import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import NumberInput from '../../common/NumberInput';
import { Button, CircularProgress, Snackbar, Tooltip } from '@material-ui/core';
import ConfirmForm from '../../common/ConfirmForm';
import IMAGES from '../../../constants/images';
import PlusMinus from '../../common/PlusMinus';
import { createHostelWorldRatePlan, updateHostelWorldRatePlan } from '../../../redux/actions/microsite/hostelworld';
import { fetchDateFormat } from '../../../utils/utility';
import { CheckRatePlanType, RATE_PLAN_FORM as FORM } from '../helper';
import moment from "moment-timezone";
import groupBy from 'lodash/groupBy';

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    headerPlainText: {
        fontSize: '1.4rem',
        color: '#000000',
        paddingBottom: theme.spacing(1),
    },

    dialog: {
        overflowY: 'hidden'
    },
    dialogPaper: {
        // minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            // minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 2px 20px #F0F0F0",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 20,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
            top: '20px',
            right: '10px'
        }
    },
    listSection: {
        margin: theme.spacing(3),
    },
    listContainer: {
        maxHeight: 'calc(100vh - 186px)',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 110px)',
        },
    },
    container: {
        height: '100%',
        position: 'relative',
        margin: 'auto',
        minWidth: 350,
        marginTop: 20,
        padding: '0 30px',
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        marginTop: 20,
        marginBottom: 20
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
    },
    field: {
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        display: 'flex',
        height: 35,
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& > div': {
            width: '100%'
        },
        '&.disabled': {
            backgroundColor: '#E0E0E0'
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    toggle: {
        alignItems: 'center',
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: 25,
        display: 'flex',
        width: '200px',
    },
    toggleButton: {
        alignItems: 'center',
        color: '#BDBDBD',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        height: 35,
        justifyContent: 'center',
        width: '50%'
    },
    toggleSelect: {
        background: '#4F9581',
        boxShadow: '-15px 0px 17px -7px #F2F2F2',
        color: '#FFFFFF',
    },
    left: {
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
    },
    right: {
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
    },
    submit: {
        display: 'flex',
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    rightAlignSubmit: {
        width: '40%',
        display: 'flex',
        justifyContent: "flex-end",
    },
    cancel: {
        color: '#999999',
        fontSize: '1.2rem',
        marginRight: 15,
        '&:hover': {
            background: '#ffffff'
        }
    },
    tooltipText: {
        fontSize: '1rem'
    },
    infoIcon: {
        cursor: 'pointer',
        height: 12,
        width: 12,
        marginLeft: 10,
        opacity: 0.5
    },
    plusMinusContainer: {
        width: 200
    },
    table: {
       display: 'flex' 
    },
    column: {
        width: '25%',
        textAlign: 'center',
    },
    tableHeader: {
        padding: 10,
        backgroundColor: '#E0E0E0',
        fontWeight: 600,
        fontSize: '1.1rem',
        lineHeight: '20px'
    },
    cell: {
        padding: 10,
        border: '1px solid #E0E0E0',
        fontSize: '1.1rem',
        lineHeight: '20px'
    },
    submitButton: {
        background: '#000000',
        fontWeight: 600,
        fontSize: '1.2rem',
        '&:hover': {
            background: '#000000',
            boxShadow: 'none'
        }
    },
    warningSection: {
        padding: '10px 5px',
        textAlign: 'center',
        width: '100%',
        background: 'rgb(255, 213, 153)',
        borderRadius: '5px',
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    firstRow: {
        alignItems: 'center',
        borderRadius: 5,
        display: 'flex',
        marginTop: 40,
        marginBottom: 40,
    },
    bulb: {
        width: 40,
        height: 40,
    },
    helperText: {
        color: '#999999',
        fontSize: '1.2rem',
        lineHeight: 1.3,
        marginLeft: 20,
    },
}));

const Toggle = ({ classes, toggleHandler, value, firstToggleText, secondToggleText }) => (
    <div className={classes.toggle}>
        <div
            className={clsx({ [classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: value })}
            onClick={() => toggleHandler()}
        >
            {firstToggleText}
        </div>
        <div
            className={clsx({ [classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: !value })}
            onClick={() => toggleHandler()}
        >
            {secondToggleText}
        </div>
    </div>
)

const getParentRatePlanData = (info = []) => {
    var counter = 0;
    var days = ['Thu', 'Fri', 'Sat'];
    var dayRates = [];

    for (let data of info) {
        var day = moment(data.date).format('ddd');

        if (counter === 3) break;

        if (!days.includes(day)) {
            counter = 0;
            dayRates = [];
            continue;
        }

        if (counter >= 0) {
            dayRates.push(data);
            counter++;
            continue;
        }
    }

    if (dayRates.length < 3)
        dayRates = info.slice(0, 3);

    return dayRates;
}

const RatePlanForm = (props) => {
    const classes = useStyles();
    const { formType, data = {}, settings = {}, inboxRoomType = {}, parentRatePlanID, ratePlans, roomTypeRatePlans, currentSpace } = props;
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [error, setError] = React.useState({})
    const [isDerived, setIsDerived] = React.useState(CheckRatePlanType(FORM.NRR_DERIVED.code, data) ? true : false)
    const parentRatePlans = roomTypeRatePlans.find(plan => data.parentrateplanid == plan.id || ratePlans?.[plan.id]?.default);

    const [formData, setFormData] = React.useState({
        weekdayrate: data.weekdayrate || 0,
        weekendrate: data.weekendrate || 0,
        percent: data.percent || -1,
        active: data.active === 1 ? true : data.active === 0 ? false : true,
        currency: data.currency || settings.currency || null,
        isbreakfastincluded: data.isbreakfastincluded === 1 ? true : data.isbreakfastincluded === 0 ? false : true
    })

    const toggleHandler = () => {
        setFormData({ ...formData, active: !formData.active })
    }

    const setDerivedHandler = () => {
        setIsDerived(!isDerived)
        setError({})
    }

    const onChangeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });
        if (error[name]) setError({ ...error, [name]: false });
    }

    const confirmDeleteHandler = () => {
        console.log('')
    }

    const submitHandler = () => {
        let errors = {};
        let payload = {
            active: formData.active ? 1 : 0,
            roomtypeid: Number(inboxRoomType.roomtypeid),
            currency: formData.currency,
            name: [FORM.NRR.code, FORM.NRR_DERIVED.code].includes(formType) ? (isDerived ? FORM.NRR_DERIVED.name : FORM.NRR.name) : FORM[formType].name,
            ...(isDerived && {
                parentrateplanid: data.parentrateplanid || parentRatePlanID,
                percent: formData.percent,
                nonrefundable: 1
            }),
            ...(!isDerived && {
                weekdayrate: formData.weekdayrate,
                weekendrate: formData.weekendrate,
            }),
            ...([FORM.BB.code, FORM.BB_NRR.code].includes(formType) && {
                isbreakfastincluded: 1
            }),
            ...([FORM.NRR.code, FORM.NRR_DERIVED.code, FORM.BB_NRR.code].includes(formType) && {
                nonrefundable: 1
            }),
            ...(FORM.BB.code === formType && {
                nonrefundable: 0
            }),
        };

        if (payload.active && payload.name !== FORM.STANDARD.name && !isDerived) {
            if (!payload.weekdayrate) errors.weekdayrate = true;
            if (!payload.weekendrate) errors.weekendrate = true;
            setError({ ...errors })

            if (Object.keys(errors).length) return false;
        }

        props.submitHandler(payload)
    }

    const processRowPrice = (price) => {
        return Number(parseFloat(price).toFixed(2))
    }

    return (
        <React.Fragment>
            <div className={classes.row}>
                <div className={classes.label}>{FORM[props.formType]?.label}</div>
            </div>
            <div className={classes.row}>
                <Toggle classes={classes} toggleHandler={toggleHandler} value={formData.active} firstToggleText={'Active'} secondToggleText={'Inactive'} />
            </div>

            {formType !== FORM.STANDARD.code &&
                <React.Fragment>
                    {(formType === FORM.NRR.code || CheckRatePlanType(FORM.NRR_DERIVED.code, data)) &&
                        <>
                            <div className={classes.firstRow}>
                                <div>
                                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                                </div>
                                <div className={classes.helperText}>
                                    If you set rate as Percent, the rate will be calculated by Hostelworld from the Standard Rate. If you set rate type as Amount - the rate will be fetched from the matching rate plan on Counter.
                                </div>
                            </div>

                            <div className={classes.row}>
                                <div className={classes.label}>Type
                                    <Tooltip placement="bottom-start" className={classes.icon} title={
                                        <div className={classes.tooltipText}>
                                            <div> Choose Amount to manually create a rate or Percentage for a discount off the Standard Rate.</div>
                                        </div>
                                    }>
                                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                                    </Tooltip>
                                </div>
                                <Toggle classes={classes} toggleHandler={setDerivedHandler} value={!isDerived} firstToggleText={'Amount'} secondToggleText={'Percent'} />
                            </div>
                        </>
                    }

                    {!isDerived &&
                        <div className={classes.row}>
                            <div className={classes.label}>Week day rate</div>
                            <div className={clsx(classes.field, { [classes.fieldError]: error.weekdayrate })}>
                                <NumberInput
                                    id="weekdayrate"
                                    name="weekdayrate"
                                    min={0}
                                    value={formData.weekdayrate}
                                    onChange={onChangeHandler} />
                            </div>
                        </div>
                    }

                    {!isDerived &&
                        <div className={classes.row}>
                            <div className={classes.label}>Weekend rate</div>
                            <div className={clsx(classes.field, { [classes.fieldError]: error.weekendrate })}>
                                <NumberInput
                                    id="weekendrate"
                                    name="weekendrate"
                                    min={0}
                                    value={formData.weekendrate}
                                    onChange={onChangeHandler} />
                            </div>
                        </div>
                    }

                    {isDerived && <>
                        <div className={classes.row}>
                            <div className={classes.label}>Non-refundable Rate <Tooltip placement="bottom-start" className={classes.icon} title={
                                <div className={classes.tooltipText}><div> Applies across all dates.</div></div>
                            }>
                                <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                            </Tooltip>
                            </div>

                            <div className={classes.plusMinusContainer}>
                                <PlusMinus
                                    minimum={-99}
                                    maximum={-1}
                                    fullWidth={true}
                                    itemID={'percent'}
                                    onCountChange={(value, id) => setFormData({ ...formData, percent: value })}
                                    prefilledCount={formData.percent} />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.label}>Discounted from Standard Rate</div>
                            <div className={classes.box}>
                                <div className={classes.table}>
                                    {getParentRatePlanData(parentRatePlans?.rates).map(rate => (
                                        <div key={rate.date} className={classes.column}>
                                            <div className={classes.tableHeader}>
                                                <div>{fetchDateFormat(rate.date, 'ddd - MMM DD')}</div>
                                                <div>{rate.price}</div>
                                            </div>
                                            <div className={classes.cell}>
                                                <div>{formData.percent}%</div>
                                                <div>{processRowPrice((rate.price * (formData.percent / 100)) + rate.price)}</div>
                                            </div>
                                        </div>
                                    ))}

                                    {!parentRatePlans?.rates && <div className={classes.warningSection}>No standard rate found</div>}
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </React.Fragment>
            }

            <div className={classes.submit}>
                <div className={classes.leftAlignSubmit}>
                    <ConfirmForm
                        title={'Delete rate plan?'}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={confirmDeleteHandler}
                    >
                        Are you sure you would like to delete this rate plan?
                    </ConfirmForm>

                    {!confirmOpen && data.id &&
                        <img
                            src={IMAGES.ICONS.bin}
                            className={classes.deleteButton}
                            onClick={() => setConfirmOpen(true)}
                        />
                    }

                </div>
                <div className={classes.rightAlignSubmit}>
                    <Button className={classes.cancel} onClick={props.cancelHandler}>Cancel</Button>
                    <Button variant="contained" color="primary" className={classes.submitButton} onClick={submitHandler}>Save</Button>
                </div>
            </div>

        </React.Fragment>
    )
}



const NewRatePlan = (props) => {
    const classes = useStyles();
    const { data = {}, currentSpace } = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState()

    const closeModalHandler = () => {
        props.closeModalHandler(null)
    }

    const closeErrorHandler = () => {
        setError(null)
    }

    const submitHandler = (payload) => {
        setLoading(true);
        let response;
        if (data.rateplanid) {
            payload.rateplanid = data.rateplanid;
            response = props.dispatch(updateHostelWorldRatePlan(payload, data.rateplanid, currentSpace.propertyID))
        }

        if (!data.rateplanid) {
            response = props.dispatch(createHostelWorldRatePlan(payload, currentSpace.propertyID))
        }

        response.then(data => {
            setLoading(false);
            props.refreshInventory()
            closeModalHandler();
        }).catch(error => {
            setError(error)
            setLoading(false);
        });
    }

    return (
        <Dialog
            open={true}
            onClose={closeModalHandler}
            classes={{ paperFullWidth: classes.dialogPaper }}
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
        >
            <div className={classes.dialog}>
                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{props?.data?.rateplanid ? 'Edit' : 'Add'} Rate</div> 
                    <div className={classes.headerPlainText}>{props.inboxRoomType.displayname} ({props.inboxRoomType.roomtypeid})</div>
                    <CloseIcon className={classes.closeIcon} onClick={closeModalHandler} />
                </div>
                <div className={classes.listContainer}>
                    <div className={classes.listSection}>
                        <RatePlanForm
                            cancelHandler={closeModalHandler}
                            submitHandler={submitHandler}
                            formType={props.formType}
                            data={props.data}
                            settings={props.settings}
                            parentRatePlanID={props.parentRatePlanID}
                            parentRatePlans={props.parentRatePlans}
                            roomTypeRatePlans={props.roomTypeRatePlans}
                            ratePlans={props.ratePlans}
                            currentSpace={currentSpace}
                            inboxRoomType={props.inboxRoomType}
                        />
                    </div>
                </div>

                {/* ------------- Snackbar error messages -------------- */}
                {error && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={closeErrorHandler}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{error}</span>}
                    />
                )}

                {loading &&
                    <div className={"loadingOverlay"}>
                        <CircularProgress className={"loading"} />
                    </div>
                }

            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    settings: state.inbox.hostelworld.settings,
    currentSpace: state.spaces[state.dashboard.currentSpace],
})

export default connect(mapStateToProps)(NewRatePlan);