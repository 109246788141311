const defaultUpsell = { default: true };

export default (state = defaultUpsell, action) => {
  switch (action.type) {
    case "ADD_UPSELL_ITEM_SUCCESS":
      if (state.default) {
        return { [action.payload._id]: action.payload }
      }
      return {
        ...state,
        [action.payload._id]: action.payload
      }

    case "LIST_UPSELL_ITEMS_SUCCESS":
      if (state.default) {
        return { ...action.payload.upsellItem }
      }
      return {
        ...state,
        ...action.payload.upsellItem
      }

    case "UPDATE_UPSELL_ITEM_SUCCESS":
      const updateItem = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: {
          ...updateItem,
          ...action.payload
        }
      }
    case "RESET_UPSELL_ITEMS":
      return {
        ...defaultUpsell
      }
    case "USER_LOGOUT":
      return {
        ...defaultUpsell
      }
    default:
      return state;
  }
};