import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { resetPassword } from '../../redux/actions/user/user';

import http from '../../redux/utils/http'; 

import { makeStyles, Grid, Hidden, Typography, Button, Checkbox, InputBase, TextField, CircularProgress } from '@material-ui/core';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'scroll',
    width: '100%',
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    background: '#F5F1ED',
    flexGrow: 1,
    height: '100%',
  },
  header: {
    color: '#333333',
    fontSize: '1.6rem',
    lineHeight: 1.4,
    fontWeight: 600,
    marginBottom: 20,
  },
  background: {
    alignItems: 'center',
    background: '#FFFFFF',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  center: {
    alignItems: 'center',
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#ffffff',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80,
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  input: {
    display: "none"
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  buttonRow: {
    marginBottom: 20,
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem',
    marginTop: 20,
  },
  imageContainer: {
    width: '50%'
  },
  signupImage: {
    objectFit: 'contain',
    width: '100%',
  },
  welcome: {
    marginBottom: 15,
    marginTop: 30,
  },
}))

const ResetPasswordPage = (props) => {
  const classes = useStyles();
  const token = new URLSearchParams(useLocation().search).get("token");
  const [ user, setUser ] = useState({});
  const [form, setForm] = useState({});
  const [ error, setError ] = useState({password: false, passwordMatch: false});
  const [ loading, setLoading ] = useState(true);
  const [ invalidToken, setInvalidToken ] = useState(false);


  const loadDataOnlyOnce = () => {
    console.log(token);
    if (token) {
      http
      .get(`/api/users/verify/forgotPassword/${token}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        setLoading(false);
        setUser(data);
      })
      .catch(errorResult => {
        setInvalidToken(true);
        setLoading(false);
        console.log(errorResult);
      });
    } else {
      setInvalidToken(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const handleLogin = () => {
    props.history.push('/login');
  }

  const handleUpdate = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
    if (e.target.value !=='') {
      if (e.target.name==='password' || e.target.name==='password2') {
        setError({...error, [e.target.name]: false, passwordMatch: false})
      } else {
        setError({...error, [e.target.name]: false})
      }
    }
  }

  const handleValidate = () => {
    let foundError = false
    let errorStep = {}
    if (!form.password || form.password.length < 7) {
      errorStep.password = true
      foundError = true
    }
    if (form.password!==form.password2) {
      errorStep.passwordMatch = true
      foundError = true
    }
    if (foundError) {
      setError({...error, ...errorStep})
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    console.log('here', user)
    const checkInput = handleValidate();
    if (checkInput) {
      props.dispatch(resetPassword(user._id, form.password, token, props.history)); 
    }
  }

  return(
    <div className={classes.root}>
      
      <Grid container className={classes.container}>
        
        {loading && <Grid item xs={12} md={6}>
          <div className={classes.loadingState}><CircularProgress /></div>
        </Grid>}

        {invalidToken && 
          <Grid item xs={12} md={6}>
            <div className={classes.section}>
              <div className={classes.row}>
                <Typography variant="h4">
                  This link has expired. Please request a new reset link.
                </Typography>
              </div>
              <div className={clsx(classes.row, classes.center)}>
                <Typography variant="body2">
                  Already have an account? <span className={classes.link} onClick={handleLogin}>Log in</span>
                </Typography>
              </div>
            </div>
          </Grid>
        }

        {!loading && !invalidToken && <Grid item xs={12} md={6}>
          <div className={classes.section}>
        
            <div className={classes.row}>
              <div className={classes.header}>
                Please reset your password
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.label}>Password</div>
              <div 
                className={classes.field} 
                style={{border: (error.password || error.passwordMatch)  ? '1px solid red' : '1px solid #ffffff'}}
              >
                <InputBase 
                  id="password"
                  name="password"
                  fullWidth
                  type="password"
                  onChange={handleUpdate}
                />
              </div>
              {error.password && <div className={classes.error}>Password must be longer than 6 characters</div>}
              {error.passwordMatch && <div className={classes.error}>Passwords must match</div>}
            </div>

            <div className={classes.row}>
              <div className={classes.label}>Repeat Password</div>
              <div 
                className={classes.field} 
                style={{border: (error.password || error.passwordMatch) ? '1px solid red' : '1px solid #ffffff'}}
              >
                <InputBase 
                  id="password2"
                  name="password2"
                  fullWidth
                  type="password"
                  onChange={handleUpdate}
                />
              </div>
              {error.password && <div className={classes.error}>Password must be longer than 6 characters</div>}
              {error.passwordMatch && <div className={classes.error}>Passwords must match</div>}
            </div>

            <div className={clsx(classes.row, classes.buttonRow)}>
              <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
                Take me to Workspace
              </Button>
            </div>

            <div className={clsx(classes.row, classes.center)}>
              <Typography variant="body2">
                Already have an account? <span className={classes.link} onClick={handleLogin}>Log in</span>
              </Typography>
            </div>

          </div>
        </Grid>}

        <Hidden smDown><Grid item xs={12} md={6}>
          <div className={classes.background}>
            <div className={classes.imageContainer}>
              <img className={classes.signupImage} src={IMAGES.ILLUSTRATIONS.communityLarge} />
            </div>
            <div className={classes.welcome}>
              <Typography variant="h2" style={{color: '#333333'}}>counter</Typography>
            </div>
            <div>
              <Typography variant="h5" style={{fontWeight: 600}}>all-in-one workspace for hostels</Typography>
            </div>
          </div>
        </Grid></Hidden>

      </Grid>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: state.auth.user
})

export default connect(mapStateToProps)(withRouter(ResetPasswordPage));