import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { setCurrentSpace, setCurrentSpaceType } from '../../redux/actions/dashboard/dashboard';

import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Room';

const useStyles = makeStyles({
  avatar: {
    '& img': {
      borderRadius: '50%',
      height: 40,
      width: 40,
    }
  },
});

const SimpleDialog = (props) => {
  const classes = useStyles();
  const { onClose, currentSpaceID, spaces, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (spaceID) => {
    props.dispatch(setCurrentSpace(spaceID));
    if (spaceID !== 'community') {
      props.dispatch(setCurrentSpaceType(spaces[spaceID].type));
    } else {
      props.dispatch(setCurrentSpaceType('Community'));
    }
    onClose();
  };

  return (
    <Dialog 
      onClose={handleClose} 
      aria-labelledby="simple-dialog-title" 
      open={open}
      size="xs"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">Select Property</DialogTitle>
      <List disablePadding={true}>

        <ListItem 
          style={{background: currentSpaceID==='community' ? '#dddddd' : '#ffffff'}}
          button={currentSpaceID !== 'community'}
          onClick={() => handleListItemClick('community')} 
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <img 
                style={{
                  background: 'community'===currentSpaceID ? '#dddddd' : '#ffffff'
                }} 
                src={'/images/icons/counter.png'}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Community"} />
        </ListItem>
        
        {Object.keys(spaces).map((spaceID) => (
          <ListItem 
            style={{background: spaceID===currentSpaceID ? '#dddddd' : '#ffffff'}}
            button={currentSpaceID !== spaceID}
            onClick={() => handleListItemClick(spaceID)} 
            key={spaceID}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                {!spaces[spaceID].profilePic && <PersonIcon />}
                {spaces[spaceID].profilePic &&
                  <img 
                    style={{
                      filter: spaceID===currentSpaceID ? 'grayscale(0%)' : 'grayscale(100%)',
                      opacity: spaceID===currentSpaceID ? 1 : 0.3
                    }} 
                    src={(spaces[spaceID].profilePic.includes('undefined') || !spaces[spaceID].profilePic) ? 
                            '/images/placeholder-hostel.jpg' : 
                            spaces[spaceID].profilePic}
                  />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={spaces[spaceID].name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  spaces: state.spaces
})

export default connect(mapStateToProps)(SimpleDialog);
