/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Button,
  Checkbox,
  InputBase,
  makeStyles,
  Typography,
  Snackbar,
} from "@material-ui/core";
import TermsAndConditions from "./TermsAndConditions";
import {
  createNewUser,
  resetError,
} from "../../redux/actions/signup/signupCommunity";

import PrivacyPolicy from "./PrivacyPolicy";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 30,
    width: "60%",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  center: {
    alignItems: "center",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
  },
  field: {
    background: "#ffffff",
    borderRadius: "15px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  legal: {
    alignItems: "center",
    display: "flex",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontSize: "1.2rem",
    paddingTop: 10,
  },
  next: {
    borderRadius: "15px",
    fontWeight: 600,
    fontSize: "1.6rem",
  },
}));

const UserSignupForm = (props) => {
  const classes = useStyles();
  const [user, setUser] = React.useState({ terms: false });

  const [error, setError] = React.useState({
    name: false,
    email: false,
    passwordLength: false,
    passwordCharacter: false,
    passwordNumber: false,
    passwordEntry: false,
    terms: false,
  });

  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPolicy, setOpenPolicy] = React.useState(false);

  const handleLogin = () => {
    props.history.push("/login");
  };

  const handleOpenPolicy = () => {
    setOpenPolicy(true);
  };
  const handleClosePolicy = () => {
    setOpenPolicy(false);
  };
  const handleOpenTerms = () => {
    setOpenTerms(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };
  const handleCloseTerms = () => {
    setOpenTerms(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };
  const handleAcceptTerms = () => {
    setOpenTerms(false);
    setUser({ ...user, terms: 1 });
    setError({ ...error, terms: false });
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const validatePassword = (password = "") => {
    const errors = {};
    let hasError = false;

    const validations = [
      { field: "passwordLength", status: password.length > 7 },
      {
        field: "passwordCharacter",
        status: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      },
      { field: "passwordNumber", status: /([0-9])/.test(password) },
      { field: "passwordEntry", status: Boolean(password) },
    ];

    validations.forEach((data) => {
      const isInvalid = !data.status;

      errors[data.field] = isInvalid;

      if (isInvalid) hasError = true;
    });

    return { passwordErrors: { ...errors }, hasError };
  };

  const validateOnChange = (id, value) => {
    // -------------- PASSWORD---------------
    if (id === "password") {
      const { passwordErrors } = validatePassword(value);
      setError({ ...error, ...passwordErrors });
      return;
    }

    // -------------- USER NAME---------------
    if ((id === "name" && value.charAt(0) === " ") || value === "") {
      setError({ ...error, name: true });
      return;
    }

    // -------------- USER EMAIL---------------
    const emailRegx = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    if (id === "email" && (!value || !value.match(emailRegx))) {
      setError({ ...error, email: true });
      return;
    }

    return true;
  };

  const handleUpdate = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const id = e.target.id;

    setUser({ ...user, [name]: value });

    if (!validateOnChange(id, value)) return;

    if (value !== "") setError({ ...error, [name]: false });
  };

  const handleToggle = (e) => {
    if (e.target.checked) {
      setUser({ ...user, terms: 1 });
      setError({ ...error, terms: false });
    } else {
      setUser({ ...user, terms: false });
    }
  };

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};

    // -------------- USER NAME---------------
    if (!user.name) {
      errorStep.name = true;
      foundError = true;
    }

    // -------------- USER EMAIL---------------
    if (
      !user.email ||
      !user.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      errorStep.email = true;
      foundError = true;
    }

    // -------------- PASSWORD---------------

    const { passwordErrors, hasError } = validatePassword(user.password);
    if (hasError) {
      errorStep = { ...errorStep, ...passwordErrors };
      foundError = true;
    }

    // -------------- TERMS AND CONDITIONS---------------
    if (!user.terms) {
      errorStep.terms = true;
      foundError = true;
    }

    if (foundError) {
      setError({ ...error, ...errorStep });
      return false;
    } else return true;
  };

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      props.dispatch(createNewUser(user));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "userAction",
        eventCategory: "Signup",
        eventAction: "Step 1",
        eventLabel: `create new user`,
      });
    }
  };

  const handleCloseError = () => {
    props.dispatch(resetError("CREATE_USER"));
  };

  const getPasswordStatus = () =>
    error.passwordLength ||
    error.passwordCharacter ||
    error.passwordNumber ||
    error.passwordEntry;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.label}>Name</div>
        <div
          className={classes.field}
          style={{ border: error.name ? "1px solid red" : "1px solid #ffffff" }}
        >
          <InputBase
            id="name"
            name="name"
            fullWidth
            type="text"
            onChange={(e) => handleUpdate(e)}
          />
        </div>
        {error.name && (
          <div className={classes.error}>Please enter your name</div>
        )}
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Email</div>
        <div
          className={classes.field}
          style={{
            border: error.email ? "1px solid red" : "1px solid #ffffff",
          }}
        >
          <InputBase
            id="email"
            name="email"
            fullWidth
            type="email"
            onChange={(e) => handleUpdate(e)}
          />
        </div>
        {error.email && (
          <div className={classes.error}>Please enter a valid email</div>
        )}
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Password</div>
        <div
          className={classes.field}
          style={{
            border: getPasswordStatus() ? "1px solid red" : "1px solid #ffffff",
          }}
        >
          <InputBase
            id="password"
            name="password"
            // placeholder="6+ characters"
            fullWidth
            type="password"
            onChange={(e) => handleUpdate(e)}
          />
        </div>

        {error.passwordEntry && (
          <div className={classes.error}>
            Please enter a password to proceed
          </div>
        )}

        {!error.passwordEntry && (
          <>
            {error.passwordLength && (
              <div className={classes.error}>Minimum 8 characters required</div>
            )}
            {error.passwordCharacter && (
              <div className={classes.error}>
                One uppercase and one lowercase letter required
              </div>
            )}
            {error.passwordNumber && (
              <div className={classes.error}>At least 1 number required</div>
            )}
          </>
        )}
      </div>

      <div className={classes.row}>
        <div className={classes.legal}>
          <Checkbox
            color="primary"
            checked={Boolean(user.terms)}
            onClick={handleToggle}
          />
          <Typography variant="body2">
            Creating an account means you accept our{" "}
            <span className={classes.link} onClick={handleOpenTerms}>
              Terms of Service
            </span>
            &nbsp;and{" "}
            <span className={classes.link} onClick={handleOpenPolicy}>
              Privacy Policy
            </span>
          </Typography>
        </div>
        {error.terms && (
          <div className={classes.error}>
            Please accept terms and conditions
          </div>
        )}
      </div>

      {openTerms && (
        <TermsAndConditions
          open={openTerms}
          handleClose={handleCloseTerms}
          handleAccept={handleAcceptTerms}
        />
      )}
      {openPolicy && (
        <PrivacyPolicy open={openPolicy} handleClose={handleClosePolicy} />
      )}

      <div className={classes.row}>
        <Button
          variant="contained"
          color="primary"
          className={classes.next}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>

      <div className={clsx(classes.row, classes.center)}>
        <Typography variant="body2">
          Already have an account?{" "}
          <span className={classes.link} onClick={handleLogin}>
            Log in
          </span>
        </Typography>
      </div>

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open
          onClose={handleCloseError}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { errors } = state;
  return {
    errors: errors.CREATE_USER,
  };
};

export default connect(mapStateToProps)(withRouter(UserSignupForm));
