import PersonalInfo from './PersonalInfo';
import HostelDetails from './HostelDetails';
import NameRooms from './NameRooms';
import ChannelDetails from './ChannelDetails';
import PaymentDetails from './PaymentDetails';

export default {
    PersonalInfo,
    HostelDetails,
    NameRooms,
    ChannelDetails,
    PaymentDetails
}