import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SidebarChatMessage from "./SidebarChatMessage";
import moment from "moment";
import _ from "lodash";

import Emoji from "./Emoji";
import { returnCurrent } from "../../redux/selectors/dashboard";
import Chat from "./Chat";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { fetchDatePerTimezone } from "../../utils/utility";

import IMAGES from "../../constants/images";
import { API_BASE_URL, NODE_ENV } from "../../config";

const io = require("socket.io-client");

const styles = (theme) => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 110px)",
    width: "100%",
    overflow: "hidden",
  },
  messageBox: {
    boxSizing: "content-box",
    display: "block",
    flexGrow: 1,
    overflowY: "scroll",
    paddingRight: "17px",
    width: "100%",
  },
  messages: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    paddingLeft: 5,
    paddingRight: 5,
  },
  blankState: {
    background: "#FFFFFF",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 160px)",
    width: "100%",
    "& img": {
      width: 250,
      height: 250,
    },
  },
  inputBox: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    marginTop: 5,
    "& .MuiFormControl-marginNormal": {
      marginBottom: 5,
      marginTop: 5,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  emojiToggle: {
    color: "#999999",
    position: "absolute",
    right: 10,
    top: 12,
  },
  inputText: {
    fontSize: "1.2rem",
    "& div": {
      padding: "10px",
      paddingLeft: "20px",
      paddingRight: "40px",
    },
    "& textarea": {
      minHeight: "16px",
    },
  },
  submit: {
    marginLeft: 20,
    minWidth: "100px",
    maxHeight: "70px",
  },
});

const baseURL =
  NODE_ENV === "production"
    ? API_BASE_URL
    : "http://localhost:3001";
var socket;

// Making the App component
const SidebarChat = (props) => {
  const {
    classes,
    user,
    currentChatID,
    currentSpaceID,
    currentSpace,
    currentChat,
    users,
  } = props;
  const [messages, setMessages] = React.useState([]);
  const [showEmoji, setShowEmoji] = React.useState(false);
  const [input, setInput] = React.useState("");
  const messagesEnd = React.useRef();
  var sameDay = false;
  var thread = false;
  var lastId = "";
  var lastDay = fetchDatePerTimezone(null, currentSpace.timezone);
  var currentUser = false;
  const spaceChat = currentChatID === currentSpaceID;

  const scrollToBottom = () => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && input != "") {
      e.preventDefault();
      socket.emit("sendMessage", input, () => {
        console.log("message sent and processed");
      });
      setInput("");
    }
  };

  const emojiToggle = (e) => {
    setShowEmoji(true);
  };

  const addEmoji = (e) => {
    // console.log(e.native)
    let emoji = e.native;
    setInput(input + emoji);
    setShowEmoji(false);
  };

  React.useEffect(() => {
    console.log("connecting to ", currentSpace.name, " chat");
    socket = io(baseURL, {
      secure: true,
      query: `token=${localStorage.jwtToken}&spaceID=${props.currentSpaceID}`,
    });

    console.log("sending emit");
    socket.emit("frontEndLoad", (messageHistory) => {
      console.log(
        "frontEndLoad --> Loading previous messages - ",
        messageHistory
      );
      setMessages([...messageHistory]);
      scrollToBottom();
    });

    socket.on("message", (message) => {
      setMessages((prevMessages) => [message, ...prevMessages]);
      scrollToBottom();
    });

    return () => {
      socket.disconnect();
      console.log("disconnected");
    };
  }, [currentSpaceID]);

  return (
    <div className={classes.root}>
      <div className={classes.messageBox}>
        {messages.length === 0 && (
          <div className={classes.blankState}>
            <img src={IMAGES.ILLUSTRATIONS.groupChat} />
          </div>
        )}
        <div className={classes.messages}>
          {messages.length !== 0 &&
            messages
              .concat()
              .reverse()
              .map((message, index) => {
                message.senderID === lastId
                  ? (thread = true)
                  : (thread = false);
                message.senderID === user._id
                  ? (currentUser = true)
                  : (currentUser = false);
                lastId = message.senderID;
                fetchDatePerTimezone(
                  message.createdAt,
                  currentSpace.timezone
                ).isSame(lastDay, "date")
                  ? (sameDay = true)
                  : (sameDay = false);
                lastDay = fetchDatePerTimezone(
                  message.createdAt,
                  currentSpace.timezone
                );
                return (
                  <SidebarChatMessage
                    key={index}
                    message={message}
                    thread={thread}
                    sameDay={sameDay}
                    currentUser={currentUser}
                    timezone={currentSpace.timezone}
                  />
                );
              })}

          {showEmoji && (
            <Emoji
              open={showEmoji}
              addEmoji={addEmoji}
              clickedOutside={() => setShowEmoji(false)}
            />
          )}
          <div style={{ float: "left", clear: "both" }} ref={messagesEnd}></div>
        </div>
      </div>

      {currentSpaceID !== "5eb29a4a2826ed0ecfce6ca9" && (
        <div className={classes.inputBox}>
          <TextField
            className={classes.inputText}
            placeholder="Type a message here..."
            multiline
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={handleChange}
            value={input}
            autoFocus
            onKeyDown={handleKeyDown}
          />
          <TagFacesIcon className={classes.emojiToggle} onClick={emojiToggle} />
        </div>
      )}

      {/* {currentChatID && <Chat />} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  users: state.users,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentChatID: state.dashboard.currentChat,
  currentChat: returnCurrent(state.messages, state.dashboard.currentChat),
});

export default withStyles(styles)(connect(mapStateToProps)(SidebarChat));
