import { schema } from 'normalizr';
import _ from 'lodash';

const productProcessStrategy = (value, parent, key) => {
  const productsArray =  _.cloneDeep(value.products);
  return {
    ...value,
    products: productsArray
  };
}

const productSchema = new schema.Entity('products', {}, {
  idAttribute: '_id'
});

const productCategorySchema = new schema.Entity('productCategory', {
  products: [productSchema]
}, { 
  processStrategy: productProcessStrategy,
  idAttribute: '_id' 
});


export { productSchema, productCategorySchema }