import http from "../../utils/http";
import { normalize } from 'normalizr';
import { rateSchema, stopSellDateSchema } from '../../schema/rateSchema';

//----------------------------------------------------------------
// LOAD RATES
//----------------------------------------------------------------
export function loadRates(form) {
  const ACTION_NAME = "LOAD_RATES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/rates/view/${form.propertyID}`, form)
      .then(successResult => {
        const data = successResult.data;
        // console.log('fetch rates API response', data);
        var normalizedOrder = normalize(data, [ rateSchema ] );
        // console.log(normalizedOrder.entities.rates);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.rates });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// FETCH STOP SELL DATES INFO
//----------------------------------------------------------------
export function getStopSellDatesInfo(propertyID, params) {
  const ACTION_NAME = "FETCH_STOP_SELL_DATES_INFO";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/stopSellAllRooms/${propertyID}`, { params })
      .then(successResult => {
        const data = successResult.data;
        const normalizedOrder = normalize(data, [ stopSellDateSchema ]);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return normalizedOrder?.entities?.stopSellDatesInfo || {};
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return false;
      });
  };
}

//----------------------------------------------------------------
// BULK UPDATE RATES
//----------------------------------------------------------------
export function bulkUpdateRates(propID, rates) {
  const ACTION_NAME = "BULK_UPDATE_RATES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/rates`, rates)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data[0] });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// BULK UPLOAD RATES
//----------------------------------------------------------------
export function bulkUploadRates(rates) {
  const ACTION_NAME = "BULK_UPLOAD_RATES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/rates/bulkUpdate`, rates)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}


//----------------------------------------------------------------
// POST RATES
//----------------------------------------------------------------
export function editRates(propID, rates) {
  const ACTION_NAME = "EDIT_RATES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/rates/${propID}`, rates)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        //dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data[0] });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}


//----------------------------------------------------------------
// RESET RATES
//----------------------------------------------------------------
export const resetRates = () => (dispatch) => {
  dispatch({ type: "RESET_RATES"})
};

