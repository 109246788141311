import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import clsx from "clsx";

import {
  createHostelWorldPromotion,
  deleteHostelWorldPromotion,
  fetchHostelWorldPromotions,
  getHostelWorldRoomTypes,
  updateHostelWorldPromotion,
} from "../../../redux/actions/microsite/hostelworld";

import Form from "./Form";

import { Grid, Dialog, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";
import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listSection: {
    margin: theme.spacing(3),
  },
  firstRow: {
    alignItems: "center",
    borderRadius: 5,
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#999999",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  row: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    cursor: "pointer",
    padding: 20,
    marginBottom: 20,
    "&:hover": {
      background: "#F8F9FB",
    },
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    color: "#666666",
  },
  nameContainer: {
    alignItems: "center",
    borderBottom: "1px solid #F2F2F2",
    color: "#333333",
    display: "flex",
    fontSize: "1.5rem",
    fontWeight: 600,
    paddingBottom: 10,
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    color: "#666666",
    fontSize: "1.2rem",
    marginTop: 10,
  },
  spaceLeft: {
    marginLeft: 20,
  },
  status: {
    background: "#55998F",
    borderRadius: "50%",
    height: 10,
    marginLeft: 20,
    width: 10,
    "&.inactive": {
      background: "#DB6675",
    },
  },
}));

const List = (props) => {
  const classes = useStyles();

  const { open, closeHandler, currentSpace } = props;
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({ id: "", active: false });
  const [promotionsList, setPromotionList] = React.useState([]);
  const [roomTypesList, setRoomTypeList] = React.useState([]);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      fetchPromotionHandler();
      fetchRoomTypeHandler();
    }
  }, [open]);

  const submitHandler = (payload, id) => {
    setLoading(true);
    if (id) {
      const response = props.dispatch(
        updateHostelWorldPromotion(payload, id, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) setPromotionList([...data]);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }

    if (!id) {
      const response = props.dispatch(
        createHostelWorldPromotion(payload, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) setPromotionList([...data]);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    setData({ id: "", active: false });
  };

  const deleteHandler = (id) => {
    setLoading(true);
    if (id) {
      const response = props.dispatch(
        deleteHostelWorldPromotion({ id }, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) setPromotionList([...data]);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    setData({ id: "", active: false });
  };

  const fetchPromotionHandler = () => {
    const response = props.dispatch(
      fetchHostelWorldPromotions(currentSpace.propertyID)
    );
    response
      .then((data) => {
        setLoading(false);
        if (data) setPromotionList([...data]);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const fetchRoomTypeHandler = () => {
    const response = props.dispatch(
      getHostelWorldRoomTypes(currentSpace.propertyID)
    );
    response
      .then((data) => {
        setLoading(false);
        if (data) setRoomTypeList([...data]);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const closeErrorHandler = () => {
    setError(null);
  };

  const closeModalHandler = () => {
    if (error) closeErrorHandler();
    closeHandler();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalHandler}
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {!data.active && (
            <AddIcon
              className={classes.iconButton}
              onClick={() => setData({ id: "", active: true })}
            />
          )}

          {data.active && (
            <BackIcon
              className={classes.iconButton}
              onClick={() => setData({ id: "", active: false })}
            />
          )}

          <div className={classes.header}>Promotions</div>
          <AccessHeaderAction moduleID={MODULE.HW_PROMOTIONS.ID} />
          <CloseIcon
            className={classes.closeIcon}
            onClick={closeModalHandler}
          />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {!data.active && (
              <>
                <div className={classes.firstRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    Increase your bookings by offering discounts on your rates.{" "}
                    <a
                      className={classes.learn}
                      href="https://hostelworldinbox.zendesk.com/hc/en-us/sections/360003076399-Promotions"
                      target="blank"
                    >
                      Learn more
                    </a>
                  </div>
                </div>

                {/* LIST ALL PROMOTIONS */}
                {/* --------------------------------------------- */}
                {promotionsList.map((promotion, index) => (
                  <div
                    key={index}
                    className={classes.row}
                    onClick={() =>
                      setData({ id: promotion.id, active: true, ...promotion })
                    }
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={10}>
                        <div>
                          <div className={classes.nameContainer}>
                            <div>{promotion.name}</div>
                            <div
                              className={clsx(classes.status, {
                                inactive: !promotion.isActive,
                              })}
                            ></div>
                          </div>

                          <div className={classes.detailsContainer}>
                            <div>
                              Discount{" "}
                              {promotion?.benefits?.PERCENTDISCOUNT?.value}%
                            </div>
                            <div className={classes.spaceLeft}>
                              Min Stay{" "}
                              {
                                promotion?.conditions?.stayDates
                                  ?.isTotalNightsGreaterOrEqualThan
                              }{" "}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div className={classes.iconContainer}>
                          <ArrowRight className={classes.icon} />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </>
            )}

            {data.active && (
              <Form
                data={data}
                roomTypesList={roomTypesList}
                submitHandler={submitHandler}
                confirmDeleteHandler={deleteHandler}
                cancelHandler={() => setData({ id: "", active: false })}
              />
            )}
          </div>
        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={closeErrorHandler}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{error}</span>}
          />
        )}

        {loading && (
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default connect(mapStateToProps)(List);
