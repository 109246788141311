import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import ActivitySlotForm from '../Activities/ActivitySlotForm';
import { resetError } from '../../../redux/actions/experiences/activities';

const useStyles = makeStyles(theme =>({
    dialog: {
        overflowY: 'hidden',
        position: 'relative'
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 2px 20px #F0F0F0",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 300px)',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]:{
            height: 'calc(100vh - 140px)'
        }
    },

    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
}));

const EditSlotModal = props =>{

    const {
        modalCloseHandler,
        slotData,
        isLoading,
        errors,
        dispatch
    } = props;

    const classes = useStyles();

    const handleCloseError = () =>{
        dispatch(resetError('UPDATE_CALENDAR_SLOT'));
    }
    
    return(
        <Dialog
            open={true}
            onClose={modalCloseHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
        >
            <div className={classes.dialog}>

                {/* .....................MODAL HEADER.............................. */}
                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{slotData.gradeTitle}</div>
                    <CloseIcon className={classes.closeIcon} 
                        onClick={modalCloseHandler} 
                    />
                </div>

                {/* ----------------PROGRESS LOADER--------------------- */}
                {isLoading && (
                    <div className={classes.loadingState}>
                        <CircularProgress className={classes.loader} />
                    </div>
                )}

                {/* .....................MODAL BODY................................ */}
                <div className={classes.container}>
                    <ActivitySlotForm 
                        { ...slotData } 
                        closeModalHandler={modalCloseHandler}
                    />                            
                </div>
                
                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}

            </div>
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { loading, errors } = state;
    return{
        isLoading: loading.UPDATE_CALENDAR_SLOT,
        errors: errors.UPDATE_CALENDAR_SLOT

    }
}

export default connect(mapStateToProps)(EditSlotModal);