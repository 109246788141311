import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { fetchColombiaSettings, submitColombiaSettings } from '../../redux/actions/countrySpecific/colombia';

import { InputBase, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 300,
    justifyContent: 'center',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #FFFFFF',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginTop: 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '60%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
    width: '100%',
  },
  button: {
    background: '#000000',
    borderRadius: 5,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  errorCode: {
    borderColor: 'red',
  },
  error: {
    fontSize: '1.2rem',
    marginTop: 10,
    color: 'red',
  }
}))

const ColombiaSettings = (props) => {
  const classes = useStyles();
  const { t, currentSpace, colombiaDetails, loadingColombia } = props;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({hotelCode: false, cityCode: false})
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    props.dispatch(fetchColombiaSettings(currentSpace.propertyID));
  }, [])

  useEffect(() => {
    if (colombiaDetails) setForm({hotelCode: colombiaDetails.hotelCode, cityCode: colombiaDetails.cityCode})
  }, [colombiaDetails])

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
    setEdit(true);
    if (e.target.value!=='') {
      setErrors({...errors, [e.target.name]: false});
    }
  }

  const handleValidate = () => {
    let check = true;
    let errorCodes = {}; 
    if (form.hotelCode =='') {
      check= false;
      errorCodes.hotelCode = true;
    } 
    if (form.cityCode == '') {
      check=false;
      errorCodes.cityCode = true;
    }
    if (!check) setErrors(errorCodes);
    return check
  }

  const handleSubmit = () => {
    const check = handleValidate();
    if (check) {
      props.dispatch(submitColombiaSettings(currentSpace.propertyID, form));
      setEdit(false);
    }
  }

  return (
    <div className={classes.root}>

      {loadingColombia && <div className={classes.loadingState}><CircularProgress /></div>}
      {!loadingColombia && <div className={classes.row}>
        <div className={classes.title}>
          Codigo del Hotel
        </div>
        <div className={clsx({[classes.field]: true, [classes.errorCode]: errors.hotelCode})}>
          <InputBase 
            id="hotelCode"
            name="hotelCode"
            value={form.hotelCode || ''}
            fullWidth
            type="text"
            onChange={handleChange}
          />
        </div>
        {errors.hotelCode && <div className={classes.error}>Please enter a hotel code to continue</div>}
      </div>}

      {!loadingColombia && <div className={classes.row}>
        <div className={classes.title}>
          Codigo de Ciudad
        </div>
        <div className={clsx({[classes.field]: true, [classes.errorCode]: errors.cityCode})}>
          <InputBase 
            id="cityCode"
            name="cityCode"
            value={form.cityCode || ''}
            fullWidth
            type="text"
            onChange={handleChange}
          />
        </div>
        {errors.cityCode && <div className={classes.error}>Please enter a city code to continue</div>}
      </div>}

      {!loadingColombia && edit && <div className={classes.buttonContainer}>
        <div className={classes.button} onClick={handleSubmit}>{t('actions.save')}</div>
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  colombiaDetails: state.property ? state.property.colombia : {},
  loadingColombia: state.loading.LOAD_COLOMBIA_DETAILS || state.loading.SUBMIT_COLOMBIA_DETAILS
})

export default withTranslation()(connect(mapStateToProps)(ColombiaSettings));