import React, { useEffect } from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";
import {
  fetchKioskSettings,
  saveKioskSettings,
} from "../../redux/actions/kiosk/kiosk";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import TrixEditor from "../utils/TrixEditor";
import { BOOKING_ENGINE_URI } from "../../config";

const useStyles = makeStyles((theme) => ({
  booking: {
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&:hover": {
      background: "#eeeeee",
    },
  },
  imageContainer: {
    alignItems: "center",
    height: 100,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      height: 50,
      width: 50,
    },
  },
  bookingHeader: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  email: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "& img": {
      height: 50,
      width: 50,
    },
    "&:hover": {
      background: "#eeeeee",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 600,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  listSection: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  configSection: {
    marginTop: 50,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  noLink: {
    marginTop: 15,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  link: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontSize: "1.3rem",
      fontWeight: 600,
    },
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  preview: {
    borderRadius: 3,
    boxShadow: "0 1px 20px #F2F2F2",
    height: "auto",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  editorContainer: {
    background: "#ffffff",
    borderRadius: "4px",
    boxShadow: "0 1px 30px 0 #E0E0E0",
    color: "#666666",
    minHeight: 400,
    padding: theme.spacing(2),
    width: "100%",
    boxSizing: "border-box",
  },
}));

const mash = (value) => {
  return value.replace(/\s+/g, "-").toLowerCase();
};

const baseURI = BOOKING_ENGINE_URI;

const Kiosk = (props) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { t } = props;
  const {
    open,
    handleClose,
    currentSpace,
    property,
    bookingEngineURL,
    loadingProperty,
    errors,
    isLoading,
  } = props;
  const [edit, setEdit] = React.useState(false);
  const [form, setForm] = React.useState({
    bookingEngineURL: "",
    htmlContent: "",
  });
  const [toggle, setToggle] = React.useState(null);
  const [displayEditor, setDisplayEditor] = React.useState(false);
  const [updateSuccessful, setUpdateSuccessful] = React.useState(false);

  useEffect(() => {
    if (property.default)
      props.dispatch(fetchProperty(currentSpace.propertyID));
    const response = props.dispatch(
      fetchKioskSettings(currentSpace.propertyID)
    );
    response.then((data) => {
      if (data && data.config) {
        const configData = data.config;
        const htmlData = configData?.customPage?.text;
        setForm({ ...form, htmlContent: htmlData });
      }
      setDisplayEditor(true);
    });

    return () => setUpdateSuccessful(false);
  }, []);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "Kiosk" });
      if (ocFeature && ocFeature.isEnabled == "true") setToggle(true);
    }
  }, [property]);

  const handleSwitchOn = () => {
    const params = {
      bookingEngineURL,
      appFeature: "Kiosk",
    };

    props.dispatch(editProperty(currentSpace.propertyID, params));

    setEdit(true);
    setToggle(true);
    setForm({ ...form, bookingEngineURL: mash(currentSpace.name) });
  };

  const handleSwitchOff = () => {
    const params = {
      bookingEngineURL: "",
      disableAppFeature: "Kiosk",
    };

    props.dispatch(editProperty(currentSpace.propertyID, params));

    setEdit(true);
    setToggle(false);
    setForm({ bookingEngineURL: "" });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${baseURI}/kiosk/${bookingEngineURL}`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const editorOnChangeHandler = (htmlData) => {
    setEdit(true);
    setForm({ ...form, htmlContent: htmlData });
  };

  const handleSave = () => {
    const data = {
      customPage: {
        text: form.htmlContent,
      },
    };
    const response = props.dispatch(
      saveKioskSettings(currentSpace.propertyID, data)
    );
    response.then((success) => {
      if (success) setUpdateSuccessful(true);
    });
  };

  const handleCloseError = () => {
    props.dispatch(resetError("EDIT_PROPERTY"));
    props.dispatch(resetError("FETCH_KIOSK_SETTINGS"));
    props.dispatch(resetError("SAVE_KIOSK_SETTINGS"));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Check-in Kiosk</div>
          <AccessHeaderAction moduleID={MODULE.KIOSK_CHECKIN.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {(loadingProperty || isLoading) && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        {!loadingProperty && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              {/* <div className={classes.firstRow}>
                            <div>
                                <img src="/images/icons/bulb.png" className={classes.bulb} />
                            </div>
                            <div className={classes.helperText}>
                                {t('automate.bookingEngineURL.definition')}
                            </div>
                        </div> */}

              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <div className={classes.title}>Check-in Kiosk</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={handleSwitchOff}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={handleSwitchOn}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {toggle && (
                <div className={classes.configSection}>
                  <div className={clsx(classes.row, classes.preAuth)}>
                    <div className={classes.title}>
                      {t("automate.kioskCheckin.viewKioskEngine")}
                    </div>

                    {bookingEngineURL && (
                      <div className={classes.linkContainer}>
                        <div className={classes.link}>
                          <a
                            href={`${baseURI}/kiosk/${bookingEngineURL}`}
                            target="_blank"
                          >
                            {`${baseURI}/kiosk/${bookingEngineURL}`}
                          </a>
                        </div>
                        <div
                          className={classes.copyButton}
                          onClick={() => copyToClipboard()}
                        >
                          copy
                        </div>
                      </div>
                    )}
                  </div>

                  {/* -----------------TEXT EDITOR---------------------- */}
                  <div className={classes.row}>
                    {displayEditor && (
                      <div className={classes.editorContainer}>
                        <TrixEditor
                          initialValue={form.htmlContent}
                          placeholder={""}
                          submitOnEnter={false}
                          enableOnChange={true}
                          handleSlateOnChange={editorOnChangeHandler}
                        />
                      </div>
                    )}
                  </div>

                  {edit && (
                    <div className={classes.buttonsContainer}>
                      <div className={classes.button} onClick={handleSave}>
                        Save
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key="top-center"
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message="Link copied to clipboard"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        {/* ------------- Snackbar error messages -------------- */}
        {updateSuccessful && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => setTimeout(setUpdateSuccessful(false))}
            autoHideDuration={2000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Update successful</span>}
          />
        )}

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => handleCloseError()}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, spaces, property, loading, errors } = state;
  return {
    currentSpaceID: dashboard.currentSpace,
    currentSpace: spaces[dashboard.currentSpace],
    property: property,
    bookingEngineURL: property.bookingEngineURL,
    loadingProperty: loading.LOAD_PROPERTY,

    isLoading:
      loading.EDIT_PROPERTY ||
      loading.FETCH_KIOSK_SETTINGS ||
      loading.SAVE_KIOSK_SETTINGS,

    errors:
      errors.EDIT_PROPERTY ||
      errors.FETCH_KIOSK_SETTINGS ||
      errors.SAVE_KIOSK_SETTINGS,
  };
};

export default withTranslation()(connect(mapStateToProps)(Kiosk));
