const GROUP = {
    LIVE: { NAME: 'Live' },
    BEDS: { NAME: 'Beds' },
    FRONTDESK: { NAME: 'Frontdesk' },
    TEAM: { NAME: 'Team' },
    REPORTS: { NAME: 'Reports' },
    AUTOMATE: { NAME: 'Automate' },
    RESERVATION: { NAME: 'Reservation' },
    HOSTELWORLD: { NAME: 'Hostelworld'},
}

const MODULE = {
    //______________________________
    //----------------LIVE
    TODAYS_PAYMENT: {
        ID: 'TODAYS_PAYMENT',
        NAME: "Today's Payment",
        GROUP: 'LIVE'
    },
    TODAYS_STATUS: {
        ID: 'TODAYS_STATUS',
        NAME: "Today's Status",
        GROUP: 'LIVE'
    },
    TODAYS_OCCUPANCY: {
        ID: 'TODAYS_OCCUPANCY',
        NAME: "Today's Occupancy",
        GROUP: 'LIVE'
    },
    LIVE_REVENUE: {
        ID: 'LIVE_REVENUE',
        NAME: "Revenue",
        GROUP: 'LIVE'
    },
    YOY_PERFORMANCE: {
        ID: 'YOY_PERFORMANCE',
        NAME: "YoY Performance",
        GROUP: 'LIVE'
    },
    AVAILABILITY_FORECAST: {
        ID: 'AVAILABILITY_FORECAST',
        NAME: "Availability Forecast",
        GROUP: 'LIVE'
    },
    TODAYS_ACTIVITY: {
        ID: 'TODAYS_ACTIVITY',
        NAME: "Today's Activity",
        GROUP: 'LIVE'
    },
    OCCUPANCY_FORECAST: {
        ID: 'OCCUPANCY_FORECAST',
        NAME: "Occupancy Forecast",
        GROUP: 'LIVE'
    },
    BOOKING_CHANNELS: {
        ID: 'BOOKING_CHANNELS',
        NAME: "Booking Channels",
        GROUP: 'LIVE'
    },
    FRONTDESK_TRANSACTIONS: {
        ID: 'FRONTDESK_TRANSACTIONS',
        NAME: "Frontdesk Transacitons",
        GROUP: 'LIVE'
    },
    //______________________________
    //--------------------BEDS
    CALENDAR: {
        ID: 'CALENDAR',
        NAME: 'Calendar',
        GROUP: 'BEDS'
    },
    RESERVATION: {
        ID: 'RESERVATION',
        NAME: 'Reservation',
        GROUP: 'BEDS'
    },
    RATES_AND_AVAILABILITY: {
        ID: 'RATES_AND_AVAILABILITY',
        NAME: 'Rates & Availability',
        GROUP: 'BEDS'
    },
    CHANNELS: {
        ID: 'CHANNELS',
        NAME: 'Channels',
        GROUP: 'BEDS'
    },
    ROOMS: {
        ID: 'ROOMS',
        NAME: 'Rooms',
        GROUP: 'BEDS'
    },
    TAXES: {
        ID: 'TAXES',
        NAME: 'Taxes',
        GROUP: 'BEDS'
    },
    ADD_ONS: {
        ID: 'ADD_ONS',
        NAME: 'Add Ons',
        GROUP: 'BEDS'
    },
    FLOOR_PLAN: {
        ID: 'FLOOR_PLAN',
        NAME: 'Floor Plan',
        GROUP: 'BEDS'
    },
    //______________________________
    //-----------------REPORTS
    REVENUE: {
        ID: 'REVENUE',
        NAME: 'Revenue',
        GROUP: 'REPORTS'
    },
    OCCUPANCY: {
        ID: 'OCCUPANCY',
        NAME: 'Occupancy',
        GROUP: 'REPORTS'
    },
    NO_SHOWS: {
        ID: 'NO_SHOWS',
        NAME: 'No Show',
        GROUP: 'REPORTS'
    },
    CANCELLATIONS: {
        ID: 'CANCELLATIONS',
        NAME: 'Cancellations',
        GROUP: 'REPORTS'
    },
    CASH_AUDIT: {
        ID: 'CASH_AUDIT',
        NAME: 'Cash Audit',
        GROUP: 'REPORTS'
    },
    TRANSACTIONS: {
        ID: 'TRANSACTIONS',
        NAME: 'Transactions',
        GROUP: 'REPORTS'
    },
    GUESTS: {
        ID: 'GUESTS',
        NAME: 'Guests',
        GROUP: 'REPORTS'
    },
    RESERVATIONS: {
        ID: 'RESERVATIONS',
        NAME: 'Reservations',
        GROUP: 'REPORTS'
    },
    BOOKING_SOURCE: {
        ID: 'BOOKING_SOURCE',
        NAME: 'Booking Source',
        GROUP: 'REPORTS'
    },
    BOOKING_METRICS: {
        ID: 'BOOKING_METRICS',
        NAME: 'Booking Metrics',
        GROUP: 'REPORTS'
    },
    HOUSEKEEPING: {
        ID: 'HOUSEKEEPING',
        NAME: 'Housekeeping',
        GROUP: 'REPORTS'
    },
    COMMISSIONS: {
        ID: 'COMMISSIONS',
        NAME: 'Commissions',
        GROUP: 'REPORTS'
    },
    PICKUP: {
        ID: 'PICKUP',
        NAME: 'Pickup',
        GROUP: 'REPORTS'
    },
    TAX: {
        ID: 'TAX',
        NAME: 'Tax',
        GROUP: 'REPORTS'
    },
    //______________________________
    //----------------AUTOMATE
    BOOKING_ENGINE: {
        ID: 'BOOKING_ENGINE',
        NAME: 'Booking Engine',
        GROUP: 'AUTOMATE'
    },
    EMAIL: {
        ID: 'EMAIL',
        NAME: 'Email',
        GROUP: 'AUTOMATE'
    },
    PAYMENTS: {
        ID: 'PAYMENTS',
        NAME: 'Payments',
        GROUP: 'AUTOMATE'
    },
    GOKI: {
        ID: 'GOKI',
        NAME: 'Goki',
        GROUP: 'AUTOMATE'
    },
    MAILCHIMP: {
        ID: 'MAILCHIMP',
        NAME: 'Mailchimp',
        GROUP: 'AUTOMATE'
    },
    QUICKBOOKS: {
        ID: 'QUICKBOOKS',
        NAME: 'Quickbooks',
        GROUP: 'AUTOMATE'
    },
    ZAPIER: {
        ID: 'ZAPIER',
        NAME: 'Zapier',
        GROUP: 'AUTOMATE'
    },
    SIBA: {
        ID: 'SIBA',
        NAME: 'Siba',
        GROUP: 'AUTOMATE'
    },
    ONLINE_CHECKIN: {
        ID: 'ONLINE_CHECKIN',
        NAME: 'Online-Checkin',
        GROUP: 'AUTOMATE'
    },
    KIOSK_CHECKIN: {
        ID: 'KIOSK_CHECKIN',
        NAME: 'Kiosk-Checkin',
        GROUP: 'AUTOMATE'
    },
    ODOO: {
        ID: 'ODOO',
        NAME: 'Odoo',
        GROUP: 'AUTOMATE'
    },
    PACE_RMS: {
        ID: 'PACE_RMS',
        NAME: 'Pace-RMS',
        GROUP: 'AUTOMATE'
    },
    MASTERWAY: {
        ID: 'MASTERWAY',
        NAME: 'Masterway',
        GROUP: 'AUTOMATE'
    },
    //______________________________
    //----------------FRONTDESK
    FRONTDESK_ACCESS: {
        ID: 'FRONTDESK_ACCESS',
        NAME: 'Frontdesk Access',
        GROUP: 'FRONTDESK'
    },
    CASH_DRAWERS: {
        ID: 'CASH_DRAWERS',
        NAME: 'Cash Drawers',
        GROUP: 'FRONTDESK'
    },
    CASH_ACCOUNTS: {
        ID: 'CASH_ACCOUNTS',
        NAME: 'Cash Accounts',
        GROUP: 'FRONTDESK'
    },
    UPSELL: {
        ID: 'UPSELL',
        NAME: 'Upsell',
        GROUP: 'FRONTDESK'
    },
    //______________________________
    //----------------TEAM
    TODO_LISTS: {
        ID: 'TODO_LISTS',
        NAME: 'To Do Lists',
        GROUP: 'TEAM'
    },
    FOLDERS: {
        ID: 'FOLDERS',
        NAME: 'Folders',
        GROUP: 'TEAM'
    },

    //---------------RESERVATION (EDIT RESERVATION MODAL)..
    VIEW_CARD: {
        ID: 'VIEW_CARD',
        NAME: 'View card',
        GROUP: 'RESERVATION'
    },
    VIEW_CARD_CVV: {
        ID: 'VIEW_CARD_CVV',
        NAME: 'View card CVV',
        GROUP: 'RESERVATION'
    },
    ADD_PAST_PAYMENTS: {
        ID: 'ADD_PAST_PAYMENTS',
        NAME: 'Add Payment for Custom date (Past/Future)',
        GROUP: 'RESERVATION'
    },
    DELETE_TRANSACTION: {
        ID: 'DELETE_TRANSACTION',
        NAME: 'Delete transaction',
        GROUP: 'RESERVATION'
    },
    CHARGE_CARDS_VIA_STRIPE: {
        ID: 'CHARGE_CARDS_VIA_STRIPE',
        NAME: 'Charge cards via stripe',
        GROUP: 'RESERVATION'
    },
    STRIPE_REFUND: {
        ID: 'STRIPE_REFUND',
        NAME: 'Stripe refund',
        GROUP: 'RESERVATION'
    },

    //---------------HOSTEL WORLD (CHANNELS MODULE)..
    HW_PROPERTY_SETTINGS: {
        ID: 'HW_PROPERTY_SETTINGS',
        NAME: 'Property settings',
        GROUP: 'HOSTELWORLD'
    },
    HW_PROMOTIONS: {
        ID: 'HW_PROMOTIONS',
        NAME: 'Promotions',
        GROUP: 'HOSTELWORLD'
    },
    HW_TAXES: {
        ID: 'HW_TAXES',
        NAME: 'Taxes',
        GROUP: 'HOSTELWORLD'
    },
    HW_ROOMTYPES: {
        ID: 'HW_ROOMTYPES',
        NAME: 'Room types',
        GROUP: 'HOSTELWORLD'
    },
    HW_MICROSITE_CONTENT: {
        ID: 'HW_MICROSITE_CONTENT',
        NAME: 'Microsite content',
        GROUP: 'HOSTELWORLD'
    },
    HW_RATES: {
        ID: 'HW_RATES',
        NAME: 'Rates and availability',
        GROUP: 'HOSTELWORLD'
    },
    HW_INVOICES: {
        ID: 'HW_INVOICES',
        NAME: 'VAT',
        GROUP: 'HOSTELWORLD'
    },
    HW_MAPPING: {
        ID: 'HW_MAPPING',
        NAME: 'Mapping',
        GROUP: 'HOSTELWORLD'
    },
    HW_BOOKINGS: {
        ID: 'HW_BOOKINGS',
        NAME: 'Bookings',
        GROUP: 'HOSTELWORLD'
    },
    HW_REVIEWS: {
        ID: 'HW_REVIEWS',
        NAME: 'Reviews',
        GROUP: 'HOSTELWORLD'
    },
    HW_SUMMARY_STATS: {
        ID: 'HW_SUMMARY_STATS',
        NAME: 'Summary stats',
        GROUP: 'HOSTELWORLD'
    },
}

module.exports = { GROUP, MODULE }