import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import clsx from "clsx";
import { withTranslation } from "react-i18next";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import AccessHeaderAction from "../permissions/AcessHeaderAction";

import { fetchCurrencyFormat } from "../../utils/utility";
import { fetchTodaysActivity } from "../../redux/actions/dashboard/live";

// import GeoMap from '../charts/GeoMap';
import RevenueLine from "../live/RevenueLine";
import CalendarChart from "../live/CalendarChart";
import ChannelPie from "../live/ChannelPie";
import CustomerList from "../live/CustomerList";
import RevenueMetricsWidget from "../live/RevenueMetricsWidget";
import PaymentsWaffle from "../live/PaymentsWaffle";
import LiveSidebar from "../live/liveSidebar";
import { calculateTotal } from "../../redux/selectors/live/live";
import CustomLegend from "../live/CustomLegend";
import TodaysOccupancy from "../live/TodaysOccupancy";
import TodaysActivity from "../live/TodaysActivity";
import OccupancyForecast from "../live/OccupancyForecast";
import AvailabilityForecast from "../live/AvailabilityForecast";

import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { fetchProperty } from "../../redux/actions/dashboard/property";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    // height: 'calc(100vh - 80px)',
    background: "#F8F9FB",
    paddingTop: 0,
    paddingRight: "40px",
    // marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
    },
  },
  root: {
    background: "transparent",
    margin: "0 auto",
    maxWidth: "1100px",
    padding: "0 40px",
    width: "calc(100% - 80px)",
    overflowY: "auto",
    paddingTop: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
      paddingTop: "20px",
      width: "calc(100% - 40px)",
    },
  },
  mainContainer: {
    // overflowY: 'auto',
    // height: '100%',
    // paddingRight: '17px',
    // boxSizing: 'content-box',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
      marginTop: 20,
    },
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    position: "relative",
    zIndex: 1,
  },
  header: {
    alignItems: "center",
    background: "#transparent",
    color: "#828282",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
  },
  cardTitle: {
    marginLeft: 0,
    verticalAlign: "middle",
    width: "100%",
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  body: {
    borderRadius: "10px",
    minHeight: 250,
    height: 250,
    overflow: "hidden",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      minHeight: 200,
    },
    "& img": {
      cursor: "pointer",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  map: {
    height: 360,
  },
  table: {
    borderRadius: 0,
  },
  today: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 80,
  },
  payments: {
    height: "234px",
    minHeight: "234px",
    paddingBottom: theme.spacing(2),
  },
  calendar: {
    height: 200,
  },
  accessInfo: {
    marginTop: "unset",
    marginRight: "5px",
    justifyContent: "flex-end",
    '& [data="action-btn"]': {
      display: "none",
    },
    '& [data="access-user-avatar"]': {
      width: "25px",
      height: "25px",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  teamSidebar: {
    width: "50%",
    position: "relative",
    paddingTop: "20px",
    overflowY: "auto",
    [theme.breakpoints.up("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  last: {
    marginBottom: 80,
  },
  bookingSourcePie: {
    height: "315px",
  },
}));

const LivePage = (props) => {
  const { t } = props;

  const classes = useStyles();

  const {
    total,
    live,
    currentSpace,
    currentSpaceID,
    loadingLive,
    uiSettings,
    property,
  } = props;

  React.useEffect(() => {
    if (uiSettings && uiSettings.language)
      props.i18n.changeLanguage(uiSettings.language);
  }, [uiSettings]);

  React.useEffect(() => {
    if (!property?.code && currentSpace?.propertyID) {
      props.dispatch(fetchProperty(currentSpace?.propertyID));
    }
  }, [currentSpace?.propertyID]);

  let cash = 0,
    card = 0,
    unpaid = 0,
    transactions = [],
    currency = "";

  if (!loadingLive && currentSpaceID && currentSpace && live[currentSpaceID]) {
    const today = live[currentSpaceID].todaysPayments || [];
    cash = today.find((e) => e.type === "cash")
      ? Math.round(today.find((e) => e.type === "cash").amount)
      : 0;
    card = today.find((e) => e.type === "card")
      ? Math.round(today.find((e) => e.type === "card").amount)
      : 0;
    unpaid = today.find((e) => e.type === "unPaid")
      ? Math.round(today.find((e) => e.type === "unPaid").amount)
      : 0;

    currency = fetchCurrencyFormat(
      currentSpace.currency ? currentSpace.currency : null
    );
    if (live[currentSpaceID].transactions) {
      transactions = live[currentSpaceID].transactions.map((item) => {
        return {
          name: item.reservationID ? item.reservationID.guestName : "",
          amount: item.amount,
          modeID: item.modeID,
          createdAt: item.createdAt,
          resID: item.reservationID ? item.reservationID._id : "",
          _id: item._id,
        };
      });
    }
  }

  return (
    <div className={clsx(classes.main, "mainScrollHeight")}>
      <div className={clsx(classes.root, "hideScrollBar")}>
        {/* PAYMENTS WIDGET */}

        <Grid
          container
          spacing={5}
          className={classes.mainContainer}
          data-intercom-target={"Payment Snapshot"}
        >
          <AccessController requiredPermission={MODULE.TODAYS_PAYMENT.ID}>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.container}>
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.paymentHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.TODAYS_PAYMENT.ID}
                    maxDisplayLimit={3}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <div
                  className={clsx(
                    classes.body,
                    classes.today,
                    classes.payments
                  )}
                >
                  {currentSpace && !loadingLive && (
                    <PaymentsWaffle
                      total={total}
                      cash={cash}
                      card={card}
                      unpaid={unpaid}
                      currency={currency}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </AccessController>

          {/* OCCUPANCY WIDGET */}

          <AccessController requiredPermission={MODULE.TODAYS_OCCUPANCY.ID}>
            <Grid item xs={12} md={6} lg={6}>
              <div
                className={classes.container}
                data-intercom-target={"Occupancy Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.occupancyHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.TODAYS_OCCUPANCY.ID}
                    maxDisplayLimit={3}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <TodaysOccupancy />
              </div>
            </Grid>
          </AccessController>

          <AccessController requiredPermission={MODULE.TODAYS_ACTIVITY.ID}>
            <Grid item xs={12} sm={12}>
              <div
                className={classes.container}
                data-intercom-target={"Revenue Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      Today's Activity
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.TODAYS_ACTIVITY.ID}
                    maxDisplayLimit={4}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <TodaysActivity />
              </div>
            </Grid>
          </AccessController>

          <RevenueMetricsWidget />

          <AccessController requiredPermission={MODULE.YOY_PERFORMANCE.ID}>
            <Grid item xs={12} sm={12}>
              <div
                className={classes.container}
                data-intercom-target={"Revenue Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.yoyHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.YOY_PERFORMANCE.ID}
                    maxDisplayLimit={4}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <div className={clsx(classes.body, classes.map)}>
                  {currentSpace && !loadingLive && <RevenueLine />}
                </div>
              </div>
            </Grid>
          </AccessController>

          <AccessController requiredPermission={MODULE.OCCUPANCY_FORECAST.ID}>
            <Grid item xs={12} sm={12}>
              <div
                className={classes.container}
                data-intercom-target={"Revenue Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.fwdOccupancyHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.OCCUPANCY_FORECAST.ID}
                    maxDisplayLimit={4}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <div>
                  <OccupancyForecast />
                </div>
              </div>
            </Grid>
          </AccessController>

          <AccessController requiredPermission={MODULE.BOOKING_CHANNELS.ID}>
            <Grid item xs={12} sm={12} lg={6}>
              <div
                className={classes.container}
                data-intercom-target={"Booking Sources"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.bookingChannelsHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.BOOKING_CHANNELS.ID}
                    maxDisplayLimit={3}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <div
                  className={clsx(
                    classes.body,
                    classes.map,
                    classes.bookingSourcePie
                  )}
                >
                  {currentSpace && !loadingLive && <ChannelPie />}
                </div>
                <CustomLegend />
              </div>
            </Grid>
          </AccessController>

          <AccessController
            requiredPermission={MODULE.FRONTDESK_TRANSACTIONS.ID}
          >
            <Grid item xs={12} sm={12} lg={6}>
              <div
                className={classes.container}
                data-intercom-target={"Transaction Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.frontdeskTransHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.FRONTDESK_TRANSACTIONS.ID}
                    maxDisplayLimit={3}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <div className={clsx(classes.body, classes.map, classes.table)}>
                  {currentSpace && !loadingLive && (
                    <CustomerList
                      transactions={transactions}
                      currencyData={{
                        code: currentSpace.currency,
                        country: currentSpace.country,
                      }}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </AccessController>

          <AccessController
            requiredPermission={MODULE.AVAILABILITY_FORECAST.ID}
          >
            <Grid item xs={12} sm={12}>
              <div
                className={clsx(classes.container, classes.last)}
                data-intercom-target={"Revenue Snapshot"}
              >
                <div className={classes.header}>
                  <div className={classes.cardTitle}>
                    <Typography component="h4" variant="h6">
                      {t("live.availabilityHeader")}
                    </Typography>
                  </div>
                  <AccessHeaderAction
                    className={classes.accessInfo}
                    moduleID={MODULE.AVAILABILITY_FORECAST.ID}
                    maxDisplayLimit={4}
                    loadAcessOnModalOpen={true}
                  />
                </div>
                <AvailabilityForecast />
              </div>
            </Grid>
          </AccessController>

          {/* <Grid item sm={12}>
            <div className={classes.container}>
              <div className={classes.header}>
                <div className={classes.cardTitle}>
                  <Typography component="h4" variant="h6">Annual Occupancy</Typography>
                </div>
              </div>
              <div className={clsx(classes.body, classes.map, classes.calendar)}>
                <CalendarChart />
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
      <div className={clsx(classes.teamSidebar, "hideScrollBar")}>
        <LiveSidebar />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { spaces, dashboard, userUIsettings, property } = state;
  const currentSpace = spaces[dashboard.currentSpace];

  return {
    currentSpaceID: dashboard.currentSpace,
    currentSpace,
    loadingLive: state.loading.LOAD_LIVE,
    live: state.live,
    total: calculateTotal(state.dashboard.currentSpace, state.live),
    uiSettings: userUIsettings[dashboard.currentSpace],
    property,
  };
};

export default withTranslation()(connect(mapStateToProps)(LivePage));
