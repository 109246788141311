import React from 'react';
import { ResponsiveLine } from '@nivo/line';


const commonProperties = {
  width: 900,
  height: 400,
  margin: { top: 20, right: 20, bottom: 60, left: 80 },
  animate: true,
  enableSlices: 'x',
}

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
      {/* <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} /> */}
      <circle
          r={size / 3}
          strokeWidth={borderWidth}
          stroke={borderColor}
          fill={borderColor}
          fillOpacity={1}
      />
  </g>
)

const SingleLineChart = (props) => {
  
  const { data, max } = props;
  
  return (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 0, max: max, stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            enable: true,
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legendOffset: 36,
            legendPosition: 'start'
        }}
        axisLeft={{
            enable: true,
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#999999"
                }
              }
            }
        }}
        areaBlendMode="screen"
        colors={{ datum: 'color' }}
        curve="monotoneX"
        enableArea={false}
        // enableCrosshair={false}
        enableGridX={false}
        enableGridY={false}
        enableSlices="x"
        pointSize={10}
        pointSymbol={CustomSymbol}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
  )
}

export default SingleLineChart