import React, { forwardRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CloseIcon from '@material-ui/icons/Close';

import { returnCurrent } from '../../redux/selectors/dashboard';

import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  CircularProgress,
  Snackbar,
  DialogActions
} from '@material-ui/core';
import { fetchDateFormat, DATE_FORMATS } from '../../utils/utility';
import { ERRORS } from '../../utils/errors';
import { createRoomBlock, resetError } from '../../redux/actions/reservation/editReservation';
import SelectRoomTypes from '../reservations/new-reservation/SelectRoomTypes';
import Navigation from '../common/Navigation';
import RoomBlock from './RoomBlock';
import Breadcrumb from '../common/Breadcrumb';
// import { ICONS } from '../../utils/imageUrls';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
    },
  },
  root: {
    borderRadius: '15px',
    width: '100%'
  },
  dialogTitle: {
    fontSize: "22px",
    fontWeight: 600
  },
  dialogContent: {
    overflow: 'visible',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
    }
  },
  error: {
    color: 'red',
    marginTop: 20,
    marginRight: 5
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  dialogAction: {
    padding: '8px 30px 30px 30px'
  },
  Button: {
    background: '#000',
    color: '#fff',
    width: 100,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
}))

const AddRoomBlock = forwardRef((props, ref) => {

  const classes = useStyles();
  const { open, closeRoomBlock, roomTypes, rooms, currentSpace, beds, t } = props;

  const STEPS = {
    1: t('actions.next'),
    2: t('actions.save')
  }
  const [breadcrumbList, setBreadcrumbList] = React.useState({});
  const [step, setStep] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [bedList, setBedList] = React.useState([]);
  const [roomTypeList, setRoomTypeList] = React.useState([]);
  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [buttonText, setButtonText] = React.useState(t('actions.next'));


  const roomRef = React.createRef();
  const blockRef = React.createRef();
  let dialogContentNode = null;


  // ----- Check for required fields validation -----
  const handleFormvalidation = (blocks, blockData) => {
    let hasError = false;
    if (
      !blocks.length ||
      !blockData.type ||
      !blockData.label ||
      !blockData.category ||
      !blockData.dates.startDate ||
      !blockData.dates.endDate
    )
      hasError = true;
    return hasError;
  }

  // -------- Submit room block data -----------
  const handleSubmit = (blockData) => {
    setError(false);
    let roomID = '';
    let bedBlocks = blockData.tab === 'room' ? [{ roomTypeID: blockData.type }] : blockData.tab === 'bed' ? blockData.roomBeds : [];
    const hasError = handleFormvalidation(bedBlocks, blockData);
    if (hasError) {
      setError(true);
      setErrorMessage(t('bookingCalendar.completionError'))
      dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return false;
    }
    let data = {
      customer: {
        firstName: blockData.label,
        lastName: "",
        gender: "N/A",
      },
      reservation: {
        isBlock: true,
        category: blockData.category,
        propertyID: currentSpace.propertyID,
        checkIn: fetchDateFormat(blockData.dates.startDate, DATE_FORMATS.DEFAULT_DATE),
        checkOut: fetchDateFormat(blockData.dates.endDate, DATE_FORMATS.DEFAULT_DATE),
      },
      allocation: [],
      roomID
    };

    if (bedBlocks.length) {
      data.allocation = bedBlocks.map(bed => {
        let bedId = bed.value || bed;
        return {
          blockSize: 1,
          blocks: [{
            propertyID: currentSpace.propertyID,
            startDate: fetchDateFormat(blockData.dates.startDate, DATE_FORMATS.DEFAULT_DATE),
            endDate: fetchDateFormat(blockData.dates.endDate, DATE_FORMATS.DEFAULT_DATE),
            roomTypeID: bed.roomTypeID || (beds[bedId] && beds[bedId].roomTypeID),
            ...(blockData.tab === 'bed' && { bedID: bedId}),
          }]
        }
      })
    }
    data = { ...data, ...(blockData.tab === 'room' && { roomID: selectedRoom }) }
    // console.log("data", JSON.stringify(data))
    props.dispatch(createRoomBlock(data, currentSpace.propertyID, props.calendarData))
  }

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetError('ADD_RESERVATION'))
  }

  // -------- Build a list of beds of a room -----------
  const handleSelectRoom = (roomID, roomTypeBeds) => {
    let list = roomTypeBeds.map(bedID => {
      if (beds[bedID] && beds[bedID].roomID === roomID) {
        return { value: bedID, label: beds[bedID].name, type: beds[bedID].roomTypeID }
      }
    }).filter(data => data)
    
    let roomTypeList = props?.rooms[roomID]?.mapping?.filter(data => roomTypes[data.roomTypeID].isActive).map(item => {
      return { value: item.roomTypeID, label: roomTypes[item.roomTypeID].name }
    }) || [];

    setSelectedRoom(roomID);
    setBedList(list)
    setRoomTypeList(roomTypeList)
  }

  // -------- Go to previous step ------------
  const handlePrev = (currentStep) => {
    let textOfButton = buttonText;
    switch (currentStep) {
      case 1: textOfButton = 'Next';
        break;
    }
    setError(false);
    setErrorMessage('');
    setStep(currentStep);
    setButtonText(textOfButton);
  }

  // ----------- Go to next step -------------
  const handleNext = () => {
    let hasError = false;
    let roomData = {};
    let block = {};
    let currentStep = step;
    let breadcrumbList = { ...breadcrumbList };

    switch (step) {
      case 1: roomData = roomRef.current.handleProcessData();
        if (roomData && roomData._id && roomData.beds) {
          handleSelectRoom(roomData._id, roomData.beds)
          if (roomTypes[roomData.primaryRoomTypeID]) {
            let icon;
            if (roomTypes[roomData.primaryRoomTypeID].dormOrPrivate === 'private') icon = IMAGES.ICONS.darkBed;
            else icon = IMAGES.ICONS.darkHostel;
            breadcrumbList = {};
            breadcrumbList[1] = { step: 1, text: roomData.name, icon: icon }
          }
        } else if (roomData && !roomData.beds) {
          hasError = true;
          setError(true);
          setErrorMessage(t('bookingCalendar.noBedsError'));
        } else if (!roomData) {
          hasError = true;
          setError(true);
          setErrorMessage(t('bookingCalendar.selectionError'));
        }
        break;
      case 2: block = blockRef.current.handleProcessData();
        handleSubmit(block)
        break;
      default: ;
    }
    if (!hasError && step !== 2) {
      currentStep++;
      setStep(currentStep);
      setButtonText(STEPS[currentStep])
      setError(false);
      setErrorMessage('');
      setBreadcrumbList(breadcrumbList)
    };
    dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Dialog
      open={open}
      onClose={closeRoomBlock}
      className={classes.dialogContainer}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901} ref={ref}>

      <div className={classes.root}>
        <CloseIcon className={classes.closeIcon} onClick={props.closeRoomBlock} />

        <DialogContent>
          <div ref={node => { dialogContentNode = node; }}>
            <DialogContent className={classes.dialogContent}>
              {/* ------------ Navigating back to previous screen and show title ------------ */}
              <Navigation step={step} title={t('bookingCalendar.roomBlock')} onChangePrev={handlePrev} />
              {error && (<div className={classes.error}>{errorMessage}</div>)}
              {step > 1 && (<Breadcrumb list={breadcrumbList} onChangePrev={handlePrev} />)}

              {/* ---------- Form data --------------  */}
              {step === 1 && <SelectRoomTypes currentSpace={currentSpace} roomTypes={roomTypes} rooms={rooms} itemName={'rooms'} ref={roomRef} multiple={false} />}
              {step === 2 && <RoomBlock currentSpace={currentSpace} bedList={bedList} roomTypeList = {roomTypeList} ref={blockRef} multiple={false} />}
            </DialogContent>
          </div>
        </DialogContent>

        {/* ------------ Action Button ------------ */}
        <DialogActions className={classes.dialogAction}>
          <Button className={classes.Button} onClick={() => handleNext()}>
            {buttonText}
          </Button>
        </DialogActions>
      </div>

      {/* ------------- Loder -------------- */}
      {props.loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleCloseError()}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{ERRORS[props.errors] || props.errors}</span>}
        />
      )}
    </Dialog>
  )
})

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  beds: state.beds,
  errors: state.errors.ADD_RESERVATION,
  loading: state.loading.ADD_RESERVATION,
  rooms: state.rooms
})

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps)(AddRoomBlock));