import React, { useState } from 'react';
import { useDispatch  } from 'react-redux';
import { getReservationDetails, resetReservationDetails } from '../../redux/actions/beds/beds';
import { resetError } from '../../redux/actions/reservation/editReservation';
import { resetReservationManualOptions } from '../../redux/actions/rooms/rooms';
import Reservation from '../reservations/Reservation';

const ViewReservationHOC = Component => props =>{

    const dispatch = useDispatch();
    const [isResvOpen, setIsResvOpen] = useState(false);

    const openReservationHandler = resvID =>{
        dispatch(getReservationDetails(resvID));
        setIsResvOpen(true);
    }

    const closeReservationHandler = () => {
        dispatch(resetError('CANCEL_RESERVATION'));
        dispatch(resetReservationDetails());
        dispatch(resetReservationManualOptions());
        setIsResvOpen(false);
    }

    return (
        <>
            {props.isOpen && (
                <Component 
                    openReservationHandler={openReservationHandler}
                    {...props}
                />
            )}

            {isResvOpen && (
                <Reservation
                    open={isResvOpen}
                    handleCloseReservation={closeReservationHandler}
                />
            )}
        </>
    )
}

export default ViewReservationHOC;
