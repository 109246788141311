import React, { Component } from "react";
import Parser from "html-react-parser";
import { connect } from "react-redux";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { subscriptionPrices } from "./constants";
import { subscribeToPlan } from "../../redux/actions/subscriptions/subscriptionUpgrade";
import ManageSubscription from "./ManageSubcription";
import { CHARGEBEE_CB_SITE } from "../../config";

const styles = (theme) => ({
  root: {
    paddingTop: 80,
    display: "flex",
    justifyContent: "center",
    paddingBottom: 120,
  },
  container: {
    maxWidth: "1200px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  headerContainer: {
    width: "100%",
  },
  title: {
    fontSize: "40px",
    maxWidth: 400,
  },
  navBar: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
    marginTop: -20,
  },
  pillContainer: {
    background: "#FFFFFF",
    borderRadius: 50,
    display: "flex",
    padding: 8,
  },
  pill: {
    color: "#808080",
    cursor: "pointer",
    fontSize: 20,
    padding: "8px 20px",
  },
  selected: {
    borderRadius: 30,
    color: "white",
    background: "#000000",
  },
  cardContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    boxShadow: "0 10px 2px 0 #F0F0F0",
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
  },
  plan: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      marginRight: 20,
    },
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    minHeight: 60,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  header: {
    fontSize: "40px",
    marginBottom: 10,
  },
  description: {
    color: "#454545",
    fontSize: "16px",
    marginBottom: 40,
    minHeight: 70,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      width: "80%",
    },
  },
  startingFrom: {
    color: "#454545",
    fontSize: "14px",
    minHeight: 20,
  },
  price: {
    fontSize: "80px",
    lineHeight: "120%",
  },
  month: {
    color: "#454545",
    fontSize: "14px",
    minHeight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 5,
      minHeight: 0,
    },
  },
  link: {
    cursor: "pointer",
    fontSize: "12px",
  },
  button: {
    border: "2px solid #BDBDBD",
    borderRadius: "30px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: 40,
    padding: 15,
    textAlign: "center",
    width: "calc(100% - 30px)",
    transition: "all 0.15s ease-in-out 0s",
    transform: "perspective(1000px) translateZ(0px)",
    display: "block",
  },
  buttonCurrent: {
    color: "#BDBDBD",
    borderColor: "#BDBDBD",
    cursor: "auto",
  },
  buttonPlus: {
    color: "#7D00FE",
    borderColor: "#7D00FE",
    "&:hover": {
      color: "white",
      background: "#7D00FE",
      transform: "perspective(1000px) translateZ(40px)",
    },
  },
  buttonPro: {
    color: "#1CAB58",
    borderColor: "#1CAB58",
    "&:hover": {
      color: "white",
      background: "#1CAB58",
      transform: "perspective(1000px) translateZ(40px)",
    },
  },
  featureList: {
    marginTop: 20,
    marginBottom: 30,
  },
  feature: {
    marginBottom: 20,
  },
  featureName: {
    borderBottom: "1px dashed rgb(221, 221, 221)",
    color: "#111111",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    cursor: "help",
  },
  tooltip: {
    padding: 10,
  },
  featureDescription: {
    color: "white",
    fontSize: "14px",
    lineHeight: 1.4,
  },
  subText: {
    marginTop: 10,
  },
  free: {
    "& span": {
      borderBottom: "2px solid #FF894F",
      color: "#FF894F",
      fontWeight: 600,
    },
  },
  basic: {
    "& span": {
      borderBottom: "2px solid #7D00FE",
      color: "#7D00FE",
      fontWeight: 600,
    },
  },
  plus: {
    "& span": {
      borderBottom: "2px solid #1CAB58",
      color: "#1CAB58",
      fontWeight: 600,
    },
  },
  bold: {
    color: "red",
  },
});

const PLAN_IDS = {
  free: "free",
  plus: "plus",
  pro: "pro",
};

const PLANS = [
  {
    id: 2,
    name: "Plus",
    description:
      "Put your business to work. Automate payments and collect direct bookings.",
    monthlyPrice: `€${subscriptionPrices.plusMonthlyPrice}`,
    color: "#7D00FE",
    subText: "",
    planID: PLAN_IDS.plus,
  },
  {
    id: 3,
    name: "Pro",
    description:
      "Sell more. Turbo charge your online distribution and grow your team.",
    monthlyPrice: `€${subscriptionPrices.proMonthlyPrice}`,
    color: "#1CAB58",
    subText: "Everything in PLUS and",
    planID: PLAN_IDS.pro,
  },
];

const FEATURES = {
  2: [
    {
      id: "2-1",
      name: "Unlimited beds and reservations",
      description:
        "Manage all your rooms in a simple, easy-to-use calendar which allows you to drag and drop reservations",
    },
    {
      id: "2-2",
      name: "All core PMS features",
      description:
        "Edit reservations, log payment transactions, occupancy reports, create rate plans, set prices and so much more.. ",
    },
    {
      id: "2-3",
      name: `Invite your team - <span>up to 5 users</span>`,
      description:
        "Invite other people to collaborate with you on your Counter property",
    },
    {
      id: "2-4",
      name: `Auto-sync reservations - <span>up to 3 channels</span>`,
      description:
        "Eliminate overbookings by connecting your property directly to multiple OTAs to automatically receive bookings, modifications, cancellations and to keep your inventory in sync",
      subText:
        "We currently support Hostelworld, Booking.com, Airbnb and Expedia. Google, Agoda & Trip.com coming soon.",
    },
    {
      id: "2-5",
      name: "Commission-free booking engine",
      description:
        "Reduce your OTA commission expenses by channeling loyal customers to book directly on your own website",
    },
    {
      id: "2-6",
      name: "Automated payment processing",
      description:
        "Automatically charge Non-Refundable Rates, no-shows and cancellations. Receive a daily payment failure report via email.",
      subText:
        "Reduce manual work with rule based charging. Eliminate costly human errors and increase your collection rate.",
    },
    {
      id: "2-7",
      name: "Rule-based email templates",
      description:
        "Configure email templates that can be triggered when guests make a reservation, check-out or at some other stage of their journey.",
      subText: "Reduce manual work and ensure a consistent guest experience.",
    },
    {
      id: "2-8",
      name: "Cash Audit",
      description:
        "Cash count at the beginning of shift and end of shift allows the manager to be alerted if there have been any transaction errors during the shift.",
      subText: "This is a powerful tool to prevent fraud.",
    },
    {
      id: "2-9",
      name: "Online check-in",
      description:
        "Automatically send your guests an online check-in link which enables them to enter their personal information, upload their ID, accept terms & conditions and complete payment.",
      subText:
        "Going 100% contactless allows your guests to feel safer during their stay.",
    },
  ],
  3: [
    {
      id: "3-1",
      name: `Invite your team - <span>up to 10 users</span>`,
      description:
        "Invite other people to collaborate with you on your Counter property",
    },
    {
      id: "3-2",
      name: `Auto-sync reservations - <span>up to 5 channels</span>`,
      description:
        "Eliminate overbookings by connecting directly to multiple OTAs to automatically receive bookings, modifications, cancellations and to keep your inventory in sync",
      subText:
        "We currently support Hostelworld, Booking.com, Airbnb and Expedia. Google, Agoda & Trip.com coming soon.",
    },
    {
      id: "3-3",
      name: "Connect a Channel Manager",
      description:
        "To access a wider range of regional OTAs, connect to an external channel manager.",
      subText:
        "Counter currently connects to Siteminder, myAllocator and wuBook.",
    },
    {
      id: "3-4",
      name: "Dedicated Onboarding Support.",
      description:
        "During configuration phase, we'll guide you to discover Counter's features.",
      subText: "Get the most out of this PMS with our assistance.",
    },
    {
      id: "3-5",
      name: "Personalized one-to-one Video call.",
      description:
        "Book a call with one of our experts to explore the tools we offer.",
      subText: "Have an advice tailored for your property and unique reality.",
    },
  ],
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333333",
    color: "white",
    maxWidth: 250,
    fontSize: "12px",
    opacity: "0.7",
    borderRadius: 10,
  },
}))(Tooltip);

class SubscriptionPlans extends Component {
  state = {
    showPortal: false,
  };

  componentDidMount() {
    this.initCargebee();
  }

  initCargebee = () => {
    const dataCbSite = CHARGEBEE_CB_SITE;

    Chargebee.inited
      ? Chargebee.registerAgain()
      : Chargebee.init({ site: dataCbSite });

    const cbInstance = Chargebee.getInstance();

    cbInstance.setCheckoutCallbacks((cart) => {
      const product = cart.products[0];
      const onboardingStatus = product.planId;

      return {
        loaded: function () {}, // checkout opened...
        close: () => {},
        success: (hostedPageId) => {
          // hostedPageId will be unique token for the checkout that happened.
          // Use hostedPageId with the hosted page api to get subscription details.
          // [hosted page api: https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page]

          cbInstance.closeAll();

          const data = { onboardingStatus, hostedPageId };
          this.subscriptionHandler(data);
        },
        step: function (value) {}, // value -> which step in checkout...
      };
    });
  };

  subscriptionHandler = (data) => {
    const { dispatch, handleNext, currentSpace } = this.props;
    dispatch(subscribeToPlan(currentSpace._id, data)).then((success) => {
      if (success) handleNext();
    });
  };

  getSubscribeBtn = (selectedPlanID) => {
    const { classes, currentSpace } = this.props;
    const { onboardingStatus = null } = currentSpace;
    const otherPlans = Object.keys(PLAN_IDS).filter(
      (id) => PLAN_IDS[id] !== selectedPlanID
    );

    switch (selectedPlanID) {
      //GET FREE PLAN BUTTON.............................
      case PLAN_IDS.free:
        const btnLabel = otherPlans.includes(onboardingStatus)
          ? "Contact to Switch"
          : "Current Plan";
        return (
          <div className={clsx(classes.button, classes.buttonCurrent)}>
            {btnLabel}
          </div>
        );

      //GET PLUS PLAN BUTTON.............................
      case PLAN_IDS.plus:
        const plusUpgradeBtn = (
          <a
            className={clsx(classes.button, classes.buttonPlus)}
            href="#"
            data-cb-type="checkout"
            data-cb-plan-id={selectedPlanID}
          >
            Upgrade
          </a>
        );

        // return plusUpgradeBtn;

        if (!onboardingStatus) return plusUpgradeBtn;

        if (otherPlans.includes(onboardingStatus)) {
          return {
            [PLAN_IDS.pro]: (
              <div className={clsx(classes.button, classes.buttonCurrent)}>
                Contact to Switch
              </div>
            ),
            [PLAN_IDS.free]: plusUpgradeBtn,
          }[onboardingStatus];
        }

        return (
          <div className={clsx(classes.button, classes.buttonCurrent)}>
            Current
          </div>
        );

      //GET PRO PLAN BUTTON.............................
      case PLAN_IDS.pro:
        const proUpgradeBtn = (
          <a
            className={clsx(classes.button, classes.buttonPro)}
            href="#"
            data-cb-type="checkout"
            data-cb-plan-id={selectedPlanID}
          >
            Upgrade
          </a>
        );

        // return proUpgradeBtn;

        if (!onboardingStatus) return proUpgradeBtn;

        if (otherPlans.includes(onboardingStatus)) {
          return onboardingStatus == PLAN_IDS.plus
            ? this.manageSubscriptionBtn()
            : proUpgradeBtn;
        }

        return (
          <div className={clsx(classes.button, classes.buttonCurrent)}>
            Current
          </div>
        );

      default:
        return (
          <div className={clsx(classes.button, classes.buttonCurrent)}>
            Contact to activate
          </div>
        );
    }
  };

  manageSubscriptionBtn = () => {
    const { classes } = this.props;
    return (
      <a
        className={clsx(classes.button, classes.buttonPro)}
        href="#"
        onClick={this.showManagePortal}
      >
        Upgrade
      </a>
    );
  };

  showManagePortal = () => {
    this.setState({ showPortal: true });
  };

  handleCloseManagePortal = () => {
    this.setState({ showPortal: false });
  };

  render() {
    const { classes } = this.props;
    const { showPortal } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid container spacing={4}>
            {PLANS.map((item) => (
              <Grid key={item.id} item sm={12} md={6}>
                <div className={classes.cardContainer}>
                  <div className={classes.priceContainer}>
                    <div className={classes.plan}>
                      <div
                        className={classes.header}
                        style={{ color: item.color }}
                      >
                        {item.name}
                      </div>
                      <div className={classes.description}>
                        {item.description}
                      </div>
                    </div>

                    <div className={classes.amount}>
                      <div className={classes.startingFrom}>
                        {item.id === 3 && "Starting from..."}
                      </div>
                      <div className={classes.price}>{item.monthlyPrice}</div>
                      <div className={classes.month}>
                        {item.id > 1 && "per month"}
                      </div>
                    </div>
                  </div>

                  {this.getSubscribeBtn(item.planID)}

                  <div className={classes.startingFrom}>{item.subText}</div>

                  <div className={classes.featureList}>
                    {FEATURES[item.id].map((feature) => (
                      <div key={feature.id} className={classes.feature}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <div className={classes.tooltip}>
                                <div className={classes.featureDescription}>
                                  {feature.description}
                                </div>
                                {feature.subText && (
                                  <div
                                    className={clsx(
                                      classes.featureDescription,
                                      classes.subText
                                    )}
                                  >
                                    {feature.subText}
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          }
                          arrow
                          placement="top"
                        >
                          <span
                            className={clsx({
                              [classes.featureName]: true,
                              [classes.free]: item.id === 1,
                              [classes.basic]: item.id === 2,
                              [classes.plus]: item.id === 3,
                            })}
                          >
                            {Parser(feature.name)}
                          </span>
                        </HtmlTooltip>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* ------------------MANAGE SUBSCRIPTIONS (SELF_SERVE PORTAL)---------------------- */}
        {showPortal && (
          <ManageSubscription
            closeManagePortal={this.handleCloseManagePortal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard } = state;
  return {
    currentSpace: spaces?.[dashboard.currentSpace] || {},
  };
};

export default withStyles(styles)(connect(mapStateToProps)(SubscriptionPlans));
