import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { returnCurrent } from '../../redux/selectors/dashboard';

import EditProduct from './new_product';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { fetchCurrencyFormat, getAmtFormat } from '../../utils/utility';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  productCategoryContainer: {
    // boxShadow: '0 3px 4px 0px #ECECEC',
    // boxShadow: '0 1px 30px 0 #ECECEC',
    // height: '100%',
    // width: 'calc(100% - 48px)',
    borderBottom: '1px solid #F0F0F0',
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      background: '#F8F9FB',
    }
  },
  settingsIcon: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 15,
      opacity: 0.5,
      width: 15,
    },
    '&:hover': {
      background: '#F8F9FB'
    }
  },
  nameContainer: {
    alignItems: 'center',
    display: 'flex',
    '& img': {
      height: 70,
      width: 70,
      objectFit: 'cover',
    }
  },
  productName: {
    display: 'flex',
    fontSize: '1.3rem',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  name: {
    fontSize: '1.3rem',
    marginLeft: 20,
  },
  taxContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  taxChip: {
    alignItems: 'center',
    background: '#E0E0E0',
    color: '#666666',
    display: 'flex',
    fontSize: '1.1rem',
    marginRight: 10
  },
}));

const ProductCategoryItem = (props) => {

  const classes = useStyles();
  const { productCategoryID, productID, productCategories, products, currentSpace, taxes, loadingTaxes } = props;
  const productCategory = productCategories[productCategoryID];
  const product = products[productID];
  const [editFlag, setEditFlag] = React.useState(false);
  const currency = fetchCurrencyFormat(currentSpace.currency || null);

  const handleEdit = () => {
    setEditFlag(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }

  const handleCancel = () => {
    setEditFlag(false)
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  return (
    <div className={classes.productCategoryContainer}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.nameContainer}>
              <img src={product.image || IMAGES.ICONS.posPlaceholder} />
              <div className={classes.name}>{product.name}</div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container alignItems="center" style={{height: '100%'}}>
              <Grid item xs={6} md={4}>
                <div className={classes.productName}>{productCategory.name}</div>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className={classes.productName}>{getAmtFormat(product.price, currency)}</div>
              </Grid>
              <Grid item xs={4} md={4}>
                <div className={classes.taxContainer}>
                  {!loadingTaxes && product.taxesApplicable.length!==0 && product.taxesApplicable.map(taxID => {
                    return taxes && taxes[taxID] && <Chip
                      key={taxID}
                      size="small"
                      label={taxes && taxes[taxID] && taxes[taxID].code ? taxes[taxID].code : taxes[taxID].name}
                      className={classes.taxChip}
                    />
                  })}
                </div>
              </Grid>
              <Grid item xs={4} md={1}>
                <div className={classes.settingsIcon} onClick={handleEdit}>
                  <img src={IMAGES.ICONS.settings} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {editFlag && 
          <EditProduct
            open={editFlag}
            productID={productID}
            handleCancel={handleCancel}
          />
        }

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  productCategories: state.productCategories,
  products: state.products,
  taxes: state.taxes,
  loadingTaxes: state.loading.LOAD_TAX_LIST
})

export default connect(mapStateToProps)(ProductCategoryItem);