import React, { Component, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import parse from "html-react-parser";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withTranslation } from "react-i18next";
import AccessController from "../../../permissions/AccessController";
import { MODULE } from "../../../../common/constants/permission";
import IMAGES from "../../../../constants/images";
import {
  fetchChannexSessionToken,
  fetchCardDetails,
  fetchViewCvvTokens,
} from "../../../../redux/actions/reservation/editReservation";
import CardCvvView from "./CardCvvView";
import usePermissions from "../../../permissions/UsePermissions";
import { isCardValid, parseCardDetails } from "./helper";
import { MAIL_SUPPORT } from "../../../../config/index";

const useStyles = makeStyles((theme) => ({
  cardInfoContainer: {
    background: "#8688BC",
    borderRadius: 10,
    color: "#FFFFFF",
    padding: 20,
    // width: 'calc(100% - 40px)',
    // maxWidth: '300px',
    // margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      width: "calc(100% - 40px)",
    },
  },
  loadingCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 135,
    width: "100%",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  logoContainer: {
    display: "flex",
    fontWeight: 600,
    fontSize: "1.6rem",
  },
  cardNumber: {
    marginTop: "20px",
    marginBottom: 20,
    fontWeight: 600,
    fontSize: "1.4rem",
  },
  cardDigit: {
    marginRight: "10px",
  },
  cardRow: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 10,
  },
  cardSection: {
    display: "flex",
    flexDirection: "column",
  },
  cardLabel: {
    fontSize: "1.1rem",
    marginBottom: 3,
  },
  cardName: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  cardExpiry: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  viewIcon: {
    cursor: "pointer",
    height: 20,
    width: 20,
  },
  link: {
    color: "blue",
    cursor: "pointer",
    display: "inline !important",
  },
  infoRow: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: 10,
    display: "flex",
    padding: 30,
    width: "calc(100% - 60px)",
    marginBottom: "20px",
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.4,
    marginLeft: 20,
    fontWeight: "bold",
  },
}));

const CardView = (props) => {
  const classes = useStyles();
  const { maskedCards, selectedCardToken, t } = props;

  const [isCardFetchLoading, setIsCardFetchLoading] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [cvvIframeSrcParams, setCvvIframeSrcParams] = useState(null);
  const hasViewCvvPermission = usePermissions(MODULE.VIEW_CARD_CVV.ID);

  useEffect(() => {
    setCardData(null);
    setCvvIframeSrcParams(null);
  }, [selectedCardToken]);

  const fetchCardDetailHandler = () => {
    setIsCardFetchLoading(true);
    if (hasViewCvvPermission) {
      getCvvTokens(selectedCardToken);
      return;
    }
    getSessionToken(selectedCardToken);
  };

  const getSessionToken = (cardToken) => {
    fetchChannexSessionToken()
      .then((response) => {
        const { session_token } = response.data;
        let params = {
          card_token: cardToken,
          session_token,
        };

        showCardDetailsHandler(params);
      })
      .catch(handleError);
  };

  const getCvvTokens = (cardToken) => {
    fetchViewCvvTokens()
      .then((response) => {
        const { session_token, service_code_token } = response.data;
        let params = {
          card_token: cardToken,
          session_token,
          service_code_token,
        };

        showCardDetailsHandler(params);
      })
      .catch(handleError);
  };

  const showCardDetailsHandler = (params) => {
    fetchCardDetails(params)
      .then((response) => {
        const cardDetails = parseCardDetails(
          response.data,
          hasViewCvvPermission
        );
        console.log("cardDetails=====", cardDetails);
        setCardData({ ...cardDetails });

        if (hasViewCvvPermission) {
          const iframeSrcParams = new URLSearchParams({
            card_token: params.card_token,
            session_token: params.service_code_token,
          });
          setCvvIframeSrcParams(iframeSrcParams.toString());
        }

        setIsCardFetchLoading(false);
      })
      .catch(handleError);
  };

  const handleError = (errorResult) => {
    setIsCardFetchLoading(false);
    console.log(errorResult);
  };

  let cardDisplay = null;

  if (maskedCards.length == 1) {
    cardDisplay = maskedCards[0];
  }

  if (maskedCards.length > 1 && selectedCardToken) {
    cardDisplay = maskedCards.find(
      (cardData) => cardData.card_token == selectedCardToken
    );
  }

  if (!cardDisplay) return null;

  const cardType =
    cardData?.cardType ||
    cardDisplay?.card_type?.charAt(0).toUpperCase() +
      cardDisplay?.card_type?.slice(1) ||
    " ";
  const isCardInvalid = !isCardValid(cardDisplay.card_number);

  return (
    <>
      {isCardInvalid && (
        <div className={clsx("row", classes.marginTop)}>
          <div className={classes.infoRow}>
            <div>
              <WarningRoundedIcon color="error" fontSize="large" />
            </div>
            <span className={classes.helperText}>
              {t("existingReservation.viewCardModal.invalidCardMessage")}{" "}
              <a className={classes.link} href={`mailto:${MAIL_SUPPORT}`}>
                {MAIL_SUPPORT}
              </a>
            </span>
          </div>
        </div>
      )}
      <div className={classes.cardInfoContainer}>
        {isCardFetchLoading && (
          <div className={classes.loadingCard}>
            <CircularProgress />
          </div>
        )}

        {!isCardFetchLoading && (
          <div>
            <div className={classes.cardRow}>
              <div className={classes.logoContainer}>
                <div className={classes.cardValue}>{cardType}</div>
              </div>

              {!cardData && (
                <AccessController requiredPermission={MODULE.VIEW_CARD.ID}>
                  <img
                    src={IMAGES.ICONS.view}
                    onClick={fetchCardDetailHandler}
                    className={classes.viewIcon}
                  />
                </AccessController>
              )}

              {cardData && (
                <img
                  src={IMAGES.ICONS.visibilityHidden}
                  onClick={() => setCardData(null)}
                  className={classes.viewIcon}
                />
              )}
            </div>

            <div className={classes.cardNumber}>
              {cardData && parse(cardData.cardNumber)}
              {!cardData &&
                cardDisplay.card_number.match(/.{1,4}/g).map((card) => {
                  return (
                    <span key={Math.random()} className={classes.cardDigit}>
                      {card}
                    </span>
                  );
                })}
            </div>

            <div className={classes.cardRow}>
              <div className={classes.cardSection}>
                <div className={classes.cardLabel}>Name</div>
                <div className={classes.cardName}>
                  {cardData ? cardData.cardName : cardDisplay.cardholder_name}
                </div>
              </div>
              <div className={classes.cardSection}>
                <div className={classes.cardLabel}>Expiry</div>
                <div className={classes.cardExpiry}>
                  {cardData
                    ? cardData.cardExpiry
                    : `${
                        cardDisplay.expiration_month
                      }/${cardDisplay.expiration_year.slice(2)}`}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {cardData && hasViewCvvPermission && (
        <CardCvvView
          cvvIframeSrcParams={cvvIframeSrcParams}
          isAccessExpired={cardData.cvvInfo.isAccessExpired}
          accessExpiryInfo={cardData.cvvInfo.accessExpiryInfo}
        />
      )}
    </>
  );
};
export default withTranslation()(CardView);
