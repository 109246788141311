import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles, Button } from "@material-ui/core";
import { uploadCustomerDoc } from "../../../redux/actions/customer/customer";
import { returnCurrent } from '../../../redux/selectors/dashboard';
import http from "../../../redux/utils/http";
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    border: '1px solid #eeeeee',
    marginTop: 10,
    backgroundColor: '#fff'
  },
  uploadButtonContainer: {
    marginBottom: 20
  },
  input: {
    display: "none"
  },
  button: {
    color: '#000000',
    fontSize: '1.2rem',
    backgroundColor: '#fff'
  },
  camera: {
    display: 'inline-block',
    height: 15,
    // opacity: 0.5,
    width: 15,
    marginRight: 10
  },
  file: {
    padding: '10px',
    border: '1px solid #eeeeee',
    overflowWrap: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    overflowWrap: 'break-word',
    marginLeft: 20
  },
  downloadButton: {
    cursor: 'pointer',
    width: 15,
    height: 15
  },
  title: {
    fontSze: '1.6rem',
    fontWeight: '600',
    padding: '0 0 10px',
  },
  header: {
    padding: '10px',
    backgroundColor: '#f8f9fb',
    borderRadius: '2px 2px 0 0',
    border: '1px solid #eeeeee',
  },
  row: {
    padding: '10px',
    border: '0px solid #eeeeee',
    borderWidth: '0 1px',
  },
  docUpload: {
    padding: 10,
    backgroundColor: '#f8f9fb',
    border: '1px solid #eeeeee',
    borderRadius: '0 0 2px 2px',
    textAlign: 'end',
  },
  submitButton: {
    color: '#ffffff',
    background: '#6ea7d6',
    fontSize: '1.2rem',
    border: '0',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#6ea7d6',
      border: 0,
    }
  }
}))

const DocumentForm = (props) => {
  const classes = useStyles();
  const { customerID, currentSpace, documents, t } = props;
  const [selectedFile, setSelectedFile] = React.useState();
  const uploadDocEnd = React.useRef();
  const fileInput = React.useRef(null);

  React.useEffect(() => {
    if(props.success) {
      setSelectedFile(undefined)
      fileInput.current.value = null
    }
  }, [props.success])


  React.useEffect(() => {
    if(selectedFile) {
      uploadDocEnd.current.scrollIntoView({ behavior: 'smooth'});
    }
  }, [selectedFile])

  const files = documents && documents.filter(doc => doc.key).map((file, docIndex) => {
    let fileArr = file.key.split("/");
    let name = fileArr.length ? fileArr[fileArr.length - 1] : file.key;
    name = name?.split('_time_')?.[0] || null;
    return (
      <div className={classes.file} key={docIndex}>
        <a style={{ cursor: 'pointer' }} onClick={() => downloadFileHandler(file.key, name)}>{name}</a>
      </div>
    )
  });

  const downloadFileHandler = (file, fileName) => {
    http.post(`/api/frontdesk/customer/document/`, { file, fileName })
      .then(successResult => {
        window.open(successResult.data, '_blank');
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log('error ', error);
      });
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const handleUpload = () => {
    const fileParts = selectedFile.name.split(".");
    const fileName = fileParts[0];
    const fileType = fileParts[1];
    const payload = {
      propID: currentSpace.propertyID,
      customerID: customerID,
      file: selectedFile,
      name: fileName,
      type: fileType
    };

    // console.log("payload", payload)
    props.dispatch(uploadCustomerDoc(payload))
  }

  return (<>
    <div className={classes.container}>
      <div className={classes.header}><span className={classes.title}>{t('existingReservation.guestTab.documentList')}</span></div>
      {files}
      {!(files && files.length) && <div className={classes.file}><span>{t('existingReservation.guestTab.noDocument')}</span></div>}
    </div>

    <div className={classes.container}>
      <div className={classes.title}>{t('existingReservation.guestTab.uploadDocument')}</div>
      <div key="upload" className={classes.uploadButtonContainer}>

        <div className={classes.header}>
          <input
            ref={fileInput}
            accept="image/*, .pdf"
            className={classes.input}
            id="outlined-button-file"
            type="file"
            onChange={handleCapture}
          />
          <label htmlFor="outlined-button-file">
            <Button
              variant="outlined"
              component="span"
              className={classes.button}
            >
              <img className={classes.camera} src={IMAGES.ICONS.camera} /> {t('existingReservation.guestTab.selectFile')}
            </Button>
          </label>
        </div>
        {selectedFile && <>
          <div className={classes.row}>
            <span className={classes.fileName}>{selectedFile.name}</span>
          </div>
          <div key="button" className={classes.docUpload} ref={selectedFile ? uploadDocEnd : null} >
            <Button
              className={classes.submitButton}
              color="primary"
              variant="outlined"
              onClick={handleUpload}
            >
              {t('actions.upload')}
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => { setSelectedFile(undefined); fileInput.current.value = null }}>{t('actions.cancel')}</Button>
          </div>
        </>}
      </div>
    </div>
  </>)
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  success: state.success.UPLOAD_CUSTOMER_DOC
})

export default withTranslation()(connect(mapStateToProps)(DocumentForm));