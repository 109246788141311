import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Toggle from '../../common/Toggle'
import Select from 'react-select'
import currencyCodes from 'currency-codes'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'
import NumberInput from '../../common/NumberInput'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: '317px'
  },
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(80% - 60px)',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(60% - 60px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px)'
    }
  },
  bulb: {
    width: 40,
    height: 40
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20
  },
  select: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 10,
    marginTop: 10,
    maxWidth: '350px',
    width: '100%'
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10
  },
  marginTop: {
    marginTop: '30px'
  },
  toggleWidth: {
    width: '200px'
  }
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

const MultiCurrency = props => {
  const classes = useStyles()
  const {
    ratePlan: { multiCurrency = {} },
    handleUpdateRatePlan,
    currency: propertyCurrency,
    t
  } = props

  const [conversionRate, setConversionRate] = useState(parseFloat(multiCurrency.conversionRate) || '')

  const handleChange = (key, value) => {
    const state = { ...multiCurrency }
    switch (key) {
      case 'enabled':
      case 'currency':
        state[key] = value
        break
      case 'conversionRate':
        if (!isNaN(parseFloat(value)) && parseFloat(value) > 0) {
          state[key] = parseFloat(value)
        }
        setConversionRate(value)
        break
      default:
        return
    }
    handleUpdateRatePlan('multiCurrency', state)
  }

  const handleGetCurrencies = () => {
    return currencyCodes.data
      .filter(d => d.code !== propertyCurrency)
      .map(d => ({ value: d.code, label: `${d.code} - ${d.currency}` }))
  }
  const currencyOptions = handleGetCurrencies()

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>Multi Currency</div>
        <div className='sub-title'>{t('beds.ratesSnapshot.multiCurrency.description')}</div>

        <div className='row'>
          <div className={classes.infoRow}>
            <div>
              <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
            </div>
            <div className={classes.helperText}>
              {t('beds.ratesSnapshot.multiCurrency.helperText')}
            </div>
          </div>
        </div>

        <Toggle
          className={classes.toggleWidth}
          toggle={multiCurrency.enabled || false}
          onText='Enabled'
          offText='Disabled'
          handleOn={() => handleChange('enabled', true)}
          handleOff={() => handleChange('enabled', false)}
        />

        {multiCurrency.enabled &&
          <>
            <div className={classes.marginTop}>
              <div className={classes.label}>Select Currency</div>
              <div className={classes.select}>
                <Select
                  isSearchable
                  name='currency'
                  onChange={option => handleChange('currency', option.value)}
                  options={currencyOptions}
                  styles={selectStyle}
                  value={multiCurrency.currency && {
                    label: `${multiCurrency.currency} - ${currencyCodes.data.find(d => d.code === multiCurrency.currency).currency}`,
                    value: multiCurrency.currency
                  }}
                  theme={theme => ({
                    ...theme,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    }
                  })}
                />
              </div>
            </div>
            <div className={clsx('row', classes.marginTop)}>
              <div className={classes.label}>Conversion Rate</div>
              <div className={classes.field}>
                <NumberInput
                  id='conversionRate'
                  name='conversionRate'
                  value={conversionRate}
                  onChange={(e) => handleChange('conversionRate', e.target.value)}
                />
              </div>
            </div>
            {multiCurrency.currency && multiCurrency.conversionRate > 0 &&
              <div className={clsx('row', 'label')}>
                1 {multiCurrency.currency} = {multiCurrency.conversionRate} {propertyCurrency}
              </div>}

            <div className={clsx('row', classes.marginTop)}>
              <div className={classes.infoRow}>
                <div>
                  <WarningRoundedIcon color='error' fontSize='large' />
                </div>
                <div className={classes.helperText}>
                  {t('beds.ratesSnapshot.multiCurrency.warnText')}
                </div>
              </div>
            </div>
          </>}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { dashboard: { currentSpace }, spaces } = state
  return {
    property: spaces[currentSpace]
  }
}

export default withTranslation()(connect(mapStateToProps)(MultiCurrency))
