import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { authCallback } from '../../redux/actions/quickBooks/quickBooks';


import Header from '../header/Header';
import Sidebar from '../header/Sidebar';

import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    background: '#ffffff',
    display: "flex",
    flexDirection: "column",
    height: '100vh',
    justifyContent: "center",
    overflow:'hidden',
    position: 'relative',
    width: '100%',
  },
  container: {
    height: '100%',
    marginLeft: 0,
    overflow: 'scroll',
    position: 'absolute',
    top: 0,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80,
      height: 'calc(100% - 80px)'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 80,
      width: "calc(100% - 450px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100% - 530px)",
    },
    [theme.breakpoints.up('xl')]: {
      width: "calc(100% - 550px)",
    },
  },
  sectionContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  sidebar: {
    background: '#ffffff',
    borderRight: '1px solid #000000',
    position: 'fixed',
    height: '100vh',
    top: 0,
    left: 0,
    width: 60,
    zIndex: 1201,
    [theme.breakpoints.up('md')]: {
      width: 80,
    },
  },
  teams: {
    border: '1px solid #000000',
    borderRadius: 15,
    display: 'block',
    position: 'absolute',
    height: 'calc(100vh - 100px)',
    overflow: 'hidden',
    top: '80px',
    right: '20px',
    width: 294,
    zIndex: 1201,
    [theme.breakpoints.up('md')]: {
      width: 344,
    },
    [theme.breakpoints.up('lg')]: {
      width: 424,
    },
    [theme.breakpoints.up('xl')]: {
      width: 444,
    },
  }
});

class QuickBooksRedirect extends Component {
  componentDidMount = () => {
    const body = { 
      propertyID: this.props.spaces[this.props.currentSpace].propertyID,
      callbackURI: window.location.href
    }
    this.props.dispatch(authCallback(body))
    this.props.history.push('/')
  };

  render() {
    const {classes, currentSpace, currentSpaceType, currentUser, spaces} = this.props

    return (
      <div className={classes.root}>
        <Hidden smDown>
          <div className={classes.sidebar} style={{background: currentSpaceType==='Community' ? '#C4AC78' : '#ffffff'}}>
            <Sidebar />
          </div>
        </Hidden>

        <Header />

        <div className={classes.container}>
          <div className={classes.sectionContainer}>
            QuickBooks Redirect Page
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  currentUser: state.auth.user,
  currentSpace: state.dashboard.currentSpace,
  currentSpaceType: state.dashboard.currentSpaceType,
  spaces: state.spaces,
  quickBooks: state.quickBooks
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(QuickBooksRedirect)));