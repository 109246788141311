import { makeStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { loginUser } from '../../../redux/actions/auth/auth';
import InputBase from '@material-ui/core/InputBase';
import { resetSuccess } from '../../../redux/actions/reservation/editReservation';

import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    width: 400,
  },
  header: {
    fontSize: '1.8rem',
    fontWeight: 600,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10,
  },
  subTitle: {
    color: '#000000',
    fontSize: '1.3rem',
  },
  field: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    display: 'flex',
    height: 35,
    marginBottom: 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  signUpPrompt: {
    display: 'flex',
    fontSize: '1.2rem',
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  button: {
    background: '#CC6633',
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    padding: '15px 20px',
  },
  errorText: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

const Login = (props) => {

  const classes = useStyles();
  const [user, setUser] = React.useState({email: props.userEmail});
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState(({email: false, passwordEntry: false}))

  React.useEffect(() => {
    if (props.loginSuccess) {
      props.dispatch(resetSuccess('LOGIN'));
      props.handleLoginSuccess();
    }
  }, [props.loginSuccess])

  const handleUpdate = (e) => {
    setUser({...user, [e.target.id]: e.target.value})
    if (e.target.value !== '') {
      setError({passwordEntry: false})
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    if (!user.password) {
      errorStep.passwordEntry = true;
      foundError = true;
    }
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNext();
    }
  }

  const handleNext = () => {
    const check = handleValidate();
    setOpen(true);
    if (check) {
      props.dispatch(loginUser(user, props.history, props.space));
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  return(
    <>
      <div className={classes.row}>
        <div className={classes.header}>Login with Counter</div>
      </div>

      {/* Enter Name */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Email</div>
        <div className={classes.subTitle}>
          {props.userEmail}
        </div>
      </div>


      {/* Password */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Password</div>
        <div className={classes.field}>
          <InputBase 
            id="password"
            name="password"
            fullWidth
            type="password"
            onChange={handleUpdate}
            onKeyDown={handleKeyDown}
          />
        </div>
        {error.passwordEntry && <div className={classes.errorText}>Please enter a password to proceed</div>}
        <div className={classes.signUpPrompt}>
          <span className={classes.link} onClick={props.handleForgot}>Forgot password?</span>
        </div>
      </div>
      
      {/* Next Button */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.button} onClick={handleNext}>Submit</div>
      </div>


      {props.errors['LOGIN'] && props.errors['LOGIN'].includes('401') && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Incorrect username or password</span>}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  loginSuccess: state.success.LOGIN,
  errors: state.errors
})

export default connect(mapStateToProps)(withRouter(Login));