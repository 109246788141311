
export default (state = [], action) => {
  switch(action.type) {
    // case "LOAD_SPACE_SUCCESS":
    //   return {
    //     ...state,
    //    ...action.payload.folders
    //   }
    case "LOAD_ALL_FILES_SUCCESS":
      return {
        ...state,
        ...action.payload.folders
      }
    case "CREATE_NEW_FOLDER_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "EDIT_FOLDER_NAME_SUCCESS":
      return {
        ...state,
        [action.payload._id]: {
          ...state[action.payload._id],
          name: action.payload.name
        }
      }
    case "DELETE_FOLDER_SUCCESS":
      const newState = Object.keys(state).reduce((object, key) => {
        if (key !== action.payload.folderID) {
          object[key] = state[key]
        }
        return object
      }, {})
      return {
        ...newState
      }
    case "ADD_NEW_FILE":
      const newFileFolder = state[action.payload.folderID]
      return {
        ...state,
        [action.payload.folderID]: {
          ...newFileFolder,
          files: [...newFileFolder.files, action.payload._id]
        }
      };
    case "DELETE_FILE_SUCCESS":
      const deleteFileFolder = state[action.payload.folderID]
      return {
        ...state,
        [action.payload.folderID]: {
          ...deleteFileFolder,
          files: deleteFileFolder.files.filter(item => item !== action.payload.fileID)
        }
      }
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};

