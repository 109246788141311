import moment from "moment-timezone";
import { CURRENCY } from "../common/constants/currency";
import { IMAGES_CDN_PATH } from "../config";

const flagPath = IMAGES_CDN_PATH + "/appAssets/flags/";

export const DATE_FORMATS = {
  FULLDATE_TIME: "ddd MMMM DD, hh:mma",
  FULLDATE: "dddd Do MMMM YYYY",
  BASIC_DATE: "DD MMMM YYYY",
  SHORT_DATE: "DD MMM",
  LONG_DATE: "DD MMM YYYY",
  DATE_MONTH: "Do MMM",
  DAY_MONTH: "DD MMM",
  DEFAULT_DATE: "YYYY-MM-DD",
  DEFAULT_DATE_SLASH: "DD/MM/YYYY",
  YEAR: "YYYY",
  MONTH: "MMM",
  DAY: "DD",
  WEEKDAY: "ddd",
  TIME: "hh:mma",
};

export function fetchDatePerTimezone(date, timezone) {
  return timezone
    ? date
      ? moment(date).tz(timezone)
      : moment().tz(timezone)
    : date
    ? moment(date)
    : moment();
}

export function fetchDateFormat(date, formatString, timezone) {
  let result = null;
  date = date ? moment(date) : null;
  if (date) {
    if (timezone) result = moment(date).tz(timezone).format(formatString);
    else result = moment(date).format(formatString);
  }
  return result;
}

/**
 * Assumptions:
 * formatString1 & formatString2 should be of similar format if not same
 * formatString2 should always be longer string than the formatString1 if not same
 * @param {*} date1
 * @param {*} date2
 * @param {*} timezone
 */
export function fetchCombineDateFormat(date1, date2, timezone, fullDate) {
  let isCurrentYear = true,
    hasSameYear = true,
    hasSameMonth = true,
    currentYear = moment().year();
  let separator = " ";
  let formatString1 = "DD";
  let formatString2 = "DD MMM";
  let result = "";
  date1 = date1 ? fetchDatePerTimezone(date1, timezone) : null;
  date2 = date2 ? fetchDatePerTimezone(date2, timezone) : null;

  // console.log(date1, date2, currentYear)

  if (date1 && date2) {
    if (fullDate) {
      formatString1 = "DD MMM YYYY";
      formatString2 = "DD MMM YYYY";
    } else {
      if (date1.year() !== currentYear || date2.year() !== currentYear)
        isCurrentYear = false;
      if (date1.year() !== date2.year()) {
        hasSameYear = false;
        hasSameMonth = false;
      }
      if (date1.month() !== date2.month()) hasSameMonth = false;

      if (!hasSameMonth) formatString1 = formatString1 + separator + "MMM";
      if (!hasSameYear) formatString1 = formatString1 + separator + "YYYY";
      if (!isCurrentYear) formatString2 = formatString2 + separator + "YYYY";
    }

    // console.log(formatString1, formatString2)

    result = date1.format(formatString1) + " - " + date2.format(formatString2);
  }
  return result;
}

export function getMomentFormat(date) {
  return date ? moment(date) : moment();
}

export function isDateBefore(date1, date2) {
  date1 = date1 ? moment(date1) : null;
  date2 = date2 ? moment(date2) : null;
  return date1 && date2 ? moment(date1).isBefore(moment(date2)) : null;
}

export function checkDateInBetween(
  startDate,
  endDate,
  compareDate,
  compareBracket
) {
  compareBracket = compareBracket || "[]";
  startDate = fetchDateFormat(startDate, DATE_FORMATS.DEFAULT_DATE);
  endDate = fetchDateFormat(endDate, DATE_FORMATS.DEFAULT_DATE);
  compareDate = fetchDateFormat(compareDate, DATE_FORMATS.DEFAULT_DATE);
  startDate = moment(moment(startDate), DATE_FORMATS.DEFAULT_DATE);
  endDate = moment(moment(endDate), DATE_FORMATS.DEFAULT_DATE);
  compareDate = moment(moment(compareDate), DATE_FORMATS.DEFAULT_DATE);

  return compareDate.isBetween(startDate, endDate, null, compareBracket);
}

export function getMomentAddDayISO(date, number) {
  number = number || 1;
  return date ? moment(date).add(number, "days").toISOString(false) : null;
}

export function checkDateInFuture(date) {
  date = date ? moment(date) : null;
  return date && moment().diff(date, "days") < 0 ? true : false;
}

export function checkDateInPast(date) {
  date = date ? moment(date) : null;
  return date && moment().diff(date, "days") > 0 ? true : false;
}
export function checkDateIfGreater(date1, date2) {
  // date1 && date2 && console.log(moment(date1).diff(date2, "days"))
  return date1 &&
    date2 &&
    moment(date1).startOf("day").diff(moment(date2).startOf("day"), "days") > 0
    ? true
    : false;
}
//date1 is smaller than date2
export function fetchNoOfDays(date1, date2) {
  return date1 && date2 ? moment(date2).endOf("day").diff(date1, "days") : 0;
}

export function fetchNoOfNights(date1, date2) {
  return date1 && date2
    ? moment(date2).endOf("day").diff(moment(date1).endOf("day"), "days")
    : 0;
}
export function fetchNightCount(date1, date2) {
  return date1 && date2
    ? moment(date2).endOf("day").diff(moment(date1).startOf("day"), "days")
    : 0;
}

export function getDatesOfRange(startDate, endDate, timezone, type) {
  // console.log("getDatesOfRange: >> startDate, endDate", startDate, endDate)
  let dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    if (type === "stringArray") {
      dateArray.push(currentDate);
    } else if (type === "objectArray") {
      dateArray.push({ date: currentDate });
    }
    currentDate =
      fetchDateFormat(
        getMomentAddDayISO(currentDate, 1),
        DATE_FORMATS.DEFAULT_DATE
      ) || null;
  }
  return dateArray;
}

export function getWeekDateByNumber(date, number) {
  return date
    ? moment(date).weekday(number).format(DATE_FORMATS.DEFAULT_DATE)
    : null;
}

export function isSame(date1, date2, type) {
  return date1 && date2 ? moment(date1).isSame(date2, type) : false;
}

export function endOfMonth(date) {
  return date
    ? moment(date).endOf("month").format(DATE_FORMATS.DEFAULT_DATE)
    : null;
}

export function startOfMonth(date) {
  return date
    ? moment(date).startOf("month").format(DATE_FORMATS.DEFAULT_DATE)
    : null;
}

// var getDaysBetweenDates = function(startDate, endDate) {
//   var now = startDate.clone(), dates = [];

//   while (now.isSameOrBefore(endDate)) {
//       dates.push(now.format(DATE_FORMATS.DEFAULT_DATE));
//       now.add(1, 'days');
//   }
//   return dates;
// };

//------------------------------------------------------------------------------------------------

export const initials = (name = "") => {
  var n = name.split(" ");
  // console.log(name, n, n[0].charAt(0).toUpperCase() + n[n.length - 1].charAt(0).toUpperCase())
  if (n.length > 1) {
    return `${
      n[0].charAt(0).toUpperCase() + n[n.length - 1].charAt(0).toUpperCase()
    }`;
  } else {
    return `${n[0].charAt(0).toUpperCase()}`;
  }
};

export const amountCommaSeperator = (amount) => {
  const thousandseparater = ",";
  const fractionalseparator = ".";
  // Format thousands and fractional seperator...
  const values = amount.toString().split(".");
  const formatedAmount =
    values[0].replace(/.(?=(?:.{3})+$)/g, `$&${thousandseparater}`) +
    (values.length == 2 ? fractionalseparator + values[1] : "");
  return formatedAmount;
};

export const getNumberAbbr = (value) => {
  const number = Math.abs(Number(value));
  const billionValue = parseFloat((number / 1.0e9).toFixed(1));
  const millionValue = parseFloat((number / 1.0e6).toFixed(1));
  const thousandValue = parseFloat((number / 1.0e3).toFixed(1));
  // Nine Zeroes for Billions
  return number >= 1.0e9
    ? `${billionValue}B`
    : // Six Zeroes for Millions
    number >= 1.0e6
    ? `${millionValue}M`
    : // Three Zeroes for Thousands
    number >= 1.0e3
    ? `${thousandValue}K`
    : number;
};

export const getCurrency = ({ code = "EUR", country = "DEU" }, amount) => {
  try {
    amount =
      amount && parseFloat(amount)
        ? parseFloat(parseFloat(amount).toFixed(2))
        : amount || 0;
    const currencyCode = code.toUpperCase();
    country = country.toLowerCase();
    country = country.charAt(0).toUpperCase() + country.slice(1);

    const currencyData = CURRENCY[currencyCode];
    let formatedAmount = "";
    const currencyDetails = currencyData.countrybasedformatting
      ? { ...currencyData.countryinfo[country], symbol: currencyData.symbol }
      : currencyData;

    const { symbol, space } = currencyDetails;

    // Check if negative value before formating...
    const parsedAmount = parseInt(amount);
    const isNegative =
      parseInt(parsedAmount) < 0 && parseInt(parsedAmount) != 0 ? true : false;
    const absoluteAmount = Math.abs(amount);

    // Format thousands and fractional seperator...
    formatedAmount = amountCommaSeperator(absoluteAmount);
    formatedAmount = space
      ? `${symbol} ${formatedAmount}`
      : `${symbol}${formatedAmount}`;
    formatedAmount = isNegative ? `-${formatedAmount}` : formatedAmount;
    return formatedAmount;
  } catch (error) {
    //console.log('error ',error);
    return `${code} ${amount}`;
  }
};

export const currencyToNumber = (currencyData, amount) => {
  try {
    const isNegative = amount.substring(0, 1) == "-";
    const tenCurrency = getCurrency(currencyData, 10); //Get format of zero currency value, eg MXN 10, $10,
    let currencyLength = tenCurrency.length - 2; // Trim the ten
    if (isNegative) {
      // console.log('negative')
      currencyLength += 1; // Add another character for the negative sign
    }
    let finalAmount = amount.substring(currencyLength).replace(/,/g, ""); // remove commas
    finalAmount = isNegative ? `-${finalAmount}` : finalAmount; // add negative sign
    return finalAmount;
  } catch (error) {
    return amount;
  }
};

export function getAmtFormat(amount, currency, currencyCode, decimals = 2) {
  amount =
    amount && parseFloat(amount)
      ? parseFloat(amount).toFixed(decimals)
      : amount || 0;
  if (amount === -0) amount = 0;
  return currency
    ? amount >= 0
      ? currency + "" + amount
      : "(" + currency + "" + Math.abs(amount) + ")"
    : amount;
}

export function checkDataValidation(data, formHelper) {
  let hasError = false;
  Object.keys(data).forEach((key) => {
    if (!data[key] && formHelper[key].required) {
      formHelper[key].error = true;
      hasError = true;
    } else formHelper[key].error = false;
  });

  return { formHelper: formHelper, hasError: hasError };
}

export function getRoundValue(num) {
  return Math.round(num);
}

export function getAvatarFlag(country) {
  return country
    ? flagPath +
        "" +
        country
          .replace(/ /g, "_")
          .replace(/\W/g, "")
          .replace(/_/g, "-")
          .toLowerCase() +
        ".png"
    : null;
}

export function fetchCurrencyFormat(currency) {
  currency = currency || "";
  const currencySymbol =
    currency && CURRENCY[currency] && CURRENCY[currency].symbol
      ? CURRENCY[currency].symbol
      : null;
  let currencyFmt = currencySymbol ? currencySymbol : currency + " ";
  if (currency.toLowerCase() === "rupees") currencyFmt = "₹";
  return currencyFmt;
}

export function fetchCurrencySymbol(currency) {
  currency = currency || "";
  const currencySymbol =
    currency && CURRENCY[currency] && CURRENCY[currency].symbol
      ? CURRENCY[currency].symbol
      : "";
  if (currency.toLowerCase() === "rupees") currencySymbol = "₹";
  return currencySymbol;
}

export function fetchTemplateString(templateString, obj) {
  templateString = templateString || "";
  obj = obj || {};
  return templateString.replace(/{(\w+)}/g, function (_, k) {
    return obj[k];
  });
}

export function humanise(firstName, lastName) {
  firstName = firstName || "";
  lastName = lastName || "";
  let name = (firstName + " " + lastName).trim();
  // console.log("name", name)
  let replacer = function (whole, prefix, word) {
    // console.log('whole', whole, 'prefix', prefix, 'word', word)
    let ret = [];
    if (prefix) {
      ret.push(prefix.charAt(0).toUpperCase());
      ret.push(prefix.substr(1).toLowerCase());
    }
    ret.push(word.charAt(0).toUpperCase());
    ret.push(word.substr(1).toLowerCase());
    return ret.join("");
  };
  // a-z\u0161\u0111\u010D\u0107\u017E is same as a-zšđčćž
  let pattern = /\b(ma?c)?([a-z\u0161\u0111\u010D\u0107\u017E]+)/gi;
  return name.replace(pattern, replacer);
}

export function deepSameKeys(o1, o2) {
  // Get the keys of each object
  const o1keys = Object.keys(o1).sort();
  const o2keys = Object.keys(o2).sort();
  // Make sure they match
  if (o1keys.join() !== o2keys.join()) {
    // This level doesn't have the same keys
    return false;
  }
  // Check any objects
  return o1keys.every((key) => {
    const v1 = o1[key];
    const v2 = o2[key];
    if (v1 === null) {
      return v2 === null;
    }
    const t1 = typeof v1;
    const t2 = typeof v2;
    if (t1 !== t2) {
      return false;
    }
    return t1 === "object" ? deepSameKeys(v1, v2) : true;
  });
}

export const isHtml = (str = "") => {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const getPropValues = (o, prop) =>
  ((res) => (
    JSON.stringify(o, (key, value) => (key === prop && res.push(value), value)),
    res
  ))([]);

export const convertArrayToCSV = (objArray = [], headerData) => {
  objArray = objArray.map((item) => {
    delete item.tableData;
    return item;
  });

  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let HeaderStr = "";
  const headerLength = headerData.length;

  headerData.forEach((data, index) => {
    if (index + 1 == headerLength.length) {
      HeaderStr += `${data.title}`;
      return;
    }
    HeaderStr += `${data.title}, `;
  });

  let str = HeaderStr + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = ``;
    let count = 1;

    for (let index in array[i]) {
      if (count > headerLength) break;

      if (line != "" || (line == "" && count == 2)) line += `,`;

      let value = array[i][index];

      if (value && typeof value == "string" && value.indexOf(",") != -1) {
        value = `"${value}"`;
      }

      line += value;

      count++;
    }

    str += line + "\r\n";
  }

  return str;
};

export const generateCSV = (csv, filename) => {
  let downloadLink = document.createElement("a");
  let blob = new Blob(["\ufeff", csv]);
  let url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = `${filename}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const processNumberInput = (input) => {
  if (
    input.length === 2 &&
    input.charAt(0) === "0" &&
    input.charAt(1) !== "."
  ) {
    input = parseInt(input);
  }
  while (isNaN(input)) {
    input = input
      .split("")
      .reverse()
      .join("")
      .replace(/[\D]/i, "")
      .split("")
      .reverse()
      .join("");
    // input = parseFloat(input)
  }
  return input;
};

export const isEUCountry = (selectedCountryCode) => {
  return [
    "AUT", //Austria,
    "BEL", //Belgium,
    "BGR", //Bulgaria,
    "HRV", //Croatia,
    "CYP", //Republic of Cyprus,
    "CZE", //Czech Republic,
    "DNK", // Denmark,
    "EST", // Estonia,
    "FIN", // Finland,
    "FRA", // France,
    "DEU", // Germany,
    "GRC", // Greece,
    "HUN", // Hungary,
    "IRL", // Ireland,
    "ITA", // Italy,
    "LVA", // Latvia,
    "LTU", // Lithuania,
    "LUX", // Luxembourg,
    "MLT", // Malta,
    "NLD", // Netherlands,
    "POL", // Poland,
    "PRT", // Portugal,
    "ROU", // Romania,
    "SVK", // Slovakia,
    "SVN", // Slovenia,
    "ESP", // Spain,
    "SWE", // Sweden
    "GBR", // United Kingdom of Great Britain and Northern Ireland (the),
  ].includes(selectedCountryCode);
};

export const filterDataFields = (data, filterObj) => {
  const filterFields = Object.keys(filterObj);

  const filteredData = Object.keys(data)
    .filter((key) => filterFields.includes(key))
    .reduce((obj, key) => ((obj[key] = data[key]), obj), {});

  const updatedData = { ...filterObj, ...filteredData };
  return updatedData;
};

export const openPDFWindow = (data, callback) => {
  let blob = new Blob([data], { type: "application/pdf" });
  let objectURL = window.URL.createObjectURL(blob);
  window.open(objectURL, "_blank");
  if (callback) callback();
};

export const isFeatureEnabled = (featureName, currentSpace) => {
  const isEnabled =
    currentSpace &&
    currentSpace?.property?.features?.find((item) => {
      return item.name === featureName;
    })?.isEnabled === "true";

  return isEnabled;
};

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};