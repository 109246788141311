import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Signup from './Signup';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

import http from '../../../redux/utils/http';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F8F9FB',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '600px',
    overflowY: 'hide',
    width: '100%',
  },
  container: {
    background: '#F8F9FB',
    flexGrow: 1,
    height: '100%',
  },
  userInfoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  right: {
    background: '#ffffff'
  },
  background: {
    alignItems: 'center',
    background: '#FFFFFF',
    display: 'flex',
    height: 'calc(100% - 32px)',
    maxHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  imageContainer: {
    width: '70%'
  },
  signupImage: {
    objectFit: 'contain',
    width: '100%',
  },
  welcome: {
    marginBottom: 15,
    marginTop: 30,
  },
}))

const UserDetails = (props) => {
  const classes = useStyles();
  const { space, user, userEmail, containerHeight } = props;
  const [forgot, setForgot] = React.useState(false);
  
  const handleForgot = ()=> {
   setForgot(true);
  }

  const handleReset = () => {
    http
      .post('/api/users/forgotPassword', { email: userEmail })
      .then(successResult => console.log(successResult))
      .catch(errorResult => console.log(errorResult))
    setForgot(false);
  }

  const handleBack = () => {
    setForgot(false);
  }

  return (
    <div className={classes.root} style={{minHeight: containerHeight}}>
      
      <Grid container className={classes.container}>
        
        <Grid item xs={12} md={6}>
          <div className={classes.userInfoContainer}>
            
            {!user && 
              <Signup userEmail={userEmail} handleSubmit={(newUser) => props.handleSubmitUser(newUser)} />
            }

            {user && !forgot && 
              <Login space={space} userEmail={userEmail} handleLoginSuccess={props.handleLoginSuccess} handleForgot={handleForgot} />
            }

            {user && forgot && 
              <ForgotPassword userEmail={userEmail} handleBack={handleBack} handleReset={handleReset} />
            }

          </div>
        </Grid>

        <Hidden smDown><Grid item xs={12} md={6} className={classes.right}>
          <div className={classes.background}>
            <div className={classes.imageContainer}>
              <img className={classes.signupImage} src="/images/illustration-sup.png" />
            </div>
            <div className={classes.welcome}>
              <Typography variant="h2" style={{color: '#333333'}}>Experiences</Typography>
            </div>
            <div>
              <Typography variant="h5" style={{fontWeight: 600}}>powered by counter</Typography>
            </div>
          </div>
        </Grid></Hidden>

      </Grid>
    
      
    </div>
  )
}

export default connect()(UserDetails);