import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {EMAIL_TAGS} from '../../../utils/constants';

const useStyles = makeStyles(theme =>({
    root:{
       padding: 'unset',
       maxWidth: '100px',
   },
   button:{
       border: '1px solid',
       padding: 'unset',
   },
   popper:{
    zIndex: 99
   },
   menuList: {
    maxHeight: '240px',
    overflowY: 'scroll',
   }
}));

const EmailTags = props =>{
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    function handleToggle() {
        setOpen(prevOpen => !prevOpen);
    }

    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const onClickHandler = tag =>{
        props.onSelectHandler(tag);
        setOpen(false);
    }

    return(
        <div className={clsx(`trix-button ${classes.root}`, props.className)}>
            <Button
                ref={anchorRef}
                aria-controls="menu-list-grow"
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.button}
            >
                Tags
            </Button>
            <Popper open={open} className={classes.popper} anchorEl={anchorRef.current} keepMounted transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper id="menu-list-grow">
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className={classes.menuList}>
                            {EMAIL_TAGS.map((item,index)=>(
                                <MenuItem key={index} onClick={()=>onClickHandler(item.tag)}>{item.title}</MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
      </div>
    )
}

export default EmailTags;