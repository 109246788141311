import { RESERVATION_STATUS } from "../../../utils/constants";

const reservations = {};

export default (state = reservations, action) => {
  switch (action.type) {
    case "LOAD_RESERVATIONS_SUCCESS":
      return {
        ...state,
        ...action.payload.reservations
      };
    case "ADD_RESERVATION_SUCCESS":
      const addedReservations = { ...state };
      action.payload && action.payload.length && action.payload.forEach((reservation) => {
        addedReservations[reservation.accommodationID] = reservation;
      })

      return { ...state, ...addedReservations };

    case "UPDATE_ROOMBLOCK_SUCCESS":
      const updateRoomBlock = { ...state };
      action.payload && action.payload.length && action.payload.forEach((reservation) => {
        updateRoomBlock[reservation.accommodationID] = reservation;
      })

      let deletedKeys = action.deletedKeys || [];
      deletedKeys && deletedKeys.forEach((data) => {
        if (data.accommodationID && updateRoomBlock[data.accommodationID]) {
          delete updateRoomBlock[data.accommodationID]
        }
      })

      return { ...state, ...updateRoomBlock };

    case "UPDATE_RESERVATION_SUCCESS":
      const prevState = { ...state };
      let deletedIDs = action.payload.removedAccFlag ? action.deletedKeys : [];
      const updatedResevation = { ...action.payload.updatedRes };
      const accommodationInfo = [...updatedResevation.accommodationList];

      deletedIDs && deletedIDs.forEach((data) => {
        if (data.accommodationID && prevState[data.accommodationID]) {
          delete prevState[data.accommodationID]
        }
      })

      accommodationInfo.forEach((data) => {
        prevState[data._id] = {
          accommodationID: data._id,
          balance: updatedResevation.balance,
          bedID: data.bedID,
          checkIn: data.checkIn,
          checkOut: data.checkOut,
          guestName: updatedResevation.guestName,
          noteCount: 0,
          resID: updatedResevation._id,
          roomID: data.roomID,
          status: updatedResevation.status,
          totalAmount: updatedResevation.totalAmount,
        };
      })

      return { ...state, ...prevState };

    case "UPDATE_RESERVATION_STATUS_SUCCESS":
      let updatedAccommodations = { ...state };
      let accommodations = action.payload.accommodationList || [];
      accommodations && accommodations.forEach((data) => {
        if (updatedAccommodations[data._id]) {
          updatedAccommodations[data._id] = {
            ...updatedAccommodations[data._id],
            status: action.payload.status
          }
        }
      })
      return {
        ...state,
        ...updatedAccommodations
      };

    case "UPDATE_CUSTOMER_SUCCESS":
      let updatedGuest = { ...state };
      if (action.isPrimary && action.accommodationIDs.length) {
        let accommodationIDs = action.accommodationIDs || [];
        accommodationIDs && accommodationIDs.forEach((key) => {
          if (updatedGuest[key]) {
            updatedGuest[key] = {
              ...updatedGuest[key],
              guestName: ((action.payload.firstName || '') + " " + (action.payload.lastName || '')).trim()
            }
          }
        })
      }
      return {
        ...state,
        ...updatedGuest
      };
    case "CANCEL_RESERVATION_SUCCESS":
      const cancelledAccommodations = { ...state };
      let accommodationList = action.payload.accommodationList || [];
      accommodationList && accommodationList.forEach((data) => {
        if (cancelledAccommodations[data._id]) {
          delete cancelledAccommodations[data._id]
        }
      })
      return cancelledAccommodations;

    case "ADD_RESERVATION_PAYMENT_SUCCESS":
      let payment = Object.keys(state).filter(res => state[res].resID === action.payload.transaction.reservationID)
      if (payment.length) {
        payment && payment.forEach((key) => {
          if (state[key]) {
            state[key] = {
              ...state[key],
              balance: action.payload.balance
            }
          }
        })
        return { ...state }
      } else {
        return state;
      }

    case "VOID_RESERVATION_TRANSACTION_SUCCESS":
      let respayment = Object.keys(state).filter(res => state[res].resID === action.payload.reservationID)
      if (respayment.length) {
        respayment && respayment.forEach((key) => {
          if (state[key]) {
            state[key] = {
              ...state[key],
              balance: state[key].balance + action.payload.amount
            }
          }
        })
        return { ...state }
      } else {
        return state;
      }

    case "UPDATE_RESERVATION_ITEMS_SUCCESS":
      let resItems = Object.keys(state).filter(res => state[res].resID === action.payload._id)
      if (resItems.length) {
        resItems && resItems.forEach((key) => {
          if (state[key]) {
            state[key] = {
              ...state[key],
              balance: action.payload.balance,
              totalAmount: action.payload.totalAmount,
            }
          }
        })
        return { ...state }
      } else {
        return state;
      }
    case "MANAGE_RESERVATION_TAX_SUCCESS":
      let resAddTax = Object.keys(state).filter(res => state[res].resID === action.payload._id)
      if (resAddTax.length) {
        resAddTax && resAddTax.forEach((key) => {
          if (state[key]) {
            state[key] = {
              ...state[key],
              balance: action.payload.balance,
              totalAmount: action.payload.totalAmount,
            }
          }
        })
        return { ...state }
      } else {
        return state;
      }

    case "DELETE_CUSTOMER_SUCCESS":
      let accommodationState = { ...state };
      let remGuestRes = action.payload.data;
      let remAccommodations = remGuestRes.accommodationList || [];
      remAccommodations && remAccommodations.forEach((data) => {
        if (accommodationState[data._id]) {
          accommodationState[data._id] = {
            ...accommodationState[data._id],
            guestName: remGuestRes.guestName
          }
        }
      })
      return {
        ...state,
        ...accommodationState
      };

      case "CREATE_ORDER_SUCCESS":
        let resOrders = Object.keys(state).filter(res => state[res].resID === action.payload.reservationID)
        if (resOrders.length) {
          resOrders && resOrders.forEach((key) => {
            if (state[key]) {
              state[key] = {
                ...state[key],
                balance: action.payload.balance,
                totalAmount: action.payload.totalAmount,
              }
            }
          })
          return { ...state }
        } else {
          return state;
        }
      case "DELETE_ORDER_SUCCESS":
        let resDelOrders = Object.keys(state).filter(res => state[res].resID === action.payload.reservationID)
        if (resDelOrders.length) {
          resDelOrders && resDelOrders.forEach((key) => {
            if (state[key]) {
              state[key] = {
                ...state[key],
                balance: action.payload.balance,
                totalAmount: action.payload.totalAmount,
              }
            }
          })
          return { ...state }
        } else {
          return state;
        }

      case "UPDATE_CALENDAR_NOTE_COUNT_SUCCESS":
        const updatedResvID =  action.payload.reservationID;
        let accommodationID = '';

        for(const [accID, accomodation] of Object.entries(state)){
          if(accomodation.resID == updatedResvID){
            accommodationID = accID;
            break;
          }
        }

        if(!state?.[accommodationID]) return state;
        return {
          ...state,
          [accommodationID]: {
            ...state[accommodationID],
            noteCount: action.payload.noteCount
          }
        }
      
    case "RESERVATIONS_RESET":
      return {}
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};