import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InputBase } from '@material-ui/core';
import Select from 'react-select';

import Button from '@material-ui/core/Button';
import NumberInput from '../../common/NumberInput';
import ConfirmForm from '../../common/ConfirmForm';
// import { uploadRoomTypePhoto } from '../../../redux/actions/rooms/roomTypes';
// import DeleteConfirm from '../DeleteConfirm';

const useStyles = makeStyles(theme => ({
    submit: {
        display: 'flex',
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    picContainer: {
        alignItems: 'center',
        // cursor: 'pointer',
        display: 'flex',
    },
    placeholder: {
        background: '#F2F2F2',
        borderRadius: '15px',
        height: 80,
        marginRight: 15,
        overflow: 'hidden',
        width: 80,
    },
    image: {
        borderRadius: 15,
        objectFit: 'cover',
        height: 80,
        width: 80,
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 10,
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: 0,
    },
    warning: {
        color: '#ff9800',
        marginTop: 10
    },
    input: {
        display: 'none'
    },
    deleteButton: {
        borderRadius: '50%',
        cursor: 'pointer',
        height: 20,
        padding: 8,
        width: 20,
        '&:hover': {
            background: '#E0E0E0',
        }
    },
    leftAlignSubmit: {
        width: '60%'
    },
    righttAlignSubmit: {
        width: '40%',
        display: 'flex',
        justifyContent: "flex-end",
    },
    cancelButton: {
        margin: '0 10px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
    },
    field: {
        alignItems: 'center',
        boxShadow: "0 1px 10px #E0E0E0",
        borderRadius: '10px',
        display: 'flex',
        height: 35,
        marginTop: 10,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'calc(80% - 32px)',
        '& > div': {
            width: '100%'
        },
        '&.disabled': {
            backgroundColor: '#E0E0E0'
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    select: {
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: 10,
        marginTop: 10,
        width: '80%',
    },
    multiLine: {
        alignItems: 'flex-start',
        height: 100,
        overflowY: 'hidden'
    },
    cancel: {
        color: '#999999',
        fontSize: '1.2rem',
        marginRight: 15,
        '&:hover': {
            background: '#ffffff'
        }
    },
    toggle: {
        alignItems: 'center',
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: 25,
        display: 'flex',
        width: '200px',
    },
    toggleButton: {
        alignItems: 'center',
        color: '#BDBDBD',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        height: 35,
        justifyContent: 'center',
        width: '50%'
    },
    left: {
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
    },
    right: {
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
    },
    toggleSelect: {
        background: '#4F9581',
        // boxShadow: '-15px 0px 17px -7px #F2F2F2',
        color: '#FFFFFF',
    },
    toggleDisabled: {
        backgroundColor: '#bdbdbd',
        color: '#FFFFFF',
    },
    input: {
        display: "none"
    },
    back: {
        '&:hover': {
            background: '#ffffff'
        }
    },
    submitButton: {
      background: '#000000',
      fontWeight: 600,
      fontSize: '1.2rem',
      '&:hover': {
          background: '#000000',
          boxShadow: 'none'
      }
    }
}))

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: 0,
        borderRadius: 10,
        paddingLeft: 8,
        '&:hover': {
            border: state.isFocused ? 0 : 0
        },
        paddingLeft: 0,
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    })
};

const taxTypeOptions = [
    { label: 'Service Fee', value: 2 },
    { label: 'Country Tax', value: 4 },
    { label: 'Resort Tax', value: 3 },
    { label: 'City Tax', value: 1 },
    { label: 'Sales Tax', value: 0 }
];

const appliesToOptions = [
    {label: "Person per night", value: 4 },
    {label: "Room per night", value: 3 },
    {label: "Person per stay", value: 2 },
    {label: "Room per stay", value: 1 },
    {label: "Total Cost", value: 0 }
];

const Form = (props) => {
    const classes = useStyles();
    const { currentSpace } = props;
    const [error, setError] = React.useState({ name: false });
    const [formData, setFormData] = React.useState({
        appliesTo: 1,
        type: 1,
        isFixed: true,
        amount: 0
    });

    const onSelectHandler = (event, name) => {
        // console.log("event, name", event.value, name)
        const value = event.value;
        const isFixed = (name === 'appliesTo' && value === 0) ? false : formData.isFixed;
        setFormData({ ...formData, [name]: value, isFixed });
    }

    const onChangeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: parseFloat(value) });
    }

    const handleToggle = () => {
        if (!formData.appliesTo) return;
        if (formData.isFixed) setFormData({ ...formData, isFixed: false })
        else setFormData({ ...formData, isFixed: true })
    }

    const handleValidate = () => {
        let foundError = false;
        let errorStep = {};
        let mandatoryFields = ['amount'];
        mandatoryFields.map(item => {
            if (!formData[item]) {
                errorStep[item] = true;
                foundError = true;
            }
        })
        if (foundError) {
            setError({ ...error, ...errorStep })
            return false
        }
        else return true
    }

    const handleSubmit = () => {
        // console.log('formData', formData)
        const checkInput = handleValidate();
        // console.log("checkInput", checkInput)
        if (checkInput) {
            props.submitHandler({ ...formData, isFixed: formData.isFixed ? 1 : 0 })
        }
    }


    return (
        <div className={classes.root}>

            <div className={classes.row}>
                <div className={classes.label}>Tax Type</div>
                <div className={clsx(classes.select, { [classes.fieldError]: error.type })}>
                    <Select
                        onChange={(e) => onSelectHandler(e, 'type')}
                        options={taxTypeOptions}
                        defaultValue={taxTypeOptions.find(e => e.value === 1)}
                        styles={selectStyle}
                        className={classes.dropdownInput}
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            {/* Increase / Descrease Toggle */}
            {/* ------------------------------------ */}
            <div className={classes.row}>
                <div className={classes.toggle}>
                    <div 
                        className={clsx({[classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: formData.isFixed, [classes.toggleDisabled]: !formData.appliesTo})}
                        onClick={() => handleToggle()}
                    >
                        Amount
                    </div>
                    <div 
                        className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: !formData.isFixed})}
                        onClick={() => handleToggle()}
                    >
                        Percentage
                    </div>
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Value</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.amount })}>
                    <NumberInput
                        id="amount"
                        name="amount"
                        min={0}
                        value={formData.amount}
                        onChange={onChangeHandler} />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Applies To</div>
                <div className={clsx(classes.select, { [classes.fieldError]: error.appliesTo })}>
                    <Select
                        onChange={(e) => onSelectHandler(e, 'appliesTo')}
                        options={appliesToOptions}
                        defaultValue={appliesToOptions.find(e => e.value === 1)}
                        styles={selectStyle}
                        className={classes.dropdownInput}
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.submit}>
                <div className={classes.leftAlignSubmit}></div>
                <div className={classes.righttAlignSubmit}>
                    <Button className={classes.cancel} onClick={props.cancelHandler}>Cancel</Button>
                    <Button variant="contained" color="primary" className={classes.submitButton} onClick={() => handleSubmit()}>Add Tax</Button>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(Form);