const searchDefaults = {
  keyword: '',
  searchResults: [],
  searchActive: false,
  status: '',
  date: '',
  roomTYpe: '',
  suggestions: []
};

export default (state = searchDefaults, action) => {
  switch (action.type) {
    case "SET_SEARCH_KEYWORD": 
      return {
        ...state,
        keyword: action.payload
      }
    case "SET_SEARCH_STATUS": 
      return {
        ...state,
        status: action.payload
      }
    case "SET_SEARCH_ACTIVE": 
      return {
        ...state,
        searchActive: action.payload
      }
    case "SET_SEARCH_DATE": 
      return {
        ...state,
        date: action.payload
      }
    case "SET_SEARCH_ROOM_TYPE": 
      return {
        ...state,
        roomType: action.payload
      }
    case "SEARCH_RESERVATIONS_SUCCESS":
      return {
        ...state,
        searchResults: action.payload.searchResults,
        suggestions: action.payload.suggestions
      };
    case "CLEAR_SEARCH": 
      return {
        ...searchDefaults
      }
    case "USER_LOGOUT":
      return {
        ...searchDefaults
      }
    default:
      return state;
  }
};