import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MaterialTable from 'material-table';

import { exportExcel } from '../../utils/excelExport';
import { DATE_FORMATS, fetchDateFormat } from '../../../utils/utility';
import { getHostelWorldInvoicePDF, getHostelWorldInvoices } from '../../../redux/actions/microsite/hostelworld';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const styles = theme => ({
    dialog: {
        overflowY: 'hidden'
    },
    dialogPaper: {
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    actionIcon: {
        cursor: 'pointer'
    },
    mainContainer: {
        height: 'calc(100vh - 88px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 88px)'
        }
    },
    listSection: {
        margin: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        position: 'relative',
        '&.tableContent': {
            minHeight: 'calc(100vh - 450px)'
        }
    },
    loadingState: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
        zIndex: 999
    },
});


const BasicExport = props => {

    const { t, downloadInvoice, classes } = props;
    const tableTitle = t('hostelworldChannel.invoices.title');

    return (
        <MaterialTable
            title={''}
            columns={[
                { title: t('hostelworldChannel.invoices.table.download'), field: 'invoiceNO', render: rowData => (
                    <div onClick={() => downloadInvoice(rowData.invoiceNO) }><PictureAsPdfIcon className={classes.actionIcon}/></div>
                )},
                { title: t('hostelworldChannel.invoices.table.invoice'), field: 'invoiceNO' },
                { title: t('hostelworldChannel.invoices.table.invoiceType'), field: 'invoiceType' },
                { title: t('hostelworldChannel.invoices.table.periodStarting'), field: 'startPeriod', render: rowData => <>{fetchDateFormat(rowData.startPeriod, DATE_FORMATS.LONG_DATE)}</> },
                { title: t('hostelworldChannel.invoices.table.periodEnding'), field: 'endPeriod', render: rowData => <>{fetchDateFormat(rowData.startPeriod, DATE_FORMATS.LONG_DATE)}</> },
                { title: t('hostelworldChannel.invoices.table.netFee'), field: 'netFee' },
                { title: t('hostelworldChannel.invoices.table.vatAmount'), field: 'vatAmount' },
                { title: t('hostelworldChannel.invoices.table.totalPayments'), field: 'totalPayments' },
                { title: t('hostelworldChannel.invoices.table.balance'), field: 'balance' },
                { title: t('hostelworldChannel.invoices.table.pay'), field: 'pay' },
            ]}
            data={props.data}
            localization={{
                toolbar: { exportCSVName: "Export as Excel" }
            }}
            options={{
                exportButton: true,
                rowStyle: { fontSize: '1.2rem' },
                paging: false,
                headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0 },
                detailPanelColumnAlignment: 'right',
                exportCsv: (columns, data) => {
                    const tableData = _.cloneDeep(data);
                    const headerConfig = [...columns];
                    const filename = `${tableTitle}`;
                    exportExcel(filename, headerConfig, tableData);
                }
            }}
        />
    )
}

class ChannelLog extends Component {

    state = {
        reportData: [],
    }

    constructor(props) {
        super(props)

    }

    componentDidMount() {
        this.getInvoices();
    }

    getInvoices = () => {
        const { dispatch, currentSpace } = this.props;
        const response = dispatch(getHostelWorldInvoices(currentSpace.propertyID));
        response.then(data => {
            if (data.length > 0) {
                this.setState({ reportData: [...data] });
                return;
            }

            this.setState({ reportData: [] })
        })
    }

    downloadInvoice = (invoiceNO) => {
        const { dispatch, currentSpace } = this.props;
        dispatch(getHostelWorldInvoicePDF(currentSpace.propertyID, invoiceNO));
    }

    render() {
        const { reportData = [] } = this.state;

        const { classes, t, open, isLoading, closeHandler } = this.props;

        return (
            <Dialog
                open={open}
                onClose={closeHandler}
                classes={{ paperFullWidth: classes.dialogPaper }}
                fullScreen={true}
            >
                {/* --------------------------------------------------------- */}
                {/* Header */}
                {/* --------------------------------------------------------- */}
                <div className={classes.dialog}>

                    <div className={classes.dialogTitle}>
                        <div className={classes.header}>{t('hostelworldChannel.invoices.title')}</div>
                        <CloseIcon className={classes.closeIcon} onClick={closeHandler} />
                    </div>

                    <div className={classes.mainContainer}>

                        <div className={clsx(classes.listSection, 'tableContent')}>

                            {isLoading && <div className={classes.loadingState}><CircularProgress /></div>}

                            {/* Blank State */}
                            {/* ------------------------------------------------------------------ */}
                            {!isLoading && reportData.length == 0 &&
                                <div className={clsx(classes.loadingState, classes.blankState)}>
                                    No invoices
                                </div>
                            }

                            {reportData.length > 0 && (
                                <BasicExport
                                    t={t}
                                    data={reportData}
                                    classes={classes}
                                    downloadInvoice={this.downloadInvoice}
                                    currentSpace={this.props.currentSpace}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const { spaces, dashboard, loading } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        isLoading: loading.GET_HOSTEL_WORLD_INVOICES ||
            loading.GET_HOSTEL_WORLD_INVOICE_PDF
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(ChannelLog)));