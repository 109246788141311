import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { sidebarMenu } from '../constant';

import SidebarItem from './SidebarItem';
import ConfirmForm from '../../../common/ConfirmForm';

import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    logo:{
        fontWeight: 600,
        fontSize: '1.4rem',
        color: '#000000',
        marginLeft: 13,
    },
    header: {
        height: '100px',
        margin: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 70,
        }
    },
    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    menuListContainer:{
        height: 'calc(100vh - 160px)',
        overflowY: 'auto'
    },
    deleteContainer: {
        marginBottom: 'auto',
        marginTop: 'auto',
        marginLeft: 25,
        marginRight: 25,
    },
    deleteButton: {
        borderRadius: '50%',
        cursor: 'pointer',
        height: 20,
        padding: 8,
        width: 20,
        '&:hover': {
        background: '#E0E0E0',
        }
    },
    closeBtn: {
        fontWeight: 600,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    drawerPaper: {
        width: '100%',
        backgroundColor: '#f2f2f2'
    },
    backIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 10,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
    },
    drawerContainer:{
        marginTop: '50px',
        height: 'calc(100vh - 100px)',
        overflowY: 'scroll',
    },
    headerLeft: {
        alignItems: 'center',
        color: '#1d1d1d',
        display: 'flex',
        width: '80%',
        [theme.breakpoints.down('xs')]: {
          maxWidth: 'calc(70vw - 12px)',
        },
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    },
    confirmContainer:{
        margin: '10px',
        display: 'flex',
    },
    confirmMobileContainer:{
        position: 'fixed',
        bottom: '0px',
        margin: '10px'
    }
}));

const Sidebar = props =>{
    const classes = useStyles();
    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const { 
        activeMenuID,
        navigateToMenuHandler,
        product,
        handleDeleteProduct
    } = props;

    const closeDrawerHandler = () =>{
        setOpenDrawer(false)
    }

    const handleConfirmDelete = () => {
        handleDeleteProduct();
        setOpenConfirm(false);
    }

    return(
        <div>
            <div className={classes.header}>
                <div className={classes.headerContent}>
                
                    <div className={classes.headerLeft}>
                        <Hidden smUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={()=>setOpenDrawer(true)}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>

                        <div className={classes.logo}>
                            {(product && product._id) ? product.name : 'Create Product'}
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <Hidden xsDown>
                <div className={clsx(classes.menuListContainer, 'hideScrollBar')}>
                    {Object.keys(sidebarMenu).map(menuID =>(
                        <SidebarItem 
                            key={`groupID_${menuID}`} 
                            menuID={menuID}
                            activeMenuID={activeMenuID}
                            navigateToMenuHandler={navigateToMenuHandler}
                            product={product}
                        />
                    ))}
                </div>
            

                {product._id && 
                    <div className={classes.deleteContainer}>
                        {!openConfirm && <img src={IMAGES.ICONS.bin} className={classes.deleteButton} onClick={() => setOpenConfirm(true)} />}
                        <ConfirmForm
                            title="Delete Product?"
                            open={openConfirm}
                            setOpen={setOpenConfirm}
                            onClose={() => setOpenConfirm(false)}
                            onConfirm={handleConfirmDelete}
                            >
                            Are you sure you would like to delete this product?
                        </ConfirmForm>
                    </div>
                }
            </Hidden>
     
            <nav className={classes.drawer} aria-label="rateplan sidebar">
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={openDrawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div className={classes.drawerContainer}>
                            <div className={classes.toolbar}>
                                <BackIcon 
                                    className={classes.backIcon} 
                                    onClick={closeDrawerHandler} 
                                />
                            </div>
                            {Object.keys(sidebarMenu).map(menuID =>(
                                <SidebarItem 
                                    key={`groupID_${menuID}`} 
                                    menuID={menuID}
                                    activeMenuID={activeMenuID}
                                    navigateToMenuHandler={navigateToMenuHandler}
                                    closeDrawerHandler={closeDrawerHandler}
                                    product={product}
                                />
                            ))}
                        </div>
                        <div className={classes.deleteContainer}>
                            {!openConfirm && <img src={IMAGES.ICONS.bin} className={classes.deleteButton} onClick={() => setOpenConfirm(true)} />}
                            <ConfirmForm
                                title="Delete Product?"
                                open={openConfirm}
                                setOpen={setOpenConfirm}
                                onClose={() => setOpenConfirm(false)}
                                onConfirm={handleConfirmDelete}
                                >
                                Are you sure you would like to delete this product?
                            </ConfirmForm>
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}

export default connect(null)(Sidebar);