const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_TODOS_SUCCESS":
      return {
        ...state,
        ...action.payload.comments
      }
    case "LOAD_FILES_SUCCESS":
      return {
        ...state,
        ...action.payload.comments
      }
    case "LOAD_ALL_FILES_SUCCESS":
      return {
        ...state,
        ...action.payload.comments
      }
    case "LOAD_ANNOUNCEMENTS_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.comments
      }
    case "ADD_ANNOUNCEMENT_COMMENT_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "ADD_TODO_COMMENT_SUCCESS":
      return {
        ...state,
        [action.payload.data._id]: action.payload.data
      }
    case "ADD_FILE_COMMENT_SUCCESS":
      return {
        ...state,
        [action.payload.data._id]: action.payload.data
      }
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};
  

