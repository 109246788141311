import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { uploadUserAvatar } from '../../redux/actions/user/user';

import {Button, Dialog, Typography, CircularProgress} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listSection: {
    padding: theme.spacing(3),
    width: 'calc(100% - 48px)'
  },
  selectButton: {
    background: '#eeeeee',
    borderRadius: '10px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      background: '#BDBDBD'
    }
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    width: '100%'
  },
  selectNewImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectButton: {
    background: '#FFFFFF',
    borderRadius: 10,
    boxShadow: '0 2px 20px #F0F0F0',
    color: '#000000',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    padding: '10px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px'
    }
  },
  image: {
    height: 200,
    width: 200
  },
  input: {
    display: 'none'
  },
  file: {
    paddingTop: 30,
    paddingBottom: 30
  },
  warning: {
    color: '#ff9800',
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    margin: theme.spacing(3)
  },
}))

const UploadPhoto = (props) => {
  const classes = useStyles();
  const {currentUser, file} = props;
  const [preview, setPreview] = React.useState(null);

  React.useEffect(() => {
    if (!file) { return }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file]);

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined)
      return
    }
    let newFile = e.target.files[0];
    setFile(newFile)
    if ((newFile.size / (1024 * 1024)) < 5) {
      setImgError(false)
    } else {
      setImgError(true)
      console.log('Please upload file with size less than 5MB', (file.size / (1024 * 1024)))
    }
  }

  const handleAvatarUpload = () => {
    props.dispatch(uploadUserAvatar(currentUser._id, file));
    props.handleClose();
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="profile-pic"
      maxWidth="sm"
      fullWidth={true}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Add Profile Picture</div>
          <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        </div>

          <div className={classes.listSection}>
            <div className={classes.fileContainer}>
              {preview && <img src={preview} className={classes.image} />}
            </div>
          </div>

          <div className={classes.listSection}>
            <div className={classes.selectNewImage}>
              <label className={classes.selectButton} htmlFor="outlined-button-file">
                Select a new image
              </label>
              <input
                accept="image/*"
                className={classes.input}
                id="outlined-button-file"
                type="file"
                onChange={handleCapture}
              />
            </div>
          </div>

          {/* --------------------------------------------------------------------- */}
          {/* UPLOAD */}
          {/* --------------------------------------------------------------------- */}
          <div className={classes.submit}>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleAvatarUpload}>Submit</Button>
          </div>

          {props.loadingPhotoUpload &&
            <div className={"loadingOverlay"}>
              <CircularProgress className={"loading"} />
            </div>
          }

      </div>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  currentUser: state.auth.user,
})

export default connect(mapStateToProps)(UploadPhoto);