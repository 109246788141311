const initialState = {default: true}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_QUESTIONS_SUCCESS":
      return {
        ...action.payload
      }
    case "SUBMIT_QUESTION_SUCCESS":
      if (state.default) {
        return {
          [action.payload._id]: action.payload
        }
      } else return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "SUBMIT_ANSWER_SUCCESS":
      const question = state[action.payload.questionID]
      return {
        ...state,
        [action.payload.questionID]: {
          ...question,
          answerCount: question.answerCount ? question.answerCount+1 : 1
        }
      }
    case "LOAD_THREAD_SUCCESS":
      const thread = state[action.payload._id]
      if (thread) {
        return {
          ...state,
          [action.payload._id]: {
            ...thread,
            viewCount: action.payload.viewCount
          }
        } 
      } else return {...state}
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    default:
      return state;
  }
};
  

