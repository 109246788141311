import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Select from 'react-select';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { setupCashAccounts, updateCashAccounts, updateCashSubAccounts } from '../../redux/actions/cash-drawers/cashAccounts';
import ConfirmForm from '../common/ConfirmForm';
import CashSubAccountItem from './CashSubAccountItem';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 250px)',
    width: '100%'
  },
  cashCategoryContainer: {
    height: '100%',
    marginBottom: 40,
    width: '100%',
  },
  cashCategoryName: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  description: {
    color: '#999999'
  },
  cashCategoryOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px'
  },
  cashCategoryMenu: {
    '& ul': {
      paddingBottom: 0,
      paddingTop: 0,
    }
  },
  moreIcon: {
    fontSize: '1.8rem'
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  edit: {
    marginBottom: 20,
  },
  editSection: {
    alignItems: 'center',
    display: 'flex',
  },
  cashCategoryEdit: {
    marginRight: 20,
    width: '50%',
    '& input': {
      fontSize: '1.4rem',
    }
  },
  cashCategoryDesc: {
    '& input': {
      fontSize: '1.3rem',
    }
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  cashAccountName: {
    backgroundColor: '#f0edeb',
    borderRadius: '10px',
    cursor: 'pointer',
    padding: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  newCashAccount: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: '0 1px 30px 0 #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10
  },
  button: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  select: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 10,
    marginTop: 10,
    width: '80%',
  },
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const CashAccountList = (props) => {
  const { t } = props
  const classes = useStyles();
  const [selectedCashAccount, setSelectedCashAccount] = React.useState(null);
  const { cashCategories, cashAccounts, categoryID, spaceID, propertyID, cashCategoryOptions } = props;
  const cashCategory = cashCategories[categoryID];
  const [createAccountFlag, setCreateAccountFlag] = React.useState(false);
  const [accountName, setAccountName] = React.useState('');
  const [editFlag, setEditFlag] = React.useState(false);
  const [deleteFlag, setDeleteFlag] = React.useState(false);
  const [form, setForm] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let list = [];
    list = [...cashCategoryOptions, ...list]
    if (cashCategory.name) list.push({ value: cashCategory.name, label: cashCategory.name })
    list = [...new Set(list.map(JSON.stringify))].map(JSON.parse);
    setOptions([...list])
  }, [cashCategoryOptions])


  // Edit Cash Category Functions
  // ----------------------------------
  const handleEdit = () => {
    setEditFlag(true);
    setAnchorEl(null);
  }

  const handleCancelUpdates = () => {
    setEditFlag(false);
    setDeleteFlag(false)
    setForm({});
  }

  const handleSubmitUpdates = () => {
    props.dispatch(updateCashAccounts(propertyID, { _id: categoryID, name: form.name, isActive: cashCategory.isActive }))
    handleCancelUpdates();
  }
  const handleDisable = () => {
    props.dispatch(updateCashAccounts(propertyID, { _id: categoryID, isActive: false }))
    handleCancelUpdates();
  }

  // Create New Cash Account Functions
  // ----------------------------------
  const handleCreateAccount = () => {
    setAnchorEl(null);
    setCreateAccountFlag(true);
  }

  const handleCancelNewAccount = () => {
    setCreateAccountFlag(false);
    setAccountName('');
  }

  const handleSubmitNewAccount = () => {
    const newCashAccount = [
      {
        "name": cashCategory.name,
        "subCategory": [
          {
            "name": accountName
          }
        ]
      }
    ]
    props.dispatch(setupCashAccounts(
      spaceID, propertyID, newCashAccount, cashCategories, cashAccounts))
    handleCancelNewAccount();
  }
  const handleUpdateSubAcc = (data) => {
    props.dispatch(updateCashSubAccounts(propertyID, data));
  }

  return (
    <div className={classes.cashCategoryContainer}>
      {!editFlag && <div className={classes.cashCategoryName}>

        {/* Cash Account Category */}
        {/* ------------------------------------------------ */}
        <div>
          <Typography variant="h5">{cashCategory.name}</Typography>
          <Typography variant="body2" className={classes.description}>{cashCategory.description}</Typography>
        </div>

        {/* Cash Account Category Menu */}
        {/* ------------------------------------------------ */}
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={classes.cashCategoryOptions}
        >
          <MoreHorizIcon className={classes.moreIcon} />
        </IconButton>
        <Menu
          id="cashCategory-menu"
          anchorEl={anchorEl}
          className={classes.cashCategoryMenu}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{ padding: 0 }}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <ListItem button onClick={handleCreateAccount}>
            <ListItemIcon className={classes.listItemIcon}>
              <AddIcon />
            </ListItemIcon>
            <Typography variant="body2">{t('frontdesk.cashAccount.addAccount')}</Typography>
          </ListItem>
          <ListItem button onClick={handleEdit} >
            <ListItemIcon className={classes.listItemIcon}>
              <EditIcon />
            </ListItemIcon>
            <Typography variant="body2">{t('frontdesk.cashAccount.editCategory')}</Typography>
          </ListItem>
          <ListItem button onClick={() => { setAnchorEl(null); setDeleteFlag(true)}}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="body2">{t('frontdesk.cashAccount.disableCategory')}</Typography>
          </ListItem>
        </Menu>
      </div>}

      <ConfirmForm
        open={deleteFlag}
        setOpen={setDeleteFlag}
        onClose={() => setDeleteFlag(false)}
        onConfirm={handleDisable}
      >
        Are you sure you would like to delete this cash category?
        </ConfirmForm>

      {/* Edit Cash Category */}
      {/* --------------------------------------------- */}
      {editFlag && (
        <div className={classes.edit}>
          <div className={classes.select}>
            <Select
              isSearchable={true}
              name="name"
              placeholder="Cash category name"
              defaultValue={options.find(m => m.value == cashCategory.name)}
              onChange={(option) => setForm({ ...form, name: option.value })}
              options={options}
              styles={selectStyle}
              theme={theme => ({
                ...theme,
                border: 0,
                colors: {
                  ...theme.colors,
                  primary: '#666666',
                  primary25: '#dddddd'
                },
              })}
            />
          </div>

          <div className={classes.submit}>
            <Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancelUpdates}>{t('actions.cancel')}</Button>
            <Button size="small" variant="outlined" onClick={handleSubmitUpdates}>{t('actions.save')}</Button>
          </div>
        </div>
      )}


      {/* Loop Through Accounts */}
      {/* --------------------------------------------- */}
      <Grid container spacing={1}>
        {cashCategory.subCategory.length > 0 && cashCategory.subCategory.map(account => (
          <Grid item xs={6} sm={6} md={4} key={account}>
            <div className={classes.cashAccountName} onClick={() => setSelectedCashAccount(account)}>
              {cashAccounts[account].name}
            </div>
          </Grid>
        ))}

        {/* Create New Category */}
        {/* --------------------------------------------- */}
        {createAccountFlag &&
          <Grid item xs={12} sm={12} md={12}>
            <div className={clsx(classes.cashAccountName, classes.newCashAccount)}>
              <TextField
                autoFocus
                fullWidth
                id="title"
                label={t('frontdesk.cashAccount.singleName')}
                margin="dense"
                onChange={(e) => setAccountName(e.target.value)}
                type="text"
                value={accountName}
              />
              <div className={classes.buttonHolder}>
                <CloseIcon className={classes.button} onClick={handleCancelNewAccount} />
                <DoneIcon className={classes.button} onClick={handleSubmitNewAccount} />
              </div>
            </div>
          </Grid>
        }

        {selectedCashAccount && cashAccounts[selectedCashAccount] &&
          <CashSubAccountItem
            open={true}
            data={cashAccounts[selectedCashAccount]}
            handleUpdate={handleUpdateSubAcc}
            handleClose={() => setSelectedCashAccount(null)}
          />
        }
      </Grid>

    </div>
  )
}

const mapStateToProps = state => ({
  cashAccounts: state.cashAccounts,
  cashCategories: state.cashCategories
})

export default withTranslation()(connect(mapStateToProps)(CashAccountList));