import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import { updateHWMigrationInfo } from '../../../redux/actions/hwMigration/hwMigration';

const useStyles = makeStyles(theme =>({
    root: {
        // border: "1px solid blue",
        margin: "0px auto",
        maxWidth: "360px",
        padding: "10px 0px",
    },
    formControl: {
        marginBottom: '25px'
    },
    displayField: {
        borderRadius: '8px',
        padding: '12px',
        backgroundColor: "#f4f4f4",
    }
}));

const validatePassword = (password = '') =>{
    let errors = {};
    let hasError = false;

    const validations = [
      { field: 'passwordLength', status: password.length > 7 },
      { field: 'passwordCharacter', status: (/(?=.*[a-z])(?=.*[A-Z])/).test(password) },
      { field: 'passwordNumber', status: (/([0-9])/).test(password) },
      { field: 'passwordEntry', status: Boolean(password) },
    ];

    validations.forEach(data =>{ 

      const isInvalid = !data.status;

      errors[data.field] = isInvalid; 

      if(isInvalid) hasError = true;

    });

    return { passwordErrors: { ...errors }, hasError }
}

const PersonalInfo = props =>{
    const classes = useStyles();
    const { t } = useTranslation();

    const { nextStep, stepInfo, ongoingStep, userEmail } = props;

    const isEditMode = ongoingStep > 1 || stepInfo?.passwordSet;

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        name: ''
    });

    const [error, setError] = React.useState({
        email: false, 
        passwordLength: false,
        passwordCharacter: false, 
        passwordNumber: false,
        passwordEntry: false,
        confirmPassword: false
    });

    useEffect(() =>{

        if(ongoingStep == 1) setFormData({ ...formData, email: userEmail });

        if(isEditMode){
            stepInfo;
            setFormData({
                ...formData,
                email: stepInfo.email,
                name: stepInfo.name || '',
                phone: stepInfo.phone || '',
            });
        }

    }, [stepInfo]);

    const onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });

        if(name == 'password'){
            setError({ 
                ...error,
                passwordLength: false,
                passwordCharacter: false, 
                passwordNumber: false,
                passwordEntry: false,
            });
            return;
        }

        setError({ ...error, [name]: false });
    }

    const validatePersonalInfo = () =>{
        let foundError = false;
        let errorStep = {};
        const { email, password, confirmPassword, name, phone } = formData;

        //-------------- USER NAME---------------
        if (!name || !name.trim()) {
            errorStep.name = true;
            foundError = true;
        }

        //-------------- USER EMAIL---------------
        if (!email || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            errorStep.email = true;
            foundError = true;
        }

        //-------------- PHONE---------------
        if (!phone || !phone.trim()) {
            errorStep.phone = true;
            foundError = true;
        }

        //-------------- PASSWORD---------------
        const { passwordErrors, hasError } = validatePassword(password);
        if(hasError){ 
            errorStep = { ...errorStep, ...passwordErrors };
            foundError = true;
        }

        //-------------- CONFIRM PASSWORD---------------
        if (confirmPassword !== password) {
            errorStep.confirmPassword = true;
            foundError = true;
        }

        if (foundError) {
            setError({...error, ...errorStep });
            return false;
        }
        return true;
    }

    const submitHandler = () =>{

        if(stepInfo?.passwordSet){
            nextStep();
            return;
        }

        if(!validatePersonalInfo()) return;

        const { userRole, activeStep, updateAPIParams, dispatch } = props;
        const { email, password,  phone, name } = formData;
        const data = {
            email, 
            password, 
            userRole,
            phone,
            name
        }

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data))
        .then(response =>{
            if(response.ongoingStep) nextStep(); 
        });
    }

    const getPasswordStatus = () =>( 
        error.passwordLength ||
        error.passwordCharacter|| 
        error.passwordNumber||
        error.passwordEntry
    );

    //EDIT PERSONAL DETAILS=======================================================

    const validateUpdateInfo = () =>{
        let foundError = false;
        let errorStep = {};

        const inputFields = [ 'name', 'phone' ];

        inputFields.forEach(fieldName =>{
            if (!formData[fieldName] || !formData[fieldName].trim()) {
                errorStep[fieldName] = true;
                foundError = true;
            }
        });

        if (foundError) {
            setError({...error, ...errorStep });
            return false;
        }

        return true;
    }

    const editPersonalDetails = () =>{

        if(!validateUpdateInfo()) return;

        const { userRole, activeStep, updateAPIParams, dispatch } = props;
        const { email, phone, name } = formData;
        const data = {
            email,
            userRole,
            phone,
            name
        }

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data))
        .then(response =>{
            if(response.ongoingStep) nextStep(); 
        });
    }

    //IF USER IS REGESTERED=================================================
    if(isEditMode){
        return(
            <div className={classes.root}>
                <div className="title">{t('hwMigration.title.step1')}<span style={{ fontSize: '1.3rem' }}>&#9996;</span></div>

                {/* ------------NAME INPUT------------------ */}
                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.name')}</div>
                    <TextField 
                        name="name"
                        variant="outlined" 
                        className="textInput"
                        placeholder="Robin Hood"
                        value={formData.name}
                        error={error.name}
                        onChange={onChangeHandler}
                    />
                    {error.name && (
                        <FormHelperText className="errorMessage">{t('hwMigration.formErrors.name')}</FormHelperText>
                    )}
                </div>

                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.workEmail')}</div>
                    <div className={classes.displayField}>{stepInfo?.email}</div>
                </div>

                {/* ------------PHONE INPUT------------------ */}
                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.phone')}</div>
                    <TextField 
                        name="phone"
                        variant="outlined" 
                        placeholder="123456"
                        className="textInput"
                        value={formData.phone}
                        error={error.phone}
                        onChange={onChangeHandler}
                    />
                    {error.phone && (
                        <FormHelperText className="errorMessage">{t('hwMigration.formErrors.phone')}</FormHelperText>
                    )}
                </div>

                <Button 
                    onClick={editPersonalDetails}
                    className="nextButton"
                >
                    {t('actions.next')}
                </Button>
            </div>
        )
    }

    //IF USER IS NOT REGESTERED==============================================
    return (
        <div className={classes.root}>
            <div className="title">{t('hwMigration.title.step1')} <span style={{ fontSize: '1.5rem', marginLeft: 5 }}>&#9996;</span></div>

            {/* ------------NAME INPUT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.name')}</div>
                <TextField 
                    name="name"
                    variant="outlined" 
                    className="textInput"
                    placeholder="Robin Hood"
                    error={error.name}
                    onChange={onChangeHandler}
                />
                {error.name && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.name')}</FormHelperText>
                )}
            </div>

            {/* ------------EMAIL INPUT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.workEmail')}</div>
                <TextField 
                    name="email"
                    variant="outlined" 
                    placeholder="robin@hood.com"
                    className="textInput"
                    value={formData.email}
                    error={error.email}
                    onChange={onChangeHandler}
                />
                {error.email && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.workEmail')}</FormHelperText>
                )}
            </div>

            {/* ------------PHONE INPUT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.phone')}</div>
                <TextField 
                    name="phone"
                    variant="outlined" 
                    placeholder="123456"
                    className="textInput"
                    error={error.phone}
                    onChange={onChangeHandler}
                />
                {error.phone && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.phone')}</FormHelperText>
                )}
            </div>

            {/* ------------PASSWORD INPUT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.password')}</div>
                <TextField 
                    name="password"
                    type="password"
                    variant="outlined" 
                    placeholder="Password"
                    className="textInput"
                    error={getPasswordStatus()}
                    onChange={onChangeHandler}
                />

                {error.passwordEntry && <FormHelperText className="errorMessage">{t('hwMigration.formErrors.password.required')}</FormHelperText>}
                {!error.passwordEntry && (
                    <>
                        {error.passwordLength && <FormHelperText className="errorMessage">{t('hwMigration.formErrors.password.minLength')}</FormHelperText>}
                        {error.passwordCharacter && <FormHelperText className="errorMessage">{t('hwMigration.formErrors.password.casing')}</FormHelperText>}
                        {error.passwordNumber && <FormHelperText className="errorMessage">{t('hwMigration.formErrors.password.number')}</FormHelperText>}
                    </>
                )}
            </div>

            {/* ------------CONFIRM PASSWORD INPUT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.confirmPassword')}</div>
                <TextField 
                    type="password"
                    name="confirmPassword"
                    variant="outlined" 
                    placeholder="Password"
                    className="textInput"
                    error={error.confirmPassword}
                    onChange={onChangeHandler}
                />
                {error.confirmPassword && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formLabels.confirmPassword')}</FormHelperText>
                )}
            </div>

            <Button 
                onClick={submitHandler}
                className="nextButton"
            >
                {t('actions.next')}
            </Button>

        </div>
    )
}

const mapStateToProps = state =>{
    const { hwMigration } = state;
    return { 
        stepInfo: hwMigration?.step1 || {}
    }
}

export default connect(mapStateToProps)(PersonalInfo);