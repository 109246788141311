import React from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';
import mapOutline from '../../data/mapOutline.json';

const CountryMap = (props) => {

    // console.log(props.data, props.max)

    return (
        <ResponsiveChoropleth
            data={props.data}
            features={mapOutline.features}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            colors="nivo"
            domain={[ 0, props.max ]}
            unknownColor="#F0F0F0"
            label="properties.name"
            valueFormat="0.2s"
            projectionTranslation={[ 0.5, 0.5 ]}
            projectionRotation={[ 0, 0, 0 ]}
            enableGraticule={false}
            borderWidth={0.5}
            borderColor="#BDBDBD"
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'column',
                    justify: true,
                    translateX: 20,
                    translateY: -100,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: 'left-to-right',
                    itemTextColor: '#444444',
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000000',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default CountryMap;