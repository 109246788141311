import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD BOOKING METRICS
//----------------------------------------------------------------
export function loadBookingMetrics(propID, startDate, endDate, level, settings, roomTypeIDs) {
  const ACTION_NAME = "LOAD_BOOKING_METRICS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
      .post(`/api/frontdesk/reports/${settings.api}`, {
        propertyID: propID,
        startDate,
        endDate,
        roomTypeIDs,
        unit: settings.unit,
        level
      })
      .then(successResult => {
        const data = successResult.data;
        // console.log(settings.label, data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, label: settings.label} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD NZ BOOKING STATS
//----------------------------------------------------------------
export function loadBookingStats(propID, startDate, endDate) {
  const ACTION_NAME = "LOAD_BOOKING_STATS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/bookingStats/${propID}?startDate=${startDate}&endDate=${endDate}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD AVAILABLE UNITS
//----------------------------------------------------------------
export function loadAvailableUnits(propID, startDate, endDate) {
  const ACTION_NAME = "LOAD_AVAILABLE_UNITS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/availableUnits/${propID}?startDate=${startDate}&endDate=${endDate}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET BOOKING METRICS
//----------------------------------------------------------------
export const resetBookingMetrics = () => ({
  type: 'RESET_BOOKING_METRICS'
})

//----------------------------------------------------------------
// RESET BOOKING STATS
//----------------------------------------------------------------
export const resetBookingStats = () => ({
  type: 'RESET_BOOKING_STATS'
})


