import { sidebarMenu } from './constant';

export const getNextStepInfo = (currentMenuID) =>{

    const menuList = Object.keys(sidebarMenu);
    const currentMenuIndex = menuList.indexOf(currentMenuID);
    const nextMainStepIndex = currentMenuIndex + 1;

    if(!menuList[nextMainStepIndex]) return;

    const nextMenu = sidebarMenu[menuList[nextMainStepIndex]];

    return {
        menuID: nextMenu.id
    }
}

export const getPrevStepInfo = (currentMenuID) =>{
        const menuList = Object.keys(sidebarMenu);
        const currentMenuIndex = menuList.indexOf(currentMenuID);
        const prevMenuIndex = currentMenuIndex - 1;

        if(!menuList[prevMenuIndex]) return;
        
        const prevMenu = sidebarMenu[menuList[prevMenuIndex]];
        
    return {
        menuID: prevMenu.id
    }
}

export const getMenuStepInfo = menuID =>{

    const menuList = Object.keys(sidebarMenu);

    const stepNo = menuList.indexOf(menuID) + 1;

    const totalSteps = menuList.length;

    const progressVal = Math.ceil(stepNo * 100 / totalSteps);

    return { stepNo, totalSteps, progressVal }
}

export const getHeaderTitle = (menuID) =>{
    console.log('menuID ',menuID);
    const activeMenuIfo = sidebarMenu[menuID];
    return activeMenuIfo.headerText;
}

const diffValidation = (diff) => {
    return diff.roomTypeID && diff.roomTypeID!=='' && diff.value && diff.type
}

const linkValidation = (item) => {
    return item.baseRoomTypeID && item.baseRoomTypeID!=='' && item.differentials.every(diffValidation)
}

export const getSubMenuStatus = (mainMenuID, ratePlan) =>{
    let success = false;

    if(mainMenuID == sidebarMenu.BASIC.id) {
        success = ratePlan.publicName && ratePlan.publicName.length > 0 &&
                    ratePlan.privateName && ratePlan.privateName.length > 0 &&
                    ratePlan.description && ratePlan.description.length > 0
        return success
    }

    if(mainMenuID == sidebarMenu.ROOMTYPE.id) {
        success = ratePlan.roomTypeIDs && ratePlan.roomTypeIDs.length > 0
        return success
    }

    if(mainMenuID == sidebarMenu.RATES.id) {
        success = (ratePlan.isDerived===false) ||
                    (ratePlan.isDerived && ratePlan.derivedRate && 
                    ratePlan.derivedRate.reference !=='' && 
                    ratePlan.derivedRate.percentOrAmount && ratePlan.derivedRate.percentOrAmount.length > 0 &&
                    ratePlan.derivedRate.value && ratePlan.derivedRate.value!=='')
        return success
    }

    if(mainMenuID == sidebarMenu.CANCELLATION.id) {
        success = (ratePlan.nonRefundable && ratePlan.nrPolicy) || 
                    (ratePlan.nonRefundable===false && ratePlan.cancellationPolicy && 
                    ratePlan.cancellationPolicy.duration && ratePlan.cancellationPolicy.duration !== '' && 
                    ratePlan.cancellationPolicy.conditions && ratePlan.cancellationPolicy.conditions.length > 0 && 
                    ratePlan.cancellationPolicy.percentChargeable && ratePlan.cancellationPolicy.percentChargeable !== '')
        return success
    }

    if(mainMenuID == sidebarMenu.VISIBILITY.id) {
        success = ratePlan.visibility && ratePlan.visibility.length > 0
        return success
    }

    if(mainMenuID == sidebarMenu.ROOMTYPELINKS.id) {
        success = ratePlan.roomTypeLinks && (
                    (ratePlan.roomTypeLinks.length===0) || 
                    (ratePlan.roomTypeLinks.every(linkValidation))
                )
        return success
    }

    if(mainMenuID == sidebarMenu.MULTICURRENCY.id) {
        success = ratePlan.multiCurrency && ratePlan.multiCurrency.enabled
                    ? ratePlan.multiCurrency.currency && ratePlan.multiCurrency.conversionRate > 0
                    : true
        return success
    }

}

export const getProgress = (ratePlan) => {
    let count = 0;

    if (ratePlan.publicName && ratePlan.publicName.length > 0 &&
        ratePlan.privateName && ratePlan.privateName.length > 0 &&
        ratePlan.description && ratePlan.description.length > 0
    ) count += 1;

    if (
        ratePlan.roomTypeIDs && ratePlan.roomTypeIDs.length > 0
    ) count += 1

    if (
        (ratePlan.isDerived===false) ||
        (ratePlan.isDerived && ratePlan.derivedRate && 
        ratePlan.derivedRate.reference !=='' && 
        ratePlan.derivedRate.percentOrAmount && ratePlan.derivedRate.percentOrAmount.length > 0 &&
        ratePlan.derivedRate.value && ratePlan.derivedRate.value!=='')
    ) count += 1

    if (
        ratePlan.nonRefundable && ratePlan.nrPolicy || 
        (ratePlan.nonRefundable===false && ratePlan.cancellationPolicy && 
        ratePlan.cancellationPolicy.duration && ratePlan.cancellationPolicy.duration !== '' && 
        ratePlan.cancellationPolicy.conditions && ratePlan.cancellationPolicy.conditions.length > 0 && 
        ratePlan.cancellationPolicy.percentChargeable && ratePlan.cancellationPolicy.percentChargeable !== '')
    ) count += 1

    if (
        ratePlan.visibility && ratePlan.visibility.length > 0
    ) count += 1

    if (
        ratePlan.roomTypeLinks && (
            (ratePlan.roomTypeLinks.length===0) || 
            (ratePlan.roomTypeLinks.every(linkValidation))
        )
    ) count += 1

    if (ratePlan.multiCurrency && ratePlan.multiCurrency.enabled) {
        if (ratePlan.multiCurrency.currency && ratePlan.multiCurrency.conversionRate > 0)
          count++
    }
    // Since multi currency is optional
    else count++

    return count
}