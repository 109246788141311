import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import 'react-dates/lib/css/_datepicker.css';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme =>({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // border: '1px solid rgba(0, 0, 0, .125)',
        color: '#0101018a'
    },
    icons: {
        padding: '12px',
        borderRadius: '50%',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '&.disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            pointerEvents: 'none'
        }
    },
    textInfo: {
        fontSize: '1.2rem',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        lineHeight: 1.66,
    },
    formControl:{
        width: '100px',
        '& .MuiSelect-select.MuiSelect-select':{
            paddingRight: '30px',
            paddingLeft: '10px',
        },
        '& .MuiInput-underline:before':{
            borderBottom: 'unset'
        },
        '& .MuiInput-underline:after':{
            borderBottom: 'unset'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before':{
            borderBottom: 'unset'
        }
    }
});

class Pagination extends Component {

    setPaginationLimit = event =>{
        const { handlePaginationChange } = this.props;
        const value = event.target.value;
        const paginationData = {
            limit: value,
            offset: 0
        }
        handlePaginationChange(paginationData);
    }
    
    getPaginationDisplay = () =>{
        const { pagination, dataCount } = this.props;
        const currentPage = pagination.currentOffset + 1;
        
        let currentPageLimit =  currentPage + (dataCount - 1);

        const displayData = `${currentPage}-${currentPageLimit} of ${pagination.total}`;
        return displayData;
    }

    handleNextPage = () =>{
        const { pagination, handlePaginationChange } = this.props;
        const limit = pagination.selectedLimit;
        const nextOffset = pagination.currentOffset + pagination.selectedLimit;

        if(nextOffset >= pagination.total) return;

        const paginationData = {
            limit,
            offset: nextOffset
        }

        handlePaginationChange(paginationData);
    }

    handlePrevPage = () =>{
        const { pagination, handlePaginationChange } = this.props;
        const limit = pagination.selectedLimit;
        const prevOffset = pagination.currentOffset - pagination.selectedLimit;

        if(prevOffset < 0) return;

        const paginationData = {
            limit,
            offset: prevOffset
        }

        handlePaginationChange(paginationData);
    }

    handleFirstPage = ()=>{
        const { pagination, handlePaginationChange } = this.props;

        if(pagination.currentOffset == 0) return;
        
        const paginationData = {
            limit: pagination.selectedLimit,
            offset: 0
        }
        handlePaginationChange(paginationData);
    }

    handleLastPage = ()=>{
        const { pagination, handlePaginationChange, dataCount } = this.props;

        const limit = pagination.selectedLimit;
        const lastPage = Math.ceil(pagination.total / pagination.selectedLimit);
        const offset = (lastPage - 1) * pagination.selectedLimit;
        const nextOffset = pagination.currentOffset + dataCount

        if(pagination.total == nextOffset) return;

        const paginationData = {
            limit,
            offset
        }

        handlePaginationChange(paginationData);
    }

    getDisbledStatus = type =>{
        const { pagination, dataCount } = this.props;
        let status = false;

        switch(type){

            case 'firstPage':
                status = pagination.currentOffset == 0 ? true : false;
                return status;

            case 'lastPage':
                const nextOffset = pagination.currentOffset + dataCount;
                status = pagination.total == nextOffset ? true : false;
                return status;

            default: 
                return;
        }
    }

    render(){
        const { classes, limitOptions, pagination } = this.props;
        return(
            <div className={classes.root}>
                <div>
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // defaultValue={limitOptions[0].value}
                            value={pagination.selectedLimit}
                            onChange={this.setPaginationLimit}
                        >
                            {limitOptions.map(option =>(
                                <MenuItem 
                                    key={`listOptions_${option.value}`}
                                    value={option.value}
                                >
                                    { option.label } rows
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
            
                </div>
                

                <FirstPageIcon 
                    className={clsx(classes.icons, { disabled: this.getDisbledStatus('firstPage') })}
                    onClick={this.handleFirstPage}
                />
                
                <NavigateBeforeIcon 
                    className={clsx(classes.icons, { disabled: this.getDisbledStatus('firstPage') })}
                    onClick={this.handlePrevPage}
                />

                <div className={classes.textInfo}>{this.getPaginationDisplay()}</div>

                <NavigateNextIcon 
                    className={clsx(classes.icons, { disabled: this.getDisbledStatus('lastPage') })}
                    onClick={this.handleNextPage}
                />
                <LastPageIcon 
                    className={clsx(classes.icons, { disabled: this.getDisbledStatus('lastPage') })}
                    onClick={this.handleLastPage}
                />
            </div>
        )
    }
}

export default withStyles(styles)(Pagination)
