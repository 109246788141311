import http from "../../utils/http"

export function getSettings(propertyID) {
  const ACTION_NAME = "GET_ONLINECHECKIN_SETTINGS"
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`api/frontdesk/onlineCheckin/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_ERROR`, payload:errorResult });
      })
  }
}

export function updateSettings(propertyID, type, data) {
  let settingsData = {...data}
  const formData = new FormData();

  if(type === 'general') {
    formData.append('logo', settingsData.logo)
    delete settingsData.logo

    formData.append('pic', settingsData.pic)
    delete settingsData.pic
  }

  if(type === 'pointOfContact') {
    formData.append('contact', settingsData.contact)
    delete settingsData.contact
  }

  Object.keys(settingsData).forEach(k => {
    formData.append(k, settingsData[k])
  })

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const ACTION_NAME = "UPDATE_ONLINECHECKIN_SETTINGS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/onlineCheckin/${propertyID}?type=${type}`, formData, config)
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:successResult.data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult
      });
  };
}

export const resetSettings = () => (dispatch) => {
  dispatch({ type: "RESET_CHECKIN_SETTINGS" })
};