import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { stepCount } from '../../redux/selectors/space/stepCount';
import { 
    setCurrentSpace, 
    setCurrentSpaceType,
} from '../../redux/actions/dashboard/dashboard';
import { setOnboarding } from '../../redux/actions/landing/uiConfig';

const useStyles = makeStyles(theme =>({
    hostelExists: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    status: {
        alignItems: 'center',
        background: '#BB5533',
        cursor: 'pointer',
        display: 'flex',
        height: 24,
        padding: '8px 15px',
        width: '40%',
        [theme.breakpoints.down("sm")]:{
            width: '100%',
        }
    },
    statusBar: {
        backgroundColor: '#E6BBB3',
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#FFFFFF'
        }
      },
    statusBar: {
        width: '65%',
        backgroundColor: '#E6BBB3',
        '&.MuiLinearProgress-root':{
            height: '10px',
            borderRadius: '20px',
        },
        '& .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#FFFFFF',
            borderRadius: '20px'
        },
        [theme.breakpoints.down("sm")]:{
            width: '50%',
        }
    },
    statusText: {
        color: '#FFFFFF',
        fontSize: '1.2rem',
        fontWeight: 600,
        width: '35%',
        [theme.breakpoints.down("sm")]:{
            width: '50%',
        }
    },
}));

const PmsProgressSection = props =>{
    const classes = useStyles();
    const { currentUser, stepCount, spaces , currentSpace, currentSpaceType, currentSpaceID, dispatch} = props;
    const [hostelExists, setHostelExists] = React.useState(false);

    useEffect(() => {
        let hostelFound;
        if (spaces) {
            hostelFound = Object.values(spaces).find(item => (item.type !== 'Association' || item.type !== 'Community'));
            if (hostelFound) setHostelExists(true);
        }
    }, [spaces]);

    const handleOnboardingClick = () => {
        if (currentSpaceID === 'community' || currentSpaceType === 'Association') {
          let hostel = Object.values(spaces).find(item => (item.type !== 'Association' || item.type !== 'Community'));
          dispatch(setCurrentSpace(hostel._id));
          dispatch(setCurrentSpaceType(hostel.type));
        }
        dispatch(setOnboarding(true));
    }

    if(currentSpace && (currentSpace.onboardingStatus ==='trial' || !currentSpace.onboardingStatus)){
        return(
            <div className={classes.hostelExists}>
                <div className={classes.status} onClick={handleOnboardingClick}>
                    <div className={classes.statusText}>Get started - Free PMS</div>
                    <LinearProgress className={classes.statusBar} variant="determinate" value={(stepCount / 4) * 100} />
                </div>
            </div>
        )
    }

    return null;
}

const mapStateToProps = state => {
    const { spaces, dashboard, auth } = state;
    return{
        spaces,
        currentSpace: spaces[dashboard.currentSpace],
        currentSpaceType: dashboard.currentSpaceType,
        currentSpaceID: dashboard.currentSpace,
        currentUser: auth.user,
        stepCount: stepCount(spaces, dashboard.currentSpace, dashboard.currentSpaceType, auth.user)
    }
};

export default connect(mapStateToProps)(PmsProgressSection);