import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import Select from 'react-select';
import Snackbar from '@material-ui/core/Snackbar';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';

import NumberInput from '../../common/NumberInput';
import ImageUpload from './ImageUpload';
import ToggleButton from './ToggleButon';
import { fetchDatePerTimezone, filterDataFields } from '../../../utils/utility';
import { updateSettings } from '../../../redux/actions/invoice/proforma';
import { 
    PROFORMA_FIELDS,
    INVOICE_FIELDS,
    TAX_RECEIPT_FIELDS, 
    COMMON_INVOICE_FIELDS, 
    FIELD_NAMES as FORM_FIELDS 
} from '../constants';

const useStyles = makeStyles(theme =>({
    root: {
        marginTop: '10px'
    },
    header:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: "40px",
    },
    formContainer: {
        display: 'flex',
        marginBottom: "20px",
        justifyContent: "space-between",

        '&.helperText': {
            lineHeight: "15px",
            wordBreak: "break-word",
        },

        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
        }
    },
    inputContainer: {
        width: '35%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    inputLabel: {
        width: '60%',
        margin: "auto 0px",
        color: "#666666",
        fontSize: "1.2rem",
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '5px'
        }
    },
    textInput: {
        boxShadow: "0 1px 10px #e0e0e0",
        padding: "0px 15px",
        borderRadius: "10px",
        width: '100%',
        height: '35px'
    },

    icon: {
        boxShadow: "0 1px 10px #E0E0E0",
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        width: 20,
        height: 20
    },
    checkedIcon: {
        borderRadius: 5,
        backgroundColor: '#4F9581',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 20,
          height: 20,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""'
        },
        'input:hover ~ &': {
          backgroundColor: '#4F9581'
        }
    },
    helperText: {
        margin: '8px 0px',
        color: "#666666",
        fontSize: "1.1rem",
        fontWeight: 400,
    },
    datePickerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    datePicker: {
        borderRadius: 10,
        boxShadow: '0 2px 30px #F0F0F0',
        paddingLeft: 10,
        paddingRight: 10,
        '& input': {
            borderRadius: 10,
            color: '#484848',
            fontWeight: 500,
        },
        '& .DateInput': {
            width: '100%'
        }
    },
    todaysDate: {
        fontSize: "1.1rem",
        border: "1px solid #999999",
        color: "#666666",
        borderRadius: "5px",
        padding: "10px",
        minWidth: "110px",
        boxSizing: "border-box",
    },
    ColumnFormContainer:{
        marginBottom: '30px',
        '& $inputContainer': {
            marginTop: '10px',
            width: '100%'
        },
        '& $inputContainer $textInput': {
            borderRadius: "5px",
            height: "40px",
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        background: '#000000',
        borderRadius: 25,
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '1.25rem',
        fontWeight: 600,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: 10,
        paddingBottom: 10
    },
    toggleContainer: {
        minWidth: '30%',
        [theme.breakpoints.down('xs')]: {
            minWidth: '50%'
        }
    }
}));

const selectStyle = {
    container: base => ({
        ...base,    
        boxShadow: "0 1px 10px #e0e0e0",
        borderRadius: "10px",
    }),
    control: base => ({
        ...base,
        border: 0,
        paddingLeft: 8,
        minHeight: 'unset',
        width: '100%',
        borderRadius: "10px",
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    })
}

const resetOptions = [
    { label: 'Month', value: 'MONTH' },
    { label: 'Year', value: 'YEAR' },
    { label: 'None', value: 'NONE' }
];

const DEFAULT_FORM_DATA = {
    ...TAX_RECEIPT_FIELDS,
    ...COMMON_INVOICE_FIELDS
}

function getReceiptNumberFormat(digits, number, prefix, suffix) {
    let numStr = number.toString()

    if(numStr.length < digits) {
        let predecessor = ''
        for (let i = 0; i < digits - numStr.length; i ++){
            predecessor = predecessor + '0'
        }
        numStr = predecessor + numStr
    }

    return `${prefix}/${numStr}/${suffix}`
}

function getFieldValues(formData) {
    let data = {};
    for(const [field, fieldName] of Object.entries(FORM_FIELDS)){
        data[field] = formData[fieldName];
    }   
    return data;
}

const TaxReceipts = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { invoiceSettings, currentSpace, dispatch } = props;
    const [fixLength, setFixLength] = useState(false);
    const [formData, setFormData] = useState({ ...DEFAULT_FORM_DATA });
    const [receiptNumberFormat, setReceiptNumberFormat] = useState('');
    const [showSaveBtn, setShowSaveBtn] = useState(false);
    const [datePickerFocus, setDatePickerFocus] = useState(false);
    const [message, setMessage] = useState('');
    useEffect(() =>{
        const filteredFormData = filterDataFields(invoiceSettings, DEFAULT_FORM_DATA);

        filteredFormData.startCountDate = moment(filteredFormData.startCountDate, "YYYY-MM-DD[T]HH:mm:ss");

        displayReceiptNumberFormat(filteredFormData);

        if(filteredFormData.taxDigits > 0) setFixLength(true);
        setFormData({ ...filteredFormData });
    }, []);

    const formChangeHandler = (name, value) =>{
        setFormData({ ...formData, [name]: value });
        setShowSaveBtn(true);
    }

    const toggleHandler = (name, status) =>{
        formChangeHandler(name, status);
    }

    const checkboxHandler = event =>{
        const isChecked = event.target.checked;
        setFixLength(isChecked);
        if(!isChecked) formChangeHandler(FORM_FIELDS.taxDigits, 0);
    }

    const onImageChangeHandler = image =>{
        formChangeHandler(FORM_FIELDS.logoImage, image);
    }

    const onInputChangeHandler = event =>{
        const { name, value } = event.target;
        const updatedFormData = { ...formData, [name]: value };
        if([ FORM_FIELDS.taxPrefix, FORM_FIELDS.taxSuffix ].includes(name)){
            displayReceiptNumberFormat(updatedFormData);
        }
        formChangeHandler(name, value);
    }

    const onNumberInputChange = event =>{
        const { name, value } = event.target;
        const formattedValue = parseInt(value);
        const updatedFormData = { ...formData, [name]: formattedValue };
        if([ FORM_FIELDS.taxIndexNumber, FORM_FIELDS.taxDigits ].includes(name)){
            displayReceiptNumberFormat(updatedFormData);
        }
        formChangeHandler(name, value);
    }

    const displayReceiptNumberFormat = (data) =>{
        const { 
            [FORM_FIELDS.taxDigits]: taxDigits, 
            [FORM_FIELDS.taxIndexNumber]: taxIndexNumber, 
            [FORM_FIELDS.taxPrefix]: taxPrefix, 
            [FORM_FIELDS.taxSuffix]: taxSuffix 
        } = data;
        const numberFormat = getReceiptNumberFormat(taxDigits, taxIndexNumber, taxPrefix, taxSuffix);
        setReceiptNumberFormat(numberFormat);
    }

    const onSelectHandler = (data, event) =>{
        formChangeHandler(event.name, data.value);
    }

    const onDateChange = (dateObj) =>{
        formChangeHandler(FORM_FIELDS.startCountDate, dateObj);
    }

    const setTodaysDate = () =>{
        const todaysDate = fetchDatePerTimezone(null, currentSpace.timezone);
        onDateChange(todaysDate);
    }

    const onSubmitHandler = () =>{
        const payloadFields = { 
            ...PROFORMA_FIELDS,
            ...INVOICE_FIELDS
        }
        const filteredPayloadData = filterDataFields(invoiceSettings, payloadFields);
        const payload = { ...filteredPayloadData, ...formData };

        dispatch(updateSettings(currentSpace.propertyID, payload))
        .then(response => {
            if(response) {
                setMessage(t('taxReceipts.saveSettingsText'))
                setShowSaveBtn(false);
            }
        });
    }

    const fieldValues = getFieldValues(formData);

    return (
        <div className={classes.root}>

            <div className={classes.header}>
                <h5>{t('taxReceipts.title')}</h5>
                <div className={classes.toggleContainer}>
                    <ToggleButton
                        name={FORM_FIELDS.taxInvoiceEnabled}
                        isOn={fieldValues.taxInvoiceEnabled}
                        onChangeHandler={toggleHandler}
                    />
                </div>
            </div>

            {fieldValues.taxInvoiceEnabled && (
                <>
                    {/* ----------------ISSUER NAME------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('invoice.issuerName')}</p>
                        <div className={classes.inputContainer}>
                            <InputBase 
                                name={FORM_FIELDS.issuerName}
                                className={classes.textInput}
                                value={fieldValues.issuerName}
                                onChange={onInputChangeHandler}
                            />
                        </div>
                    </div>

                    {/* ----------------TAX RECEIPT PREFIX------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('taxReceipts.title')} {t('invoice.prefix')}</p>
                        <div className={classes.inputContainer}>
                            <InputBase 
                                name={FORM_FIELDS.taxPrefix}
                                className={classes.textInput}
                                value={fieldValues.taxPrefix}
                                onChange={onInputChangeHandler}
                            />
                        </div>
                    </div>
                    
                    {/* ----------------TAX RECEIPT SUFFIX------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('taxReceipts.title')} {t('invoice.suffix')}</p>
                        <div className={classes.inputContainer}>
                            <InputBase
                                name={FORM_FIELDS.taxSuffix}
                                className={classes.textInput}
                                value={fieldValues.taxSuffix}
                                onChange={onInputChangeHandler}
                            />
                        </div>
                    </div>

                    {/* ----------------PROPERTY LOGO------------------- */}
                    <div className={classes.ColumnFormContainer}>
                        <p className={classes.inputLabel}>{t('invoice.propertyLogo')}</p>
                        <ImageUpload 
                            selectedLogo={fieldValues.logoImage} 
                            onChangeHandler={onImageChangeHandler}
                        />
                    </div>

                    {/* ----------------START TAX INDEX------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>Starting Index for Tax Receipt<sup>*</sup></p>
                        <div className={classes.inputContainer}>
                            <NumberInput 
                                name={FORM_FIELDS.taxIndexNumber}
                                className={classes.textInput} 
                                min={0}
                                value={fieldValues.taxIndexNumber}
                                onChange={onNumberInputChange}
                            />
                        </div>
                    </div>

                    {/* ----------------FIX TAX RECEIPT LENGTH------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>Fix the length of Tax Receipt</p>
                        <div className={classes.inputContainer}>
                            <Checkbox
                                color="primary"
                                checked={fixLength}
                                checkedIcon={<span className={classes.checkedIcon} />}
                                icon={<span className={classes.icon} />}
                                onChange={checkboxHandler}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </div>
                    </div>

                    {/* ----------------LENGTH OF TAX RECEIPT NUMBER------------------- */}
                    {fixLength && (
                        <div className={clsx(classes.formContainer, 'helperText')}>
                            <p className={classes.inputLabel}>Length of Tax Receipt Number</p>
                            <div className={classes.inputContainer}>
                                <NumberInput 
                                    name={FORM_FIELDS.taxDigits}
                                    className={classes.textInput} 
                                    min={0}
                                    value={fieldValues.taxDigits}
                                    onChange={onNumberInputChange}
                                />
                                <p className={classes.helperText}>
                                    {t('taxReceipts.numberDisplayHelperText')} - <b>{receiptNumberFormat}</b>
                                </p>
                            </div>
                        </div>
                    )}

                    {/* ----------------ISSUED COUNT RESET------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('invoice.countReset')}</p>
                        <div className={classes.inputContainer}>
                            <Select
                                name={FORM_FIELDS.countResetOn}
                                styles={selectStyle}
                                options={resetOptions}
                                value={resetOptions.find(option => option.value == fieldValues.countResetOn)}
                                onChange={onSelectHandler}
                            />
                        </div>
                    </div>

                    {/* ----------------SHOW LEGAL NAME------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('invoice.legalName')}</p>
                        <div className={classes.inputContainer}>
                            <ToggleButton
                                name={FORM_FIELDS.showLegalName}
                                isOn={fieldValues.showLegalName}
                                onChangeHandler={toggleHandler}
                            />
                        </div>
                    </div>

                    {/* ----------------SHOW TAX INFO------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('invoice.taxInfo')}</p>
                        <div className={classes.inputContainer}>
                            <ToggleButton
                                name={FORM_FIELDS.showTaxInfo}
                                isOn={fieldValues.showTaxInfo}
                                onChangeHandler={toggleHandler}
                            />
                        </div>
                    </div>

                    {/* ----------------START COUNT DATE------------------- */}
                    <div className={classes.formContainer}>
                        <p className={classes.inputLabel}>{t('invoice.countDate')} <sup>*</sup></p>
                        <div className={clsx(classes.inputContainer, 'datePicker')}>
                            <div className={classes.datePickerContainer}>
                                <div className={classes.datePicker}>
                                    <SingleDatePicker
                                        date={fieldValues.startCountDate}    
                                        onDateChange={onDateChange}
                                        focused={datePickerFocus} 
                                        onFocusChange={({ focused }) => setDatePickerFocus(focused)} 
                                        id="startCountDate"
                                        numberOfMonths={1}
                                        displayFormat={'DD MMM YYYY'}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        noBorder={true}
                                        block
                                    />
                                </div>
                                <ButtonBase
                                    className={classes.todaysDate}
                                    onClick={setTodaysDate}
                                >
                                    {t('taxReceipts.setTodaysDate')}
                                </ButtonBase>
                            </div>
                        </div>
                    </div>

                    {/* ----------------CUSTOM TAX RECEIPTS TEXT------------------- */}
                    <div className={classes.ColumnFormContainer}>
                        <p className={classes.inputLabel}>{t('taxReceipts.customText')}</p>
                        <div className={classes.inputContainer}>
                            <InputBase
                                name={FORM_FIELDS.customText}
                                className={classes.textInput}
                                value={fieldValues.customText}
                                onChange={onInputChangeHandler}
                            />
                        </div>
                    </div>
                </>
            )}

            {showSaveBtn && (
                <div className={classes.buttonContainer}>
                    <ButtonBase 
                        className={classes.button}
                        onClick={onSubmitHandler}
                    >
                        {t('actions.save')}
                    </ButtonBase>
                </div>
            )}

            {message && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    key='top-center'
                    autoHideDuration={3000}
                    open={true}
                    onClose={() => setMessage('')}
                    message={message}
                />
            )}

        </div>
    )
}

const mapStateToProps = state =>{
    const { invoiceProforma, spaces, dashboard } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        invoiceSettings: invoiceProforma || {}
    }
}

export default connect(mapStateToProps)(TaxReceipts);