import React from 'react';
import { withStyles } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';


const styles = theme => ({
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  }
});

const PieChart = (props) => {

  const { classes, data } = props;
  return (
    data.length === 0 ? 
      <div className={classes.blankSlate}>
      No reservations for this date range.
      </div> :
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 60, bottom: 60, left: 60 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ datum: 'color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableRadialLabels={true}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            translateY: 56,
            itemWidth: 0,
            itemHeight: 0,
            itemTextColor: 'transparent',
            symbolSize: 0,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
          }
        ]}
      />
  )
}

export default withStyles(styles)(PieChart);