import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import SalesPage from './SalesPage';
import UserDetails from './UserDetails';
import NoticeboardImport from './NoticeboardImport';

import { addHwExperiencesUser } from '../../../redux/actions/user/user.js';
import { fetchNoticeboardDetails } from '../../../redux/actions/experiences/hwExperiences';
import { resetSuccess } from '../../../redux/actions/reservation/editReservation';

import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F8F9FB',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  container: {
    alignItems: 'center',
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingRight: 17,
    width: '100%',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

const HWExperiencesPage = (props) => {
  const classes = useStyles();
  const { loading, auth, currentSpace, user, userEmail, userRole, space } = props;
  const [step, setStep] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(600);

  React.useEffect(() => {
    if (window.innerHeight) {
      setContainerHeight(window.innerHeight)
    }
    if (auth && currentSpace && currentSpace.hwExpFlag!=='live') {
      props.dispatch(fetchNoticeboardDetails(currentSpace.propertyID));
      setStep(2);
    }
  }, []);

  React.useEffect(() => {
    if (props.submitSuccess) {
      props.dispatch(resetSuccess('CREATE_EXPERIENCES_USER'))
      props.dispatch(resetSuccess('ADD_USER_TO_SPACE'))
      setStep(2);
    }
  }, [props.submitSuccess])

  const handleStart = () => {
    setStep(1);
  }

  const handleSubmitUser = (newUser) => {
    props.dispatch(addHwExperiencesUser(space, newUser, userRole));
  }

  const handleLoginSuccess = () => {
    if (currentSpace && currentSpace.hwExpFlag==='live'){
      props.handleComplete()
    } else {
      setStep(2);
    }
  }

  const handleNext = () => {
    props.handleComplete();
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {loading && <div className={classes.loading} style={{minHeight: containerHeight}}><CircularProgress /></div>}
        {!loading && step===0 && <SalesPage handleNext={handleStart} />}
        {!loading && step===1 && 
          <UserDetails user={user} userEmail={userEmail} space={space} containerHeight={containerHeight} handleSubmitUser={handleSubmitUser} handleLoginSuccess={handleLoginSuccess} />
        }
        {step===2 && <NoticeboardImport containerHeight={containerHeight} handleNext={handleNext} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.isAuthenticated,
    currentSpace: state.spaces[state.dashboard.currentSpace],
    doneLoading: state.success.GET_HW_USER,
    submitSuccess: state.success.CREATE_EXPERIENCES_USER && state.success.ADD_USER_TO_SPACE,
    loading: state.loading.GET_HW_USER || state.loading.CREATE_EXPERIENCES_USER || state.loading.ADD_USER_TO_SPACE
  }
}

export default connect(mapStateToProps)(HWExperiencesPage);