import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { TwitterPicker, ChromePicker } from "react-color";
import clsx from "clsx";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import {
  updateSettings,
  getSettings,
  resetSettings,
} from "../../redux/actions/onlineCheckin/onlineCheckin";
import { loadEmailTemplates } from "../../redux/actions/emails/emails";
import { getStripeAccountStatus } from "../../redux/actions/payments/payments";
import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";

import Steps from "./Steps";
import AutomaticEmail from "./AutomaticEmail";

import Select from "react-select";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import IMAGES from "../../constants/images";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { BOOKING_ENGINE_URI } from "../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 300,
    width: "100%",
    position: "absolute",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  listSection: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  urlRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 10,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  labelTitle: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 20,
  },
  label: {
    fontSize: "1.2rem",
    color: "#666666",
    fontWeight: 400,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  iconRow: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 25,
  },
  tabInfo: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    textAlign: "center",
    background: "#F8F9FB",
    borderRadius: "15px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    height: "100px",
    justifyContent: "center",
    marginRight: 25,
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  tabSelected: {
    background: "#E0E0E0",
    color: "#333333",
    fontWeight: 600,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  picContainer: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
  },
  placeholder: {
    marginTop: 15,
    background: "#F6F2EE",
    borderRadius: "15px",
    height: 80,
    marginRight: 15,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginBottom: 0,
  },
  noDisplay: {
    display: "none",
  },
  image: {
    borderRadius: 15,
    objectFit: "cover",
    height: 80,
    width: 80,
  },
  topMargin: {
    marginTop: 25,
  },
  bottomMargin: {
    marginBottom: 25,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  colorPickerLabel: {
    cursor: "pointer",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: "1.2rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 10,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  checkinLink: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontSize: "1.3rem",
      fontWeight: 600,
    },
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  createSection: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  newButton: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  errorMessage: {
    color: "#f44336",
    fontSize: 10,
  },
  displayColor: {
    height: "16px",
    width: "16px",
    alignItems: "center",
    border: "1px solid #666666",
    borderRadius: "50%",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 5,
  },
  displayColorText: {
    color: "#666666",
    fontSize: "1.2rem",
    paddingTop: 3,
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "uppercase",
  },
  colorRow: {
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    minWidth: "180px",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const baseURI = BOOKING_ENGINE_URI;

const OnlineCheckIn = (props) => {
  const { t } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    property,
    loadingProperty,
    isLoading,
    emailTemplates,
    onlineCheckin,
    errors,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("general");
  const [color, setColor] = React.useState({
    bannerColor: "#F6F2EE",
    fontColor: "#666666",
  });
  const [logoPreview, setLogoPreview] = React.useState(null);
  const [picPreview, setPicPreview] = React.useState(null);
  const [selectedLogo, setSelectedLogo] = React.useState();
  const [selectedPic, setSelectedPic] = React.useState();
  const [message, setMessage] = React.useState("");

  const [colorPicker, setColorPicker] = React.useState(null);
  const [initial, setInitial] = React.useState(true);
  const [form, setForm] = React.useState({ bookingEngineURL: "" });
  const [remove, setRemove] = React.useState({ logo: false, pic: false });
  const [error, setError] = React.useState({
    bannerColor: false,
    fontColor: false,
  });

  if (!onlineCheckin.default && initial) {
    if (property.bookingEngineURL) {
      setForm({ bookingEngineURL: property.bookingEngineURL });
    }

    if (onlineCheckin.bannerColor && onlineCheckin.fontColor) {
      setColor({
        bannerColor: onlineCheckin.bannerColor,
        fontColor: onlineCheckin.fontColor,
      });
    }

    if (onlineCheckin.propertyLogo) {
      setLogoPreview(onlineCheckin.propertyLogo);
    }

    if (onlineCheckin.propertyPic) {
      setPicPreview(onlineCheckin.propertyPic);
    }

    setInitial(false);
  }

  useEffect(() => {
    if (property.default)
      props.dispatch(fetchProperty(currentSpace.propertyID));

    props.dispatch(loadEmailTemplates(currentSpace.propertyID));
    props.dispatch(getStripeAccountStatus(currentSpace.propertyID));
    props.dispatch(getSettings(currentSpace.propertyID));
  }, []);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "Online Check-in" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  useEffect(() => {
    if (!selectedLogo) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedLogo);
    setLogoPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedLogo]);

  useEffect(() => {
    if (!selectedPic) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedPic);
    setPicPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedPic]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleToggle = (value) => {
    setToggle(value);
    if (!value) {
      if (property.bookingEngineURL)
        props.dispatch(
          editProperty(currentSpace.propertyID, {
            disableAppFeature: "Online Check-in",
          })
        );
    } else {
      if (property.bookingEngineURL)
        props.dispatch(
          editProperty(currentSpace.propertyID, {
            appFeature: "Online Check-in",
          })
        );
    }
  };

  const handleTabSelect = (tab) => {
    props.dispatch(getSettings(currentSpace.propertyID));
    setSelectedTab(tab);
  };

  const handleCapture = (e, type) => {
    if (!e.target.files || e.target.files.length === 0) {
      if (type === "logo") setSelectedLogo(undefined);
      else setSelectedPic(undefined);
      return;
    }
    if (e.target.files[0].size / (1024 * 1024) < 2) {
      if (type === "logo") {
        setRemove({ ...remove, logo: false });
        setSelectedLogo(e.target.files[0]);
      } else {
        setRemove({ ...remove, pic: false });
        setSelectedPic(e.target.files[0]);
      }
      if (!save) setSave(true);
      return;
    } else {
      console.log("Error");
    }
  };

  const handleRemovePicture = (type) => {
    if (type === "logo") {
      setLogoPreview(null);
      setRemove({ ...remove, logo: true });
      setSelectedLogo(null);
    } else {
      setPicPreview(null);
      setRemove({ ...remove, pic: true });
      setSelectedPic(null);
    }
    if (!save) setSave(true);
  };

  const handleSave = () => {
    handleColorPicker(null);
    const val = /[0-9a-fA-F]+/g;
    if (!val.test(color.bannerColor) || val.test(color.fontColor)) {
      setMessage("Please make sure the color codes are correct.");
      setOpenSnackbar(true);
      return;
    }

    const data = {
      ...color,
      logo: remove.logo ? "none" : selectedLogo,
      pic: remove.pic ? "none" : selectedPic,
    };

    props
      .dispatch(updateSettings(currentSpace.propertyID, "general", data))
      .then((response) => {
        if (response && response.status === 200) {
          setMessage("Online Check-in Settings saved!");
          setOpenSnackbar(true);
          setSave(false);
        } else {
          setMessage("Your settings has not been saved. Please try again!");
          setOpenSnackbar(true);
        }
      });
  };

  const handleColorPicker = (value) => {
    setColorPicker(value);
  };

  const handleChangeBannerColor = (colour, event) => {
    let newColor = { ...color, bannerColor: colour.hex };
    setColor(newColor);
    if (!save) setSave(true);
    handleColorPicker(null);
  };

  const handleChangeFontColor = (colour, event) => {
    let newColor = { ...color, fontColor: colour.hex };
    setColor(newColor);
    if (!save) setSave(true);
    handleColorPicker(null);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `${baseURI}/checkin/${property.bookingEngineURL}`
    );
    setOpenSnackbar(true);
    setMessage("Checkin URL copied to clipboard!");
  };

  const handleChange = (e) => {
    setForm({ bookingEngineURL: e.target.value });
  };

  const handleSaveExtension = () => {
    props.dispatch(
      editProperty(currentSpace.propertyID, {
        bookingEngineURL: form.bookingEngineURL,
        appFeature: "ONLINE_CHECKIN",
      })
    );
  };

  const handleCloseModal = () => {
    props.dispatch(resetSettings());
    handleClose();
  };

  const handleCloseError = () => {
    props.dispatch(resetError("EDIT_PROPERTY"));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Online Checkin</div>
          <AccessHeaderAction moduleID={MODULE.ONLINE_CHECKIN.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        {(loadingProperty || isLoading) && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        {!loadingProperty && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.onlineCheckin.definition")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.bottomMargin}
                >
                  <Grid item xs={7}>
                    <div className={classes.title}>Online Checkin</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={() => handleToggle(false)}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={() => handleToggle(true)}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {toggle && (
                  <div className={clsx(classes.iconRow, classes.tabInfo)}>
                    <div
                      id="general"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: selectedTab === "general",
                      })}
                      onClick={() => handleTabSelect("general")}
                    >
                      {t("automate.onlineCheckin.generalSettings")}
                    </div>
                    <div
                      id="steps"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]:
                          selectedTab === "customerDetails",
                      })}
                      onClick={() => handleTabSelect("customerDetails")}
                    >
                      {t("automate.onlineCheckin.stepsSettings")}
                    </div>
                    <div
                      id="email"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: selectedTab === "email",
                      })}
                      onClick={() => handleTabSelect("email")}
                    >
                      {t("automate.onlineCheckin.emailSettings")}
                    </div>
                  </div>
                )}
                {toggle && selectedTab === "general" && (
                  <div>
                    <div className={classes.urlRow}>
                      {!property.bookingEngineURL && (
                        <div className={classes.topMargin}>
                          <p className={classes.label}>
                            {t("automate.onlineCheckin.general.enterURL")}
                          </p>
                          <div className={classes.createSection}>
                            <TextField
                              margin="dense"
                              id="bookingEngineURL"
                              fullWidth
                              label="Enter the extension"
                              type="text"
                              onChange={handleChange}
                              value={form.bookingEngineURL}
                            />
                            <div className={classes.buttonHolder}>
                              <DoneIcon
                                className={classes.newButton}
                                onClick={handleSaveExtension}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {property.bookingEngineURL && (
                        <div>
                          <div className={classes.labelTitle}>
                            {t(
                              "automate.onlineCheckin.general.viewOnlineCheckin"
                            )}
                          </div>
                          <div className={classes.linkContainer}>
                            <div className={classes.checkinLink}>
                              <a
                                href={`${baseURI}/checkin/${property.bookingEngineURL}`}
                                target="_blank"
                              >
                                {`${baseURI}/checkin/${property.bookingEngineURL}`}
                              </a>
                            </div>
                            <div
                              className={classes.copyButton}
                              onClick={() => copyToClipboard()}
                            >
                              Copy
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <p className={classes.labelTitle}>
                          {t("automate.onlineCheckin.general.branding")}
                        </p>
                        <div className={classes.row}>
                          <Grid container alignItems="center">
                            <Grid item xs={7}>
                              <div className={classes.label}>
                                {t(
                                  "automate.onlineCheckin.general.bannerColor"
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={5}>
                              {color.bannerColor !== "" && (
                                <div
                                  className={classes.colorRow}
                                  onClick={() => handleColorPicker("banner")}
                                >
                                  <span
                                    style={{ background: color.bannerColor }}
                                    className={classes.displayColor}
                                  ></span>
                                  <span className={classes.displayColorText}>
                                    - {color.bannerColor}
                                  </span>
                                </div>
                              )}
                            </Grid>
                            {colorPicker === "banner" ? (
                              <Grid container alignItems="center">
                                <Grid item xs={12}></Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={7}>
                                  <TwitterPicker
                                    triangle="hide"
                                    color={color.bannerColor}
                                    onChange={handleChangeBannerColor}
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                            <Grid item xs={7}>
                              <div className={classes.label}>
                                {t("automate.onlineCheckin.general.fontColor")}
                              </div>
                            </Grid>
                            <Grid item xs={5}>
                              {color.fontColor !== "" && (
                                <div
                                  className={classes.colorRow}
                                  onClick={() => handleColorPicker("font")}
                                >
                                  <span
                                    style={{ background: color.fontColor }}
                                    className={classes.displayColor}
                                  ></span>
                                  <span className={classes.displayColorText}>
                                    - {color.fontColor}
                                  </span>
                                </div>
                              )}
                            </Grid>
                            {colorPicker === "font" ? (
                              <Grid container alignItems="center">
                                <Grid item xs={12}></Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={7}>
                                  <TwitterPicker
                                    triangle="hide"
                                    color={color.fontColor}
                                    onChange={handleChangeFontColor}
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                            <Grid item xs={12} className={classes.topMargin}>
                              <div className={classes.label}>
                                {t(
                                  "automate.onlineCheckin.general.propertyLogo"
                                )}
                              </div>
                              <div className={classes.picContainer}>
                                <div className={classes.placeholder}>
                                  {logoPreview && (
                                    <img
                                      className={classes.image}
                                      src={logoPreview}
                                    />
                                  )}
                                </div>
                                {!logoPreview && (
                                  <label htmlFor="upload-logo">
                                    <div
                                      className={clsx(
                                        classes.link,
                                        classes.label
                                      )}
                                    >
                                      {t(
                                        "automate.onlineCheckin.general.uploadLogo"
                                      )}
                                    </div>
                                  </label>
                                )}
                                {logoPreview && (
                                  <div
                                    className={clsx(
                                      classes.link,
                                      classes.label
                                    )}
                                    onClick={() => handleRemovePicture("logo")}
                                  >
                                    {t(
                                      "automate.onlineCheckin.general.removeLogo"
                                    )}
                                  </div>
                                )}
                                <input
                                  accept="image/*"
                                  className={classes.noDisplay}
                                  id="upload-logo"
                                  type="file"
                                  onChange={(e) => handleCapture(e, "logo")}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} className={classes.topMargin}>
                              <div className={classes.label}>
                                {t(
                                  "automate.onlineCheckin.general.propertyPic"
                                )}
                              </div>
                              <div className={classes.picContainer}>
                                <div className={classes.placeholder}>
                                  {picPreview && (
                                    <img
                                      className={classes.image}
                                      src={picPreview}
                                    />
                                  )}
                                </div>
                                {!picPreview && (
                                  <label htmlFor="upload-pic">
                                    <div
                                      className={clsx(
                                        classes.link,
                                        classes.label
                                      )}
                                    >
                                      {t(
                                        "automate.onlineCheckin.general.uploadPic"
                                      )}
                                    </div>
                                  </label>
                                )}
                                {picPreview && (
                                  <div
                                    className={clsx(
                                      classes.link,
                                      classes.label
                                    )}
                                    onClick={() => handleRemovePicture("pic")}
                                  >
                                    {t(
                                      "automate.onlineCheckin.general.removePic"
                                    )}
                                  </div>
                                )}
                                <input
                                  accept="image/*"
                                  className={classes.noDisplay}
                                  id="upload-pic"
                                  type="file"
                                  onChange={(e) => handleCapture(e, "pic")}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    {save && (
                      <div className={classes.buttonsContainer}>
                        <div className={classes.button} onClick={handleSave}>
                          Save
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {toggle && selectedTab === "customerDetails" && (
                  <div>
                    <Steps />
                  </div>
                )}
                {toggle && selectedTab === "email" && (
                  <div>
                    <AutomaticEmail />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            autoHideDuration={3000}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => handleCloseError()}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  emailTemplates: state.emailTemplates,
  onlineCheckin: state.onlineCheckin,
  errors: state.errors.EDIT_PROPERTY,
});

export default withTranslation()(connect(mapStateToProps)(OnlineCheckIn));
