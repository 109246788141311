import http from '../../utils/http';

export function fetchLiveExperiences(propertyID) {
	//const propID = '5df7695cc3a0427e4a4f4f4a'
    const ACTION_NAME = "FETCH_LIVE_EXPERIENCE";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .get(`api/frontdesk/experiences/live?propertyID=${propertyID}`)
        .then(successResult => {
          dispatch({ type: `${ACTION_NAME}_SUCCESS` });
          return successResult.data;
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
          return;
        });
    };
}