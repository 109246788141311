import React, { useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

import UserListItem from './UserListItem';
import { updateUserModulePermission } from '../../../redux/actions/permissions/permissions';
import { resetError } from '../../../redux/actions/goki/goki';

const useStyles = makeStyles(theme=>({
    root:{
        border: '1px solid',
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'relative',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    breakTag:{
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    button: {
        background: '#fff',
        borderRadius: 25,
        color: '#000',
        cursor: 'pointer',
        fontSize: '1.2rem',
        fontWeight: 600,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        }
    },
    listContainer:{
        overflowY: 'scroll'
    },
    listSection: {
        margin: theme.spacing(3),
        minHeight: 'calc(100vh - 250px)',
        [theme.breakpoints.up("sm")]:{
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(7),
        }
    },
}));

const UserFeaturePermission = ({ 
    moduleID,
    admins,
    members,
    errors,
    dispatch,
    currentSpace, 
    closeDialogHandler
}) =>{
    const classes = useStyles();
    const moduleName = moduleID.replace(/_/g, ' ').toLowerCase();
    const updatePermissionHandler = (userID, status, action) =>{
        const data = {
            spaceID: currentSpace._id,
            userID
        };
        const accessStatus = status ? 'grant' : 'deny';
        const response = dispatch(updateUserModulePermission(accessStatus, data, { module: moduleID }));
        response.then(success =>{
            if(action) action();
        });
    }

    const closeModalHandler = () =>{
        handleCloseError();
        closeDialogHandler();
    }

    const handleCloseError = () => {
        dispatch(resetError('UPDATE_USER_MODULE_PERMISSIONS'));
    }

    return(
        <Dialog
            open={true}
            onClose={closeModalHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paperFullWidth: classes.root }}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
        >
            
            <div className={classes.dialogTitle}>
                <div className={classes.header}>People using<br className={classes.breakTag}/> {moduleName}</div>
                <CloseIcon className={classes.closeIcon} onClick={closeModalHandler} />
            </div>
            <div className={classes.listContainer}>
                <div className={classes.listSection}>

                    {admins.map((userID,index)=>(
                        <UserListItem
                            moduleID={moduleID} 
                            key={index} 
                            isAdmin={true}
                            userID={userID}
                        />
                    ))}

                    {members.map((userID,index)=>(
                        <UserListItem 
                            moduleID={moduleID} 
                            key={index} 
                            isAdmin={false}
                            userID={userID}
                            updatePermissionHandler={updatePermissionHandler}
                        />
                    ))}
                </div>
            </div>
            {/* ------------- Snackbar error messages -------------- */}
            {errors && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={handleCloseError}
                    autoHideDuration={6000}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{errors}</span>}
                />
            )}
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { spaces, dashboard, errors } = state;
    const currentSpace = spaces[dashboard.currentSpace];
    const members = currentSpace.members;
    const admins = currentSpace.admins;
    return{
        members,
        currentSpace,
        admins,
        errors: errors.UPDATE_USER_MODULE_PERMISSIONS
    }
}

export default connect(mapStateToProps)(UserFeaturePermission);