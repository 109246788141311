import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";

import { fetchCurrencyFormat } from "../../../utils/utility";
import Pill from "../../common/Pill";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SubDirectoryIcon from "@material-ui/icons/SubdirectoryArrowRight";
import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 5,
    marginBottom: 30,
    padding: theme.spacing(3),
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  blankState: {
    fontSize: "1.2rem",
    marginTop: 5,
  },
  pillContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
  },
  derivedSection: {
    background: "#F8F9FB",
    borderRadius: 5,
    padding: theme.spacing(2),
    width: "calc(100% - 32px)",
    marginBottom: 20,
  },
  form: {
    marginRight: 20,
  },
  field: {
    alignItems: "center",
    background: "#ffffff",
    borderRadius: "5px",
    boxShadow: "0 1px 10px #E0E0E0",
    display: "flex",
    height: 35,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50,
  },
  fieldContainer: {
    alignItems: "center",
    display: "flex",
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 25,
    display: "flex",
    width: "200px",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  toggleSelect: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  icon: {
    borderRadius: "50%",
    height: 20,
    padding: theme.spacing(1),
    width: 20,
    cursor: "pointer",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "1.2rem",
    marginBottom: 20,
  },
  cancel: {
    color: "#484848",
    cursor: "pointer",
    fontSize: "1.3rem",
    marginRight: 30,
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  error: {
    fontSize: "1.2rem",
    color: "red",
    marginTop: 5,
  },
}));

const RoomTypeForm = (props) => {
  const classes = useStyles();
  const {
    nextStepHandler,
    prevStepHandler,
    activeStepInfo,
    roomTypes,
    roomTypeOptions,
    handleSplice,
    handleAddBack,
    ratePlan,
    currentSpace,
    handleNewLink,
    handleCancelLink,
  } = props;
  const [baseRoomType, setBaseRoomType] = useState("");
  const [toggles, setToggles] = useState([true]);
  const [differentials, setDifferentials] = useState([
    { type: "amount", value: "", roomTypeID: "" },
  ]);
  const [errors, setErrors] = useState([{ value: false, roomTypeID: false }]);
  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );

  const handleSelect = (selectedRoomType) => {
    setBaseRoomType(selectedRoomType);
    handleSplice(selectedRoomType);
  };

  const handleRestart = (selectedRoomType) => {
    setBaseRoomType("");
    let roomTypeArray = [];
    differentials.map((e) => {
      if (e.roomTypeID !== "") roomTypeArray.push(e.roomTypeID);
    });
    roomTypeArray.push(selectedRoomType);
    handleAddBack(roomTypeArray);
    setDifferentials([{ type: "amount", value: "", roomTypeID: "" }]);
    setErrors([{ value: false, roomTypeID: false }]);
    setToggles([true]);
  };

  const handleMap = (updatedItem, roomTypeID) => {
    const array = [...differentials];
    const errorArray = [...errors];
    let item = array[updatedItem];
    let errorItem = errorArray[updatedItem];
    item.roomTypeID = roomTypeID;
    errorItem.roomTypeID = false;
    setDifferentials([...array]);
    handleSplice(roomTypeID);
    setErrors([...errorArray]);
  };

  const handleUnmap = (updatedItem, roomTypeID) => {
    const array = [...differentials];
    let item = array[updatedItem];
    item.roomTypeID = "";
    setDifferentials([...array]);
    handleAddBack([roomTypeID]);
  };

  const handleUpdate = (updatedItem, key, value) => {
    let array = [...differentials];
    let errorArray = [...errors];
    let item = array[updatedItem];
    let errorItem = errorArray[updatedItem];
    if (value != "" && key == "value") {
      errorItem[key] = false;
      item[key] = value * (toggles[updatedItem] ? 1 : -1);
    } else {
      item[key] = value;
    }
    setDifferentials([...array]);
    setErrors([...errorArray]);
  };

  const handleToggle = (updatedItem, value) => {
    let array = [...differentials];
    let toggleArray = [...toggles];
    let item = array[updatedItem];
    if (item.value !== "") item.value = Math.abs(item.value) * (value ? 1 : -1);
    toggleArray[updatedItem] = value;
    setDifferentials([...array]);
    setToggles([...toggleArray]);
  };

  const handleDelete = (index) => {
    let array = [...differentials];
    let errorArray = [...errors];
    let togglesArray = [...toggles];
    const roomTypeID = array[index].roomTypeID;
    if (roomTypeID !== "") handleAddBack([roomTypeID]);
    array.splice(index, 1);
    errorArray.splice(index, 1);
    togglesArray.splice(index, 1);
    setDifferentials([...array]);
    setErrors([...errorArray]);
    setToggles([...togglesArray]);
  };

  const handleAddDifferential = () => {
    setDifferentials([
      ...differentials,
      { type: "amount", value: "", roomTypeID: "" },
    ]);
    setErrors([...errors, { value: false, roomTypeID: false }]);
    setToggles([...toggles, true]);
  };

  const handleCancel = () => {
    let addBackArray = [];
    if (baseRoomType !== "") {
      addBackArray.push(baseRoomType);
      differentials.map((e) =>
        e.roomTypeID !== "" ? addBackArray.push(e.roomTypeID) : null
      );
    }
    handleCancelLink();
    handleAddBack(addBackArray);
    setDifferentials([{ type: "amount", value: "", roomTypeID: "" }]);
    setErrors([{ value: false, roomTypeID: false }]);
    setBaseRoomType("");
  };

  const handleValidate = () => {
    let errorArray = [...errors];
    let noError = true;
    differentials.map((item, index) => {
      if (item.value == "") {
        errorArray[index].value = true;
        noError = false;
      }
      if (item.roomTypeID == "") {
        errorArray[index].roomTypeID = true;
        noError = false;
      }
    });
    setErrors([...errorArray]);
    return noError;
  };

  const handleSave = () => {
    const check = handleValidate();
    if (check) {
      handleNewLink({
        baseRoomTypeID: baseRoomType,
        differentials: differentials,
      });
      setDifferentials([{ type: "amount", value: "", roomTypeID: "" }]);
      setBaseRoomType("");
    }
  };

  return (
    <>
      <div className={classes.box}>
        {/* ERROR -- NO ROOM TYPES */}
        {/* ------------------------------------ */}
        {ratePlan.roomTypeIDs.length == 0 && (
          <div className={classes.blankState}>
            No room types available to link. Please attach some room types to
            this rate plan in Step 2.
          </div>
        )}

        {/* TITLE */}
        {/* ------------------------------------ */}
        {ratePlan.roomTypeIDs.length > 0 && (
          <div className="row">
            <div className={classes.title}>New Room Type Link</div>
          </div>
        )}

        {/* STEP 1 -- SELECT BASE ROOM TYPE */}
        {/* ------------------------------------ */}
        {ratePlan.roomTypeIDs.length > 0 && (
          <div className="row">
            <div className="label">Base Room Type</div>
            {baseRoomType == "" && (
              <div className={classes.pillContainer}>
                {roomTypeOptions.length > 0 &&
                  roomTypeOptions.map(
                    (roomType) =>
                      roomTypes[roomType] && (
                        <Pill
                          key={roomType}
                          name={roomTypes[roomType].name}
                          handleClick={() => handleSelect(roomType)}
                        />
                      )
                  )}
              </div>
            )}
            {baseRoomType !== "" && roomTypes[baseRoomType] && (
              <div className={classes.pillContainer}>
                <Pill
                  name={roomTypes[baseRoomType].name}
                  handleClick={() => handleRestart(baseRoomType)}
                  selected={true}
                  cancelButton={true}
                  green={true}
                />
              </div>
            )}
          </div>
        )}

        {/* STEP 2 -- ADD DERIVED ROOM TYPE */}
        {/* ------------------------------------ */}
        {baseRoomType !== "" &&
          differentials.map((item, index) => (
            <div key={index} className={classes.derivedSection}>
              <Grid container justifyContent="center">
                {/* Directory Icon */}
                {/* ------------------------------------ */}
                <Grid item xs={1}>
                  <SubDirectoryIcon />
                </Grid>

                <Grid item xs={10}>
                  <Grid container spacing={3} alignItems="center">
                    {/* Increase / Descrease Toggle */}
                    {/* ------------------------------------ */}
                    <Grid item xs={12}>
                      <div className={classes.toggle}>
                        <div
                          className={clsx({
                            [classes.toggleButton]: true,
                            [classes.left]: true,
                            [classes.toggleSelect]: toggles[index] === false,
                          })}
                          onClick={() => handleToggle(index, false)}
                        >
                          Decrease
                        </div>
                        <div
                          className={clsx({
                            [classes.toggleButton]: true,
                            [classes.right]: true,
                            [classes.toggleSelect]: toggles[index],
                          })}
                          onClick={() => handleToggle(index, true)}
                        >
                          Increase
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.valueContainer}>
                        {/* Amount / Percent Toggle */}
                        {/* ------------------------------------ */}
                        <div className={classes.form}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="percentOrAmount"
                              name="percentOrAmount"
                              value={item.type}
                              onChange={(e) =>
                                handleUpdate(index, "type", e.target.value)
                              }
                              row
                            >
                              <FormControlLabel
                                value="amount"
                                control={<Radio color="secondary" />}
                                label={"Amount"}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="percent"
                                control={<Radio color="secondary" />}
                                label={"Percent"}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        {/* Value */}
                        {/* ------------------------------------ */}
                        <div>
                          <div className={classes.fieldContainer}>
                            {item.type == "amount" ? currency : null}
                            <div className={classes.field}>
                              <InputBase
                                id="value"
                                name="value"
                                value={item.value ? Math.abs(item.value) : ""}
                                fullWidth
                                type="text"
                                onChange={(e) =>
                                  handleUpdate(
                                    index,
                                    "value",
                                    e.target.value !== ""
                                      ? parseFloat(e.target.value)
                                      : ""
                                  )
                                }
                              />
                            </div>
                            {item.type == "percent" ? "%" : null}
                          </div>
                          {errors[index].value && (
                            <div className={classes.error}>
                              Please enter a value
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>

                    {/* Room Type List */}
                    {/* ------------------------------------ */}
                    <Grid item xs={12}>
                      <div className={classes.pillContainer}>
                        {item.roomTypeID == "" &&
                          roomTypeOptions.length == 0 && (
                            <div className="label">
                              All existing room types have been mapped
                            </div>
                          )}
                        {item.roomTypeID == "" &&
                          roomTypeOptions.length > 0 &&
                          roomTypeOptions.map(
                            (roomType) =>
                              roomTypes[roomType] && (
                                <Pill
                                  key={roomType}
                                  name={roomTypes[roomType].name}
                                  handleClick={() => handleMap(index, roomType)}
                                />
                              )
                          )}
                        {item.roomTypeID !== "" &&
                          roomTypes[item.roomTypeID] && (
                            <Pill
                              name={roomTypes[item.roomTypeID].name}
                              selected={true}
                              cancelButton={true}
                              handleClick={() =>
                                handleUnmap(index, item.roomTypeID)
                              }
                            />
                          )}
                      </div>
                      {errors[index].roomTypeID && (
                        <div className={classes.error}>
                          Please select a room type
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Delete */}
                {/* ------------------------------------ */}
                <Grid item xs={1} onClick={() => handleDelete(index)}>
                  <img className={classes.icon} src={IMAGES.ICONS.bin} />
                </Grid>
              </Grid>
            </div>
          ))}

        {/* STEP 3 -- ADD ANOTHER DERIVED ROOM TYPE */}
        {/* ---------------------------------------------*/}
        {baseRoomType !== "" && roomTypeOptions.length > 0 && (
          <div className={classes.link} onClick={handleAddDifferential}>
            Add another rule
          </div>
        )}

        {/* STEP 4 -- SAVE */}
        {/* ------------------------------------ */}
        {baseRoomType !== "" && (
          <div className={classes.buttonContainer}>
            <div className={classes.cancel} onClick={handleCancel}>
              Cancel
            </div>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
});

export default connect(mapStateToProps)(RoomTypeForm);
