import http from "../../utils/http";
import { normalize } from 'normalizr';
// import { fileSchema } from '../../schema';
import { fileSchema, folderSchema } from '../../schema/fileSchema';

//----------------------------------------------------------------
// LOAD FILE LIST
//----------------------------------------------------------------
export function loadFiles(spaceID, folderID) {
  const ACTION_NAME = "LOAD_FILES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/file/upload/${spaceID}/${folderID}`)
      .then(successResult => {
        console.log('load file success = now what', successResult.data)
        // var normalizedOrder = normalize(successResult.data, [fileSchema] );
        // console.log(normalizedOrder.entities)
        // dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD ALL FILES
//----------------------------------------------------------------
export function loadAllFiles(spaceID) {
  const ACTION_NAME = "LOAD_ALL_FILES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/file/${spaceID}`)
      .then(successResult => {
        // console.log(successResult.data)
        var normalizedOrder = normalize(successResult.data, [folderSchema] );
        // console.log(normalizedOrder.entities)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT FILE
//----------------------------------------------------------------
export function editFile(fileID, updates) {
  const ACTION_NAME = "EDIT_FILE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch(`/api/file/upload/${fileID}`, {...updates}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      var normalizedOrder = normalize(data, fileSchema);
      // console.log('normalized data', normalizedOrder.entities);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {files: normalizedOrder.entities.files, fileID} })
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// DELETE FILE
//----------------------------------------------------------------
export function deleteFile(fileID, folderID) {
  const ACTION_NAME = "DELETE_FILE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/file/upload/${fileID}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {folderID, fileID} })
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// RESET FILE LIST
//----------------------------------------------------------------
export const resetFiles = () => (dispatch) => {
    dispatch({ type: "RESET_FILES"})
};


//----------------------------------------------------------------
// RESET FILE LIST
//----------------------------------------------------------------
export const addNewFile = (file) => (dispatch) => {
  dispatch({
    type: "ADD_NEW_FILE",
    payload: file
  })
}