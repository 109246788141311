import escapeHtml from 'escape-html'
import { Node, Text } from 'slate'

const serializeSlate = (node, level = true) => {
  if (node.bold && Text.isText(node)) {
    return `<strong>${escapeHtml(node.text)}</strong>`
  }

  if (node.italic && Text.isText(node)) {
    return `<i>${escapeHtml(node.text)}</i>`
  }

  if (node.underline && Text.isText(node)) {
    return `<u>${escapeHtml(node.text)}</u>`
  }

  if (!node.bold && Text.isText(node)) {
    return escapeHtml(node.text)
  }

  const children = node.children.map(n => serializeSlate(n, false)).join(level ? '<br/>': '')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    default:
      return children
  }
}

export default serializeSlate;