import http from "../../utils/http";

//----------------------------------------------------------------
// INVITE ALL
//----------------------------------------------------------------
export function getInviteAllLink(spaceID) {
  const ACTION_NAME = "GET_INVITE_LINK";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/inviteAll/`, { spaceID })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {spaceID, data} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET INVITE LINK
//----------------------------------------------------------------
export const resetInviteAllLink = () => {
  return {
    type: "RESET_INVITE_LINK"
  };
};

//----------------------------------------------------------------
// INVITE SPACE USER
//----------------------------------------------------------------
export function inviteSpaceUser(spaceID, userDetails) {
  const ACTION_NAME = "INVITE_USER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/inviteOne/`, { spaceID, ...userDetails })
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {spaceID, data} });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// DELETE PENDING USER
//----------------------------------------------------------------
export function deletePendingUser(spaceID, email) {
  const ACTION_NAME = "DELETE_PENDING_USER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/users/invite`, { data: { spaceID, email } })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
