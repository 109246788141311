import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getMenuStepInfo, getProgress } from './productHelper';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        padding: '10px 15px',
        boxSizing: 'border-box'
    },
    infoContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.2rem',
    },
    progressContainer:{
        width: '100%'
    },
    statusBar: {
        // width: '65%',
        backgroundColor: '#e3e3e3',
        '&.MuiLinearProgress-root':{
            height: '3px',
            borderRadius: '20px',
        },
        '& .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#BB5533',
            borderRadius: '20px'
        }
    },
    next: {
        background: '#996666',
        borderColor: '#996666',
        color: '#FFFFFF',
        '&:hover': {
            background: '#996666',
            color: '#FFFFFF',
        }
    },
    save: {
        fontSize: '1.3rem',
    }
}));

const Footer = props =>{
    const { 
        nextStepHandler, 
        prevStepHandler,  
        activeStepInfo,
        submitHandler,
        product
    } = props;
    const classes = useStyles();
    const [stepData, setStepData] = useState({});

    useEffect(() => {
        const stepData = getMenuStepInfo(activeStepInfo.menuID);
        setStepData({  ...stepData });
    }, [activeStepInfo.menuID])

    return(
        <>
            <div className={classes.progressContainer}>
                <LinearProgress 
                    className={classes.statusBar} 
                    variant="determinate" 
                    value={Math.ceil(getProgress(product) * 100/stepData.totalSteps) || 5} 
                />
            </div>

            <div className={classes.root}>
                
                {!(getProgress(product)==stepData.totalSteps) && (
                    <Button 
                        variant="outlined"
                        className={classes.save}
                        onClick={prevStepHandler}
                    >
                        Previous
                    </Button>
                )}
        
                <div className={classes.infoContainer}>
                    {getProgress(product)} of {stepData.totalSteps} complete
                </div>
                
                <Button 
                    variant="outlined"
                    className={clsx(classes.save, classes.next)}
                    onClick={getProgress(product)!==stepData.totalSteps ? nextStepHandler : submitHandler }
                >
                    {getProgress(product)!==stepData.totalSteps ? 'Next' : product._id ? 'Save' : 'Submit'}
                </Button>
            </div>
        </>
    )
}

export default Footer;