import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { fetchDateFormat, fetchNoOfNights } from '../../../../utils/utility';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    buttonContainer: {
        margin: '20px 0'
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
}));

const ChangeDeparture = (props) => {
    const classes = useStyles();
    const { data, range } = props;
    const nights = fetchNoOfNights(range.endDate, data.checkOut);

    const submitDataHandler = () => {
        let accommodation = data?.items?.find(item => item.date === range.endDate);
        let accommodationID = accommodation ? accommodation.ID : null;
        props.onSubmitHandler({
            accommodationID: accommodationID,
            changeDeparture: {
                date: range.endDate
            }
        })
    }

    return (
        <React.Fragment>
            {/* -------------------- Shorten accommodation form -------------------- */}
            <div className={classes.label}>You are shortening the stay for this guest by {nights || 0} nights, new checkout is {fetchDateFormat(range.endDate, 'MMM DD YYYY')}</div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={submitDataHandler}>Save</Button>
            </div>
        </React.Fragment>
    )
}

export default ChangeDeparture;