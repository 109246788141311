const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_ALL_FILES_SUCCESS":
      return {
        ...state,
        ...action.payload.files
      }
    case "LOAD_FILES_SUCCESS":
      return {
        ...state,
        ...action.payload.files
      }
    case "ADD_NEW_FILE":
      return {
        ...state,
        [action.payload._id]: action.payload
      };
    case "EDIT_FILE_SUCCESS":
      const editFile = state[action.payload.fileID]
      return {
        ...state,
        [action.payload.fileID]: {
          ...editFile, 
          name: action.payload.files[action.payload.fileID].name, 
          url: action.payload.files[action.payload.fileID].url
        }
      }
    case "RESET_FILES":
      return {
        ...initialState
      };
    case "DELETE_FILE_SUCCESS":
      const newState = Object.keys(state).reduce((object, key) => {
        if (key !== action.payload.fileID) {
          object[key] = state[key]
        }
        return object
      }, {})
      return {
        ...newState
      }
    case "ADD_FILE_COMMENT_SUCCESS":
      const currentFile = state[action.payload.fileID]
      return {
        ...state,
        [action.payload.fileID]: {
          ...currentFile,
          comments: [...currentFile.comments, action.payload.data._id]
        }
      }
    default:
      return state;
  }
};

