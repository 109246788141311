import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import Sidebar from './sidebar';
import ContentRenderer from './ContentRenderer';

import { sidebarMenu } from './constant';
import { getNextStepInfo, getPrevStepInfo } from './productHelper';
import { editProduct, createProduct, deleteProduct, uploadProductPOSImage, saveProductImage, POSImageUploadStatus } from '../../../redux/actions/pos/products';
import { resetError } from '../../../redux/actions/common/common';
import { editProperty } from '../../../redux/actions/dashboard/property';

const styles = theme =>({
    dialog:{
        position: 'relative'
    },
    container: {
        display: 'flex',
        height: '100%',
        position: 'relative'
    },
    sidebarContainer:{
        backgroundColor: '#F8F9FB'
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 16,
        zIndex: 99,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
    },
});

class NewProduct extends Component {

    constructor(props){
        super(props);
        const defaultMainStepID = Object.keys(sidebarMenu)[0];
        this.state = {
            activeMenuID: defaultMainStepID,
            product: {
                productCategoryID: '',
                name: '',
                code: '',
                visibility: []
            }
        }
    }

    componentDidMount(){
        if (this.props.productID) {
            this.setState({...this.state, product: this.props.products[this.props.productID]})
        }
    }

    handleUpdateProduct = (key, value)  => {
        this.setState({...this.state, product: {...this.state.product, [key]: value}})
    }

    navigateToMenuHandler = (menuID) =>{
        this.setState({ activeMenuID: menuID });
    }

    nextStepHandler = () =>{
        const { activeMenuID } = this.state;
        const nextStepInfo = getNextStepInfo(activeMenuID);

        if(nextStepInfo) this.navigateToMenuHandler(nextStepInfo.menuID);
    }

    prevStepHandler = () =>{
        const { activeMenuID } = this.state;
        const prevStepInfo = getPrevStepInfo(activeMenuID);

        if(prevStepInfo) this.navigateToMenuHandler(prevStepInfo.menuID);
    }

    submitHandler = () => {
        console.log('submit', this.state.product, this.props.currentSpace.propertyID);
        let file = this.state?.product?.imageFile;
        if (this.state.product._id) {
            const response = this.props.dispatch(editProduct(this.props.productID, {...this.state.product, propertyID: this.props.currentSpace.propertyID}))
            response.then(response =>{
                if(response){
                    if (response.key && response.url && file) {
                        this.imageUploadHandler(response)
                    } else this.props.handleCancel();
                }
            });
        } else {
            const response = this.props.dispatch(createProduct({...this.state.product, propertyID: this.props.currentSpace.propertyID}))
            response.then(response =>{
                console.log("response:: add", response)
                if(response){
                    this.checkFirstProduct()
                    if (response.key && response.url && file) {
                        this.imageUploadHandler(response)
                    } else this.props.handleCancel();
                }
            });
        }
    }

    getFileData = data =>{
        const imageFile = data.imageFile;
        const fileParts = imageFile.name.split(".");
        const fileName = fileParts[0];
        const fileType = fileParts[1];
        return { imageFile, fileName, fileType };
    }
    
    imageUploadHandler = (responseData) => {
        const { key, url, product } = responseData;
        const { imageFile, fileName, fileType } = this.getFileData(this.state.product);

        const uploadData = {
            key,
            file: imageFile,
            type: fileType
        };
        this.props.dispatch(POSImageUploadStatus('request'));
        //Get upload to storage url........
        const uploadResponse = uploadProductPOSImage(url, uploadData);
        //Upload to storage.....
        uploadResponse.then(res => {
            //Save uploaded details....
            const saveResponse = this.props.dispatch(saveProductImage(product._id, this.props.currentSpace.propertyID, key));
            saveResponse.then(success => {
                if (success) {
                    this.props.dispatch(POSImageUploadStatus('success'));
                    this.props.handleCancel();
                }
            })

        }).catch(error => {
            console.log('upload error ', error);
            this.props.dispatch(POSImageUploadStatus('error', error));
            this.props.handleCancel();
        });
    }

    checkFirstProduct = () => {
        let products = { ...this.props.products };
        let productsOfProperty = Object.keys(products).filter(id => products[id].propertyID === this.props.currentSpace.propertyID) || [];
        if (productsOfProperty && productsOfProperty.length === 1) {
            this.props.dispatch(editProperty(this.props.currentSpace.propertyID, { appFeature: "POS" }))
        }
    }



    handleDeleteProduct = () => {
        const response = this.props.dispatch(deleteProduct(this.state.product._id))
        response.then(response =>{
            if(response){
                this.props.handleCancel();
            }
        });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('CREATE_PRODUCT'));
        dispatch(resetError('EDIT_PRODUCT'));
        dispatch(resetError('DELETE_PRODUCT'));
    }

    render(){
        const { 
            classes, 
            closeModalHandler,
            currentSpace,
            isLoading,
            errors 
        } = this.props;

        const { activeMenuID } = this.state;

        const contentProps = {
            activeStepInfo: {
                menuID: activeMenuID
            },
            currency: currentSpace.currency,
            nextStepHandler: this.nextStepHandler,
            prevStepHandler: this.prevStepHandler,
            product: this.state.product,
            handleUpdateProduct: this.handleUpdateProduct,
            submitHandler: this.submitHandler
        }

        return(
            <Dialog 
                open={this.props.open} 
                onClose={this.props.handleCancel}
                className={classes.dialog} 
                fullScreen={true}
            >   
                <CloseIcon 
                    className={classes.closeIcon} 
                    onClick={this.props.handleCancel} 
                />

                <Grid container className={classes.container}>

                    {/*----------------PROGRESS LOADER--------------------- */}
                    {isLoading && (
                        <div className={classes.loadingState}>
                            <CircularProgress className={classes.loader} />
                        </div>
                    )}
                    
                    <Grid item className={classes.sidebarContainer} sm={3} lg={2} xs={12}>
                        <Sidebar
                            activeMenuID = {activeMenuID}
                            navigateToMenuHandler = {this.navigateToMenuHandler}
                            closeModalHandler = {closeModalHandler}
                            product={this.state.product}
                            handleDeleteProduct={this.handleDeleteProduct}
                        />
                    </Grid>

                    <Grid item sm={9} lg={10} xs={12}>
                        <ContentRenderer
                            activeMenuID = {activeMenuID}
                            contentProps = {contentProps}
                        />
                    </Grid>
                </Grid>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state =>{
    const { spaces, products, dashboard, loading, errors, success } = state;
    
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
        products: products,

        isLoading: loading.CREATE_PRODUCT || 
            loading.EDIT_PRODUCT ||
            loading.PRODUCT_IMAGE_UPLOAD ||
            loading.DELETE_PRODUCT,

        errors: errors.CREATE_PRODUCT || 
            errors.EDIT_PRODUCT ||
            errors.SAVE_PRODUCT_IMAGE ||
            errors.DELETE_PRODUCT,

        success: success.CREATE_PRODUCT
    }
}

export default withStyles(styles)(connect(mapStateToProps)(NewProduct));