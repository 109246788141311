import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Select from 'react-select';

import { newMessage } from '../../redux/actions/messages/messages';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles (theme => ({
  newMessage: {
    alignItems: 'center',
    borderBottom: '1px solid #dddddd',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  assigned: {
    paddingRight: theme.spacing(2),
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width:'65%'
    }
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width:'35%'
    }
  },
}));

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    borderRadius: 10,
    boxShadow: '0 2px 30px #F2F2F2',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const NewMessage = (props) => {

  const classes = useStyles();
  const [assigned, setAssigned] = React.useState([])

  const handleAssigned = (user) => {
    if (user===null) {
      setAssigned([]);
    } else {
      setAssigned(user.map(e => e.value));
    }
  }

  const handleSubmit = () => {
    if (assigned.length === 0 ) {
      console.log('no assignees')
    } else {
      const members = [...assigned, props.user._id];
      props.dispatch(newMessage(members));
      props.handleMsgClose();
    }
  }

  return (
    <div className={classes.newMessage}>
      <div className={classes.assigned}>
        <Select
          options={props.suggestions}
          onChange={handleAssigned}
          isClearable={false}
          placeholder={"Start a private chat with.."}
          styles={selectStyle}
          isMulti
          theme={theme => ({
            ...theme,
            border: 0,
            colors: {
              ...theme.colors,
              primary: '#666666',
              primary25: '#dddddd'
            },
          })}
        />
      </div>
      <div className={classes.buttonHolder}>
        <Button variant='outlined' size='small' color='primary' onClick={handleSubmit}>New Message</Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(NewMessage);