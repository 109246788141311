import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { setScheduleCallStatus, resetError } from '../redux/actions/hwMigration/hwMigration';

const styles = theme => ({
    root: {
        height: "calc(100vh - 5px)"
    },
    calendly: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '60px',
            boxSizing: 'border-box'
        }
    },
    emojiHeader: {
        fontSize: '5rem',
        marginBottom: 10,
    },
    infoSection: {
        background: "#f8f9fb",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    infoContainer: {
        maxWidth: "340px",
        margin: "20px",
        marginTop: "40%",

        '& .title': {
            fontSize: "2.4rem",
            marginBottom: "30px",
        },
        '& .infoText': {
            fontSize: "1.5rem",
            lineHeight: "20px",
            color: "#666666",
            fontWeight: 500,
            marginBottom: "30px",
        },
    },
    redirectBtn: {
        fontWeight: 600,
        color: "#42476f",
        fontSize: "1.3rem",
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        '& .icon': {
            fontSize: "1.5rem",
            marginLeft: "5px",
        }
    },
    emoji: {
        fontSize: '1.3rem',
        marginLeft: '5px'
    },
    mobileBtn: {
        left: 0,
        color: "#42476f",
        right: 0,
        bottom: 0,
        display: "none",
        padding: "20px",
        position: "fixed",
        fontSize: "1.5rem",
        borderTop: "1px solid #e0e0e0",
        textAlign: "center",
        fontWeight: 600,
        backgroundColor: "#f8f9fb",
        justifyContent: "center",
        alignItems: "center",

        '& .icon': {
            fontSize: "2rem",
            marginLeft: "10px",
        },

        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        },
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '45%',
    },
});

class ScheduleCallContainer extends Component {

    // calendlyScriptSrc = 'https://assets.calendly.com/assets/external/widget.js';
    hubSpotMeetingScriptSrc = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'

    // calendlyListener= e =>{
    //     if (this.isCalendlyEvent(e)) {

    //         const { event = null } = e.data;
    //         if(event == 'calendly.event_scheduled'){
    //             this.callStatusHandler(true);
    //             return;
    //         }
    //     }
    // }

    // isCalendlyEvent = (e) => {
    //     return e.data.event &&
    //            e.data.event.indexOf('calendly') === 0;
    // };

    isHubspotUrl = (url) => {
        const hubspotUrls = [
            'https://local.hubspot.com',
            'https://app.hubspotqa.com',
            'https://app.hubspot.com',
            'https://meetings.hubspot.com'
        ];
        return hubspotUrls.indexOf(url) > -1;
    }

    receiveMessage = (event) =>{

        if (this.isHubspotUrl(event.origin) && event.data.meetingCreated) {
            console.log('hubspot meetings: meetingCreated event');
        }

        if (this.isHubspotUrl(event.origin) && event.data.meetingBookSucceeded) {
            console.log('hubspot meetings: meetingBookSucceeded event');
            this.callStatusHandler(true);
        }
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', this.hubSpotMeetingScriptSrc);
        script.setAttribute('async', true);
        head.appendChild(script);

        window.addEventListener("message", this.receiveMessage);
    }

    callStatusHandler = (status) => {
        const { dispatch, currentSpace } = this.props;
        const data = { migrationCallStatus: status };
        dispatch(setScheduleCallStatus(currentSpace._id, data))
            .then(success => {
                if (success) this.navigateToWorkspace();
            });
    }

    navigateToWorkspace = () => {
        this.props.history.push('/beds/channels/hostelworld/');
    }

    handleCloseError = () => {
        const { dispatch } = this.props;
        dispatch(resetError('SET_SCHEDULE_CALL_STATUS'));
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    }

    render() {
        const { classes, isLoading, errors, t } = this.props;
        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={12} md={4} className={classes.infoSection}>
                    <div className={classes.infoContainer}>
                        <div className={classes.emojiHeader}>&#10024;</div>
                        <h2 className="title">{t('scheduleCall.title')}</h2>
                        <p className="infoText">{t('scheduleCall.description')}</p>

                        <h4>{t('scheduleCall.timeSlotTitle')}</h4>

                        <p className="infoText">{t('scheduleCall.timeSlotDescription')}<span className={classes.emoji}>&#128522;</span></p>

                        <div className={classes.redirectBtn} onClick={this.navigateToWorkspace}>
                            {t('scheduleCall.buttonText')}
                            <ArrowForwardIcon className="icon" />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>

                    <div
                        className={clsx('meetings-iframe-container', classes.calendly, 'hideScrollBar')}
                        data-src="https://meetings.hubspot.com/counterapp/onboarding?embed=true"
                    />

                    {/* <div
                        className={clsx("calendly-inline-widget", classes.calendly, 'hideScrollBar')}
                        data-url="https://calendly.com/counter-onboarding/counter-onboarding?hide_gdpr_banner=1&back=1&month=2021-06"
                    /> */}

                    <div className={classes.mobileBtn} onClick={this.navigateToWorkspace}>
                        {t('scheduleCall.buttonText')}
                        <ArrowForwardIcon className="icon" />
                    </div>
                </Grid>

                {/* ------------- Loader -------------- */}
                {isLoading && (
                    <div className={classes.loadingState}>
                        <CircularProgress className={classes.loader} />
                    </div>
                )}

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}

            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const { spaces, dashboard, loading, errors } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        isLoading: loading.SET_SCHEDULE_CALL_STATUS,
        errors: errors.SET_SCHEDULE_CALL_STATUS
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(withRouter(ScheduleCallContainer))));