import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fetchDateFormat } from '../../../../utils/utility';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    buttonContainer: {
        margin: '20px 0'
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
}));


const ChangeArrival = (props) => {
    const classes = useStyles();
    const { data, range } = props;

    const submitDataHandler = () => {
        let accommodation = data?.items?.[0];
        let accommodationID = accommodation ? accommodation.ID : null;
        props.onSubmitHandler({
            accommodationID: accommodationID,
            changeArrivalDate: {
                date: range.startDate
            }
        })
    }

    return (
        <React.Fragment>
            {/* -------------------- Arrival form -------------------- */}
            <div className={classes.label}>You are changing the arrival of the guest to {fetchDateFormat(range.startDate, 'MMM DD YYYY')}</div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={submitDataHandler}>Save</Button>
            </div>
        </React.Fragment>
    )
}

export default ChangeArrival;