import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import TrixEditor from '../../../utils/TrixEditor.js';

import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
    textareaContainer: {
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px',
        width: '50%',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },
    textarea: {
        background: '#fff',
        border: 'none',
        padding: '20px',
        borderRadius: "10px",
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: "10px",
        }
    },
    listText: {
        paddingLeft: '15px',
        marginBottom: '20px',
        '& li': {
            marginBottom: '10px'
        }
    },
    limitText: {
        marginTop: 10,
        width: '50%',
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },
    endContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    error: {
        fontSize: '1.2rem',
        color: "#f44336"
    },
    textbody: {
        background: '#ffffff',
        borderRadius: '4px',
        boxShadow: "0 1px 30px 0 #E0E0E0",
        color: '#666666',
        minHeight: 400,
        padding: theme.spacing(2),
        width: '50%',
    }
}));

const ActivityDescription = props => {
    const classes = useStyles();
    const {
        nextStepHandler,
        prevStepHandler,
        activeStepInfo,
        productData,
        submitDraftHandler
    } = props;

    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [isForceUpdate, setIsForceUpdate] = useState(false);

    useEffect(() => {
        if (productData.description) { 
            setDescription(productData.description); 
            setIsForceUpdate(new Date()) 
        }
    }, [productData.description]);

    const validateDescription = description => {
        const value = description.trim().split(/\s+/);
        if (value.length < 100) {
            return false
        }
        const success = Boolean(value);
        const errorStatus = !success;
        setDescriptionError(errorStatus);
        return success;
    }

    const onChangeHandler = value => {
        validateDescription(value);
        setDescription(value);
    }

    const submitHandler = () => {
        if (!validateDescription(description)) return;
        const data = { description: description.trim() };
        submitDraftHandler(data);
    }
    return (
        <>
            <div className="main-content">

                <div className="main-content-title">Provide a short description</div>

                <div className="sub-title">
                    <p>Your activity description is a chance to inspire guests to take your experience. Think about it like a story, with a beginning, middle, and end.</p>

                    <p>Describe your experience</p>

                    <ol className={classes.listText}>
                        <li>First, briefly describe what you'll do with your guests. What unique details set it apart from other similar experiences?</li>
                        <li>Then, get more specific. How will you kick things off? How will you make guests feel included and engaged during your time together?</li>
                        <li>Finally, say what you want guests to leave with. Friends? Knowledge? A greater appreciation for your culture? End with a strong selling point.</li>
                        <li>Description should be at least 100 words.</li>
                    </ol>
                </div>

                <div className={classes.textbody}>
                    {/* <TextField

                        className={classes.textarea}
                        variant="outlined"
                        multiline
                        rows="10"
                        id="description"
                        onChange={onChangeHandler}
                        value={description}
                        error={descriptionError}
                        fullWidth
                    /> */}
                    <TrixEditor
                        id="description"
                        error={descriptionError}
                        initialValue={description}
                        submitOnEnter={false}
                        enableOnChange={true}
                        handleSlateOnChange={onChangeHandler}
                        isForceUpdate={isForceUpdate}

                    />
                </div>
                {descriptionError && (
                    <FormHelperText className="errorMessage">
                        Description is required
                    </FormHelperText>
                )}

                {(!descriptionError) && (description.trim().split(/\s+/).length < 100) && <div className={classes.limitText}>
                    <div className={classes.endContainer}>
                        <span className={classes.error}>Words required to complete description: {100 - description.trim().split(/\s+/).length}</span>
                    </div>
                </div>}
            </div>

            <Footer
                nextClickHandler={submitHandler}
                backClickHandler={prevStepHandler}
                activeStepInfo={activeStepInfo}
                productData={productData}
            />
        </>
    )
}

export default ActivityDescription; 