import { sidebarMenu } from './constant';

export const getNextStepInfo = (currentMenuID) =>{

    const menuList = Object.keys(sidebarMenu);
    const currentMenuIndex = menuList.indexOf(currentMenuID);
    const nextMainStepIndex = currentMenuIndex + 1;

    if(!menuList[nextMainStepIndex]) return;

    const nextMenu = sidebarMenu[menuList[nextMainStepIndex]];

    return {
        menuID: nextMenu.id
    }
}

export const getPrevStepInfo = (currentMenuID) =>{
        const menuList = Object.keys(sidebarMenu);
        const currentMenuIndex = menuList.indexOf(currentMenuID);
        const prevMenuIndex = currentMenuIndex - 1;

        if(!menuList[prevMenuIndex]) return;
        
        const prevMenu = sidebarMenu[menuList[prevMenuIndex]];
        
    return {
        menuID: prevMenu.id
    }
}

export const getMenuStepInfo = (menuID, isEditMode) =>{

    const sidebarMenuList = getSidebarMenuList(isEditMode);

    const menuList = Object.keys(sidebarMenuList);

    const stepNo = menuList.indexOf(menuID) + 1;

    const totalSteps = menuList.length;

    const progressVal = Math.ceil(stepNo * 100 / totalSteps);

    return { stepNo, totalSteps, progressVal }
}

export const getHeaderTitle = (menuID) =>{
    console.log('menuID ',menuID);
    const activeMenuIfo = sidebarMenu[menuID];
    return activeMenuIfo.headerText;
}

export const getSubMenuStatus = (mainMenuID, product) =>{
    let success = false;

    if(mainMenuID == sidebarMenu.BASIC.id) {
        success = product.name && product.name.length > 0
        return success
    }
    
    if(mainMenuID == sidebarMenu.DETAILS.id) {
        return true
    }

    if(mainMenuID == sidebarMenu.ROOMTYPE.id) {
        success = product.primaryRoomTypeID
        return success
    }

    if(mainMenuID == sidebarMenu.INVENTORY.id) {

        if (product.advancedMapping=='oneToOne') {
            if (product.mapping && product.mapping.filter(e => e.overlap==true).length == 0) success = true
        } else success = true
        return success
    }

    if(mainMenuID == sidebarMenu.VISIBILITY.id) {
        return true
    }
    
    return

}

export const getProgress = (product) => {
    let count = 0;

    if (product.name && product.name.length > 0)
        count += 1

    if (product.primaryRoomTypeID)
        count += 1

    // Details
    count += 1

    // Inventory
    if (product.advancedMapping=='oneToOne') {
        if (product.mapping && product.mapping.filter(e => e.overlap==true).length == 0) count += 1
    } else count += 1

    // Visibility
    if(product._id)
        count += 1;

    return count
}

export const getSidebarMenuList = (isEditMode) =>{
    let sidebarMenulist = { ...sidebarMenu }; 

    //Exclude roomtype visibility tab for create rooms flow.......... 
    if(!isEditMode){
        const menuID = sidebarMenu.VISIBILITY.id;
        delete sidebarMenulist[menuID];
    }

    return { ...sidebarMenulist };
}