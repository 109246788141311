import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { saveCalendarColorSetting, resetError } from '../../../redux/actions/beds/calendar';
import { CalendarColors } from './constant';

const styles = theme =>({
    root:{
        // border: '1px solid',
        // minHeight: 'calc(100% - 64px)',
        // maxHeight: 'calc(100% - 64px)',
        // [theme.breakpoints.down('sm')]: {
        //     minHeight: '100%',
        //     maxHeight: '100%'
        // }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'relative',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '20px 28px',
        // boxShadow: '0 1px 20px #F2F2F2'
    },
    button: {
        background: '#000000',
        borderRadius: 25,
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '1.4rem',
        fontWeight: 600,
        padding: '8px 60px',
        '&:hover':{
            background: '#000000',
            color: '#ffffff',
        }
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        }
    },
    container:{
        position: 'relative'
    },  
    listContainer:{
        overflowY: 'auto',
        height: 'calc(100vh - 240px)',
    },
    loadingState: {
        width: '100%',
        height: 'calc(100vh - 170px)',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '45%',
    },
    listSection: {
        margin: theme.spacing(3),
        [theme.breakpoints.up("sm")]:{
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(7),
        }
    },

    formControl:{
        width: '100%'
    },
    optionLabel:{
        '& .MuiFormControlLabel-label':{
            fontSize: '1.4rem',
            fontWeight: 600
        }
    },
    optionContainer:{
        marginBottom: '20px',
    },
    optionInfoContainer:{
        display: 'flex',
        marginLeft: '25px',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '10px',
        boxSizing: 'border-box',
    },
    optionInfo:{
        display: 'flex',
        alignItems: 'center',
        width: '33.33%',
        marginBottom: '20px',
        '& .optionColor':{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: 'blue',
            marginRight: '5px',
        },
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    }
});

class CalendarColorConfig extends Component {
    
    constructor(props){
        super(props);
        const { uiSettings } = props;
        this.state = {
            selectedOption : uiSettings && uiSettings.calendarColour || CalendarColors.default.id
        }
    }

    handleChange = event =>{
        this.setState({
            selectedOption: event.target.value
        });
    }

    submitHandler = () =>{
        const { dispatch, currentSpace, closeModalHander } = this.props;
        const { selectedOption } = this.state;
        const data = {
            spaceID: currentSpace._id,
            calendarColour: selectedOption
        }
        const response = dispatch(saveCalendarColorSetting(data));
        response.then(success =>{
            if(success) closeModalHander();
        })
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('SAVE_CALENDAR_COLOR_SETTING'));
    }

    render(){
        const { 
            classes, 
            colorConfigInfo, 
            closeModalHander, 
            isLoading, 
            errors,
            t
        } = this.props;

        const { selectedOption } = this.state;
        return(
            <Dialog
                open={true}
                onClose={closeModalHander}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paperFullWidth: classes.root }}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={window.innerWidth < 901}
            >

                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{t('bookingCalendar.customiseCalendar')}</div>
                    <CloseIcon className={classes.closeIcon} onClick={closeModalHander} />
                </div>
                <div className={classes.container}>
                    <div className={classes.listContainer}>
                        
                        {/* ..............Circular Loader...................... */}
                        {isLoading && (
                            <div className={classes.loadingState}>
                                <CircularProgress className={classes.loader} />
                            </div>
                        )}

                        <div className={classes.listSection}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup 
                                    aria-label="calendar_color" 
                                    name="calendarColor" 
                                    value={selectedOption} 
                                    onChange={this.handleChange}
                                >
                                    
                                    { Object.keys(colorConfigInfo).map(optionID => {

                                        const colorItem = colorConfigInfo[optionID];

                                        if(!colorItem.colorInfo) return;

                                        return(
                                            <div className={classes.optionContainer} key={colorItem.id}>
                                            
                                                <FormControlLabel
                                                    className={classes.optionLabel} 
                                                    value={colorItem.id} 
                                                    control={<Radio color="primary" />} 
                                                    label={colorItem.title} 
                                                />

                                                <div className={classes.optionInfoContainer}>  

                                                    {Object.keys(colorItem.colorInfo).map(infoID =>{
                                                        
                                                        const colorInfo = colorItem.colorInfo[infoID];
                                                        
                                                        return(                        
                                                            <div className={classes.optionInfo} key={colorInfo.id}>
                                                                <div 
                                                                    className="optionColor" 
                                                                    style={{ backgroundColor: colorInfo.color }} 
                                                                />
                                                                {colorInfo.title}
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                        )
                                    })}
                        
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} onClick={this.submitHandler}>{t('actions.save')}</Button>
                    </div>
                </div>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </Dialog>
        )
    }
}

const mapStateToProps = state =>{
    const { spaces, dashboard, loading, errors, userUIsettings } = state;
    const currentSpace = spaces[dashboard.currentSpace];
   
    return {
        currentSpace,
        isLoading: loading.SAVE_CALENDAR_COLOR_SETTING || loading.FETCH_BOOKING_SOURCE,
        errors: errors.SAVE_CALENDAR_COLOR_SETTING,
        uiSettings: userUIsettings[currentSpace._id]
    };
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(CalendarColorConfig)));