import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import CustomDatePicker from '../reservations/edit-reservation/guest-accommodation/DurationDatePicker';

import { FILTERS, INIT_FILTERS, SORT_OPTIONS } from './constants';
import { getBuildingOptions, getRatePlanOptions, isBeforeTodaysDate } from './helper';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        flexWrap: "wrap",
        top: "0px",
        position: "sticky",
        background: "#fff",
        borderBottom: "1px solid #e0e0e0",
        paddingBottom: "20px",
        zIndex: 99,
        [theme.breakpoints.down('xs')]: {
            position: 'unset'
        }
    },
    selectContainer: {
        minWidth: '170px',
        marginRight: '10px',
        paddingTop: '10px',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 'unset'
        }
    },
    btnContainer: {
        minWidth: '120px',
    },
    searchBtn: {
        fontWeight: 600,
        border: "1px solid #30638e",
        width: "100%",
        color: "#30638e",
        fontSize: "1.2rem",
        borderRadius: "30px",
        padding: "10px 0px",
        '&:hover': {
            color: '#fff',
            backgroundColor: '#30638e'
        }
    },
    datepickerContaienr: {
        '& .durationDatePicker': {
            width: 'unset',
        },
        
        '& .durationDatePicker .DateInput_input': {
            fontSize: "1.2rem",
            fontWeight: 600,
            color: "#000",
            padding: '0px'
        }
    },
    dFlexVCenter: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: '1px solid #e0e0e0',
      boxShadow: 0,
      cursor: 'pointer',
      margin: "0px",
      height: '45px',
      backgroundColor: '#f8f9fb',
      fontSize: '1.2rem',
      fontWeight: 600,
      '&:hover': { border: '1px solid #e0e0e0' },
    }),
    indicatorSeparator: (base, state) => ({
      display: 'none'
    }),
    menuList: (base, state) => ({
      fontSize: '1.3rem',
    }),
};

const SearchFilters = props =>{
    const classes = useStyles();
    const theme = useTheme();
    const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

    const { filters, onChangeFilter, buildings, currentSpace, onSearchHandler, ratePlans, isReviewLoading } = props;
    const [floorOptions, setFloorOptions] = useState([]);
    const [dates, setDates] = useState({ [FILTERS.startDate]: null, [FILTERS.endDate]: null });
    const [showSearchBtn, setShowSearchBtn] = useState(false);

    const buildingOptions = useMemo(() =>getBuildingOptions(buildings), [buildings]);
    const { defaultRatePlanID, ratePlanOptions } = useMemo(() =>getRatePlanOptions(ratePlans), [ratePlans]);

    useEffect(() =>{
        //RESET BUILDING AND FLOOR FILTERS WHEN REVEIW BOOKING BUTTON IS CLICKED.........
        if(isReviewLoading && filters.buildingID){
            onSelectBuilding(null, { name: FILTERS.buildingID });
        }
    }, [filters.buildingID, isReviewLoading]);

    useEffect(() =>{
        //RESET DATES FILTER..................
        if(!filters.startDate && !filters.endDate){
            setDates({ [FILTERS.startDate]: null, [FILTERS.endDate]: null });
        }
    }, [filters.startDate, filters.endDate]);

    const onChangeHandler = (data, action) => {
        if (!data) {
          clearFilterHandler(action);
          return;
        }
        const updatedFilters = { ...filters, [action.name]: data.value };
        onChangeFilter(updatedFilters);
    };

    const onSelectBuilding = (data, action) => {

        if (!data) {
          clearFilterHandler(action);
          setFloorOptions([]);
          return;
        }
    
        //GET FLOORS OPTIONS OF SELECTED BUILDING.....................
        const selectedBuilding = buildingOptions.find(building => building.value == data.value);
        const options = selectedBuilding.floors.filter(floor => floor).map(floor => ({ label: floor, value: floor }));
    
        const updatedFilters = { 
          ...filters, 
          [action.name]: data.value, 
          [FILTERS.floor]: INIT_FILTERS.floor 
        };
    
        onChangeFilter(updatedFilters);
        setFloorOptions([...options]);
    }

    const clearFilterHandler = (action) => {
        const updatedFilters = {
          ...filters,
          [action.name]: INIT_FILTERS[action.name]
        };
    
        //FLOOR IS A FILTER DEPENDENT ON BUILDING..............
        if(action.name == FILTERS.buildingID){
          updatedFilters.floor = INIT_FILTERS.floor;
        }
    
        onChangeFilter(updatedFilters);
    }

    const onDateChangeHandler = (startDate, endDate) => {
        setDates({ startDate, endDate });
        if(startDate && endDate) setShowSearchBtn(true);
    };

    const onClickSearch = () =>{
        const filtrs = { ...filters, ...dates };

        if(!filters.ratePlanID && defaultRatePlanID){
            filtrs.ratePlanID = defaultRatePlanID
        }

        onSearchHandler(filtrs);
        setShowSearchBtn(false);
    }

    return (
        <div className={classes.root}>
                {/* ----------------------SELECT DATE--------------------- */}
                <div className={clsx(classes.selectContainer, classes.dFlexVCenter)}>
                <div className={classes.datepickerContaienr}>
                    <CustomDatePicker 
                        startLabel={'Check in date'} 
                        endLabel={'Check out date'}
                        startDate={dates.startDate}
                        endDate={dates.endDate}
                        onDatesChangeHandler={onDateChangeHandler}
                        numberOfMonths={deviceSm ? 1 : 2}
                        isOutsideRange={date => isBeforeTodaysDate(date, currentSpace.timezone)}
                        isClearable
                    />
                </div>
            </div>

            {/* ----------------------SELECT RATEPLANS--------------------- */}
            <div className={classes.selectContainer}>
                <Select
                    styles={selectStyle}
                    placeholder="Rate plan"
                    name={FILTERS.ratePlanID}
                    options={ratePlanOptions}
                    value={ratePlanOptions.find(item => item.value == filters.ratePlanID)}
                    onChange={onChangeHandler}
                />
            </div>

            {/* ----------------------SELECT BUILDING--------------------- */}
            <div className={classes.selectContainer}>
                <Select
                    styles={selectStyle}
                    placeholder="Building"
                    name={FILTERS.buildingID}
                    options={buildingOptions}
                    value={buildingOptions.find(item => item.value == filters.buildingID) || null}
                    onChange={onSelectBuilding}
                    isClearable
                />
            </div>
            
            {/* ----------------------SELECT FLOORS--------------------- */}
            {floorOptions.length > 0 && (
                <div className={classes.selectContainer}>
                    <Select
                        styles={selectStyle}
                        placeholder="Floor"
                        name={FILTERS.floor}
                        options={floorOptions}
                        onChange={onChangeHandler}
                        isClearable
                    />
                </div>
            )}

            {/* -----------------SORT FILTER------------------- */}
            <div className={classes.selectContainer}>
                <Select
                    styles={selectStyle}
                    placeholder="Sort rooms"
                    name={FILTERS.sort}
                    options={SORT_OPTIONS}
                    onChange={onChangeHandler}
                    isClearable
                />
            </div>

            {/* ----------------------SEARCH BUTTON--------------------- */}
            {showSearchBtn && (
                <div className={clsx(classes.selectContainer, classes.btnContainer)}>
                    <Button 
                        className={classes.searchBtn}
                        onClick={onClickSearch}
                    >
                        Search
                    </Button>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = state => {
    const { propertySettings, spaces,  dashboard, ratePlans, loading } = state;
    return {
      buildings: propertySettings.buildings || {},
      currentSpace: spaces[dashboard.currentSpace],
      ratePlans: ratePlans || {},
      isReviewLoading: loading.REVIEW_BOOKING
    }
}
  

export default connect(mapStateToProps)(SearchFilters);