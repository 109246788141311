import moment from 'moment-timezone';
import { fetchDateFormat, DATE_FORMATS, } from '../../../utils/utility';

// Process pickup report record for bar graph
// ------------------------------------------------------------------------
const pickupProcessing = (totalResvByDate, submittedDates, level) => {
    const graphData = [];

    const difference = level === 'daily' ? 'day' : 'month';

    let date = submittedDates.startDate.clone().subtract(1, difference);
    do {
        date = date.add(1, difference);
        graphData.push({

        date: level === 'daily' ? date.format('DD MMM') : date.format('MMM YYYY'), 
        
        resvCount:  totalResvByDate.find(e => moment(e.date).isSame(date, difference)) ? 
                    totalResvByDate.find(e => moment(e.date).isSame(date, difference)).count : 
                    0
        })
    } while (!date.isSame(submittedDates.endDate, difference));

    return [ ...graphData ];
}

// Process reservation list for table
// ------------------------------------------------------------------------
const resvListProcessing = (resvList, timezone, currencyData) => {
    const tableData = [];

    resvList.map(res => {
        const item = {
            ...res, 
            totalAmount: res.totalAmount,
            balance: res.balance,
            checkIn: moment(res.checkIn).format('DD MMM, YYYY'),
            checkOut: moment(res.checkOut).format('DD MMM, YYYY'),
            createdAt: fetchDateFormat(res.createdAt, `${DATE_FORMATS.DAY} ${DATE_FORMATS.MONTH}, ${DATE_FORMATS.YEAR}`, timezone),
            createdAtTime: fetchDateFormat(res.createdAt, `${DATE_FORMATS.TIME}`, timezone),
            createdAtMomentObj: moment(res.createdAt).tz(timezone),
            nonRefundable: res.nonRefundable ? 'YES' : res.nonRefundable === null ? 'MIXED' : 'NO'
        }
        tableData.push(item);
    })

    return [ ...tableData ];
}

export { pickupProcessing, resvListProcessing };