import React from 'react';
import { Tooltip } from '@material-ui/core';

const TooltipIcon = (props) => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [tooltipText, setTooltipText] = React.useState('');

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            title={tooltipText}
        >
            <img
                className={props.className}
                src={props.icon}
                onClick={() => {
                    setTooltipText(props.clickText)
                    setTooltipOpen(true)
                    props.handleClick();
                }}
                onMouseEnter={() => {
                    setTooltipText(props.hoverText);
                    setTooltipOpen(true);
                }}
                onMouseLeave={() => setTooltipOpen(false)}
            />
        </Tooltip>
    )

}

export default TooltipIcon;