import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import { returnCurrent } from "../../redux/selectors/dashboard";
import { fetchPickupInfo } from "../../redux/actions/reports/pickup";
import {
  pickupProcessing,
  resvListProcessing,
} from "../../redux/selectors/reports/pickup";
import BarChart from "../charts/BarChart";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import Reservation from "../reservations/Reservation";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { BOOKING_SOURCE } from "../../utils/constants";

import { exportExcel } from "../utils/excelExport";
import { WORKSPACE_URL } from "../../config";

const styles = (theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "&:hover": {
      background: "#eeeeee",
    },
  },
  icon: {
    fontSize: "5rem",
  },
  downloadButton: {
    cursor: "pointer",
    height: 25,
    width: 25,
    left: 24,
    opacity: 0.7,
    position: "absolute",
    top: 28,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  revenueGraph: {
    height: 400,
    width: "100%",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    width: "100%",
  },
  tooltip: {
    "& span": {
      fontWeight: 600,
    },
  },
  tableHeader: {
    display: "flex",
    marginBottom: 20,
    width: "100%",
  },
  label: {
    color: "#666666",
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  pillContainer: {
    alignItems: "center",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    borderRadius: 5,
    overflow: "hidden",
    width: 280,
  },
  pill: {
    alignItems: "center",
    background: "#F8F9FB",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    flexDirection: "column",
    height: "30px",
    justifyContent: "center",
    width: "140px",
  },
  pillSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
});

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const LEVELS = {
  daily: "daily",
  monthly: "monthly",
};

const BasicExport = (props) => {
  const { t } = props;
  const rowCount = props.data.length;
  const tableTitle = `${t("reports.pickup")} ${t("reports.report")}`;
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = ["string", "wholeNumber"];
  const excelColWidth = [15, 15];

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("reports.table.date"),
          field: "date",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.reservations"),
          field: "resvCount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth
          );
        },
      }}
    />
  );
};

const ReservationExport = (props) => {
  const { t, openInNewTab, currencyData } = props;
  const rowCount = props.data.length;
  const tableTitle = `${t("reports.pickup")} ${t("reports.report")}`;
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "wholeNumber",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "currency",
    "currency",
    "string",
  ];
  const excelColWidth = [20, 15, 15, 40, 15, 15, 20, 20, 20, 15];

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        { title: t("reports.table.resvCode"), field: "code", align: "center" },
        {
          title: t("reports.table.createdAt"),
          defaultSort: "asc",
          field: "createdAt",
          align: "center",
          customSort: (a, b) => a.createdAtMomentObj - b.createdAtMomentObj,
        },
        {
          title: t("reports.table.createdAtTime"),
          field: "createdAtTime",
          align: "center",
        },
        {
          title: t("reports.table.guestName"),
          field: "guestName",
          align: "center",
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          align: "center",
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          align: "center",
        },
        {
          title: t("reports.table.bookingSource"),
          field: "bookingSource",
          align: "center",
        },
        {
          title: `${t("reports.table.reservationAmount")} (${
            currencyData.code
          })`,
          field: "totalAmount",
          align: "center",
        },
        {
          title: `${t("reports.table.outBalance")} (${currencyData.code})`,
          field: "balance",
          align: "center",
        },
        {
          title: t("reports.table.nonRefundable"),
          field: "nonRefundable",
          align: "center",
        },
      ]}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      data={props.data}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth
          );
        },
        actionsColumnIndex: -1,
        thirdSortClick: false,
      }}
      onRowClick={(event, rowData) =>
        props.handleTableRowClick(rowData.reservationID)
      }
      actions={[
        {
          icon: "open_in_new",
          tooltip: "Open in a new tab",
          onClick: (event, rowData) => openInNewTab(rowData.code),
        },
      ]}
    />
  );
};

const constantBookingSources = [
  "Hostelworld",
  "Booking.com",
  "Airbnb",
  "Expedia",
];

class Pickup extends Component {
  state = {
    focusedInput: null,
    totalResvByDate: [],
    bookingSources: [],
    resvList: [],
    selectedBookingSources: [],
    submittedLevel: LEVELS.daily,
    presetValue: "",
    resCountDisplay: false,
    openReservation: false,
    goFullScreen: false,
  };

  constructor(props) {
    super(props);
    this.presetRanges = this.getPresetRanges();
    const initDateFilter = this.presetRanges[2];
    const { reportFrom, reportTo } = this.fetchDateRange(initDateFilter.value);
    const initDates = { startDate: reportFrom, endDate: reportTo };

    const currency = props.currentSpace.currency || null;
    this.state.currency = currency;
    this.state.dates = { ...initDates };
    this.state.submittedDates = { ...initDates };
    this.state.presetValue = { ...initDateFilter };
    this.state.currencyData = {
      code: props.currentSpace.currency,
      country: props.currentSpace.country,
    };
  }

  componentDidMount() {
    const { dates, selectedBookingSources } = this.state;
    const { t } = this.props;
    this.getPickupInfo(dates, selectedBookingSources);
    document.title = `${t("reports.pickup")} ${t("reports.report")} | Counter`;

    this.initBookingSources();
  }

  componentWillUnmount() {
    document.title = "Counter Workspace";
  }

  componentDidUpdate(prevProps) {
    if (this.props.live && !_.isEqual(this.props.live, prevProps.live)) {
      this.initBookingSources();
    }
  }

  initBookingSources = () => {
    const { live, currentSpace } = this.props;
    const sourceData =
      (live[currentSpace._id] && live[currentSpace._id].resvSource) || [];

    const bookingSourceData = sourceData.map((data) =>
      data._id.status ? data._id.status : "None"
    );

    const constantOptions = Object.keys(BOOKING_SOURCE).map(
      (source) => BOOKING_SOURCE[source]
    );

    let bookingSouceOptions = [
      ...constantBookingSources,
      ...constantOptions,
      ...bookingSourceData,
    ];
    bookingSouceOptions = [...new Set(bookingSouceOptions)];

    const formattedSourceData = bookingSouceOptions.map((source) => ({
      value: source,
      label: source,
    }));
    this.setState({ bookingSources: [...formattedSourceData] });
  };

  getPickupInfo = (dates, selectedBookingSources) => {
    const { dispatch, currentSpace } = this.props;
    let success = false;

    const difference = dates.endDate.diff(dates.startDate, "days");
    const level = difference > 30 ? LEVELS.monthly : LEVELS.daily;

    let data = {
      startDate: dates.startDate.clone().format("YYYY-MM-DD"),
      endDate: dates.endDate.clone().format("YYYY-MM-DD"),
      level,
      propertyID: currentSpace.propertyID,
    };

    if (selectedBookingSources.length > 0) {
      data.bookingSources = [...selectedBookingSources];
    }

    const response = dispatch(fetchPickupInfo(data));
    response.then((data) => {
      if (data && data.totalResvByDate) {
        const dateRange = {
          startDate: dates.startDate,
          endDate: dates.endDate,
        };
        this.setState({
          totalResvByDate: [...data.totalResvByDate],
          submittedDates: { ...dateRange },
          submittedLevel: level,
          resvList: [...data.resvList],
        });
        success = true;
      }
    });
    return success;
  };

  getPresetRanges = () => {
    const { t } = this.props;
    return [
      { value: "today", label: t("reports.presetRange.today") },
      { value: "yesterday", label: t("reports.presetRange.yesterday") },
      // { value: 'tomorrow', label: t('reports.presetRange.tomorrow') },
      { value: "past15", label: t("reports.presetRange.past15") },
      { value: "past30", label: t("reports.presetRange.past30") },
      { value: "custom", label: "Custom" },
    ];
  };

  fetchDateRange = (filterType) => {
    let reportFrom;
    let reportTo;

    switch (filterType) {
      case "today":
        reportFrom = moment();
        reportTo = moment();
        break;

      // case 'tomorrow':
      //     reportFrom = moment().add(1, 'day');
      //     reportTo = moment().add(1, 'day');
      //     break;

      case "yesterday":
        reportFrom = moment().subtract(1, "day");
        reportTo = moment().subtract(1, "day");
        break;

      case "past15":
      case "past30":
        const num = parseInt(filterType.substr(4));
        reportFrom = moment().subtract(num, "days");
        reportTo = moment();
        break;
    }

    return { reportFrom, reportTo };
  };

  setNewDates = (data) => {
    const filterType = data.value;
    const { reportFrom, reportTo } = this.fetchDateRange(filterType);
    this.setState({
      dates: {
        startDate: reportFrom,
        endDate: reportTo,
      },
      presetValue: data,
    });
  };

  datePickerHandler = (startDate, endDate) => {
    const customPresetIndex = this.presetRanges.length - 1;

    this.setState({
      dates: { startDate, endDate },
      presetValue: this.presetRanges[customPresetIndex],
    });
  };

  setBookingSource = (data) => {
    let selectedSources = [];

    if (data && data.length > 0) {
      selectedSources = data.map((sources) => sources.value);
    }

    this.setState({ selectedBookingSources: [...selectedSources] });
  };

  handleSubmit = () => {
    const { isLoading } = this.props;
    const { dates, selectedBookingSources } = this.state;

    if (isLoading) return;

    this.getPickupInfo(dates, selectedBookingSources);
  };

  getToolTipComponent = (point) => {
    const { classes } = this.props;
    return (
      <div className={classes.tooltip}>
        <div>
          no. of reservations: <span>{point.data.resvCount}</span>
        </div>
      </div>
    );
  };

  handleOpenReservation = (resID) => {
    this.props.dispatch(getReservationDetails(resID));
    this.setState({ openReservation: true });
  };

  handleCloseReservation = () => {
    this.setState({ openReservation: false });
    this.props.dispatch(resetReservationDetails());
  };

  openInNewTab = (resvCode) => {
    window.open(
      `${WORKSPACE_URL}/${this.props.currentProperty.code}/reservation/${resvCode}`,
      "_blank"
    );
  };

  render() {
    const {
      dates,
      focusedInput,
      totalResvByDate,
      bookingSources,
      submittedDates,
      submittedLevel,
      currencyData,
      resvList,
      presetValue,
      resCountDisplay,
      openReservation,
      goFullScreen,
    } = this.state;

    const { open, isLoading, classes, t, handleClose } = this.props;

    const pickupData = pickupProcessing(
      totalResvByDate,
      submittedDates,
      submittedLevel
    );
    const resvListData = resvListProcessing(
      resvList,
      this.props.currentSpace.timezone,
      currencyData
    );

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.dialogPaper }}
        style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
        maxWidth="md"
        fullWidth={true}
        fullScreen={window.innerWidth < 901 || goFullScreen}
      >
        {/* --------------------------------------------------------- */}
        {/* Header */}
        {/* --------------------------------------------------------- */}
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            <Typography className={classes.header}>
              {t("reports.pickup")} {t("reports.report")}
            </Typography>
            <Hidden smDown>
              {!goFullScreen && (
                <FullScreen
                  className={classes.addButton}
                  onClick={() => {
                    this.setState({ goFullScreen: true });
                  }}
                />
              )}
              {goFullScreen && (
                <FullScreenExit
                  className={classes.addButton}
                  onClick={() => this.setState({ goFullScreen: false })}
                />
              )}
            </Hidden>
            <AccessHeaderAction moduleID={MODULE.PICKUP.ID} />
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </div>

          <div
            className={classes.listContainer}
            style={{
              height:
                goFullScreen || window.innerWidth < 901
                  ? "calc(100vh - 124px)"
                  : "calc(100vh - 190px)",
            }}
          >
            <div className={classes.listMain}>
              {/* --------------------------------------------------------- */}
              {/* DATE RANGE FILTERS */}
              {/* --------------------------------------------------------- */}
              <div className={clsx(classes.listSection, classes.rightAlign)}>
                <Grid container spacing={2}>
                  {/* -------------DURATION SELECT DROPDOWN----------------- */}
                  <Grid item xs={6} md={3}>
                    <div className={classes.fieldContainer}>
                      <Select
                        value={presetValue}
                        styles={selectStyle}
                        options={this.presetRanges}
                        onChange={this.setNewDates}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>

                  {/* -------------DATEPICKER----------------- */}
                  <Grid item xs={6} md={5} className={classes.dateHolder}>
                    <DateRangePicker
                      startDate={dates.startDate}
                      startDateId="start_date"
                      endDate={dates.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) =>
                        this.datePickerHandler(startDate, endDate)
                      }
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      focusedInput={focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      noBorder={true}
                    />
                  </Grid>

                  {/* -------------BOOKING SOURCE SELECT DROPDOWN----------------- */}
                  <Grid item xs={12} lg={12}>
                    <div className={classes.lastRow}>
                      <div className={classes.resStatus}>
                        <div className={classes.fieldContainer}>
                          <Select
                            // defaultValue={statusFilters}
                            styles={selectStyle}
                            isMulti
                            placeholder="Filter by booking source ..."
                            options={bookingSources}
                            onChange={this.setBookingSource}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#666666",
                                primary25: "#dddddd",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className={classes.button}
                        onClick={this.handleSubmit}
                      >
                        {t("reports.transactionPage.submit")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {isLoading && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}

              {/* --------------------------------------------------------- */}
              {/* PICKUP ANALYSIS GRAPH*/}
              {/* --------------------------------------------------------- */}
              {!isLoading && (
                <div
                  className={classes.listSection}
                  style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
                >
                  <div className={classes.revenueGraph}>
                    {pickupData.length !== 0 && (
                      <BarChart
                        keyName="resvCount"
                        maxValue={Math.max.apply(
                          Math,
                          pickupData.map(function (o) {
                            return o.count;
                          })
                        )}
                        data={pickupData}
                        customToolTipHandler={this.getToolTipComponent}
                      />
                    )}

                    {pickupData.length === 0 && (
                      <div className={classes.blankSlate}>
                        {t("experiences.reports.noData")}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* --------------------------------------------------------- */}
              {/* TABLE SELECTOR */}
              {/* --------------------------------------------------------- */}
              {!isLoading && (
                <div className={classes.listSection}>
                  <div className={classes.label}>Filter</div>
                  <div className={classes.tableHeader}>
                    <div className={classes.pillContainer}>
                      <div
                        className={clsx({
                          [classes.pill]: true,
                          [classes.pillSelected]: !resCountDisplay,
                        })}
                        onClick={() =>
                          this.setState({ resCountDisplay: false })
                        }
                      >
                        Reservation List
                      </div>
                      <div
                        className={clsx({
                          [classes.pill]: true,
                          [classes.pillSelected]: resCountDisplay,
                        })}
                        onClick={() => this.setState({ resCountDisplay: true })}
                      >
                        Reservation Count
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* --------------------------------------------------------- */}
              {/* PICKUP TABLE */}
              {/* --------------------------------------------------------- */}
              {!isLoading && resCountDisplay && (
                <div className={classes.listSection}>
                  {pickupData.length !== 0 && (
                    <BasicExport
                      t={t}
                      data={pickupData}
                      startDate={submittedDates.startDate.format("DD MMM YYYY")}
                      endDate={submittedDates.endDate.format("DD MMM YYYY")}
                      sameDate={moment(submittedDates.startDate).isSame(
                        submittedDates.endDate,
                        "day"
                      )}
                    />
                  )}
                </div>
              )}

              {/* --------------------------------------------------------- */}
              {/* RESERVATION LIST TABLE */}
              {/* --------------------------------------------------------- */}
              {!isLoading && !resCountDisplay && (
                <div className={classes.listSection}>
                  <ReservationExport
                    t={t}
                    data={resvListData}
                    startDate={submittedDates.startDate.format("DD MMM YYYY")}
                    endDate={submittedDates.endDate.format("DD MMM YYYY")}
                    sameDate={moment(submittedDates.startDate).isSame(
                      submittedDates.endDate,
                      "day"
                    )}
                    handleTableRowClick={this.handleOpenReservation}
                    openInNewTab={this.openInNewTab}
                    currencyData={currencyData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Open reservation when reservation item clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && (
          <Reservation
            open={openReservation}
            handleCloseReservation={this.handleCloseReservation}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSpaceID: state.dashboard.currentSpace,
    currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
    currentProperty: state.property,
    live: state.live,
    isLoading: state.loading.FETCH_PICKUP_INFO,
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Pickup))
);
