import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { setCurrentModule } from '../redux/actions/dashboard/dashboard';
import { getStripeAccountStatus } from '../redux/actions/payments/payments';
import { fetchChannelStatus, resetChannelStatus } from '../redux/actions/microsite/channels';
import { fetchProperty } from '../redux/actions/dashboard/property';

import CalendarPage from '../components/beds/CalendarPage';
import BedsPage from '../components/hostel/BedsPage';
import FrontdeskPage from '../components/hostel/FrontdeskPage';
import ReportsPage from '../components/hostel/ReportsPage';
import AutomatePage from '../components/hostel/AutomatePage';
import ChannelsPage from '../components/hostel/ChannelsPage';
import FloorPlanPage from '../components/hostel/FloorPlanPage';

import { MENU } from '../utils/layout';

import AccessController from '../components/permissions/AccessController';
import { MODULE } from '../common/constants/permission';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0px 10px',
    marginRight: '20px',
    width: 'calc(100% - 50px)',
    [theme.breakpoints.down('sm')]:{
      margin: 'unset',
      width: '100%'
    }
  },
  moduleContainer: {
    display: 'block',
    [theme.breakpoints.down('sm')]:{
      margin: 'unset',
    }
  },
  moduleSection:{
    padding: '0px 20px'
  },
  xsPaddingNone: {
    [theme.breakpoints.down('xs')]: {
      padding: 'unset'
    }
  } 
}));

const HostelContainer = (props) => {
  const classes = useStyles();
  const { currentSubModule, currentModule, currentSpace, dispatch, propertyID } = props;
  const subMenuList = MENU[currentModule].submenu;
  const activeSubModule = currentSubModule || MENU[currentModule].defaultSubmenu;

  useEffect(()=>{

    dispatch(getStripeAccountStatus(propertyID));
    dispatch(fetchChannelStatus(propertyID));
    dispatch(fetchProperty(propertyID))

    //Stripe connect redirect to automate tab....
    const params = new URLSearchParams(window.location.search);
    if(params.has('redirect')){ 
      dispatch(
        setCurrentModule(
          currentModule, 
          subMenuList.automate
        )
      );
      window.history.replaceState({},document.title, "/");
    }

    return () =>{
      dispatch(resetChannelStatus(propertyID));
    }
  }, []);

  return (
    <div className={classes.root}>
      
      {/* {currentSpace && activeSubModule == subMenuList.calendar.id && <div className={classes.moduleContainer}>
          <AccessController requiredPermission={MODULE.CALENDAR.ID}>
            <CalendarPage />
          </AccessController>
      </div>}

      {currentSpace && activeSubModule == subMenuList.manage.id && <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <BedsPage isSystemAdmin={props.isSystemAdmin}/>
          </div>
      </div>}

      {currentSpace && activeSubModule == subMenuList?.channels?.id && <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <ChannelsPage />
          </div>
      </div>}


      {currentSpace && activeSubModule == subMenuList.frontdesk.id && <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <FrontdeskPage />
          </div>
      </div>}

      {currentSpace && activeSubModule == subMenuList.reports.id && <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <ReportsPage />
          </div>
      </div>}

      {currentSpace && activeSubModule == subMenuList.automate.id && <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <AutomatePage />
          </div>
      </div>} */}


      {currentSpace && ({

        //CALENDAR........................
        [subMenuList.calendar.id]: (
          <AccessController requiredPermission={MODULE.CALENDAR.ID}>
            <CalendarPage />
          </AccessController> 
        ),

        //MANGAE........................
        [subMenuList.manage.id]: (   
          <div className={classes.moduleSection}>
            <BedsPage isSystemAdmin={props.isSystemAdmin} />
          </div>       
        ),

        //CHANNELS........................
        [subMenuList?.channels?.id]: (        
          <div className={classes.moduleSection}>
            <ChannelsPage />
          </div>         
        ),

        //REPORTS........................
        [subMenuList.reports.id]: (         
          <div className={classes.moduleSection}>
            <ReportsPage />
          </div>       
        ),

        //AUTOMATE........................
        [subMenuList.automate.id]: (        
          <div className={classes.moduleSection}>
            <AutomatePage />
          </div>      
        ),

        //FRONTDESK........................
        [subMenuList.frontdesk.id]: (          
          <div className={classes.moduleSection}>
            <FrontdeskPage />
          </div>         
        ),

      })[activeSubModule]}

    </div>
  );
}

const mapStateToProps = state =>{
  const { dashboard, spaces, auth  } = state;
  const currentSpaceInfo = spaces[dashboard.currentSpace];
  return{
    currentSpace: dashboard.currentSpace,
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule,
    isSystemAdmin: auth.user.isSystemAdmin,
    propertyID: currentSpaceInfo?.propertyID
  }
}

export default connect(mapStateToProps)(HostelContainer);