import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD COLOMBIA MIGRACION REPORT
//----------------------------------------------------------------
export function loadColombiaReport(propID, date) {
  const ACTION_NAME = "LOAD_COLOMBIA_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/migracion/${propID}?date=${date}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET COLOMBIA MIGRACION REPORT
//----------------------------------------------------------------
export const resetColombiaReport = () => ({
  type: 'RESET_COLOMBIA_REPORT'
})