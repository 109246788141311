import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    calendarMenu: {
        position: 'relative',
        display: 'flex',
        '& $buttonContainer:first-child': {
            marginLeft: 'unset'
        }
    },
    buttonContainer: {

    },
    calendarRoomsGrid: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100vh - 790px)',
        top: '0px',
    },
    shortcutsContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    shortcut: {
        alignItems: 'center',
        fontSize: '1.2rem',
        borderRadius: 5,
        color: '#484848',
        cursor: 'pointer',
        display: 'flex',
        fontWeight: 600,
        marginTop: 8,
        marginRight: 10,
        padding: 8,
        width: 120,
        '&:hover': {
            background: theme.palette.grey[300],
        }
    },
    fullScreenShortcut: {
        margin: 0,
        zIndex: 1001,
    },
    arrowIcon: {
        color: '#484848',
        fontSize: '1.3rem',
        marginRight: 10,
    },
    optionContainer: {
        display: 'flex',
        marginTop: 14,
        position: 'absolute',
        top: '35px',
        [theme.breakpoints.down("md")]:{
            top: 40,
            left: 10,
        }
    },
    sortCalendar: {
        alignItems: 'center',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        height: 23,
        justifyContent: 'center',
        padding: 5,
        marginRight: 8,
        width: 25,
        [theme.breakpoints.down("md")]:{
            height: 20,
            width: 20,
            padding: 3,
            marginRight: 5,
        },
        '& img': {
            height: 25,
            opacity: 0.3,
            width: 25,
        },
        '&:hover': {
            background: '#edf2f7',
        }
    },
    calendarOption: {
        '& img': {
            height: 20,
            width: 20,
        },
    },
    addBtn:{
        alignItems: 'center',
        background: '#006699',
        borderRadius: 4,
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        justifyContent: 'center',
        lineHeight: 1.5,
        margin: '0px 10px',
        '&:hover': {
            background: '#006699'
        },
        [theme.breakpoints.down("xs")]:{
            display: 'none'
        }
    }
}));

const SortOptions = props => {
    const classes = useStyles();
    const { 
        day, 
        checkIn, 
        setResetDate, 
        handleCalendarFilter, 
        handleOpenRoomBlock, 
        setFullScreenMode,
        fullScreenMode,
        handleNewReservation,
        handleColorConfig
    } = props;

    const isCurrentDate = day.startDate.isSame(moment(checkIn), 'date');
    return (
        <>
            <div className={classes.calendarRoomsGrid}>

                <div className={classes.shortcutsContainer}>

                    {fullScreenMode && (
                        <Button 
                            className={classes.addBtn} 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleNewReservation}
                        >
                            New Reservation
                        </Button>
                    )}

                    {!isCurrentDate &&
                        <div 
                            className={clsx({[classes.shortcut]: true, [classes.fullScreenShortcut]: fullScreenMode})}
                            onClick={() => setResetDate(checkIn)}
                        >
                            <ArrowBackIosIcon className={classes.arrowIcon} />
                        Back to Today
                        </div>
                    }
                </div>
                <div className={clsx({ [classes.optionContainer]: true, 
                    // [classes.padded]: isCurrentDate
                })}>
                    
                    <div 
                        variant="contained" 
                        className={classes.sortCalendar} 
                        onClick={handleCalendarFilter}
                    >
                        <img src={IMAGES.ICONS.sort} className="SortButtonIcon" />
                    </div>
                    
                    <div 
                        variant="contained" 
                        className={clsx(classes.sortCalendar, classes.calendarOption)} 
                        onClick={handleOpenRoomBlock}
                    >
                        <img src={IMAGES.ICONS.block} className="SortButtonIcon" />
                    </div>

                    <div 
                        variant="contained" 
                        className={clsx(classes.sortCalendar, classes.calendarOption)} 
                        onClick={handleColorConfig}
                    >
                        <img src={IMAGES.ICONS.palette} className="SortButtonIcon" />
                    </div>

                    {!fullScreenMode && (
                        <Hidden xsDown>
                            <div 
                                variant="contained" 
                                className={clsx(classes.sortCalendar, classes.calendarOption)} 
                                onClick={setFullScreenMode}
                            >
                                <img src={IMAGES.ICONS.fullscreen} className="SortButtonIcon" />
                            </div>
                        </Hidden>
                    )}
        
                </div>
            </div>
        </>
    )
}

export default SortOptions;