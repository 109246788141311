import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select'
import { Tooltip } from '@material-ui/core';
import IMAGES from '../../../constants/images';
import { content_languages } from '../helper';

const useStyles = makeStyles(theme => ({
    root: {

    },
    dropDownContainer: {
        marginTop: 10,
        width: 'calc(80% + 32px)',
        marginBottom: 20,
    },
}));

const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
      paddingLeft: 8,
      boxShadow: '0 1px 10px #E0E0E0',
      '[type="text"]': {
        fontFamily: 'Roboto, sans-serif !important',
        fontSize: '1.4rem',
        color: 'rgba(0, 0, 0, 0.87)'
      }
    })
  }

const LanguagesView = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className='label'>
                {'Languages'}
                <Tooltip title={'Displaying content in languages other than English will greatly enhance your property profile.'}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                </Tooltip>
            </div>
            <div className={classes.dropDownContainer}>
                <Select
                    value={content_languages.find(lang => lang.value == props.value)}
                    styles={selectStyle}
                    options={content_languages}
                    onChange={(e) => props.updateLanguage(e.value)}
                    theme={theme => ({
                        ...theme,
                        border: 0,
                        colors: {
                            ...theme.colors,
                            primary: '#666666',
                            primary25: '#dddddd'
                        }
                    })}
                />
            </div>
        </div>
    )
}

export default LanguagesView;