import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import { fetchDateFormat, DATE_FORMATS } from '../../../utils/utility'; 

// Process reservations to display in report table
// ------------------------------------------------------------------------
const experienceReservationProcessing = (reservations, currencyData) => {
  let dataFormat = [];

  reservations && reservations.length!==0 && reservations.map(item => {
    dataFormat.push(
      {
        name: toTitleCase(item.name),
        products: item.productNames.reduce((str, date) => `${str} ${date}`, ''),
        dates: item.productDates.reduce((str, date) => `${str} ${date}`, ''),
        source: item.bookingSource,
        status: toTitleCase(item.status),
        source: item.bookingSource,
        amount: item.total,
        balance: item.balance,
        createdAt: fetchDateFormat(item.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`),
      })
    }
  );
  return dataFormat;
}

const experienceOccupancyProcessing = (occupancy, level) => {
  let dataFormat = [];

  occupancy && occupancy.length!==0 && occupancy.map(item => {
    dataFormat.push(
      {
        occupancy: (parseFloat(item.occupancy).toPrecision(4))*100,
        date: (level === 'daily') ? moment(item.date).format('DD MMM') : moment(item.date).format('MMM YYYY')
      })
    }
  );
  return dataFormat;
}

const experienceOccupancyTable = (occupancy, level) => {
  let table = [];

  occupancy && occupancy.length!==0 && occupancy.map(item => {
    table.push(
      {
        occupancy: (parseFloat(item.occupancy).toPrecision(4))*100 + '%',
        capacity: item.capacity,
        booked: item.capacity - item.available,
        date: (level === 'daily') ? moment(item.date).format('DD MMM') : moment(item.date).format('MMM YYYY')
      })
    }
  );
  return table;
}


const processExperienceRevenue = (revenueArray, currencyData, submittedDates, level) => {
  const tableData = [];
  const graphData = [];
  let difference = level==='daily' ? 'day' : (level === 'monthly' ? 'month' : 'week');
  
  if (revenueArray) {
    let dateVar = submittedDates.startDate.clone().subtract(1, difference);
    do {
      dateVar = dateVar.add(1, difference);
      graphData.push({
        "date": level==='monthly' ? dateVar.format('MMM YYYY') : dateVar.format('DD MMM'), 
        "revenue":  revenueArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
                    revenueArray.find(e => moment(e.date).isSame(dateVar, difference)).amount : 
                    0
      })
    } while (!dateVar.isSame(submittedDates.endDate, difference))   
  }

  // console.log('graph', graphData);

  revenueArray && revenueArray.length!==0 && revenueArray.map(item => {
    tableData.push({
      date: level==='monthly' ? moment(item.date).format('MMM YYYY') : moment(item.date).format('DD MMM, YYYY'),
      revenue: item.amount,
    })
  })

  return { tableData, graphData }
} 

export { experienceReservationProcessing, processExperienceRevenue, experienceOccupancyProcessing, experienceOccupancyTable };