import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { updateHWMigrationInfo } from '../../../redux/actions/hwMigration/hwMigration';

const styles = theme =>({
    root: {
        margin: "0px auto",
        maxWidth: "380px",
        padding: "10px 0px",
    },
    description: {
        color: "#666666",
        fontSize: "1.2rem",
        marginBottom: "40px",
        lineHeight: "15px",
    },
    labelColor: {
        color: "#999999 !important",
    },
    addRoomSection: {
        marginBottom: "45px",
    },
    roomsContainer: {
        display: 'flex',
        flexWrap: "wrap",
        marginBottom: "12px",
    },
    room: {
        color: "#fff",
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        alignItems: "center",
        marginRight: "10px",
        borderRadius: "5px",
        marginBottom: "8px",
        justifyContent: "space-between",
        backgroundColor: "#42476f",
        minWidth: "30%",

        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset'
        },

        '& .roomName':{ 
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: "17px",
            marginRight: "8px",
        },
        '& .closeIcon':{
            cursor: "pointer",
            width: "18px",
            height: "18px",
        },
    },
    formControl: {
        display: "flex",
        alignItems: "center"
    },
    iconButton: {
        color: "#000",
        border: "1px solid #e0e0e0",
        marginLeft: "10px",
        borderRadius: "7px",
        padding: "11px",
        fontSize: "1.4rem",
        '& .addIcon': {
            fontSize: "2.6rem",
        }
    }
});
class NameRooms extends Component {

    state = { 
        showError: false
    }

    deleteRoomHandler = (roomID, roomTypeID) =>{
        const { activeStep, updateAPIParams, dispatch } = this.props;
        const data = {  
            _id: roomID,
            roomTypeID
        }
        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data));
    }   

    addRoomHandler = (event, roomTypeID) =>{
        event.preventDefault();
        const { activeStep, updateAPIParams, dispatch } = this.props;
        const roomName = event.target.roomName.value;

        if(!roomName) return;
        
        const data = { 
            roomTypeID, 
            name: roomName 
        };
        
        event.target.reset();

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data));
        
    }

    validateRoomTypes = () =>{
        const { roomTypes } = this.props;
        let errorStatus = false;

        roomTypes.forEach(roomType => {
            if(!roomType?.rooms || roomType.rooms?.length == 0){
                errorStatus = true;
            } 
        });

        return !errorStatus;
    }

    submitHandler = () =>{

        if(!this.validateRoomTypes()){
            this.setState({ showError: true });
            return;
        }

        const { activeStep, dispatch, updateAPIParams, nextStep } = this.props;
        const data = { completed: true };

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data))
        .then(response =>{
            if(response.ongoingStep) nextStep(); 
        });
    }

    handleCloseError = () =>{
        this.setState({ showError: false });
    }

    render(){
        const { classes, nextStep, roomTypes, t } = this.props;
        const { showError } = this.state;
        
        return(
            <div className={classes.root}>
                <div className="title">{t('hwMigration.title.step3')}</div>
                <div className={classes.description}>{t('hwMigration.descriptions.step3')}</div>

                {roomTypes.map((roomType, index) =>(
                    <div className={classes.addRoomSection} key={`roomTypes_${roomType.roomTypeID}_${index}`}>

                        <p className={clsx("label", classes.labelColor)}>Room Type on Hostelworld</p>
                        <div className="title">{roomType.name}</div>
                        
                        {roomType.rooms.length > 0 && (
                            <div className={classes.roomsContainer}>
                                {roomType.rooms.map((room, rindex) =>(
                                    <div className={classes.room} key={`room_${room._id}_${rindex}`}>
                                        <div className="roomName">{room.name}</div>
                                        <CloseIcon 
                                            className="closeIcon" 
                                            onClick={() => this.deleteRoomHandler(room._id,roomType.roomTypeID )}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        
                        <form 
                            className={classes.formControl} 
                            onSubmit={e => this.addRoomHandler(e, roomType.roomTypeID)}
                            autoComplete={'off'}
                        >
                            <TextField 
                                name="roomName"
                                variant="outlined" 
                                placeholder={t('hwMigration.formLabels.addRoomtype')}
                                className="textInput"
                            />

                            <IconButton type="submit" className={classes.iconButton} component="button">
                                {/* <AddIcon className="addIcon" /> */}
                                Add
                            </IconButton>
                        </form>
                    </div>
                ))}

                <Button 
                    onClick={nextStep}
                    className="nextButton"
                    onClick={this.submitHandler}
                >
                    {t('actions.next')}
                </Button>

                {/* ------------- Snackbar error messages -------------- */}
                {showError && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">
                            {t('hwMigration.formErrors.addRoomtype')}
                        </span>}
                    />
                )}
                
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { hwMigration } = state;
    return { 
        roomTypes: hwMigration?.step3 || []
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NameRooms)));