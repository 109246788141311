import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { toTitleCase, truncateText } from '../../utils/wordUtils';
import Grid from '@material-ui/core/Grid';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    padding: theme.spacing(5),
    width: 'calc(100% - 80px)',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  icon: {
    height: 40,
    width: 40
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: '#333333',
    fontWeight: 600,
    fontSize: '1.4rem',
    marginBottom: 10,
  },
  description: {
    color: '#999999',
    fontSize: '1.2rem',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  incompleteButton: {
    border: '1px solid #999999',
    borderRadius: 5,
    cursor: 'pointer',
    padding: '10px 20px',
  },
  editContainer: {
    borderRadius: '50%',
    cursor: 'pointer',
    marginLeft: 10,
    opacity: 0.7,
    padding: theme.spacing(1),
    '& img': {
      height: 20,
      width: 20,
    },
    '&:hover': {
      background: '#F0F0F0'
    }
  }
}));


const DraftPreview = (props) => {
  const classes = useStyles();
  const { draftData } = props;
  const { categories=[], title='', description='' } = draftData;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2} md={2} className={classes.iconContainer}>
          {categories.length > 0 && draftData.categories[0]}
        </Grid>
        <Grid item xs={6} md={7}>
          <div className={classes.detailContainer}>
            <div className={classes.title}>{toTitleCase(title)}</div>
            <div className={classes.description}>{truncateText(description, 200)}</div>
          </div>
        </Grid>
        <Grid item xs={4} md={3} className={classes.buttonContainer}>
          <div className={classes.editContainer} onClick={() => props.editDraft(draftData._id)}>
            <img src={IMAGES.ICONS.editPencil} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default DraftPreview;