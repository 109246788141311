import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';

import { fetchExperiencesBooking, resetExperienceReservationsReport } from '../../../redux/actions/experiences/reports';
import { experienceReservationProcessing } from '../../../redux/selectors/reports/experienceReservations';

import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  listContainer: {
    height: 'calc(100vh - 154px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 88px)'
    }
  },
  listSection: {
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  rightAlign: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      boxShadow: '0 2px 30px #F0F0F0',
    }
  },
  label: {
    color: '#000000',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 20,
  },
  fieldContainer: {
    boxShadow: '0 1px 10px #E0E0E0',
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  dateHolder: {
    '& .DateRangePicker_picker': {
      zIndex: 1009,
      top: '45px !important',
    },
    '& .DateInput_fang': {
      zIndex: 1010
    },
    '& .DateRangePickerInput': {
      boxShadow: '0 1px 10px #E0E0E0',
    }
  },
  lastRow: {
    alignItems: 'flex-end',
    display: 'flex',
    marginBottom: 20,
    marginTop: 10,
    width: '100%',
  },
  resStatus: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    alignItems: 'center',
    background: '#000000',
    borderRadius: '5px',
    color: '#ffffff',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'center',
    marginLeft: 20,
    padding: '5px 10px',
    width: 60,
    height: 30,
  },
  dateHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },
  date: {
    borderRadius: 10,
    boxShadow: '0 2px 30px #F0F0F0',
    color: '#484848',
    cursor: 'pointer',
    marginRight: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  options: {
    borderRadius: 10,
    boxShadow: '0 2px 30px #F0F0F0',
    paddingLeft: 10,
    paddingRight: 10,
    '& input': {
      borderRadius: 10,
      color: '#484848',
      fontWeight: 500,
    }
  },
  selected: {
    background: '#4F9581',
    color: '#ffffff',
    fontWeight: 600,
  },
  tableContainer: {
    boxShadow: '0 2px 20px #F0F0F0',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  desc: {
    color: '#666666',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginTop: 5,
  },
  thead: {
    background: '#F6F2EE',
  },
  trow: {
    cursor: 'pointer'
  },
  roomTypes: {
    display: 'flex',
    flexDirection: 'column'
  },
  highlighted: {
    color: 'red'
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 300,
    justifyContent: 'center',
    width: '100%',
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }),
  placeholder: base => ({
    ...base,
    fontWeight: 500,
  })
};

const reservationStatus = [
  {value: 'CONFIRMED', label: 'Confirmed'},
  {value: 'NOSHOW', label: 'No Show'},
  {value: 'CANCELLED', label: 'Cancelled'},
]

const BasicExport = (props) => {
  const { t } = props
  return (
    <MaterialTable
      title={props.sameDate ? `${t('reports.reservations')} ${props.startDate}` : `${t('reports.reservations')} ${props.startDate} - ${props.endDate}`}
      columns={[
        { title: 'Name', field: 'name', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Source', field: 'source', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Experience', field: 'products', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Dates', field: 'dates', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Total', field: 'amount', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Balance', field: 'balance', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Status', field: 'status', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Created At', field: 'createdAt', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}}
      ]}
      data={props.data}        
      options={{
        exportButton: true,
        rowStyle: { fontSize: '1.2rem'}
      }}
    />
  )
}


const Bookings = (props) => {
  const { t } = props

  const presetRanges = [
    { value: 'today', label: t('reports.presetRange.today') },
    { value: 'yesterday', label: t('reports.presetRange.yesterday') },
    { value: 'tomorrow', label: t('reports.presetRange.tomorrow') },
    { value: 'past15', label: t('reports.presetRange.past15') },
    { value: 'past30', label: t('reports.presetRange.past30') },
    { value: 'custom', label: t('reports.presetRange.custom') }
  ]

  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const classes = useStyles();
  const { open, handleClose, currentSpace, reservations, roomTypes, loadingReservations, experiences } = props;
  const [dates, setDates] = React.useState({startDate: moment().subtract(30, 'days'), endDate: moment() });
  const [submittedDates, setSubmittedDates] = React.useState({startDate: moment(), endDate: moment()});
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [preset, setPreset] = React.useState(4);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country
  }
  const [statusFilters, setStatusFilers] = React.useState([reservationStatus[0], reservationStatus[1], reservationStatus[2]])
  const [status, setStatus] = React.useState(['CONFIRMED', 'NOSHOW', 'CANCELLED']);

  const tableData = useSelector(state => experienceReservationProcessing(state.reports.experienceReservations, currencyData));


  useEffect (() => {
    handleSubmit();
    return function cleanup() {      
      props.dispatch(resetExperienceReservationsReport());
    };
  }, [])

  const setNewDates = (e) => {
    setPreset(presetRanges.find(item => item.value==e.value))
    let reportFrom ;
    let reportTo;
    let newLevel = 'daily';
    if (e.value==='yesterday') {
      reportFrom = moment().subtract(1, 'day');
      reportTo = moment().subtract(1, 'day');
    } else if (e.value==='tomorrow') {
      reportFrom = moment().add(1, 'day');
      reportTo = moment().add(1, 'day');
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, 'days');
      reportTo = moment();
    }
    const newDiff = reportTo.diff(reportFrom, 'days');
    if (newDiff > 90) {
      newLevel = 'monthly'
    } else if (newDiff > 30) {
      newLevel = 'monthly'
    };
    setDates({startDate: reportFrom, endDate: reportTo});
    // setLevel(newLevel);
  }

  const setNewStatus = (value) => {
    if (value) {
      let newStatus = value.map(item => item.value);
      setStatus(newStatus);
    } else setNewStatus([]);
  }

  const handleSubmit = () => {
    const data = {
      status,
      startDate: moment(dates.startDate).format('YYYY-MM-DD'),
      endDate: moment(dates.endDate).format('YYYY-MM-DD'),
      propertyID: currentSpace.propertyID
    }
    props.dispatch(fetchExperiencesBooking(data));
    setSubmittedDates({startDate: dates.startDate, endDate: dates.endDate});
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t('experiences.reports.bookings')} {t('reports.report')}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>

          {/* --------------------------------------------------------- */}
          {/* DATE RANGE FILTERS */}
          {/* --------------------------------------------------------- */}
          <div className={clsx(classes.listSection, classes.rightAlign)}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <div className={classes.fieldContainer}>
                  <Select 
                    value={presetRanges[`${preset}`]}
                    styles={selectStyle}
                    options={presetRanges}
                    onChange={setNewDates}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={5} className={classes.dateHolder}>
                <DateRangePicker
                  startDate={dates.startDate}
                  startDateId="start_date"
                  endDate={dates.endDate}
                  endDateId="end_date"
                  onDatesChange={({ startDate, endDate }) => {setDates({ startDate, endDate }); setPreset(5)}}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  focusedInput={focusedInput}
                  displayFormat={'DD MMM YYYY'}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                  noBorder={true}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <div className={classes.lastRow}>
                  <div className={classes.resStatus}>
                    <div className={classes.fieldContainer}>
                      <Select 
                        defaultValue={statusFilters}
                        styles={selectStyle}
                        isMulti
                        placeholder={t('reports.reservationPage.filterResv')}
                        options={reservationStatus}
                        onChange={setNewStatus}
                        theme={theme => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: '#666666',
                            primary25: '#dddddd'
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className={classes.button} onClick={handleSubmit}>{t('reports.transactionPage.submit')}</div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.listSection}>

            {loadingReservations && <div className={classes.loadingState}><CircularProgress /></div>}

            {!loadingReservations && 
              <BasicExport 
                t={t}
                data={tableData} 
                startDate={submittedDates.startDate.format('DD MMM YYYY')} 
                endDate={submittedDates.endDate.format('DD MMM YYYY')} 
                sameDate={moment(submittedDates.startDate).isSame(submittedDates.endDate, 'day')}
              />
            }

          </div>

        </div>
        
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  reservations: state.reports.reservations || {},
  roomTypes: state.roomTypes,
  loadingReservations: state.loading.LOAD_EXPERIENCE_RESERVATIONS_REPORT,
  experiences: state.experiences
});

export default withTranslation()(connect(mapStateToProps)(Bookings));