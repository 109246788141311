import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import clsx from 'clsx';

import { logoutUser, setCurrentUser } from "../../redux/actions/auth/auth";
import EditUser from '../user/EditUser';
import NotificationList from "../notifications/NotificationList";
import MessageList from "../messages/MessageList";
import ToggleSpace from "./ToggleSpace";
import { initials } from '../utils/wordUtils';

import { stepCount } from '../../redux/selectors/space/stepCount';
import { setOnboarding } from '../../redux/actions/landing/uiConfig';
import { setCurrentSpace, setCurrentSpaceType } from '../../redux/actions/dashboard/dashboard';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Badge from "@material-ui/core/Badge";
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  header: {
    background: 'transparent',
    boxShadow: 'none',
    padding: "0px !important",
    transition: "all 0.15s linear",
    width: "444px",
    zIndex: "1101",
    [theme.breakpoints.up('md')]: {
      width: 344,
    },
    [theme.breakpoints.up('lg')]: {
      width: 444,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      background: '#ffffff',
    }
  },
  toolbar: {
    alignItems: "center",
    display: "flex",
    height: "80px",
    margin: "0 auto",
    padding: "0px !important",
    paddingRight: "20px",
    paddingLeft: "20px",
    justifyContent:"space-between",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down('xs')]: {
      height: "60px",
    },
  },
  activeHeader: {
    boxShadow: "0 0 10px rgba(0,0,0,0.4)"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: "space-between",
    },
  },
  headerLeft: {
    alignItems: 'center',
    color: '#1d1d1d',
    display: 'flex',
    marginLeft: 20,
    minWidth: 'calc(50vw - 12px)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 12,
      maxWidth: 'calc(50vw - 12px)',
    },
  },
  spaceName: {
    color: '#000000',
    cursor: 'pointer',
    fontSize: '1.6rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'calc(50vw - 12px)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    }
  },
  headerRight: {
    alignItems: 'center',
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  logo: {
    height: 40,
    width: 40,
    '& img': {
    height: 25,
    width: "auto",
    },
    marginRight: 5,
    verticalAlign: "middle",
    display: "table-cell"
  },
  status: {
    background: '#BB5533',
    borderRadius: 10,
    cursor: 'pointer',
    flexGrow: 1,
    marginRight: 10,
    padding: '10px 15px',
    [theme.breakpoints.up('lg')]: {
      marginRight: 20
    },
  },
  mobileStatus: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
  statusBar: {
    backgroundColor: '#E6BBB3',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#FFFFFF'
    }
  },
  statusText: {
    color: '#FFFFFF',
    fontSize: '1.2rem',
    fontWeight: 500,
    marginBottom: 5,
  },
  headerIconContainer: {
    marginRight: 10,
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    }
  },
  headerIcon: {
    cursor: "pointer",
    height: 25,
    width: 25,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20,
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between"
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    color: "#666666",
    fontSize: 14,
    fontWeight: 500,
    minWidth: 200
  },
  loadMore: {
    display: "flex",
    justifyContent: "center"
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "0px solid #e2e2e2",
    color: '#ffffff',
    fontSize: '1.5rem',
    height: 40,
    lineHeight: 2,
    textAlign: 'center',
    width: 40,
    [theme.breakpoints.down('sm')]: {
      height: 25,
      width: 25
    }
  },
  customBadge: {
    backgroundColor: "#BB5533",
    color: "white"
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchorEl: null,
      notAnchorEl: null,
      msgAnchorEl: null,
      user: null,
      modalOpen: false,
      headerActive: "",
      editUser: false,
      newNotifications: 0,
      openSpaceModal: false,
      hostelExists: false,
    };
    // this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    let hostelFound;
    if (this.props.spaces) {
      hostelFound = Object.values(this.props.spaces).find(item => (item.type!=='Association' || item.type!=='Community'));
      if (hostelFound) this.setState({ hostelExists: true });
    }
  }

  handleMenu = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleNotifications = event => {
    this.setState({ notAnchorEl: event.currentTarget });
  };

  handleMessage = event => {
    this.setState({ msgAnchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleNotClose = () => {
    this.setState({ notAnchorEl: null });
  }

  handleMsgClose = () => {
    this.setState({ msgAnchorEl: null });
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleOnboardingClick = () => {
    if (this.props.currentSpaceID==='community' || this.props.currentSpaceType==='Association') {
      let hostel = Object.values(this.props.spaces).find(item => (item.type!=='Association' || item.type!=='Community'));
      this.props.dispatch(setCurrentSpace(hostel._id));
      this.props.dispatch(setCurrentSpaceType(hostel.type));
    }
    this.props.dispatch(setOnboarding(true));
  }

  handleLogout = () => {
    this.setState({ menuAnchorEl: null });
    this.props.dispatch(logoutUser());
  }

  closeEditUser = () => {
    this.setState({ editUser: false });
  }


  // Space Modal Toggle Functions
  handleClickOpen = () => {
    if (this.props.auth.user.space.length!==0){
      this.setState({ openSpaceModal: true });
    }
  };

  handleClose = () => {
    this.setState({ openSpaceModal: false });
  };


  getStyle = (isActive) => {
    return isActive ? {
      boxShadow: "0 5px 5px -5px rgba(0,0,0,0.3)"
    } : {
      boxShadow: "none",
      borderBottom: "0px"
    }
  }

  render() {
    const { classes, currentSpace, currentSpaceID, currentSpaceType, currentUser, spaces, auth, t } = this.props;
    const { menuAnchorEl, notAnchorEl, msgAnchorEl, hostelExists } = this.state;
    const menuOpen = Boolean(menuAnchorEl);
    const notOpen = Boolean(notAnchorEl);
    const msgOpen = Boolean(msgAnchorEl);

    return (
        <AppBar position="sticky" className={classes.header}>
          <Toolbar
            className={classes.toolbar}
            style={ this.getStyle(this.state.headerActive === "activeHeader")}
          >

            <div className={classes.headerContainer}>

              <Hidden mdUp>
                <div className={classes.headerLeft}>
                  <div className={classes.spaceName} onClick={this.handleClickOpen}>
                    {(currentSpaceID!=='community' && currentSpace) && currentSpace.name}
                    {currentSpaceID==='community' &&
                      <span><img src="/images/icons/counter.png" onClick={this.handleClickOpen} className={classes.logo} /> {t('navbar.community')}</span>
                    }
                  </div>
                </div>
              </Hidden>

              <div className={classes.headerRight}>

                {/* ------------------------------------------------------------------------ */}
                {/* ONBOARDING PROGRESS BAR */}
                {/* ------------------------------------------------------------------------ */}
                {(currentUser.migrationStep === 4 && hostelExists) &&
                  <Hidden xsDown>
                    <div className={classes.status} onClick={this.handleOnboardingClick}>
                      <div className={classes.statusText}>{t('navbar.freePMS')}</div>
                      <LinearProgress className={classes.statusBar} variant="determinate" value={(this.props.stepCount/4) * 100} />
                    </div>
                  </Hidden>
                }

                {/* ------------------------------------------------------------------------ */}
                {/* DISPLAY MESSAGES */}
                {/* ------------------------------------------------------------------------ */}
                <IconButton
                  aria-owns={msgOpen ? 'msg-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMessage}
                  color="inherit"
                  className={classes.headerIconContainer}
                  style={{ backgroundColor: msgOpen ? "rgba(0, 0, 0, 0.08)" : ""}}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      this.props.messages &&
                      Object.keys(this.props.messages).filter(key => this.props.messages[key].unread).length
                    }
                    classes={{ badge: classes.customBadge }}
                  >
                    <img className={classes.headerIcon} src='/images/icons/message.png' />
                  </Badge>
                </IconButton>

                <MessageList msgAnchorEl={this.state.msgAnchorEl} handleMsgClose={this.handleMsgClose} />


                {/* ------------------------------------------------------------------------ */}
                {/* DISPLAY NOTIFICATIONS */}
                {/* ------------------------------------------------------------------------ */}
                <IconButton
                  aria-owns={notOpen ? 'not-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleNotifications}
                  color="inherit"
                  className={classes.headerIconContainer}
                  style={{ backgroundColor: notOpen ? "rgba(0, 0, 0, 0.08)" : ""}}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      this.props.notifications &&
                      Object.keys(this.props.notifications).filter(key => !this.props.notifications[key].status).length
                    }
                    classes={{ badge: classes.customBadge }}
                  >
                    <img className={classes.headerIcon} src='/images/icons/notification.png' />
                  </Badge>
                </IconButton>

                <NotificationList
                  notAnchorEl={this.state.notAnchorEl}
                  handleNotClose={this.handleNotClose}
                />


                {/* ------------------------------------------------------------------------ */}
                {/* USER PROFILE MENU */}
                {/* ------------------------------------------------------------------------ */}
                <IconButton
                  aria-owns={menuOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                  className={classes.headerIconContainer}
                  style={{ backgroundColor: menuOpen ? "rgba(0, 0, 0, 0.08)" : ""}}
                >
                  {this.props.auth.user.avatar &&
                    <Avatar alt={initials(this.props.auth.user.name)} src={this.props.auth.user.avatar} className={classes.avatar} />
                  }
                  {!this.props.auth.user.avatar &&
                    <Avatar alt={initials(this.props.auth.user.name)} className={classes.avatar} >
                      {initials(this.props.auth.user.name)}
                    </Avatar>
                  }
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                  className={classes.menuList}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={this.handleMenuClose}
                >
                  <MenuItem className={classes.menuItem} onClick={() => this.setState({editUser: true, menuAnchorEl: null })}>{t('navbar.editProfile')}</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={this.handleLogout}>{t('navbar.logOut')}</MenuItem>
                </Menu>

                {this.state.editUser &&
                  <EditUser open={this.state.editUser} closeEditUser={this.closeEditUser} />
                }

              </div>

            </div>
          </Toolbar>

          {(currentUser.migrationStep === 4 && hostelExists) &&
            <Hidden smUp>
              <div className={clsx(classes.status, classes.mobileStatus)} onClick={this.handleOnboardingClick}>
                <div className={classes.statusText}>Get started - Free PMS</div>
                <LinearProgress className={classes.statusBar} variant="determinate" value={(this.props.stepCount/4) * 100} />
              </div>
            </Hidden>
          }

          <ToggleSpace open={this.state.openSpaceModal} onClose={this.handleClose} />

        </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpaceType: state.dashboard.currentSpaceType,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  notifications: state.notifications,
  spaces: state.spaces,
  messages: state.messages,
  currentUser: state.auth.user,
  stepCount: stepCount(state.spaces, state.dashboard.currentSpace, state.dashboard.currentSpaceType, state.auth.user)
});

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(withRouter(Header))));