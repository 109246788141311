import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import { ReportProblem, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const backgroundColor = "#eed202";
const textColor = "#000000";
const useStyles = makeStyles({
  root: {
    background: backgroundColor,
    width: "100%",
    position: "fixed",
    zIndex: 1111,
    top: 0,
    left: 0,
  },
  boxWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: textColor,
    fontWeight: 600,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: "6px",
    fontSize: 13,
  },
  subtext: {
    color: textColor,
    fontSize: 11,
    marginTop: "0.5em",
    marginBottom: "6px",
  },
  icon: {
    margin: "10px 10px",
    cursor: "pointer",
    svg: {
      fontSize: "4.5em",
      color: textColor,
    },
  },
});

const AlertBanner = ({ banner, closeBanner, t }) => {
  const classes = useStyles();
  return (
    <>
      {banner ? (
        <div
          className={classes.root}
          style={{
            display: `${banner ? "block" : "hidden"}`,
          }}
        >
          <Box className={classes.boxWrapper}>
            <Box className={classes.icon}>
              <ReportProblem />
            </Box>
            <Box>
              <p className={classes.text}>{banner?.text && t(banner.text)}</p>
              <p className={classes.subtext}>
                {banner?.subtext && t(banner.subtext)}
                <a href={`mailto:${banner.supportEmail}`}>
                  {banner.supportEmail}
                </a>
                {banner?.subtextSuffix && t(banner.subtextSuffix)}
              </p>
            </Box>
            {closeBanner && (
              <Box className={classes.icon}>
                <Close onClick={() => closeBanner()} />
              </Box>
            )}
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default withTranslation()(AlertBanner);
