import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { submitOnboardingRequest } from '../../redux/actions/dashboard/onboarding';

import {Button, InputBase} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles( theme=> ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
  },
  container: {
    background: '#ffffff',
    borderRadius: 15,
    padding: theme.spacing(5),
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#F8F9FB',
    borderRadius: '55px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  bubble: {
    background: '#F8F9FB',
    borderRadius: 5,
    cursor: 'pointer',
    marginRight: 15,
    marginBottom: 10,
    padding: theme.spacing(2)
  },
  selected: {
    alignItems: 'center',
    background: '#999999',
    color: '#FFFFFF',
    display: 'flex',
    fontWeight: 600,
  },
  noLink: {
    cursor: 'auto',
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: '1.6rem',
    marginLeft: 10,
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  skip: {
    color: '#AAAAAA',
    cursor: 'pointer',
    fontSize: '1.3rem',
    marginRight: 20,
  },
  next: {
    background: '#BB6633',
    borderRadius: '5px',
    boxShadow: 'none',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '1.4rem',
    padding: '8px 20px',
    '&:hover': {
      background: '#999999'
    }
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  }
}))

const GoLive = (props) => {
  const classes = useStyles();
  const { currentSpaceID, t } = props;
  const [currentPMS, setCurrentPMS] = React.useState('');
  const [channelManager, setChannelMananger] = React.useState('');
  const [channels, setChannels] = React.useState([]);
  const [notes, setNotes] = React.useState('');
  const [errors, setErrors] = React.useState({pms: false, channelManager: false});

  const handleChange = (e) => {
    setNotes(e.target.value);
  }

  const handlePMSClick = (e) => {
    setCurrentPMS(e.target.id);
    setErrors({...errors, pms: false});
  }

  const handleCMClick = (e) => {
    setChannelMananger(e.target.id);
    setErrors({...errors, channelManager: false});
  }
  
  const handleOTAClick = (e) => {
    if (channels.find(ota => ota===e.target.id)) {
      setChannels(channels.filter(item => item !== e.target.id));
    } else {
      setChannels(channels.concat(e.target.id));
    }
  }

  const handleValidate = () => {
    let errorLog = {};
    let foundError = false;
    if (currentPMS === '') {
      errorLog.pms = true;
      foundError = true;
    }
    if (channelManager === '') {
      errorLog.channelManager = true;
      foundError = true;
    }
    setErrors({...errors, ...errorLog})
    return foundError;
  }
  
  const handleSubmit = () => {
    const foundError = handleValidate();
    if (!foundError) {
      let payload = {
        currentPMS: currentPMS,
        channelManager: channelManager,
        ota: channels,
        note: notes
      }
      props.dispatch(submitOnboardingRequest(currentSpaceID, payload));
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>

        <div className={classes.row}>
          <div className={classes.label}>{t('onboarding.livePage.pms')}</div>
          {currentPMS==='' && <div className={classes.list}>
            <div id="Cloudbeds" className={classes.bubble} onClick={handlePMSClick}>Cloudbeds</div>
            <div id="BPO" className={classes.bubble} onClick={handlePMSClick}>BPO</div>
            <div id="Mews" className={classes.bubble} onClick={handlePMSClick}>Mews</div>
            <div id="Loventis" className={classes.bubble} onClick={handlePMSClick}>Loventis</div>
            <div id="Other" className={classes.bubble} onClick={handlePMSClick}>Other</div>
            <div id="None" className={classes.bubble} onClick={handlePMSClick}>None</div>
          </div>}
          {currentPMS!=='' && <div className={classes.list}>
            <div className={clsx(classes.bubble, classes.selected, classes.noLink)}>
              {currentPMS}
              <CloseIcon className={classes.closeIcon} onClick={() => setCurrentPMS('')} />
            </div>
          </div>}
          {errors.pms && <div className={classes.error}>{t('onboarding.livePage.pmsError')}</div>}
        </div>

        <div className={classes.row}>
          <div className={classes.label}>{t('onboarding.livePage.channelManager')}</div>
          {channelManager==='' && <div className={classes.list}>
            <div id="myAllocator" className={classes.bubble} onClick={handleCMClick}>myAllocator</div>
            <div id="Siteminder" className={classes.bubble} onClick={handleCMClick}>Siteminder</div>
            <div id="WuBook" className={classes.bubble} onClick={handleCMClick}>WuBook</div>
            <div id="Beds24" className={classes.bubble} onClick={handleCMClick}>Beds24</div>
            <div id="Other" className={classes.bubble} onClick={handleCMClick}>Other</div>
            <div id="None" className={classes.bubble} onClick={handleCMClick}>None</div>
          </div>}
          {channelManager!=='' && <div className={classes.list}>
            <div className={clsx(classes.bubble, classes.selected, classes.noLink)}>
              {channelManager}
              <CloseIcon className={classes.closeIcon} onClick={() => setChannelMananger('')}/>
            </div>
          </div>}
          {errors.channelManager && <div className={classes.error}>{t('onboarding.livePage.channelManagerError')}</div>}
        </div>

        <div className={classes.row}>
          <div className={classes.label}>OTAs</div>
          <div className={classes.list}>
            <div id="Hostelworld" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Hostelworld")})} onClick={handleOTAClick}>Hostelworld</div>
            <div id="Booking" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Booking")})} onClick={handleOTAClick}>Booking.com</div>
            <div id="Expedia" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Expedia")})} onClick={handleOTAClick}>Expedia</div>
            <div id="Airbnb" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Airbnb")})} onClick={handleOTAClick}>Airbnb</div>
            <div id="Agoda" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Agoda")})} onClick={handleOTAClick}>Agoda</div>
            <div id="Others" className={clsx({[classes.bubble]: true, [classes.selected]: channels.find(ota => ota==="Others")})} onClick={handleOTAClick}>Others</div>
          </div> 
        </div>

        <div className={classes.row} style={{marginBottom: 0}}>
          <div className={classes.label}>{t('onboarding.livePage.notes')}</div>
          <div className={classes.field}>
            <InputBase
              id="notes"
              name="notes"
              fullWidth
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      
      <div className={classes.buttonContainer}>
        <div className={classes.skip} onClick={props.handleSkip}>{t('onboarding.skip')}</div>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
          {t('actions.submit')}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace
});

export default withTranslation()(connect(mapStateToProps)(GoLive));