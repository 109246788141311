const voidXML = ['<?xml version="1.0" encoding="utf-16"?>', '<printerVoidReceipt>', '</printerVoidReceipt>']

const cashXML = [
	'<?xml version="1.0" encoding="utf-16"?>',
	'<printerFiscalReceipt>',
	'<beginFiscalReceipt />',
	'<printRecItem description="Sample Double Room" quantity="2" unitPrice="1" department="1" justification="1" operator="1" />',
	'<printRecTotal operator="1" description="Payment" payment="1" paymentType="cash" />',
	'<endFiscalReceipt />',
	'</printerFiscalReceipt>'
]

const cardXML = [
	'<?xml version="1.0" encoding="utf-16"?>',
	'<printerFiscalReceipt>',
	'<beginFiscalReceipt />',
	'<printRecItem description="Sample Double Room" quantity="2" unitPrice="1" department="1" justification="1" operator="1" />',
	'<printRecTotal operator="1" description="Payment" payment="1" paymentType="card" />',
	'<endFiscalReceipt />',
	'</printerFiscalReceipt>'
]

module.exports = { voidXML, cashXML, cardXML }