import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
  buttonContainer: {
    display: 'flex',
  },
  bubble:{
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: 10,
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
      padding: 10,
    }
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
}));

const Visibility = props =>{
    const classes = useStyles();
    const { 
        product,
        handleUpdateProduct
    } = props;

    const handleClick = (app) => {
      const index = product.visibility.indexOf(app);
      if (index > -1) {
        let array = [...product.visibility]
        array.splice(index, 1);
        handleUpdateProduct('visibility', [...array]);
      } else {
        handleUpdateProduct('visibility', [...product.visibility, app])
      }
    }

    return(
        <>
            <div className="main-content">
                
                
              <div className="main-content-title">Visibility</div>
              <div className="sub-title">Choose where this product can be purchased within Counter</div>

              <div className="row">
                <div className={classes.buttonContainer}>
                  <div 
                    className={clsx({[classes.bubble]: true, [classes.selected]: (product.visibility.indexOf('Workspace') > -1)})}
                    onClick={() => handleClick('Workspace')}
                  >
                    Workspace
                  </div>
                  <div 
                    className={clsx({[classes.bubble]: true, [classes.selected]: (product.visibility.indexOf('Frontdesk') > -1)})}
                    onClick={() => handleClick('Frontdesk')}
                  >
                    Frontdesk POS App
                  </div>
                  <div 
                    className={clsx({[classes.bubble]: true, [classes.selected]: (product.visibility.indexOf('Booking Engine') > -1)})}
                    onClick={() => handleClick('Booking Engine')}
                  >
                    Booking Engine
                  </div>
                </div>
              </div>

            </div>
        </>
    )
}

export default Visibility; 