import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { returnCurrent } from "../../redux/selectors/dashboard";
import {
  createTag,
  resetEmailTags,
} from "../../redux/actions/reservation/reservationTags";
import moment from "moment";
import clsx from "clsx";
import Select from "react-select";

import EmailTemplateList from "./EmailTemplateList";
import { newEmail } from "../../redux/actions/emails/emails";
import { loadEmailTemplates } from "../../redux/actions/emails/emails";
import { resetError } from "../../redux/actions/reservation/editReservation";
import { getTags } from "../../redux/actions/reservation/reservationTags";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import ReportIcon from "@material-ui/icons/Report";
import DoneIcon from "@material-ui/icons/Done";
import Hidden from "@material-ui/core/Hidden";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { fetchDatePerTimezone } from "../../utils/utility";
import { EMAIL_RULE_EVENTS } from "../../utils/constants";
import TrixEditor from "../utils/TrixEditor";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import EmailHtmlInput from "./EmailHtmlInput";

const useStyles = makeStyles((theme) => ({
  email: {
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&:hover": {
      background: "#eeeeee",
    },
  },
  imageContainer: {
    alignItems: "center",
    height: 100,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      height: 40,
      width: 40,
    },
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "24px",
      left: "24px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  createSection: {
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  line2: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  createContainer: {
    height: "calc(100vh - 256px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 180px)",
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "100%",
  },
  leftAlign: {
    alignItems: "center",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "20%",
  },
  rightAlign: {
    paddingLeft: "10%",
    width: "70%",
  },
  editSubject: {
    fontSize: "1.4rem",
    flexGrow: 1,
    "& input": {
      fontSize: "1.4rem",
    },
  },
  selectOption: {
    width: "45%",
  },
  emailTrigger: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
  },
  roomTypeOptions: {
    width: "100%",
  },
  moreIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(0.5),
    "&:hover": {
      background: "#dddddd",
    },
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  button: {
    marginRight: 10,
  },
  emailBody: {
    background: "#ffffff",
    borderRadius: "4px",
    boxShadow: "0 1px 30px 0 #E0E0E0",
    color: "#666666",
    minHeight: 400,
    padding: theme.spacing(2),
    width: "100%",
  },
  timeContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    alignItems: "center",
    display: "flex",
    marginTop: 10,
    marginBottom: 10,
  },
  tag: {
    width: "100%",
  },
  bubble: {
    background: "#f8f9fb",
    borderRadius: 2,
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1.1rem",
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tagBubble: {
    background: "#C17C74",
    color: "#FFFFFF",
    borderRadius: 10,
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1.1rem",
    marginRight: 10,
    marginBottom: 5,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tagButton: {
    backgroundColor: "#6ea7d6",
    color: "#fff",
    fontWeight: 600,
    marginLeft: 5,
    borderRadius: 5,
  },
  col: {
    width: "30%",
    marginBottom: 10,
    marginRight: 10,
  },
  onefield: {
    display: "inline-block",
  },
  inline: {
    display: "flex",
    alignItems: "center",
  },
  tagIcon: {
    fontSize: "1.8rem",
    marginLeft: 5,
  },
  addSection: {
    display: "flex",
    alignItems: "flex-start",
    padding: "10px 0px",
  },
  tagCol: {
    width: "50%",
    marginBottom: 10,
    marginRight: 10,
  },
  iconHolder: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    marginLeft: 10,
    marginTop: 3,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  field: {
    background: "#f8f9fb",
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 2,
    "& > div": {
      width: "100%",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-6q0nyr-Svg": {
      color: "#000000",
      width: 30,
      height: 30,
    },
  },
  messageRow: {
    alignItems: "flex-start",
    display: "flex",
  },
  messageInfo: {
    display: "flex",
  },
  message: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rightMargin: {
    paddingBottom: 10,
    marginRight: 5,
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "0 2px 70px 0 #E0E0E0",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const emailTriggerOptions = [
  { label: "Manual Trigger", value: "" },
  {
    label: "After New Reservation",
    value: EMAIL_RULE_EVENTS.AFTER_NEW_RESERVATION,
  },
  { label: "Before Check-in", value: EMAIL_RULE_EVENTS.BEFORE_CHECKIN },
  { label: "After Check-in", value: EMAIL_RULE_EVENTS.AFTER_CHECKIN },
  { label: "Before Check-out", value: EMAIL_RULE_EVENTS.BEFORE_CHECKOUT },
  { label: "After Check-out", value: EMAIL_RULE_EVENTS.AFTER_CHECKOUT },
  { label: "On Payment", value: EMAIL_RULE_EVENTS.ON_PAYMENT },
];

const delayOptions = [
  { label: "Manual Trigger", value: -1 },
  { label: "Immediately", value: 0 },
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "4 days", value: 4 },
  { label: "5 days", value: 5 },
];

const EmailTemplates = (props) => {
  const [error, setError] = React.useState({});
  const [newTemplate, setNewTemplate] = React.useState(false);
  const [form, setForm] = React.useState({});
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    t,
    email,
    emailTemplates,
    roomTypes,
    currentSpaceID,
    errors,
  } = props;
  const templateDialog = React.useRef();

  const [invoiceAttach, setInvoiceAttach] = React.useState(false);
  const [fragment, setFragment] = React.useState("");
  const [addTag, setAddTag] = React.useState(false);
  const [newTag, setNewTag] = React.useState(false);
  const [resvTags, setResvTags] = React.useState([]);
  const [tempTags, setTempTags] = React.useState([]);
  const [isHtmlEmbed, setIsHtmlEmbed] = React.useState(false);

  const [tag, setTag] = React.useState("");

  React.useEffect(() => {
    if (emailTemplates.default === true)
      props.dispatch(loadEmailTemplates(currentSpace.propertyID));

    if (!currentSpace.resvTags) {
      props.dispatch(getTags(currentSpace._id, currentSpace.propertyID));
    }
  }, []);

  React.useEffect(() => {
    if (currentSpace.resvTags) {
      let tags = [];
      for (var i = 0; i < currentSpace.resvTags.length; i++) {
        tags.push({
          label: currentSpace.resvTags[i].name,
          value: currentSpace.resvTags[i]._id,
        });
      }

      setResvTags(tags);
    }
  }, [currentSpace.resvTags]);

  React.useEffect(() => {
    if (currentSpace.emailTags && currentSpace.emailTags.length > 0) {
      let tags = [];

      for (var i = 0; i < currentSpace.emailTags.length; i++) {
        tags.push({
          label: currentSpace.emailTags[i].name,
          value: currentSpace.emailTags[i]._id,
        });
      }

      let options = [...resvTags];

      for (var k = 0; k < options.length; k++) {
        tags.map((tag) => {
          if (tag.value === options[k].value) options.splice(k, 1);
        });
      }

      setResvTags(options);
      setTempTags(tags);
    }
  }, [currentSpace.emailTags]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
    if (e.target.value && error[e.target.id]) {
      setError({ ...error, [e.target.id]: false });
    }
  };

  const selectTrigger = (selected, actionMeta) => {
    setForm({ ...form, [actionMeta.name]: selected.value });
  };

  const selectFilter = (selected) => {
    if (selected) {
      setForm({ ...form, filter: selected.map((e) => e.value) });
    } else {
      setForm({ ...form, filter: [] });
    }
  };

  const selectSource = (selected) => {
    if (selected) {
      setForm({ ...form, bookingSource: selected.map((e) => e.value) });
    } else {
      setForm({ ...form, bookingSource: [] });
    }
  };

  const roomTypeOptions =
    currentSpace.roomTypes &&
    currentSpace.roomTypes.map((roomTypeID) => {
      return { value: roomTypeID, label: roomTypes[roomTypeID].name };
    });
  const sourceOptions =
    currentSpace.bookingSource &&
    currentSpace.bookingSource.map((source) => {
      if (source.active) return { value: source._id, label: source.name };
    });

  const handleToggle = () => {
    const newEmailStatus = !emailStatus;
    setForm({ ...form, isActive: newEmailStatus });
    setEmailStatus(newEmailStatus);
  };

  const handleInvoiceToggle = () => {
    const newInvoiceAttach = !invoiceAttach;
    setForm({ ...form, attachInvoice: newInvoiceAttach });
    setInvoiceAttach(newInvoiceAttach);
  };

  const handleBodyUpdate = (update) => {
    setFragment(update);
    setForm({
      ...form,
      body: update,
    });
  };

  const handleCancel = () => {
    templateDialog.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setNewTemplate(false);
    setForm({});
    setError({});
    setAddTag(false);
    setInvoiceAttach(false);
    props.dispatch(resetEmailTags(currentSpace._id));
    setTag("");
    setTempTags([]);
  };

  const emailValidator = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubmit = () => {
    const isEmailValid = emailValidator(form.senderEmail);
    if (!form.subject || !form.senderName || !isEmailValid) {
      setError({
        ...error,
        name: !form.name,
        subject: !form.subject,
        email: !isEmailValid,
      });
      return;
    }

    let arr = [];
    if (form.tags) {
      form.tags.map((tag) => {
        if (tag.value) arr.push(tag.value);
        else arr.push(tag);
      });
    }

    if (tempTags.length > 0) {
      tempTags.map((tag) => {
        if (tag.value) arr.push(tag.value);
      });
    }

    const data = {
      ...form,
      isActive: true,
      isHTML_Editor: isHtmlEmbed,
    };

    if (arr.length > 0) data.tags = arr;

    if (form.triggerTime) data.triggerTime = form.triggerTime.replace(":", "");

    props.dispatch(newEmail(currentSpace.propertyID, data));
    setNewTemplate(false);
    setInvoiceAttach(false);
    setIsHtmlEmbed(false);
    setFragment("");
    setForm({});
    setError({});
    props.dispatch(loadEmailTemplates(currentSpace.propertyID));
    props.dispatch(resetEmailTags(currentSpace._id));
  };

  const handleAddTag = () => {
    setAddTag(true);
  };

  const handleCreateTag = () => {
    setNewTag(true);
  };

  const handleReservationTag = (e) => {
    setForm({ ...form, tags: form.tags ? [...form.tags, e] : [e] });
    let tag = [];
    resvTags.map((t) => {
      if (t.value !== e.value) {
        tag.push(t);
      }
    });
    setResvTags(tag);
  };

  const handleRemoveTag = (index) => {
    let tags = [...form.tags];
    let resv = [...resvTags];
    resv.push(tags[index]);
    tags.splice(index, 1);
    setForm({ ...form, tags: [...tags] });
    setResvTags(resv);
  };

  const handleCreateTemplate = () => {
    setNewTemplate(true);
    templateDialog.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleEnterTag = (e) => {
    setTag(e.target.value);
  };

  const handleSaveTag = () => {
    const payload = {
      emailTemplate: true,
      name: tag,
      propertyID: currentSpace.propertyID,
    };

    props.dispatch(createTag(currentSpaceID, payload));
    setNewTag(false);
    setTag("");
  };

  const handleCancelCreate = () => {
    setNewTag(false);
    setTag("");
  };

  const handleRemoveTempTag = (index) => {
    let temp = [...tempTags];
    let tags = [...resvTags];
    tags.push(temp[index]);
    temp.splice(index, 1);
    setResvTags(tags);
    setTempTags(temp);
  };

  const handleCloseError = () => {
    props.dispatch(resetError("CREATE_TAG"));
  };

  const setHtmlEmbedStatus = (event) => {
    const isChecked = event.target.checked;
    setFragment("");
    setIsHtmlEmbed(isChecked);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth={newTemplate ? "md" : "sm"}
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* {!newTemplate && (
            <Hidden smDown><Button 
              size='small' 
              onClick={() => setNewTemplate(true)} 
              className={classes.newListButton} 
              color='primary' 
              variant='outlined'
            >
              Create
            </Button></Hidden>
          )} */}
          {!newTemplate && (
            <AddIcon
              className={classes.addButton}
              onClick={() => handleCreateTemplate()}
            />
          )}

          <div className={classes.header}>Email Templates</div>
          <AccessHeaderAction moduleID={MODULE.EMAIL.ID} />
          {!newTemplate && (
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          )}
          {newTemplate && (
            <CloseIcon className={classes.closeIcon} onClick={handleCancel} />
          )}
        </div>

        {/* ------------------------------------------------ */}
        {/* CREATE NEW EMAIL TEMPLATE */}
        {/* ------------------------------------------------ */}
        <div
          className={clsx(classes.createSection, classes.createContainer)}
          style={{ display: newTemplate === false ? "none" : "" }}
        >
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.templateName")}
            </div>
            <div className={classes.rightAlign} ref={templateDialog}>
              <TextField
                id="name"
                className={classes.editSubject}
                value={form.name ? form.name : ""}
                onChange={handleChange}
                margin="none"
                error={error.name}
                helperText={error.name && t("automate.email.templateNameError")}
                fullWidth
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.subject")}
            </div>
            <div className={classes.rightAlign}>
              <TextField
                id="subject"
                className={classes.editSubject}
                value={form.subject ? form.subject : ""}
                onChange={handleChange}
                margin="none"
                error={error.subject}
                helperText={
                  error.subject && t("automate.email.emailSubjectError")
                }
                fullWidth
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.senderName")}
            </div>
            <div className={classes.rightAlign}>
              <TextField
                id="senderName"
                className={classes.editSubject}
                value={form.senderName ? form.senderName : ""}
                onChange={handleChange}
                margin="none"
                error={error.name}
                helperText={error.name && t("automate.email.emailNameError")}
                fullWidth
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.senderEmail")}
            </div>
            <div className={classes.rightAlign}>
              <TextField
                id="senderEmail"
                className={classes.editSubject}
                value={form.senderEmail ? form.senderEmail : ""}
                onChange={handleChange}
                margin="none"
                fullWidth
                type={"email"}
                helperText={error.email && t("automate.email.emailError")}
                error={error.email}
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.trigger")}
            </div>
            <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
              <Select
                name="triggerEvent"
                styles={selectStyle}
                options={emailTriggerOptions}
                onChange={selectTrigger}
                defaultValue={emailTriggerOptions.filter(
                  (e) => e.value === (form.triggerEvent || "")
                )}
                className={classes.selectOption}
              />
              <Select
                name="triggerDelay"
                styles={selectStyle}
                options={delayOptions}
                onChange={selectTrigger}
                defaultValue={delayOptions.filter(
                  (e) => e.value === (form.triggerDelay || -1)
                )}
                className={classes.selectOption}
              />
            </div>
          </div>
          {form.triggerDelay !== 0 && (
            <div className={classes.container}>
              <div className={classes.leftAlign}>Trigger Time</div>
              <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                <form className={classes.timeContainer} noValidate>
                  <TextField
                    id="triggerTime"
                    type="time"
                    onChange={handleChange}
                    defaultValue="00:00"
                    className={classes.editSubject}
                  />
                </form>
              </div>
            </div>
          )}
          {form.triggerEvent && form.triggerDelay > 0 && (
            <div className={classes.container}>
              <div className={classes.leftAlign}>
                {/*t('automate.email.filter')*/} Room Types
              </div>
              <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                <Select
                  isMulti
                  isClearable={false}
                  defaultValue=""
                  styles={selectStyle}
                  options={roomTypeOptions}
                  onChange={selectFilter}
                  placeholder={t("automate.email.selectRoomTypes")}
                  className={classes.roomTypeOptions}
                />
              </div>
            </div>
          )}
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {/*t('automate.email.filter')*/} Booking Source
            </div>
            <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
              <Select
                isMulti
                isClearable={false}
                defaultValue=""
                styles={selectStyle}
                options={sourceOptions}
                onChange={selectSource}
                placeholder="Select Booking Source for this template ..."
                className={classes.roomTypeOptions}
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftAlign}>
              {t("automate.email.attachInvoice")}
            </div>
            <div className={classes.rightAlign}>
              <FormControlLabel
                control={
                  <Switch
                    id="attachInvoice"
                    checked={invoiceAttach}
                    onChange={handleInvoiceToggle}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={
                  invoiceAttach
                    ? t("automate.email.active")
                    : t("automate.email.inactive")
                }
              />
            </div>
          </div>
          <Grid container alignItems="center">
            <Grid item xs={1} style={{ marginRight: 0 }}>
              <div style={{ paddingTop: 2 }}>
                <ReportIcon className={classes.moreIcon} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <p>
                <span style={{ fontSize: 12 }}>
                  {t("automate.email.noteMessage")}
                </span>
              </p>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.container}>
            <div className={classes.leftAlign}>Template Restrictions</div>
            <div className={clsx(classes.rightAlign, classes.tag)}>
              {form.tags &&
                form.tags.map((tag, index) => (
                  <span className={classes.tagBubble} key={index}>
                    <div className={classes.inline}>
                      {tag.value ? tag.label : tag}{" "}
                      <CloseIcon
                        className={classes.tagIcon}
                        onClick={() => handleRemoveTag(index)}
                      />
                    </div>
                  </span>
                ))}

              {tempTags.length > 0 &&
                tempTags.map((tag, index) => (
                  <span className={classes.tagBubble} key={index}>
                    <div className={classes.inline}>
                      {tag.value ? tag.label : tag}{" "}
                      <CloseIcon
                        className={classes.tagIcon}
                        onClick={() => handleRemoveTempTag(index)}
                      />
                    </div>
                  </span>
                ))}

              {!addTag && (
                <div
                  className={clsx(classes.bubble, classes.tagButton)}
                  onClick={handleAddTag}
                >
                  {" "}
                  {
                    /*t('existingReservation.reservationTab.addTag')*/ "Add Tag"
                  }{" "}
                </div>
              )}
              {addTag && (
                <div className={clsx(classes.col, classes.onefield)}>
                  <Select
                    options={resvTags}
                    onChange={(e) => handleReservationTag(e)}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              )}
              {addTag && !newTag && (
                <div
                  className={clsx(classes.bubble, classes.tagButton)}
                  onClick={handleCreateTag}
                >
                  {" "}
                  {
                    /*t('existingReservation.reservationTab.createTag')*/ "Create Tag"
                  }{" "}
                </div>
              )}
              {addTag && newTag && (
                <div className={classes.onefield}>
                  <div className={classes.addSection}>
                    <div className={clsx(classes.field, classes.tagCol)}>
                      <InputBase
                        value={tag}
                        type="text"
                        id="resvTag"
                        onChange={(e) => handleEnterTag(e)}
                      />
                    </div>
                    <DoneIcon
                      className={classes.iconHolder}
                      onClick={handleSaveTag}
                    />
                    <CloseIcon
                      className={classes.iconHolder}
                      onClick={handleCancelCreate}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={clsx(classes.messageRow, classes.messageInfo)}>
            <div className={clsx(classes.message, classes.rightMargin)}>
              <ReportIcon className={classes.moreIcon} />
            </div>
            <div className={classes.message}>
              <span style={{ fontSize: 12, textAlign: "left" }}>
                Please note that when a reservation holds the above tags, the
                emails created via this template won't be sent for that
                reservation.
              </span>
            </div>
          </div>

          <Divider className={classes.divider} />

          <div className={classes.container}>
            <div className={classes.leftAlign}>Edit mode</div>
            <div className={classes.rightAlign}>
              <FormControlLabel
                control={
                  <Switch
                    id="isHtmlEmbed"
                    checked={isHtmlEmbed}
                    onChange={setHtmlEmbedStatus}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={isHtmlEmbed ? "Advanced" : "Simple"}
              />
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.emailBody}>
              {newTemplate && !isHtmlEmbed && (
                <TrixEditor
                  placeholder={t("automate.email.draft")}
                  submitOnEnter={false}
                  enableOnChange={true}
                  initialValue={fragment}
                  handleSlateOnChange={handleBodyUpdate}
                  plugins={["emailTags"]}
                />
              )}

              {isHtmlEmbed && (
                <EmailHtmlInput
                  initValue={fragment}
                  handleHtmlOnChange={handleBodyUpdate}
                />
              )}
            </div>
          </div>
          <div className={classes.submit}>
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              onClick={handleCancel}
            >
              {t("actions.cancel")}
            </Button>
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              onClick={handleSubmit}
            >
              {t("actions.save")}
            </Button>
          </div>
          {/*<TextField
            margin="dense"
            id="name"
            error={error.name}
            fullWidth
            label={t('automate.email.templateName')}
            type="text"
            onChange={handleOnChange}
            helperText={error.name && t('automate.email.templateNameError')}
            required
          />
          <div className={classes.line2}>
            <TextField
              margin="dense"
              id="subject"
              error={error.subject}
              fullWidth
              label={t('automate.email.emailSubject')}
              type="text"
              onChange={handleOnChange}
              helperText={error.subject && t('automate.email.emailSubjectError')}
              required
            />
            <div className={classes.buttonHolder}>
              <CloseIcon className={classes.button} onClick={handleCancelNewEmailTemplate} />
              <DoneIcon className={classes.button} onClick={handleSubmitNewEmailTemplate} />
            </div>
          </div>*/}
        </div>

        {/* --------------------------------------------------------- */}
        {/* LIST OF EMAIL TEMPLATES */}
        {/* --------------------------------------------------------- */}
        <div
          className={classes.listContainer}
          style={{ display: newTemplate === true ? "none" : "" }}
        >
          <div className={classes.listSection}>
            <EmailTemplateList handleClose={handleClose} />
          </div>
        </div>
        {errors.CREATE_TAG && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={3000}
            onClose={() => handleCloseError()}
            key="top-center"
            message="Tag already exists"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => handleCloseError()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  emailTemplates: state.emailTemplates,
  email: state.email,
  errors: state.errors,
});

export default withTranslation()(connect(mapStateToProps)(EmailTemplates));
