/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-relative-packages */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { connect } from "react-redux";
import { makeStyles, Snackbar, Typography, Dialog } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { UPSELL_ITEMS } from "../../utils/upsellItems";
import { resetError } from "../../redux/actions/reservation/editReservation";
import { returnCurrent } from "../../redux/selectors/dashboard";

import UpsellItem from "./UpsellItem";
import UpsellItemForm from "./UpsellItemForm";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)",
    },
  },
  listSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // minHeight: 'calc(100vh - 230px)'
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    alignItems: "center",
    borderRadius: 5,
    background: "#006699",
    color: "#FFFFFF",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    fontSize: "1.2rem",
    height: 25,
    marginTop: 10,
    marginBottom: 20,
    marginRight: 16,
    padding: "4px 8px",
  },
}));

const UpsellList = (props) => {
  const classes = useStyles();
  const { open, loading, list, iconList } = props;
  const [create, setCreate] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetError("UPDATE_UPSELL_ITEM"));
    props.dispatch(resetError("ADD_UPSELL_ITEM"));
    setSnackbarOpen(false);
  };

  const handleComplete = () => {
    setCreate(false);
  };

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        {/* ------------------------------------------------ */}
        {/* HEADER */}
        {/* ------------------------------------------------ */}
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>Add Ons</Typography>
          <AccessHeaderAction moduleID={MODULE.ADD_ONS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={props.onClose} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {/* ------------------------------------------------ */}
            {/* LOADING STATE - Upsell */}
            {/* ------------------------------------------------ */}
            {loading && (
              <div className={classes.blankSlate}>Loading add ons..</div>
            )}

            {/* ------------------------------------------------ */}
            {/* Create add-on */}
            {/* ------------------------------------------------ */}
            {!create && (
              <div
                className={classes.buttonContainer}
                onClick={() => setCreate(true)}
              >
                <div className={classes.button}>Create Add On</div>
              </div>
            )}

            {create && (
              <UpsellItemForm
                iconList={iconList}
                handleComplete={handleComplete}
              />
            )}

            {/* ------------------------------------------------ */}
            {/* Loop through upsell items */}
            {/* ------------------------------------------------ */}
            {!loading &&
              list.map((data, index) => (
                <UpsellItem
                  key={index}
                  item={data}
                  iconList={iconList}
                  index={data.iconID}
                />
              ))}

            {/* ------------- Snackbar error messages -------------- */}
            {props.errors && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => handleCloseError()}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{props.errors}</span>}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const currentSpace = returnCurrent(
    state.spaces,
    state.dashboard.currentSpace
  );
  const currentUpsell = currentSpace ? currentSpace.upsellItem || [] : [];

  const list = []; // List of upsell items with image
  const iconList = []; // List of icons currently in use

  if (currentUpsell.length) {
    const dataList = currentUpsell
      .map((id) => state.upsell[id])
      .filter((data) => data?.iconID)
      .reduce((r, a) => {
        r[a.iconID] = a;
        return r;
      }, Object.create(null));

    UPSELL_ITEMS.forEach((itemData) => {
      const item = { ...itemData };
      if (dataList[item.iconID]) {
        item.isActive = dataList[item.iconID].isActive;
        item.name = dataList[item.iconID].name;
        item.rate = dataList[item.iconID].rate;
        item._id = dataList[item.iconID]._id;
        list.push(item);
        iconList.push(item.iconID);
      }
    });
  }

  return {
    list,
    iconList,
    loading: state.loading.LIST_UPSELL_ITEMS,
    errors: state.errors.UPDATE_UPSELL_ITEM || state.errors.ADD_UPSELL_ITEM,
  };
};

export default connect(mapStateToProps)(UpsellList);
