import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import ProductCategoryForm from './ProductCategoryForm';
import { editProductCategory, createProductCategory, deleteProductCategory } from '../../../redux/actions/pos/productCategory';

import { List, ListItem, ListItemText, Dialog, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { resetError } from '../../../redux/actions/common/common';

const useStyles = makeStyles (theme => ({
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  addButton: {
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#666666',
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      left: '10px'
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  editIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    height: 15,
    padding: 8,
    opacity: 0.5,
    width: 15,
    '&:hover': {
      background: '#E0E0E0'
    }
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  listContainer: {
    height: 'calc(100vh - 186px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 110px)',
    },
  },
}))

const ProductCategoryList = (props) => {
  const classes = useStyles();

  const { open, handleClose, productCategories, products, loadingProductCategories, currentSpace, errors } = props;
  const [category, setCategory] = React.useState({id: '', active: false});

  const handleSubmit = (data) => {
    data = { ...data, propertyID: currentSpace.propertyID };
    if(category.id == ''){
      props.dispatch(createProductCategory(currentSpace._id, data));
    } else {
      props.dispatch(editProductCategory(category.id, data));
    }
    setCategory({id: '', active: false})
  }

  const handleDelete = () => {
    setCategory({id: '', active: false});
    props.dispatch(deleteProductCategory(category.id, currentSpace._id, productCategories[category.id]));
  }

  const handleCloseError = () =>{
    props.dispatch(resetError('CREATE_PRODUCT_CATEGORY'));
    props.dispatch(resetError('EDIT_PRODUCT_CATEGORY'));
    props.dispatch(resetError('DELETE_PRODUCT_CATEGORY'));
  }

  const handleCloseModal = () => {
    if(errors) handleCloseError();
    handleClose();
  }

  return(
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {!category.active && (
            <AddIcon className={classes.addButton} onClick={() => setCategory({id: '', active: true})} />
          )}

          {category.active && <BackIcon className={classes.addButton} onClick={() => setCategory({id: '', active: false})} />}

          <div className={classes.header}>Product Categories</div>

          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            <List className={classes.root} style={{ display: category.active ? "none": ""}}>
              
              {/* LIST ALL PRODUCT CATEGORIES */}
              {/* --------------------------------------------- */}
              {!loadingProductCategories && currentSpace.productCategories && 
                currentSpace.productCategories
                .filter(item => productCategories[item] && productCategories[item].isDeleted !== true)
                .map(category => (
                  <ListItem button key={category} onClick={() => setCategory({id: category, active: true})}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <div className={classes.primaryHolder}>
                            {productCategories[category].name}
                          </div>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <span className={classes.secondaryHolder}>
                            {productCategories[category].products && 
                            productCategories[category].products.filter(item => products[item] && products[item].isDeleted!==true).length || 0} products
                          </span>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
              ))}

            </List> 

            {category.active && 
              <ProductCategoryForm
                open={category.active}
                categoryID={category.id}
                data={category.id==='' ? {} : productCategories[category.id]}
                handleCancel={() => setCategory({id: category, active: false})}
                handleSubmit={handleSubmit}
                handleConfirmDelete={handleDelete}
              />
            }
          </div>

        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            onClose={handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}

        {props.loading &&
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        }

      </div>
    </Dialog>

  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  productCategories: state.productCategories,
  products: state.products,
  loadingProductCategories: state.loading.LOAD_PRODUCT_CATEGORY,
  errors: state.errors.CREATE_PRODUCT_CATEGORY || 
          state.errors.EDIT_PRODUCT_CATEGORY ||
          state.errors.DELETE_PRODUCT_CATEGORY,
  loading: state.loading.CREATE_PRODUCT_CATEGORY || 
          state.loading.EDIT_PRODUCT_CATEGORY ||
          state.loading.DELETE_PRODUCT_CATEGORY,
})

export default connect(mapStateToProps)(ProductCategoryList);