import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { MENU } from '../utils/layout';

import TodosPage from '../components/teams/TodosPage';
import FilesPage from '../components/teams/FilesPage';
import NoticeboardPage from '../components/teams/NoticeboardPage';
import MembersPage from '../components/teams/MembersPage';
import SidebarChat from '../components/chat/SidebarChat';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '5px',
    margin: '20px 10px',
    marginRight: '20px',
    minHeight: 'calc(100vh - 110px)',
    position: 'relative',
    [theme.breakpoints.down('sm')]:{
      margin: 'unset',
      minHeight: 'calc(100vh - 170px)',
    },
  },
  moduleContainer: {
    display: 'block',
    height: '100%',
    [theme.breakpoints.down('sm')]:{
      margin: 'unset',
    }
  },
  moduleSection:{
    height: '100%',
    padding: '0px 20px',
  }
}));

const TeamsContainer = (props) => {

  const classes = useStyles();
  const { currentSubModule, currentModule } = props;
  const subMenuList = MENU[currentModule].submenu;
  const activeSubModule = currentSubModule || MENU[currentModule].defaultSubmenu;
  
  return (
    <div className={classes.root}>

      {/* -----------------Noticeboard------------------- */}
      {(activeSubModule == subMenuList.noticeboard.id) && 
        <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <NoticeboardPage />
          </div>
        </div>
      }

      {/* -----------------Group Chat------------------- */}
      {(activeSubModule == subMenuList.chat.id) && 
        <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <SidebarChat />
          </div>
        </div>
      }
      
      {/* -----------------Todos------------------- */}
      {(activeSubModule == subMenuList.todos.id) && 
        <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <TodosPage />
          </div>
        </div>
      }


      {/* -----------------Files------------------- */}
      {(activeSubModule == subMenuList.files.id) && 
        <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <FilesPage />
          </div>
        </div>
      }

      {/* -----------------Members------------------- */}
      {(activeSubModule == subMenuList.members.id) &&
        <div className={classes.moduleContainer}>
          <div className={classes.moduleSection}>
            <MembersPage />
          </div>
        </div>
      }

    </div>
  )
}

const mapStateToProps = state =>{
  const { dashboard } = state;
  return{
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule
  }
}

export default connect(mapStateToProps)(TeamsContainer);