export default (state = {}, action) =>{
    switch(action.type){
        case 'FETCH_SUBSCRIPTION_PLANS_SUCCESS':
            return{
                ...state,
                subscriptionPlans: { ...action.payload.subscriptionPlans },
                bedCount: action.payload.bedCount,
                couponData: null
            }

        case 'SET_SELECTED_PLAN_ID':
            return{
                ...state,
                selectedPlanID: action.payload
            }

        case 'SET_SELECTED_PRICE_ID':
            return{
                ...state,
                selectedPriceID: action.payload
            }

        case 'DISCOUNT_COUPON_DETAILS_SUCCESS':
            return{
                ...state,
                couponData: { ...action.payload }
            }

        case 'REMOVE_COUPON':
            return{
                ...state,
                couponData: null
            }

        default: 
            return { ...state }
    }
}