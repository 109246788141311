import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { MENU } from '../../utils/layout';

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
  drawer: {
  },
  toolbar: {
    height: 60
  },
  drawerPaper: {
    width: drawerWidth,
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 10,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontWeight: 600
  },
  primaryText:{
    '& .MuiListItemText-primary':{
      fontSize: '1.5rem'
    }
  },
  moreIcon:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const MenuItem = ({
  icon = null,
  iconPos = 'left',
  title = '',
  onClickHandler = null
}) =>{
  const classes = useStyles();
  return(
    <ListItem button onClick={onClickHandler}>
      {icon && iconPos == 'left' && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={title} className = {classes.primaryText} />
      {icon && iconPos == 'right' && (
        <ListItemIcon 
          className={classes.moreIcon} 
        >
          {icon}
        </ListItemIcon>)}
    </ListItem>
  );
};

function SidebarMobile(props) {
  const { setMobileMenuModule, t } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [selectedMenu, setSelectedMenu] = React.useState("")

  const handleDrawerToggle = () => {
    props.handleDrawerToggle();
    setSelectedMenu("");
  };

  const menuSelectHandler = (moduleID, subMenuID = null) => {
    if(subMenuID){
      setMobileMenuModule(moduleID, subMenuID);
      handleDrawerToggle();
      return;
    }

    if(MENU[moduleID].hasSubMenu){
      selectedMenu == moduleID ? setSelectedMenu("") : setSelectedMenu(moduleID);
      return;
    }

    setMobileMenuModule(moduleID);
    handleDrawerToggle();
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <CloseIcon className={classes.closeIcon} onClick={handleDrawerToggle} />
      </div>

      <List>
        {Object.keys(MENU).map((moduleID) => (
          <List key={moduleID}>

            <MenuItem
              icon = {
                MENU[moduleID].hasSubMenu && 
                (selectedMenu === moduleID ? 
                <ExpandLess/> : <ExpandMore/>
              )}
              iconPos = 'right'
              title = {t(`subMenu.${MENU[moduleID].id}`)}
              onClickHandler = {() => menuSelectHandler(moduleID)}
            />

            {MENU[moduleID].hasSubMenu && (
              <Collapse in={selectedMenu == moduleID} timeout="auto" unmountOnExit>
                {Object.keys(MENU[moduleID].submenu).map((submenuID, subIndex) => (
                  <List 
                    key={submenuID}
                    component="div" 
                    disablePadding 
                    onClick={() => menuSelectHandler(moduleID, submenuID)}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemText 
                        primary={t(`subMenu.${MENU[moduleID].submenu[submenuID].id}`)}
                        secondary={submenuID == 'channels' ? 'BETA' : ''} 
                      />
                    </ListItem>
                  </List>))}
              </Collapse>
            )}

          </List>
        ))}
      </List>

      <Divider />

      <List>
        <MenuItem
          title = {t(`navbar.community`)}
          onClickHandler = {() => {
            const url = 'https://community.counter.app';
            window.open(url, '_blank');
          }}
        />
      </List>

    </div>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileSidebarOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

SidebarMobile.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withTranslation()(SidebarMobile);
