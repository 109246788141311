import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';

import { returnCurrent } from '../../../redux/selectors/dashboard';
import { Grid, Tooltip } from '@material-ui/core';
import IMAGES from '../../../constants/images';
import { getRatePlanName } from '../helper';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 40,
    [theme.breakpoints.down('xs')]: {
      minHeight: 70,
    },
  },
  row: {
    display: 'flex'
  },
  rateItem: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  lineName: {
    paddingLeft: 10,
    alignItems: 'flex-start'
  },
  lineItem: {
    alignItems: 'center',
    color: '#999999',
    display: 'flex',
    fontSize: '1.2rem',
    height: 30,
  },
  editIcon: {
    cursor: 'pointer',
    width: 15,
    height: 15,
    marginLeft: 10,
    zIndex: 1
  },
  greenDot: {
    backgroundColor: '#4F9581',
    borderRadius: '50%',
    // cursor: 'pointer',
    minHeight: 9,
    margin: 5,
    padding: 'none',
    minWidth: 9
  },
  redDot: {
    backgroundColor: 'red',
    borderRadius: '50%',
    cursor: 'pointer',
    minHeight: 9,
    margin: 5,
    padding: 'none',
    minWidth: 9
  },
  tooltipText: {
    fontSize: '1rem'
  },
  infoIcon: {
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginRight: 10,
    opacity: 0.5
  }
}))

const RatePlanRow = (props) => {
  const { range, t } = props
  const classes = useStyles();
  const { ratePlan, roomType, ratePlans, numberOfDays } = props;
  const ratePlanName = getRatePlanName(ratePlans?.[ratePlan?.id]);
  const isRatePlanActive = ratePlans?.[ratePlan?.id]?.active;
  const isRatePlanDefault = ratePlans?.[ratePlan?.id]?.default;
  const width = (100 / numberOfDays);
  let rangeList = range.slice(0, numberOfDays)

  const processRowPrice = (price) => {
    if(price === undefined || price === null || price === '-') 
      return '-';
    if(roomType?.basictype?.includes('Private'))
      return Number((parseFloat(price) * parseInt(roomType.beds)).toFixed(2))
    return price;
  }

  return (
    <Grid
      container
      className={clsx({ [classes.container]: true })}
      alignItems="center"
    >

      {/* ROW NAME */}
      {/* ---------------------------------------------------------------- */}
      <Grid item xs={4} sm={2}>
        <div className={clsx(classes.rateItem, classes.lineName)}>
          <div
            className={clsx({ [classes.lineItem]: true })}
          >
            <Tooltip placement="bottom-start" className={classes.icon} title={
              !isRatePlanActive ? <div className={classes.tooltipText}><div> Inactive rate plan</div></div> : ''
            }>
              <span className={isRatePlanActive ? classes.greenDot : classes.redDot}></span>
            </Tooltip>
            <span>{ratePlanName}</span> {/* {!isRatePlanDefault && */}
              <EditIcon className={classes.editIcon} onClick={() => props.updateRatePlanHandler(ratePlan?.id)} />
            {/* } */}
          </div>
        </div>
      </Grid>

      <Grid item xs={8} sm={10} className={classes.row}>
        {rangeList.map(date => {
          let rateItem = ratePlan?.rates?.find(data => data.date === date) || {};
          return (
            <div className={classes.column} key={date} style={{ width: `${width}%` }}>
              <Tooltip title={rateItem.closed ? "Stop Sell" : ""}>
                <div
                  className={classes.rateItem}
                  style={{
                    background: rateItem.closed ? '#E4ACA9' : 'transparent',
                  }}
                >

                  {/* ----------------------------------------------------------------------------- */}
                  {/* Rate */}
                  {/* ----------------------------------------------------------------------------- */}
                  <div
                    className={clsx({ [classes.lineItem]: true })}
                    style={{
                      color: rateItem.closed ? '#CC6666' : '#999999',
                      fontWeight: rateItem.closed ? 600 : 500,
                    }}
                  >
                    {processRowPrice(rateItem.price || '-')}
                  </div>
                </div>
              </Tooltip>
            </div>
          )
        })}
      </Grid>

    </Grid>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace)
})

export default withTranslation()(connect(mapStateToProps)(RatePlanRow));