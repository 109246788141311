import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import NumberInput from '../../../common/NumberInput';

import Footer from '../Footer';

const useStyles = makeStyles(theme =>({
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px',
        width: 'fit-content',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
    },
    infoText: {
        margin: '20px 0px',
        fontSize: '1.2rem',
        fontWeight: 600
    },
    currency: {
        fontSize: '1.2rem',
        fontWeight: 600,
        margin: '0px 10px'
    }
}));

const ActivityPricing = props =>{
    const classes = useStyles();
    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo,
        submitDraftHandler,
        productData ,
        currency 
    } = props;

    const [ price, setPrice ] = useState(0);

    useEffect(() => {
        if(productData.price || productData.price === 0) setPrice(productData.price);
    }, [ productData.price ]);

    const onChangeHandler = event =>{
        const value = event.target.value;
        setPrice(value);
    }

    const submitHandler = () =>{
        const data = { price, currency };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Guest pricing</div>
                
                <div className="sub-title">
                    How much to charge is entirely upto you. Enter the price you want each guest to pay and discover what you can earn.
                </div>

                <div className={classes.infoText}>Individual rate per guest</div>

                <div className={classes.inputField}>
                    <div className={classes.currency}>{currency}</div>
                    
                    {/* <InputBase 
                        id="price"
                        name="price"
                        className={classes.textInput}
                        // fullWidth
                        type="number"
                        value={price}
                        // error={true}
                        onChange={onChangeHandler}
                    /> */}

                    <NumberInput
                        id="price"
                        name="price"
                        className={classes.field}
                        className={classes.textInput}
                        value={price || 0}
                        min={0}
                        onChange={onChangeHandler}
                    />
                </div>

            </div>

            <Footer 
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
            />
        </>
    )
}

export default ActivityPricing; 