import React from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CHANNEL } from '../../utils/channels';
import MyAllocator from './MyAllocator';

import { Snackbar, makeStyles, Dialog, Button } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import { setupChannel } from '../../redux/actions/channelMapping/channelMapping';
import { resetSuccess } from '../../redux/actions/reservation/editReservation';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialog: {
    overflowY: 'scroll'
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backButton: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      left: '10px'
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  listContainer: {
    height: 'calc(100vh - 186px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 110px)',
    }
  },
  listSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    marginBottom: 10,
    marginTop: 10,
  },
  message: {
    fontSize: '1.4rem',
    color: '#666666',
    lineHeight: 1.5,
    marginBottom: 10,
    marginTop: 10,
  },
  textInputContainer: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'flex-end'
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px 0 0 10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 33,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%',
    border: '1px solid #E0E0DE',
  },
  fieldError: {
    border: '1px solid red',
  },
  Button: {
    boxShadow: '0 2px 20px #E0E0E0',
    height: '35px',
    border: '1px solid #E0E0E0',
    borderRadius: '0 20px 20px 0',
  }
}))

const ChannelSetup = props => {
  const { t } = props
  const classes = useStyles();
  const { currentSpace, automation, loading } = props;
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if(props.success) setValue('')
  }, [props.success])

  const handleSetUp = () => {
    if (value) {
      let payload = {
        HW_Property_ID: value
      }
      props.dispatch(setupChannel(currentSpace._id, props.type, { ...payload }))
    } else {
      setError(true)
    }
  }

  const handleClose = () => {
    props.dispatch(resetSuccess('SETUP_CHANNEL'));
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth={true}
      classes={{ paperFullWidth: classes.dialogPaper }}
      fullScreen={window.innerWidth < 901}
    >

      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <BackIcon className={classes.backButton} onClick={props.handleClose} />
          <div className={classes.header}>{CHANNEL[props.type] ? CHANNEL[props.type].name : 'Channel'} Setup</div>
          <CloseIcon className={classes.closeIcon} onClick={props.handleCloseAll} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {props.type === CHANNEL.Hostelworld.code &&
              <div className={classes.row}>
                <div className={classes.title}>
                  {t('beds.channelsSnapshot.hostelworld.header')}
                  </div>
                <div className={classes.label}>
                  {t('beds.channelsSnapshot.hostelworld.message')}
                  </div>
                <div className={classes.textInputContainer}>
                  <div className={clsx(classes.field, { [classes.fieldError]: error })}>
                    <InputBase
                      id="hostelworldPropertyID"
                      name="hostelworldPropertyID"
                      fullWidth
                      type="text"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                  <Button variant="outlined" className={classes.Button} onClick={handleSetUp}>Add</Button>
                </div>
              </div>
            }

            {props.type === CHANNEL.myAllocator.code &&
              <MyAllocator currentSpace={currentSpace} mappedChannel={false} />
            }

            {automation === 'no' &&
              <div className={classes.row}>
                <div className={classes.message}>
                  {t('beds.channelsSnapshot.noAutomation.partOne')} {props.type} {t('beds.channelsSnapshot.noAutomation.partTwo')} <a href="mailto:support@counter.app">support@counter.app</a> {t('beds.channelsSnapshot.noAutomation.partThree')}.
                </div>
              </div>
            }

            {automation === 'wip' &&
              <div className={classes.row}>
                <div className={classes.message}>
                  {t('beds.channelsSnapshot.wipAutomation.partOne')} {props.type} {t('beds.channelsSnapshot.wipAutomation.partTwo')} <a href="mailto:support@counter.app">support@counter.app</a> {t('beds.channelsSnapshot.wipAutomation.partThree')}
                  </div>
              </div>
            }

          </div>
        </div>

      </div>

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={() => handleClose()}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
      {/* ------------- Snackbar error messages -------------- */}
      {props.success && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={() => handleClose()}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Setup request has been sent successfully</span>}
        />
      )}

    </Dialog>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  errors: state.errors.SETUP_CHANNEL,
  success: state.success.SETUP_CHANNEL
})

export default withTranslation()(connect(mapStateToProps)(ChannelSetup));