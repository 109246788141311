import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import SnapshotTile from "../common/SnapshotTile";
import Rates from "../rates/Rates";
import ChannelList from "../channels/ChannelList";
import Taxes from "../taxes/Taxes";
import UpsellList from "../upsell/UpsellList";
import RoomTypeList from "../rooms/room_types/RoomTypeList";
import AvailabilityLogs from "../availabilityLogs/AvailabilityLogs";
import RoomList from "../rooms/RoomList";

import { Grid } from "@material-ui/core";
import ChannelLog from "../channelLog";

const styles = (theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    paddingTop: 20,
    paddingBottom: 40,
    marginBottom: 40,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 40,
      marginTop: 20,
    },
  },
});

const BedsPage = (props) => {
  const { classes, t } = props;
  const [feature, setFeature] = React.useState("");

  // URL LOGIC TO OPEN MODAL
  // ---------------------------------------------------------------------
  React.useEffect(() => {
    let pathname = window?.location?.pathname;
    let segments = pathname.split("/");
    const featureList = [
      "roomtypes",
      "addons",
      "taxes",
      "channels",
      "rates",
      "ari-logs",
      "availability-logs",
    ];
    const index = featureList.indexOf(segments[3]);
    if (index > -1) {
      setFeature(segments[3]);
      window.Intercom &&
        window.Intercom("update", { hide_default_launcher: true });
    }
  }, []);

  // OPEN FEATURE
  // ----------------------------------------------------------------------
  const handleOpen = (feature) => {
    setFeature(feature);
    window.history.replaceState({}, document.title, `/beds/manage/${feature}`);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  // CLOSE FEATURE
  // ----------------------------------------------------------------------
  const handleClose = () => {
    setFeature("");
    window.history.replaceState({}, document.title, `/beds/manage`);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* ----------------------------------------------------------------- */}
        {/* RATES AND AVAILABILITY */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.RATES_AND_AVAILABILITY.ID}>
          <Grid item xs={12} sm={4} lg={3}>
            <div
              className={classes.container}
              data-intercom-target={"Rates and Availability"}
            >
              <SnapshotTile
                name={t("beds.ratesSnapshot.name")}
                handleOpen={() => handleOpen("rates")}
              />
            </div>
          </Grid>

          {feature == "rates" && (
            <Rates open={feature == "rates"} handleClose={handleClose} />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/* TAXES */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.TAXES.ID}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={classes.container} data-intercom-target={"Taxes"}>
              <SnapshotTile
                name="Taxes"
                handleOpen={() => handleOpen("taxes")}
              />
            </div>
          </Grid>

          {feature == "taxes" && (
            <Taxes
              open={feature == "taxes"}
              handleCloseTaxModal={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           ADD ONS                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.ADD_ONS.ID}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={classes.container} data-intercom-target={"Add Ons"}>
              <SnapshotTile
                name={t("beds.addOnsSnapshot.name")}
                handleOpen={() => handleOpen("addons")}
              />
            </div>
          </Grid>

          {feature == "addons" && (
            <UpsellList open={feature == "addons"} onClose={handleClose} />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           ROOM TYPES                              */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.ROOMS.ID}>
          <Grid item xs={12} sm={4} lg={3}>
            <div
              className={classes.container}
              data-intercom-target={"Room Types"}
            >
              <SnapshotTile
                name="Room Types"
                handleOpen={() => handleOpen("roomtypes")}
              />
            </div>
          </Grid>

          {feature == "roomtypes" && (
            <RoomTypeList
              open={feature == "roomtypes"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                          CHANNEL LOGS                             */}
        {/* ----------------------------------------------------------------- */}
        {/* <AccessController requiredPermission={MODULE.ROOMS.ID}> */}
        {props.isSystemAdmin && (
          <>
            <Grid item xs={12} sm={4} lg={3}>
              <div
                className={classes.container}
                data-intercom-target={"Channel ARI Logs"}
              >
                <SnapshotTile
                  name="Channel ARI Logs"
                  handleOpen={() => handleOpen("ari-logs")}
                />
              </div>
            </Grid>

            {feature == "ari-logs" && (
              <ChannelLog
                open={feature == "ari-logs"}
                handleClose={handleClose}
              />
            )}
          </>
        )}

        {/* ----------------------------------------------------------------- */}
        {/* AVAILABILITY LOGS */}
        {/* ----------------------------------------------------------------- */}
        {props.isSystemAdmin && (
          <>
            <Grid item xs={12} sm={4} lg={3}>
              <div
                className={classes.container}
                data-intercom-target={"Availability Logs"}
              >
                <SnapshotTile
                  name={t("beds.availabilityLogs.name")}
                  handleOpen={() => handleOpen("availability-logs")}
                />
              </div>
            </Grid>
            {feature === "availability-logs" && (
              <AvailabilityLogs
                open={feature === "availability-logs"}
                handleClose={handleClose}
                isSystemAdmin={props.isSystemAdmin}
              />
            )}
          </>
        )}

        {/* </AccessController> */}

        {/* ----------------------------------------------------------------- */}
        {/* ROOMS */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.ROOMS.ID}>
          <Grid item xs={12} sm={12} xl={12}>
            <div
              className={classes.container}
              data-intercom-target={"Room Inventory"}
            >
              <RoomList />
            </div>
          </Grid>
        </AccessController>
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(BedsPage));
