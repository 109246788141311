import React, { useState, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { SingleDatePicker } from 'react-dates';

import { BOOKING_SOURCE } from '../../../utils/constants';
import { fetchDateFormat, fetchDatePerTimezone, DATE_FORMATS, fetchNoOfNights, getCurrency } from '../../../utils/utility';

import PlusMinus from '../../common/PlusMinus';
// import { ICONS } from '../../../utils/imageUrls';
import { toTitleCase } from '../../utils/wordUtils';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 6
  },
  section: {

  },
  sectionTitle: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  blocks: {

  },
  blockItems: {
    cursor: 'pointer',
    display: 'flex',
    padding: 15,
    fontSize: '1.3rem',
    background: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  blockIcon: {
    height: 20,
    width: 20,
  },
  blockLabel: {
    marginTop: 20
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 20
  },
  button: {
    background: '#fff',
    border: 'none',
    borderRadius: 5,
    minHeight: 50,
    padding: 0,
    minWidth: 50,
    width: 'calc(100% - 10px)',
    marginRight: 10,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0
    },
  },
  selected: {
    background: '#E0E0E0',
  },
  radioContainer: {
    color: '#666666',
    marginBottom: 10,
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    '& span': {
      fontSize: '1.3rem',
      padding: 0,
      paddingRight: 5
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
  new: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  bookingSource: {
    width: '50%'
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  roundbutton: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  centerAlign: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    padding: '10px 0'
  },
  disabled: {
    backgroundColor: '#eee',
    color: '#fff',
    cursor: 'default',
    '& img': {
      filter: 'invert(100%)'
    }
  }
}));

const sourceList = [
  { value: BOOKING_SOURCE.PHONE, label: BOOKING_SOURCE.PHONE, img: IMAGES.ICONS.darkCall },
  { value: BOOKING_SOURCE.EMAIL, label: BOOKING_SOURCE.EMAIL, img: IMAGES.ICONS.darkEmail },
  { value: BOOKING_SOURCE.WALKIN, label: BOOKING_SOURCE.WALKIN, img: IMAGES.ICONS.darkFootprint },
  { value: BOOKING_SOURCE.OTHER, label: BOOKING_SOURCE.OTHER, img: IMAGES.ICONS.darkMore },
]

const BasicDetails = forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, prefilledData, productDetails, productInfo, conductorInfo, bookingSource } = props;
  const [count, setCount] = useState(null);
  const [focused, setFocused] = useState(null);
  const [stay, setStay] = useState(null);
  const [source, setSource] = useState(null);
  const [baseSource, setBaseSource] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const conductorData = conductorInfo?.[productDetails?.conductorID] || {};

  const productData =  productInfo?.[productDetails?.productID] || {};



  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return {
        propertyID: currentSpace.propertyID,
        bookingSource: source,
        guestCount: count,
        stay: stay
      }
    }
  }))

  React.useEffect(() => {
    if (prefilledData.guestCount) setCount(prefilledData.guestCount)
    if (prefilledData.stay) setStay(prefilledData.stay)
    if (prefilledData.bookingSource) setSource(prefilledData.bookingSource)
  }, [prefilledData])

  const onCountChange = (newCount) => {
    setCount(newCount);
  }

  const handleSetSource = (value) => {
    if (value === BOOKING_SOURCE.OTHER) {
      setBaseSource(true);
      setSource(null);
    }
    else {
      setBaseSource(false);
      setSource(value);
    }
  }

  const handleNewBookingSource = () => {
    props.createBookingSource(source);
    setIsNew(false);
  }

  return (
    <div className={classes.container} ref={ref}>

      <div className={classes.section}>
        <Grid container>
          <Grid item xs={6} className={classes.details}><span>{productDetails.atProperty ? "In-house" : "External"} experience</span></Grid>
          <Grid item xs={6} className={classes.details}><span>Meeting point: {productDetails.location}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>{fetchDateFormat(productDetails.date, currentSpace.dateFormat)}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>Teacher/Host: {toTitleCase(conductorData.type)} {toTitleCase(conductorData.name)}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>{productDetails.duration}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>Minimum group size: {productData.minSize}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>{getCurrency(productDetails.currencyData, productDetails.price)}</span></Grid>
          <Grid item xs={6} className={classes.details}><span>Maximum group size: {productData.maxSize}</span></Grid>
          {/* <Grid item xs={6} className={classes.details}><span>Terms & conditions:</span></Grid>
          <Grid item xs={6} className={classes.details}><span>What to bring:</span></Grid> */}
        </Grid>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <span className={classes.title}>How many people are joining?</span>
        </div>
        <div className={classes.blocks}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <PlusMinus onCountChange={onCountChange} maximum={productDetails.available} prefilledCount={count} fullWidth={true} />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <span className={classes.title}>Select booking source</span>
        </div>
        <Grid container>
          {sourceList.map((data, sourceIndex) => (
            <Grid item md={2} sm={3} xs={6} key={sourceIndex}>
              <div
                className={clsx({
                  [classes.blockItems]: true,
                  [classes.selected]: (source === data.value || (baseSource && data.value === BOOKING_SOURCE.OTHER))
                })}
                onClick={() => handleSetSource(data.value)}>
                <img className={classes.blockIcon} src={data.img} />
                <span className={classes.blockLabel}>{data.label}</span>
              </div>
            </Grid>
          ))}
        </Grid>
        {(baseSource) &&
          <div>
            <RadioGroup
              aria-label="source"
              className={classes.radioContainer}
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              {bookingSource.map((value, index) =>
                <FormControlLabel
                  key={index}
                  className={classes.radio}
                  value={value}
                  control={<Radio color="primary" disableRipple />}
                  label={value}
                />
              )}
            </RadioGroup>
            {/* --------------------------------------------------------------------- */}
            {/* ADD NEW BOOKING SOURCE */}
            {/* --------------------------------------------------------------------- */}
            {!isNew && <div className={classes.link} onClick={() => setIsNew(true)}>Add new booking source</div>}
            {isNew &&
              <div className={classes.new}>
                <TextField
                  id="source"
                  margin="none"
                  label="New Booking source"
                  type="text"
                  onChange={(e) => setSource(e.target.value)}
                  className={classes.bookingSource}
                  InputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {/* --------------------------------------------------------------------- */}
                {/* SUBMIT / CANCEL NEW BOOKING SOURCE */}
                {/* --------------------------------------------------------------------- */}
                <div className={classes.buttonHolder}>
                  <CloseIcon className={classes.roundbutton} onClick={() => setIsNew(false)} />
                  <DoneIcon className={classes.roundbutton} onClick={handleNewBookingSource} />
                </div>
              </div>
            }
          </div>
        }
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <span className={classes.title}>Where are they staying?</span>
        </div>
        <Grid container>
          <Grid item md={2} sm={3} xs={6}>
            <div
              className={clsx({
                [classes.blockItems]: true,
                [classes.selected]: stay === 'inhouse'
              })}
              onClick={() => setStay('inhouse')}>
              <img className={classes.blockIcon} src={IMAGES.ICONS.home} />
              <span className={classes.blockLabel}>In house</span>
            </div>
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <div
              className={clsx({
                [classes.blockItems]: true,
                [classes.disabled]: !productData.externalGuestsAllowed,
                [classes.selected]: stay === 'external'
              })}
              onClick={() => productData.externalGuestsAllowed && setStay('external')}>
              <img className={classes.blockIcon} src={IMAGES.ICONS.undo} />
              <span className={classes.blockLabel}>External</span>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
})
export default (BasicDetails);