import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles (theme => ({
  card: {
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    height: 250,
    marginTop: 0,
    overflowY: "hidden"
  },
  cardAction: {
    alignItems: 'flex-start',
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1)
  },
  cardContent: {
    paddingTop: 4,
    paddingBottom: 4,
    width: 'calc(100% - 32px)',
  },
  categoryContainer: {
    marginBottom: 15
  },
  fileCategory: {
    marginTop: 10,
    marginBottom: 20,
    fontWeight: 600
  },
  fileName: {
    color: '#999999',
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: 5,
    textOverflow: 'ellipsis',
  },
  imgHolder: {
    '& img': {
      color: "#dddddd",
      height: 70,
      width: 70,
    }
  },
}))

const FolderSnapshot = (props) => {

  const classes = useStyles();
  const {folderID, files, folders, folderName} = props;

  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardAction} onClick={props.handleOpen}>
        <CardContent className={classes.cardContent}>
            <div className={classes.categoryContainer}>
              
              <Typography className={classes.fileCategory} gutterBottom variant="h6" component="h2">
                {folderName}
              </Typography>

              <Grid container spacing={1}>
                {folderID && folders && folders[folderID] && folders[folderID].files && folders[folderID].files.slice(0,6).map(file => 
                  <Grid item xs={4} key={file} align="center">
                    {files[file] && <div className={classes.imgHolder}>
                      {files[file].type === 'image' && <img src="/images/icons/image-icon.png"/>}
                      {files[file].type === 'document' && <img src="/images/icons/document-icon.png"/>}
                      {files[file].type === 'spreadsheet' && <img src="/images/icons/spreadsheet-icon.png"/>}
                      {files[file].type === 'pdf' && <img src="/images/icons/pdf-icon.png"/>}
                      {files[file].type === 'other' && <img src="/images/icons/document-icon.png"/>}
                    </div>}
                  </Grid>
                )}
              </Grid>
              
            </div>  
        </CardContent>
      </CardActionArea>

    </Card>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  folders: state.folders,
  files: state.files,

})

export default connect(mapStateToProps)(FolderSnapshot);