import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import {
  guestProcessing,
  mapProcessing,
  guestCount,
} from "../../redux/selectors/reports/guestList";
import {
  loadColombiaReport,
  resetColombiaReport,
} from "../../redux/actions/reports/colombia";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import Reservation from "../reservations/Reservation";

import ArrowIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { exportExcel } from "../utils/excelExport";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    // minHeight: 'calc(100vh - 230px)',
    // margin: theme.spacing(3),
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  graph: {
    height: 400,
    marginBottom: 50,
  },
  dateHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
  },
  fieldContainer: {
    border: "none",
    borderRadius: 5,
    boxShadow: "0 1px 10px 0 #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    borderRadius: 5,
    "& .SingleDatePicker_picker": {
      borderRadius: 5,
      top: "45px !important",
      zIndex: 1009,
    },
    "& .DateInput_fang": {
      zIndex: 1010,
    },
    "& .DateInput": {
      borderRadius: 5,
      width: 120,
    },
    "& .DateInput_input": {
      borderRadius: 5,
      width: "calc(100% - 22px)",
    },
    "& .SingleDatePickerInput": {
      boxShadow: "0 1px 10px #E0E0E0",
      border: "none",
      borderRadius: 5,
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  blankState: {
    alignItems: "center",
    display: "flex",
    height: 50,
    justifyContent: "center",
    width: "100%",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const BasicExport = (props) => {
  const { t } = props;
  const rowCount = props.data.length;
  const tableTitle = "Migraction List";
  const displayDate = props.date;
  const filteredData = props.data.filter((e) => e.nationalityCode !== 169);

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: "Codigo del Hotel",
          field: "hotelCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Codigo de Ciudad",
          field: "cityCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Tipo de Documento",
          field: "documentType",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Numero de Identificacion",
          field: "documentNumber",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Codigo Nacionalidad",
          field: "nationalityCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Primer Apellido",
          field: "lastName",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Segundo Apellido",
          field: "secondLastName",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Nombre del Extranjero",
          field: "firstName",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Tipo de Moviento",
          field: "typeOfMovement",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Fecha del Movimiento",
          field: "dateOfMovement",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Lugar de Procedencia",
          field: "previousDestination",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Lugar de Destino",
          field: "nextDestination",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Fecha de Nacimiento",
          field: "dateOfBirth",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={filteredData}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        pageSizeOptions: rowCount > 20 ? [5, 10, 20, rowCount] : [5, 10, 20],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(filename, headerConfig, tableData);
        },
      }}
      onRowClick={(event, rowData) =>
        props.handleTableRowClick(rowData.reservationID)
      }
    />
  );
};

const Colombia = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "today", label: t("reports.presetRange.today") },
    { value: "yesterday", label: t("reports.presetRange.yesterday") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const { open, handleClose, currentSpace, migrationList, loadingColombia } =
    props;
  const [openReservation, setOpenReservation] = useState(false);
  const [date, setDate] = useState(moment());
  const [submittedDate, setSubmittedDate] = useState(moment());
  const [focused, setFocused] = useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[0]);

  // const tableData = useSelector(state => migrationProcessing(state.reports.guestList));
  // const summary = useSelector(state => guestCount(state.reports.guestList, state.spaces[state.dashboard.currentSpace]))

  useEffect(() => {
    props.dispatch(
      loadColombiaReport(currentSpace.propertyID, date.format("YYYY-MM-DD"))
    );
    return function cleanup() {
      props.dispatch(resetColombiaReport());
    };
  }, []);

  const setNewDate = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportDate = moment();
    if (e.value == "custom") {
      return;
    } else if (e.value === "today") {
      reportDate = moment();
    } else if (e.value === "yesterday") {
      reportDate = moment().subtract(1, "day");
    }
    props.dispatch(
      loadColombiaReport(
        currentSpace.propertyID,
        reportDate.format("YYYY-MM-DD")
      )
    );
    setDate(reportDate);
    setSubmittedDate(reportDate);
  };

  const handleSubmit = (newDate) => {
    setDate(newDate);
    props.dispatch(
      loadColombiaReport(currentSpace.propertyID, newDate.format("YYYY-MM-DD"))
    );
    setSubmittedDate(newDate);
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{`Colombia Migracion ${t(
            "reports.report"
          )}`}</div>
          <AccessHeaderAction moduleID={MODULE.GUESTS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={classes.dateHeader}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} lg={4}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDate}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item className={classes.dateHolder}>
                  <SingleDatePicker
                    date={date}
                    onDateChange={(date) => {
                      handleSubmit(date);
                      setPresetValue(presetRanges[2]);
                    }}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    isOutsideRange={() => false}
                    id="migracion_date_id"
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel={true}
                    displayFormat={"DD MMM YYYY"}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.listSection}>
            {!loadingColombia && migrationList.length !== 0 && (
              <BasicExport
                t={t}
                data={migrationList}
                date={submittedDate.format("DD MMM YYYY")}
                handleTableRowClick={handleTableRowClick}
              />
            )}

            {loadingColombia && (
              <div className={classes.loadingState}>
                <CircularProgress />
              </div>
            )}

            {!loadingColombia && migrationList.length === 0 && (
              <div className={classes.blankState}>
                {t("reports.guestPage.noGuestMessage")}
              </div>
            )}
          </div>
        </div>

        {/* Open reservation when row clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && (
          <Reservation
            open={openReservation}
            handleCloseReservation={handleCloseReservation}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  migrationList: state.reports.colombia || [],
  loadingColombia: state.loading.LOAD_COLOMBIA_REPORT,
});

export default withTranslation()(connect(mapStateToProps)(Colombia));
