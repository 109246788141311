const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "AUTH_USER_SUCCESS":
      return { redirectURI: action.payload }
    case "AUTH_CALLBACK_SUCCESS":
    case "QUICKBOOKS_INFO_SUCCESS":
    case "CREATE_QB_DETAILS_SUCCESS":
    case "UPDATE_QB_DETAILS_SUCCESS":
      return action.payload
    case "QUICKBOOKS_INFO_FAILURE":
      return state
    default:
      return state;
  }
};
