import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const useStyles = makeStyles(theme =>({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        color: "#666666",
    },
    errorIcon: {
        fontSize: '9rem'
    },
    text:{
        marginTop: "25px",
        fontSize: "3rem",
    },
}));

const ErrorPage = props =>{
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <ErrorRoundedIcon className={classes.errorIcon}/>
            <div className={classes.text}>Oops! Something went wrong.</div>
        </div>
    )
}

export default ErrorPage;