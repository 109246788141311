import http from "../../utils/http"

export function getAutoPayInfo(propertyID) {
  const ACTION_NAME = "GET_AUTOPAY_INFO"
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`api/frontdesk/autoPayRule/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult
        dispatch({ type: `${ACTION_NAME}_ERROR`, payload:error });
      })
  }
}

export function updateAutoPayInfo(propertyID, data) {
  const ACTION_NAME = "UPDATE_AUTOPAY_INFO";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/autoPayRule/${propertyID}`, data)
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:successResult.data });
        return successResult
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult
      });
  };
}