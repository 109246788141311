import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

import DateBlock from './DateBlock';

const useStyles = makeStyles(theme =>({
    root:{
        marginBottom: '10px',
        borderBottom: '1px solid #ECECEC'
    },
    listContainer:{
        display: 'flex',
        '& .activityInfo:nth-child(n)':{
            backgroundColor: '#BEE1ED',
        },

        '& .activityInfo:nth-child(2n)':{
            backgroundColor: '#f0f2ff'
        },

        '& .activityInfo:nth-child(3n)':{
            backgroundColor: '#e5f0ec'
        },
    },
    gradeTitle: {
        display: 'flex',
        padding: '10px',
        fontSize: '1.2rem',
        alignItems: 'center',
        fontWeight: 500,
        height: '100%',
        boxSizing: 'border-box',
        wordBreak: 'break-word',
        paddingLeft: '15px',
        color: '#666666',
    },
}));

const DateBlockRow = props =>{
    const { gradeData, slotClickHandler, productID } = props;
    const classes = useStyles();
    const daysDisplayCount = Object.keys(gradeData.inventory).length;
    return(
        <Grid container className={clsx(classes.root, 'dateBlockRow')}>
            <Grid item xs={4} sm={2}>
                <div className={classes.gradeTitle}>{gradeData.title}</div>
            </Grid>
            <Grid item xs={8} sm={10}>
                <div className={classes.listContainer}>
                    {Object.keys(gradeData.inventory).map(item =>(
                        <DateBlock
                            key={`dateBlock_${gradeData._id}_${item}`} 
                            daysDisplayCount={daysDisplayCount}
                            slotsList={gradeData.inventory[item]}
                            date={item}
                            productID={productID}
                            gradeTitle={gradeData.title}
                            slotClickHandler={slotClickHandler}
                        />
                    ))}
                </div>
            </Grid>
        </Grid>
    )
}

export default DateBlockRow;