import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomToolTip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#3e3e3e',
      color: '#fff',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const HtmlTooltip = props =>{
    return(
        <CustomToolTip 
            title={props.title} 
            arrow
            style={{ cursor: props?.cursorType || 'pointer' }}
        >
            {props.children}
        </CustomToolTip>
    )
}

export default HtmlTooltip;