import { schema } from 'normalizr';
import _ from 'lodash';

const tempRoomsArray = {};

//Adding bedIDs to rooms reducer data..........
const roomProcessStrategy = (value, parent, key) => {
  const roomsArray = _.cloneDeep(value.rooms);
  const newRoomsArray = roomsArray.map(room => {
    const bedIDs = value.beds.sort((a, b) => a.roomID.localeCompare(b.roomID) || a.name.localeCompare(b.name)).filter(bed => bed.roomID === room._id).map(bed => bed._id);
    
    const existingBeds = tempRoomsArray[room._id] && tempRoomsArray[room._id].beds ? tempRoomsArray[room._id].beds : [];
    const bedData = new Set([...existingBeds, ...bedIDs]);
    
    tempRoomsArray[room._id] = { ...room, beds : [ ...bedData ] };
    return { ...tempRoomsArray[room._id] };
  }).filter(data => data)

  return {
    ...value,
    rooms: [ ...newRoomsArray ]
  };
}

const bedSchema = new schema.Entity( 'beds', {}, 
  { 
    idAttribute: '_id' 
  }
);

const roomSchema = new schema.Entity('rooms', {}, {
  idAttribute: '_id'
});

const buildingSchema = new schema.Entity('buildings', {}, {
  idAttribute: '_id'
});

const roomTypeSchema = new schema.Entity('roomTypes', {
  rooms: [roomSchema],
  beds: [bedSchema]
}, { 
  processStrategy: roomProcessStrategy,
  idAttribute: '_id' 
});


const resSchema = new schema.Entity('reservations', {}, { 
  idAttribute: 'accommodationID' 
});

export { roomSchema, bedSchema, roomTypeSchema, buildingSchema, resSchema }