export default (state = [], action) => {
  switch (action.type) {

    case "LOAD_RESERVATION_MANUAL_OPTIONS_SUCCESS":
      if (state.length) {
        let options = [...state, ...action.payload.availableBeds];
        let jsonObject = options.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueOptions = Array.from(uniqueSet).map(JSON.parse);
        return [...uniqueOptions];
      } else {
        let optionData = action.payload.availableBeds || [];
        return [...optionData];
      }

    case "ADD_RESERVATION_SUCCESS":
      return []
    
    case "RESET_RESERVATION_MANUAL_OPTIONS":
      return []

    case "USER_LOGOUT":
      return []

    default:
      return state;
  }
};