const defaultState = { userPermissions: [] };
export default (state = defaultState, action) => {
    switch (action.type) {
        case "LOAD_USER_PERMISSIONS_SUCCESS":
            return{
                ...state,
                userPermissions: [...action.payload]
            }

        case "FETCH_USER_PERMISSIONS_SUCCESS":
        case "UPDATE_USER_PERMISSIONS_SUCCESS":
            return {
                ...state,
                accessData: {...action.payload }
            };

        case "RESET_ACCESS_DATA":
            return {
                ...state,
                accessData: {}
            }

        case "FETCH_MODULE_USERS_SUCCESS":
        case "UPDATE_USER_MODULE_PERMISSIONS_SUCCESS":
            return {
                ...state,
                accessIdData: {
                    ...state.accessIdData,
                    [action.payload.moduleID]: [...action.payload.data]
                }
            }
        case "FETCH_GROUP_USERS_SUCCESS":
            return {
                ...state,
                accessIdData: {
                    ...state.accessIdData,
                    ...action.payload
                }
            }

        case "RESET_USER_PERMISSIONS":
            return { ...defaultState }

        default:
            return state;
    }
};