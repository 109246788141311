export default (state = {}, action) => {
  switch (action.type) {
    case "FETCH_CHANNEL_STATUS_SUCCESS":
      return {
        ...state,
        channelList: action.payload
      };
    case "FETCH_CHANNEL_ROOM_TYPE_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "FETCH_CHANNEL_RATEPLANS_SUCCESS":
      return {
        ...state,
        ratePlans: {...action.payload}
      };
    case "CREATE_ROOM_TYPE_MAPPING_SUCCESS":
      let typeData = {...state};
      typeData.mapping =  typeData.mapping || {};
      typeData.mapping[action.payload.type] = typeData.mapping[action.payload.type] || {};
      if(!typeData.mapping[action.payload.type][action.payload.roomTypeID]) {
        typeData.mapping[action.payload.type][action.payload.roomTypeID] = action.payload
      }
      return {
        ...typeData
      };
    case "DELETE_ROOM_TYPE_MAPPING_SUCCESS":
      let mappedData = {...state};
      if (mappedData.mapping && mappedData.mapping[action.payload.type] && mappedData.mapping[action.payload.type][action.payload.roomTypeID]) {
        delete mappedData.mapping[action.payload.type][action.payload.roomTypeID];
      }
      return {
        ...mappedData
      };
    case "DELETE_PROPERTY_CHANNEL_MAPPING_SUCCESS":
      let stateData = { ...state };
      let deletedType = action.payload.type;
      stateData.channel[deletedType] = null;
      stateData.mapping[deletedType] = null;
      return {
        ...stateData
      };
    case "RESET_CHANNEL_MAPPING_SUCCESS":
      let statesData = { ...state };
      statesData.channel = {};
      statesData.mapping = {};
      statesData.ratePlans = {};
      return {
        ...statesData
      };
    default:
      return state;
  }
};