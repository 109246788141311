import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { returnCurrent } from '../../../redux/selectors/dashboard';
import { fetchDateFormat, DATE_FORMATS } from '../../../utils/utility';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f8f9fb',
    display: 'flex',
    alignItems: 'end',
    padding: 20,
    paddingBottom: 0,
    justifyContent: 'space-between'
  },
  status: {
    backgroundColor: '#ffffff',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    minHeight: 65,
    width: '30%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  attach: {
    width: 40,
    height: 15,
    backgroundColor: '#ffffff',
  },
  icon: {
    padding: 10,
    '& img': {
      width: 25
    }
  },
  bold: {
    fontWeight: 600,
  },
  smallText: {
    fontSize: '1.2rem',
  },
  lightText: {
    color: '#d4d4d4'
  },
  expand: {
    height: 30,
    flex: '1 1 100%'
  },
  textDisplay: {
    display: 'block'
  },
  link: {
    cursor: 'pointer'
  },
  current: {
    backgroundColor: '#eeeeee'
  },
  button: {
    cursor: 'pointer',
    backgroundColor: "#d5d65e",
  }
}));

const Status = (props) => {
  const classes = useStyles();
  const { booking, expand, currentSpace } = props;
  const [nextStep, setNextStep] = useState(null);
  const [prevStep, setPrevStep] = useState(null);

  const handleClick = (selection) => {
    if(selection === 'cancel') {
      props.handleSelectStatus('cancel', true);
    }
  }

  const handleExpand = () => {
    props.handleSelectStatus('details', true);
  }

  const buildStatusBlock = ({
    text, status, date, linkText, secondaryText, icon, isButton, isCurrentStatus, revertTo
  }) => {
    date = date ? fetchDateFormat(date, DATE_FORMATS.LONG_DATE + ' ' + DATE_FORMATS.TIME, currentSpace.timezone) : null;
    
    return (
      <div className={
        clsx(
          classes.status, {
          [classes.button]: isButton,
          [classes.current]: isCurrentStatus
        })}
        onClick={() => { if (isButton) handleClick(status) }}
      >
        <div className={classes.details}>

          {revertTo &&
            <span className={clsx(classes.smallText, classes.textDisplay)}>
              <a className={classes.link} onClick={() => handleClick(status)}>Revert to</a>
            </span>
          }

          <span className={clsx(classes.bold, classes.textDisplay)}>{text}</span>

          {date &&
            <span className={clsx(classes.bold, classes.smallText, classes.textDisplay)}>{date}</span>
          }

          {linkText &&
            <span className={clsx(classes.smallText, classes.textDisplay)}>
              <a className={classes.link} onClick={handleExpand}>{linkText}</a>
            </span>
          }

          {secondaryText &&
            <span className={clsx(
              classes.lightText,
              classes.smallText,
              classes.textDisplay
            )}>{secondaryText}</span>
          }

        </div>
        <div className={classes.icon}>
          <img src={icon} />
        </div>

        {linkText && expand &&
          <div className={classes.expand}></div>
        }
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {/* -------------------------- Confirmed button ------------------------------- */}
      {buildStatusBlock({
        text: 'Confirmed',
        status: '',
        date: null,
        linkText: 'Booking details',
        secondaryText: null,
        icon: IMAGES.ICONS.correct,
        isCurrentStatus: true,
        isButton: false,
        revertTo: false
      })}

      {/* ----------------------------- Checked-out button ---------------------------- */}

      {buildStatusBlock({
        text: 'Cancel',
        status: 'cancel',
        date: null,
        linkText: null,
        secondaryText: null,
        icon: IMAGES.ICONS.bin,
        isCurrentStatus: false,
        isButton: true
      })}

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
})

export default connect(mapStateToProps)(Status);