import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  Snackbar,
  makeStyles,
  CircularProgress,
  DialogContent,
  InputBase,
  Button,
  Dialog,
} from "@material-ui/core";

import Channel from "../../channels/Channel";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import { loadRatePlans } from "../../../redux/actions/rates/ratePlans";
import {
  fetchChannelRoomType,
  createRoomTypeMapping,
  deleteRoomTypeMapping,
  deletePropertyChannelMapping,
  resetChannelMapping,
  createChannelFullRefreshPost,
} from "../../../redux/actions/channelMapping/channelMapping";
import { fetchChannelRatePlans } from "../../../redux/actions/channelMapping/channelRatePlanMapping";
import { resetError } from "../../../redux/actions/reservation/editReservation";
import { CHANNEL } from "../../../utils/channels";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialog: {
    overflowY: "scroll",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backButton: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100% - 30px)",
    // marginTop: 30,
    width: "100%",
  },
  loadingState: {
    width: "100%",
    height: "calc(100vh)",
    position: "absolute",
    top: "0",
    left: "0",
    background: "#F2F2F2",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  dialogContent: {
    // background: '#F2F2F2'
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "100%",
    "& > div": {
      width: "100%",
    },
  },
  textInputContainer: {
    display: "flex",
    marginBottom: "30px",
  },
  fieldError: {
    border: "1px solid red",
  },
  blankSlate: {
    margin: "50px auto",
    width: "80%",
    textAlign: "center",
    lineHeight: 1.5,
  },
  blankText: {
    fontSize: "1.4rem",
    marginBottom: 20,
  },
}));

const Mapping = (props) => {
  const classes = useStyles();
  const { currentSpace, channel, mapping, ratePlans, loading } = props;
  const [loader, setLoader] = React.useState(false);
  const code = CHANNEL.Hostelworld.code;

  React.useEffect(() => {
    if (props.open) {
      props.dispatch(fetchChannelRoomType(currentSpace.propertyID, code));
      props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, code));
      if (ratePlans.default) {
        props.dispatch(loadRatePlans(currentSpace.propertyID));
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    if (loader && !props.loadingRefresh) {
      setLoader(props.loadingRefresh);
    }
  }, [props.loadingRefresh]);

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetError("CREATE_ROOM_TYPE_MAPPING"));
    props.dispatch(resetError("DELETE_PROPERTY_CHANNEL_MAPPING"));
    props.dispatch(resetError("UPDATE_VIEW_CC_PASSWORD"));
  };

  const handleRefresh = (itemID) => {
    if (!itemID) {
      setLoader(true);
    }
    props.dispatch(
      createChannelFullRefreshPost(currentSpace.propertyID, itemID, code)
    );
  };

  const handleMapping = (payload) => {
    payload.hw_roomTypeID = payload.channel_roomTypeID;

    props.dispatch(
      createRoomTypeMapping(
        { ...payload, propertyID: currentSpace.propertyID },
        code
      )
    );
  };

  const handleDeleteMapping = (itemID) => {
    props.dispatch(deleteRoomTypeMapping(itemID, code));
  };
  const handleDeletePropertyMapping = () => {
    props.dispatch(deletePropertyChannelMapping(currentSpace.propertyID, code));
  };

  const handleClose = () => {
    props.dispatch(resetChannelMapping());
    props.closeHandler();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      classes={{ paperFullWidth: classes.dialogPaper }}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {CHANNEL.Hostelworld.name} - Counter Mapping
          </div>
          <AccessHeaderAction moduleID={MODULE.HW_MAPPING.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <DialogContent className={classes.dialogContent}>
          {(loading || loader) && (
            <div className={classes.loadingState}>
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            </div>
          )}

          {!(loading || loader) && !channel[CHANNEL.Hostelworld.code] && (
            <div className={classes.blankSlate}>
              <div className={classes.blankText}>
                No room types on {CHANNEL.Hostelworld.name}, please create new
                room types
              </div>
            </div>
          )}

          <div className={classes.root}>
            {!(loading || loader) && channel[CHANNEL.Hostelworld.code] && (
              <Channel
                channelType={CHANNEL.Hostelworld.code}
                item={channel[CHANNEL.Hostelworld.code]}
                roomTypes={currentSpace.roomTypes}
                mapping={mapping[CHANNEL.Hostelworld.code]}
                handleRefresh={handleRefresh}
                handleMapping={handleMapping}
                handleDeleteMapping={handleDeleteMapping}
                handleDeletePropertyMapping={handleDeletePropertyMapping}
              />
            )}
          </div>
        </DialogContent>
      </div>
      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  ratePlans: state.ratePlans,
  channel: state.channelMapping.channel || {},
  mapping: state.channelMapping.mapping || {},

  loadingRefresh: state.loading.CREATE_CHANNEL_FULL_REFRESH,
  loading:
    state.loading.FETCH_CHANNEL_ROOM_TYPE ||
    state.loading.DELETE_PROPERTY_CHANNEL_MAPPING,
  errors:
    state.errors.CREATE_CHANNEL_FULL_REFRESH ||
    state.errors.CREATE_ROOM_TYPE_MAPPING ||
    state.errors.DELETE_PROPERTY_CHANNEL_MAPPING,
});

export default connect(mapStateToProps)(Mapping);
