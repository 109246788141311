import http from '../../utils/http';
import { normalize } from 'normalizr';
import { cashCategorySchema } from '../../schema/frontDeskSchema';

//----------------------------------------------------------------
// LOAD CASH ACCOUNT LIST
//----------------------------------------------------------------
// export function fetchCashAccountList(propertyID) {
//   const ACTION_NAME = "LOAD_CASH_ACCOUNT_LIST";
//   return function(dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .get(`/api/frontdesk/cashAccountList/${propertyID}`)
//       .then(successResult => {
//         const data = successResult.data;
//         console.log(successResult.data)
//         // var normalizedOrder = normalize(data, [ cashDrawerSchema ] );
//         // console.log(normalizedOrder);
//         // dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.cashDrawers})
//       })
//       .catch(errorResult => {
//         console.log(errorResult);
//         // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
//       });
//   };
// }

//----------------------------------------------------------------
// LOAD CASH ACCOUNT ITEMS
//----------------------------------------------------------------
export function fetchCashAccountItems(propertyID) {
  const ACTION_NAME = "LOAD_CASH_ACCOUNTS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/cashAccountItems/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        var normalizedOrder = normalize(data, [ cashCategorySchema ] );
        // console.log(normalizedOrder.entities)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE CASH ACCOUNTS
//----------------------------------------------------------------
export function updateCashAccounts(propertyID, payload) {
  const ACTION_NAME = "UPDATE_CASH_ACCOUNTS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/cashAccount/${propertyID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        console.log('UPDATE_CASH_ACCOUNTS',data);
        var normalizedOrder = normalize(data, cashCategorySchema );
        console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE CASH SUB ACCOUNTS
//----------------------------------------------------------------
export function updateCashSubAccounts(propertyID, payload) {
  const ACTION_NAME = "UPDATE_CASH_SUB_ACCOUNTS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/cashSubAccount/${propertyID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        console.log('UPDATE_CASH_SUB_ACCOUNTS',data);
        var normalizedOrder = normalize(data, cashCategorySchema );
        console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SETUP CASH ACCOUNTS
//----------------------------------------------------------------
export function setupCashAccounts(spaceID, propertyID, accountList) {
  const ACTION_NAME = "SETUP_CASH_ACCOUNTS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/cashAccount/${propertyID}`, accountList)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ cashCategorySchema ] );
        console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// RESET CASH DRAWERS
//----------------------------------------------------------------
export const resetCashAccounts = () => (dispatch) => {
  dispatch({ type: "RESET_CASH_ACCOUNTS"})
};
