import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import MigrationFlow from '../components/hw-inbox-migration';
import WelcomePage from '../components/hw-inbox-migration/WelcomePage';
import ErrorPage from '../components/hw-inbox-migration/ErrorPage';

import migrationData from '../components/hw-inbox-migration/data';
import { migrationAccessIDs } from '../components/hw-inbox-migration/migrationPropertyData';

import { updateHWMigrationInfo, resetError } from '../redux/actions/hwMigration/hwMigration';
import setAuthToken from "../redux/utils/setAuthToken";
import { setUserSettings } from '../redux/actions/UIsettings/userSettings';
import { setCurrentUser } from '../redux/actions/auth/auth';
import  { userSettingSchema } from '../redux/schema/userUIsettings';

const styles = theme =>({
    root: {
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '45%',
    },
});

class HWMigrationContainer extends Component {

    state = {
        showMigrationProcess: false,
        showWelcomePage: false,
        showErrorPage: false,
        userInfo: {}
    }

    displayProcessHandler = (data) =>{
        //migrationComplete true => WelcomePage with redirect to dashboard button
        if(data.migrationCompleted && data.ongoingStep == 5){
            this.loginToWorksape(data.loginData);
            return;
        }

        //Show welcome onLoad page if step 1 is not completed.....
        if(!data.migrationCompleted && data.ongoingStep == 1){
            this.setState({ showWelcomePage: true });
            return;
        }

        //ongoingStep > 0 and migrationComplete false => MigrationFlow
        if(data.ongoingStep > 0){
            this.setState({ showMigrationProcess: true });
            return;
        }

        //ongoingStep is 0 and migrationComplete false => WelcomePage
        this.setState({ showWelcomePage: true });
    }

    validateUrlParams = (urlParams) =>{
    
        if(urlParams.apiKey && urlParams.userEmail && urlParams.userRole && urlParams.propertyNumber){
            return { isValid: true, flowType: 1 };
        }

        //New auth flow url params check................
        if(urlParams.apiKey && urlParams.token){
            return { isValid: true, flowType: 2 };
        }

        return { isValid: false };
    }

    componentDidMount(){
        
        const { location, dispatch } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const urlParams = {};
        for (let [ key, value ] of searchParams) {
            urlParams[key] = value;
        }

        const urlInfo = this.validateUrlParams(urlParams);

        if(!urlInfo.isValid){
            //Show something went wrong page.......
            this.setState({ showErrorPage: true });
            return;
        }
        
        if(urlInfo.flowType == 1){
            this.oldAuthFlow(urlParams);
            return;
        }

        const updateAPIParams = { 
            apiKey: urlParams.apiKey,
            hwToken: urlParams.token 
        };

        dispatch(updateHWMigrationInfo(0, updateAPIParams, {}))
        .then(response =>{
            if(response){
                this.setState({ userInfo: { ...urlParams, ...response.hw_params }});
                this.displayProcessHandler(response);
            }
        })
        .catch(error =>{
            this.setState({ showErrorPage: true });
        });

        //Test data.................
        // const data = migrationData;
        // this.setState({ userInfo: { ...urlParams, data.hw_params }});
        // dispatch(updateHWMigrationInfo(0, urlParams.apiKey, data));
        // this.displayProcessHandler(data);
        
    }

    oldAuthFlow = (urlParams) =>{

        if(!migrationAccessIDs.includes(parseInt(urlParams.propertyNumber))){
            this.props.history.push("/");
            return;
        }

        //API Call.............
        const data = { 
            propertyNumber: urlParams.propertyNumber, 
            userEmail: urlParams.userEmail,  
            userRole: urlParams.userRole,  
        }

        const updateAPIParams = { apiKey: urlParams.apiKey };

        this.props.dispatch(updateHWMigrationInfo(0, updateAPIParams, data))
        .then(response =>{
            if(response){
                this.setState({ userInfo: { ...urlParams }});
                this.displayProcessHandler(response);
            }
        });

        //Test data.................
        // const data = migrationData;
        // this.setState({ userInfo: { ...urlParams }});
        // dispatch(updateHWMigrationInfo(0, urlParams.apiKey, data));
        // this.displayProcessHandler(data);
    }

    loginToWorksape = (loginInfo = null, redirect = true) =>{
        const { dispatch, loginData } = this.props;
        const authData = loginInfo || loginData;
        const { token, user = {}, userUIsettings = [] } = authData;
        localStorage.setItem("jwtToken", token);
      
        // Set token to Auth header
        setAuthToken(token);
  
        // Set current user
        dispatch(setCurrentUser(user));

        // // User space level UI settigs.................................................
        const normalizedUserSettings = normalize(userUIsettings, [ userSettingSchema ] );
        dispatch(setUserSettings(normalizedUserSettings.entities.userSettings))
       
        if(redirect) this.props.history.push("/beds/channels/hostelworld/");
    }

    getStartedHandler = () =>{
        this.setState({
            showMigrationProcess: true,
            showWelcomePage: false,
            showErrorPage: false,
        });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('UPDATE_HW_MIGRATION_INFO'));
    }

    render(){
        const { 
            showMigrationProcess,
            showWelcomePage,
            showErrorPage,
            userInfo
        } = this.state;

        const { classes, errors, isLoading } = this.props;

        const updateAPIParams = {
            apiKey: userInfo.apiKey,
            ...(userInfo.token && { hwToken: userInfo.token }) //Token check made to handle old flow................
        }

        return (
            <div>
                {/*----------------PROGRESS LOADER--------------------- */}
                {isLoading && (
                    <div className={classes.loadingState}>
                        <CircularProgress className={classes.loader} />
                    </div>
                )}

                {showWelcomePage && <WelcomePage getStartedHandler={this.getStartedHandler}/>}
                
                {showMigrationProcess && (
                    <MigrationFlow 
                        userInfo={userInfo}
                        loginToWorksape={this.loginToWorksape}
                        updateAPIParams={updateAPIParams}
                    />
                )}
                
                {showErrorPage && <ErrorPage />}

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { hwMigration, loading, errors } = state;
    return { 
        loginData: hwMigration?.loginData || {},
        errors: errors.UPDATE_HW_MIGRATION_INFO, 
        isLoading: loading.UPDATE_HW_MIGRATION_INFO || loading.SAVE_USER_PROFILE_LANGUAGE,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(withRouter(HWMigrationContainer)));