export const CalendarColors = {
    resvStatus:{
        id: 'resvStatus',
        title: 'Reservation Status',
        colorInfo: {
            'Checked Out': {
                id: 'checkout',
                title: 'Check-out',
                color: '#30638E'
            },
            'Confirmed': {
                id: 'confirmed',
                title: 'Confirmed',
                color: '#56A3A6'
            },
            'In House': {
                id: 'inHouse',
                title: 'In house',
                color: '#7A6F9B'
            },
            'Online Checked In': {
                id: 'onlineCheckedIn',
                title: 'Online Checked In',
                color: '#C17C74'
            }
        }
    },

    bookingSource:{
        id: 'bookingSource',
        title: 'Booking Source',
        colorArray: [ 
            '#7A6F9B',
            '#56A3A6',
            '#30638E',
            '#797D81',
            '#8E3B46',
            '#9B3D12', 
            '#C17C74',
            '#aec1e1',
            '#586F6B',
            '#AC9969'
        ],
        colorInfo: {}
    },

    balance:{
        id: 'balance',
        title: 'Balance Outstanding',
        colorInfo: {
            unpaid: {
                id: 'unpaid',
                title: 'Unpaid',
                color: '#CC6666'
            },
            paid: {
                id: 'paid',
                title: 'Paid',
                color: '#797D81'
            }
        }
    },

    notes: {
        id: 'notes',
        title: 'Reservation Notes',
        colorInfo: {
            yes: {
                id: 'yes',
                title: 'Yes',
                color: '#56A3A6'
            },
            noNotes: {
                id: 'noNotes',
                title: 'No notes',
                color: '#797D81'
            }
        }
    },

    default: {
        id: 'resvStatus',
        color: '#CCCCCC'
    }
} 