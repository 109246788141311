import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker as MaterialTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme =>({
    root:{
        '& .MuiOutlinedInput-input':{
            padding: '11px',
        }
    }
}));

const TimePicker = props =>{
    const classes = useStyles();
    const { value, label, onChange } = props;
    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MaterialTimePicker 
                variant="inline"
                emptyLabel={label || "Time"}
                inputVariant="outlined"
                value={value} 
                onChange={onChange} 
                className={clsx(classes.root, 'timePicker')}
            />
        </MuiPickersUtilsProvider>
    )
}

export default TimePicker;