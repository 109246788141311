import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'

import Sidebar from './sidebar'
import ContentRenderer from './ContentRenderer'

import { sidebarMenu } from './constant'
import { getNextStepInfo, getPrevStepInfo } from './progressHelper'
// import { uploadRoomPhoto } from '../../../redux/actions/rooms/rooms'
import { resetError } from '../../../redux/actions/common/common'
import { cloneDeep, isEqual, set } from 'lodash'
import { setPropertySettings, updateHostelWorldPaymentMethod } from '../../../redux/actions/microsite/hostelworld'

const styles = theme => ({
  dialog: {
    position: 'relative'
  },
  container: {
    display: 'flex',
    height: '100%',
    position: 'relative'
  },
  sidebarContainer: {
    backgroundColor: '#F8F9FB'
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 16,
    zIndex: 99,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  }
})

class UpdateHWInbox extends Component {
  constructor (props) {
    super(props)
    const defaultMainStepID = Object.keys(sidebarMenu)[0]
    this.state = {
      activeMenuID: defaultMainStepID,
      video: false,
      settings: cloneDeep(props.propertySettings || {}),
      updatedSettings: {}
    }

    this.navigateToMenuHandler = this.navigateToMenuHandler.bind(this)
    this.nextStepHandler = this.nextStepHandler.bind(this)
    this.prevStepHandler = this.prevStepHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.handleCloseError = this.handleCloseError.bind(this)
    this.updateSettings = this.updateSettings.bind(this)
    this.deleteSettingsContent = this.deleteSettingsContent.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.propertySettings && !isEqual(this.props.propertySettings, prevProps.propertySettings)) {
      this.setState({ settings: cloneDeep(this.props.propertySettings || {}) })
    }
  }

  navigateToMenuHandler (menuID) {
    this.setState({ activeMenuID: menuID })
  }

  nextStepHandler () {
    const { activeMenuID } = this.state
    const nextStepInfo = getNextStepInfo(activeMenuID)

    if (nextStepInfo) this.navigateToMenuHandler(nextStepInfo.menuID)
  }

  prevStepHandler () {
    const { activeMenuID } = this.state
    const prevStepInfo = getPrevStepInfo(activeMenuID)

    if (prevStepInfo) this.navigateToMenuHandler(prevStepInfo.menuID)
  }

  async submitHandler () {
    let { settings, updatedSettings } = this.state
    const { currentSpace, dispatch } = this.props

    if (updatedSettings?.policy?.paymentMethod) {
      const updatedPaymentMethod = { ...settings.policy.paymentMethod, ...updatedSettings.policy.paymentMethod }
      await dispatch(updateHostelWorldPaymentMethod(updatedPaymentMethod, currentSpace.propertyID))
    }

    
    if (updatedSettings.content) {
      const checkKeys = ['categories1', 'categories2', 'bestFor1', 'bestFor2'];
      if (checkKeys.some(key => Object.keys(updatedSettings.content).includes(key))) {
        updatedSettings.categories = [
          { id: Number(updatedSettings?.content?.categories1 || settings.categories[0].id) },
          { id: Number(updatedSettings?.content?.categories2 || settings.categories[1].id) }

        ];
        updatedSettings.bestFor = [
          { id: Number(updatedSettings?.content?.bestFor1 || settings.bestFor[0].id) },
          { id: Number(updatedSettings?.content?.bestFor2 || settings.bestFor[1].id) }
        ];
      }
    }

    if (updatedSettings.policy) {
      const checkAdditionalDetailsKeys = ["ageRestriction", "curfew", "noCurfew", "petFriendly", "nonSmoking", "childFriendly", "taxesIncluded", "taxesNotIncluded"];
      if (checkAdditionalDetailsKeys.some(key => Object.keys(updatedSettings.policy).includes(key))) {

        updatedSettings.additionaldetails = {
          ageRestriction: updatedSettings?.policy?.ageRestriction || settings.policy.ageRestriction,
          curfew: updatedSettings?.policy?.curfew || settings.policy.curfew,
          noCurfew: updatedSettings?.policy?.noCurfew || settings.policy.noCurfew,
          petFriendly: updatedSettings?.policy?.petFriendly || settings.policy.petFriendly,
          nonSmoking: updatedSettings?.policy?.nonSmoking || settings.policy.nonSmoking,
          childFriendly: updatedSettings?.policy?.childFriendly || settings.policy.childFriendly,
          taxesIncluded: updatedSettings?.policy?.taxesIncluded || settings.policy.taxesIncluded,
          taxesNotIncluded: updatedSettings?.policy?.taxesNotIncluded || settings.policy.taxesNotIncluded,
        };
      }
    }

    if(updatedSettings?.images?.length) {
      updatedSettings.imagesIds = updatedSettings.images.map(image => image.id);
      delete updatedSettings.images;
    }

    dispatch(setPropertySettings(currentSpace.propertyID, updatedSettings))
      .then(result => {
        this.setState({ ...this.state, updatedSettings: {} })
      })
  }

  //   getFileData (data) {
  //     const imageFile = data.imageFile
  //     const fileParts = imageFile.name.split('.')
  //     const fileName = fileParts[0]
  //     const fileType = fileParts[1]
  //     return { imageFile, fileName, fileType }
  //   }

  //   imageUploadHandler () {
  //     const { imageFile, fileName, fileType } = this.getFileData(this.state.room)

  //     const uploadData = {
  //       file: imageFile,
  //       type: fileType,
  //       name: fileName,
  //       roomID: this.props.roomID,
  //       propID: this.props.currentSpace.propertyID
  //     }
  //     const response = this.props.dispatch(uploadRoomPhoto(uploadData))
  //     response.then(response => {
  //       this.props.handleCancel()
  //     })
  //   }

  handleCloseError () {
    const { dispatch } = this.props
    dispatch(resetError('GET_PROPERTY_SETTINGS'))
    dispatch(resetError('SET_PROPERTY_SETTINGS'))
    dispatch(resetError('GET_INBOX_REVIEWS'))
    // dispatch(resetError('SET_INBOX_REVIEWS'))
  }

  updateSettings (key, value) {
    const state = { ...this.state }
    set(state.settings, key, value)
    set(state.updatedSettings, key, value)
    this.setState(state)
  }

  deleteSettingsContent (key) {
    const state = { ...this.state }
    const { propertySettings } = this.props;
    set(state.settings, `content[${key}].content`, propertySettings.content[key].content)
    if(state?.updatedSettings?.content?.[key]) 
      delete state.updatedSettings.content[key];
    this.setState(state)
  }

  render () {
    const {
      classes,
      closeModalHandler,
      isLoading,
      errors
    } = this.props

    const { activeMenuID } = this.state

    const contentProps = {
      activeStepInfo: {
        menuID: activeMenuID
      },
      nextStepHandler: this.nextStepHandler,
      prevStepHandler: this.prevStepHandler,
      settings: this.state.settings,
      updateSettings: this.updateSettings,
      deleteSettingsContent: this.deleteSettingsContent,
      submitHandler: this.submitHandler,
      handleCancel: this.props.handleCancel
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleCancel}
        className={classes.dialog}
        fullScreen
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={this.props.handleCancel}
        />

        <Grid container className={classes.container}>

          {/* ----------------PROGRESS LOADER--------------------- */}
          {isLoading && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}

          <Grid item className={classes.sidebarContainer} sm={3} lg={2} xs={12}>
            <Sidebar
              activeMenuID={activeMenuID}
              navigateToMenuHandler={this.navigateToMenuHandler}
              closeModalHandler={closeModalHandler}
              settings={this.state.settings}
              handleToggleDemo={() => this.setState({ video: true })}
            />
          </Grid>

          <Grid item sm={9} lg={10} xs={12}>
            <ContentRenderer
              activeMenuID={activeMenuID}
              contentProps={contentProps}
            />
          </Grid>
        </Grid>

        {/* ----------------DEMO VIDEO--------------------- */}
        <Dialog
          open={this.state.video}
          maxWidth='lg'
          onClose={() => this.setState({ video: false })}
        >
          <iframe
            width='800'
            height='488'
            // src='https://www.loom.com/embed/4398accbb78e4641b7ee9c0e5809f47a'
            src='https://www.loom.com/embed/be99dbed9b8c4376a64094f311eb9b50'
          />
        </Dialog>

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open
            onClose={this.handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id='message-id'>{errors}</span>}
          />
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  propertySettings: state.inbox.hostelworld.settings,
  isLoading: state.loading.GET_PROPERTY_SETTINGS || state.loading.SET_PROPERTY_SETTINGS || state.loading.DELETE_HOSTEL_WORLD_IMAGES,
  errors: state.errors.GET_PROPERTY_SETTINGS || state.errors.SET_PROPERTY_SETTINGS || state.errors.DELETE_HOSTEL_WORLD_IMAGES,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withStyles(styles)(connect(mapStateToProps)(UpdateHWInbox))
