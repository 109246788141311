import { schema } from 'normalizr';

const commentSchema = new schema.Entity('comments', {}, { idAttribute: '_id' });

const fileSchema = new schema.Entity('files', {
  comments: [commentSchema]
}, { 
  idAttribute: '_id' 
});

const folderSchema = new schema.Entity('folders', {
  files: [fileSchema]
}, { 
  idAttribute: '_id'
});

export { fileSchema, folderSchema };