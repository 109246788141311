
import React, { Component } from "react";
import Mentions from './trix-plugins/mentions';
import FileUpload from './trix-plugins/file-upload';
import Trix from "trix";

// import { LooksOne, LooksTwo, FormatUnderlined } from '@material-ui/icons';

import LooksOne from '@material-ui/icons/LooksOne';
import LooksTwo from '@material-ui/icons/LooksTwo';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';

import EmailTags from './trix-plugins/EmailTags';
import isEmpty from "lodash/isEmpty";

class TrixEditor extends Component {
    constructor(props){
        super(props);
        const { plugins = [] } = props;
        this.includePlugins = {
            files: plugins.includes('files'),
            mentions: plugins.includes('mentions'),
            emailTags: plugins.includes('emailTags'),
        };
        // console.log('plugins ', this.includePlugins);
    }
    componentDidMount() {
        const { 
            initialValue = null,
            mentionList, 
            imageUploadHandler
        } = this.props;

        Trix.config.blockAttributes.heading2 = {
            tagName: 'h2',
            terminal: true,
            breakOnReturn: true,
            group: false
        }

        Trix.config.textAttributes.underline = {
            style: { "textDecoration": "underline" },
            inheritable: true,
            parser: function(element) {
                var style = window.getComputedStyle(element);
                return style.textDecoration === "underline";
            }
        }

        //console.log('initialValue ',initialValue);
        if(initialValue){
            this.editorEl.editor.insertHTML(initialValue);
            // this.setCursorToTextEnd();
        }

        this.editorEl.addEventListener("trix-change", this.onChangeHandler);
        this.editorEl.addEventListener("keydown", this.keyDownHandler);
        this.editorEl.addEventListener("trix-file-accept", this.fileAcceptHandler);
        
        this.mention = new Mentions(this.editorEl, mentionList);
        if(this.includePlugins.mentions) this.mention.init();
        
        if(this.includePlugins.files){
            this.fileUpload = new FileUpload(imageUploadHandler);
            this.fileUpload.init();
        }
    }

    componentDidUpdate(prevProps){
        const { isForceUpdate,initialValue } = this.props;
        if(this.editorEl && (prevProps.isForceUpdate !== isForceUpdate)){
            this.editorEl.editor.insertHTML(initialValue);
        }
    }
    // setCursorToTextEnd = () =>{
    //     const length = this.editorEl.editor.getDocument().toString().length;
    //     this.editorEl.editor.setSelectedRange(length - 1);
    // }

    fileAcceptHandler = event =>{
        if(!this.includePlugins.files) event.preventDefault();
    }

    onChangeHandler = event =>{
        const { enableOnChange, handleSlateOnChange } = this.props;
        const newValue = event.target.innerHTML;
        if (enableOnChange) handleSlateOnChange(newValue);
    }

    keyDownHandler = event =>{
        const { submitOnEnter, handleSubmit } = this.props;
        if(submitOnEnter && event.key === 'Enter' && !event.shiftKey && !this.mention.isMenuActive){
            event.preventDefault();
            const mentions = [...this.mention.mentionIDList];
            const htmlData = this.editorEl.value;
            // console.log('htmlData.trim() ', htmlData.trim());
            if(htmlData.trim()){ 
                handleSubmit(htmlData, mentions);
                this.editorEl.value = '';
            }
        }
    }

    emailTagHandler = emailTag =>{
        this.editorEl.editor.insertHTML(`<span>${emailTag}</span>`);
    }

    componentWillUnmount(){
        if(this.includePlugins.mentions) this.mention.clearListners();
        if(this.includePlugins.files) this.fileUpload.clearListners();

        this.editorEl.removeEventListener("trix-change", this.onChangeHandler);
        this.editorEl.removeEventListener("keydown", this.keyDownHandler);
        this.editorEl.removeEventListener("trix-file-accept", this.fileAcceptHandler);
    }

    render() {
        const { placeholder } = this.props;
        return (
            <div>
                <trix-toolbar id="trix-toolbar">
                    <div className="trix-button-row">
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Bold" tabIndex="-1">Bold</button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabIndex="-1">Italic</button>
                        <button type="button" className="trix-button" data-trix-attribute="underline" title="underline" tabIndex="-1"><FormatUnderlined /></button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabIndex="-1">Link</button>
                        <button type="button" className="trix-button" data-trix-attribute="heading1" title="Heading" tabIndex="-1"><LooksOne /></button>
                        <button type="button" className="trix-button" data-trix-attribute="heading2" title="Heading 2" tabIndex="-1" data-trix-active=""><LooksTwo /></button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Quote" tabIndex="-1">Quote</button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Bullets" tabIndex="-1">Bullets</button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numbers" tabIndex="-1">Numbers</button>
                        {/* <button type="button" className="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Decrease Level" tabIndex="-1" disabled="">Decrease Level</button>
                        <button type="button" className="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Increase Level" tabIndex="-1" disabled="">Increase Level</button> */}
                        {this.includePlugins.files && (
                            <button type="button" className="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="Attach Files" tabIndex="-1">Attach Files</button>
                        )}

                        {this.includePlugins.emailTags && (
                            <EmailTags onSelectHandler={this.emailTagHandler}/>
                        )}

                    </div>
                    <div className="trix-dialogs" data-trix-dialogs="">
                        <div className="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
                            <div className="trix-dialog__link-fields">
                                <input type="url" name="href" className="trix-input trix-input--dialog" placeholder="Enter a URL…" aria-label="URL" required="" data-trix-input=""/>
                                <div className="trix-button-group">
                                    <input type="button" className="trix-button trix-button--dialog" value="Link" data-trix-method="setAttribute"/>
                                    <input type="button" className="trix-button trix-button--dialog" value="Unlink" data-trix-method="removeAttribute"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </trix-toolbar>
                <input type="hidden" id="trix" value={this.props.value} />
                <trix-editor 
                    itemClass="selwyn" 
                    input="trix"
                    toolbar="trix-toolbar"
                    placeholder={placeholder} 
                    ref={ el => this.editorEl = el} 
                />
            </div>
        );
    }
}

export default TrixEditor;