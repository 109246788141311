import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        backgroundColor: '#F8F9FB'
    },
    rows: {
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        '& div': {
            flex: 1,
            display: 'flex',
            alignItems: 'baseline',
        },
        '& pre': {
            marginLeft: 20
        }
    },
    tableContainer: {
        width: '100%',
    },
}));

const fetchRequestView = (payload) => {
    if (typeof payload === 'object' && payload !== null) {
        return JSON.stringify(payload)
    }
    if (payload.includes('<request>')) {
        payload = <pre lang="xml" >{payload}</pre>
    }
    return payload;
}


const RowDetails = props => {
    const classes = useStyles();
    const { rowData } = props;

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <div className={clsx(classes.rows, 'tableRows')} >
                    <div>API URL: {rowData.uri}</div>
                </div>
                <div className={clsx(classes.rows, 'tableRows')} >
                    <div>Request: {fetchRequestView(rowData.request)}</div>
                </div>
                <div className={clsx(classes.rows, 'tableRows')} >
                    <div>Response: {rowData.response}</div>
                </div>
            </div>
        </div>
    );
}

export default RowDetails;