import React from "react";
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { getAmtFormat } from "../../../../utils/utility";
import ConfirmForm from "../../../common/ConfirmForm";
import IMAGES from "../../../../constants/images";

const TaxSummary = (props) => {

  const { admin, taxes, reservation, currency, classes, t } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  const handleConfirmFormOpen = (id) => {
    setSelectedID(id);
    setConfirmOpen(true);
  }

  const handleConfirmFormClose = () => {
    setSelectedID(null);
    setConfirmOpen(false);
  }

  const handleConfirmDelete = () => {
    props.handleDelete(selectedID);
  }

  const taxAppliedView = reservation.taxesApplied &&
    reservation.taxesApplied.map((resTaxData, index) => {
      const taxData = { ...taxes[resTaxData.taxID] };
      return (resTaxData ?
        <div key={index}>
          <div className={clsx({
            [classes.itemDesc]: true,
            [classes.taxItem]: true
          })}>
            <div className={classes.taxDisplay}>
              <Typography variant="body1" className={classes.paymentDesc} style={{ width: '65%' }}>{taxData.name} ({taxData.calculationMode})</Typography>
              {admin ? <img className={classes.actionIcon} src={IMAGES.ICONS.bin} onClick={() => handleConfirmFormOpen(resTaxData.taxID)} /> : null}
              {/* {admin ? <img className={classes.actionIcon} src="/images/icons/edit-pencil.png" onClick={() => props.onClickEdit(resTaxData.taxID)} /> : null} */}
            </div>
            <div className={classes.amount}><Typography variant="body1" className={classes.paymentDesc}>
              {getAmtFormat(resTaxData.amount, currency)}
            </Typography></div>
          </div>
          {selectedID === resTaxData.taxID &&
            <div key={`confirm_${index}`}>
              <ConfirmForm
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onClose={handleConfirmFormClose}
                onConfirm={handleConfirmDelete}
              >
                {t('existingReservation.summaryTab.taxDelete')}
              </ConfirmForm>
            </div>
          }
        </div> : null
      );
    });

  return (
    taxAppliedView.length ? taxAppliedView :
      <div>
        {reservation.inclusiveTaxAmount ? <div className={clsx({
          [classes.itemDesc]: true,
          [classes.taxItem]: true,
        })}>
          <div className={classes.name}>
            <Typography variant="body1" className={classes.paymentDesc}>{t('existingReservation.summaryTab.inclusiveTax')}</Typography>
          </div>
          <div className={classes.amount}><Typography variant="body1" className={classes.paymentDesc}>
            {getAmtFormat(reservation.inclusiveTaxAmount || 0, currency)}
          </Typography></div>
        </div> : null}
        {reservation.exclusiveTaxAmount ? <div className={clsx({
          [classes.itemDesc]: true,
          [classes.taxItem]: true,
        })}>
          <div className={classes.name}>
            <Typography variant="body1" className={classes.paymentDesc}>{t('existingReservation.summaryTab.exclusiveTax')}</Typography>
          </div>
          <div className={classes.amount}><Typography variant="body1" className={classes.paymentDesc}>
            {getAmtFormat(reservation.exclusiveTaxAmount || 0, currency)}
          </Typography></div>
        </div>
          : null}
      </div>
  )
}

export default withTranslation()(TaxSummary);