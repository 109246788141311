import http from "../../utils/http";

export const loadUserPermissions = (userID, spaceID, isReset = true) =>{
    const params = { userID, spaceID };
    const ACTION_NAME = "LOAD_USER_PERMISSIONS";
    return function (dispatch, getState) {
        
        if(isReset) dispatch({ type: `RESET_USER_PERMISSIONS` });
        const spaces = getState().spaces;
        if(spaces[spaceID] && spaces[spaceID].type != 'Hostel') return;

        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/permission/`, { params })
            .then(successResult => {
                const { allowed = [] } = successResult.data;
                const accessList = allowed.map(data=>data.module);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: accessList 
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

export const fetchUserPermissions = (params) => {
    const ACTION_NAME = "FETCH_USER_PERMISSIONS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/permission/`, { params })
            .then(successResult => {
                const { allowed } = successResult.data;
                const  accessData = _.groupBy(allowed, data => data.group);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: accessData 
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult.response);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

export const updateUserPermissions = (status, data, type) => {
    const ACTION_NAME = "UPDATE_USER_PERMISSIONS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/permission/${status}?spaceID=${data.spaceID}&userID=${data.userID}`, type)
        .then(successResult => {
            const { allowed } = successResult.data;
            const  accessData = _.groupBy(allowed, data => data.group);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: accessData 
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult.response);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
}

export const resetAccessData = () =>({
    type: "RESET_ACCESS_DATA"
});

export const fetchModuleUsers = params =>{
    const ACTION_NAME = "FETCH_MODULE_USERS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/permission/module`, { params })
        .then(successResult => {
            //console.log('successResult.data ', successResult.data);
            const { data } = successResult;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    moduleID: params.module,
                    data 
                } 
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult.response);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
}

export const fetchGroupUsers = params =>{
    const ACTION_NAME = "FETCH_GROUP_USERS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/permission/group`, { params })
        .then(successResult => {
            // console.log('successResult.data ', successResult.data);
            const { data } = successResult;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: data
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult.response);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
}

export const updateUserModulePermission = (status, data, type) =>{
    const ACTION_NAME = "UPDATE_USER_MODULE_PERMISSIONS";
    return function (dispatch, getState) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/permission/${status}?spaceID=${data.spaceID}&userID=${data.userID}`, type)
        .then(successResult => {
            const { accessIdData } = getState().permissions;
            let updatedAccessIdData;
            switch(status){
                case 'grant':
                    updatedAccessIdData = [...accessIdData[type.module], data.userID];
                    break;
                
                case 'deny':
                    updatedAccessIdData = accessIdData[type.module].filter(id => id != data.userID);
                    break;
            }
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload:{
                    moduleID: type.module,
                    data: updatedAccessIdData 
                }
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult.response);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
}

export const removeUserFromSpace = (params) =>{
    const ACTION_NAME = "DELETE_USER_FROM_SPACE";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.delete(`/api/access`, { params })
        .then(successResult => {
            const { user, space } = successResult.data;
            //console.log('successResult ', successResult);
            dispatch({ 
                type: `EDIT_SPACE_ON_PERMISSION_UPDATE`, 
                payload: space
            });

            dispatch({ 
                type: `EDIT_USER_ON_PERMISSION_UPDATE`, 
                payload: user
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult.response);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
}