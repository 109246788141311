import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { makeStyles, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';

import { voidXML, cashXML, cardXML } from './printerFiles'
import { testPrinterConnection, testPrinterConnectionXML, testPrinterVersion,
    handleTestEPOS, testPrinterVersionHTTPS } from '../../redux/actions/reservation/fiscalPrinter'
import { fetchPrinters, addPrinter } from '../../redux/actions/settings/printer'
import Toggle from '../common/Toggle';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
   header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: 20
   },
   container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
   leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  rightAlign : {
    paddingLeft: 30,
    width: '70%'
  },
  field: {
    cursor: 'pointer'
  },
  portField: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '10%',
    minWidth: 50,
    marginRight: 15
  },
  textField: {
    '& input': {
      fontSize: '1.3rem'
    }
  },
  moreIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor:'pointer',
    fontSize: '1.8rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  flexLink: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    marginLeft: 10
  },
  start: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  topMargin: {
    marginTop: 25
  },
  button: {
    background: '#F8F9FB',
    border: '1px solid #000000',
    borderRadius: 25,
    color: '#000000',
    cursor: 'pointer',
    fontSize: '1.15rem',
    fontWeight: 400,
    padding: theme.spacing(1),
    marginRight: 15,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonTxt: {
    background: 'transparent',
    color: '#000000',
    fontSize: '1.25rem',
    fontWeight: 400,
    padding: theme.spacing(1),
    marginRight: 15,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  saveButton: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 12,
    paddingBottom: 10
  },
  cancelButton: {
    background: '#ffffff',
    border: '1px solid #000000',
    borderRadius: 25,
    color: '#000000',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginRight: 20,
    paddingTop: 12,
    paddingBottom: 10
  },
  row: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  firstRow: {
    margin: '10px 0',
  },
  information: {
    margin: '25px 0',
  },
  testLoading: {
    alignItems: 'center',
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: '100%'
  },
  fieldContainer: {
    alignItems: 'center',
    display: 'flex'
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex'
  },
  triggerToggle: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    height: 35,
    justifyContent: 'center',
    width: '50%',
    color: '#666666',
    fontWeight: 400,
    background: '#ffffff',
    boxShadow: '0 1px 20px #F2F2F2'
  },
  emailLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  emailRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  settingText: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.3,
    marginLeft: '5px'
  },
  autoTagContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '10px'
  },
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(100% - 60px)'
  },
  bulb: {
    width: 40,
    height: 40
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20
  }
}))

const PrinterSettings = props => {
  const { dispatch, property, loadingPrinter, currentSpaceID, settings, t, addPrinterLoading } = props
  const classes = useStyles()

  const [printingType, setPrintingType] = React.useState('file')
  const [form, setForm] = React.useState({})
  const [editFlag, setEditFlag] = React.useState(false)
  const [error, setError] = React.useState('')
  //const [downloadLink, setDownloadLink] = React.useState({void: '', cash: '', card: ''})
  const [downloadXMLLink, setDownloadXMLLink] = React.useState({void: '', cash: '', card: ''})
  const [printer, setPrinter] = React.useState({
    deviceId: "",
    deviceIp: "",
    deviceType: "",
    deviceModel: ""
  })
  const [port, setPort] = React.useState(8008)
  const [toggle, setToggle] = useState(false)
  let ePOSDevice
  
  useEffect(() => {
    props.dispatch(fetchPrinters(currentSpaceID))
  }, [])

  useEffect(() => {
    if(settings.printers && settings.printers.length) {
      var thisPrinter = null 
      if(settings.printers[0].deviceID) {
        thisPrinter = {
          deviceId: settings.printers[0].deviceID,
          deviceIp: settings.printers[0].deviceIP,
          deviceType: settings.printers[0].deviceType,
          deviceModel: settings.printers[0].deviceModel
        }
      }

      setToggle(settings.printers[0].toggle)
      if(settings.printers[0]?.printingType)
        setPrintingType(settings.printers[0].printingType)
      else {
        if(settings.printers[0]?.deviceID)
          setPrintingType('ip')
        else
          setPrintingType('file')
      }
      //setPrintingType(settings.printers[0]?.printingType ? settings.printers[0].printingType : (settings.printers[0]?.deviceID ? 'ip' : 'file'))
      if(thisPrinter) {
        setPrinter(thisPrinter)
        setForm(thisPrinter)
        const printData= JSON.stringify({ ...thisPrinter });
        localStorage.setItem("printer", printData);
      }
    }
  }, [settings])

  const handleEdit = () => {
    setEditFlag(true);
  }

  const handleCancelUpdates = () => {
    setEditFlag(false);
  }

  const handleOnChange = event => setForm({
    ...form, 
    [event.target.id]: event.target.value
  });

  const handleSubmitUpdates = () => {
    const printData= JSON.stringify({ ...form });
    localStorage.setItem("printer", printData);

    const data = {
      spaceID: currentSpaceID,
      toggle: true,
      printingType: 'ip',
      deviceID: form.deviceId,
      deviceIP: form.deviceIp,
      deviceType: form.deviceType,
      deviceModel: form.deviceModel,
    }

    props.dispatch(addPrinter(data, currentSpaceID))
    handleCancelUpdates();
  }

  const handleTest = () => {
    //props.dispatch(testPrinterConnectionXML())
    const response = props.dispatch(testPrinterConnection())
    response.then(res => {
      if(res.status && res.status !== 200) {
        setError(res.message)
      }
      else {
        setError(res)
      }
    })
  }

  const testEPOS = () => {
    const response = props.dispatch(handleTestEPOS())
    response.then(res => {
      if(res.status && res.status !== 200) {
        setError(res.message)
      }
      else {
        setError(res)
      }
    })
  }

  const connectionTest = () => {
    ePOSDevice = new window.epsonlib.ePOSDevice()
    ePOSDevice.connect(printer.deviceIp, port, (res) => {
      console.log(res)
    })
  }
  const disconnectTest = () => {
    // ePOSDevice.connect(printer.deviceIp, port, (res) => {
    //   console.log(res)
    // })
    ePOSDevice.disconnect()
  }

  console.log(settings)

  const makeTextFile = () => {
    //const link = { ...downloadLink }
    const xmlLink = { ...downloadXMLLink }

    const voidXMLBlob = new Blob([voidXML.join('\n')], { type: 'text/xml' })
    const cardXMLBlob = new Blob([cardXML.join('\n')], { type: 'text/xml' })
    const cashXMLBlob = new Blob([cashXML.join('\n')], { type: 'text/xml' })

    // this part avoids memory leaks
    if (xmlLink.void !== '') window.URL.revokeObjectURL(xmlLink.void)
    if (xmlLink.card !== '') window.URL.revokeObjectURL(xmlLink.card)
    if (xmlLink.cash !== '') window.URL.revokeObjectURL(xmlLink.cash)

    // update the download link state
    xmlLink.void = window.URL.createObjectURL(voidXMLBlob)
    xmlLink.card = window.URL.createObjectURL(cardXMLBlob)
    xmlLink.cash = window.URL.createObjectURL(cashXMLBlob)
    
    setDownloadXMLLink(xmlLink)
  }

  useEffect(() => {
    makeTextFile()
  }, [])

  const handlePortChange = (e) => {
    setPort(e.target.value)
  }

  const handleToggle = value => {
    if(toggle === value)
      return

    setToggle(value)
    const data = {
      toggle: value,
      printingType: 'file',
      spaceID: currentSpaceID
    }

    props.dispatch(addPrinter(data, currentSpaceID))
  }

  const handleChangeType = value => {
    if(printingType === value)
      return

    setPrintingType(value)
    const data = {
      toggle: true,
      printingType: value,
      spaceID: currentSpaceID
    }
    props.dispatch(addPrinter(data, currentSpaceID))
  }

  return (
    <div className={classes.header}>
      {addPrinterLoading && <div className={"loadingOverlay"}>
        <CircularProgress className={"loading"} />
      </div>}
      <div className={classes.autoTagContainer}>
        <div className={classes.settingText}>Print Fiscal Receipt</div>
        <Toggle
          toggle={toggle}
          onText='ON'
          offText='OFF'
          handleOn={() => handleToggle(true)}
          handleOff={() => handleToggle(false)}
        />
      </div>
      <div className={clsx(classes.infoRow, classes.topMargin)}>
        <div>
          <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
        </div>
        <div className={classes.helperText}>
          Enable this feature to generate fiscal receipts for transactions on a reservation. [Epson FP type printers only].
          Payment receipt for other printers is coming soon
        </div>
      </div>
      {toggle && <div className={classes.topMargin}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <p className={classes.label}>Printing Method</p>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.toggle}>
              <div className={clsx(classes.triggerToggle, classes.emailLeft)}
                style={{background: printingType === 'file' ? '#4F9581' : '#FFFFFF', color: printingType === 'file' ? '#FFFFFF' : '#666666'}}
                onClick={(e) => handleChangeType('file')} >
                FILE
              </div>
              <div className={clsx(classes.triggerToggle, classes.emailRight)}
                style={{background: printingType === 'ip' ? '#4F9581' : '#FFFFFF', color: printingType === 'ip' ? '#FFFFFF' : '#666666'}} 
                onClick={(e) => handleChangeType('ip')} >
                IP
              </div>
            </div>
          </Grid>
        </Grid>
        {printingType === 'file' && <div className={classes.information}>
          <span className={classes.firstRow}>Requirements: Epson FpMate installed and configured to sync with a computer folder for input files.</span>
          <br/>
          <span className={classes.firstRow}>Setup instructions:</span>
          <ul>
            <li className={classes.firstRow}>In browser settings, find the downloads section</li>
            <li className={classes.firstRow}>Toggle the option 'Ask for each download'. This prompts download location for each receipt</li>
            <li className={classes.firstRow}>In FPMate Configuration, note the [path] under 'Input File Options' and ensure File name is set to '*scontrino' (without the quotes)</li>
            <li className={classes.firstRow}>Set browser's download location to [path] from previous step</li>
            <li className={classes.firstRow}>Select '.xml' as file extension</li>
            <li className={classes.firstRow}>Use the sample files below to test if setup is complete</li>
          </ul>
        </div>}
        {printingType === 'ip' && <div>
          <div className={classes.container} style={editFlag ? {} : {borderBottom: '1px solid #eeeeee'}}>
            <div className={classes.leftAlign}>Device ID</div>
            <div className={classes.rightAlign}>
              {!editFlag && (
                <div onClick={handleEdit} className={classes.field}>
                  {printer.deviceId}
                </div>
              )}
              {editFlag && (
                <TextField
                  id="deviceId"
                  name="deviceId"
                  className={classes.textField}
                  defaultValue={printer.deviceId}
                  onChange={handleOnChange}
                  margin="none"
                  fullWidth
                />
              )}
            </div>
            {!editFlag && (
              <div onClick={handleEdit}>
                <EditIcon className={classes.moreIcon} />
              </div>
            )}
          </div>
          <div className={classes.container} style={editFlag ? {} : {borderBottom: '1px solid #eeeeee'}}>
            <div className={classes.leftAlign}>Device IP</div>
            <div className={classes.rightAlign}>
              {!editFlag && (
                <div onClick={handleEdit} className={classes.field}>
                  {printer.deviceIp}
                </div>
              )}
              {editFlag && (
                <TextField
                  id="deviceIp"
                  name="deviceIp"
                  className={classes.textField}
                  defaultValue={printer.deviceIp}
                  onChange={handleOnChange}
                  margin="none"
                  fullWidth
                />
              )}
            </div>
          </div>

          <div className={classes.container} style={editFlag ? {} : {borderBottom: '1px solid #eeeeee'}}>
            <div className={classes.leftAlign}>Device Type</div>
            <div className={classes.rightAlign}>
              {!editFlag && (
                <div onClick={handleEdit} className={classes.field}>
                  {printer.deviceType}
                </div>
              )}
              {editFlag && (
                <TextField
                  id="deviceType"
                  name="deviceType"
                  className={classes.textField}
                  defaultValue={printer.deviceType}
                  onChange={handleOnChange}
                  margin="none"
                  fullWidth
                  placeholder={"(TM or FP or RT)"}
                />
              )}
            </div>
          </div>

          <div className={classes.container} style={editFlag ? {} : {borderBottom: '1px solid #eeeeee'}}>
            <div className={classes.leftAlign}>Device Model</div>
            <div className={classes.rightAlign}>
              {!editFlag && (
                <div onClick={handleEdit} className={classes.field}>
                  {printer.deviceModel}
                </div>
              )}
              {editFlag && (
                <TextField
                  id="deviceModel"
                  name="deviceModel"
                  className={classes.textField}
                  defaultValue={printer.deviceModel}
                  onChange={handleOnChange}
                  margin="none"
                  fullWidth
                  placeholder={"(TM-m30)"}
                />
              )}
            </div>
          </div>
          {(editFlag) && (
            <div className={classes.submit}>
              {/*<Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancelUpdates}>{t('actions.cancel')}</Button>
              <Button size="small" variant="outlined" className={classes.button} onClick={handleSubmitUpdates}>{t('actions.save')}</Button>*/}
              <div className={classes.cancelButton} onClick={handleCancelUpdates}>
                {t('actions.cancel')}
              </div>
              <div className={classes.saveButton} onClick={handleSubmitUpdates}>
                {t('actions.save')}
              </div>
            </div>
          )}
        </div>}
        {printingType === 'ip' && !editFlag && printer.deviceId?.length > 0 && printer.deviceIp?.length > 0 && <div>
          <div className={classes.flexLink}>
            <div className={classes.start}>
              <div onClick={() => handleTest()} className={classes.button}>
                Test Connection
              </div>
              <div onClick={() => handleTestVersion()} className={classes.button}>
                Test Version
              </div>
              <div onClick={() => testEPOS()} className={classes.button}>
                Test EPOS
              </div>
            </div>
          </div>
          <Divider className={classes.firstRow}/>
          <div className={classes.flexLink}>
            <div className={classes.start}>
              <span className={classes.buttonTxt}>Connect Settings</span>
              <div className={classes.portField}>
                <InputBase 
                  id="port"
                  name="port"
                  defaultValue={port}
                  fullWidth
                  onChange={handlePortChange}
                />
              </div>
              <div onClick={() => connectionTest()} className={classes.button}>
                Connect
              </div>
              <div onClick={() => disconnectTest()} className={classes.button}>
                Disconnect
              </div>
            </div>
          </div>
        </div>}
        {printingType === 'file' && <div className={classes.flexLink}>
          <div className={classes.start}>
            <span className={classes.buttonTxt}>Sample Files</span>
            <a download='scontrino.xml' href={downloadXMLLink.cash}>
              <div className={classes.button}>
                Cash
              </div>
            </a>
            <a download='scontrino.xml' href={downloadXMLLink.card}>
              <div className={classes.button}>
                Card
              </div>
            </a>
            <a download='scontrino.xml' href={downloadXMLLink.void}>
              <div className={classes.button}>
                Void
              </div>
            </a>
          </div>
        </div>}
        {loadingPrinter && <div className={classes.testLoading}>
          <CircularProgress />
        </div>}
        {!loadingPrinter && error.length > 0 && <div className={classes.row}>
          {error}
        </div>}
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  property: state.property,
  loadingPrinter: state.loading.TEST_PRINTER_CONNECTION,
  addPrinterLoading: state.loading.ADD_PRINTER,
  settings: state.settings
})

export default withTranslation()(connect(mapStateToProps)(PrinterSettings));