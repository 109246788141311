import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const locationList = [
    "Aquarium",
    "Arcade",
    "Bar",
    "Music venue",
    "Casino"
];

const styles = theme =>({
    root: {
    
    },
    container:{
        display: 'flex',
        marginTop: '20px',
    },
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
    },
    btnContainer:{
        marginTop: '10px',
        '& .button':{
            fontWeight: 600
        }
    }
});

const defaultData = '';
class ActivityRequirements extends Component{
    
    maxLimit = 50;

    state = {
        requirementsData: [ defaultData ],
        requirementsError: false
    }

    componentDidMount(){
        const { productData } = this.props;
        if(productData.exclusions && productData.exclusions.length > 0){
            this.setState({ 
                requirementsData: [ ...productData.exclusions ]
            });
        }
    }

    onChangeHandler = (event, index) =>{
        const { requirementsData } = this.state;
        const value = event.target.value;
        requirementsData[index] = value;
        this.setState({ requirementsData: [ ...requirementsData ] })
    }

    addItemHandler = () =>{
        const { requirementsData } = this.state;

        if(requirementsData.length >= this.maxLimit) return;

        this.setState({
            requirementsData: [ ...requirementsData, defaultData ]
        })
    }

    removeItemHandler = index =>{
        const { requirementsData } = this.state;
        const updatedData = requirementsData.filter((item, itemIndex) => itemIndex != index);
        this.setState({
            requirementsData: [ ...updatedData ]
        })
    }

    validateRequirements = (requirementsData) =>{

        if( requirementsData.length > 0 ){
            this.setState({ requirementsError: false });
            return true;
        }
        this.setState({ requirementsError: true });
    }

    submitHandler = () =>{
        const { requirementsData } = this.state;
        const { submitDraftHandler } = this.props;

        const formattedData = requirementsData.filter(item => item);
        if(!this.validateRequirements(formattedData)) return;
        const data = { exclusions: [ ...formattedData ] };
        submitDraftHandler(data);
    }

    render(){
        const { classes, prevStepHandler, activeStepInfo, productData } = this.props;
        const { requirementsData, requirementsError } = this.state;
        return(
            <>
                <div className={clsx("main-content", "hideScrollBar")}>
                    
                    <div className="main-content-title">What guests should bring</div>

                    <div className="sub-title">
                        <p>If guests need anything in order to enjoy your experience, this is the place to tell them. Cooking experience hosts should make sure to include a complete list of ingredients here instead of planning to send them to guests later. We can't accept submissions that don't have a complete and specific list.</p> 
                        <p>This list will be emailed to guests when they book your experience to help them prepare. </p>
                        <p>Be as detailed as possible and add each item individually.</p>
                    </div>

                    {requirementsData.map((data, index) =>(
                        <div className={classes.container} key={`requirements_${index}`}>

                            <div className={classes.inputField}>
                                <InputBase 
                                    id={`requirementInput${index}`}
                                    name={`requirementInput${index}`}
                                    className={classes.textInput}
                                    fullWidth
                                    type="text"
                                    placeholder="Enter item here"
                                    value={data}
                                    // error={true}
                                    onChange={e => this.onChangeHandler(e, index)}
                                />
                            </div>

                            {index > 0 && (
                                <Button 
                                    variant="outlined"
                                    // className="SaveBtn"
                                    onClick={() => this.removeItemHandler(index)}
                                >
                                    Remove
                                </Button>
                            )}

                        </div>
                    ))}

                    { requirementsData.length < this.maxLimit && (
                        <div className={classes.btnContainer}>
                            <Button 
                                className="button"
                                onClick={this.addItemHandler}
                            >
                                +Add another item
                            </Button>
                        </div>
                    )}

                    {requirementsError && (
                        <FormHelperText className="errorMessage">
                            Please add atleast one item
                        </FormHelperText>
                    )}

                </div>
                <Footer
                    nextClickHandler = {this.submitHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}

export default withStyles(styles)(ActivityRequirements);