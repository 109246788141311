import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import CalenderRoomReservations from './CalenderRoomReservations'
import { UNALLOCATED } from '../../utils/constants'
import { forEach, get, some } from 'lodash'
import { checkDateInBetween } from '../../utils/utility'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  roomBuildingFontSize: {
    fontSize: '1.2rem !important'
  }
}))

const UnallocatedReservationRender = props => {
  const { styleClasses, calendarProps, filterType, propertyID } = props

  // unallocated reservations have no beds assigned so require property to determine whether to show
  if (!propertyID) return null

  // reusing styles from parent
  const classes = { ...styleClasses, ...useStyles() }

  const unallocatedReservationIds = Object.keys(calendarProps.reservations)
    .filter(rId => !calendarProps.reservations[rId].bedID && calendarProps.reservations[rId].propertyID === propertyID)

  // block for recalculating unallocated row height on reservations or propertyID change
  useEffect(() => {
    const cellMeasurer = get(calendarProps, 'cellMeasurerRef.current')
    if (cellMeasurer) cellMeasurer._measure()
  }, [calendarProps.reservations, propertyID])

  const unallocDynamicRowData = []

  /**
   * Function to check if unallocated reservations are overlapping
   */
  const isClashing = (rowIndex, rToCheck) => {
    if (!unallocDynamicRowData[rowIndex]) return false
    const rowRsvns = unallocDynamicRowData[rowIndex].map(rId => calendarProps.reservations[rId])
    return some(rowRsvns, r => {
      // checking for clash
      if (
        (checkDateInBetween(rToCheck.checkIn, rToCheck.checkOut, r.checkIn, '[)')) ||
        (checkDateInBetween(rToCheck.checkIn, rToCheck.checkOut, r.checkOut, '(]')) ||
        (checkDateInBetween(r.checkIn, r.checkOut, rToCheck.checkIn, '[)')) ||
        (checkDateInBetween(r.checkIn, r.checkOut, rToCheck.checkOut, '(]'))
      ) return true
    })
  }

  // Calculating how many rows are required to show the reservations and put the
  // reservations in their rows
  forEach(unallocatedReservationIds, uRId => {
    const unallocResv = calendarProps.reservations[uRId]
    for (let i = 0; ; i++) {
      const clashing = isClashing(i, unallocResv)
      if (!clashing) {
        if (unallocDynamicRowData[i]) unallocDynamicRowData[i].push(uRId)
        else unallocDynamicRowData[i] = [uRId]
        break
      }
    }
  })

  // adding an extra empty row to ensure an always present empty space
  // for users to move reservations into
  unallocDynamicRowData.push([])

  return (
    <>
      {/* -----------------Unallocated Header-------------------- */}
      <Grid item xs={12} sm={12} container>
        <Grid item xs={3} sm={3} md={2} className={classes.roomType}>
          <span style={{
            backgroundColor: '#ffa3a3',
            padding: '3px',
            borderRadius: '5%'
          }}
          >{UNALLOCATED}
          </span>
        </Grid>
      </Grid>

      {/* -----------------Unallocated Rows-------------------- */}
      {unallocDynamicRowData.map((unallocRsvnIds, rowIndex) => (
        <React.Fragment key={'unallocatedBlock' + rowIndex}>
          <Grid container className={classes.roomRow}>
            <Grid item xs={3} sm={3} md={2}>
              <div className={clsx({ [classes.roomBuildingFontSize]: filterType !== 'roomType' }, classes.room)}>
                Row {rowIndex + 1}
              </div>
            </Grid>
            <Grid item xs={9} sm={9} md={10}>
              <CalenderRoomReservations
                reservationKeys={unallocRsvnIds}
                bedInfo={{}}
                roomName=''
                bedName={UNALLOCATED}
                {...calendarProps}
              />
            </Grid>
          </Grid>
        </React.Fragment>))}
      <div className={classes.roomsDivider} />
    </>
  )
}

const mapStateToProps = state => ({
  propertyID: state.property && state.property._id
})

export default connect(mapStateToProps)(UnallocatedReservationRender)
