import React, { useEffect } from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";

import {
  fetchPacePropertyMapID,
  addPacePropertyMapID,
} from "../../redux/actions/paceRMS/paceRMS";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    // minHeight: 'calc(100% - 64px)',
    // maxHeight: 'calc(100% - 64px)',
    minHeight: "310px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "20%",
  },
  listSection: {
    // height: 'calc(100vh - 186px)',
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //   height: 'calc(100vh - 110px)',
    // },
    // position: 'relative'
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
    marginTop: 10,
  },
  configSection: {
    position: "relative",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px 0px 0px 10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  link: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontSize: "1.3rem",
      fontWeight: 600,
    },
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  textInputContainer: {
    display: "flex",
    marginTop: "10px",
  },
  disableScroll: {
    overflowY: "hidden",
  },
  noRoomsMsg: {
    display: "flex",
    alignItems: "center",
    height: "200px",
    justifyContent: "center",
    "& .message": {
      color: "#A2A2A2",
      fontSize: "1.3rem",
      lineHeight: 2,
      marginRight: 10,
    },
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
  updateContainer: {
    display: "flex",
    alignItems: "center",
  },
  updateSection: {
    display: "flex",
    alignItems: "center",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  status: {
    fontWeight: 600,
    "& span": {
      fontWeight: 500,
    },
  },
}));

const PaceRMS = (props) => {
  const classes = useStyles();
  const { open, handleClose, currentSpace, isLoading, t } = props;
  const [form, setForm] = React.useState({ propertyIDKey: "" });
  const [getPacePropertyMapID, setPacePropertyMapID] = React.useState(false);
  const propertyID = currentSpace.propertyID;

  const init = () => {
    let pacePropertyIDExist = props.dispatch(
      fetchPacePropertyMapID(propertyID)
    );

    pacePropertyIDExist.then((res) => {
      if (res) {
        setPacePropertyMapID(true);
      }
    });
  };
  useEffect(() => init(), []);

  const addPropertyIDHandler = () => {
    const params = {
      propertyID,
      pacePropertyID: form.propertyIDKey,
    };
    const response = props.dispatch(addPacePropertyMapID(propertyID, params));
    response.then((res) => {
      setPacePropertyMapID(true);
    });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCloseError = () => {
    props.dispatch(resetError("FETCH_PACE_PROPERTY_MAP_ID"));
  };

  const getPaceRMSStatus = () => {
    if (!getPacePropertyMapID)
      return "Disconnected (Please enter Pace RMS Property ID)";
    if (getPacePropertyMapID) return "Connected";
    return "";
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={clsx({ [classes.dialog]: true })}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Pace RMS</div>
          <AccessHeaderAction moduleID={MODULE.PACE_RMS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {/* --------------------------------------------------------- */}
        {/* MAIN CONTENT */}
        {/* --------------------------------------------------------- */}

        <div
          className={clsx({
            [classes.listSection]: true,
            [classes.disableScroll]: isLoading,
          })}
        >
          <div className={classes.configSection}>
            {isLoading && (
              <div className={classes.loadingState}>
                <CircularProgress className={classes.loader} />
              </div>
            )}

            <div className={classes.row}>
              {/* property id is not added */}
              {
                <div>
                  <div className={classes.title}>
                    {"Property ID" || t("automate.paceRMS.apiKey")}
                  </div>
                  <div className={classes.label}>
                    {"Enter Property ID" || t("automate.paceRMS.uniqueKey")}
                  </div>
                </div>
              }

              {
                <div className={classes.textInputContainer}>
                  <div className={clsx(classes.field, classes.wide)}>
                    <InputBase
                      id="propertyIDKey"
                      name="propertyIDKey"
                      fullWidth
                      type="text"
                      onKeyUp={handleChange}
                    />
                  </div>
                  <Button variant="outlined" onClick={addPropertyIDHandler}>
                    ADD
                  </Button>
                </div>
              }
            </div>

            <div className={classes.status}>
              Status: <span>{getPaceRMSStatus()}</span>
            </div>
          </div>
        </div>
      </div>

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSpaceID: state.dashboard.currentSpace,
    currentSpace: state.spaces[state.dashboard.currentSpace],
    property: state.property,
    isLoading: state.loading.FETCH_PACE_PROPERTY_MAP_ID,
  };
};

export default withTranslation()(connect(mapStateToProps)(PaceRMS));
