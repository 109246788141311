const cityCodes = [
  {label:'MEDELLÍN', value:5001},
  {label:'ABEJORRAL', value:5002},
  {label:'ABRIAQUÍ', value:5004},
  {label:'ALEJANDRÍA', value:5021},
  {label:'AMAGÁ', value:5030},
  {label:'AMALFI', value:5031},
  {label:'ANDES', value:5034},
  {label:'ANGELÓPOLIS', value:5036},
  {label:'ANGOSTURA', value:5038},
  {label:'ANORÍ', value:5040},
  {label:'SANTAFÉ DE ANTIOQUIA', value:5042},
  {label:'ANZA', value:5044},
  {label:'APARTADÓ', value:5045},
  {label:'ARBOLETES', value:5051},
  {label:'ARGELIA', value:5055},
  {label:'ARMENIA', value:5059},
  {label:'BARBOSA', value:5079},
  {label:'BELMIRA', value:5086},
  {label:'BELLO', value:5088},
  {label:'BETANIA', value:5091},
  {label:'BETULIA', value:5093},
  {label:'CIUDAD BOLÍVAR', value:5101},
  {label:'BRICEÑO', value:5107},
  {label:'BURITICÁ', value:5113},
  {label:'CÁCERES', value:5120},
  {label:'CAICEDO', value:5125},
  {label:'CALDAS', value:5129},
  {label:'CAMPAMENTO', value:5134},
  {label:'CAÑASGORDAS', value:5138},
  {label:'CARACOLÍ', value:5142},
  {label:'CARAMANTA', value:5145},
  {label:'CAREPA', value:5147},
  {label:'EL CARMEN DE VIBORAL', value:5148},
  {label:'CAROLINA', value:5150},
  {label:'CAUCASIA', value:5154},
  {label:'CHIGORODÓ', value:5172},
  {label:'CISNEROS', value:5190},
  {label:'COCORNÁ', value:5197},
  {label:'CONCEPCIÓN', value:5206},
  {label:'CONCORDIA', value:5209},
  {label:'COPACABANA', value:5212},
  {label:'DABEIBA', value:5234},
  {label:'DONMATÍAS', value:5237},
  {label:'EBÉJICO', value:5240},
  {label:'EL BAGRE', value:5250},
  {label:'ENTRERRIOS', value:5264},
  {label:'ENVIGADO', value:5266},
  {label:'FREDONIA', value:5282},
  {label:'FRONTINO', value:5284},
  {label:'GIRALDO', value:5306},
  {label:'GIRARDOTA', value:5308},
  {label:'GÓMEZ PLATA', value:5310},
  {label:'GRANADA', value:5313},
  {label:'GUADALUPE', value:5315},
  {label:'GUARNE', value:5318},
  {label:'GUATAPE', value:5321},
  {label:'HELICONIA', value:5347},
  {label:'HISPANIA', value:5353},
  {label:'ITAGUI', value:5360},
  {label:'ITUANGO', value:5361},
  {label:'JARDÍN', value:5364},
  {label:'JERICÓ', value:5368},
  {label:'LA CEJA', value:5376},
  {label:'LA ESTRELLA', value:5380},
  {label:'LA PINTADA', value:5390},
  {label:'LA UNIÓN', value:5400},
  {label:'LIBORINA', value:5411},
  {label:'MACEO', value:5425},
  {label:'MARINILLA', value:5440},
  {label:'MONTEBELLO', value:5467},
  {label:'MURINDÓ', value:5475},
  {label:'MUTATÁ', value:5480},
  {label:'NARIÑO', value:5483},
  {label:'NECOCLÍ', value:5490},
  {label:'NECHÍ', value:5495},
  {label:'OLAYA', value:5501},
  {label:'PEÑOL', value:5541},
  {label:'PEQUE', value:5543},
  {label:'PUEBLORRICO', value:5576},
  {label:'PUERTO BERRÍO', value:5579},
  {label:'PUERTO NARE', value:5585},
  {label:'PUERTO TRIUNFO', value:5591},
  {label:'REMEDIOS', value:5604},
  {label:'RETIRO', value:5607},
  {label:'RIONEGRO', value:5615},
  {label:'SABANALARGA', value:5628},
  {label:'SABANETA', value:5631},
  {label:'SALGAR', value:5642},
  {label:'SAN ANDRÉS DE CUERQUÍA', value:5647},
  {label:'SAN CARLOS', value:5649},
  {label:'SAN FRANCISCO', value:5652},
  {label:'SAN JERÓNIMO', value:5656},
  {label:'SAN JOSÉ DE LA MONTAÑA', value:5658},
  {label:'SAN JUAN DE URABÁ', value:5659},
  {label:'SAN LUIS', value:5660},
  {label:'SAN PEDRO DE LOS MILAGROS', value:5664},
  {label:'SAN PEDRO DE URABA', value:5665},
  {label:'SAN RAFAEL', value:5667},
  {label:'SAN ROQUE', value:5670},
  {label:'SAN VICENTE FERRER', value:5674},
  {label:'SANTA BÁRBARA', value:5679},
  {label:'SANTA ROSA DE OSOS', value:5686},
  {label:'SANTO DOMINGO', value:5690},
  {label:'EL SANTUARIO', value:5697},
  {label:'SEGOVIA', value:5736},
  {label:'SONSON', value:5756},
  {label:'SOPETRÁN', value:5761},
  {label:'TÁMESIS', value:5789},
  {label:'TARAZÁ', value:5790},
  {label:'TARSO', value:5792},
  {label:'TITIRIBÍ', value:5809},
  {label:'TOLEDO', value:5819},
  {label:'TURBO', value:5837},
  {label:'URAMITA', value:5842},
  {label:'URRAO', value:5847},
  {label:'VALDIVIA', value:5854},
  {label:'VALPARAÍSO', value:5856},
  {label:'VEGACHÍ', value:5858},
  {label:'VENECIA', value:5861},
  {label:'VIGÍA DEL FUERTE', value:5873},
  {label:'YALÍ', value:5885},
  {label:'YARUMAL', value:5887},
  {label:'YOLOMBÓ', value:5890},
  {label:'YONDÓ', value:5893},
  {label:'ZARAGOZA', value:5895},
  {label:'BARRANQUILLA', value:8001},
  {label:'BARANOA', value:8078},
  {label:'CAMPO DE LA CRUZ', value:8137},
  {label:'CANDELARIA', value:8141},
  {label:'GALAPA', value:8296},
  {label:'JUAN DE ACOSTA', value:8372},
  {label:'LURUACO', value:8421},
  {label:'MALAMBO', value:8433},
  {label:'MANATÍ', value:8436},
  {label:'PALMAR DE VARELA', value:8520},
  {label:'PIOJÓ', value:8549},
  {label:'POLONUEVO', value:8558},
  {label:'PONEDERA', value:8560},
  {label:'PUERTO COLOMBIA', value:8573},
  {label:'REPELÓN', value:8606},
  {label:'SABANAGRANDE', value:8634},
  {label:'SABANALARGA', value:8638},
  {label:'SANTA LUCÍA', value:8675},
  {label:'SANTO TOMÁS', value:8685},
  {label:'SOLEDAD', value:8758},
  {label:'SUAN', value:8770},
  {label:'TUBARÁ', value:8832},
  {label:'USIACURÍ', value:8849},
  {label:'BOGOTÁ, D.C.', value:11001},
  {label:'CARTAGENA', value:13001},
  {label:'ACHÍ', value:13006},
  {label:'ALTOS DEL ROSARIO', value:13030},
  {label:'ARENAL', value:13042},
  {label:'ARJONA', value:13052},
  {label:'ARROYOHONDO', value:13062},
  {label:'BARRANCO DE LOBA', value:13074},
  {label:'CALAMAR', value:13140},
  {label:'CANTAGALLO', value:13160},
  {label:'CICUCO', value:13188},
  {label:'CÓRDOBA', value:13212},
  {label:'CLEMENCIA', value:13222},
  {label:'EL CARMEN DE BOLÍVAR', value:13244},
  {label:'EL GUAMO', value:13248},
  {label:'EL PEÑÓN', value:13268},
  {label:'HATILLO DE LOBA', value:13300},
  {label:'MAGANGUÉ', value:13430},
  {label:'MAHATES', value:13433},
  {label:'MARGARITA', value:13440},
  {label:'MARÍA LA BAJA', value:13442},
  {label:'MONTECRISTO', value:13458},
  {label:'MOMPÓS', value:13468},
  {label:'MORALES', value:13473},
  {label:'NOROSÍ', value:13490},
  {label:'PINILLOS', value:13549},
  {label:'REGIDOR', value:13580},
  {label:'RÍO VIEJO', value:13600},
  {label:'SAN CRISTÓBAL', value:13620},
  {label:'SAN ESTANISLAO', value:13647},
  {label:'SAN FERNANDO', value:13650},
  {label:'SAN JACINTO', value:13654},
  {label:'SAN JACINTO DEL CAUCA', value:13655},
  {label:'SAN JUAN NEPOMUCENO', value:13657},
  {label:'SAN MARTÍN DE LOBA', value:13667},
  {label:'SAN PABLO', value:13670},
  {label:'SANTA CATALINA', value:13673},
  {label:'SANTA ROSA', value:13683},
  {label:'SANTA ROSA DEL SUR', value:13688},
  {label:'SIMITÍ', value:13744},
  {label:'SOPLAVIENTO', value:13760},
  {label:'TALAIGUA NUEVO', value:13780},
  {label:'TIQUISIO', value:13810},
  {label:'TURBACO', value:13836},
  {label:'TURBANÁ', value:13838},
  {label:'VILLANUEVA', value:13873},
  {label:'ZAMBRANO', value:13894},
  {label:'TUNJA', value:15001},
  {label:'ALMEIDA', value:15022},
  {label:'AQUITANIA', value:15047},
  {label:'ARCABUCO', value:15051},
  {label:'BELÉN', value:15087},
  {label:'BERBEO', value:15090},
  {label:'BETÉITIVA', value:15092},
  {label:'BOAVITA', value:15097},
  {label:'BOYACÁ', value:15104},
  {label:'BRICEÑO', value:15106},
  {label:'BUENAVISTA', value:15109},
  {label:'BUSBANZÁ', value:15114},
  {label:'CALDAS', value:15131},
  {label:'CAMPOHERMOSO', value:15135},
  {label:'CERINZA', value:15162},
  {label:'CHINAVITA', value:15172},
  {label:'CHIQUINQUIRÁ', value:15176},
  {label:'CHISCAS', value:15180},
  {label:'CHITA', value:15183},
  {label:'CHITARAQUE', value:15185},
  {label:'CHIVATÁ', value:15187},
  {label:'CIÉNEGA', value:15189},
  {label:'CÓMBITA', value:15204},
  {label:'COPER', value:15212},
  {label:'CORRALES', value:15215},
  {label:'COVARACHÍA', value:15218},
  {label:'CUBARÁ', value:15223},
  {label:'CUCAITA', value:15224},
  {label:'CUÍTIVA', value:15226},
  {label:'CHÍQUIZA', value:15232},
  {label:'CHIVOR', value:15236},
  {label:'DUITAMA', value:15238},
  {label:'EL COCUY', value:15244},
  {label:'EL ESPINO', value:15248},
  {label:'FIRAVITOBA', value:15272},
  {label:'FLORESTA', value:15276},
  {label:'GACHANTIVÁ', value:15293},
  {label:'GAMEZA', value:15296},
  {label:'GARAGOA', value:15299},
  {label:'GUACAMAYAS', value:15317},
  {label:'GUATEQUE', value:15322},
  {label:'GUAYATÁ', value:15325},
  {label:'GÜICÁN', value:15332},
  {label:'IZA', value:15362},
  {label:'JENESANO', value:15367},
  {label:'JERICÓ', value:15368},
  {label:'LABRANZAGRANDE', value:15377},
  {label:'LA CAPILLA', value:15380},
  {label:'LA VICTORIA', value:15401},
  {label:'LA UVITA', value:15403},
  {label:'VILLA DE LEYVA', value:15407},
  {label:'MACANAL', value:15425},
  {label:'MARIPÍ', value:15442},
  {label:'MIRAFLORES', value:15455},
  {label:'MONGUA', value:15464},
  {label:'MONGUÍ', value:15466},
  {label:'MONIQUIRÁ', value:15469},
  {label:'MOTAVITA', value:15476},
  {label:'MUZO', value:15480},
  {label:'NOBSA', value:15491},
  {label:'NUEVO COLÓN', value:15494},
  {label:'OICATÁ', value:15500},
  {label:'OTANCHE', value:15507},
  {label:'PACHAVITA', value:15511},
  {label:'PÁEZ', value:15514},
  {label:'PAIPA', value:15516},
  {label:'PAJARITO', value:15518},
  {label:'PANQUEBA', value:15522},
  {label:'PAUNA', value:15531},
  {label:'PAYA', value:15533},
  {label:'PAZ DE RÍO', value:15537},
  {label:'PESCA', value:15542},
  {label:'PISBA', value:15550},
  {label:'PUERTO BOYACÁ', value:15572},
  {label:'QUÍPAMA', value:15580},
  {label:'RAMIRIQUÍ', value:15599},
  {label:'RÁQUIRA', value:15600},
  {label:'RONDÓN', value:15621},
  {label:'SABOYÁ', value:15632},
  {label:'SÁCHICA', value:15638},
  {label:'SAMACÁ', value:15646},
  {label:'SAN EDUARDO', value:15660},
  {label:'SAN JOSÉ DE PARE', value:15664},
  {label:'SAN LUIS DE GACENO', value:15667},
  {label:'SAN MATEO', value:15673},
  {label:'SAN MIGUEL DE SEMA', value:15676},
  {label:'SAN PABLO DE BORBUR', value:15681},
  {label:'SANTANA', value:15686},
  {label:'SANTA MARÍA', value:15690},
  {label:'SANTA ROSA DE VITERBO', value:15693},
  {label:'SANTA SOFÍA', value:15696},
  {label:'SATIVANORTE', value:15720},
  {label:'SATIVASUR', value:15723},
  {label:'SIACHOQUE', value:15740},
  {label:'SOATÁ', value:15753},
  {label:'SOCOTÁ', value:15755},
  {label:'SOCHA', value:15757},
  {label:'SOGAMOSO', value:15759},
  {label:'SOMONDOCO', value:15761},
  {label:'SORA', value:15762},
  {label:'SOTAQUIRÁ', value:15763},
  {label:'SORACÁ', value:15764},
  {label:'SUSACÓN', value:15774},
  {label:'SUTAMARCHÁN', value:15776},
  {label:'SUTATENZA', value:15778},
  {label:'TASCO', value:15790},
  {label:'TENZA', value:15798},
  {label:'TIBANÁ', value:15804},
  {label:'TIBASOSA', value:15806},
  {label:'TINJACÁ', value:15808},
  {label:'TIPACOQUE', value:15810},
  {label:'TOCA', value:15814},
  {label:'TOGÜÍ', value:15816},
  {label:'TÓPAGA', value:15820},
  {label:'TOTA', value:15822},
  {label:'TUNUNGUÁ', value:15832},
  {label:'TURMEQUÉ', value:15835},
  {label:'TUTA', value:15837},
  {label:'TUTAZÁ', value:15839},
  {label:'UMBITA', value:15842},
  {label:'VENTAQUEMADA', value:15861},
  {label:'VIRACACHÁ', value:15879},
  {label:'ZETAQUIRA', value:15897},
  {label:'MANIZALES', value:17001},
  {label:'AGUADAS', value:17013},
  {label:'ANSERMA', value:17042},
  {label:'ARANZAZU', value:17050},
  {label:'BELALCÁZAR', value:17088},
  {label:'CHINCHINÁ', value:17174},
  {label:'FILADELFIA', value:17272},
  {label:'LA DORADA', value:17380},
  {label:'LA MERCED', value:17388},
  {label:'MANZANARES', value:17433},
  {label:'MARMATO', value:17442},
  {label:'MARQUETALIA', value:17444},
  {label:'MARULANDA', value:17446},
  {label:'NEIRA', value:17486},
  {label:'NORCASIA', value:17495},
  {label:'PÁCORA', value:17513},
  {label:'PALESTINA', value:17524},
  {label:'PENSILVANIA', value:17541},
  {label:'RIOSUCIO', value:17614},
  {label:'RISARALDA', value:17616},
  {label:'SALAMINA', value:17653},
  {label:'SAMANÁ', value:17662},
  {label:'SAN JOSÉ', value:17665},
  {label:'SUPÍA', value:17777},
  {label:'VICTORIA', value:17867},
  {label:'VILLAMARÍA', value:17873},
  {label:'VITERBO', value:17877},
  {label:'FLORENCIA', value:18001},
  {label:'ALBANIA', value:18029},
  {label:'BELÉN DE LOS ANDAQUÍES', value:18094},
  {label:'CARTAGENA DEL CHAIRÁ', value:18150},
  {label:'CURILLO', value:18205},
  {label:'EL DONCELLO', value:18247},
  {label:'EL PAUJIL', value:18256},
  {label:'LA MONTAÑITA', value:18410},
  {label:'MILÁN', value:18460},
  {label:'MORELIA', value:18479},
  {label:'PUERTO RICO', value:18592},
  {label:'SAN JOSÉ DEL FRAGUA', value:18610},
  {label:'SAN VICENTE DEL CAGUÁN', value:18753},
  {label:'SOLANO', value:18756},
  {label:'SOLITA', value:18785},
  {label:'VALPARAÍSO', value:18860},
  {label:'POPAYÁN', value:19001},
  {label:'ALMAGUER', value:19022},
  {label:'ARGELIA', value:19050},
  {label:'BALBOA', value:19075},
  {label:'BOLÍVAR', value:19100},
  {label:'BUENOS AIRES', value:19110},
  {label:'CAJIBÍO', value:19130},
  {label:'CALDONO', value:19137},
  {label:'CALOTO', value:19142},
  {label:'CORINTO', value:19212},
  {label:'EL TAMBO', value:19256},
  {label:'FLORENCIA', value:19290},
  {label:'GUACHENÉ', value:19300},
  {label:'GUAPI', value:19318},
  {label:'INZÁ', value:19355},
  {label:'JAMBALÓ', value:19364},
  {label:'LA SIERRA', value:19392},
  {label:'LA VEGA', value:19397},
  {label:'LÓPEZ', value:19418},
  {label:'MERCADERES', value:19450},
  {label:'MIRANDA', value:19455},
  {label:'MORALES', value:19473},
  {label:'PADILLA', value:19513},
  {label:'PAEZ', value:19517},
  {label:'PATÍA', value:19532},
  {label:'PIAMONTE', value:19533},
  {label:'PIENDAMÓ', value:19548},
  {label:'PUERTO TEJADA', value:19573},
  {label:'PURACÉ', value:19585},
  {label:'ROSAS', value:19622},
  {label:'SAN SEBASTIÁN', value:19693},
  {label:'SANTANDER DE QUILICHAO', value:19698},
  {label:'SANTA ROSA', value:19701},
  {label:'SILVIA', value:19743},
  {label:'SOTARA', value:19760},
  {label:'SUÁREZ', value:19780},
  {label:'SUCRE', value:19785},
  {label:'TIMBÍO', value:19807},
  {label:'TIMBIQUÍ', value:19809},
  {label:'TORIBIO', value:19821},
  {label:'TOTORÓ', value:19824},
  {label:'VILLA RICA', value:19845},
  {label:'VALLEDUPAR', value:20001},
  {label:'AGUACHICA', value:20011},
  {label:'AGUSTÍN CODAZZI', value:20013},
  {label:'ASTREA', value:20032},
  {label:'BECERRIL', value:20045},
  {label:'BOSCONIA', value:20060},
  {label:'CHIMICHAGUA', value:20175},
  {label:'CHIRIGUANÁ', value:20178},
  {label:'CURUMANÍ', value:20228},
  {label:'EL COPEY', value:20238},
  {label:'EL PASO', value:20250},
  {label:'GAMARRA', value:20295},
  {label:'GONZÁLEZ', value:20310},
  {label:'LA GLORIA', value:20383},
  {label:'LA JAGUA DE IBIRICO', value:20400},
  {label:'MANAURE', value:20443},
  {label:'PAILITAS', value:20517},
  {label:'PELAYA', value:20550},
  {label:'PUEBLO BELLO', value:20570},
  {label:'RÍO DE ORO', value:20614},
  {label:'LA PAZ', value:20621},
  {label:'SAN ALBERTO', value:20710},
  {label:'SAN DIEGO', value:20750},
  {label:'SAN MARTÍN', value:20770},
  {label:'TAMALAMEQUE', value:20787},
  {label:'MONTERÍA', value:23001},
  {label:'AYAPEL', value:23068},
  {label:'BUENAVISTA', value:23079},
  {label:'CANALETE', value:23090},
  {label:'CERETÉ', value:23162},
  {label:'CHIMÁ', value:23168},
  {label:'CHINÚ', value:23182},
  {label:'CIÉNAGA DE ORO', value:23189},
  {label:'COTORRA', value:23300},
  {label:'LA APARTADA', value:23350},
  {label:'LORICA', value:23417},
  {label:'LOS CÓRDOBAS', value:23419},
  {label:'MOMIL', value:23464},
  {label:'MONTELÍBANO', value:23466},
  {label:'MOÑITOS', value:23500},
  {label:'PLANETA RICA', value:23555},
  {label:'PUEBLO NUEVO', value:23570},
  {label:'PUERTO ESCONDIDO', value:23574},
  {label:'PUERTO LIBERTADOR', value:23580},
  {label:'PURÍSIMA', value:23586},
  {label:'SAHAGÚN', value:23660},
  {label:'SAN ANDRÉS DE SOTAVENTO', value:23670},
  {label:'SAN ANTERO', value:23672},
  {label:'SAN BERNARDO DEL VIENTO', value:23675},
  {label:'SAN CARLOS', value:23678},
  {label:'SAN JOSÉ DE URÉ', value:23682},
  {label:'SAN PELAYO', value:23686},
  {label:'TIERRALTA', value:23807},
  {label:'TUCHÍN', value:23815},
  {label:'VALENCIA', value:23855},
  {label:'AGUA DE DIOS', value:25001},
  {label:'ALBÁN', value:25019},
  {label:'ANAPOIMA', value:25035},
  {label:'ANOLAIMA', value:25040},
  {label:'ARBELÁEZ', value:25053},
  {label:'BELTRÁN', value:25086},
  {label:'BITUIMA', value:25095},
  {label:'BOJACÁ', value:25099},
  {label:'CABRERA', value:25120},
  {label:'CACHIPAY', value:25123},
  {label:'CAJICÁ', value:25126},
  {label:'CAPARRAPÍ', value:25148},
  {label:'CAQUEZA', value:25151},
  {label:'CARMEN DE CARUPA', value:25154},
  {label:'CHAGUANÍ', value:25168},
  {label:'CHÍA', value:25175},
  {label:'CHIPAQUE', value:25178},
  {label:'CHOACHÍ', value:25181},
  {label:'CHOCONTÁ', value:25183},
  {label:'COGUA', value:25200},
  {label:'COTA', value:25214},
  {label:'CUCUNUBÁ', value:25224},
  {label:'EL COLEGIO', value:25245},
  {label:'EL PEÑÓN', value:25258},
  {label:'EL ROSAL', value:25260},
  {label:'FACATATIVÁ', value:25269},
  {label:'FOMEQUE', value:25279},
  {label:'FOSCA', value:25281},
  {label:'FUNZA', value:25286},
  {label:'FÚQUENE', value:25288},
  {label:'FUSAGASUGÁ', value:25290},
  {label:'GACHALA', value:25293},
  {label:'GACHANCIPÁ', value:25295},
  {label:'GACHETÁ', value:25297},
  {label:'GAMA', value:25299},
  {label:'GIRARDOT', value:25307},
  {label:'GRANADA', value:25312},
  {label:'GUACHETÁ', value:25317},
  {label:'GUADUAS', value:25320},
  {label:'GUASCA', value:25322},
  {label:'GUATAQUÍ', value:25324},
  {label:'GUATAVITA', value:25326},
  {label:'GUAYABAL DE SIQUIMA', value:25328},
  {label:'GUAYABETAL', value:25335},
  {label:'GUTIÉRREZ', value:25339},
  {label:'JERUSALÉN', value:25368},
  {label:'JUNÍN', value:25372},
  {label:'LA CALERA', value:25377},
  {label:'LA MESA', value:25386},
  {label:'LA PALMA', value:25394},
  {label:'LA PEÑA', value:25398},
  {label:'LA VEGA', value:25402},
  {label:'LENGUAZAQUE', value:25407},
  {label:'MACHETA', value:25426},
  {label:'MADRID', value:25430},
  {label:'MANTA', value:25436},
  {label:'MEDINA', value:25438},
  {label:'MOSQUERA', value:25473},
  {label:'NARIÑO', value:25483},
  {label:'NEMOCÓN', value:25486},
  {label:'NILO', value:25488},
  {label:'NIMAIMA', value:25489},
  {label:'NOCAIMA', value:25491},
  {label:'VENECIA', value:25506},
  {label:'PACHO', value:25513},
  {label:'PAIME', value:25518},
  {label:'PANDI', value:25524},
  {label:'PARATEBUENO', value:25530},
  {label:'PASCA', value:25535},
  {label:'PUERTO SALGAR', value:25572},
  {label:'PULÍ', value:25580},
  {label:'QUEBRADANEGRA', value:25592},
  {label:'QUETAME', value:25594},
  {label:'QUIPILE', value:25596},
  {label:'APULO', value:25599},
  {label:'RICAURTE', value:25612},
  {label:'SAN ANTONIO DEL TEQUENDAMA', value:25645},
  {label:'SAN BERNARDO', value:25649},
  {label:'SAN CAYETANO', value:25653},
  {label:'SAN FRANCISCO', value:25658},
  {label:'SAN JUAN DE RÍO SECO', value:25662},
  {label:'SASAIMA', value:25718},
  {label:'SESQUILÉ', value:25736},
  {label:'SIBATÉ', value:25740},
  {label:'SILVANIA', value:25743},
  {label:'SIMIJACA', value:25745},
  {label:'SOACHA', value:25754},
  {label:'SOPÓ', value:25758},
  {label:'SUBACHOQUE', value:25769},
  {label:'SUESCA', value:25772},
  {label:'SUPATÁ', value:25777},
  {label:'SUSA', value:25779},
  {label:'SUTATAUSA', value:25781},
  {label:'TABIO', value:25785},
  {label:'TAUSA', value:25793},
  {label:'TENA', value:25797},
  {label:'TENJO', value:25799},
  {label:'TIBACUY', value:25805},
  {label:'TIBIRITA', value:25807},
  {label:'TOCAIMA', value:25815},
  {label:'TOCANCIPÁ', value:25817},
  {label:'TOPAIPÍ', value:25823},
  {label:'UBALÁ', value:25839},
  {label:'UBAQUE', value:25841},
  {label:'VILLA DE SAN DIEGO DE UBATE', value:25843},
  {label:'UNE', value:25845},
  {label:'ÚTICA', value:25851},
  {label:'VERGARA', value:25862},
  {label:'VIANÍ', value:25867},
  {label:'VILLAGÓMEZ', value:25871},
  {label:'VILLAPINZÓN', value:25873},
  {label:'VILLETA', value:25875},
  {label:'VIOTÁ', value:25878},
  {label:'YACOPÍ', value:25885},
  {label:'ZIPACÓN', value:25898},
  {label:'ZIPAQUIRÁ', value:25899},
  {label:'QUIBDÓ', value:27001},
  {label:'ACANDÍ', value:27006},
  {label:'ALTO BAUDÓ', value:27025},
  {label:'ATRATO', value:27050},
  {label:'BAGADÓ', value:27073},
  {label:'BAHÍA SOLANO', value:27075},
  {label:'BAJO BAUDÓ', value:27077},
  {label:'BOJAYA', value:27099},
  {label:'EL CANTÓN DEL SAN PABLO', value:27135},
  {label:'CARMEN DEL DARIÉN', value:27150},
  {label:'CÉRTEGUI', value:27160},
  {label:'CONDOTO', value:27205},
  {label:'EL CARMEN DE ATRATO', value:27245},
  {label:'EL LITORAL DEL SAN JUAN', value:27250},
  {label:'ISTMINA', value:27361},
  {label:'JURADÓ', value:27372},
  {label:'LLORÓ', value:27413},
  {label:'MEDIO ATRATO', value:27425},
  {label:'MEDIO BAUDÓ', value:27430},
  {label:'MEDIO SAN JUAN', value:27450},
  {label:'NÓVITA', value:27491},
  {label:'NUQUÍ', value:27495},
  {label:'RÍO IRÓ', value:27580},
  {label:'RÍO QUITO', value:27600},
  {label:'RIOSUCIO', value:27615},
  {label:'SAN JOSÉ DEL PALMAR', value:27660},
  {label:'SIPÍ', value:27745},
  {label:'TADÓ', value:27787},
  {label:'UNGUÍA', value:27800},
  {label:'UNIÓN PANAMERICANA', value:27810},
  {label:'NEIVA', value:41001},
  {label:'ACEVEDO', value:41006},
  {label:'AGRADO', value:41013},
  {label:'AIPE', value:41016},
  {label:'ALGECIRAS', value:41020},
  {label:'ALTAMIRA', value:41026},
  {label:'BARAYA', value:41078},
  {label:'CAMPOALEGRE', value:41132},
  {label:'COLOMBIA', value:41206},
  {label:'ELÍAS', value:41244},
  {label:'GARZÓN', value:41298},
  {label:'GIGANTE', value:41306},
  {label:'GUADALUPE', value:41319},
  {label:'HOBO', value:41349},
  {label:'IQUIRA', value:41357},
  {label:'ISNOS', value:41359},
  {label:'LA ARGENTINA', value:41378},
  {label:'LA PLATA', value:41396},
  {label:'NÁTAGA', value:41483},
  {label:'OPORAPA', value:41503},
  {label:'PAICOL', value:41518},
  {label:'PALERMO', value:41524},
  {label:'PALESTINA', value:41530},
  {label:'PITAL', value:41548},
  {label:'PITALITO', value:41551},
  {label:'RIVERA', value:41615},
  {label:'SALADOBLANCO', value:41660},
  {label:'SAN AGUSTÍN', value:41668},
  {label:'SANTA MARÍA', value:41676},
  {label:'SUAZA', value:41770},
  {label:'TARQUI', value:41791},
  {label:'TESALIA', value:41797},
  {label:'TELLO', value:41799},
  {label:'TERUEL', value:41801},
  {label:'TIMANÁ', value:41807},
  {label:'VILLAVIEJA', value:41872},
  {label:'YAGUARÁ', value:41885},
  {label:'RIOHACHA', value:44001},
  {label:'ALBANIA', value:44035},
  {label:'BARRANCAS', value:44078},
  {label:'DIBULLA', value:44090},
  {label:'DISTRACCIÓN', value:44098},
  {label:'EL MOLINO', value:44110},
  {label:'FONSECA', value:44279},
  {label:'HATONUEVO', value:44378},
  {label:'LA JAGUA DEL PILAR', value:44420},
  {label:'MAICAO', value:44430},
  {label:'MANAURE', value:44560},
  {label:'SAN JUAN DEL CESAR', value:44650},
  {label:'URIBIA', value:44847},
  {label:'URUMITA', value:44855},
  {label:'VILLANUEVA', value:44874},
  {label:'SANTA MARTA', value:47001},
  {label:'ALGARROBO', value:47030},
  {label:'ARACATACA', value:47053},
  {label:'ARIGUANÍ', value:47058},
  {label:'CERRO SAN ANTONIO', value:47161},
  {label:'CHIVOLO', value:47170},
  {label:'CIÉNAGA', value:47189},
  {label:'CONCORDIA', value:47205},
  {label:'EL BANCO', value:47245},
  {label:'EL PIÑON', value:47258},
  {label:'EL RETÉN', value:47268},
  {label:'FUNDACIÓN', value:47288},
  {label:'GUAMAL', value:47318},
  {label:'NUEVA GRANADA', value:47460},
  {label:'PEDRAZA', value:47541},
  {label:'PIJIÑO DEL CARMEN', value:47545},
  {label:'PIVIJAY', value:47551},
  {label:'PLATO', value:47555},
  {label:'PUEBLOVIEJO', value:47570},
  {label:'REMOLINO', value:47605},
  {label:'SABANAS DE SAN ANGEL', value:47660},
  {label:'SALAMINA', value:47675},
  {label:'SAN SEBASTIÁN DE BUENAVISTA', value:47692},
  {label:'SAN ZENÓN', value:47703},
  {label:'SANTA ANA', value:47707},
  {label:'SANTA BÁRBARA DE PINTO', value:47720},
  {label:'SITIONUEVO', value:47745},
  {label:'TENERIFE', value:47798},
  {label:'ZAPAYÁN', value:47960},
  {label:'ZONA BANANERA', value:47980},
  {label:'VILLAVICENCIO', value:50001},
  {label:'ACACÍAS', value:50006},
  {label:'BARRANCA DE UPÍA', value:50110},
  {label:'CABUYARO', value:50124},
  {label:'CASTILLA LA NUEVA', value:50150},
  {label:'SAN LUIS DE CUBARRAL', value:50223},
  {label:'CUMARAL', value:50226},
  {label:'EL CALVARIO', value:50245},
  {label:'EL CASTILLO', value:50251},
  {label:'EL DORADO', value:50270},
  {label:'FUENTE DE ORO', value:50287},
  {label:'GRANADA', value:50313},
  {label:'GUAMAL', value:50318},
  {label:'MAPIRIPÁN', value:50325},
  {label:'MESETAS', value:50330},
  {label:'LA MACARENA', value:50350},
  {label:'URIBE', value:50370},
  {label:'LEJANÍAS', value:50400},
  {label:'PUERTO CONCORDIA', value:50450},
  {label:'PUERTO GAITÁN', value:50568},
  {label:'PUERTO LÓPEZ', value:50573},
  {label:'PUERTO LLERAS', value:50577},
  {label:'PUERTO RICO', value:50590},
  {label:'RESTREPO', value:50606},
  {label:'SAN CARLOS DE GUAROA', value:50680},
  {label:'SAN JUAN DE ARAMA', value:50683},
  {label:'SAN JUANITO', value:50686},
  {label:'SAN MARTÍN', value:50689},
  {label:'VISTAHERMOSA', value:50711},
  {label:'PASTO', value:52001},
  {label:'ALBÁN', value:52019},
  {label:'ALDANA', value:52022},
  {label:'ANCUYÁ', value:52036},
  {label:'ARBOLEDA', value:52051},
  {label:'BARBACOAS', value:52079},
  {label:'BELÉN', value:52083},
  {label:'BUESACO', value:52110},
  {label:'COLÓN', value:52203},
  {label:'CONSACÁ', value:52207},
  {label:'CONTADERO', value:52210},
  {label:'CÓRDOBA', value:52215},
  {label:'CUASPUD', value:52224},
  {label:'CUMBAL', value:52227},
  {label:'CUMBITARA', value:52233},
  {label:'CHACHAGÜÍ', value:52240},
  {label:'EL CHARCO', value:52250},
  {label:'EL PEÑOL', value:52254},
  {label:'EL ROSARIO', value:52256},
  {label:'EL TABLÓN DE GÓMEZ', value:52258},
  {label:'EL TAMBO', value:52260},
  {label:'FUNES', value:52287},
  {label:'GUACHUCAL', value:52317},
  {label:'GUAITARILLA', value:52320},
  {label:'GUALMATÁN', value:52323},
  {label:'ILES', value:52352},
  {label:'IMUÉS', value:52354},
  {label:'IPIALES', value:52356},
  {label:'LA CRUZ', value:52378},
  {label:'LA FLORIDA', value:52381},
  {label:'LA LLANADA', value:52385},
  {label:'LA TOLA', value:52390},
  {label:'LA UNIÓN', value:52399},
  {label:'LEIVA', value:52405},
  {label:'LINARES', value:52411},
  {label:'LOS ANDES', value:52418},
  {label:'MAGÜI', value:52427},
  {label:'MALLAMA', value:52435},
  {label:'MOSQUERA', value:52473},
  {label:'NARIÑO', value:52480},
  {label:'OLAYA HERRERA', value:52490},
  {label:'OSPINA', value:52506},
  {label:'FRANCISCO PIZARRO', value:52520},
  {label:'POLICARPA', value:52540},
  {label:'POTOSÍ', value:52560},
  {label:'PROVIDENCIA', value:52565},
  {label:'PUERRES', value:52573},
  {label:'PUPIALES', value:52585},
  {label:'RICAURTE', value:52612},
  {label:'ROBERTO PAYÁN', value:52621},
  {label:'SAMANIEGO', value:52678},
  {label:'SANDONÁ', value:52683},
  {label:'SAN BERNARDO', value:52685},
  {label:'SAN LORENZO', value:52687},
  {label:'SAN PABLO', value:52693},
  {label:'SAN PEDRO DE CARTAGO', value:52694},
  {label:'SANTA BÁRBARA', value:52696},
  {label:'SANTACRUZ', value:52699},
  {label:'SAPUYES', value:52720},
  {label:'TAMINANGO', value:52786},
  {label:'TANGUA', value:52788},
  {label:'SAN ANDRES DE TUMACO', value:52835},
  {label:'TÚQUERRES', value:52838},
  {label:'YACUANQUER', value:52885},
  {label:'CÚCUTA', value:54001},
  {label:'ABREGO', value:54003},
  {label:'ARBOLEDAS', value:54051},
  {label:'BOCHALEMA', value:54099},
  {label:'BUCARASICA', value:54109},
  {label:'CÁCOTA', value:54125},
  {label:'CACHIRÁ', value:54128},
  {label:'CHINÁCOTA', value:54172},
  {label:'CHITAGÁ', value:54174},
  {label:'CONVENCIÓN', value:54206},
  {label:'CUCUTILLA', value:54223},
  {label:'DURANIA', value:54239},
  {label:'EL CARMEN', value:54245},
  {label:'EL TARRA', value:54250},
  {label:'EL ZULIA', value:54261},
  {label:'GRAMALOTE', value:54313},
  {label:'HACARÍ', value:54344},
  {label:'HERRÁN', value:54347},
  {label:'LABATECA', value:54377},
  {label:'LA ESPERANZA', value:54385},
  {label:'LA PLAYA', value:54398},
  {label:'LOS PATIOS', value:54405},
  {label:'LOURDES', value:54418},
  {label:'MUTISCUA', value:54480},
  {label:'OCAÑA', value:54498},
  {label:'PAMPLONA', value:54518},
  {label:'PAMPLONITA', value:54520},
  {label:'PUERTO SANTANDER', value:54553},
  {label:'RAGONVALIA', value:54599},
  {label:'SALAZAR', value:54660},
  {label:'SAN CALIXTO', value:54670},
  {label:'SAN CAYETANO', value:54673},
  {label:'SANTIAGO', value:54680},
  {label:'SARDINATA', value:54720},
  {label:'SILOS', value:54743},
  {label:'TEORAMA', value:54800},
  {label:'TIBÚ', value:54810},
  {label:'TOLEDO', value:54820},
  {label:'VILLA CARO', value:54871},
  {label:'VILLA DEL ROSARIO', value:54874},
  {label:'ARMENIA', value:63001},
  {label:'BUENAVISTA', value:63111},
  {label:'CALARCA', value:63130},
  {label:'CIRCASIA', value:63190},
  {label:'CÓRDOBA', value:63212},
  {label:'FILANDIA', value:63272},
  {label:'GÉNOVA', value:63302},
  {label:'LA TEBAIDA', value:63401},
  {label:'MONTENEGRO', value:63470},
  {label:'PIJAO', value:63548},
  {label:'QUIMBAYA', value:63594},
  {label:'SALENTO', value:63690},
  {label:'PEREIRA', value:66001},
  {label:'APÍA', value:66045},
  {label:'BALBOA', value:66075},
  {label:'BELÉN DE UMBRÍA', value:66088},
  {label:'DOSQUEBRADAS', value:66170},
  {label:'GUÁTICA', value:66318},
  {label:'LA CELIA', value:66383},
  {label:'LA VIRGINIA', value:66400},
  {label:'MARSELLA', value:66440},
  {label:'MISTRATÓ', value:66456},
  {label:'PUEBLO RICO', value:66572},
  {label:'QUINCHÍA', value:66594},
  {label:'SANTA ROSA DE CABAL', value:66682},
  {label:'SANTUARIO', value:66687},
  {label:'BUCARAMANGA', value:68001},
  {label:'AGUADA', value:68013},
  {label:'ALBANIA', value:68020},
  {label:'ARATOCA', value:68051},
  {label:'BARBOSA', value:68077},
  {label:'BARICHARA', value:68079},
  {label:'BARRANCABERMEJA', value:68081},
  {label:'BETULIA', value:68092},
  {label:'BOLÍVAR', value:68101},
  {label:'CABRERA', value:68121},
  {label:'CALIFORNIA', value:68132},
  {label:'CAPITANEJO', value:68147},
  {label:'CARCASÍ', value:68152},
  {label:'CEPITÁ', value:68160},
  {label:'CERRITO', value:68162},
  {label:'CHARALÁ', value:68167},
  {label:'CHARTA', value:68169},
  {label:'CHIMA', value:68176},
  {label:'CHIPATÁ', value:68179},
  {label:'CIMITARRA', value:68190},
  {label:'CONCEPCIÓN', value:68207},
  {label:'CONFINES', value:68209},
  {label:'CONTRATACIÓN', value:68211},
  {label:'COROMORO', value:68217},
  {label:'CURITÍ', value:68229},
  {label:'EL CARMEN DE CHUCURÍ', value:68235},
  {label:'EL GUACAMAYO', value:68245},
  {label:'EL PEÑÓN', value:68250},
  {label:'EL PLAYÓN', value:68255},
  {label:'ENCINO', value:68264},
  {label:'ENCISO', value:68266},
  {label:'FLORIÁN', value:68271},
  {label:'FLORIDABLANCA', value:68276},
  {label:'GALÁN', value:68296},
  {label:'GAMBITA', value:68298},
  {label:'GIRÓN', value:68307},
  {label:'GUACA', value:68318},
  {label:'GUADALUPE', value:68320},
  {label:'GUAPOTÁ', value:68322},
  {label:'GUAVATÁ', value:68324},
  {label:'GÜEPSA', value:68327},
  {label:'HATO', value:68344},
  {label:'JESÚS MARÍA', value:68368},
  {label:'JORDÁN', value:68370},
  {label:'LA BELLEZA', value:68377},
  {label:'LANDÁZURI', value:68385},
  {label:'LA PAZ', value:68397},
  {label:'LEBRIJA', value:68406},
  {label:'LOS SANTOS', value:68418},
  {label:'MACARAVITA', value:68425},
  {label:'MÁLAGA', value:68432},
  {label:'MATANZA', value:68444},
  {label:'MOGOTES', value:68464},
  {label:'MOLAGAVITA', value:68468},
  {label:'OCAMONTE', value:68498},
  {label:'OIBA', value:68500},
  {label:'ONZAGA', value:68502},
  {label:'PALMAR', value:68522},
  {label:'PALMAS DEL SOCORRO', value:68524},
  {label:'PÁRAMO', value:68533},
  {label:'PIEDECUESTA', value:68547},
  {label:'PINCHOTE', value:68549},
  {label:'PUENTE NACIONAL', value:68572},
  {label:'PUERTO PARRA', value:68573},
  {label:'PUERTO WILCHES', value:68575},
  {label:'RIONEGRO', value:68615},
  {label:'SABANA DE TORRES', value:68655},
  {label:'SAN ANDRÉS', value:68669},
  {label:'SAN BENITO', value:68673},
  {label:'SAN GIL', value:68679},
  {label:'SAN JOAQUÍN', value:68682},
  {label:'SAN JOSÉ DE MIRANDA', value:68684},
  {label:'SAN MIGUEL', value:68686},
  {label:'SAN VICENTE DE CHUCURÍ', value:68689},
  {label:'SANTA BÁRBARA', value:68705},
  {label:'SANTA HELENA DEL OPÓN', value:68720},
  {label:'SIMACOTA', value:68745},
  {label:'SOCORRO', value:68755},
  {label:'SUAITA', value:68770},
  {label:'SUCRE', value:68773},
  {label:'SURATÁ', value:68780},
  {label:'TONA', value:68820},
  {label:'VALLE DE SAN JOSÉ', value:68855},
  {label:'VÉLEZ', value:68861},
  {label:'VETAS', value:68867},
  {label:'VILLANUEVA', value:68872},
  {label:'ZAPATOCA', value:68895},
  {label:'SINCELEJO', value:70001},
  {label:'BUENAVISTA', value:70110},
  {label:'CAIMITO', value:70124},
  {label:'COLOSO', value:70204},
  {label:'COROZAL', value:70215},
  {label:'COVEÑAS', value:70221},
  {label:'CHALÁN', value:70230},
  {label:'EL ROBLE', value:70233},
  {label:'GALERAS', value:70235},
  {label:'GUARANDA', value:70265},
  {label:'LA UNIÓN', value:70400},
  {label:'LOS PALMITOS', value:70418},
  {label:'MAJAGUAL', value:70429},
  {label:'MORROA', value:70473},
  {label:'OVEJAS', value:70508},
  {label:'PALMITO', value:70523},
  {label:'SAMPUÉS', value:70670},
  {label:'SAN BENITO ABAD', value:70678},
  {label:'SAN JUAN DE BETULIA', value:70702},
  {label:'SAN MARCOS', value:70708},
  {label:'SAN ONOFRE', value:70713},
  {label:'SAN PEDRO', value:70717},
  {label:'SAN LUIS DE SINCÉ', value:70742},
  {label:'SUCRE', value:70771},
  {label:'SANTIAGO DE TOLÚ', value:70820},
  {label:'TOLÚ VIEJO', value:70823},
  {label:'IBAGUÉ', value:73001},
  {label:'ALPUJARRA', value:73024},
  {label:'ALVARADO', value:73026},
  {label:'AMBALEMA', value:73030},
  {label:'ANZOÁTEGUI', value:73043},
  {label:'ARMERO', value:73055},
  {label:'ATACO', value:73067},
  {label:'CAJAMARCA', value:73124},
  {label:'CARMEN DE APICALÁ', value:73148},
  {label:'CASABIANCA', value:73152},
  {label:'CHAPARRAL', value:73168},
  {label:'COELLO', value:73200},
  {label:'COYAIMA', value:73217},
  {label:'CUNDAY', value:73226},
  {label:'DOLORES', value:73236},
  {label:'ESPINAL', value:73268},
  {label:'FALAN', value:73270},
  {label:'FLANDES', value:73275},
  {label:'FRESNO', value:73283},
  {label:'GUAMO', value:73319},
  {label:'HERVEO', value:73347},
  {label:'HONDA', value:73349},
  {label:'ICONONZO', value:73352},
  {label:'LÉRIDA', value:73408},
  {label:'LÍBANO', value:73411},
  {label:'SAN SEBASTIÁN DE MARIQUITA', value:73443},
  {label:'MELGAR', value:73449},
  {label:'MURILLO', value:73461},
  {label:'NATAGAIMA', value:73483},
  {label:'ORTEGA', value:73504},
  {label:'PALOCABILDO', value:73520},
  {label:'PIEDRAS', value:73547},
  {label:'PLANADAS', value:73555},
  {label:'PRADO', value:73563},
  {label:'PURIFICACIÓN', value:73585},
  {label:'RIOBLANCO', value:73616},
  {label:'RONCESVALLES', value:73622},
  {label:'ROVIRA', value:73624},
  {label:'SALDAÑA', value:73671},
  {label:'SAN ANTONIO', value:73675},
  {label:'SAN LUIS', value:73678},
  {label:'SANTA ISABEL', value:73686},
  {label:'SUÁREZ', value:73770},
  {label:'VALLE DE SAN JUAN', value:73854},
  {label:'VENADILLO', value:73861},
  {label:'VILLAHERMOSA', value:73870},
  {label:'VILLARRICA', value:73873},
  {label:'CALI', value:76001},
  {label:'ALCALÁ', value:76020},
  {label:'ANDALUCÍA', value:76036},
  {label:'ANSERMANUEVO', value:76041},
  {label:'ARGELIA', value:76054},
  {label:'BOLÍVAR', value:76100},
  {label:'BUENAVENTURA', value:76109},
  {label:'GUADALAJARA DE BUGA', value:76111},
  {label:'BUGALAGRANDE', value:76113},
  {label:'CAICEDONIA', value:76122},
  {label:'CALIMA', value:76126},
  {label:'CANDELARIA', value:76130},
  {label:'CARTAGO', value:76147},
  {label:'DAGUA', value:76233},
  {label:'EL ÁGUILA', value:76243},
  {label:'EL CAIRO', value:76246},
  {label:'EL CERRITO', value:76248},
  {label:'EL DOVIO', value:76250},
  {label:'FLORIDA', value:76275},
  {label:'GINEBRA', value:76306},
  {label:'GUACARÍ', value:76318},
  {label:'JAMUNDÍ', value:76364},
  {label:'LA CUMBRE', value:76377},
  {label:'LA UNIÓN', value:76400},
  {label:'LA VICTORIA', value:76403},
  {label:'OBANDO', value:76497},
  {label:'PALMIRA', value:76520},
  {label:'PRADERA', value:76563},
  {label:'RESTREPO', value:76606},
  {label:'RIOFRÍO', value:76616},
  {label:'ROLDANILLO', value:76622},
  {label:'SAN PEDRO', value:76670},
  {label:'SEVILLA', value:76736},
  {label:'TORO', value:76823},
  {label:'TRUJILLO', value:76828},
  {label:'TULUÁ', value:76834},
  {label:'ULLOA', value:76845},
  {label:'VERSALLES', value:76863},
  {label:'VIJES', value:76869},
  {label:'YOTOCO', value:76890},
  {label:'YUMBO', value:76892},
  {label:'ZARZAL', value:76895},
  {label:'ARAUCA', value:81001},
  {label:'ARAUQUITA', value:81065},
  {label:'CRAVO NORTE', value:81220},
  {label:'FORTUL', value:81300},
  {label:'PUERTO RONDÓN', value:81591},
  {label:'SARAVENA', value:81736},
  {label:'TAME', value:81794},
  {label:'YOPAL', value:85001},
  {label:'AGUAZUL', value:85010},
  {label:'CHAMEZA', value:85015},
  {label:'HATO COROZAL', value:85125},
  {label:'LA SALINA', value:85136},
  {label:'MANÍ', value:85139},
  {label:'MONTERREY', value:85162},
  {label:'NUNCHÍA', value:85225},
  {label:'OROCUÉ', value:85230},
  {label:'PAZ DE ARIPORO', value:85250},
  {label:'PORE', value:85263},
  {label:'RECETOR', value:85279},
  {label:'SABANALARGA', value:85300},
  {label:'SÁCAMA', value:85315},
  {label:'SAN LUIS DE PALENQUE', value:85325},
  {label:'TÁMARA', value:85400},
  {label:'TAURAMENA', value:85410},
  {label:'TRINIDAD', value:85430},
  {label:'VILLANUEVA', value:85440},
  {label:'MOCOA', value:86001},
  {label:'COLÓN', value:86219},
  {label:'ORITO', value:86320},
  {label:'PUERTO ASÍS', value:86568},
  {label:'PUERTO CAICEDO', value:86569},
  {label:'PUERTO GUZMÁN', value:86571},
  {label:'PUERTO LEGUÍZAMO', value:86573},
  {label:'SIBUNDOY', value:86749},
  {label:'SAN FRANCISCO', value:86755},
  {label:'SAN MIGUEL', value:86757},
  {label:'SANTIAGO', value:86760},
  {label:'VALLE DEL GUAMUEZ', value:86865},
  {label:'VILLAGARZÓN', value:86885},
  {label:'SAN ANDRÉS', value:88001},
  {label:'PROVIDENCIA', value:88564},
  {label:'LETICIA', value:91001},
  {label:'EL ENCANTO', value:91263},
  {label:'LA CHORRERA', value:91405},
  {label:'LA PEDRERA', value:91407},
  {label:'LA VICTORIA', value:91430},
  {label:'MIRITI - PARANÁ', value:91460},
  {label:'PUERTO ALEGRÍA', value:91530},
  {label:'PUERTO ARICA', value:91536},
  {label:'PUERTO NARIÑO', value:91540},
  {label:'PUERTO SANTANDER', value:91669},
  {label:'TARAPACÁ', value:91798},
  {label:'INÍRIDA', value:94001},
  {label:'BARRANCO MINAS', value:94343},
  {label:'MAPIRIPANA', value:94663},
  {label:'SAN FELIPE', value:94883},
  {label:'PUERTO COLOMBIA', value:94884},
  {label:'LA GUADALUPE', value:94885},
  {label:'CACAHUAL', value:94886},
  {label:'PANA PANA', value:94887},
  {label:'MORICHAL', value:94888},
  {label:'SAN JOSÉ DEL GUAVIARE', value:95001},
  {label:'CALAMAR', value:95015},
  {label:'EL RETORNO', value:95025},
  {label:'MIRAFLORES', value:95200},
  {label:'MITÚ', value:97001},
  {label:'CARURU', value:97161},
  {label:'PACOA', value:97511},
  {label:'TARAIRA', value:97666},
  {label:'PAPUNAUA', value:97777},
  {label:'YAVARATÉ', value:97889},
  {label:'PUERTO CARREÑO', value:99001},
  {label:'LA PRIMAVERA', value:99524},
  {label:'SANTA ROSALÍA', value:99624},
  {label:'CUMARIBO', value:99773},
]

module.exports = cityCodes