import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import moment from 'moment';

let data = []
let date = moment('2020-01-01', 'YYYY-MM-DD')

for (let step = 0; step < 27; step++) {
  const occupancy = Math.floor(Math.random() * Math.floor(100));
  data.push(
    {
      "day": `${date.format('YYYY-MM-DD')}`,
      "value": occupancy
    }
  );
  date = date.add(1, 'day');
}

const CalendarChart = () => (
    <ResponsiveCalendar
        data={data}
        from="2020-01-01"
        to="2020-12-31"
        emptyColor="#F6F2EE"
        colors={[ '#F7E1ED', '#EDD4D0', '#E6bbb3', '#E37381' ]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left'
            }
        ]}
    />
)

export default CalendarChart;
