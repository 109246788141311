import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD CANCELLATIONS
//----------------------------------------------------------------
export function loadCancellations(propID, startDate, endDate, level) {
  const ACTION_NAME = "LOAD_CANCELLATION_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/cancellation/${propID}?startDate=${startDate}&endDate=${endDate}&level=${level}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD CANCELLATIONS BY DAY
//----------------------------------------------------------------
export function loadCancellationsByDay(propID, startDate, endDate) {
  const ACTION_NAME = "LOAD_CANCELLATION_BY_DAY";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/reservations/day?propertyID=${propID}&startDate=${startDate}&endDate=${endDate}&status=cancellation`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET CANCELLATIONS
//----------------------------------------------------------------
export const resetCancellations = () => ({
  type: 'RESET_CANCELLATIONS'
})