import Trix from "trix";
import axios from "axios";
import { S3_PATH } from "../../../config";

class FileUpload {
  constructor(imageUploadHandler) {
    this.imageUploadHandler = imageUploadHandler;
  }

  init = () => {
    Trix.config.attachments.preview.caption = {
      name: false,
      size: false,
    };
    document.addEventListener("trix-attachment-add", this.fileUploadHandler);
  };

  uploadAttachment = (attachment, data) => {
    //console.log('uploadAttachment ', data);
    const file = data.file;
    const fileParts = file.name.split(".");
    const fileType = fileParts[1];
    const options = {
      headers: { "Content-Type": fileType },
      onUploadProgress: function (progressEvent) {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        attachment.setUploadProgress(progress);
      },
    };
    axios
      .put(data.imageData.url, file, options)
      .then((result) => {
        const fileUrl = data.imageData.key;
        const fileBasePath = S3_PATH;
        return attachment.setAttributes({
          url: `${fileBasePath}${fileUrl}`,
          href: `${fileBasePath}${fileUrl}`,
        });
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  fileUploadHandler = (event) => {
    //console.log('fileUploadHandler ', event.attachment);
    const attachment = event.attachment;
    if (attachment.file) {
      this.imageUploadHandler((uploadData) => {
        const data = {
          file: attachment.file,
          imageData: { ...uploadData },
        };
        this.uploadAttachment(attachment, data);
      });
    }
  };

  clearListners = () => {
    document.removeEventListener("trix-attachment-add", this.fileUploadHandler);
  };
}

export default FileUpload;
