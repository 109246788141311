import React from "react";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { setCurrentFolder, setCurrentFile } from "../../redux/actions/dashboard/dashboard";
import { deleteFolder } from "../../redux/actions/files/folders";
import NewFileUpload from "./NewFileUpload";
import NewFileLink from "./NewFileLink";
import FileItem from "./FileItem";
import { editFolderName } from "../../redux/actions/files/folders";

import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  newListButton: {
    marginTop: 20,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cancelButton: {
    marginRight: 10
  },
  listSection: {
    height: 'calc(100vh - 152px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  listContainer: {
    margin: theme.spacing(3)
  },
  listTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& h5': {
      fontWeight: 600
    }
  },
  editFolder: {
    marginBottom: 10,
    width: '100%'
  },
  editFolderTitle: {
    fontWeight: 600
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10
  },
  button: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  folderOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px'
  },
  folderMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  moreIcon: {
    fontSize: '1.8rem'  
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  menuItem: {
    color: "#666666",
    fontSize: 14,
    minWidth: 150,
    textDecoration: "none"
  },
  blank: {
    padding: theme.spacing(2)
  },
  fileRow: {
    alignItems: "center",
    borderBottom: "1px solid #dddddd",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    '&:hover': {
      background: "#dddddd",
      transition: "350ms"
    }
  },
  fileLink: {
    marginLeft: "auto",
    display: 'flex',
    alignItems: 'center'
  },
  fileIcon: {
    '& img': {
      color: "#dddddd",
      height: 30,
      width: 30
    }
  },
  imageIcon: {
    color: "#666666"
  },
  fileName: {
    marginLeft: 20
  },
  downloadIcon: {
    color: "#666666"
  },
  commentCount: {
    fontSize: '1.2rem',
    height: '18px',
    marginRight: 10,
    width: '18px'
  }
})

class FolderItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      newFileUpload: false,
      newFileLink: false,
      fileType: "",
      success : false,
      url : "",
      anchorEl: null,
      storage: "",
      title: '',
      anchorEl2: null,
      editFolderName: false
    }
  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleClose = () => {
    this.props.dispatch(setCurrentFolder());
    // this.props.dispatch(resetFiles());
    this.setState({ 
      newFileUpload: false, 
      newFileLink: false,
      fileType: ""
    })
  }

  handleNewFileUpload = () => {
    this.setState({ anchorEl: null });
    this.setState({ 
      newFileUpload: true, 
      newFileLink: false,
      fileType: ""
    })
  }

  handleNewFileLink = (e) => {
    this.setState({ anchorEl: null });
    this.setState({ 
      newFileLink: true, 
      newFileUpload: false, 
      storage: e.target.id 
    })
  }

  handleFileClick = (id) => {
    this.props.dispatch(setCurrentFile(id));
  }

  handleCancelNewFile = () => {
    this.setState({ 
      newFileUpload: false, 
      newFileLink: false,
      fileType: "",
      storage: ""
    })
  }

  handleFolderMenu = (event) => {
    this.setState({anchorEl2: event.currentTarget})
  }

  handleFolderMenuClose = () => {
    this.setState({anchorEl2: null})
  }

  handleEditFolderName = () => {
    this.setState({ title: this.props.currentFolder.name})
    this.setState({editFolderName: true});
    this.setState({anchorEl2: null});
  }

  handleDeleteFolder = () => {
    if (!this.props.currentFolder.files || this.props.currentFolder.files.length === 0) {
      this.props.dispatch(deleteFolder(this.props.currentSpaceID, this.props.currentFolderID));
      console.log('delete folder');
      this.props.dispatch(setCurrentFolder());
    } else {
      alert("Please delete individual file items first")
    }
    this.setState({anchorEl2: null});
  }

  handleSubmitFolderUpdate = () => {
    console.log('updating folder name => ', this.state.title);
    this.props.dispatch(editFolderName(this.props.currentFolderID, this.state.title))
    this.setState({editFolderName: false});
  }

  focusInputField = input => {
    if (input) {
      setTimeout(() => input.focus(), 100);
    }
  };

  render() {

    const {classes, currentFolder, currentFolderID, currentFileID, files, t} = this.props;

    return(
      <div>
        <Dialog 
          open={Boolean(currentFolderID)} 
          onClose={this.handleClose} 
          classes={{ paperFullWidth: classes.dialogPaper }}
          maxWidth="sm"
          fullWidth={true}
          fullScreen={window.innerWidth < 901}
        >
          <div className={classes.dialog}>
            <div className={classes.dialogTitle}>
              <div className={classes.header}>{t('files.folder')}</div>
              <CloseIcon className={classes.closeIcon} onClick={this.handleClose} />
            </div>

            <div className={classes.listSection}>
              <div className={classes.listContainer}>

                <div className={classes.listTitle}>
                  {/* ------------------------------------------------ */}
                  {/* Folder Title */}
                  {/* ------------------------------------------------ */}
                  {!this.state.editFolderName && <Typography variant="h5">{currentFolder && currentFolder.name}</Typography>}
                  {this.state.editFolderName && (
                    <div className={classes.editFolder}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        fullWidth
                        type="text"
                        className={classes.editCategoryTitle}
                        onChange={(e) => this.setState({title: e.target.value})}
                        value={this.state.title}
                        inputRef={this.focusInputField}
                      />
                    </div>
                  )}

                  {this.state.editFolderName && (
                    <div className={classes.buttonHolder}>
                      <CloseIcon className={classes.button} onClick={() => this.setState({editFolderName: false})} />
                      <DoneIcon className={classes.button} onClick={this.handleSubmitFolderUpdate} />
                    </div>
                  )}

                  {/* ------------------------------------------------ */}
                  {/* Folder Menu */}
                  {/* ------------------------------------------------ */}
                  {!this.state.editFolderName && (
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={this.handleFolderMenu}
                      className={classes.folderOptions}
                    >
                      <MoreHorizIcon className={classes.moreIcon} />
                    </IconButton>
                  )}
                  <Menu
                    id="category-menu"
                    anchorEl={this.state.anchorEl2}
                    className={classes.folderMenu}
                    keepMounted
                    open={Boolean(this.state.anchorEl2)}
                    onClose={this.handleFolderMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    style={{padding: 0}}
                    getContentAnchorEl={null}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <ListItem button onClick={this.handleEditFolderName}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="body2">{t('files.editFolder')}</Typography>
                    </ListItem>
                    <ListItem button onClick={this.handleDeleteFolder}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="body2">{t('files.deleteFolder')}</Typography>
                    </ListItem>
                  </Menu>
                </div>

                {/* List empty */}
                {currentFolder && currentFolder.files && currentFolder.files.length === 0 && <div className={classes.blank}>{t('files.noFiles')}</div>}

                {/* List Files */}
                {currentFolder && currentFolder.files && currentFolder.files.length !== 0 && currentFolder.files.map(fileID => {
                  const file = files[fileID]
                  return (
                    <div className={classes.fileRow} key={fileID} onClick={ ()=> this.handleFileClick(fileID)}>
                      <div className={classes.fileIcon}>
                        {file.type === 'image' && <img src="/images/icons/image-icon.png"/>}
                        {file.type === 'document' && <img src="/images/icons/document-icon.png"/>}
                        {file.type === 'spreadsheet' && <img src="/images/icons/spreadsheet-icon.png"/>}
                        {file.type === 'pdf' && <img src="/images/icons/pdf-icon.png"/>}
                        {file.type === 'other' && <img src="/images/icons/document-icon.png"/>}
                      </div>
                      <div className={classes.fileName}>{file.name}</div>
                      <div className={classes.fileLink}>
                        {file.comments.length != 0 ? 
                          <Avatar className={classes.commentCount}>{file.comments.length}</Avatar> : 
                          null
                        }
                        <a href={file.url} target='_blank'>
                          {file.storage === 's3' && <CloudDownloadIcon className={classes.downloadIcon}/>}
                          {file.storage != 's3' && <OpenInBrowserIcon className={classes.downloadIcon}/>}
                        </a>
                      </div>
                    </div>
                  )
                })}

                {(!this.state.newFileUpload && !this.state.newFileLink) && <div>
                  <Button 
                    size='small' 
                    color='primary' 
                    variant='outlined' 
                    className={classes.newListButton} 
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={this.handleMenuClick}
                  >
                    {t('files.addFile')}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    className={classes.menu}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    getContentAnchorEl={null}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={this.handleNewFileUpload} className={classes.menuItem}>{t('files.uploadFile')}</MenuItem>
                    <Divider/>
                    <MenuItem id="googleDrive" onClick={this.handleNewFileLink} className={classes.menuItem}>Google Drive</MenuItem>
                    <MenuItem id="dropbox" onClick={this.handleNewFileLink} className={classes.menuItem}>Dropbox</MenuItem>
                    <MenuItem id="box" onClick={this.handleNewFileLink} className={classes.menuItem}>Box</MenuItem>
                    <MenuItem id="oneDrive" onClick={this.handleNewFileLink} className={classes.menuItem}>OneDrive</MenuItem>
                  </Menu>
                </div>}

                {this.state.newFileUpload && <NewFileUpload handleCancelNewFile={this.handleCancelNewFile} />}
                {this.state.newFileLink && <NewFileLink handleCancelNewFile={this.handleCancelNewFile} storage={this.state.storage} />}

              </div>
            </div>
          </div>

        </Dialog>

        {currentFileID && <FileItem />}

      </div>
    )
  }
}

FolderItems.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentFolderID: state.dashboard.currentFolder,
  currentFileID: state.dashboard.currentFile,
  currentFolder: state.folders[state.dashboard.currentFolder],
  files: (state.files.length !== 0) ? state.files : []
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(FolderItems)));