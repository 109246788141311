import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import SelectBedForm from '../SelectBedForm';
import { getDatesOfRange } from '../../../../utils/utility';
import { createNewReservationAccommodations } from '../../reservationHelper';


const ChooseRoomForm = (props) => {
    const { beds, rooms, roomTypes, options, formData, selectedDate, startDate, endDate, currentSpace } = props;
    const [onMount, setOnMount] = React.useState(true);
    const [isAvailable, setIsAvailable] = React.useState(false);
    const [displayBeds, setDisplayBeds] = React.useState(false);
    const [isFormChanged, setFormChanged] = React.useState(false);
    const [bedOrRoom, setBedOrRoom] = React.useState(null);
    const [optionData, setOptionData] = React.useState({});
    const [form, setForm] = React.useState({
        typeOfRoom: null,
        roomTypeID: null,
        roomID: null,
        bedID: null,
        entireStay: false,
        rateEntireStay: false,
        amount: 0
    });

    React.useEffect(() => {
        handleProcessFormData();
    }, [formData, selectedDate, options]);

    React.useEffect(() => {
        if(!onMount){
            submitHandler();
        }
    }, [form.bedID, form.entireStay, form.amount, form.rateEntireStay]);

    React.useEffect(() => {
        if (props?.statusCheck?.check) {
            checkFormStatusHandler(props?.statusCheck?.date);
        }
    }, [props.statusCheck]);

    React.useEffect(() => {
        props.disableButtonHandler(isFormChanged);
    }, [isFormChanged]);

    const handleProcessFormData = () => {
        let tempData = { ...formData };
        tempData.items = [...formData.items];
        let formInfo = tempData && tempData.items.find(item => item.date === selectedDate) || {};
        let isBed = formInfo.roomTypeID && roomTypes[formInfo.roomTypeID] && roomTypes[formInfo.roomTypeID].dormOrPrivate === 'dorm' && true;
        setBedOrRoom(isBed ? 'bed' : 'room');
        setDisplayBeds(isBed)
        selectRoomTypeHandler(formInfo.roomTypeID, true);
        if (formInfo.roomID) selectRoomHandler(formInfo.roomID, formInfo.roomTypeID, true);
        if (formInfo.bedID) selectBedHandler(formInfo.bedID, formInfo.roomID, true);
        setForm({
            typeOfRoom: formInfo.typeOfRoom || (isBed ? 'dorm' : 'private'),
            roomTypeID: formInfo.roomTypeID,
            roomID: formInfo.roomID,
            bedID: formInfo.bedID,
            entireStay: formInfo.entireStay || false,
            rateEntireStay: formInfo.rateEntireStay || false,
            amount: isNaN(formInfo.rate) ? 0 : formInfo.rate
        })
        setOnMount(false)
    }

    // On select dorm or private
    const selectTypeHandler = (typeOfRoom) => {
        setForm({
            typeOfRoom: typeOfRoom,
            roomTypeID: null,
            roomID: null,
            bedID: null,
            entireStay: false,
            rateEntireStay: false,
            amount: 0
        });
        setIsAvailable(false)
        setDisplayBeds(false)
        setBedOrRoom(false)
        setOptionData({})
        setFormChanged(true)
    }

    // On select room type
    const selectRoomTypeHandler = (typeID, onMount) => {
        if (!onMount) {
            setForm({
                ...form,
                roomTypeID: typeID,
                roomID: null,
                bedID: null,
                entireStay: false,
                rateEntireStay: false,
                amount: 0
            });
            setFormChanged(true)
        }
        setIsAvailable(false)

        // fetch room keys with bed key for current set item || availability of selected date
        let roomTypeData = [...options]
            .filter(option => option.roomTypeID === typeID && option.date === selectedDate && option.rate)
            .reduce(function (r, a) {
                r[a.roomID] = r[a.roomID] || [];
                if (!r[a.roomID].includes(a.bedID)) r[a.roomID].push(a.bedID);
                return r;
            }, Object.create(null));;
        setOptionData({ ...roomTypeData })
    }

    // On select room
    const selectRoomHandler = (roomID, roomTypeID, onMount) => {
        let bedDisplay = roomID && roomTypes[roomTypeID] && roomTypes[roomTypeID].dormOrPrivate === 'dorm' && true;
        setBedOrRoom(bedDisplay ? 'bed' : 'room')
        setDisplayBeds(bedDisplay)
        setIsAvailable(false)

        if (!onMount) {
            setForm({
                ...form,
                roomID: roomID,
                bedID: null,
                entireStay: false,
                rateEntireStay: false,
                amount: 0
            });
            setFormChanged(true)
        }

        if (!bedDisplay && optionData?.[roomID]?.length === 1) {
            selectBedHandler(optionData?.[roomID][0], roomID, onMount)
            return;
        }
    }

    // On select bed
    const selectBedHandler = (bedID, roomID, onMount) => {
        let amount = form.amount || 0;
        let date1 = startDate, date2 = endDate;
        let datesOfRange = getDatesOfRange(date1, date2, null, 'stringArray');
        // get dates from given option api for a bed
        let optionDates = [...options]
            .filter(option => option.bedID === bedID && option.rate)
            .map(option => { if (selectedDate === option.date) amount = option.rate; return option.date; })
            .sort((a, b) => (a > b ? 1 : -1));
        // if equal set availability to true

        let available = optionDates.toString().includes(datesOfRange.toString());
        // console.log(datesOfRange.toString(), optionDates.toString(), available)
        setIsAvailable(available)
        setForm({ ...form, ...(roomID && { roomID: roomID }), bedID: bedID, entireStay: false, rateEntireStay: false, amount: amount || 0 });
        if(!onMount) setFormChanged(false)
    }

    const saveAmountHandler =(value) => {
        setForm({ ...form, amount: value });
    }

    const saveEntireStayHandler =(value) => {
        setForm({ ...form, entireStay: value });
    }

    const saveEntireRateHandler =(value) => {
        setForm({ ...form, rateEntireStay: value });
    }

    // On submit
    const submitHandler = () => {
        let savedData = createNewReservationAccommodations({ ...formData }, selectedDate, { ...form });
        props.saveDraftHandler(savedData)
    }

    const checkFormStatusHandler = (date) => {
        let isFormChanged = false;
        if (selectedDate) {
            let data = formData?.items?.find(item => item.date === selectedDate) || {};
            if (data.roomTypeID !== form.roomTypeID ||
                data.roomID !== form.roomID ||
                data.bedID !== form.bedID ||
                data.rate !== form.amount ||
                form.rateEntireStay ||
                form.entireStay || !(form.roomTypeID && form.roomID && form.bedID)) {
                isFormChanged = true
            }
        }
        props.onStatusCheckHandler(isFormChanged, date);
    }

    return (
        <SelectBedForm
            form={form}

            rooms={rooms}
            beds={beds}
            roomTypes={roomTypes}
            currentSpace={currentSpace}

            single={false}
            typeOption={true}
            roomTypeName={roomTypes?.[props?.originalRoomTypeID]?.name}
            bedOrRoom={bedOrRoom}
            isAvailable={isAvailable}
            edit={true}
            ButtonText={'Save changes'}

            alternateRoomTypes={([... new Set(options.map(item => {
                if (item.roomTypeID && item.rate && item.date === selectedDate &&
                    roomTypes[item.roomTypeID] &&
                    roomTypes[item.roomTypeID].dormOrPrivate === form.typeOfRoom
                ) return item.roomTypeID
            }))].filter(value => value))}
            roomList={form.roomTypeID ? optionData : null}
            bedList={optionData[form.roomID] && displayBeds ? optionData[form.roomID] : []}

            handleSelectTypeOfRoom={selectTypeHandler}
            setAmount={saveAmountHandler}
            handleSelectRoomType={selectRoomTypeHandler}
            handleSelectRoom={selectRoomHandler}
            handleSelectBed={selectBedHandler}
            handleSetSelectionEntireStay={saveEntireStayHandler}
            setRateEntireStay={saveEntireRateHandler}
            handleSave={submitHandler}
        />
    )

}

const mapStateToProps = state => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    options: state.options,
    roomTypes: state.roomTypes,
    rooms: state.rooms,
    beds: state.beds
});

export default connect(mapStateToProps)(ChooseRoomForm);