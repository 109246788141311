import http from "../../utils/http";
import { normalize } from "normalizr";
import { roomMappingSchema } from "../../schema/mappingSchema";
import { CHANNEL } from "../../../utils/channels";
import { ratePlanSchema } from "../../schema/ratePlanSchema";

// 'createRoomTypeMapping'.replace(/([A-Z])/g, '_$1').trim().toUpperCase(); functionName to ACTION_NAME
// 'createRoomTypeMapping'.replace(/([A-Z])/g, ' $1').trim().toUpperCase(); functionName to COMMENT DESCRIPTION

//----------------------------------------------------------------
// FETCH CHANNEL STATUS
//----------------------------------------------------------------
export function fetchChannelStatus(propertyID) {
  const ACTION_NAME = "FETCH_CHANNEL_STATUS";

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/frontdesk/channels/status/${propertyID}`)
      .then(successResult => {
        const data = successResult.data || {};
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data } })
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// FETCH CHANNEL LOG
//----------------------------------------------------------------
export function fetchChannelLogInfo(propertyID, params) {
  const ACTION_NAME = "FETCH_CHANNEL_LOG";

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/frontdesk/channels/log/${propertyID}`, { params })
      .then(successResult => {
        const data = successResult.data || {};
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data } })
        return data;
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  }
}

//----------------------------------------------------------------
// CREATE ROOM TYPE MAPPING
//----------------------------------------------------------------
export function createRoomTypeMapping(payload, channelType) {
  const ACTION_NAME = "CREATE_ROOM_TYPE_MAPPING";

  return function (dispatch) {
    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.createRoomTypeMapping)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }

    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(CHANNEL[channelType].API.createRoomTypeMapping, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data, type: channelType } })
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  }
}

//----------------------------------------------------------------
// FETCH CHANNEL ROOM TYPE LIST
//----------------------------------------------------------------
export function fetchChannelRoomType(propertyID, channelType) {
  const ACTION_NAME = "FETCH_CHANNEL_ROOM_TYPE";

  if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.fetchChannelRoomType)) {
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
    }
  }

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(CHANNEL[channelType].API.fetchChannelRoomType + propertyID)
      .then(successResult => {
        const data = successResult.data || {};
        let channelMap = [];
        if (!data.output) data.output = [];
        if (!data.hw_roomType_map) data.hw_roomType_map = [];
        if (channelType === CHANNEL.Hostelworld.code) channelMap = data.hw_roomType_map || [];
        if (channelType === CHANNEL.myAllocator.code) {
          channelMap = data.mya_roomType_map || [];
          dispatch({ type: 'FETCH_USERTOKEN_SUCCESS', payload: { userToken: data.userToken } })
        }
        if (data.channel_roomType_map) {
          channelMap = data.channel_roomType_map
        }

        let channelNormalised = normalize(data.output, [roomMappingSchema])
        let mappingNormalised = normalize(channelMap, [roomMappingSchema])

        // console.log('Normalised HW mapping', channelMap, mappingNormalised)

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            channel: { [channelType]: channelNormalised && channelNormalised.entities.roomMapping ? channelNormalised.entities.roomMapping : null },
            mapping: { [channelType]: mappingNormalised && mappingNormalised.entities.roomMapping ? mappingNormalised.entities.roomMapping : null },
          }
        })
        if (!channelNormalised.entities.roomMapping) {
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'No channels found' } });
        }
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// CREATE CHANNEL FULL REFRESH [POST]
//----------------------------------------------------------------
export function createChannelFullRefreshPost(propertyID, roomTypeID, channelType) {
  const ACTION_NAME = "CREATE_CHANNEL_FULL_REFRESH";

  return function (dispatch) {
    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.createChannelFullRefresh)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }

    let path = CHANNEL[channelType].API.createChannelFullRefresh + propertyID;
    if (roomTypeID) path = path + `?roomTypeID=${roomTypeID}`

    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(path)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}
//----------------------------------------------------------------
// CREATE CHANNEL FULL REFRESH [GET]
//----------------------------------------------------------------
export function createChannelFullRefreshGet(propertyID, roomTypeID, channelType) {
  const ACTION_NAME = "CREATE_CHANNEL_FULL_REFRESH";

  return function (dispatch) {
    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.createChannelFullRefresh)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }

    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(CHANNEL[channelType].API.createChannelFullRefresh + propertyID)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// DELETE ROOM TYPE MAPPING
//----------------------------------------------------------------
export function deleteRoomTypeMapping(roomTypeID, channelType) {
  const ACTION_NAME = "DELETE_ROOM_TYPE_MAPPING";

  return function (dispatch) {
    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.deleteRoomTypeMapping)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }

    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(CHANNEL[channelType].API.deleteRoomTypeMapping + roomTypeID)
      .then(successResult => {
        const { roomTypeMap, ratePlans } = successResult.data
        const ratePlansNormalised = normalize(ratePlans, [ratePlanSchema])
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...roomTypeMap, type: channelType } })
        dispatch({ type: `UPDATE_RATE_PLANS`, payload: ratePlansNormalised.entities.ratePlans })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// DELETE PROPERTY CHANNEL MAPPING
//----------------------------------------------------------------
export function deletePropertyChannelMapping(propertyID, channelType) {
  const ACTION_NAME = "DELETE_PROPERTY_CHANNEL_MAPPING";

  return function (dispatch) {

    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.deletePropertyChannelMapping)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }

    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(CHANNEL[channelType].API.deletePropertyChannelMapping + propertyID)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { type: channelType } })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// RESET CHANNEL MAPPING
//----------------------------------------------------------------
export function setupChannel(spaceID, channelType, payload) {
  const ACTION_NAME = "SETUP_CHANNEL";

  return function (dispatch) {

    if (!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.setupChannel)) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'API path not found' } });
      return;
    }
    
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(CHANNEL[channelType].API.setupChannel + spaceID, payload)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, message: data.message })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// RESET CHANNEL MAPPING
//----------------------------------------------------------------
export function resetChannelMapping() {
  return function (dispatch) {
    dispatch({ type: `RESET_CHANNEL_MAPPING_SUCCESS` });
  }
}