import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { fetchCurrencyFormat, fetchDateFormat, getAmtFormat } from '../../../../utils/utility';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '20px 0',
        borderRadius: 5
    },
    header: {
        display: 'flex',
        padding: '10px 20px',
        borderBottom: '1px solid #dadada',
        '& > div': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
        }
    },
    headerLeft: {
        '& > span': {
            fontSize: '1.6rem',
            fontWeight: 600
        },
        '& > img': {
            marginLeft: 10
        }
    },
    headerRight: {
        justifyContent: 'flex-end'
    },
    body: {
        display: 'flex',
        padding: '10px 20px',
    },
    formInput: {
        border: '1px solid #dadada',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        minWidth: 'calc(25% - 27px)',
        marginRight: 5,
        borderRadius: 30,
        justifyContent: 'space-between',
    },
    data: {
        width: '80%',
        wordBreak: 'break-word'
    },
    icon: {
        width: '20px'
    },
    label: {
        fontSize: '1.1rem'
    },
    value: {
        fontWeight: 600
    },
    actionIcon: {
        width: 20,
        cursor: 'pointer'
    },
    card: {
        border: '1px solid #dadada',
        borderRadius: 5,
        padding: 10,
    },
    detailsCard: {
        padding: 10,
        '& > div': {
            paddingBottom: 5,
            fontSize: '1rem'
        }
    },
    actionButton: {
        cursor: 'pointer',
        border: '1px solid #dadada',
        padding: '10px!important',
        minWidth: 50,
        textAlign: 'center',
        borderRadius: 30,
        fontSize: '1.2rem!important',
        '&.primary': {
            backgroundColor: '#C87250',
            color: '#fff'
        },
        '&.disabled': {
            color: '#BBBBBB',
            backgroundColor: '#fff'
        },
        '&.grey': {
            color: '#000000',
            backgroundColor: '#f2f2f2'
        }
    },
    imageCard: {
        width: 'calc(100% + 22px)',
        marginLeft: '-11px',
        marginTop: '-11px',
        position: 'relative',
        height: 200,
        backgroundColor: '#f2f4f8',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: '5px 5px 0 0'
        }
    },
    availability: {
        position: 'absolute',
        padding: '5px 10px',
        top: 20,
        fontSize: '1rem',
        color: '#C87250',
        backgroundColor: '#fff',
        borderRadius: '0px 5px 5px 0',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '1.3rem!important',
        fontWeight: 600
    },
    location: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -4,
    },
    space: {
        height: '10px',
    },
    header: {
        padding: '10px 10px 30px',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const ExperienceCard = ({
    data,
    productInfo,
    openAddFormHandler,
    classes
}) => {

    if (!productInfo || !productInfo[data.draftID]) return null;
    const startTime = fetchDateFormat(`${data.date} ${data.startTime}`, 'h:mm a');
    const endTime = fetchDateFormat(`${data.date} ${data.endTime}`, 'h:mm a');

    const imageUrl = productInfo[data.draftID].photos &&
        productInfo[data.draftID].photos.length > 0 ?
        productInfo[data.draftID].photos[0].original : ''

    const currency = fetchCurrencyFormat(data.currency)

    const activity = productInfo[data.draftID];
    let activityGradeID = null;

    Object.keys(activity.grades).map(gradeID => {
        if (Object.keys(activity?.grades?.[gradeID]?.slots)?.includes(data.slotID)) {
            activityGradeID = gradeID
        }
    })

    const cardData = {
        draftID: data.draftID,
        productID: data.productID,
        title: productInfo[data.draftID].title,
        location: productInfo[data.draftID].locations,
        duration: data.allDay ? `All day starts at ${startTime}` : `${startTime} - ${endTime}`,
        conductorID: data.conductorID,
        participants: data.participants,
        available: data.available,
        availabilityID: data.availabilityID,
        gradeID: activityGradeID,
        slotID: data.slotID,
        date: data.date,
        currencyData: {
            code: data.currency,
            country: data.country
        },
        imageUrl,
        price: data.price
    }

    const getLocationHandler = (locations = []) => {
        return locations.toString().replace(/,/g, ', ')
    }

    return (
        <React.Fragment>
            <div className={classes.card}>
                <div className={classes.imageCard}>
                    {cardData.imageUrl && <img className={classes.image} src={cardData.imageUrl} />}
                    <div className={classes.availability}>{cardData.available ? `${cardData.available} Spots left` : 'SOLD OUT'}</div>
                </div>
                <div className={classes.detailsCard}>
                    <div className={clsx(classes.title, classes.titleContainer)}><span>{cardData.title}</span> <span>{getAmtFormat(cardData.price, currency)}</span></div>
                    <div className={classes.value}>{fetchDateFormat(cardData.date, 'Do MMM YYYY') + ' | ' + (startTime || '')}</div>
                    {cardData.location.length > 0 &&
                        <div className={classes.location}><img src={IMAGES.ICONS.location} className={classes.icon} /> {getLocationHandler(cardData.location)}</div>
                    }

                    <div className={classes.space}></div>
                    {cardData.available ?
                        <div className={clsx(classes.actionButton, 'primary')}
                            onClick={() => openAddFormHandler({
                                productID: cardData.productID,
                                date: cardData.date,
                                slotID: cardData.slotID,
                                gradeID: cardData.gradeID,
                                availabilityID: cardData.availabilityID,
                                available: cardData.available,
                                title: cardData.title,
                                price: {
                                    raw: cardData.price,
                                    currency: cardData.currencyData.code
                                }
                            })}
                        >Add experience</div> :
                        <div className={clsx(classes.actionButton, 'disabled')}>Sold out</div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
};

const ExperienceList = (props) => {
    const classes = useStyles();
    const { calendarData, productInfo } = props;

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.title}>Other experiences</div>
            </div>
            <Grid container spacing={2}>
                {Object.keys(calendarData).map(dateKey => {
                    let productList = calendarData[dateKey];
                    return (
                        productList.map((item, index) =>
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <ExperienceCard
                                    classes={classes}
                                    data={item}
                                    productInfo={productInfo}
                                    openAddFormHandler={props.openAddFormHandler}
                                />
                            </Grid>
                        )
                    )
                })}

            </Grid>

        </div>
    )
}

export default ExperienceList;