/* global FormData */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { CircularProgress } from "@material-ui/core";

import http from "../../redux/utils/http";
import { addNewFile } from "../../redux/actions/files/files";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  addIcon: {
    color: "#999999",
  },
  fileInfo: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingLeft: 40,
    marginTop: 20,
    marginBottom: 20,
  },
  fileInfoTitle: {
    color: "#444444",
    minWidth: "100px",
    textAlign: "right",
    marginRight: 20,
  },
  fileInput: {
    width: "100%",
  },
  textField: {
    "& div": {
      fontSize: 14,
    },
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: 10,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 200,
    width: "100%",
  },
});

class NewFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fileType: "other",
      description: "",
      error: null,
    };
  }

  handleRadioChange = (event) => {
    this.setState({ fileType: event.target.value });
  };

  updateDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  handleSubmit = () => {
    const { currentFolderID, currentSpaceID, dispatch, handleCancelNewFile } =
      this.props;
    const { description, fileType } = this.state;

    this.setState({ loading: true });
    const file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    const fileParts = this.uploadInput.files[0].name.split(".");
    const fileName = fileParts[0];
    const fileExtension = fileParts[1];

    const data = new FormData();
    data.append("folderID", currentFolderID);
    data.append("spaceID", currentSpaceID);
    data.append("name", fileName);
    data.append("fileExtension", fileExtension);
    data.append("type", fileType);
    data.append("file", file);

    http
      .post(`/api/s3/upload`, data)
      .then((response) => {
        const returnData = response.data;
        const { key } = returnData;

        http
          .post("/api/file/upload", {
            name: fileName,
            url: key,
            desc: description,
            type: fileType,
            storage: "s3",
            spaceID: currentSpaceID,
            folderID: currentFolderID,
          })
          .then(({ data }) => {
            this.setState({ loading: false });
            dispatch(addNewFile(data));
            handleCancelNewFile();
          })
          .catch((error) => {
            const { data } = error.response;
            const message = data.message || error.message;
            this.setState({ loading: false, error: message });
          });
      })
      .catch((error) => {
        const { data } = error.response;
        const message = data.message || error.message;
        this.setState({ loading: false, error: message });
      });
  };

  handleCloseError = () => {
    this.setState({ error: null });
  };

  render() {
    const { loading, description, fileType, error } = this.state;
    const { classes, t, handleCancelNewFile } = this.props;

    return (
      <div className={classes.root}>
        {!loading && (
          <div>
            <div className={classes.fileInfo}>
              <div className={classes.fileInfoTitle}>{t("files.file")}</div>
              <div className={classes.fileInput}>
                <input
                  ref={(ref) => {
                    this.uploadInput = ref;
                  }}
                  type="file"
                />
              </div>
            </div>
            <div className={classes.fileInfo}>
              <div className={classes.fileInfoTitle}>
                {t("files.description")}
              </div>
              <div className={classes.fileInput}>
                <TextField
                  id="outlined-textarea"
                  placeholder={t("files.enterDescription")}
                  multiline
                  minRows="3"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onChange={this.updateDescription}
                  value={description}
                />
              </div>
            </div>
            <div className={classes.fileInfo}>
              <div className={classes.fileInfoTitle}>{t("files.fileType")}</div>
              <div className={classes.fileInput}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={fileType}
                    onChange={this.handleRadioChange}
                    row
                  >
                    <FormControlLabel
                      value="image"
                      control={<Radio color="primary" />}
                      label="Image"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="document"
                      control={<Radio color="primary" />}
                      label="Document"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="spreadsheet"
                      control={<Radio color="primary" />}
                      label="Spreadsheet"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="pdf"
                      control={<Radio color="primary" />}
                      label="PDF"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="primary" />}
                      label={t("files.other")}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className={classes.submit}>
              <Button
                size="small"
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleCancelNewFile}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={this.handleSubmit}
              >
                {t("actions.upload")}
              </Button>
            </div>
            <Divider />
          </div>
        )}

        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}

        {error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open
            onClose={this.handleCloseError}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <Alert
              onClose={this.handleCloseError}
              severity="error"
              id="message-id"
            >
              {t(error)}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

NewFileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  currentFolderID: PropTypes.string,
  currentSpaceID: PropTypes.string,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCancelNewFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentFolderID: state.dashboard.currentFolder,
  currentSpaceID: state.dashboard.currentSpace,
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(NewFileUpload))
);
