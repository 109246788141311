import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { makeStyles, CircularProgress, Box, Tooltip } from "@material-ui/core";
import clsx from "clsx";

import { Button, Typography } from "@material-ui/core";
import {
  DATE_FORMATS,
  fetchDateFormat,
  fetchCombineDateFormat,
  getAmtFormat,
  fetchCurrencyFormat,
  fetchNoOfDays,
  fetchNoOfNights,
} from "../../../utils/utility";
import { returnCurrent } from "../../../redux/selectors/dashboard";
import { RESERVATION_STATUS, TRANSACTION_MODE } from "../../../utils/constants";
import NewPayment from "./NewPayment";
import { toTitleCase } from "../../utils/wordUtils";
import { transactionSchema } from "../../../redux/schema/transactionModeSchema";
import ConfirmForm from "../../common/ConfirmForm";
import {
  voidReservationTransaction,
  fetchChannexSessionToken,
  fetchCardDetails,
  loadReservationInvoice,
  deleteReservationTax,
  updateReservationItems,
  loadReservationTaxReceipt,
} from "../../../redux/actions/reservation/editReservation";
import CardInfoDisplay from "../CardInfoDisplay";
import { ICONS } from "../../../utils/imageUrls";
import DiscountForm from "./DiscountForm";
import UpsellSummary from "./summary/UpsellSummary";
import TaxForm from "./TaxForm";
import TaxSummary from "./summary/TaxSummary";
import RefundForm from "./RefundForm";
import POSSummary from "./summary/POSSummary";
import { deleteOrder } from "../../../redux/actions/pos/orders";
import ExperienceSummary from "./summary/ExperienceSummary";
import AccessController from "../../permissions/AccessController";
import { MODULE } from "../../../common/constants/permission";

import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100% - 21px)",
    overflowY: "auto",
    backgroundColor: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    border: "1px solid #dadada",
    borderBottom: "none",
    marginRight: 35,
    marginTop: 30,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: 0,
      borderRadius: 5,
      border: "1px solid #dadada",
    },
  },
  summaryContainer: {
    borderRadius: 2,
    // color: "#f2f2f2",
    fontSize: "1.4rem",
    lineHeight: "1.5",
    marginBottom: 20,
    overflowY: "hidden",
  },
  collectPayment: {
    background: "#FFFFFF",
    border: "1px solid #666666",
    borderRadius: 5,
    color: "#484848",
    fontWeight: 600,
    padding: 15,
    "&:hover": {
      background: "#FFFFFF",
      color: "#484848",
    },
  },
  paymentButton: {
    background: "#d96977",
    color: "#ffffff",
    padding: 15,
    width: "100%",
    fontSize: "1.6rem",
    fontWeight: 600,
    "&:hover": {
      background: "#d96977",
      color: "#ffffff",
    },
    "&:disabled": {
      color: "#ffffff",
    },
  },
  payment: {},
  paymentContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(5),
    marginBottom: 20,
    "& > button": {
      width: "calc(100% - 60px)",
    },
  },
  tableWrapper: {
    overflow: "auto",
    width: "100%",
  },
  roomName: {
    color: "#262827",
    fontWeight: 600,
    fontSize: "1.4rem",
    paddingBottom: theme.spacing(1),
  },
  priceContainer: {
    position: "relative",
    minWidth: "25%",
  },
  price: {
    color: "#262827",
    fontSize: "1.4rem",
    fontWeight: 600,
    position: "absolute",
    top: 0,
    right: "0px",
  },
  secondaryText: {
    color: "#666666",
    fontSize: "1.1rem",
    "&.spanText": {
      display: "block",
      fontWeight: 500,
    },
  },
  itemDesc: {
    display: "flex",
    justifyContent: "space-between",
  },
  itemDetails: {
    alignItems: "center",
    display: "flex",
    paddingTop: theme.spacing(2),
  },
  itemIcon: {
    height: 17,
    marginRight: 5,
    opacity: 0.4,
    width: 17,
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 20,
  },
  iconSection: {
    width: "50%",
  },
  iconCount: {
    color: "#666666",
    fontSize: "1.1rem",
    marginRight: 10,
  },
  summary: {
    padding: "0 30px",
    width: "calc(100% - 60px)",
  },
  summaryLine: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 0,
    "& p": {
      fontSize: "1.4rem",
    },
  },
  taxTotal: {
    marginTop: 5,
    color: "#bdbdbd",
  },
  taxLabel: {
    cursor: "pointer",
    fontSize: "1.2rem",
  },
  subTotal: {
    marginTop: 5,
    "& p": {
      color: "#262827",
      fontWeight: 600,
    },
  },
  paymentItem: {
    color: "#262827",
    padding: "10px 30px",
  },
  paymentDesc: {
    color: "#666666",
    fontWeight: 500,
    fontSize: "1.4rem",
    paddingBottom: 5,
  },
  paymentDetails: {
    color: "#666666",
  },
  transactionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  transactionText: {
    paddingBottom: 5,
  },
  balance: {
    color: "#d96977",
    marginTop: 20,
    "& p": {
      fontWeight: 600,
      fontSize: "1.6rem",
    },
  },
  aux: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  auxText: {
    color: "#cccccc",
    fontSize: "1.1rem",
  },
  auxIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "33%",
    "& img": {
      height: 25,
      marginBottom: 10,
      opacity: 0.3,
      width: 25,
    },
    "&:hover": {
      "& img": {
        opacity: 1,
      },
      "& p": {
        color: "#333333",
      },
    },
  },
  noHoverOpacity: {
    "&:hover": {
      "& img": {
        opacity: 0.3,
      },
      "& p": {
        color: "#cccccc",
      },
    },
  },
  actionText: {
    cursor: "pointer",
    fontSize: "1.2rem",
  },
  paymentHistory: {
    color: "#13408d",
    cursor: "pointer",
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 20,
  },
  pay: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  payButton: {
    background: "#000000",
    borderRadius: 2,
    color: "#ffffff",
    fontSize: "1.6rem",
    fontWeight: 400,
    "&:hover": {
      background: "#000000",
    },
  },
  confirm: {
    marginTop: -10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  actionIcon: {
    height: 15,
    width: 15,
    opacity: 0.4,
    paddingLeft: 15,
    cursor: "pointer",
  },
  deleteText: {
    color: "#13408d",
    cursor: "pointer",
    fontSize: "1.1rem",
  },
  showCardDetails: {
    "&.hide": {
      display: "none",
    },
    "& .card": {
      maxWidth: "300px",
      margin: "10px auto",
      padding: "30px",
      border: "1px solid #dedede",
      borderRadius: 2,
      boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.75)",
      position: "relative",
      paddingTop: "40px",
    },

    "& .hideCardIcon": {
      position: "absolute",
      zIndex: 999,
      width: "35px",
      height: "35px",
      right: "5px",
      top: "5px",
      [theme.breakpoints.down("sm")]: {
        right: "5px",
        top: "5px",
      },
    },

    "& .card__field": {
      display: "flex",
      paddingBottom: "6px",
    },

    "& .card__field-title": {
      fontWeight: 600,
      width: "120px",
      color: " #262827",
    },

    "& .card__field-content": {
      marginLeft: "5px",
      color: "#262827",
      maxWidth: "175px",
    },

    "& .card__field--card-number": {
      "& .card__field-content:first-child": {
        marginLeft: "unset",
      },
      "& .card-number__card-number-chunk:not(:last-child)": {
        paddingRight: "0.5rem",
      },
    },
  },
  header: {
    alignItems: "center",
    borderBottom: "1px solid #dadada",
    display: "flex",
    height: 95,
    "& h3": {
      fontSize: "1.6rem",
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  headerExtra: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "15px 0",
    padding: "0 20px",
    "&>div": {
      display: "flex",
    },
  },
  roomItemContainer: {
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 2,
  },
  divider: {
    marginTop: 20,
    color: "#F0F0F0",
    borderTop: "1px solid #F0F0F0",
  },
  cardLoader: {
    height: "60px !important",
    marginTop: "25px",
    textAlign: "center",
  },
  taxDisplay: {
    display: "flex",
    marginRight: 20,
  },
  alignTextCenter: {
    textAlign: "center",
  },
}));

const SummaryTab = (props) => {
  const classes = useStyles();
  const [newPayment, setNewPayment] = React.useState(false);
  const [displayDialog, setDisplayDialog] = React.useState({
    discount: false,
    tax: false,
    refund: false,
  });
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [transactionID, setTransactionID] = React.useState(null);
  const [taxID, setTaxID] = React.useState(null);
  const [displayTax, setDisplayTax] = React.useState(false);
  const [paymentHistory, setPaymentHistory] = React.useState(false);

  const {
    beds,
    rooms,
    roomTypes,
    reservation,
    transactions,
    currentSpace,
    frontdeskUsers,
    users,
    transactionModes,
    upsellItems,
    currentUser,
    stripe,
    t,
    maskedCards,
    expBookings,
    settings,
  } = props;
  const accommodations = reservation.accommodationList;
  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );
  const admin = currentSpace && currentSpace.admins.includes(currentUser._id);
  // const showCardDetailOption = reservation.channexCardTokens && reservation.channexCardTokens.length > 0 ? true : false;
  // const reservationNights = fetchNoOfNights(reservation.checkIn, reservation.checkOut);
  const isUnallocated = props.reservation.accommodationList.find(
    (acc) => !acc.bedID
  );
  const isCheckedOut = reservation.status == RESERVATION_STATUS.CHECKED_OUT;

  // -------------- On close of dialog --------------
  const handleConfirmFormClose = () => {
    setTransactionID(null);
    setConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    const voidToBeTransaction = transactions.find(
      (trans) => trans._id === transactionID
    );
    const mode = { ...transactionModes[voidToBeTransaction.modeID] };
    props.dispatch(
      voidReservationTransaction(
        transactionID,
        (mode.name === "cash" || mode.name === "card") &&
          settings.printers &&
          settings.printers.length > 0
          ? settings.printers[0]
          : {}
      )
    );
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "void payment",
      eventLabel: "Void Payment",
    });
  };

  const handleFetchInvoice = () => {
    props.dispatch(loadReservationInvoice(reservation._id));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "load invoice",
      eventLabel: "Load Invoice",
    });
  };

  const handleFetchTaxReceipt = () => {
    props.dispatch(loadReservationTaxReceipt(reservation._id));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "load tax receipt",
      eventLabel: "Load Tax Receipt",
    });
  };

  const handleDeletePOSProduct = (payload) => {
    props.dispatch(deleteOrder(reservation._id, payload));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "delete POS item",
      eventLabel: "Delete POS Item",
    });
  };

  const handleDeleteUpsellItem = (items) => {
    props.dispatch(updateReservationItems(reservation._id, items));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "delete add-on item",
      eventLabel: "Delete Add On",
    });
  };

  const roomSummary = accommodations.map((row, index) => {
    const bedName = beds[row.bedID] && beds[row.bedID].name;
    const roomName = rooms[row.roomID] && rooms[row.roomID].name;
    const roomTypeName =
      roomTypes[row.roomTypeID] && roomTypes[row.roomTypeID].name;
    // const guestCount = row.guestCount > 1 ? row.guestCount : (roomTypes[row.roomTypeID] && roomTypes[row.roomTypeID].dormOrPrivate === "dorm") ? 1 : roomTypeName && roomTypeName.includes("Double") ? 2 : 1;
    const totalAmount = row.rateList
      ? getAmtFormat(row.rateList.reduce((a, b) => a + (b["rate"] || 0), 0))
      : 0;
    const nightCount = fetchNoOfDays(row.checkIn, row.checkOut) || 1;
    return (
      <div className={classes.roomItemContainer} key={`room_${index}`}>
        <div className={classes.itemDesc}>
          <div>
            <Typography variant="body1" className={classes.roomName}>
              {roomName}
            </Typography>
            <Typography variant="body2" className={classes.iconCount}>
              {nightCount} {t("existingReservation.summaryTab.night")},{" "}
              {fetchCombineDateFormat(row.checkIn, row.checkOut)}
            </Typography>
            <Typography variant="body2" className={classes.iconCount}>
              {getAmtFormat(totalAmount / nightCount, currency) +
                " " +
                t("existingReservation.summaryTab.perBed")}
            </Typography>
          </div>
          <div className={classes.priceContainer}>
            <Typography variant="body1" className={classes.price}>
              {currency + totalAmount}
            </Typography>
          </div>
        </div>
        <hr className={classes.divider} />
      </div>
    );
  });

  const getSummaryButton = (row, type) => {
    let button = "";
    switch (type) {
      case "refund":
        break;
      case "delete":
        button = (
          <AccessController requiredPermission={MODULE.DELETE_TRANSACTION.ID}>
            <span
              className={classes.deleteText}
              onClick={() => {
                setTransactionID(row._id);
                setConfirmOpen(true);
              }}
            >
              Delete
            </span>
          </AccessController>
        );
        break;
      default:
        break;
    }
    return button;
  };

  const getUserName = (row) => {
    let userName = "",
      user = {};
    if (frontdeskUsers[row.createdBy]) {
      user = frontdeskUsers[row.createdBy];
      userName = (user.firstName || "") + " " + (user.lastName || "");
    } else if (users[row.createdBy]) {
      userName = toTitleCase(users[row.createdBy].name) || "";
    } else if (!row.createdBy) {
      userName = "System";
    }
    return userName;
  };

  const getSummaryText = (row) => {
    let transactionText = "",
      transactioByText = "",
      transactionDate =
        fetchDateFormat(
          row.createdAt,
          DATE_FORMATS.DATE_MONTH,
          currentSpace.timezone
        ) +
        " " +
        fetchDateFormat(
          row.createdAt,
          DATE_FORMATS.TIME,
          currentSpace.timezone
        ),
      userName = getUserName(row),
      button = getSummaryButton(row, "delete"); // default action button
    const transactionMode = { ...transactionModes[row.modeID] };

    switch (transactionMode.name) {
      case TRANSACTION_MODE.HOSTELWORLD:
        transactionText =
          TRANSACTION_MODE.HOSTELWORLD +
          " " +
          t("existingReservation.summaryTab.deposit");
        break;

      case TRANSACTION_MODE.CHANNEL:
        transactionText = t("existingReservation.summaryTab.channelCollect");
        break;

      case TRANSACTION_MODE.DISCOUNT:
        transactionText = t("existingReservation.summaryTab.discount");
        transactioByText =
          t("existingReservation.summaryTab.applied") + " by " + userName;
        break;

      case TRANSACTION_MODE.SURCHARGE:
        transactionText = t("existingReservation.summaryTab.surcharge");
        transactioByText =
          t("existingReservation.summaryTab.applied") + " by " + userName;
        break;

      default:
        const transactionStatus = row.isRefund
          ? t("existingReservation.summaryTab.refunded")
          : t("existingReservation.summaryTab.paid");
        const transactionAction = row.isRefund
          ? t("existingReservation.summaryTab.credited")
          : t("existingReservation.summaryTab.collected");
        transactionText = transactionStatus + " (" + transactionMode.name + ")";
        transactioByText = transactionAction + " by " + userName;
        break;
    }

    return (
      <span>
        <span className={classes.transactionText}>{transactionText}</span>
        <span
          className={`${classes.secondaryText} spanText ${classes.paymentDetails}`}
        >
          {transactionDate}
        </span>
        {transactioByText && (
          <span
            className={`${classes.secondaryText} spanText ${classes.paymentDetails}`}
          >
            {transactioByText}
          </span>
        )}
        {transactionMode.name !== TRANSACTION_MODE.STRIPE && button}
      </span>
    );
  };

  const formatTranscationAmount = (row) => {
    if (!row.amount) return "";
    let amount = Math.abs(row.amount);
    amount = `${currency}${getAmtFormat(amount)}`;
    amount = row.isRefund ? `-${amount}` : amount;
    return amount;
  };

  return (
    <div className={classes.container}>
      <div className={classes.summaryContainer}>
        {/* --------------------------------------------------------------- */}
        {/* TITLE */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.header}>
          <Typography variant="h3">
            {t("existingReservation.summaryTab.summary")}
          </Typography>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* Room Accommodation List */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.tableWrapper}>
          {roomSummary}
          {expBookings?.length > 0 && (
            <ExperienceSummary
              classes={classes}
              currency={currency}
              currentSpace={currentSpace}
              expBookings={expBookings}
            />
          )}
          <UpsellSummary
            admin={admin}
            classes={classes}
            currency={currency}
            currentSpace={currentSpace}
            reservation={reservation}
            upsellItems={upsellItems}
            deleteUpsellItem={handleDeleteUpsellItem}
          />
          <POSSummary
            admin={admin}
            classes={classes}
            currency={currency}
            currentSpace={currentSpace}
            orders={props.orders}
            products={props.products}
            deletePOSProduct={handleDeletePOSProduct}
          />
        </div>

        {/* --------------------------------------------------------------- */}
        {/* Summary Tax */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          {reservation.taxAmount ? (
            <div className={clsx(classes.summaryLine, classes.taxTotal)}>
              <Typography
                className={classes.taxLabel}
                component="p"
                onClick={() => setDisplayTax(!displayTax)}
              >
                <span
                  className={clsx({
                    [classes.hide]:
                      reservation.taxesApplied &&
                      reservation.taxesApplied.length === 0 &&
                      !reservation.inclusiveTaxAmount &&
                      !reservation.exclusiveTaxAmount,
                  })}
                >
                  {displayTax ? "- " : "+ "}
                </span>
                {t("existingReservation.summaryTab.tax")}
              </Typography>
              <Typography component="p">
                {`${currency}${getAmtFormat(reservation.taxAmount) || "0"}`}
              </Typography>
            </div>
          ) : null}

          {displayTax && (
            <TaxSummary
              taxes={props.taxes}
              admin={admin}
              reservation={reservation}
              currency={currency}
              classes={classes}
              onClickEdit={(selectedID) => {
                setDisplayDialog({ ...displayDialog, tax: true });
                setTaxID(selectedID);
              }}
              handleDelete={(selectedID) => {
                props.dispatch(
                  deleteReservationTax(reservation._id, { taxID: selectedID })
                );
              }}
            />
          )}
        </div>

        {/* --------------------------------------------------------------- */}
        {/* SUMMARY SUBTOTAL */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.summaryLine, classes.subTotal)}>
            <Typography component="p">
              {t("newReservation.roomSelection.totalAmount")}
            </Typography>
            <Typography component="p">
              {`${currency}${getAmtFormat(reservation.totalAmount) || "0"}`}
            </Typography>
          </div>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* PAYMENT HISTORY */}
        {/* --------------------------------------------------------------- */}
        {transactions.length > 0 && !paymentHistory && (
          <div
            className={clsx(classes.paymentHistory, classes.summary)}
            onClick={() => setPaymentHistory(true)}
          >
            + Show payment history
          </div>
        )}
        {paymentHistory && (
          <div
            className={clsx(classes.paymentHistory, classes.summary)}
            onClick={() => setPaymentHistory(false)}
          >
            - Hide payment history
          </div>
        )}
        {paymentHistory &&
          transactions.map((row, index) => {
            let summaryText = getSummaryText(row);

            return (
              <div key={index}>
                <div className={clsx(classes.itemDesc, classes.paymentItem)}>
                  <Typography variant="body1" className={classes.paymentDesc}>
                    {summaryText}
                  </Typography>
                  <Typography variant="body1" className={classes.paymentDesc}>
                    {formatTranscationAmount(row)}
                  </Typography>
                </div>

                {transactionID === row._id && (
                  <div className={classes.confirm} key={`confirm_${index}`}>
                    <ConfirmForm
                      open={confirmOpen}
                      setOpen={setConfirmOpen}
                      onClose={handleConfirmFormClose}
                      onConfirm={handleConfirmDelete}
                    >
                      {t("existingReservation.summaryTab.voidConfirmation")}
                    </ConfirmForm>
                  </div>
                )}
              </div>
            );
          })}

        {/* --------------------------------------------------------------- */}
        {/* BALANCE */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.summaryLine, classes.balance)}>
            <Typography component="p" className={classes.themeText}>
              {t("existingReservation.hoverInfo.balance")}
            </Typography>
            <Typography component="p" className={classes.themeText}>
              {`${currency}${getAmtFormat(reservation.balance) || "0"}`}
            </Typography>
          </div>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* PAYMENT FORM */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.payment}>
          {newPayment && (
            <NewPayment
              handleCloseNewPayment={() => setNewPayment(false)}
              // channexCardTokens = {reservation.channexCardTokens}
            />
          )}
        </div>
        {!newPayment && (
          <div className={classes.paymentContainer}>
            <Button
              className={classes.collectPayment}
              onClick={() => setNewPayment(true)}
            >
              {t("existingReservation.summaryTab.collectPayment")}
            </Button>
          </div>
        )}

        {/* --------------------------------------------------------------- */}
        {/* DISCOUNT VOUCHER INVOICE TAX */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.aux)}>
            {/* ------------------INVOICE DOWNLOAD-------------------- */}
            <Tooltip
              title={
                isUnallocated
                  ? t("existingReservation.summaryTab.invoiceNotAllowedText")
                  : ""
              }
            >
              <div
                className={clsx(classes.auxIcon, {
                  [classes.noHoverOpacity]: isUnallocated,
                })}
                onClick={() => {
                  if (!isUnallocated) handleFetchInvoice();
                }}
              >
                <img title="Invoice" src={IMAGES.ICONS.invoice} />
                <Typography className={classes.auxText} variant="body2">
                  {t("existingReservation.summaryTab.invoice")}
                </Typography>
              </div>
            </Tooltip>

            {/* ------------------TAX RECEIPT DOWNLOAD-------------------- */}
            {props.propertySettings.taxInvoiceEnabled && (
              <Tooltip
                title={!isCheckedOut ? t("taxReceipts.downloadTooltip") : ""}
              >
                <div
                  className={clsx(classes.auxIcon, {
                    [classes.noHoverOpacity]: !isCheckedOut,
                  })}
                  onClick={() => {
                    if (isCheckedOut) handleFetchTaxReceipt();
                  }}
                >
                  <img title="Tax Receipt" src={IMAGES.ICONS.invoice} />
                  <Typography
                    className={clsx(classes.auxText, classes.alignTextCenter)}
                    variant="body2"
                  >
                    {t("taxReceipts.title")}
                  </Typography>
                </div>
              </Tooltip>
            )}

            {/* ------------------DISCOUNT-------------------- */}
            <div
              className={classes.auxIcon}
              onClick={() =>
                setDisplayDialog({ ...displayDialog, discount: true })
              }
            >
              <img title="Discount" src={IMAGES.ICONS.discounts} />
              <Typography className={classes.auxText} variant="body2">
                {t("existingReservation.summaryTab.discount")}
              </Typography>
            </div>

            {/* ------------------TAX-------------------- */}
            <div
              className={classes.auxIcon}
              onClick={() => setDisplayDialog({ ...displayDialog, tax: true })}
            >
              <img title="Tax" src={IMAGES.ICONS.taxes} />
              <Typography className={classes.auxText} variant="body2">
                {t("existingReservation.summaryTab.tax")}
              </Typography>
            </div>

            {/* ------------------REFUND-------------------- */}
            {transactions.length > 0 && (
              <div
                className={classes.auxIcon}
                onClick={() =>
                  setDisplayDialog({ ...displayDialog, refund: true })
                }
              >
                <img title="Refund" src={IMAGES.ICONS.refunds} />
                <Typography className={classes.auxText} variant="body2">
                  Refund
                </Typography>
              </div>
            )}
          </div>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* REFUND FORM */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.payment}>
          {displayDialog.refund && (
            <RefundForm
              currency={currency}
              handleCloseRefund={() =>
                setDisplayDialog({ ...displayDialog, refund: false })
              }
            />
          )}
        </div>

        {/* --------------------------------------------------------------- */}
        {/* DISCOUNT FORM */}
        {/* --------------------------------------------------------------- */}
        {displayDialog.discount && (
          <DiscountForm
            propertyID={currentSpace.propertyID}
            totalAmount={reservation.balance}
            transactionModes={transactionModes}
            handleClose={() =>
              setDisplayDialog({ ...displayDialog, discount: false })
            }
          />
        )}

        {/* --------------------------------------------------------------- */}
        {/* TAX FORM */}
        {/* --------------------------------------------------------------- */}
        {displayDialog.tax && (
          <TaxForm
            id={taxID}
            handleClose={() => {
              setDisplayDialog({ ...displayDialog, tax: false });
              setTaxID(null);
            }}
          />
        )}
      </div>

      {props.loading && (
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  reservation: state.reservationDetails.reservation,
  expBookings: state.reservationDetails.expBookings,
  transactions: state.reservationDetails.transactions,
  maskedCards: state.reservationDetails.maskedCards,
  transactionModes: state.transactionModes,
  beds: state.beds,
  rooms: state.rooms,
  roomTypes: state.roomTypes,
  users: state.users,
  taxes: state.taxes,
  upsellItems: state.upsell || {},
  products: state.products || {},
  orders: state.reservationDetails.orders || [],
  loading:
    state.loading.VOID_RESERVATION_TRANSACTION ||
    state.loading.MANAGE_RESERVATION_TAX ||
    state.loading.ADD_RESERVATION_PAYMENT ||
    state.loading.LOAD_RESERVATION_INVOICE,
  frontdeskUsers: state.frontdeskUsers,
  stripe: state.stripe,
  settings: state.settings,
  propertySettings: state.propertySettings,
});

export default withTranslation()(connect(mapStateToProps)(SummaryTab));
