import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import PlusMinus from '../../common/PlusMinus';
import { getAmtFormat } from '../../../utils/utility';

const useStyles = makeStyles(theme => ({
  block: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: 10,
    display: 'flex',
    overflow: 'hidden'
  },
  snapshot: {
    alignItems: 'center',
    display: 'flex',
    height: 70,
    justifyContent: 'center',
    width: 70,
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  icon: {
    '& img': {
      height: 24,
      width: 24,
    }
  },
  details: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 20,
    marginRight: 'auto',
  },
  bold: {
    fontWeight: 600,
  },
  textDisplay: {
    display: 'block',
    fontSize: '1.2rem',
  },
  action: {
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingRight: 10,
      minWidth: 120,
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    '& > div > div': {
      maxWidth: 'unset',
      flexBasis: 'unset',
    },
    '& button': {
      width: 30,
      height: 30,
    },
    '& span': {
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
    }
  },
}));

const POSLineItem = (props) => {
  const classes = useStyles();
  const { id, name, image, icon, amount, currency, quantity, onCountChange} = props;

  return (
    <div className={classes.block}>
      <div className={clsx({[classes.snapshot]: true, [classes.icon]: icon})}>
          <img src={image} />
      </div>
      <div className={classes.details}>
          <div className={classes.info}>
              <span className={clsx(classes.bold, classes.textDisplay)}>{name}</span>
              <span className={classes.textDisplay}>{getAmtFormat(amount, currency)}</span>
          </div>
      </div>
      <div className={classes.action}>
          <PlusMinus
              itemID={id}
              design="rounded"
              onCountChange={onCountChange}
              prefilledCount={quantity}
              fullWidth={true} />
      </div>
    </div>
  )
}

export default POSLineItem;