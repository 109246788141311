import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { UPSELL_ITEMS } from '../../utils/upsellItems';
import { addUpsellItem, updateUpsellItem } from '../../redux/actions/upsell/upsell';

import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F0F0F0',
    padding: 30,
    paddingLeft: 40,
    paddingRight: 40,
    width: 'calc(100% - 80px)'
  },
  iconRow: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  iconContainer: {
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 5,
    padding: 8,
    '& img': {
      height: 25,
      width: 25,
    },
  },
  selected: {
    background: '#8CB2A4',
  },
  row: {
    marginBottom: 20,
  },
  label: {
    fontSize: '1.1rem',
    color: '#333333',
    marginBottom: 5,
  },
  rate: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    fontSize: '1.2rem',
    paddingLeft: 15,
    width: '60%',
  },
  error: {
    color: 'red',
    fontSize: '1.1rem',
    marginTop: 5,
  },
  saveContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    borderRadius: 10,
    color: '#ffffff',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cancel: {
    background: 'transparent',
    color: '#333333',
    fontSize: '1.2rem',
    marginRight: 10
  },
  save: {
    background: '#8CB2A4',
    fontWeight: 600
  }
}))

const UpsellItemForm = (props) => {
  const classes = useStyles();
  const { item = {}, iconList, currentSpace } = props;
  const [error, setError] = React.useState({ name: false, rate: false, iconID: false });
  const [form, setForm] = React.useState({ name: item.name || '', rate: item.rate || '', isActive: item.isActive || false, iconID: item.iconID || '' });

  const handleChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value })
    if (e.target.value !== '') {
      setError({ ...error, [e.target.name]: false })
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = { 'iconID': 'string', 'name': 'string', 'rate': 'number' };
    Object.keys(mandatoryFields).map(key => {
      if (!form[key] || (mandatoryFields[key] === 'number' && isNaN(form[key]))) {
        errorStep[key] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    const check = handleValidate();
    if (check) {
      let payload = { ...item, ...form, isActive: true, propertyID: currentSpace.propertyID };
      if (payload._id) {
        props.dispatch(updateUpsellItem(item._id, payload));
      } else {
        props.dispatch(addUpsellItem(currentSpace._id, payload))
      }
      props.handleComplete()
    }
  }

  const handleCancel = () => {
    setError({ name: false, rate: false });
    setForm({ name: item.name || '', rate: item.rate || '', isActive: item.isActive || false });
    props.handleComplete();
  }

  return (
    <div className={classes.root}>

      {/* -------------------------------------------------- */}
      {/* ICONS */}
      {/* -------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.iconRow}>
          {/* Edit existing add-on should include current image */}
            {Object.keys(item).length!==0 && <div 
              className={clsx({[classes.iconContainer]: true, [classes.selected]: item.iconID===form.iconID})}
              onClick={() => {setForm({...form, iconID: item.iconID}); setError({...error, iconID: false}); }}
            >
            <img src={item.iconID===form.iconID ? UPSELL_ITEMS.find(e => e.iconID===item.iconID).selectedIcon : UPSELL_ITEMS.find(e => e.iconID===item.iconID).icon} />
          </div>}

          {/* List of unused icons */}
          {UPSELL_ITEMS.map(listItem => (
            (iconList.indexOf(listItem.iconID) === -1) && <div 
              key={listItem.iconID} 
              className={clsx({[classes.iconContainer]: true, [classes.selected]: listItem.iconID===form.iconID})}
              onClick={() => { setForm({...form, iconID: listItem.iconID}); setError({...error, iconID: false}); }}
            >
              <img src={listItem.iconID===form.iconID ? listItem.selectedIcon : listItem.icon} />
            </div>
          ))}
        </div>
        {error.iconID && <div className={classes.error}>Please select an icon</div>}
      </div>
      
      {/* -------------------------------------------------- */}
      {/* NAME */}
      {/* -------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.label}>Item Name</div>
        <div className={classes.rate} style={{ border: error.name ? '1px solid red' : '1px solid #ffffff' }}>
          <InputBase
            id="name"
            name="name"
            defaultValue={item.name || ''}
            fullWidth
            type="text"
            autoFocus
            onChange={(e) => handleChange(e, "name")}
          />
        </div>
        {error.name && <div className={classes.error}>Please enter a valid name</div>}
      </div>

      
      {/* -------------------------------------------------- */}
      {/* RATE */}
      {/* -------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.label}>Rate</div>
        <div className={classes.rate} style={{ border: error.rate ? '1px solid red' : '1px solid #ffffff' }}>
          <InputBase
            id="rate"
            name="rate"
            defaultValue={item.rate || ''}
            fullWidth
            type="text"
            onChange={(e) => handleChange(e, "rate")}
          />
        </div>
        {error.rate && <div className={classes.error}>Please enter a valid rate</div>}
      </div>


      {/* -------------------------------------------------- */}
      {/* SUBMIT */}
      {/* -------------------------------------------------- */}
      <div className={classes.saveContainer}>
        <div className={clsx(classes.cancel, classes.button)} onClick={handleCancel}>Cancel</div>
        <div className={clsx(classes.save, classes.button)} onClick={handleSubmit}>Save</div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(UpsellItemForm);