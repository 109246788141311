import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Guests from '../../search/Guests';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import clsx from 'clsx';
import { RESERVATION_STATUS } from '../../../utils/constants';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 450,
    paddingTop: 10
  },
  headerContainer: {
    display: 'flex'
  },
  searchContainer: {
    alignItems: 'center',
    background: '#f8f9fb',
    borderRadius: '5px',
    display: 'flex',
    height: 40,
    marginRight: 10,
    paddingLeft: 40,
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '9%%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  searchIcon: {
    position: 'absolute',
    height: 15,
    left: 10,
    opacity: 0.5,
    top: 12,
    width: 15,
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: 5,
    position: 'absolute',
    right: 5,
    top: 6,
    '&:hover': {
      background: '#F0F0F0',
    }
  },
  accessHeader: {
    marginTop: 'unset'
  },
  addReservation: {
    alignItems: 'center',
    background: '#006699',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: 10,
    width: 40,
    '&:hover': {

    }
  },
  options: {
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.4rem',
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
    },
    '& .SingleDatePicker': {
      width: '100%'
    },
    '& .SingleDatePickerInput__withBorder': {
      borderRadius: '10px',
      border: 'none',
      boxShadow: '0 2px 30px 0 #F0F0F0',
      color: 'rgba(0, 0, 0, 0.87)',
      width: 'calc(100% - 30px)'
    },
    '& input': {
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .DateInput_input': {
      color: 'currentColor',
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      padding: '5px 8px 5px 16px'
    },
    '& .DateInput_input__focused': {
      borderBottom: 'none'
    },
    '& .DateInput': {
      alignItems: 'center',
      borderRadius: '4px',
      display: 'flex',
      height: 36,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  selectOptions: {
    border: 'none'
  },
  addIcon: {
    color: '#FFFFFF',
    fontSize: '2.2rem',
  },
  resultContainer: {
    paddingTop: 10
  },
  button: {
    background: '#000',
    color: '#fff',
    width: 100,
    marginLeft: 20,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0
    },
    '&:disabled': {
      color: 'gray'
    }
  },
}));

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    minWidth: '120px',
    borderRadius: 10,
    // This line disable the blue border
    boxShadow: '0 2px 70px 0 #F0F0F0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const guestStatus = [
  { value: RESERVATION_STATUS.IN_HOUSE, label: 'In House' },
  { value: RESERVATION_STATUS.CONFIRMED, label: 'Confirmed' },
  { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: 'Online checked in' },
]


const SearchGuests = forwardRef((props, ref) => {
  const classes = useStyles();
  const { searchResults, search, handleClearSearch } = props;
  const [value, setValue] = useState('');
  const [selectedGuest, setSelectedGuest] = useState('');

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return { reservationID: selectedGuest }
    }
  }))

  const selectGuestStatus = selected => {
    if (selected !== null) {
      setValue(selected.value);
    } else {
      setValue('')
    }
    setSelectedGuest('')
  }
  
  const handleSearch = () => {
    setSelectedGuest('')
    props.handleSearch(search, value);
  }
  
  return (
    <div className={classes.container}  ref={ref}>
      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <div className={classes.searchContainer}>
            <img className={classes.searchIcon} src={IMAGES.ICONS.search2} />
            <InputBase
              id="guestSearch"
              name="guestSearch"
              placeholder={'Search'}
              value={search}
              fullWidth
              type="text"
              onChange={(e) => props.setSearch(e.target.value)}
            />
            {search && <CloseIcon className={classes.closeIcon} onClick={handleClearSearch} />}
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <div className={clsx(classes.options, classes.selectOptions)}>
            <Select
              styles={selectStyle}
              options={guestStatus}
              isDisabled={!search}
              isClearable={true}
              onChange={selectGuestStatus}
              placeholder={"Status"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <Button className={classes.button} disabled={!search} onClick={() => handleSearch()}>Search</Button>
        </Grid>
      </Grid>

      {
        searchResults && searchResults.length ?
        <div className={classes.resultContainer}>
          <Grid container spacing={3}>
            {searchResults.map(reservation => (
              <Guests display={'tile'} selected={selectedGuest === reservation._id} reservation={reservation} key={reservation._id} handleOpenReservation={(id) => setSelectedGuest(id)} />
            ))}
          </Grid>
        </div>
          : null
      }
    </div>
  )
});

export default SearchGuests;