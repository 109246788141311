import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD COMMISSION
//----------------------------------------------------------------
export function fetchCommissionInfo(propertyID, params) {
  const ACTION_NAME = "FETCH_COMMISSION_INFO";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/frontdesk/reports/commission/${propertyID}`, { params })
        .then(successResult => {
            const { data } = successResult;
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return data;
        })
        .catch(errorResult => {
            console.log('failure', errorResult);
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            return;
        });
  };
}