import React, { useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";

import { usersCount, adminsCount } from "../../redux/selectors/team/users";
import { setUpgradeAccount } from "../../redux/actions/dashboard/dashboard";
import InviteUser from "../users/InviteUser";
import AddUserModal from "../users/AddUserModal";
import UserTile from "../users/UserTile";
import PendingUser from "../users/PendingUser";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpArrow from "@material-ui/icons/KeyboardArrowUp";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
      marginTop: 20,
    },
  },
  inviteRow: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#C2AE7F",
    borderRadius: 5,
    cursor: "pointer",
    color: "#FFF",
    fontSize: "1.4rem",
    fontWeight: 600,
    padding: "10px 15px",
  },
  inviteButton: {
    marginLeft: 10,
    background: "#C2AE7F",
    borderRadius: 5,
    cursor: "pointer",
    color: "#FFF",
    fontSize: "1.4rem",
    fontWeight: 600,
    padding: "10px 15px",
  },
  container: {
    width: "100%",
    height: "calc(100% - 20px)",
    paddingTop: 20,
    overflowY: "scroll",
    paddingRight: 17,
    boxSizing: "content-box",
  },
  section: {
    display: "block",
    marginBottom: 40,
  },
  title: {
    color: "#000000",
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: 20,
  },
  loadingState: {
    width: "100%",
    height: "calc(100vh - 100px)",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "45%",
  },
  addMore: {
    // background: '#FFFFFF',
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    width: "100$",
  },
  action: {
    background: "#FFF",
    border: "1px solid #C2AE7F",
    borderRadius: "25px",
    color: "#C2AE7F",
    padding: "12px 25px",
  },
  why: {
    color: "#999999",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "&:hover": {
      color: "#000000",
    },
  },
  link: {
    alignItems: "center",
    display: "flex",
  },
  arrow: {
    marginRight: 5,
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  feature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  header: {
    color: "#333333",
    fontSize: "1.6rem",
    fontWeight: 600,
    marginBottom: 20,
  },
  description: {
    color: "#666666",
    fontSize: "1.3rem",
    lineHeight: 1.4,
    textAlign: "center",
    width: "70%",
  },
}));

const MembersPage = (props) => {
  const classes = useStyles();
  const {
    users,
    currentSpace,
    allUsers,
    allAdmins,
    t,
    isLoading,
    currentUser,
  } = props;
  const [newUser, setNewUser] = React.useState(false);
  const [addUser, setAddUser] = React.useState(false);
  const [moreUsers, setMoreUsers] = React.useState(false);
  const isCurrentUserSysAdmin = currentUser?.isSystemAdmin;
  const titleRef = useRef();

  const [admins, setAdmins] = React.useState([]);
  const [members, setMembers] = React.useState([]);

  useEffect(() => {
    if (moreUsers)
      setTimeout(
        () => titleRef.current.scrollIntoView({ behavior: "smooth" }),
        0
      );
  }, [moreUsers]);

  useEffect(() => {
    if (currentSpace && currentSpace.admins) setAdmins(currentSpace.admins);

    if (currentSpace && currentSpace.members) setMembers(currentSpace.members);
  }, [currentSpace]);

  const handleUpgrade = () => {
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
    props.dispatch(setUpgradeAccount(true));
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/* ..............Circular Loader...................... */}
        {isLoading && (
          <div className={classes.loadingState}>
            <CircularProgress className={classes.loader} />
          </div>
        )}

        {/* --------------------------------------------------------------- */}
        {/* ADD BUTTON FOR */}
        {/* FREE UPTO 3 ADMINS */}
        {/* PLUS UPTO 5 USERS */}
        {/* PRO UNLIMITED USERS */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.inviteRow}>
          {isCurrentUserSysAdmin && (
            <div
              className={classes.inviteButton}
              onClick={() => setAddUser(true)}
            >
              Add User
            </div>
          )}
          {((currentSpace?.onboardingStatus !== "plus" &&
            currentSpace?.onboardingStatus !== "pro" &&
            allAdmins < 3) ||
            (currentSpace?.onboardingStatus == "plus" && allUsers < 5) ||
            currentSpace?.onboardingStatus == "pro") && (
            // && allUsers < 10
            <div
              className={classes.inviteButton}
              onClick={() => setNewUser(true)}
            >
              {t("members.inviteMember")}
            </div>
          )}
        </div>

        <div className={classes.section}>
          <Grid container spacing={2}>
            {/* --------------------------------------------------------------- */}
            {/* ADMINS */}
            {/* --------------------------------------------------------------- */}
            {currentSpace &&
              admins.length &&
              admins.map((profile) => (
                <Grid item xs={12} key={profile} align="center">
                  <UserTile
                    userID={profile}
                    userType="admin"
                    user={users[profile]}
                  />
                </Grid>
              ))}

            {/* --------------------------------------------------------------- */}
            {/* MEMBERS */}
            {/* --------------------------------------------------------------- */}
            {currentSpace &&
              members.length &&
              (currentSpace?.onboardingStatus == "plus" ||
                currentSpace?.onboardingStatus == "pro") &&
              members.map((profile) => (
                <Grid item xs={12} key={profile} align="center">
                  <UserTile
                    userID={profile}
                    userType="member"
                    user={users[profile]}
                  />
                </Grid>
              ))}
          </Grid>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* PENDING INVITE */}
        {/* --------------------------------------------------------------- */}
        {currentSpace &&
          currentSpace.pendingUsers &&
          currentSpace.pendingUsers.length !== 0 && (
            <div className={classes.section}>
              <div className={classes.title}>{t("members.pending")}</div>
              <Grid container spacing={2}>
                {currentSpace &&
                  currentSpace.pendingUsers &&
                  currentSpace.pendingUsers.map((profile) => (
                    <Grid item xs={12} key={profile} align="center">
                      <PendingUser user={users[profile]} userID={profile} />
                    </Grid>
                  ))}
              </Grid>
            </div>
          )}

        {/* --------------------------------------------------------------- */}
        {/* AT USER LIMIT */}
        {/* --------------------------------------------------------------- */}
        {((currentSpace?.onboardingStatus !== "plus" &&
          currentSpace?.onboardingStatus !== "pro" &&
          allAdmins > 2) ||
          (currentSpace?.onboardingStatus == "plus" && allUsers > 4)) && ( //||
          //(currentSpace?.onboardingStatus == 'pro' && allUsers > 9)
          <>
            <div className={classes.addMore}>
              <div
                className={clsx(classes.button, classes.action)}
                onClick={handleUpgrade}
              >
                Upgrade to add more users
              </div>
            </div>
            <div className={classes.why}>
              <div
                className={classes.link}
                onClick={() =>
                  moreUsers ? setMoreUsers(false) : setMoreUsers(true)
                }
              >
                <div className={classes.arrow}>
                  {moreUsers ? <DownArrow /> : <UpArrow />}
                </div>
                <div>Why would I need to add more users?</div>
              </div>
            </div>
          </>
        )}

        {/* --------------------------------------------------------------- */}
        {/* UPSELL REASONS */}
        {/* --------------------------------------------------------------- */}
        {moreUsers && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <div ref={titleRef} className={classes.feature}>
                <div className={classes.header}>Accountability</div>
                <div className={classes.description}>
                  Always know how's making changes and creating payments when
                  everyone has their own login
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.feature}>
                <div className={classes.header}>Security</div>
                <div className={classes.description}>
                  Keep passwords safe and only share sensitive information with
                  users who have certain permissions
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.feature}>
                <div className={classes.header}>Autonomy</div>
                <div className={classes.description}>
                  Delegate tasks and empower employees to work independently
                  with the capabilities they need
                </div>
              </div>
            </Grid>
          </Grid>
        )}

        {newUser && (
          <InviteUser open={newUser} handleClose={() => setNewUser(false)} />
        )}
        {addUser && (
          <AddUserModal open={addUser} handleClose={() => setAddUser(false)} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  users: state.users,
  allUsers: usersCount(state.spaces, state.dashboard.currentSpace),
  allAdmins: adminsCount(state.spaces, state.dashboard.currentSpace),
  isLoading:
    state.loading.DELETE_PENDING_USER ||
    state.loading.LOAD_SPACE ||
    state.loading.INVITE_USER,
});

export default withTranslation()(connect(mapStateToProps)(MembersPage));
