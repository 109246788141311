import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';

import ModuleUsersAvatar from './moduleUsersAvatar';
import UserFeaturePermission from './UserFeaturePermission';
import { fetchModuleUsers } from '../../../redux/actions/permissions/permissions';

const useStyles = makeStyles(theme=>({
    root:{
        display: 'flex',
        marginTop: '12px',
    },
    button: {
        color: '#666666',
        cursor: 'pointer',
        display: 'flex',
        background: '#fff',
        boxShadow: '0 1px 10px #E0E0E0',
        alignItems: 'center',
        marginLeft: '5px',
        borderRadius: '25px',
        padding: '8px',
        '& .icon':{
            width: '15px',
            height: '15px'
        },
        '&:hover':{
            backgroundColor: '#dedede'
        }
    },
}));

const AccessHeaderAction = props =>{
    const { 
        currentSpace, 
        dispatch, 
        moduleID, 
        isAdmin, 
        maxDisplayLimit = null,
        loadAcessOnModalOpen = false 
    } = props;

    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);

    useEffect(()=>{
        fetchUsers();
    }, [currentSpace._id]);

    const fetchUsers = () =>{
        const params = { 
            spaceID: currentSpace._id, 
            module: moduleID,
        }
        dispatch(fetchModuleUsers(params));
    }

    const openModalHandler = () =>{
        if(isAdmin){
            if(loadAcessOnModalOpen) fetchUsers();
            setShowModal(true);
        }
    }

    const closeModalHandler = () =>{
        setShowModal(false);
    }

    return(
        <>
            <div className={clsx(classes.root, props.className)}> 
                <ModuleUsersAvatar 
                    moduleID={moduleID}
                    isAdmin={isAdmin}
                    maxDisplayLimit={maxDisplayLimit}
                    clickHandler={openModalHandler} 
                />
                {isAdmin && (
                    <div 
                        data="action-btn"
                        className={classes.button}
                        onClick={openModalHandler}
                    >
                        <EditIcon className="icon" />
                    </div>
                )}
            </div>
            {showModal && (
                <UserFeaturePermission 
                    moduleID={moduleID} 
                    closeDialogHandler={closeModalHandler}
                />
            )}
        </>
    )
}

const mapStateToProps = state =>{
    const { spaces, dashboard, auth } = state;
    const currentUser = auth.user._id;
    const currentSpace = spaces[dashboard.currentSpace] || {};
    const isAdmin = currentSpace.admins && currentSpace.admins.includes(currentUser);
    return{
        currentSpace,
        isAdmin
    }
}

export default connect(mapStateToProps)(AccessHeaderAction);