import { makeStyles, Grid } from '@material-ui/core'
import React, { useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import ReviewsDetails from './ReviewsDetails';
import { returnCurrent } from '../../redux/selectors/dashboard';
import { getChannelRouteInfo, setModalRoute, fetchDateRange } from './helper';
import { getInboxReviews } from '../../redux/actions/microsite/hostelworld';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px 0',
    padding: '20px 0',
  },
  title: {
    color: '#484848',
    fontSize: '1.8rem',
    fontWeight: 600
  },
  button: {
    color: '#006699',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  row: {
    borderBottom: '1px solid #E0E0E0',
    color: '#484848',
    fontSize: '1.1rem',
    paddingBottom: 10,
    margin: '10px 0',
  },
  review: {
    alignItems: 'center',
    borderRadius: 5,
    background: '#006699',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    height: 25,
    fontWeight: 600,
    width: 25,
  },
  link: {
    background: '#EEEEEE',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    padding: '5px 10px',
    '&:hover': {
      background: '#BDBDBD',
    }
  },
  loaderContainer: {
    height: 100,
    position: 'relative'
  },
  loader: {
      textAlign: 'center',
      margin: 'auto',
      position: 'absolute',
      left: 0,
      border: 0,
      right: 0,
      top: '30%',
  },
  noDataContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.8rem",
    color: "#484848",
  },
  comments: {
    display: "block",
    display: "-webkit-box",
    maxWidth: "400px",
    height: "auto",
    margin: "0 auto",
    lineHeight: 1.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    padding: "unset !important",
  }
}))

const ReviewSection = props => {
  const classes = useStyles();
  const { dispatch, currentSpace } = props;

  const pathname = useLocation().pathname;
  const history = useHistory();
  const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);

  const [detailsOpen, setOpenDetails] = useState(false);
  const [latestReviews, setLatestReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() =>{
    //CHECK IF MODAL PATH EXISTS AND OPEN MODAL ON LOAD..........................
    if(modalPath && modalPath == 'reviews') openReviewDetailsModal(true);
    getHWLatestReviews();
  }, []);

  const openReviewDetailsModal = (status) =>{
    const routeData = { 
      modalPath: 'reviews', 
      tabPath, 
      basePath 
    };
    setOpenDetails(status);
    setModalRoute(status, history, routeData);
  }

  const getHWLatestReviews = () =>{

    const { reportFrom: startDate, reportTo: endDate } = fetchDateRange('past15');

    const params = {
      startDate: startDate.clone().format("YYYY-MM-DD"),
      endDate: endDate.clone().format("YYYY-MM-DD")
    }

    setIsLoading(true);
    dispatch(getInboxReviews(currentSpace.propertyID, params))
      .then(data =>{
        if(data && data.length > 0){ 
          const updatedData = data.slice(0, 3);
          setLatestReviews(updatedData);
        }
        setIsLoading(false);
      })
  }

  return (
    <div className={classes.root}>
      
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <div className={classes.title}>Latest reviews</div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.button} onClick={() => openReviewDetailsModal(true)}>View All Reviews</div>
        </Grid>

        {latestReviews.length > 0 && (
          <Grid item xs={12}>
            {latestReviews.map((review, index) =>(
              <div className={classes.row} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={1}><div className={classes.review}>{review.overallScore}</div></Grid>
                  <Grid item xs={2}>{review.guestName}</Grid>
                  {/* <Grid item xs={2}>Male, 25-30</Grid> */}
                  <Grid item xs={2}>{review.ageRange}</Grid>
                  <Grid item xs={1}>{review.nationality}</Grid>
                  <Grid item xs={6} className={classes.comments}>{review?.comments || 'N/A'}</Grid>
                  {/* <Grid item xs={1}><div className={classes.link}>Reply</div></Grid> */}
                </Grid>
              </div>
            ))}
          </Grid>
        )}
      </Grid>

      {/* ..............Circular Loader...................... */}
      {isLoading && latestReviews.length == 0 && (
        <div className={classes.loaderContainer}>
          <CircularProgress size={35} className={classes.loader} />
        </div>
      )}

      {/* ..............No Data Display...................... */}
      {!isLoading && latestReviews.length  == 0 && (
        <div className={classes.noDataContainer}>
          No recent reviews
        </div>
      )}

      {detailsOpen && (
        <ReviewsDetails 
          open={detailsOpen} 
          handleClose={() => openReviewDetailsModal(false)} 
        />
      )}
      
    </div>
  )
}

const mapStateToProps = state =>{
  const { spaces, dashboard } = state;
  return {
    currentSpace: returnCurrent(spaces, dashboard.currentSpace),
  }
}

export default connect(mapStateToProps)(ReviewSection);
