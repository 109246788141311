import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { MODULE } from "../../../common/constants/permission";

const buttonHeight = "25px";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 85,
    height: buttonHeight,
    padding: 0,
    overflow: "unset",
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#fff !important",
      boxShadow: "0 1px 10px #E0E0E0",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(35px)",
    },
  },
  switchBase: {
    padding: 0,
  },
  track: {
    height: buttonHeight,
    borderRadius: "18px",
    backgroundColor: "#E0E0E0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checkedIcon: {
    background: " #4F9581",
    color: "#fff",
    width: "50px",
    height: buttonHeight,
    borderRadius: "18px",
    fontSize: "11px",
    lineHeight: buttonHeight,
    fontWeight: "bold",
  },
  icon: {
    background: " #fff",
    color: "#BDBDBD",
    boxShadow: "0 1px 10px #E0E0E0",
    width: "50px",
    height: buttonHeight,
    borderRadius: "18px",
    fontSize: "11px",
    lineHeight: buttonHeight,
    fontWeight: "bold",
  },
}));

const CustomSwitch = ({
  isActive,
  updateGroupPermissions,
  isCurrentMakerAdmin,
}) => {
  const classes = useStyles();

  const onChangeHandler = (event) => {
    event.preventDefault();
    const status = event.target.checked ? "grant" : "deny";
    updateGroupPermissions(status);
  };

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
      }}
      icon={<span className={classes.icon}>All</span>}
      checkedIcon={<span className={classes.checkedIcon}>All</span>}
      checked={isCurrentMakerAdmin ? true : isActive}
      disabled={isCurrentMakerAdmin ? true : false}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state, props) => {
  const { accessData = {} } = state.permissions;
  let isActive = false;
  if (accessData[props.groupID]) {
    const activeModuleCount = accessData[props.groupID].length;
    const modules = Object.keys(MODULE).filter(
      (id) => MODULE[id].GROUP == props.groupID
    );
    const totalModuleCount = modules.length;
    isActive = activeModuleCount == totalModuleCount ? true : false;
  }

  return {
    isActive: isActive,
  };
};

export default connect(mapStateToProps)(CustomSwitch);
