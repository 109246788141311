import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { TwitterPicker, ChromePicker } from "react-color";
import clsx from "clsx";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import {
  fetchConfig,
  saveConfig,
  updateConfig,
  resetConfig,
} from "../../redux/actions/odoo/settings";
import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";
import { getRoomTypeMapping } from "../../redux/actions/odoo/roomType";
import { getPayMethodMapping } from "../../redux/actions/odoo/paymentMethod";

import Select from "react-select";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import RoomType from "./RoomType";
import PaymentMethod from "./PaymentMethod";
import { MODULE } from "../../common/constants/permission";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 300,
    width: "100%",
    position: "absolute",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  listSection: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  urlRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 10,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  nonEdit: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 10,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 400,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  bottomMargin: {
    marginBottom: 25,
  },
  topMargin: {
    marginTop: 15,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 30,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 2,
    width: "80%",
  },
  errorMessage: {
    color: "#f44336",
    fontSize: 12,
  },
  additionalIcon: {
    backgroundColor: "#fff",
    marginLeft: "10px",
    boxShadow: "0 2px 20px #dedede",
    height: "35px",
    width: "35px",
  },
  rowItemsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 30,
  },
  startContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.35rem",
    fontWeight: 600,
  },
  endContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 30,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  iconRow: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 25,
  },
  tabInfo: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    textAlign: "center",
    background: "#F8F9FB",
    borderRadius: "15px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    height: "100px",
    justifyContent: "center",
    marginRight: 25,
    width: "100px",
    paddingRight: 5,
    paddingLeft: 5,
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  tabSelected: {
    background: "#E0E0E0",
    color: "#333333",
    fontWeight: 400,
  },
  editContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  iconView: {
    borderRadius: "50%",
    //background: '#ffffff',
    color: "#666666",
    cursor: "pointer",
    fontSize: "2rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#dddddd",
    },
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    minWidth: "180px",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const Odoo = (props) => {
  const { t } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { open, handleClose, currentSpace, loadingProperty, odoo, property } =
    props;
  const [toggle, setToggle] = React.useState(false);
  const [init, setInit] = React.useState(true);
  const [save, setSave] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [isExpanded, toggleExpanded] = React.useState(false);
  const [error, setError] = React.useState({
    serverURL: false,
    db: false,
    username: false,
    password: false,
  });
  const [form, setForm] = React.useState({
    serverURL: "",
    db: "",
    username: "",
    password: "",
    port: "",
  });
  const [selectedTab, setSelectedTab] = React.useState("settings");
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    if (property.default)
      props.dispatch(fetchProperty(currentSpace.propertyID));
    props.dispatch(fetchConfig(currentSpace.propertyID));
  }, []);

  useEffect(() => {
    if (odoo && !odoo.default && init) {
      //setToggle(odoo.isActive)
      setEdit(!odoo.isActive);
      setForm({
        ...form,
        serverURL: odoo.serverURL,
        db: odoo.db,
        username: odoo.username,
        password: odoo.password,
        port: odoo.port ? odoo.port : "",
      });
      setInit(false);
      props.dispatch(getRoomTypeMapping(currentSpace.propertyID));
      props.dispatch(getPayMethodMapping(currentSpace.propertyID));
    }
  }, [odoo]);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "Odoo" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleToggle = (value) => {
    setToggle(value);
    if (value) {
      setSelectedTab("settings");
    }
    if (!value)
      props.dispatch(
        editProperty(currentSpace.propertyID, { disableAppFeature: "Odoo" })
      );
    else
      props.dispatch(
        editProperty(currentSpace.propertyID, { appFeature: "Odoo" })
      );
  };

  const handleCloseModal = () => {
    handleClose();
    props.dispatch(resetConfig());
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const handleChange = (e) => {
    let data = { ...form, [e.target.name]: e.target.value };
    if (!save) setSave(true);
    if (error[e.target.name]) setError({ ...error, [e.target.name]: false });
    setForm(data);
  };

  const expandSettings = () => {
    toggleExpanded(!isExpanded);
  };

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ["serverURL", "db", "username", "password"];
    mandatoryFields.map((item) => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    });
    if (foundError) {
      setSave(false);
      setError({ ...error, ...errorStep });
      return false;
    } else return true;
  };

  const handleSubmit = () => {
    const check = handleValidate();
    if (check) {
      if (odoo) {
        props.dispatch(updateConfig(currentSpace.propertyID, form));
      } else {
        props.dispatch(
          saveConfig({ ...form, propertyID: currentSpace.propertyID })
        );
      }
    }
    setInit(true);
  };

  const handleEdit = () => {
    if (odoo && !odoo.default) {
      if (edit) {
        setForm({
          ...form,
          serverURL: odoo.serverURL,
          db: odoo.db,
          username: odoo.username,
          password: odoo.password,
          port: odoo.port ? odoo.port : "",
        });
        setSave(false);
      }
      setEdit(!edit);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Odoo</div>
          <AccessHeaderAction moduleID={MODULE.ODOO.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        {loadingProperty && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        {!loadingProperty && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.odoo.helperText")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.bottomMargin}
                >
                  <Grid item xs={7}>
                    <div className={classes.title}>Odoo</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={() => handleToggle(false)}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={() => handleToggle(true)}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {toggle && (
                  <div className={clsx(classes.iconRow, classes.tabInfo)}>
                    <div
                      id="settings"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: selectedTab === "settings",
                      })}
                      onClick={() => handleTabSelect("settings")}
                    >
                      {t("automate.odoo.settings")}
                    </div>
                    <div
                      id="roomType"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: selectedTab === "roomType",
                      })}
                      onClick={() => handleTabSelect("roomType")}
                    >
                      {t("automate.odoo.roomType")}
                    </div>
                    <div
                      id="paymentMethod"
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: selectedTab === "paymentMethod",
                      })}
                      onClick={() => handleTabSelect("paymentMethod")}
                    >
                      {t("automate.odoo.paymentMethods")}
                    </div>
                  </div>
                )}
                {toggle && selectedTab === "settings" && (
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <div className={classes.row}>
                        <div
                          className={clsx(
                            classes.mainContentCard,
                            classes.rowItemsHeader
                          )}
                        >
                          <div className={classes.startContainer}>
                            <span>{t("automate.odoo.basicConfig")}</span>
                          </div>
                          <div className={classes.endContainer}>
                            {!edit && (
                              <span
                                className={classes.editContainer}
                                onClick={handleEdit}
                              >
                                <EditIcon className={classes.iconView} />
                              </span>
                            )}
                            {edit && (
                              <span
                                className={classes.editContainer}
                                onClick={handleEdit}
                              >
                                <CloseIcon className={classes.iconView} />
                              </span>
                            )}
                          </div>
                        </div>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.topMargin}
                        >
                          <Grid item xs={4}>
                            <div className={classes.label}>
                              {t("automate.odoo.serverURL")}
                            </div>
                            {edit && error.serverURL && (
                              <div className={classes.errorMessage}>
                                <span>{t("automate.odoo.serverURLError")}</span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {edit && (
                              <div className={classes.field}>
                                <InputBase
                                  id="serverURL"
                                  name="serverURL"
                                  value={`${form.serverURL}`}
                                  placeholder="https://your-company-db.odoo.com"
                                  fullWidth
                                  type="text"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                            {!edit && (
                              <div className={classes.nonEdit}>
                                {odoo && odoo.serverURL ? odoo.serverURL : ""}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.topMargin}
                        >
                          <Grid item xs={4}>
                            <div className={classes.label}>
                              {t("automate.odoo.db")}
                            </div>
                            {edit && error.db && (
                              <div className={classes.errorMessage}>
                                <span>{t("automate.odoo.dbError")}</span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {edit && (
                              <div className={classes.field}>
                                <InputBase
                                  id="db"
                                  name="db"
                                  value={`${form.db}`}
                                  placeholder="your-company-db"
                                  fullWidth
                                  type="text"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                            {!edit && (
                              <div className={classes.nonEdit}>
                                {odoo && odoo.db ? odoo.db : ""}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.topMargin}
                        >
                          <Grid item xs={4}>
                            <div className={classes.label}>
                              {t("automate.odoo.userName")}
                            </div>
                            {edit && error.username && (
                              <div className={classes.errorMessage}>
                                <span>{t("automate.odoo.userNameError")}</span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {edit && (
                              <div className={classes.field}>
                                <InputBase
                                  id="username"
                                  name="username"
                                  value={`${form.username}`}
                                  placeholder="Email ID which generated the API Key"
                                  fullWidth
                                  type="text"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                            {!edit && (
                              <div className={classes.nonEdit}>
                                {odoo && odoo.username ? odoo.username : ""}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.topMargin}
                        >
                          <Grid item xs={4}>
                            <div className={classes.label}>
                              {t("automate.odoo.apiKey")}
                            </div>
                            {edit && error.password && (
                              <div className={classes.errorMessage}>
                                <span>{t("automate.odoo.apiKeyError")}</span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {edit && (
                              <div className={classes.field}>
                                <InputBase
                                  id="password"
                                  name="password"
                                  placeholder="Invoicing > Preference > Account Security > New API Key"
                                  defaultValue=""
                                  fullWidth
                                  type="text"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                            {!edit && (
                              <div className={classes.nonEdit}>
                                {odoo && odoo.password ? odoo.password : ""}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.row}>
                        <div
                          className={clsx(
                            classes.mainContentCard,
                            classes.rowItemsHeader
                          )}
                        >
                          <div className={classes.startContainer}>
                            <span>{t("automate.odoo.addnSettings")}</span>
                          </div>
                          <div className={classes.endContainer}>
                            <Fab
                              aria-label="add"
                              className={clsx(classes.additionalIcon)}
                              onClick={expandSettings}
                            >
                              {isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </Fab>
                          </div>
                        </div>
                        {isExpanded && (
                          <Grid
                            container
                            alignItems="center"
                            className={classes.topMargin}
                          >
                            <Grid item xs={4}>
                              <div className={classes.label}>Port</div>
                            </Grid>
                            <Grid item xs={8}>
                              {edit && (
                                <div className={classes.field}>
                                  <InputBase
                                    id="port"
                                    name="port"
                                    value={`${form.port}`}
                                    fullWidth
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              )}
                              {!edit && (
                                <div className={classes.nonEdit}>
                                  {odoo && odoo.port ? odoo.port : ""}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </div>
                      {edit && save && (
                        <div className={classes.buttonsContainer}>
                          <div
                            className={classes.button}
                            onClick={handleSubmit}
                          >
                            {t("actions.save")}
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
                {toggle && selectedTab === "roomType" && (
                  <div>
                    <RoomType />
                  </div>
                )}
                {toggle && selectedTab === "paymentMethod" && (
                  <div>
                    <PaymentMethod />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            autoHideDuration={3000}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingProperty: state.loading.LOAD_PROPERTY,
  property: state.property,
  odoo: state.odoo,
});

export default withTranslation()(connect(mapStateToProps)(Odoo));
