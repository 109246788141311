import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD COLOMBIA SETTINGS
//----------------------------------------------------------------
export function fetchColombiaSettings(propertyID) {
  const ACTION_NAME = "LOAD_COLOMBIA_DETAILS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/colombiaPropertyDetails/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SUBMIT COLOMBIA SETTINGS
//----------------------------------------------------------------
export function submitColombiaSettings(propertyID, form) {
  const ACTION_NAME = "SUBMIT_COLOMBIA_DETAILS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/colombiaPropertyDetails`, {...form, propertyID})
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}