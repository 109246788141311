import http from "../../utils/http";
import { normalize } from 'normalizr';
import { transactionSchema } from '../../schema/transactionModeSchema';
import { getReservations } from "../beds/beds";
import { printFiscalReceipt } from "./fiscalPrinter";
import { fetchDatePerTimezone, openPDFWindow } from '../../../utils/utility';

//----------------------------------------------------------------
// LOAD RESERVATION DETAILS
//----------------------------------------------------------------
export function getReservationDetailsViaCode(propertyCode, reservationCode) {
  const ACTION_NAME = "LOAD_RESERVATION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reservation/${propertyCode}/${reservationCode}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data?.error?.error || errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// ADD RESERVATION
//----------------------------------------------------------------
export function createReservation(payload, propertyID, calendarData) {
  const ACTION_NAME = "ADD_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post('/api/frontdesk/reservation/intelligent/', { ...payload })
      .then(successResult => {
        let finalData = [];
        const data = successResult.data || {};
        let newReservation = data.newRes;
        if (calendarData?.startDate && calendarData?.endDate) {
          dispatch(
            getReservations(
              propertyID,
              calendarData.startDate,
              calendarData.endDate,
              true
            )
          )
        }
        let accommodationList = [...newReservation.accommodationList] || [];
        accommodationList.map(acc => {
          let accData = { ...acc };
          finalData.push({
            roomID: accData.roomID,
            bedID: accData.bedID,
            guestName: newReservation.guestName,
            checkIn: accData.checkIn,
            checkOut: accData.checkOut,
            resID: newReservation._id,
            accommodationID: accData._id,
            status: newReservation.status,
            balance: newReservation.balance,
            totalAmount: newReservation.totalAmount,
            noteCount: newReservation.notes.length
          })
          return acc;
        })
        
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: finalData })
      })
      .catch(errorResult => {
        // console.log("errorResult", errorResult.response)
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}

//----------------------------------------------------------------
// EDIT RESERVATION [NEW]
//----------------------------------------------------------------
export function editReservation(payload, propertyID, calendarData) {
  console.log('calendarData ==>', calendarData)
  const ACTION_NAME = "UPDATE_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/edit/", payload)
      .then(successResult => {
        let data = {};
        let deletedKeys = [];
        if (!successResult.data.updatedRes) {
          data.updatedRes = successResult.data;
          deletedKeys = null;
        } else {
          data = successResult.data;
        }
        deletedKeys = successResult?.data?.deletedAccommodations || null;
        if (calendarData) {
          dispatch(getReservations(propertyID, calendarData.startDate, calendarData.endDate, true))
        }
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, deletedKeys: deletedKeys, payload: data });
      })
      .catch(errorResult => {
        let errorRes = errorResult && errorResult.response && errorResult.response.data ? errorResult.response.data : errorResult;
        const { error } = errorRes;
        const message = error?.error?.message || error?.message || 'Update failed';
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: message } });
      });
  };
}


//----------------------------------------------------------------
// ADD ROOM BLOCK
//----------------------------------------------------------------
export function createRoomBlock(payload, propertyID, calendarData) {
  const ACTION_NAME = "ADD_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/frontdesk/blocks/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch(
          getReservations(
            propertyID,
            calendarData.startDate,
            calendarData.endDate,
            true
          )
        )
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        // console.log("errorResult", errorResult.response)
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}

const updateCalendarResvNoteCount = (dispatch, reservationID, notes = []) =>{
  const noteCount = notes.filter(note => !note.isArchived).length;
  dispatch({ 
    type: `UPDATE_CALENDAR_NOTE_COUNT_SUCCESS`, 
    payload: {
      reservationID,
      noteCount
    }
  });
}

//----------------------------------------------------------------
// ADD NOTE
//----------------------------------------------------------------
export function createReservationNote(note) {
  const ACTION_NAME = "ADD_RESERVATION_NOTE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post('/api/frontdesk/reservation/note', { ...note })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        if(data) updateCalendarResvNoteCount(dispatch, note.reservationID, [{ ...data }]);
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// DELETE NOTE
//----------------------------------------------------------------
export function deleteReservationNote(reservationID, noteID) {
  const ACTION_NAME = "DELETE_RESERVATION_NOTE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch('/api/frontdesk/reservation/note/'+ reservationID, { noteID })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        if(data) updateCalendarResvNoteCount(dispatch, reservationID, data);
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// GET TRANSACTION MODE
//----------------------------------------------------------------
export function fetchTransactionMode(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_TRANSACTION_MODES";
  return function (dispatch) {
    return http.get(`/api/frontdesk/transactionMode/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [transactionSchema]);
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID, ...normalizedOrder } })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// CREATE TRANSACTION MODE
//----------------------------------------------------------------
export function createTransactionMode(spaceID, propertyID, name) {
  const ACTION_NAME = "CREATE_TRANSACTION_MODE";
  return function (dispatch) {
    return http.post('/api/frontdesk/transactionMode/', { name, propertyID })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data, spaceID } })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// UPDATE TRANSACTION MODE
//----------------------------------------------------------------
export function updateTransactionMode(spaceID, body) {
  const ACTION_NAME = "UPDATE_TRANSACTION_MODE";
  return function (dispatch) {
    return http.patch('/api/frontdesk/transactionMode', body)
      .then(successResult => {
        const data = successResult.data;
        var normalizedModeOrder = normalize(data, [transactionSchema]);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID, ...normalizedModeOrder } })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// ADD PAYMENT TRANSACTION
//----------------------------------------------------------------
export function createReservationPayment(reservationID, transaction, printerSettings) {
  console.log(reservationID, transaction)
  const ACTION_NAME = "ADD_RESERVATION_PAYMENT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/frontdesk/transaction/${reservationID}`, { ...transaction })
      .then(successResult => {
        const data = successResult.data
        if(printerSettings?.toggle && printerSettings?.printingType === 'file') dispatch(printFiscalReceipt(data, printerSettings))
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;

        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
      });
  }
}


//----------------------------------------------------------------
// UPDATE RESERVATION
//----------------------------------------------------------------
export function updateReservation(payload, formtype, propertyID, calendarData) {
  const ACTION_NAME = "UPDATE_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/edit/", payload)
      .then(successResult => {
        let data = {};
        let deletedKeys = [];
        if(formtype === 'shorten'){
          deletedKeys = [{accommodationID: payload.accommodationID, bedID: payload.bedID}];
        } else if(formtype === 'roomChange'){
          deletedKeys =  (data.deletedAccommodations && data.deletedAccommodations.length) ? data.deletedAccommodations : [];
        }
        if (!successResult.data.updatedRes) {
          data.updatedRes = successResult.data;
          deletedKeys = null;
        } else {
          data = successResult.data;
        }
        console.log("payload, formtype, propertyID, calendarData", payload, formtype, propertyID, calendarData)
        if(calendarData){
          dispatch(getReservations(propertyID, calendarData.startDate, calendarData.endDate, true))
        }
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          deletedKeys: deletedKeys,
          payload: data
        });
      })
      .catch(errorResult => {
        console.log(errorResult)
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
            console.log(error)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// EDIT BASIC RESERVATION DETAILS
//----------------------------------------------------------------
export function updateReservationDetails(reservationID, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_DATA";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reservation/edit/${reservationID}`, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: payload
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CANCEL RESERVATION DATA
//----------------------------------------------------------------
export function cancelReservation(id, payload) {
  const ACTION_NAME = "CANCEL_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/frontdesk/reservation/cancel/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE RESERVATION TAX
//----------------------------------------------------------------
export function createReservationTax(id, payload) {
  const ACTION_NAME = "MANAGE_RESERVATION_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/frontdesk/reservation/manualTax/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION TAX
//----------------------------------------------------------------
export function updateReservationTax(id, payload) {
  const ACTION_NAME = "MANAGE_RESERVATION_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/manualTax/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION STATUS
//----------------------------------------------------------------
export function updateReservationStatus(id, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_STATUS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/status/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION STATUS SHORTCUT
//----------------------------------------------------------------
export function updateReservationStatusShortcut(id, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_STATUS_SHORTCUT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/status/" + id, payload)
      .then(successResult => {
        console.log(successResult.data);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
        return successResult.data
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return false;
      });
  };
}

//----------------------------------------------------------------
// VOID RESERVATION TRANSACTION
//----------------------------------------------------------------
export function voidReservationTransaction(transactionID, printerSettings) {
  const ACTION_NAME = "VOID_RESERVATION_TRANSACTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/transaction/void/" + transactionID)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        })
        if(printerSettings?.toggle && printerSettings?.printingType === 'file') {
          let soap = ['<?xml version="1.0" encoding="utf-16"?>', '<printerVoidReceipt>', '</printerVoidReceipt>']
          
          const url = window.URL.createObjectURL(
            new Blob([soap.join('\n')]),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'scontrino.xml'
          )

          document.body.appendChild(link)

          link.click()
          link.parentNode.removeChild(link)
        }
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// DELETE RESERVATION TAX
//----------------------------------------------------------------
export function deleteReservationTax(reservationID, payload) {
  const ACTION_NAME = "MANAGE_RESERVATION_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/tax/" + reservationID, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RESERVATION ACTIVITY LOGS
//----------------------------------------------------------------
export function loadActivityLog(reservationID) {
  const ACTION_NAME = "LOAD_ACTIVITY_LOG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/frontdesk/reservation/activity/" + reservationID)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { activityLog: successResult.data }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RESERVATION INVOICE
//----------------------------------------------------------------
export function loadReservationInvoice(reservationID) {
  const ACTION_NAME = "LOAD_RESERVATION_INVOICE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/frontdesk/reservation/invoice/" + reservationID, { responseType: 'arraybuffer' })
      .then(successResult => {
        openPDFWindow(successResult.data, () =>{
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: "Success" });
        });
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: "Success" });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD TAX RECEIPT
//----------------------------------------------------------------
export function loadReservationTaxReceipt(reservationID) {
  const ACTION_NAME = "LOAD_RESERVATION_INVOICE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/frontdesk/reservation/taxInvoice/" + reservationID, { responseType: 'arraybuffer' })
      .then(successResult => {
        openPDFWindow(successResult.data, () =>{
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: "Success" });
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// EDIT RESERVATION WITH ITEMS
//----------------------------------------------------------------
export function updateReservationItems(reservationID, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_ITEMS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/items/" + reservationID, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// UPDATE ROOM BLOCK
//----------------------------------------------------------------
export function updateRoomBlock(propertyID, payload, calendarData) {
  const ACTION_NAME = "UPDATE_ROOMBLOCK";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/blocks/" + propertyID, payload)
      .then(successResult => {
        dispatch(
          getReservations(
            propertyID,
            calendarData.startDate,
            calendarData.endDate,
            true
          )
        )
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data.result,
          deletedKeys: successResult.data.deletedAccommodations
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

export function resetError(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

export function resetSuccess(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

// Show card details ............................
export function fetchChannexSessionToken(scope = 'card'){
    return http.get(`/api/frontdesk/channex/pci/sessionToken?scope=${scope}`);
}

export function fetchViewCvvTokens() {
  return http.get(`/api/frontdesk/channex/pci/viewCardToken`);
}

export function fetchCardDetails(params){
    return http.get(`https://pci.channex.io/api/v1/show_card`, { params })
}

export function channexCaptureCard(reservationID, data, initChannexForm){
  const ACTION_NAME = "CHANNEX_CAPTURE_CARD";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/frontdesk/reservation/data/" + reservationID, { channexCardToken: data.card_token })
      .then(successResult => {
        const { reservation } = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;

        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });

        initChannexForm();

        return;
      });
  };
}

export function transactionRefund(params, data) {
  const ACTION_NAME = "TRANSACTION_REFUND";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/refund/${params.propertyID}/${params.reservationID}/${params.originalTxnID}`, data)
      .then(successResult => {
        const { message: { balance, transaction } } = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            balance,
            transaction
          }
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
        return;
      });
  };
}


export function updateExperienceProductStatus(bookingID, propertyID, data) {
  const ACTION_NAME = "UPDATE_EXPERIENCE_PRODUCT_STATUS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/experiences/bookings/status/${bookingID}/${propertyID}`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
        return;
      });
  };
}


export function updateExperienceBooking(bookingID, propertyID, data) {
  const ACTION_NAME = "UPDATE_EXPERIENCE_PRODUCT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/experiences/bookings/${bookingID}/${propertyID}`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
        return;
      });
  };
}


export function createExperienceBooking(payload) {
  const ACTION_NAME = "CREATE_EXPERIENCE_PRODUCT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/experiences/bookings/`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

export function bulkDeleteRoomBlocks(propertyID, payload, timezone) {
  const ACTION_NAME = "BULK_DELETE_ROOMBLOCK";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/roomBlocks/bulkdelete/${propertyID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch(
          getReservations(
            propertyID,
            fetchDatePerTimezone(null, timezone).subtract(1, 'days').format('YYYY-MM-DD'),
            fetchDatePerTimezone(null, timezone).add(15, 'days').format('YYYY-MM-DD'),
            true
          )
        )
        return data;
      })
      .catch(errorResult => {
        const error = errorResult.response
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: error.data, code: error.status } });;
      });
  };
}