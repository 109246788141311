const PAGES = {
  LOGIN: "login",
  SETUP: "setup",
  LOCK: "lock",
  LANDING: "",
  RESERVATION: "reservation",
  RESERVATION_EDIT: "reservation/edit",
}

const RESERVATION_STATUS = {
  BLOCK: "Block",
  CONFIRMED: 'Confirmed',
  PENDING_CONF: 'Pending Confirmation',
  CHECKED_IN: 'Checked In',
  CHECKED_OUT: 'Checked Out',
  NO_SHOW: 'No Show',
  CANCELLED: 'Cancelled',
  WITHIN_FREE_CANCL: 'Within Free Cancellation Window',
  NO_FREE_CANCEL: 'No Free Cancellation',
  IN_HOUSE: 'In House',
  ONLINE_CHECKED_IN: 'Online Checked In'
}

const EXPERIENCE_STATUS = {
  CANCELLED: 'CANCELLED',
  NOSHOW: 'NOSHOW',
  CONFIRMED: 'CONFIRMED'
}

const UNALLOCATED = 'Unallocated'

const HOUSEKEEPING_CATEGORY = {
  INHOUSE: 'inHouse',
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  BEDSWITCH: 'bedSwitch'
}

const RESERVATION_TYPE = {
  PRIORITY: "Priority",
  ARRIVAL: "Arrivals",
  DEPARTURE: "Departures",
  INHOUSE: "In-house Guests"
}

const TENSE = {
  PAST: "PAST",
  PRESENT: "PRESENT",
  FUTURE: "FUTURE",
}

const EDIT_TYPE = {
  EXTEND: "extend",
  SHORTEN: "shorten",
  ROOM_CHANGE: "roomChange",
  ARRIVAL_CHANGE: "arrivalChange",
}

const TRANSACTION_MODE = {
  CASH: 'cash',
  CARD: 'card',
  HOSTELWORLD: 'HostelWorld',
  NOT_DEF: '',
  CHANNEL: 'Channel',
  CARD_ON_FILE: 'card on file',
  DISCOUNT: 'discount',
  PAYPAL: 'paypal',
  BANK_TRANSFER: 'bank transfer',
  SECURITY_DEPOSIT: 'Security Deposit',
  STRIPE: "Stripe",
  SURCHARGE: "surcharge"
}

const EMAIL_RULE_EVENTS = {
  AFTER_NEW_RESERVATION: 'AFTER_NEW_RESERVATION',
  BEFORE_CHECKIN: 'BEFORE_CHECKIN',
  AFTER_CHECKIN: 'AFTER_CHECKIN',
  BEFORE_CHECKOUT: 'BEFORE_CHECKOUT',
  AFTER_CHECKOUT: 'AFTER_CHECKOUT',
  ON_NOSHOW: 'ON_NOSHOW',
  ON_CANCEL: 'ON_CANCEL',
  ON_PAYMENT: 'ON_PAYMENT'
}

const SIBA_EVENTS = {
  AFTER_CHECKIN: 'afterCheckIn',
  AFTER_CHECKOUT: 'afterCheckOut',
  MANUAL: 'manual',
}

const EMAIL_TAGS = [
  { title: 'Reservation number', tag: '{resvCode}'},
  { title: 'Guest full name', tag: '{gName}'},
  { title: 'Guest first name', tag: '{firstName}'},
  { title: 'Guest last name', tag: '{lastName}'},
  { title: 'Guest phone', tag: '{gPhone}'},
  { title: 'Guest Email', tag: '{gEmail}'},
  { title: 'Check-in date', tag: '{checkIn}'},
  { title: 'Check-out date', tag: '{checkOut}'},
  { title: 'Reservation Total', tag: '{total}'},
  { title: 'Reservation Balance', tag: '{balance}'},
  { title: 'Property Name', tag: '{pName}'},
  { title: 'Property email', tag: '{pEmail}'},
  { title: 'Property phone', tag: '{pPhone}'},
  { title: 'Property Address', tag: '{pAddress}'},
  { title: 'Property City', tag: '{pCity}'},
  { title: 'Property State', tag: '{pState}'},
  { title: 'Property Country', tag: '{pCountry}'},
  { title: 'Property ZIP', tag: '{pZIP}'},
  { title: 'Reservation Amount', tag: '{resvAmount}'},
  { title: 'Amount Paid', tag: '{amountPaid}'},
  { title: 'Room Types', tag: '{roomType}'},
  { title: 'Online Checkin Link', tag: '{onlineCheckinLink}'}
];

const BOOKING_SOURCE = {
  PHONE: "Phone",
  EMAIL: "Email",
  WALKIN: "Walk-In",
  OTHER: "Other"
}

const TRANSACTION_USER_TYPES = {
  WORKSPACE: "WORKSPACE",
  FRONTDESK: "FRONTDESK",
  SYSTEM: "SYSTEM"
}

const FRONTDESK_USER_ROLE = {
  APP_ADMIN: "appAdmin",
  OWNER: "owner",
  MANAGER: "manager",
  FRONT_DESK: "frontDesk",
  READ_ONLY: "readOnly"
};

const TAX_TYPE = {
  AMOUNT_PER_ACCOMMODATION: "amountPerAccommodation",
  AMOUNT_PER_NIGHT: "amountPerNight",
  AMOUNT_PER_PERSON: "amountPerPerson",
  // AMOUNT_PER_RESERVATION: "amountPerReservation",
  PERCENT_OF_TOTAL: "percentOfTotal",
  RATE_SLABS: "rateSlabs"
};

const TAX_CALULATION_MODE = {
  INCLUSIVE: "inclusive",
  EXCLUSIVE: "exclusive"
};

const HW_RATEPLAN_NAME = {
  'default': 'Standard Rate',
  'bb-nrr': 'Non-refundable Bed & Breakfast Rate',
  'nrr-derived': 'Non-refundable Rate',
  'bb': 'Bed & Breakfast Rate'
}

const INVOICE_TYPE = {
  FINAL: 'FINAL',
  PRO_FORMA: 'PROFORMA'
}

const RESERVATION_KIND = {
    dorm: "dorm",
    automatic: "automatic"
}

const CHANNEX_CHANNELS = ['AirBNB', 'Expedia', 'Agoda', 'BookingCom', 'Channex'];

export {
  PAGES,
  RESERVATION_STATUS,
  UNALLOCATED,
  HOUSEKEEPING_CATEGORY,
  RESERVATION_TYPE,
  EDIT_TYPE,
  TRANSACTION_MODE,
  BOOKING_SOURCE,
  EMAIL_RULE_EVENTS,
  TENSE,
  TRANSACTION_USER_TYPES,
  TAX_TYPE,
  TAX_CALULATION_MODE,
  FRONTDESK_USER_ROLE,
  EMAIL_TAGS,
  SIBA_EVENTS,
  HW_RATEPLAN_NAME,
  CHANNEX_CHANNELS,
  EXPERIENCE_STATUS,
  INVOICE_TYPE,
  RESERVATION_KIND
}