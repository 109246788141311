import { schema } from 'normalizr';

const bedSchema = new schema.Entity('beds', {}, {
  idAttribute: '_id'
});

const roomSchema = new schema.Entity('rooms', {}, {
  idAttribute: '_id'
});

const roomTypeSchema = new schema.Entity('roomTypes', {
  rooms: [roomSchema],
  beds: [bedSchema]
}, { 
  idAttribute: '_id' 
});


const resSchema = new schema.Entity('reservations', {}, { 
  idAttribute: 'accommodationID' 
});

export { roomSchema, bedSchema, roomTypeSchema, resSchema }