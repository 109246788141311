import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import http from "../../../redux/utils/http";
import { fetchDateFormat, fetchCurrencyFormat } from "../../../utils/utility";

const useStyles = makeStyles(theme =>({  
    dialog: {
        overflowY: 'hidden'
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 2px 20px #F0F0F0",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 300px)',
        overflowY: 'auto',
        '& .gridContainer': {
            height: '100%'
        },
        '& $mainContent:first-child':{
            paddingRight: '0px'
        },
        '& $mainContent:nth-child(2)':{
            paddingLeft: '5px'
        }
    },
    mainContent:{
        padding: '10px',
    },
    summarySection:{
        padding: '20px 10px 10px 10px',
        backgroundColor: '#f8f9fb',
        height: '100%',
    },  
    inlineContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionTitle: {
        color: '#444444',
        fontSize: '1.5rem',
        fontWeight: 600,
        marginBottom: '10px'
    },
    primary: {
        fontSize: '1.3rem',
        fontWeight: 600,
        marginBottom: '10px'
    },
    secondaryText: {
        color: '#b3b3b3',
        fontSize: '1.1rem',
    },
    summaryCard:{
        padding: '20px',
        border: '5px',
        backgroundColor: '#fff',
        marginBottom: '20px'
    },
    amountText:{
        fontSize: '1.2rem',
        fontWeight: 600,
    },
    amountContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '1.3rem',
        fontWeight: 600,
        margin: '20px 5px 0px 5px',
        '&.red-text':{
            color: '#d96977',
            margin: '15px 5px 0px 5px',
        }
    },
    guestSection:{
        padding: '20px 10px 10px 10px',
        background: '#f8f9fb',
        '& $summaryCard':{
            marginBottom: '0px',
            backgroundColor: 'unset',
            boxShadow: '0 2px 20px #F0F0F0',
        },
        '& $infoBox:last-child':{
            marginBottom: '0px',
        }
    },
    infoBox: {
        fontSize: '1.3rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        '& .label':{
            fontWeight: 600,
            fontSize: '1.2rem',
            width: '50%',
        }
    },
}))

const BookingMask = props =>{

    const defaultBookingData = {
        products: [],
        contact: { 
            firstName: '',
            lastName: ''
        },
        total: {},
        bookingStatus: '',
        reservationID: null,
        balance: 0
    }

    const CancelToken = axios.CancelToken;
    let sourceToken = CancelToken.source();

    const { bookingID, closeModalHandler, dispatch, productInfo, t } = props;
    const classes = useStyles();
    const [ bookingData, setBookingData ] = useState({ ...defaultBookingData });

    useEffect(() => {
        init();
        return () =>sourceToken.cancel('modal closed.');
    },[]);

    const init = () =>{
        http.get(`/api/frontdesk/experiences/bookings/${bookingID}`, {
            cancelToken: sourceToken.token
        })
        .then(successResult => {
            const { contact, products, status, total, reservationID, balance } = successResult.data;
                const displayData = {
                    products,
                    contact,
                    total,
                    bookingStatus: status,
                    reservationID,
                    balance
                }
                setBookingData({ ...displayData });
        })
        .catch(errorResult => {
            let error =
            errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            console.log('error ',error);
        });
    }

    return(
        <Dialog
            open={true}
            onClose={closeModalHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
        >
            <div className={classes.dialog}>

                {/* .....................MODAL HEADER.............................. */}
                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{t('experiences.calendar.bookingDetails')}</div>
                    <CloseIcon className={classes.closeIcon} onClick={closeModalHandler} />
                </div>

                {/* .....................MODAL BODY................................ */}
                <div className={classes.container}>
                    <Grid container className="gridContainer">
                        <Grid item xs={12} sm={7} className={classes.mainContent}>
                            <div className={classes.guestSection}>
                                <div className={classes.sectionTitle}>{t('experiences.calendar.guestDetails')}</div>
                                <div className={classes.summaryCard}>
                                    <div className={classes.infoBox}>
                                        <div className="label">{t('experiences.calendar.status')}: </div>
                                        <div>{bookingData.bookingStatus}</div>
                                    </div>
                                    <div className={classes.infoBox}>
                                        <div className="label">{t('experiences.name')}: </div>
                                        <div>{`${bookingData.contact.firstName} ${bookingData.contact.lastName}`}</div>
                                    </div>
                                    <div className={classes.infoBox}>
                                        <div className="label">{t('experiences.phone')}: </div>
                                        <div>{bookingData.contact.phone}</div>
                                    </div>
                                    <div className={classes.infoBox}>
                                        <div className="label">{t('experiences.email')}: </div>
                                        <div>{bookingData.contact.email}</div>
                                    </div>
                                    <div className={classes.infoBox}>
                                        <div className="label">{t('experiences.calendar.guestType')}: </div>
                                        <div>
                                            {bookingData.reservationID ? 
                                                t('experiences.calendar.inHouseGuest') : 
                                                t('experiences.calendar.externalGuest')
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.mainContent}>
                            <div className={classes.summarySection}>
                                <div className={classes.sectionTitle}>{t('experiences.calendar.summary')}</div>
                                
                                {bookingData.products.map(({ 
                                    productID, 
                                    date,
                                    quantity,
                                    price 
                                }) =>(
                                    <div key={productID} className={classes.summaryCard}>
                                        <div className={classes.primary}>
                                            {productInfo[productID].title}
                                        </div>  
                                        <div className={classes.secondaryText}>
                                            {fetchDateFormat(date, 'D MMM YYYY')}
                                        </div>
                                        <div className={classes.inlineContainer}>
                                            <div className={classes.secondaryText}>
                                                {fetchCurrencyFormat(price.currency)}{price.raw}  {t('experiences.calendar.perPerson')} (x{quantity})
                                            </div>
                                            <div className={classes.amountText}>
                                                {fetchCurrencyFormat(price.currency)}{(price.raw * quantity)}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className={classes.amountContainer}>
                                    <div>{t('experiences.calendar.totalAmount')}</div>
                                    <div>{fetchCurrencyFormat(bookingData.total.currency)}{bookingData.total.raw}</div>
                                </div>

                                <div className={clsx(classes.amountContainer, 'red-text')}>
                                    <div>{t('experiences.calendar.balance')}</div>
                                    <div>{fetchCurrencyFormat(bookingData.total.currency)}{bookingData.balance}</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Dialog>
    );
}

const mapStateToProps = state =>{
    const { experiences } = state;
    return{
        productInfo: experiences.activityList,
    }
}

export default withTranslation()(connect(mapStateToProps)(BookingMask));