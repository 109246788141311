import React from 'react';
import { withTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import IMAGES from "../../../constants/images";

const useStyles = makeStyles(theme =>({
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '60%'
  },
  description: {
    height: 80,
  },
  tooltipText: {
      fontSize: '1rem'
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  },
  infoIcon: {
      cursor: 'pointer',
      height: 12,
      width: 12,
      marginLeft: 10,
      opacity: 0.5
  }
}));

const BasicInfo = props =>{
    const { t } = props
    const classes = useStyles();
    const {
        ratePlan,
        handleUpdateRatePlan
    } = props;

    return(
        <>
            <div className="main-content">

                <div className="main-content-title">{t('ratePlans.basicInfo.contentTitle')}</div>
                <div className="sub-title">{t('ratePlans.basicInfo.subTitle')}</div>

                <div className="row">
                  <div className="label">{t('ratePlans.basicInfo.publicName')}</div>
                  <div className={classes.field}>
                    <InputBase
                      id="publicName"
                      name="publicName"
                      value={ratePlan.publicName}
                      fullWidth
                      type="text"
                      autoFocus
                      onChange={(e) => handleUpdateRatePlan('publicName', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="label">{t('ratePlans.basicInfo.privateName')}</div>
                  <div className={classes.field}>
                    <InputBase
                      id="privateName"
                      name="privateName"
                      fullWidth
                      value={ratePlan.privateName}
                      type="text"
                      onChange={(e) => handleUpdateRatePlan('privateName', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="label">{t('ratePlans.basicInfo.description')}</div>
                  <div className={clsx(classes.field, classes.description)}>
                    <InputBase
                      id="description"
                      name="description"
                      fullWidth
                      value={ratePlan.description}
                      type="text"
                      multiline
                      rows={3}
                      onChange={(e) => handleUpdateRatePlan('description', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div>
                    <Checkbox
                      color="primary"
                      disabled={ratePlan.isDefault}
                      checked={ratePlan.isDefault}
                      checkedIcon={<span className={classes.checkedIcon} />}
                      icon={<span className={classes.icon} />}
                      onChange={(e) => handleUpdateRatePlan('isDefault', e.target.checked)}
                      inputProps={{
                        name: "setDefaultPlan",
                        'aria-label': 'setdefault checkbox'
                      }}
                    /> {t('ratePlans.basicInfo.setDefaultPlan')}
                    <Tooltip placement="bottom-start" title={
                        <div className={classes.tooltipText}> {t('ratePlans.basicInfo.setDefaultPlanToolTip')}</div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                  </div>
                </div>

            </div>
        </>
    )
}

export default withTranslation()(BasicInfo);