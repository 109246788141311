import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Select from 'react-select'

import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone'
import NumberInput from '../../../common/NumberInput'
import clsx from 'clsx'
import IMAGES from '../../../../constants/images'

const useStyles = makeStyles(theme => ({
  box: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    padding: theme.spacing(3)
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    marginBottom: 30
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  dropDownContainer: {
    marginTop: 10
  },
  field: {
    alignItems: 'flex-start',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    flexWrap: 'wrap',
    height: 150,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%'
  },
  minNightsRow: {
    display: 'flex',
    marginBottom: 10
  },
  short: {
    height: 35,
    width: '30%'
  },
  marginLeft: {
    marginLeft: 20
  },
  icon: {
    fontSize: '1.6rem'
  },
  iconDiv: {
    alignSelf: 'center',
    marginTop: 15
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    boxShadow: '0 1px 10px #E0E0E0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

const weekendMinList = [
  { label: 'No Minimum Nights Set', value: 'none' },
  { label: '2 Nights: Friday and Saturday', value: '2 nights' },
  { label: '3 Nights: Friday, Saturday and Sunday', value: '3 nights' }
]

const createTimeArray = isShortValue => {
  const timeArray = []
  let i
  for (i = 0; i < 25; i++) {
    const shortValue = i < 10 ? `0${i}:00` : `${i}:00`
    const longValue = i < 10 ? `0${i}:00:00+00:00` : `${i}:00:00+00:00`
    timeArray.push({ label: shortValue, value: i === 0 ? '' : (isShortValue ? shortValue : longValue) })
  }
  return timeArray
}

const createReleasePeriod = (days) => {
  const releaseArray = [
    { label: 'Anytime', value: 0 }
  ]
  let i
  for (i = 1; i < days + 1; i++) {
    if (i == 1) {
      releaseArray.push({ label: '1 day', value: i })
    } else {
      releaseArray.push({ label: `${i} days`, value: i })
    }
  }
  return releaseArray
}

const dayListCalculate = () => {
  const array = []
  let i
  for (i = 1; i < 31; i++) {
    array.push({ label: i, value: i })
  }
  return array
}

const zeroHour = ':00'

const Policies = props => {
  const classes = useStyles()
  const {
    settings,
    updateSettings,
    t
  } = props
  const dayList = dayListCalculate()
  const standardRelease = createReleasePeriod(7)
  const nonRefundableRelease = createReleasePeriod(21)
  const timeArray = createTimeArray()
  const shortTimeArray = createTimeArray(true)
  const anyTimeArray = [{ label: 'Anytime', value: undefined }, ...timeArray]

  const { policy, releasePolicy } = settings

  const selectNew = (key, value) => {
    console.log('here', key, value)
    handleUpdateRoom(key, value)
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.policies.title')}</div>
        <div className='sub-title'>{t('microsite.policies.description')}</div>

        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{t('microsite.policies.minMaxNights')}</div>
          </div>
          <div className='row'>
            <Grid container spacing={5}>
              <Grid item xs={6} md={4}>
                <div className='label'>{t('microsite.policies.minNights')}</div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={dayList.find(e => e.value == policy.minNights)}
                    styles={selectStyle}
                    options={dayList}
                    onChange={e => updateSettings('policy.minNights', e.value)}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>{t('microsite.policies.maxNights')}</div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={dayList.find(e => e.value == policy.maxNights)}
                    styles={selectStyle}
                    options={dayList}
                    onChange={e => updateSettings('policy.maxNights', e.value)}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>
                  {t('microsite.policies.weekendMin')}
                  <Tooltip title={t('microsite.policies.weekendMinHelper')}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                  </Tooltip>
                </div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={weekendMinList[0].value}
                    styles={selectStyle}
                    options={weekendMinList}
                    onChange={(e) => selectNew('weekendMin', e.value)}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{t('microsite.policies.specificMinNights')}</div>
          </div>
          <div>
            {policy.specificDates && policy.specificDates.map(sd => {
              return (
                <div key={sd.id} className={classes.minNightsRow}>
                  <div>
                    <div className='label'>From</div>
                    <SingleDatePicker
                      date={moment(sd.start)}
                      onDateChange={date => onDateChange(checkIn, date)}
                      focused={false}
                      onFocusChange={({ focused }) => setFocused(focused)}
                      id='check-out-date'
                      placeholder={t('newReservation.basicDetails.selectCheckOutDate')}
                      showClearDate
                      noBorder={false}
                      numberOfMonths={1}
                  // isOutsideRange={day => (day.isBefore(fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE)))}
                      displayFormat='DD MMM YYYY'
                      daySize={30}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  <div className={classes.marginLeft}>
                    <div className='label'>To</div>
                    <SingleDatePicker
                      date={moment(sd.end)}
                      onDateChange={date => onDateChange(checkIn, date)}
                      focused={false}
                      onFocusChange={({ focused }) => setFocused(focused)}
                      id='check-out-date'
                      placeholder={t('newReservation.basicDetails.selectCheckOutDate')}
                      showClearDate
                      noBorder={false}
                      numberOfMonths={1}
                  // isOutsideRange={day => (day.isBefore(fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE)))}
                      displayFormat='DD MMM YYYY'
                      daySize={30}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  <div className={classes.marginLeft}>
                    <div className='label'>Nights</div>
                    <div className={clsx(classes.field, classes.short)}>
                      <NumberInput
                        value={sd.minNights}
                        onChange={e => updateSettings('location.latitude', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={clsx(classes.marginLeft, classes.iconDiv)}>
                    <CloseIcon className={classes.icon} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{t('microsite.policies.releasePeriod')}</div>
          </div>
          <div className='row'>
            <Grid container spacing={5}>
              <Grid item xs={6} md={4}>
                <div className='label'>
                  {t('microsite.policies.standardRelease')}
                  <Tooltip title={t('microsite.policies.standardReleaseHelper')}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                  </Tooltip>
                </div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={standardRelease.find(e => e.value == releasePolicy.standard)}
                    styles={selectStyle}
                    options={standardRelease}
                    onChange={e => {
                      updateSettings('releasePolicy.standard', e.value)
                      updateSettings('releasePolicy.nonRefundable', releasePolicy.nonRefundable)
                      if (e.value > 0) {
                        updateSettings('releasePolicy.releaseTime', anyTimeArray[0].value)
                      } else {
                        updateSettings('releasePolicy.releaseTime', releasePolicy.releaseTime)
                      }
                    }}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>
                  {t('microsite.policies.nonRefundableRelease')}
                  <Tooltip title={t('microsite.policies.nonRefundableReleaseHelper')}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                  </Tooltip>
                </div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={nonRefundableRelease.find(e => e.value == releasePolicy.nonRefundable)}
                    styles={selectStyle}
                    options={nonRefundableRelease}
                    onChange={e => {
                      updateSettings('releasePolicy.standard', releasePolicy.standard)
                      updateSettings('releasePolicy.nonRefundable', e.value)
                      updateSettings('releasePolicy.releaseTime', releasePolicy.releaseTime)
                    }}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>
                  {t('microsite.policies.sameDayBookings')}
                  <Tooltip title={t('microsite.policies.sameDayBookingsHelper')}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                  </Tooltip>
                </div>
                <div className={classes.dropDownContainer}>
                  {releasePolicy.standard === 0 && (
                    <Select
                      value={anyTimeArray.find(e => e.value == releasePolicy.releaseTime) || anyTimeArray[0]}
                      styles={selectStyle}
                      options={anyTimeArray}
                      onChange={e => {
                        updateSettings('releasePolicy.standard', releasePolicy.standard)
                        updateSettings('releasePolicy.nonRefundable', releasePolicy.nonRefundable)
                        updateSettings('releasePolicy.releaseTime', e.value)
                      }}
                      theme={theme => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: '#666666',
                          primary25: '#dddddd'
                        }
                      })}
                    />)}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{t('microsite.policies.checkInOut')}</div>
          </div>
          <div className='row'>
            <Grid container spacing={5}>
              <Grid item xs={6} md={4}>
                <div className='label'>{t('microsite.policies.earlyCheckin')}</div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={policy.checkInEarliest === zeroHour ? shortTimeArray[0] : shortTimeArray.find(e => e.value === policy.checkInEarliest)}
                    styles={selectStyle}
                    options={shortTimeArray}
                    onChange={e => {
                      updateSettings('policy.checkInEarliest', e.value)
                      updateSettings('policy.checkInLatest', policy.checkInLatest === zeroHour ? '' : policy.checkInLatest)
                      updateSettings('policy.latestCheckOut', policy.latestCheckOut === zeroHour ? '' : policy.latestCheckOut)
                    }}
                    captureMenuScroll={false}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>{t('microsite.policies.lateCheckIn')}</div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={policy.checkInLatest === zeroHour ? shortTimeArray[0] : shortTimeArray.find(e => e.value === policy.checkInLatest)}
                    styles={selectStyle}
                    options={shortTimeArray}
                    onChange={e => {
                      updateSettings('policy.checkInEarliest', policy.checkInEarliest === zeroHour ? '' : policy.checkInEarliest)
                      updateSettings('policy.checkInLatest', e.value)
                      updateSettings('policy.latestCheckOut', policy.latestCheckOut === zeroHour ? '' : policy.latestCheckOut)
                    }}
                    captureMenuScroll={false}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className='label'>{t('microsite.policies.lateCheckOut')}</div>
                <div className={classes.dropDownContainer}>
                  <Select
                    value={policy.latestCheckOut === zeroHour ? shortTimeArray[0] : shortTimeArray.find(e => e.value === policy.latestCheckOut)}
                    styles={selectStyle}
                    options={shortTimeArray}
                    onChange={e => {
                      updateSettings('policy.checkInEarliest', policy.checkInEarliest === zeroHour ? '' : policy.checkInEarliest)
                      updateSettings('policy.checkInLatest', policy.checkInLatest === zeroHour ? '' : policy.checkInLatest)
                      updateSettings('policy.latestCheckOut', e.value)
                    }}
                    captureMenuScroll={false}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

      </div>
    </>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(Policies))
