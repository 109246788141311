import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles, makeStyles, CircularProgress, Grid, Switch } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import ConfirmForm from '../common/ConfirmForm';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff',
    borderRadius: 15,
    marginBottom: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'calc(100% - 32px)',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 2px 20px #F0F0F0'
  },
  roomTypeName: {
    alignItems: 'center',
    color: '#333333',
    display: 'flex',
    height: '100%',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  switchContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tag: {
    marginTop: 10
  },
  bubble: {
    background: '#F0F0F0',
    borderRadius: '15px',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  selected: {
    background: '#8CB2A4',
    color: '#ffffff',
    fontWeight: 600,
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    color: '#ffffff',
    fontSize: '1.8rem',
    marginLeft: 5,
  },
  actionIcon: {
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      borderRadius: '50%'
    }
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)'
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%',
  }
}))

const CustomSwitch = withStyles({
  switchBase: {
    color: '#E0E0E0',
    '&$checked': {
      color: '#8CB2A4',
    },
    '&$checked + $track': {
      backgroundColor: '#8CB2A4',
    },
  },
  checked: {},
  track: {},
})(Switch);

const MappingItem = props => {
  const classes = useStyles();
  const { itemMappedRoom, counterRoomID, gokiRooms, counterRooms, roomMappingData, mappedGokiRoomID, t } = props;

  const [status, setStatus] = React.useState(itemMappedRoom);
  const [mappedRoom, setMappedRoom] = React.useState(itemMappedRoom);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSuggestedRooms, setShowSuggestedRooms] = React.useState(false);

  const selectedGokiRoomID =  roomMappingData[counterRoomID] &&
                              roomMappingData[counterRoomID].gokiRoomID ? 
                              roomMappingData[counterRoomID].gokiRoomID : null;
  
  const filteredGokiRooms = {}; 
  
  Object.keys(gokiRooms).map(id =>{
    if(selectedGokiRoomID == id || !mappedGokiRoomID.includes(id)){
      filteredGokiRooms[id] = { ...gokiRooms[id] };
    } 
  });

  React.useEffect(() => {
    setMappedRoom(itemMappedRoom);
    setStatus(itemMappedRoom);
  }, [itemMappedRoom]);

  React.useEffect(() => {
    if (loading && !props.loader)
      setLoading(props.loader)
  }, [props.loader]);

  const handleChange = () => {
    if (mappedRoom && status) {
      setConfirmOpen(true);
    } else setStatus(!status);
  }

  const handleConfirmDelete = () => {
    const payload = { _id: roomMappingData[counterRoomID]._id };
    props.handleDeleteMapping(payload);
    setStatus(!status);
    setLoading(true);
  }

  const resetCounterRoomState = () =>{
    setShowSuggestedRooms(false);
  }

  const handleMappingSelect = (id) => {
    if(mappedGokiRoomID.length > 0 && mappedGokiRoomID.includes(id)){
      props.dispatch({ 
        type: `UPDATE_ROOM_MAPPING_FAILURE`, 
        payload: {error: 'Goki Room is already mapped.'} 
      });
      return;
    }
    setLoading(true);
    if(mappedRoom){
      props.handleUpdateMapping({
        _id: roomMappingData[counterRoomID]._id, 
        gokiRoomID: id
      }, resetCounterRoomState);
      return;
    }
    props.handleMapping({
      roomID : counterRoomID,
      gokiRoomID: id
    }, resetCounterRoomState);
  }

  const handleRefresh = () => {
    props.handleRefresh();
    setLoading(true)
  }

  return (
      <div key={counterRoomID} className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <div className={classes.roomTypeName}>{counterRooms[counterRoomID].name}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.switchContainer}>
            <CustomSwitch
              checked={status}
              onChange={handleChange}
              color="primary"
              name="switch"
              inputProps={{ 'aria-label': 'room mapping switch' }}
            />
            <RefreshIcon onClick={() => handleRefresh()} className={classes.actionIcon} />
          </div>
        </Grid>
      </Grid>

      <ConfirmForm
        title={t('automate.goki.deleteMapping')}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmDelete}
      >
        {t('automate.goki.deleteMappingConf')}
      </ConfirmForm>

      {/* Goki rooms display */}
      
        <div className={classes.tag}>
          {Object.keys(filteredGokiRooms).map(gokiRoomID =>{

            //Mapped goki room
            if(mappedRoom && selectedGokiRoomID == gokiRoomID){
              return(
                <div key={gokiRoomID} className={clsx(classes.bubble, classes.selected)}>
                  <div className={classes.inline}>
                    {gokiRooms[selectedGokiRoomID].name}
                    {!showSuggestedRooms && (
                      <CloseIcon onClick={() => setShowSuggestedRooms(true)} className={classes.closeIcon} />
                    )}
                  </div>
                </div>
              )
            }

            //Goki room available for mapping
            if(!mappedRoom || showSuggestedRooms){
              return(
                <span
                  key={gokiRoomID}
                  className={classes.bubble}
                  onClick={() => status ? handleMappingSelect(gokiRoomID) : null}
                >
                  {gokiRooms[gokiRoomID].name}
                </span>
              )
            }
          })}
        </div>
     

      {loading && <div className={classes.loadingState}>
        <CircularProgress className={classes.loader}/>
      </div>}

    </div>
  )
}

const mapStateToProps = (state, props) => {
  const roomMappingData = state.goki.roomMappingData || {};

  const itemMappedRoom = roomMappingData &&
                      roomMappingData[props.counterRoomID] && 
                      Object.keys(roomMappingData[props.counterRoomID]).length > 0 &&
                      roomMappingData[props.counterRoomID].roomID == props.counterRoomID ?
                      true : false;
  
  const mappedGokiRoomID = roomMappingData ? Object.keys(roomMappingData).map(roomID => roomMappingData[roomID].gokiRoomID) : []
  
  return {
    itemMappedRoom: itemMappedRoom,
    loader: state.loading.REFRESH_MAPPING || 
            state.loading.DELETE_ROOM_MAPPING || 
            state.loading.CREATE_ROOM_MAPPING || 
            state.loading.UPDATE_ROOM_MAPPING,
    counterRooms: state.rooms,
    gokiRooms: state.goki.rooms || {},
    roomMappingData,
    mappedGokiRoomID
  }
}

export default withTranslation()(connect(mapStateToProps)(MappingItem));