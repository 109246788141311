import http from "../../utils/http";
import { clearSearch } from "../search/search";
//----------------------------------------------------------------
// FETCH BOOKING DETAILS
//----------------------------------------------------------------
export function fetchBookingDetails(bookingID) {
  const ACTION_NAME = "FETCH_BOOKING_DETAILS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/experiences/bookings/${bookingID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE BOOKING
//----------------------------------------------------------------
export function createBooking(payload, timezone) {
  const ACTION_NAME = "CREATE_BOOKING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/experiences/bookings/`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch(clearSearch())
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE BOOKING
//----------------------------------------------------------------
export function updateBooking(bookingID, propertyID, payload) {
  const ACTION_NAME = "UPDATE_BOOKING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/experiences/bookings/${bookingID}/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// DELETE BOOKING
//----------------------------------------------------------------
export function deleteBooking(propID, bookingID) {
  const ACTION_NAME = "DELETE_BOOKING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/experiences/bookings/${bookingID}/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}