import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useEffect } from 'react';

const dataDefaults = {
  labels: ["06 Jul", "07 Jul", "08 Jul", "09 Jul", "10 Jul", "11 Jul", "12 Jul", "13 Jul", "14 Jul", "15 Jul", , "16 Jul"],
  datasets: [{
    color: '#4F9581',
    label: "revPAR",
    data: [0,0,0,0,0,0,0,0,0,0,8,8,8,8,0,0,0,8]
  }]
};

const optionDefaults = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: true
        },
        labels: {
          show: true
        }
      }
    ]
  }
};

const GroupedBar = (props) => {
  const [graph, setGraph] = React.useState({data: dataDefaults, options: optionDefaults});

  useEffect(() => {
    if (props.labels && props.labels.length) {
      let tempData = graph.data;
      let tempOptions = graph.options;
      tempData.labels = props.labels;
      tempData.datasets = props.data;
      setGraph({data: {...tempData}, options: {...tempOptions}});
      // console.log('hello', tempData)
    }
  }, [props.data])

  return (
    graph.data?.labels?.length ? <Bar
      data={graph.data}
      options={graph.options}
    />: null
  );
}

export default GroupedBar;