import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    marginBottom: 20,
    width: 'calc(100% - 60px)'
  },
  bulb: {
    width: 40,
    height: 40
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20
  },
  link: {
    paddingLeft: 5,
    '& a': {
      color: '#006699',
      cursor: 'pointer'
    }
  }
}))


const HelperText = (props) => {
  const classes = useStyles();
  const { t, text, link="" } = props;
  return (
    <div className={classes.infoRow}>
      <div>
        <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
      </div>
      <div className={classes.helperText}>
        {t(`${text}`)}
        {link && <span className={classes.link}><a href={link} target="_blank">{t('common.learnMore')}</a></span>}
      </div>
    </div>
  );
};

export default withTranslation()(HelperText);