import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ProductList from '../components/product/ProductList';
import ProductCategorySnapShot from '../components/product/product_category/ProductCategorySnapshot';
import TaxPosSnapShot from '../components/taxes/TaxPosSnapshot';
import GetStarted from '../components/product/GetStarted';
import { setUpgradeAccount } from '../redux/actions/dashboard/dashboard';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 30,
    marginRight: 20,
    marginTop: 20,
    width: 'calc(100% - 70px)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
      marginRight: 20,
      width: 'calc(100% - 40px)'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  productContainer: {
    background: '#FFFFFF',
    // boxShadow: '0 3px 4px 0 #ECECEC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
}))

const PosContainer = (props) => {
  const { currentSpace, t } = props
  const classes = useStyles();

  setUpgradeAccount

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        {currentSpace.onboardingStatus !== 'pro' ?
          <Grid item xs={12} >
            <GetStarted title={'Upgrade to Pro'} handleClick={() => props.dispatch(setUpgradeAccount(true))}/>
          </Grid> :
          <React.Fragment>
            {props.currentSpaceProducts.length > 0 &&
              <React.Fragment>
                <Grid item xs={6} sm={4} lg={3}>
                  <ProductCategorySnapShot />
                </Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <TaxPosSnapShot />
                </Grid>
              </React.Fragment>
            }
            <Grid item sm={4} lg={6}></Grid>
            <Grid item xs={12} >
              <div className={classes.productContainer}>
                <ProductList />
              </div>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </div>
  )
}
const mapStateToProps = state => {
  let currentSpace= state.spaces[state.dashboard.currentSpace];
  let currentSpaceProducts = currentSpace.productCategories || [];
  return { currentSpaceProducts }
};

export default withTranslation()(connect(mapStateToProps)(PosContainer));