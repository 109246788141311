import http from "./http";

const setAuthToken = token => {
  if (token) {
    console.log('Setting authentication token.')
    // Apply authorization token to every request if logged in
    http.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete http.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;