import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import {
  loadHousekeepingByDay,
  resetHousekeepingReport,
} from "../../redux/actions/reports/housekeeping";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import { housekeepingList } from "../../redux/selectors/reports/housekeeping";
import Reservation from "../reservations/Reservation";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { HOUSEKEEPING_CATEGORY } from "../../utils/constants";

import { exportExcel } from "../utils/excelExport";
import { alphaNumericSort } from "../utils/wordUtils";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  dateHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  date: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    cursor: "pointer",
    marginRight: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: "0 5px 10px 0",
    },
  },
  options: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    "& input": {
      borderRadius: 5,
      color: "#484848",
      fontWeight: 500,
      width: 96,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    minWidth: 120,
    [theme.breakpoints.down("xs")]: {
      margin: "0 5px 10px 0",
    },
  },
  selected: {
    background: "#4F9581",
    color: "#ffffff",
    fontWeight: 600,
    border: "1px solid #4F9581",
  },
  tabHolder: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    flexDirection: "column",
    height: "30px",
    justifyContent: "center",
    marginRight: 10,
    width: "80px",
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  label: {
    color: "#666666",
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  thead: {
    background: "#F6F2EE",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  trow: {
    cursor: "pointer",
  },
  roomTypes: {
    display: "flex",
    flexDirection: "column",
  },
  highlighted: {
    color: "red",
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: 300,
  },
}));

const housekeepingCategory = [
  { value: HOUSEKEEPING_CATEGORY.ARRIVAL, label: "Arrival" },
  { value: HOUSEKEEPING_CATEGORY.DEPARTURE, label: "Departure" },
  { value: HOUSEKEEPING_CATEGORY.BEDSWITCH, label: "Switch Bed" },
  { value: HOUSEKEEPING_CATEGORY.INHOUSE, label: "In House" },
];

const BasicExport = (props) => {
  const rowCount = props.data.length;
  const tableTitle = "Housekeeping";
  const displayDate = props.date;
  const excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "wholeNumber",
    "string",
    "string",
  ];
  const excelColWidth = [15, 40, 40, 15, 15, 15, 15, 20, 40];

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: "Type",
          field: "type",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Room Type",
          field: "roomType",
          headerStyle: { backgroundColor: "#F6F2EE" },
          defaultSort: "asc",
          customSort: (a, b) => alphaNumericSort(a.roomType, b.roomType),
        },
        {
          title: "Room",
          field: "room",
          headerStyle: { backgroundColor: "#F6F2EE" },
          defaultSort: "asc",
          customSort: (a, b) => alphaNumericSort(a.room, b.room),
        },
        {
          title: "Bed",
          field: "bed",
          headerStyle: { backgroundColor: "#F6F2EE" },
          defaultSort: "asc",
          customSort: (a, b) => alphaNumericSort(a.bed, b.bed),
        },
        {
          title: "Check-in",
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Check-out",
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Duration",
          field: "duration",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Reservation Code",
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: "Name",
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        thirdSortClick: false,
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth
          );
        },
      }}
      onRowClick={(event, rowData) =>
        props.handleTableRowClick(rowData.reservationID)
      }
    />
  );
};

const Housekeeping = (props) => {
  const { t } = props;
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    housekeeping,
    roomTypes,
    rooms,
    beds,
    loadingHousekeeping,
  } = props;
  const [openReservation, setOpenReservation] = useState(false);
  const [date, setDate] = useState(moment());
  const [submittedDate, setSubmittedDate] = useState(moment());
  const [focused, setFocused] = useState(null);
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const [filter, setFilter] = useState([
    HOUSEKEEPING_CATEGORY.ARRIVAL,
    HOUSEKEEPING_CATEGORY.DEPARTURE,
    HOUSEKEEPING_CATEGORY.BEDSWITCH,
  ]);

  const tableData = useSelector((state) =>
    housekeepingList(
      state.reports.housekeeping,
      housekeepingCategory,
      state.roomTypes,
      state.rooms,
      state.beds,
      filter
    )
  );

  useEffect(() => {
    props.dispatch(
      loadHousekeepingByDay(
        currentSpace.propertyID,
        moment().format("YYYY-MM-DD")
      )
    );
    document.title = `${t("reports.houseKeeping")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetHousekeepingReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const onDateChange = (date) => {
    if (date !== null) {
      props.dispatch(
        loadHousekeepingByDay(
          currentSpace.propertyID,
          date.format("YYYY-MM-DD")
        )
      );
      setSubmittedDate(date);
    }
    setDate(date);
  };

  const handleFilterClick = (item) => {
    const newFilter = [...filter];
    let i = newFilter.indexOf(item);
    if (i >= 0) {
      newFilter.splice(i, 1);
    } else {
      newFilter.push(item);
    }
    setFilter(newFilter);
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("reports.houseKeeping")} {t("reports.report")}
          </div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.HOUSEKEEPING.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            <div className={classes.listSection}>
              {/* --------------------------------------------------------- */}
              {/* DATE RANGE FILTERS */}
              {/* --------------------------------------------------------- */}
              <div className={classes.dateHeader}>
                <div
                  className={clsx({
                    [classes.date]: true,
                    [classes.selected]: moment().isSame(date, "day"),
                  })}
                  onClick={() => onDateChange(moment())}
                >
                  {t("reports.presetRange.today")}
                </div>
                <div
                  className={clsx({
                    [classes.date]: true,
                    [classes.selected]: moment()
                      .subtract(1, "day")
                      .isSame(date, "day"),
                  })}
                  onClick={() => onDateChange(moment().subtract(1, "day"))}
                >
                  {t("reports.presetRange.yesterday")}
                </div>
                <div
                  className={clsx({
                    [classes.date]: true,
                    [classes.selected]: moment()
                      .add(1, "day")
                      .isSame(date, "day"),
                  })}
                  onClick={() => onDateChange(moment().add(1, "day"))}
                >
                  {t("reports.presetRange.tomorrow")}
                </div>
                <div className={classes.options}>
                  <SingleDatePicker
                    date={date}
                    onDateChange={onDateChange}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    id="noShowDate"
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat={"DD MMM YYYY"}
                    noBorder={true}
                  />
                </div>
              </div>
            </div>

            {/* LOADING HOUSEKEEPING */}
            {loadingHousekeeping && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* HOUSEKEEPING SUMMARY BUBBLES*/}
            {/* --------------------------------------------------------- */}
            {!loadingHousekeeping && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {
                          housekeeping?.resArray?.filter(
                            (item) =>
                              item.category === HOUSEKEEPING_CATEGORY.ARRIVAL
                          ).length
                        }
                      </div>
                      <div className={classes.desc}>
                        {t("reports.houseKeepingPage.arrival")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {
                          housekeeping?.resArray?.filter(
                            (item) =>
                              item.category === HOUSEKEEPING_CATEGORY.DEPARTURE
                          ).length
                        }
                      </div>
                      <div className={classes.desc}>
                        {t("reports.houseKeepingPage.departure")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {
                          housekeeping?.resArray?.filter(
                            (item) =>
                              item.category === HOUSEKEEPING_CATEGORY.INHOUSE
                          ).length
                        }
                      </div>
                      <div className={classes.desc}>
                        {t("reports.houseKeepingPage.inHouse")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {housekeeping?.availableBedIDs?.length}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.houseKeepingPage.emptyBeds")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REPORT FILTERS */}
            {/* --------------------------------------------------------- */}
            {!loadingHousekeeping && (
              <div className={classes.listSection}>
                <div className={classes.label}>Filter</div>
                <div className={classes.tabHolder}>
                  {housekeepingCategory.map((category) => (
                    <div
                      key={category.value}
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: filter.includes(category.value),
                      })}
                      onClick={() => handleFilterClick(category.value)}
                    >
                      {category.label}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* HOUSEKEEPING TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingHousekeeping && (
              <div className={classes.listSection}>
                <BasicExport
                  data={tableData}
                  date={submittedDate.format("DD MMM YYYY")}
                  handleTableRowClick={handleTableRowClick}
                />
              </div>
            )}
          </div>
        </div>

        {/* Open reservation when transaction item clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && (
          <Reservation
            open={openReservation}
            handleCloseReservation={handleCloseReservation}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  housekeeping: state.reports.housekeeping || {},
  roomTypes: state.roomTypes,
  rooms: state.rooms,
  beds: state.beds,
  loadingHousekeeping: state.loading.LOAD_HOUSEKEEPING,
});

export default withTranslation()(connect(mapStateToProps)(Housekeeping));
