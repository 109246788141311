import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomSwitch from "./CustomSwitch";
import ModuleItem from "./ModuleItem";
import { GROUP, MODULE } from "../../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  sectionItem: {
    marginBottom: "40px",
    position: "relative",
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  listTitle: {
    marginBottom: "10px",
    paddingLeft: "9px",
  },
  switchContainer: {
    marginBottom: "15px",
    paddingLeft: "9px",
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 99,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
}));

const GroupItem = ({
  groupID,
  updatePermissionHandler,
  currentMakerID,
  currentSpace,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const isCurrentMakerAdmin =
    currentSpace && currentSpace.admins.includes(currentMakerID);

  const hideLoader = () => setIsLoading(false);

  const updateGroupPermissions = (status) => {
    setIsLoading(true);
    updatePermissionHandler(status, { group: groupID }, hideLoader);
  };

  return (
    <div className={classes.sectionItem}>
      {isLoading && (
        <div className={classes.loadingState}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      <div className={clsx(classes.title, classes.listTitle)}>
        {GROUP[groupID].NAME}
      </div>
      <div className={classes.switchContainer}>
        <CustomSwitch
          groupID={groupID}
          isCurrentMakerAdmin={isCurrentMakerAdmin}
          updateGroupPermissions={updateGroupPermissions}
        />
      </div>
      <Grid container>
        {Object.keys(MODULE).map((moduleID, index) => {
          if (MODULE[moduleID].GROUP == groupID) {
            return (
              <ModuleItem
                key={index}
                groupID={groupID}
                moduleID={moduleID}
                isCurrentMakerAdmin={isCurrentMakerAdmin}
                updatePermissionHandler={updatePermissionHandler}
              />
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default connect(null)(GroupItem);
