import http from "../../utils/http";
import { normalize } from 'normalizr';
import { noticeboardSchema } from '../../schema/experiences';

//----------------------------------------------------------------
// FETCH NOTICEBOARD DETAILS
//----------------------------------------------------------------
export function fetchNoticeboardDetails(propertyID) {
  const ACTION_NAME = "FETCH_NOTICEBOARD_DETAILS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/hw/noticeboard/${propertyID}`)
      .then(successResult => {
        const { data } = successResult;
        const normalizedNoticeboard = normalize(data, [ noticeboardSchema ] );
        // console.log(data, normalizedNoticeboard.entities.noticeboard);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedNoticeboard.entities.noticeboard || {} });
        return data
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// DELETE NOTICEBOARD ITEM
//----------------------------------------------------------------
export function deleteNoticeboardItem(id) {
  const ACTION_NAME = "DELETE_NOTICEBOARD_ITEM";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/hw/noticeboard/${id}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: id });
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
