import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RefreshIcon from '@material-ui/icons/Refresh';

import { connect } from 'react-redux';
import clsx from 'clsx';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Switch from '@material-ui/core/Switch';
import ChannelStatusChangeConfirm from './StatusChangeConfirm';
import { fetchDatePerTimezone } from '../../../utils/utility';

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 62,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
      marginLeft: 0,
      overflow: 'visible'
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(35px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#53998C',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 5,
        padding: '20px 30px',
        background: '#F8F9FB',
        height: 'calc(100% - 60px)',
        marginTop: 15,
    },
    title: {
        color: '#000000',
        fontSize: '3rem',
        fontWeight: 600,
    },
    row: {
        marginBottom: 30
    },
    lesserPadding: {
        marginBottom: 10,
    },
    extraPadding: {
        marginTop: 60,
    },
    pillContainer: {
        display: 'flex',
    },
    pill: {
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: 5,
        color: '#999999',
        // cursor: 'pointer',
        display: 'flex',
        fontSize: '1.1rem',
        padding: '5px 10px',
        marginRight: 10,
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            background: '#EEEEEE'
        }
    },
    refresh: {
        color: '#999999',
        fontSize: '1.8rem',
        marginRight: 10,
    },
}));

const StatsData = ({ classes, data }) => (
    <React.Fragment>
        <div className={classes.ratingContainer}>
            <div className={classes.ratingSection}>
                <div className={classes.subTitle}>Overall rating</div>
                <div className={classes.lightValue}>{data.overallRating}</div>
            </div>
            <div className={classes.ratingSection}>
                <div className={classes.subTitle}>Number of reviews</div>
                <div className={classes.lightValue}>{data.noOfReviews}</div>
            </div>
        </div>
        <div className={classes.statsContainer}>
            {data?.statInfo.map((stat, index) => (
                <div className={classes.statsSection} key={index}>
                    <div className={classes.subTitle}>{stat.title}</div>
                    <div className={classes.subSection}>
                        <div className={clsx(classes.dash, {
                            [classes.negative]: stat.difference && stat.difference.includes('-'),
                            [classes.positive]: stat.difference && !stat.difference.includes('-'),
                        }
                        )}></div>
                        <div className={classes.value}>{stat.value}</div>
                        {stat.difference && (
                            stat.difference.includes('-') ? <>
                                <ArrowDownwardIcon className={classes.negative} />
                                <div className={clsx(classes.negative)}><sup>{stat.difference.replace('-', '')}</sup></div>
                            </> : <>
                                <ArrowUpwardIcon className={classes.positive} />
                                <div className={clsx(classes.positive)}><sup>{stat.difference}</sup></div>
                            </>)}

                    </div>
                </div>
            ))}
        </div>
    </React.Fragment>
)


const Summary = (props) => {
    const classes = useStyles();
    const { channelStatus, timezone } = props;
    const [connectStatus, setConnectStatus] = React.useState(channelStatus == 'true' ? true : false);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    let overallRating = 0;
    let noOfReviews = 0;
    let statInfo = [
        { title: "Today's Bookings", value: 0, difference: null },
        { title: "This weeks bookings", value: 0, difference: null },
        { title: "All time bookings", value: 0 },
        { title: "Today's revenue", value: 0, difference: null },
        { title: "This weeks revenue", value: 0, difference: null },
        { title: "This month revenue", value: 0, difference: null },
        { title: "Cancelled bookings", value: 0 },
        { title: "Marked as no show", value: 0 },
    ]

    React.useEffect(() => {
        if (props.success) setOpenConfirm(false)
    }, [props.success])

    const changeStatusHandler = () => {
        setConnectStatus(!connectStatus)
        setOpenConfirm(true)
    }


    return (
        <div className={classes.root}>
            {channelStatus &&
                <div className={classes.row}>
                    <div className={classes.pillContainer}>
                        <div className={clsx(classes.pill/* ,classes.clickable */)}>
                            {/* <RefreshIcon className={classes.refresh} /> */}
                        Last Sync {fetchDatePerTimezone(null, timezone).format('DD, MMM')}
                        </div>
                        {/* <div className={classes.pill}>View logs</div> */}
                    </div>
                </div>
            }

            <div className={classes.lesserPadding}>
                <div className={classes.title}>Booking.com</div>
            </div>

            {channelStatus && <div className={classes.row}>
                <IOSSwitch checked={connectStatus} onChange={changeStatusHandler} name="checked" />
            </div>}


            {openConfirm &&
                <ChannelStatusChangeConfirm
                    title={channelStatus == 'false' ? 'Activate channel' : 'Deactivate channel'}
                    type={channelStatus == 'false' ? 'activate' : 'deactivate'}
                    closeModalHandler={() => {
                        setConnectStatus(!connectStatus);
                        setOpenConfirm(false)
                    }}
                    loading={props.loading}
                    submitHandler={props.submitChangeStatusHandler}
                />
            }
        </div>
    )

}


export default Summary;