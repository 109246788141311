import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import LaunchIcon from "@material-ui/icons/Launch";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import CircularProgress from "@material-ui/core/CircularProgress";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import { returnCurrent } from "../../redux/selectors/dashboard";
import {
  getCurrency,
  amountCommaSeperator,
  fetchDatePerTimezone,
} from "../../utils/utility";
import { SUPPORT_PLATFORM, TEL_SUPPORT } from "../../config/index";

import IMAGES from "../../constants/images";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 62,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: 0,
    overflow: "visible",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(35px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#53998C",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const styles = (theme) => ({
  root: {
    borderRadius: 5,
    padding: "20px 30px",
    background: "#F8F9FB",
    height: "calc(100% - 60px)",
    marginTop: 15,
  },
  title: {
    color: "#000000",
    fontSize: "3rem",
    fontWeight: 600,
  },
  row: {
    marginBottom: 30,
  },
  lesserPadding: {
    marginBottom: 10,
  },
  extraPadding: {
    marginTop: 60,
  },
  category: {
    color: "#999999",
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  data: {
    alignItems: "flex-start",
    display: "flex",
  },
  amount: {
    fontSize: "1.6rem",
    fontWeight: 600,
    marginRight: 3,
  },
  direction: {
    color: "#53998C",
    fontSize: "2.1rem",
    "&.loss": {
      transform: "rotate(180deg)",
      color: "#df4a47",
    },

    "&.grey": {
      color: "#bdbdbd !important",
    },
  },
  percentage: {
    fontSize: "1.1rem",
    color: "#53998C",
    "&.loss": {
      color: "#df4a47",
    },

    "&.grey": {
      color: "#bdbdbd !important",
    },
  },
  pillContainer: {
    display: "flex",
  },
  pill: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#999999",
    // cursor: 'pointer',
    display: "flex",
    fontSize: "1.1rem",
    padding: "5px 10px",
    marginRight: 10,
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      background: "#EEEEEE",
    },
  },
  refresh: {
    color: "#999999",
    fontSize: "1.8rem",
    marginRight: 10,
  },
  launch: {
    color: "#999999",
    fontSize: "1.5rem",
    marginLeft: 5,
  },
  title2: {
    color: "#333333",
    fontSize: "2rem",
  },
  whatsappContainer: {
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    marginTop: 10,
    padding: 20,
    "&:hover": {
      color: "#333333",
    },
  },
  whatsapp: {
    height: 30,
    opacity: 0.6,
    width: 30,
  },
  message: {
    fontSize: "1rem",
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
  },
  messageLabel: {
    fontWeight: 600,
  },
  arrow: {
    marginLeft: "auto",
  },
  loaderContainer: {
    height: 255,
    position: "relative",
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "30%",
  },
});

const SUMMARY_STATS_CONFIG = {
  thisWeekBooking: {
    title: `This Week's Bookings`,
    valueKey: "reservations",
    isCurrency: false,
    statColorClass: null,
  },
  thisMonthBooking: {
    title: `This Month's Bookings`,
    valueKey: "reservations",
    isCurrency: false,
    statColorClass: null,
  },
  thisWeekRev: {
    title: `This Week's Revenue`,
    valueKey: "revenue",
    isCurrency: true,
    statColorClass: null,
  },
  thisMonthRev: {
    title: `This Month's Revenue`,
    valueKey: "revenue",
    isCurrency: true,
    statColorClass: null,
  },
  thisWeekCancelled: {
    title: `This Week's Cancellations`,
    valueKey: "reservations",
    isCurrency: false,
    statColorClass: "grey",
  },
  thisMonthCancelled: {
    title: `This Month's Cancellations`,
    valueKey: "reservations",
    isCurrency: false,
    statColorClass: "grey",
  },
};

const invertLossStatKeys = ["thisWeekCancelled", "thisMonthCancelled"];

const SummarySection = (props) => {
  const { classes, currentSpace, settings = {}, dispatch, isLoading } = props;
  const [enabled, setEnabled] = React.useState(true);
  const [summaryStats, setSummaryStats] = React.useState([]);

  const currencyData = {
    code: currentSpace?.currency || "EUR",
    country: currentSpace?.country || "DEU",
  };

  React.useEffect(() => {
    if (settings.statistics) processStats(settings.statistics);
  }, [settings]);

  const handleChange = () => {};

  const processStats = (statsData) => {
    const summaryData = Object.keys(SUMMARY_STATS_CONFIG).map((statID) => {
      const { valueKey, title, isCurrency, statColorClass } =
        SUMMARY_STATS_CONFIG[statID];
      const data = { ...statsData[statID] };

      let value = data?.[valueKey] || 0;
      value = Math.round((value + Number.EPSILON) * 100) / 100;
      value = isCurrency
        ? getCurrency(currencyData, value)
        : amountCommaSeperator(value);

      let isLoss = data.percentageChange >= 0 ? false : true;

      if (invertLossStatKeys.includes(statID)) {
        isLoss = data.percentageChange <= 0 ? false : true;
      }

      let percentVal = Math.abs(data.percentageChange);
      percentVal = Math.ceil(percentVal);

      return { title, value, isLoss, percentVal, statColorClass };
    });

    setSummaryStats(summaryData);
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.pillContainer}>
          <div className={clsx(classes.pill /* ,classes.clickable */)}>
            {/* <RefreshIcon className={classes.refresh} /> */}
            Last Sync{" "}
            {fetchDatePerTimezone(null, currentSpace.timezone).format(
              "DD, MMM"
            )}
          </div>
          {/* <div className={classes.pill}>View logs</div> */}
          {settings.id && (
            <a
              className={clsx(classes.pill, classes.clickable)}
              href={`https://www.hostelworld.com/pwa/hosteldetails.php/property-name/city/${settings.id}`}
              target="_blank"
            >
              Visit Microsite
              <LaunchIcon className={classes.launch} />
            </a>
          )}
        </div>
      </div>

      <div className={classes.lesserPadding}>
        <div className={classes.title}>Hostelworld</div>
      </div>

      <div className={classes.row}>
        <IOSSwitch checked={enabled} onChange={handleChange} name="checked" />
      </div>

      <AccessController requiredPermission={MODULE.HW_SUMMARY_STATS.ID}>
        <div className={clsx(classes.row, classes.extraPadding)}>
          <Grid container spacing={3}>
            {summaryStats.map((data, index) => (
              <Grid item sm={6} key={`hwStat${index}`}>
                <div>
                  <div className={classes.category}>{data.title}</div>
                  <div className={classes.data}>
                    <div className={classes.color}></div>
                    <div className={classes.amount}>{data.value}</div>

                    {data.percentVal !== 0 && (
                      <>
                        <ArrowUp
                          className={clsx(
                            classes.direction,
                            { loss: data.isLoss },
                            data.statColorClass
                          )}
                        />
                        <div
                          className={clsx(
                            classes.percentage,
                            { loss: data.isLoss },
                            data.statColorClass
                          )}
                        >
                          {data.percentVal}%
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>

          {/* ..............Circular Loader...................... */}
          {isLoading && (
            <div className={classes.loaderContainer}>
              <CircularProgress className={classes.loader} />
            </div>
          )}
        </div>
      </AccessController>

      <div className={clsx(classes.row, classes.extraPadding)}>
        <div className={classes.title2}>Hostelworld</div>
        <div className={classes.title2} style={{ fontWeight: 600 }}>
          Support
        </div>
        {settings?.buddy?.email && (
          <a
            className={classes.whatsappContainer}
            target="_blank"
            href={`mailto: ${settings.buddy.email}`}
          >
            <img src={IMAGES.ICONS.email} className={classes.whatsapp} />
            <span className={classes.message}>
              <span className={classes.messageLabel}>Email</span>
              <span>{settings.buddy.email}</span>
            </span>
            <RightArrow className={classes.arrow} />
          </a>
        )}
        <a
          className={classes.whatsappContainer}
          target="_blank"
          href={`tel:${TEL_SUPPORT}`}
        >
          <img src={IMAGES.ICONS.mobilePhone} className={classes.whatsapp} />
          <span className={classes.message}>
            <span className={classes.messageLabel}>Call phone support</span>
            <span>{TEL_SUPPORT}</span>
          </span>
          <RightArrow className={classes.arrow} />
        </a>
        <a
          className={classes.whatsappContainer}
          target="_blank"
          href={SUPPORT_PLATFORM}
        >
          <img src={IMAGES.ICONS.idea} className={classes.whatsapp} />
          <span className={classes.message}>
            <span className={classes.messageLabel}>Knowledge Base</span>
            <span>{SUPPORT_PLATFORM}</span>
          </span>
          <RightArrow className={classes.arrow} />
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { spaces, dashboard, loading } = state;
  return {
    currentSpace: returnCurrent(spaces, dashboard.currentSpace),
    settings: state.inbox.hostelworld.settings,
    isLoading: loading.GET_HOSTEL_WORLD_STATS,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(SummarySection));
