import http from "../../utils/http";
import { normalize } from 'normalizr';
import { mappingSchema, gokiRoomSchema } from '../../schema/gokiSchema';

export const fetchGokiRooms = (propertyID, params) => {
    const ACTION_NAME = "FETCH_GOKI_ROOMS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/goki/connect/${propertyID}`, params)
            .then(successResult => {
                const { gokiRooms = {}, roomMaps = [], status, apiKey } = successResult.data;
                //console.log('successResult ',successResult.data);
                const gokiRoomInfo = (gokiRooms && gokiRooms.room) ? gokiRooms.room : {};
                const normalizedGokiRooms = normalize(gokiRoomInfo, [ gokiRoomSchema ] );
                const normalizedRoomMaps = normalize(roomMaps, [ mappingSchema ] );
                //console.log('normalizedRoomMaps ',normalizedGokiRooms);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        status: status,
                        roomMappingData: normalizedRoomMaps.entities.roomMapping,
                        gokiRoomData: normalizedGokiRooms.entities.gokiRoomData,
                        apiKeyDisplay: apiKey
                    }
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const getRoomMaps = (propertyID) => {
    const ACTION_NAME = "GET_ROOM_MAPS";
    return function (dispatch) {
        dispatch({ type: 'RESET_GOKI_DATA' });
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`api/frontdesk/goki/roomMaps/${propertyID}`)
            .then(successResult => {
                //Returns status = 0 || 1 || 2
                //  0 -> No Goki Config; 
                //  1 -> API key configured but no room maps; 
                //  2 -> Atleast 1 RoomMap configured
                const { status, roomMaps = [], gokiRooms = {}, apiKey } = successResult.data;
                const normalizedRoomMaps = normalize(roomMaps, [ mappingSchema ] );
                const gokiRoomInfo = (gokiRooms && gokiRooms.room) ? gokiRooms.room : {};
                const normalizedGokiRooms = normalize(gokiRoomInfo, [ gokiRoomSchema ] );
                // console.log('successResult ',successResult.data);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        status: status,
                        roomMappingData: normalizedRoomMaps.entities.roomMapping,
                        gokiRoomData: normalizedGokiRooms.entities.gokiRoomData,
                        apiKeyDisplay: apiKey
                    }
                });
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export const createRoomMapping = (propertyID, params) => {
    const ACTION_NAME = "CREATE_ROOM_MAPPING";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/goki/mapRoom/${propertyID}`, params)
            .then(successResult => {
                const { roomMaps = [], gokiRooms = {} } = successResult.data;
                const normalizedRoomMaps = normalize(roomMaps, [ mappingSchema ] );
                const normalizedGokiRooms = normalize(gokiRooms.room, [ gokiRoomSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        roomMappingData: normalizedRoomMaps.entities.roomMapping,
                        gokiRoomData: normalizedGokiRooms.entities.gokiRoomData
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult.response);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const updateRoomMapping = (propertyID, params) => {
    const ACTION_NAME = "UPDATE_ROOM_MAPPING";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/goki/mapRoom/${propertyID}`, params)
            .then(successResult => {
                const { roomMaps = [], gokiRooms = {} } = successResult.data;
                const normalizedRoomMaps = normalize(roomMaps, [ mappingSchema ] );
                const normalizedGokiRooms = normalize(gokiRooms.room, [ gokiRoomSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        roomMappingData: normalizedRoomMaps.entities.roomMapping,
                        gokiRoomData: normalizedGokiRooms.entities.gokiRoomData
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult.response);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export function deleteRoomMapping(propertyID, params) {
    const ACTION_NAME = "DELETE_ROOM_MAPPING";
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http.delete(`/api/frontdesk/goki/mapRoom/${propertyID}`, { data: params })
        .then(successResult => {
            const { roomMaps = [], gokiRooms = {} } = successResult.data;
            const normalizedRoomMaps = normalize(roomMaps, [ mappingSchema ] );
            const normalizedGokiRooms = normalize(gokiRooms.room, [ gokiRoomSchema ] );
            console.log('successResult ',successResult.data);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    roomMappingData: normalizedRoomMaps.entities.roomMapping,
                    gokiRoomData: normalizedGokiRooms.entities.gokiRoomData
                }
            });
        })
        .catch(errorResult => {
          console.log(errorResult);
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        });
    }
  }

  export const refreshMapping = (propertyID) => {
    const ACTION_NAME = "REFRESH_MAPPING";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/goki/rooms/${propertyID}`)
            .then(successResult => {
                const { room } = successResult.data;
                console.log('successResult ',successResult.data);
                const normalizedGokiRooms = normalize(room, [ gokiRoomSchema ] );
                console.log('normalizedRoomMaps ',normalizedGokiRooms);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedGokiRooms.entities.gokiRoomData
                });
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult.response);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export function resetError(action) {
    return function (dispatch) {
      dispatch({ type: `${action}_RESET`, payload: { error: null } });
    };
}