import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import clsx from "clsx";
import http from "../../../redux/utils/http";

import { toTitleCase } from "../../utils/wordUtils";
import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchDatePerTimezone,
} from "../../../utils/utility";
import {
  transactionRefund,
  resetError,
} from "../../../redux/actions/reservation/editReservation";
import { returnCurrent } from "../../../redux/selectors/dashboard";
import { TRANSACTION_MODE } from "../../../utils/constants";

import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";

import NumberInput from "../../common/NumberInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import usePermissions from "../../permissions/UsePermissions";
import { MODULE } from "../../../common/constants/permission";
import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#fff",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px",
    // paddingLeft: theme.spacing(5),
    // paddingRight: theme.spacing(5),
    // paddingBottom: theme.spacing(5),
    // paddingTop: theme.spacing(5),
    // marginBottom: theme.spacing(5),
    marginTop: 10,
  },
  header: {
    fontWeight: 600,
  },
  row: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 25,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    // marginRight: 30,
    width: "100%",
  },
  transacionDate: {
    borderBottom: "1px solid red",
  },
  label: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  // datePicker: {
  //   background: '#f8f9fb',
  //   borderRadius: '10px',
  //   width: '100%',
  //   '& .DateInput_input': {
  //     background: '#f8f9fb',
  //   }
  // },
  createdBy: {
    padding: "15px",
    background: "#f8f9fb",
    borderRadius: "5px",
  },
  datePicker: {
    padding: "5px",
    background: "#f8f9fb",
    width: "100%",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0px",
    },
  },
  paymentMode: {
    display: "flex",
    padding: "10px 0px",
    "& $tab:last-child": {
      marginRight: "0px",
    },
  },
  amount: {
    background: "#f8f9fb",
    borderRadius: 5,
    padding: "8px 15px",
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":
      {
        border: 0,
      },
  },
  dateValue: {
    background: "#f8f9fb",
    borderRadius: 5,
    width: "calc(100% - 35px)",
    marginLeft: "10px",
    padding: "8px 5px",
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":
      {
        border: 0,
      },
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  tab: {
    alignItems: "center",
    // boxShadow: '0 1px 30px 0 #E0E0E0',
    background: "#f8f9fb",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 20,
    padding: 15,
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
    },
  },
  tabIcon: {
    height: 40,
    marginBottom: "5px !important",
    width: 40,
  },
  stripeIcon: {
    height: 40,
    marginBottom: "5px !important",
    width: 70,
  },
  selected: {
    background: "#CCCCCC",
  },
  radioContainer: {
    color: "#666666",
    marginBottom: 10,
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    "& span": {
      fontSize: "1.3rem",
      padding: 0,
      paddingRight: 5,
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "1.2rem",
    marginBottom: 20,
  },
  transactionModeContainer: {
    alignItems: "flex-end",
    display: "flex",
  },
  transactionMode: {
    width: "50%",
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  cancelButton: {
    background: "#ffffff",
    border: "0",
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  submitButton: {
    color: "#ffffff",
    background: "#6ea7d6",
    fontSize: "1.2rem",
    border: "0",
    "&:hover": {
      backgroundColor: "#6ea7d6",
      border: 0,
    },
  },
  submitContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  error: {
    fontSize: "1.2rem",
    color: "red",
    marginTop: 5,
    marginBottom: 5,
  },
  link: {
    color: "blue",
    cursor: "pointer",
  },
  stripeCard: {
    minHeight: 140,
    // width: 260,
    borderRadius: "8px",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  cardNumber: {
    marginTop: "20px",
    marginLeft: "20px",
    fontWeight: 600,
    fontSize: "18px",
    color: "#666666",
  },
  cardDigit: {
    marginRight: "25px",
  },
  secondContainer: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: 400,
  },
  firstContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 400,
  },
  cardValue: {
    fontSize: "16px",
  },
  cardExpiry: {
    fontSize: "10px",
    fontWeight: 400,
  },
  cardRow: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "10px",
    marginTop: 10,
  },
  cardEnd: {
    marginTop: "20px",
    justifyContent: "flex-end",
  },
  footer: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  selectContainer: {
    width: "100%",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
    marginTop: 10,
  },
  field: {
    background: "#f8f9fb",
    borderRadius: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-6q0nyr-Svg": {
      color: "#000000",
      width: 30,
      height: 30,
    },
  },
  fieldError: {
    border: "1px solid red",
  },
  dropdownInput: {
    "& > div:not(.css-26l3qy-menu)": {
      minHeight: 30,
      border: 0,
      backgroundColor: "#f8f9fb",
      boxShadow: "none",
    },
    "& > div > div > div": {
      padding: "0 8px",
    },
    "& .css-26l3qy-menu > div > div": {
      padding: 8,
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000,
    },
  },
  cardInfoContainer: {
    width: "100%",
  },

  //Modal css.................
  dialogTitle: {
    alignItems: "center",
    background: "#f8f9fb",
    justifyContent: "center",
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  dialogContent: {
    height: "calc(100vh - 315px)",
    [theme.breakpoints.down("xs")]: {
      height: "unset",
    },
  },
  title: {
    display: "flex",
    "& span": {
      marginRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "unset",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 15,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  submitButton: {
    color: "#ffffff",
    background: "#6ea7d6",
    fontSize: "1.2rem",
    border: "0",
    "&:hover": {
      backgroundColor: "#6ea7d6",
      border: 0,
    },
  },
  dialogAction: {
    padding: 24,
    textAlign: "right",
  },
  altButton: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 25,
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: 400,
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: "center",
    marginTop: 30,
  },
}));

const defaultTabList = [
  { id: "cash", imgSrc: IMAGES.ICONS.cash, label: "Cash" },
  { id: "card", imgSrc: IMAGES.ICONS.card, label: "Card" },
  { id: "other", imgSrc: null, label: "Other" },
];

const stripeTab = {
  id: "stripe",
  imgSrc: IMAGES.ICONS.stripe,
  label: "Stripe",
};

const RefundForm = (props) => {
  const classes = useStyles();
  const {
    currentSpace,
    currency,
    transactionModes,
    transactions,
    frontdeskUsers,
    stripe,
    users,
    isLoading,
  } = props;

  const [amount, setAmount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [selectedTransactionInfo, setSelectedTransactionInfo] = useState({});
  const [errors, setErrors] = useState({
    amount: false,
    mode: false,
    date: false,
    transaction: false,
  });
  const [tabList, setTabList] = useState([...defaultTabList]);
  const [refundSuccess, setRefundSuccess] = useState(false);
  const [lastDate, setLastDate] = useState(moment());
  const [lastTrans, setLastTrans] = useState(false);
  const [transactionSelect, setTransactionSelect] = useState("");

  const hasStripeRefundPermission = usePermissions(MODULE.STRIPE_REFUND.ID);

  useEffect(() => {
    fetchCashSessions();
    return () => handleCloseError();
  }, []);

  const getTransactionModes = () => {
    const cashID = currentSpace.transactionModes.find(
      (mode) => transactionModes[mode].name === "cash"
    );
    const cardID = currentSpace.transactionModes.find(
      (mode) => transactionModes[mode].name === "card"
    );
    const stripeID = currentSpace.transactionModes.find(
      (mode) => transactionModes[mode].name === "Stripe"
    );
    const otherArray = currentSpace.transactionModes.filter((mode) => {
      return !["cash", "card", "Stripe", "surcharge"].includes(
        transactionModes[mode].name
      );
    });

    return {
      cashID,
      cardID,
      stripeID,
      otherArray,
    };
  };

  const getTransactionMode = (modeID) => {
    const transactionMode = { ...transactionModes[modeID] };
    return transactionMode.name;
  };

  const processTransactions = () => {
    let transactionList = [];
    let formattedRefundList = {};

    transactions.forEach((data) => {
      const modeName = getTransactionMode(data.modeID);
      if (modeName == "surcharge") return;

      if (data.isRefund) {
        const refundData = { ...data, amount: Math.abs(data.amount) };

        //Refund list data .............................
        formattedRefundList[data.originalTxnID] = formattedRefundList[
          data.originalTxnID
        ]
          ? [...formattedRefundList[data.originalTxnID], { ...refundData }]
          : [{ ...refundData }];

        return;
      }
      // Transaction list data...........................
      transactionList = [...transactionList, { ...data }];
    });

    return {
      transactionList: [...transactionList],
      formattedRefundList: { ...formattedRefundList },
    };
  };

  const fetchTransactionList = () => {
    let formattedTransactionList = [];

    const { transactionList, formattedRefundList } = processTransactions();

    transactionList.forEach((transaction) => {
      const transactionID = transaction._id;
      const refundData = formattedRefundList[transactionID];
      const refundedAmount = refundData
        ? Math.abs(refundData.reduce((sum, r) => sum + r.amount, 0))
        : 0;
      // const unrefundedAmount = transaction.amount - refundedAmount;
      if (transaction.amount != refundedAmount) {
        formattedTransactionList = [
          ...formattedTransactionList,
          { ...transaction },
        ];
      }
    });

    const transactionOptions = formattedTransactionList
      .map((data) => {
        let transactionModeName = getTransactionMode(data.modeID);
        if (
          !hasStripeRefundPermission &&
          transactionModeName === TRANSACTION_MODE.STRIPE
        )
          return null;

        let date;
        const todayDate = fetchDatePerTimezone(null, currentSpace.timezone);
        const updatedDate = fetchDatePerTimezone(
          data.updatedAt,
          currentSpace.timezone
        );

        const diff = todayDate.diff(updatedDate, "days");
        if (diff === 0) {
          date = "Today - " + updatedDate.format("hh:mm A");
        } else {
          date = updatedDate.format("DD MMM YYYY - hh:mm A");
        }
        return {
          value: data._id,
          label: `${currency}${data.amount} (${getTransactionMode(
            data.modeID
          )}) [${date}]`,
        };
      })
      .filter((data) => data);

    return {
      transactionList: [...formattedTransactionList],
      transactionOptions,
    };
  };

  const isStripeActive = stripe.activated && !stripe.action_required;

  const { cashID, cardID, stripeID, otherArray } = getTransactionModes();

  const { transactionList, transactionOptions } = fetchTransactionList();

  const handleSetAmount = (e) => {
    let value = e.target.value;
    value = value < 0 ? Math.abs(value) : value;
    setAmount(value);
    errors.amount = false;
  };

  const handleOtherTransMode = (e) => {
    setSelectedMode(e.target.value);
    errors.mode = false;
  };

  const getUserName = (data) => {
    let userName = "";

    if (frontdeskUsers[data.createdBy]) {
      const user = frontdeskUsers[data.createdBy];
      userName = (user.firstName || "") + " " + (user.lastName || "");
      return userName;
    }

    if (users[data.createdBy]) {
      userName = toTitleCase(users[data.createdBy].name) || "";
      return userName;
    }

    return userName;
  };

  const transactionSelectHandler = (transaction) => {
    setTransactionSelect(transaction.value);
    const selectedTransactionData = transactionList.find(
      (data) => data._id == transaction.value
    );
    const userName = getUserName(selectedTransactionData);

    setLastDate(moment(selectedTransactionData.updatedAt));

    setAmount(selectedTransactionData.amount);
    setSelectedTransactionInfo({ ...selectedTransactionData, userName });
    setErrors({ ...errors, transaction: false });

    const seletedTransactionMode = getTransactionMode(
      selectedTransactionData.modeID
    );
    const tabData =
      isStripeActive && seletedTransactionMode == "Stripe"
        ? [stripeTab, ...defaultTabList]
        : [...defaultTabList];
    setTabList([...tabData]);
  };

  const handleValidations = () => {
    let noErrorFound = true;
    let errorList = {};

    if (!selectedTransactionInfo._id) {
      setErrors({ ...errors, transaction: true });
      return;
    }

    if (!selectedMode) {
      noErrorFound = false;
      errorList.mode = true;
    }

    if (!amount || isNaN(amount) || Number(amount) === 0) {
      noErrorFound = false;
      errorList.amount = true;
    }

    setErrors({ ...errors, ...errorList });
    return noErrorFound;
  };

  const convertAmountToNegative = (amount) => {
    let formattedAmount = parseFloat(amount);
    formattedAmount = formattedAmount * -1;
    return formattedAmount;
  };

  //* --------------------------------------------------------------------- *//
  //* POST REFUND */
  //* --------------------------------------------------------------------- *//
  const handleSubmit = () => {
    if (!handleValidations()) return;

    const { propertyID, reservationID, _id } = selectedTransactionInfo;

    let payload = {};

    if (selectedTab == "cash") {
      payload.cashDrawerSessionID = selectedSession;
    }

    const params = {
      propertyID,
      reservationID,
      originalTxnID: _id,
    };

    const refundAmount = convertAmountToNegative(amount);

    const postData = {
      ...payload,
      modeID: selectedMode,
      amount: refundAmount,
      updatedAt: lastDate,
      createdAt: lastDate,
    };

    const response = props.dispatch(transactionRefund(params, postData));
    response.then((success) => {
      if (success) {
        setRefundSuccess(true);
        setTimeout(() => props.handleCloseRefund(), 2000);
      }
    });
  };

  //* --------------------------------------------------------------------- *//
  //* GET CASH SESSIONS */
  //* --------------------------------------------------------------------- *//
  const fetchCashSessions = () => {
    setLoadingSessions(true);
    const sessionDate = fetchDatePerTimezone(
      null,
      currentSpace.timezone
    ).format("YYYY-MM-DD");
    http
      .get(
        `/api/frontdesk/transaction/cashdrawer/${currentSpace.propertyID}/${sessionDate}`
      )
      .then((successResult) => {
        const data = successResult.data;
        setLoadingSessions(false);
        setSessions(data.cashDrawerSessions);
      })
      .catch((errorResult) => {
        setLoadingSessions(false);
        console.log(errorResult);
      });
  };

  const setLastTransactionDate = () => {
    setLastTrans(true);
    // let formattedTransactionList = [];
    // const { transactionList, formattedRefundList } = processTransactions();

    // transactionList.forEach(transaction => {
    //   const transactionID = transaction._id;
    //   const refundData = formattedRefundList[transactionID];
    //   const refundedAmount = refundData ? Math.abs(refundData.reduce((sum, r) => sum + r.amount, 0)) : 0;
    //   // const unrefundedAmount = transaction.amount - refundedAmount;
    //   if (transaction.amount != refundedAmount) {
    //     formattedTransactionList = [...formattedTransactionList, { ...transaction }];
    //   }
    // });

    // const tran = formattedTransactionList.filter(transaction => String(transaction._id) === String(transactionSelect))
    setLastDate(fetchDatePerTimezone(null, currentSpace.timezone));
  };

  //* --------------------------------------------------------------------- *//
  //* CHANGE TAB */
  //* --------------------------------------------------------------------- *//
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);

    switch (tab) {
      case "cash":
        setSelectedMode(cashID);
        errors.mode = false;
        break;

      case "card":
        setSelectedMode(cardID);
        errors.mode = false;
        break;

      case "stripe":
        setSelectedMode(stripeID);
        errors.mode = false;
        break;

      case "other":
        setSelectedMode(null);
        break;
    }
  };

  const handleCloseError = () => {
    props.dispatch(resetError("TRANSACTION_REFUND"));
  };

  const handleChangeDate = (e) => {
    const lastDate = moment(e.target.value);
    setLastDate(lastDate);
    setLastTrans(false);
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.root}>
        <div className={classes.dialogTitle}>
          <Typography variant="h4" className={classes.title}>
            <span>Process refund</span>
          </Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={props.handleCloseRefund}
          />
        </div>

        {isLoading && (
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        )}

        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>
            {/* ---------SELECT TRANSACTION DROPDOWN -------------------------- */}
            <div className={classes.row}>
              <div className={classes.selectContainer}>
                <div className={classes.label}>Select transaction</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: errors.transaction,
                  })}
                >
                  <Select
                    value={transactionOptions.find(
                      (e) => e.value === selectedTransactionInfo.id
                    )}
                    name="transaction"
                    className={classes.dropdownInput}
                    onChange={transactionSelectHandler}
                    options={transactionOptions}
                    noOptionsMessage={() =>
                      "All transactions have been refunded"
                    }
                  />
                </div>
                {errors.transaction && (
                  <div className={classes.error}>
                    Please select a transaction{" "}
                  </div>
                )}
              </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* DATE, AMOUNT, USER */}
            {/* --------------------------------------------------------------------- */}
            {selectedTransactionInfo._id && (
              <>
                {/* --------------------------------------------------------------------- */}
                {/* DATE */}
                {/* --------------------------------------------------------------------- */}
                <div className={classes.row}>
                  <div className={classes.item}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <div className={classes.label}>Date</div>
                        {/*<div className={classes.createdBy}>
                          {fetchDateFormat(selectedTransactionInfo.createdAt, 'MMM DD, YYYY')}
                        </div>*/}
                        <div className={classes.datePicker}>
                          {!lastTrans && (
                            <TextField
                              id="datetime-local"
                              type="datetime-local"
                              onChange={(e) => handleChangeDate(e)}
                              defaultValue={fetchDatePerTimezone(
                                lastDate,
                                currentSpace.timezone
                              ).format("YYYY-MM-DDTHH:mm")}
                              className={classes.dateValue}
                            />
                          )}
                          {lastTrans && (
                            <TextField
                              id="datetime-local"
                              type="datetime-local"
                              onChange={(e) => handleChangeDate(e)}
                              value={fetchDatePerTimezone(
                                lastDate,
                                currentSpace.timezone
                              ).format("YYYY-MM-DDTHH:mm")}
                              className={classes.dateValue}
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item sm={2}></Grid>
                      <Grid item sm={5} xs={12}>
                        {/*<div className={classes.createdBy}>
                          {fetchDateFormat(selectedTransactionInfo.createdAt, 'MMM DD, YYYY')}
                        </div>*/}
                        <div
                          className={classes.altButton}
                          onClick={setLastTransactionDate}
                          style={{
                            background:
                              lastTrans === false ? "#FFFFFF" : "#E0E0E0",
                            fontWeight: lastTrans === false ? 400 : 600,
                          }}
                        >
                          Set Current Date
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {/* --------------------------------------------------------------------- */}
                {/* AMOUNT */}
                {/* --------------------------------------------------------------------- */}
                <div className={classes.row}>
                  <div className={classes.item}>
                    <div className={classes.label}>Amount</div>
                    <NumberInput
                      id="amount"
                      min={0}
                      value={amount}
                      className={classes.amount}
                      onChange={(e) => handleSetAmount(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      }
                    />
                    {errors.amount && (
                      <div className={classes.error}>
                        Please enter a valid amount
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {/* --------------------------------------------------------------------- */}
            {/* TABS */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.paymentMode}>
              {tabList.map((tab) => (
                <div
                  id={tab.id}
                  key={`tab_${tab.id}`}
                  className={clsx(classes.tab, {
                    [classes.selected]: selectedTab === tab.id,
                  })}
                  onClick={() => handleTabSelect(tab.id)}
                >
                  {/* {tab.imgSrc && (
                    <img
                      src={tab.imgSrc}
                      className={clsx(classes.tabIcon, {[classes.stripeIcon]: tab.id == 'stripe' })}
                      onClick={() => handleTabSelect(tab.id)}
                    />
                  )} */}
                  {tab.label}
                </div>
              ))}
            </div>
            {errors.mode && (
              <div className={classes.error}>
                Please select a transaction mode
              </div>
            )}

            {/* --------------------------------------------------------------------- */}
            {/* CASH TAB OPTIONS */}
            {/* --------------------------------------------------------------------- */}

            {selectedTab === "cash" && (
              <div>
                {loadingSessions && <div>Loading cash drawer sessions</div>}
                {!loadingSessions && sessions.length === 0 && (
                  <div>No cash drawer sessions</div>
                )}
                {!loadingSessions && sessions.length !== 0 && (
                  <RadioGroup
                    aria-label="cash-sessions"
                    className={classes.radioContainer}
                    value={selectedSession}
                    onChange={(e) => setSelectedSession(e.target.value)}
                  >
                    {sessions.map((session) => (
                      <FormControlLabel
                        key={session._id}
                        className={classes.radio}
                        value={session._id}
                        control={<Radio color="primary" disableRipple />}
                        label={`${
                          frontdeskUsers[session.startedByID].firstName
                        } ${frontdeskUsers[session.startedByID].lastName}, 
                    ${fetchDateFormat(
                      session.startTime,
                      `${DATE_FORMATS.TIME}`
                    )} -->
                    ${
                      session.isClosed
                        ? `${frontdeskUsers[session.endedByID].firstName} ${
                            frontdeskUsers[session.endedByID].lastName
                          }, ${fetchDateFormat(
                            session.endTime,
                            `${DATE_FORMATS.TIME}`
                          )}`
                        : "Active cash drawer session"
                    }
                  `}
                      />
                    ))}
                  </RadioGroup>
                )}
              </div>
            )}

            {/* --------------------------------------------------------------------- */}
            {/* OTHER TAB OPTIONS */}
            {/* --------------------------------------------------------------------- */}
            {selectedTab === "other" && (
              <div>
                <RadioGroup
                  aria-label="payment-mode"
                  className={classes.radioContainer}
                  value={selectedMode}
                  onChange={(e) => handleOtherTransMode(e)}
                >
                  {otherArray.map((otherPaymentID) => (
                    <FormControlLabel
                      key={otherPaymentID}
                      className={classes.radio}
                      value={transactionModes[otherPaymentID]._id}
                      control={<Radio color="primary" disableRipple />}
                      label={toTitleCase(transactionModes[otherPaymentID].name)}
                    />
                  ))}
                </RadioGroup>
              </div>
            )}
          </div>
        </DialogContent>

        <DialogActions className={classes.dialogAction}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handleSubmit}
              disabled={refundSuccess}
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>

        {/* ------------- Snackbar error messages -------------- */}
        {props.errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={10000}
            onClose={() => handleCloseError()}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}

        {refundSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={10000}
            onClose={() => setRefundSuccess(false)}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Refund processed successfully</span>}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  name: state.auth.user.name,
  balance: state.reservationDetails.reservation.balance,
  reservationID: state.reservationDetails.reservation._id,
  maskedCards: state.reservationDetails.maskedCards,
  transactionModes: state.transactionModes,
  frontdeskUsers: state.frontdeskUsers,
  stripe: state.stripe,
  transactions: state.reservationDetails.transactions,
  users: state.users,
  isLoading: state.loading.TRANSACTION_REFUND,
  errors: state.errors.TRANSACTION_REFUND,
});

export default connect(mapStateToProps)(RefundForm);
