import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        border: '1px solid #bdbdbd',
        borderRadius: '5px',
        marginTop: '30px',
        '& .title':{
            fontSize: '1.3rem',
            fontWeight: 600,
            marginRight: '10px'
        },
        [theme.breakpoints.down("xs")]:{
            flexDirection: 'column'
        }
    },

    optionBtnContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        [theme.breakpoints.down("xs")]:{
            flexDirection: 'column',
            marginTop: '20px'
        }
    },

    optionUpdateBtn:{
        marginRight: '10px',
        '&.cancelBtn':{
            backgroundColor: '#6fa7d6',
            color: '#fff',
            border: 'none'
        },
        [theme.breakpoints.down("xs")]:{
            marginBottom: '10px',
            marginRight: '0px',
        }
    }
}));

const updateTypes = {
    current: 'current',
    future: 'future',
    all: 'all'
}

const SlotUpdateConfirm = props =>{
    const { onClickHandler, closeHandler, date, t } = props;
    const classes = useStyles();
    const displayDate = moment(date).format('Do MMMM');
    return(
        <div className={classes.root}>
            <div className="title">{t('experiences.manage.activity.updateSlots')}:</div>
            <div className={classes.optionBtnContainer}>
                <Button 
                    variant="outlined"
                    className={classes.optionUpdateBtn}
                    onClick={()=>onClickHandler(updateTypes.current)}
                >
                    { displayDate }
                </Button>

                <Button 
                    variant="outlined"
                    className={classes.optionUpdateBtn}
                    onClick={()=>onClickHandler(updateTypes.future)}
                >
                    { displayDate } {t('experiences.manage.activity.futureDates')}
                </Button>

                {/* <Button 
                    variant="outlined"
                    className={classes.optionUpdateBtn}
                    onClick={()=>onClickHandler(updateTypes.all)}
                >
                    {t('experiences.manage.activity.allDates')}
                </Button> */}

                <Button 
                    variant="outlined"
                    className={clsx(classes.optionUpdateBtn, 'cancelBtn')}
                    onClick={closeHandler}
                >
                    {t('actions.cancel')}
                </Button>
            </div>
        </div>
    )
}

export default withTranslation()(SlotUpdateConfirm);