const stepCount = (spaces, currentSpaceID, currentSpaceType, currentUser) => {
  let currentSpace;
  if (currentSpaceType === 'Community' || currentSpaceType === 'Association') {
    currentSpace = Object.values(spaces).find(item => (item.type!=='Association' || item.type!=='Community'));
  } else if (currentSpaceID) {
    currentSpace = spaces[currentSpaceID]
  }
  let count = 0;
  if (currentSpace && currentSpace.roomTypes && currentSpace.roomTypes.length !== 0) {
    count+= 1;
  }
  if (currentUser && currentUser.avatar ) {
    count+= 1;
  }
  if (currentSpace && ((currentSpace.members && currentSpace.members.length > 1) || (currentSpace.admins && currentSpace.admins.length > 1))) {
    count+= 1;
  }
  if (currentSpace && currentSpace.onboardingStatus) {
    count+=1;
  }
  return count;
}

export {stepCount};