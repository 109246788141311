import React,  { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import HtmlTooltip from '../common/HtmlTooltip';

import { isBedPreOccupied, isBedAvailable } from './helper';
import { updateSelectedBeds, deleteSelectedRoomBeds } from '../../redux/actions/floorPlan/floorPlan';
import { ERROR_MSG } from './constants';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '8px 15px',
    },
    bedsList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bedContainer:{
        width: "33%",
        boxSizing: "border-box",
        padding: '5px'
    },
    bedBlock: {
        margin: "0px",
        display: "flex",

        '& .MuiFormControlLabel-label': {
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            width: '100%',
            padding: "10px",
            justifyContent: "flex-start",
            fontWeight: 600,
            color: "#4f4f4f",
            fontSize: "1.2rem",
            backgroundColor: "#fff",
        }
    },
    roomBlock: {
        margin: "0px",
        display: "flex",

        '& .MuiFormControlLabel-label': {
            color: "#4f4f4f",
            width: "100%",
            padding: "10px",
            fontSize: "1.2rem",
            textAlign: "center",
            lineHeight: "30px",
            height: "30px",
            backgroundColor: "#f8f9fb",
        }
    },
    controlLabel: {
        '& .MuiCheckbox-root': {
            display: 'none'
        },

        '& .Mui-checked + .MuiFormControlLabel-label':{
            backgroundColor: '#006699',
            color: '#fff'
        },

        '&.Mui-disabled .MuiFormControlLabel-label': {
            opacity: '0.4 !important',
            cursor: 'default'
        },

        '&.disableSelection .MuiFormControlLabel-label': {
            opacity: '0.4 !important',
            cursor: 'default'
        }
    },
    checkAllSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '5px',
        '& .MuiFormControlLabel-labelPlacementStart': {
            marginRight: '5px'
        }
    },
    checkAll: {
        '& .checkAll': {
            width: 10, 
            height: 10, 
            fontSize: '1.6rem'
        },
        '& .checkIcon': {
            color: "#f8f9fb", 
            outline: 'auto #e0e0e0',
            width: '20px',
            height: '20px'
        },
        '& .checkedIcon': {
            color: '#999999', 
            outline: 'auto #e0e0e0',
            width: '20px',
            height: '20px'
        },
        '& .MuiTypography-body1': {
            fontSize: "1rem",
            color: "#999999",
            marginRight: "5px",
        }
    }
}));

function validateCheckAll(data) {
    const bookableBedIds = data.currentBedIDs.filter(bedID =>{
        return isBedAvailable(data.availableBeds, bedID) && !isBedPreOccupied(data.preOccupiedBeds, bedID)
    });

    if(data.selectedBedIDs.length == 0) return false;
    
    const status = bookableBedIds.every(id => data.selectedBedIDs.includes(id));
    return status;
}

function getTooltipTitle(isSelected, isPreOccupied, disableSelection) {
    if(!isSelected) return '';
    if(disableSelection) return ERROR_MSG.disableSelection;
    if(isPreOccupied)return ERROR_MSG.isPreOccupied;
    return '';
}

function isBedDisabled(isSelected, isAvailable, isPreOccupied, disableSelection) {
    //FOR DISABLED AND PRE-OCCUPPIED BEDS ALLOW ONLY UNSELECTION IF SELECTED...............
    if(isSelected && (isPreOccupied || disableSelection)) return false;

    if(!isAvailable || isPreOccupied || disableSelection) return true;
    return false;
}

//=================*NOTE:- BELOW 'current' REFFERS TO THE CURRENT LOOPED VALUE*=====================//
const RoomCardContent = props =>{
    const classes = useStyles();
    const { 
        bedIDs = [], // All bedIDs belonging to current room. 
        roomID,      // Current rooms id. 
        roomTypeID,  // Current roomTypeID belonging to current rooms mapping.
        beds,        // Reducer beds data.
        availableBeds, // List of beds available for the selected booking duration 
        isPrivateRoom,
        preOccupiedBeds, //List of selected bed ids which are already booked.
        bedsPerRoom,
        disableSelection, // TRUE if selected rateplan is not applicable to current roomtype.
        dispatch,
        selectedBedsInfo,
        displayIndex,
        isReviewLoading
    }= props;

    const [currentBedIDs, setCurrentBedIDs] = useState([]);

    const selectedBedIDs = selectedBedsInfo?.[roomID]?.bedIDs || [];
    const isCheckAll = validateCheckAll({ 
        currentBedIDs, 
        selectedBedIDs, 
        availableBeds, 
        preOccupiedBeds 
    });

    useEffect(() =>{

        const currentBeds = [];

        if(bedIDs?.length > 0){
            //FILTER BEDS BASED ON CURRENT ROOMTYPE, ROOM AND IF BED ISACTIVE...................
            bedIDs.forEach(bedID =>{
                const bedInfo = beds[bedID];
                if( bedInfo.isActive && bedInfo.roomTypeID == roomTypeID && bedInfo.roomID == roomID){
                    currentBeds.push(bedID);
                }
            });

            setCurrentBedIDs([ ...currentBeds ]);
        }

    }, [bedIDs]);

    const onChangeHandler = event =>{
        const bedID = event.target.id;
        const isChecked = event.target.checked;
        const isPreOccupied = isBedPreOccupied(preOccupiedBeds, bedID);
        const isAvailable = isBedAvailable(availableBeds, bedID);
        const isSelected = selectedBedIDs.includes(bedID);

        if(isChecked && isBedDisabled(isSelected, isAvailable, isPreOccupied, disableSelection)) return;

        //CHECK IF A ROOMS CURRENT ROOMTYPE IS CHANGED AFTER BED SELECTION IN PREVIOUS ROOMTYPE....  
        if(isRoomTypeChanged([ bedID ])) return;
        
        const currentSelectedBeds = isChecked ? [ ...selectedBedIDs, bedID ] : selectedBedIDs.filter(id => id !== bedID);
        
        handleBedsUpdate(currentSelectedBeds);
    }

    const onSelectAll = event =>{

        const isChecked = event.target.checked;

        //FOR DISABLED AND PRE-OCCUPPIED BEDS ALLOW ONLY UNSELECTION IF SELECTED................
        if(isChecked && disableSelection) return;
        
        const bookableBedIds = currentBedIDs.filter(bedID =>!isBedPreOccupied(preOccupiedBeds, bedID) && isBedAvailable(availableBeds, bedID));

        //CHECK IF A ROOMS CURRENT ROOMTYPE IS CHANGED AFTER BED SELECTION IN PREVIOUS ROOMTYPE....  
        if(isRoomTypeChanged(bookableBedIds)) return;

        const currentSelectedBeds = isChecked ? [ ...bookableBedIds ] : [];

        handleBedsUpdate(currentSelectedBeds);
    }

    const isRoomTypeChanged = selectedBeds =>{

        if(selectedBedsInfo?.[roomID]?.roomTypeID !== roomTypeID){        
            handleBedsUpdate(selectedBeds);
            return true;
        }

        return false;
    }

    const handleBedsUpdate = (updatedBedIds) =>{

        if(isReviewLoading) return;

        if(updatedBedIds.length == 0){
            dispatch(deleteSelectedRoomBeds(roomID));
            return;
        }
        
        dispatch(updateSelectedBeds({
            bedIDs: [...updatedBedIds],
            roomID,
            roomTypeID,
            bedsPerRoom,
            isPrivateRoom,
            displayIndex
        }));
    }

    if(isPrivateRoom){
        
        const bedID = currentBedIDs?.[0] || '';
        const isPreOccupied = isBedPreOccupied(preOccupiedBeds, bedID);
        const isSelected = selectedBedIDs.includes(bedID);
        const isAvailable = isBedAvailable(availableBeds, bedID);

        return (
            <HtmlTooltip title={getTooltipTitle(isSelected, isPreOccupied, disableSelection)}>
                <FormControlLabel
                    className={clsx(classes.roomBlock,  classes.controlLabel, { disableSelection: isPreOccupied || disableSelection })}
                    control={<Checkbox id={bedID} name={`room`} />}
                    label={'Select Room'}
                    onChange={onChangeHandler}
                    disabled={isBedDisabled(isSelected, isAvailable, isPreOccupied, disableSelection)}
                    checked={selectedBedIDs.includes(bedID)}
                />
            </HtmlTooltip>
        )
    }

    return(
        <div className={classes.root}>
            <div className={classes.checkAllSection}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className="checkAll"
                            icon={<CheckRoundedIcon className="checkIcon" />}
                            checkedIcon={<CheckRoundedIcon className="checkedIcon" />}
                        />
                    }
                    className={classes.checkAll}
                    label="Select all available beds"
                    labelPlacement="start"
                    onChange={onSelectAll}
                    checked={isCheckAll}
                />
            </div>

            <div className={classes.bedsList}>
                {currentBedIDs.map((bedID,index) =>{

                    const isPreOccupied = isBedPreOccupied(preOccupiedBeds, bedID);
                    const isSelected = selectedBedIDs.includes(bedID);
                    const isAvailable = isBedAvailable(availableBeds, bedID);

                    return (
                        <HtmlTooltip 
                            key={`bed_${index}`}
                            title={getTooltipTitle(isSelected, isPreOccupied, disableSelection)}  
                        >
                            <div
                                className={classes.bedContainer}
                            >
                                <FormControlLabel
                                    className={clsx(classes.bedBlock, classes.controlLabel, { disableSelection: isPreOccupied || disableSelection })} 
                                    control={<Checkbox id={bedID} name={`Bed ${index+1}`} />}
                                    label={beds?.[bedID]?.name || ''}
                                    onChange={onChangeHandler}
                                    disabled={isBedDisabled(isSelected, isAvailable, isPreOccupied, disableSelection)}
                                    checked={isSelected}
                                />
                            </div>
                        </HtmlTooltip>
                    )
                })}
            </div>
            
        </div>
    )
}

const mapStateToProps = state =>{
    const { beds, floorPlan, loading } = state;
    return{ 
        beds,
        selectedBedsInfo: floorPlan.selectedBedsInfo,
        availableBeds: floorPlan.availableBeds,
        preOccupiedBeds: floorPlan.preOccupiedBeds,
        isReviewLoading: loading.REVIEW_BOOKING 
    }
}

export default connect(mapStateToProps)(RoomCardContent);