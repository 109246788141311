import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";

import {
  fetchByApiKey,
  fetchByProperty,
  saveList,
  updateList,
  resetList,
} from "../../redux/actions/mailChimp/lists";
import {
  fetchProperty,
  editProperty,
} from "../../redux/actions/dashboard/property";
import { fetchRules } from "../../redux/actions/mailChimp/rules";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    minHeight: "calc(100vh - 250px)",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  configSection: {
    marginTop: 30,
  },
  fieldContainer: {
    border: "1px solid #dddddd",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 30,
  },
  addContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
  moreIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#dddddd",
    },
  },
  titleRow: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  newButton: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  createSection: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "1px",
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const MailChimp = (props) => {
  const { t } = props;
  const isMediumScreen = useMediaQuery({
    query: "(min-device-width: 768px)",
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    property,
    mailChimp,
    loadingProperty,
  } = props;
  const [edit, setEdit] = React.useState(false);
  const [saveToggle, setSaveToggle] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [form, setForm] = React.useState({ apiKey: "" });
  const [toggle, setToggle] = React.useState(false);
  const [value, setValue] = React.useState([{ key: "", ruleID: "" }]);
  const [view, setView] = React.useState(false);
  let selectedList = [];
  let selectedRule = [];

  const [flag, setFlag] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [fetch, setFetch] = React.useState(true);

  // ********************************************************** //
  // * First load * //
  // ********************************************************** //
  useEffect(() => {
    props.dispatch(fetchByProperty(currentSpace.propertyID));
    if (property.default) {
      props.dispatch(fetchProperty(currentSpace.propertyID));
    }
  }, []);

  // ********************************************************** //
  // * Display an error message incase the API key is invalid * //
  // ********************************************************** //
  useEffect(() => {
    if (flag) {
      if (mailChimp.lists.error) {
        props.dispatch(resetList());
        setFlag(false);
        setMessage("The entered API Key could not be validated. Try again!");
        setOpenSnackbar(true);
        setView(false);
      }
    }
  }, [mailChimp]);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "MailChimp" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  // ********************************************************************** //
  // * Populating the selectedList and selectedRule with existing mapping * //
  // ********************************************************************** //
  if (!update) {
    if (!mailChimp.propertyInfo.default && !mailChimp.propertyInfo.error) {
      if (!mailChimp.lists.default) {
        if (
          mailChimp.propertyInfo.lists &&
          mailChimp.propertyInfo.lists.length > 0
        ) {
          for (var k = 0; k < mailChimp.propertyInfo.lists.length; k++) {
            for (var i = 0; i < mailChimp.lists.length; i++) {
              if (
                mailChimp.propertyInfo.lists[k].key === mailChimp.lists[i].value
              ) {
                selectedList.push(mailChimp.lists[i]);
              }
            }
          }
        }
      }

      if (!mailChimp.rules.default) {
        if (
          mailChimp.propertyInfo.lists &&
          mailChimp.propertyInfo.lists.length > 0
        ) {
          for (var k = 0; k < mailChimp.propertyInfo.lists.length; k++) {
            for (var i = 0; i < mailChimp.rules.length; i++) {
              if (
                mailChimp.propertyInfo.lists[k].ruleID ===
                mailChimp.rules[i].value
              ) {
                selectedRule.push(mailChimp.rules[i]);
              }
            }
          }
        }
      }
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSwitchToggle = (value) => {
    setToggle(value);
    if (!value)
      props.dispatch(
        editProperty(currentSpace.propertyID, {
          disableAppFeature: "MailChimp",
        })
      );
    else
      props.dispatch(
        editProperty(currentSpace.propertyID, { appFeature: "MailChimp" })
      );
  };

  const handleChange = (e) => {
    setMessage("");
    setFlag(false);
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  // ********************************************************** //
  // ************* To refresh the lists and rules ************* //
  // ********************************************************** //
  const handleRefreshLists = () => {
    props.dispatch(
      fetchByApiKey(currentSpace.propertyID, mailChimp.propertyInfo.apiKey)
    );
    props.dispatch(fetchRules());
    setMessage("Refreshing lists ...");
    setOpenSnackbar(true);
  };

  // *********************************************************************** //
  // * To fetch the lists and rules and display select modules for mapping * //
  // *********************************************************************** //
  const handleFetchLists = () => {
    setFetch(!fetch);
    if (!mailChimp.propertyInfo.default) {
      props.dispatch(
        fetchByApiKey(currentSpace.propertyID, mailChimp.propertyInfo.apiKey)
      );
      props.dispatch(fetchRules());

      if (!update) {
        if (!mailChimp.propertyInfo.default && !mailChimp.propertyInfo.error) {
          if (value.length > 1) {
            setValue([...value]);
          } else {
            if (value[0].key.length > 0) {
              setValue([...value]);
            } else {
              if (
                mailChimp.propertyInfo.lists &&
                mailChimp.propertyInfo.lists.length > 0
              ) {
                setValue([
                  ...mailChimp.propertyInfo.lists,
                  { key: "", ruleID: "" },
                ]);
              }
            }
          }
        }
      } else {
        setValue([{ key: "", ruleID: "" }]);
      }
      setView(true);
    }
  };

  // ********************************************************** //
  // ****************** To save the API Key ******************* //
  // ********************************************************** //
  const handleSaveKey = () => {
    setFetch(!fetch);
    props.dispatch(fetchByApiKey(currentSpace.propertyID, form.apiKey));

    const data = {
      propertyID: currentSpace.propertyID,
      apiKey: form.apiKey,
    };

    props.dispatch(saveList(data));

    setValue([{ key: "", ruleID: "" }]);
    setFlag(true);
    selectedList = [];
    props.dispatch(fetchRules());
    setView(true);
  };

  const handleEditKey = () => {
    setForm({ apiKey: "" });
    setEdit(!edit);
  };

  // ********************************************************** //
  // ***************** To update the API Key ****************** //
  // ********************************************************** //
  const handleUpdateKey = () => {
    setValue([{ key: "", ruleID: "" }]);
    props.dispatch(fetchByApiKey(currentSpace.propertyID, form.apiKey));
    setFlag(true);

    const data = {
      propertyID: currentSpace.propertyID,
      apiKey: form.apiKey,
    };

    props.dispatch(updateList(data));
    setUpdate(true);
    setEdit(false);
    setSaveToggle(false);
    props.dispatch(fetchRules());
    setMessage("Refreshing selection ...");
    setOpenSnackbar(true);
  };

  const setNewValue = (selectedOption, index, type) => {
    const newVal = [...value];
    if (type === "list") {
      newVal[index].key = selectedOption.value;
      if (newVal[index].ruleID.length > 0) {
        if (index === value.length - 1) {
          setValue([...newVal, { key: "", ruleID: "" }]);
        } else {
          setValue(newVal);
        }
        setSaveToggle(true);
      } else {
        setValue(newVal);
      }
    } else {
      newVal[index].ruleID = selectedOption.value;
      if (newVal[index].key.length > 0) {
        if (index === value.length - 1) {
          setValue([...newVal, { key: "", ruleID: "" }]);
        } else {
          setValue(newVal);
        }
        setSaveToggle(true);
      } else {
        setValue(newVal);
      }
    }
  };

  // ********************************************************** //
  // *************** To save/update the Mapping *************** //
  // ********************************************************** //
  const handleSave = () => {
    let saveData = [];
    for (var x = 0; x < value.length; x++) {
      if (value[x].key.length > 0 && value[x].ruleID.length > 0) {
        saveData.push(value[x]);
      }
    }

    saveData = saveData.filter(
      (val, index, self) =>
        index ===
        self.findIndex((v) => v.key === val.key && v.ruleID === val.ruleID)
    );

    const data = {
      list: saveData,
      propertyID: currentSpace.propertyID,
    };

    props.dispatch(updateList(data)).then((res) => {
      if (res && res.status === 200) {
        setMessage("MailChimp Settings saved!");
        setOpenSnackbar(true);
      } else {
        setMessage("Error in saving the information. Try again!");
        setOpenSnackbar(true);
      }
    });
  };

  if (!mailChimp.propertyInfo.default) {
    if (fetch) {
      handleFetchLists();
    }
  }

  let displayComponent;

  if (mailChimp.propertyInfo.default) {
    displayComponent = (
      <React.Fragment>
        <div className={classes.createSection}>
          <TextField
            margin="dense"
            id="apiKey"
            fullWidth
            label={t("automate.mailChimp.enterApiKey")}
            type="text"
            onChange={handleChange}
            defaultValue={form.apiKey}
          />
          <div className={classes.buttonHolder}>
            <DoneIcon className={classes.newButton} onClick={handleSaveKey} />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    if (!edit) {
      displayComponent = (
        <React.Fragment>
          <div className={classes.linkContainer}>
            <div className={classes.addContainer}>
              <span className={classes.title}>
                {" "}
                {t("automate.mailChimp.displayApiKey")}:{" "}
                {mailChimp.propertyInfo.maskedApiKey}{" "}
              </span>
            </div>
            <div className={classes.saveContainer}>
              <div className={classes.linkContainer}>
                <span
                  onClick={() => handleEditKey()}
                  className={classes.addContainer}
                >
                  <EditIcon className={classes.moreIcon} />
                </span>
                <span className={classes.saveContainer}>
                  <RefreshIcon
                    onClick={() => handleRefreshLists()}
                    className={classes.actionIcon}
                  />
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      displayComponent = (
        <React.Fragment>
          <div className={classes.createSection}>
            <TextField
              margin="dense"
              id="apiKey"
              fullWidth
              label={t("automate.mailChimp.newApiKey")}
              type="text"
              onChange={handleChange}
              defaultValue={form.apiKey}
            />
            <div className={classes.buttonHolder}>
              <CloseIcon
                className={classes.newButton}
                onClick={handleEditKey}
              />
              <DoneIcon
                className={classes.newButton}
                onClick={handleUpdateKey}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>MailChimp</div>
          <AccessHeaderAction moduleID={MODULE.MAILCHIMP.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {loadingProperty && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        <div className={classes.listContainer}>
          {!loadingProperty && (
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.mailChimp.definition")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <div className={classes.title}>MailChimp</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={() => handleSwitchToggle(false)}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={() => handleSwitchToggle(true)}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {toggle && (
                <div className={classes.configSection}>
                  <div className={classes.row}>
                    {mailChimp.propertyInfo.default && (
                      <div>
                        <div className={classes.title}>
                          {t("automate.mailChimp.enterApiKey")}
                        </div>
                        <div className={classes.label}>
                          {t("automate.mailChimp.apiKeyHelp")}
                        </div>
                      </div>
                    )}
                    {displayComponent}
                  </div>
                </div>
              )}
              {toggle && !mailChimp.lists.error && view && (
                <div className={classes.configSection}>
                  {isMediumScreen && (
                    <div className={classes.row}>
                      <Grid container alignItems="center">
                        <Grid item xs={5}>
                          {!mailChimp.rules.default && (
                            <div className={classes.titleRow}>
                              {t("automate.mailChimp.events")}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          {!mailChimp.rules.default && (
                            <div className={classes.titleRow}>
                              {t("automate.mailChimp.audience")}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {value.map((x, i) => {
                    return (
                      <div className={classes.row} key={i}>
                        <Grid container alignItems="center">
                          <Grid item sm={5} xs={12}>
                            {!mailChimp.lists.default && (
                              <div className={classes.fieldContainer}>
                                <Select
                                  defaultValue={selectedRule[i]}
                                  styles={selectStyle}
                                  options={mailChimp.rules}
                                  onChange={(val) =>
                                    setNewValue(val, i, "rule")
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    border: "1px",
                                    colors: {
                                      ...theme.colors,
                                      primary: "#666666",
                                      primary25: "#dddddd",
                                    },
                                  })}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid item sm={2}></Grid>
                          <Grid item xs={12} sm={5}>
                            {!mailChimp.lists.default && (
                              <div className={classes.fieldContainer}>
                                <Select
                                  defaultValue={selectedList[i]}
                                  styles={selectStyle}
                                  options={mailChimp.lists}
                                  onChange={(val) =>
                                    setNewValue(val, i, "list")
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    border: "1px",
                                    colors: {
                                      ...theme.colors,
                                      primary: "#666666",
                                      primary25: "#dddddd",
                                    },
                                  })}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </div>
              )}
              {saveToggle && toggle && (
                <div className={classes.buttonsContainer}>
                  <div className={classes.button} onClick={handleSave}>
                    {t("automate.mailChimp.save")}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  mailChimp: state.mailChimp,
  loadingProperty: state.loading.LOAD_PROPERTY,
});

export default withTranslation()(connect(mapStateToProps)(MailChimp));
