import React from "react";
import { makeStyles, Typography, Grid, Hidden } from '@material-ui/core';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import { toTitleCase } from "../../utils/wordUtils";
import { RESERVATION_STATUS } from "../../../utils/constants";
import TooltipIcon from "../common/TooltipIcon";
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    header: {
        marginRight: 35,
        marginLeft: 35,
        paddingTop: 20,
        paddingBottom: 10,
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 40,
    },
    button: {
        border: '0px solid #dddddd',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '2.2rem',
        marginRight: 10,
        padding: 10,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    iconHolder: {
        position: 'absolute',
        top: 13,
        right: 28,
    },
    icon: {
        cursor: 'pointer',
        marginRight: 10,
        height: 15,
        width: 15,
    },
    buttonHolder: {
        position: 'absolute',
        top: 0,
        right: 10,
        height: 42,
    },
    actionIcon: {
        width: 15,
        height: 15,
        cursor: 'pointer'
    },
    buttonContainer: {
        position: 'relative'
    },
    title: {
        display: 'flex',
        '& span': {
            marginRight: 20
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'unset',
            alignItems: 'center',
            fontSize: '1.3rem',
        }
    },
    copyContainer: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        '& input': {
            border: '1px solid rgb(223, 225, 230)',
            borderRadius: 3,
            cursor: 'default',
            padding: 5,
            marginRight: 10,
        },
        [theme.breakpoints.down('sm')]: {
            '& input': {
                display: 'none'
            }
        }
    },
    dot: {
        borderRadius: '50%',
        width: 6,
        height: 6,
        background: '#d96977',
        position: 'absolute',
        top: -1,
        right: 1,
        zIndex: 99,
    }
}))


const Header = (props) => {
    const classes = useStyles();
    const { loading, reservation, property } = props;

    return (
        <div className={classes.header}>
            <Grid container>
                <Grid item xs={9}>
                    {(!loading && reservation) ?
                        <div className={classes.titleContainer}>
                            <Typography variant="h4" className={classes.title}><span>{toTitleCase(reservation?.guestName)}</span>
                                {reservation.code && property.code &&
                                    <div className={classes.copyContainer}>
                                        <TooltipIcon
                                            className={classes.actionIcon}
                                            icon={IMAGES.ICONS.copy}
                                            clickText='Copied'
                                            hoverText='Copy reservation link'
                                            handleClick={props.handleCopy}
                                        />
                                    </div>
                                }
                                {reservation.status !== RESERVATION_STATUS.CANCELLED && reservation.status !== RESERVATION_STATUS.NO_SHOW &&
                                    <div className={classes.copyContainer}>
                                        <TooltipIcon
                                            className={classes.actionIcon}
                                            icon={IMAGES.ICONS.trash}
                                            clickText='Cancel reservation'
                                            hoverText='Cancel reservation'
                                            handleClick={props.handleDelete}
                                        />
                                    </div>
                                }
                            </Typography>
                        </div>
                        :
                        <div className={classes.titleContainer}>
                            <Typography variant="h4" className={classes.title}><span>Reservation details</span></Typography>
                        </div>
                    }
                </Grid>
                <Grid item xs={3} className={classes.buttonContainer}>
                    {(!loading && reservation) &&
                        <div className={classes.iconHolder}>
                            <Hidden mdUp>
                                <img src={IMAGES.ICONS.cart} className={classes.icon} onClick={props.handleOpenLog} />
                                {reservation && reservation.balance !== 0 && <div className={classes.dot}></div>}
                            </Hidden>
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Header; 