import http from '../../utils/http';

export function getRoomTypeMapping(propertyID) {
  const ACTION_NAME = "GET_ODOO_ROOMTYPES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/odoo/roomTypeMap/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
export function updateRoomTypeMapping(propertyID, data) {
  const ACTION_NAME = "UPDATE_ODOO_ROOMTYPES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/odoo/roomTypeMap/${propertyID}`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}