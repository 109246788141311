import http from '../../utils/http';

export function getBookingEngineSettings(propertyID) {
    const ACTION_NAME = "GET_BOOKING_ENGINE_SETTINGS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .get(`/api/frontdesk/bookingEngine/${propertyID}`)
            .then(successResult => {
                const data = successResult.data;
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    };
}

export function saveBookingEngineSettings(propertyID, data) {
    const ACTION_NAME = "SAVE_BOOKING_ENGINE_SETTINGS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .patch(`/api/frontdesk/bookingEngine/${propertyID}`, data)
            .then(successResult => {
                const data = successResult.data;
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    };
}