import { toTitleCase } from "../../components/utils/wordUtils";
import { DATE_FORMATS, fetchDateFormat, getCurrency } from "../../utils/utility";

export const processMasterwayBills = (billsData = [], currencyData = {}) =>{
    let data = [];

    for(const [billID, bill] of Object.entries(billsData)){
        data.push({
            billID,
            resCode: bill.resCode, 
            guestName: toTitleCase(bill.guestName),
            billType: bill.isRefund ? 'Refund' : 'Transaction',   
            currentStatus: bill.success ? 'Success' : 'Failed', 
            billAmount: getCurrency(currencyData, bill.amount),
            failureReason: bill.failureReason, 
            lastTryDate: fetchDateFormat(bill.billCreateTime, `${DATE_FORMATS.DEFAULT_DATE} ${DATE_FORMATS.TIME}`),
            isSuccess: bill.success
        });
    }
    return data;
}