import { schema } from 'normalizr';

const mappingSchema = new schema.Entity('roomMapping', {}, {
    idAttribute: 'roomID'
});

const gokiRoomSchema = new schema.Entity('gokiRoomData', {}, {
    idAttribute: 'id'
});

export { gokiRoomSchema, mappingSchema }