import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';

import POSLineItem from './POSLineItem';

import { Button, Grid } from '@material-ui/core';
import { fetchCurrencyFormat, getAmtFormat } from '../../../utils/utility';
import { createOrder } from '../../../redux/actions/pos/orders';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 20,
        height: 20,
        marginRight: 20
    },
    section: {
        alignItems: 'center',
        display: 'flex', 
        marginTop: '30px',
    },
    title: {
        fontSize: '2rem',
        fontWeight: 600
    },
    sectionTitle: {
        paddingTop: 30,
        paddingBottom: 10,
        fontSize: '1.3rem',
        fontWeight: 600
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 50,
        width: '100%',
    },
    submit: {
        border: '0',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: '#6ea7d6',
        color: '#fff',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
          backgroundColor: '#6ea7d6',
          color: '#fff',
        }
      },
}));

const ProductCategoryList = (props) => {
    const classes = useStyles();
    const { currentSpace, productCategories, reservation, products } = props;
    const [selectedItems, setSelectedItems] = React.useState({});
    const currency = fetchCurrencyFormat(currentSpace.currency || null);

    const onCountChange = (count, itemID) => {
        let selected = { ...selectedItems };
        if (!selected[itemID]) {
            selected[itemID] = {
                productID: itemID,
                productCategoryID: products[itemID].productCategoryID,
            }
        }
        selected[itemID].price = products[itemID].price * count,
        selected[itemID].quantity = count;
        if(count === 0 && selected[itemID]) delete selected[itemID];
        setSelectedItems({ ...selected })
    }

    const getSelectedList = (selectedItems) => {
        let total = 0;
        let items= Object.keys(selectedItems).map(id => {
            total = total + (selectedItems[id].price)
            return selectedItems[id]
        })

        return { items, total }
    };

    const handleSubmit = () => {
        // console.log("selectedItems", selectedItems)
        let itemInfo = getSelectedList(selectedItems);

        let payload = {
            productList: itemInfo.items,
            productAmount: itemInfo.total,
            reservationID: reservation._id,
            propertyID: currentSpace.propertyID,
            status: 'Closed',
            isDeleted: false
        };

        props.dispatch(createOrder(payload));
        setSelectedItems({})
        // Google tag manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add POS item', 
        'eventLabel': 'Add POS Item', 'eventValue': productAmount});
    }

    return (
        <div className={classes.container}>
            <Grid container >

                {currentSpace?.productCategories?.sort()?.map((id, index) => {
                    const categoryData = { ...productCategories[id] };
                    if (!categoryData?.products?.length || categoryData.isDeleted === true) return;
                    return (
                        <Grid item xs={12} key={index}>
                            <div className={classes.sectionTitle}>{categoryData.name}</div>
                            <Grid container spacing={3}>
                                {categoryData?.products?.sort()?.map(product => ( products[product].isDeleted !== true && 
                                    (<Grid item xs={12} md={6} key={product} >
                                        <POSLineItem 
                                            id={product} 
                                            name={products[product].name} 
                                            amount={getAmtFormat(products[product].price, currency)} 
                                            image={products?.[product]?.image || IMAGES.ICONS.posPlaceholder}
                                            onCountChange={onCountChange} 
                                            quantity={selectedItems?.[product]?.quantity || 0}
                                        />
                                    </Grid>)
                                ))}
                            </Grid>
                        </Grid>
                    )
                })}

                {Object.keys(selectedItems).length > 0 && <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.submit} onClick={() => handleSubmit()}>Save</Button>
                    </div>
                </Grid>}
            </Grid>

        </div>
    )
}

const mapStateToProps = state => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    products: state.products,
    productCategories: state.productCategories,
    reservation: state.reservationDetails.reservation,
    loading: state.loading.LOAD_PRODUCT_CATEGORIES,
    error: state.errors.CREATE_PRODUCT || state.errors.UPDATE_PRODUCT || state.errors.DELETE_PRODUCT_CATEGORY
})

export default connect(mapStateToProps)(ProductCategoryList);