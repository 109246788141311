import { sidebarMenu } from './constant';

export const getNextStepInfo = (currentMenuID) =>{

    const menuList = Object.keys(sidebarMenu);
    const currentMenuIndex = menuList.indexOf(currentMenuID);
    const nextMainStepIndex = currentMenuIndex + 1;

    if(!menuList[nextMainStepIndex]) return;

    const nextMenu = sidebarMenu[menuList[nextMainStepIndex]];

    return {
        menuID: nextMenu.id
    }
}

export const getPrevStepInfo = (currentMenuID) =>{
        const menuList = Object.keys(sidebarMenu);
        const currentMenuIndex = menuList.indexOf(currentMenuID);
        const prevMenuIndex = currentMenuIndex - 1;

        if(!menuList[prevMenuIndex]) return;
        
        const prevMenu = sidebarMenu[menuList[prevMenuIndex]];
        
    return {
        menuID: prevMenu.id
    }
}

export const getMenuStepInfo = menuID =>{

    const menuList = Object.keys(sidebarMenu);

    const stepNo = menuList.indexOf(menuID) + 1;

    const totalSteps = menuList.length;

    const progressVal = Math.ceil(stepNo * 100 / totalSteps);

    return { stepNo, totalSteps, progressVal }
}

export const getHeaderTitle = (menuID) =>{
    console.log('menuID ',menuID);
    const activeMenuIfo = sidebarMenu[menuID];
    return activeMenuIfo.headerText;
}

export const getSubMenuStatus = (mainMenuID, product) =>{
    let success = false;

    if(mainMenuID == sidebarMenu.BASIC.id) {
        success = product.name && product.name.length > 0
        return success
    }

    if(mainMenuID == sidebarMenu.CATEGORY.id) {
        success = product.productCategoryID && product.productCategoryID.length > 0
        return success
    }

    if(mainMenuID == sidebarMenu.INVENTORY.id) {
        return true
    }

    if(mainMenuID == sidebarMenu.PRICE.id) {
        success = product.price && product.price!==''
        return success
    }

    if(mainMenuID == sidebarMenu.TAXES.id) {
        success = product.taxesApplicable
        return success
    }

    if(mainMenuID == sidebarMenu.VISIBILITY.id) {
        success = product.visibility && product.visibility.length > 0
        return success
    }
    
    return

}

export const getProgress = (product) => {
    let count = 0;

    if (product.name && product.name.length > 0)
        count += 1

    if (product.productCategoryID && product.productCategoryID.length > 0)
        count += 1

    // Inventory
    count += 1

    if (product.price && product.price!=='')
        count += 1

    if (product.taxesApplicable)
        count += 1

    if (product.visibility && product.visibility.length > 0)
        count += 1

    return count
}