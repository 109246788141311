import { MENU } from '../../../utils/layout';

const init = {
  currentFolder: '',
  currentSpace: '',
  currentTodo: '',
  currentChat: '',
  currentModule: MENU.live.id,
  currentSubModule: null,
  upgradeAccount: false
};

export default (state = init, action) => {
  switch (action.type) {
    case 'SET_CURRENT_SPACE':
      return {
        ...state,
        currentSpace: action.currentSpace,
        currentModule: 'live'
      }
    case 'SET_CURRENT_SPACE_TYPE':
      return {
        ...state,
        currentSpaceType: action.currentSpaceType,
      }
    //------------------------------------------------------------------------ 
    case "SET_IFRAME":
      return {
        ...state,
        iframe: action.iframe
      };
    //------------------------------------------------------------------------
    case "SET_CURRENT_FOLDER":
      return {
        ...state,
        currentFolder: action.currentFolder
      };
    //------------------------------------------------------------------------ 
    case "SET_CURRENT_FILE":
      return {
        ...state,
        currentFile: action.currentFile
      };
    //------------------------------------------------------------------------ 
    case "SET_CURRENT_TODO":
      return {
        ...state,
        currentTodo: action.currentTodo
      };
    //------------------------------------------------------------------------ 
    case "SET_CURRENT_MAKER":
      return {
        ...state,
        currentMaker: action.currentMaker
      };
    //------------------------------------------------------------------------ 
    case 'SET_CURRENT_MODULE':
      return {
        ...state,
        currentModule: action.currentModule,
        currentSubModule: action.currentSubModule
      };
    //------------------------------------------------------------------------ 
    case 'SET_CURRENT_CHAT':
      return {
        ...state,
        currentChat: action.currentChat
      };
    //------------------------------------------------------------------------ 
    case 'SET_UPGRADE_ACCOUNT':
      return {
        ...state,
        upgradeAccount: action.upgradeAccount
      };
    //------------------------------------------------------------------------ 
    case 'SET_MOBILE_SIDEBAR_OPEN_VALUE':
      return {
        ...state,
        mobileSidebarOpen: action.mobileSidebarOpen
      };
    //------------------------------------------------------------------------ 
    case 'SET_CURRENT_RESERVATION_SETTINGS':
      return {
        ...state,
        reservationSetting: {
          openByID: action.openByID,
          settings: action.settings
        }
      };
    //------------------------------------------------------------------------ 
    case 'SET_SPACE_CHANNEL_STATUS':
      return {
        ...state,
        channelStatusList: action.list
      };
    case 'USER_LOGOUT':
      return {
        ...init
      }
    //------------------------------------------------------------------------
    default:
      return state;
  }
};

