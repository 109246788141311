import http from '../../utils/http'

export function fetchSettings(propertyID) {
  const ACTION_NAME = "FETCH_INVOICE_SETTINGS"
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`/api/frontdesk/invoiceInfo/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data })
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
      });
  };
}

export function updateSettings(propertyID, data) {
  const formData = new FormData();

  if(data.logoImage) {
    formData.append('upload', data.logoImage)
    delete data.logoImage
  }

  Object.keys(data).forEach(k => {
    formData.append(k, data[k])
  })

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const ACTION_NAME = "UPDATE_INVOICE_SETTINGS"
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .post(`/api/frontdesk/invoiceInfo/${propertyID}`, formData, config)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        dispatch({ type: 'LOAD_PROPERTY_SETTINGS', payload: { taxInvoiceEnabled: data.taxInvoiceEnabled || false }});
        return data
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
      })
  }
}

export function manualReset(propertyID) {
  const ACTION_NAME = "MANUAL_RESET";
  const data = {
    type: 'Manual Reset'
  }
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/invoiceInfo/${propertyID}/reset`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}