import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/WarningRounded'

import { CHANNEL } from '../../utils/channels'
import { addRatePlanMapping, deleteRatePlanMapping } from '../../redux/actions/channelMapping/channelRatePlanMapping'

import Grid from '@material-ui/core/Grid'
import { some } from 'lodash'
import clsx from 'clsx'
import { CHANNEX_CHANNELS, HW_RATEPLAN_NAME } from '../../utils/constants'
import { withTranslation } from 'react-i18next'
import { activateChannelProperty } from '../../redux/actions/microsite/bookingCom'
import { fetchChannelStatus } from '../../redux/actions/microsite/channels';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #dadada',
    borderRadius: 5,
    padding: 20,
    marginBottom: 10
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconItem: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 5,
    height: 30,
    width: 30,
    '&:hover': {
      background: '#F0F0F0'
    }
  },
  icon: {
    width: 15,
    height: 15,
    opacity: 0.8
  },
  title: {
    color: '#484848',
    fontSize: '1.2rem'
  },
  derived: {
    opacity: '0.5'
  },
  noHover: {
    cursor: 'default',
    '&:hover': {
      background: '#FFFFFF'
    }
  },
  warningIcon: {
    marginLeft: 5,
    color: '#f3b502'
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    borderRadius: 10,
    boxShadow: '0 2px 30px #F2F2F2',
    minHeight: 25,
    fontSize: '1.2rem',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.2rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const RatePlanMappingItem = (props) => {
  const classes = useStyles()
  const { channelType, roomTypeID, channelRatePlan, channelRatePlans = [], ratePlansAvailableForMapping, roomTypeIDs = {}, currentSpace, t } = props
  const {channexRoomTypeID, channelRoomTypeID} = roomTypeIDs
  const ratePlans = Object.values(props.ratePlans)
  const isDerived = Boolean(channelRatePlan.parentrateplanid)

  const [selectedRatePlan, setSelectedRatePlan] = React.useState('')
  const [mappedRatePlans, setMappedRatePlans] = React.useState('')
  const [edit, setEdit] = React.useState(false)

  const isChannexChannel = CHANNEX_CHANNELS.includes(channelType)
  const channelCode = CHANNEL[channelType].code
  const isAirbnbChannel = channelType === CHANNEL.AirBNB.code

  useEffect(() => {
    // if channex -> channel rate plan mapping does not exist, mapping for counter -> channel not possible
    if (isChannexChannel && !channelRatePlan.channexRatePlanCode) return
    // mapped rate plans
    const mrp = ratePlans.filter(rp => some(rp.mapping, m => (
      m.channel.toUpperCase() === channelCode.toUpperCase() &&
      m.code === channelRatePlan.ratePlanCode &&
      m.roomTypeID === roomTypeID
    )))
    // error: multiple counter rate plans mapped to same channel rate plan
    setMappedRatePlans(mrp)
    if (selectedRatePlan && !mrp.length) setSelectedRatePlan('')
    if (mrp.length > 0) setSelectedRatePlan(mrp[0]._id)
  }, [props.ratePlans])

  const activateChannelHandler = () => {
    const { channelStatus } = props;
    console.log('channelStatus', channelStatus)
    if (channelStatus.mappedRoomCount === 0 && channelStatus.status == 'false') {
      const activateResponse = props.dispatch(activateChannelProperty(currentSpace.propertyID));
      activateResponse.then(data => {
          props.dispatch(fetchChannelStatus(currentSpace.propertyID));
      }).catch(error => {
          const activateAgainResponse = props.dispatch(activateChannelProperty(currentSpace.propertyID));
          activateAgainResponse.then(data => {
              props.dispatch(fetchChannelStatus(currentSpace.propertyID));
          }).catch(error => {
              props.dispatch(fetchChannelStatus(currentSpace.propertyID));
          });
      });
  };
  }

  const onChangeHandler = (selectedRatePlan) => {
    if (channelType === CHANNEL.BookingCom.code) {
      if (props.channelRoomCategory !== 'dorm' && props.counterRoomTypes[roomTypeID].bedsPerRoom !== channelRatePlan.maxOccupancy) {
        props.errorHandler('ratePlanMismatch')
        return;
      }
      if (props.channelRoomCategory === 'dorm' && channelRatePlan.maxOccupancy !== 1) {
        props.errorHandler('roomTypeMismatch')
        return;
      }
    }

    if (channelRatePlan.currency) {
      let counterRatePlan = props.ratePlans?.[selectedRatePlan?.value];
      if (counterRatePlan?.multiCurrency?.enabled && channelRatePlan.currency != counterRatePlan.multiCurrency.currency) {
        props.errorHandler('currencyMismatch')
        return;
      }
      if (!counterRatePlan?.multiCurrency?.enabled && channelRatePlan.currency != currentSpace.currency) {
        props.errorHandler('currencyMismatch')
        return;
      }
    }

    const payload = {
      addMapping: {
        roomTypeID,
        channel: CHANNEL[channelType].code,
        code: channelRatePlan.ratePlanCode
      }
    }
    if (isChannexChannel) {
      payload.addMapping = {
        ...payload.addMapping,
        channexRatePlanCode: channelRatePlan.channexRatePlanCode,
        channelRoomTypeID,
        channexRoomTypeID,
        propertyID: currentSpace.propertyID
      }
    }
    props
      .dispatch(addRatePlanMapping(selectedRatePlan.value, CHANNEL[channelType].code, payload))
      .then(data => {
        if(isChannexChannel){
          activateChannelHandler()
        }
        if (data) {
          setSelectedRatePlan(selectedRatePlan.value)
          props.handleRemove(selectedRatePlan.value)
          setEdit(false)

          //sending out derived rateplan mappings
          const derivedRatePlans = channelRatePlans.filter(rp => String(rp.parentrateplanid) === channelRatePlan.ratePlanCode)
          // recursive function for sending out derived rate plan mappings sequentially
          const mapDerivedRRatePlans = i => {
            if(i >= derivedRatePlans.length) return
            const rp = derivedRatePlans[i]
            const payload = {
              addMapping: {
                roomTypeID,
                channel: CHANNEL[channelType].code,
                code: rp.ratePlanCode,
                derivedOnOTA: true,
                parentCode: channelRatePlan.ratePlanCode
              }
            }
            props
              .dispatch(addRatePlanMapping(selectedRatePlan.value, CHANNEL[channelType].code, payload))
              .then(() => mapDerivedRRatePlans(i + 1))
          }
          if (derivedRatePlans.length > 0) {
            console.log('Sending updates for derived rate plans', derivedRatePlans)
            mapDerivedRRatePlans(0)
          }
        }
      })
  }

  const handleDelete = () => {
    if (selectedRatePlan === '') {
      setEdit(false)
    } else {
      const payload = {
        removeMapping: {
          roomTypeID,
          channel: CHANNEL[channelType].code
        }
      }
      if (isChannexChannel) {
        payload.removeMapping = {
          ...payload.removeMapping,
          channexRatePlanCode: channelRatePlan.channexRatePlanCode,
          channelRatePlanCode: channelRatePlan.ratePlanCode,
          channelRoomTypeID,
          channexRoomTypeID,
          propertyID: currentSpace.propertyID
        }
      }
      props
        .dispatch(deleteRatePlanMapping(selectedRatePlan, CHANNEL[channelType].code, payload))
        .then(data => {
          if (data) {
            props.handleAddBack(selectedRatePlan)
            setSelectedRatePlan('')
          }
        })
    }
  }

  return (
    <Grid container spacing={3} alignItems='center' className={clsx({[classes.derived]: isDerived})}>
      <Grid item xs={4}>
        <div className={classes.title}>
          {HW_RATEPLAN_NAME[channelRatePlan.name] || channelRatePlan.name}
          {' '}({channelRatePlan.ratePlanCode})
        </div>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={4}>
        {!edit && selectedRatePlan && (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className={classes.title}>{ratePlans && ratePlans.find(rp => rp._id === selectedRatePlan).privateName}</div>
            {mappedRatePlans.length > 1 && (
              <div className={classes.warningIcon}>
                <Tooltip title={`${CHANNEL[channelType].name} rate plan mapped to multiple counter rate plans: ${mappedRatePlans.map(mrp => mrp.privateName).join(', ')}`}>
                  <WarningIcon fontSize='small' />
                </Tooltip>
              </div>
            )}
          </div>
        )}
        {isDerived && <div className={classes.title}>{t('beds.channelsSnapshot.ratePlanMapping.derived')}</div>}
        {!edit && !selectedRatePlan && !isDerived && <div className={classes.title}>{t('beds.channelsSnapshot.ratePlanMapping.noMapping')}</div>}
        {edit && !isDerived &&
          <div>
            <Select
              menuPortalTarget={document.querySelector('body')}
              options={ratePlansAvailableForMapping}
              onChange={onChangeHandler}
              isClearable={false}
              isSearchable={false}
              styles={selectStyle}
              theme={theme => ({
                ...theme,
                border: 0,
                colors: {
                  ...theme.colors,
                  primary: '#666666',
                  primary25: '#dddddd'
                }
              })}
            />
          </div>}
      </Grid>

      <Grid item xs={2}>
        {!isAirbnbChannel && (
          <div className={classes.iconContainer}>
            {!edit && selectedRatePlan === '' && (
              <div className={clsx(classes.iconItem,{[classes.noHover]: isDerived})} onClick={() => !isDerived && setEdit(true)}>
                <img src={IMAGES.ICONS.editPencil} className={classes.icon} />
              </div>
            )}
            {(selectedRatePlan !== '' || edit) && !isDerived && <div className={classes.iconItem} onClick={handleDelete}><img src={IMAGES.ICONS.bin} className={classes.icon} /></div>}
          </div>
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, props) => ({
  ratePlans: state.ratePlans,
  channelStatus: state?.inbox?.channels?.channelList?.[props.channelType] || {},
  loading: state.loading.LOAD_RATEPLANS || state.loading.FETCH_CHANNEL_RATEPLANS,
  roomTypeIDs: state.channelMapping.mapping[props.channelType]?.[props.roomTypeID],
  counterRoomTypes: state.roomTypes,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(RatePlanMappingItem))
