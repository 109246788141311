import http from "../../utils/http";

//----------------------------------------------------------------
// GET EXPERIENCE RESERVATIONS
//----------------------------------------------------------------
export function fetchExperiencesBooking(query) {
    const ACTION_NAME = "LOAD_EXPERIENCE_RESERVATIONS_REPORT";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .post(`api/frontdesk/experiences/reports/bookings`, query)
        .then(successResult => {
          // console.log('here', successResult.data)
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: successResult.data });
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
        });
    };
}

//----------------------------------------------------------------
// GET EXPERIENCE OCCUPANCY
//----------------------------------------------------------------
export function fetchExperiencesOccupancy(query) {
    const ACTION_NAME = "LOAD_EXPERIENCES_OCCUPANCY_REPORT";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .post(`api/frontdesk/experiences/reports/occupancy`, query)
        .then(successResult => {
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:successResult.data });
          //return successResult.data;
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
          return;
        });
    };
}

//----------------------------------------------------------------
// GET EXPERIENCE REVENUE
//----------------------------------------------------------------
export function fetchExperiencesRevenue(query) {
    const ACTION_NAME = "LOAD_EXPERIENCE_REVENUE_REPORT";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .post(`api/frontdesk/experiences/reports/revenue`, query)
        .then(successResult => {
          // console.log('here', successResult.data)
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:successResult.data });
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
          return;
        });
    };
}

//----------------------------------------------------------------
// RESET RESERVATIONS REPORT
//----------------------------------------------------------------
export const resetExperienceReservationsReport = () => ({
  type: 'RESET_EXPERIENCE_RESERVATIONS_REPORT'
})

//----------------------------------------------------------------
// RESET OCCUPANCY REPORT
//----------------------------------------------------------------
export const resetExperienceOccupancyReport = () => ({
  type: 'RESET_EXPERIENCE_OCCUPANCY_REPORT'
})

//----------------------------------------------------------------
// RESET REVENUE REPORT
//----------------------------------------------------------------
export const resetExperienceRevenueReport = () => ({
  type: 'RESET_EXPERIENCE_REVENUE_REPORT'
})