import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';

import Pill from '../../../common/Pill';
import NewTaxForm from './NewTaxForm';

import { createTaxItem } from '../../../../redux/actions/taxes/taxes';

const useStyles = makeStyles(theme =>({
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 25,
    display: 'flex',
    marginTop: 5,
    width: '200px',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  toggleSelect: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  pillContainer:{
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%',
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
}));

const Taxes = props => {
    const classes = useStyles();
    const {
        product,
        handleUpdateProduct,
        currentSpace,
        taxes
    } = props;

    const [taxEnabled, setTaxEnabled] = React.useState(product.taxesApplicable ? (product.taxesApplicable.length==0 ? false : true) : '');
    const [taxArray, setTaxArray] = React.useState([]);
    const [createTax, setCreateTax] = React.useState(false);

    useEffect(() => {
      createTaxArray();
    }, [])

    useEffect(() => {
      createTaxArray();     
    }, [taxes])

    const createTaxArray = () => {
      const spaceTaxes = currentSpace.taxes && currentSpace.taxes.map(tax => {
        if (taxes[tax] && (taxes[tax].type === 'percentOfTotal'))
          return { id: tax, value: taxes[tax].value, label: taxes[tax] && taxes[tax].name }
      }).filter(tax => tax)
      setTaxArray([...spaceTaxes])
    }

    const handleCancelTax = () => {
      setTaxEnabled(false);
      handleUpdateProduct('taxesApplicable', []);
    }

    const handleAddTax = () => {
      setTaxEnabled(true);
      handleUpdateProduct('taxesApplicable', []);
    }

    const handleClick = (tax) => {
      const index = product.taxesApplicable.indexOf(tax);
      if (index > -1) {
        let newTaxesArray = [...product.taxesApplicable];
        newTaxesArray.splice(index, 1);
        handleUpdateProduct('taxesApplicable', [...newTaxesArray]);
      } else {
        handleUpdateProduct('taxesApplicable', [...product.taxesApplicable, tax]);
      }
    }

    const handleSubmit = (data) => {
      const response = props.dispatch(createTaxItem(
        currentSpace._id,
        currentSpace.propertyID,
        { ...data, propertyID: currentSpace.propertyID, }
      ));
      response.then(response =>{
          if(response){
            setCreateTax(false);
          }
      });
      
    }

    return (
        <>
            <div className="main-content">
                
              <div className="main-content-title">Taxes</div>
              <div className="sub-title">Setup taxes for this product</div>

              <div className="row">
                <div className="label">Enable Taxes</div>
                <div className={classes.toggle}>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: taxEnabled===false})}
                    onClick={handleCancelTax}
                  >
                    OFF
                  </div>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: taxEnabled===true})}
                    onClick={handleAddTax}
                  >
                    ON
                  </div>
                </div>
              </div>
              

              {taxEnabled && <div className={"row"}>
                <div className={classes.pillContainer}>
                  {taxArray.map(tax => 
                    <Pill 
                      key={tax.id}
                      name={`${tax.label} - ${tax.value}%`} 
                      handleClick={() => handleClick(tax.id)} 
                      selected={product.taxesApplicable.indexOf(tax.id) > -1}
                      green={product.taxesApplicable.indexOf(tax.id) > -1} 
                      cancelButton={product.taxesApplicable.indexOf(tax.id) > -1}
                    />
                  )}
                </div>
              </div>}

              {taxEnabled && !createTax && <div className="row">
                <div className={classes.link} onClick={() => setCreateTax(true)}>Create a new tax</div>
              </div>}

              {taxEnabled && createTax && <div className="row">
                <NewTaxForm handleSubmit={handleSubmit} handleCancel={() => setCreateTax(false)} />
              </div>}

            </div>
        </>
    )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  taxes: state.taxes
})

export default connect(mapStateToProps)(Taxes); 