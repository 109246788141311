import { normalize } from "normalizr";
import http from "../../utils/http";
import { upsellList } from "../../schema/upsellSchema";

//----------------------------------------------------------------
// ADD UPSELL ITEM
//----------------------------------------------------------------
export function addUpsellItem(spaceID, item) {
  const ACTION_NAME = "ADD_UPSELL_ITEM";
  return function (dispatch) {
    return http
      .post("/api/frontdesk/upsell", item)
      .then((successResult) => {
        const { data } = successResult;

        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data, spaceID });
      })
      .catch((errorResult) => {
        const error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LIST UPSELL ITEMS
//----------------------------------------------------------------
export function listUpsellItems(spaceID, propertyID) {
  const ACTION_NAME = "LIST_UPSELL_ITEMS";
  return function (dispatch) {
    return http
      .get(`/api/frontdesk/upsell/${propertyID}`)
      .then((successResult) => {
        const { data } = successResult;
        const normalizedOrder = normalize(data, [upsellList]);

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            upsellItem: normalizedOrder.entities.upsellList,
            spaceID,
          },
        });
      })
      .catch((errorResult) => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE UPSELL ITEM
//----------------------------------------------------------------
export function updateUpsellItem(id, item) {
  const ACTION_NAME = "UPDATE_UPSELL_ITEM";
  return function (dispatch) {
    return http
      .patch(`/api/frontdesk/upsell/${id}`, item)
      .then((successResult) => {
        const { data } = successResult;

        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch((errorResult) => {
        const error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
