import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import http from "../../redux/utils/http";

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme =>({
    root:{
        padding: '25px',
        [theme.breakpoints.up("sm")]:{
            minWidth: '700px'
        },
        [theme.breakpoints.down("sm")]:{
            margin: '0px',
            paddingTop: '25px',
        }
    },
    resContainer:{
        boxShadow: '0 2px 20px #E0E0E0',
        borderRadius: '15px',
        marginTop: '15px',
    },
    dateInfoContainer:{
        display: 'flex',
        justifyContent: 'space-around'
    },
    dateInfo:{
        textAlign: 'center',
        padding: '15px 0px',
    },
    bedInfo:{
        width: '100%',
        textAlign: 'center',
        padding: '15px 0px',
        borderTop: '1px solid #E0E0E0',
    },
    arrowImgContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        [theme.breakpoints.up("sm")]:{
            height: '100%'
        },

        [theme.breakpoints.down("sm")]:{
            margin: '10px 0px',
            '-webkit-transform': 'rotate(90deg)',
            '-moz-transform': 'rotate(90deg)',
            '-o-transform': 'rotate(90deg)',
            '-ms-transform': 'rotate(90deg)',
            transform: 'rotate(90deg)',
        }
    },
    arrowImg:{
        width: '35px',
        height: '35px'
    },
    actionContainer:{
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px'
    },
    actionItems:{
        boxShadow: '0 2px 20px #E0E0E0',
        borderRadius: '15px',
        padding: '20px 35px',
        textAlign: "center"
    },
    orignalRateBtn:{
        backgroundColor: '#eae6e659',
        color: '#c6bfbf',
        borderRadius: '25px',
        padding: '7px 15px',
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: '#4F9581',
            color:' #fff',
        },
        [theme.breakpoints.up("sm")]:{
            padding: '7px 40px',
        },
    },
    orignalRateBtnActive:{
        backgroundColor: '#4F9581',
        color: '#fff',
        borderRadius: '25px',
        padding: '7px 15px',
        fontWeight: 'bold',
        pointerEvents: 'none',
        '&:hover':{
            pointer: 'none'
        },
        [theme.breakpoints.up("sm")]:{
            padding: '7px 40px',
        },
    },
    cancelBtn:{
        borderRadius: '25px',
        padding: '5px 30px',
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: '#000',
            color: '#fff',
        },
        [theme.breakpoints.up("sm")]:{
            marginRight: '10px'
        },
    },
    confirmBtn:{
        borderRadius: '25px',
        backgroundColor: '#000',
        padding: '5px 30px',
        color: '#fff',
        marginBottom: '10px',
        fontWeight: 'bold',
        '&:hover':{
            color: '#fff',
            backgroundColor: '#4b4b4b'
        },
        [theme.breakpoints.up("sm")]:{
            marginBottom: '0px'
        },
        '&:disabled':{
            background: '#dddddd'
        }
    },
    title:{
        fontSize: '1.3rem',
        fontWeight: 'bold'
    },
    subTitle:{
        fontWeight: 'bold',
        color: '#737373',
        fontSize: '1.2rem'
    },
    title2:{
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '5px'
    },
    confirmBtnContainer:{
        display: "flex",
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]:{
            flexDirection: "row-reverse"
        }
    },
    loader: {
        height: '36px'
    }
}));

const ConfirmModal = ({
    isOpen,
    closeHandler,
    confirmData,
    updateCalenderReservation,
    currency
}) =>{
    let oldReservationData = {};
    let newReservationData = {};
    let totalAmount = 0;
    let averageAmount = 0;
    
    if(confirmData){
        oldReservationData = confirmData.oldReservationData;
        newReservationData = confirmData.newReservationData;
        totalAmount = confirmData.totalAmount;
        averageAmount = confirmData.averageAmount;
    }

    const CancelToken = axios.CancelToken;
    let sourceToken = CancelToken.source();

    const classes = useStyles();
    const [sustainRates, setSustainRates] = useState(true);
    const [newRates, setNewRates] = useState({ totalAmount: 0, averageAmount: 0, tax: 0, rates: []});
    const [isFetchInProgress, setIsFetchInProgress] = useState(false);

    useEffect(()=>{
        return () =>sourceToken.cancel('modal closed.');
    },[]);

    const updateReservation = () =>{
        const data = { ...confirmData.updateData, sustainRates, rateList: newRates?.rateList };
        updateCalenderReservation(data);
    }

    const getNewRoomRates = () =>{
        if(newRates.totalAmount > 0){
            setSustainRates(false);
            return;
        }

        const { 
            updates: { 
                bedID, 
                checkIn, 
                checkOut
            } 
        } = confirmData.updateData;

        setIsFetchInProgress(true);
        http.get(`/api/frontdesk/rates/calendar/${confirmData.propertyID}?bedID=${bedID}&startDate=${checkIn}&endDate=${checkOut}`, {
            cancelToken: sourceToken.token
        })
        .then(successResult => {
            const rateData = successResult.data;
            if(rateData.rates.length > 0){
                const totalAmount = rateData.rates.reduce((sum, rate) => sum + rate);
                const averageAmount = totalAmount/rateData.rates.length;
                const tax = rateData.exclusiveTax;
                setIsFetchInProgress(false);
                setNewRates({ totalAmount, averageAmount,tax, rateList: rateData?.rateList});
                setSustainRates(false);
            }
        })
        .catch(errorResult => {
            let error =
            errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            console.log('error ',error);
            setIsFetchInProgress(false);
        });
    }

    const closeModalHandler = () =>{
        closeHandler();
    }

    return(
        <Dialog
            open={isOpen}
            onClose={()=>{}}
            PaperProps={{ classes: {root: classes.root } }}
        >
            <Grid container>
                <Grid item xs={12} sm={5}>
                    <BookingInfoCard 
                        title = "Existing Booking"
                        {...oldReservationData}

                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box className={classes.arrowImgContainer}>
                        <img className={classes.arrowImg} src={IMAGES.ICONS.arrows} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <BookingInfoCard 
                        title = "New Booking"
                        {...newReservationData}
                    />
                </Grid>
            </Grid>
            <Box className={classes.actionContainer}>
                <Box className={classes.actionItems}>
                    <Button 
                        className={sustainRates ? classes.orignalRateBtnActive : classes.orignalRateBtn}
                        onClick={()=>setSustainRates(true)}
                    >
                        Keep original rates
                    </Button>
                    <Box marginTop="10px" align="center">
                        <Typography variant="subtitle2" component="div" className={classes.subTitle}>
                            Total {currency}{totalAmount}
                        </Typography>
                        <Typography variant="subtitle2" component="div" className={classes.subTitle}>
                            Average base rate {currency}{averageAmount}
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="subtitle2" component="div" className={classes.title2}>
                    or
                </Typography>
                <Box className={classes.actionItems}>
                    <Button 
                        className={!sustainRates ? classes.orignalRateBtnActive : classes.orignalRateBtn}
                        onClick={getNewRoomRates}
                     >
                         Set rates of new room
                    </Button>
                    <Box marginTop="10px" align="center">
                        { isFetchInProgress && ( 
                            <Box className={classes.loader}>
                                <CircularProgress/>
                            </Box>
                        )}
                        { (newRates.totalAmount > 0) && (
                            <>
                                <Typography variant="subtitle2" component="div" className={classes.subTitle}>
                                    Total {currency}{newRates.totalAmount}
                                </Typography>
                                <Typography variant="subtitle2" component="div" className={classes.subTitle}>
                                    Average base rate {currency}{newRates.averageAmount}
                                </Typography>
                                <Typography variant="subtitle2" component="div" className={classes.subTitle}>
                                    tax {currency}{newRates.tax}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.confirmBtnContainer}>
                <Button 
                    variant="outlined" 
                    className={classes.confirmBtn} 
                    onClick={updateReservation}
                    disabled={isFetchInProgress}
                >
                    Confirm
                </Button>
                <Button variant="outlined" className={classes.cancelBtn} onClick={closeModalHandler}>Cancel</Button>
            </Box>
        </Dialog>
    )
}

const BookingInfoCard = props =>{
const classes = useStyles();
    return(
        <Box>
            <Typography variant="h4" component="div" align="center">
                        {props.title}
            </Typography>
            <Box className={classes.resContainer}>
                <Box className={classes.dateInfoContainer}>
                    <Box className={classes.dateInfo}>
                        <Typography  component="div" className={classes.title} gutterBottom={true}>
                            Check-in
                        </Typography>
                        <Typography  component="div" className={classes.subTitle}>
                            {props.checkinDate}
                        </Typography>
                    </Box>
                    <Box className={classes.dateInfo}>
                    <Typography  component="div" className={classes.title} gutterBottom={true}>
                            Check-out
                        </Typography>
                        <Typography component="div"  className={classes.subTitle} >
                            {props.checkoutDate}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.bedInfo}>
                    <Typography component="div" className={classes.title}>
                        {props.roomName}
                    </Typography>
                    <Typography component="div" className={classes.title}>
                        {props.bedName}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default ConfirmModal;