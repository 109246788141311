import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

import http from '../../redux/utils/http';
import { addUser, addInvitedUser, addUserToSpace, loginAndConnect } from '../../redux/actions/user/user';


import { makeStyles, Grid, Hidden, Typography, Button, Checkbox, InputBase, TextField, CircularProgress } from '@material-ui/core';
import { logoutUser } from '../../redux/actions/auth/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'scroll',
    width: '100%',
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    background: '#F5F1ED',
    flexGrow: 1,
    height: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 20,
  },
  headerImage: {
    borderRadius: 5,
    objectFit: 'cover',
    height: 60,
    width: 60,
  },
  headerText: {
    color: '#333333',
    fontSize: '1.6rem',
    lineHeight: 1.4,
    marginLeft: 15,
    fontWeight: 600,
  },
  background: {
    alignItems: 'center',
    background: '#FFFFFF',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  expiredLink: {
    textAlign: 'center'
  },
  center: {
    alignItems: 'center',
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#ffffff',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80,
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  input: {
    display: "none"
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  buttonRow: {
    marginBottom: 20,
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem',
    marginTop: 20,
  },
  imageContainer: {
    width: '50%'
  },
  signupImage: {
    objectFit: 'contain',
    width: '100%',
  },
  welcome: {
    marginBottom: 15,
    marginTop: 30,
  },
}))

const NewUserPage = (props) => {
  const classes = useStyles();
  const { currentUser } = props;
  const token = new URLSearchParams(useLocation().search).get("token");
  const [ user, setUser ] = useState({});
  const [ existingUser, setExistingUser ] = useState(false);
  const [ unknown, setUnknown ] = useState(false);
  const [ loggedInUser, setLoggedInUser ] = useState(false);
  const [ newSignUp, setNewSignup ] = useState(false);
  const [ error, setError ] = useState({name: false, email: false, password: false, passwordMatch: false});
  const [ preview, setPreview ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ invalidToken, setInvalidToken ] = useState(false);
  const [ space, setSpace ] = useState({});
  const [ selectedFile, setSelectedFile ] = useState();

  const loadDataOnlyOnce = () => {
    console.log(token);
    http
    .get(`/api/users/verify/invite/${token}`)
    .then(successResult => {
      const data = successResult.data;
      console.log(data);
      setSpace(data.space);

      if (data.user) {
        setUser({...data.user});
        if (currentUser._id === data.user._id) {
          console.log('existing user = invited user');
          setUser({name: currentUser.name, email: currentUser.email, _id: currentUser._id});
          setLoggedInUser(true);
        } else if (data.user.profileExists) {
          console.log('logged in as different user - logging out');
          props.dispatch(logoutUser());
          setExistingUser(true);
        } else {
          setNewSignup(true);
        }
      } else {
        if (currentUser && currentUser._id) {
          console.log('logged in user');
          setLoggedInUser(true);
          setUser({name: currentUser.name, email: currentUser.email, _id: currentUser._id});
        } else {
          console.log('unknown user - invite by link');
          setUnknown(true);
        }
      }

      setLoading(false);

    })
    .catch(errorResult => {
      setInvalidToken(true);
      setLoading(false);
      console.log(errorResult);
    });
  }

  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleLogin = () => {
    props.history.push('/login');
  }

  const handleUpdate = (e) => {
    setUser({...user, [e.target.name]: e.target.value})
    if (e.target.value !=='') {
      if (e.target.name==='password' || e.target.name==='password2') {
        setError({...error, [e.target.name]: false, passwordMatch: false})
      } else {
        setError({...error, [e.target.name]: false})
      }
    }
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
    setUser({ ...user, image: e.target.files[0] })
  }

  const handleValidate = () => {
    let foundError = false
    let errorStep = {}
    if (!user.name) {
      errorStep.name = true;
      foundError = true;
    }
    if (!user.email || !user.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errorStep.email = true;
      foundError = true;
    }
    if ((newSignUp || existingUser || unknown) && (!user.password || user.password.length < 7)) {
      errorStep.password = true
      foundError = true
    }
    if ((newSignUp || unknown) && (user.password!==user.password2)) {
      errorStep.passwordMatch = true
      foundError = true
    }
    if ((newSignUp || unknown) && (!user.password2)) {
      errorStep.password2 = true
      foundError = true
    }
    if (foundError) {
      setError({...error, ...errorStep})
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (loggedInUser) {
      console.log('add user to space');
      props.dispatch(addUserToSpace(space._id, user._id, props.history));
    } else if (checkInput) {
      if (existingUser) {
        console.log('login and connect');
        props.dispatch(loginAndConnect({email: user.email, password: user.password}, space, props.history));
      } else if (newSignUp) {
        console.log('new signup');
        props.dispatch(addInvitedUser(space, user, token, props.history));
      } else if (unknown) {
        console.log('unknown user');
        props.dispatch(addUser(space, user, props.history));
      }
    }
  }

  return(
    <div className={classes.root}>

      <Grid container className={classes.container}>

        {loading && <Grid item xs={12} md={6}>
          <div className={classes.loadingState}><CircularProgress /></div>
        </Grid>}

        {invalidToken &&
          <Grid item xs={12} md={6}>
            <div className={classes.section}>
              <div className={clsx(classes.row, classes.expiredLink)}>
                This link has expired. Please request a new link.
              </div>
              <div className={clsx(classes.row, classes.center)}>
                <Typography variant="body2">
                  Already have an account? <span className={classes.link} onClick={handleLogin}>Log in</span>
                </Typography>
              </div>
            </div>
          </Grid>
        }

        {!loading && !invalidToken && <Grid item xs={12} md={6}>
          <div className={classes.section}>

            <div className={classes.row}>
              <div className={classes.header}>
                <img
                  className={classes.headerImage}
                  src={(space.profilePic?.includes('undefined') || !space.profilePic) ?
                            '/images/placeholder-hostel.jpg' :
                            space.profilePic}
                />
                <div className={classes.headerText}>Hello! Welcome to {space.name}</div>
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.label}>Email</div>
              {unknown && <div className={classes.field} style={{border: error.email ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase
                  id="email"
                  name="email"
                  fullWidth
                  type="email"
                  onChange={handleUpdate}
                />
              </div>}
              {!unknown && user.email}
            </div>

            <div className={classes.row}>
              <div className={classes.label}>Name</div>
              {!loggedInUser && !existingUser && <div className={classes.field} style={{border: error.name ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase
                  id="name"
                  name="name"
                  defaultValue={user.name}
                  fullWidth
                  type="text"
                  onChange={handleUpdate}
                />
              </div>}
              {(loggedInUser || existingUser) && user.name}
              {error.name && <div className={classes.error}>Please enter your name</div>}
            </div>

            {(newSignUp || unknown) && <div className={classes.row}>
              <div className={classes.label}>Profile Picture</div>
              <div className={classes.picContainer}>
                <div className={classes.placeholder}>
                  {preview && <img className={classes.image} src={preview} />}
                </div>
                <label htmlFor="upload-property-image"><div className={clsx(classes.link, classes.label)}>Upload profile picture</div></label>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="upload-property-image"
                  type="file"
                  onChange={handleCapture}
                />
              </div>
            </div>}

            {!loggedInUser && <div className={classes.row}>
              <div className={classes.label}>Password</div>
              <div
                className={classes.field}
                style={{border: (error.password || error.passwordMatch)  ? '1px solid red' : '1px solid #ffffff'}}
              >
                <InputBase
                  id="password"
                  name="password"
                  // placeholder="6+ characters"
                  fullWidth
                  type="password"
                  onChange={handleUpdate}
                />
              </div>
              {error.password && <div className={classes.error}>Password must be longer than 6 characters</div>}
              {error.passwordMatch && <div className={classes.error}>Passwords must match</div>}
            </div>}

            {(newSignUp || unknown) && <div className={classes.row}>
              <div className={classes.label}>Repeat Password</div>
              <div
                className={classes.field}
                style={{border: (error.password || error.passwordMatch) ? '1px solid red' : '1px solid #ffffff'}}
              >
                <InputBase
                  id="password2"
                  name="password2"
                  // placeholder="6+ characters"
                  fullWidth
                  type="password"
                  onChange={handleUpdate}
                />
              </div>
              {error.password && <div className={classes.error}>Password must be longer than 6 characters</div>}
              {error.password2 && <div className={classes.error}>Please re-enter your password</div>}
              {error.passwordMatch && <div className={classes.error}>Passwords must match</div>}
            </div>}

            <div className={clsx(classes.row, classes.buttonRow)}>
              <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
                Take me to Workspace
              </Button>
            </div>

            {unknown && <div className={clsx(classes.row, classes.center)}>
              <Typography variant="body2">
                Already have an account? <span className={classes.link} onClick={handleLogin}>Log in</span>
              </Typography>
            </div>}

          </div>
        </Grid>}

        <Hidden smDown><Grid item xs={12} md={6}>
          <div className={classes.background}>
            <div className={classes.imageContainer}>
              <img className={classes.signupImage} src="/images/community-large.png" />
            </div>
            <div className={classes.welcome}>
              <Typography variant="h2" style={{color: '#333333'}}>counter</Typography>
            </div>
            <div>
              <Typography variant="h5" style={{fontWeight: 600}}>all-in-one workspace for hostels</Typography>
            </div>
          </div>
        </Grid></Hidden>

      </Grid>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: state.auth.user
})

export default connect(mapStateToProps)(withRouter(NewUserPage));
