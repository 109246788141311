import React from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { makeStyles, InputBase, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px 0px 0px 10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%',
    '& > div': {
      width: '100%'
    }
  },
  textInputContainer: {
    display: 'flex',
    marginTop: '10px'
  },
  fieldError: {
    border: '1px solid red',
  },
  select: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 10,
    width: '80%',
  },
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const PropertyMapForm = (props) => {
  const { t } = props
  const classes = useStyles();
  const { isDisabled } = props;
  const [mappedProperty, setMappedProperty] = React.useState(null);
  const [error, setError] = React.useState({ mappedProperty: false });
  const [mappedPropertyOptions, setMappedPropertyOptions] = React.useState([]);

  React.useEffect(() => {
    let list = []
    Object.keys(props.properties).map(key => list.push({ value: key, label: props.properties[key].name }))
    setMappedPropertyOptions(list)
  }, [props.properties])

  const handleSubmit = () => {
    if (!mappedProperty) setError({ mappedProperty: true })
    else props.handleSubmit(mappedProperty)
  }

  return (
    <div className={classes.row}>
      <div className={classes.label}>{t('beds.channelsSnapshot.mapProperty')}</div>
      <div className={classes.textInputContainer}>
        <div className={clsx(classes.select, { [classes.fieldError]: error.mappedProperty })}>
          <Select
            isSearchable={true}
            isDisabled={isDisabled}
            name="mappedProperty"
            onChange={(option) => setMappedProperty(option.value)}
            options={mappedPropertyOptions}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
        <Button variant="outlined" onClick={handleSubmit}>Map</Button>
      </div>
    </div>
  )
}

export default withTranslation()(PropertyMapForm);