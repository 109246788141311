import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, InputBase } from "@material-ui/core";
// import { ICONS } from '../../utils/imageUrls';
import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    minHeight: 30,
    padding: 0,
    minWidth: 30,
    width: "100%",
    height: 50,
    boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.75)",
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
      minWidth: "unset",
      minHeight: "unset",
      padding: 15,
    },
    "&.rounded": {
      border: "1px solid #E0E0E0",
      borderRadius: "50%",
      justifySelf: "center",
      maxHeight: 50,
      maxWidth: 50,
      boxShadow: "none",
    },
  },
  icon: {
    height: 20,
    width: 20,
    "&.rounded": {
      height: 12,
      width: 12,
    },
  },
  disabled: {
    background: "#bdbdbd",
    cursor: "default",
    "& h6, & svg, & h4": {
      color: "#fff",
    },
    "& img": {
      filter: "invert(100%)",
    },
  },
  count: {
    alignItems: "center",
    background: "#fff",
    borderRadius: 5,
    display: "flex",
    height: 50,
    boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.75)",
    justifyContent: "center",
    width: "100%",
    minHeight: 50,
    minWidth: 50,
    "& h4": {
      fontSize: "2rem",
      fontWeight: 500,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      padding: "0 5px",
      width: "calc(100% - 10px)",
    },
    "&.rounded": {
      boxShadow: "none",
    },
  },
  selected: {
    background: "#E0E0E0",
    "&.rounded": {
      background: "unset",
    },
  },
  countInput: {
    fontSize: "2rem",
    fontWeight: 500,
    "& input": {
      textAlign: "center",
    },
    "&.rounded": {
      fontSize: "1.7rem",
      fontWeight: 600,
      "& input": {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
}));

const PlusMinus = (props) => {
  const classes = useStyles();
  const { fullWidth, prefilledCount } = props;
  const minCount = props.minimum ? parseInt(props.minimum) : 0;
  const maxCount =
    props.maximum || props.maximum === 0 ? parseInt(props.maximum) : null;
  const [count, setCount] = React.useState(minCount);

  React.useEffect(() => {
    setCount(prefilledCount || 0);
  }, [prefilledCount]);

  const addCount = () => {
    if (minCount < 0 && maxCount === 0 && count < 0) {
      let updatedCount = count + 1;
      setCount(updatedCount);
      props.onCountChange(updatedCount, props.itemID);
    } else if (maxCount === 0) {
      setCount(0);
      props.onCountChange(0, props.itemID);
    } else if (!maxCount || (maxCount && count < maxCount)) {
      let updatedCount = count + 1;
      setCount(updatedCount);
      props.onCountChange(updatedCount, props.itemID);
    }
  };

  const minusCount = () => {
    if (count > minCount) {
      let updatedCount = count - 1;
      setCount(updatedCount);
      props.onCountChange(updatedCount, props.itemID);
    }
  };

  const updateCount = (value) => {
    value = parseInt(value) || 0;
    if (value >= minCount && (maxCount ? value <= maxCount : true)) {
      setCount(value);
      props.onCountChange(value, props.itemID);
    }
  };

  return (
    <Grid
      container
      spacing={props.design === "rounded" ? 1 : 1}
      justifyContent="center"
    >
      <Grid
        item
        xs={fullWidth ? 4 : 3}
        sm={fullWidth ? 4 : 2}
        md={fullWidth ? 4 : 1}
        className="grid-inc-dec"
      >
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.disabled]: props.disabled,
            rounded: props.design === "rounded",
          })}
          disabled={props.disabled}
          onClick={() => minusCount()}
        >
          <img
            className={clsx(classes.icon, {
              rounded: props.design === "rounded",
            })}
            src={IMAGES.ICONS.darkMinus}
          />
        </Button>
      </Grid>
      <Grid
        item
        xs={fullWidth ? 4 : 3}
        sm={fullWidth ? 4 : 2}
        md={fullWidth ? 4 : 1}
        className="grid-count"
      >
        <span
          className={clsx({
            [classes.count]: true,
            [classes.selected]: count !== minCount,
            rounded: props.design === "rounded",
          })}
        >
          <InputBase
            id={"count" + props.itemID}
            name="count"
            value={count}
            className={clsx(classes.countInput, {
              rounded: props.design === "rounded",
            })}
            fullWidth
            type="tel"
            onChange={(e) => updateCount(e.target.value)}
          />
        </span>
      </Grid>
      <Grid
        item
        xs={fullWidth ? 4 : 3}
        sm={fullWidth ? 4 : 2}
        md={fullWidth ? 4 : 1}
        className="grid-inc-dec"
      >
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.disabled]: props.disabled,
            rounded: props.design === "rounded",
          })}
          disabled={props.disabled}
          onClick={() => addCount()}
        >
          <img
            className={clsx(classes.icon, {
              rounded: props.design === "rounded",
            })}
            src={IMAGES.ICONS.darkAdd}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default PlusMinus;
