const legal = {};

export default (state = legal, action) => {
  switch (action.type) {
      case "LOAD_SETUP_SUCCESS":
      case "SUBMIT_SETUP_SUCCESS":
      case "UPDATE_SETUP_SUCCESS":
      case "UPDATE_SCHEDULE_SUCCESS":
        return {
          ...action.payload
        }
      case "LOAD_PROCESSED_SUCCESS":
        let thisLegal = { ...state }
        if(thisLegal)
          thisLegal.processed = action.payload

        return {
          ...thisLegal
        }

      case "LOAD_QUEUE_SUCCESS":
        let thisState = { ...state }
        if(thisState)
          thisState.queue = action.payload

        return {
          ...thisState
        }

      case "LOAD_FAILED_SUCCESS":
        let currentState = { ...state }
        if(currentState)
          currentState.failed = action.payload

        return {
          ...currentState
        }

      case "RESET_SIBA":
          return { }
      default:
          return state;
  }
};