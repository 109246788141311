import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, Hidden, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { fetchCurrencyFormat, fetchDateFormat, getAmtFormat, humanise } from '../../../../utils/utility';
import OneFieldForm from '../../../common/OneFieldForm';
import { EXPERIENCE_STATUS } from '../../../../utils/constants';
import ConfirmForm from '../../../common/ConfirmForm';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: 20,
		boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 75%)',
		borderRadius: 5
	},
	section: {
		padding: '10px 20px',
		borderBottom: '1px solid #dadada',
	},
	header: {
		display: 'flex',
		'& > div': {
			width: '50%',
			display: 'flex',
			alignItems: 'center',
		}
	},
	headerLeft: {
		'& > span': {
			fontSize: '1.6rem',
			fontWeight: 600
		},
		'& > img': {
			marginLeft: 10
		}
	},
	headerRight: {
		justifyContent: 'flex-end'
	},
	body: {
		display: 'flex',
		padding: '10px 20px',
	},
	formInput: {
		border: '1px solid #dadada',
		padding: '10px 10px 10px 15px',
		display: 'flex',
		alignItems: 'center',
		width: '200px',
		marginRight: 5,
		borderRadius: 30,
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 27px)',
		}
	},
	data: {
		width: '80%',
		wordBreak: 'break-word'
	},
	iconSection: {
		width: '20%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	icon: {
		width: '20px'
	},
	label: {
		fontSize: '1.1rem'
	},
	value: {
		fontSize: '1.1rem',
		fontWeight: 600
	},
	actionIcon: {
		width: 20,
		cursor: 'pointer'
	},
	actionButton: {
		cursor: 'pointer',
		border: '1px solid #dadada',
		padding: '5px 10px',
		marginLeft: 5,
		minWidth: 50,
		textAlign: 'center',
		borderRadius: 30,
		fontSize: '1.2rem'
	},
	imageCard: {
		position: 'relative',
		'& img': {
			width: '100%',
			// height: '75%'
		},

		minHeight: 150,
		backgroundColor: '#f2f4f8',
		borderRadius: 5,
	},
	availability: {
		position: 'absolute',
		padding: '5px 10px',
		top: 20,
		fontSize: '1rem',
		color: '#C87250',
		backgroundColor: '#fff',
		borderRadius: '0px 5px 5px 0',
	},
	details: {
		'& > div': {
			paddingBottom: 5
		}
	},
	title: {
		fontWeight: 600
	},
	location: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: -4,
	},
}));

const FormData = ({
	classes,
	fieldName,
	displayValue,
	keyName,
	value,
	inEditMode,
	editableFieldHandler,
	handleSubmit,
	editOption
}) => {
	return (
		<React.Fragment>
			{!inEditMode ?
				<div className={classes.formInput}>
					<div className={classes.data}>
						<div className={classes.label}>{fieldName}</div>
						<div className={classes.value}>{displayValue}</div>
					</div>
					{editOption &&
						<div className={classes.iconSection}>
							<img
								className={classes.actionIcon}
								src={IMAGES.ICONS.editPencil}
								onClick={() => editableFieldHandler(true)}
							/>
						</div>
					}
				</div> :
				<div className={clsx(classes.data, 'fullWidth')}>
					<div className={classes.label}>{fieldName}</div>
					<OneFieldForm
						type="number"
						defaultValue={value}
						handleClose={() => editableFieldHandler(false)}
						handleSubmit={(value) => {
							handleSubmit(value);
							editableFieldHandler(false);
						}}
					/>
				</div>
			}
		</React.Fragment>
	)
}

const ProductCard = (props) => {
	const { classes, data, products, timezone } = props;
	const [inEditMode, setInEditMode] = React.useState(false);

	const getLocationHandler = (locations = []) => {
		return locations.toString().replace(/,/g, ', ')
	}

	return (
		<div className={classes.body}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={4}>
					<div className={classes.imageCard}>
						<img src={data?.product?.photos?.[0]?.original} />
						<div className={classes.availability}>{data?.availability?.available ? `${data?.availability?.available} Spots left` : 'SOLD OUT'}</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={8}>
					<div className={classes.details}>
						<div className={classes.title}>{data?.product?.title || '-'}</div>
						<div className={classes.value}>{fetchDateFormat(data.date, 'Do MMM YYYY') + ' | ' + (data?.availability?.startTime || '')}</div>
						{data?.product?.locations?.length > 0 &&
							<div className={classes.location}><img src={IMAGES.ICONS.location} className={classes.icon} /> {getLocationHandler(data.product.locations)}</div>
						}

						<FormData
							classes={classes}
							fieldName="Price per person"
							keyName={"pricePerPerson"}
							displayValue={getAmtFormat(data?.price?.raw, fetchCurrencyFormat(data?.price?.currency))}
							value={data?.price?.raw}
							editOption={true}
							inEditMode={inEditMode}
							editableFieldHandler={setInEditMode}
							handleSubmit={props.handleSubmit}
						/>
					</div>
				</Grid>
			</Grid>

		</div>
	)
}

const ExperienceCard = (props) => {
	const { classes, data, timezone, primary, product, keyIndex, updateProductStatusHandler } = props;
	const [formType, setFormType] = React.useState(null);

	return (
		<div className={classes.container}>
			<div className={classes.section}>
				<div className={classes.header}>
					<div className={classes.headerLeft}>
						<span>{primary ? humanise(data.contact.firstName, data.contact.lastName) : 'Participant ' + keyIndex}</span>
						<img className={classes.actionIcon} src={IMAGES.ICONS.bin} onClick={() => setFormType(EXPERIENCE_STATUS.CANCELLED)} />
					</div>
					<div className={classes.headerRight}>
						{primary && product?.availability?.available > 0 && <div className={classes.actionButton}
							onClick={() => props.openAddFormHandler({
								date: product.date,
								productID: product.product._id,
								slotID: product.slotID,
								gradeID: product.grade._id,
								availabilityID: product.availability._id,
								available: product.availability.available,
								title: product.product.title,
								price: { ...product.price }
							}, data._id)}>+ Add person</div>}

						<div className={classes.actionButton} onClick={() => setFormType(EXPERIENCE_STATUS.NOSHOW)} >Mark as no show</div>
					</div>
				</div>

				<ConfirmForm
					open={formType !== null}
					setOpen={setFormType}
					onClose={() => setFormType(null)}
					onConfirm={() => {
						updateProductStatusHandler(data._id, product._id, formType);
						setFormType(null);
					}}
				>
					{formType === EXPERIENCE_STATUS.CANCELLED && 'Are you sure you would like to delete this participant?'}
					{formType === EXPERIENCE_STATUS.NOSHOW && 'Are you sure you would like to mark as no show this participant?'}
				</ConfirmForm>
			</div>


			<ProductCard
				data={product}
				classes={classes}
				timezone={timezone}
				products={props.productInfo}
				handleSubmit={(value) => props.updateProductRateHandler(data._id, product._id, value)}
			/>

		</div>
	)
};

const ReservationExp = (props) => {
	const classes = useStyles();
	const { bookedExperiences = [] } = props;

	return (
		<React.Fragment>
			{bookedExperiences.map((experience, index) => (
				experience.products.filter(product => product.status === EXPERIENCE_STATUS.CONFIRMED).map((product, pIndex) => (
					<ExperienceCard
						{...props}
						key={pIndex}
						primary={pIndex === 0}
						keyIndex={pIndex + 1}
						classes={classes}
						data={experience}
						product={product}
						openAddFormHandler={props.openAddFormHandler}
						updateProductStatusHandler={props.updateProductStatusHandler}
					/>
				))
			))}
		</React.Fragment>
	)
}

export default ReservationExp;