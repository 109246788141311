import { combineReducers } from "redux";
import dashboardReducer from "./dashboard/dashboard";
import liveReducer from "./dashboard/live";
import notificationsReducer from "./notifications/notifications";
import messagesReducer from "./messages/messages";
import uiConfigReducer from "./listing/uiConfig";
import authReducer from "./auth/auth";
import spacesReducer from "./dashboard/spaces";
import propertyReducer from "./dashboard/property";
import cashDrawersReducer from "./cash-drawers/cashDrawers";
import masterwayReducer from "./masterway/masterway";
import cashCategoriesReducer from "./cash-drawers/cashCategories";
import cashAccountsReducer from "./cash-drawers/cashAccounts";
import upsellReducer from "./upsell/upsell";
import announcementsReducer from "./announcements/announcements";
import votesReducer from "./votes/votes";
import foldersReducer from "./files/folders";
import filesReducer from "./files/files";
import todoCategoriesReducer from "./todos/todoCategories";
import todosReducer from "./todos/todos";
import commentsReducer from "./comments/comments";
import chatReducer from "./dashboard/chat";
import calendarSnapshotReducer from "./beds/calendarSnapshot";
import roomTypesReducer from "./rooms/roomTypes";
import roomsReducer from "./rooms/rooms";
import buildingReducer from "./rooms/buildings";
import mappingReducer from "./mapping/roomMapping";
import taxesReducer from "./taxes/taxes";
import transactionModesReducer from "./transactions/transactionMode";
import ratesReducer from "./rates/rates";
import ratePlansReducer from "./rates/ratePlans";
import bedsReducer from "./beds/beds";
import reservationsReducer from "./beds/reservations";
import reservationDetailsReducer from "./beds/reservationDetails";
import searchReducer from "./search/search";
import reportsReducer from "./reports/reports";
import discussionReducer from "./community/discussions";
import threadReducer from "./community/thread";
import usersReducer from "./users/users";
import frontdeskUsersReducer from "./users/frontdeskUsers";
import emailTemplatesReducer from "./emails/emailTemplates";
import emailReducer from "./emails/email";
import loadingReducer from "./loading/loading";
import errorsReducer from "./errors/errors";
import successReducer from "./success/success";
import suggestReducer from "./rooms/suggest";
import optionsReducer from "./rooms/options";
import bookingSourceReducer from "./bookingSource/bookingSource";
import channelMappingReducer from "./channelMapping/channelMapping";
import gokiReducer from "./goki/goki";
import permissionsReducer from "./permissions/permissions";
import subscriptionReducer from "./subscriptions/subscriptions";
import experiencesReducer from "./experiences/experiences";
import mailChimpListReducer from "./mailChimp/lists";
import mailChimpRulesReducer from "./mailChimp/rules";
import mailChimpPropertyReducer from "./mailChimp/propertyInfo";
import productReducer from "./pos/products";
import productCategoryReducer from "./pos/productCategory";
import myAllocatorReducer from "./channelMapping/myAllocator";
import qbPaymentReducer from "./quickBooks/payment";
import qbPurchaseReducer from "./quickBooks/purchase";
import zapierKeyReducer from "./zapier/accessToken";
import vatReducer from "./plus/plus";
import paymentReducer from "./payments/payments";
import autoPayReducer from "./payments/autoPay";
import proformaReducer from "./invoice/proforma";
import onlineCheckinReducer from "./onlineCheckin/general";
import userUIsettingReducer from "./UIsettings/userSettings";
import sibaReducer from "./legal/siba";
import odooReducer from "./odoo/odoo";
import hostelworldSettingsReducer from "./inbox/hostelworld";
import hostelworldReviewsReducer from "./inbox/hostelworld-reviews";
import inboxChannelsReducer from "./inbox/channels";
import settingsReducer from "./settings/settings";
import hwMigrationReducer from "./hwMigration/hwMigration";
import propertySettingsReducer from "./settings/propertySettings";
import floorPlanReducer from "./floorPlan/floorPlan";
import linkupsReducer from "./linkups";

const RootReducer = combineReducers({
  dashboard: dashboardReducer,
  live: liveReducer,
  linkups: linkupsReducer,
  notifications: notificationsReducer,
  messages: messagesReducer,
  uiConfig: uiConfigReducer,
  auth: authReducer,
  spaces: spacesReducer,
  property: propertyReducer,
  cashDrawers: cashDrawersReducer,
  masterway: masterwayReducer,
  cashCategories: cashCategoriesReducer,
  cashAccounts: cashAccountsReducer,
  upsell: upsellReducer,
  announcements: announcementsReducer,
  votes: votesReducer,
  folders: foldersReducer,
  files: filesReducer,
  todoCategories: todoCategoriesReducer,
  todos: todosReducer,
  comments: commentsReducer,
  chat: chatReducer,
  calendarSnapshot: calendarSnapshotReducer,
  buildings: buildingReducer,
  roomTypes: roomTypesReducer,
  rooms: roomsReducer,
  beds: bedsReducer,
  mapping: mappingReducer,
  taxes: taxesReducer,
  transactionModes: transactionModesReducer,
  reservations: reservationsReducer,
  reservationDetails: reservationDetailsReducer,
  rates: ratesReducer,
  ratePlans: ratePlansReducer,
  search: searchReducer,
  reports: reportsReducer,
  discussions: discussionReducer,
  thread: threadReducer,
  users: usersReducer,
  frontdeskUsers: frontdeskUsersReducer,
  emailTemplates: emailTemplatesReducer,
  email: emailReducer,
  suggestions: suggestReducer,
  options: optionsReducer,
  bookingSource: bookingSourceReducer,
  channelMapping: channelMappingReducer,
  goki: gokiReducer,
  permissions: permissionsReducer,
  subscriptions: subscriptionReducer,
  products: productReducer,
  productCategories: productCategoryReducer,
  myAllocator: myAllocatorReducer,
  experiences: experiencesReducer,
  legal: combineReducers({
    siba: sibaReducer,
  }),
  success: successReducer,
  errors: errorsReducer,
  mailChimp: combineReducers({
    lists: mailChimpListReducer,
    rules: mailChimpRulesReducer,
    propertyInfo: mailChimpPropertyReducer,
  }),
  quickBooks: combineReducers({
    payment: qbPaymentReducer,
    purchase: qbPurchaseReducer,
  }),
  odoo: odooReducer,
  zapier: zapierKeyReducer,
  vat: vatReducer,
  stripe: paymentReducer,
  payRule: autoPayReducer,
  invoiceProforma: proformaReducer,
  onlineCheckin: onlineCheckinReducer,
  userUIsettings: userUIsettingReducer,
  loading: loadingReducer,
  success: successReducer,
  errors: errorsReducer,
  settings: settingsReducer,
  hwMigration: hwMigrationReducer,
  propertySettings: propertySettingsReducer,
  inbox: combineReducers({
    channels: inboxChannelsReducer,
    hostelworld: combineReducers({
      settings: hostelworldSettingsReducer,
      reviews: hostelworldReviewsReducer,
    }),
  }),
  floorPlan: floorPlanReducer,
  // filters: filtersReducer,
  // locations: locationsReducer,
  // spaceListings: spaceListingsReducer,
  // spaceListing: spaceListingReducer,
  // experts: expertsReducer,
});

export default RootReducer;
