import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px',
        width: '50%',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
    },
    label: {
        fontWeight: 600,
        fontSize: '1.2rem',
        width: '100%',
    },
    radioBtnContainer:{
        marginBottom: '20px'
    },

    selectFormControl: {
        width: '30%',
        boxSizing: 'border-box',
        marginBottom: '20px',
        marginTop: '10px',
        '& .errorMessage':{
            marginLeft: '0px'
        },
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
}));

const hostTypeList = [
    'host', 
    'guide', 
    'instructor',
    'coach', 
    'driver', 
    'pilot'
];

const hostCategoryList = [
    { value: 'STAFF', label: 'Hostel staff member' }, 
    { value: 'EXTERNAL', label: 'External person' }, 
    { value: '3RDPARTY', label: 'Third party provider' }
];

const defaultHostInfo = {
    conductorName: '',
    conductorType: '',
    conductorCategory: 'STAFF'
}

const formHelper = {
    conductorNameError: false, 
    conductorTypeError: false
}

const AcitivityHostDetails = props =>{
    const classes = useStyles();

    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo,
        submitDraftHandler,
        productData 
    } = props;

    const [ hostInfo, setHostInfo ] = useState({ ...defaultHostInfo });
    const [errors, setError] = useState({ ...formHelper });

    useEffect(() =>{
        if(productData.conductorName){

            const initData = {
                conductorName: productData.conductorName,
                conductorType: productData.conductorType,
                conductorCategory: productData.conductorCategory
            }
            
            setHostInfo({ ...initData });
        }
    }, [productData.conductorName]);

    const onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        setHostInfo({ ...hostInfo, [name]: value });
        setError({ ...errors, [`${name}Error`]: !value });
    }

    const validateHostDetails = () =>{
        const { conductorName, conductorType } = hostInfo;
        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        if(!conductorName || !conductorName.trim()){
            formInputStatus.conductorNameError = true;
            validationSuccess = false;
        }

        if(!conductorType){
            formInputStatus.conductorTypeError = true;
            validationSuccess = false;
        }

        setError({ ...formInputStatus });
        return validationSuccess;
    }

    const submitHandler = () =>{
        if(!validateHostDetails()) return;
        const data = { ...hostInfo };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Name the person who will host this experience</div>

                <FormControl component="fieldset" className={classes.radioBtnContainer} >
                    <RadioGroup 
                        aria-label="type" 
                        name="conductorCategory" 
                        value={hostInfo.conductorCategory}
                        onChange={onChangeHandler}
                    >
                        {hostCategoryList.map(category =>(
                            <FormControlLabel 
                                key={`hostCategory_${category.value}`}
                                value={category.value}
                                control={<Radio color="primary" />} 
                                label={category.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                <div className={classes.label}>Type</div>
                <FormControl 
                    variant="outlined" 
                    className={classes.selectFormControl}
                >
                    <Select
                        native
                        value={hostInfo.conductorType}
                        onChange={onChangeHandler}
                        className={classes.selectInput}
                        inputProps={{
                            name: 'conductorType'
                        }}
                        error={errors.conductorTypeError}
                    >
                        <option aria-label="None" value="" />
                        {hostTypeList.map(type =>(
                            <option value={type} key={`hostType_${type}`}>
                                {type}
                            </option>
                        ))}
                    </Select>
                    {errors.conductorTypeError && (
                        <FormHelperText className="errorMessage">
                            Host type is required
                        </FormHelperText>
                    )}
                </FormControl>

                <div className={classes.label}>Name</div>
                <div className={classes.inputField}>
                    <InputBase 
                        id="conductorName"
                        name="conductorName"
                        className={classes.textInput}
                        fullWidth
                        type="text"
                        value={hostInfo.conductorName}
                        onChange={onChangeHandler}
                        error={errors.conductorNameError}
                    />
                </div>
                {errors.conductorNameError && (
                    <FormHelperText className="errorMessage">
                        Host name is required
                    </FormHelperText>
                )}

            </div>

            <Footer 
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
            />
        </>
    )
} 

export default AcitivityHostDetails;