import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { toTitleCase } from "../utils/wordUtils";
import {
  fetchDateFormat,
  DATE_FORMATS,
  getAmtFormat,
  fetchNoOfNights,
} from "../../utils/utility";
import LaunchIcon from "@material-ui/icons/Launch";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Button, Typography } from "@material-ui/core";
import { RESERVATION_STATUS } from "../../utils/constants";
import { WORKSPACE_URL } from "../../config";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    minWidth: 450,
    borderRadius: 25,
    padding: 0,
    zIndex: 1000,
    "& .roomBlockTooltipContainer": {
      padding: 20,
    },
    "& .tooltipContainer": {},
    "& .tooltipHeader": {
      padding: 25,
      backgroundColor: "#f8f9fb",
      borderRadius: "25px 25px 0 0",
      display: "flex",
      "& > div": {
        width: "50%",
      },
      "& .imageContainer": {
        width: 40,
      },
      "& .titleContainer .title": {
        fontSize: "1.3rem",
        fontWeight: 600,
      },
      "& .rightCol": {
        textAlign: "right",
        "& .button": {
          border: "1px solid #d8d8d8",
          padding: "8px 15px",
          fontSize: "1.2rem",
          background: "#ffffff",
          fontWeight: 600,
          borderRadius: 25,
        },
      },
      "& .leftCol": {
        display: "flex",
        "& img": {
          width: 30,
        },
      },
    },
    "& .tooltipContent": {
      padding: 25,
      display: "flex",
      "& .col": {
        width: "50%",
        "&:first-child": {
          borderRight: "1px solid #f2f2f2",
          marginRight: 25,
        },
      },
    },
    "& .notesContent": {
      padding: "0 25px",
      "& .borderLine": {
        borderTop: "1px solid #f2f2f2",
        paddingBottom: 25,
      },
      "& .note": {
        color: "#c2c2c2",
      },
    },
    "& .rowCol": {
      paddingBottom: 25,
    },
    "& .label": {
      color: "#808080",
      paddingBottom: 5,
      fontWeight: 600,
    },
    "& .value": {
      color: "#393939",
      fontWeight: 600,
    },
    "& .error": {
      color: "#c8706d",
    },
    "& .footer": {
      padding: "0 25px 25px",
      display: "flex",
      fontSize: "1.3rem",
      fontWeight: 600,
      color: "#828282",
      "& > div": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      },
      "& svg": {
        fontSize: 14,
        margin: "0px 15px 0px 5px",
      },
    },
  },
}))(Tooltip);

const reservationToolTipContent = ({
  guestName,
  checkIn,
  checkOut,
  totalAmount,
  balance,
  status,
  timeOfArrival,
  guestCount,
  flag,
  resID,
  reservationNumber,
  latestGuestComment,
  latestNote,
  currency,
  viewReservationHandler,
  bookingSource,
  code,
  currentProperty,
  nextStep,
  updateStatusHandler,
  closeTooltipHandler,
  t,
}) => (
  <div className="tooltipContainer">
    <div className="tooltipHeader">
      <div className="leftCol">
        {flag && (
          <div className="imageContainer">
            <img src={flag} />
          </div>
        )}
        <div className="titleContainer">
          <div className="title">{toTitleCase(guestName)}</div>
          <div className="label">{status}</div>
        </div>
      </div>
      <div className="rightCol">
        {nextStep && (
          <Button
            className="button"
            onClick={() => updateStatusHandler(resID, nextStep)}
          >
            {nextStep == RESERVATION_STATUS.IN_HOUSE
              ? t("existingReservation.tooltip.checkIn")
              : nextStep == RESERVATION_STATUS.CHECKED_OUT
              ? t("existingReservation.tooltip.checkOut")
              : `${nextStep} Guest`}
          </Button>
        )}
      </div>
    </div>

    <div className="tooltipContent">
      <div className="col">
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.reservationNumber")}
          </div>
          <div className="value">{reservationNumber}</div>
        </div>
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.checkInOut")}
          </div>
          <div className="value">
            {fetchDateFormat(checkIn, DATE_FORMATS.LONG_DATE)} -{" "}
            {fetchDateFormat(checkOut, DATE_FORMATS.LONG_DATE)}
          </div>
        </div>
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.numberOfGuest")}
          </div>
          <div className="value">{guestCount || "-"}</div>
        </div>
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.totalAmount")}
          </div>
          <div className="value">{getAmtFormat(totalAmount, currency)}</div>
        </div>
      </div>
      <div className="col">
        {bookingSource && (
          <div className="rowCol">
            <div className="label">
              {t("existingReservation.tooltip.bookingSource")}
            </div>
            <div className="value">{bookingSource}</div>
          </div>
        )}
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.numberOfNights")}
          </div>
          <div className="value">{fetchNoOfNights(checkIn, checkOut)}</div>
        </div>
        <div className="rowCol">
          <div className="label">
            {t("existingReservation.tooltip.arrivalTime")}
          </div>
          <div className="value">{timeOfArrival || "-"}</div>
        </div>
        <div className="rowCol">
          <div className="label error">
            {t("existingReservation.tooltip.balance")}
          </div>
          <div className="value error">{getAmtFormat(balance, currency)}</div>
        </div>
      </div>
    </div>

    <div className="notesContent">
      <div className="borderLine"></div>
      {(latestNote || latestGuestComment) && (
        <>
          {latestNote && (
            <div className="rowCol">
              <div className="label">
                {t("existingReservation.tooltip.latestNote")}
              </div>
              <div className="note">{latestNote || "-"}</div>
            </div>
          )}
          {latestGuestComment && (
            <div className="rowCol">
              <div className="label">
                {t("existingReservation.tooltip.lastMessage")}
              </div>
              <div className="note">{latestGuestComment || "-"}</div>
            </div>
          )}
        </>
      )}
    </div>

    <div className="footer">
      {/* ------------------GO TO RESERVATION------------------------ */}
      <div
        onClick={() => {
          closeTooltipHandler();
          viewReservationHandler(resID, status);
        }}
      >
        {t("existingReservation.tooltip.goToReservation")}{" "}
        <ArrowForwardIosIcon />
      </div>

      {/* ------------------OPEN RESERVATION IN NEW TAB------------------------ */}
      <div
        onClick={() => {
          closeTooltipHandler();
          window.open(
            `${WORKSPACE_URL}/${currentProperty.code}/reservation/${code}`,
            "_blank"
          );
        }}
      >
        {t("existingReservation.tooltip.openReservationInNewTab")}{" "}
        <LaunchIcon />
      </div>
    </div>
  </div>
);

const fetchToolTipData = (props) => {
  const { guestName, checkIn, checkOut, status, t } = props;
  return status === RESERVATION_STATUS.BLOCK ? (
    <div className="roomBlockTooltipContainer">
      <Typography color="inherit" style={{ fontWeight: 600 }}>
        {toTitleCase(guestName)}
      </Typography>
      {t("existingReservation.hoverInfo.checkIn")}:{" "}
      {fetchDateFormat(checkIn, DATE_FORMATS.SHORT_DATE)}
      <br />
      {t("existingReservation.hoverInfo.checkOut")}:{" "}
      {fetchDateFormat(checkOut, DATE_FORMATS.SHORT_DATE)}
      <br />
      {t("existingReservation.hoverInfo.status")}: {status}
      <br />
    </div>
  ) : (
    reservationToolTipContent(props)
  );
};

const ReservationDetailsTooltip = (props) => {
  const {
    children,
    customProps,
    isDragging = false,
    ...componentProps
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const closeTooltipHandler = () => {
    setIsOpen(false);
  };

  return (
    <HtmlTooltip
      {...componentProps}
      open={!isDragging && isOpen}
      enterDelay={1000} // added a delay to allow user to drag and drop [small delay will restrict user as the tooltip will cover the block]
      enterNextDelay={!isOpen ? 1000 : 0}
      interactive={!isOpen ? false : true}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      title={
        customProps
          ? fetchToolTipData({
              ...customProps,
              closeTooltipHandler,
            })
          : ""
      }
    >
      {children}
    </HtmlTooltip>
  );
};
export default ReservationDetailsTooltip;
