import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { Button, Grid, Typography, Chip, CircularProgress } from "@material-ui/core";
import ExtendIcon from '@material-ui/icons/ArrowForward'
import { DATA_ACTION, inventoryConditionHandler } from "../../utils/helper";
import moment from "moment";

import { IMAGES } from "../../constants";

const useStyles = makeStyles((theme) => ({
  picContainer: {
    alignItems: "center",
    background: "#D0D0D0",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  roomTypePic: {
    height: 150,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
  },
  defaultImage: {
    transform: "scale(0.6)",
  },
  nameContainer: {
    marginBottom: 10,
  },
  nameText: {
    marginRight: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
      wordWrap: "break-word",
    },
  },
  settingsIcon: {
    borderRadius: "50%",
    cursor: "pointer",
    display: "inline",
    height: 15,
    padding: theme.spacing(1),
    width: 15,
    "& img": {
      height: 15,
      opacity: 0.5,
      width: 15,
    },
    "&:hover": {
      background: "#F8F9FB",
    },
  },
  extendAvailability: {
    borderRadius: "15px",
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: "solid",
    padding: "5px 15px",
    fontSize: "14px",
    display: "inline-flex",
    alignItems: "center",
    height: "24px",
    marginTop: "10px",
    cursor: "pointer",
  },
  chip: {
    background: "#E0E0E0",
    border: "none",
    color: "#666666",
  },
}));



const ExtendAvailabilityButton = ({isLoading, ...props}) => <Button {...props}>
   <p style={{margin: "0 10px"}}>Extend Availability</p> {isLoading ? <CircularProgress size={20 } /> : <ExtendIcon />}
</Button>

const RoomTile = (props) => {
  const classes = useStyles();
  const { room, roomType, handleEdit, isLoading, handleExtendAvailability } = props;
  const image = IMAGES.ICONS.posPlaceholder;
  let roomImage =
    room && room.images && room.images.length
      ? room.images[room.images.length - 1].key || image
      : image;

  const roomActivePeriods = room?.mapping.filter((activePeriod) => {
    const isEndDateAfterToday = moment().isBefore(moment(activePeriod.endDate));
    return activePeriod.isActive && isEndDateAfterToday;
  });

  const hasActivePeriods = roomActivePeriods && roomActivePeriods.length > 0;

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes.roomTypeName}
    >
      <Grid item xl={3} md={4}>
        <div className={classes.picContainer}>
          <img
            className={clsx({
              [classes.roomTypePic]: true,
              [classes.defaultImage]: roomImage == image,
            })}
            src={roomImage}
          />
        </div>
      </Grid>

      {/* Room */}
      {/* ------------------------------------------------ */}
      <Grid item xl={9} md={8}>
        <div className={classes.nameContainer}>
          <Typography variant="h4" className={classes.nameText}>
            {room?.name}
            <span className={classes.settingsIcon} onClick={handleEdit}>
              <img src={IMAGES.ICONS.settings} />
            </span>
            {!hasActivePeriods && (
                <ExtendAvailabilityButton
                  isLoading={isLoading}
                  onClick={handleExtendAvailability}
                  variant="outlined"
                  label="Extend Availability"
                />
            )}
          </Typography>
        </div>

        <Chip
          variant="outlined"
          size="small"
          label={inventoryConditionHandler(roomType, DATA_ACTION.NAME_STATUS)}
          className={classes.chip}
        />
      </Grid>
    </Grid>
  );
};

export default RoomTile;
