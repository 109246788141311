import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import EditIcon from "@material-ui/icons/Edit";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { updateAutoPayInfo } from "../../redux/actions/payments/autoPay";
import { resetError } from "../../redux/actions/reservation/editReservation";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    minHeight: "calc(100vh - 250px)",
  },
  row: {
    marginBottom: 30,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  select: {
    height: 35,
    paddingLeft: 20,
    width: "calc(100% - 20px)",
  },
  preAuth: {
    marginTop: 70,
  },
  options: {
    alignItems: "center",
    display: "flex",
    marginBottom: 15,
  },
  tag: {
    fontSize: "1.2rem",
    marginLeft: 20,
    width: "30%",
  },
  field: {
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginLeft: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "20%",
  },
  fieldDisabled: {
    background: "#F2F2F2",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginLeft: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "20%",
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 5,
    width: 20,
    height: 20,
  },
  checkedIcon: {
    backgroundColor: "#4F9581",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      borderRadius: 5,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#4F9581",
    },
  },
  unit: {
    fontSize: "1.2rem",
    fontWeight: 600,
    marginLeft: 15,
  },
  note: {
    color: "#999999",
    fontSize: "1.2rem",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  editIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    marginLeft: "10px",
    "&:hover": {
      background: "#dddddd",
    },
  },
  paymentStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 300,
    fontSize: "1.3rem",
    "& .warning": {
      color: "#ffae42",
    },
    "& .success": {
      color: "#28a745",
    },
  },
  paymentStatusTitle: {
    fontSize: "1.3rem",
    fontWeight: 600,
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    minWidth: "180px",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const reportFrequency = [
  { label: "Everyday", value: "daily" },
  { label: "Weekdays", value: "weekday" },
  { label: "Every monday", value: "monday" },
  { label: "App notifications only", value: "appNotifOnly" },
];

const Payments = (props) => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const {
    open,
    handleClose,
    currentSpace,
    actionRequired,
    fetchStripeAccountLink,
    payRule,
    t,
    errors,
  } = props;
  const [toggle, setToggle] = React.useState(null);
  const [form, setForm] = React.useState({
    auto_NRR: false,
    auto_noShow: false,
    sendFailReport: false,
    reportFreq: null,
  });
  const [preAuth, setPreAuth] = React.useState({
    amount: -1,
    pax: -1,
    duration: -1,
  });
  const [preAuthStatus, setPreAuthStatus] = React.useState({
    amount: false,
    pax: false,
    duration: false,
  });
  const [selectedFreq, setFreq] = React.useState(null);
  const [message, setMessage] = React.useState("");

  if (toggle === null && payRule._id) {
    setToggle(true);
    setForm({
      ...form,
      auto_NRR: payRule.auto_NRR,
      auto_noShow: payRule.auto_noShow,
      sendFailReport: payRule.sendFailReport,
      reportFreq: payRule.sendFailReport ? payRule.reportFreq : null,
    });

    setPreAuthStatus({
      amount: payRule.preAuth.amount === -1 ? false : true,
      pax: payRule.preAuth.pax === -1 ? false : true,
      duration: payRule.preAuth.duration === -1 ? false : true,
    });

    setPreAuth({ ...payRule.preAuth });
    if (payRule.sendFailReport && payRule.reportFreq) {
      for (var i = 0; i < reportFrequency.length; i++) {
        if (reportFrequency[i].value === payRule.reportFreq) {
          setFreq({
            ...selectedFreq,
            value: reportFrequency[i].value,
            label: reportFrequency[i].label,
          });
        }
      }
    }
  }

  const handleToggle = (type, value) => {
    if (type === "sendFailReport") {
      setFreq({
        ...selectedFreq,
        value: reportFrequency[0].value,
        label: reportFrequency[0].label,
      });
      setForm({ ...form, [type]: value, reportFreq: reportFrequency[0].value });
    } else {
      setForm({ ...form, [type]: value });
    }
  };

  const handleFreqSelection = (e) => {
    setFreq({ ...selectedFreq, value: e.value, label: e.label });
    setForm({ ...form, reportFreq: e.value });
  };

  const handlePreAuth = (e) => {
    setPreAuth({ ...preAuth, [e.target.name]: e.target.value });
  };

  const handlePreAuthStatus = (type) => {
    setPreAuthStatus({ ...preAuthStatus, [type]: !preAuthStatus[type] });
  };

  const handleSave = () => {
    const data = {
      auto_NRR: form.auto_NRR,
      auto_noShow: form.auto_noShow,
      sendFailReport: form.sendFailReport,
    };

    if (form.sendFailReport) {
      data.reportFreq = form.reportFreq;
    }

    let newPreAuth = {
      amount: preAuthStatus.amount ? preAuth.amount : -1,
      pax: preAuthStatus.pax ? preAuth.pax : -1,
      duration: preAuthStatus.duration ? preAuth.duration : -1,
    };

    data.preAuth = newPreAuth;

    //console.log(data)
    props
      .dispatch(updateAutoPayInfo(currentSpace.propertyID, data))
      .then((response) => {
        if (response.status === 200) {
          setOpenSnackbar(true);
          setMessage("Your Payment Settings have been saved!");
        } else {
          setOpenSnackbar(true);
          setMessage("Error in saving the settings.");
        }
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setMessage(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Automated Payments</div>
          <AccessHeaderAction moduleID={MODULE.PAYMENTS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            <div className={clsx(classes.row, classes.firstRow)}>
              <div>
                <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
              </div>
              <div className={classes.helperText}>
                {t("automate.payments.helpText")}
              </div>
            </div>

            <div className={classes.row}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <div className={classes.paymentStatusTitle}>
                    {t("automate.payments.accountStatus")}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.paymentStatus}>
                    {!actionRequired && (
                      <div className="success">
                        {t("automate.payments.good")}
                      </div>
                    )}
                    {actionRequired && (
                      <div className="warning">
                        {t("automate.payments.requireAttn")}
                      </div>
                    )}
                    <EditIcon
                      className={classes.editIcon}
                      onClick={fetchStripeAccountLink}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.row}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <div className={classes.title}>
                    {t("automate.payments.nrr")}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.toggle}>
                    <div
                      className={clsx(classes.toggleButton, classes.left)}
                      style={{
                        background:
                          form.auto_NRR === false ? "#F2F2F2" : "#FFFFFF",
                      }}
                      onClick={() => handleToggle("auto_NRR", false)}
                    >
                      OFF
                    </div>
                    <div
                      className={clsx({
                        [classes.toggleButton]: true,
                        [classes.right]: true,
                        [classes.selected]: form.auto_NRR === true,
                      })}
                      onClick={() => handleToggle("auto_NRR", true)}
                    >
                      ON
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.row}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <div className={classes.title}>
                    {t("automate.payments.noShow")}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.toggle}>
                    <div
                      className={clsx(classes.toggleButton, classes.left)}
                      style={{
                        background:
                          form.auto_noShow === false ? "#F2F2F2" : "#FFFFFF",
                      }}
                      onClick={() => handleToggle("auto_noShow", false)}
                    >
                      OFF
                    </div>
                    <div
                      className={clsx({
                        [classes.toggleButton]: true,
                        [classes.right]: true,
                        [classes.selected]: form.auto_noShow === true,
                      })}
                      onClick={() => handleToggle("auto_noShow", true)}
                    >
                      ON
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.row}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <div className={classes.title}>
                    {t("automate.payments.failureReports")}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.toggle}>
                    <div
                      className={clsx(classes.toggleButton, classes.left)}
                      style={{
                        background:
                          form.sendFailReport === false ? "#F2F2F2" : "#FFFFFF",
                      }}
                      onClick={() => handleToggle("sendFailReport", false)}
                    >
                      OFF
                    </div>
                    <div
                      className={clsx({
                        [classes.toggleButton]: true,
                        [classes.right]: true,
                        [classes.selected]: form.sendFailReport === true,
                      })}
                      onClick={() => handleToggle("sendFailReport", true)}
                    >
                      ON
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            {form.sendFailReport && (
              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}></Grid>
                  <Grid item xs={5}>
                    <div className={clsx(classes.toggle, classes.select)}>
                      <Select
                        value={selectedFreq}
                        styles={selectStyle}
                        options={reportFrequency}
                        onChange={(e) => handleFreqSelection(e)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/*
              <div className={clsx(classes.row, classes.preAuth)}>
                <div className={classes.title}>
                  Pre-authorize credit cards when a new reservation meets any of the following criteria
              </div>
              </div>

              <div className={clsx(classes.row, classes.options)}>
                <Checkbox
                  color="primary"
                  checked={preAuthStatus.amount}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e)=>handlePreAuthStatus('amount')}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div className={classes.tag}>Amounts greater than</div>
                <div className={clsx({ [classes.field]: preAuthStatus.amount === true, [classes.fieldDisabled]: preAuthStatus.amount === false })}>
                  <InputBase
                    id="amount"
                    name="amount"
                    value={(preAuth.amount === -1) ? '' : preAuth.amount}
                    disabled={!preAuthStatus.amount}
                    fullWidth
                    type="tel"
                    onChange={(e) => handlePreAuth(e)}
                  />
                </div>
                <div className={classes.unit}>{currentSpace.currency}</div>
              </div>

              <div className={clsx(classes.row, classes.options)}>
                <Checkbox
                  color="primary"
                  checked={preAuthStatus.pax}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  onChange={(e)=>handlePreAuthStatus('pax')}
                  icon={<span className={classes.icon} />}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div className={classes.tag}>Groups larger than</div>
                <div className={clsx({ [classes.field]: preAuthStatus.pax === true, [classes.fieldDisabled]: preAuthStatus.pax === false })}>
                  <InputBase
                    id="pax"
                    name="pax"
                    value={(preAuth.pax === -1) ? '' : preAuth.pax}
                    disabled={!preAuthStatus.pax}
                    fullWidth
                    type="tel"
                    onChange={(e) => handlePreAuth(e)}
                  />
                </div>
                <div className={classes.unit}>persons</div>
              </div>

              <div className={clsx(classes.row, classes.options)}>
                <Checkbox
                  color="primary"
                  checked={preAuthStatus.duration}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  onChange={(e)=>handlePreAuthStatus('duration')}
                  icon={<span className={classes.icon} />}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div className={classes.tag}>Stays longer than</div>
                <div className={clsx({ [classes.field]: preAuthStatus.duration === true, [classes.fieldDisabled]: preAuthStatus.duration === false })}>
                  <InputBase
                    id="duration"
                    name="duration"
                    value={(preAuth.duration === -1) ? '' : preAuth.duration}
                    disabled={!preAuthStatus.duration}
                    fullWidth
                    type="tel"
                    onChange={(e) => handlePreAuth(e)}
                  />
                </div>
                <div className={classes.unit}>nights</div>
              </div>

              <div className={classes.note}>
                Note that pre-authorizations enabled above will be applied 7 days before check-in.
              </div>
            */}

            <div className={clsx(classes.row, classes.buttonsContainer)}>
              <div className={classes.button} onClick={handleSave}>
                {t("actions.save")}
              </div>
            </div>
          </div>
        </div>

        {openSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            autoHideDuration={3000}
            open={true}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  errors: state.errors,
  payRule: state.payRule,
});

export default withTranslation()(connect(mapStateToProps)(Payments));
