import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

import { editSpace } from '../../redux/actions/dashboard/space';
import { uploadPropertyPhoto } from '../../redux/actions/dashboard/property';

const useStyles = makeStyles(theme => ({
    root: {
        '&.edit $container': {
            borderBottom: 'unset'
        }
    },
    container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        width: '100%',
        borderBottom: '1px solid #eeeeee'
    },
    errorContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        width: '100%'
    },
    leftAlign: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '20%'
    },
    rightAlign: {
        paddingLeft: 30,
        width: '70%'
    },
    name: {
        cursor: "pointer",
        fontSize: '1.6rem',
        fontWeight: 600,
        lineHeight: 1.5
    },
    categoryAlert: {
        color: '#999999',
        fontSize: '1.2rem',
        marginTop: 5,
    },
    textField: {
        '& input': {
            fontSize: '1.3rem'
        }
    },
    address: {
        marginBottom: 10
    },
    addressState: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between'
    },
    addressStateItem: {
        width: '45%'
    },
    field: {
        cursor: 'pointer'
    },
    addressLine: {
        marginBottom: 5
    },
    moreIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        fontSize: '1.8rem',
        padding: theme.spacing(1),
        '&:hover': {
            background: '#dddddd',
        },
    },
    cancelButton: {
        marginRight: 10
    },
    submit: {
        display: 'flex',
        justifyContent: "flex-end",
        marginTop: theme.spacing(3)
    },
    notice: {
        color: '#999999',
        fontSize: '1.2rem',
        marginBottom: 10,
        marginTop: 10,
    },
    iconRow: {
        alignItems: 'flex-start',
        display: 'flex',
        marginBottom: 25
    },
    picContainer: {
        alignItems: 'center',
        // cursor: 'pointer',
        display: 'flex',
    },
    placeholder: {
        background: '#ffffff',
        borderRadius: '15px',
        height: 80,
        marginRight: 15,
        width: 80,
    },
    input: {
        display: "none"
    },
    label: {
        fontSize: '1.3rem',
        color: '#666666',
        marginBottom: 10
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: 0,
    },
    image: {
        borderRadius: 15,
        objectFit: 'cover',
        height: 80,
        width: 80,
    },
    loadingState: {
        alignItems: 'center',
        display: 'flex',
        height: 300,
        justifyContent: 'center',
        width: '90%',
        position: 'absolute'
    },
    errorMessage: {
        color: '#f44336',
        fontSize: '1.2rem'
    }
}));

const FORM_SPACE_DATA = {
    name: '',
    email: '',
    phone: '',
    lat: '',
    long: '',
    dateFormat: 'DD/MM/YYYY',
}

const FORM_PROPERTY_DATA = {
    description: '',
    taxName: '',
    taxID: '',
    address: {
        lineOne: '',
        lineTwo: '',
        city: '',
        state: '',
        zipCode: '',
    }
}

const INIT_FORM_DATA = {
    ...FORM_SPACE_DATA,
    ...FORM_PROPERTY_DATA
}

const processFieldsInfo = (data, fields) => {
    let fieldsData = {};

    fields.forEach(field => {
        const fieldData = data?.[field] ? data[field].trim() : ''
        fieldsData[field] = fieldData;
    });

    return fieldsData;
}

const GeneralSettings = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentSpace = {}, property, dispatch, isLoading } = props;
    const [formData, setFormData] = useState({ ...INIT_FORM_DATA });
    const [isEdit, setIsEdit] = useState(false);

    const [selectedFile, setSelectedFile] = React.useState();
    const [imgError, setImgError] = React.useState(false);
    const [preview, setPreview] = React.useState(null);
    const [uploaded, setUploaded] = React.useState(false);
    const [removed, setRemoved] = React.useState(false)
    const [latitude, setLatitude] = React.useState(formData.lat)
    const [longitude, setLongitude] = React.useState(formData.long)
    const errorMessage = useSelector(state => state.errors)
    const [error, setError] = React.useState("")

    useEffect(() => {
        setError(errorMessage.EDIT_SPACE)
    }, [errorMessage])

    useEffect(() => {

        if (currentSpace?.profilePic) setPreview(currentSpace.profilePic)

        const formSpaceData = processFieldsInfo(currentSpace, Object.keys(FORM_SPACE_DATA));

        setLatitude(formSpaceData.lat);
+       setLongitude(formSpaceData.long);

        setFormData(prevState => ({
            ...prevState,
            ...formSpaceData
        }));
    }, [currentSpace]);

    useEffect(() => {
        const { address, ...generalFields } = FORM_PROPERTY_DATA;
        const formPropertyData = processFieldsInfo(property, Object.keys(generalFields));
        const formAddrData = processFieldsInfo(property.address, Object.keys(address));

        setFormData(prevState => ({
            ...prevState,
            ...formPropertyData,
            address: { ...formAddrData }
        }));

    }, [property]);

    const handleEdit = () => {
        setError("")
        setIsEdit(true);
    }

    const onChangeHandler = event => {
        const name = event.target.name;
        let value = event.target.value;

        if (event.target.name === 'lat') {
            if (value === '+' || value === '-') {
                setLatitude(value)
                value = 0
            } else {
                if (isNaN(value)) return
                if (Number(value) > 90 || Number(value) < -90) return
                setLatitude(value)
            }
        }
        if (event.target.name === 'long') {
            if (value === '+' || value === '-') {
                setLongitude(value)
                value = 0
            } else {
                if (isNaN(value)) return
                if (Number(value) > 180 || Number(value) < -180) return
                setLongitude(value)
            }
        }
        setFormData((state) => ({ ...state, [name]: value }));
    };

    const onAddrChangeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setFormData((state) => ({
            ...state,
            address: {
                ...state.address,
                [name]: value
            }
        }));
    }

    const handleCancelUpdates = () => {
        setIsEdit(false);
        setUploaded(false)
        setRemoved(false)
    }

    const handleSubmitUpdates = () => {

        const { address, ...patchData } = formData;

        const data = {
            ...patchData,
            address: {
                ...address,
                country: currentSpace.country
            }
        }

        if (!data.email) {
            setError("Kindly input a valid email address, as it is mandatory.")
            return;
        }

        if (data.email) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
                setError("Kindly input a valid email address")
                return;
            }
        }

        dispatch(editSpace(currentSpace._id, data));
        if(uploaded) {
            dispatch(uploadPropertyPhoto(currentSpace._id, 'profilePic', selectedFile));
        }
        handleCancelUpdates();
    }


    useEffect(() => {
        if (!selectedFile) return;

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl)
        if (!uploaded)
            setUploaded(true)

        if (removed)
            setRemoved(false)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const handleCapture = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        if ((e.target.files[0].size / (1024 * 1024)) < 5) {
            setSelectedFile(e.target.files[0])
            setImgError(false)
        } else {
            setImgError(true)
            // console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
        }
    }

    return (
        <div className={clsx(classes.root, { 'edit': isEdit })}>
            <div className={classes.errorMessage}>{error}</div>

            {/* --------------------------------------------------------------------- */}
            {/* PROPERTY NAME */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>

                <div className={classes.leftAlign}>{t('fields.propertyName')}</div>

                <div className={classes.rightAlign}>
                    {!isEdit && <div className={classes.name} onClick={handleEdit}>{formData.name}</div>}
                    {isEdit && (
                        <TextField
                            id="name"
                            name="name"
                            className={classes.name}
                            defaultValue={formData.name}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                            autoFocus
                        />
                    )}
                </div>

                {/* ------------------------------------------------ */}
                {/* EDIT ICON */}
                {/* ------------------------------------------------ */}
                {!isEdit && (
                    <div onClick={handleEdit}>
                        <EditIcon className={classes.moreIcon} />
                    </div>
                )}

            </div>

            {/* --------------------------------------------------------------------- */}
            {/* ADDRESS */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.address')}</div>

                <div className={classes.rightAlign}>

                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {/* -----------------ADDRESS LINE 1------------------ */}
                            {formData.address.lineOne && (
                                <div className={classes.addressLine}>
                                    {formData.address.lineOne}
                                </div>
                            )}

                            {/* -----------------ADDRESS LINE 2------------------ */}
                            {formData.address.lineTwo && (
                                <div className={classes.addressLine}>
                                    {formData.address.lineTwo}
                                </div>
                            )}

                            {/* -----------------CITY | STATE------------------ */}
                            {(formData.address.city || formData.address.state) && (
                                <div className={classes.addressLine}>
                                    {formData.address.city} {formData.address.state}
                                </div>
                            )}

                            {/* -----------------COUNTRY | ZIPCODE------------------ */}
                            <div className={classes.addressLine}>
                                {currentSpace.country} {formData.address.zipCode}
                            </div>
                        </div>
                    )}

                    {isEdit && (
                        <div>
                            <TextField
                                name="lineOne"
                                className={clsx(classes.textField, classes.address)}
                                defaultValue={formData.address.lineOne}
                                placeholder={t('fields.address1')}
                                onChange={onAddrChangeHandler}
                                margin="none"
                                fullWidth
                            />
                            <TextField
                                name="lineTwo"
                                className={clsx(classes.textField, classes.address)}
                                defaultValue={formData.address.lineTwo}
                                placeholder={t('fields.address2')}
                                onChange={onAddrChangeHandler}
                                margin="none"
                                fullWidth
                            />
                            <div className={classes.addressState}>
                                <TextField
                                    name="city"
                                    className={clsx(classes.textField, classes.address, classes.addressStateItem)}
                                    defaultValue={formData.address.city}
                                    placeholder={t('fields.city')}
                                    onChange={onAddrChangeHandler}
                                    margin="none"
                                />
                                <TextField
                                    name="state"
                                    className={clsx(classes.textField, classes.address, classes.addressStateItem)}
                                    defaultValue={formData.address.state}
                                    placeholder={t('fields.state')}
                                    onChange={onAddrChangeHandler}
                                    margin="none"
                                />
                            </div>
                            <div className={classes.addressState}>
                                <TextField
                                    name="zipCode"
                                    className={clsx(classes.textField, classes.address, classes.addressStateItem)}
                                    defaultValue={formData.address.zipCode}
                                    placeholder={t('fields.zipCode')}
                                    onChange={onAddrChangeHandler}
                                    margin="none"
                                />
                                <div className={clsx(classes.textField, classes.address, classes.addressStateItem)}>
                                    {currentSpace.country ? currentSpace.country : t('account.contactSupport')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* DESCRIPTION */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.desc')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.description}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            name="description"
                            className={classes.textField}
                            defaultValue={formData.description}
                            onChange={onChangeHandler}
                            margin="none"
                            multiline
                            rows="3"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* PHONE NUMBER */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.phone')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.phone}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            id="phone"
                            name="phone"
                            className={classes.textField}
                            defaultValue={formData.phone}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* EMAIL */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.email')}<sup style={{color: 'red'}}>*</sup></div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.email}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            id="email"
                            name="email"
                            className={classes.textField}
                            defaultValue={formData.email}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* LEGAL ENTITY */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.legal')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.taxName}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            name="taxName"
                            className={classes.textField}
                            defaultValue={formData.taxName}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* TAX ID */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.taxID')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.taxID}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            name="taxID"
                            className={classes.textField}
                            defaultValue={formData.taxID}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* PROPERTY CODE */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.code')}</div>
                <div className={classes.rightAlign}>
                    {property.code}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* CURRENCY */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.currency')}</div>
                <div onClick={handleEdit} className={classes.rightAlign}>
                    {currentSpace.currency}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* TIME ZONE */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.timezone')}</div>
                <div onClick={handleEdit} className={classes.rightAlign}>
                    {currentSpace.timezone}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* DATE FORMAT */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.dateFormat')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.dateFormat}
                        </div>
                    )}

                    {isEdit && (
                        <>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="dateFormat"
                                    name="dateFormat"
                                    value={formData.dateFormat}
                                    onChange={onChangeHandler}
                                    row
                                >
                                    <FormControlLabel
                                        value="DD/MM/YYYY"
                                        control={<Radio color="primary" />}
                                        label="DD/MM/YYYY"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="MM/DD/YYYY"
                                        control={<Radio color="primary" />}
                                        label="MM/DD/YYYY"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                            {/* -----------SUPPORT MESSAGE----------------- */}
                            <div className={classes.notice}>
                                {t('account.supportMessage')}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* LATITUDE */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.latitude')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.lat}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            id="lat"
                            name="lat"
                            className={classes.textField}
                            value={latitude}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* LONGITUDE */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('fields.longitude')}</div>
                <div className={classes.rightAlign}>
                    {!isEdit && (
                        <div onClick={handleEdit} className={classes.field}>
                            {formData.long}
                        </div>
                    )}
                    {isEdit && (
                        <TextField
                            id="long"
                            name="long"
                            className={classes.textField}
                            value={longitude}
                            onChange={onChangeHandler}
                            margin="none"
                            fullWidth
                        />
                    )}
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* PROPERTY IMAGE (code to be updated)*/}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container}>
                <div className={classes.leftAlign}>{t('account.propertyImage')}</div>
                <div className={classes.rightAlign}>
                    <div className={classes.picContainer}>

                        {/* ----------------IMAGE PREVIEW---------------------- */}
                        {preview && (
                            <div className={classes.placeholder}>
                                <img className={classes.image} src={preview} />
                            </div>
                        )}

                        {/* --------------------LABEL------------------------- */}
                        {isEdit && (
                            <>
                                {!uploaded && (
                                    <label htmlFor="upload-property-image">
                                        <div className={clsx(classes.link, classes.label)}>{t('account.uploadPic')}</div>
                                    </label>
                                )}

                                {uploaded && (
                                    <label htmlFor="upload-another-property-image">
                                        <div className={clsx(classes.link, classes.label)}>{t('account.uploadAnotherPic')}</div>
                                    </label>
                                )}
                            </>
                        )}

                        <input
                            accept="image/*"
                            className={classes.input}
                            id="upload-property-image"
                            type="file"
                            onChange={handleCapture}
                        />

                        <input
                            accept="image/*"
                            className={classes.input}
                            id="upload-another-property-image"
                            type="file"
                            onChange={handleCapture}
                        />
                    </div>
                </div>
            </div>

            {isEdit && imgError && <div className={classes.errorContainer}>
                <div className={classes.leftAlign}></div>
                <div className={classes.rightAlign}>
                    <span className={classes.errorMessage}>Please upload file with size less than 5MB.</span>
                </div>
            </div>}

            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL UPDATES */}
            {/* --------------------------------------------------------------------- */}
            {(isEdit || uploaded || removed) && (
                <div className={classes.submit}>
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={handleCancelUpdates}
                    >
                        {t('actions.cancel')}
                    </Button>

                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.button}
                        onClick={handleSubmitUpdates}
                    >
                        {t('actions.save')}
                    </Button>
                </div>
            )}

            {isLoading && <div className={"loadingOverlay"}>
              <CircularProgress className={"loading"} />
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { spaces, dashboard, property, loading } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        property: property,
        isLoading: loading.EDIT_SPACE || loading.UPLOAD_PROPERTY_PHOTO
    }
}

export default connect(mapStateToProps)(GeneralSettings);
