import moment from 'moment';
import { UNALLOCATED } from '../../../utils/constants';

const calcSum = (array) => {
  let sum = 0;
  array.length && array.map(e => sum+= e.amount);
  return sum;
}

// Process occupancy & revenue for combined chart
// ------------------------------------------------------------------------
const combinedProcessor = (occupancyData, revenueItems, roomTypes, currencyData, submittedDates, level) => {
  let occData = []
  let labels = []
  let revData = []
  let combinedTableData = []
  let bedCategoryData = []
  let totalOccupancy = 0;

  if (occupancyData && occupancyData.currentYear && revenueItems) {
    let difference = level==='daily' ? 'day' : 'month';

    let occArray = occupancyData?.currentYear?.totalOccupancy || [];
    let revArray = revenueItems || [];
    let dateVar = submittedDates.startDate.clone().subtract(1, difference);
    do {
      dateVar = dateVar.add(1, difference);
      occData.push(occArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
                    parseFloat(occArray.find(e => moment(e.date).isSame(dateVar, difference)).occupancy*100).toFixed(2) : 
                      0)
      labels.push(level==='daily' ? dateVar.format('DD MMM') : dateVar.format('MMM YYYY'))
      revData.push(calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference)))));
      combinedTableData.push({
        date: level==='daily' ? dateVar.format('DD MMM, YYYY') : dateVar.format('MMM YYYY'), 
        roomRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && moment(e.date).isSame(dateVar, difference)))),
        upsellRevenue: calcSum(revArray.filter(e => (e.category=="Upsell" && moment(e.date).isSame(dateVar, difference)))),
        experiencesRevenue: calcSum(revArray.filter(e => (e.category=="Experiences" && moment(e.date).isSame(dateVar, difference)))),
        posRevenue: calcSum(revArray.filter(e => (e.category=="POS" && moment(e.date).isSame(dateVar, difference)))),
        totalRevenue: calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference))))
      });

      const bedCategoryOccupancy =  occArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
      parseFloat(occArray.find(e =>moment(e.date).isSame(dateVar, difference)).occupancy*100).toFixed(2) : 0.00;

      totalOccupancy += parseFloat(bedCategoryOccupancy);

      bedCategoryData.push({
        date: level==='daily' ? dateVar.format('DD MMM, YYYY') : dateVar.format('MMM YYYY'), 
        dormsRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && roomTypes?.[e.roomTypeID]?.dormOrPrivate=="dorm" && moment(e.date).isSame(dateVar, difference)))),
        privatesRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && roomTypes?.[e.roomTypeID]?.dormOrPrivate=="private" && moment(e.date).isSame(dateVar, difference)))),
        totalRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && moment(e.date).isSame(dateVar, difference)))),
        // occupancy: occArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
        //           `${parseFloat(occArray.find(e => moment(e.date).isSame(dateVar, difference)).occupancy*100).toFixed(2)}%` : 
        //             '0.00%'
        occupancy: `${bedCategoryOccupancy}%`
      });

      
    } while (!dateVar.isSame(submittedDates.endDate, difference));

    combinedTableData.push({
      roomRevenue: calcSum(revArray.filter(e => e.category=="Beds")),
      upsellRevenue: calcSum(revArray.filter(e => e.category=="Upsell")),
      experiencesRevenue: calcSum(revArray.filter(e => e.category=="Experiences")),
      posRevenue: calcSum(revArray.filter(e => e.category=="POS")),
      totalRevenue: calcSum(revArray),
      totalRow: true
    });

    bedCategoryData.push({
      dormsRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && roomTypes?.[e.roomTypeID]?.dormOrPrivate=="dorm"))),
      privatesRevenue: calcSum(revArray.filter(e => (e.category=="Beds" && roomTypes?.[e.roomTypeID]?.dormOrPrivate=="private"))),
      totalRevenue: calcSum(revArray.filter(e => e.category=="Beds")),
      occupancy: `${(totalOccupancy/ bedCategoryData.length).toFixed(2)}%`,
      totalRow: true
    })

    // console.log('here', bedCategoryData);
  }
  return {combinedGraphData: {occData, revData, labels}, combinedTableData, bedCategoryData};
}

// Process revenue items graph
// ------------------------------------------------------------------------
const revenueItemsGraphProcessor = (revenueItems, submittedDates, level) => {
  
  let labels = []
  let bedsData = [];
  let posData = [];
  let experiencesData = [];
  let addOnsData = [];
  let revArray = revenueItems || [];

  if (revenueItems) {
    let difference = level==='daily' ? 'day' : 'month';

    let dateVar = submittedDates.startDate.clone().subtract(1, difference);
    do {
      dateVar = dateVar.add(1, difference);
      labels.push(level==='daily' ? dateVar.format('DD MMM') : dateVar.format('MMM YYYY'));
      bedsData.push(calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference)) && e.category=='Beds')));
      addOnsData.push(calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference)) && e.category=='Upsell')));
      experiencesData.push(calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference)) && e.category=='Experiences')));
      posData.push(calcSum(revArray.filter(e => (moment(e.date).isSame(dateVar, difference)) && e.category=='POS')));
    } while (!dateVar.isSame(submittedDates.endDate, difference))   

    // console.log(bedsData, addOnsData, experienceData, posData, labels);

  }
  return {bedsData, addOnsData, experiencesData, posData, labels};
}


// Process revenue items table
// ------------------------------------------------------------------------
const revenueItemsProcessor = (revenueItems, roomTypes, upsellItems, experiences, products, currencyData, submittedDates, level) => {
  
  let revenueItemsTable = [];
  let productGraphData = [];

  revenueItems && revenueItems.length > 0 && revenueItems.map(item => {

    // Check that the data point is between start and end date (inclusive)
    if (item.date, moment(item.date).isBetween(submittedDates.startDate, submittedDates.endDate, 'days', '[]')) {
      let product = ""
      if (item.category=="Beds") product=item.roomTypeID ? roomTypes[item.roomTypeID]?.name : UNALLOCATED
      if (item.category=="Upsell") product=upsellItems[item.upsellID]?.name
      if (item.category=="Experiences") product=experiences && experiences[item.productID]?.name
      if (item.category=="POS") product=products[item.productID]?.name;
      revenueItemsTable.push({
        date: level==='daily' ? moment(item.date).format('DD MMM, YYYY') : moment(item.date).format('MMM, YYYY'),
        amount: item.amount,
        count: item.count,
        category: item.category=="Upsell" ? "Add Ons" : item.category,
        product
      })
      const productIndex = productGraphData.findIndex(e => e.product == product);
      if (productIndex > -1) {
        productGraphData[productIndex].amount = productGraphData[productIndex].amount + item.amount;
        productGraphData[productIndex].count = productGraphData[productIndex].count + item.count;
      } else {
        if (productGraphData.length == 15) {
          productGraphData[14] = {
            product: "Other",
            amount: productGraphData[14].amount + item.amount,
            count: productGraphData[14].count + item.count
          }
        } else {
          productGraphData.push({
            product,
            amount: item.amount,
            count: item.count,
          })
        }
      }
      
    }

    productGraphData.sort((a,b) => a.amount < b.amount)

  })
  return {revenueItemsTable, productGraphData}
}


// Process revenue category totals
// ------------------------------------------------------------------------
const categoryProcessor = (revenueItems, submittedDates) => {
  
  let beds = 0;
  let pos = 0;
  let addOns = 0;
  let experiences = 0;

  revenueItems && revenueItems.length > 0 && revenueItems.map(item => {

    // Check that the data point is between start and end date (inclusive)
    if (item.date, moment(item.date).isBetween(submittedDates.startDate, submittedDates.endDate, 'days', '[]')) {
      let product = ""
      if (item.category=="Beds") beds += item.amount
      if (item.category=="Upsell") addOns += item.amount
      if (item.category=="Experiences") experiences += item.amount
      if (item.category=="POS") pos += item.amount
    }
  });

  return [beds, addOns, experiences, pos];
}



// Process revenue category totals
// ------------------------------------------------------------------------
const productProcessor = (revenueItems, submittedDates) => {
  
  let products = [];
  let productAmount = [];
  let productCount = [];
  
  revenueItems && revenueItems.length > 0 && revenueItems.map(item => {

    // Check that the data point is between start and end date (inclusive)
    if (item.date, moment(item.date).isBetween(submittedDates.startDate, submittedDates.endDate, 'days', '[]')) {
      let productName = "";
      if (item.category=="Beds")
      if (item.category=="Beds") beds += item.amount
      if (item.category=="Upsell") addOns += item.amount
      if (item.category=="Experiences") experiences += item.amount
      if (item.category=="POS") pos += item.amount
    }
  });

  return [beds, addOns, experiences, pos];
}



export { combinedProcessor, revenueItemsGraphProcessor, revenueItemsProcessor, categoryProcessor, productProcessor };