import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";

import { MODULE } from "../../../common/constants/permission";
import ModuleHeader from "../ModuleHeader";

import AddButton from "../../common/AddButton";
import ProductListItem from "./ProductListItem";
// import ProductForm from './ProductForm';
// import ActivitySlotsModal from './ActivitySlotsModal';
import ActivityFlow from "../Activity_Flow";
import { deleteProduct } from "../../../redux/actions/experiences/activities";

import IMAGES from "../../../constants/images";

const styles = (theme) => ({
  root: {
    background: "#FFFFFF",
    padding: "0",
    width: "100% ",
  },
  moduleHeader: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    width: "calc(100% - 32px)",
  },
  mainContainer: {
    margin: "0 20px",
    marginTop: "10px",
    padding: "0px 10px",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  productListContainer: {
    padding: "30px 0px",
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  addProductBtn: {
    maxWidth: 140,
  },
  blankState: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    "& img": {
      height: 400,
      objectFit: "cover",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "100%",
      },
    },
  },
  textBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3),
    paddingBottom: 80,
  },
  title: {
    textAlign: "center",
    width: "80%",
    marginBottom: 30,
  },
  subTitle: {
    fontSize: "1.5rem",
    color: "#AAAAAA",
    lineHeight: 1.5,
    marginBottom: 30,
    textAlign: "center",
    width: "80%",
  },
  button: {
    background: "#FFFFFF",
    border: "1px solid #CC6633",
    borderRadius: 5,
    color: "#CC6633",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "center",
    fontWeight: 600,
    padding: "12px 16px",
  },
});

class Activities extends Component {
  state = {
    selectedProductID: null,
    showProductModal: false,
    showGradeSlotsModal: false,
    showRatesModal: false,
    showEditPage: null,
    publishSuccess: false,
  };

  editProductHandler = (productID) => {
    this.setState({
      selectedProductID: productID,
      showProductModal: true,
    });
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  addProductHandler = () => {
    this.setState({ showProductModal: true });
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  showGradeSlotsHandler = (productID) => {
    this.setState({
      selectedProductID: productID,
      showGradeSlotsModal: true,
    });
  };

  editProductVaritions = (productID) => {
    this.setState({
      selectedProductID: productID,
      showProductModal: true,
      showEditPage: {
        mainMenuID: "SETTINGS",
        subMenuID: "variations",
      },
    });
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  deleteProductHandler = (productID) => {
    const { dispatch } = this.props;
    dispatch(deleteProduct(productID));
  };

  closeModalHandler = () => {
    this.setState({
      selectedProductID: null,
      showProductModal: false,
      showGradeSlotsModal: false,
      showRatesModal: false,
      showEditPage: null,
    });
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  publishMessageHandler = (status) => {
    this.setState({ publishSuccess: status });
  };

  render() {
    const { classes, isLoading, productList, t } = this.props;
    const {
      selectedProductID,
      showProductModal,
      showEditPage,
      publishSuccess,
      // showGradeSlotsModal,
      // showRatesModal
    } = this.state;

    return (
      <div className={classes.root}>
        <ModuleHeader
          title={t("experiences.manage.activity.header")}
          moduleID={MODULE.CALENDAR.ID}
          className={classes.moduleHeader}
        />

        {/*----------------PROGRESS LOADER--------------------- */}
        {isLoading && (
          <div className={classes.loadingState}>
            <CircularProgress className={classes.loader} />
          </div>
        )}

        <div className={classes.mainContainer}>
          {Object.keys(productList).length > 1 && (
            <div className={classes.addButtonContainer}>
              <div className={classes.addProductBtn}>
                <AddButton
                  color={"#CC6633"}
                  title={t("experiences.manage.activity.addExperience")}
                  handleClick={this.addProductHandler}
                />
              </div>
            </div>
          )}

          <div className={classes.productListContainer}>
            {Object.keys(productList).map((productID) => (
              <ProductListItem
                key={`product_${productID}`}
                productID={productID}
                editProductHandler={this.editProductHandler}
                deleteProductHandler={this.deleteProductHandler}
                editProductVaritions={this.editProductVaritions}
              />
            ))}
          </div>

          {!isLoading && Object.keys(productList).length < 2 && (
            <div className={classes.blankState}>
              <img src={IMAGES.ILLUSTRATIONS.experiences} />
              <div className={classes.textBox}>
                <Typography className={classes.title} variant="h3">
                  {t("experiences.manage.activity.growBusiness")}
                </Typography>
                <div className={classes.subTitle}>
                  {t("experiences.manage.activity.leverage")}
                </div>
                <div
                  className={classes.button}
                  onClick={this.addProductHandler}
                >
                  {t("experiences.manage.activity.createExperience")}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* {showProductModal && (
                    <ProductForm 
                        productID = {selectedProductID}
                        closeModalHandler={this.closeModalHandler}
                    />
                )} */}

        {/* {showGradeSlotsModal && (
                    <ActivitySlotsModal
                        productID = {selectedProductID}
                        closeModalHandler={this.closeModalHandler}
                    />
                )} */}

        {showProductModal && (
          <ActivityFlow
            productID={selectedProductID}
            closeModalHandler={this.closeModalHandler}
            showEditPage={showEditPage}
            publishMessageHandler={this.publishMessageHandler}
          />
        )}

        {/* ------------- Snackbar success message -------------- */}
        {publishSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={publishSuccess}
            onClose={() => this.publishMessageHandler(false)}
            autoHideDuration={4000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">Experience published successfully</span>
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, loading, experiences } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace] || {},
    isLoading: loading.FETCH_ACTIVITY_LIST,
    productList: experiences.activityList || {},
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Activities))
);
