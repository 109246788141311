import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Select from 'react-select';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
// import get from 'lodash/get';

import { GUEST_DOC_TYPES, GENDER_OPTIONS } from '../../../common/constants/guest';

const useStyles = makeStyles(theme =>({
    root: {
        position: 'relative'
    },
    dropdownInput: {
        "& > div:not(.css-26l3qy-menu)": {
          minHeight: 30,
          border: 0,
          backgroundColor: '#f8f9fb',
          boxShadow: 'none'
        },
        "& > div > div > div": {
          padding: '0 8px'
        },
        "& .css-26l3qy-menu > div > div": {
          padding: 8
        },
        "& .css-26l3qy-menu": {
          zIndex: 5000
        }
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 10,
        marginTop: 10
    },
    field: {
        background: '#f8f9fb',
        borderRadius: 2,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
        '& .css-1okebmr-indicatorSeparator': {
          display: 'none'
        },
        '& .css-6q0nyr-Svg': {
          color: '#000000',
          width: 30,
          height: 30,
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    more: {
        fontSize: '1.4rem',
        color: '#666666',
        cursor: "pointer",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
}));

const CustomInput = props =>{
    const classes = useStyles();
    const { label, error } = props;
    return (
        <div>
            <div className={classes.label}>{label}</div>
            <div className={clsx(classes.field, { [classes.fieldError]: error })}>
                {props.children}
            </div>
        </div>
    );
}

const handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
}

const GuestForm = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { formErrors, onChangeHandler, todaysDate, showGuestHandler, data } = props;
    const [showMore, setShowMore] = useState({ address: false, other: false });

    const countryOptions = handleGetCountries();

    const genderOptions = GENDER_OPTIONS.map(gender =>({
        value: gender.value,
        label: gender.translationID ? t(gender.translationID) : gender.label
    }));

    const documentTypeOptions = GUEST_DOC_TYPES.map(docType =>({
        value: docType.value,
        label: t(docType.translationID)
    }));

    const onTextInputChange = event =>{
        const name = event.target.name;
        const value = event.target.value;
        onChangeHandler(name, value);
    }

    return (
        <div className={classes.root}>
            <h4>Guest Details</h4>
            <Grid container spacing={2}>

                {/* ------------------FIRST NAME--------------------- */}
                <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                        label={t('newReservation.guest.firstName')} 
                        error={formErrors.firstName}
                    >
                        <InputBase
                            name="firstName"
                            value={data.firstName}
                            onChange={onTextInputChange}
                            onBlur={showGuestHandler}
                            fullWidth
                        />
                    </CustomInput>
                </Grid>
                
                {/* ------------------LAST NAME--------------------- */}
                <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                        label={t('newReservation.guest.lastName')} 
                        error={formErrors.lastName}
                    >
                        <InputBase
                            name="lastName"
                            value={data.lastName}
                            onChange={onTextInputChange}
                            fullWidth
                        />
                    </CustomInput>
                </Grid>

                {/* ------------------EMAIL--------------------- */}
                <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                        label={t('newReservation.guest.email')} 
                        // error={}
                    >
                        <InputBase
                            type='email'
                            name="email"
                            value={data.email}
                            onChange={onTextInputChange}
                            fullWidth
                        />
                    </CustomInput>
                </Grid>

                {/* ------------------PHONE--------------------- */}
                <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                        label={t('newReservation.guest.phone')} 
                        // error={}
                    >
                        <InputBase
                            type='tel'
                            name="phone"
                            value={data.phone}
                            onChange={onTextInputChange}
                            fullWidth
                        />
                    </CustomInput>
                </Grid>

                {/* ------------------NATIONALITY--------------------- */}
                <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                        label={t('newReservation.guest.nationality')} 
                        // error={}
                    >
                        <Select
                            className={classes.dropdownInput}
                            name="nationality"
                            options={countryOptions}
                            value={countryOptions.find(e => e.value === data.nationality)}
                            onChange={option => onChangeHandler('nationality', option.value)}
                        />
                    </CustomInput>
                </Grid>

                {!showMore.other && (
                    <Grid item xs={12}>
                        <Typography 
                            className={classes.more} 
                            onClick={() => setShowMore({ ...showMore, other: true })}
                        >
                            {t('newReservation.guest.showMore')} <ExpandMoreIcon />
                        </Typography>
                    </Grid>
                )}

                {showMore.other && (
                    <>
                        {/* ------------------DATE OF BIRTH--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.dob')} 
                                error={formErrors.dateOfBirth}
                            >
                                <InputBase
                                    type='date'
                                    //defaultValue={data.dateOfBirth}
                                    onChange={e => onChangeHandler('dateOfBirth', e.target.value)}
                                    inputProps={{ max: todaysDate }}
                                    fullWidth
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------GENDER--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.gender')} 
                                // error={}
                            >
                                <Select
                                    className={classes.dropdownInput}
                                    options={genderOptions}
                                    //value={genderOptions.find(e => e.value === data.gender)}
                                    onChange={option => onChangeHandler('gender', option.value)}
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------COMPANY NAME--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.companyName')} 
                                // error={}
                            >
                                <InputBase
                                    name="companyName"
                                    //value={data.companyName}
                                    onChange={onTextInputChange}
                                    fullWidth
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------TAX ID--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.taxID')} 
                                // error={}
                            >
                                <InputBase
                                    name="taxID"
                                    //value={data.taxID}
                                    onChange={onTextInputChange}
                                    fullWidth
                                />
                            </CustomInput>
                        </Grid>

                        {!showMore.address && (
                            <Grid item xs={12}>
                                <Typography 
                                    className={classes.more} 
                                    onClick={() => setShowMore({ ...showMore, address: true })}
                                >
                                    {t('newReservation.guest.showAddress')} <ExpandMoreIcon />
                                </Typography>
                            </Grid>
                        )}

                        {showMore.address && (
                            <>
                                {/* ------------------ADDRESS--------------------- */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <CustomInput
                                        label={t('newReservation.guest.address')} 
                                        // error={}
                                    >
                                        <InputBase
                                            name="lineOne"
                                            //value={data.address.lineOne}
                                            onChange={onTextInputChange}
                                            fullWidth
                                        />
                                    </CustomInput>
                                </Grid>

                                {/* ------------------CITY--------------------- */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <CustomInput
                                        label={t('newReservation.guest.city')} 
                                        // error={}
                                    >
                                        <InputBase
                                            name="city"
                                            //value={data.address.city}
                                            onChange={onTextInputChange}
                                            fullWidth
                                        />
                                    </CustomInput>
                                </Grid>

                                {/* ------------------STATE--------------------- */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <CustomInput
                                        label={t('newReservation.guest.state')} 
                                        // error={}
                                    >
                                        <InputBase
                                            name="state"
                                            //value={data.address.state}
                                            onChange={onTextInputChange}
                                            fullWidth
                                        />
                                    </CustomInput>
                                </Grid>

                                {/* ------------------ZIPCODE--------------------- */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <CustomInput
                                        label={t('newReservation.guest.zipCode')} 
                                        // error={}
                                    >
                                        <InputBase
                                            name="zipCode"
                                            //value={data.address.zipCode}
                                            onChange={onTextInputChange}
                                            fullWidth
                                        />
                                    </CustomInput>
                                </Grid>

                                {/* ------------------COUNTRY--------------------- */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <CustomInput
                                        label={t('newReservation.guest.country')} 
                                        // error={}
                                    >
                                        <Select
                                            className={classes.dropdownInput}
                                            options={countryOptions}
                                            //value={countryOptions.find(e => e.value === data.address.country)}
                                            onChange={option => onChangeHandler('address.country', option.value)}
                                        />
                                    </CustomInput>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} sm={12} md={12}>
                            <Divider />
                        </Grid>

                        {/* ------------------DOCUMENT TYPE--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.docType')} 
                                // error={}
                            >
                                <Select
                                    className={classes.dropdownInput}
                                    options={documentTypeOptions}
                                    //value={documentTypeOptions.find(e => e.value === get(data, 'documents[0].type'))}
                                    onChange={option => onChangeHandler('documents[0].type', option.value)}
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------DOCUMENT NUMBER--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.docNum')} 
                                // error={}
                            >
                                <InputBase
                                    //value={get(data, 'documents[0].number', '')}
                                    onChange={e => onChangeHandler('documents[0].number', e.target.value)}
                                    fullWidth
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------DOCUMENT ISSUING COUNTRY--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.docIssuingCountry')} 
                                // error={}
                            >
                                <Select
                                    className={classes.dropdownInput}
                                    options={countryOptions}
                                    //value={countryOptions.find(e => e.value === get(data, 'documents[0].issuingCountry'))}
                                    onChange={option => onChangeHandler('documents[0].issuingCountry', option.value)}
                                />
                            </CustomInput>
                        </Grid>

                        {/* ------------------DOCUMENT EXPIRY--------------------- */}
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                label={t('newReservation.guest.docExpiry')} 
                                // error={}
                            >
                                <InputBase
                                    type='date'
                                    //defaultValue={get(data, 'documents[0].expiry', '')}
                                    onChange={e => onChangeHandler('dateOfBirth', e.target.value)}
                                    onChange={e => onChangeHandler('documents[0].expiry', e.target.value)}
                                    inputProps={{ max: todaysDate }}
                                    fullWidth
                                />
                            </CustomInput>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}

export default GuestForm;