import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { getReservationDetails, resetReservationDetails } from '../../redux/actions/beds/beds';
import { setSearchActive, clearSearch} from '../../redux/actions/search/search';

import { Grid, CircularProgress } from '@material-ui/core';
import Reservation from '../reservations/Reservation';
import Guests from './Guests';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 20,
    width: 'calc(100% - 60px)',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.4rem',
    height: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%'
  },
  searchCount: {
    alignItems: 'center',
    color: '$666666',
    display: 'flex',
    fontSize: '1.3rem',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  iconContainer: {
    marginLeft: 20,
  },
  icon: {
    borderRadius: 5,
    cursor: 'pointer',
    height: 15,
    width: 15,
    marginLeft: 10,
    opacity: 0.2,
    padding: 5,
  },
  selected: {
    opacity: 0.7,
    background: '#FFFFFF',
  },
  searchResults: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  clearContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%',
  },
  clearButton: {
    padding: '12px 25px',
    borderRadius: 5,
    background: '#E0E0E0',
    cursor: 'pointer',
    fontSize: '1.2rem',
    color: '#484848',
    '&:hover': {
      background: '#C0C0C0'
    }
  }
}))

const SearchFilters = (props) => {
  const classes = useStyles();
  const [openReservation, setOpenReservation] = React.useState(false);
  const [display, setDisplay] = React.useState('tile');

  const { searchResults, loadingSearchReservations, status, date, keyword } = props;
  const searchInactive = status === '' && date === '' && keyword === ''

  const handleOpenReservation = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true });
  }

  const handleCloseReservation = () => {
    props.dispatch(resetReservationDetails());
    setOpenReservation(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false });
  }

  const handleClearSearch = () => {
    props.dispatch(clearSearch());
    props.dispatch(setSearchActive(false));
  }

  return (
    <div className={classes.container}>

      {/* Count of search results - more work necessary */}
      {/* ------------------------------------------------------------------ */}
      <div className={classes.searchCount}>
        {!searchInactive && !loadingSearchReservations && searchResults.length === 9 && 'More than 10 results found'}
        {!searchInactive && !loadingSearchReservations && searchResults.length < 9 && `${searchResults.length} results found`}
        <div className={classes.iconContainer}>
          <img src={IMAGES.ICONS.list} className={clsx(classes.icon, { [classes.selected]: display === 'list' })} onClick={() => setDisplay('list')} />
          <img src={IMAGES.ICONS.tiles} className={clsx(classes.icon, { [classes.selected]: display === 'tile' })} onClick={() => setDisplay('tile')} />
        </div>
      </div>

      {/* Search results */}
      {/* ------------------------------------------------------------------ */}
      {searchResults && searchResults.length ?
        <Grid container spacing={3} className={classes.searchResults}>
          {searchResults.map(reservation => (
            // <GuestTile reservation={reservation} key={reservation._id} handleOpenReservation={handleOpenReservation} />
            <Guests display={display} reservation={reservation} key={reservation._id} handleOpenReservation={handleOpenReservation} />
          ))}
        </Grid>
        : null}

      {/* Loading search results */}
      {/* ------------------------------------------------------------------ */}
      {loadingSearchReservations && <div className={classes.loading}><CircularProgress /></div>}

      
      <div className={classes.clearContainer}>
        <div className={classes.clearButton} onClick={handleClearSearch}>Close Search</div>
      </div>

      {/* Open reservation when search results clicked */}
      {/* ------------------------------------------------------------------ */}
      {openReservation &&
        <Reservation
          open={openReservation}
          handleCloseReservation={handleCloseReservation}
        />
      }


    </div>
  )
}

const mapStateToProps = (state) => ({
  searchResults: state.search.searchResults,
  keyword: state.search.keyword,
  date: state.search.date,
  status: state.search.status,
  currentReservation: state.dashboard.currentReservation,
  loadingSearchReservations: state.loading.SEARCH_RESERVATIONS
})

export default connect(mapStateToProps)(SearchFilters);