import React from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { returnCurrent } from "../../redux/selectors/dashboard";
import { createTaxItem } from "../../redux/actions/taxes/taxes";
import TaxList from "./TaxList";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import NewTax from "./NewTax";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { resetReducerState } from "../../redux/actions/common/common";

const useStyles = makeStyles((theme) => ({
  dialog: {
    // overflowY: 'scroll'
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      right: "15px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  createSection: {
    // alignItems: 'flex-end',
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // display: 'flex',
    justifyContent: "space-between",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  contactDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  contact: {
    width: "47%",
  },
  pin: {
    marginRight: 25,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: 10,
  },
  listSection: {
    margin: theme.spacing(3),
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const Taxes = (props) => {
  const { t } = props;
  const [newTax, setNewTax] = React.useState(false);
  const classes = useStyles();
  const { currentSpaceID, currentSpace } = props;

  const handleClose = () => {
    setNewTax(false);
    props.handleCloseTaxModal();
  };

  const handleNewTaxSubmit = (data) => {
    const response = props.dispatch(
      createTaxItem(currentSpace._id, currentSpace.propertyID, {
        ...data,
        propertyID: currentSpace.propertyID,
      })
    );
    response.then((response) => {
      if (response) {
        setNewTax(false);
      }
    });
  };

  const handleCloseError = () => {
    props.dispatch(resetReducerState("CREATE_TAX_ITEM"));
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      classes={{ paperFullWidth: classes.dialogPaper }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* {!newTax && (
            <Hidden smDown><Button 
              size='small' 
              onClick={() => setNewTax(true)} 
              className={classes.newListButton} 
              color='primary' 
              variant='outlined'
            >
              New Tax
            </Button></Hidden>
          )} */}
          {!newTax && (
            <AddIcon
              className={classes.addButton}
              onClick={() => setNewTax(true)}
            />
          )}
          <Typography className={classes.header}>
            {t("beds.taxesSnapshot.title")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.TAXES.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {/* ------------------------------------------------ */}
        {/* CREATE NEW TAX */}
        {/* ------------------------------------------------ */}
        <div
          className={classes.createSection}
          style={{ display: newTax === false ? "none" : "" }}
        >
          {newTax && (
            <NewTax
              currentSpace={currentSpace}
              handleCancel={() => setNewTax(false)}
              handleSubmit={handleNewTaxSubmit}
            />
          )}
        </div>

        {/* --------------------------------------------------------- */}
        {/* TAX LIST */}
        {/* --------------------------------------------------------- */}
        <div className={classes.listSection}>
          {!newTax && <TaxList handleClose={handleClose} />}
        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {props.error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => handleCloseError()}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{props.error}</span>}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  error: state.errors.CREATE_TAX_ITEM
    ? "Error occurred while creating a new Tax"
    : "",
});

export default withTranslation()(connect(mapStateToProps)(Taxes));
