import React from 'react';
import { capitalize } from './wordUtils';
import _ from 'lodash';

export default (users, members) => {
  
  const memberLength = members.length;
  // console.log(members, users);

  return (
    <span>
      {members.map((member,index) => {
        if (memberLength === 1 || memberLength === index + 1) {
          return (
            <span key={index}>
              {users[member]?.name && capitalize(users[member].name)}
            </span>
          )
        } 
        else if (memberLength === index + 2) {
          return (
            <span key={index}>
              {users[member]?.name && (capitalize(_.first(users[member].name.split(" "))) + ' & ')}
            </span>
          )
        } else {
          return (
            <span key={index}>
              {users[member]?.name && capitalize(_.first(users[member].name.split(" ")))},&nbsp;
            </span>
          )
        }
      })}
    </span>
  )
}