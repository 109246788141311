import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { fetchProductList } from "../../redux/actions/experiences/activities";
import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import Bookings from "./reports/Bookings";
import Occupancy from "./reports/Occupancy";
import Revenue from "./reports/Revenue";

import { RESERVATION_STATUS } from "../../utils/constants";

import { Grid, Typography } from "@material-ui/core";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    padding: 20,
    width: "calc(100% - 40px)",
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    border: "0px solid #000000",
    boxShadow: "0 3px 4px 0 #ECECEC",
    cursor: "pointer",
    "&:hover": {
      background: "#EFEFEF",
      transition: "0.2s",
    },
  },
  header: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  cardTitle: {
    marginLeft: 0,
    verticalAlign: "middle",
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  body: {
    borderRadius: "10px",
    minHeight: 200,
    overflow: "hidden",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      minHeight: 200,
    },
    "& img": {
      cursor: "pointer",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  emailBody: {
    minHeight: 120,
  },
  paymentBody: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: 120,
    "& svg": {
      fontSize: "5rem",
    },
  },
  description: {
    color: "#666666",
    textAlign: "center",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 150,
    justifyContent: "center",
  },
  icon: {
    fontSize: "5rem",
  },
  image: {
    height: 120,
    width: 120,
  },
}));

const ReportsPage = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { currentSpace, experiences } = props;
  const [openBookings, setOpenBookings] = useState(false);
  const [openOccupancy, setOpenOccupancy] = useState(false);
  const [openRevenue, setOpenRevenue] = useState(false);

  const handleOpenBookings = () => {
    setOpenBookings(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };
  const handleCloseBookings = () => {
    setOpenBookings(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleOpenOccupancy = () => {
    props.dispatch(fetchProductList(currentSpace.propertyID));
    setOpenOccupancy(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };
  const handleCloseOccupancy = () => {
    setOpenOccupancy(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleOpenRevenue = () => {
    //props.dispatch(fetchProductList(currentSpace.propertyID))
    setOpenRevenue(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };
  const handleCloseRevenue = () => {
    setOpenRevenue(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* ----------------------------------------------------------------- */}
        {/*                        RESERVATIONS                               */}
        {/* ----------------------------------------------------------------- */}
        <Grid item xs={6} sm={4} lg={3} xl={2}>
          <div className={classes.card} onClick={handleOpenBookings}>
            <div className={classes.header}>
              <Typography component="h4" variant="h6">
                {t("experiences.reports.bookings")}
              </Typography>
            </div>
            <div className={classes.iconHolder}>
              <img
                src={IMAGES.ILLUSTRATIONS.reportsReservations}
                className={classes.image}
              />
            </div>
          </div>
        </Grid>

        {openBookings && (
          <Bookings open={openBookings} handleClose={handleCloseBookings} />
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                        OCCUPANCY                                  */}
        {/* ----------------------------------------------------------------- */}
        <Grid item xs={6} sm={4} lg={3} xl={2}>
          <div className={classes.card} onClick={handleOpenOccupancy}>
            <div className={classes.header}>
              <Typography component="h4" variant="h6">
                {t("reports.occupancy")}
              </Typography>
            </div>
            <div className={classes.iconHolder}>
              <img
                src={IMAGES.ILLUSTRATIONS.reportsOccupancy}
                className={classes.image}
              />
            </div>
          </div>
        </Grid>

        {openOccupancy && (
          <Occupancy open={openOccupancy} handleClose={handleCloseOccupancy} />
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                        REVENUE                                    */}
        {/* ----------------------------------------------------------------- */}
        <Grid item xs={6} sm={4} lg={3} xl={2}>
          <div className={classes.card} onClick={handleOpenRevenue}>
            <div className={classes.header}>
              <Typography component="h4" variant="h6">
                {t("reports.revenue")}
              </Typography>
            </div>
            <div className={classes.iconHolder}>
              <img
                src={IMAGES.ILLUSTRATIONS.reportsRevenue}
                className={classes.image}
              />
            </div>
          </div>
        </Grid>

        {openRevenue && (
          <Revenue open={openRevenue} handleClose={handleCloseRevenue} />
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  experiences: state.experiences,
});

export default withTranslation()(connect(mapStateToProps)(ReportsPage));
