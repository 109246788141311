import React from "react";
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { editFrontdeskUser } from "../../redux/actions/user/frontdeskUsers";

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Hidden from '@material-ui/core/Hidden';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'auto'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%',
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
  },
  newListButton: {
    cursor: 'pointer',
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  backButton: {
    color: '#666666',
    cursor: 'pointer',
    fontSize: '3rem',
    left: 24,
    position: 'absolute',
    top: 28
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  listSection: {
    minHeight: 'calc(100vh - 230px)',
    padding: theme.spacing(3)
  },
  container: {
    alignItems: "center",
    borderBottom: '1px solid #eeeeee',
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  rightAlign : {
    paddingLeft: 30,
    width: '70%'
  },
  moreIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#EEEEEE'
    }
  },
  fieldName: {
    cursor: "pointer",
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.5
  },
  fieldContact: {
    cursor: "pointer",
    fontSize: '1.4rem',
  },
  statusField: {
    cursor: 'pointer'
  },
  resetPin: {
    cursor: 'pointer',
    fontSize: '1.2rem',
    color: 'blue'
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
});

const FrontdeskStaffItem = (props) => {
  const { t } = props
  const {classes, frontdeskUsers, selectedUser} = props;
  const frontdeskUser = frontdeskUsers[selectedUser];
  const [edit, setEdit] = React.useState(false);
  const [editPin, setEditPin] = React.useState(false);
  const [maker, setMaker] = React.useState(null);
  const [userPin, setUserPin] = React.useState(null);

  const handleBack = () => {
    handleCancelUpdates();
    props.handleBack();
  }

  const handleClose = () => {
    handleCancelUpdates();
    props.handleClose();
  }

  const handleEdit = () => {
    setEdit(true);
    setMaker({isDeleted: frontdeskUser.isDeleted});
  }

  const handleOnChange = (e) => {
    if (e.target.id === 'isDeleted') {
      setMaker({...maker, [e.target.id]: !e.target.checked});
    } else {
      setMaker({...maker, [e.target.id]: e.target.value});
    }
  }

  const handleOnPinChange = (e) => setUserPin({...userPin, [e.target.id]: e.target.value});

  const handleCancelUpdates = () => {
    setEditPin(false);
    setUserPin(null);
    setEdit(false);
  }

  const handleSubmitUpdates = () => {
    if (editPin) {
      if (userPin.pin === userPin.pin2) {
        // console.log('update cash drawer with pin update', selectedUser, maker);
        props.dispatch(editFrontdeskUser(selectedUser, {...maker, loginPin: userPin.pin}));
        handleCancelUpdates();
      } else {
        alert('PINs do not match')
      }
    } else {
      // console.log('update cash drawer without pin update', selectedUser, maker);
      props.dispatch(editFrontdeskUser(selectedUser, maker));
      handleCancelUpdates();
    }
  }

  return(
    <Dialog
      open={Boolean(selectedUser)}
      classes={{ paperFullWidth: classes.dialogPaper }}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div>
            <Hidden smDown><Button 
              size='small' 
              color='primary' 
              variant='outlined' 
              className={classes.newListButton}
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              onClick={handleBack}
            >
              {t('actions.back')}
            </Button></Hidden>
            <Hidden mdUp>
              <BackIcon className={classes.backButton} onClick={handleBack} />
            </Hidden>
          </div>
          <Typography variant="h5">{edit && 'Edit '}{t('frontdesk.frontdeskAccess.frontdeskUser')}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listSection}>

            <div className={classes.container}>

              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.firstName')}
              </div>
              
              {/* --------------------------------------------------------------------- */}
              {/* First Name */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.rightAlign}>
                {!edit && <div className={classes.fieldName} onClick={handleEdit}>{frontdeskUser.firstName}</div>}
                {edit && (
                  <TextField
                    id="firstName"
                    className={classes.fieldName}
                    defaultValue={frontdeskUser.firstName}
                    onChange={handleOnChange}
                    margin="none"
                    fullWidth
                  />
                )}
              </div>

              {/* ------------------------------------------------ */}
              {/* EDIT CASH DRAWER */}
              {/* ------------------------------------------------ */}
              {!edit && (
                <div onClick={handleEdit}>
                  <EditIcon className={classes.moreIcon} />
                </div>
              )}
              
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* Last Name */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.lastName')}
              </div>
              <div className={classes.rightAlign}>
                {!edit && <div className={classes.fieldName} onClick={handleEdit}>{frontdeskUser.lastName}</div>}
                {edit && (
                  <TextField
                    id="lastName"
                    className={classes.fieldName}
                    defaultValue={frontdeskUser.lastName}
                    onChange={handleOnChange}
                    margin="none"
                    fullWidth
                  />
                )}
              </div>
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* Status */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.status')}
              </div>
              <div className={classes.rightAlign}>
                {!edit && 
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={!frontdeskUser.isDeleted}
                        className={classes.statusField}
                        color="primary"
                      />
                    }
                    label={frontdeskUser.isDeleted ? t('frontdesk.frontdeskAccess.inactive') : t('frontdesk.frontdeskAccess.active')}
                    onClick={handleEdit}
                  />
                }
                {edit && (
                  <FormControlLabel
                    control={
                      <Switch
                        id="isDeleted"
                        checked={!maker.isDeleted}
                        onChange={handleOnChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={maker.isDeleted ? t('frontdesk.frontdeskAccess.inactive') : t('frontdesk.frontdeskAccess.active')}
                  />
                )}
              </div>
            </div>

            {/* <div className={classes.container}>
              {/* --------------------------------------------------------------------- *}
              {/* Email *}
              {/* --------------------------------------------------------------------- *}
              <div className={classes.leftAlign}>
                Email
              </div>
              <div className={classes.rightAlign}>
                <div className={classes.createdAt} onClick={handleEdit}>
                  {!edit && 
                    <div className={classes.fieldContact}>
                      {frontdeskUser.email ? frontdeskUser.email : '(optional)'}
                    </div>
                  }
                  {edit && (
                    <TextField
                      id="email"
                      className={classes.fieldContact}
                      defaultValue={frontdeskUser.email}
                      onChange={handleOnChange}
                      margin="none"
                      fullWidth
                    />
                  )}
                </div>
              </div>
            </div> */}

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* Phone */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.phone')}
              </div>
              <div className={classes.rightAlign}>
                <div className={classes.createdAt} onClick={handleEdit}>
                  {!edit && 
                    <div className={classes.fieldContact}>
                      {frontdeskUser.phone ? frontdeskUser.phone : '(optional)'}
                    </div>
                  }
                  {edit && (
                  <TextField
                    id="phone"
                    className={classes.fieldContact}
                    defaultValue={frontdeskUser.phone}
                    onChange={handleOnChange}
                    margin="none"
                    fullWidth
                  />
                  )}
                </div>
              </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* Role */}
            {/* --------------------------------------------------------------------- */}
            {/* <div className={classes.container}>
              <div className={classes.leftAlign}>
                Role
              </div>
              <div className={classes.rightAlign}>
                <div className={classes.createdAt}>
                  {frontdeskUser.role === 'frontDesk' && `Frontdesk User`}
                </div>
              </div>
            </div> */}

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* Created At */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.createdOn')}
              </div>
              <div className={classes.rightAlign}>
                <div className={classes.createdAt}>
                  {moment(frontdeskUser.createdAt).format('DD MMM, YYYY')}
                </div>
              </div>
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* Reset Pin */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('frontdesk.frontdeskAccess.pin')}
              </div>
              <div className={classes.rightAlign}>
                {!editPin && <div className={classes.resetPin} onClick={() => {handleEdit(); setEditPin(true);}}>
                  {t('frontdesk.frontdeskAccess.resetPin')}
                </div>}
                {edit && editPin && (
                  <TextField
                    id="pin"
                    type="password"
                    onChange={handleOnPinChange}
                    className={classes.fieldName}
                    margin="none"
                  />
                )}
              </div>
            </div>

            {edit && editPin && (
              <div className={classes.container}>
                {/* --------------------------------------------------------------------- */}
                {/* Re-enter Pin */}
                {/* --------------------------------------------------------------------- */}
                <div className={classes.leftAlign}>
                  {t('frontdesk.frontdeskAccess.repeatPin')}
                </div>
                <div className={classes.rightAlign}>
                  <TextField
                    id="pin2"
                    type="password"
                    onChange={handleOnPinChange}
                    className={classes.fieldName}
                    margin="none"
                  />
                </div>
              </div>
            )}

            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL UPDATES */}
            {/* --------------------------------------------------------------------- */}
            {edit && (
              <div className={classes.submit}>
                <Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancelUpdates}>{t('actions.cancel')}</Button>
                <Button size="small" variant="outlined" className={classes.button} onClick={handleSubmitUpdates}>{t('actions.save')}</Button>
              </div>
            )}

        </div>
      </div>
      
    </Dialog>
  )
}

const mapStateToProps = state => ({
  frontdeskUsers: state.frontdeskUsers
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(FrontdeskStaffItem)));