import React, { useState } from 'react';
// import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CheckinInfo from './CheckinInfo';

const useStyles = makeStyles(theme =>({
    rootContainer: {
        boxShadow: '0 3px 4px 0px #ECECEC',
    },
    root:{
        //border: '1px solid yellow',
        display: 'flex',
        backgroundColor: '#F8F9FB',
        borderRadius: '5px',
        overflow: 'hidden'
    },
    mainContainer:{
        background: '#FFFFFF',
        padding: theme.spacing(1),
        width: '100%'
    },
    tabBody: {
        
    }
}));

const LiveSidebar = (props) => {
    const classes = useStyles();

    return(
        <div className={classes.rootContainer}>
            <div className={classes.root}>
                <div className={classes.mainContainer} >

                    <CheckinInfo />
                    
                </div>
            </div>
        </div>
    )
}

export default LiveSidebar;