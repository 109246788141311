const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const capitalizeFirst = (name) => {
  var n = name.split(" ");
  return n[0].charAt(0).toUpperCase() + n[0].slice(1);
}

const initials = (name = "") => {
  var n = name.split(" ");
  // console.log(name, n, n[0].charAt(0).toUpperCase() + n[n.length - 1].charAt(0).toUpperCase())
  if (n.length > 1) {
    return `${n[0].charAt(0).toUpperCase() + n[n.length - 1].charAt(0).toUpperCase()}`
  } else if (n.length == 1) {
    return `${n[0].charAt(0).toUpperCase()}`
  } else {
    return ""
  }
}

const toTitleCase = (str) => {
  str = str || "";
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const truncateText = (text, max_length) => {
  if(text.length > max_length - 3){
      return text.substring(0, max_length).trimEnd() + "..."
  }
  else{
      return text
  }
}

const humanFileSize = (size) => {
  if (size < 1024) return size + ' B'
  let i = Math.floor(Math.log(size) / Math.log(1024))
  let num = (size / Math.pow(1024, i))
  let round = Math.round(num)
  num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
  return `${num} ${'KMGTPEZY'[i-1]}B`
}

const alphaNumericSort = (a, b) =>{
  return new Intl.Collator('en',{numeric:true, sensitivity:'accent'}).compare(a,b);
}

export {
  capitalize, 
  capitalizeFirst, 
  initials, 
  toTitleCase, 
  humanFileSize, 
  truncateText, 
  alphaNumericSort 
};