import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CalendarDates from '../CalendarDates';

const useStyles = makeStyles(theme => ({
    calendarDates: {
        position: 'relative',
        '& .date-section': {
            padding: '5px 0px',
            paddingRight: '15px',
            boxSizing: 'border-box',
        },
        '& .datepicker-container': {
            margin: 'unset !important'
        },
        '& .DateInput_input': {
            fontSize: '12px'
        },
        '& .datePicker-arrow-icon': {
            fontSize: '1.2rem'
        }
    },
    calendarGrid: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100vh - 290px)',
        paddingRight: '15px',
        boxSizing: 'border-box',
        '& .calendarColumn': {
            display: 'inline-block',
            boxShadow: 'inset 7px 0 6px -7px #F0F0F0',
            height: '100%',
        },

        '& .calendarColumn:first-child': {
            boxShadow: 'unset'
        },

        '&.fullscreen':{
            height: 'calc(100vh - 150px)',
        }
    },
}));

const DatesHeader = props => {
    const classes = useStyles();
    const {
        checkIn,
        checkOut,
        resetDate,
        numberOfDays,
        timezone,
        dateFormat,
        handleSelectedDate,
        handleCalendarDays,
        fullScreenMode
    } = props;

    return (
        <>
            <CalendarDates
                dateSelectEnabled={true}
                calendarDaysEnabled={true}
                calendarChangeEnabled={true}
                numberOfDays={numberOfDays}
                datePicker={true}
                checkIn={checkIn}
                checkOut={checkOut}
                forcedStartDate={resetDate}
                timezone={timezone}
                dateFormat={dateFormat}
                handleSelectedDate={handleSelectedDate}
                handleCalendarDays={handleCalendarDays}
                showAccessHeader={false}
            />
            <div className={clsx({[classes.calendarGrid]: true, 'fullscreen': fullScreenMode})} >
                {Array(numberOfDays)
                    .fill()
                    .map((_, i) => (
                        <div key={i} className="calendarColumn" style={{ width: `${100 / numberOfDays}%` }}></div>
                    ))}
            </div>
        </>
    )
}

export default DatesHeader;