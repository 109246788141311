import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import MaterialTable, { MTableBodyRow } from 'material-table';
import { getReservationDetails, resetReservationDetails } from '../../../redux/actions/beds/beds';
import Reservation from '../../reservations/Reservation';
import { exportExcel } from '../../utils/excelExport';
import { processSessionTransactions } from '../../../redux/selectors/reports/cashAudit';
import HtmlTooltip from '../../common/HtmlTooltip';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 30
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '100%',
    },
    clear: {
        cursor: 'pointer',
        fontSize: '1.2rem',
    },
    sectionHeaderText: {
        color: '#333333',
        fontWeight: 600
    },
    thead: {
        background: '#F6F2EE',
    },
    trow: {
        cursor: 'pointer'
    },
    highlighted: {
        color: 'red'
    },
    tableRow: {
        cursor: 'default !important'
    }
}))

const SessionTransactions = (props) => {

    const classes = useStyles();
    const { currentSpace, transactions, transactionModes, frontdeskUsers, users, loadingFrontdesk, t } = props;
    const [openReservation, setOpenReservation] = React.useState(false);

    //const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);
    const currencyData = {
        code: currentSpace.currency,
        country: currentSpace.country
    }

    const formattedTransactions = processSessionTransactions(
        transactions,
        transactionModes,
        frontdeskUsers,
        users,
        currencyData
    );

    const handleTableRowClick = (resID) => {
        props.dispatch(getReservationDetails(resID));
        setOpenReservation(true);
    }

    const handleCloseReservation = () => {
        setOpenReservation(false);
        props.dispatch(resetReservationDetails());
    }

    const rowCount = formattedTransactions.length;
    const tableTitle = t('reports.table.transaction');

    return (
        <div className={classes.root}>

            <MaterialTable
                title={tableTitle}
                columns={[
                    { title: t('reports.table.transactionType'), field: 'transactionType' },
                    { title: t('reports.table.mode'), field: 'mode' },
                    { title: `t('reports.table.amount') (${currencyData.code})`, field: 'amount' },
                    { title: t('reports.table.createdBy'), field: 'createdBy' },
                    { title: t('reports.table.note'), field: 'note' },
                    { title: t('reports.table.createdAt'), field: 'createdAt' }
                ]}
                data={formattedTransactions}
                // isLoading={props.isLoading} 
                options={{
                    // grouping: true,
                    exportButton: true,
                    rowStyle: { fontSize: '1.2rem' },
                    headerStyle: {
                        backgroundColor: '#F6F2EE',
                        zIndex: 'unset'
                    },
                    paging: rowCount > 50 ? true : false,
                    pageSize: 50,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [50, 60, 80],
                    exportCsv: (columns, data) => {
                        const tableData = _.cloneDeep(data);
                        const headerConfig = [...columns];
                        const filename = `${tableTitle}`;
                        exportExcel(filename, headerConfig, tableData);
                    },
                }}
                localization={{
                    toolbar: { exportCSVName: "Export as Excel" },
                    body: {
                        emptyDataSourceMessage: t('reports.table.noTransaction')
                    }
                }}
                onRowClick={(event, rowData) => rowData?.resvID ? handleTableRowClick(rowData.resvID) : null}
                components={{
                    Row: props =>{
                        if(['CASH_IN', 'CASH_OUT'].includes(props.data.transactionTypeID)){

                            const tooltipTitle = ({
                                'CASH_IN': 'Frontdesk cash-in transaction',
                                'CASH_OUT': 'Frontdesk cash-out transaction'
                            })[props.data.transactionTypeID];

                            return (
                                <HtmlTooltip title={tooltipTitle} cursorType="default">
                                    <MTableBodyRow {...props} className={classes.tableRow}/>
                                </HtmlTooltip>
                            )
                        }
                        return <MTableBodyRow {...props} />
                    }
                }}
            />

            {/* Open reservation when transaction item clicked */}
            {/* ------------------------------------------------------------------ */}
            {openReservation &&
                <Reservation
                    open={openReservation}
                    handleCloseReservation={handleCloseReservation}
                />
            }

        </div>
    )

}

const mapStateToProps = state => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    transactions: state.reports.cashDrawers.transactions,
    transactionModes: state.transactionModes,
    frontdeskUsers: state.frontdeskUsers,
    users: state.users,
    loadingFrontdesk: state.loading.LOAD_FRONTDESK_USERS,
    users: state.users
});

export default withTranslation()(connect(mapStateToProps)(SessionTransactions));