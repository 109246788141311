import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { fetchCurrencyFormat, getNumberAbbr } from '../../utils/utility'; 

const commonProperties = {
  width: 900,
  height: 400,
  margin: { top: 20, right: 20, bottom: 60, left: 80 },
  data,
  animate: true,
  enableSlices: 'x',
}

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
      {/* <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} /> */}
      <circle
          r={size / 3}
          strokeWidth={borderWidth}
          stroke={borderColor}
          fill={borderColor}
          fillOpacity={1}
      />
  </g>
)

const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

const RevenueLine = (props) => {

  const { classes, currentSpace, currentSpaceID, live } = props;
  const tickValue= window.innerWidth < 901 ? 45 : 0;
  const paddingRight= window.innerWidth < 901 ? 20 : 110;
  let currency;
  if (currentSpace) {
    currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);
  }

  let data2 = [
    {
      "id": moment().subtract(1, 'year').year(),
      "color": "#E6BBB3",
      "data": []
    },
    {
      "id": moment().year(),
      "color": "#8CB2A4",
      "data": []
    }
  ]

  let match1, match2;

  if (live[currentSpaceID]) {
    for (let i = 0; i < 12; i++) { 
      
      match1 = live[currentSpaceID].revenue && live[currentSpaceID].revenue.filter(e => {
        return e._id.year=== moment().subtract(1, 'year').year() && e._id.month===i+1
      }) || []

      match2 = live[currentSpaceID].revenue && live[currentSpaceID].revenue.filter(e => {
        return e._id.year===moment().year() && e._id.month===i+1
      }) || []

      data2[0]["data"].push({"x": months[i], "y": match1.length ? Math.round(match1[0].amount) : 0})
      data2[1]["data"].push({"x": months[i], "y": match2.length ? Math.round(match2[0].amount) : 0})
      match1 = []
      match2 = []
    }
  }

  return (
    <ResponsiveLine
        data={data2}
        margin={{ top: 50, right: paddingRight, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: tickValue,
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle',
            format: function(value){ 
              return getNumberAbbr(value);
            }
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: "#999999"
              }
            }
          }
        }}
        areaBlendMode="screen"
        colors={{ datum: 'color' }}
        curve="monotoneX"
        enableArea={false}
        // enableCrosshair={false}
        enableGridX={false}
        enableGridY={false}
        enableSlices="x"
        pointSize={10}
        pointSymbol={CustomSymbol}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        sliceTooltip={({ slice }) => {
          return (
            <div
                style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                }}
            >
              {slice.points.map(point => (
                  <div
                      key={point.id}
                      style={{
                          color: point.serieColor,
                          padding: '3px 0',
                      }}
                  >
                      <strong>{point.serieId}</strong>: {currency}{getNumberAbbr(point.data.yFormatted)}
                  </div>
              ))}
            </div>
          )
        }}
        useMesh={true}
        legends={[
            {
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentSpaceID: state.dashboard.currentSpace,
  live: state.live
})

export default connect(mapStateToProps)(RevenueLine);


const data = [
  {
    "id": "2018",
    "color": "#E6BBB3",
    "data": [
      {
        "x": "Jan",
        "y": 260
      },
      {
        "x": "Feb",
        "y": 240
      },
      {
        "x": "Mar",
        "y": 200
      },
      {
        "x": "Apr",
        "y": 208
      },
      {
        "x": "May",
        "y": 148
      },
      {
        "x": "Jun",
        "y": 136
      },
      {
        "x": "Jul",
        "y": 148
      },
      {
        "x": "Aug",
        "y": 160
      },
      {
        "x": "Sep",
        "y": 180
      },
      {
        "x": "Oct",
        "y": 240
      },
      {
        "x": "Nov",
        "y": 260
      },
      {
        "x": "Dec",
        "y": 340
      }
    ]
  },
  {
    "id": "2019",
    "color": "#8CB2A4",
    "data": [
      {
        "x": "Jan",
        "y": 300
      },
      {
        "x": "Feb",
        "y": 280
      },
      {
        "x": "Mar",
        "y": 210
      },
      {
        "x": "Apr",
        "y": 180
      },
      {
        "x": "May",
        "y": 160
      },
      {
        "x": "Jun",
        "y": 150
      },
      {
        "x": "Jul",
        "y": 160
      },
      {
        "x": "Aug",
        "y": 195
      },
      {
        "x": "Sep",
        "y": 220
      },
      {
        "x": "Oct",
        "y": 260
      },
      {
        "x": "Nov",
        "y": 280
      },
      {
        "x": "Dec",
        "y": 345
      }
    ]
  }
]