import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ChatSupport from '../chat-support/ChatSupport';
import clsx from 'clsx';
import Stepper from './Stepper';

import Content from './content';
import Header from './Header';

const styles = theme =>({
    root: {
        '& .inboxlangSelect': {
            padding: '20px 30px'
        },
        [theme.breakpoints.down("xs")]: {
            '& .inboxlangSelect': {
                padding: '20px 10px'
            },
        }
    },
    container: {
        // border: "1px solid",
        margin: "0 auto",
        maxWidth: "1000px",
        padding: "0px 10px",
        minHeight: "calc(100vh - 110px)",
        marginTop: "30px",
    },
    content: {
        // border: "1px solid red",
        marginTop: "50px",

        [theme.breakpoints.down('xs')]:{
            marginTop: "30px",
        },

        '& .title':{
            fontSize: "1.4rem",
            fontWeight: 600,
            marginBottom: '30px'
        },

        '& .label': {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#666666",
            marginBottom: "8px",
        },
        
        '& .textInput': {
            width: "100%",

            '& .MuiOutlinedInput-root':{
                borderRadius: '8px'
            },

            '& .MuiOutlinedInput-input':{
                padding: "12px 10px",
            },

            '& .MuiOutlinedInput-notchedOutline':{
                borderColor: "#e0e0e0",
            },

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#e0e0e0",
            },

            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: "#f44336",
            }
        },

        '& .nextButton': {
            backgroundColor: "#42476f",
            color: "#fff",
            width: "100%",
            borderRadius: '5px',
            padding: '8px'
        },
        
        '& .errorMessage': {
            color: "#f44336"
        },
    }
});

const CONTENT = {
    1: 'PersonalInfo',
    2: 'HostelDetails',
    3: 'NameRooms',
    4: 'ChannelDetails',
    5: 'PaymentDetails'
};

class MigrationFlow extends Component {

    state = {
        activeStep: 1
    }

    componentDidMount(){
        const { ongoingStep } = this.props;
        this.setActiveStep(ongoingStep);
    }

    nextStep = () =>{
        const { activeStep } = this.state;
        const { migrationCompleted, ongoingStep, loginToWorksape } = this.props;

        //Check if process completed.........
        if(migrationCompleted && ongoingStep == 5){
            loginToWorksape();
            return;
        }

        const stepNo = activeStep + 1;
        this.setActiveStep(stepNo);
    }

    setActiveStep = stepNo =>{
        const { ongoingStep } = this.props;
        if(stepNo > ongoingStep) return;
        this.setState({ activeStep: stepNo });
    }

    getIntercomConfigInfo = () => {
        const { personalInfo, space, userInfo } = this.props;

        return {
            email: personalInfo.email,
            name: personalInfo.name,
            spaceID: space?._id || '',
            propertyID: space?.propertyID || '',
            propertyName: space?.name || '',
            isAdmin: userInfo.userRole == 'manager',
        }
    }

    render(){
        const { 
            classes, 
            userInfo, 
            ongoingStep,
            migrationCompleted, 
            space,
            loginToWorksape,
            updateAPIParams
        } = this.props;
        
        const { activeStep } = this.state;
        const ContentComponent = Content[CONTENT[activeStep]];

        const componentProps = {
            nextStep: this.nextStep,
            ...userInfo,
            activeStep,
            updateAPIParams: { ...updateAPIParams, spaceID: space._id },
            loginToWorksape,
            ongoingStep
        }

        return(
            <div className={classes.root}>
                <div className={classes.container}>

                    <Stepper 
                        migrationCompleted={migrationCompleted}
                        ongoingStep={ongoingStep}
                        activeStep={activeStep}
                        setActiveStep={this.setActiveStep} 
                    />
                    
                    <div className={classes.content}>
                        <ContentComponent { ...componentProps }/>
                    </div>
                </div>

                {ongoingStep > 1 && (
                    <ChatSupport/>
                )}
                
                <Header />

            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { hwMigration } = state;
    return { 
        ongoingStep: hwMigration?.ongoingStep || 0,
        migrationCompleted: hwMigration?.migrationCompleted || false,
        space: hwMigration?.space || {},
        personalInfo: hwMigration?.step1 || {}
    }
}

export default withStyles(styles)(connect(mapStateToProps)(MigrationFlow));