import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

import {fetchCashDrawerSessions} from '../../redux/actions/cash-drawers/cashDrawers';
import ComputerIcon from '@material-ui/icons/Computer';

import { 
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';
import CashDrawerItem from './CashDrawerItem';

const useStyles = makeStyles (theme => ({
  root: {
    minHeight: 'calc(100vh - 250px)',
    width: '100%'
  },
  blankState: {
    alignItems: 'center',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
  },
  row: {
    paddingTop: 5,
    paddingBottom: 5
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    color: '#ffffff',
    fontSize: "1.8rem",
    height: 40,
    lineHeight: 2.2,
    textAlign: 'center',
    width: 40,
    '&img': {
      display: "flex",
      alignItems: "center",
    }
  },
  role: {
    borderRadius: '4px',
    border: '1px solid #dddddd',
    cursor: 'pointer',
    padding: theme.spacing(1)
  }
}))


const CashDrawerList = (props) => {
  const { t } = props
  const classes = useStyles();
  const [openCashDrawer, setOpenCashDrawer] = React.useState('');
  const [selectedCashDrawer, setSelectedCashDrawer] = React.useState(false);

  const { cashDrawers } = props;

  const handleOpen = (item) => {
    props.dispatch(fetchCashDrawerSessions(item));
    setSelectedCashDrawer(item);
    setOpenCashDrawer(true);
  }

  const handleBack = () => {
    setOpenCashDrawer(false); 
    setSelectedCashDrawer('');
  }

  const handleClose = () => {
    setOpenCashDrawer(false);
    setSelectedCashDrawer('');
    props.handleClose();
  }

  return(
    <List className={classes.root}>

      {/* ------------------------------------------------ */}
      {/* LOADING STATE - Cash Drawers*/}
      {/* ------------------------------------------------ */}
      {props.loadingCashDrawers && 
        <div>
          {t('frontdesk.cashDrawer.loading')}
        </div>
      }

      {/* ------------------------------------------------ */}
      {/* BLANK STATE - Cash Drawers*/}
      {/* ------------------------------------------------ */}
      {!props.loadingCashDrawers && (Object.keys(cashDrawers).length ===0) && 
        <div className={classes.blankState}>
          {t('frontdesk.cashDrawer.addInfo')}
        </div>
      }

      {/* LIST ALL CASH DRAWERS */}
      {/* --------------------------------------------- */}
      {!props.loadingCashDrawers && cashDrawers && Object.keys(cashDrawers).map(item => (
        <ListItem button onClick={() => handleOpen(item)} key={item}>
          <ListItemAvatar>
            <ComputerIcon />
          </ListItemAvatar>
          <ListItemText primary={cashDrawers[item].name} secondary={`${t('frontdesk.cashDrawer.status')}: ${cashDrawers[item].isClosed ? t('frontdesk.cashDrawer.closed') : t('frontdesk.cashDrawer.open')}`} />
        </ListItem>
      ))}

      {openCashDrawer && 
        <CashDrawerItem 
          cashDrawerID={selectedCashDrawer}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      }

    </List>
  )
}

const mapStateToProps = state => ({
  cashDrawers: state.cashDrawers,
  loadingCashDrawers: state.loading.LOAD_CASH_DRAWERS
})

export default withTranslation()(connect(mapStateToProps)(CashDrawerList));