import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { Snackbar, makeStyles, CircularProgress, DialogContent, InputBase, Button, Dialog } from '@material-ui/core'

import Channel from './Channel'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import { loadRatePlans } from '../../redux/actions/rates/ratePlans'
import {
  fetchChannelRoomType,
  createRoomTypeMapping,
  deleteRoomTypeMapping,
  deletePropertyChannelMapping,
  resetChannelMapping,
  createChannelFullRefreshPost,
  createChannelFullRefreshGet
} from '../../redux/actions/channelMapping/channelMapping'
import { fetchChannelRatePlans } from '../../redux/actions/channelMapping/channelRatePlanMapping'
import { resetError } from '../../redux/actions/reservation/editReservation'
import { CHANNEL } from '../../utils/channels'
import { updateViewCCPassword } from '../../redux/actions/channelMapping/myAllocator'
import { CHANNEX_CHANNELS } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialog: {
    overflowY: 'auto'
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: '0 2px 20px #F2F2F2',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  backButton: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      left: '10px'
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 30px)',
    // marginTop: 30,
    width: '100%'
  },
  loadingState: {
    width: '100%',
    height: 'calc(100vh)',
    position: 'absolute',
    top: '0',
    left: '0',
    background: '#F2F2F2',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%'
  },
  dialogContent: {
    background: '#F2F2F2'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 1px 10px #E0E0E0',
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    '& > div': {
      width: '100%'
    }
  },
  textInputContainer: {
    display: 'flex',
    marginBottom: '30px'
  },
  fieldError: {
    border: '1px solid red'
  },
  blankSlate: {
    margin: '50px auto',
    width: '80%',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  blankText: {
      fontSize: '1.4rem',
      marginBottom: 20,
  },
}))

const ChannelMapping = props => {
  const classes = useStyles()
  const { currentSpace, channel, mapping, ratePlans, loading } = props
  const [loader, setLoader] = React.useState(false)
  const [viewCCPassword, setViewCCPassword] = React.useState('')
  const [viewCCUpdateSuccess, setViewCCUpdateSuccess] = React.useState(false)

  React.useEffect(() => {
    return () => {
      setViewCCUpdateSuccess(false)
    }
  }, [])

  React.useEffect(() => {
    if (props.open) {
      props.dispatch(fetchChannelRoomType(currentSpace.propertyID, props.type))
      props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, props.type))
      if (ratePlans.default) {
        props.dispatch(loadRatePlans(currentSpace.propertyID))
      }
    }
  }, [props.open])

  React.useEffect(() => {
    if (loader && !props.loadingRefresh) { setLoader(props.loadingRefresh) }
  }, [props.loadingRefresh])

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetError('FETCH_MY_ALLOCATOR_PROPERTIES'))
    props.dispatch(resetError('CREATE_ROOM_TYPE_MAPPING'))
    props.dispatch(resetError('DELETE_PROPERTY_CHANNEL_MAPPING'))
    props.dispatch(resetError('UPDATE_VIEW_CC_PASSWORD'))
    props.dispatch(resetError('CREATE_CHANNEL_FULL_REFRESH'))
    props.dispatch(resetError('MAP_MY_ALLOCATOR_TO_PROPERTY'))
  }

  const handleRefresh = (itemID) => {
    if (!itemID) {
      setLoader(true)
    }
    if (props.type === CHANNEL.Hostelworld.code) {
      props.dispatch(createChannelFullRefreshPost(currentSpace.propertyID, itemID, props.type))
    } else if (props.type === CHANNEL.myAllocator.code) {
      props.dispatch(createChannelFullRefreshGet(currentSpace.propertyID, itemID, props.type))
    } else if (CHANNEX_CHANNELS.includes(props.type)) {
      let p1, p2, p3
      props.dispatch(fetchChannelRoomType(currentSpace.propertyID, props.type))
        .then(() => {
          p1 = true
          if (p1 && p2 && p3) setLoader(false)
        })
      props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, props.type))
        .then(() => {
          p2 = true
          if (p1 && p2 && p3) setLoader(false)
        })
      props.dispatch(loadRatePlans(currentSpace.propertyID))
        .then(() => {
          p3 = true
          if (p1 && p2 && p3) setLoader(false)
        })
    }
  }

  const handleMapping = (payload) => {
    if (props.type === CHANNEL.Hostelworld.code) {
      payload.hw_roomTypeID = payload.channel_roomTypeID
    } else if (props.type === CHANNEL.myAllocator.code) {
      payload.mya_roomTypeID = payload.channel_roomTypeID
    }
    props.dispatch(createRoomTypeMapping({ ...payload, propertyID: currentSpace.propertyID }, props.type))
      .then(res => {
        if (!CHANNEX_CHANNELS.includes(props.type)) return
        props.dispatch(fetchChannelRoomType(currentSpace.propertyID, props.type))
        props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, props.type))
      })
  }

  const handleDeleteMapping = (itemID) => {
    props.dispatch(deleteRoomTypeMapping(itemID, props.type))
      .then(res => {
        if (!CHANNEX_CHANNELS.includes(props.type)) return
        props.dispatch(fetchChannelRoomType(currentSpace.propertyID, props.type))
        props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, props.type))
      })
  }
  const handleDeletePropertyMapping = () => {
    props.dispatch(deletePropertyChannelMapping(currentSpace.propertyID, props.type))
  }

  const handleClose = (closeAll) => {
    handleCloseError()
    props.dispatch(resetChannelMapping())
    if (closeAll) {
      props.handleCloseAll()
    } else { props.handleClose() }
  }

  const updateViewCCHandler = () => {
    const password = viewCCPassword.trim()
    if (!password) return
    const data = { viewCC: password, propertyID: currentSpace.propertyID }
    const response = props.dispatch(updateViewCCPassword(data))
    response.then(success => {
      if (success) {
        setViewCCPassword('')
        setViewCCUpdateSuccess(true)
        setTimeout(() => setViewCCUpdateSuccess(false), 2000)
      }
    })
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose(true)}
      maxWidth='md'
      fullWidth
      classes={{ paperFullWidth: classes.dialogPaper }}
      fullScreen={window.innerWidth < 901}
    >

      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <BackIcon className={classes.backButton} onClick={() => handleClose(false)} />
          <div className={classes.header}>{CHANNEL[props.type] ? CHANNEL[props.type].name : 'Channel'}</div>
          <CloseIcon className={classes.closeIcon} onClick={() => handleClose(true)} />
        </div>

        <DialogContent className={classes.dialogContent}>
          {(loading || loader) && (
            <div className={classes.loadingState}>
              <div className={classes.loader}>
                <CircularProgress />
                {props.type === CHANNEL.Expedia.code && <div>Fetching Data from Expedia...</div>}
              </div>
            </div>
          )}

          {
          /* {props.type === CHANNEL.myAllocator.code &&
            <MyAllocator currentSpace={currentSpace} mappedChannel={channel.myAllocator ? true : false} />
          } */
          }

          {!(loading || loader) && (!channel[CHANNEL[props.type].code] && props.type === CHANNEL.Hostelworld.code && 
            <div className={classes.blankSlate}>
              <div className={classes.blankText}>No room types on {CHANNEL[props.type].name}, please create new room types</div>
            </div>
          )}

          {props.type === CHANNEL.myAllocator.code &&
            <div className={classes.row}>
              <div className={classes.label}>Credit Card Password</div>
              <div className={classes.textInputContainer}>
                <div className={clsx(classes.field
                  // { [classes.fieldError]: error.token }
                )}
                >
                  <InputBase
                    id='viewCCPassword'
                    name='viewCCPassword'
                    type='text'
                    value={viewCCPassword}
                    onChange={(e) => {
                      setViewCCPassword(e.target.value)
                      // setError({ token: false })
                    }}
                  />
                </div>
                <Button
                  variant='outlined'
                  onClick={updateViewCCHandler}
                >
                  Edit
                </Button>
              </div>
            </div>}

          <div className={classes.root}>
            {!(loading || loader) && Object.keys(channel).map((key, index) =>
              (channel[key] && <Channel
                key={index}
                channelType={key}
                item={channel[key]}
                roomTypes={currentSpace.roomTypes}
                mapping={mapping[key]}
                handleRefresh={handleRefresh}
                handleMapping={handleMapping}
                handleDeleteMapping={handleDeleteMapping}
                handleDeletePropertyMapping={handleDeletePropertyMapping}
              />)
            )}
          </div>

        </DialogContent>
      </div>
      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id='message-id'>{props.errors}</span>}
        />
      )}

      {/* ------------- Snackbar myallocator viewCC update success -------------- */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={viewCCUpdateSuccess}
        onClose={() => setViewCCUpdateSuccess(false)}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>View credit card password updated successfully.</span>}
      />
    </Dialog>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  ratePlans: state.ratePlans,
  channel: state.channelMapping.channel || {},
  mapping: state.channelMapping.mapping || {},

  loadingRefresh: state.loading.CREATE_CHANNEL_FULL_REFRESH,
  loading: state.loading.FETCH_CHANNEL_ROOM_TYPE ||
    state.loading.FETCH_MY_ALLOCATOR_PROPERTIES ||
    state.loading.DELETE_PROPERTY_CHANNEL_MAPPING ||
    state.loading.MAP_MY_ALLOCATOR_TO_PROPERTY ||
    state.loading.UPDATE_VIEW_CC_PASSWORD,
  errors: state.errors.FETCH_MY_ALLOCATOR_PROPERTIES ||
    state.errors.CREATE_CHANNEL_FULL_REFRESH ||
    state.errors.CREATE_ROOM_TYPE_MAPPING ||
    state.errors.DELETE_PROPERTY_CHANNEL_MAPPING ||
    state.errors.MAP_MY_ALLOCATOR_TO_PROPERTY ||
    state.errors.UPDATE_VIEW_CC_PASSWORD
})

export default connect(mapStateToProps)(ChannelMapping)
