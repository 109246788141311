import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import Item from '../../common/Item';
// import { ICONS } from '../../../utils/imageUrls';

import { Grid } from '@material-ui/core';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 20,
    height: 20,
    marginRight: 20
  },
  section: {
    margin: '30px 0 20px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
}));

const SelectRoomTypes = forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, roomTypes, prefilledData } = props;
  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    if(prefilledData && prefilledData.length) {
      setSelectedItem([ ...prefilledData])
    }
  },[prefilledData]);

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return selectedItems;
    }
  }))


  const onCountChange = (count, itemID) => {
    if (itemID && count==1) {
      setSelectedItems([ ...selectedItems, itemID])
    } else if (itemID && count==0) {
      let array = [...selectedItems];
      const index = array.indexOf(itemID);
      if (index > -1) {
        array.splice(index, 1);
      }
      setSelectedItems(array);
    }
  }

  const fetchItemListView = (value, image) => {

    return currentSpace && currentSpace.roomTypes &&
      Object.values(currentSpace.roomTypes).map((id, index) => {
        let itemData = { ...roomTypes[id] };
        
        if (!itemData.isActive) return null;

        let roomTypeImage = itemData.images && itemData.images.length ? (itemData.images[itemData.images.length - 1].imagelink || image) : image;
        itemData.image = roomTypeImage;
        if (itemData.dormOrPrivate !== value) return null;
        return (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Item
              itemID={itemData._id}
              data={itemData}
              onCountChange={onCountChange}
              multiple={false}
              prefilledCount={0}
              selected={selectedItems.includes(itemData._id)} />
          </Grid>
        )
      })
  }

  return (
    <>
      <Grid container ref={ref}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={IMAGES.ICONS.darkBed} />
            <span className={classes.title}>Private Rooms</span>
          </div>
        </Grid>
        {fetchItemListView('private', IMAGES.ICONS.privateRoomType)}
      </Grid>
      <Grid container ref={ref} className={classes.rooms}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={IMAGES.ICONS.darkHostel} />
            <span className={classes.title}>Shared Rooms</span>
          </div>
        </Grid>
          {fetchItemListView('dorm', IMAGES.ICONS.dormRoomType)}
      </Grid>
    </>
  )
})

export default (SelectRoomTypes);