import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import {
  setCurrentModule,
  setMobileSidebarOpenValue,
} from '../../redux/actions/dashboard/dashboard';
import { fetchLiveReports } from '../../redux/actions/dashboard/live';
import { setOnboarding } from '../../redux/actions/landing/uiConfig';
import { clearSearch } from '../../redux/actions/search/search';
import { MENU } from '../../utils/layout';
import { fetchProperty } from '../../redux/actions/dashboard/property';

import SidebarMobile from '../header/SidebarMoblie';
import { isFeatureEnabled } from '../../utils/utility';

const styles = (theme) => ({
  root: {
    display: 'flex',
    background: '#F8F9FB',
    marginTop: '70px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  container: {
    alignItems: 'center',
    background: '#F8F9Fb',
    display: 'flex',
    flexDirection: 'column',
    // height: '100vh',
    justifyContent: 'flex-start',
    marginLeft: 10,
    marginTop: 20,
  },
  linkContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  linkScroll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    boxSizing: 'content-box',
    '& img': {
      cursor: 'pointer',
      height: 28,
      width: 28,
    },
  },
  selectedImage: {
    filter: 'none',
  },
  home: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '80px',
    '& img': {
      height: 40,
      width: 40,
    },
  },
  homeIcon: {
    cursor: 'pointer',
  },
  button: {
    marginBottom: 24,
    padding: '15px 10px',
    marginLeft: '10px',
    paddingRight: 20,
    paddingLeft: 20,
    '& img': {
      borderRadius: '00px',
      height: 25,
      opacity: 0.5,
      width: 25,
    },
  },
  selected: {
    '& img': {
      opacity: 1,
    },
  },
  buttonActive: {
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 25,
    borderTopLeftRadius: 25,
  },

  subMenuContainer: {
    minWidth: '250px',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    padding: '10px',
    paddingTop: '10px',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
    },
  },
  subMenuTitle: {
    margin: '10px 0px',
    fontSize: '1.3rem',
    fontWeight: 600,
    paddingLeft: '15px',
  },
  subMenuTab: {
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: '5px',
    padding: '10px 20px',
    width: '100%',
    '&:hover, &.isActive': {
      // fontWeight: 500,
      color: '#fff',
      backgroundColor: '#6fa7d6',
    },
  },
});

const Sidebar = (props) => {
  const {
    classes,
    currentModule,
    currentSubModule,
    mobileSidebarOpen,
    onboarding,
    dispatch,
    currentSpaceID,
    currentSpace,
    t,
  } = props;
  const selectedMenuData = MENU[currentModule] || MENU.live;
  const subMenuList = selectedMenuData.submenu;
  const activeSubmenu = currentSubModule
    ? currentSubModule
    : selectedMenuData.defaultSubmenu;

  const changeModule = (moduleID) => {
    if (moduleID === 'live') {
      dispatch(fetchLiveReports(currentSpaceID, currentSpace.propertyID));
    }
    if (currentModule !== moduleID && moduleID === 'hostel') {
      dispatch(fetchProperty(currentSpace.propertyID));
    }
    dispatch(setCurrentModule(moduleID));
    dispatch(clearSearch());
    dispatch(setOnboarding(false));
    window.history.replaceState({}, document.title, `/${moduleID}`);
  };

  const changeSubModule = (subMenuID) => {
    dispatch(setCurrentModule(currentModule, subMenuID));
    window.history.replaceState(
      {},
      document.title,
      `/${currentModule}/${subMenuID}`
    );
  };

  const setMobileMenuModule = (moduleID, subMenuID) => {
    dispatch(setCurrentModule(moduleID, subMenuID));
    dispatch(clearSearch());
    dispatch(setOnboarding(false));
    const url = subMenuID ? `/${moduleID}/${subMenuID}` : `/${moduleID}`;
    window.history.replaceState({}, document.title, url);
  };

  const handleMobileDrawerToggle = () => {
    dispatch(setMobileSidebarOpenValue(!mobileSidebarOpen));
  };

  const accessible = (menuID) => {
    const disabledMenus = [MENU.experience.id, MENU.pos.id, MENU.linkups.id]
    if (disabledMenus.includes(menuID)) {
      return false;
    }

    return true;
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        {!onboarding && (
          <div className={classes.container}>
            <div className={clsx(classes.linkContainer, 'mainScrollHeight')}>
              <div className={classes.linkScroll}>
                {Object.keys(MENU).map(
                  (moduleID) =>
                    accessible(moduleID) && (
                      <div
                        key={moduleID}
                        data-intercom-target={moduleID}
                        className={clsx(
                          classes.button,
                          {
                            [classes.buttonActive]:
                              moduleID === currentModule &&
                              moduleID !== 'live' &&
                              !onboarding,
                          },
                          {
                            [classes.selected]:
                              moduleID === currentModule && !onboarding,
                          }
                        )}
                        onClick={() => changeModule(moduleID)}
                      >
                        <img
                          src={
                            moduleID === currentModule
                              ? MENU[moduleID].selectedIcon
                              : MENU[moduleID].icon
                          }
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}

        {selectedMenuData.hasSubMenu &&
          accessible(selectedMenuData.id) &&
          !onboarding && (
            <div className={classes.subMenuContainer}>
              <div className={classes.subMenuTitle}>
                {selectedMenuData.name}
              </div>

              {Object.keys(subMenuList).map((menuID, menuIndex) => (
                <div
                  key={`${selectedMenuData.id}_${menuIndex}`}
                  className={clsx(classes.subMenuTab, {
                    isActive: menuID == activeSubmenu,
                  })}
                  style={{
                    background:
                      menuID == activeSubmenu
                        ? selectedMenuData.color
                        : '#FFFFFF',
                    color: menuID == activeSubmenu ? '#FFFFFF' : '#666666',
                  }}
                  data-intercom-target={subMenuList[menuID].name}
                  onClick={() => changeSubModule(menuID)}
                >
                  {t(`subMenu.${subMenuList[menuID].id}`)}
                </div>
              ))}
            </div>
          )}
      </div>

      <SidebarMobile
        mobileSidebarOpen={mobileSidebarOpen}
        handleDrawerToggle={handleMobileDrawerToggle}
        setMobileMenuModule={setMobileMenuModule}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, uiConfig, spaces } = state;
  return {
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule,
    mobileSidebarOpen: dashboard.mobileSidebarOpen,
    onboarding: uiConfig.onboarding,
    currentSpaceID: dashboard.currentSpace,
    currentSpace: spaces[dashboard.currentSpace],
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Sidebar))
);
