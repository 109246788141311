import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import clsx from 'clsx';

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  Typography
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmForm from '../common/ConfirmForm';

const styles = theme => ({
  categoryOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px'
  },
  categoryMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  moreIcon: {
    fontSize: '1.8rem'
  },
  primaryHolder: {
    display: 'flex',
    alignItems: 'center'
  },
  bubble: {
    display: 'inline-block',
    marginRight: 10,
    borderRadius: '50%',
    height: 7,
    width: 7
  },
  grey: {
    background: '#bdbdbd'
  },
  green: {
    background: 'green'
  },
  secondaryHolder: {
    marginLeft: 17
  }
})

const EmailTemplateListItem = (props) => {

  const { classes, emailTemplate, t } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false)

  const handleClickDelete = () => {
    setAnchorEl(null);
    setConfirmOpen(true);
  }

  const handleConfirmDelete = () => {
    props.handleDeleteEmail(emailTemplate._id);
    setConfirmOpen(false);
  }

  const handleConfirmFormClose = () => {
    setConfirmOpen(false);
  }

  return (
    <>
      <ListItem button onClick={() => props.handleOpenEmail(emailTemplate._id)}>
        <ListItemText
          primary={
            <React.Fragment>
              <div className={classes.primaryHolder}>
                {!emailTemplate.isActive ?
                  <span className={clsx(classes.grey, classes.bubble)}></span> :
                  <span className={clsx(classes.green, classes.bubble)}></span>
                }
                {emailTemplate.name}
              </div>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <span className={classes.secondaryHolder}>
                {`${t('automate.email.subject')}: ${emailTemplate.subject}`}
              </span>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={classes.categoryOptions}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHorizIcon className={classes.moreIcon} />
          </IconButton>
          <Menu
            id="email-template-menu"
            anchorEl={anchorEl}
            className={classes.categoryMenu}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{ padding: 0 }}
            getContentAnchorEl={null}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <ListItem button onClick={() => handleClickDelete()}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="body2">{t('actions.delete')}</Typography>
            </ListItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <div style={{ paddingLeft: 32 }}>
        <ConfirmForm
          title={t('automate.email.deleteTemplate')}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onClose={handleConfirmFormClose}
          onConfirm={handleConfirmDelete}
        >
          {t('automate.email.deleteTemplateConf')}
        </ConfirmForm>
      </div>
    </>
  )
}

export default withTranslation()(withStyles(styles)(EmailTemplateListItem));