import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import clsx from 'clsx';

import UpsellItem from './UpsellItem';

import { Button, makeStyles } from '@material-ui/core';
import { UPSELL_ITEMS } from '../../utils/upsellItems';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 30px)',
    marginTop: 30,
    width: '100%'
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '1.3rem',
    marginBottom: 10,
  },
  buttonContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  next: {
    borderRadius: '25px',
    fontWeight: 600,
    fontSize: '1.5rem',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))


const Upsell = (props) => {
  const [upsellList, setUpsellList] = useState(UPSELL_ITEMS);

  const upsells = useSelector(state => state.upsell);

  const property = useSelector(state => state.property);

  const classes = useStyles();

  const handleFinishSignup = () => {
    props.handleNext();
  }

  useEffect(() => {
    const upsellsToArray = Object.values(upsells);
    const filteredUpsells = upsellsToArray.filter(upsell => upsell.propertyID == property._id)
    const addIconToFilteredUpsells = filteredUpsells.map( item => {
      const findIcon = UPSELL_ITEMS.find(i => i.iconID == item.iconID);
      if (findIcon) {
        return { ...item, icon: findIcon.icon };
      }
      return item
    })
    const newUpsellList = upsellList.filter(up => !addIconToFilteredUpsells.find(add => add.name == up.name))
    let combinedUpsells = [ ...newUpsellList, ...addIconToFilteredUpsells ];
    setUpsellList(combinedUpsells)
  }, [])

  return (
    <div className={classes.root}>

      <div className={classes.row}>
        <div className={classes.title}>
          Select the items to sell at your frontdesk
        </div>
      </div>

      {upsellList.map((item, index) => (
        <UpsellItem key={index} item={item} index={index} />
      ))}

      <div className={clsx(classes.row, classes.buttonContainer)}>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleFinishSignup}>
          Launch Frontdesk
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps)(Upsell);