import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import AddButton from '../common/AddButton';
import ProductItem from './ProductItem';
import NewProduct from './new_product';

import { resetError } from '../../redux/actions/reservation/editReservation';

import { Grid, Hidden, Typography } from '@material-ui/core';
import GetStarted from './GetStarted';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    width: '100%',
    // marginBottom: 30
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    // boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 40,
    padding: theme.spacing(3),
    paddingBottom: 0,
    width: 'calc(100% - 48px)'
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  header: {
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    }
  },
  permissions: {
    marginTop: '-12px'
  },
  addButton: {
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#666666',
    fontSize: '3rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      top: '26px',
      left: '15px'
    },
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  listContainer: {
    background: '#FFFFFF',
    overflowY: 'visible',
  },
  listSection: {
    margin: theme.spacing(3)
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    height: 300,
    justifyContent: 'center'
  },
  label: {
    color: '#999999',
    fontSize: '1.2rem',
  },
  headerRow: {
    marginBottom: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
}))

const ProductList = (props) => {

  const classes = useStyles();
  const { currentSpace, loading, products, productCategories } = props;
  const [newProduct, setNewProduct] = React.useState(false)

  const handleCancel = () => {
    setNewProduct(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  const handleAddProduct = () => {
    setNewProduct(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }

  return (
    <div className={classes.root}>

      {/* ------------------------------------------------ */}
      {/* BLANK STATE - No Product Categories */}
      {/* ------------------------------------------------ */}
      {currentSpace && (!currentSpace.productCategories || currentSpace.productCategories.length === 0) ?
        <GetStarted handleClick={handleAddProduct} title={'Get Started'}/> : <>

          {/* ------------------------------------------------ */}
          {/* HEADER */}
          {/* ------------------------------------------------ */}
          <div className={classes.dialogTitle}>
            <div className={classes.titleBox}>
              <div className={classes.header}>
                <Typography component="h4" variant="h6">Products</Typography>
              </div>

              {!loading &&
                <div className={classes.addButtonContainer}>
                  <div className={classes.addButton} data-intercom-target={'Add Product'}>
                    <AddButton
                      color={"#996666"}
                      title={'Add Product'}
                      handleClick={handleAddProduct}
                    />
                  </div>
                </div>
              }
            </div>
          </div>

          <div className={classes.listContainer}>

            <div className={classes.listSection}>

              {/* ------------------------------------------------ */}
              {/* LOADING STATE - Products & Product Category*/}
              {/* ------------------------------------------------ */}
              {loading &&
                <div className={classes.blankSlate}>
                  <CircularProgress />
                </div>
              }

              {/* ------------------------------------------------ */}
              {/* BLANK STATE - No Product Categories */}
              {/* ------------------------------------------------ */}
              {!loading && currentSpace && (!currentSpace.productCategories || currentSpace.productCategories.length === 0) &&
                <div className={classes.blankSlate}>
                  Get Started
              </div>
              }


              <Hidden xsDown>{!loading && currentSpace && currentSpace.productCategories && currentSpace.productCategories.length > 0 &&
                <Grid container className={classes.headerRow}>
                  <Grid item xs={2} md={4}>
                    <div className={classes.label}>Name</div>
                  </Grid>
                  <Grid item xs={10} md={8}>
                    <Grid container alignItems="center" style={{ height: '100%' }}>
                      <Grid item xs={6} md={4}>
                        <div className={clsx(classes.label, classes.center)}>Category</div>
                      </Grid>
                      <Grid item xs={4} md={3}>
                        <div className={clsx(classes.label, classes.center)}>Price</div>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <div className={clsx(classes.label, classes.center)}>Tax</div>
                      </Grid>
                      <Grid item xs={4} md={1}>
                        <div className={clsx(classes.label, classes.center)}>Edit</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }</Hidden>

              {/* ------------------------------------------------ */}
              {/* Loop through product categories */}
              {/* ------------------------------------------------ */}
              {!loading && currentSpace && currentSpace.productCategories && 
                currentSpace.productCategories
                .filter(item => productCategories[item] && productCategories[item].isDeleted !== true)
                .sort()
                .map(productCategoryID => (
                  productCategories[productCategoryID]?.products && 
                  productCategories[productCategoryID].products
                  .filter(item => products[item] && products[item].isDeleted !== true)
                  .map(productID => (
                    <ProductItem
                      key={productID}
                      productCategoryID={productCategoryID}
                      productID={productID}
                    />
                  ))
              ))}


            </div>
          </div>
        </>}
      {/* ------------------------------------------------ */}
      {/* Create Product */}
      {/* ------------------------------------------------ */}
      {newProduct &&
          <NewProduct
            open={newProduct}
            handleCancel={handleCancel}
          />
      }

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  taxes: state.taxes,
  productCategories: state.productCategories,
  products: state.products,
  loading: state.loading.LOAD_PRODUCT_CATEGORIES || state.loading.LOAD_PRODUCTS
})

export default connect(mapStateToProps)(ProductList);