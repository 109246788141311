import React from "react";
import { withStyles, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import BookingTab from "./BookingTab";
import GuestTab from "./GuestTab";
import NotesTab from "./NotesTab";
import ActivityTab from "./ActivityTab";
import SummaryTab from "./SummaryTab";
import { deleteBooking, fetchBookingDetails, updateBooking } from "../../../redux/actions/experiences/booking";
import { resetReducerState } from "../../../redux/actions/common/common";
import { humanise } from "../../../utils/utility";

const styles = theme => ({
  dialog: {
    overflowY: 'auto'
  },
  dialogPaper: {
    maxHeight: 'unset',
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#f8f9fb',
    justifyContent: 'center',
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 15,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  tabsContainer: {
    padding: '0 10px',
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'unset',
      justifyContent: 'left',
      fontWeight: 600
    },
    '& .MuiTab-textColorInherit': {
      paddingLeft: 0,
      marginRight: 10,
    },
    '& .MuiTabs-indicator': {
      background: "#6ea7d6",
      height: "5px",
      marginTop: "-5px"
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-scroller': {
        whiteSpace: 'unset',
        display: 'block',
      }
    },
  },
  tabPanel: {
    borderRadius: '15px',
    flexGrow: 1,
    width: '70%',
    '& > .MuiBox-root': {
      height: 'calc(100vh - 150px)',
      padding: 10,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10,
      overflowY: 'hidden'
    }
  },
  extraDetails: {
    height: 'calc(100% - 20px)',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#f8f9fb',
    overflowY: 'auto',
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  actionIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    '& span': {
      marginRight: 20
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
    }
  },
  bookingContainer: {
    display: 'flex'
  },
  notification: {
    padding: '5px 10px',
    backgroundColor: '#6ea7d6',
    color: '#fff',
    fontWeight: 600,
    marginLeft: 5,
    borderRadius: 5
  },
  summery: {
    width: '30%',
    height: 'calc(100vh - 150px)',
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

const EditBookingMask = (props) => {

  const { classes, open, currentSpace, booking, cancelSuccess,updateSuccess, bookingID, loading } = props;
  const [value, setValue] = React.useState("reservation");
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const notesCount = booking?.notes ? booking.notes.length : 0;

  React.useEffect(() => {
    if(bookingID){
      props.dispatch(fetchBookingDetails(bookingID))
    }
  }, [bookingID]);
  
  React.useEffect(() => {
    if (cancelSuccess) {
      props.dispatch(resetReducerState('DELETE_BOOKING'));
      props.dispatch(resetReducerState('FETCH_BOOKING_DETAILS'));
      props.refreshCalendar();
      props.handleClose()
    }
  }, [cancelSuccess]);

  React.useEffect(() => {
    if (updateSuccess) {
      props.dispatch(resetReducerState('UPDATE_BOOKING'));
      props.refreshCalendar();
    }
  }, [updateSuccess]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetReducerState('UPDATE_BOOKING'));
  }

  const deleteBookingMask = () => {
    props.dispatch(deleteBooking(currentSpace.propertyID, bookingID))
  }

  const editBookingMask = (payload) => {
    props.dispatch(updateBooking(bookingID, currentSpace.propertyID, payload))
  }

  return (
    <Dialog
      open={open}
      classes={{ paperFullWidth: classes.dialogPaper }}
      onClose={props.handleClose}
      fullWidth={true}
      fullScreen={true}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography variant="h4" className={classes.title}><span>{humanise(booking?.contact?.firstName, booking?.contact?.lastName) || 'Booking details'}</span></Typography>
          <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        </div>


        {/* VERTICAL booking TABS */}
        {/* ---------------------------------------------------------------------- */}
        <Tabs
          orientation="horizontal"
          aria-label="Vertical tabs example"
          className={classes.tabsContainer}
          value={value}
          variant="scrollable"
          scrollButtons={windowWidth > 901 ? "auto" : "on"}
          onChange={handleChange}
        >
          <Tab value="reservation" label="Reservation" {...a11yProps("reservation")} />
          <Tab value="guests" label="Guest" {...a11yProps("guests")} />
          <Tab value="notes" label={<span>Notes {notesCount ? <span className={classes.notification}>{notesCount}</span> : ''}</span>} {...a11yProps("notes")} />
          <Tab value="logs" label="Logs" {...a11yProps("logs")} />
        </Tabs>

        <div className={classes.bookingContainer}>
          {/* TAB CONTENT */}
          {/* ---------------------------------------------------------------------- */}

          {/* Booking */}
          {/* -------------------------------------- */}
          <TabPanel className={classes.tabPanel} value={value} index={"reservation"}>
            <div className={classes.extraDetails}>
              {!loading && (
                <BookingTab
                  booking={booking}
                  bookingID={bookingID}
                  editBookingMask={editBookingMask}
                  deleteBookingMask={deleteBookingMask}
                />
              ) }
              {loading && <div className={classes.blankSlate}>Loading booking details..</div>}
            </div>
          </TabPanel>

          {/* Guests */}
          {/* -------------------------------------- */}
          <TabPanel className={classes.tabPanel} value={value} index={"guests"}>
            <div className={classes.extraDetails}>
              {!loading && <GuestTab booking={booking} bookingID={bookingID} currentSpace={currentSpace}/> }
              {loading && <div className={classes.blankSlate}>Loading booking details..</div>}
            </div>
          </TabPanel>

          {/* Notes */}
          {/* -------------------------------------- */}
          <TabPanel className={classes.tabPanel} value={value} index={"notes"}>
            <div className={classes.extraDetails}>
              {!loading && <NotesTab /> }
              {loading && <div className={classes.blankSlate}>Loading booking details..</div>}
            </div>
          </TabPanel>

          {/* Activity */}
          {/* -------------------------------------- */}
          <TabPanel className={classes.tabPanel} value={value} index={"logs"}>
            <div className={classes.extraDetails}>
              {!loading && <ActivityTab /> }
              {loading && <div className={classes.blankSlate}>Loading booking details..</div>}
            </div>
          </TabPanel>

          <div className={classes.summery}>
            {!loading && <SummaryTab booking={booking}/>}
          </div>
        </div>


      </div>

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleCloseError()}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </Dialog>
  )
}

const mapStateToProps = state => ({
  loading: state.loading.FETCH_BOOKING_DETAILS,
  booking: state.experiences?.bookingDetails || {},
  updateSuccess: state.success.UPDATE_BOOKING,
  cancelSuccess: state.success.DELETE_BOOKING,
  errors: state.errors.UPDATE_BOOKING,
})

export default withStyles(styles)(connect(mapStateToProps)(EditBookingMask));