import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import Purchase from "./Purchase";

import {
  fetchProperty,
  editProperty,
} from "../../redux/actions/dashboard/property";
import {
  quickBooksAuth,
  addQBInfo,
  updateQBInfo,
  fetchQBInfo,
  fetchQBAccounts,
} from "../../redux/actions/quickBooks/quickBooks";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "react-select";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import IMAGES from "../../constants/images";
import { get, indexOf } from "lodash";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    minHeight: "calc(100vh - 250px)",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  titleRow: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    lineHeight: 1.3,
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  fieldContainer: {
    border: "1px solid #dddddd",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  altButton: {
    background: "#ffffff",
    borderRadius: 25,
    color: "#000000",
    cursor: "pointer",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
    border: "1px solid #dddddd",
    boxShadow: "0 1px 10px #cdcdcd",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "1px",
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const QuickBooks = (props) => {
  const { t } = props;
  const isMediumScreen = useMediaQuery({
    query: "(min-device-width: 768px)",
  });
  const uuid = () => {
    return Math.floor(Math.random() * 1000000);
  };
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    property,
    loadingProperty,
    quickBooks,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const [options, setOptions] = React.useState([
    { paymentMethodID: "", transactionModeID: "", id: uuid() },
  ]);
  const [saveToggle, setSaveToggle] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [message, setMessage] = React.useState("");
  // let selectedMode = []
  // let selectedMethod = []

  const [expanded, setExpanded] = React.useState(false);
  const [isPayment, setPayment] = React.useState(true);
  // ********************************************************** //
  // *** First Load *** //
  // ********************************************************** //
  useEffect(() => {
    props.dispatch(fetchQBInfo(currentSpace.propertyID));
    if (property.default) {
      props.dispatch(fetchProperty(currentSpace.propertyID));
    }
  }, []);

  // ********************************************************** //
  // *** Fetching AuthRedirect URL incase QBO is not set up *** //
  // ********************************************************** //
  useEffect(() => {
    if (quickBooks.payment.default) {
      props.dispatch(quickBooksAuth());
    }
  }, [quickBooks.payment]);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "QBO" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  // *********************************************************************** //
  // * adding space to select new mode and method incase if mapping exists * //
  // *********************************************************************** //
  if (!update) {
    if (quickBooks.payment.selectedPaymentMethods) {
      setUpdate(true);
      let newOptions = [...quickBooks.payment.selectedPaymentMethods];
      newOptions.forEach((x, i) => {
        x.id = uuid();
      });
      setOptions([
        ...newOptions,
        { paymentMethodID: "", transactionModeID: "", id: uuid() },
      ]);
    }
  }

  const handleCloseModal = () => {
    props.dispatch(fetchQBInfo(currentSpace.propertyID));
    handleClose();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSwitchToggle = (value) => {
    setToggle(value);
    if (!value)
      props.dispatch(
        editProperty(currentSpace.propertyID, { disableAppFeature: "QBO" })
      );
    else
      props.dispatch(
        editProperty(currentSpace.propertyID, { appFeature: "QBO" })
      );
  };

  const handleSave = () => {
    setMessage("");
    let saveData = [];
    let checkData = [];

    //removes incomplete transMode and paymentMethod
    for (var x = 0; x < options.length; x++) {
      if (
        options[x].transactionModeID.length > 0 &&
        options[x].paymentMethodID.length > 0
      ) {
        let transactionModeID = options[x].transactionModeID,
          paymentMethodID = options[x].paymentMethodID;
        saveData.push({ transactionModeID, paymentMethodID });
      }
    }

    //to throw message incase there are duplicates
    checkData = saveData.filter(
      (val, index, self) =>
        index ===
        self.findIndex(
          (v) =>
            v.transactionModeID === val.transactionModeID ||
            v.paymentMethodID === val.paymentMethodID
        )
    );
    if (checkData.length === saveData.length) {
      const data = {
        paymentMethods: saveData,
        propertyID: currentSpace.propertyID,
      };

      //snackbar message variation
      if (!update) {
        props.dispatch(addQBInfo(data)).then((res) => {
          if (res && res.status === 200) {
            setMessage("Your Quickbooks settings saved!");
            props.dispatch(fetchQBAccounts(currentSpace.propertyID));
            setOpenSnackbar(true);
          } else {
            setMessage("Error in updating the information. Try again!");
            setOpenSnackbar(true);
          }
        });
      } else {
        props.dispatch(updateQBInfo(data)).then((res) => {
          if (res && res.status === 200) {
            setMessage("Your Quickbooks settings updated!");
            props.dispatch(fetchQBAccounts(currentSpace.propertyID));
            setOpenSnackbar(true);
          } else {
            setMessage("Error in saving the information. Try again!");
            setOpenSnackbar(true);
          }
        });
      }
    } else {
      setMessage("You seem to have duplicate values. Try again!");
      setOpenSnackbar(true);
    }
  };

  // ************************************************ //
  // * Refreshing the paymentMethods and transModes * //
  // ************************************************ //
  const handleRefresh = () => {
    props.dispatch(fetchQBInfo(currentSpace.propertyID));
    setMessage("Refreshing Payment Methods ...");
    setOpenSnackbar(true);
  };

  const handleAccordian = () => {
    setPayment(!isPayment);
  };

  // ************************************************ //
  // *  TransactionMode  Selection* //
  // ************************************************ //

  const handlePaymentsMapping = (selectedOption, index) => {
    if (selectedOption.value === "Select...") {
      selectedOption.value = "";
    }
    let updatedOptions = [...options];
    updatedOptions.forEach((x, i) => {
      if (x.id === +index) {
        x.transactionModeID = selectedOption.value;
        if (x.paymentMethodID.length > 0 && i == options.length - 1) {
          let id = uuid();
          updatedOptions.push({
            paymentMethodID: "",
            transactionModeID: "",
            id,
          });
        }
      }
    });
    setOptions(updatedOptions);
    setSaveToggle(true);
  };

  // ************************************************ //
  // *  PaymentMethod  Selection* //
  // ************************************************ //

  const handleMappingRowPayment = (selectedOption, index) => {
    let updatedOptions = [...options];
    updatedOptions.forEach((x, i) => {
      if (x.id === +index) {
        x.paymentMethodID = selectedOption.value;
        if (x.transactionModeID.length > 0 && i == options.length - 1) {
          let id = uuid();
          updatedOptions.push({
            paymentMethodID: "",
            transactionModeID: "",
            id,
          });
        }
      }
    });
    setOptions(updatedOptions);
    setSaveToggle(true);
  };

  // ************************************************ //
  // * Delete Rows for Mapping* //
  // ************************************************ //

  const handleDeleteMappingRow = (index) => {
    let updatedOptions = [...options];
    updatedOptions = updatedOptions.filter((x) => x.id !== +index);
    setOptions(updatedOptions);
    setSaveToggle(true);
  };

  // ************************************************ //
  // * mapping Rows from from previous selections* //
  // ************************************************ //

  const getSelectedValue = (value, type) => {
    let result = null;
    if (type == "method") {
      quickBooks.payment.paymentMethod.forEach((pm) => {
        options.forEach((op) => {
          if (op.id === value) {
            if (op.paymentMethodID == pm.value) result = pm;
          }
        });
      });
    }

    if (type == "mode") {
      quickBooks.payment.transactionMode.forEach((pm) => {
        options.forEach((op) => {
          if (op.id === value) {
            if (op.transactionModeID == pm.value) result = pm;
          }
        });
      });
    }

    return result;
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>QuickBooks</div>
          <AccessHeaderAction moduleID={MODULE.QUICKBOOKS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        {loadingProperty && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        <div className={classes.listContainer}>
          {!loadingProperty && (
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.quickBooks.definition")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <div className={classes.title}>QuickBooks</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={() => handleSwitchToggle(false)}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={() => handleSwitchToggle(true)}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {toggle && (
                <div>
                  {quickBooks.payment.redirectURI && (
                    <div className={classes.row}>
                      <div>
                        <div className={classes.title}>
                          {t("automate.quickBooks.authenticateAccount")}
                        </div>
                        <div className={classes.label}>
                          {t("automate.quickBooks.authenticateMessage")}
                        </div>
                      </div>
                      {quickBooks.payment.redirectURI && (
                        <Grid container alignItems="center">
                          <Grid item xs={3} sm={4}></Grid>
                          <Grid item xs={6} sm={4}>
                            <div className={classes.buttonsContainer}>
                              <a href={quickBooks.payment.redirectURI}>
                                <span className={classes.altButton}>
                                  {t("automate.quickBooks.authenticate")}
                                </span>
                              </a>
                            </div>
                          </Grid>
                          <Grid item xs={3} sm={4}></Grid>
                        </Grid>
                      )}
                    </div>
                  )}
                  {!quickBooks.payment.redirectURI && (
                    <div>
                      <Accordion
                        expanded={isPayment}
                        onChange={() => handleAccordian()}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <span className={classes.title}>
                            {t("automate.quickBooks.payments.name")}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {quickBooks.payment.selectedPaymentMethods && (
                              <div className={classes.linkContainer}>
                                <div className={classes.addContainer}>
                                  {t(
                                    "automate.quickBooks.payments.updateHeading"
                                  )}
                                </div>
                                <div className={classes.saveContainer}>
                                  <RefreshIcon
                                    onClick={() => handleRefresh()}
                                    className={classes.actionIcon}
                                  />
                                </div>
                              </div>
                            )}
                            {!quickBooks.payment.redirectURI &&
                              !quickBooks.payment.selectedPaymentMethods && (
                                <div>
                                  <div className={classes.linkContainer}>
                                    <div className={classes.addContainer}>
                                      {t(
                                        "automate.quickBooks.payments.configureHeading"
                                      )}
                                    </div>
                                    <div className={classes.saveContainer}>
                                      <RefreshIcon
                                        onClick={() => handleRefresh()}
                                        className={classes.actionIcon}
                                      />
                                    </div>
                                  </div>
                                  <div className={classes.label}>
                                    {t(
                                      "automate.quickBooks.payments.headingLabel"
                                    )}
                                  </div>
                                </div>
                              )}
                            {
                              <div className={classes.label}>
                                {t("automate.quickBooks.payments.definition")}
                              </div>
                            }
                            {isMediumScreen &&
                              !quickBooks.payment.redirectURI && (
                                <div className={classes.row}>
                                  <br />
                                  <Grid container alignItems="center">
                                    <Grid item sm={5}>
                                      <div
                                        className={clsx(
                                          classes.title,
                                          classes.titleRow
                                        )}
                                      >
                                        {t(
                                          "automate.quickBooks.payments.transactionMode"
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item sm={5}>
                                      <div
                                        className={clsx(
                                          classes.title,
                                          classes.titleRow
                                        )}
                                      >
                                        {t(
                                          "automate.quickBooks.payments.paymentMethod"
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            {options.map(
                              (
                                { transactionModeID, paymentMethodID, id },
                                index
                              ) => {
                                return (
                                  <div className={classes.row} key={index}>
                                    <Grid container alignItems="center">
                                      <Grid item sm={5} xs={12}>
                                        {quickBooks.payment.transactionMode && (
                                          <div
                                            className={classes.fieldContainer}
                                          >
                                            <Select
                                              hideSelectedOptions={true}
                                              value={getSelectedValue(
                                                id,
                                                "mode"
                                              )}
                                              styles={selectStyle}
                                              options={
                                                quickBooks.payment
                                                  .transactionMode
                                              }
                                              data-index={id}
                                              onChange={(val) =>
                                                handlePaymentsMapping(val, id)
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                border: "1px",
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#666666",
                                                  primary25: "#dddddd",
                                                },
                                              })}
                                            />
                                          </div>
                                        )}
                                      </Grid>

                                      <Grid item xs={12} sm={5}>
                                        {quickBooks.payment.paymentMethod && (
                                          <div
                                            className={classes.fieldContainer}
                                          >
                                            <Select
                                              hideSelectedOptions={true}
                                              value={getSelectedValue(
                                                id,
                                                "method"
                                              )}
                                              styles={selectStyle}
                                              options={
                                                quickBooks.payment.paymentMethod
                                              }
                                              data-index={id}
                                              onChange={(val) =>
                                                handleMappingRowPayment(val, id)
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                border: "1px",
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#666666",
                                                  primary25: "#dddddd",
                                                },
                                              })}
                                            />
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item sm={2}>
                                        <div className={classes.saveContainer}>
                                          {index + 1 < options.length && (
                                            <DeleteIcon
                                              onClick={() =>
                                                handleDeleteMappingRow(id)
                                              }
                                              className={classes.actionIcon}
                                            />
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                );
                              }
                            )}

                            {saveToggle && (
                              <div className={classes.buttonsContainer}>
                                <div
                                  className={classes.altButton}
                                  onClick={handleSave}
                                >
                                  {update && (
                                    <span>
                                      {t("automate.quickBooks.update")}
                                    </span>
                                  )}
                                  {!update && (
                                    <span>{t("automate.quickBooks.save")}</span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      {quickBooks.payment.selectedPaymentMethods && (
                        <Accordion
                          onChange={() => handleAccordian()}
                          expanded={!isPayment}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <span className={classes.title}>
                              {t("automate.quickBooks.accounts.name")}
                            </span>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Purchase />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            autoHideDuration={3000}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  quickBooks: state.quickBooks,
});

export default withTranslation()(connect(mapStateToProps)(QuickBooks));
