import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { returnCurrent } from "../../../redux/selectors/dashboard";
import { GROUP } from "../../../common/constants/permission";
import {
  fetchUserPermissions,
  updateUserPermissions,
  removeUserFromSpace,
} from "../../../redux/actions/permissions/permissions";
import { resetError } from "../../../redux/actions/goki/goki";

import GroupItem from "./GroupItem";

const styles = (theme) => ({
  root: {
    position: "relative",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "relative",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#000000",
    lineHeight: "20px",
    paddingTop: theme.spacing(1),
  },
  breakTag: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  button: {
    alignItems: "center",
    background: "#fff",
    borderRadius: 25,
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    marginTop: "10px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: "0 2px 20px #F0F0F0",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  listContainer: {
    overflowY: "scroll",
    position: "relative",
  },
  listSection: {
    margin: theme.spacing(3),
    minHeight: "calc(100vh - 250px)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(7),
      marginRight: theme.spacing(7),
    },
  },
  loadingState: {
    width: "100%",
    height: "calc(100vh - 100px)",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "40%",
  },
  disableScroll: {
    overflowY: "hidden",
  },
});

class ManageUserPermissions extends Component {
  componentDidMount() {
    this.getUserPermissions();
  }

  getUserPermissions = () => {
    const { dispatch, currentSpace, currentMakerID } = this.props;
    const data = {
      spaceID: currentSpace._id,
      userID: currentMakerID,
    };

    dispatch(fetchUserPermissions(data));
  };

  updatePermissionHandler = (status, type, action = null) => {
    const { dispatch, currentSpace, currentMakerID } = this.props;
    const data = {
      spaceID: currentSpace._id,
      userID: currentMakerID,
    };
    const response = dispatch(updateUserPermissions(status, data, type));
    response.then((success) => {
      if (action) action();
    });
  };

  handleCloseError = () => {
    const { dispatch } = this.props;
    dispatch(resetError("FETCH_USER_PERMISSIONS"));
    dispatch(resetError("UPDATE_USER_PERMISSIONS"));
  };

  closeDialogHandler = () => {
    const { closeModalHandler } = this.props;
    this.handleCloseError();
    closeModalHandler();
  };

  removeUserHandler = () => {
    const { dispatch, currentSpace, currentMakerID, closeMainModalHandler } =
      this.props;
    const data = {
      spaceID: currentSpace._id,
      userID: currentMakerID,
    };
    const response = dispatch(removeUserFromSpace(data));
    response.then((success) => {
      if (success) closeMainModalHandler();
    });
  };

  render() {
    const { classes, currentSpace, errors, currentMaker, isLoading } =
      this.props;

    return (
      <>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            Manage permissions for <br className={classes.breakTag} />{" "}
            {currentMaker.name}
          </div>
          <div
            className={classes.buttonsContainer}
            onClick={this.removeUserHandler}
          >
            <div className={classes.button}>
              Remove from {currentSpace.name}
            </div>
          </div>
          <CloseIcon
            className={classes.closeIcon}
            onClick={this.closeDialogHandler}
          />
        </div>
        <div
          className={clsx({
            [classes.listContainer]: true,
            [classes.disableScroll]: isLoading,
          })}
        >
          {isLoading && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}
          <div className={classes.listSection}>
            {Object.keys(GROUP).map((groupID, groupIndex) => (
              <GroupItem
                key={`group_${groupIndex}`}
                currentMakerID={currentMaker._id}
                currentSpace={currentSpace}
                groupID={groupID}
                updatePermissionHandler={this.updatePermissionHandler}
              />
            ))}
          </div>
        </div>
        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={this.handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, errors, loading } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace],
    isLoading: loading.FETCH_USER_PERMISSIONS || loading.DELETE_USER_FROM_SPACE,
    errors:
      errors.FETCH_USER_PERMISSIONS ||
      errors.UPDATE_USER_PERMISSIONS ||
      errors.DELETE_USER_FROM_SPACE,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(ManageUserPermissions)
);
