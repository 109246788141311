import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

// import PlusForm from './PlusForm';
// import PricingCards from './PricingCards';

import SubscriptionPlans from "./SubscriptionPlans";
import UpgradeSuccessModal from "./UpgradeSuccessModal";

import { setUpgradeAccount } from "../../redux/actions/dashboard/dashboard";
import {
  resetError,
  reloadLoadSpaceDetails,
  // setSelectedPlanID, setSelectedPriceID
} from "../../redux/actions/subscriptions/subscriptionUpgrade";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  STRIPE_PLUS_MONTHLY_PRICE_ID,
  STRIPE_PLUS_YEARLY_PRICE_ID,
  STRIPE_PRO_MONTHLY_PRICE_ID,
  STRIPE_PRO_YEARLY_PRICE_ID,
} from "../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3)
    overflowY: "hidden",
    background: "#F0F0F0",
  },
  whiteBackground: {
    background: "#FFFFFF",
  },
  pricingCardContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    display: "none",
    "&.isActive": {
      display: "block",
    },
  },
  bubble: {
    backgroundColor: "#bdbdbd",
    border: "1px solid #dddddd",
    color: "#ffffff",
    fontSize: "3.5rem",
    height: 100,
    lineHeight: 3,
    marginBottom: theme.spacing(2),
    textAlign: "center",
    width: 100,
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    // width: '30%',
    marginBottom: 20,
    height: "200px",
    width: "200px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '60%',
    // },
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
  },
  description: {
    boxShadow: "0 2px 20px #F0F0F0",
    borderRadius: 15,
    marginBottom: 20,
    padding: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  priceBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  price: {
    fontSize: "2rem",
    fontWeight: 600,
    marginBottom: 5,
  },
  fee: {
    color: "#999999",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  basic: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 600,
    marginBottom: 10,
    width: "100%",
  },
  list: {
    paddingLeft: 60,
    marginBottom: 20,
  },
  listItem: {
    alignItems: "center",
    display: "flex",
    fontSize: "1.2rem",
    marginBottom: 3,
  },
  checkIcon: {
    fontSize: "1.5rem",
    marginRight: 10,
  },
  label: {
    fontWeight: 600,
    marginBottom: 10,
  },
  advanced: {
    fontSize: "1.2rem",
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    background: "#C4ADA2",
    borderRadius: 15,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    padding: "10px 15px",
    "&:hover": {
      background: "#996666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("xs")]: {
      top: 18,
    },
  },
  subText: {
    fontSize: "1.4rem",
    color: "#999999",
    marginLeft: 5,
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "45%",
  },
}));

const UPGRADE_MODAL = {
  VIEW_PLANS: 1,
  SUBSCRIBE_TO_PLAN: 2,
  SUCCESS: 3,
};

const PlusModal = (props) => {
  const { currentSpace, upgradeAccount, errors, dispatch, t, isLoading } =
    props;
  const classes = useStyles();
  const [activeModal, setActiveModal] = React.useState(
    UPGRADE_MODAL.VIEW_PLANS
  );

  useEffect(() => {
    //RESET MODAL STATE ON CLOSING MODAL...........
    if (upgradeAccount && activeModal !== UPGRADE_MODAL.VIEW_PLANS)
      setActiveModal(UPGRADE_MODAL.VIEW_PLANS);
  }, [upgradeAccount]);

  const handleNext = (info) => {
    showSuccessModal();
  };

  const handleCloseError = () => {
    dispatch(resetError("SUBSCRIBE_TO_PLAN"));
  };

  const handleClose = () => {
    props.dispatch(setUpgradeAccount(false));
  };

  const closeModalHandler = () => {
    switch (activeModal) {
      case UPGRADE_MODAL.VIEW_PLANS:
        handleClose();
        break;

      case UPGRADE_MODAL.SUCCESS:
        handleClose();
        break;
    }
  };

  const showSuccessModal = () => {
    dispatch(reloadLoadSpaceDetails(currentSpace._id));
    setActiveModal(UPGRADE_MODAL.SUCCESS);
  };

  return (
    <div>
      <Dialog
        open={upgradeAccount || false}
        onClose={closeModalHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        fullScreen={activeModal !== 3}
        disableEnforceFocus={true}
      >
        <div
          className={clsx({
            [classes.dialog]: true,
            [classes.whiteBackground]: activeModal == UPGRADE_MODAL.SUCCESS,
          })}
        >
          {/* .....................MODAL HEADER.............................. */}
          {activeModal !== UPGRADE_MODAL.SUCCESS && (
            <CloseIcon
              className={classes.closeIcon}
              onClick={closeModalHandler}
            />
          )}

          {/*----------------PROGRESS LOADER--------------------- */}
          {isLoading && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}

          {/* .....................MODAL BODY................................ */}
          {
            {
              [UPGRADE_MODAL.VIEW_PLANS]: (
                <div
                  className={clsx(classes.pricingCardContainer, {
                    isActive: activeModal == UPGRADE_MODAL.VIEW_PLANS,
                  })}
                >
                  <SubscriptionPlans handleNext={handleNext} />
                </div>
              ),

              // [UPGRADE_MODAL.SUBSCRIBE_TO_PLAN]: <PlusForm showSuccessModal={showSuccessModal} />,

              [UPGRADE_MODAL.SUCCESS]: (
                <UpgradeSuccessModal closeModalHandler={closeModalHandler} />
              ),
            }[activeModal]
          }

          {/* ------------- Snackbar error messages -------------- */}
          {errors && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={true}
              onClose={handleCloseError}
              autoHideDuration={6000}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{errors}</span>}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { spaces, dashboard, subscriptions, errors, loading } = state;
  const currentSpace = spaces[dashboard.currentSpace] || {};
  return {
    currentSpace,
    upgradeAccount: state.dashboard.upgradeAccount,
    // subscriptionPlans: subscriptions.subscriptionPlans || {},
    errors: errors.SUBSCRIBE_TO_PLAN,
    isLoading: loading.SUBSCRIBE_TO_PLAN || loading.GET_PORTAL_SESSION,
  };
};

export default withTranslation()(connect(mapStateToProps)(PlusModal));
