import React, { useEffect } from "react";
import Iframe from "react-iframe";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { HHL_HOST } from "../config/index";
import { getHostelworldHostedLinkupsToken } from "../redux/actions/linkups";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 10px",
    marginRight: "20px",
    width: "calc(100% - 20px)",
    height: "calc(100vh - 60px)",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
      width: "100%",
    },
  },
  moduleContainer: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
  },
  moduleSection: {
    padding: "0px 20px",
  },
  xsPaddingNone: {
    [theme.breakpoints.down("xs")]: {
      padding: "unset",
    },
  },
}));

const LinkupsContainer = (props) => {
  const { propertyID, dispatch, hhlToken } = props;
  const classes = useStyles();

  useEffect(() => {
    dispatch(getHostelworldHostedLinkupsToken({ propertyID }));
  }, [propertyID]);

  const hostedLinkupsUrl = `${HHL_HOST}/?token=${hhlToken}`;

  return (
    <div className={classes.root}>
      <Iframe
        url={hostedLinkupsUrl}
        width="100%"
        height="100%"
        id="1234"
        className=""
        display="block"
        position="relative"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, spaces, auth, linkups } = state;
  const currentSpaceInfo = spaces[dashboard.currentSpace];
  return {
    currentSpace: dashboard.currentSpace,
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule,
    isSystemAdmin: auth.user.isSystemAdmin,
    propertyID: currentSpaceInfo?.propertyID,
    hhlToken: linkups?.token,
  };
};

export default connect(mapStateToProps)(LinkupsContainer);
