import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import Header from './Header';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        height: '100vh',
        [theme.breakpoints.down('xs')]:{
            flexDirection: "column-reverse",
            height: '100%',
        }
    },
    sectionContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        '& .inboxlangSelect': {
            padding: '20px 30px'
        }
    },
    section:{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flex: 'unset',
            // height: '100vh'
        }
    },
    textSection: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
        }
    },
    title:{
        fontSize: '2rem',
        fontWeight: 600,
        margin: "unset",
        marginBottom: "30px",
    },
    desc: {
        color: "#514b4b",
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 1.4,
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    mainContent: {
        flex: 1,
        maxWidth: "400px",
        padding: "20px 10px",
    },
    startBtn: {
        color: "#fff",
        width: "100%",
        padding: "10px",
        fontSize: "1.4rem",
        fontWeight: 600,
        borderRadius: "5px",
        backgroundColor: "#000",
        '&:hover': {
            backgroundColor: "#000",
            color: "#fff",
        }
    },
    infoContainer: {
        margin: "40px 0px",
    },
    emoji: {
        fontSize: '2rem',
        marginRight: 20
    },
    infoItems: {
        fontWeight: 500,
        color: "#514b4b",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        fontSize: "1.2rem",
    },
    content: {
        fontSize: "3rem",
        padding: "20px 40px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "2.5rem",
        }
    },
    sectionText: {
        backgroundColor: "#f8f8ff",
        paddingBottom: "78px",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 'unset'
        }
    }
}));

const videoLinks = {
    en: 'https://www.videoask.com/fi4frjqmx',
    es: 'https://www.videoask.com/f6nuypgk7',
    pt: 'https://www.videoask.com/fqimm6tgl',
    it: 'https://www.videoask.com/f3dee90xx'
}

const WelcomePage = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { getStartedHandler } = props;

    const getVideoIframe = () =>{
        const currentLanguage = i18next.language;
        let iframeSrc = videoLinks.en;

        switch(currentLanguage){
            case 'en':
            case 'es':
            case 'pt':
            case 'it':
                iframeSrc = videoLinks[currentLanguage];
                break;
                
            default:
                iframeSrc = videoLinks.en;
                break;
        }

        return(
            <iframe src={iframeSrc}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="800px"
                style={{border: 'none'}}
            >
            </iframe>
        )
    }

    return(
        <div className={classes.root}>

            <div className={classes.sectionContainer}>
                
                <div className={clsx(classes.section, classes.textSection)}>
                    <div className={classes.mainContent}>
                        <div className={classes.title}>{t('hwMigration.welcomePage.title')}</div>

                        <div className={classes.desc}>
                            {t('hwMigration.welcomePage.description')}
                        </div>

                        <div className={classes.infoContainer}> 
                            <div className={classes.infoItems}>
                                <span className={classes.emoji}>&#128197;</span>
                                {t('hwMigration.welcomePage.listItem1')}
                            </div>

                            <div className={classes.infoItems}>
                                <span className={classes.emoji}>&#128241;</span>
                                {t('hwMigration.welcomePage.listItem2')}
                            </div>

                            <div className={classes.infoItems}>
                                <span className={classes.emoji}>&#128202;</span>
                                {t('hwMigration.welcomePage.listItem3')}
                            </div>

                            <div className={classes.infoItems}>
                                <span className={classes.emoji}>&#10133;</span>
                                {t('hwMigration.welcomePage.listItem4')}
                            </div>
                        </div>

                        <Button 
                            className={classes.startBtn}
                            onClick={getStartedHandler}
                        >
                            {t('actions.getStarted')}
                        </Button>
                    </div>
                </div>

                <Header />
                
            </div>
            <div className={clsx(classes.section, classes.sectionText)}>
                {/* {getVideoIframe()} */}
                <div className={classes.content}>
                    <h2>Your Inbox, but better</h2>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;