import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'; 

import ActivityTitle from './tab_pages/ActivityTitle';
import ActivityTheme from './tab_pages/ActivityTheme';
import ActivityDescription from './tab_pages/ActivityDescription';
import ActivityLanguage from './tab_pages/ActivityLanguage';
import ActivityPhotos from './tab_pages/ActivityPhotos';
import ActivityGroupSize from './tab_pages/ActivityGroupSize';
import ActivityPricing from './tab_pages/ActivityPricing';
import ActivityLocation from './tab_pages/ActivityLocation';
import ActivityProvisions from './tab_pages/ActivityProvisions';
import ActivityRequirements from './tab_pages/ActivityRequirements';
import ActivityHostDetails from './tab_pages/ActivityHostDetails';
import ActivityStartPoint from './tab_pages/ActivityStartPoint';
import ActivityBookingDetails from './tab_pages/ActivityBookingDetails';
import ActivityVariations from './tab_pages/ActivityVariations';
import ActivityGuidelines from './tab_pages/ActivityGuidelines';

import { getHeaderTitle } from './activityHelper';

const styles = theme =>({
    root: {
        height: '100%'
    },
    container:{
        '& .main-content':{
            height: 'calc(100vh - 60px)',
            padding: '0px 100px',
            overflowY: 'auto',
            [theme.breakpoints.down("xs")]:{
                padding: '0px 20px',
                height: 'calc(100vh - 130px)',
            }
        },
        '& .main-content-title': {
            fontSize: '1.7rem',
            fontWeight: 600,
            marginBottom: '10px',
            marginTop: 40,
        },
        '& .sub-title': {
            fontSize: '1.2rem',
            marginBottom: 30,
            '& p, ol, ul': {
                maxWidth: '400px'
            }
        },

        '& .errorMessage': {
            color: "#f44336"
        },
    },
});

class ContentRenderer extends Component {
    
    components = {
        experienceName: ActivityTitle,
        theme: ActivityTheme,
        description: ActivityDescription,
        language: ActivityLanguage,
        photos: ActivityPhotos,
        groupSize: ActivityGroupSize,
        pricing: ActivityPricing,
        activityLocation: ActivityLocation,
        provisions: ActivityProvisions,
        requirements: ActivityRequirements,
        hostDetails: ActivityHostDetails,
        startPoint: ActivityStartPoint,
        bookingDetails: ActivityBookingDetails,
        variations: ActivityVariations,
        guidelines: ActivityGuidelines
    };

    render() {
        const { activeSubMenuID, contentProps, classes } = this.props;
        const MainComponent = this.components[activeSubMenuID];
        
        if(!MainComponent) return null;
        
        const activeStepInfo = contentProps.activeStepInfo;
        const headerTitle = getHeaderTitle(activeStepInfo.mainMenuID, activeStepInfo.subMenuID);

        return(
            <div className={classes.root}>

                <div className={classes.container}>
                    <MainComponent { ...contentProps } />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ContentRenderer);