import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ConfirmForm from '../../../common/ConfirmForm';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
    root: {
        marginTop: '20px'
    },
    confirm: {
        fontSize: "1.3rem",
        lineHeight: "15px",
    },
    btnContainer: {
        textAlign: 'end',
        position: 'relative',
        minHeight: '20px',
        '& .loader': {
            left: "45%",
            position: 'absolute'
        }
    },
    button:{
        backgroundColor: '#5C5C5C',
        color: '#fff',
        padding: "6px 20px",
        '&:hover': {
            backgroundColor: '#5C5C5C',
            color: '#fff'
        }
    },
    iframeConatiner: {
        display: 'none',
        '&.show': {
            display: 'flex',
        },
        '& span': { 
            width: "15%",
            margin: 0,
            fontWeight: 600,
            lineHeight: "22px",
        }
    },
    info: {
        fontWeight: 500,
        margin: 0
    }
}));

const IFRAME_BASE_URL = 'https://pci.channex.io/api/v1/show_service_code';

const CardCvvView = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { cvvIframeSrcParams, isAccessExpired, accessExpiryInfo } = props;
    const parentRef = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [showCvv, setShowCvv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const displayCvvHandler = () =>{
        setIsLoading(true);
        const iframeURL = `${IFRAME_BASE_URL}?${cvvIframeSrcParams}`;
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', iframeURL);
        iframe.setAttribute('style', 'border: 0');
        iframe.setAttribute('scrolling', "no");
        iframe.setAttribute('width', '50%');
        iframe.setAttribute('height', '40px');

        iframe.onload = function(){
            setIsLoading(false);
            setShowCvv(true);
        }

        iframe.onerror = function(errorData){
            console.log('errorData', errorData);
            setIsLoading(false);
        }

        parentRef.current.appendChild(iframe);
    }

    return (
        <div className={classes.root}>
            <ConfirmForm
                className={classes.confirm}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={displayCvvHandler}
            >
                {t(`existingReservation.viewCardModal.cvvConfirmMsg`)}
            </ConfirmForm>

            {!isAccessExpired && !showCvv && !confirmOpen && (
                <div className={classes.btnContainer}>
                    {!isLoading && (
                        <Button
                            className={classes.button}
                            onClick={() =>setConfirmOpen(true)}
                        >
                            {t(`existingReservation.viewCardModal.cvvButtonText`)}
                        </Button>
                    )}

                    {isLoading && <CircularProgress size={30} className="loader"/>}
                </div>
            )}

            {isAccessExpired && (
                <p className={classes.info}>{t(`existingReservation.viewCardModal.cvvAccessedOn`)} {accessExpiryInfo}</p>
            )}

            <div ref={parentRef} className={clsx(classes.iframeConatiner, { show: showCvv })}>
                <span>CVV :</span>
            </div> 
        </div>
    )
}

export default CardCvvView;