import http from "../../utils/http";
import { normalize } from 'normalizr';
import { spaceSchema } from '../../schema';
import { getRoomTypes } from '../beds/beds';
import { getFrontdeskUsers } from '../user/frontdeskUsers';
import { fetchTaxList } from '../taxes/taxes';
import { fetchTransactionMode } from '../reservation/editReservation';
import { getCalendarSnapshot } from '../beds/calendar';
import { fetchLiveReports } from '../dashboard/live';
import { loadAnnouncements } from "../announcements/announcements";
import { listUpsellItems } from "../upsell/upsell";
import { loadVotes } from '../votes/votes';
import { fetchProductCategorys } from "../pos/productCategory";
import { MENU } from '../../../utils/layout';
import { getBuildings } from "../rooms/building";
import { ratePlanSchema } from "../../schema/ratePlanSchema";

//----------------------------------------------------------------
// LOAD SPACE DETAILS
//----------------------------------------------------------------
export function loadSpace(spaceID, currentSpace) {
  const ACTION_NAME = "LOAD_SPACE";
  return function(dispatch) {
    // dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/space/${spaceID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, {space: spaceSchema} );
        // console.log(normalizedOrder.entities)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities})
        dispatch(setIframe(false));
        if (currentSpace) {
          if (currentSpace===spaceID) {
            dispatch(setCurrentSpaceType(data.space.type));  
          }
        } else {
          dispatch(setCurrentSpace(spaceID));
          dispatch(setCurrentSpaceType(data.space.type));
        }
        
        if (data.space.propertyID) {

          if((currentSpace && currentSpace == spaceID) || !currentSpace){
            dispatch(loadPropertyContent(data.space.propertyID, spaceID));
          }
          
          dispatch(getRoomTypes(spaceID, data.space.propertyID));
          dispatch(getBuildings(spaceID, data.space.propertyID));
          dispatch(getFrontdeskUsers(spaceID, data.space.propertyID));
          dispatch(fetchTaxList(spaceID, data.space.propertyID));
          dispatch(fetchTransactionMode(spaceID, data.space.propertyID));
          dispatch(loadAnnouncements(spaceID));
          //dispatch(getCalendarSnapshot(spaceID, data.space.propertyID));
          dispatch(fetchLiveReports(spaceID, data.space.propertyID));
          dispatch(listUpsellItems(spaceID, data.space.propertyID));
          dispatch(fetchProductCategorys(spaceID, data.space.propertyID))
        }
        if (data.space.type === "Association") {
          dispatch(loadAnnouncements(spaceID));
          dispatch(loadVotes(spaceID));
        }
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD SPACE DETAILS
//----------------------------------------------------------------
export function loadOnlySpace(spaceID, currentSpace) {
  const ACTION_NAME = "LOAD_SPACE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/space/${spaceID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, {space: spaceSchema} );
        console.log(normalizedOrder.entities)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities})
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD PROPERTY CONTENT INFO
//----------------------------------------------------------------
export function loadPropertyContent(propertyID, spaceID) {
  const ACTION_NAME = "LOAD_PROPERTY_CONTENT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/content/${propertyID}`)
      .then(successResult => {
        
        const { 
          invoiceInfo, 
          buildings = {}, 
          bookingSources = [],
          ratePlans = [] 
        } = successResult.data;

        let buildingsList = {};
        buildings.forEach(building =>{
            buildingsList[building.buidlingID] = { ...building }
        });

        //------------------LOAD PROPERTY SETTINGS------------------------------
        dispatch({ 
          type: `LOAD_PROPERTY_SETTINGS`, 
          payload: { 
            taxInvoiceEnabled: invoiceInfo?.taxInvoiceEnabled || false,
            buildings: { ...buildingsList }
          }
        });

        //------------------LOAD RATE PLANS------------------------------
        const normalizedRatePlan = normalize(ratePlans, [ ratePlanSchema ] );
        dispatch({ 
          type: `LOAD_RATEPLANS_SUCCESS`, 
          payload: normalizedRatePlan.entities.ratePlans 
        });

        if(spaceID){
          //------------------LOAD BOOKING SOURCES------------------------------
          dispatch({ 
            type: `FETCH_BOOKING_SOURCE_SUCCESS`, 
            payload: { 
              data: bookingSources,
              spaceID
            }
          });
        }

        dispatch({ type: `${ACTION_NAME}_SUCCESS`});
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SET SPACE
//----------------------------------------------------------------
export const setSpace = (space) => ({
  type: 'SET_SPACE',
  payload: space
})

//----------------------------------------------------------------
// SET CURRENT SPACE
//----------------------------------------------------------------
export const setCurrentSpace = (currentSpace = '') => ({
  type: 'SET_CURRENT_SPACE',
  currentSpace
})

//----------------------------------------------------------------
// SET CURRENT SPACE TYPE
//----------------------------------------------------------------
export const setCurrentSpaceType = (currentSpaceType = '') => ({
  type: 'SET_CURRENT_SPACE_TYPE',
  currentSpaceType
})

//----------------------------------------------------------------
// SET CURRENT FOLDER
//----------------------------------------------------------------
export const setCurrentFolder = (currentFolder = '') => ({
  type: 'SET_CURRENT_FOLDER',
  currentFolder
})

//----------------------------------------------------------------
// SET CURRENT FILE
//----------------------------------------------------------------
export const setCurrentFile = (currentFile = '') => ({
  type: 'SET_CURRENT_FILE',
  currentFile
})

//----------------------------------------------------------------
// SET CURRENT MAKER
//----------------------------------------------------------------
export const setCurrentMaker = (currentMaker = '') => ({
  type: 'SET_CURRENT_MAKER',
  currentMaker
})

//----------------------------------------------------------------
// SET CURRENT MODULE
//----------------------------------------------------------------
export const setCurrentModule = (currentModule = MENU.live.id, subModule = null) => {
  const defaultSubmenu = MENU[currentModule].defaultSubmenu;
  const currentSubModule = subModule ? subModule : defaultSubmenu;
  return{
    type: 'SET_CURRENT_MODULE',
    currentModule,
    currentSubModule
  }
}

//----------------------------------------------------------------
// SET IFRAME
//----------------------------------------------------------------
export const setIframe = (iframe = false) => ({
  type: 'SET_IFRAME',
  iframe
})

//----------------------------------------------------------------
// SET CURRENT TODO
//----------------------------------------------------------------
export const setCurrentTodo = (currentTodo = '') => ({
  type: 'SET_CURRENT_TODO',
  currentTodo
})

//----------------------------------------------------------------
// SET CURRENT CHAT
//----------------------------------------------------------------
export const setCurrentChat = (currentChat = '') => ({
  type: 'SET_CURRENT_CHAT',
  currentChat
})

//----------------------------------------------------------------
// SET UPGRADE ACCOUNT -- VIEW UPGRADE MODAL
//----------------------------------------------------------------
export const setUpgradeAccount = (upgradeAccount) => ({
  type: 'SET_UPGRADE_ACCOUNT',
  upgradeAccount
})

//----------------------------------------------------------------
// SET UPGRADE ACCOUNT -- VIEW UPGRADE MODAL
//----------------------------------------------------------------
export const setMobileSidebarOpenValue = (mobileSidebarOpen) => ({
  type: 'SET_MOBILE_SIDEBAR_OPEN_VALUE',
  mobileSidebarOpen
})

//----------------------------------------------------------------
// SET CURRENT RESERVATION SETTINGS
//----------------------------------------------------------------
export const setCurrentReservationSettings = (openByID = false, settings = null) => ({
  type: 'SET_CURRENT_RESERVATION_SETTINGS',
  openByID,
  settings,
})

//----------------------------------------------------------------
// SET CHANNEL STATUS 
//----------------------------------------------------------------
export const setSpaceChannelStatus = (list) => ({
  type: 'SET_SPACE_CHANNEL_STATUS',
  list
})