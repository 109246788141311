import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import AppRouter from "./routers/AppRouter";
import configureStore from "./redux/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import initLogger from "./utils/logger";
import { initHotjar } from "./utils/hotjar";

import "normalize.css/normalize.css";
import "./index.css";
import "./styles/styles.scss";

initLogger();
initHotjar();

const store = configureStore().store;
const persistor = configureStore().persistor;

const jsx = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <AppRouter />
        </I18nextProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);

ReactDOM.render(jsx, document.getElementById("root"));
