export const setUserSettings = data =>({
    type: 'SET_USER_UI_SETTINGS',
    payload: { ...data }
});

export const setCalendarColor = data =>({
    type: 'SET_CALENDAR_COLOR',
    payload: { ...data }
})

export const setCalendarDates = data =>({
    type: 'SET_CALENDAR_DATES',
    payload: { ...data }
})

export const setLanguage = data =>({
    type: 'SET_LANGUAGE',
    payload: { ...data }
})