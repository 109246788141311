const xss = require('xss');

const xssOptions = {
    whiteList: {
        strong: [],
        em: [],
        br: [],
        div: [],
        a: ['href', 'target'],
        span: ['style'],
        p: [],
        ul: [],
        li: [],
        ol: [],
        h1: [],
        h2: [],
        blockquote: [],
    }
};

const xssSanitize = (text) => {
    return xss(text, xssOptions)
}

module.exports = {
  xssSanitize,
};