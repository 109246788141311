import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';

import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';

import TimePicker from '../../../common/TimePicker';
import SlotRepeatDays from '../../Activities/SlotRepeatDays';
import { defaultImageUrl } from '../activityHelper';

const styles = theme => ({
    root: {
        margin: '0px 30px',
        [theme.breakpoints.down('xs')]: {
            margin: '0px 15px',
        }
    },
    label: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginBottom: 5,
        width: '100%',
        padding: '5px'
    },
    selectFormControl: {
        // width: '100%',
        padding: '0px 5px',
        boxSizing: 'border-box',
        marginBottom: '20px'
    },
    selectInputContainer: {
        display: 'flex',

        '& $selectInput': {
            borderRadius: '0px',
        },

        '& .adornment': {
            border: '1px solid #d8dcdd',
            padding: '0px 10px',
            backgroundColor: '#ebeff0',
            color: '#19232d',
            fontSize: '1.2rem',
            fontWeight: 400,
            lineHeight: '35px',
            zIndex: 1,
            minWidth: '30px',
            textAlign: 'center'
        },
        '& .adornment.start': {
            marginRight: '-1px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
        },
        '& .adornment.end': {
            marginLeft: '-2px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root': {
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d8dcdd'
        }
    },


    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: ' 0px 5px',
        marginBottom: '20px',
        '& .timePicker': {
            marginLeft: '10px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '0px',
                width: '100%'
            }
        },
        '& $selectInputContainer': {
            marginBottom: '10px'
        }

    },
    datePicker: {
        border: '1px solid #d8dcdd',
        minWidth: '160px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        '& .SingleDatePicker_picker': {
            zIndex: 2
        },
        '& .DateInput_input__disabled': {
            backgroundColor: '#fff',
            fontStyle: 'unset'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },

    checkbox: {
        marginLeft: '0px',
        marginRight: '0px',
        width: 'max-content',
        marginBottom: '20px',
    },

    formControl: {
        marginBottom: '20px',
    },
    textInput: {
        height: '40px',
        backgroundColor: '#f8f9fb',
        borderRadius: '5px',
        padding: '0px 10px',
        '&.Mui-error': {
            border: '1px solid #f44336'
        },
        '&.title': {
            minWidth: '430px'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        padding: '0px 5px',
        '& .button': {
            [theme.breakpoints.down("xs")]: {
                width: '100%'
            }
        }
    },

    selectConductor: {
        display: 'flex',
        marginBottom: '10px',
        '& $selectFormControl': {
            marginBottom: '0px'
        },
        '& $selectInput': {
            minWidth: '170px'
        }
    },

    addConductorBtn: {
        fontSize: '1.3rem',
        fontWeight: 600,
        cursor: 'pointer',
        padding: '5px',
        textDecoration: 'underline'
    },

    errorMessage: {
        color: "#f44336"
    },

    cutOffSelect: {
        width: '50%',
        marginBottom: '20px',
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },

    hideBtn:{
        display: 'none'
    },

    productDetails: {
        margin: '30px 0px',
        '& .productPic':{
            height: 150,
            width: '100%',
            objectFit: 'cover',
        },
        '& .title':{
            fontSize: '1.5rem',
            fontWeight: 600,
            paddingLeft: '10px'
        }
    },
});

const repeatTypes = {
    off: { id: 'off', title: 'Do not repeat', maxDuration: 0 },
    days: { id: 'days', title: 'Repeat daily', maxDuration: 31 },
    // months: { id: 'months', title: 'Repeat monthly', maxDuration: 12 },
    weeks: { id: 'weeks', title: 'Repeat weekly', maxDuration: 31 },
    // years: { id:'years', title: 'Repeat yearly', maxDuration: 31 },
};

const defaultFormData = {
    start: {
        date: null,
        time: null
    },
    end: {
        date: null,
        time: null
    },
    allDay: false,
    repeat: {
        type: repeatTypes.days.id,
        interval: 1,
        until: null,
        dayOfWeek: []
    }
}

const formHelper = {
    titleError: false,
}

const uiState = {
    startDateFocus: false,
    endDateFocus: false,
    repeatUntilFocus: false,
    errorData: {
        status: false,
        message: ''
    },
    showRepeatSection: true,
}

class VariationForm extends Component {
 
    constructor(props){
        super(props);
        this.state = {
            ...defaultFormData,
            ...formHelper,
            ...uiState,
            isBookingDetailStep: this.checkIfBookingDetailStep()
        }
    }

    checkIfBookingDetailStep = () =>{
        const { activeStepInfo } = this.props;
        return activeStepInfo.subMenuID == 'bookingDetails'
    }

    getGradeID = () =>{
        const { gradeID, productData } = this.props;
        const { isBookingDetailStep } = this.state;
        
        if(isBookingDetailStep && productData.grades){
            const mainGradeID = Object.keys(productData.grades)[0];
            return mainGradeID;
        }

        if(gradeID) return gradeID;

        return;
    }

    componentDidMount(){
        
        const { productData } = this.props;
        const { isBookingDetailStep } = this.state;

        //If form in edit mode, set form fields....
        if(productData.grades){
            
            const gradeID = this.getGradeID();

            if(!gradeID) return;

            const gradeData = { ...productData.grades[gradeID] };

            const slotID = Object.keys(gradeData.slots)[0];

            // Currently feature has one slot per grade restriction..................... 
            const slotData = { ...gradeData.slots[slotID] };

            //Repeat section hidden for slot edit......... 
            //Show title for grade edit ...............
            this.setState({ 
                showRepeatSection: false,
                title: isBookingDetailStep ? productData.title : gradeData.title, 
            });
            
            // Converting slot start and end date immutable objects........
            slotData.start = { ...slotData.start };
            slotData.end = { ...slotData.end };

            if(!slotData.end.time){
                const startTime = slotData.start.time;
                slotData.end.time = moment(`${slotData.end.date} ${startTime}`).add(1, 'hour').format('HH:mm');
            }

            slotData.start.time = moment(`${slotData.start.date} ${slotData.start.time}`);
            slotData.end.time = moment(`${slotData.end.date} ${slotData.end.time}`);
            slotData.repeat.until = moment(slotData.repeat.until);

            slotData.start.date = moment(slotData.start.date);
            slotData.end.date = moment(slotData.start.date);

            this.initFormData(slotData);
        }
    }

    initFormData = slotData =>{
        const formFields = Object.keys(defaultFormData);
        // Filtering form fields from product data........ 
        const filteredSlotData = Object.keys(slotData)
        .filter(key => formFields.includes(key))
        .reduce((obj, key) => (obj[key] = slotData[key], obj), {});
        this.setState({ ...filteredSlotData, slotID: slotData._id });
    }

    handleStartDate = date =>{
        const { start, end } = this.state;
        const endTime = start.time ? moment(start.time).add(1, 'hour') : null;

        if(!endTime){
            this.setState({
                start: { ...start, date },
                end: { ...end, date }
            });
            return;
        }

        this.setState({
            start: { ...start, date },
            end: { date, time: endTime }
        });
    }

    handleStartTime = time => {
        const { start } = this.state;
        const endTime = moment(time).add(1, 'hour');
        this.setState({ 
            start: { ...start, time },
            end: { date: start.date, time: endTime }
        });
    }

    checkboxHandler = event =>{
        const name = event.target.name;
        const isChecked = event.target.checked;
        this.setState({ [ name ]: isChecked });
    }

    onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [ name ]: value });
    }

    dependantInputHandler = event =>{ 
        const type = event.target.getAttribute('data-type');
        const name = event.target.getAttribute('data-attr-name');
        const value = event.target.value.trim();
        this.setState({
            [ type ]: { ...this.state[type], [ name ]: value }
        });
    }

    selectDayHandler = (type, value) =>{
        const { repeat } = this.state;
        let selectedWeekDays = new Set([ ...repeat.dayOfWeek ]);
        
        switch(type){
            case "add": 
                selectedWeekDays.add(value);
                break;
            
            case "delete":
                selectedWeekDays.delete(value);
                break;
        }

        this.setState({ 
            repeat: { ...repeat, dayOfWeek: [ ...selectedWeekDays ] }
        });
    }

    validateForm = () =>{
        const { 
            start, 
            end, 
            repeat,
            title,
            isBookingDetailStep,
            allDay
        } = this.state;

        const { gradeID, t } = this.props;

        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        //For grade title validation on create grades....
        if(!isBookingDetailStep && !gradeID && !title){
            formInputStatus.titleError = true;
            validationSuccess = false;
        }

        if(!start.date || !start.time){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.startTime')
                }
            });
            return false;
        }

        if(!allDay && (!end.date || !end.time)){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.endTime')
                }
            });
            return false;
        }

        if(repeat.type !== repeatTypes.off.id && !repeat.until){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.repeatUntil')
                }
            });
            return false;
        }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    filterRequestData = data =>{
        const requestData = { ...data };
        
        Object.keys(uiState).forEach(key=>{
            delete requestData[key];
        });

        Object.keys(formHelper).forEach(key =>{
            delete requestData[key];
        });

        delete requestData.isBookingDetailStep;
        delete requestData.title;

        return { ...requestData };
    }

    getRequestData = () =>{
        const { start, end, repeat, allDay } = this.state;

        const startDate = moment(start.date).format('YYYY-MM-DD');
        const endDate = moment(end.date).format('YYYY-MM-DD');

        // let startTime = null;
        // let endTime = null;

        // if(!allDay){
        //     startTime = moment(start.time).format('HH:mm');
        //     endTime = moment(end.time).format('HH:mm');
        // }

        let startTime = moment(start.time).format('HH:mm');
        let endTime = !allDay ? moment(end.time).format('HH:mm') : null;

        const repeatUntil  = moment(repeat.until).format('YYYY-MM-DD');

        let data = { 
            ...this.state,
            start: {
                date: startDate,
                time: startTime
            },
            end: {
                date: endDate,
                time: endTime
            },
            repeat: { 
                ...repeat,
                until: repeatUntil 
            }
        }
        
        // Deleting only ui required states from request object... 
        const requestData = this.filterRequestData(data);

        return { ...requestData };
    }

    editGradeHandler = () =>{
        const { variationSubmitHandler, productData } = this.props;
        const { isBookingDetailStep, title } = this.state;

        const gradeID = this.getGradeID();
        const slotData = this.getRequestData();

        const data = {
            draftID: productData._id,
            gradeID,
            title: isBookingDetailStep ? productData.title : title,
            ...slotData
        }

        // const data = Object.keys(productData.grades).map(gradeID =>{
        //     if(gradeID == seletcedGradeID){
        //         return { ...updatedGradeData };
        //     }
        //     return { ...productData.grades[gradeID] }
        // });

        // const requestData = { grades: [ ...data ] };

        const requestData = {
            data,
            type: 'grade',
            preventNextStep: !isBookingDetailStep
        }

        variationSubmitHandler(requestData);
    }

    submitHandler = () =>{
        const { variationSubmitHandler, productData } = this.props;
        const { isBookingDetailStep, title } = this.state;

        // Validating form inputs..............
        if(!this.validateForm()) return;

        //Check if form in edit mode .................
        const gradeID = this.getGradeID();
        if(gradeID){
            this.editGradeHandler();
            return;
        }

        // Add grade........................................
        const slotData = this.getRequestData();

        const data = {
            draftID: productData._id,
            title: isBookingDetailStep ? productData.title : title,
            ...slotData
        }

        // gradeData = {
        //     title: isBookingDetailStep ? productData.title : title,
        //     slot: { ...slotData }
        // }

        // let data = [];

        // if(productData.grades){
        //     data = Object.keys(productData.grades).map(id =>({ ...productData.grades[id] }));
        // }

        // const processedData = [ ...data, gradeData ];

        // const requestData = {
        //     grades: [ ...processedData ]
        // }

        const requestData = {
            data,
            type: 'grade',
            preventNextStep: !isBookingDetailStep
        }

        variationSubmitHandler(requestData);
    }

    handleCloseSnackBar = () =>{
        this.setState({
            errorData: {
                status: false,
                message: ''
            }
        });
    }

    render() {
        const {
            classes,
            seletedDate,
            currentSpace,
            formSubmitRef,
            productData,
            coverPhotoUrl
        } = this.props;

        const { state } = this;

        const PropertyTimezone = currentSpace.timezone;

        return (

            <Grid container>

                {state.isBookingDetailStep && (
                    <Grid item container className={classes.productDetails}>
                        <Grid item md={4} lg={3}>
                            <img className="productPic" src={ coverPhotoUrl || defaultImageUrl } />
                        </Grid>
                        <Grid item sm={12} md={8} lg={9}>
                            <div className="title">{productData.title}</div>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    {/* -----------------GRADE TITLE----------------- */}
                    {!state.isBookingDetailStep && (
                        <>
                            <div className={classes.label}>Title*</div>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    id="grade_title"
                                    name="title"
                                    type="text"
                                    className={clsx(classes.textInput, 'title')}
                                    fullWidth={true}
                                    value={state.title || ''}
                                    onChange={this.onChangeHandler}
                                    error={state.titleError}
                                />
                                {state.titleError && (
                                    <FormHelperText className={classes.errorMessage}>Title is required</FormHelperText>
                                )}
                            </FormControl>
                        </>
                    )}

                    <div className={classes.label}>Next available slot*</div>

                    {/* -----------------SLOT START DATETIME----------------- */}
                    <div className={classes.dateTimeContainer}>
                        <div className={classes.selectInputContainer}>
                            <div className="adornment start">From</div>
                            <div className={classes.datePicker}>
                                <SingleDatePicker
                                    date={state.start.date}
                                    isOutsideRange={function noRefCheck() { }}
                                    id="start_date"
                                    onDateChange={this.handleStartDate}
                                    onFocusChange={({ focused }) => this.setState({ startDateFocus: focused })}
                                    focused={state.startDateFocus}
                                    noBorder={true}
                                    numberOfMonths={1}
                                    hideKeyboardShortcutsPanel
                                    displayFormat={'DD MMM YYYY'}
                                    disabled={Boolean(seletedDate) || !state.showRepeatSection} // Datepicker disabled for edit slot and edit solt for rates and availability
                                />
                            </div>
                        </div>

                        {/* {!state.allDay && ( */}
                            <TimePicker
                                value={state.start.time}
                                onChange={this.handleStartTime}
                            />
                        {/* )} */}
                    </div>

                    {/* -----------------SLOT END DATETIME----------------- */}
                    <div className={classes.dateTimeContainer}>
                        <div className={classes.selectInputContainer}>
                            <div className="adornment start">To</div>
                            <div className={classes.datePicker}>
                                <SingleDatePicker
                                    date={state.end.date}
                                    isOutsideRange={function noRefCheck() { }}
                                    id="end_date"
                                    onDateChange={date => this.setState({
                                        end: { ...state.end, date }
                                    })}
                                    onFocusChange={({ focused }) => this.setState({ endDateFocus: focused })}
                                    focused={state.endDateFocus}
                                    noBorder={true}
                                    numberOfMonths={1}
                                    hideKeyboardShortcutsPanel
                                    displayFormat={'DD MMM YYYY'}
                                    disabled={Boolean(seletedDate) || !state.showRepeatSection} // Datepicker disabled for edit slot and edit solt for rates and availability
                                />
                            </div>
                        </div>

                        {!state.allDay && (
                            <TimePicker
                                value={state.end.time}
                                onChange={time => this.setState({
                                    end: { ...state.end, time }
                                })}
                            />
                        )}
                    </div>

                    {/* -----------------ALL DAY CHECK------------------- */}
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                            <Checkbox
                                id="all_day"
                                name="allDay"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={state.allDay}
                                onChange={this.checkboxHandler}
                            />
                        }
                        label="All Day"
                    />

                    {state.showRepeatSection && (
                        <>
                            {/* --------------SLOT REPEAT------------- */}
                            <div className={classes.label}>Repeat</div>

                            {/* ----------------------REAPEAT TYPE--------------------------- */}
                            <FormControl variant="outlined" className={classes.selectFormControl}>
                                <Select
                                    native
                                    value={state.repeat.type}
                                    onChange={this.dependantInputHandler}
                                    className={classes.selectInput}
                                    inputProps={{
                                        'data-type': 'repeat',
                                        'data-attr-name': 'type',
                                        id: 'repeat_type',
                                    }}
                                >
                                    {Object.keys(repeatTypes).map(type => (
                                        <option value={repeatTypes[type].id} key={`repeatType_${repeatTypes[type].id}`}>
                                            {repeatTypes[type].title}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>


                            {state.repeat.type !== repeatTypes.off.id && (
                                <>
                                    {/* ---------------------REAPEAT DURATION------------------------- */}
                                    <FormControl variant="outlined" className={classes.selectFormControl}>
                                        <div className={classes.selectInputContainer}>
                                            <div className="adornment start">Every</div>
                                            <Select
                                                native
                                                value={state.repeat.interval}
                                                onChange={this.dependantInputHandler}
                                                className={classes.selectInput}
                                                inputProps={{
                                                    'data-type': 'repeat',
                                                    'data-attr-name': 'interval',
                                                    id: 'repeat_interval',
                                                }}
                                            >
                                                {Array(repeatTypes[state.repeat.type].maxDuration)
                                                    .fill()
                                                    .map((_, i) => (
                                                        <option value={i + 1} key={`duration_${i}`}>{i + 1}</option>
                                                    ))}
                                            </Select>
                                            <div className="adornment end">
                                                {repeatTypes[state.repeat.type].id}
                                            </div>
                                        </div>
                                    </FormControl>

                                    {/* ------------------------------REAPEAT UNTIL-------------------------- */}
                                    <div className={clsx(classes.selectFormControl, classes.selectInputContainer)}>
                                        <div className="adornment start">Until</div>
                                        <div className={classes.datePicker}>
                                            <SingleDatePicker
                                                date={state.repeat.until}
                                                isOutsideRange={day => day.isBefore(moment.tz(new Date(), PropertyTimezone).format('YYYY-MM-DD'))}
                                                id="date_until"
                                                onDateChange={date => this.setState({
                                                    repeat: { ...state.repeat, until: date }
                                                })}
                                                onFocusChange={({ focused }) => this.setState({ repeatUntilFocus: focused })}
                                                focused={state.repeatUntilFocus}
                                                noBorder={true}
                                                numberOfMonths={1}
                                                displayFormat={'DD MMM YYYY'}
                                                hideKeyboardShortcutsPanel
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {state.repeat.type == repeatTypes.weeks.id && (
                                <>
                                    {/* ------------REAPEAT ON---------- */}
                                    <div className={classes.label}>On:</div>
                                    <SlotRepeatDays
                                        selectedDays={[...state.repeat.dayOfWeek]}
                                        selectDayHandler={this.selectDayHandler}
                                    />
                                </>
                            )}

                        </>
                    )}

                    {/* ------------CUT OFF START----------------- */}
                    {/* <div className={classes.label}>Cut off start</div>
                    <FormControl
                        key={`location_${data.value}`}
                        variant="outlined"
                        className={classes.cutOffSelect}
                    >
                        <Select
                            native
                            value={data.value}
                            onChange={this.onChangeHandler}
                            className={classes.selectInput}
                            inputProps={{
                                name: 'location'
                            }}
                        >
                            <option aria-label="None" value="" />
                            {locationList.map(theme =>(
                                <option value={theme} key={`theme_${theme}`}>
                                    {theme}
                                </option>
                            ))}
                        </Select>
                    </FormControl> */}

                    {/* --------------------CUT OFF START---------------------- */}
                    {/* <div className={classes.label}>Cut off time for your guest</div>
                    <div>If no one books by this window, the instance will be removed from your calendar.</div>
                    <FormControl
                        key={`location_${data.value}`}
                        variant="outlined"
                        className={classes.cutOffSelect}
                    >
                        <Select
                            native
                            value={data.value}
                            onChange={this.onChangeHandler}
                            className={classes.selectInput}
                            inputProps={{
                                name: 'location'
                            }}
                        >
                            <option aria-label="None" value="" />
                            {locationList.map(theme =>(
                                <option value={theme} key={`theme_${theme}`}>
                                    {theme}
                                </option>
                            ))}
                        </Select>
                    </FormControl> */}

                    {/* ------HIDDEN SUBMIT FORM BUTTON REFERRENCED BY PARENT COMPONENT----- */}
                    <button 
                        className={classes.hideBtn} 
                        ref={formSubmitRef} 
                        onClick={this.submitHandler} />
                </Grid>

                {/* ------------- Snackbar for frontend validation errors -------------- */}
                {state.errorData.status && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseSnackBar}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{state.errorData.message}</span>}
                    />
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const { spaces, dashboard, experiences } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace] || {},
        productList: experiences.activityList || {},
        conductorList: experiences.conductorList || {}
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(VariationForm)));