import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import clsx from 'clsx';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";


import { Button } from "@material-ui/core";
import moment from "moment-timezone";
import { DEVICE_VIEW, DISPLAY_SETTING, GetNextMonthStart, GetNextWeekStart, GetPreviousMonthStart, GetPreviousWeekStart } from './Helper';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    actionIcon: {
        cursor: 'pointer',
        opacity: 0.2,
        height: 20,
        width: 20,
        '&:hover , &.selected': {
            opacity: 1
        }
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > img': {
            marginLeft: 15,
        }
    },
    fullDate: {
        alignItems: 'center',
        background: 'transparent',
        boxShadow: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 20,
    },
    arrowContainer: {
        alignItems: 'center',
        // borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        marginRight: 0,
        marginLeft: 0,
        height: '30px',
        width: '30px',
    },
    arrow: {
        background: 'transparent',
        borderRadius: '50%',
        color: '#666666',
        height: '20px',
        width: '20px',
        minWidth: '20px',
        padding: 8,
        '&:hover': {
            background: '#edf2f7',
            color: '#000000'
        }
    },
    arrowIcon: {
        fontSize: '1.8rem',
    },
    picker: {
        '& input': {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '1.2rem',
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: 'center',
            width: 'calc(100% - 0px)',
        },
        '& .SingleDatePickerInput': {
            background: 'transparent'
        },
        '& .DateInput': {
            background: 'transparent',
            width: '80px',
            [theme.breakpoints.down('sm')]: {
                width: '75px',
            }
        },
        '& .DateInput_input': {
            background: 'transparent'
        }
    },
}));

const CalendarSetting = (props) => {
    const classes = useStyles();
    const [focused, setFocused] = React.useState(false);
    const [layout, setLayout] = React.useState(DISPLAY_SETTING.WEEK);

    React.useEffect(() => {
        if (props.view) {
            props.changeDateHandler(layout, props.date)
        }
    }, [props.view])

    const changeDateHandler = (date) => {
        props.changeDateHandler(layout, date)
    }

    const changeLayoutHandler = (value) => {
        setLayout(value)
        props.changeDateHandler(value, props.date)
    }

    const fetchPreviousDateHandle = () => {
        let date = props.date;
        if (layout === DISPLAY_SETTING.WEEK) {
            date = GetPreviousWeekStart(props.date);
        }
        if (layout === DISPLAY_SETTING.MONTH) {
            date = GetPreviousMonthStart(props.date)
        }
        props.changeDateHandler(layout, date)
    }

    const fetchNextDateHandle = () => {
        let date = props.date;
        if (layout === DISPLAY_SETTING.WEEK) {
            date = GetNextWeekStart(props.date);
        }
        if (layout === DISPLAY_SETTING.MONTH) {
            date = GetNextMonthStart(props.date)
        }
        props.changeDateHandler(layout, date)
    }

    return (
        <div className={classes.root}>
            {props.view === DEVICE_VIEW.LARGE &&
                <React.Fragment>
                    <img
                        className={clsx(classes.actionIcon, { 'selected': layout === DISPLAY_SETTING.MONTH })}
                        onClick={() => changeLayoutHandler(DISPLAY_SETTING.MONTH)}
                        src={IMAGES.ICONS.fourSquaresViewOptions}
                    />
                    <img
                        className={clsx(classes.actionIcon, { 'selected': layout === DISPLAY_SETTING.WEEK })}
                        onClick={() => changeLayoutHandler(DISPLAY_SETTING.WEEK)}
                        src={IMAGES.ICONS.square}
                    />

                </React.Fragment>
            }

            <div className={clsx(classes.fullDate, 'datepicker-container')} >
                <div className={classes.arrowContainer}>
                    <Button
                        className={clsx(classes.arrow, "left")}
                        onClick={fetchPreviousDateHandle}
                    >
                        <ArrowBackIosIcon className={clsx(classes.arrowIcon, 'datePicker-arrow-icon')} />
                    </Button>
                </div>
                <div className={classes.picker} style={{ background: 'transparent' }}>
                    <SingleDatePicker
                        date={moment(props.date)}
                        id={`start_date_${props.index}`}
                        onDateChange={(date) => changeDateHandler(date)}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        focused={focused}
                        noBorder={true}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        displayFormat={() =>
                            layout === DISPLAY_SETTING.MONTH
                                ? 'MMMM YYYY'
                                : 'DD MMM YYYY'
                        }
                        hideKeyboardShortcutsPanel
                        anchorDirection={"right"}
                    />
                </div>
                <div className={classes.arrowContainer}>
                    <Button
                        className={clsx(classes.arrow, "right")}
                        onClick={fetchNextDateHandle}
                    >
                        <ArrowForwardIosIcon className={clsx(classes.arrowIcon, 'datePicker-arrow-icon')} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CalendarSetting;