import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Masonry from 'react-masonry-css';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchFilters from '../floor-plan/SearchFilters';
import BookingInfoFooter from '../floor-plan/BookingInfoFooter';
import RoomCard from '../floor-plan/RoomCard';

import { getRoomsInfo, applySearchFilter } from '../floor-plan/helper';
import { INIT_FILTERS } from '../floor-plan/constants';
import { fetchAvailabeBeds, resetFloorPlan } from '../../redux/actions/floorPlan/floorPlan';
import { fetchNoOfDays } from '../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 90px)',
        marginTop: '20px',
        padding: "20px",
        boxSizing: "border-box",
        position: 'relative'
    },
    mainContent: {
        height: "calc(100vh - 185px)",
        overflow: "auto",
        position: 'relative'
    },
    listContainer: {
        margin: "30px 0px",
    },
    noData: {
        color: "#999999",
        fontSize: "2rem",
        textAlign: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "100%",
        transform: "translate(-50%,-50%)",
        '& h4': { margin: "15px" },
        '& p': { 
            fontSize: "1.6rem",
            margin: "unset"
        },
        [theme.breakpoints.down('xs')]: {
            position: 'unset',
            transform: 'unset'
        }
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
}));

const FloorPlanPage = props =>{

    const classes = useStyles();
    const theme = useTheme();
    const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { currentSpace, rooms, dispatch, isLoading } = props;

    const [filters, setFilters] = useState({ ...INIT_FILTERS });
    const [roomsData, setRoomsData] = useState([]);
    const [bookingDuration, setBookingDuration] = useState(0);

    useEffect(() =>{
        return () => resetHandler();
    }, []);

    const onChangeFilter = (filtr) =>{
        setFilters({ ...filtr });
        displayRooms(filtr);
    }

    const onSearchHandler = (filtrs) =>{
        const params = {
            startDate: filtrs.startDate.format('YYYY-MM-DD'),
            endDate: filtrs.endDate.format('YYYY-MM-DD')
        }
        dispatch(fetchAvailabeBeds(currentSpace.propertyID, params));
        setBookingDuration(fetchNoOfDays(filtrs.startDate, filtrs.endDate));
        onChangeFilter({ ...filtrs });
    }
    
    const displayRooms = (filtrs) =>{

        if(!filtrs.startDate || !filtrs.endDate) return;

        const roomsList = getRoomsInfo(currentSpace.rooms, rooms);
        const filteredRoomList = applySearchFilter(filtrs, roomsList);
        setRoomsData([ ...filteredRoomList ]);
    }

    const resetHandler = () =>{
        setFilters({ ...INIT_FILTERS });
        setRoomsData([]);
        setBookingDuration(0);
        dispatch(resetFloorPlan());
    }

    return(
        <div className={classes.root}>
            <div className={clsx(classes.mainContent, 'hideScrollBar')}>

                <SearchFilters
                    filters={filters}
                    onChangeFilter={onChangeFilter} 
                    onSearchHandler={onSearchHandler}
                />

                <div className={classes.listContainer}>
                    <Masonry
                        breakpointCols={deviceSm ? 1 : 3}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {roomsData.map(room =>(
                            <RoomCard 
                                key={`roomID_${room._id}`}
                                roomsData={room}
                                selectedRatePlanID={filters.ratePlanID}
                                duration={bookingDuration}
                            />
                        ))}
                    </Masonry>
                </div>

                {roomsData.length == 0 && (
                    <div className={classes.noData}>
                        <h4>Floor Plan</h4>
                        <p>Select check in and check out dates to proceed</p>
                    </div>
                )}
            </div>

            <BookingInfoFooter  
                duration={bookingDuration}  
                ratePlanID={filters.ratePlanID}    
                startDate={filters.startDate} 
                endDate={filters.endDate}
                currentSpace={currentSpace}
                resetHandler={resetHandler}
            />

            {isLoading && (
                <div className={classes.loadingState}>
                    <CircularProgress className={classes.loader} />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms, buildings, dashboard, spaces, loading } = state;
    const currentSpaceID = dashboard.currentSpace;
    const currentSpace = spaces[currentSpaceID];
    return{ 
        rooms, 
        buildings,
        currentSpace,
        isLoading: loading.GET_FLOOR_PLAN_ROOM_MAPS || loading.LOAD_PROPERTY_CONTENT
    }
}

export default connect(mapStateToProps)(FloorPlanPage);