import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Note from '../../common/Note';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  notesContainer: {
    flexGrow: 1,
    marginTop: '10px',
    overflowY: 'auto',
    width: '100%',
    paddingRight: '25px',
    boxSizing: 'content-box',
  },
  noteContainer: {
    background: '#FFF',
    border: "none",
    borderRadius: 5,
    marginBottom: 10,
    padding: theme.spacing(2),
  },
  lightText: {
    color: "#828282",
    fontWeight: 400
  },
  note: {
    display: 'block',
    fontSize: '1.4rem',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    width: '100%',
    wordWrap: 'break-word',
  },
  newNoteContainer: {
    width: '100%',
  },
  newNote: {
    alignItems: ' center',
    display: 'flex',
  },
  formBox: {
    alignItems: "center",
    borderRadius: "8px",
    color: "#828282",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 10,
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: '100%',
    "& .MuiOutlinedInput-multiline": {
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: '#fff'
    },
    "& textarea": {
      backgroundColor: "#FFFFFF",
      fontSize: '1.4rem'
    },
    "& .spanButton": {
      fontSize: "16px",
      cursor: "pointer",
      padding: "0 6px 0 18px"
    }
  },
}));

const NotesTab = props => {
  const classes = useStyles();
  const { notes, currentSpace } = props;
  const [note, setNote] = React.useState('')
  const [error, setError] = React.useState({
    note: false
  })

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && note != '') {
      e.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    let hasErrors = !note;
    if (!hasErrors) {
      setState({ note: "" });
    }
  };

  const notesView = notes.map((item, index) => (
    item ?
      <Note
        key={index}
        name={item.systemGenerated ? "System" : item.addedBy} 
        date={item.createdAt}
        timezone={currentSpace.timezone} 
        message={item.note}
      />
      : null
  ));

  const noNotes = (
    <Grid item xs={12} className={classes.noteContainer}>
      <Typography className={clsx(classes.lightText, classes.note)} component="p">
        This booking does not have any notes yet.
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <div className={classes.notesContainer}>
        <Grid container>
          {notes.length !== 0 && notesView}
          {notes.length === 0 && noNotes}
        </Grid>
      </div>

      {/* <div className={classes.newNoteContainer}>
        <div className={classes.newNote}>
          <div className={classes.formBox}>
            <TextField
              id="note"
              error={error.note}
              variant="outlined"
              value={note}
              fullWidth
              multiline
              placeholder="Type new note here.."
              onChange={(e) => {
                setNote(e.target.value);
                if (e.target.value) setError({ note: false });
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </div> */}

    </div>
  );
}

const mapStateToProps = state => {
  return {
    notes: [],
    currentSpace: state.spaces[state.dashboard.currentSpace]
  }
}

export default connect(mapStateToProps)(NotesTab);