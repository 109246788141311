import React from 'react';
import clsx from 'clsx';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import { getAmtFormat, fetchDateFormat, DATE_FORMATS, fetchCurrencyFormat, getMomentAddDayISO } from '../../../utils/utility';
import { ICONS } from '../../../utils/imageUrls';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { getAccommodationName } from '../../../utils/helper';

const styles = (theme) => ({
  container: {
    borderBottom: "0px solid #dddddd",
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      padding: '20px 5px'
    }
  },
  block: {
    borderRadius: 2,
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    backgroundColor: '#ffffff',
    padding: 20,
    minHeight: 175,
    margin: 4,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px'
    }
  },
  selected: {
    backgroundColor: '#f2f2f2',
  },
  lightText: {
    marginTop: 5,
    color: '#cbcbcb',
    fontSize: '1.2rem'
  },
  iconSection: {
    backgroundColor: '#fff',
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    margin: 10,
    padding: '10px 20px',
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      padding: 5,
      margin: '10px 0'
    }
  },
  unavailable: {
    backgroundColor: '#E37381'
  },
  edit: {
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    backgroundColor: '#ffffff',
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    margin: 4,
    padding: 10,
    cursor: 'pointer'
  },
  blockDelete: {
    minHeight: 125,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    [theme.breakpoints.down('xs')]: {
      width: 15,
      height: 15,
    },
    '&.inverted': {
      '-webkit-filter': 'invert(100%)',
      filter: 'invert(100%)',
    }
  },
  editDisabled: {
    opacity: '0.3'
  }
});

const ManualAccommodation = props => {
  const { classes, day, data, currentSpace, selectedDate, itemID, numberOfDays, roomTypes, rooms, beds, t } = props;
  const currency = currentSpace && currentSpace.currency ? fetchCurrencyFormat(currentSpace.currency) : null;
  let unavailableIndex = 0;
  let previousItemID = null;
  let savedDate = data.filter((d) => d.isSaved);
  let firstItem = savedDate.length ? savedDate[0].date : null;
  let lastItem = savedDate.length ? savedDate[savedDate.length - 1].date : null;
  let nextOfLastItem = data.length ? data[data.length - 1].date : null;
  nextOfLastItem = nextOfLastItem ? getMomentAddDayISO(nextOfLastItem) : null;

  return (
    <div className={classes.container}>
      {
        day.days.map((dayValue, dayIndex) => {
          let matchingData = data.find(item =>
            fetchDateFormat(dayValue, DATE_FORMATS.DEFAULT_DATE) === fetchDateFormat(item.date, DATE_FORMATS.DEFAULT_DATE)
          );
          let roomTypeID = matchingData && matchingData.roomTypeID;
          let roomID = matchingData && matchingData.roomID;
          let bedID = matchingData && matchingData.bedID;
          let dormOrPrivate = roomTypeID && roomTypes[roomTypeID] && roomTypes[roomTypeID].dormOrPrivate;
          let roomName = (roomID && rooms[roomID] && rooms[roomID].name) || '';
          let bedName = (bedID && beds[bedID] && beds[bedID].name) || '';
          let firstNight = props.edit && matchingData && matchingData.date && matchingData.date === firstItem;
          let lastNight = props.edit && matchingData && matchingData.date && matchingData.date === lastItem;
          let enableExtend = props.edit && (fetchDateFormat(dayValue, DATE_FORMATS.DEFAULT_DATE) === fetchDateFormat(nextOfLastItem, DATE_FORMATS.DEFAULT_DATE));


          if ((!roomTypeID || (bedID !== previousItemID && previousItemID)) && matchingData && matchingData.date) {
            unavailableIndex++;
          } else {
            unavailableIndex = 0
          }
          let borderWidth = unavailableIndex >= 1 ? '4px' : '0px';
          const isUnallocated = !data[data.length - 1].bedID
          previousItemID = bedID;

          return (
            <div
              key={dayIndex}
              style={{
                width: `calc(${100 / numberOfDays}% - ${borderWidth}`,
                borderLeft: `${borderWidth} solid #d96977`
              }}>
              {
                matchingData && matchingData.date ? (
                  <>
                    {/* ----------------------------- Accommodation room and rate --------------------------- */}
                    <div className={clsx({
                      [classes.block]: true,
                      [classes.selected]: selectedDate === matchingData.date,
                      [classes.blockDelete]: !props.disableClicks && props.edit
                    })}
                      style={{ 
                        cursor: (props.disableClicks || props.edit) ? 'default' : 'pointer',
                        wordWrap: 'break-word'
                      }}
                      onClick={() => {
                        if (!props.disableClicks && !props.edit) {
                          props.handleSelected(itemID, selectedDate, matchingData.date)
                        }
                      }}
                    >
                      {/* {dormOrPrivate === 'dorm' ?
                        bedName.toLowerCase().includes(roomName.toLowerCase()) ?
                          <span className={classes.text}>{bedName}</span>
                          :
                          <span className={classes.text}>{((roomName || '') + ' ' + (bedName || '')).trim()}</span>
                        :
                        <span className={classes.text}>{roomName}</span>
                      } */}

                      {<span className={classes.text}>{getAccommodationName({ dormOrPrivate, roomName, bedName })}</span>}

                      <br /><br />
                      <span className={classes.text}>{getAmtFormat(matchingData.rate, currency)}</span>
                      <br />
                      <span className={classes.lightText}>Base rate</span>
                    </div>

                    {/* ------------------------------------ Edit buttons ----------------------------- */}
                    {props.edit &&
                      <Tooltip title={props.disableClicks ? t('newReservation.manualAccommodation.cancelEditResNotAllowedText') : (bedID ? '' : t('newReservation.manualAccommodation.unallocEditResNotAllowedText'))} >
                        <div
                          className={clsx(classes.edit, {[classes.editDisabled]: !bedID || props.disableClicks})}
                          onClick={() => {
                            if (bedID && !props.disableClicks)
                              props.handleSelected(itemID, selectedDate, matchingData.date, null, firstNight, lastNight)
                          }}
                        >
                          {matchingData.ID &&
                            <EditIcon className={classes.editIcon} />
                          }
                        </div>
                      </Tooltip>
                    }
                  </>
                ) :
                  /* ------------------------------------ Extend ----------------------------- */
                  (enableExtend) ?
                    <Tooltip title={props.disableClicks ? t('newReservation.manualAccommodation.cancelEditResNotAllowedText') : (isUnallocated ? t('newReservation.manualAccommodation.unallocEditResNotAllowedText') : '')} >
                      <div
                        className={clsx({
                          [classes.block]: true,
                          [classes.add]: props.edit,
                          [classes.selected]: selectedDate === fetchDateFormat(dayValue, DATE_FORMATS.DEFAULT_DATE),
                          [classes.editDisabled]: isUnallocated || props.disableClicks
                        })}
                        style={{ cursor: props.disableClicks ? 'default' : 'pointer' }}
                        onClick={() => {
                          if (!isUnallocated && !props.disableClicks)
                            props.handleAdd(itemID, selectedDate, fetchDateFormat(dayValue, DATE_FORMATS.DEFAULT_DATE))
                        }}
                      >
                        <img className={classes.icon} src={ICONS.ADD} />
                      </div>
                    </Tooltip>
                    : <div className={classes.block} style={{ cursor: props.disableClicks ? 'default' : 'pointer' }}></div>
              }
            </div>
          )
        })
      }
    </div>
  )
}

const mapStateToProps = state => ({
  roomTypes: state.roomTypes,
  rooms: state.rooms,
  beds: state.beds
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(ManualAccommodation)))