import http from "../../utils/http";
import { normalize } from 'normalizr';
import { todoCategorySchema, todoSchema, commentSchema } from '../../schema';
import { setCurrentTodo } from '../dashboard/dashboard';

//----------------------------------------------------------------
// LOAD TO DO LIST
//----------------------------------------------------------------
export function loadTodos(spaceID) {
  const ACTION_NAME = "LOAD_TODOS";
  return function(dispatch) {
    return http.get(`/api/task/${spaceID}?sortBy=categoryID:asc`)
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      var normalizedOrder = normalize(data, [ todoCategorySchema ] );
      // console.log(normalizedOrder.entities);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities})
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// TOGGLE TODO
//----------------------------------------------------------------
export function updateTodo(todoID, newStatus) {
  const ACTION_NAME = "UPDATE_TODO";
  return function(dispatch) {
    return http.patch(`/api/task/${todoID}`, {status: newStatus}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      var normalizedOrder = normalize(data, todoSchema);
      // console.log(normalizedOrder.entities)
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {todo: normalizedOrder.entities.todos, todoID}})
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// ADD TODO
//----------------------------------------------------------------
export function addTodo(categoryID, todo) {
  const ACTION_NAME = "ADD_TODO";
  return function(dispatch) {
    return http.post(`/api/task/${categoryID}`, {...todo}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      // var normalizedOrder = normalize(data, todoSchema);
      // console.log(normalizedOrder.entities)
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data})
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// EDIT TODO
//----------------------------------------------------------------
export function editTodo(todoID, updates) {
  const ACTION_NAME = "EDIT_TODO";
  return function(dispatch) {
    return http.patch(`/api/task/${todoID}`, {...updates}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      var normalizedOrder = normalize(data, todoSchema);
      // console.log(normalizedOrder.entities);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {todo: normalizedOrder.entities.todos, todoID}})
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// DELETE TODO
//----------------------------------------------------------------
export function deleteTodo(todoID) {
  const ACTION_NAME = "DELETE_TODO";
  return function(dispatch) {
    return http.delete(`/api/task/${todoID}`, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data})
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// FETCH TODO
//----------------------------------------------------------------
export function fetchTodo(spaceID, todoID) {
  const ACTION_NAME = "FETCH_TODO";
  return function(dispatch) {
    return http.get(`/api/task/${spaceID}/${todoID}`)
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      var normalizedOrder = normalize(data, todoSchema);
      // console.log(normalizedOrder.entities);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {todo: normalizedOrder.entities.todos, todoID}})
      dispatch(setCurrentTodo(todoID));
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

