import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    fontWeight: 600,
    marginTop: 40,
    marginBottom: 10
  },
  listIcon: {
    fontSize: 20,
    marginRight: 10
  }
}))

const CommentsHeader = (props) => {
  const { t } = props
  const classes = useStyles();

  return(
    <div className={classes.header}>
      <ListIcon className={classes.listIcon} />
      <Typography component="h4" variant="h4">{t('comment.activity')}</Typography>
    </div>
  )
}

export default withTranslation()(CommentsHeader);
