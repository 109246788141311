import { schema } from 'normalizr';

const commentSchema = new schema.Entity('comments', {}, {
  idAttribute: '_id'
})

const announcementSchema = new schema.Entity('announcements', {
  comments: [commentSchema]
}, {
  idAttribute: '_id'
});

export { announcementSchema }