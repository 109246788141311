import http from "../../utils/http";


//----------------------------------------------------------------
// CREATE ORDER 
//----------------------------------------------------------------
export function createOrder(order) {
    const ACTION_NAME = "CREATE_ORDER";
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .post(`/api/pos/order`, { ...order })
        .then(successResult => {
          const data = successResult.data;
          dispatch({
            type: `${ACTION_NAME}_SUCCESS`,
            payload: { ...data.resvDetails, reservationID: data.order.reservationID }
          });
        })
        .catch(errorResult => {
          console.log(errorResult)
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        });
    };
  }

//----------------------------------------------------------------
// DELETE ORDER 
//----------------------------------------------------------------
export function deleteOrder(reservationID, payload) {
    const ACTION_NAME = "DELETE_ORDER";
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .patch(`/api/pos/order/reservation/${reservationID}`, payload)
        .then(successResult => {
          const data = successResult.data;
          dispatch({
            type: `${ACTION_NAME}_SUCCESS`,
            payload: { ...data, reservationID: reservationID }
          });
        })
        .catch(errorResult => {
          console.log(errorResult)
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        });
    };
  }