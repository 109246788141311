import React from 'react';
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";
import Trix from "trix";
import { initials } from '../wordUtils';
import { escape } from 'lodash';

class Mentions {
    constructor(editorEl, mentionList){
        const escapedMentionList = mentionList?.map((mention) => {
          return {
            ...mention,
            value: escape(mention.value),
            email: escape(mention.email),
          };
        });
        this.editorEl =  editorEl;
        this.editor = editorEl.editor;
        this.mentionList = escapedMentionList;
        this.mentionIDList = new Set();
        this.isMenuActive = false;
    }

    fetchMentionAvatar = profileData =>{
        if(profileData.profileImage){
            return `<img class="mentions-profile-image" src="${profileData.profileImage}" />`
        }
        return `<div class="default-mention-profile-image" title="profile pic">${initials(profileData.value)}</div>`
    }

    init = () => {
        this.tribute = new Tribute({
            trigger: '@',
            allowSpaces: true,
            lookup: 'value',
            menuItemLimit: 10,
            values: this.mentionList,
            itemClass: 'mentions-item',
            menuItemTemplate: (item) => {
                const { original } = item;
                const userName = original.value;
                const userEmail = original.email;
                return `<span>
                        ${this.fetchMentionAvatar(item.original)}
                        <div class="mentions-info">
                            <div class="mentions-user-name">${userName}</div>
                            <div class="mentions-email">${userEmail}</div>
                        </div>
                    </span>`;
            }
        });
        this.tribute.attach(this.editorEl);
        this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
        this.editorEl.addEventListener("tribute-replaced", this.replaced);
        this.editorEl.addEventListener("trix-attachment-remove", this.deleteMentionHandler);

        this.editorEl.addEventListener("tribute-active-true", this.setMenuActive);
        this.editorEl.addEventListener("tribute-active-false", this.setMenuInActive);
    }

    replaced = (e) => {
        let mention = e.detail.item.original;

        this.mentionIDList.add(mention.id);
        let attachment = new Trix.Attachment({
            mentionID: mention.id,
            content: `<span class="mention">
                        ${this.fetchMentionAvatar(mention)}
                        <span>${mention.value}</span>
                    </span>`
        });
        this.editor.insertAttachment(attachment);
        this.editor.insertString("  ");
    }

    _pasteHtml = (html, startPos, endPos) => {
        let position = this.editor.getPosition();
        this.editor.setSelectedRange([position - [endPos - startPos], position])
        this.editor.deleteInDirection("backward");
    }

    deleteMentionHandler = event =>{
        const { values: {
            mentionID
        } } = event.attachment.attachment.attributes;

        if(mentionID) this.mentionIDList.delete(mentionID);
    }

    setMenuActive = () =>{
        this.isMenuActive = true;
    }

    setMenuInActive = () =>{
        this.isMenuActive = false;
    }

    clearListners = () =>{
        this.editorEl.removeEventListener("tribute-replaced", this.replaced);
        this.editorEl.removeEventListener("trix-attachment-remove", this.deleteMentionHandler);
        this.editorEl.removeEventListener("tribute-active-true", this.setMenuActive);
        this.editorEl.removeEventListener("tribute-active-false", this.setMenuInActive);
    }
}

export default Mentions;