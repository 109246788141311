import http from '../../utils/http';
// import tempData from '../../../components/masterway/tempData';

// ----------------------------------------------------------------
// FETCH MASTERWAY CONFIG
// ----------------------------------------------------------------
export function fetchMasterwayConfig(propertyID) {
    const ACTION_NAME = 'FETCH_MASTERWAY_CONFIG'
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` })
        return http
            .get(`/api/frontdesk/masterway/config/${propertyID}`)
            .then(successResult => {
                const data = successResult.data
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
            })
            .catch(errorResult => {
                console.log(errorResult)
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
            })
    }
}

// ----------------------------------------------------------------
// FETCH PATCH MASTERWAY CONFIG
// ----------------------------------------------------------------
export function patchMasterwayConfig(propertyID, config) {
    const ACTION_NAME = 'PATCH_MASTERWAY_CONFIG'
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` })
        return http
            .patch(`/api/frontdesk/masterway/config/${propertyID}`, config)
            .then(successResult => {
                const data = successResult.data
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
            })
            .catch(errorResult => {
                console.log(errorResult)
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
            })
    }
}

// ----------------------------------------------------------------
// FETCH MASTERWAY BILL STATUS
// ----------------------------------------------------------------
export function getMasterwayBillStatus(propertyID) {
    const ACTION_NAME = 'FETCH_MASTERWAY_BILL_STATUS'
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .get(`/api/frontdesk/masterway/billStatus/${propertyID}`)
            .then(successResult => {
                const data = successResult.data
                const billsData = {};

                //NORMALIZE FAILED BILLS....................
                for(const item of data){
                    billsData[item.billID] = { ...item };
                }

                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: billsData })
                return data;
            })
            .catch(errorResult => {
                console.log(errorResult)
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
            })
    }
}

// ----------------------------------------------------------------
// FETCH MASTERWAY BILL STATUS
// ----------------------------------------------------------------
export function retryMasterFailedBill(billID) {
    const ACTION_NAME = 'RETRY_MASTERWAY_FAILED_BILL'
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .get(`/api/frontdesk/masterway/retryBill/${billID}`)
            .then(successResult => {
                const data = successResult.data;
                const updatedBill = { [data.billID]: { ...data }};
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: updatedBill });
                return data;
            })
            .catch(errorResult => {
                console.log(errorResult)
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
            })
    }
}