import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import clsx from 'clsx';

import { loadSibaSetup, submitSibaSetup, updateSibaSetup } from '../../redux/actions/legal/siba';
import { resetSuccess } from '../../redux/actions/common/common';

import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles( theme => ({
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10,
    textAlign: 'center'
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 30,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    marginBottom: 10,
    marginTop: 10,
  },
  error: {
    marginTop: 10,
    color: 'red',
    fontSize: '1.2rem'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  roomOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%'
  },
  roomTypeOptions: {
    width: '100%'
  },
  topMargin: {
    marginTop: 20
  },
  rowItemsHeader:{
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center"
  },
  startContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.35rem',
    fontWeight: 600
  },
  endContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 30
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  iconView: {
    borderRadius: '50%',
    //background: '#ffffff',
    color: '#666666',
    cursor:'pointer',
    fontSize: '2rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  nonEdit: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 600,
    height: 30,
    marginTop: 10,
    paddingLeft: theme.spacing(2)
  },
  roomChip: {
    cursor: 'pointer',
    marginRight: 5,
    marginBottom: 5
  },
  bottomPadding: {
    paddingBottom: 50
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: '0 2px 70px 0 #E0E0E0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginBottom: 50
  })
}

const Setup = (props) => {
  const { t, currentSpace, rooms, legal } = props
  const classes = useStyles()

  const [form, setForm] = React.useState({ abbreviation: '', accessKey: '', contactName: '', contactEmail: '', establishment: '', nipc: '', zipzone: '' })
  const [save, setSave] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selRoom, setRoomSelection] = React.useState([])
  const [selection, setSelection] = React.useState({})
  const [message, setMessage] = React.useState('')

  const [error, setError] = React.useState(
    {nipc: false, establishment: false, accessKey: false, abbreviation: false, contactName: false, contactEmail: false, zipzone: false}
  )

  let roomOptions = []

  rooms && Object.keys(rooms).map(room => {
    if(currentSpace && currentSpace.roomTypes) {
      const check = currentSpace.roomTypes.includes(rooms[room].primaryRoomTypeID)
      if(check)
        roomOptions.push({ value: rooms[room]._id, label: rooms[room].name })
    }
  })

  useEffect(() => {
    let sel = []
    if(legal && legal.siba) {
      setEdit(!Boolean(legal.siba.nipc))
      setForm({ ...form, ...legal.siba })
      if(legal.siba.roomIDs && legal.siba.roomIDs.length > 0) {
        setSelection({ ...selection, rooms: legal.siba.roomIDs})

        Object.keys(rooms).map(room => {
          if(legal.siba.roomIDs.includes(room)) {
            sel.push({ value: rooms[room]._id, label: rooms[room].name })
          }
        })
        if(sel.length > 0) setRoomSelection(sel)
      }
    }
  }, [legal])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleChange = (e) => {
    if(!save) setSave(true)
    if (e.target.value !== '') {
      setError({...error, [e.target.id]: false})
    }
    setForm({...form, [e.target.name]: e.target.value});
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['nipc', 'establishment', 'accessKey', 'abbreviation', 'contactName', 'contactEmail', 'zipzone'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setSave(false)
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () =>{
    const check = handleValidate();
    if (check) {
      const body = {
        propertyID: currentSpace.propertyID,
        ...form
      }

      if(selection.rooms && selection.rooms.length > 0) {
        body.roomIDs = selection.rooms
      }

      if(legal.siba && legal.siba._id){
        props.dispatch(updateSibaSetup(currentSpace.propertyID, body))
        .then((res) => {
          if(res.status === 200){
            setMessage('Configuration updated successfully!')
            setOpenSnackbar(true)
            setEdit(false)
            setSave(false)
          }
        })
      }
      else {
        props.dispatch(submitSibaSetup(body))
        .then((res) => {
          if(res.status === 201 || res.status === 200){
            setMessage('Configuration saved successfully!')
            setOpenSnackbar(true)
            setEdit(false)
            setSave(false)
          }
        })
      }
    }
  }

  const selectRooms = (selected) => {
    if(!save) setSave(true)
    if (selected) {
      setSelection({ ...selection, rooms: selected.map(e => e.value) })
    } else {
      setSelection({ ...selection, rooms: [] })
    }
  }

  const handleEdit = () => {
    if(legal.siba && legal.siba.nipc)
      setEdit(!edit)
  }

  return (
    <div className={clsx({ [classes.bottomPadding]: edit })}>
      <Grid item xs={12}>
        <div className={classes.row}>
          <div className={classes.rowItemsHeader}>
            <div className={classes.startContainer}>
              <span>{t('automate.siba.config')}</span>
            </div>
            <div className={classes.endContainer}>
              {(!edit && currentSpace.address && (currentSpace.address.length > 2) && currentSpace.city) && <span di className={classes.editContainer} onClick={handleEdit}>
                <EditIcon className={classes.iconView} />
              </span>}
              
              {(edit) && <span className={classes.editContainer} onClick={handleEdit}>
                <CloseIcon className={classes.iconView} />
              </span>}
            </div>
          </div>

          {((currentSpace.address.length < 3) || !currentSpace.address || !currentSpace.city) && <div className={classes.rowItemsHeader}>
            <div className={classes.rowItemsHeader}>
              <span className={classes.error}>{t('automate.siba.noteText')}</span>
            </div>            
          </div>
          }
          <Grid container alignItems="center" className={classes.topMargin}>
            <Grid item xs={5}>
              <div className={classes.label}>
                Name of the establishment
              </div>
            </Grid>
            <Grid item xs={7}>
              
              {<div className={classes.nonEdit}>
                {currentSpace.name}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center" >
            <Grid item xs={5}>
              <div className={classes.label}>
                Address
              </div>
            </Grid>
            <Grid item xs={7}>
              
              {<div className={classes.nonEdit}>
                {currentSpace.address&& currentSpace.address.length>2 ? currentSpace.address : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container  alignItems="center" >
            <Grid item xs={5}>
              <div className={classes.label}>
                Location
              </div>
            </Grid>
            <Grid item xs={7}>              
              <div className={classes.nonEdit}>
                {currentSpace.city}
              </div>
            </Grid>
          </Grid>
          <Grid container alignItems="center" >
            <Grid item xs={5}>
              <div className={classes.label}>
                NIPC
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.nipc ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="nipc"
                  name="nipc"
                  value={form.nipc}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.nipc) ? legal.siba.nipc : ''}
              </div>}
              {edit && error.nipc && <div className={classes.error}>{t('automate.siba.nipcError')}</div>}
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                Establishment
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.establishment ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="establishment"
                  name="establishment"
                  value={form.establishment}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.establishment && <div className={classes.error}>{t('automate.siba.estError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.establishment) ? legal.siba.establishment : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                Access Key
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.accessKey ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="accessKey"
                  name="accessKey"
                  value={form.accessKey}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.accessKey && <div className={classes.error}>{t('automate.siba.keyError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.accessKey) ? legal.siba.accessKey : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.abbv')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.abbreviation ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="abbreviation"
                  name="abbreviation"
                  value={form.abbreviation}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.abbreviation && <div className={classes.error}>{t('automate.siba.abbvError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.abbreviation) ? legal.siba.abbreviation : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.contactName')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.contactName ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="contactName"
                  name="contactName"
                  value={form.contactName}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.contactName && <div className={classes.error}>{t('automate.siba.contactNameError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.contactName) ? legal.siba.contactName : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.contactEmail')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.contactEmail ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="contactEmail"
                  name="contactEmail"
                  value={form.contactEmail}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.contactEmail && <div className={classes.error}>{t('automate.siba.contactEmailError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.contactEmail) ? legal.siba.contactEmail : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.zip')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.field} style={{border: error.zipzone ? '1px solid red' : '1px solid #ffffff'}}>
                <InputBase 
                  id="zipzone"
                  name="zipzone"
                  value={form.zipzone}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>}
              {edit && error.zipzone && <div className={classes.error}>{t('automate.siba.zipError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(legal.siba.zipzone) ? legal.siba.zipzone : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.topMargin}>
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.rooms')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={classes.roomOptions}>
                <Select
                  isMulti
                  isClearable={false}
                  defaultValue={roomOptions && roomOptions.filter(e => legal && legal.siba && legal.siba.roomIDs && legal.siba.roomIDs.length && legal.siba.roomIDs.includes(e.value))}
                  styles={selectStyle}
                  options={roomOptions}
                  maxMenuHeight={200}
                  onChange={selectRooms}
                  placeholder='Select Room(s)'
                  className={classes.roomTypeOptions}
                />
              </div>}
              {!edit && <div style={{marginTop: 5}}>
                {(selRoom.length > 0) &&
                  selRoom.map((sel, index) => {
                    return (<Chip
                      key={index}
                      className={classes.roomChip}
                      variant="outlined"
                      size="small"
                      label={sel.label}
                    />)
                  })
                }
                {(selRoom.length === 0) &&
                  <Chip
                    className={classes.roomChip}
                    variant="outlined"
                    size="small"
                    label={'No tags found'}
                  />
                }
              </div>}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {(save) && <div className={classes.buttonsContainer}>
        <div className={classes.button} onClick={handleSubmit}>
          {t('actions.save')}
        </div>
      </div>}
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  rooms: state.rooms,
  legal: state.legal,
  loadingProperty: state.loading.LOAD_PROPERTY,
})

export default withTranslation()(connect(mapStateToProps)(Setup))