import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid',
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(3),
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 14,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    listContainer: {
        overflowY: 'auto'
    },
    listSection: {
        margin: theme.spacing(3),
        minHeight: 'calc(100vh - 250px)',
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(7),
        }
    },
    smallSection: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            minHeight: 200
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1':{
            fontFamily: '"Gothic A1", sans- serif',
        }
    },
    buttonContainer: {
        margin: '10px 24px',
        textAlign: 'end'
    },
    button: {
        background: '#000',
        color: '#fff',
        fontWeight: 700,
        marginLeft: 5,
        '&:hover': {
            background: '#000'
        }
    },
    titleText: {
        fontSize: '1.5rem',
        marginBottom: 10,
        fontFamily: '"Gothic A1", sans- serif',
    },
    note: {
        fontSize: '1.2rem',
        fontFamily: '"Gothic A1", sans- serif',
        fontWeight: 600,
        marginTop: 10,
    }
}));

const DeleteConfirm = (props) => {
    const classes = useStyles();
    const { title, type, loading, isSystemAdmin } = props;
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText(' ');
        setError(false);
    };

    const submitHandler = () => {
        if (value) {
            props.submitHandler(value === "true")
        } else {
            setHelperText('Please select an option.');
            setError(true);
        }
    };

    return (
        <Dialog
            open={true}
            onClose={props.closeModalHandler}
            maxWidth={'sm'}
            fullWidth={true}>

            <div className={classes.dialogTitle}>
                <div className={classes.header}>{title}</div>
                <CloseIcon className={classes.closeIcon} onClick={props.closeModalHandler} />
            </div>
            <div className={classes.listContainer}>
                <div className={classes.smallSection}>
                    <div className={classes.titleText}>How to handle any existing reservations in the {type}</div>

                    <FormControl component="fieldset" error={error} className={classes.formControl}>
                        <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                            <FormControlLabel value="true" control={<Radio />} label="Move them to Unallocated" />
                            <FormControlLabel value="false" disabled={!isSystemAdmin} control={<Radio />} label="Delete the reservations permanently" />
                        </RadioGroup>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>

                    <div className={classes.note}>Note: Room blocks will be deleted in both the cases</div>

                </div>
            </div>

            <div className={classes.buttonContainer}>
                <Button onClick={props.closeModalHandler} variant="outlined" className={classes.cancelButton}>Cancel</Button>
                <Button onClick={() => submitHandler()} className={classes.button}>Confirm</Button>
            </div>
            {loading &&
                <div className={"loadingOverlay"}>
                    <CircularProgress className={"loading"} />
                </div>
            }
        </Dialog>
    )
}

export default DeleteConfirm;