import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { getAmtFormat, fetchDateFormat, DATE_FORMATS } from "../../utils/utility";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import clsx from 'clsx';
import IMAGES from "../../constants/images";

const useStyles = makeStyles(theme => ({
  card: {
    background: "#fff",
    boxShadow: 'none',
  },
  selected: {
    background: '#E0E0E0',
  },
  cardAction: {
    border: '1px solid #ECECEC',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #666666',
    }
  },
  tagContainer: {
    paddingBottom: "20px",
  },
  tag: {
    backgroundColor: "#F78F74",
    padding: "5px 10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    color: "#fff",
    padding: "5px 10px",
    position: "absolute",
    left: "10px",
    top: "0px",
    "& p": {
      fontSize: "1.2rem"
    }
  },
  media: {
    borderRadius: "50%",
    height: 60,
    marginTop: 20,
    margin: "auto",
    width: 60
  },
  guestName: {
    marginBottom: 25,
    textAlign: 'center',
  },
  dates: {
    color: '#4f4f4f',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.2rem'
  },
  bookingSummary: {
    // borderTop: "1px solid #e0e0e0",
    color: "#4f4f4f",
    display: "flex",
    fontSize: "1rem",
    flexWrap: 'wrap',
    justifyContent: "center",
    paddingTop: 10,
  },
  resCode: {
    // borderTop: "1px solid #e0e0e0",
    color: "#4f4f4f",
    display: "flex",
    fontSize: "1.15rem",
    flexWrap: 'wrap',
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5
  },
  codeText: {
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5
    // borderRight: "1px solid #e0e0e0"
  },
  icon: {
    display: "inline",
    height: 20,
    marginRight: 10,
    width: 20
  },
  location: {
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    // borderRight: "1px solid #e0e0e0"
  },
  room: {
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10
  },
  paymentSummary: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    "& p": {
      fontSize: "1.2rem",
      textAlign: 'center',
    }
  },
  errorText: {
    background: '#f0edeb',
    borderRadius: '20px',
    color: "#EA847F",
    padding: theme.spacing(1),
    width: '140px'
  },
  successText: {
    height: '14px',
  },
}));

const GuestTile = (props) => {
  const classes = useStyles();
  const {
    reservationID,
    checkIn,
    checkOut,
    guestName,
    balance,
    status,
    userInitials,
    address,
    accommodationName,
    avatar,
    currency,
    selected,
    handleOpenReservation,
    xs = 12,
    sm = 6,
    md = 4,
    lg = 3,
    isCustomerCard,
    phone,
    resCode,
    showCode = true
  } = props;

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.container}>
      <Card className={classes.card}>
        <CardActionArea
          className={clsx(classes.cardAction, {[classes.selected]: selected})}
          onClick={() => handleOpenReservation && handleOpenReservation(reservationID)}
        >
          <Avatar
            alt={userInitials}
            src={avatar}
            className={classes.media}
          >
            {avatar ? null : userInitials}
          </Avatar>
          <CardContent>
            <Typography
              className={classes.guestName}
              style={isCustomerCard ? { marginBottom: 0 } : {}}
              variant="h4"
              component="h2"
            >
              {guestName}
            </Typography>

            {showCode && (
              <div className={classes.resCode}>
                <div className={classes.codeText}>
                  Code: <b>{resCode}</b>
                </div>
              </div>
            )}

            {!isCustomerCard && <div className={classes.paymentSummary}>
              {balance ?
                <Typography className={classes.errorText}>Balance {getAmtFormat(balance, currency)}</Typography> :
                <div className={classes.successText}></div>
              }
            </div>}

            <div className={classes.paymentSummary}>
              <Typography className={classes.successText}>{status}</Typography>
            </div>

            {phone && <div className={classes.paymentSummary}>
              <Typography className={classes.successText}>{phone}</Typography>
            </div>}

            {(checkIn || checkOut) && (
              <div className={classes.dates}>
                {fetchDateFormat(checkIn, DATE_FORMATS.SHORT_DATE)} - {fetchDateFormat(checkOut, DATE_FORMATS.LONG_DATE)}
              </div>
            )}

            {!isCustomerCard && <div className={classes.bookingSummary}>
              {address && (
                <div className={classes.location}>
                  <img src={IMAGES.ICONS.location} className={classes.icon} />
                  {address}
                </div>
              )}
              <div className={classes.room}>
                {accommodationName && (
                  <>
                    <img src={IMAGES.ICONS.rooms} className={classes.icon} />
                    {accommodationName}
                  </>
                )}
              </div>
            </div>}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => ({
  rooms: state.rooms
});

export default connect(mapStateToProps)(GuestTile);
