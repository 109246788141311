import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
//import RefreshIcon from '@material-ui/icons/Refresh';
import MappingItem from './MappingItem';

const useStyles = makeStyles(theme => ({
  channelContainer: {
    background: '#ffffff',
    border: '1px solid #ffffff',
    borderRadius: '10px',
    boxShadow: '0 1px 30px 0 #E0E0E0',
    marginBottom: 20,
    padding: theme.spacing(1),
    width: 'calc(100% - 16px)',
  },
  error: {
    border: '1px solid red',
  },
  tab: {
    alignItems: 'center',
    borderRadius: '15px',
    color: '#999999',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 20px)',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: theme.spacing(2),
    paddingLeft: 20,
  },
  mappingSection: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: 80,
  },
  selected: {
    background: '#8CB2A4',
    color: '#333333',
    cursor: 'auto'
  },
  channelName: {
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600
  },
  countContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  count: {
    color: '#999999',
    fontSize: '2rem',
    fontWeight: 600,
  },
  selectedCount: {
    color: '#333333',
    background: '#8CB2A4',
  },
  startMappingButton: {
    background: '#ffffff',
    borderColor: '#ffffff',
    boxShadow: '0 1px 20px 0 #E0E0E0',
    color: '#999999',
    fontSize: '1.2rem',
    paddingLeft: 12,
    paddingRight: 12,
    '&:hover': {
      background: '#ffffff',
    }
  },
  subLabel: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.1rem',
    fontWeight: 600,
    marginTop: 8,
  },
  // roomItem: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
  },
  saveButton: {
    background: '#ffffff',
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      background: '#ffffff',
    }
  },
  actionIcon: {
    cursor: 'pointer',
    padding: 5,
    color: '#000000',
    '&:hover': {
      background: '#dddddd',
      borderRadius: '50%'
    }
  }
}))

const Channel = props => {
  //const classes = useStyles();
  const { item,roomTypes, mapping, propertyID } = props;

  return (
    <div
      // className={
      //   clsx({
      //     [classes.channelContainer]: true,
      //     [classes.selectedCount]: true
      //   })
      // }
    >
      {/* <Grid container>
        <Grid item xs={12}>
          <div className={classes.tab}>
            <div
              className={classes.channelName}
              style={{ color: '#ffffff' }}
            >
              
            </div>
            <RefreshIcon onClick={() => props.handleRefresh()} className={classes.actionIcon} />
          </div>
        </Grid>
      </Grid> */}

      <div 
        // className={classes.roomItem}
      >
        {roomTypes && Object.keys(roomTypes).map((roomTypeID, index) =>{
          if(roomTypes[roomTypeID].propertyID == propertyID){
            return roomTypes[roomTypeID].rooms.map(key =>(
                <MappingItem 
                    key={key} 
                    counterRoomID={key} 
                    channelItems={item} 
                    mapping={mapping}
                    handleRefresh={props.handleRefresh}
                    handleMapping={props.handleMapping}
                    handleDeleteMapping={props.handleDeleteMapping}
                    handleUpdateMapping={props.handleUpdateMapping}
                />
            ))
          }
        })}
      </div>
    </div>
  )
}

export default Channel;