import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {connect} from 'react-redux';
import http from '../../redux/utils/http';

import { InputBase, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  backIcon: {
    cursor: 'pointer',
    height: 20,
    opacity: 0.6,
    transform: 'rotateY(180deg)',
    width: 20,
  },
  headerText: {
    color: '#333333',
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  center: {
    alignItems: 'center',
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  signUpPrompt: {
    display: 'flex',
    fontSize: '1.2rem',
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  signUpButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  forgot: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  legal: {
    alignItems: 'center',
    display: 'flex'
  },
  submit: {
    marginBottom: 0,
    marginTop: 10,
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem',
  },
  errors: {
    fontSize: '1.2rem',
    marginTop: 5,
    color: 'red',
  },
  resetLink: {
    marginTop: 20,
  }
}));

const ForgotPassword = (props) => {

  const classes = useStyles();
  const [ errors, setErrors ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ resetLink, setResetLink ] = useState(false)


  const handleOnChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== '') {
      setErrors(false);
    }
  }

  const handleValidate = () => {
    if (!email || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setErrors(true);
      return false
    } else return true
  }

  const handleSubmit = () => {
    const check = handleValidate();
    if (check) {
      http
      .post('/api/users/forgotPassword', {email})
      .then(successResult => console.log(successResult))
      .catch(errorResult => console.log(errorResult))
      setResetLink(true);
      setEmail('');
    }
  }

  return(
    <div className={classes.root}>
    
      <div className={classes.row}>
        <img src={IMAGES.ICONS.arrows} className={classes.backIcon} onClick={props.handleBack} />
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Email</div>
        <div className={classes.field} style={{border: errors ? '1px solid red' : '1px solid #ffffff'}}>
          <InputBase
            autoFocus
            id="email"
            name="email"
            type="email"
            fullWidth
            value={email}
            onChange={handleOnChange}
          />
        </div>
        {errors && <div className={classes.errors}>Please enter a valid email</div>}
      </div>

      <div className={clsx(classes.row, classes.submit)}>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
          Send Reset Link
        </Button>
      </div>

      {resetLink && 
        <div className={clsx(classes.row, classes.resetLink)}>
          <Typography variant="h5">
            We've sent a link to your email. Please use this link to reset your password.
          </Typography>
        </div>
      }

    </div>
  )
}

const mapStateToProps = (state) => ({
});

export default connect()(ForgotPassword);