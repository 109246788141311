import http from '../../utils/http';

export function fetchZapierKey(propertyID) {
	const ACTION_NAME = "FETCH_ZAPIER_KEY";
	return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
    .get(`/api/frontdesk/zapier/token?propertyID=${propertyID}`)
    .then(successResult => {
      const data = successResult.data;
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  };
}

export function refreshZapierKey(propertyID) {
  const ACTION_NAME = "REFRESH_ZAPIER_KEY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
    .get(`/api/frontdesk/zapier/token/refresh?propertyID=${propertyID}`)
    .then(successResult => {
      const data = successResult.data;
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  };
}