import { IMAGES_CDN_PATH } from "../../config";

const imagePath = IMAGES_CDN_PATH + "/illustrations";

export default {
  //LOGIN PAGE............
  communityLarge: imagePath + "/community-large.png",

  //BEDS REPORTS................
  reportsRevenue: imagePath + "/reports-revenue.png",
  reportsOccupancy: imagePath + "/reports-occupancy.png",
  reportsNoShows: imagePath + "/reports-no-shows.png",
  reportsCancellations: imagePath + "/reports-cancellations.png",
  reportsAudit: imagePath + "/reports-audit.png",
  reportsTransactions: imagePath + "/reports-transactions.png",
  reportsGuests: imagePath + "/reports-guests.png",
  reportsBookingSource: imagePath + "/reports-booking-source.png",
  reportsReservations: imagePath + "/reports-reservations.png",
  reportsHousekeeping: imagePath + "/reports-housekeeping.png",
  reportsBookingMetrics: imagePath + "/reports-booking-metrics.png",
  colombia: imagePath + "/colombia.png",
  newZealand: imagePath + "/new-zealand.png",

  //BEDS AUTOMATE...................
  emailTemplates: imagePath + "/email-templates.png",
  bookingEngine: imagePath + "/booking-engine.png",
  onlineCheckIn: imagePath + "/onlineCheckIn.png",
  payments: imagePath + "/payments.png",

  //BEDS FRONTDESK..............
  frontdesk: imagePath + "/illustration-frontdesk.png",

  //EXPERIENCES.................
  experiences: imagePath + "/experiences.png",

  //POS.................
  pos: imagePath + "/pos.png",

  groupChat: imagePath + "/group-chat.png",
  noticeboard: imagePath + "/noticeboard.png",
  todo: imagePath + "/todo.png",

  //CHANNELS......................
  percent: imagePath + "/percent.png",
  sup: imagePath + "/sup.png",
};
