import http from '../../utils/http';

//----------------------------------------------------------------
// LOAD PROPERTY
//----------------------------------------------------------------
export function submitOnboardingRequest(spaceID, payload) {
  const ACTION_NAME = "SUBMIT_ONBOARDING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/signup/onboarding/${spaceID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {spaceID} })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}