import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';

// Process reservations to display in report table
// ------------------------------------------------------------------------
const reservationProcessing = (reservations, currencyData) =>{
  let dataFormat = [];

  reservations && reservations.length!==0 && reservations.map(item => dataFormat.push(
    {
        resvCode: item?.code || '',
        name: toTitleCase(item.guestName),
        checkIn: moment(item.checkIn).format('DD MMM YYYY'),
        checkOut: moment(item.checkOut).format('DD MMM YYYY'),
        source: item.bookingSource,
        amount: item.accommodationAmount,
        commission: item.commission,
        resID: item.reservationID
    }
  ));
  return dataFormat;
}

// Process channel data to display in report multibar graph
// ------------------------------------------------------------------------
const channelDataProccessing = (data, type="curreny") =>{

    const colorConfig = {
        bar1: 'rgb(79, 149, 129)' ,
        bar2: 'rgb(242, 208, 115)',
        bar3: 'rgb(174, 193, 225)'
    }

    const configTypes = {
        amount: {
            bar1: {
                title: 'Commission Cost',
                propertyKey: 'commission',
                color: colorConfig.bar1
            },
            bar2: {
                title: 'Promotion Cost',
                propertyKey: 'promoCost',
                color: colorConfig.bar2
            },
            bar3: {
                title: 'Promotion and Commission Cost',
                propertyKey: 'promoAndCommissionCost',
                color: colorConfig.bar3
            }
        },
        percentage: {
            bar1: {
                title: 'Commission Cost',
                propertyKey: 'commissionPercentage',
                color: colorConfig.bar1
            },
            bar2: {
                title: 'Promotion Cost',
                propertyKey: 'promoCostPercent',
                color: colorConfig.bar2
            },
            bar3: {
                title: 'Promotion and Commission Cost',
                propertyKey: 'promoAndCommissionCostPercent',
                color: colorConfig.bar3
            }
        }
    }

    const configData = { ...configTypes[type] };

    const graphKeys = Object.keys(configData).map(item => configData[item].title);

    const graphData = data.map(channelData =>{

        let formattedData = { 
            bookingSource: channelData.bookingSource, 
            resvCount: channelData.resvCount
        };

        Object.keys(configData).forEach(barID =>{
            const barData = configData[barID];

            let barValue = channelData[barData.propertyKey];
            barValue = parseFloat(barValue).toFixed(2);

            barValue = (type == 'percentage') ? parseFloat(barValue * 100).toFixed(2) : barValue;

            formattedData[barData.title] = parseFloat(barValue);

            formattedData[`${barData.title}Color`] = barData.color;
        });

        return { ...formattedData };
    });

    return { graphKeys, graphData };
}

export { reservationProcessing,  channelDataProccessing };