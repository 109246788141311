import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'

import ConfirmForm from '../common/ConfirmForm'
import { CHANNEL } from '../../utils/channels'
import RatePlanMapping from './RatePlanMapping'

import { withStyles, makeStyles, CircularProgress, Grid, Switch, Tooltip, Snackbar } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningRounded'
import { withTranslation } from 'react-i18next'
import RefreshIcon from '@material-ui/icons/Refresh'
import { CHANNEX_CHANNELS } from '../../utils/constants'
import { some } from 'lodash'
import { DATA_ACTION, inventoryConditionHandler } from '../../utils/helper'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    marginBottom: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'calc(100% - 32px)',
    position: 'relative',
    overflow: 'hidden',
    // boxShadow: '0 2px 20px #F0F0F0'
  },
  roomTypeName: {
    alignItems: 'center',
    color: '#333333',
    display: 'flex',
    height: '100%',
    fontSize: '1.4rem',
    fontWeight: 600
  },
  switchContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  tag: {
    marginTop: 10
  },
  bubble: {
    background: '#F0F0F0',
    borderRadius: '15px',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  selected: {
    background: '#8CB2A4',
    color: '#ffffff',
    fontWeight: 600
  },
  inline: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    color: '#ffffff',
    fontSize: '1.8rem',
    marginLeft: 5
  },
  actionIcon: {
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      borderRadius: '50%'
    }
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)'
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%'
  },
  advancedLink: {
    fontSize: '1.1rem',
    color: '#14408D',
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 10
  },
  noRPMapWarn: {
    marginLeft: 5,
    color: '#f3b502'
  },
  disabledIcon: {
    cursor: 'pointer',
    color: '#999999',
    borderRadius: '50%',
    background: '#dddddd'
  }
}))

const CustomSwitch = withStyles({
  switchBase: {
    color: '#E0E0E0',
    '&$checked': {
      color: '#8CB2A4'
    },
    '&$checked + $track': {
      backgroundColor: '#8CB2A4'
    }
  },
  checked: {},
  track: {}
})(Switch)

const fetchChannelRoomBedsCount = (channel, data) => {
  let count = null;
  switch (channel) {
    case CHANNEL.Hostelworld.code:
      count = data.beds;
      break;
    default: break;
  }
  return count;
}

const fetchChannelRoomCategory = (channel, data) => {
  let roomCategory = data.roomCategory === 'shared' ? 'dorm' : data.roomCategory;
  switch (channel) {
    case CHANNEL.BookingCom.code:
      roomCategory = (data.displayName.includes('Bed in') || data.roomCategory === 'shared') ? 'dorm' : data.roomCategory;
      break;
    default: break;
  }
  return roomCategory;
}

const fetchErrorMessage = (type) => {
  let message;
  switch (type) {
    case 'roomTypeMismatch':
      message = "Mismatch in room type configuration. Please contact support";
      break;
    case 'ratePlanMismatch':
      message = "Mismatch in occupancy settings. Please contact support";
      break;
    case 'currencyMismatch':
      message = "Mismatch in currency settings. Please contact support";
      break;
  }
  return message;
}


const MappingItem = props => {
  const classes = useStyles()
  const {
    channelName,
    channelRoomType,
    channelRoomTypeID,

    counterRoomTypeMappedID,
    counterRoomTypes,

    ratePlans: counterRatePlans,
    allMappedRoomTypes,
    allChannelRatePlans = {},
    currentSpace,
    t,
    allowRefresh,
    fetchToolTip
  } = props
  const isChannexChannel = CHANNEX_CHANNELS.includes(channelName)

  //find out if any channel -> counter rate plan mapping exists
  const mappedChannelRatePlans = []
  if (allChannelRatePlans[channelRoomTypeID]) {
    const channelRoomTypeRatePlans = allChannelRatePlans[channelRoomTypeID].ratePlans || []

    for (const channelRatePlan of channelRoomTypeRatePlans) {
      if (isChannexChannel && !channelRatePlan.channexRatePlanCode && !Object.keys(counterRatePlans).length) continue

      for (const counterRatePlan of Object.values(counterRatePlans)) {
        for (const mapping of counterRatePlan?.mapping) {
          if (mapping?.roomTypeID !== counterRoomTypeMappedID) continue
          if (mapping?.code !== channelRatePlan?.ratePlanCode) continue
          mappedChannelRatePlans.push(channelRatePlan)
          break
        }
      }
    }
  }

  // if (!counterRoomTypes?.[counterRoomTypeMappedID]?.isActive) return null
  const isAirbnbChannel = channelName === CHANNEL.AirBNB.code

  const [status, setStatus] = React.useState(!!counterRoomTypeMappedID)
  const [mappedCounterRoomTypeID, setMappedRoom] = React.useState(counterRoomTypeMappedID || '')
  const [displayAdvanced, setDisplayAdvanced] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const roomCategory = fetchChannelRoomCategory (channelName, channelRoomType)
  const channelBedsPerRoom = fetchChannelRoomBedsCount(channelName, channelRoomType)

  React.useEffect(() => {
    setMappedRoom(counterRoomTypeMappedID || '')
  }, [counterRoomTypeMappedID])

  React.useEffect(() => {
    if (loading && !props.loader) { setLoading(props.loader) }
  }, [props.loader])

  const handleChange = () => {
    if (isAirbnbChannel) return
    if (mappedCounterRoomTypeID && status) {
      return setConfirmOpen(true)
    }
    setStatus(!status)
  }

  const handleConfirmDelete = () => {
    props.handleDeleteMapping(mappedCounterRoomTypeID)
    setStatus(!status)
    setLoading(true)
  }

  const handleMappingSelect = (id) => {
    if (!status) return
    props.handleMapping({
      roomTypeID: id,
      // hw_roomTypeID: id,
      channel_roomTypeID: channelRoomTypeID,
      channexRoomTypeID: channelRoomType.channexRoomTypeID,
      channelCode: channelName
    })
    setMappedRoom(id)
    setLoading(true)
  }

  const handleRefresh = () => {
    props.handleRefresh(mappedCounterRoomTypeID)
    setLoading(true)
  }

  const errorHandler = (type) => {
    setError(fetchErrorMessage(type))
  }
  const closeErrorHandler = () => {
    setError(null)
  }

  const fetchText = (name, displayName) => {
    if (name && displayName) return name + ' (' + displayName + ')'
    if (!name && displayName) return displayName
    if (name && !displayName) return name
    if (!name && !displayName) return ''
  }

  return (
    <div className={classes.root}>
      <Grid container>

        {/* Name of channel room type and ID & with warning if no rate plan mapped */}
        <Grid item xs={8}>
          <div className={classes.roomTypeName}>
            <span>{`${fetchText(channelRoomType.roomTypeName, channelRoomType.displayName)} (${channelRoomTypeID})`}</span>
            {status && mappedChannelRatePlans.length === 0 && (
              <span className={classes.noRPMapWarn}>
                <Tooltip title={t('beds.channelsSnapshot.mappingItem.noRatePlansMapped')}>
                  <WarningIcon fontSize='small' />
                </Tooltip>
              </span>
            )}
          </div>
        </Grid>

        {/* Switch to activate or deactivate room type & refresh icon to refresh the mapping */}
        <Grid item xs={4}>
          <div className={classes.switchContainer}>
            <Tooltip title={isAirbnbChannel ? t('beds.channelsSnapshot.mappingItem.comingSoon') : ''}>
              <CustomSwitch
                checked={status}
                onChange={handleChange}
                color='primary'
                name='switch'
                inputProps={{ 'aria-label': 'room mapping switch' }}
              />
            </Tooltip>
            {props.mappingRefresh && (allowRefresh(counterRoomTypeMappedID ? counterRoomTypeMappedID : null).status
              ? <RefreshIcon onClick={() => handleRefresh()} className={classes.actionIcon} />
              : <Tooltip title={fetchToolTip(allowRefresh(counterRoomTypeMappedID ? counterRoomTypeMappedID : null).diff)}><RefreshIcon className={classes.disabledIcon} /></Tooltip>
            )}
          </div>
        </Grid>
      </Grid>

      {/* Confirm form to delete room type mapping between channel roomtype and counter room type */}
      <ConfirmForm
        title='Delete mapping?'
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmDelete}
      >
        {t('beds.channelsSnapshot.mappingItem.confirmDeleteText')}
      </ConfirmForm>

      {/* If mapped roomtype to channel roomtype, display counter roomtype name */}
      {status && mappedCounterRoomTypeID &&
        <div className={classes.tag}>
          <div className={clsx(classes.bubble, classes.selected)}>
            <div className={classes.inline}>
              {inventoryConditionHandler(counterRoomTypes?.[mappedCounterRoomTypeID], DATA_ACTION.NAME_CODE)}
            </div>
          </div>
        </div>}

      {/* If no mapping, display list of counter roomtypes which are not mapped, depending on dormOrPrivate or all */}
      {!mappedCounterRoomTypeID && counterRoomTypes && <div className={classes.tag}>
        {currentSpace?.roomTypes?.map(roomTypeID => 
        counterRoomTypes?.[roomTypeID]?.isActive &&
          (counterRoomTypes[roomTypeID].dormOrPrivate === roomCategory || roomCategory === 'all') &&
          !allMappedRoomTypes?.includes?.(roomTypeID) &&
          (channelBedsPerRoom === null || (channelBedsPerRoom === counterRoomTypes[roomTypeID].bedsPerRoom)) && 
          (
            <span
              key={roomTypeID}
              className={classes.bubble}
              onClick={() => handleMappingSelect(roomTypeID)}
            >
              {inventoryConditionHandler(counterRoomTypes[roomTypeID], DATA_ACTION.NAME_CODE)}
            </span>
          ))}
        {/* <div className={classes.bubble}> + Add Room Type </div> */}
      </div>}
      {loading && <div className={classes.loadingState}>
        <CircularProgress className={classes.loader} />
      </div>}

      {/* DISPLAY ADVANCED MAPPING FOR RATE PLANS OF HOSTELWORLD */}
      {/* -------------------------------------------------------- */}
      {status && mappedCounterRoomTypeID !== '' && props.advancedRatePlanMapping && !displayAdvanced &&
        <div className={classes.advancedLink} onClick={() => setDisplayAdvanced(true)}>+ {t('beds.channelsSnapshot.mappingItem.ratePlanMapping')}</div>}
      {status && mappedCounterRoomTypeID !== '' && props.advancedRatePlanMapping && displayAdvanced &&
        <div className={classes.advancedLink} onClick={() => setDisplayAdvanced(false)}>- {t('beds.channelsSnapshot.mappingItem.ratePlanMapping')}</div>}

      {status && mappedCounterRoomTypeID !== '' && props.advancedRatePlanMapping && displayAdvanced &&
        <RatePlanMapping
          roomTypeID={mappedCounterRoomTypeID}
          channelType={channelName}
          cancelAdvancedMapping={() => setDisplayAdvanced(false)}
          mappedRoom={channelRoomType}
          channelRoomCategory={roomCategory}
          errorHandler={errorHandler}
        />}

      {error && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open
        onClose={() => closeErrorHandler()}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{error}</span>}
      />}

    </div>
  )
}

const mapStateToProps = (state, props) => {
  const channelRoomTypeKey = CHANNEL[props.channelName].roomTypeIDKey
  const mappedCounterRoomTypeID = props.mapping ? Object.keys(props.mapping).find(key => props.mapping[key][channelRoomTypeKey] == props.channelRoomTypeID) : null;

  return {
    currentSpace: state.spaces[state.dashboard.currentSpace],
    counterRoomTypeMappedID: mappedCounterRoomTypeID || null,
    allMappedRoomTypes: Object.keys(props.mapping || {}) || null,
    allChannelRatePlans: state.channelMapping.ratePlans,
    loader: state.loading.CREATE_CHANNEL_FULL_REFRESH || 
      state.loading.DELETE_ROOM_TYPE_MAPPING || 
      state.loading.CREATE_ROOM_TYPE_MAPPING,
    counterRoomTypes: state.roomTypes,
    ratePlans: state.ratePlans
  }
}

export default withTranslation()(connect(mapStateToProps)(MappingItem))
