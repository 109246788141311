import http from "../../utils/http";

//----------------------------------------------------------------
// ADD TODO COMMENT
//----------------------------------------------------------------
export function addTodoComment(spaceID, todoID, comments, mentions) {
  const ACTION_NAME = "ADD_TODO_COMMENT";
  return function(dispatch) {
    return http.patch(`/api/task/comments/${todoID}`, {...comments, mentions}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, todoID}})
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// ADD FILE COMMENT
//----------------------------------------------------------------
export function addFileComment(fileID, comments, mentions) {
  const ACTION_NAME = "ADD_FILE_COMMENT";
  return function(dispatch) {
    return http.post(`/api/file/comments/${fileID}`, {...comments, mentions}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, fileID}})
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}