import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import { fetchCommissionInfo } from "../../redux/actions/reports/commissions";
import {
  reservationProcessing,
  channelDataProccessing,
} from "../../redux/selectors/reports/commissions";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import { tempData } from "./tempCommissionData";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import Reservation from "../reservations/Reservation";
import { fetchCurrencyFormat } from "../../utils/utility";

import { ResponsiveBar } from "@nivo/bar";

import { exportExcel } from "../utils/excelExport";

const styles = (theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  graphContainer: {
    height: 400,
    width: "100%",
    marginBottom: "30px",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toggleSwitch: {
    display: "flex",
    width: "100px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 30px #E0E0E0",
    height: "40px",
    fontSize: "1.6rem",
    fontWeight: 600,
    color: "#999999",
    borderRadius: "5px",
    overflow: "hidden",

    "& .button": {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },

    "& .button.active": {
      backgroundColor: "#4F9581",
      color: "#fff",
    },
  },
  tooltip: {
    "& div": {
      marginBottom: "2px",
    },
    "& span": {
      fontWeight: 600,
    },
  },
});

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

const BasicExport = (props) => {
  const { t, currencyData } = props;
  const tableTitle = `${t("reports.commissions")} ${t("reports.report")}`;
  const rowCount = props.data.length;
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("reports.table.resvCode"),
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: t("reports.table.guestName"),
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: t("reports.table.bookingSource"),
          field: "source",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: `${t("reports.table.amount")} (${currencyData.code})`,
          field: "amount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: `Commission (${currencyData.code})`,
          field: "commission",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(filename, headerConfig, tableData);
        },
      }}
      onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  );
};

const MultiBarGraph = (props) => {
  const { data, keys, indexBy, ticks, getToolTipComponent, maxValue } = props;

  const theme = {
    axis: {
      ticks: {
        line: {
          stroke: "#999999",
        },
        text: {
          fill: "#999999",
        },
      },
    },
  };

  // const graphMaxValue = maxValue ? { maxValue } : {};

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 30, right: 30, bottom: 60, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      colors={(bar) => bar.data[`${bar.id}Color`]}
      minValue={0}
      // { ...graphMaxValue }
      axisTop={null}
      axisRight={null}
      enableLabel={false}
      axisBottom={{
        orient: "top",
        tickSize: ticks ? 5 : 0,
        tickPadding: 5,
        tickRotation: ticks ? 60 : 0,
        tickValues: 5,
        tickRotation: data?.length > 5 ? 20 : 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      theme={theme}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: -40,
          translateY: 65,
          itemsSpacing: 2,
          itemWidth: 120,
          itemHeight: 30,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 12,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={getToolTipComponent}
    />
  );
};

const graphConsts = {
  percentage: "percentage",
  amount: "amount",
};

class Commisions extends Component {
  state = {
    focusedInput: null,
    selectedConfigType: graphConsts.percentage,
    resvListData: [],
    costByChannelData: [],
    openReservation: false,
  };

  constructor(props) {
    super(props);
    this.presetRanges = this.getPresetRanges();
    this.state.presetValue = this.presetRanges[2];
    const { reportFrom, reportTo } = this.fetchDateRange(
      this.presetRanges[2].value
    );
    const initDates = { startDate: reportFrom, endDate: reportTo };

    const currency = fetchCurrencyFormat(
      props.currentSpace.currency ? props.currentSpace.currency : null
    );
    this.state.currency = currency;
    this.state.dates = { ...initDates };
    this.state.submittedDates = { ...initDates };
  }

  componentDidMount() {
    const { dates } = this.state;
    const { t } = this.props;
    this.getCommisionInfo(dates);
    document.title = `${t("reports.commissions")} ${t(
      "reports.report"
    )} | Counter`;
  }

  componentWillUnmount() {
    document.title = "Counter Workspace";
  }

  getCommisionInfo = (dates) => {
    const { dispatch, currentSpace } = this.props;
    let success = false;

    const params = {
      startDate: dates.startDate.clone().format("YYYY-MM-DD"),
      endDate: dates.endDate.clone().format("YYYY-MM-DD"),
    };
    const response = dispatch(
      fetchCommissionInfo(currentSpace.propertyID, params)
    );
    response.then((data) => {
      if (data && data.resvList && data.costByChannel) {
        const dateRange = {
          startDate: dates.startDate,
          endDate: dates.endDate,
        };
        this.setState({
          resvListData: [...data.resvList],
          costByChannelData: [...data.costByChannel],
          submittedDates: { ...dateRange },
        });
        success = true;
      }

      // TempData reponse for testing
      // const dateRange = { startDate: dates.startDate, endDate: dates.endDate };
      // this.setState({
      //     resvListData: [ ...tempData.resvList ],
      //     costByChannelData: [ ...tempData.costByChannel ],
      //     submittedDates: { ...dateRange }
      // })
      // success = true;
    });
    return success;
  };

  getPresetRanges = () => {
    const { t } = this.props;
    return [
      { value: "today", label: t("reports.presetRange.today") },
      { value: "yesterday", label: t("reports.presetRange.yesterday") },
      // { value: 'tomorrow', label: t('reports.presetRange.tomorrow') },
      { value: "past15", label: t("reports.presetRange.past15") },
      { value: "past30", label: t("reports.presetRange.past30") },
      { value: "custom", label: t("reports.presetRange.custom") },
    ];
  };

  toggleGraphConfigType = (type) => {
    this.setState({ selectedConfigType: type });
  };

  fetchDateRange = (filterType) => {
    let reportFrom;
    let reportTo;

    switch (filterType) {
      case "today":
        reportFrom = moment();
        reportTo = moment();
        break;

      // case 'tomorrow':
      //     reportFrom = moment().add(1, 'day');
      //     reportTo = moment().add(1, 'day');
      //     break;

      case "yesterday":
        reportFrom = moment().subtract(1, "day");
        reportTo = moment().subtract(1, "day");
        break;

      case "past15":
      case "past30":
        const num = parseInt(filterType.substr(4));
        reportFrom = moment().subtract(num, "days");
        reportTo = moment();
        break;
    }

    return { reportFrom, reportTo };
  };

  setNewDates = (data) => {
    this.setState({ ...this.state, presetValue: data });
    if (data.value == "custom") {
      return;
    }
    const filterType = data.value;
    const { reportFrom, reportTo } = this.fetchDateRange(filterType);
    this.setState({
      dates: {
        startDate: reportFrom,
        endDate: reportTo,
      },
    });
    this.getCommisionInfo({
      startDate: reportFrom,
      endDate: reportTo,
    });
  };

  handleTableRowClick = (resID) => {
    const { dispatch } = this.props;
    dispatch(getReservationDetails(resID));
    this.setState({ openReservation: true });
  };

  handleCloseReservation = () => {
    const { dispatch } = this.props;
    this.setState({ openReservation: false });
    dispatch(resetReservationDetails());
  };

  handleSubmit = () => {
    const { isLoading } = this.props;
    const { dates } = this.state;

    if (isLoading) return;

    this.getCommisionInfo(dates);
  };

  getToolTipComponent = (point) => {
    const { currency, selectedConfigType } = this.state;
    const { classes } = this.props;

    if (selectedConfigType == graphConsts.percentage) {
      return (
        <div className={classes.tooltip}>
          <div>
            {point.id}: <span>{point.value}%</span>{" "}
          </div>
          <div>
            Reservation count: <span>{point.data.resvCount}</span>{" "}
          </div>
        </div>
      );
    }

    return (
      <div className={classes.tooltip}>
        <div>
          {point.id} ({currency}): <span>{point.value}</span>{" "}
        </div>
        <div>
          Reservation count: <span>{point.data.resvCount}</span>{" "}
        </div>
      </div>
    );
  };

  render() {
    const {
      dates,
      focusedInput,
      submittedDates,
      selectedConfigType,
      resvListData,
      costByChannelData,
      openReservation,
      currency,
      goFullScreen,
    } = this.state;

    const { open, isLoading, classes, t, handleClose, currentSpace } =
      this.props;

    const currencyData = {
      code: currentSpace.currency,
      country: currentSpace.country,
    };
    const tableData = reservationProcessing(resvListData, currencyData);
    const { graphKeys, graphData } = channelDataProccessing(
      costByChannelData,
      selectedConfigType
    );

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.dialogPaper }}
        style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
        maxWidth="md"
        fullWidth={true}
        fullScreen={window.innerWidth < 901 || goFullScreen}
      >
        {/* --------------------------------------------------------- */}
        {/* Header */}
        {/* --------------------------------------------------------- */}
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            <div className={classes.header}>
              {t("reports.commissions")} {t("reports.report")}
            </div>
            <Hidden smDown>
              {!goFullScreen && (
                <FullScreen
                  className={classes.addButton}
                  onClick={() => {
                    this.setState({ goFullScreen: true });
                  }}
                />
              )}
              {goFullScreen && (
                <FullScreenExit
                  className={classes.addButton}
                  onClick={() => this.setState({ goFullScreen: false })}
                />
              )}
            </Hidden>
            <AccessHeaderAction moduleID={MODULE.COMMISSIONS.ID} />
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </div>

          <div
            className={classes.listContainer}
            style={{
              height:
                goFullScreen || window.innerWidth < 901
                  ? "calc(100vh - 124px)"
                  : "calc(100vh - 190px)",
            }}
          >
            <div className={classes.listMain}>
              {/* --------------------------------------------------------- */}
              {/* DATE RANGE FILTERS */}
              {/* --------------------------------------------------------- */}
              <div className={clsx(classes.listSection, classes.rightAlign)}>
                <Grid container spacing={2}>
                  {/* -------------DURATION SELECT DROPDOWN----------------- */}
                  <Grid item xs={6} md={3}>
                    <div className={classes.fieldContainer}>
                      <Select
                        value={this.state.presetValue}
                        styles={selectStyle}
                        options={this.presetRanges}
                        onChange={this.setNewDates}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>

                  {/* -------------DATEPICKER----------------- */}
                  <Grid item className={classes.dateHolder}>
                    <DateRangePicker
                      startDate={dates.startDate}
                      startDateId="start_date"
                      endDate={dates.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({
                          ...this.state,
                          dates: { startDate, endDate },
                          presetValue: this.presetRanges[5],
                        });
                      }}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      focusedInput={focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      noBorder={true}
                    />
                  </Grid>

                  {/* -------------ARROW BUTTON----------------- */}
                  <Grid item xs={2} lg={1}>
                    <div className={classes.button} onClick={this.handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </Grid>

                  {/* -------------TOGGLE SWITCH----------------- */}
                  <Grid item sm={3} xs={12} className={classes.toggleContainer}>
                    <div className={classes.toggleSwitch}>
                      <div
                        onClick={() =>
                          this.toggleGraphConfigType(graphConsts.percentage)
                        }
                        className={clsx("button", {
                          active: selectedConfigType == graphConsts.percentage,
                        })}
                      >
                        %
                      </div>
                      <div
                        onClick={() =>
                          this.toggleGraphConfigType(graphConsts.amount)
                        }
                        className={clsx("button", {
                          active: selectedConfigType == graphConsts.amount,
                        })}
                      >
                        {currency}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                {isLoading && (
                  <div className={classes.loadingState}>
                    <CircularProgress />
                  </div>
                )}

                <div className={classes.graphContainer}>
                  {!isLoading && graphData.length !== 0 && (
                    <MultiBarGraph
                      keys={graphKeys}
                      data={graphData}
                      indexBy="bookingSource"
                      yAxisLegend="Commissions"
                      currency={currency}
                      // maxValue={selectedConfigType == graphConsts.percentage ? 100 : null}
                      getToolTipComponent={this.getToolTipComponent}
                    />
                  )}
                </div>
              </div>

              {!isLoading && tableData.length !== 0 && (
                <div className={classes.listSection}>
                  <BasicExport
                    t={t}
                    data={tableData}
                    startDate={submittedDates.startDate.format("DD MMM YYYY")}
                    endDate={submittedDates.endDate.format("DD MMM YYYY")}
                    sameDate={moment(submittedDates.startDate).isSame(
                      submittedDates.endDate,
                      "day"
                    )}
                    handleTableRowClick={this.handleTableRowClick}
                    currencyData={currencyData}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Open reservation when reservation item clicked */}
          {/* ------------------------------------------------------------------ */}
          {openReservation && (
            <Reservation
              open={openReservation}
              handleCloseReservation={this.handleCloseReservation}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, loading } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace],
    isLoading: loading.FETCH_COMMISSION_INFO,
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Commisions))
);
