
export default (state = {}, action) => {
  switch(action.type) {
    case "LOAD_VOTES_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.votes
      }
    case "ADD_VOTE_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "POST_VOTE_SUCCESS":
      const vote = state[action.payload.voteID];
      if (vote.voterList) {
        return {
          ...state,
          [action.payload.voteID]: {
            ...vote,
            hasVoted: true,
            voterList: [...vote.voterList, action.payload.userID]
          }
        }
      } else {
        return {
          ...state,
          [action.payload.voteID]: {
            ...vote,
            hasVoted: true,
            voterList: [action.payload.userID]
          }
        }
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};

