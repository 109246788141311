
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import TrixEditor from '../utils/TrixEditor';
import { addAnnouncement } from '../../redux/actions/announcements/announcements';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import IMAGES from '../../constants/images';

const useStyles = makeStyles( theme => ({
  booking: {
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:hover': {
      background: '#eeeeee'
    }
  },
  imageContainer: {
    alignItems: 'center',
    height: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 50,
      width: 50
    },
  },
  bookingHeader: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  listContainer: {
    height: 'calc(100vh - 112px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 88px)',
    }
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30
  },
  firstRow: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.3,
    marginBottom: 10,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    justifyContent: 'center',
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: 'calc(100% - 32px)',
  },
  body: {
    background: '#ffffff',
    borderRadius: '4px',
    boxShadow: "0 1px 30px 0 #E0E0E0",
    color: '#666666',
    minHeight: 400,
    padding: theme.spacing(2),
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
    maringBottom: 20,
  },
  button: {
    background: '#000000',
    borderRadius: 5,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  }
}))

const AddAnnouncement = (props) => {
  const { t } = props
  const classes = useStyles();
  const { open, handleClose, currentSpace } = props;
  const [form, setForm] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  
  let body;

  const handleBodyUpdate = (update) => {
    body = update;
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  };

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const handleSubmit = () => {
    // console.log(form, JSON.stringify(body));
    if (body) {
      props.dispatch(addAnnouncement({...form, body, spaceID: currentSpace._id}));
      handleClose();
    }
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t('noticeboard.postMessage')}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            
            <div className={classes.firstRow}>
              <div>
                <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
              </div>
              <div className={classes.helperText}>
                {t('noticeboard.helpText')}
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.title}>
                {t('noticeboard.subject')}
              </div>
              <div className={classes.field}>
                <InputBase 
                  id="title"
                  name="title"
                  fullWidth
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={classes.container}>
              <div className={classes.title}>
                {t('noticeboard.message')}
              </div>
              {/* --------------------------------------------------------------------- */}
              {/* ANNOUNCEMENT BODY */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.body}>
                <TrixEditor 
                  placeholder={t('noticeboard.messageLabel')}
                  submitOnEnter={false}
                  enableOnChange={true}
                  handleSlateOnChange={handleBodyUpdate}
                />
              </div>
            </div>

            <div className={classes.buttonsContainer}>
              <div className={classes.button} onClick={handleSubmit}>
                {t('actions.submit')}
              </div>
            </div>
          </div>

        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key='top-center'
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message="Link copied to clipboard"
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(AddAnnouncement));



