import React from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import  { connect } from 'react-redux';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

import ReservationsList from './ReservationsList';
import { returnCurrent } from '../../redux/selectors/dashboard';
import { fetchTodaysActivity } from '../../redux/actions/dashboard/live';
import { getCurrency } from '../../utils/utility'; 

const useStyles = makeStyles( theme => ({
  activityTab: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.15rem',
    flexDirection: 'column',
    height: '35px',
    justifyContent: 'center',
    marginRight: 10,
    width: '90px'
  },
  activityInfo: {
    alignItems: 'left',
    background: '#FFFFFF',
    borderRadius: '10px',
    color: '#666666',
    width: '100%',
  },
  activityHeader: {
    margin: '15px 20px'
  },
  activityFirst: {
    display: 'block',
    color: '#8CB2A4',
    fontSize: '2rem',
    fontWeight: 600,
  },
  activityCancellation: {
    display: 'block',
    color: '#F9B3A4',
    fontSize: '2rem',
    fontWeight: 600,
  },
  activitySecond: {
    marginTop: 5,
    display: 'block',
    fontSize: '1.25rem',
    color: '#666666'
  },
  activityLoading: {
    alignItems: 'center',
    display: 'flex',
    height: 400,
    justifyContent: 'center',
    width: '100%'
  },
  activitySpace: {
    marginBottom: 30,
    marginTop: 20,
  },
  refreshRow: {
    alignItems: 'flex-end',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10
  },
  actionIcon: {
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      borderRadius: '50%'
    }
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  reservationBody: {
    borderRadius: '10px',
    overflow: 'hidden',
    zIndex: 2,
  },
  linkContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconRow: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10
  }
}))

const TodaysActivity = (props) => {
  const classes = useStyles();
  const { live, loadingLive, currentSpace, currentSpaceID } = props

  React.useEffect(() => {
    if(live[currentSpaceID]?.todaysActivity) {
      setActivityLoad(false)
      setTodaysActivity(live[currentSpaceID].todaysActivity)
    }
  }, [live[currentSpaceID]])

  let currencyData = undefined;
  if (currentSpace) {
    currencyData = { 
      code: currentSpace.currency,
      country: currentSpace.country
    }
  }
  
  const [activityTab, setActivityTab] = React.useState('bookings')
  const [activityLoad, setActivityLoad] = React.useState(false)
  const [todaysActivity, setTodaysActivity] = React.useState(null)
  const [pageTurned, setPageTurned] = React.useState(false)

  const activityTabSelect = (tab) => {
    setActivityTab(tab)
    setPageTurned(false)
  }

  const refreshActivity = () => {
    setActivityLoad(true)
    props.dispatch(fetchTodaysActivity(currentSpaceID, currentSpace.propertyID))
  }

  const pageTurnFunc = () => {
    setPageTurned(true)
  }

  return (
    <div>
      {(!currentSpace || loadingLive || !todaysActivity || activityLoad) && <div className={classes.activityLoading}>
        <CircularProgress />
      </div>}
      {(!loadingLive && todaysActivity && !activityLoad) && <div>
        <div className={classes.linkContainer}>
          <div className={classes.iconRow}>
            <div 
              id="all"
              className={clsx({[classes.activityTab]: true, [classes.tabSelected]: activityTab==='bookings'})}
              onClick={() => activityTabSelect('bookings')} >
              Bookings
            </div>
            <div 
              id="private"
              className={clsx({[classes.activityTab]: true, [classes.tabSelected]: activityTab==='cancellations'})}
              onClick={() => activityTabSelect('cancellations')} >
              Cancellations
            </div>
          </div>
          {/* <div className={classes.refreshRow}>
            <RefreshIcon className={classes.actionIcon} onClick={refreshActivity}/>
          </div> */}
        </div>
        <Grid container alignItems="center" className={classes.activitySpace}>
          <Grid item xs={4} sm={4} lg={4} className={classes.activityInfo}>
            <div className={classes.activityHeader}>
              {activityTab==='bookings' && <span className={classes.activityFirst}>{todaysActivity.bookings ? (todaysActivity.bookings.length) : 'NA'}</span>}
              {activityTab==='cancellations' && <span className={classes.activityFirst}>{todaysActivity.cancellations ? (todaysActivity.cancellations.length) : 'NA'}</span>}
              <span className={classes.activitySecond}>{(activityTab==='bookings') ? pluralize('Booking', todaysActivity.bookings.length) : pluralize('Cancellation', todaysActivity.cancellations.length)}</span>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} lg={4} className={classes.activityInfo}>
            <div className={classes.activityHeader}>
              {activityTab==='bookings' && <span className={classes.activityFirst}>{todaysActivity.bookRoomNight ? (todaysActivity.bookRoomNight) : '0'}</span>}
              {activityTab==='cancellations' && <span className={classes.activityFirst}>{todaysActivity.cancRoomNight ? (todaysActivity.cancRoomNight) : '0'}</span>}
              <span className={classes.activitySecond}>Room {(activityTab==='bookings') ? pluralize('Night', todaysActivity.bookRoomNight) : pluralize('Night', todaysActivity.cancRoomNight)}</span>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} lg={4} className={classes.activityInfo}>
            <div className={classes.activityHeader}>
              {activityTab==='bookings' && <span className={classes.activityFirst}>{(todaysActivity.bookAmount && currencyData) ? getCurrency(currencyData, todaysActivity.bookAmount) : '0'}</span>}
              {activityTab==='cancellations' && <span className={classes.activityCancellation}>{(todaysActivity.cancAmount && currencyData) ? getCurrency(currencyData, todaysActivity.cancAmount) : '0'}</span>}
              <span className={classes.activitySecond}>Revenue</span>
            </div>
          </Grid>
        </Grid>
        <div className={clsx(classes.reservationBody)}>
          {todaysActivity && 
            <ReservationsList 
              reservations={(activityTab==='bookings') ? todaysActivity.bookings : todaysActivity.cancellations}
              activityTab={activityTab}
              pageTurned={pageTurned}
              pageTurnFunc={pageTurnFunc}
              currencyData={{ 
                code: currentSpace.currency ? currentSpace.currency : 'EUR', 
                country: currentSpace.country ? currentSpace.country : 'DEU'
              }} 
            />
          }
        </div>
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  live: state.live,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  loadingLive: state.loading.LOAD_LIVE,
  currentSpaceID: state.dashboard.currentSpace
})

export default connect(mapStateToProps)(TodaysActivity);