
export default (state = [], action) => {
  switch (action.type) {

    // --------------------------------------------------
    // SPACES
    // --------------------------------------------------
    case "LOAD_SPACE_SUCCESS":
      return {
        ...state,
        ...action.payload.spaces
      }
    case "CREATE_SPACE_SUCCESS":
      return {
        ...state,
        [action.payload._id]: { ...action.payload, roomTypes: [] }
      }
    case "SET_SPACE":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "GET_HW_USER_SUCCESS":
      return {
        ...state,
        [action.payload.space._id]: { ...action.payload.space, roomTypes: [] }
      }
    case "SET_FRONTDESK_SIGNUP_STEP_SUCCESS":
      const frontdeskSpace = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: { ...frontdeskSpace, ...action.payload }
      }
    case "EDIT_SPACE_SUCCESS":
      const editSpace = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: { ...editSpace, ...action.payload }
      }

    //Update spaces store when a user is removed from a space... 
    case "EDIT_SPACE_ON_PERMISSION_UPDATE":
      const editSpaceData = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: { ...editSpaceData, ...action.payload }
      }
    case "GET_HW_USER_SUCCESS":
      return {
        ...state,
        [action.payload.space._id]: action.payload.space
      }

    // --------------------------------------------------
    // WORKSPACE USERS
    // --------------------------------------------------
    case "ADD_USER_TO_SPACE_SUCCESS":
      return {
        ...state,
        [action.payload.space._id]: { ...action.payload.space }
      }

    // --------------------------------------------------
    // FRONTDESK USERS
    // --------------------------------------------------
    case "LOAD_FRONTDESK_USERS_SUCCESS":
      const frondeskUsersSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...frondeskUsersSpace,
          frontdeskUsers: [...action.payload.result]
        }
      }
    case "CREATE_FRONTDESK_USER_SUCCESS":
      const createFrondeskUsersSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...createFrondeskUsersSpace,
          frontdeskUsers: [...createFrondeskUsersSpace.frontdeskUsers, action.payload._id]
        }
      }

    // --------------------------------------------------
    // FOLDERS
    // --------------------------------------------------
    case "CREATE_NEW_FOLDER_SUCCESS":
      const currentSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...currentSpace,
          folders: [...currentSpace.folders, action.payload._id]
        }
      }
    case "DELETE_FOLDER_SUCCESS":
      const deleteFolderSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...deleteFolderSpace,
          folders: deleteFolderSpace.folders.filter(item => item !== action.payload.folderID)
        }
      }

    // --------------------------------------------------
    // ANNOUNCEMENTS
    // --------------------------------------------------
    case "LOAD_ANNOUNCEMENTS_SUCCESS":
      const currentAnnouncementSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...currentAnnouncementSpace,
          announcements: action.payload.result
        }
      }
    case "ADD_ANNOUNCEMENT_SUCCESS":
      const addAnnouncementSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...addAnnouncementSpace,
          announcements: (addAnnouncementSpace.announcements) ? [...addAnnouncementSpace.announcements, action.payload._id] : [action.payload._id]
        }
      }

    // --------------------------------------------------
    // VOTES
    // --------------------------------------------------
    case "LOAD_VOTES_SUCCESS":
      const currentVotesSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...currentVotesSpace,
          votes: action.payload.result
        }
      }
    case "ADD_VOTE_SUCCESS":
      const addVoteSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...addVoteSpace,
          votes: [...addVoteSpace.votes, action.payload._id]
        }
      }

    // --------------------------------------------------
    // TODOS
    // --------------------------------------------------
    case "ADD_TODOCATEGORY_SUCCESS":
      const currentCategorySpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...currentCategorySpace,
          taskCategories: [...currentCategorySpace.taskCategories, action.payload._id]
        }
      }
    case "DELETE_TODOCATEGORY_SUCCESS":
      const deleteCategorySpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...deleteCategorySpace,
          taskCategories: deleteCategorySpace.taskCategories.filter(item => item !== action.payload.taskCategoryID)
        }
      }

    // --------------------------------------------------
    // ROOM
    // --------------------------------------------------
    case "CREATE_ROOM_SUCCESS":
      const createRoomSpace = state[action.payload.spaceID]
      let spaceRoomIds = createRoomSpace.rooms ? [...createRoomSpace.rooms] : [];
      spaceRoomIds = [...spaceRoomIds, action.payload.data._id];
      spaceRoomIds = [...new Set(spaceRoomIds)];
      return {
        ...state,
        [action.payload.spaceID]: {
          ...createRoomSpace,
          rooms: [...spaceRoomIds]
        }
      }

    case "DELETE_ROOM_SUCCESS":
      const deleteRoomSpace = state[action.payload.spaceID]
      let spaceRoom = deleteRoomSpace.rooms || [];
      spaceRoom = spaceRoom.filter(key => key !== action.payload.room._id)
      spaceRoom = spaceRoom || [];
      spaceRoom = [...new Set(spaceRoom)];
      return {
        ...state,
        [action.payload.spaceID]: {
          ...deleteRoomSpace,
          rooms: [...spaceRoom]
        }
      }

    // --------------------------------------------------
    // ROOM TYPES
    // --------------------------------------------------
    case "CREATE_ROOMTYPE_SUCCESS":
      const createRoomTypeSpace = state[action.payload.roomType.spaceID]
      let spaceRoomTypeIds = createRoomTypeSpace.roomTypes ? [...createRoomTypeSpace.roomTypes] : [];
      spaceRoomTypeIds = [...spaceRoomTypeIds, action.payload.roomType._id];
      spaceRoomTypeIds = [...new Set(spaceRoomTypeIds)];
      return {
        ...state,
        [action.payload.roomType.spaceID]: {
          ...createRoomTypeSpace,
          roomTypes: [...spaceRoomTypeIds]
        }
      }
    case "LOAD_ROOMTYPES_SUCCESS":
      if (action.payload.roomTypes) {
        const loadRoomTypeSpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...loadRoomTypeSpace,
            roomTypes: Object.keys(action.payload.roomTypes),
            rooms: Object.keys(action.payload.rooms || {})
          }
        }
      } else return { ...state };

    // case "DELETE_ROOM_TYPE_SUCCESS":
    //   const deleteRoomTypeSpace = state[action.payload.spaceID]
    //   let spaceRoomType = deleteRoomTypeSpace.roomTypes || [];
    //   spaceRoomType = spaceRoomType.filter(key => key !== action.payload.roomTypeID)
    //   spaceRoomType = spaceRoomType || [];
    //   spaceRoomType = [...new Set(spaceRoomType)];
    //   return {
    //     ...state,
    //     [action.payload.spaceID]: {
    //       ...deleteRoomTypeSpace,
    //       roomTypes: [...spaceRoomType]
    //     }
    //   }

    // --------------------------------------------------
    // PRODUCT CATEGORY
    // --------------------------------------------------
    case "CREATE_PRODUCT_CATEGORY_SUCCESS":
      const createProductCategorySpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...createProductCategorySpace,
          productCategories: createProductCategorySpace.productCategories ?
            [...createProductCategorySpace.productCategories, action.payload._id] :
            [action.payload._id]
        }
      }
    case "LOAD_PRODUCT_CATEGORIES_SUCCESS":
      if (action.payload.productCategory) {
        const loadProductCategorySpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...loadProductCategorySpace,
            productCategories: Object.keys(action.payload.productCategory)
          }
        }
      } else return { ...state };

    case "DELETE_PRODUCT_CATEGORY_SUCCESS":
      const deleteProductCategorySpace = state[action.payload.spaceID]
      let spaceProductCategory = deleteProductCategorySpace.productCategories || [];
      spaceProductCategory = spaceProductCategory.filter(key => key !== action.payload.productCategoryID)
      spaceProductCategory = spaceProductCategory || [];
      return {
        ...state,
        [action.payload.spaceID]: {
          ...deleteProductCategorySpace,
          productCategories: [...spaceProductCategory]
        }
      }


    // --------------------------------------------------
    // TRANSACTION MODES
    // --------------------------------------------------
    case "LOAD_TRANSACTION_MODES_SUCCESS":
      const loadTransactionModesSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...loadTransactionModesSpace,
          transactionModes: action.payload.result
        }
      }

    case "UPDATE_TRANSACTION_MODE_SUCCESS":
      const updatedTransactionModesSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...updatedTransactionModesSpace,
          transactionModes: action.payload.result
        }
      }

    case "CREATE_TRANSACTION_MODE_SUCCESS":
      const createTransactionModeSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...createTransactionModeSpace,
          transactionModes: [...createTransactionModeSpace.transactionModes, action.payload._id]
        }
      }



    // --------------------------------------------------
    // USER ADD AND REMOVE BY SYSTEM ADMIN
    // --------------------------------------------------
    case "ADMIN_USER_ADD_SUCCESS":
      var thisSpaceUsers = []
      state[action.payload.spaceID][action.payload.type].map((user) => {
        thisSpaceUsers.push(user)
      })

      thisSpaceUsers.push(action.payload.userID)

      const spaceWhereUserAdded = {
        ...state[action.payload.spaceID]
      }
      spaceWhereUserAdded[action.payload.type] = thisSpaceUsers

      return {
        ...state,
        [action.payload.spaceID]: spaceWhereUserAdded
      }

    case "ADMIN_USER_REMOVE_SUCCESS":
      var thisSpaceUsers = []
      state[action.payload.spaceID][action.payload.type].map((user) => {
        if(String(user) !== String(action.payload.userID))
          thisSpaceUsers.push(user)
      })

      const spaceWhereUserRemoved = {
        ...state[action.payload.spaceID]
      }

      spaceWhereUserRemoved[action.payload.type] = thisSpaceUsers
      
      return {
        ...state,
        [action.payload.spaceID]: spaceWhereUserRemoved
      }



    // --------------------------------------------------
    // TAX LIST
    // --------------------------------------------------
    case "LOAD_TAX_LIST_SUCCESS":
      const loadTaxListSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...loadTaxListSpace,
          taxes: action.payload.result
        }
      }
    case "CREATE_TAX_ITEM_SUCCESS":
      const currentSpaceNewTax = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...currentSpaceNewTax,
          taxes: [...currentSpaceNewTax.taxes, action.payload._id]
        }
      }

    case "UPLOAD_PROPERTY_PHOTO_SUCCESS":
      const currentSpacePhoto = state[action.payload._id]
      //console.log("currentSpacePhoto", action.payload._id, action.payload)
      return {
        ...state,
        [action.payload._id]: {
          ...currentSpacePhoto,
          profilePic: action.payload.profilePic ? action.payload.profilePic : null,
          background: action.payload.background ? action.payload.background : null
        }
      }
    // --------------------------------------------------
    // UPSELL
    // --------------------------------------------------
    case "ADD_UPSELL_ITEM_SUCCESS":
      const createpUpsellSpace = state[action.spaceID]
      return {
        ...state,
        [action.spaceID]: {
          ...createpUpsellSpace,
          upsellItem: createpUpsellSpace.upsellItem ?
            [...createpUpsellSpace.upsellItem, action.payload._id] :
            [action.payload._id]
        }
      }
    case "LIST_UPSELL_ITEMS_SUCCESS":
      if (action.payload.upsellItem) {
        const loadUpsellSpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...loadUpsellSpace,
            upsellItem: Object.keys(action.payload.upsellItem)
          }
        }
      } else return { ...state };

    // --------------------------------------------------
    // BUILDINGS REQUEST
    // --------------------------------------------------
    case "CREATE_BUILDING_SUCCESS":
      if (action.payload.buildings) {
        const createBuildingSpace = state[action.payload.spaceID]
        let data = (createBuildingSpace.buildings) ? (createBuildingSpace.buildings) : []
        let newBuilding = Object.keys(action.payload.buildings)
        newBuilding.map((b) => {
          data.push(b)
        })
        
        return {
          ...state,
          [action.payload.spaceID]: {
            ...createBuildingSpace,
            buildings: data
          }
        }
      } else {
        return { ...state }
      }

    case "LOAD_BUILDINGS_SUCCESS":
      if (action.payload.buildings) {
        const loadBuildingSpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...loadBuildingSpace,
            buildings: Object.keys(action.payload.buildings)
          }
        }
      } else return { ...state };


    // --------------------------------------------------
    // RESERVATION TAG REQUEST
    // --------------------------------------------------
    case "CREATE_TAG_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]

        let thisReservationTags = []
        state[action.payload.spaceID].resvTags.map((source) => {
          thisReservationTags.push(source)
        })

        thisReservationTags.push(action.payload.data)
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            resvTags: thisReservationTags
          }
        }
      } else return { ...state };

    case "LOAD_TAGS_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            resvTags: action.payload.data
          }
        }
      } else return { ...state };

    case "UPDATE_TAG_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        let tagData = []
        thisSpace.resvTags.map((source) => {
          if(source._id !== action.payload.data._id)
            tagData.push(source)
          else
            tagData.push(action.payload.data)
        })
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            resvTags: tagData
          }
        }
      } else return { ...state };

    case "RESERVATION_TAG_ADDED":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        let tempData = []
        if(thisSpace.tempTags) {
          tempData = [ ...thisSpace.tempTags ]
          tempData.push(action.payload.data)
        }
        else {
          tempData = [ action.payload.data ]
        }
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            tempTags: tempData
          }
        }
      } else return { ...state };

    case "EMAIL_TEMPLATE_TAG_ADDED":
      if (action.payload.data) {
        const thisEmailSpace = state[action.payload.spaceID]
        let tempData = []
        if(thisEmailSpace.emailTags) {
          tempData = [ ...thisEmailSpace.emailTags ]
          tempData.push(action.payload.data)
        }
        else {
          tempData = [ action.payload.data ]
        }
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisEmailSpace,
            emailTags: tempData
          }
        }
      } else return { ...state };

    case "RESERVATION_TAGS_RESET":
      const thisSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...thisSpace,
          tempTags: []
        }
      }

    case "EMAIL_TEMPLATE_TAG_RESET":
      const thisTempSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...thisTempSpace,
          emailTags: []
        }
      }

    // --------------------------------------------------
    // BOOKING SOURCE REQUEST
    // --------------------------------------------------
    case "CREATE_BOOKING_SOURCE_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        let thisBookingSource = []
        state[action.payload.spaceID].bookingSource.map((source) => {
          thisBookingSource.push(source)
        })

        thisBookingSource.push(action.payload.data)
        
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            bookingSource: thisBookingSource
          }
        }
      } else return { ...state };

    case "FETCH_BOOKING_SOURCE_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            bookingSource: action.payload.data
          }
        }
      } else return { ...state };

    case "UPDATE_BOOKING_SOURCE_SUCCESS":
      if (action.payload.data) {
        const thisSpace = state[action.payload.spaceID]
        let arrData = []
        thisSpace.bookingSource.map((source) => {
          if(source._id !== action.payload.data._id)
            arrData.push(source)
          else
            arrData.push(action.payload.data)
        })

        return {
          ...state,
          [action.payload.spaceID]: {
            ...thisSpace,
            bookingSource: arrData
          }
        }
      } else return { ...state };

    // --------------------------------------------------
    // ONBOARDING REQUEST
    // --------------------------------------------------
    case "SUBMIT_ONBOARDING_SUCCESS":
      return {
        ...state,
        [action.payload.spaceID]: { ...state[action.payload.spaceID], onboardingStatus: 'pending' }
      }

    // --------------------------------------------------
    // LOGOUT
    // --------------------------------------------------
    case "USER_LOGOUT":
      return {
        ...action.payload
      }

    case "CHANGE_USER_ROLE_SUCCESS":
      const spaceThis = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...spaceThis,
          ...action.payload.space
        }
      }

    default:
      return state;
  }
};

