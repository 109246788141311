import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#F8F9Fb',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#f8f9fb',
        justifyContent: 'center',
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105',
    },
    title: {
      display: 'flex',
      '& span': {
        marginRight: 20
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'unset',
      }
    },
    closeIcon: {
      borderRadius: '50%',
      color: '#666666',
      cursor: 'pointer',
      padding: theme.spacing(1),
      position: 'absolute',
      right: 24,
      transition: '150ms',
      top: 15,
      '&:hover': {
        background: '#dddddd',
        color: '#666666'
      },
    },
    upgrade: {
      padding: 24,
      paddingBottom: 20,
    },
    text: {
      color: '#484848',
      marginTop: 25,
      marginBottom: 25,
      fontSize: '1.4rem',
    }
}));

const SupportModal = props => {
    const classes = useStyles();
    const { open, handleClose } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <div className={classes.dialogTitle}>
                <Typography variant="h4" className={classes.title}><span>Reservation Details</span></Typography>
                <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            </div>

            <div className={classes.root}>
                <div className={classes.upgrade}>
                    <div className={classes.text}>This feature will be available shortly, once your experiences are bookable on Hostelworld.com</div>
                    <div className={classes.text}>Watch this space!</div>
                </div>
            </div>
        </Dialog>
    )
}

export default SupportModal;