import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import { fetchDateFormat, DATE_FORMATS, fetchCurrencyFormat, getAmtFormat,  currencyToNumber } from '../../../utils/utility'; 
import { TRANSACTION_USER_TYPES, TRANSACTION_MODE } from '../../../utils/constants';

function round(v) {
  return (v >= 0 || -1) * Math.round(Math.abs(v));
}

function calcTransactionSum (transactions, transactionModeID, date) {
  let sum = 0;
  Object.values(transactions).map(item => {
    if (!item.isVoided && item.modeID == transactionModeID && moment(item.createdAt).isSame(date, 'day')) sum+= item.transactionAmount
  })
  // console.log('here', date.format('DD MMM YYYY') , sum);
  return sum
}

function calcSum (transactionObject) {
  let sum = 0;
  Object.keys(transactionObject).map(e => sum+=transactionObject[e]);

  return sum
}

// Process transactions to display in report table
// ------------------------------------------------------------------------
const transactionProcessing = (transactions, transactionModes, currencyData, users, frontdeskUsers, roomTypes, currentSpace) => {
  let dataFormat = [];

  // const currency = fetchCurrencyFormat(currencyData || null);

  transactions && transactions.length!==0 && transactions.map(item => {
    if (item.userType!==TRANSACTION_USER_TYPES.SYSTEM) {
      let transactionModeText = transactionModes[item.modeID] ? `${toTitleCase(transactionModes[item.modeID].name)}${item.isVoided ? ` (void)` : item.transactionAmount < 0 ? ` (refund)` : ``}` : '';
      dataFormat.push(
        {
          resvCode: item?.code || '',
          name: toTitleCase(item.name),
          checkIn: moment(item.checkIn).format('DD MMM, YYYY'),
          checkOut: moment(item.checkOut).format('DD MMM, YYYY'),
          roomType: item.roomTypeIDs && item.roomTypeIDs.map((roomType, index) => `${roomTypes[roomType]?.name} `),
          balance: item.reservationBalance,
          createdBy: item.userType===TRANSACTION_USER_TYPES.WORKSPACE ? 
                      users[item.createdBy] && toTitleCase(users[item.createdBy].name) :
                      item.userType===TRANSACTION_USER_TYPES.FRONTDESK ? 
                      frontdeskUsers[item.createdBy] && `${frontdeskUsers[item.createdBy].firstName} ${frontdeskUsers[item.createdBy].lastName}` :
                        '',
          transactionMode: transactionModeText.trim(),
          amount: item.transactionAmount,
          createdAt: fetchDateFormat(item.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.YEAR}`, currentSpace.timezone),
          createdAtTime: fetchDateFormat(item.createdAt, `${DATE_FORMATS.TIME}`, currentSpace.timezone),
          isVoided: item.isVoided ? item.isVoided : false,
          resID: item.resID,
        }
      );
    }
  });
  return dataFormat;
}

// Process transactions to display in transaction summary report table
// ------------------------------------------------------------------------
const transactionSummaryProcessing = (transactions, transactionModes, currentSpace, currencyData, submittedDates) => {
  let summaryTable=[];

  let dateVar = submittedDates.startDate.clone().subtract(1, 'day');
  let colTotals={};

  if (transactions && transactions.length!==0) {
    do {
      dateVar = dateVar.add(1, 'day');
      let transactionModeDayTotals = {};
      let total = 0;
      currentSpace?.transactionModes?.map(transactionModeID => {
        if (transactionModes?.[transactionModeID] && transactionModes?.[transactionModeID]?.name !== 'HostelWorld') {
          let tempTransactionTotal = calcTransactionSum(transactions, transactionModeID, dateVar);
          transactionModeDayTotals[`${transactionModes[transactionModeID].name}`] = tempTransactionTotal;
          colTotals[`${transactionModes[transactionModeID].name}`] = colTotals[`${transactionModes[transactionModeID].name}`] ? colTotals[`${transactionModes[transactionModeID].name}`]+tempTransactionTotal : tempTransactionTotal;
          total+=tempTransactionTotal;
        }
      })
      colTotals['total'] = colTotals['total'] ? colTotals['total']+total : total;
      summaryTable.push({
        date: dateVar.format('DD MMM, YYYY'),
        ...transactionModeDayTotals,
        total: total
      })
    } while (!dateVar.isSame(submittedDates.endDate, 'day'));
  }

  Object.keys(colTotals).map(key => colTotals[key] = colTotals[key]);

  summaryTable.push({...colTotals, totalRow: true});
  
  return summaryTable;
} 

// Process transaction modes for report drop down filter
// ------------------------------------------------------------------------
const transactionModeSelect = (transactionModes, currentSpace) => {
  let array = [];
  currentSpace && currentSpace.transactionModes && currentSpace.transactionModes.map(mode => {
    if(transactionModes[mode]) {
      if(!transactionModes[mode].isCustomMode && transactionModes[mode].name === TRANSACTION_MODE.SURCHARGE)
        return
      
      array.push({label: toTitleCase(transactionModes[mode].name), value: mode})
    }
  })
  return array;
}

// Process transactions for graph
// ------------------------------------------------------------------------
const transactionGraphProcessor = (transactionModes, transactions, dates, currentSpace) => {
  let graphData = [];
  let labels = [];
  let revenue = [];
  let count = [];
  let totalAmount = 0;
  let totalCount = 0;

  if (transactions && transactions.length !== 0) {
    transactions.map(item => {
      if (dates.startDateTime && dates.endDateTime &&
        !moment(fetchDateFormat(item.createdAt, `DD MMM YYYY HH:mm`, currentSpace.timezone)).isBetween(
          dates.startDateTime, dates.endDateTime, null, '[]'
        )) {
        return
      }
      if (!item.isVoided) {
        const index = graphData.findIndex(e => e.modeID == item.modeID);
        totalAmount += item.transactionAmount;
        totalCount += 1;
        if (index > -1) {
          graphData[index] = {
            modeID: item.modeID,
            amount: graphData[index].amount + item.transactionAmount,
            count: graphData[index].count + 1
          };
        } else {
          graphData.push({
            modeID: item.modeID,
            amount: item.transactionAmount,
            count: 1
          })
        }
      }
    });
  }

  if (totalCount !== 0) {
    labels.push('Total')
    revenue.push(Math.round(totalAmount * 100) / 100)
    count.push(totalCount)
  }

  graphData.map(e => {
    labels.push(toTitleCase(transactionModes[e.modeID]?.name))
    revenue.push(Math.round(e.amount * 100) / 100)
    count.push(e.count)
  })

  return {labels, revenue, count}
}

export { transactionProcessing, transactionSummaryProcessing, transactionModeSelect, transactionGraphProcessor};