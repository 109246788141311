import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { fetchCurrencyFormat } from '../../../utils/utility'; 
import _ from 'lodash';

import RoomTypeForm from './RoomTypeForm';

import SubDirectoryIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ArrowRight from '@material-ui/icons/KeyboardBackspace';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme =>({
  box: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 5,
    marginBottom: 30,
    padding: theme.spacing(3)
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  bin: {
    borderRadius: '50%',
    height: 18,
    padding: theme.spacing(1),
    width: 18,
    cursor: 'pointer',
    '&:hover': {
      background: '#E0E0E0'
    }
  },
  diff: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.3rem',
    marginTop: 10,
  },
  dirIcon: {
    color: '#666666',
    marginRight: 10,
  },
  arrowRight: {
    color: '#666666',
    marginRight: 10,
    marginLeft: 10,
    transform: 'rotate(180deg)' 
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
}));

const RoomTypeLinkage = props =>{
    const classes = useStyles();
    const { 
      currentSpace,
      roomTypes,
      ratePlan,
      handleUpdateRatePlan
    } = props;

    const [newLinkageInProgress, setNewLinkageInProgress] = useState(ratePlan.roomTypeLinks ? false : true);
    const [roomTypeOptions, setRoomTypeOptions] = useState(ratePlan.roomTypeIDs);
    const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);

    useEffect(() => {
      if (ratePlan.roomTypeLinks && ratePlan.roomTypeLinks.length > 0) {
        // console.log('process room type options');
        let tempRoomTypeArray = []
        ratePlan.roomTypeLinks.map(link => {
          tempRoomTypeArray.push(link.baseRoomTypeID);
          link.differentials.map(diff => tempRoomTypeArray.push(diff.roomTypeID));
        })
        let difference = _.difference(roomTypeOptions,tempRoomTypeArray);
        setRoomTypeOptions([...difference]);
      }
    }, [])

    const handleSplice = (selectedRoomType) => {
      let array = [...roomTypeOptions];
      const index = array.indexOf(selectedRoomType);
      if (index>-1) {
        array.splice(index, 1);
        setRoomTypeOptions([...array]);
      }
    }

    const handleAddBack = (selectedRoomTypeArray) => {
      setRoomTypeOptions([...roomTypeOptions, ...selectedRoomTypeArray])
    }

    const handleNewLink = (newLink) => {
      if (ratePlan.roomTypeLinks && ratePlan.roomTypeLinks.length > 0 ) {
        handleUpdateRatePlan('roomTypeLinks', [...ratePlan.roomTypeLinks, newLink]);
        setNewLinkageInProgress(false);
      } else {
        handleUpdateRatePlan('roomTypeLinks', [newLink]);
        setNewLinkageInProgress(false);
      }
    }

    const handleCancelLink = () => {
      setNewLinkageInProgress(false);
    }

    const handleSkip = () => {
      handleUpdateRatePlan('roomTypeLinks', []);
      setNewLinkageInProgress(false);
    }

    const handleDeleteLink = (linkID) => {
      let array = [...ratePlan.roomTypeLinks];
      const index = array.findIndex(e => e.baseRoomTypeID==linkID);

      // Add room type options back into the array
      const item = array[index];
      let roomTypeArray = [item.baseRoomTypeID];
      item.differentials.map(e => roomTypeArray.push(e.roomTypeID));
      setRoomTypeOptions([...roomTypeOptions, ...roomTypeArray]);

      // Delete link from rate plan
      array.splice(index, 1);
      handleUpdateRatePlan('roomTypeLinks', [...array]);
    }

    return(
        <>
          <div className="main-content">

            <div className="main-content-title">Room Type Linkage</div>
            <div className="sub-title">Create links between room types to enable counter to automatically calculate rates</div>
            

            {/* DISPLAY EXISTING ROOM TYPE LINKS */}
            {/* ------------------------------------ */}
            {ratePlan.roomTypeLinks && ratePlan.roomTypeLinks.length > 0 && ratePlan.roomTypeLinks.map((link, index) => 
              <div key={index} className={classes.box}>
                <div className={classes.viewContainer}>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{roomTypes[link.baseRoomTypeID] && roomTypes[link.baseRoomTypeID].name}</div>
                    <img onClick={() => handleDeleteLink(link.baseRoomTypeID)} className={classes.bin} src={IMAGES.ICONS.bin} />
                  </div>
                  {link.differentials.map(diff => 
                    <div key={diff.roomTypeID} className={classes.diff}>
                      <SubDirectoryIcon className={classes.dirIcon} />
                      <div className={classes.relation}>
                        {Math.sign(diff.value)==-1 ? 'Decrease by ' : 'Increase by '}
                        {diff.type=='amount' ? currency : null}{Math.abs(diff.value)}{diff.type=='percent' ? '%' : null}
                      </div>
                      <ArrowRight className={classes.arrowRight}/>
                      <div className={classes.roomType}>{roomTypes[diff.roomTypeID] && roomTypes[diff.roomTypeID].name}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* FORM -- CREATE NEW ROOM TYPE LINK */}
            {/* ------------------------------------ */}
            {newLinkageInProgress && 
              
              <RoomTypeForm 
                roomTypeOptions={roomTypeOptions} 
                handleAddBack={handleAddBack}
                handleSplice={handleSplice}
                ratePlan={ratePlan}
                handleNewLink={handleNewLink}
                handleCancelLink={handleCancelLink}
              />

            }

            {newLinkageInProgress && !ratePlan.roomTypeLinks && 
              <div onClick={handleSkip} className={classes.link}>Keep room rates independent. I do not want room type links for this rate plan.</div>
            }

            {/* BUTTON -- CREATE NEW ROOM TYPE LINK */}
            {/* ------------------------------------ */}
            {!newLinkageInProgress && roomTypeOptions.length>0 && <div className="row">
              <div className={classes.link} onClick={() => setNewLinkageInProgress(true)}>Add Room Type Link</div>
            </div>}

          </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    roomTypes: state.roomTypes
})

export default connect(mapStateToProps)(RoomTypeLinkage); 