import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD OCCUPANCY
//----------------------------------------------------------------
export function loadOccupancy(propID, startDate, endDate, level, year) {
  const ACTION_NAME = "LOAD_OCCUPANCY_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/occupancy/${propID}?startDate=${startDate}&endDate=${endDate}&level=${level}`)
      .then(successResult => {
        if (successResult.status===200) {
          const data = successResult.data;
          // console.log('success', successResult, successResult.status, data);
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data, year } });
        }
      })
      .catch(errorResult => {
        console.log('failure', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function loadRevOccupancy(propID, startDate, endDate, level, year) {
  const body = {
    propID, startDate, endDate, level
  }

  const ACTION_NAME = "LOAD_OCCUPANCY_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reports/revOccupancy`, body)
      .then(successResult => {
        if (successResult.status===200) {
          const data = successResult.data
          // console.log('success', successResult, successResult.status, data);
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data, year } });
        }
      })
      .catch(errorResult => {
        console.log('failure', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET OCCUPANCY REPORT
//----------------------------------------------------------------
export const resetOccupancyReport = () => ({
  type: 'RESET_OCCUPANCY_REPORT'
})