import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme =>({
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 25,
    display: 'flex',
    marginTop: 5,
    width: '200px',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  toggleSelect: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(80% - 60px)',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(60% - 60px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px)',
    }
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20,
  },
}));

const Inventory = props =>{
    const classes = useStyles();
    const {
        product,
        handleUpdateProduct
    } = props;

    const [inventory, setInventory] = React.useState(false);

    return(
        <>
            <div className="main-content">
                
              <div className="main-content-title">Inventory</div>
              <div className="sub-title">Setup inventory tracking for this product</div>


              <div className="row">
                <div className={classes.infoRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    This is very useful for measuring COGS, spoilage, stock takes and for getting the system to generate low stock alerts.
                  </div>
                </div>
              </div>

              

              <div className="row">
                <div className="label">Enable Inventory</div>
                <div className={classes.toggle}>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: !inventory})}
                    onClick={() => setInventory(false)}
                  >
                    OFF
                  </div>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: inventory})}
                    onClick={() => setInventory(true)}
                  >
                    ON
                  </div>
                </div>
              </div>

              {inventory && <div className="row">
                <div className="label">
                  Adding product inventory is currently in Beta and is only available to selected properties. Please check back in a few weeks.
                </div>
              </div>}

            </div>
        </>
    )
}

export default Inventory; 