import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import ImageUploadItem from '../../ImageUploadItem';

const useStyles = makeStyles(theme => ({
	field: {
		alignItems: 'center',
		background: '#ffffff',
		borderRadius: '5px',
		boxShadow: "0 1px 10px #E0E0E0",
		display: 'flex',
		height: 35,
		marginTop: 10,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: '60%'
	},
	description: {
		height: 80,
	}
}));

const BasicInfo = props => {
	const classes = useStyles();
	const {
		product,
		handleUpdateProduct
	} = props;
	
	const handleUpdate = (key, value) => {
		value = value ? value.trimStart() : '';
		handleUpdateProduct(key, value)
	}

	return (
		<>
			<div className="main-content">

				<div className="main-content-title">Basic Information</div>
				<div className="sub-title">Describe this product and upload a picture </div>

				<div className="row">
					<div className="label">Name</div>
					<div className={classes.field}>
						<InputBase
							id="publicName"
							name="publicName"
							value={product.name}
							fullWidth
							type="text"
							autoFocus
							onChange={(e) => handleUpdate('name', e.target.value)}
						/>
					</div>
				</div>

				<div className="row">
					<div className="label">Code</div>
					<div className={classes.field}>
						<InputBase
							id="code"
							name="code"
							value={product.code}
							fullWidth
							type="text"
							onChange={(e) => handleUpdate('code', e.target.value)}
						/>
					</div>
				</div>

				<div className="row">
					<div className="label">Description</div>
					<div className={clsx(classes.field, classes.description)}>
						<InputBase
							id="description"
							name="description"
							fullWidth
							value={product.description}
							type="text"
							multiline
							rows={3}
							onChange={(e) => handleUpdate('description', e.target.value)}
						/>
					</div>
				</div>

				<div className="row">
					<div className="label">Picture</div>
					<ImageUploadItem
						image={product?.image || null}
						selectedFile={product.imageFile}
						onChange={(image) => {
							handleUpdateProduct('imageFile', image);
							if(!image && product._id){
								handleUpdateProduct('image', null);
							}
						}}
					/>
				</div>

			</div>
		</>
	)
}

export default BasicInfo; 