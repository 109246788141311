import React from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgress, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { returnCurrent } from "../../../redux/selectors/dashboard";
import { createReservationNote, deleteReservationNote } from '../../../redux/actions/reservation/editReservation';
import { fetchDateFormat, DATE_FORMATS } from "../../../utils/utility";

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';

import IMAGES from '../../../constants/images';
import { isDeleteNoteActivated } from '../reservationHelper';
import ConfirmForm from '../../common/ConfirmForm';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  notesContainer: {
    flexGrow: 1,
    marginTop: '10px',
    overflowY: 'auto',
    width: '100%',
    paddingRight: '17px',
    boxSizing: 'content-box',
    // [theme.breakpoints.down('xs')]: {
    //   paddingRight: 0
    // }
  },
  noteContainer: {
    background: '#FFF',
    border: "none",
    borderRadius: 5,
    marginBottom: 10,
    padding: 20,
  },
  chip: {
    alignItems: "center",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: '100%'
  },
  smallText: {
    fontSize: "1.1rem",
    paddingRight: 5,
    fontWeight: 600
  },
  highlightedText: {
    color: '#EABF87',
    cursor: 'pointer',
    fontWeight: 600,
  },
  lightText: {
    color: "#828282",
    fontWeight: 400
  },
  note: {
    display: 'block',
    fontSize: '1.4rem',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
    width: '100%',
    wordWrap: 'break-word',
  },
  newNoteContainer: {
    width: '100%',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    }
  },
  newNote: {
    alignItems: ' center',
    background: '#FFF',
    display: 'flex',
    position: 'relative',
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    }
  },
  formBox: {
    alignItems: "center",
    borderRadius: "8px",
    color: "#828282",
    display: "flex",
    justifyContent: "space-between",
    marginRight: 50,
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: '100%',
    "& .MuiInputBase-inputMultiline":{
      borderRadius: 5,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: '#fff',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 7,
        paddingBottom: 7,
      }
    },
    "& textarea": {
      backgroundColor: "#FFFFFF",
      fontSize: '1.4rem',
      paddingLeft: 20,
    },
  },
  send: {
    cursor: 'pointer',
    height: 20,
    opacity: 0.6,
    position: 'absolute',
    right: 15,
    top: 'calc(50% - 10px)',
    width: 20,
  },
  helper: {
    color: '#999999',
    display: 'flex',
    fontSize: '1.1rem',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    }
  },
  deleteIcon: {
    width: 15,
    height: 15,
    float: 'right',
    cursor: 'pointer',
  },
  confirmContainer: {
    paddingTop: 5
  }
});

class NotesTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      note: "",
      selectedID: null,
      confirmOpen: false,
      formHelper: {
        note: {
          error: false,
          required: true
        }
      }
    };
    this.buttonClicked = false;
  }
  
  componentDidMount() {
    this.scrollToBottom();
  }
  
  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && this.state.input != '') {
      e.preventDefault();
      this.handleCreateReservationNote();
    }
  }

  handleFieldChange = name => event => {
    let formHelper = { ...this.state.formHelper };
    if (event.target.value) {
      formHelper[name].error = false;
    }
    this.setState({ note: event.target.value, formHelper: formHelper, selectedID: null, confirmOpen: false });
  };

  handleCreateReservationNote = () => {
    let data = { note: this.state.note };
    let hasErrors = this.handleValidation(data);
    if (!hasErrors) {
      this.props.dispatch(createReservationNote({
        ...data, 
        reservationID: this.props.reservation._id,
        name: this.props.user.name
      }));
      this.setState({ note: "", selectedID: null, confirmOpen: false });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add note', 'eventLabel': 'Add Note'});
    }
  };

  handleValidation = data => {
    let formHelper = { ...this.state.formHelper };
    let hasError = false;
    Object.keys(data).forEach(key => {
      if (!data[key] && formHelper[key].required) {
        formHelper[key].error = true;
        hasError = true;
      }
    });

    this.setState({ formHelper: formHelper });
    return hasError;
  };

  handleConfirmFormClose = () => {
    this.setState({
      selectedID: null,
      confirmOpen: false
    });
  }

  handleConfirmDelete = () => {
    const { selectedID } = this.state;
    if (selectedID)
      this.props.dispatch(deleteReservationNote(this.props.reservation._id, selectedID))
  }

  processReservationNotes = (notes = []) => {
    return notes?.filter(note => note && !note.isArchived) || [];
  }

  render() {
    const { t } = this.props
    const classes = this.props.classes;
    const currentSpace = { ...this.props.currentSpace };
    const formHelper = { ...this.state.formHelper };
    const reservation = this.props?.reservation;
    const reservationNotes = this.processReservationNotes(reservation?.notes);
    const { selectedID, confirmOpen } = this.state;

    const notes = reservationNotes.map((item, index) => (
      item && !item.isArchived?
        <Grid item xs={12} key={index} className={classes.noteContainer}>
          <div className={classes.chip}>
            <Typography component="span" className={clsx(classes.smallText, classes.highlightedText)}>
              {item.systemGenerated ? t('existingReservation.notesTab.system') : item.addedBy}
            </Typography>
            <Typography
              component="span"
              className={clsx(classes.lightText, classes.smallText)}
            >
              {fetchDateFormat(item.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, currentSpace.timezone)}
            </Typography>
            {
              isDeleteNoteActivated(item, this.props.user, currentSpace.admins) && selectedID != item._id &&
              <img src={IMAGES.ICONS.bin} className={classes.deleteIcon} onClick={() => this.setState({ selectedID: item._id, confirmOpen: true })} />
            }
          </div>
          {selectedID === item._id &&
            <div className={classes.confirmContainer}>
              <ConfirmForm
                open={confirmOpen}
                setOpen={(value) => this.setState({ confirmOpen: value })}
                onClose={this.handleConfirmFormClose}
                onConfirm={this.handleConfirmDelete}
              >
                {t('existingReservation.notesTab.noteDelete')}
              </ConfirmForm>
            </div>
          }
          <Typography className={clsx(classes.lightText, classes.note)} component="p">{item.note}</Typography>
        </Grid> : null
    ));

    const noNotes = (
      <Grid item xs={12} className={classes.noteContainer}>
        <Typography className={clsx(classes.lightText, classes.note)} component="p">
          {t('existingReservation.notesTab.noNotes')}
        </Typography>
      </Grid>
    );


    return (
      <div className={classes.root}>
        <div className={classes.notesContainer}>
          <Grid container>
            {reservationNotes.length!==0  && notes}
            {reservationNotes.length===0 && noNotes}
          </Grid>
          <div style={{ float:"left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>

        <div className={classes.newNoteContainer}>
          <div className={classes.newNote}>
            <div className={classes.formBox}>
              <InputBase
                id="note"
                error={formHelper.note.error}
                variant="outlined"
                value={this.state.note}
                fullWidth
                multiline
                placeholder={t('existingReservation.notesTab.newNote')}
                onChange={this.handleFieldChange("note")}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <img 
              src={IMAGES.ICONS.plane} 
              className={classes.send} 
              onClick={this.handleCreateReservationNote} 
              style={{opacity: this.state.note=="" ? 0.4 : 0.8}}
            />
          </div>

          <div className={classes.helper}>
            <span style={{marginRight: 10}}><strong>Return</strong> to send</span><span><strong>Shift + Return</strong> to add a new line</span>
          </div>
        </div>

        {this.props.loading &&
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  reservation: state.reservationDetails.reservation,
  loading: state.loading.ADD_RESERVATION_NOTE || state.loading.DELETE_RESERVATION_NOTE,
  user: state.auth.user
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NotesTab)));
