import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    blankState: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        marginTop: '10%',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
        paddingBottom: 80,
    },
    button: {
        background: 'rgb(0, 102, 153)',
        borderRadius: 5,
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        fontWeight: 600,
        padding: '12px 16px',
    },
    blankStateImage: {
        width: '260px',
        height: '260px',
    },
    logo: {
        height: 40,
        width: 40,
        marginTop: 10
    },
    title: {
        color: '#484848',
        fontSize: '1.5rem',
        fontWeight: 600,
        marginTop: 15,
        marginBottom: 10
    },
    supportContact: {
        color: "#999999",
        fontSize: "1.3rem",
        marginBottom: "10px",
    },
}));


const BlankState = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.blankState}>
                <img className={classes.blankStateImage} src="/images/illustration-invite.png" />
                <div className={classes.supportContact}>
                    <div className={classes.button} onClick={() => props.setOpen(true)}>Set up Google Hotels now</div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default BlankState;