import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { returnCurrent } from "../../redux/selectors/dashboard";
import { createTag } from '../../redux/actions/reservation/reservationTags'
import { resetError } from '../../redux/actions/reservation/editReservation';
import moment from 'moment';
import clsx from 'clsx';
import Select from 'react-select';

import TrixEditor from '../utils/TrixEditor';
import CommentDisplay from '../utils/CommentDisplay';
import { editEmail, loadEmail } from '../../redux/actions/emails/emails';
import serializeSlate from '../utils/slate-components/serializeSlate';
import deserialize from '../utils/slate-components/deserializeSlate';
import { isHtml } from '../../utils/utility'

import Grid from '@material-ui/core/Grid';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Hidden from '@material-ui/core/Hidden';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ReportIcon from '@material-ui/icons/Report';
import { EMAIL_RULE_EVENTS } from "../../utils/constants";
import EmailHtmlInput from './EmailHtmlInput';

const styles = theme => ({
  dialog: {
    overflowY: 'scroll'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%',
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  backButton: {
    color: '#666666',
    fontSize: '3rem',
    left: 24,
    position: 'absolute',
    top: 28
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  cancelButton: {
    marginRight: 10
  },
  listSection: {
    minHeight: 'calc(100vh - 230px)',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xl')]: {
      margin: theme.spacing(1)
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  rightAlign: {
    paddingLeft: '10%',
    width: '70%'
  },
  clickable: {
    cursor: 'pointer'
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.4rem',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  subject: {
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600
  },
  editSubject: {
    fontSize: '1.4rem',
    flexGrow: 1,
    '& input': {
      fontSize: '1.4rem',
    }
  },
  emailContainer: {
    display: 'flex',
    flexGrow: 1
  },
  emailName: {
    width: '100%',
    '& input': {
      fontSize: '1.4rem',
    }
  },
  emailAddress: {
    width: '100%',
    '& input': {
      fontSize: '1.4rem',
    }
  },
  emailTrigger: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%'
  },
  roomTypeChip: {
    cursor: 'pointer',
    marginRight: 5,
    marginBottom: 5
  },
  selectOption: {
    width: '45%'
  },
  roomTypeOptions: {
    width: '100%'
  },
  emailBody: {
    background: '#ffffff',
    borderRadius: '4px',
    boxShadow: "0 1px 30px 0 #E0E0E0",
    color: '#666666',
    minHeight: 400,
    padding: theme.spacing(2),
    width: '100%',
  },
  moreIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  tagBubble: {
    background: '#C17C74',
    color: '#FFFFFF',
    borderRadius: 10,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 5,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  inline: {
    display: 'flex',
    alignItems: 'center'
  },
  tagIcon: {
    fontSize: '1.8rem',
    marginLeft: 5
  },
  tag: {
    width: '100%'
  },
  bubble: {
    background: '#f8f9fb',
    borderRadius: 2,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tagButton: {
    backgroundColor: '#6ea7d6',
    color: '#fff',
    fontWeight: 600,
    marginLeft: 5,
    borderRadius: 5
  },
  col: {
    width: '30%',
    marginBottom: 10,
    marginRight: 10
  },
  onefield: {
    display: 'inline-block'
  },
  addSection: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px 0px'
  },
  tagCol: {
    width: '50%',
    marginBottom: 10,
    marginRight: 10
  },
  buttonHolder: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    marginLeft: 10,
    marginTop: 3,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  field: {
    background: '#f8f9fb',
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 2,
    '& > div': {
      width: '100%'
    },
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .css-6q0nyr-Svg': {
      color: '#000000',
      width: 30,
      height: 30,
    }
  },
  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagChip: {
    background: '#C17C74',
    color: '#FFFFFF',
    cursor: 'pointer',
    marginRight: 5,
    marginBottom: 5
  },
  htmlCodeDisplay: {
    whiteSpace: 'pre-wrap'
  },
  errorColor: {
    border: '1px solid #f44336'
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  }
})


const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: '0 2px 70px 0 #E0E0E0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const emailTriggerOptions = [
  { label: 'Manual Trigger', value: '' },
  { label: 'After New Reservation', value: EMAIL_RULE_EVENTS.AFTER_NEW_RESERVATION },
  { label: 'Before Check-in', value: EMAIL_RULE_EVENTS.BEFORE_CHECKIN },
  { label: 'After Check-in', value: EMAIL_RULE_EVENTS.AFTER_CHECKIN },
  { label: 'Before Check-out', value: EMAIL_RULE_EVENTS.BEFORE_CHECKOUT },
  { label: 'After Check-out', value: EMAIL_RULE_EVENTS.AFTER_CHECKOUT },
  { label: 'On Payment', value: EMAIL_RULE_EVENTS.ON_PAYMENT }
]

const delayOptions = [
  { label: 'Manual Trigger', value: '' },
  { label: 'Immediately', value: 0 },
  { label: '1 day', value: 1 },
  { label: '2 days', value: 2 },
  { label: '3 days', value: 3 },
  { label: '4 days', value: 4 },
  { label: '5 days', value: 5 }
]

const EmailTemplateItem = (props) => {

  const [edit, setEdit] = React.useState(false);
  const [form, setForm] = React.useState({});
  const [fragment, setFragment] = React.useState('');
  const [error, setError] = React.useState(
    {
      senderName: false,
      senderEmail: false,
    }
  );

  let body;
  const { classes, open, currentSpace, currentSpaceID, roomTypes, emailName, email, loadingEmail, t, errors } = props
  const [emailStatus, setEmailStatus] = React.useState(email.isActive);
  const [isHtmlEmbed, setIsHtmlEmbed] = React.useState(false);
  const [invoiceAttach, setInvoiceAttach] = React.useState(email.attachInvoice);
  const delay = delayOptions.filter(e => e.value === (email.triggerDelay))[0];
  const trigger = emailTriggerOptions.filter(e => e.value === (email.triggerEvent || ""))[0];
  const templateDialog = React.useRef();
  const [selectedTags, setSelectedTags] = React.useState([])
  const [resvTags, setResvTags] = React.useState([])
  const [tempTags, setTempTags] = React.useState([])
  const [bookingSource, setBookingSource] = React.useState([])

  const [createNewTag, setCreateTag] = React.useState(false)
  const [newTag, setNewTag] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [sameDay, setSameDay] = React.useState(false)

  const setupForm = () => {
    if (email) {
      let formData = {};
      formData.senderName = email.senderName || "";
      formData.senderEmail = email.senderEmail || "";
      if(email.triggerDelay === undefined)
        formData.triggerDelay = "";
      else
        formData.triggerDelay = email.triggerDelay
      formData.triggerEvent = email.triggerEvent || "";
      if(email.triggerTime) {
        let  triggerTime = email.triggerTime;
        let formattedTime = triggerTime.slice(0,2) + ':' + triggerTime.slice(2)
        formData.triggerTime = formattedTime
      }
      else {
        // formData.triggerTime = '00:00'
        formData.triggerTime = '';
      }
      formData.filter = email.filter || [];
      formData.bookingSource = email.bookingSource || [];
      formData.isActive = email.isActive || false;
      formData.attachInvoice = email.attachInvoice || false;

      formData.isHTML_Editor = email.isHTML_Editor || false;

      if(email.bookingSource) {
        let tempSources = []
        email.bookingSource.map((source) => {
          currentSpace.bookingSource && currentSpace.bookingSource.map((val) => {
            if(val._id === source) {
              tempSources.push({ label: val.name, value: val._id})
            }
          })
        })

        setBookingSource(tempSources)
      }

      setForm({ ...form, ...formData });
    }
  }

  React.useEffect(() => {
    if(currentSpace.resvTags){
      let tags = []
      for(var i = 0; i < currentSpace.resvTags.length; i++) {
        tags.push({ label: currentSpace.resvTags[i].name, value: currentSpace.resvTags[i]._id })
      }

      setResvTags(tags)
    }
  }, [currentSpace?.resvTags])

  React.useEffect(() => {
    if(email.isHTML_Editor) setIsHtmlEmbed(true);
  }, [email.isHTML_Editor]);

  React.useEffect(() => {
    setupForm()
  }, [email]);

  React.useEffect(() => {
    if (email.body) {
      let htmlBody = email.body ? email.body : '';

      if (isHtml(htmlBody) === false) {
        htmlBody = '<div>' + fragment + '</div>';
      }

      setFragment(htmlBody);
    }
  }, [email.body]);

  React.useEffect(() => {
    if((currentSpace.resvTags) && (email.tags)) {
      let tags = []

      for(var i = 0; i < currentSpace.resvTags.length; i++) {
        email.tags.map((tag) => {
          if(tag === currentSpace.resvTags[i]._id)
            tags.push({ label: currentSpace.resvTags[i].name, value: currentSpace.resvTags[i]._id })
        })
      }

      let options = [ ...resvTags ]

      for (var k = 0; k < options.length; k++) {
        email.tags.map((tag) => {
          if(tag === options[k].value)
            options.splice(k, 1)
        })
      }

      setResvTags(options)
      setSelectedTags(tags)
    }
  }, [email])

  // if (email.body) {
  //   // const html = email.body;

  //   // let document
  //   // if (isHtml(html) === false) {
  //   //   const htmlFix = '<p>'+ html + '</p>'
  //   //   document = new DOMParser().parseFromString(htmlFix, 'text/html')
  //   // } else {
  //   //   document = new DOMParser().parseFromString(html, 'text/html')
  //   // }

  //   // fragment = deserialize(document.body)
  //   fragment = html;
  // }

  const roomTypeOptions = currentSpace.roomTypes && currentSpace.roomTypes.map(roomTypeID => { return { value: roomTypeID, label: roomTypes[roomTypeID].name } })
  const sourceOptions = currentSpace.bookingSource && currentSpace.bookingSource.map(source => {
    if(source.active)
      return { value: source._id, label: source.name } 
  })

  const handleCancelUpdates = () => {
    setEdit(false);
    setForm({});
    setEmailStatus(email.isActive);
    setInvoiceAttach(email.attachInvoice);
  }

  const handleSubmitUpdates = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      let tagsArr = []
      if(selectedTags.length > 0) {
        selectedTags.map((sel) => {
          tagsArr.push(sel.value)
        })
      }

      if(tempTags.length > 0) {
        tempTags.map((temp) => {
          tagsArr.push(temp.value)
        })
    }
      const data = {
        ...form
      }

      if(tagsArr.length > 0) {
        data.tags = tagsArr
      }

      if(form.triggerDelay === 0 && !sameDay)
        data.triggerTime = ''
      else
        data.triggerTime = form.triggerTime.replace(':', '')

      if (body) {
        props.dispatch(editEmail(currentSpace.propertyID, email._id, { ...data, body }))
        .then((res) => {
          if(!res) {
            setMessage('Unable to update template. Try again')
            setOpenSnackbar(true)
          }
          else {
            setEdit(false)
            setForm({})
            setTempTags([])
            setEmailStatus(form.isActive ? form.isActive : email.isActive)
            setInvoiceAttach(form.attachInvoice ? form.attachInvoice : email.attachInvoice)
          }
        })
      } else {
        props.dispatch(editEmail(currentSpace.propertyID, email._id, data))
        .then((res) => {
          if(!res) {
            setMessage('Unable to update template. Try again')
            setOpenSnackbar(true)
          }
          else {
            setEdit(false)
            setForm({})
            setTempTags([])
            setEmailStatus(form.isActive ? form.isActive : email.isActive)
            setInvoiceAttach(form.attachInvoice ? form.attachInvoice : email.attachInvoice)
          }
        })
      }
      templateDialog.current.scrollIntoView({ behavior: "smooth", block: "start" })
    } else {
      templateDialog.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    //props.dispatch(loadEmail(currentSpace.propertyID, email._id))
  }

  const handleEdit = () => {
    setupForm()
    setEdit(true);
    setEmailStatus(email.isActive);
    setInvoiceAttach(email.attachInvoice);
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
    if (e.target.value !== '') {
      setError({ ...error, [e.target.id]: false })
    }
  }

  const handleToggle = () => {
    const newEmailStatus = !emailStatus;
    setForm({ ...form, isActive: newEmailStatus });
    setEmailStatus(newEmailStatus);
  }

  const handleInvoiceToggle = () => {
    const newInvoiceAttach = !invoiceAttach;
    setForm({ ...form, attachInvoice: newInvoiceAttach });
    setInvoiceAttach(newInvoiceAttach);
  }

  const selectTrigger = (selected, actionMeta) => {
    setForm({ ...form, [actionMeta.name]: selected.value })
    setError({ ...error, [actionMeta.name]: false })
    // if(actionMeta.name === 'triggerDelay' && selected.value === 0){
    //   setSelectTime(true)
    // }
  }

  const selectFilter = (selected) => {
    if (selected) {
      setForm({ ...form, filter: selected.map(e => e.value) })
    } else {
      setForm({ ...form, filter: [] })
    }
  }

  const selectSource = (selected) => {
    if (selected) {
      setForm({ ...form, bookingSource: selected.map(e => e.value) })
    } else {
      setForm({ ...form, bookingSource: [] })
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['senderName', 'senderEmail'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleBodyUpdate = (update) => {
    setFragment(update);
    setForm({
      ...form,
      body: update
    })
  }

  const handleRemoveTag = (index) => {
    let sel = [ ...selectedTags ]
    let tags = [ ...resvTags ]
    tags.push(sel[index])
    sel.splice(index, 1)
    setSelectedTags(sel)
    setResvTags(tags)
  }

  const handleRemoveTempTag = (index) => {
    let temps = [ ...tempTags ]
    let tags = [ ...resvTags ]
    tags.push(temps[index])
    temps.splice(index, 1)
    setTempTags(temps)
    setResvTags(tags)
  }

  const handleSelectTag = (e) => {
    let temps = [ ...tempTags ]
    let tags = [ ...resvTags ]
    let arr = []
    tags.map((tag) => {
      if(tag.value !== e.value) {
        arr.push(tag)
      }
    })

    temps.push(e)
    setResvTags(arr)
    setTempTags(temps)
  }

  const handleCreateTag = () => {
    setCreateTag(true)
  }

  const handleCreateNewTag = (e) => {
    setNewTag(e.target.value)
  }

  const handleCancelCreate = () => {
    setCreateTag(false)
    setNewTag('')
  }

  const handleSaveTag = () => {
    const payload = {
      templateID: email._id,
      name: newTag,
      propertyID: currentSpace.propertyID
    }

    props.dispatch(createTag(currentSpaceID, payload))
    setCreateTag(false)
  }

  const handleCloseError = () => {
    props.dispatch(resetError('CREATE_TAG'))
  }

  const setHtmlEmbedStatus = event =>{
    const isChecked = event.target.checked;

    if(isChecked && !email.isHTML_Editor){ 
      setFragment('');
      setForm({ 
        ...form, 
        body: '', 
        isHTML_Editor: isChecked 
      });

    }else if(!isChecked && email.isHTML_Editor){
      setFragment('');
      setForm({ 
        ...form, 
        body: '', 
        isHTML_Editor: isChecked 
      });

    }else{
      setFragment(email.body);
      setForm({ 
        ...form,
        body: email.body, 
        isHTML_Editor: isChecked 
      });
    }
    setIsHtmlEmbed(isChecked);

  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
    setMessage('')
  }

  const handleSameDay = () => {
    setSameDay(!sameDay)
  }

  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div>
            <Hidden smDown><Button
              size='small'
              color='primary'
              variant='outlined'
              className={classes.newListButton}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={props.handleBack}
            >
              {t('actions.back')}
              </Button></Hidden>
            <Hidden mdUp>
              <BackIcon className={classes.backButton} onClick={props.handleBack} />
            </Hidden>
          </div>
          <Typography variant="h5">{emailName}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        </div>

        <div className={classes.listSection} ref={templateDialog}>

          {loadingEmail && <div>{t('automate.email.loadingEmail')}</div>}

          {!loadingEmail && <div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* SUBJECT */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.subject')}
                </div>
              {!edit && (
                <div className={clsx(classes.subject, classes.rightAlign)} >
                  {email.subject}
                </div>
              )}
              {edit && (
                <div className={classes.rightAlign}>
                  <TextField
                    id="subject"
                    className={classes.editSubject}
                    defaultValue={email.subject}
                    onChange={handleChange}
                    margin="none"
                    fullWidth
                  />
                </div>
              )}
              {/* ------------------------------------------------ */}
              {/* EDIT EMAIL TEMPLATE */}
              {/* ------------------------------------------------ */}
              {!edit && (
                <div onClick={handleEdit}>
                  <EditIcon className={classes.moreIcon} />
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* SENDER NAME */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.senderName')}
                </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)} >
                  {email.senderName}
                </div>
              )}
              {edit && (
                <div className={classes.rightAlign}>
                  <TextField
                    id="senderName"
                    type="text"
                    error={error.senderName}
                    className={classes.emailName}
                    defaultValue={email.senderName}
                    onChange={handleChange}
                    margin="none"
                  />
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* SENDER EMAIL */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.senderEmail')}
                </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)} >
                  {email.senderEmail}
                </div>
              )}
              {edit && (
                <div className={classes.rightAlign}>
                  <TextField
                    id="senderEmail"
                    type="email"
                    error={error.senderEmail}
                    className={classes.emailAddress}
                    defaultValue={email.senderEmail}
                    onChange={handleChange}
                    margin="none"
                  />
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* TRIGGER */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.trigger')}
              </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)} >
                  {delay && delay.label} -- {trigger && trigger.label}
                </div>
              )}
              {edit && (
                <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                  <Select
                    name="triggerEvent"
                    styles={selectStyle}
                    options={emailTriggerOptions}
                    onChange={selectTrigger}
                    defaultValue={emailTriggerOptions.filter(e => e.value === (email.triggerEvent || ""))}
                    className={clsx({[classes.selectOption] : true, [classes.errorColor] : error.triggerEvent === true})}
                  />
                  <Select
                    name="triggerDelay"
                    styles={selectStyle}
                    options={delayOptions}
                    onChange={selectTrigger}
                    defaultValue={delayOptions.filter(e => e.value === (email.triggerDelay))}
                    className={clsx({[classes.selectOption] : true, [classes.errorColor] : error.triggerDelay === true})}
                  />
                </div>
              )}
            </div>

            {form.triggerDelay === 0 && edit && <div className={classes.container}>
              <div className={classes.leftAlign}>
                <Checkbox
                  color="primary"
                  checked={sameDay}
                  checkedIcon={<span className={classes.checkedIcon} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e)=>handleSameDay()}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                <span className={classes.label}>Check the icon if you need to send the email on the same day of the event at a specific time.</span>
              </div>
            </div>}

            {((form.triggerDelay !== 0 && form.triggerDelay !== '') || sameDay) && <div className={classes.container}>
              <div className={classes.leftAlign}>
                Trigger Time
              </div>
              {!edit && <div className={clsx(classes.clickable, classes.rightAlign)} >
                {email.triggerTime && <span>{email.triggerTime.slice(0,2) + ':' + email.triggerTime.slice(2)} HRS</span>}
                {!(email.triggerTime) && <span>Trigger time not set.</span>}
              </div>}
              {edit && (
                <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                  <form className={classes.timeContainer} noValidate>
                    <TextField
                      id="triggerTime"
                      type="time"
                      onChange={handleChange}
                      defaultValue={form.triggerTime}
                      className={classes.editSubject}
                    />
                  </form>
                </div>
              )}
            </div>}

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* ROOM TYPE */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {/*t('automate.email.filter')*/} Room Types
                </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)} >
                  {email.filter && email.filter.length !== 0 &&
                    email.filter.map(e =>
                      <Chip
                        key={e}
                        className={classes.roomTypeChip}
                        variant="outlined"
                        size="small"
                        label={roomTypes[e].name}
                      />
                    )
                  }
                  {email.filter && email.filter.length === 0 && t('automate.email.noRoomFilter')}
                </div>
              )}
              {edit && (
                <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                  <Select
                    isMulti
                    isClearable={false}
                    defaultValue={roomTypeOptions && roomTypeOptions.filter(e => email.filter && email.filter.length && email.filter.includes(e.value))}
                    styles={selectStyle}
                    options={roomTypeOptions}
                    onChange={selectFilter}
                    placeholder={t('automate.email.selectRoomTypes')}
                    className={classes.roomTypeOptions}
                  />
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* ROOM TYPE */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {/*t('automate.email.filter')*/} Booking Source
                </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)} >
                  {email.bookingSource && email.bookingSource.length !== 0 &&
                    bookingSource.map(e =>
                      <Chip
                        key={e.value}
                        className={classes.roomTypeChip}
                        variant="outlined"
                        size="small"
                        label={e.label}
                      />
                    )
                  }
                  {email.bookingSource && email.bookingSource.length === 0 && 'No booking source filter. Will be sent to all reservations.'}
                </div>
              )}
              {edit && (
                <div className={clsx(classes.rightAlign, classes.emailTrigger)}>
                  <Select
                    isMulti
                    isClearable={false}
                    defaultValue={sourceOptions && sourceOptions.filter(e => email.bookingSource && email.bookingSource.length && email.bookingSource.includes(e.value))}
                    styles={selectStyle}
                    options={sourceOptions}
                    onChange={selectSource}
                    placeholder='Select Booking Source for this template ...'
                    className={classes.roomTypeOptions}
                  />
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* ROOM TYPE */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                Template Restrictions
              </div>
              {!edit && (
                <div className={clsx(classes.clickable, classes.rightAlign)}>
                  {email.tags && email.tags.length !== 0 &&
                    selectedTags.map((tag, index) =>
                      <Chip
                        key={index}
                        className={classes.tagChip}
                        variant="outlined"
                        size="small"
                        label={tag.label}
                      />
                    )
                  }
                  {((!email.tags) || (selectedTags.length === 0)) &&
                    <Chip
                      className={classes.roomTypeChip}
                      variant="outlined"
                      size="small"
                      label={'No tags found'}
                    />
                  }
                </div>
              )}
              {edit && (
                <div className={clsx(classes.rightAlign, classes.tag)}>
                  {selectedTags.length > 0 && selectedTags.map((tag, index) => (
                    <span className={classes.tagBubble} key={index}>
                      <div className={classes.inline}>{(tag.value) ? (tag.label) : tag} <CloseIcon className={classes.tagIcon} onClick={() => handleRemoveTag(index)} /></div>
                    </span>
                  ))}
                  {tempTags.length > 0 && tempTags.map((tag, index) => (
                    <span className={classes.tagBubble} key={index}>
                      <div className={classes.inline}>{(tag.value) ? (tag.label) : tag} <CloseIcon className={classes.tagIcon} onClick={() => handleRemoveTempTag(index)} /></div>
                    </span>
                  ))}
                  <div className={clsx(classes.col, classes.onefield)}>
                    <Select
                      options={resvTags}
                      onChange={(e) => handleSelectTag(e)}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#666666',
                          primary25: '#dddddd'
                        },
                      })}
                    />
                  </div>
                  {!createNewTag && <div className={clsx(classes.bubble, classes.tagButton)} onClick={handleCreateTag}> {/*t('existingReservation.reservationTab.createTag')*/ 'Create Tag'} </div>}
                  {createNewTag && <div className={classes.onefield}>
                    <div className={classes.addSection}>
                      <div className={clsx(classes.field, classes.tagCol)}>
                        <InputBase
                          value={newTag}
                          type="text"
                          id="resvTag"
                          onChange={(e) => handleCreateNewTag(e)}
                        />
                      </div>
                      <DoneIcon className={classes.buttonHolder} onClick={handleSaveTag}/>
                      <CloseIcon className={classes.buttonHolder} onClick={handleCancelCreate}/>
                    </div>
                  </div>}
                </div>
              )}
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* STATUS */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.status')}
              </div>

              {!edit &&
                <div className={clsx(classes.rightAlign, classes.clickable)}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={email.isActive}
                        className={clsx(classes.statusField, classes.clickable)}
                        color="primary"
                      />
                    }
                    label={email.isActive ? t('automate.email.active') : t('automate.email.inactive')}

                  />
                </div>
              }
              {edit &&
                <div className={classes.rightAlign}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isActive"
                        checked={emailStatus}
                        onChange={handleToggle}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={emailStatus ? t('automate.email.active') : t('automate.email.inactive')}
                  />
                </div>
              }
            </div>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* ATTACH INVOICE*/}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.leftAlign}>
                {t('automate.email.attachInvoice')}
              </div>

              {!edit &&
                <div className={clsx(classes.rightAlign, classes.clickable)}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={email.attachInvoice}
                        className={clsx(classes.statusField, classes.clickable)}
                        color="primary"
                      />
                    }
                    label={email.attachInvoice ? t('automate.email.active') : t('automate.email.inactive')}

                  />
                </div>
              }
              {edit &&
                <div className={classes.rightAlign}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="attachInvoice"
                        checked={invoiceAttach}
                        onChange={handleInvoiceToggle}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={invoiceAttach ? t('automate.email.active') : t('automate.email.inactive')}
                  />
                </div>
              }
            </div>

            <div className={classes.container}>
              
              <div className={classes.leftAlign}>
                  Edit mode
              </div>

        
              <div className={classes.rightAlign}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled = {!edit}
                      id="isHtmlEmbed"
                      checked={isHtmlEmbed}
                      onChange={setHtmlEmbedStatus}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={isHtmlEmbed ? 'Advanced' : 'Simple'}
                />
              </div>
              
            </div>

            <Grid container alignItems="center">
              <Grid item xs={1} style={{marginRight: 0}}>
                <div style={{paddingTop: 2}}>
                  <ReportIcon className={classes.moreIcon} />
                </div>
              </Grid>
              <Grid item xs={11}>  
                <p><span style={{fontSize: 12}}>{t('automate.email.noteMessage')}</span></p>
              </Grid>
            </Grid>

            <div className={classes.container}>
              {/* --------------------------------------------------------------------- */}
              {/* BODY */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.emailBody}>
                
                {!edit && !isHtmlEmbed &&
                  <div className={classes.clickable} onClick={handleToggle}>
                    <CommentDisplay
                      editorContent={fragment}
                      html={true}
                      placeholder={t('automate.email.draft')}
                    />
                  </div>
                }

                {!edit && isHtmlEmbed &&
                  <div className={classes.clickable} onClick={handleToggle}>
                    <code className={classes.htmlCodeDisplay}>{fragment}</code>
                  </div>
                }

                {edit && !isHtmlEmbed && (
                
                  <TrixEditor
                    placeholder={t('automate.email.draft')}
                    submitOnEnter={false}
                    enableOnChange={true}
                    initialValue={fragment}
                    handleSlateOnChange={handleBodyUpdate}
                    plugins={['emailTags']}
                  />
                )}

                {edit && isHtmlEmbed && (
                  <EmailHtmlInput 
                    initValue={fragment}
                    handleHtmlOnChange={handleBodyUpdate}
                  />
                )}  
              </div>

            </div>
          </div>}

          {/* --------------------------------------------------------------------- */}
          {/* SUBMIT / CANCEL UPDATES */}
          {/* --------------------------------------------------------------------- */}
          {edit && (
            <div className={classes.submit}>
              <Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancelUpdates}>{t('actions.cancel')}</Button>
              <Button size="small" variant="outlined" className={classes.button} onClick={handleSubmitUpdates}>{t('actions.save')}</Button>
            </div>
          )}

        </div>
        {errors.CREATE_TAG && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            autoHideDuration={3000}
            onClose={() => handleCloseError()}
            key='top-center'
            message="Tag already exists"
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseError()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
        {message && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => handleCloseSnackbar()}
            key='top-center'
            message={message}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseSnackbar()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>

    </Dialog>

  )
}

EmailTemplateItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  users: state.users,
  roomTypes: state.roomTypes,
  email: state.email,
  loadingEmail: state.loading.LOAD_EMAIL,
  errors: state.errors
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(EmailTemplateItem)));