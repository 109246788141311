import React from 'react';
import { Button } from '@material-ui/core';
import PlusMinus from '../../common/PlusMinus';
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { fetchDatePerTimezone, fetchNoOfNights } from '../../../utils/utility';

export default (props) => {
  const { classes } = props;
  const [focused, setFocused] = React.useState(null);
  const [checkOut, setCheckOut] = React.useState(moment(props.selectedDate));

  const handleNightChange = (nights) => {
    setCheckOut(fetchDatePerTimezone(props.selectedDate).add(nights, 'days'))
    props.handleNightChange(nights)
  }

  const onDateChange = (checkOutDate) => {
    let nights = 0;
    if (checkOutDate && props.selectedDate) {
      nights = fetchNoOfNights(props.selectedDate, checkOutDate);
      setCheckOut(checkOutDate)
    } else {
      setCheckOut(null)
    }
    props.handleNightChange(nights)
  }

  return (
    <div className={classes.extendContainer}>
      <span className={classes.title}>Select number of nights</span>
      <div className={classes.extend}>
        <div className={classes.plusminusContainer}><PlusMinus fullWidth={true} prefilledCount={props.nights} onCountChange={handleNightChange} /></div>
        <span>OR</span>
        <div style={{display: 'grid'}}>
          <div className={classes.datepicker}>
            <SingleDatePicker
              date={checkOut}
              onDateChange={date => onDateChange(date)}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              id="check-in-date"
              placeholder={"Select checkout"}
              showClearDate={false}
              noBorder={true}
              numberOfMonths={1}
              isOutsideRange={day => isInclusivelyBeforeDay(day, moment(props.selectedDate))}
              displayFormat={'DD MMM YYYY'}
              daySize={30}
              hideKeyboardShortcutsPanel
            />
          </div>
        </div>
      </div>
      <Button
        className={classes.extendButton}
        disabled={!props.nights}
        onClick={() => props.handleExtend(props.itemID, props.selectedDate, props.nights)}>Extend</Button>
    </div>
  )
}