import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: 10,
    marginBottom: 10,
  },
  bubble: {
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    height: '30px',
    padding: '0 10px',
    minWidth: '80px',
  },
  cancelIcon: {
    justifyContent: 'space-between',
  },
  selected: {
    background: theme.palette.grey[400],
    color: '#000000',
  },
  icon: {
    marginLeft: 20,
    fontSize: '1.6rem',
  },
  green: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
}));

const Pill = props => {
  const classes = useStyles();
  const { name, selected=false, cancelButton=false, green=false, handleClick } = props;

  return (
    <div className={classes.container}>
      <div 
        className={clsx({
          [classes.bubble]: true, 
          [classes.cancelIcon]: cancelButton,
          [classes.selected]: selected,
          [classes.green]: green
        })}
        onClick={handleClick}
      >
        {name}
        {cancelButton && <CloseIcon className={clsx({[classes.icon]: true, [classes.green]: green})} />}
      </div>
    </div>
  )
}

export default Pill;