import http from '../../utils/http';
import { normalize } from 'normalizr';
import { cashDrawerSchema, cashSessionSchema, transactionSchema } from '../../schema/frontDeskSchema';


//----------------------------------------------------------------
// CREATE NEW CASH DRAWER
//----------------------------------------------------------------
export function postNewCashDrawer(spaceID, propertyID, name) {
  const ACTION_NAME = "CREATE_NEW_CASH_DRAWER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/cashDrawer', {propertyID, name})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// LOAD CASH DRAWERS
//----------------------------------------------------------------
export function fetchCashDrawers(propertyID, startDate, endDate, fetchSessions) {
  const ACTION_NAME = "LOAD_CASH_DRAWERS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/cashDrawer/property/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        var normalizedOrder = normalize(data, [ cashDrawerSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.cashDrawers});
        if (fetchSessions) {
          dispatch(fetchCashDrawerSessions(propertyID, data[0]._id, startDate, endDate))
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// FETCH CASH DRAWER SESSIONS
//----------------------------------------------------------------
export function fetchCashDrawerSessions(propertyID, cashDrawerID, startDate, endDate) {
  const ACTION_NAME = "FETCH_CASH_DRAWER_SESSIONS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/cashDrawer/sessions/${propertyID}/${cashDrawerID}?startDate=${startDate}&endDate=${endDate}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ cashSessionSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.cashSessions });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// FETCH CASH DRAWER TRANSACTIONS
//----------------------------------------------------------------
export function fetchCashDrawerTransactions(cashDrawerSessionID) {
  const ACTION_NAME = "FETCH_CASH_DRAWER_TRANSACTIONS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/cashDrawer/transaction/${cashDrawerSessionID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ transactionSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.cashDrawerTransactions });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// RESET CASH DRAWERS
//----------------------------------------------------------------
export const resetCashDrawers = () => (dispatch) => {
  dispatch({ type: "RESET_CASH_DRAWERS"})
};

//----------------------------------------------------------------
// RESET CASH DRAWER SESSIONS
//----------------------------------------------------------------
export const resetCashDrawerSessions = () => (dispatch) => {
  dispatch({ type: "RESET_CASH_DRAWER_SESSIONS"})
};

//----------------------------------------------------------------
// RESET CASH DRAWER TRANSACTIONS
//----------------------------------------------------------------
export const resetCashDrawerTransactions = () => (dispatch) => {
  dispatch({ type: "RESET_CASH_DRAWER_TRANSACTIONS"})
};
