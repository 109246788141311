import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';

import { loadSibaProcessed } from '../../redux/actions/legal/siba'

import MaterialTable from 'material-table';

const useStyles = makeStyles( theme => ({
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10,
    textAlign: 'center'
  },
  listSection: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },

}))

const BasicExport = (props) => {
  const { t } = props;
  const rowCount = props.data.length;
  return (
    <MaterialTable
      title={t('automate.siba.processed')}
      columns={[
        { title: t('reports.table.reservationName'), field: 'guestName', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        { title: t('reports.table.checkin'), field: 'checkIn', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        { title: t('reports.table.checkout'), field: 'checkOut', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        { title: t('automate.siba.noOfGuest'), field: 'customerCount', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        { title: t('automate.siba.sentOn'), field: 'sentOn', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        { title: t('automate.siba.status'), field: 'status', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}}
      ]}
      data={props.data}        
      options={{
        exportButton: false,
        rowStyle: { fontSize: '1.2rem'},
        pageSizeOptions: rowCount > 20 ? [5, 10, 20, rowCount] : [5, 10, 20]
      }}
      //onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  )
}

const Processed = (props) => {
  const { t, currentSpace, legal } = props
  const classes = useStyles()
  const [data, setData] = React.useState([])

  useEffect(() => {
    let arr = []
    if(legal && legal.siba && legal.siba.processed && legal.siba.processed.length){
      legal.siba.processed.map((p) => {
        arr.push({ guestName: p.reservation.guestName, checkIn: moment(p.reservation.checkIn).format('DD-MMM-YYYY'), checkOut: moment(p.reservation.checkOut).format('DD-MMM-YYYY'), sentOn: moment(p.updatedAt).format('DD-MMM-YYYY'), customerCount: p.guestsData.length, status: 'Sent' })
      })
      setData(arr)
    }
  }, [legal])

  useEffect(() => {
    props.dispatch(loadSibaProcessed(currentSpace.propertyID))
  }, [])

  return (
    <div className={classes.listSection}>
      {data.length === 0 && <div className={classes.label}>{t('experiences.reports.noData')}</div>}

      {data.length > 0 && 
        <BasicExport 
          t={t}
          data={data} 
          //handleTableRowClick={handleTableRowClick} 
        />
      }

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  legal: state.legal
})

export default withTranslation()(connect(mapStateToProps)(Processed))