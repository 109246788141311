export const sidebarMenu = {
    // TEMPLATE: {
    //     id: "TEMPLATE",
    //     title: "Template",
    // },
    BASIC: {
        id: "BASIC",
        title: "Basic Information",
    },
    ROOMTYPE: {
        id: "ROOMTYPE",
        title: "Room Types",
    },
    MULTICURRENCY: {
        id: "MULTICURRENCY",
        title: "Multi Currency"
    },
    RATES: {
        id: "RATES",
        title: "Rates",
    },
    CANCELLATION: {
        id: "CANCELLATION",
        title: "Cancellation",
    },
    VISIBILITY: {
        id: "VISIBILITY",
        title: "Visibility",
    },
    ROOMTYPELINKS: {
        id: "ROOMTYPELINKS",
        title: "Room Type Linkage",
    }
}