import React from "react";
import { connect } from 'react-redux';
import { makeStyles, CircularProgress, Grid } from "@material-ui/core";
import { returnCurrent } from '../../../redux/selectors/dashboard';

import { loadReservationManualOptions, resetReservationManualOptions } from "../../../redux/actions/rooms/rooms";
import { updateReservation, updateReservationDetails } from "../../../redux/actions/reservation/editReservation";
import Status from "./Status";
import LockReservation from "./LockReservation";
import { createTag, resetResrvTags } from '../../../redux/actions/reservation/reservationTags'
import StayInfo from "./StayInfo";
import DetailsForm from "./DetailsForm";
import Accommodations from "./guest-accommodation/";
import AddBedGuest from "./add-bed";
import { ICONS } from "../../../utils/imageUrls";
import { RESERVATION_STATUS } from "../../../utils/constants";
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
  },
  dottedContainer: {
    border: '2px dashed #dadada',
    margin: '15px 25px 15px 25px',
    textAlign: 'center',
    padding: '25px',
    cursor: 'pointer'
  },
  icon: {
    width: 25,
    height: 25
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '66%',
    height: 60,
    backgroundColor: '#fff'
  }
}))


const ReservationTab = (props) => {
  const classes = useStyles();
  const { reservation, accommodationList, currentSpace, loading, options, windowWidth, maskedCards } = props;
  const [displayForm, setDisplayForm] = React.useState(false)
  const [addBed, setAddBed] = React.useState(false)

  React.useEffect(() => {
    props.dispatch(resetResrvTags(currentSpace._id))
  }, [reservation])

  // React.useEffect(() => {
  //   props.dispatch(getReservationDetails(reservation._id))
  // }, [currentSpace.resvTags])

  /* --------------------------------------------------------------- */
  /* API CALLS */
  /* --------------------------------------------------------------- */

  const loadReservationManualOptionData = (propertyID, data) => {
    resetReservationManualOptionData()
    props.dispatch(loadReservationManualOptions(propertyID, data))
  }

  const resetReservationManualOptionData = () => {
    props.dispatch(resetReservationManualOptions())
  }

  const updateReservationData = (payload, formType = null, propertyID = reservation.propertyID) => {
    props.dispatch(updateReservation(payload, formType, propertyID, props.calendarData))
  }

  const handleEditReservation = (payload) => {
    props.dispatch(updateReservationDetails(reservation._id, payload))
  }

  const createNewTag = (payload) => {
    const data = {
      ...payload,
      propertyID: reservation.propertyID,
      reservationID: reservation._id
    }

    props.dispatch(createTag(currentSpace._id, data))
  }

  const closeModalHandler = () => {
    setAddBed(false)
  }

  return (
    <div className={classes.container}>

      <Grid container>
        <Grid item xs={12}>

          {/* --------------------------------------------------------------- */}
          {/* STATUS FORM */}
          {/* --------------------------------------------------------------- */}
          <Status
            expand={displayForm}
            handleSelectStatus={(value) => setDisplayForm(value)}
          />

          {displayForm &&
            <DetailsForm
              open={displayForm}
              reservation={reservation}
              bookingSource={props.bookingSource}
              currentSpace={currentSpace}
              maskedCards={maskedCards}
              submitFormHandler={handleEditReservation}
              createNewTagHandler={createNewTag}
              closeFormHandler={() => setDisplayForm(false)} />
          }
        </Grid>

        {/* --------------------------------------------------------------- */}
        {/* GUESTS / NIGHTS */}
        {/* --------------------------------------------------------------- */}
        <Grid item xs={12}>
          <StayInfo
            reservation={reservation}
          />
        </Grid>

        {/* --------------------------------------------------------------- */}
        {/* ACCOMMODATIONS */}
        {/* --------------------------------------------------------------- */}
        <Grid item xs={12}>
          <Accommodations calendarData={props.calendarData}/>
        </Grid>

        {/* --------------------------------------------------------------- */}
        {/* ADD BED OR GUEST */}
        {/* --------------------------------------------------------------- */}
        {![RESERVATION_STATUS.CANCELLED, RESERVATION_STATUS.NO_SHOW].includes(reservation.status) &&
          <Grid item xs={12}>
            <div className={classes.dottedContainer} onClick={() => setAddBed(true)}>
              <div><img className={classes.icon} src={IMAGES.ICONS.darkAdd} /></div>
              <div>Add person or bed</div>
            </div>
            {addBed && <AddBedGuest reservation={reservation} closeModalHandler={closeModalHandler} currentSpace={currentSpace} calendarData={props.calendarData} />}
          </Grid>
        }

        {/* --------------------------------------------------------------- */}
        {/* LOCK RESERVATION */}
        {/* --------------------------------------------------------------- */}
        <Grid item xs={12}>
          <LockReservation
            reservation={reservation}
            allocationLock={reservation.allocationLock}
            updateReservation={updateReservationData}
          />
        </Grid>

      </Grid>

      {loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  let reservation = state.reservationDetails.reservation;
  let currentSpace = returnCurrent(state.spaces, state.dashboard.currentSpace);
  let tags = reservation.tags || [];
  if(currentSpace.tempTags){
    currentSpace.tempTags.map(tag => {
      if(!tags.includes(tag._id)) tags.push(tag._id)
    })
  }

  reservation.tags = tags || [];

  let accommodationList = reservation.accommodationList.map(acc => {
    acc.rateList = acc.rateList.sort((l, m) => l.date > m.date ? 1 : -1);
    return acc
  }).sort((a, b) => (a.checkIn > b.checkIn ? 1 : -1));

  return {
    currentSpace: currentSpace,
    reservation: reservation,
    accommodationList: accommodationList,
    roomTypes: state.roomTypes,
    rooms: state.rooms,
    beds: state.beds,
    bookingSource: state.bookingSource,
    maskedCards: state.reservationDetails?.maskedCards,
    loading: state.loading.CANCEL_RESERVATION ||
      state.loading.LOAD_RESERVATION_MANUAL_OPTIONS ||
      state.loading.UPDATE_RESERVATION ||
      state.loading.UPDATE_RESERVATION_DATA ||
      state.loading.UPDATE_RESERVATION_STATUS,
    updated: state.success.UPDATE_RESERVATION,
    errors: state.errors.UPDATE_RESERVATION
  }
}

export default connect(mapStateToProps)(ReservationTab);