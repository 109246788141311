import React from "react";
import { connect } from "react-redux";

import { FRONTDESK_USER_ROLE } from "../../utils/constants";

import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    background: "#ffffff",
    borderRadius: "15px",
    marginBottom: 15,
    padding: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    borderRadius: "50%",
    height: 50,
    objectFit: "cover",
    width: 50,
  },
  descContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.3rem",
    marginBottom: 8,
  },
  subTitle: {
    color: "#666666",
    fontSize: "1.1rem",
  },
}));

const StaffList = (props) => {
  const classes = useStyles();
  const { currentSpace, frontdeskUsers } = props;

  return (
    <div className={classes.root}>
      {currentSpace.frontdeskUsers &&
        currentSpace.frontdeskUsers.map((userID) => (
          <div key={userID} className={classes.container}>
            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <div className={classes.imageContainer}>
                  {frontdeskUsers[userID].avatarURL ? (
                    <img
                      src={frontdeskUsers[userID].avatarURL}
                      className={classes.image}
                    />
                  ) : (
                    <img
                      src="/images/placeholderProfile.jpg"
                      className={classes.image}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className={classes.descContainer}>
                  <div className={classes.label}>
                    {" "}
                    {frontdeskUsers[userID].firstName}{" "}
                    {frontdeskUsers[userID].lastName}
                  </div>
                  <div className={classes.subTitle}>
                    {frontdeskUsers[userID].role ===
                      FRONTDESK_USER_ROLE.APP_ADMIN && "Admin"}
                    {frontdeskUsers[userID].role ===
                      FRONTDESK_USER_ROLE.MANAGER && "Manager"}
                    {frontdeskUsers[userID].role ===
                      FRONTDESK_USER_ROLE.FRONT_DESK && "Staff"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  frontdeskUsers: state.frontdeskUsers,
});

export default connect(mapStateToProps)(StaffList);
