import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD HOUSEKEEPING BY DAY
//----------------------------------------------------------------
export function loadHousekeepingByDay(propID, date) {
  const ACTION_NAME = "LOAD_HOUSEKEEPING";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/housekeeping/${propID}?date=${date}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {date: date, data} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET HOUSEKEEPING REPORT
//----------------------------------------------------------------
export const resetHousekeepingReport = () => ({
  type: 'RESET_HOUSEKEEPING_REPORT'
})