import http from "../../utils/http";

export function getStripeAccountLink(propertyID) {
    const ACTION_NAME = "GET_STRIPE_ACCOUNT_LINK";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .get(`api/frontdesk/stripe/connect/accountLink/${propertyID}`)
        .then(successResult => {
          dispatch({ type: `${ACTION_NAME}_SUCCESS` });
          return successResult.data;
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
          return;
        });
    };
}

export function getStripeAccountStatus(propertyID) {
    const ACTION_NAME = "GET_STRIPE_ACCOUNT_STATUS";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .get(`api/frontdesk/stripe/connect/accountStatus/${propertyID}`)
        .then(successResult => {
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:successResult.data });
          return successResult.data;
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
          return;
        });
    };
}

export function resetError(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}