import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { createBuilding } from "../../../../redux/actions/rooms/building";

import NumberInput from '../../../common/NumberInput';
import Pill from '../../../common/Pill';
import ConfirmForm from '../../../common/ConfirmForm';

import { InputBase } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  new: {
    display: 'flex',
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50,
  },
  description: {
    height: '70px',
    width: '60%'
  },
  addBuilding: {
    marginTop: 10,
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex'
  },
  pillContainer:{
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%',
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
  long: {
		width: '60%'
	},
}));


const Details = props =>{
  const classes = useStyles();
  const { 
    currentSpace,
    room,
    buildings,
    loadingBuildings,
    handleUpdateRoom,
    t
  } = props;
  const [newBuilding, setNewBuilding] = useState(false);
  const [newBuildingName, setNewBuildingName] = useState('');

  const handleClick = (building) => {
    if (room.buildingID == building) {
      handleUpdateRoom('buildingID', null);
    } else {
      handleUpdateRoom('buildingID', building)
    }
  }

  const handleNewBuiling = () => {
    if (newBuildingName !== '') {
      props.dispatch(createBuilding(currentSpace.propertyID, currentSpace._id, {
        name: newBuildingName,
        propertyID: currentSpace.propertyID
      }))
      setNewBuilding(false);
      setNewBuildingName('');
    }
  }
  
  return(
    <>
      <div className="main-content">
              
        <div className="main-content-title">{t('beds.roomTypesAndRooms.detailsOptional')}</div>
        <div className="sub-title">{t('beds.roomTypesAndRooms.enterRoomLoc')}</div>

        <div className="row">
					<div className="label">{t('beds.roomTypesAndRooms.description')}</div>
					<div className={clsx(classes.field, classes.description)}>
						<InputBase
							id="description"
							name="description"
							fullWidth
							value={room.description}
							type="text"
							multiline
							rows={3}
							onChange={(e) => handleUpdateRoom('description', e.target.value)}
						/>
					</div>
				</div>

        <div className="row">

          {/* --------------------------------------------------------------------- */}
          {/* LIST OF BUILDINGS */}
          {/* --------------------------------------------------------------------- */}
          {!loadingBuildings && currentSpace.buildings && currentSpace.buildings.length !== 0 && <div>
            <div className="label">{t('beds.roomTypesAndRooms.building')}</div>
            <div className={classes.pillContainer}>
              {currentSpace.buildings.map(building => 
                <Pill 
                  key={building}
                  name={buildings[building].name} 
                  handleClick={() => handleClick(building)}
                  selected={room.buildingID == building}
                  green={room.buildingID == building} 
                  cancelButton={room.buildingID == building}
                />
              )}
            </div>
          </div>}

          {!newBuilding && <div className={classes.addBuilding}>
            <div className={classes.link} onClick={() => setNewBuilding(true)}>{t('beds.roomTypesAndRooms.addBuilding')}</div>
          </div>}
        

          {newBuilding && <div className={classes.addBuilding}>
            {/* --------------------------------------------------------------------- */}
            {/* ADD NEW BUILDING */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.new}>
              <div className={clsx(classes.field, classes.long)}>
                <InputBase
                  id="building"
                  placeholder={t('beds.roomTypesAndRooms.buildingName')}
                  type="text"
                  onChange={(e) => setNewBuildingName(e.target.value)}
                  className={classes.building}
                />
              </div>

              {/* --------------------------------------------------------------------- */}
              {/* SUBMIT / CANCEL NEW BOOKING SOURCE */}
              {/* --------------------------------------------------------------------- */}
              <ConfirmForm 
                open={true}
                onClose={() => setNewBuilding(false)}
                onConfirm={handleNewBuiling}
                setOpen={()=>{}}
              />
            </div>
          </div>}

        </div>

        <div className="row">
          <div className="label">Floor</div>
          {/* Floor */}
          {/* ------------------------------------ */}
          <div className={classes.field}>
            <NumberInput 
              id="floor"
              name="floor"
              min={0}
              value={(room.floor && room.floor !=='') ? room.floor : ''}
              onChange={(e) => handleUpdateRoom('floor', e.target.value.trimStart())} 
            />
          </div>
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  ratePlans: state.ratePlans,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  buildings: state.buildings,
  loadingBuildings: state.loading.LOAD_BUILDINGS
})

export default withTranslation()(connect(mapStateToProps)(Details)); 