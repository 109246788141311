import React from "react";
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';
// import CashDrawerSessions from '../reports/CashDrawerSessions';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'scroll'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%',
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  backButton: {
    color: '#666666',
    fontSize: '3rem',
    left: 24,
    position: 'absolute',
    top: 28
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cancelButton: {
    marginRight: 10
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  button: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  listSection: {
    minHeight: 'calc(100vh - 230px)',
    padding: theme.spacing(3)
  },
  todoContainer: {
    alignItems: "center",
    borderBottom: '1px solid #eeeeee',
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  rightAlign : {
    paddingLeft: 30,
    width: '70%'
  },
  todoName: {
    cursor: "pointer",
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.5
  },
  textField: {
    fontWeight: 600
  },
  todoDetails: {
    fontSize: "1.2rem",
    marginTop: 7
  },
  moreIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor:'pointer',
    fontSize: '1.8rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
});

const CashDrawerItem = (props) => {
  const { t } = props
  const {classes, cashDrawers, cashDrawerID, sessions, loadingSessions} = props;
  const cashDrawer = cashDrawers[cashDrawerID];
  const [edit, setEdit] = React.useState('');
  const [name, setName] = React.useState('');

  const handleBack = () => {
    // this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    props.handleBack();
  }

  const handleClose = () => {
    // this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    props.handleClose();
  }

  const handleCancelUpdates = () => {
    setName('');
    setEdit(false);
  }

  const handleSubmitUpdates = () => {
    console.log('update cash drawer', cashDrawerID, name)
    handleCancelUpdates();
  }

  return(
    <Dialog
      open={Boolean(cashDrawerID)}
      classes={{ paperFullWidth: classes.dialogPaper }}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div>
            <Hidden smDown><Button 
              size='small' 
              color='primary' 
              variant='outlined' 
              className={classes.newListButton}
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              onClick={handleBack}
            >
              {t('actions.back')}
            </Button></Hidden>
            <Hidden mdUp>
              <BackIcon className={classes.backButton} onClick={handleBack} />
            </Hidden>
          </div>
          <Typography variant="h5">{edit && 'Edit '}{t('frontdesk.cashDrawer.singleName')}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listSection}>

          <div>
            <div className={classes.todoContainer}>

              <div className={classes.leftAlign}>
                {t('frontdesk.cashDrawer.nameLabel')}
              </div>
              
              {/* --------------------------------------------------------------------- */}
              {/* Cash Drawer Name */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.rightAlign}>
                {!edit && <div className={classes.todoName} onClick={() => setEdit(true)}>{cashDrawer.name}</div>}
                {edit && (
                  <TextField
                    id="task-name"
                    className={classes.textField}
                    defaultValue={cashDrawer.name}
                    onChange={(e) => setName(e.target.value)}
                    margin="none"
                    fullWidth
                    autoFocus
                  />
                )}
              </div>

              {/* ------------------------------------------------ */}
              {/* EDIT CASH DRAWER */}
              {/* ------------------------------------------------ */}
              {!edit && (
                <div onClick={() => setEdit(true)}>
                  <EditIcon className={classes.moreIcon} />
                </div>
              )}

              {/* --------------------------------------------------------------------- */}
              {/* SUBMIT / CANCEL UPDATES */}
              {/* --------------------------------------------------------------------- */}
              {edit && (
                <div className={classes.buttonHolder}>
                  <CloseIcon className={classes.button} onClick={handleCancelUpdates} />
                  <DoneIcon className={classes.button} onClick={handleSubmitUpdates} />
                </div>
              )}
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* UPDATED AT */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.todoContainer}>
              <div className={classes.leftAlign}>{t('frontdesk.cashDrawer.lastUpdate')}</div>
              <div className={classes.rightAlign}>
                {moment(cashDrawer.updatedAt).calendar() }
              </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* STATUS */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.todoContainer}>
              <div className={classes.leftAlign}>{t('frontdesk.cashDrawer.currentStatus')}</div>
                <div className={classes.rightAlign}>
                  <div>{cashDrawer.isClosed ? t('frontdesk.cashDrawer.closed') : t('frontdesk.cashDrawer.open')}</div>
                </div>
            </div>

          </div>

        </div>
      </div>
      
    </Dialog>
  )
}

const mapStateToProps = state => ({
  cashDrawers: state.cashDrawers,
  sessions: state.cashDrawerSessions,
  loadingSessions: state.loading.FETCH_CASH_DRAWER_SESSIONS
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(CashDrawerItem)));