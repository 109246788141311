import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { DateRangePicker } from "react-dates";
import clsx from "clsx";
import Select from "react-select";
import moment from "moment-timezone";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { connect } from "react-redux";
import {
  createHostelWorldReviewFlag,
  createHostelWorldReviewReply,
  getInboxReviews,
} from "../../redux/actions/microsite/hostelworld";
import { round, truncate } from "lodash";
import MaterialTable from "material-table";
import { DATE_FORMATS, fetchDateFormat } from "../../utils/utility";
import { groupingConfig } from "../utils/excelExport";

import { fetchDateRange } from "./helper";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 80,
    "& .MuiTableCell-head": {
      backgroundColor: "#F6F2EE",
    },
  },
  rightAlign: {
    boxSizing: "border-box",
    display: "flex",
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
  },
  fieldContainer: {
    borderRadius: 5,
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateContainer: {
    fontWeight: 500,
    paddingLeft: 10,
    paddingRight: 10,
    "& input": {
      fontWeight: 500,
      color: "#484848",
    },
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "300px",
    width: "100%",
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
  },
  reviewParam: {
    display: "flex",
    marginRight: 20,
    alignItems: "center",
  },
  reviewParamScore: {
    padding: "0px 5px",
    marginRight: "5px",
    backgroundColor: "#dededa",
    borderRadius: "3px",
  },
  button: {
    border: "1px solid #999999",
    marginRight: "5px",
  },
  buttonSelected: {
    background: "#006599",
    color: "white",
    marginRight: "5px",
    "&:hover": {
      background: "#006599",
    },
  },
  field: {
    alignItems: "flex-start",
    background: "#ffffff",
    borderRadius: "5px",
    boxShadow: "0 1px 10px #E0E0E0",
    display: "flex",
    flexWrap: "wrap",
    height: 150,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },

  detailPanelRoot: {
    padding: "15px",
  },

  reviewContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "20px",
    fontFamily: `'Gothic A1',sans-serif`,
    "& .comments": {
      color: "#484848",
      fontSize: "1.2rem",
    },
  },

  reviewReplyContainer: {
    marginLeft: 25,
  },

  reviewUser: {
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: "5px",
  },

  scoresContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: "0px 10px",
    "& .item": {
      display: "flex",
      alignItems: "center",
    },
    "& .label": {
      fontWeight: 600,
      fontSize: "1.1rem",
      color: "#484848",
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
  },
  multiLine: {
    alignItems: "flex-start",
    height: 100,
    overflowY: "hidden",
  },
  field: {
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
    display: "flex",
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "calc(80% - 32px)",
    "& > div": {
      width: "100%",
    },
    "&.disabled": {
      backgroundColor: "#E0E0E0",
    },
  },
  fieldError: {
    border: "1px solid red",
  },
  radioContainer: {
    color: "#666666",
    marginBottom: 10,
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    "& span": {
      fontSize: "1.3rem",
      padding: 0,
      paddingRight: 5,
    },
  },
  tab: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    flexDirection: "column",
    height: "30px",
    justifyContent: "center",
    marginRight: 10,
    padding: "10px",
    marginRight: 20,
    marginLeft: 0,
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  hide: {
    display: "none",
  },
}));
const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const reviewParams = [
  "security",
  "location",
  "staff",
  "atmosphere",
  "cleanliness",
  "valueForMoney",
  "facilities",
];

const reviewParamTitle = {
  security: "Security",
  location: "Location",
  staff: "Staff",
  atmosphere: "Atmosphere",
  cleanliness: "Cleanliness",
  valueForMoney: "Value For Money",
  facilities: "Facilities",
};

const ReviewReplyFlagForm = (props) => {
  const { rowData, t, classes } = props;
  const [formType, setFormType] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [error, setError] = React.useState(null);

  const onFormChangeHandler = (e) => {
    console.log("e.target.value", e.target.value);
    setFormType(e.target.value);
  };
  const onChangeHandler = (e) => {
    setDescription(e.target.value);
    setError(false);
  };
  const cancelHandler = () => {
    setFormType(null);
    setError(false);
    setDescription("");
  };
  const submitHandler = () => {
    if (!description) {
      setError(true);
      return;
    }
    props.submitHandler(formType, description);
    cancelHandler();
  };

  return (
    <React.Fragment>
      {rowData.comments && !(rowData.reply && rowData.query) && (
        <div className={classes.row}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              className={classes.radioContainer}
              name="typeOfForm"
              onClick={(e) => onFormChangeHandler(e)}
            >
              {!rowData.reply && (
                <FormControlLabel
                  value="reply"
                  className={clsx({
                    [classes.tab]: true,
                    [classes.tabSelected]: formType === "reply",
                  })}
                  control={
                    <Radio
                      color="primary"
                      className={classes.hide}
                      disableRipple
                    />
                  }
                  label={t("hostelworldChannel.reviews.table.replyToReview")}
                />
              )}
              {!rowData.query && (
                <FormControlLabel
                  value="flag"
                  className={clsx({
                    [classes.tab]: true,
                    [classes.tabSelected]: formType === "flag",
                  })}
                  control={
                    <Radio
                      color="primary"
                      className={classes.hide}
                      disableRipple
                    />
                  }
                  label={t(
                    "hostelworldChannel.reviews.table.flagAsInappropriate"
                  )}
                />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      )}

      {formType && (
        <React.Fragment>
          <div className={classes.row}>
            <div className={classes.label}>Description</div>
            <div
              className={clsx(classes.multiLine, classes.field, {
                [classes.fieldError]: error,
              })}
            >
              <InputBase
                id="description"
                name="description"
                fullWidth
                multiline
                rows="5"
                type="text"
                value={description}
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className={classes.submit}>
            <Button className={classes.cancel} onClick={cancelHandler}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={() => submitHandler()}
            >
              {t("hostelworldChannel.reviews.table.submit")}
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DetailPanel = ({ rowData, t, submitHandler }) => {
  const classes = useStyles();
  const [isReply, setIsReply] = useState(true);
  const [reply, setReply] = useState("");

  return (
    <div className={classes.detailPanelRoot}>
      {/* -----------GUEST COMMENT----------- */}
      {rowData.comments && (
        <div className={classes.reviewContainer}>
          <div className={classes.reviewUser}>{rowData.guestName}</div>
          <div className="comments">{rowData.comments}</div>
        </div>
      )}

      {/* -----------CHANNEL REPLY----------- */}
      {rowData.reply && (
        <div
          className={clsx(
            classes.reviewContainer,
            classes.reviewReplyContainer
          )}
        >
          <div className="comments">
            <b>Reply: </b>
            {rowData.reply}
          </div>
        </div>
      )}

      {/* -----------CHANNEL FLAG----------- */}
      {rowData.query && (
        <div
          className={clsx(
            classes.reviewContainer,
            classes.reviewReplyContainer
          )}
        >
          <div className="comments">
            <b>Flagged:</b> {rowData.query}
          </div>
        </div>
      )}

      {/* -----------REVIEW SCORES----------- */}
      <div className={classes.scoresContainer}>
        {reviewParams.map((param) => (
          <div key={param} className="item">
            <div className={classes.reviewParamScore}>
              {rowData.scores[param]}
            </div>
            <div className="label">{reviewParamTitle[param]}</div>
          </div>
        ))}
      </div>

      <ReviewReplyFlagForm
        rowData={rowData}
        t={t}
        classes={classes}
        submitHandler={(type, data) =>
          submitHandler(type, data, rowData.reviewID)
        }
      />
    </div>
  );
};

const BasicExport = (props) => {
  const { t } = props;
  const rowCount = props.data.length;
  const tableTitle = t("hostelworldChannel.reviews.title");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  const headerStyle = { backgroundColor: "#F6F2EE" };
  const data = props.data;

  const groupingConfigParmas = {
    data,
    tableTitle,
    displayDate,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("hostelworldChannel.reviews.table.date"),
          headerStyle,
          field: "stayed",
          render: (rowData) => moment(rowData.stayed).format("DD MMM YYYY"),
        },
        {
          title: t("hostelworldChannel.reviews.table.guestName"),
          field: "guestName",
          headerStyle,
        },
        {
          title: t("hostelworldChannel.reviews.table.country"),
          field: "nationality",
          headerStyle,
        },
        {
          title: t("hostelworldChannel.reviews.table.age"),
          field: "ageRange",
          headerStyle,
        },
        {
          title: t("hostelworldChannel.reviews.table.score"),
          field: "overallScore",
          headerStyle,
        },
        {
          title: t("hostelworldChannel.reviews.table.comments"),
          headerStyle,
          field: "comments",
          render: (rowData) =>
            rowData.comments ? (
              <Tooltip title={rowData.comments} placement="top">
                <div>{truncate(rowData.comments, { length: 25 })}</div>
              </Tooltip>
            ) : (
              ""
            ),
        },
        {
          title: t("hostelworldChannel.reviews.table.replied"),
          headerStyle,
          field: "reply",
          render: (rowData) =>
            rowData.reply ? (
              <div style={{ color: "green" }}>
                <DoneRoundedIcon />
              </div>
            ) : (
              "-"
            ),
        },
      ]}
      detailPanel={(rowData) => (
        <DetailPanel
          rowData={rowData}
          t={t}
          submitHandler={props.reviewResponseHandler}
        />
      )}
      data={data}
      isLoading={props.isLoading}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: "1.2rem" },
        headerStyle: {
          zIndex: "unset",
          backgroundColor: "#F6F2EE",
        },
        paging: rowCount > 50,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
      }}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
        body: {
          emptyDataSourceMessage: "No Reviews Found",
        },
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
};

const ReviewsDetails = (props) => {
  const { open, handleClose, loadingReviews, currentSpace, dispatch, t } =
    props;

  const presetRanges = [
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();

  const [focusedInput, setFocusedInput] = useState(null);
  const [presetValue, setPresetValue] = useState(presetRanges[2]);
  const { reportFrom, reportTo } = fetchDateRange(presetRanges[2].value);
  const initDates = { startDate: reportFrom, endDate: reportTo };
  const [dates, setDates] = React.useState({ ...initDates });
  const [submittedDates, setSubmittedDates] = React.useState({ ...initDates });
  const [reviews, setReviews] = React.useState([]);
  const [error, setError] = React.useState("");

  useEffect(() => {
    getHWReviews(dates);
  }, []);

  const perReviewAverage = reviews.map((r) => {
    const sum = reviewParams.reduce(
      (prev, current) => r.scores[current] + prev,
      0
    );
    return sum / reviewParams.length;
  });

  const average =
    perReviewAverage.reduce((prev, current) => prev + current, 0) /
    perReviewAverage.length;

  const reviewParamsAvg = {};
  const sum = {};
  reviewParams.forEach((param) => {
    reviews.forEach((review) => {
      if (!sum[param]) sum[param] = 0;
      sum[param] += review.scores[param];
    });
    reviewParamsAvg[param] = round(sum[param] / reviews.length, 1);
  });

  const getHWReviews = (dates) => {
    if (!(dates.startDate && dates.endDate)) return;
    const params = {
      startDate: dates.startDate.clone().format("YYYY-MM-DD"),
      endDate: dates.endDate.clone().format("YYYY-MM-DD"),
    };
    dispatch(getInboxReviews(currentSpace.propertyID, params))
      .then((data) => {
        if (data && data.length > 0) {
          const dateRange = {
            startDate: dates.startDate,
            endDate: dates.endDate,
          };
          setReviews(data);
          setSubmittedDates({ ...dateRange });
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const reviewResponseHandler = (type, data, reviewID) => {
    const params = {
      startDate: dates.startDate.clone().format("YYYY-MM-DD"),
      endDate: dates.endDate.clone().format("YYYY-MM-DD"),
    };
    if (type === "reply") {
      props
        .dispatch(
          createHostelWorldReviewReply(
            { reply: data, ...params },
            currentSpace.propertyID,
            reviewID
          )
        )
        .then((data) => {
          if (data && data.length > 0) {
            const dateRange = {
              startDate: dates.startDate,
              endDate: dates.endDate,
            };
            setReviews(data);
            setSubmittedDates({ ...dateRange });
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
    if (type === "flag") {
      props
        .dispatch(
          createHostelWorldReviewFlag(
            { queryText: data, ...params },
            currentSpace.propertyID,
            reviewID
          )
        )
        .then((data) => {
          if (data && data.length > 0) {
            const dateRange = {
              startDate: dates.startDate,
              endDate: dates.endDate,
            };
            setReviews(data);
            setSubmittedDates({ ...dateRange });
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const setNewDates = (data) => {
    setPresetValue(data);

    if (data.value == "custom") return;

    const filterType = data.value;
    const { reportFrom, reportTo } = fetchDateRange(filterType);

    setDates({ startDate: reportFrom, endDate: reportTo });
  };

  const handleSubmit = () => {
    if (loadingReviews) return;
    getHWReviews(dates);
  };

  const isAfterMaxAllowedUpdateDate = (day) => {
    if (dates?.startDate) {
      const maxAllowedUpdateDate = moment(dates.startDate)
        .tz(currentSpace?.timezone)
        .add(1, "years")
        .format(DATE_FORMATS.DEFAULT_DATE);
      const status = day.isAfter(maxAllowedUpdateDate, "day");
      return status;
    }

    return false;
  };

  const closeErrorHandler = () => {
    setError(null);
  };

  const closeDialogHandler = () => {
    if (error) closeErrorHandler();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialogHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("hostelworldChannel.reviews.title")}
          </div>
          <AccessHeaderAction moduleID={MODULE.HW_REVIEWS.ID} />
          <CloseIcon
            className={classes.closeIcon}
            onClick={closeDialogHandler}
          />
        </div>
        <div className={classes.listContainer}>
          {/* --------------------------------------------------------- */}
          {/* DATE RANGE FILTERS */}
          {/* --------------------------------------------------------- */}
          <div className={clsx(classes.listSection, classes.rightAlign)}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <div className={classes.fieldContainer}>
                  <Select
                    value={presetValue}
                    styles={selectStyle}
                    options={presetRanges}
                    onChange={setNewDates}
                    theme={(theme) => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item>
                <div
                  className={clsx(
                    classes.fieldContainer,
                    classes.dateContainer
                  )}
                >
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[7]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat="DD MMM YYYY"
                    hideKeyboardShortcutsPanel
                    // isOutsideRange={() => false}
                    isOutsideRange={(day) => isAfterMaxAllowedUpdateDate(day)}
                    numberOfMonths={1}
                    noBorder
                  />
                </div>
              </Grid>
              <Grid item xs={2} md={1} lg={1}>
                <div className={classes.buttonContainer} onClick={handleSubmit}>
                  <ArrowIcon className={classes.go} />
                </div>
              </Grid>
            </Grid>
          </div>
          {/* --------------------------------------------------------- */}
          {/* LOADING STATE */}
          {/* --------------------------------------------------------- */}
          {loadingReviews && (
            <div className={classes.blankSlate}>
              <CircularProgress />
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* REVIEWS SUMMARY BUBBLES */}
          {/* --------------------------------------------------------- */}
          {!loadingReviews && (
            <div className={classes.listSection}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {round(average, 2) || 0}
                    </div>
                    <div className={classes.desc}>
                      {t("hostelworldChannel.reviews.reviewScore")}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>{reviews.length}</div>
                    <div className={classes.desc}>
                      {t("hostelworldChannel.reviews.title")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* REVIEW PARAMETERS AVERAGE SCORES */}
          {/* --------------------------------------------------------- */}
          {!loadingReviews && (
            <div className={classes.listSection}>
              <Grid container spacing={3} justifyContent="center">
                {reviewParams.map((param) => (
                  <div key={param} className={classes.reviewParam}>
                    <div className={classes.reviewParamScore}>
                      {reviewParamsAvg[param] || 0}
                    </div>
                    <div>{reviewParamTitle[param]}</div>
                  </div>
                ))}
              </Grid>
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* RESERVATION TABLE */}
          {/* --------------------------------------------------------- */}
          {!loadingReviews && (
            <div className={classes.listSection}>
              <BasicExport
                t={t}
                data={reviews}
                startDate={submittedDates.startDate.format("DD MMM YYYY")}
                endDate={submittedDates.endDate.format("DD MMM YYYY")}
                sameDate={moment(submittedDates.startDate).isSame(
                  submittedDates.endDate,
                  "day"
                )}
                isLoading={false}
                reviewResponseHandler={reviewResponseHandler}
              />
            </div>
          )}
        </div>
      </div>
      {/* ------------- Snackbar error messages -------------- */}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={closeErrorHandler}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{error}</span>}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  // reviews: state.inbox.hostelworld.reviews,
  loadingReviews: state.loading.GET_INBOX_REVIEWS,
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default withTranslation()(connect(mapStateToProps)(ReviewsDetails));
