import { IMAGES_CDN_PATH } from "../../config";

const imagePath = IMAGES_CDN_PATH + "/logos";

export default {
  //LOGIN PAGE............
  counter: imagePath + "/counter.png",
  goki: imagePath + "/goki.png",
  mailchimp: imagePath + "/mailchimp.png",
  quickBooks: imagePath + "/quickBooks.png",
  zapier: imagePath + "/zapier.png",
  odoo: imagePath + "/odoo.png",
  siba: imagePath + "/siba.png",
  hostelworld: imagePath + "/hostelworld.jpg",
  paceRMS: imagePath + "/pace.png",
  masterway: imagePath + "/masterway.png",
};
