const FIELD_NAMES = {
    proformaSuffix: 'proformaSuffix',
    proformaPrefix: 'proformaPrefix', 
    proformaDigits: 'proformaDigits',
    startingIndexNumber: 'startingIndexNumber',

    prefix: 'prefix',
    suffix: 'suffix',
    finalIndexNumber: 'finalIndexNumber',
    invoiceDigits: 'invoiceDigits',
    
    taxPrefix: 'taxPrefix',
    taxSuffix: 'taxSuffix',
    taxIndexNumber: 'taxStartingIndexNumber',
    taxDigits: 'taxDigits',
    taxInvoiceEnabled: 'taxInvoiceEnabled',

    issuerName: 'issuerName',
    logoImage: 'logoImage',
    countResetOn: 'countResetOn',
    startCountDate: 'startCountDate',
    showLegalName: 'showLegalName',
    showTaxInfo: 'showTaxInfo',
    customText: 'customText'
}

const PROFORMA_FIELDS = {
    [FIELD_NAMES.proformaSuffix]: '',
    [FIELD_NAMES.proformaPrefix]: '', 
    [FIELD_NAMES.proformaDigits]: '',
    [FIELD_NAMES.startingIndexNumber]: 0,
}

const INVOICE_FIELDS = {
    [FIELD_NAMES.prefix]: '',
    [FIELD_NAMES.suffix]: '',
    [FIELD_NAMES.finalIndexNumber]: 0,
    [FIELD_NAMES.invoiceDigits]: ''
}

const TAX_RECEIPT_FIELDS = {
    [FIELD_NAMES.taxPrefix]: '',
    [FIELD_NAMES.taxSuffix]: '',
    [FIELD_NAMES.taxIndexNumber]: 0,
    [FIELD_NAMES.taxDigits]: 0,
    [FIELD_NAMES.taxInvoiceEnabled]: false
}

const COMMON_INVOICE_FIELDS = {
    [FIELD_NAMES.issuerName]: '',
    [FIELD_NAMES.logoImage]: '',
    [FIELD_NAMES.countResetOn]: '',
    [FIELD_NAMES.startCountDate]: null,
    [FIELD_NAMES.showLegalName]: false,
    [FIELD_NAMES.showTaxInfo]: false,
    [FIELD_NAMES.customText]: ''
}

export {
    PROFORMA_FIELDS,
    INVOICE_FIELDS,
    TAX_RECEIPT_FIELDS,
    COMMON_INVOICE_FIELDS,
    FIELD_NAMES
}