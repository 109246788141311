import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

const BarInfo = ({ data, indexBy, keyName }) => (
    <ResponsiveBar
        data={data}
        keys={keyName}
        indexBy={indexBy}
        margin={{ top: 10, right: 0, bottom: 30, left: 30 }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        padding={0.3}
        colors={"#F5DB8C"}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#F8F9FB',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#999999"
                }
              }
            },
            grid: {
              line: {
                stroke: "#E0E0E0",
                strokeDasharray: "4 4"
              }
            }
        }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 20
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -20
        }}
        maxValue={100}
        minValue={0}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
)

export default BarInfo;