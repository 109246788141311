import React from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { returnCurrent } from "../../redux/selectors/dashboard";
// import UserShortcuts from "./UserShortcuts";
import { setCurrentSpaceType, setCurrentSpace } from "../../redux/actions/dashboard/dashboard";
import { resetEmailTemplates } from '../../redux/actions/emails/emails';
import { resetRatePlans } from '../../redux/actions/rates/ratePlans';
import { resetCashDrawers } from '../../redux/actions/cash-drawers/cashDrawers';
import { loadUserPermissions } from '../../redux/actions/permissions/permissions';
import { resetCalendarDisplay } from '../../redux/actions/beds/calendar';

const styles = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'flex-start',
  },
  linkContainer: {
    width: '100%',
    height: 'calc(100vh - 80px)',
    overflow: 'hidden',
  },
  linkScroll: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    paddingRight: '17px',
    boxSizing: 'content-box',
    '& img': {
      cursor: 'pointer',
      // webkitFilter: 'grayscale(100%)',
      // filter: 'grayscale(100%)',
      height: 40,
      width: 40
    }
  },
  selectedImage: {
    filter: 'none'
  },
  home: {
    alignItems: 'center',
    display:'flex',
    justifyContent: 'center',
    height: '80px',
    '& img': {
      height: 40,
      width: 40,
    }
  },
  homeIcon: {
    cursor: 'pointer'
  },
  button: {
    marginBottom: 20,
    '& img': {
      borderRadius: '10px',
    }
  }
})

const Sidebar = (props) => {
  const { classes, spaces, emailTemplates, currentSpaceID, currentSpaceType, ratePlans, currentUserID } = props;

  const handleSpaceClick = (spaceID, spaceType) => {
    if (!emailTemplates.default) {props.dispatch(resetEmailTemplates())};
    if (!ratePlans.default) {props.dispatch(resetRatePlans())};
    props.dispatch(resetCashDrawers());
    props.dispatch(resetCalendarDisplay());
    props.dispatch(loadUserPermissions(currentUserID, spaceID));
    props.dispatch(setCurrentSpace(spaceID));
    props.dispatch(setCurrentSpaceType(spaceType));
  }

  const handleCommunityClick = () => {
    props.dispatch(setCurrentSpaceType('Community'));
    props.dispatch(setCurrentSpace('community'));
  }

  return (
    <div>
      {props.currentSpaceID && (
        <div className={classes.container}>
          
          <div className={classes.home}>
            <img className={classes.homeIcon} src='/images/icons/counter.png' onClick={handleCommunityClick} />
          </div>
          <div className={classes.linkContainer}>
            <div className={classes.linkScroll}>
              {Object.keys(spaces).map((spaceID) => {
                return (
                  <div key={spaceID} className={classes.button} onClick={() => handleSpaceClick(spaceID, spaces[spaceID].type)}>
                    {spaces[spaceID] && spaces[spaceID].profilePic && 
                      <img 
                        style={{
                          filter: (spaceID===currentSpaceID || currentSpaceType==='Community') ? 'grayscale(0%)' : 'grayscale(100%)',
                          opacity: (spaceID===currentSpaceID || currentSpaceType==='Community') ? 1 : 0.3
                        }} 
                        src={(spaces[spaceID].profilePic.includes('undefined') || !spaces[spaceID].profilePic) ? 
                            '/images/placeholder-hostel.jpg' : 
                            spaces[spaceID].profilePic}
                      />
                    }
                  </div>
                )
              })}
            </div>
          </div>

        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentSpaceType: state.dashboard.currentSpaceType,
  users: state.users,
  spaces: state.spaces,
  emailTemplates: state.emailTemplates,
  ratePlans: state.ratePlans,
  currentUserID: state.auth.user._id,
})

export default withStyles(styles)(connect(mapStateToProps)(Sidebar));