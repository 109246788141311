import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setCurrentModule, setMobileSidebarOpenValue } from '../../redux/actions/dashboard/dashboard';
import { fetchLiveReports } from '../../redux/actions/dashboard/live';
import { setOnboarding } from '../../redux/actions/landing/uiConfig';
import { clearSearch } from '../../redux/actions/search/search';
import { MENU } from '../../utils/layout';

import SidebarMobile from '../header/SidebarMoblie';

const styles = theme => ({
  root:{
    display: 'flex',
    background: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  container: {
    alignItems: 'center',
    background: '#F8F9Fb',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 10,
  },
  linkContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  linkScroll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    boxSizing: 'content-box',
    '& img': {
      cursor: 'pointer',
      height: 28,
      width: 28
    }
  },
  selectedImage: {
    filter: 'none'
  },
  home: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '80px',
    '& img': {
      height: 40,
      width: 40,
    }
  },
  homeIcon: {
    cursor: 'pointer'
  },
  button: {
    marginBottom: 24,
    padding: '15px 10px',
    marginLeft: '10px',
    paddingRight: 20,
    paddingLeft: 20,
    '& img': {
      borderRadius: '00px',
      height: 25,
      opacity: 0.5,
      width: 25,
    }
  },
  selected: {
    '& img': {
      opacity: 1,
    }
  },
  buttonActive: {
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 25,
    borderTopLeftRadius: 25,
  },

  subMenuContainer: {
    minWidth: '250px',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    padding: '10px',
    paddingTop: '10px',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
    }
  },
  subMenuTitle:{
    margin: '10px 0px',
    fontSize: '1.3rem',
    fontWeight: 600,
    paddingLeft: '15px',
  },
  subMenuTab:{
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: '5px',
    padding: '10px 20px',
    width: '100%',
    '&:hover, &.isActive':{
      // fontWeight: 500,
      color: '#fff',
      backgroundColor: '#6fa7d6'
    }
  }
})

const HWSidebar = (props) => {
  const { classes, currentModule, currentSubModule, mobileSidebarOpen, onboarding, dispatch, currentSpaceID, currentSpace } = props;
  const selectedMenuData = MENU.experience;
  const subMenuList = selectedMenuData.submenu;
  const activeSubmenu = currentSubModule ? currentSubModule : selectedMenuData.defaultSubmenu;

  
  const changeSubModule = (submenuID) =>{
    dispatch(setCurrentModule(currentModule, submenuID));
  }

  const setMobileMenuModule = (moduleID, subMenueID) =>{
    dispatch(setCurrentModule(moduleID, subMenueID));
    dispatch(clearSearch());
    dispatch(setOnboarding(false));
  }

  const handleMobileDrawerToggle = () => {
    dispatch(setMobileSidebarOpenValue(!mobileSidebarOpen))
  }

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        
          <div className={classes.subMenuContainer}>

            <div className={classes.subMenuTitle}>
              {selectedMenuData.name}
            </div>

            {Object.keys(subMenuList).map((menuID, menuIndex) =>(
              <div
                key={`${selectedMenuData.id}_${menuIndex}`} 
                className={clsx(
                  classes.subMenuTab, 
                  { isActive: menuID == activeSubmenu }
                )}
                style={{
                  background: menuID == activeSubmenu ? selectedMenuData.color : '#FFFFFF',
                  color: menuID == activeSubmenu ? '#FFFFFF' : '#666666'
                }}
                data-intercom-target={subMenuList[menuID].name}
                onClick={() => changeSubModule(menuID)}
              >
                {subMenuList[menuID].name}
              </div>
            ))}

          </div>  
      </div>

      <SidebarMobile 
        mobileSidebarOpen={mobileSidebarOpen} 
        handleDrawerToggle={handleMobileDrawerToggle}
        setMobileMenuModule={setMobileMenuModule}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const { dashboard, uiConfig, spaces } = state;
  return{
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule,
    mobileSidebarOpen: dashboard.mobileSidebarOpen,
    onboarding: uiConfig.onboarding,
    currentSpaceID: dashboard.currentSpace,
    currentSpace: spaces[dashboard.currentSpace]
  }
}

export default withStyles(styles)(connect(mapStateToProps)(HWSidebar));