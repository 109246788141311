import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import Activity_Flow from '../../../experiences/Activity_Flow';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    blankState: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        '& img': {
            height: 400,
            objectFit: 'cover',
            [theme.breakpoints.down('md')]: {
                height: 'auto',
                width: '100%'
            }
        }
    },
    textBox: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
        paddingBottom: 80,
    },
    title: {
        textAlign: 'center',
        width: '80%',
        marginBottom: 30,
    },
    subTitle: {
        fontSize: '1.5rem',
        color: '#AAAAAA',
        lineHeight: 1.5,
        marginBottom: 30,
        textAlign: 'center',
        width: '80%'
    },
    button: {
        background: '#FFFFFF',
        border: '1px solid #CC6633',
        borderRadius: 5,
        color: '#CC6633',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        fontWeight: 600,
        padding: '12px 16px',
    },
}));


const blankState = (props) => {
    const classes = useStyles();
    const { t } = props;
    const [showProductModal, setShowProductModal] = React.useState(false);

    const publishActionHandler = (status) =>{
        if(status){
            props.publishActionHandler();
        }
    }

    return (
        <div className={classes.blankState}>
            <img src={IMAGES.ILLUSTRATIONS.experiences} />
            <div className={classes.textBox}>
                <Typography className={classes.title} variant="h3">
                    {t('experiences.manage.activity.growBusiness')}
                </Typography>
                <div className={classes.subTitle}>{t('experiences.manage.activity.leverage')}</div>
                <div className={classes.button} onClick={() => setShowProductModal(true)}>{t('experiences.manage.activity.createExperience')}</div>
            </div>

            {showProductModal && (
                <Activity_Flow
                    closeModalHandler={() => setShowProductModal(false)}
                    showEditPage={false}
                    publishMessageHandler={publishActionHandler}
                />
            )}
        </div>

    )

}

export default withTranslation()(blankState);