import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import clsx from 'clsx';
import BlankState from './BlankState';
import Summary from './Summary';
// import Manage from './Manage';
import { Grid, Snackbar } from '@material-ui/core';
// import CounterMapping from './CounterMapping';
// import { activateChannelProperty, deactivateChannelProperty } from '../../../redux/actions/microsite/bookingCom';
// import { fetchChannelStatus } from '../../../redux/actions/microsite/channels';
// import { getChannelRouteInfo, setModalRoute } from './helper';

import SetupModal from './SetupModal';

const styles = theme => ({
    root: {
        background: '#FFFFFF',
        height: '100%',
        width: 'calc(100% - 40px)',
        padding: '10px 20px',
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 40
        }
    },
    leftColumn: {
        background: '#FFFFFF',
        padding: '15px 0px 15px 0',
    },
});

const Error = (props) => (
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        onClose={props.closeErrorHandler}
        autoHideDuration={6000}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.error}</span>}
    />
)

class GoogleHotelsConatiner extends Component {
    state = {
        showStepup: false,
        success: false,
        loading: false,
        error: null,
    }

    submitChangeStatusHandler = (type) => {
        const { dispatch, currentSpace } = this.props;
        this.setState({ success: false, loading: true, error: null })
        if (type === 'activate') {
            // const activateResponse = dispatch(activateChannelProperty(currentSpace.propertyID))
            // activateResponse.then(data => {
            //     dispatch(fetchChannelStatus(currentSpace.propertyID))
            //     this.setState({ success: true, loading: false })
            // }).catch(error => {
            //     this.setState({ loading: false, error: error })
            // })
        }
        if (type === 'deactivate') {
            // const deactivateResponse = dispatch(deactivateChannelProperty(currentSpace.propertyID))
            // deactivateResponse.then(data => {
            //     dispatch(fetchChannelStatus(currentSpace.propertyID))
            //     this.setState({ success: true, loading: false })
            // }).catch(error => {
            //     this.setState({ loading: false, error: error })
            // })
        }
    }

    setupGoogleHotels = (flag) => {
        this.setState({ showStepup: flag })
    }

    render() {
        const { classes, isLoading, channelStatus, currentSpace } = this.props;
        const { success, loading, showStepup } = this.state;
        return (
            <div className={classes.root}>
                {!isLoading &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <div className={classes.leftColumn}>
                                {channelStatus === null && <BlankState setOpen={this.setupGoogleHotels} />}
                                {/* {channelStatus !== null && <Manage />} */}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Summary
                                channelStatus={channelStatus}
                                timezone={currentSpace.timezone}
                                success={success}
                                loading={loading}
                                submitChangeStatusHandler={this.submitChangeStatusHandler} />
                        </Grid>
                    </Grid>
                }

                {showStepup && (
                    <SetupModal closeHandler={() =>this.setupGoogleHotels(false)}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { loading, inbox: { channels } } = state;
    return {
        currentSpace: state.spaces[state.dashboard.currentSpace],
        channelList: channels?.channelList || [],
        channelStatus: channels?.channelList?.BookingCom?.status || null,
        isLoading: loading.FETCH_INBOX_CHANNELS_STATUS,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(withRouter(GoogleHotelsConatiner)));