import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Property from './tab_pages/Property'
import Facilities from './tab_pages/Facilities'
import Location from './tab_pages/Location'
import Policies from './tab_pages/Policies'
import Conditions from './tab_pages/Conditions'
import Payments from './tab_pages/Payments'
import PropertyImages from './tab_pages/PropertyImages'

import Footer from './Footer'
import { cloneDeep } from 'lodash'

const styles = theme => ({
  root: {
    height: '100%'
  },
  contentHeader: {
    height: '100px',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '0px 20px',
    boxSizing: 'border-box',
    alignItems: 'center'
  },
  container: {
    '& .main-content': {
      height: 'calc(100vh - 60px)',
      padding: '0px 100px',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 20px',
        height: 'calc(100vh - 130px)'
      }
    },
    '& .main-content-title': {
      fontSize: '1.7rem',
      fontWeight: 600,
      marginBottom: '10px',
      marginTop: 40
    },
    '& .sub-title': {
      fontSize: '1.3rem',
      marginBottom: 30,
      '& p, ol, ul': {
        maxWidth: '400px'
      }
    },
    '& .row': {
      marginBottom: 30,
      width: '100%'
    },
    '& .label': {
      alignItems: 'center',
      color: '#484848',
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 500,
      marginBottom: 5
    },
    '& .info-icon': {
      cursor: 'pointer',
      height: 12,
      width: 12,
      marginLeft: 10,
      opacity: 0.5
    },
    '& .errorMessage': {
      color: '#f44336'
    }
  }
})

class ContentRenderer extends Component {
  constructor (props) {
    super(props)
    this.state = cloneDeep(props.propertySettings || {})

    this.components = {
      Property,
      Facilities,
      Location,
      Policies,
      Conditions,
      Payments,
      Images: PropertyImages
    }
  }

  render () {
    const { activeMenuID, contentProps, classes } = this.props
    const MainComponent = this.components[activeMenuID]

    if (!MainComponent) return null

    return (
      <div className={classes.root}>

        <div className={classes.container}>

          <MainComponent {...contentProps} />

          <Footer {...contentProps} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ContentRenderer)
