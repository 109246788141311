import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";

import PropertySettings from "./PropertySettings";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import { getChannelRouteInfo, setModalRoute } from "./helper";
import IMAGES from "../../constants/images";

const styles = (theme) => ({
  container: {
    background: "#F8F9FB",
    borderRadius: 5,
    display: "flex",
    height: "calc(100% - 30px)",
    flexDirection: "column",
    padding: 15,
    width: "calc(100% - 30px)",
  },
  widget: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  reviewHeader: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    marginBottom: 20,
  },
  score: {
    alignItems: "center",
    borderRadius: 5,
    background: "#006699",
    color: "#FFFFFF",
    display: "flex",
    fontSize: "1.6rem",
    fontWeight: 600,
    height: 30,
    justifyContent: "center",
    width: 35,
  },
  detailsSection: {
    marginLeft: 10,
  },
  desc: {
    color: "#006699",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  count: {
    color: "#006699",
    fontSize: "1.1rem",
  },
  highlight: {
    color: "#006699",
    fontStyle: "normal",
    display: "flex-inline"
  },
  rightMargin: {
    marginRight: 20,
  },
  leftMargin: {
    marginLeft: 20,
  },
  scoreHeader: {
    color: "#484848",
    display: "flex",
    justifyContent: "space-between",
  },
  scoreDesc: {
    fontSize: "1.1rem",
  },
  scoreValue: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  progressContainer: {
    marginBottom: 10,
    marginTop: 3,
    width: "100%",
    "& .MuiLinearProgress-barColorPrimary": {
      background: "#006699",
    },
  },
  imageHolder: {
    height: 200,
    [theme.breakpoints.up("lg")]: {
      height: 290,
    },
  },
  image: {
    borderRadius: 5,
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  logo: {
    height: 40,
    width: 40,
  },
  label: {
    color: "#333333",
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  icon: {
    borderRadius: "50%",
    cursor: "pointer",
    height: 18,
    width: 18,
    opacity: 0.6,
    padding: 8,
    "&:hover": {
      opacity: 1,
      background: "#EEEEEE",
    },
  },
  sampleData: {
    marginTop: "-10px",
    marginBottom: 15,
    fontSize: "1.3rem",
  },
  defaultImageText: {
    width: "calc(100% - 30px)",
    height: "calc(100% - 30px)",
    display: "flex",
    padding: 15,
    background: "#F8F9FB",
    borderRadius: 5,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
});

const thumbnailPath =
  "https://a.hwstatic.com/image/upload/c_fill,b_white,h_360,w_480/v1/";

const processReviewCategories = (data = {}) => [
  { desc: "Security", value: data?.security ? data.security / 10 : 0 },
  { desc: "Location", value: data?.location ? data.location / 10 : 0 },
  { desc: "Staff", value: data?.staff ? data.staff / 10 : 0 },
  { desc: "Atmosphere", value: data?.atmosphere ? data.atmosphere / 10 : 0 },
  { desc: "Cleanliness", value: data?.cleanliness ? data.cleanliness / 10 : 0 },
  { desc: "Facilities", value: data?.facilities ? data.facilities / 10 : 0 },
  {
    desc: "Value For Money",
    value: data?.valueForMoney ? data.valueForMoney / 10 : 0,
  },
];

const BannerSection = (props) => {
  const { classes, settings = {}, settingsLoading, currentSpace, propertyNumber } = props;
  const [propertySettingsOpen, openPropertySettings] = React.useState(false);
  const [reviewCategories, setReviewCategories] = React.useState(
    processReviewCategories(settings.ratings)
  );

  const { images = [] } = settings;
  const processedImages = images
    .sort((a, b) => a.order - b.order)
    .map((i) => thumbnailPath + i.uriPath);

  const pathname = useLocation().pathname;
  const history = useHistory();
  const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);

  React.useEffect(() => {
    setReviewCategories(processReviewCategories(settings.ratings));
  }, [settings.ratings]);

  React.useEffect(() => {
    //CHECK IF MODAL PATH EXISTS AND OPEN MODAL ON LOAD..........................
    if (modalPath && modalPath == "settings") openPropertySettingsModal(true);
  }, []);

  const openPropertySettingsModal = (status) => {
    const routeData = {
      modalPath: "settings",
      tabPath,
      basePath,
    };
    openPropertySettings(status);
    setModalRoute(status, history, routeData);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={classes.container}>
            <div className={classes.widget}>
              <div className={classes.label}>{settings?.name} <em className={classes.highlight}>( {propertyNumber} )</em></div>

              <AccessController
                requiredPermission={MODULE.HW_PROPERTY_SETTINGS.ID}
              >
                {settingsLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  <img
                    className={classes.icon}
                    src={IMAGES.ICONS.settings}
                    onClick={() => openPropertySettingsModal(true)}
                  />
                )}

                {/* ***************************PROPERTY SETTINGS MODAL****************************** */}
                {propertySettingsOpen && (
                  <PropertySettings
                    open={propertySettingsOpen}
                    handleClose={() => openPropertySettingsModal(false)}
                  />
                )}
              </AccessController>
            </div>
            {/* <div className={classes.sampleData}>Sample Data</div> */}
            <div className={classes.reviewsContainer}>
              <div className={classes.reviewHeader}>
                <div className={classes.score}>
                  {settings?.reviewsBadge?.rating || 0}
                </div>
                <div className={classes.detailsSection}>
                  <div className={classes.desc}>
                    {settings?.reviewsBadge?.ratingDescription || ""}
                  </div>
                  <div className={classes.count}>
                    {settings.reviewCount || 0} Reviews
                  </div>
                </div>
              </div>
              <div className={classes.reviewDetails}>
                <Grid container>
                  {reviewCategories.map((item, index) => (
                    <Grid key={item.desc} item xs={6}>
                      <div
                        className={clsx({
                          [classes.scoreContainer]: true,
                          [classes.rightMargin]: index % 2 == 0,
                          [classes.leftMargin]: index % 2 !== 0,
                        })}
                      >
                        <div className={classes.scoreHeader}>
                          <div className={classes.scoreDesc}>{item.desc}</div>
                          <div className={classes.scoreValue}>{item.value}</div>
                        </div>
                        <div className={classes.progressContainer}>
                          <LinearProgress
                            variant="determinate"
                            value={item.value * 10}
                          />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.imageHolder}>
            {processedImages[0] ? (
              <img src={processedImages[0]} className={classes.image} />
            ) : (
              <div className={classes.defaultImageText}>
                {!settingsLoading ? "Property Images, coming soon..." : ""}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  settingsLoading: state.loading.GET_PROPERTY_SETTINGS,
  settings: state.inbox.hostelworld.settings,
  currentSpace: state.spaces?.[state.dashboard?.currentSpace],
});

export default connect(mapStateToProps)(withStyles(styles)(BannerSection));
