import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import countries from 'i18n-iso-countries';


// Process guestList to display in guest table
// ------------------------------------------------------------------------
const guestProcessing = (guestList) => {
  let dataFormat = [];

  guestList && guestList.length!==0 && guestList.map(guest => dataFormat.push(
    {
      resvCode: guest?.code || '',
      name: `${toTitleCase(guest.firstName)} ${toTitleCase(guest.lastName)}`,
      checkIn: moment(guest.checkIn).format('DD MMM, YYYY'),
      checkOut: moment(guest.checkOut).format('DD MMM, YYYY'),
      duration: Math.round(guest.duration),
      dateOfBirth: `${guest.dateOfBirth ? moment(guest.dateOfBirth).format('DD MMM, YYYY') : '-'}`,
      age: guest.dateOfBirth ? moment(guest.checkIn).diff(moment(guest.dateOfBirth), 'year') : '-',
      nationality: `${guest.nationality ? countries.getName(guest.nationality, 'en') : '-'}`,
      documents: guest.documents || [{}],
      city: guest.city ? guest.city : '-',
      country: guest.country ? countries.getName(guest.country, 'en') : '-',
      resID: guest.resID
    }
  ));
  return dataFormat;
}

// Process demographics to display in country map
// ------------------------------------------------------------------------
const mapProcessing = (demographics) => {
  let data = [];
  let max = 10;

  demographics && demographics.length!==0 && demographics.map(item => {
    if (item.country && item.country!=="") { 
      item.count>max ? max=item.count : null;
      data.push({value: item.count, id: item.country});
    }
  });

  return {data, max};
}

// Count of guests
// ------------------------------------------------------------------------
const guestCount = (guestList, currentSpace) => {
  let domestic = 0;
  let international = 0;
  let unknown = 0;

  guestList && guestList.map(guest => {
    if (guest.nationality!=='') {
      guest.nationality===currentSpace.country ? domestic+= 1 : international+=1 
    } else {
      unknown+=1
    }
  })

  const total = domestic+international+unknown;
  if (total!==0) {
    domestic = Math.round(domestic/total * 100);
    international=Math.round(international/total * 100);
    unknown=Math.round(unknown/total * 100);
  }
  return {domestic, international, unknown};
}


export { guestProcessing, mapProcessing, guestCount };