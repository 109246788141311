const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "FETCH_LISTS_PROPERTY_SUCCESS":
      return {
      	...action.payload
      }
    case "SAVE_LIST_SUCCESS": 
    	return {
    		...action.payload
    	}
    case "UPDATE_LIST_SUCCESS": 
      return {
        ...action.payload
      }
    default:
      return state;
  }
};
