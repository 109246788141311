import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import CustomerDetails from './CustomerDetails';
import CustomerDocs from './CustomerDocs';
import CustomerRules from './CustomerRules';
import Upsell from './Upsell';
import Payment from './Payment';
import ContactDetails from './ContactDetails';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles( theme => ({
  closeIcon: {
  	borderRadius: '50%',
  	color: '#666666',
  	cursor: 'pointer',
  	padding: theme.spacing(1),
  	position: 'absolute',
  	right: 24,
  	transition: '150ms',
  	top: 20,
  	'&:hover': {
  		background: '#dddddd',
  		color: '#666666'
  	},
  	[theme.breakpoints.down('sm')]: {
  		top: '20px',
  		right: '10px'
  	}
  },
  tabsContainer: {
    marginBottom: 15,
    '& .MuiTabs-scroller': {
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-scroller': {
        whiteSpace: 'unset',
        display: 'block'
      }
    }
  },
  tabIcon: {
    height: 20,
    marginBottom: '10px !important',
    width: 20
  },
  tab: {
    background: '#ffffff',
    borderRadius: '15px',
    boxShadow: '0 2px 40px 0 #eeeeee',
    fontSize: '1.1rem',
    maxWidth: '70px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '70px',
    minWidth: 55,
    margin: 5,
    '&.Mui-selected': {
      background: '#E0E0E0;'
    }
  },
  tabOnline: {
    border: '2px solid #4F9581',
    background: '#ffffff',
    borderRadius: '15px',
    boxShadow: '0 2px 40px 0 #eeeeee',
    fontSize: '1.1rem',
    maxWidth: '70px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '70px',
    minWidth: 55,
    margin: 5,
    '&.Mui-selected': {
      background: '#E0E0E0;'
    }
  },
  tabPanel: {
    //borderRadius: '15px',
    //boxShadow: '0 2px 40px 0 #eeeeee',
    flexGrow: 1,
    '& .MuiBox-root': {
      overflowY: 'auto'
    }
  },
  docsPanel: {
    //borderRadius: '15px',
    //boxShadow: '0 2px 40px 0 #eeeeee',
    marginBottom: theme.spacing(6),
    flexGrow: 1
  },
  circleStyle: {
    display:"inline-block",
    backgroundColor: "#4F9581",
    borderRadius: "50%",
    width:8,
    height:8,
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

function labelInfo(index, data) {
  const classes = useStyles()

  return (
    <div>
      <div>{index}</div>
      {(data) && <div> 
        {(!data.skip) && <span className={classes.circleStyle}></span>}
      </div>}
    </div>
  )
}

const Steps = (props) => {
  const { t } = props
  const classes = useStyles()
  const { open, handleClose, currentSpace, property, loadingProperty, isLoading, onlineCheckin } = props
  const [value, setValue] = React.useState("customerDetails")
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs
        orientation="horizontal"
        aria-label="Vertical tabs example"
        className={classes.tabsContainer}
        value={value}
        variant="fullWidth"
        scrollButtons={windowWidth > 901 ? "auto" : "on"}
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}>
        <Tab
          value="customerDetails"
          /*icon={<img src="/images/icons/calendar.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("customerDetails")}
          label={labelInfo(t('automate.onlineCheckin.steps.customerDetails.name'), {skip: false})}
        />
        <Tab
          value="customerDocs"
          /*icon={<img src="/images/icons/guests.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("customerDocs")}
          label={labelInfo(t('automate.onlineCheckin.steps.customerDocs.name'), onlineCheckin.customerDocuments)}
        />
        <Tab
          value="customerRules"
          /*icon={<img src="/images/icons/wallet.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("customerRules")}
          label={labelInfo(t('automate.onlineCheckin.steps.customerRules.name'), onlineCheckin.customerRules)}
        />
        <Tab
          value="upsell"
          /*icon={<img src="/images/icons/conversation.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("upsell")}
          label={labelInfo(t('automate.onlineCheckin.steps.upsell.name'), {skip: true})}
        />
        <Tab
          value="payments"
          /*icon={<img src="/images/icons/notes.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("customerDetails")}
          label={labelInfo(t('automate.onlineCheckin.steps.customerPayments.name'), {skip: false})}
        />
        <Tab
          value="contact"
          /*icon={<img src="/images/icons/notes.png" className={classes.tabIcon} />}*/
          className={clsx(classes.tab, classes.tabSpace)}
          {...a11yProps("contact")}
          label={labelInfo(t('automate.onlineCheckin.steps.pointOfContact.name'), {skip: false})}
        />
      </Tabs>

      <TabPanel className={classes.tabPanel} value={value} index={"customerDetails"}>
        <CustomerDetails />
      </TabPanel>
      <TabPanel className={classes.docsPanel} value={value} index={"customerDocs"}>
        <CustomerDocs />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"customerRules"}>
        <CustomerRules />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"upsell"}>
        <Upsell />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"payments"}>
        <Payment />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"contact"}>
        <ContactDetails />
      </TabPanel>
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  onlineCheckin: state.onlineCheckin
})

export default withTranslation()(connect(mapStateToProps)(Steps))