import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Pill from '../../../common/Pill'
import { some } from 'lodash'

const useStyles = makeStyles(theme => ({
  new: {
    display: 'flex'
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  }
}))

const categories = [
  {
    categoryId: 1,
    value: 'free',
    name: 'Free',
    facilities: [
      {
        id: 96,
        name: 'Free Airport Transfers'
      },
      {
        id: 30,
        name: 'Free Parking'
      },
      {
        id: 87,
        name: 'Towels Included'
      },
      {
        id: 1,
        name: 'Breakfast Included'
      },
      {
        id: 9,
        name: 'Linen Included'
      },
      {
        id: 103,
        name: 'Free Internet Access'
      },
      {
        id: 90,
        name: 'Free WiFi'
      },
      {
        id: 80,
        name: 'Free City Maps'
      },
      {
        id: 140,
        name: 'Free city tour'
      }
    ]
  },
  {
    categoryId: 2,
    value: 'general',
    name: 'General',
    facilities: [
      {
        id: 78,
        name: 'Adaptors'
      },
      {
        id: 116,
        name: 'Parking'
      },
      {
        id: 28,
        name: 'Bicycle Parking'
      },
      {
        id: 27,
        name: 'Elevator'
      },
      {
        id: 113,
        name: 'Jobs Board'
      },
      {
        id: 119,
        name: 'Hairdryers'
      },
      {
        id: 115,
        name: 'Hairdryers for Hire'
      },
      {
        id: 37,
        name: 'Breakfast Not Included'
      },
      {
        id: 29,
        name: 'BBQ'
      },
      {
        id: 92,
        name: 'Kitchen'
      },
      {
        id: 82,
        name: 'Linen Not Included'
      },
      {
        id: 14,
        name: 'Lockers'
      },
      {
        id: 123,
        name: 'Safe Deposit Box'
      },
      {
        id: 50,
        name: 'Air conditioning'
      },
      {
        id: 120,
        name: 'Ceiling Fan'
      },
      {
        id: 111,
        name: 'Reading Light'
      },
      {
        id: 22,
        name: 'Key card access'
      },
      {
        id: 106,
        name: 'Cable TV'
      },
      {
        id: 24,
        name: 'Common Room'
      },
      {
        id: 107,
        name: 'Outdoor Terrace'
      },
      {
        id: 79,
        name: 'Book Exchange'
      },
      {
        id: 16,
        name: 'Swimming Pool'
      },
      {
        id: 88,
        name: 'Indoor Swimming Pool'
      },
      {
        id: 118,
        name: 'Outdoor Swimming Pool'
      },
      {
        id: 105,
        name: 'Hot Tub'
      },
      {
        id: 38,
        name: 'Gym'
      },
      {
        id: 81,
        name: 'Hot showers'
      },
      {
        id: 51,
        name: 'Meeting Room'
      },
      {
        id: 141,
        name: "Children's play area"
      },
      {
        id: 8,
        name: 'Wheelchair Accessible'
      },
      {
        id: 129,
        name: 'Card Phones'
      },
      {
        id: 131,
        name: 'Washing Machine'
      },
      {
        id: 144,
        name: 'Sauna'
      },
      {
        id: 145,
        name: 'Steam room'
      },
      {
        id: 99,
        name: 'Cooker'
      },
      {
        id: 91,
        name: 'Cots available'
      },
      {
        id: 98,
        name: 'Dishwasher'
      },
      {
        id: 102,
        name: 'Dryer'
      },
      {
        id: 100,
        name: 'Fridge/Freezer'
      },
      {
        id: 122,
        name: 'Iron/ironing Board'
      },
      {
        id: 124,
        name: 'Microwave'
      },
      {
        id: 132,
        name: 'Towels Not Included'
      },
      {
        id: 101,
        name: 'Utensils'
      },
      {
        id: 300,
        name: 'Flexible NRR'
      },
      {
        id: 301,
        name: 'Sanitisation Badge'
      }
    ]
  },
  {
    categoryId: 3,
    value: 'services',
    name: 'Services',
    facilities: [
      {
        id: 77,
        name: 'ATM'
      },
      {
        id: 20,
        name: '24 hour reception'
      },
      {
        id: 15,
        name: 'Luggage Storage'
      },
      {
        id: 5,
        name: 'Laundry Facilities'
      },
      {
        id: 6,
        name: 'Airport Transfers'
      },
      {
        id: 13,
        name: 'Towels for Hire'
      },
      {
        id: 23,
        name: 'Currency Exchange'
      },
      {
        id: 112,
        name: '24 hour security'
      },
      {
        id: 21,
        name: 'Tours/Travel desk'
      },
      {
        id: 121,
        name: 'Housekeeping'
      },
      {
        id: 7,
        name: 'Bicycle Hire'
      },
      {
        id: 4,
        name: 'Internet Access'
      },
      {
        id: 19,
        name: 'Fax Service'
      },
      {
        id: 97,
        name: 'Shuttle Bus'
      },
      {
        id: 104,
        name: 'Postal Service'
      },
      {
        id: 150,
        name: 'Express check-in / out'
      },
      {
        id: 151,
        name: 'Internet caf'
      },
      {
        id: 152,
        name: 'Late check-out'
      },
      {
        id: 153,
        name: 'Reception (limited hours)'
      },
      {
        id: 156,
        name: 'Wake-up calls'
      },
      {
        id: 17,
        name: 'Direct Dial Telephone'
      }
    ]
  },
  {
    categoryId: 4,
    value: 'food',
    name: 'Food & Drink',
    facilities: [
      {
        id: 114,
        name: 'Mini supermarket'
      },
      {
        id: 117,
        name: 'Cafe'
      },
      {
        id: 12,
        name: 'Bar'
      },
      {
        id: 2,
        name: 'Restaurant'
      },
      {
        id: 83,
        name: 'Meals Available'
      },
      {
        id: 125,
        name: 'Tea & Coffee Making Facilities'
      },
      {
        id: 86,
        name: 'Vending Machines'
      }
    ]
  },
  {
    categoryId: 5,
    value: 'entertainment',
    name: 'Entertainment',
    facilities: [
      {
        id: 95,
        name: 'Board Games'
      },
      {
        id: 41,
        name: 'Nightclub'
      },
      {
        id: 94,
        name: 'DVDs'
      },
      {
        id: 108,
        name: 'Games Room'
      },
      {
        id: 85,
        name: 'Wii'
      },
      {
        id: 84,
        name: 'Playstation'
      },
      {
        id: 109,
        name: 'Pool Table'
      },
      {
        id: 110,
        name: 'Fusball'
      },
      {
        id: 133,
        name: 'WiFi'
      }
    ]
  }
]

const Facilities = props => {
  const classes = useStyles()
  const {
    settings,
    updateSettings,
    t
  } = props

  const handleClick = facilityId => {
    let updated

    if (settings.facilities.includes(facilityId)) {
      updated = settings.facilities.filter(f => f !== facilityId)
    } else {
      updated = [...settings.facilities, facilityId]
    }

    updateSettings('facilities', updated)
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.facilities.title')}</div>
        <div className='sub-title'>{t('microsite.facilities.description')}</div>

        {categories.map(category => (
          <div className='row' key={category.categoryId}>
            <div className='label'>{t(`microsite.facilities.${category.value}`)}</div>
            <div className={classes.container}>
              {category.facilities.sort((a, b) => {
                const aSelected = some(settings.facilities, fId => fId === a.id)
                const bSelected = some(settings.facilities, fId => fId === b.id)
                if (aSelected && !bSelected) return -1
                if (!aSelected && bSelected) return 1
                return aSelected.id - bSelected.id
              }).map(facility => {
                const selected = some(settings.facilities, fId => fId === facility.id)
                return (
                  <Pill
                    key={facility.id}
                    name={facility.name}
                    cancelButton={selected}
                    selected={selected}
                    handleClick={() => handleClick(facility.id)}
                  />
                )
              }
              )}
            </div>
          </div>
        ))}

      </div>
    </>
  )
}

export default withTranslation()(Facilities)
