/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-expressions */
/* eslint-disable vars-on-top */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-relative-packages */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  makeStyles,
  CircularProgress,
  Typography,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { returnCurrent } from "../../redux/selectors/dashboard";

import AddButton from "../common/AddButton";
import RoomTile from "./RoomTile";
import NewRoom from "./room_wizard";
import { resetError } from "../../redux/actions/reservation/editReservation";
import { getCalendarRenderData } from "../../redux/actions/beds/calendar";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { ROOM_INVENTORY_TYPE, ROOM_WIZARD_MENU } from "../../constants";
import { DATE_FORMATS } from "../../utils/utility";
import { editRoom } from "../../redux/actions/rooms/rooms";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    width: "100%",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    // boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 40,
    padding: theme.spacing(3),
    paddingBottom: 0,
    width: "calc(100% - 48px)",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: 5,
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  permissions: {
    marginTop: "-12px",
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      left: "15px",
    },
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    background: "#FFFFFF",
    overflowY: "visible",
  },
  listSection: {
    margin: theme.spacing(3),
  },
  create: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  createSection: {
    alignItems: "center",
    display: "flex",
  },
  roomTypeTitle: {
    marginRight: 20,
    width: "50%",
    "& input": {
      fontSize: "1.4rem",
    },
  },
  roomTypeHeader: {
    marginRight: 20,
    width: "40%",
    "& input": {
      fontSize: "1.4rem",
    },
  },
  roomTypeCode: {
    marginRight: 20,
    width: "30%",
    "& input": {
      fontSize: "1.4rem",
    },
  },
  roomTypeDesc: {
    "& input": {
      fontSize: "1.3rem",
    },
  },
  cancelButton: {
    marginRight: 10,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: 300,
    justifyContent: "center",
  },
  blankText: {
    fontSize: "1.4rem",
    marginBottom: 20,
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  placeholder: {
    background: "#F2F2F2",
    borderRadius: "15px",
    height: 80,
    marginRight: 15,
    overflow: "hidden",
    width: 80,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  // eslint-disable-next-line no-dupe-keys
  addButton: {
    maxWidth: 140,
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginBottom: 0,
  },
  warning: {
    color: "#ff9800",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
}));

const FILTERS = {
  roomType: "roomType",
  room: "room",
  building: "building",
};

const reA = /[^a-zA-Z]/g;
const reN = /[^0-9]/g;

const RoomList = (props) => {
  const classes = useStyles();
  const { currentSpace, rooms, roomTypes, loadingRoomTypes, t } = props;
  const [activeRoomConfig, setActiveRoomConfig] = React.useState(false);
  const roomID = activeRoomConfig?.roomID;
  const [createNewList, setCreateNewList] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);

  const { uiSetting } = currentSpace;
  const filterType =
    uiSetting && uiSetting.calendarSort
      ? uiSetting.calendarSort
      : FILTERS.roomType;

  function sortAlphaNum(a, b) {
    const aA = rooms[a]?.name?.replace(reA, "");
    const bA = rooms[b]?.name?.replace(reA, "");
    if (aA === bA) {
      const aN = parseInt(rooms[a]?.name?.replace(reN, ""), 10);
      const bN = parseInt(rooms[b]?.name?.replace(reN, ""), 10);
      return aN - bN;
      // eslint-disable-next-line no-else-return
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  const handleOpen = () => {
    setCreateNewList(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleCancel = () => {
    setCreateNewList(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleSetRoomID = (roomID) => {
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
    setActiveRoomConfig({ roomID });
  };

  const handleEditRoom = (payload) => {
    console.log(payload);
  };

  const handleSubmitNewRoom = () => {
    handleCancel();
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleCloseError = () => {
    props.dispatch(resetError("DELETE_ROOM_TYPE"));
    setSnackbarOpen(false);
  };

  const handleExtendAvailability = ({
    room
  }) => {
    const roomID = room._id;
    const roomTypeID = room?.primaryRoomTypeID
    const isMultiInventory =
    room?.advancedMapping &&
    room?.advancedMapping === ROOM_INVENTORY_TYPE.oneToMany;

    if (isMultiInventory) {
      return setActiveRoomConfig({
        roomID,
        menuId: ROOM_WIZARD_MENU.INVENTORY,
      });
    }

    const startDate = moment().format(DATE_FORMATS.DEFAULT_DATE);
    const endDate = moment().add(2, "years").format(DATE_FORMATS.DEFAULT_DATE);
    const newMapping = [{
      startDate,
      endDate,
      visible: true,
      isActive: true,
      roomTypeID,
    }];

    console.log("MAPPING AFTER: ", newMapping);

    return props.dispatch(
      editRoom(roomID, {
        newMapping
      })
    );
  };

  React.useEffect(() => {
    console.log(
      "props.isCreateLoading",
      props.isCreateLoading,
      props.isDeleteLoading
    );
    if (props.isCreateLoading === false || props.isDeleteLoading === false) {
      console.log("isCreateLoading", props.isCreateLoading);
      props.dispatch(
        getCalendarRenderData(currentSpace.propertyID, filterType)
      );
    }
  }, [props.isCreateLoading, props.isDeleteLoading]);

  return (
    <div className={classes.root}>
      {/* ------------------------------------------------ */}
      {/* HEADER */}
      {/* ------------------------------------------------ */}
      <div className={classes.dialogTitle}>
        <div className={classes.titleBox}>
          <div className={classes.header}>
            <Typography component="h4" variant="h6">
              {t("beds.roomTypesAndRooms.roomInventory")}
            </Typography>
          </div>
        </div>
        <div className={classes.permissions}>
          <AccessHeaderAction moduleID={MODULE.ROOMS.ID} />
        </div>
      </div>

      <div className={classes.listContainer}>
        <div className={classes.listSection}>
          {/* ------------------------------------------------ */}
          {/* LOADING STATE - Rooms / Room Types*/}
          {/* ------------------------------------------------ */}
          {loadingRoomTypes && (
            <div className={classes.blankSlate}>
              <CircularProgress />
            </div>
          )}

          {!loadingRoomTypes &&
            currentSpace.rooms &&
            currentSpace.rooms.length > 0 && (
              <div className={classes.addButtonContainer}>
                <div
                  className={classes.addButton}
                  data-intercom-target="New Room"
                >
                  <AddButton
                    color="#006699"
                    title={t("beds.roomTypesAndRooms.addRoom")}
                    handleClick={handleOpen}
                  />
                </div>
              </div>
            )}

          {/* ------------------------------------------------ */}
          {/* BLANK STATE - No Rooms */}
          {/* ------------------------------------------------ */}
          {!loadingRoomTypes &&
            currentSpace &&
            (!currentSpace.rooms || currentSpace.rooms.length === 0) && (
              <div className={classes.blankSlate}>
                <div className={classes.blankText}>
                  Create and setup your rooms and room types
                </div>
                <div
                  className={classes.addButton}
                  data-intercom-target="New Room"
                >
                  <AddButton
                    color="#006699"
                    title={t("beds.roomTypesAndRooms.addRoom")}
                    handleClick={handleOpen}
                  />
                </div>
              </div>
            )}

          {/* ------------------------------------------------ */}
          {/* Loop through rooms */}
          {/* ------------------------------------------------ */}
          <Grid container spacing={3}>
            {!loadingRoomTypes &&
              currentSpace &&
              currentSpace.rooms &&
              currentSpace.rooms.sort(sortAlphaNum).map((roomID) => {
                return (
                  <Grid key={roomID} item xs={12} lg={6}>
                    {rooms[roomID] && (
                      <RoomTile
                        roomID={roomID}
                        room={rooms[roomID]}
                        roomType={
                          rooms[roomID]?.primaryRoomTypeID
                            ? roomTypes[rooms[roomID]?.primaryRoomTypeID]
                            : {}
                        }
                        isLoading={props.isEditLoading}
                        handleExtendAvailability={() =>
                          handleExtendAvailability({
                            room: rooms[roomID],
                            roomID,
                          })
                        }
                        handleEdit={() => handleSetRoomID(roomID)}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>

      {createNewList && (
        <NewRoom
          open={createNewList}
          handleCancel={handleCancel}
          handleSubmit={handleSubmitNewRoom}
        />
      )}

      {roomID && (
        <NewRoom
          roomID={roomID}
          room={rooms[roomID]}
          menuId={activeRoomConfig?.menuId}
          open={Boolean(roomID)}
          handleCancel={() => setActiveRoomConfig(false)}
          handleSubmit={handleEditRoom}
        />
      )}

      {/* ------------- Snackbar error messages -------------- */}
      {props.deleteError && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => handleCloseError()}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.deleteError}</span>}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  rooms: state.rooms,
  roomTypes: state.roomTypes,
  loadingRoomTypes: state.loading.LOAD_ROOMTYPES,
  deleteError: state.errors.DELETE_ROOM,
  isCreateLoading: state.loading.CREATE_ROOM,
  isDeleteLoading: state.loading.DELETE_ROOM,
  isEditLoading: state.loading.EDIT_ROOM
});

export default withTranslation()(connect(mapStateToProps)(RoomList));
