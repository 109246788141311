import http from "../../utils/http";
import { normalize } from 'normalizr';

export const fetchPacePropertyMapID = (propertyID) => {
    const ACTION_NAME = "FETCH_PACE_PROPERTY_MAP_ID";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/pace/propertyMap/${propertyID}`)
            .then(successResult => {
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        status: status,
                        propertyID: successResult.data.propertyID
                    }
                });

                return true;
            })
            .catch(errorResult => {
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const addPacePropertyMapID = (propertyID, params) => {
    const ACTION_NAME = "ADD_PACE_PROPERTY_MAP_ID";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/pace/propertyMap/`, params)
            .then(successResult => {
                console.log('successResult ',successResult);
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        status: status,
                        propertyID: successResult.data.propertyID
                    }
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}