import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import clsx from 'clsx';
import _ from 'lodash';

import { fetchDateFormat, DATE_FORMATS } from "../../../utils/utility";
import { toTitleCase } from '../../utils/wordUtils';
import { returnCurrent } from '../../../redux/selectors/dashboard';
import { loadEmailTemplates, sendEmail } from '../../../redux/actions/emails/emails';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative'
  },
  notesContainer: {
    flexGrow: 1,
    marginTop: '10px',
    overflowY: 'auto',
    width: '100%',
    paddingRight: '25px',
    boxSizing: 'content-box',
  },
  noteContainer: {
    background: '#FFF',
    border: "none",
    borderRadius: "15px",
    marginBottom: 10,
    padding: theme.spacing(2),
  },
  chip: {
    alignItems: "center",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  smallText: {
    fontSize: "1.1rem",
    paddingRight: 5,
    fontWeight: 600
  },
  highlightedText: {
    color: '#EABF87',
    cursor: 'pointer',
    fontWeight: 600,
  },
  lightText: {
    color: "#828282",
    fontWeight: 400,
  },
  subject: {
    cursor: 'pointer',
    display: 'block',
    color: '#999999',
    fontSize: '1.4rem',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    width: '100%',
    wordWrap: 'break-word',
  },
  note: {
    display: 'block',
    fontSize: '1.4rem',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    width: '100%',
    wordWrap: 'break-word',
  },
  noEmails: {
    paddingTop: theme.spacing(1),
    color: '#999999',
    fontSize: '1.4rem',
    overflow: 'auto',
    width: '100%',
    wordWrap: 'break-word',
  },
  newNoteContainer: {
    width: '100%',
    marginBottom: 30,
  },
  newNote: {
    alignItems: ' center',
    display: 'flex',
  },
  formBox: {
    alignItems: "center",
    borderRadius: "8px",
    color: "#828282",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 10,
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: '100%',
    "& .MuiOutlinedInput-multiline": {
      paddingTop: 10,
      paddingBottom: 10
    },
    "& textarea": {
      backgroundColor: "#FFFFFF",
      fontSize: '1.4rem'
    },
    "& .spanButton": {
      fontSize: "16px",
      cursor: "pointer",
      padding: "0 6px 0 18px"
    }
  },
  dropdown: {
    border: 'none',
    fontSize: '1.4rem'
  },
  selectTemplate: {
    width: '80%'
  },
  send: {
    background: '#333333',
    borderRadius: '5px',
    color: '#ffffff',
    '&:hover': {
      background: '#333333'
    }
  },
  TextField: {
    marginBottom: 5
  },
  iconTextSection: {
    display: 'flex',
    paddingTop: 5,
    '& img': {
      width: 12,
      height: 12,
      marginRight: 5,
      padding: 5,
      paddingLeft: 0,
    },
    '& p':{
      paddingTop: 0,
      width: 60,
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const EmailsTab = (props) => {
  const { t } = props

  const classes = useStyles();
  const { currentSpace, emails, emailTemplates, reservationID, reservationInfo: { guestName = '', guestComments = [] } } = props;
  const [selectedEmail, setSelectedEmail] = useState(null);

  let emailType = []
  if (!_.isEmpty(emailTemplates) && emailTemplates.default !== true) {
    emailType = []
    Object.entries(emailTemplates).map(item => {
      if (item?.[1]?.isActive)
        emailType.push({ label: item[1].name, value: item[0] })
    })
  } else {
    emailType = [{ label: t('existingReservation.emailTab.noEmailSetup'), value: null }]
  }

  useEffect(() => {
    if (emailTemplates.default === true) {
      emailType = [{ label: t('existingReservation.emailTab.loading'), value: null }]
      props.dispatch(loadEmailTemplates(currentSpace.propertyID));
    }
  }, []);

  const handleChange = selectedOption => {
    setSelectedEmail(selectedOption);
  };

  const emailView = emails.map((item, index) => (
    item ?
      <Grid item xs={12} key={index} className={classes.noteContainer}>
        <div className={classes.chip}>
          {/* <Typography component="span" className={clsx(classes.smallText, classes.highlightedText)}>
            {item.from}
          </Typography> */}
          <Typography
            component="span"
            className={clsx(classes.lightText, classes.smallText)}
          >
            {fetchDateFormat(item.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, currentSpace.timezone)}
          </Typography>
        </div>
        <Typography className={classes.subject} component="p"><span className={classes.highlightedText}>To: </span> {item.to}</Typography>
        <Typography className={classes.subject} component="p"><span className={classes.highlightedText}>From: </span> {item.from}</Typography>
        <Typography className={classes.subject} component="p"><span className={classes.highlightedText}>Subject: </span> {item.subject}</Typography>
        {item.deliveryStatus && <Typography className={classes.subject} component="p"><span className={classes.highlightedText}>Delivery status: </span> <span className={classes.capitalize}>{item.deliveryStatus}</span></Typography>}
        {item.deliveryStatus === 'delivered' &&
          <div className={classes.iconTextSection}>
            {item.openCount > 0 &&
              <><img src={IMAGES.ICONS.check} /> <Typography className={classes.subject} component="p">Opened</Typography></>
            }
            {item.clickCount > 0 &&
              <><img src={IMAGES.ICONS.check} /> <Typography className={classes.subject} component="p">Clicked</Typography></>
            }
          </div>
        }
      </Grid> : null
  ));

  const noEmails = (
    <Grid item xs={12} className={classes.noteContainer}>
      <Typography className={clsx(classes.lightText, classes.noEmails)} component="p">
        {t('existingReservation.emailTab.noEmail')}
      </Typography>
    </Grid>
  );

  const noEmailID = (
    <Grid item xs={12} className={classes.noteContainer}>
      <Typography className={clsx(classes.lightText, classes.noEmails)} component="p">
        {t('existingReservation.emailTab.noEmailID')}
      </Typography>
    </Grid>
  );

  const guestCommentsView = guestComments.map(commentInfo => (
    <Grid item xs={12} key={commentInfo?._id} className={classes.noteContainer}>
      <div className={classes.chip}>
        <Typography component="span" className={clsx(classes.smallText, classes.highlightedText)}>
          {guestName && toTitleCase(guestName)}
        </Typography>
        <Typography
          component="span"
          className={clsx(classes.lightText, classes.smallText)}
        >
          (Guest Comment)
        </Typography>
      </div>
      <Typography className={classes.subject} component="p">{commentInfo?.comment}</Typography>
    </Grid>
  ))

  const handleSendEmail = () => {
    // console.log('send email', selectedEmail.value, reservationID);
    props.dispatch(sendEmail(reservationID, selectedEmail.value));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'manually send email', 'eventLabel': 'Send Manual Email' });
  }

  return (
    <div className={classes.root}>
      <div className={classes.notesContainer}>

        {props.emailID &&
          <div className={classes.newNoteContainer}>
            <div className={classes.formBox}>
              <div className={clsx(classes.selectTemplate, 'select-up')}>
                <Select
                  className={classes.dropdown}
                  value={selectedEmail}
                  onChange={handleChange}
                  options={emailType}
                  isSearchable={true}
                  isClearable={true}
                  menuPlacement="auto"
                  placeholder={t('existingReservation.emailTab.selectEmailType')}
                  styles={selectStyle}
                  theme={theme => ({
                    ...theme,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>
              {selectedEmail && <div>
                <Button
                  className={classes.send}
                  fullWidth
                  variant="outlined"
                  onClick={() => handleSendEmail()}
                >
                  {t('existingReservation.emailTab.sendEmail')}
                </Button>
              </div>}
            </div>
          </div>
        }

        <Grid container>
          {guestComments && guestComments.length !== 0 && guestCommentsView}
          {props.emailID && Object.keys(emails).length !== 0 && emailView}
          {props.emailID && Object.keys(emails).length === 0 && noEmails}
          {!props.emailID && noEmailID}
        </Grid>
      </div>


    </div>
  );
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  emailTemplates: state.emailTemplates,
  emails: state.reservationDetails.emails,
  reservationInfo: state.reservationDetails?.reservation || {},
  reservationID: state.reservationDetails.reservation._id
});

export default withTranslation()(connect(mapStateToProps)(EmailsTab));
