/*eslint no-undefined: "error"*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

import EditableField from "../common/EditableField";
import IMAGES from "../../constants/images";
import { editProperty } from "../../redux/actions/dashboard/property";

const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    width: "100%",
    "&.edit $container": {
      borderBottom: "unset",
    },
    "& .editIcon": {
      position: "absolute",
      top: "10px",
      right: 0,
    },
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: 20,
  },
  editIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#dddddd",
    },
  },
  topMargin: {
    marginTop: 25,
  },
  information: {
    margin: "25px 0",
  },
  testLoading: {
    alignItems: "center",
    display: "flex",
    height: 100,
    justifyContent: "center",
    width: "100%",
  },
  fieldContainer: {
    alignItems: "center",
    display: "flex",
  },
  emailLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  emailRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  settingText: {
    fontSize: "1.3rem",
    fontWeight: 600,
    lineHeight: 1.3,
    marginLeft: "5px",
    alignSelf: "flex-start",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
  },
  infoRow: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: 10,
    display: "flex",
    padding: 30,
    width: "calc(100% - 60px)",
  },
  bulb: {
    width: 40,
    height: 40,
  },
  buttonsContainer: {
    display: "flex",
    marginTop: "20px",
    gap: "20px",
    alignSelf: "end",
  },
  helperText: {
    color: "#999999",
    fontSize: "1.2rem",
    lineHeight: 1.4,
    marginLeft: 20,
  },
}));

const BusinessIdentifiers = (props) => {
  const { dispatch, property, loadingBusinessIdentifiers, t } = props;
  const translationRoot = "account.businessIdentifiers";
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [editFlag, setEditFlag] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleEdit = () => setEditFlag(true);

  const handleCancelUpdates = () => {
    setEditFlag(false);
  };

  const handleSubmitUpdates = () => {
    dispatch(editProperty(property._id, formData));
    handleCancelUpdates();
  };

  return (
    <div className={classes.header}>
      {loadingBusinessIdentifiers && (
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      )}
      <div className={classes.pageContainer}>
        <div className={classes.settingText}>
          {t(`${translationRoot}.title`)}
        </div>
        <div className={clsx(classes.infoRow, classes.topMargin)}>
          <div>
            <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
          </div>
          <div className={classes.helperText}>
            <p>{t(`${translationRoot}.introText1`)}</p>
            <p>{t(`${translationRoot}.introText2`)}</p>
          </div>
        </div>
        <div className={clsx(classes.form, { edit: editFlag })}>
          <div className={classes.errorMessage}>{error}</div>

          {!editFlag && (
            <div onClick={handleEdit}>
              <EditIcon className={classes.editIcon} />
            </div>
          )}

          <EditableField
            isEdit={editFlag}
            formData={formData}
            setFormData={setFormData}
            onEdit={handleEdit}
            label={t("fields.vatNumber.label")}
            helperText={t("fields.vatNumber.helperText")}
            name="vatNumber"
          />

          <EditableField
            isEdit={editFlag}
            formData={formData}
            setFormData={setFormData}
            onEdit={handleEdit}
            name="businessRegistrationNumber"
            label={t("fields.businessRegistrationNumber.label")}
            helperText={t("fields.businessRegistrationNumber.helperText")}
          />
          <EditableField
            isEdit={editFlag}
            formData={formData}
            setFormData={setFormData}
            onEdit={handleEdit}
            label={t("fields.tinNumber.label")}
            helperText={t("fields.tinNumber.helperText")}
            name="tinNumber"
          />

          {/* --------------------------------------------------------------------- */}
          {/* SUBMIT / CANCEL UPDATES */}
          {/* --------------------------------------------------------------------- */}
          {editFlag && (
            <div className={classes.buttonsContainer}>
              <Button
                size="small"
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleCancelUpdates}
              >
                {t("actions.cancel")}
              </Button>

              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={handleSubmitUpdates}
              >
                {t("actions.save")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  property: state.property,
  loadingBusinessIdentifiers: state.loading?.BUSINESS_IDENTIFIERS,
  addBusinessIdentifiersLoading: state.loading?.ADD_BUSINESS_IDENTIFIIERS,
  settings: state.settings,
});

export default withTranslation()(connect(mapStateToProps)(BusinessIdentifiers));
