import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from "moment";

import { initials } from '../utils/wordUtils';
import { markMessageRead } from "../../redux/actions/messages/messages";
import { setCurrentChat } from "../../redux/actions/dashboard/dashboard";

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  notificationContainer: {
    
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: '1px solid #dddddd',
    color: "#ffffff",
    lineHeight: 2,
    textAlign: 'center',
  },
  header: {
    
  },
  inline: {
    fontSize: '1.3rem',
    display: 'inline',
  },
  dot: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    cursor: 'pointer',
    height: 9,
    margin: 5,
    padding: 'none',
    width: 9
  }
}));

const Notification = (props) => {
  const classes = useStyles();

  const {users, messages, message } = props;
  const lastMessage = messages[message].lastMessage;

  const handleToggleRead = () => {
    console.log('mark read')
  }

  const handleMessageClick = () => {
    props.handleMsgClose();
    props.dispatch(markMessageRead(message));
    props.dispatch(setCurrentChat(messages[message].chatRoomID));
  }

  return (
    <ListItem 
      button 
      onClick={handleMessageClick}
      alignItems="flex-start" 
      className={classes.notificationContainer}
    >
      <ListItemAvatar>
        <Avatar
          alt={initials(users && users[lastMessage.senderID].name)}
          src={users && users[lastMessage.senderID].avatar} 
          title='Test Message' 
          className={classes.avatar}
        >
          {initials(users && users[lastMessage.senderID].name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component="span"
            variant="h6"
            className={classes.header}
            color="textPrimary"
          >
            {JSON.parse(lastMessage.message)}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
            >
              {users && users[lastMessage.senderID].name}
            </Typography>
            <span style={{ marginLeft: 5, marginRight: 5 }}>&#xb7;</span>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
            >
              {moment(lastMessage.createdAt).isSame(moment(), "day") ? 
                moment(lastMessage.createdAt).format('h:mm a') : 
                moment(lastMessage.createdAt).format('MMM DD')
              }
            </Typography>
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <div 
          onClick={handleToggleRead} 
          className={classes.dot} 
          style={
            false ? 
            {backgroundColor: '#888888'} : 
            {backgroundColor: '#7E1541'}
          }></div>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const mapStateToProps = state => ({
  users: state.users,
  messages: state.messages
})

export default connect(mapStateToProps)(Notification);