import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import { fetchCurrencyFormat, amountCommaSeperator } from '../../../utils/utility';

const formatPayoutCurrency = (currency, amount) =>{
    const parsedAmount = parseInt(amount);
    const isNegative = parseInt(parsedAmount) < 0 && parseInt(parsedAmount) != 0 ? true : false;
    const absoluteAmount = Math.abs(amount);

    let formatedAmount = amountCommaSeperator(absoluteAmount);
    formatedAmount = `${currency}${formatedAmount}`;
    formatedAmount = isNegative ? `-${formatedAmount}` :  formatedAmount;
    return formatedAmount;
}

// Process payouts data to display in report table
// ------------------------------------------------------------------------
const payoutProcessing = (payoutData, propertyCurrency) => {
    let dataFormat = [];

    payoutData && payoutData.length !== 0 && payoutData.map(item => {

        let formattedTransaction = [];

        item.transactions && item.transactions.map(transaction => {

            formattedTransaction.push({
                date: moment(transaction.date).format('DD MMM YYYY h:mm a'),
                guestName: transaction.guestName,
                gross: formatPayoutCurrency(propertyCurrency, transaction.gross),
                fee: formatPayoutCurrency(propertyCurrency, transaction.fee),
                total: formatPayoutCurrency(propertyCurrency, transaction.total),
                reservationID: transaction.reservationID,
                description: transaction.description
            });
        });


        const payoutCurrency = fetchCurrencyFormat(item.currency ? item.currency : null);

        dataFormat.push({
            id: item.id,
            createdDate: moment(item.createdDate).format('DD MMM YYYY h:mm a'),
            arrivalDate: moment(item.arrivalDate).format('DD MMM YYYY h:mm a'),
            gross: formatPayoutCurrency(payoutCurrency, item.gross),
            feeTotal: formatPayoutCurrency(payoutCurrency, item.feeTotal),
            total: formatPayoutCurrency(payoutCurrency, item.total),
            status: item.status,
            transactions: [...formattedTransaction]
        });
    });
    return dataFormat;
}

export { payoutProcessing };