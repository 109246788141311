import React, { useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import moment from "moment-timezone";

import { DATE_FORMATS, fetchDateFormat, fetchDatePerTimezone } from "../../../utils/utility";

const useStyles = makeStyles(theme => ({
    root:{
        display: 'flex',
        // width: '70%',
        height: '80px',
        padding: '0px 15px 0px 25px',
        alignItems: 'center',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
        position: 'relative',
        '&.isActive':{
            // maxWidth: '125px',
            backgroundColor: '#F8F9FB',
            boxShadow: '0 2px 2px #F0F0F0',
            filter: 'drop-shadow(0 2px 2px #F0F0F0)',
            marginRight: '25px'
        },
        '&.isActive $dateContainer .month':{
            color: '#CC6633'
        },
        '&.isActive $dateContainer .day':{
            color: '#CC6633'
        },
        '&.isActive:after':{
            content:"''",
            width: 0,
            height: 0,
            borderTop: '40px solid transparent',
            borderLeft: '25px solid #F8F9FB',
            borderBottom: '40px solid transparent',
            position: 'absolute',
            left: '100%',
            zIndex: -1
        },
    },
    dateContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .month':{
            fontSize: '1.2rem',
            fontWeight: 600,
            color: '#bdbdbd',
        },
        '& .day':{
            fontSize: '1.4rem',
            fontWeight: 600,
            marginTop: '5px',
        },
    },
    isCurrentDate:{
        '&:after': {
          content:'""',
          width: 5,
          height: 5,
          borderRadius: '50%',
          background: '#b83b3b',
          display: 'inline-block'
        }
      },
    activityCount:{
        color: '#4f4f4f',
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    divider:{
        backgroundColor: '#E0E0E0',
        height: '40px',
        width: '1px',
        margin: '0px 25px',
    },
    weekDivider:{
        width: '70%',
        borderBottom: '1px solid #ececec',
        marginBottom: '40px',
    }
}));

const CalendarDateCard = props =>{
    const { dayInfo, isActive, onClickHandler, activityCount, timezone, t } = props;
    const classes = useStyles();
    const momentObj = moment(dayInfo);
    const day = momentObj.format('ddd');
    const date = momentObj.format('D');
    const dayNumber = momentObj.get('isoWeekday');
    const isWeekend = dayNumber === 7;
    const currentDate = fetchDatePerTimezone(null, timezone)

    const ref = useRef(null);

    useEffect(() =>{
        if(isActive) ref.current.scrollIntoView({behavior: "smooth", block: "end" });
    }, [dayInfo]);

    const selectDateHandler = () =>{
        const seletedDate = momentObj.format(DATE_FORMATS.DEFAULT_DATE);
        onClickHandler(seletedDate);
    }
    
    return(
        <>
            <div 
                ref={isActive ? ref : null} 
                className={clsx(classes.root, { isActive })}
                onClick = {selectDateHandler}
            >
                <div className={clsx(
                    classes.dateContainer,
                    {
                        [classes.isCurrentDate]: fetchDateFormat(currentDate, DATE_FORMATS.DEFAULT_DATE) === fetchDateFormat(momentObj, DATE_FORMATS.DEFAULT_DATE),
                    }
                )}>
                    <div className="month">{day}</div>
                    <div className="day">{date}</div>
                </div>
                <div className={classes.divider}></div>
                <div className={classes.activityCount}>
                    {activityCount} {t('experiences.calendar.activities')}
                </div>
            </div>

            { isWeekend && <div className={classes.weekDivider} /> }
        </>
    )
};

export default withTranslation()(CalendarDateCard);