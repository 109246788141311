import http from "../../utils/http";
import { normalize } from "normalizr";
import { ratePlanMappingSchema } from "../../schema/mappingSchema";
import { CHANNEL } from "../../../utils/channels";

//----------------------------------------------------------------
// FETCH CHANNEL RATE PLAN LIST
//----------------------------------------------------------------
export function fetchChannelRatePlans(propertyID, channelType) {
  const ACTION_NAME = "FETCH_CHANNEL_RATEPLANS";

  if(!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.fetchChannelRatePlans)){
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: {error: 'API path not found'} });
      return;
    }
  }

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(CHANNEL[channelType].API.fetchChannelRatePlans + propertyID)
      .then(successResult => {
        const data = successResult.data || {};
        console.log('GET /api/hostelworld/ratePlans/', data);

        let ratePlansNormalised = normalize(data, [ratePlanMappingSchema])
        // console.log('normalize', ratePlansNormalised.entities.ratePlanMapping);
        dispatch({type: `${ACTION_NAME}_SUCCESS`, payload: ratePlansNormalised.entities.ratePlanMapping})

        if (!ratePlansNormalised.entities.ratePlanMapping) {
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: 'No channels found' } });
        }
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// ADD RATE PLAN MAPPING
//----------------------------------------------------------------
export function addRatePlanMapping(ratePlanID, channelType, payload) {
  const ACTION_NAME = "ADD_CHANNEL_RATEPLAN_MAPPING";
  
  if(!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.createRatePlanMapping)){
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: {error: 'API path not found'} });
      return;
    }
  }

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch(CHANNEL[channelType].API.createRatePlanMapping + ratePlanID, {...payload})
      .then(successResult => {
        const data = successResult.data;
        console.log('add rate plan mapping', data);
        dispatch({type: `${ACTION_NAME}_SUCCESS`, payload: data})
        return true
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  }

}

//----------------------------------------------------------------
// DELETE RATE PLAN MAPPING
//----------------------------------------------------------------
export function deleteRatePlanMapping(ratePlanID, channelType, payload) {
  const ACTION_NAME = "DELETE_CHANNEL_RATEPLAN_MAPPING";
  
  if(!(CHANNEL[channelType] && CHANNEL[channelType].API && CHANNEL[channelType].API.deleteRatePlanMapping)){
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: {error: 'API path not found'} });
      return;
    }
  }

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch(CHANNEL[channelType].API.deleteRatePlanMapping + ratePlanID, {...payload})
      .then(successResult => {
        const data = successResult.data;
        console.log('delete rate plan mapping', data);
        dispatch({type: `${ACTION_NAME}_SUCCESS`, payload: data})
        return true
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  }

}