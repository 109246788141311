import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputBase,
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { TRANSACTION_MODE } from "../../../utils/constants";
import { createReservationPayment } from "../../../redux/actions/reservation/editReservation";
import NumberInput from "../../common/NumberInput";

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    backgroundColor: '#fff'
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#f8f9fb',
    justifyContent: 'center',
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
    padding:'40px 20px 0px 20px',
    marginBottom: '10px',
    borderBottom: '1px solid #E3E3E3',
  },
  title: {
    display: 'flex',
    '& span': {
      marginRight: 20
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 15,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20
  },
  formControl: {
    padding: "10px 0px",
  },
  radioLabel: {
    color: '#666666',
    padding: "10px",
    borderRadius: 4,
    backgroundColor: "#f8f9fb",
    fontSize: '1.4rem',
    marginLeft: 0,
    '& span': {
      fontSize: '1.4rem',
    },
    "&.selected-radio": {
      backgroundColor: "#E0E0E0;",
      color: "#FFFFFF"
    }
  },
  radioButton: {
    display: "none"
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
    color: '#333333',
    marginTop: 10
  },
  field: {
    background: '#f8f9fb',
    borderRadius: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .css-6q0nyr-Svg': {
      color: '#000000',
      width: 30,
      height: 30,
    }
  },
  submit: {
    color: '#ffffff',
    background: '#6ea7d6',
    fontSize: '1.2rem',
    border: '0',
    '&:hover': {
      backgroundColor: '#6ea7d6',
      border: 0,
    }
  },
  dialogAction: {
    padding: 24,
    textAlign: 'right'
  },

  dialogContent: {
    // height: 'calc(100vh - 315px)',
    minHeight: '200px'
  },

  tabContainer:{
    width: '100%',
    height: '35px',
    display: 'flex',

    '& .tab':{
      display: 'flex',
      fontSize: '1.6rem',
      alignItems: 'center',
      fontWeight: 600,
      width: '50%',
      padding: '0px 20px',
      paddingBottom: '5px',
      marginBottom: '-3px',
      cursor: 'pointer',
    },

    '& .tab.active':{
      borderBottom: '5px solid #6ea7d6',
      paddingBottom: '0px',
    }
  }
}));

const TABS = {
  discount: 'discount',
  sucharge: 'surcharge'
}

const DiscountForm = (props) => {
  const { totalAmount, transactionModes = {}, propertyID, reservationID, t } = props;
  const [discount, setDiscount] = React.useState('cash');
  const [activeTab, setActiveTab] = React.useState(TABS.discount)
  const [amount, setAmount] = React.useState(0);
  const [percent, setPercent] = React.useState(0);
  const classes = useStyles();

  const handleTypeChange = (e) => {
    setDiscount(e.target.value)
    setAmount(0)
    setPercent(0)
  }
  const handleAmountChange = (e) => {
    let value = e.target.value;
    value = value < 0 ? Math.abs(value) : value;
    setAmount(value)
  }
  const handlePercentChange = (e) => {
    if(!e.target.value){
      setPercent(0);
    }
    if (parseFloat(e.target.value) <= 100){
      setPercent(e.target.value);
      setAmount(parseFloat(parseFloat(totalAmount) * parseFloat(e.target.value) / 100).toFixed(2));
    }
  }
  const handleSubmit = () => {

    const isDiscountTabActive = activeTab ==  TABS.discount;
    const isSurchargeTabActive = activeTab ==  TABS.sucharge;

    const activeMode =  isDiscountTabActive ? TRANSACTION_MODE.DISCOUNT : TRANSACTION_MODE.SURCHARGE;

    const modeID = Object.keys(transactionModes).find(id =>
      transactionModes[id].name === activeMode &&
      transactionModes[id].propertyID === propertyID
    );

    let formattedAmount = parseFloat(amount);

    if(isSurchargeTabActive) formattedAmount = formattedAmount * -1; 

    let payload = {
      amount: formattedAmount,
      modeID
    }

    props.dispatch(createReservationPayment(reservationID, payload, {}));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    if(isSurchargeTabActive) {
      window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add surcharge', 
      'eventLabel': 'Add Surcharge', 'eventValue': amount });
    } else {
      window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add discount', 
      'eventLabel': 'Add Discount', 'eventValue': amount});
    }
    
    props.handleClose();
  }

  const tabChangeHandler = tab =>{
    setActiveTab(tab);
    setDiscount('cash');
    setAmount(0);
    setPercent(0);
  }

  const type = [
    { 
      value: 'cash', 
      label: t('existingReservation.summaryTab.amount') 
    },
    { 
      value: 'percent', 
      label: t('existingReservation.summaryTab.percent') 
    }
  ];

  const discountTypeView = (
    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          row
          className={classes.radioGroup}
          aria-label="cashFlow"
          name="cashFlow"
          value={discount}
          onChange={(e) => handleTypeChange(e)}
        >
          {type.map(option => {
            return (
              <FormControlLabel
                className={`${classes.radioLabel} ${discount === option.value ? "selected-radio" : ""}`}
                key={option.value}
                value={option.value}
                control={
                  <Radio color="primary" className={classes.radioButton} />
                }
                label={option.label}
                labelPlacement="end"
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  )

  const amountView = (discount === "cash" ?
    <Grid item xs={12}>
      <div className={classes.label}>{t('existingReservation.summaryTab.amount')}</div>
      <div className={classes.field}>
        <NumberInput min={0} value={amount || ''} id="amount" onChange={handleAmountChange} />
      </div>
    </Grid> : null
  );

  const percentView = (discount === "percent" ?
    <Grid item xs={12}>
      <div className={classes.label}>{t('existingReservation.summaryTab.percent')}</div>
      <div className={classes.field}>
        <NumberInput min={0} value={percent || ''} id="percent" onChange={handlePercentChange} />
      </div>
    </Grid> : null
  );

  const buttonView =
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!(discount && amount > 0)}
        onClick={handleSubmit}
      >{t('actions.save')}</Button>
    </Grid>;

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.root}>
        <div className={classes.dialogTitle}>
          {/* ----------------TAB SELECTION----------------- */}
          <div className={classes.tabContainer}>
            <div 
              className={clsx("tab", { active: activeTab ==  TABS.discount })}
              onClick={() =>tabChangeHandler(TABS.discount)}
            >
              Discount
            </div>
            <div 
              className={clsx("tab", { active: activeTab ==  TABS.sucharge })}
              onClick={() =>tabChangeHandler(TABS.sucharge)}
            >
              Surcharge
            </div>
          </div>
          <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        </div>

        <DialogContent className={classes.dialogContent}>

          <Grid container spacing={2}>
            {discountTypeView}
            {amountView}
            {percentView}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          {buttonView}
        </DialogActions>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    reservationID: state.reservationDetails.reservation._id,
  }
};
export default withTranslation()(connect(mapStateToProps)(DiscountForm));