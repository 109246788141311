import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import {
  fetchCurrencyFormat,
  convertArrayToCSV,
  generateCSV,
} from "../../utils/utility";
import { returnCurrent } from "../../redux/selectors/dashboard";
import {
  loadBookingMetrics,
  loadAvailableUnits,
  resetBookingMetrics,
} from "../../redux/actions/reports/bookingMetrics";
import {
  loadRevenue,
  resetRevenueReport,
} from "../../redux/actions/reports/revenue";
import {
  loadRevOccupancy,
  resetOccupancyReport,
} from "../../redux/actions/reports/occupancy";

import {
  bookingMetricGraphProcessing,
  bookingMetricTableProccessing,
} from "../../redux/selectors/reports/bookingMetrics";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GroupedBar from "../charts/GroupedBar";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { exportExcel } from "../utils/excelExport";
import { fetchCurrencySymbol } from "../../utils/utility";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "&:hover": {
      background: "#eeeeee",
    },
  },
  icon: {
    fontSize: "5rem",
  },
  downloadButton: {
    cursor: "pointer",
    height: 25,
    width: 25,
    left: 24,
    opacity: 0.7,
    position: "absolute",
    top: 28,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "46px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  graph: {
    marginTop: 30,
    marginBottom: 30,
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "200px",
    width: "100%",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  countryHeader: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 70,
    textAlign: "center",
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const selectStyle2 = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    fontWeight: 500,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const reportTypes = [
  { value: "revpar", label: "RevPAR - Revenue per Available Room" },
  { value: "revpab", label: "RevPAB - Revenue Per Available Bed" },
  { value: "adr-room", label: "ADR - Average Daily Rate by Room" },
  { value: "adr-bed", label: "ADR - Average Daily Rate by Bed" },
];

const allReportSettings = {
  revpar: {
    api: "revPAUnit",
    metric: "revPAU",
    unit: "room",
    label: "revPAR",
  },
  revpab: {
    api: "revPAUnit",
    metric: "revPAU",
    unit: "bed",
    label: "revPAB",
  },
  "adr-room": {
    api: "adr",
    metric: "adr",
    unit: "room",
    label: "ADR-room",
  },
  "adr-bed": {
    api: "adr",
    metric: "adr",
    unit: "bed",
    label: "ADR-bed",
  },
};

const BasicExport = (props) => {
  const { t, currencyData, reportSettings, daily } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.bookingMetrics");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = ["string", "currency", "percentage"];
  let excelColWidth = [10, 20, 20];

  let columnsArray = [
    { title: "Date", field: "date", align: "center" },
    {
      title: `Revenue (${currencyData.code})`,
      field: "revenue",
      align: "center",
    },
    { title: "Occupancy", field: "occupancy", align: "center" },
  ];

  reportSettings.map((settingsItem) => {
    columnsArray.push({
      title: `${settingsItem.label} (${currencyData.code})`,
      field: settingsItem.label,
      align: "center",
    });
    excelHeaderType.push("currency");
    excelColWidth.push(20);
  });

  if (daily) {
    const appendArray = [
      { title: "No. of Units Sold", field: "unitsSold", align: "center" },
      {
        title: "No. of Available Units",
        field: "availableUnits",
        align: "center",
      },
    ];
    columnsArray = [...columnsArray, ...appendArray];
    excelHeaderType = [...excelHeaderType, "wholeNumber", "wholeNumber"];
    excelColWidth = [...excelColWidth, 20, 20];
  }

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={columnsArray}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        search: false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        sorting: true,
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth,
            currencyData
          );
        },
      }}
    />
  );
};

const BookingMetrics = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    loadingMetrics,
    loadingAvailableUnits,
    roomTypes,
  } = props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[2]);
  const [level, setLevel] = React.useState("daily");
  const [report, setReport] = React.useState(["revpar"]);
  const [reportSettings, setReportSettings] = React.useState([
    {
      api: "revPAUnit",
      metric: "revPAU",
      unit: "room",
      label: "revPAR",
    },
  ]);
  const [roomTypeFilter, setRoomTypeFilter] = React.useState([]);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };
  const [goFullScreen, setGoFullscreen] = React.useState(false);

  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );

  // ROOM TYPE ARRAY
  const roomTypeArray = [];
  currentSpace.roomTypes &&
    currentSpace.roomTypes.forEach((roomTypeID) => {
      if (!roomTypes[roomTypeID].isActive) return;
      roomTypeArray.push({
        value: roomTypeID,
        label: roomTypes[roomTypeID].name,
      });
    });

  // SELECTOR - PROCESS REPORT DATA FOR CHART
  // ----------------------------------------------------------------------
  const { barLabels, barData } = useSelector((state) =>
    bookingMetricGraphProcessing(
      state.reports.bookingMetrics,
      submittedDates,
      reportSettings,
      level
    )
  );
  const tableData = useSelector((state) =>
    bookingMetricTableProccessing(
      state.reports.availableUnits,
      state.reports.occupancy,
      state.reports.revenue,
      state.reports.bookingMetrics,
      currencyData,
      submittedDates,
      reportSettings,
      level
    )
  );

  // CLEANUP - DELETE REPORT DATA
  // ----------------------------------------------------------------------
  React.useEffect(() => {
    props.dispatch(
      loadRevenue(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        "daily"
      )
    );
    props.dispatch(
      loadBookingMetrics(
        currentSpace.propertyID,
        moment().subtract(15, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        "daily",
        { metric: "revPAU", unit: "room", api: "revPAUnit", label: "revPAR" }
      )
    );
    props.dispatch(
      loadAvailableUnits(
        currentSpace.propertyID,
        moment().subtract(15, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        "daily",
        "currentYear"
      )
    );
    document.title = `${t("reports.bookingMetrics")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetBookingMetrics());
      props.dispatch(resetRevenueReport());
      props.dispatch(resetOccupancyReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewRoomType = (value) => {
    if (value) {
      let newRoomTypeFilter = value.map((item) => item.value);
      setRoomTypeFilter(newRoomTypeFilter);
    } else setRoomTypeFilter([]);
  };

  // CHANGE DATES
  // ----------------------------------------------------------------------
  const setNewDates = (e) => {
    let settings = [];
    if (report.length !== 0) {
      report.map((reportItem) => {
        settings.push(allReportSettings[reportItem]);
      });
    }

    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "monthToDate") {
      reportFrom = moment().startOf("month");
    } else if (e.value === "yearToDate") {
      reportFrom = moment().startOf("year");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 31) {
      newLevel = "monthly";
    }

    // props.dispatch(loadBookingMetrics(
    //   currentSpace.propertyID,
    //   reportFrom.format('YYYY-MM-DD'),
    //   reportTo.format('YYYY-MM-DD'),
    //   newLevel,
    //   {...reportSettings}
    // ));

    if (settings.length !== 0) {
      settings.map((itemSettings) => {
        props.dispatch(
          loadBookingMetrics(
            currentSpace.propertyID,
            dates.startDate.format("YYYY-MM-DD"),
            dates.endDate.format("YYYY-MM-DD"),
            newLevel,
            itemSettings,
            roomTypeFilter
          )
        );
      });
    }

    props.dispatch(
      loadAvailableUnits(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD")
      )
    );

    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD"),
        newLevel,
        "currentYear"
      )
    );

    props.dispatch(
      loadRevenue(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD"),
        newLevel
      )
    );

    setDates({ startDate: reportFrom, endDate: reportTo });
    setLevel(newLevel);
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setReportSettings(settings);
  };

  // CHANGE REPORT DROPDOWN
  // ----------------------------------------------------------------------
  const setReportType = (selectedArray) => {
    const tempArray = [];
    if (selectedArray) {
      selectedArray.map((item) => {
        tempArray.push(item.value);
      });
    }
    setReport([...tempArray]);
  };

  // SUBMIT QUERY
  // ----------------------------------------------------------------------
  const handleSubmit = () => {
    let settings = [];
    if (report.length !== 0) {
      report.map((reportItem) => {
        settings.push(allReportSettings[reportItem]);
      });
    }
    let calcLevel = "daily";
    const difference = dates.endDate.diff(dates.startDate, "days");
    if (difference > 90) {
      calcLevel = "monthly";
    } else if (difference > 30) {
      calcLevel = "monthly";
    }

    if (settings.length !== 0) {
      settings.map((itemSettings) => {
        props.dispatch(
          loadBookingMetrics(
            currentSpace.propertyID,
            dates.startDate.format("YYYY-MM-DD"),
            dates.endDate.format("YYYY-MM-DD"),
            calcLevel,
            itemSettings,
            roomTypeFilter
          )
        );
      });
    }

    props.dispatch(
      loadAvailableUnits(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD")
      )
    );

    props.dispatch(
      loadRevenue(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        calcLevel
      )
    );

    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        calcLevel,
        "currentYear"
      )
    );

    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
    setLevel(calcLevel);
    setReportSettings(settings);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>
            {t("reports.bookingMetrics")} {t("reports.report")}
          </Typography>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.BOOKING_METRICS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[7]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <div className={classes.fieldContainer}>
                    <Select
                      styles={selectStyle}
                      isMulti
                      options={roomTypeArray}
                      onChange={setNewRoomType}
                      placeholder={t("reports.reservationPage.filterRoomType")}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.fieldContainer}>
                        <Select
                          defaultValue={reportTypes[0]}
                          styles={selectStyle2}
                          options={reportTypes}
                          onChange={setReportType}
                          isMulti
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            fontWeight: 500,
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* --------------------------------------------------------- */}
            {/* REVPAU GRAPH */}
            {/* --------------------------------------------------------- */}
            {!loadingMetrics && barLabels && barLabels.length !== 0 && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.graph}>
                  {barData.length !== 0 && (
                    <GroupedBar labels={barLabels} data={barData} />
                  )}
                </div>
              </div>
            )}

            {loadingMetrics && (
              <div className={classes.blankSlate}>
                <CircularProgress />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* AVAILABILITY UNIT TABLE */}
            {/* --------------------------------------------------------- */}
            {/* {console.log('tableData', tableData)} */}
            {!loadingAvailableUnits && (
              <div className={classes.listSection}>
                {tableData.length !== 0 && (
                  <BasicExport
                    t={t}
                    data={tableData}
                    reportSettings={reportSettings}
                    daily={level === "daily"}
                    startDate={submittedDates.startDate.format("DD MMM YYYY")}
                    endDate={submittedDates.endDate.format("DD MMM YYYY")}
                    sameDate={moment(submittedDates.startDate).isSame(
                      submittedDates.endDate,
                      "day"
                    )}
                    currencyData={currencyData}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  loadingMetrics: state.loading.LOAD_BOOKING_METRICS,
  loadingAvailableUnits: state.loading.LOAD_AVAILABLE_UNITS,
});

export default withTranslation()(connect(mapStateToProps)(BookingMetrics));
