const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "FETCH_INVOICE_SETTINGS_SUCCESS":
    case "UPDATE_INVOICE_SETTINGS_SUCCESS":
    case "MANUAL_RESET_SUCCESS":
      return action.payload
    default:
      return state;
  }
};
