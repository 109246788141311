const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_TODOS_SUCCESS":
      return {
        ...state,
        ...action.payload.todoCategories
      }
    case "ADD_TODO_SUCCESS":
      const taskCategory = state[action.payload.categoryID]
      return {
        ...state,
        [action.payload.categoryID]: {
          ...taskCategory,
          tasks: [...taskCategory.tasks, action.payload._id]
        }
      }
    case "ADD_TODOCATEGORY_SUCCESS":
      return {
        ...state,
        [action.payload._id]: {...action.payload, tasks: []}
      }
    case "EDIT_TODOCATEGORY_SUCCESS":
      const existingCategory = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: {...existingCategory, ...action.payload}
      }
    case "DELETE_TODOCATEGORY_SUCCESS":
      const newState = {
        ...state  
      }
      delete newState[action.payload.taskCategoryID]
      return newState;
    case "DELETE_TODO_SUCCESS":
      const selectedCategory = state[action.payload.categoryID]
      return {
        ...state,
        [action.payload.categoryID]: {
          ...selectedCategory,
          tasks: selectedCategory.tasks.filter(item => item !== action.payload._id)
        }
      }
    case "RESET_TODOS":
      return {
        ...initialState
      };
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};
  

