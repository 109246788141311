import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'; 

import BasicInfo from './tab_pages/BasicInfo';
import RoomTypeSelect from './tab_pages/RoomTypeSelect';
import Details from './tab_pages/Details';
import Inventory from './tab_pages/Inventory';
import RoomTypeVisibility from './tab_pages/RoomTypeVisibility'; 

import Footer from './Footer';

const styles = theme =>({
    root: {
        height: '100%'
    },
    contentHeader:{
        height: '100px',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        margin: '0px 20px',
        boxSizing: 'border-box',
        alignItems: 'center'
    },
    container:{
        '& .main-content':{
            height: 'calc(100vh - 60px)',
            padding: '0px 100px',
            overflowY: 'auto',
            [theme.breakpoints.down("xs")]:{
                padding: '0px 20px',
                height: 'calc(100vh - 130px)',
            }
        },
        '& .main-content-title': {
            fontSize: '1.7rem',
            fontWeight: 600,
            marginBottom: '10px',
            marginTop: 40,
        },
        '& .sub-title': {
            fontSize: '1.3rem',
            marginBottom: 30,
            '& p, ol, ul': {
                maxWidth: '400px'
            }
        },
        '& .row': {
            marginBottom: 30,
            width: '100%',
        },
        '& .label': {
            color: '#484848',
            fontSize: '1.2rem',
            fontWeight: 500,
            marginBottom: 5,
        },

        '& .errorMessage': {
            color: "#f44336"
        },
    },
});

class ContentRenderer extends Component {
    
    components = {
        BASIC: BasicInfo,
        DETAILS: Details,
        ROOMTYPE: RoomTypeSelect,
        INVENTORY: Inventory,
        VISIBILITY: RoomTypeVisibility
    };

    render() {
        const { activeMenuID, contentProps, classes } = this.props;
        const MainComponent = this.components[activeMenuID];
        
        if(!MainComponent) return null;

        return(
            <div className={classes.root}>

                <div className={classes.container}>

                    <MainComponent { ...contentProps } />

                    <Footer {...contentProps} />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ContentRenderer);