import React from 'react';
import { connect } from "react-redux";
import * as i18nIsoCountries from "i18n-iso-countries";
import GuestList from './GuestList';
import GuestTile from './GuestTile';
import { toTitleCase } from '../utils/wordUtils';
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { initials, getAvatarFlag, fetchDateFormat, DATE_FORMATS, fetchCurrencyFormat } from "../../utils/utility";


const Guests = (props) => {
  const { reservation, rooms, display, selected, currentSpace } = props;
  
  const guest = reservation.guests[0]
  // Guest Information
  const checkIn = fetchDateFormat(reservation.checkIn, DATE_FORMATS.DEFAULT_DATE);
  const checkOut = fetchDateFormat(reservation.checkOut, DATE_FORMATS.DEFAULT_DATE);

  let cityName = "";
  let countryName = "";
  let flag = "";
  let guestImage = "";
  let data = {
    reservationID: reservation._id,
    checkIn: checkIn,
    checkOut: checkOut,
    guestName: toTitleCase(reservation.guestName),
    balance: reservation.balance,
    status: reservation.status,
    userInitials: initials(reservation.guestName),
    accommodationName: rooms?.[reservation?.accommodationList?.[0]?.roomID]?.name || '',
    currency: fetchCurrencyFormat(currentSpace.currency),
    selected: selected || null,
    resCode: reservation.code
  };

  if (guest) {
    cityName = guest.address ? guest.address.city : "";
    countryName = guest.nationality || (guest.address ? guest.address.country : "");
    data.address = countryName ? countryName : null;
    countryName = i18nIsoCountries.getName(countryName, "en") || countryName;
    flag = getAvatarFlag(countryName);
    guestImage = guest.image ? guest.image : "";

    data.guestName = toTitleCase(((guest.firstName || "") + " " + (guest.lastName || "")).trim() || props.guestName);
    data.userInitials = initials(data.guestName);
    data.avatar = guestImage || flag;
  }


  return (
    display === 'tile' ?
      <GuestTile
        {...data}
        handleOpenReservation={props.handleOpenReservation}
      />
      :
      <GuestList
        {...data}
        handleOpenReservation={props.handleOpenReservation}
      />
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  rooms: state.rooms
});

export default connect(mapStateToProps)(Guests);