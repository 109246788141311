import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import VariationForm from './VariationForm';

const useStyles = makeStyles(theme =>({
    dialog: {
        overflowY: 'hidden',
        position: 'relative'
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 2px 20px #F0F0F0",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        // alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 200px)',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]:{
            height: 'calc(100vh - 140px)'
        }
    },

    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    mainContent: {
        margin: '0px 30px',
        [theme.breakpoints.down('xs')]:{
            margin: '0px 5px',
        }
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        padding: '0px 5px',
        '& .button':{
            [theme.breakpoints.down("xs")]:{
                width: '100%'
            }
        }
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    formContainer:{
        minHeight: 'calc(100vh - 260px)'
    }
}));

const VariationFormModal = props =>{

    const { 
        closeModalHandler,
        activeStepInfo,
        productData,
        submitDraftHandler,
        gradeID, 
        isLoading
    } = props;

    const classes = useStyles();

    const ref = React.createRef();
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const triggerFormSubmit = () =>{
        ref.current.click();
    }

    const variationSubmitHandler = requestData =>{
        const { data, type, preventNextStep } = requestData;
        const response = submitDraftHandler(data, type, preventNextStep); 
        response.then(success =>{
            if(success) successHandler();
        })
    }

    const successHandler = () =>{
        setUpdateSuccess(true);
        setTimeout(()=> closeModalHandler(), 2000);
    }

    const handleCloseError = () =>{
        setUpdateSuccess(false);
    }

    return(
        <Dialog
                open={true}
                onClose={closeModalHandler}
                maxWidth={"md"}
                fullWidth={true}
                fullScreen={window.innerWidth < 901}
            >
                <div className={classes.dialog}>

                    {/* .....................MODAL HEADER.............................. */}
                    <div className={classes.dialogTitle}>
                        <div className={classes.header}>{gradeID ? 'Edit Variation': 'Add Variation'}</div>
                        <CloseIcon className={classes.closeIcon} 
                            onClick={closeModalHandler} 
                        />
                    </div>

                    {/*----------------PROGRESS LOADER--------------------- */}
                    {isLoading && (
                        <div className={classes.loadingState}>
                            <CircularProgress className={classes.loader} />
                        </div>
                    )}

                    {/* .....................MODAL BODY................................ */}
                    <div className={classes.container}>
                        <div className={classes.mainContent}>
                            <div className={classes.formContainer}>
                                <VariationForm 
                                    formSubmitRef={ref} 
                                    activeStepInfo={activeStepInfo}
                                    productData={productData}
                                    variationSubmitHandler={variationSubmitHandler}
                                    gradeID={gradeID}
                                />
                            </div>      
                            
                            <div className={classes.buttonContainer}>
                                <Button 
                                    className="button"
                                    variant="outlined"
                                    onClick={triggerFormSubmit}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/* ------------- Snackbar error messages -------------- */}
                    {updateSuccess && (
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={true}
                            onClose={handleCloseError}
                            autoHideDuration={6000}
                            ContentProps={{
                            'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">
                                { gradeID ? 
                                    'Variation updated successfully' :
                                    'Variation added successfully'
                                }
                            </span>}
                        />
                    )}
                </div>
            </Dialog>
    )   
}

const mapStateToProps = state =>{
    const { loading } = state;
    return{
        isLoading: loading.ADD_UPDATE_ACTIVITY,
    }
}

export default connect(mapStateToProps)(VariationFormModal);