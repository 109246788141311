import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getCurrency } from '../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        left: 0,
        right: 0,
        bottom: "75px",
        position: "absolute",
        boxShadow: 'unset',
        '&.Mui-expanded': {
            margin: 'unset',
            minHeight: "20px",
        },
        '&.Mui-expanded:before': {
            opacity: 1
        }
    },
    accSummary: {
        minHeight: "20px !important",
        backgroundColor: "#f8f9fb",

        '& .MuiAccordionSummary-content': {
            margin: 'unset',
            fontSize: "1.2rem",
            fontWeight: 600,
            color: "#666666",
        },
        '& .MuiIconButton-root': {
            padding: "3px",
        }
    },
    accDetails: {
        overflowY: "scroll",
        maxHeight: "250px",
    },
    content: {
        width: "560px",
        margin: "0 auto",
        backgroundColor: "#f8f9fb",
        padding: "0px 20px",
        paddingTop: "20px",
        height: "100%",
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        '& .label': {
            fontSize: '1.3rem',
            fontWeight: 600,
            color: '#000',
            margin: 'unset',
            maxWidth: "350px",
            [theme.breakpoints.down('xs')]: {
                maxWidth: "250px",
            }
        },
        '& .label .roomType': {
            fontSize: '1.1rem',
            fontWeight: 'unset',
            color: '#666666'
        },
        '& .label .count': {
            color: "#999999",
            fontWeight: "unset",
            fontSize: "1.1rem",
            marginLeft: "5px",
        },
    }
}));

const SummaryDrawer = props =>{
    const classes = useStyles();
    const { summary, currencyData, roomTypes, rooms } = props;
    return (
        <Accordion className={classes.root} defaultExpanded>
            <AccordionSummary
                className={classes.accSummary}
                expandIcon={<ExpandLessIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                Summary
            </AccordionSummary>
            <AccordionDetails className={clsx(classes.accDetails, 'hideScrollBar')}>
                <div className={classes.content}>

                    {summary?.breakup?.map((item, index) =>{
                        const roomType = roomTypes?.[item.roomTypeID];
                        const room = rooms?.[item.roomID];
                        return (
                            <div className={classes.flexRow} key={`summaryItem_${index}`}>

                                <p className="label">
                                    {room?.name} 
                                    {/* <span className='roomType'>({roomType.name})</span> */}
                                    {roomType.dormOrPrivate == 'dorm' ? <span className='count'>x {item.guestCount} Beds</span> : <span className='count'>x 1 Room</span>}
                                </p>
                                
                                <p className="label">{getCurrency(currencyData, item.accommodationAmount)}</p>
                            </div>
                        )
                    })}

                    <div className={classes.flexRow}>
                        <p className="label">Total Exclusive Tax</p>
                        <p className="label">{getCurrency(currencyData, summary.totalExclusiveTax)}</p>
                    </div>

                    <div className={classes.flexRow}>
                        <p className="label">Total Inclusive Tax</p>
                        <p className="label">{getCurrency(currencyData, summary.totalInclusiveTax)}</p>
                    </div>

                    <div className={classes.flexRow}>
                        <p className="label">Grand Total</p>
                        <p className="label">{getCurrency(currencyData, summary.totalAmount)}</p>
                    </div>
                </div>

            </AccordionDetails>
        </Accordion>
    )
}

const mapStateToProps = state =>{
    const { roomTypes, rooms } = state;
    return{
        roomTypes,
        rooms
    }
}

export default connect(mapStateToProps)(SummaryDrawer);