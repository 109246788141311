/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles, withStyles, Grid, Switch } from "@material-ui/core";
import { getCurrency } from "../../utils/utility";
import UpsellItemForm from "./UpsellItemForm";
import { updateUpsellItem } from "../../redux/actions/upsell/upsell";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  row: {
    alignItems: "center",
    display: "flex",
    marginBottom: 10,
  },
  bubble: {
    alignItems: "flex-start",
    background: "#FFFFFF",
    borderRadius: "15px",
    boxShadow: "0 1px 10px solid #BDBDBD",
    display: "flex",
    fontSize: "1.3rem",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "calc(100% - 32px)",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    height: 25,
    width: 25,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "1.3rem",
    fontWeight: 600,
    color: "#333333",
  },
  amount: {
    color: "#666666",
    fonSize: "1.2rem",
    marginTop: 10,
  },
  switchContainer: {
    marginLeft: "auto",
  },
  editIcon: {
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: 8,
    "&:hover": {
      background: "#F0F0F0",
    },
    "& img": {
      height: 15,
      width: 15,
    },
  },
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: "#E0E0E0",
    "&$checked": {
      color: "#8CB2A4",
    },
    "&$checked + $track": {
      backgroundColor: "#8CB2A4",
    },
  },
  checked: {},
  track: {},
})(Switch);

const UpsellItem = (props) => {
  const classes = useStyles();
  const { currentSpace, item, iconList } = props;
  const [selected, setSelected] = useState(false);
  const [form, setForm] = useState({
    name: item.name,
    rate: item.rate,
    isActive: item.isActive || false,
  });

  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };

  useEffect(() => {
    if (item) {
      setForm({
        name: item.name,
        rate: item.rate,
        isActive: item.isActive || false,
      });
    }
  }, [item]);

  const handleCheckbox = (e) => {
    const payload = { ...item, isActive: e.target.checked };
    props.dispatch(updateUpsellItem(item._id, payload));
    setForm({ ...form, isActive: e.target.checked });
  };

  const handleComplete = () => {
    setSelected(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        {!selected && (
          <div className={classes.bubble}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <div className={classes.iconContainer}>
                  <img alt="icon" src={item.icon} className={classes.icon} />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={classes.nameContainer}>
                  <div className={classes.name}>{item.name}</div>
                  <div className={classes.amount}>
                    {getCurrency(currencyData, item.rate)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={1}>
                {item.isActive && (
                  <div className={classes.switchContainer}>
                    <div
                      className={classes.editIcon}
                      onClick={() => setSelected(true)}
                    >
                      <img alt="edit" src={IMAGES.ICONS.editPencil} />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={2}>
                <div className={classes.switchContainer}>
                  <CustomSwitch
                    checked={Boolean(item.isActive)}
                    onChange={(e) => handleCheckbox(e, "isActive")}
                    color="primary"
                    name="switch"
                    inputProps={{ "aria-label": "room mapping switch" }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {selected && (
          <UpsellItemForm
            iconList={iconList}
            handleComplete={handleComplete}
            item={item}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID,
});

export default connect(mapStateToProps)(UpsellItem);
