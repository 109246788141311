export default {
   "space":{
      "members":[
         
      ],
      "admins":[
         "60c8c3a3cf92cd4bd9358008"
      ],
      "deletedUsers":[
         
      ],
      "modules":[
         
      ],
      "gallery":[
         
      ],
      "dateFormat":"DD/MM/YYYY",
      "hwRoomsFlag":false,
      "migrationChannels":[
         "Hostelworld",
         "Booking.com"
      ],
      "_id":"60c8c216843c2349c62198b9",
      "name":"Counter_Test_Prop2",
      "hwExpFlag":"started",
      "email":"cathal.ryan@hostelworld.com",
      "onboardingStatus":"pending",
      "hostelworldID":309543,
      "lat":"43.7384",
      "long":"7.4246",
      "address":"1 test, 2 test",
      "type":"Hostel",
      "country":"ESP",
      "timezone":"Europe/Amsterdam",
      "pendingUsers":[
         
      ],
      "createdAt":"2021-06-15T15:07:02.869Z",
      "updatedAt":"2021-06-17T07:12:00.336Z",
      "__v":1,
      "propertyID":"60caf540f114d52c443c5784",
      "id":"60c8c216843c2349c62198b9"
   },
   "spaceID":"60c8c216843c2349c62198b9",
   "step1":{
      "email":"jason@counter.app",
      "name": "Jason",
      "phone": "9876543210",
      "passwordSet":true
   },
   "step2":{
      "timezone":"Europe/Amsterdam",
      "currency":"EUR",
      "country":"ESP"
   },
   "step3":[
      {
         "name":"Basic Female Dorm",
         "roomTypeID":"60caf585f114d52c443c5c7a",
         "bedsPerRoom":4,
         "dormOrPrivate":"dorm",
         "rooms":[
            {
               "_id":"60d1b596eea1ad64e838142a",
               "name":"FD 102"
            }
         ]
      },
      {
         "name":"Deluxe Double",
         "roomTypeID":"60caf586f114d52c443c5eb0",
         "bedsPerRoom":2,
         "dormOrPrivate":"private",
         "rooms":[
            {
               "_id":"60d1b596eea1ad64e838142a",
               "name":"FD 102"
            }
         ]
      }
   ],
   "step4":{
      "channels":[
         "Hostelworld",
         "Booking.com"
      ]
   },
   "step5":{
      "subscription":"pending"
   },
   "ongoingStep":3,
   "migrationCompleted":false,
   "loginData":{
      "token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MGFjNzRkZmNlMjQ5OTRhYzU4ZmVlZDQiLCJpYXQiOjE2MjQzNTYyNDV9.hEDzzhJDrOQz7l8fAp5lP6sYqeQmhaYn24AKcsJu5bY",
      "userUIsettings":[
         
      ],
      "user":{
         "space":[
            "60ac74f3ce24994ac58feed6",
            "60abb56828acf8d1321f3fdf"
         ],
         "isExpert":false,
         "chatRoom":[
            
         ],
         "_id":"60ac74dfce24994ac58feed4",
         "name":"jason noronha",
         "email":"jason@counter.app",
         "createdAt":"2021-05-25T03:54:07.016Z",
         "updatedAt":"2021-06-22T10:04:05.508Z",
         "__v":87,
         "migrationStep":4
      }
   }
}