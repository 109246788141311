import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import ChatSupport from '../chat-support/ChatSupport';

import UserSignupForm from './UserSignupForm';
import PropertySignupForm from './PropertySignupForm';
// import TellUsMoreForm from './TellUsMoreForm';
// import { setUserSignupStep } from '../../redux/actions/signup/signupCommunity';

import { makeStyles, Grid, Hidden, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'hide',
    width: '100%',
  },
  progressHeader: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: '2px solid #E0E0E0',
    display: 'flex',
    height: 78,
    width: '100%'
  },
  headerText: {
    fontWeight: 600,
    color: '#999999'
  },
  logo: {
    marginLeft: 40,
    marginRight: 20,
    '& img': {
      height: 50,
      width: 50
    }
  },
  container: {
    background: '#F5F1ED',
    flexGrow: 1,
    height: '100%',
  },
  userInfoContainer: {
    display: 'flex',
    height: '100%'
  },
  right: {
    background: '#ffffff'
  },
  background: {
    alignItems: 'center',
    background: '#FFFFFF',
    display: 'flex',
    height: 'calc(100% - 32px)',
    maxHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  imageContainer: {
    width: '50%'
  },
  signupImage: {
    objectFit: 'contain',
    width: '100%',
  },
  welcome: {
    marginBottom: 15,
    marginTop: 30,
  }
}))

const Signup = (props) => {

  const classes = useStyles();
  const { step } = props;

  // const user = {
  //   user_id: currentUser && currentUser._id,
  //   email: currentUser && currentUser.email,
  //   name: currentUser && currentUser.name,
  //   "Property": currentSpace && currentSpace.name,
  //   "Country": currentSpace && currentSpace.country
  // }

  React.useEffect(() => {
    if (step > 2) {
      props.history.push('/');
    }
  }, [step]);

  const enterUserInfo = (
    <div className={classes.userInfoContainer}>
      <UserSignupForm />
    </div>
  )

  const enterPropertyInfo = (
    <div className={classes.userInfoContainer}>
      <PropertySignupForm />
    </div>
  )

  // TODO: Add this back in when signup process is complete
  // const tellUsMore = (
  //   <div className={classes.userInfoContainer}>
  //     <TellUsMoreForm />
  //   </div> 
  // )

  return (
    <div className={classes.root}>
      <div className={classes.progressHeader}>
        <div className={classes.logo}><img src="/images/icons/counter.png" /></div>
        <div className={classes.pageHeader}>
          <Typography variant="h6" className={classes.headerText}>{`Step ${step} of 2`}</Typography>
          <Typography variant="h6" className={classes.headerText}>
            {step===1 && 'Personal Information'}
            {step===2 && 'Property Information'}
            {/* {step===3 && 'Tell Us More!'} */}
          </Typography>
        </div>
      </div>
      
      <Grid container className={classes.container}>
        
        <Grid item xs={12} md={6}>
          {step===1 && enterUserInfo}
          {step===2 && enterPropertyInfo}
          {/* {step===3 && tellUsMore} */}
        </Grid>

        {step===1 && <Hidden smDown><Grid item xs={12} md={6} className={classes.right}>
          <div className={classes.background}>
            <div className={classes.imageContainer}>
              <img className={classes.signupImage} src="/images/community-large.png" />
            </div>
            <div className={classes.welcome}>
              <Typography variant="h2" style={{color: '#333333'}}>Signup for counter</Typography>
            </div>
            <div>
              <Typography variant="h5" style={{fontWeight: 600}}>all-in-one workspace for hostels</Typography>
            </div>
          </div>
        </Grid></Hidden>}

      </Grid>

      <ChatSupport/>

    </div>
  )
}

const mapStateToProps = state => ({
  step: state.auth.user.migrationStep || 1,
  currentUser: state.auth.user,
  currentSpace: state.dashboard.currentSpace && state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(withRouter(Signup));