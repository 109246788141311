import http from "../../utils/http";
import { normalize } from 'normalizr';
import { rateSchema } from '../../schema/rateSchema';


//----------------------------------------------------------------
// POST RESTRICTIONS
//----------------------------------------------------------------
export function editRestrictions(propID, restrictions, params = false) {
  const ACTION_NAME = "EDIT_RESTRICTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/restrictions/${propID}`, restrictions, { params })
      .then(successResult => {
        const data = successResult.data;
        console.log('Edit restrictions API success response: ', data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return true
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}