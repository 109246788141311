import http from "../../utils/http";
import { normalize } from 'normalizr';
import { roomTypeSchema, resSchema } from '../../schema/roomTypeSchema';
import { setCurrentReservationSettings } from "../dashboard/dashboard";

//----------------------------------------------------------------
// LOAD ROOMS AND ROOM TYPES
//----------------------------------------------------------------
export function getRoomTypes(spaceID, propID) {
  const ACTION_NAME = "LOAD_ROOMTYPES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/beds/roomType/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, [ roomTypeSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities, spaceID: spaceID} });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// LOAD RESERVATIONS
//----------------------------------------------------------------
export function getReservations(propID, start, end, refresh) {
  const ACTION_NAME = "LOAD_RESERVATIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/calendar/reservations/${propID}?start=${start}&end=${end}`)
      .then(successResult => {
        if (refresh) {
          dispatch({ type: 'RESERVATIONS_RESET' });
        }
        const data = successResult.data.resArray.map(res => ({...res, propertyID: propID}))
        // console.log(start,end)
        // console.log('loaded reservations for calendar', successResult.data)
        var normalizedOrder = normalize(data, [resSchema]);
        // console.log(normalizedOrder.entities);
        let blockedDays = {};
        if(successResult.data.blockArray && successResult.data.blockArray.length) {
          blockedDays = successResult.data.blockArray.reduce(function (r, a) {
            r[a.bedID] = r[a.bedID] || [];
            if (!r[a.bedID].includes(a.date))
              r[a.bedID].push(a.date);
            return r;
          }, Object.create(null));;
        }
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...normalizedOrder.entities, blockedDays } });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// LOAD RESERVATION DETAILS
//----------------------------------------------------------------
export function getReservationDetails(resID) {
  const ACTION_NAME = "LOAD_RESERVATION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    dispatch(setCurrentReservationSettings(true, null));
    return http
      .get(`/api/frontdesk/beds/reservation/${resID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// RESET RESERVATION DETAILS
//----------------------------------------------------------------
export const resetReservationDetails = () => (dispatch) => {
  dispatch({ type: "RESET_RESERVATION"})
};


export const updateCalenderReservation = (propertyID, patchData, calendarData) =>{
  const ACTION_NAME = "UPDATE_CALENDER_RESERVATION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/reservation/drag/${propertyID}`, {...patchData})
      .then(successResult => {
        dispatch(
          getReservations(
            propertyID, 
            calendarData.startDate, 
            calendarData.endDate,
            true)
        );
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RESERVATION CARD TOKENS
//----------------------------------------------------------------
export function getReservationCardTokens(resID) {
  const ACTION_NAME = "LOAD_RESERVATION_CARD_TOKENS"
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`/api/frontdesk/beds/reservation/cardTokens/${resID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error })
      })
  }
}