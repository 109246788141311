import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { MENU } from '../utils/layout';

import CalendarPage from '../components/experiences/CalendarPage';
import ManagePage from '../components/experiences/ManagePage';
import ReportsPage from '../components/experiences/ReportsPage';

import { fetchProductList, fetchConductorList } from '../redux/actions/experiences/activities';

const useStyles = makeStyles(theme => ({
    root: {
      margin: '0px 10px',
      marginRight: '20px',
      width: 'calc(100% - 50px)',
      [theme.breakpoints.down('sm')]:{
        margin: 'unset',
        width: '100%'
      }
    },
    moduleContainer: {
      [theme.breakpoints.down('sm')]:{
        margin: 'unset',
      },
      '&.isActive':{
        display: 'block',
      },
    }
}));

const Experiences = props => {
    const classes = useStyles();
    const { currentSubModule, currentModule, currentSpace, dispatch } = props;
    const subMenuList = MENU[currentModule].submenu;
    const activeSubModule = currentSubModule || MENU[currentModule].defaultSubmenu;

    useEffect(()=>{
      dispatch(fetchProductList(currentSpace.propertyID));
      dispatch(fetchConductorList(currentSpace.propertyID));
    }, [])

    return(
        <div className={classes.root}>
          {/* -----------------Calendar------------------- */}
          {!_.isEmpty(currentSpace) && activeSubModule == subMenuList.calendar.id && (
            <div style={{height: props.iframe ? '100vh' : 'calc(100vh - 90px)'}}>
              <CalendarPage />
            </div>
          )}
            
          {/* ---------------Experiences------------------- */}
          {activeSubModule == subMenuList.manage.id &&
          <div className={classes.moduleContainer}>
            <ManagePage />
          </div>}

          {/* -----------------  Reports  ------------------- */}
          {activeSubModule == subMenuList.reports.id &&
            <div className={classes.moduleContainer}>
              <ReportsPage />
            </div>
          }
        </div>
    )
}

const mapStateToProps = state =>{
  const { spaces, dashboard } = state;
  return{
    currentSpace: spaces[dashboard.currentSpace] || {},
    currentModule: dashboard.currentModule,
    currentSubModule: dashboard.currentSubModule,
    iframe: dashboard.iframe
  }
}

export default connect(mapStateToProps)(Experiences);