import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import Item from '../../common/Item';
import { Grid } from '@material-ui/core';
import { ICONS } from '../../../utils/imageUrls';
import { getCurrency } from '../../../utils/utility';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 20,
    height: 20,
    marginRight: 20
  },
  section: {
    margin: '30px 0 20px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
}));

const BookingProducts = forwardRef((props, ref) => {
  const classes = useStyles();
  const {  multiple, prefilledData } = props;
  const [selectedItem, setSelectedItem] = React.useState({});

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      if (multiple) {
        return fetchMultipleData();
      } 
    }
  }))

  React.useEffect(() => {
    if (prefilledData && Object.keys(prefilledData).length) {
      setSelectedItem({ ...prefilledData })
    }
  }, [prefilledData])

  const fetchMultipleData = () => {
    let data = { products: {} };
    data.products = { ...selectedItem };
    return data;
  }

  const onCountChange = (count, itemID, info) => {
    let itemData = {};
    if (multiple) {
      itemData = { ...selectedItem };
    }
    if (itemID) {
      if (count === 0) delete itemData[itemID];
      else itemData[itemID] = { ...info, quantity: count };
      setSelectedItem({ ...itemData })
    }
  }

  const fetchItemListView = () => {
    return props.itemList?.map((item, typeIndex) => {
        return (
          <Grid item xs={6} sm={4} md={3} key={typeIndex}>
            <Item
              itemID={item._id}
              data={{
                ...item,
                name: item.name,
                labelOne: `${moment(`${item.date} ${item.startTime}`).format('h:mm a')} - ${moment(`${item.date} ${item.endTime}`).format('h:mm a')}`,
                labelTwo: `${getCurrency({ code: item.currency, country: item.country }, item.price)} per person`,
              }}
              onCountChange={onCountChange}
              multiple={multiple}
              prefilledCount={prefilledData[item._id]?.quantity || 0}
              selected={selectedItem[item._id]} />
          </Grid>
        )
      })
  }

  return (
    <Grid container ref={ref}>
      <Grid item xs={12}>
        <div className={classes.section}>
          <img className={classes.icon} src={ICONS.BED} />
          <span className={classes.title}>Products</span>
        </div>
      </Grid>
      {fetchItemListView()}
    </Grid>
  )
})

export default (BookingProducts);