import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { updateSibaSchedule } from '../../redux/actions/legal/siba'
import { connect } from 'react-redux';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';

import { SIBA_EVENTS } from "../../utils/constants";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles( theme => ({
  label: {
    fontSize: '1.25rem',
    color: '#666666',
    fontWeight: 400
  },
  labelTitle: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 600,
    marginTop: 20
  },
  topMargin: {
    marginTop: 25
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex'
  },
  select: {
    height: 35,
    paddingLeft: 20,
    width: '80%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  heightComponent: {
    height: 400
  },
  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  editSubject: {
    fontSize: '1.4rem',
    flexGrow: 1,
    '& input': {
      fontSize: '1.4rem',
    }
  },
  emailTrigger: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    background: '#ffffff'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  error: {
    marginTop: 10,
    color: 'red',
    fontSize: '1.2rem'
  },
  rowItemsHeader:{
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 25
  },
  startContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.35rem',
    fontWeight: 600
  },
  endContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 30
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  iconView: {
    borderRadius: '50%',
    //background: '#ffffff',
    color: '#666666',
    cursor:'pointer',
    fontSize: '2rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  nonEdit: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 600,
    height: 35,
    marginTop: 20,
    paddingLeft: theme.spacing(2)
  },
  bottomPadding: {
    paddingBottom: 50
  }
}))

const triggerFreq = [
  { label: 'Trigger Delay', value: '' },
  { label: '1 day', value: 1 },
  { label: '2 day', value: 2 },
  { label: '3 day', value: 3 },
  { label: '4 day', value: 4 },
  { label: '5 day', value: 5 }
]

const triggerEvent = [
  { label: 'Trigger Event', value: '' },
  { label: 'After Check-in', value: SIBA_EVENTS.AFTER_CHECKIN },
  { label: 'After Check-out', value: SIBA_EVENTS.AFTER_CHECKOUT },
  { label: 'Manual', value: SIBA_EVENTS.MANUAL }
]

const triggerTime = [
  { label: 'Trigger Time', value: '' },
  { label: 'At 09:00 HRS', value: '0900' },
  { label: 'At 18:00 HRS', value: '1800' }
]

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    minWidth: '180px',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.2rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

const Scheduling = (props) => {
  const { t, currentSpace, legal } = props
  const classes = useStyles()
  const [form, setForm] = React.useState({})
  const [display, setDisplay] = React.useState({})
  const [error, setError] = React.useState({time: false, delay: false, event:false })
  const [save, setSave] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState('')

  useEffect(() => {
    if(legal && legal.siba) {
      setEdit(!Boolean(legal.siba.schedule))
      if(legal.siba.schedule) {
        setForm({ ...form, ...legal.siba.schedule })
        let event = [], delay = [], time = [];

        if(legal.siba.schedule.event) 
          event = triggerEvent.filter((event) => event.value === legal.siba.schedule.event)
        else
          event = [{label: '', value: ''}]

        if(legal.siba.schedule.delay) 
          delay = triggerFreq.filter((freq) => freq.value === legal.siba.schedule.delay)
        else
          delay = [{label: '', value: ''}]

        if(legal.siba.schedule.time)
          time = triggerTime.filter((time) => time.value === legal.siba.schedule.time)
        else
          time = [{label: '', value: ''}]

        setDisplay({ ...display, event: event[0].label, delay: delay[0].label, time: time[0].label  })
      }
    }
  }, [legal])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const selectTriggerEvent = (selected) => {
    if (selected) {
      setForm({ ...form, event: selected.value })
      if(error.event) setError({ ...error, event: false})
      if(!save) setSave(true)
    }
  }

  const selectTriggerDelay = (selected) => {
    if (selected) {
      setForm({ ...form, delay: selected.value })
      if(error.delay) setError({ ...error, delay: false})
      if(!save) setSave(true)
    }
  }

  const selectTriggerTime = (selected) => {
    if (selected) {
      setForm({ ...form, time: selected.value })
      if(error.time) setError({ ...error, time: false})
      if(!save) setSave(true)
    }
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
    if(!save) setSave(true)
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['time', 'delay', 'event'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setSave(false)
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = (e) => {
    const check = handleValidate()
    if(check) {
      props.dispatch(updateSibaSchedule(currentSpace.propertyID, form))
      .then((res) => {
        if(res.status === 200) {
          setMessage('Configuration saved successfully!')
          setOpenSnackbar(true)
          setEdit(false)
          setSave(false)
        }
      })
    }
  }

  const handleEdit = () => {
    if(legal.siba && legal.siba.schedule)
      setEdit(!edit)
  }

  return (
    <div className={clsx({ [classes.bottomPadding]: edit, [classes.heightComponent]: true })}>
      <Grid item xs={12}>
        <div className={classes.row}>
          <div className={classes.rowItemsHeader}>
            <div className={classes.startContainer}>
              <span>{t('automate.siba.scheduleConfig')}</span>
            </div>
            <div className={classes.endContainer}>
              {(!edit) && <span className={classes.editContainer} onClick={handleEdit}>
                <EditIcon className={classes.iconView} />
              </span>}
              {(edit) && <span className={classes.editContainer} onClick={handleEdit}>
                <CloseIcon className={classes.iconView} />
              </span>}
            </div>
          </div>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.event')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={clsx(classes.toggle, classes.select)}>
                <Select
                  value={triggerEvent.filter(e => e.value === (form.event || ""))}
                  styles={selectStyle}
                  maxMenuHeight={200}
                  options={triggerEvent}
                  onChange={selectTriggerEvent}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>}
              {edit && error.event && <div className={classes.error}>{t('automate.siba.eventError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(display && display.event) ?  display.event : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={clsx({[classes.topMargin]: edit})}>
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.delay')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={clsx(classes.toggle, classes.select)}>
                <Select
                  value={triggerFreq.filter(e => e.value === (form.delay || ""))}
                  styles={selectStyle}
                  options={triggerFreq}
                  maxMenuHeight={200}
                  onChange={selectTriggerDelay}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>}
              {edit && error.delay && <div className={classes.error}>{t('automate.siba.delayError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(display && display.delay) ?  display.delay : ''}
              </div>}
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={clsx({[classes.topMargin]: edit})}>
            <Grid item xs={5}>
              <div className={classes.label}>
                {t('automate.siba.time')}
              </div>
            </Grid>
            <Grid item xs={7}>
              {edit && <div className={clsx(classes.toggle, classes.select)}>
                <Select
                  value={triggerTime.filter(e => e.value === (form.time || ""))}
                  styles={selectStyle}
                  options={triggerTime}
                  maxMenuHeight={200}
                  onChange={selectTriggerTime}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>}
              {edit && error.time && <div className={classes.error}>{t('automate.siba.timeError')}</div>}
              {!edit && <div className={classes.nonEdit}>
                {(display && display.time) ?  display.time : ''}
              </div>}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {(save && edit) && <div className={classes.buttonsContainer}>
        <div className={classes.button} onClick={handleSubmit}>
          {t('actions.save')}
        </div>
      </div>}
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  legal: state.legal,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
})

export default withTranslation()(connect(mapStateToProps)(Scheduling))