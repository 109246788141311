const colorArray = [
  "#4F9581",
  "#AEC1E1",
  "#F2D073",
  "#E37381",
  "#E6BBB3",
  '#C17C74',
  '#586F6B',
  '#AC9969',
  '#7A6F9B',
  '#30638E'
];

const visibleSourceCount = 9;

const getSum = (array, column) => {
  let values = array.map((item) => parseInt(item[column]) || 0)
  return values.reduce((a, b) => a + b)
}

const sourceByCount = (reports) => {
  let data = [];
  const bookingSource = reports.bookingSource;
  const prop = bookingSource && bookingSource.propertyBookingSource;
  var totalCount = 0;
  var lastSourceIndex = visibleSourceCount - 1;

  if (prop) {
    prop.sort((a, b) => b.count - a.count).slice(0,lastSourceIndex).map((source,index) => {
      totalCount += source.count;
      data.push({
        "id": source.bookingSource ? source.bookingSource : 'None',
        "label": "Reservations",
        "value": source.count,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (prop && prop.length>lastSourceIndex) {
    const total = getSum(prop, 'count');
    data.push({
      "id": "Other (remaining sources)",
      "label": "Reservations",
      "value": (total - totalCount),
      "color": colorArray[lastSourceIndex]
    })
  }

  return data;
}

const sourceByAmount = (reports, currency) => {
  let data = [];
  const bookingSource = reports.bookingSource;
  const prop = bookingSource && bookingSource.propertyBookingSource;
  var totalCount = 0;
  var lastSourceIndex = visibleSourceCount - 1;

  if (prop) {
    prop.sort((a, b) => b.count - a.count).slice(0,lastSourceIndex).map((source,index) => {
      totalCount += source.count;
      data.push({
        "id": source.bookingSource ? source.bookingSource : 'None',
        "label": `Amount (${currency})`,
        "value": source.amount,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (prop && prop.length> lastSourceIndex) {
    const total = getSum(prop, 'amount');
    data.push({
      "id": "Other (remaining sources)",
      "label": `Amount (${currency})`,
      "value": (total - totalCount),
      "color": colorArray[lastSourceIndex]
    })
  }

  return data;
}

const calculatePercentage = (value, total) =>{
  let percentVal = ((value/total) * 100);
  percentVal = Math.round((percentVal + Number.EPSILON) * 100) / 100;
  return percentVal;
}

const processBookingSrcTableData = (bookingSource = {}, currencyData) =>{
  let dataFormat = [];
  const { propertyBookingSource = [] } = bookingSource;

  if(!propertyBookingSource?.length > 0) return dataFormat;
  
  const totalBookingAmount = getSum(propertyBookingSource, 'amount');
  const totalResvCount = getSum(propertyBookingSource, 'count');

  propertyBookingSource.map(item => dataFormat.push(
    {
        sourceName: item?.bookingSource || '',
        resvCount: item.count,
        totalResvPercent: `${calculatePercentage(item.count, totalResvCount)}%`,
        amount: item.amount,
        totalAmountPercent: `${calculatePercentage(item.amount, totalBookingAmount)}%`
    }
  ));
  return dataFormat;
}

export { sourceByCount, sourceByAmount, processBookingSrcTableData };