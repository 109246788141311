export const sidebarMenu = {
    BASIC: {
        id: "BASIC",
        title: "Basic Information",
    },
    DETAILS: {
        id: "DETAILS",
        title: "Details",
    },
    ROOMTYPE: {
        id: "ROOMTYPE",
        title: "Room Type",
    },
    INVENTORY: {
        id: "INVENTORY",
        title: "Multi-Inventory",
    },
    VISIBILITY: {
        id: "VISIBILITY",
        title: "Visibility",
    }
}