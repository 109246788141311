import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import VariationForm from './VariationForm';
import Footer from '../Footer';

const styles = theme =>({
    root: {
        margin: '0px 30px',
        [theme.breakpoints.down('xs')]:{
            margin: '0px 15px',
        }
    },
    label: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginBottom: 5,
        width: '100%',
        padding: '5px'
    },
    selectFormControl: {
        // width: '100%',
        padding: '0px 5px',
        boxSizing: 'border-box',
        marginBottom: '20px'
    },
    selectInputContainer:{
        display: 'flex',

        '& $selectInput':{
            borderRadius: '0px',
        },
        
        '& .adornment':{
            border: '1px solid #d8dcdd',
            padding: '0px 10px',
            backgroundColor: '#ebeff0',
            color: '#19232d',
            fontSize: '1.2rem',
            fontWeight: 400,
            lineHeight: '35px',
            zIndex: 1,  
            minWidth: '30px',
            textAlign: 'center'
        },
        '& .adornment.start':{
            marginRight: '-1px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
        },
        '& .adornment.end':{
            marginLeft: '-2px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
        },

        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },


    dateTimeContainer:{
        display: 'flex',
        flexWrap: 'wrap',
        padding:' 0px 5px',
        marginBottom: '20px',
        '& .timePicker':{
            marginLeft: '10px',
            [theme.breakpoints.down('xs')]:{
                marginLeft: '0px',
                width: '100%'
            }
        },
        '& $selectInputContainer':{
            marginBottom: '10px'
        }
    
    },
    datePicker:{
        border: '1px solid #d8dcdd',
        minWidth: '160px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        '& .SingleDatePicker_picker':{
            zIndex: 2
        },
        '& .DateInput_input__disabled':{
            backgroundColor: '#fff',
            fontStyle: 'unset'
        },
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },

    checkbox:{
        marginLeft: '0px',
        marginRight: '0px',
        width: 'max-content',
        marginBottom: '20px',
    },

    formControl:{
        marginBottom: '20px',
    },
    textInput: {
        height: '40px',
        backgroundColor: '#f8f9fb',
        borderRadius: '5px',
        padding: '0px 10px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
        '&.title':{
            minWidth: '430px'
        }
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        padding: '0px 5px',
        '& .button':{
            [theme.breakpoints.down("xs")]:{
                width: '100%'
            }
        }
    },

    selectConductor:{
        display: 'flex',
        marginBottom: '10px',
        '& $selectFormControl':{
            marginBottom: '0px'
        },
        '& $selectInput':{
            minWidth: '170px'
        }
    },

    addConductorBtn:{
        fontSize: '1.3rem',
        fontWeight: 600,
        cursor: 'pointer',
        padding: '5px',
        textDecoration: 'underline'
    },

    errorMessage: {
        color: "#f44336"
    },

    cutOffSelect: {
        width: '50%',
        marginBottom: '20px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    }
});

class ActivityBookingDetails extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    submitHandler = () =>{
        this.ref.current.click();
    }

    variationSubmitHandler = requestData =>{
        const { submitDraftHandler } = this.props;
        const { data, type, preventNextStep } = requestData;

        submitDraftHandler(data, type, preventNextStep); 
    }

    render(){
        const { 
            classes, 
            nextStepHandler, 
            prevStepHandler,
            activeStepInfo,
            productData,
            coverPhotoUrl
        } = this.props;

        return(
            <>
                <div className="main-content">
                    <VariationForm 
                        formSubmitRef={this.ref} 
                        activeStepInfo={activeStepInfo}
                        productData={productData}
                        variationSubmitHandler={this.variationSubmitHandler}
                        coverPhotoUrl={coverPhotoUrl}
                    />
                </div>
                <Footer 
                    nextClickHandler = {this.submitHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}


export default withStyles(styles)(ActivityBookingDetails);