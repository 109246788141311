import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import PlusMinus from "../../common/PlusMinus";
import POSLineItem from './POSLineItem';

import { UPSELL_ITEMS } from "../../../utils/upsellItems";
import { getAmtFormat, fetchCurrencyFormat } from "../../../utils/utility";
import { updateReservationItems } from "../../../redux/actions/reservation/editReservation";

import { Grid, Button, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
	container: {
		paddingTop: 20
	},
	title: {
		fontSize: '2rem',
		fontWeight: 600
	},
	countContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		height: '100%'
	},
	countIcon: {
		width: '30px',
		height: '30px',
		alignItems: 'center',
		background: '#fff',
		borderRadius: '50%',
		boxShadow: '0 0px 10px 0 #d6d1ce',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		'& svg': {
			fontSize: '2.8rem',
			color: '#605b5b',
		},
	},
	countNumber: {
		width: '30px',
		height: '30px',
		alignItems: 'center',
		background: '#ffffff',
		color: '#605b5b',
		borderRadius: '50%',
		boxShadow: '0 0px 10px 0 #d6d1ce',
		display: 'flex',
		justifyContent: 'center',
		marginLeft: 5,
		marginRight: 5,
	},
	icon: {
		background: '#fff',
		width: '50px',
		height: '50px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		// boxShadow: '0 0px 10px 0 #d6d1ce',
		cursor: 'pointer',
		border: '1px solid #fff',
		'& img': {
			width: '70%',
		}
	},
	itemIcons: {
		width: '100%',
		paddingLeft: 0,
		marginTop: 20,
		'& li': {
			display: 'inline-block',
			margin: '10px',
		},
		'& li.active span': {
			border: '1px solid #C0C0C0',
			background: '#C0C0C0',
		},
	},
	mobileItemIcons: {
		overflow: 'auto',
		width: '100%',
		whiteSpace: 'nowrap',
		paddingLeft: 0,
		marginTop: 20,
		'& li': {
			display: 'inline-block',
			margin: '10px',
		},
		'& li.active span': {
			border: '1px solid #C0C0C0',
			background: '#C0C0C0',
		},
	},
	section: {
		padding: 10,
	},
	block: {
		display: 'flex',
		padding: '20px 10px',
		background: '#fff',
		alignItems: 'center'
	},
	details: {
		display: 'flex',
		width: '60%',
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	mobileDetails: {
		display: 'flex',
		width: '90%',
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	submit: {
		color: '#ffffff',
		background: '#6ea7d6',
		fontSize: '1.2rem',
		border: '0',
		'&:hover': {
			backgroundColor: '#6ea7d6',
			border: 0,
		}
	},
	disabled: {
		cursor: 'default',
		background: 'grey'
	},
	disabledImage: {
		cursor: 'default',
		'& img': {
			filter: 'invert(90%)'
		}
	},
	closeIcon: {
		borderRadius: '50%',
		color: '#666666',
		cursor: 'pointer',
		marginLeft: 10,
		padding: 5,
		transition: '150ms',
		'&:hover': {
			background: '#dddddd',
			color: '#666666'
		},
	},
	buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 50,
        width: '100%',
    },
    submit: {
        border: '0',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: '#6ea7d6',
        color: '#fff',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
          backgroundColor: '#6ea7d6',
          color: '#fff',
        }
      },
	emptyContainer: {
		background: '#FFF',
		border: "none",
		borderRadius: 5,
		marginBottom: 10,
		padding: '10px 20px !important',
    	margin: '2px 12px'
	},
	lightText: {
		color: "#828282",
		fontWeight: 400,
		display: 'block',
		fontSize: '1.4rem',
		overflow: 'auto',
		whiteSpace: 'pre-wrap',
		width: '100%',
		wordWrap: 'break-word',
	},
});

const Upsell = (props) => {
	const { classes, reservation, currentSpace, loading, upsellItems, t } = props;
	const [selectedItem, setSelectedItem] = React.useState({});
	// const [inEdit, setInEdit] = React.useState(false);
	const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);


	const handleIconSelection = (iconID, selected) => {
		let itemData = UPSELL_ITEMS.find(item => item.iconID === iconID) || {};
		return selected ? itemData.selectedIcon : itemData.icon;
	}

	const onCountChange = (count, itemID) => {
		let selected = { ...selectedItem };
		if (!selected[itemID]) {
			selected[itemID] = {
				upsellID: itemID,
			}
		}
		selected[itemID].count = count;
		selected[itemID].amount = (upsellItems[itemID].rate || 0) * count;
		if (count === 0 && selected[itemID]) delete selected[itemID];
		setSelectedItem({ ...selected })
	}

	const getSelectedList = (selectedItem) => {
        return Object.keys(selectedItem).map(id => selectedItem[id]);
	};
	
	const handleSubmit = () => {
		let items = getSelectedList(selectedItem);
		props.dispatch(updateReservationItems(reservation._id, items));
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add add-on', 'eventLabel': 'Add Add-On'});
		setSelectedItem({})
	}

	// const handleManageItem = (itemData) => {
	// 	let selected = { ...selectedItem };
	// 	if (selected[itemData.upsellID]) {
	// 		delete selected[itemData.upsellID].toSave;
	// 		setSelectedItem({ ...selected })
	// 		setInEdit(false)
	// 		props.dispatch(updateReservationItems(reservation._id, { ...selected[itemData.upsellID] }))
	// 	}
	// }

	// const handleSelected = (itemData) => {
	// 	let selected = { ...selectedItem }
	// 	if (!selected[itemData._id]) {
	// 		onCountChange(1, itemData._id)
	// 	}
	// }

	// const handleCloseItem = (itemData) => {
	// 	let selected = { ...selectedItem };
	// 	if (selected[itemData._id]) {
	// 		if (selected[itemData._id]._id) {
	// 			selected[itemData._id].count = selected[itemData._id].savedCount;
	// 			selected[itemData._id].amount = selected[itemData._id].savedAmount;
	// 			selected[itemData._id].toSave = false
	// 		} else {
	// 			delete selected[itemData._id]
	// 		}
	// 		setInEdit(false)
	// 		setSelectedItem({ ...selected })
	// 	}
	// }

	// const itemView = currentSpace.upsellItem && currentSpace.upsellItem.map((key) => {
	//   const itemData = { ...upsellItems[key] };
	//   return itemData && itemData.isActive && currentSpace.propertyID === itemData.propertyID ? (
	//     <li key={key}
	//       onClick={() => !selectedItem?.[key]?.toSave && inEdit ? null : handleSelected(itemData)}
	//       className={clsx({
	//         ['active']: selectedItem[key],
	//         [classes.disabledImage]: !selectedItem?.[key]?.toSave && inEdit && !selectedItem[key]
	//       })}
	//     >
	//       <span className={classes.icon}>
	//         <img src={handleIconSelection(itemData.iconID, selectedItem[key])} alt={itemData.name} title={itemData.name} />
	//       </span>
	//     </li>
	//   ) : null
	// });

	const itemList = currentSpace?.upsellItem?.sort()?.map(key => {

		// check for inActive add ons
		if (!upsellItems?.[key]?.isActive) return null;

		let itemData = selectedItem[key] ? selectedItem[key] : null;
		return (
			<Grid item xs={12} md={6} key={key}>
				<POSLineItem
					id={key}
					name={upsellItems?.[key]?.name}
					amount={upsellItems?.[key]?.rate}
					image={handleIconSelection(upsellItems?.[key]?.iconID)}
					icon={true}
					currency={currency}
					onCountChange={onCountChange}
					quantity={itemData?.count || 0}
				/>
			</Grid>
		)
	}).filter(list => list);


	const noAddOns = (
		<Grid item xs={12} className={classes.emptyContainer}>
		  <p className={classes.lightText}>
			{t('existingReservation.addOnsTab.noAddOns')}
		  </p>
		</Grid>
	  );


	return (
		<div className={classes.container}>
			<Grid container spacing={3}>

				{itemList?.length > 0 ? itemList : noAddOns}

				{Object.keys(selectedItem).length > 0 && <Grid item xs={12}>
					<div className={classes.buttonContainer}>
						<Button variant="contained" className={classes.submit} onClick={() => handleSubmit()}>{t('actions.save')}</Button>
					</div>
				</Grid>}

			</Grid>
			{loading &&
				<div className={"loadingOverlay"}>
					<CircularProgress className={"loading"} />
				</div>
			}
		</div>
	);
}

const mapStateToProps = (state, props) => {
	return {
		loading: state.loading.UPDATE_RESERVATION_ITEMS,
		upsellItems: state.upsell || {},
		reservation: state.reservationDetails.reservation,
		currentSpace: state.spaces[state.dashboard.currentSpace]
	}
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Upsell)));