import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select';

import Footer from '../Footer';

const themeList = [ 
    { value: 'Activity', label: 'Activity' },
    { value: 'Beerpong', label: 'Beerpong' },
    { value: 'Comedy', label: 'Comedy' },
    { value: 'Community', label: 'Community' },
    { value: 'Cookingclass', label: 'Cookingclass' },
    { value: 'Daytrip', label: 'Daytrip' },
    { value: 'Drink', label: 'Drink' },
    { value: 'Eventtour', label: 'Eventtour' },
    { value: 'Food', label: 'Food' },
    { value: 'Games', label: 'Games' },
    { value: 'Karaoke', label: 'Karaoke' },
    { value: 'Movie', label: 'Movie' },
    { value: 'Music', label: 'Music' },
    { value: 'Party', label: 'Party' },
    { value: 'Sustainability', label: 'Sustainability' },
    { value: 'Walkingtour', label: 'Walkingtour' },
    { value: 'Yoga', label: 'Yoga' } 
];

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    selectFormControl: {
        width: '50%',
        boxSizing: 'border-box',
        marginTop: '10px',
        borderRadius: '4px',
        border: '1px solid hsl(0,0%,80%)',
        
        '&.error':{
            borderColor: '#f44336'
        },

        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    checkboxContainer:{
        marginTop: '20px',
        marginLeft: '-10px'
    },
    checkbox:{
        marginLeft: '0px',
        marginRight: '0px',
        width: 'max-content'
    },
}));

const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
    //   paddingLeft: 8,
    //   borderRadius: 10,
    //   boxShadow: '0 2px 30px #F2F2F2',
    //   '[type="text"]': {
    //     fontFamily: 'Roboto, sans-serif !important',
    //     fontSize: '1.4rem',
    //     color: 'rgba(0, 0, 0, 0.87)'
    //   }
    })
};

const ActivityTheme = props =>{

    const classes = useStyles();
    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo,
        submitDraftHandler,
        productData  
    } = props;

    const [ theme, setTheme ] = useState([]);
    const [ inHoustGuestAllowed, setInHoustGuestAllowed ] = useState(false);
    const [ themeError, setThemeError ] = useState(false);

    useEffect(() => {
        if(productData.categories){ 
            const data = formatThemeData(productData.categories);
            setTheme(data);
        }

        if(productData.hasOwnProperty("externalGuestsAllowed")){
            const status = productData.externalGuestsAllowed;
            setInHoustGuestAllowed(!status);
        }

    }, [ productData.categories ]);

    const formatThemeData = (data) =>{
        const formattedData = data.map(theme =>({
            value: theme,
            label: theme
        }));

        return [ ...formattedData ];
    }

    const onChangeHandler = value =>{
        if(value){
            setTheme(value);
            setThemeError(false);
            return;
        }
        setTheme([]);
    }

    const checkboxHandler = event =>{
        const isChecked = event.target.checked;
        setInHoustGuestAllowed(isChecked);
    }

    const validateThemes = (themesData) =>{

        if( themesData.length > 0 ){
            setThemeError(false);
            return true;
        }
        setThemeError(true);
    }

    const submitHandler = () =>{
        const categories = theme.map(item => item.value);
        if(!validateThemes(categories)) return;
        const data = { 
            categories,  
            externalGuestsAllowed: !inHoustGuestAllowed
        };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className={clsx("main-content", "hideScrollBar")}>
                
                <div className="main-content-title">What type of experience will you host</div>

                <div className="sub-title">
                    <p>Select a theme that best describes what guests will mainly be doing on your experience. This will help guests find and book your experience.</p>
                </div>

                <FormControl 
                    variant="outlined" 
                    className={clsx(classes.selectFormControl, { error: themeError })}
                >
                    <Select
                        value={theme}
                        options={themeList}
                        onChange={onChangeHandler}
                        styles={selectStyle}
                        isMulti
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </FormControl>

                {themeError && (
                    <FormHelperText className="errorMessage">
                        Theme is required
                    </FormHelperText>
                )}

                <div className={classes.checkboxContainer}>
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                        <Checkbox
                            id="inHoustGuest"
                            name="inHoustGuest"
                            color="primary"
                            checked={inHoustGuestAllowed}
                            onChange={checkboxHandler}
                        />
                        }
                        label={'This experience is for in-house guests only'}
                    />
                </div>

            </div>
            <Footer
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
            />
        </>
    )
}

export default ActivityTheme; 