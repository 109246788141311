import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { BOOKING_SOURCE } from '../../../utils/constants';
import IMAGES from '../../../constants/images';
import { createBookingSource } from '../../../redux/actions/reservation/newReservation';

const useStyles = makeStyles(theme =>({
    root: {
        paddingBottom: '30px'
    },
    blockItems: {
        cursor: 'pointer',
        display: 'flex',
        padding: '24px',
        fontSize: '1.3rem',
        background: '#ffffff',
        boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
        alignItems: 'center',
        borderRadius: 5,
        marginRight: 10,
        marginBottom: 10,
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
          padding: 20
        }
    },
    selected: {
        background: '#E0E0E0',
    },
    blockIcon: {
        height: 40,
        width: 40,
        [theme.breakpoints.down('xs')]: {
          height: 20,
          width: 20
        }
    },
    blockLabel: {
        marginTop: 20
    },
    radioContainer: {
        color: '#666666',
        margin: 10,
    },
    radio: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        '& span':{
          fontSize: '1.3rem',
          padding: 0,
          paddingRight: 5
        },
    },
    link: {
        color: theme.palette.primary.main,
        cursor:'pointer',
        fontSize: '1.2rem',
        marginBottom: '20px',
        marginLeft: '10px'
    },
    formContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        marginLeft: '10px'
    },
    bookingSource: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    buttonHolder: {
        display: 'flex',
        marginLeft: 10,
        marginBottom: 4
    },
    roundbutton: {
        border: '1px solid #dddddd',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '1.8rem',
        marginLeft: 10,
        padding: 5,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        }
    },
    error: {
        color: '#f44336'
    }
}));

const DEFAULT_BOOKING_SOURCE = [
    { value: BOOKING_SOURCE.PHONE, label: BOOKING_SOURCE.PHONE, img: IMAGES.ICONS.darkCall },
    { value: BOOKING_SOURCE.EMAIL, label: BOOKING_SOURCE.EMAIL, img: IMAGES.ICONS.darkEmail },
    { value: BOOKING_SOURCE.WALKIN, label: BOOKING_SOURCE.WALKIN, img: IMAGES.ICONS.darkFootprint },
    { value: BOOKING_SOURCE.OTHER, label: BOOKING_SOURCE.OTHER, img: IMAGES.ICONS.darkMore },
];

const BookingSources = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { selectedSource, sourceSelectHandler, bookingSource, dispatch, currentSpace, error } = props;
    const [showOtherSources, setshowOtherSources] = useState(false);
    const [addNewSource, setAddNewSource] = useState(false);

    const onSelectHandler = (source) =>{

        if(source == BOOKING_SOURCE.OTHER){
            setshowOtherSources(true);
            sourceSelectHandler(null);
            return;
        }

        sourceSelectHandler(source);
        setshowOtherSources(false);
    }

    const onSelectOtherSource = event =>{
        const value = event.target.value;
        sourceSelectHandler(value);
    }

    const newBookingSourceHandler = () =>{
        const payload = {
            propertyID: currentSpace.propertyID, 
            name: selectedSource
        }
        dispatch(createBookingSource(payload, currentSpace._id));
        setAddNewSource(false);
    }

    return (
        <div className={classes.root}>

            <h4>{t('newReservation.basicDetails.selectBookingSource')}</h4>

            <Grid container>
                {DEFAULT_BOOKING_SOURCE.map((data, sourceIndex) => (
                    <Grid item md={2} sm={3} xs={6} key={`defaultSource_${sourceIndex}`}>
                        <div
                            className={clsx({
                                [classes.blockItems]: true,
                                [classes.selected]: (data.value == selectedSource || (showOtherSources && data.value === BOOKING_SOURCE.OTHER))
                            })}
                            onClick={() => onSelectHandler(data.value)}>
                            <img className={classes.blockIcon} src={data.img} />
                            <span className={classes.blockLabel}>{data.label}</span>
                        </div>
                    </Grid>
                ))}
            </Grid>
            
            {/* ----------------OTHER BOOKING SOURCE OPTIONS----------------- */}
            {showOtherSources && (
                <>
                    <RadioGroup
                        aria-label="source"
                        className={classes.radioContainer}
                        value={selectedSource}
                        onChange={onSelectOtherSource}
                    > 
                        {bookingSource.map((value, index) =>
                            <FormControlLabel
                                key={`otherSource_${index}`}
                                className={classes.radio}
                                value={value}
                                control={<Radio color="primary" disableRipple />}
                                label={value}
                            />
                        )}
                    </RadioGroup>

                    {/* ----------------ADD NEW BOOKING SOURCE----------------- */}
                    {!addNewSource && (
                        <div 
                            className={classes.link} 
                            onClick={() => setAddNewSource(true)}
                        >
                            {t('newReservation.basicDetails.addBookingSource')}
                        </div>
                    )}

                    {addNewSource && (
                        <div className={classes.formContainer}>
                            <TextField
                                margin="none"
                                label={t('newReservation.basicDetails.newBookingSource')}
                                onChange={onSelectOtherSource}
                                className={classes.bookingSource}
                                InputLabelProps={{ shrink: true }}
                            />
            
                            <div className={classes.buttonHolder}>
                                <CloseIcon className={classes.roundbutton} onClick={() => setAddNewSource(false)} />
                                <DoneIcon className={classes.roundbutton} onClick={newBookingSourceHandler} />
                            </div>
                        </div>
                    )}
                </>
            )}

            {error && <div className={classes.error}>Please select booking a source</div>}
        </div>
    )
}

const mapStateToProps = state =>{
    const { bookingSource } = state;
    return {
        bookingSource
    }
}

export default connect(mapStateToProps)(BookingSources);