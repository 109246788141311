import http from "../../utils/http";
import { normalize } from 'normalizr';
import { voteSchema } from '../../schema/voteSchema';

//----------------------------------------------------------------
// LOAD VOTES
//----------------------------------------------------------------
export function loadVotes(spaceID) {
  const ACTION_NAME = "LOAD_VOTES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/vote/all/${spaceID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ voteSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE VOTE
//----------------------------------------------------------------
export function createVote(form) {
  const ACTION_NAME = "ADD_VOTE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/vote`, form)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data, spaceID: form.spaceID}});
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// POST VOTE
//----------------------------------------------------------------
export function postVote(voteID, optionID, userID) {
  const ACTION_NAME = "POST_VOTE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/vote`, {voteID, optionID})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {voteID, userID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
