import React from 'react';
import { makeStyles } from '@material-ui/core';
import { COUNTER_ADDRESS } from '../constants';

const useStyles = makeStyles( theme => ({
  root: {
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: '1.5',
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 15,
    marginTop: 15,
  },
  subHeader: {
    fontWeight: 600,
    fontSize: '1.3rem',
    marginTop: 20,
  },
  text: {
    marginLeft: 10,
    marginBottom: 10,
  },
  subText: {
    marginLeft: 10
  },
  link: {
    color: 'blue'
  }
}))

export default () => {

  const classes=  useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.text}>
        <p>These terms and conditions (the T&Cs) govern the agreement(s) concluded between Hostelworld.com
          Limited, a company incorporated in Ireland at {COUNTER_ADDRESS}
          (“HW”, “we”, “us” or “our”) and you for PMS System (as defined below). By clicking to accept these T&Cs,
          or using, enabling or accessing the PMS System, you agree to these T&Cs which you acknowledge
          represent a binding legal agreement between you and HW. If you are agreeing to these T&Cs on behalf of a
          company or other organisation, you represent that you have the authority to bind that company or
          organisation (including all agents) to these T&Cs. If you do not have that authority, or if you do not agree
          with these T&Cs, you may not use the PMS System.</p>
      </div>

      <div className={classes.subHeader}>1. SERVICES</div>
      <div className={classes.text}>
        <p>1.1.  HW shall, during the Term, provide the PMS System and make available the Documentation to you on and
          subject to these T&Cs.</p>
        <p>1.2.  HW shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days
          a week, except for: <br/>
          <div className={classes.subText}>
            <p>1.2.1.  planned maintenance carried out during the maintenance window of 4:00 am to 7.00 am Irish time; and</p>
            <p>1.2.2.  unscheduled maintenance performed outside Normal Business Hours, provided that HW has used
              reasonable endeavours to give you at least 6 Normal Business Hours’ notice in advance.</p>
          </div>
        </p>
        <p>1.3.  HW will, as part of the Services, provide you with HW’s standard customer support services during Normal
          Business Hours.</p>
        <p>1.4.  You acknowledge and agree that parts of the Services may be released by HW in a beta testing version, and
          that in such case, you should use the PMS System with that standard of stability in mind.</p>
      </div>
      
      <div className={classes.subHeader}>2. CUSTOMER DATA</div>
      <div className={classes.text}>
        <p>2.1.  You shall own all right, title and interest in and to all your Customer Data and shall have sole responsibility for
          the legality, reliability, integrity, accuracy and quality of all such Customer Data.</p>
        <p>2.2.  Both parties will comply with all applicable requirements of the Data Protection Legislation. This Section 3 is in
          addition to, and does not relieve, remove or replace, a party’s obligations or rights under the Data Protection
          Legislation.</p>
        <p>2.3.  The parties acknowledge that in performing its obligations under these T&Cs, HW may process personal data
          on your behalf. In such circumstances, HW acknowledges that you are the controller and HW is a processor and
          HW shall: <br/>
          <div className={classes.subText}>
            <p>2.3.1.  process such personal data only in accordance with your documented written instructions unless HW is
              required by the laws of European Union Member State or by the laws of the European Union applicable
              to HW to process personal data;</p>
            <p>2.3.2.  ensure that all personnel who have access to and/or process personal data are obliged to keep the
              personal data confidential;</p>
            <p>2.3.3.  not transfer any personal data outside of the European Economic Area unless it has obtained your prior
              written consent to do so and the following conditions are fulfilled:
              <div className={classes.subText}>
                <p>(i)  you or HW have provided appropriate safeguards in relation to the transfer;</p>
                <p>(ii)  the data subject has enforceable rights and effective legal remedies;</p>
                <p>(iii)  HW complies with its obligations under the Data Protection Legislation by providing an adequate
                  level  of protection to any personal data that is transferred; and</p>
                <p>(iv)  HW complies with reasonable instructions notified to it in advance by you with respect to the
                processing of the personal data;</p>
              </div>
            </p>
            <p>2.3.4.  assist you, at your cost, in responding to any request from a data subject and in ensuring compliance
            with its obligations under the Data Protection Legislation with respect to security, breach notifications,
            impact assessments and consultations with supervisory authorities or regulators;</p>
            <p>2.3.5.  at your written direction, delete or return personal data and copies thereof to you on termination of the
            agreement unless required by applicable law to store the personal data;</p>
            <p>2.3.6.  notify you without undue delay on becoming aware of a personal data breach; and</p>
            <p>2.3.7.  maintain complete and accurate records and information to demonstrate its compliance with this Section
              3 and allow for audits by you or your designated auditor. Without prejudice to the generality of Section
              3.2, you will ensure that you have all necessary appropriate consents and notices in place to enable
              lawful transfer of the personal data to HW for the duration and purposes of these T&Cs so that HW may
              lawfully use, process and transfer the personal data in accordance with these T&Cs on your behalf. </p>
          </div>
        </p>
        <p>2.4.  Each party shall implement such technical and organisational security measures as are required to comply with
          the data security obligations under Data Protection Legislation.</p>
        <p>2.5.  HW is authorised to engage sub-processors to undertake processing on its behalf and shall ensure that a
          written contract exists between HW and the sub-processor containing Sections equivalent to those imposed on
          HW in this Section. In the event that any sub-processor fails to meet its data protection obligations, HW shall
          remain fully liable to you for the performance of the sub-processor’s obligations.</p>
      </div>

      <div className={classes.subHeader}>3. THIRD PARTY</div>
      <div className={classes.text}>
        <p>3.1.  You acknowledge that the PMS System functionality may enable or assist you to access products, services,
          information, data, content and other materials made available by or on behalf of third parties (<strong>“Third Party
          Materials”</strong>) or allow for the routing or transmission of Third Party Materials, including via links, and that you do
          so solely at your own risk. By using such functionality, you are directing HW to access, route and transmit to you
          the applicable Third Party Materials. In addition, your integration and other use of Third Party Materials may
          result in transmission of your information, data, content or other materials outside of the PMS System, or
          otherwise result in third-party access to or use of your information, data, content or other materials.</p>
        <p>3.2.  Certain Third Party Materials may, among other things, be inaccurate, misleading or deceptive. Nothing in these
          T&Cs will be deemed to be a representation or warranty by HW with respect to any Third Party Materials. HW
          neither controls nor endorses, nor is HW responsible for, any Third Party Materials, including their accuracy,
          validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or safety, or any applicable
          intellectual property rights. HW has no obligation to monitor Third Party Materials. In addition, the availability for
          integration of any Third Party Materials through the PMS System does not imply HW’s endorsement of, or
          affiliation with, any provider of such Third Party Materials, nor does it create any legal relationship between you
          and any such provider. HW makes no representation, warranty or commitment and shall have no liability or
          obligation whatsoever in relation to the content or use of, or correspondence with, any such Third Party
          Materials, or any transactions completed, and any contract entered into by you, with any such third party.</p>
        <p>3.3.  Any contract entered into and any transaction completed with any third-party is between you and the relevant
          third party, and not HW. Your use of Third Party Materials is at your own risk and is subject to any additional
          terms, conditions and policies applicable (<strong>“Third Party T&Cs</strong>) to such Third Party Materials. HW recommends
          that you refer to such Third Party T&Cs prior to integrating and/or using any Third Party Materials.</p>
      </div>

      <div className={classes.subHeader}>4. HW'S OBLIGATIONS</div>
      <div className={classes.text}>
        <p>4.1.  HW undertakes that the PMS System will be provided substantially in accordance with the Documentation and
        with reasonable skill and care.</p>
        <p>4.2.  The undertaking at Section 4.1 shall not apply to the extent of any non-conformance which is caused by use of
        the PMS System contrary to HW’s instructions, or modification or alteration of the PMS System by any party
        other than HW or HW’s duly authorised contractors or agents. If the PMS System does not conform with the
        foregoing undertaking, HW will use all reasonable commercial endeavours to correct any such non-conformance
        promptly or provide you with an alternative means of accomplishing the desired performance. Such correction or
        substitution constitutes your sole and exclusive remedy for any breach of the undertaking set out in Section 4.</p>
        <p>4.3.  These T&Cs shall not prevent HW from entering into similar agreements with third parties, or from
        independently developing, using, selling or licensing documentation, products and/or services which are similar
        to those provided under these T&Cs.</p>
        <p>4.4.  HW is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of
        data over communications networks and facilities, including the internet, and you acknowledges that the PMS
        System and Documentation may be subject to limitations, delays and other problems inherent in the use of such
        communications facilities.</p>
        <p>4.5.  HW does not warrant that:
          <div className={classes.subText}>
            <p>4.5.1.  your use of the PMS System will be uninterrupted or error-free;</p>
            <p>4.5.2.  the PMS System, Documentation and/or the information obtained by you through the PMS System will
              meet your requirements; or </p>
            <p>4.5.3.  the PMS System will be free from Vulnerabilities.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>5. YOUR OBLIGATIONS</div>
      <div className={classes.text}>
        <p>5.1.  You shall:
          <div className={classes.subText}>
            <p>5.1.1.  in order to enable HW to provide the PMS System, including but not limited to Customer Data, security
              access information and configuration services, provide HW with:
              <div className={classes.subText}>
                <p>(i)  all necessary co-operation in relation to these T&Cs ; and</p>
                <p>(ii)  all necessary access to such information as may be required by HW;</p>
              </div>
            </p>
            <p>5.1.2.  without affecting your other obligations under these T&Cs, comply with all applicable laws and regulations
              with respect to its activities under these T&Cs ;</p>
            <p>5.1.3.  carry out all your other responsibilities set out in these T&Cs in a timely and efficient manner. In the
              event of any delays in your provision of such assistance as agreed by the parties, HW may adjust any
              agreed timetable or delivery schedule as reasonably necessary;</p>
            <p>5.1.4.  ensure that the PMS System and the Documentation are used in accordance with the terms and
              conditions of these T&Cs and you shall be responsible for any breach of these T&Cs resulting from a
              misuse of the PMS System and/or the Documentation;</p>
            <p>5.1.5.  obtain and shall maintain all necessary licences, consents, and permissions necessary for HW, your
              contractors and agents to perform their obligations under these T&Cs, including without limitation the
              PMS System;</p>
            <p>5.1.6.  ensure that your network and systems comply with the relevant specifications provided by HW from time to
              time; and</p>
            <p>5.1.7.  be, to the extent permitted by law and except as otherwise expressly provided in these T&Cs, solely
              responsible for procuring, maintaining and securing your network connections and telecommunications
              links from your systems to HW's data centres, and all problems, conditions, delays, delivery failures and
              all other loss or damage arising from or relating to your network connections or telecommunications links
              or caused by the internet.</p>
          </div>
        </p>
        <p>5.2.  You warrant that you possess (and undertake to maintain throughout the Term) all such licences,
          permissions, permits and approvals as are necessary so as to allow accommodation and experiences made
          available on the PMS at your Property to be provided lawfully to customers (including, without limitation, all
          such health and safety licences, animal welfare laws, permissions, permits and approvals as are required by
          any relevant authority).</p>
      </div>

      <div className={classes.subHeader}>6. PROPRIETARY RIGHTS</div>
      <div className={classes.text}>
        <p>6.1.  You acknowledge and agree that HW and/or its licensors own all intellectual property rights in the PMS
          System and the Documentation. Except as expressly stated in these T&Cs, these T&Cs do not grant you
          any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks
          (whether registered or unregistered), or any other rights or licences in respect of the PMS System or the
          Documentation.</p>
        <p>6.2.  HW confirms that it has all the rights in relation to the PMS System and the Documentation that are
          necessary to grant all the rights it purports to grant under, and in accordance with, the T&Cs of these
          T&Cs.</p>
      </div>

      <div className={classes.subHeader}>7. CONFIDENTIALITY</div>
      <div className={classes.text}>
        <p>7.1.  Each party may be given access to Confidential Information from the other party in order to perform its
          obligations under these T&Cs. A party’s Confidential Information shall not be deemed to include information
          that:
          <div className={classes.subText}>
            <p>7.1.1.  is or becomes publicly known other than through any act or omission of the receiving party;</p>
            <p>7.1.2.  was in the other party’s lawful possession before the disclosure;</p>
            <p>7.1.3.  is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</p>
            <p>7.1.4.  is comprised of aggregated non-personal data; or</p>
            <p>7.1.5.  is independently developed by the receiving party, which independent development can be shown by
            written evidence.</p>
          </div>
        </p>

        <p>7.2.  Subject to Section 7.4, each party shall hold the other’s Confidential Information in confidence and not make the
          other’s Confidential Information available to any third party or use the other’s Confidential Information for any
          purpose other than the implementation of these T&Cs.</p>
        <p>7.3.  Each party shall take all reasonable steps to ensure that the other’s Confidential Information to which it has
          access is not disclosed or distributed by its employees or agents in violation of the T&Cs of these T&Cs.</p>
        <p>7.4.  A party may disclose Confidential Information to the extent such Confidential Information is required to be
          disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent
          jurisdiction, provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of
          such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this
          Section 8.4, it takes into account the reasonable requests of the other party in relation to the content of such
          disclosure.</p>
        <p>7.5.  You acknowledge that details of the PMS System, and the results of any performance tests of the PMS System,
          constitute HW’s Confidential Information.</p>
        <p>7.6.  Neither party shall make, or permit any person to make, any public announcement concerning these T&Cs
          without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed),
          except as required by law, any governmental or regulatory authority (including, without limitation, any relevant
          securities exchange), any court or other authority of competent jurisdiction.</p>
        <p>7.7.  The above provisions of this Section 7 shall survive termination of these T&Cs, however arising.</p>
      </div>

      <div className={classes.subHeader}>8. INDEMNITY</div>
      <div className={classes.text}>
        <p>8.1.  You shall defend, indemnify and hold harmless HW against claims, actions, proceedings, losses, damages,
          expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in
          connection with your use of the PMS System and/or Documentation.</p>
        <p>8.2.  In no event shall HW, its employees, agents and sub-contractors be liable to you to the extent that the alleged
          infringement is based on:
          <div className={classes.subText}>
            <p>8.2.1.  a modification of the PMS System or Documentation by anyone other than HW or its agents,
              subcontractors or partners or with HW’s consent or approval; or</p>
            <p>8.2.2.  your use of the PMS System or Documentation otherwise than in accordance with the Documentation; or</p>
            <p>8.2.3.  your use of the PMS System or Documentation after notice of the alleged or actual infringement from
              HW or any appropriate authority.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>9. LIMITATION OF LIABILITY</div>
      <div className={classes.text}>
        <p>9.1. Except as expressly and specifically provided in these T&Cs:
          <div className={classes.subText}>
            <p>9.1.1. you assume sole responsibility for results obtained from the use of the PMS System and the
            Documentation by you, and for conclusions drawn from such use. HW shall have no liability for any
            damage caused by errors or omissions in any information, instructions or scripts provided to us by you in
            connection with the PMS System, or any actions taken by HW at your direction;</p>
            <p>9.1.2. all warranties, representations, conditions and all other T&Cs of any kind whatsoever implied by statute
            or common law are, to the fullest extent permitted by applicable law, excluded from these T&Cs ; and</p>
            <p>9.1.3. the PMS System and the Documentation are provided to you on an "as is" basis.</p>
          </div>
        </p>
        <p>9.2 Nothing in these T&Cs excludes the liability of HW:
          <div className={classes.subText}>
            <p>9.2.1. for death or personal injury caused by HW’s negligence; or</p>
            <p>9.2.2. for fraud or fraudulent misrepresentation.</p>
          </div>
        </p>
        <p>9.3 Subject to Section 9.1 and Section 9.2:
          <div className={classes.subText}>
            <p>9.3.1. HW shall not be liable whether in tort (including for negligence or breach of statutory duty), contract,
            misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill
            and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any
            special, indirect or consequential loss, costs, damages, charges or expenses however arising under
            these T&Cs ; and</p>
            <p>9.3.2. HW’s total aggregate liability in contract, tort (including negligence or breach of statutory duty),
            misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated
            performance of these T&Cs shall be limited to USD$5,000.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>10.  TERM AND TERMINATION</div>
      <div className={classes.text}>
        <p>10.1. These T&Cs shall, unless otherwise terminated as provided in this Section 10, commence on the date you click
          to accept these T&Cs and shall continue in effect for 12 months (the “Term”) and, thereafter, these T&Cs shall
          be automatically renewed for successive periods of 12 months (each a Renewal Period), unless earlier
          terminated pursuant to this Section 10 or either party gives the other party written notice of non-renewal at least
          30 days prior to the expiration of the then-current term. HW may terminate or suspend your use of the PMS
          System at any time and without prior notice.</p>
        <p>10.2. Without affecting any other right or remedy available to it, either party may terminate these T&Cs with
          immediate effect by giving written notice to the other party if:
          <div className={classes.subText}>
            <p>10.2.1. the other party commits a material breach of any other term of these T&Cs which breach is irremediable
            or (if such breach is remediable) fails to remedy that breach within a period of 14 days after being
            notified in writing to do so;</p>
            <p>10.2.2. becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due</p>
            <p>10.2.3. the other party undergoes a change of control in favour of a direct competitor of the other party and fails
            to inform the other party in writing prior to the change taking effect, the other party may terminate these
            T&Cs effective immediately upon the date of the Change of Control the other party suspends or ceases,
            or threatens to suspend or cease, carrying on all or a substantial part of its business.</p>
          </div>
        </p>

        <p>10.3. On termination of these T&Cs for any reason:
          <div className={classes.subText}>
            <p>10.3.1. you shall immediately cease all use of the PMS System and/or the Documentation;</p>
            <p>10.3.2. each party shall return and make no further use of any equipment, property, documentation and other
            items (and all copies of them) belonging to the other party;</p>
            <p>10.3.3. HW may destroy or otherwise dispose of any of your Customer Data in its possession in accordance
            with Section 3.3(v), unless HW receives, no later than 10 days after the effective date of the termination
            of these T&Cs, a written request for the delivery to you of the then most recent back-up of your
            Customer Data. HW shall use reasonable commercial endeavours to deliver the back-up to you within 30
            days of its receipt of such a written request. You shall pay all reasonable expenses incurred by HW in
            returning or disposing of Customer Data; and</p>
            <p>10.3.4. any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of
            termination, including the right to claim damages in respect of any breach of the agreement which
            existed at or before the date of termination shall not be affected or prejudiced.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>11.  ANIMAL WELFARE</div>
      <div className={classes.text}>
        <p>11.1. HW is opposed any experience that promotes and involves intentional direct contact with wild animals in their
          natural habitat, including, petting, feeding, riding animals or similar practices (the “Prohibited Practices”). HW is
          committed to ensuring that all our accommodation partners around the world work also to ensure the highest
          quality of life for any animals involved in their experiences. In doing this, we are guided by (and require our
          partners to respect) the internationally accepted standards of care for animals, the Five Freedoms for Animals –
          (i) freedom from hunger or thirst with access to fresh water and an appropriate diet to maintain full health; (ii)
          freedom from discomfort by providing an appropriate environment including shelter and a comfortable resting
          area; (iii) freedom from pain, injury, or disease by treating animals respectfully and providing prompt access to
          veterinary care; (iv) freedom to express normal behaviour by providing sufficient space, proper facilities, and
          allowing for company of the animal's own kind; and (v) freedom from fear and distress by ensuring conditions
          and treatment which avoid mental suffering.</p>
        <p>11.2. Accordingly, you hereby confirm that (i) neither you nor any of your officers, employees or other persons
          associated with you have been or is the subject of any investigation, inquiry or enforcement proceedings by any
          governmental, administrative or regulatory body regarding any offence or alleged offence of or in connection
          with the Prohibited Practices; (ii) you shall comply with all applicable animal welfare laws, statutes, regulations
          and codes; and (iii) you shall ensure that animals (including but not limited to animals of whatever species for
          any form of entertainment*) shall not be caused any harm, suffering or distress as a result of any experiences,
          activities or events promoted, managed, arranged or organised by you.</p>
        <p>* ‘Entertainment’ is an inclusive term taking in all form of animal acts.</p>
      </div>

      <div className={classes.subHeader}>12.  GENERAL</div>
      <div className={classes.text}>
        <p>12.1. HW shall have no liability to you under these T&Cs if it is prevented from or delayed in performing its
          obligations under these T&Cs, or from carrying on its business, by acts, events, omissions or accidents beyond
          its reasonable control, including, without limitation, infectious diseases, epidemics, pandemics or quarantine,
          strikes, lock-outs or other industrial disputes, act of God, war, riot, civil commotion, malicious damage,
          compliance with any law or governmental order, rule, regulation, action or direction, accident, breakdown of
          plant or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that you is notified of
          such an event and its expected duration.</p>
        <p>12.2. HW may update or modify these T&Cs (including any referenced policies and other documents) from time to
          time. Any such amendments will take effect ten (10) days from the date of posting by us and your continued use
          of the PMS System after the effective date of the revised T&Cs constitutes your acceptance of such revised
          T&Cs.</p>
        <p>12.3. No failure or delay by a party to exercise any right or remedy provided under these T&Cs or by law shall
          constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that
          or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the
          further exercise of that or any other right or remedy.</p>
        <p>12.4. Except as expressly provided in these T&Cs, the rights and remedies provided under these T&Cs are in
          addition to, and not exclusive of, any rights or remedies provided by law.</p>
        <p>12.5. If any part of these T&Cs is determined to be invalid or unenforceable, then the invalid or unenforceable
          provision shall be deemed to be superseded by any such valid, enforceable provision that most closely matches
          the intent of the original provision and the remainder of these T&Cs shall continue in effect.</p>
        <p>12.6. These T&Cs constitute the entire agreement between the parties and supersedes and extinguishes all previous
          agreements, promises, assurances, warranties, representations, and understandings between them, whether
          written or oral, relating to its subject matter. Each party acknowledges that in entering into these T&Cs it does
          not rely on, and shall have no remedies in respect of, any statement, representation, assurance or warranty
          (whether made innocently or negligently) that is not set out in these T&Cs. Each party agrees that it shall have
          no claim for innocent or negligent misrepresentation based on any statement in these T&Cs.</p>
        <p>12.7. You shall not, without the prior written consent of HW, assign, transfer, charge, sub-contract or deal in any other
          manner with all or any of your rights or obligations under these T&Cs. HW may at any time assign, transfer,
          charge, sub-contract or deal in any other manner with all or any of its rights or obligations under these T&Cs.</p>
        <p>12.8. Nothing in these T&Cs is intended to or shall operate to create a partnership between the parties, or authorise
          either party to act as agent for the other, and neither party shall have the authority to act in the name or on
          behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any
          representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</p>
        <p>12.9. These T&Cs and any dispute or claim arising out of or in connection with it or its subject matter or formation
          (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of
          Ireland. Each party irrevocably agrees that the courts of Ireland shall have exclusive jurisdiction to settle any
          dispute or claim arising out of or in connection with these T&Cs or its subject matter or formation (including
          non-contractual disputes or claims).</p>
      </div>

      <div className={classes.subHeader}>12.  DEFINITIONS</div>
      <div className={classes.text}>
        <p>12.1. The definitions and rules of interpretation in this Section apply in these T&Cs.
          <div className={classes.subText}>
            <p><strong>Business Day</strong>:  a day other than a Saturday, Sunday or public holiday in Ireland when banks in Dublin are
            open for business.</p>
            <p><strong>Control</strong>:  shall be as defined in Section 432 of the Tax Consolidation Acts 1997.</p>
            <p><strong>Confidential Information</strong>:  information that is proprietary or confidential and is either clearly labelled as such or
            identified as Confidential Information in Section 7.</p>
            <p><strong>Controller, processor, data subject, personal data, personal data breach, processing and appropriate
            technical and organisational measures</strong>:  as defined in the Data Protection Legislation.</p>
            <p><strong>Customer Data</strong>: the data inputted by you or HW on your behalf for the purpose of using the Services or
            facilitating your use of the Services and any data generated by, or derived from your use of the Services,
            whether hosted or stored within the Services or elsewhere.</p>
            <p><strong>Data Protection Legislation</strong>: all applicable data protection and privacy legislation in force from time to time in
            the Ireland including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the
            Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended and any other European
            Union legislation relating to personal data and all other legislation and regulatory requirements in force from
            time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy
            of electronic communications);</p>
            <p><strong>Documentation</strong>:  the document made available to you by HW which sets out a description of the PMS System
            and the user instructions for the PMS System.</p>
            <p><strong>Intellectual Property Rights</strong>:  patents, rights to inventions, copyright and related rights, moral rights,
            trademarks and service marks, business names and domain names, rights in get-up, goodwill and the right to
            sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to
            use, and protect the confidentiality of, confidential information (including know-how and trade secrets) and all
            other intellectual property rights, in each case whether registered or unregistered and including all applications
            and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights
            and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any
            part of the world.</p>
            <p><strong>Normal Business Hours</strong>:  8.00 am to 6.00 pm local Irish time, each Business Day.</p>
            <p><strong>PMS System</strong>: the cloud-based property management software application and platform provided by HW to you
            under these T&Cs via <a href="https://workspace.counter.app/pms-terms" className={classes.link} target="_blank">https://workspace.counter.app/pms-terms</a> or 
            any other website notified to you by HW from
            time to time, as more particularly described in the Documentation.</p>
            <p><strong>Vulnerability</strong>:  a weakness in the computational logic (for example, code) found in software and hardware
            components that when exploited, results in a negative impact to the confidentiality, integrity, or availability, and the
            term Vulnerabilities shall be construed accordingly.</p>
          </div>
        </p>
      </div>

      <div className={classes.text}>
        Last updated: 2 September 2020
      </div>


    </div>
  )
} 