import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD TAX REPORTS
//----------------------------------------------------------------
export function fetchTaxInfo(data) {
  const ACTION_NAME = "FETCH_TAX_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/frontdesk/reports/tax`, data)
        .then(successResult => {
            const { data } = successResult;
            dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        })
        .catch(errorResult => {
            console.log('failure', errorResult);
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            return;
        });
  };
}

export const resetTaxReport = () => ({
  type: 'RESET_TAX_REPORT'
})