import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { ERRORS } from '../../../utils/errors';
// import { ICONS } from '../../../utils/imageUrls';

import Navigation from '../../common/Navigation';
import SelectRoomType from './SelectRoomType';
import Breadcrumb from '../../common/Breadcrumb';
import BulkUpdateDetails from './BulkUpdateDetails';

import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  Snackbar,
  DialogActions
} from '@material-ui/core';


import CloseIcon from '@material-ui/icons/Close';
import { bulkUploadRates } from '../../../redux/actions/rates/rates';
import { resetError } from '../../../redux/actions/beds/calendar';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    background: '#F8F9FB',
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
    },
  },
  root: {
    background: '#F8F9FB',
    borderRadius: '15px',
    width: '100%'
  },
  dialogTitle: {
    fontSize: "22px",
    fontWeight: 600
  },
  dialogContent: {
    background: '#F8F9FB',
    overflow: 'visible',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
    }
  },
  error: {
    color: 'red',
    marginTop: 20,
    marginRight: 5
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 30,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  dialogAction: {
    background: '#F8F9FB',
    padding: '8px 48px 30px 48px'
  },
  Button: {
    background: '#000',
    color: '#fff',
    width: 100,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
}))


const STEPS = {
  1: 'Next',
  2: 'Submit'
}

const BulkUpdate = forwardRef((props, ref) => {

  const classes = useStyles();
  const { open, handleClose, roomTypes, rooms, currentSpace, beds, rates, ratePlans } = props;
  const [breadcrumbList, setBreadcrumbList] = React.useState({});
  const [step, setStep] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [roomList, setRoomList] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);
  const [buttonText, setButtonText] = React.useState('Next');

  const roomRef = React.createRef();
  const bulkRef = React.createRef();
  let dialogContentNode = null;


  React.useEffect(() => {
    if(props.success) {
      props.dispatch(resetError('BULK_UPLOAD_RATES'))
      handleClose();
    }
  }, [props.success])

  // ----- Check for required fields validation -----
  const handleFormvalidation = (bulkData) => {
    let hasError = false;
    if (
      !bulkData.roomTypeIDs ||
      !bulkData.dateRange ||
      !bulkData.dateRange.length
    )
      hasError = true;
    if (bulkData.minLOS && bulkData.maxLOS && bulkData.minLOS > bulkData.maxLOS)
      hasError = true;
    return hasError;
  }

  // -------- Submit bulk update -----------
  const handleSubmit = (bulkData) => {
    setError(false);
    let payload = {...bulkData, roomTypeIDs: [...roomList], propertyID: currentSpace.propertyID};
    const hasError = handleFormvalidation(payload);
    if (hasError) {
      setError(true);
      setErrorMessage('Please complete the form.')
      dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return false;
    }
    const response = props.dispatch(bulkUploadRates(payload));
    response.then(success =>{
      if(success) props.reloadRatesViewHandler();
    });
  }

  // -------- Go to previous step ------------
  const handlePrev = (currentStep) => {
    let textOfButton = buttonText;
    switch (currentStep) {
      case 1: textOfButton = 'Next';
        break;
    }
    setError(false);
    setErrorMessage('');
    setStep(currentStep);
    setButtonText(textOfButton);
  }

  // ----------- Go to next step -------------
  const handleNext = () => {
    let hasError = false;
    let roomTypeData = {};
    let bulk = {};
    let currentStep = step;
    let breadcrumbList = { ...breadcrumbList };

    switch (step) {
      case 1: roomTypeData = roomRef.current.handleProcessData();
        if (roomTypeData && roomTypeData.length) {
          setRoomList(roomTypeData)
          roomTypeData.map((item, index) => {
            let icon;
            if (roomTypes[item].dormOrPrivate === 'private') icon = IMAGES.ICONS.darkBed;
            else icon = IMAGES.ICONS.darkHostel;
            breadcrumbList[index] = { step: 1, text: roomTypes[item].name, icon: icon }
          })    
        } else if (!roomTypeData.length) {
          hasError = true;
          setError(true);
          setErrorMessage('Please select a room');
        }
        break;
      case 2: bulk = bulkRef.current.handleProcessData();
        handleSubmit(bulk)
        break;
      default: ;
    }
    if (!hasError && step !== 2) {
      currentStep++;
      setStep(currentStep);
      setButtonText(STEPS[currentStep])
      setError(false);
      setErrorMessage('');
      setBreadcrumbList(breadcrumbList)
    };
    dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialogContainer}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901} ref={ref}>

      <div className={classes.root}>

        <DialogContent>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <div ref={node => { dialogContentNode = node; }}>
            <DialogContent className={classes.dialogContent}>
              {/* ------------ Navigating back to previous screen and show title ------------ */}
              <Navigation step={step} title={"Bulk Rate Update"} onChangePrev={handlePrev} />
              {error && (<div className={classes.error}>{errorMessage}</div>)}
              {step > 1 && (<Breadcrumb list={breadcrumbList} onChangePrev={handlePrev} />)}

              {/* ---------- Form data --------------  */}
              {step === 1 && <SelectRoomType currentSpace={currentSpace} roomTypes={roomTypes} rooms={rooms} itemName={'rooms'} ref={roomRef} multiple={false} />}
              {step === 2 && <BulkUpdateDetails 
                ref={bulkRef} 
                rates={rates} 
                ratePlans={ratePlans}  
                selectedRoomTypesIDs={roomList} 
                currentSpace={currentSpace}
              />}
            </DialogContent>
          </div>
        </DialogContent>

        {/* ------------ Action Button ------------ */}
        <DialogActions className={classes.dialogAction}>
          <Button className={classes.Button} onClick={() => handleNext()}>
            {buttonText}
          </Button>
        </DialogActions>
      </div>

      {/* ------------- Loder -------------- */}
      {props.loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => handleCloseError()}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{ERRORS[props.errors] || props.errors}</span>}
        />
      )}
    </Dialog>
  )
})

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
  beds: state.beds,
  errors: state.errors.BULK_UPLOAD_RATES,
  loading: state.loading.BULK_UPLOAD_RATES,
  success: state.success.BULK_UPLOAD_RATES,
  rooms: state.rooms,
  rates: state.rates,
  ratePlans: state.ratePlans,
})

export default connect(mapStateToProps)(BulkUpdate);