import React, { useEffect } from 'react';
import {Bar} from 'react-chartjs-2';

const dataDefaults = {
  datasets: [{
      type: 'bar',
      label: 'Count',
      fill: false,
      borderColor: '#AEC1E1',
      backgroundColor: '#AEC1E1',
      yAxisID: 'count'
    },{
      label: 'Revenue',
      type:'bar',
      fill: false,
      backgroundColor: '#4F9581',
      borderColor: '#4F9581',
      yAxisID: 'revenue'
    }]
};

const optionDefaults = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'revenue',
        gridLines: {
          display: false
        },
        labels: {
          show: true
        },
        ticks: {
          beginAtZero: true
        },
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'count',
        gridLines: {
          display: true
        },
        labels: {
          show: true
        },
        ticks: {
          beginAtZero: true
        },
      }
    ]
  }
};

export default (props) => {
  
  const [graph, setGraph] = React.useState({data: dataDefaults, options: optionDefaults});

  useEffect(() => {
    if (props.inputs && props.inputs.labels.length && props.inputs.leftAxis.length && props.inputs.rightAxis.length) {
      let tempData = graph.data;
      let tempOptions = graph.options;
      tempData.labels = props.inputs.labels;
      tempData.datasets[1].data = props.inputs.leftAxis;
      tempData.datasets[0].data = props.inputs.rightAxis;
      setGraph({data: {...tempData}, options: {...tempOptions}});
    }
  }, [props.inputs])

  return (
    graph.data?.labels?.length ? <Bar
      data={graph.data}
      options={graph.options}
    /> : null
  );
}