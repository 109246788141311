import React from 'react';
import { InputBase } from '@material-ui/core';
import { processNumberInput } from '../../utils/utility';

const NumberInput = (props) => {
  const { id, name, value, min, max, disabled, startAdornment, endAdornment, className } = props;

  const onInputChange = (e) => {
    let input = e.target.value;
    if(min === 0)
      input = processNumberInput(input);
    else if (input != '-')
      input = processNumberInput(input);

    props.onChange({ target: { value: input, name: name } });
  }

  // const processInput = (input) => {
  //   if(input.length === 2 && input.charAt(0) === "0" && input.charAt(1) !== "." ){
  //     input = parseInt(input);
  //   }
  //   while (isNaN(input)) {
  //     input = input
  //       .split('')
  //       .reverse()
  //       .join('')
  //       .replace(/[\D]/i, '')
  //       .split('')
  //       .reverse()
  //       .join('');
  //     // input = parseFloat(input)
  //   }
  //   return input
  // }

  return (
    <InputBase
      id={id || name}
      name={name || id}
      value={value}
      disabled={disabled}
      fullWidth
      type="text"
      onChange={(e) => onInputChange(e)}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      className={className}
    />
  )
}

export default NumberInput;