import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { getReservationDetails, resetReservationDetails } from '../../redux/actions/beds/beds';
import Reservation from '../reservations/Reservation';
import { getCurrency } from '../../utils/utility'; 

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
  },
  tableRow: {
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#F6F2EE !important',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const CustomerList = (props) => {
  const { t } = props;
  const classes = useStyles();
  const {transactions, currencyData, transactionModes, loadingTransactionModes} = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [openReservation, setOpenReservation] = React.useState(false);

  const handleOpenReservation = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true)
  }

  const handleCloseReservation = () => {
    props.dispatch(resetReservationDetails());
    setOpenReservation(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, transactions.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {transactions.length===0 && 
        <div className={classes.blankSlate}>
          <Typography variant="body1">{t('live.noFrontdeskTransactionData')}</Typography>
        </div>}

      {transactions.length !== 0 && !loadingTransactionModes && <Paper className={classes.paper} square={true}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              {stableSort(transactions, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      onClick={() => handleOpenReservation(row.resID)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">{row.amount && getCurrency(currencyData, Math.round(row.amount))}</TableCell>
                      <TableCell align="right">{row.modeID && transactionModes[row.modeID] && transactionModes[row.modeID].name}</TableCell>
                      {/* <TableCell>{moment(row.createdAt).format()}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={transactions.length}
          rowsPerPage={6}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
        />

        {/* Open reservation when transaction clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && 
          <Reservation 
            open={openReservation}
            handleCloseReservation={handleCloseReservation} 
          />
        }
      </Paper>}
    </div>
  );
}

const mapStateToProps = state => ({
  transactionModes: state.transactionModes,
  loadingTransactionModes: state.loading.LOAD_TRANSACTION_MODES
})

export default withTranslation()(connect(mapStateToProps)(CustomerList));