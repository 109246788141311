import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { toTitleCase, truncateText } from '../../utils/wordUtils';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Close';
import { deleteNoticeboardItem } from '../../../redux/actions/experiences/hwExperiences';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    padding: theme.spacing(5),
    width: 'calc(100% - 80px)',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  categoryIcon: {
    opacity: 0.5,
    height: 40,
    width: 40
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: '#333333',
    fontWeight: 600,
    fontSize: '1.4rem',
    marginBottom: 10,
  },
  description: {
    color: '#999999',
    fontSize: '1.2rem',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  incompleteButton: {
    background: '#3144BD',
    border: '0px solid #999999',
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    fontWeight: 600,
    padding: '10px 20px',
  },
  deleteIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    marginLeft: 10,
    opacity: 0.7,
    padding: theme.spacing(1),
    '& img': {
      height: 20,
      width: 20,
    },
    '&:hover': {
      background: '#F0F0F0'
    }
  },
  deleteContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  warningText: {
    fontSize: '1.2rem',
    color: 'red',
    marginRight: 10,
  },
  smallIcon: {
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '50%',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 5,
    padding: 3,
    '& svg': {
      fontSize: '1.8rem',
    },
    '&:hover': {
      background: '#E0E0E0',
    }
  }
}));

const categories = [
  {category: 'ACTIVITY', icon: 'images/experiences/noticeboard-activity.png'},
  {category: 'BEERPONG', icon: 'images/experiences/noticeboard-beer.png'},
  {category: 'COMEDY', icon: 'images/experiences/noticeboard-comedy.png'},
  {category: 'COMMUNITY', icon: 'images/experiences/noticeboard-community.png'},
  {category: 'COOKING', icon: 'images/experiences/noticeboard-cooking.png'},
  {category: 'DAYTRIP', icon: 'images/experiences/noticeboard-day.png'},
  {category: 'DRINK', icon: 'images/experiences/noticeboard-drink.png'},
  {category: 'EVENTTOUR', icon: 'images/experiences/noticeboard-event.png'},
  {category: 'FOOD', icon: 'images/experiences/noticeboard-food.png'},
  {category: 'GAMES', icon: 'images/experiences/noticeboard-games.png'},
  {category: 'KARAOKE', icon: 'images/experiences/noticeboard-karaoke.png'},
  {category: 'MOVIE', icon: 'images/experiences/noticeboard-movie.png'},
  {category: 'MUSIC', icon: 'images/experiences/noticeboard-music.png'},
  {category: 'PARTY', icon: 'images/experiences/noticeboard-party.png'},
  {category: 'SUSTAINABILITY', icon: 'images/experiences/noticeboard-sustainability.png'},
  {category: 'WALKINGTOUR', icon: 'images/experiences/noticeboard-walking.png'},
  {category: 'YOGA', icon: 'images/experiences/noticeboard-yoga.png'}
]

const ExperiencePreview = (props) => {
  const classes = useStyles();
  const [deleteItem, setDeleteItem] = React.useState(false);
  const { experience, setupExperience } = props;

  const handleDelete = () => {
    props.dispatch(deleteNoticeboardItem(experience._id))
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={6}>
        <Grid item xs={2} md={2} className={classes.iconContainer}>
          <img 
            className={classes.categoryIcon} 
            src={
              categories.find(e => e.category === experience.category) ? 
              categories.find(e => e.category === experience.category).icon : 
              '/images/layout/experiences.png'
            } 
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.detailContainer}>
            <div className={classes.title}>{toTitleCase(experience.title)}</div>
            <div className={classes.description}>{truncateText(experience.description, 150)}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          {!deleteItem && <div className={classes.buttonContainer}>
            <div 
              className={classes.incompleteButton}
              onClick={() =>setupExperience(experience)}
            >
              Setup
            </div>
            <div className={classes.deleteIcon} onClick={() => setDeleteItem(true)}>
              <img src="/images/icons/bin.png" />
            </div>
          </div>}
          {deleteItem && <div className={classes.deleteContainer}>
            <div className={classes.warningText}>Delete this item?</div>
            <div className={classes.smallIcon} onClick={() => setDeleteItem(false)}><CancelIcon /></div>
            <div className={classes.smallIcon} onClick={handleDelete}><CheckIcon /></div>
          </div>}
        </Grid>
      </Grid>
    </div>
  )
}

export default connect()(ExperiencePreview);