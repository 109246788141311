import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ViewCardModal from '../reservation-cards/ViewCardModal';
import OneFieldForm from '../../../common/OneFieldForm';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid #C87250',
        borderRadius: '0 5px 5px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0 0px 5px 5px',
        },
    },
    closeHeader: {
        textAlign: 'end',
    },
    form: {
        padding: '0 20px 20px'
    },
    formInput: {
        border: '1px solid #dadada',
        padding: '10px 10px 10px 15px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'calc(25% - 32px)',
        marginRight: 5,
        borderRadius: 30,
        justifyContent: 'space-between',
    },
    data: {
        width: '80%',
        wordBreak: 'break-word',
        '&.fullWidth': {
            width: '100%',
        }
    },
    icon: {
        width: '20%'
    },
    label: {
        fontSize: '1.1rem'
    },
    value: {
        fontWeight: 600
    },
    actionIcon: {
        cursor: 'pointer',
        width: 20
    },
    row: {
        display: 'flex',
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '& > div': {
                marginBottom: 10
            }
        },
    },
    action: {
        cursor: 'pointer',
    },
    actionInput: {
        cursor: 'pointer',
        textAlign: 'center',
        color: '#C87250',
        backgroundColor: '#f2f2f2',
        '& > *': {
            width: '100%'
        }
    },
    tags: {
        border: '1px solid #dadada',
        backgroundColor: '#f2f2f2',
        padding: '5px 10px',
        marginRight: 5,
        minWidth: 50,
        textAlign: 'center',
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        transition: '150ms',
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    tagCloseIcon: {
        width: 15,
        height: 15,
        cursor: 'pointer',
    }
}));

const FormData = ({
    classes,
    fieldName,
    keyName,
    value,
    editableFieldHandler,
    editableFields,
    editOption
}) => {
    return (
        <React.Fragment>
            {!editableFields[keyName] ?
                <div className={classes.formInput}>
                    <div className={clsx(classes.data, { ['fullWidth']: !editOption })}>
                        <div className={classes.label}>{fieldName}</div>
                        <div className={classes.value}>{value}</div>
                    </div>
                    {editOption &&
                        <div className={classes.icon}>
                            <img
                                className={classes.actionIcon}
                                src={IMAGES.ICONS.editPencil}
                                onClick={() => editableFieldHandler(keyName, true)}
                            />
                        </div>
                    }
                </div>
                :
                <div className={clsx(classes.data, 'fullWidth')}>
                    <div className={classes.label}>{fieldName}</div>
                    <OneFieldForm handleClose={() => editableFieldHandler(keyName, false)} handleSubmit={(value) => void 0} />
                </div>
            }
        </React.Fragment>
    )
}

const DetailsForm = (props) => {
    const classes = useStyles();
    const { bookedExperience, maskedCards, currentSpace } = props;
    const [showCard, setShowCard] = React.useState(false);
    const [showTagForm, setShowTagForm] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [editableFields, setEditableFields] = React.useState({});
    const isCardAdded = maskedCards && maskedCards.length > 0;

    const data = {
        bookingSource: bookedExperience.bookingSource,
        bookingCommission: '-',
        cancellationPolicy: '-',
        bookingID: bookedExperience.reference,
        ratePlan: bookedExperience?.products?.[0]?.grade?.title,
        reservationID: bookedExperience.reservationID,
        tags: []
    }

    const validateShowCard = () => {
        return true;
        // const allowedStatus = ['plus', 'pro'];
        // if (allowedStatus.includes(currentSpace.onboardingStatus)) return true;
        // return;
    }

    const viewCardHandler = () => {
        // if(!isCardAdded) return;
        setShowCard(true);
    }

    const addTagHandler = () => {
        setShowTagForm(false)
    }
    const editableFieldHandler = (fieldName, value) => {
        setEditableFields({ ...editableFields, [fieldName]: value })
    }

    return (
        <div className={classes.container}>
            <div className={classes.closeHeader}>
                <CloseIcon fontSize="small" className={classes.closeIcon} onClick={props.closeFormHandler} />
            </div>
            <div className={classes.form}>
                <div className={classes.row}>
                    <FormData
                        classes={classes}
                        fieldName="Booking source"
                        value={data.bookingSource}
                        keyName={"bookingSource"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName="Booking commission"
                        value={data.bookingCommission}
                        keyName={"bookingCommission"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName="Cancellation policy"
                        value={data.cancellationPolicy}
                        keyName={"cancellationPolicy"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    {validateShowCard() &&
                        <div className={clsx(classes.formInput, classes.actionInput)} onClick={viewCardHandler}>
                            <div>Credit card details</div>
                        </div>
                    }
                </div>

                <div className={classes.row}>
                    <FormData
                        classes={classes}
                        fieldName="Booking ID"
                        value={data.bookingID}
                        keyName={"bookingID"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName="Rate plan"
                        value={data.ratePlan}
                        keyName={"ratePlan"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName="Counter reservation ID"
                        value={data.reservationID}
                        keyName={"reservationID"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                </div>

                <div className={classes.row}>
                    {data.tags.map((tag, tagIndex) => (
                        <div key={tagIndex} className={classes.tags}>{tag}
                            {/* <CloseIcon fontSize="small" className={classes.tagCloseIcon} onClick={()=>removeTagHandler(tagIndex)}/> */}
                        </div>
                    ))}
                    {/* {showTagForm ?
                        <div style={{ maxWidth: 410 }}><OneFieldForm handleClose={() => setShowTagForm(false)} handleSubmit={(value) => addTagHandler(value)} /></div> :
                        <div className={clsx(classes.tags, classes.action)} onClick={() => setShowTagForm(true)}>+</div>
                    } */}
                </div>
            </div>

            {showCard && (
                <ViewCardModal
                    isCardAdded={isCardAdded}
                    closeModalHander={() => setShowCard(false)}
                />
            )}

        </div>
    )
}

export default DetailsForm;