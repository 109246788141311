import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DATE_FORMATS, fetchDateFormat, getMomentFormat } from '../../../../utils/utility';
import { DEVICE_VIEW } from './Helper';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 4px',
        width: 'calc(100% / 7 - 8px)',
        textAlign: 'center',
        minHeight: 200,
        '&.smallDevice': {
            width: 'calc(100% / 4 - 8px)',
        }
    },
    button: {
        alignItems: "center",
        border: "none",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        marginTop: 5,
        padding: '5px 10px 5px 10px',
        position: 'relative'
    },
    card: {
        border: '1px solid #dadada',
        marginTop: 5,
        borderRadius: 5,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: '#F2F2F2',
        },
        '&.highlighted': {
            backgroundColor: '#F2F2F2'
        },
        '&.selected': {
            backgroundColor: '#f2f2f2'
        },
        '&.noAction': {
            cursor: 'default',
            backgroundColor: '#ffffff'
        },
        '&.different': {
            borderLeft: '4px solid #d96977'
        }
    },
    noBorderAction: {
        border: '1px solid transparent',
        cursor: 'default'
    },
    isCurrentDate: {
        background: '#f8f9fb',
        color: '#b83b3b',
        fontWeight: 600,
        width: '20%',
        borderRadius: '5px',
        margin: 'auto',
        marginTop: 5,
    },
    week: {
        color: '#484848',
        display: 'block',
        fontSize: '1rem',
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 80,
        padding: 10,
    },
    currencyContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        minHeight: 35,
    },
    label: {
        fontSize: '1.2rem',
        color: '#666666',
        fontWeight: 600,
        padding: 0,
    },
    border: {
        border: '1px solid #f2f2f2',
    },
    currency: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '1.2rem',
    },
    text: {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#4e4e4e',
    }
}));

const GuestDateCard = (props) => {
    const classes = useStyles();
    const { data, displayDay, timezone, selected, highlighted, allowDataActions, isDifferent, view } = props;
    const currentDate = fetchDateFormat(getMomentFormat(null, timezone), DATE_FORMATS.DEFAULT_DATE)
    let date = fetchDateFormat(props.date, 'DD')
    if (date === '01') date = fetchDateFormat(props.date, 'D MMM');

    return (
        <div className={clsx(classes.root, {['smallDevice']: view === DEVICE_VIEW.SMALL})}>
            <div className={clsx(classes.button, { [classes.isCurrentDate]: currentDate === props.date })}>
                {displayDay && <span className={classes.week}>{fetchDateFormat(props.date, DATE_FORMATS.WEEKDAY)}</span>}
                <span className={classes.date}>{date}</span>
            </div>
            {data ?
                <div className={clsx(classes.card, { 'selected': selected, 'highlighted': highlighted, 'noAction': !allowDataActions, 'different': isDifferent })}
                    onClick={() => allowDataActions  ? props.selectDateHandler(props.date) : null}>
                    <div className={classes.labelContainer}>
                        <div className={classes.label}>{data.name}</div>
                    </div>
                    <div className={classes.border}></div>
                    <div className={classes.currencyContainer}>
                        <div className={classes.currency}>{data.formattedRate}</div>
                        {/* <div className={classes.text}>Base rate</div> */}
                    </div>
                </div> :
                <div className={clsx(classes.card, classes.noBorderAction)} />
            }
        </div>
    )

}

export default GuestDateCard;