import { resetTodaysReservations } from "../../actions/live/reservations";
import { resetReservationsReport } from "../../actions/reports/reservations";

export default (state = {}, action) => {
  switch(action.type) {
    case "LOAD_LIVE_SUCCESS":
      return {
        ...state,
        [action.payload.spaceID]: {...action.payload}
      }
    case "CREATE_NEW_CASH_DRAWER_SUCCESS":
      const cashDrawerLive = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {...cashDrawerLive, cashDrawerCount: cashDrawerLive.cashDrawerCount+1}
      }
    // case "SETUP_CASH_ACCOUNTS_SUCCESS":
    //   const cashAccountsLive = state[action.payload.spaceID]
    //   return {
    //     ...state,
    //     [action.payload.spaceID]: {...cashAccountsLive, cashAccountCount: Object.keys(action.payload.cashAccounts).length}
    //   }
    case "LOAD_TODAYS_RESERVATIONS_SUCCESS":
      const reservationSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...reservationSpace, 
          todaysReservations: action.payload.data
        }
      }
    case "RESET_TODAYS_RESERVATIONS":
      const resetReservationsSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...resetReservationsSpace,
          todaysReservations: []
        }
      }

    case "LOAD_TODAYS_ACTIVITY_SUCCESS":
      const thisSpace = state[action.payload.spaceID]
      return {
        ...state,
        [action.payload.spaceID]: {
          ...thisSpace, 
          todaysActivity: action.payload.data
        }
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};
  