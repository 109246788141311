import React from 'react';
import { makeStyles, InputBase, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import clsx from 'clsx';
import OneFieldForm from '../../common/OneFieldForm';
import { BOOKING_SOURCE } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f8f9fb',
    marginBottom: 10,
    padding: '0 20px',
  },
  form: {
    backgroundColor: '#fff',
    padding: 10,
    paddingTop: 0
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    background: '#f8f9fb',
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 2,
    '& > div': {
      width: '100%'
    },
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .css-6q0nyr-Svg': {
      color: '#000000',
      width: 30,
      height: 30,
    }
  },
  closeHeader: {
    textAlign: 'right',
    padding: 10,
    '& svg': {
      cursor: 'pointer',
      borderRadius: '50%',
      color: '#666666',
      padding: theme.spacing(1),
      transition: '150ms',
      '&:hover': {
        background: '#dddddd',
        color: '#666666'
      },
    }
  },
  col: {
    width: '25%',
    marginBottom: 10,
    marginRight: 10
  },
  actionIcon: {
    cursor: 'pointer',
    width: 15,
    height: 15
  },
  bubble: {
    background: '#f8f9fb',
    borderRadius: 2,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  selected: {
    background: '#8CB2A4',
    color: '#ffffff',
    fontWeight: 600,
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  tag: {
    width: '80%'
  },
  button: {
    backgroundColor: '#6ea7d6',
    color: '#fff',
    fontWeight: 600,
    marginLeft: 5,
    borderRadius: 5
  },
  closeIcon: {
    fontSize: '1.8rem',
    marginLeft: 5,
  },
  onefield: {
    display: 'inline-block'
  },
  buttonContainer: {
    textAlign: 'end',
    padding: 10
  },
  back: {
    background: '#ffffff',
    border: '0',
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  submit: {
    border: '0',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#6ea7d6',
    color: '#fff',
    fontWeight: 600,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#6ea7d6',
      color: '#fff',
    }
  },
  dropdownInput: {
    "& > div:not(.css-26l3qy-menu)": {
      minHeight: 30,
      border: 0,
      backgroundColor: '#f8f9fb',
      boxShadow: 'none'
    },
    "& > div > div > div": {
      padding: '0 8px'
    },
    "& .css-26l3qy-menu > div > div": {
      padding: 8
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000
    }
  },
}));

const BasicEditDetails = (props) => {
  const classes = useStyles();
  const { booking, bookingSource } = props;
  const [isNewTag, setIsNewTag] = React.useState(false);
  const [sourceList, setSourceList] = React.useState([
    { value: BOOKING_SOURCE.PHONE, label: BOOKING_SOURCE.PHONE },
    { value: BOOKING_SOURCE.EMAIL, label: BOOKING_SOURCE.EMAIL },
    { value: BOOKING_SOURCE.WALKIN, label: BOOKING_SOURCE.WALKIN },
    { value: BOOKING_SOURCE.OTHER, label: BOOKING_SOURCE.OTHER }
  ]);

  const [form, setForm] = React.useState({
    bookingSource: booking.bookingSource,
    timeOfArrival: booking.timeOfArrival,
    bookingID: booking.bookingID,
    cancellationPolicy: booking.cancellationPolicy,
    tags: []
  });

  React.useEffect(() => {
    let list = []
    bookingSource.map(key => list.push({ value: key, label: key }))
    if(booking.bookingSource) list.push({ value: booking.bookingSource, label: booking.bookingSource })
    list = [...sourceList, ...list]
    list = [...new Set(list.map(JSON.stringify))].map(JSON.parse);
    setSourceList([...list])
  }, [bookingSource])

  const handleClose = () => {
    props.handleClose();
  }

  const handleNewTag = () => {
    setIsNewTag(true)
  }

  const handleAddTag = (value) => {
    setIsNewTag(false)
    setForm({ ...form, tags: [...form.tags, value] })
  }

  const handleRemoveTag = (index) => {
    let tags = [...form.tags];
    tags.splice(index, 1);
    setForm({ ...form, tags: [...tags] })
  }

  const handleSelect = (option, type) => {
    setForm({ ...form, [type]: option.value })
  }

  const handleChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value })
  }

  const handleSubmit = () => {
    props.handleEdit(form);
  }

  return (
    <div className={classes.container}>
      {props.open &&
        <div className={classes.form}>
          <div className={classes.closeHeader}>
            <CloseIcon fontSize="small" onClick={handleClose} />
          </div>
          <div className={classes.row}>
            <div className={classes.col}>Booking Source</div>
            <div className={clsx(classes.field, classes.col)}>
              <Select
                isSearchable={true}
                name="bookingSource"
                onChange={(option) => handleSelect(option, 'bookingSource')}
                value={sourceList.find(e => e.value === form.bookingSource)}
                options={sourceList}
                className={classes.dropdownInput}
              />
            </div>
            {/* <div className={classes.col}>Booking ID</div>
            <div className={clsx(classes.field, classes.col)}>
              <InputBase
                type="text"
                id="bookingID"
                defaultValue={booking.bookingID}
                disabled
                onChange={(e) => handleChange(e, "bookingID")}
              />
            </div> */}
          </div>
          {/* <div className={classes.row}>
            <div className={classes.col}>Tags</div>
            <div className={classes.tag}>
              {form.tags?.map((tag, index) => (
                <span className={classes.bubble} key={index}>
                  <div className={classes.inline}>{tag}<CloseIcon className={classes.closeIcon} onClick={() => handleRemoveTag(index)} /></div>
                </span>
              ))}

              {!isNewTag && <div className={clsx(classes.bubble, classes.button)} onClick={handleNewTag}> Add new tag </div>}
              {isNewTag && <div className={classes.onefield}>
                <OneFieldForm handleClose={() => setIsNewTag(false)} handleSubmit={(value) => handleAddTag(value)} />
              </div>}
            </div>
          </div> */}

          <div className={classes.buttonContainer}>
            <Button className={classes.back} onClick={handleClose}>Cancel</Button>
            <Button variant="contained" className={classes.submit} onClick={() => handleSubmit()}>Save</Button>
          </div>
        </div>
      }
    </div>
  )

}

export default BasicEditDetails;