import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import { getMenuStepInfo, getProgress } from './progressHelper'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '10px 15px',
    boxSizing: 'border-box'
  },
  infoContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem'
  },
  progressContainer: {
    width: '100%'
  },
  statusBar: {
    // width: '65%',
    backgroundColor: '#e3e3e3',
    '&.MuiLinearProgress-root': {
      height: '3px',
      borderRadius: '20px'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#006699',
      borderRadius: '20px'
    }
  },
  next: {
    background: '#006699',
    borderColor: '#006699',
    color: '#FFFFFF',
    '&:hover': {
      background: '#006699',
      color: '#FFFFFF'
    }
  },
  save: {
    fontSize: '1.3rem'
  }
}))

const Footer = props => {
  const {
    nextStepHandler,
    prevStepHandler,
    activeStepInfo,
    submitHandler,
    settings
  } = props
  const classes = useStyles()
  const [stepData, setStepData] = useState({})

  useEffect(() => {
    const stepData = getMenuStepInfo(activeStepInfo.menuID)
    setStepData({ ...stepData })
  }, [activeStepInfo.menuID])

  const progress = getProgress(settings)

  return (
    <>
      <div className={classes.progressContainer}>
        <LinearProgress
          className={classes.statusBar}
          variant='determinate'
          value={progress * 100 / stepData.totalSteps}
        />
      </div>

      <div className={classes.root}>

        {progress !== stepData.totalSteps && (
          <Button
            variant='outlined'
            className={classes.save}
            onClick={prevStepHandler}
          >
            Previous
          </Button>
        )}

        <div className={classes.infoContainer}>
          {progress} of {stepData.totalSteps} complete
        </div>

        <Button
          variant='outlined'
          className={clsx(classes.save, classes.next)}
          onClick={progress !== stepData.totalSteps ? nextStepHandler : submitHandler}
        >
          {progress === stepData.totalSteps ? 'Save' : 'Next'}
        </Button>
      </div>
    </>
  )
}

export default Footer
