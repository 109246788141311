export const sidebarMenu = {
    // TEMPLATE: {
    //     id: "TEMPLATE",
    //     title: "Template",
    // },
    BASIC: {
        id: "BASIC",
        title: "Basic Information",
    },
    CATEGORY: {
        id: "CATEGORY",
        title: "Category",
    },
    INVENTORY: {
        id: "INVENTORY",
        title: "Inventory",
    },
    PRICE: {
        id: "PRICE",
        title: "Price",
    },
    TAXES: {
        id: "TAXES",
        title: "Taxes",
    },
    VISIBILITY: {
        id: "VISIBILITY",
        title: "Visibility",
    }
}