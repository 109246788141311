import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { createProductCategory } from '../../../../redux/actions/pos/productCategory';

import Pill from '../../../common/Pill';
import NewProductCategory from './NewProductCategory';

const useStyles = makeStyles(theme => ({
	roomTypeContainer: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		width: '80%',
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontSize: '1.2rem',
		marginBottom: 20,
	},
}));

const CategorySelect = props => {
	const classes = useStyles();
	const {
		productCategories,
		currentSpace,
		product,
		handleUpdateProduct
	} = props;
	const [newProductCategory, setNewProductCategory] = React.useState(false);

	const handleClick = (category) => {
		if (product.productCategoryID == category) {
			handleUpdateProduct('productCategoryID', null);
		} else {
			handleUpdateProduct('productCategoryID', category)
		}
	}

	const handleSubmit = (data) => {
		data = { ...data, propertyID: currentSpace.propertyID };
		props.dispatch(createProductCategory(currentSpace._id, data));
		setNewProductCategory(false);
	}

	return (
		<>
			<div className="main-content">

				<div className="main-content-title">Categories</div>
				<div className="sub-title">Select a category that this product belongs to</div>
				<div className="row">
					<div className={classes.roomTypeContainer}>
						{currentSpace &&
							currentSpace.productCategories &&
							currentSpace.productCategories
								.filter(category => productCategories[category].isDeleted !== true)
								.map(category =>
									<Pill
										key={category}
										name={productCategories[category].name}
										handleClick={() => handleClick(category)}
										selected={product.productCategoryID == category}
										green={product.productCategoryID == category}
										cancelButton={product.productCategoryID == category}
									/>
								)}
					</div>
				</div>

				{!newProductCategory && <div className="row">
					<div className={classes.link} onClick={() => setNewProductCategory(true)}>Add a product category</div>
				</div>}

				{newProductCategory && <div className="row">
					<NewProductCategory handleSubmit={handleSubmit} handleCancel={() => setNewProductCategory(false)} />
				</div>}

			</div>
		</>
	)
}

const mapStateToProps = state => ({
	currentSpace: state.spaces[state.dashboard.currentSpace],
	productCategories: state.productCategories
})

export default connect(mapStateToProps)(CategorySelect); 