import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { returnCurrent } from '../../../redux/selectors/dashboard';
import { RESERVATION_STATUS } from '../../../utils/constants';
import { updateReservationStatus } from '../../../redux/actions/reservation/editReservation';
import { fetchDateFormat, DATE_FORMATS } from '../../../utils/utility';
import { getTags } from '../../../redux/actions/reservation/reservationTags'
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		alignItems: 'stretch',
		padding: '20px 25px',
		paddingBottom: 0,
		[theme.breakpoints.down('xs')]: {
			display: 'block',
			padding: 10,
		},
		'&.extended': {
			alignItems: 'center',
		}
	},
	status: {
		backgroundColor: '#f2f2f2',
		borderRadius: 5,
		padding: '15px 20px 15px 20px',
		display: 'flex',
		alignItems: 'center',
		minHeight: 65,
		width: '30%',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		[theme.breakpoints.down('xs')]: {
			width: 'unset',
		}
	},
	attachContainer: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
		}
	},
	attach: {
		width: 40,
		height: 15,
		backgroundColor: '#f2f2f2',
		[theme.breakpoints.down('xs')]: {
			width: 20,
			height: 20,
		},
	},
	icon: {
		alignItems: 'center',
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		padding: 10,
		width: 30,
		'& img': {
			height: 25,
			width: 25
		}
	},
	iconLink: {
		borderRadius: '50%',
		cursor: 'pointer',
		'&:hover': {
			background: '#DADADA',
		}
	},
	linkText: {
		marginLeft: 10,
	},
	link: {
		cursor: 'pointer',
		fontSize: '1.1rem',
		color: '#7CAC86',
	},
	detailsLink: {
		cursor: 'pointer',
		fontSize: '1.1rem',
		fontWeight: 600,
		color: '#13408d',
	},
	date: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		}
	},
	primaryTextHolder: {
		display: 'flex',
	},
	primaryText: {
		fontSize: '1.4rem',
		fontWeight: 600,
		color: '#666666',
	},
	secondaryText: {
		fontSize: '1.1rem',
		color: '#828282',
	},
	button: {
		cursor: 'pointer',
		backgroundColor: "#13408d",
		'& > div > div > div': {
			color: '#ffffff',
		},
		'& img': {
			filter: 'invert(1)'
		}
	},
	expand: {
		height: 10,
		flex: '1 1 100%'
	},
}));

const Status = (props) => {
	const classes = useStyles();
	const { reservation, expand, currentSpace, t, currentSpaceID } = props;
	const [nextStep, setNextStep] = useState(null);
	const [prevStep, setPrevStep] = useState(null);

	useEffect(() => {
		if (props.reservation) {
			if ((reservation.status === RESERVATION_STATUS.CONFIRMED || reservation.status === RESERVATION_STATUS.ONLINE_CHECKED_IN)) {
				setNextStep(RESERVATION_STATUS.IN_HOUSE)
				setPrevStep(null)
			} else if (reservation.status === RESERVATION_STATUS.IN_HOUSE) {
				setNextStep(RESERVATION_STATUS.CHECKED_OUT)
				setPrevStep(RESERVATION_STATUS.CONFIRMED)
			} else if (reservation.status === RESERVATION_STATUS.CHECKED_OUT) {
				setNextStep(null)
				setPrevStep(RESERVATION_STATUS.IN_HOUSE)
			} else if (reservation.status === RESERVATION_STATUS.CANCELLED || reservation.status === RESERVATION_STATUS.NO_SHOW) {
				setNextStep(RESERVATION_STATUS.CONFIRMED)
				setPrevStep(null)
			}
		}
		return () => { setNextStep(null) };
	}, [reservation]);

	const handleUpdateStatus = (selection) => {
		props.dispatch(updateReservationStatus(reservation._id, { status: selection }))
	}
	const handleClick = (selection) => {
		if (selection !== RESERVATION_STATUS.CONFIRMED) {
			props.handleSelectStatus(false);
		}
		if (nextStep === selection || prevStep === selection) {
			handleUpdateStatus(selection)
		}
	}
	const handleExpand = (e) => {
		e.stopPropagation();
		props.dispatch(getTags(currentSpaceID, currentSpace.propertyID))
		props.handleSelectStatus(true);
	}

	const StatusBlock = ({
		primaryText = null,
		secondaryText = null,
		linkText = null,
		status = null,
		date = null,
		icon = null,
		isButton = false,
		reconfirm = false,
		canRevert = false,
		visible = false
	}) => (
		<div className={clsx(classes.status, { [classes.button]: isButton })} onClick={() => { if (isButton) handleClick(status) }}>
			{visible && <React.Fragment>
				<div className={classes.details}>
					<div className={classes.primaryTextHolder}>
						<div className={classes.primaryText}>{primaryText}</div>
						{canRevert &&
							<div className={classes.linkText}>
								<a className={classes.link} onClick={() => handleClick(status)}>{
									reservation.status === RESERVATION_STATUS.IN_HOUSE ? 
									t('existingReservation.reservationTab.revert') :
									t('existingReservation.reservationTab.revertCheckOut')
								}</a>
							</div>
						}
						{reconfirm &&
							<div className={classes.linkText}>
								<a className={classes.link} onClick={() => handleClick(status)}>{t('existingReservation.reservationTab.confirmBack')}</a>
							</div>
						}
					</div>

					{date &&
						<div className={classes.secondaryText}>{date}</div>
					}
					{linkText &&
						<div><a className={classes.detailsLink} onClick={handleExpand}>{linkText}</a></div>
					}
					{secondaryText &&
						<div className={classes.secondaryText}>{secondaryText}</div>
					}
				</div>

				{icon && <div className={clsx(classes.icon, { [classes.iconLink]: reconfirm })} onClick={() => { if (reconfirm) handleClick(status) }}>
					<img src={icon} />
				</div>}

				{linkText && expand && <div className={classes.expand}></div>}
			</React.Fragment>}
		</div>
	);



	const BuildData = (status) => {
		let data = {};

		switch (status) {
			case RESERVATION_STATUS.CONFIRMED:
			case RESERVATION_STATUS.ONLINE_CHECKED_IN:
				data = {
					...data,
					primaryText: t('existingReservation.reservationTab.confirmed'),
					status: RESERVATION_STATUS.CONFIRMED,
					date: fetchDateFormat(reservation.createdAt, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, currentSpace.timezone),
					linkText: t('existingReservation.header'),
					canRevert: reservation.status === RESERVATION_STATUS.IN_HOUSE ? true : false,
					visible: true
				}
				break;
			case RESERVATION_STATUS.NO_SHOW:
			case RESERVATION_STATUS.CANCELLED:
				data = {
					...data,
					primaryText: status === RESERVATION_STATUS.NO_SHOW ? t('existingReservation.hoverInfo.noShow') : t('existingReservation.hoverInfo.cancelled'),
					status: RESERVATION_STATUS.CONFIRMED,
					date: fetchDateFormat(reservation.createdAt, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, currentSpace.timezone),
					linkText: t('existingReservation.header'),
					icon: IMAGES.ICONS.revert,
					reconfirm: nextStep === RESERVATION_STATUS.CONFIRMED ? true : false,
					isButton: false,
					visible: true
				}
				break;
			case RESERVATION_STATUS.IN_HOUSE:
				data = {
					...data,
					primaryText: nextStep === RESERVATION_STATUS.IN_HOUSE ?
						t('existingReservation.hoverInfo.checkIn') :
						t('existingReservation.reservationTab.checkedIn'),
					secondaryText: [RESERVATION_STATUS.IN_HOUSE, RESERVATION_STATUS.CHECKED_OUT].includes(reservation.status) ?
						'Staff: ' + (reservation.checkedInByStaff || "") : null,
					status: RESERVATION_STATUS.IN_HOUSE,
					date: [RESERVATION_STATUS.IN_HOUSE, RESERVATION_STATUS.CHECKED_OUT].includes(reservation.status) && reservation.checkedInDate ?
						(fetchDateFormat(reservation.checkedInDate, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, currentSpace.timezone) || null) : null,
					icon: IMAGES.ICONS.checkIn,
					isButton: nextStep === RESERVATION_STATUS.IN_HOUSE ? true : false,
					canRevert: reservation.status === RESERVATION_STATUS.CHECKED_OUT ? true : false,
					visible: [RESERVATION_STATUS.CONFIRMED,
					RESERVATION_STATUS.ONLINE_CHECKED_IN,
					RESERVATION_STATUS.IN_HOUSE,
					RESERVATION_STATUS.CHECKED_OUT].includes(reservation.status)
				}
				break;
			case RESERVATION_STATUS.CHECKED_OUT:
				data = {
					...data,
					primaryText: reservation.status === RESERVATION_STATUS.CHECKED_OUT ?
						t('existingReservation.hoverInfo.checkedOut') :
						t('existingReservation.reservationTab.checkOut'),
					status: RESERVATION_STATUS.CHECKED_OUT,
					date: reservation.status === RESERVATION_STATUS.CHECKED_OUT && reservation.checkedOutDate ?
						(fetchDateFormat(reservation.checkedOutDate, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, currentSpace.timezone) || null) : null,
					icon: IMAGES.ICONS.checkOut,
					isButton: reservation.status === RESERVATION_STATUS.IN_HOUSE ? true : false,
					visible: [RESERVATION_STATUS.IN_HOUSE, RESERVATION_STATUS.CHECKED_OUT].includes(reservation.status)
				}
				break;
		}
		return data;
	}

	return (
		<div className={clsx(classes.container, { 'extended': expand })}>
			{/* -------------------------- Confirmed button ------------------------------- */}
			<StatusBlock
				{...BuildData(
					[RESERVATION_STATUS.ONLINE_CHECKED_IN, RESERVATION_STATUS.NO_SHOW, RESERVATION_STATUS.CANCELLED].
						includes(reservation.status) ?
						reservation.status :
						RESERVATION_STATUS.CONFIRMED
				)}
			/>
			<div className={classes.attachContainer}><div className={classes.attach} /></div>
			{/* --------------------------- Checked-in button ------------------------------ */}
			<StatusBlock
				{...BuildData(RESERVATION_STATUS.IN_HOUSE)}
			/>
			<div className={classes.attachContainer}><div className={classes.attach} /></div>
			{/* ----------------------------- Checked-out button ---------------------------- */}
			<StatusBlock
				{...BuildData(RESERVATION_STATUS.CHECKED_OUT)}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	reservation: state.reservationDetails.reservation,
	currentSpaceID: state.dashboard.currentSpace,
	currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
})

export default withTranslation()(connect(mapStateToProps)(Status));