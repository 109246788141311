
// Filters Reducer
const defaultState = []

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CREATE_BOOKING_SOURCE_SUCCESS':
      let data = [...new Set([...state, action.payload.data.name])];
      return [...data]
    case 'FETCH_BOOKING_SOURCE_SUCCESS':
      let source = action.payload.data && action.payload.data.map(d => d.name);
      source = [...new Set([...source])];
      return [...source]
    // case 'UPDATE_BOOKING_SOURCE_SUCCESS':
    //   let body = action.payload.data && action.payload.data.map(d => d.name);
    //   body = [...new Set([...body])];
    //   return [...body]
    default:
      return state;
  }
}