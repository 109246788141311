import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD COMMISSION
//----------------------------------------------------------------
export function fetchPickupInfo(data) {
  const ACTION_NAME = "FETCH_PICKUP_INFO";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/frontdesk/reports/reservations/pickUp`, data)
        .then(successResult => {
            const { data } = successResult;
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return data;
        })
        .catch(errorResult => {
            console.log('failure', errorResult);
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            return;
        });
  };
}