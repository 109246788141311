import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  fetchProperty,
  editProperty,
} from "../../redux/actions/dashboard/property";
import {
  fetchZapierKey,
  refreshZapierKey,
} from "../../redux/actions/zapier/accessToken";

import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    minHeight: "calc(100vh - 250px)",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  titleNew: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
    marginTop: 10,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    lineHeight: 1.3,
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  link: {
    color: "blue",
    textDecorationLine: "underline",
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "70%",
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
  leftMargin: {
    marginLeft: 20,
  },
  topMargin: {
    marginTop: 25,
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "1px",
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const Zapier = (props) => {
  const { t } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { open, handleClose, currentSpace, property, loadingProperty, zapier } =
    props;
  const [message, setMessage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  useEffect(() => {
    props.dispatch(fetchZapierKey(currentSpace.propertyID));
    if (property.default) {
      props.dispatch(fetchProperty(currentSpace.propertyID));
    }
  }, []);

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "Zapier" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  const handleCloseModal = () => {
    handleClose();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSwitchToggle = (value) => {
    setToggle(value);
    if (!value)
      props.dispatch(
        editProperty(currentSpace.propertyID, { disableAppFeature: "Zapier" })
      );
    else
      props.dispatch(
        editProperty(currentSpace.propertyID, { appFeature: "Zapier" })
      );
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleRefreshKey = () => {
    props.dispatch(refreshZapierKey(currentSpace.propertyID));
    setOpenSnackbar(true);
    setMessage("Rotating your secret...");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(zapier.apiKey);
    setOpenSnackbar(true);
    setMessage("Copied to clipboard!");
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Zapier</div>
          <AccessHeaderAction moduleID={MODULE.ZAPIER.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        {loadingProperty && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        <div className={classes.listContainer}>
          {!loadingProperty && (
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.zapier.definition")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <div className={classes.title}>Zapier</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={() => handleSwitchToggle(false)}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={() => handleSwitchToggle(true)}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {toggle && zapier.default && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}
              {toggle && zapier.maskedKey && (
                <div className={classes.row}>
                  <ul>
                    <li>{t("automate.zapier.pointOne")}</li>
                    <li>{t("automate.zapier.pointTwo")}</li>
                    <li>{t("automate.zapier.pointThree")}</li>
                    <li>{t("automate.zapier.pointFour")}</li>
                    <li>
                      {t("automate.zapier.pointFive")}{" "}
                      <a
                        href="https://zapier.com/learn/getting-started-guide/build-zap-workflow/"
                        className={classes.link}
                        target="_blank"
                      >
                        {t("automate.zapier.here")}
                      </a>
                      .
                    </li>
                  </ul>
                  <br />
                  <div>
                    <div className={classes.title}>
                      {t("automate.zapier.secretHeading")}
                    </div>
                    <div className={classes.label}>
                      {t("automate.zapier.secretLabel")}
                    </div>
                  </div>
                  {!show && (
                    <div className={classes.linkContainer}>
                      <div className={classes.addContainer}>
                        <span className={classes.titleNew}>
                          {" "}
                          {t("automate.zapier.yourSecret")}: {zapier.maskedKey}{" "}
                        </span>
                      </div>
                      <div className={classes.saveContainer}>
                        <div className={classes.buttonsContainer}>
                          <div className={classes.button} onClick={handleShow}>
                            {t("automate.zapier.revealSecret")}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {show && (
                    <div className={classes.linkContainer}>
                      <div
                        className={clsx(classes.field, classes.addContainer)}
                      >
                        <InputBase
                          id="secret"
                          name="secret"
                          value={zapier.apiKey}
                          fullWidth
                          type="text"
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className={classes.saveContainer}>
                        <div
                          className={clsx(
                            classes.linkContainer,
                            classes.topMargin
                          )}
                        >
                          <span className={classes.addContainer}>
                            <RefreshIcon
                              onClick={() => handleRefreshKey()}
                              className={classes.actionIcon}
                            />
                          </span>
                          <span
                            className={clsx(
                              classes.saveContainer,
                              classes.leftMargin
                            )}
                          >
                            <div
                              className={classes.copyButton}
                              onClick={handleCopy}
                            >
                              {t("automate.zapier.copy")}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            autoHideDuration={3000}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  zapier: state.zapier,
});

export default withTranslation()(connect(mapStateToProps)(Zapier));
