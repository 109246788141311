import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import { RESERVATION_STATUS, BOOKING_SOURCE } from "../../utils/constants";
import {
  convertArrayToCSV,
  generateCSV,
  currencyToNumber,
} from "../../utils/utility";

import {
  loadReservationsByDay,
  resetReservationsReport,
} from "../../redux/actions/reports/reservations";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import { reservationProcessing } from "../../redux/selectors/reports/reservations";
import Reservation from "../reservations/Reservation";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";
import Pill from "../common/Pill";
import Checkbox from '@material-ui/core/Checkbox';

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { groupingConfig } from "../utils/excelExport";
import { CHANNEL } from "../../utils/channels";
import { WORKSPACE_URL } from "../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  dateTypeContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "-10px",
  },
  pullRight: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
  },
  link: {
    color: "#4F9581",
    cursor: "pointer",
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  label: {
    color: "#000000",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 20,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  dateHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  date: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    cursor: "pointer",
    marginRight: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  options: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    paddingLeft: 10,
    paddingRight: 10,
    "& input": {
      borderRadius: 10,
      color: "#484848",
      fontWeight: 500,
    },
  },
  selected: {
    background: "#4F9581",
    color: "#ffffff",
    fontWeight: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  thead: {
    background: "#F6F2EE",
  },
  trow: {
    cursor: "pointer",
  },
  roomTypes: {
    display: "flex",
    flexDirection: "column",
  },
  highlighted: {
    color: "red",
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

const reservationStatus = [
  { value: RESERVATION_STATUS.CONFIRMED, label: "Confirmed" },
  { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: "Online Check In" },
  { value: RESERVATION_STATUS.IN_HOUSE, label: "In House" },
  { value: RESERVATION_STATUS.CHECKED_OUT, label: "Checked Out" },
  { value: RESERVATION_STATUS.NO_SHOW, label: "No Show" },
  { value: RESERVATION_STATUS.CANCELLED, label: "Cancelled" },
];

const BasicExport = (props) => {
  const { t, currencyData, openInNewTab } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.reservations");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "wholeNumber",
    "string",
    "string",
    "string",
    "wholeNumber",
    "currency",
    "currency",
    "currency",
    "currency",
    "currency",
    "currency",
    "currency",
    "currency",
    "string",
    "string",
    "string",
    "currency",
    "string",
    "string",
    "string",
  ];
  let excelColWidth = [
    20, 40, 20, 20, 20, 15, 15, 20, 20, 20, 20, 10, 20, 15, 20, 10, 20, 20, 20,
    20, 20, 20, 20, 20, 20, 20, 30, 20, 20, 40, 40,
  ];

  const headerConfig = [
    { title: "Reservation Code", field: "resvCode" },
    { title: "Guest Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    { title: "Created At", field: "reservationDate" },
    { title: "Check-in", field: "checkIn" },
    { title: "Check-out", field: "checkOut" },

    { title: "Confirmed Time", field: "confirmedTime" },
    { title: "Checked-in Time", field: "checkedInTime" },
    { title: "Checked-out Time", field: "checkedOutTime" },
    { title: "Checked-in by Staff", field: "checkedInByStaff" },

    { title: "Duration", field: "duration" },
    { title: "Booking Source", field: "source" },
    { title: "Status", field: "status" },
    { title: "Source Reservation ID", field: "sourceReservationID" },
    { title: "Guest Count", field: "guestCount" },

    {
      title: `Accomodation Amount (${currencyData.code})`,
      field: "accommodationAmount",
    },
    { title: `Upsell Amount (${currencyData.code})`, field: "upsellAmount" },
    { title: `Inclusive Tax (${currencyData.code})`, field: "inclusiveTax" },
    { title: `Exclusive Tax (${currencyData.code})`, field: "exclusiveTax" },
    {
      title: `Total Tax Amount (${currencyData.code})`,
      field: "totalTaxAmount",
    },

    { title: `Amount Paid (${currencyData.code})`, field: "amountPaid" },
    { title: `Total Amount (${currencyData.code})`, field: "amount" },
    { title: `Balance (${currencyData.code})`, field: "balance" },

    { title: "Cancellation Time", field: "cancellationAt" },
    { title: "Cancellation By", field: "cancellationBy" },
    { title: "Cancellation Reason", field: "cancellationReason" },
    { title: `Commission (${currencyData.code})`, field: "commission" },
    { title: "Arrival Time", field: "timeOfArrival" },

    { title: "Roomtypes", field: "roomTypesInfo" },
    { title: "Rooms", field: "roomsInfo" },
    { title: "Non Refundable", field: "nonRefundable" },
  ];

  const pdfHeaderData = [
    { title: "Reservation Code", field: "resvCode" },
    { title: "Guest Name", field: "name" },
    { title: "Guests Count", field: "guestCount" },
    { title: "Check-in", field: "checkIn" },
    { title: "Check-out", field: "checkOut" },
    { title: "Duration", field: "duration" },
    { title: "Booking Source", field: "source" },
    { title: "Status", field: "status" },
    { title: `Tax (${currencyData.code})`, field: "totalTaxAmount" },
    { title: `Amount (${currencyData.code})`, field: "amount" },
    { title: "Non Refundable", field: "nonRefundable" },
  ];

  const groupingConfigParmas = {
    data: props.data,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
    headerConfig,
    pdfHeaderData,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("reports.table.resvCode"),
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.guestName"),
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.guestCount"),
          field: "guestCount",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.durationStay"),
          field: "duration",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("existingReservation.hoverInfo.status"),
          field: "status",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.bookingSource"),
          field: "source",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: `${t("reports.table.tax")} (${currencyData.code})`,
          field: "totalTaxAmount",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: `${t("reports.table.amount")} (${currencyData.code})`,
          field: "amount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
          customSort: (a, b) =>
            currencyToNumber(props.currencyData, a.amount) -
            currencyToNumber(props.currencyData, b.amount),
        },
        {
          title: t("reports.table.nonRefundable"),
          field: "nonRefundable",
        },
        {
          title: `${t("existingReservation.hoverInfo.balance")} (${
            currencyData.code
          })`,
          field: "balance",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
          customSort: (a, b) =>
            currencyToNumber(props.currencyData, a.balance) -
            currencyToNumber(props.currencyData, b.balance),
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        headerStyle: {
          backgroundColor: "#F6F2EE",
          zIndex: "unset",
        },
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: "open_in_new",
          tooltip: "Open in a new tab",
          onClick: (event, rowData) => openInNewTab(rowData.resvCode),
        },
      ]}
      onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  );
};

const constantBookingSources = [
  "Hostelworld",
  "Booking.com",
  "Airbnb",
  "Expedia",
];

const Reservations = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "today", label: t("reports.presetRange.today") },
    { value: "yesterday", label: t("reports.presetRange.yesterday") },
    { value: "tomorrow", label: t("reports.presetRange.tomorrow") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    currentProperty,
    reservations,
    roomTypes,
    rooms,
    loadingReservations,
    accessModuleID = null,
  } = props;
  const [openReservation, setOpenReservation] = React.useState(false);
  const [dates, setDates] = React.useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[0]);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };
  const [statusFilters, setStatusFilers] = React.useState([
    reservationStatus[0],
    reservationStatus[2],
    reservationStatus[3],
  ]);
  const [status, setStatus] = React.useState([
    RESERVATION_STATUS.CONFIRMED,
    RESERVATION_STATUS.IN_HOUSE,
    RESERVATION_STATUS.CHECKED_OUT,
  ]);
  const [roomTypeFilter, setRoomTypeFilter] = React.useState([]);
  const [roomFilter, setRoomFilter] = React.useState([]);
  const [bookingSources, setBookingSources] = React.useState([]);
  const [selectedBookingSources, setSelectedBookingSources] = React.useState(
    []
  );
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const [showJustUnallocated, setShowJustUnallocated ] = React.useState(false);
  const [advanced, setAdvanced] = React.useState(false);
  const [dateType, setDateType] = React.useState("stayDate");
  // const [defaultBookingSources, setDefaultBookingSources] = React.useState([]);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] =
    React.useState(false);

  const roomTypeArray = [];
  const roomArray = [];

  currentSpace.roomTypes &&
    currentSpace.roomTypes.forEach((roomTypeID) => {
      if (!roomTypes[roomTypeID].isActive) return;

      roomTypeArray.push({
        value: roomTypeID,
        label: roomTypes[roomTypeID].name,
      });
    });

  useEffect(() => {
    currentSpace.rooms &&
      currentSpace.rooms.forEach((roomID) => {
        if (!rooms[roomID].isActive) return;

        if (roomTypeFilter.length === 0) return;

        const valid = roomTypeFilter.includes(rooms[roomID].primaryRoomTypeID);

        if (valid)
          roomArray.push({
            value: roomID,
            label: rooms[roomID].name,
          });
      });
  }, [roomArray]);

  const tableData = useSelector((state) =>
    reservationProcessing(
      state.reports.reservations,
      currencyData,
      roomTypes,
      rooms
    )
  );

  useEffect(() => {
    initBookingSources();
    const loadStatus = [
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.IN_HOUSE,
      RESERVATION_STATUS.CHECKED_OUT,
    ];
    let params = {
      propertyID: currentSpace.propertyID,
      dateType: dateType,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      status: loadStatus,
    };

    // Temporary code when opening reservation report from Hostelworld Tab
    if (
      props.bookingSource &&
      props.bookingSource == CHANNEL?.[props.bookingSource]?.code
    ) {
      params.bookingSources = [props.bookingSource];
      // setDefaultBookingSources([{ value: props.bookingSource, label: props.bookingSource }]);
      setAdvanced(true);
      setSelectedBookingSources([props.bookingSource]);
      setIsAdvancedFilterApplied(true);
    }
    // ------------------------------------------

    props.dispatch(loadReservationsByDay(params));
    document.title = `${t("reports.reservations")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetReservationsReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const initBookingSources = () => {
    const { live, currentSpace } = props;
    const sourceData =
      (live[currentSpace._id] && live[currentSpace._id].resvSource) || [];

    const bookingSourceData = sourceData.map((data) =>
      data._id.status ? data._id.status : "None"
    );

    const constantOptions = Object.keys(BOOKING_SOURCE).map(
      (source) => BOOKING_SOURCE[source]
    );
    let bookingSouceOptions = [
      ...constantBookingSources,
      ...constantOptions,
      ...bookingSourceData,
    ];
    bookingSouceOptions = [...new Set(bookingSouceOptions)];

    const formattedSourceData = bookingSouceOptions.map((source) => ({
      value: source,
      label: source,
    }));
    setBookingSources([...formattedSourceData]);
  };

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom;
    let reportTo;
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "yesterday") {
      reportFrom = moment().subtract(1, "day");
      reportTo = moment().subtract(1, "day");
    } else if (e.value === "tomorrow") {
      reportFrom = moment().add(1, "day");
      reportTo = moment().add(1, "day");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
      reportTo = moment();
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 90) {
      newLevel = "monthly";
    } else if (newDiff > 30) {
      newLevel = "monthly";
    }

    const advancedFilters = getAdvancedFilters();

    props.dispatch(
      loadReservationsByDay({
        propertyID: currentSpace.propertyID,
        startDate: reportFrom.format("YYYY-MM-DD"),
        endDate: reportTo.format("YYYY-MM-DD"),
        dateType: dateType,
        status,
        ...advancedFilters,
      })
    );
    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
  };

  const getAdvancedFilters = () => {
    let isAdvancedFilterSet = false;
    let params = {};

    if (roomTypeFilter && roomTypeFilter.length > 0) {
      params.roomTypeIDs = [...roomTypeFilter];
      isAdvancedFilterSet = true;
    }

    if (roomFilter && roomFilter.length > 0) {
      params.roomIDs = [...roomFilter];
      isAdvancedFilterSet = true;
    }

    if (selectedBookingSources && selectedBookingSources.length > 0) {
      params.bookingSources = [...selectedBookingSources];
      isAdvancedFilterSet = true;
    }

    if (dateType !== "stayDate") {
      isAdvancedFilterSet = true;
    }

    setIsAdvancedFilterApplied(isAdvancedFilterSet);

    return params;
  };

  const setNewStatus = (value) => {
    if (value) {
      let newStatus = value.map((item) => item.value);
      setStatus(newStatus);
    } else setNewStatus([]);
  };

  const setNewRoomType = (value) => {
    if (value) {
      let newRoomTypeFilter = value.map((item) => item.value);
      setRoomTypeFilter(newRoomTypeFilter);
    } else {
      setRoomTypeFilter([]);
      setRoomFilter([]);
    }
  };

  const setNewRoom = (value) => {
    if (value) {
      let newRoomFilter = value.map((item) => item.value);
      setRoomFilter(newRoomFilter);
    } else setRoomFilter([]);
  };

  const handleSubmit = () => {
    let calcLevel = "daily";
    const difference = dates.endDate.diff(dates.startDate, "days");
    // let isAdvancedFilterSet = false;

    if (difference > 90) {
      calcLevel = "monthly";
    } else if (difference > 30) {
      calcLevel = "monthly";
    }

    const advancedFilters = getAdvancedFilters();

    let params = {
      propertyID: currentSpace.propertyID,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      dateType: dateType,
      status,
      showJustUnallocated,
      ...advancedFilters,
    };

    // if (roomTypeFilter && roomTypeFilter.length > 0) {
    //   params.roomTypeIDs = [ ...roomTypeFilter ];
    //   isAdvancedFilterSet = true;
    // }

    // if (roomFilter && roomFilter.length > 0) {
    //   params.roomIDs = [ ...roomFilter ];
    //   isAdvancedFilterSet = true;
    // }

    // if(selectedBookingSources && selectedBookingSources.length > 0){
    //   params.bookingSources = [ ...selectedBookingSources ];
    //   isAdvancedFilterSet = true;
    // }

    // if(dateType !== 'stayDate'){
    //   isAdvancedFilterSet = true;
    // }

    props.dispatch(loadReservationsByDay(params));

    // setIsAdvancedFilterApplied(isAdvancedFilterSet);

    setSubmittedDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const openInNewTab = (resvCode) => {
    window.open(
      `${WORKSPACE_URL}/${currentProperty.code}/reservation/${resvCode}`,
      "_blank"
    );
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  const setBookingSource = (data) => {
    let selectedSources = [];

    if (data && data.length > 0) {
      selectedSources = data.map((sources) => sources.value);
    }

    setSelectedBookingSources([...selectedSources]);
  };

  const hideAdvancedFilters = () => {
    const defaultDateType = "stayDate";
    setAdvanced(false);

    setDateType(defaultDateType);
    setRoomTypeFilter([]);
    setRoomFilter([]);

    if (props.bookingSource) {
      setSelectedBookingSources([props.bookingSource]);
    } else {
      setSelectedBookingSources([]);
    }

    if (isAdvancedFilterApplied) {
      const params = {
        propertyID: currentSpace.propertyID,
        dateType: defaultDateType,
        startDate: dates.startDate.format("YYYY-MM-DD"),
        endDate: dates.endDate.format("YYYY-MM-DD"),
        ...(props.bookingSource && { bookingSources: [props.bookingSource] }),
        status,
      };
      props.dispatch(loadReservationsByDay(params));
      setIsAdvancedFilterApplied(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("reports.reservations")} {t("reports.report")}
          </div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction
            moduleID={accessModuleID || MODULE.RESERVATIONS.ID}
          />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={5} className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[5]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder={true}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={classes.pullRight}>
                  {!advanced && (
                    <div
                      className={classes.link}
                      onClick={() => setAdvanced(true)}
                    >
                      {t("reports.reservationPage.showAdvanced")}
                    </div>
                  )}
                  {advanced && (
                    <div className={classes.link} onClick={hideAdvancedFilters}>
                      {t("reports.reservationPage.hideAdvanced")}
                    </div>
                  )}
                </Grid>

                {advanced && (
                  <Grid item xs={12}>
                    <div className={classes.dateTypeContainer}>
                      <Pill
                        name="Stay Date"
                        selected={dateType == "stayDate"}
                        green={dateType == "stayDate"}
                        handleClick={() => setDateType("stayDate")}
                      />
                      <Pill
                        name="Booking Date"
                        selected={dateType == "bookingDate"}
                        green={dateType == "bookingDate"}
                        handleClick={() => setDateType("bookingDate")}
                      />
                      <Pill
                        name="Check-in Date"
                        selected={dateType == "checkIn"}
                        green={dateType == "checkIn"}
                        handleClick={() => setDateType("checkIn")}
                      />
                      <Pill
                        name="Check-out Date"
                        selected={dateType == "checkOut"}
                        green={dateType == "checkOut"}
                        handleClick={() => setDateType("checkOut")}
                      />
                    </div>
                  </Grid>
                )}

                {advanced && (
                  <Grid item xs={12}>
                    <div className={classes.dataTypeContainer}>
                      <Checkbox
                        color="primary"
                        checked={showJustUnallocated}
                        onChange={() =>
                          setShowJustUnallocated(!showJustUnallocated)
                        }
                        inputProps={{
                          "aria-label": t(
                            "reports.reservationPage.filterShowJustUnallocated"
                          ),
                        }}
                      />
                      <span className={classes.label}>
                        {t("reports.reservationPage.filterShowJustUnallocated")}
                      </span>
                    </div>
                  </Grid>
                )}

                {/* -------------ROOMTYPE SELECT DROPDOWN----------------- */}
                {advanced && !showJustUnallocated && (
                  <Grid item xs={12} md={6}>
                    <div className={classes.fieldContainer}>
                      <Select
                        styles={selectStyle}
                        isMulti
                        options={roomTypeArray}
                        onChange={setNewRoomType}
                        placeholder={t(
                          "reports.reservationPage.filterRoomType"
                        )}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                )}

                {/* --------------- ROOM SELECT DROPDOWN----------------- */}
                {advanced && roomTypeFilter.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <div className={classes.fieldContainer}>
                      <Select
                        styles={selectStyle}
                        isMulti
                        options={roomArray}
                        onChange={setNewRoom}
                        placeholder={t("reports.reservationPage.filterRoom")}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                )}

                {/* -------------BOOKING SOURCE SELECT DROPDOWN----------------- */}
                {advanced && (
                  <Grid item xs={12} lg={12}>
                    <div className={classes.fieldContainer}>
                      <Select
                        styles={selectStyle}
                        isMulti
                        placeholder="Filter by booking source ..."
                        options={bookingSources}
                        value={bookingSources.filter((option) =>
                          selectedBookingSources.includes(option.value)
                        )}
                        onChange={setBookingSource}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                )}

                {/* -------------RESERVATION STATUS SELECT DROPDOWN----------------- */}
                <Grid item xs={12} lg={12}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.fieldContainer}>
                        <Select
                          defaultValue={statusFilters}
                          styles={selectStyle}
                          isMulti
                          placeholder={t("reports.reservationPage.filterResv")}
                          options={reservationStatus}
                          onChange={setNewStatus}
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.listSection}>
              {loadingReservations && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}

              {!loadingReservations && (
                <BasicExport
                  t={t}
                  data={tableData}
                  currencyData={currencyData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  handleTableRowClick={handleTableRowClick}
                  openInNewTab={openInNewTab}
                  currencyData={currencyData}
                />
              )}
            </div>
          </div>

          {/* Open reservation when reservation item clicked */}
          {/* ------------------------------------------------------------------ */}
          {openReservation && (
            <Reservation
              open={openReservation}
              handleCloseReservation={handleCloseReservation}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentProperty: state.property,
  reservations: state.reports.reservations || {},
  roomTypes: state.roomTypes,
  rooms: state.rooms,
  live: state.live,
  loadingReservations: state.loading.LOAD_RESERVATIONS_BY_DAY,
});

export default withTranslation()(connect(mapStateToProps)(Reservations));
