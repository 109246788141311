

export default (state = {}, action) =>{

    let productID,
    gradeID,
    productData,
    gradesData,
    slotsData;

    switch (action.type) {

        case "FETCH_ACTIVITY_LIST_SUCCESS":
            return{
                ...state,
                activityList: { ...action.payload }
            }

        case "FETCH_CONDUCTOR_LIST_SUCCESS":
            return{
                ...state,
                conductorList: { ...action.payload }
            }
            
        // Calendar reducer methods.................................
        case "FETCH_CALENDAR_INFO_SUCCESS":
            return {
                ...state,
                calendarData: { ...action.payload.calendar },
                // productInfo: { ...action.payload.products },
                // conductorInfo: { ...action.payload.conductors }
            }

        case "DISPLAY_PRODUCTS":
            return {
                ...state,
                displayProductList: [ ...action.payload.products ]
            }

        case "DISPLAY_PRODUCT_DETAILS":
            return {
                ...state,
                displayProductDetails: { ...action.payload.productData }
            }

        case "RESET_PRODUCT_DISPLAY":
            return {
                ...state,
                displayProductDetails: {},
                displayProductList: []
            }
        

        // Activities reducer methods.................................

        case "ADD_UPDATE_ACTIVITY_SUCCESS":
        case "SAVE_ACTIVITY_IMAGE_SUCCESS":
        case "DELETE_ACTIVITY_IMAGE_SUCCESS":
        case "PUBLISH_PRODUCT_SUCCESS":
            
            productID = action.payload._id;
            productData = { ...action.payload };

            // //Retaining grades data format on edit product details .................... 
            // if(state.activityList[productID] && state.activityList[productID].grades){
            //     gradesData = { ...state.activityList[productID].grades };
            //     productData = { ...productData, grades: { ...gradesData,  } };
            // }

            return{
                ...state,
                activityList: {
                    ...state.activityList,
                    ...productData
                }
            }
        
        case "DELETE_ACTIVITY_SUCCESS":

            productID = action.payload;
            productData = {};
            
            Object.keys(state.activityList).forEach(id =>{
                if(id != productID){
                    productData[id] = { ...state.activityList[id] } 
                }
            })
            
            return{
                ...state,
                activityList: {
                    ...productData
                }
            }
            

        case "ADD_UPDATE_GRADE_SUCCESS":

            productID = action.payload.productID;
            productData = state.activityList[productID];
            gradesData = { ...productData.grades, ...action.payload.grade };
            
            return{
                ...state,
                activityList: {
                    ...state.activityList,
                    [productID]: { 
                        ...productData,
                        grades: { ...gradesData }
                    }
                }
            }

        case "ADD_SLOT_SUCCESS":
        case "UPDATE_SLOT_SUCCESS":

            productID = action.payload.productID;
            gradeID = action.payload.gradeID;
            productData = { ...state.activityList[productID] };
            gradesData = { ...productData.grades };
            slotsData = { ...gradesData[gradeID].slots, ...action.payload.slot}
            return{
                ...state,
                activityList: {
                    ...state.activityList,
                    [productID]: { 
                        ...productData,
                        grades: { 
                            ...gradesData, 
                            [gradeID]: { 
                                ...gradesData[gradeID],
                                slots: { ...slotsData } 
                            }
                        }
                    }
                }
            }
        
        case "ADD_UPDATE_CONDUCTOR_SUCCESS":
            return{
                ...state,
                conductorList: {
                    ...state.conductorList,
                    [action.payload._id]: { ...action.payload }
                }
            }

        case "FETCH_RATES_AVAILABILITY_SUCCESS":
            return{
                ...state,
                productRatesList: [ ...action.payload ]
            }

        case "FETCH_BOOKING_DETAILS_SUCCESS":
            return{
                ...state,
                bookingDetails: {...action.payload }
            }
        case "UPDATE_BOOKING_SUCCESS":
            return{
                ...state,
                bookingDetails: {...action.payload.booking }
            }

        case "FETCH_NOTICEBOARD_DETAILS_SUCCESS":
            return {
                ...state,
                noticeboard: action.payload
            }
        case "DELETE_NOTICEBOARD_ITEM_SUCCESS":
            const newState = {
                ...state.noticeboard  
            }
            delete newState[action.payload.id]
            return {
                ...state,
                noticeboard: {...newState},
            }
        default:
            return state;
    }
}