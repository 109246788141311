import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment-timezone';

import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import currencyCodes from 'currency-codes';
import timezoneOptions from '../timezones';

import { updateHWMigrationInfo } from '../../../redux/actions/hwMigration/hwMigration';

const useStyles = makeStyles(theme =>({
    root: {
        margin: "0px auto",
        maxWidth: "360px",
        padding: "10px 0px",
    },
    name: {
        fontSize: '1.4rem',
        color: '#484848',
    },
    formControl: {
        marginBottom: '25px'
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    dateTime: {
        color: "#666666",
        fontSize: "1.1rem",
        marginTop: "5px",
        textAlign: "end",
        fontWeight: 500,
    },
    displayField: {
        borderRadius: '8px',
        padding: '12px',
        backgroundColor: "#f4f4f4",
    }
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: `1px solid ${state.theme.borderColor}`,
      boxShadow: 0,
      cursor: 'pointer',
      '&:hover': { border: `1px solid ${state.theme.borderColor}` },
    }),
    indicatorSeparator: (base, state) =>({
        display: 'none'
    })
};

const getSelectTheme = (theme, errorStatus) =>({
    ...theme,
    borderColor: errorStatus ? '#f44336' : '#e0e0e0'
});

const handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
}

const handleGetCurrencies = () => {
    let codeArray = []
    currencyCodes.codes().map(e => codeArray.push({ value: e, label: e }))
    return codeArray;
}

const initFormData = {
    timezone: null, 
    currency: null,
    country: null
}

const HotelDetails = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { nextStep, stepInfo, space, ongoingStep } = props;
    const countryOptions = handleGetCountries();
    const currencyOptions = handleGetCurrencies();

    const [formData, setFormData] = useState({ ...initFormData });
    const [error, setError] = React.useState({
        timezone: false, 
        currency: false,
        country: false
    });

    useEffect(() =>{
        const formInfo = { ...initFormData, ...stepInfo };
        setFormData(formInfo);
    }, [stepInfo]);

    const onSelectHandler = (data, type) =>{
        setFormData({ ...formData, [type]: data.value })
        setError({ ...error, [type]: false })
    }

    const validateHostelDetails = () =>{
        let foundError = false;
        let errorStep = {};
        const inputFields = ['timezone', 'currency', 'country'];

        inputFields.forEach(inputID =>{
            if (!formData[inputID]) {
                errorStep[inputID] = true;
                foundError = true;
            }
        })

        if (foundError) {
            setError({...error, ...errorStep });
            return false;
        }
        return true;
    }

    const submitHandler = () =>{

        if(ongoingStep > 2){
            nextStep(); 
            return;
        }

        if(!validateHostelDetails()) return;

        const { activeStep, dispatch, updateAPIParams } = props;
        const data = { ...formData };

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data))
        .then(response =>{
            if(response.ongoingStep) nextStep(); 
        });
    }

    const getSelectedValue = (options, value) =>{
        const selectedVal = options.find(item => item.value == value);
        return selectedVal;
    }

    //DISPLAY STATIC HOSTEL INFO ONCE HOSTEL INFO IS SET................
    if(ongoingStep > 2){
        return(
            <div className={classes.root}>
                
                <div className="title">{t('hwMigration.title.step2')}</div>

                {/* ------------HOSTEL NAME------------------ */}
                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.hostelName')}</div>
                    <div className={classes.displayField}>{space?.name || ''}</div>
                </div>

                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.timezone')}</div>
                    <div className={classes.displayField}>{getSelectedValue(timezoneOptions, stepInfo?.timezone)?.label}</div>
                    <div className={classes.dateTime}>Current Time: {moment().tz(stepInfo?.timezone).format('DD/MM/YYYY hh:mm A')}</div>
                </div>

                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.currency')}</div>
                    <div className={classes.displayField}>{getSelectedValue(currencyOptions, stepInfo?.currency)?.label}</div>
                </div>

                <div className={classes.formControl}>
                    <div className="label">{t('hwMigration.formLabels.country')}</div>
                    <div className={classes.displayField}>{getSelectedValue(countryOptions, stepInfo?.country)?.label}</div>
                </div>

                <Button 
                    onClick={submitHandler}
                    className="nextButton"
                >
                    {t('actions.next')}
                </Button>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className="title">{t('hwMigration.title.step2')}</div>


            {/* ------------HOSTEL NAME------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.hostelName')}</div>
                <div className={classes.name}>{space.name}</div>
            </div>
 
            {/* ------------TIMEZONE SELECT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.timezone')}</div>
                <Select
                    value={getSelectedValue(timezoneOptions, formData.timezone)}
                    name="timezone"
                    options={timezoneOptions}
                    styles={selectStyle}
                    theme={t => getSelectTheme(t, error.timezone)}
                    onChange={data => onSelectHandler(data, 'timezone')}
                />

                {formData.timezone && (
                    <div className={classes.dateTime}>Current Time: {moment().tz(formData.timezone).format('DD/MM/YYYY hh:mm A')}</div>
                )}

                {error.timezone && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.timezone')}</FormHelperText>
                )}
            </div>

            {/* ------------CURRENCY SELECT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.currency')}</div>
                <Select
                    name="currency"
                    value={getSelectedValue(currencyOptions, formData.currency)}
                    options={currencyOptions}
                    styles={selectStyle}
                    theme={t => getSelectTheme(t, error.currency)}
                    onChange={data => onSelectHandler(data, 'currency')}
                />
                {error.currency && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.currency')}</FormHelperText>
                )}
            </div>

            {/* ------------COUNTRY SELECT------------------ */}
            <div className={classes.formControl}>
                <div className="label">{t('hwMigration.formLabels.country')}</div>
                <Select
                    name="country"
                    value={getSelectedValue(countryOptions, formData.country)}
                    options={countryOptions}
                    styles={selectStyle}
                    theme={t => getSelectTheme(t, error.country)}
                    onChange={data => onSelectHandler(data, 'country')}
                />
                {error.country && (
                    <FormHelperText className="errorMessage">{t('hwMigration.formErrors.country')}</FormHelperText>
                )}
            </div>

            <Button 
                onClick={submitHandler}
                className="nextButton"
            >
                {t('actions.next')}
            </Button>

        </div>
    )
}

const mapStateToProps = state =>{
    const { hwMigration } = state;
    return { 
        stepInfo: hwMigration?.step2 || {},
        space: hwMigration?.space || {},
    }
}

export default connect(mapStateToProps)(HotelDetails);