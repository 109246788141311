import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import { publishProduct } from '../../../../redux/actions/experiences/activities';

import Footer from '../Footer';
import { validateArrayKeyHasValue, validateArrayLength, validateText, validateWordCount, } from '../activityHelper';

const useStyles = makeStyles(theme =>({
    listText: {
        paddingLeft: '15px',
        marginBottom: '20px',
        '& li':{
            marginBottom: '5px',
        }
    },
}));

const ERROR_MESSAGE = {
    language: 'Please add a language for the experience',
    description: 'Description should be at least 100 words',
    categories: 'Please add a theme for the experience',
    locations: 'Please add a location for the experience',
    inclusions: 'Please set a provision for the experience',
    exclusions: 'Please set a requirement for the experience',
    photos: 'Please upload a cover photo for the experience',
};

const ActivityGuidelines = props =>{
    const classes = useStyles();
    const { 
        prevStepHandler, 
        activeStepInfo,
        productData,
        dispatch,
        closeModalHandler,
        publishMessageHandler
    } = props;

    const [ errorMsg, setErrorMessage ] = useState('');

    const validatePublish = () =>{
        // Validate if description atleast 100 words
        if(!validateWordCount(productData?.description || '')){
            return { success: false, error: ERROR_MESSAGE.description }
        }
        // Validate if theme is added
        if (!validateArrayLength(productData?.categories || [])) {
            return { success: false, error: ERROR_MESSAGE.categories }
        }
        // Validate if language is added
        if(!validateText(productData?.language || '')){
            return { success: false, error: ERROR_MESSAGE.language }
        }
        //  Validate if location is added
        if (!validateArrayLength(productData?.locations || [])) {
            return { success: false, error: ERROR_MESSAGE.locations }
        }
        //  Validate if provision is added
        if (!validateArrayLength(productData?.inclusions || [])) {
            return { success: false, error: ERROR_MESSAGE.inclusions }
        }
        // Validate if requirement is added
        if (!validateArrayLength(productData?.exclusions || [])) {
            return { success: false, error: ERROR_MESSAGE.exclusions }
        }
        // Validate if cover photo is uploaded
        if (!validateArrayKeyHasValue(productData?.photos || [], 'type', 'cover')) {
            return { success: false, error: ERROR_MESSAGE.photos }
        }

        return { success: true };
    }

    const sumbitHandler = () =>{

        const validation = validatePublish();
        if(!validation.success){
            setErrorMessage(validation.error);
            return;   
        }
        
        if(productData?.isPublished){
            publishMessageHandler(true);
            closeModalHandler();
            return;
        }

        const response = dispatch(publishProduct(productData._id));
        response.then(success =>{
            if(success) {
                publishMessageHandler(true);
                closeModalHandler();
            }
        });
    }


    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Practise good hygiene</div>
                
                <div className="sub-title">
                    
                    <p>While hosting, wear a protective face covering, and wash your hands regularly.</p> 
                    
                    <ul className={classes.listText}>
                        <li>All hosts and guests of experiences must wear facial coverings. Check your relevant regulatory authority for guidance on specific rules in your region.</li>
                        <li>Wash your hands before, during and after your experience. Scrub for at least 20 seconds with warm soap and water.</li>
                        <li>Bring hand sanitizer for you and your guests.</li>
                    </ul>

                </div>
            </div>

            {/* ------------- Snackbar error message -------------- */}
            {Boolean(errorMsg) && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={()=>setErrorMessage('')}
                    autoHideDuration={6000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{errorMsg}</span>}
                />
            )}

            <Footer 
                nextClickHandler = {sumbitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                nextStepLable = "Save and Publish"
                productData = {productData}
            />
        </>
    )
}

export default connect(null)(ActivityGuidelines); 