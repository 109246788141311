import moment from 'moment';

const dateSort = (array) => {
  array.sort(function compare(a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);
    return dateA - dateB;
  });
  return array
}

// Process occupancy to display as bar chart or grouped bar chart
// ------------------------------------------------------------------------
const occupancyProcessor = (occupancyData, yoy, level, submittedDates) => {
  
  let graphData = []
  let difference = level==='daily' ? 'day' : 'month';
  let tableData = [];
  let hasDormRooms = false;
  let hasPrivateRooms = false;
  
  if (occupancyData && occupancyData.currentYear) {
    
    let occArray = occupancyData.currentYear.totalOccupancy ? Object.values(occupancyData.currentYear.totalOccupancy) : [];
    let roomArray = occupancyData.currentYear.roomCategoryOccupancy ? Object.values(occupancyData.currentYear.roomCategoryOccupancy) : [];
    
    roomArray.forEach(data =>{
      if(data.dormOrPrivate ==='dorm') hasDormRooms = true;
      if(data.dormOrPrivate ==='private') hasPrivateRooms = true;
    });

    let dateVar = submittedDates.startDate.clone().subtract(1, difference);
    do {
      dateVar = dateVar.add(1, difference);
      graphData.push({
        "date": level==='daily' ? dateVar.format('DD MMM, YYYY') : dateVar.format('MMM YYYY'), 
        "occupancy":  occArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
                      occArray.find(e => moment(e.date).isSame(dateVar, difference)).occupancy*100 : 
                      0
      })

      tableData.push({
        date: level==='daily' ? dateVar.format('DD MMM, YYYY') : dateVar.format('MMM YYYY'), 
        total: occArray.find(e => moment(e.date).isSame(dateVar, difference)) ? 
               `${parseFloat(occArray.find(e => moment(e.date).isSame(dateVar, difference)).occupancy*100).toFixed(2)}%` : 
               '0.00%',
        dorm: roomArray.find(e => e.dormOrPrivate==='dorm' && moment(e.date).isSame(moment(dateVar), difference)) ? 
              `${parseFloat(roomArray.find(e => e.dormOrPrivate==='dorm' && moment(e.date).isSame(moment(dateVar), difference)).occupancy*100).toFixed(2)}%` : 
              '0.00%',
        private: roomArray.find(e => e.dormOrPrivate==='private' && moment(e.date).isSame(moment(dateVar), difference)) ? 
              `${parseFloat(roomArray.find(e => e.dormOrPrivate==='private' && moment(e.date).isSame(moment(dateVar), difference)).occupancy*100).toFixed(2)}%` : 
              '0.00%',
      })

    } while (!dateVar.isSame(submittedDates.endDate, difference))   

    if (yoy && occupancyData.lastYear) {
      let occArray2 = occupancyData.lastYear.totalOccupancy ? Object.values(occupancyData.lastYear.totalOccupancy) : [];
      let tempData = [];
  
      if (level==='daily') {
        graphData.map(item => {
          tempData.push({
            date: item.date,
            ['Current']: item.occupancy,
            ['Previous']: occArray2.find(e => moment(e.date).isSame(moment(item.date, 'DD MMM'), 'date')) ? 
                          occArray2.find(e => moment(e.date).isSame(moment(item.date, 'DD MMM'), 'date')).occupancy*100 : 
                          0
          })
        });
      } else if (level==='monthly') {
        graphData.map(item => {
          tempData.push({
            date: item.date,
            ['Current']: item.occupancy,
            ['Previous']: occArray2.find(e => moment(e.date).isSame(moment(item.date, 'MMM YY'), 'date')) ? 
                          occArray2.find(e => moment(e.date).isSame(moment(item.date, 'MMM YY'), 'date')).occupancy*100 : 
                          0
          })
        });
      }
      
      graphData=tempData;
    }
  }

  // console.log('processed occupancy data', data);
  return {graphData, tableData, hasDormRooms, hasPrivateRooms };
}

export { occupancyProcessor };