import http from "../../utils/http";

//----------------------------------------------------------------
// CREATE NEW FOLDER
//----------------------------------------------------------------
export function createNewFolder(spaceID, name) {
  const ACTION_NAME = "CREATE_NEW_FOLDER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/folder', {spaceID, name})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT FOLDER NAME
//----------------------------------------------------------------
export function editFolderName(folderID, name) {
  const ACTION_NAME = "EDIT_FOLDER_NAME";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/folder/${folderID}`, {name})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// DELETE FOLDER
//----------------------------------------------------------------
export function deleteFolder(spaceID, folderID) {
  const ACTION_NAME = "DELETE_FOLDER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/folder/${folderID}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {folderID, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

