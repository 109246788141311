import IMAGES from "../constants/images";

const MENU = {
  live: {
    id: "live",
    name: "Live",
    color: "#CC6666",
    icon: IMAGES.ICONS.live,
    selectedIcon: IMAGES.ICONS.liveSelected,
    hasSubMenu: false,
    defaultSubmenu: null,
    submenu: {},
  },
  beds: {
    id: "beds",
    name: "Rooms",
    color: "#006699",
    icon: IMAGES.ICONS.bed,
    selectedIcon: IMAGES.ICONS.bedSelected,
    hasSubMenu: true,
    defaultSubmenu: "calendar",
    submenu: {
      calendar: {
        id: "calendar",
        name: "Calendar",
      },
      manage: {
        id: "manage",
        name: "Manage",
      },
      channels: {
        id: "channels",
        name: "Channels",
      },
      reports: {
        id: "reports",
        name: "Reports",
      },
      automate: {
        id: "automate",
        name: "Automate",
      },
      frontdesk: {
        id: "frontdesk",
        name: "Frontdesk",
      },
    },
  },
  linkups: {
    id: "linkups",
    name: "Linkups",
    color: "#006699",
    icon: IMAGES.ICONS.experiences,
    selectedIcon: IMAGES.ICONS.experiencesSelected,
    hasSubMenu: false,
  },
  experience: {
    id: "experience",
    name: "Experiences",
    color: "#CC6633",
    icon: IMAGES.ICONS.experiences,
    selectedIcon: IMAGES.ICONS.experiencesSelected,
    hasSubMenu: true,
    defaultSubmenu: "calendar",
    submenu: {
      calendar: {
        id: "calendar",
        name: "Calendar",
      },
      manage: {
        id: "manage",
        name: "Manage",
      },
      reports: {
        id: "reports",
        name: "Reports",
      },
      // automate: {
      //   id: "automate",
      //   name: "Automate"
      // }
    },
  },
  pos: {
    id: "pos",
    name: "POS",
    color: "#996666",
    icon: IMAGES.ICONS.pos,
    selectedIcon: IMAGES.ICONS.posSelected,
    hasSubMenu: true,
    defaultSubmenu: "manage",
    submenu: {
      manage: {
        id: "manage",
        name: "Manage",
      },
      reports: {
        id: "reports",
        name: "Reports",
      },
    },
  },
  team: {
    id: "team",
    name: "Team",
    color: "#C2AE7F",
    icon: IMAGES.ICONS.team,
    selectedIcon: IMAGES.ICONS.teamSelected,
    hasSubMenu: true,
    defaultSubmenu: "noticeboard",
    submenu: {
      noticeboard: {
        id: "noticeboard",
        name: "Noticeboard",
      },
      chat: {
        id: "chat",
        name: "Group Chat",
      },
      todos: {
        id: "todos",
        name: "To Dos",
      },
      files: {
        id: "files",
        name: "Files",
      },
      members: {
        id: "members",
        name: "Members",
      },
    },
  },
};

export { MENU };
