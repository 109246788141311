import React from 'react';
import { connect } from 'react-redux';
import { fetchMyAllocatorProperties, mapMyAllocatorToProperty } from '../../redux/actions/channelMapping/myAllocator';

import TokenForm from './TokenForm';
import PropertyMapForm from './PropertyMapForm';

const MyAllocator = (props) => {
  const { properties, currentSpace, userToken, viewCCPassword, mappedChannel, constructedToken, showEditCCpassword } = props;

  const handleSubmitToken = (token, viewCCPassword) => {
    props.dispatch(fetchMyAllocatorProperties(token, viewCCPassword, currentSpace.propertyID))
  }

  const handleSubmitMappedProperty = (myAllocatorID) => {
    props.dispatch(mapMyAllocatorToProperty({
      mya_propertyID: myAllocatorID,
      propertyID: currentSpace.propertyID,
      userToken: userToken,
      viewCC: viewCCPassword
    }));
  }

  return (
    <>
      <TokenForm
        handleSubmit={handleSubmitToken}
        constructedToken={constructedToken}
      />
      {Object.keys(properties).length > 1 &&
        <PropertyMapForm handleSubmit={handleSubmitMappedProperty} properties={properties} isDisabled={mappedChannel}/>
      }
    </>
  )
}

const mapStateToProps = state => {
  let constructedToken = state.myAllocator.userToken;
  if (constructedToken) constructedToken = '******' + (constructedToken.substring(constructedToken.length - 4));
  return {
    properties: state.myAllocator.properties,
    constructedToken: constructedToken || null,
    userToken: state.myAllocator.userToken,
    viewCCPassword: state.myAllocator.viewCCPassword || '',
  }
};
export default connect(mapStateToProps)(MyAllocator);