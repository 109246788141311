import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography, CardActions } from '@material-ui/core';
import PlusMinus from './PlusMinus';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 200,
    padding: 20,
    margin: 10,
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0
    }
  },
  media: {
    height: 0,
    paddingTop: '65%',
    borderRadius: 2
  },
  label: {
    padding: '16px 0 0'
  },
  action: {
    cursor: 'pointer'
  },
  selected: {
    backgroundColor: theme.palette.grey[300],
  },
  CardActions: {
    padding: '8px 0!important'
  },
  labelOne: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#bdbdbd',
    marginTop: 10
  },
  labelTwo: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginTop: 15
  },
}));

const Item = (props) => {
  const classes = useStyles();
  const { data, itemID, multiple, selected, prefilledCount } = props;

  const onClickItem = () => {
    if (multiple) return;
    if (selected) props.onCountChange(0, itemID);
    if (!selected) props.onCountChange(1, itemID);
  }

  return (
    <Card className={clsx({
      [classes.root]: true,
      [classes.action]: !multiple,
      [classes.selected]: !multiple && selected
    })} onClick={() => onClickItem(itemID, selected)}>

      <CardMedia className={classes.media} image={data.image} />
      <CardContent className={classes.label}>
        <Typography className={classes.title}>{data.name}</Typography>
        {data.labelOne && <Typography className={classes.labelOne}>{data.labelOne}</Typography>}
        {data.labelTwo && <Typography className={classes.labelTwo}>{data.labelTwo}</Typography>}
      </CardContent>

      { multiple && (
        <CardActions className={classes.CardActions}>
          <PlusMinus maximum={data.maximum} fullWidth={true} itemID={itemID} onCountChange={(c, i) => props.onCountChange(c, i, data)} prefilledCount={prefilledCount} />
        </CardActions>
      )}
    </Card>
  )
}

export default (Item);