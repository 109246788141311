import React from 'react';
import { makeStyles } from '@material-ui/core';
import { COUNTER_ADDRESS } from '../constants';

const useStyles = makeStyles( theme => ({
  root: {
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: '1.5',
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 15,
    marginTop: 15,
  },
  subHeader: {
    fontWeight: 600,
    fontSize: '1.3rem',
    marginTop: 20,
  },
  text: {
    marginLeft: 10,
    marginBottom: 10,
  },
  subText: {
    marginLeft: 10
  },
  link: {
    color: 'blue'
  }
}))

export default () => {

  const classes=  useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.text}>
        <p>These terms and conditions (the “Terms”) govern the agreement(s) concluded between Counter App Limited, a
        company incorporated in Ireland at {COUNTER_ADDRESS} (“Counter”, “we”,
        “us” or “our”) and you for PMS Plus Add-ons (as defined below). By clicking to accept these Terms, or using,
        enabling or accessing the PMS Plus Add-ons, you agree to these Terms which you acknowledge represent a binding
        legal agreement between you and Counter. If you are agreeing to these Terms on behalf of a company or other
        organisation, you represent that you have the authority to bind that company or organisation (including all agents) to
        these Terms. If you do not have that authority, or if you do not agree with these Terms, you may not use the PMS
        Plus Add-ons.</p>
      </div>

      <div className={classes.subHeader}>1.	PMS PLUS ADD-ONS</div>
      <div className={classes.text}>
        <p>1.1. The PMS Plus Add-ons is comprised of the Booking Engine, the Booking Management Tool, the Payments System, the
          Revenue Management System, and the Integration System. The PMS Plus Service allows you to integrate the Booking
          Engine, the Payments System, and the Integration System into your Core PMS and also with software solutions provided
          by third-parties.
        </p>
        <p>1.2.	Counter shall, during the Term, provide the PMS Plus Add-ons and make available the PMS Plus Add-on Documentation
          to you on and subject to these Terms.</p>
        <p>1.3.	<strong>Booking Engine</strong>
          <div className={classes.subText}>
            <p>1.3.1. The Booking Engine software is designed to be displayed on your Website and will enable you to take bookings
              from guests. Guests can search for availability at your property, book accommodation and make payments to you.
              The guest can pay a deposit or pay the full amount to you via the Booking Engine.</p>
            <p>1.3.2. In providing the Booking Engine, Counter will provide to you:
              <div className={classes.subText}>
                <p>(i) software to enable the search for and booking of accommodation for your own properties on your Website; and <br/></p>
                <p>(ii) code such as HTML, CSS and/or Javascript that you will add to your Website.</p>
              </div>
            </p>
          </div>
        </p>
        <p>1.4. <strong>Payments System</strong>
          <div className={classes.subText}>
            <p>1.4.1. The Payment System will enable you to collect payments from your guests directly via their bank payment card.</p>
            <p>1.4.2. Payments received through the Payments System will be paid, subject to Counter’s Payment Processing Fee,
              from Counter’s Stripe Platform Account to your Stripe Connect Account in accordance with Section 7.</p>
          </div>
        </p>
        <p>1.5. <strong>Integration System</strong>
          <div className={classes.subText}>
            <p>1.5.1. The Integration System will enable your Core PMS to integrate with software solutions provided by third parties
            such as accounting and bookkeeping software, QuickBooks, Xero, Twilio, Mailchimp, etc.</p>
            <p>1.5.2. Counter will provide the technical means to enable:
              <div className={classes.subText}>
                <p>(i) the integration of your Core PMS with third-party software solutions with appropriate access authentications; and</p>
                <p>(ii) revocation of access as required.</p>
              </div>
            </p>
            <p>1.5.3. If you integrate your Core PMS with third-party software solution using the Integration System, you will be using
            Third Party Materials (as defined below) which will be subject to the Third Party Terms (as defined below).</p>
          </div>
        </p>    
        <p>1.6. <strong>Revenue Management System</strong>
          <div className={classes.subText}>
            <p>1.6.1 The Revenue Management System will enable your hostel to automatically set prices based on overarching
            pricing guidelines provided by you.</p>
            <p>1.6.2 You agree that all prices outputted by the Revenue Management System have been vetted and verified by you
            and that Counter will not be liable for any bookings made at these prices.</p>
          </div>
        </p>
        <p>1.7. <strong>Booking Management Tool</strong>
          <div className={classes.subText}>
            <p>1.7.1 The Booking Management Tool will allow your hostel to provide self-service features to your guests such as
            check-in and check-out, booking cancellations, room upgrades and extensions, adding meals etc.</p>
          </div>
        </p>    
        <p>1.8. Counter shall use commercially reasonable endeavours to make the PMS Plus Add-ons available 24 hours a day, seven
          days a week, except for:
          <div className={classes.subText}>
            <p>(i) planned maintenance carried out during the maintenance window of 4:00 am to 7.00 am Irish time; and</p>
            <p>(ii) unscheduled maintenance performed outside Normal Business Hours, provided that Counter has used reasonable
            endeavours to give you at least 6 Normal Business Hours’ notice in advance.</p>
          </div>
        </p>
        <p>1.9. Counter will, as part of the PMS Plus Add-ons, provide you with Counter’s standard customer support services during
          Normal Business Hours.</p>
        <p>1.10. You acknowledges and agrees that parts of the PMS Plus Add-ons may be released by Counter in a beta testing version
          from time to time, and that in such case, you should use the PMS Plus Software with that standard of stability in mind.</p>
      </div>
      
      <div className={classes.subHeader}>2.	CUSTOMER DATA</div>
      <div className={classes.text}>
        <p>2.1. You shall own all rights, title and interest in and to all your Data and you shall have sole responsibility for the legality,
          reliability, integrity, accuracy and quality of all such Customer Data.</p>
        <p>2.2. Both parties will comply with all applicable requirements of the Data Protection Legislation. This Section 2 is in
          addition to, and does not relieve, remove or replace, a party’s obligations or rights under the Data Protection
          Legislation.</p>
        <p>2.3. The parties acknowledge that in performing its obligations under these Terms, Counter may process personal data on
          your behalf. In such circumstances, Counter acknowledges that you are the controller and Counter is a processor
          and Counter shall:
          <div className={classes.subText}>
            <p>2.3.1. process such personal data only in accordance with your documented written instructions unless Counter is
              required by the laws of EU Member State or by the laws of the EU applicable to Counter to process personal
              data;</p>
            <p>2.3.2. ensure that all personnel who have access to and/or process personal data are obliged to keep the personal
              data confidential;</p>
            <p>2.3.3. not transfer any personal data outside of the European Economic Area unless it has obtained your prior
              written consent to do so and the following conditions are fulfilled:
              <div className={classes.subText}>
                <p>(i) you or Counter has provided appropriate safeguards in relation to the transfer;</p>
                <p>(ii) the data subject has enforceable rights and effective legal remedies;</p>
                <p>(iii) Counter complies with its obligations under the Data Protection Legislation by providing an adequate
                  level of protection to any personal data that is transferred; and</p>
                <p>(iv) Counter complies with reasonable instructions notified to it in advance by you with respect to the
                  processing of the personal data;</p>
              </div>
            </p>
            <p>2.3.4. assist you, at your cost, in responding to any request from a data subject and in ensuring compliance with
              your obligations under the Data Protection Legislation with respect to security, breach notifications, impact
              assessments and consultations with supervisory authorities or regulators;</p>
            <p>2.3.5. at your written direction, delete or return personal data and copies thereof to you on termination of the
              agreement unless required by applicable law to store the personal data;</p>
            <p>2.3.6. notify you without undue delay on becoming aware of a personal data breach; and</p>
            <p>2.3.7. maintain complete and accurate records and information to demonstrate its compliance with this Section 2 and
              allow for audits by you or your designated auditor. Without prejudice to the generality of Section 2.2, you will
              ensure that you have all necessary appropriate consents and notices in place to enable lawful transfer of the
              personal data to Counter for the duration and purposes of these Terms so that Counter may lawfully use,
              process and transfer the personal data in accordance with these Terms on your behalf.</p>
          </div>
        </p>
        <p>2.4. Each party shall implement such technical and organisational security measures as are required to comply with the
          data security obligations under Data Protection Legislation.</p>
        <p>2.5. Counter is authorised to engage sub-processors to undertake processing on its behalf and shall ensure that a written
          contract exists between Counter and the sub-processor containing Sections equivalent to those imposed on Counter
          in this Section. In the event that any sub-processor fails to meet its data protection obligations, Counter shall remain
          fully liable to you for the performance of the sub-processor’s obligations.</p>
      </div>

      <div className={classes.subHeader}>3.	THIRD PARTY MATERIALS</div>
      <div className={classes.text}>
        <p>3.1. You acknowledge that the PMS Plus Add-ons functionality may enable or assist you to access products, services,
          information, data, content and other materials made available by or on behalf of third parties (<strong>“Third Party
          Materials”</strong>) or allow for the routing or transmission of Third Party Materials, including via links, and that you do so
          solely at your own risk. By using such functionality, you are directing Counter to access, route and transmit to you the
          applicable Third Party Materials. In addition, your integration and other use of Third Party Materials may result in
          transmission of your information, data, content or other materials outside of the Core PMS, or otherwise result in
          third-party access to or use of your information, data, content or other materials. Counter neither controls nor
          endorses, nor is Counter responsible for, any Third Party Materials, including their accuracy, validity, timeliness,
          completeness, reliability, integrity, quality, legality, usefulness or safety, or any applicable intellectual property rights.</p>
        <p>3.2. Certain Third Party Materials may, among other things, be inaccurate, misleading or deceptive. Nothing in these
          Terms will be deemed to be a representation or warranty by Counter with respect to any Third Party Materials.
          Counter has no obligation to monitor Third Party Materials. In addition, the availability for integration of any Third
          Party Materials through the Integration System does not imply Counter’s endorsement of, or affiliation with, any
          provider of such Third Party Materials, nor does it create any legal relationship between you and any such provider.
          Counter makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in
          relation to the content or use of, or correspondence with, any such Third Party Materials, or any transactions
          completed, and any contract entered into by you, with any such third party.</p>
        <p>3.3. Any contract entered into and any transaction completed with any third-party is between you and the relevant third
          party, and not Counter. Your use of Third Party Materials is at your own risk and is subject to any additional terms,
          conditions and policies applicable (<strong>“Third Party Terms”</strong>) to such Third Party Materials. Counter recommends that
          you refer to such Third Party Terms prior to integrating and/or using any Third Party Materials.</p>
      </div>

      <div className={classes.subHeader}>4.	COUNTER’S OBLIGATIONS</div>
      <div className={classes.text}>
        <p>4.1. Counter undertakes that the PMS Plus Add-ons will be performed substantially in accordance with the PMS Plus
          Add-on Documentation and with reasonable skill and care.</p>
        <p>4.2. The undertaking at Section 4.1 shall not apply to the extent of any non-conformance which is caused by use by you
          of the PMS Plus Add-ons contrary to Counter’s instructions, or modification or alteration of the PMS Plus Add-ons by
          any party other than Counter or Counter’s duly authorised contractors or agents. If the PMS Plus Add-ons do not
          conform with the foregoing undertaking, Counter will, at its expense, use all reasonable commercial endeavours to
          correct any such non-conformance promptly, or provide you with an alternative means of accomplishing the desired
          performance. Such correction or substitution constitutes your sole and exclusive remedy for any breach of the
          undertaking set out in Section 4.1.</p>
        <p>4.3. These Terms shall not prevent Counter from entering into similar agreements with third parties, or from independently
          developing, using, selling or licensing documentation, products and/or services which are similar to those provided
          under these Terms.</p>
        <p>4.4. Counter is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of
          data over communications networks and facilities, including the internet, and you acknowledge that the PMS Plus
          Add-ons and PMS Plus Add-on Documentation may be subject to limitations, delays and other problems inherent in
          the use of such communications facilities.</p>
        <p>4.5. Counter does not warrant that:
          <div className={classes.text}>
            <p>4.5.1. your use of the PMS Plus Add-ons will be uninterrupted or error-free;</p>
            <p>4.5.2. the PMS Plus Add-ons, PMS Plus Add-on Documentation and/or the information obtained by you through the
              PMS Plus Add-ons will meet your requirements; or</p>
            <p>4.5.3. the PMS Plus Add-ons will be free from Vulnerabilities.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>5.	YOUR OBLIGATIONS</div>
      <div className={classes.text}>
        <p>5.1. You shall:
          <div className={classes.subText}>
            <p>5.1.1. provide Counter, in order to enable Counter to provide the PMS Plus Add-ons, including but not limited to
            Customer Data, security access information and configuration services, with:
              <div className={classes.subText}>
                <p>(i) all necessary co-operation in relation to these Terms; and</p>
                <p>(ii) all necessary access to such information as may be required by Counter;</p>
              </div>
            </p>
            <p>5.1.2. without affecting its other obligations under these Terms, comply with all applicable laws and regulations with
            respect to its activities under these Terms;</p>
            <p>5.1.3. carry out all of your other responsibilities set out in these Terms in a timely and efficient manner. In the event
            of any delays in your provision of such assistance as agreed by the parties, Counter may adjust any agreed
            timetable or delivery schedule as reasonably necessary;</p>
            <p>5.1.4. ensure that the PMS Plus Add-ons and the PMS Plus Add-on Documentation are used in accordance with
            these Terms and shall be responsible for any breach of these Terms resulting from a misuse of the PMS Plus
            Add-ons and/or the PMS Plus Add-on Documentation;</p>
            <p>5.1.5. obtain and maintain all necessary licences, consents, and permissions necessary for Counter, its contractors
            and agents to perform their obligations under these Terms, including without limitation the PMS Plus Add-ons;</p>
            <p>5.1.6. ensure that your computer network and systems comply with the relevant specifications provided by Counter
            from time to time; and</p>
            <p>5.1.7. be, to the extent permitted by law and except as otherwise expressly provided in these Terms, solely
            responsible for procuring, maintaining and securing your computer network connections and
            telecommunications links from your systems to Counter's data centres, and all problems, conditions, delays,
            delivery failures and all other loss or damage arising from or relating to your computer network connections or
            telecommunications links or caused by the internet.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>6.	COUNTER FEES AND PAYMENT</div>
      <div className={classes.text}>
        <p>6.1. In consideration of the provision of the PMS Plus Add-ons and PMS Plus Add-on Documentation by Counter to you
          under these Terms, you shall pay to Counter the PMS Plus Add-on Fees to Counter for the in accordance with this
          Section 6.</p>
        <p>6.2. The PMS Plus Add-on Fees that apply per month, per Bed and displayed on the Core PMS (regardless of booking
          status) will vary according to your geographical location and will be dynamically updated and displayed on the PMS
          Plus Add-ons accordingly. We reserve the right in our sole discretion to make changes to the PMS Plus Add-on Fees
          by giving no less than 30 days' notice of such changes to you. If you do not agree to these changes, you may cancel
          your subscription.</p>
        <p>6.3. You will pay the PMS Plus Add-on Fees (and any other amounts) due to Counter under these Terms free and clear of
          all taxes, deductions or withholdings whatsoever, save only as may be required by law. You agree that if such taxes,
          deductions or withholdings are required by law, you shall pay to Counter such additional amount as may be
          necessary in order that the net amount received by Counter after such taxes, deductions and withholdings shall not
          be less than the amount Counter would have been entitled to receive in the absence of any such taxes, deductions or
          withholdings.</p>
        <p>6.4. You agree to provide us, on the Effective Date, with valid, up-to-date and complete bank card (credit or debit card)
          details and any other relevant valid, up-to-date and complete contact and billing details and, if you provide:
          <div className={classes.subText}>
            <p>6.4.1 your bank card details to us, you hereby authorise us to bill such bank card:
              <div className={classes.subText}>
                <p>(i) on the 28 th day of each month for the PMS Plus Add-on Fees payable in respect of the Initial Term (as
                  defined in Section 13); and</p>
                <p>(ii) subject to Section 13.1, on the 28 th day of each month for the PMA Plus Add-on Fees payable in respect
                  of the next and each subsequent Renewal Period.</p>
              </div>
            </p>
          </div>
        </p>
        <p>6.5 If Counter has not received payment within 30 days after the due date, and without prejudice to any other of our rights
          and remedies:
          <div className={classes.subText}>
            <p>6.5.1 Counter may, without liability to you, suspend or terminate your account and access to all or part of the PMS
            Plus Add-ons and Counter shall be under no obligation to provide any or all of the PMS Plus Add-ons while
            the PMS Plus Add-on Fees concerned remain unpaid; and</p>
            <p>6.5.2 interest shall accrue on a daily basis on such due amounts at an annual rate equal to 3% over the then current
            base lending rate of Counter’s bankers in Ireland from time to time, commencing on the due date and
            continuing until fully paid, whether before or after judgment. All amounts and fees stated or referred to in these
            Terms are exclusive of value added tax.</p>
          </div>
        </p>
        <p>6.6 Except as expressly set forth herein, all PMS Plus Add-on Fees are non-refundable once paid to Counter (including
          upon any termination or suspension of these Terms).</p>
      </div>

      <div className={classes.subHeader}>7.	PMS PLUS PAYMENTS SYSTEM</div>
      <div className={classes.text}>
        <p>7.1. The Payments System will enable you to accept payments from guests for a variety of services including
        accommodation, food and beverages, transport (e.g. airport transfers), activities, ancillaries (e.g. extra towels, lock
        rental, luggage storage) and merchandise (t-shirts, sun-lotion etc.) (“Ancillaries”) where the guest’s payment method
        (bank card number, expiry date etc.) (“Payment Card”) is either:
          <div className={classes.subText}>
            <p>7.1.1. received, together with the guest’s booking information, by Counter via the Booking Engine through an online
            travel agency (“OTA”) such as Hostelworld.com Limited; or</p>
            <p>7.1.2. provided directly you by the guest and inserted directly by you into the Core PMS.</p>
          </div>
        </p>
        <p>7.2. The capturing and sharing of Payment Card details by an OTA from a guest will be strictly subject to and governed by
        the OTA’s terms and conditions between the guest and the OTA.</p>
        <p>7.3. The Payments System will enable you to process the following forms of payment (“Guest Payments”) using the
          Payment Card:
          <div className={classes.subText}>
            <p>7.3.1. the balance of a non-refundable rate which will be processed by Counter on the same day as the booking and
            Payment Card details are received from the OTA;</p>
            <p>7.3.2. payments for ‘no shows’ which will be processed by Counter in accordance with your terms and conditions
            agreed between you and the guest; and</p>
            <p>7.3.3. for the consumption of goods and services by the guest at your property.</p>
          </div>
        </p>
        <p>7.4. With respect to all collections and remittances of any Guest Payments by Counter to you in connection with this
          Section 7, you agree that such services will be performed by Counter in its capacity as limited agent for you (and
          acting only your behalf) for the purpose of facilitating the receipt of payments made by guests for Guest Payments
          made through the Payment System and the disbursement of those payments to you. Counter reserves the right to
          determine, in its sole discretion, the frequency of payments and/or settlements to you.</p>
        <p>7.5. The Payment System and any related transaction services in connection with Guest Payments collected by Counter
          and which are payable to you are provided by Stripe and are subject to the Stripe Connected Account Agreement
          (located at https://stripe.com/connect-account/legal), which includes the Stripe Terms of Service (located at
          https://stripe.com/legal) (collectively, the “Stripe Services Agreement”). By registering an account with Stripe, you
          agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a
          condition of Counter enabling payment processing services through Stripe, you agree to provide Counter with
          accurate and complete information, and you authorise Counter to share such information related to your use of the
          processing and transaction services in accordance with Section 7.6 below.</p>
        <p>7.6. You acknowledge and agree that Counter may access the Connect Account(s) registered by you with Stripe in
          connection with the processing of payments related to Guest Payments and access and use Connect Account Data
          in the manner described in the Stripe Connect Documentation for the following purposes (and expressly grant
          Counter all necessary or required permissions and consents in this regard):
          <div className={classes.subText}>
            <p>7.6.1. permit Counter to comply with any applicable anti-terrorism, financial services or ‘Know Your Customer’/
            ‘Customer Due Diligence’ legal requirements imposed or required by Stripe;</p>
            <p>7.6.2. crediting your Connect Account with payments due to you;</p>
            <p>7.6.3. routing of transaction related information on your behalf to Stripe and other third parties who are involved in
            the routing of transaction related information;</p>
            <p>7.6.4. reporting and reconciliation purposes;</p>
            <p>7.6.5. deducting charges for permitted chargebacks; and</p>
            <p>7.6.6. allocation and execution of payments from you for accommodation bookings.</p>
          </div>
        </p>
        <p>7.7. Counter will deliver reports on bookings made, monies collected and settled into your Connect Account and other
          additional reports and reconciliations to you via the Core PMS.</p>
        <p>7.8. Any credit card chargebacks or other transaction reversals initiated against Counter or its affiliates for any reason
          (except to the extent they are caused solely by Counter's negligence or wilful misconduct) with respect to a booking
          and all related credit card association, payment processing, re-presentment, penalty and other fees and expenses
          incurred by Counter or its affiliates in connection with such chargebacks will ultimately be your responsibility, and you
          shall promptly and fully reimburse Counter for such amounts on demand. Counter will deduct all charges relating to
          chargebacks, in accordance with Section 7.6 from your Connect Account. In the event that there are insufficient funds
          in your Connect Account to fully reimburse Counter for such amounts, Counter reserve the right to charge your bank
          card on file for such amounts. Counter will notify you in advance of its intention to carry out such charge.</p>
        <p>7.9. Counter reserves the right to suspend or terminate the PMS Plus Add-ons if Counter considers, in its sole discretion,
          the volume of chargebacks referred to in Section 7.8 to be excessive.</p>
      </div>

      <div className={classes.subHeader}>8.	PAYMENT CARD PROCESSING BY YOU</div>
      <div className={classes.text}>
        <p>8.1. Where you are provided with Payment Card details in accordance with Section 7.1, you will only process such details
          in accordance with these Terms and you will ensure that Payment Card details obtained from Counter are only ever
          used for the following purposes:
          <div className={classes.subText}>
            <p>8.1.1. taking payment for legitimate and properly due cancellation fee(s);</p>
            <p>8.1.2. taking payment for Ancillaries;</p>
            <p>8.1.3. taking payment for the balance due from the guest for a non-refundable booking;</p>
            <p>8.1.4. taking payment for a completed booking on check-in, where the guest has fulfilled the booking; and</p>
            <p>8.1.5. pre-authorising and/or placing a hold on Payment Cards for booking amounts which will be held in place for a
              period of 7 days and will be released if payment for the booking is not taken by the end of the 7 day period.</p>
          </div>
        </p>
        <p>8.2. You will be responsible for all charges, including but not limited to chargebacks, imposed by your card processor or
          any other third party in relation to payments that you take, or attempt to take, from guests.</p>
        <p>8.3. You will only take payments in the currency in which you quote your total price for a guest to stay one night at your
          property when using the Payment Card details.</p>
        <p>8.4. Counter will have no liability to you in respect of bookings where you are not able to take payment for the balance
          due from the guest for a non-refundable booking (where permitted) or any cancellation fee(s), for any reason.</p>
        <p>8.5. You confirm that you, and its service providers, will keep all Payment Card details confidential, and will always
          comply with the requirements, compliance criteria and validation processes as set out in the Payment Card Industry
          ("PCI") Data Security Standard, as such standards are updated from time to time.</p>
        <p>8.6. You hereby indemnify Counter for the full amount of all claims, liabilities, demands, damages, expenses, losses,
          refunds, fines, costs (including all legal costs) and all other sums of whatever nature which Counter incurs, suffers or
          becomes responsible for as a result of actual or alleged fraudulent or unauthorised use of any Payment Card details
          which arise as a result of your failure to comply with your obligations under these terms, including but not limited to
          the repayment by Counter to guests of sums fraudulently or erroneously taken using the Payment Card details.</p>
      </div>

      <div className={classes.subHeader}>9.	PROPRIETARY RIGHTS</div>
      <div className={classes.text}>
        <p>9.1. You acknowledge and agree that Counter and/or its licensors own all intellectual property rights in the PMS Plus Add-
          ons and the PMS Plus Add-on Documentation. Except as expressly stated in these Terms, these Terms does not
          grant you any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks
          (whether registered or unregistered), or any other rights or licences in respect of the PMS Plus Add-ons or the PMS
          Plus Add-on Documentation.</p>
        <p>9.2. Counter confirms that it has all the rights in relation to the PMS Plus Add-ons and the PMS Plus Add-on
          Documentation that are necessary to grant all the rights it purports to grant under, and in accordance with, the terms
          of these Terms.</p>
      </div>

      <div className={classes.subHeader}>10.	CONFIDENTIALITY</div>
      <div className={classes.text}>
        <p>10.1. Each party may be given access to Confidential Information from the other party in order to perform its obligations
          under these Terms. A party’s Confidential Information shall not be deemed to include information that:
          <div className={classes.subText}>
            <p>10.1.1. is or becomes publicly known other than through any act or omission of the receiving party;</p>
            <p>10.1.2. was in the other party’s lawful possession before the disclosure;</p>
            <p>10.1.3. is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</p>
            <p>10.1.4. is comprised of aggregated non-personal data; or</p>
            <p>10.1.5. is independently developed by the receiving party, which independent development can be shown by written
              evidence.</p>
          </div>
        </p>
        <p>10.2. Subject to Section 10.4, each party shall hold the other’s Confidential Information in confidence and not make the
          other’s Confidential Information available to any third party or use the other’s Confidential Information for any purpose
          other than the implementation of these Terms.</p>
        <p>10.3. Each party shall take all reasonable steps to ensure that the other’s Confidential Information to which it has access is
          not disclosed or distributed by its employees or agents in violation of the terms of these Terms.</p>
        <p>10.4. A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed
          by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction,
          provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure
          as possible and, where notice of disclosure is not prohibited and is given in accordance with this Section 8.4, it takes
          into account the reasonable requests of the other party in relation to the content of such disclosure.</p>
        <p>10.5. You acknowledge that details of the PMS Plus Add-ons, and the results of any performance tests of the PMS Plus
          Add-ons, constitute Counter’s Confidential Information.</p>
        <p>10.6. Neither party shall make, or permit any person to make, any public announcement concerning these Terms without
          the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as
          required by law, any governmental or regulatory authority (including, without limitation, any relevant securities
          exchange), any court or other authority of competent jurisdiction.</p>
        <p>10.7. The above provisions of this Section 9 shall survive termination of these Terms, however arising.</p>
      </div>

      <div className={classes.subHeader}>11.	INDEMNITY</div>
      <div className={classes.text}>
        <p>11.1. You shall defend, indemnify and hold harmless Counter against claims, actions, proceedings, losses, damages,
          expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in connection
          with your use of the PMS Plus Add-ons and/or PMS Plus Add-on Documentation.</p>
        <p>11.2. In no event shall Counter, its employees, agents and sub-contractors be liable to you to the extent that the alleged
          infringement is based on:
          <div className={classes.subText}>
            <p>11.2.1. a modification of the PMS Plus Add-ons or PMS Plus Add-on Documentation by anyone other than Counter or
            its agents, subcontractors or partners or with Counter’s consent or approval; or</p>
            <p>11.2.2. your use of the PMS Plus Add-ons or PMS Plus Add-on Documentation otherwise than in accordance with the
            PMS Plus Add-on Documentation; or</p>
            <p>11.2.3. your use of the PMS Plus Add-ons or PMS Plus Add-on Documentation after notice of the alleged or actual
            infringement from Counter or any appropriate authority.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>12.  LIMITATION OF LIABILITY</div>
      <div className={classes.text}>
        <p>12.1. Except as expressly and specifically provided in these Terms:
          <div className={classes.subText}>
            <p>12.1.1. you assume sole responsibility for results obtained from the use of the PMS Plus Add-ons and the PMS Plus
            Add-on Documentation by you, and for conclusions drawn from such use. Counter shall have no liability for
            any damage caused by errors or omissions in any information, instructions or scripts provided to Counter by
            you in connection with the PMS Plus Add-ons, or any actions taken by Counter at your direction;</p>
            <p>12.1.2. all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or
            common law are, to the fullest extent permitted by applicable law, excluded from these Terms; and</p>
            <p>12.1.3. the PMS Plus Add-ons and the PMS Plus Add-on Documentation are provided to you on an "as is" basis.</p>
          </div>
        </p>
        <p>12.2. Nothing in these Terms excludes the liability of Counter:
          <div className={classes.subText}>
            <p>12.2.1. for death or personal injury caused by Counter’s negligence; or</p>
            <p>12.2.2. for fraud or fraudulent misrepresentation.</p>
          </div>
        </p>
        <p>12.3. Subject to Section 12.1 and Section 12.2:
          <div className={classes.subText}>
            <p>12.3.1. Counter shall not be liable whether in tort (including for negligence or breach of statutory duty), contract,
            misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or
            similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or
            consequential loss, costs, damages, charges or expenses however arising under these Terms; and</p>
            <p>12.3.2. in no event will Counter’s total aggregate liability in contract, tort (including negligence or breach of statutory
            duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated
            performance of these Terms exceed the total PMS Plus Add-on Fees paid for the PMS Plus Add-ons during
            the 12 months immediately preceding the date on which the claim arose.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>13.  TERM AND TERMINATION</div>
      <div className={classes.text}>
        <p>13.1. These Terms shall, unless otherwise terminated as provided in this Section 13, commence on the date you click to
          accept these Terms (<strong>“Effective Date”</strong>) and shall continue in effect for 12 months (the <strong>“Initial Term”</strong>) and, thereafter,
          these Terms shall be automatically renewed for successive periods of 12 months (each a <strong>“Renewal Period”</strong>), unless
          earlier terminated pursuant to this Section 13 or either party gives the other party written notice of non-renewal at
          least 30 days prior to the expiration of the then-current term. Counter may terminate or suspend your use of any or all
          of the PMS Plus Add-ons at any time and without prior notice.</p>
        <p>13.2. Without affecting any other right or remedy available to it, either party may terminate these Terms with immediate
          effect by giving written notice to the other party if:
          <div className={classes.subText}>
            <p>13.2.1. the other party commits a material breach of any other term of these Terms which breach is irremediable or (if
            such breach is remediable) fails to remedy that breach within a period of 14 days after being notified in writing
            to do so;</p>
            <p>13.2.2. becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due</p>
            <p>13.2.3. the other party undergoes a change of control in favour of a direct competitor of the other party and fails to
            inform the other party in writing prior to the change taking effect, the other party may terminate these Terms
            effective immediately upon the date of the Change of Control the other party suspends or ceases, or threatens
            to suspend or cease, carrying on all or a substantial part of its business.</p>
          </div>
        </p>
        <p> 13.3. On termination of these Terms for any reason:
          <div className={classes.subText}>
            <p>13.3.1. you shall immediately cease all use of the PMS Plus Add-ons and/or the PMS Plus Add-on Documentation;</p>
            <p>13.3.2. each party shall return and make no further use of any equipment, property, documentation and other items
              (and all copies of them) belonging to the other party;</p>
            <p>13.3.3. Counter may destroy or otherwise dispose of any of your Customer Data in its possession in accordance
              with Section 2.3.5, unless Counter receives, no later than 10 days after the effective date of the termination of
              these Terms, a written request for the delivery to you of the then most recent back-up of you Data. Counter
              shall use reasonable commercial endeavours to deliver the back-up to you within 30 days of its receipt of
              such a written request. You shall pay all reasonable expenses incurred by Counter in returning or disposing of
              Customer Data; and</p>
            <p>13.3.4. any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination,
              including the right to claim damages in respect of any breach of the agreement which existed at or before the
              date of termination shall not be affected or prejudiced.</p>
          </div>
        </p>
      </div>

      <div className={classes.subHeader}>14.	GENERAL</div>
      <div className={classes.text}>
        <p>14.1. Counter shall have no liability to you under these Terms if it is prevented from or delayed in performing its obligations
          under these Terms, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable
          control, including, without limitation, infectious diseases, epidemics, pandemics or quarantine strikes, lock-outs or
          other industrial disputes, act of God, war, riot, civil commotion, malicious damage, compliance with any law or
          governmental order, rule, regulation, action or direction, accident, breakdown of plant or machinery, fire, flood, storm
          or default of suppliers or sub-contractors, provided that you is notified of such an event and its expected duration.</p>
        <p>14.2. Counter may update or modify these Terms (including any referenced policies and other documents) from time to
          time. Any such amendments will take effect ten (10) days from the date of posting by Counter and your continued use
          of the PMS Plus Add-ons after the effective date of the revised Terms constitutes your acceptance of such revised
          Terms.</p>
        <p>14.3. Except as expressly provided in these Terms, the rights and remedies provided under these Terms are in addition to,
          and not exclusive of, any rights or remedies provided by law.</p>
        <p>14.4. If any part of these Terms is determined to be invalid or unenforceable, then the invalid or unenforceable provision
          shall be deemed to be superseded by any such valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of these Terms shall continue in effect.</p>
        <p>14.5. These Terms constitutes the entire agreement between the parties and supersedes and extinguishes all previous
          agreements, promises, assurances, warranties, representations and understandings between them, whether written
          or oral, relating to its subject matter. Each party acknowledges that in entering into these Terms it does not rely on,
          and shall have no remedies in respect of, any statement, representation, assurance or warranty (whether made
          innocently or negligently) that is not set out in these Terms. Each party agrees that it shall have no claim for innocent
          or negligent misrepresentation based on any statement in these Terms.</p>
        <p>14.6. You shall not, without the prior written consent of Counter, assign, transfer, charge, sub-contract or deal in any other
          manner with all or any of your rights or obligations under these Terms. Counter may at any time assign, transfer,
          charge, sub-contract or deal in any other manner with all or any of its rights or obligations under these Terms.</p>
        <p>14.7. Nothing in these Terms is intended to or shall operate to create a partnership between the parties, or authorise either
          party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or
          otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the
          assumption of any obligation or liability and the exercise of any right or power).</p>
        <p>14.8. These Terms and any dispute or claim arising out of or in connection with it or its subject matter or formation
          (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of
          Ireland. Each party irrevocably agrees that the courts of Ireland shall have exclusive jurisdiction to settle any dispute
          or claim arising out of or in connection with these Terms or its subject matter or formation (including non-contractual
          disputes or claims).</p>
      </div>

      <div className={classes.subHeader}>15.  DEFINITIONS</div>
      <div className={classes.text}>
        <p>15.1. The definitions and rules of interpretation in this Section apply in these Terms:
          <div className={classes.subText}>
            <p><strong>Beds:</strong> the beds together with all other ancillary products and services offered for sale by you on a non-optional basis or
            as part of one inclusive price (examples of ancillary products and services being breakfast, airport pick-ups and internet
            access).</p>
            <p><strong>Business Day:</strong>  a day other than a Saturday, Sunday or public holiday in Ireland when banks in Dublin are open for
            business.</p>
            <p><strong>Booking Engine:</strong> the property management software that enables a Customer to accept bookings, as more particularly
            described in Section 1.3.</p>
            <p><strong>Booking Management Tool:</strong> the online booking management software add-on tool that enables hostels to provide
            certain guest features such as self-check-in and check-out, booking cancellations, room upgrades and extensions and
            ancillaries, etc.</p>
            <p><strong>Connect Accounts:</strong> the account registered by you with Stripe that is to be integrated with Counter for the purposes of
            allowing you to use the Payment System in connection with the remittance of payments collected by Counter for Guest
            Payments as more particularly described in Section 7.6.</p>
            <p><strong>Connect Account Data:</strong> data about Connect Accounts.</p>
            <p><strong>Control:</strong>  shall be as defined in Section 432 of the Tax Consolidation Acts 1997.</p>
            <p><strong>Confidential Information:</strong> information that is proprietary or confidential and is either clearly labelled as such or identified
            as Confidential Information in Section 10.</p>
            <p><strong>Controller, processor, data subject, personal data, personal data breach, processing and appropriate technical
            and organisational measures:</strong>  as defined in the Data Protection Legislation.</p>
            <p><strong>Customer Data:</strong> the data inputted by you or Counter on your behalf for the purpose of using the PMS Plus Add-ons or
            facilitating your use of the PMS Plus Add-ons and any data generated by, or derived from your use of the PMS Plus Add-
            ons, whether hosted or stored within the PMS Plus Add-ons or elsewhere.</p>
            <p><strong>Data Protection Legislation:</strong> all applicable data protection and privacy legislation in force from time to time in the
            Ireland including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and
            Electronic Communications Directive 2002/58/EC as amended and any other EU legislation relating to personal data and
            all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of
            personal data (including, without limitation, the privacy of electronic communications);</p>
            <p><strong>Core PMS:</strong> the cloud-based property management software application system and platform developed and provided by
            Hostelworld.com Limited.</p>
            <p><strong>Integration System:</strong> the system that enables the Add-ons to integrate with the Core PMS, as more particularly
            described in Section 1.5.</p>
            <p><strong>Intellectual Property Rights:</strong>  patents, rights to inventions, copyright and related rights, moral rights, trademarks and
            service marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair
            competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality
            of, confidential information (including know-how and trade secrets) and all other intellectual property rights, in each case
            whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or
            extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which
            subsist or will subsist now or in the future in any part of the world.</p>
            <p><strong>Normal Business Hours:</strong>  8.00 am to 6.00 pm local Irish time, each Business Day.</p>
            <p><strong>Payments System:</strong> the software service that enables Counter to accept and collect payments, manage bookings and
            perform transaction reporting or other financial transactions, as more particularly described in Section 7.</p>
            <p><strong>PMS Plus Add-on Documentation:</strong>  the document(s) made available to you by Counter online 
            via <a href="https://support.counter.app/" className={classes.link} target="_blank">https://support.counter.app/</a> or such other web address notified by Counter to you from time to time
            which sets out a description of the PMS Plus Add-ons and the user instructions for the PMS Plus Service.</p>
            <p><strong>PMS Plus Add-ons:</strong> the Booking Engine, the Booking Management Tool, the Payments System, the Revenue
            Management System, and the Integration System provided by Counter to you pursuant to these Terms, as more
            particularly described in the PMS Plus Add-on Documentation.</p>
            <p><strong>PMS Plus Add-on Fees:</strong> the subscription fees payable by you to Counter for the PMS Plus Add-ons, as more
            particularly described in Section 6.</p>
            <p><strong>Prohibited Business:</strong> any of the categories of businesses and business practices for which the Payment Services
            cannot be used and which are identified on the then-current Stripe Prohibited Business List located on the Stripe Legal
            Page.</p>
            <p><strong>Revenue Management System:</strong> the online revenue management system that enables hostels to automatically set room
            prices based on overarching pricing guidelines provided by the hostel.</p>
            <p><strong>Stripe Connect:</strong> the programming interfaces for the provisioning and management of Stripe accounts, or any
            replacement or amended interfaces made available by Stripe, as described in the Stripe Connect Documentation.</p>
            <p><strong>Stripe Connect Documentation:</strong> the online documentation related to Stripe Connect that Stripe makes available 
            at <a href="https://stripe.com/docs/connect" className={classes.link} target="_blank">https://stripe.com/docs/connect</a> and <a href="https://stripe.com/docs/api" className={classes.link} target="_blank">https://stripe.com/docs/api</a>, each as updated from time to time.</p>
            <p><strong>Stripe Legal Page:</strong> the Stripe legal page for the jurisdiction where the Connect Account is located.</p>
            <p><strong>Vulnerability:</strong>  a weakness in the computational logic (for example, code) found in software and hardware components
            that when exploited, results in a negative impact to the confidentiality, integrity, or availability, and the
            term Vulnerabilities shall be construed accordingly.</p>
            <p><strong>Website:</strong> the any website or technology platforms operated by you that incorporates the Booking Engine.</p>
          </div>
        </p>
      </div>


      <div className={classes.text}>
        Last updated: 23 July 2020
      </div>


    </div>
  )
} 