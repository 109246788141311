import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { fetchExperiencesRevenue, resetExperienceRevenueReport } from '../../../redux/actions/experiences/reports';
import { processExperienceRevenue } from '../../../redux/selectors/reports/experienceReservations';
import BarChart from '../../charts/BarChart';

import ArrowIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  iconHolder: {
    alignItems: 'center',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    height: 120,
    justifyContent: 'center',
    zIndex: 2,
    '&:hover': {
      background: '#eeeeee'
    }
  },
  icon: {
    fontSize: '5rem'
  },
  downloadButton: {
    cursor: 'pointer',
    height: 25,
    width: 25,
    left: 24,
    opacity: 0.7,
    position: 'absolute',
    top: 28
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  listContainer: {
    //minHeight: 'calc(100vh - 230px)',
    height: 'calc(100vh - 190px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 124px)',
    }
  },
  listSection: {
    margin: theme.spacing(3),
  },
  rightAlign: {
    boxSizing: 'border-box',
    display: 'flex',
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
  },
  fieldContainer: {
    border: 'none',
    borderRadius: 5,
    boxShadow: '0 1px 10px 0 #E0E0E0',
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  dateHolder: {
    '& .DateRangePicker_picker': {
      top: '45px !important',
    },
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      border: 'none',
      borderRadius: 5,
      boxShadow: '0 1px 10px #E0E0E0',
    }
  },
  buttonContainer: {
    alignItems: 'center',
    background: '#999999',
    borderRadius: '2px',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  go: {
    color: '#ffffff',
    fontWeight: 600,
  },
  revenueGraph: {
    height: 400,
    width: '100%'
  },
  noTableGraph: {
    height: 150,
    width: '100%'
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 300,
    justifyContent: 'center',
    width: '100%',
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const BasicExport = (props) => {
  const { t } = props
  return (
    <MaterialTable
      title={props.sameDate ? `${t('reports.revenue')} ${props.startDate}` : `${t('reports.revenue')} ${props.startDate} - ${props.endDate} ${(props.level === 'weekly') ? '(weekly)' : ''}`}
      columns={[
        { title: 'Date', field: 'date', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}},
        { title: 'Revenue', field: 'revenue', align: 'center', headerStyle: { backgroundColor: '#F6F2EE'}}
      ]}
      data={props.data}        
      options={{
        exportButton: true,
        rowStyle: { fontSize: '1.2rem'}
      }}
    />
  )
}

const Revenue = (props) => {
  const { t } = props

  const presetRanges = [
    { value: 'future15', label: t('reports.presetRange.future15') },
    { value: 'future30', label: t('reports.presetRange.future30') },
    { value: 'monthToDate', label: t('reports.presetRange.monthToDate') },
    { value: 'yearToDate', label: t('reports.presetRange.yearToDate') },
    { value: 'past15', label: t('reports.presetRange.past15') },
    { value: 'past30', label: t('reports.presetRange.past30') },
    { value: 'past90', label: t('reports.presetRange.past90') },
    { value: 'past180', label: t('reports.presetRange.past180') },
    { value: 'past365', label: t('reports.presetRange.past365') }
  ]
  
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const { open, handleClose, currentSpace, loadingRevenue, experiences } = props;
  const [dates, setDates] = React.useState({startDate: moment(), endDate: moment().add(30, 'days')});
  const [submittedDates, setSubmittedDates] = React.useState({startDate: moment(), endDate: moment()});
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [preset, setPreset] = React.useState(1);
  const [max, setMax] = React.useState(null);
  const [level, setLevel] = React.useState('daily');
  const [submittedLevel, setSubmittedLevel] = React.useState('daily');
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country
  }

  const {graphData, tableData } = useSelector(state => processExperienceRevenue(state.reports.experienceRevenue, currencyData, submittedDates, submittedLevel))

  useEffect (() => {
    handleSubmit();
    return function cleanup() {      
      props.dispatch(resetExperienceRevenueReport());
    };
  }, [])

  const setNewDates = (e) => {
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = 'daily';
    if (e.value==='monthToDate') {
      reportFrom = moment().startOf('month');
    } else if (e.value==='yearToDate') {
      reportFrom = moment().startOf('year');
    } else if (e.value.substr(0,6)==='future') {
      const num = parseInt(e.value.substr(6));
      reportFrom=moment();
      reportTo = moment().add(num, 'days');
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, 'days');
    }
    const newDiff = reportTo.diff(reportFrom, 'days');
    if (newDiff > 30 && newDiff < 60) {
      newLevel = 'weekly'
    }
    else if(newDiff > 60) {
      newLevel = 'monthly'
    }

    setDates({startDate: reportFrom, endDate: reportTo});
    setLevel(newLevel);
  }

  const handleSubmit = () => {
    let calcLevel = 'daily';
    const difference = dates.endDate.diff(dates.startDate, 'days');
    if (difference > 30 && difference < 60) {
      calcLevel = 'weekly'
    }
    else if(difference > 60) {
      calcLevel = 'monthly'
    }
    setLevel(calcLevel);
    setSubmittedLevel(calcLevel);

    const query = {
      level: calcLevel,
      startDate: moment(dates.startDate).format('YYYY-MM-DD'),
      endDate: moment(dates.endDate).format('YYYY-MM-DD'),
      propertyID: currentSpace.propertyID
    }

    props.dispatch(fetchExperiencesRevenue(query))
    setSubmittedDates({startDate: dates.startDate, endDate: dates.endDate});
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* <img className={classes.downloadButton} src="/images/icons/download.png" /> */}
          <Typography className={classes.header}>{t('reports.revenue')} {t('reports.report')}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          <div className={clsx(classes.listSection, classes.rightAlign)}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <div className={classes.fieldContainer}>
                  <Select 
                    defaultValue={presetRanges[`${preset}`]}
                    styles={selectStyle}
                    options={presetRanges}
                    onChange={setNewDates}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item className={classes.dateHolder}>
                <DateRangePicker
                  startDate={dates.startDate}
                  startDateId="start_date"
                  endDate={dates.endDate}
                  endDateId="end_date"
                  onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  focusedInput={focusedInput}
                  displayFormat={'DD MMM YYYY'}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                />
              </Grid>
              <Grid item xs={2} md={1} xl={1}>
                <div className={classes.buttonContainer} onClick={handleSubmit}>
                  <ArrowIcon className={classes.go} />
                </div>
              </Grid>
            </Grid>
          </div>

          {loadingRevenue && <div className={classes.loadingState}><CircularProgress /></div>}

          {/* --------------------------------------------------------- */}
          {/* REVENUE ANALYSIS GRAPH*/}
          {/* --------------------------------------------------------- */}
          {!loadingRevenue && <div className={classes.listSection}>
            <div className={classes.revenueGraph}>
              {graphData.length !== 0 && <BarChart keyName="revenue" maxValue={Math.max.apply(Math, graphData.map(function(o) { return o.revenue; }))} data={graphData} />}
              {graphData.length === 0 && <div className={classes.blankSlate}>{t('experiences.reports.noData')}</div>}
            </div>
          </div>}

          {/* --------------------------------------------------------- */}
          {/* REVENUE TABLE */}
          {/* --------------------------------------------------------- */}
          {!loadingRevenue && <div className={classes.listSection}>
            {tableData.length !==0 && 
              <BasicExport 
                t={t}
                level={submittedLevel}
                data={tableData} 
                startDate={submittedDates.startDate.format('DD MMM YYYY')} 
                endDate={submittedDates.endDate.format('DD MMM YYYY')} 
                sameDate={moment(submittedDates.startDate).isSame(submittedDates.endDate, 'day')}
              />
            }

          </div>}

        </div>

      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingRevenue: state.loading.LOAD_EXPERIENCE_REVENUE_REPORT,
  experiences: state.experiences
})

export default withTranslation()(connect(mapStateToProps)(Revenue));