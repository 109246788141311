import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import clsx from 'clsx';

import { ERRORS } from '../../utils/errors';
import { fetchDatePerTimezone } from '../../utils/utility';
import { createBuilding, editBuilding, deleteBuilding, getBuildings } from '../../redux/actions/rooms/building';
import { createTag, updateTag } from '../../redux/actions/reservation/reservationTags'
import { updateTransactionMode, createTransactionMode } from '../../redux/actions/reservation/editReservation'
import { updateBookingSource, createBookingSource } from '../../redux/actions/reservation/newReservation'
import { resetError } from '../../redux/actions/reservation/editReservation';

import Select from 'react-select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles( theme => ({
  listSection: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3)
  },
  row: {
    marginBottom: 20,
  },
  firstTitle: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.3
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.3,
    marginTop: 15
  },
  label: {
    fontSize: '1.2rem',
    color: '#666666',
    fontWeight: 400
  },
  listDetail: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px 0px'
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.3
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    paddingLeft: theme.spacing(1),
    width: '90%'
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  newButton: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    marginTop: 3,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  addButton: {
    color: '#bcbcbc',
    cursor: 'pointer',
    '&:hover': {
      color: '#666666'
    }
  },
  verticalMargin: {
    marginTop: 10,
    marginBottom: 10
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}))

const ListSettings = (props) => {
  const classes = useStyles()
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const { property, currentSpace, currentSpaceID, spaces, dispatch, transactionModes, bookingSource, buildings, errors } = props
  
  const [message, setMessage] = React.useState('')
  const [tag, setTag] = React.useState({ resv: '', building: '', paymentMethod: '', bookingSource: '' })
  const [add, setAdd] = React.useState({ resv: false, building: false, paymentMethod: false, bookingSource: false })
  const [spaceTags, setSpaceTag] = React.useState([])
  const [spaceBuildings, setBuildings] = React.useState([])
  const [paymentMethods, setPaymentModes] = React.useState([])
  const [source, setSource] = React.useState([])

  useEffect(() => {
    if(currentSpace.resvTags){
      let tags = []
      for(var i = 0; i < currentSpace.resvTags.length; i++) {
        if(currentSpace.resvTags[i].isActive)
          tags.push({ ...currentSpace.resvTags[i], edit: false, label: currentSpace.resvTags[i].name })
      }
      setSpaceTag(tags)
    }
  }, [currentSpace.resvTags])

  useEffect(() => {
    if(buildings){
      let set = []
      for (let [key, value] of Object.entries(buildings)) {
        if(buildings[key].propertyID === currentSpace.propertyID)
          set.push({ ...buildings[key], edit: false, label: buildings[key].name })
      }
      setBuildings(set)
    }
  }, [buildings])

  useEffect(() => {
    if(currentSpace.transactionModes){
      let set = []
      for (let [key, value] of Object.entries(transactionModes)) {
        if((transactionModes[key].isActive) && (transactionModes[key].propertyID === currentSpace.propertyID))
          if(transactionModes[key].name !== 'surcharge')
            set.push({ ...transactionModes[key], edit: false, label: transactionModes[key].name })
      }
      setPaymentModes(set)
    }
  }, [currentSpace.transactionModes])

  useEffect(() => {
    if(currentSpace.bookingSource){
      let sourceData = []
      for(var i = 0; i < currentSpace.bookingSource.length; i++) {
        if(currentSpace.bookingSource[i].active)
          sourceData.push({ ...currentSpace.bookingSource[i], edit: false, label: currentSpace.bookingSource[i].name })
      }
      setSource(sourceData)
    }
  }, [currentSpace.bookingSource])

  const handleChange = (e) => {
    setTag({ ...tag, [e.target.id]: e.target.value });
  }

  const handleAdd = (type) => {
    let value = !add[type]
    setAdd({...add, [type]: value})
  }

  // -----------------------------------------------------------
  // Reservation Tags actions
  // -----------------------------------------------------------

  const handleSaveTag = () => {
    const data = {
      name: tag.resv,
      propertyID: currentSpace.propertyID
    }
    dispatch(createTag(currentSpaceID, data))
    setTag({ ...tag, resv: ''})
    handleAdd('resv')
  }

  const handleEditTag = (i) => {
    let value = [ ... spaceTags ]
    value[i].edit = true
    setSpaceTag(value)
  }

  const handleCancelEditTag = (i) => {
    let value = [ ... spaceTags ]
    value[i].edit = false
    setSpaceTag(value)
  }

  const handleEditTagName = (e, i) => {
    let value = [ ... spaceTags ]
    value[i].label = e.target.value
    setSpaceTag(value)
  }

  const handleConfirmEditTag = (i) => {
    let value = [ ...spaceTags ]
    const data = {
      tagID: value[i]._id,
      propertyID: value[i].propertyID,
      name: value[i].label
    }

    dispatch(updateTag(currentSpaceID, data))
    handleCancelEditTag(i)
  }

  const handleDeleteTag = (i) => {
    let value = [ ...spaceTags ]
    const data = {
      tagID: value[i]._id,
      propertyID: value[i].propertyID,
      delete: true
    }

    dispatch(updateTag(currentSpaceID, data))
  }

  // -----------------------------------------------------------
  // Building actions
  // -----------------------------------------------------------

  const handleSaveBuilding = () => {
    const data = {
      name: tag.building,
      propertyID: currentSpace.propertyID
    }

    dispatch(createBuilding(currentSpace.propertyID, currentSpaceID, data))
    //dispatch(getBuildings(currentSpaceID, currentSpace.propertyID))
    setTag({ ...tag, building: ''})
    handleAdd('building')
  }

  const handleEditBuilding = (i) => {
    let value = [ ... spaceBuildings ]
    value[i].edit = true
    setBuildings(value)
  }

  const handleCancelEditBuilding = (i) => {
    let value = [ ... spaceBuildings ]
    value[i].edit = false
    setBuildings(value)
  }

  const handleEditBuildingName = (e, i) => {
    let value = [ ... spaceBuildings ]
    value[i].label = e.target.value
    setBuildings(value)
  }

  const handleDeleteBuilding = (i) => {
    let value = [ ... spaceBuildings ]
    dispatch(deleteBuilding(value[i]._id))
  }

  const handleConfirmEditBuilding = (i) => {
    let value = [ ...spaceBuildings ]
    const data = {
      propertyID: value[i].propertyID,
      name: value[i].label
    }

    dispatch(editBuilding(currentSpaceID, value[i]._id, data))
    handleCancelEditBuilding(i)
  }

  // -----------------------------------------------------------
  // Payment Method actions
  // -----------------------------------------------------------

  const deletePaymentMethod = (i) => {
    let value = [ ...paymentMethods ]
    const data = {
      modeID: value[i]._id,
      delete: true,
      propertyID: value[i].propertyID
    }

    dispatch(updateTransactionMode(currentSpaceID, data))
  }

  const handleSavePaymentMethod = () => {
    dispatch(createTransactionMode(currentSpaceID, currentSpace.propertyID, tag.paymentMethod))
    setTag({ ...tag, paymentMethod: ''})
    handleAdd('paymentMethod')
  }

  const handleEditPaymentMethod = (i) => {
    let value = [ ... paymentMethods ]
    value[i].edit = true
    setPaymentModes(value)
  }

  const handleCancelEditPaymentMethod = (i) => {
    let value = [ ... paymentMethods ]
    value[i].edit = false
    setPaymentModes(value)
  }

  const handleEditModeName = (e, i) => {
    let value = [ ... paymentMethods ]
    value[i].label = e.target.value
    setPaymentModes(value)
  }

  const handleConfirmEditMode = (i) => {
    let value = [ ...paymentMethods ]

    const data = {
      modeID: value[i]._id,
      name: value[i].label,
      propertyID: value[i].propertyID
    }

    dispatch(updateTransactionMode(currentSpaceID, data))
    handleCancelEditPaymentMethod(i)
  }

  // -----------------------------------------------------------
  // Booking Source actions
  // -----------------------------------------------------------

  const handleSaveBookingSource = () => {
    const data = {
      name: tag.bookingSource,
      propertyID: currentSpace.propertyID
    }
    dispatch(createBookingSource(data, currentSpaceID))
    setTag({ ...tag, bookingSource: ''})
    handleAdd('bookingSource')
  }

  const deleteBookingSource = (i) => {
    let value = [ ...source ]

    const data = {
      sourceID: value[i]._id,
      delete: true,
      propertyID: value[i].propertyID
    }

    dispatch(updateBookingSource(currentSpaceID, data))
  }

  const handleEditBookingSource = (i) => {
    let value = [ ... source ]
    value[i].edit = true
    setSource(value)
  }

  const handleCancelEditBookingSource = (i) => {
    let value = [ ... source ]
    value[i].edit = false
    setSource(value)
  }

  const handleEditSourceName = (e, i) => {
    let value = [ ... source ]
    value[i].label = e.target.value
    setSource(value)
  }

  const handleConfirmEditSource = (i) => {
    let value = [ ...source ]

    const data = {
      sourceID: value[i]._id,
      name: value[i].label,
      propertyID: value[i].propertyID
    }

    dispatch(updateBookingSource(currentSpaceID, data))
    handleCancelEditBookingSource(i)
  }

  const handleCloseError = () => {
    if(errors.DELETE_BUILDING)
      props.dispatch(resetError('DELETE_BUILDING'))
    if(errors.EDIT_BUILDING)
      props.dispatch(resetError('EDIT_BUILDING'))
    if(errors.CREATE_BUILDING)
      props.dispatch(resetError('CREATE_BUILDING'))
    if(errors.CREATE_TRANSACTION_MODE)
      props.dispatch(resetError('CREATE_TRANSACTION_MODE'))
    if(errors.UPDATE_TRANSACTION_MODE)
      props.dispatch(resetError('UPDATE_TRANSACTION_MODE'))
    if(errors.CREATE_TAG)
      props.dispatch(resetError('CREATE_TAG'))
    if(errors.UPDATE_TAG)
      props.dispatch(resetError('UPDATE_TAG'))
    if(errors.CREATE_BOOKING_SOURCE)
      props.dispatch(resetError('CREATE_BOOKING_SOURCE'))
    if(errors.UPDATE_BOOKING_SOURCE)
      props.dispatch(resetError('UPDATE_BOOKING_SOURCE'))
  }

  return (
    <div className={classes.listSection}>
      
      
      {/* RESERVATION TAGS */}
      {/* ---------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.firstTitle}>Reservation Tags</div>
        {(spaceTags.length > 0) && <div>
          {spaceTags.map((x, i) => {
            return (
              <Grid container alignItems="center" key={i}>
                {(!x.edit) && <Grid item xs={10}>
                  {x.name}
                </Grid>}
                {(x.edit) && <Grid item xs={10}>
                  <div className={clsx(classes.field, classes.addContainer)}>
                    <InputBase 
                      id="resv"
                      name="resv"
                      onChange={(e) => handleEditTagName(e, i)}
                      value={x.label}
                      label="Enter a reservation tag"
                      fullWidth
                      type="text"
                    />
                  </div>
                </Grid>}
                {(!x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <EditIcon className={classes.newButton} onClick={() => handleEditTag(i)} />
                  </div>
                  <div className={classes.buttonHolder}>
                    <DeleteIcon className={classes.newButton} onClick={() => handleDeleteTag(i)}/>
                  </div>
                </Grid>}
                {(x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <CloseIcon className={classes.newButton} onClick={() => handleCancelEditTag(i)}/>
                  </div>
                  <div className={classes.buttonHolder}>
                    <DoneIcon className={classes.newButton} onClick={() => handleConfirmEditTag(i)}/>
                  </div>
                </Grid>}
              </Grid>
            )
          })}
        </div>}
        {(spaceTags.length === 0) && <Grid container alignItems="center" className={classes.verticalMargin}>
          <Grid item xs={12}>
            <span><i>No tags found!</i></span>
          </Grid>
        </Grid>}
        {(add.resv) && <Grid container alignItems="center">
          <Grid item xs={10}>
            <div className={clsx(classes.field, classes.addContainer)}>
              <InputBase 
                id="resv"
                name="resv"
                value={tag.resv}
                onChange={handleChange}
                label="Enter a reservation tag"
                fullWidth
                type="text"
              />
            </div>
          </Grid>
          <Grid item xs={2} className={classes.listDetail}>
            <div className={classes.buttonHolder}>
              <DoneIcon className={classes.newButton} onClick={handleSaveTag}/>
            </div>
          </Grid>
        </Grid>}
        {(!add.resv) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <span className={classes.addButton} onClick={() => handleAdd('resv')}>Add Reservation Tag</span>
          </Grid>
        </Grid>}
      </div>


      {/* BUILDINGS */}
      {/* ---------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Buildings</div>
        {(spaceBuildings.length > 0) && <div>
          {spaceBuildings.map((x, i) => {
            return (
              <Grid container alignItems="center" key={i}>
                {(!x.edit) && <Grid item xs={10}>
                  {x.name}
                </Grid>}
                {(x.edit) && <Grid item xs={10}>
                  <div className={clsx(classes.field, classes.addContainer)}>
                    <InputBase 
                      id="resv"
                      name="resv"
                      onChange={(e) => handleEditBuildingName(e, i)}
                      value={x.label}
                      fullWidth
                      type="text"
                    />
                  </div>
                </Grid>}
                {(!x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <EditIcon className={classes.newButton} onClick={() => handleEditBuilding(i)} />
                  </div>
                  <div className={classes.buttonHolder}>
                    <DeleteIcon className={classes.newButton} onClick={() => handleDeleteBuilding(i)} />
                  </div>
                </Grid>}
                {(x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <CloseIcon className={classes.newButton} onClick={() => handleCancelEditBuilding(i)} />
                  </div>
                  <div className={classes.buttonHolder}>
                    <DoneIcon className={classes.newButton} onClick={() => handleConfirmEditBuilding(i)} />
                  </div>
                </Grid>}
              </Grid>
            )
          })}
        </div>}
        {(spaceBuildings.length === 0) && <Grid container alignItems="center" className={classes.verticalMargin}>
          <Grid item xs={12}>
            <span><i>No buildings found!</i></span>
          </Grid>
        </Grid>}
        {(add.building) && <Grid container alignItems="center">
          <Grid item xs={10}>
            <div className={clsx(classes.field, classes.addContainer)}>
              <InputBase 
                id="building"
                name="building"
                value={tag.building}
                onChange={handleChange}
                fullWidth
                type="text"
              />
            </div>
          </Grid>
          <Grid item xs={2} className={classes.listDetail}>
            <div className={classes.buttonHolder}>
              <DoneIcon className={classes.newButton} onClick={handleSaveBuilding} />
            </div>
          </Grid>
        </Grid>}
        {(!add.building) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <span className={classes.addButton} onClick={() => handleAdd('building')}>Add Building</span>
          </Grid>
        </Grid>}
      </div>

      {/* PAYMENT METHODS */}
      {/* ---------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Payment Methods</div>
        {(paymentMethods.length > 0) && <div>
          {paymentMethods.map((x, i) => {
            if(!x.isCustomMode) {
              return (
                <Grid container alignItems="center" key={i}>
                  <Grid item xs={10}>
                    <span className={classes.capitalize}>{x.name}</span>
                  </Grid>
                  <Grid item xs={2} className={classes.listDetail}>
                    <div className={classes.buttonHolder}>
                      <DeleteIcon className={classes.newButton} onClick={() => deletePaymentMethod(i)} />
                    </div>
                  </Grid>
                </Grid>
              )
            }
            else {
              return (
                <Grid container alignItems="center" key={i}>
                  {(!x.edit) && <Grid item xs={10}>
                    {x.name}
                  </Grid>}
                  {(x.edit) && <Grid item xs={10}>
                    <div className={clsx(classes.field, classes.addContainer)}>
                      <InputBase 
                        id="paymentMethod"
                        name="paymentMethod"
                        onChange={(e) => handleEditModeName(e, i)}
                        value={x.label}
                        fullWidth
                        type="text"
                      />
                    </div>
                  </Grid>}
                  {(!x.edit) && <Grid item xs={2} className={classes.listDetail}>
                    <div className={classes.buttonHolder}>
                      <DeleteIcon className={classes.newButton} onClick={() => deletePaymentMethod(i)} />
                    </div>
                    <div className={classes.buttonHolder}>
                      <EditIcon className={classes.newButton} onClick={() => handleEditPaymentMethod(i)} />
                    </div>
                  </Grid>}
                  {(x.edit) && <Grid item xs={2} className={classes.listDetail}>
                    <div className={classes.buttonHolder}>
                      <CloseIcon className={classes.newButton} onClick={() => handleCancelEditPaymentMethod(i)} />
                    </div>
                    <div className={classes.buttonHolder}>
                      <DoneIcon className={classes.newButton} onClick={() => handleConfirmEditMode(i)} />
                    </div>
                  </Grid>}
                </Grid>
              )
            }
          })}
        </div>}
        {(paymentMethods.length === 0) && <Grid container alignItems="center" className={classes.verticalMargin}>
          <Grid item xs={12}>
            <span><i>No Payment Methods found!</i></span>
          </Grid>
        </Grid>}
        {(add.paymentMethod) && <Grid container alignItems="center">
          <Grid item xs={10}>
            <div className={clsx(classes.field, classes.addContainer)}>
              <InputBase 
                id="paymentMethod"
                name="paymentMethod"
                value={tag.paymentMethod}
                onChange={handleChange}
                fullWidth
                type="text"
              />
            </div>
          </Grid>
          <Grid item xs={2} className={classes.listDetail}>
            <div className={classes.buttonHolder}>
              <DoneIcon className={classes.newButton} onClick={() => handleSavePaymentMethod()} />
            </div>
          </Grid>
        </Grid>}
        {(!add.paymentMethod) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <span className={classes.addButton} onClick={() => handleAdd('paymentMethod')}>Add Payment Method</span>
          </Grid>
        </Grid>}
      </div>


      {/* BOOKING SOURCE */}
      {/* ---------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Booking Source</div>
        {(source.length > 0) && <div>
          {source.map((x, i) => {
            return (
              <Grid container alignItems="center" key={i}>
                {(!x.edit) && <Grid item xs={10}>
                  {x.name}
                </Grid>}
                {(x.edit) && <Grid item xs={10}>
                  <div className={clsx(classes.field, classes.addContainer)}>
                    <InputBase 
                      id="bookingSource"
                      name="bookingSource"
                      onChange={(e) => handleEditSourceName(e, i)}
                      value={x.label}
                      fullWidth
                      type="text"
                    />
                  </div>
                </Grid>}
                {(!x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <EditIcon className={classes.newButton} onClick={() => handleEditBookingSource(i)} />
                  </div>
                  <div className={classes.buttonHolder}>
                    <DeleteIcon className={classes.newButton} onClick={() => deleteBookingSource(i)} />
                  </div>
                </Grid>}
                {(x.edit) && <Grid item xs={2} className={classes.listDetail}>
                  <div className={classes.buttonHolder}>
                    <CloseIcon className={classes.newButton} onClick={() => handleCancelEditBookingSource(i)} />
                  </div>
                  <div className={classes.buttonHolder}>
                    <DoneIcon className={classes.newButton} onClick={() => handleConfirmEditSource(i)} />
                  </div>
                </Grid>}
              </Grid>
            )
          })}
        </div>}
        {(source.length === 0) && <Grid container alignItems="center" className={classes.verticalMargin}>
          <Grid item xs={12}>
            <span><i>No Booking Source found!</i></span>
          </Grid>
        </Grid>}
        {(add.bookingSource) && <Grid container alignItems="center">
          <Grid item xs={10}>
            <div className={clsx(classes.field, classes.addContainer)}>
              <InputBase 
                id="bookingSource"
                name="bookingSource"
                value={tag.bookingSource}
                onChange={handleChange}
                fullWidth
                type="text"
              />
            </div>
          </Grid>
          <Grid item xs={2} className={classes.listDetail}>
            <div className={classes.buttonHolder}>
              <DoneIcon className={classes.newButton} onClick={() => handleSaveBookingSource()} />
            </div>
          </Grid>
        </Grid>}
        {(!add.bookingSource) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <span className={classes.addButton} onClick={() => handleAdd('bookingSource')}>Add Booking Source</span>
          </Grid>
        </Grid>}
      </div>



      {(errors.EDIT_BUILDING || errors.CREATE_BUILDING || errors.CREATE_TRANSACTION_MODE || errors.UPDATE_TRANSACTION_MODE 
        || errors.CREATE_TAG || errors.UPDATE_TAG || errors.CREATE_BOOKING_SOURCE || errors.UPDATE_BOOKING_SOURCE) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={3000}
          onClose={() => handleCloseError()}
          key='top-center'
          message={errors.EDIT_BUILDING || errors.CREATE_BUILDING || errors.CREATE_TRANSACTION_MODE || errors.UPDATE_TRANSACTION_MODE || errors.CREATE_TAG || errors.UPDATE_TAG || errors.CREATE_BOOKING_SOURCE || errors.UPDATE_BOOKING_SOURCE}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseError()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      {(errors.DELETE_BUILDING) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={3000}
          onClose={() => handleCloseError()}
          key='top-center'
          message="Building is mapped to rooms."
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseError()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  spaces: state.spaces,
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  transactionModes: state.transactionModes,
  bookingSource: state.bookingSource,
  buildings: state.buildings,
  errors: state.errors
})

export default withTranslation()(connect(mapStateToProps)(ListSettings))