import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter, useLocation} from 'react-router-dom';

import LoginSection from './LoginSection';
import ForgotPassword from './ForgotPassword';

import { withStyles } from '@material-ui/core';
import { Grid, Hidden, Typography, CircularProgress } from '@material-ui/core';

import IMAGES from '../../constants/images';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'scroll',
    position: 'relative',
    width: '100%',
  },
  progressHeader: {
    position: 'absolute',
    top: 40,
    left: 40,
  },
  logo: {
    cursor: 'pointer',
    '& img': {
      height: 50,
      width: 50
    }
  },
  container: {
    background: '#F5F1ED',
    flexGrow: 1,
    height: '100%',
  },
  userInfoContainer: {
    display: 'flex',
    height: '100%'
  },
  background: {
    alignItems: 'center',
    background: '#FFFFFF',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageContainer: {
    width: '50%'
  },
  signupImage: {
    objectFit: 'contain',
    width: '100%',
  },
  welcome: {
    marginBottom: 15,
    marginTop: 30,
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: '#ffffff4d',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '45%',
  },
});


const forgotPath = '/forgotPassword';
const loginPath = '/login';

const LoginPage = (props) => {

  const location = useLocation();
  
  const { classes, isLoading } = props;
  let currentPath = location.pathname;

  useEffect(() => {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (props.auth.isAuthenticated) {
      props.history.push("/");
    }
  }, [])

  const handleForgot = () => {
    props.history.push(forgotPath);
  }

  const handleBack = () => {
    props.history.push(loginPath);
  }

  return (
    <div className={classes.root}>

      <div className={classes.progressHeader}>
        <div className={classes.logo}><img src={IMAGES.LOGOS.counter} /></div>
      </div>
    
      <Grid container className={classes.container}>
        
        <Grid item xs={12} md={6}>
          {currentPath === loginPath && <LoginSection handleForgot={handleForgot} />}
          {currentPath === forgotPath && <ForgotPassword handleBack={handleBack} />}
        </Grid>

        <Hidden smDown><Grid item xs={12} md={6}>
          <div className={classes.background}>
            <div className={classes.imageContainer}>
              <img className={classes.signupImage} src={IMAGES.ILLUSTRATIONS.communityLarge} />
            </div>
            <div className={classes.welcome}>
              <Typography variant="h1" style={{color: '#333333'}}>counter</Typography>
            </div>
            <div>
              <Typography variant="h4" style={{fontWeight: 600}}>all-in-one workspace for hostels</Typography>
            </div>
          </div>
        </Grid></Hidden>

      </Grid>     

      {/* ----------------PROGRESS LOADER--------------------- */}
      {isLoading && (
        <div className={classes.loadingState}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.loading.LOGIN,
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(LoginPage)));