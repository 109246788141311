import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        display: "flex",
        boxShadow: "0 1px 20px #f2f2f2",
        backgroundColor: "#fff"
    },
    toggleElement: {
        textAlign: 'center',
        width: '50%',
        fontSize: '1.2rem',
        padding: '10px',
        cursor: 'pointer',
        color: '#666666',
        '&.active': {
            color: '#fff',
            backgroundColor: '#4f9581',
            cursor: 'default'
        }
    },
}));

const ToggleButton = props =>{
    const classes = useStyles();
    const { name = '', isOn = false, onChangeHandler } = props;

    const toggleHandler = status =>{
        onChangeHandler(name, status);
    }

    return (
        <div className={classes.root}>
            <div 
                className={clsx(classes.toggleElement, { active: !isOn })}
                onClick={() =>toggleHandler(false)}
            >
                OFF
            </div>
            <div 
                className={clsx(classes.toggleElement, { active: isOn })}
                onClick={() =>toggleHandler(true)}
            >
                ON
            </div>
        </div>
    )
}

export default ToggleButton;