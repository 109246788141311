import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment-timezone";
import clsx from 'clsx';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { DATE_FORMATS, fetchDateFormat } from '../../../utils/utility';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import IMAGES from '../../../constants/images';


const useStyles = makeStyles(theme => ({
  days: {
    alignItems: 'center',
    marginBottom: 15
  },
  rangePicker: {
    marginRight: 30,
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      fontSize: '1.3rem',
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      boxShadow: '0 3px 4px 0 #ECECEC',
      border: 'none',
    }
  },
  blockItems: {
    marginBottom: 5,
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.3rem',
    height: 40,
    justifyContent: 'center',
    marginRight: 10,
    width: 50,
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px'
    },
  },
  blockItemsValue: {
    marginLeft: 12,
    marginTop: 15
  },
  rangePickerContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
    },
  },
  selected: {
    backgroundColor: theme.palette.grey[300],
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 30,
    marginTop: 10,
    height: 20,
    width: 20
  }
}))

const daysOfWeek = [
  {value: 'Monday', label: 'Mon'},
  {value: 'Tuesday', label: 'Tue'},
  {value: 'Wednesday', label: 'Wed'},
  {value: 'Thursday', label: 'Thu'},
  {value: 'Friday', label: 'Fri'},
  {value: 'Saturday', label: 'Sat'},
  {value: 'Sunday', label: 'Sun'}
]

const DateRange = (props) => {
  const classes = useStyles();
  const { handleUpdate, rangeNumber, timezone, handleDelete } = props;

  const [dates, setDates] = React.useState({});
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [days, setDays] = React.useState([]);

  const today = fetchDateFormat(new Date(), DATE_FORMATS.DEFAULT_DATE, timezone);

  const handleDaySelect = (day) => {
    const daysArray = [...days]
    const index = daysArray.indexOf(day);
    if (index >= 0) {
      daysArray.splice(index, 1);
    } else {
      daysArray.push(day);
    }
    setDays(daysArray);
    handleProcessData({ ...dates, days: daysArray });
  }

  const handleAllSelect = () => {
    setDays([]);
    handleProcessData({ ...dates, days: [] });
  }

  const handleDatesSelect = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
    handleProcessData({ startDate, endDate, days });
  }

  const handleProcessData = ({ startDate, endDate, days }) => {
    handleUpdate({
      startDate: fetchDateFormat(startDate, DATE_FORMATS.DEFAULT_DATE),
      endDate: fetchDateFormat(endDate, DATE_FORMATS.DEFAULT_DATE),
      daysOfWeek: days
    }, rangeNumber)
  }

  const isAfterMaxAllowedUpdateDate = day =>{
    if(dates?.startDate){
      const maxAllowedUpdateDate = moment(dates.startDate).tz(timezone).add(1, 'years').format(DATE_FORMATS.DEFAULT_DATE);
      const status = day.isAfter(maxAllowedUpdateDate, 'day');
      return status;
    }

    return false;
  }


  return (
    <div className={classes.days}>

      <Grid container>
        <Grid item sm={12} md={5}>
          <div className={classes.rangePicker}>
              <DateRangePicker
                startDate={dates.startDate}
                startDateId="start_date"
                endDate={dates.endDate}
                endDateId="end_date"
                onDatesChange={({ startDate, endDate }) => handleDatesSelect({ startDate, endDate })}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                focusedInput={focusedInput}
                displayFormat={'DD MMM YYYY'}
                hideKeyboardShortcutsPanel
                isOutsideRange={day => day.isBefore(today) || isAfterMaxAllowedUpdateDate(day)}
                numberOfMonths={1}
                minimumNights={0}
                readOnly
              />
          </div>
        </Grid>
        <Grid item sm={12} md ={7}>
          <div className={classes.rangePickerContainer}>
            
            <div className={clsx({[classes.blockItems]: true, [classes.selected]: !days.length})} onClick={() => handleAllSelect()}>
              <div className={classes.blockItemsValue}>All</div> 
            </div>

            {daysOfWeek.map(item => (
              <div 
                key={item.value}
                className={clsx({[classes.blockItems]: true, [classes.selected]: days.includes(item.value)})}
                onClick={() => handleDaySelect(item.value)}
              >
                <div className={classes.blockItemsValue}>{item.label}</div>
              </div>
            ))}

            <img src={IMAGES.ICONS.bin} className={classes.icon} onClick={() => handleDelete(rangeNumber)} />
          </div>
        </Grid>
      </Grid>

    </div>
  )
}

export default DateRange;