import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GuestDateCard from './GuestDateCard';
import CalendarSetting from './CalendarSetting';
import { DEVICE_VIEW, DISPLAY_SETTING, fetchCalendarDates, FORM_TYPE, GetDateRange } from './Helper';
import { checkDateInBetween, DATE_FORMATS, fetchDateFormat } from '../../../../utils/utility';

const useStyles = makeStyles(theme => ({
    root: {

    },
    settings: {
        marginBottom: 20,
    },
    row: {
        display: 'flex',
        // justifyContent: 'space-between'
    }
}));

const Calendar = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { data, allowDataActions, currentSpace, view } = props;
    let datesData = fetchCalendarDates(DISPLAY_SETTING.WEEK, { checkIn: data.checkIn, checkOut: data.checkOut }, view);
    const [selectedDate, setSelectedDate] = React.useState(data.checkIn)
    const [dates, setDates] = React.useState(datesData)
    const [range, setRange] = React.useState({ startDate: null, endDate: null })
    const [selectedCards, setSelectedCards] = React.useState([]);
    let previousDayBed = null;


    React.useImperativeHandle(ref, () => ({
        clearRangeHandler() {
            setSelectedCards([]);
            setRange({ startDate: null, endDate: null })
        }
    }))

    React.useEffect(() => {
        setSelectedDate(data.checkIn);
        changeDateHandler(DISPLAY_SETTING.WEEK, data.checkIn) 
    }, [data.checkIn])

    // --------------------------- Dates ------------------------
    const selectDateHandler = (date) => {
        let cards = [...selectedCards];
        cards.push(date)
        if(cards.length > 2){
            cards.shift();
        }
        let dateRange = GetDateRange(cards[0], cards[1])
        setRange(dateRange)
        if (cards.length === 2) {
            props.changeModalHandler(FORM_TYPE.CHOICE, dateRange)
        }
        setSelectedCards(cards);
    }

    const changeDateHandler = (layout, date) => {
        let datesInfo = fetchCalendarDates(layout, { startDate: date, checkIn: data.checkIn, checkOut: data.checkOut }, view);
        setSelectedDate(fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE))
        setDates(datesInfo);
    }
    // ---------------------------------------------------------------

    const fetchPropsDataHandler = (date, previousDayBed) => {
        let isDifferent = false;
        let selected = date === range.startDate || date === range.endDate;
        let highlighted = checkDateInBetween(range.startDate, range.endDate, date, '()')
        let dataInfo = { ...data };
        let cardData = dataInfo?.items?.find(item => item.date === date);

        let currentDayBed = cardData?.bedID || null;
        if (previousDayBed && previousDayBed !== currentDayBed) {
            isDifferent = true;
        }

        return { isDifferent, selected, highlighted, cardData, currentDayBed };
    }

    return (
        <React.Fragment>
            <div className={classes.settings}>
                <CalendarSetting
                    index={props.index}
                    changeDateHandler={changeDateHandler}
                    date={selectedDate}
                    view={view}
                />
            </div>
            {
                dates?.map((dateArray, rowIndex) => (
                    <div className={classes.row} key={rowIndex}>
                        {
                            dateArray?.map((date, index) => {
                                let { isDifferent, selected, highlighted, cardData, currentDayBed } = fetchPropsDataHandler(date, previousDayBed)
                                previousDayBed = currentDayBed;

                                return (
                                    <GuestDateCard
                                        key={index}
                                        date={date}
                                        view={view}
                                        isDifferent={isDifferent}
                                        selected={selected}
                                        allowDataActions={allowDataActions}
                                        highlighted={highlighted}
                                        displayDay={rowIndex === 0 || view === DEVICE_VIEW.SMALL}
                                        timezone={currentSpace.timezone}
                                        data={cardData}
                                        selectDateHandler={selectDateHandler}
                                    />
                                )
                            })
                        }
                    </div>
                ))
            }

        </React.Fragment>
    )
})

export default Calendar;