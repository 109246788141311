
export default (state = {}, action) => {
  switch(action.type) {
    case "LOAD_ANNOUNCEMENTS_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.announcements
      }
    case "ADD_ANNOUNCEMENT_SUCCESS":
      console.log('here2', action.payload, state);    
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "ADD_ANNOUNCEMENT_COMMENT_SUCCESS":
      const announcementComment = state[action.payload.announcementID];
      return {
        ...state,
        [action.payload.announcementID]: {
          ...announcementComment,
          comments: [...announcementComment.comments, action.payload._id]
        }
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};

