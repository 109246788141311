import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import CashAccounts from "./CashAccounts";
import { returnCurrent } from "../../redux/selectors/dashboard";

import Grid from "@material-ui/core/Grid";
import { Card, CardActionArea, Typography } from "@material-ui/core";
import {
  fetchCashAccountItems,
  resetCashAccounts,
} from "../../redux/actions/cash-drawers/cashAccounts";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    width: "100%",
  },
  card: {
    alignItems: "flex-start",
    display: "flex",
    width: "100%",
  },
  cardAction: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
  },
  header: {
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 200,
    width: "100%",
  },
  bubbleHolder: {
    cursor: "pointer",
    margin: "auto",
    paddingTop: theme.spacing(1),
    width: 50,
  },
  bubble: {
    backgroundColor: "#bdbdbd",
    border: "1px solid #dddddd",
    borderRadius: "50%",
    color: "#ffffff",
    fontSize: "2rem",
    height: 50,
    lineHeight: 2.5,
    textAlign: "center",
    width: 50,
  },
  name: {
    color: "#999999",
    display: "flex",
    fontSize: "1rem",
    justifyContent: "center",
    marginTop: 5,
    textAlign: "center",
  },
  highlight: {
    color: "#666666",
    textAlign: "center",
  },
  description: {
    color: "#666666",
    textAlign: "center",
  },
}));

const CashAccountSnapshot = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { propertyID, currentSpaceID, live, loadingLive } = props;

  let count = 0;
  if (!loadingLive && live[currentSpaceID]) {
    count = live[currentSpaceID].cashAccountCount;
  }

  const [cashAccountModal, setCashAccountModal] = React.useState(false);

  const handleOpenCashAccountModal = () => {
    props.dispatch(fetchCashAccountItems(propertyID));
    setCashAccountModal(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleCloseCashAccountModal = () => {
    props.dispatch(resetCashAccounts());
    setCashAccountModal(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea
          className={classes.cardAction}
          onClick={handleOpenCashAccountModal}
        >
          <div className={classes.header}>
            <Typography component="h4" variant="h6">
              {t("frontdesk.cashAccount.name")}
            </Typography>
          </div>
          <div className={classes.container}>
            <Grid item xs={12} sm={12}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12}>
                  <Typography variant="h2" className={classes.highlight}>
                    {count}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" className={classes.description}>
                    {t("frontdesk.cashAccount.activeInfo")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardActionArea>
      </Card>

      {cashAccountModal && (
        <CashAccounts
          open={cashAccountModal}
          handleCloseCashAccountModal={handleCloseCashAccountModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSpaceID: state.dashboard.currentSpace,
    currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
    propertyID: returnCurrent(state.spaces, state.dashboard.currentSpace)
      .propertyID,
    live: state.live,
    loadingLive: state.loading.LOAD_LIVE,
  };
};

export default withTranslation()(connect(mapStateToProps)(CashAccountSnapshot));
