import { schema } from 'normalizr';

const frontdeskUserSchema = new schema.Entity('frontdeskUsers', {}, {
  idAttribute: '_id'
});

const cashSessionSchema = new schema.Entity('cashSessions', {}, {
  idAttribute: '_id'
});

const transactionSchema = new schema.Entity('cashDrawerTransactions', {}, {
  idAttribute: '_id'
});

const cashDrawerSchema = new schema.Entity('cashDrawers', {}, {
  idAttribute: '_id'
});

const cashAccountSchema = new schema.Entity('cashAccounts', {}, {
  idAttribute: '_id'
});

const cashCategorySchema = new schema.Entity('cashCategories', {
  subCategory: [cashAccountSchema]
}, {
  idAttribute: '_id'
});

export { frontdeskUserSchema, cashSessionSchema, transactionSchema, cashDrawerSchema, cashCategorySchema }