import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import { setUpgradeAccount } from "../../redux/actions/dashboard/dashboard";

import PaymentsSnapshot from "../payments/PaymentsSnapshot";

import SnapshotCard from "../common/SnapshotCard";
import EmailTemplates from "../emails/EmailTemplates";
import BookingEngine from "../booking-engine/BookingEngine";
import OnlineCheckIn from "../onlineCheckin/OnlineCheckIn";
import Goki from "../goki/Goki";
import MailChimp from "../mailChimp/MailChimp";
import QuickBooks from "../quickBooks/QuickBooks";
import Zapier from "../zapier/Zapier";
import Odoo from "../odoo/Odoo";
import Siba from "../legal/Siba";
import Kiosk from "../kiosk/Kiosk";
import PaceRMS from "../paceRMS/PaceRMS";
import MasterwayAutomate from "../masterway/MasterwayAutomate";

import { Grid, Typography } from "@material-ui/core";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    paddingTop: 20,
    paddingBottom: 40,
    marginBottom: 40,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
    },
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    border: "0px solid #000000",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  bannerContainer: {
    background: "#FFFFFF",
    borderRadius: 5,
    boxShadow: "0 3px 4px 0 #ECECEC",
    padding: theme.spacing(2),
    marginBottom: 10,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 250,
    paddingLeft: theme.spacing(5),
  },
  titleBox: {
    marginBottom: 20,
  },
  feature: {
    color: "#666666",
    fontSize: "1.3rem",
    marginBottom: 5,
  },
  release: {
    marginTop: 20,
    marginBottom: 10,
  },
  releaseButton: {
    background: "#FFFFFF",
    border: "1px solid #006699",
    borderRadius: "5px",
    fontWeight: 600,
    color: "#006699",
    cursor: "pointer",
    display: "inline-block",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textDecoration: "none",
  },
  imageHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
    height: 200,
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      height: 250,
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  image: {
    borderRadius: 15,
    maxHeight: 200,
    objectFit: "cover",
    [theme.breakpoints.up("lg")]: {
      maxHeight: 250,
    },
  },
}));

const AutomatePage = (props) => {
  const classes = useStyles();
  const { currentSpace } = props;
  const [feature, setFeature] = React.useState("");
  let featureList = {
    emailtemplates: "plus",
    bookingengine: "plus",
    onlinecheckin: "plus",
    mailchimp: "plus",
    quickbooks: "plus",
    odoo: "plus",
  };

  if (props.currentSpace?.property?.features) {
    const allFeaturesList = {
      emailtemplates: "plus",
      bookingengine: "plus",
      onlinecheckin: "plus",
      kiosk: "pro",
      goki: "plus",
      mailchimp: "plus",
      quickbooks: "plus",
      zapier: "plus",
      odoo: "plus",
      siba: "plus",
      paceRMS: "plus",
      masterway: "plus",
    };

    props.currentSpace?.property?.features.map((feature) => {
      if (feature.isEnabled) {
        featureList[feature.name] = allFeaturesList[feature.name];
      }
    });
  }

  // URL LOGIC TO OPEN MODAL
  // ---------------------------------------------------------------------
  React.useEffect(() => {
    let pathname = window?.location?.pathname;
    let segments = pathname.split("/");
    const index = Object.keys(featureList).indexOf(segments[3]);
    if (index > -1) {
      if (
        (featureList[segments[3]] == "plus" &&
          (currentSpace.onboardingStatus == "plus" ||
            currentSpace.onboardingStatus == "pro")) ||
        (featureList[segments[3]] == "pro" &&
          currentSpace.onboardingStatus == "pro")
      ) {
        setFeature(segments[3]);
        window.Intercom &&
          window.Intercom("update", { hide_default_launcher: true });
      }
    }
  }, []);

  // OPEN FEATURE
  // ----------------------------------------------------------------------
  const handleClickOpen = (automateFeature) => {
    if (
      (featureList[automateFeature] == "plus" &&
        (currentSpace.onboardingStatus == "plus" ||
          currentSpace.onboardingStatus == "pro")) ||
      (featureList[automateFeature] == "pro" &&
        currentSpace.onboardingStatus == "pro")
    ) {
      setFeature(automateFeature);
      window.history.replaceState(
        {},
        document.title,
        `/beds/automate/${automateFeature}`
      );
      window.Intercom &&
        window.Intercom("update", { hide_default_launcher: true });
    }
  };

  // CLOSE FEATURE
  // ----------------------------------------------------------------------
  const handleClose = () => {
    setFeature("");
    window.history.replaceState({}, document.title, `/beds/automate`);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {!currentSpace.onboardingStatus && (
          <Grid item xs={12} lg={12} xl={12}>
            <div className={classes.bannerContainer}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={5}>
                  <div className={classes.titleContainer}>
                    <div className={classes.titleBox}>
                      <Typography variant="h3">
                        Automate your Business
                      </Typography>
                      <Typography variant="h5">
                        Save time on repetitive tasks
                      </Typography>
                    </div>
                    <div className={classes.release}>
                      <div
                        className={classes.releaseButton}
                        onClick={() => props.dispatch(setUpgradeAccount(true))}
                      >
                        Upgrade Your Account
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={7}>
                  <div className={classes.imageHolder}>
                    <img
                      src="/images/illustration-automate.jpg"
                      className={classes.image}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                    EMAIL TEMPLATES                                */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.EMAIL.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <SnapshotCard
                name="Email Templates"
                infoLink="https://support.counter.app/en/articles/3989013-email-templates-and-automation"
                badge="plus"
                iconSource={IMAGES.ILLUSTRATIONS.emailTemplates}
                handleClickOpen={() => handleClickOpen("emailtemplates")}
              />
            </div>
          </Grid>

          {feature == "emailtemplates" && (
            <EmailTemplates
              open={feature == "emailtemplates"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        PAYMENTS                                   */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.PAYMENTS.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <PaymentsSnapshot />
            </div>
          </Grid>
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                      BOOKING ENGINE                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.BOOKING_ENGINE.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <SnapshotCard
                name="Booking Engine"
                infoLink="https://support.counter.app/en/articles/4288036-quickbooks-integration"
                demoLink="https://frontdesk.counter.app/demo-booking"
                badge="plus"
                iconSource={IMAGES.ILLUSTRATIONS.bookingEngine}
                handleClickOpen={() => handleClickOpen("bookingengine")}
              />
            </div>
          </Grid>

          {feature == "bookingengine" && (
            <BookingEngine
              open={feature == "bookingengine"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        ONLINE CHECKIN                             */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.ONLINE_CHECKIN.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <SnapshotCard
                name="Online Check-in"
                infoLink="https://support.counter.app/en/articles/4354905-zapier-integration"
                demoLink="https://frontdesk.counter.app/demo-checkin"
                badge="plus"
                iconSource={IMAGES.ILLUSTRATIONS.onlineCheckIn}
                handleClickOpen={() => handleClickOpen("onlinecheckin")}
              />
            </div>
          </Grid>

          {feature == "onlinecheckin" && (
            <OnlineCheckIn
              open={feature == "onlinecheckin"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                             KIOSK                                 */}
        {/* ----------------------------------------------------------------- */}
        {featureList.hasOwnProperty("kiosk") > 0 && (
          <AccessController requiredPermission={MODULE.KIOSK_CHECKIN.ID}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.container}>
                <SnapshotCard
                  name="Kiosk"
                  infoLink="https://support.counter.app/en/articles/4354905-zapier-integration"
                  badge="pro"
                  iconSource={IMAGES.ILLUSTRATIONS.bookingEngine}
                  handleClickOpen={() => handleClickOpen("kiosk")}
                />
              </div>
            </Grid>

            {feature == "kiosk" && (
              <Kiosk open={feature == "kiosk"} handleClose={handleClose} />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                             GOKI                                  */}
        {/* ----------------------------------------------------------------- */}
        {featureList.hasOwnProperty("goki") > 0 && (
          <AccessController requiredPermission={MODULE.GOKI.ID}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.container}>
                <SnapshotCard
                  name="Goki"
                  infoLink="https://support.counter.app/en/articles/4270171-goki-integration"
                  badge="plus"
                  iconSource={IMAGES.LOGOS.goki}
                  handleClickOpen={() => handleClickOpen("goki")}
                />
              </div>
            </Grid>

            {feature == "goki" && (
              <Goki open={feature == "goki"} handleClose={handleClose} />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                           MAILCHIMP                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.MAILCHIMP.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <SnapshotCard
                name="Mailchimp"
                infoLink="https://support.counter.app/en/articles/4258087-mailchimp-integration"
                badge="plus"
                iconSource={IMAGES.LOGOS.mailchimp}
                handleClickOpen={() => handleClickOpen("mailchimp")}
              />
            </div>
          </Grid>

          {feature == "mailchimp" && (
            <MailChimp
              open={feature == "mailchimp"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           QUICKBOOKS                              */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.QUICKBOOKS.ID}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container}>
              <SnapshotCard
                name="Quickbooks"
                infoLink="https://support.counter.app/en/articles/4288036-quickbooks-integration"
                badge="plus"
                iconSource={IMAGES.LOGOS.quickBooks}
                handleClickOpen={() => handleClickOpen("quickbooks")}
              />
            </div>
          </Grid>

          {feature == "quickbooks" && (
            <QuickBooks
              open={feature == "quickbooks"}
              handleClose={handleClose}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                             ZAPIER                                */}
        {/* ----------------------------------------------------------------- */}
        {featureList.hasOwnProperty("zapier") > 0 && (
          <AccessController requiredPermission={MODULE.ZAPIER.ID}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.container}>
                <SnapshotCard
                  name="Zapier"
                  infoLink="https://support.counter.app/en/articles/4354905-zapier-integration"
                  badge="plus"
                  iconSource={IMAGES.LOGOS.zapier}
                  handleClickOpen={() => handleClickOpen("zapier")}
                />
              </div>
            </Grid>

            {feature == "zapier" && (
              <Zapier open={feature == "zapier"} handleClose={handleClose} />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                              ODOO                                 */}
        {/* ----------------------------------------------------------------- */}
        {featureList.hasOwnProperty("odoo") > 0 && (
          <AccessController requiredPermission={MODULE.ODOO.ID}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.container}>
                <SnapshotCard
                  name="Odoo"
                  infoLink="https://support.counter.app/en/articles/4354905-zapier-integration"
                  badge="plus"
                  iconSource={IMAGES.LOGOS.odoo}
                  handleClickOpen={() => handleClickOpen("odoo")}
                />
              </div>
            </Grid>

            {feature == "odoo" && (
              <Odoo open={feature == "odoo"} handleClose={handleClose} />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                             SIBA                                  */}
        {/* ----------------------------------------------------------------- */}
        {currentSpace.country === "PRT" &&
          featureList.hasOwnProperty("siba") > 0 && (
            <AccessController requiredPermission={MODULE.SIBA.ID}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <div className={classes.container}>
                  <SnapshotCard
                    name="Siba"
                    infoLink="https://support.counter.app/en/articles/4354905-zapier-integration"
                    badge="plus"
                    iconSource={IMAGES.LOGOS.siba}
                    handleClickOpen={() => handleClickOpen("siba")}
                  />
                </div>
              </Grid>

              {feature == "siba" && (
                <Siba open={feature == "siba"} handleClose={handleClose} />
              )}
            </AccessController>
          )}

        {/* ----------------------------------------------------------------- */}
        {/*                           PACE RMS                                */}
        {/* ----------------------------------------------------------------- */}
        {featureList.hasOwnProperty("paceRMS") > 0 && (
          <AccessController requiredPermission={MODULE.PACE_RMS.ID}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.container}>
                <SnapshotCard
                  name="Pace RMS"
                  infoLink="https://support.counter.app/en/articles/4270171-goki-integration"
                  badge="plus"
                  iconSource={IMAGES.LOGOS.paceRMS}
                  handleClickOpen={() => handleClickOpen("paceRMS")}
                />
              </div>
            </Grid>

            {feature == "paceRMS" && (
              <PaceRMS open={feature == "paceRMS"} handleClose={handleClose} />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                           Masterway                               */}
        {/* ----------------------------------------------------------------- */}
        {currentSpace.country === "PRT" &&
          featureList.hasOwnProperty("masterway") > 0 && (
            <AccessController requiredPermission={MODULE.MASTERWAY.ID}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <div className={classes.container}>
                  <SnapshotCard
                    name="Masterway"
                    infoLink="https://app.masterway.net/"
                    badge="plus"
                    iconSource={IMAGES.LOGOS.masterway}
                    handleClickOpen={() => handleClickOpen("masterway")}
                  />
                </div>
              </Grid>

              {feature === "masterway" && (
                <MasterwayAutomate
                  open={feature === "masterway"}
                  handleClose={handleClose}
                />
              )}
            </AccessController>
          )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default connect(mapStateToProps)(AutomatePage);
