import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'; 
import AccessHeaderAction from '../permissions/AcessHeaderAction';

const useStyles = makeStyles(theme =>({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 40,
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        marginBottom: 5,
        '& h4': {
            color: "#4f4f4f",
            fontWeight: 600,
        }
    },
    permissions: {
        marginTop: '-12px'
    },
    accessHeader: {
        marginTop: 'unset'
    },
}));

const ModuleHeader = props =>{
    const classes = useStyles();
    return(
        <div className={clsx(classes.root, props.className)}>
            {/* <div className="title">{props.title}</div>
            <AccessHeaderAction
                moduleID={props.moduleID}
                className={classes.accessHeader}
            /> */}
            <div className={classes.titleBox}>
                <div className={classes.header}>
                    <Typography component="h4" variant="h6">{props.title}</Typography>
                </div>
            </div>
            <div className={classes.permissions}>
                <AccessHeaderAction
                    moduleID={props.moduleID}
                    className={classes.accessHeader}
                />
            </div>
        </div>
    )
}

export default ModuleHeader;