import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from "moment";

import {toggleNotification} from "../../redux/actions/notifications/notifications";
import { setCurrentSpace, setCurrentTodo, setCurrentFolder } from "../../redux/actions/dashboard/dashboard";
import { fetchTodo } from "../../redux/actions/todos/todos";
import { initials } from '../utils/wordUtils';
import { loadFiles } from "../../redux/actions/files/files";

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  notificationContainer: {
    
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: '1px solid #dddddd',
    color: "#ffffff",
    fontSize: '1.8rem',
    lineHeight: 2.2,
    textAlign: 'center'
  },
  header: {
    
  },
  inline: {
    fontSize: '1.3rem',
    display: 'inline',
  },
  dot: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    cursor: 'pointer',
    height: 8,
    margin: 5,
    width: 8
  }
}));

const Notification = (props) => {
  const classes = useStyles();

  const {users, spaces, todos, notification, files, currentSpace} = props;
  var sender;
  if (users && notification.genType === 'user') {
    sender = users[notification.genID];
  } else if (spaces && notification.genType === 'space') {
    sender = spaces[notification.genID];
  }

  const handleToggleRead = () => {
    const newStatus = !props.notification.status
    // console.log(props.notification)
    props.dispatch(toggleNotification(props.notification._id, newStatus))
  }

  const handleNotificationClick = () => {
    // console.log(props.notification);
    // ----------------------------------------------------------------
    // SYSTEM NOTIFICATION
    // ----------------------------------------------------------------
    if (props.notification.genType === 'space') {
      props.handleNotClose();
      if (props.notification.status === false) {
        handleToggleRead();
      }
      props.dispatch(setCurrentSpace(props.notification.genID));
      window.scrollTo(0, 0);
    }

    // ----------------------------------------------------------------
    // TODO NOTIFICATION
    // ----------------------------------------------------------------
    if (props.notification.genArea === 'task') {
      props.handleNotClose();
      if (props.notification.status === false) {
        handleToggleRead();
      }
      
      // Check if todo already exists in Redux. If not, fetch todo.
      const todo = todos[props.notification.genAreaID];
      if (todo) {
        props.dispatch(setCurrentSpace(todo.spaceID));
        props.dispatch(setCurrentTodo(todo._id));
        window.scrollTo(0, 0);
      } else {
        console.log('new todo');
        props.dispatch(fetchTodo(props.notification.spaceID, props.notification.genAreaID));
        window.scrollTo(0, 0);
      }
    }

    // ----------------------------------------------------------------
    // FILE NOTIFICATION
    // ----------------------------------------------------------------
    if (props.notification.genArea === 'file') {
      props.handleNotClose();
      if (props.notification.status === false) {
        handleToggleRead();
      }
      props.dispatch(setCurrentSpace(props.notification.spaceID));
      props.dispatch(setCurrentFolder(props.notification.genAreaID));
      props.dispatch(loadFiles(props.notification.spaceID, props.notification.genAreaID))
      window.scrollTo(0, 0);
    }
  }

  return (
    <ListItem 
      button 
      onClick={handleNotificationClick}
      alignItems="flex-start" 
      className={classes.notificationContainer}
    >
      <ListItemAvatar>
        <Avatar 
          alt={notification.genType === 'user' && users[notification.genID] && initials(users[notification.genID].name)}
          src={ users[notification.genID] &&
            (notification.genType === 'user' ? 
            users[notification.genID].avatar : 
            spaces[notification.genID].profilePic)
          }
          className={classes.avatar}
        >
          {notification.genType === 'user' && users[notification.genID] && initials(users[notification.genID].name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component="span"
            variant="h6"
            className={classes.header}
            color="textPrimary"
          >
            {notification.title}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
            >
              {sender && sender.name}
            </Typography>
            <span style={{ marginLeft: 5, marginRight: 5 }}>&#xb7;</span>
            {moment(notification.createdAt).isSame(moment(), "day") ? 
              moment(notification.createdAt).format('hh:mm a') : 
              moment(notification.createdAt).format('MMM DD')
            }
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <div 
          onClick={handleToggleRead} 
          className={classes.dot} 
          style={
            notification.status ? 
            {backgroundColor: '#888888'} : 
            {backgroundColor: '#7E1541'}
          }></div>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const mapStateToProps = state => ({
  users: state.users,
  spaces: state.spaces,
  todos: state.todos,
  files: state.files
})

export default connect(mapStateToProps)(Notification);