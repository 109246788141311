const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "FETCH_LISTS_APIKEY_SUCCESS":
      return action.payload
    case "FETCH_LISTS_APIKEY_FAILURE":
      return action.payload
    case "RESET_LIST":
      return { default: true };
    default:
      return state;
  }
};
