const reports = {};

export default (state = reports, action) => {
  switch (action.type) {
    // ------------------------------------------------------------------------
    // REVENUE
    // ------------------------------------------------------------------------
    case "LOAD_REVENUE_REPORT_SUCCESS":
      return {
        ...state,
        revenue: {...action.payload}
      };
    case "LOAD_REVENUE_ITEMS_REPORT_SUCCESS":
      return {
        ...state,
        revenueItems: action.payload
      };
    case "RESET_REVENUE_REPORT":
      return {
        ...state,
        revenue: {},
        revenueItems: []
      }
    // ------------------------------------------------------------------------
    // OCCUPANCY
    // ------------------------------------------------------------------------
    case "LOAD_OCCUPANCY_REPORT_SUCCESS":
      return {
        ...state,
        occupancy: {
          ...state.occupancy,
          [action.payload.year]: action.payload.data
        }
      };
    case "RESET_OCCUPANCY_REPORT":
      return {
        ...state,
        occupancy: {}
      }
    // ------------------------------------------------------------------------
    // NO SHOW
    // ------------------------------------------------------------------------
    case "LOAD_NO_SHOW_REPORT_SUCCESS":
      return {
        ...state,
        noShows: {...action.payload}
      };
    case "LOAD_NO_SHOW_BY_DAY_SUCCESS":
      return {
        ...state,
        noShowByDay: [...action.payload]
      }
    case "RESET_NO_SHOWS":
      return {
        ...state,
        noShows: {},
        noShowByDay: []
      }
    // ------------------------------------------------------------------------
    // CANCELLATIONS
    // ------------------------------------------------------------------------
    case "LOAD_CANCELLATION_REPORT_SUCCESS":
      return {
        ...state,
        cancellations: {...action.payload}
      };
    case "LOAD_CANCELLATION_BY_DAY_SUCCESS":
      return {
        ...state,
        cancellationsByDay: [...action.payload]
      }
    case "RESET_CANCELLATIONS":
      return {
        ...state,
        cancellations: {},
        cancellationsByDay: []
      }
    // ------------------------------------------------------------------------
    // CASH DRAWER SESSIONS
    // ------------------------------------------------------------------------
    case "FETCH_CASH_DRAWER_SESSIONS_SUCCESS":
      return {
        ...state,
        cashDrawers: {
          ...state.cashDrawers,
          sessions: {...action.payload}
        }
      }
    case "RESET_CASH_DRAWER_SESSIONS":
      return {
        ...state,
        cashDrawers: {
          ...state.cashDrawers,
          sessions: {}
        }
      }
    // ------------------------------------------------------------------------
    // CASH DRAWER TRANSACTIONS
    // ------------------------------------------------------------------------
    case "FETCH_CASH_DRAWER_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        cashDrawers: {
          ...state.cashDrawers,
          transactions: {...action.payload}
        }
      }
    case "RESET_CASH_DRAWER_TRANSACTIONS":
      return {
        ...state,
        cashDrawers: {
          ...state.cashDrawers,
          transactions: {}
        } 
      }
    // ------------------------------------------------------------------------
    // TRANSACTIONS
    // ------------------------------------------------------------------------
    case "LOAD_TRANSACTIONS_BY_DAY_SUCCESS":
      return {
        ...state,
        transactions: action.payload
      }
    case "RESET_TRANSACTIONS_REPORT":
      return {
        ...state,
        transactions: []
      }
    // ------------------------------------------------------------------------
    // GUEST LIST
    // ------------------------------------------------------------------------
    case "LOAD_GUEST_LIST_SUCCESS":
      return {
        ...state,
        guestList: action.payload.guestDetails,
        demographics: action.payload.demographics
      };
    case "RESET_GUEST_LIST":
      return {
        ...state,
        guestList: [],
        demographics: []
      }
    // ------------------------------------------------------------------------
    // RESERVATIONS
    // ------------------------------------------------------------------------
    case "LOAD_RESERVATIONS_BY_DAY_SUCCESS":
      return {
        ...state,
        reservations: [
          ...action.payload
        ]
      }
    case "UPDATE_RESERVATION_STATUS_SHORTCUT_SUCCESS":
      const array = [...state.reservations];
      array.forEach((item,index)=>{
        if (item.resID == action.payload._id) array[index].status = action.payload.status
      })
      return {
        ...state,
        reservations: array
      }
    case "RESET_RESERVATIONS_REPORT":
      return {
        ...state,
        reservations: []
      }
    // ------------------------------------------------------------------------
    // BOOKING SOURCE
    // ------------------------------------------------------------------------
    case "LOAD_BOOKING_SOURCE_SUCCESS":
      return {
        ...state,
        bookingSource: {
          ...action.payload
        }
      }
    case "RESET_BOOKING_SOURCE_REPORT":
      return {
        ...state,
        bookingSource: {}
      }
    // ------------------------------------------------------------------------
    // HOUSEKEEPING
    // ------------------------------------------------------------------------
    case "LOAD_HOUSEKEEPING_SUCCESS":
      return {
        ...state,
        housekeeping: {
          ...action.payload.data
        }
      }
    case "RESET_HOUSEKEEPING_REPORT":
      return {
        ...state,
        housekeeping: {}
      }
    // ------------------------------------------------------------------------
    // BOOKING METRICS
    // ------------------------------------------------------------------------
    case "LOAD_BOOKING_METRICS_SUCCESS":
      return {
        ...state,
        bookingMetrics: {
          ...state.bookingMetrics,
          [action.payload.label]: [...action.payload.data]
        }
      }
    case "RESET_BOOKING_METRICS":
      return {
        ...state,
        bookingMetrics: {},
        availableUnits: [],
      }
      // ------------------------------------------------------------------------
      // BOOKING STATS
      // ------------------------------------------------------------------------
      case "LOAD_BOOKING_STATS_SUCCESS":
        return {
          ...state,
          bookingStats: {
            ...action.payload.data
          }
        }
      case "RESET_BOOKING_STATS":
        return {
          ...state,
          bookingStats: {},
        }
    // ------------------------------------------------------------------------
    // AVAILABLE UNITS
    // ------------------------------------------------------------------------
    case "LOAD_AVAILABLE_UNITS_SUCCESS":
      return {
        ...state,
        availableUnits: [
          ...action.payload.data
        ]
    }

    // ------------------------------------------------------------------------
    // COLOMBIA MIGRACION
    // ------------------------------------------------------------------------
    case "LOAD_COLOMBIA_REPORT_SUCCESS":
      return {
        ...state,
        colombia: [
          ...action.payload
        ]
    }
    case "RESET_COLOMBIA_REPORT":
      return {
        ...state,
        colombia: []
      }

    // ------------------------------------------------------------------------
    // EXPERIENCE RESERVATIONS
    // ------------------------------------------------------------------------
    case "LOAD_EXPERIENCE_RESERVATIONS_REPORT_SUCCESS":
      return {
        ...state,
        experienceReservations: action.payload
      }
    case "RESET_EXPERIENCE_RESERVATIONS_REPORT":
      return {
        ...state,
        experienceReservations: []
      }

    // ------------------------------------------------------------------------
    // EXPERIENCE OCCUPANCY
    // ------------------------------------------------------------------------
    case "LOAD_EXPERIENCES_OCCUPANCY_REPORT_SUCCESS":
      return {
        ...state,
        experienceOccupancy: action.payload
      }
    case "RESET_EXPERIENCE_OCCUPANCY_REPORT":
      return {
        ...state,
        experienceOccupancy: []
      }
    // ------------------------------------------------------------------------
    // EXPERIENCE REVENUE
    // ------------------------------------------------------------------------
    case "LOAD_EXPERIENCE_REVENUE_REPORT_SUCCESS":
      return {
        ...state,
        experienceRevenue: action.payload
      }
    case "RESET_EXPERIENCE_REVENUE_REPORT":
      return {
        ...state,
        experienceRevenue: []
      }

    case "FETCH_TAX_REPORT_SUCCESS":
      return {
        ...state,
        tax: [
          ...action.payload
        ]
      }

    case "RESET_TAX_REPORT":
      return {
        ...state,
        tax: []
      }
      
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};