import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import { returnCurrent } from "../../redux/selectors/dashboard";
import {
  loadRevenueItems,
  resetRevenueReport,
} from "../../redux/actions/reports/revenue";
import {
  loadOccupancy,
  loadRevOccupancy,
  resetOccupancyReport,
} from "../../redux/actions/reports/occupancy";
import {
  combinedProcessor,
  revenueItemsGraphProcessor,
  revenueItemsProcessor,
  categoryProcessor,
} from "../../redux/selectors/reports/revenue";
import Bar from "../charts/Bar";
import StackedBar from "../charts/StackedBar";
import BarLine from "../charts/BarLine";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { exportExcel, groupingConfig } from "../utils/excelExport";
import { BOOKING_SOURCE } from "../../utils/constants";
import { fetchCurrencySymbol, currencyToNumber } from "../../utils/utility";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "&:hover": {
      background: "#eeeeee",
    },
  },
  icon: {
    fontSize: "5rem",
  },
  downloadButton: {
    cursor: "pointer",
    height: 25,
    width: 25,
    left: 24,
    opacity: 0.7,
    position: "absolute",
    top: 28,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    // boxShadow: '0 1px 10px 0 #E0E0E0',
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
      // boxShadow: '0 1px 10px #E0E0E0',
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  tabHolder: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    flexDirection: "column",
    height: "30px",
    justifyContent: "center",
    marginRight: 10,
    width: "80px",
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  label: {
    color: "#666666",
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  revenueGraph: {
    height: 450,
    width: "100%",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  toggleHolder: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 600,
    marginRight: 20,
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

const TotalRevenueTable = (props) => {
  const { t, currencyData, level } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.totalRevenue");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "string",
    "currency",
    "currency",
    "currency",
    "currency",
    "currency",
  ];
  if (level !== "daily")
    excelHeaderType = [
      "string",
      "currency",
      "currency",
      "currency",
      "currency",
      "currency",
    ];
  const excelColWidth = [10, 20, 20, 20, 20, 20];

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        { title: "Date", field: "date", align: "center" },
        {
          title: `Room Revenue (${currencyData.code})`,
          field: "roomRevenue",
          align: "center",
        },
        {
          title: `Add Ons Revenue (${currencyData.code})`,
          field: "upsellRevenue",
          align: "center",
        },
        {
          title: `Experiences Revenue (${currencyData.code})`,
          field: "experiencesRevenue",
          align: "center",
        },
        {
          title: `POS Revenue (${currencyData.code})`,
          field: "posRevenue",
          align: "center",
        },
        {
          title: `Total Revenue (${currencyData.code})`,
          field: "totalRevenue",
          align: "center",
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        sorting: false,
        headerStyle: {
          backgroundColor: "#F6F2EE",
          color: "#000000",
          zIndex: 0,
        },
        rowStyle: (rowData) => ({
          background: rowData.totalRow ? "#F0F0F0" : "#FFFFFF",
          color: "#333333",
          fontSize: "1.2rem",
          fontWeight: rowData.totalRow ? 600 : 500,
        }),
        paging: rowCount > 50 ? true : false,
        search: false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth,
            currencyData,
            true
          );
        },
      }}
    />
  );
};

const RoomRevenueTable = (props) => {
  const { t, currencyData, level } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.roomRevenue");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "string",
    "currency",
    "currency",
    "currency",
    "percentage",
  ];
  if (level !== "daily")
    excelHeaderType = [
      "string",
      "currency",
      "currency",
      "currency",
      "percentage",
    ];
  const excelColWidth = [10, 20, 20, 20, 20];

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        { title: "Date", field: "date", align: "center" },
        {
          title: `Dorms Revenue (${currencyData.code})`,
          field: "dormsRevenue",
          align: "center",
        },
        {
          title: `Privates Revenue (${currencyData.code})`,
          field: "privatesRevenue",
          align: "center",
        },
        {
          title: `Total Room Revenue (${currencyData.code})`,
          field: "totalRevenue",
          align: "center",
        },
        { title: "Occupancy", field: "occupancy", align: "center" },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        sorting: false,
        headerStyle: {
          backgroundColor: "#F6F2EE",
          zIndex: 0,
        },
        rowStyle: (rowData) => ({
          background: rowData.totalRow ? "#F0F0F0" : "#FFFFFF",
          color: "#333333",
          fontSize: "1.2rem",
          fontWeight: rowData.totalRow ? 600 : 500,
        }),
        paging: rowCount > 50 ? true : false,
        search: false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 75, 100],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth,
            currencyData,
            true
          );
        },
      }}
    />
  );
};

const ItemizedRevenueTable = (props) => {
  const { t, currencyData, level } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.itemizedRevenue");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = ["string", "string", "string", "wholeNumber", "currency"];
  if (level !== "daily")
    excelHeaderType = ["string", "string", "string", "wholeNumber", "currency"];
  const excelColWidth = [10, 20, 40, 10, 20];

  const groupingConfigParmas = {
    data: props.data,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        { title: "Date", field: "date", align: "center", defaultSort: "asc" },
        { title: "Category", field: "category", align: "center" },
        { title: "Product", field: "product", align: "center" },
        { title: "Units", field: "count", align: "center" },
        {
          title: `Amount (${currencyData.code})`,
          field: "amount",
          align: "center",
          customSort: (a, b) =>
            currencyToNumber(props.currencyData, a.amount) -
            currencyToNumber(props.currencyData, b.amount),
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: "1.2rem" },
        headerStyle: {
          backgroundColor: "#F6F2EE",
        },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        emptyRowsWhenPaging: false,
      }}
    />
  );
};

const constantBookingSources = [
  "Hostelworld",
  "Booking.com",
  "Airbnb",
  "Expedia",
];

const Revenue = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const { open, handleClose, currentSpace, loadingRevenueItems } = props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[2]);
  const [filter, setFilter] = React.useState("date");
  const [tableFilter, setTableFilter] = React.useState("total");
  const [level, setLevel] = React.useState("daily");
  const [submittedLevel, setSubmittedLevel] = React.useState("daily");
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };

  const [bookingSources, setBookingSources] = React.useState([]);
  const [selectedBookingSources, setSelectedBookingSources] = React.useState(
    []
  );

  const { combinedGraphData, combinedTableData, bedCategoryData } = useSelector(
    (state) =>
      combinedProcessor(
        state.reports.occupancy,
        state.reports.revenueItems,
        state.roomTypes,
        currencyData,
        submittedDates,
        submittedLevel
      )
  );
  const revenueItemsGraph = useSelector((state) =>
    revenueItemsGraphProcessor(
      state.reports.revenueItems,
      submittedDates,
      submittedLevel
    )
  );
  const { revenueItemsTable, productGraphData } = useSelector((state) =>
    revenueItemsProcessor(
      state.reports.revenueItems,
      state.roomTypes,
      state.upsell,
      state.experiences.activityList,
      state.products,
      currencyData,
      submittedDates,
      submittedLevel
    )
  );
  const categoryGraphData = useSelector((state) =>
    categoryProcessor(state.reports.revenueItems, submittedDates)
  );

  // On load of report - load revenue items and occupancy
  // ----------------------------------------------------------------
  useEffect(() => {
    initBookingSources();

    const params = {
      propertyID: currentSpace.propertyID,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      level: "daily",
    };

    props.dispatch(loadRevenueItems(params));
    //props.dispatch(loadOccupancy(currentSpace.propertyID, dates.startDate.format('YYYY-MM-DD'), dates.endDate.format('YYYY-MM-DD'), 'daily','currentYear'))
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        "daily",
        "currentYear"
      )
    );
    document.title = `${t("reports.revenue")} ${t("reports.report")} | Counter`;
    return function cleanup() {
      props.dispatch(resetRevenueReport());
      props.dispatch(resetOccupancyReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const initBookingSources = () => {
    const { live } = props;
    const sourceData =
      (live[currentSpace._id] && live[currentSpace._id].resvSource) || [];

    const bookingSourceData = sourceData.map((data) =>
      data._id.status ? data._id.status : "None"
    );

    const constantOptions = Object.keys(BOOKING_SOURCE).map(
      (source) => BOOKING_SOURCE[source]
    );
    let bookingSouceOptions = [
      ...constantBookingSources,
      ...constantOptions,
      ...bookingSourceData,
    ];
    bookingSouceOptions = [...new Set(bookingSouceOptions)];

    const formattedSourceData = bookingSouceOptions.map((source) => ({
      value: source,
      label: source,
    }));
    setBookingSources([...formattedSourceData]);
  };

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "monthToDate") {
      reportFrom = moment().startOf("month");
    } else if (e.value === "yearToDate") {
      reportFrom = moment().startOf("year");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 90) {
      newLevel = "monthly";
    } else if (newDiff > 30) {
      newLevel = "monthly";
    }

    const params = {
      propertyID: currentSpace.propertyID,
      startDate: reportFrom.format("YYYY-MM-DD"),
      endDate: reportTo.format("YYYY-MM-DD"),
      level: newLevel,
    };

    props.dispatch(loadRevenueItems(params));
    //props.dispatch(loadOccupancy(currentSpace.propertyID, reportFrom.format('YYYY-MM-DD'), reportTo.format('YYYY-MM-DD'), newLevel,'currentYear'))
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD"),
        newLevel,
        "currentYear"
      )
    );

    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setLevel(newLevel);
    setSubmittedLevel(newLevel);
  };

  const handleSubmit = () => {
    let calcLevel = "daily";
    const difference = dates.endDate.diff(dates.startDate, "days");
    if (difference > 90) {
      calcLevel = "monthly";
    } else if (difference > 30) {
      calcLevel = "monthly";
    }

    const params = {
      propertyID: currentSpace.propertyID,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      level: calcLevel,
    };

    if (selectedBookingSources && selectedBookingSources.length > 0) {
      params.bookingSources = [...selectedBookingSources];
    }

    props.dispatch(loadRevenueItems(params));
    //props.dispatch(loadOccupancy(currentSpace.propertyID, dates.startDate.format('YYYY-MM-DD'), dates.endDate.format('YYYY-MM-DD'), calcLevel,'currentYear'));
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        calcLevel,
        "currentYear"
      )
    );
    setLevel(calcLevel);
    setSubmittedLevel(calcLevel);
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
  };

  const setBookingSource = (data) => {
    let selectedSources = [];

    if (data && data.length > 0) {
      selectedSources = data.map((sources) => sources.value);
    }

    setSelectedBookingSources([...selectedSources]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>
            {t("reports.revenue")} {t("reports.report")}
          </Typography>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.REVENUE.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>

                <Grid xs={12} sm={6} md={4} item className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[7]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                  />
                </Grid>

                {/* -------------RESERVATION STATUS SELECT DROPDOWN----------------- */}
                <Grid item xs={12} lg={12}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.fieldContainer}>
                        <Select
                          //defaultValue={statusFilters}
                          styles={selectStyle}
                          isMulti
                          placeholder="Filter by booking sources"
                          options={bookingSources}
                          onChange={setBookingSource}
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* --------------------------------------------------------- */}
            {/* GRAPH FILTERS */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && (
              <div className={classes.listSection}>
                <div className={classes.label}>Filter</div>
                <div className={classes.tabHolder}>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: filter == "date",
                    })}
                    onClick={() => setFilter("date")}
                  >
                    Date
                  </div>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: filter == "occupancy",
                    })}
                    onClick={() => setFilter("occupancy")}
                  >
                    Occupancy
                  </div>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: filter == "category",
                    })}
                    onClick={() => setFilter("category")}
                  >
                    Category
                  </div>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: filter == "product",
                    })}
                    onClick={() => setFilter("product")}
                  >
                    Product
                  </div>
                </div>
              </div>
            )}

            {loadingRevenueItems && (
              <div className={classes.loadingState}>
                <CircularProgress />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE ANALYSIS GRAPH*/}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && filter == "date" && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.revenueGraph}>
                  {revenueItemsGraph.length !== 0 && (
                    <StackedBar inputs={revenueItemsGraph} />
                  )}
                  {revenueItemsGraph.length === 0 && (
                    <div className={classes.blankSlate}>
                      {t("experiences.reports.noData")}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE ANALYSIS GRAPH WITH OCCUPANCY*/}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && filter == "occupancy" && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.revenueGraph}>
                  <BarLine inputs={combinedGraphData} />
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE GRAPH BY CATEGORY */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && filter == "category" && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.revenueGraph}>
                  <Bar
                    data={categoryGraphData}
                    labels={["Beds", "Add Ons", "Experiences", "POS"]}
                    color={["#4F9581", "#AEC1E1", "#E4ACA9", "#F2D073"]}
                  />
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE GRAPH BY PRODUCT */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && filter == "product" && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.revenueGraph}>
                  <Bar
                    data={productGraphData.map((e) => e.amount)}
                    labels={productGraphData.map((e) => e.product)}
                  />
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* TABLE FILTERS */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && (
              <div className={classes.listSection}>
                <div className={classes.label}>Table Data</div>
                <div className={classes.tabHolder}>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: tableFilter == "total",
                    })}
                    onClick={() => setTableFilter("total")}
                  >
                    Total
                  </div>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: tableFilter == "roomRevenue",
                    })}
                    onClick={() => setTableFilter("roomRevenue")}
                  >
                    Rooms
                  </div>
                  <div
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: tableFilter == "items",
                    })}
                    onClick={() => setTableFilter("items")}
                  >
                    Itemized
                  </div>
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE / OCCUPANCY TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && tableFilter == "total" && (
              <div className={classes.listSection}>
                <TotalRevenueTable
                  t={t}
                  data={combinedTableData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  level={level}
                  currencyData={currencyData}
                />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* ROOM REVENUE TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && tableFilter == "roomRevenue" && (
              <div className={classes.listSection}>
                <RoomRevenueTable
                  t={t}
                  data={bedCategoryData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  level={level}
                  currencyData={currencyData}
                />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* REVENUE ITEMS TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingRevenueItems && tableFilter == "items" && (
              <div className={classes.listSection}>
                <ItemizedRevenueTable
                  t={t}
                  data={revenueItemsTable}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  level={level}
                  currencyData={currencyData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  revenueItems: state.reports.revenueItems,
  loadingRevenueItems: state.loading.LOAD_REVENUE_ITEMS_REPORT,
  live: state.live,
});

export default withTranslation()(connect(mapStateToProps)(Revenue));
