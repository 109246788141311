import http from "../../utils/http";
import axios from "axios";
import { normalize } from 'normalizr';
import { roomTypeSchema, resSchema } from '../../schema/roomTypeSchema';
import { getRoomTypes } from "../beds/beds";

//----------------------------------------------------------------
// LOAD ROOMS AND ROOM TYPES
//----------------------------------------------------------------
// export function getRoomTypes(propID, spaceID) {
//   const ACTION_NAME = "LOAD_ROOMTYPES";
//   return function(dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .get(`/api/beds/roomType/${propID}`)
//       .then(successResult => {
//         const data = successResult.data;
//         // console.log(successResult.data);
//         var normalizedOrder = normalize(data, [ roomTypeSchema ] );
//         // console.log(normalizedOrder);
//         dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities, spaceID: spaceID} });
//       })
//       .catch(errorResult => {
//         console.log(errorResult);
//         // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
//       });
//   };
// }

//----------------------------------------------------------------
// EDIT ROOM TYPE
//----------------------------------------------------------------
export function editRoomType(roomTypeID, updatedRoomType) {
  const ACTION_NAME = "EDIT_ROOMTYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/roomTypes/${roomTypeID}`, { ...updatedRoomType })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE ROOM TYPE
//----------------------------------------------------------------
export function createRoomType(spaceID, roomType) {
  let roomTypeData = { ...roomType };
  let file = roomTypeData.image;
  delete roomTypeData.image;
  const ACTION_NAME = "CREATE_ROOMTYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/roomTypes', roomTypeData)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        if (file) {
          const fileParts = file.name.split(".");
          const fileType = fileParts[1];
          const key = data.key;
          const url = data.url;
          const options = {
            headers: {
              "Content-Type": fileType
            }
          };
          axios.put(url, file, options).then(result => {
            http.post("/api/frontdesk/roomType/images/save", {
              roomTypeID: data.roomType._id,
              propertyID: data.roomType.propertyID,
              key: key
            }).then(response => {
              let roomTypeData = { ...response.data, beds: [...data.beds], rooms: [...data.rooms] };
              let normalizedOrder = normalize(roomTypeData, roomTypeSchema);
              let roomTypeNormalised = normalizedOrder.entities.roomTypes[data.roomType._id];

              dispatch({
                type: `${ACTION_NAME}_SUCCESS`,
                payload: {
                  roomType: { ...roomTypeNormalised, spaceID },
                  beds: normalizedOrder.entities.beds,
                  rooms: normalizedOrder.entities.rooms
                }
              });
              return true;
            }).catch(error => { return error; });
          }).catch(error => { return error; });

        } else {
          let roomTypeData = { ...data.roomType, beds: [...data.beds], rooms: [...data.rooms] };
          let normalizedOrder = normalize(roomTypeData, roomTypeSchema);
          let roomTypeNormalised = normalizedOrder.entities.roomTypes[data.roomType._id];
          dispatch({
            type: `${ACTION_NAME}_SUCCESS`,
            payload: {
              roomType: { ...roomTypeNormalised, spaceID },
              beds: normalizedOrder.entities.beds,
              rooms: normalizedOrder.entities.rooms
            }
          });
          return true;
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error = errorResult?.response?.data?.error?.error?.message;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error ? { message: error } : errorResult });
        return
      });
  };
}


//----------------------------------------------------------------
// DELETE ROOM TYPE
//----------------------------------------------------------------
export function deleteRoomType(roomTypeID, spaceID, roomType, dataObject) {
  const ACTION_NAME = "DELETE_ROOM_TYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(`/api/frontdesk/roomTypes/${roomTypeID}`, { data: { ...dataObject } })
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            roomTypeID, spaceID, roomType
          }
        })
        dispatch(getRoomTypes(spaceID, dataObject.propertyID))
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        if (!error.message || error.message === "400 - {}")
          error.error = 'Unable to delete room type'
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}


//----------------------------------------------------------------
// UPLOAD ROOM TYPE IMAGE
//----------------------------------------------------------------
export function uploadRoomTypePhoto(payload) {
  const ACTION_NAME = "UPLOAD_ROOM_TYPE_PHOTO";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/frontdesk/roomTypes/images/upload", {
        propertyID: payload.propertyID,
        roomTypeID: payload.roomTypeID,
        name: payload.name,
        type: payload.type,
      })
      .then(successResult => {
        const docInfo = successResult.data;
        const key = docInfo.key;
        const url = docInfo.url;
        const options = {
          headers: {
            "Content-Type": payload.type
          }
        };
        axios.put(url, payload.file, options).then(result => {
          http.post("/api/frontdesk/roomType/images/save", {
            roomTypeID: payload.roomTypeID,
            propertyID: payload.propertyID,
            key: key
          }).then(response => {
            let data = response.data;
            dispatch({
              type: `${ACTION_NAME}_SUCCESS`,
              payload: { images: data.images },
              roomTypeID: payload.roomTypeID
            });
          }).catch(error => { return error; });
        }).catch(error => { return error; });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log(error);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

