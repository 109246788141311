import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid',
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(3),
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 14,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    listContainer: {
        overflowY: 'auto'
    },
    listSection: {
        margin: theme.spacing(3),
        minHeight: 'calc(100vh - 250px)',
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(7),
        }
    },
    smallSection: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            minHeight: 200
        }
    },
    buttonContainer: {
        margin: '0 24px',
        textAlign: 'end'
    },
    button: {
        width: 100,
        height: 50,
        background: '#000',
        color: '#fff',
        fontWeight: 700,
        '&:hover': {
            background: '#000'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    }
}));

const DialogForm = (props) => {
    const classes = useStyles();
    const { children, title, loading, isSmallerForm, buttonText } = props;
    // isSmallerForm is a prop used to make the dialog smaller for 
    // choice between "change rate" or "change room"
    // ---------------------------------------------------------------------------

    return (
        <Dialog
            open={true}
            onClose={props.closeModalHandler}
            classes={{ paperFullWidth: !isSmallerForm ? classes.root : null }}
            maxWidth={isSmallerForm? 'sm' : 'md'}
            fullWidth={true}
            fullScreen={window.innerWidth < 901}>

            <div className={classes.dialogTitle}>
                <div className={classes.header}>{title}</div>
                <CloseIcon className={classes.closeIcon} onClick={props.closeModalHandler} />
            </div>
            <div className={classes.listContainer}>
                <div className={clsx({[classes.listSection]: !isSmallerForm, [classes.smallSection]: isSmallerForm})}>
                    {children}
                </div>
            </div>
            {buttonText &&
                <div className={classes.buttonContainer}>
                    <Button onClick={()=>props.submitHandler()} className={classes.button}>{buttonText}</Button>
                </div>
            }

            {loading &&
                <div className={"loadingOverlay"}>
                    <CircularProgress className={"loading"} />
                </div>
            }
        </Dialog>
    )
}

export default DialogForm;