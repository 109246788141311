import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
// import moment from 'moment';
import clsx from "clsx";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import Revenue from "../reports/Revenue.js";
import Occupancy from "../reports/Occupancy";
import NoShow from "../reports/NoShow";
import Cancellation from "../reports/Cancellation";
import CashAudit from "../reports/cashAudits";
import Transactions from "../reports/Transactions";
import GuestList from "../reports/GuestList.js";
import Reservations from "../reports/Reservations";
import BookingSource from "../reports/BookingSource";
import Housekeeping from "../reports/Housekeeping";
import BookingMetrics from "../reports/BookingMetrics";
import Commissions from "../reports/Commissions";
import Taxes from "../reports/taxes";
import Payouts from "../reports/payouts";
import Colombia from "../reports/Colombia";
import Pickup from "../reports/Pickup";
import NewZealand from "../reports/NewZealand";

import { Grid, Typography } from "@material-ui/core";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    marginBottom: 40,
    paddingTop: 20,
    paddingBottom: 40,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
      marginTop: 20,
    },
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    border: "0px solid #000000",
    boxShadow: "0 3px 4px 0 #ECECEC",
    cursor: "pointer",
    "&:hover": {
      background: "#EFEFEF",
      transition: "0.2s",
    },
  },
  header: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  cardTitle: {
    marginLeft: 0,
    verticalAlign: "middle",
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  body: {
    borderRadius: "10px",
    minHeight: 200,
    overflow: "hidden",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      minHeight: 200,
    },
    "& img": {
      cursor: "pointer",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  emailBody: {
    minHeight: 120,
  },
  paymentBody: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: 120,
    "& svg": {
      fontSize: "5rem",
    },
  },
  description: {
    color: "#666666",
    textAlign: "center",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 150,
    justifyContent: "center",
  },
  icon: {
    fontSize: "5rem",
  },
  image: {
    height: 120,
    width: 120,
  },
  country: {
    height: 60,
    width: 60,
    opacity: 0.7,
  },
}));

const ReportsPage = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { currentSpace } = props;
  const [openReport, setOpenReport] = useState("");

  // URL LOGIC
  // ---------------------------------------------------------------------
  React.useEffect(() => {
    let pathname = window?.location?.pathname;
    let segments = pathname.split("/");
    const reportNames = [
      "revenue",
      "occupancy",
      "noshow",
      "cancellations",
      "cashaudit",
      "transactions",
      "guests",
      "bookingsource",
      "reservations",
      "housekeeping",
      "bookingmetrics",
      "commissions",
      "tax",
    ];
    const index = reportNames.indexOf(segments[3]);
    if (index > -1) {
      setOpenReport(segments[3]);
      window.Intercom &&
        window.Intercom("update", { hide_default_launcher: true });
    }
  }, []);

  // OPEN REPORT
  // ----------------------------------------------------------------------
  const handleOpenReport = (reportName) => {
    setOpenReport(reportName);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "reports",
      eventAction: "open",
      eventLabel: reportName,
    });
    window.history.replaceState(
      {},
      document.title,
      `/beds/reports/${reportName}`
    );
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  // CLOSE REPORT
  // ----------------------------------------------------------------------
  const handleCloseReport = () => {
    setOpenReport("");
    window.history.replaceState({}, document.title, `/beds/reports`);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* ----------------------------------------------------------------- */}
        {/*                           REVENUE                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.REVENUE.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("revenue")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.revenue")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsRevenue}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "revenue" && (
            <Revenue
              open={openReport == "revenue"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           OCCUPANCY                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.OCCUPANCY.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("occupancy")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.occupancy")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsOccupancy}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "occupancy" && (
            <Occupancy
              open={openReport == "occupancy"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           NO-SHOW                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.NO_SHOWS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("noshow")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.noShows")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsNoShows}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "noshow" && (
            <NoShow
              open={openReport == "noshow"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           CANCELLATIONS                           */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.CANCELLATIONS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("cancellations")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.cancellations")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsCancellations}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "cancellations" && (
            <Cancellation
              open={openReport == "cancellations"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        CASH AUDIT                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.CASH_AUDIT.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("cashaudit")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.cashAudit")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsAudit}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "cashaudit" && (
            <CashAudit
              open={openReport == "cashaudit"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        TRANSACTIONS                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.TRANSACTIONS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("transactions")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.transactions")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsTransactions}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "transactions" && (
            <Transactions
              open={openReport == "transactions"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        GUEST LIST                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.GUESTS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("guests")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.guests")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsGuests}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "guests" && (
            <GuestList
              open={openReport == "guests"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                      BOOKING SOURCE                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.BOOKING_SOURCE.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("bookingsource")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.bookingSource")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsBookingSource}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "bookingsource" && (
            <BookingSource
              open={openReport == "bookingsource"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        RESERVATIONS                               */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.RESERVATIONS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("reservations")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.reservations")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsReservations}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "reservations" && (
            <Reservations
              open={openReport == "reservations"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                      HOUSEKEEPING                                  */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.HOUSEKEEPING.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("housekeeping")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.houseKeeping")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsHousekeeping}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "housekeeping" && (
            <Housekeeping
              open={openReport == "housekeeping"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                      BOOKING METRICS                              */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.BOOKING_METRICS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("bookingmetrics")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.bookingMetrics")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsBookingMetrics}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "bookingmetrics" && (
            <BookingMetrics
              open={openReport == "bookingmetrics"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        COMMISIONS                                 */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.COMMISSIONS.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("commissions")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.commissions")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsTransactions}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "commissions" && (
            <Commissions
              open={openReport == "commissions"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                           PICKUP                                  */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.PICKUP.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("pickup")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  {t("reports.pickup")}
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsOccupancy}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "pickup" && (
            <Pickup
              open={openReport == "pickup"}
              handleClose={handleCloseReport}
            />
          )}
        </AccessController>

        {/* ----------------------------------------------------------------- */}
        {/*                        STRIPE PAYOUTS                                 */}
        {/* ----------------------------------------------------------------- */}
        {props.stripe.activated && !props.stripe.action_required && (
          <>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
              <div
                className={classes.card}
                onClick={() => handleOpenReport("payout")}
              >
                <div className={classes.header}>
                  <Typography component="h4" variant="h6">
                    {t("reports.payouts")}
                  </Typography>
                </div>
                <div className={classes.iconHolder}>
                  <img
                    src={IMAGES.ILLUSTRATIONS.reportsRevenue}
                    className={classes.image}
                  />
                </div>
              </div>
            </Grid>

            {openReport == "payout" && (
              <Payouts
                open={openReport == "payout"}
                handleClose={handleCloseReport}
              />
            )}
          </>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                        COLOMBIA                                 */}
        {/* ----------------------------------------------------------------- */}
        {currentSpace.country === "COL" && (
          <AccessController requiredPermission={MODULE.GUESTS.ID}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
              <div
                className={classes.card}
                onClick={() => handleOpenReport("colombia")}
              >
                <div className={classes.header}>
                  <Typography component="h4" variant="h6">
                    Colombia
                  </Typography>
                </div>
                <div className={classes.iconHolder}>
                  <img
                    src={IMAGES.ILLUSTRATIONS.colombia}
                    className={clsx(classes.image, classes.country)}
                  />
                </div>
              </div>
            </Grid>

            {openReport == "colombia" && (
              <Colombia
                open={openReport == "colombia"}
                handleClose={handleCloseReport}
              />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                        NEW ZEALAND                                */}
        {/* ----------------------------------------------------------------- */}
        {currentSpace.country === "NZL" && (
          <AccessController requiredPermission={MODULE.GUESTS.ID}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
              <div
                className={classes.card}
                onClick={() => handleOpenReport("newZealand")}
              >
                <div className={classes.header}>
                  <Typography component="h4" variant="h6">
                    New Zealand
                  </Typography>
                </div>
                <div className={classes.iconHolder}>
                  <img
                    src={IMAGES.ILLUSTRATIONS.newZealand}
                    className={clsx(classes.image, classes.country)}
                  />
                </div>
              </div>
            </Grid>

            {openReport == "newZealand" && (
              <NewZealand
                open={openReport == "newZealand"}
                handleClose={handleCloseReport}
              />
            )}
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/*                             TAX                                   */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.TAX.ID}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <div
              className={classes.card}
              onClick={() => handleOpenReport("tax")}
            >
              <div className={classes.header}>
                <Typography component="h4" variant="h6">
                  Tax
                </Typography>
              </div>
              <div className={classes.iconHolder}>
                <img
                  src={IMAGES.ILLUSTRATIONS.reportsTransactions}
                  className={classes.image}
                />
              </div>
            </div>
          </Grid>

          {openReport == "tax" && (
            <Taxes open={openReport == "tax"} handleClose={handleCloseReport} />
          )}
        </AccessController>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  stripe: state.stripe,
});

export default withTranslation()(connect(mapStateToProps)(ReportsPage));
