const buildings = {};

export default (state = buildings, action) => {
  switch (action.type) {
    
    case "CREATE_BUILDING_SUCCESS":
      return {
        ...state,
        ...action.payload.buildings
      }

    case "DELETE_BUILDING_SUCCESS":
      const deleteBuilding = { ...state };
      if (action.payload && action.payload._id && deleteBuilding[action.payload._id]) {
        delete deleteBuilding[action.payload._id]
      }
      return { ...deleteBuilding }

    case "EDIT_BUILDING_SUCCESS":
      return {
        ...state,
        ...action.payload.buildings
      }

    case "LOAD_BUILDINGS_SUCCESS":
      return {
        ...state,
        ...action.payload.buildings
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};