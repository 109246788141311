import React from 'react';
//import { connect } from 'react-redux';
import parse from 'html-react-parser';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//import { fetchChannexSessionToken } from "../../redux/actions/reservation/editReservation";

const CardInfoDisplay = ({ cardData = {}, hideCardHandler }) => {
    // const fetchCvvData = () =>{
    //     const scope = 'service_code';
    //     const tokenResponse = fetchChannexSessionToken(scope);
    //     tokenResponse.then(successResult =>{
    //         console.log('successResult ',successResult);
    //     });
    // }

    return (
        <div className="card">
            <IconButton 
                aria-label="delete" 
                className="hideCardIcon"
                onClick={hideCardHandler}
            >
                <CloseIcon />
            </IconButton>
            <div className="card__field card__field--card-type">
                <div className="card__field-title">Card Type:</div>
                <div className="card__field-content">
                    {cardData.cardType}
                </div>
            </div>
            <div className="card__field card__field--card-number">
                <div className="card__field-title">Number:</div>
                <div className="card__field-content">
                    {parse(cardData.cardNumber)}
                </div>
            </div>
            <div className="card__field card__field--cardholder-name">
                <div className="card__field-title">Name:</div>
                <div className="card__field-content">
                    {cardData.cardName}
                </div>
            </div>
            <div className="card__field card__field--expiration-date">
                <div className="card__field-title"> Expiration Date:</div>
                <div className="card__field-content">
                    {cardData.cardExpiry}
                </div>
            </div>
            <div className="card__field card__field--expiration-date">
                <div className="card__field-title"> CVV:</div>
            </div>
        </div>
    )
}

export default CardInfoDisplay;