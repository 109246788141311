const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_ROOM_SUGGESTION_REQUEST":
      return []
    case "RESET_ROOM_SUGGESTION":
      return []
    case "LOAD_ROOM_SUGGESTION_SUCCESS":
      let finalData = [];
      // let price = { ...action.payload.price };
      let prices = [ ...action.payload.prices ];
      let suggestions = [...action.payload.suggestions].map(function (data) {
        data.prices = [ ...prices ];
        return data;
      })
      if (state.length && suggestions.length) {
        let m = Math.max(state.length, suggestions.length);
        for (let i = 0; i < m; ++i) {
          let suggest1 = { ...state[i % state.length] };
          let suggest2 = { ...suggestions[i % suggestions.length] };

          // console.log('i % state.length', i % state.length, 'i % suggestions.length', i % suggestions.length);
          let temp = {
            roomTypeScore: suggest1.roomTypeScore,
            // price: {
            //   accommodationAmount: (suggest1.price.accommodationAmount || 0 ) + (suggest2.price.accommodationAmount || 0),
            //   exclusiveTax: (suggest1.price.exclusiveTax || 0) + (suggest2.price.exclusiveTax || 0),
            //   inclusiveTax: (suggest1.price.inclusiveTax || 0) + (suggest2.price.inclusiveTax || 0),
            // },
            prices: [ ...suggest1.prices, ...prices ],
            calendarOptions: suggest1.calendarOptions.concat(suggest2.calendarOptions)
          }
          finalData.push(temp);
        }
        return [...finalData]
      } else {
        return [...state, ...suggestions]
      }
    case "USER_LOGOUT":
      return []
    case "ADD_RESERVATION_SUCCESS":
      return []
    default:
      return state;
  }
};