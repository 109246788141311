import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, DialogContent, Dialog } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import { resetChannelMapping } from '../../../../redux/actions/channelMapping/channelMapping'
import { CHANNEL } from '../../../../utils/channels'
import Mapping from './Mapping'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialog: {
        overflowY: 'scroll'
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: '0 2px 20px #F2F2F2',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: 'center',
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    backButton: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        left: 24,
        padding: theme.spacing(1),
        position: 'absolute',
        top: 20,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
            top: '20px',
            left: '10px'
        }
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 20,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
            top: '20px',
            right: '10px'
        }
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100% - 30px)',
        // marginTop: 30,
        width: '100%'
    },
    loadingState: {
        width: '100%',
        height: 'calc(100vh)',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '#F2F2F2',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%'
    },
    dialogContent: {
        // background: '#F2F2F2'
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 30
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 10
    },
    field: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 1px 10px #E0E0E0',
        height: 35,
        // marginTop: 10,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
        '& > div': {
            width: '100%'
        }
    },
    textInputContainer: {
        display: 'flex',
        marginBottom: '30px'
    },
    fieldError: {
        border: '1px solid red'
    }
}))

const MappingDialog = props => {
    const classes = useStyles()
    const handleClose = () => {
        props.dispatch(resetChannelMapping())
        props.closeHandler()
    }


    return (
        <Dialog
            open={true}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
            classes={{ paperFullWidth: classes.dialogPaper }}
            fullScreen={window.innerWidth < 901}
        >

            <div className={classes.dialog}>
                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{CHANNEL.BookingCom.name} - Counter Mapping</div>
                    <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                </div>

                <DialogContent className={classes.dialogContent}>
                    <Mapping open={true}/>
                </DialogContent>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(MappingDialog)
