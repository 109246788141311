import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import ReactGA from "react-ga";

import CashDrawerSessions from "./CashDrawerSessions";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";
import {
  fetchCashDrawers,
  fetchCashDrawerSessions,
  resetCashDrawerSessions,
  resetCashDrawerTransactions,
} from "../../../redux/actions/cash-drawers/cashDrawers";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  label: {
    color: "#666666",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginBottom: 10,
  },
  fieldContainer: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "300px",
    width: "100%",
  },
  blankText: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center",
    width: 350,
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      fontWeight: 500,
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const CashAudit = (props) => {
  const { t } = props;
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    cashDrawers,
    loadingCashDrawers,
    loadingSessions,
  } = props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  // const [submittedDates, setSubmittedDates] = React.useState({startDate: moment(), endDate: moment()});
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [newCashDrawer, setNewCashDrawer] = React.useState(
    Object.keys(cashDrawers)[0]
  );
  const [cashDrawerOptions, setCashDrawerOptions] = React.useState([]);
  const [goFullScreen, setGoFullscreen] = React.useState(false);

  useEffect(() => {
    props.dispatch(
      fetchCashDrawers(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        true
      )
    );
    ReactGA.pageview("/reports/cashAudit");
    document.title = `${t("reports.cashAudit")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetCashDrawerSessions());
      props.dispatch(resetCashDrawerTransactions());
      document.title = "Counter Workspace";
    };
  }, []);

  useEffect(() => {
    let array = [];
    if (!cashDrawers.default) {
      Object.keys(cashDrawers).map((item) => {
        array.push({
          label: cashDrawers[item].name,
          value: cashDrawers[item]._id,
        });
      });
      // console.log('here', cashDrawers, array);
      setCashDrawerOptions(array);
      array.length > 0 && setNewCashDrawer(array[0].value);
    }
  }, [cashDrawers]);

  const handleSubmit = () => {
    props.dispatch(resetCashDrawerTransactions);
    props.dispatch(
      fetchCashDrawerSessions(
        currentSpace.propertyID,
        newCashDrawer,
        moment(dates.startDate).format("YYYY-MM-DD"),
        moment(dates.endDate).format("YYYY-MM-DD")
      )
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>
            {t("reports.cashAudit")} {t("reports.report")}
          </Typography>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.CASH_AUDIT.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {!loadingCashDrawers && cashDrawerOptions.length === 0 && (
              <div className={classes.blankSlate}>
                <div className={classes.blankText}>
                  {t("reports.cashAuditPage.defaultMessage")}
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* CASH DRAWER FILTERS */}
            {/* --------------------------------------------------------- */}
            {cashDrawerOptions.length !== 0 && !loadingCashDrawers && (
              <div className={clsx(classes.listSection, classes.rightAlign)}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <div className={classes.fieldContainer}>
                      <Select
                        defaultValue={cashDrawerOptions[0]}
                        styles={selectStyle}
                        options={cashDrawerOptions}
                        onChange={(e) => setNewCashDrawer(e.value)}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} md={4} className={classes.dateHolder}>
                    <DateRangePicker
                      startDate={dates.startDate}
                      startDateId="start_date"
                      endDate={dates.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) =>
                        setDates({ startDate, endDate })
                      }
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      }
                      focusedInput={focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      noBorder={true}
                    />
                  </Grid>

                  <Grid item xs={2} sm={1}>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* CASH DRAWER SESSIONS */}
            {/* --------------------------------------------------------- */}
            <div className={classes.listSection}>
              <div className={classes.noShowGraph}>
                {(loadingCashDrawers || loadingSessions) && (
                  <div className={classes.blankSlate}>
                    <CircularProgress />
                  </div>
                )}
                {!loadingSessions &&
                  !loadingCashDrawers &&
                  cashDrawerOptions.length !== 0 && <CashDrawerSessions />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  cashDrawers: state.cashDrawers,
  loadingCashDrawers: state.loading.LOAD_CASH_DRAWERS,
  loadingSessions: state.loading.FETCH_CASH_DRAWER_SESSIONS,
});

export default withTranslation()(connect(mapStateToProps)(CashAudit));
