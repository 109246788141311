const rooms = {};

export default (state = rooms, action) => {
  switch (action.type) {
    case "LOAD_ROOMTYPES_SUCCESS":
      if (action.payload.rooms) {
        return {
          ...state,
          ...action.payload.rooms
        };
      } else return { ...state };

    case "CREATE_ROOMTYPE_SUCCESS":
      return {
        ...state,
        ...action.payload.rooms
      };

    // case "DELETE_ROOM_TYPE_SUCCESS":
    //   const deleteRoomTypeRooms = { ...state };

    //   let deleteRoomTypeRoomKey = action.payload.roomType.rooms || [];
    //   deleteRoomTypeRoomKey && deleteRoomTypeRoomKey.forEach((id) => {
    //     if (id && deleteRoomTypeRooms[id]) {
    //       delete deleteRoomTypeRooms[id]
    //     }
    //   })
    //   return { ...deleteRoomTypeRooms }

    case "LOAD_RESERVATIONS_SUCCESS":
      let newRooms = {};
      // Add new reservations to the rooms state
      action.payload && action.payload.reservations &&
        Object.keys(action.payload.reservations).forEach((id) => {
          let roomID = action.payload.reservations[id].roomID;
          if (newRooms[roomID]) {
            newRooms[roomID] = {
              ...newRooms[roomID],
              reservations: [...newRooms[roomID].reservations, id]
            }
          } else {
            newRooms[roomID] = {
              ...state[roomID],
              reservations: [id]
            }
          }
        })
      return {
        ...state,
        ...newRooms
      };
    case "CREATE_ROOM_SUCCESS":
      return {
        ...state,
        [action.payload.data._id]: { ...action.payload.data }
      }

    case "DELETE_ROOM_SUCCESS":
      const deleteRoom = { ...state };
      if (action.payload.room && action.payload.room._id && deleteRoom[action.payload.room._id]) {
        delete deleteRoom[action.payload.room._id]
      }
      return { ...deleteRoom }

    case "UPLOAD_ROOM_PHOTO_SUCCESS":
      const uploadRoomPhoto = state[action.roomID]
      return {
        ...state,
        [action.roomID]: {
          ...uploadRoomPhoto,
          images: [...action.payload.images]
        }
      }
    case "EDIT_ROOM_SUCCESS":
      const editRoom = state[action.payload.updatedRoom._id];
      const bedPayload = action.payload.beds || [];
      let bedIDs = bedPayload.map(data => data._id);
      let currentBeds = editRoom.beds || [];
      return {
        ...state,
        [action.payload.updatedRoom._id]: {
          ...editRoom,
          ...action.payload.updatedRoom,
          primaryRoomTypeID: action.payload.updatedRoom.primaryRoomTypeID,
          beds: [...new Set(currentBeds.concat(bedIDs))]
        }
      }

    case "DELETE_ROOMS_MAPPING_SUCCESS":
      const editRoomMapping = state[action.payload.room._id];
      const deletedBedPayload = action.payload.distinctBedIDs || [];
      let roomBeds = editRoomMapping.beds || [];
      roomBeds = roomBeds.filter((id) => !deletedBedPayload.includes(id));
      return {
        ...state,
        [action.payload.room._id]: {
          ...editRoomMapping,
          ...action.payload.room,
          beds: roomBeds
        }
      }

    case "LOAD_ROOMS_SUCCESS":
      return {
        ...state,
        ...action.payload
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};