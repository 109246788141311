import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import pluralize from "pluralize";
import clsx from "clsx";
import moment from "moment";

import { initials } from "../utils/wordUtils";

import AddAnnouncement from "../announcements/AddAnnouncement";
import ViewAnnouncement from "../announcements/ViewAnnouncement";
import CommentDisplay from "../utils/CommentDisplay";
import AddButton from "../common/AddButton";

import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
      marginTop: 20,
    },
  },
  newSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  add: {
    borderRadius: "50%",
    color: "#333333",
    cursor: "pointer",
    padding: 10,
    "&:hover": {
      background: "#dddddd",
    },
  },
  blankState: {
    alignItems: "center",
    display: "flex",
    paddingTop: 100,
    justifyContent: "center",
    width: "100%",
    "& img": {
      height: 300,
      width: 300,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    paddingRight: 17,
    boxSizing: "content-box",
  },
  chatContainer: {
    marginBottom: 20,
  },
  card: {
    boxShadow: "0 3px 4px 0 #ECECEC",
    borderRadius: 5,
    marginBottom: 30,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginBottom: 10,
  },
  description: {
    fontSize: "1.2rem",
  },
  defaultDesc: {
    alignItems: "center",
    color: "#666666",
    fontSize: "1.3rem",
  },
  defaultTitle: {
    color: "#000000",
    fontSize: "2.2rem",
    marginBottom: 20,
    textAlign: "center",
  },
  defaultText: {
    marginBottom: 10,
    textAlign: "center",
  },
  defaultmage: {
    marginTop: 20,
    marginBottom: 20,
    minHeight: "350px",
    height: "auto",
    width: "100%",
    objectFit: "cover",
  },
  details: {
    alignItems: "flex-end",
    color: "#828282",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1.2rem",
    flexGrow: 1,
    width: "100%",
    marginTop: 20,
  },
  authorHolder: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  author: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    marginRight: 10,
    backgroundColor: theme.palette.grey[300],
    border: "0px solid #dddddd",
    color: "#ffffff",
    fontSize: "1.2rem",
    lineHeight: 2,
    textAlign: "center",
  },
  name: {
    color: "#666666",
    fontSize: "1.1rem",
    marginBottom: 2,
  },
  date: {
    color: "#828282",
    fontSize: "1rem",
  },
  comments: {
    display: "flex",
    flexDirection: "row",
  },
  commentCount: {
    marginRight: 5,
  },
  defaultTitle: {
    textAlign: "center",
    width: "80%",
    marginBottom: 30,
    marginLeft: "10%",
  },
  defaultSubTitle: {
    fontSize: "1.5rem",
    color: "#AAAAAA",
    lineHeight: 1.5,
    marginBottom: 30,
    marginLeft: "10%",
    textAlign: "center",
    width: "80%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    background: "#FFFFFF",
    border: "1px solid #C2AE7F",
    borderRadius: 5,
    color: "#C2AE7F",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "center",
    maxWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    padding: "12px 16px",
  },
}));

const NewsPage = (props) => {
  const classes = useStyles();
  const { currentSpace, announcements, loadingAnnouncements, t } = props;
  const [addAnnouncement, setAddAnnouncement] = React.useState(false);
  const [announcement, setAnnouncement] = React.useState("");

  const handleAddAnnouncement = (item) => {
    setAddAnnouncement(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleViewAnnouncement = (item) => {
    setAnnouncement(item);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleCloseViewAnnouncement = () => {
    setAnnouncement("");
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleCloseAnnouncement = () => {
    setAddAnnouncement(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {!loadingAnnouncements &&
          currentSpace &&
          currentSpace.announcements &&
          currentSpace.announcements.length !== 0 && (
            <div className={classes.newSection}>
              <AddButton
                handleClick={handleAddAnnouncement}
                title={t("noticeboard.postMessage")}
              />
            </div>
          )}

        {!loadingAnnouncements &&
          currentSpace &&
          (!currentSpace.announcements ||
            (currentSpace.announcements &&
              currentSpace.announcements.length === 0)) && (
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid
                  container
                  spacing={5}
                  justifyContent="center"
                  className={classes.defaultDesc}
                >
                  <Grid item xs={12} md={6} lg={5}>
                    <img
                      className={classes.defaultmage}
                      src={IMAGES.ILLUSTRATIONS.noticeboard}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={5} className={classes.textBox}>
                    <Typography className={classes.defaultTitle} variant="h3">
                      {t("noticeboard.defaultTitle")}
                    </Typography>
                    <div className={classes.defaultSubTitle}>
                      {t("noticeboard.defaultSubtitle")}
                    </div>
                    <div className={classes.buttonContainer}>
                      <div
                        className={classes.button}
                        onClick={handleAddAnnouncement}
                      >
                        {t("noticeboard.addAnnouncement")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

        {!loadingAnnouncements &&
          currentSpace &&
          currentSpace.announcements &&
          currentSpace.announcements.length !== 0 &&
          currentSpace.announcements
            .sort(
              (a, b) =>
                moment(announcements[b].createdAt).unix() -
                moment(announcements[a].createdAt).unix()
            )
            .map(
              (item) =>
                announcements[item] && (
                  <Card className={classes.card} key={item}>
                    <CardActionArea
                      onClick={() => handleViewAnnouncement(item)}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.title}>
                          {announcements[item].title}
                        </div>
                        <div className={classes.description}>
                          <CommentDisplay
                            editorContent={announcements[item].body}
                          />
                        </div>

                        <div className={classes.details}>
                          <div className={classes.authorHolder}>
                            <Avatar
                              alt={initials(announcements[item].postedBy.name)}
                              src={announcements[item].postedBy.avatar}
                              className={classes.avatar}
                            >
                              {initials(announcements[item].postedBy.name)}
                            </Avatar>
                            <div className={classes.author}>
                              <div className={classes.name}>
                                {announcements[item].postedBy.name}
                              </div>
                              <div className={classes.date}>
                                {moment(announcements[item].createdAt).isSame(
                                  moment(),
                                  "day"
                                )
                                  ? `${t("live.sidebar.today")}, ${moment(
                                      announcements[item].createdAt
                                    ).format("h:mma")}`
                                  : moment(
                                      announcements[item].createdAt
                                    ).format("DD MMM, YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className={classes.comments}>
                            <div className={classes.commentCount}>
                              {announcements[item].comments.length}
                            </div>
                            <div>
                              {pluralize(
                                t("noticeboard.commentName"),
                                announcements[item].comments.length
                              )}
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )
            )}

        {announcement !== "" && (
          <ViewAnnouncement
            open={announcement !== ""}
            announcementID={announcement}
            handleClose={handleCloseViewAnnouncement}
          />
        )}

        {addAnnouncement && (
          <AddAnnouncement
            open={addAnnouncement}
            handleClose={handleCloseAnnouncement}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  announcements: state.announcements,
  loadingAnnouncements: state.loading.LOAD_ANNOUNCEMENTS,
});

export default withTranslation()(connect(mapStateToProps)(NewsPage));
