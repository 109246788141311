import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import { loadEmail, resetEmail, deleteEmailTemplate } from '../../redux/actions/emails/emails';
import { returnCurrent } from '../../redux/selectors/dashboard';
import EmailTemplateItem from './EmailTemplateItem';
import EmailTemplateListItem from './EmailTemplateListItem';

import {
  List,
  IconButton,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';


const useStyles = makeStyles (theme => ({
  root: {
    minHeight: 'calc(100vh - 250px)',
    width: '100%'
  },
  row: {
    paddingTop: 5,
    paddingBottom: 5
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    color: '#ffffff',
    fontSize: "1.8rem",
    height: 40,
    lineHeight: 2.2,
    textAlign: 'center',
    width: 40,
    '&img': {
      display: "flex",
      alignItems: "center",
    }
  },
  role: {
    borderRadius: '4px',
    border: '1px solid #dddddd',
    cursor: 'pointer',
    padding: theme.spacing(1)
  },
  
}))

const EmailTemplateList = (props) => {
  const classes = useStyles();

  const { emailTemplates, loadingEmailTemplates, currentSpace, t } = props;
  const [openEmail, setOpenEmail] = React.useState('');

  const handleOpenEmail = (email) => {
    props.dispatch(loadEmail(currentSpace.propertyID, email))
    setOpenEmail(emailTemplates[email].name);
  }

  const handleClose = () => {
    setOpenEmail(false);
    props.handleClose();
    props.dispatch(resetEmail());
  }

  const handleBack = () => {
    props.dispatch(resetEmail());
    setOpenEmail('');
  }

  const handleDeleteEmail = (id) => {
    // if (window.confirm('Are you sure you would like to delete this email template?')) {
      props.dispatch(deleteEmailTemplate(currentSpace.propertyID, id))
    // }
  }

  return(
    <List className={classes.root}>

      {loadingEmailTemplates && 
        <div>
          {t('automate.email.loadingTemplates')}
        </div>
      }

      {/* LIST ALL EMAIL TEMPLATES */}
      {/* --------------------------------------------- */}
      {!loadingEmailTemplates && Object.keys(emailTemplates).map(email => (
        <EmailTemplateListItem key={email} emailTemplate={emailTemplates[email]} handleOpenEmail={handleOpenEmail} handleDeleteEmail={handleDeleteEmail}/>
      ))}

      {openEmail && 
        <EmailTemplateItem
          open={Boolean(openEmail)}
          emailName={openEmail}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      }

    </List>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  emailTemplates: state.emailTemplates,
  loadingEmailTemplates: state.loading.LOAD_EMAIL_TEMPLATES
})

export default withTranslation()(connect(mapStateToProps)(EmailTemplateList));