const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_CHAT_SUCCESS":
      return {
        ...state,
        [action.payload.spaceID]: action.payload.chatHistory
      }
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};  