const defaultProperty = { default: true}

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "FETCH_RULES_SUCCESS":
      return action.payload
    default:
      return state;
  }
};
