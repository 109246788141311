import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import ReactCodeInput from 'react-code-input';

import { createFrontdeskUser } from '../../redux/actions/user/frontdeskUsers';

import { Button, Checkbox, InputBase, makeStyles, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
  },
  roomTypeContainer: {
    background: '#ffffff',
    borderRadius: '15px',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#F5F1ED',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  number: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  currencyField: {
    alignItems: 'center',
    display: 'flex',
    width: '40%'
  },
  currency: {
    marginRight: 10
  },
  subText: {
    fontSize: '1.1rem',
    color: '#999999',
    marginTop: 5,
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#F5F1ED',
    borderRadius: '50%',
    height: 80,
    marginRight: 15,
    overflow: 'hidden',
    width: 80,
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  save: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    '&:hover': {
      background: '#FFFFFF'
    },
  },
  bin: {
    height: 20,
    opacity: 0.7,
    width: 20
  },
  bulbContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    marginLeft: 20,
  },
  saveButton: {
    borderRadius: 10,
    width: '80px'
  },
  warning: {
    color: '#ff9800',
    marginTop: 10
  },
  input: {
    display: 'none'
  }
}))

const codeStyle = {
  inputStyle: {
    backgroundColor: '#F5F1ED',
    borderRadius: '50%',
    border: 'none',
    fontSize: '1.4rem',
    height: '40px',
    margin:  '4px',
    MozAppearance: 'textfield',
    textAlign: 'center',
    width: '40px',
  },
  inputStyleInvalid: {
    color: 'red',
    border: '1px solid red'
  }
}

const NewStaff = (props) => {

  const classes = useStyles();
  const {currentSpaceID, currentSpace, propertyID} = props;
  const [form, setForm] = React.useState({});
  const [preview, setPreview] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const [imgError, setImgError] = React.useState(false);
  const [error, setError] = React.useState(
    {
      firstName: false, 
      lastName: false, 
      loginPin: false, 
      role: false, 
    }
  );


  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleUpdate = (value) => {
    setForm({...form, loginPin: value})
    if (value) {
      setError({...error, loginPin: false})
    }
  }

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
    if (e.target.value !=='') {
      setError({...error, [e.target.name]: false})
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['firstName', 'lastName', 'role'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (!form.loginPin || form.loginPin.length!==4) {
      errorStep.loginPin = true;
      foundError = true;
    }
    if (foundError) {
      setError({...error, ...errorStep})
      return false
    }
    else return true
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // console.log("e.target.files[0]", e.target.files[0])
    if ((e.target.files[0].size / (1024 * 1024)) < 5) {
      setSelectedFile(e.target.files[0])
      setForm({ ...form, image: e.target.files[0] })
      setImgError(false)
    } else {
      setImgError(true)
      setSelectedFile(undefined)
      // console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
    }
  }

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      props.dispatch(createFrontdeskUser(currentSpaceID, {...form, propertyID}));
    }
    props.handleCancel();
  }

  return (
    <div className={classes.root}>
      
      <div className={classes.roomTypeContainer}>
        <div className={classes.row}>
          <div className={classes.label}>First Name</div>
          <div className={classes.field} style={{border: error.firstName ? '1px solid red' : '1px solid #ffffff'}}>
            <InputBase 
              id="firstName"
              name="firstName"
              fullWidth
              type="text"
              onChange={handleChange}
            />
          </div>
          {error.firstName && <div className={classes.error}>Please enter first name</div>}
        </div>

        <div className={classes.row}>
          <div className={classes.label}>Last Name</div>
          <div className={classes.field} style={{border: error.lastName ? '1px solid red' : '1px solid #ffffff'}}>
            <InputBase 
              id="lastName"
              name="lastName"
              fullWidth
              type="text"
              onChange={handleChange}
            />
          </div>
          {error.lastName && <div className={classes.error}>Please enter last name</div>}
        </div>

        <div className={classes.row}>
          <div className={classes.picContainer}>
            <div className={classes.placeholder}>
              {preview && <img className={classes.image} src={preview} />}
            </div>
            <label htmlFor="upload-user-image"><div className={clsx(classes.link, classes.label)}>Upload profile picture</div></label>
            <input
              accept="image/*"
              className={classes.input}
              id="upload-user-image"
              type="file"
              onChange={handleCapture}
            />
          </div>
          {imgError && <span className={classes.warning}>The selected file is too large. Please select a file less than 5MB</span>}
        </div>

        <div className={classes.row}>
          <div className={classes.label}>What is the role of this user?</div>
          <FormControl 
            component="fieldset" 
            style={{
              borderLeft: error.role ? '2px solid red' : '0px solid #ffffff',
              paddingLeft: error.role ? '5px' : 0,
            }}
            >
            <RadioGroup row aria-label="position" name="role" onChange={handleChange}>
              <FormControlLabel value="manager" control={<Radio color="primary" />} label="Manager" />
              <FormControlLabel value="frontDesk" control={<Radio color="primary" />} label="Staff" />
            </RadioGroup>
          </FormControl>
          {error.role && <div className={classes.error}>Please select one</div>}
        </div>

        <div className={classes.row}>
          <div className={classes.label}>Please enter a 4-digit PIN for this staff member</div>
          <div 
            className={classes.number} 
            style={{
              borderLeft: error.loginPin ? '1px solid red' : '0px solid #ffffff',
              paddingLeft: error.loginPin ? '5px' : 0,
            }}
          >
            <ReactCodeInput type='number' fields={4} onChange={handleUpdate} {...codeStyle} />
          </div>
          {error.loginPin && <div className={classes.error}>Please enter a valid PIN code</div>}
          <div className={classes.bulbContainer}>
            <div>
              <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
            </div>
            <div className={classes.helperText}>
              This PIN will allow each staff member to unlock the reception register in 5 secs,
              without having to remember complex login details.
            </div>
          </div>
        </div>

        <div className={classes.save}>
          {!props.modalView && <Button className={classes.cancelButton} onClick={() => props.handleCancel(props.index)}>
            <img src={IMAGES.ICONS.bin} className={classes.bin} />
          </Button>}
          <Button className={classes.saveButton} variant="outlined" onClick={() => handleSubmit()}>Save</Button>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID
})

export default connect(mapStateToProps)(NewStaff);