import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import MappingDialog from './mapping/Dialog';
import Reservations from '../../reports/Reservations';
import { getChannelRouteInfo, setModalRoute } from './helper';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
    },
    settings: {
        width: 150,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        backgroundColor: '#F7F8FA',
        borderRadius: 5,
        marginBottom: 20,
        cursor: 'pointer',
        '& svg': {
            color: '#d6d6d6',
            cursor: 'pointer',
        }
    },
    button: {
        width: 150,
        padding: 10,
        border: '1px solid #d6d6d6',
        cursor: 'pointer',
        borderRadius: 5,
    },
}));

const Manage = (props) => {
    const classes = useStyles();
    const [openMapping, setOpenMapping] = React.useState(false);
    const [openReport, setOpenReport] = React.useState(false);

    const pathname = useLocation().pathname;
    const history = useHistory();
    const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);


    React.useEffect(() =>{
        //CHECK IF MODAL PATH EXISTS AND OPEN MODAL ON LOAD..........................
        if(modalPath && modalPath == 'report' && tabPath== 'booking') manageReportModalHandler(true);
        if(modalPath && modalPath == 'mapping' && tabPath== 'booking') manageMappingModalHandler(true);
      }, []);

    const manageReportModalHandler = (flag) => {
        const routeData = {
            modalPath: 'report',
            tabPath,
            basePath
        };
        setModalRoute(flag, history, routeData);
        setOpenReport(flag);
    }

    const manageMappingModalHandler = (flag) => {
        const routeData = {
            modalPath: 'mapping',
            tabPath,
            basePath
        };
        setModalRoute(flag, history, routeData);
        setOpenMapping(flag);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.settings} onClick={() => manageMappingModalHandler(true)}>
                    <div>Mapping</div> <SettingsIcon />
                </div>

                <div className={classes.button} onClick={() => manageReportModalHandler(true)}>Reservations</div>
            </div>

            {openMapping && <MappingDialog closeHandler={() => manageMappingModalHandler(false)} />}

            {openReport && (
                <Reservations
                    open={true}
                    bookingSource={"BookingCom"}
                    handleClose={() => manageReportModalHandler(false)}
                />
            )}
        </React.Fragment>
    )

}

export default Manage;