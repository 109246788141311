const defaultMapping = {};

export default (state = defaultMapping, action) => {
  switch (action.type) {
    case "LOAD_MAILSY_MAPPING_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "ADD_MAILSY_MAPPING_SUCCESS":
      const roomTypes = state.transformedRoomTypes;
      return {
        ...state,
        transformedRoomTypes: {
          ...roomTypes,
          [action.payload._id]: {...action.payload}
        }
      };
    case "USER_LOGOUT": 
      return {
        ...defaultMapping
      }
    default:
      return state;
  }
};