import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";

import {
  fetchQBAccounts,
  patchQBPurchase,
  fetchSubCategories,
} from "../../redux/actions/quickBooks/quickBooks";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "react-select";

import Divider from "@material-ui/core/Divider";

import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  titleRow: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    lineHeight: 1.3,
    marginBottom: 10,
    marginTop: 10,
  },
  subCategory: {
    marginLeft: 20,
    marginRight: 20,
  },
  fieldContainer: {
    border: "1px solid #dddddd",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  listSection: {
    width: "100%",
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  smallButton: {
    background: "#ffffff",
    borderRadius: 25,
    color: "#000000",
    cursor: "pointer",
    fontSize: "1.3rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 10,
    paddingBottom: 10,
    border: "1px solid #dddddd",
    boxShadow: "0 1px 5px #cdcdcd",
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "1px",
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const Purchase = (props) => {
  const { t } = props;
  const isMediumScreen = useMediaQuery({
    query: "(min-device-width: 768px)",
  });
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const {
    open,
    handleClose,
    currentSpace,
    property,
    loadingProperty,
    quickBooks,
    handleViewToggle,
  } = props;
  const [pay, setPay] = React.useState([
    { accountID: "", accountName: "", cashDrawerID: "" },
  ]);
  const [category, setCategory] = React.useState([
    { accountID: "", accountName: "", categoryID: "", subCategory: [] },
  ]);
  const [accDefault, setAccount] = React.useState({ value: "", label: "" });
  const [viewDefault, setDefault] = React.useState(false);
  const [payAction, setPayAction] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [viewCat, setViewCat] = React.useState(false);

  const [initialCategory, setInitial] = React.useState(true);
  const [intialAccount, setInitAccount] = React.useState(true);

  let cdSelected = [];
  let payAccSelected = [];

  let catSelected = [];
  let catAccSelected = [];

  useEffect(() => {
    if (quickBooks.purchase.selectedCashDrawer) {
      setPay([
        ...quickBooks.purchase.selectedCashDrawer,
        { accountID: "", accountName: "", cashDrawerID: "" },
      ]);
      setViewCat(true);
    }
  }, [props.quickBooks.purchase.selectedCashDrawer]);

  // ********************************************************** //
  // ********** To populate pre-existing categories *********** //
  // ********************************************************** //
  if (quickBooks.purchase.selectedCategory) {
    if (initialCategory) {
      let intialCategory;
      intialCategory = [...quickBooks.purchase.selectedCategory];
      intialCategory.forEach((cat) => {
        cat.subCategory.push({
          accountID: "",
          accountName: "",
          subCategoryID: "",
        });
      });
      setCategory([...intialCategory]);
      setSave(true);
      setDefault(true);
      setInitial(false);
    }

    quickBooks.purchase.selectedCategory.forEach((selectedCat) => {
      quickBooks.purchase.category.forEach((cat) => {
        if (selectedCat.categoryID === cat.value) {
          catSelected.push(cat);
        }
      });
      quickBooks.purchase.account.forEach((acc) => {
        if (selectedCat.accountID === acc.value) {
          catAccSelected.push(acc);
        }
      });
    });
  }

  // ********************************************************** //
  // ******* To populate pre-existing default account ********* //
  // ********************************************************** //
  if (quickBooks.purchase.defaultAccount) {
    if (intialAccount) {
      setInitAccount(false);
      setAccount({
        ...accDefault,
        value: quickBooks.purchase.defaultAccount.value,
        label: quickBooks.purchase.defaultAccount.label,
      });
    }
  }

  if (quickBooks.purchase.selectedCashDrawer) {
    quickBooks.purchase.selectedCashDrawer.forEach((selectedCd) => {
      quickBooks.purchase.cashDrawer.forEach((cd) => {
        if (selectedCd.cashDrawerID === cd.value) {
          cdSelected.push(cd);
        }
      });
      quickBooks.purchase.account.forEach((acc) => {
        if (selectedCd.accountID === acc.value) {
          payAccSelected.push(acc);
        }
      });
    });
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleRefreshAccount = () => {
    props.dispatch(fetchQBAccounts(currentSpace.propertyID));
    setMessage("Refreshing all the drop boxes ...");
    setOpenSnackbar(true);
  };

  // ********************************************************** //
  // *********** Cash Drawer selection and saving ************* //
  // ********************************************************** //
  const setCashDrawer = (selection, index, type) => {
    let newVal = [...pay];
    if (type === "cashDrawer") {
      newVal[index].cashDrawerID = selection.value;
      if (newVal[index].accountID.length > 0) {
        if (index === pay.length - 1) {
          setPay([
            ...newVal,
            { accountID: "", accountName: "", cashDrawerID: "" },
          ]);
        } else {
          setPay(newVal);
        }
        setPayAction(true);
      } else {
        setPay(newVal);
      }
    } else {
      newVal[index].accountID = selection.value;
      newVal[index].accountName = selection.label;
      if (newVal[index].cashDrawerID.length > 0) {
        if (index === pay.length - 1) {
          setPay([
            ...newVal,
            { accountID: "", accountName: "", cashDrawerID: "" },
          ]);
        } else {
          setPay(newVal);
        }
        setPayAction(true);
      } else {
        setPay(newVal);
      }
    }
  };

  const handleSaveDrawer = () => {
    let saveData = [];
    let checkData = [];

    for (var x = 0; x < pay.length; x++) {
      if (pay[x].accountID.length > 0 && pay[x].cashDrawerID.length > 0) {
        saveData.push(pay[x]);
      }
    }

    if (saveData.length > 1) {
      checkData = saveData.filter(
        (val, index, self) =>
          index === self.findIndex((v) => v.cashDrawerID === val.cashDrawerID)
      );

      if (checkData.length === saveData.length) {
        const data = {
          cashDrawer: saveData,
          propertyID: currentSpace.propertyID,
        };

        props.dispatch(patchQBPurchase(data)).then((res) => {
          if (res && res.status === 200) {
            setViewCat(true);
            setMessage("Cash Drawer settings saved!");
            setOpenSnackbar(true);
          } else {
            setMessage("Error in saving the information. Try again!");
            setOpenSnackbar(true);
          }
        });
      } else {
        setMessage("You have duplicate cash-drawers! Try again!");
        setOpenSnackbar(true);
      }
    } else {
      const data = {
        cashDrawer: saveData,
        propertyID: currentSpace.propertyID,
      };

      props.dispatch(patchQBPurchase(data));
      setViewCat(true);
      setMessage("Cash Drawer saved!");
      setOpenSnackbar(true);
    }
  };

  // ********************************************************** //
  // ***** Category/SubCat selection, addition and saving ***** //
  // ********************************************************** //
  const selectCategory = (selection, index, type) => {
    let newVal = [...category];
    if (type === "category") {
      newVal[index].categoryID = selection.value;
      setCategory(newVal);
      let allCat = [];
      newVal.forEach((val) => {
        allCat.push({ categoryID: val.categoryID });
      });
      props.dispatch(
        fetchSubCategories({
          category: allCat,
          propertyID: currentSpace.propertyID,
        })
      );
    } else {
      newVal[index].accountID = selection.value;
      newVal[index].accountName = selection.label;
      setCategory(newVal);
    }
  };

  const selectSubCategory = (selection, parentIndex, type, subIndex) => {
    let newVal = [...category];
    if (type === "subCategory") {
      newVal[parentIndex].subCategory[subIndex].subCategoryID = selection.value;
      if (newVal[parentIndex].subCategory[subIndex].accountID.length > 0) {
        if (subIndex === newVal[parentIndex].subCategory.length - 1) {
          newVal[parentIndex].subCategory.push({
            accountID: "",
            accountName: "",
            subCategoryID: "",
          });
          setCategory(newVal);
        } else {
          setCategory(newVal);
        }
        setSave(true);
      } else {
        setCategory(newVal);
      }
    } else {
      newVal[parentIndex].subCategory[subIndex].accountID = selection.value;
      newVal[parentIndex].subCategory[subIndex].accountName = selection.label;
      if (newVal[parentIndex].subCategory[subIndex].subCategoryID.length > 0) {
        if (subIndex === newVal[parentIndex].subCategory.length - 1) {
          newVal[parentIndex].subCategory.push({
            accountID: "",
            accountName: "",
            subCategoryID: "",
          });
          setCategory(newVal);
        } else {
          setCategory(newVal);
        }
        setSave(true);
      } else {
        setCategory(newVal);
      }
    }
  };

  const handleAddCategory = () => {
    const newVal = [
      ...category,
      { accountID: "", accountName: "", categoryID: "", subCategory: [] },
    ];
    setCategory(newVal);
  };

  const handleSaveCategory = () => {
    let saveData = [];
    let dupeSubCategories = false;
    for (var x = 0; x < category.length; x++) {
      if (
        category[x].accountID.length > 0 &&
        category[x].categoryID.length > 0 &&
        category[x].subCategory.length > 0
      ) {
        let subCategory = [];
        subCategory = category[x].subCategory.filter((sub) => {
          return sub.accountID.length > 0 && sub.subCategoryID.length > 0;
        });

        let subData = [];
        subData = subCategory.filter(
          (val, index, self) =>
            index ===
            self.findIndex((v) => v.subCategoryID === val.subCategoryID)
        );

        if (subData.length === subCategory.length) {
          dupeSubCategories = false;
          saveData.push({
            categoryID: category[x].categoryID,
            accountID: category[x].accountID,
            accountName: category[x].accountName,
            subCategory: subCategory,
          });
        } else {
          dupeSubCategories = true;
          setMessage("Uh Oh! You seem have duplicate Sub-Categories mapped!");
          setOpenSnackbar(true);
        }
      }
    }

    if (!dupeSubCategories) {
      let checkData = [];
      checkData = saveData.filter(
        (val, index, self) =>
          index === self.findIndex((v) => v.categoryID === val.categoryID)
      );

      if (checkData.length === saveData.length) {
        const data = {
          category: saveData,
          propertyID: currentSpace.propertyID,
        };

        props.dispatch(patchQBPurchase(data));
        setDefault(true);
        setMessage("Categories and Sub-Categories saved!");
        setOpenSnackbar(true);
      } else {
        setMessage("Uh Oh! You seem to have duplicate Categories mapped!");
        setOpenSnackbar(true);
      }
    }
  };

  const selectDefault = (val) => {
    const data = {
      defaultAccount: {
        accountID: val.value,
        accountName: val.label,
      },
      propertyID: currentSpace.propertyID,
    };

    props.dispatch(patchQBPurchase(data));
    setMessage("Default Account saved!");
    setOpenSnackbar(true);
  };

  return (
    <div className={classes.listSection}>
      <div className={classes.linkContainer}>
        <div className={classes.addContainer}>
          {t("automate.quickBooks.accounts.subHeading")}
        </div>
        <div className={classes.saveContainer}>
          <RefreshIcon
            className={classes.actionIcon}
            onClick={handleRefreshAccount}
          />
        </div>
      </div>
      <div className={classes.label}>
        {t("automate.quickBooks.accounts.definition")}
      </div>
      {isMediumScreen && (
        <div className={classes.row}>
          <br />
          <Grid container alignItems="center">
            <Grid item sm={5}>
              <div className={clsx(classes.title, classes.titleRow)}>
                {t("automate.quickBooks.accounts.cashDrawer")}
              </div>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={5}>
              <div className={clsx(classes.title, classes.titleRow)}>
                {t("automate.quickBooks.accounts.paymentAccount")}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {pay.map((x, i) => {
        return (
          <div className={classes.row} key={i}>
            <Grid container alignItems="center">
              <Grid item sm={5} xs={12}>
                <div className={classes.fieldContainer}>
                  <Select
                    styles={selectStyle}
                    value={cdSelected[i]}
                    options={quickBooks.purchase.cashDrawer}
                    onChange={(val) => setCashDrawer(val, i, "cashDrawer")}
                    theme={(theme) => ({
                      ...theme,
                      border: "1px",
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item sm={2}></Grid>
              <Grid item xs={12} sm={5}>
                <div className={classes.fieldContainer}>
                  <Select
                    styles={selectStyle}
                    value={payAccSelected[i]}
                    options={quickBooks.purchase.account}
                    onChange={(val) => setCashDrawer(val, i, "paymentAcc")}
                    theme={(theme) => ({
                      ...theme,
                      border: "1px",
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        );
      })}
      {(viewCat || payAction) && (
        <div className={classes.buttonsContainer}>
          <div className={classes.smallButton} onClick={handleSaveDrawer}>
            {t("automate.quickBooks.save")}
          </div>
        </div>
      )}
      {viewCat && (
        <div>
          <Divider className={classes.firstRow} />
          {isMediumScreen && (
            <div className={classes.row}>
              <br />
              <Grid container alignItems="center">
                <Grid item sm={5}>
                  <div className={clsx(classes.title, classes.titleRow)}>
                    {t("automate.quickBooks.accounts.category")}
                  </div>
                </Grid>
                <Grid item sm={2}></Grid>
                <Grid item sm={5}>
                  <div className={clsx(classes.title, classes.titleRow)}>
                    {t("automate.quickBooks.accounts.account")}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          {category.map((currentCategory, i) => {
            if (
              currentCategory.accountID.length > 0 &&
              currentCategory.categoryID.length > 0
            ) {
              if (currentCategory.subCategory.length === 0) {
                currentCategory.subCategory.push({
                  accountID: "",
                  accountName: "",
                  subCategoryID: "",
                });
              }
            }
            return (
              <div key={i}>
                <div className={classes.row}>
                  <Grid container alignItems="center">
                    <Grid item sm={5} xs={12}>
                      <div className={classes.fieldContainer}>
                        <Select
                          styles={selectStyle}
                          value={catSelected[i]}
                          options={quickBooks.purchase.category}
                          onChange={(val) => selectCategory(val, i, "category")}
                          theme={(theme) => ({
                            ...theme,
                            border: "1px",
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </Grid>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={12} sm={5}>
                      <div className={classes.fieldContainer}>
                        <Select
                          styles={selectStyle}
                          value={catAccSelected[i]}
                          options={quickBooks.purchase.account}
                          onChange={(val) => selectCategory(val, i, "account")}
                          theme={(theme) => ({
                            ...theme,
                            border: "1px",
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {quickBooks.purchase.subCategory &&
                  currentCategory.subCategory.length > 0 && (
                    <div>
                      {isMediumScreen && (
                        <div className={classes.row}>
                          <br />
                          <div className={classes.subCategory}>
                            <Grid container alignItems="center">
                              <Grid item sm={5}>
                                <div
                                  className={clsx(
                                    classes.title,
                                    classes.titleRow
                                  )}
                                >
                                  {t(
                                    "automate.quickBooks.accounts.subCategory"
                                  )}
                                </div>
                              </Grid>
                              <Grid item sm={2}></Grid>
                              <Grid item sm={5}>
                                <div
                                  className={clsx(
                                    classes.title,
                                    classes.titleRow
                                  )}
                                >
                                  {t("automate.quickBooks.accounts.account")}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      )}
                      <br />
                      {currentCategory.subCategory.map((x, subIndex) => {
                        let subSelected = [];
                        let subAccSelected = [];

                        if (quickBooks.purchase.subCategory[i]) {
                          for (
                            var k = 0;
                            k < currentCategory.subCategory.length;
                            k++
                          ) {
                            for (
                              var j = 0;
                              j < quickBooks.purchase.subCategory[i].length;
                              j++
                            ) {
                              if (
                                currentCategory.subCategory[k].subCategoryID ===
                                quickBooks.purchase.subCategory[i][j].value
                              ) {
                                subSelected.push(
                                  quickBooks.purchase.subCategory[i][j]
                                );
                              }
                            }

                            for (
                              var l = 0;
                              l < quickBooks.purchase.account.length;
                              l++
                            ) {
                              if (
                                currentCategory.subCategory[k].accountID ===
                                quickBooks.purchase.account[l].value
                              ) {
                                subAccSelected.push(
                                  quickBooks.purchase.account[l]
                                );
                              }
                            }
                          }
                        }

                        return (
                          <div className={classes.subCategory} key={subIndex}>
                            <div className={classes.row}>
                              <Grid container alignItems="center">
                                <Grid item sm={5} xs={12}>
                                  <div className={classes.fieldContainer}>
                                    <Select
                                      styles={selectStyle}
                                      value={subSelected[subIndex]}
                                      options={
                                        quickBooks.purchase.subCategory[i]
                                      }
                                      onChange={(val) =>
                                        selectSubCategory(
                                          val,
                                          i,
                                          "subCategory",
                                          subIndex
                                        )
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        border: "1px",
                                        colors: {
                                          ...theme.colors,
                                          primary: "#666666",
                                          primary25: "#dddddd",
                                        },
                                      })}
                                    />
                                  </div>
                                </Grid>
                                <Grid item sm={2}></Grid>
                                <Grid item xs={12} sm={5}>
                                  <div className={classes.fieldContainer}>
                                    <Select
                                      styles={selectStyle}
                                      value={subAccSelected[subIndex]}
                                      options={quickBooks.purchase.account}
                                      onChange={(val) =>
                                        selectSubCategory(
                                          val,
                                          i,
                                          "account",
                                          subIndex
                                        )
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        border: "1px",
                                        colors: {
                                          ...theme.colors,
                                          primary: "#666666",
                                          primary25: "#dddddd",
                                        },
                                      })}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {currentCategory.subCategory.length > 0 && (
                  <Divider className={classes.firstRow} />
                )}
              </div>
            );
          })}
        </div>
      )}
      {save && (
        <div className={classes.linkContainer}>
          <div className={classes.addContainer}>
            <div className={classes.smallButton} onClick={handleAddCategory}>
              {t("automate.quickBooks.accounts.addCategory")}
            </div>
          </div>
          <div className={classes.saveContainer}>
            <div className={classes.smallButton} onClick={handleSaveCategory}>
              {t("automate.quickBooks.accounts.saveCategory")}
            </div>
          </div>
        </div>
      )}
      {viewDefault && (
        <div>
          <Divider className={classes.firstRow} />
          <div className={classes.label}>
            {t("automate.quickBooks.accounts.defaultAccountHeader")}
          </div>
          <div className={classes.row}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={5}>
                <div className={classes.fieldContainer}>
                  <Select
                    styles={selectStyle}
                    options={quickBooks.purchase.account}
                    defaultValue={accDefault}
                    onChange={(val) => selectDefault(val)}
                    theme={(theme) => ({
                      ...theme,
                      border: "1px",
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {message && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key="top-center"
          autoHideDuration={3000}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  quickBooks: state.quickBooks,
});

export default withTranslation()(connect(mapStateToProps)(Purchase));
