import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import RoomTypeList from "./roomTypes/List";
import Mapping from "./mapping/Mapping";
import Microsite from "./microsite";
import TaxList from "./taxes/TaxList";
import PromotionList from "./promotions/List";
import Rates from "./rates";

import Grid from "@material-ui/core/Grid";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import { getChannelRouteInfo, setModalRoute } from "./helper";
import { setCurrentModule } from "../../redux/actions/dashboard/dashboard";
import { MENU } from "../../utils/layout";
import IMAGES from "../../constants/images";
import Invoices from "./invoices";
import { isEUCountry } from "../../utils/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
  },
  box: {
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#333333",
    display: "flex",
    fontSize: "1.3rem",
    fontWeight: 600,
    height: "calc(100% - 30px)",
    justifyContent: "space-between",
    padding: 15,
  },
  feature: {
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  boxButton: {
    cursor: "pointer",
    "&:hover": {
      background: "#EEEEEE",
    },
  },
  learn: {
    color: "#999999",
    cursor: "pointer",
    fontSize: "1.1rem",
    fontWeight: 500,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    height: 100,
    margin: "20px 0",
    width: "100%",
  },
  image: {
    objectFit: "cover",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  action: {
    alignItems: "center",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    padding: "5px 5px 5px 10px",
    "&:hover": {
      background: "#EEEEEE",
    },
  },
  noClick: {
    cursor: "auto",
    fontWeight: 500,
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  arrow: {
    color: "#BDBDBD",
  },
  small: {
    marginLeft: 5,
    fontSize: "1.5rem",
  },
}));

const modals = {
  mapping: "mapping",
  microsite: "microsite",
  promotions: "promotions",
  rates: "rates",
  roomType: "roomType",
  taxes: "taxes",
  invoices: "invoices",
};

const ManageSection = (props) => {
  const classes = useStyles();
  const { property } = props;
  const [openModal, setOpenModal] = React.useState("");

  const pathname = useLocation().pathname;
  const history = useHistory();
  const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);

  React.useEffect(() => {
    //CHECK IF MODAL PATH EXISTS AND OPEN MODAL ON LOAD..........................
    if (
      tabPath === "hostelworld" &&
      modalPath &&
      Object.keys(modals).includes(modalPath)
    ) {
      openModalHandler(modals[modalPath]);
    }
  }, []);

  const openModalHandler = (selectedPath) => {
    const routeData = {
      modalPath: selectedPath,
      tabPath,
      basePath,
    };
    const status = Boolean(selectedPath);
    setOpenModal(selectedPath);
    setModalRoute(status, history, routeData);
  };

  const navigateToLinkupsHandler = () => {
    history.push(`/${MENU.linkups.id}`);
    props.dispatch(
      setCurrentModule(MENU.linkups.id)
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* -------------------PROMOTIONS------------------- */}
        <AccessController requiredPermission={MODULE.HW_PROMOTIONS.ID}>
          <Grid item xs={12} sm={4}>
            <div className={clsx(classes.box, classes.feature)}>
              <div>Promotions</div>
              <a
                className={classes.learn}
                href="https://hostelworldinbox.zendesk.com/hc/en-us/sections/360003076399-Promotions"
                target="blank"
              >
                Learn more
              </a>
              <div className={classes.imageContainer}>
                <img
                  src={IMAGES.ILLUSTRATIONS.percent}
                  className={classes.image}
                />
              </div>
              <div className={classes.actionContainer}>
                <div
                  className={classes.action}
                  onClick={() => openModalHandler(modals.promotions)}
                >
                  Set up now
                  <RightArrow className={clsx(classes.arrow, classes.small)} />
                </div>
              </div>
            </div>
            {/* ****************PROMOTION Details Modal************* */}
            {openModal === modals.promotions && (
              <PromotionList
                open={true}
                closeHandler={() => openModalHandler(false)}
              />
            )}
          </Grid>
        </AccessController>

        {/* -------------------EXPERIENCES------------------- */}
        <Grid item xs={12} sm={4}>
          <div className={clsx(classes.box, classes.feature)}>
            <div>Linkups</div>
            <div className={classes.learn}>Learn more</div>
            <div className={classes.imageContainer}>
              <img
                src={IMAGES.ILLUSTRATIONS.experiences}
                className={classes.image}
              />
            </div>
            <div className={classes.actionContainer}>
              <div
                className={classes.action}
                onClick={() => navigateToLinkupsHandler()}
              >
                Set up now
                <RightArrow className={clsx(classes.arrow, classes.small)} />
              </div>
            </div>
          </div>
        </Grid>

        {/* -------------------APP ONLY RATES------------------- */}
        <Grid item xs={12} sm={4}></Grid>

        {/* -------------------RATES & AVAILABILITY------------------- */}
        <AccessController requiredPermission={MODULE.HW_RATES.ID}>
          <Grid item xs={6} sm={4}>
            <div
              className={clsx(classes.box, classes.boxButton)}
              onClick={() => openModalHandler(modals.rates)}
            >
              <div>Rates & Availability</div>
              <RightArrow className={classes.arrow} />
            </div>
          </Grid>
          {/* RoomType Modal */}
          {openModal === modals.rates && (
            <Rates open={true} closeHandler={() => openModalHandler(false)} />
          )}
        </AccessController>

        {/* -------------------MICROSITE CONTENT------------------- */}
        <AccessController requiredPermission={MODULE.HW_MICROSITE_CONTENT.ID}>
          <Grid item xs={6} sm={4}>
            <div
              className={clsx(classes.box, classes.boxButton)}
              onClick={() => openModalHandler(modals.microsite)}
            >
              <div>Microsite Content</div>
              <RightArrow className={classes.arrow} />
            </div>
          </Grid>

          {/* ****************Microsite Modal***************** */}
          {openModal === modals.microsite && (
            <Microsite
              open={true}
              handleCancel={() => openModalHandler(false)}
            />
          )}
        </AccessController>

        {/* -------------------TAXES------------------- */}
        <AccessController requiredPermission={MODULE.HW_TAXES.ID}>
          <Grid item xs={6} sm={4}>
            <div
              className={clsx(classes.box, classes.boxButton)}
              onClick={() => openModalHandler(modals.taxes)}
            >
              <div>Tax Details</div>
              <RightArrow className={classes.arrow} />
            </div>
          </Grid>

          {/* ****************Tax Details Modal************* */}
          {openModal === modals.taxes && (
            <TaxList open={true} closeHandler={() => openModalHandler(false)} />
          )}
        </AccessController>

        {/* -------------------ROOM TYPES------------------- */}
        <AccessController requiredPermission={MODULE.HW_ROOMTYPES.ID}>
          <Grid item xs={6} sm={4}>
            <div
              className={clsx(classes.box, classes.boxButton)}
              onClick={() => openModalHandler(modals.roomType)}
            >
              <div>Room Types</div>
              <RightArrow className={classes.arrow} />
            </div>
          </Grid>

          {/* RoomType Modal */}
          {openModal === modals.roomType && (
            <RoomTypeList
              open={true}
              closeHandler={() => openModalHandler(false)}
            />
          )}
        </AccessController>

        {/* -------------------COUNTER MAPPING------------------- */}
        <AccessController requiredPermission={MODULE.HW_MAPPING.ID}>
          <Grid item xs={6} sm={4}>
            <div
              className={clsx(classes.box, classes.boxButton)}
              onClick={() => openModalHandler(modals.mapping)}
            >
              <div>Counter Mapping</div>
              <RightArrow className={classes.arrow} />
            </div>
          </Grid>

          {/* *****************Mapping Modal***************** */}
          {openModal === modals.mapping && (
            <Mapping open={true} closeHandler={() => openModalHandler(false)} />
          )}
        </AccessController>

        {/* -------------------INVOICES------------------- */}
        {isEUCountry(property?.address?.country) && (
          <AccessController requiredPermission={MODULE.HW_INVOICES.ID}>
            <Grid item xs={6} sm={4}>
              <div
                className={clsx(classes.box, classes.boxButton)}
                onClick={() => openModalHandler(modals.invoices)}
              >
                <div>Invoices</div>
                <RightArrow className={classes.arrow} />
              </div>
            </Grid>
            {/* *****************Invoices Modal***************** */}
            {openModal === modals.invoices && (
              <Invoices
                open={true}
                closeHandler={() => openModalHandler(false)}
              />
            )}
          </AccessController>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  property: state.property,
});

export default connect(mapStateToProps)(ManageSection);
