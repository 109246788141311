import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

import ConfirmForm from '../../common/ConfirmForm';

import Button from '@material-ui/core/Button';
import { InputBase } from '@material-ui/core';
import Select from 'react-select';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#F2F2F2',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    overflow: 'hidden',
    width: 80,
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  warning: {
    color: '#ff9800',
    marginTop: 10
  },
  input: {
    display: 'none'
  },
  deleteButton: {
    borderRadius: '50%',
    cursor: 'pointer',
    height: 20,
    padding: 8,
    width: 20,
    '&:hover': {
      background: '#E0E0E0',
    }
  },
  leftAlignSubmit: {
    width: '60%'
  },
  righttAlignSubmit: {
    width: '40%',
    display: 'flex',
    justifyContent: "flex-end",
  },
  cancelButton: {
    margin: '0 10px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  field: {
    alignItems: 'center',
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: '10px',
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'calc(80% - 32px)',
    '& > div': {
      width: '100%'
    }
  },
  fieldError: {
    border: '1px solid red',
  },
  select: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 10,
    marginTop: 10,
    width: '80%',
  },
  multiLine: {
    alignItems: 'flex-start',
    height: 100,
    overflowY: 'hidden'
  },
  cancel: {
    color: '#999999',
    fontSize: '1.2rem',
    marginRight: 15,
    '&:hover': {
      background: '#ffffff'
    }
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 40,
  },
  uploadButton: {
    color: '#999999',
    fontSize: '1.2rem',
    marginTop: 10,
  },
  camera: {
    display: 'inline-block',
    height: 15,
    opacity: 0.5,
    width: 15,
    marginRight: 10
  },
  input: {
    display: "none"
  },
  back: {
    '&:hover': {
      background: '#ffffff'
    }
  }
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const ProductCategoryForm = (props) => {

  const classes = useStyles();
  const { categoryID, data, taxes, currentSpace } = props;
  const [form, setForm] = React.useState({ name: data?.name || '' });
  const [error, setError] = React.useState({ name: false });
  const [preview, setPreview] = React.useState(data.images && data.images.length ? data.images[data.images.length - 1].key : null);
  const [selectedFile, setSelectedFile] = React.useState();
  const [imgError, setImgError] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [taxArray, setTaxArray] = React.useState([]);
  const [defaultTaxes, setDefaultTaxes] = React.useState(null);

  useEffect(() => {
    createTaxArray();
  }, [])

  const createTaxArray = () => {
    const spaceTaxes = currentSpace.taxes && currentSpace.taxes.map(tax => {
      if (taxes[tax] && (taxes[tax].type === 'percentOfTotal'))
        return { value: tax, label: taxes[tax] && `${taxes[tax].name} - ${taxes[tax].value}%`}
    }).filter(tax => tax)
    setTaxArray([...spaceTaxes])

    if (data.taxesApplicable && data.taxesApplicable.length > 0) {
      const newArray = data.taxesApplicable.map(taxID => spaceTaxes.find(e => e.value === taxID))
      console.log('here', newArray);
     setDefaultTaxes(newArray);
    } else setDefaultTaxes([])
  }

  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    if ((e.target.files[0].size / (1024 * 1024)) < 5) {
      setSelectedFile(e.target.files[0])
      setForm({ ...form, image: e.target.files[0] })
      setImgError(false)
    } else {
      setImgError(true)
      // console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
    }
  }

  const handleChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value })
    if (e.target.value !== '') {
      setError({ ...error, [name]: false })
    }
  }

  const handleSelect = selected => {
    if (selected !== null) {
      const taxIDArray = selected.map(item => item.value)
      setForm({ ...form, taxesApplicable: taxIDArray })
    } else {
      setForm({ ...form, taxesApplicable: [] })
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['name'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    console.log("form", form)
    const checkInput = handleValidate();
    if (checkInput) {
      props.handleSubmit(form);
    }
  }

  const handleConfirmFormClose = () => {
    setConfirmOpen(false);
  }

  const handleConfirmDelete = () => {
    props.handleConfirmDelete()
    setConfirmOpen(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.picContainer}>
          <div className={classes.placeholder}>
            {preview ?
              <img className={classes.image} src={preview} /> :
              <img className={classes.image} src={IMAGES.ICONS.posPlaceholder} />
            }
          </div>
          <label htmlFor="outlined-button-file"><div className={clsx(classes.link, classes.label)}>Upload picture</div></label>
          <input
            accept="image/*"
            className={classes.input}
            id="outlined-button-file"
            type="file"
            onChange={handleCapture}
          />
          {imgError && <span className={classes.warning}>The selected file is too large. Please select a file less than 5MB</span>}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Name</div>
        <div className={clsx(classes.field, { [classes.fieldError]: error.name })}>
          <InputBase
            id="name"
            name="Name"
            type="text"
            defaultValue={data.name}
            onChange={(e) => handleChange(e, "name")}
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Code</div>
        <div className={clsx(classes.field, { [classes.fieldError]: error.code })}>
          <InputBase
            id="code"
            name="Code"
            type="text"
            defaultValue={data.code}
            onChange={(e) => handleChange(e, "code")}
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Tax</div>
        <div className={clsx(classes.select, { [classes.fieldError]: error.taxesApplicable })}>
          {defaultTaxes && 
            <Select
              defaultValue={defaultTaxes}
              isMulti
              onChange={handleSelect}
              options={taxArray}
              placeholder={"Apply tax rates..."}
              styles={selectStyle}
              className={classes.dropdownInput}
              theme={theme => ({
                ...theme,
                border: 0,
                colors: {
                  ...theme.colors,
                  primary: '#666666',
                  primary25: '#dddddd'
                },
              })}
            />
          }
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Description</div>
        <div className={clsx(classes.multiLine, classes.field)}>
          <InputBase
            id="description"
            name="description"
            fullWidth
            multiline
            rows="5"
            type="text"
            defaultValue={data.description}
            onChange={(e) => handleChange(e, "description")}
          />
        </div>
      </div>

      {/* --------------------------------------------------------------------- */}
      {/* SUBMIT / CANCEL / DELETE UPDATES */}
      {/* --------------------------------------------------------------------- */}

      <div className={classes.submit}>
        <div className={classes.leftAlignSubmit}>
          <ConfirmForm
            title="Delete Product category?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onClose={handleConfirmFormClose}
            onConfirm={handleConfirmDelete}
          >
            Are you sure you would like to delete this product category?
          </ConfirmForm>

          {!confirmOpen && props.categoryID &&
            <img src={IMAGES.ICONS.bin} className={classes.deleteButton} onClick={() => setConfirmOpen(true)} />
          }

        </div>
        <div className={classes.righttAlignSubmit}>
          <Button className={classes.cancel} onClick={props.handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save</Button>
        </div>
      </div>
    </div >
  )

}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  taxes: state.taxes
})

export default connect(mapStateToProps)(ProductCategoryForm);