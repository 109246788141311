import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import Reservations from "../reports/Reservations";
import Reservation from "../reservations/Reservation";

import { MODULE } from "../../common/constants/permission";
import { getChannelRouteInfo, setModalRoute, fetchDateRange } from "./helper";
import { returnCurrent } from "../../redux/selectors/dashboard";
import { RESERVATION_STATUS } from "../../utils/constants";
import { getLatestBookings } from "../../redux/actions/microsite/hostelworld";
import { reservationProcessing } from "../../redux/selectors/reports/reservations";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0",
    padding: "20px 0",
  },
  title: {
    color: "#484848",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  button: {
    color: "#006699",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  row: {
    borderBottom: "1px solid #E0E0E0",
    color: "#484848",
    fontSize: "1.1rem",
    paddingBottom: 10,
    margin: "10px 0",
  },
  link: {
    background: "#EEEEEE",
    borderRadius: 5,
    cursor: "pointer",
    display: "inline-block",
    padding: "5px 10px",
    "&:hover": {
      background: "#BDBDBD",
    },
  },
  loaderContainer: {
    height: 100,
    position: "relative",
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "30%",
  },
  noDataContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.8rem",
    color: "#484848",
  },
}));

const BookingSection = (props) => {
  const classes = useStyles();
  const { currentSpace, dispatch, roomTypes, rooms } = props;
  const [resReport, setResReport] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openReservation, setOpenReservation] = React.useState(false);

  const [lastestBookings, setLatestBookings] = React.useState([]);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };

  const pathname = useLocation().pathname;
  const history = useHistory();
  const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);

  React.useEffect(() => {
    //CHECK IF MODAL PATH EXISTS AND OPEN MODAL ON LOAD..........................
    if (modalPath && modalPath == "bookings") openResReportModal(true);
    getLatestResevations();
  }, []);

  const openResReportModal = (status) => {
    const routeData = {
      modalPath: "bookings",
      tabPath,
      basePath,
    };
    setResReport(status);
    setModalRoute(status, history, routeData);
  };

  const getLatestResevations = () => {
    const loadStatus = [
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.IN_HOUSE,
      RESERVATION_STATUS.ONLINE_CHECKED_IN,
    ];

    const { reportFrom: startDate, reportTo: endDate } =
      fetchDateRange("past15");

    const params = {
      propertyID: currentSpace.propertyID,
      startDate,
      endDate,
      status: loadStatus,
      bookingSources: ["Hostelworld"],
    };

    setIsLoading(true);
    dispatch(getLatestBookings(params)).then((data) => {
      if (data && data.length > 0) {
        let updatedData = reservationProcessing(
          data,
          currencyData,
          roomTypes,
          rooms
        );
        updatedData = updatedData.reverse().slice(0, 3);
        setLatestBookings(updatedData);
      }
      setIsLoading(false);
    });
  };

  const handleOpenReservation = (resID) => {
    dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    dispatch(resetReservationDetails());
  };

  const formatDuration = (duration, label) =>
    duration > 1 ? `${duration} ${label}s` : `${duration} ${label}`;

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <div className={classes.title}>Latest bookings</div>
        </Grid>
        <Grid item xs={6}>
          <div
            className={classes.button}
            onClick={() => openResReportModal(true)}
          >
            View All Bookings
          </div>
        </Grid>
        <Grid item xs={12}>
          {lastestBookings.map((booking, index) => (
            <div className={classes.row} key={`hwBooking${index}`}>
              <Grid container spacing={1} alignItems="center">
                {/* <Grid item xs={1}>Flag</Grid> */}
                <Grid item xs={3}>
                  {booking.name}
                </Grid>
                <Grid item xs={2}>
                  {formatDuration(booking.duration, "night")}
                </Grid>
                <Grid item xs={3}>
                  {booking?.roomTypesInfo || "N/A"}
                </Grid>
                <Grid item xs={2}>
                  {formatDuration(booking.guestCount, "guest")}
                </Grid>
                <Grid item xs={1}>
                  {booking.amount}
                </Grid>
                <Grid item xs={1}>
                  <div
                    className={classes.link}
                    onClick={() => handleOpenReservation(booking.resID)}
                  >
                    View
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </Grid>
      </Grid>

      {/* ..............Circular Loader...................... */}
      {isLoading && lastestBookings.length == 0 && (
        <div className={classes.loaderContainer}>
          <CircularProgress size={35} className={classes.loader} />
        </div>
      )}

      {/* ..............No Data Display...................... */}
      {!isLoading && lastestBookings.length == 0 && (
        <div className={classes.noDataContainer}>No recent bookings</div>
      )}

      {openReservation && (
        <Reservation
          open={openReservation}
          handleCloseReservation={handleCloseReservation}
        />
      )}

      {resReport && (
        <Reservations
          open={true}
          accessModuleID={MODULE.HW_BOOKINGS.ID}
          bookingSource={"Hostelworld"}
          handleClose={() => openResReportModal(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { spaces, dashboard } = state;
  return {
    currentSpace: returnCurrent(spaces, dashboard.currentSpace),
    roomTypes: state.roomTypes,
    rooms: state.rooms,
  };
};

export default connect(mapStateToProps)(BookingSection);
