import React from 'react';
import  { connect } from 'react-redux';
import { ResponsiveWaffle } from '@nivo/waffle';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { getNumberAbbr, fetchCurrencyFormat } from '../../utils/utility'; 

const CustomCell = ({
  position,
  size,
  x,
  y,
  color,
  fill,
  opacity,
  borderWidth,
  borderColor,
  data,
  onHover,
  onLeave,
  onClick,
}) => (
  <circle
      r={size / 2}
      cx={x + size / 2}
      cy={y + size / 2}
      fill={fill || color}
      strokeWidth={borderWidth}
      stroke={borderColor}
      opacity={opacity}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={event => {
          onClick({ position, color, x, y, data }, event)
      }}
  />
)

const PaymentsWaffle = (props) => {
  
  const { live, currentSpace, currentSpaceID, cash, card, unpaid, total, currency } = props;

  let data2 = [
    {
      id: "Cash",
      label: "Cash",
      value: Math.round((cash / total) * 100),
      color: "#E4ACA9"
    },
    {
      id: "Card",
      label: "Card",
      value: Math.round((card / total) * 100),
      color: "#F5DB8C"
    },
    {
      id: "Unpaid",
      label: "Unpaid",
      value: 1,
      value: Math.round((unpaid / total) * 100),
      color: "#F8F9FB"
    }
  ]

  // Recalculate all valued based on total = 100. Calculate percentages for data array.
  // if (live[currentSpaceID]) {
  //   const today = live[currentSpaceID].todaysPayments
  //   data2[0]["value"] = Math.round(cash / total * 100)
  //   data2[1]["value"] = Math.round(card / total * 100)
  //   data2[2]["value"] = 100 - (data2[0]["value"] + data2[1]["value"])
  //   console.log(data2[0]["value"], data2[1]["value"], data2[2]["value"])
  // }


  // Don't want to display percentages but the original amount with currency.
  const CustomTooltip = node => {
    let labelAmount = 0;

    switch (node.label) {
      case 'Cash':
        labelAmount = cash;
        break;

      case 'Card':
        labelAmount = card;
        break;
    
      default:
        labelAmount = unpaid;
        break;
    }

    const formatedLabelAmount = getNumberAbbr(labelAmount);
    const currencyFormat = fetchCurrencyFormat(currency);

    return(
    <div>
      <span style={{fontWeight: 600}}>{node.label}</span>
      <span>: {currencyFormat}{formatedLabelAmount}</span>
    </div>)
  }

  return (
    <ResponsiveWaffle
        data={data2}
        total={100} //Chart does not recalculate when this dude is dynamic. Assumed this to be 100.
        rows={18}
        columns={14}
        margin={{ top: 30, right: 0, bottom: 0, left: 100 }}
        cellComponent={CustomCell}
        colors={{ datum: 'color' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
        animate={true}
        motionStiffness={90}
        motionDamping={11}
        tooltip={CustomTooltip}
        legends={[
            {
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: -100,
                translateY: -10,
                itemsSpacing: 4,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                itemTextColor: '#777',
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000',
                            itemBackground: '#f7fafb'
                        }
                    }
                ]
            }
        ]}
    />
  )
}

const mapStateToProps = state => ({
  live: state.live,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentSpaceID: state.dashboard.currentSpace
})

export default connect(mapStateToProps)(PaymentsWaffle);
