import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Button, Checkbox, Dialog, FormControl, FormControlLabel, FormHelperText, InputBase, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connectChannelProperty } from '../../../redux/actions/microsite/bookingCom';
import Mapping from './mapping/Mapping';
import { fetchChannelStatus } from '../../../redux/actions/microsite/channels';
import IMAGES from '../../../constants/images';
import { createChannelFullRefreshPost } from '../../../redux/actions/channelMapping/channelMapping';
import { CHANNEL } from '../../../utils/channels';

const useStyles = makeStyles(theme => ({
    dialog: {
        position: 'relative',
        '& .MuiDialog-paperFullScreen': {
            backgroundColor: 'rgba(248,249,251,255)'
        }
    },
    container: {
        height: '100%',
        position: 'relative',
        margin: 'auto',
        marginTop: 100,
        minWidth: 350,
        padding: '0 20px',
        '& input': {
            backgroundColor: '#FFFFFF',
        }
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: '600',
        marginBottom: '20px',
    },
    label: {
        color: 'rgba(195,196,198,255)',
        fontWeight: 600,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        marginTop: 20,
        marginBottom: 20
    },
    button: {
        width: '100%',
        background: 'rgba(18,64,141,255)',
        borderRadius: 5,
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        fontWeight: 600,
        padding: '12px 16px',
        '&:hover': {
            background: 'rgba(18,64,141,255)',
        },
    },
    loading: {
        minWidth: '100%',
        minHeight: '100%',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 16,
        zIndex: 99,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
    },
    field: {
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        display: 'flex',
        height: 35,
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& > div': {
            width: '100%'
        },
        '&.disabled': {
            backgroundColor: '#E0E0E0'
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    infoIcon: {
        cursor: 'pointer',
        height: 12,
        width: 12,
        marginLeft: 10,
        opacity: 0.5
    },
    hide: {
        display: 'none'
    }
}));

const SCREEN_TYPE = {
    CONNECT: 'connect',
    CONNECT_ON_UNSUCCESS: 'ConnectOnUnsuccess',
    FAILURE: 'Failure',
    SUCCESS: 'Success',
    MAPPING: 'Mapping',
}

const ConnectChannel = (props) => {
    const { classes } = props;
    const [error, setError] = React.useState({ hostelID: false, confirmConnection: false });
    const [form, setForm] = React.useState({});

    const submitHandler = () => {
        if (!form.hostelID) {
            setError({...error, hostelID: true});
            return false
        }
        if (!form.confirmConnection) {
            setError({...error, confirmConnection: true});
            return false
        }
        setError({ hostelID: false, confirmConnection: false });
        props.submitHandler(form);
    }

    return (
        <React.Fragment>
            <div className={classes.title}>Let's get your connection up and running</div>
            <div className={classes.row}>
                <div className={classes.label}>Enter your existing Booking.com ID
                <Tooltip placement="bottom-start" className={classes.icon} title={`"Booking.com ID" is the number used to sign-in on Booking.com extranet. It's a six or seven digit number`}>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.hostelID })}>
                    <InputBase
                        id="hostelID"
                        name="hostelID"
                        type="text"
                        placeholder="Enter your booking.com ID"
                        onChange={(e) => {
                            setForm({ ...form, hostelID: e.target.value });
                            setError({...error, hostelID: false});
                        }}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Select channex on booking.com channel setting
                <Tooltip placement="bottom-start" className={classes.icon} title={'Navigate to Booking.com extranet > Account > Connectivity Provider > Search and select Channex.io'}>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <FormControl component="fieldset" error={error.confirmConnection}>
                    <FormControlLabel
                        control={<Checkbox color="primary" disableRipple />}
                        label="Confirm that you connected channex on booking.com"
                        onChange={(e) => { 
                            setForm({ ...form, confirmConnection: e.target.checked }); 
                            setError({ ...error, confirmConnection: false }); 
                        }} />
                    {error.confirmConnection && <FormHelperText>Please confirm</FormHelperText>}
                </FormControl>
            </div>
            <Button className={classes.button} onClick={submitHandler}>Test connection</Button>
        </React.Fragment>
    )

}

const ConnectOnUnsuccess = (props) => {
    const { classes } = props;
    const [error, setError] = React.useState(false);
    const [form, setForm] = React.useState({});

    const submitHandler = () => {
        if (!form.hostelID) {
            setError(true);
            return false
        }
        if (error) {
            setError(false);
        }
        props.submitHandler(form);
    }

    return (
        <React.Fragment>
            <div className={classes.title}>Connection unsuccessful</div>

            <div className={classes.row}>
                <div className={classes.label}>Why is this happening
                    <Tooltip placement="bottom-start" className={classes.icon} title={'Ensure you have selected Channex.io as connectivity partner, Navigate to Booking.com extranet > Account > Connectivity Provider > Search and select Channex.io'}>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.field, { [classes.fieldError]: error })}>
                    <InputBase
                        id="hostelID"
                        name="hostelID"
                        type="text"
                        placeholder="Re-enter your booking.com ID"
                        onChange={(e) => setForm({ ...form, hostelID: e.target.value })}
                    />
                </div>
            </div>

            <Button className={classes.button} onClick={submitHandler}>Test connection</Button>
        </React.Fragment>
    )
}

const Failure = (props) => {
    const { classes } = props;

    return (
        <React.Fragment>
            <div className={classes.title}>Looks like something is not right!</div>
            <div className={classes.text}>Our customer support team will contact you shortly</div>
            <Button className={classes.button} onClick={props.submitHandler}>Back to workspace</Button>
        </React.Fragment>
    )
}

const Success = (props) => {
    const { classes } = props;

    return (
        <React.Fragment>
            <div className={classes.title}>Yayy! Connection successful</div>
            <Button className={classes.button} onClick={props.submitHandler}>Proceed</Button>
        </React.Fragment>
    )
}

const MappingChannel = (props) => {
    const { classes, channelStatus, hide } = props;

    return (
        <React.Fragment>
            <div className={classes.title}>Map your rooms</div>
            <div className={classes.label}>How to map rooms correctly?</div>
            <Mapping open={true} hideLoader={true} />
            <Button className={classes.button} disabled={channelStatus != 'true'} onClick={props.submitHandler}>Finish setup</Button>
        </React.Fragment>
    )
}


const CounterMapping = (props) => {
    const classes = useStyles();
    const { currentSpace, channelStatus, loadingRedux } = props;
    const [loading, setLoading] = React.useState(false);
    const [currentScreen, setCurrentScreen] = React.useState(SCREEN_TYPE.CONNECT)
    // const [currentScreen, setCurrentScreen] = React.useState(SCREEN_TYPE.SUCCESS)

    const connectChannelHandler = (form) => {
        setLoading(true);

        const response = props.dispatch(connectChannelProperty(form, currentSpace.propertyID))
        response.then(data => {
            setLoading(false);
            if (data) {
                setCurrentScreen(SCREEN_TYPE.SUCCESS)
                props.dispatch(fetchChannelStatus(currentSpace.propertyID));
                // props.changeChannelStatus(true)
            }
        }).catch(error => {
            setLoading(false);
            if (currentScreen === SCREEN_TYPE.CONNECT)
                setCurrentScreen(SCREEN_TYPE.CONNECT_ON_UNSUCCESS)
            if (currentScreen === SCREEN_TYPE.CONNECT_ON_UNSUCCESS)
                setCurrentScreen(SCREEN_TYPE.FAILURE)
        });
    }

    const proceedToMappingHandler = () => {
        setCurrentScreen(SCREEN_TYPE.MAPPING);
    }

    const fullRefreshHandler = () => {
        props.dispatch(createChannelFullRefreshPost(currentSpace.propertyID, null, CHANNEL.BookingCom.code))
        props.cancelHandler();
    }

    const cancelHandler = () => {
        props.cancelHandler();
    }

    return (
        <Dialog
            open={true}
            onClose={cancelHandler}
            className={classes.dialog}
            fullScreen={true}>

            {![SCREEN_TYPE.SUCCESS, SCREEN_TYPE.MAPPING].includes(currentScreen) && <CloseIcon className={classes.closeIcon} onClick={cancelHandler} />}

            {!(loading) &&
                <div className={classes.container}>
                    {currentScreen === SCREEN_TYPE.CONNECT &&
                        <ConnectChannel classes={classes} submitHandler={connectChannelHandler} />
                    }

                    {currentScreen === SCREEN_TYPE.CONNECT_ON_UNSUCCESS &&
                        <ConnectOnUnsuccess classes={classes} submitHandler={connectChannelHandler} />
                    }

                    {currentScreen === SCREEN_TYPE.FAILURE &&
                        <Failure classes={classes} submitHandler={cancelHandler} />
                    }

                    {currentScreen === SCREEN_TYPE.SUCCESS &&
                        <Success classes={classes} submitHandler={proceedToMappingHandler} />
                    }
                    {currentScreen === SCREEN_TYPE.MAPPING &&
                        <MappingChannel classes={classes} channelStatus={channelStatus} hide={loadingRedux} submitHandler={fullRefreshHandler} />}
                </div>
            }

            {loading && <img className={classes.loading} src={IMAGES.ICONS.bookingComLoading} />}

        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    loadingRedux: state.loading.FETCH_CHANNEL_ROOM_TYPE ||
        state.loading.DELETE_PROPERTY_CHANNEL_MAPPING ||
        state.loading.ACTIVATE_CHANNEL_PROPERTY 
})

export default connect(mapStateToProps)(CounterMapping);