import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import _ from "lodash";

import NewMessage from "./NewMessage";
import UnreadMessages from "./UnreadMessages";
import { returnAllOtherUsers } from "../../redux/selectors/dashboard";
import { setCurrentChat } from "../../redux/actions/dashboard/dashboard";
import { newUnreadMessage } from "../../redux/actions/messages/messages";
import commaSeparatedList from "../utils/commaSeparatedList";
import { initials } from "../utils/wordUtils";

import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Badge from "@material-ui/core/Badge";
import Pusher from "pusher-js";
import { CircularProgress } from "@material-ui/core";
import { PUSHER_KEY } from "../../config";

const pusher_key_var = PUSHER_KEY;

const styles = (theme) => ({
  menuList: {
    "& ul": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  root: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: 350,
    },
  },
  unreadMessages: {
    margin: theme.spacing(2),
  },
  allRead: {
    cursor: "pointer",
  },
  messageList: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    padding: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingTop: 10,
  },
  header: {
    fontWeight: 600,
  },
  card: {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    shadow: "none",
  },
  customBadge: {
    backgroundColor: "#A93660",
    borderRadius: "50%",
    color: "#FFFFFF",
    height: 8,
    minWidth: 8,
    padding: 0,
    right: 10,
    top: 10,
    width: 8,
  },
  bubble: {
    backgroundColor: "#bdbdbd",
    border: "1px solid #dddddd",
    borderRadius: "50%",
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "3rem",
    lineHeight: 2.5,
    height: 70,
    marginBottom: 5,
    textAlign: "center",
    width: 70,
  },
  messageName: {
    cursor: "pointer",
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 100,
  },
  blankState: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 100,
    lineHeight: 1.5,
    textAlign: "center",
    width: "50%",
    marginLeft: "25%",
  },
});

class MessageList extends React.Component {
  componentDidMount() {
    this.pusher = new Pusher(pusher_key_var, {
      encrypted: true,
      cluster: "eu",
      forceTLS: true,
    });
    console.log('USER ID: ', this.props.user._id)
    this.channel = this.pusher.subscribe(this.props.user._id);
    this.channel.bind("created", this.updateEvents);
    this.channel.bind("sendMessage", this.updateEvents);
    this.channel.bind("updated", this.updateEvents);
    this.channel.bind("deleted", this.updateEvents);
  }

  componentWillUnmount() {
    this.channel.unbind();
    this.pusher.unsubscribe(this.channel);
  }

  handleChatRoomSelect = (chatRoomID) => {
    // console.log('chatRoom ID', chatRoomID);
    this.props.dispatch(setCurrentChat(chatRoomID));
    this.props.handleMsgClose();
  };

  updateEvents = (data) => {
    console.log("New message received ", data);
    this.props.dispatch(newUnreadMessage(data));
  };

  render() {
    const {
      classes,
      msgAnchorEl,
      handleMsgClose,
      users,
      messages,
      loadingMessages,
    } = this.props;
    const msgOpen = Boolean(msgAnchorEl);
    const unreadMessages = Object.keys(messages).filter(
      (key) => messages[key].unread
    );

    return (
      <Menu
        id="msg-appbar"
        anchorEl={msgAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ padding: 0 }}
        getContentAnchorEl={null}
        className={classes.menuList}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={msgOpen}
        onClose={handleMsgClose}
      >
        <div className={classes.root}>
          <NewMessage
            suggestions={this.props.suggestions}
            handleMsgClose={handleMsgClose}
          />

          {/* ------------------------------------------------------------ */}
          {/* UNREAD MESSAGES */}
          {/* ------------------------------------------------------------ */}
          {unreadMessages.length !== 0 && (
            <List
              component="nav"
              className={classes.unreadMessages}
              disablePadding={true}
            >
              <div className={classes.headerContainer}>
                <Typography
                  className={classes.header}
                  component="span"
                  variant="h6"
                >
                  New for you
                </Typography>
                <Typography
                  className={classes.allRead}
                  component="span"
                  variant="body2"
                >
                  Mark all read
                </Typography>
              </div>
              {unreadMessages.map((message) => (
                <UnreadMessages
                  key={message}
                  message={message}
                  handleMsgClose={handleMsgClose}
                />
              ))}
            </List>
          )}

          {/* ------------------------------------------------------------ */}
          {/* MESSAGE LIST */}
          {/* ------------------------------------------------------------ */}
          <List
            component="nav"
            disablePadding={true}
            className={classes.messageList}
          >
            <div className={classes.headerContainer}>
              <Typography
                className={classes.header}
                component="span"
                variant="h6"
              >
                Recent Messages
              </Typography>
            </div>

            <Grid
              container
              alignContent="center"
              className={classes.messageGrid}
            >
              {loadingMessages && (
                <Grid item xs={12}>
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                </Grid>
              )}
              {!loadingMessages && Object.keys(messages).length === 0 && (
                <Grid item xs={12}>
                  <div className={classes.blankState}>
                    No message history. Invite your team members to join Counter
                    and start a private chat above.
                  </div>
                </Grid>
              )}

              {!loadingMessages &&
                messages &&
                Object.keys(messages).map((message) => {
                  let user = messages[message].members[0];
                  return (
                    <Grid
                      xs={4}
                      sm={3}
                      md={2}
                      key={message}
                      item
                      align="center"
                    >
                      <Card className={classes.card}>
                        <CardActionArea
                          onClick={() => this.handleChatRoomSelect(message)}
                        >
                          <Badge
                            overlap="rectangular"
                            badgeContent=""
                            invisible={!messages[message].unread}
                            classes={{ badge: classes.customBadge }}
                          >
                            <Avatar
                              alt={users[user] && initials(users[user].name)}
                              src={users[user] && users[user].avatar}
                              className={classes.bubble}
                              title={users[user] && users[user].name}
                            >
                              {users[user] && initials(users[user].name)}
                            </Avatar>
                          </Badge>
                          <div className={classes.messageName}>
                            {commaSeparatedList(
                              users,
                              messages[message].members
                            )}
                          </div>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </List>
        </div>
      </Menu>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  users: state.users,
  messages: state.messages,
  loadingMessages: state.loading.LOAD_MESSAGES,
  suggestions: returnAllOtherUsers(state.users, state.auth.user._id),
  notificationError: state.notifications.loadNotificationsFailure,
});

export default withStyles(styles)(connect(mapStateToProps)(MessageList));
