const defaultState = { taxInvoiceEnabled: false };

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case "LOAD_PROPERTY_SETTINGS":
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }
};