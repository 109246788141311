const roomTypes = {};

export default (state = roomTypes, action) => {
  switch (action.type) {
    case "LOAD_ROOMTYPES_SUCCESS":
      return {
        ...state,
        ...action.payload.roomTypes
      };
    case "CREATE_ROOMTYPE_SUCCESS":
      if (state === {}) {
        return {
          [action.payload.roomType._id]: { ...action.payload.roomType }
        }
      } else return {
        ...state,
        [action.payload.roomType._id]: { ...action.payload.roomType }
      }

    case "DELETE_ROOM_TYPE_SUCCESS":
      const deleteRoomType = { ...state };
      return {
        ...state,
        [action.payload.roomTypeID]: { 
          ...deleteRoomType[action.payload.roomTypeID], 
          beds: [],
          isActive: false 
        }
      }

    case "EDIT_ROOMTYPE_SUCCESS":
      const roomType = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: { ...roomType, ...action.payload }
      }
    case "CREATE_ROOM_SUCCESS":
      let createdRoom = { ...action.payload.data };
      let primaryRoomTypeID = createdRoom.primaryRoomTypeID || (createdRoom.mapping ? createdRoom.mapping[0].roomTypeID : null)
      const createRoomType = state[primaryRoomTypeID];
      const roomBeds = createdRoom.beds || [];
      if (primaryRoomTypeID && state[primaryRoomTypeID]) {
        return {
          ...state,
          [primaryRoomTypeID]: {
            ...createRoomType,
            rooms: [...createRoomType.rooms, createdRoom._id],
            beds: [...createRoomType.beds, ...roomBeds],
          }
        }
      } else {
        return { ...state }
      }

    case "EDIT_ROOM_SUCCESS":
      const bedPayload = action.payload.beds || [];
      const roomTypes = { ...state };
      bedPayload.forEach(bed => {
        if (roomTypes[bed.roomTypeID]) {
          let roomsData = [...roomTypes[bed.roomTypeID].rooms];
          if (!roomsData.includes(bed.roomID)) roomsData.push(bed.roomID);
          roomTypes[bed.roomTypeID] = {
            ...roomTypes[bed.roomTypeID],
            rooms: [...roomsData],
            beds: [...roomTypes[bed.roomTypeID].beds, bed._id]
          }
        }
      });
      return {
        ...state,
        ...roomTypes
      }

    case "DELETE_ROOMS_MAPPING_SUCCESS":
      const deletedBedPayload = action.payload.distinctBedIDs || [];
      const roomTypesData = { ...state };
      let affectedRoomTypeID = action.payload.affectedRoomTypeID;
      if (roomTypesData[affectedRoomTypeID] && deletedBedPayload.length) {
        let typeRoomsData = [...roomTypesData[affectedRoomTypeID].rooms];
        let typeBedsData = [...roomTypesData[affectedRoomTypeID].beds];

        //even if the room and roomType has a extra mappings its still removed as as per logic
        typeRoomsData = typeRoomsData.filter((ids) => ids !== action.payload.room._id);
        typeBedsData = typeBedsData.filter((id) => !deletedBedPayload.includes(id));

        roomTypesData[affectedRoomTypeID] = {
          ...roomTypesData[affectedRoomTypeID],
          rooms: [...typeRoomsData],
          beds: [...typeBedsData]
        }

      }

      return {
        ...state,
        ...roomTypesData
      }

    case "DELETE_ROOM_SUCCESS":
      const deleteRoom = state[action.payload.room.primaryRoomTypeID];
      const deletedRoomBeds = action.payload.beds || [];
      let deteletedBedIDs = deletedRoomBeds.map(itemData => itemData._id);
      let typeRooms = deleteRoom?.rooms || [];
      let typeBeds = deleteRoom?.beds || [];
      typeRooms = typeRooms.filter(id => id !== action.payload.room._id);
      typeBeds = typeBeds.filter(id => !deteletedBedIDs.includes(id));
      return {
        ...state,
        [action.payload.room.primaryRoomTypeID]: {
          ...deleteRoom,
          rooms: typeRooms,
          beds: typeBeds,
        }
      }

    case "UPLOAD_ROOM_TYPE_PHOTO_SUCCESS":
      const uploadRoomTypePhoto = state[action.roomTypeID]
      return {
        ...state,
        [action.roomTypeID]: {
          ...uploadRoomTypePhoto,
          images: [...action.payload.images]
        }
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};