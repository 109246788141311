import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const groupSizeLimit = 50;

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    selectFormControl: {
        width: '30%',
        boxSizing: 'border-box',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    formContainer: {
        marginBottom: '20px'
    }
}));

const ActivityGroupSize = props =>{

    const classes = useStyles();
    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo, 
        submitDraftHandler,
        productData
    } = props;

    const [ groupSize, setGroupSize ] = useState({ minSize: 1, maxSize: ''});
    const [ formError, setFormError ] = useState({ minSize: '', maxSize: '' });

    const groupSizeList = Array(groupSizeLimit).fill();

    useEffect(() => {
        if(productData.maxSize){
            setGroupSize({ 
                minSize: productData.minSize, 
                maxSize: productData.maxSize
            });
        }
    }, [ productData.maxSize ]);

    const onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        setGroupSize({ ...groupSize, [ name ]: value });
    }

    const validateGroupSize = () =>{
        const { minSize, maxSize } = groupSize;
        
        const minVal = parseInt(minSize);
        const maxVal = parseInt(maxSize);

        if(!minVal){
            setFormError({ ...formError, minSize: 'Min group size is required'});
            return;
        }

        if(!maxVal){
            setFormError({ ...formError, maxSize: 'Max group size is required'});
            return;
        }

        if(minVal > maxVal){
            setFormError({ ...formError, maxSize: 'Max group size should be greater than min group size'});
            return;
        }

        return true;
    }

    const submitHandler = () =>{

        if(!validateGroupSize()) return;

        const data = { ...groupSize };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className={clsx("main-content", "hideScrollBar")}>
                <div className={classes.formContainer}>
                    <div className="main-content-title">Minimum group size</div>
                    <FormControl variant="outlined" className={classes.selectFormControl}>
                        <Select
                            native
                            value={groupSize.minSize}
                            onChange={onChangeHandler}
                            className={classes.selectInput}
                            inputProps={{
                                name: 'minSize'
                            }}
                        >
                            {groupSizeList.map((_,index) =>(
                                <option value={index + 1} key={`minSize_${index}`}>
                                    {index + 1}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {formError.minSize && (
                        <FormHelperText className="errorMessage">
                            {formError.minSize}
                        </FormHelperText>
                    )}
                </div>
                
                <div className={classes.formContainer}>
                    <div className="main-content-title">Maximum group size</div>
                    <FormControl variant="outlined" className={classes.selectFormControl}>
                        <Select
                            native
                            value={groupSize.maxSize}
                            onChange={onChangeHandler}
                            className={classes.selectInput}
                            inputProps={{
                                name: 'maxSize'
                            }}
                        >
                            <option aria-label="None" value="" />
                            {groupSizeList.map((_,index) =>(
                                <option value={index + 1} key={`maxSize_${index}`}>
                                    {index + 1}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {formError.maxSize && (
                        <FormHelperText className="errorMessage">
                            {formError.maxSize}
                        </FormHelperText>
                    )}
                </div>

            </div>
            <Footer
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
            />
        </>
    )
}

export default ActivityGroupSize; 