import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import ConfirmForm from '../../../common/ConfirmForm';

import IMAGES from '../../../../constants/images';

import { 
    getUploadUrl,  
    uploadProductImage, 
    saveProductImage,
    deleteProductImage,
    acivityImageUploadStatus
 } from '../../../../redux/actions/experiences/activities';

const styles = theme =>({
    item: {
        width: 240,
        paddingRight: 20,
    },
    uploadContainer:{
        marginBottom: '10px'
    },
    drop: {
        alignItems: 'center',
        border: '2px dashed #dddddd',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
        padding: theme.spacing(3),
        '&:focus': {
            outline: 'none',
        },
        '& img': {
            width: 50,
        },
    },
    hide: {
        display: 'none'
    },

    previewContainer:{
        width: '100%',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
    },
    closeIcon: {
        top: '-12px',
        color: '#666666',
        right: '-12px',
        cursor: 'pointer',
        padding: '5px',
        position: 'absolute',
        background: '#dddddd',
        transition: '150ms',
        borderRadius: '50%',
        width: '17px',
        height: '17px',
    },
    confirmContainer:{
        marginTop: '5px'
    }
});

class ImageUploadItem extends Component {

    state = {
        confirmOpen: false
    }

    getFileData = files =>{
        const imageFile = files[0];
        const fileParts = imageFile.name.split(".");
        const fileName = fileParts[0];
        const fileType = fileParts[1];
        return { imageFile, fileName, fileType };
    }

    imageUploadHandler = event =>{
        const { productID, currentSpace, dispatch, photoData, resetError = null } = this.props;

        if(resetError) resetError();

        const files = event.target.files;
        const { imageFile, fileName, fileType } = this.getFileData(files);
        const data = {
            name: fileName,
            type: fileType
        }

        dispatch(acivityImageUploadStatus('request'));

        const response = getUploadUrl(productID, currentSpace.propertyID, data);
        response.then(({data}) =>{
            const key = data.key;
            const uploadData = {
                key,
                file: imageFile,
                type: fileType
            };

            //Get upload to storage url........
            const uploadResponse = uploadProductImage(data.url, uploadData);
            //Upload to storage.....
            uploadResponse.then(res=>{

                //Save uploaded details....
                const imageData = { 
                    key, 
                    caption: '',
                    type: photoData.type
                };
                const saveResponse = dispatch(saveProductImage(productID, currentSpace.propertyID, imageData));
                saveResponse.then(success =>{
                    if(success) dispatch(acivityImageUploadStatus('success'));
                })
            
            }).catch(error =>{
                console.log('upload error ', error);
                dispatch(acivityImageUploadStatus('error'));
            });
        
        }).catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            console.log('get upload url ',error);
            dispatch(acivityImageUploadStatus('error', error));
        });
    }

    deleteImageHandler = () =>{
        const { dispatch, productID, photoData } = this.props;
        dispatch(deleteProductImage(productID, photoData.id));
    }

    confirmHandler = status =>{
        this.setState({ confirmOpen: status });
    } 

    render(){
        const { classes, photoData, photoRef = null } = this.props;
        const { confirmOpen } = this.state;
        const hasImage = photoData.imageUrl ? true : false;

        return (
            <div className={classes.item} ref={photoRef}>

                {/* ----------------IMAGE UPLOAD BUTTON----------------------- */}
                {!hasImage && (
                    <div className={classes.uploadContainer}>
                        <input
                            accept="image/*"
                            className={classes.hide}
                            id={`photo_${photoData.type}`}
                            type="file"
                            onChange={this.imageUploadHandler}
                        />
                        <label htmlFor={`photo_${photoData.type}`}>
                            <div className={classes.drop}>
                                <img src={IMAGES.ICONS.image} />
                            </div>
                        </label>
                    </div>
                )}

                {/* ----------------IMAGE PREVIEW----------------------- */}
                {hasImage && (
                    <div className={classes.previewContainer}>
                        <CloseIcon 
                            className={classes.closeIcon} 
                            onClick={() => this.confirmHandler(true)} 
                        />
                        <img src={photoData.imageUrl} />
                    </div>
                )}
              
                <div className="sub-title">{photoData.description}</div>
              
                <div className={classes.confirmContainer}>
                    <ConfirmForm
                        title="Delete Photo?"
                        open={confirmOpen}
                        setOpen={this.confirmHandler}
                        onClose={() => this.confirmHandler(false)}
                        onConfirm={this.deleteImageHandler}
                        >
                        Are you sure you would like to delete this photo?
                    </ConfirmForm>
                </div>
              
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { spaces, dashboard } = state;
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
    }
}


export default withStyles(styles)(connect(mapStateToProps)(ImageUploadItem));