import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: 10,
    },
    productPic: {
        height: 150,
        width: '100%',
        objectFit: 'cover',
    },
    nameContainer: {
        display: 'flex',
        marginBottom: 10,
        flexDirection: 'column'
      },
    nameText: {
        marginBottom: 5
    },
    settingsIcon: {
        // borderRadius: '50%',
        // cursor: 'pointer',
        height: 15,
        padding: theme.spacing(1),
        width: 15,
        '& img': {
          height: 15,
          opacity: 0.5,
          width: 15,
        },
        // '&:hover': {
        //   background: '#F8F9FB'
        // }
    },
    editBtn: {
        cursor: 'pointer',
        fontSize: '1.2rem',
        color: '#BDBDBD'
    }
}));

const VariationItem = props =>{

    const classes = useStyles();
    const { 
        title, 
        variationNo, 
        editVariationHandler,
        gradeID,
        coverPhotoUrl = ""
    } = props;

    const getVariationTitle = () =>{
        const variation = `Variation ${variationNo}`;
        const variationTitle = (variationNo == 1) ? `${variation} (Main experience)` : variation;
        return variationTitle;
    }

    return(
        <Grid container spacing={3} className={classes.headerContainer}>

            <Grid item md={4} lg={3} xs={12}>
                <img className={classes.productPic} src={coverPhotoUrl} />
            </Grid>

            <Grid item sm={12} md={8} lg={9}>
                <div className={classes.nameContainer}>
                    <Typography className={classes.nameText}>{getVariationTitle()}</Typography>
                    <Typography variant="h4" className={classes.nameText}>{title}</Typography>

                    {variationNo == 1 && (
                        <div className={classes.settingsIcon}>
                            <img src={IMAGES.ICONS.upsellLocks} />
                        </div>
                    )}

                    {variationNo != 1 && (
                        <div
                            className={classes.editBtn} 
                            onClick={() => editVariationHandler(gradeID)}
                        >
                            Edit
                        </div>
                    )}
                    
                </div>
            </Grid>

        </Grid>
    )
}

export default VariationItem;