import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_ACCESS_TOKEN } from "../../config";

const MAPBOX_TOKEN = MAPBOX_ACCESS_TOKEN;

const DEFAULT_LOCATION = {
  longitude: 0,
  latitude: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "300px",
  },
}));

const MapBox = ({ coordinates, onChangeLocation, zoom = 1 }) => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    const { latitude, longitude } = coordinates;

    //INITIALIZE MAP......................
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: latitude && longitude ? 12 : zoom,
      accessToken: MAPBOX_TOKEN,
      center: [longitude, latitude],
    });

    //INITIALIZE MARKER......................
    marker.current = new mapboxgl.Marker({ draggable: true });

    //INITIALIZE GEOCODER......................
    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_TOKEN,
      mapboxgl: mapboxgl,
      marker: false,
    });

    // INITIALIZE NAVIGATION CONTROL......................
    const navControl = new mapboxgl.NavigationControl();

    //ADD GEOCODER AND NAVIGATION CONTROL TO MAP..........
    map.current.addControl(geocoder);
    map.current.addControl(navControl, "bottom-right");

    //INITIATE EVENT LISTENERS................
    map.current.on("click", onClickHandler);
    geocoder.on("result", onResultHandler);
    marker.current.on("dragend", onDragEndHandler);

    return () => {
      //REMOVE EVENT LISTENERS..................
      map.current.off("click", onClickHandler);
      geocoder.off("result", onResultHandler);
      marker.current.off("dragend", onDragEndHandler);
    };
  }, []);

  useEffect(() => {
    const { latitude, longitude } = coordinates;
    if (longitude && latitude) {
      marker.current.setLngLat([longitude, latitude]).addTo(map.current);

      map.current.flyTo({
        center: [longitude, latitude],
        speed: 10,
      });
    }
  }, [coordinates]);

  const onClickHandler = (evt) => {
    const { lat, lng } = evt.lngLat;
    updateLocation(lng, lat);
  };

  const onResultHandler = (evt) => {
    const { result } = evt;
    const location =
      result &&
      (result.center ||
        (result.geometry?.type === "Point" && result.geometry.coordinates));

    if (location) {
      updateLocation(location[0], location[1]);
      return;
    }

    updateLocation(...DEFAULT_LOCATION);
  };

  const onDragEndHandler = () => {
    const { lat, lng } = marker.current.getLngLat();
    updateLocation(lng, lat);
  };

  const updateLocation = (lng, lat) => {
    const location = { latitude: lat, longitude: lng };
    onChangeLocation(location);
  };

  return <div ref={mapContainer} className={classes.root} />;
};

export default MapBox;
