import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { fetchChannexSessionToken, channexCaptureCard, resetError } from '../../../../redux/actions/reservation/editReservation';

const styles = theme =>({
    cardIframeContainer: {
        // marginTop: '20px',
        position: 'relative',
        paddingTop: '40%',
        overflow: 'hidden',
        paddingBottom: '100px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '75%',
        },
    },
    cardCaptureIframe:{
        border: '0',
        height: '100%',
        left: '5%',
        position: 'absolute',
        top: '35px',
        width: '91%',
        [theme.breakpoints.down('sm')]: {
            top: '22px',
            left: '5px',
            width: '97%'
        }
    },
    buttonContainer:{
        display: 'flex', 
        justifyContent: 'flex-end', 
        margin: '20px',
        // marginTop: '30px',
        // [theme.breakpoints.down("xs")]:{
        //     margin: '0px 10px'
        // }
    },
    checkoutBtn:{
        width: '100%',
        backgroundColor: '#5C5C5C',
        color: '#fff',
        textTransform: 'capitalize',
        padding: "6px 20px",
        minWidth: '100px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: '#5C5C5C',
            color: '#fff'
        }
    },
    section: {
        // margin: theme.spacing(3),
        // [theme.breakpoints.up("sm")]:{
        //     marginLeft: theme.spacing(7),
        //     marginRight: theme.spacing(7),
        // }
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
});

class CardCaptureForm extends Component {
    state = {
        cardCaptureIframeSrc: null
    }

    cardCaptureListener = event =>{
        
        // if (event.origin !== PCI_PROXY_DOMAIN) {
        //     //console.log('rejected origin ',event.origin);
        //     return;
        // }
        
        if (event.data.valid) {
            //console.log("card valid: ", event.data.valid);
        }

        if (event.data.success) {
            const cardData = event.data.card;
            this.saveChannexToken(cardData);
        }
    }

    initChannexForm = () =>{
        const { dispatch } = this.props; 

        const ACTION_NAME = "FETCH_CHANNEX_TOKEN";
        
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        
        fetchChannexSessionToken().then(response=> {
    
            const channexSessionToken = response.data.session_token;
            this.setState({ 
                cardCaptureIframeSrc: `https://pci.channex.io/api/v1/capture_form?session_token=${channexSessionToken}&style=booking_engine`
            });
            window.addEventListener("message", this.cardCaptureListener);
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });

        }).catch(errorResult => {
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }

    componentDidMount(){
        this.initChannexForm();
    }

    handleSubmit = () =>{
        const { isLoading } = this.props;

        if(isLoading) return;

        //Submit card Capture form...
        this.cardCaptureIframeRef.contentWindow.postMessage("submit", "https://pci.channex.io");
    }

    saveChannexToken = cardData => {
        const { dispatch, reservationID, closeModalHander } = this.props; 
        // const data = {
        //     channexCardToken: cardData.card_token,
        // };

        const response = dispatch(channexCaptureCard(reservationID, cardData, this.initChannexForm));
        response.then(success =>{
            if(success) closeModalHander();
        });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('FETCH_CHANNEX_TOKEN'));
        dispatch(resetError('CHANNEX_CAPTURE_CARD'));
    }

    componentWillUnmount(){
        window.removeEventListener("message", this.cardCaptureListener);
    }

    render(){
        const { classes, isLoading, errors } = this.props;
        const { cardCaptureIframeSrc } = this.state;
        return(
        
            <>
                <div className={classes.section}>

                    {/* ..............Circular Loader...................... */}
                    {isLoading && (
                        <div className={classes.loadingState}>
                            <CircularProgress className={classes.loader} />
                        </div>
                    )}

                    <div className={classes.cardIframeContainer}>
                        {cardCaptureIframeSrc && (
                            <iframe
                                ref={ ref => this.cardCaptureIframeRef = ref }
                                id="cardCaptureIframe"
                                height="300"
                                scrolling="no"
                                className={classes.cardCaptureIframe}
                                src= { cardCaptureIframeSrc }>
                            </iframe>
                        )}
                    </div>

                </div>

                <div className={classes.buttonContainer}>
                    <Button
                        type="button"
                        className={classes.checkoutBtn}
                        onClick={this.handleSubmit}
                    >
                        Save
                    </Button>
                </div>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}

            </>
        );
    }
}

const mapStateToProps = state =>{
    const { loading, errors } = state;
    return{
        isLoading: loading.FETCH_CHANNEX_TOKEN || loading.CHANNEX_CAPTURE_CARD,
        errors: errors.FETCH_CHANNEX_TOKEN || errors.CHANNEX_CAPTURE_CARD
    }
}

export default withStyles(styles)(connect(mapStateToProps)(CardCaptureForm));