import React from "react";
import { withStyles, Snackbar, Hidden } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReservationTab from './edit-reservation/ReservationTab';
import GuestsTab from './edit-reservation/GuestsTab';
import NotesTab from './edit-reservation/NotesTab';
import EmailsTab from './edit-reservation/EmailsTab';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import CloseIcon from '@material-ui/icons/Close';
import ActivityLog from "./edit-reservation/ActivityLog";
import Cancel from "./edit-reservation/Cancel";
import { resetError, resetSuccess } from "../../redux/actions/reservation/editReservation";
import { getStripeAccountStatus } from '../../redux/actions/payments/payments';
import Summary from "./edit-reservation/Summary";
import UpsellTab from "./edit-reservation/UpsellTab";
import SummaryDrawer from "./SummaryDrawer";
import POSTab from "./edit-reservation/POSTab";
import ExperienceTab from "./edit-reservation/ExperienceTab";
import Header from "./view-reservation/Header";
import { loadUserPermissions } from "../../redux/actions/permissions/permissions";
import { fetchPrinters } from '../../redux/actions/settings/printer'

const styles = theme => ({
  dialog: {
    overflowY: 'auto',
  },
  dialogPaper: {
    maxHeight: 'unset',
  },
  dialogHeader: {
    position: 'relative',
    zIndex: 2,
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    zIndex: 99,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  tabsContainer: {
    padding: '0 35px',
    boxShadow: '0px 2px 5px 0px rgba(235,237,247,0.75)',
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'unset',
      justifyContent: 'left',
      fontSize: '1.2rem',
      marginRight: 20,
    },
    '& .MuiTab-textColorInherit': {
      paddingLeft: 0,
      marginRight: 10,
    },
    '& .MuiTabs-indicator': {
      background: "#13408c",
      height: "3px",
      marginTop: "-5px"
    },
    '& .MuiTab-root': {
      minWidth: 20,
      minHeight: 35,
      paddingBottom: 0,
    },
    '& .Mui-selected': {
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-scroller': {
        whiteSpace: 'unset',
        display: 'block',
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
  },
  tabPanel: {
    borderRadius: '15px',
    flexGrow: 1,
    width: '70%',
    '& > .MuiBox-root': {
      height: 'calc(100vh - 130px)',
      padding: 10,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10,
      overflowY: 'hidden'
    }
  },
  reservationDetails: {
    height: '100%',
    overflowY: 'auto',
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  experienceDetails: {
    height: 'calc(100% - 30px)',
    overflowY: 'auto',
    padding: 20,
    margin: 6,
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  extraDetails: {
    height: 'calc(100% - 20px)',
    borderRadius: 5,
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 20,
    marginRight: 25,
    marginLeft: 25,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#F2F2F2',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    overflowY: 'auto',
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
    }
  },
  reservationContainer: {
    display: 'flex',
  },
  notification: {
    padding: '5px 10px',
    backgroundColor: '#13408d',
    color: '#fff',
    fontWeight: 600,
    marginLeft: 5,
    borderRadius: 5
  },
  summary: {
    width: '30%',
    height: 'calc(100vh - 130px)',
  },
  blankSlate: {
    marginLeft: 35,
    marginTop: 20,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

const getNotesCount = (notes = []) => {
  return notes?.filter(note => !note.isArchived)?.length || 0;
}

const Reservation = (props) => {

  const { classes, open, emails, reservation = {}, cancelSuccess, loadingCancel, 
          loadingReservation, loadingError, loadingRoomTypes, currentSpace, property, t,
          currentModule, currentSubModule, currentSpaceID
        } = props;
  const [value, setValue] = React.useState("reservation");
  const [visible, setVisible] = React.useState(false);
  const [posVisible, setPosVisible] = React.useState(false);
  const [openLog, setOpenLog] = React.useState(false);
  const [optionDates, setOptionDates] = React.useState({ startDate: null, duration: null });
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const messageCount = ((emails?.length || 0) + (reservation?.guestComments?.length || 0));
  const notesCount = getNotesCount(reservation?.notes)

  React.useEffect(() => {
    if (property.features) {
      let POSFeature = _.find(property.features, { name: "POS" })
      if ((POSFeature) && (POSFeature.isEnabled === 'true')) {
        setPosVisible(true)
      } else setPosVisible(false)
    }
  }, [property])

  React.useEffect(() => {
		if (props?.settings?.openByID && reservation.code) {
			window.history.replaceState({}, document.title, `/${property.code}/reservation/${reservation.code}`);
      document.title = `${reservation.code} - ${reservation.guestName} | Counter`;
		}
	}, [props.settings, reservation])

  React.useEffect(() => {
    if (cancelSuccess) {
      setVisible(false);
      handleClose()
    }
  }, [cancelSuccess]);

  React.useEffect(() => {
    
    props.dispatch(getStripeAccountStatus(currentSpace.propertyID));
    props.dispatch(fetchPrinters(currentSpaceID))
    fetchUserPermissions();
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'view', 'eventLabel': 'View Reservation'});
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true });
    return () => {
      window.Intercom && window.Intercom('update', { "hide_default_launcher": false });
      document.title = `Counter Workspace`;
    }
  }, [])

  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  const fetchUserPermissions = () => {
    props.dispatch(loadUserPermissions(props.currentUserID, currentSpaceID, false));
  }

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetSuccess('CANCEL_RESERVATION'));
    props.dispatch(resetError('UPDATE_RESERVATION'))
    props.dispatch(resetError('DELETE_CUSTOMER'))
    props.dispatch(resetError('SEND_EMAIL'))
    props.dispatch(resetError('UPLOAD_CUSTOMER_DOC'))
    props.dispatch(resetError('UPDATE_RESERVATION_STATUS'))
    props.dispatch(resetError('ADD_RESERVATION_PAYMENT'))
  }

  const handleManualOptionDates = (startDate, duration) => {
    setOptionDates({ startDate: startDate, duration: duration });
  }

  // const paymentSelect = () => {
  //   props.dispatch(getStripeAccountStatus(reservation.propertyID))
  // }

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.origin + '/' + property.code + '/reservation/' + reservation.code);
  }

  const handleClose = () => {
    // if (props.currentModule && props.currentModule !== "") {
		// 	if (props.currentSubModule && props.currentSubModule !== "") {
		// 		window.history.replaceState({}, document.title, `/${props.currentModule}/${props.currentSubModule}`);
		// 	} else {
		// 		window.history.replaceState({}, document.title, `/${props.currentModule}`);
		// 	}
		// } else {
		// 	window.history.replaceState({}, document.title, "/");
		// }

    let path = '/';
    path += props?.currentModule || '';
    path += props?.currentSubModule && `/${props.currentSubModule}` || '';  

    window.history.replaceState({}, document.title, path);
    handleCloseError();
    props.handleCloseReservation()
  }


  return (
    <div>
      <Dialog
        open={open}
        classes={{ paperFullWidth: classes.dialogPaper }}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={true}
        disableAutoFocus={true}
      >

        <div className={classes.dialogHeader}>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <Header
            loading={loadingReservation}
            reservation={reservation._id ? reservation : null}
            property={property}
            handleDelete={() => setVisible(true)}
            handleOpenLog={() => setOpenLog(true)}
            handleCopy={handleCopy}
          />
        </div>


        <div className={classes.dialog}>

          {/* VERTICAL reservation TABS */}
          {/* ---------------------------------------------------------------------- */}
          <Tabs
            orientation="horizontal"
            aria-label="Vertical tabs example"
            className={classes.tabsContainer}
            value={value}
            variant="scrollable"
            scrollButtons={windowWidth > 901 ? "auto" : "on"}
            onChange={handleChange}
          >
            <Tab
              value="reservation"
              // className={classes.tab}
              label={t('existingReservation.reservationLabel')} {...a11yProps("reservation")}
            />
            <Tab
              value="upsell"
              // className={classes.tab}
              label={t('existingReservation.upsellLabel')} {...a11yProps("upsell")}
            />
            {posVisible && <Tab
              value="pos"
              // className={classes.tab}
              label="POS" {...a11yProps("pos")}
            />}
            <Tab
              value="experience"
              // className={classes.tab}
              label={t('existingReservation.experienceLabel')} {...a11yProps("experience")}
            />
            <Tab
              value="guests"
              // className={classes.tab}
              label={t('existingReservation.guestLabel')} {...a11yProps("guests")}
            />
            <Tab
              value="messages"
              // className={classes.tab}
              label={<span>{t('existingReservation.messageLabel')} {messageCount ? <span className={classes.notification}>{messageCount}</span> : ''}</span>} {...a11yProps("messages")}
            />
            <Tab
              value="notes"
              // className={classes.tab}
              label={<span>{t('existingReservation.notesLabel')} {notesCount ? <span className={classes.notification}>{notesCount}</span> : ''}</span>} {...a11yProps("notes")}
            />
            <Tab
              value="activity"
              // className={classes.tab}
              label={t('existingReservation.activityLabel')} {...a11yProps("activity")}
            />
          </Tabs>

          <div className={classes.reservationContainer}>
            {/* TAB CONTENT */}
            {/* ---------------------------------------------------------------------- */}

            {/* Reservation */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"reservation"}>
              <div className={classes.reservationDetails}>
                {!loadingReservation && !loadingRoomTypes && !loadingError &&<ReservationTab windowWidth={windowWidth} calendarData={props.calendarData} optionDates={optionDates} options={props.options} handleManualOptionDates={handleManualOptionDates} />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* Upsell */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"upsell"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingRoomTypes && !loadingError && <UpsellTab />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* POS */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"pos"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingRoomTypes && !loadingError && <POSTab />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* POS */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"experience"}>
              <div className={classes.experienceDetails}>
                {!loadingReservation && !loadingRoomTypes && <ExperienceTab timezone={property.timezone}/>}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* Guests */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"guests"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingError && <GuestsTab />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>
            {/* Notes */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"notes"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingError && <NotesTab reservationNoteKeys={reservation.notes} reservationNotes={{}} />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* Messages */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"messages"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingError && <EmailsTab emailID={reservation.guestEmail} />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            {/* Activity */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={value} index={"activity"}>
              <div className={classes.extraDetails}>
                {!loadingReservation && !loadingError && <ActivityLog />}
                {loadingReservation && <div className={classes.blankSlate}>{t('existingReservation.loadingReservation')}</div>}
              </div>
            </TabPanel>

            <Hidden smDown>
              <div className={classes.summary}>
                {!loadingReservation && !loadingError && <Summary />}
              </div>
            </Hidden>

          </div>

          {/* --------------------------------------------------------------- */}
          {/* CANCEL view */}
          {/* --------------------------------------------------------------- */}
          {visible &&
            <Cancel handleClose={() => setVisible(false)} loadingCancel={loadingCancel} />
          }

        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {props.errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            autoHideDuration={10000}
            onClose={() => handleCloseError()}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}
      </Dialog>

      {!loadingError &&
        <SummaryDrawer
          open={openLog}
          closeLog={() => setOpenLog(false)}
          loadingReservation={loadingReservation}
        />}

    </div>
  )
}

const mapStateToProps = state => ({
  currentUserID: state.auth.user._id,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  currentModule: state.dashboard.currentModule,
	currentSubModule: state.dashboard.currentSubModule,
	settings: state.dashboard.reservationSetting,
  reservation: state.reservationDetails.reservation,
  emails: state.reservationDetails.emails,
  options: state.options || [],
  property: state.property,
  loadingReservation: state.loading.LOAD_RESERVATION,
  loadingRoomTypes: state.loading.LOAD_ROOMTYPES,
  loadingCancel: state.loading.CANCEL_RESERVATION,
  cancelSuccess: state.success.CANCEL_RESERVATION,
  loadingError: state.errors.LOAD_RESERVATION,
  errors: state.errors.UPDATE_RESERVATION ||
    state.errors.DELETE_CUSTOMER ||
    state.errors.SEND_EMAIL ||
    state.errors.UPLOAD_CUSTOMER_DOC ||
    state.errors.UPDATE_RESERVATION_STATUS ||
    state.errors.ADD_RESERVATION_PAYMENT
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Reservation)));