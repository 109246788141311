import React, { Component } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Snackbar, Drawer } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { returnCurrent } from "../../../redux/selectors/dashboard";
import BasicDetails from './BasicDetails';
import Navigation from '../../common/Navigation';
import Breadcrumb from '../../common/Breadcrumb';
import SelectRoomTypes from './SelectRoomTypes';
import AutomaticSelection from './AutomaticSelection';
import ManualSelection from './ManualSelection';
import GuestList from './GuestList';
import { deepSameKeys, fetchDateFormat, DATE_FORMATS, getAmtFormat, fetchCurrencyFormat, humanise, getAvatarFlag } from '../../../utils/utility';
// import { ICONS } from '../../../utils/imageUrls';
import { loadRoomSuggestion, resetRoomSuggestion, resetReservationManualOptions, loadReservationManualOptions } from '../../../redux/actions/rooms/rooms';
import { createReservation, editReservation, resetError, updateReservation, updateReservationDetails } from '../../../redux/actions/reservation/editReservation';
import { createBookingSource } from '../../../redux/actions/reservation/newReservation';
import { loadRatePlans } from '../../../redux/actions/rates/ratePlans';
import http from "../../../redux/utils/http";
import { isUndefined } from 'lodash';
import GuestTile from '../../search/GuestTile';
import * as i18nIsoCountries from "i18n-iso-countries";

import IMAGES from '../../../constants/images';
import { getAccommodationName } from '../../../utils/helper';
import { getReservations } from '../../../redux/actions/beds/beds';
import { RESERVATION_KIND } from '../../../utils/constants';


const styles = (theme) => ({
  Button: {
    background: '#000',
    color: '#fff',
    width: 100,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  fullWidth: {
    width: '100%'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    zIndex: 99,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  error: {
    color: 'red',
    fontSize: '1.4rem',
    padding: '30px 5px 0'
  },
  selectionType: {
    top: 24,
    zIndex: 99,
    right: 24,
    padding: 8,
    position: 'absolute',
    backgroundColor: 'transparent',
    '& .MuiToggleButtonGroup-grouped': {
      color: '#888888',
      padding: '0 20px',
      height: 40,
      border: 0,
      boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-child)': {
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },
    '& .MuiToggleButtonGroup-grouped:not(:last-child)': {
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    '& .MuiToggleButton-root.Mui-selected': {
      color: '#fff',
      backgroundColor: '#c8c8c8',
    },
    [theme.breakpoints.down('xs')]: {
      top: 42,
      right: 24,
      padding: '8px 0'
    },
  },
  dialogAction: {
    padding: '8px 30px 30px 30px'
  },
  dialogContent: {
    padding: '40px 5px',
    margin: '0 auto',
    overflow: 'hidden',
  },
  space: {
    height: 50
  },
  root: {
    // overflowY: 'scroll'
  },
  drawerPaper: {
    padding: '0px 20px',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  heading: {
    padding: '20px'
  },
  relativePosition: {
    position: 'relative'
  }
});

const reservationFormData = {
  reservation: {
    propertyID: '',
    bookingSource: '',
    checkIn: '',
    nights: 0,
    checkOut: ''
  }
}

class NewReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      buttonText: props.t('actions.next'),
      currentStep: 1,
      prevStep: 1,
      data: { typeID: {} },
      messageopen: true,
      selectionType: 'automatic',
      filteredSuggest: [],
      availability: {},
      breadcrumbList: {},
      ratePlanOptions: [],
      selectedRatePlan: '',
      drawerState: {
        open: false,
        customers: [],
        searchInProgress: false
      }
    };
    this.basic = React.createRef();
    this.type = React.createRef();
    this.bed = React.createRef();
    this.guest = React.createRef();

  }

  componentDidMount() {
    const { dispatch, currentSpace, ratePlans } = this.props;
    if (ratePlans.default) dispatch(loadRatePlans(currentSpace.propertyID));
  }

  componentDidUpdate(prevProps) {
    if (this.props.suggestions && !_.isEqual(this.props.suggestions, prevProps.suggestions)) {
      this.handleProcessSuggestions(this.props.suggestions);
    }
  }


  fetchRoomTypeAvailability = (params) => {
    http.get(`/api/frontdesk/reservation/suggest/bedCount/${this.props.currentSpace.propertyID}`, {
      params
    })
      .then(successResult => {
        let data = successResult.data || [];
        data = data.reduce(function (r, a) {
          r[a.roomTypeID] = r[a.roomTypeID] || {};
          r[a.roomTypeID] = a;
          return r;
        }, Object.create(null));
        this.setState({ availability: data });
      })
      .catch(errorResult => {
        this.setState({ availability: {} });
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log('error ', error);
      });
  }

  // call suggestion api
  fetchSuggestions = (data) => {
    let startCount = 0, endCount = 0;
    Object.keys(data.typeID).map(roomTypeID => {
      const count = data.typeID[roomTypeID];
      if (count) {
        startCount = 1 + endCount;
        let params = {
          duration: data.nights || 1,
          startDate: data.checkIn,
          guestCount: count,
          roomTypeID: roomTypeID,
          guestNumber: startCount
        }
        endCount = endCount + count;
        this.props.dispatch(loadRoomSuggestion(data.propertyID, params))
      }
    })
    this.props.dispatch(loadReservationManualOptions(data.propertyID,
      {
        duration: data.nights || 1,
        startDate: data.checkIn
      }
    ))
  }

  getRoomName = (bedID, roomID, roomTypeID) => {
    const { beds, rooms, roomTypes } = this.props;

    let bedName = beds?.[bedID]?.name || '';
    let roomName = rooms?.[roomID]?.name || '';
    const dormOrPrivate = roomTypes?.[roomTypeID]?.dormOrPrivate;

    // return roomTypes?.[roomTypeID]?.dormOrPrivate === 'dorm' ?
    //   (
    //     bedName.toLowerCase().includes(roomName.toLowerCase()) ?
    //       bedName :
    //       bedName + '(' + roomName + ')'
    //   )
    //   : roomName;

    return getAccommodationName({ dormOrPrivate, roomName, bedName }, '${bedName}(${roomName})');
  }

  composeErrorRoomSelection = (error) => {
    let msg = 'More than one guest is occupying ';
    error.forEach(e => {
      msg = msg + (this.getRoomName(e.bedID, e.roomID, e.roomTypeID)) + ' on ' + fetchDateFormat(e.date, 'MMM DD') + ', ';
    })
    return msg.slice(0, -1) + ' Please ensure they are on different beds.';
  }

  // Go next step
  handleNext = (currentStep) => {
    let hasError = false, errorMessage = '', newButtonText = null;
    let { buttonText } = this.state;
    let data = { ...this.state.data };

    switch (currentStep) {
      case 1:
        let reservation = this.basic.current.handleProcessData();
        hasError = !deepSameKeys(reservationFormData.reservation, reservation)
        newButtonText = this.props.t('actions.next');
        data = { ...data, ...reservation };
        if (reservation.nights < 1) hasError = true;
        if (hasError) errorMessage = this.props.t('newReservation.incompleteError')
        if (reservation.nights < 0) errorMessage = this.props.t('newReservation.incorrectCheckoutError')
        if (!hasError) {
          this.fetchRoomTypeAvailability({ startDate: reservation.checkIn, endDate: reservation.checkOut });
        }
        break;
      case 2:
        let typedata = this.type.current.handleProcessData();
        newButtonText = this.props.t('actions.proceed');
        data = { ...data, ...typedata };
        if (!Object.keys(data.typeID).length) hasError = true;
        if (hasError) errorMessage = this.props.t('newReservation.selectRoomTypeError');
        else this.fetchSuggestions(data);
        break;
      case 3:
        let blockData = this.bed.current.handleProcessData();
        newButtonText = this.props.t('actions.finish');
        if (!blockData.calendarOptions) hasError = true;
        if (hasError) errorMessage = this.props.t('newReservation.selectRoomError');
        if (blockData?.roomSelectionError?.length) {
          hasError = true;
          errorMessage = this.composeErrorRoomSelection(blockData.roomSelectionError);
        }

        data = { ...data, ...blockData };
        break;
      case 4:
        let guestInfo = this.guest.current.handleProcessData();
        let customers = [...guestInfo.customers];
        data = { ...data, customers };
        if (!guestInfo.customers.length || (guestInfo.totalGuest !== customers.length)) hasError = true;
        if (hasError) errorMessage = this.props.t('newReservation.enterGuestDetails');
        else this.handleSubmit(data);
        break;
      default: ;
    }
    if (hasError) {
      this.dialogContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (currentStep !== 4) {
      currentStep++;
      buttonText = newButtonText || buttonText;
      this.handleBuildBreadcrumb(data, currentStep)
    }
    this.setState({ currentStep, prevStep: currentStep - 1, data, hasError, errorMessage, buttonText })
  }

  // Go previous steps
  handlePrev = (currentStep) => {
    let { buttonText } = this.state;
    switch (currentStep) {
      case 1: buttonText = this.props.t('actions.next'); break;
      case 2: buttonText = this.props.t('actions.next'); break;
      case 3: buttonText = this.props.t('actions.proceed'); break;
    }
    if (this.state.filteredSuggest.length && currentStep < 3) {
      this.props.dispatch(resetRoomSuggestion());
      this.props.dispatch(resetReservationManualOptions())
    }
    this.handleBuildBreadcrumb(this.state.data, currentStep)
    this.setState({ currentStep, prevStep: currentStep + 1, buttonText, hasError: false, errorMessage: '' });
  }

  // create a breadcrumb
  handleBuildBreadcrumb = (data, step) => {
    data = { ...data };
    let breadcrumbList = { ...this.state.breadcrumbList };
    const currentSpace = { ...this.props.currentSpace };
    const currencySymbol = fetchCurrencyFormat(currentSpace.currency);
    let dateText = data.checkIn || null,
      nightsText = data.nights || null,
      guestCount = data.guestCount || null,
      hasShared = data.hasShared || false,
      hasPrivate = data.hasPrivate || false,
      total = getAmtFormat(data.total, currencySymbol);

    const removeNextKeys = (list, num) => {
      let keys = Object.keys(list);
      keys.forEach(key => { if (key > num && list[key]) delete list[key]; });
      return list
    }

    switch (step) {
      case 1:
        breadcrumbList = {};
        break;
      case 2:
        breadcrumbList = {};
        breadcrumbList[1] = { step: step - 1, text: dateText ? fetchDateFormat(dateText, DATE_FORMATS.DATE_MONTH) : null, icon: IMAGES.ICONS.darkCalendar }
        breadcrumbList[2] = { step: step - 1, text: `${nightsText} ${pluralize('nights', nightsText)}`, icon: IMAGES.ICONS.darkSleep }
        break;
      case 3:
        breadcrumbList[3] = { step: step - 1, text: guestCount, icon: IMAGES.ICONS.darkBoyBroadSmile }
        if (hasShared) breadcrumbList[4] = { step: step - 1, text: this.props.t('newReservation.selectRoomTypes.shared'), icon: IMAGES.ICONS.darkHostel }
        if (hasPrivate) breadcrumbList[5] = { step: step - 1, text: this.props.t('newReservation.selectRoomTypes.private'), icon: IMAGES.ICONS.darkBed }
        breadcrumbList = removeNextKeys(breadcrumbList, 5);
        break;
      case 4:
        breadcrumbList[6] = { step: step - 1, text: `${this.props.t('newReservation.roomSelection.totalAmount')} ` + total, icon: null, final: true }
        break;
    }
    this.dialogContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.setState({ breadcrumbList });
  }

  //  submit form
  handleSubmit = (data) => {
    const { selectionType } = this.state;

    let booking = {
      method: selectionType,
      reservation: {
        propertyID: data.propertyID,
        bookingSource: data.bookingSource,
        checkIn: data.checkIn,
        checkOut: data.checkOut,
        guestCount: data.guestCount,
        // ratePlanIDs: [ ...data.ratePlanIDs ]
      },
      calendarOptions: data.calendarOptions,
      customers: data.customers
    }

    if (selectionType === RESERVATION_KIND.automatic) {
      booking.reservation.ratePlanIDs = [...data.ratePlanIDs];
    }

    this.props.dispatch(
      getReservations(this.props?.propertyID, data.checkIn, data.checkOut, true)
    );

    const newRes = data.calendarOptions
      .filter((cd) => cd.reservationID !== null && data.checkIn === cd.date)
      .map((cd) => {
        const rate = cd.rates && cd.rates[0] && cd.rates[0]?.amount;
        return {
          ...cd,
          rate 
        };
      });

    const firstNewRes = newRes ? newRes[0] : {};

    const filterReservationByRoomID = Object.values(
      this.props.others.reservations
    )
      .filter(
        (resv) =>
          resv.roomID === firstNewRes.roomID && resv.checkIn === firstNewRes.date
      )
      .map((res) => {
        res.rates = firstNewRes.rates;
        return res;
      });

    if (
      filterReservationByRoomID.length > 0 &&
      filterReservationByRoomID.findIndex(
        (filtered) =>
          filtered.checkIn === firstNewRes.date &&
          filtered.roomID === firstNewRes.roomID
      ) > 0
    ) {
      let payload = {
        reservationID: filterReservationByRoomID[0]?.resID,
        addBedGuest: {
          checkIn: data.checkIn,
          checkOut: data.checkOut,
          guestCount: data.guestCount,
          calendarOptions: newRes,
          customers: data.customers,
        },
      };

      if (selectionType === RESERVATION_KIND.automatic) {
        payload.addBedGuest.ratePlanIDs = [...data.ratePlanIDs];
      }
      this.props.dispatch(
        editReservation(payload, data.propertyID, this.props.calendarData)
      );
      setTimeout(() => {
        this.props.handleCloseNewReservation();
        this.props.handleViewReservation(filterReservationByRoomID[0]?.resID);
      }, 2000);
    } else {
      this.props.dispatch(
        createReservation(booking, data.propertyID, this.props.calendarData)
      );
    }
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add reservation', 'eventLabel': 'Add Reservation' });
  }

  selectRatePlanHandler = data => {
    this.setState({
      selectedRatePlan: data.value
    })
  }

  getformattedPrices = (suggestions) => {
    const { data: { typeID } } = this.state;
    const { ratePlans } = this.props;
    let filteredPrices = {};
    let ratePlanOptions = [];

    let pricesArray = suggestions && suggestions.length > 0 ? [...suggestions[0].prices] : [];

    //Process rateplans....................
    pricesArray.map(data => {
      const ratePlanInfo = ratePlans[data.ratePlanID];

      if (ratePlanInfo?.isDeleted) return;
      const ratePlanName = ratePlanInfo.isDerived ? `${ratePlanInfo.privateName} (derived)` : (!ratePlanInfo.isDefault && !ratePlanInfo.isDerived) ? `${ratePlanInfo.privateName} (non-derived)` : `${ratePlanInfo.privateName}`;

      const updatedData = {
        ...data,
        isDerivedRatePlan: ratePlanInfo.isDerived,
        isDefaultRatePlan: ratePlanInfo.isDefault
      }

      filteredPrices[ratePlanName] = filteredPrices[ratePlanName] ?
        [...filteredPrices[ratePlanName], updatedData] : [updatedData];

    });

    const selectedRoomTypeCount = Object.keys(typeID).filter(roomTypeID => typeID[roomTypeID] > 0).length;

    Object.keys(filteredPrices).map(ratePlanName => {

      if (filteredPrices[ratePlanName].length < selectedRoomTypeCount) {
        delete filteredPrices[ratePlanName];
        return;
      };

      const { isDerivedRatePlan = false, isDefaultRatePlan = false } = filteredPrices?.[ratePlanName]?.[0];

      ratePlanOptions.push({
        value: ratePlanName,
        label: ratePlanName,
        isDerived: isDerivedRatePlan,
        isDefault: isDefaultRatePlan
      })
    });

    ratePlanOptions.sort((a, b) => {
      const comp1 = a.isDefault ? 1 : a.isDerived ? 3 : 2;
      const comp2 = b.isDefault ? 1 : b.isDerived ? 3 : 2;
      return comp1 - comp2;
    });

    return {
      filteredPrices: { ...filteredPrices },
      ratePlanOptions: [...ratePlanOptions]
    }
  }

  // Check if atleast a single object of calendarOption has NEW_RES
  handleProcessSuggestions = (suggestions) => {
    let filteredSuggest = [];

    const { filteredPrices, ratePlanOptions } = this.getformattedPrices(suggestions);

    suggestions.forEach(data => {
      let suggest = { ...data };

      if (suggest && suggest.calendarOptions) {
        let calendarOptions = [...suggest.calendarOptions];
        if (calendarOptions.find(option => option.guest && option.reservationID === "NEW_RES")) {
          filteredSuggest.push({ ...data, prices: { ...filteredPrices } })
        }
      }
    });

    this.setState({
      filteredSuggest,
      ratePlanOptions,
      selectedRatePlan: ratePlanOptions.length > 0 ? ratePlanOptions[0].value : ''
    });
  }

  handleSelectionType = (event, selectionType) => {
    if (selectionType)
      this.setState({ selectionType });
  };

  handleCloseSnackBar = () => {
    this.props.dispatch(resetError('ADD_RESERVATION'))
  }

  createBookingSource = (value) => {
    this.props.dispatch(createBookingSource({ propertyID: this.props.currentSpace.propertyID, name: value }, this.props.currentSpace._id))
  }

  setDrawerState(drawerState) {
    const { open, customers, searchInProgress } = drawerState
    this.setState({
      drawerState: {
        open: isUndefined(open) ? this.state.drawerState.open : open,
        customers: isUndefined(customers) ? this.state.drawerState.customers : customers,
        searchInProgress: isUndefined(searchInProgress) ? this.state.drawerState.searchInProgress : searchInProgress
      }
    })
  }

  render() {
    const { classes, t } = this.props;
    const { currentStep, prevStep, buttonText, hasError, errorMessage, selectionType, availability, ratePlanOptions, selectedRatePlan } = this.state;

    const STEPS = {
      1: t('newReservation.newBooking'),
      2: t('newReservation.selectRoomType'),
      3: t('newReservation.selectRoom'),
      4: t('newReservation.guestDetails'),
    }

    const data = { ...this.state.data };
    const currentSpace = { ...this.props.currentSpace };
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleCloseNewReservation()}
        maxWidth="md"
        fullWidth={true}
        fullScreen={window.innerWidth < 901}>
        <div className={classes.root}>
          {currentStep !== 3 ?
            (<CloseIcon className={classes.closeIcon} onClick={() => this.props.handleCloseNewReservation()} />) :

            (<div className={classes.relativePosition}>
              <ToggleButtonGroup exclusive value={selectionType} onChange={this.handleSelectionType} className={classes.selectionType}>
                <ToggleButton value="automatic" aria-label="automatic">{t('newReservation.automatic')}</ToggleButton>
                <ToggleButton value="manual" aria-label="manual">{t('newReservation.manual')}</ToggleButton>
              </ToggleButtonGroup>
            </div>)
          }

          <DialogContent>
            <div ref={node => { this.dialogContent = node; }}>
              <DialogContent className={classes.dialogContent}>

                {/* ------------ Navigating back to previous screen and show title ------------ */}
                <Navigation step={currentStep} title={STEPS[currentStep]} onChangePrev={this.handlePrev} />
                {hasError && (<div className={classes.error}>{errorMessage}</div>)}
                {currentStep > 1 && (<><div className={classes.space}></div>
                  <Breadcrumb
                    list={this.state.breadcrumbList}
                    ratePlanOptions={ratePlanOptions}
                    currentStep={currentStep}
                    onChangePrev={this.handlePrev}
                    divider={true}
                    selectedRatePlan={selectedRatePlan}
                    selectRatePlanHandler={this.selectRatePlanHandler}
                    selectionType={selectionType}
                    displayOption={selectionType == 'automatic' && currentStep == 3}
                  />
                </>)}

                {/* ------------ Create Reservation form information ------------ */}
                {currentStep === 1 &&
                  <BasicDetails
                    ref={this.basic}
                    currentSpace={currentSpace}
                    bookingSource={this.props.bookingSource}
                    createBookingSource={this.createBookingSource}
                    prefilledData={data}
                  />}

                {currentStep === 2 &&
                  <SelectRoomTypes
                    ref={this.type}
                    currentSpace={currentSpace}
                    availability={availability}
                    availabilityMin={data.nights}
                    checkAvailability={true}
                    roomTypes={this.props.roomTypes}
                    multiple={true}
                    prefilledData={data.typeID}
                  />}

                {currentStep === 3 &&
                  (selectionType === "automatic" ?
                    <AutomaticSelection
                      ref={this.bed}
                      currentSpace={currentSpace}
                      guestCount={data.guestCount}
                      date={data.checkIn}
                      nights={data.nights}
                      suggestions={this.state.filteredSuggest}
                      loading={this.props.loading}
                      currentStep={currentStep}
                      onChangePrev={this.handlePrev}
                      selectedRatePlan={selectedRatePlan}
                    /> :
                    <ManualSelection
                      ref={this.bed}
                      options={this.props.options}
                      currentSpace={currentSpace}
                      guestCount={data.guestCount}
                      data={data.typeID}
                      date={data.checkIn}
                      nights={data.nights}
                      loading={this.props.loading}
                      currentStep={currentStep}
                      prevStep={prevStep}
                      roomTypes={this.props.roomTypes}
                      rooms={this.props.rooms}
                      beds={this.props.beds}
                      taxes={this.props.taxes}
                      manualDefault={data.manualDefaultData}
                      onChangePrev={this.handlePrev} />
                  )
                }

                {currentStep === 4 &&
                  <GuestList
                    ref={this.guest}
                    currentSpace={currentSpace}
                    guestCount={data.guestCount}
                    viewMasterBooker={true}
                    setDrawerState={this.setDrawerState.bind(this)}
                  />}

              </DialogContent>
            </div>
          </DialogContent>

          {/* ------------ Action Button ------------ */}
          <DialogActions className={classes.dialogAction}>
            <Button
              className={clsx({
                [classes.Button]: true,
                [classes.fullWidth]: currentStep === 3
              })}
              onClick={() => this.handleNext(currentStep)}>
              {buttonText}
            </Button>
          </DialogActions>

          {/* -------------- Loader --------------- */}
          {this.props.loading &&
            <div className={"loadingOverlay"}>
              <CircularProgress className={"loading"} />
            </div>
          }
          {/* ---------- Error message ------------ */}
          {this.props.errors && (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.messageopen}
              onClose={() => this.handleCloseSnackBar()}
              autoHideDuration={6000}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.props.errors}</span>}
            />
          )}
        </div>
        {this.state.drawerState.open && <Drawer
          open={this.state.drawerState.open}
          variant='persistent'
          anchor='right'
          PaperProps={{
            style: {
              position: 'absolute'
            },
            className: classes.drawerPaper
          }}
        >
          <CloseIcon className={classes.closeIcon} onClick={() => this.setDrawerState({ open: false })} />
          <h3 className={classes.heading}>Tag Customer</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }} >
            {this.state.drawerState.searchInProgress ? (
              <CircularProgress />
            )
              : this.state.drawerState.customers.length < 1 ? 'No records found'
                : this.state.drawerState.customers.map(({ data, onClick }) => {
                  const countryName = i18nIsoCountries.getName(data.nationality, "en") || data.nationality
                  const avatar = data.image || getAvatarFlag(countryName)
                  return <div style={{ width: '220px', margin: '10px' }} key={data._id} onClick={onClick}>
                    <GuestTile
                      guestName={humanise(data.firstName + ' ' + data.lastName)}
                      status={data.email}
                      phone={data.phone}
                      avatar={avatar}
                      isCustomerCard
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    />
                  </div>
                })}
          </div>
        </Drawer>}
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  suggestions: state.suggestions || [],
  options: state.options || [],
  taxes: state.taxes,
  rooms: state.rooms,
  beds: state.beds,
  bookingSource: state.bookingSource,
  reservationCreated: state.success.ADD_RESERVATION,
  loading: state.loading.ADD_RESERVATION || state.loading.LOAD_ROOM_SUGGESTION,
  errors: state.errors.ADD_RESERVATION,
  ratePlans: state.ratePlans,
  propertyID: state.property._id,
  others: state
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NewReservation)));
