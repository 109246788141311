import http from '../../utils/http';
import { normalize } from 'normalizr';
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentModule, setCurrentSpace, setCurrentSpaceType, setIframe } from "../dashboard/dashboard";
import {loadNotifications} from "../notifications/notifications";
import {loadMessages} from '../messages/messages';
import { setCurrentUser } from '../auth/auth';
import { setLanguage } from '../UIsettings/userSettings';
import { MENU } from '../../../utils/layout';
import { userSchema } from "../../schema/userSchema";

//----------------------------------------------------------------
// ADD USER
//----------------------------------------------------------------
export function addUser(space, user, history) {
  const ACTION_NAME = "CREATE_USER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/register`, user)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data)
        localStorage.setItem("jwtToken", data.token);
        setAuthToken(data.token);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
        if(space && (space.onboardingStatus !== 'plus' && space.onboardingStatus !== 'pro'))
          dispatch(addAdminToSpace(space._id, data.user._id, history, true));
        else{
          dispatch(addUserToSpace(space._id, data.user._id, history))
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// ADD USER FOR HW EXPERIENCES
//----------------------------------------------------------------
export function addHwExperiencesUser(space, user, userRole) {
  const ACTION_NAME = "CREATE_EXPERIENCES_USER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/register`, user)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data)
        localStorage.setItem("jwtToken", data.token);
        setAuthToken(data.token);
        if (space.admins.length===0 || userRole==='manager') {
          // console.log('adding admin');
          dispatch(addAdminToSpace(space._id, data.user._id, null, true));
        } else {
          dispatch(addUserToSpace(space._id, data.user._id, null, true));
        }
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {user: data.user, space}});
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// GET HW USER
//----------------------------------------------------------------
export function getHWUser(userEmail, userRole, propertyNumber) {
  const ACTION_NAME = "GET_HW_USER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/hwInbox/signup", {userEmail, propertyNumber, userRole})
      .then(successResult => {
        const data = successResult.data;
        // console.log('here', data);
        dispatch(setCurrentSpace(data.space._id));
        dispatch(setCurrentSpaceType(data.space.type));
        dispatch(setCurrentModule(MENU.experience.id));
        dispatch(setIframe(true));
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { space: data.space}});
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}

//----------------------------------------------------------------
// SIMPLE LOGIN
//----------------------------------------------------------------
export const loginAndConnect = (userData, space, history) => dispatch => {
  const ACTION_NAME = "LOGIN";
  dispatch({ type: `${ACTION_NAME}_REQUEST` });
  http
    .post("/api/users/login", userData)
    .then(res => {
      // console.log(res.data)
      
      // Set token to localStorage
      const { token, user } = res.data;
      localStorage.setItem("jwtToken", token);
      
      // Set token to Auth header
      setAuthToken(token);

      // Set current user
      dispatch({ type: `${ACTION_NAME}_SUCCESS` });
      dispatch(setCurrentUser(user));
      //dispatch(addUserToSpace(spaceID, user._id, history))
      if(space && (space.onboardingStatus !== 'plus' && space.onboardingStatus !== 'pro'))
        dispatch(addAdminToSpace(space._id, user._id, history, true));
      else{
        dispatch(addUserToSpace(space._id, user._id, history))
      }

    })
    .catch(errorResult => {
        // console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      }
    );
};

//----------------------------------------------------------------
// ADD USER
//----------------------------------------------------------------
export function addInvitedUser(space, user, inviteToken, history) {
  const ACTION_NAME = "CREATE_USER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/users/claimProfile/${inviteToken}`, user)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data)
        localStorage.setItem("jwtToken", data.token);
        setAuthToken(data.token);
        dispatch(setCurrentUser(data.user));
        dispatch(setCurrentSpace(space._id));
        dispatch(setCurrentSpaceType(space.type));
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
        history.push('/');
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// ADD USER TO SPACE
//----------------------------------------------------------------
export function addUserToSpace(spaceID, userID, history, experienceFlow) {
  const ACTION_NAME = "ADD_USER_TO_SPACE";
  let spaceData = {members: [userID]};
  if (experienceFlow) {
    spaceData.hwExpFlag = 'imported'
  }
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/space/${spaceID}`, spaceData)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch(setCurrentSpace(data._id));
        dispatch(setCurrentSpaceType(data.type));
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {space: data, userID} });
        if (history) history.push('/');
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// ADD ADMIN TO SPACE
//----------------------------------------------------------------
export function addAdminToSpace(spaceID, userID, history, experienceFlow) {
  const ACTION_NAME = "ADD_USER_TO_SPACE";
  let spaceData = {admins: [userID]};
  if (experienceFlow) {
    spaceData.hwExpFlag = 'imported'
  }
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/space/${spaceID}`, spaceData)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch(setCurrentSpace(data._id));
        dispatch(setCurrentSpaceType(data.type));
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {space: data, userID} });
        if (history) history.push('/');
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// UPDATE USER AVATAR
//----------------------------------------------------------------
export const updateAvatar = (userID, avatar = '') => ({
  type: 'UPDATE_USER_AVATAR',
  payload: { userID, avatar: avatar }
})


//----------------------------------------------------------------
// UPDATE PASSWORD
//----------------------------------------------------------------
export function updatePassword(values) {
  const ACTION_NAME = "UPDATE_PASSWORD";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/users/password`, {...values})
      .then(successResult => {
        // console.log(successResult)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET PASSWORD
//----------------------------------------------------------------
export function resetPassword(userID, password, token, history) {
  const ACTION_NAME = "RESET_PASSWORD";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/resetPassword/${token}`, {userID, password})
      .then(successResult => {
        const data = successResult.data;
        // console.log('reset success', data);
        localStorage.setItem("jwtToken", data.token);
        setAuthToken(data.token);
        dispatch(setCurrentUser(data.user));
        dispatch(setCurrentSpace(data.user.space[0]));
        dispatch(loadNotifications());
        dispatch(loadMessages());
        history.push("/");
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT USER PROFILE
//----------------------------------------------------------------
export function editUser(values) {
  const ACTION_NAME = "EDIT_USER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/users/me`, {...values})
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// UPLOAD USER AVATAR
//----------------------------------------------------------------
export function uploadUserAvatar(userID, file) {
  const ACTION_NAME = "UPDATE_USER_AVATAR";
 
  const formData = new FormData();
  formData.append('avatar',file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/users/upload`, formData, config)
      .then(response => {
        console.log("{ userID, avatar: `${response.data}?${Date.now()}` }", { userID, avatar: `${response.data.avatar}?${Date.now()}` })
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { userID, avatar: `${response.data.avatar}?${Date.now()}` }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

export const saveLanguageSetting = data =>{
  const ACTION_NAME = "SAVE_LANGUAGE_SETTINGS";
  return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http.post(`/api/UIsettings/user`, data)
          .then(successResult => {

            const configData = { 
              spaceID: data.spaceID,
              language: data.language
            }

            dispatch(setLanguage(configData));

            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return true;
          })
          .catch(errorResult => {
              console.log("errorResult", errorResult.response);
              let error =
                errorResult && errorResult.response && errorResult.response.data
                  ? errorResult.response.data
                  : errorResult;
              dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
              return;
          });
  }
}

//----------------------------------------------------------------
// CHANGE USER ROLE
//----------------------------------------------------------------
export function changeUserRole(userID, spaceID, userType) {
  const ACTION_NAME = "CHANGE_USER_ROLE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/changeRole`, { userID, spaceID, userType })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID, space: data}});
      })
      .catch(errorResult => {
        //console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//-----------------------------------------------------------------
// ADD USER TO A SPACE AS SYSTEM ADMIN
//-----------------------------------------------------------------
export function adminUserAdd(body) {
  const ACTION_NAME = "ADMIN_USER_ADD";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/admin/user/add`, body)
      .then(successResult => {
        const data = successResult.data
        var normalizedOrder = normalize([data.user], [userSchema])
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID: body.spaceID, userID: data.userID, type: data.type, data: normalizedOrder.entities.user }})
        return successResult
      })
      .catch(errorResult => {
        const error = errorResult.response
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
        return error.data
      });
  };
}

//-----------------------------------------------------------------
// REMOVE USER FROM A SPACE AS SYSTEM ADMIN
//-----------------------------------------------------------------
export function adminUserRemove(body) {
  const ACTION_NAME = "ADMIN_USER_REMOVE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/admin/user/remove`, body)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID: body.spaceID, userID: data.userID, type: data.type }})
        return successResult
      })
      .catch(errorResult => {
        const error = errorResult.response
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
        return error.data
      });
  };
}