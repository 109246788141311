
const validateTextForHTMLInjection = (text) => {
    var re = /<[a-z][\s\S]*>/i;
    return re.test(text)
}

const validateEmail = (email) => {
    var re = /^[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*@[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*(\.[a-zA-Z]{2,})$/;
    return re.test(String(email).toLowerCase());
};

module.exports = {
    validateTextForHTMLInjection,
    validateEmail
}