import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { Checkbox } from '@material-ui/core';
import { fetchCurrencyFormat } from '../../../utils/utility';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0 3px 4px 0 #ECECEC',
    borderRadius: 5,
    padding: 15,
    marginBottom: 5,
    '& input[type=checkbox]': {
      zIndex: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#333333',
    fontWeight: 600,
    marginTop: 15,
  },
  description: {
    fontSize: '1.2rem',
    color: '#999999',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 12
    }
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  fieldContainer: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 1px 20px #F2F2F2',
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'calc(100% - 32px)',
  },
  currency: {
    color: '#999999',
    marginRight: 15,
  },
  days: {
    color: '#999999',
    marginLeft: 15,
  },
  field: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: 35,
  },
  selected: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
}))

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#000000',
    },
  },
  checked: {},
 })

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const RowItem = (props) => {
  const classes = useStyles();
  const { id, value, label, description, type, status, handleStatusChange, handleValueChange, ratePlan } = props;

  const handleSwitchOff = () => {
    handleValueChange(id, false)
  }

  const handleSwitchOn = () => {
    handleValueChange(id, true)
  }

  const handleChange = (e) => {
    handleValueChange(e.target.name, e.target.value)
  }

  return(
    <div className={classes.root}>

      <Grid container>
        <Grid item sm={1} xs={2}>
          <CustomCheckbox 
            checked={status}
            onChange={() => handleStatusChange(id, !status)} 
          />
        </Grid>
        <Grid item sm={8} xs={4}>
          <div className={classes.title}>{label}</div>
        </Grid>
        <Grid item sm={3} xs={6}>
        {type!=='toggle' && 
            <div className={classes.fieldContainer}>
                {type==='currency' && 
                  <div className={classes.currency}>
                    {fetchCurrencyFormat(ratePlan?.multiCurrency?.currency || props?.currentSpace?.currency || null)}
                  </div>
                }
                <div className={classes.field}>
                  <InputBase 
                    id={id}
                    name={id}
                    fullWidth
                    type="text"
                    defaultValue={value}
                    onChange={handleChange}
                  />
                </div>
                {type==='days' && <div className={classes.days}>days</div>}
              </div>
            }

            {type==='toggle' && <div className={classes.toggle}>
              <div 
                className={clsx(classes.toggleButton, classes.left)} 
                style={{
                  background: value === false ? '#E0E0E0' : !value ? '#FFFFFF' : '#F2F2F2',
                  color: value === false ? '#000000' : '#BDBDBD',
                }}
                onClick={handleSwitchOff}
              >
                OFF
              </div>
              <div 
                style={{background: !value ? '#FFFFFF' : '#4F9581'}}
                className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: (value)})}
                onClick={handleSwitchOn}
              >
                ON
              </div>
            </div>}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={1} xs={false}></Grid>
        <Grid item sm={8} xs={12}>
          <div className={classes.description}>{description}</div>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
      </Grid>

    </div>
  )
}

export default RowItem;