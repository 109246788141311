import http from "../../utils/http";
import { normalize } from 'normalizr';
import { announcementSchema } from '../../schema/announcementSchema';

//----------------------------------------------------------------
// LOAD ANNOUNCEMENTS
//----------------------------------------------------------------
export function loadAnnouncements(spaceID) {
  const ACTION_NAME = "LOAD_ANNOUNCEMENTS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/announcements/${spaceID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ announcementSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// ADD ANNOUNCEMENT
//----------------------------------------------------------------
export function addAnnouncement(form) {
  const ACTION_NAME = "ADD_ANNOUNCEMENT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/announcement`, form)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// SUBMIT ANNOUNCEMENT COMMENT
//----------------------------------------------------------------
export function submitAnnouncementComment(announcementID, comment) {
  const ACTION_NAME = "ADD_ANNOUNCEMENT_COMMENT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/announcement/comments/${announcementID}`, {comments: comment})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data, announcementID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
