import { isEmpty } from 'lodash'; 

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case "USER_LOADING":
      return {
        ...state,
        loading: true
      };
    case "CREATE_USER_SUCCESS":
      return {
        isAuthenticated: true,
        user: action.payload.user
      }
    case "CREATE_EXPERIENCES_USER_SUCCESS":
      return {
        isAuthenticated: true,
        user: action.payload.user
      }
    case "CREATE_SPACE_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          space: [
            ...state.user.space,
            action.payload._id
          ]
        }
      }
    case "EDIT_USER_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case "UPDATE_USER_AVATAR_SUCCESS":
      return {
        ...state,
        user: {...state.user, avatar: action.payload.avatar}
      }
    case "GET_INVITE_LINK_SUCCESS":
      return {
        ...state,
        inviteLink: action.payload.data
      }
    case "RESET_INVITE_LINK":
      return {
        ...state,
        inviteLink: ''
      }
    case "ADD_USER_TO_SPACE_SUCCESS":
      const spaceList = state.user.space;
      return {
        ...state,
        user: {
          ...state.user,
          space: [
            ...spaceList,
            action.payload.space._id
          ]
        }
      }
    case "REMOVE_SPACE_SUCCESS":
      return {
        ...state,
        user: action.payload
      }
    case "ADD_SPACE_SUCCESS":
      return {
        ...state,
        user: action.payload
      }
    case "SET_HW_USER":
      return {
        ...state,
        user: action.payload
      }
    default:
      return state;
  }
}