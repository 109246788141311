import React from "react";
import { connect } from "react-redux";
import pluralize from "pluralize";

import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  roomTypeContainer: {
    background: "#ffffff",
    borderRadius: "15px",
    padding: theme.spacing(2),
  },
  image: {
    borderRadius: 10,
    height: 50,
    objectFit: "cover",
    width: 50,
  },
  descContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.3rem",
    marginBottom: 8,
  },
  subTitle: {
    color: "#666666",
    fontSize: "1.1rem",
  },
}));

const RoomTypeList = (props) => {
  const classes = useStyles();
  const { currentSpace, roomTypes } = props;

  return (
    <Grid container spacing={3} className={classes.root}>
      {currentSpace.roomTypes &&
        currentSpace.roomTypes.map((roomTypeID) => {
          let roomTypeData = { ...roomTypes[roomTypeID] };
          let typeImageLink =
            roomTypeData.images && roomTypeData.images.length
              ? roomTypeData.images[0].imagelink
              : null;
          return (
            <Grid item xs={12} sm={6} lg={4} key={roomTypeID}>
              <Grid
                container
                justifyContent="center"
                className={classes.roomTypeContainer}
              >
                <Grid item xs={3}>
                  {typeImageLink ? (
                    <img src={typeImageLink} className={classes.image} />
                  ) : roomTypeData.dormOrPrivate === "dorm" ? (
                    <img
                      src="/images/placeholder-shared.png"
                      className={classes.image}
                    />
                  ) : (
                    <img
                      src="/images/placeholder-private.png"
                      className={classes.image}
                    />
                  )}
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.descContainer}>
                    <div className={classes.label}>{roomTypeData.name}</div>
                    <div className={classes.subTitle}>
                      {roomTypeData.dormOrPrivate === "dorm"
                        ? "Shared"
                        : "Private"}
                      &nbsp;&#183;&nbsp;
                      {roomTypeData.numberOfUnits}{" "}
                      {pluralize("room", roomTypeData.numberOfUnits)}
                      &nbsp;&#183;&nbsp;
                      {roomTypeData.bedsPerRoom} guests per room
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
});

export default connect(mapStateToProps)(RoomTypeList);
