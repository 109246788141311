import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import DraggableCard from './DraggableCard';
import RoomTypeCard from './RoomTypeCard';
import RoomCard from './RoomCard';
import CustomDragLayer from './CustomDragLayer';
import { ItemTypes } from './ItemTypes';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { 
    fetchCalendarSortData, 
    updateCalenderSortOrder, 
    resetError, 
    getCalendarRenderData,
    setCalendarSortType,
    setCalendarDisplayData 
} from '../../../redux/actions/beds/calendar';

const styles = theme =>({
    root:{
        border: '1px solid',
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'relative',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    breakTag:{
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonContainer:{
        height: '150px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 28px',
        boxShadow: '0 1px 20px #F2F2F2'
    },
    button: {
        background: '#000000',
        borderRadius: 25,
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '1.4rem',
        fontWeight: 600,
        padding: '8px 60px',
        '&:hover':{
            background: '#000000',
            color: '#ffffff',
        }
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        }
    },
    listContainer:{
        overflowY: 'auto',
        minHeight: 'calc(100vh - 230px)'
    },
    listSection: {
        margin: theme.spacing(3),
        [theme.breakpoints.up("sm")]:{
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(7),
        }
    },
    secondaryTitle: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#525252',
        marginBottom: '5px'
    },
    tabContainer:{
    },
    tabButtonContainer:{
        padding: '10px 0px',
        display: 'flex',
        '& $tabButton:first-child':{
            marginLeft: '0px !important',
        },
        '& $tabButton:last-child':{
            marginRight: '0px !important',
        }
    },
    tabButton:{
        boxShadow: '0 1px 10px #E0E0E0',
        width: '100%',
        margin: '0px 10px',
        borderRadius: 25,
        cursor: 'pointer',
        fontSize: '1.3rem',
        fontWeight: 600,
        color: '#525252',
        textAlign: 'center',
        padding: '10px',
        '&.active':{
            backgroundColor: '#c67d5a',
            color:' #fff',
        }
    },

    mainContainerBody:{
        margin: '15px 0px'
    },

    loadingState: {
        width: '100%',
        height: 'calc(100vh - 65px)',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    buildingError:{
        width: '100%',
        textAlign: 'center',
        marginTop: '100px',
        fontSize: '1.4rem'

    }
});

const FILTERS = {
    roomType: { id: 1, type: 'Room Type', value: 'roomType' },
    room: { id: 2, type: 'Room', value: 'room' },
    building: { id: 3, type: 'Building', value: 'building' }
};

class CalendarSort extends React.PureComponent {

    state = {
        activeFilter: FILTERS.roomType,
        roomTypeFilter: [],
        roomFilter: [],
        buildingFilter: [],
        buildingAssignedError: false 
    }

    constructor(props) {
        super(props)
        this.drawFrame = () => {
          const nextState = this.updateCardList(this.pendingUpdateFn);
          this.setState(nextState);
          this.pendingUpdateFn = undefined
          this.requestedFrame = undefined
        }

        this.moveCard = (hoveredCardID, draggedCardID, typeData) => {
            const { roomTypeFilter, roomFilter, buildingFilter } = this.state;

            const cardIndexData = {};
            let rooms = null;

            switch(typeData.type){

                case ItemTypes.ROOMTYPE:
                    cardIndexData.dragIndex = roomTypeFilter.findIndex(data => data.roomTypeId == draggedCardID);
                    cardIndexData.hoverIndex = roomTypeFilter.findIndex(data => data.roomTypeId == hoveredCardID);
                    break;

                case ItemTypes.NESTED_ROOMTYPE_ROOM:
                    rooms = roomTypeFilter[typeData.roomTypeIndex].rooms;
                    cardIndexData.dragIndex = rooms.indexOf(draggedCardID);
                    cardIndexData.hoverIndex = rooms.indexOf(hoveredCardID);
                    break;

                case ItemTypes.ROOM:
                    cardIndexData.dragIndex = roomFilter.indexOf(draggedCardID);
                    cardIndexData.hoverIndex = roomFilter.indexOf(hoveredCardID);
                    break;

                case ItemTypes.BUILDING:
                    cardIndexData.dragIndex = buildingFilter.findIndex(data => data.buildingId == draggedCardID);
                    cardIndexData.hoverIndex = buildingFilter.findIndex(data => data.buildingId == hoveredCardID);
                    break;
                
                case ItemTypes.NESTED_BUILDING_ROOM:
                    rooms = buildingFilter[typeData.buildingIndex].rooms;
                    cardIndexData.dragIndex = rooms.indexOf(draggedCardID);
                    cardIndexData.hoverIndex = rooms.indexOf(hoveredCardID);
                    break;
            }

            this.scheduleUpdate({
                cardIndexData,
                typeData
            })
        }
    }

    getCalendarSortData = (filterType) =>{
        const { currentSpace, dispatch } = this.props;
        const response = dispatch(fetchCalendarSortData(currentSpace.propertyID, filterType));
        response.then(success =>{
    
            switch(filterType){

                case FILTERS.roomType.value:
                    this.setState({ 
                        activeFilter: FILTERS.roomType,
                        roomTypeFilter: [...success.filters ]
                    });
                    break;

                case FILTERS.room.value:
                    this.setState({ 
                        activeFilter: FILTERS.room,
                        roomFilter: [...success.filters ]
                    });
                    break;

                case FILTERS.building.value:
                    this.setState({ 
                        activeFilter: FILTERS.building,
                        buildingFilter: [...success.filters ],
                        buildingAssignedError: success.noBuildingAssignedError
                    });
                    break;

                default:
                    break;
            }
        });
    }

    componentDidMount(){
        const { currentSpace } = this.props;
        const { uiSetting } = currentSpace;
        const filterType = (uiSetting && uiSetting.calendarSort) ? 
            uiSetting.calendarSort : 
            FILTERS.roomType.value;
        this.getCalendarSortData(filterType);
    }

    updateArray = (data, { dragIndex, hoverIndex }) =>{
        let tempList = [ ...data ];
        const draggedCardData = data[dragIndex];
        tempList.splice(dragIndex, 1);
        tempList.splice(hoverIndex, 0, draggedCardData);
        return [...tempList]
    }

    updateCardList = ({ cardIndexData, typeData}) =>{
        const { roomTypeFilter, roomFilter, buildingFilter } = this.state;
        let updatedArray = null;
        let rooms = null;

        switch(typeData.type){

            case ItemTypes.ROOMTYPE:
                updatedArray = this.updateArray(roomTypeFilter, cardIndexData);
                return { roomTypeFilter: [...updatedArray]};

            case ItemTypes.NESTED_ROOMTYPE_ROOM:
                rooms = roomTypeFilter[typeData.roomTypeIndex].rooms;
                updatedArray = this.updateArray(rooms, cardIndexData);
                roomTypeFilter[typeData.roomTypeIndex].rooms = [...updatedArray];
                return { roomTypeFilter: [...roomTypeFilter]};

            case ItemTypes.ROOM:
                updatedArray = this.updateArray(roomFilter, cardIndexData);
                return { roomFilter: [...updatedArray]};

            case ItemTypes.BUILDING:
                updatedArray = this.updateArray(buildingFilter, cardIndexData);
                return { buildingFilter: [...updatedArray]};

            case ItemTypes.NESTED_BUILDING_ROOM:
                rooms = buildingFilter[typeData.buildingIndex].rooms;
                updatedArray = this.updateArray(rooms, cardIndexData);
                buildingFilter[typeData.buildingIndex].rooms = [...updatedArray];
                return { buildingFilter: [...buildingFilter]};

        }
    }

    scheduleUpdate(updateFn) {
        this.pendingUpdateFn = updateFn;
        if (!this.requestedFrame) {
          this.requestedFrame = requestAnimationFrame(this.drawFrame);
        }
    }

    componentWillUnmount() {
        if (this.requestedFrame !== undefined) {
            cancelAnimationFrame(this.requestedFrame)
        }
    }

    setActiveTab = selectedFilter =>{
        const { activeFilter } = this.state;
        if(activeFilter.id == selectedFilter.id) return;
        this.setState({ activeFilter: selectedFilter });
        this.getCalendarSortData(selectedFilter.value);
    }

    saveSortOrder = () =>{
        const { dispatch, currentSpace, closeModalHander, clearCalendarCache } = this.props;
        const { activeFilter, roomTypeFilter, roomFilter, buildingFilter } = this.state;

        const filterData = (activeFilter.id == FILTERS.roomType.id) ? roomTypeFilter : 
                        (activeFilter.id == FILTERS.room.id) ? roomFilter : buildingFilter;

        if(filterData.length == 0) return;

        const data = {
            filterType: activeFilter.value,
            filters: [ ...filterData ],
            spaceID: currentSpace._id
        }

        const response = dispatch(updateCalenderSortOrder(data));
        response.then(success =>{
            if(success) {
                const uiSetting = { ...currentSpace.uiSetting, calendarSort: activeFilter.value };
                dispatch(setCalendarSortType(currentSpace._id, uiSetting));
                clearCalendarCache();
                dispatch(setCalendarDisplayData(filterData));
                closeModalHander();
            }
        });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('FETCH_CALENDAR_SORT_DATA'));
        dispatch(resetError('UPDATE_CALENDER_SORT'));
        dispatch(resetError('LOAD_ROOMTYPES'));
    }

    render() {
        const { classes, open, closeModalHander, roomTypes, rooms, isLoading, errors, t } = this.props;
        const { activeFilter, roomTypeFilter, roomFilter, buildingFilter, buildingAssignedError } = this.state;
        return (
            <Dialog
                open={open}
                onClose={closeModalHander}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paperFullWidth: classes.root }}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={window.innerWidth < 901}
            >

                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{t('bookingCalendar.customiseSort')}</div>
                    <CloseIcon className={classes.closeIcon} onClick={closeModalHander} />
                </div>
                <div className={classes.listContainer}>
                    <div className={classes.listSection}>

                        {/* ..............Circular Loader...................... */}
                        {isLoading && (
                            <div className={classes.loadingState}>
                                <CircularProgress className={classes.loader} />
                            </div>
                        )}

                        <div className={classes.tabContainer}>
                            <div className={classes.secondaryTitle}>{t('bookingCalendar.sortPreference')}</div>
                            
                            {/* .....................Tab Buttons...................... */}
                            <div className={classes.tabButtonContainer}>
                                {Object.keys(FILTERS).map(filter =>(
                                    <div 
                                        key={`tab_${FILTERS[filter].id}`}
                                        onClick={() =>this.setActiveTab(FILTERS[filter])}
                                        className={clsx({ 
                                            [classes.tabButton]: true, 
                                            'active': activeFilter.id == FILTERS[filter].id 
                                        })}>
                                        {FILTERS[filter].type}
                                    </div>
                                ))}
                            </div>

                            {/* .....................Tab Body..........................*/}
                            <DndProvider backend={Backend}>

                                <CustomDragLayer />

                                <div className={classes.mainContainerBody}>

                                    {activeFilter.id == FILTERS.roomType.id && (
                                        <>
                                            {roomTypeFilter.map((roomType, roomTypeIndex) =>(
                                                <DraggableCard 
                                                    itemType = {ItemTypes.ROOMTYPE}
                                                    id={roomType.roomTypeId } 
                                                    cardIndex={roomTypeIndex}
                                                    key={`roomtype_key_${roomType.roomTypeId}`} 
                                                    moveCard={this.moveCard}
                                                    typeData={{
                                                        type: ItemTypes.ROOMTYPE
                                                    }}
                                                >
                                                    <RoomTypeCard name={roomTypes[roomType.roomTypeId] && roomTypes[roomType.roomTypeId].name || t('bookingCalendar.unnamedRoom')} >
                                                        {roomType.rooms.map((roomId, roomIndex)=>(
                                                            <DraggableCard 
                                                                itemType = {`${ItemTypes.NESTED_ROOMTYPE_ROOM}_${roomType.roomTypeId}`}
                                                                id={roomId} 
                                                                key={`room_key_${roomId}`} 
                                                                cardIndex={roomIndex}
                                                                moveCard={this.moveCard} 
                                                                typeData={{
                                                                    type: ItemTypes.NESTED_ROOMTYPE_ROOM,
                                                                    roomTypeIndex 
                                                                }}
                                                            >
                                                                <RoomCard 
                                                                    type = {ItemTypes.NESTED_ROOMTYPE_ROOM}
                                                                    roomName={rooms[roomId] && rooms[roomId].name || t('bookingCalendar.unnamedRoom')} 
                                                                />
                                                            </DraggableCard>
                                                        ))}
                                                    </RoomTypeCard>
                                                </DraggableCard>    
                                            ))}
                                        </>
                                    )}

                                    {activeFilter.id == FILTERS.room.id && (
                                        <>
                                            {roomFilter.map((roomId, roomIndex)=>(
                                                rooms[roomId] && (
                                                    <DraggableCard 
                                                        itemType = {ItemTypes.ROOMTYPE}
                                                        id={roomId} 
                                                        cardIndex={roomIndex}
                                                        key={`room_key_${roomId}`} 
                                                        moveCard={this.moveCard}
                                                        typeData={{
                                                            type: ItemTypes.ROOM
                                                        }}
                                                    >
                                                        <RoomCard roomName={rooms[roomId].name} />
                                                    </DraggableCard>
                                                )
                                            ))}
                                        </>
                                    )}

                                    {activeFilter.id == FILTERS.building.id && (
                                        <>
                                            {( buildingAssignedError || buildingFilter.length == 0 )
                                                && (<div className={classes.buildingError}>{t('bookingCalendar.mapError')}</div>)}

                                            {!buildingAssignedError && buildingFilter.map((building, buildingIndex) =>(
                                                <DraggableCard
                                                    itemType = {ItemTypes.BUILDING}
                                                    id={building.buildingId } 
                                                    cardIndex={buildingIndex}
                                                    key={`building_key_${building.buildingId}`} 
                                                    moveCard={this.moveCard}
                                                    typeData={{
                                                        type: ItemTypes.BUILDING
                                                    }}
                                                >
                                                    <RoomTypeCard name={ building.buildingName || t('bookingCalendar.unnamedRoom')} >
                                                        {building.rooms.map((roomId, roomIndex)=>(
                                                            rooms[roomId] && (
                                                                <DraggableCard 
                                                                    itemType = {`${ItemTypes.NESTED_BUILDING_ROOM}_${building.buildingId}`}
                                                                    id={roomId} 
                                                                    key={`room_key_${roomId}`} 
                                                                    cardIndex={roomIndex}
                                                                    moveCard={this.moveCard} 
                                                                    typeData={{
                                                                        type: ItemTypes.NESTED_BUILDING_ROOM,
                                                                        buildingIndex 
                                                                    }}
                                                                >
                                                                    <RoomCard 
                                                                        type = {ItemTypes.NESTED_BUILDING_ROOM}
                                                                        roomName={rooms[roomId].name} 
                                                                    />
                                                                </DraggableCard>
                                                            )
                                                        ))}
                                                    </RoomTypeCard>
                                                </DraggableCard>    
                                            ))}
                                        </>
                                    )}
                                </div>
                            </DndProvider>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} onClick={this.saveSortOrder}>{t('actions.save')}</Button>
                </div>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const { roomTypes, rooms, spaces, dashboard, loading, errors } = state;
    return {
        roomTypes,
        rooms,
        currentSpace: spaces[dashboard.currentSpace],
        isLoading: loading.FETCH_CALENDAR_SORT_DATA || 
            loading.UPDATE_CALENDER_SORT ||
            loading.LOAD_ROOMTYPES,
        errors: errors.FETCH_CALENDAR_SORT_DATA || 
            errors.UPDATE_CALENDER_SORT ||
            errors.LOAD_ROOMTYPES
    };
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(CalendarSort)));