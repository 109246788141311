import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { addTodoComment, addFileComment } from '../../redux/actions/comments/comments';
import { returnOtherUsers, returnCurrent } from "../../redux/selectors/dashboard";
import { submitAnnouncementComment } from '../../redux/actions/announcements/announcements';
import { submitAnswer } from '../../redux/actions/community/discussion'; 
import { mentionNotification } from '../../redux/actions/notifications/notifications';
import { initials } from '../utils/wordUtils';
import { getPropValues } from '../../utils/utility';

import Avatar from '@material-ui/core/Avatar';
import TrixEditor from '../utils/TrixEditor';
import http from "../../redux/utils/http";


const styles = theme => ({
  root: {
    alignContent: "center",
    display: "flex",
    justifyContent: 'flex-start',
    marginTop: 20,
    width: "100%"
  },
  avatarContainer: {
    position: 'relative',
    width: '60px'
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: '1px solid #dddddd',
    color: "#ffffff",
    fontSize: '2rem',
    left: '0px',
    lineHeight: 2,
    position: 'absolute',
    textAlign: 'center',
    top: '0px',
  },
  commentHolder: {
    marginTop: '3px',
    width: '100%'
  },
  comment: {
    border: '1px solid #dddddd',
    fontSize: '1.4rem',
    minHeight: '30px',
    padding: '10px',
    width: "calc(100% - 20px)"
    // maxWidth: '460px'
  },
  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    width: '100%'
  }
});

class NewComment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      assignedUsers: []
    }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   return false;
  // }

  handleUpdateComment = (e) => {
    this.setState({comment: e.target.value});
  }

  handleAssigned = (user) => {
    // if (!this.state.assignedUsers.includes(user._id)) {
    //   this.setState(prevState => ({assignedUsers: [...prevState.assignedUsers, user._id]}));
    // }
    // console.log(this.state.assignedUsers);
  }

  // getMentionIDs = comment =>{
  //   let commentObj = JSON.parse(comment);
  //   let mentionObj = [];
  //   commentObj.forEach(comment => {
  //     const filteredComments = comment.children.filter(data=>data.type == 'mention');
  //     mentionObj = [...mentionObj, ...filteredComments];
  //   });
  //   const mentionIDs  = mentionObj.map(mention => mention.id);
  //   return mentionIDs;
  // }

  mentionNotification = ({
    title, mentions, genArea, genAreaID
  }) =>{
    if(mentions.length == 0) return;
    this.props.dispatch(mentionNotification({
      title: `Mentioned you in: ${title}`,
      taggedTo: mentions,
      genType: 'user',
      genID: this.props.user._id,
      genArea,
      genAreaID,
      status: false,
      spaceID: this.props.currentSpaceID
    })); 
  }

  handleSubmit = (comment, mentions) => {
    // const mentions = this.getMentionIDs(comment);

    if (this.props.questionID) {
      this.props.dispatch(submitAnswer({
        questionID: this.props.questionID, 
        body: comment
      }));
    } else if (this.props.announcementID) {
      console.log('posting a comment for announcement ', this.props.announcementID, comment)
      this.props.dispatch(submitAnnouncementComment(this.props.announcementID, comment));
    } else if (this.props.currentTodoID) {
      this.props.dispatch(addTodoComment(
        this.props.currentSpaceID, 
        this.props.currentTodoID, 
        {comments: comment}, 
        mentions
      ));
      // Send notification to mentions.
      this.mentionNotification({
        title: this.props.currentTodo.title,
        mentions,
        genArea: 'task', 
        genAreaID: this.props.currentTodoID
      });
    } else if (this.props.currentFileID) {
      this.props.dispatch(addFileComment(
        this.props.currentFileID, 
        {comments: comment},
        mentions
      ));
      // Send notification to mentions.
      this.mentionNotification({
        title: this.props.currentFile.name,
        mentions,
        genArea: 'file', 
        genAreaID: this.props.currentFolderID
      });
    }
  }

  handleImageUpload = (callback) =>{
      const { currentSpaceID, currentFileID } = this.props;

      let postData = null;

      if(currentFileID){
        postData = {
          spaceID: currentSpaceID,  
          folderID: this.props.currentFolderID,
          fileID: currentFileID
        }
      }else{
        postData = {
          spaceID: currentSpaceID,  
          categoryID: this.props.currentTodo.categoryID,
          taskID: this.props.currentTodoID
        }
      }
      
      http
        .post(`/api/task/comments/s3/upload`, postData)
        .then(response => {
          const data = response.data;
          const uploadData = {
            key: data.key,
            url: data.url
          }
          callback(uploadData);
        })
        .catch(error => {
          console.log(JSON.stringify(error));  
        });
  }

  render() {
    const {user, users, suggestions, classes, t} = this.props;
    let userList = Object.keys(users).map(userId => ({ 
      value: users[userId].name, 
      id: userId,
      email: users[userId].email,
      profileImage: users[userId].avatar ? users[userId].avatar : null
    }));
    userList = [...new Set(userList)];
    return(
      <div className={classes.root}>
        <div className={classes.avatarContainer}>
          <Avatar 
            alt={initials(user.name)} 
            src={user.avatar} 
            className={classes.avatar}
          >
            {initials(user.name)}
          </Avatar>
        </div>
        <div className={classes.commentHolder}>
          <div className={classes.comment}>
            <TrixEditor
              placeholder={this.props.placeholder || t('comment.addComment')}
              submitOnEnter={true}
              mentionList = {userList}
              plugins = {this.props.slatePlugins}
              imageUploadHandler={this.handleImageUpload}
              handleSubmit={this.handleSubmit} 
            />
          </div>
        </div>
      </div>
    )
  }
}

NewComment.propTypes = {
  comment: PropTypes.string
}

const mapStateToProps = state => ({
  users: state.users,
  suggestions: returnOtherUsers(state.users, state.spaces, state.dashboard.currentSpace, state.auth.user._id),
  currentSpaceID: state.dashboard.currentSpace,
  currentTodoID: state.dashboard.currentTodo,
  currentTodo: returnCurrent(state.todos, state.dashboard.currentTodo),
  currentFolderID: state.dashboard.currentFolder,
  currentFileID: state.dashboard.currentFile,
  currentFile: returnCurrent(state.files, state.dashboard.currentFile),
  user: state.auth.user
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NewComment)));
