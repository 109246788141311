import React, { useState } from "react";

import IMAGES from "../../constants/images";
import { SUPPORT_PLATFORM, MAIL_SUPPORT, TEL_SUPPORT } from "../../config";

import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { LiveHelp, ExpandMore } from "@material-ui/icons";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 15,
    right: 20,
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    color: "white",
  },
  box: {
    width: 350,
    height: 500,
    borderRadius: "5px",
    backgroundColor: "#F6F6F6",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2571B2",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    cursor: "pointer",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#1D5C8F",
    },
    "& > :first-child": {
      fontSize: 40,
    },
  },
  whatsappContainer: {
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    marginTop: 10,
    padding: 20,
    "&:hover": {
      color: "#333333",
    },
  },
  whatsapp: {
    height: 30,
    opacity: 0.6,
    width: 30,
  },
  row: {
    marginBottom: 30,
    position: "absolute",
    zIndex: 111,
    width: "90%",
    marginTop: "-50px",
    padding: "0 18px",
  },
  message: {
    fontSize: "1rem",
    fontWeight: 300,
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
  },
  messageLabel: {
    fontWeight: 600,
  },
  arrow: {
    marginLeft: "auto",
  },
  title: {
    color: "#fff",
    fontSize: "2rem",
    fontWeight: 800,
    position: "relative",
    width: "95%",
    height: 120,
    background: "linear-gradient(to right bottom, #246EAE, #0F3A5C)",
    padding: "30px 0 0px 18px",
  },
  animateHelpIcon: {
    animation: `$helpIcon 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  animateExpandIcon: {
    animation: `$expandIcon 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes helpIcon": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes expandIcon": {
    "0%": {
      opacity: 0,
      position: "relative",
      transform: "rotate(180deg)",
    },
    "100%": {
      opacity: 1,
      transform: "rotate(0deg)",
    },
  },
}));

const ChatSupport = () => {
  const [expand, setExpand] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {expand && (
        <Fade in={expand === true} timeout={1000}>
          <Box className={classes.box} boxShadow={3}>
            <div className={classes.title}> Counter-Hostelworld Support</div>
            <div className={classes.row}>
              <Box boxShadow={2}>
                <a
                  className={classes.whatsappContainer}
                  target="_blank"
                  href={`mailto: ${MAIL_SUPPORT}`}
                >
                  <img src={IMAGES.ICONS.email} className={classes.whatsapp} />
                  <span className={classes.message}>
                    <span className={classes.messageLabel}>Email</span>
                    <span>{MAIL_SUPPORT}</span>
                  </span>
                  <RightArrow className={classes.arrow} />
                </a>
              </Box>
              <Box boxShadow={2}>
                <a
                  className={classes.whatsappContainer}
                  target="_blank"
                  href={`tel:${TEL_SUPPORT}`}
                >
                  <img
                    src={IMAGES.ICONS.mobilePhone}
                    className={classes.whatsapp}
                  />
                  <span className={classes.message}>
                    <span className={classes.messageLabel}>
                      Call phone support
                    </span>
                    <span>{TEL_SUPPORT}</span>
                  </span>
                  <RightArrow className={classes.arrow} />
                </a>
              </Box>
              <Box boxShadow={2}>
                <a
                  className={classes.whatsappContainer}
                  target="_blank"
                  href={SUPPORT_PLATFORM}
                >
                  <img src={IMAGES.ICONS.idea} className={classes.whatsapp} />
                  <span className={classes.message}>
                    <span className={classes.messageLabel}>Knowledge Base</span>
                    <span>{SUPPORT_PLATFORM}</span>
                  </span>
                  <RightArrow className={classes.arrow} />
                </a>
              </Box>
            </div>
          </Box>
        </Fade>
      )}

      <div className={classes.icon} onClick={() => setExpand(!expand)}>
        {expand ? (
          <ExpandMore className={classes.animateExpandIcon} />
        ) : (
          <LiveHelp className={classes.animateHelpIcon} />
        )}
      </div>
    </div>
  );
};

export default ChatSupport;
