import http from "../../utils/http";
import { normalize } from 'normalizr';
import { subscriptionPlansSchema } from '../../schema/subscriptions';
import { spaceSchema } from '../../schema';

export const fetchSubscriptionPlans = spaceID =>{
    const ACTION_NAME = "FETCH_SUBSCRIPTION_PLANS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .get(`/api/subscription/plans/${spaceID}`)
        .then(successResult => {
            const { populatedProducts, beds } = successResult.data;
            const normalizedSubscriptionPlans = normalize(populatedProducts, [ subscriptionPlansSchema ] );
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    subscriptionPlans: normalizedSubscriptionPlans.entities.subscriptionPlans,
                    bedCount: beds
                }
            });
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    };
}

export const setSelectedPlanID = id =>({
    type: 'SET_SELECTED_PLAN_ID',
    payload: id
});

export const setSelectedPriceID = id =>({
    type: 'SET_SELECTED_PRICE_ID',
    payload: id
});

export const getDiscountCouponDetails = (couponCode, productID) =>{
    const ACTION_NAME = "DISCOUNT_COUPON_DETAILS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .get(`/api/subscription/promoCode/${couponCode}/${productID}`)
        .then(successResult => {
            const { promoCode: { coupon, id } } = successResult.data;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    id: id,
                    value: coupon.percent_off,
                    duration: coupon.duration,
                    durationInMonths: coupon.duration_in_months
                }
            });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    }
};

export function reloadLoadSpaceDetails(spaceID) {
    const ACTION_NAME = "LOAD_SPACE";
    return function(dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .get(`/api/space/${spaceID}`)
        .then(successResult => {
            const data = successResult.data;
            var normalizedOrder = normalize(data, {space: spaceSchema} );
            dispatch({ 
              type: `${ACTION_NAME}_SUCCESS`, 
              payload: normalizedOrder.entities
            });
            return normalizedOrder.entities;
        })
        .catch(errorResult => {
            console.log(errorResult);
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            return;
        });
    };
}

export const showPaymentLoader = () =>({
    type: 'SUBSCRIPTION_PAYMENT_REQUEST'
});

export const hidePaymentLoader = () =>({
    type: 'SUBSCRIPTION_PAYMENT_SUCCESS'
});

export const showPaymentError = error =>({
    type: 'SUBSCRIPTION_PAYMENT_FAILURE',
    payload: { error }
});

export function resetError(action) {
    return function (dispatch) {
      dispatch({ type: `${action}_RESET`, payload: { error: null } });
    };
}

//CHARGEBEE INTEGRATION...........................................
export const subscribeToPlan = (spaceID, data) =>{
    const ACTION_NAME = "SUBSCRIBE_TO_PLAN";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .patch(`/api/subscription/${spaceID}`, data)
        .then(successResult => {
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    };
}

export const getPortalSession = (spaceID) =>{
    const ACTION_NAME = "GET_PORTAL_SESSION";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .get(`/api/subscription/portal_page/${spaceID}`)
        .then(successResult => {
            const data = successResult.data;
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return data;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;

            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    };
}

export const updateChargebeeSubscription = (spaceID, data) =>{
    const ACTION_NAME = "UPDATE_CHARGEBEE_SUBSCRIPTION";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .patch(`/api/subscription/manage/${spaceID}`, data)
        .then(successResult => {
            const data = successResult.data;
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return true;
        })
        .catch(errorResult => {
            console.log("errorResult", errorResult);
            let error =
                errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;

            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
            return;
        });
    };
}