import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { addUpdateConductor } from '../../../redux/actions/experiences/activities';

const useStyles = makeStyles(theme =>({
    root: {
        maxWidth: '600px',
        marginTop:' 20px',
    },
    mainContainer:{
        padding: '10px',
        backgroundColor: '#f8f9fb',
        borderRadius: '5px',
    },
    selectFormControl: {
        // width: '100%',
        padding: '0px 5px',
        boxSizing: 'border-box',
        marginBottom: '20px',
        width: '100%'
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
            backgroundColor: '#fff'
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    formControl:{
        width: '100%',
        padding: '0px 5px',
        boxSizing: 'border-box',
        marginBottom: '20px',
    },
    textInput: {
        height: '40px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        padding: '0px 10px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        }
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px 5px',
        width: '100%',
        '& .SaveBtn':{
            backgroundColor: '#fff'
        },
        '& .cancelBtn':{
            marginRight: '10px',
            backgroundColor: '#fff'   
        }
    },
}));

const defaultFormData = {
    type: 'instructor',
    name: '',
    bio: ''
}

const ConductorForm = props =>{
    const { currentSpace, dispatch, hideFormHandler, conductorID, conductorList, t } = props;

    const conductorTypes = [
        { id: 'host', title: t('experiences.calendar.conductorType.host') },
        { id: 'guide', title: t('experiences.calendar.conductorType.guide') },
        { id: 'instructor', title: t('experiences.calendar.conductorType.instructor') },
        { id: 'coach', title: t('experiences.calendar.conductorType.coach') },
        { id: 'driver', title: t('experiences.calendar.conductorType.driver') },
        { id: 'pilot', title: t('experiences.calendar.conductorType.pilot') },
    ]
    const classes = useStyles();
    const [formData, setFormData] = useState({ ...defaultFormData });

    useEffect(()=>{
        // Set form field on edit mode......
        if(conductorID){
            const conductorData = conductorList[conductorID];
            const data = {
                type: conductorData.type,
                name: conductorData.name,
                bio: conductorData.bio
            }
            setFormData({ ...data });
            return;
        }

        setFormData({ ...defaultFormData });

    }, [conductorID]);

    const onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }

    const submitHandler = () =>{
        const data = { ...formData };
        // Adding conductor id for update conductor request...
        if(conductorID){
            data._id = conductorID;
        }
        const response = dispatch(addUpdateConductor(currentSpace.propertyID, data));
        response.then(success =>{
            if(success) hideFormHandler();
        });
    }

    return(
        <div className={classes.root}>
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.selectFormControl}>
                        <Select
                            native
                            value={formData.type}
                            onChange={onChangeHandler}
                            className={classes.selectInput}
                            inputProps={{
                                name: 'type'
                            }}
                        >
                            {conductorTypes.map(type =>(
                                <option value={type.id} key={`conductor_${type.title}`}>{type.title}</option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl}>
                        <InputBase 
                            id="conductor_name"
                            name="name"
                            type="text"
                            className={classes.textInput}
                            fullWidth={true}
                            placeholder={t('experiences.name')}
                            value={formData.name}
                            onChange={onChangeHandler}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputBase 
                            id="conductor_bio"
                            name="bio"
                            type="text"
                            className={classes.textInput}
                            fullWidth={true}
                            placeholder={t('experiences.bio')}
                            value={formData.bio}
                            onChange={onChangeHandler}
                        />
                    </FormControl>
                </Grid>

                <div className={classes.buttonContainer}>
                    <Button 
                        variant="outlined"
                        className="cancelBtn"
                        onClick={hideFormHandler}
                    >
                        {t('actions.cancel')}
                    </Button>
                    <Button 
                        variant="outlined"
                        className="SaveBtn"
                        onClick={submitHandler}
                    >
                        {conductorID ? t('actions.update') : t('actions.create')}
                    </Button>
                </div>

            </Grid>
        </div>
    )
}

const mapStateToProps = state =>{
    const { spaces, dashboard, experiences } = state;
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
        conductorList: experiences.conductorList || {}
    }
}

export default withTranslation()(connect(mapStateToProps)(ConductorForm));