import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import SelectRoomTypes from '../../new-reservation/SelectRoomTypes';
import DialogForm from '../guest-accommodation/DialogForm';
import BasicDetails from './BasicDetails';
import { loadReservationManualOptions, resetReservationManualOptions } from '../../../../redux/actions/rooms/rooms';
import Breadcrumb from '../../../common/Breadcrumb';
import ManualSelection from '../../new-reservation/ManualSelection';
import GuestList from '../../new-reservation/GuestList';

import http from "../../../../redux/utils/http";
import { DATE_FORMATS, fetchCurrencyFormat, fetchDateFormat, getAmtFormat } from '../../../../utils/utility';
// import { ICONS } from '../../../../utils/imageUrls';
import pluralize from 'pluralize';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Navigation from '../../../common/Navigation';
import { editReservation, resetError } from '../../../../redux/actions/reservation/editReservation';

import IMAGES from '../../../../constants/images';
import { getAccommodationName } from '../../../../utils/helper';

const styles = (theme) => ({
    Button: {
        background: '#000',
        color: '#fff',
        width: 100,
        height: 50,
        fontWeight: 700,
        '&:hover': {
            background: '#000'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    fullWidth: {
        width: '100%'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        zIndex: 99,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    dialogAction: {
        padding: '8px 30px 30px 30px'
    },
    dialogContent: {
        padding: '0px 5px',
        margin: '0 auto',
        // overflowY: 'auto',
        overflowY: 'unset'
    },
    space: {
      height: 50
    },
    error: {
        color: 'red',
        fontSize: '1.4rem',
        padding: '30px 5px 0'
    },
})


class AddBedGuest extends Component {
    state = {
        disableButton: false,
        buttonText: 'Next',
        currentStep: 1,
        prevStep: 1,
        data: { typeID: {} },
        availability: {},
        breadcrumbList: {}
    }
    basic = React.createRef();
    type = React.createRef();
    bed = React.createRef();
    guest = React.createRef();

    // -----------------------------------------
    componentDidUpdate(prevProps) {
        if (this.props.success && !_.isEqual(this.props.success, prevProps.success)) {
            this.props.dispatch(resetError('UPDATE_RESERVATION'))
            this.props.closeModalHandler();
        }
    }

    // -----------------------------------------
    fetchRoomTypeAvailability = (params) => {
        http.get(`/api/frontdesk/reservation/suggest/bedCount/${this.props.currentSpace.propertyID}`, {
            params
        })
            .then(successResult => {
                let data = successResult.data || [];
                data = data.reduce(function (r, a) {
                    r[a.roomTypeID] = r[a.roomTypeID] || {};
                    r[a.roomTypeID] = a;
                    return r;
                }, Object.create(null));
                this.setState({ availability: data });
            })
            .catch(errorResult => {
                this.setState({ availability: {} });
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                console.log('error ', error);
            });
    }

    // call suggestion api
    fetchSuggestions = (data) => {
        this.props.dispatch(loadReservationManualOptions(data.propertyID,
            {
                duration: data.nights || 1,
                startDate: data.checkIn
            }
        ))
    }
    // -----------------------------------------
    submitFormHandler = (data) => {
        const { reservation, currentSpace, calendarData } = this.props;
        let payload = {
            reservationID: reservation._id,
            addBedGuest: {
                checkIn: data.checkIn,
                checkOut: data.checkOut,
                guestCount: data.guestCount,
                calendarOptions: data.calendarOptions,
                customers: data.customers
            }
        }
        console.log('payload', payload)
        this.props.dispatch(editReservation(payload, currentSpace.propertyID, calendarData))
          
    }
    // -----------------------------------------
    validateBasicData = (data) => {
        let hasError = false;
        ['checkIn', 'nights', 'checkOut'].forEach(key => {
            if (data[key] == null && data[key] == undefined)
                hasError = true;
        });
        return hasError;
    }
    // -----------------------------------------
    getRoomName = (bedID, roomID, roomTypeID) => {
        const { beds, rooms, roomTypes } = this.props;

        let bedName = beds?.[bedID]?.name || '';
        let roomName = rooms?.[roomID]?.name || '';
        const dormOrPrivate = roomTypes?.[roomTypeID]?.dormOrPrivate;

        // return roomTypes?.[roomTypeID]?.dormOrPrivate === 'dorm' ?
        //     (
        //         bedName.toLowerCase().includes(roomName.toLowerCase()) ?
        //             bedName :
        //             bedName + '(' + roomName + ')'
        //     )
        //     : roomName;

        return getAccommodationName({ dormOrPrivate, roomName, bedName }, '${bedName}(${roomName})');
    }

    composeErrorRoomSelection = (error) => {
        let msg = 'More than one guest is occupying ';
        error.forEach(e => {
            msg = msg + (this.getRoomName(e.bedID, e.roomID, e.roomTypeID)) + ' on ' + fetchDateFormat(e.date, 'MMM DD') + ', ';
        })
        return msg.slice(0, -1) + ' Please ensure they are on different beds.';
    }

    previousStepHandler = (step) => {
        if (step < 3) {
            this.props.dispatch(resetReservationManualOptions())
        }
        this.setState({ currentStep: step, prevStep: step + 1, errorMessage: '' });
    }

    nextStepHandler = (step) => {
        let hasError = false, errorMessage = '';
        let data = { ...this.state.data };
        switch (step) {
            case 1:
                let reservation = this.basic.current.handleProcessData();
                hasError = this.validateBasicData(reservation)
                data = { ...data, ...reservation };

                if (data.nights < 1) hasError = true;
                if (hasError) errorMessage = "Please select all fields to proceed";
                if (data.nights < 0) errorMessage = "Checkout cannot be before checkin.";
                if (!hasError) {
                    this.fetchRoomTypeAvailability({ startDate: data.checkIn, endDate: data.checkOut });
                }
                break;
            case 2:
                let typeData = this.type.current.handleProcessData();
                data = { ...data, ...typeData };
                if (!Object.keys(data.typeID).length) hasError = true;
                if (hasError) errorMessage = 'Select room type';
                else this.fetchSuggestions(data);
                break;
            case 3:
                let blockData = this.bed.current.handleProcessData();
                if (!blockData.calendarOptions) hasError = true;
                if (hasError) errorMessage = 'Select room';
                if (blockData?.roomSelectionError?.length) {
                    hasError = true;
                    errorMessage = this.composeErrorRoomSelection(blockData.roomSelectionError);
                }

                data = { ...data, ...blockData };
                break;
            case 4:
                let guestInfo = this.guest.current.handleProcessData();
                let customers = [...guestInfo.customers];
                data = { ...data, customers };
                this.submitFormHandler(data);
                break;
        }

        this.dialogContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (hasError) {
            this.setState({ errorMessage })
            return
        }
        if (step !== 4) {
            this.setState({ currentStep: step + 1, data, errorMessage: null })
        }
    }

    buildBreadcrumbHandler = (data, step) => {
        data = { ...data };
        const currentSpace = { ...this.props.currentSpace };
        const currencySymbol = fetchCurrencyFormat(currentSpace.currency);

        let breadcrumbList = {
            ...(step > 1 && { 1: { step: 1, text: fetchDateFormat(data.checkIn, DATE_FORMATS.DATE_MONTH), icon: IMAGES.ICONS.darkCalendar } }),
            ...(step > 1 && { 2: { step: 1, text: `${data.nights} ${pluralize('nights', data.nights)}`, icon: IMAGES.ICONS.darkSleep } }),
            ...(step > 2 && { 3: { step: 2, text: data.guestCount, icon: IMAGES.ICONS.darkBoyBroadSmile } }),
            ...(step > 2 && data.hasShared && { 4: { step: 2, text: 'Shared', icon: IMAGES.ICONS.darkHostel } }),
            ...(step > 2 && data.hasPrivate && { 5: { step: 2, text: 'Private', icon: IMAGES.ICONS.darkBed } }),
            ...(step > 3 && { 6: { step: step - 1, text: `Total Amount ` + getAmtFormat(data.total, currencySymbol), icon: null, final: true } })
        };
        return breadcrumbList;
    }

    fetchDialogTitleHandler = (step) => {
        let title = '';
        switch (step) {
            case 1: title = 'Add bed or guest'; break;
            case 2: title = `Select Room Type`; break;
            case 3: title = `Select Room`; break;
            case 4: title = `Guest Details`; break;
            default: title = ''; break;
        }
        return title;
    }
    fetchDialogButtonNameHandler = (step) => {
        let title = '';
        switch (step) {
            case 1:
            case 2: title = `Next`; break;
            case 3: title = `Proceed`; break;
            case 4: title = `Submit`; break;
            default: title = ''; break;
        }
        return title;
    }
    // -----------------------------------------

    render() {
        const { classes, currentSpace, roomTypes } = this.props;
        const { errorMessage, availability, data, breadcrumbList, currentStep, prevStep } = this.state;
        return (
            <Dialog
                open={true}
                onClose={this.props.closeModalHandler}
                maxWidth="md"
                fullWidth={true}
                fullScreen={window.innerWidth < 901}
            >
                <div className={classes.root}>
                    <CloseIcon className={classes.closeIcon} onClick={() => this.props.closeModalHandler()} />
                    <DialogContent style={{ overflowY: 'unset' }}>
                        <div ref={node => { this.dialogContent = node; }}>
                            <DialogContent className={classes.dialogContent}>
                                <Navigation step={currentStep} title={this.fetchDialogTitleHandler(currentStep)} onChangePrev={this.previousStepHandler} />
                                {errorMessage && <div className={classes.error}>{errorMessage} </div>}
                                {currentStep > 1 && <div className={classes.space}></div>}

                                {currentStep !== 1 &&
                                    <Breadcrumb
                                        list={this.buildBreadcrumbHandler(data, currentStep)}
                                        currentStep={currentStep}
                                        onChangePrev={this.previousStepHandler}
                                        divider={true}
                                    />
                                }

                                {currentStep === 1 &&
                                    <BasicDetails
                                        ref={this.basic}
                                        currentSpace={currentSpace}
                                        preFilledData={data} />
                                }

                                {currentStep === 2 &&
                                    <SelectRoomTypes
                                        ref={this.type}
                                        currentSpace={currentSpace}
                                        availability={availability}
                                        availabilityMin={data.nights}
                                        checkAvailability={true}
                                        roomTypes={roomTypes}
                                        multiple={true}
                                        prefilledData={data.typeID}
                                    />
                                }

                                {currentStep === 3 &&
                                    <ManualSelection
                                        ref={this.bed}
                                        edit={true}
                                        options={this.props.options}
                                        currentSpace={currentSpace}
                                        guestCount={data.guestCount}
                                        data={data.typeID}
                                        date={data.checkIn}
                                        nights={data.nights}
                                        loading={this.props.loading}
                                        currentStep={currentStep}
                                        prevStep={prevStep}
                                        roomTypes={roomTypes}
                                        rooms={this.props.rooms}
                                        beds={this.props.beds}
                                        taxes={this.props.taxes}
                                        manualDefault={data.manualDefaultData}
                                        onChangePrev={this.handlePrev} />
                                }

                                {currentStep === 4 &&
                                    <GuestList
                                        ref={this.guest}
                                        currentSpace={currentSpace}
                                        guestCount={data.guestCount}
                                    />
                                }
                            </DialogContent>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Button
                            className={clsx({
                                [classes.Button]: true,
                                [classes.fullWidth]: currentStep === 3
                            })}
                            onClick={() => this.nextStepHandler(currentStep)}>
                            {this.fetchDialogButtonNameHandler(currentStep)}
                        </Button>
                    </DialogActions>

                    {/* -------------- Loader --------------- */}
                    {this.props.loading &&
                        <div className={"loadingOverlay"}>
                            <CircularProgress className={"loading"} />
                        </div>
                    }

                    {/* -------------- Error --------------- */}
                    {this.props.errors &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={true}
                            onClose={() => this.props.dispatch(resetError('UPDATE_RESERVATION'))}
                            autoHideDuration={6000}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{this.props.errors}</span>}
                        />
                    }
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    currentSpace: state.spaces[state.dashboard.currentSpace],
    roomTypes: state.roomTypes,
    options: state.options || [],
    taxes: state.taxes,
    rooms: state.rooms,
    beds: state.beds,
    success: state.success.UPDATE_RESERVATION,
    loading: state.loading.UPDATE_RESERVATION || state.loading.LOAD_RESERVATION_MANUAL_OPTIONS,
    errors: state.errors.UPDATE_RESERVATION,
})

export default withStyles(styles)(connect(mapStateToProps)(AddBedGuest));