import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import CreateReservationModal from './reservation/CreateReservationModal';
import { processBookingStats, validateReviewBooking, validatePreBooking, getAllSelectedBedIDs } from './helper';
import IMAGES from '../../constants/images';
import { reviewBooking } from '../../redux/actions/floorPlan/floorPlan';
import { getCurrency, getMomentFormat } from '../../utils/utility';
import ViewReservationHOC from '../common/ViewReservationHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import SummaryDrawer from './SummaryDrawer';
import HtmlTooltip from '../common/HtmlTooltip';
import { ERROR_MSG } from './constants';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '0px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: "0 -5px 5px -5px #e0e0e0",
        height: "70px",
        backgroundColor: '#fff',
        margin: "0px -20px",
        position: 'relative'
    },
    bookBtn: {
        color: "#30638e",
        border: "1px solid #30638e",
        fontSize: "1.2rem",
        fontWeight: 600,
        borderRadius: "5px",
        padding: "8px 25px",
        position: 'relative',
        minWidth: '145px',
        [theme.breakpoints.down('xs')]: {
            padding: "8px 15px",
        },
        '& .loader': {
            width: '25px !important',
            height: '25px !important',
            position: 'absolute'
        },
        '&.disableBtn': {
            opacity: 0.4,
            cursor: 'default !important'
        }
    },
    infoContainer: {
        display: 'flex'
    },
    iconItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 600,
        marginRight: "30px",
        
        '& .imgContainer': {
            border: "1px solid #e0e0e0",
            padding: "5px",
            borderRadius: "50%",
            marginRight: "10px",
            backgroundColor: "#f8f9fb",
            [theme.breakpoints.down('xs')]: { marginRight: "5px" }
        },  
        '& img': {
            width: 15,
            height: 15,
        },

        '& span': { marginLeft: "3px" },

        [theme.breakpoints.down('xs')]: {
            marginRight: "15px",
            '& span': {
                display: 'none'
            }
        }
    }
}));

const CreateReservation = ViewReservationHOC(CreateReservationModal);

function getTooltipContent({ hasBedsSelected, reviewSuccess, preBookingCheckSuccess, ratePlanID }) {
    if(!ratePlanID) return ERROR_MSG.ratePlanID;
    if(!hasBedsSelected) return '';
    if(!reviewSuccess) return ERROR_MSG.bookingReview;
    if(!preBookingCheckSuccess) return ERROR_MSG.preBooking;
    return '';
}

const BookingInfoFooter = props =>{
    const classes= useStyles();
    const { 
        ratePlanID,
        duration,
        selectedBedsInfo,
        ratesInfo,
        preOccupiedBeds,
        dispatch,
        startDate,
        endDate,
        currentSpace, 
        resetHandler,
        isReviewLoading
    } = props;

    const [stats, setStats] = useState({
        guestCount: 0,
        privateRoomCount: 0, 
        dormBedsCount: 0
    });

    const [showBookingModal, setShowBookingModal] = useState(false);
    const [disableReviewBtn, setDisableReviewBtn] = useState(false);
    const [showBookBtn, setShowBookBtn] = useState(false);
    const [summary, setSummary] = useState({});
    const [errorMsg, setErrorMsg] = useState('');

    const currencyData = {
        code: currentSpace.currency,
        country: currentSpace.country
    }

    useEffect(() =>{
        const processedStats = processBookingStats(selectedBedsInfo);
        setStats({ ...processedStats });

        //CHECK IF SELECTED RATEPLAN ID IS APPLICABLE TO SELECTED BED IDS
        const reviewSuccess = validateReviewBooking(selectedBedsInfo, ratesInfo, ratePlanID);
        
        //CHECK IF SELECTED BED IDS ARE NOT PRE OCCUPIED..........................
        const preBookingCheckSuccess = validatePreBooking(selectedBedsInfo, preOccupiedBeds);

        const hasBedsSelected = Object.keys(selectedBedsInfo).length > 0;
        
        const success = reviewSuccess && preBookingCheckSuccess && hasBedsSelected;
        
        setDisableReviewBtn(!success);
        
        setShowBookBtn(false);

        const error = getTooltipContent({
            hasBedsSelected, 
            reviewSuccess, 
            preBookingCheckSuccess, 
            ratePlanID
        });
        setErrorMsg(error);

    }, [selectedBedsInfo, ratePlanID]);

    const calculateBookingHandler = () =>{
        if(!ratePlanID || isReviewLoading || disableReviewBtn) return;

        const payload = {
            bedIDs : getAllSelectedBedIDs(selectedBedsInfo),
            ratePlanID,
            startDate: getMomentFormat(startDate).format('YYYY-MM-DD'),
            endDate: getMomentFormat(endDate).format('YYYY-MM-DD')
        }
        dispatch(reviewBooking(currentSpace.propertyID, payload))
            .then(data =>{
                if(data){

                    if(data.preOccupiedBeds && Object.keys(data.preOccupiedBeds).length > 0){
                        setDisableReviewBtn(true);
                        setShowBookBtn(false);
                        setErrorMsg(ERROR_MSG.preBooking);
                        return;
                    }

                    setSummary({ ...data });
                    setShowBookBtn(true);
                }
            })
    }

    const closeBookingModal = () =>{
        setShowBookingModal(true);
    }

    const resetFooter = () =>{
        setSummary({});
        setShowBookBtn(false);
        setDisableReviewBtn(false);
        setShowBookingModal(false);
        resetHandler();
    }

    return(
        <>
            {showBookBtn && (
                <SummaryDrawer 
                    summary={summary}
                    currencyData={currencyData}
                />
            )}

            <div className={classes.root}>
                <div className={classes.infoContainer}>

                    <div className={classes.iconItem} >
                        <div className="imgContainer">
                            <img src={IMAGES.ICONS.user} />
                        </div>
                        {stats.guestCount} <span>guests</span>
                    </div>

                    <div className={classes.iconItem} >
                        <div className="imgContainer">
                            <img src={IMAGES.ICONS.darkSleep} />
                        </div>
                        {duration} <span>Nights</span>
                    </div>

                    <div className={classes.iconItem} >              
                        <div className="imgContainer">
                            <img src={IMAGES.ICONS.private} />
                        </div>
                        {stats.privateRoomCount} <span>Private Rooms</span>
                    </div>

                    <div className={classes.iconItem} >
                        <div className="imgContainer">
                            <img src={IMAGES.ICONS.darkHostel} />
                        </div>
                        {stats.dormBedsCount} <span>Shared Beds</span>
                    </div>
                </div>

                {!showBookBtn && (
                    <HtmlTooltip title={errorMsg}>
                        <Button 
                            className={clsx(classes.bookBtn, { disableBtn: disableReviewBtn })}
                            onClick={calculateBookingHandler}
                            disabled={isReviewLoading}
                        >
                            {isReviewLoading && <CircularProgress className="loader" />}
                            Review Booking
                        </Button>
                    </HtmlTooltip>
                )}

                {showBookBtn && (
                    <Button 
                        className={classes.bookBtn}
                        onClick={closeBookingModal}
                    >
                        Book for {getCurrency(currencyData, summary.totalAmount)}
                    </Button>
                )}
            </div>

            <CreateReservation
                isOpen={showBookingModal}
                selectedBedsInfo={selectedBedsInfo}
                ratePlanID={ratePlanID}
                startDate={startDate}
                endDate={endDate}
                closeModalHandler={() => setShowBookingModal(false)}
                resetHandler={resetFooter}
            />
        </>
    )
}

const mapStateToProps = state =>{
    const { floorPlan, loading } = state;
    return{
        selectedBedsInfo: floorPlan.selectedBedsInfo,
        ratesInfo: floorPlan.ratesInfo,
        preOccupiedBeds: floorPlan.preOccupiedBeds,
        isReviewLoading: loading.REVIEW_BOOKING
    }
}

export default connect(mapStateToProps)(BookingInfoFooter);