import moment from 'moment';

export const getChannelRouteInfo = (pathname) =>{
    const routePath = pathname.split('/');
    const pathLength = routePath?.length || 0;
    const tabPathIndex = pathLength == 4 ? pathLength - 1 : pathLength - 2;
    const tabPath = routePath?.[tabPathIndex] || null;

    const modalPath = pathLength == 5 ? routePath?.[pathLength - 1] : null;

    const basePath = '/beds/channels';

    return { tabPath, modalPath, basePath }
}

export const setModalRoute = (status, history, routeData) =>{
    const { tabPath, modalPath, basePath } = routeData;

    if(!status){
        history.replace(`${basePath}/${tabPath}/`);
        window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
        return;
    }

    history.replace(`${basePath}/${tabPath}/${modalPath}`);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
}

export const fetchDateRange = (filterType) =>{
    let reportFrom ;
    let reportTo;
  
    switch(filterType){
      case 'today':
        reportFrom = moment();
        reportTo = moment();
        break;
      
      case 'tomorrow':
        reportFrom = moment().add(1, 'day');
        reportTo = moment().add(1, 'day');
        break;
  
      case 'yesterday':
        reportFrom = moment().subtract(1, 'day');
        reportTo = moment().subtract(1, 'day');
        break;
      
      case 'monthToDate':
        reportFrom = moment().startOf('month');
        reportTo = moment();
        break;
  
      case 'yearToDate':
        reportFrom = moment().startOf('year');
        reportTo = moment();
        break;
  
      case 'past15':
      case 'past30':
      case 'past90':
      case 'past180':
      case 'past365':
        const num = parseInt(filterType.substr(4));
        reportFrom = moment().subtract(num, 'days');
        reportTo = moment();
        break;
    }
  
    return { reportFrom, reportTo };
  };

export const RATE_PLAN_FORM = {
  STANDARD: {
    code: 'STANDARD',
    label: 'Standard',
    name: 'standard',
  },
  NRR: {
    code: 'NRR',
    label: 'Non-Refundable Rate',
    name: 'nrr',
  },
  NRR_DERIVED: {
    code: 'NRR_DERIVED',
    label: 'Non-Refundable Rate - Derived',
    name: 'nrr-derived',
  },
  BB: {
    code: 'BB',
    label: 'Bed & Breakfast',
    name: 'bb',
  },
  BB_NRR: {
    code: 'BB_NRR',
    label: 'Bed & Breakfast Non-Refundable Rate',
    name: 'bb-nrr',
  },
  BB_NRR_DERIVED: {
    code: 'BB_NRR',
    label: 'Bed & Breakfast Non-Refundable Rate - Derived',
    name: 'bb-nrr',
  },
  BB_DERIVED: {
    code: 'BB',
    label: 'Bed & Breakfast - Derived',
    name: 'bb',
  },
}

/**
 * 
 * @param {*} code 
 * @param {*} data 
 * default: key is applied to Standard rate plans which gets created by default for a room type by inbox
 * nonrefundable: key used to check if the amount is refundable or not
 * parentrateplanid: key in a rate plan is when the given rate plan is derived from the Standard(default) rate plan
 * isbreakfastincluded: key in a rate plan is to check if breakfast is included or not
 * @returns 
 */
export const CheckRatePlanType = (code, data = {}) => {
  switch (code) {
    case RATE_PLAN_FORM.STANDARD.code:
      return data.default ? true : false;

    case RATE_PLAN_FORM.NRR.code:
      return !data.default && data.nonrefundable && !data.parentrateplanid && !data.isbreakfastincluded ? true : false;

    case RATE_PLAN_FORM.NRR_DERIVED.code:
      return !data.default && data.nonrefundable && data.parentrateplanid && !data.isbreakfastincluded ? true : false;

    case RATE_PLAN_FORM.BB.code:
      return !data.default && !data.nonrefundable && !data.parentrateplanid ? true : false;

    case RATE_PLAN_FORM.BB_NRR.code:
      return !data.default && data.nonrefundable && !data.parentrateplanid && data.isbreakfastincluded ? true : false;

    case RATE_PLAN_FORM.BB_NRR_DERIVED.code:
      return !data.default && data.nonrefundable && data.parentrateplanid && data.isbreakfastincluded ? true : false;

    case RATE_PLAN_FORM.BB_DERIVED.code:
      return !data.default && !data.nonrefundable && data.parentrateplanid ? true : false

  }
}

export const getRatePlanName = (data = {}) => {

  let name = data.name;

  if (CheckRatePlanType(RATE_PLAN_FORM.STANDARD.code, data)) 
    return RATE_PLAN_FORM.STANDARD.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.NRR.code, data)) 
    return RATE_PLAN_FORM.NRR.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.NRR_DERIVED.code, data)) 
    return RATE_PLAN_FORM.NRR_DERIVED.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.BB.code, data)) 
    return RATE_PLAN_FORM.BB.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.BB_NRR.code, data)) 
    return RATE_PLAN_FORM.BB_NRR.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.BB_NRR_DERIVED.code, data)) 
    return RATE_PLAN_FORM.BB_NRR_DERIVED.label;

  if (CheckRatePlanType(RATE_PLAN_FORM.BB_DERIVED.code, data)) 
    return RATE_PLAN_FORM.BB_DERIVED.label;

  return name
}

// ---------------------- language related --------------------------------
export const content_languages = [
  { value: "pt_br", label: "Brazilian" },
  { value: "cs_cz", label: "Czech" },
  { value: "da_dk", label: "Danish" },
  { value: "de_de", label: "German" },
  { value: "en_gb", label: "English" },
  { value: "es_es", label: "Spanish" },
  { value: "fi_fi", label: "Finnish" },
  { value: "fr_fr", label: "French" },
  { value: "it_it", label: "Italian" },
  { value: "ja_jp", label: "Japanese" },
  { value: "ko_kr", label: "Korean" },
  { value: "nl_nl", label: "Dutch" },
  { value: "nb_no", label: "Norwegian" },
  { value: "pl_pl", label: "Polish" },
  { value: "pt_pt", label: "Portuguese" },
  { value: "ru_ru", label: "Russian" },
  { value: "sv_se", label: "Swedish" },
  { value: "tr_tr", label: "Turkish" },
  { value: "zh_cn", label: "Chinese" },
  { value: "zh_hant", label: "Traditional Chinese" }
];

export const getMicrositeFieldContent = (data, keyName, language) => {
  return Object.keys(data || {}).find(k => k.startsWith(keyName) && k.endsWith(language))
}

export const getMissingLanguage = (data, keyName) => {
  let keys = Object.keys(data || {}).filter(k => k.startsWith(keyName))
  let missingLanguage = keys.
    filter(k => !data?.[k]?.content).
    map(k => k?.split('-')?.[2]).
    map(k => content_languages.find(lang => lang.value == k));
  return missingLanguage;
}

// ------------------------------------------------------------------------