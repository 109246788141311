import React from 'react';
import { makeStyles, InputBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import NumberInput from './NumberInput';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    background: '#f8f9fb',
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 15,
    border: '1px solid #dadada',
    width: 'calc(80% - 35px)',
    '& > div': {
      width: '100%'
    },
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .css-6q0nyr-Svg': {
      color: '#000000',
      width: 30,
      height: 30,
    }
  },
  roundButton: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  fieldError: {
    border: '1px solid red',
  },
}));

const OneFieldForm = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.type === 'number' ? (props.defaultValue || 0) : (props.defaultValue || ''));
  const [error, setError] = React.useState(false);

  const handleSubmit = () => {
    if (!value) {
      setError(true)
    } else
      props.handleSubmit(value)
  }

  const handleChange = (e) => {
    if (error) setError(false)
    setValue(e.target.value)
  }

  return (
    <div className={classes.row}>
      {props.type === 'number' ?
        <NumberInput
          className={classes.field}
          min={0}
          value={value}
          onChange={handleChange}
        /> :
        <div className={clsx(classes.field, classes.col, { [classes.fieldError]: error })}>
          <InputBase
            type="text"
            id="tag"
            onChange={handleChange}
            fullWidth
          />
        </div>}
      <CloseIcon className={classes.roundButton} onClick={props.handleClose} />
      <DoneIcon className={classes.roundButton} onClick={() => handleSubmit()} />
    </div>
  )
}

export default OneFieldForm;