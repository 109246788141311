import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import ChatSupport from "../chat-support/ChatSupport";

import ReceptionForm from "./ReceptionForm";
import StaffAccess from "./StaffAccess";
import Accounts from "./Accounts";
import Upsell from "./Upsell";
import { setFrontdeskSignupStep } from "../../redux/actions/signup/signupCommunity";

import {
  makeStyles,
  Grid,
  Hidden,
  Typography,
  Dialog,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { FRONTDESK_URI } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflowY: "scroll",
    position: "relative",
    width: "100%",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 16,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  progressHeader: {
    alignItems: "center",
    background: "#ffffff",
    borderBottom: "2px solid #E0E0E0",
    display: "flex",
    height: 78,
    minHeight: 78,
    width: "100%",
  },
  headerText: {
    fontWeight: 600,
    color: "#999999",
  },
  logo: {
    marginLeft: 40,
    marginRight: 20,
    "& img": {
      height: 50,
      width: 50,
    },
  },
  container: {
    background: "#F5F1ED",
    flexGrow: 1,
  },
  userInfoContainer: {
    display: "flex",
    height: "100%",
  },
  background: {
    alignItems: "center",
    background: "#FFFFFF",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
  },
  imageContainer: {
    width: "70%",
  },
  signupImage: {
    objectFit: "contain",
    maxHeight: "200px",
    width: "100%",
  },
  welcome: {
    marginBottom: 30,
    marginTop: 30,
  },
  tagSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: 30,
    marginLeft: 20,
  },
  tagContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 15,
  },
  icon: {
    color: "#999999",
    fontSize: "2.5rem",
    marginRight: 15,
  },
}));

const FrontdeskSignup = (props) => {
  const classes = useStyles();
  const { currentSpaceID, currentSpace, currentUser, step } = props;

  // props.dispatch(setFrontdeskSignupStep(currentSpaceID, 3));

  const user = {
    user_id: currentUser && currentUser._id,
    email: currentUser && currentUser.email,
    name: currentUser && currentUser.name,
    migrationStep: currentUser && currentUser.migrationStep,
    spaceID: currentSpace && currentSpace._id,
    propertyID: currentSpace && currentSpace.propertyID,
    propertyName: currentSpace && currentSpace.name,
    isAdmin:
      currentSpace &&
      currentSpace.admins &&
      currentSpace.admins.some((item) => item === currentUser._id),
  };

  const handleReceptionComplete = () => {
    props.dispatch(setFrontdeskSignupStep(currentSpaceID, 2));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "frontdesk signup",
      eventAction: "step 1",
      eventLabel: "create reception",
    });
  };

  const handleStaffComplete = () => {
    props.dispatch(setFrontdeskSignupStep(currentSpaceID, 3));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "frontdesk signup",
      eventAction: "step 2",
      eventLabel: "staff access",
    });
  };

  const handleAccountsComplete = () => {
    props.dispatch(setFrontdeskSignupStep(currentSpaceID, 4));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "frontdesk signup",
      eventAction: "step 3",
      eventLabel: "account setup",
    });
  };

  const handleUpsellComplete = () => {
    props.dispatch(setFrontdeskSignupStep(currentSpaceID, "Complete"));
    window.open(FRONTDESK_URI, "_blank");
    props.handleClose();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "frontdesk signup",
      eventAction: "step 4",
      eventLabel: "upsell items",
    });
  };

  const reception = (
    <div className={classes.userInfoContainer}>
      <ReceptionForm handleNext={handleReceptionComplete} />
    </div>
  );

  const staffAccess = (
    <div className={classes.userInfoContainer}>
      <StaffAccess handleNext={handleStaffComplete} />
    </div>
  );

  const accounts = (
    <div className={classes.userInfoContainer}>
      <Accounts handleNext={handleAccountsComplete} />
    </div>
  );

  const upsell = (
    <div className={classes.userInfoContainer}>
      <Upsell handleNext={handleUpsellComplete} />
    </div>
  );

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth fullScreen>
      <div className={classes.root}>
        <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        <div className={classes.progressHeader}>
          <div className={classes.logo}>
            <img src="/images/icons/counter.png" />
          </div>
          <div className={classes.pageHeader}>
            <Typography
              variant="h6"
              className={classes.headerText}
            >{`Step ${step} of 4`}</Typography>
            <Typography variant="h6" className={classes.headerText}>
              {step === 1 && "Reception"}
              {step === "2" && "Staff Access"}
              {step === "3" && "Accounts"}
              {step === "4" && "Upsell"}
            </Typography>
          </div>
        </div>

        <Grid container className={classes.container}>
          <Grid item xs={12} md={6}>
            {step === 1 && reception}
            {step === "2" && staffAccess}
            {step === "3" && accounts}
            {step === "4" && upsell}
          </Grid>

          {step === 1 && (
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <div className={classes.background}>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.signupImage}
                      src="/images/illustration-frontdesk.png"
                    />
                  </div>
                  <div className={classes.welcome}>
                    <Typography variant="h2" style={{ color: "#333333" }}>
                      Welcome to Frontdesk
                    </Typography>
                    <div className={classes.tagSection}>
                      <div className={classes.tagContainer}>
                        <DoneIcon className={classes.icon} />
                        <Typography variant="h5" className={classes.tagline}>
                          Works on any device
                        </Typography>
                      </div>
                      <div className={classes.tagContainer}>
                        <DoneIcon className={classes.icon} />
                        <Typography variant="h5" className={classes.tagline}>
                          App built for receptionists
                        </Typography>
                      </div>
                      <div className={classes.tagContainer}>
                        <DoneIcon className={classes.icon} />
                        <Typography variant="h5" className={classes.tagline}>
                          Zero training guarantee
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Hidden>
          )}
        </Grid>

        <ChatSupport />
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  step: state.spaces[state.dashboard.currentSpace].frontdeskSignup || 1,
});

export default connect(mapStateToProps)(FrontdeskSignup);
