import React from 'react';
import { makeStyles } from '@material-ui/core';

import PMSTermsVersion1 from '../signupCommunity/termsAndConditions/PMSTermsVersion1';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    height: 'calc(100vh - 186px)',
    overflowY: 'scroll',
    padding: theme.spacing(5)
  },
}))
const PMSTermsPage = () => {

  const classes = useStyles();

  return (
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Hostelworld PMS System Terms & Conditions</div>
        </div>

        <div className={classes.content}>
          <PMSTermsVersion1 />
        </div>

      </div>
  )
}

export default PMSTermsPage;