import { toTitleCase } from '../../../components/utils/wordUtils';; 
import { fetchDateFormat, DATE_FORMATS } from '../../../utils/utility';
import moment from 'moment';

const colorArray = [
  "#4F9581",
  "#AEC1E1",
  "#F2D073",
  "#E37381",
  "#E6BBB3"
]

const getSum = (array, column) => {
  let values = array.map((item) => parseInt(item[column]) || 0)
  return values.reduce((a, b) => a + b)
}

const channelNoShowSelector = (noShows, filter) => {
  let data = [];
  var amountCount = 0;
  if (noShows && Object.keys(noShows).length!==0) {
    noShows[filter].sort((a, b) => b.totalAmount - a.totalAmount).slice(0,4).map((source,index) => {
      amountCount += source.totalAmount;
      data.push({
        "id": source.bookingSource ? source.bookingSource : 'None',
        "label": source.bookingSource ? source.bookingSource : 'None',
        "value": source.totalAmount,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (noShows && noShows[filter].length>4) {
    const totalNoShowAmount = getSum(noShows[filter], 'totalAmount');
    data.push({
      "id": "Other",
      "label": "Other",
      "value": (totalNoShowAmount - amountCount),
      "color": colorArray[4]
    })
  }

  return data;
}

const roomTypeNoShowSelector = (noShows, filter, roomTypes) => {
  let data = [];
  var amountCount = 0;
  if (noShows && Object.keys(noShows).length!==0) {
    noShows[filter].sort((a, b) => b.totalAmount - a.totalAmount).slice(0,4).map((source,index) => {
      amountCount += source.totalAmount;
      data.push({
        "id": roomTypes[source.roomTypeID].name,
        "label": roomTypes[source.roomTypeID].name,
        "value": source.totalAmount,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (noShows && noShows[filter].length>4) {
    const totalNoShowAmount = getSum(noShows[filter], 'totalAmount');
    data.push({
      "id": "Other",
      "label": "Other",
      "value": (totalNoShowAmount - amountCount),
      "color": colorArray[4]
    })
  }

  return data;
}

const formatRoomsData = (data, roomInfo) =>{
  let formattedData = '';
  let dataArray = [ ...new Set([...data]) ];

  dataArray.forEach((id, index) =>{

    formattedData += `${roomInfo[id].name}` || '';

    if(index + 1 == dataArray.length) return;

    formattedData += ", ";
  });

  return `${formattedData}`;
}

const processCancelledReservationsList = (cancelledReservations, roomTypes, submittedDates, currencyData, timezone) =>{
  let dataFormat = [];

  roomTypes && cancelledReservations && cancelledReservations.length!==0 && cancelledReservations.map(reservation => {
    
    if (reservation.checkIn, moment(reservation.checkIn).isBetween(submittedDates.startDate, submittedDates.endDate, 'days', '[]'))
    {
      dataFormat.push(
      {
        resvCode: reservation?.code || '',
        name: toTitleCase(reservation.name),
        checkIn: moment(reservation.checkIn).format('DD MMM YYYY'),
        checkOut: moment(reservation.checkOut).format('DD MMM YYYY'),
        roomTypes: formatRoomsData(reservation.roomTypeIDs,roomTypes),
        bookingSource: reservation.bookingSource,
        totalAmount: reservation.totalAmount,
        balance: reservation.balance,
        cancelledAt: fetchDateFormat(reservation.cancellationAt, `${DATE_FORMATS.DAY} ${DATE_FORMATS.MONTH}, ${DATE_FORMATS.YEAR}`, timezone),
        cancelledAtTime: fetchDateFormat(reservation.cancellationAt, `${DATE_FORMATS.TIME}`, timezone),
        cancellationReason: reservation.cancellationReason,
        resID: reservation.resID
      })
    };
  });
  return dataFormat;
}

const processCancellationGraph = (cancellations, submittedDates, level) => {
  let tempArray = [];
  const cancellationSummaryList = cancellations && cancellations.propertyCancellations;
  if (cancellationSummaryList && cancellationSummaryList.length!==0) {
    
    let difference = level==='daily' ? 'day' : 'month';
    let dateVar = submittedDates.startDate.clone().subtract(1, difference);
  
    do {
      dateVar = dateVar.add(1, difference);
      tempArray.push({
        "date": level==='daily' ? dateVar.format('DD MMM') : dateVar.format('MMM YYYY'), 
        "cancellations":  cancellationSummaryList.find(e => moment(e.date).isSame(dateVar, difference)) ? 
                    cancellationSummaryList.find(e => moment(e.date).isSame(dateVar, difference)).cancellations : 
                    0
      })
    } while (!dateVar.isSame(submittedDates.endDate, difference))
  }

  return tempArray;
}

export {channelNoShowSelector, roomTypeNoShowSelector, processCancelledReservationsList, processCancellationGraph};