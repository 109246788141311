import http from '../../utils/http'

export function fetchChannelStatus(propertyID) {
    const ACTION_NAME = "FETCH_INBOX_CHANNELS_STATUS";

    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/channels/status/${propertyID}`)
            .then(successResult => {
                const data = successResult.data || [];
                let channelList = {};
                
                data.forEach(channel => {
                    channelList[channel.name] = { ...channel }
                });

                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: channelList
                });
            })
            .catch(errorResult => {
                console.log('errorResult', errorResult);
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            });
    }
}

export const resetChannelStatus = () =>({
    type: 'RESET_INBOX_CHANNEL_STATUS'
})