import http from "../../utils/http";
import { normalize } from 'normalizr';
import { productSchema, conductorSchema } from '../../schema/experiences';

export const fetchCalendarInfo = (params) => {
    const ACTION_NAME = "FETCH_CALENDAR_INFO";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/experiences/calendar`, { params })
            .then(successResult => {
                const { calendar, products, conductors } = successResult.data;
                //console.log(successResult.data);
                const normalizedProducts = normalize(products, [ productSchema ] );
                const normalizedConductors = normalize(conductors, [ conductorSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: {
                        calendar,
                        products: normalizedProducts.entities.products,
                        conductors: normalizedConductors.entities.conductors,
                    }
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const fetchActivityBookings = (propertyID, params) =>{
    const ACTION_NAME = "FETCH_ACTIVITY_BOOKINGS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/experiences/search/${propertyID}`, { params })
            .then(successResult => {
                const { data } = successResult;
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const displayProducts = products =>({
    type: 'DISPLAY_PRODUCTS',
    payload: { products } 
});

export const displayProductDetail = productData =>({
    type: 'DISPLAY_PRODUCT_DETAILS',
    payload: { productData }
});

export const resetProductDisplay = () =>({
    type: 'RESET_PRODUCT_DISPLAY'
});