
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setUpgradeAccount } from '../../redux/actions/dashboard/dashboard';

import Typography from '@material-ui/core/Typography';

import IMAGES from '../../constants/images';

const useStyles = makeStyles( theme => ({
  root: {
    width: '100%'
  },
  moduleContainer: {
    position: 'relative'
  },
  email: {
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:hover': {
      background: '#ffffff'
    }
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#ffffff'
    }
  },
  imageContainer: {
    alignItems: 'center',
    height: 130,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 90,
      width: 90
    },
  },
  snapshotHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 25,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  infoItem: {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    marginLeft: 5,
    marginRight: 5,
    padding: 5,
    '&:hover': {
      background: '#eeeeee'
    }
  },
  icon: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  padding: {
    height: 41
  },
  upgrade: {
    background: '#006699',
    borderRadius: 5,
    border: '1px solid #006699',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontWeight: 600,
    padding: '5px 8px',
  },
  pro: {
    background: '#996666',
    border: '1px solid #996666',
  }
}))

const SnapshotCard = (props) => {
  const classes = useStyles();
  const { currentSpace, name, infoLink, demoLink, iconSource, badge,  t } = props;

  const handleInfoClick = () => {
    window.open(`${infoLink}`, '_blank');
  }

  const handleDemoClick = () => {
    window.open(`${demoLink}`, '_blank');
  }

  return (

    <div className={classes.root}>

      <div className={clsx({
        [classes.moduleContainer]: true, 
        [classes.hover]: currentSpace.onboardingStatus && (
            badge=='plus' && (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') ||
            badge=='pro' && currentSpace.onboardingStatus == 'pro'
          )
        })} 
        onClick={props.handleClickOpen}
      >
        
        {/* HEADER */}
        {/* --------------------------------- */}
        <div className={classes.snapshotHeader}>
          <Typography component="h4" variant="h6">{name}</Typography>
        
          {/* UPGRADE TO PLUS */}
          {/* --------------------------------- */}
          {(badge=='plus' && currentSpace.onboardingStatus != 'plus' && currentSpace.onboardingStatus != 'pro')  && (
            <div className={classes.upgrade} onClick={() => props.dispatch(setUpgradeAccount(true))}>Plus</div>
          )}

          {/* UPGRADE TO PRO */}
          {/* --------------------------------- */}
          {(badge=='pro' && currentSpace.onboardingStatus != 'pro')  && (
            <div className={clsx(classes.upgrade, classes.pro)} onClick={() => props.dispatch(setUpgradeAccount(true))}>Pro</div>
          )}
          
        </div>

        {/* ICON */}
        {/* --------------------------------- */}
        <div className={classes.imageContainer}>
          <img src={iconSource} />
        </div>

        {/* DEMO / VIDEO */}
        {/* --------------------------------- */}
        <div className={classes.infoContainer}>
          {demoLink && <div className={classes.infoItem} onClick={handleDemoClick}>
            <img className={classes.icon} src={IMAGES.ICONS.demo} />
            <span>{t('automate.demo')}</span>
          </div>}
        </div>

      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(SnapshotCard));



