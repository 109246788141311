import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  buttonContainer:{
    alignItems: 'center',
    background: '#6fa7d6',
    borderRadius: 4,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '25px',
    padding: '4px 8px',
    '& .buttonTitle':{
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    '& .AddButtonIcon':{
      width: '20px',
      height: '20px'
    },
    '&:hover': {
      background: '#568EBD',
      transition: '0.2s'
    }
  },
  newResButton: {
    alignItems: 'center',
    background: '#6fa7d6',
    borderRadius: '4px',
    color: '#ffffff',
    display: 'flex',
    height: '23px',
    justifyContent: 'center',
    width: '23px',
  },
}))

// Add Button Component
// ------------------------------------
// Example: Calendar Page, Add Room Type, etc.
// 
// Input props
// handleClick - function when add button is clicked
// title (optional) - name of button
// color (optional) - background color

const AddButton = (props) => {
  const classes = useStyles();
  const { handleClick, title, color } = props;

  return (
    <div 
      className={classes.buttonContainer} 
      style={{background: color ? color : null}}
      onClick={handleClick}
    >
      <div className="buttonTitle">
        {title ? title : 'Add'}
      </div>
    </div>
  )
}

export default AddButton;