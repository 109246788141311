import http from '../../utils/http';

//----------------------------------------------------------------
// LOAD PROPERTY
//----------------------------------------------------------------
export function fetchLiveReports(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_LIVE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/live/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data, spaceID}})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function fetchTodaysActivity(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_TODAYS_ACTIVITY";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/live/todaysActivity/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data, spaceID }})
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}