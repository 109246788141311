import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import AddIcon from '@material-ui/icons/Add';

import VariationItem from './VariationItem';
import VariationFormModal from './variationFormModal';
import Footer from '../Footer';
import { defaultImageUrl } from '../activityHelper';

const styles = theme =>({
    root: {
        
    },
    listContainer:{
        marginTop: '20px'
    },
    addItemContainer:{
        alignItems: 'center',
        border: '1px dashed #BDBDBD',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.3rem',
        margin: '5px',
        padding: theme.spacing(2),
        justifyContent: 'center',
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: '#F8F9FB',
        }
    },
    addIcon: {
        color: '#999999',
        marginRight: 5,
    },
});

class ActivityVariations extends Component {
    state = {
        showModal: false,
        gradeID: null
    }

    showModalHandler = () =>{
        this.setState({ showModal: true });
    }

    closeModalHandler = () =>{
        this.setState({ 
            showModal: false,
            gradeID: null 
        });
    }

    editVariationHandler = gradeID =>{
        this.setState({
            showModal: true, 
            gradeID
        })
    }

    render(){

        const { 
            classes, 
            nextStepHandler, 
            prevStepHandler, 
            activeStepInfo,
            productData,
            coverPhotoUrl 
        } = this.props;

        const { showModal, gradeID } = this.state;
        return(
            <>
                <div className="main-content">
                    
                    <div className="main-content-title">Add variations of the main experience</div>
                    
                    {/* <div className="sub-title">
                        Your surf experience has a beginner and advanced level classes.
                    </div> */}

                    <div className={classes.listContainer}>
                        
                        {Object.keys(productData.grades).map((gradeID, index) =>{
                            const gradeData = productData.grades[gradeID];
                            return(
                                <VariationItem 
                                    key={`variation_${gradeID}`}
                                    title={gradeData.title} 
                                    variationNo={index+1}
                                    editVariationHandler={()=>this.editVariationHandler(gradeID)}
                                    coverPhotoUrl={coverPhotoUrl || defaultImageUrl}
                                />
                            )
                        })}

                        <div 
                            className={classes.addItemContainer}
                            onClick={this.showModalHandler}
                        >
                            <div>Add another</div>
                            <div>variation</div>
                            <AddIcon className={classes.addIcon} />
                        </div>
                     
                    </div>

                    { showModal && (
                        <VariationFormModal 
                            { ...this.props } 
                            gradeID = {gradeID}
                            closeModalHandler={this.closeModalHandler}
                        />
                    )}

                </div>

                <Footer 
                    nextClickHandler = {nextStepHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}

export default withStyles(styles)(ActivityVariations);