import http from "../../utils/http";

//----------------------------------------------------------------
// ADD TODO CATEGORY
//----------------------------------------------------------------
export function addTodoCategory(spaceID, todoCategory) {
  const ACTION_NAME = "ADD_TODOCATEGORY";
  return function(dispatch) {
    return http.post(`/api/taskCategory/${spaceID}`, {...todoCategory}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data})
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// EDIT TODO CATEGORY
//----------------------------------------------------------------
export function editTaskCategory(spaceID, taskCategoryID, updates) {
  const ACTION_NAME = "EDIT_TODOCATEGORY";
  return function(dispatch) {
    return http.patch(`/api/taskCategory/${taskCategoryID}`, {...updates}, {headers: {'Accept': 'application/json'}})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      // var normalizedOrder = normalize(data, todoSchema);
      // console.log(normalizedOrder.entities);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data})
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}

//----------------------------------------------------------------
// DELETE TODO CATEGORY
//----------------------------------------------------------------
export function deleteTodoCategory(spaceID, taskCategoryID) {
  const ACTION_NAME = "DELETE_TODOCATEGORY";
  return function(dispatch) {
    return http.delete(`/api/taskCategory/${taskCategoryID}`)
    .then(successResult => {
      const data = successResult.data;
      console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {taskCategoryID, spaceID}})
    })
    .catch(errorResult => {
      console.log(errorResult);
      // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}