import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";

import {
  createHostelWorldRoomType,
  deleteHostelWorldRoomType,
  getHostelWorldRoomTypes,
  setHostelWorldSettingsRoomTypes,
  updateHostelWorldRoomType,
} from "../../../redux/actions/microsite/hostelworld";
import Form from "./Form";

import { Grid, Dialog, CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listSection: {
    margin: theme.spacing(3),
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  row: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    cursor: "pointer",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 20,
    "&:hover": {
      background: "#F8F9FB",
    },
  },
  icon: {
    alignItems: "center",
    color: "#666666",
    display: "flex",
    justifyContent: "center",
  },
  nameContainer: {
    alignItems: "center",
    display: "flex",
    color: "#333333",
    fontWeight: 600,
    "& img": {
      borderRadius: 5,
      height: 70,
      width: 70,
      objectFit: "cover",
    },
  },
  name: {
    fontSize: "1.3rem",
    marginLeft: 20,
  },
  blankSlate: {
    margin: "20px auto",
    width: "80%",
    textAlign: "center",
    lineHeight: 1.5,
  },
  blankText: {
    fontSize: "1.4rem",
    marginBottom: 20,
  },
}));

const List = (props) => {
  const classes = useStyles();

  const { open, closeHandler, currentSpace } = props;
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({ roomtypeid: "", active: false });
  const [roomTypesList, setRoomTypeList] = React.useState([]);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      fetchRoomTypeHandler();
    }
  }, [open]);

  const submitHandler = (payload) => {
    setLoading(true);
    if (payload.roomtypeid) {
      const response = props.dispatch(
        updateHostelWorldRoomType(payload, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) {
            processSettingRoomTypes({
              oldID: parseInt(payload.roomtypeid),
              id: parseInt(data.roomtypeid),
              payload,
              newList: data.roomtypes,
              type: "update",
            });
            setRoomTypeList(data.roomtypes);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }

    if (!payload.roomtypeid) {
      const response = props.dispatch(
        createHostelWorldRoomType(payload, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) {
            processSettingRoomTypes({
              id: parseInt(data.roomtypeid),
              payload,
              type: "create",
            });
            setRoomTypeList(data.roomtypes);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    setData({ roomtypeid: "", active: false });
  };

  const deleteHandler = (roomtypeid) => {
    setLoading(true);
    if (roomtypeid) {
      const response = props.dispatch(
        deleteHostelWorldRoomType({ roomtypeid }, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          processSettingRoomTypes({ id: parseInt(roomtypeid), type: "delete" });
          if (data) setRoomTypeList([...data]);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    setData({ roomtypeid: "", active: false });
  };

  const fetchRoomTypeHandler = () => {
    const response = props.dispatch(
      getHostelWorldRoomTypes(currentSpace.propertyID)
    );
    response
      .then((data) => {
        setLoading(false);
        if (data) setRoomTypeList([...data]);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const closeErrorHandler = () => {
    setError(null);
  };

  const closeModalHandler = () => {
    if (error) closeErrorHandler();
    closeHandler();
  };

  const processSettingRoomTypes = (data) => {
    const { type, id, newList, payload, oldID } = data;
    const { roomTypes = [] } = props?.settings;
    let roomTypesData;

    switch (type) {
      case "delete":
        roomTypesData = roomTypes.map((d) => {
          if (d.id === id) d.active = false;
          return d;
        });
        break;
      case "create":
        roomTypesData = [...roomTypes];
        roomTypesData.push({
          active: true,
          basicType: payload.basictype || "",
          beds: parseInt(payload.beds),
          description: {
            content: "",
            proposedUpdate: payload.description || "",
          },
          id: id,
          label: payload.labeltype,
        });
        break;
      case "update":
        let updatedData =
          newList.find((d) => parseInt(d.roomtypeid) === id) || {};
        roomTypesData = roomTypes.map((d) => {
          if (d.id === oldID) {
            d.active = false;
          }
          return d;
        });
        roomTypesData.push({
          active: true,
          basicType: payload.basictype || "",
          beds: parseInt(payload.beds),
          description: {
            content: updatedData.description || "",
            proposedUpdate: updatedData.description ? "" : payload.description,
          },
          id: id,
          label: payload.labeltype,
        });
        break;
      default:
        roomTypesData = [...roomTypes];
    }

    props.dispatch(setHostelWorldSettingsRoomTypes(roomTypesData));
  };

  const displayRoomTypeName = (data) => {
    if (data.displayname && data.roomtypename)
      return data.displayname + " (" + data.roomtypename + ")";
    if (data.displayname && !data.roomtypename) return data.displayname;
    if (!data.displayname && data.roomtypename) return data.roomtypename;
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalHandler}
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {!data.active && (
            <AddIcon
              className={classes.iconButton}
              onClick={() => setData({ roomtypeid: "", active: true })}
            />
          )}

          {data.active && (
            <BackIcon
              className={classes.iconButton}
              onClick={() => setData({ roomtypeid: "", active: false })}
            />
          )}

          <div className={classes.header}>Room Types</div>
          <AccessHeaderAction moduleID={MODULE.HW_ROOMTYPES.ID} />
          <CloseIcon
            className={classes.closeIcon}
            onClick={closeModalHandler}
          />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {/* BLANK STATE - No Rooms */}
            {/* ------------------------------------------------ */}
            {!loading &&
              !data.active &&
              (!roomTypesList || roomTypesList.length === 0) && (
                <div className={classes.blankSlate}>
                  <div className={classes.blankText}>
                    No room types on Hostelworld, please click on + button to
                    create new room types
                  </div>
                </div>
              )}
            {/* LIST ALL ROOM TYPES */}
            {/* --------------------------------------------- */}
            {/* ------------------------------------------------ */}

            {!data.active &&
              roomTypesList.map((roomType, index) => (
                <Grid
                  key={index}
                  container
                  alignItems="center"
                  className={classes.row}
                  onClick={() =>
                    setData({
                      roomtypeid: roomType.roomtypeid,
                      active: true,
                      ...roomType,
                    })
                  }
                >
                  <Grid item xs={11}>
                    <div className={classes.nameContainer}>
                      <div className={classes.name}>
                        {displayRoomTypeName(roomType)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <ArrowRight className={classes.icon} />
                  </Grid>
                </Grid>
              ))}

            {data.active && (
              <Form
                data={data}
                submitHandler={submitHandler}
                confirmDeleteHandler={deleteHandler}
                cancelHandler={() => setData({ roomtypeid: "", active: false })}
              />
            )}
          </div>
        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={closeErrorHandler}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{error}</span>}
          />
        )}

        {loading && (
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  settings: state?.inbox?.hostelworld?.settings,
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default connect(mapStateToProps)(List);
