import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Toggle from "../common/Toggle";

import {
  loadOccupancy,
  loadRevOccupancy,
  resetOccupancyReport,
} from "../../redux/actions/reports/occupancy";
import { occupancyProcessor } from "../../redux/selectors/reports/occupancy";
import BarChart from "../charts/BarChart";
import GroupedBarChart from "../charts/GroupedBarChart";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { exportExcel } from "../utils/excelExport";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "&:hover": {
      background: "#eeeeee",
    },
  },
  icon: {
    fontSize: "5rem",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    // boxShadow: '0 1px 10px #E0E0E0',
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    display: "flex",
    alignItems: "center",
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
      // boxShadow: '0 1px 10px #E0E0E0',
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  revenueGraph: {
    height: 400,
    width: "100%",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  errorState: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 300,
    width: "100%",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  title: {
    fontWeight: 600,
    marginRight: 20,
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      marginTop: 10,
    },
  },
  resStatus: {
    alignItems: "center",
    flexGrow: 1,
    display: "flex",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const calcAverage = (array, filter) => {
  let count = array.length;
  let amount = 0;
  array.map((e) => {
    let str = e[filter];
    str = str.substring(0, str.length - 1);
    amount += parseFloat(str);
  });
  if (count !== 0) {
    return `${parseFloat(amount / count).toFixed(2)}%`;
  } else return "NA";
};

const calcAverageNum = (array, filter) => {
  let count = array.length;
  let amount = 0;
  array.map((e) => {
    amount += e[filter];
  });
  if (count !== 0) {
    return `${parseFloat(amount / count).toFixed(2)}%`;
  } else return "NA";
};

const BasicExport = (props) => {
  const tableData = props.data.tableData;
  const { hasDormRooms, hasPrivateRooms } = props.data;
  const tableTitle = "Occupancy";
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = ["string", "percentage", "percentage", "percentage"];
  if (props.level !== "daily")
    excelHeaderType = ["string", "percentage", "percentage", "percentage"];
  const excelColWidth = [15, 20, 20, 20];

  const rowCount = tableData.length;

  let tableColumns = [
    {
      title: "Date",
      field: "date",
      defaultSort: "asc",
      headerStyle: { backgroundColor: "#F6F2EE" },
    },
    {
      title: "Total Occupancy",
      field: "total",
      headerStyle: { backgroundColor: "#F6F2EE" },
    },
  ];

  if (hasDormRooms && hasPrivateRooms) {
    tableColumns = [
      { title: "Date", field: "date", defaultSort: "asc" },
      { title: "Dorm Occupancy", field: "dorm", align: "center" },
      { title: "Private Occupancy", field: "private", align: "center" },
      { title: "Total Occupancy", field: "total", align: "center" },
    ];
  }

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={tableColumns}
      data={tableData}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        search: false,
        sorting: false,
        rowStyle: {
          color: "#333333",
          fontSize: "1.2rem",
        },
        headerStyle: {
          backgroundColor: "#F6F2EE",
          zIndex: 0,
        },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth
          );
        },
      }}
    />
  );
};

const Occupancy = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "future15", label: t("reports.presetRange.future15") },
    { value: "future30", label: t("reports.presetRange.future30") },
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const { open, handleClose, currentSpace, loadingOccupancy, errorOccupancy } =
    props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [yoy, setYoy] = React.useState(false);
  const [submittedYoy, setsubmittedYoy] = React.useState(false);
  const [presetValue, setPresetValue] = React.useState(presetRanges[4]);
  const [submit, setSubmit] = React.useState(true);
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const [level, setLevel] = React.useState("daily");

  const occupancyData = useSelector((state) =>
    occupancyProcessor(
      state.reports.occupancy,
      submittedYoy,
      level,
      submittedDates
    )
  );

  useEffect(() => {
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        submittedDates.startDate.format("YYYY-MM-DD"),
        submittedDates.endDate.format("YYYY-MM-DD"),
        "daily",
        "currentYear"
      )
    );
    document.title = `${t("reports.occupancy")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetOccupancyReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "monthToDate") {
      reportFrom = moment().startOf("month");
    } else if (e.value === "yearToDate") {
      reportFrom = moment().startOf("year");
    } else if (e.value.substr(0, 6) === "future") {
      const num = parseInt(e.value.substr(6));
      reportFrom = moment();
      reportTo = moment().add(num, "days");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 90) {
      newLevel = "monthly";
    } else if (newDiff > 30) {
      newLevel = "monthly";
    }
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD"),
        newLevel,
        "currentYear"
      )
    );
    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setLevel(newLevel);
  };

  const handleSubmit = () => {
    let calcLevel = "daily";
    const difference = dates.endDate.diff(dates.startDate, "days");
    if (difference > 90) {
      calcLevel = "monthly";
    } else if (difference > 30) {
      calcLevel = "monthly";
    }
    props.dispatch(
      loadRevOccupancy(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        calcLevel,
        "currentYear"
      )
    );
    if (yoy) {
      props.dispatch(
        loadRevOccupancy(
          currentSpace.propertyID,
          dates.startDate.clone().subtract(1, "year").format("YYYY-MM-DD"),
          dates.endDate.clone().subtract(1, "year").format("YYYY-MM-DD"),
          "daily",
          "lastYear"
        )
      );
    }
    setsubmittedYoy(yoy);
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
    setLevel(calcLevel);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>
            {t("reports.occupancy")} {t("reports.report")}
          </Typography>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.OCCUPANCY.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <div className={classes.dateHolder}>
                    <DateRangePicker
                      startDate={dates.startDate}
                      startDateId="start_date"
                      endDate={dates.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) => {
                        setDates({ startDate, endDate });
                        setPresetValue(presetRanges[9]);
                      }}
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      }
                      focusedInput={focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={5} item className={classes.dateHolder}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.title}>YoY Analysis</div>
                      <Toggle
                        toggle={yoy}
                        onText="ON"
                        offText="OFF"
                        handleOn={() => setYoy(true)}
                        handleOff={() => setYoy(false)}
                      />
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* --------------------------------------------------------- */}
            {/* OCCUPANCY GRAPH*/}
            {/* --------------------------------------------------------- */}
            {errorOccupancy === "" && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.revenueGraph}>
                  {loadingOccupancy && (
                    <div className={classes.blankSlate}>
                      <CircularProgress />
                    </div>
                  )}

                  {!loadingOccupancy &&
                    submittedYoy &&
                    occupancyData.graphData[0]["Current"] !== null && (
                      <GroupedBarChart
                        keyName={["Previous", "Current"]}
                        data={occupancyData.graphData}
                        maxValue={100}
                        units={"%"}
                        ticks={true}
                      />
                    )}

                  {!loadingOccupancy && !submittedYoy && (
                    <BarChart
                      keyName="occupancy"
                      data={occupancyData.graphData}
                      maxValue={100}
                      units={"%"}
                    />
                  )}
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* OCCUPANCY SUMMARY BUBBLES - NO YOY*/}
            {/* --------------------------------------------------------- */}
            {!loadingOccupancy && !submittedYoy && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {calcAverage(occupancyData.tableData, "total")}
                      </div>
                      <div className={classes.desc}>
                        Average Total Occupancy
                      </div>
                    </div>
                  </Grid>

                  {occupancyData.hasDormRooms &&
                    occupancyData.hasPrivateRooms && (
                      <Grid item xs={6} sm={3} align="center">
                        <div className={classes.bubble}>
                          <div className={classes.amount}>
                            {calcAverage(occupancyData.tableData, "dorm")}
                          </div>
                          <div className={classes.desc}>
                            Average Dorm Occupancy
                          </div>
                        </div>
                      </Grid>
                    )}

                  {occupancyData.hasDormRooms &&
                    occupancyData.hasPrivateRooms && (
                      <Grid item xs={6} sm={3} align="center">
                        <div className={classes.bubble}>
                          <div className={classes.amount}>
                            {calcAverage(occupancyData.tableData, "private")}
                          </div>
                          <div className={classes.desc}>
                            Average Private Occupancy
                          </div>
                        </div>
                      </Grid>
                    )}
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* OCCUPANCY SUMMARY BUBBLES - WITH YOY*/}
            {/* --------------------------------------------------------- */}
            {!loadingOccupancy && submittedYoy && (
              <div className={classes.listSection}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {calcAverageNum(occupancyData.graphData, "Current")}
                      </div>
                      <div className={classes.desc}>
                        Current Average Occupancy
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {calcAverageNum(occupancyData.graphData, "Previous")}
                      </div>
                      <div className={classes.desc}>
                        Previous Average Occupancy
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* OCCUPANCY TABLE */}
            {/* --------------------------------------------------------- */}
            {errorOccupancy === "" && (
              <div className={classes.listSection}>
                {!loadingOccupancy && occupancyData.tableData.length !== 0 && (
                  <BasicExport
                    data={occupancyData}
                    startDate={submittedDates.startDate.format("DD MMM YYYY")}
                    endDate={submittedDates.endDate.format("DD MMM YYYY")}
                    level={level}
                  />
                )}
              </div>
            )}

            {errorOccupancy !== "" && (
              <div className={classes.errorState}>
                <div className={classes.errorMessage}>
                  Oops! Something seems to have gone wrong here.
                </div>
                <div>
                  <Button variant="outlined">Retry</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingOccupancy: state.loading.LOAD_OCCUPANCY_REPORT,
  errorOccupancy: state.errors.LOAD_OCCUPANCY_REPORT,
});

export default withTranslation()(connect(mapStateToProps)(Occupancy));
