import React from 'react';
import parse from 'html-react-parser';
import { isHtml } from '../../utils/utility';
import serializeSlate from './slate-components/serializeSlate';

const CommentDisplay = props =>{
    let comment = props.editorContent;
    
    //Fix to be removed later ...............
    if(comment.length && comment[0] == '"'){
        comment = '';
    }
    
    if(isHtml(comment) == false && comment.trim()){
        comment = JSON.parse(comment);
        comment = serializeSlate({children: comment});
    }

    return(
        <div className="trix-content">{parse(comment)}</div>
    )
}

export default CommentDisplay;