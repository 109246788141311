import http from "../../utils/http";
import { normalize } from 'normalizr';
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentSpace, setSpace, setCurrentSpaceType } from "../dashboard/dashboard";
import {loadNotifications} from "../notifications/notifications";
import {loadMessages} from '../messages/messages';
import  { userSettingSchema } from '../../schema/userUIsettings';
import { setUserSettings } from '../UIsettings/userSettings';

//----------------------------------------------------------------
// REGISTER USER
//----------------------------------------------------------------
export const registerUser = (userData, history) => dispatch => {
  http
    .post("/api/users/register", userData)
    .then(res => {
      const { token, user } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("user", JSON.stringify(user));

      // Set token to Auth header
      setAuthToken(token);

      // Set current user
      dispatch(setCurrentUser(user));
      history.push("/");
    })
    .catch(err =>
      console.log(err)
    );
};

//----------------------------------------------------------------
// LOGIN - get user token
//----------------------------------------------------------------
export const loginUser = (userData, history, experienceSpace) => dispatch => {
  const ACTION_NAME = "LOGIN";
  dispatch({ type: `${ACTION_NAME}_REQUEST` });
  http
    .post("/api/users/login", userData)
    .then(res => {
      // console.log(res.data)

      // Set token to localStorage
      const { token, user, userUIsettings } = res.data;
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);

      // Set current user
      dispatch(setCurrentUser(user));

      if (experienceSpace) {
        dispatch(setSpace(experienceSpace));
        dispatch(setCurrentSpace(experienceSpace._id));
        dispatch(setCurrentSpaceType(experienceSpace.type));
      } else {
        dispatch(setCurrentSpace(user.space[0]));
        dispatch(loadNotifications());
        dispatch(loadMessages());

        // User space level UI settigs.................................................
        var normalizedUserSettings = normalize(userUIsettings, [ userSettingSchema ] );
        dispatch(setUserSettings(normalizedUserSettings.entities.userSettings))
        history.push("/");
      }
      dispatch({ type: `${ACTION_NAME}_SUCCESS` });
    })
    .catch(errorResult => {
        // console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      }
    );
};

//----------------------------------------------------------------
// SET CURRENT USER
//----------------------------------------------------------------
export const setCurrentUser = decoded => {
  return {
    type: "SET_CURRENT_USER",
    payload: decoded
  };
};

//----------------------------------------------------------------
// RESET STATE
//----------------------------------------------------------------
export const resetState = () => ({
  type: 'USER_LOGOUT',
  payload: {}
})


//----------------------------------------------------------------
// SET USER LOADING
//----------------------------------------------------------------
export const setUserLoading = () => {
  return {
    type: "USER_LOADING"
  };
};

//----------------------------------------------------------------
// LOGOUT
//----------------------------------------------------------------
export const logoutUser = () => dispatch => {

  const ACTION_NAME = "LOGOUT";
  dispatch({type: `${ACTION_NAME}_REQUEST`});
  http
    .post('/api/users/logout')
    .then(() => dispatch({type: `${ACTION_NAME}_SUCCESS`}))
    .catch(errorResult => dispatch({type: `${ACTION_NAME}_FAILURE`, payload: errorResult}))
    .finally(() => {

      // Remove token from local storage
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("user");

      // Remove auth header for future requests
      setAuthToken(false);

      // Set current user to empty object {} which will set isAuthenticated to false
      dispatch(setCurrentUser({}));
      dispatch(setCurrentSpace());
      dispatch(resetState());
    });
};
