import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper'
import { triggerSibaQueue} from '../../redux/actions/legal/siba'

import { loadSibaFailed } from '../../redux/actions/legal/siba'

import MaterialTable from 'material-table';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles( theme => ({
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10,
    textAlign: 'center'
  },
  listSection: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  triggerContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  triggerButton: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    color: '#BDBDBD',
    cursor: 'pointer',
    fontSize: '1.1rem',
    padding: theme.spacing(1),
  },
  leftMargin: {
    marginLeft: 20
  },
  table: {
    width: '100%'
  },
  icon: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    marginTop: 3,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  }
}))

const TableData = (props) => {
  
  const handleTrigger = (resvID, index) => {
    props.handleTrigger(resvID)
  }



  const classes = useStyles()

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Reservation Name</TableCell>
            <TableCell align="center">Failed On</TableCell>
            <TableCell align="center">Failure Reason</TableCell>            
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.guestName}
              </TableCell>
              <TableCell align="center">
                {row.failedOn}
              </TableCell>
              <TableCell align="center">
                {row.failureReason}{row.resvID}
              </TableCell>
              <TableCell align="center">
                <span className={clsx(classes.triggerContainer)}>
                  <div className={classes.triggerButton} onClick={() => handleTrigger(row.resvID, index)}>
                    trigger
                  </div>
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Failed = (props) => {
  const { t, currentSpace, legal } = props
  const classes = useStyles()
  const [data, setData] = React.useState([])
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState('');
  
  useEffect(() => {
    let arr = []
    if(legal && legal.siba && legal.siba.failed && legal.siba.failed.length){
      legal.siba.failed.map((f) => {
        arr.push({resvID: f.reservation._id, queueID: f._id, guestName: f.reservation.guestName, failedOn: moment(f.updatedAt).format('DD-MMM-YYYY'), failureReason: f.failureReason})
      })
      setData(arr)
    }
  }, [legal])

  useEffect(() => {
    props.dispatch(loadSibaFailed(currentSpace.propertyID))
  }, [])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleTrigger = (resvID, index) => {

    props.dispatch(triggerSibaQueue(resvID))
    .then((res) => {
      if(res.status === 201) {
        props.dispatch(loadSibaFailed(currentSpace.propertyID))
        setMessage('Item triggered successfully!')
        setOpenSnackbar(true)
        let val = [ ...data]
        val.splice(index, 1)
        setData(val)
      }
    })
  }
  

  const handleDelete = (queueID, index) => {
    props.dispatch(deleteSibaQueue(queueID))
    .then((res) => {
      if(res.status === 200) {
        let val = [ ...data]
        setMessage('Item deleted successfully!')
        setOpenSnackbar(true)
        val.splice(index, 1)
        setData(val)
      }
    })
  }

  return (
    <div className={classes.listSection}>
      {data.length === 0 && <div className={classes.label}>{t('experiences.reports.noData')}</div>}

      {data.length > 0 && <div>
        <TableData
          data={data} 
          handleTrigger={handleTrigger}
          handleDelete={handleDelete}
        />
      </div>}
    {(message) && <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key='top-center'
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      message={message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  legal: state.legal
})

export default withTranslation()(connect(mapStateToProps)(Failed))