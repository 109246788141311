import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

import { getTags } from "../../redux/actions/reservation/reservationTags";
import { fetchBookingSource } from "../../redux/actions/reservation/newReservation";

import InvoiceSettings from "./InvoiceSettings";
import ListSettings from "./ListSettings";
import ColombiaSettings from "./ColombiaSettings";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

import ReservationSettings from "./ReservationSettings";
import PrinterSettings from "./PrinterSettings";
import GeneralSettings from "./GeneralSettings";
import BusinessIdentifiers from "./BusinessIdentifiers";
import TabsView from "../tabs/Tabs";

import { ENABLE_DAC7 } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  listContainer: {
    height: "calc(100vh - 154px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listSection: {
    padding: theme.spacing(3),
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "100%",
  },
  errorContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  leftAlign: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "20%",
  },
  rightAlign: {
    paddingLeft: 30,
    width: "70%",
  },
  name: {
    cursor: "pointer",
    fontSize: "1.6rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  categoryAlert: {
    color: "#999999",
    fontSize: "1.2rem",
    marginTop: 5,
  },
  textField: {
    "& input": {
      fontSize: "1.3rem",
    },
  },
  address: {
    marginBottom: 10,
  },
  addressState: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
  addressStateItem: {
    width: "45%",
  },
  field: {
    cursor: "pointer",
  },
  addressLine: {
    marginBottom: 5,
  },
  moreIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#dddddd",
    },
  },
  cancelButton: {
    marginRight: 10,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  notice: {
    color: "#999999",
    fontSize: "1.2rem",
    marginBottom: 10,
    marginTop: 10,
  },
  iconRow: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 25,
  },
  tabInfo: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: "15px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    height: "100px",
    justifyContent: "center",
    marginRight: 25,
    width: "100px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  tabSelected: {
    background: "#E0E0E0",
    color: "#333333",
    fontWeight: 600,
  },
  picContainer: {
    alignItems: "center",
    // cursor: 'pointer',
    display: "flex",
  },
  placeholder: {
    background: "#ffffff",
    borderRadius: "15px",
    height: 80,
    marginRight: 15,
    width: 80,
  },
  input: {
    display: "none",
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginBottom: 0,
  },
  image: {
    borderRadius: 15,
    objectFit: "cover",
    height: 80,
    width: 80,
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "90%",
    position: "absolute",
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "1.2rem",
  },
}));

const AccountSettings = (props) => {
  const classes = useStyles();
  const {
    property,
    currentSpace,
    loadingProperty,
    currentSpaceID,
    t,
    editLoading,
  } = props;

  const TABS = {
    general: {
      name: "general",
      label: t("account.general.tab"),
      content: <GeneralSettings />,
    },
    invoices: {
      name: "invoices",
      label: t("account.invoices.tab"),
      content: <InvoiceSettings />,
    },
    lists: {
      name: "lists",
      label: t("account.lists.tab"),
      content: <ListSettings />,
    },
    reservations: {
      name: "reservations",
      label: t("account.reservations.tab"),
      content: <ReservationSettings />,
    },
    printer: {
      name: "printer",
      label: t("account.printer.tab"),
      content: <PrinterSettings />,
    },
    colombia: {
      name: "colombia",
      label: t("account.colombia.tab"),
      content: <ColombiaSettings />,
      isDisabled: !currentSpace || currentSpace?.country != "COL",
    },
  };

  if (ENABLE_DAC7)
    TABS.businessIdentifiers = {
      name: "businessIdentifiers",
      label: t("account.businessIdentifiers.tab"),
      content: <BusinessIdentifiers />,
    };

  const [selectedTab, setSelectedTab] = React.useState(TABS.general.name);

  const handleTabSelect = (tab) => {
    if (selectedTab !== TABS.lists.name && tab === TABS.lists.name) {
      props.dispatch(getTags(currentSpaceID, property._id));
      props.dispatch(fetchBookingSource(property._id, currentSpaceID));
    }
    setSelectedTab(tab);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t("navbar.accountSettings")}</div>
          <CloseIcon
            className={classes.closeIcon}
            onClick={props.handleClose}
          />
        </div>

        <div className={classes.listContainer}>
          {loadingProperty && (
            <div className={classes.listSection}>
              {t("navbar.loadingProperty")}
            </div>
          )}

          {!loadingProperty && (
            <TabsView
              tabs={TABS}
              handleTabSelect={handleTabSelect}
              selectedTab={selectedTab}
            />
          )}

          {editLoading && (
            <div className={"loadingOverlay"}>
              <CircularProgress className={"loading"} />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  property: state.property,
  loadingProperty: state.loading?.LOAD_PROPERTY,
  editLoading: state.loading?.EDIT_PROPERTY,
});

export default withTranslation()(connect(mapStateToProps)(AccountSettings));
