import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';

import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MaterialTable, { MTableBody } from 'material-table';
import ArrowIcon from '@material-ui/icons/ArrowForward';

import Pagination from '../common/Pagination';
import { exportExcel } from '../utils/excelExport';
import { DATE_FORMATS, fetchCurrencyFormat, fetchDateFormat } from '../../utils/utility';
import { fetchChannelLogInfo } from '../../redux/actions/channelMapping/channelMapping';
import RowDetails from './RowDetails';

const styles = theme => ({
    dialog: {
        overflowY: 'hidden'
    },
    dialogPaper: {
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    mainContainer: {
        height: 'calc(100vh - 154px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 88px)'
        }
    },
    listSection: {
        margin: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        position: 'relative',
        '&.tableContent': {
            minHeight: 'calc(100vh - 450px)'
        }
    },
    rightAlign: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        '& .DateInput': {
            width: 120
        },
        '& .DateInput_input': {
            width: 'calc(100% - 22px)'
        },
        '& .DateRangePickerInput': {
            boxShadow: '0 2px 30px #F0F0F0',
        }
    },
    fieldContainer: {
        boxShadow: '0 1px 10px #E0E0E0',
        color: '#484848',
        fontSize: '1.4rem',
        fontWeight: 600,
    },
    loadingState: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
        zIndex: 999
    },
    buttonContainer: {
        alignItems: 'center',
        background: '#999999',
        borderRadius: '2px',
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    go: {
        color: '#ffffff',
        fontWeight: 600,
    },
});

const selectStyle = {
    control: base => ({
        ...base,
        border: 0,
        paddingLeft: 8,
        // This line disable the blue border
        boxShadow: 'none',
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }),
    placeholder: base => ({
        ...base,
        fontWeight: 500,
    })
};

const limitOptions = [
    { label: 50, value: 50 },
    { label: 60, value: 60 },
    { label: 80, value: 80 },
]

const BasicExport = props => {

    const { paginationProps, currentSpace } = props;
    const tableTitle = 'Channel logs';
    const displayDate = props.sameDate ? props.startDate : `${props.startDate} - ${props.endDate}`;

    return (
        <MaterialTable
            title={`${tableTitle} ${displayDate}`}
            columns={[
                { title: 'Date', field: 'createdAt', render: rowData => <>{fetchDateFormat(rowData.createdAt, DATE_FORMATS.FULLDATE_TIME, currentSpace.timezone)}</> },
                { title: 'Channel', field: 'channel' },
                { title: 'Channel property', field: 'channel_propertyID' },
                { title: 'Type', field: 'type' },
                { title: 'Success', field: 'success', align: 'center', render: rowData => <>{rowData.success ? 'Success': 'Error'}</> },
            ]}
            data={props.data}
            localization={{
                toolbar: { exportCSVName: "Export as Excel" }
            }}
            options={{
                exportButton: true,
                rowStyle: { fontSize: '1.2rem' },
                paging: false,
                headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0 },
                detailPanelColumnAlignment: 'right',
                exportCsv: (columns, data) => {
                    const tableData = _.cloneDeep(data);
                    const headerConfig = [...columns];
                    const filename = `${tableTitle} ${displayDate}`;
                    exportExcel(filename, headerConfig, tableData);
                }
            }}
            components={{
                Body: props => (
                    <>
                        <MTableBody {...props} />

                        {paginationProps.pagination.total > limitOptions[0].value && (
                            <tfoot>
                                <tr>
                                    <td colSpan="7">
                                        <Pagination {...paginationProps} />
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </>
                )
            }}
            detailPanel={ rowData =>(
                <RowDetails rowData={rowData}/>
            )}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
    )
}

class ChannelLog extends Component {

    state = {
        focusedInput: null,
        openReservation: false,
        isExpandAll: false,
        isCollapseAll: false,
        reportData: [],
        pagination: {
            total: 0,
            selectedLimit: limitOptions[0].value,
            currentOffset: 0
        }
    }

    constructor(props) {
        super(props)
        this.presetRanges = this.getPresetRanges();
        this.state.presetValue = this.presetRanges[3];
        const { reportFrom, reportTo } = this.fetchDateRange(this.presetRanges[3].value);
        const initDates = { startDate: reportFrom, endDate: reportTo };

        const currency = fetchCurrencyFormat(props.currentSpace.currency ? props.currentSpace.currency : null);
        this.state.currency = currency;
        this.state.dates = { ...initDates };
        this.state.submittedDates = { ...initDates };
    }

    componentDidMount() {
        const { dates, pagination } = this.state;
        const paginationData = {
            offset: pagination.currentOffset,
            limit: pagination.selectedLimit
        };
        this.getChannelLogInfo(dates, paginationData);
    }

    getChannelLogInfo = (dates, paginationData) => {
        const { dispatch, currentSpace } = this.props;
        let success = false;

        if (!dates.endDate) return;

        const params = {
            startDate: dates.startDate.clone().format("YYYY-MM-DD"),
            endDate: dates.endDate.clone().format("YYYY-MM-DD"),
            limit: paginationData.limit,
            offset: paginationData.offset
        }
        const response = dispatch(fetchChannelLogInfo(currentSpace.propertyID, params));
        response.then(data => {
            if (data && data.logs && data.logs.length > 0) {
                this.setState({
                    pagination: {
                        selectedLimit: paginationData.limit,
                        currentOffset: paginationData.offset,
                        total: data.total
                    },
                    reportData: [...data.logs],
                    submittedDates: { startDate: dates.startDate, endDate: dates.endDate }
                });
                return;
            }

            this.setState({
                pagination: {
                    selectedLimit: paginationData.limit,
                    currentOffset: 0,
                    total: 0
                },
                reportData: [],
                submittedDates: { startDate: dates.startDate, endDate: dates.endDate }
            })
        })
        return success;
    }

    getPresetRanges = () => {
        const { t } = this.props;
        return [
            { value: 'today', label: t('reports.presetRange.today') },
            { value: 'yesterday', label: t('reports.presetRange.yesterday') },
            { value: 'past15', label: t('reports.presetRange.past15') },
            { value: 'past30', label: t('reports.presetRange.past30') },
            { value: 'custom', label: t('reports.presetRange.custom') }
        ];
    }

    fetchDateRange = (filterType) => {
        let reportFrom;
        let reportTo;

        switch (filterType) {
            case 'today':
                reportFrom = moment();
                reportTo = moment();
                break;

            case 'tomorrow':
                reportFrom = moment().add(1, 'day');
                reportTo = moment().add(1, 'day');
                break;

            case 'yesterday':
                reportFrom = moment().subtract(1, 'day');
                reportTo = moment().subtract(1, 'day');
                break;

            case 'past15':
            case 'past30':
                const num = parseInt(filterType.substr(4));
                reportFrom = moment().subtract(num, 'days');
                reportTo = moment();
                break;
        }

        return { reportFrom, reportTo };
    }

    setNewDates = data => {
        this.setState({ ...this.state, presetValue: data });
        if (data.value == 'custom') {
            return
        }
        const filterType = data.value;
        const { reportFrom, reportTo } = this.fetchDateRange(filterType);
        this.setState({
            dates: {
                startDate: reportFrom,
                endDate: reportTo
            }
        });
    }

    handleSubmit = () => {
        const { isLoading } = this.props;
        const { dates, pagination } = this.state;

        if (isLoading) return;

        const paginationData = {
            limit: pagination.selectedLimit,
            offset: 0
        }

        this.getChannelLogInfo(dates, paginationData);
    }

    //Pagination functions
    handlePaginationChange = (paginationData) => {
        const { dates } = this.state;
        const { isLoading } = this.props;

        if (isLoading) return;

        this.getChannelLogInfo(dates, paginationData);
    }

    render() {
        const {
            dates,
            focusedInput,
            reportData = [],
            pagination,
            currency,
            submittedDates
        } = this.state;

        const { classes, t, open, isLoading, handleClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{ paperFullWidth: classes.dialogPaper }}
                fullScreen={true}
            >
                {/* --------------------------------------------------------- */}
                {/* Header */}
                {/* --------------------------------------------------------- */}
                <div className={classes.dialog}>

                    <div className={classes.dialogTitle}>
                        <div className={classes.header}>Channel Log {t('reports.report')}</div>
                        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                    </div>

                    <div className={classes.mainContainer}>

                        {/* --------------------------------------------------------- */}
                        {/* DATE RANGE FILTERS */}
                        {/* --------------------------------------------------------- */}
                        <div className={clsx(classes.listSection, classes.rightAlign)}>
                            <Grid container spacing={2}>

                                {/* -------------DURATION SELECT DROPDOWN----------------- */}
                                <Grid item xs={6} md={3}>
                                    <div className={classes.fieldContainer}>
                                        <Select
                                            value={this.state.presetValue}
                                            styles={selectStyle}
                                            options={this.presetRanges}
                                            onChange={this.setNewDates}
                                            theme={theme => ({
                                                ...theme,
                                                border: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#666666',
                                                    primary25: '#dddddd'
                                                },
                                            })}
                                        />
                                    </div>
                                </Grid>

                                {/* -------------DATEPICKER----------------- */}
                                <Grid item>
                                    <div className={clsx(classes.fieldContainer, classes.dateContainer)}>
                                        <DateRangePicker
                                            startDate={dates.startDate}
                                            startDateId="start_date"
                                            endDate={dates.endDate}
                                            endDateId="end_date"
                                            onDatesChange={({ startDate, endDate }) => {
                                                this.setState({
                                                    ...this.state,
                                                    dates: { startDate, endDate },
                                                    presetValue: this.presetRanges[4]
                                                })
                                            }}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            focusedInput={focusedInput}
                                            displayFormat={'DD MMM YYYY'}
                                            hideKeyboardShortcutsPanel
                                            isOutsideRange={() => false}
                                            numberOfMonths={1}
                                            noBorder={true}
                                            minimumNights={0}
                                        />
                                    </div>
                                </Grid>

                                {/* -------------ARROW BUTTON----------------- */}
                                <Grid item xs={2} md={1} lg={1}>
                                    <div className={classes.buttonContainer} onClick={this.handleSubmit}>
                                        <ArrowIcon className={classes.go} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className={clsx(classes.listSection, 'tableContent')}>

                            {isLoading && <div className={classes.loadingState}><CircularProgress /></div>}

                            {/* Blank State */}
                            {/* ------------------------------------------------------------------ */}
                            {!isLoading && reportData.length == 0 &&
                                <div className={clsx(classes.loadingState, classes.blankState)}>
                                    No channel logs available for this date range.
                                </div>
                            }

                            {reportData.length > 0 && (
                                <BasicExport
                                    t={t}
                                    data={reportData}
                                    currentSpace={this.props.currentSpace}
                                    startDate={submittedDates.startDate.format('DD MMM YYYY')}
                                    endDate={submittedDates.endDate.format('DD MMM YYYY')}
                                    sameDate={moment(submittedDates.startDate).isSame(submittedDates.endDate, 'day')}
                                    paginationProps={{
                                        pagination,
                                        limitOptions,
                                        dataCount: reportData.length,
                                        setPaginationLimit: this.setPaginationLimit,
                                        handlePaginationChange: this.handlePaginationChange
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const { spaces, dashboard, loading } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        isLoading: loading.FETCH_CHANNEL_LOG
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(ChannelLog)));