import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { fetchDateFormat } from '../../../../utils/utility';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: 0,
	},
	section: {
		backgroundColor: "#C87250",
		padding: '10px 0px 10px 30px',
		display: 'flex',
		alignItems: 'center',
		minHeight: 65,
		width: '30%',
		// justifyContent: 'space-around',
		flexWrap: 'wrap',
		color: '#fff',
		borderRadius: 5,
		lineHeight: '20px',
		'&.attach': {
			borderRadius: '10px 10px 0 0'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	primaryText: {
		fontSize: '1.4rem'
	},
	secondaryText: {
		fontSize: '1.2rem'
	},
	linkText: {
		cursor: 'pointer',
		color: '#E5A451',
		fontSize: '1rem'
	},
}));

const titleCase = (text) => {
	if (!text || text?.length == 0) return text;
	text = text.toLowerCase();
	return text[0].toUpperCase() + text.substr(1);
}

const Status = (props) => {
	const classes = useStyles();
	const { bookedExperience = {} } = props;

	return (
		<div className={classes.container}>
			<div className={clsx(classes.section, { ['attach']: props.isDisplay })} onClick={props.displayFormHandler}>
				<div className={classes.details}>
					<div className={clsx(classes.primaryText)}>{titleCase(bookedExperience.status)}</div>
					<div className={clsx(classes.secondaryText)}>{fetchDateFormat(bookedExperience.createdAt, 'Do MMM YYYY hh:mm a')}</div>
					<div className={clsx(classes.linkText)}>Reservation details</div>
				</div>
			</div>
		</div>
	)

}

export default Status;