import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import BackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    width: 400,
  },
  headerRow: {
    position: 'relative',
    justifyContent: 'center',
  },
  back: {
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#666666',
    padding: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    '&:hover': {
      background: '#F0F0F0'
    }
  },
  header: {
    fontSize: '1.8rem',
    fontWeight: 600,
    textAlign: 'center',
    width: '100%',
    marginTop: 8,
  },
  title: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10,
  },
  subTitle: {
    color: '#000000',
    fontSize: '1.3rem',
  },
  button: {
    background: '#CC6633',
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    padding: '15px 20px',
  },
  errorText: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

const ForgotPassword = (props) => {

  const classes = useStyles();
  
  return(
    <>
      <div className={clsx(classes.row, classes.headerRow)}>
        <div className={classes.header}>
          Forgot Password
        </div>
        <BackIcon className={classes.back} onClick={props.handleBack} />
      </div>

      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Email</div>
        <div className={classes.subTitle}>
          {props.userEmail}
        </div>
      </div>

      <div className={classes.row}>
        <div>Please confirm that you would like to reset password via email</div>
      </div>

      {/* Next Button */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.button} onClick={props.handleReset}>Reset My Password</div>
      </div>
    </>
  )
}

export default ForgotPassword;