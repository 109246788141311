import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Dialog from '../../common/CustomDialog';
import Button from '@material-ui/core/Button';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import BookingSources from './BookingSources';
import GuestForm from './GuestForm';
import TagGuestDrawer from './TagGuestDrawer';

import { checkDateIfGreater, fetchDatePerTimezone, getMomentFormat } from '../../../utils/utility';
import { getAllSelectedBedIDs, getTodaysDate, validateDocExpiry } from '../helper';
import { createNewBooking, searchCustomers } from '../../../redux/actions/floorPlan/floorPlan';
import { resetError } from '../../../redux/actions/common/common';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    footer: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: "15px 30px",
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#fff'
    },
    button: {
        background: '#000',
        color: '#fff',
        width: 100,
        height: 40,
        fontWeight: 700,
        '&:hover': {
          background: '#000'
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        }
    },
}));

const defaultGuestFormData = {
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    nationality:"",
    dateOfBirth:"",
    gender:"N/A",
    documents:[],
    companyName:"",
    taxID:"",
    address:{
        lineOne:"",
        city:"",
        state:"",
        country:"",
        zipCode:""
    }
}

const ERROR_FIELDS = {
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    docExpiry: false,
    bookingSource: false
}

const CreateReservationModal = props =>{
    const classes = useStyles();
    const { 
        dispatch, 
        currentSpace, 
        searchInProgress, 
        closeModalHandler,
        selectedBedsInfo,
        ratePlanID,
        startDate,
        endDate,
        openReservationHandler, //HOC prop,
        isLoading,
        errors,
        resetHandler
    } = props;

    const [guestDetails, setGuestDetails] = useState(defaultGuestFormData);
    const [selectedBookingSource, setSelectedBookingSource] = useState(null);
    const [formErrors, setFormErrors] = useState(ERROR_FIELDS);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [showGuestOptions, setShowGuestOptions] = useState(false);
   
    const guestFormInputHandler = (key, value) =>{
        let guestInfo = cloneDeep(guestDetails);
        set(guestInfo, key, value);
        setGuestDetails({ ...guestInfo });

        //SEARCH CUSTOMER...................
        if(key == 'firstName'){
            customerSearchHandler(guestInfo);
        }

        //RESET FORM FIELD ERROR................
        if(Object.keys(ERROR_FIELDS).includes(key) && formErrors[key]){
            setFormErrors({ ...formErrors, [key]: false });
        }
    }

    const validateGuestForm = () =>{
        const errors = { ...ERROR_FIELDS }
        let success = true;

        if(!selectedBookingSource){
            errors.bookingSource = true;
            success = false;
        }

        if(!guestDetails?.firstName || !guestDetails.firstName.trim()){
            errors.firstName = true;
            success = false;
        }

        if(!guestDetails?.lastName || !guestDetails.lastName.trim()){
            errors.lastName = true;
            success = false;
        }

        if (guestDetails.dateOfBirth && checkDateIfGreater(guestDetails.dateOfBirth, fetchDatePerTimezone(null))) {
            errors.dateOfBirth = true;
            success = false;
        }

        const docExpiryDate = guestDetails?.documents?.[0]?.expiry || null;
    
        if(docExpiryDate && !validateDocExpiry(docExpiryDate, currentSpace.timezone)){
            errors.docExpiry = true;
            success = false;
        }

        setFormErrors({ ...errors });
        return success;
    }

    const submitHandler = () =>{
   
        if(!validateGuestForm()) return;

        const payload = {
            bedIDs: getAllSelectedBedIDs(selectedBedsInfo),
            ratePlanID,
            startDate: getMomentFormat(startDate).format('YYYY-MM-DD'),
            endDate: getMomentFormat(endDate).format('YYYY-MM-DD'),
            customer: { ...guestDetails }
        }

        dispatch(createNewBooking(currentSpace.propertyID, payload))
            .then(response => {
                if(response.success  && response?.newRes?._id){ 
                    closeModalHandler();
                    openReservationHandler(response.newRes._id);
                    resetHandler();
                }
         })
    }

    const sourceSelectHandler = useCallback(source =>{
        setSelectedBookingSource(source);
    }, []);

    const customerSearchHandler = (guestInfo) =>{
        const params = {
            firstName: guestInfo.firstName,
            lastName: guestInfo.lastName,
            phone: guestInfo.phone, 
            email: guestInfo.email, 
            nationality: guestInfo.nationality
        }
        dispatch(searchCustomers(currentSpace.propertyID, params))
            .then(response =>{
                if(response) setCustomerOptions([ ...response ]);
            })
    }

    const tagCustomerHandler = (guestInfo) =>{
        const customerInfo = cloneDeep(guestDetails);

        customerInfo.customerID = guestInfo._id;

        const fields = ['firstName', 'lastName', 'email', 'phone', 'nationality'];
        fields.forEach(field => {
            customerInfo[field] = guestInfo[field];
        });
        setGuestDetails({ ...customerInfo });
        setShowGuestOptions(false);
    }

    const showGuestHandler = () =>{
        // if(customerOptions.length > 0){
            setShowGuestOptions(true);
        // }
    }

    const closeDrawerHandler = () =>{
        setShowGuestOptions(false);
    }

    const handleCloseError = () =>{
        dispatch(resetError('CREATE_NEW_BOOKING'));
    }

    return (
        <Dialog
            headerTitle="New Booking"
            maxWidth="md"
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
        >
            <BookingSources 
                selectedSource={selectedBookingSource}
                sourceSelectHandler={sourceSelectHandler}
                error={formErrors.bookingSource}
                currentSpace={currentSpace}
            />

            <GuestForm
                data={guestDetails}
                formErrors={formErrors}
                onChangeHandler={guestFormInputHandler}
                todaysDate={getTodaysDate(currentSpace.timezone)}
                showGuestHandler={showGuestHandler}
            />

            {/* --------------------DRAWER------------------- */}
            {showGuestOptions && (
                <TagGuestDrawer 
                    customerOptions={customerOptions}
                    tagCustomerHandler={tagCustomerHandler}
                    searchInProgress={searchInProgress}
                    closeDrawerHandler={closeDrawerHandler}
                />
            )}

            {/* -----------------FOOTER-------------- */}
            <div className={classes.footer}>
                <Button 
                    className={classes.button}
                    onClick={submitHandler}
                >
                    Book
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { spaces, dashboard, loading, errors } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace] || {},
        searchInProgress: loading.SEARCH_CUSTOMERS,
        isLoading: loading.CREATE_NEW_BOOKING,
        errors: errors.CREATE_NEW_BOOKING
    }
}

export default connect(mapStateToProps)(CreateReservationModal);