import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import BarChart from '../../charts/BarChart';
import GroupedBarChart from '../../charts/GroupedBarChart';
import { fetchExperiencesOccupancy, resetExperienceOccupancyReport } from '../../../redux/actions/experiences/reports';
import { experienceOccupancyProcessing, experienceOccupancyTable } from '../../../redux/selectors/reports/experienceReservations';

import ArrowIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from 'material-table';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  iconHolder: {
    alignItems: 'center',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    height: 120,
    justifyContent: 'center',
    zIndex: 2,
    '&:hover': {
      background: '#eeeeee'
    }
  },
  icon: {
    fontSize: '5rem'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  listContainer: {
    //minHeight: 'calc(100vh - 230px)',
    height: 'calc(100vh - 190px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 124px)',
    }
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 70,
  },
  rightAlign: {
    boxSizing: 'border-box',
    display: 'flex',
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
  },
  fieldContainer: {
    borderRadius: 5,
    boxShadow: '0 1px 10px #E0E0E0',
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  dateHolder: {
    '& .DateRangePicker_picker': {
      top: '45px !important',
    },
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      border: 'none',
      borderRadius: 5,
      boxShadow: '0 1px 10px #E0E0E0',
    }
  },
  buttonContainer: {
    alignItems: 'center',
    background: '#999999',
    borderRadius: '2px',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  go: {
    color: '#ffffff',
    fontWeight: 600,
  },
  revenueGraph: {
    height: 400,
    width: '100%'
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  loadingSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '200px',
    width: '100%'
  },
  errorState: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%'
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  bubble: {
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0 2px 30px #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    height: 130,
    justifyContent: 'center',
    padding: 10,
    width: 130,
    borderRadius: '50%',
  },
  amount: {
    color: '#000000',
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  desc: {
    color: '#666666',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginTop: 5,
  },
  toggleHolder: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 600,
    marginRight: 20,
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex',
    width: 150,
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
}));

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const BasicExport = (props) => {
  const rowCount = props.data.length;
  return (
    <MaterialTable
      title={`${props.productName} - ${props.startDate} to ${props.endDate}`}
      columns={[
        { title: 'Experience On', field: 'date', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', fontWeight: 600}},
        { title: 'Total Capacity', field: 'capacity', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', fontWeight: 600}},
        { title: 'No. of Bookings', field: 'booked', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', fontWeight: 600}},
        { title: 'Total Occupancy', field: 'occupancy', align: 'center', headerStyle: { backgroundColor: '#F6F2EE', fontWeight: 600}}
      ]}
      data={props.data}        
      options={{
        exportButton: true,
        search: false,
        rowStyle: { fontSize: '1.2rem'},
        pageSizeOptions: rowCount > 20 ? [5, 10, 20, rowCount] : [5, 10, 20]
      }}
    />
  )
}

const calcAverage = (array, filter) => {
  let count = array.length;
  let amount = 0;
  array.map(e => {
    let str = e[filter];
    str = str.substring(0, str.length - 1);
    amount += parseFloat(str);
  });
  if (count!==0) {
    return `${parseFloat(amount/count).toFixed(2)}%`
  } else return 'NA'
}


const Occupancy = (props) => {
  const { t } = props

  const presetRanges = [
    { value: 'future15', label: t('reports.presetRange.future15') },
    { value: 'future30', label: t('reports.presetRange.future30') },
    { value: 'monthToDate', label: t('reports.presetRange.monthToDate') },
    { value: 'yearToDate', label: t('reports.presetRange.yearToDate') },
    { value: 'past15', label: t('reports.presetRange.past15') },
    { value: 'past30', label: t('reports.presetRange.past30') },
    { value: 'custom', label: t('reports.presetRange.custom') }
  ]

  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const classes = useStyles();
  const { open, handleClose, currentSpace, errorOccupancy, experiences, loadingOccupancy } = props
  const [dates, setDates] = React.useState({startDate: moment(), endDate: moment().add(30, 'days')})
  const [submittedDates, setSubmittedDates] = React.useState({startDate: moment().subtract(15, 'days'), endDate: moment()})
  const [focusedInput, setFocusedInput] = React.useState(null)
  const [preset, setPreset] = React.useState(1)
  const [submit, setSubmit] = React.useState(true)
  const [initial, setInitial] = React.useState(true)

  const [productList, setProductList] = React.useState([])
  const [perData, setPerData] = React.useState({perClass: null, perDay: null})
  const [selectedProduct, setSelectedProduct] = React.useState(null)
  const [level, setLevel] = React.useState('daily');
  const [message, setMessage] = React.useState('')

  const graphData = useSelector(state => experienceOccupancyProcessing(state.reports.experienceOccupancy, level))
  const tableData = useSelector(state => experienceOccupancyTable(state.reports.experienceOccupancy, level))
  
  if((experiences.activityList) && (initial)) {
    let list = []
    setInitial(false)
    
    Object.keys(experiences.activityList).forEach(function (key) {
      list.push({value: experiences.activityList[key].publishedProductID, label: experiences.activityList[key].title})
    })
    setProductList([...productList, ...list])
    setSelectedProduct(list[0])
  }

  const setNewDates = (e) => {
    setPreset(presetRanges.find(item => item.value==e.value))
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = 'daily';
    if (e.value==='monthToDate') {
      reportFrom = moment().startOf('month');
    } else if (e.value==='yearToDate') {
      reportFrom = moment().startOf('year');
    } else if (e.value.substr(0,6)==='future') {
      const num = parseInt(e.value.substr(6));
      reportFrom=moment();
      reportTo = moment().add(num, 'days');
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, 'days');
    }
    const newDiff = reportTo.diff(reportFrom, 'days');
    if (newDiff > 90) {
      newLevel = 'monthly'
    } else if (newDiff > 30) {
      newLevel = 'monthly'
    };

    setLevel(newLevel);
    setDates({startDate: reportFrom, endDate: reportTo});
    //setSubmittedDates({startDate: reportFrom, endDate: reportTo});
  }

  const selectProduct = (e) => {
    setSelectedProduct({ ...selectedProduct, value: e.value, label: e.label})
  }

  const handleSubmit = () => {
    if(Object.keys(experiences.activityList).length !== 0) {
      let calcLevel = 'daily';
      const difference = dates.endDate.diff(dates.startDate, 'days');
      if (difference > 90) {
        calcLevel = 'monthly'
      } else if (difference > 30) {
        calcLevel = 'monthly'
      }

      const data = {
        productID: selectedProduct.value,
        startDate: moment(dates.startDate).format('YYYY-MM-DD'),
        endDate: moment(dates.endDate).format('YYYY-MM-DD'),
        propertyID: currentSpace.propertyID,
        level: calcLevel
      }

      props.dispatch(fetchExperiencesOccupancy(data))
      setSubmittedDates({startDate: dates.startDate, endDate: dates.endDate});
      setLevel(calcLevel)
    }
    else {
      setMessage(t('experiences.reports.addExperience'))
      setOpenSnackbar(true)
    }
  }

  const handleCloseDialog = () => {
    props.dispatch(resetExperienceOccupancyReport())
    handleClose()
  }

  useEffect (() => {
    handleSubmit()
  }, [])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const setDatePicker = (startDate, endDate) => {
    setDates({ startDate, endDate })
    setPreset(6)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography className={classes.header}>{t('reports.occupancy')} {t('reports.report')}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleCloseDialog} />
        </div>

        <div className={classes.listContainer}>

          {/* --------------------------------------------------------- */}
          {/* DATE RANGE FILTERS */}
          {/* --------------------------------------------------------- */}
          <div className={clsx(classes.listSection, classes.rightAlign)}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <div className={classes.fieldContainer}>
                  <Select 
                    value={presetRanges[`${preset}`]}
                    styles={selectStyle}
                    options={presetRanges}
                    onChange={setNewDates}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid xs={6} md={4} item className={classes.dateHolder}>
                <DateRangePicker
                  startDate={dates.startDate}
                  startDateId="start_date"
                  endDate={dates.endDate}
                  endDateId="end_date"
                  onDatesChange={({ startDate, endDate }) => setDatePicker(startDate, endDate)}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  focusedInput={focusedInput}
                  displayFormat={'DD MMM YYYY'}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.fieldContainer}>
                  <Select 
                    defaultValue={productList[0]}
                    styles={selectStyle}
                    options={productList}
                    onChange={selectProduct}
                    theme={theme => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={2} md={1} lg={1}>
                <div className={classes.buttonContainer} onClick={handleSubmit}>
                  <ArrowIcon className={classes.go} />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------------------------- */}
          {/* OCCUPANCY GRAPH*/}
          {/* --------------------------------------------------------- */}
          <div className={classes.listSection}>
            {loadingOccupancy && <div className={classes.loadingSlate}><CircularProgress /></div>}
            {!loadingOccupancy && <div className={classes.revenueGraph}>

              {(graphData.length === 0) && <div className={classes.blankSlate}>{t('experiences.reports.noData')}</div>}

              {(graphData.length > 0) &&
                <BarChart keyName={'occupancy'} data={graphData} maxValue={100} units={'%'} ticks={false}/>
              }

            </div>}
          </div>
          {!loadingOccupancy && (tableData.length > 0) && <div className={classes.listSection}>
            <BasicExport
              productName={selectedProduct.label}
              data={tableData} 
              startDate={submittedDates.startDate.format('DD MMM YYYY')} 
              endDate={submittedDates.endDate.format('DD MMM YYYY')} 
            />
          </div>}
        </div>
        {(message) && <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key='top-center'
          autoHideDuration={1000}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />}
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingOccupancy: state.loading.LOAD_OCCUPANCY_REPORT,
  errorOccupancy: state.errors.LOAD_OCCUPANCY_REPORT,
  experiences: state.experiences,
  loadingOccupancy: state.loading.LOAD_EXPERIENCES_OCCUPANCY_REPORT
})

export default withTranslation()(connect(mapStateToProps)(Occupancy));



