import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme =>({
    root:{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        position: 'relative',
        padding: '20px 0px',
    },
    banner:{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
        height: '170px',
        width: '170px',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        [theme.breakpoints.down("sm")]:{
            height: '150px',
            width: '150px',
        }
    },
    closeIcon:{
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 20,
        transition: '150ms',
        top: 20,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    message: {
        padding: '20px',
        fontSize: '1.5rem',
        lineHeight: '20px',
    }
})); 

const UpgradeSuccessModal = props =>{
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <CloseIcon className={classes.closeIcon} onClick={props.closeModalHandler} />
            <div className={classes.banner}>
                <img src="/images/icons/rocket-man.jpg" />
            </div>
            <div className={classes.message}>You have successfully upgraded your plan!</div>
        </div>
    )
}

export default UpgradeSuccessModal;