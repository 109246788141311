import React from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { getDatesOfRange, fetchDatePerTimezone } from '../../utils/utility';
import ExtendReservation from './edit-reservation/ExtendReservations';
import SelectBedForm from './edit-reservation/SelectBedForm';
import ChangeRateForm from './edit-reservation/ChangeRateForm';
import { createNewReservationAccommodations, editReservationExtendStay } from './reservationHelper';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'calc(100% - 60px)',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    padding: 30,
    marginTop: 10,
    marginBottom: 30,
    transition: 'visibility 0s linear 0.7s, opacity 0.7s ease-in-out'
  },
  actionTitle: {
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 30,
    marginTop: 10
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10
  },
  sectionChips: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 30,
    flexWrap: 'wrap'
  },
  chips: {
    padding: '10px 15px',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    borderRadius: 2,
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: 4,
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: '#eeeeee',
    }
  },
  success: {
    color: '#4caf50'
  },
  warning: {
    color: '#ff9800'
  },
  errorContainer: {
    display: 'flex',
    padding: 15,
    borderRadius: 2,
    backgroundColor: '#fff',
    fontWeight: 500,
    color: '#e37381'
  },
  error: {
    margin: '15px 0',
    color: '#e37381'
  },
  break: {
    margin: '20px -30px',
    width: 'calc(100% + 60px)',
    height: 4,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
  },
  form: {
    display: 'grid'
  },
  button: {
    backgroundColor: '#eeeeee',
    width: '100%',
    padding: '10px 0',
    '&:hover': {
      backgroundColor: '#eeeeee',
    }
  },
  field: {
    background: '#ffffff',
    borderRadius: 2,
    width: '25%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  block: {
    display: 'block',
    padding: '0 4px'
  },
  hide: {
    display: 'none',
  },
  extendContainer: {
    margin: '20px 0 30px'
  },
  extend: {
    margin: '20px 0 20px 0px;',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      margin: '0 25px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
      '& > *': {
        marginTop: 10,
        marginBottom: 10,
      }
    }
  },
  plusminusContainer: {
    width: 170,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  extendButton: {
    width: 170,
    backgroundColor: '#12408C',
    color: '#fff',
    height: 50,
    '&:hover': {
      backgroundColor: '#12408C',
    },
    '&.Mui-disabled': {
      backgroundColor: 'grey',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  closeIcon: {
    cursor: 'pointer'
  },
  datepicker: {
    border: '1px solid #eeeeee',
    width: 155,
    height: 48,
    '& > div, > div > div, > div > div > div, .DateInput_1': {
      width: '100%',
      height: '100%',
    },
    '& .SingleDatePicker': {
      width: '100%',
      height: '100%'
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)',
      padding: '17px 11px 5px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  }
}));

const ManualReservationForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, data, selectedDate, itemID, startDate, endDate, roomTypes, rooms, beds, manualOptions, formType, t } = props;
  const [displayData, setDisplayData] = React.useState({});

  const [roomTypeKey, setRoomTypeKey] = React.useState(null);
  const [roomKey, setRoomKey] = React.useState(null);
  const [bedKey, setBedKey] = React.useState(null);
  const [isAvailable, setIsAvailable] = React.useState(false);
  const [isRateEntireStay, setRateEntireStay] = React.useState(false);
  const [isSelectionEntireStay, setSelectionEntireStay] = React.useState(false);
  const [amount, setAmount] = React.useState(0);

  const [formChangeStatus, setFormChangeStatus] = React.useState(false);
  const [displayTypes, setDisplayTypes] = React.useState(false);
  const [displayRooms, setDisplayRooms] = React.useState(false);
  const [displayBeds, setDisplayBeds] = React.useState(false);
  const [bedOrRoom, setBedOrRoom] = React.useState('room');
  const [typeOfRoom, setTypeOfRoom] = React.useState(null);
  const [next, setNext] = React.useState(false);

  //Keys for edit
  const [nights, setNights] = React.useState(0);
  const extend = (formType && formType === "extend") ? true : false;
  // const arrivalChange = (formType && formType === "arrivalChange") ? true : false;
  const changeRate = (formType && formType === "changeRate") ? true : false;
  const roomChange = (formType && formType === "roomChange") ? true : false;
  const [displayExtend, setDisplayExtend] = React.useState(extend ? true : false);
  const [ButtonText, setButtonText] = React.useState(t('actions.save'));

  React.useEffect(() => {
    handleProcessFormData();
  }, [data, selectedDate, manualOptions])

  React.useImperativeHandle(ref, () => ({
    handleFormStatus(newDate) {
      return handleCheckFormChanged(newDate);
    }
  }))

  const handleProcessFormData = () => {
    let formData = handleDefaultData();
    setAmount(isNaN(formData.rate) ? 0 : parseFloat(formData.rate));
    let isBed = formData.roomTypeID && roomTypes[formData.roomTypeID] && roomTypes[formData.roomTypeID].dormOrPrivate === 'dorm' && true;
    setBedOrRoom(isBed ? 'bed' : 'room');
    if (roomChange || extend) {
      handleSelectTypeOfRoom(isBed ? 'dorm' : 'private')
    }
    if (!(changeRate || roomChange)) {
      // console.log("Proceesing data")
      handleSelectRoomType(formData.roomTypeID);
      if (formData.roomID) handleSelectRoom(formData.roomID, formData.roomTypeID);
      if (formData.bedID) handleSelectBed(formData.bedID);
      setSelectionEntireStay(formData.selectionEntireStay || false)
      setRateEntireStay(formData.rateEntireStay || false)
    }
    if (!extend) {
      setDisplayExtend(false)
    }
  }

  // -------------- handle default data -------------------
  const handleDefaultData = () => {
    let tempData = { ...data };
    tempData.items = [...data.items];
    let formData = tempData && tempData.items.find(item => item.date === selectedDate) || {};
    formData.roomTypeID = formData.roomTypeID || ((extend) ? tempData.roomTypeID : null);
    formData.roomID = formData.roomID || null;
    formData.bedID = formData.bedID || null;
    formData.rate = parseFloat(formData.rate) || 0;
    formData.selectionEntireStay = tempData.selectionEntireStay || false;
    formData.rateEntireStay = tempData.rateEntireStay || false;
    return formData;
  }

  const handleSelectTypeOfRoom = (type) => {
    setTypeOfRoom(type);
    clearKeys()
    setDisplayTypes(true);
  }

  // ----------- On select of room type ---------------
  const handleSelectRoomType = (typeID) => {
    //set state to default
    setRoomKey(null);
    setBedKey(null);
    setIsAvailable(null);
    setDisplayBeds(false)
    setRoomTypeKey(typeID);

    // fetch room keys with bed key for current set item || availability of selected date
    let roomTypeData = [...manualOptions]
      .filter(option => option.roomTypeID === typeID &&
        (!option.itemID || option.itemID === itemID) &&
        option.date === selectedDate)
      .reduce(function (r, a) {
        r[a.roomID] = r[a.roomID] || [];
        if (!r[a.roomID].includes(a.bedID))
          r[a.roomID].push(a.bedID);
        return r;
      }, Object.create(null));;
    setDisplayData({ ...roomTypeData })

    // check for displaying types or rooms
    let dataLength = Object.keys(roomTypeData).length;
    let displayOtherTypes = (dataLength === 0 || typeID !== data.roomTypeID) && manualOptions.length > 0 && true;
    let ifOptions = ([... new Set(manualOptions.map(item => { if (item.roomTypeID && item.date === selectedDate) return item.roomTypeID }))]).filter(id => id);
    if (displayOtherTypes) {
      // console.log("In here")
      setDisplayTypes(ifOptions.length > 0 && true);
    }
    setDisplayRooms(dataLength > 0 && true);
    if (nights === 0 && extend && !displayExtend) {
      setDisplayExtend(true);
    }
  }

  // ----------- On select of room ---------------
  const handleSelectRoom = (id, typeID) => {
    setRoomKey(id);
    setBedKey(null);
    setSelectionEntireStay(false);
    // check to display beds
    let room = rooms[id];
    // console.log("roomTypes[typeID]", roomTypes[typeID], typeID, roomTypes);
    let bedDisplay = id && roomTypes[typeID] && roomTypes[typeID].dormOrPrivate === 'dorm' && true;
    setBedOrRoom(bedDisplay ? 'bed' : 'room')
    setDisplayBeds(bedDisplay);
    // if not displaying beds than set default bedId
    if (!bedDisplay && displayData[id] && displayData[id].length === 1) {
      handleSelectBed(displayData[id][0]);
    } else {
      setDisplayBeds(true);
    }
  }

  // ----------- On select of bed ---------------
  const handleSelectBed = (id) => {
    setBedKey(id);
    setSelectionEntireStay(false);
    // get actual date range
    let date1 = roomChange ? selectedDate : startDate;
    let date2 = endDate;
    if (extend) {
      date1 = selectedDate;
      date2 = fetchDatePerTimezone(selectedDate).add((nights || 1) - 1, 'days').format("YYYY-MM-DD");
    } /* else if (arrivalChange) {
      date2 = selectedDate;
      date1 = fetchDatePerTimezone(selectedDate).subtract((nights || 1) - 1, 'days').format("YYYY-MM-DD");
    } */
    let datesOfRange = getDatesOfRange(date1, date2, null, 'stringArray')
    // get dates from given option api for a bed
    let optionDates = [...manualOptions]
      .filter(option => option.bedID === id && (!option.itemID || option.itemID === itemID))
      .map(option => { if (!parseFloat(amount) && selectedDate === option.date) setAmount(option.rate); return option.date; })
      .sort((a, b) => (a > b ? 1 : -1));
    // if equal set availability to true

    // console.log(datesOfRange.toString(), optionDates.toString())
    let available = optionDates.toString().includes(datesOfRange.toString());
    setIsAvailable(available)
    setSelectionEntireStay(available)
    if (extend) {
      setButtonText(available ? t('actions.save') : t('actions.next'));
    }
  }

  // ------------- On save -------------------
  const handleSave = () => {
    let savedData = createNewReservationAccommodations({ ...data }, selectedDate, {
      roomTypeID: roomTypeKey,
      roomID: roomKey,
      bedID: bedKey,
      entireStay: isSelectionEntireStay,
      rateEntireStay: isRateEntireStay,
      amount: parseFloat(amount)
    })

    manualOptions
      .map(option => {
        if (option.itemID === itemID || !option.itemID) {
          if (isSelectionEntireStay) {
            if (option.bedID === bedKey) option.itemID = itemID;
            else option.itemID = null;
          } else if (option.date === selectedDate) {
            if (option.bedID === bedKey) option.itemID = itemID;
            else option.itemID = null;
          }
        }
        return option;
      })
    setFormChangeStatus(false)

    // console.log("savedData", savedData)
    props.handleSaveSelected(itemID, savedData);
  }

  const handleSaveExtend = () => {
    let response = editReservationExtendStay(selectedDate, nights, {
      roomTypeID: roomTypeKey,
      roomID: roomKey,
      bedID: bedKey,
      entireStay: isSelectionEntireStay,
      rateEntireStay: isRateEntireStay,
      amount: parseFloat(amount)
    })

    setNights(response.nights)
    setNext(response.next)
    // console.log('in extend', response)
    props.handleSaveSelected(itemID, response.savedData, response.next);
  }

  const handleSaveChangeRate = () => {
    let formData = data && data.items.find(item => item.date === selectedDate) || {};
    // console.log("formData", formData)

    if (formData.ID) {
      let savedData = {
        accommodationID: formData.ID,
        changeRate: {
          date: selectedDate,
          rate: parseFloat(amount),
          ...isRateEntireStay && {restOfStay: isRateEntireStay}
        }
      }
      console.log("Change rate", itemID, savedData)
      props.handleSaveSelected(itemID, savedData);
    }
  }

  const handleSaveChangeBed = () => {
    let formData = data && data.items.find(item => item.date === selectedDate) || {};

    if (formData.ID) {
      let savedData = {
        accommodationID: formData.ID,
        changeBed: {
          date: selectedDate,
          roomTypeID: roomTypeKey,
          roomID: roomKey,
          bedID: bedKey,
          changeBedRemainingStay: isSelectionEntireStay,
          rate: parseFloat(amount)
        }
      }
      props.handleSaveSelected(itemID, savedData);
    }
  }

  // ------------- Check if form not saved ------------
  const handleCheckFormChanged = (newDate) => {
    let isFormChanged = false;
    if (selectedDate) {
      let formData = data && data.items.find(item => item.date === selectedDate) || {};
      if (changeRate) {
        if (parseFloat(formData.rate) !== parseFloat(amount) && (parseFloat(formData.rate) >= 0 || parseFloat(amount) >= 0)) {
          isFormChanged = true;
        }
      } else if (roomChange) {
        if ((formData.roomTypeID !== roomTypeKey && (formData.roomTypeID && roomTypeKey))
          || (formData.roomID !== roomKey && (formData.roomID && roomKey))
          || (formData.bedID !== bedKey && (formData.bedID && bedKey))
          || (parseFloat(formData.rate) !== parseFloat(amount) && (parseFloat(formData.rate) >= 0 || parseFloat(amount) >= 0))
          || (data.selectionEntireStay && !isSelectionEntireStay)
          || (!data.selectionEntireStay && isSelectionEntireStay)
          || (data.rateEntireStay && !isRateEntireStay)
          || (!data.rateEntireStay && isRateEntireStay)) {
          isFormChanged = true;
        }
      }
      else if ((formData.roomTypeID !== roomTypeKey && (formData.roomTypeID || roomTypeKey))
        || (formData.roomID !== roomKey && (formData.roomID || roomKey))
        || (formData.bedID !== bedKey && (formData.bedID || bedKey))
        || (parseFloat(formData.rate) !== parseFloat(amount) && (parseFloat(formData.rate) >= 0 || parseFloat(amount) >= 0))
        || (data.selectionEntireStay && !isSelectionEntireStay)
        || (!data.selectionEntireStay && isSelectionEntireStay)
        || (data.rateEntireStay && !isRateEntireStay)
        || (!data.rateEntireStay && isRateEntireStay)) {
        isFormChanged = true;
      }
      if (selectedDate && selectedDate !== newDate) setFormChangeStatus(isFormChanged);
      else if (selectedDate === newDate && (!isFormChanged && formChangeStatus)) setFormChangeStatus(isFormChanged);
    }
    return isFormChanged;
  }

  const clearKeys = () => {
    setRoomTypeKey(null);
    setDisplayData({});
    setDisplayTypes(false);
    setDisplayRooms(false);
    setRoomKey(null);
    setBedKey(null);
    setIsAvailable(null);
    setDisplayBeds(false)
  }

  const handleNightChange = (value, is) => {
    setNights(value)
    clearKeys()
  };

  const handleExtend = (itemid, selecteddate, night) => {
    props.handleExtend(itemid, selecteddate, night);
    setDisplayExtend(false)
    clearKeys()
  }

  const handleSetSelectionEntireStay = (value) => {
    setSelectionEntireStay(value);
    if (extend) setButtonText((value || (nights || 1) === 1) ? t('actions.save') : t('actions.next'));
  }

  return (<>
    {formChangeStatus &&
      <div className={classes.errorContainer}>
        <span>Save the current form before proceeding!</span>
      </div>
    }
    <div className={clsx({ [classes.block]: true, [classes.hide]: props.hide })} ref={ref}>
      <div className={classes.container}>
        {props.title &&
          <div className={classes.title}>
            <span className={classes.actionTitle}>{props.title} {extend ? `for ${displayExtend ? nights : (nights || 1)} nights` : ''}</span>
            {(!next) && <CloseIcon className={classes.closeIcon} onClick={() => { props.handleDiscard(itemID); setFormChangeStatus(false) }} />}
          </div>}

        {extend && displayExtend &&
          <ExtendReservation
            classes={classes}
            nights={nights}
            itemID={itemID}
            selectedDate={selectedDate}
            handleNightChange={handleNightChange}
            handleExtend={handleExtend}
          />
        }

        {!changeRate && !displayExtend &&
          <SelectBedForm
            edit={props.edit}

            form={{
              typeOfRoom: typeOfRoom,
              roomTypeID: roomTypeKey,
              roomID: roomKey,
              bedID: bedKey,
              entireStay: isSelectionEntireStay,
              rateEntireStay: isRateEntireStay,
              amount: amount
            }}

            rooms={rooms}
            beds={beds}
            roomTypes={roomTypes}
            currentSpace={currentSpace}

            single={(extend && nights === 1) ? true : false}
            typeOption={(roomChange || extend) ? true : false}
            // entireStay={false}
            entireStay={(roomChange && bedKey) ? true : false}
            roomTypeName={data.roomTypeID && roomTypes[data.roomTypeID] && roomTypes[data.roomTypeID].name}
            bedOrRoom={bedOrRoom}
            isAvailable={isAvailable}
            ButtonText={ButtonText}

            alternateRoomTypes={displayTypes && ([... new Set(manualOptions.map(item => {
              if (item.roomTypeID && item.date === selectedDate &&
                (roomChange || extend || item.roomTypeID !== data.roomTypeID) &&
                roomTypes[item.roomTypeID] &&
                roomTypes[item.roomTypeID].dormOrPrivate === typeOfRoom
              ) return item.roomTypeID
            }))])}
            roomList={displayRooms ? displayData : null}
            bedList={displayData[roomKey] && displayBeds ? displayData[roomKey] : []}

            handleSelectTypeOfRoom={handleSelectTypeOfRoom}
            setAmount={setAmount}
            handleSelectRoomType={handleSelectRoomType}
            handleSelectRoom={handleSelectRoom}
            handleSelectBed={handleSelectBed}
            handleSetSelectionEntireStay={handleSetSelectionEntireStay}
            setRateEntireStay={setRateEntireStay}
            handleSave={extend ? handleSaveExtend : roomChange ? handleSaveChangeBed : handleSave}
          />}

        {changeRate &&
          <ChangeRateForm
            classes={classes}
            currentSpace={currentSpace}
            roomTypeName={data.roomTypeID && roomTypes[data.roomTypeID] && roomTypes[data.roomTypeID].name}
            bedOrRoom={bedOrRoom}
            rateRestOfStay={isRateEntireStay}
            amount={amount}
            setAmount={setAmount}
            setRateRestOfStay={setRateEntireStay}
            handleSave={handleSaveChangeRate}
          />}
      </div>
    </div>
  </>)
})

export default withTranslation('translation', { withRef: true })(ManualReservationForm);