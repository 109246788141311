const inboxContent = {}

export default (state = inboxContent, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_SETTINGS_REQUEST':
    case 'GET_PROPERTY_SETTINGS_FAILURE':
      return {}

    case 'GET_PROPERTY_SETTINGS_SUCCESS':
      return {
        ...state,
        ...action.payload
      }

    case 'SET_PROPERTY_SETTINGS_REQUEST':
    case 'SET_PROPERTY_SETTINGS_FAILURE':
      return {
        ...state
      }
      
    case 'SET_PROPERTY_SETTINGS_SUCCESS':
      return {
        ...state,
        ...action.payload
      }

    case 'DELETE_HOSTEL_WORLD_IMAGES_SUCCESS':
    case 'UPLOAD_HOSTEL_WORLD_IMAGES_SUCCESS':
      const { data } = action.payload;
      return { ...state, images: [...data] }

    case 'UPDATE_SETTINGS_ROOM_TYPES':
      return {...state, roomTypes: [...action.payload]}

    default:
      return state
  }
}
