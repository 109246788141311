import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { withTranslation } from "react-i18next";

import MaterialTable from "material-table";
import { fetchCurrencyFormat, getNumberAbbr } from "../../utils/utility";
import { RESERVATION_STATUS } from "../../utils/constants";

import { resetSuccess } from "../../redux/actions/reservation/editReservation";
import {
  normalReservationProcessing,
  filteredResProcessing,
} from "../../redux/selectors/reports/reservations";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import {
  loadReservationsByDay,
  resetReservationsReport,
} from "../../redux/actions/reports/reservations";
import { updateReservationStatusShortcut } from "../../redux/actions/reservation/editReservation";
import { fetchProperty } from "../../redux/actions/dashboard/property";
import Reservation from "../reservations/Reservation";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";

import { exportExcel } from "../utils/excelExport";
import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 0px)",
    maxHeight: "calc(100% - 0px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 600,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
  },
  listContainer: {
    height: "calc(100vh - 88px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listSection: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  bubble: {
    background: "#F0F0F0",
    borderRadius: "15px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.3rem",
    height: "35px",
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      height: "20px",
    },
  },
  alignRight: {
    borderRadius: "50%",
    cursor: "pointer",
    marginLeft: "auto",
    padding: theme.spacing(1),
    "& svg": {
      color: "#999999",
    },
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  selected: {
    background: "#8CB2A4",
    color: "#ffffff",
    fontWeight: 600,
  },
  circle: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  actionIcon: {
    height: 15,
    width: 15,
    opacity: "0.7",
  },
  loadingIcon: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& svg": {
      color: "#BDBDBD",
    },
  },
}));

const calculateSum = (array, field) => {
  let sum = 0;
  array.map((item) => (sum += item[field]));
  return sum;
};

const calculateAverage = (array, field) => {
  let sum = 0;
  let count = 0;
  array.map((item) => {
    sum += item[field];
    count += 1;
  });
  if (count !== 0) return Math.round((sum / count) * 10) / 10;
  else return 0;
};

// IMPORTANT FOR THIS REPORT
// ---------------------------------------------------------------------------------------------------------
// Need to define custom reservation status for check-in and check-out
// Check in should contain reservations with state="in house" but check-in date = today
// Check out should contain reservations with state="checked out" but check-out date = today
// ---------------------------------------------------------------------------------------------------------
const reservationStatus = [
  { value: "check in", label: "Check In" },
  { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: "Online Check In" },
  { value: RESERVATION_STATUS.IN_HOUSE, label: "In House" },
  { value: "check out", label: "Check Out" },
  { value: RESERVATION_STATUS.NO_SHOW, label: "No Show" },
  { value: RESERVATION_STATUS.CANCELLED, label: "Cancelled" },
];

const BasicExport = (props) => {
  const { t } = props;
  const classes = useStyles();

  const rowCount = props.data.length;
  const title = `${props.today ? "Today" : "Tomorrow"}'s Reservations - ${
    props.date
  }`;
  return (
    <MaterialTable
      title={title}
      columns={[
        {
          title: t("reports.table.resvCode"),
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.guestName"),
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.durationStay"),
          field: "duration",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.source"),
          field: "source",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.amount"),
          field: "amount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("existingReservation.hoverInfo.balance"),
          field: "balance",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("existingReservation.hoverInfo.status"),
          field: "status",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("plus.summary.beds"),
          field: "bedsInfo",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.guestCount"),
          field: "guestCount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.type"),
          field: "nonRefundable",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      actions={[
        // Checkin Shortcut
        // ------------------------------------------------------
        (rowData) => ({
          icon: () => (
            <img className={classes.actionIcon} src={IMAGES.ICONS.enter} />
          ),
          tooltip: "Check In",
          onClick: (event, rowData) =>
            props.updateStatus(rowData.resID, RESERVATION_STATUS.IN_HOUSE),
          hidden: !(
            (
              props.loadingRes !== rowData.resID && // If loading, display loading icon
              rowData.status === RESERVATION_STATUS.CONFIRMED && // Only check in confirmed reservations
              today && // only allowed for today's reservations
              moment().diff(moment(rowData.checkIn)) >= 0
            ) // checkin date today or earlier
          ),
        }),

        // Checkout Shortcut
        // ------------------------------------------------------
        (rowData) => ({
          icon: () => (
            <img className={classes.actionIcon} src={IMAGES.ICONS.logout} />
          ),
          tooltip: "Check Out",
          onClick: (event, rowData) =>
            props.updateStatus(rowData.resID, RESERVATION_STATUS.CHECKED_OUT),
          hidden: !(
            (
              props.loadingRes !== rowData.resID && // If loading display loading icon
              rowData.status === RESERVATION_STATUS.IN_HOUSE && // Only check out in-house reservations
              today && // only allowed for today's reservations
              moment().diff(moment(rowData.checkOut)) >= 0
            ) // checkout date today or earlier
          ),
        }),

        // Loading state on click of checkin / checkout
        // ------------------------------------------------------
        (rowData) => ({
          icon: () => (
            <div className={classes.loadingIcon}>
              <CircularProgress size={25} />
            </div>
          ),
          tooltip: "Loading",
          onClick: (event, rowData) => {},
          hidden: !(props.loadingRes === rowData.resID),
        }),
      ]}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        headerStyle: { backgroundColor: "#F6F2EE" },
        actionsColumnIndex: -1,
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 60, 80],
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = title;
          exportExcel(filename, headerConfig, tableData);
        },
      }}
      onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  );
};

const LiveReservations = (props) => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    preset,
    today,
    loadingTodaysReservations,
    rooms,
    beds,
    t,
  } = props;
  const [status, setStatus] = React.useState(
    preset === RESERVATION_STATUS.CONFIRMED
      ? "check in"
      : preset === RESERVATION_STATUS.IN_HOUSE
      ? RESERVATION_STATUS.IN_HOUSE
      : preset === RESERVATION_STATUS.CHECKED_OUT
      ? "check out"
      : ""
  );
  const [loadingRes, setLoadingRes] = React.useState("");
  const [openReservation, setOpenReservation] = React.useState(false);

  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };
  const tableData = useSelector((state) =>
    normalReservationProcessing(
      state.reports.reservations,
      currencyData,
      rooms,
      beds
    )
  );
  const filteredData = useSelector((state) =>
    filteredResProcessing(
      state.reports.reservations,
      currencyData,
      status,
      today,
      rooms,
      beds
    )
  );

  React.useEffect(() => {
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });

    const params = {
      propertyID: currentSpace.propertyID,
      dateType: "stayDate",
      startDate: today
        ? moment().format("YYYY-MM-DD")
        : moment().add(1, "d").format("YYYY-MM-DD"),
      endDate: today
        ? moment().format("YYYY-MM-DD")
        : moment().add(1, "d").format("YYYY-MM-DD"),
      status: [
        RESERVATION_STATUS.CONFIRMED,
        RESERVATION_STATUS.IN_HOUSE,
        RESERVATION_STATUS.CHECKED_OUT,
        RESERVATION_STATUS.NO_SHOW,
        RESERVATION_STATUS.ONLINE_CHECKED_IN,
        RESERVATION_STATUS.CANCELLED,
      ],
    };

    props.dispatch(fetchProperty(currentSpace.propertyID));
    props.dispatch(loadReservationsByDay(params));

    return function cleanup() {
      window.Intercom &&
        window.Intercom("update", { hide_default_launcher: false });
      props.dispatch(resetReservationsReport());
    };
  }, []);

  const handleApplyFilter = (newStatus) => {
    // Filter reservation table data based on new status
    setStatus(newStatus);
  };

  // React.useState(() => {
  //   console.log('getting here 1', props.loadSuccess)
  //   if (props.loadSuccess) {
  //     console.log('getting here')
  //     let newStatus ='';
  //     if (preset===RESERVATION_STATUS.CONFIRMED) newStatus='check in';
  //     if (preset===RESERVATION_STATUS.CHECKED_OUT) newStatus='check out';
  //     if (preset===RESERVATION_STATUS.INHOUSE) newStatus=RESERVATION_STATUS.INHOUSE;
  //     handleApplyFilter(newStatus);
  //     props.dispatch(resetSuccess('LOAD_RESERVATIONS_BY_DAY'));
  //   }
  // }, [props.loadSuccess])

  const closeModal = () => {
    props.handleClose();
  };

  const handleRefresh = () => {
    const params = {
      propertyID: currentSpace.propertyID,
      dateType: "stayDate",
      startDate: today
        ? moment().format("YYYY-MM-DD")
        : moment().add(1, "d").format("YYYY-MM-DD"),
      endDate: today
        ? moment().format("YYYY-MM-DD")
        : moment().add(1, "d").format("YYYY-MM-DD"),
      status: [
        RESERVATION_STATUS.CONFIRMED,
        RESERVATION_STATUS.IN_HOUSE,
        RESERVATION_STATUS.CHECKED_OUT,
        RESERVATION_STATUS.NO_SHOW,
        RESERVATION_STATUS.ONLINE_CHECKED_IN,
        RESERVATION_STATUS.CANCELLED,
      ],
    };
    props.dispatch(loadReservationsByDay(params));
    setStatus("");
  };

  // Open Reservation
  // -------------------------------------------------
  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  // Function for click of check-in check-out shortcut
  // -------------------------------------------------
  const handleStatusUpdate = (resID, status) => {
    console.log("hey hey", resID, status);
    setLoadingRes(resID);
    const result = props.dispatch(
      updateReservationStatusShortcut(resID, { status: status })
    );
    result.then(() => {
      console.log("done");
      setLoadingRes("");
    });
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      fullWidth={true}
      fullScreen={true}
    >
      {/* --------------------------------------------------------- */}
      {/* DIALOG HEADER */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {today ? "Today" : "Tomorrow"}'s Reservations
          </div>
          <CloseIcon className={classes.closeIcon} onClick={closeModal} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
            {/* --------------------------------------------------------- */}
            {/* RESERVATION STATUS FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={classes.firstRow}>
              <div
                className={clsx({
                  [classes.bubble]: true,
                  [classes.selected]: !status.length,
                })}
                onClick={() => handleApplyFilter("")}
              >
                All Reservations
              </div>
              {reservationStatus.map((item) => (
                <div
                  key={item.value}
                  className={clsx({
                    [classes.bubble]: true,
                    [classes.selected]: status.includes(item.value),
                  })}
                  onClick={() => handleApplyFilter(item.value)}
                >
                  {item.label}
                </div>
              ))}
              <div className={classes.alignRight} onClick={handleRefresh}>
                <RefreshIcon />
              </div>
            </div>

            {/* --------------------------------------------------------- */}
            {/* LOADING STATE */}
            {/* --------------------------------------------------------- */}
            {loadingTodaysReservations && (
              <div className={classes.loadingState}>
                <CircularProgress />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* RESERVATION SUMMARY BUBBLES*/}
            {/* --------------------------------------------------------- */}
            {!loadingTodaysReservations && (
              <div className={classes.listSection}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={2} align="center">
                    <div className={classes.circle}>
                      <div className={classes.amount}>
                        {status === "" ? tableData.length : filteredData.length}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.reservations")}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={2} align="center">
                    <div className={classes.circle}>
                      <div className={classes.amount}>
                        {currency}{" "}
                        {getNumberAbbr(
                          status === ""
                            ? calculateSum(tableData, "rawAmount")
                            : calculateSum(filteredData, "rawAmount")
                        )}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.totalRevenue")}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={2} align="center">
                    <div className={classes.circle}>
                      <div className={classes.amount}>
                        {currency}{" "}
                        {getNumberAbbr(
                          status === ""
                            ? calculateSum(tableData, "rawBalance")
                            : calculateSum(filteredData, "rawBalance")
                        )}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.table.outBalance")}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={2} align="center">
                    <div className={classes.circle}>
                      <div className={classes.amount}>
                        {status === ""
                          ? calculateAverage(tableData, "duration")
                          : calculateAverage(filteredData, "duration")}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.avgDuration")}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={2} align="center">
                    <div className={classes.circle}>
                      <div className={classes.amount}>
                        {getNumberAbbr(
                          status === ""
                            ? calculateSum(tableData, "guestCount")
                            : calculateSum(filteredData, "guestCount")
                        )}
                      </div>
                      <div className={classes.desc}>{t("reports.guests")}</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* RESERVATIONS TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingTodaysReservations && (
              <div className={classes.row}>
                <BasicExport
                  t={t}
                  data={status === "" ? tableData : filteredData}
                  date={
                    today
                      ? moment().format("DD MMM, YYYY")
                      : moment().add(1, "d").format("DD MMM, YYYY")
                  }
                  handleTableRowClick={handleTableRowClick}
                  updateStatus={handleStatusUpdate}
                  loadingRes={loadingRes}
                  today={today}
                />
              </div>
            )}
          </div>
        </div>

        {/* Open reservation when reservation item clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && (
          <Reservation
            open={openReservation}
            handleCloseReservation={handleCloseReservation}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  rooms: state.rooms,
  beds: state.beds,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingTodaysReservations: state.loading.LOAD_RESERVATIONS_BY_DAY,
  loadSuccess: state.success.LOAD_RESERVATIONS_BY_DAY,
});

export default withTranslation()(connect(mapStateToProps)(LiveReservations));
