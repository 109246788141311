import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD NO-SHOWS
//----------------------------------------------------------------
export function loadNoShows(propID, startDate, endDate, level) {
  const ACTION_NAME = "LOAD_NO_SHOW_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/noshow/${propID}?startDate=${startDate}&endDate=${endDate}&level=${level}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD NO-SHOWS BY DAY
//----------------------------------------------------------------
export function loadNoShowsByDay(propID, startDate, endDate) {
  const ACTION_NAME = "LOAD_NO_SHOW_BY_DAY";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/reservations/day?propertyID=${propID}&startDate=${startDate}&endDate=${endDate}&status=noshow`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET NO SHOWS
//----------------------------------------------------------------
export const resetNoShows = () => ({
  type: 'RESET_NO_SHOWS'
})