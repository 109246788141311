import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import _ from "lodash";

import Notification from "./Notification";
import NotificationError from "./NotificationError";
import {
  addNotification,
  markAllRead,
} from "../../redux/actions/notifications/notifications";
// import Events from "./Events";

import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Pusher from "pusher-js";
import { PUSHER_KEY } from "../../config";

const pusher_key_var = PUSHER_KEY;

const styles = (theme) => ({
  menuList: {
    "& ul": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  root: {
    // maxWidth: 400,
    width: "100%",
  },
  unreadNotifications: {
    marginBottom: 10,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: 10,
    paddingTop: 10,
  },
  newHeader: {
    fontWeight: 600,
  },
  allRead: {
    cursor: "pointer",
  },
  readNotifications: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
});

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    this.pusher = new Pusher(pusher_key_var, {
      encrypted: true,
      cluster: "eu",
      forceTLS: true,
    });
    this.channel = this.pusher.subscribe(this.props.user._id);
    this.channel.bind("taskCreated", this.updateEvents);
    this.channel.bind("mention", this.updateEvents);
    this.channel.bind("updated", this.updateEvents);
    this.channel.bind("deleted", this.updateEvents);
  }

  componentWillUnmount() {
    this.channel.unbind();
    this.pusher.unsubscribe(this.channel);
  }

  updateEvents = (data) => {
    console.log("New notification received ", data);
    this.props.dispatch(addNotification(data));
  };

  handleMarkAllRead = () => {
    console.log("hey");
    this.props.dispatch(markAllRead());
  };

  render() {
    const {
      notifications,
      notificationError,
      notAnchorEl,
      handleNotClose,
      classes,
    } = this.props;
    const unreadNotifications = Object.keys(notifications).filter(
      (key) => !notifications[key].status
    );
    const readNotifications = Object.keys(notifications).filter(
      (key) => notifications[key].status
    );
    const notOpen = Boolean(notAnchorEl);

    return (
      <Menu
        id="not-appbar"
        anchorEl={notAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ padding: 0 }}
        getContentAnchorEl={null}
        className={classes.menuList}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={notOpen}
        onClose={handleNotClose}
      >
        <div className={classes.root}>
          {/* ------------------------------------------------------------ */}
          {/* UNREAD NOTIFICATIONS */}
          {/* ------------------------------------------------------------ */}
          {unreadNotifications.length !== 0 && (
            <List
              component="nav"
              className={classes.unreadNotifications}
              disablePadding={true}
            >
              {notificationError && (
                <NotificationError errorMessage={notificationError.message} />
              )}
              <div className={classes.headerContainer}>
                <Typography
                  className={classes.newHeader}
                  component="span"
                  variant="h6"
                >
                  New for you
                </Typography>
                <Typography
                  className={classes.allRead}
                  component="span"
                  variant="body2"
                  onClick={this.handleMarkAllRead}
                >
                  Mark all read
                </Typography>
              </div>

              {unreadNotifications.map((key) => (
                <div key={key}>
                  {notifications[key].status ? (
                    false
                  ) : (
                    <Notification
                      handleNotClose={this.props.handleNotClose}
                      notification={notifications[key]}
                    />
                  )}
                </div>
              ))}
            </List>
          )}

          {/* ------------------------------------------------------------ */}
          {/* READ NOTIFICATIONS */}
          {/* ------------------------------------------------------------ */}
          {readNotifications.length !== 0 && (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.readNotifications}
              disablePadding={true}
            >
              <div className={classes.headerContainer}>
                <Typography
                  className={classes.newHeader}
                  component="span"
                  variant="h6"
                >
                  Previous Notifications
                </Typography>
              </div>
              {readNotifications.map((key) => (
                <div key={key}>
                  {notifications[key].status ? (
                    <Notification
                      handleNotClose={this.props.handleNotClose}
                      notification={notifications[key]}
                    />
                  ) : (
                    false
                  )}
                </div>
              ))}
            </List>
          )}

          {/* ------------------------------------------------------------ */}
          {/* BLANK STATE - ZERO NOTIFICATIONS */}
          {/* ------------------------------------------------------------ */}
          {Object.keys(notifications).length === 0 && (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.unreadNotifications}
              disablePadding={true}
            >
              <div className={classes.headerContainer}>
                <Typography component="span" variant="body1">
                  No notifications right now. Check back later :)
                </Typography>
              </div>
            </List>
          )}
        </div>
      </Menu>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  notifications: state.notifications,
  notificationError: state.notifications.loadNotificationsFailure,
});

export default withStyles(styles)(connect(mapStateToProps)(NotificationList));
