import http from "../../utils/http";


//----------------------------------------------------------------
// SEARCH KEYWORD
//----------------------------------------------------------------
export const submitSearchKeyword = (propertyID, keyword='', status='', date='', roomType='') => {

  return function(dispatch) {
    if (keyword.length > 2) {
      dispatch(searchReservations(propertyID, keyword, status, date, roomType))
    }

    dispatch({
      type: 'SET_SEARCH_KEYWORD',
      payload: keyword
    })
  };
}

//----------------------------------------------------------------
// SEARCH ACTIVE
//----------------------------------------------------------------
export const setSearchActive = (searchActive=false) => {

  return function(dispatch) {
    dispatch({
      type: 'SET_SEARCH_ACTIVE',
      payload: searchActive
    })
  };
}

//----------------------------------------------------------------
// STATUS FILTERS
//----------------------------------------------------------------
export const setStatusFilters = (propertyID, keyword = '', status='', date='', roomType='') => {

  return function(dispatch) {
    dispatch(searchReservations(propertyID, keyword, status, date, roomType))

    dispatch({
      type: 'SET_SEARCH_STATUS',
      payload: status
    })
  }
}

//----------------------------------------------------------------
// DATE FILTER
//----------------------------------------------------------------
export const setDateFilters = (propertyID, keyword = '', status='', date='', roomType='') => {

  return function(dispatch) {
    dispatch(searchReservations(propertyID, keyword, status, date, roomType))

    dispatch({
      type: 'SET_SEARCH_DATE',
      payload: date
    })
  }
}

//----------------------------------------------------------------
// ROOM TYPE FILTER
//----------------------------------------------------------------
export const setRoomTypeFilters = (propertyID, keyword = '', status='', date='', roomType='') => {

  return function(dispatch) {
    dispatch(searchReservations(propertyID, keyword, status, date, roomType))

    dispatch({
      type: 'SET_SEARCH_ROOM_TYPE',
      payload: roomType
    })
  }
}


//----------------------------------------------------------------
// CLEAR SEARCH
//----------------------------------------------------------------
export const clearSearch = () => {
  return function(dispatch) {
    dispatch({
      type: 'CLEAR_SEARCH',
      payload: {}
    })
  }
};

//----------------------------------------------------------------
// SEARCH RESERVATIONS
//----------------------------------------------------------------
export function searchReservations(propID, keyword, status='', date='', roomType='', order='asc', limit) {
  const ACTION_NAME = "SEARCH_RESERVATIONS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/search/${propID}?keyword=${keyword}&status=${status}&date=${date}&roomType=${roomType}&order=${order}&limit=${limit}`)
      .then(successResult => {
        const data = successResult.data;
        if (data.error) {
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: data.error } });
          return;
        }
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log('searchReservations ERROR', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}