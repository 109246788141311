import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import moment from "moment";
import { connect } from "react-redux";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import { deleteRoomMapping } from "../../../../redux/actions/rooms/rooms";
import Pill from "../../../common/Pill";
import ConfirmForm from "../../../common/ConfirmForm";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SubDirectoryIcon from "@material-ui/icons/SubdirectoryArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import IMAGES from "../../../../constants/images";
import {
  DATA_ACTION,
  inventoryConditionHandler,
} from "../../../../utils/helper";

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 5,
    marginBottom: 30,
    padding: theme.spacing(3),
    marginBottom: 20,
  },
  spacer: {
    marginBottom: 200,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  closeIcon: {
    borderRadius: "50%",
    height: 18,
    marginLeft: "auto",
    padding: theme.spacing(1),
    width: 18,
    cursor: "pointer",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  blankState: {
    fontSize: "1.2rem",
    marginTop: 5,
  },
  subTitle: {
    fontWeight: 600,
    fontSize: "1.3rem",
  },
  pillContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
  },
  derivedSection: {
    background: "#F8F9FB",
    borderRadius: 5,
    padding: theme.spacing(2),
    width: "calc(100% - 32px)",
    marginBottom: 20,
  },
  form: {
    marginRight: 20,
  },
  field: {
    alignItems: "center",
    background: "#ffffff",
    borderRadius: "5px",
    boxShadow: "0 1px 10px #E0E0E0",
    display: "flex",
    height: 35,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50,
  },
  fieldContainer: {
    alignItems: "center",
    display: "flex",
  },
  dateContainer: {
    background: "#F8F9FB",
    borderRadius: 5,
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 500,
    paddingLeft: 10,
    paddingRight: 30,
    "& input": {
      background: "#F8F9FB",
      fontWeight: 500,
      color: "#484848",
    },
    "& .DateRangePickerInput": {
      background: "#F8F9FB",
    },
    "& .DateInput": {
      background: "#F8F9FB",
    },
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  icon: {
    borderRadius: "50%",
    height: 20,
    padding: theme.spacing(1),
    width: 20,
    cursor: "pointer",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "1.2rem",
    marginBottom: 20,
  },
  cancel: {
    color: "#484848",
    cursor: "pointer",
    fontSize: "1.3rem",
    marginRight: 30,
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  error: {
    fontSize: "1.2rem",
    color: "red",
    marginTop: 5,
  },
}));

const RoomTypeForm = (props) => {
  const classes = useStyles();
  const {
    roomTypeID,
    roomTypes,
    roomTypeOptions,
    handleSplice,
    handleAddBack,
    handleUpdateRoom,
    room,
    handleSaveLink,
    handleCancelLink,
    t,
  } = props;
  const [baseRoomType, setBaseRoomType] = useState(
    roomTypeID ? roomTypeID : ""
  );
  const [differentials, setDifferentials] = useState([
    { startDate: null, endDate: null, focusedInput: null, roomTypeID: "" },
  ]);
  const [errors, setErrors] = useState([false]);
  const [openConfirm, setOpenConfirm] = useState([false]);

  useEffect(() => {
    if (roomTypeID) {
      let array = [];
      room.mapping.map((mappingItem) => {
        if (mappingItem.roomTypeID == roomTypeID) {
          array.push({
            ...mappingItem,
            startDate: moment(mappingItem.startDate),
            endDate: moment(mappingItem.endDate),
            focusedInput: null,
          });
        }
      });
      // console.log('hey', array)
      if (array.length > 0) setDifferentials([...array]);
    }
  }, [roomTypeID]);

  const handleChangeRoomType = () => {
    if (!roomTypeID) handleRestart(baseRoomType);
  };

  const handleSelect = (selectedRoomType) => {
    setBaseRoomType(selectedRoomType);
    handleSplice(selectedRoomType);
  };

  const handleRestart = (selectedRoomType) => {
    setBaseRoomType("");
    handleAddBack([selectedRoomType]);
    setErrors([false]);
    setOpenConfirm([false]);
  };

  const handleUpdate = (updatedItem, startDate, endDate) => {
    let array = [...differentials];
    let item = array[updatedItem];
    let errorArray = [...errors];
    errorArray[updatedItem] = false;
    item.startDate = startDate;
    item.endDate = endDate;
    setDifferentials([...array]);
    setErrors([...errorArray]);
  };

  const isDayBlocked = (d) => {
    let overlap = false;
    differentials.map((e) => {
      if (e.startDate && e.endDate) {
        if (
          moment(d).diff(e.startDate, "days") > -1 &&
          moment(e.endDate).diff(d, "days") > -1
        )
          overlap = true;
      }
    });
    return overlap;
  };

  const handleFocussedInput = (updatedItem, focusedInput) => {
    let array = [...differentials];
    let item = array[updatedItem];
    item.focusedInput = focusedInput;
    setDifferentials([...array]);
  };

  const processdDeleteMapping = (index) => {
    let array = [...differentials];
    let errorArray = [...errors];
    let confirmArray = [...openConfirm];
    const roomTypeID = array[index].roomTypeID;
    if (roomTypeID !== "") handleAddBack([roomTypeID]);
    array.splice(index, 1);
    errorArray.splice(index, 1);
    confirmArray.splice(index, 1);
    setDifferentials([...array]);
    setErrors([...errorArray]);
    setOpenConfirm([...confirmArray]);
  };

  const handleConfirmDelete = (index, value) => {
    let array = [...openConfirm];
    array[index] = value;
    setOpenConfirm([...array]);
  };

  const handleDelete = (index) => {
    if (room._id && differentials[index]._id) {
      const response = props.dispatch(
        deleteRoomMapping(
          differentials[index]._id,
          differentials[index].roomTypeID
        )
      );
      response.then((data) => {
        if (data) {
          console.log(data);
          processdDeleteMapping(index);
          handleUpdateRoom("mapping", [...data.room.mapping]);
        }
      });
    } else {
      processdDeleteMapping(index);
    }
  };

  const handleAddDifferential = () => {
    setDifferentials([
      ...differentials,
      { startDate: null, endDate: null, roomTypeID: "" },
    ]);
    setErrors([...errors, false]);
    setOpenConfirm([...openConfirm, false]);
  };

  const handleCancel = () => {
    if (roomTypeID) handleCancelLink(roomTypeID);
    else {
      let addBackArray = [];
      if (baseRoomType !== "") {
        addBackArray.push(baseRoomType);
      }
      handleCancelLink();
      handleAddBack(addBackArray);
    }
  };

  const handleValidate = () => {
    let errorArray = [...errors];
    let noError = true;
    console.log("differentials", differentials);
    differentials.map((item, index) => {
      if (
        item.startDate == "" ||
        item.startDate == null ||
        item.endDate == "" ||
        item.endDate == null
      ) {
        errorArray[index] = true;
        noError = false;
      }
    });
    setErrors([...errorArray]);
    return noError;
  };

  const handleSave = () => {
    const check = handleValidate();
    if (check) {
      let mapping = [];
      differentials.map((link) => {
        mapping.push({
          ...link,
          roomTypeID: baseRoomType,
          startDate: moment(link.startDate).format("YYYY-MM-DD"),
          endDate: moment(link.endDate).format("YYYY-MM-DD"),
          visible: true,
        });
      });
      if (roomTypeID) {
        handleSaveLink(roomTypeID, mapping);
      } else handleSaveLink(mapping);
    }
  };

  return (
    <>
      <div
        className={clsx({ [classes.box]: true, [classes.spacer]: !roomTypeID })}
      >
        {/* TITLE */}
        {/* ------------------------------------ */}
        <div className="row">
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              {roomTypeID ? "Edit" : "New"} Room Type Link
            </div>
            <CloseIcon className={classes.closeIcon} onClick={handleCancel} />
          </div>
        </div>

        {/* STEP 1 -- SELECT BASE ROOM TYPE */}
        {/* ------------------------------------ */}
        <div className="row">
          <div className="label">Room Type</div>
          {baseRoomType == "" && (
            <div className={classes.pillContainer}>
              {roomTypeOptions.length > 0 &&
                roomTypeOptions.map((roomType) => (
                  <Pill
                    key={roomType}
                    name={inventoryConditionHandler(
                      roomTypes[roomType],
                      DATA_ACTION.NAME_CODE
                    )}
                    handleClick={() => handleSelect(roomType)}
                  />
                ))}
            </div>
          )}
          {baseRoomType !== "" && baseRoomType !== room.primaryRoomTypeID && (
            <div className={classes.pillContainer}>
              <Pill
                name={inventoryConditionHandler(
                  roomTypes[baseRoomType],
                  DATA_ACTION.NAME_CODE
                )}
                handleClick={handleChangeRoomType}
                selected={true}
                cancelButton={!Boolean(roomTypeID)}
                green={true}
              />
            </div>
          )}
          {baseRoomType !== "" && baseRoomType == room.primaryRoomTypeID && (
            <div className={classes.subTitle}>
              {inventoryConditionHandler(
                roomTypes[baseRoomType],
                DATA_ACTION.NAME_CODE
              )}
            </div>
          )}
        </div>

        {/* STEP 2 -- ADD START & END DATE */}
        {/* ------------------------------------ */}
        {differentials.map((item, index) => (
          <div key={index} className={classes.derivedSection}>
            <Grid container justifyContent="center" alignItems="center">
              {/* Directory Icon */}
              {/* ------------------------------------ */}
              <Grid item xs={1}>
                <SubDirectoryIcon />
              </Grid>

              <Grid item xs={7}>
                <div className={classes.fieldContainer}>
                  <div className={classes.dateContainer}>
                    <DateRangePicker
                      startDate={item.startDate}
                      startDateId="start_date"
                      endDate={item.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) =>
                        handleUpdate(index, startDate, endDate)
                      }
                      onFocusChange={(focusedInput) =>
                        handleFocussedInput(index, focusedInput)
                      }
                      focusedInput={item.focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      isDayBlocked={isDayBlocked}
                      numberOfMonths={1}
                      noBorder={true}
                    />
                  </div>
                </div>
                {errors[index] && (
                  <div className={classes.error}>
                    Please enter a valid date range to proceed
                  </div>
                )}
              </Grid>

              {/* Delete */}
              {/* ------------------------------------ */}
              <Grid item xs={4}>
                {!openConfirm[index] && (
                  <img
                    className={classes.icon}
                    src={IMAGES.ICONS.bin}
                    onClick={() => handleConfirmDelete(index, true)}
                  />
                )}
                <ConfirmForm
                  title="Delete Room?"
                  open={openConfirm[index]}
                  setOpen={(value) => handleConfirmDelete(index, value)}
                  onClose={() => handleConfirmDelete(index, false)}
                  onConfirm={() => handleDelete(index)}
                >
                  Are you sure you would like to delete this date range?
                </ConfirmForm>
              </Grid>
            </Grid>
          </div>
        ))}

        {/* STEP 3 -- ADD ANOTHER DATE RANGE */}
        {/* ---------------------------------------------*/}
        {roomTypeOptions.length > 0 && (
          <div className={classes.link} onClick={handleAddDifferential}>
            Add another range
          </div>
        )}

        {/* STEP 4 -- SAVE */}
        {/* ------------------------------------ */}
        {baseRoomType !== "" && differentials.length > 0 && (
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleSave}
            >
              {t("actions.save")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roomTypes: state.roomTypes,
});

export default withTranslation()(connect(mapStateToProps)(RoomTypeForm));
