import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getMenuStepInfo, getProgress } from './activityHelper';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        padding: '10px 15px',
        boxSizing: 'border-box'
    },
    infoContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.2rem',
    },
    progressContainer:{
        width: '100%'
    },
    statusBar: {
        // width: '65%',
        backgroundColor: '#e3e3e3',
        '&.MuiLinearProgress-root':{
            height: '3px',
            borderRadius: '20px',
        },
        '& .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#BB5533',
            borderRadius: '20px'
        }
    },
    smallBack: {
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    button: {
        background: '#CC6633',
        border: 'none',
        color: '#FFFFFF',
        fontWeight: 500,
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
        '&:hover': {
            background: '#CC6633',
            border: 'none',
            color: '#FFFFFF',
            fontWeight: 500,
        }
    }
}));

const Footer = props =>{
    const { 
        nextClickHandler, 
        backClickHandler, 
        hideBackBtn = false, 
        nextStepLabel = "Save & Proceed",
        activeStepInfo ,
        productData
    } = props;

    const classes = useStyles();

    const [stepData, setStepData] = useState({});

    useEffect(() => {

        const stepData = getMenuStepInfo(activeStepInfo.subMenuID);
        setStepData({  ...stepData });

    }, [activeStepInfo.subMenuID])

    return(
        <>
            <div className={classes.progressContainer}>
                <LinearProgress 
                    className={classes.statusBar} 
                    variant="determinate" 
                    value={Math.ceil(getProgress(productData) * 100 / stepData.totalSteps) || 10} 
                />
            </div>

            <div className={classes.root}>
                
                {!hideBackBtn && (
                    <Button 
                        variant="outlined"
                        className={classes.smallBack}
                        onClick={backClickHandler}
                    >
                        Back
                    </Button>
                )}
        
                <div className={classes.infoContainer}>
                    {getProgress(productData)} of {stepData.totalSteps} steps complete
                </div>

                <Button 
                    variant="outlined"
                    className={classes.button}
                    onClick={nextClickHandler}
                >
                    {nextStepLabel}
                </Button>
            </div>
        </>
    )
}

export default Footer;