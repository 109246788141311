import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { getAmtFormat, fetchDateFormat, DATE_FORMATS } from "../../utils/utility";
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    width: '100%'
  },
  primaryHolder: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  primaryInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  secondaryHolder: {
    '& span': {
      marginRight: 20
    }
  },
  media: {
    borderRadius: "50%",
    height: 40,
    marginRight: 20,
    width: 40
  },
  listItem: {
    backgroundColor: '#fff',
  },
  room: {
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10
  },
  icon: {
    display: "inline",
    height: 15,
    marginRight: 10,
    width: 15
  },
}))

const GuestList = (props) => {
  const classes = useStyles();
  const {
    reservationID,
    checkIn,
    checkOut,
    guestName,
    balance,
    status,
    userInitials,
    address,
    accommodationName,
    avatar,
    currency,
    handleOpenReservation,
    resCode
  } = props;


  return (
    <List className={classes.root}>
      <ListItem className={classes.listItem} button onClick={() => handleOpenReservation(reservationID)}>
        <ListItemText
          primary={
            <React.Fragment>
              <div className={classes.primaryHolder}>
                <div className={classes.primaryInfo}>
                  <Avatar
                    alt={userInitials}
                    src={avatar}
                    className={classes.media}
                  >
                    {avatar ? null : userInitials}
                  </Avatar>
                  {guestName}
                </div>

                <div className={classes.room}>
                  <img src={IMAGES.ICONS.rooms} className={classes.icon} />
                  {accommodationName}
                </div>
              </div>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <span className={classes.secondaryHolder}>
                <span>Code: <b>{resCode}</b></span>
                {balance > 0 && <span>Balance {getAmtFormat(balance, currency)}</span>}
                {status && <span>{status}</span>}
                <span>{fetchDateFormat(checkIn, DATE_FORMATS.SHORT_DATE)} - {fetchDateFormat(checkOut, DATE_FORMATS.LONG_DATE)}</span>
                {address && <span><img src={IMAGES.ICONS.location} className={classes.icon} />{address}</span>}
              </span>
            </React.Fragment>
          } />
      </ListItem>
    </List>
  )

}

export default GuestList;