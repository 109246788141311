import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { locationList } from '../location_constant'; 

import Footer from '../Footer';

const styles = theme =>({
    root: {
    
    },
    container:{
        // display: 'flex',
        // flexDirection: 'column'
    },
    selectFormControl: {
        width: '30%',
        boxSizing: 'border-box',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
        // marginTop: '20px'
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    infoText: {
        marginTop: '20px',
        fontSize: '1.2rem',
        fontWeight: 600
    },
    btnContainer:{
        marginTop: '10px',
        '& .button':{
            fontWeight: 600
        }
    },
    container:{
        display: 'flex',
        marginTop: '20px',
    },
});

const defaultData = '';

class ActivityLocation extends Component{
    
    maxLimit = 3;

    state = {
        locationData: [ defaultData ],
        locationError: false
    }

    componentDidMount(){
        const { productData } = this.props;
        if(productData.locations && productData.locations.length > 0){
            this.setState({ 
                locationData: [ ...productData.locations ]
            });
        }
    }

    addItemHandler = () =>{
        const { locationData } = this.state;

        if(locationData.length >= this.maxLimit) return;

        this.setState({
            locationData: [ ...locationData, defaultData ]
        })
    }

    removeItemHandler = index =>{
        const { locationData } = this.state;
        const updatedData = locationData.filter((item, itemIndex) => itemIndex != index);
        this.setState({
            locationData: [ ...updatedData ]
        })
    }

    onChangeHandler = (event, index) =>{
        const { locationData } = this.state;
        const value = event.target.value;
        locationData[index] = value;
        this.setState({ locationData: [ ...locationData ] }) 
    }

    validateLocation = (locationData) =>{

        if( locationData.length > 0 ){
            this.setState({ locationError: false });
            return true;
        }
        this.setState({ locationError: true });
    }

    submitHandler = () =>{
        const { locationData } = this.state;
        const { submitDraftHandler } = this.props;

        const formattedData = locationData.filter(location => location);
        if(!this.validateLocation(formattedData)) return;
        const data = { locations: [ ...formattedData ] };
        submitDraftHandler(data);
    }

    render(){
        const { classes, nextStepHandler, prevStepHandler, activeStepInfo, productData } = this.props;
        const { locationData, locationError } = this.state;
        return(
            <>
                <div className={clsx("main-content", "hideScrollBar")}>
                    
                    <div className="main-content-title">Where we will be hosting the experience?</div>

                    <div className="sub-title">
                        Choose upto three locations for your experiences
                    </div>

                    <div className={classes.infoText}>Location</div>

                    {locationData.map((data, index) =>(
                        <div className={classes.container} key={`location_${index}`}>
                            <FormControl 
                                key={`location_${data}`}
                                variant="outlined" 
                                className={classes.selectFormControl}
                            >
                                {/* <InputLabel htmlFor="grouped-native-select"></InputLabel> */}
                                <Select 
                                    native
                                    // label={""} 
                                    id="grouped-native-select"
                                    className={classes.selectInput}
                                    onChange={e => this.onChangeHandler(e, index)}
                                    inputProps={{
                                        name: 'location'
                                    }}
                                    value={data}
                                >
                                    <option aria-label="None" value="" />
                                    {Object.keys(locationList).map((group, groupIndx) =>(
                                        <optgroup 
                                            key={`${group}_${groupIndx}`} 
                                            label={group}
                                        >
                                            {locationList[group].map((location, locationIndx) =>(
                                                <option 
                                                    key={`${location}_${locationIndx}`} 
                                                    value={location}
                                                >
                                                    {location}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </Select>
                            </FormControl>

                            {index > 0 && (
                                <Button 
                                    variant="outlined"
                                    // className="SaveBtn"
                                    onClick={() => this.removeItemHandler(index)}
                                >
                                    Remove
                                </Button>
                            )}
                        </div>
                    ))}

                    { locationData.length < this.maxLimit && (
                        <div className={classes.btnContainer}>
                            <Button 
                                className="button"
                                onClick={this.addItemHandler}
                            >
                                +Select a location
                            </Button>
                        </div>
                    )}

                    {locationError && (
                        <FormHelperText className="errorMessage">
                            Please select atleast one location
                        </FormHelperText>
                    )}

                </div>
                <Footer
                    nextClickHandler = {this.submitHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}

export default withStyles(styles)(ActivityLocation);