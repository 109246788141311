import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Summary from "./edit-reservation/Summary";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    width: '100%',
  },
  header: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    position: 'fixed',
    background: 'white',
    width: '100%',
  },
  section: {
    marginTop: 60,
  },
  arrowIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    transition: '150ms',
    top: 10,
    marginRight: 10,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cardRoot: {
    marginRight: '20px',
    marginLeft: '20px',
    marginBottom: '10px',
  }
}));

const SummaryDrawer = (props) => {
  const classes = useStyles();
  const { open } = props;

  return (
    <Drawer
      open={open}
      anchor={'right'}
      onClose={props.closeLog}
      transitionDuration={400}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <div className={classes.toolbar}>
          <ArrowForwardIcon className={classes.arrowIcon} onClick={props.closeLog} />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.cardRoot}>
          {!props.loadingReservation && <Summary />}
        </div>
      </div>

    </Drawer>
  )

}

export default (SummaryDrawer);