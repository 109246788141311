const CHANNEL = {
  Hostelworld: {
    code: 'Hostelworld',
    name: 'Hostelworld',
    icon: '/images/hostelworld.jpg',
    channelRefresh: true,
    mappingRefresh: true,
    roomTypeIDKey: 'hw_roomTypeID',
    advancedRatePlanMapping: true,
    allowDeletePropertyMapping: false,

    API: {
      createRoomTypeMapping: '/api/hostelworld/roomTypes/map',
      createRatePlanMapping: '/api/frontdesk/ratePlans/',
      fetchChannelRatePlans: '/api/hostelworld/ratePlans/',
      fetchChannelRoomType: '/api/hostelworld/roomTypes/',
      createChannelFullRefresh: '/api/hostelworld/fullRefresh/',
      deleteRoomTypeMapping: '/api/hostelworld/roomTypes/map/',
      deleteRatePlanMapping: '/api/frontdesk/ratePlans/',
      setupChannel: '/api/hostelworld/channelSetup/',
    }

  },
  myAllocator: {
    code: 'myAllocator',
    name: 'myAllocator',
    icon: '/images/icons/myAllocator-icon.png',
    channelRefresh: true,
    mappingRefresh: false,
    roomTypeIDKey: 'mya_roomTypeID',
    advancedRatePlanMapping: false,
    allowDeletePropertyMapping: true,

    API: {
      createRoomTypeMapping: '/api/myAllocator/roomTypes/map',
      fetchChannelRoomType: '/api/myAllocator/roomList/',
      createChannelFullRefresh: '/api/myAllocator/fullRefresh/',
      deleteRoomTypeMapping: '/api/myAllocator/roomTypes/map/',
      deletePropertyChannelMapping: '/api/myAllocator/property/map/',
    }
  },
  BookingCom: {
    code: 'BookingCom',
    name: 'Booking.com',
    channelRefresh: true,
    mappingRefresh: false,
    roomTypeIDKey: 'channelRoomTypeID',
    advancedRatePlanMapping: true,
    allowDeletePropertyMapping: false,
    API: {
      createRoomTypeMapping: '/api/channex/BookingCom/roomTypes/map',
      createRatePlanMapping: '/api/channex/BookingCom/ratePlans/',
      fetchChannelRatePlans: '/api/channex/BookingCom/ratePlans/',
      fetchChannelRoomType: '/api/channex/BookingCom/roomTypes/',
      createChannelFullRefresh: '/api/channex/BookingCom/fullRefresh/',
      deleteRoomTypeMapping: '/api/channex/BookingCom/roomTypes/map/',
      deleteRatePlanMapping: '/api/channex/BookingCom/delete/ratePlans/',
      // setupChannel: '/api/channex/BookingCom/channelSetup/',
    }
  },
  Agoda: {
    code: 'Agoda',
    name: 'Agoda',
    channelRefresh: false,
    mappingRefresh: false,
    roomTypeIDKey: 'channelRoomTypeID',
    advancedRatePlanMapping: true,
    allowDeletePropertyMapping: false,
    API: {
      createRoomTypeMapping: '/api/channex/Agoda/roomTypes/map',
      createRatePlanMapping: '/api/channex/Agoda/ratePlans/',
      fetchChannelRatePlans: '/api/channex/Agoda/ratePlans/',
      fetchChannelRoomType: '/api/channex/Agoda/roomTypes/',
      // createChannelFullRefresh: '/api/channex/Agoda/fullRefresh/',
      deleteRoomTypeMapping: '/api/channex/Agoda/roomTypes/map/',
      deleteRatePlanMapping: '/api/channex/Agoda/delete/ratePlans/',
      // setupChannel: '/api/channex/Agoda/channelSetup/',
    }
  },
  AirBNB: {
    code: 'AirBNB',
    name: 'Airbnb',
    channelRefresh: false,
    mappingRefresh: false,
    roomTypeIDKey: 'channelRoomTypeID',
    advancedRatePlanMapping: true,
    allowDeletePropertyMapping: false,
    API: {
      // createRoomTypeMapping: '/api/channex/AirBNB/roomTypes/map',
      // createRatePlanMapping: '/api/channex/AirBNB/ratePlans/',
      fetchChannelRatePlans: '/api/channex/AirBNB/ratePlans/',
      fetchChannelRoomType: '/api/channex/AirBNB/roomTypes/',
      // createChannelFullRefresh: '/api/channex/AirBNB/fullRefresh/',
      // deleteRoomTypeMapping: '/api/channex/AirBNB/roomTypes/map/',
      // deleteRatePlanMapping: '/api/channex/AirBNB/delete/ratePlans/',
      // setupChannel: '/api/channex/AirBNB/channelSetup/',
    }
  },
  Expedia: {
    code: 'Expedia',
    name: 'Expedia',
    channelRefresh: false,
    mappingRefresh: false,
    roomTypeIDKey: 'channelRoomTypeID',
    advancedRatePlanMapping: true,
    allowDeletePropertyMapping: false,
    API: {
      createRoomTypeMapping: '/api/channex/Expedia/roomTypes/map',
      createRatePlanMapping: '/api/channex/Expedia/ratePlans/',
      fetchChannelRatePlans: '/api/channex/Expedia/ratePlans/',
      fetchChannelRoomType: '/api/channex/Expedia/roomTypes/',
      // createChannelFullRefresh: '/api/channex/Expedia/fullRefresh/',
      deleteRoomTypeMapping: '/api/channex/Expedia/roomTypes/map/',
      deleteRatePlanMapping: '/api/channex/Expedia/delete/ratePlans/',
      // setupChannel: '/api/channex/Expedia/channelSetup/',
    }
  }
}


export { CHANNEL }