import { BOOKING_SOURCE } from "./constants";

const DATA_ACTION = {
    NAME_STATUS: 'NAME_STATUS',
    NAME_CODE: 'NAME_CODE',
    NAME_CODE_STATUS: 'NAME_CODE_STATUS',
    ROOM_DISPLAY_STATUS: 'ROOM_DISPLAY_STATUS',
    ROOM_TYPE_DISPLAY_STATUS: 'ROOM_TYPE_DISPLAY_STATUS',
}

const constantBookingSources = [
    'Hostelworld', 
    'Booking.com', 
    'Airbnb', 
    'Expedia'
];

const inventoryConditionHandler = (data = {}, action, payload) => {
    let returnValue;

    switch (action) {

        case DATA_ACTION.NAME_STATUS:
            returnValue = data.isActive ? data.name : (data.name || '') + ' [DELETED]';
            break;

        case DATA_ACTION.NAME_CODE:
            returnValue = data.internalCode ? data.name + ` (${data.internalCode})` : data.name;
            break;

        case DATA_ACTION.NAME_CODE_STATUS:
            returnValue = data.internalCode ? data.name + ` (${data.internalCode})` : data.name;
            returnValue = data.isActive ? returnValue : returnValue + ' [DELETED]';
            break;

        case DATA_ACTION.ROOM_DISPLAY_STATUS:
            returnValue = data.isActive;
            break;

        case DATA_ACTION.ROOM_TYPE_DISPLAY_STATUS:
            returnValue = data.isActive && data.isVisible;
            break;

        default:
            returnValue = null;
            break;

    }

    return returnValue;
}

const ACCCOMMODATION_NAME_TEMPLATES = {
    dorm: "${roomName} ${bedName}",
    private: "${roomName}"
}

const getAccommodationName = ({ dormOrPrivate = '', roomName = '', bedName = '' }, customTemplate = '' ) =>{

    if(!roomName && !bedName) return '';

    const replacements = {"${roomName}": roomName, "${bedName}": bedName};
    const regex = /[${]+\w+}/g;
    let template = ACCCOMMODATION_NAME_TEMPLATES.private;

    if(dormOrPrivate == 'dorm'){
        template = customTemplate || ACCCOMMODATION_NAME_TEMPLATES.dorm;
    }

    return template.replace(regex, all =>replacements[all] || all);
} 

const getBookingSources = (live = {}, currentSpace = {}) =>{
    const sourceData = live?.[currentSpace?._id]?.resvSource || [];
    
    const bookingSourceData = sourceData.map(data => data._id.status ? data._id.status : "None");
    
    const constantOptions = Object.keys(BOOKING_SOURCE).map(source => BOOKING_SOURCE[source]);
    let bookingSources = [ ...constantBookingSources, ...constantOptions, ...bookingSourceData ];
    bookingSources = [ ...new Set(bookingSources) ];
    return bookingSources;
}

export {
    DATA_ACTION,
    inventoryConditionHandler,
    getAccommodationName,
    getBookingSources
}