
// Select Hostel on Map
export const selectHostel = (selected_hostel_id = '') => ({
    type: 'SELECT_HOSTEL',
    selected_hostel_id
})

// Click Hostel to view more details
export const hostelDialog = (dialog_hostel_id = '') => ({
    type: 'HOSTEL_DIALOG',
    dialog_hostel_id
})

// Toggle Login
export const setDisplayLogin = (display_login = false) => ({
    type: 'DISPLAY_LOGIN',
    display_login
})

// Toggle Signup
export const setDisplaySignup = (display_signup = false) => ({
    type: 'DISPLAY_SIGNUP',
    display_signup
})

// Toggle Sales Page Drawer
export const setSalesDrawer = (sales_drawer = false) => ({
    type: 'SALES_DRAWER',
    sales_drawer
})

// Display Modal for New Question
export const displayNewPostDialog = (display_new_post_dialog = false) => ({
    type: 'DISPLAY_NEW_POST_DIALOG',
    display_new_post_dialog
})

// TOGGLE ONBOARDING
export const setOnboarding = (onboarding = false) => ({
    type: 'SET_ONBOARDING',
    onboarding
})
