import React from 'react';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";

import CalendarDates from '../../beds/CalendarDates';
import ManualRoomSelectForm from '../ManualReservationForm';
import ManualAccommodation from "./ManualAccommodation";
import { fetchDateFormat, DATE_FORMATS, getMomentAddDayISO, getDatesOfRange, getAmtFormat, fetchCurrencyFormat } from '../../../utils/utility';
import { ICONS } from '../../../utils/imageUrls';
import { TAX_TYPE, TAX_CALULATION_MODE } from '../../../utils/constants';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    borderRadius: 2,
    backgroundColor: '#f8f9fb',
    padding: 30,
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px'
    }
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 30
  },
  sectionGuest: {
    height: 90,
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 75
    }
  },
  icon: {
    width: 20,
    height: 20,
    padding: 20,
    borderRadius: '50%',
    boxShadow: '0 0 5px 5px #f5f5f5',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  sectionAmount: {
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '20px 0',
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: '10px 10px 15px',
      '& span': {
        marginTop: 5
      }
    }
  },
  label: {
    fontSize: '1.4rem',
    display: 'block',
    fontWeight: 600
  },
}));

const ManualSelection = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { guestCount, date, nights, currentSpace, currentStep, prevStep, manualDefault, data, options, roomTypes, rooms, beds, taxes, edit, t } = props;
  const startDate = date || null;
  const endDate = nights === 1 ? date: fetchDateFormat(getMomentAddDayISO(date, (nights - 1)), DATE_FORMATS.DEFAULT_DATE) || null;
  const [day, setDay] = React.useState({})
  const [manualFormData, setManualFormData] = React.useState([])
  const [manualOptions, setManualOptions] = React.useState([])
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const [total, setTotal] = React.useState(0);
  const [accommodationAmount, setAccommodationAmount] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const currency = fetchCurrencyFormat(currentSpace ? currentSpace.currency : null);
  const defaultSelection = true;
  let formRef = React.createRef();

  // -------------- Use effects --------------
  React.useEffect(() => {
    if (data) {
      if (defaultSelection && options && currentStep > prevStep) {
        handleSemiManualData({ ...data }, [...options])
      } else {
        handleManualData();
      }
    }
    if (options) {
      setManualOptions([...options]);
    }
  }, [data, options])

  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  React.useImperativeHandle(ref, () => ({
    handleProcessData() {
      let data = handleFinalData();
      return {
        calendarOptions: data.finalInfo,
        roomSelectionError: data.error,
        manualDefaultData: manualFormData,
        total: total
      }
    }
  }))

  // -------------- On day changes --------------
  const handleCalendarDays = (day) => {
    setDay(day)
  };

  // -------------- On load format manual data --------------
  const handleManualData = () => {
    if (currentStep < prevStep && manualDefault && manualDefault.length) {
      setManualFormData([...manualDefault])
      handleCalculateAmount(manualDefault);
    } else
      if (Object.keys(data).length) {
        let manualFormInfo = [];
        let endCount = 0;
        let datesOfRange = getDatesOfRange(startDate, endDate, currentSpace.timezone, 'objectArray')

        Object.keys(data).map(id => {
          let count = data[id] + endCount;
          for (let i = endCount; i < count; i++) {
            manualFormInfo.push({ roomTypeID: id, guestNumber: i + 1, items: datesOfRange, selectedDate: startDate, hide: false })
          }
          endCount = count;
        })
        setManualFormData([...manualFormInfo])
      }
  }

  // -------------- On load format semi-manual data --------------
  const handleSemiManualData = (semiData, semiOptions) => {
    if (Object.keys(semiData).length) {
      let manualFormInfo = [];
      let endCount = 0;

      Object.keys(semiData).map(id => {
        let count = semiData[id] + endCount;
        for (let i = endCount; i < count; i++) {
          let datesOfRange = getDatesOfRange(startDate, endDate, currentSpace.timezone, 'objectArray')
          let manualFormObj = { roomTypeID: id, guestNumber: i + 1, items: [...datesOfRange], selectedDate: startDate };
          for (let dateIndex = 0; dateIndex < manualFormObj.items.length; dateIndex++) {
            semiOptions = semiOptions.map(optionData => {
              let option = {...optionData};
              if (option.roomTypeID === id && option.date === manualFormObj.items[dateIndex].date && !manualFormObj.items[dateIndex].roomTypeID && !option.itemID) {
                manualFormObj.items[dateIndex].roomTypeID = option.roomTypeID;
                manualFormObj.items[dateIndex].rate = option.rate || 0;
                manualFormObj.items[dateIndex].roomID = option.roomID;
                manualFormObj.items[dateIndex].bedID = option.bedID;

                option.itemID = i + 1;
              }
              return option;
            })
          }
          manualFormInfo.push(manualFormObj)
        }
        endCount = count;
      })
      setManualFormData([...manualFormInfo])
      handleCalculateAmount(manualFormInfo)
      setManualOptions([...semiOptions]);
    }
  }

  // -------------- On selected item --------------
  const handleSelected = (itemID, currentDate, newDate) => {
    // console.log("itemID, currentDate, newDate", itemID, currentDate, newDate)
    let status = formRef.current.handleFormStatus(newDate);
    if (!status && currentDate !== newDate) {
      let formattedData = [...manualFormData].map(item => {
        let manualItem = { ...item };
        if (manualItem.guestNumber === itemID) {
          manualItem.selectedDate = newDate;
          manualItem.hide = false;
        }
        return manualItem;
      })
      setManualFormData([...formattedData])
    }
  }

  // -------------- On save of an item --------------
  const handleSaveSelected = (itemID, savedData) => {
    let formattedData = [...manualFormData].map(item => {
      let manualItem = { ...item };
      if (manualItem.guestNumber === itemID) {
        manualItem = { ...savedData };
        manualItem.hide = true;
        manualItem.selectedDate = '';
      }
      return manualItem;
    })
    setManualFormData([...formattedData])
    handleCalculateAmount(formattedData);
  }

  // -------------- On save of an item --------------
  const handleDiscard = (itemID) => {
    let formattedData = [...manualFormData].map(item => {
      let manualItem = { ...item };
      if (manualItem.guestNumber === itemID) {
        manualItem.hide = true;
        manualItem.selectedDate = '';
      }
      return manualItem;
    })
    setManualFormData([...formattedData])
  }

  // ------------- Formatting final data ------------------
  const handleFinalData = () => {
    let finalInfo = [], bedsPerDate = {}, error = [];

    for (let index = 0; index < manualFormData.length; index++) {
      const element = manualFormData[index];
      for (let itemIndex = 0; itemIndex < element.items.length; itemIndex++) {
        const itemElement = element.items[itemIndex];

        if (!bedsPerDate[itemElement.date]) bedsPerDate[itemElement.date] = [itemElement.bedID];
        else if (bedsPerDate[itemElement.date] && bedsPerDate[itemElement.date].includes(itemElement.bedID)) {
          error.push({
            date: itemElement.date,
            bedID: itemElement.bedID,
            roomID: itemElement.roomID,
            roomTypeID: itemElement.roomTypeID,
            guest: `guest ${element.guestNumber}`
          })
        } else {
          bedsPerDate[itemElement.date].push(itemElement.bedID);
        }

        finalInfo.push({
          ...(!edit && {reservationID: "NEW_RES"}),
          date: itemElement.date,
          bedID: itemElement.bedID,
          roomID: itemElement.roomID,
          roomTypeID: itemElement.roomTypeID,
          rate: Number(itemElement.rate),
          guest: `guest ${element.guestNumber}`
        })
      }
    }

    // console.log('error', error, bedsPerDate)

    return { finalInfo, error };
  }


  // ------------- Calculate Amount and tax ------------------
  const handleCalculateAmount = (savedData) => {
    let inclusiveTax = 0, exclusiveTax = 0, accommodationAmount = 0;

    let typeRate = {}
    for (let index = 0; index < savedData.length; index++) {
      const element = savedData[index];
      for (let itemIndex = 0; itemIndex < element.items.length; itemIndex++) {
        const itemElement = element.items[itemIndex];
        if (itemElement.roomTypeID) {
          accommodationAmount += Number(itemElement.rate);
          if (typeRate[itemElement.roomTypeID]) {
            typeRate[itemElement.roomTypeID].accommodationAmount += Number(itemElement.rate);
            typeRate[itemElement.roomTypeID].totalGuestDuration += 1;
            typeRate[itemElement.roomTypeID].rateList.push(Number(itemElement.rate));
            if (!typeRate[itemElement.roomTypeID].guest.includes(`guest${savedData.guestNumber}`)) {
              typeRate[itemElement.roomTypeID].guest.push(`guest${savedData.guestNumber}`)
            }
          } else {
            typeRate[itemElement.roomTypeID] = {
              accommodationAmount: Number(itemElement.rate),
              rateList: [Number(itemElement.rate)],
              totalGuestDuration: 1,
              guest: [`guest${savedData.guestNumber}`]
            };
          }
        }
      }
    }
    // console.log('typeRate', typeRate)
    Object.keys(typeRate).map(id => {
      let taxesApplicable = (roomTypes[id] && roomTypes[id].taxesApplicable) ? [...roomTypes[id].taxesApplicable] : [];
      for (let i = 0; i < taxesApplicable.length; i++) {
        let taxItem = { ...taxes[taxesApplicable[i]] };
        let tax = 0;
        // console.log("taxItem", taxItem)
        switch (taxItem.type) {
          case TAX_TYPE.AMOUNT_PER_ACCOMMODATION:
            tax = taxItem.value
            // console.log('AMOUNT_PER_ACCOMMODATION', tax)
            break;
          case TAX_TYPE.AMOUNT_PER_NIGHT:
            tax = (taxItem.value * typeRate[id].totalGuestDuration)
            // console.log('AMOUNT_PER_NIGHT', tax)
            break;
          case TAX_TYPE.AMOUNT_PER_PERSON:
            tax = (taxItem.value * typeRate[id].guest.length)
            // console.log('AMOUNT_PER_PERSON', tax)
            break;
          case TAX_TYPE.PERCENT_OF_TOTAL:
            tax = (taxItem.value * typeRate[id].accommodationAmount) / 100
            // console.log('PERCENT_OF_TOTAL', tax)
            break;
          case TAX_TYPE.RATE_SLABS:
            typeRate[id].rateList.forEach(rate => {
              tax += taxItem.rateSlabRules
                .filter(rule => rate >= rule.priceFrom && rate <= rule.priceTo)
                .map(rule => { return (rate * rule.value) / 100; })
                .reduce((a, b) => a + b, 0);
            })
            // console.log('RATE_SLABS', tax)
            break;

          default:
            tax = 0;
            break;
        }

        // console.log(taxItem.calculationMode, TAX_CALULATION_MODE.EXCLUSIVE)
        if (taxItem.calculationMode == TAX_CALULATION_MODE.EXCLUSIVE) {
          exclusiveTax += Number(tax)
        }
        else {
          inclusiveTax += Number(tax)
        }
      }
    });

    // console.log('inclusiveTax> ', inclusiveTax, 'exclusiveTax> ', exclusiveTax)
    // const [total, setTotal] = React.useState(0);
    setAccommodationAmount(accommodationAmount || 0);
    setTaxAmount((exclusiveTax || 0) + (inclusiveTax || 0));
    setTotal((accommodationAmount || 0) + (exclusiveTax || 0))
  }

  return (
    <>
      <div className={classes.container} ref={ref}>
        <div className={classes.title}>Manual room selection</div>

        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            {startDate && endDate &&
              <CalendarDates
                dateRangeAPIEnable={false}
                showPerNight={true}
                dateSelectEnabled={true}
                calendarDaysEnabled={true}
                calendarChangeEnabled={
                  (windowWidth <= 901 && nights > 3) ||
                  nights > 4
                }
                checkIn={startDate}
                checkOut={endDate}
                handleCalendarDays={handleCalendarDays}
                numberOfDays={windowWidth > 901 ? '5' : '3'}
                calendarChangeBeyond={{ prev: 0, next: 0 }}
              />
            }
          </Grid>
        </Grid>
        {manualFormData.map((selectionData, i) => {
          let selection = { ...selectionData };
          return (
            <Grid container key={i}>
              <Grid item xs={2}>
                <div className={classes.sectionGuest}><img className={classes.icon} src={IMAGES.ICONS.darkBoyBroadSmile} /></div>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  <Grid xs={12} item>
                    {Object.keys(day).length > 0 &&
                      <ManualAccommodation
                        itemID={selection.guestNumber}
                        day={day}
                        data={selection.items}
                        selectedDate={selection.selectedDate}
                        currentSpace={currentSpace}
                        numberOfDays={windowWidth > 901 ? '5' : '3'}
                        handleSelected={handleSelected}
                      />}
                  </Grid>
                  <Grid xs={12} item>
                    <ManualRoomSelectForm
                      ref={formRef}
                      itemID={selection.guestNumber}
                      selectedDate={selection.selectedDate}
                      currentSpace={currentSpace}
                      data={selection}
                      startDate={startDate}
                      endDate={endDate}
                      manualOptions={manualOptions}
                      roomTypes={roomTypes}
                      rooms={rooms}
                      beds={beds}
                      hide={selection.hide}
                      handleSaveSelected={handleSaveSelected}
                      handleDiscard={handleDiscard}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </div>

      <div className={classes.sectionAmount} ref={ref}>
        <span className={classes.label}>{t('newReservation.roomSelection.accomodationAmount')} {getAmtFormat(accommodationAmount, currency)}</span>
        <span className={classes.label}>{t('newReservation.roomSelection.taxAmount')} {getAmtFormat(taxAmount, currency)}</span>
        <span className={classes.label}>{t('newReservation.roomSelection.totalAmount')} {getAmtFormat(total, currency)}</span>

      </div>
    </>
  )

});

export default withTranslation('translation', { withRef: true })(ManualSelection);