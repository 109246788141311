import React from 'react';
import { Button, Dialog, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PlusMinus from '../../../common/PlusMinus';
import { ICONS } from '../../../../utils/imageUrls';
import GuestForm from '../../GuestForm';

const useStyles = makeStyles(theme => ({
    dialog: {

    },
    dialogTitle: {
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: 15,
        position: 'sticky',
        top: 0,
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 15,
        transition: '150ms',
        top: 15,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    content: {
        display: 'flex',
        minHeight: 200,
        justifyContent: 'center',
        padding: '20px 40px',
    },
    card: {
        width: '40%',
        padding: 20,
        boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 75%)',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardContent: {
        textAlign: 'center',
        lineHeight: '25px'
    },
    primaryText: {

    },
    boldText: {
        fontWeight: 600
    },
    buttonContainer: {
        marginTop: 10,
        '& button': {
            boxShadow: 'unset !important',
            border: '1px solid #e5e5e5 !important'
        },
        '& span': {
            boxShadow: 'unset !important',
        }
    },
    or: {
        width: '10%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    plainButton: {
        width: '100%',
        border: 'none',
        height: '50px',
        padding: '0',
        minWidth: '30px',
        background: '#fff',
        boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 75%)',
        minHeight: '30px',
        borderRadius: '5px',
    },
    icon: {
        height: 20,
        width: 20,
        '&.rounded': {
            height: 12,
            width: 12,
        }
    },
    footer: {
        display: 'flex',
        padding: '15px 20px',
        minHeight: 40,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    actionButton: {
        backgroundColor: '#000000 !important',
        color: '#ffffff',
        padding: '6px 25px'
    }
}));

const AddExperience = (props) => {
    const classes = useStyles();
    const { data } = props;
    const [masterBookerCount, setMasterBookerCount] = React.useState(0);
    const [newCount, setNewCount] = React.useState(0);
    const [showGuestForm, setShowGuestForm] = React.useState(false);
    const [guests, setGuests] = React.useState({});

    const saveFormHandler = () => {
        if (masterBookerCount) {
            props.saveFormHandler(null, { count: masterBookerCount })
        }

        if(newCount){
            props.saveFormHandler(guests, { count: newCount })
        }
        handleClose();
    }

    const handleClose = () => {
        setMasterBookerCount(0);
        setNewCount(0);
        setShowGuestForm(false);
        setGuests({})
        props.handleClose();
    }

    const handleGuestInfo = (count, data) => {
        setGuests(data);
      }

    return (
        <Dialog
            open={props.open}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={window.innerWidth < 901}>

            <div className={classes.dialog}>
                <div className={classes.dialogTitle}>
                    <div className={classes.header}>{data?.title}</div>
                    <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                </div>

                {!showGuestForm &&
                    <div className={classes.content}>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <div className={classes.primaryText}>Add participants</div>
                                <div className={clsx(classes.primaryText, classes.boldText)}>to Master booker</div>
                                <div className={classes.buttonContainer}>
                                    <PlusMinus
                                        fullWidth={true}
                                        prefilledCount={masterBookerCount}
                                        onCountChange={(value) => { setMasterBookerCount(value); setNewCount(0) }}
                                        maximum={data.available} />
                                </div>
                            </div>
                        </div>

                        <div className={classes.or}>or</div>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <div className={classes.primaryText}>Add participants</div>
                                <div className={clsx(classes.primaryText, classes.boldText)}>under new name</div>
                                <div className={classes.buttonContainer}>
                                    <PlusMinus
                                        fullWidth={true}
                                        prefilledCount={newCount}
                                        onCountChange={(value) => { setNewCount(value); setMasterBookerCount(0) }}
                                        maximum={data.available} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {showGuestForm &&
                    <GuestForm
                        open={true}
                        mode={"add"}
                        currentCount={0}
                        new={true}
                        handleGuestInfo={handleGuestInfo}
                        timezone={props.timezone}
                    />
                }

                <div className={classes.footer}>
                    {(masterBookerCount > 0 || showGuestForm)&& <Button className={classes.actionButton} onClick={saveFormHandler}>Save</Button>}
                    {newCount > 0 && !showGuestForm && <Button className={classes.actionButton} onClick={() => setShowGuestForm(true)}>Next</Button>}
                </div>
            </div>
        </Dialog>
    )
}

export default AddExperience;