const defaultConfig = {
    config: {},
    failedBills: {}
}

// ----------------------------------------------------------------
// CASH DRAWERS REDUCER
// ----------------------------------------------------------------
export default (state = defaultConfig, action) => {
    switch (action.type) {
        case 'FETCH_MASTERWAY_CONFIG_SUCCESS':
            return {
                ...state,
                config: { ...action.payload }
            }
        case 'PATCH_MASTERWAY_CONFIG_SUCCESS':
            return {
                ...state,
                config: { ...action.payload }
            }
        case 'FETCH_MASTERWAY_BILL_STATUS_SUCCESS': 
            return {
                ...state,
                failedBills: { ...action.payload }
            }
        case 'RETRY_MASTERWAY_FAILED_BILL_SUCCESS': 
            return {
                ...state,
                failedBills: {
                    ...state.failedBills,
                    ...action.payload
                }
            }
        case 'RESET_MASTERWAY': {
            return { ...defaultConfig }
        }
        default:
            return state
    }
}
