import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import * as i18nIsoCountries from "i18n-iso-countries";

import GuestTile from '../../search/GuestTile';
import { getAvatarFlag, humanise } from '../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        top: "83px",
        right: "0px",
        width: "40%",
        height: "calc(100% - 155px)",
        position: "absolute",
        backgroundColor: "#fff",
        borderLeft: "1px solid #0000001f",
        overflow: "hidden",
        backgroundColor: "#f8f9fb",
        [theme.breakpoints.down('xs')]: {
            width: "60%",
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "0px 10px",
    },
    closeIcon: {
        padding: "5px",
    },
    cardListing: {
        position: 'relative',
        padding: "10px 40px",
        height: "calc(100% - 70px)",
        overflowY: "scroll",
        [theme.breakpoints.down('xs')]: {
            padding: "10px",
        }
    },
    card: {
        width: '100%',
        marginBottom: '20px'
    },
    loader: {
        position: 'absolute',
        top: '40%',
        left: '45%'
    }
}));

const TagGuestDrawer = props =>{
    const classes = useStyles();
    const { customerOptions, tagCustomerHandler, searchInProgress, closeDrawerHandler } = props;

    return (
        <div className={classes.root}>
            
            <div className={classes.header}>
                <h4>Tag Customer</h4>
                <IconButton 
                    className={classes.closeIcon} 
                    onClick={closeDrawerHandler}
                >
                    <CloseIcon/>
                </IconButton>
            </div>

            <div className={clsx(classes.cardListing, 'hideScrollBar')}>
                {customerOptions.map((customer, index) =>{
                    const countryName = i18nIsoCountries.getName(customer.nationality, "en") || customer.nationality
                    return (
                        <div 
                            key={`customer_${customer._id}_${index}`}
                            className={classes.card}
                            onClick={() =>tagCustomerHandler(customer)}
                        >
                            <GuestTile
                                key={`guestTile_${index}`} 
                                guestName={humanise(customer.firstName + ' ' + customer.lastName)}
                                status={customer.email}
                                phone={customer.phone}
                                avatar={customer.image || getAvatarFlag(countryName)}
                                isCustomerCard
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                showCode={false}
                            />
                        </div>
                    )})
                }

                {!searchInProgress && customerOptions.length == 0 && <h3>No records found</h3>}

                {searchInProgress && <CircularProgress className={classes.loader}/>}
            </div>


        </div>
    )
}

export default TagGuestDrawer;