import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { some } from 'lodash';

import IMAGES from '../../constants/images';
import {submitSearchKeyword, setSearchActive, clearSearch} from '../../redux/actions/search/search';

import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

const maxSearchLength = 64

const useStyles = makeStyles(theme => ({
  searchContainer: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '5px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    height: 40,
    marginRight: 10,
    paddingLeft: 40,
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
      width: 'calc(100vw - 100px)',
      margin: '5px 20px 10px 20px',
    },
    '&:hover':{
      border: '1px solid #666666'
    },
  },
  active: {
    border: '1px solid #666666'
  },
  searchIcon: {
    position: 'absolute',
    height: 15,
    left: 10,
    opacity: 0.5,
    top: 12,
    width: 15
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: 5,
    position: 'absolute',
    right: 5,
    top: 6,
    '&:hover': {
      background: '#F0F0F0'
    }
  },
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px #0000001A, 0 4px 11px #0000001A',
    marginTop: '8px'
  },
  listBox: {
    padding: '4px 0px'
  },
  suggestionItems: {
    padding: '8px 12px',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#DEEBFF'
    }
  }
}))

const Paper = props => {
  const classes = useStyles()
  const { children } = props
  return some(children, child => child) ? <div className={classes.paper}>{children}</div> : null
}

const Listbox = React.forwardRef((props, ref) => {
  const classes = useStyles()
  return (
    <ul id={props.id} className={clsx(classes.listBox)} role={props.role} onMouseDown={props.onMouseDown}>
      {props.children.map((child, index) => (
        <li
          key={index}
          className={clsx(child.props.className, classes.suggestionItems)}
          data-option-index={index}
          onClick={child.props.onClick}
          onMouseOver={child.props.onMouseOver}
          onTouchStart={child.props.onTouchStart}
        >
          {child.props.children}
        </li>
      ))}
    </ul>
  )
})

const SearchBar = props => {
  const classes = useStyles()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [search, setSearch] = React.useState('');
  const { dispatch, suggestions, currentSpace, status, date, roomType, searchActive } = props;

  // Clear search bar if search is no longer active
  // This can be triggered from other places in the app
  // Such as search results
  // --------------------------------------------------------
  useEffect(() => {
    if (!searchActive) {
      setSearch('')
    }
  }, [searchActive])

  const handleSearch = (propertyID, value, status, date, roomType) => {
    dispatch(setSearchActive(true));
    dispatch(submitSearchKeyword(propertyID, value, status, date, roomType));
  }

  const handleClearSearch = () => {
    dispatch(clearSearch());
    dispatch(setSearchActive(false));
  }

  const debounceHandler = useCallback(_.debounce(handleSearch, 750), []);
  const onChangeHandler = e =>{
    if (!e) return
    let value = e.target.value || e.target.textContent
    value = value.slice(0, maxSearchLength)
    setSearchKeyword(value)
    setSearch(value);
    debounceHandler(currentSpace?.propertyID, value, status, date, roomType)
  }

  return (
    <div className={clsx({[classes.searchContainer]: true, [classes.active]: searchActive})}>
      <img className={classes.searchIcon} src={IMAGES.ICONS.search2} />
      <Autocomplete
        options={searchKeyword.length > 2 ? suggestions : []}
        inputValue={search}
        freeSolo
        disableClearable
        filterOptions={option => option}
        autoComplete
        fullWidth
        onInputChange={onChangeHandler}
        renderInput={params => (
          <TextField
            {...params}
            id='search'
            name='search'
            placeholder='Search'
            fullWidth
            type='text'
            maxLength={maxSearchLength}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        )}
        PaperComponent={Paper}
        ListboxComponent={Listbox}
      />
      {search && <CloseIcon className={classes.closeIcon} onClick={handleClearSearch} />}

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  suggestions: state.search.suggestions,
  status: state.search.status,
  date: state.search.date,
  roomType: state.search.roomType,
  searchActive: state.search.searchActive
})

export default connect(mapStateToProps)(SearchBar)
