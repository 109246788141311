import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { initials, capitalizeFirst } from '../utils/wordUtils';
import FrontDeskStaff from './FrontdeskStaff';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardActionArea, Typography } from '@material-ui/core';

const useStyles = makeStyles ( theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  card: {
    alignItems: 'stretch',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  cardAction: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  header: {
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    }
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 200,
    width: '100%'
  },
  bubbleHolder: {
    cursor: 'pointer',
    margin: "auto",
    paddingTop: theme.spacing(1),
    width: 50
  },
  bubble: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    borderRadius:"50%",
    color: '#ffffff',
    fontSize: "2rem",
    height: 50,
    lineHeight: 2.5,
    textAlign: 'center',
    width: 50
  },
  name: {
    color: '#666666',
    display: 'flex',
    fontSize: '1rem',
    justifyContent: 'center',
    marginTop: 5,
    textAlign: 'center',
  }
}))


const FrontdeskStaffSnapshot = (props) => {
  const { t } = props
  const classes= useStyles();

  const {currentSpace, frontdeskUsers, loadingFrondeskUsers} = props;
  const [staffModal, setStaffModal] = React.useState(false);

  const handleOpenStaffModal = () => {
    setStaffModal(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }

  const handleCloseStaffModal = () => {
    setStaffModal(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  return(
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea className={classes.cardAction} onClick={handleOpenStaffModal}>

          <div className={classes.header}>
            <Typography component="h4" variant="h6">{t('frontdesk.frontdeskAccess.name')}</Typography>
          </div>

          <div className={classes.userContainer}>
            <Grid container spacing={1}>
              {currentSpace && 
                currentSpace.frontdeskUsers && 
                !loadingFrondeskUsers &&
                currentSpace.frontdeskUsers
                  .filter(user => frontdeskUsers[user] && frontdeskUsers[user].isDeleted === false)
                  .slice(0,6)
                  .map(profile => (
                  <Grid item xs={4} sm={4} lg={4} key={profile}>
                    <div className={classes.bubbleHolder}>
                      <Avatar
                        alt={initials(frontdeskUsers[profile].firstName + ' ' + frontdeskUsers[profile].lastName)} 
                        src={frontdeskUsers[profile].avatar} 
                        className={classes.bubble}
                        title={frontdeskUsers[profile].firstName + ' ' + frontdeskUsers[profile].lastName} 
                      >
                        {initials(frontdeskUsers[profile].firstName + ' ' + frontdeskUsers[profile].lastName)}
                      </Avatar>
                    </div>
                    <div className={classes.name}>
                      <span>{frontdeskUsers[profile].firstName}</span>
                    </div>
                  </Grid>
              ))}

            </Grid>
          </div>
        </CardActionArea>
      </Card>

      {staffModal && 
        <FrontDeskStaff
          open={staffModal} 
          handleCloseStaffModal={handleCloseStaffModal} 
        />
      }
      
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  frontdeskUsers: state.frontdeskUsers,
  loadingFrondeskUsers: state.loading.LOAD_FRONTDESK_USERS
})

export default withTranslation()(connect(mapStateToProps)(FrontdeskStaffSnapshot));