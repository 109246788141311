export default (state = {}, action) => {
    switch (action.type) {

        case 'FETCH_INBOX_CHANNELS_STATUS_SUCCESS':
            return {
                ...state,
                channelList: {...action.payload }
            }

        case 'RESET_INBOX_CHANNEL_STATUS':
            return {}
          
      default:
        return state
    }
}