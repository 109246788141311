const fetchProformaInfo = (invoiceProforma) => {
  const data = {}
  if(invoiceProforma) {
  	data.issuerName = (invoiceProforma.issuerName) ? (invoiceProforma.issuerName) : ''
  	data.prefix = (invoiceProforma.prefix) ? (invoiceProforma.prefix) : ''
  	data.suffix = (invoiceProforma.suffix) ? (invoiceProforma.suffix) : ''
  	data.proformaPrefix = (invoiceProforma.proformaPrefix) ? (invoiceProforma.proformaPrefix) : ''
    data.proformaSuffix = (invoiceProforma.proformaSuffix) ? (invoiceProforma.proformaSuffix) : ''
  	data.customText = (invoiceProforma.customText) ? (invoiceProforma.customText) : ''
  	data.startingIndexNumber = (invoiceProforma.startingIndexNumber) ? (invoiceProforma.startingIndexNumber) : 0
  	data.finalIndexNumber = (invoiceProforma.finalIndexNumber) ? (invoiceProforma.finalIndexNumber) : 0
    data.startingIndexNumber = (invoiceProforma.startingIndexNumber) ? (invoiceProforma.startingIndexNumber) : 0
    data.proformaDigits = (invoiceProforma.proformaDigits) ? (invoiceProforma.proformaDigits) : 0
  	data.invoiceDigits = (invoiceProforma.invoiceDigits) ? (invoiceProforma.invoiceDigits) : 0
  }
  return data
}

export { fetchProformaInfo }