import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isBoolean } from 'util';
import DateRange from './DateRange';
import RowItem from './RowItem';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  wide: {
    marginBottom: 30,
  },
  dateRanges: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 10,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '1.2rem',
  },
  blocks: {
    display: 'flex',
  },
  blockItems: {
    cursor: 'pointer',
    display: 'flex',
    padding: '16px 24px',
    fontSize: '1.3rem',
    background: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 20,
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 20px'
    }
  },
  selectContainer: {
    marginTop: '20px',
    width: '31.5%',
    [theme.breakpoints.down("xs")]: {
      width: '100%'
    }
  }
}))

const intersection = (arr1, arr2) => {
  const res = [];
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      continue;
    };
    res.push(arr1[i]);
  };
  return res;
};

const bulkSettings = [
  { key: 'amount', label: 'Rate', type: 'currency', description: `Update rate details in your property's currency` },
  { key: 'stopSell', label: 'Stop Sell', type: 'toggle', description: 'Instantly close bookings on all channels except for Counter' },
  { key: 'CTA', label: 'CTA', type: 'toggle', description: 'Closed to arrival. No check-ins allowed on this date' },
  { key: 'CTD', label: 'CTD', type: 'toggle', description: 'Closed to departure. No check-outs allowed to end on this date' },
  { key: 'minLOS', label: 'minLOS', type: 'days', description: 'Minimum length of stay - do not allow bookings lesser than this duration' },
  { key: 'maxLOS', label: 'maxLOS', type: 'days', description: 'Maximum length of stay - do not allow bookings longer than this duration' },
];

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    borderRadius: 10,
    boxShadow: '0 2px 30px #F2F2F2',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const BulkUpdateDetails = forwardRef((props, ref) => {
  const classes = useStyles();
  const [dateRangeArray, setDateRangeArray] = React.useState([{ startDate: null, endDate: null, days: [] }]);
  const [deleteIcon, setDeleteIcon] = React.useState(false);
  const [settings, setSettings] = React.useState({ amount: null });
  const [selectedRatePlanData, setSelectedRatePlanData] = React.useState({ data: [], id: '' });

  useEffect(() => {
    if (dateRangeArray.length > 1) setDeleteIcon(true)
  }, [dateRangeArray])

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      let dateRange = fetchDateRange();
      let amount = parseFloat(settings.amount);
      let minLOS = parseFloat(settings.minLOS);
      let maxLOS = parseFloat(settings.maxLOS);
      return {
        ...(dateRange.length && { dateRange: [...dateRange] }),
        ...(amount && typeof amount === 'number' && { amount }),
        ...(minLOS && typeof minLOS === 'number' && { minLOS }),
        ...(maxLOS && typeof maxLOS === 'number' && { maxLOS }),
        ...(typeof settings.stopSell === 'boolean' && { stopSell: settings.stopSell || false }),
        ...(typeof settings.CTA === 'boolean' && { CTA: settings.CTA }),
        ...(typeof settings.CTD === 'boolean' && { CTD: settings.CTD }),
        ...(selectedRatePlanData.id && { ratePlanID: selectedRatePlanData.id })
      }
    }
  }))

  const fetchDateRange = () => {
    return dateRangeArray.filter(range => range.startDate && range.endDate);
  }

  const handleAdd = () => {
    setDateRangeArray([...dateRangeArray, { startDate: null, endDate: null, days: [] }]);
  }

  const handleDelete = (rangeNumber) => {
    const array = [...dateRangeArray];
    array.splice(rangeNumber, 1);
    setDateRangeArray(array);
  }

  const handleStatusChange = (key, status, value = false) => {
    let settingObject = { ...settings }
    if (!status) {
      delete settingObject[key];
    } else {
      settingObject[key] = value;
    }
    setSettings({ ...settingObject });
  }
  const handleValueChange = (key, value) => {
    let settingObject = { ...settings }
    settingObject[key] = value;
    setSettings({ ...settingObject });
  }

  const handleUpdateDateData = (payload, indexValue) => {
    let array = [...dateRangeArray];
    array[indexValue] = payload;
    setDateRangeArray(array);
  }

  const onChangeHandler = data => {
    // if( data.value != 'nrr-derived (Hostelworld)') handleStatusChange('amount', true, null); 
    if (!data.isDerivedRate) handleStatusChange('amount', true, null);
    setSelectedRatePlanData({
      id: data.value,
      isDerivedRate: data.isDerivedRate,
    })
  }

  const getRatePlanOptions = () => {
    const { selectedRoomTypesIDs, rates, ratePlans } = props;
    let ratePlanList = Object.keys(ratePlans);
    let ratePlanOptions = [];

    selectedRoomTypesIDs.forEach(roomTypeItem => {
      // Check which rate plans have this room type ID
      let roomTypeRatePlanArray = []
      Object.keys(ratePlans).map(ratePlanItem => {
        const ratePlanData = ratePlans[ratePlanItem];
        if (ratePlanData?.isDeleted) return
        else if (ratePlanData.roomTypeIDs.indexOf(roomTypeItem) > -1) {
          roomTypeRatePlanArray.push(ratePlanItem);
        } else return;
      })

      // Push intersection of rate plan IDs
      ratePlanList = [...intersection(ratePlanList, roomTypeRatePlanArray)]

    })

    ratePlanList.map(ratePlanItem => {
      const ratePlanName = ratePlans[ratePlanItem].isDerived ?
        `${ratePlans[ratePlanItem].privateName} (derived)` :
        (!ratePlans[ratePlanItem].isDerived && !ratePlans[ratePlanItem].isDefault) ? `${ratePlans[ratePlanItem].privateName} (non-derived)` :
          `${ratePlans[ratePlanItem].privateName}`;
      ratePlanOptions.push({
        label: ratePlanName,
        value: ratePlans[ratePlanItem]._id,
        isDerivedRate: ratePlans[ratePlanItem].isDerived
      });

    });

    return [...ratePlanOptions];
  }

  const checkRatePlanConditions = item => {

    if (item.label == 'Rate') {

      //Rate of nrr-derived (Hostelworld) rateplan should not be editable...................
      const hasAmountProperty = settings.hasOwnProperty("amount");
      // const isHWDerivedRatePlan = selectedRatePlanData.id == 'nrr-derived (Hostelworld)';
      const isHWDerivedRatePlan = selectedRatePlanData.isDerivedRate;

      if (hasAmountProperty && isHWDerivedRatePlan) {
        handleStatusChange(item.key, false);
        return;
      }

      // if(!hasAmountProperty && !isHWDerivedRatePlan) handleStatusChange(item.key, true, null); 
    }

  }

  const ratePlanOptions = getRatePlanOptions();
  // Sorting rate Plan Options as order of Default,Non Derived,Derived
  ratePlanOptions.sort((a, b) => a.isDefault ? -1 : (a.isDerivedRate ? 1 : b.isDerivedRate ? -1 : 0))

  return (
    <div className={classes.root}>

      <div className={clsx(classes.row, classes.wide)}>
        <div className={classes.label}>Date Range</div>
        {dateRangeArray.map((item, index) => (
          <div key={index} className={classes.dateRanges}>
            <DateRange rangeNumber={index} handleDelete={handleDelete} handleUpdate={handleUpdateDateData} timezone={props.currentSpace.timezone} />
          </div>
        ))}

        <div className={classes.link} onClick={handleAdd}>Add another date range</div>

        <div className={classes.selectContainer}>
          <div className={classes.label}>Rate plan</div>
          <Select
            value={ratePlanOptions.find(option => selectedRatePlanData.id == option.value)}
            options={ratePlanOptions}
            onChange={onChangeHandler}
            isClearable={false}
            isSearchable={false}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
      </div>

      {bulkSettings.map(item => {

        checkRatePlanConditions(item);
        // if(selectedRatePlanData.id == 'nrr-derived (Hostelworld)' && item.label == 'Rate') return null;
        if (selectedRatePlanData.isDerivedRate && item.label == 'Rate') return null;

        return (
          <div className={classes.row} key={item.key}>
            <RowItem
              id={item.key}
              label={item.label}
              type={item.type}
              description={item.description}
              value={settings[item.key]}
              currentSpace={props.currentSpace}
              status={settings.hasOwnProperty(item.key)}
              handleStatusChange={handleStatusChange}
              handleValueChange={handleValueChange}
              ratePlan={props.ratePlans[selectedRatePlanData.id]}
            />
          </div>
        )
      })}

    </div>
  )
});

export default BulkUpdateDetails;