import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Pill from '../../common/Pill';

const useStyles = makeStyles(theme =>({
 roomTypeContainer:{
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%',
 }
}));

const RoomTypeSelect = props =>{
    const classes = useStyles();
    const { 
      roomTypes,
      currentSpace,
      ratePlan,
      handleUpdateRatePlan
    } = props;

    const handleClick = (roomType) => {
      const index = ratePlan.roomTypeIDs.indexOf(roomType);
      if (index > -1) {
        let array = [...ratePlan.roomTypeIDs]
        array.splice(index, 1);
        handleUpdateRatePlan('roomTypeIDs', [...array]);
      } else {
        handleUpdateRatePlan('roomTypeIDs', [...ratePlan.roomTypeIDs, roomType])
      }
    }

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Room Types</div>
                <div className="sub-title">Select room types that should be enabled for this rate plan</div>
                <div className="row">
                  <div className={classes.roomTypeContainer}>
                    {currentSpace && currentSpace.roomTypes && currentSpace.roomTypes.map(roomType =>{

                      if(!roomTypes?.[roomType]?.isActive) return null;

                      return (
                        <Pill 
                          key={roomType} 
                          name={roomTypes[roomType].name} 
                          handleClick={() => handleClick(roomType)} 
                          selected={ratePlan.roomTypeIDs.indexOf(roomType) > -1}
                          green={ratePlan.roomTypeIDs.indexOf(roomType) > -1} 
                          cancelButton={ratePlan.roomTypeIDs.indexOf(roomType) > -1}
                        />
                      )
                    })}
                  </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = state => ({
  roomTypes: state.roomTypes,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(RoomTypeSelect); 