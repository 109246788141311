import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import clsx from 'clsx';
import moment from 'moment';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { setStatusFilters, setDateFilters, setRoomTypeFilters } from '../../redux/actions/search/search';
import { RESERVATION_STATUS } from '../../utils/constants';

import Select from 'react-select';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles (theme => ({
  container: {
    margin: 20,
    width: 'calc(100% - 60px)'
  },
  filters: {
    color: '#666666',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  label: {
    fontSize: '1.3rem'
  },
  optionsContainer: {
    width: '100%'
  },
  options: {
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.4rem',
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
    },
    '& .SingleDatePicker': {
      width: '100%'
    },
    '& .SingleDatePickerInput__withBorder': {
      borderRadius: '5px',
      border: '1px solid #ECECEC',
      color: 'rgba(0, 0, 0, 0.87)',
      height: 38,
      width: 'calc(100% - 30px)'
    },
    '& input': {
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    },
    '& .DateInput_input': {
      color: 'currentColor',
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      padding: '5px 8px 5px 16px',
      width: '100%',
    },
    '& .DateInput_input__focused': {
      borderBottom: 'none'
    },
    '& .DateInput': {
      alignItems: 'center',
      borderRadius: '5px',
      display: 'flex',
      height: 38,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%',
    }
  },
  selectOptions: {
    border: '1px solid #ECECEC',
  },
  select: {
    border:'none'
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    minWidth: '120px',
    borderRadius: 5,
    border: 'none',
    boxShadow: 'none',
    // This line disable the blue border
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const roomTypes = [
  { value: 'private', label: 'Private' },
  { value: 'shared', label: 'Shared' }
]

const guestStatus = [
    { value: RESERVATION_STATUS.IN_HOUSE, label: 'In House' },
    { value: RESERVATION_STATUS.CHECKED_OUT, label: 'Checked out' },
    { value: RESERVATION_STATUS.NO_SHOW, label: 'No Show' },
    { value: RESERVATION_STATUS.CANCELLED, label: 'Cancelled' },
    { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: 'Online checked in' },
    { value: 'past', label: 'Past Booking' },
    { value: 'future', label: 'Future Booking' }
]

const SearchFilters = (props) => {
  const classes = useStyles();

  const { keyword, currentSpace, status, dateFilter, roomType } = props;
  const [date, setDate] = React.useState(null);
  const [focused, setFocused] = React.useState(null);

  const onDateChange = (date) => {
    if (date !== null) {
      props.dispatch(setDateFilters(currentSpace.propertyID, keyword, status, moment(date).format('YYYY-MM-DD'), roomType));
    } else {
      props.dispatch(setDateFilters(currentSpace.propertyID, keyword, status, '', roomType));
    }
    setDate(date);
  }

  const selectGuestStatus = selected => {
    if (selected !== null) {
      props.dispatch(setStatusFilters(currentSpace.propertyID, keyword, selected.value, dateFilter, roomType));
    } else {
      props.dispatch(setStatusFilters(currentSpace.propertyID, keyword, '', dateFilter, roomType));
    }
  }

  const selectRoomType = selected => {
    console.log(selected)
    if (selected !== null) {
      props.dispatch(setRoomTypeFilters(currentSpace.propertyID, keyword, status, dateFilter, selected.value));
    } else {
      props.dispatch(setRoomTypeFilters(currentSpace.propertyID, keyword, status, dateFilter, ''));
    }
  }

  return (
    <div className={classes.container}>
      
      <div className={classes.filters}>Filters</div>
      
      <div className={classes.optionsContainer}>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={4} md={4} lg={3}>
            <div className={classes.options}>
              <SingleDatePicker
                date={date}    
                onDateChange={onDateChange}
                focused={focused} 
                onFocusChange={({ focused }) => setFocused(focused)} 
                id="your_unique_id"
                numberOfMonths={1}
                showClearDate={true}
                isOutsideRange={() => false}
                displayFormat={'DD MMM YY'}
                hideKeyboardShortcutsPanel
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={3}>
            <div className={clsx(classes.options, classes.selectOptions)}>
              <Select 
                styles={selectStyle}
                options={guestStatus}
                isClearable={true}
                onChange={selectGuestStatus}
                placeholder={"Status"}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={3}>
            <div className={clsx(classes.options, classes.selectOptions)}>
              <Select 
                styles={selectStyle}
                options={roomTypes}
                isClearable={true}
                onChange={selectRoomType}
                placeholder={"Room Category"}
              />
            </div>
          </Grid>

        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  keyword: state.search.keyword,
  status: state.search.status,
  dateFilter: state.search.date,
  roomType: state.search.roomType
})

export default connect(mapStateToProps)(SearchFilters);