import http from "../../utils/http";
import { normalize } from 'normalizr';
import { roomTypeSchema, resSchema } from '../../schema/calendarSchema';
import { setCalendarColor } from '../UIsettings/userSettings';

//----------------------------------------------------------------
// LOAD CALENDAR SNAPSHOT
//----------------------------------------------------------------
export function getCalendarSnapshot(spaceID, propID) {
  const ACTION_NAME = "LOAD_CALENDAR_SNAPSHOT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/calendar/snapshot/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log('calendar snapshot', spaceID, propID, successResult.data)
        let calendarSnapshotArray = []
        data.map(item => {
          var normalizedOrder = normalize(item.accommodation, [ resSchema ] );
          // console.log(item.bedID, normalizedOrder);
          calendarSnapshotArray.push({
            bedID: item.bedID,
            reservationKeys: normalizedOrder.result,
            reservations: normalizedOrder.entities.reservations
          })
        })
        // console.log('dispatch calendar snapshot', calendarSnapshotArray)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {spaceID, calendarSnapshot: calendarSnapshotArray} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CALENDAR SORT 
//---------------------------------------------------------------
export function fetchCalendarSortData(propertyID, type){
  const ACTION_NAME = "FETCH_CALENDAR_SORT_DATA";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/calendar/filter/${propertyID}/${type}`)
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return successResult.data;
      })
      .catch(errorResult => {
        console.log("errorResult", errorResult.response);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  }
};

export const updateCalenderSortOrder = (params) => {
  const ACTION_NAME = "UPDATE_CALENDER_SORT";
  return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http.patch(`/api/frontdesk/calendar/filter`, params)
          .then(successResult => {
              dispatch({ type: `${ACTION_NAME}_SUCCESS` });
              return true;
          })
          .catch(errorResult => {
              console.log("errorResult", errorResult.response);
              let error =
                errorResult && errorResult.response && errorResult.response.data
                  ? errorResult.response.data
                  : errorResult;
              dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
              return;
          });
  }
}

export const getCalendarRenderData = (propertyID, type) =>{
  const ACTION_NAME = "GET_CALENDAR_RENDER_DATA";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/calendar/filter/${propertyID}/${type}`)
      .then(successResult => {
        const { data } = successResult;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: [...data.filters]
        });
        return true;
      })
      .catch(errorResult => {
        console.log("errorResult", errorResult.response);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  }
}

export const setCalendarSortType = (spaceID, uiSetting) =>({
  type: 'EDIT_SPACE_SUCCESS',
  payload: {
    _id: spaceID,
    uiSetting
  }
})

export const setCalendarDisplayData = payload =>({
  type: 'GET_CALENDAR_RENDER_DATA_SUCCESS',
  payload
})

export const resetError = (action) => {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

export const resetCalendarDisplay = () =>({
  type: 'RESET_CALENDER_DISPLAY'
});


export const saveCalendarColorSetting = data =>{
  const ACTION_NAME = "SAVE_CALENDAR_COLOR_SETTING";
  return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http.post(`/api/UIsettings/user`, data)
          .then(successResult => {

            const configData = { 
              spaceID: data.spaceID,
              colorConfig: data.calendarColour
            }

            dispatch(setCalendarColor(configData));

            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return true;
          })
          .catch(errorResult => {
              console.log("errorResult", errorResult.response);
              let error =
                errorResult && errorResult.response && errorResult.response.data
                  ? errorResult.response.data
                  : errorResult;
              dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
              return;
          });
  }
}