import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import {
  inviteSpaceUser,
} from "../../redux/actions/auth/invite";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import { loadOnlySpace } from "../../redux/actions/dashboard/dashboard";
import { validateEmail, validateTextForHTMLInjection } from "../../utils/validators";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "scroll",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    minHeight: "calc(100vh - 250px)",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginTop: 15,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "calc(80% - 32px)",
  },
  radioRow: {
    alignItems: "flex-start",
    marginBottom: 10,
    marginTop: 10,
  },
  titleRow: {
    alignItems: "center",
    display: "flex",
  },
  pill: {
    background: "#E0E0E0",
    borderRadius: "25px",
    color: "#666666",
    fontSize: "1.0rem",
    marginLeft: 15,
    padding: "5px 8px",
  },
  description: {
    color: "#666666",
    fontSize: "1.2rem",
    marginBottom: 10,
  },
  select: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 10,
    marginTop: 10,
    width: "80%",
  },
  noLink: {
    marginTop: 15,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  link: {
    color: "#4F9581",
    fontSize: "1.3rem",
    fontWeight: 600,
    wordBreak: "break-all",
    width: "80%",
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: 100,
    width: "100%",
  },
  error: {
    color: "red",
    fontSize: "1.2rem",
    marginTop: 5,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  button: {
    background: "#000000",
    borderRadius: 5,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const userTypesArray = [
  { value: "member", label: "Member", description: "" },
  { value: "admin", label: "Admin", description: "" },
];

const InviteUser = (props) => {
  const classes = useStyles();
  const {
    currentSpace,
    currentUser,
    currentSpaceID,
    t,
  } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [error, setError] = React.useState({ name: false, email: false });
  const admin = currentSpace && currentSpace.admins.includes(currentUser._id);

  // If admin, invite admin. If member, invite member
  const [form, setForm] = React.useState({
    permissions: userTypesArray[0].value,
    role: admin ? userTypesArray[1].value : userTypesArray[0].value,
  });

  const handleOnChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (error[e.target.name]) {
      setError({ ...error, [e.target.name]: false });
    }
  };

  const handleSelect = (selected) => {
    setForm({ ...form, role: selected.target.value });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleValidation = () => {
    let foundError = false;
    let errorStep = {};
    if (
      !form.name || validateTextForHTMLInjection(form.name)
    ) {
      errorStep.name = true;
      foundError = true;
    }
    if (
      !form.email || !validateEmail(form.email)
    ) {
      errorStep.email = true;
      foundError = true;
    }
    if (foundError) {
      setError({ ...error, ...errorStep });
      return false;
    }
    
    return true;
  };

  const handleSubmitUpdates = () => {
    const check = handleValidation();
    if (check) {
      const response = props.dispatch(inviteSpaceUser(currentSpaceID, form));

      response.then((success) => {
        if (success) props.dispatch(loadOnlySpace(currentSpaceID));
      });

      props.handleClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t("members.newMember")}</div>
          <CloseIcon
            className={classes.closeIcon}
            onClick={props.handleClose}
          />
        </div>

        <div className={classes.listSection}>
          {/* --------------------------------------------------------------------- */}
          {/* USER NAME */}
          {/* --------------------------------------------------------------------- */}
          <div className={classes.row}>
            <div className={classes.title}>{t("members.name")}</div>
            <div
              className={clsx(classes.field, classes.wide)}
              style={{
                border: error.name ? "1px solid red" : "1px solid #ffffff",
              }}
            >
              <InputBase
                id="name"
                name="name"
                fullWidth
                type="text"
                onChange={handleOnChange}
              />
            </div>
            {error.name && (
              <div className={classes.error}>{t("members.nameError")}</div>
            )}
          </div>

          {/* --------------------------------------------------------------------- */}
          {/* EMAIL */}
          {/* --------------------------------------------------------------------- */}
          <div className={classes.row}>
            <div className={classes.title}>{t("members.email")}</div>
            <div
              className={clsx(classes.field, classes.wide)}
              style={{
                border: error.email ? "1px solid red" : "1px solid #ffffff",
              }}
            >
              <InputBase
                id="email"
                name="email"
                fullWidth
                type="text"
                onChange={handleOnChange}
              />
            </div>
            {error.email && (
              <div className={classes.error}>{t("members.emailError")}</div>
            )}
          </div>

          {/* --------------------------------------------------------------------- */}
          {/* ROLE */}
          {/* --------------------------------------------------------------------- */}
          {admin && (
            <div className={classes.row}>
              <div className={classes.title}>{t("members.role")}</div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="role"
                  name="role"
                  value={form.role}
                  onChange={handleSelect}
                >
                  <FormControlLabel
                    value={userTypesArray[0].value}
                    control={<Radio />}
                    className={classes.radioRow}
                    disabled={
                      !admin ||
                      (currentSpace.onboardingStatus !== "pro" &&
                        currentSpace.onboardingStatus !== "plus")
                    }
                    label={
                      <>
                        <div className={classes.titleRow}>
                          <div>Member</div>
                          {currentSpace.onboardingStatus !== "pro" &&
                            currentSpace.onboardingStatus !== "plus" && (
                              <div className={classes.pill}>Upgrade</div>
                            )}
                        </div>
                        <div className={classes.description}>
                          Access to reservations and calendar. Possible to set
                          custom access.
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    value={userTypesArray[1].value}
                    control={<Radio />}
                    className={classes.radioRow}
                    label={
                      <>
                        <div className={classes.titleRow}>
                          <div>Admin</div>
                        </div>
                        <div className={classes.description}>
                          Account user with full access
                        </div>
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}


          {/* --------------------------------------------------------------------- */}
          {/* SEND INVITE */}
          {/* --------------------------------------------------------------------- */}
          <div className={classes.buttonsContainer}>
            <div className={classes.button} onClick={handleSubmitUpdates}>
              {t("members.sendInvite")}
            </div>
          </div>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message="Link copied to clipboard"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  currentSpaceID: state.dashboard.currentSpace
});

export default withTranslation()(connect(mapStateToProps)(InviteUser));
