import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";
import {
  getBookingEngineSettings,
  saveBookingEngineSettings,
} from "../../redux/actions/bookingEngine/bookingEngine";
import TermsConditions from "./TermsConditions";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import IMAGES from "../../constants/images";
import { BOOKING_ENGINE_URI } from "../../config";

const useStyles = makeStyles((theme) => ({
  booking: {
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&:hover": {
      background: "#eeeeee",
    },
  },
  imageContainer: {
    alignItems: "center",
    height: 100,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      height: 50,
      width: 50,
    },
  },
  bookingHeader: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  email: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "& img": {
      height: 50,
      width: 50,
    },
    "&:hover": {
      background: "#eeeeee",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 600,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  listSection: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  configSection: {
    marginTop: 50,
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  noLink: {
    marginTop: 15,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  link: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontSize: "1.3rem",
      fontWeight: 600,
    },
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  preview: {
    borderRadius: 3,
    boxShadow: "0 1px 20px #F2F2F2",
    height: "auto",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  checkboxContainer: {
    alignItems: "center",
    marginBottom: "5px",
    "& .checkbox": {
      textAlign: "end",
    },
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: "#4F9581",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#4F9581",
    },
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "1.2rem",
    marginTop: "5px",
  },
  iconRow: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 25,
  },
  tabInfo: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    textAlign: "center",
    background: "#F8F9FB",
    borderRadius: "15px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    height: "100px",
    justifyContent: "center",
    marginRight: 25,
    width: "100px",
    paddingRight: 5,
    paddingLeft: 5,
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  tabSelected: {
    background: "#E0E0E0",
    color: "#333333",
    fontWeight: 600,
  },
}));

const mash = (value) => {
  return value.replace(/\s+/g, "-").toLowerCase();
};

const baseURI = BOOKING_ENGINE_URI;

const BookingEngine = (props) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { t } = props;
  const {
    open,
    handleClose,
    currentSpace,
    property,
    bookingEngine,
    GA,
    FB,
    loadingProperty,
    errors,
    isLoading,
    stripe,
  } = props;
  const [edit, setEdit] = React.useState(false);
  const [initial, setInitial] = React.useState(true);
  const [form, setForm] = React.useState({
    bookingEngineURL: "",
    googleAnalyticsID: "",
    fbPixelID: "",
    collectCard: false,
    collectPayment: false,
    validatePhone: false,
  });

  const [toggle, setToggle] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("settings");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const initSettings = () => {
    const response = props.dispatch(
      getBookingEngineSettings(currentSpace.propertyID)
    );
    response.then((data) => {
      if (data) {
        setForm({
          ...form,
          collectCard: data.collectCard,
          collectPayment: data.collectPayment,
          validatePhone: data.meta?.validatePhone || false,
        });
      }
    });
  };

  const initFeatures = () => {
    setInitial(false);
    let ocFeature = _.find(property.features, { name: "Booking Engine" });
    if (ocFeature && ocFeature.isEnabled == "true") setToggle(true);

    setForm({
      ...form,
      bookingEngineURL: bookingEngine || "",
      googleAnalyticsID: GA || "",
      fbPixelID: FB || "",
    });
  };

  useEffect(() => {
    if (property.default)
      props.dispatch(fetchProperty(currentSpace.propertyID));
    initSettings();
  }, []);

  if (initial && property.features) initFeatures();

  // useEffect(() =>{
  //   if(property.features) initFeatures();
  // }, [property]);

  // if(initial) {
  //     if(property.features) {
  //       setInitial(false)
  //       let ocFeature = _.find(property.features, { name: "Booking Engine" });
  //       if(ocFeature && (ocFeature.isEnabled == "true"))
  //         setToggle(true);

  //       setForm({
  //         ...form,
  //         bookingEngineURL: bookingEngine || '',
  //         googleAnalyticsID: GA || '',
  //         fbPixelID: FB || ''
  //       });
  //     }
  // }

  const handleSwitchOn = () => {
    setEdit(false);
    setToggle(!toggle);
    setForm({ ...form, bookingEngineURL: mash(currentSpace.name) });
    props.dispatch(
      editProperty(currentSpace.propertyID, { appFeature: "Booking Engine" })
    );
  };

  const handleSwitchOff = () => {
    setEdit(false);
    setToggle(!toggle);
    setForm({ bookingEngineURL: "", googleAnalyticsID: "", fbPixelID: "" });
    props.dispatch(
      editProperty(currentSpace.propertyID, {
        disableAppFeature: "Booking Engine",
      })
    );
  };

  const handleChange = (e) => {
    setEdit(true);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${baseURI}/booking/${bookingEngine}`);
    setOpenSnackbar(true);
  };

  const handleSave = () => {
    // if (form.bookingEngineURL!=='') {
    //   const data = {
    //     ...form,
    //     appFeature: 'Booking Engine'
    //   }
    //   props.dispatch(editProperty(currentSpace.propertyID, data));
    // } else if (form.bookingEngineURL==='') {
    //   props.dispatch(editProperty(currentSpace.propertyID, { disableAppFeature: 'Booking Engine' }));
    // }

    const featureParams = {
      bookingEngineURL: form.bookingEngineURL,
      googleAnalyticsID: form.googleAnalyticsID,
      fbPixelID: form.fbPixelID,
    };

    let data =
      form.bookingEngineURL !== ""
        ? { ...featureParams, appFeature: "Booking Engine" }
        : { disableAppFeature: "Booking Engine" };

    props.dispatch(editProperty(currentSpace.propertyID, data));

    const settingsData = {
      collectCard: form.collectCard,
      collectPayment: form.collectPayment,
      meta: {
        validatePhone: form.validatePhone
      },
    };

    props.dispatch(
      saveBookingEngineSettings(currentSpace.propertyID, settingsData)
    );
    // setForm({bookingEngineURL: '', googleAnalyticsID: '', fbPixelID: ''});
    setEdit(false);
  };

  const handleCloseError = () => {
    props.dispatch(resetError("EDIT_PROPERTY"));
  };

  const checkBoxHandler = (event) => {
    const isChecked = event.target.checked;
    const name = event.target.name;

    setEdit(true);

    if (name == "collectCard") {
      setForm({ ...form, collectCard: isChecked, collectPayment: false });
      return;
    } else if (name === 'validatePhone') {
      setForm({ ...form, validatePhone: isChecked });
      return;
    }

    setForm({ ...form, collectPayment: isChecked });
  };

  const getErrorMsg = () => {
    if (stripe.activated && stripe.action_required)
      return "Stripe payments are currently inactive for your account.";

    if (!stripe.activated)
      return "Stripe account has not been set-up to recieve payments.";
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Booking Engine</div>
          <AccessHeaderAction moduleID={MODULE.BOOKING_ENGINE.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {(loadingProperty || isLoading) && (
          <div className={classes.loadingState}>
            <CircularProgress />
          </div>
        )}

        {!loadingProperty && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              <div className={classes.firstRow}>
                <div>
                  <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                </div>
                <div className={classes.helperText}>
                  {t("automate.bookingEngine.definition")}
                </div>
              </div>

              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <div className={classes.title}>Booking Engine</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx(classes.toggleButton, classes.left)}
                        style={{
                          background: toggle === false ? "#F2F2F2" : "#FFFFFF",
                        }}
                        onClick={handleSwitchOff}
                      >
                        OFF
                      </div>
                      <div
                        className={clsx({
                          [classes.toggleButton]: true,
                          [classes.right]: true,
                          [classes.selected]: toggle === true,
                        })}
                        onClick={handleSwitchOn}
                      >
                        ON
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {toggle && (
                <div className={clsx(classes.iconRow, classes.tabInfo)}>
                  <div
                    id="settings"
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: selectedTab === "settings",
                    })}
                    onClick={() => handleTabSelect("settings")}
                  >
                    Settings
                  </div>
                  <div
                    id="roomType"
                    className={clsx({
                      [classes.tab]: true,
                      [classes.tabSelected]: selectedTab === "t&c",
                    })}
                    onClick={() => handleTabSelect("t&c")}
                  >
                    T&C
                  </div>
                </div>
              )}

              {toggle && selectedTab === "settings" && (
                <div className={classes.configSection}>
                  <div className={classes.row}>
                    <div className={classes.title}>
                      {t("automate.bookingEngine.nameBookingEngine")}
                    </div>
                    <div className={classes.label}>
                      {t("automate.bookingEngine.nameBookingEngineHelp")}
                    </div>
                    <div className={clsx(classes.field, classes.wide)}>
                      <InputBase
                        id="bookingEngineURL"
                        name="bookingEngineURL"
                        defaultValue={form.bookingEngineURL}
                        fullWidth
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className={clsx(classes.row, classes.preAuth)}>
                    <div className={classes.title}>
                      {t("automate.bookingEngine.viewBookingEngine")}
                    </div>
                    {!bookingEngine && (
                      <div className={classes.noLink}>
                        {t("automate.bookingEngine.saveBookingEngine")}
                      </div>
                    )}
                    {bookingEngine && (
                      <div className={classes.linkContainer}>
                        <div className={classes.link}>
                          <a
                            href={`${baseURI}/booking/${bookingEngine}`}
                            target="_blank"
                          >
                            {`${baseURI}/booking/${bookingEngine}`}
                          </a>
                        </div>
                        <div
                          className={classes.copyButton}
                          onClick={() => copyToClipboard()}
                        >
                          copy
                        </div>
                      </div>
                    )}
                    {bookingEngine && (
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            className={classes.preview}
                            src="/images/booking-engine-1.png"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            className={classes.preview}
                            src="/images/booking-engine-2.png"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            className={classes.preview}
                            src="/images/booking-engine-3.png"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            className={classes.preview}
                            src="/images/booking-engine-4.png"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            className={classes.preview}
                            src="/images/booking-engine-5.png"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </div>

                  <div className={clsx(classes.row, classes.options)}></div>

                  <div className={classes.row}>
                    <div className={classes.title}>
                      {t("automate.bookingEngine.googleAnalyticsID")}
                    </div>
                    <div className={classes.field}>
                      <InputBase
                        id="googleAnalyticsID"
                        name="googleAnalyticsID"
                        defaultValue={form.googleAnalyticsID || ""}
                        fullWidth
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className={classes.row}>
                    <div className={classes.title}>
                      {t("automate.bookingEngine.facebookPixelID")}
                    </div>
                    <div className={classes.field}>
                      <InputBase
                        id="fbPixelID"
                        name="fbPixelID"
                        defaultValue={form.fbPixelID || ""}
                        fullWidth
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <Grid container className={classes.checkboxContainer}>
                    <Grid item xs={7}>
                      <div className={classes.title}>
                        Validate Phone Number
                      </div>
                    </Grid>
                    <Grid item xs={5} className="checkbox">
                      <Checkbox
                        color="primary"
                        checked={form.validatePhone}
                        checkedIcon={<span className={classes.checkedIcon} />}
                        onChange={checkBoxHandler}
                        inputProps={{
                          name: "validatePhone",
                          "aria-label": "secondary checkbox",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container className={classes.checkboxContainer}>
                    <Grid item xs={7}>
                      <div className={classes.title}>
                        Collect Customer Card Information
                      </div>
                    </Grid>
                    <Grid item xs={5} className="checkbox">
                      <Checkbox
                        color="primary"
                        checked={form.collectCard}
                        checkedIcon={<span className={classes.checkedIcon} />}
                        onChange={checkBoxHandler}
                        inputProps={{
                          name: "collectCard",
                          "aria-label": "secondary checkbox",
                        }}
                      />
                    </Grid>
                  </Grid>

                  {form.collectCard && (
                    <Grid container className={classes.checkboxContainer}>
                      <Grid item xs={7}>
                        <div className={classes.title}>
                          Charge guest to complete booking
                        </div>
                      </Grid>
                      <Grid item xs={5} className="checkbox">
                        <Checkbox
                          color="primary"
                          checked={form.collectPayment}
                          disabled={!stripe.activated || stripe.action_required}
                          checkedIcon={<span className={classes.checkedIcon} />}
                          onChange={checkBoxHandler}
                          inputProps={{
                            name: "collectPayment",
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      </Grid>

                      {form.collectCard && (
                        <Grid item xs={12}>
                          <p className={classes.errorMessage}>
                            {getErrorMsg()}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </div>
              )}

              {edit && (
                <div className={classes.buttonsContainer}>
                  <div className={classes.button} onClick={handleSave}>
                    Save
                  </div>
                </div>
              )}

              {toggle && selectedTab === "t&c" && (
                <div>
                  <TermsConditions />
                </div>
              )}
            </div>
          </div>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key="top-center"
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message="Link copied to clipboard"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => handleCloseError()}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  bookingEngine: state.property.bookingEngineURL,
  stripe: state.stripe,
  GA: state.property.googleAnalyticsID,
  FB: state.property.fbPixelID,
  loadingProperty: state.loading.LOAD_PROPERTY,
  isLoading:
    state.loading.EDIT_PROPERTY ||
    state.loading.GET_BOOKING_ENGINE_SETTINGS ||
    state.loading.SAVE_BOOKING_ENGINE_SETTINGS,
  errors: state.errors.EDIT_PROPERTY,
});

export default withTranslation()(connect(mapStateToProps)(BookingEngine));
