export const getChannelRouteInfo = (pathname) =>{
    const routePath = pathname.split('/');
    const pathLength = routePath?.length || 0;
    const tabPathIndex = pathLength == 4 ? pathLength - 1 : pathLength - 2;
    const tabPath = routePath?.[tabPathIndex] || null;

    const modalPath = pathLength == 5 ? routePath?.[pathLength - 1] : null;

    const basePath = '/beds/channels';

    return { tabPath, modalPath, basePath }
}

export const setModalRoute = (status, history, routeData) =>{
    const { tabPath, modalPath, basePath } = routeData;

    if(!status){
        history.replace(`${basePath}/${tabPath}/`);
        window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
        return;
    }

    history.replace(`${basePath}/${tabPath}/${modalPath}`);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
}