import React, {useState} from 'react';
import 'react-dates/initialize';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { addTodo } from "../../redux/actions/todos/todos";
import { returnUsers, returnCurrent } from "../../redux/selectors/dashboard"; 

import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const styles = theme => ({
  newTodoButton: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10
  },
  addNewTodo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3)
  },
  newTodoCheck: {
    maxWidth: 35
  },
  newTodoDesc: {
    fontWeight: 600,
    width: "calc(100% - 35px)"
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(1)
  },
  cancelButton: {
    marginRight: 10
  },
  todoContainer: {
    alignItems: "center",
    borderBottom: '1px solid #eeeeee',
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '30%'
  },
  rightAlign : {
    paddingLeft: 15,
    width: '70%'
  },
  todoInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: "row",
    paddingLeft: 40,
    marginTop: 20,
    marginBottom: 20
  },
  todoInfoTitle: {
    color: "#444444",
    minWidth: "100px",
    textAlign: 'right',
    marginRight: 20
  },
  notesBox: {
    color: '#666666',
    cursor: 'pointer'
  },
  notes: {
    fontWeight: 600
  },
  select: {
    color: '#999999',
    cursor: 'pointer',
    fontWeight: 600
  },
  noteDetails: {
    fontSize: '1.3rem',
    fontWeight: 500,
    lineHeight: '1.4'
  }
});

class NewTodo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      createTodo: false,
      addNotes: false,
      title: '',
      assignedTo: [],
      date: null,
      focused: false,
      assigned: false,
      notes: ''
    }
  }

  handleInputChange = e => {
    this.setState({ title: e.target.value})
  }

  handleAssigned = user => {
    user = user || [];
    let assignedTo = user.map(data => data.value)
    this.setState({ assignedTo })
  }

  handleNewTodo = () => {
    // console.log('todo submitted', this.props.categoryID, this.state.title, this.state.assignedTo, this.state.notes);
    // console.log(this.state.date);
    this.props.dispatch(addTodo(
      this.props.categoryID, 
      {
        title: this.state.title, 
        status: false,
        assignedTo: this.state.assignedTo,
        body: this.state.notes,
        dueDate: moment(this.state.date).valueOf().toString()
      }
    ));

    this.setState({ 
      createTodo: false,
      title: '',
      assignedTo: [],
      date: null,
      focused: false,
      assigned: false,
      addNotes: false,
      notes: ''
    });
  }

  render (){

    const {classes, t} = this.props;

    return (
      <div>
        <Button 
          size="small" 
          variant="outlined" 
          onClick={() => this.setState({ createTodo: true })} 
          className={classes.newTodoButton}
          style={{ display: this.state.createTodo === true ? "none": ""}}
        >
          {t('todos.addTask')}
        </Button>

        {/* ------------------------------------------------------------ */}
        {/* ADD NEW TODO FORM */}
        {/* ------------------------------------------------------------ */}
        <div style={{ display: this.state.createTodo === false ? "none": ""}}>
          
          {/* Title */}
          {/* ------------------------------------------------------------ */}
          <div className={classes.addNewTodo}>
            <Checkbox
              checked={false}
              value={false}
              color="primary"
              className={classes.newTodoCheck}
            /> <Input
              placeholder={t('todos.describeItem')}
              className={classes.newTodoDesc}
              id="title"
              type="text"
              autoFocus
              style={{ fontSize: 14 }}
              onChange={this.handleInputChange}
              value={this.state.title}
            />
          </div>

          {/* Assigned to */}
          {/* ------------------------------------------------------------ */}
          <div className={classes.todoContainer}>
            <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.assignedTo')}:</div>
            <div className={`${classes.rightAlign} ${classes.notesDesc}`}>
              {!this.state.assigned && <span className={classes.select} onClick={() => this.setState({assigned: true})}>{t('todos.typeNames')}..</span>}
              {this.state.assigned && (
                <Select
                  options={this.props.suggestions}
                  isClearable={true}
                  onChange={(e) =>this.handleAssigned(e)}
                  isMulti
                  placeholder={t('todos.typeNames')}
                />
              )}
            </div>
          </div>

          {/* Due on */}
          {/* ------------------------------------------------------------ */}
          <div className={classes.todoContainer}>
            <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.dueOn')}:</div>
            <div className={`${classes.rightAlign} ${classes.notesDesc}`}>
              <SingleDatePicker
                date={this.state.date} // momentPropTypes.momentObj or null
                onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                id="todo_date" // PropTypes.string.isRequired,
                showClearDate={true}
                placeholder={t('todos.selectDate')}
                numberOfMonths={1} 
                noBorder={true}
                displayFormat={'DD MMM YY'}
              />
            </div>
          </div>

          {/* Notes */}
          {/* ------------------------------------------------------------ */}
          <div className={classes.todoContainer}>
            <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.notes')}:</div>
            <div className={`${classes.rightAlign} ${classes.notesDesc}`}>
              {!this.state.addNotes && <span onClick={() => this.setState({ addNotes: true})} className={classes.select}>{t('todos.addExtraDetails')}</span>}
              {this.state.addNotes && (
                <div className={classes.comment}>
                  <TextField
                    id="outlined-full-width"
                    fullWidth
                    multiline
                    margin="none"
                    variant="outlined"
                    value={this.state.noteDetails}
                    onChange={(e) => this.setState({notes: e.target.value})}
                    className={classes.noteDetails}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div style={{ display: this.state.addNotes === false ? "none": ""}} className={classes.editor} >
            <Editor />
          </div> */}

          {/* Submit Buttons */}
          {/* ------------------------------------------------------------ */}
          <div className={classes.submit}>
            <Button 
              size="small" 
              variant="outlined" 
              className={classes.cancelButton} 
              onClick={() => this.setState({
                createTodo: false, 
                addNotes: false
              })}
            >
              {t('actions.cancel')}
            </Button>
            <Button size="small" variant="outlined" className={classes.button} onClick={this.handleNewTodo}>{t('actions.create')}</Button>
          </div>

        </div>
      </div>
    )
  }
}

NewTodo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  let suggestionMemberList = returnUsers(state.users, returnCurrent(state.spaces, state.dashboard.currentSpace).members);
  let suggestionAdminList = returnUsers(state.users, returnCurrent(state.spaces, state.dashboard.currentSpace).admins);
  let suggestionList = [ ...suggestionMemberList, ...suggestionAdminList ];

  let suggestions = suggestionList.map(member => {
    return { value: member._id, label: member.name }
  })
  return {
    suggestions: suggestions,
    currentSpaceID: state.dashboard.currentSpace
  }
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NewTodo)));