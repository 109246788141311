
// Return
const returnCurrent = (list, current) => {
  if (list && current) {
    return list[current]
  }
  return null
}
// Return 
const folderList = (folders, current) => {
  if (folders && current) {
    // console.log('here ',current ,folders[current] )
    return folders[current]
  }
  return null
}

/// Return Suggestions
const returnUsers = (users, members, admins) => {
  var suggestions = [];
  if (users) {
    members && members.map(member => {
      suggestions.push(users[member])
    })
    admins && admins.map(admin => {
      suggestions.push(users[admin])
    })
    return suggestions;
  }
}

/// Return Suggestions excluding current user within current space
const returnOtherUsers = (users, spaces, currentSpace, currentUserID) => {
  if (currentSpace!=='community' && currentSpace) {
    let members = spaces[currentSpace] && spaces[currentSpace].members;
    let admins = spaces[currentSpace] && spaces[currentSpace].admins;
    var suggestions = [];
    if (users) {
      members && members.map(member => {
        member !== currentUserID ? suggestions.push(users[member]) : false
      })
      admins && admins.map(admin => {
        admin !== currentUserID ? suggestions.push(users[admin]) : false
      })
      return suggestions;
    }
  } else return []
}

/// Return ALL Suggestions excluding current user
const returnAllOtherUsers = (users, currentUserID) => {
  var suggestions = [];
  if (users) {
    Object.values(users).map(user => {
      user._id===currentUserID ? false : suggestions.push({label: user.name, value: user._id})
    })
    return suggestions;
  }
}

export {returnCurrent, folderList, returnUsers, returnOtherUsers, returnAllOtherUsers};