import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InputBase, Tooltip } from '@material-ui/core';
import IMAGES from '../../../constants/images';
import { content_languages } from '../helper';

const useStyles = makeStyles(theme => ({
    field: {
        alignItems: 'flex-start',
        background: '#ffffff',
        borderRadius: '5px',
        boxShadow: '0 1px 10px #E0E0E0',
        display: 'flex',
        flexWrap: 'wrap',
        height: 150,
        marginTop: 10,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '80%',
        '&.disabled': {
            background: '#E2E2E2'
        }
    },
    langContainer: {
        marginTop: 10,
        padding: 10,
        boxShadow: '0 1px 10px #e0e0e0',
        borderRadius: 5,
        fontSize: '1.2rem',
        lineHeight: '20px',
        width: 'calc(80% + 12px)'
    },
    subTitle: {
        fontWeight: 600
    },
    link: {
        color: '#006699',
        cursor: 'pointer',
        fontSize: '1.1rem',
        fontWeight: 600,
    }
}));

const MicrositeDescription = (props) => {
    const classes = useStyles()
    const { label, tooltipText, keyName, value, disabled, language, missingLanguages } = props;

    return (
        <div className='row'>
            <div className='label'>
                {label} {language && ` (${content_languages.find(lang => lang.value == language)?.label})`}
                <Tooltip title={tooltipText}>
                    <img className='info-icon' src={IMAGES.ICONS.question} />
                </Tooltip>
            </div>

            <div className={`${classes.field} ${disabled ? 'disabled' : ''}`}>
                <InputBase
                    id={keyName}
                    name={keyName}
                    value={value}
                    fullWidth
                    disabled={disabled}
                    type='text'
                    autoFocus
                    multiline
                    rows={8}
                    onChange={(e) => props.onChange(e.target.value)}
                />
            </div>

            <div className={classes.langContainer}>
                <span className={classes.subTitle}>Missing languages</span><span> | </span>
                {missingLanguages.map(lang => (
                    <React.Fragment key={lang.value}>
                        <span onClick={() => props.updateLanguage(lang.value)} className={classes.link}>{lang.label}</span><span> | </span>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default MicrositeDescription;