import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { createRoomType } from '../../../../redux/actions/rooms/roomTypes';

import Pill from '../../../common/Pill';
import NewRoomTypeForm from './NewRoomTypeForm';
import { DATA_ACTION, inventoryConditionHandler } from '../../../../utils/helper';

import IMAGES from '../../../../constants/images';
import { ROOM_TYPE_VALIDITY_IN_YEARS } from '../../../../config';

const useStyles = makeStyles(theme => ({
	roomTypeContainer: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		width: '80%',
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontSize: '1.2rem',
		marginBottom: 20,
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 600,
		marginBottom: 5,
		marginTop: 10,
	},
	details: {
		color: '#666666',
		fontSize: '1.3rem',
		fontWeight: 500,
		marginBottom: 3,
		width: '60%',
	},
	infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(80% - 60px)',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(60% - 60px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px)',
    }
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20,
  },
}));

function selectDistinct(array) {
  const seenIt = new Set();
  const distinct = [];

  for (let i = 0; i < array.length; i++) {
      const value = array[i].roomTypeID;

      if (!seenIt.has(value)) {
          seenIt.add(value);
          distinct.push(value);
      }
  }
  
  return distinct; 
  /* you could also drop the 'distinct' array and return 'Array.from(seenIt)', which converts the set object to an array */
}

const checkActiveMapping = (room, roomOriginal = {}) => {
	if (!room._id) return true;
	if (room._id && !roomOriginal?.primaryRoomTypeID) return true;
	return false;
}

const RoomTypeSelect = props => {
	const classes = useStyles();
	const {
		roomTypes,
		currentSpace,
		room,
		rooms,
		handleUpdateRoom,
		handleUpdateMultipleRoom,
		t
	} = props;
	const [newRoomCategory, setNewRoomCategory] = React.useState(false);

	const displayRoomTypeList = checkActiveMapping(room, rooms[room._id]);

	const handleClick = (roomType) => {
		if (room.primaryRoomTypeID == roomType) {
			handleUpdateMultipleRoom({'primaryRoomTypeID': null, 'mapping': null});
		} else {
			handleUpdateMultipleRoom({
				'primaryRoomTypeID': roomType, 
				'mapping': [{
					roomTypeID: roomType, 
					startDate: moment().format('YYYY-MM-DD'), 
					endDate: moment().add(ROOM_TYPE_VALIDITY_IN_YEARS, "years").format("YYYY-MM-DD")
				}]
			});
		}
	}

	const handleEditClick = (selectedRoomType) => {
		if (room.primaryRoomTypeID == selectedRoomType) return
		else handleUpdateRoom('primaryRoomTypeID', selectedRoomType)
	}

	const handleEditNewClick = (selectedRoomType) => {
		if (room.primaryRoomTypeID == selectedRoomType) return
		else {
			let mapping = room?.mapping?.filter(mapped => !mapped.new) || [];
			mapping.push({
				new: true,
				roomTypeID: selectedRoomType, 
				startDate: moment().format('YYYY-MM-DD'), 
				endDate: moment().add(ROOM_TYPE_VALIDITY_IN_YEARS, "years").format("YYYY-MM-DD")
			})
			handleUpdateMultipleRoom({ 'primaryRoomTypeID': selectedRoomType, 'mapping': mapping })
		}
	}

	const handleSubmit = (data) => {
		data = { ...data, propertyID: currentSpace.propertyID };
		const response = props.dispatch(createRoomType(currentSpace._id, data));
		response.then(data => {
			console.log('response', data);
			if (data) {
				setNewRoomCategory(false);
			}
		})
	}

	return (
		<>
			<div className="main-content">

				<div className="main-content-title">{room._id ? 'Primary ' : null}Room Type</div>
				{!room._id && <div className="sub-title">{t('beds.roomTypesAndRooms.selectRoomType')}</div>}

				{!room._id && <div className="row">
					<div className={classes.roomTypeContainer}>
						{currentSpace &&
							currentSpace.roomTypes &&
							currentSpace.roomTypes
								.filter(roomType => roomTypes[roomType].isActive !== false)
								.map(roomType =>
									<Pill
										key={roomType}
										name={inventoryConditionHandler(roomTypes[roomType], DATA_ACTION.NAME_STATUS)}
										handleClick={() => handleClick(roomType)}
										selected={room.primaryRoomTypeID == roomType}
										green={room.primaryRoomTypeID == roomType}
										cancelButton={room.primaryRoomTypeID == roomType}
									/>
								)}
					</div>
				</div>}

				{!room._id && !newRoomCategory && <div className="row">
					<div className={classes.link} onClick={() => setNewRoomCategory(true)}>{t('beds.roomTypesAndRooms.addRoomType')}</div>
				</div>}

				{!room._id && newRoomCategory && <div className="row">
					<NewRoomTypeForm handleSubmit={handleSubmit} handleCancel={() => setNewRoomCategory(false)} />
				</div>}

				{room._id && <div className="row">
					<div className={classes.infoRow}>
						<div>
							<img src={IMAGES.ICONS.bulb} className={classes.bulb} />
						</div>
						<div className={classes.helperText}>
							Primary room types can be any room type that has been mapped to this room. In case you would like
							to add a new primary room type, please first add it to the advanced inventory mapping.
						</div>
					</div>
				</div>}

				{room._id && <div className="row">
					<div className={classes.roomTypeContainer}>
						{!displayRoomTypeList && selectDistinct(room.mapping).map(roomTypeItem =>
							<Pill
								key={roomTypeItem}
								name={inventoryConditionHandler(roomTypes[roomTypeItem], DATA_ACTION.NAME_CODE_STATUS)}
								handleClick={() => roomTypes[roomTypeItem].isActive && handleEditClick(roomTypeItem)}
								selected={room.primaryRoomTypeID == roomTypeItem}
								green={room.primaryRoomTypeID == roomTypeItem}
								cancelButton={false}
							/>
						)}

						{displayRoomTypeList &&
							currentSpace?.roomTypes
								?.filter(roomType => roomTypes[roomType].isActive !== false)
								?.map(roomType =>
									<Pill
										key={roomType}
										name={inventoryConditionHandler(roomTypes[roomType], DATA_ACTION.NAME_CODE_STATUS)}
										handleClick={() => handleEditNewClick(roomType)}
										selected={room.primaryRoomTypeID == roomType}
										green={room.primaryRoomTypeID == roomType}
										cancelButton={room.primaryRoomTypeID == roomType}
									/>
								)}
					</div>
				</div>}

			</div>
		</>
	)
}

const mapStateToProps = state => ({
	currentSpace: state.spaces[state.dashboard.currentSpace],
	roomTypes: state.roomTypes,
	rooms: state.rooms,
})

export default withTranslation()(connect(mapStateToProps)(RoomTypeSelect)); 