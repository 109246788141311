import http from '../../utils/http';

//----------------------------------------------------------------
// LOAD PROPERTY
//----------------------------------------------------------------
export function fetchProperty(propertyID) {
  const ACTION_NAME = "LOAD_PROPERTY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/property/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT PROPERTY
//----------------------------------------------------------------
export function editProperty(propertyID, property) {
  const ACTION_NAME = "EDIT_PROPERTY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/property/${propertyID}`, { ...property })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        let errorRes =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
          const { error } = errorRes;
          const message = error && error.message ? error.message : 'Update failed';
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: message } });
      });
  };
}

//----------------------------------------------------------------
// UPLOAD PROPERTY IMAGE
//----------------------------------------------------------------
export function uploadPropertyPhoto(id, type, payload) {
  const ACTION_NAME = "UPLOAD_PROPERTY_PHOTO";
 
  const formData = new FormData();
  formData.append('upload',payload);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/space/${type}/${id}`, formData, config)
      .then(response => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: response.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// RESET PROPERTY
//----------------------------------------------------------------

export const resetProperty = () => (dispatch) => {
  dispatch({ type: "RESET_PROPERTY" })
};

//----------------------------------------------------------------
// RESET ERROR
//----------------------------------------------------------------

export function resetError(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}
//----------------------------------------------------------------
// VALIDATE VAT NUMBER IN PLUS FORM
//----------------------------------------------------------------

export function validateVatNumber(vatNumber) {
  const ACTION_NAME = "VALIDATE_VAT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/vat/validate?vatNumber=${vatNumber}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export const resetVatMessage = () => (dispatch) => {
  dispatch({ type: "RESET_VAT" })
}