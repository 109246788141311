import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import {
  fetchCurrencyFormat,
  convertArrayToCSV,
  generateCSV,
} from "../../utils/utility";
import { returnCurrent } from "../../redux/selectors/dashboard";
import {
  loadBookingStats,
  resetBookingStats,
} from "../../redux/actions/reports/bookingMetrics";

import ArrowIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconHolder: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "&:hover": {
      background: "#eeeeee",
    },
  },
  icon: {
    fontSize: "5rem",
  },
  downloadButton: {
    cursor: "pointer",
    height: 25,
    width: 25,
    left: 24,
    opacity: 0.7,
    position: "absolute",
    top: 28,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    //minHeight: 'calc(100vh - 230px)',
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    paddingTop: 30,
  },
  rightAlign: {
    boxSizing: "border-box",
    display: "flex",
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
  },
  fieldContainer: {
    border: "none",
    borderRadius: 5,
    boxShadow: "0 1px 10px 0 #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "46px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      boxShadow: "0 1px 10px #E0E0E0",
      border: "none",
      borderRadius: 5,
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  graph: {
    height: 400,
    width: "100%",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "200px",
    width: "100%",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  countryHeader: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 70,
    textAlign: "center",
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const selectStyle2 = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    fontWeight: 500,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const NewZealand = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const { open, handleClose, currentSpace, bookingStats, loadingStats } = props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[2]);
  const [report, setReport] = React.useState(0);
  const [reportSettings, setReportSettings] = React.useState({
    metric: "revPAU",
    unit: "room",
    label: "RevPAR",
    level: "daily",
  });

  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );

  // CLEANUP - DELETE REPORT DATA
  // ----------------------------------------------------------------------
  React.useEffect(() => {
    props.dispatch(
      loadBookingStats(
        currentSpace.propertyID,
        moment().subtract(15, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
    return function cleanup() {
      props.dispatch(resetBookingStats());
    };
  }, []);

  // CHANGE DATES
  // ----------------------------------------------------------------------
  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "monthToDate") {
      reportFrom = moment().startOf("month");
    } else if (e.value === "yearToDate") {
      reportFrom = moment().startOf("year");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 31) {
      newLevel = "monthly";
    }

    props.dispatch(
      loadBookingStats(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD")
      )
    );
    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setReportSettings({ ...reportSettings, level: newLevel });
  };

  // CHANGE REPORT DROPDOWN
  // ----------------------------------------------------------------------
  const setReportType = (e) => {
    if (e.value !== reportTypes[report].value) {
      setReport(reportTypes.findIndex((item) => item.value === e.value));
    }
  };

  // SUBMIT QUERY
  // ----------------------------------------------------------------------
  const handleSubmit = () => {
    props.dispatch(
      loadBookingStats(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD")
      )
    );
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
    setReportSettings(settings);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* <img className={classes.downloadButton} src="/images/icons/download.png" /> */}
          <Typography className={classes.header}>
            New Zealand {t("reports.report")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.BOOKING_METRICS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          {/* --------------------------------------------------------- */}
          {/* DATE RANGE FILTERS */}
          {/* --------------------------------------------------------- */}
          <div className={clsx(classes.listSection, classes.rightAlign)}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3}>
                <div className={classes.fieldContainer}>
                  <Select
                    value={presetValue}
                    styles={selectStyle}
                    options={presetRanges}
                    onChange={setNewDates}
                    theme={(theme) => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item className={classes.dateHolder}>
                <DateRangePicker
                  startDate={dates.startDate}
                  startDateId="start_date"
                  endDate={dates.endDate}
                  endDateId="end_date"
                  onDatesChange={({ startDate, endDate }) => {
                    setDates({ startDate, endDate });
                    setPresetValue(presetRanges[7]);
                  }}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  focusedInput={focusedInput}
                  displayFormat={"DD MMM YYYY"}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                />
              </Grid>
              <Grid item xs={2} md={1} xl={1}>
                <div className={classes.buttonContainer} onClick={handleSubmit}>
                  <ArrowIcon className={classes.go} />
                </div>
              </Grid>
            </Grid>
          </div>

          {loadingStats && (
            <div className={classes.blankSlate}>
              <CircularProgress />
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* NZ BOOKING STATS*/}
          {/* --------------------------------------------------------- */}
          {!loadingStats && (
            <div className={classes.listSection}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.totalNightsAvailable}
                    </div>
                    <div className={classes.desc}>
                      Total stay-unit nights available
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.nightsOccupied}
                    </div>
                    <div className={classes.desc}>
                      Stay-unit nights occupied
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.guestNightsOccupied}
                    </div>
                    <div className={classes.desc}>Short Term Guest Nights</div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.guestArrivals}
                    </div>
                    <div className={classes.desc}>
                      Short Term Guest Arrivals
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.nzGuests}
                    </div>
                    <div className={classes.desc}>NZ Guests</div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.overseasGuests}
                    </div>
                    <div className={classes.desc}>Overseas Guests</div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} align="center">
                  <div className={classes.bubble}>
                    <div className={classes.amount}>
                      {bookingStats?.unknownGuestOrigin}
                    </div>
                    <div className={classes.desc}>Origin not recorded</div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  bookingStats: state.reports.bookingStats,
  loadingStats: state.loading.LOAD_BOOKING_STATS,
});

export default withTranslation()(connect(mapStateToProps)(NewZealand));
