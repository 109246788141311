import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import  { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { fetchDatePerTimezone } from '../../utils/utility'; 

const useStyles = makeStyles( theme => ({
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 195,
    justifyContent: 'center',
    width: '100%'
  },
  blankState: {
    alignItems: 'center',
    display: 'flex',
    height: 195,
    justifyContent: 'center',
    width: '100%'
  },
  availability: {
    color: '#666666',
    fontSize: '1.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem'
    }
  },
  availabilityHeader: {
    color: '#666666',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem'
    }
  },
  row: {
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15
  },
  indRow: {
    marginTop: 15
  },
  rowInfo: {
    fontWeight: 400,
    marginTop: 20
  }
}))

const AvailabilityForecast = (props) => {
  const classes = useStyles();
  const { live, loadingLive, currentSpace, currentSpaceID, t } = props

  let dateArray = [], privateArray = [], dormArray = [], totalArray = [];

  if (!loadingLive && currentSpaceID && currentSpace && live[currentSpaceID]) {
    if (live[currentSpaceID].twoWeeksAvailability && live[currentSpaceID].twoWeeksAvailability.length !== 0) {
      for (var i = 0; i < 10; i++) {
        const item = live[currentSpaceID].twoWeeksAvailability[i]
        dateArray.push(((item) && (item.date)) ? fetchDatePerTimezone(item.date, currentSpace.timezone).format('DD MMM') : "-")
        if(item) {
          privateArray.push(item.privateBedsAvailable ? item.privateBedsAvailable : '-')
          dormArray.push(item.dormBedsAvailable ? item.dormBedsAvailable : '-')
          totalArray.push(item.totalBedsAvailable ? item.totalBedsAvailable : '-')
        }
      }
    }
  }

  return (
    <div>
      {(!currentSpace || loadingLive) && <div className={classes.loadingState}>
        <CircularProgress />
      </div>}
      {!loadingLive && (!dateArray || (dateArray.length === 0) || (totalArray.length === 0)) && 
        <div className={classes.blankState}>
          {t('live.noAvailabilityData')}
        </div>
      }
      {currentSpace && !loadingLive && (dateArray.length > 0) && (totalArray.length > 0) && <div className={classes.row}>
        <Grid container alignItems="center" className={classes.indRow}>
          <Grid item xs={2} sm={2}></Grid>
          {dateArray.map((date, index) => (
            <Grid key={`date_${index}`} item xs={1} sm={1} className={classes.availability}>
              {date}
            </Grid>
          ))}
        </Grid>
        {(dormArray.length > 0) && <Grid container alignItems="center" className={classes.indRow}>
          <Grid item xs={2} sm={2} className={clsx(classes.availabilityHeader)}>{t('live.occupancy.dorms')}</Grid>
          {dormArray.map((dorm, index) => (
            <Grid key={`dorm_${index}`} item xs={1} sm={1} className={clsx(classes.availability, classes.rowInfo)}>
              {dorm}
            </Grid>
          ))}
        </Grid>}
        {(privateArray.length > 0) && <Grid container alignItems="center" className={classes.indRow}>
          <Grid item xs={2} sm={2} className={clsx(classes.availabilityHeader)}>{t('live.occupancy.privates')}</Grid>
          {privateArray.map((prvt, index) => (
            <Grid key={`private_${index}`} item xs={1} sm={1} className={clsx(classes.availability, classes.rowInfo)}>
              {prvt}
            </Grid>
          ))}
        </Grid>}
        <Grid container alignItems="center" className={classes.indRow}>
          <Grid item xs={2} sm={2} className={clsx(classes.availabilityHeader)}>{t('live.occupancy.total')}</Grid>
          {totalArray.map((total, index) => (
            <Grid key={`total_${index}`} item xs={1} sm={1} className={clsx(classes.availability, classes.rowInfo)}>
              {total}
            </Grid>
          ))}
        </Grid>
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  live: state.live,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  loadingLive: state.loading.LOAD_LIVE,
  currentSpaceID: state.dashboard.currentSpace
})

export default withTranslation()(connect(mapStateToProps)(AvailabilityForecast));