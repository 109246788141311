import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import moment from "moment";
import Linkify from 'react-linkify';

import { initials } from '../utils/wordUtils';

import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import { fetchDatePerTimezone } from '../../utils/utility';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  dateBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: 10,
    position: "relative",
    zIndex: 1
  },
  date: {
    fontSize: 18,
    fontWeight: 600,
    zIndex: 1,
    '& span': {
      '& h5':{
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        fontSize: '1.2rem',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      }
    },
    '&::before': {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "0",
      right: "0",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      zIndex: "-1"
    }
  },
  messageContainer: {
    marginTop: 3,
    display:"flex",
    position: "relative",
    width: "calc(100% - 25px)"
  },
  message: {
    borderRadius: "1rem",
    boxShadow: '0 1px 20px #F0F0F0',
    display: "inline-block",
    lineHeight: 1.4,
    maxWidth: "80%",
    textAlign: "left",
    padding: "10px 15px",
    position: "relative"
  },
  senderBox: {
    alignItems: "center",
    display: "flex",
    fontSize: '1.1rem',
    marginBottom: 3
  },
  sender: {
    fontSize: 10,
    fontWeight: 600
  },
  messageTime: {
    fontSize: 9,
    marginRight: 5
  },
  avatarContainer: {
    position: 'absolute',
    top: 3,
    zIndex: 5
  },
  avatar: {
    backgroundColor: theme.palette.grey[300],
    border: "0px solid #dddddd",
    color: '#ffffff',
    fontSize: '1.4rem',
    height: 30,
    lineHeight: 2,
    textAlign: 'center',
    width: 30
  },
  messageText: {
    color: "#000000",
    display: 'block',    
    fontSize: '1.2rem',
    marginTop: 3,
    whiteSpace: "pre-line",
    wordWrap: "break-word"
  }
}))

const SidebarChatMessage = (props) => {

  const classes = useStyles();
  const {message, users} = props;

  return (
    <div className={classes.root}>
      {!props.sameDay && (
        <div className={classes.dateBox}>
          <div className={classes.date}><span>
            <Typography variant="h5">
              {fetchDatePerTimezone(message.createdAt, props.timezone).isSame(fetchDatePerTimezone(null, props.timezone), "day") ? 
              "Today" : 
              fetchDatePerTimezone(message.createdAt, props.timezone).format('ddd, MMM D')}
            </Typography>
          </span></div>
        </div>
      )}
      <div 
        className={classes.messageContainer} 
        style={{ 
          justifyContent: props.currentUser === true ? "flex-end": "flex-start",
          paddingLeft: props.currentUser === true ? "0px" : "25px",
          paddingRight: props.currentUser === true ? "25px" : "0px"
        }}
      >
        {(!props.sameDay || !props.thread) && (
          <div 
            className={classes.avatarContainer}
            style={{ 
              left: props.currentUser === true ? "": "0",
              right: props.currentUser === true ? "0": ""
            }}
          >
            <Avatar 
              alt={users[message.senderID] && initials(users[message.senderID].name)}
              src={users[message.senderID] && users[message.senderID].avatar} 
              className={classes.avatar} 
            >
              {users[message.senderID] && initials(users[message.senderID].name)}
            </Avatar>
          </div>
        )}
        <div 
          className={classes.message} 
          key={message.createdAt} 
          style={{ 
            backgroundColor: props.currentUser === true ? "#F2F2F2": "#F2F2F2",
            color: props.currentUser === true ? "#000000": "#000000",
            boxShadow: props.currentUser === true ? "none": "none"
          }}
        >
          {(!props.sameDay || !props.thread) && (<div className={classes.senderBox} style={{ flexDirection: props.currentUser ? "row-reverse" : "row" }}>
              <div 
                className={classes.sender} 
                style={{ marginRight: props.currentUser ? 0 : 5 }}
              >
                {props.currentUser ? "Me" : users[message.senderID] && users[message.senderID].name}
              </div>
              <div className={classes.messageTime}>
                {fetchDatePerTimezone(message.createdAt, props.timezone).format('h:mm a')}
              </div>
            </div>
          )}
          <div 
            className={classes.messageText}
            style={{ 
              color: props.currentUser === true ? "#000000": "#000000"
            }}
          >
            <Linkify>{JSON.parse(message.message)}</Linkify>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps)(SidebarChatMessage);