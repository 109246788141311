import React from "react";
import { withStyles } from "@material-ui/core";

import SearchFilters from "../components/search/SearchFilters";
import SearchResults from "../components/search/SearchResults";

import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  container: {
    background: '#F8F9FB',
    width: '100%'
  },
});

const SearchContainer = (props) => {

  const { classes } = props;

  return (

    <div className={classes.container}>
      <SearchFilters />
      <SearchResults />
    </div>
  )
}

export default withStyles(styles)(SearchContainer);