import React, { useEffect } from 'react';
import {Bar} from 'react-chartjs-2';

const dataDefaults = {
  datasets: [{}]
};

const optionDefaults = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        stacked: true,
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: true
        },
        labels: {
          show: true
        }
      }
    ]
  }
};

export default (props) => {
  
  const [graph, setGraph] = React.useState({data: dataDefaults, options: optionDefaults});

  useEffect(() => {
    if (props.data && props.data.length) {
      let tempData = graph.data;
      let tempOptions = graph.options;
      tempData.labels = props.labels;
      tempData.datasets[0].data = props.data;
      if (props.color) tempData.datasets[0].backgroundColor = props.color
      else tempData.datasets[0].backgroundColor = new Array(props.labels.length).fill('#4F9581');
      setGraph({data: {...tempData}, options: {...tempOptions}});
      console.log('loaded');
    }
  }, [props.data])

  return (
    graph.data?.labels?.length ? <Bar
      data={graph.data}
      options={graph.options}
    />: null
  );
}