import { EXPERIENCE_STATUS, RESERVATION_STATUS } from "../../../utils/constants";

export default (state = {}, action) => {
  switch (action.type) {
    case "LOAD_RESERVATION_SUCCESS":
      return {
        ...action.payload
      };
      case "LOAD_RESERVATION_CARD_TOKENS_SUCCESS":
      return {
        ...state,
        maskedCards: action.payload
      };
      case "UPDATE_RESERVATION_SUCCESS":
      const updateReservation = state.reservation;
      const reservationPayload = {...action.payload.updatedRes}
      console.log("action.payload.updatedRes", action.payload.updatedRes)
      return {
        ...state,
        reservation: {
          ...updateReservation,
          accommodationList: reservationPayload.accommodationList,
          accommodationAmount: reservationPayload.accommodationAmount,
          inclusiveTaxAmount: reservationPayload.inclusiveTaxAmount,
          exclusiveTaxAmount: reservationPayload.exclusiveTaxAmount,
          checkIn: reservationPayload.checkIn,
          checkOut: reservationPayload.checkOut,
          totalAmount: reservationPayload.totalAmount,
          taxAmount: reservationPayload.taxAmount,
          taxesApplied: reservationPayload.taxesApplied,
          balance: reservationPayload.balance,
          allocationLock: reservationPayload.allocationLock,
        }
      }
    case "UPDATE_RESERVATION_DATA_SUCCESS":
      const updateReservationData = state.reservation;
      const reservationPayloadData = {...action.payload}
      return {
        ...state,
        reservation: {
          ...updateReservationData,
          ...reservationPayloadData
        }
      }

    case "ADD_RESERVATION_NOTE_SUCCESS":
      const prevReservation = state.reservation;
      const prevNotes = state.reservation.notes;
      return {
        ...state,
        reservation: {
          ...prevReservation,
          notes: [
            ...prevNotes,
            action.payload
          ]
        }
      }

    case "DELETE_RESERVATION_NOTE_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          notes: [
            ...action.payload
          ]
        }
      }
    case "SEND_EMAIL_SUCCESS":
      return {
        ...state,
        emails: [
          ...state.emails,
          action.payload
        ]
      }
    case "ADD_RESERVATION_PAYMENT_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          balance: action.payload.balance
        },
        transactions: [
          ...state.transactions,
          action.payload.transaction
        ]
      }

    case "VOID_RESERVATION_TRANSACTION_SUCCESS":
      let voidPayload = action.payload;
      let resTransactions = [...state.transactions] || [];
      resTransactions = resTransactions.filter(t => t._id !== voidPayload._id);

      return {
        ...state,
        reservation: {
          ...state.reservation,
          balance: (state.reservation.balance || 0) + (voidPayload.amount || 0)
        },
        transactions: [
          ...resTransactions
        ]
      }
    case "ADD_CUSTOMER_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          guestCount: state.reservation.guestCount + 1,
          guests: [
            ...state.reservation.guests,
            action.payload.customer
          ]
        }
      }
      
    case "UPDATE_CUSTOMER_SUCCESS":
      let isPrimary = action.isPrimary;
      let custReservation = {...state.reservation};
      let guests = state.reservation.guests || [];
      let payload = action.payload;
      guests = guests.map(guest => {
        if (guest._id === payload._id) guest = { ...payload };
        return guest;
      });
      if(isPrimary){
        custReservation.guestName = ((payload.firstName || '') +" "+(payload.lastName || '')).trim();
        custReservation.guestEmail = payload.email;
      }
      return {
        ...state,
        reservation: {
          ...custReservation,
          guests: guests
        }
      }

    case "DELETE_CUSTOMER_SUCCESS":
      let revGuests = state.reservation.guests || [];
      let remGuestRes = action.payload.data;
      revGuests = revGuests.filter(guest => guest._id !== action.payload.deletedCustomerID);
      return {
        ...state,
        reservation: {
          ...state.reservation,
          guestCount: remGuestRes.guestCount,
          guestName: remGuestRes.guestName,
          guestEmail: remGuestRes.guestEmail,
          guestPhone: remGuestRes.guestPhone,
          guests: revGuests
        }
      }
    case "UPLOAD_CUSTOMER_DOC_SUCCESS":
      let resGuests = state.reservation.guests || [];
      resGuests = resGuests.map(guest => {
        if (guest._id === action.payload._id) guest = { ...action.payload };
        return guest;
      })
      return {
        ...state,
        reservation: {
          ...state.reservation,
          guests: resGuests
        }
      }
    case "LOAD_ACTIVITY_LOG_SUCCESS":
      return {
        ...state,
        activityLog: action.payload.activityLog
      }
    case "UPDATE_RESERVATION_STATUS_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: action.payload.status,
          checkedInByStaff: action.payload.checkedInByStaff,
          checkedInDate: action.payload.checkedInDate,
          checkedOutDate: action.payload.checkedOutDate,
        }
      }
    case "UPDATE_RESERVATION_ITEMS_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          upsellList: action.payload.upsellList,
          upsellAmount: action.payload.upsellAmount,
          totalAmount: action.payload.totalAmount,
          balance: action.payload.balance,
        }
      }
    case "MANAGE_RESERVATION_TAX_SUCCESS":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          balance: action.payload.balance,
          exclusiveTaxAmount: action.payload.exclusiveTaxAmount,
          inclusiveTaxAmount: action.payload.inclusiveTaxAmount,
          taxAmount: action.payload.taxAmount,
          taxesApplied: action.payload.taxesApplied,
          totalAmount: action.payload.totalAmount,
        }
      }
    case "ADD_RESERVATION_SUCCESS":
      let newReservation = { ...state };
      let result = action.payload[0];
      if (result.status === RESERVATION_STATUS.CONFIRMED) {
        newReservation = {
          reservation: {
            _id: action.payload[0].resID,
            status: action.payload[0].status
          }
        }
      }
      return { ...state, ...newReservation }
    case "USER_LOGOUT":
      return {}
    case "RESET_RESERVATION":
      return {}

    case "CHANNEX_CAPTURE_CARD_SUCCESS":
      const maskedCards = [...state.maskedCards, action.payload]
      return{
        ...state,
        maskedCards,
        reservation:{
          ...state.reservation,
          channexCardToken: action.payload.card_token
        }
      }

    case "TRANSACTION_REFUND_SUCCESS":
      return{
        ...state,
        reservation: {
          ...state.reservation,
          balance: action.payload.balance
        },
        transactions: [
          ...state.transactions,
          action.payload.transaction
        ]
      }
    case "CREATE_ORDER_SUCCESS":
      return{
        ...state,
        reservation: {
          ...state.reservation,
          balance: action.payload.balance, 
          taxAmount: action.payload.taxAmount, 
          totalAmount: action.payload.totalAmount
        },
        orders: [...action.payload.orders]
      }
    case "DELETE_ORDER_SUCCESS":
      return{
        ...state,
        reservation: {
          ...state.reservation,
          balance: action.payload.balance, 
          taxAmount: action.payload.taxAmount, 
          totalAmount: action.payload.totalAmount
        },
        orders: [...action.payload.orders]
      }
    case "UPDATE_EXPERIENCE_PRODUCT_STATUS_SUCCESS":
    case "UPDATE_EXPERIENCE_PRODUCT_SUCCESS":
    case "CREATE_EXPERIENCE_PRODUCT_SUCCESS":
      let expBookings = action.payload.expBookings;
      let reservationResp = action.payload.reservation;
      return{
        ...state,
        reservation: {
          ...state.reservation,
          balance: reservationResp.balance, 
          taxAmount: reservationResp.taxAmount, 
          totalAmount: reservationResp.totalAmount
        },
        expBookings: [...expBookings]
      }

    default:
      return state;
  }
};