const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "FETCH_ODOO_CONFIG_SUCCESS":
    case "UPDATE_ODOO_CONFIG_SUCCESS":
    case "SAVE_ODOO_CONFIG_SUCCESS":
      return action.payload

    case "GET_ODOO_ROOMTYPES_SUCCESS":
    case "UPDATE_ODOO_ROOMTYPES_SUCCESS":
      let thisOdoo = { ...state }
      if(thisOdoo)
        thisOdoo.roomTypes = action.payload

      return {
        ...thisOdoo
      }

    case "GET_ODOO_PAYMETHOD_SUCCESS":
    case "UPDATE_ODOO_PAYMETHOD_SUCCESS":
      let payOdoo = { ...state }
      if(payOdoo)
        payOdoo.paymentMethods = action.payload

      return {
        ...payOdoo
      }

    case "RESET_ODOO_CONFIG":
      return { default: true };
    default:
      return state;
  }
};
