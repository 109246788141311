export default (state = {}, action) => {
  switch(action.type) {
    case "ADD_PRINTER_SUCCESS":
      if (action.payload.data) {
        var printers = []

        printers.push(action.payload.data)

        return {
          ...state,
          printers
        }
      } else return { ...state }
    
    case "FETCH_PRINTERS_SUCCESS":
      if(action.payload) {
        return {
          ...state,
          printers: action.payload
        }
      }
      else return { ...state };
    
    case "RESET_PRINTERS":
      return {
        ...state,
        printers: []
      }
    
    default:
      return state;
  }
};