import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { returnCurrent } from "../../redux/selectors/dashboard";
import _ from "lodash";

import TodoItem from './TodoItem';
import Category from './Category';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  listSection: {
    height: 'calc(100vh - 216px)',
    margin: theme.spacing(3),
    overflowY: 'scroll',
  },
  createSection: {
    alignItems: 'flex-end',
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  button: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    height: 300,
    justifyContent: 'center'
  },
})

const TodoSingleList = (props) => {
  const { t } = props
  const {classes, open, handleClose, currentSpace, categoryID, todoCategories} = props;

  return (
    <div className={classes.root}>
      <Dialog 
        open={open} 
        onClose={handleClose}
        classes={{ paperFullWidth: classes.dialogPaper }}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={window.innerWidth < 901}
      >
        {/* --------------------------------------------------------- */}
        {/* Header */}
        {/* --------------------------------------------------------- */}
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            <div className={classes.header}>{t('todos.todoList')}</div>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </div>

          <div className={classes.listSection}>
            <Category handleCloseTodoList={handleClose} category={categoryID} />
          </div>
        </div>

        {/* ------------------------------------------------ */}
        {/* View individual todo */}
        {/* ------------------------------------------------ */}
        <TodoItem handleCloseTodoList={handleClose} handleOpenTodoList={() => console.log('back')} />

      </Dialog>
    </div>
  )
}

TodoSingleList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentTodoID: state.dashboard.currentTodo,
  todoCategories: state.todoCategories
})


export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(TodoSingleList)));