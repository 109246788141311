

//----------------------------------------------------------------
// SAVE FRONTDESK USERS WHEN SPACE LOADS
//----------------------------------------------------------------
export default (state = [], action) => {
  switch(action.type) {
    case "LOAD_FRONTDESK_USERS_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.frontdeskUsers
      }
    case "EDIT_FRONTDESK_USER_SUCCESS":
      return {
        ...state,
        [action.payload._id]: {...action.payload}
      }
    case "CREATE_FRONTDESK_USER_SUCCESS":
      return {
        ...state,
        [action.payload._id]: {...action.payload}
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};

