import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';


const styles = theme => ({
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  }
})

const colorArray = [
  "#8CB2A4",
  "#AEC1E1",
  "#F2D073",
  "#E37381",
  "#E6BBB3"
]

const compare = ( a, b ) => {
  if ( a.count > b.count ){
    return -1;
  }
  if ( a.count < b.count ){
    return 1;
  }
  return 0;
}


const ChannelPie = (props) => {
  const { t } = props;
  const { classes, currentSpace, live} = props;
  const bookingSource = live[currentSpace] && live[currentSpace].resvSource;
  const data = bookingSource ? 
                bookingSource.sort(compare).slice(0,5).map((e,index) => ({
                  id: e._id.status ? e._id.status : "None",
                  value: e.count, 
                  color: colorArray[index], 
                  label: e._id.status ? e._id.status : "None" 
                }) ) : 
                [];

  return (
    data.length === 0 ? 
      <div className={classes.blankSlate}>
      {t('live.noBookingSourceData')}
      </div> :
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 60, bottom: 40, left: 60 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ datum: 'color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
          // {
          //   anchor: 'bottom',
          //   direction: 'row',
          //   translateY: 56,
          //   itemWidth: 0,
          //   itemHeight: 0,
          //   itemTextColor: 'transparent',
          //   symbolSize: 0,
          //   symbolShape: 'circle',
          //   effects: [
          //       {
          //           on: 'hover',
          //           style: {
          //               itemTextColor: '#000'
          //           }
          //       }
          //   ]
          // }
        ]}
      />
  )
}

const mapStateToProps = state => ({
  currentSpace: state.dashboard.currentSpace,
  live: state.live
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(ChannelPie)));