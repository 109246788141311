import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import { InputBase } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import NumberInput from '../common/NumberInput';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: '10px',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'calc(100% - 50px)',
    '& > div': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      marginBottom: 10,
    }
  },
  fieldError: {
    border: '1px solid red',
  },
  radioError: {
    color: 'red',
  },
  select: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 10,
    marginTop: 10,
    width: 'calc(100% - 20px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 10,
    }
  },
  link: {
    color: 'blue',
    cursor: 'pointer',
  },
  removeSlab: {
    color: '#999999',
    fontSize: '1.8rem',
    cursor: 'pointer'
  },
  cancel: {
    '&:hover': {
      background: '#ffffff'
    }
  },
  helperText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    fontSize: '1.2rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.66,
    marginLeft: '10px'
  },
  perNight: {
    width: '45%',
    [theme.breakpoints.down('xs')]:{
      width: 'calc(100% - 50px)',
    }
  }
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const NewTax = (props) => {
  const { t } = props

  const taxTypesArray = [
    { value: 'amountPerAccommodation', label: t('beds.taxesSnapshot.taxType.amountPerAccommodation') },
    { value: 'amountPerNight', label: t('beds.taxesSnapshot.taxType.amountPerNight') },
    { value: 'amountPerPerson', label: t('beds.taxesSnapshot.taxType.amountPerPerson') },
    { value: 'amountPerReservation', label: t('beds.taxesSnapshot.taxType.amountPerReservation') },
    { value: 'percentOfTotal', label: t('beds.taxesSnapshot.taxType.percentOfTotal') },
    { value: 'rateSlabs', label: t('beds.taxesSnapshot.taxType.rateSlabs') }
  ]
  const classes = useStyles();
  const { currentSpace } = props;
  const [form, setForm] = React.useState({
    name: '',
    type: '',
    calculationMode: 'exclusive',
    rateSlabRules: [{ priceFrom: 0, priceTo: 0, value: 0 }],
    value: 0,
    code: '',
    maxNights: 0
  });
  const [ hasMaxLength, setHasMaxLength ] = React.useState('no');
  const [error, setError] = React.useState(
    {
      name: false,
      type: false,
      calculationMode: false,
      value: false,
      code: false,
      // hasMaxLength: false
      // maxNights: false
    }
  );

  const handleChange = (e, name) => {
    let value = e.target.value;
    const numberInputs = ['value', 'maxNights'];
    if(numberInputs.includes(name)){
      value = value < 0 ? Math.abs(value) : value;
    }

    setForm({ ...form, [name]: value })
    if (value !== '') {
      setError({ ...error, [name]: false })
    }
  }

  const handleSelect = (option, type) => {

    if(form.type === 'amountPerNight' && type == 'type') maxLengthStatusHandler('no');

    setForm({ ...form, [type]: option.value })
    if (option.value !== '') {
      setError({ ...error, [type]: false })
    }
  }

  const removeSlab = (deleteIndex) => {
    let items = form.rateSlabRules.filter((slab, index) => index != deleteIndex);
    setForm({ ...form, rateSlabRules: items });
  }

  const addSlab = () => {
    setForm({ ...form, rateSlabRules: [...form.rateSlabRules, { priceFrom: 0, priceTo: 0, value: 0 }] })
  }

  const handleRateUpdate = (value, name, editIndex) => {
    const updateslab = form.rateSlabRules.map((slab, index) =>
      index === editIndex ? { ...slab, [name]: value } : slab
    );
    setError({ ...error, ['priceFrom' + editIndex]: false, ['priceTo' + editIndex]: false, })
    setForm({ ...form, rateSlabRules: updateslab });
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['name', 'type', 'calculationMode'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })

    if(form.type === 'rateSlabs'){
      form?.rateSlabRules?.map((slab, index) => {
        if (parseFloat(slab.priceFrom) >= parseFloat(slab.priceTo)) {
          errorStep['priceFrom' + index] = true;
          errorStep['priceTo' + index] = true;
          foundError = true;
        }
      });
    }

    // if(form.type === 'amountPerNight' && !form.hasOwnProperty("maxNights")){
    //   errorStep['maxNights'] = true;
    //   foundError = true;
    // }

    // if(form.type === 'amountPerNight' && !hasMaxLength){
    //   errorStep['hasMaxLength'] = true;
    //   foundError = true;
    // }

    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    // console.log("form", form)
    const checkInput = handleValidate();
    if (checkInput) {
      let payload = { ...form };
      if (form.type === 'rateSlabs') {
        payload.value = 0;
        payload.rateSlabRules.map((slab, index) => {
          payload.rateSlabRules[index].priceFrom = parseFloat(slab?.priceFrom || 0)
          payload.rateSlabRules[index].priceTo = parseFloat(slab?.priceTo || 0)
          payload.rateSlabRules[index].value = parseFloat(slab?.value || 0)
        });
      }
      else {
        payload.rateSlabRules = [];
        payload.value = parseFloat(payload.value);
      }

      //console.log("payload", payload);
      props.handleSubmit(payload);
    }
  }

  const maxLengthStatusHandler = (status) =>{
    if(status == 'no') setForm({ ...form, maxNights: 0 });
    setError({ ...error, hasMaxLength: false });
    setHasMaxLength(status);
  }

  return (
    <div className={classes.root}>

      {/* ---------------TAX NAME AND TYPE FIELDS-------------------------------- */}
      <div className={classes.row}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>{t('beds.taxesSnapshot.name')}</div>
            <div className={clsx(classes.field, { [classes.fieldError]: error.name })}>
              <InputBase
                id="name"
                name="Tax Name"
                type="text"
                defaultValue={form.name}
                onChange={(e) => handleChange(e, "name")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.label}>{t('beds.taxesSnapshot.type')}</div>
            <div className={clsx(classes.select, { [classes.fieldError]: error.type })}>
              <Select
                className={classes.dropdownInput}
                isSearchable={true}
                name="Type"
                defaultValue={taxTypesArray.find(h => h.value == form.type)}
                onChange={(option) => handleSelect(option, "type")}
                options={taxTypesArray}
                styles={selectStyle}
                theme={theme => ({
                  ...theme,
                  border: 0,
                  colors: {
                    ...theme.colors,
                    primary: '#666666',
                    primary25: '#dddddd'
                  },
                })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      
      {/* ---------------CALCULATION AND AMOUNT FIELDS-------------------------------- */}
      <div className={classes.row}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>{t('beds.taxesSnapshot.calculation')}</div>
            <FormControl component="fieldset" >
              <RadioGroup row aria-label="position"
                className={error.calculationMode ? classes.radioError : ''}
                defaultValue={form.calculationMode}
                name="calculationMode" onChange={(e) => {
                  setForm({ ...form, calculationMode: e.target.value });
                  setError({ ...error, calculationMode: false })
                }}>
                <FormControlLabel value={'exclusive'} control={<Radio color="primary" />} label={t('beds.taxesSnapshot.exclusive')} />
                <FormControlLabel value={'inclusive'} control={<Radio color="primary" />} label={t('beds.taxesSnapshot.inclusive')} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {form.type !== 'rateSlabs' &&
            <Grid item xs={12} sm={6}>
              <div className={classes.label}>{t('beds.taxesSnapshot.amount')}</div>
              <div className={clsx(classes.field, { [classes.fieldError]: error.value })}>
                <NumberInput
                  id="value"
                  name="Amount"
                  min={0}
                  value={form.value || 0}
                  onChange={(e) => handleChange(e, "value")}
                  endAdornment={
                    <span>{form.type.includes('percent') ? '%': currentSpace.currency}</span>
                  }
                />
              </div>
            </Grid>
          }
        </Grid>
      </div>
      
      {/* ---------------APPLY TO MAX NIGHT FIELD-------------------------------- */}
      {form.type === 'amountPerNight' && (
        <>
          <div className={classes.row}>
            <div className={classes.label}>Does this tax have a max length?</div>
            <FormControl component="fieldset" >
              <RadioGroup row aria-label="position"
                // className={error.hasMaxLength ? classes.radioError : ''}
                value={hasMaxLength}
                name="hasMaxLength" 
                onChange={ (e) => maxLengthStatusHandler(e.target.value) }
              >
                <FormControlLabel value={'yes'} control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value={'no'} control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>

          {hasMaxLength === 'yes' &&
            <div className={classes.row}>
              <div className={classes.label}>Max Length</div>
              <div className={clsx(classes.field, classes.perNight, { [classes.fieldError]: error.maxNights })}>
                <NumberInput
                  id="maxNights"
                  name="maxNights"
                  value={form.maxNights || 0}
                  // defaultValue={form.maxNights}
                  // placeholder="Set zero to turn off"
                  min={0}
                  onChange={(e) => handleChange(e, "maxNights")}
                  endAdornment={
                    <span>Nights</span>
                  }
                />
              </div>
            </div>
          }
        </>
      )}

      {/* ---------------INTERNAL CODE FIELD-------------------------------- */}
      <div className={classes.row}>
        <div className={classes.label}>{t('beds.taxesSnapshot.internalCode')}</div>
        <div className={clsx(classes.field, { [classes.fieldError]: error.code })}>
          <InputBase
            id="code"
            name="Internal Code"
            type="text"
            defaultValue={form.code}
            onChange={(e) => handleChange(e, "code")}
          />
        </div>

      </div>
      
      {/* -------------------------SECTION DISPLAYED FOR PERCANTAGE BASED ON ROOM PRICE------------------------- */}
      {form.type === 'rateSlabs' &&
        <div className={classes.row}>
          {form.rateSlabRules.map((slab, index) =>
            <Grid container key={index} style={{ marginBottom: 15, alignItems: 'center' }}>
              <Grid item xs={4}>
                <div className={classes.label}>{t('beds.taxesSnapshot.roomPriceFrom')}</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error?.['priceFrom' + index] })}>
                  <NumberInput
                    id="priceFrom"
                    min={0}
                    label="Room Price From"
                    value={form.rateSlabRules[index].priceFrom}
                    onChange={(e) => handleRateUpdate(e.target.value || 0 , "priceFrom", index)}
                  />
                </div>
              </Grid>

              <Grid item xs={4}>
                <div className={classes.label}>{t('beds.taxesSnapshot.roomPriceTo')}</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error?.['priceTo' + index] })}>
                  <NumberInput
                    id="priceTo"
                    min={0}
                    label="Room Price To"
                    value={form.rateSlabRules[index].priceTo}
                    onChange={(e) => handleRateUpdate(e.target.value || 0 , "priceTo", index)}
                  />
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className={classes.label}>{t('beds.taxesSnapshot.taxRate')} %</div>
                <div className={classes.field}>
                  <NumberInput
                    id="value"
                    min={0}
                    label="Tax Rate %"
                    value={form.rateSlabRules[index].value}
                    onChange={(e) => handleRateUpdate(e.target.value || 0 , "value", index)}
                  />
                </div>
              </Grid>
              {form.rateSlabRules.length > 1 &&
                <Grid item xs={1}>
                  <CloseIcon className={classes.removeSlab} onClick={() => removeSlab(index)} />
                </Grid>
              }
            </Grid>
          )}
          <div className={clsx(classes.label, classes.link)} onClick={addSlab}>Add {form.rateSlabRules.length ? 'another' : 'a'} tax tier</div>
        </div>
      }
      <div className={classes.buttonContainer}>
        <Button className={classes.back} onClick={() => props.handleCancel()}>{t('beds.taxesSnapshot.cancel')}</Button>
        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>{t('beds.taxesSnapshot.save')}</Button>
      </div>

    </div>
  )
}

export default (withTranslation()(NewTax));