import { schema } from 'normalizr';

const mappingSchema = new schema.Entity('transformedRoomTypes', {}, {
  idAttribute: '_id'
});

const roomMappingSchema = new schema.Entity('roomMapping', {}, {
  idAttribute: 'roomTypeID'
});

const ratePlanMappingSchema = new schema.Entity('ratePlanMapping', {}, {
  idAttribute: 'roomtypeid'
});

const propertySchema = new schema.Entity('properties', {}, {
  idAttribute: 'id'
});

export { mappingSchema, roomMappingSchema, ratePlanMappingSchema, propertySchema }