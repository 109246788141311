import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';

import { returnCurrent } from "../../redux/selectors/dashboard";
import { updateTodo } from "../../redux/actions/todos/todos";
import { setCurrentTodo } from "../../redux/actions/dashboard/dashboard";
import { editTaskCategory } from '../../redux/actions/todos/todoCategories';
import {deleteTodoCategory} from '../../redux/actions/todos/todoCategories';
import { initials } from '../utils/wordUtils';
import NewTodo from './NewTodo';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import DescriptionIcon from '@material-ui/icons/Description';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';


const styles = theme => ({
  categoryContainer: {
    marginBottom: theme.spacing(3)
  },
  completed: {
    fontSize: 11,
    marginBottom: 5,
    color: "#666666"
  },
  listTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    '& h5': {
      fontWeight: 600
    }
  },
  editCategory: {
    marginBottom: 10,
    width: '100%'
  },
  editCategoryTitle: {
    fontWeight: 600
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10
  },
  button: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  categoryOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px'
  },
  categoryMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  moreIcon: {
    fontSize: '1.8rem'  
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  todoItem: {
    display: 'flex',
    alignItems: "center"
  },
  todoTitleHolder: {
    cursor: "pointer",
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: 5
  },
  todoTitle: {
    cursor: "pointer",
    display: 'inline-block',
    marginRight: 5
  },
  descIcon: {
    color: "#0081A7",
    display: 'inline-block',
    fontSize: 14,
    marginLeft: 5,
    marginRight: 5
  },
  commentCount: {
    fontSize: '1.2rem',
    height: '18px',
    margin: '5px',
    width: '18px'
  },
  userProfile: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    color: "#ffffff",
    fontSize: '1rem',
    height: 18,
    lineHeight: 1.8,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
    width: 18,
  },
  dueDate: {
    fontSize: 12,
    color: '#666666',
    marginRight: 5
  }
})

class Category extends React.Component {

  constructor() {
    super();
    this.state = {
      title: '',
      anchorEl: null,
      editCategory: false
    };
    this.textInput = React.createRef();
  };

  handleSelectTodo = (id) => {
    this.props.dispatch(setCurrentTodo(id))
  }

  handleStatusUpdate = (id) => {
    // console.log(id)
    const newStatus = !this.props.todos[id].status
    // console.log(id, newStatus)
    this.props.dispatch(updateTodo(id, newStatus));
  }

  handleCategoryMenu = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleCategoryMenuClose = () => {
    this.setState({anchorEl: null})
  }

  handleEditCategory = () => {
    this.setState({ title: this.props.todoCategories[this.props.category].name})
    this.setState({editCategory: true});
    this.setState({anchorEl: null});
  }

  handleDeleteCategory = () => {
    if (this.props.todoCategories[this.props.category].tasks.length === 0) {
      this.props.dispatch(deleteTodoCategory(this.props.currentSpaceID, this.props.category));
    } else {
      alert("Please delete individual todo items first")
    }
    this.setState({anchorEl: null});
    this.props.handleCloseTodoList();
  }

  handleSubmitCategoryUpdate = () => {
    // console.log('updating list name => ', this.state.title);
    this.props.dispatch(editTaskCategory(this.props.currentSpace, this.props.category, {name: this.state.title}))
    this.setState({editCategory: false});
  }

  focusInputField = input => {
    if (input) {
      setTimeout(() => input.focus(), 100);
    }
  };

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {

    const {todoCategories, todos, users, category, classes, t} = this.props;

    return (
      <div className={classes.categoryContainer}>

        <div className={classes.completed}>
          {/* {console.log(this.props.todoCategories[123] === undefined)} */}
          {todoCategories && !!(todoCategories[category])
          && todoCategories[category].tasks.length !== 0 
          && (
            <div>
              {todoCategories[category].tasks.filter(task => todos[task].status === true).length}/
              {todoCategories[category].tasks.length} {t('todos.completed')}
            </div>
          )}
        </div>
        <div className={classes.listTitle}>
          {/* ------------------------------------------------ */}
          {/* Category Title */}
          {/* ------------------------------------------------ */}
          {!this.state.editCategory && <Typography variant="h5">{todoCategories[category] && todoCategories[category].name}</Typography>}
          {this.state.editCategory && (
            <div className={classes.editCategory}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                fullWidth
                type="text"
                className={classes.editCategoryTitle}
                onChange={(e) => this.setState({title: e.target.value})}
                value={this.state.title}
                inputRef={this.focusInputField}
              />
            </div>
          )}

          {this.state.editCategory && (
            <div className={classes.buttonHolder}>
              <CloseIcon className={classes.button} onClick={() => this.setState({editCategory: false})} />
              <DoneIcon className={classes.button} onClick={this.handleSubmitCategoryUpdate} />
            </div>
          )}

          {/* ------------------------------------------------ */}
          {/* Category Menu */}
          {/* ------------------------------------------------ */}
          {!this.state.editCategory && (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={this.handleCategoryMenu}
              className={classes.categoryOptions}
            >
              <MoreHorizIcon className={classes.moreIcon} />
            </IconButton>
          )}
          <Menu
            id="category-menu"
            anchorEl={this.state.anchorEl}
            className={classes.categoryMenu}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleCategoryMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{padding: 0}}
            getContentAnchorEl={null}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <ListItem button onClick={this.handleEditCategory}>
              <ListItemIcon id={category} className={classes.listItemIcon}>
                <EditIcon id={category} />
              </ListItemIcon>
              <Typography id={category} variant="body2">{t('todos.editList')}</Typography>
            </ListItem>
            {/* <ListItem button >
              <ListItemIcon className={classes.listItemIcon}>
                <ArchiveIcon />
              </ListItemIcon>
              <Typography variant="body2">Archive List</Typography>
            </ListItem> */}
            <ListItem button onClick={this.handleDeleteCategory}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="body2">{t('todos.deleteList')}</Typography>
            </ListItem>
          </Menu>
        </div>

        {todoCategories[category] 
          && todoCategories[category].tasks.length !== 0 
          && todoCategories[category].tasks.map(task => (
          
          <div key={task}>
          {/* ------------------------------------------------ */}
          {/* List incomplete Todos */}
          {/* ------------------------------------------------ */}
            {todos[task].status === false && (
              <div className={classes.todoItem}>
                <Checkbox
                  onChange={() => this.handleStatusUpdate(task)}
                  id={task}
                  value={todos[task].status}
                  checked={todos[task].status}
                  color="primary"
                />
                <div className={classes.todoTitleHolder} onClick={() => this.handleSelectTodo(task)}>
                  <span className={classes.todoTitle}>{!(_.isEmpty(todos)) && todos[task].title}</span>
                  {todos[task].body && <DescriptionIcon className={classes.descIcon} />}
                  {!isNaN(todos[task].dueDate) && (
                    <span className={classes.dueDate}>
                      <CalendarTodayIcon className={classes.descIcon} />{moment(parseInt(todos[task].dueDate,10)).format('DD MMM')}
                    </span>
                  )}
                  {todos[task].comments.length != 0 ? 
                    <Avatar className={classes.commentCount}>{todos[task].comments.length}</Avatar> : 
                    null
                  }
                  {todos[task].assignedTo && todos[task].assignedTo.map(user => (
                    <div className={classes.userProfile} key={user}>
                      <Avatar 
                        alt={users && users[user] && initials(users[user].name)}
                        src={users && users[user] && users[user].avatar} 
                        className={classes.avatar} 
                      >
                        {users && users[user] && initials(users[user].name)}
                      </Avatar>
                      {users && 
                        users[user] && 
                        this.capitalize(_.first(users[user].name.split(" ")))
                      }
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}

        {/* ------------------------------------------------ */}
        {/* Create New Todo Item */}
        {/* ------------------------------------------------ */}
        <NewTodo categoryID={category} />

        {/* ------------------------------------------------ */}
        {/* List Completed Todos */}
        {/* ------------------------------------------------ */}
        {todoCategories[category] && 
          todoCategories[category].tasks.length !== 0 && 
          todoCategories[category].tasks.map(task => (
          <div key={task}>
            {todos[task].status === true && (
              <div className={classes.todoItem}>
                <Checkbox
                  onChange={() => this.handleStatusUpdate(task)}
                  id={task}
                  value={todos[task].status}
                  checked={todos[task].status}
                  color="primary"
                />
                  <span className={classes.title} onClick={() => this.handleSelectTodo(task)} style={{ fontSize: 12 }}>
                    {!(_.isEmpty(todos)) && todos[task].title}
                  </span>
              </div>
            )}
          </div>
        ))}
        
      </div>
    )
  }
}

Category.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  todoCategories: state.todoCategories,
  todos: state.todos,
  users: state.users
})


export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Category)));