import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { returnCurrent } from '../../redux/selectors/dashboard';
import { setCurrentMaker } from '../../redux/actions/dashboard/dashboard';
import { initials } from '../utils/wordUtils';

import { 
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FrontdeskStaffItem from './FrontdeskStaffItem';

const useStyles = makeStyles (theme => ({
  root: {
    minHeight: 'calc(100vh - 250px)',
    width: '100%'
  },
  row: {
    paddingTop: 5,
    paddingBottom: 5
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    color: '#ffffff',
    fontSize: "1.8rem",
    height: 40,
    lineHeight: 2.2,
    textAlign: 'center',
    width: 40,
    '&img': {
      display: "flex",
      alignItems: "center",
    }
  },
  role: {
    borderRadius: '4px',
    border: '1px solid #dddddd',
    cursor: 'pointer',
    padding: theme.spacing(1)
  },
  editIcon: {
    fontSize: '1.8rem'
  },
  showInactive: {
    color: '#333333',
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginBottom: 15,
    marginTop: 30,
  }
}))


const MakersList = (props) => {
  const { t } = props
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [showInactive, setShowInactive] = React.useState(false);
  const { frontdeskUsers, users } = props;

  return(
    <List className={classes.root}>

      {/* LIST ALL FRONTDESK USERS */}
      {/* --------------------------------------------- */}
      {props.currentSpace && props.currentSpace.frontdeskUsers && props.currentSpace.frontdeskUsers.map(member => (
        (frontdeskUsers[member] && frontdeskUsers[member].isDeleted === false) && 
          <ListItem button key={member} onClick={() => setSelectedUser(member)}>
            <ListItemAvatar>
              <Avatar
                alt={initials(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)}
                className={classes.avatar}
                src={frontdeskUsers[member].avatar} 
              >
                {initials(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)}/>
            {/* <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => setSelectedUser(member)}>
                <EditIcon className={classes.editIcon}/>
              </IconButton>
            </ListItemSecondaryAction> */}
          </ListItem>
      ))}

      {/* LIST ALL MANAGERS */}
      {/* --------------------------------------------- */}
      {/* {props.currentSpace && props.currentSpace.members.map(member => (
        <ListItem button key={member}>
          <ListItemAvatar>
            <Avatar
              alt={initials(users[member].name)}
              className={classes.avatar}
              src={users[member].avatar} 
            >
              {initials(users[member].name)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={users[member].name} secondary={'Manager'} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit">
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))} */}

      {!showInactive && 
        props.currentSpace && 
        props.currentSpace.frontdeskUsers && 
        (props.currentSpace.frontdeskUsers.filter(e => frontdeskUsers[e].isDeleted === true).length > 0) &&
        (
          <div className={classes.showInactive} onClick={() => setShowInactive(true)}>
            {t('frontdesk.frontdeskAccess.showInactiveUsers')}
          </div>
        )
      }

      {showInactive && 
        props.currentSpace && 
        props.currentSpace.frontdeskUsers && 
        (props.currentSpace.frontdeskUsers.filter(e => frontdeskUsers[e].isDeleted === true).length > 0) &&
        (
          <div className={classes.showInactive} onClick={() => setShowInactive(false)}>
            {t('frontdesk.frontdeskAccess.hideInactiveUsers')}
          </div>
        )
      }
      
      {showInactive && 
        props.currentSpace && 
        props.currentSpace.frontdeskUsers && 
        props.currentSpace.frontdeskUsers.map(member => (
          (frontdeskUsers[member].isDeleted === true) && 
            <ListItem button key={member} onClick={() => setSelectedUser(member)}>
              <ListItemAvatar>
                <Avatar
                  alt={initials(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)}
                  className={classes.avatar}
                  src={frontdeskUsers[member].avatar} 
                >
                  {initials(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={(frontdeskUsers[member].firstName + ' ' + frontdeskUsers[member].lastName)} />
              {/* <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => setSelectedUser(member)}>
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </ListItemSecondaryAction> */}
            </ListItem>
          )
        )
      }

      {selectedUser && 
        <FrontdeskStaffItem
          selectedUser={selectedUser}
          handleClose={props.handleClose}
          handleBack={() => setSelectedUser(null)}
        />
      }

    </List>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  users: state.users,
  frontdeskUsers: state.frontdeskUsers
})

export default withTranslation()(connect(mapStateToProps)(MakersList));