import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD OCCUPANCY
//----------------------------------------------------------------
export function fetchPayoutInfo(propertyID, params) {
  const ACTION_NAME = "FETCH_PAYOUT_INFO";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/frontdesk/reports/stripe/payout/${propertyID}`, { params })
        .then(successResult => {
            const { data } = successResult;
            dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            return data;
        })
        .catch(errorResult => {
            console.log('failure', errorResult);
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
            return;
        });
  };
}