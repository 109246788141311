import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { checkDateInBetween, DATE_FORMATS, fetchDateFormat, fetchDatePerTimezone, fetchNoOfNights } from '../../../../utils/utility';
import { Button } from '@material-ui/core';
import ManualSelectBed from './ManualSelectBed';
import { fetchRequiredKeyValue, validateEditReservationPayload } from './Helper';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    buttonContainer: {
        margin: '20px 0'
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
    stepper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    step: {
        backgroundColor: '#657898',
        color: '#ffffff',
        fontWeight: 600,
        padding: 10,
        '&.current': {
            backgroundColor: '#13408d',
        }
    }
}));

const postPoneCheckIn = ({ classes, range, data, view, formType, onSubmitHandler }) => {
    const nights = fetchNoOfNights(data.checkIn, range.startDate);

    const submitDataHandler = () => {
        onSubmitHandler({
            guestNumber: data.guestNumber,
            editDates: {
                checkIn: range.startDate,
                checkOut: data.checkOut,
                formType: formType
            },
        });
    }

    return (
        <React.Fragment>
            {/* -------------------- Shorten accommodation form from check-in side-------------------- */}
            <div className={classes.label}>You are shortening the stay for this guest by {nights || 0} nights,
            new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}</div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={submitDataHandler}>Save</Button>
            </div>
        </React.Fragment>
    )
};

const shortenAccommodation = ({ classes, range, data, view, formType, onSubmitHandler }) => {
    const nights = fetchNoOfNights(data.checkIn, data.checkOut) - fetchNoOfNights(range.startDate, range.endDate);

    const submitDataHandler = () => {
        onSubmitHandler({
            guestNumber: data.guestNumber,
            editDates: {
                checkIn: range.startDate,
                checkOut: range.endDate,
                formType: formType
            },
        });
    }

    return (
        <React.Fragment>
            {/* -------------------- Shorten accommodation form from both side-------------------- */}
            <div className={classes.label}>You are shortening the stay for this guest by {nights || 0} nights,
            new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}
            and new check-out is {fetchDateFormat(range.endDate, 'MMM DD YYYY')}</div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={submitDataHandler}>Save</Button>
            </div>
        </React.Fragment>
    )
};

const prePoneCheckIn = ({ classes, range, data, view, formType, onSubmitHandler }) => {
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setErrorMessage('')
    }, [range])

    const nights = fetchNoOfNights(range.startDate, data.checkIn);
    const endDate = fetchDatePerTimezone(data.checkIn).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE);

    const submitDataHandler = (form) => {
        let requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
        let items = fetchRequiredKeyValue([...form.items], requiredKeys)

        let isValidated = validateEditReservationPayload(items, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }
        onSubmitHandler({
            guestNumber: data.guestNumber,
            editDates: {
                checkIn: range.startDate,
                checkOut: data.checkOut,
                durationRange: [{ startDate: range.startDate, endDate: endDate }],
                bedsPerDate: items,
                formType: formType
            },
        });

    }

    return (
        <React.Fragment>
            {/* -------------------- Pre-pone the accommodation from check-in side-------------------- */}
            <div className={classes.label}>You are extending the stay for this guest by {nights || 0} nights,
        new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}</div>
            <div className={classes.spacing}></div>

            <ManualSelectBed
                view={view}
                data={data}
                errorMessage={errorMessage}
                range={{
                    startDate: range.startDate,
                    endDate: endDate
                }}
                submitDataHandler={submitDataHandler}
            />
        </React.Fragment>
    )
};

const moveAccommodation = ({ classes, range, data, view, formType, lockedReservation, onSubmitHandler }) => {
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setErrorMessage('')
    }, [range])

    const nights = fetchNoOfNights(range.startDate, range.endDate);
    let allowChange = true;
    let newRange = {
        startDate: range.startDate,
        endDate: fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE)
    };

    if (range.startDate < data.checkIn && range.endDate > data.checkIn) {
        newRange.startDate = range.startDate;
        newRange.endDate = fetchDatePerTimezone(data.checkIn).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE);
    }

    if (range.startDate < data.checkOut && range.endDate > data.checkOut) {
        newRange.startDate = data.checkOut;
        newRange.endDate = fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE);
    }

    if (lockedReservation && !(checkDateInBetween(range.startDate, range.endDate, data.checkIn) || checkDateInBetween(range.startDate, range.endDate, data.checkOut) ||
        checkDateInBetween(data.checkIn, data.checkOut, range.startDate) || checkDateInBetween(data.checkIn, data.checkOut, range.endDate))) {
        allowChange = false;
    }

    const submitDataHandler = (form) => {
        let requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
        let items = fetchRequiredKeyValue([...form.items], requiredKeys)

        let isValidated = validateEditReservationPayload(items, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }
        onSubmitHandler({
            guestNumber: data.guestNumber,
            editDates: {
                checkIn: range.startDate,
                checkOut: range.endDate,
                durationRange: [{ startDate: range.startDate, endDate: fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE) }],
                bedsPerDate: items,
                formType: formType
            },
        });

    }

    return (
        <React.Fragment>
            {allowChange && <React.Fragment>
                {/* -------------------- moving the accommodation with different checkin-checkout and nights -------------------- */}
                <div className={classes.label}>The guest is staying for {nights || 0} nights,
                    new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}
                    new check-out is {fetchDateFormat(range.endDate, 'MMM DD YYYY')} </div>
                <div className={classes.spacing}></div>

                <ManualSelectBed
                    view={view}
                    data={data}
                    errorMessage={errorMessage}
                    range={newRange}
                    submitDataHandler={submitDataHandler}
                />
            </React.Fragment>}
            {!allowChange && <div className={classes.label}>You need to unlock the reservation first before making changes</div>}
        </React.Fragment>
    )
};

const extendAccommodation = ({ classes, range, data, view, formType, onSubmitHandler }) => {
    const requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
    const prePoneNights = fetchNoOfNights(range.startDate, data.checkIn);
    const postPoneNights = fetchNoOfNights(data.checkOut, range.endDate);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [items, setItems] = React.useState([]);
    const stepRef = React.useRef()
    
    React.useEffect(() => {
        setErrorMessage('');
        setStep(1)
        setItems([])
    }, [range])

    const submitDataHandler = (form) => {
        let formItems = fetchRequiredKeyValue([...form.items], requiredKeys)
        let isValidated = validateEditReservationPayload(formItems, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }
        if (step === 1) {
            setItems(formItems)
            stepRef.current.scrollIntoView({ behavior: 'smooth' })
            setStep(2);
        }

        if (step === 2) {
            let payloadItems = items;
            payloadItems = payloadItems.concat(formItems)
            onSubmitHandler({
                guestNumber: data.guestNumber,
                editDates: {
                    checkIn: range.startDate,
                    checkOut: range.endDate,
                    durationRange: [
                        { startDate: range.startDate, endDate: fetchDatePerTimezone(data.checkIn).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE) },
                        { startDate: data.checkOut, endDate: fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE) }
                    ],
                    bedsPerDate: [...payloadItems],
                    formType: formType
                },
            });
        }
    }

    return (
        <React.Fragment>
            {/* -------------------- Pre-pone the accommodation from check-in side-------------------- */}
            <div className={classes.label}>You are extending the stay for this guest by {prePoneNights + postPoneNights || 0} nights,
            new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}
            new check-out is {fetchDateFormat(range.endDate, 'MMM DD YYYY')} </div>
            <div className={classes.spacing}></div>

            <div className={classes.stepper} ref={stepRef}>
                <div className={clsx(classes.step, { 'current': step === 1 })}>Step 1: {fetchDateFormat(range.startDate, 'MMM DD YYYY')} - {fetchDateFormat(data.checkIn, 'MMM DD YYYY')}</div>
                <div className={clsx(classes.step, { 'current': step === 2 })}>Step 2: {fetchDateFormat(data.checkOut, 'MMM DD YYYY')} - {fetchDateFormat(range.endDate, 'MMM DD YYYY')}</div>
            </div>

            <ManualSelectBed
                view={view}
                data={data}
                errorMessage={errorMessage}
                buttonText={step === 1 ? 'Next' : null}
                range={step === 1 ? {
                    startDate: range.startDate,
                    endDate: fetchDatePerTimezone(data.checkIn).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE)
                } : {
                    startDate: data.checkOut,
                    endDate: fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE)
                }}
                triggerBuild={step === 2}
                submitDataHandler={submitDataHandler}
            />
        </React.Fragment>
    )
};


const ChangeDates = (props) => {
    const classes = useStyles();
    const { typeOfForm } = props;

    const components = {
        prePoneCheckIn,
        postPoneCheckIn,
        moveAccommodation,
        extendAccommodation,
        shortenAccommodation,
    }

    const SpecificForm = components[typeOfForm];
    return (
        <SpecificForm
            classes={classes}
            formType={typeOfForm}
            {...props}
        />
    )
}

export default ChangeDates;