import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import { MODULE } from "../../../common/constants/permission";
import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  checkBox: {
    marginRight: "5px",
  },
  checkBoxLabel: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
    color: "#666666",
  },
  checkedIcon: {
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 15,
      height: 15,
      borderRadius: 5,
      backgroundSize: "cover",
      background: `url(${IMAGES.ICONS.check}) no-repeat`,
      content: '""',
      margin: "3px",
    },
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 5,
    width: 20,
    height: 20,
  },
  loader: {
    width: "20px !important",
    height: "20px !important",
    marginRight: "5px",
    padding: "9px",
  },
}));

const ModuleItem = ({
  moduleID,
  isChecked,
  updatePermissionHandler,
  isCurrentMakerAdmin,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const hideLoader = () => setIsLoading(false);
  const onChangeHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const status = event.target.checked ? "grant" : "deny";
    updatePermissionHandler(status, { module: moduleID }, hideLoader);
  };

  return (
    <Grid item sm={6} xs={12} className={classes.listItem}>
      {isLoading && <CircularProgress className={classes.loader} />}

      {!isLoading && (
        <Checkbox
          className={classes.checkBox}
          color="primary"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={isCurrentMakerAdmin ? true : isChecked}
          disabled={isCurrentMakerAdmin ? true : false}
          onChange={onChangeHandler}
        />
      )}
      <div className={classes.checkBoxLabel}>{MODULE[moduleID].NAME}</div>
    </Grid>
  );
};

const mapStateToProps = (state, props) => {
  const { accessData = {} } = state.permissions;
  const isChecked = accessData[props.groupID]
    ? accessData[props.groupID].find((data) => data.module == props.moduleID)
    : false;
  return {
    isChecked: Boolean(isChecked),
  };
};

export default connect(mapStateToProps)(ModuleItem);
