import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core';
import NumberInput from '../../common/NumberInput';

const ChangeRateForm = (props) => {
  const { classes, currentSpace, form, t } = props;

  return (
    <>
      <div className={classes.title}>{t('existingReservation.reservationTab.roomType')} | {props.roomTypeName}</div>

      <div className={classes.break}></div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>{t('existingReservation.reservationTab.setPrice')} {props.bedOrRoom}</div>
        <div className={classes.form}>
          <NumberInput
            className={classes.field}
            value={props.amount}
            min={0}
            onChange={(e) => { props.setAmount(e.target.value) }}
            startAdornment={
              <InputAdornment position="start">{currentSpace.currency}</InputAdornment>
            } />
          <FormControlLabel value="rate" style={{ marginTop: 15 }} control={
            <Checkbox
              color="primary"
              checked={props.rateRestOfStay}
              onChange={(e) => { props.setRateRestOfStay(e.target.checked) }}
            />
          } label={t('existingReservation.reservationTab.applyPriceRestStay')} labelPlacement="end" />
        </div>
      </div>
      <div className={classes.action}>
        <Button className={classes.button} onClick={() => props.handleSave()}>{t('actions.save')}</Button>
      </div>
    </>
  )
}

export default withTranslation()(ChangeRateForm)