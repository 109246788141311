import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
// import clsx from 'clsx';
// import parse from 'html-react-parser';

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
// import Snackbar from '@material-ui/core/Snackbar';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Select from "react-select";

import CardCaptureForm from "./CardCaptureForm";
import CardView from "./CardView";

// import { fetchChannexSessionToken, fetchCardDetails } from '../../../../redux/actions/reservation/editReservation';
// import AccessController from '../../../permissions/AccessController';
// import { MODULE } from '../../../../common/constants/permission';
// import IMAGES from '../../../../constants/images';
import { CHANNEL } from "../../../../utils/channels";
import { getReservationCardTokens } from "../../../../redux/actions/beds/beds";

const styles = (theme) => ({
  root: {
    // border: '1px solid',
    // minHeight: 'calc(100% - 100px)',
    // maxHeight: 'calc(100% - 400px)',
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100% - 60px)",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "relative",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#000000",
    lineHeight: "20px",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  container: {
    overflowY: "auto",
    minHeight: "400px",
  },
  section: {
    width: "calc(100% - 290px)",
    margin: "0 auto",
    marginTop: "40px",
    marginBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 40px)",
    },
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  cardSelect: {
    marginBottom: "60px",
  },
  label: {
    color: "#999999",
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 5,
  },

  cardInfoContainer: {
    background: "#8688BC",
    borderRadius: 10,
    color: "#FFFFFF",
    padding: 20,
    // width: 'calc(100% - 40px)',
    // maxWidth: '300px',
    // margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      width: "calc(100% - 40px)",
    },
  },
  loadingCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 135,
    width: "100%",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  logoContainer: {
    display: "flex",
    fontWeight: 600,
    fontSize: "1.6rem",
  },
  cardNumber: {
    marginTop: "20px",
    marginBottom: 20,
    fontWeight: 600,
    fontSize: "1.4rem",
  },
  cardDigit: {
    marginRight: "10px",
  },
  cardRow: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 10,
  },
  cardSection: {
    display: "flex",
    flexDirection: "column",
  },
  cardLabel: {
    fontSize: "1.1rem",
    marginBottom: 3,
  },
  cardName: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  cardExpiry: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  viewIcon: {
    cursor: "pointer",
    height: 20,
    width: 20,
  },
  cardSelectHeader: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addCard: {
    cursor: "pointer",
    color: "#484848",
    fontSize: "1.2rem",
    marginBottom: 5,
  },
  blankCard: {
    color: "#999999",
    display: "flex",
    fontSize: "1.5rem",
    textAlign: "center",
    flexDirection: "column",
    minHeight: "315px",
    justifyContent: "center",
    "& span": {
      marginBottom: 10,
    },
  },
  link: {
    color: "blue",
    cursor: "pointer",
    display: "inline !important",
  },
  noCardDisplay: {},
  infoRow: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: 10,
    display: "flex",
    padding: 30,
    width: "calc(100% - 60px)",
    marginBottom: "20px",
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.4,
    marginLeft: 20,
    fontWeight: "bold",
  },
});

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    borderRadius: 5,
    boxShadow: "0 2px 10px 0px #E0E0E0",
    paddingLeft: 8,
    // This line disable the blue border
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const isCardValid = (cardNumber) => String(cardNumber).match(/^[0-9*]{16}$/);

class ViewCardModal extends Component {
  state = {
    selectedCardToken: null,
    // isCardFetchLoading: false,
    // cardData: null,
    showAddCardModal: false,
  };

  componentDidMount() {
    const { maskedCards, bookingSource, reservationID, dispatch } = this.props;
    if (maskedCards && maskedCards.length > 0)
      this.setState({ selectedCardToken: maskedCards[0].card_token });

    // fetching card if invalid card or no card found
    if (bookingSource?.toUpperCase() !== CHANNEL.Hostelworld.code.toUpperCase())
      return;
    if (maskedCards?.length < 1)
      return dispatch(getReservationCardTokens(reservationID));
    if (maskedCards.find((mc) => isCardValid(mc.card_number))) return;

    dispatch(getReservationCardTokens(reservationID));
  }

  //* --------------------------------------------------------------------- *//
  //* FETCH CARD FROM CHANNEX */
  //* --------------------------------------------------------------------- *//
  // fetchCardDetailHandler = (channexCardToken) => {

  //     this.setState({ isCardFetchLoading: true });

  //     const tokenResponse = fetchChannexSessionToken();
  //     const tokenResponse2 = fetchChannexSessionToken('service_code');

  //     Promise.all([tokenResponse, tokenResponse2]).then(response =>{
  //         console.log('Promise.all======', response);
  //         let params = { card_token: channexCardToken };
  //         const [sessionTokenRes, serviceCodeRes] = response;

  //         if(sessionTokenRes.status == 200){
  //             params.session_token = sessionTokenRes?.data?.session_token || '';
  //         }

  //         if(serviceCodeRes.status == 200){
  //             params.service_code_token = serviceCodeRes?.data?.session_token || '';
  //         }

  //         const cardResponse = fetchCardDetails(params);
  //         cardResponse.then(successResult => {

  //             this.setState({ isCardFetchLoading: false });

  //             let parser = new DOMParser();
  //             let parsedHtml = parser.parseFromString(successResult.data, 'text/html');
  //             const cardDetails = {
  //                 cardType: parsedHtml.getElementsByClassName("card__field--card-type")[0].children[1].innerHTML.trim(),
  //                 cardNumber: parsedHtml.getElementsByClassName("card__field--card-number")[0].children[1].innerHTML.trim(),
  //                 cardName: parsedHtml.getElementsByClassName("card__field--cardholder-name")[0].children[1].innerHTML.trim(),
  //                 cardExpiry: parsedHtml.getElementsByClassName("card__field--expiration-date")[0].children[1].innerHTML.trim(),
  //                 cardCvv: parsedHtml.getElementsByClassName("card__field card__field--service-code")[0].children[1].innerHTML.trim(),
  //             }
  //             console.log('cardDetails ', cardDetails);

  //             const parsedCVVHtml = parser.parseFromString(cardDetails.cardCvv, 'text/html');
  //             console.log('isRemoved', parsedCVVHtml.getElementsByClassName('card__service-code-removed').length);
  //             console.log('showCard', parsedCVVHtml.getElementsByClassName('card__show-service-code').length);

  //             this.setState({ cardData: { ...cardDetails } });
  //         })
  //         .catch(errorResult => {
  //             this.setState({ isCardFetchLoading: false });
  //             console.log(errorResult);
  //         });
  //     })
  //     .catch(errorResult => {
  //         this.setState({ isCardFetchLoading: false });
  //         console.log(errorResult);
  //     });

  // tokenResponse.then(successResult => {
  //     const { session_token } = successResult.data;
  //     if (session_token) {

  //         const params = {
  //             card_token: channexCardToken,
  //             session_token
  //         };

  //         const cardResponse = fetchCardDetails(params);
  //         cardResponse.then(successResult => {

  //             this.setState({ isCardFetchLoading: false });

  //             let parser = new DOMParser();
  //             let parsedHtml = parser.parseFromString(successResult.data, 'text/html');
  //             const cardDetails = {
  //                 cardType: parsedHtml.getElementsByClassName("card__field--card-type")[0].children[1].innerHTML.trim(),
  //                 cardNumber: parsedHtml.getElementsByClassName("card__field--card-number")[0].children[1].innerHTML.trim(),
  //                 cardName: parsedHtml.getElementsByClassName("card__field--cardholder-name")[0].children[1].innerHTML.trim(),
  //                 cardExpiry: parsedHtml.getElementsByClassName("card__field--expiration-date")[0].children[1].innerHTML.trim(),
  //             }
  //             // console.log('cardDetails ', cardDetails);
  //             this.setState({ cardData: { ...cardDetails } });
  //         })
  //         .catch(errorResult => {
  //             this.setState({ isCardFetchLoading: false });
  //             console.log(errorResult);
  //         });
  //     }

  // }).catch(errorResult => {
  //     this.setState({ isCardFetchLoading: false });
  //     console.log(errorResult);
  // });
  // }

  // displayCardInfo = () => {

  //     const {
  //         classes,
  //         maskedCards,
  //         currentSpace,
  //         currentUser
  //     } = this.props;

  //     const {
  //         selectedCardToken,
  //         isCardFetchLoading,
  //         cardData
  //     } = this.state;

  //     let cardDisplay = null;
  //     const admin = currentSpace && currentSpace.admins.includes(currentUser._id);

  //     if (maskedCards.length == 1) {
  //         cardDisplay = maskedCards[0];
  //     }

  //     if (maskedCards.length > 1 && selectedCardToken) {
  //         cardDisplay = maskedCards.find(cardData => cardData.card_token == selectedCardToken);
  //     }

  //     if (!cardDisplay) return;
  //     const cardType = cardData?.cardType || (cardDisplay?.card_type?.charAt(0).toUpperCase() + cardDisplay?.card_type?.slice(1)) || ' '
  //     const isCardInvalid = !isCardValid(cardDisplay.card_number)
  //     return (
  //         <>
  //         {isCardInvalid && (
  //             <div className={clsx('row', classes.marginTop)}>
  //             <div className={classes.infoRow}>
  //               <div>
  //                 <WarningRoundedIcon color='error' fontSize='large' />
  //               </div>
  //               <span className={classes.helperText}>
  //                 {'Invalid Card, please report using chat bubble in '}
  //                 <a className={classes.link} onClick={() => window.location.pathname = '/beds/calendar'}>calendar</a>
  //               </span>
  //             </div>
  //           </div>
  //         )}
  //         <div className={classes.cardInfoContainer}>

  //             {isCardFetchLoading && <div className={classes.loadingCard}><CircularProgress /></div>}

  //             {!isCardFetchLoading && <div>
  //                 <div className={classes.cardRow}>
  //                     <div className={classes.logoContainer}>
  //                         <div className={classes.cardValue}>
  //                             {cardType}
  //                         </div>
  //                     </div>

  //                     {!cardData &&
  //                         <AccessController requiredPermission={MODULE.VIEW_CARD.ID}>
  //                             <img
  //                                 src={IMAGES.ICONS.view}
  //                                 onClick={() => this.fetchCardDetailHandler(selectedCardToken)}
  //                                 className={classes.viewIcon}
  //                             />
  //                         </AccessController>
  //                     }

  //                     {cardData && <img
  //                         src={IMAGES.ICONS.visibilityHidden}
  //                         onClick={() => this.setState({ cardData: null })}
  //                         className={classes.viewIcon}
  //                     />}
  //                 </div>

  //                 <div className={classes.cardNumber}>
  //                     {cardData && parse(cardData.cardNumber)}
  //                     {!cardData && cardDisplay.card_number.match(/.{1,4}/g).map(card => {
  //                         return (
  //                             <span key={Math.random()} className={classes.cardDigit}>{card}</span>
  //                         )
  //                     })}
  //                 </div>

  //                 <div className={classes.cardRow}>
  //                     <div className={classes.cardSection}>
  //                         <div className={classes.cardLabel}>Name</div>
  //                         <div className={classes.cardName}>{cardData ? cardData.cardName : cardDisplay.cardholder_name}</div>
  //                     </div>
  //                     <div className={classes.cardSection}>
  //                         <div className={classes.cardLabel}>Expiry</div>
  //                         <div className={classes.cardExpiry}>
  //                             {cardData ? cardData.cardExpiry : `${cardDisplay.expiration_month}/${cardDisplay.expiration_year.slice(2)}`}
  //                         </div>
  //                     </div>

  //                 </div>
  //             </div>}
  //         </div>
  //         </>
  //     )
  // }

  cardSelectHandler = (cardNumber) => {
    this.setState({
      selectedCardToken: cardNumber.value,
      // cardData: null
    });
  };

  closeModalHander = () => {
    const { showAddCardModal } = this.state;
    const { closeModalHander } = this.props;

    if (showAddCardModal) {
      this.setState({ showAddCardModal: false });
      return;
    }

    closeModalHander();
  };

  render() {
    const { classes, isLoading, maskedCards, reservationID, isCardAdded } =
      this.props;

    const { selectedCardToken, showAddCardModal } = this.state;
    const cardNumberOptions = maskedCards.map((cardData) => ({
      value: cardData.card_token,
      label: cardData.card_number,
    }));

    return (
      <Dialog
        open={true}
        classes={{ paperFullWidth: classes.root }}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={window.innerWidth < 901}
      >
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {showAddCardModal ? "Add Card" : "View Card"}
          </div>
          <CloseIcon
            className={classes.closeIcon}
            onClick={this.closeModalHander}
          />
        </div>

        <div className={classes.container}>
          {!showAddCardModal && (
            <div className={classes.section}>
              {/* ..............Circular Loader...................... */}
              {isLoading && (
                <div className={classes.loadingState}>
                  <CircularProgress className={classes.loader} />
                </div>
              )}

              <div className={classes.cardSelect}>
                {/* ---------- HAS NO CARDS ADDED TO RESERVATION --------------- */}
                {!isCardAdded && (
                  <div className={classes.noCardDisplay}>
                    <div className={classes.blankCard}>
                      <span>No card tagged to this reservation.</span>
                      <span
                        className={classes.link}
                        onClick={() =>
                          this.setState({ showAddCardModal: true })
                        }
                      >
                        + Add New Card
                      </span>
                    </div>
                  </div>
                )}

                {/* ---------- HAS CARDS ADDED TO RESERVATION --------------- */}
                {isCardAdded && (
                  <>
                    <div className={classes.cardSelectHeader}>
                      <div
                        className={classes.addCard}
                        onClick={() =>
                          this.setState({ showAddCardModal: true })
                        }
                      >
                        + Add New Card
                      </div>
                    </div>

                    <div className={classes.label}>Select card number</div>
                    <Select
                      value={cardNumberOptions.find(
                        (e) => e.value === selectedCardToken
                      )}
                      name="cardNumber"
                      onChange={this.cardSelectHandler}
                      options={cardNumberOptions}
                      styles={selectStyle}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </>
                )}
              </div>

              {/* {this.displayCardInfo()} */}
              <CardView
                maskedCards={maskedCards}
                selectedCardToken={selectedCardToken}
              />
            </div>
          )}

          {showAddCardModal && (
            <CardCaptureForm
              reservationID={reservationID}
              closeModalHander={this.closeModalHander}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, spaces, dashboard, loading, reservationDetails } = state;

  return {
    currentUser: auth.user,
    currentSpace: spaces[dashboard.currentSpace],
    maskedCards: reservationDetails.maskedCards,
    reservationID: reservationDetails?.reservation?._id,
    bookingSource: reservationDetails?.reservation?.bookingSource,
    isLoading: loading.FETCH_CHANNEX_TOKEN || loading.CHANNEX_CAPTURE_CARD,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(ViewCardModal));
