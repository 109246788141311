import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import LinearProgress from '@material-ui/core/LinearProgress';
import LiveReservations from '../../live/LiveReservations';

const useStyles = makeStyles(theme =>({
    root:{
        // boxShadow: '0 2px 20px #E0E0E0',
        background: '#F8F9FB',
        borderRadius: '10px',
        cursor: 'pointer',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '10px',
        padding: '20px 20px 10px 20px',
    },
    infoContainer:{
        display: 'flex',
    },
    nuemricTitle: {
        fontSize: '1.6rem',
        fontWeight: 600,
        color: '#bcb9b9',
        paddingRight: '5px'
    },
    infoTitle: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#bcb9b9',
        paddingRight: '5px',
        lineHeight: '18px',
    },
    reservationInfo:{
        display: 'flex',
        marginTop: '10px',
        '& $infoContainer':{
            padding: '5px 15px'
        },
        '& $infoContainer:first-child':{
            borderRight: '1px solid #dedede'
        }
    },  
    progressBar:{
        '&.MuiLinearProgress-root':{
            height: '15px',
            borderRadius: 5,
        },
        '&.MuiLinearProgress-colorPrimary':{
            backgroundColor: '#fff',
            // boxShadow: '0 3px 4px 0px #ECECEC',
            borderRadius: 5,
        },
        '& .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#E4ACA9',
            borderRadius: '20px',
            borderRadius: 5,
        },
        '&.yellow .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#F5DB8C',
            borderRadius: 5,
        },
        '&.brown .MuiLinearProgress-barColorPrimary':{
            backgroundColor: '#BEE1ED',
            borderRadius: 5,
        },
    }
}))

const ProgressInfoCard = ({
    progressBarColor = null,
    titleSection1 = '',
    titleSection2 = '',
    valueSection1 = 0,
    valueSection2 = 0,
    progressVal = 0,
    reservationStatus,
    today
}) =>{
    const classes = useStyles();
    const [openReport, setOpenReport] = React.useState(false);
    
    return(
        <>
            <div className={classes.root} onClick={() => setOpenReport(true)}>
                <LinearProgress 
                    variant="determinate" 
                    className={clsx(classes.progressBar, progressBarColor)} 
                    value={progressVal} 
                />
                <div className={classes.reservationInfo}>
                    <div className={classes.infoContainer}>
                        <div className={classes.nuemricTitle}>{valueSection1}</div>
                        <div className={classes.infoTitle}>{titleSection1}</div>
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.nuemricTitle}>{valueSection2}</div>
                        <div className={classes.infoTitle}>{titleSection2}</div>
                    </div>
                </div>

            </div>

            {openReport && <LiveReservations today={today} open={openReport} handleClose={() => setOpenReport(false)} preset={reservationStatus} />}
        </>
    )
}

export default ProgressInfoCard;