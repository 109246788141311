const defaultEmail = {};

export default (state = defaultEmail, action) => {
  switch (action.type) {
    case "LOAD_EMAIL_SUCCESS":
      return {
        ...action.payload
      };
    case "EDIT_EMAIL_SUCCESS":
      return {
        ...action.payload
      }

    case "EMAIL_TAG_SUCCESS":
      const thisState = { ...state }
      let tempData = []
      if (action.payload.data) {
        if(thisState.tags) {
          tempData = [ ...thisState.tags ]
          tempData.push(action.payload.data._id)
        }
        else {
          tempData = [ action.payload.data._id ]
        }
        return {
          ...state,
          tags: tempData
        }
      } else return { ...state };

    case "RESET_EMAIL":
      return {
        ...defaultEmail
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};