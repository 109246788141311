const FILTERS = {
    startDate: 'startDate',
    endDate: 'endDate',
    buildingID: 'buildingID',
    floor: 'floor',
    sort: 'sort',
    ratePlanID: 'ratePlanID'
}
  
const INIT_FILTERS = {
    [FILTERS.startDate]: null,
    [FILTERS.endDate]: null,
    [FILTERS.buildingID]: '',
    [FILTERS.floor]: '',
    [FILTERS.sort]: '',
    [FILTERS.ratePlanID]: ''
}

const SORT_OPTIONS = [
    { label: 'A-Z', value: 'a-z' },
    { label: 'Z-A', value: 'z-a' },
];

const ERROR_MSG = {
    ratePlanID: 'Please select a rate plan to proceed',
    bookingReview: 'Some of the selected beds are not available for the current rate plan. Either change rate plan or remove the beds from selection.',
    preBooking: 'Some of the selected beds have been occupied. Please unselect the preoccupied beds to proceed',
    noBedsSelected: 'Please select a bed to proceed',
    disableSelection: 'This bed is not available for the selected rate plan. Click to unselect',
    isPreOccupied: 'This bed has been occupied. Click to unselect'
}

export {
    FILTERS,
    INIT_FILTERS,
    SORT_OPTIONS,
    ERROR_MSG
}