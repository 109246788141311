import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchChannelStatus } from "../../redux/actions/channelMapping/channelMapping";
import { resetError } from "../../redux/actions/reservation/editReservation";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import ChannelMapping from "./ChannelMapping";
import ChannelSetup from "./ChannelSetup";

import {
  Button,
  Snackbar,
  makeStyles,
  CircularProgress,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { setUpgradeAccount } from "../../redux/actions/dashboard/dashboard";
import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialog: {
    overflowY: "scroll",
    background: "#ffffff",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: "#ffffff",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  root: {
    minHeight: "calc(100% - 30px)",
    width: "100%",
    background: "#ffffff",
  },
  loadingState: {
    width: "100%",
    height: "calc(100vh - 100px)",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  primaryHolder: {
    display: "flex",
    alignItems: "center",
  },
  bubble: {
    display: "inline-block",
    marginRight: 20,
    borderRadius: "50%",
    height: 15,
    width: 15,
  },
  grey: {
    background: "#bdbdbd",
  },
  green: {
    background: "green",
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    margin: 30,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 30,
  },
  button: {
    boxShadow: "0 2px 20px #E0E0E0",
    border: "none",
    borderRadius: 10,
  },
  plus: {
    background: "#C4ADA2",
    boxShadow: "none",
    color: "#ffffff",
    "&:hover": {
      background: "#996666",
    },
  },
  numbers: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  statusText: {
    fontSize: "1.5rem",
    fontWeight: 600,
    width: "66px",
    textAlign: "center",
  },
}));

const channels = [
  { name: "Hostelworld", code: "Hostelworld", type: "free", automated: "yes" },
  { name: "myAllocator", code: "myAllocator", type: "plus", automated: "yes" },
  { name: "Siteminder", code: "Siteminder", type: "plus", automated: "no" },
  { name: "WuBook", code: "WuBook", type: "plus", automated: "no" },
  { name: "Booking.com", code: "BookingCom", type: "plus", automated: "wip" },
  { name: "Airbnb", code: "AirBNB", type: "plus", automated: "wip" },
  { name: "Expedia", code: "Expedia", type: "plus", automated: "wip" },
  { name: "Agoda", code: "Agoda", type: "plus", automated: "wip" },
];

const activeSort = (channels, channelMapping) => {
  channels.sort((a, b) => {
    const a1 = Object.values(channelMapping).find((e) => e.name === a.code);
    const b1 = Object.values(channelMapping).find((e) => e.name === b.code);
    if (a1 && a1.status === "true" && b1 && b1.status === "true") return 0;
    if (a1 && a1.status === "true") return -1;
    if (b1 && b1.status === "true") return 1;
    return 0;
  });

  return channels;
};

const ChannelListTab = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { currentSpace, channelMapping, loading, channelRefresh } = props;
  const [loader, setLoader] = useState(false);
  const [channelOpen, setChannelOpen] = useState(false);
  const [channelType, setChannelType] = useState(null);
  const [channelSetup, setChannelSetup] = useState(false);
  const [channelAutomation, setChannelAutomation] = useState("");

  useEffect(() => {
    if (currentSpace) {
      props.dispatch(fetchChannelStatus(currentSpace.propertyID));
    }
  }, []);

  const handleCloseError = () => {
    props.dispatch(resetError("FETCH_CHANNEL_STATUS"));
  };

  const currentChannel = (item) => {
    return Object.values(channelMapping).find((e) => e.name === item.code);
  };

  const handleRowClick = (value) => {
    if (!currentChannel(value)) return;
    if (currentChannel(value).status === "true") {
      setChannelOpen(true);
      setChannelType(value.code);
    }
  };

  const handlePlusClick = () => {
    props.handleClose();
    props.dispatch(setUpgradeAccount(true));
  };

  const handleChannelSetup = (value) => {
    setChannelType(value.code);
    setChannelAutomation(value.automated);
    setChannelSetup(true);
  };
  const handleClose = (value) => {
    setChannelSetup(false);
  };

  return (
    <>
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("beds.channelsSnapshot.name")}
          </div>
          <AccessHeaderAction moduleID={MODULE.CHANNELS.ID} />
        </div>
        <div>
          {(loading || loader) && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}
          <div className={classes.firstRow}>
            <div>
              <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
            </div>
            <div className={classes.helperText}>
              {t("beds.channelsSnapshot.helpText")}
            </div>
          </div>
          <div className={classes.root}>
            <List>
              {activeSort(channels, channelMapping).map((item) => {
                if (
                  currentSpace._id == "5ffcf937bdc6500ec55c74c8" &&
                  item.code == "Siteminder"
                ) {
                  return (
                    <ListItem
                      key={item.code}
                      button={
                        currentChannel(item) &&
                        currentChannel(item).status === "true"
                      }
                      onClick={() => handleRowClick(item)}
                      className={classes.listItem}
                    >
                      <ListItemText
                        primary={
                          <Fragment>
                            <div className={classes.primaryHolder}>
                              <span
                                className={clsx(classes.green, classes.bubble)}
                              ></span>
                              {item.name}
                            </div>
                          </Fragment>
                        }
                      />
                      <div className={classes.statusText}>Live</div>
                    </ListItem>
                  );
                }
                const isChannelActive =
                  currentChannel(item) &&
                  currentChannel(item).status === "true";
                return (
                  <ListItem
                    key={item.code}
                    button={
                      currentChannel(item) &&
                      currentChannel(item).status === "true"
                    }
                    onClick={() => handleRowClick(item)}
                    className={classes.listItem}
                  >
                    <ListItemText
                      primary={
                        <Fragment>
                          <div className={classes.primaryHolder}>
                            {isChannelActive ? (
                              <span
                                className={clsx(classes.green, classes.bubble)}
                              ></span>
                            ) : (
                              <span
                                className={clsx(classes.grey, classes.bubble)}
                              ></span>
                            )}
                            {item.name}
                          </div>
                        </Fragment>
                      }
                    />
                    <ListItemSecondaryAction>
                      {!isChannelActive &&
                        item.type === "plus" &&
                        currentSpace.onboardingStatus !== "plus" &&
                        currentSpace.onboardingStatus !== "pro" && (
                          <Button
                            variant="outlined"
                            className={clsx(classes.button, classes.plus)}
                            onClick={handlePlusClick}
                          >
                            Upgrade to Plus
                          </Button>
                        )}
                      {((!currentChannel(item) &&
                        (item.type == "free" ||
                          currentSpace.onboardingStatus === "plus" ||
                          currentSpace.onboardingStatus === "pro")) ||
                        (currentChannel(item) &&
                          item.type == "free" &&
                          !isChannelActive) ||
                        (currentChannel(item) &&
                          item.type == "plus" &&
                          !isChannelActive &&
                          (currentSpace.onboardingStatus === "plus" ||
                            currentSpace.onboardingStatus === "pro"))) && (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => handleChannelSetup(item)}
                        >
                          {t("beds.channelsSnapshot.setup")}
                        </Button>
                      )}
                      {currentChannel(item) && isChannelActive && (
                        <div className={classes.numbers}>{`${
                          currentChannel(item).mappedRoomCount
                        } / ${currentChannel(item).totalRoomCount}`}</div>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      </div>
      {channelOpen && (
        <ChannelMapping
          open={channelOpen}
          type={channelType}
          handleClose={() => {
            setChannelType(null);
            setChannelOpen(false);
          }}
          handleCloseAll={handleClose}
        />
      )}
      {channelSetup && (
        <ChannelSetup
          open={channelSetup}
          type={channelType}
          automation={channelAutomation}
          handleClose={() => {
            setChannelType(null);
            setChannelSetup(false);
            setChannelAutomation("");
          }}
          handleCloseAll={handleClose}
        />
      )}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  channelMapping:
    (state.channelMapping && state.channelMapping.channelList) || {},
  loading: state.loading.FETCH_CHANNEL_STATUS || state.loading.SETUP_CHANNEL,
  errors: state.errors.FETCH_CHANNEL_STATUS,
});

export default withTranslation()(connect(mapStateToProps)(ChannelListTab));
