import http from '../../utils/http';

export function fetchKioskSettings(propertyID) {
  const ACTION_NAME = "FETCH_KIOSK_SETTINGS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/kiosk/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function saveKioskSettings(propertyID, data) {
    const ACTION_NAME = "SAVE_KIOSK_SETTINGS";
    return function (dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .patch(`/api/frontdesk/kiosk/${propertyID}`, data)
        .then(successResult => {
        //   const data = successResult.data;
        //   console.log(successResult.data)
          dispatch({ type: `${ACTION_NAME}_SUCCESS` });
          return true;
        })
        .catch(errorResult => {
          console.log(errorResult);
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
          return;
        });
    };
}