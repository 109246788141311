import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex'
  },
  column: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    alignItems: 'center',
    color: '#484848',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.1rem',
    fontWeight: 600,
    overflow: 'hidden',
    paddingLeft: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subTitle: {
    color: '#484848',
    fontSize: '1.1rem',
    overflow: 'hidden',
    paddingLeft: 25,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expand: {
    color: '#666666',
    fontSize: '1.6rem',
    marginLeft: 5
  },
  bubble: {
    alignItems: 'center',
    borderRadius: 5,
    color: '#999999',
    display: 'flex',
    fontSize: '1.1rem',
    height: 15,
    justifyContent: 'center',
    padding: 6,
    width: 20,
  },
  semiHighlight: {
    fontWeight: 600,
    color: '#666666',
  },
  highlight: {
    fontWeight: 600,
    color: '#CC6666',
  },
  icon: {
    fontSize: '1.6rem',
  },
  infoIcon: {
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginLeft: 10,
    opacity: 0.5
  }
}))

const findOccupancy = (occupancy, date, roomCategory) => {
  let foundDate = null;
  if (roomCategory !== ''){
    foundDate = occupancy.roomCategoryOccupancy.find(e => e.dormOrPrivate==roomCategory && date.isSame(e.date, 'day'));
  } else foundDate = occupancy.totalOccupancy.find(e => date.isSame(e.date, 'day'));
  if (foundDate) {
    return foundDate.occupancy*100
  } else return 0
}

function OccupancyRow (props) {
  let display = []; 
  const {roomCategory='', numberOfDays, startDate, occupancy, classes} = props;
  const width = (100 / numberOfDays);
  for (let i = 0; i < numberOfDays; i++) {
    let tempDate = startDate.clone().add(i, 'days');
    const occupancyItem = findOccupancy(occupancy, tempDate, roomCategory) || 0;
    display.push(
      <div className={classes.column} key={i} style={{ width: `${width}%` }}>
        <div className={clsx({[classes.bubble]: true, [classes.semiHighlight]: occupancyItem>80, [classes.highlight]: occupancyItem>90})} >
          {`${parseFloat(occupancyItem).toFixed(0)}%`}
        </div>
      </div>
    )
  }
  return display;
}

const OccupancyByDate = (props) => {
  const classes = useStyles();

  const { currentSpace, startDate, numberOfDays,loadingOccupancy, occupancy, t } = props;
  const [collapse, setCollapse] = React.useState(true);

  const toggleCollapse = () => {
    if (collapse) setCollapse(false);
    else setCollapse(true);
  }

  return (
    <div className={classes.root}>

      {/* TOTAL OCCUPANCY */}
      {/* ------------------------------------------------------------ */}
      <Grid container className={classes.container} alignItems="center">
        <Grid item xs={4} sm={2}>
          <div className={classes.title} onClick={toggleCollapse}>
            {t('reports.occupancy')}
            {collapse && <ExpandMoreIcon className={classes.expand}/>}
            {!collapse && <ExpandLessIcon className={classes.expand}/>}
          </div>
        </Grid>

        {!loadingOccupancy && occupancy && occupancy.currentYear && <Grid item xs={8} sm={10} className={classes.row}>
          <OccupancyRow 
            numberOfDays={numberOfDays}
            startDate={startDate}
            classes={classes}
            occupancy={occupancy.currentYear}
          />
        </Grid>}
      </Grid>

      {/* PRIVATES OCCUPANCY */}
      {/* ------------------------------------------------------------ */}
      {!collapse && <Grid container className={classes.container} alignItems="center">
        <Grid item xs={4} sm={2}>
          <div className={classes.subTitle}>
            {t('beds.ratesSnapshot.bulkRateUpdate.privateRooms')}
          </div>
        </Grid>

        {!loadingOccupancy && occupancy && occupancy.currentYear && <Grid item xs={8} sm={10} className={classes.row}>
          <OccupancyRow 
            roomCategory="private"
            numberOfDays={numberOfDays}
            startDate={startDate}
            classes={classes}
            occupancy={occupancy.currentYear}
          />
        </Grid>}
      </Grid>}

      {/* SHARED OCCUPANCY */}
      {/* ------------------------------------------------------------ */}
      {!collapse && <Grid container className={classes.container} alignItems="center">
        <Grid item xs={4} sm={2}>
          <div className={classes.subTitle}>
            {t('beds.ratesSnapshot.bulkRateUpdate.sharedRooms')}
          </div>
        </Grid>

        {!loadingOccupancy && occupancy && occupancy.currentYear && <Grid item xs={8} sm={10} className={classes.row}>
          <OccupancyRow 
            roomCategory="dorm"
            numberOfDays={numberOfDays}
            startDate={startDate}
            classes={classes}
            occupancy={occupancy.currentYear}
          />
        </Grid>}
      </Grid>}

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  occupancy: state.reports.occupancy,
  loadingOccupancy: state.loading.LOAD_OCCUPANCY_REPORT
})

export default withTranslation()(connect(mapStateToProps)(OccupancyByDate));