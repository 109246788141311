import React from "react";
import { makeStyles } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
  },
  textBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    width: "80%",
    marginBottom: 30,
    marginTop: 30,
  },
  subTitle: {
    fontSize: "1.3rem",
    fontWeight: 600,
    color: "#AAAAAA",
    lineHeight: 1.5,
    marginBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: "center",
  },
  button: {
    background: "#FFFFFF",
    border: "1px solid #996666",
    borderRadius: 5,
    color: "#996666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "center",
    marginBottom: 30,
    fontWeight: 600,
    padding: "12px 16px",
    minWidth: "80px",
  },
  image: {
    height: "100%",
    minHeight: "300px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.up("lg")]: {
      minHeight: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 0,
      height: "auto",
    },
  },
}));

const GetStarted = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12} md={4} className={classes.textBox}>
          <Typography className={classes.title} variant="h3">
            Grow your business beyond beds
          </Typography>
          <div className={classes.subTitle}>
            Take your customer experience to the next level with a POS that
            works seamlessly with your PMS
          </div>
          <div className={classes.button} onClick={props.handleClick}>
            {props.title}
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <img className={classes.image} src={IMAGES.ILLUSTRATIONS.pos} />
        </Grid>
      </Grid>
    </div>
  );
};

export default GetStarted;
