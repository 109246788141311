import React, { useState, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';

import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { SingleDatePicker } from 'react-dates';

import { BOOKING_SOURCE } from '../../../utils/constants';
import { fetchDateFormat, fetchDatePerTimezone, DATE_FORMATS, fetchNightCount } from '../../../utils/utility';

import PlusMinus from '../../common/PlusMinus';
// import { ICONS } from '../../../utils/imageUrls';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 6
  },
  section: {

  },
  sectionTitle: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  blocks: {

  },
  blockItems: {
    cursor: 'pointer',
    display: 'flex',
    padding: '24px',
    fontSize: '1.3rem',
    background: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 20
    }
  },
  blockIcon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20
    }
  },
  blockLabel: {
    marginTop: 20
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 20
  },
  button: {
    background: '#fff',
    border: 'none',
    borderRadius: 5,
    minHeight: 50,
    padding: 0,
    minWidth: 50,
    width: 'calc(100% - 10px)',
    marginRight: 10,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0
    },
  },
  selected: {
    background: '#E0E0E0',
  },
  radioContainer: {
    color: '#666666',
    marginBottom: 10,
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    '& span':{
      fontSize: '1.3rem',
      padding: 0,
      paddingRight: 5
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
  new: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  bookingSource: {
    width: '50%'
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  roundbutton: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '1.8rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  checkOut: {
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  centerAlign: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const sourceList = [
  { value: BOOKING_SOURCE.PHONE, label: BOOKING_SOURCE.PHONE, img: IMAGES.ICONS.darkCall },
  { value: BOOKING_SOURCE.EMAIL, label: BOOKING_SOURCE.EMAIL, img: IMAGES.ICONS.darkEmail },
  { value: BOOKING_SOURCE.WALKIN, label: BOOKING_SOURCE.WALKIN, img: IMAGES.ICONS.darkFootprint },
  { value: BOOKING_SOURCE.OTHER, label: BOOKING_SOURCE.OTHER, img: IMAGES.ICONS.darkMore },
]

const BasicDetails = forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, prefilledData, bookingSource, t } = props;
  const [checkIn, setCheckin] = useState(null);
  const [checkOut, setCheckout] = useState(null);
  const [focused, setFocused] = useState(null);
  const [focusedCheckout, setFocusedCheckout] = useState(null);
  const [nights, setNights] = useState(null);
  const [source, setSource] = useState(null);
  const [baseSource, setBaseSource] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return {
        propertyID: currentSpace.propertyID,
        bookingSource: source,
        nights: nights > 0 ? nights : fetchNightCount(checkIn, checkOut),
        checkIn: fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE),
        checkOut: fetchDateFormat(checkOut, DATE_FORMATS.DEFAULT_DATE),
      }
    }
  }))

  React.useEffect(() => {
    if(prefilledData.checkIn) setCheckin(fetchDatePerTimezone(prefilledData.checkIn))
    if(prefilledData.nights) setNights(prefilledData.nights);
    if(prefilledData.checkOut) setCheckout(fetchDatePerTimezone(prefilledData.checkOut));
    if(prefilledData.bookingSource) setSource(prefilledData.bookingSource)
  }, [prefilledData])

  const onNightChange = (value, id) => { 
    setNights(value) 
    setCheckout(fetchDatePerTimezone(checkIn).add(value, 'days'))
  }
  const onDateChange = (checkInDate, checkOutDate) => {
    if (checkInDate !== checkIn) setCheckin(checkInDate);
    if (checkOutDate !== checkOut) setCheckout(checkOutDate);
    if (checkInDate && checkOutDate) {
      setNights(fetchNightCount(checkInDate, checkOutDate))
    } else {
      setNights(0)
    }
  }

  const handleSetSource = (value) => {
    if (value === BOOKING_SOURCE.OTHER) {
      setBaseSource(true);
      setSource(null); 
    }
    else { 
      setBaseSource(false);
      setSource(value); 
    }
  }

  const handleNewBookingSource = () => {
    props.createBookingSource(source);
    setIsNew(false);
  }

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <img className={classes.icon} src={IMAGES.ICONS.darkCalendar} />
          <span className={classes.title}>{t('newReservation.basicDetails.selectCheckInDate')}</span>
        </div>
        <Grid container>
          <Grid item md={2} sm={3} xs={4}>
            <Button
              onClick={() => onDateChange(fetchDatePerTimezone(null, currentSpace.timezone), checkOut)}
              className={clsx({
                [classes.button]: true,
                [classes.selected]: fetchDatePerTimezone(null, currentSpace.timezone).isSame(checkIn, 'day')
              })}
              variant='outlined'
            >
              {t('newReservation.basicDetails.today')}
              </Button>
          </Grid>
          <Grid item md={2} sm={3} xs={4}>
            <Button
              onClick={() => onDateChange(fetchDatePerTimezone(null, currentSpace.timezone).add(1, 'days'), checkOut)}
              className={clsx({
                [classes.button]: true,
                [classes.selected]: fetchDatePerTimezone(null, currentSpace.timezone).add(1, 'days').isSame(checkIn, 'day')
              })}
              variant='outlined'
            >
              {t('newReservation.basicDetails.tomorrow')}
              </Button>
          </Grid>
          <Grid item md={2} sm={3} xs={4}>
            <div className={"datePickerContainer"}>
              <SingleDatePicker
                date={checkIn}
                onDateChange={date => onDateChange(date, checkOut)}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="check-in-date"
                placeholder={t('newReservation.basicDetails.selectDate')}
                showClearDate={true}
                noBorder={true}
                numberOfMonths={1}
                isOutsideRange={day => false}
                displayFormat={'DD MMM YYYY'}
                daySize={30}
                hideKeyboardShortcutsPanel
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <img className={classes.icon} src={IMAGES.ICONS.darkSleep} />
          <span className={classes.title}>{t('newReservation.basicDetails.selectNights')}</span>
        </div>
        <div className={classes.blocks}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <PlusMinus onCountChange={onNightChange} prefilledCount={nights} fullWidth={true} />
            </Grid>
            <Grid item xs={12} sm={1} style={{margin: '15px 0'}}>
              <div className={classes.centerAlign}>
                <span className={classes.title}>OR</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className={clsx("datePickerContainer", classes.checkOut)}>
                <SingleDatePicker
                  date={checkOut}
                  onDateChange={date => onDateChange(checkIn, date)}
                  focused={focusedCheckout}
                  onFocusChange={({ focused }) => setFocusedCheckout(focused)}
                  id="check-out-date"
                  placeholder={t('newReservation.basicDetails.selectCheckOutDate')}
                  showClearDate={true}
                  noBorder={true}
                  numberOfMonths={1}
                  isOutsideRange={day => (day.isBefore(fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE)))}
                  displayFormat={'DD MMM YYYY'}
                  daySize={30}
                  hideKeyboardShortcutsPanel
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <span className={classes.title}>{t('newReservation.basicDetails.selectBookingSource')}</span>
        </div>
        <Grid container>
          {sourceList.map((data, sourceIndex) => (
            <Grid item md={2} sm={3} xs={6} key={sourceIndex}>
              <div
                className={clsx({
                  [classes.blockItems]: true,
                  [classes.selected]: (source === data.value || (baseSource && data.value === BOOKING_SOURCE.OTHER))
                })}
                onClick={() => handleSetSource(data.value)}>
                <img className={classes.blockIcon} src={data.img} />
                <span className={classes.blockLabel}>{data.label}</span>
              </div>
            </Grid>
          ))}
        </Grid>
        {(baseSource) && 
        <div>
          
          <RadioGroup
            aria-label="source"
            className={classes.radioContainer}
            value={source}
            onChange={(e) => setSource(e.target.value)}
          > 
            {bookingSource.map((value, index) =>
              <FormControlLabel
                key={index}
                className={classes.radio}
                value={value}
                control={<Radio color="primary" disableRipple />}
                label={value}
              />
            )}
          </RadioGroup>


          {/* --------------------------------------------------------------------- */}
          {/* ADD NEW BOOKING SOURCE */}
          {/* --------------------------------------------------------------------- */}
          {!isNew && <div className={classes.link} onClick={() => setIsNew(true)}>{t('newReservation.basicDetails.addBookingSource')}</div>}
          {isNew && 
            <div className={classes.new}>
              <TextField
                id="source"
                margin="none"
                label={t('newReservation.basicDetails.newBookingSource')}
                type="text"
                onChange={(e) => setSource(e.target.value)}
                className={classes.bookingSource}
                InputProps={{
                  inputProps: { 
                      min: 0
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {/* --------------------------------------------------------------------- */}
              {/* SUBMIT / CANCEL NEW BOOKING SOURCE */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.buttonHolder}>
                <CloseIcon className={classes.roundbutton} onClick={() => setIsNew(false)} />
                <DoneIcon className={classes.roundbutton} onClick={handleNewBookingSource} />
              </div>
            </div>
          }
        </div>
      }
      </div>
    </div>
  )
})
export default withTranslation('translation', { withRef: true })(BasicDetails);