import http from '../../utils/http';

export function quickBooksAuth() {
	const ACTION_NAME = "AUTH_USER";
	return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
      .get(`/api/frontdesk/quickBooks/authenticate`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function authCallback(data) {
  const ACTION_NAME = "AUTH_CALLBACK";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/quickBooks/callback', data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function fetchQBInfo(propertyID) {
  const ACTION_NAME = "QUICKBOOKS_INFO";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
      .get(`/api/frontdesk/quickBooks?propertyID=${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function addQBInfo(data) {
  const ACTION_NAME = "CREATE_QB_DETAILS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/quickBooks`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function updateQBInfo(data) {
  const ACTION_NAME = "UPDATE_QB_DETAILS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/quickBooks`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function fetchQBAccounts(propertyID) {
  const ACTION_NAME = "QB_ACCOUNTS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
      .get(`/api/frontdesk/quickBooks/account?propertyID=${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function patchQBPurchase(data) {
  const ACTION_NAME = "QB_PURCHASE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/quickBooks/purchase`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function fetchSubCategories(category) {
  const ACTION_NAME = "QB_SUBCATEGORY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
      .get(`/api/frontdesk/quickBooks/subCategory?info=${JSON.stringify(category)}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}