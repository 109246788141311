import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import { Dialog, CircularProgress, Grid } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import http from '../../redux/utils/http'
import AvailabilityLogTableExport from './AvailabilityLogTableExport'
import { DateRangePicker } from 'react-dates'
import moment from 'moment-timezone'
import Select from 'react-select'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: '0 2px 20px #F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    zIndex: '105'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%'
  },
  gridContainer: {
    padding: '30px'
  },
  button: {
    alignItems: 'center',
    background: '#000000',
    borderRadius: '5px',
    color: '#ffffff',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'center',
    marginLeft: 20,
    padding: '5px 10px',
    width: 60,
    height: 30,
    alignSelf: 'center'
  },
  dateHolder: {
    '& .DateRangePicker_picker': {
      top: '45px !important'
    },
    '& .DateInput': {
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      border: 'none',
      borderRadius: 5,
      boxShadow: '0 1px 10px #E0E0E0'
    }
  },
  shrinkInputBase: {
    width: 'calc(100% - 30px)'
  },
  fieldContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    // boxShadow: '0 1px 10px 0 #E0E0E0',
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
    marginTop: '8px'
  },
  spacingLeft: {
    marginLeft: '10px'
  },
  exportTable: {
    padding: '0px 10px 0px 10px'
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }),
  placeholder: base => ({
    ...base,
    fontWeight: 500
  }),
  menu: provided => ({ ...provided, zIndex: 9999 })
}

const limitOptions = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 }
]

const AvailabilityLogs = (props) => {
  const classes = useStyles()

  const { open, handleClose, currentSpace, isSystemAdmin, roomTypes, t } = props
  const roomTypeOptions = [
    { label: 'All Room Types' },
    ...roomTypes.filter(rt => String(rt.propertyID) === String(currentSpace.propertyID)).map(rt => ({ label: rt.name, value: rt._id }))
  ]

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState()
  const [availLogData, setAvailLogData] = useState([])

  const [focusedInput, setFocusedInput] = useState(null)

  const [roomTypeIDFilter, setRoomTypeIDFilter] = useState(roomTypeOptions[0].value)
  const [availabilityIDFilter, setAvailabilityIDFilter] = useState('')
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [timezone, setTimezone] = useState()

  const [pagination, setPagination] = useState({
    total: 0,
    selectedLimit: limitOptions[0].value,
    currentOffset: 0
  })

  const fetchAvailabilityLogs = async (payload) => {
    setIsLoading(true)
    try {
      const result = await http.post('/api/frontdesk/availabilityLogs/', payload)
      const { timezone, availabilityLogs, totalCount } = result.data
      setTimezone(timezone)
      setAvailLogData(availabilityLogs)
      setPagination({
        total: totalCount,
        selectedLimit: payload.limit || pagination.selectedLimit,
        currentOffset: payload.skip || 0
      })
      return result
    } catch (err) {
      const error = err?.response?.data?.error || err
      console.log(error)
      setErrors(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAvailabilityLogs({
      propertyID: currentSpace.propertyID,
      roomTypeID: roomTypeIDFilter,
      limit: pagination.selectedLimit,
      skip: pagination.currentOffset
    })
  }, [])

  const handleSubmit = paginationData => {
    if (!paginationData) paginationData = pagination
    const payload = {
      startDate: startDateFilter && moment(startDateFilter).format('YYYY-MM-DD'),
      endDate: endDateFilter && moment(endDateFilter).format('YYYY-MM-DD'),
      limit: paginationData.selectedLimit,
      skip: paginationData.currentOffset
    }
    if (availabilityIDFilter) {
      payload.availabilityID = availabilityIDFilter
    } else {
      payload.propertyID = currentSpace.propertyID
      payload.roomTypeID = roomTypeIDFilter
    }
    fetchAvailabilityLogs(payload)
  }

  // Pagination functions
  const handlePaginationChange = ({ limit, offset }) => {
    const paginationData = {
      selectedLimit: limit,
      currentOffset: offset
    }
    handleSubmit(paginationData)
  }

  const handleCloseModal = () => {
    handleClose()
  }

  const handleCloseError = () => {
    setErrors()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      fullScreen
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t('beds.availabilityLogs.name')}</div>

          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        <div>
          <Grid container className={classes.gridContainer}>
            {/* startDate, endDate */}
            <Grid item className={classes.dateHolder}>
              <div style={{ marginBottom: '8px' }}>Availability Date Range</div>
              <DateRangePicker
                startDate={startDateFilter}
                startDateId='start_date'
                endDate={endDateFilter}
                endDateId='end_date'
                onDatesChange={({ startDate, endDate }) => {
                  setStartDateFilter(startDate)
                  setEndDateFilter(endDate)
                }}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                focusedInput={focusedInput}
                displayFormat='DD MMM YYYY'
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                numberOfMonths={1}
                noBorder
                minimumNights={0}
              />
            </Grid>

            {/* roomTypeID */}
            <Grid item xs={6} sm={4} md={3} className={classes.spacingLeft}>
              <div className={classes.label}>Room Type</div>
              <div className={classes.fieldContainer}>
                <Select
                  value={roomTypeOptions.find(rto => rto.value === roomTypeIDFilter)}
                  styles={selectStyle}
                  options={roomTypeOptions}
                  onChange={e => setRoomTypeIDFilter(e.value)}
                  theme={theme => ({
                    ...theme,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    }
                  })}
                />
              </div>
            </Grid>

            {/* availabilityID */}
            {isSystemAdmin && (
              <Grid item xs={6} sm={4} md={3} className={classes.spacingLeft}>
                <div className={classes.shrinkInputBase}>
                  <div className={classes.label}>Availability ID</div>
                  <div className={classes.field}>
                    <InputBase
                      value={availabilityIDFilter}
                      fullWidth
                      type='text'
                      autoFocus
                      onChange={(e) => setAvailabilityIDFilter(e.target.value)}
                    />
                  </div>
                </div>
              </Grid>
            )}

            <div
              className={classes.button}
              onClick={() => handleSubmit({ selectedLimit: pagination.selectedLimit, currentOffset: 0 })}
            >
              Submit
            </div>
          </Grid>

          <div className={classes.exportTable}>
            <AvailabilityLogTableExport
              t={t}
              data={availLogData}
              isSystemAdmin={isSystemAdmin}
              timezone={timezone}
              paginationProps={{
                pagination,
                limitOptions,
                dataCount: availLogData.length,
                // setPaginationLimit: setPaginationLimit,
                handlePaginationChange: handlePaginationChange
              }}
            />
          </div>
        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open
            onClose={handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id='message-id'>{JSON.stringify(errors, null, 2)}</span>}
          />
        )}

        {isLoading &&
          <div className='loadingOverlay'>
            <CircularProgress className='loading' />
          </div>}

      </div>
    </Dialog>

  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: Object.values(state.roomTypes).filter(rt => rt.isActive)
})

export default withTranslation()(connect(mapStateToProps)(AvailabilityLogs))
