import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, InputBase, Button } from '@material-ui/core';
import clsx from 'clsx';
import ViewCardModal from './reservation-cards/ViewCardModal';
import OneFieldForm from '../../common/OneFieldForm';
import { BOOKING_SOURCE } from '../../../utils/constants';
import Select from 'react-select';
import TimePicker from '../../common/TimePicker';
import { fetchDateFormat } from '../../../utils/utility';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    container: {
        background: '#f2f2f2',
        borderRadius: '0 5px 5px',
        margin: '0 25px 20px',
        padding: 1,
        [theme.breakpoints.down('xs')]: {
            margin: '0 10px 20px',
            borderRadius: '0 0px 5px 5px',
        }
    },
    closeHeader: {
        padding: 5,
        textAlign: 'end',
    },
    form: {
        padding: '0 20px 20px'
    },
    formInput: {
        background: '#FFFFFF',
        border: '1px solid #dadada',
        padding: '10px 15px 10px 15px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'calc(33% - 32px)',
        marginRight: 5,
        borderRadius: 30,
        justifyContent: 'space-between',
    },
    data: {
        paddingLeft: 10,
        width: 'calc(100% - 42px)',
        wordBreak: 'break-word',
        '&.fullWidth': {
            paddingLeft: 10,
            width: 'calc(100% - 10px)',
        }
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '20%'
    },
    label: {
        fontSize: '1.1rem',
        marginBottom: 5,
    },
    value: {
        fontWeight: 600
    },
    actionIcon: {
        cursor: 'pointer',
        height: 15,
        width: 15
    },
    row: {
        display: 'flex',
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '& > div': {
                marginBottom: 10
            }
        },
    },
    action: {
        background: '#FFFFFF',
        color: '#13408d',
        cursor: 'pointer',
    },
    actionInput: {
        backgroundColor: '#ffffff',
        fontSize: '1.3rem',
        color: '#13408d',
        fontWeight: '600',
        cursor: 'pointer',
        textAlign: 'center',
        '& > *': {
            width: '100%'
        }
    },
    migrated: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    migratedIcon: {
        height: 15,
        width: 15,
        marginRight: 10,
        opacity: 0.5,
    },
    migratedDesc: {
        color: '#999999',
        fontSize: '1.2rem',
    },
    tagContainer: {
        marginTop: 20,
    },
    tags: {
        background: '#FFFFFF',
        border: '1px solid #dadada',
        borderRadius: 30,
        display: 'inline-block',
        fontSize: '1.1rem',
        minWidth: 50,
        marginRight: 5,
        marginBottom: 10,
        padding: '10px 15px',
        textAlign: 'center',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        transition: '150ms',
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    tagCloseIcon: {
        width: 15,
        height: 15,
        marginLeft: 10,
        cursor: 'pointer',
    },
    dropdownInput: {
        "& > div:not(.css-26l3qy-menu)": {
            minHeight: 39,
            border: 0,
            backgroundColor: '#f8f9fb',
            borderRadius: 30,
            boxShadow: 'none'
        },
        "& > div > div > div": {
            padding: '0 8px'
        },
        "& .css-26l3qy-menu > div > div": {
            padding: 8
        },
        "& .css-26l3qy-menu": {
            zIndex: 5000
        },
        '&.small > div:not(.css-26l3qy-menu)': {
            minHeight: 30,
        }
    },
    field: {
        background: '#FFFFFF',
        paddingRight: 15,
        paddingLeft: 15,
        borderRadius: 30,
        marginRight: 5,
        minWidth: 'calc(33% - 32px)',
        border: '1px solid #dadada',
        '& > div': {
            width: '100%'
        },
        '& .css-1okebmr-indicatorSeparator': {
            display: 'none'
        },
        '& .css-6q0nyr-Svg': {
            color: '#666666',
            width: 20,
            height: 20,
        },
        '& fieldset': {
            border: 0
        },
        '& input.MuiInputBase-input': {
            padding: '11px 7px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 5,
        }
    },
    buttonContainer: {
        textAlign: 'end',
        padding: 10
    },
    back: {
        background: '#ffffff',
        border: '0',
        marginRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    submit: {
        border: '0',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: '#6ea7d6',
        color: '#fff',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#6ea7d6',
            color: '#fff',
        }
    },
    tagForm: {
        width: '50%',
        border: '1px solid #dadada',
        padding: 10,
        fontWeight: 600,
        borderRadius: 5,
        '& > div': {
            marginBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
        }
    }
}));

const FormData = ({
    classes,
    fieldName,
    keyName,
    defaultValue,
    value,
    type,
    options = [],
    editableFieldHandler,
    editableFields,
    onChangeHandler,
    editOption
}) => {
    return (
        <React.Fragment>
            {!editableFields[keyName] ?
                <div className={classes.formInput}>
                    <div className={clsx(classes.data, { ['fullWidth']: !editOption })}>
                        <div className={classes.label}>{fieldName}</div>
                        <div className={classes.value}>{defaultValue || value || '-'}</div>
                    </div>
                    {editOption &&
                        <div className={classes.icon}>
                            <img
                                className={classes.actionIcon}
                                src={IMAGES.ICONS.pencil}
                                onClick={() => editableFieldHandler(keyName, true)}
                            />
                        </div>
                    }
                </div>
                :
                <div className={clsx(classes.data, 'fullWidth')}>
                    <div className={classes.label}>{fieldName}</div>
                    <div className={classes.field}>
                        {type === 'Select' ?
                            <Select
                                isSearchable={true}
                                name={keyName}
                                onChange={(option) => onChangeHandler(keyName, option.value)}
                                value={options.find(e => e.value === value)}
                                options={options}
                                className={classes.dropdownInput}
                            /> :
                            (type === 'TimePicker' ?
                                <TimePicker
                                    value={value}
                                    onChange={(value) => onChangeHandler(keyName, value)}
                                /> :
                                (type === 'InputBase' ?
                                    <InputBase
                                        type="text"
                                        id={keyName}
                                        defaultValue={value}
                                        onChange={(e) => onChangeHandler(keyName, e.target.value)}
                                    /> : null
                                )
                            )
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const DetailsForm = (props) => {
    const classes = useStyles();
    const { reservation = {}, bookingSource, maskedCards, currentSpace, t } = props;
    const [showCard, setShowCard] = useState(false);
    const [editableFields, setEditableFields] = useState({});
    const [toSubmit, setToSubmit] = useState(false);

    const [showTagForm, setShowTagForm] = useState(false)   ;
    const [spaceTags, setSpaceTag] = useState([])

    const isCardAdded = maskedCards && maskedCards.length > 0;
    const timeOfArrival = reservation.timeOfArrival && reservation.timeOfArrival !== '--' ?
        (reservation.timeOfArrival.length <= 2 ? reservation.timeOfArrival + ':00' : reservation.timeOfArrival) : '--';

    const [sourceList, setSourceList] = useState([
        { value: BOOKING_SOURCE.PHONE, label: BOOKING_SOURCE.PHONE },
        { value: BOOKING_SOURCE.EMAIL, label: BOOKING_SOURCE.EMAIL },
        { value: BOOKING_SOURCE.WALKIN, label: BOOKING_SOURCE.WALKIN },
        { value: BOOKING_SOURCE.OTHER, label: BOOKING_SOURCE.OTHER }
    ]);

    const [form, setForm] = useState({
        bookingSource: reservation.bookingSource,
        cancellationPolicy: reservation.cancellationPolicy,
        timeOfArrival: null,
        tags: []
    })
    // ----------------------------#####################----------------------------
    // ----------------------------#####################----------------------------
    useEffect(() => {
        let list = []
        bookingSource.map(key => list.push({ value: key, label: key }))
        if (reservation.bookingSource) list.push({ value: reservation.bookingSource, label: reservation.bookingSource })
        list = [...sourceList, ...list]
        list = [...new Set(list.map(JSON.stringify))].map(JSON.parse);
        setSourceList([...list])
    }, [bookingSource])

    useEffect(() => {
        if (currentSpace.resvTags || currentSpace.tempTags) {
            processTagsHandler()
        }
    }, [currentSpace.resvTags, currentSpace.tempTags])

    // ----------------------------#####################----------------------------
    // ----------------------------#####################----------------------------
    const processTagsHandler = () => {
        let formTags = [];
        let tags = currentSpace?.resvTags?.map(tagData =>
            ({ label: tagData.name, value: tagData._id })
        ).filter(tagData => {
            if (reservation?.tags?.includes(tagData.value)) {
                formTags.push(tagData);
                return false;
            }
            return true
        })
        setSpaceTag(tags)
        setForm({ ...form, tags: formTags })
    }
    // ----------------------------#####################----------------------------
    // ----------------------------#####################----------------------------
    const validateShowCard = () => {
        return true;
        // const allowedStatus = ['plus', 'pro'];
        // if (allowedStatus.includes(currentSpace.onboardingStatus)) return true;
        // return;
    }
    const viewCardHandler = () => {
        // if(!isCardAdded) return;
        setShowCard(true);
    }
    // ----------------------------#####################----------------------------
    // ----------------------------#####################----------------------------
    const createTagHandler = (value) => {
        props.createNewTagHandler({ name: value })
        setShowTagForm(false)
    }
    const selectTagHandler = (e) => {
        let tag = []
        setToSubmit(true)
        setForm({ ...form, tags: [...form.tags, e] })
        spaceTags.map((t) => { if (t.value !== e.value) { tag.push(t) } })
        setSpaceTag(tag)
    }
    const removeTagHandler = (index) => {
        let tags = [...form.tags];
        let spaceFilteredTags = [...spaceTags];
        let removedTag = tags.splice(index, 1);
        if (removedTag.length)
            spaceFilteredTags = spaceFilteredTags.concat(removedTag);
        setForm({ ...form, tags: [...tags] })
        setToSubmit(true);
        setSpaceTag(spaceFilteredTags)
    }

    const editableFieldHandler = (fieldName, value) => {
        setEditableFields({ ...editableFields, [fieldName]: value })
        setToSubmit(true);
    }

    const onChangeHandler = (keyName, value) => {
        setForm({ ...form, [keyName]: value })
    }

    const cancelEdit = () => {
        setToSubmit(false);
        setEditableFields({});
        setShowTagForm(false);
        setForm({
            bookingSource: reservation.bookingSource,
            cancellationPolicy: reservation.cancellationPolicy,
            timeOfArrival: timeOfArrival
        })
        processTagsHandler();
    }

    const handleSubmit = () => {
        let payload = { ...form }, data = []
        if (form.timeOfArrival && form.timeOfArrival !== reservation.timeOfArrival) {
            payload.timeOfArrival = fetchDateFormat(form.timeOfArrival, 'LT')
        }
        if (!form.timeOfArrival && !reservation.timeOfArrival) delete payload['timeOfArrival'];
        if (!form.timeOfArrival && reservation.timeOfArrival) payload.timeOfArrival = reservation.timeOfArrival;
    
        payload?.tags?.map((tag) => {
            if (tag.value) data.push(tag.value);
            else data.push(tag);
        })
        payload.tags = data
        props.submitFormHandler(payload);
        setToSubmit(false)
        setShowTagForm(false);
        setEditableFields({})
    }

    return (
        <div className={classes.container}>
            <div className={classes.closeHeader}>
                <CloseIcon fontSize="small" className={classes.closeIcon} onClick={props.closeFormHandler} />
            </div>
            <div className={classes.form}>
                <div className={classes.row}>
                    <FormData
                        classes={classes}
                        fieldName={t('existingReservation.reservationTab.bookingSource')}
                        value={form.bookingSource}
                        keyName={"bookingSource"}
                        editOption={true}
                        type={'Select'}
                        options={sourceList}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                        onChangeHandler={onChangeHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName={t('existingReservation.reservationTab.arrivalTime')}
                        defaultValue={timeOfArrival}
                        value={form.timeOfArrival}
                        keyName={"timeOfArrival"}
                        editOption={true}
                        type={'TimePicker'}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                        onChangeHandler={onChangeHandler}
                    />
                    {validateShowCard() &&
                        <div className={clsx(classes.formInput, classes.actionInput)} onClick={viewCardHandler}>
                            <div>View Credit Card</div>
                        </div>
                    }
                </div>

                <div className={classes.row}>
                    <FormData
                        classes={classes}
                        fieldName={t('existingReservation.reservationTab.bookingID')}
                        value={reservation.sourceResvID}
                        keyName={"bookingID"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    <FormData
                        classes={classes}
                        fieldName={t('existingReservation.reservationTab.code')}
                        value={reservation.code}
                        keyName={"code"}
                        editOption={false}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                    />
                    {reservation.migrated &&
                        <div className={clsx(classes.formInput, classes.migrated)}>
                            <img className={classes.migratedIcon} src={IMAGES.ICONS.shield} />
                            <div className={classes.migratedDesc}>Migrated Reservation</div>
                        </div>
                    }
                </div>

                <div className={classes.row}>
                    <FormData
                        classes={classes}
                        fieldName={t('existingReservation.reservationTab.cancellationPolicy')}
                        value={form.cancellationPolicy}
                        keyName={"cancellationPolicy"}
                        editOption={true}
                        type={'InputBase'}
                        editableFields={editableFields}
                        editableFieldHandler={editableFieldHandler}
                        onChangeHandler={onChangeHandler}
                    />
                </div>

                {/* ----------------------------##########--- TAGS ---###########----------------------------*/}
                <div className={classes.tagContainer}>
                    {form?.tags?.map((tag, tagIndex) => (
                        <span key={tagIndex} className={clsx(classes.tags, classes.value)}>
                            <div>
                                {tag.label}
                                <CloseIcon fontSize="small" className={classes.tagCloseIcon} onClick={() => removeTagHandler(tagIndex)} />
                            </div>
                        </span>
                    ))}
                    {showTagForm ?
                        <div className={classes.tagForm}>
                            <div className={classes.label}>Add Reservation Tag</div>
                            {spaceTags?.length > 0 &&
                                <React.Fragment>
                                    <div className={classes.field}>
                                        <Select
                                            options={spaceTags}
                                            placeholder="Select a tag"
                                            onChange={(e) => selectTagHandler(e)}
                                            className={clsx(classes.dropdownInput, 'small')}
                                        />
                                    </div>
                                    <div className={classes.label}>OR create a new one</div>
                                </React.Fragment>
                            }
                            <OneFieldForm handleClose={() => setShowTagForm(false)} handleSubmit={(value) => createTagHandler(value)} />
                        </div> :
                        <span className={clsx(classes.tags, classes.actionInput)} onClick={() => setShowTagForm(true)}>Add Reservation Tag</span>
                    }
                </div>
            </div>

            <div className={classes.buttonContainer}>
                {toSubmit && (
                    <>
                        <Button className={classes.back} onClick={cancelEdit}>{t('actions.cancel')}</Button>
                        <Button variant="contained" className={classes.submit} onClick={() => handleSubmit()}>{t('actions.save')}</Button>
                    </>
                )}
            </div>

            {showCard && (
                <ViewCardModal
                    isCardAdded={isCardAdded}
                    closeModalHander={() => setShowCard(false)}
                />
            )}

        </div>
    )
}

export default withTranslation()(DetailsForm);