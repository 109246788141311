import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import HostelworldTab from '../hostelworld/HostelworldTab';

import { Grid, Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BookingComTab from '../channel/bookingCom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { fetchGroupUsers } from "../../redux/actions/permissions/permissions";
import { getChannelRouteInfo } from '../hostelworld/helper';
import { fetchChannelStatus } from '../../redux/actions/microsite/channels';

import GoogleHotels from '../channel/google-hotels';
import ChannelListTab from "../channels/ChannelListTab";

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F8F9FB',
    margin: "0 auto",
    marginTop: 20,
    marginBottom: 40,
    width: "100%",
    position: 'relative'
  },
  container:{
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
      marginTop:40
    },
  },
  header: {
    background: '#FFFFFF',
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  tabsContainer: {
    background: '#FFFFFF',
    padding: '0 15px',
    boxShadow: '0 3px 4px 0 #ECECEC',
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'unset',
      justifyContent: 'left',
      fontSize: '1.2rem',
      marginRight: 20,
    },
    '& .MuiTab-textColorInherit': {
      paddingLeft: 0,
      marginRight: 10,
    },
    '& .MuiTabs-indicator': {
      background: "#006699",
      height: "3px",
      marginTop: "-5px"
    },
    '& .MuiTab-root': {
      minWidth: 20,
      minHeight: 35,
      paddingBottom: 0,
    },
    '& .Mui-selected': {
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-scroller': {
        whiteSpace: 'unset',
        display: 'block',
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
  },
  tabPanel: {
    background: '#F8F9FB',
    flexGrow: 1,
    width: '100%',
    '& > .MuiBox-root': {
      padding: 0,
      paddingBottom: 40,
    }
  },
  tabContent: {
    marginTop: 10,
  },
  loadingState: {
    width: '100%',
    height: "calc(100vh - 200px)",
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

const ChannelsPage = (props) => {

  const { t, dispatch, currentSpace, isLoading } = props;

  const TABS = {
    hostelworld: { id: 'hostelworld', label: 'Hostelworld' },
    ...((['plus', 'pro'].includes(currentSpace?.onboardingStatus)) && { booking: { id: 'booking', label: 'Booking.com' } }),
    // booking: { id: 'booking', label: 'Booking.com' },
    // airbnb: { id: 'airbnb', label: 'Airbnb' },
    // expedia: { id: 'expedia', label: 'Expedia' },
    // agoda: { id: 'agoda', label: 'Agoda' },
    setup: { id: 'setup', label: 'Setup' },
  }

  // TODO: enable for google hotels
  // if (['plus', 'pro'].includes(currentSpace?.onboardingStatus)) {
  //   TABS['google-hotels'] = { id: 'google-hotels', label: 'Google Hotels' }
  // }

  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(TABS.hostelworld.id);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const pathname = useLocation().pathname;
  const history = useHistory();
  const { modalPath, tabPath, basePath } = getChannelRouteInfo(pathname);

  useEffect(() => {
    fetchUserGroupPermissions();
    initTab();
  }, []);

  const initTab = () => {
    //SET DEFAULT TAB IF NOT SET.............
    if (!Object.keys(TABS).includes(tabPath)) {
      setActiveTab(TABS.hostelworld.id);
      setTabRoute(TABS.hostelworld.id);
      return;
    }

    setActiveTab(tabPath);
    if (!modalPath) setTabRoute(tabPath);
  }

  const fetchChannelInfo = () => {
    if (!currentSpace?.propertyID) return;
    dispatch(fetchChannelStatus(currentSpace.propertyID));
  }

  const setTabRoute = (tab) => {
    history.replace(`${basePath}/${tab}/`);
  }

  const fetchUserGroupPermissions = () => {
    const params = {
      spaceID: currentSpace._id,
      groupIDs: ['HOSTELWORLD'],
    }
    dispatch(fetchGroupUsers(params));
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setTabRoute(newValue);
  };

  const getTabList = () => {
    const tabs = Object.keys(TABS).filter(tabID => tabID);
    return tabs;
  }

  return (
    <div className={classes.root}>

      {/* ..............Circular Loader...................... */}
      {isLoading && (
        <div className={classes.loadingState}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      <Grid container className={classes.container}>

        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography component="h4" variant="h6">{t('subMenu.channels')} <sup> BETA</sup></Typography>
          </div>
        </Grid>

        {/* CHANNEL TABS */}
        {/* ---------------------------------------------------------------------- */}
        <Grid item xs={12}>
          <Tabs
            orientation="horizontal"
            className={classes.tabsContainer}
            value={activeTab}
            variant="scrollable"
            scrollButtons={windowWidth > 901 ? "auto" : "on"}
            onChange={handleChange}
          >

            {getTabList().map((tabID, index) => (
              <Tab
                key={`channel_key_${index}`}
                value={tabID}
                label={TABS[tabID].label}
                {...a11yProps(tabID)}
              />
            ))}

          </Tabs>

          <div className={classes.tabContent}>
            {/* TAB CONTENT */}
            {/* ---------------------------------------------------------------------- */}

            {/* Hostelworld */}
            {/* -------------------------------------- */}
            <TabPanel className={classes.tabPanel} value={activeTab} index={TABS.hostelworld.id}>
              <HostelworldTab />
            </TabPanel>

            {/* Booking.com */}
            {/* -------------------------------------- */}
            {TABS.booking &&
              <TabPanel className={classes.tabPanel} value={activeTab} index={TABS.booking.id}>
                <BookingComTab />
              </TabPanel>
            }

            {/* Setup */}
            {/* -------------------------------------- */}
            {TABS.setup &&
              <TabPanel className={classes.tabPanel} value={activeTab} index={TABS.setup.id}>
                <ChannelListTab />
              </TabPanel>
            } 

            {/* Google Hotel */}
            {/* -------------------------------------- */}
            {TABS['google-hotels'] && (
              <TabPanel className={classes.tabPanel} value={activeTab} index={TABS['google-hotels'].id}>
                <GoogleHotels />
              </TabPanel>
            )}

          </div>
        </Grid>

      </Grid>


    </div>
  )
}

const mapStateToProps = state => {
  const { spaces, auth, dashboard, loading, inbox: { channels } } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace],
    isLoading: loading.FETCH_INBOX_CHANNELS_STATUS
  };
}

export default withTranslation()(connect(mapStateToProps)(ChannelsPage));