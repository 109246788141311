export const isCardValid = cardNumber => String(cardNumber).match(/^[0-9*]{16}$/);

const cardFieldClasses = [
    { type: 'cardType', className: 'card__field--card-type', elementNo: 0, childNo: 1 },
    { type: 'cardNumber', className: 'card__field--card-number', elementNo: 0, childNo: 1},
    { type: 'cardName', className: 'card__field--cardholder-name', elementNo: 0, childNo: 1 },
    { type: 'cardExpiry', className: 'card__field--expiration-date', elementNo: 0, childNo: 1 },
];

export function parseCardDetails(data, cvvInfo = false){
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(data, 'text/html');
    const cardDetails = {};

    for(const cardInput of cardFieldClasses){
        const cardInputElement = parsedHtml.getElementsByClassName(cardInput.className)[cardInput.elementNo];
        cardDetails[cardInput.type] = cardInputElement.children[cardInput.childNo].innerHTML.trim();
    }

    if(cvvInfo){
        cardDetails.cardCvv = parsedHtml.getElementsByClassName("card__field card__field--service-code")[0].children[1].innerHTML.trim();
        cardDetails.cvvInfo = { isAccessExpired: false, accessExpiryInfo: '' };

        const parsedCVVHtml = parser.parseFromString(cardDetails.cardCvv, 'text/html');

        if(parsedCVVHtml.getElementsByClassName('card__show-service-code').length > 0){
            cardDetails.cvvInfo.isAccessExpired = false;
        }

        if(parsedCVVHtml.getElementsByClassName('card__service-code-removed').length > 0){
            const targetStr = '<span class=\"card__service-code-removed\">Removed</span> ';
            const CvvInfo = {
                isAccessExpired: true,
                accessExpiryInfo: cardDetails.cardCvv.replace(targetStr, '')
            }
            cardDetails.cvvInfo = { ...CvvInfo };
        }
    }

    return cardDetails;
}