import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RatePlanMappingItem from './RatePlanMappingItem'

import { CHANNEL } from '../../utils/channels'

import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import { some, sortBy } from 'lodash'
import { CHANNEX_CHANNELS } from '../../utils/constants'
import { withTranslation } from 'react-i18next';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #dadada',
    borderRadius: 5,
    padding: 20,
    position: 'relative',
    marginBottom: 10
  },
  loading: {
    color: '#666666',
    fontSize: '1.2rem'
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '50%'
  },
  icon: {
    width: 15,
    height: 15,
    opacity: 0.8
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 600
  },
  submitContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
    marginBottom: 10,
    width: '100%'
  },
  cancel: {
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginRight: 25
  },
  save: {
    background: '#006699',
    borderColor: '#006699',
    color: '#FFFFFF',
    fontSize: '1.3rem',
    '&:hover': {
      background: '#006699',
      color: '#FFFFFF'
    }
  }
}))

const RatePlanMapping = (props) => {
  const classes = useStyles()
  const { channelType, ratePlans = {}, roomTypeID, allChannelRatePlans = {}, mappedRoom, loading, loadingUpdate, t } = props
  const channelRatePlans = (allChannelRatePlans[mappedRoom.roomTypeID] && allChannelRatePlans[mappedRoom.roomTypeID].ratePlans) || []

  const [ratePlansAvailableForMapping, setRatePlansAvailableForMapping] = React.useState([])
  const [processedRatePlans, setProcessedRatePlans] = React.useState(false)

  const isChannexChannel = CHANNEX_CHANNELS.includes(channelType)
  const channelCode = CHANNEL[channelType].code

  useEffect(() => {
    const available = []
    Object.values(ratePlans).forEach(ratePlan => {
      if (!ratePlan.roomTypeIDs.includes(roomTypeID)) return
      if (some(
        ratePlan.mapping,
        mapping => {
          if (isChannexChannel && CHANNEX_CHANNELS.includes(mapping.channel)) {
            const isChannelMapped = some(channelRatePlans, rp => rp.channexRatePlanCode && rp.ratePlanCode === mapping.code)
            return mapping.channel.toUpperCase() === channelCode.toUpperCase() && mapping.roomTypeID === roomTypeID && isChannelMapped
          }
          
          return mapping.channel === channelCode && mapping.roomTypeID === roomTypeID
        }
      )) return
      available.push({label: ratePlan.privateName, value: ratePlan._id})
    })
    setRatePlansAvailableForMapping(available)
    setProcessedRatePlans(true)
  }, [allChannelRatePlans, ratePlans])

  const handleRemove = (selectedRatePlan) => {
    setRatePlansAvailableForMapping(ratePlansAvailableForMapping.filter(rp => rp.value !== selectedRatePlan))
  }

  const handleAddBack = (ratePlanId) => {
    const alreadyExists = ratePlansAvailableForMapping.find(rp => rp._id === ratePlanId)
    if (alreadyExists) return
    setRatePlansAvailableForMapping([
      ...ratePlansAvailableForMapping,
      {
        label: Object.values(ratePlans).find(rp => rp._id === ratePlanId).privateName,
        value: ratePlanId
      }
    ])
  }

  const sort = () => {
    const orderedChannelRatePlans = sortBy(channelRatePlans, crp => crp.ratePlanCode)
    const sorted = []
    orderedChannelRatePlans.forEach(rp => {
      // If rate plan is not derived
      if (!rp.parentrateplanid) {
        // If child present push rate plan above its first child
        const childIndex = sorted.findIndex(s => String(s.parentrateplanid) === rp.ratePlanCode)
        if(childIndex >= 0) return sorted.splice(childIndex, 0, rp)
        return sorted.push(rp)
      }
      // If rate plan is derived

      //If parent present push rate plan below its parent
      const parentIndex = sorted.findIndex(s => s.ratePlanCode === String(rp.parentrateplanid))
      if(parentIndex >= 0) return sorted.splice(parentIndex + 1, 0, rp)
      return sorted.push(rp)
    })
    return sorted
  }

  const sortedChannelRatePlans = sort()

  return (
    <div className={classes.container}>
      {loading && <div className={classes.loading}>{t('beds.channelsSnapshot.ratePlanMapping.loading')}</div>}

      {loadingUpdate && <div className={classes.loaderContainer}><CircularProgress className={classes.loader} /></div>}

      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={4}>
          <div className={classes.title}>{CHANNEL[channelType].name} {t('beds.channelsSnapshot.ratePlanMapping.ratePlans')}</div>
        </Grid>
        <Grid item xs={2}><img className={classes.icon} src={IMAGES.ICONS.arrows} /></Grid>
        <Grid item xs={4}>
          <div className={classes.title}>Counter {t('beds.channelsSnapshot.ratePlanMapping.ratePlans')}</div>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {processedRatePlans && sortedChannelRatePlans.map(channelRatePlan => (
        <RatePlanMappingItem
          key={channelRatePlan.ratePlanCode}
          channelType={channelType}
          ratePlansAvailableForMapping={ratePlansAvailableForMapping}
          roomTypeID={roomTypeID}
          handleRemove={handleRemove}
          handleAddBack={handleAddBack}
          channelRatePlan={channelRatePlan}
          channelRatePlans={channelRatePlans}
          channelRoomCategory={props.channelRoomCategory}
          errorHandler={props.errorHandler}
        />
      )
      )}

    </div>
  )
}

const mapStateToProps = state => ({
  ratePlans: state.ratePlans,
  allChannelRatePlans: state.channelMapping.ratePlans,
  loading: state.loading.LOAD_RATEPLANS || state.loading.FETCH_CHANNEL_RATEPLANS,
  loadingUpdate: state.loading.DELETE_CHANNEL_RATEPLAN_MAPPING || state.loading.ADD_CHANNEL_RATEPLAN_MAPPING
})

export default withTranslation()(connect(mapStateToProps)(RatePlanMapping))
