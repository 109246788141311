import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { schema, normalize } from "normalizr";
import _ from "lodash";

import { returnCurrent } from "../../../redux/selectors/dashboard";
import {
  fetchDateFormat,
  fetchDatePerTimezone,
  DATE_FORMATS,
  getDatesOfRange,
} from "../../../utils/utility";
import {
  getHostelWorldInventory,
  getHostelWorldRatePlans,
  getHostelWorldRoomTypes,
} from "../../../redux/actions/microsite/hostelworld";

import CalendarDates from "../../beds/CalendarDates";
import RoomTypeRate from "./RoomTypeRate";

import { Grid, Dialog, Hidden, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";
import IMAGES from "../../../constants/images";
import { WORKSPACE_URL } from "../../../config";

const styles = (theme) => ({
  dialog: {
    background: "#F8F9FB",
    scrollY: "hidden",
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: "calc(100vh - 100px)",
    justifyContent: "center",
    width: "100%",
  },
  loadingRateState: {
    alignItems: "center",
    display: "flex",
    height: "600px",
    justifyContent: "center",
    width: "100%",
  },
  calendarHeader: {
    background: "#F8F9FB",
    //position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  headerSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
    paddingBottom: 10,
  },
  calendarDates: {
    position: "relative",
  },
  buttonHolder: {
    position: "absolute",
    top: 0,
    right: 10,
    height: 42,
  },
  button: {
    border: "0px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "2.2rem",
    marginRight: 10,
    padding: 10,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  accessInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accessInfoHeader: {
    marginTop: "unset",
    marginBottom: "10px",
  },
  listContainer: {
    background: "#F8F9FB",
    height: "calc(100vh - 124px)",
    overflowY: "auto",
  },
  rateSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  banner: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    display: "flex",
    marginBottom: 20,
    padding: 20,
    width: "calc(100% - 40px)",
  },
  logo: {
    marginRight: 15,
    overflow: "hidden",
    minWidth: 30,
    "& img": {
      height: 30,
      width: 30,
      objectFit: "cover",
    },
  },
  bannerDesc: {
    color: "#484848",
    fontSize: "1.2rem",
    marginRight: 15,
  },
  bannerButton: {
    marginLeft: "auto",
    "& a": {
      color: "#006699",
      cursor: "pointer",
      fontWeight: 600,
      fontSize: "1.2rem",
    },
  },
  blankSlate: {
    margin: "50px auto",
    width: "80%",
    textAlign: "center",
    lineHeight: 1.5,
  },
  blankText: {
    fontSize: "1.4rem",
    marginBottom: 20,
  },
});

const Rates = (props) => {
  const { classes, open, closeHandler, currentSpace, loadingRates, t } = props;
  const timezone = currentSpace.timezone;

  const [loading, setLoading] = React.useState(false);
  const [numberOfDays, setNumberOfDays] = React.useState(
    window.innerWidth < 901 ? "5" : "14"
  );
  const [roomTypesList, setRoomTypeList] = React.useState([]);
  const [ratePlans, setRatePlans] = React.useState({});
  const [inventoryList, setInventoryList] = React.useState([]);
  const [day, setDay] = React.useState({
    startDate: fetchDatePerTimezone(null, timezone),
    endDate: fetchDatePerTimezone(null, timezone),
    days: [],
  });

  const startDate = fetchDateFormat(
    fetchDatePerTimezone(null, timezone),
    DATE_FORMATS.DEFAULT_DATE
  );
  const endDate = fetchDatePerTimezone(null, timezone)
    .add(14, "days")
    .format(DATE_FORMATS.DEFAULT_DATE);
  const [range, setRange] = React.useState(
    getDatesOfRange(startDate, endDate, null, "stringArray")
  );

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      fetchRoomTypeHandler();
      fetchInventoryHandler(startDate);
      fetchRatePlansHandler();
    }
  }, [open]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setNumberOfDays(window.innerWidth < 901 ? "5" : "14");
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const ratePlansSchema = new schema.Entity(
    "rateplans",
    {},
    { idAttribute: "id" }
  );
  const roomTypeSchema = new schema.Entity(
    "roomtypes",
    { rateplans: [ratePlansSchema] },
    { idAttribute: "id" }
  );

  const fetchRoomTypeHandler = () => {
    const response = props.dispatch(
      getHostelWorldRoomTypes(currentSpace.propertyID)
    );
    response
      .then((data) => {
        // setLoading(false);
        if (data) setRoomTypeList([...data]);
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const fetchInventoryHandler = (date1) => {
    const date2 = fetchDatePerTimezone(date1, null)
      .add(numberOfDays === "14" ? 6 : 4, "days")
      .format(DATE_FORMATS.DEFAULT_DATE);

    let params = {
      firstCallStartDate: date1,
      firstCallEndDate: date2,
      ...(numberOfDays === "14" && {
        secondCallStartDate: fetchDatePerTimezone(date2, null)
          .add(1, "days")
          .format(DATE_FORMATS.DEFAULT_DATE),
        secondCallEndDate: fetchDatePerTimezone(date2, null)
          .add(7, "days")
          .format(DATE_FORMATS.DEFAULT_DATE),
      }),
    };

    const response = props.dispatch(
      getHostelWorldInventory(currentSpace.propertyID, params)
    );
    response
      .then((data) => {
        if (data) {
          setInventoryDataHandler(
            data.firstCallData,
            data.secondCallData,
            params.firstCallStartDate,
            params.secondCallEndDate || params.firstCallEndDate
          );
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchRatePlansHandler = () => {
    const response = props.dispatch(
      getHostelWorldRatePlans(currentSpace.propertyID)
    );
    response
      .then((data) => {
        // setLoading(false);
        if (data) setRatePlans({ ...data });
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const handleCalendarDays = (newDay) => {
    if (day.days.length !== 0) {
      setLoading(true);
      fetchInventoryHandler(newDay.startDate.format("YYYY-MM-DD"));
    }
    setDay({ ...newDay });
  };

  const setInventoryDataHandler = (array1, array2, date1, date2) => {
    if (array1 && !array2) {
      setRange(getDatesOfRange(date1, date2, null, "stringArray"));
      setLoading(false);
      setInventoryList([...array1]);
      return;
    }

    if (array1 && array2) {
      let normalizedData1 = normalize(array1, [roomTypeSchema]);
      let normalizedData2 = normalize(array2, [roomTypeSchema]);

      let roomTypeKeys = [
        ...new Set(normalizedData1?.result?.concat(normalizedData2?.result)),
      ];

      let roomTypeList1 = { ...normalizedData1.entities.roomtypes };
      let roomTypeList2 = { ...normalizedData2.entities.roomtypes };
      let ratePlanList1 = { ...normalizedData1.entities.rateplans };
      let ratePlanList2 = { ...normalizedData2.entities.rateplans };

      let finalData = roomTypeKeys.map((key) => {
        let info = {};
        info.id = key;
        info.availability = {
          ...(roomTypeList1?.[key]?.availability || {}),
          ...(roomTypeList2?.[key]?.availability || {}),
        };
        info.availability.nights = [
          ...(roomTypeList1?.[key]?.availability.nights || []),
          ...(roomTypeList2?.[key]?.availability.nights || []),
        ];

        let roomTypeRatePlansKeys = [
          ...new Set([
            ...(roomTypeList1?.[key]?.rateplans || []),
            ...(roomTypeList2?.[key]?.rateplans || []),
          ]),
        ];

        info.rateplans = roomTypeRatePlansKeys.map((ratePlanKey) => {
          let ratePlanInfo = { id: ratePlanKey };
          ratePlanInfo.info = {
            ...(ratePlanList1?.[ratePlanKey]?.info || {}),
            ...(ratePlanList2?.[ratePlanKey]?.info || {}),
          };
          ratePlanInfo.rates = [
            ...(ratePlanList1?.[ratePlanKey]?.rates || []),
            ...(ratePlanList2?.[ratePlanKey]?.rates || []),
          ];
          return ratePlanInfo;
        });
        return info;
      });
      setLoading(false);
      setRange(getDatesOfRange(date1, date2, null, "stringArray"));
      setInventoryList([...finalData]);
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={closeHandler}
        className={classes.dialog}
        fullScreen={true}
      >
        {/* -------------------------------------------------------- */}
        {/* HEADER */}
        {/* -------------------------------------------------------- */}
        <div className={classes.calendarHeader}>
          <div className={classes.headerSection}>
            <Grid container>
              <Hidden smUp>
                <Grid item xs={12} className={classes.accessInfo}>
                  <AccessHeaderAction
                    moduleID={MODULE.HW_RATES.ID}
                    className={classes.accessInfoHeader}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={4} sm={2}></Grid>
              <Grid item xs={8} sm={10} className={classes.calendarDates}>
                <CalendarDates
                  dateSelectEnabled={false}
                  calendarDaysEnabled={true}
                  calendarChangeEnabled={true}
                  numberOfDays={numberOfDays}
                  datePicker={true}
                  checkIn={startDate}
                  checkOut={endDate}
                  timezone={currentSpace.timezone}
                  dateFormat={currentSpace.dateFormat}
                  handleCalendarDays={handleCalendarDays}
                  showAccessHeader={true}
                  moduleID={MODULE.HW_RATES.ID}
                />
                <div className={classes.buttonHolder}>
                  <CloseIcon
                    className={classes.button}
                    onClick={closeHandler}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.listContainer}>
          {/* -------------------------------------------------------- */}
          {/* BANNER */}
          {/* -------------------------------------------------------- */}
          <div className={classes.rateSection}>
            <div className={classes.banner}>
              <div className={classes.logo}>
                <img src={IMAGES.LOGOS.hostelworld} />
              </div>
              <div className={classes.bannerDesc}>
                {t("hostelworldChannel.rates.banner")}
              </div>
              <div className={classes.bannerButton}>
                <a href={`${WORKSPACE_URL}/beds/manage/rates`}>
                  {t("hostelworldChannel.rates.editRates")}
                </a>
              </div>
            </div>
          </div>

          {/* -------------------------------------------------------- */}
          {/* RATE PLAN DISPLAY */}
          {/* -------------------------------------------------------- */}
          {!loading && (!roomTypesList || roomTypesList.length === 0) && (
            <div className={classes.blankSlate}>
              <div className={classes.blankText}>
                {t("hostelworldChannel.rates.noRoomTypes")}
              </div>
            </div>
          )}
          {/* -------------------------------------------------------- */}
          {/* RATE PLAN DISPLAY */}
          {/* -------------------------------------------------------- */}
          <div className={classes.rateSection}>
            {roomTypesList?.map((roomType) => {
              const rate = inventoryList.find(
                (inventory) => inventory.id === parseInt(roomType.roomtypeid)
              ) || { availability: {}, rate: {} };
              return (
                <RoomTypeRate
                  numberOfDays={numberOfDays}
                  roomTypeID={roomType.roomtypeid}
                  roomType={roomType}
                  rate={rate}
                  range={range}
                  ratePlans={ratePlans}
                  key={roomType.roomtypeid}
                  refreshInventory={() => {
                    setLoading(true);
                    fetchInventoryHandler(startDate);
                    fetchRatePlansHandler();
                  }}
                />
              );
            })}
          </div>
        </div>

        {loading && (
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        )}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  loadingRates: state.loading.LOAD_RATES,
  loading:
    state.loading.LOAD_RATEPLANS ||
    state.loading.LOAD_HOSTEL_WORLD_INVENTORY ||
    state.loading.LOAD_HOSTEL_WORLD_RATE_PLANS,
});

export default withTranslation()(
  withStyles(styles)(withTranslation()(connect(mapStateToProps)(Rates)))
);
