import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const languageOptions = [
    { value:"AF",label: 'Afrikaans' },
    { value:"SQ", label: 'Albanian' },
    { value:"AR", label: 'Arabic' },
    { value:"HY", label: 'Armenian' },
    { value:"EU", label: 'Basque' },
    { value:"BN", label: 'Bengali' },
    { value:"BG", label: 'Bulgarian' },
    { value:"CA", label: 'Catalan' },
    { value:"KM", label: 'Cambodian' },
    { value:"ZH", label: 'Chinese (Mandarin)' },
    { value:"HR", label: 'Croatian' },
    { value:"CS", label: 'Czech' },
    { value:"DA", label: 'Danish' },
    { value:"NL", label: 'Dutch' },
    { value:"EN", label: 'English' },
    { value:"ET", label: 'Estonian' },
    { value:"FJ", label: 'Fiji' },
    { value:"FI", label: 'Finnish' },
    { value:"FR", label: 'French' },
    { value:"KA", label: 'Georgian' },
    { value:"DE", label: 'German' },
    { value:"EL", label: 'Greek' },
    { value:"GU", label: 'Gujarati' },
    { value:"HE", label: 'Hebrew' },
    { value:"HI", label: 'Hindi' },
    { value:"HU", label: 'Hungarian' },
    { value:"IS", label: 'Icelandic' },
    { value:"ID", label: 'Indonesian' },
    { value:"GA", label: 'Irish' },
    { value:"IT", label: 'Italian' },
    { value:"JA", label: 'Japanese' },
    { value:"JW", label: 'Javanese' },
    { value:"KO", label: 'Korean' },
    { value:"LA", label: 'Latin' },
    { value:"LV", label: 'Latvian' },
    { value:"LT", label: 'Lithuanian' },
    { value:"MK", label: 'Macedonian' },
    { value:"MS", label: 'Malay' },
    { value:"ML", label: 'Malayalam' },
    { value:"MT", label: 'Maltese' },
    { value:"MI", label: 'Maori' },
    { value:"MR", label: 'Marathi' },
    { value:"MN", label: 'Mongolian' },
    { value:"NE", label: 'Nepali' },
    { value:"NO", label: 'Norwegian' },
    { value:"FA", label: 'Persian' },
    { value:"PL", label: 'Polish' },
    { value:"PT", label: 'Portuguese' },
    { value:"PA", label: 'Punjabi' },
    { value:"QU", label: 'Quechua' },
    { value:"RO", label: 'Romanian' },
    { value:"RU", label: 'Russian' },
    { value:"SM", label: 'Samoan' },
    { value:"SR", label: 'Serbian' },
    { value:"SK", label: 'Slovak' },
    { value:"SL", label: 'Slovenian' },
    { value:"ES", label: 'Spanish' },
    { value:"SW", label: 'Swahili' },
    { value:"SV", label: 'Swedish ' },
    { value:"TA", label: 'Tamil' },
    { value:"TT", label: 'Tatar' },
    { value:"TE", label: 'Telugu' },
    { value:"TH", label: 'Thai' },
    { value:"BO", label: 'Tibetan' },
    { value:"TO", label: 'Tonga' },
    { value:"TR", label: 'Turkish' },
    { value:"UK", label: 'Ukrainian' },
    { value:"UR", label: 'Urdu' },
    { value:"UZ", label: 'Uzbek' },
    { value:"VI", label: 'Vietnamese' },
    { value:"CY", label: 'Welsh' },
    { value:"XH", label: 'Xhosa' }
]

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    selectFormControl: {
        width: '30%',
        boxSizing: 'border-box',
        marginTop: '10px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
}));

const ActivityLanguage = props =>{

    const classes = useStyles();
    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo,
        submitDraftHandler,
        productData  
    } = props;

    const [ language, setLanguage ] = useState('');
    const [ languageError, setLanguageError ] = useState(false);

    useEffect(() => {
        if(productData.language) setLanguage(productData.language);
    }, [ productData.language ]);

    const validateLanguage = value =>{
        const success = Boolean(value);
        const errorStatus = !success;
        setLanguageError(errorStatus);
        return success;
    }

    const onChangeHandler = event =>{
        const value = event.target.value;
        validateLanguage(value);
        setLanguage(value);
    }

    const submitHandler = () =>{
        
        if(!validateLanguage(language)) return;
        const data = { language };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className={clsx("main-content", "hideScrollBar")}>
                
                <div className="main-content-title">Language</div>

                <div className="sub-title">
                    You should be able to read, write and speak in this language.
                </div>

                <FormControl variant="outlined" className={classes.selectFormControl}>
                    <Select
                        native
                        value={language}
                        onChange={onChangeHandler}
                        className={classes.selectInput}
                        inputProps={{
                            name: 'language'
                        }}
                        error={languageError}
                    >
                        <option aria-label="None" value="" />
                        {languageOptions.map(language =>(
                            <option value={language.value} key={`theme_${language.value}`}>
                                {language.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                {languageError && (
                    <FormHelperText className="errorMessage">
                        Language is required
                    </FormHelperText>
                )}

            </div>
            <Footer
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
            />
        </>
    )
}

export default ActivityLanguage; 