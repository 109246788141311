import http from '../../utils/http';

export function fetchConfig(propertyID) {
  const ACTION_NAME = "FETCH_ODOO_CONFIG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/odoo/propertyMap/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export const resetConfig = () => (dispatch) => {
  dispatch({ type: "RESET_ODOO_CONFIG" })
};

export function saveConfig(data) {
  const ACTION_NAME = "SAVE_ODOO_CONFIG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/odoo/propertyMap/`, data)
      .then(successResult => {
        const data = successResult.data;
        console.log(data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function updateConfig(propertyID, data) {
  const ACTION_NAME = "UPDATE_ODOO_CONFIG";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/odoo/propertyMap/${propertyID}`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}