import React from "react";
import { withTranslation } from 'react-i18next';
import { makeStyles, Typography, Grid, Hidden } from '@material-ui/core';
import { toTitleCase } from "../../../utils/wordUtils";
import TooltipIcon from "../../common/TooltipIcon";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConfirmForm from "../../../common/ConfirmForm";
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    header: {
        paddingBottom: 10,
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 40,
    },
    button: {
        border: '0px solid #dddddd',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '2.2rem',
        marginRight: 10,
        padding: 10,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    binContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    bin: {
        width: 15,
        height: 15,
        cursor: 'pointer'
    },
    actionIcon: {
        width: 30,
        height: 30,
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    title: {
        display: 'flex',
        fontWeight: 600,
        fontSize: '1.4rem',
        '& span': {
            marginRight: 20
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'unset',
            alignItems: 'center'
        }
    },
    copyContainer: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        '& input': {
            border: '1px solid rgb(223, 225, 230)',
            borderRadius: 3,
            cursor: 'default',
            padding: 5,
            marginRight: 10,
        },
        [theme.breakpoints.down('sm')]: {
            '& input': {
                display: 'none'
            }
        }
    }
}))


const Header = (props) => {
    const classes = useStyles();
    const { data, t } = props;
    const [deleteVisible, setDeleteVisible] = React.useState(false);

    return (
        <div className={classes.header}>
            <Grid container>
                <Grid item xs={9}>

                    <div className={classes.titleContainer}>
                        <div className={classes.title}><span>{data?.guestName}</span>

                            {(props.allowDataActions && props.allowDelete) &&
                                <div className={classes.binContainer}>
                                    <TooltipIcon
                                        className={classes.bin}
                                        icon={IMAGES.ICONS.trash}
                                        clickText='Delete guest'
                                        hoverText='Delete guest'
                                        handleClick={() => setDeleteVisible(true)}
                                    />
                                </div>
                            }

                        </div>
                    </div>

                </Grid>
                <Grid item xs={3} className={classes.buttonContainer}>
                    {props.expand === 'less' ?
                        <ExpandMoreIcon className={classes.actionIcon} onClick={() => props.changeExpandHandler('more')} /> :
                        <ExpandLessIcon className={classes.actionIcon} onClick={() => props.changeExpandHandler('less')} />
                    }
                </Grid>
                <Grid item xs={12}>
                    <ConfirmForm
                        open={deleteVisible}
                        setOpen={setDeleteVisible}
                        onClose={() => setDeleteVisible(false)}
                        onConfirm={props.deleteGuestHandler}
                    >
                        Are you sure you would like to delete this guest?
                    </ConfirmForm>
                </Grid>
            </Grid>
        </div>
    )
}

export default withTranslation()(Header);