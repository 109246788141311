import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { returnCurrent } from "../../../redux/selectors/dashboard";
import { fetchCashDrawerTransactions } from '../../../redux/actions/cash-drawers/cashDrawers';
import { processCashDrawerSessions } from '../../../redux/selectors/reports/cashAudit';
import SessionTransactions from './SessionTransactions';
import MaterialTable from 'material-table';
import { exportExcel } from '../../utils/excelExport';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 30
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10,
        width: '100%',
    },
    clear: {
        cursor: 'pointer',
        fontSize: '1.2rem',
    },
    sectionHeaderText: {
        color: '#333333',
        fontWeight: 600
    },
    thead: {
        background: '#F6F2EE',
    },
    trow: {
        cursor: 'pointer'
    },
    highlighted: {
        color: 'red'
    }
}))

const CashDrawerSessions = (props) => {

    const classes = useStyles();
    const { currentSpace, sessions, frontdeskUsers, loadingFrontdesk, loadingTransactions, t } = props;
    const [selected, setSelected] = React.useState('');
    const [selectedSession, setSelectedSession] = React.useState({});

    //const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);
    const currencyData = {
        code: currentSpace.currency,
        country: currentSpace.country
    }

    const formattedSessionsData = processCashDrawerSessions(sessions, frontdeskUsers, currencyData);

    const handleTableRowClick = (value, data) => {

        if(selected){
            handleClearSelection();
            return;
        } 

        setSelected(value);
        setSelectedSession(data);
        props.dispatch(fetchCashDrawerTransactions(value));
    }

    const handleClearSelection = () => {
        setSelected('');
        setSelectedSession('');
    }

    const formattedTableData = selected ? [ selectedSession ] : formattedSessionsData;
    const rowCount = formattedTableData.length;
    const tableTitle = t('reports.table.cashDrawerSession');
    
    return (
        <div className={classes.root}>


            <div className={classes.sectionHeader}>
                {selected && (
                    <div className={classes.clear} onClick={handleClearSelection}>
                        {t('reports.table.clearSelection')}
                    </div>
                )}
            </div>

            <MaterialTable
                title={tableTitle}
                columns={[
                    { 
                        title: t('reports.table.startShift'), 
                        field: 'startTime',
                        render: rowData => (
                            <div>
                                <div>{rowData.startTime}</div>
                                <div>{rowData.startedByUser}</div>
                            </div>
                        ) 
                    },
                    { 
                        title: t('reports.table.endShift'), 
                        field: 'endTime',
                        render: rowData => (
                            <div>
                                <div>{rowData.endTime || t('reports.table.registerOpen')}</div>
                                <div>{rowData.endedByUser}</div>
                            </div>
                        ) 
                    },
                    { title:  `${t('reports.table.openingBal')} (${currencyData.code})`, field: 'startCount' },
                    { title: `${t('reports.table.closingBal')} (${currencyData.code})`, field: 'endCount' },
                    { 
                        title: t('reports.table.overShort'), 
                        field: 'overShort',
                        render: rowData =>( 
                            <div style={{ color: rowData.overShortAmount <  0 ? 'red' : '#000' }}>
                                {rowData.overShort}
                            </div> 
                        )
                    }
                ]}
                data={formattedTableData}
                // isLoading={props.isLoading} 
                options={{
                    // grouping: true,
                    exportButton: true,
                    rowStyle: { fontSize: '1.2rem' },
                    headerStyle: {
                        backgroundColor: '#F6F2EE',
                        zIndex: 'unset'
                    },
                    paging: rowCount > 50 ? true : false,
                    pageSize: 50,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
                    exportCsv: (columns, data) => {
                        const tableData = _.cloneDeep(data);
                        const headerConfig = [...columns];
                        const filename = `${tableTitle}`;
                        exportExcel(filename, headerConfig, tableData);
                    },
                }}
                localization={{
                    toolbar: { exportCSVName: "Export as Excel" },
                    body: {
                        emptyDataSourceMessage: 'No sessions found'
                    }
                }}
                onRowClick={(event, rowData) => handleTableRowClick(rowData._id, rowData)}
            />

            {/* --------------------------------------------------------- */}
            {/* CASH DRAWER TRANSACTIONS */}
            {/* --------------------------------------------------------- */}
            {selected !== '' && !loadingTransactions && <div className={classes.listSection}>
                <div className={classes.noShowGraph}>
                    {<SessionTransactions />}
                </div>
            </div>}

        </div>
    )

}

const mapStateToProps = state => ({
    currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
    sessions: state.reports.cashDrawers && state.reports.cashDrawers.sessions || {},
    frontdeskUsers: state.frontdeskUsers,
    loadingFrontdesk: state.loading.LOAD_FRONTDESK_USERS,
    loadingTransactions: state.loading.FETCH_CASH_DRAWER_TRANSACTIONS
});

export default withTranslation()(connect(mapStateToProps)(CashDrawerSessions));