import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import pluralize from 'pluralize';
import ReactCodeInput from 'react-code-input';

import { postNewCashDrawer } from '../../redux/actions/cash-drawers/cashDrawers';
import { createFrontdeskUser } from '../../redux/actions/user/frontdeskUsers';

import { Button, Grid, Dialog, InputBase, makeStyles, Typography } from '@material-ui/core';
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 50px)',
    marginTop: 50,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  founded: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '15px',
    display: 'flex',
    marginBottom: 10,
    minHeight: 38,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& input': {
      padding: 0
    },
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  },
  button: {
    background: '#fff',
    border: 'none',
    borderRadius: 10,
    height: 50,
    padding: 0,
    width: 50,
    minWidth: 50,
  },
  register: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  registerNumber: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 70px 0 #EBEDF7',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    width: 50,
    '& h4': {
      fontSize: '2rem',
      fontWeight: 500,
    }
  },
  options: {
    width: '100%',
  },
  optionItem: {
    background: '#ffffff',
    borderRadius: '15px',
    color: '#999999',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.2rem',
    marginRight: 10,
    marginBottom: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selected: {
    background: '#4F9581',
    color: '#ffffff',
  },
  placeholder: {
    background: '#ffffff',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  legal: {
    alignItems: 'center',
    display: 'flex'
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem'
  },
  lastThing: {
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: 20,
  },
  desc: {
    fontSize: '1.3rem',
    marginBottom: 10,
  },
  content: {
    padding: theme.spacing(5)
  },
  number: {
    marginTop: 30,
    marginBottom: 30
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
}))

const codeStyle = {
  inputStyle: {
    backgroundColor: '#F5F1ED',
    borderRadius: '50%',
    border: 'none',
    fontSize: '1.4rem',
    height: '40px',
    margin:  '4px',
    MozAppearance: 'textfield',
    textAlign: 'center',
    width: '40px',
  },
  inputStyleInvalid: {
    color: 'red',
    border: '1px solid red'
  }
}

const ReceptionForm = (props) => {

  const classes = useStyles();
  const { currentSpace, propertyID, userName } = props;
  const [computer, setComputer] = React.useState({desktop: false, laptop: false, ipad: false, android: false, other: false});
  const [printer, setPrinter] = React.useState({desk: false, receipt: false, none: false});
  const [form, setForm] = React.useState({});
  const [registerCount, setRegisterCount] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [login, setLogin] = React.useState('');
  const [loginError, setLoginError] = React.useState(false);
  
  const handleChange = (e) => {
    // console.log(e.target.value, e.target.name, form);
    setForm({...form, [e.target.name]: e.target.value});
  }

  const addRegister = () => {
    setRegisterCount(registerCount+1);
    setRegisters([...registers, row(registerCount + 1)]);
  }

  const removeRegister = () => {
    if (registerCount>1) {
      setRegisterCount(registerCount-1);
      const newArr = registers.slice();
      newArr.pop();
      setRegisters(newArr);
    }
  }

  const handleValidate = () => {
    if (registerCount===Object.keys(form).length) {
      return true
    } else {
      setError(true);
      return false
    }
  }

  const handleSubmitNext = () => {
    const check = handleValidate();
    if (check) {
      Object.keys(form).map(item => {
        console.log('Creating new cash drawer', form[item]);
        props.dispatch(postNewCashDrawer(currentSpace._id, currentSpace.propertyID, form[item]));
      })
    }
    setOpen(true);
    // props.handleNext();
  }

  const handleUpdate = (value) => {
    setLogin(value)
    if (value) {
      setLoginError(false)
    }
  }

  const handleLoginValidate = () => {
    if (!login || login.length!==4) {
      setLoginError(true);
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = () => {
    const check = handleLoginValidate();
    if (check) {
      const  firstName = userName.split(' ').slice(0, -1).join(' ');
      const lastName = userName.split(' ').slice(-1).join(' ');
      props.dispatch(createFrontdeskUser(
        currentSpace._id, 
        {
          firstName: firstName || lastName || 'User', 
          lastName: lastName ? lastName : firstName,
          loginPin: login, 
          role: 'appAdmin',
          propertyID
        }
      ));
      setOpen(false);
      props.handleNext();
    }
  }

  const row = (id) => {
    return (
      <div className={classes.field} key={id} style={{border: error ? '1px solid red' : '1px solid #ffffff'}}>
        <InputBase 
          id={`${id}`}
          name={`Cash Register ${id}`}
          placeholder={`Cash Register ${id}`}
          fullWidth
          type="text"
          onChange={handleChange}
        />
      </div>
    )
  }
  const [registers, setRegisters] = React.useState([row(1)]);

  return (
    <div className={classes.root}>
      
      <div className={classes.row}> 
        <div className={classes.label}>How many cash registers do you have at your reception?</div>
        <div className={classes.register}>
          <Button className={classes.button} onClick={removeRegister}><RemoveIcon /></Button>
          <span className={classes.registerNumber}>
            <Typography variant="h4">{registerCount}</Typography>
          </span>
          <Button className={classes.button} onClick={addRegister}><AddIcon /></Button>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>Name your cash registers</div>
        {registers}
        {error && <div className={classes.error}>Please assign names to your cash registers</div>}
      </div>

      <div className={classes.row}>
        <div className={classes.label}>What computer devices do you have at your reception?</div>
        <div className={classes.options}>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: computer.desktop})} 
            onClick={() => setComputer({...computer, desktop: !computer.desktop})}
          >
            Desktop
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: computer.laptop})} 
            onClick={() => setComputer({...computer, laptop: !computer.laptop})}
          >
            Laptop
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: computer.android})} 
            onClick={() => setComputer({...computer, android: !computer.android})}
          >
            Android Tablet
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: computer.ipad})} 
            onClick={() => setComputer({...computer, ipad: !computer.ipad})}
          >
            iPad
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: computer.other})} 
            onClick={() => setComputer({...computer, other: !computer.other})}
          >
            Other
          </div>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.label}>What printers do you use?</div>
        <div className={classes.options}>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: printer.desk})} 
            onClick={() => setPrinter({...printer, desk:!printer.desk})}
          >
            Standard desk printer
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: printer.receipt})} 
            onClick={() => setPrinter({...printer, receipt: !printer.receipt})}
          >
            Receipt printer
          </div>
          <div 
            className={clsx({[classes.optionItem]: true, [classes.selected]: printer.none})} 
            onClick={() => setPrinter({...printer, none:!printer.none})}
          >
            None
          </div>
        </div>
      </div>

      <div className={classes.row}>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmitNext}>
          Next
        </Button>
      </div>

      {open &&
        <Dialog
          open={open}
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.content}>
            <div className={classes.lastThing}>Admin User Setup</div>
            <div className={classes.desc}>Please enter a 4 digit PIN code for your personal access</div>
            <div 
              className={classes.number} 
              style={{
                borderLeft: loginError ? '1px solid red' : '0px solid #ffffff',
                paddingLeft: loginError ? '5px' : 0,
              }}
            >
              <ReactCodeInput type='number' fields={4} onChange={handleUpdate} {...codeStyle} />
            </div>
            {loginError && <div className={classes.error}>Please enter a valid PIN code</div>}
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
                Next
              </Button>
            </div>
          </div>
        </Dialog>
      }

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  userName: state.auth.user.name,
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID,
})

export default connect(mapStateToProps)(ReceptionForm);