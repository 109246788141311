const rates = {};

export default (state = rates, action) => {
  switch (action.type) {
    case "LOAD_RATES_SUCCESS":
      return {
        ...action.payload
      };
    case "EDIT_RATES_SUCCESS":
      const updatedRoomType = action.payload.roomTypeID;
      const ratePlansArray = state[updatedRoomType].ratePlans;
      const foundIndex = ratePlansArray.findIndex(e => e.ratePlanID === 'default')
      const arr = ratePlansArray[foundIndex].data.slice();

      action.payload.data.map(item => {
        const match = arr.findIndex(e => e.outputDate === item.date); 
        if (item.available) {
          arr[match] = {...arr[match], rate: item.rate, availability: item.available}
        } else {
          arr[match] = {...arr[match], rate: item.rate}
        }
      })

      ratePlansArray[foundIndex] = { ratePlanID: "default", data: arr}
      // console.log('rates reducer', action.payload.data, arr, ratePlansArray[foundIndex])
      return{
        ...state,
        [updatedRoomType]: {
          ...state[updatedRoomType],
          ratePlans: [
            ...ratePlansArray
          ]
        }
      }
    case "EDIT_RESTRICTIONS_SUCCESS":
      const restrictionRoomType = action.payload.roomTypeID;
      const restrictionRatePlan = action.payload.ratePlanID;
      // console.log('STEP 1', restrictionRoomType, restrictionRatePlan, action.payload);
      const restrictionsArray = state[restrictionRoomType].ratePlans;

      const restrictionIndex = restrictionsArray.findIndex(e => e.ratePlanID === restrictionRatePlan)
      const newRestArr = restrictionsArray[restrictionIndex].data.slice();

      // console.log('STEP 2', newRestArr);

      action.payload.data.map(item => {
        const match = newRestArr.findIndex(e => e.outputDate === item.outputDate); 

        if (item.stopSell !== undefined) { newRestArr[match].stopSell = item.stopSell }
        if (item.minLOS !== undefined) { newRestArr[match].minLOS = item.minLOS }
        if (item.maxLOS !== undefined) { newRestArr[match].maxLOS = item.maxLOS }
        if (item.CTA !== undefined) { newRestArr[match].CTA = item.CTA }
        if (item.CTD !== undefined) { newRestArr[match].CTD = item.CTD }
        
      })

      restrictionsArray[restrictionIndex] = { ratePlanID: restrictionRatePlan, data: newRestArr}
      console.log('STEP 3', restrictionsArray[restrictionIndex])
      return{
        ...state,
        [restrictionRoomType]: {
          ...state[restrictionRoomType],
          ratePlans: [
            ...restrictionsArray
          ]
        }
      }
    case "RESET_RATES": 
      return {}
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};