import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { fetchCurrencyFormat } from '../../../utils/utility';
import Pill from '../../common/Pill';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme =>({
  buttonContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  bubble:{
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: 10,
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
      padding: 10,
    }
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  pillContainer: {
    display: 'flex',
    marginTop: 10,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginLeft: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50,
  },
  fieldContainer: {
    borderRadius: 5,
    boxShadow: '0 1px 10px #E0E0E0',
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
    width: '60%',
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex'
  },
  percent: {
    marginLeft: 10
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 25,
    display: 'flex',
    width: '200px',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  toggleSelect: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
}));

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    borderRadius: 5,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const Derived = props =>{
    const classes = useStyles();
    const {
      ratePlans,
      ratePlan,
      currentSpace,
      handleUpdateRatePlan
    } = props;
    const [toggle, setToggle] = useState((ratePlan.derivedRate && ratePlan.derivedRate.value !=='') ? Math.sign(ratePlan.derivedRate.value) !== -1 : true);
    const [ratePlanOptions, setRatePlanOptions] = useState([])
    const [parentPlans, setParentPlans] = useState({})
    const currency = fetchCurrencyFormat(ratePlan.multiCurrency && ratePlan.multiCurrency.enabled ?
       ratePlan.multiCurrency.currency :
       (currentSpace.currency ? currentSpace.currency : null)
    )

    React.useEffect(() => {
      let plans = {}
      Object.keys(ratePlans).map(rp => {
        const rPlan = ratePlans[rp]
        if(!rPlan.isDerived && !plans[rp])
          plans[rp] = rPlan
      })
      setParentPlans(plans)
      setRatePlanOptions(Object.keys(plans))
    }, [ratePlans])

    const handleUpdate = (key, value) => {
      console.log(key, value, ratePlan.derivedRate);
      handleUpdateRatePlan('derivedRate', {...ratePlan.derivedRate, [key]: value});
    }

    const handleToggle = (newToggle) => {
      if (ratePlan.derivedRate.value !== '') {
        setToggle(newToggle);
        handleUpdateRatePlan('derivedRate', {...ratePlan.derivedRate, value: Math.abs(ratePlan.derivedRate.value)*(newToggle ? 1 : -1)});
        // console.log(newToggle, Math.abs(ratePlan.derivedRate.value)*(newToggle ? 1 : -1));
      } else {
        setToggle(newToggle);
      }
    }

    return(
        <>
            <div className="main-content">

                <div className="main-content-title">Rates</div>
                <div className="sub-title">Setup a stand alone rate plan or let counter derive rates from another rate plan</div>

                <div className="row">
                  <div className={classes.buttonContainer}>
                    <div
                      className={clsx({[classes.bubble]: true, [classes.selected]: ratePlan.isDerived===false})}
                      onClick={() => handleUpdateRatePlan('isDerived', false)}
                    >
                      Stand Alone Rates
                    </div>
                    <div
                      className={clsx({[classes.bubble]: true, [classes.selected]: ratePlan.isDerived})}
                      onClick={() => handleUpdateRatePlan('isDerived', true)}
                    >
                      Derived Rates
                    </div>
                  </div>
                </div>


                {ratePlan.isDerived && <Grid container spacing={3} alignItems="center">

                  {/* Rate Plan List */}
                  {/* ------------------------------------ */}
                  <Grid item xs={12}>
                    <div className="label">Base Rate Plan</div>
                    <div className={classes.pillContainer}>
                      {ratePlan.derivedRate.reference === '' && (ratePlanOptions.length > 0) && ratePlanOptions.map(ratePlan =>
                        <Tooltip
                          title={parentPlans[ratePlan]?.multiCurrency?.enabled ? 'Derived rates from a multi-currency base rate is not supported' : ''}
                          key={ratePlan}
                        >
                          <div>
                            <Pill
                              name={parentPlans[ratePlan].publicName ? parentPlans[ratePlan].publicName : ''}
                              handleClick={() => {
                                if (!parentPlans[ratePlan]?.multiCurrency?.enabled)
                                  handleUpdate('reference', ratePlan)
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                      {ratePlan.derivedRate.reference !== '' &&
                        <Pill
                          name={parentPlans[ratePlan.derivedRate.reference] && parentPlans[ratePlan.derivedRate.reference].publicName}
                          selected={true}
                          cancelButton={true}
                          handleClick={() => handleUpdate('reference', '')}
                        />
                      }
                    </div>
                  </Grid>

                  {/* Increase / Descrease Toggle */}
                  {/* ------------------------------------ */}
                  <Grid item xs={12}>
                    <div className={classes.toggle}>
                      <div
                        className={clsx({[classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: !toggle})}
                        onClick={() => handleToggle(false)}
                      >
                        Decrease
                      </div>
                      <div
                        className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: toggle})}
                        onClick={() => handleToggle(true)}
                      >
                        Increase
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.valueContainer}>

                      {/* Amount / Percent Toggle */}
                      {/* ------------------------------------ */}
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="percentOrAmount"
                          name="percentOrAmount"
                          value={ratePlan.derivedRate.percentOrAmount}
                          onClick={(e) => handleUpdate('percentOrAmount', e.target.value)}
                          row
                        >
                          <FormControlLabel
                            value="amount"
                            control={<Radio color="secondary" />}
                            label={"Amount"}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="percent"
                            control={<Radio color="secondary" />}
                            label={"Percent"}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>

                      {/* Value */}
                      {/* ------------------------------------ */}
                      {ratePlan.derivedRate.percentOrAmount=='amount' ? currency : null}
                      <div className={classes.field}>
                        <InputBase
                          id="value"
                          name="value"
                          defaultValue={ratePlan.derivedRate.value !=='' ? Math.abs(ratePlan.derivedRate.value) : ''}
                          fullWidth
                          inputProps={{min: 0}}
                          type="amount"
                          onChange={(e) => handleUpdate('value', e.target.value==='' ? '' : (Math.abs(parseFloat(e.target.value)) * (toggle ? 1 : -1)) )}
                        />
                      </div>
                      {ratePlan.derivedRate.percentOrAmount==='percent' && <div className={classes.percent}>%</div>}

                    </div>
                  </Grid>

                </Grid>}

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  ratePlans: state.ratePlans,
  currentSpace: state.spaces[state.dashboard.currentSpace],
})

export default connect(mapStateToProps)(Derived);