import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Reservation from '../components/reservations/Reservation';
import { resetReservationDetails } from '../redux/actions/beds/beds';
import { resetReservationManualOptions } from '../redux/actions/rooms/rooms';
import { getReservationDetailsViaCode } from '../redux/actions/reservation/editReservation';
import { CircularProgress, Snackbar } from '@material-ui/core';
import { resetReducerState } from '../redux/actions/common/common';
import http from "../redux/utils/http";
import { setSpaceChannelStatus } from '../redux/actions/dashboard/dashboard';

const useStyles = makeStyles(theme => ({

}));

const ReservationContainer = (props) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	const [enableReservation, setEnableReservation] = useState(false)
	const { currentSpace = {} } = props
	let pathname = window?.location?.pathname;

	useEffect(() => {
		if (pathname?.includes("/reservation/") && !open) {
			let params = pathname.replace("/reservation", "").split("/")
			if (params[params.length - 2] && params[params.length - 1]) {
				props.dispatch(resetReservationDetails())
				props.dispatch(getReservationDetailsViaCode(params[params.length - 2], params[params.length - 1]));
				setEnableReservation(true)
				setOpen(true)
			}
		}
	}, [])

	useEffect(() => {
		if(currentSpace && currentSpace.propertyID){
			fetchChannelStatus(currentSpace.propertyID)
		}
	}, [currentSpace.propertyID])

	const fetchChannelStatus = (propertyID) => {
		http.get(`/api/frontdesk/channels/status/${propertyID}`)
			.then(successResult => {
				let data = successResult.data || [];
				// let data = [{ "name": "Hostelworld", "status": "true", "totalRoomCount": 7, "mappedRoomCount": 6 }, { "name": "BookingCom", "status": "true", "totalRoomCount": 7, "mappedRoomCount": 6 }, { "name": "AirBNB", "status": "true", "totalRoomCount": 7, "mappedRoomCount": 6 }, { "name": "Expedia", "status": "true", "totalRoomCount": 7, "mappedRoomCount": 6 }];
				let list = {};
				data.map(a => { if (a.status) list[a.name] = a.status });
				props.dispatch(setSpaceChannelStatus(list, props.currentSpace._id))
			})
			.catch(errorResult => {
				props.dispatch(setSpaceChannelStatus({}, props.currentSpace._id))
				console.log('error ', errorResult);
			});
	}

	useEffect(() => {
		if (props.errors) {
			setOpen(false)
		}
	}, [props.errors])


	const handleCloseReservation = () => {
		props.dispatch(resetReservationDetails());
		props.dispatch(resetReservationManualOptions())
		setOpen(false)
		setEnableReservation(false);
		
		// if (props.currentModule && props.currentModule !== "") {
		// 	if (props.currentSubModule && props.currentSubModule !== "") {
		// 		window.history.replaceState({}, document.title, `/${props.currentModule}/${props.currentSubModule}`);
		// 	} else {
		// 		window.history.replaceState({}, document.title, `/${props.currentModule}`);
		// 	}
		// } else {
		// 	window.history.replaceState({}, document.title, "/");
		// }

		let path = '/';
		path += props?.currentModule || '';
		path += props?.currentSubModule && `/${props.currentSubModule}` || '';
		window.history.replaceState({}, document.title, path);
	}

	const handleCloseError = () => {
		props.dispatch(resetReducerState('LOAD_RESERVATION'))
		handleCloseReservation()
	}

	return (
		<div className={''}>
			{enableReservation ?
				<React.Fragment>
					{open &&
						<Reservation
							open={open}
							handleCloseReservation={handleCloseReservation}
						/>
					}

					{/* ------------- Snackbar error messages -------------- */}
					{props.errors && (
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							open={true}
							autoHideDuration={6000}
							onClose={() => handleCloseError()}
							ContentProps={{
								'aria-describedby': 'message-id',
							}}
							message={<span id="message-id">{props.errors}</span>}
						/>
					)}

				</React.Fragment> : null}
		</div>
	);
}

const mapStateToProps = state => ({
	currentSpace: state.spaces[state.dashboard.currentSpace] || {},
	loading: state.loading.LOAD_RESERVATION,
	currentModule: state.dashboard.currentModule,
	currentSubModule: state.dashboard.currentSubModule,
	errors: state.errors.LOAD_RESERVATION,
	success: state.success.LOAD_RESERVATION,
})

export default connect(mapStateToProps)(ReservationContainer);