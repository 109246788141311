import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import { fetchCurrencyFormat, getNumberAbbr } from "../../utils/utility";
import {
  loadNoShows,
  resetNoShows,
  loadNoShowsByDay,
} from "../../redux/actions/reports/noShows";
import {
  channelNoShowSelector,
  roomTypeNoShowSelector,
  propertyNoShowSelector,
} from "../../redux/selectors/reports/noShows";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import {
  processNoShowGraph,
  processNoShowReservationsList,
} from "../../redux/selectors/reports/noShows";
import BarChart from "../charts/BarChart";
import { groupingConfig } from "../utils/excelExport";
import Reservation from "../reservations/Reservation";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import PieChart from "../charts/PieChart";
import Hidden from "@material-ui/core/Hidden";
import MaterialTable from "material-table";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { UNALLOCATED } from "../../utils/constants";
import { WORKSPACE_URL } from "../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  noShowGraph: {
    height: 400,
    width: "100%",
  },
  pieContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  pie: {
    height: 300,
  },
  category: {
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: 30,
    textAlign: "center",
  },
  blankSlate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "300px",
    width: "100%",
  },
  errorState: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 300,
    width: "100%",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const BasicExport = (props) => {
  const { t, openInNewTab, currencyData } = props;
  const rowCount = props.data.length;
  const tableTitle = t("reports.noShows");
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  const data = props.data.map((d) => {
    if (d.roomTypes) return d;
    return { ...d, roomTypes: UNALLOCATED };
  });
  let excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "currency",
    "currency",
  ];
  let excelColWidth = [20, 40, 15, 15, 40, 20, 30, 30];

  const groupingConfigParmas = {
    data,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("reports.table.resvCode"),
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.guestName"),
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.roomType"),
          field: "roomTypes",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.bookingSource"),
          field: "bookingSource",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: `${t("reports.table.reservationAmount")} (${
            currencyData.code
          })`,
          field: "totalAmount",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: `${t("reports.table.outBalance")} (${currencyData.code})`,
          field: "balance",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={data}
      isLoading={props.isLoading}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: "1.2rem" },
        headerStyle: {
          zIndex: "unset",
          backgroundColor: "#F6F2EE",
        },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: "open_in_new",
          tooltip: "Open in a new tab",
          onClick: (event, rowData) => openInNewTab(rowData.resvCode),
        },
      ]}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
        body: {
          emptyDataSourceMessage: "No Reservations Found",
        },
      }}
      onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  );
};

const NoShow = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "monthToDate", label: t("reports.presetRange.monthToDate") },
    { value: "yearToDate", label: t("reports.presetRange.yearToDate") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "past90", label: t("reports.presetRange.past90") },
    { value: "past180", label: t("reports.presetRange.past180") },
    { value: "past365", label: t("reports.presetRange.past365") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    currentProperty,
    loadingNoShows,
    loadingNoShowsByDay,
    errorNoShows,
    propertyNoShows,
    noShowsBySource,
    noShowsByRoomType,
  } = props;
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[2]);
  const [level, setLevel] = React.useState("daily");
  const [openReservation, setOpenReservation] = React.useState(false);
  const [goFullScreen, setGoFullscreen] = React.useState(false);

  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };
  const tableData = useSelector((state) =>
    processNoShowReservationsList(
      state.reports.noShowByDay,
      state.roomTypes,
      submittedDates,
      currencyData
    )
  );
  const graphData = useSelector((state) =>
    processNoShowGraph(state.reports.noShows, submittedDates, level)
  );

  React.useEffect(() => {
    props.dispatch(
      loadNoShows(
        currentSpace.propertyID,
        moment().subtract(15, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        "daily"
      )
    );
    props.dispatch(
      loadNoShowsByDay(
        currentSpace.propertyID,
        moment().subtract(15, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
    document.title = `${t("reports.noShows")} ${t("reports.report")} | Counter`;
    return function cleanup() {
      props.dispatch(resetNoShows());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    let newLevel = "daily";
    if (e.value == "custom") {
      return;
    } else if (e.value === "monthToDate") {
      reportFrom = moment().startOf("month");
    } else if (e.value === "yearToDate") {
      reportFrom = moment().startOf("year");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    const newDiff = reportTo.diff(reportFrom, "days");
    if (newDiff > 90) {
      newLevel = "monthly";
    } else if (newDiff > 31) {
      newLevel = "monthly";
    }
    props.dispatch(
      loadNoShows(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD"),
        newLevel
      )
    );
    props.dispatch(
      loadNoShowsByDay(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD")
      )
    );
    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setLevel(newLevel);
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  const openInNewTab = (resvCode) => {
    window.open(
      `${WORKSPACE_URL}/${currentProperty.code}/reservation/${resvCode}`,
      "_blank"
    );
  };

  const handleSubmit = () => {
    let calcLevel = "daily";
    const difference = dates.endDate.diff(dates.startDate, "days");
    if (difference > 90) {
      calcLevel = "monthly";
    } else if (difference > 31) {
      calcLevel = "monthly";
    }
    props.dispatch(
      loadNoShows(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD"),
        calcLevel
      )
    );
    props.dispatch(
      loadNoShowsByDay(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD")
      )
    );
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
    setLevel(calcLevel);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("reports.noShows")} {t("reports.report")}
          </div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.NO_SHOWS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[7]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder={true}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <div className={classes.button} onClick={handleSubmit}>
                    {t("reports.transactionPage.submit")}
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* --------------------------------------------------------- */}
            {/* LOADING STATE*/}
            {/* --------------------------------------------------------- */}
            {loadingNoShows && (
              <div className={classes.blankSlate}>
                <CircularProgress />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* NO SHOW GRAPH*/}
            {/* --------------------------------------------------------- */}
            {errorNoShows === "" && graphData.length !== 0 && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <div className={classes.noShowGraph}>
                  {!loadingNoShows && (
                    <BarChart data={graphData} keyName={"no shows"} />
                  )}
                </div>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* PIE CHARTS */}
            {/* --------------------------------------------------------- */}
            {!loadingNoShows &&
              (noShowsByRoomType.length !== 0 ||
                noShowsByRoomType.length !== 0) && (
                <div
                  className={classes.listSection}
                  style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
                >
                  <Grid container>
                    {noShowsBySource.length !== 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.pieContainer}
                      >
                        <div className={classes.category}>
                          {t("reports.noShowPage.noShowBookingSource")}
                        </div>
                        <div className={classes.pie}>
                          <PieChart data={noShowsBySource} />
                        </div>
                      </Grid>
                    )}
                    {noShowsByRoomType.length !== 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.pieContainer}
                      >
                        <div className={classes.category}>
                          {t("reports.noShowPage.noShowRoomType")}
                        </div>
                        <div className={classes.pie}>
                          <PieChart data={noShowsByRoomType} />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}

            {/* --------------------------------------------------------- */}
            {/* NO SHOW SUMMARY BUBBLES*/}
            {/* --------------------------------------------------------- */}
            {!loadingNoShows && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {currency}
                        {getNumberAbbr(propertyNoShows.paid)}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.subHeading.totalAmount")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {currency}
                        {getNumberAbbr(propertyNoShows.balance)}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.subHeading.lostRevenue")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {propertyNoShows.percent}%
                      </div>
                      <div className={classes.desc}>
                        {t("reports.subHeading.collectionRate")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {propertyNoShows.count}
                      </div>
                      <div className={classes.desc}>
                        {t("reports.noShowPage.totalNoShow")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* RESERVATION TABLE */}
            {/* --------------------------------------------------------- */}
            {!loadingNoShowsByDay && (
              <div className={classes.listSection}>
                <BasicExport
                  t={t}
                  data={tableData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  isLoading={loadingNoShowsByDay}
                  handleTableRowClick={handleTableRowClick}
                  openInNewTab={openInNewTab}
                  currencyData={currencyData}
                />
              </div>
            )}

            {/* Open reservation when transaction item clicked */}
            {/* ------------------------------------------------------------------ */}
            {openReservation && (
              <Reservation
                open={openReservation}
                handleCloseReservation={handleCloseReservation}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentProperty: state.property,
  loadingNoShows: state.loading.LOAD_NO_SHOW_REPORT,
  loadingNoShowsByDay: state.loading.LOAD_NO_SHOW_BY_DAY,
  errorNoShows: state.errors.LOAD_NO_SHOW_REPORT,
  propertyNoShows: propertyNoShowSelector(
    state.reports.noShows,
    "propertyNoShows",
    "noShows"
  ),
  noShowsBySource: channelNoShowSelector(
    state.reports.noShows,
    "propertyChannelNoShows"
  ),
  noShowsByRoomType: roomTypeNoShowSelector(
    state.reports.noShows,
    "roomTypeNoShows",
    state.roomTypes
  ),
});

export default withTranslation()(connect(mapStateToProps)(NoShow));
