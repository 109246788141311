import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from "clsx";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { displayProductDetail } from '../../../redux/actions/experiences/calendar';
import { getCurrency } from '../../../utils/utility';

const useStyles = makeStyles(theme => ({
    main:{
        padding: '0px 20px'
    },  
    root:{
        boxShadow: '0 3px 4px 0 #ECECEC',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
        '&.isActive': {
            backgroundColor: '#F8F9FB',
        },
        '&.isActive $arrowContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%',
        },
    },
    activityInfoContainer:{
        padding: '15px 20px',
        display: 'flex'
    },
    titleContainer:{
        width: '80%',
        color: '#4f4f4f',
        '& .title':{
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        '& .time':{
            fontSize: '1.2rem',
            fontWeight: 600,
            color: '#bdbdbd',
            marginTop: '10px',
        },
        '& .cost':{
            fontSize: '1.2rem',
            fontWeight: 600,
            marginTop: '20px',
        },
    },
    arrowContainer:{
        display: 'none',
    },
    arrowIcon:{
        fontSize: '2rem',
        fill: '#a4a4a4'
    },
    slotsAvailable:{
        textAlign: 'center',
        padding: '10px',
        fontSize: '1.2rem',
        fontWeight: 600,
        boxShadow: '0 2px 20px #F0F0F0',
        borderRadius: '5px',
        color: '#CC6633',
        backgroundColor: '#fff'
    }
}));    

const ActivityList = props =>{
    const { productList = [], productInfo = {}, dispatch, t } = props;
    const classes = useStyles();
    const [selectedProductID, setSelectedProductID] = useState(null);

    useEffect(()=>{
        if(productList && productList[0]) {
            const productID = productList[0].draftID;
            selectProductHandler(productID, 0);
            setSelectedProductID(productID+'_0');
        }
    }, [productList, productInfo]);

    const getMergedParticipants = (participants) => {
        let array = participants ? [...participants] : [];
        let finalData = {};
        array.map(itemData => {
            let item = {...itemData}
            if (finalData[item._id]) {
                finalData[item._id].products.quantity += item.products.quantity
            } else
                finalData[item._id] = { ...item };
            return itemData;
        })

        return finalData ? Object.values(finalData): [];
    }

    const selectProductHandler = (productID, index) =>{
        if(!productInfo || !productInfo[productID]) return;
        const startTime = moment(`${productList[index].date} ${productList[index].startTime}`).format('h:mm a');
        const endTime = moment(`${productList[index].date} ${productList[index].endTime}`).format('h:mm a');

        const imageUrl = productInfo[productID].photos && 
            productInfo[productID].photos.length > 0 ? 
            productInfo[productID].photos[0].original : '';

        const participants = getMergedParticipants([...productList[index].participants]);

        const data = {
            productID,
            productName: productInfo[productID].title,
            location: productInfo[productID].locationName,
            duration: productList[index].allDay ? `All day starts at ${startTime}` : `${startTime} - ${endTime}`,
            conductorID: productList[index].conductorID,
            participants: participants,
            available: productList[index].available,
            slotID: productList[index].slotID,
            date: productList[index].date,
            currencyData: {
                code: productList[index].currency,
                country: productList[index].country
            },
            imageUrl,
            price: productList[index].price
        }
        dispatch(displayProductDetail(data));
        setSelectedProductID(productID+'_'+index);
    }

    return(
        <>
            {productList.map((item, index) =>{
                
                const productTitle = productInfo && productInfo[item.draftID] ? productInfo[item.draftID].title : '';
                
                return(
                    <div className={classes.main} key={`${item.draftID}_${index}`}>
                        <div className={clsx(
                                classes.root, 
                                { isActive: `${item.draftID}_${index}` == selectedProductID }
                            )}
                            onClick={()=>selectProductHandler(item.draftID, index)}
                        >
                            <div className={classes.activityInfoContainer}>
                                <div className={classes.titleContainer}>
                                    <div className="title">{productTitle}</div>
                                    <div className="time">
                                        {`${moment(`${item.date} ${item.startTime}`).format('h:mm a')} - ${moment(`${item.date} ${item.endTime}`).format('h:mm a')}`}
                                    </div>
                                    <div className="cost">
                                        {item.price!==0 && `${getCurrency({ code: item.currency, country: item.country }, item.price)} ${t('experiences.calendar.perPerson')}`}
                                        {item.price===0 && t('experiences.calendar.free')}
                                    </div>
                                </div>
                                <div className={classes.arrowContainer}>
                                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                                </div>
                            </div>
                            <div className={classes.slotsAvailable}>
                                {item.available} {t('experiences.calendar.slotsAvailable')}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

const mapStateToProps = state =>{
    const { experiences } = state;
    return{
        productList: experiences.displayProductList,
        productInfo: experiences.activityList
    }
}

export default withTranslation()(connect(mapStateToProps)(ActivityList));