import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ChatMessage from "./ChatMessage";
import moment from "moment";
import _ from "lodash";

import Emoji from "./Emoji";
import CloseIcon from "@material-ui/icons/Close";
import { returnCurrent } from "../../redux/selectors/dashboard";
import commaSeparatedList from "../utils/commaSeparatedList";
import { setCurrentChat } from "../../redux/actions/dashboard/dashboard";

import { CircularProgress, Dialog } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { fetchDatePerTimezone } from "../../utils/utility";
import { NODE_ENV, API_BASE_URL } from "../../config";

const styles = (theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  dialog: {},
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
    [theme.breakpoints.down("sm")]: {
      "& h4": {
        fontSize: "1.6rem",
      },
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: 50,
      paddingLeft: 50,
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      right: 8,
      top: 8,
    },
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
  },
  subTitle: {
    color: "#000000",
    marginTop: 5,
    fontSize: "1.4rem",
  },
  messageBox: {
    minHeight: "400px",
    height: "calc(100vh - 280px)",
    overflowY: "scroll",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 120px)",
    },
  },
  inputBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "relative",
  },
  emojiToggle: {
    color: "#999999",
    position: "absolute",
    right: 25,
    top: 22,
  },
  inputText: {
    "& div": {
      padding: "10px",
      paddingRight: "40px",
    },
  },
  submit: {
    marginLeft: 20,
    minWidth: "100px",
    maxHeight: "70px",
  },
});

const io = require("socket.io-client");
const baseURL =
  NODE_ENV === "production"
    ? API_BASE_URL
    : "http://localhost:3001";
var socket;

// Making the App component
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      messages: [],
      input: "",
      showEmoji: false,
    };

    socket = io(baseURL, {
      query: `token=${localStorage.jwtToken}&spaceID=${this.props.currentChatID}`,
    });
  }

  scrollToBottom = () => {
    this.messagesEnd && this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  handleChange = (e) => {
    this.setState({ input: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && this.state.input != "") {
      e.preventDefault();
      socket.emit("sendMessage", this.state.input, () => {
        console.log("message sent and processed");
      });
      this.setState({ input: "" });
    }
  };

  emojiToggle = (e) => {
    this.setState({ showEmoji: true });
  };

  addEmoji = (e) => {
    // console.log(e.native)
    let emoji = e.native;
    this.setState((prevState) => ({
      input: prevState.input + emoji,
      showEmoji: false,
    }));
  };

  componentDidMount = () => {
    this.scrollToBottom();

    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });

    socket.emit("frontEndLoad", (messageHistory) => {
      console.log("Loading previous messages - ", messageHistory);
      this.setState((prevState) => ({
        firstLoad: true,
        messages: [...messageHistory],
      }));
    });

    socket.on("message", (message) => {
      console.log("new message", message);
      this.setState((prevState) => ({
        messages: [message, ...prevState.messages],
      }));
    });
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    // console.log('leave room');
    socket.disconnect();
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  }

  handleClose = () => {
    this.setState({ messages: [], firstLoad: false });
    this.props.dispatch(setCurrentChat());
  };

  render() {
    const {
      classes,
      user,
      currentChatID,
      currentSpaceID,
      currentSpace,
      currentSpaceType,
      currentChat,
      users,
    } = this.props;
    var sameDay = false;
    var thread = false;
    var lastId = "";
    var lastDay = fetchDatePerTimezone(null, currentSpace.timezone);
    var currentUser = false;
    const spaceChat = currentChatID === currentSpaceID;

    return (
      <Dialog
        open={Boolean(currentChatID) || false}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.dialogPaper }}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={window.innerWidth < 901}
      >
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            {/* ------------------------------------------------ */}
            {/* TEAM CHAT HEADER */}
            {/* ------------------------------------------------ */}
            {spaceChat === true && (
              <div>
                <div className={classes.header}>
                  {currentSpaceType === "Hostel" ? "Team" : "Group"} Chat
                </div>
                <div className={classes.subTitle}>{currentSpace.name}</div>
              </div>
            )}

            {/* ------------------------------------------------ */}
            {/* PRIVATE CHAT HEADER */}
            {/* ------------------------------------------------ */}
            {spaceChat === false && currentChat && (
              <div>
                <div className={classes.header}>Private Message</div>
                <div className={classes.subTitle}>
                  {commaSeparatedList(users, currentChat.members)}
                </div>
              </div>
            )}
            <CloseIcon
              className={classes.closeIcon}
              onClick={this.handleClose}
            />
          </div>

          {/* ------------------------------------------------ */}
          {/* CHAT BODY */}
          {/* ------------------------------------------------ */}
          <div className={classes.messageBox}>
            {this.state.firstLoad &&
              this.state.messages
                .concat()
                .reverse()
                .map((message, index) => {
                  message.senderID === lastId
                    ? (thread = true)
                    : (thread = false);
                  message.senderID === user._id
                    ? (currentUser = true)
                    : (currentUser = false);
                  lastId = message.senderID;
                  fetchDatePerTimezone(
                    message.createdAt,
                    currentSpace.timezone
                  ).isSame(lastDay, "date")
                    ? (sameDay = true)
                    : (sameDay = false);
                  lastDay = fetchDatePerTimezone(
                    message.createdAt,
                    currentSpace.timezone
                  );
                  return (
                    <ChatMessage
                      key={index}
                      message={message}
                      thread={thread}
                      sameDay={sameDay}
                      currentUser={currentUser}
                      timezone={currentSpace.timezone}
                    />
                  );
                })}
            {this.state.showEmoji && (
              <Emoji
                open={this.state.showEmoji}
                addEmoji={this.addEmoji}
                clickedOutside={() => this.setState({ showEmoji: false })}
              />
            )}
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>

          {/* ------------------------------------------------ */}
          {/* CHAT INPUT */}
          {/* ------------------------------------------------ */}
          {currentSpaceID !== "5eb29a4a2826ed0ecfce6ca9" && (
            <div className={classes.inputBox}>
              <TextField
                className={classes.inputText}
                placeholder="Type message here..."
                autoFocus
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.input}
                autoFocus
                onKeyDown={this.handleKeyDown}
              />
              <TagFacesIcon
                className={classes.emojiToggle}
                onClick={this.emojiToggle}
              />
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}

Chat.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  users: state.users,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpaceType: state.dashboard.currentSpaceType,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentChatID: state.dashboard.currentChat,
  currentChat: returnCurrent(state.messages, state.dashboard.currentChat),
});

export default withStyles(styles)(connect(mapStateToProps)(Chat));
