import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import NewRoomType from './NewRoomType';
import RoomTypeList from './RoomTypeList';

import AddIcon from '@material-ui/icons/Add';
import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  addContainer: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px dashed #999999',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  addText: {
    color: '#666666',
    marginBottom: 5,
  },
  addIcon: {
    color: '#666666',
    fontSize: '4rem'
  },
  skip: {
    color: '#AAAAAA',
    cursor: 'pointer',
    marginRight: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    width: '100%',
  },
  next: {
    background: '#BB5533',
    borderRadius: '5px',
    boxShadow: 'none',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '1.4rem',
    padding: '8px 20px',
    '&:hover': {
      background: '#BB5533',
      boxShadow: 'none'
    }
  }
}))

const RoomTypes = (props) => {

  const classes = useStyles();
  const { roomTypes, propertyID, t } = props;
  const [newRooms, setNewRooms] = React.useState([])
  const [roomIndex, setRoomIndex] = React.useState(0)

  useEffect(() => {
    // handleAddNewRoom();
  })

  const handleClear = (index) => {
    const array = newRooms;
    array.splice(index);
    setNewRooms(array);
  }

  const handleAddNewRoom = () => {
    setNewRooms(
      [...newRooms,
        <div key={roomIndex} className={classes.row}>
          <NewRoomType index={roomIndex} handleCancel={handleClear} />
        </div>
      ]
    );
    setRoomIndex(roomIndex+1);
  }

  return (
    <div className={classes.root}>

      {roomTypes.length===0 && <div className={classes.row}>
        <Typography variant="h6">
          {t('onboarding.roomType.enterAllTypes')}
        </Typography>
      </div>}

      <RoomTypeList />
    
      {newRooms}  

      <div className={classes.row}>
        <div className={classes.addContainer} onClick={handleAddNewRoom}>
          <Typography variant="h6" className={classes.addText}>{t('onboarding.roomType.addRoomType')}</Typography>
          <AddIcon className={classes.addIcon} />
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.skip} onClick={props.handleSkip}>{t('onboarding.skip')}</div>
        <Button variant="contained" className={classes.next} onClick={props.handleNext}>
          {t('onboarding.complete')}
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID,
  roomTypes: state.spaces[state.dashboard.currentSpace].roomTypes || {}
})

export default withTranslation()(connect(mapStateToProps)(RoomTypes));