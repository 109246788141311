import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import moment from 'moment';

import CommentsHeader from './CommentsHeader';
import NewComment from './NewComment';
import CommentDisplay from '../utils/CommentDisplay';
import { initials } from '../utils/wordUtils';
import { returnCurrent } from "../../redux/selectors/dashboard";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { fetchDatePerTimezone } from '../../utils/utility';

const useStyles = makeStyles( theme => ({
  commentHolder: {
    alignContent: "center",
    display: "flex",
    justifyContent: 'flex-start',
    marginBottom: 10,
    width: "100%"
  },
  avatarContainer: {
    position: 'relative',
    width: '60px'
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: '1px solid #dddddd',
    color: '#ffffff',
    fontSize: '2rem',
    lineHeight: 2,
    position: 'absolute',
    textAlign: 'center',
    top: '10px'
  },
  comment: {
    background: '#eeeeee',
    borderRadius: '5px',
    padding: theme.spacing(2),
    width: "100%"
  },
  commentHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px'
  },
  commenter: {
    '& h6': {
      fontWeight: 600
    }
  },
  time: {
    color: '#666666',
    fontSize: '1.2rem'
  },
  commentBody: {
    whiteSpace: "pre-wrap"
  }
}));

const CommentList = (props) => {

  const classes = useStyles();
  const {users, currentSpace, itemComments, allComments = []} = props

  return (
    <div>
      <CommentsHeader comments={itemComments.length}/>
      
      {itemComments.length !==0 && itemComments.map(comment => (
        <div className={classes.commentHolder} key={comment}>
          <div className={classes.avatarContainer}>
            <Avatar 
              alt={users[allComments[comment].addedBy] && initials(users[allComments[comment].addedBy].name)}
              src={users[allComments[comment].addedBy] && users[allComments[comment].addedBy].avatar} 
              className={classes.avatar} 
            >
              {users[allComments[comment].addedBy] && initials(users[allComments[comment].addedBy].name)}
            </Avatar>
          </div>
          <div className={classes.comment}>
            <div className={classes.commentHeader}>
              <div className={classes.commenter} ><Typography variant="h6">{users[allComments[comment].addedBy] && users[allComments[comment].addedBy].name}</Typography></div>
              <div className={classes.time}>
                {fetchDatePerTimezone(allComments[comment].createdAt, currentSpace.timezone).isSame(fetchDatePerTimezone(null, currentSpace.timezone), "day") ? 
                  fetchDatePerTimezone(allComments[comment].createdAt, currentSpace.timezone).format('hh:mm a') : 
                  fetchDatePerTimezone(allComments[comment].createdAt, currentSpace.timezone).format('MMM DD [at] h:mm A z')
                }
              </div>
            </div>
            
            <CommentDisplay editorContent={allComments[comment].body} />
          </div>
        </div>
      ))}

      <NewComment slatePlugins={props.slatePlugins} />

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  allComments: state.comments,
  users: state.users
})

export default connect(mapStateToProps)(CommentList);