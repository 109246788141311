export const sidebarMenu = {
    BASIC_INFORMATION: {
        id: "BASIC_INFORMATION",
        title: "Basic Information",
        subMenu: [
            {
                id: "experienceName",
                title: "Name your experience"
            },
            {
                id: "theme",
                title: "Your theme",
            },
            {
                id: "description",
                title: "Description",
            },
            {
                id: "language",
                title: "Language",
            }
        ]
    },

    EXPERIENCE_PAGE: {
        id: "EXPERIENCE_PAGE",
        title: "Experience page",
        subMenu: [
            {
                id: "activityLocation",
                title: "Where we'll be"
            },
            {
                id: "provisions",
                title: "What we'll provide"
            },
            {
                id: "requirements",
                title: "What guests should bring"
            },
            {
                id: "hostDetails",
                title: "Host details"
            },
            {
                id: "photos",
                title: "Photos"
            },
            {
                id: "startPoint",
                title: "Address"
            },
            {
                id: "groupSize",
                title: "Group size"
            },
            {
                id: "pricing",
                title: "Pricing"
            }
        ]
    },

    SETTINGS: {
        id: "SETTINGS",
        title: "Settings",
        subMenu: [
            {
                id: "bookingDetails",
                title: "Booking details",
            },
            {
                id: "variations",
                title: "Variations",
            },
            {
                id: "guidelines",
                title: "Guidelines",
            }
        ]
    },
}