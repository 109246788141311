export const sidebarMenu = {
  Property: {
    id: 'Property',
    title: 'Property Description'
  },
  Facilities: {
    id: 'Facilities',
    title: 'Facilities'
  },
  Location: {
    id: 'Location',
    title: 'Location & Directions'
  },
  Policies: {
    id: 'Policies',
    title: 'Availability Policies'
  },
  Conditions: {
    id: 'Conditions',
    title: 'Policy Information'
  },
  Payments: {
    id: 'Payments',
    title: 'Payment Methods'
  },
  Images: {
    id: 'Images',
    title: 'Images'
  }
}
