import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import http from "../../redux/utils/http";
import { addNewFile } from "../../redux/actions/files/files";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  addIcon: {
    color: "#999999"
  },
  fileInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: "row",
    paddingLeft: 10,
    marginTop: 20,
    marginBottom: 20
  },
  fileInfoTitle: {
    color: "#444444",
    minWidth: "150px",
    textAlign: 'right',
    marginRight: 20
  },
  fileInput: {
    width: "100%"
  },
  textField: {
    '& div': {
      fontSize: 14
    }
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  cancelButton: {
    marginRight: 10
  }
});

class NewFileLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success : false,
      type: "other",
      name: '',
      description: '',
      link: '',
      loading: false
    }
  }

  handleFieldChange =  (event) => {
    this.setState({[event.target.id]: event.target.value});
  }

  handleRadioChange = (event) => {
    this.setState({type: event.target.value});
  }

  handleSubmit = () => {
    this.setState({loading: true});
    http
      .post('/api/file/upload', {
        name: this.state.name, 
        url: this.state.link,
        desc: this.state.description,
        type: this.state.type,
        storage: this.props.storage,
        spaceID: this.props.currentSpaceID, 
        folderID: this.props.currentFolderID
      })
      .then(response => {
        console.log(response.data);
        this.setState({loading: false});
        this.props.dispatch(addNewFile(response.data));
        this.props.handleCancelNewFile();
      })
      .catch(error => {
        console.log(JSON.stringify(error));  
      })
  }

  render() {
    const {classes, t} = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.fileInfo}>
          <div className={classes.fileInfoTitle}>
            {this.props.storage==='googleDrive' && (<span>Google Drive Link</span>)} 
            {this.props.storage==='box' && (<span>Box Link</span>)} 
            {this.props.storage==='dropbox' && (<span>Dropbox Link</span>)} 
            {this.props.storage==='oneDrive' && (<span>OneDrive Link</span>)} 
          </div>
          <div className={classes.fileInput}>
            <TextField
              id="link"
              placeholder={t('files.link')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              value={this.state.link}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className={classes.fileInfo}>
          <div className={classes.fileInfoTitle}>{t('files.fileName')}</div>
          <div className={classes.fileInput}>
            <TextField
              id="name"
              placeholder={t('files.link')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              value={this.state.name}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        {this.props.storage === "googleDrive" && (
          <div className={classes.fileInfo}>
            <div className={classes.fileInfoTitle}>{t('files.fileType')}</div>
            <div className={classes.fileInput}> 
            <FormControl component="fieldset">
              <RadioGroup id="type" aria-label="position" name="position" value={this.state.type} onChange={this.handleRadioChange} row>
                <FormControlLabel
                  value="spreadsheet"
                  control={<Radio color="primary" />}
                  label="Sheets"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="document"
                  control={<Radio color="primary" />}
                  label="Docs"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="slides"
                  control={<Radio color="primary" />}
                  label="Slides"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" />}
                  label={t('files.other')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            </div>
          </div>
        )}
        <div className={classes.fileInfo}>
          <div className={classes.fileInfoTitle}>{t('files.description')}</div>
          <div className={classes.fileInput}> 
            <TextField
              id="description"
              placeholder={t('files.enterDescription')}
              multiline
              rows="3"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              value={this.state.description}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className={classes.submit}>
          <Button size="small" variant="outlined" className={classes.cancelButton} onClick={this.props.handleCancelNewFile}>{t('actions.cancel')}</Button>
          <Button size="small" variant="outlined" className={classes.button} onClick={this.handleSubmit}>{t('actions.submit')}</Button>
        </div>
        <Divider />
      </div>
    )
  }
}


NewFileLink.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentFolderID: state.dashboard.currentFolder,
  currentSpaceID: state.dashboard.currentSpace
})
export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(NewFileLink)));