import { toTitleCase } from '../../../components/utils/wordUtils';
import moment from 'moment';
import { UNALLOCATED } from '../../../utils/constants';

const colorArray = [
  "#4F9581",
  "#AEC1E1",
  "#F2D073",
  "#E37381",
  "#E6BBB3"
]

const propertyNoShowSelector = (reportObject, filter, filter2) => {
  let amount = 0;
  let balance = 0;
  let paid = 0;
  let percent = 0;
  let count = 0;
  if (reportObject) {
    reportObject[filter] && reportObject[filter].length!==0 && reportObject[filter].map(item => {
      amount += item.totalAmount;
      paid += item.amountPaid;
      count += item[filter2]
    })
    amount = Math.round(amount);
    paid = Math.round(paid);
    balance = amount - paid;
    if (amount !==0) percent = Math.round((paid/amount) * 100);
  }
  return {paid, balance, percent, count}
}

const getSum = (array, column) => {
  let values = array.map((item) => parseInt(item[column]) || 0)
  return values.reduce((a, b) => a + b)
}

const channelNoShowSelector = (noShows, filter) => {
  let data = [];
  var amountCount = 0;
  if (noShows && Object.keys(noShows).length!==0) {
    noShows[filter] && noShows[filter].sort((a, b) => b.totalAmount - a.totalAmount).slice(0,4).map((source,index) => {
      amountCount += source.totalAmount;
      data.push({
        "id": source.bookingSource ? source.bookingSource : 'None',
        "label": source.bookingSource ? source.bookingSource : 'None',
        "value": source.totalAmount,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (noShows && noShows[filter] && noShows[filter].length>4) {
    const totalNoShowAmount = getSum(noShows[filter], 'totalAmount');
    data.push({
      "id": "Other",
      "label": "Other",
      "value": (totalNoShowAmount - amountCount),
      "color": colorArray[4]
    })
  }

  return data;
}

const roomTypeNoShowSelector = (noShows, filter, roomTypes) => {
  let data = [];
  var amountCount = 0;
  if (noShows && Object.keys(noShows).length!==0) {
    noShows[filter] && noShows[filter].sort((a, b) => b.totalAmount - a.totalAmount).slice(0,4).map((source,index) => {
      amountCount += source.totalAmount;
      data.push({
        "id": source.roomTypeID ? roomTypes[source.roomTypeID].name : UNALLOCATED,
        "label": source.roomTypeID ? roomTypes[source.roomTypeID].name : UNALLOCATED,
        "value": source.totalAmount,
        "color": colorArray[index] ? colorArray[index] : 'red'
      })
    })
  }

  if (noShows && noShows[filter] && noShows[filter].length>4) {
    const totalNoShowAmount = getSum(noShows[filter], 'totalAmount');
    data.push({
      "id": "Other",
      "label": "Other",
      "value": (totalNoShowAmount - amountCount),
      "color": colorArray[4]
    })
  }

  return data;
}

const formatRoomsData = (data, roomInfo) =>{
  let formattedData = '';
  let dataArray = [ ...new Set([...data]) ];

  dataArray.forEach((id, index) =>{

    formattedData += `${roomInfo[id].name}` || '';

    if(index + 1 == dataArray.length) return;

    formattedData += ", ";
  });

  return `${formattedData}`;
}

const processNoShowReservationsList = (noShowReservations, roomTypes, submittedDates, currencyData) =>{
  let dataFormat = [];

  noShowReservations && noShowReservations.length!==0 && noShowReservations.map(reservation => {
    
    if (reservation.checkIn, moment(reservation.checkIn).isBetween(submittedDates.startDate, submittedDates.endDate, 'days', '[]'))
    {
      dataFormat.push(
      {
        resvCode: reservation?.code || '',
        name: toTitleCase(reservation.name),
        checkIn: moment(reservation.checkIn).format('DD MMM YYYY'),
        checkOut: moment(reservation.checkOut).format('DD MMM YYYY'),
        roomTypes: formatRoomsData(reservation.roomTypeIDs,roomTypes),
        bookingSource: reservation.bookingSource,
        totalAmount: reservation.totalAmount,
        balance: reservation.balance,
        resID: reservation.resID
      })
    }
  });  
  return dataFormat;
}

const processNoShowGraph = (noShowList, submittedDates, level) => {
  let tempData = [];
  const noShowSummaryList = noShowList && noShowList.propertyNoShows;
  if (noShowSummaryList && noShowSummaryList.length!==0) {
    let difference = level==='daily' ? 'day' : 'month';
    let dateVar = submittedDates.startDate.clone().subtract(1, difference);

    do {
      dateVar = dateVar.add(1, difference);
      tempData.push({
        "date": level==='daily' ? dateVar.format('DD MMM') : dateVar.format('MMM YYYY'), 
        "no shows":  noShowSummaryList.find(e => moment(e.date).isSame(dateVar, difference)) ? 
                    noShowSummaryList.find(e => moment(e.date).isSame(dateVar, difference)).noShows : 
                    0
      })
    } while (!dateVar.isSame(submittedDates.endDate, difference))
  }
  return tempData
}

export {
  channelNoShowSelector, 
  roomTypeNoShowSelector, 
  propertyNoShowSelector,
  processNoShowReservationsList,
  processNoShowGraph
};