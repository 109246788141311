import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputBase,
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import { createReservationTax, updateReservationTax } from "../../../redux/actions/reservation/editReservation";
import NumberInput from "../../common/NumberInput";

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    backgroundColor: '#fff'
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#f8f9fb',
    justifyContent: 'center',
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
  },
  dialogContent: {
    // height: 'calc(100vh - 315px)',
    minHeight: '200px'
  },
  title: {
    display: 'flex',
    '& span': {
      marginRight: 20
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 15,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
    color: '#333333',
    marginTop: 10
  },
  dropdownInput: {
    "& > div:not(.css-26l3qy-menu)": {
      minHeight: 30,
      border: 0,
      backgroundColor: '#f8f9fb',
      boxShadow: 'none'
    },
    "& > div > div > div": {
      padding: '0 8px'
    },
    "& .css-26l3qy-menu > div > div": {
      padding: 8
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000
    }
  },
  field: {
    background: '#f8f9fb',
    borderRadius: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .css-6q0nyr-Svg': {
      color: '#000000',
      width: 30,
      height: 30,
    }
  },
  submit: {
    color: '#ffffff',
    background: '#6ea7d6',
    fontSize: '1.2rem',
    border: '0',
    '&:hover': {
      backgroundColor: '#6ea7d6',
      border: 0,
    }
  },
  dialogAction: {
    padding: 24,
    textAlign: 'right'
  }
}));

const DiscountForm = (props) => {
  const { currentSpace, taxes, id, reservation, t } = props;
  const [value, setValue] = React.useState(0);
  const [taxID, setTaxID] = React.useState(id || null);
  const classes = useStyles();

  React.useEffect(() => {
    if(id && reservation && reservation.taxesApplied){
      let resTax = reservation.taxesApplied.find(tax => tax.taxID === id);
      if(resTax) setValue(resTax.amount);
    }
  }, [reservation]);

  const handleValueChange = (e) => {
    setValue(e.target.value)
  }

  const handleSelect = selected => {
    setTaxID(selected.value);
  }

  const handleSubmit = () => {
    let payload = {
      value: parseFloat(value),
      taxID: taxID,
    }
    if (id) {
      props.dispatch(updateReservationTax(reservation._id, payload))
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'update tax', 'eventLabel': 'Update Reservation Tax'});
    } else
      props.dispatch(createReservationTax(reservation._id, payload))
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': 'add tax', 'eventLabel': 'Add Reservation Tax'});
    props.handleClose();
  }

  const handleGetTaxes = () => {
    let taxOptions = currentSpace.taxes && currentSpace.taxes.map(item => {
      if (taxes[item] && taxes[item]?.calculationMode === "exclusive") { return { value: item, label: taxes[item].name } }
    }).filter(options => options);
    return taxOptions;
  }

  const TaxOptions = handleGetTaxes();

  const taxListView =
    <Grid item xs={12}>
      <div className={classes.label}>{t('existingReservation.summaryTab.tax')}</div>
      <div className={classes.field}>
        <Select
          onChange={handleSelect}
          options={TaxOptions}
          defaultValue={TaxOptions.filter(e => e.value === taxID)}
          isDisabled={id ? true : false}
          className={classes.dropdownInput}
        />
      </div>
    </Grid>;

  const valueView =
    <Grid item xs={12}>
      <div className={classes.label}>{t('existingReservation.summaryTab.value')}</div>
      <div className={classes.field}>
        <NumberInput min={0} value={value || ''} id="value" onChange={handleValueChange} />
      </div>
    </Grid>;

  const buttonView =
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!(taxID && value > 0)}
        onClick={handleSubmit}
      >{t('actions.save')}</Button>
    </Grid>;

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.root}>
        <div className={classes.dialogTitle}>
          <Typography variant="h4" className={classes.title}><span>{t('existingReservation.summaryTab.tax')}</span></Typography>
          <CloseIcon className={classes.closeIcon} onClick={props.handleClose} />
        </div>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            {taxListView}
            {valueView}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          {buttonView}
        </DialogActions>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    reservation: state.reservationDetails.reservation,
    currentSpace: state.spaces[state.dashboard.currentSpace],
    taxes: state.taxes
  }
};
export default withTranslation()(connect(mapStateToProps)(DiscountForm));