import React, { useEffect } from "react";
import {
  makeStyles,
  CircularProgress,
  formatMs,
  Switch,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { TwitterPicker, ChromePicker } from "react-color";
import clsx from "clsx";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import {
  getRoomTypeMapping,
  updateRoomTypeMapping,
} from "../../redux/actions/odoo/roomType";

import Select from "react-select";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    borderRadius: 10,
    margin: 15,
    display: "block",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  rowItemsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.35rem",
    fontWeight: 600,
  },
  selectedContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  errorContainer: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.20rem",
    fontWeight: 400,
    color: "#f44336",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  roomTypeRow: {
    alignItems: "flex-start",
    display: "flex",
  },
  roomTypeChip: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "100%",
  },
  leftAlign: {
    alignItems: "center",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "20%",
  },
  rightAlign: {
    width: "100%",
  },
  clickable: {
    cursor: "pointer",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  tagBubble: {
    background: "#D9FADD",
    color: "#000000",
    borderRadius: 10,
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1.1rem",
    marginRight: 10,
    marginBottom: 5,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  inline: {
    display: "flex",
    alignItems: "center",
  },
  tagIcon: {
    fontSize: "1.8rem",
    marginLeft: 5,
  },
  tag: {
    width: "100%",
  },
  buttonsContainer: {
    marginTop: 45,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.3rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 10,
    paddingBottom: 10,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 400,
    marginTop: 30,
    textAlign: "center",
  },
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: "#E0E0E0",
    "&$checked": {
      color: "#8CB2A4",
    },
    "&$checked + $track": {
      backgroundColor: "#8CB2A4",
    },
  },
  checked: {},
  track: {},
})(Switch);

const RoomType = (props) => {
  const { t } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { open, handleClose, currentSpace, loadingProperty, odoo, roomTypes } =
    props;
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [rtArray, setRTArr] = React.useState([]);

  // useEffect(() => {
  //   if(odoo && !odoo.default && currentSpace && currentSpace.propertyID)
  //     props.dispatch(getRoomTypeMapping(currentSpace.propertyID))
  // }, [currentSpace])

  useEffect(() => {
    if ((!odoo && odoo.default) || !odoo.roomTypes) {
      return;
    }

    let rtArr = [];
    if (!_.isEmpty(roomTypes) && roomTypes.default !== true) {
      Object.entries(roomTypes).map((item) => {
        if (item[1].propertyID === currentSpace.propertyID)
          rtArr.push({ label: item[1].name, value: item[1]._id });
      });
    }
    setRTArr(rtArr);

    let arr = [];
    odoo.roomTypes.map((rt) => {
      let val = {
        ...rt,
        selected: [],
        save: false,
        error: false,
      };

      if (val.roomTypeIDs && val.roomTypeIDs.length > 0 && rtArr.length > 0) {
        val.roomTypeIDs.map((x) => {
          const sel = rtArr.filter((rt) => rt.value === x);
          if (sel.length > 0) {
            val.selected.push({ value: sel[0].value, label: sel[0].label });
            const newRT = rtArr.filter((rt) => rt.value !== x);
            setRTArr(newRT);
          }
        });
      }
      arr.push(val);
    });

    setData(arr);
  }, [odoo]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (ind) => {
    let form = [...data];

    if (form[ind].isActive) {
      const value = {
        odooProductID: form[ind].odooProductID,
        odooDefaultCode: form[ind].odooDefaultCode,
        roomTypeIDs: [],
        isActive: false,
      };

      if (form[ind].selected.length > 0) {
        props
          .dispatch(updateRoomTypeMapping(currentSpace.propertyID, value))
          .then((response) => {
            if (response.status === 200) {
              form[ind].isActive = response.data.isActive;
              let arr = [...rtArray];

              form[ind].selected.map((sel) => {
                arr.push(sel);
              });
              setRTArr(arr);
            }
          });
      } else {
        form[ind].isActive = false;
      }
    } else {
      form[ind].isActive = true;
    }
    setData(form);
  };

  const handleSelect = (ind, type) => {
    let form = [...data];
    let arr = [...rtArray];
    let selection = arr.filter((rt) => rt.value === type);
    form[ind].selected.push({
      value: selection[0].value,
      label: selection[0].label,
    });
    if (!form[ind].save) form[ind].save = true;
    if (form[ind].error) form[ind].error = false;
    setData(form);
    let newArr = arr.filter((rt) => rt.value !== type);
    setRTArr(newArr);
  };

  const handleRemoveSelection = (selection, parentIndex) => {
    let form = [...data];
    let arr = [...rtArray];
    let selData = form[parentIndex].selected.filter(
      (rt) => rt.value !== selection.value
    );
    form[parentIndex].selected = selData;
    if (!form[parentIndex].save) form[parentIndex].save = true;
    if (form[parentIndex].error) form[parentIndex].error = false;
    setData(form);
    arr.push(selection);
    setRTArr(arr);
  };

  const handleSubmit = (index) => {
    let roomTypeIDs = [];
    if (data[index].selected.length === 0) {
      let form = [...data];
      form[index].error = true;
      form[index].save = false;
      setData(form);
      return;
    }
    data[index].selected.map((sel) => {
      roomTypeIDs.push(sel.value);
    });

    const value = {
      odooProductID: data[index].odooProductID,
      odooDefaultCode: data[index].odooDefaultCode,
      roomTypeIDs,
      isActive: true,
    };

    props.dispatch(updateRoomTypeMapping(currentSpace.propertyID, value));
    let form = [...data];
    form[index].error = false;
    form[index].save = false;
    setData(form);
  };

  return (
    <div>
      {data.length > 0 &&
        data.map((d, index) => {
          return (
            <div className={classes.root} key={Math.random()}>
              <div className={classes.rowItemsHeader}>
                <div className={classes.headerContainer}>
                  <span>{d.odooProductName}</span>
                </div>
                <div className={classes.toggleContainer}>
                  <CustomSwitch
                    checked={d.isActive}
                    onChange={() => handleChange(index)}
                    color="primary"
                    name="switch"
                    inputProps={{ "aria-label": "room mapping switch" }}
                  />
                </div>
              </div>
              {d.isActive && (
                <div>
                  <div className={classes.container}>
                    <div
                      className={clsx(classes.clickable, classes.rightAlign)}
                    >
                      {rtArray.length > 0 &&
                        rtArray.map((rt) => (
                          <Chip
                            key={rt.value}
                            className={classes.roomTypeChip}
                            variant="outlined"
                            size="small"
                            label={rt.label}
                            onClick={() => handleSelect(index, rt.value)}
                          />
                        ))}
                    </div>
                  </div>
                  {d.selected.length > 0 && (
                    <div>
                      <Divider className={classes.divider} />
                      <div className={classes.rowItemsHeader}>
                        <div className={classes.selectedContainer}>
                          <span>{t("automate.odoo.selectedRoomTypes")}</span>
                        </div>
                      </div>
                      <div className={classes.tag}>
                        {d.selected.length > 0 &&
                          d.selected.map((sel, selIndex) => (
                            <span className={classes.tagBubble} key={selIndex}>
                              <div className={classes.inline}>
                                {sel.value ? sel.label : sel}{" "}
                                <CloseIcon
                                  className={classes.tagIcon}
                                  onClick={() =>
                                    handleRemoveSelection(sel, index)
                                  }
                                />
                              </div>
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                  {d.error && (
                    <div>
                      <Divider className={classes.divider} />
                      <div className={classes.rowItemsHeader}>
                        <div className={classes.errorContainer}>
                          <span>{t("automate.odoo.saveError")}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {d.save && (
                    <div className={classes.buttonsContainer}>
                      <div
                        className={classes.button}
                        onClick={() => handleSubmit(index)}
                      >
                        {t("actions.save")}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      {!data.length && (
        <div className={classes.label}>
          {t("automate.odoo.completeSettings")}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingProperty: state.loading.LOAD_PROPERTY,
  odoo: state.odoo,
  roomTypes: state.roomTypes,
});

export default withTranslation()(connect(mapStateToProps)(RoomType));
