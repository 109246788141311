import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme =>({
  buttonContainer: {
    display: 'flex',
  },
  bubble:{
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: 10,
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
      padding: 10,
    }
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  fieldContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '10%',
    minWidth: 50,
  },
  description: {
    height: 80,
    width: '80%',
  },
  text: {
    marginLeft: 10,
  }
}));

const RatePlanTemplate = props =>{
    const classes = useStyles();
    const {
        ratePlan,
        handleUpdateRatePlan
    } = props;

    const handleUpdate = (key, value) => {
      handleUpdateRatePlan('cancellationPolicy', {...ratePlan.cancellationPolicy, [key]: value});
    }

    const handleUpdateNRPolicy = (key, value) => {
      handleUpdateRatePlan(key, value );
    }

    return(
        <>
            <div className="main-content">
                
              <div className="main-content-title">Cancellation</div>
              <div className="sub-title">Select a cancellation policy for this rate plan</div>


              <div className="row">
                  <div className={classes.buttonContainer}>
                    <div 
                      className={clsx({[classes.bubble]: true, [classes.selected]: ratePlan.nonRefundable===false})}
                      onClick={() => handleUpdateRatePlan('nonRefundable', false)}
                    >
                      Refundable
                    </div>
                    <div 
                      className={clsx({[classes.bubble]: true, [classes.selected]: ratePlan.nonRefundable})}
                      onClick={() => handleUpdateRatePlan('nonRefundable', true)}
                    >
                      Non Refundable
                    </div>
                  </div>
                </div>

              {ratePlan.nonRefundable === false && <div>
                <div className="row">
                  <div className="label">Duration</div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.field}>
                      <InputBase 
                        id="duration"
                        name="duration"
                        value={ratePlan.cancellationPolicy && ratePlan.cancellationPolicy.duration ? ratePlan.cancellationPolicy.duration : ''}
                        fullWidth
                        type="tel"
                        autoFocus
                        onChange={(e) => handleUpdate('duration', (isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value) ))}
                      />
                    </div>
                    <div className={classes.text}>days in advance</div>
                  </div>
                </div>

                <div className="row">
                  <div className="label">Conditions (visible to guests on Booking Engine)</div>
                  <div className={clsx(classes.field, classes.description)}>
                    <InputBase 
                      id="conditions"
                      name="conditions"
                      fullWidth
                      type="text"
                      value={ratePlan.cancellationPolicy && ratePlan.cancellationPolicy.conditions ? ratePlan.cancellationPolicy.conditions : ''}
                      multiline
                      rows={3}
                      onChange={(e) => handleUpdate('conditions', e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="label">Cancellation Charge</div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.field}>
                      <InputBase 
                        id="percentChargeable"
                        name="percentChargeable"
                        value={ratePlan.cancellationPolicy && ratePlan.cancellationPolicy.percentChargeable ? ratePlan.cancellationPolicy.percentChargeable : ''}
                        fullWidth
                        type="tel"
                        onChange={(e) => handleUpdate('percentChargeable', (isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value) ))}
                      />
                    </div>
                    <div className={classes.text}>% of total reservation amount</div>
                  </div>
                </div>

              </div>}
              {ratePlan.nonRefundable && <div>
                <div className="row">
                  <div className="label">Cancellation Policy (visible to guests on Booking Engine)</div>
                  <div className={clsx(classes.field, classes.description)}>
                    <InputBase 
                      id="conditions"
                      name="conditions"
                      fullWidth
                      type="text"
                      value={ratePlan.nrPolicy}
                      multiline
                      rows={3}
                      onChange={(e) => handleUpdateNRPolicy('nrPolicy', e.target.value)}
                    />
                  </div>
                </div>
              </div>}

            </div>
        </>
    )
}

export default RatePlanTemplate; 