import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import {
  guestProcessing,
  mapProcessing,
  guestCount,
} from "../../redux/selectors/reports/guestList";
import {
  loadGuestList,
  resetGuestList,
} from "../../redux/actions/reports/guestList";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import Reservation from "../reservations/Reservation";
import CountryMap from "../charts/CountryMap";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import { groupingConfig } from "../utils/excelExport";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  graph: {
    height: 400,
    marginBottom: 50,
  },
  dateHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
  },
  fieldContainer: {
    borderRadius: 5,
    border: "1px solid #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      marginTop: 10,
    },
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  date: {
    borderRadius: 10,
    boxShadow: "0 2px 30px #F0F0F0",
    color: "#484848",
    cursor: "pointer",
    marginRight: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  options: {
    borderRadius: 10,
    boxShadow: "0 2px 30px #F0F0F0",
    paddingLeft: 10,
    paddingRight: 10,
    "& input": {
      borderRadius: 10,
      color: "#484848",
      fontWeight: 500,
    },
  },
  selected: {
    background: "#4F9581",
    color: "#ffffff",
    fontWeight: 600,
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  blankState: {
    alignItems: "center",
    display: "flex",
    height: 50,
    justifyContent: "center",
    width: "100%",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const BasicExport = (props) => {
  const { t } = props;
  const rowCount = props.data.length;
  const tableTitle = `${t("reports.guests")} ${t("reports.report")}`;
  const displayDate = `${props.startDate} - ${props.endDate}`;
  const excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "wholeNumber",
    "string",
    "wholeNumber",
    "string",
    "string",
    "string",
    "string",
    "string",
  ];
  const excelColWidth = [20, 40, 15, 15, 15, 20, 15, 20, 20, 30, 20, 20];

  const groupingConfigParmas = {
    data: props.data,
    excelHeaderType,
    excelColWidth,
    tableTitle,
    displayDate,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: t("reports.table.resvCode"),
          field: "resvCode",
          defaultSort: "asc",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("fields.name"),
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkin"),
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.checkout"),
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("reports.table.duration"),
          field: "duration",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.dob"),
          field: "dateOfBirth",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.age"),
          field: "age",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.city"),
          field: "city",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.country"),
          field: "country",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.docType"),
          field: "documentType",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.docNum"),
          field: "documentNumber",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
        {
          title: t("newReservation.guest.nationality"),
          field: "nationality",
          headerStyle: { backgroundColor: "#F6F2EE" },
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        headerStyle: {
          backgroundColor: "#F6F2EE",
          zIndex: "unset",
        },
      }}
      onRowClick={(event, rowData) => props.handleTableRowClick(rowData.resID)}
    />
  );
};

const GuestList = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "today", label: t("reports.presetRange.today") },
    { value: "yesterday", label: t("reports.presetRange.yesterday") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const { open, handleClose, currentSpace, loadingGuestList, demographics } =
    props;
  const [openReservation, setOpenReservation] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[0]);
  const [goFullScreen, setGoFullscreen] = React.useState(false);

  const tableData = useSelector((state) =>
    guestProcessing(state.reports.guestList)
  );
  const mapData = useSelector((state) =>
    mapProcessing(state.reports.demographics)
  );
  const summary = useSelector((state) =>
    guestCount(
      state.reports.guestList,
      state.spaces[state.dashboard.currentSpace]
    )
  );

  useEffect(() => {
    props.dispatch(
      loadGuestList(
        currentSpace.propertyID,
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
    document.title = `${t("reports.guests")} ${t("reports.report")} | Counter`;
    return function cleanup() {
      props.dispatch(resetGuestList());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    if (e.value == "custom") {
      return;
    } else if (e.value === "today") {
      reportFrom = moment();
      reportTo = moment();
    } else if (e.value === "yesterday") {
      reportFrom = moment().subtract(1, "day");
      reportTo = moment().subtract(1, "day");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    props.dispatch(
      loadGuestList(
        currentSpace.propertyID,
        reportFrom.format("YYYY-MM-DD"),
        reportTo.format("YYYY-MM-DD")
      )
    );
    setDates({ startDate: reportFrom, endDate: reportTo });
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
  };

  const handleSubmit = () => {
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
    props.dispatch(
      loadGuestList(
        currentSpace.propertyID,
        dates.startDate.format("YYYY-MM-DD"),
        dates.endDate.format("YYYY-MM-DD")
      )
    );
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("reports.guests")} {t("reports.report")}
          </div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.GUESTS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              {/* --------------------------------------------------------- */}
              {/* DATE RANGE FILTERS */}
              {/* --------------------------------------------------------- */}
              <div className={classes.dateHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3} lg={4}>
                    <div className={classes.fieldContainer}>
                      <Select
                        value={presetValue}
                        styles={selectStyle}
                        options={presetRanges}
                        onChange={setNewDates}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.lastRow}>
                      <div className={classes.dateHolder}>
                        <DateRangePicker
                          startDate={dates.startDate}
                          startDateId="start_date"
                          endDate={dates.endDate}
                          endDateId="end_date"
                          onDatesChange={({ startDate, endDate }) => {
                            setDates({ startDate, endDate });
                            setPresetValue(presetRanges[4]);
                          }}
                          onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput)
                          }
                          focusedInput={focusedInput}
                          displayFormat={"DD MMM YYYY"}
                          hideKeyboardShortcutsPanel
                          numberOfMonths={1}
                          isOutsideRange={() => false}
                        />
                      </div>
                      <div className={classes.button} onClick={handleSubmit}>
                        {t("reports.transactionPage.submit")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

            {!loadingGuestList && (
              <div
                className={clsx(classes.listSection, classes.graph)}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <CountryMap data={mapData.data} max={mapData.max} />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* GUEST SUMMARY BUBBLES*/}
            {/* --------------------------------------------------------- */}
            {!loadingGuestList && tableData.length !== 0 && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>{summary.domestic}%</div>
                      <div className={classes.desc}>
                        {t("reports.guestPage.domestic")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {summary.international}%
                      </div>
                      <div className={classes.desc}>
                        {t("reports.guestPage.international")}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>{summary.unknown}%</div>
                      <div className={classes.desc}>
                        {t("reports.guestPage.unknown")}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            <div className={classes.listSection}>
              {!loadingGuestList && tableData.length !== 0 && (
                <BasicExport
                  t={t}
                  data={tableData.map((td) => ({
                    ...td,
                    documentType: get(td, "documents[0].type", "-"),
                    documentNumber: get(td, "documents[0].number", "-"),
                  }))}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  handleTableRowClick={handleTableRowClick}
                />
              )}

              {loadingGuestList && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}

              {!loadingGuestList && tableData.length === 0 && (
                <div className={classes.blankState}>
                  {t("reports.guestPage.noGuestMessage")}
                </div>
              )}
            </div>
          </div>

          {/* Open reservation when transaction item clicked */}
          {/* ------------------------------------------------------------------ */}
          {openReservation && (
            <Reservation
              open={openReservation}
              handleCloseReservation={handleCloseReservation}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  demographics: state.reports.demographics || [],
  loadingGuestList: state.loading.LOAD_GUEST_LIST,
});

export default withTranslation()(connect(mapStateToProps)(GuestList));
