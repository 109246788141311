import React from 'react';
import clsx from 'clsx';
import { makeStyles, InputBase, Button } from '@material-ui/core';
import ConfirmForm from '../common/ConfirmForm';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    '& > div': {
      width: '100%'
    }
  },
  textInputContainer: {
    display: 'flex',
    marginBottom: '30px'
  },
  fieldError: {
    border: '1px solid red',
  },
  buttonContainer:{
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
    '& .button':{
      width: '20px'
    }
  }
}))

const TokenForm = (props) => {
  const classes = useStyles();
  const { constructedToken } = props;
  const [token, setToken] = React.useState(null);
  const [viewCCPassword, setViewCCPassword] = React.useState(null);
  const [showWarning, setShowWarning] = React.useState(false);
  const [error, setError] = React.useState({ token: false });

  const handleSubmit = () => {
    if (!token){ 
      setError({ token: true });
      return;
    }

    if(!viewCCPassword){
      setShowWarning(true);
      return;
    }

    props.handleSubmit(token, viewCCPassword);
  }

  const byPassWarningHandler = () =>{
    props.handleSubmit(token, viewCCPassword);
    setShowWarning(false);
  }

  return (
    <div className={classes.row}>
      {!constructedToken && <>
        <div className={classes.label}>User Token</div>
        <div className={classes.textInputContainer}>
          <div className={clsx(classes.field, { [classes.fieldError]: error.token })}>
            <InputBase
              id="userToken"
              name="userToken"
              type="text"
              onChange={(e) => {
                setToken(e.target.value);
                setError({ token: false });
              }}
            />
          </div>
        </div>

        <div className={classes.label}>Credit Card Password</div>
        <div className={classes.textInputContainer}>
          <div className={classes.field}>
            <InputBase
              id="viewCCPassword"
              name="viewCCPassword"
              type="text"
              onChange={(e) => {
                setViewCCPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {showWarning && (
            <ConfirmForm
              open={showWarning}
              setOpen={setShowWarning}
              onClose={() => setShowWarning(false)}
              onConfirm={byPassWarningHandler}
            >
              Credit Card Password is required to get card details for bookings. Are you sure you want to continue?
            </ConfirmForm>
          )}
  
          {!showWarning && <Button variant="outlined" className="button" onClick={handleSubmit}>Add</Button>}
        </div>
      </>}

      {constructedToken && <>
      <div className={classes.label}>Verified user token: {constructedToken}</div>
      </>}
    </div>
  )
}

export default TokenForm;