import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import HtmlToolTip from './HtmlToolTip';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '20px',
        backgroundColor: '#F8F9FB'
    },
    header: {
        width: '100%',
        display: 'flex',
        fontWeight: 500,
        '& div':{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        }
    },
    rows: {
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        '& div':{
            flex: 1,
            display: 'flex',
            padding: '16px',
            alignItems: 'center',
        },
        '&.tableRows:hover':{
            cursor: 'pointer',
            backgroundColor: '#e3e3e3'
        }
    },
    tableContainer:{
        width: '100%',
    },
    tableHeader: {
        borderBottom: '1px solid #e3e3e3',
        boxSizing: 'border-box',
        fontWeight: 500,
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
    }
}));


const TransactionTable = props => {
    const classes = useStyles();
    const { transactions, id, handleTableRowClick } = props;

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <div className={clsx(classes.tableHeader, classes.rows)}>
                    <div>Date</div>
                    <div>Guest</div>
                    <div>Charged</div>
                    <div>Fee</div>
                    <div>Net</div>
                </div>
                
                {transactions.map((transaction, index) =>(
                    <HtmlToolTip 
                        title={transaction?.description || ''}
                        key={`${id}_${transaction.reservationID}_${index}`}
                        cursorType={transaction.reservationID ? 'pointer' : 'default' }
                    >
                        <div 
                            // key={`${id}_${transaction.reservationID}`}
                            className={clsx(classes.rows, 'tableRows')}
                            onClick={() => handleTableRowClick(transaction.reservationID)}
                        >
                            <div>{transaction.date}</div>
                            <div>{transaction.guestName}</div>
                            <div>{transaction.gross}</div>
                            <div>({transaction.fee})</div>
                            <div>{transaction.total}</div>
                        </div>
                    </HtmlToolTip>
                ))}
            </div>
        </div>
    );
}

export default TransactionTable;