import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD RESERVATIONS BY DAY
//----------------------------------------------------------------

// Note* - "loadReservationsByDay" function is used by:- 
// 1) Live reservations report. 
// 2) Reservation reports (beds module).
export function loadReservationsByDay(params) {
  const ACTION_NAME = "LOAD_RESERVATIONS_BY_DAY";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reports/reservations`, { ...params })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET RESERVATIONS REPORT
//----------------------------------------------------------------
export const resetReservationsReport = () => ({
  type: 'RESET_RESERVATIONS_REPORT'
})