import { sidebarMenu } from './constant'

export const getNextStepInfo = (currentMenuID) => {
  const menuList = Object.keys(sidebarMenu)
  const currentMenuIndex = menuList.indexOf(currentMenuID)
  const nextMainStepIndex = currentMenuIndex + 1

  if (!menuList[nextMainStepIndex]) return

  const nextMenu = sidebarMenu[menuList[nextMainStepIndex]]

  return {
    menuID: nextMenu.id
  }
}

export const getPrevStepInfo = (currentMenuID) => {
  const menuList = Object.keys(sidebarMenu)
  const currentMenuIndex = menuList.indexOf(currentMenuID)
  const prevMenuIndex = currentMenuIndex - 1

  if (!menuList[prevMenuIndex]) return

  const prevMenu = sidebarMenu[menuList[prevMenuIndex]]

  return {
    menuID: prevMenu.id
  }
}

export const getMenuStepInfo = menuID => {
  const menuList = Object.keys(sidebarMenu)

  const stepNo = menuList.indexOf(menuID) + 1

  const totalSteps = menuList.length

  const progressVal = Math.ceil(stepNo * 100 / totalSteps)

  return { stepNo, totalSteps, progressVal }
}

export const getHeaderTitle = (menuID) => {
  console.log('menuID ', menuID)
  const activeMenuIfo = sidebarMenu[menuID]
  return activeMenuIfo.headerText
}

export const getSubMenuStatus = (mainMenuID, settings) => {
  if (mainMenuID === sidebarMenu.Property.id) {
    return true
  }

  if (mainMenuID === sidebarMenu.Facilities.id) {
    return true
  }

  if (mainMenuID === sidebarMenu.Location.id) {
    return true
  }

  if (mainMenuID === sidebarMenu.Policies.id) {
    return settings?.policy?.maxNights && settings?.policy?.minNights
  }

  if (mainMenuID === sidebarMenu.Conditions.id) {
    return true
  }

  if (mainMenuID === sidebarMenu.Payments.id) {
    return true
  }

  if (mainMenuID === sidebarMenu.Images.id) {
    return true
  }
}

export const getProgress = (settings) => {
  let count = 0
  Object.keys(sidebarMenu).forEach(menuID => {
    if (getSubMenuStatus(menuID, settings)) count++
  })
  return count
}
