import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 4
  },
  title: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 600
  },
  actionIcon: {
    cursor: 'pointer',
    width: '0.8em',
    height: '0.8em',
  }
}))

const Navigation = (props) => {
  const classes = useStyles();
  const { step, title } = props;

  return (
    <div className={classes.header}>
      {step > 1 && <ArrowBackIosIcon className={classes.actionIcon} onClick={() => props.onChangePrev(step - 1)}/>}
      <span className={classes.title}>{title}</span>
    </div>
  )
}

export default (Navigation);