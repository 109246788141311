import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import { fetchDatePerTimezone, fetchCurrencyFormat } from '../../../utils/utility'; 
import { RESERVATION_STATUS, INVOICE_TYPE } from '../../../utils/constants';

const taxProcessing = (reportsTax, taxes, timezone, currency, selectedResStatus) => {
  let tableData = [], invoices = []
  const thisCurrency = fetchCurrencyFormat(currency ? currency : null)
  
  if(reportsTax && reportsTax.length > 0) {
  	reportsTax.forEach((info) => {
  		let taxData = []
      let totalTax = 0
  		info.taxesApplied.forEach((tax) => {
  			taxData.push({
  				taxID: tax.taxID,
  				amount: `${thisCurrency}${Math.round(tax.amount * 100) / 100}`,
  				taxName: toTitleCase(taxes?.[tax.taxID]?.name),
  				taxType: toTitleCase(taxes?.[tax.taxID]?.calculationMode),
  				taxCode: taxes?.[tax.taxID]?.code ? taxes[tax.taxID]?.code : '-'
  			})
        totalTax = totalTax + tax.amount
  		})

      let finalInvoice = [RESERVATION_STATUS.CHECKED_OUT, RESERVATION_STATUS.NO_SHOW, RESERVATION_STATUS.CANCELLED].includes(info.status)
      
      let invoiceArray = []
      if(finalInvoice)
        invoiceArray = info.invoices.filter((inv) => inv.invoiceType === INVOICE_TYPE.FINAL)
      else
        invoiceArray = info.invoices.filter((inv) => inv.invoiceType === INVOICE_TYPE.PRO_FORMA)

      if(invoiceArray.length > 1) {
        invoices = invoiceArray.sort((a, b) => {
          return moment(b.createdAt).diff(a.createdAt);
        })
      }
      else {
        invoices = invoiceArray
      }

      if(selectedResStatus.length > 0 && !selectedResStatus.includes(info.status)) return;

  		tableData.push({
  			balance: `${Math.round((info.totalAmount - info.amountPaid) * 100) / 100}`,
        totalAmount: `${Math.round(info.totalAmount * 100) / 100}`,
  			totalTax: (totalTax > 0) ? `${Math.round(totalTax * 100) / 100}` : '-',
  			checkIn: fetchDatePerTimezone(info.checkIn, timezone).format("DD MMM YYYY"),
  			checkOut: fetchDatePerTimezone(info.checkOut, timezone).format("DD MMM YYYY"),
  			resID: info._id,
  			resvCode: info.code,
  			guestName: info.guestName,
  			status: info.status,
  			taxesApplied: taxData,
        invoices: info.invoices,
        invoiceNumber: (invoices.length) ? invoices[0].invoiceNo : '-'
  		})
  	})
  }

  return tableData;
}

export { taxProcessing }