import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const useStyles = makeStyles(theme =>({
    roomContainer:{
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '15px 20px',
        borderRadius: 15,
        margin: '15px 5px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '& $dragIcon':{
            marginRight: '10px'
        },
        '& $roomTitle':{
            fontSize: '1.2rem',
        }
    },
    nestedRoomContainer: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '8px 10px',
        borderRadius: 15,
        margin: '5px',
        width: '120px',
        backgroundColor: '#fff',
        cursor: 'pointer'
    },
    dragIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
        opacity: 0.7
    },
    roomTitle: {
        fontSize: '1.1rem',
        fontWeight: 600
    },
    hideCard: {
        opacity: 0
    }
}));

const RoomCard = props =>{
    const { roomName, type = '' } = props;
    const classes = useStyles();

    if(type){
        return(
            <div className={classes.nestedRoomContainer}>
                <MenuRoundedIcon className={classes.dragIcon} />
                <div className={classes.roomTitle}>{roomName}</div>
            </div>
        )
    }

    return(
        <div className={classes.roomContainer}>
            <MenuRoundedIcon className={classes.dragIcon} />
            <div className={classes.roomTitle}>{roomName}</div>
        </div>
    );
};

export default RoomCard;