import React from 'react';
import { connect } from 'react-redux';
import ReservationExp from './experience/ReservationExp';
import DetailsForm from './experience/DetailsForm';
import Status from './experience/Status';
import ExperienceList from './experience/ExperienceList';
import { fetchCalendarInfo } from '../../../redux/actions/experiences/calendar';
import { createExperienceBooking, updateExperienceBooking, updateExperienceProductStatus } from '../../../redux/actions/reservation/editReservation';
import AddExperience from './experience/AddExperience';
import { EXPERIENCE_STATUS } from '../../../utils/constants';
import { CircularProgress } from '@material-ui/core';
import BlankState from './experience/BlankState';

const ExperienceTab = (props) => {
    const [formDisplay, setFormDisplay] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const [data, setData] = React.useState({})
    const [bookingID, setBookingID] = React.useState(null)

    React.useEffect(() => {
        if (props?.reservation?.checkIn && props?.reservation?.checkOut) {
            fetchCalendarExperiences(props.reservation.checkIn, props.reservation.checkOut)
        }

    }, [props.reservation]);


    const fetchCalendarExperiences = () => {
        props.dispatch(fetchCalendarInfo({
            propertyID: props.reservation.propertyID,
            startDate: props.reservation.checkIn,
            endDate: props.reservation.checkOut
        }))
    }


    const updateProductStatusHandler = (bookingID, bookingProductID, status) => {
        props.dispatch(updateExperienceProductStatus(bookingID, props.reservation.propertyID, {
            status,
            bookingProductID
        }))
    }


    const updateProductRateHandler = (bookingID, bookingProductID, rate) => {
        props.dispatch(updateExperienceBooking(bookingID, props.reservation.propertyID, {
            products: [{
                rate,
                _id: bookingProductID
            }]
        }))
    }

    const openAddFormHandler = (data, bookingID) => {
        setData(data)
        setBookingID(bookingID)
        setOpen(true)
    }

    const getProductsArray = (count, data) => {
        return [...Array(count)].map((x, i) => {
            return {
                date: data.date,
                productID: data.productID,
                slotID: data.slotID,
                gradeID: data.gradeID,
                availabilityID: data.availabilityID,
                status: EXPERIENCE_STATUS.CONFIRMED,
                price: { ...data.price },
                quantity: 1
            }
        })
    }

    const saveFormHandler = (guest, payload) => {
        if (bookingID) {
            let payloadData = {
                products: getProductsArray(payload.count, data)
            }
            // console.log("payloadData", payloadData)
            props.dispatch(updateExperienceBooking(bookingID, props.reservation.propertyID, payloadData))
        }
        if (!bookingID) {
            let payloadData = {
                propertyID: props.reservation.propertyID,
                guestCount: payload.count,
                products: getProductsArray(payload.count, data),
                reservationID: props.reservation._id,
                customers: guest ? [guest] : []
            }
            // console.log("payloadData", payloadData)
            props.dispatch(createExperienceBooking(payloadData))
        }

        // console.log("data", data)
    }

    return (
        <React.Fragment>
            {props?.bookedExperiences?.[0] &&
                <Status
                    isDisplay={formDisplay}
                    displayFormHandler={() => setFormDisplay(true)}
                    bookedExperience={props?.bookedExperiences?.[0] || {}}
                />
            }
            {formDisplay &&
                <DetailsForm
                    closeFormHandler={() => setFormDisplay(false)}
                    bookedExperience={props?.bookedExperiences?.[0] || {}}
                    maskedCards={props?.maskedCards}
                    currentSpace={props.currentSpace}
                />
            }
            <ReservationExp
                bookedExperiences={props.bookedExperiences}
                productInfo={props.productInfo}
                openAddFormHandler={openAddFormHandler}
                updateProductStatusHandler={updateProductStatusHandler}
                updateProductRateHandler={updateProductRateHandler}
            />

            {Object.keys(props.calendarData).length > 0 ?
                <ExperienceList
                    productInfo={props.productInfo}
                    calendarData={props.calendarData}
                    openAddFormHandler={openAddFormHandler}
                />
                : 
                <BlankState publishActionHandler={fetchCalendarExperiences}/>
            }

            {open &&
                <AddExperience
                    open={open}
                    timezone={props.currentSpace.timezone}
                    data={data?.available ? { ...data } : { ...data, available: data?.availability?.available }}
                    saveFormHandler={saveFormHandler}
                    handleClose={() => setOpen(false)}
                />
            }

            {props.loading &&
                <div className={"loadingOverlay"}>
                    <CircularProgress className={"loading"} />
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        reservation: state.reservationDetails.reservation,
        bookedExperiences: state.reservationDetails.expBookings,
        maskedCards: state.reservationDetails.maskedCards,
        currentSpace: state.spaces[state.dashboard.currentSpace],

        productInfo: state.experiences?.activityList,
        calendarData: state.experiences?.calendarData || {},
        loading: state.loading.UPDATE_EXPERIENCE_PRODUCT_STATUS ||
            state.loading.UPDATE_EXPERIENCE_PRODUCT ||
            state.loading.CREATE_EXPERIENCE_PRODUCT
    }
}

export default connect(mapStateToProps)(ExperienceTab);