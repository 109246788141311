import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";

import CashAccountSnapshot from "../cash-drawer/CashAccountSnapshot";
import CashDrawerSnapshot from "../cash-drawer/CashDrawerSnapshot";
import FrontdeskStaffSnapshot from "../users/FrontdeskStaffSnapshot";
import FrontdeskSignup from "../frontdesk-signup/FrontdeskSignup";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import IMAGES from "../../constants/images";
import { FRONTDESK_URI } from "../../config";

const styles = (theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    paddingTop: 20,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  bannerContainer: {
    background: "#FFFFFF",
    borderRadius: 5,
    boxShadow: "0 3px 4px 0 #ECECEC",
    padding: theme.spacing(2),
    marginBottom: 20,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 250,
    paddingLeft: theme.spacing(5),
  },
  titleBox: {
    marginBottom: 20,
  },
  feature: {
    color: "#666666",
    fontSize: "1.3rem",
    marginBottom: 5,
  },
  release: {
    marginTop: 20,
    marginBottom: 10,
  },
  releaseButton: {
    background: "#FFFFFF",
    border: "1px solid #006699",
    borderRadius: "5px",
    fontWeight: 600,
    color: "#006699",
    cursor: "pointer",
    display: "inline-block",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textDecoration: "none",
  },
  imageHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
    height: 200,
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      height: 250,
      paddingRight: theme.spacing(5),
    },
  },
  image: {
    borderRadius: 15,
    maxHeight: 200,
    [theme.breakpoints.up("lg")]: {
      maxHeight: 250,
    },
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    "& h3": {
      color: "#ffffff",
      textAlign: "center",
    },
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    border: "0px solid #000000",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  header: {
    alignItems: "center",
    // borderBottom: "1px solid #dddddd",
    background: "#transparent",
    color: "#828282",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "flex-start",
    // margin: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& img": {
      height: 20,
      width: 20,
    },
  },
  cardTitle: {
    marginLeft: 0,
    verticalAlign: "middle",
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  body: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    minHeight: 200,
    overflow: "hidden",
  },
  smallBody: {
    minHeight: 150,
  },
});

const FrontdeskPage = (props) => {
  const { t } = props;
  const { classes, currentSpace } = props;
  const [frontdeskOpen, setFrontdeskOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={12} xl={12}>
          <div className={classes.bannerContainer}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={12} sm={5}>
                <div className={classes.titleContainer}>
                  <div className={classes.titleBox}>
                    <Typography variant="h3">
                      {t("frontdesk.header")}
                    </Typography>
                    <Typography variant="h5">
                      {t("frontdesk.subHeader")}
                    </Typography>
                  </div>
                  <div>
                    <div className={classes.feature}>
                      {t("frontdesk.perkOne")}
                    </div>
                    <div className={classes.feature}>
                      {t("frontdesk.perkTwo")}
                    </div>
                    <div className={classes.feature}>
                      {t("frontdesk.perkThree")}
                    </div>
                  </div>
                  <div className={classes.release}>
                    {currentSpace.frontdeskSignup !== "Complete" && (
                      <div
                        className={classes.releaseButton}
                        onClick={() => setFrontdeskOpen(true)}
                      >
                        {t("frontdesk.setup")}
                      </div>
                    )}
                    {currentSpace.frontdeskSignup === "Complete" && (
                      <a
                        href={FRONTDESK_URI}
                        target="_blank"
                        className={classes.releaseButton}
                      >
                        {t("frontdesk.launch")}
                      </a>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={7}>
                <div className={classes.imageHolder}>
                  <img
                    src={IMAGES.ILLUSTRATIONS.frontdesk}
                    className={classes.image}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {frontdeskOpen && (
        <FrontdeskSignup
          open={frontdeskOpen}
          handleClose={() => setFrontdeskOpen(false)}
        />
      )}

      {currentSpace && currentSpace.frontdeskSignup === "Complete" && (
        <Grid container spacing={3}>
          {/* ----------------------------------------------------------------- */}
          {/*                           ACCESS                                  */}
          {/* ----------------------------------------------------------------- */}
          <AccessController requiredPermission={MODULE.FRONTDESK_ACCESS.ID}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className={classes.container}>
                <FrontdeskStaffSnapshot />
              </div>
            </Grid>
          </AccessController>

          {/* ----------------------------------------------------------------- */}
          {/*                           CASH DRAWERS                               */}
          {/* ----------------------------------------------------------------- */}
          <AccessController requiredPermission={MODULE.CASH_DRAWERS.ID}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className={classes.container}>
                <CashDrawerSnapshot />
              </div>
            </Grid>
          </AccessController>

          {/* ----------------------------------------------------------------- */}
          {/*                           CASH ACCOUNTS                           */}
          {/* ----------------------------------------------------------------- */}
          <AccessController requiredPermission={MODULE.CASH_ACCOUNTS.ID}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className={classes.container}>
                <CashAccountSnapshot />
              </div>
            </Grid>
          </AccessController>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(FrontdeskPage))
);
