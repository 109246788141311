import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { sidebarMenu } from '../constant';
import { getSubMenuStatus } from '../activityHelper';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

        '&.isActive .arrowIcon':{
            '-webkit-transform': 'rotate(180deg)',    /* Chrome and other webkit browsers */
            '-moz-transform': 'rotate(180deg)',       /* FF */
            '-o-transform': 'rotate(180deg)',         /* Opera */
            '-ms-transform': 'rotate(180deg)',        /* IE9 */
            transform: 'rotate(180deg)',            /* W3C compliant browsers */
        }
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: 500,
        color: '#4f4f4f',
        padding: '10px'
    },
    name: {
        paddingLeft: 10,
    },
    subMenuItem:{  
        border: '2px solid transparent',
        margin: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        '&.isActive': {
            border: '2px solid',
            borderColor: '#000',
            borderRadius: '20px',
        }
    },
    icon: {
        border: '1px solid',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        '&.isActive':{
            backgroundColor: '#000',
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '19px',
            backgroundPosition: '0px 1px',
        }
    }
}));

const SidebarItem = props =>{
    const classes = useStyles();

    const { 
        groupID, 
        activeMainMenuID, 
        activeSubMenuID,
        navigateToMenuHandler,
        productData,
        editMode,
        closeDrawerHandler = null
    } = props;

    const subMenu = sidebarMenu[groupID].subMenu;

    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        if(activeMainMenuID == groupID) setIsOpen(true);
    },[activeSubMenuID]);

    const handleClick = () =>{
        setIsOpen(!isOpen);
    }

    const handleSubMenuClick = (subMenuID) =>{

        // Disable navigation to next tab if product flow in add mode.......
        if(!editMode || activeSubMenuID == subMenuID) return;

        navigateToMenuHandler(groupID, subMenuID);

        // For mobile mode..................................
        if(closeDrawerHandler) closeDrawerHandler();
    }

    return(
        <>
            <div 
                className={clsx(classes.root, { 'isActive': isOpen })}
                onClick={handleClick}
            >
                <ArrowDropDownIcon className="arrowIcon" />
                <div className={classes.title} style={{color: '#000000', fontWeight: 600}}>{sidebarMenu[groupID].title}</div>
            </div>

            <Collapse in={isOpen} timeout={100}>

                {subMenu.map(menuData =>(
                    <div 
                        key={`menuID_${menuData.id}`}
                        className={clsx(
                            classes.title, 
                            classes.subMenuItem,
                            { 'isActive': menuData.id == activeSubMenuID }
                        )}
                        onClick={() => handleSubMenuClick(menuData.id)}
                    >
                        <div className={classes.name}>{menuData.title}</div>
                        <div className={clsx(classes.icon, { 
                            'isActive': getSubMenuStatus(
                                    groupID,
                                    menuData.id, 
                                    productData
                                ) 
                        })}/>
                    </div>
                ))}

            </Collapse>
        </>
    )
}

export default SidebarItem;