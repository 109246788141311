import React from "react";
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import clsx from 'clsx';

import { editTaxItem } from '../../redux/actions/taxes/taxes';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LayersIcon from '@material-ui/icons/Layers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { processNumberInput } from '../../utils/utility';

import IMAGES from '../../constants/images';

const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'auto'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%',
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105',
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  backButton: {
    color: '#666666',
    fontSize: '3rem',
    left: 24,
    position: 'absolute',
    top: 28
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  listSection: {
    minHeight: 'calc(100vh - 230px)',
    padding: theme.spacing(3)
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  icon: {
    width: 50,
    height: 50
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  rightAlign: {
    paddingLeft: 30,
    width: '70%'
  },
  clickable: {
    cursor: 'pointer'
  },
  name: {
    cursor: "pointer",
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.5
  },
  textField: {
    fontWeight: 600
  },
  editText: {
    '& input': {
      fontSize: '1.4rem'
    }
  },
  amountContainer: {
    display: 'flex'
  },
  todoDetails: {
    fontSize: "1.2rem",
    marginTop: 7
  },
  moreIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: theme.spacing(1),
    '&:hover': {
      background: '#dddddd',
    },
  },
  slabRow: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 10
  },
  editRow: {
    marginBottom: 15,
  },
  editRate: {
    marginLeft: 10
  },
  removeSlab: {
    color: '#999999',
    fontSize: '1.8rem',
  },
  newSlab: {
    color: 'blue',
    cursor: "pointer",
    fontSize: '1.2rem',
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
});

const taxTypesArray = [
  { value: 'amountPerAccommodation', label: 'Fixed Amount per Room / Bed' },
  { value: 'amountPerNight', label: 'Amount per person per night' },
  { value: 'amountPerPerson', label: 'Fixed Amount per person' },
  { value: 'percentOfTotal', label: 'Percentage of Total Reservation' },
  { value: 'rateSlabs', label: 'Percentage Based on Room Price' },
]

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: '0 2px 70px 0 #E0E0E0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const TaxItem = (props) => {
  const { t } = props
  const { classes, taxes, taxID, currentSpace } = props;
  const tax = taxes[taxID];
  const defaultMaxValue = tax?.maxNights || 0;
  // const [type, setType] = React.useState(tax.type);
  const [edit, setEdit] = React.useState('');
  const [form, setForm] = React.useState({
    _id: tax._id,
    name: tax.name,
    type: tax.type,
    value: tax.value,
    maxNights: defaultMaxValue
  });

  const [hasMaxLength, setHasMaxLength] = React.useState(defaultMaxValue > 0 ? 'yes' : 'no');
  const [calculationMode, setCalculationMode] = React.useState(tax.calculationMode);
  const [rateSlabs, setRateSlabs] = React.useState(
    tax.rateSlabRules.length !== 0 ?
      tax.rateSlabRules :
      tax.type === 'rateSlabs' ? [{ priceFrom: 0, priceTo: 0, value: 0 }] : []);

  const [error, setError] = React.useState(
    {
      name: false,
      type: false,
      calculationMode: false,
      value: false,
      code: false,
    }
  );

  const handleBack = () => {
    // this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    props.handleBack();
  }

  const handleClose = () => {
    // this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    props.handleClose();
  }

  const handleSelect = selected => {
    // setType(selected.value);
    if(form.type === 'amountPerNight' && type == 'type') maxLengthStatusHandler('no');

    setForm({ ...form, type: selected.value });

    if (selected.value === 'rateSlabs') {
      setRateSlabs(tax.rateSlabRules.length !== 0 ? tax.rateSlabRules : [{ priceFrom: 0, priceTo: 0, value: 0 }])
    } else {
      setRateSlabs([])
    }
  }

  const handleChange = e => {
    // let value = e.target.id === "value" ? processNumberInput(e.target.value) : e.target.value;
    let value = e.target.value;
    const id = e.target.id;
    const numberFields = ["value", "maxNights"];

    if (numberFields.includes(id)) {
      value = processNumberInput(value);
      value = value < 0 ? Math.abs(value) : value;
    }

    setForm({ ...form, [e.target.id]: value });
    setError({ ...error, [e.target.id]: false });
  }

  const handleToggle = () => {
    const newCalculationMode = (calculationMode === 'exclusive' ? 'inclusive' : 'exclusive');
    setForm({ ...form, calculationMode: newCalculationMode });
    setCalculationMode(newCalculationMode);
  }

  const removeSlab = (deleteIndex) => {
    let items = rateSlabs.filter((slab, index) => index != deleteIndex);
    setRateSlabs(items);
  }

  const addSlab = () => {
    setRateSlabs([...rateSlabs, { priceFrom: 0, priceTo: 0, value: 0 }])
  }

  const handleRateUpdate = (e, editIndex) => {
    let value = processNumberInput(e.target.value)
    const arr = rateSlabs.map((slab, index) =>
      index === editIndex ? { ...slab, [e.target.id]: value } : slab
    );
    setError({ ...error, ['priceFrom' + editIndex]: false, ['priceTo' + editIndex]: false, })
    setRateSlabs(arr);
  }

  const handleCancelUpdates = () => {
    setEdit(false);
    setForm({ _id: tax._id, type: tax.type });
    setRateSlabs(tax.rateSlabRules.length !== 0 ? tax.rateSlabRules : [{ priceFrom: 0, priceTo: 0, value: 0 }])
  }

  const handleSubmitUpdates = () => {
    // console.log({...form, rateSlabRules: rateSlabs})
    const checkInput = handleValidate();
    if (checkInput) {
      let payload = { ...form };
      
      payload = !form.maxNights ? { ...payload, maxNights: 0 } : { ...payload }; 
      
      if (form.type === 'rateSlabs') {
        payload.value = 0;
        payload.rateSlabRules = [];
        rateSlabs.map((slab, index) => {
          payload.rateSlabRules.push({
            priceFrom: parseFloat(slab?.priceFrom || 0),
            priceTo: parseFloat(slab?.priceTo || 0),
            value: parseFloat(slab?.value || 0),
          })
        });
      }
      else {
        payload.rateSlabRules = [];
        if (payload.value) payload.value = parseFloat(payload.value);
      }

      props.dispatch(editTaxItem(currentSpace.propertyID, { ...payload }))
      // setForm({ _id: tax._id, type: form.type });
      setEdit(false);
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['name'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })

    if (form.type === 'rateSlabs') {
      rateSlabs?.map((slab, index) => {
        if (parseFloat(slab.priceFrom) >= parseFloat(slab.priceTo)) {
          errorStep['priceFrom' + index] = true;
          errorStep['priceTo' + index] = true;
          foundError = true;
        }
      });
    } else if (!form.value && form.value !== 0) {
      errorStep['value'] = true;
      foundError = true;
    }

    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const maxLengthStatusHandler = (status) => {
    if (status == 'no') setForm({ ...form, maxNights: 0 });
    setError({ ...error, hasMaxLength: false });
    setHasMaxLength(status);
  }

  // const processInput = (input) => {

  //   if(input.length === 2 && input.charAt(0) === "0" && input.charAt(1) !== "." ){
  //     input = parseInt(input);
  //   }

  //   while (isNaN(input)) {
  //     input = input
  //       .split('')
  //       .reverse()
  //       .join('')
  //       .replace(/[\D]/i, '')
  //       .split('')
  //       .reverse()
  //       .join('');
  //     // input = parseFloat(input)
  //   }
  //   return input
  // }

  return (
    <Dialog
      open={Boolean(taxID)}
      classes={{ paperFullWidth: classes.dialogPaper }}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div>
            <Hidden smDown><Button
              size='small'
              color='primary'
              variant='outlined'
              className={classes.newListButton}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleBack}
            >
              {t('beds.taxesSnapshot.back')}
            </Button></Hidden>
            <Hidden mdUp>
              <BackIcon className={classes.backButton} onClick={handleBack} />
            </Hidden>
          </div>
          <Typography variant="h5">{edit && 'Edit '}{t('beds.taxesSnapshot.taxAccount')}</Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listSection}>

          <div>

            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                {form.type === 'amountPerAccommodation' && <img className={classes.icon} src={IMAGES.ICONS.bed} />}
                {form.type === 'amountPerNight' && <img className={classes.icon} src={IMAGES.ICONS.nights} />}
                {form.type === 'amountPerPerson' && <img className={classes.icon} src={IMAGES.ICONS.guest} />}
                {form.type === 'amountPerReservation' && <img className={classes.icon} src={IMAGES.ICONS.calendar2} />}
                {form.type === 'percentOfTotal' && <img className={classes.icon} src={IMAGES.ICONS.discount} />}
                {form.type === 'rateSlabs' && <LayersIcon className={classes.icon} />}
              </div>
            </div>

            <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.name')}
              </div>

              {/* --------------------------------------------------------------------- */}
              {/* TAX NAME */}
              {/* --------------------------------------------------------------------- */}
              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.name} onClick={() => setEdit(true)}>{tax.name}</div>}
                {edit && (
                  <TextField
                    id="name"
                    className={classes.textField}
                    defaultValue={tax.name}
                    onChange={handleChange}
                    margin="none"
                    error={error.name}
                    fullWidth
                    autoFocus
                  />
                )}
              </div>

              {/* ------------------------------------------------ */}
              {/* EDIT TAX */}
              {/* ------------------------------------------------ */}
              {!edit && (
                <div onClick={() => setEdit(true)}>
                  <EditIcon className={classes.moreIcon} />
                </div>
              )}

            </div>

            <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.type')}
              </div>

              {/* --------------------------------------------------------------------- */}
              {/* TAX TYPE */}
              {/* --------------------------------------------------------------------- */}
              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {tax.type === 'amountPerAccommodation' && t('beds.taxesSnapshot.taxType.amountPerAccommodation')}
                  {tax.type === 'amountPerNight' && t('beds.taxesSnapshot.taxType.amountPerNight')}
                  {tax.type === 'amountPerPerson' && t('beds.taxesSnapshot.taxType.amountPerPerson')}
                  {tax.type === 'amountPerReservation' && t('beds.taxesSnapshot.taxType.amountPerReservation')}
                  {tax.type === 'percentOfTotal' && t('beds.taxesSnapshot.taxType.percentOfTotal')}
                  {tax.type === 'rateSlabs' && t('beds.taxesSnapshot.taxType.rateSlabs')}
                </div>}
                {edit && (
                  <Select
                    defaultValue={taxTypesArray.find(e => e.value === tax.type)}
                    onChange={handleSelect}
                    options={taxTypesArray}
                    styles={selectStyle}
                  />
                )}
              </div>

            </div>

            {/* --------------------------------------------------------------------- */}
            {/* TAX AMOUNT */}
            {/* --------------------------------------------------------------------- */}
            {(form.type !== 'rateSlabs') && <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.amount')}
              </div>

              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {!tax.type.includes('percent') ? currentSpace.currency : null} {tax.value}{tax.type.includes('percent') ? '%' : null}
                </div>}
                {edit && (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <TextField
                        id="value"
                        type="number"
                        className={classes.editText}
                        defaultValue={tax.value}
                        onChange={handleChange}
                        value={form.value}
                        margin="none"
                        error={error.value}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      {!form.type.includes('percent') ? currentSpace.currency : '%'}
                    </Grid>
                  </Grid>
                )}
              </div>

            </div>}

            {/* --------------------------------------------------------------------- */}
            {/* TAX CALCULATION */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.calculation')}
              </div>

              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {tax.calculationMode === 'exclusive' ? 'Exclusive' : 'Inclusive'}
                </div>}
                {edit && (
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="position" name="position" value={calculationMode} onChange={handleToggle} row>
                      <FormControlLabel
                        value="inclusive"
                        control={<Radio color="primary" />}
                        label={t('beds.taxesSnapshot.inclusive')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="exclusive"
                        control={<Radio color="primary" />}
                        label={t('beds.taxesSnapshot.exclusive')}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* APPLY TO MAX NIGHTS */}
            {/* --------------------------------------------------------------------- */}
            {form.type === 'amountPerNight' && (
              <>
                {edit && (
                  <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

                    <div className={classes.leftAlign}>
                      Does this tax have a max length?
                    </div>

                    <div className={clsx(classes.clickable, classes.rightAlign)}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={hasMaxLength}
                          onChange={(e) => maxLengthStatusHandler(e.target.value)}
                          row
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="No"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                )}

                {hasMaxLength === 'yes' && (
                  <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

                    <div className={classes.leftAlign}>
                      Apply to max nights
                    </div>

                    <div className={clsx(classes.clickable, classes.rightAlign)}>
                      {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                        {tax?.maxNights || 0}
                      </div>}
                      {edit && (
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <TextField
                              id="maxNights"
                              type="number"
                              className={classes.editText}
                              onChange={handleChange}
                              value={form.maxNights}
                              margin="none"
                              fullWidth
                            />
                          </Grid>
                          <Grid item> Nights </Grid>
                        </Grid>
                      )}
                    </div>

                  </div>
                )}
              </>
            )}

            {/* --------------------------------------------------------------------- */}
            {/* TAX CODE */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.internalCode')}
              </div>

              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {tax.code}
                </div>}
                {edit && (
                  <TextField
                    id="code"
                    className={classes.editText}
                    defaultValue={tax.code}
                    onChange={handleChange}
                    margin="none"
                    fullWidth
                  />
                )}
              </div>

            </div>

            {/* --------------------------------------------------------------------- */}
            {/* RATE SLABS */}
            {/* --------------------------------------------------------------------- */}
            {(form.type === 'rateSlabs') && <div className={classes.container} style={edit ? {} : { borderBottom: '1px solid #eeeeee' }}>

              <div className={classes.leftAlign}>
                {t('beds.taxesSnapshot.rateSlab')}
              </div>

              <div className={clsx(classes.clickable, classes.rightAlign)}>
                {!edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {tax.rateSlabRules.map((slab, index) =>
                    <div className={classes.slabRow} key={index}>
                      <div>{t('beds.taxesSnapshot.roomPrice')}: {slab.priceFrom} - {slab.priceTo}</div>
                      <div style={{ marginLeft: 10 }}>{t('beds.taxesSnapshot.tax')}: {slab.value}%</div>
                    </div>
                  )}
                </div>}
                {edit && <div className={classes.field} onClick={() => setEdit(true)}>
                  {rateSlabs.map((slab, index) =>
                    <div className={clsx(classes.slabRow, classes.editRow)} key={index}>
                      <TextField
                        id="priceFrom"
                        error={error['priceFrom'+index]}
                        label="Room Price From"
                        className={classes.editText}
                        value={0}
                        value={rateSlabs[index].priceFrom}
                        onChange={(e) => handleRateUpdate(e, index)}
                        margin="none"
                      />
                      <TextField
                        id="priceTo"
                        label="Room Price To"
                        error={error['priceTo'+index]}
                        className={clsx(classes.editText, classes.editRate)}
                        value={rateSlabs[index].priceTo}
                        onChange={(e) => handleRateUpdate(e, index)}
                        margin="none"
                      />
                      <TextField
                        id="value"
                        label="Tax Rate %"
                        className={clsx(classes.editText, classes.editRate)}
                        value={rateSlabs[index].value}
                        onChange={(e) => handleRateUpdate(e, index)}
                        margin="none"
                      />
                      {rateSlabs.length > 1 && <CloseIcon className={classes.removeSlab} onClick={() => removeSlab(index)} />}
                    </div>
                  )}
                  <div className={classes.newSlab} onClick={addSlab}>{t('beds.taxesSnapshot.addTaxTier')}</div>
                </div>}
              </div>

            </div>}

            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL UPDATES */}
            {/* --------------------------------------------------------------------- */}
            {edit && (
              <div className={classes.submit}>
                <Button size="small" variant="outlined" className={classes.cancelButton} onClick={handleCancelUpdates}>{t('beds.taxesSnapshot.cancel')}</Button>
                <Button size="small" variant="outlined" className={classes.button} onClick={handleSubmitUpdates}>{t('beds.taxesSnapshot.save')}</Button>
              </div>
            )}

          </div>

        </div>
      </div>

    </Dialog>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  taxes: state.taxes
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(TaxItem)));