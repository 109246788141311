import React, { useEffect } from "react";
import {
  makeStyles,
  CircularProgress,
  formatMs,
  Switch,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { TwitterPicker, ChromePicker } from "react-color";
import clsx from "clsx";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import {
  getPayMethodMapping,
  updatePayMethodMapping,
} from "../../redux/actions/odoo/paymentMethod";

import Select from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 30,
    textAlign: "center",
  },
  noLabel: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 400,
    marginTop: 30,
    textAlign: "center",
  },
  resetLabel: {
    fontSize: "1.25rem",
    color: "#666666",
    fontWeight: 400,
    marginTop: 10,
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  titleRow: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topMargin: {
    marginTop: 25,
  },
  fieldContainer: {
    border: "1px solid #dddddd",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 10,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  cancelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: 10,
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.3rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 10,
    paddingBottom: 10,
  },
  resetButton: {
    background: "#ffffff",
    borderRadius: 25,
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#666666",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  yesButton: {
    background: "#E0E0E0",
    borderRadius: 25,
    color: "#000000",
    cursor: "pointer",
    fontSize: "1.25rem",
    textAlign: "center",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  marginBoth: {
    marginTop: 10,
    marginBottom: 10,
  },
  icon: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    marginTop: 3,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 600,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "1px",
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const PaymentMethod = (props) => {
  const { t } = props;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    loadingProperty,
    odoo,
    transactionModes,
    loading,
  } = props;
  const [data, setData] = React.useState([{ journalID: "", modeID: "" }]);
  const [odooJournal, setOdooJournal] = React.useState([]);
  const [paymentMethods, setPayMethods] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [save, setSave] = React.useState(false);
  const [reset, setReset] = React.useState(false);

  const [discardedMode, setDiscardedMode] = React.useState([]);
  const [discardedJournal, setDiscardedJournal] = React.useState([]);

  const handleRefresh = () => {
    let modes = [];

    if (currentSpace.transactionModes) {
      for (let [key, value] of Object.entries(transactionModes)) {
        if (
          transactionModes[key].isActive &&
          transactionModes[key].propertyID === currentSpace.propertyID
        )
          if (transactionModes[key].name !== "surcharge")
            modes.push({
              value: transactionModes[key]._id,
              label:
                transactionModes[key].name.charAt(0).toUpperCase() +
                transactionModes[key].name.slice(1),
            });
      }
    }

    let journal = [];
    let sel = [];

    if (odoo.paymentMethods && odoo.paymentMethods.length > 0) {
      odoo.paymentMethods.map((pm) => {
        journal.push({
          value: pm.odooJournalID,
          label: `${pm.odooJournalName}[${pm.odooJournalCode}]`,
        });
        if (pm.modeID) {
          sel.push({ journalID: pm.odooJournalID, modeID: pm.modeID });
        }
      });
    }

    if (sel.length > 0) {
      let disJournal = [];
      let disMode = [];

      sel.map((s) => {
        journal.map((j, index) => {
          if (j.value === s.journalID) {
            disJournal.push(journal[index]);
            journal.splice(index, 1);
          }
        });
        modes.map((m, index) => {
          if (m.value === s.modeID) {
            disMode.push(modes[index]);
            modes.splice(index, 1);
          }
        });
      });

      setDiscardedJournal(disJournal);
      setDiscardedMode(disMode);
    }

    setOdooJournal(journal);
    setPayMethods(modes);
    if (journal.length > 0 && modes.length > 0)
      setData([...sel, { journalID: "", modeID: "" }]);
    else setData([...sel]);
  };

  useEffect(() => {
    if (odoo && !odoo.default) handleRefresh();
  }, [odoo]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const selectData = (selectedOption, index, type) => {
    const newVal = [...data];
    if (type === "journal") {
      newVal[index].journalID = selectedOption.value;
      if (newVal[index].modeID.length > 0) {
        if (index === data.length - 1) {
          if (paymentMethods.length > 0 && odooJournal.length > 0)
            setData([...newVal, { journalID: "", modeID: "" }]);
          else setData([...newVal]);
        } else {
          setData(newVal);
        }
        if (!save) {
          setSave(true);
          setReset(false);
        }
      } else {
        setData(newVal);
      }

      if (discardedJournal[index]) {
        let dJournal = [...discardedJournal];
        dJournal[index] = {
          value: selectedOption.value,
          label: selectedOption.label,
        };
        let journal = [...odooJournal];
        journal.push(discardedJournal[index]);
        let newJournal = journal.filter(
          (p) => p.value !== selectedOption.value
        );
        setOdooJournal(newJournal);
        setDiscardedJournal(dJournal);
      } else {
        let dJournal = [...discardedJournal];
        dJournal.push(selectedOption);
        setDiscardedJournal(dJournal);
        let journal = odooJournal.filter(
          (j) => j.value !== selectedOption.value
        );
        setOdooJournal(journal);
      }
    } else {
      newVal[index].modeID = selectedOption.value;
      if (newVal[index].journalID) {
        if (index === data.length - 1) {
          if (paymentMethods.length > 0 && odooJournal.length > 0)
            setData([...newVal, { journalID: "", modeID: "" }]);
          else setData([...newVal]);
        } else {
          setData(newVal);
        }
        if (!save) {
          setSave(true);
          setReset(false);
        }
      } else {
        setData(newVal);
      }
      if (discardedMode[index]) {
        let dMode = [...discardedMode];
        dMode[index] = {
          value: selectedOption.value,
          label: selectedOption.label,
        };
        let methods = [...paymentMethods];
        methods.push(discardedMode[index]);
        let newMethods = methods.filter(
          (p) => p.value !== selectedOption.value
        );
        setPayMethods(newMethods);
        setDiscardedMode(dMode);
      } else {
        let dMode = [...discardedMode];
        dMode.push(selectedOption);
        setDiscardedMode(dMode);
        let methods = paymentMethods.filter(
          (p) => p.value !== selectedOption.value
        );
        setPayMethods(methods);
      }
    }
  };

  const handleSubmit = () => {
    if (odoo.paymentMethods.length > 0) {
      let arr = [];
      odoo.paymentMethods.map((pm) => {
        let collection = _.find(data, { journalID: pm.odooJournalID });
        if (collection) {
          arr.push({
            odooJournalID: pm.odooJournalID,
            odooJournalCode: pm.odooJournalCode,
            modeID: collection.modeID,
            isActive: true,
          });
        } else {
          arr.push({
            odooJournalID: pm.odooJournalID,
            odooJournalCode: pm.odooJournalCode,
            modeID: null,
            isActive: false,
          });
        }
      });

      props.dispatch(updatePayMethodMapping(currentSpace.propertyID, arr));
      setSave(false);
    }
  };

  const handleSure = () => {
    setReset(true);
  };

  const handleReset = () => {
    let journal = [...odooJournal];
    let modes = [...paymentMethods];
    discardedJournal.map((j) => {
      journal.push(j);
    });
    discardedMode.map((m) => {
      modes.push(m);
    });

    setOdooJournal(journal);
    setPayMethods(modes);
    setDiscardedJournal([]);
    setDiscardedMode([]);
    // Before this, the modified data also needs to be sent to backend
    // Need to check if API allows reset option
    setData([{ journalID: "", modeID: "" }]);
  };

  const handleCancel = () => {
    setSave(false);
    handleRefresh();
  };

  const handleDelete = (index) => {
    let newData = [...data];
    let journal = [...odooJournal];
    let modes = [...paymentMethods];
    let dJournal = [...discardedJournal];
    let dModes = [...discardedMode];
    newData.splice(index, 1);
    dJournal.splice(index, 1);
    dModes.splice(index, 1);
    journal.push(discardedJournal[index]);
    modes.push(discardedMode[index]);

    if (odooJournal.length === 0 || paymentMethods.length === 0) {
      setData([...newData, { journalID: "", modeID: "" }]);
    } else {
      setData(newData);
    }

    setDiscardedMode(dModes);
    setDiscardedJournal(dJournal);
    setOdooJournal(journal);
    setPayMethods(modes);
    setSave(true);
  };

  return (
    <div>
      {/*(discardedJournal.length > 0) && <div className={clsx(classes.row, classes.topMargin)}>
        {(!reset) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={8}>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.resetButton} onClick={handleSure}>
                  Reset
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
        {(reset) && <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <p className={classes.resetLabel}>Are you sure you want to reset the mapping?</p>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.yesButton} onClick={handleReset}>
                  Yes
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
      </div>*/}
      {odoo && odoo.paymentMethods && odoo.paymentMethods.length > 0 && (
        <div className={classes.marginBoth}>
          <Grid container alignItems="center" className={classes.topMargin}>
            <Grid item xs={12}>
              <div className={classes.row}>
                <Grid container alignItems="center">
                  <Grid item sm={5}>
                    <div className={clsx(classes.title, classes.titleRow)}>
                      {t("automate.odoo.transactionModes")}
                    </div>
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item sm={5}>
                    <div className={clsx(classes.title, classes.titleRow)}>
                      Odoo Journals
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {data.map((x, i) => {
            return (
              <div className={classes.row} key={i}>
                <Grid container alignItems="center">
                  <Grid item sm={5} xs={12}>
                    <div className={classes.fieldContainer}>
                      <Select
                        hideSelectedOptions={true}
                        value={discardedMode[i] ? discardedMode[i] : ""}
                        styles={selectStyle}
                        options={paymentMethods}
                        onChange={(val) => selectData(val, i, "mode")}
                        theme={(theme) => ({
                          ...theme,
                          border: "1px",
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item sm={5} xs={12}>
                    <div className={classes.fieldContainer}>
                      <Select
                        hideSelectedOptions={true}
                        value={discardedJournal[i] ? discardedJournal[i] : ""}
                        styles={selectStyle}
                        options={odooJournal}
                        onChange={(val) => selectData(val, i, "journal")}
                        theme={(theme) => ({
                          ...theme,
                          border: "1px",
                          colors: {
                            ...theme.colors,
                            primary: "#666666",
                            primary25: "#dddddd",
                          },
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={1}>
                    {discardedJournal[i] && discardedMode[i] ? (
                      <DeleteIcon
                        className={classes.icon}
                        onClick={() => handleDelete(i)}
                      />
                    ) : (
                      <span></span>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      )}
      {save && (
        <div className={clsx(classes.row, classes.topMargin)}>
          <div className={classes.saveContainer}>
            {/*<div className={classes.button} onClick={handleSubmit}>
            Save
          </div>*/}
            <div className={classes.linkContainer}>
              <span className={classes.cancelContainer}>
                <div className={classes.button} onClick={handleCancel}>
                  Cancel
                </div>
              </span>
              <span className={classes.saveContainer}>
                <div className={classes.button} onClick={handleSubmit}>
                  Save
                </div>
              </span>
            </div>
          </div>
        </div>
      )}
      {!odoo && !odoo.paymentMethods && (
        <div className={classes.noLabel}>
          Complete settings to map your payment methods.
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingProperty: state.loading.LOAD_PROPERTY,
  loading: state.loading,
  odoo: state.odoo,
  transactionModes: state.transactionModes,
});

export default withTranslation()(connect(mapStateToProps)(PaymentMethod));
