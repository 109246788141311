import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import Select from 'react-select';
import clsx from 'clsx';
import moment from "moment-timezone";
import { InputBase } from '@material-ui/core';
import { DayPickerSingleDateController, DateRangePicker } from "react-dates";

import Button from '@material-ui/core/Button';
import NumberInput from '../../common/NumberInput';
import ConfirmForm from '../../common/ConfirmForm';
import { DATE_FORMATS, fetchDateFormat } from '../../../utils/utility';
import Pill from '../../common/Pill';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    submit: {
        display: 'flex',
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    picContainer: {
        alignItems: 'center',
        // cursor: 'pointer',
        display: 'flex',
    },
    placeholder: {
        background: '#F2F2F2',
        borderRadius: '15px',
        height: 80,
        marginRight: 15,
        overflow: 'hidden',
        width: 80,
    },
    image: {
        borderRadius: 15,
        objectFit: 'cover',
        height: 80,
        width: 80,
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 5,
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: 0,
    },
    warning: {
        color: '#ff9800',
        marginTop: 10
    },
    input: {
        display: 'none'
    },
    deleteButton: {
        borderRadius: '50%',
        cursor: 'pointer',
        height: 20,
        opacity: 0.6,
        padding: 8,
        width: 20,
        '&:hover': {
            background: '#E0E0E0',
        }
    },
    leftAlignSubmit: {
        width: '60%'
    },
    righttAlignSubmit: {
        width: '40%',
        display: 'flex',
        justifyContent: "flex-end",
    },
    cancelButton: {
        margin: '0 10px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
    },
    field: {
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        display: 'flex',
        height: 35,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'calc(80% - 32px)',
        '& > div': {
            width: '100%'
        },
        '&.disabled': {
            backgroundColor: '#E0E0E0'
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    select: {
        border: '1px solid #E0E0E0',
        borderRadius: 5,
        width: '80%',
    },
    multiLine: {
        alignItems: 'flex-start',
        height: 100,
        overflowY: 'hidden'
    },
    cancel: {
        color: '#999999',
        fontSize: '1.2rem',
        marginRight: 15,
        '&:hover': {
            background: '#ffffff'
        }
    },
    imageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 40,
    },
    uploadButton: {
        color: '#999999',
        fontSize: '1.2rem',
        marginTop: 10,
    },
    camera: {
        display: 'inline-block',
        height: 15,
        opacity: 0.5,
        width: 15,
        marginRight: 10
    },
    input: {
        display: "none"
    },
    back: {
        '&:hover': {
            background: '#ffffff'
        }
    },
    submitButton: {
        background: '#000000',
        fontWeight: 600,
        fontSize: '1.2rem',
        '&:hover': {
            background: '#000000',
            boxShadow: 'none'
        }
    },
    toggle: {
        alignItems: 'center',
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: 25,
        display: 'flex',
        width: '200px',
    },
    toggleButton: {
        alignItems: 'center',
        color: '#BDBDBD',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        height: 35,
        justifyContent: 'center',
        width: '50%'
    },
    toggleSelect: {
        background: '#4F9581',
        boxShadow: '-15px 0px 17px -7px #F2F2F2',
        color: '#FFFFFF',
    },
    left: {
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
    },
    right: {
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
    },
    blockItems: {
        marginBottom: 5,
        alignItems: 'center',
        background: '#FFFFFF',
        borderRadius: 5,
        boxShadow: '0 3px 4px 0 #ECECEC',
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: '1.3rem',
        height: 40,
        justifyContent: 'center',
        marginRight: 10,
        width: 50,
        [theme.breakpoints.down('xs')]: {
            padding: '8px 16px'
        },
    },
    blockItemsValue: {
        marginLeft: 12,
        marginTop: 15
    },
    rangePickerContainer: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
        },
    },
    selected: {
        backgroundColor: theme.palette.grey[300],
    },
    fieldContainer: {
        width: 'calc(80%)',
        border: '1px solid #E0E0E0',
        height: 35,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
    },
    dateContainer: {
        color: '#484848',
        fontSize: '1.4rem',
        fontWeight: 500,
        width: '100%',
        height: 35,
        '& input': {
            fontWeight: 500,
            color: '#484848',
            lineHeight: '15px'
        },
        '& .DateRangePicker_picker': {
            top: '45px !important',
            zIndex: 3
        },
    },
    pillContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    dropdown: {
        border: 0,
        padding: 0,
        width: '80%'
    }
}))

const selectStyle = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        paddingLeft: 8,
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    })
};

const ratePlanTypesOptions = [
    { label: "Standard", value: 'Standard' },
    { label: "Non-refundable Rate", value: 'NRR' },
    { label: "Standard Bed & Breakfast", value: 'Standard_BB' },
    { label: "Non-refundable Bed & Breakfast", value: 'NRR_BB' }
];

const promotionTypeOptions = [
    { label: "Last Minute", value: 'LASTMINUTE' },
    { label: "Mobile", value: 'MOBILE' },
    { label: "Apps", value: 'APPS' },
    { label: "Length Of Stay", value: 'LOS' },
    { label: "Custom", value: 'CUSTOM' }
]

const daysOfWeek = [
    { value: 'Monday', label: 'Mon' },
    { value: 'Tuesday', label: 'Tue' },
    { value: 'Wednesday', label: 'Wed' },
    { value: 'Thursday', label: 'Thu' },
    { value: 'Friday', label: 'Fri' },
    { value: 'Saturday', label: 'Sat' },
    { value: 'Sunday', label: 'Sun' }
]

const filterDaysHandler = (daysToExclude) => {
    if (daysToExclude.length === 0) return [];
    return daysOfWeek.filter(day => !daysToExclude.includes(day.value)).map(day => day.value);
}

const Form = (props) => {
    const classes = useStyles();
    const { data, roomTypesList } = props;
    const [error, setError] = React.useState({ name: false });
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [days, setDays] = React.useState(filterDaysHandler(data?.conditions?.stayDates?.isDayOfWeekOneOf || []));
    const [dates, setDates] = React.useState(data?.conditions?.stayDates?.isNotOneDayOf || []);
    const [range, setRange] = React.useState({
        startDate: data?.conditions?.stayDates?.isAfterOrSameDayThan ? moment(data?.conditions?.stayDates?.isAfterOrSameDayThan) : null,
        endDate: data?.conditions?.stayDates?.isBeforeOrSameDayThan ? moment(data?.conditions?.stayDates?.isBeforeOrSameDayThan) : null
    });
    const [available, setAvailable] = React.useState(data?.conditions?.stayDates?.isAfterOrSameDayThan && data?.conditions?.stayDates?.isBeforeOrSameDayThan ? false : true)

    const [focusedInput, setFocusedInput] = React.useState(null);
    const [formData, setFormData] = React.useState({
        id: data.id || null,
        isActive: data.isActive || false,
        name: data.name || '',
        type: data.type || "CUSTOM",
        discount: data?.benefits?.PERCENTDISCOUNT?.value || 0,
        isTotalNightsGreaterOrEqualThan: data?.conditions?.stayDates?.isTotalNightsGreaterOrEqualThan || 0,
        isDaysToArrivalMoreOrEqualThan: data?.conditions?.stayDates?.isDaysToArrivalMoreOrEqualThan || 0,
        isDaysToArrivalLessOrEqualThan: data?.conditions?.stayDates?.isDaysToArrivalLessOrEqualThan || 0,
        roomTypeIds: data?.conditions?.roomTypeIds?.containsAnyOf || [],
        ratePlanTypes: data?.conditions?.ratePlanTypes?.containsAnyStringOf || [],
    });

    const checkHandler = (checked, value, name) => {
        let checkedArray = formData[name] || [];

        if (!checked) {
            const index = checkedArray.indexOf(value);
            if (index > -1) {
                checkedArray.splice(index, 1);
            }
        }
        if (checked) {
            checkedArray.push(value)
            setError({ ...error, [name]: false })
        }

        setFormData({ ...formData, [name]: checkedArray });
    }

    const onChangeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });
        if (error[name]) setError({ ...error, [name]: false });
    }
    const selectDayHandler = day => {
        const daysArray = [...days]
        const index = daysArray.indexOf(day);
        if (index >= 0) {
            daysArray.splice(index, 1);
        } else {
            daysArray.push(day);
        }
        setDays(daysArray);
    }

    const availableHandler = (e) => {
        if (e.target.checked) {
            setRange({ startDate: null, endDate: null })
        }
        setAvailable(!available)
    }

    const dateChangeHandler = date => {
        const wasPreviouslyPicked = dates.some((d) => moment(d).isSame(date));
        if (wasPreviouslyPicked) {
            setDates((previousDates) =>
                previousDates.filter((d) => !moment(d).isSame(date))
            );
        } else {
            setDates((previousDates) => [...previousDates, fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE)]);
        }
    }

    const toggleHandler = () => {
        setFormData({ ...formData, isActive: !formData.isActive });
    }

    const handleValidate = () => {
        let foundError = false;
        let errorStep = {};
        let mandatoryFields = ['name', 'discount', 'isTotalNightsGreaterOrEqualThan'];
        let mandatoryArryFields = ['ratePlanTypes', 'roomTypeIds']
        mandatoryFields.map(item => {
            if (!formData[item]) {
                errorStep[item] = true;
                foundError = true;
            }
        })
        mandatoryArryFields.map(item => {
            if (!formData[item] || (formData[item] && formData[item].length == 0)) {
                errorStep[item] = true;
                foundError = true;
            }
        })
        if (formData.type === 'LASTMINUTE' && !formData['isDaysToArrivalLessOrEqualThan']) {
            errorStep['isDaysToArrivalLessOrEqualThan'] = true;
            foundError = true;
        }
        if (foundError) {
            setError({ ...error, ...errorStep })
            return false
        }
        else return true
    }

    const handleSubmit = () => {
        // console.log('formData', formData)
        const checkInput = handleValidate();
        // console.log("checkInput", checkInput)
        if (checkInput) {
            let payload = {
                type: formData.type,
                name: formData.name,
                isActive: formData.isActive,
                conditions: {
                    stayDates: {
                        ...(days.length && { isDayOfWeekOneOf: filterDaysHandler(days) }),
                        ...(dates.length && { isNotOneDayOf: dates }),
                        ...(range.startDate && { isAfterOrSameDayThan: fetchDateFormat(range.startDate, DATE_FORMATS.DEFAULT_DATE) }),
                        ...(range.endDate && { isBeforeOrSameDayThan: fetchDateFormat(range.endDate, DATE_FORMATS.DEFAULT_DATE) }),
                        ...(formData.isTotalNightsGreaterOrEqualThan && { isTotalNightsGreaterOrEqualThan: parseInt(formData.isTotalNightsGreaterOrEqualThan) }),
                        ...(formData.isDaysToArrivalMoreOrEqualThan && { isDaysToArrivalMoreOrEqualThan: parseInt(formData.isDaysToArrivalMoreOrEqualThan) }),
                        ...(formData.isDaysToArrivalLessOrEqualThan && { isDaysToArrivalLessOrEqualThan: parseInt(formData.isDaysToArrivalLessOrEqualThan) })
                    },
                    ...(formData?.ratePlanTypes?.length > 0 && {
                        ratePlanTypes: {
                            containsAnyStringOf: formData.ratePlanTypes
                        }
                    }),
                    ...(formData?.roomTypeIds?.length > 0 && {
                        roomTypeIds: {
                            containsAnyOf: formData.roomTypeIds
                        }
                    })
                },
                benefits: {
                    PERCENTDISCOUNT: {
                        value: parseFloat(formData.discount)
                    }
                },
                stack: formData.type === 'APPS' || formData.type === 'MOBILE'
            }
            if (formData.type === 'APPS') {
                payload.conditions.channel = { isOneStringOf: ['APPS']  }
            }
            if (formData.type === 'MOBILE') {
                payload.conditions.channel = { isOneStringOf: ['APPS', 'HW_MOBILE']  }
            }
            props.submitHandler(payload, formData.id)
        }
    }

    const confirmDeleteHandler = () => {
        props.confirmDeleteHandler(data.id)
    }

    const setPromotionType = (option) => {
        setFormData({
            ...formData,
            type: option.value
        })
    }

    return (
        <div className={classes.root}>

            <div className={classes.row}>
                <div className={classes.label}>Name</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.name })}>
                    <InputBase
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={formData.name}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.toggle}>
                    <div
                        className={clsx({ [classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: formData.isActive })}
                        onClick={() => toggleHandler()}
                    >
                        Active
                    </div>
                    <div
                        className={clsx({ [classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: !formData.isActive })}
                        onClick={() => toggleHandler()}
                    >
                        Inactive
                    </div>
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Type</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.type }, classes.dropdown)}>
                    <Select
                        styles={selectStyle}
                        options={promotionTypeOptions}
                        onChange={setPromotionType}
                        placeholder={'Promotion Type'}
                        value={promotionTypeOptions.find(o => o.value === formData.type)}
                        theme={theme => ({
                            ...theme,
                            boxShadow: 'none',
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Discount (%)</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.discount })}>
                    <NumberInput
                        id="discount"
                        name="discount"
                        min={0}
                        max={500}
                        value={formData.discount}
                        onChange={onChangeHandler} />
                </div>
            </div>

            {formData.type === "LASTMINUTE" && <div className={classes.row}>
                <div className={classes.label}>For bookings made day(s) or less before check-in</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.isDaysToArrivalLessOrEqualThan })}>
                    <NumberInput
                        id="isDaysToArrivalLessOrEqualThan"
                        name="isDaysToArrivalLessOrEqualThan"
                        min={0}
                        max={500}
                        value={formData.isDaysToArrivalLessOrEqualThan}
                        onChange={onChangeHandler} />
                </div>
            </div>}

            <div className={classes.row}>
                <div className={classes.label}>For the minimum night stay of (nights)</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.isTotalNightsGreaterOrEqualThan })}>
                    <NumberInput
                        id="isTotalNightsGreaterOrEqualThan"
                        name="isTotalNightsGreaterOrEqualThan"
                        min={0}
                        max={500}
                        value={formData.isTotalNightsGreaterOrEqualThan}
                        onChange={onChangeHandler} />
                </div>
            </div>

            {formData.type != "LASTMINUTE" && <div className={classes.row}>
                <div className={classes.label}>For bookings made day(s) before check-in (days)</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.isDaysToArrivalMoreOrEqualThan })}>
                    <NumberInput
                        id="isDaysToArrivalMoreOrEqualThan"
                        name="isDaysToArrivalMoreOrEqualThan"
                        min={0}
                        max={500}
                        value={formData.isDaysToArrivalMoreOrEqualThan}
                        onChange={onChangeHandler} />
                </div>
            </div>}

            <div className={classes.row}>
                <div className={classes.label}>For bookings between the dates</div>

                <FormControlLabel className={classes.noShowBooking}
                    control={<Checkbox checked={available} onChange={(e) => availableHandler(e)} name="waivedFees" />}
                    label={"Always available"}
                />

                {!available && <div className={classes.fieldContainer}>
                    <div className={classes.dateContainer}>
                        <DateRangePicker
                            startDate={range.startDate}
                            startDateId="start_date"
                            endDate={range.endDate}
                            endDateId="end_date"
                            onDatesChange={({ startDate, endDate }) => setRange({ startDate, endDate })}
                            noBorder={true}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                            hideKeyboardShortcutsPanel
                            displayFormat={'DD MMM YYYY'}
                            numberOfMonths={1}
                        />
                    </div>
                </div>}
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Disable for the following days</div>
                <DayPickerSingleDateController
                    onDateChange={dateChangeHandler}
                    focused={true}
                    onFocusChange={console.log}
                    date={null}
                    isDayHighlighted={(day) => dates.some((d) => moment(d).isSame(day, "day"))}
                    keepOpenOnDateSelect
                />
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Disable this promotion for the selected days of week</div>
                <div className={classes.rangePickerContainer}>
                    {daysOfWeek.map(item => (
                        <div
                            key={item.value}
                            className={clsx({ [classes.blockItems]: true, [classes.selected]: days.includes(item.value) })}
                            onClick={() => selectDayHandler(item.value)}
                        >
                            <div className={classes.blockItemsValue}>{item.label}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={classes.row}>
                <FormControl component="fieldset" error={error.roomTypeIds} >
                    <FormLabel className={classes.label}>Apply to the rooms</FormLabel>

                    <div className={classes.pillContainer}>
                        {roomTypesList.map((option, index) => (
                            <Pill
                                key={index}
                                name={option.roomtypename || option.displayname}
                                cancelButton={formData?.roomTypeIds?.includes(parseInt(option.roomtypeid))}
                                selected={formData?.roomTypeIds?.includes(parseInt(option.roomtypeid))}
                                handleClick={() => checkHandler(!formData?.roomTypeIds?.includes(parseInt(option.roomtypeid)), parseInt(option.roomtypeid), 'roomTypeIds')} />
                        ))}
                    </div>
                </FormControl>
            </div>

            <div className={classes.row}>
                <FormControl component="fieldset" error={error.ratePlanTypes} >
                    <FormLabel className={classes.label}>Discount from the rates</FormLabel>

                    <div className={classes.pillContainer}>
                        {ratePlanTypesOptions.map((option, index) => (
                            <Pill
                                key={index}
                                name={option.label}
                                cancelButton={formData?.ratePlanTypes?.includes(option.value)}
                                selected={formData?.ratePlanTypes?.includes(option.value)}
                                handleClick={() => checkHandler(!formData?.ratePlanTypes?.includes(option.value), option.value, 'ratePlanTypes')} />
                        ))}
                    </div>
                </FormControl>
            </div>



            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL / DELETE UPDATES */}
            {/* --------------------------------------------------------------------- */}

            <div className={classes.submit}>
                <div className={classes.leftAlignSubmit}>
                    <ConfirmForm
                        title={'Delete room type?'}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={confirmDeleteHandler}
                    >
                        Are you sure you would like to delete this promotion?
                    </ConfirmForm>

                    {!confirmOpen && data.id &&
                        <Tooltip placement="right" title={['LOS', 'LASTMINUTE'].includes(data.type) ? 'Cannot delete default promotions from Hostelworld' : ''}>
                            <img
                                src={IMAGES.ICONS.bin}
                                className={classes.deleteButton}
                                onClick={() => !['LOS', 'LASTMINUTE'].includes(data.type) && setConfirmOpen(true)}
                            />
                        </Tooltip>
                    }

                </div>
                <div className={classes.righttAlignSubmit}>
                    <Button className={classes.cancel} onClick={props.cancelHandler}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save</Button>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(Form);