import React, { useEffect, memo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from "react-dnd-html5-backend";
import clsx from 'clsx';


const useStyles = makeStyles(theme =>({
    mainCard: {
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '15px',
        borderRadius: '20px',
        marginBottom: '20px'
    },
    mainTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px'
    },
    dragIcon: {
        marginRight: '5px'
    },
    mainTitle: {
        fontSize: '1.4rem',
        fontWeight: 600
    },
    secondaryTitle: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#525252',
        marginBottom: '5px'
    },
    cardBody: {
        display: 'flex',
        padding: '5px 0px',
        margin: '0px -5px',
        flexWrap: 'wrap'
    },
    hideCard: {
        opacity: 0
    }
}));

const DraggableCard = memo(({ id, itemType, typeData, moveCard, children, cardIndex }) =>{
    const classes = useStyles();
    const ref = useRef(null);
    const [ { isDragging }, connectDrag, preview] = useDrag({
        item: { type: itemType, id, children, cardIndex },
        collect: (monitor) => {
            const result = {
              isDragging: monitor.isDragging()
            };
            return result;
        }
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const [, connectDrop] = useDrop({
        accept: itemType,
        hover(item, monitor){
            if(item.id != id){
                const hoveredCardID = id;
                const draggedCardID = item.id;

                const dragIndex = item.cardIndex;
                const hoverIndex = cardIndex;

                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect();

                // Get vertical middle
                const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                // Determine mouse position
                const clientOffset = monitor.getClientOffset();

                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                moveCard(hoveredCardID, draggedCardID, typeData);
            }
        }
    });

    connectDrag(ref);
    connectDrop(ref);
    
    return(
        <div ref={ref}  className={clsx({
                [classes.hideCard]: isDragging
            })}
        >
            {children}
        </div>
    )
});

export default DraggableCard;