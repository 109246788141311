import http from "../../utils/http";
import { normalize } from "normalizr";
import { propertySchema } from "../../schema/mappingSchema";
import { fetchChannelRoomType } from "./channelMapping";
import { CHANNEL } from "../../../utils/channels";

//----------------------------------------------------------------
// FETCH MY ALLOCATOR PROPERTIES
//----------------------------------------------------------------
export function fetchMyAllocatorProperties(userToken, viewCC, propertyID) {
  const ACTION_NAME = "FETCH_MY_ALLOCATOR_PROPERTIES";

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/myAllocator/propertyList/${userToken}`)
      .then(successResult => {
        const data = successResult.data || {};
        // console.log(data);
        let normalisedData = normalize(data, [propertySchema])
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`, 
          payload: { 
            properties: normalisedData.entities.properties, 
            userToken: userToken, 
            viewCCPassword: viewCC
          } 
        });

        if(data && data.length === 1 && data[0].id) {
          dispatch(mapMyAllocatorToProperty({
            mya_propertyID: data[0].id,
            propertyID: propertyID,
            userToken: userToken,
            viewCC
          }))
        }
      })
      .catch(errorResult => {
        let error =
        errorResult && errorResult.response && errorResult.response.data
        ? errorResult.response.data
        : errorResult;
        console.log('errorResult', error);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: {error: error} });
      });
  }
}

//----------------------------------------------------------------
// MAP MY ALLOCATOR TO PROPERTY
//----------------------------------------------------------------
export function mapMyAllocatorToProperty(payload) {
  const ACTION_NAME = "MAP_MY_ALLOCATOR_TO_PROPERTY";
  console.log("payload", payload)

  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post('/api/myAllocator/map/property', { ...payload })
      .then(successResult => {
        const data = successResult.data || [];
        dispatch(fetchChannelRoomType(payload.propertyID, CHANNEL.myAllocator.code))
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}


export function updateViewCCPassword(payload) {
  const ACTION_NAME = "UPDATE_VIEW_CC_PASSWORD";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch('/api/myAllocator/map/property', { ...payload })
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return true;
      })
      .catch(errorResult => {
        console.log('errorResult', errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  }
}