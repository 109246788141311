import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import CardCaptureForm from './CardCaptureForm';

const useStyles = makeStyles(theme =>({
    root:{
        // border: '1px solid',
        // minHeight: 'calc(100% - 100px)',
        // maxHeight: 'calc(100% - 400px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100% - 60px)'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'relative',
        top: 0,
        textAlign: "center",
        zIndex: '105'
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 24,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        }
    },
    container:{
        overflowY: 'auto',
        height: '100%'
    }
}));

const AddCardModal = props => {

    const classes = useStyles();
    const { closeModalHander, reservationID } = props;

    return(
        <Dialog
            open={true}
            classes={{ paperFullWidth: classes.root }}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
        >
            <div className={classes.dialogTitle}>
                <div className={classes.header}>Add Card</div>
                <CloseIcon className={classes.closeIcon} onClick={closeModalHander} />
            </div>

            <div className={classes.container}>
                <CardCaptureForm 
                    reservationID={reservationID}
                    closeModalHander={closeModalHander}
                />
            </div>

        </Dialog>
    );
}

export default AddCardModal;