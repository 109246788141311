const defaultCashDrawers = { default: true};

//----------------------------------------------------------------
// CASH DRAWERS REDUCER
//----------------------------------------------------------------
export default (state = defaultCashDrawers, action) => {
  switch(action.type) {
    case "LOAD_CASH_DRAWERS_SUCCESS":
      return {
        ...action.payload
      }
    case "RESET_CASH_DRAWERS":
      return {
        ...defaultCashDrawers
      }
    case "CREATE_NEW_CASH_DRAWER_SUCCESS": 
      if (state.default) {
        return {[action.payload._id]: action.payload}
      }
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "USER_LOGOUT":
      return {
        ...defaultCashDrawers
      }
    default:
      return state;
  }
};

