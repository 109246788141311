import http from "../../utils/http";
import { normalize } from 'normalizr';
import { emailSchema } from '../../schema/emailSchema';

//----------------------------------------------------------------
// LOAD EMAIL TEMPLATES
//----------------------------------------------------------------
export function loadEmailTemplates(propID) {
  const ACTION_NAME = "LOAD_EMAIL_TEMPLATES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/emailTemplateList/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ emailSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.emails });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE EMAIL
//----------------------------------------------------------------
export function newEmail(propertyID, newEmail) {
  const ACTION_NAME = "CREATE_EMAIL";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/emailTemplate`, {...newEmail, propertyID})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD EMAIL
//----------------------------------------------------------------
export function loadEmail(propID, emailID) {
  const ACTION_NAME = "LOAD_EMAIL";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/emailTemplate/${propID}/${emailID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT EMAIL
//----------------------------------------------------------------
export function editEmail(propertyID, emailID, emailTemplate) {
  const ACTION_NAME = "EDIT_EMAIL";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/emailTemplate`, {...emailTemplate, propertyID, _id: emailID})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return successResult
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
      });
  };
}

//----------------------------------------------------------------
// SEND EMAIL
//----------------------------------------------------------------
export function sendEmail(reservationID, emailTemplateID) {
  const ACTION_NAME = "SEND_EMAIL";
  console.log({reservationID, emailTemplateID})
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/email`, {reservationID, emailTemplateID})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// DELETE EMAIL TEMPLATE
//----------------------------------------------------------------
export function deleteEmailTemplate(propID, templateID) {
  const ACTION_NAME = "DELETE_EMAIL_TEMPLATE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/emailTemplate/${propID}/${templateID}`)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET EMAIL TEMPLATES
//----------------------------------------------------------------
export const resetEmailTemplates = () => (dispatch) => {
  dispatch({ type: "RESET_EMAIL_TEMPLATES"})
};

//----------------------------------------------------------------
// RESET EMAIL
//----------------------------------------------------------------
export const resetEmail = () => (dispatch) => {
  dispatch({ type: "RESET_EMAIL"})
};