import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Pill from '../../../common/Pill'

import MicrositeDescription from '../MicrositeDescription'
import { getMissingLanguage } from '../../helper'
import LanguagesView from '../LanguageView'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  }
}))

const defaultList = [
  { value: 'ageRestriction', label: 'Age Restriction' },
  { value: 'curfew', label: 'Curfew' },
  { value: 'noCurfew', label: 'No Curfew' },
  { value: 'petFriendly', label: 'Pet Friendly' },
  { value: 'nonSmoking', label: 'No Smoking' },
  { value: 'childFriendly', label: 'Child Friendly' }
]

const getKey=(content, keyName, selectedLanguage)=> {
  return content ? Object.keys(content).find(key => key.startsWith(keyName) && key.indexOf(selectedLanguage) >= 0) : null
}

const Conditions = props => {
  const classes = useStyles()
  const {
    settings,
    updateSettings,
    deleteSettingsContent,
    t
  } = props
  const { content, policy } = settings
  const [selectedLanguage, setSelectedLanguage] = React.useState('en_gb');
  const [conditionsContentKey, setConditionsContentKey] = React.useState(getKey(content, 'conditions', 'en_gb'));
  const [notesContentKey, setNotesContentKey] = React.useState(getKey(content, 'notes', 'en_gb'));

  React.useEffect(() => {
    setConditionsContentKey(getKey(content, 'conditions', selectedLanguage))
    setNotesContentKey(getKey(content, 'notes', selectedLanguage))
  }, [selectedLanguage])

  const handleClick = fieldId => {
    updateSettings(`policy.${fieldId}`, !policy[fieldId])
  }

  const seLanguageHandler = (lang) => {
    setSelectedLanguage(lang);
    deleteSettingsContent(`${conditionsContentKey}`)
    deleteSettingsContent(`${notesContentKey}`)
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.conditions.title')}</div>
        <div className='sub-title'>{t('microsite.conditions.description')}</div>

        <LanguagesView value={selectedLanguage} updateLanguage={(lang) => seLanguageHandler(lang)}/>

        <MicrositeDescription
          label={t('microsite.conditions.terms')}
          tooltipText={t('microsite.conditions.termsHelper')}
          keyName={'hostelworldTerms'}
          value={content?.[conditionsContentKey]?.content}
          disabled={false}
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${conditionsContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'conditions')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
        />

        <MicrositeDescription
          label={t('microsite.conditions.note')}
          tooltipText={t('microsite.conditions.noteHelper')}
          keyName={'hostelworldThingsToNote'}
          value={content?.[notesContentKey]?.content}
          disabled={false}
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${notesContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'notes')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
        />

        <div className='row'>
          <div className='label'>{t('microsite.conditions.additional')}</div>
          <div className={classes.container}>
            {defaultList.map(item =>
              <Pill
                key={item.value}
                name={item.label}
                cancelButton={policy[item.value]}
                selected={policy[item.value]}
                handleClick={() => handleClick(item.value)}
              />
            )}
          </div>
        </div>

      </div>
    </>
  )
}

export default withTranslation()(Conditions)
