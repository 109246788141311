const products = {};

export default (state = products, action) => {
  switch (action.type) {
    case "LOAD_PRODUCT_CATEGORIES_SUCCESS":
      if (action.payload.products) {
        return {
          ...state,
          ...action.payload.products
        };
      } else return { ...state };

    case "DELETE_PRODUCT_CATEGORY_SUCCESS":
      const deleteCategoryProduct = { ...state };

      let deleteCategoryProductKey = action.payload.productCategory.products || [];
      deleteCategoryProductKey && deleteCategoryProductKey.forEach((id) => {
        if (id && deleteCategoryProduct[id]) {
          delete deleteCategoryProduct[id]
        }
      })
      return { ...deleteCategoryProduct }

    case "CREATE_PRODUCT_SUCCESS":
      return {
        ...state,
        [action.payload._id]: { ...action.payload }
      }

    case "DELETE_PRODUCT_SUCCESS":
      const deleteProduct = { ...state };
      if (action.payload && action.payload._id && deleteProduct[action.payload._id]) {
        delete deleteProduct[action.payload._id]
      }
      return { ...deleteProduct }

    case "UPLOAD_PRODUCT_PHOTO_SUCCESS":
      const uploadProductPhoto = state[action.productID]
      return {
        ...state,
        [action.productID]: {
          ...uploadProductPhoto,
          images: [...action.payload.images]
        }
      }
    case "EDIT_PRODUCT_SUCCESS":
      const editProduct = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: {
          ...editProduct,
          ...action.payload,
          productCategoryID: action.payload.productCategoryID
        }
      }
    case "SAVE_PRODUCT_IMAGE_SUCCESS":
      const productImage = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: {
          ...productImage,
          ...action.payload,
          image: action.payload.image ? action.payload.image : null,
          productCategoryID: action.payload.productCategoryID
        }
      }
    case "LOAD_PRODUCTS_SUCCESS":
      return {
        ...state,
        ...action.payload
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};