/* global window */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/order */
/* eslint-disable import/first */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeStyles, CircularProgress, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";
import * as i18nIsoCountries from "i18n-iso-countries";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import { getAvatarFlag, initials, humanise } from "../../../utils/utility";
import GuestForm from "../GuestForm";

import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import { returnCurrent } from "../../../redux/selectors/dashboard";
import {
  createCustomer,
  updateCustomer,
  deleteCustomer,
} from "../../../redux/actions/customer/customer";
import DocumentForm from "./DocumentForm";

import IMAGES from "../../../constants/images";
import { RESERVATION_KIND } from "../../../utils/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 10 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    marginTop: 20,
    "& .MuiTabs-scrollable span": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  tabs: {
    minHeight: "unset",
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTabs-flexContainer": {
      alignItems: "flex-start",
    },
  },
  mobileTabs: {
    minHeight: "unset",
    width: "100%",
  },
  avatarText: {
    margin: "10px 0 30px",
    fontSize: "smaller",
    fontWeight: 500,
  },
  avatar: {
    border: "0px solid transparent",
    cursor: "pointer",
    height: 70,
    width: 70,
    padding: 2,
    backgroundColor: "#fff",
    "&.selected": {
      border: "0px solid #ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 50,
    },
  },
  defaultAvatar: {
    "& img": {
      width: "70%",
      height: "70%",
    },
  },
  addTab: {
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #eeeeee",
    display: "flex",
    height: 70,
    justifyContent: "center",
    width: 70,
    backgroundColor: "#fff",
    "&:hover": {
      background: "#eeeeee",
    },
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 50,
    },
  },
  addIcon: {
    height: 30,
    width: 30,
  },
  TabPanel: {
    width: "75%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  MobileTabPanel: {
    width: "100%",
  },
  header: {
    padding: 10,
    backgroundColor: "#fff",
    fontWeight: 600,
  },
  error: {
    color: "red",
    background: "#fff",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
}));

const GuestsTab = (props) => {
  const classes = useStyles();
  const { reservation, roomTypes, t } = props;
  const [value, setValue] = useState(0);
  const currentSpace = props.currentSpace;
  const timezone = currentSpace.timezone;
  const smallDevice = window.matchMedia("(max-width: 450px)").matches;
  const orientation = smallDevice ? "horizontal" : "vertical";
  const [addGuestsErrorMessage, setAddGuestsErrorMessage] = useState("");

  const handleChange = (_event, newValue) => {
    const findRoom = roomTypes[reservation?.accommodationList[0]?.roomTypeID];

    if (findRoom) {
      if (findRoom.dormOrPrivate === RESERVATION_KIND.dorm) {
        if (
          newValue === reservation.guests.length &&
          reservation.accommodationList.length === reservation.guests.length
        ) {
          setAddGuestsErrorMessage(
            t("automate.onlineCheckin.error.maximumGuest")
          );
        }
      }
    }
    setValue(newValue);
  };

  const handleUpdateCustomer = (_id, payload, isPrimary) => {
    let accommodationIDs = [];
    if (isPrimary) {
      accommodationIDs =
        reservation.accommodationList &&
        reservation.accommodationList.map((data) => data._id);
    }
    props.dispatch(updateCustomer(_id, payload, isPrimary, accommodationIDs));
  };

  const handleCreateCustomer = (payload) => {
    props.dispatch(createCustomer(reservation._id, payload));
  };

  const handleDeleteCustomer = (customerID) => {
    props.dispatch(deleteCustomer(reservation._id, customerID));
    // Google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "delete guest",
      eventLabel: "Delete Guest",
    });
    setAddGuestsErrorMessage("");
  };

  const handleBuildUserAvatar = (guestIndex) => {
    const customerData = reservation.guests[guestIndex] || {};
    let countryName =
      customerData.nationality ||
      (customerData.address ? customerData.address.country : "");
    countryName = i18nIsoCountries.getName(countryName, "en") || countryName;
    const flag = getAvatarFlag(countryName);
    const guestImage = customerData.image || "";
    const avatar = guestImage || flag;
    const userInitials = initials(
      `${customerData.firstName} ${customerData.lastName}`
    );

    return (
      <>
        <Avatar
          alt={userInitials}
          src={avatar || IMAGES.ICONS.darkBoyBroadSmile}
          className={clsx({
            [classes.avatar]: true,
            [classes.defaultAvatar]: !avatar,
          })}
        />
        <span className={classes.avatarText}>
          {humanise(customerData.firstName, customerData.lastName)}
        </span>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={orientation}
        variant="scrollable"
        className={classes.tabs}
        indicatorColor="primary"
        onChange={handleChange}
        value={value}
      >
        {reservation.guests.map((guest, index) => (
          <Tab
            value={index}
            key={guest._id}
            icon={handleBuildUserAvatar(index)}
            {...a11yProps(0)}
          />
        ))}
        <Tab
          value={reservation.guests.length}
          icon={
            <div className={classes.addTab}>
              <AddIcon className={classes.addIcon} />
            </div>
          }
        />
        <div className={classes.errorColor}>{addGuestsErrorMessage}</div>
      </Tabs>

      {reservation.guests.map((guest, index) => (
        <TabPanel
          className={classes.TabPanel}
          key={guest._id}
          value={value}
          index={index}
        >
          <div className={classes.panel}>
            <GuestForm
              customerInfo={guest}
              mode="edit"
              timezone={timezone}
              isPrimary={index === 0}
              delete
              canBeDeleted={guest.id !== reservation.guestId}
              handleUpdateCustomer={handleUpdateCustomer}
              handleDeleteCustomer={handleDeleteCustomer}
            />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className={classes.buttonSection}
            >
              <Grid item xs={12}>
                <DocumentForm
                  customerID={guest._id}
                  documents={guest?.documents || []}
                />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      ))}
      <TabPanel
        className={classes.TabPanel}
        value={value}
        index={reservation.guests.length}
      >
        <div className={classes.panel}>
          {addGuestsErrorMessage ? (
            <div className={classes.error}>{addGuestsErrorMessage}</div>
          ) : (
            <div className={classes.header}>Add new guest to reservation</div>
          )}
          <GuestForm
            mode="add"
            error={addGuestsErrorMessage}
            handleCreateCustomer={handleCreateCustomer}
            timezone={timezone}
          />
        </div>
      </TabPanel>
      {props.loading && (
        <div className="loadingOverlay">
          <CircularProgress className="loading" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  reservation: state.reservationDetails.reservation,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  loading:
    state.loading.ADD_CUSTOMER ||
    state.loading.UPDATE_CUSTOMER ||
    state.loading.DELETE_CUSTOMER ||
    state.loading.UPLOAD_CUSTOMER_DOC,
});

export default withTranslation()(connect(mapStateToProps)(GuestsTab));
