import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccessHeaderAction from '../permissions/AcessHeaderAction';

const useStyles = makeStyles(theme =>({
    root: {
        position: 'relative',
        paddingBottom: "72px",
        boxSizing: "border-box",
        minHeight: '71px'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: "15px 30px",
        borderBottom: '1px solid #e0e0e0',
        boxSizing: "border-box",
        position: 'relative',

        '& h4': {
            margin: 'unset'
        },

        '& .closeIcon': {
            cursor: 'pointer',
            fontSize: '2.4rem',
            color: '#666666',
            position: 'absolute',
            right: 15,
            top: 5
        },

        [theme.breakpoints.down('xs')]:{
            padding: "15px 20px",
        }
    },
    headerTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        '& h4': {
            padding: "5px 0px",
        }
    },
    dialogContent: {
        overflowY: "auto",
        padding: "40px",
        // paddingBottom: "unset",
        boxSizing: "border-box",
        minHeight: "calc(100vh - 240px)",
        [theme.breakpoints.down('sm')]: {
            padding: '40px 20px',
            // paddingBottom: "unset",
        }
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 99
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
}));

const DialogWithPermissions = props =>{
    const classes = useStyles();
    const { 
        closeModalHandler, 
        isOpen = true,  
        maxWidth = "sm", 
        headerTitle = '', 
        errors = false,
        isLoading = false,
        handleCloseError,
        className,
        showMessage, // Triggers snackbar with the text set for this prop
        moduleID
    } = props;

    const [message, setMessage] = useState('');

    useEffect(() => {
        if(showMessage) setMessage(showMessage);
    }, [showMessage]);

    const closeHandler = () =>{
        if(isLoading) return;
        if(handleCloseError) handleCloseError();
        closeModalHandler();
    }

    const handleHideMsg = () =>{
        setMessage('');
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={maxWidth}
            classes={{ paperFullWidth: clsx(classes.root, className) }}
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
            onClose={closeHandler}
        >
            {/* -----------------------DIALOG HEADER-----------------  */}
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <h4>{headerTitle}</h4>
                    <AccessHeaderAction moduleID={moduleID} />
                </div>
                <IconButton className="closeIcon" onClick={closeHandler}>
                    <CloseIcon />
                </IconButton>
            </div>

            {/* -----------------------DIALOG BODY-----------------  */}
            <div className={clsx(classes.dialogContent, 'dialogBody')}>
                {props.children}
            </div>

            {/* ..............Circular Loader...................... */}
            {isLoading && (
                <div className={classes.loaderContainer}>
                    <CircularProgress className={classes.loader} />
                </div>
            )}

            {/* ------------- Snackbar error messages -------------- */}
            {errors && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={handleCloseError}
                    autoHideDuration={6000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{errors}</span>}
                />
            )}
            
            {/* ------------- Snackbar custom messages -------------- */}
            {message && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={handleHideMsg}
                    autoHideDuration={6000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{message}</span>}
                />
            )}
        </Dialog>
    )
}

DialogWithPermissions.propTypes = {
    isOpen: PropTypes.bool,
    headerTitle: PropTypes.string.isRequired,
    closeModalHandler: PropTypes.func.isRequired,
    handleCloseError: PropTypes.func,
    errors: PropTypes.string,
    showMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    moduleID: PropTypes.string.isRequired
}

export default DialogWithPermissions;