import http from "../../utils/http";
import { normalize } from 'normalizr';
import { ratePlanSchema } from '../../schema/ratePlanSchema';

//----------------------------------------------------------------
// LOAD Rate Plans
//----------------------------------------------------------------
export function loadRatePlans(propID) {
  const ACTION_NAME = "LOAD_RATEPLANS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/ratePlans/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ ratePlanSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.ratePlans });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// ADD RATE PLAN
//----------------------------------------------------------------
export function addRatePlan(form) {
  const ACTION_NAME = "ADD_RATE_PLAN";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/ratePlans/`, form)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}

//----------------------------------------------------------------
// EDIT RATE PLAN
//----------------------------------------------------------------
export function editRatePlan(form) {
  const ACTION_NAME = "EDIT_RATE_PLAN";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/ratePlans/${form._id}`, form)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}


//----------------------------------------------------------------
// DELETE RATE PLAN
//----------------------------------------------------------------
export function deleteRatePlan(ratePlanID) {
  const ACTION_NAME = "DELETE_RATE_PLAN";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/ratePlans/${ratePlanID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}

//----------------------------------------------------------------
// RESET RATE PLANS
//----------------------------------------------------------------
export const resetRatePlans = () => (dispatch) => {
  dispatch({ type: "RESET_RATEPLANS"})
};

