import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Hidden from "@material-ui/core/Hidden";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { MODULE } from "../../../common/constants/permission";
import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { fetchDatePerTimezone, DATE_FORMATS } from "../../../utils/utility";

import CalendarDates from "../../beds/CalendarDates";
import DateBlockRow from "./DateBlockRow";
import EditSlotModal from "./EditSlotModal";

import { fetchRatesAvailability } from "../../../redux/actions/experiences/ratesAvailability";

const styles = (theme) => ({
  dialog: {
    background: "#F8F9FB",
    scrollY: "hidden",
  },
  calendarHeader: {
    background: "#F8F9FB",
    top: 0,
    zIndex: 2,
  },
  headerSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
    paddingBottom: 10,
  },
  calendarDates: {
    position: "relative",
  },
  calendarGrid: {
    position: "absolute",
    width: "100%",
    height: "calc(100vh - 120px)",
    pointerEvents: "none",
    "& .calendarColumn": {
      display: "inline-block",
      boxShadow: "inset 7px 0 6px -7px #F0F0F0",
      height: "100%",
    },
  },
  buttonHolder: {
    position: "absolute",
    top: 0,
    right: 10,
    height: 42,
  },
  button: {
    border: "0px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "2.2rem",
    marginRight: 10,
    padding: 10,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  accessInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accessInfoHeader: {
    marginTop: "unset",
    marginBottom: "10px",
  },
  listContainer: {
    background: "#F8F9FB",
    height: "calc(100vh - 124px)",
    overflowY: "scroll",
    position: "relative",
  },
  rateSection: {
    marginRight: 35,
    marginLeft: 35,
    paddingTop: 10,
  },
  productRow: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 4px 0 #ECECEC",
    marginBottom: "20px",
    "& .dateBlockRow:last-child": {
      borderBottom: "none",
    },
  },
  productTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    padding: "10px 5px",
    zIndex: 0,
    paddingLeft: "15px",
    marginBottom: "5px",
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
});

class RatesAvailability extends Component {
  constructor(props) {
    super(props);
    this.dayDisplayCount = window.innerWidth < 901 ? 3 : 10;
    this.dateBlockWidth = 100 / this.dayDisplayCount;
    this.initDateRange = this.getDateRangeVal();
    this.currentDateObject = {};
    this.state = {
      showEditSlot: false,
      selectedSlotData: {},
    };
  }

  getDateRangeVal = () => {
    const { currentSpace } = this.props;
    let startDate = fetchDatePerTimezone(null, currentSpace.timezone).format(
      DATE_FORMATS.DEFAULT_DATE
    );
    let endDate = fetchDatePerTimezone(null, currentSpace.timezone)
      .add(this.dayDisplayCount, "days")
      .format(DATE_FORMATS.DEFAULT_DATE);
    return { startDate, endDate };
  };

  fetchCalendarInfo = (dateObj) => {
    const { dispatch, currentSpace } = this.props;
    const params = {
      propertyID: currentSpace.propertyID,
      ...dateObj,
    };
    dispatch(fetchRatesAvailability(params));
  };

  handleCalendarDays = (dateObj) => {
    const startDate = dateObj.startDate.format(DATE_FORMATS.DEFAULT_DATE);
    const endDate = dateObj.endDate.format(DATE_FORMATS.DEFAULT_DATE);
    const dates = { startDate, endDate };
    this.currentDateObject = { ...dates };
    this.fetchCalendarInfo(dates);
  };

  slotClickHandler = (slotData) => {
    const data = {
      ...slotData,
      seletedDate: slotData.date,
      availabilityID: slotData.availID,
      dateRangeObj: { ...this.currentDateObject },
    };

    this.setState({
      selectedSlotData: { ...data },
      showEditSlot: true,
    });
  };

  closeEditSlotForm = () => {
    this.setState({
      showEditSlot: false,
      selectedSlotData: {},
    });
  };

  render() {
    const {
      classes,
      currentSpace,
      productRatesList,
      closeModalHandler,
      isLoading,
    } = this.props;
    const { startDate, endDate } = this.initDateRange;
    const { showEditSlot, selectedSlotData } = this.state;
    return (
      <div>
        <Dialog
          open={true}
          // onClose={closeModalHandler}
          className={classes.dialog}
          fullScreen={true}
        >
          <div className={classes.calendarHeader}>
            <div className={classes.headerSection}>
              <Grid container>
                <Hidden smUp>
                  <Grid item xs={12} className={classes.accessInfo}>
                    <AccessHeaderAction
                      moduleID={MODULE.RATES_AND_AVAILABILITY.ID}
                      className={classes.accessInfoHeader}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={4} sm={2}></Grid>
                <Grid item xs={8} sm={10} className={classes.calendarDates}>
                  <CalendarDates
                    dateSelectEnabled={false}
                    calendarDaysEnabled={true}
                    calendarChangeEnabled={true}
                    numberOfDays={this.dayDisplayCount}
                    datePicker={true}
                    checkIn={startDate}
                    checkOut={endDate}
                    timezone={currentSpace.timezone}
                    dateFormat={currentSpace.dateFormat}
                    handleCalendarDays={this.handleCalendarDays}
                    showAccessHeader={false}
                  />
                  <div className={classes.calendarGrid}>
                    {Array(this.dayDisplayCount)
                      .fill()
                      .map((_, i) => (
                        <div
                          key={`clanderColumn_${i}`}
                          className="calendarColumn"
                          style={{ width: `${this.dateBlockWidth}%` }}
                        />
                      ))}
                  </div>
                  <div className={classes.buttonHolder}>
                    <CloseIcon
                      className={classes.button}
                      onClick={closeModalHandler}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.listContainer}>
            {/*----------------PROGRESS LOADER--------------------- */}
            {isLoading && (
              <div className={classes.loadingState}>
                <CircularProgress className={classes.loader} />
              </div>
            )}

            <div className={classes.rateSection}>
              {productRatesList.map((product) => (
                <div
                  className={classes.productRow}
                  key={`product_${product._id}`}
                >
                  <div className={classes.productTitle}>{product.title}</div>

                  {product.grades.map((grade) => (
                    <DateBlockRow
                      key={`grade_${grade._id}`}
                      gradeData={grade}
                      productID={product.draftID}
                      slotClickHandler={this.slotClickHandler}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Dialog>

        {showEditSlot && (
          <EditSlotModal
            modalCloseHandler={this.closeEditSlotForm}
            slotData={selectedSlotData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, experiences, loading } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace] || {},
    productRatesList: experiences.productRatesList || [],
    isLoading: loading.FETCH_RATES_AVAILABILITY,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(RatesAvailability));
