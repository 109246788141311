import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD TRANSACTIONS BY DAY
//----------------------------------------------------------------
export function loadTransactionsByDay(propID, startDate, endDate, transactionModeIDs) {
  const ACTION_NAME = "LOAD_TRANSACTIONS_BY_DAY";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/transactions/${propID}`, {startDate, endDate, transactionModeIDs})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// RESET TRANSACTIONS REPORT
//----------------------------------------------------------------
export const resetTransactionsReport = () => ({
  type: 'RESET_TRANSACTIONS_REPORT'
})