import React from 'react';
import { useDrop } from 'react-dnd';
import { checkDateInPast, checkDateIfGreater, fetchDateFormat, DATE_FORMATS } from "../../utils/utility";
import { calculateBookingDates } from './calenderReservationHelper';


const CalenderDateBlock = props =>{
    
    // const calendarDate = fetchDateFormat(props.date, DATE_FORMATS.DEFAULT_DATE);

    const isBlocked = props.bedInfo.blocks && props.bedInfo.blocks.includes(fetchDateFormat(props.date, DATE_FORMATS.DEFAULT_DATE));
    // const isBlockedStartDate = (isBlocked && calendarDate == props.bedInfo.blocks[0]) ? true : false;
    // const blockedResCount = (isBlocked && props.bedInfo.blocks) ? props.bedInfo.blocks.length : 0;
    

    const [{ canDrop, isOver, item }, drop] = useDrop({
        accept: `res_${props.dragId}`, // checkDateInPast(props.date) ? '' : `res_${props.dragId}` (removed).........
        canDrop: () => !isBlocked, // !(checkDateInPast(props.date) || isBlocked) (removed).....
        drop: () =>{
            if(props.reservations){
                // Find reservation checkin greater than dropped date
                const nextReservation = props.reservations.find(data => (checkDateIfGreater(data.checkIn, props.date) && item.resID != data.resID));
                if(nextReservation){
                    const { selectedCheckOut } = calculateBookingDates(props.date, item.duration);
                    if(checkDateIfGreater(selectedCheckOut, nextReservation.checkIn)){
                        const errorMessage = 'Reservation rescheduling failed due to overlapping with another reservation.';
                        props.triggerErrorHandler(errorMessage);
                        return;
                    }
                }
            }
            return{ 
                selectedBedID: props.bedInfo._id,
                selectedBookingDate: props.date,
                selectedRoomName: props.roomName, 
                selectedBedName: props.bedName,
                isUnallocated: !props.bedInfo._id
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem()
        })
    });

    const resWidth = item ? item.resWidth : 1;

    const style={
        width: `${props.width}%`,
        position: 'relative',
        backgroundColor: isBlocked ? '#e3e3e3': 'transparent',
        // backgroundColor: 'transparent',
        boxSizing: 'content-box',
        // boxShadow: '0 0 15px 0 #dedede',
        // clipPath: 'inset(0px -2px 0px -2px)'
    };

    const hoverContainer = {
        display: 'flex',
        width: `${resWidth}%`,
        height: '100%',
        position: 'relative',
        zIndex: 9999,
        border: '1px dashed',
        borderRadius: '20px',
        pointerEvents: 'none',
    }

    // const resBlockContainer = {
    //     width: `${props.width * (blockedResCount - 1)}%`,
    //     position: 'relative',
    //     boxSizing: 'content-box',
    //     background: 'repeating-linear-gradient( 45deg, #c1c1c1, #e3e3e3 10px, #e3e3e3 10px, #e3e3e3 20px )'
    // }

    return(
        <>
            {/* {isBlocked && isBlockedStartDate && (
                <div style={resBlockContainer}></div>
            )} */}

            {/* {!isBlocked && ( */}
                <div 
                    ref={drop}
                    id={props.id} 
                    style={style}
                >   
                    {(isOver && canDrop) && (
                        <div style={hoverContainer}></div>
                    )}
                </div>
            {/* )} */}
        </>
    );
}

export default CalenderDateBlock;