const defaultTransactions = { default: true};

export default (state = defaultTransactions, action) => {
  switch (action.type) {
    case "LOAD_TRANSACTION_MODES_SUCCESS":
      if (state.default) {
        return action.payload.entities.transactionModes
      } else return {
        ...state,
        ...action.payload.entities.transactionModes
      };
    case "RESET_TRANSACTION_MODES":
      return {
        ...defaultTransactions
      }
    case "CREATE_TRANSACTION_MODE_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "UPDATE_TRANSACTION_MODE_SUCCESS":
      if (state.default) {
        return action.payload.entities.transactionModes
      } else return {
        ...state,
        ...action.payload.entities.transactionModes
      };
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};