import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  errorOops: {
    marginBottom: 10
  }
}))

export default (props) => {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.errorOops}>Oops something went wrong.</div>
      <div>Error Message: {props.errorMessage}</div>
    </div>
  )
}