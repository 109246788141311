import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

const commonProperties = {
  margin: { top: 20, right: 30, bottom: 60, left: 60 },
  animate: true,
  enableSlices: 'x',
}

const theme = {
  axis: {
    ticks: {
      line: {
        stroke: "#999999"
      },
      text: {
        fill: "#999999"
      }
    },
  },
};

const BarChart = (props) => {

  const { data, keyName, maxValue, currency, units, customToolTipHandler = '' } = props;

  const graphMaxValue =  maxValue ? { maxValue } : {};

  return (
    <ResponsiveBar
        {...commonProperties}
        data={data}
        enableLabel={false}
        keys={[keyName]}
        indexBy="date"
        colors={"#4F9581"}
        axisBottom={{
          orient: "top",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 60,
          tickValues: 5
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        minValue={0}
        { ...graphMaxValue }
        // maxValue={maxValue ? maxValue : false}
        theme={theme}
        tooltip={(point) => {

          if(customToolTipHandler) return customToolTipHandler(point);

          return(
            <span>
              {keyName}: {currency ? currency : ''}{parseFloat(point.value.toFixed(2))}{units ? `${units}` : null}
            </span>
          )
        }}
    />
  )
}

export default BarChart;