const notifications = {};

export default (state = notifications, action) => {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "TOGGLE_NOTIFICATION_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      };
    case "ADD_NOTIFICATION":
      return {
        ...state,
        [action.payload._id]: action.payload
      };
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};

