import { schema } from 'normalizr';

const rateSchema = new schema.Entity('rates', {}, {
  idAttribute: 'roomTypeID'
});

const stopSellDateSchema = new schema.Entity('stopSellDatesInfo', {}, {
  idAttribute: 'date'
})

export { rateSchema, stopSellDateSchema }