import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { getReservationDetails, resetReservationDetails } from '../../redux/actions/beds/beds';
import { returnCurrent } from '../../redux/selectors/dashboard';
import Reservation from '../reservations/Reservation';
import { getCurrency, fetchDatePerTimezone } from '../../utils/utility'; 

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  blankSlate: {
    height: 200,
    alignItems: 'center',
    color: '#666666',
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
  },
  tableRow: {
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#F2F2F2 !important',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ReservationsList = (props) => {
  const classes = useStyles();
  const { reservations, currencyData, currentSpace, t, activityTab, pageTurned } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [openReservation, setOpenReservation] = React.useState(false);

  React.useEffect(() => {
    if(!pageTurned)
      setPage(0)
  }, [pageTurned])

  const handleOpenReservation = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true)
  }

  const handleCloseReservation = () => {
    props.dispatch(resetReservationDetails());
    setOpenReservation(false);
  }

  const handleChangePage = (event, newPage) => {
    props.pageTurnFunc()
    setPage(newPage);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, reservations.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {reservations.length===0 && 
        <div className={classes.blankSlate}>
          <Typography variant="body1">No Activity for today!</Typography>
        </div>}

      {reservations.length !== 0 && <Paper className={classes.paper} square={true}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell><b>Guest Name</b></TableCell>
                <TableCell><b>Check-In</b></TableCell>
                <TableCell><b>Check-Out</b></TableCell>
                <TableCell><b>Total Amount</b></TableCell>
                <TableCell><b>Balance</b></TableCell>
                {activityTab === 'bookings' && <TableCell><b>Booking Source</b></TableCell>}
                {activityTab === 'cancellations' && <TableCell><b>Cancelled By</b></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(reservations, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      onClick={() => handleOpenReservation(row._id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell>{row.guestName}</TableCell>
                      <TableCell>{fetchDatePerTimezone(row.checkIn, currentSpace.timezone).format("DD MMM YYYY")}</TableCell>
                      <TableCell>{fetchDatePerTimezone(row.checkOut, currentSpace.timezone).format("DD MMM YYYY")}</TableCell>
                      <TableCell>{row.totalAmount && getCurrency(currencyData, Math.round(row.totalAmount))}</TableCell>
                      <TableCell>{row.balance && getCurrency(currencyData, Math.round(row.balance))}</TableCell>
                      {activityTab === 'bookings' && <TableCell>{row.bookingSource}</TableCell>}
                      {activityTab === 'cancellations' && <TableCell>{row.cancellationBy}</TableCell>}
                      {/* <TableCell>{moment(row.createdAt).format()}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={reservations.length}
          rowsPerPage={3}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
        />

        {/* ------------------------------------------------------------------ */}
        {openReservation && 
          <Reservation 
            open={openReservation}
            handleCloseReservation={handleCloseReservation} 
          />
        }
      </Paper>}
    </div>
  );
}

const mapStateToProps = state => ({
  loadingTransactionModes: state.loading.LOAD_TRANSACTION_MODES,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentSpaceID: state.dashboard.currentSpace
})

export default withTranslation()(connect(mapStateToProps)(ReservationsList));