export default (state = {}, action) => {
    switch (action.type) {
        case "REFRESH_MAPPING_SUCCESS":
            return {
                ...state,
                rooms: {...action.payload }
            };
        
        case "FETCH_GOKI_ROOMS_SUCCESS":
        case "GET_ROOM_MAPS_SUCCESS":
            return {
                ...state,
                status: action.payload.status,
                roomMappingData: { ...action.payload.roomMappingData },
                rooms: { ...action.payload.gokiRoomData },
                apiKeyDisplay: action.payload.apiKeyDisplay
            }

        case "CREATE_ROOM_MAPPING_SUCCESS":
        case "UPDATE_ROOM_MAPPING_SUCCESS":
        case "DELETE_ROOM_MAPPING_SUCCESS":
            return {
                ...state,
                roomMappingData: { ...action.payload.roomMappingData },
                rooms: { ...action.payload.gokiRoomData } 
            }
        
        case "RESET_GOKI_DATA":
            return { }

        default:
            return state;
    }
};