import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(3),
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 14,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    listContainer: {
        overflowY: 'auto'
    },
    smallSection: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            minHeight: 200
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1':{
            fontFamily: '"Gothic A1", sans- serif',
        }
    },
    buttonContainer: {
        margin: '10px 24px',
        textAlign: 'end'
    },
    button: {
        background: '#000',
        color: '#fff',
        fontWeight: 700,
        marginLeft: 5,
        '&:hover': {
            background: '#000'
        }
    },
    titleText: {
        fontSize: '1.5rem',
        marginBottom: 10,
        fontFamily: '"Gothic A1", sans- serif',
    }
}));

const ChannelStatusChangeConfirm = (props) => {
    const classes = useStyles();
    const { title, type, loading, error } = props;

    return (
        <Dialog
            open={true}
            onClose={props.closeModalHandler}
            maxWidth={'sm'}
            fullWidth={true}>

            <div className={classes.dialogTitle}>
                <div className={classes.header}>{title}</div>
                <CloseIcon className={classes.closeIcon} onClick={props.closeModalHandler} />
            </div>
            <div className={classes.listContainer}>
                <div className={classes.smallSection}>
                    <div className={classes.titleText}>Are you sure, you want to {type} the channel?</div>
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <Button onClick={props.closeModalHandler} variant="outlined" className={classes.cancelButton}>Cancel</Button>
                <Button onClick={() => props.submitHandler(type)} className={classes.button}>Confirm</Button>
            </div>
            {loading &&
                <div className={"loadingOverlay"}>
                    <CircularProgress className={"loading"} />
                </div>
            }
        </Dialog>
    )
}

export default ChannelStatusChangeConfirm;