import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ( theme => ({
  container: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
    width: 'calc(100% - 40px)'
  },
  title: {
    color: "#4f4f4f",
    fontWeight: 600
  }
}))

const SnapshotTile = (props) => {
  const { t, name } = props
  const classes= useStyles();

  return(
    <>
      <div className={classes.container} onClick={props.handleOpen}>
        <div className={classes.title}>{name}</div>
      </div>
    </>
  )
}

export default SnapshotTile;