/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable radix */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableBody } from "material-table";
import ArrowIcon from "@material-ui/icons/ArrowForward";

import Reservation from "../../reservations/Reservation";
import { fetchCurrencyFormat } from "../../../utils/utility";
import { fetchPayoutInfo } from "../../../redux/actions/reports/payout";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../../redux/actions/beds/beds";
import { payoutProcessing } from "../../../redux/selectors/reports/payout";

// import PayoutInfoRow from './PayoutInfoRow';
// import MoreButton from './MoreButton';
import Pagination from "../../common/Pagination";
import HtmlToolTip from "./HtmlToolTip";
import TransactionTable from "./TransactionTable";
import { exportExcel } from "../../utils/excelExport";

const styles = (theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  mainContainer: {
    height: "calc(100vh - 154px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: "relative",
    "&.tableContent": {
      minHeight: "calc(100vh - 450px)",
    },
  },
  rightAlign: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      boxShadow: "0 2px 30px #F0F0F0",
    },
  },
  fieldContainer: {
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  loadingState: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
    zIndex: 999,
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  graphContainer: {
    height: 400,
    width: "100%",
    marginBottom: "30px",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toggleSwitch: {
    display: "flex",
    width: "100px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 30px #E0E0E0",
    height: "40px",
    fontSize: "1.6rem",
    fontWeight: 600,
    color: "#999999",
    borderRadius: "5px",
    overflow: "hidden",

    "& .button": {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },

    "& .button.active": {
      backgroundColor: "#4F9581",
      color: "#fff",
    },
  },
  tooltip: {
    "& div": {
      marginBottom: "2px",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    fontSize: "1.2rem",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "& .cursorHelp": {
      cursor: "help",
    },
  },
  tableHeader: {
    position: "relative",
    width: "100%",
    display: "flex",
    fontWeight: 500,
    minHeight: "60px",
    boxSizing: "border-box",
    paddingRight: "50px",
    paddingLeft: "16px",
    backgroundColor: "#f6f2ee",
    fontFamily: "Roboto, sans-serif",
    "& div": {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
  },
  moreButton: {
    position: "absolute",
    right: "15px",
    top: "25%",
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
});

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

const limitOptions = [
  { label: 50, value: 50 },
  { label: 60, value: 60 },
  { label: 80, value: 80 },
];

const BasicExport = (props) => {
  const { paginationProps, handleTableRowClick, currencyCode } = props;
  const tableTitle = "Payouts";
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: "Payout Initiated",
          field: "createdDate",
          cellStyle: { minWidth: "160px" },
        },
        {
          title: "Payout Completion",
          field: "arrivalDate",
          cellStyle: { minWidth: "160px" },
        },
        { title: "Gross", field: "gross" },
        {
          title: `Fee (${currencyCode})`,
          field: "feeTotal",
          align: "center",
          render: (rowData) => <>({rowData.feeTotal})</>,
        },
        {
          title: `Payout Amount (${currencyCode})`,
          field: "total",
          align: "center",
        },
        {
          title: "Status",
          field: "commission",
          align: "center",
          render: (rowData) => (
            <>
              {rowData.status === "Failed" && (
                <HtmlToolTip title={rowData?.failureMessage}>
                  <div style={{ cursor: "help" }}>{rowData.status}</div>
                </HtmlToolTip>
              )}

              {rowData.status !== "Failed" && <div>{rowData.status}</div>}
            </>
          ),
        },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        rowStyle: { fontSize: "1.2rem" },
        paging: false,
        headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        detailPanelColumnAlignment: "right",
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(filename, headerConfig, tableData);
        },
      }}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />

            {paginationProps.pagination.total > limitOptions[0].value && (
              <tfoot>
                <tr>
                  <td colSpan="7">
                    <Pagination {...paginationProps} />
                  </td>
                </tr>
              </tfoot>
            )}
          </>
        ),
      }}
      detailPanel={(rowData) => (
        <TransactionTable
          id={rowData.id}
          transactions={rowData.transactions}
          handleTableRowClick={handleTableRowClick}
        />
      )}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
};

class Payout extends Component {
  state = {
    focusedInput: null,
    openReservation: false,
    isExpandAll: false,
    isCollapseAll: false,
    reportData: [],
    pagination: {
      total: 0,
      selectedLimit: limitOptions[0].value,
      currentOffset: 0,
    },
  };

  constructor(props) {
    super(props);
    this.presetRanges = this.getPresetRanges();
    this.state.presetValue = this.presetRanges[3];
    const { reportFrom, reportTo } = this.fetchDateRange(
      this.presetRanges[3].value
    );
    const initDates = { startDate: reportFrom, endDate: reportTo };

    const currency = fetchCurrencyFormat(
      props.currentSpace.currency ? props.currentSpace.currency : null
    );
    this.state.currency = currency;
    this.state.dates = { ...initDates };
    this.state.submittedDates = { ...initDates };
  }

  componentDidMount() {
    const { dates, pagination } = this.state;
    const paginationData = {
      offset: pagination.currentOffset,
      limit: pagination.selectedLimit,
    };
    this.getPayoutInfo(dates, paginationData);
  }

  getPayoutInfo = (dates, paginationData) => {
    const { dispatch, currentSpace } = this.props;
    const success = false;

    if (!dates.endDate) return;

    const params = {
      startDate: dates.startDate.clone().format("YYYY-MM-DD"),
      endDate: dates.endDate.clone().format("YYYY-MM-DD"),
      limit: paginationData.limit,
      offset: paginationData.offset,
    };
    const response = dispatch(fetchPayoutInfo(currentSpace.propertyID, params));
    response.then((data) => {
      if (data && data.report && data.report.length > 0) {
        this.setState({
          pagination: {
            selectedLimit: paginationData.limit,
            currentOffset: paginationData.offset,
            total: data.total,
          },
          reportData: [...data.report],
          submittedDates: {
            startDate: dates.startDate,
            endDate: dates.endDate,
          },
        });
        return;
      }

      this.setState({
        pagination: {
          selectedLimit: paginationData.limit,
          currentOffset: 0,
          total: 0,
        },
        reportData: [],
        submittedDates: { startDate: dates.startDate, endDate: dates.endDate },
      });
    });
    return success;
  };

  handleExpandAll = () => {
    if (this.state.isExpandAll) return;
    this.setState({
      isExpandAll: true,
      isCollapseAll: false,
    });
  };

  handleCollapseAll = () => {
    if (this.state.isCollapseAll) return;
    this.setState({
      isCollapseAll: true,
      isExpandAll: false,
    });
  };

  resetCollapseStatus = () => {
    this.setState({
      isCollapseAll: false,
      isExpandAll: false,
    });
  };

  getPresetRanges = () => {
    const { t } = this.props;
    return [
      { value: "today", label: t("reports.presetRange.today") },
      { value: "yesterday", label: t("reports.presetRange.yesterday") },
      { value: "past15", label: t("reports.presetRange.past15") },
      { value: "past30", label: t("reports.presetRange.past30") },
      { value: "custom", label: t("reports.presetRange.custom") },
    ];
  };

  fetchDateRange = (filterType) => {
    let reportFrom;
    let reportTo;

    switch (filterType) {
      case "today":
        reportFrom = moment();
        reportTo = moment();
        break;

      case "tomorrow":
        reportFrom = moment().add(1, "day");
        reportTo = moment().add(1, "day");
        break;

      case "yesterday":
        reportFrom = moment().subtract(1, "day");
        reportTo = moment().subtract(1, "day");
        break;

      case "past15":
      case "past30":
        const num = parseInt(filterType.substr(4));
        reportFrom = moment().subtract(num, "days");
        reportTo = moment();
        break;
    }

    return { reportFrom, reportTo };
  };

  setNewDates = (data) => {
    this.setState({ ...this.state, presetValue: data });
    if (data.value === "custom") {
      return;
    }
    const filterType = data.value;
    const { reportFrom, reportTo } = this.fetchDateRange(filterType);
    this.setState({
      dates: {
        startDate: reportFrom,
        endDate: reportTo,
      },
    });
  };

  moreButtonHandler = (type) => {
    switch (type) {
      case "ExpandAll":
        this.handleExpandAll();
        break;

      case "CollapseAll":
        this.handleCollapseAll();
        break;
    }
  };

  handleSubmit = () => {
    const { isLoading } = this.props;
    const { dates, pagination } = this.state;

    if (isLoading) return;

    const paginationData = {
      limit: pagination.selectedLimit,
      offset: 0,
    };

    this.getPayoutInfo(dates, paginationData);
  };

  handleTableRowClick = (resID) => {
    if (!resID) return;
    const { dispatch } = this.props;
    dispatch(getReservationDetails(resID));
    this.setState({ openReservation: true });
  };

  handleCloseReservation = () => {
    const { dispatch } = this.props;
    this.setState({ openReservation: false });
    dispatch(resetReservationDetails());
  };

  // Pagination functions
  handlePaginationChange = (paginationData) => {
    const { dates } = this.state;
    const { isLoading } = this.props;

    if (isLoading) return;

    this.getPayoutInfo(dates, paginationData);
  };

  calculateGrossAmount = (data) => {
    let totalGrossAmount = 0;

    data.forEach((item) => {
      totalGrossAmount += item.gross;
    });

    totalGrossAmount = totalGrossAmount.toFixed(2);
    totalGrossAmount = parseFloat(totalGrossAmount);

    return `${this.state.currency}${totalGrossAmount}`;
  };

  totalTransactionsProcessed = (data) => {
    let totalTransactions = 0;

    data.forEach((item) => {
      const transactionCount = item.transactions ? item.transactions.length : 0;
      totalTransactions += transactionCount;
    });

    return totalTransactions;
  };

  uniqueReservationsCount = (data) => {
    let resIDArray = [];

    data.forEach((item) => {
      const reservationIDs = item.transactions.map(
        (data) => data.reservationID
      );
      resIDArray = [...resIDArray, ...reservationIDs];
    });

    const uniqueReservationIDs = [...new Set([...resIDArray])];

    return uniqueReservationIDs.length;
  };

  render() {
    const {
      dates,
      focusedInput,
      reportData = [],
      openReservation,
      pagination,
      currency,
      submittedDates,
    } = this.state;

    const { classes, t, open, isLoading, handleClose, currentSpace } =
      this.props;

    const tableData = payoutProcessing(reportData, currency);

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.dialogPaper }}
        maxWidth="md"
        fullWidth
        fullScreen={window.innerWidth < 901}
      >
        {/* --------------------------------------------------------- */}
        {/* Header */}
        {/* --------------------------------------------------------- */}
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            <div className={classes.header}>Payouts {t("reports.report")}</div>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </div>

          <div className={classes.mainContainer}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                {/* -------------DURATION SELECT DROPDOWN----------------- */}
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={this.state.presetValue}
                      styles={selectStyle}
                      options={this.presetRanges}
                      onChange={this.setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>

                {/* -------------DATEPICKER----------------- */}
                <Grid item>
                  <div
                    className={clsx(
                      classes.fieldContainer,
                      classes.dateContainer
                    )}
                  >
                    <DateRangePicker
                      startDate={dates.startDate}
                      startDateId="start_date"
                      endDate={dates.endDate}
                      endDateId="end_date"
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({
                          ...this.state,
                          dates: { startDate, endDate },
                          presetValue: this.presetRanges[4],
                        });
                      }}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      focusedInput={focusedInput}
                      displayFormat={"DD MMM YYYY"}
                      hideKeyboardShortcutsPanel
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      noBorder
                      minimumNights={0}
                    />
                  </div>
                </Grid>

                {/* -------------ARROW BUTTON----------------- */}
                <Grid item xs={2} md={1} lg={1}>
                  <div
                    className={classes.buttonContainer}
                    onClick={this.handleSubmit}
                  >
                    <ArrowIcon className={classes.go} />
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* --------------------------------------------------------- */}
            {/* PAYOUT SUMMARY BUBBLES */}
            {/* --------------------------------------------------------- */}
            {!isLoading && (
              <div className={classes.listSection}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {this.calculateGrossAmount(reportData)}
                      </div>
                      <div className={classes.desc}>Amount processed</div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {this.totalTransactionsProcessed(reportData)}
                      </div>
                      <div className={classes.desc}>Transactions processed</div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={3} align="center">
                    <div className={classes.bubble}>
                      <div className={classes.amount}>
                        {this.uniqueReservationsCount(reportData)}
                      </div>
                      <div className={classes.desc}>Reservations processed</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            <div className={clsx(classes.listSection, "tableContent")}>
              {isLoading && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}

              {reportData.length > 0 && (
                <BasicExport
                  t={t}
                  data={tableData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  handleTableRowClick={this.handleTableRowClick}
                  currencyCode={currentSpace?.currency || ""}
                  paginationProps={{
                    pagination,
                    limitOptions,
                    dataCount: reportData.length,
                    setPaginationLimit: this.setPaginationLimit,
                    handlePaginationChange: this.handlePaginationChange,
                  }}
                />
              )}

              {/* {reportData.length > 0  && (
                                <div className={classes.tableContainer}>
                                    <div className={classes.tableHeader}>
                                        <div>Payout Initiated</div>
                                        <HtmlTooltip title="Date the payout is expected to arrive in the bank. This factors in delays like weekends or bank holidays.">
                                            <div className="cursorHelp">Payout Completion</div>
                                        </HtmlTooltip>
                                        <div>Gross</div>
                                        <div>Fee</div>
                                        <div>Payout Amount</div>
                                        <div>Status</div>
                                        <MoreButton 
                                            className={classes.moreButton}
                                            options={moreOptions}
                                            selectHandler = { this.moreButtonHandler } 
                                        >
                                            <MoreHorizIcon />
                                        </MoreButton>
                                    </div>

                                    {reportData.map(data =>(
                                            <PayoutInfoRow 
                                                key= {data.id}
                                                payoutData={data}
                                                isExpandAll = {isExpandAll} 
                                                isCollapseAll = {isCollapseAll}
                                                handleExpandAll = {this.handleExpandAll}
                                                resetCollapseStatus = {this.resetCollapseStatus}
                                                handleTableRowClick = {this.handleTableRowClick}
                                                propertyCurrency={currency}
                                            />
                                    ))}

                                    <Pagination 
                                        pagination = {pagination}
                                        limitOptions = {limitOptions}
                                        dataCount={reportData.length}
                                        setPaginationLimit={this.setPaginationLimit}
                                        handlePaginationChange = {this.handlePaginationChange}
                                    />
                                </div>
                            )} */}
            </div>
          </div>

          {/* Open reservation when reservation item clicked */}
          {/* ------------------------------------------------------------------ */}
          {openReservation && (
            <Reservation
              open={openReservation}
              handleCloseReservation={this.handleCloseReservation}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, loading } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace],
    isLoading: loading.FETCH_PAYOUT_INFO,
  };
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(Payout))
);
