import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const useStyles = makeStyles(theme =>({
    mainCard: {
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '10px 15px 10px 15px',
        borderRadius: '20px',
        marginBottom: '20px',
        backgroundColor: '#fff',
        cursor: 'pointer'
    },
    mainTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    dragIcon: {
        marginRight: '10px',
        opacity: 0.7,
    },
    mainTitle: {
        fontSize: '1.4rem',
        fontWeight: 600
    },
    secondaryTitle: {
        fontSize: '1.1rem',
        color: '#666666',
    },
    cardBody: {
        display: 'flex',
        padding: '5px 0px',
        margin: '0px -5px',
        flexWrap: 'wrap'
    },
    hideCard: {
        opacity: 0
    }
}));

const RoomTypeCard = ({ name, children }) =>{
    const classes = useStyles();
    return(
        <div className={classes.mainCard}>
            <div className={classes.mainTitleContainer}>
                <MenuRoundedIcon className={classes.dragIcon} />
                <div className={classes.mainTitle}>{name}</div>
            </div>
            <div className={classes.secondaryTitle}>Drag and drop rooms to sort</div>
            <div className={classes.cardBody}>
                {children}
            </div>
        </div>
    )
};

export default RoomTypeCard;