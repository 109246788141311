import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import {
  Button,
  Grid,
  Typography,
  InputBase,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchDatePerTimezone,
  checkDateIfGreater,
} from "../../utils/utility";
import { toTitleCase } from "../utils/wordUtils";
import Select from "react-select";
import ConfirmForm from "../common/ConfirmForm";
import http from "../../redux/utils/http";

import cityCodes from "../../common/colombiaCityCodes";
import { get, set } from "lodash";
import IMAGES from "../../constants/images";
import { GUEST_DOC_TYPES } from "../../common/constants/guest";

const styles = (theme) => ({
  more: {
    fontSize: "1.4rem",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dropdownInput: {
    "& > div:not(.css-26l3qy-menu)": {
      minHeight: 30,
      border: 0,
      backgroundColor: "#f8f9fb",
      boxShadow: "none",
    },
    "& > div > div > div": {
      padding: "0 8px",
    },
    "& .css-26l3qy-menu > div > div": {
      padding: 8,
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000,
    },
  },
  submitSection: {
    // marginTop: 20,
    textAlign: "end",
  },
  form: {
    // height: 'calc(100% - 75px)',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    padding: 20,
    backgroundColor: "#fff",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
    marginTop: 10,
  },
  field: {
    background: "#f8f9fb",
    borderRadius: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-6q0nyr-Svg": {
      color: "#000000",
      width: 30,
      height: 30,
    },
  },
  fieldError: {
    border: "1px solid red",
  },
  icon: {
    width: 25,
    height: 25,
  },
  moreContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  delete: {
    "& img": {
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    width: "100%",
  },
});

class GuestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: true,
      showAddress: false,
      deleteGuest: false,
      customerID: null,
      displayButton: false,
      error: this.handleGetInitialFormState(),
      customerInfo: this.handleGetInitialCustomerState(),
      countryOptions: this.handleGetCountries(),
      cityOptions: [],
      customerOptions: [],
    };

    this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
    this.handleTagCustomer = this.handleTagCustomer.bind(this);
  }

  componentDidMount() {
    if (this.props.currentSpace && this.props.currentSpace.country == "COL") {
      this.handleCityCodes();
    }
    if (this.props.customerInfo) {
      this.handleProcessData(this.props.customerInfo);
    }
    if (this.props.new) {
      this.setState({ showMore: false });
    }
    // if(this.props.mode === "edit") {
    //   this.setState({showAddress: true });
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerInfo &&
      this.props.mode === "edit" &&
      !_.isEqual(this.props.customerInfo, prevProps.customerInfo)
    ) {
      this.handleProcessData(this.props.customerInfo);
    }
  }

  handleProcessData = (data) => {
    const dateOfBirthArr = data.dateOfBirth ? data.dateOfBirth.split("T") : [];
    const dateOfBirth = dateOfBirthArr[0] || "";
    let address = { ...data.address };
    let customerInfo = {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      phone: data?.phone || "",
      nationality: data?.nationality || "",
      dateOfBirth: dateOfBirth || "",
      gender: data?.gender || "N/A",
      documents: data?.documents || [{}],
      companyName: data?.companyName || "",
      placeOfBirth: data?.placeOfBirth || "",
      taxID: data?.taxID || "",
      address: {
        lineOne: address?.lineOne || "",
        city: address?.city || "",
        state: address?.state || "",
        country: address?.country
          ? i18nIsoCountries.getAlpha3Code(address.country, "en") ||
            address.country
          : "",
        zipCode: address?.zipCode || "",
      },
      previousDestination:
        this.props.currentSpace.country !== "COL"
          ? null
          : data.previousDestination
          ? data.previousDestination
          : { type: "city", value: null },
      nextDestination:
        this.props.currentSpace.country !== "COL"
          ? null
          : data.nextDestination
          ? data.nextDestination
          : { type: "city", value: null },
    };

    this.setState({
      customerInfo: customerInfo,
      customerID: data._id,
    });
  };

  handleInputChange = (data, keyName) => {
    const customerInfo = { ...this.state.customerInfo };
    let error = { ...this.state.error };

    if (keyName === "country") customerInfo.address.country = data.value;
    else if (keyName === "previousDestinationName")
      customerInfo.previousDestination.value = data.value;
    else if (keyName === "nextDestinationName")
      customerInfo.nextDestination.value = data.value;
    else if (keyName === "previousDestinationType")
      customerInfo.previousDestination.type = data.target.value;
    else if (keyName === "nextDestinationType")
      customerInfo.nextDestination.type = data.target.value;
    else if (keyName === "documents[0].type")
      set(customerInfo, keyName, data.value);
    else if (keyName === "documents[0].issuingCountry")
      set(customerInfo, keyName, data.value);
    else if (keyName === "documents[0].expiry")
      set(customerInfo, keyName, data.target.value);
    else customerInfo[keyName] = data.value;

    set(error, keyName, false);
    this.setState({ customerInfo: customerInfo, error, displayButton: true });
    this.handleReturnCustomerInfo(customerInfo);
  };

  handleFieldChange = (name) => (event) => {
    let error = { ...this.state.error };
    const customerInfo = { ...this.state.customerInfo };

    set(customerInfo, name, event.target.value);

    if (event.target.value) {
      set(error, name, false);
    }

    this.setState({ customerInfo, error, displayButton: true });
    this.handleReturnCustomerInfo(customerInfo);
  };

  handleReturnCustomerInfo = (customerInfo) => {
    let count = this.props.currentCount;
    if (count >= 0 && this.props.new) {
      this.props.handleGuestInfo(count, customerInfo);
    }
  };

  handleManageCustomer = () => {
    const mode = this.props.mode;
    const customerInfo = { ...this.state.customerInfo };
    let hasErrors = this.handleValidation(customerInfo);

    if (!customerInfo?.nextDestination?.value) {
      delete customerInfo.nextDestination;
    }
    if (!customerInfo?.previousDestination?.value) {
      delete customerInfo.previousDestination;
    }

    if (
      customerInfo?.documents?.length &&
      Object.keys(customerInfo.documents[0]).length === 0
    ) {
      customerInfo.documents = [];
    }

    if (!hasErrors) {
      if (customerInfo.dateOfBirth)
        customerInfo.dateOfBirth = fetchDateFormat(
          customerInfo.dateOfBirth,
          DATE_FORMATS.DEFAULT_DATE
        );
      if (mode === "edit")
        this.props.handleUpdateCustomer(
          this.props.customerInfo._id,
          customerInfo,
          this.props.isPrimary
        );
      else if (mode === "add") this.props.handleCreateCustomer(customerInfo);
    }
  };

  handleDeleteGuest = () => {
    this.props.handleDeleteCustomer(this.props.customerInfo._id);
  };

  handleValidation = (data) => {
    let error = { ...this.state.error };
    let hasError = false;
    const mandatoryFields = ["firstName", "lastName"];
    mandatoryFields.map((item) => {
      if (!data[item]) {
        error[item] = true;
        hasError = true;
      }
    });
    if (
      data.dateOfBirth &&
      checkDateIfGreater(data.dateOfBirth, fetchDatePerTimezone(null))
    ) {
      error.dateOfBirth = true;
      // error.dateOfBirth.message = "Date cannot be in future";
      hasError = true;
    }

    if (
      data.documents &&
      data.documents.length &&
      data.documents[0].expiry &&
      !checkDateIfGreater(data.documents[0].expiry, fetchDatePerTimezone(null))
    ) {
      set(error, "documents[0].expiry", true);
      hasError = true;
    }

    // if (data.email) {
    //   error.phone = false;
    // } else if (data.phone) {
    //   error.email = false;
    // }
    this.setState({ error: error });
    return hasError;
  };

  handleGetInitialFormState() {
    const error = {
      address: {},
      documents: [{}],
    };
    return error;
  }

  handleShowMore = () => {
    this.setState({ showMore: true });
  };

  handleGetInitialCustomerState = () => {
    let customerInfo = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      gender: "N/A",
      companyName: "",
      taxID: "",
      nationality: "",
      documents: [{}],
      placeOfBirth: "",
      address: {
        lineOne: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      previousDestination:
        this.props.currentSpace && this.props.currentSpace.country === "COL"
          ? { type: "city", value: null }
          : null,
      nextDestination:
        this.props.currentSpace && this.props.currentSpace.country === "COL"
          ? { type: "city", value: null }
          : null,
    };
    return customerInfo;
  };

  handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({
          value: i18nIsoCountries.alpha2ToAlpha3(key),
          label: countries[key],
        });
      }
    }
    return countryOptions;
  };

  handleCityCodes = () => {
    // console.log('city codes', cityCodes)
    this.setState({ ...this.state, cityOptions: cityCodes });
  };

  handleCustomerSearch({ firstName, lastName, phone, email, nationality }) {
    if (!firstName && !lastName && !phone && !email && !nationality) {
      return this.setState({ customerOptions: [] });
    }
    this.setState({ customerSearchInProgress: true });
    this.props.setDrawerState({ searchInProgress: true });
    http
      .get(
        "/api/frontdesk/search/customer/" + this.props.currentSpace.propertyID,
        { params: { firstName, lastName, phone, email, nationality } }
      )
      .then(({ data: customerOptions }) => {
        this.setState({ customerOptions, customerSearchInProgress: false });
        this.props.setDrawerState({
          customers: customerOptions.map((c) => ({
            data: c,
            onClick: () => {
              this.handleTagCustomer(c),
                this.props.setDrawerState({ open: false });
            },
          })),
          searchInProgress: false,
        });
      })
      .catch((err) => {
        this.setState({ customerSearchInProgress: false });
        this.props.setDrawerState({ searchInProgress: false });
      });
  }

  handleTagCustomer(customer) {
    if (!customer) return;

    const customerInfo = { ...this.state.customerInfo };
    customerInfo.customerID = customer._id;
    const fields = ["firstName", "lastName", "email", "phone", "nationality"];
    fields.forEach((field) => {
      // if(customer[field] && !customerInfo[field]) {
      customerInfo[field] = customer[field];
      // }
    });
    this.setState({ customerInfo });
    this.handleReturnCustomerInfo(customerInfo);
  }

  getDocumentTypeOptions = () => {
    return GUEST_DOC_TYPES.map((docType) => ({
      value: docType.value,
      label: this.props.t(docType.translationID),
    }));
  };

  render() {
    const { t, currentSpace } = this.props;
    const genderOptions = [
      { value: "Male", label: t("newReservation.guest.male") },
      { value: "Female", label: t("newReservation.guest.female") },
      { value: "N/A", label: "N/A" },
    ];

    const documentTypeOptions = this.getDocumentTypeOptions();
    const classes = this.props.classes;
    const mode = this.props.mode;
    const customerInfo = { ...this.state.customerInfo };
    let error = { ...this.state.error };
    const countryOptions = [...this.state.countryOptions];
    const cityOptions = [...this.state.cityOptions];
    let count = this.props.currentCount;
    count = count >= 0 ? count : -1;
    let dateOfBirthValue = this.props.customerInfo
      ? fetchDateFormat(
          this.props.customerInfo.dateOfBirth,
          DATE_FORMATS.DEFAULT_DATE,
          null
        )
      : "";
    let docExpiry = this.props.customerInfo
      ? fetchDateFormat(
          get(this.props.customerInfo, "documents[0].expiry", ""),
          DATE_FORMATS.DEFAULT_DATE,
          null
        )
      : "";

    return (
      <div className={classes.form}>
        <Grid container spacing={2}>
          {this.props.delete && this.props.canBeDeleted && (
            <Grid item xs={12} sm={12} align="right" className={classes.delete}>
              {!this.state.deleteGuest && (
                <img
                  className={classes.icon}
                  src={IMAGES.ICONS.bin}
                  onClick={() => this.setState({ deleteGuest: true })}
                />
              )}
              <ConfirmForm
                open={this.state.deleteGuest}
                setOpen={(value) => this.setState({ deleteGuest: value })}
                onClose={() => this.setState({ deleteGuest: false })}
                onConfirm={this.handleDeleteGuest}
              >
                {t("newReservation.guest.confirmDelete")}
              </ConfirmForm>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>
              {t("newReservation.guest.firstName")}*
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.firstName,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"firstName" + count}
                fullWidth
                onChange={(event) => {
                  this.handleFieldChange("firstName")(event);
                  if (this.props.setDrawerState) {
                    const { lastName, phone, email, nationality } =
                      this.state.customerInfo;
                    this.handleCustomerSearch({
                      firstName: event.target.value,
                      lastName,
                      phone,
                      email,
                      nationality,
                    });
                  }
                }}
                error={error.firstName}
                value={customerInfo.firstName}
                onBlur={() => {
                  if (
                    this.props.setDrawerState &&
                    (this.state.customerSearchInProgress ||
                      this.state.customerOptions.length > 0)
                  ) {
                    this.props.setDrawerState({ open: true });
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>
              {t("newReservation.guest.lastName")}*
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.lastName,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"lastName" + count}
                fullWidth
                onChange={(event) => {
                  this.handleFieldChange("lastName")(event);
                  if (this.props.setDrawerState) {
                    const { firstName, phone, email, nationality } =
                      this.state.customerInfo;
                    this.handleCustomerSearch({
                      firstName,
                      lastName: event.target.value,
                      phone,
                      email,
                      nationality,
                    });
                  }
                }}
                error={error.lastName}
                value={customerInfo.lastName}
                onBlur={() => {
                  if (
                    this.props.setDrawerState &&
                    (this.state.customerSearchInProgress ||
                      this.state.customerOptions.length > 0)
                  ) {
                    this.props.setDrawerState({ open: true });
                  }
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>
              {t("newReservation.guest.email")}
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.email,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"email" + count}
                fullWidth
                onChange={this.handleFieldChange("email")}
                error={error.email}
                value={customerInfo.email}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>
              {t("newReservation.guest.phone")}
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.phone,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"phone" + count}
                fullWidth
                onChange={this.handleFieldChange("phone")}
                error={error.phone}
                value={customerInfo.phone}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>
              {t("newReservation.guest.nationality")}
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.nationality,
              })}
            >
              <Select
                isDisabled={this.props.disabled}
                value={countryOptions.find(
                  (e) => e.value === customerInfo.nationality
                )}
                isSearchable={true}
                name="nationality"
                className={classes.dropdownInput}
                onChange={(e) => this.handleInputChange(e, "nationality")}
                options={countryOptions}
              />
            </div>
          </Grid>

          {/* -------------------------------------------- */}
          {/* SHOW MORE INPUTS SECTION */}
          {/* -------------------------------------------- */}
          {!this.props.showLess && this.state.showMore && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.dob")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.dateOfBirth,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="date"
                    id={"dateOfBirth" + count}
                    fullWidth
                    onChange={this.handleFieldChange("dateOfBirth")}
                    error={error.dateOfBirth}
                    defaultValue={dateOfBirthValue}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.gender")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.gender,
                  })}
                >
                  <Select
                    isDisabled={this.props.disabled}
                    value={genderOptions.find(
                      (e) => e.value === customerInfo.gender
                    )}
                    isSearchable={true}
                    name="gender"
                    className={classes.dropdownInput}
                    onChange={(e) => {
                      this.handleInputChange(e, "gender");
                    }}
                    options={genderOptions}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.placeOfBirth")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.placeOfBirth,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"placeOfBirth" + count}
                    fullWidth
                    onChange={this.handleFieldChange("placeOfBirth")}
                    error={error.placeOfBirth}
                    value={customerInfo.placeOfBirth}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.companyName")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.companyName,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"companyName" + count}
                    fullWidth
                    onChange={this.handleFieldChange("companyName")}
                    error={error.companyName}
                    value={customerInfo.companyName}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.taxID")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.taxID,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"taxID" + count}
                    fullWidth
                    onChange={this.handleFieldChange("taxID")}
                    error={error.taxID}
                    value={customerInfo.taxID}
                  />
                </div>
              </Grid>

              {!this.state.showAddress ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    className={classes.more}
                    onClick={() => this.setState({ showAddress: true })}
                  >
                    {t("newReservation.guest.showAddress")} <ExpandMoreIcon />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>
                      {t("newReservation.guest.address")}
                    </div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.lineOne,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"address" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.lineOne")}
                        error={error.address.lineOne}
                        value={
                          customerInfo.address
                            ? customerInfo.address.lineOne
                            : t("newReservation.guest.lineOne")
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>
                      {t("newReservation.guest.city")}
                    </div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.city,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"city" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.city")}
                        error={error.address.city}
                        value={
                          customerInfo.address ? customerInfo.address.city : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>
                      {t("newReservation.guest.state")}
                    </div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.state,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"state" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.state")}
                        error={error.address.state}
                        value={
                          customerInfo.address ? customerInfo.address.state : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>
                      {t("newReservation.guest.zipCode")}
                    </div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.zipCode,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"zipCode" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.zipCode")}
                        error={error.address.zipCode}
                        value={
                          customerInfo.address
                            ? customerInfo.address.zipCode
                            : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>
                      {t("newReservation.guest.country")}
                    </div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.country,
                      })}
                    >
                      <Select
                        isDisabled={this.props.disabled}
                        value={
                          customerInfo.address && customerInfo.address.country
                            ? countryOptions.find(
                                (e) => e.value === customerInfo.address.country
                              )
                            : null
                        }
                        isSearchable={true}
                        name="country"
                        className={classes.dropdownInput}
                        onChange={(e) => this.handleInputChange(e, "country")}
                        options={countryOptions}
                      />
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.docType")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(error, "documents[0].type"),
                  })}
                >
                  <Select
                    isDisabled={this.props.disabled}
                    value={documentTypeOptions.find(
                      (e) => e.value === get(customerInfo, "documents[0].type")
                    )}
                    isSearchable={true}
                    className={classes.dropdownInput}
                    onChange={(e) =>
                      this.handleInputChange(e, "documents[0].type")
                    }
                    options={documentTypeOptions}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.docNum")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(error, "documents[0].number"),
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    fullWidth
                    onChange={this.handleFieldChange("documents[0].number")}
                    error={get(error, "documents[0].number")}
                    value={get(customerInfo, "documents[0].number", "")}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.docIssuingCountry")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(
                      error,
                      "documents[0].issuingCountry"
                    ),
                  })}
                >
                  <Select
                    isDisabled={this.props.disabled}
                    value={countryOptions.find(
                      (e) =>
                        e.value ===
                        get(customerInfo, "documents[0].issuingCountry", "")
                    )}
                    isSearchable={true}
                    error={get(error, "documents[0].issuingCountry")}
                    className={classes.dropdownInput}
                    onChange={(e) =>
                      this.handleInputChange(e, "documents[0].issuingCountry")
                    }
                    options={countryOptions}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>
                  {t("newReservation.guest.docExpiry")}
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(error, "documents[0].expiry"),
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="date"
                    fullWidth
                    onChange={(e) =>
                      this.handleInputChange(e, "documents[0].expiry")
                    }
                    error={get(error, "documents[0].expiry")}
                    defaultValue={docExpiry}
                  />
                </div>
              </Grid>

              {/* -------------------------------------------- */}
              {/* PREV / NEXT FOR COLOMBIA */}
              {/* -------------------------------------------- */}
              {currentSpace.country === "COL" && (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.label}>
                    {t("newReservation.guest.prevDestination")}
                  </div>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="prevDestination"
                          name="previousDestinationType"
                          value={customerInfo.previousDestination.type}
                          onChange={(e) =>
                            this.handleInputChange(e, "previousDestinationType")
                          }
                        >
                          <FormControlLabel
                            value="city"
                            control={<Radio />}
                            label="City"
                          />
                          <FormControlLabel
                            value="country"
                            control={<Radio />}
                            label="Country"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.field}>
                        <Select
                          isDisabled={this.props.disabled}
                          value={
                            customerInfo?.previousDestination?.type == "city"
                              ? cityOptions.find(
                                  (e) =>
                                    e.value ===
                                    parseInt(
                                      customerInfo?.previousDestination?.value
                                    )
                                )
                              : countryOptions.find(
                                  (e) =>
                                    e.value ===
                                    customerInfo?.previousDestination?.value
                                )
                          }
                          isSearchable={true}
                          name="previousDestinationName"
                          className={classes.dropdownInput}
                          onChange={(e) =>
                            this.handleInputChange(e, "previousDestinationName")
                          }
                          options={
                            customerInfo?.previousDestination?.type == "city"
                              ? cityOptions
                              : countryOptions
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {currentSpace.country === "COL" && (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.label}>
                    {t("newReservation.guest.nextDestination")}
                  </div>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="nextDestination"
                          name="nextDestinationType"
                          value={customerInfo?.nextDestination?.type}
                          onChange={(e) =>
                            this.handleInputChange(e, "nextDestinationType")
                          }
                        >
                          <FormControlLabel
                            value="city"
                            control={<Radio />}
                            label="City"
                          />
                          <FormControlLabel
                            value="country"
                            control={<Radio />}
                            label="Country"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.field}>
                        <Select
                          isDisabled={this.props.disabled}
                          value={
                            customerInfo?.nextDestination?.type == "city"
                              ? cityOptions.find(
                                  (e) =>
                                    e.value ===
                                    parseInt(
                                      customerInfo?.nextDestination?.value
                                    )
                                )
                              : countryOptions.find(
                                  (e) =>
                                    e.value ===
                                    customerInfo?.nextDestination?.value
                                )
                          }
                          isSearchable={true}
                          name="nextDestinationName"
                          className={classes.dropdownInput}
                          onChange={(e) =>
                            this.handleInputChange(e, "nextDestinationName")
                          }
                          options={
                            customerInfo?.nextDestination?.type == "city"
                              ? cityOptions
                              : countryOptions
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                {this.props.masterBookerView && (
                  <FormControlLabel
                    value="masterbooker"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          this.props.toggleMasterbooker(e.target.checked)
                        }
                      />
                    }
                    label="This is a masterbooker"
                    labelPlacement="end"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {!this.props.showLess && !this.state.showMore && (
                  <Typography
                    className={classes.more}
                    onClick={this.handleShowMore}
                  >
                    {t("newReservation.guest.showMore")} <ExpandMoreIcon />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {!this.props.new && (
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                className={classes.buttonSection}
              >
                {this.state.displayButton && !this.props.error && (
                  <Grid item xs={12} className={classes.submitSection}>
                    <Button
                      variant={"outlined"}
                      className={classes.button}
                      onClick={this.handleManageCustomer}
                    >
                      {t("actions.save")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(GuestForm))
);
