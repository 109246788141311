import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { Snackbar, makeStyles, CircularProgress } from '@material-ui/core'

import Channel from '../../../channels/Channel'
import { loadRatePlans } from '../../../../redux/actions/rates/ratePlans'
import {
    fetchChannelRoomType,
    createRoomTypeMapping,
    deleteRoomTypeMapping,
    deletePropertyChannelMapping,
    resetChannelMapping,
    createChannelFullRefreshPost,
} from '../../../../redux/actions/channelMapping/channelMapping'
import { fetchChannelRatePlans } from '../../../../redux/actions/channelMapping/channelRatePlanMapping'
import { resetError } from '../../../../redux/actions/reservation/editReservation'
import { CHANNEL } from '../../../../utils/channels';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100% - 30px)',
        width: '100%'
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '#F2F2F2',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%'
    },
    content: {
        minHeight: '400px',
        background: '#F2F2F2'
    },
    blankSlateContainer: {
        background: '#FFFFFF'
    },
    blankSlate: {
        margin: '50px auto',
        width: '80%',
        textAlign: 'center',
        lineHeight: 1.5,
    },
    blankText: {
        fontSize: '1.4rem',
        marginBottom: 20,
    },
}))

const Mapping = props => {
    const classes = useStyles()
    const { currentSpace, channel, mapping, ratePlans, loading, hideLoader } = props
    const [loader, setLoader] = React.useState(false)
    const channelName = CHANNEL.BookingCom.code
    const code = CHANNEL[channelName].code;

    React.useEffect(() => {
        if (props.open) {
            props.dispatch(fetchChannelRoomType(currentSpace.propertyID, code))
            props.dispatch(fetchChannelRatePlans(currentSpace.propertyID, code))
            if (ratePlans.default) {
                props.dispatch(loadRatePlans(currentSpace.propertyID))
            }
        }
    }, [props.open])

    React.useEffect(() => {
        if (loader && !props.loadingRefresh) { setLoader(props.loadingRefresh) }
    }, [props.loadingRefresh])

    // -------- Close snackbar -------------
    const handleCloseError = () => {
        props.dispatch(resetError('CREATE_ROOM_TYPE_MAPPING'))
        props.dispatch(resetError('CREATE_CHANNEL_FULL_REFRESH'))
        props.dispatch(resetError('DELETE_PROPERTY_CHANNEL_MAPPING'))
        props.dispatch(resetError('UPDATE_VIEW_CC_PASSWORD'))
    }

    const handleRefresh = (itemID) => {
        if (!itemID) {
            setLoader(true)
        }
        props.dispatch(createChannelFullRefreshPost(currentSpace.propertyID, itemID, code))
    }

    const handleMapping = (payload) => {
        const response = props.dispatch(createRoomTypeMapping({ ...payload, propertyID: currentSpace.propertyID }, code));
        response.then(data => {
            props.dispatch(fetchChannelRoomType(currentSpace.propertyID, code))
        }).catch(error => {
            // setLoader(false);
        });
    }

    const handleDeleteMapping = (itemID) => {
        props.dispatch(deleteRoomTypeMapping(itemID, code));
    }
    const handleDeletePropertyMapping = () => {
        props.dispatch(deletePropertyChannelMapping(currentSpace.propertyID, code))
    }

    const handleClose = () => {
        props.dispatch(resetChannelMapping())
        props.closeHandler()
    }


    return (
        <React.Fragment>
            <div className={clsx(classes.content, {[classes.blankSlateContainer]: !(loading || loader) && !channel[CHANNEL[channelName].code]})}>
                {loading &&
                    <div className={"loadingOverlay"}>
                        <CircularProgress className={"loading"} />
                    </div>
                }

                {!(loading || loader) && (!channel[CHANNEL[channelName].code] &&
                    <div className={classes.blankSlate}>
                        <div className={classes.blankText}>No room types on {CHANNEL[channelName].name}, please create new room types</div>
                    </div>
                )}

                <div className={classes.root}>
                    {!(loading || loader) && (channel[CHANNEL[channelName].code] &&
                        <Channel
                            channelType={CHANNEL[channelName].code}
                            item={channel[CHANNEL[channelName].code]}
                            roomTypes={currentSpace.roomTypes}
                            mapping={mapping[CHANNEL[channelName].code]}
                            handleRefresh={handleRefresh}
                            handleMapping={handleMapping}
                            handleDeleteMapping={handleDeleteMapping}
                            handleDeletePropertyMapping={handleDeletePropertyMapping}
                        />
                    )}
                </div>

            </div>
            {/* ------------- Snackbar error messages -------------- */}
            {props.errors && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open
                    onClose={() => handleCloseError()}
                    autoHideDuration={6000}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id='message-id'>{props.errors}</span>}
                />
            )}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const { dashboard, spaces, ratePlans, channelMapping, loading, errors, inbox: { channels } } = state;
    return {
        currentSpaceID: dashboard.currentSpace,
        currentSpace: spaces[dashboard.currentSpace],
        ratePlans: ratePlans,
        channel: channelMapping.channel || {},
        mapping: channelMapping.mapping || {},

        loadingRefresh: loading.CREATE_CHANNEL_FULL_REFRESH,
        loading: loading.FETCH_CHANNEL_ROOM_TYPE ||
            loading.CREATE_CHANNEL_FULL_REFRESH ||
            loading.DELETE_PROPERTY_CHANNEL_MAPPING,
        errors: errors.CREATE_CHANNEL_FULL_REFRESH ||
            errors.CREATE_ROOM_TYPE_MAPPING ||
            errors.DELETE_PROPERTY_CHANNEL_MAPPING,
    }
}

export default connect(mapStateToProps)(Mapping)
