const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_CALENDAR_SNAPSHOT_SUCCESS":
      return {
        ...state,
        [action.payload.spaceID]: action.payload.calendarSnapshot
      }
    
    case "GET_CALENDAR_RENDER_DATA_SUCCESS":
      return {
        ...state,
        calendarDisplayData: [...action.payload]
      }
    case "RESET_CALENDER_DISPLAY":
      return {
        ...state,
        calendarDisplayData: []
      }

    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};  