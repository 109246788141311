import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme =>({
    label: {
        fontSize: '1.2rem',
        color: '#666666',
        fontWeight: 400,
        marginTop: 10
    },
    newLabel: {
        fontSize: '1.2rem',
        color: '#666666',
        fontWeight: 400,

        '&.center': {
            textAlign: 'center'
        }
    },
    newButton: {
        background: '#E0E0E0',
        borderRadius: 25,
        color: '#000000',
        cursor: 'pointer',
        fontSize: '1.25rem',
        textAlign: 'center',
        fontWeight: 600,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: 10,
        paddingBottom: 10
    },
    resetbutton: {
        background: '#ffffff',
        borderRadius: 25,
        boxShadow: "0 1px 10px #E0E0E0",
        color: '#666666',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.25rem',
        fontWeight: 600,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: 10,
        paddingBottom: 10
    },
    firstRow: {
        alignItems: 'center',
        display: 'flex',
        marginTop: 10,
        marginBottom: 20,
    },
}));

const ProformaStats = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { 
        handleManualReset,
        issuedCount,
        finalCount,
        lastRestDate, 
        taxIssuedCount
    } = props;
    const [isResetConfirm, setIsResetConfirm] = React.useState(false);

    const resetHandler = () => {
        handleManualReset();
        setIsResetConfirm(false);
    }

    const resetConfrimHandler = () => {
        setIsResetConfirm(true);
    }

    return (
        <>
            {/* -------------------------MANUAL RESET----------------------- */}
            {!isResetConfirm && (
                <Grid container alignItems="center">
                    <Grid item xs={8}>
                        <p className={classes.label}>{t('invoice.manualReset')}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <div 
                            className={classes.resetbutton} 
                            onClick={resetConfrimHandler}
                        >
                            {t('actions.reset')}
                        </div>
                    </Grid>
                </Grid>
            )}

            {isResetConfirm && (
                <Grid container alignItems="center">
                    <Grid item xs={8}>
                        <p className={classes.label}>{t('invoice.resetConfirmation')}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <div 
                            className={classes.newButton} 
                            onClick={resetHandler}
                        >
                            {t('invoice.yes')}
                        </div>
                    </Grid>
                </Grid>
            )}

            <Divider className={classes.firstRow}/>

            {/* -------------------------PROFORMA STATS----------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <div className={classes.newLabel}>{t('invoice.issuedCount')}</div>
                </Grid>
                <Grid item xs={5}>
                    <p className={clsx(classes.newLabel, 'center')}><b>{issuedCount}</b></p>
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <div className={classes.newLabel}>{t('invoice.finalCount')}</div>
                </Grid>
                <Grid item xs={5}>
                    <p className={clsx(classes.newLabel, 'center')}><b>{finalCount}</b></p>
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <div className={classes.newLabel}>Tax Issued Count</div>
                </Grid>
                <Grid item xs={5}>
                    <p className={clsx(classes.newLabel, 'center')}><b>{taxIssuedCount}</b></p>
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <div className={classes.newLabel}>{t('invoice.lastReset')}</div>
                </Grid>
                <Grid item xs={5}>
                    <p className={clsx(classes.newLabel, 'center')}><b>{lastRestDate}</b></p>
                </Grid>
            </Grid>
        </>
    )
}

export default ProformaStats;