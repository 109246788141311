import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const locationList = [
    "Aquarium",
    "Arcade",
    "Bar",
    "Music venue",
    "Casino"
];

const styles = theme =>({
    root: {
    
    },
    container:{
        display: 'flex',
        marginTop: '20px',
    },
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        }
    },
    btnContainer:{
        marginTop: '10px',
        '& .button':{
            fontWeight: 600
        }
    }
});

const defaultData = '';
class ActivityProvisions extends Component{
    
    maxLimit = 50;

    state = {
        provisionsData: [ defaultData ],
        provisionsError: false
    }

    componentDidMount(){
        const { productData } = this.props;
        if(productData.inclusions && productData.inclusions.length > 0){
            this.setState({ 
                provisionsData: [ ...productData.inclusions ]
            });
        }
    }

    onChangeHandler = (event, index) =>{
        const { provisionsData } = this.state;
        const value = event.target.value;
        provisionsData[index] = value;
        this.setState({ provisionsData: [ ...provisionsData ] })
    }

    addItemHandler = () =>{
        const { provisionsData } = this.state;

        if(provisionsData.length >= this.maxLimit) return;

        this.setState({
            provisionsData: [ ...provisionsData, defaultData ]
        })
    }

    removeItemHandler = index =>{
        const { provisionsData } = this.state;
        const updatedData = provisionsData.filter(((item, itemIndex) => itemIndex != index));
        this.setState({
            provisionsData: [ ...updatedData ]
        })
    }

    validateProvisions = (provisionsData) =>{

        if( provisionsData.length > 0 ){
            this.setState({ provisionsError: false });
            return true;
        }
        this.setState({ provisionsError: true });
    }

    submitHandler = () =>{
        const { provisionsData } = this.state;
        const { submitDraftHandler } = this.props;

        const formattedData = provisionsData.filter(item => item);
        if(!this.validateProvisions(formattedData)) return;
        const data = { inclusions: [ ...formattedData ] };
        submitDraftHandler(data);
    }

    render(){
        const { classes, prevStepHandler, activeStepInfo, productData } = this.props;
        const { provisionsData, provisionsError } = this.state;
        return(
            <>
                <div className={clsx("main-content", "hideScrollBar")}>
                    
                    <div className="main-content-title">Add details on what you'll provide</div>

                    <div className="sub-title">
                        You can provide food and drink, special equipment, a ticket to a concert or anything else special to make your guests comfortable
                    </div>

                    {provisionsData.map((data, index) =>(
                        <div className={classes.container} key={`provisions_${index}`}>

                            <div className={classes.inputField}>
                                <InputBase 
                                    id={`provisionInput${index}`}
                                    name={`provisionInput${index}`}
                                    className={classes.textInput}
                                    fullWidth
                                    type="text"
                                    placeholder="Enter item here"
                                    value={data}
                                    // error={true}
                                    onChange={e => this.onChangeHandler(e, index)}
                                />
                            </div>

                            {index > 0 && (
                                <Button 
                                    variant="outlined"
                                    // className="SaveBtn"
                                    onClick={() => this.removeItemHandler(index)}
                                >
                                    Remove
                                </Button>
                            )}

                        </div>
                    ))}

                    { provisionsData.length < this.maxLimit && (
                        <div className={classes.btnContainer}>
                            <Button 
                                className="button"
                                onClick={this.addItemHandler}
                            >
                                +Add another item
                            </Button>
                        </div>
                    )}

                    {provisionsError && (
                        <FormHelperText className="errorMessage">
                            Please add atleast one item
                        </FormHelperText>
                    )}

                </div>
                <Footer
                    nextClickHandler = {this.submitHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}

export default withStyles(styles)(ActivityProvisions);