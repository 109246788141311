import React, { useEffect, useState } from "react";
import { Hidden, makeStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import TimePicker from "../common/TimePicker";

import { exportExcel, groupingConfig } from "../utils/excelExport";
import {
  fetchCurrencyFormat,
  fetchDateFormat,
  getNumberAbbr,
  currencyToNumber,
  fetchCurrencySymbol,
} from "../../utils/utility";
import {
  loadTransactionsByDay,
  resetTransactionsReport,
} from "../../redux/actions/reports/transactions";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import {
  transactionProcessing,
  transactionSummaryProcessing,
  transactionModeSelect,
  transactionGraphProcessor,
} from "../../redux/selectors/reports/transactions";
import Reservation from "../reservations/Reservation";
import BarMultiAxis from "../charts/BarMultiAxis";

import CloseIcon from "@material-ui/icons/Close";
import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import Arrow from "../common/Arrow";
import { capitalize } from "lodash";
import { WORKSPACE_URL } from "../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 124px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .MuiTableCell-head": {
      backgroundColor: "#F6F2EE",
    },
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  dateHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  date: {
    borderRadius: 10,
    boxShadow: "0 2px 30px #F0F0F0",
    color: "#484848",
    cursor: "pointer",
    marginRight: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
      // boxShadow: '0 1px 10px #E0E0E0',
    },
  },
  options: {
    borderRadius: 10,
    boxShadow: "0 2px 30px #F0F0F0",
    paddingLeft: 10,
    paddingRight: 10,
    "& input": {
      borderRadius: 10,
      color: "#484848",
      fontWeight: 500,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    // boxShadow: '0 2px 30px #F0F0F0',
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
  selected: {
    background: "#4F9581",
    color: "#ffffff",
    fontWeight: 600,
  },
  bubble: {
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0 2px 30px #F0F0F0",
    display: "flex",
    flexDirection: "column",
    height: 130,
    justifyContent: "center",
    padding: 10,
    width: 130,
    borderRadius: "50%",
  },
  amount: {
    color: "#000000",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  roomTypes: {
    display: "flex",
    flexDirection: "column",
  },
  highlighted: {
    color: "red",
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  blankState: {
    fontSize: "1.3rem",
  },
  timepickerContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    "& .timePicker": {
      width: 120,
    },
    "& input": {
      fontWeight: 600,
      color: "#484848",
      fontFamily: '"Gothic A1", sans-serif',
    },
    "& fieldset": {
      border: 0,
    },
  },
  tabHolder: {
    display: "flex",
  },
  tab: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.1rem",
    flexDirection: "column",
    height: "30px",
    justifyContent: "center",
    marginRight: 10,
    width: "80px",
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  label: {
    color: "#666666",
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  action: {
    color: "#999999",
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    height: "30px",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

const getDateTime = (date, time) => {
  let timeInfo = fetchDateFormat(time, "HH:mm");
  return date && time
    ? moment(date + " " + timeInfo, "DD MMM YYYY HH:mm")
    : null;
};

const BasicExport = (props) => {
  const rowCount = props.data.length;
  const [data, setData] = useState([...props.data]);
  const {
    classes,
    startTime,
    endTime,
    currencyData,
    openInNewTab,
    handleTableRowClick,
  } = props;
  const tableTitle = "Transactions";
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "currency",
    "string",
    "string",
    "currency",
  ];
  let excelColWidth = [20, 20, 20, 40, 10, 10, 25, 20, 20, 25];

  const groupingConfigParmas = {
    data,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
  };

  React.useEffect(() => {
    filterTime(startTime, endTime);
  }, [startTime, endTime]);

  const filterTime = (start, end) => {
    if (start && end) {
      let startDateTime = getDateTime(props.startDate, start);
      let endDateTime = getDateTime(props.endDate, end);

      const filtered = props.data.filter((d) => {
        let createdDateTime = moment(
          d.createdAt + " " + d.createdAtTime,
          "MMM DD, YYYY hh:mma"
        );
        if (createdDateTime.isBetween(startDateTime, endDateTime, null, "[]")) {
          return true;
        }
      });
      setData(filtered); // set filter data if condition is met
    } else {
      setData([...props.data]); // else set original data i.e testData
    }
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        {
          title: "Transaction Date",
          field: "createdAt",
          defaultSort: "asc",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Transaction Time",
          field: "createdAtTime",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Reservation Code",
          field: "resvCode",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Name",
          field: "name",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Check-in",
          field: "checkIn",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Check-out",
          field: "checkOut",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        // { title: 'Room Type', field: 'roomType', headerStyle: { backgroundColor: '#F6F2EE', zIndex: 0}},
        {
          title: `Reservation Balance (${currencyData.code})`,
          field: "balance",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
          customSort: (a, b) =>
            currencyToNumber(props.currencyData, a.balance) -
            currencyToNumber(props.currencyData, b.balance),
        },
        {
          title: "Created By",
          field: "createdBy",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: "Transaction Type",
          field: "transactionMode",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        },
        {
          title: `Transaction Amount (${currencyData.code})`,
          field: "amount",
          align: "center",
          headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
          customSort: (a, b) =>
            currencyToNumber(props.currencyData, a.amount) -
            currencyToNumber(props.currencyData, b.amount),
        },
      ]}
      data={data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        rowStyle: (rowData) => ({
          color: rowData.isVoided ? "red" : "#333333",
          fontSize: "1.2rem",
        }),
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: "open_in_new",
          tooltip: "Open in a new tab",
          onClick: (event, rowData) => openInNewTab(rowData.resvCode),
        },
      ]}
      onRowClick={(event, rowData) => handleTableRowClick(rowData.resID)}
    />
  );
};

const SummaryTable = (props) => {
  const { data, transactionModes, currentSpace, currencyData } = props;
  const rowCount = data.length;
  const tableTitle = "Transactions";
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = ["string"];
  let excelColWidth = [20];
  let columns = [{ title: "Date", field: "date", defaultSort: "asc" }];

  currentSpace?.transactionModes?.map((item) => {
    if (transactionModes[item] && transactionModes[item]?.name !== "HostelWorld") {
      columns.push({
        title: capitalize(transactionModes[item].name),
        field: transactionModes[item].name,
        align: "center",
      });
      excelHeaderType.push("currency");
      excelColWidth.push(20);
    }
  });
  columns.push({ title: "Total", field: "total", align: "center" });
  excelHeaderType.push("currency");
  excelColWidth.push(20);

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={columns}
      data={data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        sorting: false,
        emptyRowsWhenPaging: false,
        search: false,
        pageSizeOptions: [50, 60, 80],
        headerStyle: {
          backgroundColor: "#F6F2EE",
          zIndex: 0,
        },
        rowStyle: (rowData) => ({
          background: rowData.totalRow ? "#F0F0F0" : "#FFFFFF",
          color: "#333333",
          fontSize: "1.2rem",
          fontWeight: rowData.totalRow ? 600 : 500,
        }),
        exportCsv: (columns, data) => {
          const tableData = _.cloneDeep(data);
          const headerConfig = [...columns];
          const filename = `${tableTitle} ${displayDate}`;
          exportExcel(
            filename,
            headerConfig,
            tableData,
            excelHeaderType,
            excelColWidth,
            currencyData,
            true
          );
        },
      }}
    />
  );
};

const Transactions = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "today", label: t("reports.presetRange.today") },
    { value: "yesterday", label: t("reports.presetRange.yesterday") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    transactionModes,
    currentProperty,
    loadingTransactions,
  } = props;
  const [openReservation, setOpenReservation] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[0]);
  const [transactionModeFilter, setTransactionModeFilter] = useState([]);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const [tableFilter, setTableFilter] = React.useState("items");
  const [isVoidHidden, setIsVoidHidden] = React.useState(false);

  const currency = fetchCurrencyFormat(
    currentSpace.currency ? currentSpace.currency : null
  );
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };

  var tableData = useSelector((state) =>
    transactionProcessing(
      state.reports.transactions,
      state.transactionModes,
      currencyData,
      state.users,
      state.frontdeskUsers,
      state.roomTypes,
      currentSpace
    )
  );
  // removing Voided Transaction
  if (isVoidHidden) tableData = tableData.filter((d) => !d.isVoided);

  const transactionSummaryTable = useSelector((state) =>
    transactionSummaryProcessing(
      state.reports.transactions,
      state.transactionModes,
      currentSpace,
      currencyData,
      submittedDates
    )
  );
  const transactionModesArray = useSelector((state) =>
    transactionModeSelect(state.transactionModes, currentSpace)
  );
  const graphData = useSelector((state) =>
    transactionGraphProcessor(
      state.transactionModes,
      state.reports.transactions,
      {
        startDateTime: getDateTime(
          dates?.startDate?.format("DD MMM YYYY"),
          startTime
        ),
        endDateTime: getDateTime(
          dates?.endDate?.format("DD MMM YYYY"),
          endTime
        ),
      },
      currentSpace
    )
  );

  useEffect(() => {
    props.dispatch(
      loadTransactionsByDay(
        currentSpace.propertyID,
        moment().format("YYYY-MM-DD"),
        null,
        []
      )
    );
    document.title = `${t("reports.transactions")} ${t(
      "reports.report"
    )} | Counter`;
    return function cleanup() {
      props.dispatch(resetTransactionsReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewDates = (e) => {
    setIsVoidHidden(false);
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom = moment();
    let reportTo = moment();
    if (e.value == "custom") {
      return;
    } else if (e.value === "today") {
      reportFrom = moment();
      reportTo = moment();
    } else if (e.value === "yesterday") {
      reportFrom = moment().subtract(1, "day");
      reportTo = moment().subtract(1, "day");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
    }
    // console.log('POST transaction report parameters ', reportFrom.format('YYYY-MM-DD'), reportTo.format('YYYY-MM-DD'), transactionModeFilter)
    if (reportFrom.isSame(reportTo, "day")) {
      props.dispatch(
        loadTransactionsByDay(
          currentSpace.propertyID,
          reportFrom.format("YYYY-MM-DD"),
          {},
          transactionModeFilter
        )
      );
    } else {
      props.dispatch(
        loadTransactionsByDay(
          currentSpace.propertyID,
          reportFrom.format("YYYY-MM-DD"),
          reportTo.format("YYYY-MM-DD"),
          transactionModeFilter
        )
      );
    }
    setSubmittedDates({ startDate: reportFrom, endDate: reportTo });
    setDates({ startDate: reportFrom, endDate: reportTo });
    setEndTime(null);
    setStartTime(null);
  };

  const setTransactionMode = (array) => {
    if (array) {
      let idArray = [];
      array.map((item) => idArray.push(item.value));
      setTransactionModeFilter(idArray);
    } else {
      setTransactionModeFilter([]);
    }
  };

  const handleSubmit = () => {
    setIsVoidHidden(false);
    if (dates.startDate.isSame(dates.endDate, "day")) {
      props.dispatch(
        loadTransactionsByDay(
          currentSpace.propertyID,
          dates.startDate.format("YYYY-MM-DD"),
          {},
          transactionModeFilter
        )
      );
    } else {
      props.dispatch(
        loadTransactionsByDay(
          currentSpace.propertyID,
          dates.startDate.format("YYYY-MM-DD"),
          dates.endDate.format("YYYY-MM-DD"),
          transactionModeFilter
        )
      );
    }
    setSubmittedDates({ startDate: dates.startDate, endDate: dates.endDate });
  };

  const openInNewTab = (resvCode) => {
    window.open(
      `${WORKSPACE_URL}/${currentProperty.code}/reservation/${resvCode}`,
      "_blank"
    );
  };

  const handleTableRowClick = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("reports.transactions")} {t("reports.report")}
          </div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.TRANSACTIONS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            {/* --------------------------------------------------------- */}
            {/* DATE RANGE FILTERS */}
            {/* --------------------------------------------------------- */}
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setStartTime(null);
                      setEndTime(null);
                      setPresetValue(presetRanges[4]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder={true}
                    minimumNights={0}
                  />
                </Grid>
                <Grid item className={classes.dateHolder}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.timepickerContainer}>
                      <TimePicker
                        value={startTime}
                        label={"Start time"}
                        onChange={(time) => setStartTime(time)}
                      />
                      <Arrow />
                      <TimePicker
                        value={endTime}
                        label={"End time"}
                        onChange={(time) => setEndTime(time)}
                      />
                    </div>
                    {startTime && endTime && (
                      <span
                        style={{ marginLeft: 2, cursor: "pointer" }}
                        onClick={() => {
                          setStartTime(null);
                          setEndTime(null);
                        }}
                      >
                        Clear
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.fieldContainer}>
                        <Select
                          styles={selectStyle}
                          isMulti
                          options={transactionModesArray}
                          onChange={setTransactionMode}
                          placeholder={t("reports.transactionPage.filterby")}
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Loading State */}
            {/* ------------------------------------------------------------------ */}
            {loadingTransactions && (
              <div className={classes.loadingState}>
                <CircularProgress />
              </div>
            )}

            {/* Transaction Summary Graph */}
            {/* ------------------------------------------------------------------ */}
            {!loadingTransactions && graphData.labels?.length !== 0 && (
              <div
                className={classes.listSection}
                style={{ maxWidth: goFullScreen ? "1000px" : "100%" }}
              >
                <BarMultiAxis
                  inputs={{
                    labels: graphData.labels,
                    leftAxis: graphData.revenue,
                    rightAxis: graphData.count,
                  }}
                />
              </div>
            )}

            {/* --------------------------------------------------------- */}
            {/* TABLE FILTERS */}
            {/* --------------------------------------------------------- */}
            {!loadingTransactions &&
              (isVoidHidden || tableData.length !== 0) && (
                <div className={classes.listSection}>
                  <div className={classes.label}>Table Data</div>
                  <div className={classes.tabHolder}>
                    <div
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: tableFilter == "items",
                      })}
                      onClick={() => setTableFilter("items")}
                    >
                      Itemized
                    </div>
                    <div
                      className={clsx({
                        [classes.tab]: true,
                        [classes.tabSelected]: tableFilter == "subTotal",
                      })}
                      onClick={() => setTableFilter("subTotal")}
                    >
                      Sub-Totals
                    </div>
                    {tableFilter == "items" && (
                      <div className={classes.checkbox}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              defaultChecked={false}
                              checked={isVoidHidden}
                              onChange={(e) => setIsVoidHidden(!isVoidHidden)}
                            />
                          }
                          label="Hide Voided Transactions"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* Blank State */}
            {/* ------------------------------------------------------------------ */}
            {!loadingTransactions && tableData.length == 0 && (
              <div className={clsx(classes.loadingState, classes.blankState)}>
                No transaction data available for this date range.
              </div>
            )}
            {/* Transaction Table -- Items */}
            {/* ------------------------------------------------------------------ */}
            {!loadingTransactions &&
              tableData.length !== 0 &&
              tableFilter == "items" && (
                <div className={classes.listSection}>
                  {isVoidHidden && (
                    <BasicExport
                      startTime={startTime}
                      endTime={endTime}
                      data={tableData}
                      currencyData={currencyData}
                      startDate={submittedDates.startDate.format("DD MMM YYYY")}
                      endDate={submittedDates.endDate.format("DD MMM YYYY")}
                      sameDate={moment(submittedDates.startDate).isSame(
                        submittedDates.endDate,
                        "day"
                      )}
                      handleTableRowClick={handleTableRowClick}
                      openInNewTab={openInNewTab}
                    />
                  )}
                  {!isVoidHidden && (
                    <BasicExport
                      startTime={startTime}
                      endTime={endTime}
                      data={tableData}
                      currencyData={currencyData}
                      startDate={submittedDates.startDate.format("DD MMM YYYY")}
                      endDate={submittedDates.endDate.format("DD MMM YYYY")}
                      sameDate={moment(submittedDates.startDate).isSame(
                        submittedDates.endDate,
                        "day"
                      )}
                      handleTableRowClick={handleTableRowClick}
                      openInNewTab={openInNewTab}
                    />
                  )}
                </div>
              )}

            {/* Transaction Table -- Sub Totals*/}
            {/* ------------------------------------------------------------------ */}
            {!loadingTransactions &&
              tableData.length !== 0 &&
              tableFilter == "subTotal" && (
                <div className={classes.listSection}>
                  <SummaryTable
                    classes={classes}
                    currentSpace={currentSpace}
                    transactionModes={transactionModes}
                    data={transactionSummaryTable}
                    currencyData={currencyData}
                    startDate={submittedDates.startDate.format("DD MMM YYYY")}
                    endDate={submittedDates.endDate.format("DD MMM YYYY")}
                    sameDate={moment(submittedDates.startDate).isSame(
                      submittedDates.endDate,
                      "day"
                    )}
                  />
                </div>
              )}
          </div>
        </div>

        {/* Open reservation when transaction item clicked */}
        {/* ------------------------------------------------------------------ */}
        {openReservation && (
          <Reservation
            open={openReservation}
            handleCloseReservation={handleCloseReservation}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentProperty: state.property,
  transactionModes: state.transactionModes,
  loadingTransactions: state.loading.LOAD_TRANSACTIONS_BY_DAY,
});

export default withTranslation()(connect(mapStateToProps)(Transactions));
