const beds = {};

export default (state = beds, action) => {
  switch (action.type) {
    case "LOAD_ROOMTYPES_SUCCESS":
      if (action.payload.beds){

        const bedsData = { ...action.payload.beds };
        const formattedBedsData = {};

        Object.keys(bedsData).forEach(bedID=>{

          formattedBedsData[bedID] = { ...bedsData[bedID] };

          // REINSTATING BLOCKED DAYS IN BEDS REDUCER...
          if(state?.[bedID]?.blocks){
            formattedBedsData[bedID] = { ...formattedBedsData[bedID], blocks: [ ...state[bedID].blocks ] };
          }

          // REINSTATING RESERVATION IDS IN BEDS REDUCER...
          if(state?.[bedID]?.reservations){
            formattedBedsData[bedID] = { ...formattedBedsData[bedID], reservations: [ ...state[bedID].reservations ] };
            return;
          }

          formattedBedsData[bedID] = { ...formattedBedsData[bedID], reservations: [] };
        });

        return {
          ...state,
          ...formattedBedsData
        };
      }
      else return { ...state }

    case "CREATE_ROOMTYPE_SUCCESS":
      return {
        ...state,
        ...action.payload.beds
      };

    case "CREATE_ROOM_SUCCESS":
      const preCreateRoomBed = { ...state };
      let createdRoomBeds = action.payload.beds || [];
      createdRoomBeds && createdRoomBeds.forEach((bed) => {
        preCreateRoomBed[bed._id] = { ...bed }
      })
      return { ...preCreateRoomBed }

    case "DELETE_ROOM_SUCCESS":
      const preDeletedRoomBed = { ...state };
      let deletedRoomBeds = action.payload.beds || [];
      deletedRoomBeds && deletedRoomBeds.forEach((bed) => {
        if (preDeletedRoomBed[bed._id]) {
          delete preDeletedRoomBed[bed._id]
        }
      })
      return { ...preDeletedRoomBed }

    case "DELETE_ROOM_TYPE_SUCCESS":
      const deleteRoomTypeBeds = { ...state };
      let deleteRoomTypeBedKey = action.payload.roomType.beds || [];
      deleteRoomTypeBedKey && deleteRoomTypeBedKey.forEach((id) => {
        if (id && deleteRoomTypeBeds[id]) {
          delete deleteRoomTypeBeds[id]
        }
      })
      return { ...deleteRoomTypeBeds }

    case "DELETE_ROOMS_MAPPING_SUCCESS":
      const deleteRoomMappingBeds = { ...state };
      let deleteRoomMappingBedKey = action.payload.distinctBedIDs || [];
      deleteRoomMappingBedKey && deleteRoomMappingBedKey.forEach((id) => {
        if (id && deleteRoomMappingBeds[id]) {
          delete deleteRoomMappingBeds[id]
        }
      })
      return { ...deleteRoomMappingBeds }

    case "LOAD_RESERVATIONS_SUCCESS":

      // resetting reservation data for state..
      let bedInfo = {};
      Object.keys(state).forEach(id => {
        bedInfo[id] = { ...state[id], reservations: [] }
        delete bedInfo[id].blocks;
      });

      // let newBeds = {};
      // Add new reservations to the beds state
      action.payload.reservations && Object.keys(action.payload.reservations).forEach((id) => {
        let bedID = action.payload.reservations[id].bedID;
        if (bedInfo[bedID]) {
          bedInfo[bedID] = {
            ...bedInfo[bedID],
            reservations: [...bedInfo[bedID].reservations, id]
          }
        } else {
          bedInfo[bedID] = {
            ...state[bedID],
            reservations: [id]
          }
        }
      })
      action.payload.blockedDays && Object.keys(action.payload.blockedDays).forEach((id) => {
        if (bedInfo[id]) {
          bedInfo[id] = {
            ...bedInfo[id],
            blocks: action.payload.blockedDays[id]
          }
        } else {
          bedInfo[id] = {
            ...state[id],
            blocks: action.payload.blockedDays[id]
          }
        }
      })
      return {
        ...bedInfo
      };
      
    case "ADD_RESERVATION_SUCCESS":
      let beds = { ...state };
      let reservationList = action.payload || [];
      reservationList.forEach((reservation) => {
        let bedID = reservation.bedID;
        if (beds[bedID]) {
          let bedReservationList = beds[bedID].reservations || [];
          bedReservationList = [...new Set([...bedReservationList, reservation.accommodationID])];
          beds[bedID] = {
            ...beds[bedID],
            reservations: [...bedReservationList]
          }
        }
      })

      return { ...state, ...beds };

    case "UPDATE_ROOMBLOCK_SUCCESS":
      let bedlist = { ...state };
      let reservationsList = action.payload || [];
      let deletedKeys = action.deletedKeys || [];
      reservationsList.forEach((reservation) => {
        let bedID = reservation.bedID;
        if (bedlist[bedID]) {
          bedlist[bedID].reservations = bedlist[bedID].reservations || [];
          if (!bedlist[bedID].reservations.includes(reservation.accommodationID)) {
            bedlist[bedID] = {
              ...bedlist[bedID],
              reservations: [...bedlist[bedID].reservations, reservation.accommodationID]
            }
          }
        }
      })

      deletedKeys.forEach((data) => {
        if (bedlist[data.bedID]) {
          let bedReservations = bedlist[data.bedID].reservations || [];
          bedReservations = bedReservations.filter(id => id !== data.accommodationID);

          bedlist[data.bedID] = {
            ...bedlist[data.bedID],
            reservations: bedReservations
          }
        }
      })

      return { ...state, ...bedlist };

    case "UPDATE_RESERVATION_SUCCESS":
      let deletedIDs = action.payload.removedAccFlag ? action.deletedKeys : [];
      const updatedResevation = { ...action.payload.updatedRes };
      const accommodationInfo = [...updatedResevation.accommodationList];
      let prevBedState = { ...state };
      // let deletedKeys = action.deletedKeys || [];

      deletedIDs && deletedIDs.forEach((data) => {
        if (data.bedID && prevBedState[data.bedID]) {
          let bedRemReservations = prevBedState[data.bedID].reservations || [];
          bedRemReservations = bedRemReservations.filter(id => id !== data.accommodationID);
          prevBedState[data.bedID] = {
            ...prevBedState[data.bedID],
            reservations: bedRemReservations
          }
        }
      })

      accommodationInfo.forEach((data) => {
        let bedID = data.bedID;
        if (prevBedState[bedID]) {
          prevBedState[bedID].reservations = prevBedState[bedID].reservations || [];
          if (!prevBedState[bedID].reservations.includes(data._id)) {
            prevBedState[bedID] = {
              ...prevBedState[bedID],
              reservations: [...prevBedState[bedID].reservations, data._id]
            }
          }
        }
      })

      return { ...state, ...prevBedState };

    case "CANCEL_RESERVATION_SUCCESS":
      const updatedBeds = { ...state };
      let accommodationList = action.payload.accommodationList || [];
      accommodationList && accommodationList.forEach((data) => {
        if (updatedBeds[data.bedID]) {
          let reservations = updatedBeds[data.bedID].reservations || [];
          reservations = reservations.filter(id => id !== data._id);

          updatedBeds[data.bedID] = {
            ...updatedBeds[data.bedID],
            reservations: reservations
          }
        }
      })
      return { ...state, ...updatedBeds };
    case "EDIT_ROOM_SUCCESS":
      const roomBeds = { ...state };
      const roomPayload = action.payload.updatedRoom || {};
      const bedPayload = action.payload.beds || [];
      if (roomPayload.roomTypeID && roomPayload.roomTypeID.dormOrPrivate === "private") {
        Object.keys(roomBeds).forEach((id) => {
          if (roomBeds[id].roomID === roomPayload._id) {
            roomBeds[id] = {
              ...roomBeds[id],
              name: roomPayload.name
            }
          }
        })
      }
      bedPayload && bedPayload.forEach(bedData => {
        if (!roomBeds[bedData._id]) {
          roomBeds[bedData._id] = bedData
        }
      })

      return { ...state, ...roomBeds };
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};