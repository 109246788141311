import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD SIBA SETUP
//----------------------------------------------------------------
export function loadSibaSetup(propertyID) {
  const ACTION_NAME = "LOAD_SETUP";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/siba/setup/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SUBMIT SIBA SETUP
//----------------------------------------------------------------
export function submitSibaSetup(body) {
  const ACTION_NAME = "SUBMIT_SETUP";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`api/frontdesk/siba/setup`, body)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE SIBA SETUP
//----------------------------------------------------------------
export function updateSibaSetup(propertyID, siba) {
  const ACTION_NAME = "UPDATE_SETUP";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/siba/setup/${propertyID}`, siba)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE SIBA SCHEDULE
//----------------------------------------------------------------
export function updateSibaSchedule(propertyID, siba) {
  const ACTION_NAME = "UPDATE_SCHEDULE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/frontdesk/siba/schedule/${propertyID}`, siba)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD SIBA PROCESSED
//----------------------------------------------------------------
export function loadSibaProcessed(propertyID) {
  const ACTION_NAME = "LOAD_PROCESSED";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/siba/processed/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// DELETE SIBA PROCESSED
//----------------------------------------------------------------
export function deleteSibaProcessed(queueID) {
  const ACTION_NAME = "DELETE_PROCESSED";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/siba/processed/${queueID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {_id: data} });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD SIBA QUEUE
//----------------------------------------------------------------
export function loadSibaQueue(propertyID) {
  const ACTION_NAME = "LOAD_QUEUE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/siba/queue/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// TRIGGER SIBA QUEUE
//----------------------------------------------------------------
export function triggerSibaQueue(reservationID, data) {
  const ACTION_NAME = "TRIGGER_QUEUE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`api/frontdesk/siba/queue/trigger/${reservationID}`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// DELETE SIBA QUEUE
//----------------------------------------------------------------
export function deleteSibaQueue(queueID) {
  const ACTION_NAME = "DELETE_QUEUE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/siba/queue/${queueID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {_id: data} });
        return successResult
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD SIBA FAILED
//----------------------------------------------------------------
export function loadSibaFailed(propertyID) {
  const ACTION_NAME = "LOAD_FAILED";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/siba/failed/${propertyID}`)
      .then(successResult => {
        const data = successResult.data
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}