import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import Footer from '../Footer';


const styles = theme =>({
    root: {
    
    },
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px',
        width: '50%',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
        // marginBottom: '20px'
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
    },
    label: {
        fontWeight: 600,
        fontSize: '1.2rem',
        width: '100%',
    },
    selectFormControl: {
        width: '30%',
        boxSizing: 'border-box',
        marginTop: '10px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },
    infoText: {
        marginTop: '20px',
        fontSize: '1.2rem',
        fontWeight: 600,
        marginTop: '20px', marginBottom: '20px'
    },
    inputContainer: {
        marginBottom: '20px'
    }
    
});

const START_POINTS = {
    atProperty: 'atProperty',
    external: 'external'
}

const formHelper = {
    countryError: false,
    lineOneError: false,
    cityError: false,
    zipCodeError: false
}

class ActivityStartPoint extends Component{

    state = {
        lineOne: '',
        lineTwo: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        addressNotes: '',
        startPoint: START_POINTS.atProperty,
        ...formHelper
    }

    constructor(props){
        super(props);
        this.countries = this.handleGetCountries();
    }

    componentDidMount(){
        const { 
            productData: { 
                externalAddress, 
                atProperty = true,
                addressNotes = '' 
            } } = this.props;

        if(externalAddress){
            this.setState({ 
                lineOne: externalAddress.lineOne,
                lineTwo: externalAddress.lineTwo,
                city: externalAddress.city,
                state: externalAddress.state,
                zipCode: externalAddress.zipCode,
                country: externalAddress.country,
                startPoint: atProperty ? START_POINTS.atProperty : START_POINTS.external,
                addressNotes
            });
        }
    }

    onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ 
            [name]: value ,
            [`${name}Error`]: !value.trim()
        });
    }

    handleGetCountries = () => {
        const countries = i18nIsoCountries.getNames("en");
        let countryOptions = [];
        for (var key in countries) {
          if (countries.hasOwnProperty(key)) {
            countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
          }
        }
        return countryOptions;
    }

    validateForm = () =>{
        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        let formFields = [
            'lineOne',
            'city',
            'country',
            'zipCode'
        ];
        
        // Validating required feilds ........
        formFields.forEach(input =>{

            if(!this.state[input] || !this.state[input].trim()){
                formInputStatus[`${input}Error`] = true;
                validationSuccess = false;
            }

        });

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    submitHandler = () =>{
        const { 
            lineOne,
            lineTwo,
            city,
            state,
            country,
            zipCode,
            addressNotes,
            startPoint
        } = this.state;

        const { submitDraftHandler } = this.props;

        if(startPoint === START_POINTS.atProperty){
            const data = {
                externalAddress: {
                    city
                },
                addressNotes,
                atProperty: true
            }
            submitDraftHandler(data);
            return;
        }

        if(!this.validateForm()) return;
        const data = {
            externalAddress: {
                lineOne,
                lineTwo,
                city,
                state,
                country,
                zipCode,
            },
            addressNotes,
            atProperty: false
        }
        submitDraftHandler(data);
    }
    
    render(){
        const { classes, nextStepHandler, prevStepHandler, activeStepInfo, productData } = this.props;
        const { 
            lineOne,
            lineTwo,
            city,
            zipCode,
            country,
            startPoint,
            addressNotes,
            countryError,
            lineOneError,
            cityError,
            zipCodeError
        } = this.state;
        return(
            <>
                <div className={clsx("main-content", "hideScrollBar")}>
                    
                    <div className="main-content-title">Where should your guests meet?</div>

                    <div className="sub-title">
                        Tell guests exactly where to meet you at the start of the experience. Make sure the location is easy to find. The exact address won't be shared until the guest's reservation is confirmed.
                    </div>

                    <FormControl component="fieldset" className={classes.radioBtnContainer} >
                        <RadioGroup 
                            name="startPoint" 
                            value={startPoint}
                            onChange={this.onChangeHandler}
                        >
                            <FormControlLabel value={START_POINTS.atProperty} control={<Radio color="primary" />} label="At the hotel" />
                            <FormControlLabel value={START_POINTS.external} control={<Radio color="primary" />} label="External address" />
                        </RadioGroup>
                    </FormControl>
                    {startPoint === START_POINTS.atProperty && (
                        <div className={classes.infoText}>
                            {/* ------------------- CITY -------------------------- */}
                            <div className={classes.label}>City</div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <InputBase 
                                        id={START_POINTS.atProperty && 'city'}
                                        name={START_POINTS.atProperty && 'city'}
                                        className={classes.textInput}
                                        fullWidth
                                        type="text"
                                        value={city}
                                        onChange={this.onChangeHandler}
                                        error={cityError}
                                    />
                                </div>
                            
                                {cityError && (
                                    <FormHelperText className="errorMessage">
                                        City is required
                                    </FormHelperText>
                                )}
                            </div>
                        </div>
                    )}

                    {startPoint !== START_POINTS.atProperty && (
                        <>
                            <div className={classes.infoText }>Provide an address</div>

                            {/* -------------------COUNTRY/REGION-------------------------- */}
                            <div className={classes.label}>Country/Region</div>
                            <div className={classes.inputContainer}>
                                <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl}
                                >
                                    <Select
                                        native
                                        value={country}
                                        onChange={this.onChangeHandler}
                                        className={classes.selectInput}
                                        inputProps={{
                                            name: 'country'
                                        }}
                                        error={countryError}
                                    >
                                        <option aria-label="None" value="" />
                                        {this.countries.map((country, index) => (
                                            <option key={index} value={country.value} >{country.label}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                {countryError && (
                                    <FormHelperText className="errorMessage">
                                        Country is required
                                    </FormHelperText>
                                )}
                            </div>

                            {/* ------------------- STREET ADDRESS -------------------------- */}
                            <div className={classes.label}>Street address</div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <InputBase 
                                        id="lineOne"
                                        name="lineOne"
                                        className={classes.textInput}
                                        fullWidth
                                        type="text"
                                        value={lineOne}
                                        onChange={this.onChangeHandler}
                                        error={lineOneError}
                                    />
                                </div>
                                {lineOneError && (
                                    <FormHelperText className="errorMessage">
                                        Address is required
                                    </FormHelperText>
                                )}
                            </div>

                            {/* ------------------- APT,SUIT,BLGD -------------------------- */}
                            <div className={classes.label}>Apt,Suit,Bldg.(optional)</div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <InputBase 
                                        id="lineTwo"
                                        name="lineTwo"
                                        className={classes.textInput}
                                        fullWidth
                                        type="text"
                                        value={lineTwo}
                                        onChange={this.onChangeHandler}
                                        // error={true}
                                    />
                                </div>
                            </div>
                            
                            {/* ------------------- CITY -------------------------- */}
                            <div className={classes.label}>City</div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <InputBase 
                                        id={START_POINTS.external && "city"}
                                        name={START_POINTS.external && "city"}
                                        className={classes.textInput}
                                        fullWidth
                                        type="text"
                                        value={city}
                                        onChange={this.onChangeHandler}
                                        error={cityError}
                                    />
                                </div>
                            
                                {cityError && (
                                    <FormHelperText className="errorMessage">
                                        City is required
                                    </FormHelperText>
                                )}
                            </div>

                            {/* ------------------- ZIP CODE-------------------------- */}
                            <div className={classes.label}>Zip code</div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <InputBase 
                                        id="zipCode"
                                        name="zipCode"
                                        className={classes.textInput}
                                        fullWidth
                                        type="text"
                                        value={zipCode}
                                        onChange={this.onChangeHandler}
                                        error={zipCodeError}
                                    />
                                </div>
                                {zipCodeError && (
                                    <FormHelperText className="errorMessage">
                                        Zip code is required
                                    </FormHelperText>
                                )}
                            </div>
                        </>
                    )}

                    <div className={classes.infoText}>Tell guest how they can find you once they arrive</div>
                    <div className="sub-title">
                        If your meeting point is hard to find
                    </div>

                    <div className={classes.inputField}>
                        <InputBase 
                            id="addressNotes"
                            name="addressNotes"
                            className={classes.textInput}
                            fullWidth
                            type="text"
                            placeholder="Location Name"
                            value={addressNotes}
                            onChange={this.onChangeHandler}
                            // error={true}
                        />
                    </div>

                </div>
                <Footer
                    nextClickHandler = {this.submitHandler}
                    backClickHandler = {prevStepHandler}
                    activeStepInfo = {activeStepInfo}
                    productData = {productData}
                />
            </>
        )
    }
}

export default withStyles(styles)(ActivityStartPoint);