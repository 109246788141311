import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';
import ChannelStatusChangeConfirm from '../bookingCom/StatusChangeConfirm';
import { fetchDatePerTimezone } from '../../../utils/utility';

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 62,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
      marginLeft: 0,
      overflow: 'visible'
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(35px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#53998C',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 5,
        padding: '20px 30px',
        background: '#F8F9FB',
        height: 'calc(100% - 60px)',
        marginTop: 15,
    },
    title: {
        color: '#000000',
        fontSize: '3rem',
        fontWeight: 600,
    },
    row: {
        marginBottom: 30
    },
    lesserPadding: {
        marginBottom: 10,
    },
    extraPadding: {
        marginTop: 60,
    },
    pillContainer: {
        display: 'flex',
    },
    pill: {
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: 5,
        color: '#999999',
        // cursor: 'pointer',
        display: 'flex',
        fontSize: '1.1rem',
        padding: '5px 10px',
        marginRight: 10,
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            background: '#EEEEEE'
        }
    },
    refresh: {
        color: '#999999',
        fontSize: '1.8rem',
        marginRight: 10,
    },
}));

const Summary = (props) => {
    const classes = useStyles();
    const { channelStatus, timezone } = props;
    const [connectStatus, setConnectStatus] = React.useState(channelStatus == 'true' ? true : false);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    React.useEffect(() => {
        if (props.success) setOpenConfirm(false)
    }, [props.success])

    const changeStatusHandler = () => {
        setConnectStatus(!connectStatus)
        setOpenConfirm(true)
    }


    return (
        <div className={classes.root}>

            {channelStatus &&
                <div className={classes.row}>
                    <div className={classes.pillContainer}>
                        <div className={clsx(classes.pill)}>
                            Last Sync {fetchDatePerTimezone(null, timezone).format('DD, MMM')}
                        </div>
                    </div>
                </div>
            }

            <div className={classes.lesserPadding}>
                <div className={classes.title}>Google Hotels</div>
            </div>

            {channelStatus && <div className={classes.row}>
                <IOSSwitch checked={connectStatus} onChange={changeStatusHandler} name="checked" />
            </div>}


            {openConfirm &&
                <ChannelStatusChangeConfirm
                    title={channelStatus == 'false' ? 'Activate channel' : 'Deactivate channel'}
                    type={channelStatus == 'false' ? 'activate' : 'deactivate'}
                    closeModalHandler={() => {
                        setConnectStatus(!connectStatus);
                        setOpenConfirm(false)
                    }}
                    loading={props.loading}
                    submitHandler={props.submitChangeStatusHandler}
                />
            }
        </div>
    )

}


export default Summary;