import moment from 'moment';
import {
    fetchNoOfDays,
    checkDateInBetween,
    fetchDateFormat,
    DATE_FORMATS,
    checkDateIfGreater,
    getMomentAddDayISO
} from "../../utils/utility";

export const fetchBookingDuration = (calendarData, reservation) =>{
    const { startDate, endDate, numberOfDays } = calendarData;
    const { checkIn, checkOut } = reservation;
    
    let duration = 0;
    // If accommodation checkin & checkout in range
    if ( checkDateInBetween(startDate, endDate, checkIn) &&
        checkDateInBetween(startDate, endDate, checkOut)){

        duration = fetchNoOfDays(checkIn, checkOut);
        return duration;
    }
    
    // If accommodation checkin in range but not checkout
    if ( checkDateInBetween(startDate, endDate, checkIn) && 
        !checkDateInBetween(startDate, endDate, checkOut)){

        duration = fetchNoOfDays(checkIn, endDate);
        return duration;
    }

   // If accommodation checkout in range but not checkin
    if ( !checkDateInBetween(startDate, endDate, checkIn) && 
        checkDateInBetween(startDate, endDate, checkOut)){

        duration = fetchNoOfDays(startDate, checkOut);
        return duration;
   }

   // If accommodation checkin & checkout envelops range
   if ( checkDateInBetween(checkIn, checkOut, startDate) &&  
        checkDateInBetween(checkIn, checkOut, endDate)){
            
        duration = numberOfDays-1;
        return duration;
    }

    return duration;
}

export const isPreCheckedIn = (calendarData, reservation) =>{
    const { startDate, endDate } = calendarData;
    const { checkIn, checkOut } = reservation;
    // check if guest checkedin before checkin date....
    if (!checkDateInBetween(startDate, endDate, checkIn) && 
        // checkDateInBetween(startDate, endDate, checkOut) &&
        checkDateIfGreater(startDate, checkIn)){
        return true;
    }
    return false;
}

export const isStartDateEqualToCheckout = (startDate, checkOut) =>{
    if (startDate.isSame(moment(checkOut), 'date'))  return true;
    return false;
}

export const checkDateInReservation = (date, checkinDate, checkoutDate) =>{
    if(((fetchDateFormat(date, DATE_FORMATS.BASIC_DATE) === fetchDateFormat(checkinDate, DATE_FORMATS.BASIC_DATE)) ||
        checkDateInBetween(checkinDate, checkoutDate, date))){
            return true;
    }
    return false;
}

export const calculateBookingDates = (date, duration) =>{
    const checkoutDate = getMomentAddDayISO(date, duration);
    return {
        selectedCheckIn: date,
        selectedCheckOut: checkoutDate
    }
}