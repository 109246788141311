import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';

import FileListSnapshot from '../files/FileListSnapshot';
import FolderItems from "../files/FolderItems";
import { setCurrentFolder } from "../../redux/actions/dashboard/dashboard";

import { createNewFolder } from '../../redux/actions/files/folders';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,
      marginTop: 20,
    }
  },
  container: {
    width: '100%',
    height: '100%',
    paddingRight: 17,
    boxSizing: 'content-box'
  },
  card: {
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    marginTop: 0,
    height: 250,
    overflowY: "hidden"
  },
  cardAction: {
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: 'center',
  },
  addIcon: {
    fontSize: '5rem',
    marginBottom: 10,
  },
  dialog: {
    padding: theme.spacing(3)
  },
  title: {
    '& h2': {
      color: '#000000',
      fontSize: '1.8rem',
      fontWeight: 600,
    }
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%'
  },
  submit: {
    padding: theme.spacing(3)
  },
  cancelButton: {
    color: '#000000',
    '&:hover': {
      background: '#FFFFFF',
    }
  },
  submitButton: {
    background: '#000000',
    borderRadius: 25,
    boxShadow: 'none',
    fontSize: '1.4rem',
    fontWeight: 600,
    '&:hover': {
      background: '#000000',
    }
  }
}))

const FilesPage = (props) => {
  const { t } = props
  const classes = useStyles();
  const { folders, currentSpace} = props;
  const [newFolder, setNewFolder] = React.useState(false);
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleOpen = (id) => {
    props.dispatch(setCurrentFolder(id));
  }

  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value !== '') {
      setError(false);
    }
  }

  const handleCreateList = () => {
    if (name!=='') {
    props.dispatch(createNewFolder(currentSpace._id, name));
    setNewFolder(false);
    } else setError(true)
  }
 
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          {currentSpace && currentSpace.folders && currentSpace.folders.map(item => 
            <Grid item xs={12} sm={4} md={4} xl={3} key={item}>
              <FileListSnapshot folderID={item} folderName={folders[item] && folders[item].name} handleOpen={() => handleOpen(item)} />
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={4} xl={3}>
            <Card className={classes.card}>
              <CardActionArea className={classes.cardAction} onClick={() => setNewFolder(true)}>
                <CardContent className={classes.cardContent}>
                  <AddIcon className={classes.addIcon} />
                  {t('files.addFolder')}
                </CardContent>            
              </CardActionArea>
            </Card>
          </Grid>

          <FolderItems />

          {newFolder && 
            <Dialog
              open={newFolder}
              onClose={() => setNewFolder(false)}
              className={classes.dialog}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle id="form-dialog-title" className={classes.title}>
                {t('files.addFolder')}
              </DialogTitle>
              <DialogContent>
                <div className={classes.field} style={{border: error ? '1px solid red' : '1px solid #ffffff'}}>
                  <InputBase
                    id="name"
                    name="name"
                    placeholder={t('files.folderName')}
                    fullWidth
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </DialogContent>
              <DialogActions className={classes.submit}>
                <Button className={classes.cancelButton} onClick={() => setNewFolder(false)} color="primary">
                  {t('actions.cancel')}
                </Button>
                <Button className={classes.submitButton} variant="contained" onClick={handleCreateList} color="primary">
                  {t('actions.submit')}
                </Button>
              </DialogActions> 
            </Dialog>
          }

        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  folders: state.folders
})

export default withTranslation()(connect(mapStateToProps)(FilesPage));