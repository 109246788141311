import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Toggle from '../../../common/Toggle'

import Tooltip from '@material-ui/core/Tooltip';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
  field: {
    alignItems: 'flex-start',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    flexWrap: 'wrap',
    height: 150,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%'
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  toggleWidth: {
    width: '200px',
    marginTop: 10
  }
}))

const Payments = props => {
  const classes = useStyles()
  const {
    settings,
    updateSettings,
    t
  } = props

  const { policy = {} } = settings
  const { paymentMethod = {} } = policy

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.payments.title')}</div>
        <div className='sub-title'>{t('microsite.payments.description')}</div>

        <div className='row'>
          <div className='label'>
            {t('microsite.payments.creditCards')}
            <Tooltip title={t('microsite.payments.creditCardsHelper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <Toggle
            className={classes.toggleWidth}
            toggle={paymentMethod.creditCard}
            onText='Enabled'
            offText='Disabled'
            handleOn={() => updateSettings('policy.paymentMethod.creditCard', true)}
            handleOff={() => updateSettings('policy.paymentMethod.creditCard', false)}
          />
        </div>

        <div className='row'>
          <div className='label'>
            {t('microsite.payments.debitCards')}
            <Tooltip title={t('microsite.payments.debitCardsHelper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <Toggle
            className={classes.toggleWidth}
            toggle={paymentMethod.debitCard}
            onText='Enabled'
            offText='Disabled'
            handleOn={() => updateSettings('policy.paymentMethod.debitCard', true)}
            handleOff={() => updateSettings('policy.paymentMethod.debitCard', false)}
          />
        </div>

        <div className='row'>
          <div className='label'>
            {t('microsite.payments.paypal')}
            <Tooltip title={t('microsite.payments.paypalHelper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <Toggle
            className={classes.toggleWidth}
            toggle={paymentMethod.paypal}
            onText='Enabled'
            offText='Disabled'
            handleOn={() => updateSettings('policy.paymentMethod.paypal', true)}
            handleOff={() => updateSettings('policy.paymentMethod.paypal', false)}
          />
        </div>

        <div className='row'>
          <div className='label'>
            {t('microsite.payments.alipay')}
            <Tooltip title={t('microsite.payments.alipayHelper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <Toggle
            className={classes.toggleWidth}
            toggle={paymentMethod.alipay}
            onText='Enabled'
            offText='Disabled'
            handleOn={() => updateSettings('policy.paymentMethod.alipay', true)}
            handleOff={() => updateSettings('policy.paymentMethod.alipay', false)}
          />
        </div>

      </div>
    </>
  )
}

export default withTranslation()(Payments)
