import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

}));

const ActivityLogTab = props => {
  const classes = useStyles();
  
  return (
    <div></div>
  )
}

const mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(ActivityLogTab);