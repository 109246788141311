import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CalendarColors } from '../calendar_color_config/constant';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px 20px',
        overflow: 'hidden'
    },
    legendItem: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.2rem',
        marginRight: 10,
        '& .text':{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '120px',
            whiteSpace: 'nowrap',
        }
    },
    lengend: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        marginRight: 7,
    },
    inHouse: {
        background: '#f0f2ff',
    },
    checkedOut: {
        background: '#e5f0ec',
    },
    confirmed: {
        background: '#fdf3da',
    },
}));

const CalendarLengends = props => {
    const { colorConfigInfo, uiSettings } = props;
    const classes = useStyles();
    const configOption =  uiSettings && uiSettings.calendarColour || CalendarColors.default.id;
    const groupTypeData = configOption && colorConfigInfo[configOption] || CalendarColors[configOption];
    return (
        <div className={classes.root}>
            
            {groupTypeData.colorInfo &&
            Object.keys(groupTypeData.colorInfo).map(infoID =>{
                
                const colorInfo = groupTypeData.colorInfo[infoID];
                
                return(
                    <div className={classes.legendItem} key={`legend_${infoID}`}>
                        <div 
                            className={classes.lengend} 
                            style={{ background: colorInfo.color }}
                        />
                        <div className="text">{colorInfo.title}</div>
                    </div>
                )
            })}
            
        </div>
    )
}

const mapStateToProps = state =>{
    const { userUIsettings, spaces,  dashboard } = state;
    const currentSpace = spaces[dashboard.currentSpace];
    return{
        uiSettings: userUIsettings[currentSpace._id]
    }
}

export default connect(mapStateToProps)(CalendarLengends);