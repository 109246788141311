import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: '0 2px 20px #F2F2F2',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 40,
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: 'center',
        zIndex: '105'
    },
    header: {
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#000000',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 20,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
            top: '20px',
            right: '10px'
        }
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '#F2F2F2',
        zIndex: 999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%'
    },
    dialogContent: {
        minHeight: "calc(100vh - 350px)",
        overflowY: "auto",
        padding: "30px 40px",
        paddingBottom: "0px",
        boxSizing: "border-box",
        [theme.breakpoints.down('xs')]: {
            padding: "30px 10px",
        }
    },
    formControl: {
        marginBottom: '25px',
        flex: 1,
        '& .label': {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#666666",
            marginBottom: "8px",
        },
        
        '& .textInput': {
            width: "100%",
    
            '& .MuiOutlinedInput-root':{
                borderRadius: '8px',
                overflow: 'hidden'
            },
    
            '& .MuiOutlinedInput-input':{
                padding: "12px 10px",
            },
    
            '& .MuiOutlinedInput-notchedOutline':{
                borderColor: "#e0e0e0",
            },
    
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#e0e0e0",
            },
    
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: "#f44336",
            },

            '& .MuiInputBase-input.Mui-disabled':{
                backgroundColor: "#ededed",
            }
        },
    },
    inlineForm: {
        display: 'flex',
        marginBottom: "10px",
        "& .textInput:first-child, $formControl:first-child": {
            marginRight: '15px'
        }
    },
    mb: {
        marginBottom: '10px'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: '20px 0px'
    },
    button: {
        background: 'rgba(18,64,141,255)',
        borderRadius: 5,
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        fontWeight: 600,
        padding: '12px 16px',
        '&:hover': {
            background: 'rgba(18,64,141,255)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
}));

const FORM_FIELDS = {
    name: '',
    address: {
        lineOne: '', 
        lineTwo: '', 
        city: '', 
        state: '', 
        zipCode: '',
        country: ''
    },
    phone: '',
    lat: '',
    long: ''
}

const INIT_FIELD_STATUS = {
    name: false,
    address: {
        lineOne: false, 
        lineTwo: false, 
        city: false, 
        state: false, 
        zipCode: false,
        country: false
    },
    phone: false,
    lat: false,
    long: false
}

const processFieldsInfo = (data, fields) =>{
    let fieldsStatus = {};
    let fieldsData = {};
    
    fields.forEach(field =>{
        const fieldData = data?.[field] ? data[field].trim() : ''
        fieldsStatus[field] = Boolean(fieldData);
        fieldsData[field] = fieldData;
    });

    return { fieldsStatus, fieldsData };
}

const SetupModal = props =>{
    const classes = useStyles();
    const { currentSpace, property } = props;
    const [formData, setFormData] = useState({ ...FORM_FIELDS });
    const [disabledFields, setDisabledFields] = useState({ ...INIT_FIELD_STATUS });
    const [errors, setErrors] = useState({ ...INIT_FIELD_STATUS });

    useEffect(() =>{
        const { address, ...generalFields } = FORM_FIELDS;

        const formFields = processFieldsInfo(currentSpace, Object.keys(generalFields));
        const addressFields = processFieldsInfo(property.address,  Object.keys(address));

        setDisabledFields({ 
            ...formFields.fieldsStatus, 
            address: { ...addressFields.fieldsStatus } 
        });

        setFormData({
            ...formFields.fieldsData, 
            address: { ...addressFields.fieldsData } 
        });

    }, []);

    const handleClose = () => {
        props.closeHandler();
    }

    const onChangeHandler = (event) =>{
        const name = event.target.name;
        const value = event.target.value;

        if(disabledFields[name]) return;

        setFormData({ ...formData, [name]: value });
    }

    const onAddrChangeHandler = (event) =>{
        const name = event.target.name;
        const value = event.target.value;

        if(disabledFields.address[name]) return;

        setFormData({ 
            ...formData, 
            address: { 
                ...formData.address, 
                [name]: value 
            } 
        });
    }

    const validateForm = () =>{
        let hasErrors = false;
        const { address, ...generalFields } = FORM_FIELDS;
        let { address: { ...addressErrors }, ...generalErrors } = INIT_FIELD_STATUS;

        Object.keys(address).forEach(field =>{
            if(!formData.address[field] || !formData.address[field].trim()){
                addressErrors[field] = true;
                hasErrors = true;
                return;
            }
        });

        Object.keys(generalFields).forEach(field =>{
            if(!formData[field] || !formData[field].trim()){
                generalErrors[field] = true;
                hasErrors = true;
                return;
            }
        });

        setErrors({ ...generalErrors, address: { ...addressErrors }});
        return !hasErrors;
    }

    const submitHandler = () =>{
        validateForm();
    }

    return (
        <Dialog
            open={true}
            maxWidth='sm'
            fullWidth
            fullScreen={window.innerWidth < 901}
        >
            <div className={classes.dialogTitle}>
                <div className={classes.header}>Stepup</div>
                <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            </div>

            <div className={classes.dialogContent}>
                <div>
                    {/* ------------NAME INPUT------------------ */}
                    <div className={classes.formControl}>
                        <div className="label">Property Name</div>
                        <TextField 
                            name="name"
                            variant="outlined" 
                            className="textInput"
                            defaultValue={formData.name}
                            disabled={disabledFields.name}
                            error={errors.name}
                            onChange={onChangeHandler}
                        />
                    </div>

                    {/* ------------ADDRESS INPUT------------------ */}
                    <div className={classes.formControl}>
                        <div className="label">address</div>
                        <TextField 
                            name="lineOne"
                            variant="outlined" 
                            className={clsx("textInput", classes.mb)}
                            placeholder="Address Line 1"
                            defaultValue={formData.address.lineOne}
                            disabled={disabledFields.address.lineOne}
                            error={errors.address.lineOne}
                            onChange={onAddrChangeHandler}
                        />
                        <TextField 
                            name="lineTwo"
                            variant="outlined" 
                            className={clsx("textInput", classes.mb)}
                            placeholder="Address Line 2"
                            defaultValue={formData.address.lineTwo}
                            disabled={disabledFields.address.lineTwo}
                            error={errors.address.lineTwo}
                            onChange={onAddrChangeHandler}
                        />
                        <div className={classes.inlineForm}>
                            <TextField 
                                name="city"
                                variant="outlined" 
                                className="textInput"
                                placeholder="City"
                                defaultValue={formData.address.city}
                                disabled={disabledFields.address.city}
                                error={errors.address.city}
                                onChange={onAddrChangeHandler}
                            />
                            <TextField 
                                name="state"
                                variant="outlined" 
                                className="textInput"
                                placeholder="State"
                                defaultValue={formData.address.state}
                                disabled={disabledFields.address.state}
                                error={errors.address.state}
                                onChange={onAddrChangeHandler}
                            />
                        </div>
                        <div className={classes.inlineForm}>
                            <TextField 
                                name="zipCode"
                                variant="outlined" 
                                className="textInput"
                                placeholder="Zip Code"
                                defaultValue={formData.address.zipCode}
                                disabled={disabledFields.address.zipCode}
                                error={errors.address.zipCode}
                                onChange={onAddrChangeHandler}
                            />
                            <TextField 
                                name="country"
                                variant="outlined" 
                                className="textInput"
                                defaultValue={formData.address.country}
                                disabled={disabledFields.address.country}
                                error={errors.address.country}
                                onChange={onAddrChangeHandler}
                            />
                        </div>
                    </div>

                    {/* ------------PHONE INPUT------------------ */}
                    <div className={classes.formControl}>
                        <div className="label">phone</div>
                        <TextField 
                            name="phone"
                            variant="outlined" 
                            className="textInput"
                            defaultValue={formData.phone}
                            disabled={disabledFields.phone}
                            error={errors.phone}
                            onChange={onChangeHandler}
                        />
                    </div>
                    
                    <div className={classes.inlineForm}>
                        {/* ------------LATITUDE INPUT------------------ */}
                        <div className={classes.formControl}>
                            <div className="label">Latitude</div>
                            <TextField 
                                name="lat"
                                variant="outlined" 
                                className="textInput"
                                defaultValue={formData.lat}
                                disabled={disabledFields.lat}
                                error={errors.lat}
                                onChange={onChangeHandler}
                            />
                        </div>

                        {/* ------------LONGITUDE INPUT------------------ */}
                        <div className={classes.formControl}>
                            <div className="label">Longitude</div>
                            <TextField 
                                name="long"
                                variant="outlined" 
                                className="textInput"
                                defaultValue={formData.long}
                                error={errors.long}
                                disabled={disabledFields.long}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                    
                </div>
                <div className={classes.btnContainer}>
                    <Button 
                        className={classes.button} 
                        onClick={submitHandler}
                    >
                        Connect
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { spaces, dashboard, property } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        property,
    }
}

export default connect(mapStateToProps)(SetupModal);