import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '20px',
        backgroundColor: '#F8F9FB'
    },
    header: {
        width: '100%',
        display: 'flex',
        fontWeight: 500,
        '& div':{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        }
    },
    rows: {
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        '& div':{
            flex: 1,
            display: 'flex',
            padding: '16px',
            alignItems: 'center',
        }
    },
    tableContainer:{
        width: '100%',
    },
    tableHeader: {
        borderBottom: '1px solid #e3e3e3',
        boxSizing: 'border-box',
        fontWeight: 500,
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
    }
}));


const TaxTable = props => {
    const classes = useStyles();
    const { taxesApplied, id, handleTableRowClick } = props;

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <div className={clsx(classes.tableHeader, classes.rows)}>
                    <div>Tax Name</div>
                    <div>Tax Mode</div>
                    <div>Tax Code</div>
                    <div>Amount</div>
                </div>
                
                {taxesApplied.map(tax =>(
                    <div 
                        key={`${id}_${tax.taxID}`}
                        className={clsx(classes.rows, 'tableRows')}
                        //onClick={() => handleTableRowClick(transaction.reservationID)}
                    >
                        <div>{tax.taxName}</div>
                        <div>{tax.taxType}</div>
                        <div>{tax.taxCode}</div>
                        <div>{tax.amount}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TaxTable;