import React from "react";
import { connect } from "react-redux";
import { initials, capitalizeFirst } from "../../utils/wordUtils";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  roomTypeContainer: {
    background: "#ffffff",
    borderRadius: "15px",
    padding: theme.spacing(2),
  },
  image: {
    borderRadius: 10,
    height: 50,
    objectFit: "cover",
    width: 50,
  },
  descContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.3rem",
    marginBottom: 8,
  },
  subTitle: {
    color: "#666666",
    fontSize: "1.1rem",
  },
}));

const userTypesArray = [
  { value: "team", label: "Level 1 - Team Member" },
  { value: "manager", label: "Level 2 - Reservations Manager" },
  { value: "finance", label: "Level 3 - Finance Manager" },
  { value: "property", label: "Level 4 - Property Manager" },
  { value: "admin", label: "Level 5 - Administrator" },
];

const TeamList = (props) => {
  const classes = useStyles();
  const { users, currentUser } = props;

  return (
    <Grid container spacing={3} className={classes.root}>
      {users &&
        Object.keys(users)
          .filter((e) => e !== currentUser._id)
          .map((userID) => (
            <Grid item xs={12} sm={6} key={userID}>
              <Grid
                container
                justifyContent="center"
                className={classes.roomTypeContainer}
              >
                <Grid item xs={3} lg={2} align="center">
                  <Avatar
                    alt={users[userID] && initials(users[userID].name)}
                    src={users[userID] && users[userID].avatar}
                    className={classes.bubble}
                    title={users[userID] && users[userID].name}
                  >
                    {users[userID] && initials(users[userID].name)}
                  </Avatar>
                </Grid>
                <Grid item xs={9} lg={10}>
                  <div className={classes.descContainer}>
                    <div className={classes.label}>{users[userID].name}</div>
                    <div className={classes.subTitle}>
                      {userTypesArray.find(
                        (e) => e.value === users[userID].role
                      ) &&
                        userTypesArray.find(
                          (e) => e.value === users[userID].role
                        ).label}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ))}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  users: state.users,
});

export default connect(mapStateToProps)(TeamList);
