// UI Config Reducer Defaults
const uiConfigReducerDefaultState = { 
    selected_hostel_id: "", 
    hostel_dialog_id: "", 
    display_login: false, 
    display_signup: false,
    display_new_post_dialog: false,
    sales_drawer: false,
    signup: 1
}

export default (state = uiConfigReducerDefaultState, action) => {
    switch(action.type) {
        case 'SELECT_HOSTEL':
            return {
                ...state,
                selected_hostel_id: action.selected_hostel_id
            }
        case 'HOSTEL_DIALOG':
            return {
                ...state,
                dialog_hostel_id: action.dialog_hostel_id
            }
        case 'DISPLAY_LOGIN':
            return {
                ...state,
                display_login: action.display_login
            }
        case 'DISPLAY_SIGNUP':
                return {
                    ...state,
                    display_signup: action.display_signup
                }
        case 'SALES_DRAWER':
            return {
                ...state,
                sales_drawer: action.sales_drawer
            }
        case 'DISPLAY_NEW_POST_DIALOG':
            return {
                ...state,
                display_new_post_dialog: action.display_new_post_dialog
            }
        case 'SET_SIGNUP_STEP':
            return {
                ...state,
                signup: action.step
            }
        case "USER_LOGOUT":
            return {
                ...uiConfigReducerDefaultState
            }
        case "SET_ONBOARDING":
            return {
                ...state,
                onboarding: action.onboarding
            }
        default:
            return state;
    }
};