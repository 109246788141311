import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import { SORT_OPTIONS } from './constants';
import { isDateBefore } from '../../utils/utility';
import { alphaNumericSort } from '../utils/wordUtils';

// const processBuildingData = (rooms, filters = {}) =>{
//     const roomsData = _.cloneDeep(rooms);
//     const buildingData = _.chain(roomsData)
//     // .filter(filters)
//     .sortBy(['buildingID', 'floor'])
//     .groupBy('buildingID')
//     .mapValues(roomsByBuilding => _.groupBy(roomsByBuilding, 'floor'))
//     .value();
//     return buildingData;
// }

function getRoomsInfo(roomIDs, roomsData){
    const roomsInfo = [];

    for(const roomID of roomIDs){
        //Check for deleted rooms.................
        if(!roomsData[roomID].isActive) continue;
        roomsInfo.push({ ...roomsData[roomID] });
    }

    return roomsInfo;
}

function getMappingsRoomTypeInfo(roomsData, roomTypes) {
    let roomTypeList = new Map();

    for(const mappedRoomType of roomsData?.mapping){

        //Check if roomtype has selected rateplan applied........
        // if(selectedRatePlan && !selectedRatePlan.roomTypeIDs.includes(mappedRoomType._id)) continue;

        //Check for deleted and visible = false roomTypes.................
        if(!mappedRoomType.isActive || !mappedRoomType.visible) continue;

        const roomTypeData = { ...roomTypes[mappedRoomType.roomTypeID] };

        if(!roomTypeData.isActive) continue;

        roomTypeList.set(roomTypeData._id, { ...roomTypeData });
    }

    return [...roomTypeList.values()];
}

function getRatePlanOptions(ratePlans) {
    const ratePlanOptions = [];
    let defaultRatePlanID = null;

    for(const [ratePlanID, ratePlan] of Object.entries(ratePlans)){
        if(ratePlan.isDeleted) continue;

        if(ratePlan.isDefault){
            defaultRatePlanID = ratePlanID;
        }

        ratePlanOptions.push({ 
            value: ratePlanID,
            label: ratePlan.privateName,
        });
    }
    return { defaultRatePlanID, ratePlanOptions };
}

function getRoomTypeInfo(roomType){  
    if(!roomType) return {};
    return {
        _id: roomType._id,
        name: roomType.name,
        isPrivate: roomType.dormOrPrivate == 'private',
        bedsPerRoom: roomType.bedsPerRoom
    }
}

function getBuildingOptions(buildings){
    const buildingOptions = [];

    for (const [buildingID, building] of Object.entries(buildings)) {

        buildingOptions.push({
            value: buildingID,
            label: building.name,
            floors: building.floors
        });
    }

    return buildingOptions;
}

function isBeforeTodaysDate(date, timezone) {
    const todaysDate = moment.tz(new Date(), timezone).format('YYYY-MM-DD')
    return date.isBefore(todaysDate);
}

function searchFilter(data) {
    this.roomArray = data;

    this.building = function (buildingID){
        if(!buildingID) return this;
        this.roomArray = this.roomArray.filter(item => item.buildingID == buildingID);
        return this;
    }

    this.floor = function (floor){
        if(!floor) return this;
        this.roomArray = this.roomArray.filter(item => item.floor == floor);
        return this;
    }

    this.sort = function (type){
        if(!type) return this;
        this.roomArray.sort((a,b) =>{
            const roomA = a.name.toLocaleLowerCase();
            const roomB = b.name.toLocaleLowerCase();

            const sortVal = {
                [SORT_OPTIONS[0].value]: { value1: roomA, value2: roomB },
                [SORT_OPTIONS[1].value]: { value1: roomB, value2: roomA }
            }[type];

            return alphaNumericSort(sortVal.value1, sortVal.value2);
        });
        return this;
    }

    this.result = function () {
        return this.roomArray;
    }
}

function applySearchFilter(filters, roomsListData){

    if(roomsListData.length == 0) return [];

    const roomsData = cloneDeep(roomsListData);
    const filterObj = new searchFilter(roomsData);
    const filteredData = filterObj.building(filters.buildingID)
        .floor(filters.floor)
        .sort(filters.sort)
        .result();

    return filteredData;
}

function getSumOfRates(ratesInfo, roomTypeID, ratePlanID){
    const sumOfRates = ratesInfo?.[roomTypeID]?.rates?.[ratePlanID];
    if(sumOfRates !== 0 && (!sumOfRates || sumOfRates == 'NA')) return 'NA';
    return sumOfRates;
}

function getPerRoomBedCost(data){
    const { roomTypeID, ratePlanID, ratesInfo, duration } = data;

    const sumOfRates = getSumOfRates(ratesInfo, roomTypeID, ratePlanID);

    if(sumOfRates == 'NA') return sumOfRates;

    const perRoomBedCost = (sumOfRates / duration).toFixed(2);
    return perRoomBedCost;
}

function isBedPreOccupied(preOccupiedBeds, bedID){
    return preOccupiedBeds?.[bedID] || false;
}

function isBedAvailable(availableBeds, bedID){ 
    return availableBeds?.[bedID] || false;
}

function processBookingStats(bedsInfoList){
    let stats = {
        guestCount: 0,
        privateRoomCount: 0,
        dormBedsCount: 0
    };

    for(const [, bedInfo] of Object.entries(bedsInfoList)){

        if(bedInfo.isPrivateRoom){
            stats.privateRoomCount += 1;
            stats.guestCount += bedInfo.bedsPerRoom;
            continue;
        }
        const currentBedCount = bedInfo.bedIDs.length;
        stats.dormBedsCount += currentBedCount;
        stats.guestCount += currentBedCount;
    }

    return stats;
}

function validateReviewBooking(bedsInfoList, ratesInfo, ratePlanID){

    const success = Object.entries(bedsInfoList).every(([, bedInfo]) =>{
        return getSumOfRates(ratesInfo, bedInfo.roomTypeID, ratePlanID) !== 'NA'
    });

    return success;
}

function validatePreBooking(bedsInfoList, preOccupiedBeds){

    const success = Object.entries(bedsInfoList).every(([, bedInfo]) =>{
        const status = bedInfo.bedIDs.every(bedID => !isBedPreOccupied(preOccupiedBeds, bedID));
        return status;
    });

    return success;
}

function getTodaysDate(timezone){
    return moment.tz(new Date(), timezone).format('YYYY-MM-DD');
}

function validateDocExpiry(docExpiryDate, timezone){
    const today = getTodaysDate(timezone);
    return isDateBefore(docExpiryDate, today) ? false : true;
}

function getAllSelectedBedIDs(bedsInfoList){
    let selectedBedIDs = [];
    for(const [, bedInfo] of Object.entries(bedsInfoList)){
        selectedBedIDs = [ ...selectedBedIDs, ...bedInfo.bedIDs ];
    }
    return selectedBedIDs;
}

export { 
    // processBuildingData, 
    getMappingsRoomTypeInfo, 
    getRoomsInfo, 
    getRatePlanOptions,
    getRoomTypeInfo,
    getBuildingOptions,
    isBeforeTodaysDate,
    applySearchFilter,
    getPerRoomBedCost,
    isBedPreOccupied,
    processBookingStats,
    getTodaysDate,
    validateDocExpiry,
    validateReviewBooking,
    validatePreBooking,
    isBedAvailable,
    getAllSelectedBedIDs
}