
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Payments from './Payments';
import { setUpgradeAccount } from '../../redux/actions/dashboard/dashboard';

import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import { getStripeAccountStatus, getStripeAccountLink, resetError } from '../../redux/actions/payments/payments';
import { getAutoPayInfo, updateAutoPayInfo } from '../../redux/actions/payments/autoPay';
import IMAGES from '../../constants/images';

const useStyles = makeStyles( theme => ({
  root: {
    width: '100%',
  },
  payments: {
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#ffffff'
    }
  },
  imageContainer: {
    alignItems: 'center',
    background: 'transparent',
    height: 130,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 90,
      width: 120
    },
  },
  snapshotHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 25,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& h4': {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  buttonContainer: {
    alignItems: 'center',
    background: '#C9ADA1',
    boxShadow: '0 1px 20px #F0F0F0',
    color: '#ffffff',
    fontSize: '1.4rem',
    fontWeight: 600,
    cursor: 'pointer',
    height: 50,
    display: 'flex',
    justifyContent: 'center'
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  infoItem: {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    marginLeft: 5,
    marginRight: 5,
    padding: 5,
    '&:hover': {
      background: '#eeeeee'
    }
  },
  icon: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  upgrade: {
    background: '#006699',
    borderRadius: 5,
    border: '1px solid #006699',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginLeft: 5,
    textAlign: 'center',
    padding: '5px 8px',
  }
}))

const PaymentsSnapshot = (props) => {
  const [open, setOpen] = React.useState(false);
  const [upgrade, setUpgrade] = React.useState(false);
  const [loadingStripe, setLoadingStripe] = React.useState(true);
  const [paymentStatus, setPaymentStatus] = React.useState({ activated: false, actionRequired: false});
  const classes = useStyles();
  const { currentSpace, dispatch, error, t } = props;

  const handleInfoClick = () => {
    window.open('https://support.counter.app/en/articles/4272725-payment-automation', '_blank');
  }

  const handleClickOpen = () => {
    if (currentSpace.onboardingStatus && (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') && paymentStatus.activated) {
      dispatch(getAutoPayInfo(currentSpace.propertyID))
      setOpen(true);
      window.history.replaceState({}, document.title, `/beds/automate/payments`);
      window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
    }
  } 

  const handleClose = () => {
    setOpen(false);
    window.history.replaceState({}, document.title, `/beds/automate`);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  useEffect(()=>{
    //Get Stripe Account Status.........
    dispatch(getStripeAccountStatus(currentSpace.propertyID)).then(response => {
      if(response){
        setPaymentStatus({
          activated: response.activated,
          actionRequired: response.action_required
        });
        setLoadingStripe(false);
      }
    });
  }, []);

  const fetchStripeAccountLink = () =>{
    dispatch(getStripeAccountLink(currentSpace.propertyID)).then(response => {
      if(response){
        window.location.href = response.url;
      }
    });
  }

  const handleCloseError = () =>{
    dispatch(resetError("GET_STRIPE_ACCOUNT_LINK"));
  }

  return (
    <div className={classes.root}>
      <div className={clsx({
        [classes.payments]: true, 
        [classes.hover]: currentSpace.onboardingStatus && (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') && paymentStatus.activated
        })} 
        onClick={handleClickOpen}
      >
        
        {/* HEADER */}
        {/* --------------------------------- */}
        <div className={classes.snapshotHeader}>
          <Typography component="h4" variant="h6">Payments</Typography>

          {/* UPGRADE TO PLUS */}
          {/* --------------------------------- */}
          {currentSpace.onboardingStatus != 'plus'  && currentSpace.onboardingStatus != 'pro' && (
            <div className={classes.upgrade} onClick={() => props.dispatch(setUpgradeAccount(true))} >Plus</div>
          )}

          {!loadingStripe && currentSpace.onboardingStatus && 
            (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') && 
            !paymentStatus.activated && (
              <div className={classes.upgrade} onClick={fetchStripeAccountLink}>
                {t('automate.payments.setup')}
              </div>
          )}
        </div>

        {/* ICON */}
        {/* --------------------------------- */}
        <div className={classes.imageContainer}>
          <img src={IMAGES.ILLUSTRATIONS.payments} />
        </div>

        {/* DEMO / VIDEO */}
        {/* --------------------------------- */}
        <div className={classes.infoContainer}>
          <div className={classes.infoItem} onClick={handleInfoClick}>
            <img className={classes.icon} src={IMAGES.ICONS.learnMore} />{t('automate.learnMore')}
          </div>
        </div>

      </div>

      {currentSpace.onboardingStatus && 
        (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') && 
        open && (
          <Payments 
            open={open}
            actionRequired={paymentStatus.actionRequired}
            fetchStripeAccountLink={fetchStripeAccountLink}
            handleClose={handleClose} 
          />
      )}

      {/* ------------- Snackbar error messages -------------- */}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error}</span>}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  error: state.errors.GET_STRIPE_ACCOUNT_LINK
  
})

export default withTranslation()(connect(mapStateToProps)(PaymentsSnapshot));



