import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setPropertySettings } from "../../redux/actions/microsite/hostelworld";
import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listSection: {
    padding: theme.spacing(3),
    paddingLeft: 40,
    paddingRight: 40,
  },
  box: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
    padding: theme.spacing(3),
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    marginBottom: 30,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  label: {
    alignItems: "center",
    color: "#484848",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 500,
    marginBottom: 5,
  },
  field: {
    alignItems: "flex-start",
    background: "#ffffff",
    borderRadius: "5px",
    boxShadow: "0 1px 10px #E0E0E0",
    display: "flex",
    flexWrap: "wrap",
    height: 32,
    marginTop: 10,
    paddingTop: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  submit: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  text: {
    color: "#484848",
    display: "flex",
    fontSize: "1.2rem",
    lineHeight: 1.4,
  },
  spacing: {
    marginTop: 10,
  },
  bold: {
    fontWeight: 600,
  },
  link: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontWeight: 600,
      marginLeft: 5,
    },
  },
  footer: {
    justifyContent: "center",
    marginTop: 50,
    width: "100%",
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
}));

const fieldList = [
  "web",
  "bookingEmail",
  "mainManagerEmail",
  "altManagerEmail1",
  "altManagerEmail2",
  "phone",
  "fax",
  "post",
  "mobileUrl",
];

const AccountSettings = (props) => {
  const classes = useStyles();
  const {
    currentSpace,
    loadingProperty,
    loadingSetProperty,
    propertySettings = {},
    dispatch,
    t,
  } = props;
  const {
    name,
    location = {},
    releasePeriod,
    minimumCancellationDays,
    buddy = {},
  } = propertySettings;

  const [settings, setSettings] = React.useState({});
  const [updatedSettings, setUpdatedSettings] = React.useState({});

  useEffect(() => setSettings(propertySettings), [propertySettings]);

  const handleOnChange = (a, event) => {
    setSettings({
      ...settings,
      [a]: event,
    });
    setUpdatedSettings({
      ...updatedSettings,
      [a]: event,
    });
  };

  const handleSubmitUpdates = () => {
    dispatch(
      setPropertySettings(currentSpace.propertyID, updatedSettings)
    ).then((result) => {
      console.log(result);
      setSettings(result);
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="md"
      fullWidth
      fullScreen={window.innerWidth < 901}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>
            {t("hostelworldChannel.propertySettings.title")}
          </div>
          <AccessHeaderAction moduleID={MODULE.HW_PROPERTY_SETTINGS.ID} />
          <CloseIcon
            className={classes.closeIcon}
            onClick={props.handleClose}
          />
        </div>

        <div className={classes.listContainer}>
          {loadingSetProperty && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}
          {loadingProperty && (
            <div className={classes.listSection}>
              {t("navbar.loadingProperty")}
            </div>
          )}

          {!loadingProperty && (
            <div className={classes.listSection}>
              {/* --------------------------------------------------------------------- */}
              {/* PROPERTY INFO BOX */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.box}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>
                    {t(
                      "hostelworldChannel.propertySettings.propertyInformation"
                    )}
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      <div className={clsx(classes.text, classes.bold)}>
                        {name}
                      </div>
                      {location.address1 && (
                        <div className={classes.text}>{location.address1}</div>
                      )}
                      {location.address2 && (
                        <div className={classes.text}>{location.address2}</div>
                      )}
                      {location.province && (
                        <div className={classes.text}>
                          {location.province.name}
                        </div>
                      )}
                      {location.city && (
                        <div className={classes.text}>{location.city.name}</div>
                      )}
                      {location.country && (
                        <div className={classes.text}>
                          {location.country.name}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <div>
                        <div className={clsx(classes.text, classes.bold)}>
                          Release Period
                        </div>
                        <div className={classes.text}>
                          {releasePeriod || 0} days(s)
                        </div>
                      </div>
                      <div className={classes.spacing}>
                        <div className={clsx(classes.text, classes.bold)}>
                          Cancellation Policy
                        </div>
                        <div className={classes.text}>
                          {minimumCancellationDays || 0} day(s)
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* --------------------------------------------------------------------- */}
              {/* CONTACT DETAILS BOX */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.box}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>
                    {t("hostelworldChannel.propertySettings.contactDetails")}
                  </div>
                </div>

                <Grid container spacing={5}>
                  {fieldList.map((item, index) => (
                    <Grid key={index} item xs={6} md={4}>
                      <div className={classes.label}>
                        {t(`hostelworldChannel.propertySettings.${item}`)}
                      </div>
                      <div className={classes.field}>
                        <InputBase
                          id={item}
                          name={item}
                          value={settings?.[item] || ""}
                          fullWidth
                          type="text"
                          onChange={(e) => handleOnChange(item, e.target.value)}
                        />
                      </div>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    {/* --------------------------------------------------------------------- */}
                    {/* SUBMIT / CANCEL UPDATES */}
                    {/* --------------------------------------------------------------------- */}
                    <div className={classes.submit}>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.button}
                        onClick={handleSubmitUpdates}
                      >
                        {t("actions.save")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* --------------------------------------------------------------------- */}
              {/* HOSTELWORLD BOX */}
              {/* --------------------------------------------------------------------- */}
              <div className={classes.box}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>
                    {t(
                      "hostelworldChannel.propertySettings.hostelworldDetails"
                    )}
                  </div>
                </div>
                <div className={classes.text}>
                  {t("hostelworldChannel.propertySettings.buddy")}
                </div>
                <div className={clsx(classes.text, classes.spacing)}>
                  <span>{buddy?.fullName} - </span>
                  <span className={classes.link}>
                    <a href={"mailto:" + buddy?.email}>{buddy?.email}</a>
                  </span>
                </div>
              </div>

              <div className={clsx(classes.text, classes.footer)}>
                <span>{t("hostelworldChannel.propertySettings.footer")}</span>
                <span className={classes.link}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.hostelworld.com/securityprivacy.php"
                  >
                    Data Protection Notice
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  loadingSetProperty: state.loading.SET_PROPERTY_SETTINGS,
  propertySettings: state.inbox.hostelworld.settings,
});

export default withTranslation()(connect(mapStateToProps)(AccountSettings));
