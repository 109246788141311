import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Box from "@material-ui/core/Box";
import { ReportProblem, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const backgroundColor = "#ff0f0f";
const textColor = "#ffffff";
const useStyles = makeStyles({
  root: {
    background: backgroundColor,
    width: "100%",
    position: "fixed",
    zIndex: 1111,
    top: 0,
    left: 0,
  },
  boxWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: textColor,
    fontWeight: 600,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: "4px",
    fontSize: 13,
  },
  subtext: {
    color: textColor,
    fontSize: 12,
    marginTop: "0.5em",
    marginBottom: "4px",
    "& a": {
      color: textColor,
      textDecoration: "underline",
      fontWeight: 600,
    },
  },
  icon: {
    margin: "10px 10px",
    cursor: "pointer",
    "& svg": {
      fontSize: "3em",
      color: textColor,
    },
  },
});

const AlertBanner = ({ banner, closeBanner, t }) => {
  const classes = useStyles();
  const endDate = "2025-03-28";
  const daysRemaining = moment(endDate).diff(moment(), "days");
  return (
    <>
      {banner ? (
        <div
          className={classes.root}
          style={{
            display: `${banner ? "block" : "hidden"}`,
          }}
        >
          <Box className={classes.boxWrapper}>
            <Box className={classes.icon}>
              <ReportProblem />
            </Box>
            <Box>
              <p
                className={classes.text}
                dangerouslySetInnerHTML={{
                  __html: t(banner.text, {
                    email: "",
                    noOfDays: daysRemaining,
                  }),
                }}
              ></p>
              <p
                className={classes.subtext}
                dangerouslySetInnerHTML={{
                  __html: t(banner.subtext, {
                    supportLink:
                      "https://global.hostelworld.com/discontinuation.en",
                    supportEmail: "support@hostelworld.com",
                  }),
                }}
              ></p>
            </Box>
            {closeBanner && (
              <Box className={classes.icon}>
                <Close onClick={() => closeBanner()} />
              </Box>
            )}
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default withTranslation()(AlertBanner);
