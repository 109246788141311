import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import CalendarRender from './CalendarRender';
import CalendarLegends from './calendar/CalendarLegends';
import SortOptions from './calendar/SortOptions';
import DatesHeader from './calendar/DatesHeader';

import {
    List,
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache
} from 'react-virtualized';

let cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 150
});

const useStyles = makeStyles(theme => ({
    dialog: {

    },
    calenderBlockContainer: {
        height: '100vh',
        '& div:focus': {
            outline: 'none'
        }
    },
    reservationLoader:{
        zIndex: 99,
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    calendarHeader: {
        boxShadow: '0 3px 4px 0 #ECECEC',
        paddingTop: '20px',
        top: 0
    },
    buttonHolder: {
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 10,
        height: 38,
    },
    button: {
        border: '0px solid #dddddd',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '2.2rem',
        marginRight: 10,
        padding: 8,
        '&:hover': {
            background: '#edf2f7',
            color: '#666666'
        }
    },
    calendarMenu: {
        position: 'relative',
        display: 'flex'
    },
    calendarDates: {
        position: 'relative',
        '& .date-section': {
            padding: '5px 0px',
            paddingRight: '15px',
            boxSizing: 'border-box',
        },
        '& .DateInput_input': {
            fontSize: '12px'
        },
        '& .datePicker-arrow-icon': {
            fontSize: '1.2rem'
        }
    },
}));

const CalendarModal = props => {
    const classes = useStyles();
    const {
        handleClose,
        calendarDisplayData,
        getCalendarRenderProps,
        day,
        checkIn,
        checkOut,
        setResetDate,
        handleCalendarFilter,
        handleOpenRoomBlock,
        setFullScreenMode,
        timezone,
        handleSelectedDate,
        handleCalendarDays,
        resetDate,
        numberOfDays,
        handleNewReservation,
        isLoading,
        colorConfigInfo,
        handleColorConfig
    } = props;

    const cellMeasurerRef = useRef()

    useEffect(()=>{
        //Clear calendar row cached height on re-render
        clearCalendarCache();
    },[calendarDisplayData]);

    const renderCalender = ({ index, parent, key, style }) => {

        const calendarData = calendarDisplayData[index];

        let calendarProps = getCalendarRenderProps();

        calendarProps = { ...calendarProps, numberOfDays, cellMeasurerRef }

        const filterType = calendarProps.filterType;

        return (
            <CellMeasurer
                ref={cellMeasurerRef}
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <Grid
                    container
                    key={`${filterType}_${index}`}
                    className={classes.roomTypesContainer}
                    style={style}
                >
                    <CalendarRender
                        type={filterType}
                        calendarData={calendarData}
                        calendarProps={calendarProps}
                    />
                </Grid>
            </CellMeasurer>
        );
    }

    const clearCalendarCache = () => {
        cache.clearAll();
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            className={classes.dialog}
            fullScreen={true}
        >

            <Grid container justifyContent="flex-end" className={classes.calendarHeader}>

                {/* -----------------Sort Options---------------*/}

                <Grid item xs={12} className={classes.calendarMenu}>
                    <SortOptions
                        day={day}
                        checkIn={checkIn}
                        setResetDate={setResetDate}
                        handleCalendarFilter={handleCalendarFilter}
                        handleOpenRoomBlock={handleOpenRoomBlock}
                        setFullScreenMode={setFullScreenMode}
                        handleNewReservation={handleNewReservation}
                        handleColorConfig={handleColorConfig}
                        fullScreenMode
                    />
                </Grid>


                {/* -----------------Dates Header---------------*/}

                <Grid item xs={9} sm={9} md={10} className={classes.calendarDates}>
                    <DatesHeader
                        checkIn={checkIn}
                        checkOut={checkOut}
                        resetDate={resetDate}
                        numberOfDays={numberOfDays}
                        timezone={timezone}
                        handleSelectedDate={handleSelectedDate}
                        handleCalendarDays={handleCalendarDays}
                        fullScreenMode
                    />

                    <div className={classes.buttonHolder}>
                        <CloseIcon className={classes.button} onClick={handleClose} />
                    </div>
                </Grid>
            </Grid>

            <Grid container className={classes.calenderBlockContainer}>
                
                {isLoading &&
                    <div className={classes.reservationLoader}>
                        <CircularProgress className={"loading"}/>
                    </div>
                }

                <AutoSizer>
                    {
                        ({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                width={width}
                                rowCount={calendarDisplayData.length}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={renderCalender}
                                overscanRowCount={1}
                            />
                        )}
                </AutoSizer>
            </Grid>

            <CalendarLegends colorConfigInfo={colorConfigInfo} />

        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { loading } = state;
    return{
        isLoading: loading.LOAD_RESERVATIONS || loading.GET_CALENDAR_RENDER_DATA,
    }
}

export default connect(mapStateToProps)(CalendarModal);