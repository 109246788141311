import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
// import { useMediaQuery } from 'react-responsive'
import http from "../../../redux/utils/http";
// import parse from 'html-react-parser';

import { toTitleCase } from "../../utils/wordUtils";
import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchDatePerTimezone,
  getAmtFormat,
} from "../../../utils/utility";
import {
  createReservationPayment,
  createTransactionMode,
  fetchChannexSessionToken,
  fetchCardDetails,
} from "../../../redux/actions/reservation/editReservation";
// import { returnCurrent } from '../../../redux/selectors/dashboard';

import AddCardModal from "./reservation-cards/AddCardModal";

import {
  Typography,
  Button,
  TextField,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
// import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import Select from "react-select";
import { getStripeAccountLink } from "../../../redux/actions/payments/payments";
import NumberInput from "../../common/NumberInput";
import AccessController from "../../permissions/AccessController";
import { MODULE } from "../../../common/constants/permission";
import usePermissions from "../../permissions/UsePermissions";
// import IMAGES from "../../../constants/images";
import { CHANNEL } from "../../../utils/channels";
import { getReservationCardTokens } from "../../../redux/actions/beds/beds";
import CardView from "./reservation-cards/CardView";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#F2F2F2",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: "relative",
    marginBottom: 20,
    marginTop: 30,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 22,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  header: {
    fontWeight: 600,
  },
  row: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 30,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  label: {
    color: "#999999",
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  note: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  transacionDate: {
    borderBottom: "1px solid red",
  },
  createdBy: {
    color: "#999999",
    fontWeight: 500,
  },
  datePicker: {
    background: "#f8f9fb",
    borderRadius: "5px",
    width: "100%",
    "& .SingleDatePicker": {
      padding: "5px 5px",
    },
    "& .DateInput_input": {
      background: "#f8f9fb",
      color: "rgba(0, 0, 0, 0.87)",
      paddingLeft: "10px",
      fontWeight: 500,
      width: "90px",
    },
  },
  defaultDate: {
    color: "#000000",
  },
  paymentMode: {
    display: "flex",
  },
  amount: {
    background: "#f8f9fb",
    borderRadius: 5,
    padding: "5px 15px",
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":
      {
        border: 0,
      },
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  tab: {
    alignItems: "center",
    // boxShadow: '0 1px 30px 0 #E0E0E0',
    background: "#f8f9fb",
    borderRadius: "5px",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 20,
    padding: 10,
    width: "100px",
  },
  lastTab: {
    marginRight: 0,
  },
  tabIcon: {
    height: 40,
    marginBottom: "5px !important",
    width: 40,
  },
  stripeIcon: {
    height: 40,
    marginBottom: "5px !important",
    width: 70,
  },
  selected: {
    background: "#000000",
    color: "#FFFFFF",
    fontWeight: 600,
  },
  radioContainer: {
    color: "#666666",
    marginBottom: 10,
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    "& span": {
      fontSize: "1.3rem",
      padding: 0,
      paddingRight: 5,
    },
  },
  link: {
    color: "#13408d",
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginBottom: 20,
  },
  transactionModeContainer: {
    alignItems: "flex-end",
    display: "flex",
  },
  transactionMode: {
    width: "80%",
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    background: "#FFFFFF",
    border: "1px solid #dddddd",
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  submitButton: {
    border: "0",
    backgroundColor: "#000000;",
    color: "#fff",
    fontWeight: 600,
    borderRadius: 5,
    padding: 15,
    width: "100%",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#fff",
    },
  },
  error: {
    fontSize: "1.2rem",
    color: "red",
    marginTop: 5,
    marginBottom: 5,
  },
  stripeCard: {
    minHeight: 140,
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    width: "100%",
    maxWidth: "300px",
  },
  cardSelectContainer: {
    marginBottom: 20,
    width: "100%",
  },
  cardSelectHeader: {
    display: "flex",
    justifyContent: "flex-end",
  },
  blankCard: {
    color: "#999999",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.2rem",
    textAlign: "center",
    width: "80%",
    margin: "0 auto",
    "& span": {
      marginBottom: 5,
    },
  },
  viewIcon: {
    cursor: "pointer",
    height: 20,
    width: 20,
  },
  addCard: {
    cursor: "pointer",
    color: "#484848",
    fontSize: "1.2rem",
    marginBottom: 5,
  },
  cardInfoContainer: {
    background: "#8688BC",
    borderRadius: 10,
    color: "#FFFFFF",
    padding: 20,
    width: "calc(100% - 40px)",
    maxWidth: "300px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      width: "calc(100% - 40px)",
    },
  },
  loadingCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 135,
    width: "100%",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  logoContainer: {
    display: "flex",
    fontWeight: 600,
    fontSize: "1.6rem",
  },
  cardNumber: {
    marginTop: "20px",
    marginBottom: 20,
    fontWeight: 600,
    fontSize: "1.4rem",
  },
  cardDigit: {
    marginRight: "10px",
  },
  cardRow: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 10,
  },
  cardSection: {
    display: "flex",
    flexDirection: "column",
  },
  cardLabel: {
    fontSize: "1.1rem",
    marginBottom: 3,
  },
  cardName: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  cardExpiry: {
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  footer: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  warningMessage: {
    color: "#999999",
    fontSize: "1.1rem",
    lineHeight: 1.4,
  },
  tooltipBase: {
    color: "#999999",
    fontSize: "1.1rem",
    lineHeight: 1.4,
    cursor: "pointer",
  },
  field: {
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
  },
  fieldError: {
    border: "1px solid red",
  },
  infoRow: {
    alignItems: "center",
    background: "#F8F9FB",
    borderRadius: 10,
    display: "flex",
    padding: 30,
    width: "calc(100% - 60px)",
    marginBottom: "20px",
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.4,
    marginLeft: 20,
    fontWeight: "bold",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    borderRadius: 5,
    boxShadow: "0 2px 10px 0px #E0E0E0",
    paddingLeft: 8,
    // This line disable the blue border
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const isCardValid = (cardNumber) => String(cardNumber).match(/^[0-9*]{16}$/);

const NewPayment = (props) => {
  // const isMediumScreen = useMediaQuery({
  //   query: '(min-device-width: 768px)'
  // })
  const classes = useStyles();
  const {
    name,
    balance,
    currentSpace,
    currentUser,
    reservationID,
    transactionModes,
    frontdeskUsers,
    maskedCards,
    stripe,
    channexCardToken = null,
    settings,
    bookingSource,
    dispatch,
  } = props;

  const hasChargeViaStripePermission = usePermissions(
    MODULE.CHARGE_CARDS_VIA_STRIPE.ID
  );

  const [date, setDate] = React.useState(
    fetchDatePerTimezone(null, currentSpace.timezone)
  );
  const [focused, setFocused] = React.useState(false);
  const [amount, setAmount] = React.useState(
    balance ? parseFloat(getAmtFormat(balance)) : 0
  );
  // const [selectedMode, setSelectedMode] = React.useState(null);
  const [newModeFlag, setNewModeFlag] = React.useState(false);
  const [newTransactionMode, setNewTransactionMode] = React.useState("");
  const [loadingSessions, setLoadingSessions] = React.useState(false);
  const [sessions, setSessions] = React.useState([]);
  const [selectedSession, setSelectedSession] = React.useState("none");
  const [errors, setErrors] = React.useState({
    amount: false,
    mode: false,
    date: false,
  });
  const [selectCardError, setSelectCardError] = React.useState(false);
  // const [cardData, setCardData] = React.useState(null);
  // const [isCardFetchLoading, setIsCardFetchLoading] = React.useState(false);
  const [selectedCardToken, setSelectedCardToken] = React.useState(null);
  const [showAddCard, setShowAddCard] = React.useState(false);
  const [printer, setPrinter] = React.useState(true);
  const cashID = currentSpace?.transactionModes?.find(
    (mode) => transactionModes[mode].name === "cash"
  );
  const cardID = currentSpace?.transactionModes?.find(
    (mode) => transactionModes[mode].name === "card"
  );
  const stripeID = currentSpace?.transactionModes?.find(
    (mode) => transactionModes[mode].name === "Stripe"
  );
  const otherArray = currentSpace?.transactionModes?.filter((mode) => {
    return (
      transactionModes[mode].name !== "cash" &&
      transactionModes[mode].name !== "card" &&
      transactionModes[mode].name !== "Stripe" &&
      transactionModes[mode].name !== "discount" &&
      transactionModes[mode].name !== "surcharge" && 
      transactionModes[mode].isActive
    );
  });
  const [selectedTab, setSelectedTab] = React.useState("cash");
  const [selectedMode, setSelectedMode] = React.useState(
    stripe.activated && !stripe.action_required && stripeID
      ? stripeID
      : cashID
      ? cashID
      : null
  );
  // const admin = currentSpace && currentSpace.admins.includes(currentUser._id)

  const cardNumberOptions = maskedCards.map((cardData) => ({
    value: cardData.card_token,
    label: cardData.card_number,
  }));

  React.useEffect(() => {
    if (bookingSource?.toUpperCase() !== CHANNEL.Hostelworld.code.toUpperCase())
      return;
    if (maskedCards?.length < 1) {
      dispatch(getReservationCardTokens(reservationID));
      return;
    }
    if (maskedCards.find((mc) => isCardValid(mc.card_number))) return;

    dispatch(getReservationCardTokens(reservationID));
  }, [bookingSource]);

  React.useEffect(() => {
    if (
      hasChargeViaStripePermission &&
      stripe.activated &&
      !stripe.action_required
    ) {
      setSelectedTab("stripe");
    }
  }, [hasChargeViaStripePermission]);

  React.useEffect(() => {
    if (maskedCards && maskedCards.length > 0)
      setSelectedCardToken(maskedCards[0].card_token);
  }, [maskedCards]);

  React.useEffect(() => {
    if (date) {
      // console.log("fetchCashSessions", date)
      fetchCashSessions(date);
    }
  }, [date]);

  //* --------------------------------------------------------------------- *//
  //* FETCH CARD FROM CHANNEX */
  //* --------------------------------------------------------------------- *//
  // const fetchCardDetailHandler = (channexCardToken) => {
  //   setIsCardFetchLoading(true);
  //   const tokenResponse = fetchChannexSessionToken();
  //   tokenResponse.then(successResult => {
  //     const { session_token } = successResult.data;
  //     if (session_token) {
  //       const params = {
  //         card_token: channexCardToken,
  //         session_token
  //       };

  //       const cardResponse = fetchCardDetails(params);
  //       cardResponse.then(successResult => {
  //         setIsCardFetchLoading(false);
  //         let parser = new DOMParser();
  //         let parsedHtml = parser.parseFromString(successResult.data, 'text/html');
  //         const cardDetails = {
  //           cardType: parsedHtml.getElementsByClassName("card__field--card-type")[0].children[1].innerHTML.trim(),
  //           cardNumber: parsedHtml.getElementsByClassName("card__field--card-number")[0].children[1].innerHTML.trim(),
  //           cardName: parsedHtml.getElementsByClassName("card__field--cardholder-name")[0].children[1].innerHTML.trim(),
  //           cardExpiry: parsedHtml.getElementsByClassName("card__field--expiration-date")[0].children[1].innerHTML.trim(),
  //         }
  //         // console.log('cardDetails ', cardDetails);
  //         setCardData({ ...cardDetails });
  //       })
  //         .catch(errorResult => {
  //           setIsCardFetchLoading(false);
  //           console.log(errorResult);
  //         });
  //     }

  //   }).catch(errorResult => {
  //     setIsCardFetchLoading(false);
  //     console.log(errorResult);
  //   });
  // }

  //* --------------------------------------------------------------------- *//
  //* CARD FUNCTIONS */
  //* --------------------------------------------------------------------- *//
  const cardSelectHandler = (cardNumber) => {
    setSelectedCardToken(cardNumber.value);
    setSelectCardError(false);
    // setCardData(null);
  };

  const handleStripeRedirect = () => {
    props
      .dispatch(getStripeAccountLink(currentSpace.propertyID))
      .then((response) => {
        if (response) {
          window.open(response.url, "_blank");
        }
      });
  };

  //* --------------------------------------------------------------------- *//
  //* VALIDATION */
  //* --------------------------------------------------------------------- *//
  const handleValidations = () => {
    let noErrorFound = true;
    let errorList = {};
    if (!selectedMode) {
      noErrorFound = false;
      errorList.mode = true;
    }
    if (!amount || isNaN(amount) || Number(amount) === 0) {
      noErrorFound = false;
      errorList.amount = true;
    }
    setErrors({ ...errors, ...errorList });
    return noErrorFound;
  };

  const validateStripePayment = () => {
    if (selectedCardToken) return true;
    setSelectCardError(true);
    return;
  };

  //* --------------------------------------------------------------------- *//
  //* POST NEW TRANSACTION */
  //* --------------------------------------------------------------------- *//
  const handleSubmit = () => {
    const check = handleValidations();
    if (check) {
      let payload = {};
      let today = fetchDatePerTimezone(null, currentSpace.timezone);
      let createdAt = moment(date).utc().format();
      if (
        fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE) !==
        fetchDateFormat(today, DATE_FORMATS.DEFAULT_DATE)
      ) {
        payload.createdAt = createdAt;
      }
      payload.printerCheck = printer;
      if (selectedTab === "cash") {
        if (selectedSession === "none") {
          payload = {
            ...payload,
            amount,
            modeID: selectedMode,
            cashDrawerSessionID: "",
          };
        } else {
          payload = {
            ...payload,
            amount,
            modeID: selectedMode,
            cashDrawerSessionID: selectedSession,
          };
        }
        props.dispatch(
          createReservationPayment(
            reservationID,
            payload,
            printer && settings.printers && settings.printers.length > 0
              ? settings.printers[0]
              : {}
          )
        );
      } else if (selectedTab === "stripe") {
        if (!validateStripePayment()) return;
        payload = {
          ...payload,
          amount,
          modeID: selectedMode,
          channexCardToken: selectedCardToken,
        };
        props.dispatch(createReservationPayment(reservationID, payload, {}));
      } else {
        payload = { ...payload, amount, modeID: selectedMode };
        props.dispatch(
          createReservationPayment(
            reservationID,
            payload,
            selectedTab === "card" &&
              printer &&
              settings.printers &&
              settings.printers.length > 0
              ? settings.printers[0]
              : {}
          )
        );
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "userAction",
        eventCategory: "Reservation",
        eventAction: "add payment",
        eventLabel: "Add Payment",
        eventValue: amount,
      });

      props.handleCloseNewPayment();
    }
  };

  //* --------------------------------------------------------------------- *//
  //* GET CASH SESSIONS */
  //* --------------------------------------------------------------------- *//
  const fetchCashSessions = (sessionDate) => {
    setLoadingSessions(true);
    http
      .get(
        `/api/frontdesk/transaction/cashdrawer/${
          currentSpace.propertyID
        }/${sessionDate.format("YYYY-MM-DD")}`
      )
      .then((successResult) => {
        const data = successResult.data;
        setLoadingSessions(false);
        setSessions(data.cashDrawerSessions);
        // console.log(data);
      })
      .catch((errorResult) => {
        setLoadingSessions(false);
        console.log(errorResult);
      });
  };

  //* --------------------------------------------------------------------- *//
  //* CHANGE TAB */
  //* --------------------------------------------------------------------- *//
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    if (tab === "cash") {
      setSelectedMode(cashID);
      errors.mode = false;
    } else if (tab === "card") {
      setSelectedMode(cardID);
      errors.mode = false;
    } else if (tab === "stripe") {
      setSelectedMode(stripeID);
      errors.mode = false;
      // if(stripe.activated && !stripe.action_required && maskedCards && maskedCards.length == 0){
      //   showAddCardForm();
      // }
    }
    if (tab === "other") {
      setSelectedMode(null);
    }
  };

  //* --------------------------------------------------------------------- *//
  //* CREATE NEW TRANSACTION MODE */
  //* --------------------------------------------------------------------- *//
  const handleNewTransactionMode = () => {
    props.dispatch(
      createTransactionMode(
        currentSpace._id,
        currentSpace.propertyID,
        newTransactionMode
      )
    );
    setNewModeFlag(false);
    setNewTransactionMode("");
  };

  const handleSetAmount = (e) => {
    let value = e.target.value;
    value = value < 0 ? Math.abs(value) : value;
    setAmount(value);
    errors.amount = false;
  };

  const handleOtherTransMode = (e) => {
    setSelectedMode(e.target.value);
    errors.mode = false;
  };

  const handleToggle = (e) => {
    if (e.target.checked) {
      setPrinter(true);
      setErrors({ printer: false });
    } else {
      setPrinter(false);
    }
  };

  //* --------------------------------------------------------------------- *//
  //* DISPLAY CREDIT CARD */
  //* --------------------------------------------------------------------- *//
  // const displayCardInfo = () => {
  //   let cardDisplay = null;

  //   if (maskedCards.length == 1) {
  //     cardDisplay = maskedCards[0];
  //   }

  //   if (maskedCards.length > 1 && selectedCardToken) {
  //     cardDisplay = maskedCards.find(cardData => cardData.card_token == selectedCardToken);
  //   }

  //   if (!cardDisplay) return
  //   const cardType = cardData?.cardType || (cardDisplay?.card_type?.charAt(0).toUpperCase() + cardDisplay?.card_type?.slice(1)) || ' '
  //   const isCardInvalid = !isCardValid(cardDisplay.card_number)
  //   return(
  //     <>
  //     {isCardInvalid && (
  //       <div className={clsx('row', classes.marginTop)}>
  //       <div className={classes.infoRow}>
  //         <div>
  //           <WarningRoundedIcon color='error' fontSize='large' />
  //         </div>
  //         <span className={classes.helperText}>
  //           {'Invalid Card, please report using chat bubble in '}
  //           <a className={classes.link} onClick={() => window.location.pathname = '/beds/calendar'}>calendar</a>
  //         </span>
  //       </div>
  //     </div>
  //     )}
  //     <div className={classes.cardInfoContainer}>

  //       {isCardFetchLoading && <div className={classes.loadingCard}><CircularProgress /></div>}

  //       {!isCardFetchLoading && <div>
  //         <div className={classes.cardRow}>
  //           <div className={classes.logoContainer}>
  //             <div className={classes.cardValue}>
  //               {cardType}
  //             </div>
  //           </div>
  //           {!cardData &&
  //             <AccessController requiredPermission={MODULE.VIEW_CARD.ID}>
  //               <img src={IMAGES.ICONS.view} onClick={() => fetchCardDetailHandler(selectedCardToken)} className={classes.viewIcon} />
  //             </AccessController>
  //           }
  //           {cardData && <img src={IMAGES.ICONS.visibilityHidden} onClick={() => setCardData(null)} className={classes.viewIcon} />}
  //         </div>

  //         <div className={classes.cardNumber}>
  //           {cardData && parse(cardData.cardNumber)}
  //           {!cardData && cardDisplay.card_number.match(/.{1,4}/g).map(card => {
  //             return (
  //               <span key={Math.random()} className={classes.cardDigit}>{card}</span>
  //             )
  //           })
  //           }
  //         </div>

  //         <div className={classes.cardRow}>
  //           <div className={classes.cardSection}>
  //             <div className={classes.cardLabel}>Name</div>
  //             <div className={classes.cardName}>{cardData ? cardData.cardName : cardDisplay.cardholder_name}</div>
  //           </div>
  //           <div className={classes.cardSection}>
  //             <div className={classes.cardLabel}>Expiry</div>
  //             <div className={classes.cardExpiry}>
  //               {cardData ? cardData.cardExpiry : `${cardDisplay.expiration_month}/${cardDisplay.expiration_year.slice(2)}`}
  //             </div>
  //           </div>

  //         </div>
  //       </div>}
  //     </div>
  //     </>
  //   )
  // }

  return (
    <div className={classes.container}>
      {/* --------------------------------------------------------------------- */}
      {/* HEADER */}
      {/* --------------------------------------------------------------------- */}
      <CloseIcon
        className={classes.closeIcon}
        onClick={props.handleCloseNewPayment}
      />
      <div className={classes.row}>
        <Typography variant="h5" className={classes.header}>
          Add Payment
        </Typography>
      </div>

      {/* --------------------------------------------------------------------- */}
      {/* DATE */}
      {/* --------------------------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.item}>
          <div className={classes.label}>Date</div>
          <AccessController
            requiredPermission={MODULE.ADD_PAST_PAYMENTS.ID}
            renderOtherwise={
              <div className={classes.defaultDate}>
                {fetchDatePerTimezone(null, currentSpace.timezone).format(
                  "DD MMM, YYYY"
                )}
              </div>
            }
          >
            <div className={classes.datePicker}>
              <SingleDatePicker
                date={date}
                onDateChange={(date) => setDate(date)}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="transaction_date"
                numberOfMonths={1}
                displayFormat={"MMM DD, YYYY"}
                isOutsideRange={function noRefCheck() {}}
                noBorder={true}
                small
                hideKeyboardShortcutsPanel
              />
            </div>
          </AccessController>

          {errors.date && (
            <div className={classes.error}>Please enter a valid date</div>
          )}
        </div>
      </div>

      {/* --------------------------------------------------------------------- */}
      {/* AMOUNT */}
      {/* --------------------------------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.item}>
          <div className={classes.label}>Amount</div>
          <NumberInput
            id="amount"
            type="tel"
            min={0}
            value={amount}
            className={classes.amount}
            onChange={(e) => handleSetAmount(e)}
          />
          {errors.amount && (
            <div className={classes.error}>Please enter a valid amount</div>
          )}
        </div>
      </div>

      {/* --------------------------------------------------------------------- */}
      {/* TABS */}
      {/* --------------------------------------------------------------------- */}
      {errors.mode && (
        <div className={classes.error}>Please select a transaction mode</div>
      )}
      <div className={clsx(classes.row, classes.paymentMode)}>
        <AccessController
          requiredPermission={MODULE.CHARGE_CARDS_VIA_STRIPE.ID}
        >
          {stripe.activated && !stripe.action_required && (
            <div
              id="Stripe"
              className={clsx({
                [classes.tab]: true,
                [classes.selected]: selectedTab === "stripe",
              })}
              onClick={() => handleTabSelect("stripe")}
            >
              Stripe
            </div>
          )}
        </AccessController>
        <div
          id="cash"
          className={clsx({
            [classes.tab]: true,
            [classes.selected]: selectedTab === "cash",
          })}
          onClick={() => handleTabSelect("cash")}
        >
          Cash
        </div>
        <div
          id="card"
          className={clsx({
            [classes.tab]: true,
            [classes.selected]: selectedTab === "card",
          })}
          onClick={() => handleTabSelect("card")}
        >
          Card
        </div>
        <div
          id="other"
          className={clsx({
            [classes.tab]: true,
            [classes.selected]: selectedTab === "other",
            [classes.lastTab]: true,
          })}
          onClick={() => handleTabSelect("other")}
        >
          Other
        </div>
      </div>

      {/* --------------------------------------------------------------------- */}
      {/* STRIPE TAB OPTIONS */}
      {/* --------------------------------------------------------------------- */}

      {/* Stripe not active */}
      {stripe.activated && stripe.action_required && (
        <div className={clsx(classes.row, classes.createdBy)}>
          <p>
            Stripe payments are currently inactive. To activate them,{" "}
            <span
              className={classes.link}
              onClick={() => handleStripeRedirect()}
            >
              Click here
            </span>
            !
          </p>
        </div>
      )}

      {/* Stripe active - No Card*/}
      {stripe.activated &&
        !stripe.action_required &&
        maskedCards &&
        maskedCards.length == 0 &&
        // isMediumScreen &&
        selectedTab === "stripe" && (
          <div className={classes.cardSelectContainer}>
            <div className={classes.cardSelectHeader}>
              <div className={classes.blankCard}>
                <span>No card tagged to this reservation.</span>
                <span
                  className={classes.link}
                  style={{ display: "inline" }}
                  onClick={() => setShowAddCard(true)}
                >
                  + Add New Card{" "}
                </span>
              </div>
            </div>
          </div>
        )}

      {/* Stripe active */}
      {stripe.activated &&
        !stripe.action_required &&
        maskedCards &&
        maskedCards.length > 0 &&
        // isMediumScreen &&
        selectedTab === "stripe" && (
          <div className={classes.row}>
            <div className={classes.stripeCard}>
              {/* ---------SELECT CARD DROPDOWN -------------------------- */}
              <div className={classes.cardSelectContainer}>
                <div className={classes.cardSelectHeader}>
                  <div
                    className={classes.addCard}
                    onClick={() => setShowAddCard(true)}
                  >
                    + Add New Card
                  </div>
                </div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: selectCardError,
                  })}
                >
                  <Select
                    value={cardNumberOptions.find(
                      (e) => e.value === selectedCardToken
                    )}
                    name="cardNumber"
                    onChange={cardSelectHandler}
                    options={cardNumberOptions}
                    styles={selectStyle}
                    theme={(theme) => ({
                      ...theme,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#666666",
                        primary25: "#dddddd",
                      },
                    })}
                  />
                </div>
                {selectCardError && (
                  <div className={classes.error}>Please select a card </div>
                )}
              </div>

              {/* {displayCardInfo()} */}
              <CardView
                maskedCards={maskedCards}
                selectedCardToken={selectedCardToken}
              />
            </div>
          </div>
        )}

      {/* --------------------------------------------------------------- */}
      {/* CHANNEX CARD CAPTURE IFRAME MODAL */}
      {/* --------------------------------------------------------------- */}
      {showAddCard && (
        <AddCardModal
          reservationID={reservationID}
          closeModalHander={() => setShowAddCard(false)}
        />
      )}

      {/* --------------------------------------------------------------------- */}
      {/* CASH TAB OPTIONS */}
      {/* --------------------------------------------------------------------- */}
      {selectedTab === "cash" && (
        <div className={classes.row}>
          {loadingSessions && <div>Loading cash drawer sessions</div>}
          {!loadingSessions && sessions.length === 0 && (
            <div className={classes.warningMessage}>
              No cash drawer sessions found for this date.
              <br />
              Amount entered here will be captured for tracking purposes only.
            </div>
          )}
          {!loadingSessions && sessions.length > 0 && (
            <RadioGroup
              aria-label="cash-sessions"
              className={classes.radioContainer}
              value={selectedSession}
              onChange={(e) => setSelectedSession(e.target.value)}
            >
              {sessions.map((session) => (
                <FormControlLabel
                  key={session._id}
                  className={classes.radio}
                  value={session._id}
                  control={<Radio color="primary" disableRipple />}
                  label={`${frontdeskUsers[session.startedByID].firstName} ${
                    frontdeskUsers[session.startedByID].lastName
                  }, 
                    ${fetchDateFormat(
                      session.startTime,
                      `${DATE_FORMATS.TIME}`
                    )} >
                    ${
                      session.isClosed
                        ? `${frontdeskUsers[session.endedByID].firstName} ${
                            frontdeskUsers[session.endedByID].lastName
                          }, ${fetchDateFormat(
                            session.endTime,
                            `${DATE_FORMATS.TIME}`
                          )}`
                        : "Active cash drawer session"
                    }
                  `}
                />
              ))}
              <FormControlLabel
                className={classes.radio}
                value={"none"}
                control={<Radio color="primary" disableRipple />}
                label={"Do not tag to cash drawer session"}
              />
            </RadioGroup>
          )}
        </div>
      )}

      {/* --------------------------------------------------------------------- */}
      {/* CARD TAB OPTIONS */}
      {/* --------------------------------------------------------------------- */}
      {selectedTab === "card" && (
        <div className={classes.row}>
          <div className={classes.warningMessage}>
            External credit card terminal.
            <br />
            Amount entered here will be captured for tracking purposes only.
          </div>
        </div>
      )}

      {/* --------------------------------------------------------------------- */}
      {/* OTHER TAB OPTIONS */}
      {/* --------------------------------------------------------------------- */}
      {selectedTab === "other" && (
        <div className={classes.row}>
          <div className={classes.transactionMode}>
            <RadioGroup
              aria-label="payment-mode"
              className={classes.radioContainer}
              value={selectedMode}
              onChange={(e) => handleOtherTransMode(e)}
            >
              {otherArray.map((otherPaymentID) => (
                <FormControlLabel
                  key={otherPaymentID}
                  className={classes.radio}
                  value={transactionModes[otherPaymentID]._id}
                  control={<Radio color="primary" disableRipple />}
                  label={toTitleCase(transactionModes[otherPaymentID].name)}
                />
              ))}
            </RadioGroup>

            {/* --------------------------------------------------------------------- */}
            {/* ADD NEW TRANSACTION MODE */}
            {/* --------------------------------------------------------------------- */}
            {!newModeFlag && (
              <div
                className={classes.link}
                onClick={() => setNewModeFlag(true)}
              >
                Add another payment method
              </div>
            )}
            {newModeFlag && (
              <div className={classes.transactionModeContainer}>
                <TextField
                  id="newPaymentMode"
                  margin="none"
                  label="New Payment Method"
                  type="text"
                  onChange={(e) => setNewTransactionMode(e.target.value)}
                  className={classes.transactionMode}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {/* --------------------------------------------------------------------- */}
                {/* SUBMIT / CANCEL NEW TRANSACTION MODE */}
                {/* --------------------------------------------------------------------- */}
                <div className={classes.buttonHolder}>
                  <CloseIcon
                    className={classes.button}
                    onClick={() => setNewModeFlag(false)}
                  />
                  <DoneIcon
                    className={classes.button}
                    onClick={handleNewTransactionMode}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {(selectedTab === "cash" || selectedTab === "card") &&
        settings &&
        settings.printers &&
        settings.printers.length > 0 && (
          <div>
            <Checkbox
              defaultChecked={printer}
              color="primary"
              onClick={handleToggle}
              inputProps={{ "aria-label": "Accept Printing" }}
            />
            <Tooltip title="Check Account Settings > Printer for additional details">
              <span className={classes.tooltipBase}>Print Receipt</span>
            </Tooltip>
          </div>
        )}
      {/* --------------------------------------------------------------------- */}
      {/* SUBMIT / CANCEL PAYMENT */}
      {/* --------------------------------------------------------------------- */}
      <Button
        size="small"
        variant="outlined"
        className={classes.submitButton}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  name: state.auth.user.name,
  balance: state.reservationDetails.reservation.balance,
  reservationID: state.reservationDetails.reservation._id,
  maskedCards: state.reservationDetails.maskedCards,
  transactionModes: state.transactionModes,
  frontdeskUsers: state.frontdeskUsers,
  // isSystemAdmin: state.auth.user.isSystemAdmin,
  // userPermissions: state.permissions.userPermissions,
  stripe: state.stripe,
  settings: state.settings,
  bookingSource: state.reservationDetails.reservation.bookingSource,
});

export default connect(mapStateToProps)(NewPayment);
