import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SYSTEM_ADMIN_ACCESS_DENY_LIST } from './constants';

class AccessController extends Component{
    checkPermissions = () =>{
        const { isAdmin, isSystemAdmin, requiredPermission, userPermissions } = this.props;
        
        if(isAdmin) return true;

        if(isSystemAdmin && !SYSTEM_ADMIN_ACCESS_DENY_LIST.includes(requiredPermission)) return true;
        
        if(userPermissions.includes(requiredPermission)) return true;

        return false;
    }

    render(){
        const { requiredPermission, children, renderOtherwise } = this.props;
        if(!children || !requiredPermission){
            return null;
        } 

        if(this.checkPermissions()){
            return children;
        }

        if(renderOtherwise){
            return renderOtherwise;
        }

        return null;
    }
}

const mapStateToProps = state =>{
    const { auth, dashboard, spaces, permissions } = state;
    const currentUser = auth.user._id;
    const currentSpace = spaces[dashboard.currentSpace] || {};
    const isAdmin = currentSpace.admins && currentSpace.admins.includes(currentUser);
    const isSystemAdmin = auth.user.isSystemAdmin;
    return{
        isAdmin,
        isSystemAdmin,
        userPermissions: permissions.userPermissions
    }
}

export default connect(mapStateToProps)(AccessController);