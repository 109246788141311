import React from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { toTitleCase } from '../../utils/wordUtils';
// import PublicIcon from '@material-ui/icons/Public';
// import HomeIcon from '@material-ui/icons/Home';
// import SearchIcon from '@material-ui/icons/Search';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    root:{
        background: '#F8F9FB',
        marginBottom: 10,
        padding: '12px 20px',
        cursor: 'pointer',
        '&:hover': {
            background: '#F8F9FB'
        }
    },
    participantInfo:{
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.1rem',
        color: '#666666',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        '& .iconContainer':{
            alignItems: 'center',
            display: 'flex',
            marginRight: '20px'
        },
        '& .icon':{
            fontSize: '1.5rem',
            opacity: 0.7,
            marginRight: '5px',
        },
        '& img' : {
            height: 12,
            width: 12,
            marginRight: 5,
            opacity: 0.5,
        }
    },
    participantSection:{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
        fontSize: '1rem',
        fontWeight: 600,
        '& .redButton':{
            color: '#d96977',
        }
    },
    name: {
        color: '#666666',
        fontSize: '1.2rem',
    }
}));

const ParticipantInfoCard = ({
    bookingID,
    reservationID,
    country,
    guestType,
    bookingSource,
    guestName,
    isPaymentPending,
    openBookingMask,
    count,
    t
}) =>{
    const classes = useStyles();
    return(
        <div 
            className={clsx(classes.root, 'participantCardRoot')}
            onClick={() => openBookingMask(reservationID)}
        >
            <div className={classes.participantSection}>
                <div className={classes.name}>{toTitleCase(guestName)}</div>
                {isPaymentPending && <div className="redButton">{t('experiences.calendar.unpaid')}</div> }
            </div>
            <div className={classes.participantInfo}>
                {count && <div className="iconContainer">
                    <img src={IMAGES.ICONS.experienceUser} />
                    {count}
                </div>}
                {bookingSource && <div className="iconContainer">
                <img src={IMAGES.ICONS.experienceSource} />
                    {bookingSource}
                </div>}
            </div>
        </div>
    )
}

export default withTranslation()(ParticipantInfoCard);