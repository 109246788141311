import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Pill from '../../../common/Pill'

import Tooltip from '@material-ui/core/Tooltip'
import IMAGES from '../../../../constants/images';
import MicrositeDescription from '../MicrositeDescription'
import { getMissingLanguage } from '../../helper'
import LanguagesView from '../LanguageView'

const useStyles = makeStyles(theme => ({
  field: {
    alignItems: 'flex-start',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    flexWrap: 'wrap',
    height: 150,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%',
    '&.disabled': {
      background: '#E2E2E2'
    }
  },
  description: {
    height: 80
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  }
}))

const hostelCategories = [
  { label: 'Eco Hostel', value: '1' },
  { label: 'Adventure Hostel', value: '2' },
  { label: 'Beach Hostel', value: '3' },
  { label: 'Party Hostel', value: '4' },
  { label: 'Ski Hostel', value: '5' },
  { label: 'Surf Hostel', value: '6' },
  { label: 'Haunted Hostel', value: '7' },
  { label: 'Remote/Off the beaten track Hostel', value: '8' },
  { label: 'Family Hostel', value: '9' },
  { label: 'Gay Friendly Hostel', value: '10' },
  { label: 'Ryokan Hostel', value: '11' },
  { label: 'Onsen', value: '12' }
]

const bestForCategories = [
  { label: 'Central Location', value: '1' },
  { label: 'Couples', value: '2' },
  { label: 'Culture', value: '3' },
  { label: 'Entertainment', value: '4' },
  { label: 'Events', value: '5' },
  { label: 'Families', value: '6' },
  { label: 'Food', value: '7' },
  { label: 'Green Living', value: '8' },
  { label: 'Groups', value: '9' },
  { label: 'Home Cooking', value: '10' },
  { label: 'Meeting People', value: '11' },
  // { label: 'Meeting People', value: '12' }, duplicate option in hostelworld inbox
  { label: 'Parties', value: '13' },
  { label: 'Privacy', value: '14' },
  { label: 'Young Travellers', value: '15' },
]

const processOptionsDataHandler = (categories, bestFor) => (
  {
    categories1: categories?.[0]?.id || null,
    categories2: categories?.[1]?.id || null,
    bestFor1: bestFor?.[0]?.id || null,
    bestFor2: bestFor?.[1]?.id || null
  }
)

const getKey=(content, keyName, selectedLanguage)=> {
  return content ? Object.keys(content).find(key => key.startsWith(keyName) && key.indexOf(selectedLanguage) >= 0) : null
}


const Property = props => {
  const { t } = props
  const classes = useStyles()
  const {
    settings: propertySettings,
    updateSettings,
    deleteSettingsContent
  } = props;

  const { content, categories = [], bestFor = [] } = propertySettings
  const [selectedLanguage, setSelectedLanguage] = React.useState('en_gb');
  const [briefIntroContentKey, setBriefIntroContentKey] = React.useState(getKey(content, 'briefdescription','en_gb'));
  const [fullDescContentKey, setFullDescContentKey] = React.useState(getKey(content, 'description','en_gb'));

  const [optionsValue, setOptionsValue] = useState(processOptionsDataHandler(categories, bestFor))

  React.useEffect(()=>{
    setOptionsValue(processOptionsDataHandler(categories, bestFor))
  }, [propertySettings])

  React.useEffect(() => {
    setBriefIntroContentKey(getKey(content, 'briefdescription', selectedLanguage))
    setFullDescContentKey(getKey(content, 'description', selectedLanguage))
  }, [selectedLanguage])
  
  const handleSelect = (label, value, type) => {
    setOptionsValue({ ...optionsValue, [label]: value })
    updateSettings(`content.${label}`, value)
  }

  const seLanguageHandler = (lang) => {
    setSelectedLanguage(lang);
    deleteSettingsContent(`${fullDescContentKey}`)
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.property.title')}</div>
        <div className='sub-title'>{t('microsite.property.description')}</div>

        <LanguagesView value={selectedLanguage} updateLanguage={(lang) => seLanguageHandler(lang)}/>

        <MicrositeDescription
          label={ t('microsite.property.briefIntro') }
          tooltipText={ t('microsite.property.briefIntoHelper') }
          keyName={ 'briefIntro' }
          value={ content?.[briefIntroContentKey]?.content }
          disabled={ true }
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${briefIntroContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'briefdescription')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
          />

        <MicrositeDescription
          label={ t('microsite.property.fullDesc') }
          tooltipText={ t('microsite.property.fullDescHelper') }
          keyName={ 'fullDesc' }
          value={ content?.[fullDescContentKey]?.content }
          disabled={ false }
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${fullDescContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'description')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
        />

        <div className='row'>
          <div className='label'>
            {t('microsite.property.category1')}
            <Tooltip title={t('microsite.property.category1Helper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <div className={classes.container}>
            {optionsValue.categories1 &&
              <Pill
                name={hostelCategories.find(e => e.value == optionsValue.categories1)?.label}
                cancelButton
                selected
                handleClick={() => handleSelect('categories1', null, 'category')}
              />}
            {!optionsValue.categories1 && hostelCategories.map(category => category.value != optionsValue.categories2 &&
              <Pill key={category.value} name={category.label} handleClick={() => handleSelect('categories1', category.value, 'category')} />
            )}
          </div>
        </div>

        <div className='row'>
          <div className='label'>{t('microsite.property.category2')}</div>
          <div className={classes.container}>
            {optionsValue.categories2 &&
              <Pill
                name={hostelCategories.find(e => e.value == optionsValue.categories2)?.label}
                cancelButton
                selected
                handleClick={() => handleSelect('categories2', null, 'category')}
              />}
            {!optionsValue.categories2 && hostelCategories.map(category => category.value != optionsValue.categories1 &&
              <Pill key={category.value} name={category.label} handleClick={() => handleSelect('categories2', category.value, 'category')} />
            )}
          </div>
        </div>

        <div className='row'>
          <div className='label'>
            {t('microsite.property.bestFor1')}
            <Tooltip title={t('microsite.property.bestFor1Helper')}>
              <img className='info-icon' src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
          <div className={classes.container}>
            {optionsValue.bestFor1 &&
              <Pill
                name={bestForCategories.find(e => e.value == optionsValue.bestFor1)?.label}
                cancelButton
                selected
                handleClick={() => handleSelect('bestFor1', null, 'bestFor')}
              />}
            {!optionsValue.bestFor1 && bestForCategories.map(category => category.value != optionsValue.bestFor2 &&
              <Pill key={category.value} name={category.label} handleClick={() => handleSelect('bestFor1', category.value, 'bestFor')} />
            )}
          </div>
        </div>

        <div className='row'>
          <div className='label'>{t('microsite.property.bestFor2')}</div>
          <div className={classes.container}>
            {optionsValue.bestFor2 &&
              <Pill
                name={bestForCategories.find(e => e.value == optionsValue.bestFor2)?.label}
                cancelButton
                selected
                handleClick={() => handleSelect('bestFor2', null, 'bestFor')}
              />}
            {!optionsValue.bestFor2 && bestForCategories.map(category => category.value != optionsValue.bestFor1 &&
              <Pill key={category.value} name={category.label} handleClick={() => handleSelect('bestFor2', category.value, 'bestFor')} />
            )}
          </div>
        </div>

      </div>
    </>
  )
}

export default withTranslation()(Property)
