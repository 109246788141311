import http from "../../utils/http";
import { normalize } from 'normalizr';
import { discussionSchema } from '../../schema/communitySchema';

//----------------------------------------------------------------
// LOAD QUESTIONS
//----------------------------------------------------------------
export function loadQuestions() {
  const ACTION_NAME = "LOAD_QUESTIONS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/questions`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [discussionSchema]);
        // console.log(normalizedOrder.entities)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.discussions });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD SPECIFIC THREAD
//----------------------------------------------------------------
export function loadThread(questionID) {
  const ACTION_NAME = "LOAD_THREAD";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/question/${questionID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// RESET THREAD
//----------------------------------------------------------------
export const resetThread = () => (dispatch) => {
  dispatch({ type: "RESET_THREAD"})
};

//----------------------------------------------------------------
// SUBMIT QUESTION
//----------------------------------------------------------------
export function submitQuestion(question) {
  const ACTION_NAME = "SUBMIT_QUESTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/question`, question)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SUBMIT ANSWER
//----------------------------------------------------------------
export function submitAnswer(answer) {
  const ACTION_NAME = "SUBMIT_ANSWER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/answer`, answer)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


