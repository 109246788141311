import http from '../../utils/http';
import axios from "axios";
import { normalize } from 'normalizr';
import { frontdeskUserSchema } from '../../schema/frontDeskSchema';

//----------------------------------------------------------------
// LOAD FRONTDESK USERS
//----------------------------------------------------------------
export function getFrontdeskUsers(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_FRONTDESK_USERS";
  return function(dispatch) {
    // dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/user/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data)
        var normalizedOrder = normalize(data, [ frontdeskUserSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder, spaceID}})
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE FRONTDESK USER
//----------------------------------------------------------------
export function createFrontdeskUser(spaceID, newUser) {
  const ACTION_NAME = "CREATE_FRONTDESK_USER";
  let newUserData = { ...newUser };
  let file = newUserData.image;
  delete newUserData.image;
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/user/register', {...newUser}, {headers: {'Accept': 'application/json'}})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        if(file) {
          const fileParts = file.name.split(".");
          const fileType = fileParts[1];
          const key = data.key;
          const url = data.url;
          const options = {
            headers: {
              "Content-Type": fileType
            }
          };
          axios.put(url, file, options).then(result => {
            http.post("/api/frontdesk/user/images/save", {
              userID: data.user._id,
              key: key
            }).then(response => {
              let responseData = response.data;
              dispatch({
                type: `${ACTION_NAME}_SUCCESS`,
                payload: { ...responseData, spaceID } 
              });
            }).catch(error => { return error; });
          }).catch(error => { return error; });
        } else{
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data.user, spaceID} });
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// EDIT FRONTDESK USER
//----------------------------------------------------------------
export function editFrontdeskUser(userID, userDetails) {
  const ACTION_NAME = "EDIT_FRONTDESK_USER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/user/${userID}`, {...userDetails}, {headers: {'Accept': 'application/json'}})
      .then(successResult => {
        const data = successResult.data;
        // console.log('hello', data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
