import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { some } from "lodash";
import IMAGES from "../../../constants/images";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
  },
  searchContainer: {
    alignItems: "center",
    background: "#f8f9fb",
    borderRadius: "5px",
    display: "flex",
    height: 40,
    marginRight: 10,
    paddingLeft: 40,
    paddingRight: theme.spacing(2),
    position: "relative",
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  searchIcon: {
    position: "absolute",
    height: 15,
    left: 10,
    opacity: 0.5,
    top: 12,
    width: 15,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: 5,
    position: "absolute",
    right: 5,
    top: 6,
    "&:hover": {
      background: "#F0F0F0",
    },
  },
  accessHeader: {
    marginTop: "unset",
  },
  addReservation: {
    alignItems: "center",
    background: "#006699",
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    height: 40,
    justifyContent: "center",
    marginRight: 10,
    width: 40,
    "&:hover": {},
  },
  addIcon: {
    color: "#FFFFFF",
    fontSize: "2.2rem",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 0 0 1px #0000001A, 0 4px 11px #0000001A",
    marginTop: "8px",
  },
  listBox: {
    padding: "4px 0px",
  },
  suggestionItems: {
    padding: "8px 12px",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#DEEBFF",
    },
  },
}));

const Paper = (props) => {
  const classes = useStyles();
  const { children } = props;
  return some(children, (child) => child) ? (
    <div className={classes.paper}>{children}</div>
  ) : null;
};

const Listbox = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <ul
      id={props.id}
      className={clsx(classes.listBox)}
      role={props.role}
      onMouseDown={props.onMouseDown}
    >
      {props.children.map((child, index) => (
        <li
          key={index}
          className={clsx(child.props.className, classes.suggestionItems)}
          data-option-index={index}
          onClick={child.props.onClick}
          onMouseOver={child.props.onMouseOver}
          onTouchStart={child.props.onTouchStart}
        >
          {child.props.children}
        </li>
      ))}
    </ul>
  );
});

const ModuleHeader = (props) => {
  const classes = useStyles();
  const [searchKeyword, setSearchKeyword] = useState("");
  const {
    search,
    handleSearch,
    handleClearSearch,
    handleNewReservation,
    suggestions,
  } = props;
  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.searchContainer}>
          <img className={classes.searchIcon} src={IMAGES.ICONS.search2} />
          <Autocomplete
            options={searchKeyword.length > 2 ? suggestions : []}
            inputValue={search}
            freeSolo
            disableClearable
            filterOptions={(option) => option}
            autoComplete
            fullWidth
            onInputChange={(e) => {
              if (!e) return;
              const value = e.target.value || e.target.textContent;
              setSearchKeyword(value);
              handleSearch(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="bookingEngineURL"
                name="bookingEngineURL"
                placeholder="Search"
                fullWidth
                type="text"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
            PaperComponent={Paper}
            ListboxComponent={Listbox}
          />
          {search && (
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClearSearch}
            />
          )}
        </div>

        {!search && (
          <div
            className={classes.addReservation}
            onClick={handleNewReservation}
            data-intercom-target="New Reservation"
          >
            <AddIcon className={classes.addIcon} />
          </div>
        )}
      </div>
      <AccessHeaderAction
        moduleID={MODULE.CALENDAR.ID}
        className={classes.accessHeader}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  suggestions: state.search.suggestions,
});

export default connect(mapStateToProps)(ModuleHeader);
