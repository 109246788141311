import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

import { editRoom, deleteRoomMapping } from '../../../../redux/actions/rooms/rooms';
import RoomTypeForm from './RoomTypeForm';
import ExistingRoomMap from './ExistingRoomMap';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme =>({
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 25,
    display: 'flex',
    marginTop: 5,
    width: '200px',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  toggleSelect: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(80% - 60px)',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(60% - 60px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px)',
    }
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20,
  },
  buttonContainer: {
    display: 'flex',
  },
  bubble:{
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: 10,
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
      padding: 10,
    }
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 20,
  },
}));

function selectDistinct(array) {
  const seenIt = new Set();
  const distinct = [];

  for (let i = 0; i < array.length; i++) {
      const value = array[i].roomTypeID;

      if (!seenIt.has(value)) {
          seenIt.add(value);
          distinct.push(value);
      }
  }
  
  return distinct; 
  /* you could also drop the 'distinct' array and return 'Array.from(seenIt)', which converts the set object to an array */
}

const after = (a,b) => {
  return moment(a).diff(moment(b), 'days') > 0
}

const Inventory = props =>{
    const classes = useStyles();
    const {
        room,
        currentSpace,
        roomTypes,
        handleUpdateRoom,
        handleUpdateMultipleRoom,
        t
    } = props;

    const [inventory, setInventory] = React.useState(false);
    const [newLinkageInProgress, setNewLinkageInProgress] = useState(false);
    const [roomTypeOptions, setRoomTypeOptions] = useState([]);
    const [editList, setEditList] = useState([]); 

    useEffect(() => {
      if (room.advancedMapping=='oneToOne' || room.advancedMapping=='oneToMany' ) setInventory(true);
      if (room.primaryRoomTypeID) {
        let array = [];
        currentSpace.roomTypes.map(e => {
          if (roomTypes[e].isActive && e!==room.primaryRoomTypeID && !selectDistinct(room.mapping).includes(e)) array.push(e)
        })
        setRoomTypeOptions([...array]);
      }
    }, []);

    const handleCheckOverlap = (mapping) => {
      let prevDates = [];
      let array = [];
      mapping.sort((a, b) => after(a.startDate,b.startDate)).map((mappingItem, index) => {
        if (prevDates.length ==0) {
          array.push({...mappingItem, overlap: false});
        } else if (prevDates.filter(date => after(date, mappingItem.startDate)).length > 0) {
          // console.log('running filter', prevDates, mappingItem.startDate, prevDates.filter(date => after(date, mappingItem.startDate)).length);
          array.push({...mappingItem, overlap: true})
        } else array.push({...mappingItem, overlap: false});
        prevDates.push(mappingItem.endDate);
      })
      return array;
    }

    const handleToggleMulti = () => {
      if (inventory) {
        setInventory(false); 
        handleUpdateRoom('advancedMapping', 'none');
      } else {
        setInventory(true); 
        handleUpdateRoom('advancedMapping', 'oneToOne');
      }
    }

    const handleToggleOverlap = () => {
      if (room.advancedMapping=='oneToOne') {
        const newMapping = room.mapping.map(item => ({...item, overlap: false}));
        handleUpdateMultipleRoom({'advancedMapping': 'oneToMany', 'mapping': newMapping});
      } else if (room.advancedMapping=='oneToMany') {
        const newMapping = handleCheckOverlap(room.mapping);
        handleUpdateMultipleRoom({'advancedMapping': 'oneToOne', 'mapping': newMapping});
      }
    }

    const handleSplice = (selectedRoomType) => {
      let array = [...roomTypeOptions];
      const index = array.indexOf(selectedRoomType);
      if (index>-1) {
        array.splice(index, 1);
        setRoomTypeOptions([...array]);
      }
    }

    const handleAddBack = (selectedRoomTypeArray) => {
      setRoomTypeOptions([...roomTypeOptions, ...selectedRoomTypeArray])
    }

    const handleSaveNewLink = (newRoomTypeMapping) => {
      if (room._id){
        // Edit room to add new room type date range
        let mappingArray = [];
        newRoomTypeMapping.map(link => {
            mappingArray.push({roomTypeID: link.roomTypeID, startDate: moment(link.startDate).format('YYYY-MM-DD'), endDate: moment(link.endDate).format('YYYY-MM-DD'), visible: true})
        })
        // console.log('here', mappingArray);
        const response = props.dispatch(editRoom(room._id, {newMapping: mappingArray, advancedMapping: room.advancedMapping}));
        response.then(data => {
          // console.log('response', data)
          if (data) {
            handleUpdateRoom('mapping', [...data.updatedRoom.mapping]);
            setNewLinkageInProgress(false)
          }
        })
      } else {
        // Create room and new room type date range
        if (room.mapping && room.mapping.length > 0 ) {
          let updatedArray = []
          if (room.advancedMapping=='oneToOne') updatedArray = handleCheckOverlap([...room.mapping, ...newRoomTypeMapping])
          else updatedArray = [...room.mapping, ...newRoomTypeMapping]
          handleUpdateRoom('mapping', [...updatedArray]);
          setNewLinkageInProgress(false);
        } else {
          handleUpdateRoom('mapping', [...newRoomTypeMapping]);
          setNewLinkageInProgress(false);
        }
      }
    }

    const handleCancelLink = () => {
      setNewLinkageInProgress(false);
    }

    const handleEditClick = (roomTypeID) => {
      setEditList([...editList, roomTypeID]);
    }

    const handleCancelEdit = (roomTypeID) => {
      let array = [...editList]
      const index = array.indexOf(roomTypeID);
      if (index > -1) array.splice(index, 1)
      setEditList([...array]);
    }

    const handleEditLink = (roomTypeID, editedRoomTypeMapping) => {
      if (room._id) {
        let newMapping = [];
        let existingMapping = [];
        editedRoomTypeMapping.map(link => {
          if (link._id) existingMapping.push({_id: link._id, roomTypeID: link.roomTypeID, startDate: moment(link.startDate).format('YYYY-MM-DD'), endDate: moment(link.endDate).format('YYYY-MM-DD'), visible: true})
          else newMapping.push({roomTypeID: link.roomTypeID, startDate: moment(link.startDate).format('YYYY-MM-DD'), endDate: moment(link.endDate).format('YYYY-MM-DD'), visible: true})
        })
        const response = props.dispatch(editRoom(room._id, {newMapping, existingMapping}));
        response.then(data => {
          if (data) {
            handleUpdateRoom('mapping', [...data.updatedRoom.mapping]);
            handleCancelEdit(roomTypeID);
          }
        })
      } else {
        let array = [...room.mapping];
        let modifiedMapping = array.filter(e => e.roomTypeID!==roomTypeID);
        let updatedArray = []
        if (room.advancedMapping=='oneToOne') updatedArray = handleCheckOverlap([...modifiedMapping, ...editedRoomTypeMapping])
        else updatedArray = [...modifiedMapping, ...editedRoomTypeMapping]
        handleUpdateRoom('mapping', updatedArray);
        handleCancelEdit(roomTypeID);
      }
    }

    const handleDeleteLink = (roomTypeID) => {
      let updatedMapping = []
      room.mapping.map(e => {
        if (e.roomTypeID !== roomTypeID) updatedMapping.push(e)
      })
      // // Delete link from rate plan
      handleUpdateRoom('mapping', [...updatedMapping]);
      // // Add room type options back into the array
      setRoomTypeOptions([...roomTypeOptions, roomTypeID]);
    }

    return(
        <>
            <div className="main-content">
                
              <div className="main-content-title">{t('beds.roomTypesAndRooms.multiInv')}</div>
              <div className="sub-title">{t('beds.roomTypesAndRooms.roomTypeSwitch1')}</div>


              {!inventory && <div className="row">
                <div className={classes.infoRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    {t('beds.roomTypesAndRooms.roomTypeSwitch2')}
                  </div>
                </div>
              </div>}

              
              <div className="row">
                <div className="label">{t('beds.roomTypesAndRooms.enableMultiInv')}</div>
                <div className={classes.toggle}>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.left]: true, [classes.toggleSelect]: !inventory})}
                    onClick={handleToggleMulti}
                  >
                    OFF
                  </div>
                  <div 
                    className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.toggleSelect]: inventory})}
                    onClick={handleToggleMulti}
                  >
                    ON
                  </div>
                </div>
              </div>

              {inventory && !room.primaryRoomTypeID && 
                <div className="label">{t('beds.roomTypesAndRooms.enableMultiInvError')}</div>
              }

              {inventory && room.primaryRoomTypeID && <div className="row">
                <div className="row">
                  <div className={classes.buttonContainer}>
                    <div 
                      className={clsx({[classes.bubble]: true, [classes.selected]: room.advancedMapping=='oneToOne'})}
                      onClick={handleToggleOverlap}
                    >
                      {t('beds.roomTypesAndRooms.noOverlappingDates')}
                    </div>
                    <div 
                      className={clsx({[classes.bubble]: true, [classes.selected]: room.advancedMapping=='oneToMany'})}
                      onClick={handleToggleOverlap}
                    >
                      {t('beds.roomTypesAndRooms.allowOverlappingDates')}
                    </div>
                  </div>
                </div>
              </div>}

              {room.advancedMapping=='oneToMany' && <div className="row">
                <div className={classes.infoRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    When counter receives a booking for one room type, it will automatically close the availability of other room types 
                    tagged to this room. However, when you sell more than 100% of your rooms, there is a possibility that a group could 
                    simultaneously book multiple beds and cause an overbooking. Handle with care!
                  </div>
                </div>
              </div>}

              {/* VIEW / EDIT EXISTING ROOM TYPE SETTINGS */}
              {/* --------------------------------------------------------- */}
              {inventory && room.primaryRoomTypeID && room.mapping && room.mapping.length > 0 && 
                selectDistinct(room.mapping).map(roomTypeID => 
                  editList.indexOf(roomTypeID) > -1 ? 
                    <RoomTypeForm 
                      key={roomTypeID}
                      roomTypeID={roomTypeID}
                      roomTypeOptions={roomTypeOptions}
                      handleAddBack={handleAddBack}
                      handleSplice={handleSplice}
                      room={room}
                      handleUpdateRoom={handleUpdateRoom}
                      handleSaveLink={handleEditLink}
                      handleCancelLink={handleCancelEdit}
                    /> :
                    <ExistingRoomMap 
                      key={roomTypeID}
                      roomTypeID={roomTypeID} 
                      room={room} 
                      roomTypes={roomTypes}
                      handleEdit={handleEditClick}
                      handleDelete={handleDeleteLink}
                    />
              )}

              {/* BUTTON -- CREATE ADVANCED ROOM TYPE CONFIG */}
              {/* --------------------------------------------------------- */}
              {inventory && room.primaryRoomTypeID && !newLinkageInProgress && roomTypeOptions.length>0 && <div className="row">
                <div className={classes.link} onClick={() => setNewLinkageInProgress(true)}>Add Another Room Type</div>
              </div>}

              {/* FORM -- CREATE ADVANCED ROOM TYPE CONFIG */}
              {/* --------------------------------------------------------- */}
              {inventory && newLinkageInProgress &&   
                <RoomTypeForm 
                  roomTypeOptions={roomTypeOptions} 
                  handleAddBack={handleAddBack}
                  handleSplice={handleSplice}
                  room={room}
                  handleSaveLink={handleSaveNewLink}
                  handleCancelLink={handleCancelLink}
                />
              }


            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes
})

export default withTranslation()(connect(mapStateToProps)(Inventory)); 