import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import EmailTags from '../utils/trix-plugins/EmailTags';

const useStyles = makeStyles(theme =>({
    header: {
        display: 'flex',
        borderBottom: '1px solid #dedede',
        position: 'relative',
        height: '40px',
    },
    emailTagsClass:{
        position: 'absolute',
        right: 0,
        width: '75px',
    },
    inputElement: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '400px',
        border: 'none',
        resize: 'none',
        padding: '10px',
        '&:focus':{
            outline: 'none'
        }
    }
}));

function getInputSelection(el) {
    var start = 0, end = 0, normalizedValue, range,
        textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            // Create a working TextRange that lives only in the input
            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            // Check if the start and end of the selection are at the very end
            // of the input, since moveStart/moveEnd doesn't return what we want
            // in those cases
            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                start = end = len;
            } else {
                start = -textInputRange.moveStart("character", -len);
                start += normalizedValue.slice(0, start).split("\n").length - 1;

                if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                    end = len;
                } else {
                    end = -textInputRange.moveEnd("character", -len);
                    end += normalizedValue.slice(0, end).split("\n").length - 1;
                }
            }
        }
    }

    return {
        start: start,
        end: end
    };
}

function replaceSelectedText(el, text, callback) {
    var sel = getInputSelection(el), val = el.value;
    el.value = val.slice(0, sel.start) + text + val.slice(sel.end);
    callback(el.value);
}

const EmailHtmlInput = props =>{

    const classes = useStyles();
    const textInput = useRef(null);

    const emailTagHandler = tag =>{
        var el = textInput.current;
        replaceSelectedText(el, tag, props.handleHtmlOnChange);
    }

    const onChangeHandler = event =>{
        const value = event.target.value;
        props.handleHtmlOnChange(value);
    }

    // const handleOnExit = e => {
    //     const node = textInput.current;
    //     var sel = getInputSelection(node);
    //     node.select(sel.start, sel.end);
    // }

    return(
        <div>
            <div className={classes.header}>
                <EmailTags 
                    className={classes.emailTagsClass} 
                    onSelectHandler={emailTagHandler}
                />
            </div>
            <textarea 
                defaultValue={props.initValue}
                ref={textInput}
                className={classes.inputElement}
                spellCheck="false"
                onChange={onChangeHandler}
                // onBlur={handleOnExit}
                placeholder="Email body goes here, enusre it's properly formatted HTML."
            />
        </div>
    )
}

export default EmailHtmlInput;