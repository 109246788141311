import React from "react";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { inviteSpaceUser } from "../../../redux/actions/auth/invite";

import { Button, Grid, InputBase, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import Snackbar from "@material-ui/core/Snackbar";
import { WORKSPACE_URL } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  container: {
    background: "#ffffff",
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  roomTypeContainer: {},
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 30,
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
  },
  field: {
    background: "#F8F9FB",
    borderRadius: "5px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  number: {
    width: "25%",
  },
  currencyField: {
    alignItems: "center",
    display: "flex",
    width: "40%",
  },
  currency: {
    marginRight: 10,
  },
  subTitle: {
    fontSize: "1.1rem",
    color: "#999999",
    marginTop: 10,
  },
  noLink: {
    fontSize: "1.2rem",
    marginTop: 5,
  },
  loadingState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  link: {
    color: "#66A859",
    fontSize: "1.1rem",
    fontWeight: 600,
    wordBreak: "break-all",
    width: "80%",
  },
  copyButton: {
    background: "#ffffff",
    border: "1px solid #BDBDBD",
    borderRadius: "5px",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  placeholder: {
    background: "#F5F1ED",
    borderRadius: "15px",
    height: 80,
    marginRight: 15,
    overflow: "hidden",
    width: 80,
  },
  error: {
    color: "red",
    fontSize: "1.2rem",
    paddingTop: 10,
  },
  save: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  cancelButton: {
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  bin: {
    height: 20,
    opacity: 0.5,
    width: 20,
  },
  saveButton: {
    color: "#999999",
    borderRadius: 5,
    width: "120px",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  input: {
    display: "none",
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    background: "#F5F1ED",
    border: 0,
    borderRadius: 15,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    minWidth: "180px",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const userTypesArray = [
  { value: "member", label: "Member", description: "" },
  // { value: 'manager', label: 'Level 2 - Reservations Manager', description: 'User can update reservation information and view basic reports. Not allowed to update financial info'},
  // { value: 'finance', label: 'Level 3 - Finance Manager', description: 'User can update financial records such as payments, over/short and view advanced reports'},
  // { value: 'property', label: 'Level 4 - Property Manager', description: 'User can update automation settings, advanced reports, team and property information'},
  { value: "admin", label: "Admin", description: "" },
];

const AddTeamMember = (props) => {
  const classes = useStyles();
  const { currentSpaceID, t } = props;
  const [form, setForm] = React.useState({ role: userTypesArray[0].value });
  const [error, setError] = React.useState({ name: false, email: false });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.value !== "") {
      setError({ ...error, [e.target.name]: false });
    }
  };

  const handleSelect = (selected) => {
    setForm({ ...form, role: selected.value });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ["name", "email"];
    mandatoryFields.map((item) => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    });
    if (foundError) {
      setError({ ...error, ...errorStep });
      return false;
    } else return true;
  };

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      console.log(form);
      props.dispatch(inviteSpaceUser(currentSpaceID, form));
      props.handleCancel(props.index);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={5} className={classes.roomTypeContainer}>
          <Grid item xs={12} sm={12}>
            <div className={classes.row}>
              <div className={classes.label}>{t("fields.name")}</div>
              <div
                className={classes.field}
                style={{
                  border: error.name ? "1px solid red" : "1px solid #ffffff",
                }}
              >
                <InputBase
                  id="name"
                  name="name"
                  fullWidth
                  type="text"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {error.name && (
                <div className={classes.error}>{t("members.nameError")}</div>
              )}
            </div>

            <div className={classes.row} style={{ marginBottom: 0 }}>
              <div className={classes.label}>{t("fields.email")}</div>
              <div
                className={classes.field}
                style={{
                  border: error.email ? "1px solid red" : "1px solid #ffffff",
                }}
              >
                <InputBase
                  id="email"
                  name="email"
                  fullWidth
                  type="text"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {error.email && (
                <div className={classes.error}>{t("members.emailError")}</div>
              )}
            </div>

            {/* <div className={classes.row}>
              <div className={classes.label}>Role</div>
              <div className={classes.select}>
                <Select
                  defaultValue={userTypesArray.find(e => e.value===form.role)}
                  onChange={handleSelect}
                  options={userTypesArray}
                  styles={selectStyle}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>
              {form.role && <div className={classes.subTitle}>{userTypesArray.find(e => e.value===form.role).description}</div>}
            </div> */}
          </Grid>
        </Grid>
      </div>

      <div className={classes.save}>
        {!props.modalView && (
          <Button
            className={classes.cancelButton}
            onClick={() => props.handleCancel(props.index)}
          >
            <img src="/images/icons/bin.png" className={classes.bin} />
          </Button>
        )}
        <Button
          className={classes.saveButton}
          variant="outlined"
          onClick={() => handleSubmit()}
        >
          {t("onboarding.team.sendInvite")}
        </Button>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key="top-center"
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message="Link copied to clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  inviteLink: state.auth.inviteLink,
  loadingInvite: state.loading.GET_INVITE_LINK,
});

export default withTranslation()(connect(mapStateToProps)(AddTeamMember));
