import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import { getPublishedExperienceCount } from "./Activity_Flow/activityHelper";

import RateSnapshot from "./RateSnapshot";
import ActivityList from "./Activities";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    padding: 20,
    width: "calc(100% - 40px)",
  },
  container: {
    background: "transparent",
    borderRadius: "5px",
    border: "0px solid #000000",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
    minHeight: 250,
    [theme.breakpoints.down("md")]: {
      minHeight: 200,
    },
  },
}));

const ManagePage = (props) => {
  const classes = useStyles();
  const { activityList } = props;
  const publishCount = getPublishedExperienceCount(activityList);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* ----------------------------------------------------------------- */}
        {/* RATES AND AVAILABILITY */}
        {/* ----------------------------------------------------------------- */}

        {publishCount > 0 && (
          <AccessController
            requiredPermission={MODULE.RATES_AND_AVAILABILITY.ID}
          >
            {
              <Grid item xs={12} sm={6} lg={4}>
                <div className={classes.container}>
                  <RateSnapshot currentSpace={null} />
                </div>
              </Grid>
            }
          </AccessController>
        )}

        {/* ----------------------------------------------------------------- */}
        {/* ACTIVITY LISTING */}
        {/* ----------------------------------------------------------------- */}
        <AccessController requiredPermission={MODULE.ROOMS.ID}>
          {
            <Grid item xs={12} sm={12} xl={12}>
              <div className={classes.container}>
                <ActivityList />
              </div>
            </Grid>
          }
        </AccessController>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { experiences } = state;
  return {
    activityList: experiences.activityList || {},
  };
};

export default connect(mapStateToProps)(ManagePage);
