import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import ConfirmForm from '../common/ConfirmForm';

import IMAGES from '../../constants/images';

const styles = theme => ({
    item: {
        width: 240,
        paddingRight: 20,
    },
    uploadContainer: {
        marginBottom: '10px'
    },
    drop: {
        alignItems: 'center',
        border: '2px dashed #dddddd',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
        padding: theme.spacing(3),
        '&:focus': {
            outline: 'none',
        },
        '& img': {
            width: 50,
        },
    },
    hide: {
        display: 'none'
    },

    previewContainer: {
        width: '100%',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
    },
    closeIcon: {
        top: '-12px',
        color: '#666666',
        right: '-12px',
        cursor: 'pointer',
        padding: '5px',
        position: 'absolute',
        background: '#dddddd',
        transition: '150ms',
        borderRadius: '50%',
        width: '17px',
        height: '17px',
    },
    warning: {
        color: '#ff9800',
        fontSize: '1.2rem',
        marginTop: 10
    },
    confirmContainer: {
        marginTop: '5px'
    }
});

const ImageUploadItem = (props) => {
    const { classes, image, onChange, productID, currentSpace, dispatch } = props;
    const [preview, setPreview] = React.useState(props.selectedFile ? URL.createObjectURL(props.selectedFile) : (image || null));
    const [selectedFile, setSelectedFile] = React.useState();
    const [imgError, setImgError] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false)

    React.useEffect(() => {
        if (!selectedFile) {
        //   setPreview(undefined)
          return
        }
    
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
      }, [selectedFile]);


    const imageUploadHandler = event => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            setSelectedFile(undefined)
            return
          }
          if ((files[0].size / (1024 * 1024)) < 5) {
            setSelectedFile(files[0])
            onChange(files[0])
            setImgError(false)
          } else {
            setImgError(true)
            // console.log('Please upload file with size less than 5MB', (files[0].size / (1024 * 1024)))
          }
    }

    const deleteImageHandler = () => {
        setSelectedFile(undefined)
        setPreview(undefined)
        onChange(undefined)
    }

    return (
        <div className={classes.item}>

            {/* ----------------IMAGE UPLOAD BUTTON----------------------- */}
            {!preview && (
                <div className={classes.uploadContainer}>
                    <input
                        accept="image/*"
                        className={classes.hide}
                        id={'photo'}
                        type="file"
                        onChange={imageUploadHandler}
                    />
                    <label htmlFor={'photo'}>
                        <div className={classes.drop}>
                            <img src={IMAGES.ICONS.image} />
                        </div>
                    </label>
                </div>
            )}
            
            {imgError && <span className={classes.warning}>The selected file is too large. Please select a file less than 5MB</span>}

            {/* ----------------IMAGE PREVIEW----------------------- */}
            {preview && (
                <div className={classes.previewContainer}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={() => setConfirmOpen(true)}
                    />
                    <img src={preview} />
                </div>
            )}

            <div className={classes.confirmContainer}>
                <ConfirmForm
                    title="Delete Photo?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onConfirm={deleteImageHandler}
                >
                    Are you sure you would like to remove this photo?
                    </ConfirmForm>
            </div>

        </div>
    )

}

const mapStateToProps = state => {
    const { spaces, dashboard } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace] || {},
    }
}


export default withStyles(styles)(connect(mapStateToProps)(ImageUploadItem));