import React from 'react';
import { useDrag } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { toTitleCase } from "../utils/wordUtils";
import { fetchDateFormat, DATE_FORMATS, fetchCurrencyFormat } from "../../utils/utility";
import { calculateBookingDates } from './calenderReservationHelper';

import { RESERVATION_STATUS } from '../../utils/constants';
import { CalendarColors } from './calendar_color_config/constant';
import IMAGES from '../../constants/images';
import ReservationDetailsTooltip from './ReservationDetailsTooltip';

const useStyles = makeStyles(theme =>({
    roomBubble: {
        background: '#F0EDEB',
        borderRadius: '10px',
        cursor: 'pointer',

        boxSizing: 'content-box',
        padding: '10px',
        margin: '0px 1px',

        color: '#FFFFFF',
        fontSize: '1.1rem',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'space-between',

        '&:before': {
            zIndex: -1
        },
        '&:hover': {
            background: '#F2F2F2',
            color: '#FFFFFF',
            opacity: 0.7
        },
        '& span':{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    },
    rightEdge: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    leftEdge: {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
    },
    lockIcon: {
        width: 10,
        height: 10
    },
    dragStatus:{
        background: '#dedede9c'
    }
}));

const ReservationBlock = props =>{
    // const [didDrop, setDidDrop] = useState(false);
    const { t, currentProperty } = props;
    const classes = useStyles();
    const [{ isDragging }, drag] = useDrag({
        item: { type: `res_${props.dragId}`, resID: props.resID, duration: props.bookingDuration, resWidth: props.width },
        canDrag: props.dragEnabled, //canDrag: props.dragEnabled && !checkDateInPast(props.checkIn) (REMOVED).
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            const didDropRes = monitor.didDrop();
            if (didDropRes && item && (dropResult.selectedBedID || dropResult.isUnallocated)) {
                const {
                    selectedBedID,
                    selectedBookingDate, 
                    selectedRoomName, 
                    selectedBedName  
                } = dropResult;
                const { selectedCheckIn, selectedCheckOut } = calculateBookingDates(selectedBookingDate, props.bookingDuration);
                const updateData = {
                    reservationID: props.resID,
                    updates: {
                        accommodationID: props.accommodationID,
                        bedID: selectedBedID,
                        unallocate: dropResult.isUnallocated,
                        checkIn: fetchDateFormat(selectedCheckIn, DATE_FORMATS.DEFAULT_DATE),
                        checkOut: fetchDateFormat(selectedCheckOut, DATE_FORMATS.DEFAULT_DATE)
                    }
                }

                const displayDateFormat = 'dddd MMMM DD';
                const averageAmount = Math.ceil(props.totalAmount/props.bookingDuration);
                const confirmData = {
                    oldReservationData: {
                        checkinDate: fetchDateFormat(props.checkIn, displayDateFormat),
                        checkoutDate: fetchDateFormat(props.checkOut, displayDateFormat),
                        roomName: props.roomName,
                        bedName: props.bedName
                    },
                    newReservationData: {
                        checkinDate: fetchDateFormat(selectedCheckIn, displayDateFormat),
                        checkoutDate: fetchDateFormat(selectedCheckOut, displayDateFormat),
                        roomName: selectedRoomName,
                        bedName: selectedBedName
                    },
                    totalAmount: props.totalAmount,
                    averageAmount: averageAmount,
                    updateData: { ...updateData}
                };
                // setDidDrop(true);
                if (dropResult.isUnallocated) {
                    const updateResData = { ...updateData, sustainRates: true }
                    props.updateReservation(updateResData)
                } else {
                    props.handleConfirmShow(confirmData)
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
    });

    const dateBlockStyle = {
        width: `${props.blockWidth}%`,
        position: 'relative',
        borderRadius: isDragging ? '20px' : '0px'
    };

    const reservationBlockStyle ={
        position: 'absolute',
        zIndex: 999,
        width: `${props.width}%`,
        pointerEvents: isDragging ? 'none' : 'all',
    };

    const getReservationColor = type =>{

        if(props.status == RESERVATION_STATUS.BLOCK) return CalendarColors.default.color;

        const configType = type ? type : CalendarColors.default.id;
        
        let option = '';
        
        switch(configType){
            case CalendarColors.resvStatus.id:
                option = props.status;
                break;

            case CalendarColors.bookingSource.id:
                option = props.bookingSource;
                break;
            
            case CalendarColors.balance.id:
                const balance = Math.round((props.balance + Number.EPSILON) * 100) / 100;
                option = balance > 0 ? 'unpaid' : 'paid';
                break;

            case CalendarColors.notes.id:
                option = props.noteCount > 0 ? 'yes' : 'noNotes';
                break;

            default: 
                return CalendarColors.default.color;
        }

        const { colorConfigInfo } = props;
        const groupTypeData = colorConfigInfo[configType];
    
        if(!groupTypeData.colorInfo[option]) return CalendarColors.default.color;

        const groupOptionInfo = groupTypeData.colorInfo[option];

        return groupOptionInfo.color; 
    }

    const colorConfig = props.uiSettings && props.uiSettings.calendarColour || '';

    const viewReservationHandler = (resID, status) => {
        props.handleViewReservation(resID, status)
    }
    const updateStatusHandler = (resID, status) => {
        props.updateStatusHandler(resID, status)
    }

    const fetchNextStep = (current) => {
        let next = null;
        if ((current === RESERVATION_STATUS.CONFIRMED || current === RESERVATION_STATUS.ONLINE_CHECKED_IN)) {
            next = RESERVATION_STATUS.IN_HOUSE;
        } else if (current === RESERVATION_STATUS.IN_HOUSE) {
            next = RESERVATION_STATUS.CHECKED_OUT;
        } else if (current === RESERVATION_STATUS.CHECKED_OUT) {
            next = null;
        } else if (current === RESERVATION_STATUS.CANCELLED || current === RESERVATION_STATUS.NO_SHOW) {
            next = RESERVATION_STATUS.CONFIRMED;
        }
        return next;
    }

    const nextStep = fetchNextStep(props.status)
    
    return(
        <div  style={dateBlockStyle} >
            <ReservationDetailsTooltip
                isDragging={isDragging}
                customProps={props.tooltip ? { 
                    ...props, 
                    viewReservationHandler, 
                    updateStatusHandler,
                    nextStep
                } : ''}
            >
                <div
                    ref={drag}
                    style={reservationBlockStyle}
                >
                        <>
                            {props.children}
                            <div 
                                className={clsx({
                                    [classes.roomBubble]: true,
                                    [classes.rightEdge]: props.rightEdge,
                                    [classes.leftEdge]: props.leftEdge,
                                    [classes.dragStatus]: isDragging
                                })}
                                style={{ background: getReservationColor(colorConfig)} }
                                onClick={() => props.handleViewReservation(props.resID, props.status)}
                                title={props.guestNameLabel ? null : props.bedInfo.name}
                            >
                                <span>{props.guestNameLabel ? toTitleCase(props.guestName) :  props.bedInfo.name }</span>
                            {props.allocationLock && <img className={classes.lockIcon} src={IMAGES.ICONS.lock} />}
                            </div>
                        </>
                </div>
            </ReservationDetailsTooltip>
        </div>
    )
}

const mapStateToProps = state =>{
    const { userUIsettings, spaces,  dashboard } = state;
    const currentSpace = spaces[dashboard.currentSpace];
    const currency = fetchCurrencyFormat(currentSpace.currency || null);
    return{
        uiSettings: userUIsettings[currentSpace._id],
        currency: currency,
        currentProperty: state.property,
    }
}

export default withTranslation()(connect(mapStateToProps)(ReservationBlock));
