import React from 'react';
import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles (theme => ({
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 10px #F2F2F2',
    borderRadius: 25,
    display: 'flex',
    width: 150,
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  }
}))

const Toggle = (props) => {
  const classes = useStyles();
  const { toggle, onText, offText, handleOn, handleOff, className } = props;
  
  return (
    <div className={clsx(className, classes.toggle)}>
      <div 
        className={clsx(classes.toggleButton, classes.left)} 
        style={{background: toggle===false ? '#F2F2F2' : '#FFFFFF'}}
        onClick={handleOff}
      >
        {offText}
      </div>
      <div 
        className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: toggle===true})}
        onClick={handleOn}
      >
        {onText}
      </div>
    </div>
  )
}

export default Toggle;