import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Button, Grid, makeStyles, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MappingItem from './MappingItem';
import { CHANNEL } from '../../utils/channels';
import ConfirmForm from '../common/ConfirmForm';
import { withTranslation } from 'react-i18next'
import { fetchRefreshTiming } from '../../redux/actions/channelMapping/channelRefresh';

const useStyles = makeStyles(theme => ({
  channelContainer: {
    // background: '#F2F2F2',
    // border: '1px solid #ffffff',
    // borderRadius: '10px',
    // boxShadow: '0 1px 30px 0 #E0E0E0',
    marginBottom: 20,
    padding: theme.spacing(1),
    width: 'calc(100% - 16px)',
  },
  error: {
    border: '1px solid red',
  },
  tab: {
    alignItems: 'center',
    borderRadius: '15px',
    color: '#999999',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 20px)',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: theme.spacing(2),
    paddingLeft: 20,
  },
  mappingSection: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: 80,
  },
  selected: {
    background: '#8CB2A4',
    color: '#333333',
    cursor: 'auto'
  },
  channelName: {
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600
  },
  countContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  count: {
    color: '#999999',
    fontSize: '2rem',
    fontWeight: 600,
  },
  selectedCount: {
    // color: '#333333',
    // background: '#8CB2A4',
  },
  startMappingButton: {
    background: '#ffffff',
    borderColor: '#ffffff',
    boxShadow: '0 1px 20px 0 #E0E0E0',
    color: '#999999',
    fontSize: '1.2rem',
    paddingLeft: 12,
    paddingRight: 12,
    '&:hover': {
      background: '#ffffff',
    }
  },
  subLabel: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.1rem',
    fontWeight: 600,
    marginTop: 8,
  },
  roomItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
  },
  saveButton: {
    background: '#ffffff',
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      background: '#ffffff',
    }
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: '#ffffff'
  },
  actionIcon: {
    cursor: 'pointer',
    padding: 5,
    color: '#000000',
    '&:hover': {
      background: '#dddddd',
      borderRadius: '50%'
    }
  },
  disabledIcon: {
    cursor: 'pointer',
    color: '#999999',
    borderRadius: '50%',
    background: '#dddddd'
  }
}))

const Channel = props => {
  const classes = useStyles();
  const { currentSpace, item: channelRoomTypeList, roomTypes, mapping, channelType: channelName, t } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [refreshTiming, setRefreshTiming] = React.useState({})

  React.useEffect(() => {
    const channelTimer = props.dispatch(fetchRefreshTiming(currentSpace.propertyID))
    channelTimer.then((res) => {
      if(res) setRefreshTiming(res)
    }).catch(err => {
      setRefreshTiming({})
    })
  }, [])

  React.useEffect(() => {
    function callTimer() {
      const channelTimer = props.dispatch(fetchRefreshTiming(currentSpace.propertyID))
      channelTimer.then((res) => {
        if(res) setRefreshTiming(res)
      }).catch(err => {
        setRefreshTiming({})
      });
    }

    const timer = setInterval(callTimer, 60000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const allowRefresh = (roomTypeID) => {
    let timings = refreshTiming[channelName]
    if(!timings)
      return { status: true }

    const now = moment()
    let diff
    if(!roomTypeID) {
      const lastRefresh = moment(timings.lastRefresh)
      diff = now.diff(lastRefresh, 'minutes')
    }
    else {
      let roomType = timings.roomTypes.find((a) => String(a.roomTypeID) === String(roomTypeID))
      const lastRefresh = roomType ? moment(roomType.lastRefresh) : moment(timings.lastRefresh)
      //const lastRefresh = moment(roomType.lastRefresh)
      diff = now.diff(lastRefresh, 'minutes')
    }

    if(diff < 30)
      return { status: false, diff: 30 - diff }
    else
      return { status: true }
  }


  const fetchToolTip = (diff) => {
    return `You can refresh in another ${diff} minutes`
  } 

  return (
    <div
      className={
        clsx({
          [classes.channelContainer]: true,
          [classes.selectedCount]: true
        })
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.tab}>
            <div className={classes.channelName} style={{ color: '#000000' }}></div>
            {CHANNEL[channelName].channelRefresh && (allowRefresh(null).status
              ? <RefreshIcon onClick={() => props.handleRefresh()} className={classes.actionIcon} />
              : <Tooltip title={fetchToolTip(allowRefresh(null).diff)}><RefreshIcon className={classes.disabledIcon} /></Tooltip>
            )}
          </div>
        </Grid>
      </Grid>

      <div className={classes.roomItem}>
        {Object.keys(channelRoomTypeList).map((key, index) =>
          (<MappingItem
            key={index}
            channelRoomTypeID={key}
            channelRoomType={channelRoomTypeList[key]}
            mapping={mapping}
            channelName={channelName}
            mappingRefresh={CHANNEL[channelName].mappingRefresh}
            handleRefresh={props.handleRefresh}
            handleMapping={props.handleMapping}
            handleDeleteMapping={props.handleDeleteMapping}
            advancedRatePlanMapping={CHANNEL[channelName].advancedRatePlanMapping}
            allowRefresh={allowRefresh}
            fetchToolTip={fetchToolTip}
          />)
        )}
      </div>

      {CHANNEL[channelName].allowDeletePropertyMapping &&
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.tab}>
              <ConfirmForm
                title={t('beds.channelsSnapshot.deleteConnection.formTitle')}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={() => props.handleDeletePropertyMapping(channelName)}
              >
              {t('beds.channelsSnapshot.deleteConnection.formText')}
              </ConfirmForm>
            {!confirmOpen &&
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.deleteButton}
                onClick={() => setConfirmOpen(true)}>{t('beds.channelsSnapshot.deleteConnection.button')}</Button>}
            </div>
          </Grid>
        </Grid>
      }

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(Channel))