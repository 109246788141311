import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { updateHWMigrationInfo } from '../../../redux/actions/hwMigration/hwMigration';

const useStyles = makeStyles(theme =>({
    root: {
        // border: "1px solid blue",
        margin: "0px auto",
        maxWidth: "300px",
        padding: "10px 0px",
    },
    description: {
        color: "#666666",
        fontSize: "1.2rem",
        marginBottom: "40px",
        lineHeight: "15px",
    },
    selectBtn: {
        color: "#000",
        width: "100%",
        border: "1px solid #ebebed",
        padding: "8px",
        borderRadius: "5px",
        marginBottom: "15px",
        backgroundColor: "#f2f2f2",
        fontWeight: 600,
        fontSize: "1.3rem",

        '&.selected':{
            backgroundColor: '#5dbe7b',
            color: '#fff'
        }
    }
}));

const CHANNELS = [
    { id: 'hostelworld', label: 'Hostelworld' },
    { id: 'Airbnb', label: 'Airbnb' },
    { id: 'Booking.com', label: 'Booking.com' },
    { id: 'Expedia', label: 'Expedia' },
    { id: 'Agoda', label: 'Agoda' },
    { id: 'Booking Engine', label: 'Your own website' },
    { id: 'Other', label: 'Other' }
]

const ChannelDetails = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { nextStep, stepInfo } = props;
    const [selectedChannels, setSelectedChannels] = useState([]);

    useEffect(() =>{
        if(stepInfo.channels) setSelectedChannels([ ...stepInfo.channels ]);
    }, [stepInfo]);

    const handleChannelSelect = (channelID, isSelected) =>{

        if(channelID == 'hostelworld') return; 

        if(isSelected){
            const updatedSelection = selectedChannels.filter(id => id !== channelID);
            setSelectedChannels([ ...updatedSelection ]);
            return;
        }

        setSelectedChannels([ ...selectedChannels, channelID ]);
    }

    const submitHandler = () =>{
        const { activeStep, dispatch, updateAPIParams } = props;
        
        const data = { channels: [ ...selectedChannels ] };

        dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data))
        .then(response =>{
            if(response.ongoingStep) nextStep(); 
        });
    } 

    return (
        <div className={classes.root}>
            <div className="title">{t('hwMigration.title.step4')}</div>

            {CHANNELS.map((data, index) =>{
                const isSelected = selectedChannels.includes(data.id);
                return (
                    <Button 
                        key={`channel${index}`}
                        className={clsx(classes.selectBtn, { selected: isSelected || data.id == 'hostelworld' })}
                        onClick={() =>handleChannelSelect(data.id, isSelected)}
                    >
                        {data.label}
                    </Button>
                )
            })}

            <Button 
                onClick={submitHandler}
                className="nextButton"
            >
                {t('actions.next')}
            </Button>

        </div>
    )
}

const mapStateToProps = state =>{
    const { hwMigration } = state;
    return { 
        stepInfo: hwMigration?.step4 || {}
    }
}

export default connect(mapStateToProps)(ChannelDetails);