import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import RatePlanRow from './RatePlanRow';
import AvailabilityRow from './AvailabilityRow';
import DefaultRatePlan from './DefaultRatePlan';
import NewRatePlan from './NewRatePlan';
import { CheckRatePlanType, RATE_PLAN_FORM as FORM } from '../helper';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    // borderBottom: '1px solid #eeeeee',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    marginBottom: 20,
    paddingTop: 10,
  },
  container: {
    paddingBottom: 15,
  },
  roomType: {
    fontSize: '1.4rem',
    fontWeight: 600,
    overflow: 'hidden',
    paddingBottom: theme.spacing(1),
    paddingTop: 10,
    paddingLeft: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem'
    },
  },
  rowContainer: {
    padding: 10,
    display: 'flex'
  },
  button: {
    color: '#666666',
    padding: 10,
    fontSize: '1.2rem',
    background: '#E2E2E2',
    borderRadius: 5,
    marginRight: 10,
    '&:hover': {
      background: '#E2E2E2',
      fontWeight: 600
    }
  }
}))

const showNewRate = (plans) => {
  if ([FORM.BB.code, FORM.BB_NRR.code, FORM.NRR.code].every(val => plans.includes(val))) return false
  if ([FORM.BB.code, FORM.BB_NRR.code, FORM.NRR_DERIVED.code].every(val => plans.includes(val))) return false
  return true;
}

const AddRatePlanRow = (props) => {
  const classes = useStyles();
  const { ratePlans, roomTypeRatePlans = [] } = props;
  const ratePlanCodes = roomTypeRatePlans.map(plan => {
    let data = ratePlans?.[plan.id] || {};

    if (CheckRatePlanType(FORM.STANDARD.code, data)) return null;

    if (CheckRatePlanType(FORM.NRR.code, data)) return FORM.NRR.code;

    if (CheckRatePlanType(FORM.NRR_DERIVED.code, data)) return FORM.NRR_DERIVED.code;

    if (CheckRatePlanType(FORM.BB_NRR.code, data)) return FORM.BB_NRR.code;

    if (CheckRatePlanType(FORM.BB.code, data)) return FORM.BB.code;

  }).filter(code => code)

  const [newRate, setNewRate] = React.useState(false)
  const showRateButton = showNewRate(ratePlanCodes);

  return (
    <div className={classes.rowContainer}>
      {showRateButton && !newRate &&
        <Button className={classes.button} onClick={() => setNewRate(true)}>New Rate</Button>
      }
      {newRate &&
        <React.Fragment>
          <Button className={classes.button} onClick={() => setNewRate(false)}>Close</Button>
          {!(ratePlanCodes.includes(FORM.NRR.code) || ratePlanCodes.includes(FORM.NRR_DERIVED.code)) &&
            <Button className={classes.button} onClick={() => props.setOpenFormHandler(FORM.NRR.code)}>{FORM.NRR.label}</Button>
          }
          {!ratePlanCodes.includes(FORM.BB.code) &&
            <Button className={classes.button} onClick={() => props.setOpenFormHandler(FORM.BB.code)}>{FORM.BB.label}</Button>
          }
          {!ratePlanCodes.includes(FORM.BB_NRR.code) &&
            <Button className={classes.button} onClick={() => props.setOpenFormHandler(FORM.BB_NRR.code)}>{FORM.BB_NRR.label}</Button>
          }
        </React.Fragment>
      }
    </div>
  )
}


const RoomTypeRate = (props) => {
  const classes = useStyles();
  const { roomType, range, roomTypeID, numberOfDays, rate, ratePlans } = props;
  const [openForm, setOpenForm] = React.useState()
  const [selectedRatePlanID, setSelectedRatePlanID] = React.useState(null)
  const parentRatePlanID = rate?.rateplans?.map(plan => { if (ratePlans?.[plan.id]?.default) return plan.id }).filter(id => id)?.[0];

  const updateRatePlanHandler = (id) => {
    setSelectedRatePlanID(id)
    let plan = ratePlans?.[id] || {};
    let type;

    if (CheckRatePlanType(FORM.STANDARD.code, plan)) type = FORM.STANDARD.code;

    if (CheckRatePlanType(FORM.NRR.code, plan)) type = FORM.NRR.code;

    if (CheckRatePlanType(FORM.NRR_DERIVED.code, plan)) type = FORM.NRR_DERIVED.code;

    if (CheckRatePlanType(FORM.BB_NRR.code, plan)) type = FORM.BB_NRR.code;

    if (CheckRatePlanType(FORM.BB.code, plan)) type = FORM.BB.code;

    if (CheckRatePlanType(FORM.BB_DERIVED.code, plan)) { /*test*/ }

    if (CheckRatePlanType(FORM.BB_NRR_DERIVED.code, plan)) { /*test*/ }

    if (type) setOpenForm(type)
  }

  const setOpenFormHandler = (type) => {
    setSelectedRatePlanID(null)
    setOpenForm(type)
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.roomType}>
            {roomType.displayname} ({roomType.roomtypeid})
          </div>
        </Grid>
      </Grid>

      {rate?.availability &&
        <AvailabilityRow numberOfDays={numberOfDays} availability={rate.availability} range={range} roomType={roomType} />
      }

      {/* {!rate?.rateplans &&
        <DefaultRatePlan numberOfDays={numberOfDays} range={range}/>
      } */}

      {rate?.rateplans?.map(ratePlan =>
        <RatePlanRow
          key={ratePlan.id}
          numberOfDays={numberOfDays}
          ratePlan={ratePlan}
          roomTypeID={roomTypeID}
          roomType={roomType}
          ratePlans={ratePlans}
          range={range}
          updateRatePlanHandler={updateRatePlanHandler}
        />
      )}

      <AddRatePlanRow setOpenFormHandler={setOpenFormHandler} ratePlans={ratePlans} roomTypeRatePlans={rate?.rateplans} />

      {openForm && <NewRatePlan
        formType={openForm}
        closeModalHandler={() => setOpenFormHandler(null)}
        refreshInventory={props.refreshInventory}
        inboxRoomType={roomType}
        parentRatePlanID={parentRatePlanID}
        roomTypeRatePlans={rate?.rateplans}
        ratePlans={ratePlans}
        data={selectedRatePlanID ? { ...ratePlans[selectedRatePlanID], rateplanid: selectedRatePlanID } : {}}
      />}

    </div>
  )
}

export default RoomTypeRate;