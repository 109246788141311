import React from "react";
import { makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      left: "15px",
    },
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listSection: {
    minHeight: "calc(100vh - 230px)",
    padding: theme.spacing(3),
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "100%",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  leftAlign: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "20%",
  },
  rightAlign: {
    paddingLeft: 30,
    width: "70%",
  },
  clickable: {
    cursor: "pointer",
  },
  name: {
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  textField: {
    fontWeight: 600,
  },
  editText: {
    "& input": {
      fontSize: "1.4rem",
    },
  },
  moreIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#dddddd",
    },
  },
  cancelButton: {
    marginRight: 10,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
}));

const CashSubAccountItem = (props) => {
  const { t, data } = props;
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);

  const [form, setForm] = React.useState({
    _id: data._id,
    name: data.name,
    isActive: data.isActive,
  });
  const [error, setError] = React.useState({
    name: false,
  });
  const handleClose = () => {
    props.handleClose();
  };

  const handleCancelUpdates = () => {
    setEdit(false);
    setError({ name: false });
    setForm({
      _id: data._id,
      name: data.name,
      isActive: data.isActive,
    });
  };
  const handleSubmitUpdates = () => {
    if (form.name) {
      props.handleUpdate(form);
      setEdit(false);
    } else {
      setError({ ...error, name: true });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <BackIcon className={classes.addButton} onClick={handleClose} />
          <Typography className={classes.header}>
            {t("frontdesk.cashAccount.name")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.CASH_ACCOUNTS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listSection}>
          <div
            className={classes.container}
            style={edit ? {} : { borderBottom: "1px solid #eeeeee" }}
          >
            <div className={classes.leftAlign}>Name</div>

            {/* --------------------------------------------------------------------- */}
            {/* CASH SUB ACCOUNT NAME */}
            {/* --------------------------------------------------------------------- */}
            <div className={clsx(classes.clickable, classes.rightAlign)}>
              {!edit && (
                <div className={classes.name} onClick={() => setEdit(true)}>
                  {data.name}
                </div>
              )}
              {edit && (
                <TextField
                  id="name"
                  className={classes.textField}
                  defaultValue={data.name}
                  onChange={(e) => {
                    setError({ ...error, name: false });
                    setForm({ ...form, name: e.target.value });
                  }}
                  margin="none"
                  error={error.name}
                  fullWidth
                  autoFocus
                />
              )}
            </div>

            {/* ------------------------------------------------ */}
            {/* EDIT CASH SUB ACCOUNT */}
            {/* ------------------------------------------------ */}
            {!edit && (
              <div onClick={() => setEdit(true)}>
                <EditIcon className={classes.moreIcon} />
              </div>
            )}
          </div>

          <div className={classes.container}>
            {/* --------------------------------------------------------------------- */}
            {/* Status */}
            {/* --------------------------------------------------------------------- */}
            <div className={classes.leftAlign}>Status</div>
            <div className={classes.rightAlign}>
              {!edit && (
                <FormControlLabel
                  control={
                    <Switch
                      disabled
                      checked={form.isActive}
                      className={classes.statusField}
                      color="primary"
                    />
                  }
                  label={form.isActive ? "Active" : "Inactive"}
                  onClick={() => setEdit(true)}
                />
              )}
              {edit && (
                <FormControlLabel
                  control={
                    <Switch
                      id="isActive"
                      checked={form.isActive}
                      onChange={(e) =>
                        setForm({ ...form, isActive: e.target.checked })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={form.isActive ? "Active" : "Inactive"}
                />
              )}
            </div>
          </div>

          {/* --------------------------------------------------------------------- */}
          {/* SUBMIT / CANCEL UPDATES */}
          {/* --------------------------------------------------------------------- */}
          {edit && (
            <div className={classes.submit}>
              <Button
                size="small"
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleCancelUpdates}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={handleSubmitUpdates}
              >
                {t("actions.save")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default withTranslation()(CashSubAccountItem);
