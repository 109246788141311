const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "GET_AUTOPAY_INFO_SUCCESS":
    case "UPDATE_AUTOPAY_INFO_SUCCESS":
      return action.payload
    default:
      return state;
  }
};
