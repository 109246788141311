const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "QB_ACCOUNTS_SUCCESS":
    case "QB_PURCHASE_SUCCESS":
    case "QB_SUBCATEGORY_SUCCESS":
      return action.payload
    default:
      return state;
  }
}