import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import TodoSnapshot from "../todo/TodoSnapshot";
import TodoSingleList from "../todo/TodoSingleList";
import { addTodoCategory } from "../../redux/actions/todos/todoCategories";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
      marginTop: 20,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    paddingRight: 17,
    boxSizing: "content-box",
  },
  card: {
    borderRadius: 5,
    boxShadow: "0 3px 4px 0 #ECECEC",
    marginTop: 0,
    height: 250,
    overflowY: "hidden",
  },
  cardAction: {
    height: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: "center",
  },
  blankRoot: {
    background: "#FFFFFF",
    boxShadow: "0 3px 4px 0 #ECECEC",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  textBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 30,
  },
  boxTitle: {
    textAlign: "center",
    width: "80%",
    marginBottom: 30,
  },
  boxSubTitle: {
    fontSize: "1.5rem",
    color: "#AAAAAA",
    lineHeight: 1.5,
    marginBottom: 30,
    textAlign: "center",
    width: "80%",
  },
  boxButton: {
    background: "#FFFFFF",
    border: "1px solid #C2AE7F",
    borderRadius: 5,
    color: "#C2AE7F",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.4rem",
    justifyContent: "center",
    fontWeight: 600,
    padding: "12px 16px",
  },
  image: {
    width: "350px",
    [theme.breakpoints.up("lg")]: {
      width: "400px",
    },
  },
  addIcon: {
    fontSize: "5rem",
    marginBottom: 10,
  },
  dialog: {
    padding: theme.spacing(3),
  },
  title: {
    "& h2": {
      color: "#000000",
      fontSize: "1.8rem",
      fontWeight: 600,
    },
  },
  field: {
    alignItems: "center",
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    display: "flex",
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  submit: {
    padding: theme.spacing(3),
  },
  cancelButton: {
    color: "#000000",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  submitButton: {
    background: "#000000",
    borderRadius: 25,
    boxShadow: "none",
    fontSize: "1.4rem",
    fontWeight: 600,
    "&:hover": {
      background: "#000000",
    },
  },
}));

const TodosPage = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { todoCategories, currentSpace } = props;
  const [todoModal, setTodoModal] = React.useState("");
  const [newList, setNewList] = React.useState(false);
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleOpen = (id) => {
    setTodoModal(id);
  };

  const handleClose = () => {
    setTodoModal("");
  };

  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      setError(false);
    }
  };

  const handleCreateList = () => {
    if (name !== "") {
      props.dispatch(addTodoCategory(currentSpace._id, { name }));
      setNewList(false);
    } else setError(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          {currentSpace?.taskCategories &&
            currentSpace?.taskCategories.length === 0 && (
              <Grid item xs={12}>
                <div className={classes.blankRoot}>
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.container}
                  >
                    <Grid item xs={12} md={5} className={classes.textBox}>
                      <Typography className={classes.boxTitle} variant="h3">
                        {t("todos.multiTask")}
                      </Typography>
                      <div className={classes.boxSubTitle}>
                        {t("todos.assign")}
                      </div>
                      <div
                        className={classes.boxButton}
                        onClick={() => setNewList(true)}
                      >
                        {t("todos.createList")}
                      </div>
                    </Grid>
                    <Grid item md={6} lg={5}>
                      <img
                        className={classes.image}
                        src={IMAGES.ILLUSTRATIONS.todo}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}

          {currentSpace &&
            currentSpace.taskCategories &&
            currentSpace.taskCategories.map((item) => (
              <Grid key={item} item xs={12} sm={4} md={4} xl={3}>
                {todoCategories[item] && (
                  <TodoSnapshot
                    todoArray={todoCategories[item].tasks}
                    categoryName={todoCategories[item].name}
                    handleOpen={() => handleOpen(item)}
                  />
                )}
              </Grid>
            ))}

          {currentSpace &&
            currentSpace.taskCategories &&
            currentSpace.taskCategories.length !== 0 && (
              <Grid item xs={12} sm={4} md={4} xl={3}>
                <Card className={classes.card}>
                  <CardActionArea
                    className={classes.cardAction}
                    onClick={() => setNewList(true)}
                  >
                    <CardContent className={classes.cardContent}>
                      <AddIcon className={classes.addIcon} />
                      {t("todos.addList")}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}

          {newList && (
            <Dialog
              open={newList}
              onClose={() => setNewList(false)}
              className={classes.dialog}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle id="form-dialog-title" className={classes.title}>
                {t("todos.addList")}
              </DialogTitle>
              <DialogContent>
                <div
                  className={classes.field}
                  style={{
                    border: error ? "1px solid red" : "1px solid #ffffff",
                  }}
                >
                  <InputBase
                    id="name"
                    name="name"
                    placeholder={t("todos.listName")}
                    fullWidth
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </DialogContent>
              <DialogActions className={classes.submit}>
                <Button
                  className={classes.cancelButton}
                  onClick={() => setNewList(false)}
                  color="primary"
                >
                  {t("actions.cancel")}
                </Button>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  onClick={handleCreateList}
                  color="primary"
                >
                  {t("actions.submit")}
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {todoModal !== "" && (
            <TodoSingleList
              open={todoModal !== ""}
              handleClose={handleClose}
              categoryID={todoModal}
            />
          )}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  todoCategories: state.todoCategories,
});

export default withTranslation()(connect(mapStateToProps)(TodosPage));
