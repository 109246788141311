import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { updateSettings } from '../../redux/actions/onlineCheckin/onlineCheckin';

import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { filterDataFields } from '../../utils/utility';
import { GUEST_DOC_TYPES } from '../../common/constants/guest';

const useStyles = makeStyles( theme => ({
  row: {
  	display: 'flex',
  	flexDirection: 'column',
  	marginBottom: 30
  },
  title: {
  	fontSize: '1.5rem',
  	fontWeight: 600,
  	lineHeight: 1.3
  },
  labelTitle: {
  	fontSize: '1.4rem',
  	color: '#666666',
    fontWeight: 600,
  	marginTop: 20
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10
  },
  toggle: {
  	alignItems: 'center',
  	boxShadow: '0 1px 20px #F2F2F2',
  	borderRadius: 25,
  	display: 'flex'
  },
  toggleButton: {
  	alignItems: 'center',
  	color: '#BDBDBD',
  	cursor: 'pointer',
  	display: 'flex',
  	fontSize: '1.2rem',
  	fontWeight: 600,
  	height: 35,
  	justifyContent: 'center',
  	width: '50%'
  },
  left: {
  	borderTopLeftRadius: 25,
  	borderBottomLeftRadius: 25
  },
  right: {
  	borderTopRightRadius: 25,
  	borderBottomRightRadius: 25
  },
  selected: {
  	background: '#4F9581',
  	boxShadow: '-15px 0px 17px -7px #F2F2F2',
  	color: '#FFFFFF'
  },
  bottomMargin: {
    marginBottom: 15
  },
  alignCenter: {
    textAlign: 'center',
    marginBottom: 15
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  rootIcon: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  disabledIcon: {
    background: '#808B96',
    borderRadius: 5
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  },
  topMargin: {
    marginTop: 25
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  fieldsContainer: {
    marginTop: '40px'
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    minWidth: '180px',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

// const docType = [
//   { label: 'Visa', value: 'visa' },
//   { label: 'Passport', value: 'passport' },
//   { label: 'National ID', value: 'nationalID' }
// ];

const DOCUMENT_FIELDS = [
  { id: 'number', label: 'Number' },
  { id: 'expiry', label: 'Expiry' },
  { id: 'issuingCountry', label: 'Issuing country' },
];

const DEFAULT_SETTINGS = {
  skip: false,
  documentRequired: false,
  document: GUEST_DOC_TYPES[4].value,
  documentNumber: 'none',
  documentExpiry: 'none',
  documentIssuingCountry: 'none'
}

const ToggleSwitch = props =>{
  const classes = useStyles();
  const { toggle, handleToggle } = props;
  return (
    <div className={classes.toggle}>
      <div 
        className={clsx(classes.toggleButton, classes.left)} 
        style={{background: toggle ? '#FFFFFF' : '#F2F2F2'}}
        onClick={() => handleToggle(false)}
      >
        OFF
      </div>
      <div 
        className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: toggle===true})}
        onClick={() => handleToggle(true)}
      >
        ON
      </div>
    </div>
  )
}

function getDocTypeOptions(t) {
  return GUEST_DOC_TYPES.map(type =>({
    id: type.id,
    value: type.value,
    label: t(type.translationID)
  }))
}

const CustomerDocs = (props) => {
  const { t } = props;
  const [enableDocs, setEnableDocs] = React.useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const { currentSpace, onlineCheckin } = props;
  const [message, setMessage] = React.useState(null);
  const [isDocRequired, setIsDocRequied] = React.useState(false);
  const [showSaveBtn, setShowSaveBtn] = React.useState(false);
  const [selectedType, setType] = React.useState(GUEST_DOC_TYPES[4].value);
  const [docFields, setDocFields] =  React.useState({
    number: DEFAULT_SETTINGS.number,
    expiry: DEFAULT_SETTINGS.expiry,
    issuingCountry: DEFAULT_SETTINGS.issuingCountry
  });
  const [enableMandatoryCheck, setEnableMandatoryCheck] = React.useState(false);
  const docTypeOptions = getDocTypeOptions(t);

  useEffect(() =>{
    if(onlineCheckin?.customerDocuments){
      const filteredSettings = filterDataFields(onlineCheckin.customerDocuments, DEFAULT_SETTINGS);

      setEnableDocs(!filteredSettings.skip);
      setIsDocRequied(filteredSettings.documentRequired);
      setEnableMandatoryCheck(filteredSettings.documentRequired);

      setDocFields({
        number: filteredSettings.documentNumber,
        expiry: filteredSettings.documentExpiry,
        issuingCountry: filteredSettings.documentIssuingCountry
      });

      //Temp code to handle inconsistent document list previously created....................
      if(['visa', 'passport', 'nationalID'].includes(filteredSettings.document)){
          const documentType = GUEST_DOC_TYPES.find(type => type.id == filteredSettings.document);
          setType(documentType.value);
          return;
      }

      const documentType = GUEST_DOC_TYPES.find(type => type.value == filteredSettings.document);
      if(documentType) setType(documentType.value);
    }
  }, [onlineCheckin]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onInputUpdateHandler = () =>{
    setShowSaveBtn(true);
  }

  const enableDocHandler = isEnabled => {
    if(enableDocs && !isEnabled) {
      const data = {
        skip: !isEnabled
      }
      props.dispatch(updateSettings(currentSpace.propertyID, 'customerDocs', data))
    }
    setEnableDocs(isEnabled);
  }

  const handleDocStatus = event => {
    const isChecked = event.target.checked;
    setIsDocRequied(isChecked);

    if(!isChecked){
      setDocFields({
        number: DEFAULT_SETTINGS.number,
        expiry: DEFAULT_SETTINGS.expiry,
        issuingCountry: DEFAULT_SETTINGS.issuingCountry
      })
    }
    setEnableMandatoryCheck(isChecked);
    onInputUpdateHandler()
  }

  const setDocType = (e) => {
    setType(e.value);
    onInputUpdateHandler();
  }

  const handleSave = () => {
    const data = {
      skip: !enableDocs,
      documentRequired: isDocRequired,
      document: selectedType,
      documentIssuingCountry: docFields.issuingCountry,
      documentNumber: docFields.number,
      documentExpiry: docFields.expiry,
    }

    props.dispatch(updateSettings(currentSpace.propertyID, 'customerDocs', data))
    .then((response) => {
      if(response && response.status === 200) {
        setMessage('Customer Document settings have been saved.')
        setOpenSnackbar(true);
        setShowSaveBtn(false);
      }
      else {
        setMessage('Your settings has not been saved. Please try again!')
        setOpenSnackbar(true);
      }
    })
  }

  const onChangeDisplaySetting = event =>{
    const name = event.target.name;
    const isChecked = event.target.checked;
    const value = isChecked ? 'display' : 'none';
    setDocFields({ ...docFields, [name]: value });
    onInputUpdateHandler();
  }

  const onChangeMandatorySetting = event =>{
    const name = event.target.name;
    const isChecked = event.target.checked;
    const value = isChecked ? 'mandatory' : 'display';
    setDocFields({ ...docFields, [name]: value });
    onInputUpdateHandler();
  }

  return (
    <div>

      {/* ---------------------------TOGGLE SWITCH--------------------------------- */}
      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <div className={classes.title}>
              {t('automate.onlineCheckin.steps.customerDocs.name')}
            </div>
          </Grid>

          <Grid item xs={5}>
            <ToggleSwitch 
              toggle={enableDocs}
              handleToggle={enableDocHandler}
            />
          </Grid>
        </Grid>
      </div>

      {enableDocs && (

        <div className={classes.row}>
          {/* ---------------------------ID DOCUMENT--------------------------------- */}
          <Grid container alignItems="center">
            <Grid item className={classes.bottomMargin}xs={8}><span className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDocs.document')}</span></Grid>
            <Grid item className={classes.alignCenter} xs={4}>
              <span className={classes.label}>{t('automate.onlineCheckin.steps.customerDocs.mandatory')} : </span>
              <Checkbox
                color="primary"
                checked={isDocRequired}
                checkedIcon={<span className={classes.checkedIcon} />}
                icon={<span className={classes.icon} />}
                onChange={handleDocStatus}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Grid>
          </Grid>
          
          {/* ---------------------------DOCUMENT TYPE--------------------------------- */}
          <Grid container alignItems="center">
            <Grid item xs={7}>
              <p className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDocs.docType')}</p>
            </Grid>
            <Grid item xs={5}>
              <div className={clsx(classes.toggle, classes.select)}>
                <Select
                  value={docTypeOptions.find(item => item.value == selectedType)}
                  styles={selectStyle}
                  options={docTypeOptions}
                  onChange={(e) => setDocType(e)}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#666666',
                      primary25: '#dddddd'
                    },
                  })}
                />
              </div>
            </Grid>
          </Grid>
          
          {/* ---------------------------DOCUMENT FIELDS SETTINGS--------------------------------- */}
          <Grid container className={classes.fieldsContainer}>
            <Grid item xs={4}><span className={classes.labelTitle}>Document Fields</span></Grid>
            <Grid item className={classes.alignCenter} xs={4}><span className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDetails.display')}</span></Grid>
            <Grid item className={classes.alignCenter} xs={4}><span className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDetails.mandatory')}</span></Grid>
           
            {DOCUMENT_FIELDS.map(field =>(
              <React.Fragment key={`docField_${field.id}`}>

                <Grid item xs={4}><div className={classes.label}>{field.label}</div></Grid>

                {/* --------------DISPLAY CHECK-------------- */}
                <Grid item xs={4} className={classes.alignCenter}>
                  <Checkbox
                    color="primary"
                    name={field.id}
                    checkedIcon={<span className={classes.checkedIcon} />}
                    icon={<span className={classes.icon} />}
                    checked={['display', 'mandatory'].includes(docFields[field.id])}
                    onChange={onChangeDisplaySetting}
                  />
                </Grid>

                {/* --------------MANDATORY CHECK-------------- */}
                <Grid item xs={4} className={classes.alignCenter}>
                    <Checkbox
                      color="primary"
                      name={field.id}
                      checkedIcon={<span className={classes.checkedIcon} />}
                      icon={<span className={classes.icon} />}
                      disabled={!enableMandatoryCheck || docFields[field.id] == 'none'}
                      checked={docFields[field.id] == 'mandatory'}
                      onChange={onChangeMandatorySetting}
                    />
                </Grid>
                
                
              </React.Fragment>
            ))}
          </Grid>
          
          {/* ---------------------------SAVE BUTTON--------------------------------- */}
          {showSaveBtn && (
            <div className={classes.buttonsContainer}>
              <div className={classes.button} onClick={handleSave}>
                Save
              </div>
            </div>
          )}
        </div>
      )}

      {/* ---------------------------MESSAGE SNACKBAR--------------------------------- */}
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  onlineCheckin: state.onlineCheckin
})

export default withTranslation()(connect(mapStateToProps)(CustomerDocs))