import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";

import {
  fetchGokiRooms,
  getRoomMaps,
  createRoomMapping,
  deleteRoomMapping,
  refreshMapping,
  updateRoomMapping,
  resetError,
} from "../../redux/actions/goki/goki";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";

import Channel from "./Channel";
import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  booking: {
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&:hover": {
      background: "#eeeeee",
    },
  },
  imageContainer: {
    alignItems: "center",
    height: 100,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      height: 50,
      width: 50,
    },
  },
  bookingHeader: {
    color: "#828282",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    // minHeight: 'calc(100% - 64px)',
    // maxHeight: 'calc(100% - 64px)',
    minHeight: "310px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  email: {
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: 120,
    justifyContent: "center",
    zIndex: 2,
    "& img": {
      height: 50,
      width: 50,
    },
    "&:hover": {
      background: "#eeeeee",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "20%",
  },
  listSection: {
    // height: 'calc(100vh - 186px)',
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //   height: 'calc(100vh - 110px)',
    // },
    // position: 'relative'
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: "1.3rem",
    color: "#666666",
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  configSection: {
    position: "relative",
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
  },
  field: {
    background: "#ffffff",
    borderRadius: "10px 0px 0px 10px",
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    // marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "80%",
  },
  noLink: {
    marginTop: 15,
  },
  linkContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 25,
  },
  link: {
    "& a": {
      color: "#4F9581",
      cursor: "pointer",
      fontSize: "1.3rem",
      fontWeight: 600,
    },
  },
  copyButton: {
    background: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 1px 10px #E0E0E0",
    color: "#BDBDBD",
    cursor: "pointer",
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
  preview: {
    borderRadius: 3,
    boxShadow: "0 1px 20px #F2F2F2",
    height: "auto",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "15px",
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.4rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  textInputContainer: {
    display: "flex",
    marginTop: "10px",
  },
  disableScroll: {
    overflowY: "hidden",
  },
  noRoomsMsg: {
    display: "flex",
    alignItems: "center",
    height: "200px",
    justifyContent: "center",
    "& .message": {
      color: "#A2A2A2",
      fontSize: "1.3rem",
      lineHeight: 2,
      marginRight: 10,
    },
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      borderRadius: "50%",
    },
  },
  updateContainer: {
    display: "flex",
    alignItems: "center",
  },
  updateSection: {
    display: "flex",
    alignItems: "center",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },

  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  moreIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: theme.spacing(1),
    marginLeft: "5px",
    "&:hover": {
      background: "#dddddd",
    },
  },
  textField: {
    fontWeight: 600,
  },
  updateTitle: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
    marginRight: "10px",
  },
  refreshLoader: {
    width: "20px !important",
    height: "20px !important",
    padding: "5px",
  },
  status: {
    fontWeight: 600,
    "& span": {
      fontWeight: 500,
    },
  },
}));

const Goki = (props) => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    roomTypes,
    isLoading,
    apiKeyDisplay = "",
    gokiStatus = 0,
    hasGokiRooms,
    isRefresh,
    t,
  } = props;
  const [form, setForm] = React.useState({ gokiApiKey: "" });
  const [updateApiKey, setUpdateApiKey] = React.useState(false);
  const [showRooms, setShowRooms] = React.useState(false);
  const propertyID = currentSpace.propertyID;

  const init = () => {
    props.dispatch(getRoomMaps(propertyID));
  };

  useEffect(() => init(), []);

  useEffect(() => {
    if (gokiStatus) setShowRooms(true);
  }, [gokiStatus]);

  const fetchGokiRoomHandler = () => {
    const params = {
      apiKey: form.gokiApiKey,
    };
    const response = props.dispatch(fetchGokiRooms(propertyID, params));
    response.then((res) => {
      if (res) setUpdateApiKey(false);
    });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleRefresh = () => {
    props.dispatch(refreshMapping(propertyID));
  };
  const handleMapping = (payload, resetCounterRoomState) => {
    const response = props.dispatch(createRoomMapping(propertyID, payload));
    response.then((success) => {
      if (success) resetCounterRoomState();
    });
  };

  const handleUpdateMapping = (payload, resetCounterRoomState) => {
    const response = props.dispatch(updateRoomMapping(propertyID, payload));
    response.then((success) => {
      if (success) resetCounterRoomState();
    });
  };

  const handleDeleteMapping = (payload) => {
    props.dispatch(deleteRoomMapping(propertyID, payload));
  };

  const handleCloseError = () => {
    props.dispatch(resetError("FETCH_GOKI_ROOMS"));
    props.dispatch(resetError("RESFRESH_MAPPING"));
    props.dispatch(resetError("DELETE_ROOM_MAPPING"));
    props.dispatch(resetError("CREATE_ROOM_MAPPING"));
    props.dispatch(resetError("UPDATE_ROOM_MAPPING"));
  };

  const updateApiKeyHandler = () => {
    setUpdateApiKey(true);
  };

  const getGokiStatus = () => {
    if (!gokiStatus) return "Disconnected (Please enter Goki API Key)";
    if (gokiStatus > 0) return "Connected";
    return "";
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={clsx({ [classes.dialog]: true })}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Goki</div>
          <AccessHeaderAction moduleID={MODULE.GOKI.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {/* --------------------------------------------------------- */}
        {/* MAIN CONTENT */}
        {/* --------------------------------------------------------- */}

        <div
          className={clsx({
            [classes.listSection]: true,
            [classes.disableScroll]: isLoading,
          })}
        >
          <div className={classes.configSection}>
            {isLoading && (
              <div className={classes.loadingState}>
                <CircularProgress className={classes.loader} />
              </div>
            )}

            <div className={classes.row}>
              {/* goki key is not configured */}
              {gokiStatus == 0 && (
                <div>
                  <div className={classes.title}>
                    {t("automate.goki.apiKey")}
                  </div>
                  <div className={classes.label}>
                    {t("automate.goki.uniqueKey")}
                  </div>
                </div>
              )}

              {/* goki key is configured */}
              {gokiStatus != 0 && (
                <div className={classes.updateContainer}>
                  <div className={classes.updateTitle}>
                    {!updateApiKey && "Verified"} {t("automate.goki.apiKey")}:
                  </div>

                  {!updateApiKey && (
                    <>
                      <div>{apiKeyDisplay} </div>
                      <div onClick={updateApiKeyHandler}>
                        <EditIcon className={classes.moreIcon} />
                      </div>
                    </>
                  )}

                  {updateApiKey && (
                    <div className={classes.updateSection}>
                      <TextField
                        id="gokiApiKey"
                        name="gokiApiKey"
                        type="text"
                        className={classes.textField}
                        onChange={handleChange}
                        margin="none"
                        fullWidth
                        autoFocus
                      />
                      <div className={classes.buttonHolder}>
                        <CloseIcon
                          className={classes.button}
                          onClick={() => setUpdateApiKey(false)}
                        />
                        <DoneIcon
                          className={classes.button}
                          onClick={fetchGokiRoomHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {gokiStatus == 0 && (
                <div className={classes.textInputContainer}>
                  <div className={clsx(classes.field, classes.wide)}>
                    <InputBase
                      id="gokiApiKey"
                      name="gokiApiKey"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                    />
                  </div>
                  <Button variant="outlined" onClick={fetchGokiRoomHandler}>
                    {t("actions.add")}
                  </Button>
                </div>
              )}
            </div>

            <div className={classes.status}>
              Status: <span>{getGokiStatus()}</span>
            </div>

            {/* {gokiStatus != 0 && !hasGokiRooms && (
                  <div className={classes.noRoomsMsg}>
                    <div className="message">{t('automate.goki.createRooms')}</div>
                    <div>
                      {isRefresh ? (
                        <CircularProgress className={classes.refreshLoader} />
                      ) : (
                        <RefreshIcon onClick={() => handleRefresh()} className={classes.actionIcon} />
                      )}
                    </div>
                  </div>
                )} */}

            {/* {gokiStatus != 0 && showRooms && hasGokiRooms && (
                  <Channel 
                    roomTypes={roomTypes}
                    handleRefresh={handleRefresh}
                    handleMapping={handleMapping}
                    handleDeleteMapping={handleDeleteMapping}
                    handleUpdateMapping={handleUpdateMapping}
                    propertyID={propertyID}
                  />
                )} */}
          </div>
        </div>
      </div>

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{props.errors}</span>}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const gokiRooms = state.goki.rooms;
  const hasGokiRooms =
    gokiRooms && Object.keys(gokiRooms).length > 0 ? true : false;

  return {
    currentSpaceID: state.dashboard.currentSpace,
    currentSpace: state.spaces[state.dashboard.currentSpace],
    property: state.property,
    loadingProperty: state.loading.LOAD_PROPERTY,
    roomTypes: state.roomTypes,
    gokiStatus: state.goki.status,
    hasGokiRooms,
    apiKeyDisplay: state.goki.apiKeyDisplay,
    isLoading: state.loading.GET_ROOM_MAPS || state.loading.FETCH_GOKI_ROOMS,
    isRefresh: state.loading.REFRESH_MAPPING,
    errors:
      state.errors.RESFRESH_MAPPING ||
      state.errors.DELETE_ROOM_MAPPING ||
      state.errors.CREATE_ROOM_MAPPING ||
      state.errors.UPDATE_ROOM_MAPPING ||
      state.errors.FETCH_GOKI_ROOMS,
  };
};

export default withTranslation()(connect(mapStateToProps)(Goki));
