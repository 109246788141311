import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: 15,
    },
    row: {
        display: 'flex'
    },
    data: {
        alignItems: 'center',
        color: '#666666',
        display: 'flex',
        fontSize: '1.2rem',
        flexDirection: 'column',
        height: 30,
        justifyContent: 'center',
    },
    lineName: {
        marginLeft: 10,
        alignItems: 'flex-start'
    },
    lineItem: {
        alignItems: 'center',
        color: '#666666',
        display: 'flex',
        fontSize: '1.2rem',
        height: 30,
        paddingLeft: 10,
    }
}))

const DefaultRatePlan = (props) => {
    const { range, t } = props
    const classes = useStyles();
    const { numberOfDays } = props;
    const width = (100 / numberOfDays);
    let rangeList = range.slice(0, numberOfDays)

    return (
        <Grid
            container
            className={classes.container}
        >

            {/* ROW NAME */}
            {/* ---------------------------------------------------------------- */}
            <Grid item xs={4} sm={2}>
                <div className={clsx(classes.data, classes.lineName)}>
                    <div className={classes.lineItem}>
                        {'Standard'}
                    </div>
                </div>
            </Grid>

            <Grid item xs={8} sm={10} className={classes.row}>
                {rangeList.map(date => {
                    return (
                        <div key={date} className={classes.data} style={{ width: `${width}%` }}>
                            {'-'}
                        </div>
                    )
                })}
            </Grid>

        </Grid>
    )
}

export default withTranslation()(DefaultRatePlan);