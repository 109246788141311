import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SYSTEM_ADMIN_ACCESS_DENY_LIST } from './constants';

const permissionsSelector = state => {
    const { auth, dashboard, spaces, permissions } = state;
    const currentUser = auth.user._id;
    const currentSpace = spaces[dashboard.currentSpace] || {};
    const isAdmin = currentSpace.admins && currentSpace.admins.includes(currentUser);
    const isSystemAdmin = auth.user.isSystemAdmin;
    return {
        isAdmin,
        isSystemAdmin,
        userPermissions: permissions.userPermissions
    }
}

const usePermissions = requiredPermission => {
    const [hasPermission, setHasPermission] = useState(false);
    const { isAdmin, isSystemAdmin, userPermissions } = useSelector(permissionsSelector);
    
    const init = () =>{
        if (isAdmin) {
            setHasPermission(true);
            return true;
        }

        if(isSystemAdmin && !SYSTEM_ADMIN_ACCESS_DENY_LIST.includes(requiredPermission)) {
            setHasPermission(true);
            return true;
        }

        if (userPermissions.includes(requiredPermission)) {
            setHasPermission(true);
            return;
        }
        setHasPermission(false);
    }
    
    useEffect(() => {
        init();
    }, [isAdmin, isSystemAdmin, userPermissions]);
    return hasPermission;
}

export default usePermissions;