import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD BOOKING SOURCE
//----------------------------------------------------------------
export function loadBookingSource(propID, startDate, endDate) {
  const ACTION_NAME = "LOAD_BOOKING_SOURCE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/bookingSource/${propID}?startDate=${startDate}&endDate=${endDate}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET BOOKING SOURCE REPORT
//----------------------------------------------------------------
export const resetBookingSourceReport = () => ({
  type: 'RESET_BOOKING_SOURCE_REPORT'
})