

//----------------------------------------------------------------
// CASH ACCOUNTS REDUCER
//----------------------------------------------------------------
export default (state = {}, action) => {
  switch(action.type) {
    case "LOAD_CASH_ACCOUNTS_SUCCESS":
      return {
        ...state,
        ...action.payload.cashAccounts
      }
    case "SETUP_CASH_ACCOUNTS_SUCCESS":
      return {
        ...state,
        ...action.payload.cashAccounts
      }
    case "UPDATE_CASH_ACCOUNTS_SUCCESS":
      return {
        ...state,
        ...action.payload.cashAccounts
      }
    case "UPDATE_CASH_SUB_ACCOUNTS_SUCCESS":
      return {
        ...state,
        ...action.payload.cashAccounts
      }
    case "RESET_CASH_ACCOUNTS":
      return {}
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};

