import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import GuestForm from '../../reservations/GuestForm';
import { Avatar } from '@material-ui/core';
// import { ICONS } from '../../../utils/imageUrls';
import clsx from "clsx";
import * as i18nIsoCountries from "i18n-iso-countries";
import { getAvatarFlag, humanise, initials } from '../../../utils/utility';
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  sidebar: {
    width: 100,
    padding:20
  },
  item: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20
  },
  avatar: {
    border: "1px solid transparent",
    cursor: "pointer",
    height: 50,
    width: 50,
    padding: 2,
    backgroundColor: '#fff',
    "&.selected": {
      border: "1px solid #000"
    },
    '& img':{ 
      width: '70%',
      height: '70%',
    }
  },
}));

const GuestTab = props => {
  const { booking, currentSpace } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState(0);

  const handleBuildUserAvatar = (customerData, index) => {
    let countryName = customerData.nationality || (customerData.address ? customerData.address.country : "");
    countryName = i18nIsoCountries.getName(countryName, "en") || countryName;
    const flag = getAvatarFlag(countryName);
    const guestImage = customerData.image || "";
    const avatar = guestImage || flag;
    const userInitials = initials(customerData.firstName + " " + customerData.lastName);

    return (<>
      <Avatar
        alt={userInitials}
        src={avatar || IMAGES.ICONS.darkBoyBroadSmile}
        onClick={() => setSelected(index)}
        className={clsx({
          [classes.avatar]: true,
          'selected': selected === index
        })}
      />
      <span className={classes.avatarText}>{humanise(customerData.firstName, customerData.lastName)}</span>
      </>
    );
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        {booking.customers.map((guest, index) =>
          <div className={classes.item} key={index}>
            {handleBuildUserAvatar(guest, index)}
          </div>
        )}
      </div>

      <GuestForm
        customerInfo={booking?.customers?.[selected] || {}}
        mode={"edit"}
        timezone={currentSpace.timezone}
        isPrimary={true}
        delete={false}
        disabled={true}
        showLess={true}
        handleUpdateCustomer={() => void 0}
        handleDeleteCustomer={() => 0}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(GuestTab);