import React from 'react';
import clsx from "clsx";
import { connect } from 'react-redux';
import { makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Button, Dialog, DialogContent, DialogActions, CircularProgress, Checkbox } from '@material-ui/core';
import { RESERVATION_STATUS } from '../../../utils/constants';
import { cancelReservation } from '../../../redux/actions/reservation/editReservation';
import DialogForm from './guest-accommodation/DialogForm';
import { CHANNEL } from '../../../utils/channels';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 24,
  },
  formControl: {
    padding: "10px 0px",
  },
  radioGroup: {
    marginBottom: 20
  },
  radioLabel: {
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#F0EDEB",
    fontSize: '1.4rem',
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 0,
    '& span': {
      fontSize: '1.4rem',
    },
  },
  selected: {
    backgroundColor: "#E0E0E0;",
    color: "#FFFFFF"
  },
  radioButton: {
    display: "none"
  },
  notes: {
    background: '#fff',
    border: 'none',
    borderRadius: "10px",
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: "10px",
    }
  },
  themeButton: {
    backgroundColor: " #d96977",
    boxShadow: 'none',
    color: "#FFFFFF",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: " #d96977",
      color: "#FFFFFF"
    },
    "&:focus": {
      outline: "none"
    }
  },
  newNoteContainer: {
    width: '100%',
  },
  newNote: {
    alignItems: ' center',
    display: 'flex',
  },
  formBox: {
    alignItems: "center",
    borderRadius: "8px",
    color: "#828282",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 25,
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: '100%',
    "& .MuiOutlinedInput-multiline": {
      paddingTop: 10,
      paddingBottom: 10
    },
    "& textarea": {
      backgroundColor: "#FFFFFF",
      fontSize: '1.4rem'
    },
    "& .spanButton": {
      fontSize: "16px",
      cursor: "pointer",
      padding: "0 6px 0 18px"
    }
  },
  tab: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.1rem',
    flexDirection: 'column',
    height: '30px',
    justifyContent: 'center',
    marginRight: 10,
    padding: "10px",
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 0,
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  noShowBooking: {
    color: '#13408d'
  },
  checkboxContainer: {
    display: 'grid'
  }
}));

const invalidCCKey = 'invalidCC'

const Cancel = (props) => {
  const classes = useStyles();

  const { reservation, channelStatusList = {}, currentUser } = props;

  const [status, setStatus] = React.useState();
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [cancellationReason, setCancellationReason] = React.useState('')
  const [noShowBooking, setNoShowBooking] = React.useState(true)
  const [waivedFees, setWaivedFees] = React.useState(false)

  const cancelType = [
    { key: RESERVATION_STATUS.NO_SHOW, value: RESERVATION_STATUS.NO_SHOW },
    { key: "p" + RESERVATION_STATUS.CANCELLED, value: "Property Canceled" },
    { key: "g" + RESERVATION_STATUS.CANCELLED, value: "Guest Canceled" }
  ]

  if (channelStatusList[CHANNEL.BookingCom.code] && reservation.bookingSource === CHANNEL.BookingCom.name && reservation.invalidCCTransactions > 0)
    cancelType.push({ key: invalidCCKey + RESERVATION_STATUS.CANCELLED, value: "Invalid Credit Card" })

  const handleCancelReservation = () => {
    if (status) {
      let payload = {
        status,
        cancellationReason,
        noShowBooking: status === RESERVATION_STATUS.NO_SHOW && reservation.bookingSource === CHANNEL.BookingCom.name && noShowBooking,
        invalidCC: status.includes(invalidCCKey),
        waived_fees: waivedFees,
        cancellationBy: currentUser.name
      }
      if (status.includes(RESERVATION_STATUS.CANCELLED))
        payload.status = RESERVATION_STATUS.CANCELLED;

      props.dispatch(cancelReservation(reservation._id, payload))
      // Google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': 'userAction', 'eventCategory': 'Reservation', 'eventAction': `Cancelled - ${cancellationReason}`, 'eventLabel': 'Cancelled Reservation' });
      props.handleClose()
    }

  }

  const onStatusSelectHandler = (e) => {
    if (['pCancelled', 'gCancelled'].includes(e.target.value) && reservation.bookingSource === CHANNEL.Hostelworld.name) {
      let isDisabled = cancellationReason.trim() ? false : true;
      setIsDisabled(isDisabled)
    }
    else setIsDisabled(false)
    setStatus(e.target.value);
    if (e.target.value === RESERVATION_STATUS.NO_SHOW)
      setNoShowBooking(true)
    else setNoShowBooking(false)
  }

  const onNotesChangeHandler = (e) => {
    if (!noShowBooking && reservation.bookingSource === CHANNEL.Hostelworld.name) {
      let isDisabled = e.target.value.trim() ? false : true;
      setIsDisabled(isDisabled)
    }
    else setIsDisabled(false)
    setCancellationReason(e.target.value)
  }

  return (<>
    <DialogForm closeModalHandler={props.handleClose} loading={props.loadingCancel} isSmallerForm={true} title={'Cancel reservation'}>

      <div className={classes.root}>
        <DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              row
              className={classes.radioGroup}
              aria-label={"status"}
              name={"Status"}
              onChange={onStatusSelectHandler}
            >
              {cancelType.map(option => (
                <FormControlLabel
                  className={clsx({
                    [classes.tab]: true,
                    [classes.tabSelected]: status === option.key
                  })}
                  key={option.key}
                  value={option.key}
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label={option.value}
                  labelPlacement="end"
                />

              ))}
            </RadioGroup>
          </FormControl>
          <div className={classes.newNoteContainer}>
            <div className={classes.newNote}>
              <div className={classes.formBox}>
                <TextField
                  className={classes.notes}
                  variant="outlined"
                  multiline
                  rows="2"
                  id="name"
                  label="Notes"
                  onChange={onNotesChangeHandler}
                  fullWidth
                />
              </div>
            </div>
          </div>

          {channelStatusList[CHANNEL.BookingCom.code] && status === RESERVATION_STATUS.NO_SHOW && reservation.bookingSource === CHANNEL.BookingCom.name && (
            <div className={classes.checkboxContainer}>
              <FormControlLabel className={classes.noShowBooking}
                control={<Checkbox checked={noShowBooking} onChange={() => { setNoShowBooking(!noShowBooking); setWaivedFees(false) }} name="noShowBooking" />}
                label={<span style={{ fontWeight: 'bold' }}>Mark as no-show on Booking.com</span>}
              />
              <FormControlLabel className={classes.noShowBooking}
                control={<Checkbox disabled={!noShowBooking} checked={waivedFees} onChange={() => setWaivedFees(!waivedFees)} name="waivedFees" />}
                label={<span style={{ fontWeight: 'bold' }}>Waive fees</span>}
              />
            </div>
          )}

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCancelReservation()}
            color="primary"
            className={classes.themeButton}
            variant="contained"
            disabled={isDisabled}
          >Cancel booking</Button>
        </DialogActions>
      </div>
    </DialogForm>
  </>
  )

}

const mapStateToProps = state => ({
  reservation: state.reservationDetails.reservation,
  channelStatusList: state.dashboard.channelStatusList,
  currentUser: state.auth.user
})

export default connect(mapStateToProps)(Cancel);