import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setCurrentMaker } from '../../redux/actions/dashboard/dashboard';
import { initials } from '../utils/wordUtils';
import { newMessage } from '../../redux/actions/messages/messages';
import { toTitleCase } from '../utils/wordUtils';

import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import { resetAccessData } from '../../redux/actions/permissions/permissions';
import { inviteSpaceUser, deletePendingUser } from '../../redux/actions/auth/invite';
import { loadOnlySpace } from '../../redux/actions/dashboard/dashboard';
import IMAGES from '../../constants/images';

const useStyles = makeStyles( theme => ({
  root:{
    paddingBottom: theme.spacing(1),
  },
  container: {
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    padding: theme.spacing(2)
  },
  bubble: {
    backgroundColor: '#E0E0E0',
    border: '0px solid #dddddd',
    color: '#ffffff',
    fontSize: '2rem',
    height: 40,
    lineHeight: 3,
    textAlign: 'center',
    width: 40
  },
  userContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  attributes: {
    color: '#484848',
    display: 'flex',
    marginBottom: 5,
  },
  dot: {
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    color: '#333333',
    fontWeight: 600,
    marginBottom: 5,
  },
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    background: '#F8F9FB',
    borderRadius: 25,
    fontSize: '1.2rem',
  },
  closeIcon:{
    borderRadius: '50%',
    padding: '8px',
    zIndex: 99,
    cursor: 'pointer',
    marginLeft: 15,
    '& img':{
      width: '20px',
      height: '20px'
    },
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
}))

const UserTile = (props) => {
  const classes = useStyles();
  const {user, userType, userID, currentUser, currentSpace , t} = props;
  const isCurrentMakerAdmin = currentSpace && currentSpace.admins.includes(userID);
  const isCurrentUserAdmin = currentSpace.admins.includes(currentUser);
  const isOwn = (userID == currentUser) ? true : false;
  const isHostel = currentSpace.type == 'Hostel' ? true: false; 

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [isInviteSent, setIsInviteSent] = useState(false);
  
  useEffect(() => {
    return () => {
      props.dispatch(resetAccessData());
      setIsInviteSent(false);
    };
  },[]);

  const handleClose = () => {
    closePermissionModal();
    props.dispatch(setCurrentMaker());
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  const handleLock = () => {
    setCurrentMaker(userID);
    setShowPermissionModal(true);
  }

  const handleMessage = () => {
    props.dispatch(newMessage([userID, currentUser]));
  }

  const closePermissionModal = () =>{
    setShowPermissionModal(false);
  }

  const deletePendingUserHandler = (email) =>{
    const response = props.dispatch(deletePendingUser(currentSpace._id, email, userID));
    response.then(success =>{
      if(success) props.dispatch(loadOnlySpace(currentSpace._id));
    })
  }

  const resendInviteHandler = () => {

    const data = {
      spaceID: currentSpace._id,
      permissions: 'member',
      role: user.role ? user.role : 'member',
      name: user.userID.name,
      email: user.userID.email
    }
    
    const response = props.dispatch(inviteSpaceUser(currentSpace._id, data));

    response.then(success =>{
      if(success) setIsInviteSent(true); 
    });
  }

  return (
    <div className={classes.root}>
        {user != null &&  (
          <div className={classes.container}>
            <Grid container alignItems="center">

              <Grid item xs={4} md={2} lg={1}>
                <Avatar
                  alt={initials(user.userID.name)}
                  className={classes.bubble}
                >
                  {initials(user.userID.name)}
                  {/* {console.log(currentMaker.name)} */}
                </Avatar>
              </Grid>
              <Grid item xs={8} md={6} lg={7}>
                <div className={classes.userContainer}>
                  <Typography className={classes.title} variant="h6">{toTitleCase(user.userID.name)}</Typography>
                  <div className={classes.attributes}>
                    <Typography variant="body2">{user.userID.email}</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.actionContainer}>
                  <Button variant="outlined" className={classes.button} onClick={resendInviteHandler}>Resend Invite</Button>
                  <div className={classes.closeIcon} onClick={() => deletePendingUserHandler(user.userID.email)}>
                    <img alt="delete" src={IMAGES.ICONS.bin} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}

        {isInviteSent && <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key='top-center'
          autoHideDuration={2000}
          open={isInviteSent}
          onClose={() => setIsInviteSent(false)}
          message={'Invite sent successfully'}
        />}

    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.auth.user._id,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(UserTile));