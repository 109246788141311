import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
// import { ICONS } from '../../../../utils/imageUrls';
import { DATE_FORMATS, fetchDateFormat, fetchDatePerTimezone, fetchNightCount } from '../../../../utils/utility';
import { Button, Grid } from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import PlusMinus from '../../../common/PlusMinus';

import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 6,
        minHeight: "420px",
    },
    sectionTitle: {
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0'
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 600
    },
    icon: {
        width: 18,
        height: 18,
        marginRight: 20
    },
    button: {
        background: '#fff',
        border: 'none',
        borderRadius: 5,
        minHeight: 50,
        padding: 0,
        minWidth: 50,
        width: 'calc(100% - 10px)',
        marginRight: 10,
        boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
        [theme.breakpoints.down('xs')]: {
            flexGrow: 0,
            maxWidth: '100%',
            flexBasis: '100%',
            marginBottom: 5,
            marginLeft: 0,
            marginRight: 0
        },
    },
    selected: {
        background: '#E0E0E0',
    },
    checkOut: {
        marginLeft: 15,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    centerAlign: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));


const BasicDetails = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { currentSpace, preFilledData } = props;
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckout] = useState(null);
    const [focused, setFocused] = useState(null);
    const [focusedCheckout, setFocusedCheckout] = useState(null);
    const [nights, setNights] = useState(null);

    React.useImperativeHandle(ref, () => ({
        handleProcessData() {
          return {
            propertyID: currentSpace.propertyID,
            nights: nights > 0 ? nights : fetchNightCount(checkIn, checkOut),
            checkIn: fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE),
            checkOut: fetchDateFormat(checkOut, DATE_FORMATS.DEFAULT_DATE),
          }
        }
      }))
    
      React.useEffect(() => {
        if(preFilledData.checkIn) setCheckIn(fetchDatePerTimezone(preFilledData.checkIn))
        if(preFilledData.nights) setNights(preFilledData.nights);
        if(preFilledData.checkOut) setCheckout(fetchDatePerTimezone(preFilledData.checkOut));
      }, [preFilledData])

    const onNightChange = (value, id) => {
        setNights(value)
        setCheckout(fetchDatePerTimezone(checkIn).add(value, 'days'))
    }
    const onDateChange = (checkInDate, checkOutDate) => {
        if (checkInDate !== checkIn) setCheckIn(checkInDate);
        if (checkOutDate !== checkOut) setCheckout(checkOutDate);
        if (checkInDate && checkOutDate) {
            setNights(fetchNightCount(checkInDate, checkOutDate))
        }
        else setNights(0)
    }


    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    <img className={classes.icon} src={IMAGES.ICONS.darkCalendar} />
                    <span className={classes.title}>Select check-in date</span>
                </div>
                <Grid container>
                    <Grid item md={2} sm={3} xs={4}>
                        <Button
                            onClick={() => onDateChange(fetchDatePerTimezone(null, currentSpace.timezone), checkOut)}
                            className={clsx({
                                [classes.button]: true,
                                [classes.selected]: fetchDatePerTimezone(null, currentSpace.timezone).isSame(checkIn, 'day')
                            })}
                            variant='outlined'
                        >
                            Today
                        </Button>
                    </Grid>
                    <Grid item md={2} sm={3} xs={4}>
                        <Button
                            onClick={() => onDateChange(fetchDatePerTimezone(null, currentSpace.timezone).add(1, 'days'), checkOut)}
                            className={clsx({
                                [classes.button]: true,
                                [classes.selected]: fetchDatePerTimezone(null, currentSpace.timezone).add(1, 'days').isSame(checkIn, 'day')
                            })}
                            variant='outlined'
                        >
                            Tomorrow
                        </Button>
                    </Grid>
                    <Grid item md={2} sm={3} xs={4}>
                        <div className={"datePickerContainer"}>
                            <SingleDatePicker
                                date={checkIn}
                                onDateChange={date => onDateChange(date, checkOut)}
                                focused={focused}
                                onFocusChange={({ focused }) => setFocused(focused)}
                                id="check-in-date"
                                placeholder={'Select Date'}
                                showClearDate={true}
                                noBorder={true}
                                numberOfMonths={1}
                                isOutsideRange={day => false}
                                displayFormat={'DD MMM YYYY'}
                                daySize={30}
                                hideKeyboardShortcutsPanel
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    <img className={classes.icon} src={IMAGES.ICONS.darkSleep} />
                    <span className={classes.title}>Select number of nights</span>
                </div>
                <div className={classes.blocks}>
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <PlusMinus onCountChange={onNightChange} prefilledCount={nights} fullWidth={true} />
                        </Grid>
                        <Grid item xs={12} sm={1} style={{ margin: '15px 0' }}>
                            <div className={classes.centerAlign}>
                                <span className={classes.title}>OR</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div className={clsx("datePickerContainer", classes.checkOut)}>
                                <SingleDatePicker
                                    date={checkOut}
                                    onDateChange={date => onDateChange(checkIn, date)}
                                    focused={focusedCheckout}
                                    onFocusChange={({ focused }) => setFocusedCheckout(focused)}
                                    id="check-out-date"
                                    placeholder={'Select check-out date'}
                                    showClearDate={true}
                                    noBorder={true}
                                    numberOfMonths={1}
                                    isOutsideRange={day => (day.isBefore(fetchDateFormat(checkIn, DATE_FORMATS.DEFAULT_DATE)))}
                                    displayFormat={'DD MMM YYYY'}
                                    daySize={30}
                                    hideKeyboardShortcutsPanel
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
})

export default BasicDetails;