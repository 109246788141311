import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { InputAdornment, Button } from '@material-ui/core';
import DurationDatePicker from './DurationDatePicker';
import NumberInput from '../../../common/NumberInput';
import { checkDateInBetween, fetchDateFormat } from '../../../../utils/utility';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    changeAll: {
        maxWidth: 300,
    },
    formInput: {
        border: '1px solid #dadada',
        padding: '10px 10px 10px 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        width: 'calc(100% - 27px)',
    },
    data: {
        width: '80%',
        wordBreak: 'break-word',
        '&.fullWidth': {
            marginRight: 5
        }
    },
    value: {
        fontWeight: 600
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        width: '20%'
    },
    actionIcon: {
        cursor: 'pointer',
        height: 20,
        width: 20
    },
    fieldContainer: {
        width: '100%',
    },
    field: {
        background: '#f8f9fb',
        padding: '10px 10px 10px 15px',
        borderRadius: 30,
        border: '1px solid #dadada',
        width: 'calc(100% - 0px)',
        '& .css-1okebmr-indicatorSeparator': {
            display: 'none'
        },
        '& .css-6q0nyr-Svg': {
            color: '#000000',
            width: 30,
            height: 30,
        },
        '& fieldset': {
            border: 0
        },
        '& input.MuiInputBase-input': {
            padding: '0px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 5,
        }
    },
    rateSection: {
        backgroundColor: '#f2f2f2',
        borderRadius: 5,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& > div': {
            width: '33.33%'
        },
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 20,
        padding: 10,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
    spacing: {
        padding: '10px 0'
    },
    disabledLabel: {
        fontSize: '1.3rem',
        color: '#666666',
    }
}));

const FormData = ({
    classes,
    adornment,
    defaultValue,
    value,
    editableFieldHandler,
    editableFields,
    onChangeHandler
}) => {
    return (
        <React.Fragment>
            {!editableFields ?
                <div className={classes.formInput}>
                    <div className={classes.data}>
                        <div className={classes.value}>{defaultValue || value || '-'}</div>
                    </div>
                    <div className={classes.icon}>
                        <img
                            className={classes.actionIcon}
                            src={IMAGES.ICONS.pencil}
                            onClick={() => editableFieldHandler(true)}
                        />
                    </div>
                </div>
                :
                <div className={classes.fieldContainer}>
                    <NumberInput
                        className={classes.field}
                        min={0}
                        value={value}
                        onChange={(e) => onChangeHandler(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">{adornment}</InputAdornment>
                        }
                    />
                </div>
            }
        </React.Fragment>
    )
}

const RatePerDate = React.forwardRef((props, ref) => {
    const { data, classes } = props;
    const [edit, setEdit] = React.useState(false);
    const [value, setValue] = React.useState(data.rate || 0);

    React.useImperativeHandle(ref, () => ({
        clearChangeHandler() {
            setEdit(false);
        }
    }))

    const onChangeHandler = (value) => {
        setValue(value)
        props.onChangeRatesByDate(data.date, value)
    }


    return (
        <div className={classes.rateSection}>
            <div className={classes.label}>{data.name}</div>
            <div className={classes.label}>{fetchDateFormat(data.date, 'ddd DD MMM YYYY')}</div>
            <div className={classes.form}>
                <FormData
                    classes={classes}
                    adornment={data.currency}
                    defaultValue={data.formattedRate || 0}
                    value={value || 0}
                    editOption={true}
                    editableFields={edit}
                    editableFieldHandler={(value) => setEdit(value)}
                    onChangeHandler={onChangeHandler}
                />
            </div>
        </div>
    )
})

const ChangeRateForm = (props) => {
    const classes = useStyles();
    const { data, range } = props;
    const itemsRef = React.useRef([]);
    const [edit, setEdit] = React.useState(false);
    const [ratesByDate, setRatesByDate] = React.useState([]);
    const [ratesByDateRange, setRatesByDateRange] = React.useState({});

    let items = data?.items?.filter(item => checkDateInBetween(range.startDate, range.endDate, item.date));

    React.useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
     }, [items]);

    const onChangeHandler = (value) => {
        if(value >= 0){
            setRatesByDateRange({startDate: range.startDate, endDate: range.endDate, rate: value})
            setRatesByDate([])
            items.map((item, i) => itemsRef.current[i].clearChangeHandler())
        }
    }

    const onChangeRatesByDate = (date, rate) => {
        let rates = [...ratesByDate];
        const index = rates.findIndex((e) => e.date === date);
        rate = rate || 0;
        if (index === -1) {
            rates.push({ date: date, rate: parseFloat(rate) });
        } else {
            if (rate >= 0)  rates[index] = { date: date, rate: parseFloat(rate) };
            else rates.splice(index, 1)
        }
        setEdit(false);
        setRatesByDate(rates)
        setRatesByDateRange({})
    }

    const submitFormHandler = () => {
        let payload = {
            guestNumber: data.guestNumber
        }
        if (ratesByDateRange.rate >= 0) {
            payload.changeRatesByDateRange = ratesByDateRange;
            payload.changeRatesByDateRange.rate = parseFloat(payload.changeRatesByDateRange.rate)
            props.onSubmitHandler(payload);
            return;
        }

        if (ratesByDate.length) {
            payload.changeRatesByDate = ratesByDate;
            props.onSubmitHandler(payload);
            return;
        }
    }

    return (
        <React.Fragment>
            {/* -------------------- Duration picker -------------------- */}
            <div className={classes.disabledLabel}>Selected date range</div>
            <div className={classes.spacing}></div>
            <DurationDatePicker startLabel={'Check in date'} endLabel={'Check out date'} startDate={range.startDate} endDate={range.endDate} disabled={true} onDatesChangeHandler={void 0}/>
            <div className={classes.spacing}></div>

            {/* -------------------- Rate form for selected range -------------------- */}
            <div className={classes.label}>Set new rate and apply for all days selected</div>
            <div className={classes.spacing}></div>
            <div className={classes.changeAll}>
                <FormData
                    classes={classes}
                    adornment={data?.items?.[0]?.currency}
                    defaultValue={data?.items?.[0]?.formattedRate || 0}
                    value={ratesByDateRange?.rate || 0}
                    editOption={true}
                    editableFields={edit}
                    editableFieldHandler={(value) => setEdit(value)}
                    onChangeHandler={onChangeHandler}
                />
            </div>
            <div className={classes.spacing}></div>

            {/* ---------------------- Rates per selected date range ------------------ */}
            <div className={classes.label}>Or change individually</div>
            <div className={classes.spacing}></div>
            {
                items.map((item, index) =>
                    <RatePerDate
                        key={index}
                        ref={el => itemsRef.current[index] = el} 
                        classes={classes}
                        data={item}
                        onChangeRatesByDate={onChangeRatesByDate}
                    />
                )
            }
            <div className={classes.spacing}></div>

            {/* ---------------------- Form submit button ------------------ */}
            {(ratesByDate.length!==0 || (ratesByDateRange.rate >=0)) && <Button className={classes.button} onClick={()=> submitFormHandler()}>Save</Button>}
        </React.Fragment>
    )
}

export default ChangeRateForm;