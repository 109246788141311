import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Picker } from "emoji-mart";
import 'emoji-mart/css/emoji-mart.css';
 
const useStyles = makeStyles( theme => ({
  emojiBox: {
    backgroundColor: "#ffffff",
    zIndex: 99
  },
  emojiMart: {
    position: "absolute",
    bottom: 60,
    right: 32,
    zIndex: 99
  },
}));

const Emoji = (props) => {
  const node = useRef();
  const classes = useStyles();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    props.clickedOutside(false);
  };

  useEffect(() => {
    if (props.open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div ref={node} className={classes.emojiBox}>
      <div className={classes.emojiMart}><Picker set="emojione" onSelect={props.addEmoji} /></div>
    </div>
  )
};

export default Emoji;