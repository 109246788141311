import { schema } from 'normalizr';

const gradeProcessStrategy = (value, parent, key) =>{

    if(value.grades){
        let formattedGradeData = {};
        const gradesArray = _.cloneDeep(value.grades);
        
        gradesArray.forEach(gradeData =>{ 

            let formattedSlotsData = {};

            if(gradeData.slots){
                const slotsArray = _.cloneDeep(gradeData.slots);
                
                slotsArray.forEach(slotData =>{ 
                    formattedSlotsData[slotData._id] = { ...slotData};
                });
            }

            formattedGradeData[gradeData._id] = { 
                ...gradeData, 
                slots: { ...formattedSlotsData }
            };
        });

        return{ 
            ...value,
            grades: { ...formattedGradeData }
        }
    }

    return { ...value, grades: {} }
}

const productSchema = new schema.Entity('products', {}, {
    idAttribute: '_id'
});

const conductorSchema = new schema.Entity('conductors', {}, {
    idAttribute: '_id'
});

const productListSchema = new schema.Entity('productList', {},{
    processStrategy: gradeProcessStrategy,
    idAttribute: '_id'
});

const noticeboardSchema = new schema.Entity('noticeboard', {},{
    idAttribute: '_id'
});

export { productSchema, conductorSchema, productListSchema, noticeboardSchema }