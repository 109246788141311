import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';

import ExperiencePreview from './ExperiencePreview';
import { CircularProgress } from '@material-ui/core';
import ActivityFlow from '../Activity_Flow';
import DraftPreview from './DraftPreview';

import { deleteNoticeboardItem, fetchNoticeboardDetails } from '../../../redux/actions/experiences/hwExperiences';
import { addUpdateDraft, fetchProductList } from '../../../redux/actions/experiences/activities';
import { noticeboardSchema } from '../../../redux/schema/experiences';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    background: '#F8F9FB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 600,
    width: '80%',
    maxWidth: 800,
    position: 'relative'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    width: '100%',
  },
  helperRow: {
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20,
    display: 'flex',
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    marginLeft: 20,
  },
  header: {
    fontSize: '1.8rem',
    fontWeight: 600,
    width: '100%',
    marginTop: 50,
  },
  titleContainer: {
    display: 'flex',
    marginTop: 40,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    color: '#333333',
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 20,
  },
  field: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  leftAlign: {
    alignItems: 'flex-start'
  },
  center: {
    alignItems: 'center'
  },
  addButton: {
    cursor: 'pointer',
    borderRadius: 5,
    border: '1px solid #999999',
    padding: '10px 20px',
    maxWidth: 150,
  },
  button: {
    background: '#CC6633',
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 150,
    fontSize: '1.3rem',
    fontWeight: 600,
    padding: '15px 20px',
  },
  loading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 400,
    width: '100%',
  },
  listContainer: {
    marginBottom: '40px',
    width: '100%',
    '& $row':{
      marginBottom: '20px'
    }
  }
}))

const NoticeboardImport = (props) => {
  const classes = useStyles();

  const { 
    containerHeight, 
    loading,
    noticeboard, 
    dispatch, 
    currentSpace,
    draftList
  } = props;

  const [ seletcedDraftID, setSeletcedDraftID ] = useState(null);

  const loadNoticeboard = () => {
    const response = dispatch(fetchNoticeboardDetails(currentSpace.propertyID));
    return response.then(data => {
      if (data && data.length===0) {
        console.log('no noticeboard data --> redirecting')
        props.handleNext();
      }
      return
    })
  }
  
  useEffect(() =>{
    dispatch(fetchProductList(currentSpace.propertyID));
    loadNoticeboard();
  }, []);

  const setupExperience = experienceData =>{
    const { _id, title, description, category } = experienceData;
    let requestData = {
      propertyID: currentSpace.propertyID,
      title,
      description,
      categories: [ category ]
    }
    const response = dispatch(addUpdateDraft('draft', requestData));
    return response.then(data =>{
        if(data) {
          setSeletcedDraftID(data._id);
          // console.log('delete noticeboard item', _id)
          props.dispatch(deleteNoticeboardItem(_id));
        }
        return;
    });
  }

  const closeModalHandler = () =>{
    setSeletcedDraftID(null);
  }

  return (
    <div className={classes.root} style={{minHeight: containerHeight}}>
      
      {loading && <div className={classes.loading}><CircularProgress /></div>}
      
      {!loading && <div className={classes.section}>

        {/* --------------- HEADER --------------- */}
        <div className={classes.row}>
          <div className={classes.titleContainer}>
            <div className={classes.header}>Import Hostelworld Noticeboard Data</div>
          </div>
          <div className={classes.helperRow}>
              <div>
                <img src="/images/icons/bulb.png" className={classes.bulb} />
              </div>
              <div className={classes.helperText}>
                Counter is an experience management system with the ability to handle inventory, pricing, reports and 
                much more. In order to move your older data from HW noticeboard into Counter, please setup your experiences 
                below. You can also choose to discard your old data.
              </div>
            </div>
        </div>

        {/* ---------------DRAFT LIST--------------- */}
        {draftList && Object.keys(draftList).length!==0 && <div>
          <div className={classes.titleContainer}>
            <div className={classes.title}>New Experiences</div>
          </div>
          <div className={classes.listContainer}>
            {Object.keys(draftList).map(draftID =>(
              <div className={classes.row} key={draftID}>
                <DraftPreview 
                  editDraft={(draftID) => setSeletcedDraftID(draftID)}
                  draftData={{...draftList[draftID]}}
                />
              </div>
            ))}
          </div>
        </div>}

        {/* -------------NOTICEBOARD LIST----------*/}
        {noticeboard && Object.keys(noticeboard).length!==0 && <div>
          <div className={classes.titleContainer}>
            <div className={classes.title}>HW Noticeboard Items</div>
          </div>
          {Object.keys(noticeboard).map(noticeboardItem => (
            <div className={classes.row} key={noticeboardItem}>
              <ExperiencePreview 
                experience={noticeboard[noticeboardItem]}
                setupExperience={setupExperience} 
              />
            </div>
          ))}
        </div>}

        <div className={clsx(classes.row, classes.center)} >
          <div className={classes.button} onClick={props.handleNext}>Complete Setup</div>
        </div>

      </div>}

      {seletcedDraftID && (
        <ActivityFlow 
          productID={seletcedDraftID}
          closeModalHandler={closeModalHandler} 
        />
      )}
  
    </div>
  )
}

const mapStateToProps = (state) => ({
  noticeboard: state.experiences.noticeboard,
  loading: state.loading.FETCH_NOTICEBOARD_DETAILS || state.loading.FETCH_ACTIVITY_LIST,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  draftList: state.experiences.activityList || {}
})

export default connect(mapStateToProps)(NoticeboardImport);