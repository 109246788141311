import React from 'react';
import clsx from 'clsx';
import  { connect } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BarInfo from '../charts/BarInfo';
import { returnCurrent } from '../../redux/selectors/dashboard';
import { fetchOccupancyForToday } from '../../redux/selectors/live/live';

const useStyles = makeStyles( theme => ({
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  tabInfo: {
    display: 'flex'
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 195,
    justifyContent: 'center',
    width: '100%'
  },
  iconRow: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10
  },
  pie: {
    height: 195,
  },
  tab: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.1rem',
    flexDirection: 'column',
    height: '30px',
    justifyContent: 'center',
    marginRight: 10,
    width: '70px'
  }
}))

const TodaysOccupancy = (props) => {
  const classes = useStyles();
  const { live, loadingLive, currentSpace, currentSpaceID } = props

  const [selectedTab, setSelectedTab] = React.useState('today')
  const [initial, setInitial] = React.useState(true)
  const [charts, setCharts] = React.useState(null)
  
  React.useEffect(() => {
    if (!loadingLive && currentSpaceID && currentSpace && live[currentSpaceID]) {
      const chartData = fetchOccupancyForToday(currentSpaceID, live)
      setCharts(chartData)
    }
  }, [live[currentSpaceID]])

  const handleTabSelect = (tab) => {
    setSelectedTab(tab)
  }

  return (
    <div>
      <div className={clsx(classes.iconRow, classes.tabInfo)}>
        <div 
          id="today"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: selectedTab==='today'})} 
          onClick={() => handleTabSelect('today')} >
          Today
        </div>
        <div 
          id="mtd"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: selectedTab==='mtd'})} 
          onClick={() => handleTabSelect('mtd')} >
          MTD
        </div>
        <div 
          id="ytd"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: selectedTab==='ytd'})} 
          onClick={() => handleTabSelect('ytd')} >
          YTD
        </div>
        <div 
          id="ly"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: selectedTab==='ly'})} 
          onClick={() => handleTabSelect('ly')} >
          Last Year
        </div>
      </div>
      {(selectedTab==='today') && <div>
        {(!charts) && <div className={classes.loadingState}>
          <CircularProgress />
        </div>}
        {(charts && charts.todayChart) && <div className={classes.pie}>
          <BarInfo data={charts.todayChart} indexBy={'value'} keyName={['occupancy']} />
        </div>}
      </div>}
      {(selectedTab==='mtd') && <div>
        {(!charts) && <div className={classes.loadingState}>
          <CircularProgress />
        </div>}
        {(charts && charts.mtdChart) && <div className={classes.pie}>
          <BarInfo data={charts.mtdChart} indexBy={'value'} keyName={['occupancy']} />
        </div>}
      </div>}
      {(selectedTab==='ytd') && <div>
        {(!charts) && <div className={classes.loadingState}>
          <CircularProgress />
        </div>}
        {(charts && charts.ytdChart) && <div className={classes.pie}>
          <BarInfo data={charts.ytdChart} indexBy={'value'} keyName={['occupancy']}/>
        </div>}
      </div>}
      {(selectedTab==='ly') && <div>
        {(!charts) && <div className={classes.loadingState}>
          <CircularProgress />
        </div>}
        {(charts && charts.lyChart) && <div className={classes.pie}>
          <BarInfo data={charts.lyChart} indexBy={'value'} keyName={['occupancy']}/>
        </div>}
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  live: state.live,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  loadingLive: state.loading.LOAD_LIVE,
  currentSpaceID: state.dashboard.currentSpace
})

export default connect(mapStateToProps)(TodaysOccupancy);