const initialState = {default: true}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_THREAD_SUCCESS":
      return {
        ...action.payload
      }
    case "SUBMIT_ANSWER_SUCCESS":
      if (!state.answers || state.answers.length===0) {
        return {
          ...state,
          answers: [action.payload],
          answerCount: 1
        }
      } else return {
        ...state,
        answers: [
          ...state.answers,
          action.payload
        ],
        answerCount: state.answerCount+1
      }
    case "RESET_THREAD":
      return {
        ...initialState
      }
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    default:
      return state;
  }
};
  

