import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import SwipeableViews from 'react-swipeable-views';

import RoomCardContent from './RoomCardContent';
import { getRoomTypeInfo, getMappingsRoomTypeInfo, getPerRoomBedCost } from './helper';
import { getCurrency } from '../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {},
    cardContainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        overflow: "hidden",
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e0e0e0",
        justifyContent: "space-between",
        padding: "15px 0px",
    },
    cardTitle: {
        textAlign: 'center',
        '& p': { margin: 0 },
        '& .primary': {
            fontSize: '1.3rem',
            fontWeight: 500,
            marginBottom: '5px',
            color: '#4f4f4f'
        },
        '& .secondary': {
            fontSize: '1.1rem',
            fontWeight: 500,
            color: '#bdbdbd'
        },
        '& .secondary.warning': {
            color: '#ff8c00'
        }
    },
    cardBody: {
        backgroundColor: "#f8f9fb",
    },
    iconButton:{
        width: '1.5em',
        height: '1.5em',
        '& .icon': {
            fontSize: "3rem",
            color: "#4f4f4f",
        },
        '&.hide': {
            visibility: 'hidden'
        },
        '&.Mui-disabled .MuiIconButton-label': {
            opacity: 0.4
        }
    },
    cardLabel: {
        margin: "5px 0px",
        fontSize: "1.3rem",
    }
}));

const RoomCard = props =>{

    const classes = useStyles();
    const { 
        roomTypes, 
        roomsData,
        selectedRatePlanID,
        currentSpace,
        duration,
        ratesInfo,
        selectedBedsInfo,
        isReviewLoading 
    } = props;

    const currencyData = {
        code: currentSpace.currency,
        country: currentSpace.country
    }

    const [activeRoomTypeIndex, setActiveRoomTypeIndex] = useState(0);
    const [roomTypeMappings, setRoomTypeMappings] = useState([]);

    useEffect(() =>{
        const roomTypeMappingsInfo = getMappingsRoomTypeInfo(roomsData, roomTypes);
        setRoomTypeMappings([ ...roomTypeMappingsInfo ]);
    }, []);

    //RESET MAPPING VIEW TO BEDS SELECTED ROOMTYPE............................
    useEffect(() =>{
        const selectedRoomData = selectedBedsInfo?.[roomsData._id];
        if(selectedRoomData?.bedIDs && selectedRoomData.bedIDs.length > 0){
            setActiveRoomTypeIndex(selectedRoomData?.displayIndex || 0);
        }
    }, [selectedRatePlanID, isReviewLoading]);

    const currentRoomType = getRoomTypeInfo(roomTypeMappings?.[activeRoomTypeIndex]);
    const perRoomBedCost = getPerRoomBedCost({
        roomTypeID: currentRoomType._id, 
        ratePlanID: selectedRatePlanID,
        ratesInfo, 
        duration
    });
    const mappingCount = roomTypeMappings?.length;

    const nextBtnHandler = () =>{
        const activeIndex = activeRoomTypeIndex + 1;
        if(activeIndex > roomTypeMappings.length - 1) return;
        setActiveRoomTypeIndex(activeIndex);
    }

    const previousBtnHandler = () =>{
        const activeIndex = activeRoomTypeIndex - 1;
        if(activeIndex < 0) return;
        setActiveRoomTypeIndex(activeIndex);
    }

    const swipeHandler = (index) =>{
        setActiveRoomTypeIndex(index);
    }

    if(mappingCount == 0) return null;

    return(
        <div className={classes.root}>  
            
            <h4 className={classes.cardLabel}>{roomsData.name}</h4>

            <div className={classes.cardContainer}>

                {/* ------------------CARD HEADER----------------------- */}
                <div className={classes.cardHeader}>

                    {/* -------SLIDER PREVIOUS ACTION BUTTON------- */}
                    <IconButton 
                        className={clsx(classes.iconButton, { hide: mappingCount == 1 })}
                        onClick={previousBtnHandler}
                        disabled={activeRoomTypeIndex == 0}
                    >
                        <NavigateBeforeRoundedIcon className="icon" />
                    </IconButton>
                    
                    <div className={classes.cardTitle}>
                        <p className="primary">{currentRoomType?.name}</p>

                        {perRoomBedCost == 'NA' ? 
                            <p className="secondary warning"> No rates for selected rate plan</p> : 
                            <p className="secondary">
                                {getCurrency(currencyData, perRoomBedCost)} per {currentRoomType.isPrivate ? 'room' : 'bed'} per night
                            </p>
                        }
                    </div>
                    
                    {/* -------SLIDER NEXT ACTION BUTTON------- */}
                    <IconButton 
                        className={clsx(classes.iconButton, { hide: mappingCount == 1 })}
                        onClick={nextBtnHandler}
                        disabled={activeRoomTypeIndex == (mappingCount - 1)}
                    >
                        <NavigateNextRoundedIcon className="icon"/>
                    </IconButton>
                </div>

                {/* ------------------CARD BODY----------------------- */}
                <div className={classes.cardBody}>
                    <SwipeableViews
                        index={activeRoomTypeIndex}
                        onChangeIndex={swipeHandler}
                        action={actions => setTimeout(actions.updateHeight, 1000)}
                        animateHeight={true}
                    >
                        {roomTypeMappings.map((roomType, index) =>(
                            <RoomCardContent 
                                key={`roomType_${roomType._id}_${roomsData._id}`} 
                                roomID={roomsData._id}
                                roomTypeID={roomType._id}    
                                bedIDs={roomsData.beds}
                                isPrivateRoom={currentRoomType.isPrivate}
                                bedsPerRoom={currentRoomType.bedsPerRoom}
                                disableSelection={perRoomBedCost == 'NA'}
                                displayIndex={index}
                            />
                        ))}
                    </SwipeableViews>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state =>{
    const { roomTypes, dashboard, spaces , floorPlan, loading } = state;
    const currentSpaceID = dashboard.currentSpace;
    const currentSpace = spaces[currentSpaceID];
    return{ 
        roomTypes, 
        currentSpace, 
        ratesInfo: floorPlan.ratesInfo,
        selectedBedsInfo: floorPlan.selectedBedsInfo,
        isReviewLoading: loading.REVIEW_BOOKING
    }
}

export default connect(mapStateToProps)(RoomCard);