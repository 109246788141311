import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import SubDirectoryIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ArrowRight from '@material-ui/icons/KeyboardBackspace';
import IMAGES from '../../../../constants/images';
import { DATA_ACTION, inventoryConditionHandler } from '../../../../utils/helper';

const useStyles = makeStyles(theme => ({
  box: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 5,
    marginBottom: 30,
    padding: theme.spacing(3)
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    marginBottom: 10,
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  edit: {
    cursor: 'pointer',
    height: 14, 
    marginLeft: 15,
    opacity: 0.5,
    width: 14,
    '&:hover': {
      opacity: 1,
    }
  },
  base: {
    background: '#E0E0E0',
    borderRadius: '25px',
    color: '#666666',
    fontSize: '1.1rem',
    marginLeft: 'auto',
    padding: '5px 8px',
  },
  bin: {
    borderRadius: '50%',
    height: 18,
    marginLeft: 'auto',
    padding: theme.spacing(1),
    width: 18,
    cursor: 'pointer',
    '&:hover': {
      background: '#E0E0E0'
    }
  },
  diff: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.3rem',
    marginTop: 10,
  },
  dirIcon: {
    color: '#666666',
    marginRight: 10,
  },
  arrowRight: {
    color: '#666666',
    marginRight: 10,
    marginLeft: 10,
    transform: 'rotate(180deg)' 
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer',
    fontSize: '1.2rem',
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    marginLeft: 10,
  }
}))

const mappingFilter = (historical, roomTypeID, array) => {
  const tempArray = []
  array.filter(e => e.roomTypeID==roomTypeID).map(e => {
    if (historical) {
      // display all
      tempArray.push(e);
    } else {
      // If start date > today or if end date > today ---> display
      if (moment(e.startDate).diff(moment(), 'days') > -1 || moment(e.endDate).diff(moment(), 'days') > -1) tempArray.push(e);
    }
  })
  return tempArray;
}

const pastExists = (roomTypeID, array) => {
  let past = false;
  array.filter(e => e.roomTypeID==roomTypeID).map(e => {
    if (moment().diff(moment(e.startDate), 'days') > 0 && moment().diff(moment(e.endDate), 'days') > 0) {
      past=true
    }
  });
  return past;
}

const ExistingRoomMap = (props) => {
  const classes = useStyles();
  const { roomTypes, roomTypeID, handleEdit, handleDelete, room, t } = props;
  const [historical, setHistorical] = useState(false);

  const handleToggle = () => {
    if (historical) setHistorical(false)
    else setHistorical(true);
  }

  return (
    <div key={roomTypeID} className={classes.box}>
      <div className={classes.viewContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{inventoryConditionHandler(roomTypes[roomTypeID], DATA_ACTION.NAME_CODE_STATUS)}</div>
          {roomTypes[roomTypeID].isActive && <img src={IMAGES.ICONS.editPencil} onClick={() => handleEdit(roomTypeID)} className={classes.edit} />}
          {(roomTypeID == room.primaryRoomTypeID) && <div className={classes.base}>{t('beds.roomTypesAndRooms.primaryRoomType')}</div>}
          {(roomTypeID !== room.primaryRoomTypeID && !room._id) && <img className={classes.bin} onClick={() => handleDelete(roomTypeID)} src={IMAGES.ICONS.bin} />}
        </div>
        {pastExists(roomTypeID, room.mapping) && <div className={classes.link} onClick={handleToggle}>{historical ? 'hide' : 'show'} {t('beds.roomTypesAndRooms.pastMappings')}</div>}
        {mappingFilter(historical, roomTypeID, room.mapping)
          .sort((a, b) => moment(a.startDate).diff(moment(b.startDate), 'days') > 0)
          .map((link, index) => 
          <div key={index} className={classes.diff}>
            <SubDirectoryIcon className={classes.dirIcon} />
            <div className={classes.relation}>
              {moment(link.startDate).format('DD MMM, YYYY')}
            </div>
            <ArrowRight className={classes.arrowRight}/>
            <div className={classes.relation}>
              {moment(link.endDate).format('DD MMM, YYYY')}
            </div>
            {link.overlap && <div className={classes.error}>{t('beds.roomTypesAndRooms.overlappingDates')}</div>}
        </div>
        )}
      </div>
    </div>
  )
}

export default withTranslation()(ExistingRoomMap);