import http from "../../utils/http";
import { normalize } from 'normalizr';
import { buildingSchema } from "../../schema/roomTypeSchema";
import { getReservationDetails } from "../beds/beds";


// ----------------------------------------------------------------
// LOAD TAGS
// ----------------------------------------------------------------
export function getTags(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_TAGS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/tags/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data, spaceID } });
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data || errorResult;
        console.log(error);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE TAG 
//----------------------------------------------------------------
export function createTag(spaceID, body) {
  const ACTION_NAME = "CREATE_TAG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/tag`, body)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { spaceID, data }
        })

        if(body.reservationID) {
          dispatch({
            type: `RESERVATION_TAG_ADDED`,
            payload: { spaceID, data }
          })
        }

        if(body.templateID) {
          dispatch({
            type: `EMAIL_TAG_SUCCESS`,
            payload: { templateID: body.templateID, data }
          })
        }

        if(body.emailTemplate) {
          dispatch({
            type: `EMAIL_TEMPLATE_TAG_ADDED`,
            payload: { spaceID, data }
          })
        }
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data || errorResult;
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

export const resetResrvTags = (spaceID) => (dispatch) => {
  dispatch({ type: "RESERVATION_TAGS_RESET", payload: { spaceID } })
};

export const resetEmailTags = (spaceID) => (dispatch) => {
  dispatch({ type: "EMAIL_TEMPLATE_TAG_RESET", payload: { spaceID } })
};

//----------------------------------------------------------------
// UPDATE TAG 
//----------------------------------------------------------------
export function updateTag(spaceID, body) {
  const ACTION_NAME = "UPDATE_TAG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/tag`, body)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { spaceID, data }
        });
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data || errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}