import React from 'react';
import { makeStyles, Button, Tooltip } from '@material-ui/core';
// import { ICONS } from '../../../utils/imageUrls';
import { fetchNoOfNights } from '../../../utils/utility';
import pluralize from 'pluralize';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#f2f2f2',
        borderRadius: 5,
        margin: '15px 25px 15px 25px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            margin: 10,
        }
    },
    icon: {
        width: 20,
        height: 20
    },
    detailsSection: {
        display: 'flex',
        alignItems: 'center',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        fontSize: '1.0rem',
        '& > *': {
            borderLeft: '5px solid transparent',
            borderRight: '10px solid transparent',
        }
    },
    button: {
        backgroundColor: '#ffffff',
        border: '1px solid #f2f2f2',
        borderRadius: 20,
        padding: '10px 25px',
        fontSize: '1.3rem',
        color: '#13408d',
        fontWeight: '600',
    }
}))

const StayInfo = (props) => {
    const classes = useStyles();
    const { reservation } = props;
    const { nonRefundable, guestCount = 1 } = reservation;
    const nights = fetchNoOfNights(reservation.checkIn, reservation.checkOut);
    const isButtonEnabled = false//here check if the multiple guests and all stays for same duration of time

    let rateTypeText
    switch (nonRefundable) {
        case true:
            rateTypeText = 'NRR'
            break
        case false:
            rateTypeText = 'Standard'
            break
        case null:
            rateTypeText = 'Mixed'
            break
        default:
            rateTypeText = 'Standard'
            break
    }

    return (
        <div className={classes.container}>
            <div className={classes.detailsSection}>
                <div className={classes.info}>
                    <img src={IMAGES.ICONS.darkBoyBroadSmile} className={classes.icon} />
                    <div>{guestCount} {pluralize('Guest', guestCount)}</div>
                </div>
                <div className={classes.info}>
                    <img src={IMAGES.ICONS.darkSleep} className={classes.icon} />
                    <div>{nights} {pluralize('Night', nights)}</div>
                </div>
                <Tooltip title={rateTypeText === 'Mixed' ? 'NRR and Standard' : ''}>
                    <div className={classes.info}>
                        <img src={IMAGES.ICONS.taxes} className={classes.icon} />
                        <div>{rateTypeText}</div>
                    </div>
                </Tooltip>
            </div>
            {isButtonEnabled &&
                <div className={classes.buttonSection}>
                    <Button className={classes.button}>Update all</Button>
                </div>
            }
        </div>
    )
}

export default StayInfo;