import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker } from 'react-dates';
import { getMomentFormat } from '../../../../utils/utility';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    container: {
        marginRight: 20,
        width: '40%',
        maxWidth: 300,
        // [theme.breakpoints.up('lg')]: {
        //     width: '30%',
        // },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    labelContainer: {
        color: '#666666',
        display: 'flex',
        '& > div': {
            fontSize: '1rem',
            fontWeight: 600,
        }
    },
    leftLabel: {
        width: 'calc(45% + 50px)',
    },
    arrow: {
        width: 20,
        height: 20,
    },
    DateRangePicker: {
        width: '100%',
        '& > div, & > div > div, & > div > div > div': {
          width: '100%',
        },
        '& .DateInput_1': {
          width: 'calc(45% - 30px)',
        },
        '& .DateRangePickerInput_arrow': {
            marginLeft: 30,
            marginRight: 30,
        },
        '& .DateInput_input': {
          borderBottom: '0px solid rgba(0, 0, 0, 0.87)',
          width: '96%',
          paddingRight: 0,
          padding: '10px 5px 10px',
          paddingLeft: 0,
          fontSize: '1.5rem',

        },
        '& .DateInput__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DateRangePickerInput__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DateInput_input__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DayPickerNavigation_button__verticalDefault': {
          width: 'calc(50% - 12px)',
          height: 'unset',
        },
        '& .DayPickerNavigation_svg__vertical': {
        },
        '& .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2': {
            top: '45px!important',
        },
        '& .CalendarDay__selected': {
            background: '#13408d',
            border: '1px double #13408d',
        },
        '& .CalendarDay__selected_span': {
            background: '#F2F2F2',
            border: '1px double #F2F2F2',
            color: '#666666'
        },
        '& .CalendarDay__selected_span:hover': {
            background: '#DADADA',
            border: '1px double #DADADA',
        },
        '& .CalendarDay__default:hover': {
            background: '#DADADA',
            border: '1px double #DADADA',
        },
        '& .CalendarDay__selected:hover': {
            background: '#13408d',
            border: '1px double #13408d',
        },
      },
      disabledLabel: {
          color: '#828282'
      }
}));

const DurationDatePicker = (props) => {
    const classes = useStyles();
    const { isOutsideRange = () => false, isClearable = false } = props;
    const [dates, setDates] = React.useState({ startDate: null, endDate: null });
    const [focusedInput, setFocusedInput] = React.useState(null);

    React.useEffect(()=> {
        if(props.startDate && props.endDate){
            setDates({ startDate: getMomentFormat(props.startDate), endDate: getMomentFormat(props.endDate) });
        }

        if(isClearable && !props.startDate && !props.endDate){
            setDates({ startDate: null, endDate: null });
        }
    }, [props.startDate, props.endDate])


    const isDayBlocked = () => {

    }

    return (
        <div className={clsx(classes.container, 'durationDatePicker')}>
            <div className={classes.labelContainer}>
                <div className={clsx({[classes.leftLabel]: true, [classes.disabledLabel]: props.disabled})}>{props.startLabel}</div>
                <div className={clsx({[classes.rightLabel]: true, [classes.disabledLabel]: props.disabled})}>{props.endLabel}</div>
            </div>
            <div className={classes.DateRangePicker}>
                <DateRangePicker
                    disabled={props.disabled}
                    startDate={dates.startDate}
                    startDateId={"start_date_"+props.index}
                    endDate={dates.endDate}
                    endDateId={"end_date_"+props.index}
                    onDatesChange={({ startDate, endDate }) => {
                        setDates({ startDate, endDate });
                        props.onDatesChangeHandler(startDate, endDate)
                    }}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    focusedInput={focusedInput}
                    displayFormat={'DD MMM YYYY'}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={isOutsideRange}
                    // isDayBlocked={isDayBlocked}
                    numberOfMonths={props?.numberOfMonths || 1}
                    noBorder={true}
                    customArrowIcon={<img className={classes.arrow} src={IMAGES.ICONS.dateArrow} />}
                />
            </div>
        </div>
    )
}

export default DurationDatePicker;