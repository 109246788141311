import React from 'react';
import moment from 'moment';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { fetchDatePerTimezone } from '../../../utils/utility';

import ProgressInfoCard from './ProgressInfoCard';
import ActivityInfoCard from './ActivityInfoCard';
import { fetchLiveExperiences } from '../../../redux/actions/live/sidebar';
import { fetchLiveReports } from '../../../redux/actions/dashboard/live';
import { RESERVATION_STATUS } from '../../../utils/constants';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme =>({
    root:{
        // padding: '10px',
        width: '100%',
        overflow: 'hidden',
    },
    listContainer:{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        paddingRight: '17px', /* Increase/decrease this value for cross-browser compatibility */
        boxSizing: 'content-box' /* So the width will be 100% + 17px */
    },
    day: {
        marginBottom: 30
    },
    dateInfoContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 10px',
        marginTop: 10,
        padding: '10px 0px'
    },
    primaryTitle:{
        color: "#4f4f4f",
        fontSize: '1.3rem',
        fontWeight: 600,
        color: '#000'
    },
    date: {
        color: "#BDBDBD", 
        fontSize: '1.2rem',
        fontWeight: 500,
        marginLeft: 5,
    },
    loadingState: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        minHeight: 'calc(100vh - 100px)',
        justifyContent: 'center',
        width: '100%'
    },
    noExperienceContainer:{
        paddingTop: '20px',
        paddingBottom: '20px',
        textAlign: 'center',
        width: '95%',
        background: '#F8F9FB',
        borderRadius: '10px',
        margin: 10,
        marginTop: 10
    },
    weatherIcon: {
        width: 20,
        position: 'relative',
        top: '-0.75vh',
        height: 20,
        marginRight: 10
    }
}))

const weatherConditions = ['clear', 'clouds', 'drizzle', 'rain', 'thunderstorm', 'snow']
// const imageSrc = "/images/weather_icons/"

const CheckinInfo = props =>{
    const { t } = props;
    const classes = useStyles();
    const { currentSpace, live, currentSpaceID, loadingLive } = props
    const [ propertyInfo, setPropertyInfo ] = React.useState(null)
    const [ experienceInfo, setExperienceInfo ] = React.useState(null)
    const [ initial, setInitial ] = React.useState(true)

    const fetchExperienceInfo = () => {
        if(currentSpace) {
            props.dispatch(fetchLiveExperiences(currentSpace.propertyID)).then(response => {
                if(response){
                    if(!response) {
                        setExperienceInfo({})
                    }
                    else {
                        setExperienceInfo(response)
                    }
                }
            })
        }
    }

    React.useEffect(() => {
        if((live[currentSpaceID]) && (live[currentSpaceID].checkInCheckOutData)) {
            setPropertyInfo(live[currentSpaceID].checkInCheckOutData)
        }
        fetchExperienceInfo()
    }, [live[currentSpaceID]])

    let dateArray = []

    if(currentSpace) {
        dateArray.push(moment(fetchDatePerTimezone(null, currentSpace.timezone)).format("YYYY-MM-DD"))
        dateArray.push(moment(fetchDatePerTimezone(null, currentSpace.timezone)).add(1, 'day').format("YYYY-MM-DD"))
    }
    
    return(
        <div className={classes.root}>
            {loadingLive && <div className={classes.loadingState}>
                <CircularProgress />
            </div>}
            {!loadingLive && <div className={classes.listContainer}>
                {dateArray.map((data, index) =>(
                    <div key={`property_${index}`} className={classes.day}>
                        <div className={classes.dateInfoContainer}>
                            {(index === 0) && <div className={classes.primaryTitle}>{t('live.sidebar.today')} <span className={classes.date}> {moment(data).format('ddd, MMM DD')}</span></div>}
                            {(index === 1) && <div className={classes.primaryTitle}>{t('live.sidebar.tomorrow')} <span className={classes.date}> {moment(data).format('ddd, MMM DD')}</span></div>}
                            {(propertyInfo && propertyInfo[data]) && (propertyInfo[data].weather) && (propertyInfo[data].weather.temperature) && 
                                <div className={classes.primaryTitle}>
                                    {(weatherConditions.includes(propertyInfo[data].weather.type)) && <span style={{float: "left"}}><img src={IMAGES.ICONS?.[propertyInfo[data].weather.type] || ''} className={classes.weatherIcon} /></span>}
                                    {(!weatherConditions.includes(propertyInfo[data].weather.type)) && <span style={{float: "left"}}><img src={IMAGES.ICONS.other} className={classes.weatherIcon} /></span>}
                                    <span style={{float: "right"}}>{Math.round(propertyInfo[data].weather.temperature)}<sup> o</sup> C </span>
                                </div>
                            }
                        </div>
        
                        {propertyInfo && <ProgressInfoCard 
                            progressBarColor="yellow"
                            titleSection1={t('live.sidebar.checkIn')}
                            titleSection2={t('live.sidebar.done')}
                            valueSection1={((propertyInfo[data]) && (propertyInfo[data].checkIn)) ? (propertyInfo[data].checkIn.total ? propertyInfo[data].checkIn.total : 0) : 0} //main
                            valueSection2={((propertyInfo[data]) && (propertyInfo[data].checkIn)) ? propertyInfo[data].checkIn.done ? propertyInfo[data].checkIn.done : 0 : 0} //done
                            progressVal={((propertyInfo[data]) && (propertyInfo[data].checkIn)) ? (((propertyInfo[data].checkIn.done) && (propertyInfo[data].checkIn.total)) ? (propertyInfo[data].checkIn.done/propertyInfo[data].checkIn.total)*100 : 0) : 0}
                            reservationStatus={RESERVATION_STATUS.CONFIRMED}
                            today={index === 0}
                        />}
        
                        {propertyInfo && <ProgressInfoCard 
                            progressBarColor="brown"
                            titleSection1={t('live.sidebar.checkOut')}
                            titleSection2={t('live.sidebar.done')}
                            valueSection1={((propertyInfo[data]) && (propertyInfo[data].checkOut)) ? (propertyInfo[data].checkOut.total ? propertyInfo[data].checkOut.total : 0) : 0}
                            valueSection2={((propertyInfo[data]) && (propertyInfo[data].checkOut)) ? (propertyInfo[data].checkOut.done ? propertyInfo[data].checkOut.done : 0) : 0}
                            progressVal={((propertyInfo[data]) && (propertyInfo[data].checkOut)) ? (((propertyInfo[data].checkOut.done) && (propertyInfo[data].checkOut.total)) ? (propertyInfo[data].checkOut.done/propertyInfo[data].checkOut.total)*100 : 0) : 0}
                            reservationStatus={RESERVATION_STATUS.CHECKED_OUT}
                            today={index===0}
                        />}
        
                        {propertyInfo && <ProgressInfoCard
                            titleSection1={t('live.sidebar.inHouse')}
                            titleSection2={t('live.sidebar.emptyBeds')}
                            valueSection1={((propertyInfo[data]) && (propertyInfo[data].inHouse)) ? (propertyInfo[data].inHouse.total ? propertyInfo[data].inHouse.total : 0) : 0}
                            valueSection2={((propertyInfo[data]) && (propertyInfo[data].inHouse)) ? (propertyInfo[data].inHouse.emptyBeds ? propertyInfo[data].inHouse.emptyBeds : 0) : 0}
                            progressVal={((propertyInfo[data]) && (propertyInfo[data].inHouse)) ? (((propertyInfo[data].inHouse.emptyBeds) && (propertyInfo[data].inHouse.total)) ? (propertyInfo[data].inHouse.total)/(propertyInfo[data].inHouse.total + propertyInfo[data].inHouse.emptyBeds)*100 : 0) : 0}
                            reservationStatus={RESERVATION_STATUS.IN_HOUSE}
                            today={index===0}
                        />}
        
                        {experienceInfo && (experienceInfo[data]) && <span>
                            {experienceInfo[data].map((data, index) =>(
                                <span key={`experience_${index}`}>
                                    <ActivityInfoCard
                                        title = {data.productName}
                                        time = {(data.allDay) ? "All Day Experience" : `${data.startTime} - ${data.endTime}`}
                                        location = {(data.locationName) ? data.locationName : null}
                                        personName = {(data.conductorName) ? data.conductorName : null}
                                    />
                                </span>
                            ))}
                        </span>}

                        {experienceInfo && (!experienceInfo[data]) && <div style={{color: "#898989"}} className={classes.noExperienceContainer}>
                            {(index === 0) && <span>{t('live.sidebar.noExperiencesToday')}</span>}
                            {(index === 1) && <span>{t('live.sidebar.noExperiencesTomorrow')}</span>}
                        </div>}
                    </div>
                ))}
            </div>}
        </div>
    )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingLive: state.loading.LOAD_LIVE,
  live: state.live
})

export default withTranslation()(connect(mapStateToProps)(CheckinInfo))