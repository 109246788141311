const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_TODOS_SUCCESS":
      return {
        ...state,
        ...action.payload.todos
      }
    case "UPDATE_TODO_SUCCESS":
      const toggleTodo = state[action.payload.todoID]
      return {
        ...state,
        [action.payload.todoID]: {...toggleTodo, ...action.payload.todo[action.payload.todoID]}
      }
    case "EDIT_TODO_SUCCESS":
      const editTodo = state[action.payload.todoID]
      return {
        ...state,
        [action.payload.todoID]: {...editTodo, ...action.payload.todo[action.payload.todoID]}
      }
    case "ADD_TODO_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "FETCH_TODO_SUCCESS":
      return {
        ...state,
        [action.payload.todoID]: action.payload.todo[action.payload.todoID]
      }
    case "DELETE_TODO_SUCCESS":
      const newState = {
        ...state  
      }
      delete newState[action.payload._id]
      return {
        ...newState,
      }
    case "ADD_TODO_COMMENT_SUCCESS":
      const currentTodo = state[action.payload.todoID]
      return {
        ...state,
        [action.payload.todoID]: {
          ...currentTodo,
          comments: [...currentTodo.comments, action.payload.data._id]
        }
      }
    case "RESET_TODOS":
      return {
        ...initialState
      };
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    default:
      return state;
  }
};
  

