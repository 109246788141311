import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withTranslation } from "react-i18next";

import { loadSibaSetup, submitSibaSetup } from "../../redux/actions/legal/siba";
import { resetSuccess } from "../../redux/actions/common/common";
import {
  fetchProperty,
  editProperty,
  resetError,
} from "../../redux/actions/dashboard/property";

import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

import Setup from "./Setup";
import Scheduling from "./Scheduling";
import Processed from "./Processed";
import Queue from "./Queue";
import Failed from "./Failed";

import IMAGES from "../../constants/images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  loadingState: {
    width: "100%",
    height: "400px",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  listSection: {
    height: "calc(100vh - 186px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
    position: "relative",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    marginTop: 10,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#A2A2A2",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  bottomMargin: {
    marginBottom: 25,
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  configSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
  },
  tabsContainer: {
    marginBottom: 15,
    "& .MuiTabs-scroller": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTabs-scroller": {
        whiteSpace: "unset",
        display: "block",
      },
    },
  },
  tab: {
    background: "#ffffff",
    borderRadius: "15px",
    boxShadow: "0 2px 40px 0 #eeeeee",
    fontSize: "1.1rem",
    maxWidth: "85px",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    width: "85px",
    minWidth: 45,
    margin: 5,
    "&.Mui-selected": {
      background: "#E0E0E0;",
    },
  },
  setupPanel: {
    //borderRadius: '15px',
    //boxShadow: '0 2px 40px 0 #eeeeee',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
    flexGrow: 1,
    // '& .MuiBox-root': {
    //   overflowY: 'auto'
    // }
  },
  schedulingPanel: {
    //borderRadius: '15px',
    //boxShadow: '0 2px 40px 0 #eeeeee',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
    flexGrow: 1,
    // '& .MuiBox-root': {
    //   overflowY: 'auto'
    // }
  },
  qpPanel: {
    //borderRadius: '15px',
    //boxShadow: '0 2px 40px 0 #eeeeee',
    flexGrow: 1,
    "& .MuiBox-root": {
      overflowY: "auto",
    },
  },
  disableScroll: {
    overflowY: "hidden",
  },
  error: {
    marginTop: 10,
    color: "red",
    fontSize: "1.2rem",
  },
  adjustments: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}

const Siba = (props) => {
  const classes = useStyles();
  const { open, handleClose, isLoading, errors, currentSpace, property, t } =
    props;
  const [form, setForm] = React.useState({});
  const [value, setValue] = React.useState("setup");
  const [toggle, setToggle] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [error, setError] = React.useState({
    nipc: false,
    establishment: false,
    accessKey: false,
    abbreviation: false,
    contactName: false,
    contactEmail: false,
    zipzone: false,
  });

  useEffect(() => {
    if (property.features) {
      let ocFeature = _.find(property.features, { name: "Siba" });
      if (ocFeature && ocFeature.isEnabled === "true") {
        setToggle(true);
      }
    }
  }, [property]);

  useEffect(() => {
    if (property.default)
      props.dispatch(fetchProperty(currentSpace.propertyID));
    props.dispatch(loadSibaSetup(currentSpace.propertyID));
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setError({ ...error, [e.target.id]: false });
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCloseError = () => {};

  const handleCloseSuccess = () => {
    props.dispatch(resetSuccess("SUBMIT_SIBA"));
  };

  // const handleValidate = () => {
  //   let foundError = false;
  //   let errorStep = {};
  //   const mandatoryFields = ['nipc', 'establishment', 'accessKey', 'abbreviation', 'contactName', 'contactEmail', 'zipzone'];
  //   mandatoryFields.map(item => {
  //     if (!form[item]) {
  //       errorStep[item] = true;
  //       foundError = true;
  //     }
  //   })
  //   if (foundError) {
  //     setError({ ...error, ...errorStep })
  //     return false
  //   }
  //   else return true
  // }

  // const handleSubmit = () =>{
  //   const check = handleValidate();
  //   if (check) {
  //     props.dispatch(submitSiba({...form, propertyID: currentSpace.propertyID}))
  //   }
  // }

  const handleToggle = (value) => {
    setToggle(value);
    if (!value) {
      props.dispatch(
        editProperty(currentSpace.propertyID, { disableAppFeature: "Siba" })
      );
    } else {
      props.dispatch(
        editProperty(currentSpace.propertyID, { appFeature: "Siba" })
      );
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={clsx({ [classes.dialog]: true })}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Siba</div>
          <AccessHeaderAction moduleID={MODULE.SIBA.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {/* --------------------------------------------------------- */}
        {/* MAIN CONTENT */}
        {/* --------------------------------------------------------- */}

        <div
          className={clsx({
            [classes.listSection]: true,
            [classes.disableScroll]: isLoading,
          })}
        >
          {isLoading && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}

          {!isLoading && (
            <div className={classes.configSection}>
              <div className={classes.adjustments}>
                <div className={classes.firstRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    {t("automate.siba.helperText")}
                  </div>
                </div>

                <div className={classes.row}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.bottomMargin}
                  >
                    <Grid item xs={7}>
                      <div className={classes.title}>Siba</div>
                    </Grid>
                    <Grid item xs={5}>
                      <div className={classes.toggle}>
                        <div
                          className={clsx(classes.toggleButton, classes.left)}
                          style={{
                            background:
                              toggle === false ? "#F2F2F2" : "#FFFFFF",
                          }}
                          onClick={() => handleToggle(false)}
                        >
                          OFF
                        </div>
                        <div
                          className={clsx({
                            [classes.toggleButton]: true,
                            [classes.right]: true,
                            [classes.selected]: toggle === true,
                          })}
                          onClick={() => handleToggle(true)}
                        >
                          ON
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {toggle && (
                  <Tabs
                    orientation="horizontal"
                    aria-label="Vertical tabs example"
                    className={classes.tabsContainer}
                    value={value}
                    variant="fullWidth"
                    scrollButtons={windowWidth > 901 ? "auto" : "on"}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                  >
                    <Tab
                      value="setup"
                      /*icon={<img src="/images/icons/calendar.png" className={classes.tabIcon} />}*/
                      className={clsx(classes.tab, classes.tabSpace)}
                      {...a11yProps("setup")}
                      label={t("automate.siba.setup")}
                    />
                    <Tab
                      value="scheduling"
                      /*icon={<img src="/images/icons/guests.png" className={classes.tabIcon} />}*/
                      className={clsx(classes.tab, classes.tabSpace)}
                      {...a11yProps("scheduling")}
                      label={t("automate.siba.scheduling")}
                    />
                    <Tab
                      value="queue"
                      /*icon={<img src="/images/icons/conversation.png" className={classes.tabIcon} />}*/
                      className={clsx(classes.tab, classes.tabSpace)}
                      {...a11yProps("queue")}
                      label={t("automate.siba.queue")}
                    />
                    <Tab
                      value="processed"
                      /*icon={<img src="/images/icons/wallet.png" className={classes.tabIcon} />}*/
                      className={clsx(classes.tab, classes.tabSpace)}
                      {...a11yProps("processed")}
                      label={t("automate.siba.processed")}
                    />
                    <Tab
                      value="failed"
                      /*icon={<img src="/images/icons/wallet.png" className={classes.tabIcon} />}*/
                      className={clsx(classes.tab, classes.tabSpace)}
                      {...a11yProps("failed")}
                      label={t("automate.siba.failed")}
                    />
                  </Tabs>
                )}
              </div>

              {toggle && (
                <div>
                  <TabPanel
                    className={classes.setupPanel}
                    value={value}
                    index={"setup"}
                  >
                    <Setup />
                  </TabPanel>
                  <TabPanel
                    className={classes.schedulingPanel}
                    value={value}
                    index={"scheduling"}
                  >
                    <Scheduling />
                  </TabPanel>
                  <TabPanel
                    className={classes.qpPanel}
                    value={value}
                    index={"processed"}
                  >
                    <Processed />
                  </TabPanel>
                  <TabPanel
                    className={classes.qpPanel}
                    value={value}
                    index={"queue"}
                  >
                    <Queue />
                  </TabPanel>
                  <TabPanel
                    className={classes.qpPanel}
                    value={value}
                    index={"failed"}
                  >
                    <Failed />
                  </TabPanel>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ------------- Snackbar success messages -------------- */}
      {props.success && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={handleCloseSuccess}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Successfully saved your profile</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleCloseSuccess}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      )}

      {/* ------------- Snackbar error messages -------------- */}
      {errors && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errors}</span>}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  isLoading: state.loading.LOAD_SETUP,
  errors: state.errors.SUBMIT_SIBA,
  success: state.success.SUBMIT_SIBA,
  property: state.property,
});

export default withTranslation()(connect(mapStateToProps)(Siba));
