import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setupCashAccounts } from '../../redux/actions/cash-drawers/cashAccounts';

import { Button, Typography, makeStyles, InputBase } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 30px)',
    marginTop: 30,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '1.3rem',
    marginBottom: 10,
  },
  bulbContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: '#A2A2A2',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    marginLeft: 20,
  },
  bubble: {
    background: '#FFFFFF',
    borderRadius: '15px',
    boxShadow: "0 1px 10px solid #BDBDBD",
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 10,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  selected: {
    background: '#8CB2A4',
    color: '#ffffff',
  },
  add: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginTop: 5,
    marginBottom: 10
  },
  icon: {
    fontSize: '1.6rem',
    marginRight: 5,
  },
  addText: {
    fontSize: '1.2rem'
  },
  field: {
    background: '#FFFFFF',
    borderRadius: '15px',
    boxShadow: "0 1px 10px solid #BDBDBD",
    marginBottom: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative'
  },
  doneIcon: {
    color: '#666666',
    cursor: 'pointer',
    position: 'absolute',
    right: 12,
    top: 12
  },
  buttonContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  next: {
    borderRadius: '25px',
    fontWeight: 600,
    fontSize: '1.5rem',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  }
}))

const Accounts = (props) => {

  const classes = useStyles();
  const { propertyID, currentSpaceID } = props;
  const [defaultAccountList, setDefaultAccountList] = React.useState([
    {
      name: 'Guests',
      subCategory: ['Events', 'Activity', 'Marketing', 'Transport']
    },
    {
      name: 'Office Admin',
      subCategory: ['Advertizing & Promos', 'Business Licenses', 'Office Supplies', 'Printing']
    },
    {
      name: 'Staff',
      subCategory: ['Salaries & Wages', 'Bonus', 'Allowance']
    },
    {
      name: 'Inventory',
      subCategory: ['Food', 'Beverages', 'Gift Shop Items']
    },
    {
      name: 'Operations',
      subCategory: ['Breakfast', 'Cleaning', 'Maintenance', 'Laundry', 'Linen', 'Utilities']
    },
    {
      name: 'Misc',
      subCategory: ['Misc']
    },
  ]);
  const [form, setForm] = React.useState({
    ["Guests"]: [],
    ["Inventory"]: [],
    ["Office Admin"]: [],
    ["Staff"]: [],
    ["Operations"]: [],
    ["Misc"]: [],
  });
  const [add, setAdd] = React.useState({
    ["Guests"]: false,
    ["Inventory"]: false,
    ["Office Admin"]: false,
    ["Staff"]: false,
    ["Operations"]: false,
    ["Misc"]: false,
  });
  const [temp, setTemp] = React.useState({});

  const handleChange = (value, categoryName) => {
    const item = form[categoryName]
    const found = item.find(e => e===value);
    if (found) {
      const newArr = item.filter(e => e!==value);
      setForm({...form, [categoryName]: newArr});
    } else {
      item.push(value);
      setForm({...form, [categoryName]: item})
    }
  }

  const handleAdd = (category) => {
    if (add[category]) {
      console.log('exists');
    } else {
      setAdd({...add, [category]: true })
    }
  }

  const handleAddSubCategory = (category) => {
    let newArr = defaultAccountList.find(e => e.name===category).subCategory;
    newArr.push(temp[category]);
    setForm({...form, [category]: [...form[category], temp[category]]})
    setTemp({...temp, [category]: ''});
    setAdd({...add, [category]: false })
  }

  const handleSubmit = () => {
    let submitCategory=[];
    Object.keys(form).map(formCategory => {
      form[formCategory].length && submitCategory.push({
        "name": formCategory,
        "subCategory": form[formCategory].map(e => ({"name": e}))
      })
    })
    console.log(submitCategory);
    props.dispatch(setupCashAccounts(currentSpaceID, propertyID, submitCategory));
    props.handleNext();
  }

  /* New Sub Category */
  /* -------------------------------------------------------- */
  const newSubCategory = (category) => {
    return (
      <div>
        {add[category] && <div className={classes.field}>
          <InputBase 
            id={category}
            name={category}
            fullWidth
            type="text"
            onChange={(e) => setTemp({...temp, [category]: e.target.value})}
          />
          <DoneIcon className={classes.doneIcon} onClick={() => handleAddSubCategory(category)}/>
        </div>}

        {!add[category] && <div className={classes.add} onClick={() => handleAdd(category)}>
          <AddIcon className={classes.icon} /> 
          <div className={classes.addText}>Add Account</div>
        </div>}
      </div>
    )
  }

  return (
    <div className={classes.root}>

      <div className={classes.row}>
        <div className={classes.title}>
          Select cash-out accounts for your reception
        </div>
        <div className={classes.bulbContainer}>
          <div>
            <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
          </div>
          <div className={classes.helperText}>
            Reception staff will be required to classify all cash outflows with one
            of the following accounts. This helps clean up accounting, deter 
            fraud and reduce back office work.
          </div>
        </div>
      </div>

      {defaultAccountList.map((item, index) => (
        <div key={index} className={classes.row}>
          
          {/* Category Name */}
          {/* -------------------------------------------------------- */}
          <div className={classes.title}>{item.name}</div>
          
          {/* Sub Categories */}
          {/* -------------------------------------------------------- */}
          <div className={classes.bubbleContainer}>
            {item.subCategory.map((categoryItem) => (
              <div 
                key={categoryItem}
                className={clsx({[classes.bubble]: true, [classes.selected]: Boolean(form[item.name].find(e => e===categoryItem)) })} 
                onClick={() => handleChange(categoryItem, item.name)}
              >
                {categoryItem}
              </div>    
            ))}
          </div>
          
          {/* Add New Sub Category */}
          {/* -------------------------------------------------------- */}
          {newSubCategory(item.name)}

        </div>
      ))}

    
      <div className={clsx(classes.row, classes.buttonContainer)}>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
          Next
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID,
  currentSpaceID: state.dashboard.currentSpace,
})

export default connect(mapStateToProps)(Accounts);