import { sidebarMenu } from './constant';
import IMAGES from '../../../constants/images';

export const getNextStepInfo = (currentMainMenuID, currentSubMenuID) =>{

    const activeSubMenu = sidebarMenu[currentMainMenuID].subMenu;
    const totalSubMenus = activeSubMenu.length;
    const currentSubMenuIndex = activeSubMenu.map(data => data.id).indexOf(currentSubMenuID);
    const nextSubMenuIndex = currentSubMenuIndex + 1;

    if(nextSubMenuIndex >= totalSubMenus){
        const menuList = Object.keys(sidebarMenu);
        const currentMainMenuIndex = menuList.indexOf(currentMainMenuID);
        const nextMainStepIndex = currentMainMenuIndex + 1;

        if(!menuList[nextMainStepIndex]) return;

        const nextMainMenu = sidebarMenu[menuList[nextMainStepIndex]];

        return {
            mainMenuID: nextMainMenu.id,
            subMenuID: nextMainMenu.subMenu[0].id
        }
    } 

    return {
        mainMenuID: currentMainMenuID,
        subMenuID: activeSubMenu[nextSubMenuIndex].id
    }

}

export const getPrevStepInfo = (currentMainMenuID, currentSubMenuID) =>{

    const activeSubMenu = sidebarMenu[currentMainMenuID].subMenu;
    const currentSubMenuIndex = activeSubMenu.map(data => data.id).indexOf(currentSubMenuID);
    const prevSubMenuIndex = currentSubMenuIndex - 1;

    if(prevSubMenuIndex < 0){
        const menuList = Object.keys(sidebarMenu);
        const currentMainMenuIndex = menuList.indexOf(currentMainMenuID);
        const prevMainMenuIndex = currentMainMenuIndex - 1;

        if(!menuList[prevMainMenuIndex]) return;
        
        const prevMainMenu = sidebarMenu[menuList[prevMainMenuIndex]];
        const prevMenuLength = prevMainMenu.subMenu.length;
  
        return {
            mainMenuID: prevMainMenu.id,
            subMenuID: prevMainMenu.subMenu[prevMenuLength - 1].id
        }
    } 

    return {
        mainMenuID: currentMainMenuID,
        subMenuID: activeSubMenu[prevSubMenuIndex].id
    }

}

export const getSubMenuList = () =>{
    const menuList = Object.keys(sidebarMenu);
    let subMenuList = [];

    menuList.forEach(menuID =>{
        const tempData = sidebarMenu[menuID].subMenu.map(data => data.id);
        subMenuList = [ ...subMenuList, ...tempData ];
    });

    return subMenuList;
}

export const getMenuStepInfo = subMenuID =>{

    const subMenuList = getSubMenuList();

    const stepNo = subMenuList.indexOf(subMenuID) + 1;

    const totalSteps = subMenuList.length;

    const progressVal = Math.ceil(stepNo * 100 / totalSteps);

    return { stepNo, totalSteps, progressVal }
}

export const getHeaderTitle = (mainMenuID, subMenuID) =>{

    const activeMenuIfo = sidebarMenu[mainMenuID];

    const activeSubMenuInfo =  activeMenuIfo.subMenu.find(data => data.id == subMenuID);

    return activeSubMenuInfo.title;
}

export const getSubMenuStatus = (mainMenuID, subMenuID, productData) =>{
    
    let success = false;

    // BASIC INFORMATION -----------------------------------------------------
    if(mainMenuID == sidebarMenu.BASIC_INFORMATION.id){
        switch(subMenuID){

            case "experienceName":
                success = Boolean(productData.title);
                return success;
    
            case "theme":
                success = productData.categories && productData.categories.length > 0;
                return success;
            
            case "description":
                success = Boolean(productData.description);
                return success;
    
            case "language":
                success = Boolean(productData.language);
                return success;
    
            default:
                return;
        }
    }

    // EXPERIENCE PAGE -----------------------------------------------------
    if(mainMenuID == sidebarMenu.EXPERIENCE_PAGE.id){
        switch(subMenuID){

            case "activityLocation":
                success = productData.locations && productData.locations.length > 0;
                return success;
    
            case "provisions":
                success = productData.inclusions && productData.inclusions.length > 0;
                return success;
            
            case "requirements":
                success = productData.exclusions && productData.exclusions.length > 0;
                return success;
    
            case "hostDetails":
                const { conductorName, conductorType, conductorCategory } = productData;
                success = conductorName && conductorType && conductorCategory;
                return success;
    
            case "photos":
                success = productData.photos && productData.photos.length > 0;
                return success;
    
            case "startPoint":
                const { externalAddress, atProperty } = productData;

                if(!atProperty){
                    success = externalAddress && 
                    externalAddress.city && 
                    externalAddress.country&&
                    externalAddress.zipCode;
                }

                if(atProperty) success = true;
    
                return success;
    
            case "groupSize":
                success = productData.minSize && productData.maxSize;
                return success;
    
            case "pricing":
                success = productData.price || productData.price === 0;
                return success;
    
            default:
                return;
        }
    }

    // SETTINGS -----------------------------------------------------
    if(mainMenuID == sidebarMenu.SETTINGS.id){
        switch(subMenuID){

            case "bookingDetails":
            case "variations":
    
                success = productData.grades && 
                    Object.keys(productData.grades).length > 0;
                return success;
    
            case "guidelines":
                success = productData.isPublished;
                return success;
    
            default:
                return;
        }
    }
}

export const getExperienceStatus = (productData) =>{
    let completedStepCount = 0;
    let totalStepCount = 0; 

    Object.keys(sidebarMenu).forEach(mainMenuID =>{
        const subMenuList = sidebarMenu[mainMenuID].subMenu;

        subMenuList.forEach(subMenu =>{
            const success = getSubMenuStatus(mainMenuID, subMenu.id, productData);
            if(success) completedStepCount += 1;
            totalStepCount += 1;
        });
    });
    return { completedStepCount, totalStepCount };
}

export const getPublishedExperienceCount = (productList) =>{
    let publishedExperienceCount = 0;

    Object.keys(productList).forEach(productID =>{
        const productData = productList[productID];
        
        const success = getSubMenuStatus('SETTINGS', 'guidelines', productData);
        if(success) publishedExperienceCount += 1;
    });

    return publishedExperienceCount;
}


export const getProgress = (productData) => {
    let count = 0

    if (Boolean(productData.title))
        count += 1
    
    if (productData.categories && productData.categories.length > 0)
        count += 1
    
    if (Boolean(productData.description))
        count += 1
    
    if (Boolean(productData.language))
        count += 1
    
    if (productData.locations && productData.locations.length > 0)
        count += 1
    
    if (productData.inclusions && productData.inclusions.length > 0)
        count += 1
            
    if (productData.exclusions && productData.exclusions.length > 0)
        count += 1
    
    if (productData.conductorName && productData.conductorType && productData.conductorCategory)
        count += 1
    
    if (productData.photos && productData.photos.length > 0)
        count += 1
    
    if(!productData.atProperty) {
        if (productData.externalAddress && 
            productData.externalAddress.city && 
            productData.externalAddress.country &&
            productData.externalAddress.zipCode) 
            count += 1
    } else if (productData.atProperty) count += 1
    
    if (productData.minSize && productData.maxSize)
        count += 1
    
    // Booking details
    count += 1
    
    if (productData.price)
        count += 1

    if (productData.grades && Object.keys(productData.grades).length > 0)
        count += 1
    
    if (productData.isPublished)
        count += 1
              
    return count
}

export const validateWordCount = (value) => {
    var words = value.split(/\s+/);
    var numWords = words.length;
    var minWords = 100;
    if(numWords < minWords) return false;
    return true;
}

export const validateText = value =>{
    const success = Boolean(value);
    return success;
}

export const validateArrayLength = (arrayData) => {
    if (arrayData.length > 0) return true;
    return false
}

export const validateArrayKeyHasValue = (arrayData, key, value) => {
    if (arrayData.length > 0) {
        const hasValue = arrayData.find(data => data[key] == value);
        if (hasValue) return true;
    }
    return false
}

export const defaultImageUrl = IMAGES.ICONS.experienceDefault;