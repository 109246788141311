export function resetError(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

export function resetSuccess(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

export function resetReducerState(action){
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}