import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Taxes from './Taxes';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles ( theme => ({
  container: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
    width: 'calc(100% - 40px)'
  },
  title: {
    color: "#4f4f4f",
    fontWeight: 600
  }
}))


const TaxPosSnapshot = (props) => {
  const { t } = props
  const classes= useStyles();
  const [taxModal, setTaxModal] = React.useState(false);

  const handleOpenTaxModal = () => {
    setTaxModal(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }

  const handleCloseTaxModal = () => {
    setTaxModal(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }
  
  return(
    <>
      <div className={classes.container} onClick={handleOpenTaxModal}>
        <div className={classes.title}>{t('beds.taxesSnapshot.title')}</div>
      </div>

      {taxModal && 
        <Taxes
          open={taxModal} 
          handleCloseTaxModal={handleCloseTaxModal} 
        />
      }
    </>
  )
}


export default withTranslation()(TaxPosSnapshot);