import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import { RESERVATION_STATUS } from "../../../utils/constants";
import {
  convertArrayToCSV,
  generateCSV,
  currencyToNumber,
} from "../../../utils/utility";

import {
  fetchTaxInfo,
  resetTaxReport,
} from "../../../redux/actions/reports/tax";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../../redux/actions/beds/beds";
import { loadReservationInvoice } from "../../../redux/actions/reservation/editReservation";
import { taxProcessing } from "../../../redux/selectors/reports/tax";
import Reservation from "../../reservations/Reservation";

import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MaterialTable, { MTableBody } from "material-table";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";

import { groupingConfig } from "../../utils/excelExport";

import TaxTable from "./TaxTable";
import { WORKSPACE_URL } from "../../../config";

const reservationStatus = [
  { value: RESERVATION_STATUS.CONFIRMED, label: "Confirmed" },
  { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: "Online Check In" },
  { value: RESERVATION_STATUS.IN_HOUSE, label: "In House" },
  { value: RESERVATION_STATUS.CHECKED_OUT, label: "Checked Out" },
  { value: RESERVATION_STATUS.NO_SHOW, label: "No Show" },
  { value: RESERVATION_STATUS.CANCELLED, label: "Cancelled" },
];

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 20px #F2F2F2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  listContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 88px)",
    },
  },
  listMain: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginTop: 30,
    },
  },
  listSection: {
    margin: theme.spacing(3),
    marginBottom: 40,
    "& .material-icons": {
      color: "#999999",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      marginBottom: 40,
    },
  },
  rightAlign: {
    maxWidth: 1000,
  },
  label: {
    color: "#000000",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 20,
  },
  fieldContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  dateHolder: {
    "& .DateRangePicker_picker": {
      top: "45px !important",
    },
    "& .DateInput": {
      width: 120,
    },
    "& .DateInput_input": {
      width: "calc(100% - 22px)",
    },
    "& .DateRangePickerInput": {
      border: "1px solid #E0E0E0",
      borderRadius: 5,
    },
  },
  lastRow: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    width: "100%",
  },
  resStatus: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  options: {
    borderRadius: 10,
    boxShadow: "0 2px 30px #F0F0F0",
    paddingLeft: 10,
    paddingRight: 10,
    "& input": {
      borderRadius: 10,
      color: "#484848",
      fontWeight: 500,
    },
  },
  selected: {
    background: "#4F9581",
    color: "#ffffff",
    fontWeight: 600,
  },
  tableContainer: {
    boxShadow: "0 2px 20px #F0F0F0",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  desc: {
    color: "#666666",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: 5,
  },
  thead: {
    background: "#F6F2EE",
  },
  trow: {
    cursor: "pointer",
  },
  roomTypes: {
    display: "flex",
    flexDirection: "column",
  },
  highlighted: {
    color: "red",
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 300,
    justifyContent: "center",
    width: "100%",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toggleSwitch: {
    display: "flex",
    width: "200px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 30px #E0E0E0",
    height: "40px",
    fontSize: "1.25rem",
    fontWeight: 400,
    color: "#999999",
    borderRadius: "5px",
    overflow: "hidden",

    "& .button": {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },

    "& .button.active": {
      backgroundColor: "#4F9581",
      color: "#ffffff",
      fontWeight: 600,
    },
  },
  buttonContainer: {
    alignItems: "center",
    background: "#999999",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  go: {
    color: "#ffffff",
    fontWeight: 600,
  },
  button: {
    alignItems: "center",
    background: "#000000",
    borderRadius: "5px",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginLeft: 20,
    padding: "5px 10px",
    width: 60,
    height: 30,
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 500,
  }),
};

// const limitOptions = [
//     { label: 10, value: 10 },
//     { label: 15, value: 15 },
//     { label: 20, value: 20 },
// ]

const BasicExport = (props) => {
  const rowCount = props.data.length;
  const {
    t,
    paginationProps,
    currencyData,
    openInNewTab,
    handleOpenReservation,
    handleFetchInvoice,
  } = props;
  const tableTitle = `Tax ${t("reports.report")}`;
  const displayDate = props.sameDate
    ? props.startDate
    : `${props.startDate} - ${props.endDate}`;
  let excelHeaderType = [
    "string",
    "string",
    "string",
    "string",
    "number",
    "number",
    "number",
    "string",
    "string",
  ];
  let excelColWidth = [40, 20, 20, 20, 20, 20, 20, 20, 20];

  const groupingConfigParmas = {
    data: props.data,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
  };

  return (
    <MaterialTable
      title={`${tableTitle} ${displayDate}`}
      columns={[
        { title: "Guest Name", field: "guestName", align: "left" },
        { title: "Reservation Code", field: "resvCode", align: "left" },
        { title: "Check In", field: "checkIn", align: "left" },
        { title: "Check Out", field: "checkOut", align: "left" },
        {
          title: `Total Amount (${currencyData.code})`,
          field: "totalAmount",
          align: "left",
        },
        {
          title: `Balance (${currencyData.code})`,
          field: "balance",
          align: "left",
        },
        {
          title: `Total Tax (${currencyData.code})`,
          field: "totalTax",
          align: "left",
        },
        { title: "Invoice Number", field: "invoiceNumber", align: "left" },
        //{ title: 'Paid Amount', field: 'amountPaid', align: 'left' },
        { title: "Reservation Status", field: "status", align: "left" },
      ]}
      data={props.data}
      localization={{
        toolbar: { exportCSVName: "Export as Excel" },
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: "1.2rem" },
        paging: rowCount > 50 ? true : false,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions:
          rowCount > 100 ? [50, 75, 100, rowCount] : [50, 75, 100],
        headerStyle: { backgroundColor: "#F6F2EE", zIndex: 0 },
        detailPanelColumnAlignment: "left",
        actionsColumnIndex: -1,
      }}
      detailPanel={(rowData) => (
        <TaxTable id={rowData.id} taxesApplied={rowData.taxesApplied} />
      )}
      actions={[
        {
          icon: "receipt",
          tooltip: "Open Invoice",
          onClick: (event, rowData) => handleFetchInvoice(rowData.resID),
        },
        {
          icon: "open_in_new",
          tooltip: "Open in a new tab",
          onClick: (event, rowData) => openInNewTab(rowData.resvCode),
        },
      ]}
      onRowClick={(event, rowData, togglePanel) =>
        handleOpenReservation(rowData.resID)
      }
    />
  );
};

const Tax = (props) => {
  const { t } = props;

  const presetRanges = [
    { value: "today", label: t("reports.presetRange.today") },
    { value: "yesterday", label: t("reports.presetRange.yesterday") },
    { value: "tomorrow", label: t("reports.presetRange.tomorrow") },
    { value: "past15", label: t("reports.presetRange.past15") },
    { value: "past30", label: t("reports.presetRange.past30") },
    { value: "custom", label: t("reports.presetRange.custom") },
  ];

  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    currentProperty,
    reservations,
    loadingTaxReport,
    reports,
  } = props;
  const [openReservation, setOpenReservation] = React.useState(false);
  const [dates, setDates] = React.useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [submittedDates, setSubmittedDates] = React.useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [presetValue, setPresetValue] = React.useState(presetRanges[0]);
  const [configType, setConfigType] = React.useState("checkout");
  const [goFullScreen, setGoFullscreen] = React.useState(false);
  const [status, setStatus] = React.useState([
    RESERVATION_STATUS.CONFIRMED,
    RESERVATION_STATUS.IN_HOUSE,
    RESERVATION_STATUS.CHECKED_OUT,
  ]);
  const currencyData = {
    code: currentSpace.currency,
    country: currentSpace.country,
  };

  const [selectedBookingSources, setSelectedBookingSources] = React.useState(
    []
  );

  const tableData = useSelector((state) =>
    taxProcessing(
      state.reports.tax,
      state.taxes,
      currentSpace.timezone,
      currentSpace.currency,
      status
    )
  );

  useEffect(() => {
    const loadStatus = [
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.IN_HOUSE,
      RESERVATION_STATUS.CHECKED_OUT,
    ];

    let params = {
      propertyID: currentSpace.propertyID,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      configType,
      status: loadStatus,
    };

    props.dispatch(fetchTaxInfo(params));
    document.title = `Tax ${t("reports.report")} | Counter`;

    return function cleanup() {
      props.dispatch(resetTaxReport());
      document.title = "Counter Workspace";
    };
  }, []);

  const setNewDates = (e) => {
    setPresetValue(presetRanges.find((item) => item.value == e.value));
    let reportFrom;
    let reportTo;
    if (e.value == "custom") {
      return;
    } else if (e.value === "yesterday") {
      reportFrom = moment().subtract(1, "day");
      reportTo = moment().subtract(1, "day");
    } else if (e.value === "tomorrow") {
      reportFrom = moment().add(1, "day");
      reportTo = moment().add(1, "day");
    } else {
      const num = parseInt(e.value.substr(4));
      reportFrom = moment().subtract(num, "days");
      reportTo = moment();
    }

    setDates({ startDate: reportFrom, endDate: reportTo });
  };

  const handleSubmit = () => {
    const data = {
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
      propertyID: currentSpace.propertyID,
      configType,
      status,
    };

    props.dispatch(fetchTaxInfo(data));

    setSubmittedDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const handleOpenReservation = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setOpenReservation(true);
  };

  const openInNewTab = (resID) => {
    window.open(
      `${WORKSPACE_URL}/${currentProperty.code}/reservation/${resID}`,
      "_blank"
    );
  };

  const handleCloseReservation = () => {
    setOpenReservation(false);
    props.dispatch(resetReservationDetails());
  };

  const toggleMode = (mode) => {
    if (mode !== configType) setConfigType(mode);
  };

  const handleFetchInvoice = (resID) => {
    props.dispatch(loadReservationInvoice(resID));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "userAction",
      eventCategory: "Reservation",
      eventAction: "load invoice",
      eventLabel: "Load Invoice",
    });
  };

  const setNewStatus = (value) => {
    if (value) {
      let newStatus = value.map((item) => item.value);
      setStatus(newStatus);
      return;
    }
    setNewStatus([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      style={{ maxHeight: goFullScreen ? "100%" : "100%" }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901 || goFullScreen}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>Tax {t("reports.report")}</div>
          <Hidden smDown>
            {!goFullScreen && (
              <FullScreen
                className={classes.addButton}
                onClick={() => setGoFullscreen(true)}
              />
            )}
            {goFullScreen && (
              <FullScreenExit
                className={classes.addButton}
                onClick={() => setGoFullscreen(false)}
              />
            )}
          </Hidden>
          <AccessHeaderAction moduleID={MODULE.RESERVATIONS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div
          className={classes.listContainer}
          style={{
            height:
              goFullScreen || window.innerWidth < 901
                ? "calc(100vh - 124px)"
                : "calc(100vh - 190px)",
          }}
        >
          <div className={classes.listMain}>
            <div className={clsx(classes.listSection, classes.rightAlign)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <div className={classes.fieldContainer}>
                    <Select
                      value={presetValue}
                      styles={selectStyle}
                      options={presetRanges}
                      onChange={setNewDates}
                      theme={(theme) => ({
                        ...theme,
                        border: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={4} className={classes.dateHolder}>
                  <DateRangePicker
                    startDate={dates.startDate}
                    startDateId="start_date"
                    endDate={dates.endDate}
                    endDateId="end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      setDates({ startDate, endDate });
                      setPresetValue(presetRanges[5]);
                    }}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    focusedInput={focusedInput}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder={true}
                  />
                </Grid>
                <Grid item xs={12} md={5} className={classes.toggleContainer}>
                  <div className={classes.toggleSwitch}>
                    <div
                      onClick={() => toggleMode("checkout")}
                      className={clsx("button", {
                        active: configType == "checkout",
                      })}
                    >
                      Checkout Date
                    </div>
                    <div
                      onClick={() => toggleMode("invoice")}
                      className={clsx("button", {
                        active: configType == "invoice",
                      })}
                    >
                      Invoice Date
                    </div>
                  </div>
                </Grid>

                {/* -------------RESERVATION STATUS SELECT DROPDOWN----------------- */}
                <Grid item xs={12} lg={12}>
                  <div className={classes.lastRow}>
                    <div className={classes.resStatus}>
                      <div className={classes.fieldContainer}>
                        <Select
                          defaultValue={[
                            reservationStatus[0],
                            reservationStatus[2],
                            reservationStatus[3],
                          ]}
                          styles={selectStyle}
                          isMulti
                          placeholder={t("reports.reservationPage.filterResv")}
                          options={reservationStatus}
                          onChange={setNewStatus}
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#666666",
                              primary25: "#dddddd",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t("reports.transactionPage.submit")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.listSection}>
              {loadingTaxReport && (
                <div className={classes.loadingState}>
                  <CircularProgress />
                </div>
              )}

              {!loadingTaxReport && (
                <BasicExport
                  t={t}
                  data={tableData}
                  currencyData={currencyData}
                  startDate={submittedDates.startDate.format("DD MMM YYYY")}
                  endDate={submittedDates.endDate.format("DD MMM YYYY")}
                  sameDate={moment(submittedDates.startDate).isSame(
                    submittedDates.endDate,
                    "day"
                  )}
                  handleOpenReservation={handleOpenReservation}
                  handleFetchInvoice={handleFetchInvoice}
                  openInNewTab={openInNewTab}
                />
              )}
            </div>
          </div>

          {/* Open reservation when reservation item clicked */}
          {/* ------------------------------------------------------------------ */}
          {openReservation && (
            <Reservation
              open={openReservation}
              handleCloseReservation={handleCloseReservation}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  reservations: state.reports.reservations || {},
  currentProperty: state.property,
  reports: state.reports,
  taxes: state.taxes,
  loadingTaxReport: state.loading.FETCH_TAX_REPORT,
});

export default withTranslation()(connect(mapStateToProps)(Tax));
