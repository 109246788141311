import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
    root:{
        padding: '20px 20px 10px 20px',
        // boxShadow: '0 2px 20px #E0E0E0',
        background: '#F8F9FB',
        borderRadius: '10px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '10px',
    },
    primaryTitle:{
        color: "#333333",
        fontSize: '1.4rem',
        fontWeight: 600,
        marginBottom: '10px'
    },
    secondaryTitle:{
        fontSize: '1.3rem',
        color: '#BDBDBD',
    },
    activityInfoContainer:{
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '5px',
    },
    iconContainer:{
        alignItems: 'center',
        display: 'flex',
        marginRight: '20px',
        '& img':{
            width: '15px',
            height: '15px',
            marginRight: '5px',
            opacity: 0.5
        }
    }   
}))

const ActivityInfoCard = ({
    title = '',
    time = '',
    location = '',
    personName = '',
}) =>{
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className={classes.primaryTitle}>{title}</div>
            <div className={classes.secondaryTitle}>{time}</div>
            <div className={classes.activityInfoContainer}>
                {(location) && <div className={classes.iconContainer}>
                    <img src="/images/icons/pin.png" />
                    <div className={classes.secondaryTitle}>{location}</div>
                </div>}
                {(personName) && <div className={classes.iconContainer}>
                    <img src="/images/icons/user.png" />
                    <div className={classes.secondaryTitle}>{personName}</div>
                </div>}
            </div>
        </div>
    )
}

export default ActivityInfoCard;