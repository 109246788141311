import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccommodationCard from './AccommodationCard';
import { fetchCurrencyFormat, getAmtFormat, humanise } from '../../../../utils/utility';
import { toTitleCase } from '../../../utils/wordUtils';
import { RESERVATION_STATUS } from '../../../../utils/constants';
import { editReservation } from '../../../../redux/actions/reservation/editReservation';
import { loadReservationManualOptions, resetReservationManualOptions } from '../../../../redux/actions/rooms/rooms';
import { getAccommodationName } from '../../../../utils/helper';

const styles = theme => ({

});

class GuestAccommodations extends Component {
    state = {
        accommodations: []
    };

    // ----------- life cycle ----------------
    componentDidMount() {
        this.fetchGuestAccommodation()
    }
    componentDidUpdate(prevProps) {
        if (this.props.reservation && !_.isEqual(this.props.reservation, prevProps.reservation)) {
            this.fetchGuestAccommodation()
        }
    }

    // ----------------- Data -------------------
    fetchGuestAccommodation = () => {
        const { reservation, roomTypes, rooms, beds, currentSpace } = this.props;
        const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null)

        let guestAccommodation = [...reservation.accommodationList].reduce(function (r, a) {
            r[a.guestNumber] = r[a.guestNumber] || [];
            r[a.guestNumber].push(a);
            return r;
        }, Object.create(null));

        let data = Object.keys(guestAccommodation).map(key => {
            let checkInDates = [], checkOutDates = [];
            let guestArray = [...guestAccommodation[key]];
            let isUnallocatedGuest = guestArray.find(guest => !guest.bedID);
            let index = parseInt(key) - 1;
            let accommodationItem = {
                isUnallocatedGuest: isUnallocatedGuest ? true: false,
                guestNumber: key,
                guestName: toTitleCase(
                    humanise(reservation?.guests?.[index]?.firstName, reservation?.guests?.[index]?.lastName)
                ) || 'Guest '+ key
            };

            let items = [];
            guestArray.map(item => {

                let dormOrPrivate = roomTypes?.[item.roomTypeID]?.dormOrPrivate;
                let roomName = rooms?.[item.roomID]?.name || '';
                let bedName = beds?.[item.bedID]?.name || '';
                const accomodationName = getAccommodationName({dormOrPrivate, roomName, bedName});

                checkInDates.push(item.checkIn)
                checkOutDates.push(item.checkOut)

                item.rateList.map(rate => {
                    items.push({
                        roomTypeID: item.roomTypeID,
                        formattedRate: getAmtFormat(Number(rate.rate || 0), currency),
                        rate: Number(rate.rate || 0),
                        currency: currency,
                        roomID: item.roomID,
                        bedID: item.bedID,
                        ID: item._id,
                        date: rate.date,
                        dormOrPrivate,
                        name: accomodationName

                    })
                    return rate;
                });
                return item
            });

            accommodationItem.checkIn = checkInDates.reduce(function (a, b) { return a < b ? a : b; });
            accommodationItem.checkOut = checkOutDates.reduce(function (a, b) { return a > b ? a : b; });
            accommodationItem.items = [...items];
            return accommodationItem;
        })
        this.setState({ accommodations: data });
    }

    // ----------------- api calls ----------------
    deleteGuestHandler = (guestNumber) => {
        let payload = {
            deleteGuest: {
                guestNumber: guestNumber
            }
        }
        this.callEditReservationHandler(payload);
    }

    callEditReservationHandler = (payload) => {
        const { reservation, currentSpace, calendarData } = this.props;
        payload.reservationID = reservation._id;
        console.log('payload', payload, currentSpace.propertyID)
        this.props.dispatch(editReservation(payload, currentSpace.propertyID, calendarData))
    }

    callBedSuggestionHandler = (date, duration) => {
        const { reservation, dispatch } = this.props;
        dispatch(loadReservationManualOptions(
            reservation.propertyID, {
            duration: duration,
            startDate: date
        }))
    }

    resetCallHandler = () => {
        const { dispatch } = this.props;
        dispatch(resetReservationManualOptions())
    }


    // ----------------- render --------------------
    render() {
        const { reservation, currentSpace, options, loading } = this.props;
        const accommodations = this.state.accommodations;
        const allowDataActions = ![RESERVATION_STATUS.CANCELLED, RESERVATION_STATUS.NO_SHOW].includes(reservation.status);
        return (
            <div>
                {accommodations.map((data, index) =>
                    <AccommodationCard
                        key={index}
                        data={data}
                        allowExpand={index > 0}
                        allowDelete={accommodations.length > 1}
                        allowDataActions={allowDataActions || false}
                        currentSpace={currentSpace}
                        loading={loading}
                        lockedReservation={reservation.allocationLock}

                        deleteGuestHandler={this.deleteGuestHandler}
                        changeGuestAccommodationHandler={this.callEditReservationHandler}
                        callBedSuggestionHandler={this.callBedSuggestionHandler}
                        resetCallHandler={this.resetCallHandler}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { spaces, reservationDetails } = state;
    return {
        currentSpace: spaces[state.dashboard.currentSpace] || {},
        reservation: reservationDetails.reservation || {},
        roomTypes: state.roomTypes,
        rooms: state.rooms,
        beds: state.beds,
        loading: state.loading.LOAD_RESERVATION_MANUAL_OPTIONS,
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(GuestAccommodations)));