import http from '../../utils/http';
import { normalize } from 'normalizr';
import { taxSchema } from '../../schema/taxSchema';

//----------------------------------------------------------------
// LOAD TAX ITEMS
//----------------------------------------------------------------
export function fetchTaxList(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_TAX_LIST";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/taxItems/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ taxSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE TAX ITEM
//----------------------------------------------------------------
export function createTaxItem(spaceID, propertyID, tax) {
  const ACTION_NAME = "CREATE_TAX_ITEM";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/tax/${propertyID}`, {...tax})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...data, spaceID} });
        return true
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}

//----------------------------------------------------------------
// EDIT TAX ITEM
//----------------------------------------------------------------
export function editTaxItem(propertyID, updatedTax) {
  const ACTION_NAME = "EDIT_TAX_ITEM";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/tax/${propertyID}`, {...updatedTax})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// RESET CASH ACCOUNTS
//----------------------------------------------------------------
export const resetCashAccounts = () => (dispatch) => {
  dispatch({ type: "RESET_CASH_ACCOUNTS"})
};
