import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import IMAGES from '../../constants/images';
import { getDatesOfRange } from '../../utils/utility';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex'
  },
  column: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  roomType: {
    alignItems: 'center',
    color: '#484848',
    display: 'flex',
    fontSize: '1.1rem',
    fontWeight: 600,
    overflow: 'hidden',
    paddingLeft: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bubble: {
    alignItems: 'center',
    borderRadius: '50%',
    color: '#999999',
    cursor: 'pointer',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    padding: 8,
    width: 20,
    '&:hover': {
      background: '#DADADA',
      color: '#333333',
    },
    '&.redBg':{
      background: 'rgb(228, 172, 169)',
    },
    '&.redBg $icon':{
      color: '#333333',
    }
  },
  icon: {
    fontSize: '1.6rem',
  },
  infoIcon: {
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginLeft: 10,
    opacity: 0.5
}
}))

const StopSellByDate = (props) => {
  const classes = useStyles();

  const { currentSpace, numberOfDays, t, startDate, endDate, stopSellDateInfo } = props;
  const width = (100 / numberOfDays);
  let stopSellRow = [];

  // for (let i = 1; i <= numberOfDays; i++) {
  //   stopSellRow.push(
  //     <div className={classes.column} key={i} style={{ width: `${width}%` }}>
  //       <div className={classes.bubble} onClick={() => props.stopSellByDateHandler(i - 1)} >
  //         <CancelIcon className={classes.icon} />
  //       </div>
  //     </div>
  //   )
  // }

  const dateRange = getDatesOfRange(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), null, 'stringArray');

  stopSellRow = dateRange.map((date, i) =>{
    const isStopSellApplied = stopSellDateInfo?.[date]?.stopSellAllRooms || false;
    const StopSellIcon = isStopSellApplied ? EventAvailableOutlinedIcon : CancelIcon;
    const tooltipTitle = isStopSellApplied ? 'Remove stop sell' : 'Apply stop sell';
    return (
      <div className={classes.column} key={i} style={{ width: `${width}%` }}>
        <div 
          className={clsx(classes.bubble, { redBg: isStopSellApplied })} 
          onClick={() => props.stopSellByDateHandler(i, !isStopSellApplied)} 
        >
          <Tooltip title={tooltipTitle}>
            <StopSellIcon className={classes.icon} />
          </Tooltip>
        </div>
      </div>
    )
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.container} alignItems="center">
        <Grid item xs={4} sm={2}>
          <div className={classes.roomType}>
            {t('beds.ratesSnapshot.stopSellAllRooms')}

            <Tooltip title={t('beds.ratesSnapshot.stopSellAllRoomTooltip')}>
              <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
            </Tooltip>
          </div>
        </Grid>

        <Grid item xs={8} sm={10} className={classes.row}>
          {stopSellRow}
        </Grid>
      </Grid>

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(StopSellByDate));