const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "VALIDATE_VAT_SUCCESS":
      return action.payload
    case "RESET_VAT":
      return { default: true }
    default:
      return state;
  }
};
