import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import  { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

import SingleLineChart from '../charts/SingleLineChart';
import { returnCurrent } from '../../redux/selectors/dashboard';

const useStyles = makeStyles( theme => ({
  tab: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.1rem',
    flexDirection: 'column',
    height: '30px',
    justifyContent: 'center',
    marginRight: 10,
    width: '70px'
  },
  tabSelected: {
    background: theme.palette.grey[300],
    fontWeight: 600,
  },
  tabInfo: {
    display: 'flex'
  },
  iconRow: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10
  },
  forecastBody: {
    borderRadius: '10px',
    minHeight: 210,
    height: 210,
    overflow: 'hidden',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      minHeight: 170,
    },
    '& img': {
      cursor: 'pointer',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  forecastMap: {
    height: 320
  },
}))

const dateSort = (array) => {
  let sortArray = [];
  if (array[0] && array[0].data && array[0].data.length!==0) {
    sortArray = [...array[0].data];
    sortArray.sort((a,b) => {
      return moment(a.x, 'DD MMM').diff(moment(b.x, 'DD MMM'), 'days')
    })
    return [{color: array[0].color, id: array[0].id, data: sortArray}]
  }
  return array
}

const OccupancyForecast = (props) => {
  const classes = useStyles();
  const { live, loadingLive, currentSpace, currentSpaceID, t } = props

  const [forecastTab, setForecastTab] = React.useState('all')

  const forecastTabSelect = (tab) => {
    setForecastTab(tab)
  }

  let totalInfo = [
    {
      "id": "All",
      "color": "#8CB2A4",
      "data": []
    }
  ]

  let privateInfo = [
    {
      "id": "Privates",
      "color": "#F8E7B2",
      "data": []
    }
  ]

  let dormInfo = [
    {
      "id": "Dorms",
      "color": "#AEC1E1",
      "data": []
    }
  ]

  // Blank state for future 14-day occupancy
  for (let i = 0; i < 14; i++) {
      dormInfo[0]["data"].push({"x": moment().add(i, 'd').format("DD MMM"), "y": 0})
      privateInfo[0]["data"].push({"x": moment().add(i, 'd').format("DD MMM"), "y": 0})
      totalInfo[0]["data"].push({"x": moment().add(i, 'd').format("DD MMM"), "y": 0})
  }

  // Populate future 14-day occupancy
  if (live[currentSpaceID] && live[currentSpaceID].twoWeekTotalOccupancy && live[currentSpaceID].twoWeekRoomCategoryOccupancy && 
    live[currentSpaceID].twoWeekTotalOccupancy.length!==0 && live[currentSpaceID].twoWeekRoomCategoryOccupancy!==0) {
    let privateArray = [];
    let dormArray = [];
    let totalArray = [];
    live[currentSpaceID].twoWeekTotalOccupancy.map((total, index) => {
      totalArray.push({"x": moment(total.date).format("DD MMM"), "y": Math.round(total.occupancy * 100)})
    })

    live[currentSpaceID].twoWeekRoomCategoryOccupancy.map((info, index) => {
      if(info.dormOrPrivate === 'dorm'){
        dormArray.push({"x": moment(info.date).format("DD MMM"), "y": Math.round(info.occupancy * 100)})
      }
      else {
        privateArray.push({"x": moment(info.date).format("DD MMM"), "y": Math.round(info.occupancy * 100)})
      }
    })
    dormInfo[0].data = dormArray;
    privateInfo[0].data = privateArray;
    totalInfo[0].data = totalArray;
  }

  return (
    <div>
      <div className={clsx(classes.iconRow, classes.tabInfo)}>
        {(privateInfo[0].data.length > 0) && (dormInfo[0].data.length > 0) && <div 
          id="all"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: forecastTab==='all'})}
          onClick={() => forecastTabSelect('all')} >
          {t('live.occupancy.all')}
        </div>}
        {(privateInfo[0].data.length > 0) && (dormInfo[0].data.length > 0) && <div 
          id="private"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: forecastTab==='private'})}
          onClick={() => forecastTabSelect('private')} >
          {t('live.occupancy.privates')}
        </div>}
        {(privateInfo[0].data.length > 0) && (dormInfo[0].data.length > 0) && <div 
          id="dorm"
          className={clsx({[classes.tab]: true, [classes.tabSelected]: forecastTab==='dorm'})}
          onClick={() => forecastTabSelect('dorm')} >
          {t('live.occupancy.dorms')}
        </div>}
      </div>
      {(forecastTab==='all') && <div>
        {(!totalInfo) && <div>
          <CircularProgress />
        </div>}
        {(totalInfo) && <div className={clsx(classes.forecastBody, classes.forecastMap)}>
          <SingleLineChart data={dateSort(totalInfo)} max={100}/>
        </div>}
      </div>}
      {(forecastTab==='private') && <div>
        {(!privateInfo) && <div>
          <CircularProgress />
        </div>}
        {(privateInfo) && <div className={clsx(classes.forecastBody, classes.forecastMap)}>
          <SingleLineChart data={dateSort(privateInfo)} max={100}/>
        </div>}
      </div>}
      {(forecastTab==='dorm') && <div>
        {(!dormInfo) && <div>
          <CircularProgress />
        </div>}
        {(dormInfo) && <div className={clsx(classes.forecastBody, classes.forecastMap)}>
          <SingleLineChart data={dateSort(dormInfo)} max={100}/>
        </div>}
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  live: state.live,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  loadingLive: state.loading.LOAD_LIVE,
  currentSpaceID: state.dashboard.currentSpace
})

export default withTranslation()(connect(mapStateToProps)(OccupancyForecast));