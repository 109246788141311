import React, { Component } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Snackbar } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { returnCurrent } from "../../../redux/selectors/dashboard";
import BasicDetails from './BasicDetails';
import Navigation from '../../common/Navigation';
import Breadcrumb from '../../common/Breadcrumb';
import GuestList from '../../reservations/new-reservation/GuestList';
import { deepSameKeys, fetchDateFormat, DATE_FORMATS, getAmtFormat, fetchCurrencyFormat } from '../../../utils/utility';
// import { ICONS } from '../../../utils/imageUrls';
import BookingProducts from './BookingProducts';
import { createBookingSource } from '../../../redux/actions/reservation/newReservation';
import { createBooking } from '../../../redux/actions/experiences/booking';
import { fetchCalendarInfo } from '../../../redux/actions/experiences/calendar';
import { resetReducerState } from '../../../redux/actions/common/common';
import SearchGuests from './SearchGuests';
import { clearSearch, searchReservations, setSearchKeyword } from '../../../redux/actions/search/search';

const styles = (theme) => ({
  root: {
    // background: '#F8F9FB'
  },
  Button: {
    background: '#000',
    color: '#fff',
    width: 100,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  fullWidth: {
    width: '100%'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  error: {
    color: 'red',
    fontSize: '1.4rem',
    padding: '30px 5px 0'
  },
  selectionType: {
    top: 24,
    right: 24,
    padding: 8,
    position: 'absolute',
    backgroundColor: 'transparent',
    '& .MuiToggleButtonGroup-grouped': {
      color: '#888888',
      padding: '0 20px',
      height: 40,
      border: 0,
      boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-child)': {
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },
    '& .MuiToggleButtonGroup-grouped:not(:last-child)': {
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    '& .MuiToggleButton-root.Mui-selected': {
      color: '#fff',
      backgroundColor: '#c8c8c8',
    },
    [theme.breakpoints.down('xs')]: {
      top: 42,
      right: 24,
      padding: '8px 0'
    },
  },
  dialogAction: {
    padding: '8px 30px 30px 30px'
  },
  dialogContent: {
    padding: '40px 5px',
    margin: '0 auto',
    overflow: 'hidden',
  },
  space: {
    height: 50
  }
});

const bookingFormData = {
  booking: {
    propertyID: '',
    bookingSource: '',
    date: '',
    guestCount: 0,
    stay: ''
  }
}


const NewBookingMask = (props) => {
  const { classes, currentSpace, experiences, productDetails } = props;
  const [disableButton, setDisableButton] = React.useState(false);
  const [buttonText, setButtonText] = React.useState('Next');
  const [currentStep, setCurrentStep] = React.useState(1);
  const [prevStep, setPrevStep] = React.useState(1);
  // const [itemList, setItemList] = React.useState([]);
  const [data, setData] = React.useState({ products: {} });
  const [error, setError] = React.useState({ hasError: false, message: null });
  const [breadcrumbList, setBreadcrumb] = React.useState({});
  const [messageopen, setMessageopen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const basic = React.createRef();
  const item = React.createRef();
  const guest = React.createRef();
  let dialogContentNode = null;


  // Adithya :)
  const STEPS = {
    1: productDetails.productName,
    2: 'Find Guest',
    3: 'Add Participant Details',
  }

  // To process calendar info
  // React.useEffect(() => {
  //   if (experiences.calendarData && data.date) {
  //     handleItemData(data.date)
  //   }
  // }, [experiences.calendarData])


  React.useEffect(() => {
    if (props.bookingCreated) {
      props.dispatch(resetReducerState('CREATE_BOOKING'));
      props.refreshCalendar();
      props.handleClose()
    }
  }, [props.bookingCreated])

  // Go next step
  const handleNext = (currentStep) => {
    let hasError = false, errorMessage = '', newButtonText = null;
    let bookingDetails = { ...data };

    switch (currentStep) {
      case 1:
        let booking = basic.current.handleProcessData();
        booking = { ...booking, date: productDetails.date }
        hasError = !deepSameKeys(bookingFormData.booking, booking)
        newButtonText = 'Finish';
        bookingDetails = { ...bookingDetails, ...booking };
        if (bookingDetails.guestCount < 1) hasError = true;
        if (hasError) errorMessage = 'Please select all fields to proceed.'
        // else if (bookingDetails.date) {
        //   if (experiences?.calendarData?.[booking.date])
        //     handleItemData(booking.date);
        //   else
        //     props.dispatch(fetchCalendarInfo({ propertyID: currentSpace.propertyID, date: booking.date }))
        // }
        break;
      case 2:
        let reservation = item.current.handleProcessData();
        bookingDetails = { ...bookingDetails, ...reservation };
        if (!bookingDetails.reservationID) hasError = true;
        if (hasError) errorMessage = 'Please select a guest';
        else handleSubmit(bookingDetails);
        break;
      case 3:
        let guestInfo = guest.current.handleProcessData();
        let customers = [...guestInfo.customers];
        bookingDetails = { ...bookingDetails, customers };
        if (!guestInfo.customers.length || (guestInfo.totalGuest !== customers.length)) hasError = true;
        if (hasError) errorMessage = 'Please enter guest info.';
        else handleSubmit(bookingDetails);
        break;
      default: ;
    }
    if (hasError) {
      dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (currentStep === 1) {
      if (bookingDetails.stay === 'external') currentStep = 3;
      else currentStep = 2
      setButtonText(newButtonText || buttonText)
    }
    setCurrentStep(currentStep)
    setPrevStep(currentStep - 1)
    setData({ ...bookingDetails });
    setError({ hasError: hasError, message: errorMessage })
  }

  // Go previous steps
  const handlePrev = (currentStep) => {
    setCurrentStep(1)
    setPrevStep(0)
    setButtonText('Next')
    setError({ hasError: false, message: '' })
  }


  const getProductsArray = (data, productInfo) => {
    return [...Array(data.guestCount)].map((x, i) => {
      return {
        date: productDetails.date,
        productID: productInfo.productID,
        slotID: productDetails.slotID,
        gradeID: productInfo.gradeID,
        availabilityID: productInfo.availabilityID,
        quantity: 1
      }
    })
  }

  //  submit form
  const handleSubmit = (data) => {
    let productInfo = getProductData(productDetails.productID, productDetails.date);
    // Monasha / Selwyn
    // productInfo.productID !== productDetails.productID ?????
    let payload = {
      propertyID: currentSpace.propertyID,
      bookingSource: data.bookingSource,
      guestCount: data.guestCount,
      stay: data.stay,
      products: getProductsArray(data, productInfo)
    }
    if (payload.stay === 'inhouse') {
      payload.reservationID = data.reservationID
    } else if (payload.stay === 'external') {
      payload.customers = data.customers
    }

    console.log('payload', payload)
    props.dispatch(createBooking(payload, currentSpace.timezone));
  }

  const getProductData = (productID, selectedDate) => {
    console.log("productID",productID)
    const calendarData = experiences?.calendarData[selectedDate] || [];
    const activityList = experiences?.activityList || {};
    let item = {};
    calendarData.map(data => {
      if(data.draftID === productID){
        let activityData = { ...activityList[data.draftID] };
        return activityData?.grades && Object.keys(activityData.grades).map(gradeID => {
          let gradeInfo = { ...activityData.grades[gradeID] };
          gradeInfo.slots = gradeInfo?.slots || [];
          if (Object.keys(gradeInfo.slots).includes(data.slotID) && data.slotID === productDetails.slotID) {
            item = {
              ...data,
              _id: data.slotID,
              maximum: data.available,
              name: gradeInfo.title,
              gradeID: gradeID,
              quantity: 0
            }
          }
        });

      }
    })
    console.log("item", item.productID)
    return item
  }

  /*const handleItemData = (selectedDate) => {
    const calendarData = experiences?.calendarData[selectedDate] || [];
    const activityList = experiences?.activityList || {};
    let itemList = [];
    calendarData.map(data => {
      let activityData = { ...activityList[data.productID] };
      let activityImage = activityData.photos?.length ? (activityData.photos[activityData.photos.length - 1].original) : '';
      return activityData?.grades && Object.keys(activityData.grades).map(gradeID => {
        let gradeInfo = { ...activityData.grades[gradeID] };
        gradeInfo.slots = gradeInfo?.slots || [];
        if (Object.keys(gradeInfo.slots).includes(data.slotID)) {
          itemList.push({
            ...data,
            _id: data.slotID,
            maximum: data.available,
            name: gradeInfo.title,
            gradeID: gradeID,
            image: activityImage,
            quantity: 0
          })
        }
      });
    })
    setItemList(itemList)
  }*/

  const handleCloseSnackBar = () => {
    props.dispatch(resetReducerState('CREATE_BOOKING'))
  }

  const createBookingSources = (value) => {
    props.dispatch(createBookingSource({ propertyID: currentSpace.propertyID, name: value }, currentSpace._id))
  }

  const handleClearSearch = () => {
    props.dispatch(clearSearch());
    setSearch('');
  }

  const handleSearch = (keyword, status) => {
    setSearch(keyword)
    props.dispatch(searchReservations(currentSpace.propertyID, keyword, status, productDetails.date, '', 'desc', 25))
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose()}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}>
      <div className={classes.root}>
        <CloseIcon className={classes.closeIcon} onClick={() => props.handleClose()} />
        <DialogContent>
          <div ref={node => { dialogContentNode = node; }}>
            <DialogContent className={classes.dialogContent}>

              {/* ------------ Navigating back to previous screen and show title ------------ */}
              <Navigation step={currentStep} title={STEPS[currentStep]} onChangePrev={handlePrev} />
              {error.hasError && (<div className={classes.error}>{error.message}</div>)}

              {/* ------------ Creat Reservation form information ------------ */}
              {currentStep === 1 &&
                <BasicDetails
                  ref={basic}
                  currentSpace={currentSpace}
                  productDetails={props.productDetails}
                  productInfo={props.productInfo}
                  conductorInfo={props.conductorInfo}
                  bookingSource={props.bookingSource}
                  createBookingSource={createBookingSources}
                  prefilledData={data}
                />}

              {currentStep === 2 &&
                // <BookingProducts
                //   ref={item}
                //   multiple={true}
                //   itemList={itemList}
                //   experiences={props.experiences}
                //   selectedDate={data.date}
                //   prefilledData={data.products}
                // />

                <SearchGuests
                  ref={item}
                  searchResults={props.searchResults}
                  search={search} 
                  setSearch={(value) => setSearch(value)}
                  handleSearch={handleSearch} 
                  handleClearSearch={handleClearSearch} 
                />
              }

              {currentStep === 3 &&
                <GuestList
                  ref={guest}
                  currentSpace={currentSpace}
                  guestCount={data.guestCount}
                  viewMasterBooker={true}
                  showLess={true}
                />}

            </DialogContent>
          </div>
        </DialogContent>

        {/* ------------ Action Button ------------ */}
        <DialogActions className={classes.dialogAction}>
          <Button
            className={clsx({
              [classes.Button]: true,
              [classes.fullWidth]: currentStep === 3
            })}
            onClick={() => handleNext(currentStep)}>
            {buttonText}
          </Button>
        </DialogActions>

        {/* -------------- Loader --------------- */}
        {props.loading &&
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        }
        {/* ---------- Error message ------------ */}
        {props.errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            onClose={() => handleCloseSnackBar()}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{props.errors}</span>}
          />
        )}
      </div>
    </Dialog>
  );
}


const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  productDetails: state.experiences?.displayProductDetails || {},
  productInfo: state.experiences?.activityList || {},
  conductorInfo: state.experiences?.conductorList || {},
  searchResults: state.search.searchResults,
  experiences: state.experiences,
  suggestions: state.suggestions || [],
  options: state.options || [],
  bookingSource: state.bookingSource,
  bookingCreated: state.success.CREATE_BOOKING,
  loading: state.loading.FETCH_ACTIVITY_LIST || 
    state.loading.FETCH_CALENDAR_INFO || 
    state.loading.CREATE_BOOKING ||
    state.loading.SEARCH_RESERVATIONS,
  errors: state.errors.CREATE_BOOKING
})

export default withStyles(styles)(connect(mapStateToProps)(NewBookingMask));