import http from "../../utils/http";
import axios from "axios";
import { normalize } from 'normalizr';
import { productCategorySchema } from "../../schema/productCategorySchema";

//----------------------------------------------------------------
// LOAD PRODUCTS AND PRODUCT CATEGORIES
//----------------------------------------------------------------
export function fetchProductCategorys(spaceID, propID) {
  const ACTION_NAME = "LOAD_PRODUCT_CATEGORIES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/pos/productCategory/products/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, [ productCategorySchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {...normalizedOrder.entities, spaceID: spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// EDIT PRODUCT CATEGORY
//----------------------------------------------------------------
export function editProductCategory(productCategoryID, updatedProductCategory) {
  let updatedProductCategoryData = { ...updatedProductCategory };
  let file = updatedProductCategoryData.image;
  delete updatedProductCategoryData.image;
  let fileName = null;
  if(file && file.name) {
    fileName = file.name.split(".")[0];
    updatedProductCategoryData.fileName = fileName;
  }
  const ACTION_NAME = "EDIT_PRODUCT_CATEGORY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/pos/productCategory/${productCategoryID}`, { ...updatedProductCategoryData })
      .then(successResult => {
        const data = successResult.data;
        if (file) {
          uploadProductCategoryImage(file, data, function(err, response){
            if(err) return err;
            dispatch({
              type: `${ACTION_NAME}_SUCCESS`,
              payload: { ...response.data }
            });
          })
        } else {
          dispatch({
            type: `${ACTION_NAME}_SUCCESS`,
            payload: { ...data.productCategory }
          });
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE PRODUCT CATEGORY
//----------------------------------------------------------------
export function createProductCategory(spaceID, productCategory) {
  let productCategoryData = { ...productCategory };
  let file = productCategoryData.image;
  delete productCategoryData.image;
  const ACTION_NAME = "CREATE_PRODUCT_CATEGORY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/pos/productCategory', productCategoryData)
      .then(successResult => {
        const data = successResult.data;
        if (file) {
          uploadProductCategoryImage(file, data, function(err, response){
            if(err) return err;
            dispatch({
              type: `${ACTION_NAME}_SUCCESS`,
              payload: { ...response.data, spaceID }
            });
          })
          
        } else {
          dispatch({
            type: `${ACTION_NAME}_SUCCESS`,
            payload: { ...data.productCategory, spaceID }
          });
        }
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}


//----------------------------------------------------------------
// DELETE PRODUCT CATEGORY
//----------------------------------------------------------------
export function deleteProductCategory(productCategoryID, spaceID, productCategory) {
  const ACTION_NAME = "DELETE_PRODUCT_CATEGORY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(`/api/pos/productCategory/${productCategoryID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            productCategoryID, 
            spaceID, 
            productCategory: data
          }
        })
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        if (!error.message || error.message === "400 - {}")
          error.error = 'Unable to delete product category'
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}

//----------------------------------------------------------------
// UPLOAD PRODUCT CATEGORY IMAGE
//----------------------------------------------------------------
function uploadProductCategoryImage(file, data, callback) {
  const fileParts = file.name.split(".");
  const fileType = fileParts[1];
  const key = data.key;
  const url = data.url;
  const options = {
    headers: {
      "Content-Type": fileType
    }
  };
  axios.put(url, file, options).then(result => {
    http.post("/api/pos/productCategory/images/save", {
      productCategoryID: data.productCategory._id,
      propertyID: data.productCategory.propertyID,
      key: key
    }).then(response => {
      callback(null,response);
    }).catch(error => { callback(error); });
  }).catch(error => { callback( error); });
}