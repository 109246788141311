import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    width: "100%",
    height: "100%",
    overflow: "visible",
  },
  section: {
    marginBottom: 50,
    width: "100%",
  },
  shadow: {
    boxShadow: "0 4px 2px -2px #ECECEC",
    display: "flex",
    justifyContent: "center",
    height: 10,
    marginBottom: 100,
    marginLeft: "35%",
    width: "30%",
  },
  row: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 80,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "calc(100% - 48px)",
  },
  paddedRow: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: "calc(100% - 96px)",
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    width: "100%",
  },
  counter: {
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: 8,
  },
  title: {
    fontSize: "2.6rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  titleImage: {
    marginBottom: 20,
    width: "80%",
    "& img": {
      height: "auto",
      width: "100%",
    },
  },
  button: {
    background: "#CC6633",
    borderRadius: 5,
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: "1.3rem",
    fontWeight: 600,
    padding: "15px 20px",
  },
  clear: {
    background: "#FFFFFF",
    border: "1px solid #000000",
    color: "#000000",
  },
  subTitleContainer: {
    marginBottom: 20,
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  subTitle: {
    color: "#3333333",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: 1.5,
  },
  center: {
    textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  iconContainer: {
    alignItems: "center",
    background: "#ECECEC",
    borderRadius: "50%",
    display: "flex",
    height: 60,
    justifyContent: "center",
    marginBottom: 20,
    width: 60,
    "& img": {
      height: 30,
      opacity: 0.8,
      width: 30,
    },
  },
  featureImage: {
    alignItems: "center",
    borderRadius: 5,
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    height: "calc(100% - 32px)",
    justifyContent: "center",
    overflow: "hidden",
    padding: theme.spacing(2),
    "& img": {
      height: "auto",
      objectFit: "cover",
      width: "100%",
    },
  },
  featureDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "80%",
  },
  small: {
    width: "50%",
  },
  large: {
    width: "70%",
  },
  pullRight: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  image: {
    borderRadius: 5,
    marginBottom: 20,
    objectFit: "cover",
    width: "100%",
  },
  smallTitle: {
    color: "#444444",
    fontSize: "1.7rem",
    fontWeight: 600,
    lineHeight: 1.5,
    marginBottom: 20,
  },
  description: {
    color: "#555555",
    fontSize: "1.2rem",
    lineHeight: 1.5,
  },
  toolRow: {
    background: "#ECECEC",
    marginBottom: 30,
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: "calc(100% - 96px)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      width: "calc(100% - 48px)",
    },
  },
  featureIconContainer: {
    display: "flex",
  },
  featureIcon: {
    marginRight: 20,
  },
  faq: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 25,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "calc(100% - 48px)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
  },
  faqTitle: {
    color: "#444444",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginTop: 40,
  },
  footerImage: {
    minHeight: 500,
    objectFit: "cover",
    opacity: 0.8,
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    top: 300,
  },
}));

const HWExperiencesPage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* TITLE SECTION */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <div className={classes.titleContainer}>
            <div className={classes.counter}>COUNTER EXPERIENCES</div>
            <div className={classes.title}>Host an experience</div>
            <div className={classes.titleImage}>
              <img src="/images/illustration-experience.png" />
            </div>
            <div className={classes.button} onClick={props.handleNext}>
              Get Started
            </div>
          </div>
        </div>
      </div>

      <div className={classes.shadow}></div>

      {/* PICTURE SECTION */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <div className={classes.small}>
                <img
                  className={classes.image}
                  src="/images/experiences/experience1.png"
                />
              </div>
              <div className={classes.pullRight}>
                <div className={classes.large}>
                  <img
                    className={classes.image}
                    src="/images/experiences/experience2.png"
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className={classes.large}>
                <img
                  className={classes.image}
                  src="/images/experiences/experience3.png"
                />
              </div>
              <div className={classes.pullRight}>
                <div className={classes.small}>
                  <img
                    className={classes.image}
                    src="/images/experiences/experience4.png"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* 3 FEATURES */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <div className={classes.subTitleContainer}>
            <div className={clsx(classes.subTitle, classes.center)}>
              A worldwide audience of travelers and locals are searching for
              unique social experiences
            </div>
          </div>
        </div>

        <div className={clsx(classes.row, classes.paddedRow)}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <div>
                <div className={classes.iconContainer}>
                  <img src="/images/layout/experiences.png" />
                </div>
                <div>
                  <div className={classes.smallTitle}>
                    Diversify your inventory
                  </div>
                  <div className={classes.description}>
                    Creating experiences is a good way to make your business
                    more robust.
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <div className={classes.iconContainer}>
                  <img src="/images/experiences/handshake.png" />
                </div>
                <div>
                  <div className={classes.smallTitle}>Sell to locals</div>
                  <div className={classes.description}>
                    Travelers who aren't able to travel right now are starved
                    for community-led experiences.
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <div className={classes.iconContainer}>
                  <img src="/images/experiences/flash.png" />
                </div>
                <div>
                  <div className={classes.smallTitle}>Grow your revenue</div>
                  <div className={classes.description}>
                    Use your expertize and local knowledge to generate a whole
                    new stream of revenue.
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.shadow}></div>

      {/* GET STARTED */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <div className={classes.title}>How to get started</div>
        </div>

        <div className={classes.row}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={5}>
              <div className={classes.featureImage}>
                <img src="/images/experience-feature1.png" />
              </div>
            </Grid>
            <Hidden xsDown>
              <Grid item sm={1}></Grid>
            </Hidden>
            <Grid item xs={12} sm={5}>
              <div className={classes.featureDescription}>
                <div className={clsx(classes.subTitle, classes.marginBottom)}>
                  1. Design an experience
                </div>
                <div className={classes.description}>
                  Hosting an experience is an exciting opportunity to share your
                  expertise with guests from all around the world.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={5} justifyContent="center">
            <Hidden smUp>
              <Grid item xs={12}>
                <div className={classes.featureImage}>
                  <img src="/images/experience-feature2.png" />
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={5}>
              <div className={classes.featureDescription}>
                <div className={clsx(classes.subTitle, classes.marginBottom)}>
                  2. Set up prices and availability
                </div>
                <div className={classes.description}>
                  Maximize your visibility to guests all over the world by
                  setting prices and releasing availability of your
                  ƒexperiences.
                </div>
              </div>
            </Grid>
            <Grid item sm={1}></Grid>
            <Hidden xsDown>
              <Grid item sm={5}>
                <div className={classes.featureImage}>
                  <img src="/images/experience-feature2.png" />
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={5}>
              <div className={classes.featureImage}>
                <img src="/images/experience-feature3.png" />
              </div>
            </Grid>
            <Hidden xsDown>
              <Grid item sm={1}></Grid>
            </Hidden>
            <Grid item xs={12} sm={5}>
              <div className={classes.featureDescription}>
                <div className={clsx(classes.subTitle, classes.marginBottom)}>
                  3. Automatically receive bookings
                </div>
                <div className={classes.description}>
                  Listing your experience with Hostelworld is a great way to
                  showcase your business to a new audience of locals and
                  travelers.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <div
            className={clsx(classes.button, classes.clear)}
            onClick={props.handleNext}
          >
            List your experience now
          </div>
        </div>
      </div>

      <div className={classes.shadow}></div>

      {/* TOOLS */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={4}>
              <div className={classes.title}>
                We've got your back every step of the way
              </div>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <div className={classes.description}>
                Our platform provides everything you need to run your business.
                You can manage your bookings, payments, inventory, run reports
                and much more.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.toolRow}>
          <Grid container>
            <Grid item sm={6}>
              <div className={classes.featureIconContainer}>
                <div className={classes.featureIcon}>Scheduling</div>
                <div className={classes.featureIcon}>Payments</div>
                <div className={classes.featureIcon}>Insights</div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className={classes.smallTitle}>Tools tailored to you</div>
              <div className={classes.description}>
                A calendar to give you an overview of your upcoming experiences
                and bookings with a visibility into your future demand, offline
                reservations, payment collection, and much more.
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* FAQS */}
      {/* ------------------------------------------------ */}
      <div className={classes.section}>
        <div className={classes.row}>
          <div className={classes.faq}>
            <div className={classes.faqTitle}>
              Do I need a business license?
            </div>
            <div className={classes.description}>
              Depending on the activities involves, some experiences might
              require a business license. Make sure to check local laws in your
              area to determine which licenses may be required for your
              experience, expecially if there is food, alcohol or transportation
              involved.
            </div>
          </div>
          <div className={classes.faq}>
            <div className={classes.faqTitle}>
              Can I set a minimum number of guests per experience?
            </div>
            <div className={classes.description}>
              The default minimum number of guests you can host during each
              experience is 1. Please feel free to update your settings in case
              you have a minimum group size requirement.
            </div>
          </div>
          <div className={classes.faq}>
            <div className={classes.faqTitle}>Can I sell free experiences?</div>
            <div className={classes.description}>
              Absolutely. Many hostels host free experiences to build an active
              community and help guests feel at home in their space. You can
              setup free experiences here to market your beds, bar, coffee shop
              or other facilities to potential customers.
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      {/* ------------------------------------------------ */}
      <div className={classes.footerContainer}>
        <img
          className={classes.footerImage}
          src="/images/experiences/experience-footer.jpg"
        />
        <div className={classes.buttonContainer}>
          <div className={classes.button} onClick={props.handleNext}>
            Get Started
          </div>
        </div>
      </div>
    </div>
  );
};

export default HWExperiencesPage;
