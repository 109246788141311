import IMAGES from "./images";

const ROOM_INVENTORY_TYPE = {
  oneToOne: "oneToOne",
  oneToMany: "oneToMany",
  none: "none",
};

const ROOM_WIZARD_MENU = {
  BASIC: "BASIC",
  DETAILS: "DETAILS",
  ROOMTYPE: "ROOMTYPE",
  INVENTORY: "INVENTORY",
  VISIBILITY: "VISIBILITY",
};

export { IMAGES, ROOM_INVENTORY_TYPE, ROOM_WIZARD_MENU };
