import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

/*eslint no-undefined: "error"*/
const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "100%",
    borderBottom: "1px solid #eeeeee",
  },
  errorContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  leftAlign: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "20%",
    wordBreak: "break-word",
  },
  rightAlign: {
    paddingLeft: 30,
    width: "70%",
  },
  textField: {
    "& input": {
      fontSize: "1.3rem",
    },
  },
}));

const EditableField = ({
  isEdit,
  label,
  formData = {},
  name,
  handleEdit,
  setFormData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.leftAlign}>{label}</div>
      <div className={classes.rightAlign}>
        {!isEdit && (
          <div onClick={handleEdit} className={classes.field}>
            {formData[name]}
          </div>
        )}
        {isEdit && (
          <TextField
            name={name}
            className={classes.textField}
            defaultValue={formData[name]}
            onChange={(event) => {
              const { name, value } = event.target;
              setFormData((state) => ({ ...state, [name]: value }));
            }}
            margin="none"
            fullWidth
          />
        )}
      </div>
    </div>
  );
};

export default EditableField;
