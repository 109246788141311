import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import CustomDialog from "../common/DialogWithPermissions";
import { MODULE } from "../../common/constants/permission";
import { fetchCashDrawers } from "../../redux/actions/cash-drawers/cashDrawers";
import {
  fetchMasterwayConfig,
  getMasterwayBillStatus,
} from "../../redux/actions/masterway/masterway";
import { resetError } from "../../redux/actions/common/common";

import SetupConfig from "./SetupConfig";
import FailedBills from "./FailedBills";

const useStyles = makeStyles((theme) => ({
  root: {},
  unsetFooter: {
    paddingBottom: "unset",
  },
  tabContainer: {
    display: "flex",
    marginBottom: "40px",
    "& .tab": {
      borderRadius: "8px",
      padding: "10px 20px",
      marginLeft: "20px",
      cursor: "pointer",
      boxShadow: "0 2px 40px 0 #eeeeee",
    },
    "& .tab.active": {
      background: "#E0E0E0",
    },
  },
}));

const TABS = {
  setup: { id: "setup", label: "Setup" },
  failed: { id: "failed", label: "Failed" },
};

const MasterwayAutomate = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, currentSpace, isLoading, dispatch, errors } =
    props;
  const propertyID = currentSpace.propertyID;

  const [activeTab, setActiveTab] = useState(TABS.setup.id);

  const init = () => {
    dispatch(fetchCashDrawers(propertyID));
    dispatch(fetchMasterwayConfig(propertyID));
    dispatch(getMasterwayBillStatus(propertyID));
  };

  useEffect(() => {
    init();
    return () => {
      props.dispatch({ type: "RESET_MASTERWAY" });
    };
  }, []);

  const handleCloseError = () => {
    props.dispatch(resetError("FETCH_MASTERWAY_CONFIG"));
    props.dispatch(resetError("PATCH_MASTERWAY_CONFIG"));
    props.dispatch(resetError("RETRY_MASTERWAY_FAILED_BILL"));
  };

  return (
    <CustomDialog
      isOpen={open}
      headerTitle="Masterway"
      className={clsx({ [classes.unsetFooter]: activeTab == TABS.failed.id })}
      isLoading={isLoading}
      handleCloseError={handleCloseError}
      moduleID={MODULE.MASTERWAY.ID}
      closeModalHandler={handleClose}
      maxWidth="md"
      errors={errors}
    >
      {/* ------------------TAB HEAD COMMENTED FOR FUTURE USE------------------- */}
      <div className={classes.tabContainer}>
        {Object.keys(TABS).map((tabID) => (
          <div
            key={`tab_${tabID}`}
            className={clsx("tab", { active: activeTab == tabID })}
            onClick={() => setActiveTab(tabID)}
          >
            {TABS[tabID].label}
          </div>
        ))}
      </div>

      {/* ------------------TAB BODY------------------- */}
      {
        {
          [TABS.setup.id]: <SetupConfig />,
          [TABS.failed.id]: <FailedBills />,
        }[activeTab]
      }
    </CustomDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSpace: state.spaces[state.dashboard.currentSpace],

    isLoading:
      state.loading.LOAD_CASH_DRAWERS ||
      state.loading.FETCH_MASTERWAY_CONFIG ||
      state.loading.PATCH_MASTERWAY_CONFIG ||
      state.loading.FETCH_MASTERWAY_BILL_STATUS ||
      state.loading.RETRY_MASTERWAY_FAILED_BILL,

    errors:
      state.errors.PATCH_MASTERWAY_CONFIG ||
      state.errors.RETRY_MASTERWAY_FAILED_BILL,
  };
};

export default connect(mapStateToProps)(MasterwayAutomate);
