import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

// import ProductCategoryForm from './ProductCategoryForm';

import { Grid, Chip, Dialog, CircularProgress, Hidden } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { resetError } from '../../../redux/actions/common/common';
import RoomTypeForm from './RoomTypeForm';
import { createRoomType, deleteRoomType, editRoomType } from '../../../redux/actions/rooms/roomTypes';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles (theme => ({
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: 'hidden',
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 2px 20px #F0F0F0",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  addButton: {
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#666666',
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      left: '10px'
    }
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 20,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      right: '10px'
    }
  },
  editIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    height: 15,
    padding: 8,
    opacity: 0.5,
    width: 15,
    '&:hover': {
      background: '#E0E0E0'
    }
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  listContainer: {
    height: 'calc(100vh - 154px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 88px)',
    },
  },
  row: {
    borderBottom: '1px solid #F0F0F0',
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      background: '#F8F9FB',
    }
  },
  settingsIcon: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 15,
      opacity: 0.5,
      width: 15,
    },
    '&:hover': {
      background: '#F8F9FB'
    }
  },
  nameContainer: {
    alignItems: 'center',
    display: 'flex',
    '& img': {
      borderRadius: 5,
      height: 70,
      width: 70,
      objectFit: 'cover',
    }
  },
  name: {
    fontSize: '1.3rem',
    marginLeft: 20,
    marginBottom: 5
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  taxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20
    }
  },
  taxChip: {
    alignItems: 'center',
    background: '#E0E0E0',
    color: '#666666',
    display: 'flex',
    fontSize: '1.1rem',
    marginRight: 10,
    marginBottom: 5
  },
}))

const RoomTypeList = (props) => {
  const classes = useStyles();

  const { open, handleClose, roomTypes, taxes, loadingRoomTypes, loadingTaxes, currentSpace, errors, t } = props;
  const [roomType, setRoomType] = React.useState({id: '', active: false});

  const handleSubmit = (data) => {
    if(roomType.id == ''){
      data = { ...data, propertyID: currentSpace.propertyID };
      props.dispatch(createRoomType(currentSpace._id, data));
    } else {
      delete data.image;
      props.dispatch(editRoomType(roomType.id, data));
    }
    setRoomType({id: '', active: false})
  }

  const handleDelete = (unallocate) => {
    setRoomType({ id: '', active: false });
    props.dispatch(deleteRoomType(roomType.id, currentSpace._id, roomTypes[roomType.id],
      {
        propertyID: currentSpace.propertyID,
        roomTypeID: roomType.id,
        unallocate
      }));
  }

  const handleCloseError = () =>{
    props.dispatch(resetError('CREATE_ROOMTYPE'));
    props.dispatch(resetError('EDIT_ROOMTYPE'));
    props.dispatch(resetError('DELETE_ROOM_TYPE'));
  }

  const handleCloseModal = () => {
    if(errors) handleCloseError();
    handleClose();
  }

  const getImage = (roomTypeItem) => {
    const image = IMAGES.ICONS.posPlaceholder;
    let roomTypeImage = roomTypes[roomTypeItem].images && roomTypes[roomTypeItem].images.length ? (roomTypes[roomTypeItem].images[roomTypes[roomTypeItem].images.length - 1].imagelink || image) : image;
    return roomTypeImage;
  }

  return(
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {!roomType.active && (
            <AddIcon className={classes.addButton} onClick={() => setRoomType({id: '', active: true})} />
          )}

          {roomType.active && <BackIcon className={classes.addButton} onClick={() => setRoomType({id: '', active: false})} />}

          <div className={classes.header}>{t('beds.roomTypesAndRooms.title')}</div>

          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
        </div>

        <div className={classes.listContainer}>
          <div className={classes.listSection}>
              
            {/* LIST ALL ROOM TYPES */}
            {/* --------------------------------------------- */}
            {!loadingRoomTypes && !roomType.active && currentSpace.roomTypes && 
              currentSpace.roomTypes
              .filter(item => roomTypes[item] && roomTypes[item].isActive === true)
              .map(roomTypeItem => (
                <Grid container spacing={3} key={roomTypeItem} alignItems="center" className={classes.row}>
                  <Grid item  xs={10} sm={6}>
                    <div className={classes.nameContainer}>
                      <img src={getImage(roomTypeItem)} />
                      <div className={classes.vertical}>
                        <div className={classes.name}>{roomTypes[roomTypeItem].name}</div>
                        <Hidden smUp>
                          <div className={classes.taxContainer}>
                            {!loadingTaxes && roomTypes[roomTypeItem].taxesApplicable?.length!==0 && roomTypes[roomTypeItem].taxesApplicable.map(taxID => {
                              return taxes && taxes[taxID] && <Chip
                                key={taxID}
                                size="small"
                                label={taxes && taxes[taxID] && taxes[taxID].code ? taxes[taxID].code : taxes[taxID].name}
                                className={classes.taxChip}
                              />
                            })}
                          </div>
                        </Hidden>
                      </div>
                    </div>
                  </Grid>
                  <Hidden xsDown><Grid item sm={4}>
                    <div className={classes.taxContainer}>
                      {!loadingTaxes && roomTypes[roomTypeItem].taxesApplicable?.length!==0 && roomTypes[roomTypeItem].taxesApplicable.map(taxID => {
                        return taxes && taxes[taxID] && <Chip
                          key={taxID}
                          size="small"
                          label={taxes && taxes[taxID] && taxes[taxID].code ? taxes[taxID].code : taxes[taxID].name}
                          className={classes.taxChip}
                        />
                      })}
                    </div>
                  </Grid></Hidden>
                  <Grid item xs={2} sm={2}>
                    <div className={classes.settingsIcon} onClick={() => setRoomType({id: roomTypeItem, active: true})}>
                      <img src={IMAGES.ICONS.settings} />
                    </div>
                  </Grid>
                </Grid>
            ))}

            {roomType.active && 
              <RoomTypeForm
                roomTypeID={roomType.id}
                data={roomType.id==='' ? {} : roomTypes[roomType.id]}
                handleCancel={() => setRoomType({id: roomType, active: false})}
                handleSubmit={handleSubmit}
                handleConfirmDelete={handleDelete}
              />
            }
          </div>

        </div>

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            onClose={handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}

        {props.loading &&
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        }

      </div>
    </Dialog>

  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
  taxes: state.taxes,
  loadingRoomTypes: state.loading.LOAD_ROOM_TYPES,
  loadingTaxes: state.loading.LOAD_TAX_LIST,
  errors: state.errors.CREATE_ROOMTYPE || 
          state.errors.EDIT_ROOMTYPE ||
          state.errors.DELETE_ROOM_TYPE,
  loading: state.loading.CREATE_ROOMTYPE || 
          state.loading.EDIT_ROOMTYPE ||
          state.loading.DELETE_ROOM_TYPE,
})

export default withTranslation()(connect(mapStateToProps)(RoomTypeList));