import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

const commonProperties = {
  margin: { top: 20, right: 30, bottom: 60, left: 60 },
  animate: true,
  enableSlices: 'x',
}

const theme = {
  axis: {
    ticks: {
      line: {
        stroke: "#999999"
      },
      text: {
        fill: "#999999"
      }
    },
  },
};

const BarChart = (props) => {

  const { data, keyName, currency, maxValue, units, ticks } = props;
  return (
    <ResponsiveBar
        {...commonProperties}
        data={data}
        enableLabel={false}
        keys={keyName}
        indexBy="date"
        groupMode="grouped"
        colors={{ scheme: 'pastel2' }}
        maxValue={maxValue ? maxValue : null}
        axisBottom={{
          orient: "top",
          tickSize: ticks ? 5 : 0,
          tickPadding: 5,
          tickRotation: ticks ? 60 : 0,
          tickValues: 5
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        minValue={0}
        theme={theme}
        tooltip={(point) => (
          <span>
              {point.id}: {currency ? currency : ''}{parseFloat(point.value.toFixed(2))}{units ? `${units}` : null}
          </span>
        )}
    />
  )
}

export default BarChart;