import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { InputBase, Grid } from '@material-ui/core';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
  box: {
    boxShadow: '0 1px 10px #E0E0E0',
    borderRadius: 5,
    marginBottom: 30,
    padding: theme.spacing(3)
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  bin: {
    borderRadius: '50%',
    height: 18,
    padding: theme.spacing(1),
    width: 18,
    cursor: 'pointer',
    '&:hover': {
      background: '#E0E0E0'
    }
  },
  form: {
    marginTop: 30
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  label: {
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    alignItems: 'center',
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: '10px',
    display: 'flex',
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'calc(100% - 32px)',
    '& > div': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      marginBottom: 10,
    }
  },
  multiLine: {
    alignItems: 'flex-start',
    height: 100,
    overflowY: 'hidden'
  },
  fieldError: {
    border: '1px solid red',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  button: {
    '&:hover':{
      background: '#FFF'
    }
  }
}));

const NewProductCategory = (props) => {
  const classes = useStyles();
  const { handleCancel, t } = props;
  const [form, setForm] = React.useState({
    name: '',
    code: '',
    description: ''
  });
  const [error, setError] = React.useState(
    {
      name: false,
      code: false,
      description: false,
    }
  );

  const handleChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value })
    if (e.target.value !== '') {
      setError({ ...error, [name]: false })
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['name'];
    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      let payload = { ...form };
      props.handleSubmit(payload);
    }
  }

  return (
    <div className={classes.box}>
      <div className={classes.viewContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>New Product Category</div>
          <img onClick={handleCancel} className={classes.bin} src={IMAGES.ICONS.bin} />
        </div>

        <div className={classes.form}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <div className="row">
                <div className={classes.label}>Category Name</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.name })}>
                  <InputBase
                    id="name"
                    name="Tax Name"
                    type="text"
                    onChange={(e) => handleChange(e, "name")}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
              <div className="row">
                <div className={classes.label}>Code</div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.code })}>
                  <InputBase
                    id="code"
                    name="Code"
                    type="text"
                    onChange={(e) => handleChange(e, "code")}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={7}>
              <div className="row">
                <div className={classes.label}>Description</div>
                <div className={clsx(classes.multiLine, classes.field)}>
                  <InputBase
                    id="description"
                    name="description"
                    fullWidth
                    multiline
                    rows="5"
                    type="text"
                    onChange={(e) => handleChange(e, "description")}
                  />
                </div>
              </div>
            </Grid>

          </Grid>

          <div className={classes.buttonContainer}>
            <Button className={classes.button} variant="outlined" color="primary" onClick={() => handleSubmit()}>
              {t('beds.taxesSnapshot.save')}
            </Button>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default withTranslation()(NewProductCategory);