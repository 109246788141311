import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
// import moment from 'moment';

import CalendarModal from './CalendarModal'; 

import { fetchDatePerTimezone, fetchCurrencyFormat } from "../../utils/utility";
import { RESERVATION_STATUS } from '../../utils/constants';
import clsx from 'clsx';

import { CalendarColors } from './calendar_color_config/constant';
import { BOOKING_SOURCE } from '../../utils/constants';

import CalendarLegends from './calendar/CalendarLegends';
import ModuleHeader from './calendar/ModuleHeader';
import SortOptions from './calendar/SortOptions';
import DatesHeader from './calendar/DatesHeader';
import CalendarRender from './CalendarRender';
// import CalendarDates from './CalendarDates';
import Reservation from '../reservations/Reservation';
import NewReservation from '../reservations/new-reservation/NewReservation';
import AddRoomBlock from './AddRoomBlock';
import EditRoomBlock from './EditRoomBlock';
import ConfirmModal from './ConfirmModal';
import SearchResults from '../search/SearchResults';
import SearchFilters from '../search/SearchFilters';
import { 
  getReservations, 
  getReservationDetails, 
  resetReservationDetails, 
  updateCalenderReservation 
} from '../../redux/actions/beds/beds';
import { fetchBookingSource } from '../../redux/actions/reservation/newReservation';
import { returnCurrent } from '../../redux/selectors/dashboard';
import { resetError, updateReservationStatus } from '../../redux/actions/reservation/editReservation';
import { resetReservationManualOptions } from '../../redux/actions/rooms/rooms';
import { getCalendarRenderData } from '../../redux/actions/beds/calendar';
import {submitSearchKeyword, clearSearch} from '../../redux/actions/search/search';
import { Grid, Snackbar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import CalendarSort from '../beds/calendar_sort';
import CalendarColor from './calendar_color_config';
// import { resetRatePlans } from '../../redux/actions/rates/ratePlans';
// import { fetchProperty } from '../../redux/actions/dashboard/property';
import { isEmpty } from 'lodash';
import { fetchProductList } from '../../redux/actions/experiences/activities';
import { setCalendarDates } from '../../redux/actions/UIsettings/userSettings';

let cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 150
});

const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 3px 4px 0 #ECECEC',
    marginLeft: 20,
    position: 'relative',
  },
  fullScreen: {
    // height: 'calc(100vh - 90px)',
  },
  calendarHeader: {
    // borderBottom: '1px solid #F0F0F0',
    boxShadow: '0 3px 4px 0 #ECECEC',
    padding: '20px 0px 0px 15px',
    marginTop: 20,
    top: 0,
    flex: '1 0 auto'
  },
  newReservationHolder: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
  },
  buttonContainer:{
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginLeft: '10px',
    '& .buttonTitle':{
      fontSize: '1.2rem',
      fontWeight: 600,
      marginLeft: '5px'
    },
    '& .EditButtonIcon':{
      width: '15px',
      height: '15px'
    }
  },
  searchContainer: {
    alignItems: 'center',
    background: '#f8f9fb',
    borderRadius: '5px',
    display: 'flex',
    height: 40,
    marginRight: 10,
    paddingLeft: 40,
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '300px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px'
    },
  },
  searchIcon: {
    position: 'absolute',
    height: 15,
    left: 10,
    opacity: 0.5,
    top: 12,
    width: 15,
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.8rem',
    padding: 5,
    position: 'absolute',
    right: 5,
    top: 6,
    '&:hover': {
      background: '#F0F0F0',
    }
  },
  addReservation: {
    alignItems: 'center',
    background: '#006699',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    height: 40, 
    justifyContent: 'center',
    marginRight: 10,
    width: 40,
    '&:hover': {
      
    }
  },
  addIcon: {
    color: '#FFFFFF',
    fontSize: '2.2rem', 
  },
  calendarDates: {
    position: 'relative',
    '& .date-section':{
      padding: '5px 0px',
      paddingRight: '15px',
      boxSizing: 'border-box',
    },
    '& .datepicker-container':{
      margin: 'unset !important'
    },
    '& .DateInput_input':{
      fontSize: '12px'
    },
    '& .datePicker-arrow-icon':{
      fontSize: '1.2rem'
    }
  },
  buttonHolder: {
    position: 'absolute',
    top: 0,
    right: 10,
    height: 42,
  },
  button: {
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '2.2rem',
    marginRight: 10,
    padding: 10,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  blankSlate: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 170px)',
    width: '100%'
  },
  roomTypesContainer: {
    marginBottom: 15,
    paddingLeft: '15px',
    flex: '1 0 auto'
  },
  roomType: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: 5,
    marginTop: 10
  },
  roomRow: {
    borderBottom: '1px solid #f2f2f2',
    boxShadow: '0 3px 4px 0 #ECECEC',
    minHeight: '42px',
    paddingBottom: 5,
    paddingTop: 5,
  },
  room: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2rem',
    height: '100%',
    marginLeft: 10
  },
  // legend: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   padding: '8px 20px',
  // },
  // legendItem: {
  //   alignItems: 'center',
  //   display: 'flex',
  //   fontSize: '1.3rem',
  //   marginRight: 20,
  // },
  // color: {
  //   borderRadius: '50%',
  //   width: 10,
  //   height: 10,
  //   marginRight: 7,
  // },
  // inHouse: {
  //   background: '#f0f2ff',
  // },
  // checkedOut: {
  //   background: '#e5f0ec',
  // },
  // confirmed: {
  //   background: '#fdf3da',
  // },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  calenderBlockContainer: {
    // height: 'inherit',
    height: 'calc(100vh - 250px)',
    // zIndex: 999,
    '& div:focus':{
      outline: 'none'
    }
  },
  reservationLoader:{
    zIndex: 1501,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  accessInfo:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  accessInfoHeader: {
    marginTop: 'unset',
    marginBottom: '10px'
  },
  moduleHeader: {
    width: 'calc(100% - 15px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    paddingRight: 15,
    '& .title':{
        fontSize: '1.7rem',
        fontWeight: 600,
    }
  },
  headerContainer: {
    display: 'flex'
  },
  accessHeader: {
      marginTop: 'unset'
  },
  calendarGrid:{
    position: 'absolute',
    width: '100%',
    height: 'calc(100vh - 290px)',
    paddingRight: '15px',
    boxSizing: 'border-box',
    '& .calendarColumn':{
      display: 'inline-block',
      boxShadow: 'inset 7px 0 6px -7px #F0F0F0',
      height: '100%',
    },

    '& .calendarColumn:first-child':{
      boxShadow: 'unset'
    }
  },
  calendarMenu:{
    position: 'relative',
    display: 'flex',
    '& $buttonContainer:first-child':{
      marginLeft: 'unset'
    }
  },
  calendarRoomsGrid:{
    position: 'absolute',
    width: '100%',
    height: 'calc(100vh - 180px)',
    top: '0px',
    // boxShadow: '0 2px 20px 0 #F0F0F0'
  },
  shortcutsContainer: {
    display: 'flex',
  },
  shortcut: {
    alignItems: 'center',
    fontSize: '1.2rem',
    borderRadius: 5,
    color: '#484848',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 600,
    marginTop: 8,
    marginRight: 10,
    padding: 8,
    width: 120,
    '&:hover': {
      background: '#f8f9fb',
    }
  },
  arrowIcon: {
    color: '#484848',
    fontSize: '1.3rem',
    marginRight: 10,
  },
  optionContainer: {
    display: 'flex',
    marginTop: 14,
  },
  padded: {
    marginTop: 52,
  },
  sortCalendar: {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
    marginRight: 5,
    '& img': {
      height: 25,
      opacity: 0.3,
      width: 25,
    },
    '&:hover': {
      background: '#edf2f7',
    }
  },
  calendarOption: {
    '& img': {
      height: 20,
      width: 20,
    },
  }
}));

const FILTERS = {
  roomType: 'roomType',
  room: 'room',
  building: 'building'
};

const CalendarPage = (props) => {
  const classes = useStyles();
  const {
    // checkIn,
    // checkOut,
    currentSpace,
    open,
    handleClose,
    reservations,
    isLoading,
    cancelSuccess,
    updateReservationErrors,
    status,
    date,
    roomType,
    isRoomTypesLoading,
    live
  } = props;

  // adding extra row for unallocated reservations
  const calendarDisplayData = isEmpty(props.calendarDisplayData)
    ? props.calendarDisplayData
    : [...props.calendarDisplayData, { isUnallocated: true }]

  const { uiSetting } = currentSpace;
  const filterType = (uiSetting && uiSetting.calendarSort) ? uiSetting.calendarSort : FILTERS.roomType;
  const numberOfDays = window.innerWidth < 901 ? 5 : 12;
  const ListRef = React.useRef(null);

  const [day, setDay] = React.useState({
    startDate: fetchDatePerTimezone(null, currentSpace.timezone),
    endDate: fetchDatePerTimezone(null, currentSpace.timezone),
    days: []
  });

  const [roomblockModal, setRoomblockModal] = React.useState(false);
  const [reservationModal, setReservationModal] = React.useState(false);
  const [newReservation, setNewReservation] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [openRoomBlock, setOpenRoomBlock] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(true);
  const [dragEnabled, setDragEnabled] = React.useState(true);
  const [tooltipEnabled, setTooltipEnabled] = React.useState(true);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [confirmData, setConfirmData] = React.useState({}); 
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [resetDate, setResetDate] = React.useState(null);
  const [fullScreenMode, setFullscreenMode] = React.useState(false);
  const [openColorConfig, setOpenColorConfig] = React.useState(false);
  const [colorConfig, setColorConfig] = React.useState({ ...CalendarColors });

  const checkIn = fetchDatePerTimezone(null, currentSpace.timezone).subtract( 1, 'days').format("YYYY-MM-DD");
  const checkOut = fetchDatePerTimezone(null, currentSpace.timezone).add((numberOfDays - 2), 'days').format("YYYY-MM-DD");

  const cellMeasurerRef = useRef()

  React.useEffect(()=>{
    props.dispatch(fetchBookingSource(currentSpace.propertyID, currentSpace._id));
    props.dispatch(fetchProductList(currentSpace.propertyID))
    props.dispatch(
      getCalendarRenderData(
        currentSpace.propertyID, 
        filterType
    ));

    // props.dispatch(fetchProperty(currentSpace.propertyID))

    // return () =>{
    //   props.dispatch(resetRatePlans());
    // }
  },[]);

  React.useEffect(() => {
    props.dispatch(setCalendarDates({spaceID: currentSpace._id, dates: day}))
  }, [day])

  React.useEffect(()=>{
    formatColorConfig();
  }, [live]);

  React.useEffect(()=>{
    //Clear calendar row cached height on re-render
    clearCalendarCache();
  }, [props.calendarDisplayData]);

  // React.useEffect(()=>{
  //   // On page reload roomtypes is fetched last,
  //   // Hence reservation attached to beds state are lost when roomtypes reducer is refreshed
  //   // Hence the below code fetches reservation data after roomtypes are fetched 
  //   // And attaches reservations to beds state.  
  //   if(!isRoomTypesLoading){
  //     props.dispatch(
  //       getReservations(
  //         props.currentSpace.propertyID,
  //         checkIn,
  //         checkOut
  //     ));
  //   } 
  // }, [isRoomTypesLoading]);

  React.useEffect(() => {
    if (cancelSuccess) {
      handleCloseReservation();
      handleCloseEditRoomBlock();
    }
    // On create reservation/room block
    if (props.createReservationSuccess) {
      setOpenRoomBlock(false);
      //if reservation then call the view reservation api to show in the modal
      // if(props.createdReservationID && props.createdReservationStatus){
      //   handleViewReservation(props.createdReservationID, props.createdReservationStatus);
      // }
      handleCloseNewReservation();
    }
    if (props.editRoomBlockSuccess) {
      handleCloseEditRoomBlock();
    }
    if(updateReservationErrors){
      setErrorMessage(updateReservationErrors);
    }
  }, [cancelSuccess, props.createReservationSuccess, updateReservationErrors, props.editRoomBlockSuccess]);

  const compare = ( a, b ) => {
    if ( a.count > b.count ){
        return -1;
    }
    if ( a.count < b.count ){
        return 1;
    }
    return 0;
  }

  const formatColorConfig = () =>{
    const groupType = CalendarColors.bookingSource.id;
    
    const sourceData = live[currentSpace._id] && live[currentSpace._id].resvSource || [];
    const bookingSourceData = sourceData.sort(compare).slice(0,5).map(data => data._id.status ? data._id.status : "None");
    
    const colorConfigData = _.cloneDeep(CalendarColors);
    const constantOptions = Object.keys(BOOKING_SOURCE).map(source => BOOKING_SOURCE[source]);
    
    let bookingSouceOptions = [ ...constantOptions, ...bookingSourceData ];
    bookingSouceOptions = [ ...new Set(bookingSouceOptions) ];
    
    const colorArray = colorConfigData[groupType].colorArray;

    bookingSouceOptions.forEach((option, indx) =>{
      colorConfigData[groupType].colorInfo[option] = {
        id: option,
        title: option,
        color: colorArray[indx]
      }
    });

    // colorConfigData[groupType].colorInfo['others'] = {
    //   id: 'others',
    //   title: 'Others',
    //   color: CalendarColors.default.color
    // }

    setColorConfig({ ...colorConfigData });
  }

  const handleSelectedDate = (newDay) => {
    setDay({ ...newDay })
  }

  const handleCalendarDays = (newDay) => {
    setDay({ ...newDay });
    setResetDate(null);

    props.dispatch(
      getReservations(
        props.currentSpace.propertyID,
        newDay.startDate.format("YYYY-MM-DD"),
        newDay.endDate.format("YYYY-MM-DD"))
    );
  };

  // TODO: Add overlap modal
  const handleViewReservation = (resID, status) => {
    props.dispatch(getReservationDetails(resID));
    if (status === RESERVATION_STATUS.BLOCK) {
      setRoomblockModal(true)
    } else {
      setReservationModal(true);
    }
    setSnackBarOpen(false)
  }

  const handleCloseReservation = () => {
    setReservationModal(false)
    props.dispatch(resetReservationDetails());
    props.dispatch(resetReservationManualOptions())
  }

  const handleCloseNewReservation = (id) => {
    props.dispatch(resetError('ADD_RESERVATION'))
    setNewReservation(false);
  }

  const handleNewReservation = () => {
    setNewReservation(true)
    setSnackBarOpen(true)
  }

  const handleOpenRoomBlock = () => {
    setOpenRoomBlock(true);
  }

  const handleCloseRoomBlock = () => {
    props.dispatch(resetError('ADD_RESERVATION'))
    setOpenRoomBlock(false);
    setRoomblockModal(false)
  }

  const handleCloseEditRoomBlock = () => {
    props.dispatch(resetReservationDetails());
    setRoomblockModal(false)
  }

  const handleSearch = (value) => {
    setSearch(value)
    props.dispatch(submitSearchKeyword(currentSpace.propertyID, value, status, date, roomType));
  }

  const handleClearSearch = () => {
    props.dispatch(clearSearch());
    setSearch('');
  }

  const updateReservation = data =>{
    const propertyID = currentSpace.propertyID;
    const calendarData = {
      startDate: day.startDate.format("YYYY-MM-DD"), 
      endDate: day.endDate.format("YYYY-MM-DD")
    }
    setOpenConfirm(false);
    props.dispatch(updateCalenderReservation(propertyID,data,calendarData));
  }

  const handleConfirmShow = (data) =>{
    const confirmData = { ...data, propertyID: currentSpace.propertyID }
    setConfirmData(confirmData);
    setOpenConfirm(true);
  }

  const handleConfirmClose = () =>{
    setConfirmData({});
    setOpenConfirm(false);
  }

  const triggerErrorHandler = errorMessage =>{
    setErrorMessage(errorMessage);
  }

  const handleCalendarFilter = () =>{
    setOpenFilter(true);
  }

  const closeFilterModal = () =>{
    setOpenFilter(false);
  }

  const handleColorConfig = () =>{
    setOpenColorConfig(true);
  }

  const closeColorModal = () =>{
    setOpenColorConfig(false);
  }

  const resetErrorHandler = () =>{
    setErrorMessage(null);
    props.dispatch(resetError("UPDATE_CALENDER_RESERVATION"));
    props.dispatch(resetError("UPDATE_RESERVATION_STATUS"));
  }

  const updateStatusHandler = (resID, status) => {
    const ACTION_NAME = 'RESERVATION_STATUS_LOADER';
    props.dispatch({ type: `${ACTION_NAME}_REQUEST` });
    props.dispatch(updateReservationStatus(resID, { status }))
      .then(success => {
        props.dispatch({ type: `${ACTION_NAME}_SUCCESSS` });
      })
  }

  const getCalendarRenderProps = () =>({
      day,
      guestNameLabel: true,
      colour: true,
      handleViewReservation,
      updateStatusHandler,
      numberOfDays, 
      singleReservation: false,
      tooltip: tooltipEnabled,
      dragEnabled,
      reservations,
      handleConfirmShow,
      triggerErrorHandler,
      filterType,
      colorConfigInfo : { ...colorConfig },
      updateReservation
  });

  const renderCalender = ({ index, parent, key, style }) =>{

    const calendarData = calendarDisplayData[index];

    let calendarProps = getCalendarRenderProps()

    calendarProps = { ...calendarProps, cellMeasurerRef }

    return(
      <CellMeasurer
          ref={cellMeasurerRef}
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
      >
        <Grid 
          container 
          key={`${filterType}_${index}`} 
          className={classes.roomTypesContainer} 
          style={style}
        >
          <CalendarRender 
            type={filterType}
            calendarData={calendarData}
            calendarProps={calendarProps}
          />
        </Grid>
      </CellMeasurer>
    );
  }

  const clearCalendarCache = () =>{
    cache.clearAll();
    ListRef.current.recomputeRowHeights();
  }

  const setFullScreenMode = () =>{
    setFullscreenMode(true);
  }

  const hideFullScreenMode = () =>{
    setFullscreenMode(false);
  }

  return (
    <div>
      <div className={clsx({[classes.root]: true, [classes.fullScreen]: !search})}>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* CALENDAR HEADER */}
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid container justifyContent="flex-end" className={classes.calendarHeader}>

          {/* -----------MODULE HEADER-----------*/}
          {/* <div className={classes.moduleHeader}>
            <ModuleHeader 
              search={search} 
              handleSearch={handleSearch} 
              handleClearSearch={handleClearSearch} 
              handleNewReservation={handleNewReservation}
            />
          </div> */}

          {/* -----------------Sort Options---------------*/}
          {!search && (
            <Grid item xs={12} className={classes.calendarMenu}>
              <SortOptions 
                day={day} 
                checkIn={checkIn}  
                setResetDate={setResetDate}  
                handleCalendarFilter={handleCalendarFilter}  
                handleColorConfig={handleColorConfig}
                handleOpenRoomBlock={handleOpenRoomBlock} 
                setFullScreenMode={setFullScreenMode}
                handleNewReservation={handleNewReservation}
              />
            </Grid>
          )}

          {search && <Grid item xs={12}><SearchFilters /></Grid>}
          
          {/* -----------------Dates Header---------------*/}
          {!search && (
            <Grid item xs={9} sm={9} md={10} className={classes.calendarDates}>
              <DatesHeader 
                checkIn={checkIn}
                checkOut={checkOut}
                resetDate={resetDate}
                numberOfDays={numberOfDays}
                timezone={currentSpace.timezone}
                dateFormat={currentSpace.dateFormat}
                handleSelectedDate={handleSelectedDate}
                handleCalendarDays={handleCalendarDays}
              />
            </Grid>
          )}
        </Grid>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* CALENDAR BODY */}
        {/* ------------------------------------------------------------------------------------------ */}

        {!search && <Grid container className={classes.calenderBlockContainer}>
          {isLoading && 
            !fullScreenMode &&
              <div className={classes.reservationLoader}>
                <CircularProgress className={"loading"}/>
              </div>
          }

          <DndProvider backend={Backend}>
            <AutoSizer>
              {
                  ({ height, width }) => (
                      <List
                          ref={ListRef}
                          className="List"
                          height={height}
                          width={width}
                          rowCount={calendarDisplayData.length}
                          deferredMeasurementCache={cache}
                          rowHeight={cache.rowHeight}
                          rowRenderer={renderCalender}
                          overscanRowCount={1}
                      />
              )}
            </AutoSizer>

            {fullScreenMode && (
              <CalendarModal 
                calendarDisplayData={calendarDisplayData}
                getCalendarRenderProps={getCalendarRenderProps}
                day={day} 
                checkIn={checkIn}  
                checkOut={checkOut}
                setResetDate={setResetDate}  
                handleCalendarFilter={handleCalendarFilter}  
                handleOpenRoomBlock={handleOpenRoomBlock} 
                setFullScreenMode={setFullScreenMode}
                resetDate={resetDate}
                numberOfDays={numberOfDays}
                timezone={currentSpace.timezone}
                handleNewReservation={handleNewReservation}
                handleSelectedDate={handleSelectedDate}
                handleCalendarDays={handleCalendarDays}
                handleClose={hideFullScreenMode}
                colorConfigInfo = {colorConfig}
                handleColorConfig={handleColorConfig}
              />
            )}

          </DndProvider>
        </Grid>}

        {/* --------------------------------------------- */}
        {/* CALENDAR LEGEND */}
        {/* --------------------------------------------- */}
        {!search && <CalendarLegends colorConfigInfo = {colorConfig} /> }

        {/* ------------------------------------------------------------------------------------------ */}
        {/* MODALS */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* VIEW RESERVATION */}
        {reservationModal &&
          <Reservation
            open={reservationModal}
            handleCloseReservation={handleCloseReservation}
            calendarData={{
              startDate: day.startDate.format("YYYY-MM-DD"),
              endDate: day.endDate.format("YYYY-MM-DD")
            }}
          />
        }

        {/* NEW RESERVATION */}
        {newReservation &&
          <NewReservation
            open={newReservation}
            handleCloseNewReservation={handleCloseNewReservation}
            calendarData={{
              startDate: day.startDate.format("YYYY-MM-DD"),
              endDate: day.endDate.format("YYYY-MM-DD")
            }}
          />
        }

        {/* ADD ROOM BLOCK */}
        {openRoomBlock &&
          <AddRoomBlock
            open={openRoomBlock}
            closeRoomBlock={handleCloseRoomBlock}
            calendarData={{
              startDate: day.startDate.format("YYYY-MM-DD"),
              endDate: day.endDate.format("YYYY-MM-DD")
            }}
          />
        }

        {/* EDIT ROOM BLOCK */}
        {roomblockModal &&
          <EditRoomBlock
            open={roomblockModal}
            closeRoomBlock={handleCloseEditRoomBlock}
            calendarData={{
              startDate: day.startDate.format("YYYY-MM-DD"),
              endDate: day.endDate.format("YYYY-MM-DD")
            }}
          />
        }
        
        {/* CONFIRM ROOM DRAG AND DROP */}
        {openConfirm &&
          <ConfirmModal 
            isOpen={openConfirm}
            closeHandler={handleConfirmClose}
            confirmData={confirmData}
            updateCalenderReservation = {updateReservation}
            currency={fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null)}
            /> 
          }

          {/* SORT CALENDAR */}
          {openFilter && (
            <CalendarSort 
              open={openFilter}
              closeModalHander={closeFilterModal}
              clearCalendarCache={clearCalendarCache}
            />
          )}

          {/* CUSTOMIZE RESERVATION COLORS */}
          {openColorConfig && (
            <CalendarColor 
              open={openColorConfig}
              colorConfigInfo={colorConfig}
              closeModalHander={closeColorModal}
            />
          )}


        {/* ------------------------------------------------------------------------------------------ */}
        {/* SNACK BARS */}
        {/* ------------------------------------------------------------------------------------------ */}


        {/* RESERVATION CREATED */}
        {(props.createdReservationID && props.createReservationSuccess) && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackBarOpen}
            onClose={() => setSnackBarOpen(false)}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">
                New reservation successfully created. <a className={classes.link} onClick={() => handleViewReservation(props.createdReservationID)}>View Reservation</a>
              </span>
            }
          />
        )}

        {/* ERROR MESSAGE */}
        {/* {Boolean(errorMessage) && ( */}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(errorMessage)}
            onClose={resetErrorHandler}
            autoHideDuration={6000}
            message={errorMessage}
          />
        {/* )} */}

      </div>

      {/* ------------------------------------------------------------------------------------------ */}
      {/* SEARCH RESULTS */}
      {/* ------------------------------------------------------------------------------------------ */}

      {search && <Grid container className={classes.calenderBlockContainer}><SearchResults /></Grid>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  reservations: state.reservations,
  isLoading: state.loading.LOAD_RESERVATIONS || state.loading.GET_CALENDAR_RENDER_DATA || state.loading.RESERVATION_STATUS_LOADER,
  editRoomBlockSuccess: state.success.UPDATE_ROOMBLOCK,
  createReservationSuccess: state.success.ADD_RESERVATION,
  createdReservationID: state.reservationDetails.reservation && state.reservationDetails.reservation._id,
  createdReservationStatus: state.reservationDetails.reservation && state.reservationDetails.reservation.status,
  cancelSuccess: state.success.CANCEL_RESERVATION,
  updateReservationErrors: state.errors.UPDATE_CALENDER_RESERVATION || state.errors.UPDATE_RESERVATION_STATUS,
  calendarDisplayData: state.calendarSnapshot.calendarDisplayData || [],
  status: state.search.status,
  date: state.search.date,
  roomType: state.search.roomType,
  isRoomTypesLoading: state.loading.LOAD_ROOMTYPES,
  live: state.live
})

export default connect(mapStateToProps)(CalendarPage);
