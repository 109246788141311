import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import clsx from 'clsx'
import AddPhoto from '@material-ui/icons/AddPhotoAlternateOutlined'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ConfirmForm from '../../../common/ConfirmForm'
import { deleteHostelWorldImages, uploadHostelWorldImages } from '../../../../redux/actions/microsite/hostelworld';
import IMAGES from '../../../../constants/images';

const thumbnailPath = 'https://a.hwstatic.com/image/upload/c_fill,b_white,h_180,w_180/v1/'
const mediumPath = 'https://a.hwstatic.com/image/upload/c_fit,w_900/v1/'

const useStyles = makeStyles(theme => ({
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(80% - 60px)',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(60% - 60px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px)'
    }
  },
  bulb: {
    width: 40,
    height: 40
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  imageContainer: {
    marginBottom: 20,
    marginRight: 20,
    cursor: 'pointer'
  },
  addImage: {
    border: '2px dashed #dddddd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 175,
    height: 175,
    fontSize: 70,
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer',
    }
  },
  viewImageContainer: {
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 64px)',
      padding: 10
    }
  },
  previewContainer: {
    width: '100%',
    position: 'relative',
    '& img': {
      width: 180,
      height: 180,
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  closeImageIcon: {
    top: '-12px',
    color: '#666666',
    right: '-12px',
    cursor: 'pointer',
    padding: '5px',
    position: 'absolute',
    background: '#dddddd',
    transition: '150ms',
    borderRadius: '50%',
    width: '17px',
    height: '17px',
  },
  hidden: {
    display: "none"
  },
  warning: {
    color: '#ff9800',
    fontSize: '1.2rem',
    marginTop: 10,
    marginBottom: 10
  },
  errorContainer: {
    marginBottom: 80
  }
}))

const Upload = props => {
  const [fileInputState, setFileInputState] = useState('');
  const [previewSource, setPreviewSource] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');

  React.useEffect(()=>{
    props.setError(errMsg)
  }, [errMsg])

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setErrMsg('')
    setFileInputState(e.target.value);
    handleSubmitFile(file)
  };

  const handleSubmitFile = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      let height ;
      let width;
      //Initiate the JavaScript Image object.
      let image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        height = this.height;
        width = this.width;
        if (height < 1080 || width < 1920) {
          setErrMsg(`Image dimensions (${width}X${height}) are smaller than the minimum required: (1920X1080)`);
          setPreviewSource('')
          reader.abort()
          return false;
        }
        setPreviewSource(reader.result);
        uploadImage(reader.result);
        return true
      };
    };
    reader.onerror = () => {
      setPreviewSource('')
      console.log('here in onerror')
      setErrMsg('something went wrong!');
    };
  };

  const uploadImage = async (base64EncodedImage) => {
    props.uploadImageHandler(JSON.stringify({ data: base64EncodedImage }));
  };
  return (
    <React.Fragment>
      {previewSource && (
        <div className={props.classes.imageContainer}>
          <div className={props.classes.previewContainer}>
            <img
              src={previewSource}
              alt="chosen"
            />
          </div>
        </div>
      )}

      <div className={clsx(props.classes.imageContainer, props.classes.addImage)}>
          <input
            id="fileInput"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileInputChange}
            value={fileInputState}
            className={props.classes.hidden}
          />
          <label htmlFor="fileInput">
            <AddPhoto fontSize='inherit' />
          </label>
      </div>
    </React.Fragment>
  );
}

const DragggableImage = props => {
  const { id, src, onClick, position, classes, changeOrderFromTo, confirmHandler } = props

  const ref = useRef(null)

  const [{ isDragging }, connectDrag] = useDrag(({
    item: { type: 'image', position },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const [, connectDrop] = useDrop({
    accept: 'image',
    hover: (item, monitor) => {
      // item = dragged item
      if (item.position !== position) {
        changeOrderFromTo(item.position, position)
        item.position = position
      }
    }
  })

  connectDrag(ref)
  connectDrop(ref)

  return (
    <div className={classes.previewContainer} ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
      <CloseIcon
        className={classes.closeImageIcon}
        onClick={() => confirmHandler(id)}
      />
      <img src={src} onClick={onClick} />
    </div>
  )
}

const PropertyImages = props => {
  const classes = useStyles()
  const {
    settings,
    updateSettings,
    currentSpace,
    t
  } = props

  const [viewImage, setViewImage] = useState(null)
  const [paperWidth, setPaperWidth] = useState(null)
  const [toDeleteID, setToDeleteID] = useState(null)
  const [error, setError] = useState(null)

  const paperRef = useCallback(node => {
    if (!node) return
    const width = node.getBoundingClientRect().width
    setPaperWidth(width <= 991 ? width : width - 20)
  }, [])

  const { images = [] } = settings
  const processedImages = images
    .sort((a, b) => a.position - b.position)
    .map(i => ({
      id: i.id,
      thumbnail: thumbnailPath + i.uriPath,
      medium: mediumPath + i.uriPath,
      position: i.position
    }))

  const changeOrderFromTo = (from, to) => {
    const fromIndex = from - 1
    const toIndex = to - 1
    const sorted = images.sort((a, b) => a.position - b.position)
    const removed = sorted.splice(fromIndex, 1)[0]
    sorted.splice(toIndex, 0, removed)
    const newImages = sorted.map((s, i) => ({ ...s, position: i + 1 }))
    updateSettings('images', newImages)
  }

  const deleteImageHandler = () => {
    const { dispatch } = props;
    dispatch(deleteHostelWorldImages(toDeleteID, currentSpace.propertyID));
  }

  const uploadImageHandler = (image) => {
    const { dispatch } = props;
    dispatch(uploadHostelWorldImages(image, currentSpace.propertyID));
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.images.title')}</div>
        <div className='sub-title'>{t('microsite.images.description')}</div>

        <div className='row'>
          <div className={classes.infoRow}>
            <div>
              <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
            </div>
            <div className={classes.helperText}>
              {t('microsite.images.helperText')}
            </div>
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className={classes.imagesContainer}>
            {processedImages.map(i => (
              <div key={i.thumbnail} className={classes.imageContainer}>
                <DragggableImage
                  id={i.id}
                  src={i.thumbnail}
                  classes={classes}
                  confirmHandler={(id) => setToDeleteID(id)}
                  onClick={() => setViewImage(i.medium)} 
                  position={i.position}
                  changeOrderFromTo={changeOrderFromTo}
                />

                {toDeleteID === i.id && <div className={classes.confirmContainer}>
                  <ConfirmForm
                    title="Delete Photo?"
                    open={true}
                    setOpen={() => setToDeleteID(null)}
                    onClose={() => setToDeleteID(null)}
                    onConfirm={deleteImageHandler}
                  >
                    Delete Photo?
                    </ConfirmForm>
                </div>}
              </div>
            ))}
            {images.length <= 30 && (
              <Upload classes={classes} uploadImageHandler={uploadImageHandler} setError={setError}/>
            )}
          </div>
          <div className={classes.errorContainer}> {error && <span className={classes.warning}>{error}</span>}</div>
        </DndProvider>

        {viewImage && (
          <Dialog
            maxWidth='md'
            fullWidth
            fullScreen={window.innerWidth < 901}
            open={Boolean(viewImage)}
            onClose={() => setViewImage(null)}
          >
            <CloseIcon className={classes.closeIcon} onClick={() => setViewImage(null)} />
            <div className={classes.viewImageContainer} ref={paperRef}>
              <div style={{ margin: 'auto' }}>
                <img src={viewImage} width={paperWidth} />
              </div>
            </div>
          </Dialog>
        )}
      </div>

    </>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(PropertyImages))
