import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DATA_ACTION, inventoryConditionHandler } from '../../../../utils/helper';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
    },
    bubble: {
        alignItems: 'center',
        background: '#E2E2E2',
        borderRadius: 10,
        color: '#666666',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.3rem',
        flexDirection: 'column',
        height: 100,
        justifyContent: 'center',
        lineHeight: 1.5,
        marginRight: 20,
        padding: 20,
        textAlign: 'center',
        width: 100,
        [theme.breakpoints.down('xs')]: {
            height: 70,
            width: 70,
            padding: 10,
        }
    },
    selected: {
        background: '#4F9581',
        color: '#FFFFFF',
        fontWeight: 600,
    },
}));

const RoomTypeVisibility = props => {
    const classes = useStyles();
    const {
        room,
        roomTypes,
        updateRoomTypeVisibility
    } = props;

    const [visibility, setVisibility] = useState([]);

    useEffect(() => init(), []);

    const init = () =>{
        
        if(!room?.mapping || room.mapping.length == 0) return;

        const visibilityData = room.mapping.map(data =>({
            roomTypeID: data.roomTypeID,
            status: data.visible
        }));
        
        const uniqVisibility = visibilityData.filter((vis, index, self) =>
          index === self.findIndex((t) => (
            t.roomTypeID === vis.roomTypeID && t.status === vis.status
          ))
        )
        
        setVisibility([ ...uniqVisibility ]);
    }

    const toggleVisibility = (selectedIndex) => {

        const updatedVisibility = visibility.map((data, index) =>{
            if(index == selectedIndex) return { ...data, status: !data.status };
            return { ...data };
        });

        setVisibility([ ...updatedVisibility ]);
        updateRoomTypeVisibility(updatedVisibility);
    }


    return (
        <>
            <div className="main-content">


                <div className="main-content-title">Room Type Visibility</div>
                <div className="sub-title">Choose which room types will be visible on the calendar</div>

                <div className="row">
                    <div className={classes.buttonContainer}>

                        {visibility.map((data, index) =>(
                            <div
                                key={`roomtype_${data.roomTypeID}`}
                                className={clsx({ [classes.bubble]: true, [classes.selected]: data.status })}
                                onClick={() => toggleVisibility(index)}
                            >
                                {inventoryConditionHandler(roomTypes[data.roomTypeID], DATA_ACTION.NAME_STATUS)}
                            </div>
                        ))}
                
                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = state => ({
	currentSpace: state.spaces[state.dashboard.currentSpace],
	roomTypes: state.roomTypes
});

export default connect(mapStateToProps)(RoomTypeVisibility); 