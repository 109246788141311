import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex'
  },
  bubble: {
    alignItems: 'center',
    background: '#E2E2E2',
    borderRadius: 10,
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
      padding: 10
    }
  },
  selected: {
    background: '#4F9581',
    color: '#FFFFFF',
    fontWeight: 600
  }
}))

const Visibility = props => {
  const classes = useStyles()
  const {
    ratePlan,
    handleUpdateRatePlan
  } = props

  const multiCurrencyEnabled = ratePlan.multiCurrency && ratePlan.multiCurrency.enabled

  const handleClick = (app) => {
    if (app === 'Booking Engine' && multiCurrencyEnabled) return
    const index = ratePlan.visibility.indexOf(app)
    if (index > -1) {
      const array = [...ratePlan.visibility]
      array.splice(index, 1)
      handleUpdateRatePlan('visibility', [...array])
    } else {
      handleUpdateRatePlan('visibility', [...ratePlan.visibility, app])
    }
  }

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>Visibility</div>
        <div className='sub-title'>Choose where this rate plan can be booked within Counter</div>

        <div className='row'>
          <div className={classes.buttonContainer}>
            <div
              className={clsx({ [classes.bubble]: true, [classes.selected]: (ratePlan.visibility.indexOf('Workspace') > -1) })}
              onClick={() => handleClick('Workspace')}
            >
              Workspace
            </div>
            <div
              className={clsx({ [classes.bubble]: true, [classes.selected]: (ratePlan.visibility.indexOf('Frontdesk') > -1) })}
              onClick={() => handleClick('Frontdesk')}
            >
              Frontdesk
            </div>

            <Tooltip
              title={multiCurrencyEnabled
                ? 'Multi-currency rates are currently not supported in Booking Engine. Coming out soon!'
                : ''}
            >
              <div
                className={clsx({ [classes.bubble]: true, [classes.selected]: (!multiCurrencyEnabled && ratePlan.visibility.indexOf('Booking Engine') > -1) })}
                onClick={() => handleClick('Booking Engine')}
              >
                Booking Engine
              </div>
            </Tooltip>

          </div>
        </div>

      </div>
    </>
  )
}

export default Visibility
