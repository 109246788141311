import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CloseIcon from '@material-ui/icons/Close';

import { returnCurrent } from '../../redux/selectors/dashboard';

import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  CircularProgress,
  Snackbar,
  DialogActions,
} from '@material-ui/core';
import { fetchDatePerTimezone, fetchDateFormat, DATE_FORMATS } from '../../utils/utility';
import { ERRORS } from '../../utils/errors';
import { resetError, cancelReservation, updateRoomBlock } from '../../redux/actions/reservation/editReservation';
import RoomBlock from './RoomBlock';
import Breadcrumb from '../common/Breadcrumb';
// import { ICONS } from '../../utils/imageUrls';
import { RESERVATION_STATUS } from '../../utils/constants';
import ConfirmForm from '../common/ConfirmForm';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
    },
  },
  root: {
    borderRadius: '15px',
    width: '100%'
  },
  dialogContent: {
    minHeight: 250,
    overflow: 'visible',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
    }
  },
  error: {
    color: 'red',
    marginTop: 20,
    marginRight: 5
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  dialogAction: {
    padding: '8px 30px 30px 30px',
    justifyContent: 'space-between'
  },
  Button: {
    background: '#000',
    color: '#fff',
    width: 120,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  title: {
    color: '#333333',
    fontSize: 22,
    fontWeight: 600
  },
  blankSlate: {
    marginTop: 25,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  actionIcon: {
    cursor: 'pointer',
    height: 30,
    width: 30
  },
  cancelDialog: {
    '& .MuiDialog-paperWidthSm': {
      padding: '40px 20px'
    }
  },
  cancelText: {
    color: '#000',
    fontSize: '1.6rem'
  },
  cancelDialogAction: {
    padding: '8px 24px',
    justifyContent: 'flex-start'
  },
  cancelButton: {
    background: '#ffffff',
    borderRadius: 5,
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    padding: theme.spacing(2),
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75) !important',
    width: 100,
    textAlign: 'center'
  }
}))

const EditRoomBlock = forwardRef((props, ref) => {

  const classes = useStyles();
  const { open, closeRoomBlock, roomTypes, rooms, currentSpace, beds, roomBlock, loadingRoomBlock } = props;
  const [breadcrumbList, setBreadcrumbList] = React.useState({});
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [cancelVisible, setCancelVisible] = React.useState(false);
  const [bedList, setBedList] = React.useState([]);
  const [roomTypeList, setRoomTypeList] = React.useState([]);
  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [prefilledData, setPrefilledData] = React.useState({});
  const blockRef = React.createRef();
  let dialogContentNode = null;

  React.useEffect(() => {
    if (roomBlock) {
      handleProcessData();
    }
  }, [roomBlock])

  const handleProcessData = () => {
    let accommodations = [...roomBlock.accommodationList];
    let accommodationBeds = accommodations.map(accommodation => accommodation.bedID);
    setPrefilledData({
      dates: {
        startDate: fetchDatePerTimezone(roomBlock.checkIn),
        endDate: fetchDatePerTimezone(roomBlock.checkOut)
      },
      tab: 'bed',
      label: roomBlock.guestName,
      type: accommodations?.[0]?.roomTypeID,
      category: roomBlock.category,
      roomBeds: [...accommodationBeds]
    })

    if (accommodations.length) {
      let firstAcc = { ...accommodations[0] };
      let roomTypeID = firstAcc.roomTypeID;
      if (firstAcc.roomID && rooms[firstAcc.roomID]) {
        handleSelectRoom(firstAcc.roomID, rooms[firstAcc.roomID].beds || [])
        let icon;
        if (roomTypes?.[roomTypeID]?.dormOrPrivate === 'private') icon = IMAGES.ICONS.darkBed;
        else icon = IMAGES.ICONS.darkHostel;
        let breadcrumbList = {};
        breadcrumbList[1] = { step: 1, text: rooms[firstAcc.roomID].name, icon: icon }
        setBreadcrumbList({ ...breadcrumbList });
      }
    }
  }


  // ----- Check for required fields validation -----
  const handleFormvalidation = (blocks, blockData) => {
    let hasError = false;
    if (
      !blocks.length ||
      !blockData.label ||
      !blockData.category ||
      !blockData.dates.startDate ||
      !blockData.dates.endDate
    )
      hasError = true;
    if (!hasError && blockData.tab === 'room' && !blocks[0].roomTypeID)
      hasError = true;
    return hasError;
  }

  // -------- Submit room block data -----------
  const handleSubmit = () => {
    setError(false);
    let blockData = blockRef.current.handleProcessData();
    // let bedBlocks = blockData.tab === 'room' ? bedList.map(bedData => bedData.value) : blockData.tab === 'bed' ? blockData.roomBeds : [];
    let bedBlocks = blockData.tab === 'room' ? [{ roomTypeID: blockData.type }] : blockData.tab === 'bed' ? blockData.roomBeds : [];
    const hasError = handleFormvalidation(bedBlocks, blockData);
    if (hasError) {
      setError(true);
      setErrorMessage('Please complete the form.')
      dialogContentNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return false;
    }
    let accommodations = [...roomBlock.accommodationList];

    let data = {
      reservation: {
        _id: roomBlock._id,
        isBlock: true,
        guestName: blockData.label,
        category: blockData.category,
        propertyID: currentSpace.propertyID,
        checkIn: fetchDateFormat(blockData.dates.startDate, DATE_FORMATS.DEFAULT_DATE),
        checkOut: fetchDateFormat(blockData.dates.endDate, DATE_FORMATS.DEFAULT_DATE),
      },
      allocation: [],
      ...(blockData.tab === 'room' && { roomID: selectedRoom }) 
    };

    if (bedBlocks.length) {
      if (blockData.tab === 'bed') {
        data.allocation = bedBlocks.map(bedID => {
          const bedAcc = accommodations.find(acc => bedID === acc.bedID);
          return {
            accommodationID: bedAcc && bedAcc._id || false,
            roomTypeID: blockData.type,
            bedID: bedID
          }
        })
        accommodations.forEach(acc => {
          if (!bedBlocks.includes(acc.bedID)) {
            data.allocation.push({ accommodationID: acc._id, bedID: false, roomTypeID: blockData.type })
          }
        })
      } else if (blockData.tab === 'room') {
        data.allocation = [...bedBlocks];
      }
    }
    props.dispatch(updateRoomBlock(currentSpace.propertyID, data, props.calendarData))
  }

  // -------- Close snackbar -------------
  const handleCloseError = () => {
    props.dispatch(resetError('UPDATE_ROOMBLOCK'))
  }

  // -------- Build a list of beds of a room -----------
  const handleSelectRoom = (roomID, roomTypeBeds) => {
    let list = roomTypeBeds.map(bedID => {
      if (beds[bedID] && beds[bedID].roomID === roomID) {
        return { value: bedID, label: beds[bedID].name, type: beds[bedID].roomTypeID }
      }
    }).filter(data => data)
    let roomTypeList = props?.rooms[roomID]?.mapping?.filter(data => roomTypes[data.roomTypeID].isActive).map(item => {
      return { value: item.roomTypeID, label: roomTypes[item.roomTypeID].name }
    }) || [];

    setSelectedRoom(roomID);
    setBedList(list)
    setRoomTypeList(roomTypeList)
  }

  // ---------- Cancel room block ---------------
  const handleCancelRoomBlock = () => {
    props.dispatch(cancelReservation(roomBlock._id, { status: RESERVATION_STATUS.CANCELLED }));
    setCancelVisible(false);
  }

  return (
    <Dialog
      open={open}
      onClose={closeRoomBlock}
      className={classes.dialogContainer}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901} ref={ref}>

      <div className={classes.root}>
        <CloseIcon className={classes.closeIcon} onClick={props.closeRoomBlock} />

        <DialogContent>
          <div ref={node => { dialogContentNode = node; }}>
            <DialogContent className={classes.dialogContent}>
              {/* ------------ Title, error  ------------ */}
              <span className={classes.title}>Edit Room Block</span>
              {error && (<div className={classes.error}>{errorMessage}</div>)}
              {!loadingRoomBlock &&
                <>
                  {/* ---------- Form data --------------  */}
                  <Breadcrumb list={breadcrumbList} onChangePrev={() => void 0} />
                  <RoomBlock currentSpace={currentSpace} bedList={bedList} roomTypeList = {roomTypeList} ref={blockRef} multiple={false} prefilledData={prefilledData} />
                </>
              }
              {loadingRoomBlock && <div className={classes.blankSlate}>Loading room block details..</div>}
            </DialogContent>
          </div>
        </DialogContent>

        {/* ------------ Action Button ------------ */}
        {!loadingRoomBlock && roomBlock && roomBlock._id &&
          <DialogActions className={classes.dialogAction}>
            {!cancelVisible && <img className={classes.actionIcon} src={IMAGES.ICONS.bin} onClick={() => setCancelVisible(true)} />}
            <ConfirmForm
              open={cancelVisible}
              setOpen={setCancelVisible}
              onClose={() => setCancelVisible(false)}
              onConfirm={handleCancelRoomBlock}
            >
              Are you sure you would like to delete this room block?
            </ConfirmForm>
            <Button className={classes.Button} onClick={() => handleSubmit()}>
              Save Changes
          </Button>
          </DialogActions>
        }
      </div>

      {/* ------------- Loder -------------- */}
      {props.loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }

      {/* ------------- Snackbar error messages -------------- */}
      {props.errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={() => handleCloseError()}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{ERRORS[props.errors] || props.errors}</span>}
        />
      )}

    </Dialog>
  )
})

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  roomTypes: state.roomTypes,
  beds: state.beds,
  errors: state.errors.UPDATE_ROOMBLOCK || state.errors.CANCEL_RESERVATION,
  loading: state.loading.UPDATE_ROOMBLOCK || state.loading.CANCEL_RESERVATION,
  roomBlock: state.reservationDetails.reservation,
  loadingRoomBlock: state.loading.LOAD_RESERVATION,
  cancelSuccess: state.success.CANCEL_RESERVATION,
  rooms: state.rooms
})

export default connect(mapStateToProps)(EditRoomBlock);
