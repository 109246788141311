import React, { forwardRef, useImperativeHandle } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Item from '../../common/Item';
import { Grid } from '@material-ui/core';
// import { ICONS } from '../../../utils/imageUrls';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 20,
    height: 20,
    marginRight: 20
  },
  section: {
    margin: '30px 0 20px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  rooms: {
    
  }
}));

const SelectRoomTypes = forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, roomTypes, multiple, rooms, itemName, prefilledData, checkAvailability, availability, availabilityMin, t } = props;
  const [selectedItem, setSelectedItem] = React.useState({});

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      if (multiple) {
        return fetchMultipleData();
      } else {
        let itemKeys = Object.keys(selectedItem) || [];
        if (itemName === 'rooms') {
          return itemKeys[0] && selectedItem[itemKeys[0]] && rooms[itemKeys[0]]
        } else
          return itemKeys[0] && selectedItem[itemKeys[0]] && roomTypes[itemKeys[0]]
      }
    }
  }))

  React.useEffect(() => {
    if(prefilledData && Object.keys(prefilledData).length) {
      setSelectedItem({ ...prefilledData })
    }
  },[prefilledData])

  const fetchMultipleData = () => {
    let data = { typeID: {}, guestCount: 0, hasShared: false, hasPrivate: false };
    data.typeID = { ...selectedItem };
    Object.keys(selectedItem).map(key => {
      data.guestCount = data.guestCount + selectedItem[key];
      if (roomTypes[key] && roomTypes[key].dormOrPrivate === 'private' && selectedItem[key])
        data.hasPrivate = true;
      if (roomTypes[key] && roomTypes[key].dormOrPrivate === 'dorm' && selectedItem[key])
        data.hasShared = true;
    })
    return data;
  }

  const onCountChange = (count, itemID, itemName) => {
    let itemData = {};
    if (multiple) {
      itemData = { ...selectedItem };
    }
    if (itemID) {
      itemData[itemID] = count;
      setSelectedItem({ ...itemData })
    }
  }

  const fetchItemListView = (value, image) => {

    return currentSpace && currentSpace.roomTypes &&
      Object.values(currentSpace.roomTypes).map((id, typeIndex) => {
        let itemData = { ...roomTypes[id] };
        let roomTypeImage = itemData.images && itemData.images.length ? (itemData.images[itemData.images.length - 1].imagelink || image) : image;
        itemData.image = roomTypeImage;

        if (itemData.dormOrPrivate !== value) return null;
        if (itemName === 'rooms') {
          return itemData.rooms.filter(roomId => rooms[roomId].primaryRoomTypeID === id).map((roomID, roomIndex) => {
            itemData = { ...rooms[roomID] };
            let roomImage = itemData.images && itemData.images.length ? itemData.images[itemData.images.length - 1].key : null;
            itemData.image = roomImage || roomTypeImage;
            return (
              <Grid item xs={6} sm={4} md={3} key={roomIndex}>
                <Item
                  itemID={itemData._id}
                  data={itemData}
                  onCountChange={onCountChange}
                  multiple={multiple}
                  selected={selectedItem[itemData._id]} />
              </Grid>
            )
          })
        } else {
          itemData.maximum = checkAvailability ? (availability[id]?.maxBookableNights || 0) : null;
          if(checkAvailability && itemData.maximum <= 0 ) return null;
          return (
            <Grid item xs={6} sm={4} md={3} key={typeIndex}>
              <Item
                itemID={itemData._id}
                data={itemData}
                onCountChange={onCountChange}
                multiple={multiple}
                prefilledCount={prefilledData[itemData._id]}
                selected={selectedItem[itemData._id]} />
            </Grid>
          )
        }
      })
  }

  return (
    <>
      <Grid container ref={ref}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={IMAGES.ICONS.darkBed} />
            <span className={classes.title}>{t('newReservation.selectRoomTypes.private')}</span>
          </div>
        </Grid>
        {fetchItemListView('private', IMAGES.ICONS.privateRoomType)}
      </Grid>
      <Grid container ref={ref} className={classes.rooms}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={IMAGES.ICONS.darkHostel} />
            <span className={classes.title}>{t('newReservation.selectRoomTypes.shared')}</span>
          </div>
        </Grid>
          {fetchItemListView('dorm', IMAGES.ICONS.dormRoomType)}
      </Grid>
    </>
  )
})

export default withTranslation('translation', { withRef: true })(SelectRoomTypes);