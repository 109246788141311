import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { returnCurrent } from "../../redux/selectors/dashboard";
import FrontdeskStaffList from "./FrontdeskStaffList";
import { createFrontdeskUser } from "../../redux/actions/user/frontdeskUsers";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      left: "15px",
    },
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  createSection: {
    paddingTop: theme.spacing(2),
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  contactDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  contact: {
    width: "47%",
  },
  pin: {
    marginRight: 25,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: 10,
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const Makers = (props) => {
  const { t } = props;
  const [maker, setMaker] = React.useState({
    firstName: "",
    lastName: "",
    pin: "",
    pin2: "",
  });
  const [invite, setInvite] = React.useState(false);
  const classes = useStyles();

  const handleOnChange = (e) =>
    setMaker({ ...maker, [e.target.id]: e.target.value });

  const handleClose = () => {
    setInvite(false);
    props.handleCloseStaffModal();
  };

  const handleAddUser = () => {
    if (maker.pin === maker.pin2) {
      if (props.currentSpace.propertyID) {
        // console.log('add frontdesk user', maker);
        props.dispatch(
          createFrontdeskUser(props.currentSpace._id, {
            firstName: maker.firstName,
            lastName: maker.lastName,
            loginPin: maker.pin,
            role: "frontDesk",
            propertyID: props.currentSpace.propertyID,
          })
        );
        setInvite(false);
        setMaker({ firstName: "", lastName: "", pin: "", pin2: "" });
      }
    } else {
      alert("Pin codes do not match. Please try again.");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* {!invite && (
            <Hidden smDown><Button 
              size='small' 
              onClick={() => setInvite(true)} 
              className={classes.newListButton} 
              color='primary' 
              variant='outlined'
            >
              New Frontdesk User
            </Button></Hidden>
          )} */}
          {!invite && (
            <AddIcon
              className={classes.addButton}
              onClick={() => setInvite(true)}
            />
          )}

          <Typography className={classes.header}>
            {t("frontdesk.frontdeskAccess.name")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.FRONTDESK_ACCESS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          {/* --------------------------------------------------------- */}
          {/* Add New Receptionist */}
          {/* --------------------------------------------------------- */}
          {invite && (
            <div className={classes.createSection}>
              <div className={classes.contactDetails}>
                <TextField
                  className={classes.contact}
                  margin="dense"
                  id="firstName"
                  fullWidth
                  label={t("frontdesk.frontdeskAccess.firstName")}
                  type="text"
                  onChange={handleOnChange}
                  value={maker.firstName}
                />
                <TextField
                  className={classes.contact}
                  margin="dense"
                  id="lastName"
                  label={t("frontdesk.frontdeskAccess.lastName")}
                  type="text"
                  onChange={handleOnChange}
                  value={maker.lastName}
                />
              </div>
              <TextField
                margin="dense"
                className={classes.pin}
                id="pin"
                label={t("frontdesk.frontdeskAccess.enterPin")}
                type="password"
                onChange={handleOnChange}
                value={maker.pin}
              />
              <TextField
                margin="dense"
                id="pin2"
                label={t("frontdesk.frontdeskAccess.repeatPin")}
                type="password"
                onChange={handleOnChange}
                value={maker.pin2}
              />
              <div className={classes.submit}>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={() => setInvite(false)}
                >
                  {t("actions.cancel")}
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleAddUser}
                >
                  {t("actions.submit")}
                </Button>
              </div>
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* Staff List */}
          {/* --------------------------------------------------------- */}
          <div className={classes.listSection}>
            <FrontdeskStaffList handleClose={props.handleCloseStaffModal} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
});

export default withTranslation()(connect(mapStateToProps)(Makers));
