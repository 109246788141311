import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

import { fetchSettings } from "../../redux/actions/invoice/proforma";
import {
  fetchProperty,
  resetProperty,
} from "../../redux/actions/dashboard/property";
import { setUpgradeAccount } from "../../redux/actions/dashboard/dashboard";

import AccountSettings from "./AccountSettings";
import AddSpaceModal from "./AddSpaceModal";
import SpaceListMenu from "./SpaceListMenu";
import SupportModal from "../common/SupportModal";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardIcon from "@material-ui/icons/CreditCard";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import HelpIcon from "@material-ui/icons/HelpOutline";
import NavigationIcon from "@material-ui/icons/NavigationOutlined";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import IMAGES from "../../constants/images";
import ManageSubscription from "../plus/ManageSubcription";
import { INTERCOM_PRODUCT_TOUR, HELP_PAGE_LINK } from "../../config";

const useStyles = makeStyles((theme) => ({
  spaceContainer: {
    alignItems: "center",
    borderRadius: 5,
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxWidth: 300,
    marginRight: 20,
    "&:hover": {
      background: "#E0E0E0",
      color: "#000000",
    },
    "&:active": {
      background: "#E0E0E0",
      color: "#000000",
    },
  },
  spaceName: {
    color: "#000000",
    fontSize: "1.6rem",
    fontWeight: 600,
    marginRight: 5,
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      marginLeft: 5,
    },
  },
  link: {
    cursor: "pointer",
  },
  more: {
    borderRadius: "50%",
    cursor: "pointer",
    opacity: 0.6,
    padding: 5,
  },
  button: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  profilePic: {
    borderRadius: "20px",
    width: "80px",
    height: "80px",
  },
  moreInfoTriangle: {
    bottom: 0,
    borderRadius: "20px",
    boxSizing: "border-box",
    cursor: "pointer",
    height: 80,
    opacity: 0.6,
    position: "absolute",
    right: 0,
    width: 80,
    "&:hover": {
      background: "#ffffff",
    },
  },
  settingsIcon: {
    bottom: 7,
    height: 10,
    opacity: 0.7,
    position: "absolute",
    display: "none",
    // right: '32.5px',
    right: 7,
    width: 10,
    "&:hover": {
      display: "block",
      height: 20,
      width: 20,
      right: 30,
      bottom: 30,
    },
  },
  profile: {
    alignItems: "center",
    display: "flex",
    marginTop: 15,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    borderRadius: "50%",
    height: 40,
    width: 40,
  },
  contact: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    marginBottom: 15,
    marginTop: 15,
  },
  listIcon: {
    display: "flex",
    minWidth: 30,
  },
  menu: {
    "& .MuiMenu-paper": {
      borderRadius: "5px",
      minWidth: 250,
      paddingBottom: 2,
    },
  },
  menuItem: {
    minWidth: 200,
    paddingTop: 10,
    paddingBottom: 10,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const PropertyMenu = (props) => {
  const classes = useStyles();
  const { currentSpace, currentUser, t } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountModal, setAccountModal] = React.useState(false);
  const [contactUsModal, setContactUsModal] = React.useState(false);
  const [addSpace, setAddSpace] = React.useState(false);
  const [showManagePortal, setManagePortal] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenAccountModal = () => {
    if (currentSpace.propertyID) {
      props.dispatch(fetchProperty(currentSpace.propertyID));
      props.dispatch(fetchSettings(currentSpace.propertyID));
    }
    setAnchorEl(null);
    setAccountModal(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleOpenContactUsModal = () => {
    setAnchorEl(null);
    setContactUsModal(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handlePlusModal = () => {
    setAnchorEl(null);
    props.dispatch(setUpgradeAccount(true));
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAccountModal = () => {
    setAccountModal(false);
    props.dispatch(resetProperty());
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleCloseContactUsModal = () => {
    setContactUsModal(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  const handleHelp = () => {
    setAnchorEl(null);
    window.open(HELP_PAGE_LINK, "_blank");
  };

  const getActivatePlan = () => {
    const planType =
      currentSpace && currentSpace.onboardingStatus
        ? currentSpace.onboardingStatus
        : "";
    let activePlan = { type: planType };
    switch (planType) {
      case "plus":
        activePlan.title = "Plus Plan";
        return activePlan;

      case "pro":
        activePlan.title = "Pro Plan";
        return activePlan;

      default:
        activePlan.title = "Free Plan";
        return activePlan;
    }
  };

  const handleShowManagePortal = () => {
    setManagePortal(true);
  };

  const handleCloseManagePortal = () => {
    setManagePortal(false);
  };

  const activePlan = getActivatePlan();

  return (
    <>
      <div className={classes.spaceContainer} onClick={handleClick}>
        <div className={classes.spaceName}>
          {currentSpace && currentSpace.name}
        </div>
        <DownIcon
          style={{ color: anchorEl === null ? "" : "#000000" }}
          className={classes.more}
        />
      </div>

      {currentSpace && (
        <Menu
          id="property-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          className={classes.menu}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          disableEnforceFocus={true}
        >
          <div className={classes.profile}>
            <ListItemIcon>
              <img
                src={
                  !currentSpace.profilePic ||
                  currentSpace.profilePic.includes("undefined")
                    ? IMAGES.ICONS.hostel
                    : currentSpace.profilePic
                }
                className={classes.icon}
              />
            </ListItemIcon>
            <div className={classes.contact}>
              <Typography
                variant="body2"
                style={{ marginBottom: 5, fontWeight: 600 }}
              >
                {currentSpace.name}
              </Typography>
              <Typography
                variant="body2"
                className={classes.link}
                onClick={() => props.dispatch(setUpgradeAccount(true))}
              >
                {activePlan.title}
              </Typography>
            </div>
          </div>

          <Divider className={classes.divider} />

          <MenuItem disableRipple className={classes.menuItem}>
            <SpaceListMenu
              closeParent={handleClose}
              handleCreateProperty={handleOpenContactUsModal}
              handleAddSpace={() => setAddSpace(true)}
            />
          </MenuItem>

          {/* ------------------------ACCOUNT SETTINGS ITEM------------------------- */}
          <MenuItem
            className={classes.menuItem}
            onClick={handleOpenAccountModal}
          >
            <ListItemIcon className={classes.listIcon}>
              <img src={IMAGES.ICONS.settings} height="18" width="18" />
            </ListItemIcon>
            <Typography variant="body2">
              {t("navbar.accountSettings")}
            </Typography>
          </MenuItem>

          {/* -----------------------UPGRADE ONBOARDING STATUS ITEM------------------------- */}
          {activePlan.type != "plus" && activePlan.type != "pro" && (
            <MenuItem className={classes.menuItem} onClick={handlePlusModal}>
              <ListItemIcon className={classes.listIcon}>
                <CardIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2">{t("navbar.upgrade")}</Typography>
            </MenuItem>
          )}

          {/* {activePlan.type == 'plus' && (
          <MenuItem className={classes.menuItem} onClick={handlePlusModal}>
            <ListItemIcon className={classes.listIcon}>
              <CardIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">{t('navbar.upgrade2')}</Typography>
          </MenuItem>
        )} */}

          {/* -----------------------MANAGE SUBCRIPTION ITEM------------------------- */}
          {["plus", "pro"].includes(activePlan.type) && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleShowManagePortal}
            >
              <ListItemIcon className={classes.listIcon}>
                <CardIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2">{t("navbar.billing")}</Typography>
            </MenuItem>
          )}

          <Divider className={classes.divider} />

          {/* -----------------------HELP CENTER LINK ITEM------------------------- */}
          <MenuItem className={classes.menuItem} onClick={handleHelp}>
            <ListItemIcon className={classes.listIcon}>
              <HelpIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">{t("navbar.helpCenter")}</Typography>
          </MenuItem>
        </Menu>
      )}

      {/*  Account Modal */}
      {/* --------------------------------------------- */}
      {accountModal && (
        <AccountSettings
          open={accountModal}
          handleClose={handleCloseAccountModal}
        />
      )}

      {/*  Contact Us Modal */}
      {/* --------------------------------------------- */}
      {contactUsModal && (
        <SupportModal
          open={contactUsModal}
          handleClose={handleCloseContactUsModal}
        />
      )}

      {/*  Add Space */}
      {/* --------------------------------------------- */}
      {currentUser.isSystemAdmin && addSpace && (
        <AddSpaceModal open={addSpace} handleClose={() => setAddSpace(false)} />
      )}

      {/* MANAGE SUBSCRIPTIONS (SELF_SERVE PORTAL) */}
      {showManagePortal && (
        <ManageSubscription closeManagePortal={handleCloseManagePortal} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default withTranslation()(connect(mapStateToProps)(PropertyMenu));
