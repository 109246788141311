export const tempData = {
    "costByChannel":[
       {
          "commission":93.15,
          "accommodationAmount":620.98,
          "resvCount":11,
          "actualAccommAmount":648,
          "commissionPercentage":0.15000483107346452,
          "promoCost":27.019999999999982,
          "promoCostPercent":0.0416975308641975,
          "promoAndCommissionCost":120.16999999999999,
          "promoAndCommissionCostPercent":0.18544753086419752,
          "bookingSource":"HostelWorld"
       },
       {
          "commission":0,
          "accommodationAmount":30.6,
          "resvCount":1,
          "actualAccommAmount":36,
          "commissionPercentage":0,
          "promoCost":5.399999999999999,
          "promoCostPercent":0.14999999999999997,
          "promoAndCommissionCost":5.399999999999999,
          "promoAndCommissionCostPercent":0.14999999999999997,
          "bookingSource":"Booking.com"
       },
       {
          "commission":434.29,
          "accommodationAmount":2895.4,
          "resvCount":43,
          "actualAccommAmount":3447,
          "commissionPercentage":0.1499930924915383,
          "promoCost":551.5999999999999,
          "promoCostPercent":0.16002320858717722,
          "promoAndCommissionCost":985.8899999999999,
          "promoAndCommissionCostPercent":0.28601392515230634,
          "bookingSource":"BookingCom"
       },
       {
          "commission":0,
          "accommodationAmount":94.03,
          "resvCount":1,
          "actualAccommAmount":126,
          "commissionPercentage":0,
          "promoCost":31.97,
          "promoCostPercent":0.2537301587301587,
          "promoAndCommissionCost":31.97,
          "promoAndCommissionCostPercent":0.2537301587301587,
          "bookingSource":"Airbnb"
       },
       {
        "commission":0,
        "accommodationAmount":94.03,
        "resvCount":1,
        "actualAccommAmount":126,
        "commissionPercentage":0,
        "promoCost":31.97,
        "promoCostPercent":0.2537301587301587,
        "promoAndCommissionCost":31.97,
        "promoAndCommissionCostPercent":0.2537301587301587,
        "bookingSource":"myAllocator"
     }
    ],
    "resvList":[
       {
          "actualAccommAmount":104,
          "reservationID":"5fa32d82a71e345e8a5684ce",
          "guestName":"Franziska Heinritz",
          "checkIn":"2020-11-06T00:00:00.000Z",
          "checkOut":"0011-11-08T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":11.93,
          "accommodationAmount":79.54
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fafb3317539940ee56353dd",
          "guestName":"Adolfo Benito Gonzalez",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":7.29,
          "accommodationAmount":48.6
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fa2ef64c6758c2d1537bfef",
          "guestName":"Magnus Magnus Simonsson",
          "checkIn":"2020-12-23T00:00:00.000Z",
          "checkOut":"0012-12-25T00:00:00.000Z",
          "bookingSource":"Booking.com",
          "commission":0,
          "accommodationAmount":30.6
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fa2b5fe7a02c339d8ffd24c",
          "guestName":"Abies dalmulder",
          "checkIn":"2020-11-07T00:00:00.000Z",
          "checkOut":"0011-11-09T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":5.4,
          "accommodationAmount":36
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fb302587539940ee565d8c5",
          "guestName":"Kamyar Khamoush",
          "checkIn":"2020-11-18T00:00:00.000Z",
          "checkOut":"0011-11-19T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.43,
          "accommodationAmount":16.2
       },
       {
          "actualAccommAmount":40,
          "reservationID":"5faf1b4a5436721848684498",
          "guestName":"Thomas Krzemien",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-17T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.4,
          "accommodationAmount":36
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fa9cfca29511c617e65d49e",
          "guestName":"Vera Kankalovich",
          "checkIn":"2020-11-28T00:00:00.000Z",
          "checkOut":"0011-11-30T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":4.59,
          "accommodationAmount":30.6
       },
       {
          "actualAccommAmount":25,
          "reservationID":"5fafeab67539940ee56359a7",
          "guestName":"Emeric Robert",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-16T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":3,
          "accommodationAmount":20
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fa6ff31eff7be29b705f0ae",
          "guestName":"Adolfo Benito Gonzalez",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.83,
          "accommodationAmount":38.88
       },
       {
          "actualAccommAmount":20,
          "reservationID":"5fafe8777539940ee563595f",
          "guestName":"A G",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-16T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.7,
          "accommodationAmount":18
       },
       {
          "actualAccommAmount":60,
          "reservationID":"5faf61b23a01e60ee6ad67b8",
          "guestName":"Kornrl Rozsa",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":8.1,
          "accommodationAmount":54
       },
       {
          "actualAccommAmount":126,
          "reservationID":"5fa72585eff7be29b705f5f6",
          "guestName":"jimmy adamo",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":13.61,
          "accommodationAmount":90.72
       },
       {
          "actualAccommAmount":17,
          "reservationID":"5fa2969627549c24cc46a8c5",
          "guestName":"Zsanett Horváth",
          "checkIn":"2020-11-05T00:00:00.000Z",
          "checkOut":"0011-11-06T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":2.55,
          "accommodationAmount":17
       },
       {
          "actualAccommAmount":108,
          "reservationID":"5fab97e531c095336ad134af",
          "guestName":"Diane Beyer",
          "checkIn":"2020-12-21T00:00:00.000Z",
          "checkOut":"0012-12-27T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":11.66,
          "accommodationAmount":77.76
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fa9d5ca29511c617e65d63d",
          "guestName":"Carina Zimmermann",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":6.56,
          "accommodationAmount":43.74
       },
       {
          "actualAccommAmount":40,
          "reservationID":"5faf93c27539940ee5635099",
          "guestName":"Christian Schoder",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-17T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":6,
          "accommodationAmount":40
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5faf84ec7539940ee5634ef5",
          "guestName":"Anna Tadini",
          "checkIn":"2020-11-22T00:00:00.000Z",
          "checkOut":"0011-11-25T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":7.29,
          "accommodationAmount":48.6
       },
       {
          "actualAccommAmount":90,
          "reservationID":"5fb24aa63a01e60ee6afbde1",
          "guestName":"Augusto Pellizza",
          "checkIn":"2020-11-18T00:00:00.000Z",
          "checkOut":"0011-11-23T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":12.15,
          "accommodationAmount":81
       },
       {
          "actualAccommAmount":500,
          "reservationID":"5fabac59d87b3739dd52b0d6",
          "guestName":"Clémence Goulesque",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-24T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":63.75,
          "accommodationAmount":425
       },
       {
          "actualAccommAmount":50,
          "reservationID":"5fafef5f3a01e60ee6ad7cca",
          "guestName":"cyril landrieau",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-16T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.4,
          "accommodationAmount":36
       },
       {
          "actualAccommAmount":80,
          "reservationID":"5fabd1526707fc41e2b86031",
          "guestName":"Alberto Martín Juan",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-23T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":10.8,
          "accommodationAmount":72
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fb131387539940ee5637df5",
          "guestName":"Olmo Moner lasheras",
          "checkIn":"2020-12-13T00:00:00.000Z",
          "checkOut":"0012-12-16T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":7.29,
          "accommodationAmount":48.6
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5faaad4d3106e509826a4414",
          "guestName":"Esther Lopez Trillo",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.83,
          "accommodationAmount":38.88
       },
       {
          "actualAccommAmount":66,
          "reservationID":"5fb110dc7539940ee5637a3d",
          "guestName":"Emma Friend",
          "checkIn":"2020-11-17T00:00:00.000Z",
          "checkOut":"0011-11-20T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":9.21,
          "accommodationAmount":61.38
       },
       {
          "actualAccommAmount":44,
          "reservationID":"5fa5c8e46c32bc04db011128",
          "guestName":"Florian Caumont",
          "checkIn":"2020-11-06T00:00:00.000Z",
          "checkOut":"0011-11-07T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.05,
          "accommodationAmount":33.66
       },
       {
          "actualAccommAmount":90,
          "reservationID":"5fabc7696707fc41e2b85f3d",
          "guestName":"Raul Bravo de las Heras",
          "checkIn":"2020-11-30T00:00:00.000Z",
          "checkOut":"0012-12-05T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":9.72,
          "accommodationAmount":64.8
       },
       {
          "actualAccommAmount":108,
          "reservationID":"5fb015827539940ee5635eaa",
          "guestName":"Xavi Mas Flores",
          "checkIn":"2020-11-24T00:00:00.000Z",
          "checkOut":"0011-11-30T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":14.58,
          "accommodationAmount":97.2
       },
       {
          "actualAccommAmount":72,
          "reservationID":"5fa2f2efa71e345e8a567cdc",
          "guestName":"Amir Heshmati",
          "checkIn":"2020-11-07T00:00:00.000Z",
          "checkOut":"0011-11-11T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":10.04,
          "accommodationAmount":66.96
       },
       {
          "actualAccommAmount":75,
          "reservationID":"5fad0b45c8785f15935294a0",
          "guestName":"SEBASTIAN ESCORIAZA",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":8.1,
          "accommodationAmount":54
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fa9cdbb29511c617e65d44e",
          "guestName":"Vera Kankalovich",
          "checkIn":"2020-11-28T00:00:00.000Z",
          "checkOut":"0011-11-29T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.29,
          "accommodationAmount":15.3
       },
       {
          "actualAccommAmount":44,
          "reservationID":"5fb3072a3a01e60ee6aff613",
          "guestName":"Kamyar Khamoush",
          "checkIn":"2020-11-20T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.94,
          "accommodationAmount":39.6
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fb1774a3a01e60ee6ad9fc8",
          "guestName":"Irene Cerezo Martienez",
          "checkIn":"2020-11-23T00:00:00.000Z",
          "checkOut":"0011-11-26T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":7.29,
          "accommodationAmount":48.6
       },
       {
          "actualAccommAmount":126,
          "reservationID":"5fa37c098c54f730c05466e5",
          "guestName":"Catherine Guay",
          "checkIn":"2020-11-11T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":13.61,
          "accommodationAmount":90.72
       },
       {
          "actualAccommAmount":88,
          "reservationID":"5fb254a87539940ee565a09f",
          "guestName":"Olmo Moner lasheras",
          "checkIn":"2020-11-17T00:00:00.000Z",
          "checkOut":"0011-11-21T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":11.88,
          "accommodationAmount":79.2
       },
       {
          "actualAccommAmount":108,
          "reservationID":"5fae4d093a2cd23b53babe33",
          "guestName":"Alizé Jonckheere",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-21T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":15.07,
          "accommodationAmount":100.44
       },
       {
          "actualAccommAmount":200,
          "reservationID":"5fa94f2cf85b7e390054a280",
          "guestName":"Barbara Crespi",
          "checkIn":"2020-12-03T00:00:00.000Z",
          "checkOut":"0012-12-13T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":30,
          "accommodationAmount":200
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fa349a5a71e345e8a56884c",
          "guestName":"Luca Rambaldi",
          "checkIn":"2020-11-07T00:00:00.000Z",
          "checkOut":"0011-11-09T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":3.89,
          "accommodationAmount":25.92
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fa34ad2a71e345e8a568878",
          "guestName":"Luca Rambaldi",
          "checkIn":"2020-11-06T00:00:00.000Z",
          "checkOut":"0011-11-07T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":1.94,
          "accommodationAmount":12.96
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fa70ce9eff7be29b705f2f3",
          "guestName":"Thib Margerie",
          "checkIn":"2020-11-08T00:00:00.000Z",
          "checkOut":"0011-11-09T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.19,
          "accommodationAmount":14.58
       },
       {
          "actualAccommAmount":126,
          "reservationID":"5facf351c8785f15935291ee",
          "guestName":"Ceylan Ahmeti",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"Airbnb",
          "commission":0,
          "accommodationAmount":94.03
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fb001747539940ee5635c89",
          "guestName":"Eelco van de wiel",
          "checkIn":"2020-11-20T00:00:00.000Z",
          "checkOut":"0011-11-21T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":2.7,
          "accommodationAmount":18
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fb1a0017539940ee5639114",
          "guestName":"Carl Olerud",
          "checkIn":"2020-11-18T00:00:00.000Z",
          "checkOut":"0011-11-20T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":4.86,
          "accommodationAmount":32.4
       },
       {
          "actualAccommAmount":22,
          "reservationID":"5fb1cdeb7539940ee5639612",
          "guestName":"Thib Margerie Nets-z",
          "checkIn":"2020-11-16T00:00:00.000Z",
          "checkOut":"0011-11-17T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.97,
          "accommodationAmount":19.8
       },
       {
          "actualAccommAmount":144,
          "reservationID":"5fab988a31c095336ad134da",
          "guestName":"Diane Beyer",
          "checkIn":"2020-12-27T00:00:00.000Z",
          "checkOut":"0001-01-04T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":15.55,
          "accommodationAmount":103.68
       },
       {
          "actualAccommAmount":140,
          "reservationID":"5faa98a44d0b161bea751b03",
          "guestName":"Daniel Mannherz",
          "checkIn":"2020-11-16T00:00:00.000Z",
          "checkOut":"0011-11-23T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":19.53,
          "accommodationAmount":130.2
       },
       {
          "actualAccommAmount":36,
          "reservationID":"5fae5ef24ce44a438bf4ac36",
          "guestName":"Carl Olerud",
          "checkIn":"2020-11-16T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":4.86,
          "accommodationAmount":32.4
       },
       {
          "actualAccommAmount":44,
          "reservationID":"5fb126493a01e60ee6ad9b87",
          "guestName":"Kornel Rozsa",
          "checkIn":"2020-11-18T00:00:00.000Z",
          "checkOut":"0011-11-20T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":5.94,
          "accommodationAmount":39.6
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fafe20b7539940ee56358a3",
          "guestName":"Carlos Zelaya",
          "checkIn":"2020-11-21T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":2.7,
          "accommodationAmount":18
       },
       {
          "actualAccommAmount":25,
          "reservationID":"5fb10d1a7539940ee56379bf",
          "guestName":"Lisa Lehnhoff Destarac",
          "checkIn":"2020-11-16T00:00:00.000Z",
          "checkOut":"0011-11-17T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":3.75,
          "accommodationAmount":25
       },
       {
          "actualAccommAmount":75,
          "reservationID":"5fb1744a3a01e60ee6ad9f89",
          "guestName":"Dan Johnson",
          "checkIn":"2020-11-17T00:00:00.000Z",
          "checkOut":"0011-11-20T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":10.12,
          "accommodationAmount":67.5
       },
       {
          "actualAccommAmount":54,
          "reservationID":"5fa9a37b29511c617e65c5de",
          "guestName":"Kamyar Khamoush",
          "checkIn":"2020-11-15T00:00:00.000Z",
          "checkOut":"0011-11-18T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":6.2,
          "accommodationAmount":41.31
       },
       {
          "actualAccommAmount":126,
          "reservationID":"5fad33a6c8785f1593529fe4",
          "guestName":"mathiss dewilde",
          "checkIn":"2020-11-16T00:00:00.000Z",
          "checkOut":"0011-11-23T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":18.9,
          "accommodationAmount":126
       },
       {
          "actualAccommAmount":17,
          "reservationID":"5fa56d4b337c0b708be24dbf",
          "guestName":"Antoine Jacquet",
          "checkIn":"2020-11-06T00:00:00.000Z",
          "checkOut":"0011-11-07T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.29,
          "accommodationAmount":15.3
       },
       {
          "actualAccommAmount":375,
          "reservationID":"5fac1ad87ad29803695959f8",
          "guestName":"Clémence Goulesque",
          "checkIn":"2020-11-19T00:00:00.000Z",
          "checkOut":"0011-11-24T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":47.81,
          "accommodationAmount":318.75
       },
       {
          "actualAccommAmount":108,
          "reservationID":"5fafb0d27539940ee563536d",
          "guestName":"Annasiria Palumbo",
          "checkIn":"2020-12-30T00:00:00.000Z",
          "checkOut":"0001-01-01T00:00:00.000Z",
          "bookingSource":"HostelWorld",
          "commission":16.2,
          "accommodationAmount":108
       },
       {
          "actualAccommAmount":18,
          "reservationID":"5fafea0d3a01e60ee6ad7c7b",
          "guestName":"Emerson Antonio Martinez Lopez",
          "checkIn":"2020-11-21T00:00:00.000Z",
          "checkOut":"0011-11-22T00:00:00.000Z",
          "bookingSource":"BookingCom",
          "commission":2.7,
          "accommodationAmount":18
       }
    ]
 }