import React, { forwardRef, useImperativeHandle } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import GuestForm from '../GuestForm';
// import { ICONS } from '../../../utils/imageUrls';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    margin: '40px 0 20px'
  },
  icon: {
    width: 30,
    height: 30,
    padding: 10,
    borderRadius: '50%',
    marginRight: 10,
    boxShadow: '0 0 5px 5px #f5f5f5',
  },
  label: {
    fontWeight: 600,
    fontSize: '1.4rem',
  },
}));

const GuestList = forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentSpace, guestCount, t, setDrawerState } = props;
  const [totalGuest, setTotalGuest] = React.useState(guestCount);
  const [guests, setGuests] = React.useState({});

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return {
        customers: Object.keys(guests).map(key => {
          if(guests[key].firstName && guests[key].lastName) return guests[key];
        }).filter(guest => guest), 
        totalGuest: totalGuest
      }
    }
  }))

  const toggleMasterbooker = (value) => {
    if (value) setTotalGuest(1)
    else setTotalGuest(guestCount)
  }

  const handleGuestInfo = (count, data) => {
    let updatedGuest = {...guests};
    updatedGuest[count] = data;
    setGuests(updatedGuest);
  }

  return (<>
    {[...Array(totalGuest)].map((x, i) => (
      <React.Fragment key={i}>
        <div className={classes.section}>
          <img className={classes.icon} src={IMAGES.ICONS.darkBoyBroadSmile} />
          <div className={classes.label}>{t('newReservation.guest.guestNumber')} {i + 1}</div>
        </div>
        <GuestForm
          currentCount={i}
          open={true}
          mode={"add"}
          new={true}
          masterBookerView={guestCount > 1 && i === 0 && (props.viewMasterBooker || false)}
          showLess={props.showLess || false}
          timezone={currentSpace.timezone}
          toggleMasterbooker={toggleMasterbooker}
          handleGuestInfo={handleGuestInfo}
          setDrawerState={setDrawerState}
        />
      </React.Fragment>
    )
    )}
  </>)
})

export default withTranslation('translation', { withRef: true })(GuestList);