export default (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_HW_MIGRATION_INFO_SUCCESS":
            return {
                ...state,
                ...action.payload
            };

        case "SAVE_USER_PROFILE_LANGUAGE_SUCCESS": 
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    userUIsettings: [ 
                        { ...action.payload }
                    ]
                }
            }
        
        case "RESET_HW_MIGRATION_DATA":
            return { }

        default:
            return state;
    }
};