import http from '../../utils/http';
//----------------------------------------------------------------
// ADD PRINTER
//----------------------------------------------------------------
export function addPrinter(body, spaceID) {
  const ACTION_NAME = "ADD_PRINTER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/settings/printer`, body)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data, spaceID } });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function fetchPrinters(spaceID) {
  const ACTION_NAME = "FETCH_PRINTERS";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/settings/printer/${spaceID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function resetPrinters() {
  const ACTION_NAME = "RESET_PRINTERS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}` });
  }
}