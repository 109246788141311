import http from '../../utils/http';

//----------------------------------------------------------------
// EDIT SPACE
//----------------------------------------------------------------
export function editSpace(spaceID, space) {
  const ACTION_NAME = "EDIT_SPACE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/space/${spaceID}`, { ...space })
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}