/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/self-closing-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

import Sidebar from "./sidebar";
import ContentRenderer from "./ContentRenderer";

import { sidebarMenu } from "./constant";
import { getNextStepInfo, getPrevStepInfo } from "./roomHelper";
import {
  editRoom,
  createRoom,
  deleteRoom,
  uploadRoomPhoto,
} from "../../../redux/actions/rooms/rooms";
import { resetError } from "../../../redux/actions/common/common";

const styles = (theme) => ({
  dialog: {
    position: "relative",
  },
  container: {
    display: "flex",
    height: "100%",
    position: "relative",
  },
  sidebarContainer: {
    backgroundColor: "#F8F9FB",
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 16,
    zIndex: 99,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
});

class NewRoom extends Component {
  constructor(props) {
    super(props);
    const defaultMainStepID = props?.menuId || Object.keys(sidebarMenu)[0];
    this.state = {
      activeMenuID: defaultMainStepID,
      video: false,
      room: {
        primaryRoomTypeID: "",
        name: "",
      },
    };

    this.roomTypeVisibility = [];
  }

  componentDidMount() {
    if (this.props.roomID) {
      this.setState({ ...this.state, room: this.props.room });
    }
  }

  handleUpdateRoom = (key, value) => {
    this.setState({
      ...this.state,
      room: { ...this.state.room, [key]: value },
    });
  };

  handleUpdateMultipleRoom = (multipleFields) => {
    this.setState({
      ...this.state,
      room: { ...this.state.room, ...multipleFields },
    });
  };

  navigateToMenuHandler = (menuID) => {
    this.setState({ activeMenuID: menuID });
  };

  nextStepHandler = () => {
    const { activeMenuID } = this.state;
    const nextStepInfo = getNextStepInfo(activeMenuID);

    if (nextStepInfo) this.navigateToMenuHandler(nextStepInfo.menuID);
  };

  prevStepHandler = () => {
    const { activeMenuID } = this.state;
    const prevStepInfo = getPrevStepInfo(activeMenuID);

    if (prevStepInfo) this.navigateToMenuHandler(prevStepInfo.menuID);
  };

  processMapping = (room) => {
    const currentRoomData = [...room.mapping];
    const finalMapping = [];
    currentRoomData.forEach((item) => {
      finalMapping.push({
        startDate: item.startDate,
        endDate: item.endDate,
        roomTypeID: item.roomTypeID,
        visible: true,
      });
    });
    return { ...room, mapping: finalMapping };
  };

  updateRoomTypeVisibility = (data) => {
    this.roomTypeVisibility = [...data];
  };

  submitHandler = () => {
    const payload = this.processMapping(this.state.room);
    const file = this.state?.room?.imageFile;
    if (this.state.room._id) {
      const roomInfo = { ...this.state.room };
      const newMapping = roomInfo.mapping.filter((mapped) => mapped.new) || [];

      // created this object as on edit the room state has the whole room data where on edit some of the keys are not allowed
      const roomPayload = {
        name: roomInfo.name,
        roomTypeID: roomInfo.roomTypeID,
        buildingID: roomInfo.buildingID,
        description: roomInfo.description,
        primaryRoomTypeID: roomInfo.primaryRoomTypeID,
        advancedMapping: roomInfo.advancedMapping,
        floor: roomInfo.floor,
        newMapping,
      };

      if (this.roomTypeVisibility.length > 0) {
        roomPayload.visibility = [...this.roomTypeVisibility];
      }

      const response = this.props.dispatch(
        editRoom(this.props.roomID, { ...roomPayload })
      );
      response.then((response) => {
        if (response && file) {
          this.imageUploadHandler();
        } else this.props.handleCancel();
      });
    } else {
      const response = this.props.dispatch(
        createRoom(this.props.currentSpace._id, {
          ...payload,
          propertyID: this.props.currentSpace.propertyID,
        })
      );
      response.then((response) => {
        if (response && file) {
          this.imageUploadHandler();
        } else this.props.handleCancel();
      });
    }
  };

  getFileData = (data) => {
    const imageFile = data.imageFile;
    const fileParts = imageFile.name.split(".");
    const fileName = fileParts[0];
    const fileType = fileParts[1];
    return { imageFile, fileName, fileType };
  };

  imageUploadHandler = () => {
    const { imageFile, fileName, fileType } = this.getFileData(this.state.room);

    const [currentRoom] = Object.values(this.props.rooms).filter(
      (room) => room.name === this.state.room.name
    );

    const uploadData = {
      file: imageFile,
      type: fileType,
      name: fileName,
      roomID: currentRoom._id,
      propID: this.props.currentSpace.propertyID,
    };
    const response = this.props.dispatch(uploadRoomPhoto(uploadData));
    response.then(() => {
      this.props.handleCancel();
    });
  };

  handleDeleteRoom = (unallocate) => {
    const response = this.props.dispatch(
      deleteRoom(this.props.currentSpace._id, this.state.room._id, {
        propertyID: this.props.currentSpace.propertyID,
        roomID: this.state.room._id,
        unallocate,
      })
    );
    response.then((response) => {
      if (response) {
        this.props.handleCancel();
      }
    });
  };

  handleCloseError = () => {
    const { dispatch } = this.props;
    dispatch(resetError("CREATE_ROOM"));
    dispatch(resetError("EDIT_ROOM"));
    dispatch(resetError("DELETE_ROOM"));
    dispatch(resetError("UPLOAD_ROOM_PHOTO"));
    dispatch(resetError("DELETE_ROOMS_MAPPING"));
  };

  render() {
    const { classes, closeModalHandler, currentSpace, isLoading, errors } =
      this.props;

    const { activeMenuID } = this.state;

    const contentProps = {
      activeStepInfo: {
        menuID: activeMenuID,
      },
      currency: currentSpace.currency,
      nextStepHandler: this.nextStepHandler,
      prevStepHandler: this.prevStepHandler,
      room: this.state.room,
      handleUpdateRoom: this.handleUpdateRoom,
      handleUpdateMultipleRoom: this.handleUpdateMultipleRoom,
      submitHandler: this.submitHandler,
      handleCancel: this.props.handleCancel,
      updateRoomTypeVisibility: this.updateRoomTypeVisibility,
    };

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleCancel}
        className={classes.dialog}
        fullScreen={true}
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={this.props.handleCancel}
        />

        <Grid container className={classes.container}>
          {/* ----------------PROGRESS LOADER--------------------- */}
          {isLoading && (
            <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
            </div>
          )}

          <Grid item className={classes.sidebarContainer} sm={3} lg={2} xs={12}>
            <Sidebar
              activeMenuID={activeMenuID}
              navigateToMenuHandler={this.navigateToMenuHandler}
              closeModalHandler={closeModalHandler}
              room={this.state.room}
              handleDeleteRoom={this.handleDeleteRoom}
              handleToggleDemo={() => this.setState({ video: true })}
            />
          </Grid>

          <Grid item sm={9} lg={10} xs={12}>
            <ContentRenderer
              activeMenuID={activeMenuID}
              contentProps={contentProps}
            />
          </Grid>
        </Grid>

        {/* ----------------DEMO VIDEO--------------------- */}

        <Dialog
          open={this.state.video}
          maxWidth="lg"
          onClose={() => this.setState({ video: false })}
        >
          <iframe
            width="800"
            height="488"
            src="https://www.loom.com/embed/4398accbb78e4641b7ee9c0e5809f47a"
          ></iframe>
        </Dialog>

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={this.handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, rooms, dashboard, loading, errors, success } = state;

  return {
    currentSpace: spaces[dashboard.currentSpace] || {},
    rooms,

    isLoading:
      loading.CREATE_ROOM ||
      loading.EDIT_ROOM ||
      loading.UPLOAD_ROOM_PHOTO ||
      loading.DELETE_ROOM ||
      loading.DELETE_ROOMS_MAPPING,

    errors:
      errors.CREATE_ROOM ||
      errors.EDIT_ROOM ||
      errors.UPLOAD_ROOM_PHOTO ||
      errors.DELETE_ROOM ||
      errors.DELETE_ROOMS_MAPPING,

    success: success.CREATE_ROOM,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(NewRoom));
