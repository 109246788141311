import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import TermsAndConditions from '../../signupCommunity/TermsAndConditions';
import PrivacyPolicy from '../../signupCommunity/PrivacyPolicy';

import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    width: 400,
  },
  header: {
    fontSize: '1.8rem',
    fontWeight: 600,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10,
  },
  subTitle: {
    color: '#000000',
    fontSize: '1.3rem',
  },
  field: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    display: 'flex',
    height: 35,
    marginBottom: 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  legal: {
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    background: '#CC6633',
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    padding: '15px 20px',
  },
  errorText: {
    color: 'red',
    fontSize: '1.2rem',
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

const Signup = (props) => {

  const classes = useStyles();
  const [user, setUser] = React.useState({email: props.userEmail});
  const [error, setError] = React.useState(({name: false, passwordFormat: false, passwordEntry: false, terms: false}));
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPolicy, setOpenPolicy] = React.useState(false);

  const handleUpdate = (e) => {
    setUser({...user, [e.target.id]: e.target.value})
    if (e.target.value !== '') {
      if (e.target.id==='name') setError({ ...error, [e.target.id]: false })
      if (e.target.id==='password') setError({...error, passwordFormat: false, passwordEntry: false})
    }
  }

  const handleToggle = (e) => {
    if (e.target.checked) {
      setUser({...user, terms: 1})
      setError({...error, terms: false})
    } else {
      setUser({...user, terms: false})
    }
  }

  const handleOpenPolicy = () => {
    setOpenPolicy(true);
  }
  const handleClosePolicy = () => {
    setOpenPolicy(false);
  }
  const handleOpenTerms = () => {
    setOpenTerms(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }
  const handleCloseTerms = () => {
    setOpenTerms(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }
  const handleAcceptTerms = () => {
    setOpenTerms(false);
    setUser({...user, terms: 1});
    setError({...error, terms: false});
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }
  

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (!user.name) {
      errorStep.name = true;
      foundError = true;
    }
    if (!user.password) {
      errorStep.passwordEntry = true;
      foundError = true;
    }
    if (!user.password) {
      errorStep.passwordLength = true;
      foundError = true;
    } else if (!user.password.match(passw)) {
      errorStep.passwordFormat = true;
      foundError = true
    }
    if (!user.terms) {
      errorStep.terms = true
      foundError = true
    }
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleNext = () => {
    const check = handleValidate();
    if (check) {
      props.handleSubmit(user);
    }
  }

  return(
    <>
      <div className={classes.row}>
        <div className={classes.header}>Create an Account</div>
      </div>

      {/* Email (defaulted) */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Email</div>
        <div className={classes.subTitle}>
          {props.userEmail}
        </div>
      </div>

      {/* Enter Name */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Enter Name</div>
        <div className={classes.field} style={{border: error.name ? '1px solid red' : '1px solid #ffffff'}}>
          <InputBase 
            id="name"
            name="name"
            fullWidth
            type="text"
            onChange={handleUpdate}
          />
        </div>
        {error.name && <div className={classes.errorText}>Please enter your name to proceed</div>}
      </div>


      {/* Password */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.title}>Create Password</div>
        <div className={classes.field} style={{border: (error.passwordEntry || error.passwordFormat) ? '1px solid red' : '1px solid #ffffff'}}>
          <InputBase 
            id="password"
            name="password"
            fullWidth
            type="password"
            onChange={handleUpdate}
          />
        </div>
        {error.passwordEntry && <div className={classes.errorText}>Please enter a password to proceed</div>}
        {error.passwordFormat && <div className={classes.errorText}>Minimum 8 characters</div>}
        {error.passwordFormat && <div className={classes.errorText}>One uppercase and one lowercase letter</div>}
        {error.passwordFormat && <div className={classes.errorText}>At least 1 number</div>}
      </div>

      <div className={classes.row}>
        <div className={classes.legal}>
          <Checkbox color="primary" checked={Boolean(user.terms)} onClick={handleToggle} />
          <Typography variant="body2">
            Creating an account means you accept our <span className={classes.link} onClick={handleOpenTerms}>Terms of Service</span> 
            &nbsp;and <span className={classes.link} onClick={handleOpenPolicy}>Privacy Policy</span>
          </Typography>
        </div>
        {error.terms && <div className={classes.errorText}>Please accept terms and conditions</div>}
      </div>

      {openTerms && <TermsAndConditions open={openTerms} handleClose={handleCloseTerms} handleAccept={handleAcceptTerms} />}
      {openPolicy && <PrivacyPolicy open={openPolicy} handleClose={handleClosePolicy} />}
      
      {/* Next Button */}
      {/* --------------------------------------------- */}
      <div className={classes.row}>
        <div className={classes.button} onClick={handleNext}>Next</div>
      </div>
    </>
  )
}

export default Signup;