import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { patchMasterwayConfig } from '../../redux/actions/masterway/masterway';
import { capitalize } from 'lodash';

const useStyles = makeStyles(theme =>({
    root: {
        maxWidth: "600px",
        margin: "0 auto",
    },
    footer: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: "15px 30px",
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#fff',
        height: "41px",
    },
    button: {
        background: '#000',
        color: '#fff',
        width: 100,
        height: 40,
        fontWeight: 700,
        borderRadius: 25,
        '&:hover': {
          background: '#000'
        },
        '&.Mui-disabled':{
            color: "#fff !important",
            opacity: 0.4,
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        }
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: "20px",
        '& .icon': {
            fontSize: "2rem",
        }
    },
    fieldContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '30px',

        '& .error': {
            border: '1px solid #f44336;'
        },

        '& .errorAstrix': {
            color: '#f44336'
        },

        [theme.breakpoints.down('xs')]: {
            alignItems: 'unset',
            flexDirection: 'column',
            '& $field, $fieldValue': {
                marginTop: '10px'
            }
        }
    },
    label: {
        fontSize: "1.3rem",
        minWidth: "220px",
        margin: "unset",
    },
    field: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 1px 10px #E0E0E0',
        padding: "3px 15px",
        width: '100%',
        boxSizing: "border-box",
    },
    fieldValue: {
        margin: "unset",
        fontWeight: 600,
        fontSize: "1.3rem",
    },
    mainLabel: {
        fontWeight: 600,
        fontSize: "1.2rem",
        marginBottom: "20px",
    },
    mappingTable: {
        width: "100%",
        borderRadius: "5px",
        borderCollapse: "collapse",
        tableLayout: "fixed",
        marginBottom: '30px',

        '& td, th': {
            border: "1px solid #dddddd",
            textAlign: "left",
            padding: "8px",
            fontSize: "1.3rem",
        },
        '& th': {
            color: "#666666",
            fontSize: "1.1rem",
            backgroundColor: "#f6f2ee",
        },
        [theme.breakpoints.down('xs')]: {
            tableLayout: 'unset'
        }
    },
    fieldGroup: {
        borderTop: "1px solid #e0e0e0",
        borderBottom: "1px solid #e0e0e0",
        marginBottom: "30px",
        '& $fieldContainer:last-child': {
            marginBottom: '10px'
        }
    },
    tableCell: {
        '& p': { margin: 'unset' },
        '& .primary': { marginBottom: '5px'},
        '& .secondary': {
            fontSize: "1.1rem",
            color: "#666666",
        }
    }
}));

const selectStyle = {
    control: base => ({
        ...base,
        border: 0,
        boxShadow: 'none',
        width: '100%',
        minWidth: '160px',
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }),
    valueContainer: base =>({
        ...base,
        padding: '2px 0px'
    })
}

const paymentMethodOptions = [
    {label: 'Bank Transfer (TB)', value: 'TB'},
    {label: 'Cash (NU)', value: 'NU'},
    {label: 'Payment ATM (MB)', value: 'MB'},
    {label: 'Bank Check (CH)', value: 'CH'},
    {label: 'Debit Card (CD)', value: 'CD'},
    {label: 'Credit Card (CC)', value: 'CC'},
];

const CustomField = props =>{
    const classes = useStyles();
    const { label, isRequired = false, error=false, isEdit, value="" } = props;
    return (
        <div className={classes.fieldContainer}>
            <p className={classes.label}>{label} {isRequired && isEdit && <sup className='errorAstrix'>*</sup>}</p>

            {!isEdit && <p className={classes.fieldValue}>{value}</p>}

            {isEdit && (
                <div className={clsx(classes.field, { error: error })}>
                    {props.children}
                </div>
            )}
        </div>
    )
}

const SetupConfig = props =>{   
    const classes = useStyles();
    const { 
        cashDrawers, 
        masterwayConfig, 
        taxes, 
        currentSpace = {},
        dispatch,
        transactionModes
    } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [config, setConfig] = useState(masterwayConfig);
    const [formErrors, setFormErrors] = useState({
        apiKey: false,
        apiSecret: false,
        companyCode: false,
        companySeries: false,
        vatType: false,
        cityTaxID: false,
        cityTaxExemptionCode: false
    });

    useEffect(() => {
        setConfig(masterwayConfig)
    }, [masterwayConfig]);

    const toggleEdit = () =>{
        setIsEdit(prevState => !prevState);
    }

    const handleChange = (e) => {
        const configClone = cloneDeep(config);
        set(configClone, e.target.name, e.target.value);
        setConfig(configClone);
    }

    const paymentMappingChange = (e, source) =>{
        const configClone = cloneDeep(config);
        const paymentMapping = {
            ...(configClone?.paymentMethodMappings && { ...configClone.paymentMethodMappings }),
            [source]: e.value
        }
        set(configClone, 'paymentMethodMappings', paymentMapping);
        setConfig(configClone);
    }

    const exemptionCodeHandler = (e, taxID) =>{
        const configClone = cloneDeep(config);
        const taxesAndFees = {
            ...(configClone?.taxesAndFees && { ...configClone.taxesAndFees }),
            [taxID]: e.target.value
        }
        set(configClone, 'taxesAndFees', taxesAndFees);
        setConfig(configClone);
    }

    const validateSetupConfig = () =>{
        let success = true;
        const errorsFields = { ...formErrors };
        
        for(const field of Object.keys(errorsFields)){
            if(!config?.[field]?.trim()){
                errorsFields[field] = true;
                success = false;
            }
        }
        setFormErrors({ ...errorsFields });
        return success;
    }

    const handleSave = () => {
        if(!validateSetupConfig()) return;
        dispatch(patchMasterwayConfig(currentSpace.propertyID, config));
    }

    const taxOptions = Object.values(taxes).filter(tax => tax.propertyID === currentSpace.propertyID).map(tax => ({ label: tax.name, value: tax._id }))
    const selectedCityTax = taxOptions.find(option => option.value === config.cityTaxID)
    const reducedTax = taxOptions.find(option => option.value === config.vatReducedTaxID)
    const intermediateTax = taxOptions.find(option => option.value === config.vatIntermediateTaxID)
    const normalTax = taxOptions.find(option => option.value === config.vatNormalTaxID)

    return (
        <div className={classes.root}>
            <div className={classes.sectionHeader}>
                <h4>Configuration</h4>
                <IconButton onClick={toggleEdit}>
                    {!isEdit && <EditIcon className='icon'/>}
                    {isEdit && <CloseIcon className='icon'/>}
                </IconButton>
            </div>

            <div className={classes.formContainer}>
                {/* ------------------API KEY------------------ */}
                <CustomField 
                    label="API Key"
                    isRequired
                    isEdit={isEdit}
                    value={config.apiKey}
                    error={formErrors.apiKey}
                >
                    <InputBase
                        id='apiKey'
                        name='apiKey'
                        value={config.apiKey || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------API SECRET------------------ */}
                <CustomField 
                    label="API Secret"
                    isRequired
                    isEdit={isEdit}
                    value={config.apiSecret}
                    error={formErrors.apiSecret}
                >
                    <InputBase
                        id='apiSecret'
                        name='apiSecret'
                        value={config.apiSecret || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------COMPANY CODE------------------ */}
                <CustomField 
                    label="Company Code"
                    isRequired
                    isEdit={isEdit}
                    value={config.companyCode}
                    error={formErrors.companyCode}
                >
                    <InputBase
                        id='companyCode'
                        name='companyCode'
                        value={config.companyCode || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------COMPANY SERIES------------------ */}
                <CustomField 
                    label="Company Series"
                    isRequired
                    isEdit={isEdit}
                    value={config.companySeries}
                    error={formErrors.companySeries}
                >
                    <InputBase
                        id='companySeries'
                        name='companySeries'
                        value={config.companySeries || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------VAT TYPE------------------ */}
                <CustomField 
                    label="VAT Type"
                    isRequired
                    isEdit={isEdit}
                    value={config.vatType}
                    error={formErrors.vatType}
                >
                    <InputBase
                        id='vatType'
                        name='vatType'
                        value={config.vatType || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------UNIQUE ACCOMMODATION CODE------------------ */}
                <CustomField 
                    label="Unique Accommodation Code"
                    isEdit={isEdit}
                    value={config.uniqueAccCode}
                >
                    <InputBase
                        id='uniqueAccCode'
                        name='uniqueAccCode'
                        value={config.uniqueAccCode || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* ------------------CASH REGISTERS------------------ */}
                <div className={classes.fieldGroup}>
                    <p className={classes.mainLabel}>Cash Registers :</p>  

                    {(isEmpty(Object.values(cashDrawers)) ? 

                        [{ _id: 'default', name: 'Default' }] : 

                        Object.values(cashDrawers)).map(drawer => (
                            <CustomField 
                                key={`label.${drawer._id}`}
                                label={drawer.name}
                                isEdit={isEdit}
                                value={config?.cashDrawers?.[drawer._id]}
                            >
                                <InputBase
                                    id={`cashDrawers.${drawer._id}`}
                                    name={`cashDrawers.${drawer._id}`}
                                    value={config?.cashDrawers?.[drawer._id] || ''}
                                    fullWidth
                                    type='text'
                                    onChange={handleChange}
                                />
                            </CustomField>
                    ))}
                </div>

                {/* -------------------CITY TAX-------------------- */}
                <CustomField 
                    label="City Tax"
                    isEdit={isEdit}
                    value={selectedCityTax?.label}
                    error={formErrors.cityTaxID}
                >
                    <Select
                        value={selectedCityTax}
                        styles={selectStyle}
                        options={taxOptions}
                        onChange={e => handleChange({ target: { name: 'cityTaxID', value: e.value } })}
                    />
                </CustomField>

                {/* -------------------CITY TAX EXEMPTION CODE-------------------- */}
                <CustomField 
                    label="City Tax Exemption Code"
                    isEdit={isEdit}
                    value={config.cityTaxExemptionCode}
                    error={formErrors.cityTaxExemptionCode}
                >
                    <InputBase
                        id='cityTaxExemptionCode'
                        name='cityTaxExemptionCode'
                        value={config.cityTaxExemptionCode || ''}
                        fullWidth
                        type='text'
                        onChange={handleChange}
                    />
                </CustomField>

                {/* -------------------REDUCED VAT(6%) MAPPING-------------------- */}
                <CustomField 
                    label="Reduced VAT(6%) Mapping"
                    isEdit={isEdit}
                    value={reducedTax?.label}
                >
                    <Select
                        value={reducedTax}
                        styles={selectStyle}
                        options={taxOptions}
                        onChange={e => handleChange({ target: { name: 'vatReducedTaxID', value: e.value } })}
                    />
                </CustomField>

                {/* -------------------INTERMEDIATE VAT(13%) MAPPING-------------------- */}
                <CustomField 
                    label="Intermediate VAT(13%) Mapping"
                    isEdit={isEdit}
                    value={intermediateTax?.label}
                >
                    <Select
                        value={intermediateTax}
                        styles={selectStyle}
                        options={taxOptions}
                        onChange={e => handleChange({ target: { name: 'vatIntermediateTaxID', value: e.value } })}
                    />
                </CustomField>

                {/* -------------------Normal VAT(23%) Mapping-------------------- */}
                <CustomField 
                    label="Normal VAT(23%) Mapping"
                    isEdit={isEdit}
                    value={normalTax?.label}
                >
                    <Select
                        value={normalTax}
                        styles={selectStyle}
                        options={taxOptions}
                        onChange={e => handleChange({ target: { name: 'vatNormalTaxID', value: e.value } })}
                    />
                </CustomField>

                {/* ------------------TAXES AND FEES------------------ */}
                <p className={classes.mainLabel}>Taxes and Fees :</p>

                <table className={classes.mappingTable}>
                    <tbody>
                        <tr>
                            <th>Counter Taxes</th>
                            <th>Exemption Code</th>
                        </tr>
                        {currentSpace?.taxes?.map(taxID =>{
                            const currentExemptionCode = config?.taxesAndFees?.[taxID] || '';
                            return (
                                <tr key={`tax_${taxID}`}>
                                    <td className={classes.tableCell}>
                                        <p className='primary'>{taxes?.[taxID]?.name}</p>
                                        <p className='secondary'>Code: {taxes?.[taxID]?.code}</p>
                                    </td>
                                    <td>
                                        {isEdit && (
                                            <InputBase
                                                name="exemptionCode"
                                                value={currentExemptionCode}
                                                fullWidth
                                                type='text'
                                                onChange={e =>exemptionCodeHandler(e, taxID)}
                                                placeholder='Enter exemption code'
                                            />
                                        )}

                                        {!isEdit && currentExemptionCode}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                {/* ------------------PAYMENT METHOD MAPPING------------------ */}
                <p className={classes.mainLabel}>Payment Method Mapping :</p>

                <table className={classes.mappingTable}>
                    <tbody>
                        <tr>
                            <th>Counter Payment Method</th>
                            <th>Masterway Payment Method</th>
                        </tr>
                        {transactionModes.map((mode, index) =>{
                            const currentOption = paymentMethodOptions.find(item => item.value == config?.paymentMethodMappings?.[mode.name]) || ''
                            return (
                                <tr key={`source_${index}`}>
                                    <td>{capitalize(mode.name)}</td>
                                    <td>
                                        {isEdit && (
                                            <Select
                                                value={currentOption}
                                                styles={selectStyle}
                                                options={paymentMethodOptions}
                                                onChange={e => paymentMappingChange(e, mode.name)}
                                            />
                                        )}

                                        {!isEdit && currentOption?.label}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* -----------------FOOTER-------------- */}
            <div className={classes.footer}>
                <Button 
                    className={classes.button}
                    onClick={handleSave}
                    disabled={!isEdit}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentSpace: state.spaces[state.dashboard.currentSpace],
        property: state.property,
        cashDrawers: state.cashDrawers,
        masterwayConfig: state.masterway.config,
        taxes: state.taxes,
        transactionModes: Object.values(state.transactionModes).filter(mode => mode.propertyID === state.property._id)
    }
}

export default connect(mapStateToProps)(SetupConfig);