const INIT_STATE = {
    selectedBedsInfo: {},
    availableBeds: {},
    ratesInfo: {},
    preOccupiedBeds: {},
    summary: {}
}

export default (state = INIT_STATE, action) =>{

    switch(action.type) {

        case "GET_FLOOR_PLAN_ROOM_MAPS_SUCCESS":
            return {
                ...state,
                ...action.payload       
            }

        case "UPDATE_SELECTED_BEDS": 
            return {
                ...state,
                selectedBedsInfo: {
                    ...state.selectedBedsInfo,
                    [action.payload.roomID]: { ...action.payload }
                }
            }
        
        case "DELETE_SELECTED_ROOM_BEDS":

            const bedsInfo = { ...state.selectedBedsInfo };
            delete bedsInfo[action.payload];

            return {
                ...state,
                selectedBedsInfo: { ...bedsInfo }
            }

        case "REVIEW_BOOKING_SUCCESS": 
            return {
                ...state,
                ...action.payload
            }

        case "RESET_FLOOR_PLAN":
            return { ...INIT_STATE }

        default: 
            return state;
    }
}