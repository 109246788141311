import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { returnCurrent } from "../../redux/selectors/dashboard";
import _ from "lodash";

import { initials } from '../utils/wordUtils';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import DescriptionIcon from '@material-ui/icons/Description';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const useStyles = makeStyles (theme => ({
  todoCategory: {
    marginBottom: 10, 
    marginLeft: 15,
    marginTop: 10,
    fontWeight: 600
  },
  card: {
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    height: 250,
    marginTop: 0,
    overflowY: "hidden"
  },
  cardAction: {
    alignItems: 'flex-start',
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1)
  },
  cardContent: {
    paddingTop: 4,
    paddingBottom: 4
  },
  categoryContainer: {
    marginBottom: 15
  },
  todoTitleHolder: {
    fontSize: '1.2rem',
    display: 'inline-block',
    lineHeight: 1.4,
    marginRight: 5
  },
  taskHolder: {
    marginBottom: 2
  },
  todoItem: {
    display: 'flex',
    alignItems: "center"
  },
  todoTitle: {
    display: 'inline-block',
    marginRight: 5
  },
  descIcon: {
    color: "#0081A7",
    display: 'inline-block',
    fontSize: '1.1rem',
    marginLeft: 5,
    marginRight: 5
  },
  dateHolder: {
    fontSize: '1.1rem',
    color: '#666666',
    display: 'inline-block',
  },
  profileHolder: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  userProfile: {
    fontSize: '1.1rem',
    color: '#666666',
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    color: "#ffffff",
    fontSize: '1rem',
    height: 15,
    lineHeight: 1.8,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
    width: 15,
  }
}))

const TodoSnapshot = (props) => {

  const classes = useStyles();
  const {todoArray, todos, users, categoryName} = props;

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardAction} onClick={props.handleOpen}>
        <CardContent className={classes.cardContent}>
            <div className={classes.categoryContainer}>
              
              <Typography className={classes.todoCategory} gutterBottom variant="h6" component="h2">
                {categoryName}
              </Typography>

              {todoArray.slice(0,4).map(task => (
                <div className={classes.taskHolder} key={task}>
                  {todos[task].status === false && (
                    <div className={classes.todoItem} ><Checkbox
                      disabled
                      id='123'
                      checked={todos[task].status}
                      color="primary"
                    />  
                      <div className={classes.todoTitleHolder}>
                        {todos[task].title}{todos[task].body && <DescriptionIcon className={classes.descIcon} />}
                        {!isNaN(props.todos[task].dueDate) && (
                          <div className={classes.dateHolder}>
                            <div className={classes.date}><CalendarTodayIcon className={classes.descIcon} />{moment(parseInt(todos[task].dueDate,10)).format('DD MMM')}</div>
                          </div>
                        )}
                        {todos[task].assignedTo && todos[task].assignedTo.map(user => (
                          <div className={classes.profileHolder} key={user}>
                            <div className={classes.userProfile}>
                              <Avatar 
                                alt={users && users[user] && initials(users[user].name)}
                                src={users && users[user] && users[user].avatar}
                                className={classes.avatar} 
                              >
                                {users && users[user] && initials(users[user].name)}
                              </Avatar>
                              {/* {users && 
                                users[user] && 
                                capitalize(_.first(users[user].name.split(" ")))
                              } */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              
            </div>  
        </CardContent>
      </CardActionArea>

    </Card>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  todoCategories: state.todoCategories,
  todos: state.todos,
  users: state.users
})

export default connect(mapStateToProps)(TodoSnapshot);