import http from "../../utils/http";
import axios from 'axios';
import { normalize } from 'normalizr';
import { productListSchema, conductorSchema } from '../../schema/experiences';

export const addUpdateProduct = (propertyID, data) =>{
    const ACTION_NAME = "ADD_UPDATE_ACTIVITY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/products/${propertyID}`, data)
            .then(successResult => {
                const { product } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { ...product }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const addGrade = (propertyID, data) =>{
    const ACTION_NAME = "ADD_UPDATE_GRADE";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/experiences/grades/${propertyID}`, data)
            .then(successResult => {
                const { grade } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { 
                        productID: data.productID,
                        grade: { 
                            [grade._id]: { 
                                ...grade,
                                slots: {
                                    [grade.slots[0]._id]: { ...grade.slots[0] }
                                } 
                            } 
                        }
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}  

export const addUpdateGrade = data =>{
    const ACTION_NAME = "ADD_UPDATE_GRADE";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/grades`, data)
            .then(successResult => {
                const { grade } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { 
                        productID: data.productID,
                        grade: { 
                            [grade._id]: { ...grade } 
                        }
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
} 

export const addSlot = data =>{
    const ACTION_NAME = "ADD_SLOT";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/experiences/slots`, data)
            .then(successResult => {
                const { slot } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { 
                        productID: data.productID,
                        gradeID: data.gradeID,
                        slot: { 
                            [slot._id]: { ...slot } 
                        }
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const updateSlot = data =>{
    const ACTION_NAME = "UPDATE_SLOT";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/slots/${data._id}`, data)
            .then(successResult => {
                const { slot } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { 
                        productID: data.productID,
                        gradeID: data.gradeID,
                        slot: { 
                            [slot._id]: { ...slot } 
                        }
                    }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const addUpdateConductor = (propertyID, data) =>{
    const ACTION_NAME = "ADD_UPDATE_CONDUCTOR";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/conductors/${propertyID}`, data)
            .then(successResult => {
                const { data } = successResult;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: { ...data }
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}


// NEW FLOW APIs...............................................................................

export const fetchProductList = (propertyID) =>{
    const ACTION_NAME = "FETCH_ACTIVITY_LIST";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/experiences/draft/list/${propertyID}`)
            .then(successResult => {
                const { data } = successResult;
                const normalizedProducts = normalize(data, [ productListSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.productList
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const fetchConductorList = (propertyID) =>{
    const ACTION_NAME = "FETCH_CONDUCTOR_LIST";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/experiences/conductors/list/${propertyID}`) 
            .then(successResult => {
                const { data } = successResult;
                const normalizedProducts = normalize(data, [ conductorSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.conductors
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const addUpdateDraft = (type, data) =>{
    const ACTION_NAME = "ADD_UPDATE_ACTIVITY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/draft/${type}`, data)
            .then(successResult => {
                const { draft } = successResult.data;
                const data = [draft];
                const normalizedProducts = normalize(data, [ productListSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.productList
                });

                return draft;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

export const deleteProduct = (draftID) =>{
    const ACTION_NAME = "DELETE_ACTIVITY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.delete(`/api/frontdesk/experiences/draft/${draftID}`)
            .then(successResult => {
                const { draft } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: draftID
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

// PRODUCT IMAGE APIs......................................................................

export const getUploadUrl = (draftID, propertyID, data) =>{
    return http
    .post(`api/frontdesk/experiences/photos/signedURL/${draftID}/${propertyID}`, data);
}

export const uploadProductImage = (url, data) =>{
    const options = {
        headers: {
            "Content-Type": data.type
        }
    };
    return axios.put(url, data.file, options, { key: data.key });
}

export const saveProductImage = (draftID, propertyID, data) =>{
    const ACTION_NAME = "SAVE_ACTIVITY_IMAGE";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/experiences/photos/saveURL/${draftID}/${propertyID}`, data)
            .then(successResult => {
                const { draft } = successResult.data;
                const data = [draft];
                const normalizedProducts = normalize(data, [ productListSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.productList
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

export const deleteProductImage = (draftID, photoID) =>{
    const ACTION_NAME = "DELETE_ACTIVITY_IMAGE";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.delete(`api/frontdesk/experiences/photos/${draftID}/${photoID}`)
            .then(successResult => {
                const { draft } = successResult.data;
                const data = [draft];
                const normalizedProducts = normalize(data, [ productListSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.productList
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}


// PRODUCT PUBLISH APIs............................................................
export const publishProduct = draftID =>{
    const ACTION_NAME = "PUBLISH_PRODUCT";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/experiences/draft/publish/${draftID}`)
            .then(successResult => {
                const { draft } = successResult.data;
                const data = [draft];
                const normalizedProducts = normalize(data, [ productListSchema ] );
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: normalizedProducts.entities.productList
                });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
                return;
            });
    }
}

export function resetError(action) {
    return function (dispatch) {
      dispatch({ type: `${action}_RESET`, payload: { error: null } });
    };
}

export const acivityImageUploadStatus = (type, error = 'Error') =>{
    const ACTION_NAME = 'ACTIVITY_IMAGE_UPLOAD';
    return function (dispatch) {
        switch(type){

            case "request":
                dispatch({ type: `${ACTION_NAME}_REQUEST` });
                break;
    
            case "success":
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                break;
    
            case "error":
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                break;
    
            default:
                dispatch({ type: `${ACTION_NAME}_FAILURE` });
                break;
    
        }
    }
}