import http from '../../utils/http'
import { CHANNEL } from '../../../utils/channels'

// CONNECT CHANNEL PROPERTY TO COUNTER
//----------------------------------------------------------------
export function connectChannelProperty(payload, propertyID) {
    const ACTION_NAME = "CONNECT_CHANNEL_PROPERTY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .post(`/api/channex/${CHANNEL.BookingCom.code}/connect/${propertyID}/`, { ...payload })
            .then(successResult => {
                const data = successResult.data;
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
                return data;
            })
            .catch(errorResult => {
                console.log(errorResult.response);
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
                // return errorResult;
                throw errorResult?.response?.data?.error?.error?.message;
            });
    };
}

// ACTIVATE CHANNEL PROPERTY TO COUNTER
//----------------------------------------------------------------
export function activateChannelProperty(propertyID) {
    const ACTION_NAME = "ACTIVATE_CHANNEL_PROPERTY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .post(`/api/channex/${CHANNEL.BookingCom.code}/activate/${propertyID}/`)
            .then(successResult => {
                const data = successResult.data;
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
                return data;
            })
            .catch(errorResult => {
                console.log(errorResult.response);
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
                // return errorResult;
                throw errorResult?.response?.data?.error?.error?.message;
            });
    };
}

// DEACTIVATE CHANNEL PROPERTY TO COUNTER
//----------------------------------------------------------------
export function deactivateChannelProperty(propertyID) {
    const ACTION_NAME = "DEACTIVATE_CHANNEL_PROPERTY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .post(`/api/channex/${CHANNEL.BookingCom.code}/deactivate/${propertyID}/`)
            .then(successResult => {
                const data = successResult.data;
                dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
                return data;
            })
            .catch(errorResult => {
                console.log(errorResult.response);
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
                // return errorResult;
                throw errorResult?.response?.data?.error?.error?.message;
            });
    };
}