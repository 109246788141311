import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Parser from "html-react-parser";
import { subscriptionPrices } from "../../plus/constants";
import {
  updateHWMigrationInfo,
  saveUserProfileLanguge,
} from "../../../redux/actions/hwMigration/hwMigration";
import { CHARGEBEE_CB_SITE } from "../../../config";

const PLANS_COLOR_CODES = {
  basicPlan: "#e1c95b",
  plusPlan: "#5dbe7b",
  proPlan: "#876878",
};

const getPlanStyles = (styleType) => {
  const styles = {};

  Object.keys(PLANS_COLOR_CODES).forEach((planID) => {
    const colorCode = PLANS_COLOR_CODES[planID];

    if (styleType == "span") {
      styles[`&.${planID} span`] = {
        color: colorCode,
        borderBottom: `1px solid ${colorCode}`,
        fontWeight: 600,
      };
      return;
    }

    if (styleType == "btn") {
      styles[`&.${planID}`] = {
        color: colorCode,
        border: `2px solid ${colorCode}`,

        "&:hover": {
          backgroundColor: colorCode,
          color: "#fff",
        },
      };
      return;
    }

    styles[`&.${planID}`] = { color: colorCode };
  });

  return styles;
};

const styles = (theme) => ({
  root: {
    // border: '1px solid blue',
    margin: "0px auto",
    padding: "10px 0px",
    marginTop: 80,
  },
  description: {
    color: "#666666",
    fontSize: "1.4rem",
    lineHeight: "15px",
  },
  couponContainer: {
    padding: "15px",
    borderRadius: "5px",
    border: "1px solid #e0e0e0",
    marginBottom: "70px",
  },

  cardListContainer: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "unset",
      alignItems: "flex-start",
    },
  },

  cardGrid: {
    maxWidth: "320px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
    },
  },

  cardContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    border: "1px solid #e0e0e0",
    padding: 20,
    height: "100%",
    boxSizing: "border-box",

    "&.plusPlan": {
      border: "2px solid #5dbe7b",
    },

    "&.basicPlan": {
      "& $secondaryTitle": { visibility: "hidden" },

      "& .price::after": { display: "none" },

      "& .duration": { visibility: "hidden" },

      "& .freeTag": { display: "none" },
    },
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "2.2rem",
    color: "#5dbe7b",
    marginBottom: "10px",
    ...getPlanStyles(),
  },
  secondaryTitle: {
    fontWeight: 600,
    fontSize: "1.3rem",
    marginBottom: "15px",
  },
  priceContainer: {
    display: "flex",
    fontWeight: 600,
    marginBottom: "10px",

    "& .price": {
      fontSize: "2.5rem",
      marginBottom: "5px",
      position: "relative",
      marginRight: "10px",
    },

    // '& .price::after':{
    //     top: "50%",
    //     left: "0px",
    //     right: "0px",
    //     content: "''",
    //     position: "absolute",
    //     marginTop: "calc(0.125em / 1 * -1)",
    //     borderBottom: "3px solid #c96f6e",
    // },

    "& .duration": {
      fontSize: "1rem",
    },

    "& .freeTag": {
      fontSize: "2.2rem",
      color: "#c96f6e",
    },
  },

  subscribeBtn: {
    borderRadius: "25px",
    textAlign: "center",
    padding: "15px",
    fontSize: "1.3rem",
    fontWeight: 600,
    cursor: "pointer",
    display: "block",

    "&:hover": {
      backgroundColor: "#5dbe7b",
      color: "#fff",
    },

    ...getPlanStyles("btn"),
  },
  featureList: {
    margin: "30px 0px",
    "& .subInfo": {
      fontWeight: 600,
      fontSize: "1.1rem",
      color: "#666666",
      marginBottom: "15px",
    },
  },
  featureItem: {
    cursor: "help",
    fontWeight: 400,
    color: "#666666",
    fontSize: "1.3rem",
    marginBottom: "15px",
    ...getPlanStyles("span"),
  },
  recommentText: {
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: "10px",
    marginTop: "-26px",
    color: "#5dbe7b",
    [theme.breakpoints.down("xs")]: {
      marginTop: "unset",
    },
  },
  tooltip: {
    padding: 10,
    color: "white",
    fontSize: "14px",
    lineHeight: 1.4,
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333333",
    color: "white",
    maxWidth: 250,
    fontSize: "12px",
    opacity: "0.7",
    borderRadius: 10,
  },
}))(Tooltip);

const getPricingList = (t) => {
  return [
    {
      id: 1,
      name: "Basic",
      monthlyPrice: "Free",
      className: "basicPlan",
      inclusinDescription: "",
      planID: "free",
    },
    {
      id: 2,
      name: "Plus",
      monthlyPrice: `€${subscriptionPrices.plusMonthlyPrice}`,
      className: "plusPlan",
      inclusinDescription: t("subscriptions.pricing.plusInclusions"),
      planID: "plus",
    },
    {
      id: 3,
      name: "Pro",
      monthlyPrice: `€${subscriptionPrices.proMonthlyPrice}`,
      className: "proPlan",
      inclusinDescription: t("subscriptions.pricing.proInclusions"),
      planID: "pro",
    },
  ];
};

const getFeaturesList = (t) => {
  t("subscriptions.feature1.name.item1");
  return {
    1: [
      {
        id: "1-1",
        name: t("subscriptions.feature1.name.item1"),
        description: t("subscriptions.feature1.description.item1"),
      },
      {
        id: "1-2",
        name: t("subscriptions.feature1.name.item2"),
        description: t("subscriptions.feature1.description.item2"),
      },
      {
        id: "1-3",
        name: t("subscriptions.feature1.name.item3"),
        description: t("subscriptions.feature1.description.item3"),
      },
      {
        id: "1-4",
        name: t("subscriptions.feature1.name.item4"),
        description: t("subscriptions.feature1.description.item4"),
      },
      {
        id: "1-5",
        name: t("subscriptions.feature1.name.item5"),
        description: t("subscriptions.feature1.description.item5"),
      },
    ],
    2: [
      {
        id: "2-1",
        name: t("subscriptions.feature2.name.item1"),
        description: t("subscriptions.feature2.description.item1"),
      },
      {
        id: "2-2",
        name: t("subscriptions.feature2.name.item2"),
        description: t("subscriptions.feature2.description.item2"),
        subText: t("subscriptions.feature2.subText.item2"),
      },
      {
        id: "2-3",
        name: t("subscriptions.feature2.name.item3"),
        description: t("subscriptions.feature2.description.item3"),
      },
      {
        id: "2-4",
        name: t("subscriptions.feature2.name.item4"),
        description: t("subscriptions.feature2.description.item4"),
        subText: t("subscriptions.feature2.subText.item4"),
      },
      {
        id: "2-5",
        name: t("subscriptions.feature2.name.item5"),
        description: t("subscriptions.feature2.description.item5"),
        subText: t("subscriptions.feature2.subText.item5"),
      },
      {
        id: "2-6",
        name: t("subscriptions.feature2.name.item6"),
        description: t("subscriptions.feature2.description.item6"),
        subText: t("subscriptions.feature2.subText.item6"),
      },
      {
        id: "2-7",
        name: t("subscriptions.feature2.name.item7"),
        description: t("subscriptions.feature2.description.item7"),
        subText: t("subscriptions.feature2.subText.item7"),
      },
      {
        id: "2-8",
        name: t("subscriptions.feature2.name.item8"),
        description: t("subscriptions.feature2.description.item8"),
        subText: t("subscriptions.feature2.subText.item8"),
      },
      {
        id: "2-9",
        name: t("subscriptions.feature2.name.item9"),
        description: t("subscriptions.feature2.description.item9"),
        subText: t("subscriptions.feature2.subText.item9"),
      },
      {
        id: "2-10",
        name: t("subscriptions.feature2.name.item10"),
        description: t("subscriptions.feature2.description.item10"),
        subText: t("subscriptions.feature2.subText.item10"),
      },
      {
        id: "2-11",
        name: t("subscriptions.feature2.name.item11"),
        description: t("subscriptions.feature2.description.item11"),
        subText: t("subscriptions.feature2.subText.item11"),
      },
    ],
    3: [
      {
        id: "3-1",
        name: t("subscriptions.feature3.name.item1"),
        description: t("subscriptions.feature3.description.item1"),
      },
      {
        id: "3-2",
        name: t("subscriptions.feature3.name.item2"),
        description: t("subscriptions.feature3.description.item2"),
        subText: t("subscriptions.feature3.subText.item2"),
      },
      {
        id: "3-3",
        name: t("subscriptions.feature3.name.item3"),
        description: t("subscriptions.feature3.description.item3"),
        subText: t("subscriptions.feature3.subText.item3"),
      },
      {
        id: "3-4",
        name: t("subscriptions.feature3.name.item4"),
        description: t("subscriptions.feature3.description.item4"),
        subText: t("subscriptions.feature3.subText.item4"),
      },
      {
        id: "3-5",
        name: t("subscriptions.feature3.name.item5"),
        description: t("subscriptions.feature3.description.item5"),
        subText: t("subscriptions.feature3.subText.item5"),
      },
    ],
  };
};

class PaymentDetails extends Component {
  componentDidMount() {
    this.initCargebee();
  }

  initCargebee = () => {
    const dataCbSite = CHARGEBEE_CB_SITE;

    Chargebee.inited
      ? Chargebee.registerAgain()
      : Chargebee.init({ site: dataCbSite });

    const cbInstance = Chargebee.getInstance();

    cbInstance.setCheckoutCallbacks((cart) => {
      const product = cart.products[0];
      const onboardingStatus = product.planId;

      return {
        loaded: function () {}, // checkout opened...
        close: () => console.log("checkout closed"),
        success: (hostedPageId) => {
          // hostedPageId will be unique token for the checkout that happened.
          // Use hostedPageId with the hosted page api to get subscription details.
          // [hosted page api: https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page]

          cbInstance.closeAll();

          const data = { onboardingStatus, hostedPageId };
          this.subscriptionHandler(data);
        },
        step: function (value) {}, // value -> which step in checkout...
      };
    });
  };

  freePlanHandler = () => {
    const data = { onboardingStatus: "free" };
    this.subscriptionHandler(data);
  };

  subscriptionHandler = (data) => {
    const { activeStep, dispatch, updateAPIParams, history, loginToWorksape } =
      this.props;

    this.setUserProfileLanguge(() => {
      dispatch(updateHWMigrationInfo(activeStep, updateAPIParams, data)).then(
        (response) => {
          if (response.migrationCompleted) {
            // if(data.onboardingStatus == 'free'){
            //     loginToWorksape();
            //     return;
            // }

            loginToWorksape(null, false);
            history.push("/schedule-call");
          }
        }
      );
    });
  };

  setUserProfileLanguge = (callback) => {
    const { updateAPIParams, dispatch } = this.props;
    const currentLanguage = i18next.language;

    const data = {
      spaceID: updateAPIParams.spaceID,
      language: currentLanguage,
    };
    dispatch(saveUserProfileLanguge(data)).then((success) => {
      callback();
    });
  };

  render() {
    const { classes, t } = this.props;
    const FEATURES = getFeaturesList(t);
    const PRICING = getPricingList(t);

    return (
      <div className={classes.root}>
        <Grid container className={classes.cardListContainer}>
          {PRICING.map((item, index) => (
            <Grid
              item
              md={4}
              xs={12}
              className={classes.cardGrid}
              key={`plan${index}`}
            >
              {item.id == 2 && (
                <div className={classes.recommentText}>
                  {t("subscriptions.mostPopular")}
                </div>
              )}

              <div className={clsx(classes.cardContainer, item.className)}>
                <div className={clsx(classes.cardTitle, item.className)}>
                  {item.name}
                </div>

                {/* <div className={classes.secondaryTitle}>6 month free on the {item.name} plan!</div> */}

                <div className={classes.priceContainer}>
                  <div>
                    <div className="price">{item.monthlyPrice}</div>
                    <div className="duration">
                      {t("subscriptions.perMonth")}
                    </div>
                  </div>
                </div>

                {/* ----------Free subscription button--------------- */}
                {item.id == 1 && (
                  <div
                    className={clsx(classes.subscribeBtn, item.className)}
                    onClick={this.freePlanHandler}
                  >
                    {t("actions.choose")}
                  </div>
                )}

                {/* ---------------plus/pro button--------------- */}
                {item.id > 1 && (
                  <a
                    className={clsx(classes.subscribeBtn, item.className)}
                    href="javascript:void(0)"
                    data-cb-type="checkout"
                    data-cb-plan-id={item.planID}
                  >
                    {t("actions.choose")}
                  </a>
                )}

                <div className={classes.featureList}>
                  <h4>{t("subscriptions.includes")}</h4>

                  <div className="subInfo">{item.inclusinDescription}</div>

                  {FEATURES[item.id].map((feature, frIndex) => (
                    <HtmlTooltip
                      key={`feature_${item.id}_${frIndex}`}
                      title={
                        <div className={classes.tooltip}>
                          {feature.description}
                        </div>
                      }
                      arrow
                      placement="top"
                    >
                      <div
                        className={clsx(classes.featureItem, item.className)}
                      >
                        {Parser(feature.name)}
                      </div>
                    </HtmlTooltip>
                  ))}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRouter(connect(null)(PaymentDetails)))
);
