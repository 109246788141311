import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import Sidebar from './sidebar';
import ContentRenderer from './ContentRenderer';

import { sidebarMenu } from './constant';
import { getNextStepInfo, getPrevStepInfo } from './activityHelper';
import { addUpdateDraft, resetError } from '../../../redux/actions/experiences/activities';

const styles = theme =>({
    dialog:{
        position: 'relative'
    },
    container: {
        display: 'flex',
        height: '100%',
        position: 'relative'
    },
    sidebarContainer:{
        backgroundColor: '#F8F9FB'
    },
    loadingState: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 999
      },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '50%',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 16,
        zIndex: 99,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
    },
});

class ActivityFLow extends Component {

    constructor(props){
        super(props);
        const defaultMainStepID = Object.keys(sidebarMenu)[0];
        const defaultSubStepID = sidebarMenu[defaultMainStepID].subMenu[0].id;
        this.state = {
            firstStepMenuID: defaultMainStepID,
            activeMainMenuID: defaultMainStepID,
            activeSubMenuID: defaultSubStepID,
            productID: null
        }
    }

    componentDidMount(){
        const { productID, showEditPage } = this.props;

        if(productID) this.setState({ productID });

        if(showEditPage) this.navigateToMenuHandler(showEditPage.mainMenuID, showEditPage.subMenuID);
        // this.navigateToMenuHandler('SETTINGS', 'bookingDetails');

    }

    navigateToMenuHandler = (mainStepID, subStepID) =>{
        this.setState({
            activeMainMenuID: mainStepID,
            activeSubMenuID: subStepID
        })
    }

    nextStepHandler = () =>{
        const { activeMainMenuID, activeSubMenuID } = this.state;
        const { errors } = this.props;

        const nextStepInfo = getNextStepInfo(activeMainMenuID, activeSubMenuID);

        if(nextStepInfo) this.navigateToMenuHandler(nextStepInfo.mainMenuID, nextStepInfo.subMenuID);

        if(errors) this.handleCloseError();
    }

    prevStepHandler = () =>{
        const { activeMainMenuID, activeSubMenuID } = this.state;
        const prevStepInfo = getPrevStepInfo(activeMainMenuID, activeSubMenuID);

        if(prevStepInfo) this.navigateToMenuHandler(prevStepInfo.mainMenuID, prevStepInfo.subMenuID);
    }

    submitDraftHandler = (data, type = "draft", preventNextStep = false) =>{
        const { dispatch, currentSpace } = this.props;
        const { productID } = this.state;

        let requestData = {
            propertyID: currentSpace.propertyID,
            ...data
        }

        if(productID){
            requestData._id = productID;
        }

        const response = dispatch(addUpdateDraft(type, requestData));
        return response.then(response =>{
            if(response){
                if(!productID && response._id) this.setState({ productID: response._id });
                if(!preventNextStep) this.nextStepHandler();
                return true;
            }
            return;
        });
    }

    getCoverPhotoUrl = () =>{
        // const { productID, activityList } = this.props;
        const { activityList } = this.props;
        const { productID } = this.state;

        if(!productID) return '';

        const productData = activityList[productID];

        if(productData && productData.photos && productData.photos.length > 0){
            const photoData = productData.photos.find(photo =>photo.type == 'cover');
            return photoData && photoData.original ? photoData.original : '';
        }

        return '';
    }

    getProductData = () =>{
        const { activityList } = this.props;
        const { productID } = this.state;
        const productData = productID ? { ...activityList[productID] } : {};
        return productData;
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        
        dispatch(resetError('ADD_UPDATE_ACTIVITY'));
        dispatch(resetError('ACTIVITY_IMAGE_UPLOAD'));
        dispatch(resetError('DELETE_ACTIVITY_IMAGE'));
        dispatch(resetError('PUBLISH_PRODUCT'));
    }

    componentWillUnmount(){
        this.handleCloseError();
    }

    render(){
        const { 
            classes, 
            closeModalHandler,
            currentSpace,
            isLoading,
            errors,
            publishMessageHandler 
        } = this.props;

        const { activeMainMenuID, activeSubMenuID, productID } = this.state;
        
        const productData = this.getProductData();

        const contentProps = {
            activeStepInfo: {
                mainMenuID: activeMainMenuID,
                subMenuID: activeSubMenuID
            },
            productData,
            currency: currentSpace.currency,
            nextStepHandler: this.nextStepHandler,
            prevStepHandler: this.prevStepHandler,
            submitDraftHandler: this.submitDraftHandler,
            coverPhotoUrl: this.getCoverPhotoUrl(),
            closeModalHandler: closeModalHandler,
            publishMessageHandler: publishMessageHandler
        }

        return(
            <Dialog 
                open={true} 
                // onClose={closeModalHandler}
                className={classes.dialog} 
                fullScreen={true}
            >   
                <CloseIcon 
                    className={classes.closeIcon} 
                    onClick={closeModalHandler} 
                />

                <Grid container className={classes.container}>

                    {/*----------------PROGRESS LOADER--------------------- */}
                    {isLoading && (
                        <div className={classes.loadingState}>
                            <CircularProgress className={classes.loader} />
                        </div>
                    )}
                    
                    <Grid item className={classes.sidebarContainer} xs={12} sm={3} lg={2}>
                        <Sidebar 
                            productID={productID}
                            activeMainMenuID = {activeMainMenuID}
                            activeSubMenuID = {activeSubMenuID}
                            productData = {productData}
                            navigateToMenuHandler = {this.navigateToMenuHandler}
                            closeModalHandler = {closeModalHandler}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} lg={10}>
                        <ContentRenderer 
                            activeSubMenuID = {activeSubMenuID}
                            contentProps = {contentProps}
                        />
                    </Grid>
                </Grid>

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state =>{
    const { spaces, dashboard, loading, errors, experiences } = state;
    
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
        activityList: experiences.activityList,

        isLoading: loading.ADD_UPDATE_ACTIVITY || 
            loading.ACTIVITY_IMAGE_UPLOAD ||
            loading.DELETE_ACTIVITY_IMAGE ||
            loading.PUBLISH_PRODUCT ||
            loading.DELETE_ACTIVITY,

        errors: errors.ADD_UPDATE_ACTIVITY || 
            errors.ACTIVITY_IMAGE_UPLOAD ||
            errors.DELETE_ACTIVITY_IMAGE ||
            errors.PUBLISH_PRODUCT ||
            errors.DELETE_ACTIVITY,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ActivityFLow));