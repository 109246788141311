import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import StaffList from './StaffList';
import NewStaff from './NewStaff';

import AddIcon from '@material-ui/icons/Add';
import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 30px)',
    marginTop: 30,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  buttonContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  addContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 15,
  },
  addText: {
    color: '#666666',
  },
  addIcon: {
    color: '#666666',
    fontSize: '2rem'
  },
  next: {
    borderRadius: '25px',
    fontWeight: 600,
    fontSize: '1.5rem',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  }
}))

const StaffAccess = (props) => {

  const classes = useStyles();
  const { propertyID } = props;
  const [newStaff, setNewStaff] = React.useState([])
  const [staffIndex, setStaffIndex] = React.useState(0)

  useEffect(() => {
    handleAddNewStaff();
  }, []);

  const handleClear = (index) => {
    const array = newStaff;
    array.splice(index);
    setNewStaff(array);
  }

  const handleAddNewStaff = () => {
    setNewStaff(
      [...newStaff,
        <div key={staffIndex} className={classes.row}>
          <NewStaff index={staffIndex} handleCancel={handleClear} />
        </div>
      ]
    );
    setStaffIndex(staffIndex+1);
  }

  return (
    <div className={classes.root}>

      <div className={classes.row} style={{marginBottom: 0}}>
        <StaffList />
      </div>
    
      {newStaff}  

      <div className={clsx(classes.row, classes.buttonContainer)}>
        <div className={classes.addContainer} onClick={handleAddNewStaff}>
          <AddIcon className={classes.addIcon} />
          <Typography variant="h6" className={classes.addText}>Add Staff Member</Typography>
        </div>
        <Button variant="contained" color="primary" className={classes.next} onClick={() => props.handleNext()}>
          Next
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID
})

export default connect(mapStateToProps)(StaffAccess);