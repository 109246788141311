import http from '../../utils/http';

export function fetchByApiKey(propertyID, apiKey) {
	const ACTION_NAME = "FETCH_LISTS_APIKEY";
	return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    if(apiKey) {
      return http
      .get(`/api/frontdesk/mailChimp?apiKey=${apiKey}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
    }
  };
}

export function resetList() {
  const ACTION_NAME = "RESET_LIST";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}` });
  }
}

export function fetchByProperty(propertyID) {
  const ACTION_NAME = "FETCH_LISTS_PROPERTY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/mailChimp?propertyID=${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function saveList(data) {
  const ACTION_NAME = "SAVE_LIST";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/mailChimp', data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export function updateList(data) {
  const ACTION_NAME = "UPDATE_LIST";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/mailChimp`, data)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload:data });
        return successResult
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}