import React from "react";
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setCurrentTodo } from "../../redux/actions/dashboard/dashboard";
import { updateTodo, editTodo, deleteTodo } from "../../redux/actions/todos/todos";
import { returnCurrent, returnUsers } from '../../redux/selectors/dashboard';
import moment from 'moment';
import CommentList from "../comments/CommentList";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import { initials } from '../utils/wordUtils';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { fetchDatePerTimezone } from "../../utils/utility";

const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  backButton: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '2.5rem',
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 24,
    transition: '150ms',
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    top: 24,
    transition: '150ms',
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cancelButton: {
    marginRight: 10
  },
  listSection: {
    height: 'calc(100vh - 230px)',
    overflowY: 'scroll',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 150px)',
    }
  },
  todoContainer: {
    alignItems: "center",
    borderBottom: '1px solid #eeeeee',
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  leftAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '20%'
  },
  rightAlign : {
    paddingLeft: 15,
    width: '70%'
  },
  todoName: {
    cursor: "pointer",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5
  },
  textField: {
    fontWeight: 600
  },
  todoDetails: {
    fontSize: "1.2rem",
    marginTop: 7
  },
  categoryOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px'
  },
  categoryMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  moreIcon: {
    fontSize: '1.8rem'  
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  assignedTo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  userProfile: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 10
  },
  editAssignedTo: {
    width: '100%'
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: "1px solid #dddddd",
    color: "#ffffff",
    fontSize: '1.2rem',
    height: 20,
    lineHeight: 1.8,
    marginRight: 5,
    textAlign: 'center',
    width: 20,
  },
  chip: {
    margin: theme.spacing(1),
  },
  chipAvatar: {
    backgroundColor: '#bdbdbd',
    color:'#ffffff',
    fontSize: '1.2rem',
    lineHeight: 2,
    textAlign: 'center',
  },
  notesContainer: {
    marginTop: theme.spacing(2)
  },
  notes: {
    fontWeight: 600
  },
  noteDisplay: {
    whiteSpace: "pre-wrap"
  },
  noteDetails: {
    '& div': {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '1.4'
    }
  },
  select: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.8'
  },
  setDate: {
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: '1.8'
  },
  cancelButton: {
    marginRight: 10
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
});

class TodoItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      focused: false,
      date: null,
      assigned: false,
      assignedTo: [],
      title: '',
      notes: null,
      anchorEl: null
    }
  }

  handleBack = () => {
    this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    this.props.dispatch(setCurrentTodo());
    this.props.handleOpenTodoList();
  }

  handleClose = () => {
    this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    this.props.dispatch(setCurrentTodo());
    this.props.handleCloseTodoList();
  }

  handleStatusUpdate = () => {
    const newStatus = !this.props.currentTodo.status
    this.props.dispatch(updateTodo(this.props.currentTodoID, newStatus));
  }

  handleAssigned = user => {
    user = user || [];
    let assignedTo = user.map(data => data.value)
    this.setState({ assignedTo })
  }

  handleRemoveAssigned = user => {
    var array = [...this.state.assignedTo];
    // console.log('removing assigned to', user);
    var index = array.indexOf(user);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({assignedTo: array});
    }
  }

  handleEditMenuClick = () => {
    this.setState({anchorEl: null});
    this.handleEdit();
  }

  handleEdit = () => {
    this.setState({edit: true});
    this.setState({assignedTo: this.props.currentTodo.assignedTo});
    if (!isNaN(this.props.currentTodo.dueDate)) {
      this.setState({ date: moment(parseInt(this.props.currentTodo.dueDate,10)) })
    }
  }

  handleTodoItemMenu = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleTodoItemMenuClose = () => {
    this.setState({anchorEl: null})
  }

  handleDelete = () => {
    this.setState({anchorEl: null})
    this.props.dispatch(deleteTodo(this.props.currentTodoID));
    this.setState({edit: false, date: null, notes: null, title: '', focused: false});
    this.props.dispatch(setCurrentTodo());
    this.props.handleOpenTodoList();
  }

  handleCancelUpdates = () => {
    this.setState({edit: false, date: null, notes: '', title: '', focused: false, assignedTo: []});
  }

  handleSubmitUpdates = () => {
    // console.log('update todo item', this.state.title, this.state.date, this.props.currentTodoID)
    // console.log(this.state.assignedTo);
    var updates = {dueDate: moment(this.state.date).valueOf().toString()};
    if (this.state.title != '') {
      updates.title = this.state.title
    }
    if (this.state.assignedTo != []) {
      updates.assignedTo = this.state.assignedTo
    }
    if (this.state.notes != null) {
      updates.body = this.state.notes
    }
    this.props.dispatch(editTodo(this.props.currentTodoID, updates));
    this.handleCancelUpdates();
  }

  render() {

    const {classes, currentTodo, currentTodoID,currentSpace, users, t} = this.props;

    return(
      <div>
        <Dialog
          open={Boolean(currentTodoID)}
          classes={{ paperFullWidth: classes.dialogPaper }}
          onClose={this.handleClose}
          maxWidth="sm"
          fullWidth={true}
          fullScreen={window.innerWidth < 901}
        >

          {/* --------------------------------------------------------- */}
          {/* Header */}
          {/* --------------------------------------------------------- */}
          {currentTodoID && 
            <div className={classes.dialog}>
              <div className={classes.dialogTitle}>
                <BackIcon className={classes.backButton} onClick={this.handleBack} />
                <div className={classes.header}>{t('todos.todoItem')}</div>
                <CloseIcon className={classes.closeIcon} onClick={this.handleClose} />
              </div>
      
              <div className={classes.listSection}>
                {/* List Files */}
                <div>
                  <div className={classes.todoContainer}>
                    
                    <div className={classes.leftAlign}>
                      <Checkbox
                        className={classes.checkbox}
                        onChange={() => this.handleStatusUpdate()}
                        id={currentTodo._id || 'default'}
                        value={currentTodo.status || ''}
                        checked={currentTodo.status || false}
                        color="primary"
                      />
                    </div>
                    
                    {/* --------------------------------------------------------------------- */}
                    {/* TODO TITLE */}
                    {/* --------------------------------------------------------------------- */}
                    <div className={classes.rightAlign}>
                      {!this.state.edit && 
                        <div className={classes.todoName} onClick={this.handleEdit}>
                          {currentTodo.title}
                        </div>
                      }
                      {this.state.edit && (
                        <TextField
                          label={t('todos.title')}
                          className={classes.textField}
                          defaultValue={currentTodo.title}
                          onChange={(e) => this.setState({title: e.target.value})}
                          margin="none"
                          fullWidth
                          autoFocus
                        />
                      )}
                      {!this.state.edit && <div className={classes.todoDetails}>
                        {t('todos.addedBy')} {users && users[currentTodo.createdBy].name} {t('todos.on')} {fetchDatePerTimezone(currentTodo.createdAt, currentSpace.timezone).format('MMM DD, YYYY')}
                      </div>}
                    </div>

                    {/* ------------------------------------------------ */}
                    {/* MENU - EDIT, DELETE */}
                    {/* ------------------------------------------------ */}
                    {!this.state.edit && (
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={this.handleTodoItemMenu}
                        className={classes.categoryOptions}
                      >
                        <MoreHorizIcon className={classes.moreIcon} />
                      </IconButton>
                    )}
                    <Menu
                      id="todo-item-menu"
                      anchorEl={this.state.anchorEl}
                      className={classes.categoryMenu}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleTodoItemMenuClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      style={{padding: 0}}
                      getContentAnchorEl={null}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <ListItem button onClick={this.handleEditMenuClick}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <EditIcon />
                        </ListItemIcon>
                        <Typography variant="body2">{t('todos.editTodo')}</Typography>
                      </ListItem>
                      <ListItem button onClick={this.handleDelete}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="body2">{t('todos.deleteTodo')}</Typography>
                      </ListItem>
                    </Menu>
                    
                  </div>

                  {/* --------------------------------------------------------------------- */}
                  {/* ASSIGNED TO */}
                  {/* --------------------------------------------------------------------- */}
                  <div className={classes.todoContainer}>
                    <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.assignedTo')}:</div>
                    <div className={`${classes.rightAlign} ${classes.assignedTo}`}>
                      {!this.state.assigned && 
                        currentTodo.assignedTo.length !== 0 ? 
                        !this.state.edit && currentTodo.assignedTo.map(user => (
                          <span className={classes.userProfile} key={user} onClick={this.handleEdit}>
                            <Avatar 
                              alt={users && users[user] && initials(users[user].name)}
                              src={users && users[user] && users[user].avatar} 
                              className={classes.avatar}
                            >
                              {users && users[user] && initials(users[user].name)}
                            </Avatar>
                            {users && users[user] && users[user].name}
                          </span>
                        )) : 
                        !this.state.edit && <span className={classes.select} onClick={this.handleEdit}>Type names to assign..</span>}
                      {this.state.edit && (
                        <div className={classes.editAssignedTo}>
                          <Select
                            options={this.props.suggestions}
                            isClearable={true}
                            onChange={(e) => this.handleAssigned(e)}
                            defaultValue={this.props.suggestions.filter(e => this.state.assignedTo.includes(e.value))}
                            isMulti
                            placeholder={t('todos.typeNames')}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* --------------------------------------------------------------------- */}
                  {/* DUE DATE */}
                  {/* --------------------------------------------------------------------- */}
                  <div className={classes.todoContainer}>
                    <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.dueOn')}:</div>
                    {!this.state.edit && (
                      <div onClick={this.handleEdit} className={`${classes.rightAlign} ${classes.selectDate}`}>
                        {!isNaN(currentTodo.dueDate) ? 
                          <span className={classes.setDate}>{moment(parseInt(currentTodo.dueDate,10)).format('MMM DD, YYYY')}</span> : 
                          <span className={classes.select}>{t('todos.selectDate')}</span> }
                      </div>
                    )}
                    {this.state.edit && (
                      <div className={`${classes.rightAlign} ${classes.notesDesc}`}>
                        <SingleDatePicker
                          date={this.state.date} // momentPropTypes.momentObj or null
                          onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                          id={currentTodo._id} // PropTypes.string.isRequired,
                          showClearDate={true}
                          placeholder={t('todos.selectDate')}
                          numberOfMonths={1} 
                          noBorder={true}
                          displayFormat={'MMM DD, YYYY'}
                          showDefaultInputIcon
                          inputIconPosition="before"
                          small
                        />
                      </div>
                    )}
                  </div>

                  {/* --------------------------------------------------------------------- */}
                  {/* NOTES */}
                  {/* --------------------------------------------------------------------- */}
                  <div className={classes.todoContainer}>
                    <div className={`${classes.leftAlign} ${classes.notes}`}>{t('todos.notes')}:</div>
                    
                    {!this.state.edit && (
                      <div onClick={this.handleEdit} className={`${classes.rightAlign} ${classes.selectDate}`}>
                        {currentTodo.body ?
                          <div className={classes.noteDisplay}>{currentTodo.body}</div> : 
                          <span className={classes.select}>{t('todos.addExtraDetails')}..</span>}
                      </div>
                    )}

                    {this.state.edit && (
                      <div className={`${classes.rightAlign} ${classes.notesDesc}`}>
                        <TextField
                          fullWidth
                          multiline
                          margin="none"
                          variant="outlined"
                          defaultValue={currentTodo.body}
                          onChange={(e) => this.setState({notes: e.target.value})}
                          className={classes.noteDetails}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    )}

                  </div>
                </div>
                
                {/* --------------------------------------------------------------------- */}
                {/* SUBMIT / CANCEL UPDATES */}
                {/* --------------------------------------------------------------------- */}
                {this.state.edit && (
                  <div className={classes.submit}>
                    <Button size="small" variant="outlined" className={classes.cancelButton} onClick={this.handleCancelUpdates}>{t('actions.cancel')}</Button>
                    <Button size="small" variant="outlined" className={classes.button} onClick={this.handleSubmitUpdates}>{t('actions.save')}</Button>
                  </div>
                )}

                <CommentList 
                  itemComments={currentTodo.comments} 
                  slatePlugins={['files', 'mentions']}
                />

              </div>
            </div>
          }
          
        </Dialog>
        
      </div>
    )
  }
}

const mapStateToProps = state => {
  let suggestionList = returnUsers(state.users, returnCurrent(state.spaces, state.dashboard.currentSpace).members, returnCurrent(state.spaces, state.dashboard.currentSpace).admins);
  let suggestions = suggestionList.map(member => {
    return { value: member._id, label: member.name }
  })
  return {
    currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
    suggestions: suggestions,
    currentTodoID: state.dashboard.currentTodo,
    currentTodo: returnCurrent(state.todos, state.dashboard.currentTodo),
    users: state.users
  }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(TodoItem)));