import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import clsx from 'clsx';

import { fetchDatePerTimezone } from '../../utils/utility';
import { fetchSettings, updateSettings, manualReset } from '../../redux/actions/invoice/proforma';
import { fetchProformaInfo } from '../../redux/selectors/invoiceProforma';

import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import ProformaStats from './invoice/ProformaStats';
import TaxReceipts from './invoice/TaxReceipts';

const useStyles = makeStyles( theme => ({
  listSection: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      margin: 'unset'
    }
  },
  label: {
    fontSize: '1.2rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10
  },
  subLabel: {
    fontSize: '1.1rem',
    color: '#666666',
    fontWeight: 400
  },
  newLabel: {
    fontSize: '1.2rem',
    color: '#666666',
    fontWeight: 400
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex'
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 35,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%',
    paddingTop: 2
  },
  picContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex'
  },
  placeholder: {
    marginTop: 15,
    background: '#F6F2EE',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0
  },
  noDisplay: {
    display: 'none'
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80
  },
  topMargin: {
    marginTop: 25
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  newButton: {
    background: '#E0E0E0',
    borderRadius: 25,
    color: '#000000',
    cursor: 'pointer',
    fontSize: '1.25rem',
    textAlign: 'center',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  resetbutton: {
    background: '#ffffff',
    borderRadius: 25,
    boxShadow: "0 1px 10px #E0E0E0",
    color: '#666666',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  select: {
    height: 35,
    paddingLeft: 20,
    width: 'calc(100% - 35px)',
  },
  triggerToggle: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    height: 35,
    justifyContent: 'center',
    width: '50%',
    color: '#666666',
    fontWeight: 400,
    background: '#ffffff',
    '&:hover': {
      background: '#dddddd'
    }
  },
  emailLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  emailRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  options: {
    borderRadius: 10,
    boxShadow: '0 2px 30px #F0F0F0',
    paddingLeft: 10,
    paddingRight: 10,
    '& input': {
      borderRadius: 10,
      color: '#484848',
      fontWeight: 500,
    }
  },
  firstRow: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
    marginBottom: 20,
  },
  initialRow: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 30,
    marginBottom: 30,
  },
  altButton: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 25,
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center'
  },
  flexLink: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  start: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: 20
  },
  invoiceTab: {
    alignItems: 'center',
    // boxShadow: '0 1px 30px 0 #E0E0E0',
    background: '#f8f9fb',
    borderRadius: '5px 10px',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 5,
    marginRight: 20,
    minWidth: '70px'
  },
  selected: {
    fontWeight: 600,
    background: '#CCCCCC',
  },
  loadingState: {
    alignItems: 'center',
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: '90%',
    position: 'absolute'
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: 'none',
    width: '100%',
    minWidth: '160px',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
}

const resetOptions = [
  { label: 'Month', value: 'MONTH' },
  { label: 'Year', value: 'YEAR' },
  { label: 'None', value: 'NONE' }
]

const InvoiceSettings = (props) => {
  const classes = useStyles()
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [initial, setInitial] = React.useState(true)
  const { property, currentSpace, loadingProperty, invoiceProforma, t, invoiceLoading } = props
  const [resetValue, setResetValue] = React.useState(null)
  const [proforma, setProforma] = React.useState({
    issuerName: '',
    prefix: '',
    suffix: '',
    startingIndexNumber: '',
    customText: '',
    proformaSuffix: '',
    proformaPrefix: '', 
    proformaDigits: '',
    invoiceDigits: ''
  })
  const [showToggle, setShowToggle] = React.useState({ showLegalName: false, showTaxInfo: false })
  const [logoPreview, setLogoPreview] = React.useState(null)
  const [selectedLogo, setSelectedLogo] = React.useState()
  const [startCountDate, setStartCountDate] = React.useState(null)
  const [lastResetDate, setLastResetDate] = React.useState(null)
  const [focus, setFocus] = React.useState({countDate: null, resetDate: null})
  const [saveToggle, setSave] = React.useState(false)
  const [today, setToday] = React.useState(false)
  const [message, setMessage] = React.useState('')
  // const [buttonClick, setButtonClick] = React.useState(false)
  const [fixLength, setFixLength] = React.useState({ invoiceDigits: false, proformaDigits: false })

  const proformaData = useSelector((state) => fetchProformaInfo(state.invoiceProforma))

  const [selectedTab, setSelectedTab] = React.useState('proforma')

  if((initial) && (!invoiceProforma.default)) {
    setInitial(false)
    if(invoiceProforma.logoImage) {
      setLogoPreview(`${invoiceProforma.logoImage}`)
    }

    if(invoiceProforma.countResetOn) {
      for(var i = 0; i < resetOptions.length; i++){
        if(resetOptions[i].value === invoiceProforma.countResetOn){
          setResetValue({...resetValue, value: resetOptions[i].value, label: resetOptions[i].label })
        }
      }
    }

    if(invoiceProforma.startCountDate) {
      const dummyDate = moment(invoiceProforma.startCountDate, "YYYY-MM-DD[T]HH:mm:ss")
      setStartCountDate(dummyDate)
    }

    if(invoiceProforma.lastResetDate) {
      const newDate = moment(invoiceProforma.lastResetDate, "YYYY-MM-DD[T]HH:mm:ss").format("DD MMM YYYY")
      setLastResetDate(newDate)
    }

    if(invoiceProforma.showLegalName) {
      setShowToggle({...showToggle, showLegalName: invoiceProforma.showLegalName})
    }

    if(invoiceProforma.showTaxInfo) {
      setShowToggle({...showToggle, showTaxInfo: invoiceProforma.showTaxInfo})
    }

    if(proformaData)
      setProforma({...proforma, ...proformaData})

    let fix = { ...fixLength }
    if(proformaData.invoiceDigits > 0)
      fix['invoiceDigits'] = true

    if(proformaData.proformaDigits > 0)
      fix['proformaDigits'] = true

    setFixLength(fix)
  }

  useEffect(() => {
    if (!selectedLogo) {
      return
    }

    const objectUrl = URL.createObjectURL(selectedLogo);
    setLogoPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedLogo])

  const handleChange = (e) => {
    if(e.target.name === 'startingIndexNumber' || e.target.name === 'proformaDigits' || e.target.name === 'finalIndexNumber' || e.target.name === 'invoiceDigits') {
      if(e.target.value < 0) setProforma({...proforma, [e.target.name]: 0})
      else setProforma({...proforma, [e.target.name]: e.target.value})
    }
    else
      setProforma({...proforma, [e.target.name]: e.target.value})
    setSave(true)
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedLogo(undefined)
    }
    if ((e.target.files[0].size / (1024 * 1024)) < 2) {
      setSave(true)
      setSelectedLogo(e.target.files[0])
      return
    } 
    else {
      console.log('Error')
    }
  }

  const handleRemovePicture = () => {
    setLogoPreview(null)
    setSelectedLogo(null)
    setSave(true)
  }

  const setResetCount = (e) => {
    setSave(true)
    setResetValue({...resetValue, label: e.label, value: e.value})
  }

  const handleToggleChange = (type, value) => {
    setShowToggle({ ...showToggle, [type]: value })
    setSave(true)
  }

  const onDateChange = (dateInfo, type) => {
    setStartCountDate(dateInfo)
    setSave(true)
  }

  const handleSave = () => {
    const data = {
      ...proforma,
      countResetOn: resetValue.value,
      startCountDate: startCountDate.format("YYYY-MM-DD[T]HH:mm:ss"),
      showLegalName: showToggle.showLegalName,
      showTaxInfo: showToggle.showTaxInfo
    }

    if(selectedLogo) {
      data.logoImage = selectedLogo
    }

    props.dispatch(updateSettings(currentSpace.propertyID, data))
    .then((response) => {
      if(response) {
        setMessage('Invoice settings saved!')
        setOpenSnackbar(true)
        setSave(false)
      }
    })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleSetToday = () => {
    setStartCountDate((fetchDatePerTimezone(null, currentSpace.timezone)))
    setToday(true)
    setSave(true)
  }

  const handleManualReset = () => {
    props.dispatch(manualReset(currentSpace.propertyID))
    setMessage('Manually resetting issued count.')
    setOpenSnackbar(true)
    // setButtonClick(false)
  }

  const handleTabSelect = (tab) => {
    if(selectedTab !== tab)
      setSelectedTab(tab)
  }

  const handleFixLength = (type) => {
    const fix = { ...fixLength }
    const proformaData = { ...proforma }
    if(fix[type])
      proformaData[type] = 0

    fix[type] = !fix[type]
    setFixLength(fix)
    setProforma(proformaData)
    setSave(true)
  }

  const fetchInvoiceNumber = (digits, number, prefix, suffix) => {
    let numStr = number.toString()

    if(numStr.length < digits) {
        let predecessor = ''
        for (var i = 0; i < digits - numStr.length; i ++){
            predecessor = predecessor + '0'
        }
        numStr = predecessor + numStr
    }

    return `${prefix}/${numStr}/${suffix}`
  }

  return (
    <>
      {invoiceLoading && <div className={"loadingOverlay"}>
        <CircularProgress className={"loading"} />
      </div>}

      <div className={classes.listSection}>

        <ProformaStats 
          handleManualReset={handleManualReset}
          issuedCount={invoiceProforma.proformaIssuedCount}
          finalCount={invoiceProforma.finalIssuedCount}
          taxIssuedCount={invoiceProforma.taxIssuedCount}
          lastRestDate={lastResetDate} 
        />

        <Divider className={classes.firstRow}/>

        {/* -------------------------TABS [ proforma, invoice ]----------------------- */}
        <div className={classes.flexLink}>
          <div className={classes.start}>
            <div
              onClick={() => handleTabSelect('proforma')}
              className={clsx({[classes.invoiceTab]: true, [classes.selected]: selectedTab==='proforma'})}>
              <p>Proforma</p>
            </div>
            <div
              onClick={() => handleTabSelect('invoice')}
              className={clsx({[classes.invoiceTab]: true, [classes.selected]: selectedTab==='invoice'})}>
              <p>Invoice</p>
            </div>
            <div
              onClick={() => handleTabSelect('taxReceipts')}
              className={clsx({[classes.invoiceTab]: true, [classes.selected]: selectedTab==='taxReceipts'})}>
              <p>Tax Reciepts</p>
            </div>
          </div>
        </div>

        {/* -------------------------TAB BODY [ proforma, invoice, taxReceipts ]----------------------- */}

        {selectedTab ==='taxReceipts' ? <TaxReceipts /> : (
          <>
            <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.label}>
                  {t('invoice.issuerName')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="issuerName"
                    name="issuerName"
                    value={`${proforma.issuerName}`}
                    fullWidth
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
            </Grid>
            {selectedTab === 'invoice' && <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.label}>
                  Invoice {t('invoice.prefix')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="prefix"
                    name="prefix"
                    value={`${proforma.prefix}`}
                    fullWidth
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={classes.label}>
                  Invoice {t('invoice.suffix')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="suffix"
                    name="suffix"
                    value={`${proforma.suffix}`}
                    fullWidth
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'proforma' && <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.label}>
                  Proforma {t('invoice.prefix')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="proformaPrefix"
                    name="proformaPrefix"
                    value={`${proforma.proformaPrefix}`}
                    fullWidth
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={classes.label}>
                  Proforma {t('invoice.suffix')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="proformaSuffix"
                    name="proformaSuffix"
                    value={`${proforma.proformaSuffix}`}
                    fullWidth
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
            </Grid>}
            <Grid container alignItems="center">
              <Grid item xs={12} className={classes.topMargin}>
                <div className={classes.label}>{t('invoice.propertyLogo')}</div>
                <div className={classes.picContainer}>
                  <div className={classes.placeholder}>
                    {logoPreview && <img className={classes.image} src={logoPreview} />}
                  </div>
                  {!logoPreview &&
                    <label htmlFor="upload-logo">
                      <div className={clsx(classes.link, classes.label)}>{t('invoice.uploadLogo')}</div>
                    </label>
                  }
                  {logoPreview &&
                    <div className={clsx(classes.link, classes.label)} onClick={() => handleRemovePicture()}>
                      {t('actions.remove')}
                  </div>}
                  <input
                    accept="image/*"
                    className={classes.noDisplay}
                    id="upload-logo"
                    type="file"
                    onChange={(e) => handleCapture(e)}
                  />
                </div>
              </Grid>
            </Grid>
            {/*(!invoiceProforma.issuerName) && <p className={classes.label}>Please note that the default information will be used for invoice when there is no customization.</p>*/}
            {selectedTab === 'proforma' && <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={7}>
                <div className={classes.label}>
                  Starting Index for Proforma<sup style={{color: 'red'}}>*</sup>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="startingIndexNumber"
                    name="startingIndexNumber"
                    value={`${proforma.startingIndexNumber}`}
                    fullWidth
                    type="number"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'proforma' && <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={9}><span className={classes.label}>Fix the length of Proforma Number</span></Grid>
              <Grid item xs={3}>
                <div className={classes.flexEnd}>
                  <Checkbox
                    color="primary"
                    checked={fixLength.proformaDigits}
                    checkedIcon={<span className={classes.checkedIcon} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e)=>handleFixLength('proformaDigits')}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'proforma' && fixLength.proformaDigits && <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.label}>
                  Length of Proforma Number
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="proformaDigits"
                    name="proformaDigits"
                    value={`${proforma.proformaDigits}`}
                    fullWidth
                    type="number"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={clsx(classes.flexEnd, classes.subLabel)} style={{marginTop: 10}}>
                  Your proforma number would look like this - {fetchInvoiceNumber(proforma.proformaDigits, proforma.startingIndexNumber, proforma.proformaPrefix, proforma.proformaSuffix)}
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'invoice' && <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={7}>
                <div className={classes.label}>
                  Starting Index for Invoice<sup style={{color: 'red'}}>*</sup>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="finalIndexNumber"
                    name="finalIndexNumber"
                    value={`${proforma.finalIndexNumber}`}
                    fullWidth
                    type="number"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'invoice' && <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={9}><span className={classes.label}>Fix the length of Invoice Number</span></Grid>
              <Grid item xs={3}>
                <div className={classes.flexEnd}>
                  <Checkbox
                    color="primary"
                    checked={fixLength.invoiceDigits}
                    checkedIcon={<span className={classes.checkedIcon} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e)=>handleFixLength('invoiceDigits')}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
              </Grid>
            </Grid>}
            {selectedTab === 'invoice' && fixLength.invoiceDigits &&  <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className={classes.label}>
                  Length of Invoice Number
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.field}>
                  <InputBase 
                    id="invoiceDigits"
                    name="invoiceDigits"
                    value={`${proforma.invoiceDigits}`}
                    fullWidth
                    type="number"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={clsx(classes.flexEnd, classes.subLabel)} style={{marginTop: 10}}>
                  Your invoice number would look like this - {fetchInvoiceNumber(proforma.invoiceDigits, proforma.finalIndexNumber, proforma.prefix, proforma.suffix)}
                </div>
              </Grid>
            </Grid>}
            <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={7}>
                <p className={classes.label}>{t('invoice.countReset')}</p>
              </Grid>
              <Grid item xs={5}>
                <div className={clsx(classes.toggle, classes.select)}>
                  <Select
                    value={resetValue}
                    styles={selectStyle}
                    options={resetOptions}
                    onChange={(e) => setResetCount(e)}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.topMargin}>
              <Grid item xs={7}>
                <p className={classes.label}>{t('invoice.legalName')}</p>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.toggle}>
                  <div className={clsx(classes.triggerToggle, classes.emailLeft)}
                    style={{background: showToggle.showLegalName === false ? '#4F9581' : '#FFFFFF', color: showToggle.showLegalName === false ? '#FFFFFF' : '#666666'}}
                    onClick={(e) => handleToggleChange('showLegalName', false)} >
                    OFF
                  </div>
                  <div className={clsx(classes.triggerToggle, classes.emailRight)}
                    style={{background: showToggle.showLegalName === true ? '#4F9581' : '#FFFFFF', color: showToggle.showLegalName === true ? '#FFFFFF' : '#666666'}} 
                    onClick={(e) => handleToggleChange('showLegalName', true)} >
                    ON
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{marginTop: 15}}>
              <Grid item xs={7}>
                <p className={classes.label}>{t('invoice.taxInfo')}</p>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.toggle}>
                  <div className={clsx(classes.triggerToggle, classes.emailLeft)}
                    style={{background: showToggle.showTaxInfo === false ? '#4F9581' : '#FFFFFF', color: showToggle.showTaxInfo === false ? '#FFFFFF' : '#666666'}}
                    onClick={(e) => handleToggleChange('showTaxInfo', false)} >
                    OFF
                  </div>
                  <div className={clsx(classes.triggerToggle, classes.emailRight)}
                    style={{background: showToggle.showTaxInfo === true ? '#4F9581' : '#FFFFFF', color: showToggle.showTaxInfo === true ? '#FFFFFF' : '#666666'}} 
                    onClick={(e) => handleToggleChange('showTaxInfo', true)} >
                    ON
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{marginTop: 15}}>
              <Grid item xs={4}>
                <p className={classes.label}>{t('invoice.countDate')}<sup style={{color: 'red'}}>*</sup></p>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.options}>
                  <SingleDatePicker
                    date={startCountDate}    
                    onDateChange={(e) => onDateChange(e, 'startCountDate')}
                    focused={focus.countDate} 
                    onFocusChange={({ focused }) => setFocus({...focus, countDate: focused})} 
                    id="startCountDate"
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat={'DD MMM YYYY'}
                    noBorder={true}
                  />
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className={classes.altButton}
                  style={{background: today === false ? '#FFFFFF' : '#E0E0E0'}}
                  onClick={handleSetToday}>
                  {t('invoice.setToday')}
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{marginTop: 15}}>
              <Grid item xs={12}>
                <p className={classes.label}>{t('invoice.customText')}</p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="customText"
                  name="customText"
                  fullWidth
                  rowsMax={6}
                  label="Custom Text goes here"
                  type="text"
                  multiline={true}
                  onChange={(e) => handleChange(e)}
                  value={proforma.customText}
                />
              </Grid>
            </Grid>
            {saveToggle && <div className={classes.buttonsContainer}>
              <div className={classes.button} onClick={handleSave}>
                {t('actions.save')}
              </div>
            </div>}
            {(message) && <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              key='top-center'
              autoHideDuration={3000}
              open={openSnackbar}
              onClose={handleCloseSnackbar}
              message={message}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />}
          </>
        )}
        
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  loadingProperty: state.loading.LOAD_PROPERTY,
  invoiceProforma: state.invoiceProforma,
  invoiceLoading: state.loading.UPDATE_INVOICE_SETTINGS
})

export default withTranslation()(connect(mapStateToProps)(InvoiceSettings))