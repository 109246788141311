/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-return */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/order */
import http from "../../utils/http";
import axios from "axios";
import { normalize } from 'normalizr';
import { roomSchema } from "../../schema/roomTypeSchema";

//----------------------------------------------------------------
// CREATE ROOM 
//----------------------------------------------------------------
export function createRoom(spaceID, room) {
  const ACTION_NAME = "CREATE_ROOM";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/frontdesk/rooms', { ...room })
      .then(successResult => {
        const data = successResult.data;
        let roomBeds = data.beds.map(bed => bed._id);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            spaceID,
            data: { ...data.room, beds: roomBeds },
            beds: data.beds
          }
        });
        return data
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return
      });
  };
}

//----------------------------------------------------------------
// EDIT ROOM
//----------------------------------------------------------------
export function editRoom(roomID, updatedRoom) {
  const ACTION_NAME = "EDIT_ROOM";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/rooms/${roomID}`, { ...updatedRoom })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { name: error.name, statusCode: error.statusCode, message: error } });
        return;
      });
  };
}

//----------------------------------------------------------------
// DELETE ROOM
//----------------------------------------------------------------
export function deleteRoom(spaceID, roomID, dataObject) {
  const ACTION_NAME = "DELETE_ROOM";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/rooms/${roomID}`, { data: { ...dataObject } })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { spaceID, ...data } });
        return true
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

// ----------------------------------------------------------------
// LOAD ROOMS
// ----------------------------------------------------------------
export function getRooms(propID) {
  const ACTION_NAME = "LOAD_ROOMS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/rooms/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        var normalizedOrder = normalize(data, [roomSchema]);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...normalizedOrder.entities } });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD ALL ROOM SUGGESTION
//----------------------------------------------------------------
export function loadRoomSuggestion(propID, params) {
  const ACTION_NAME = "LOAD_ROOM_SUGGESTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      // .get(`/api/frontdesk/reservation/suggest/new/${propID}`, { params })
      .get(`/api/frontdesk/reservation/suggest/bedScore/${propID}`, { params })
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            suggestions: successResult.data.suggestions,
            prices: successResult.data.prices
          }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// RESET ALL ROOM SUGGESTION
//----------------------------------------------------------------
export function resetRoomSuggestion() {
  return {
    type: "RESET_ROOM_SUGGESTION",
    payload: []
  };
}

//----------------------------------------------------------------
// UPLOAD ROOM IMAGE
//----------------------------------------------------------------
export function uploadRoomPhoto(payload) {
  const ACTION_NAME = "UPLOAD_ROOM_PHOTO";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/frontdesk/rooms/images/upload", {
        propertyID: payload.propID,
        roomID: payload.roomID,
        name: payload.name,
        type: payload.type,
      })
      .then(successResult => {
        const docInfo = successResult.data;
        const key = docInfo.key;
        const url = docInfo.url;
        const options = {
          headers: {
            "Content-Type": payload.type
          }
        };
        axios.put(url, payload.file, options)
          .then(() => {
            http.post("/api/frontdesk/rooms/images/save", {
              roomID: payload.roomID,
              key: key
            }).then(response => {
              let data = response.data;
              dispatch({
                type: `${ACTION_NAME}_SUCCESS`,
                payload: { images: data.images },
                roomID: payload.roomID
              });
            }).catch(error => {
              return error;
            });
        
          })
          .catch(error => {
            return error;
          });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// LOAD MANUAL OPTIONS
//----------------------------------------------------------------
export function loadReservationManualOptions(propertyID, params) {
  const ACTION_NAME = "LOAD_RESERVATION_MANUAL_OPTIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reservation/manualOptions/${propertyID}`, { params })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// RESET RESERVATION MANUAL OPTIONS
//----------------------------------------------------------------
export const resetReservationManualOptions = () => (dispatch) => {
  dispatch({ type: "RESET_RESERVATION_MANUAL_OPTIONS" })
};


//----------------------------------------------------------------
// DELETE ROOM MAPPING
//----------------------------------------------------------------
export function deleteRoomMapping(mappingID, roomTypeID) {
  const ACTION_NAME = "DELETE_ROOMS_MAPPING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/rooms/mapping/${mappingID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data, affectedRoomTypeID: roomTypeID } });
        return data;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

export function uploadRoomImage() {

}
export function saveRoomImage() {

}

export function RoomImageUploadStatus() {

}


