import React, { forwardRef } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import IMAGES from '../../constants/images';

import {
  makeStyles,
  InputBase,
  Grid,
  Chip,
  Paper,
} from '@material-ui/core';
// import { ICONS } from '../../utils/imageUrls';

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 30,
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  field: {
    color: '#333333',
    fontSize: '1.4rem',
    fontWeight: 600,
    background: '#ffffff',
    borderRadius: '5px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)'
  },
  tabSection: {
    display: 'flex',
    marginTop: 30
  },
  tabName: {
    background: '#ffffff',
    borderRadius: 5,
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 30,
    marginRight: 15,
    padding: theme.spacing(2),
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75) !important',
    width: 100,
    textAlign: 'center'
  },
  fieldRow: {
    margin: '30px 0 20px',
  },
  blockItems: {
    cursor: 'pointer',
    display: 'flex',
    padding: '24px',
    fontSize: '1.3rem',
    background: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 20
    }
  },
  icon: {
    height: 12,
    width: 12,
    margin: 0
  },
  blockIcon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20
    }
  },
  blockLabel: {
    marginTop: 20
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: 'none',
    marginBottom: 10
  },
  chip: {
    margin: theme.spacing(0.5),
    background: '#fff',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75) !important',
    padding: 20,
    borderRadius: 5,
    '&:hover': {
      background: '#E0E0E0',
    },
    '&:focus': {
      background: '#fff',
    },
    '& svg.MuiSvgIcon-root.MuiChip-deleteIcon':{
      display: 'none'
    }
  },
  selected: {
    background: '#E0E0E0!important',
  },
  DateRangePicker: {
    width: '100%',
    '& > div, & > div > div, & > div > div > div': {
      width: '100%',
    },
    '& .DateInput_1': {
      width: '50%',
    },
    '& .DateRangePickerInput_arrow': {
      display: 'none'
    },
    '& .DateInput_input': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.87)',
      paddingLeft: 11,
      boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
      width: '96%',
      paddingRight: 0,
      height: '1.1875em',
      padding: '14px 5px 14px'
    },
    '& .DayPickerNavigation_button__verticalDefault': {
      width: 'calc(50% - 12px)',
      height: 'unset',
    },
    '& .DayPickerNavigation_svg__vertical': {
      height: 20,
      width: 20
    }
  },
}))

const blockCategory = [
  { value: 'event', label: 'Event', img: IMAGES.ICONS.darkBulb },
  { value: 'private', label: 'Private Use', img: IMAGES.ICONS.darkFlower },
  { value: 'season', label: 'Off Season', img: IMAGES.ICONS.darkHammock },
  { value: 'maintenance', label: 'Maintenance', img: IMAGES.ICONS.darkSupport },
  { value: 'other', label: 'Other', img: IMAGES.ICONS.darkMore },
]

const BedListUi = ({ classes, bedList, handleClick, handleDelete, roomBeds, selectedType }) => {
  return (<Paper component="ul" className={classes.section}>
    {bedList.map((data) => selectedType === data.type && (
      <li key={data.value}>
        <Chip
          label={data.label}
          onClick={() => handleClick(data.value)}
          onDelete={() => handleDelete(data.value)}
          deleteIcon={
            roomBeds.includes(data.value) ?
              <img src={IMAGES.ICONS.darkClose} className={classes.icon} />
              : null
          }
          className={clsx(classes.chip, { [classes.selected]: roomBeds.includes(data.value) })}
        />
      </li>
    ))}
  </Paper>)
}

const RoomBlock = forwardRef((props, ref) => {

  const classes = useStyles();
  const { bedList, roomTypeList, prefilledData, t } = props;
  const [dates, setDates] = React.useState({ startDate: null, endDate: null })
  const [label, setLabel] = React.useState('');
  const [tab, setTab] = React.useState('room');
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [roomBeds, setRoomBeds] = React.useState([]);
  const [type, setType] = React.useState(roomTypeList?.length === 1 ? roomTypeList[0].value: null);
  const smallDevice = window.matchMedia('(max-width: 450px)').matches;
  const orientation = smallDevice ? "vertical" : "horizontal";

  React.useImperativeHandle(ref, () => ({
    handleProcessData() {
      return {
        dates,
        tab,
        type,
        label,
        category,
        roomBeds
      }
    }
  }))

  React.useEffect(() => {
    if (prefilledData){
      if (prefilledData.dates) setDates(prefilledData.dates)
      if (prefilledData.tab) setTab(prefilledData.tab)
      if (prefilledData.label) setLabel(prefilledData.label);
      if (prefilledData.category) setCategory(prefilledData.category)
      if (prefilledData.roomBeds) setRoomBeds(prefilledData.roomBeds)
      if (prefilledData.type) setType(prefilledData.type)
    }
  }, [prefilledData])

  const handleDelete = (value) => {
    console.info('You clicked the delete icon.', value);
    let bedIds = roomBeds.filter(ids => ids !== value);
    setRoomBeds(bedIds)
  };

  const handleClick = (value) => {
    console.info('You clicked the Chip.', value);
    if(!roomBeds.includes(value)) {
      setRoomBeds(prevRoomBeds => ([...prevRoomBeds, value]));
    }
  };

  const handleSelectTab = (value) => {
    setTab(value)
    setType(roomTypeList?.length === 1 ? roomTypeList[0].value: null)
    if (prefilledData?.roomBeds)
      setRoomBeds(prefilledData.roomBeds)
    else
      setRoomBeds([])
  }

  return (
    <div className={classes.setRateContainer}>

      <div className={classes.tabSection}>
        <div
          className={clsx({ [classes.tabName]: true, [classes.selected]: tab === 'room' })}
          onClick={() => handleSelectTab('room')}
        >
          {t('bookingCalendar.entireRoom')}
      </div>
        <div
          className={clsx({ [classes.tabName]: true, [classes.selected]: tab === 'bed' })}
          onClick={() => handleSelectTab('bed')}
        >
          {t('bookingCalendar.bed')}
      </div>
      </div>

      {roomTypeList.length > 1 &&
        <Paper component="ul" className={classes.section}>
          {roomTypeList.map((data) => (
            <li key={data.value}>
              <Chip
                label={data.label}
                onClick={() => { setType(data.value); setRoomBeds([]) }}
                className={clsx(classes.chip, { [classes.selected]: type == data.value })}
              />
            </li>
          ))}
        </Paper>
      }

      {tab === 'bed' && type &&
        <BedListUi
          classes={classes}
          bedList={bedList}
          selectedType={type}
          handleClick={handleClick}
          handleDelete={handleDelete}
          roomBeds={roomBeds}
        />
      }

      <Grid container>
        <Grid item xs={12} sm={8}>
          <div className={classes.fieldRow}>
            <div className={classes.label}>{t('bookingCalendar.dateRange')}</div>
          </div>
          <div className={classes.DateRangePicker}>
            <DateRangePicker
              startDate={dates.startDate}
              startDateId="start_date"
              endDate={dates.endDate}
              endDateId="end_date"
              onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
              noBorder={true}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              hideKeyboardShortcutsPanel={true}
              verticalHeight={370}
              isOutsideRange={day => false}
              orientation={orientation}
              displayFormat={'DD MMM YYYY'}
              numberOfMonths={smallDevice ? 1 : 2}
            />
          </div>
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} sm={8}>
          <div className={classes.fieldRow}>
            <div className={classes.label}>{t('fields.name')}</div>
          </div>
          <div className={classes.field}>
            <InputBase
              type="text"
              id="name"
              fullWidth
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <div className={classes.fieldRow}>
            <div className={classes.label}>{t('bookingCalendar.reservationSource')}</div>
          </div>
        </Grid>

        {blockCategory.map((data, categoryIndex) => (
          <Grid item xs={6} sm={3} md={2} key={categoryIndex}>
            <div
              className={clsx({
                [classes.blockItems]: true,
                [classes.selected]: category === data.value
              })}
              onClick={() => setCategory(data.value)}>
              <img className={classes.blockIcon} src={data.img} />
              <span className={classes.blockLabel}>{data.label}</span>
            </div>
          </Grid>
        ))}
      </Grid>

    </div>
  )
})

export default withTranslation('translation', { withRef: true })(RoomBlock);