import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Typography, CardActionArea } from "@material-ui/core";
import RatesAvailability from "./Rates_Availability";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    width: "100%",
  },
  card: {
    alignItems: "flex-start",
    display: "flex",
    width: "100%",
  },
  cardAction: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
  },
  header: {
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "200px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
  blankSlate: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "250px",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
  highlight: {
    color: "#666666",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem",
    },
  },
  description: {
    color: "#666666",
  },
  maxLOS: {
    textAlign: "center",
  },
}));

const RateSnapshot = (props) => {
  const { t } = props;
  const classes = useStyles();

  const [showRateModal, setShowRateModal] = React.useState(false);

  const handleOpenRateModal = () => {
    setShowRateModal(true);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: true });
  };

  const handleCloseRateModal = () => {
    setShowRateModal(false);
    window.Intercom &&
      window.Intercom("update", { hide_default_launcher: false });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea
          className={classes.cardAction}
          onClick={handleOpenRateModal}
        >
          <div className={classes.header}>
            <Typography component="h4" variant="h6">
              {t("experiences.manage.rate.header")}
            </Typography>
          </div>

          <div className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h3" className={classes.highlight}>
                      100%
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <Typography variant="h6" className={classes.description}>
                      {t("experiences.manage.rate.subHeader1")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h3" className={classes.highlight}>
                      0%
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <Typography variant="h6" className={classes.description}>
                      {t("experiences.manage.rate.subHeader2")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardActionArea>
      </Card>

      {showRateModal && (
        <RatesAvailability closeModalHandler={handleCloseRateModal} />
      )}
    </div>
  );
};

export default withTranslation()(RateSnapshot);
