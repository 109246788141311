
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { initials } from '../utils/wordUtils';
import NewComment from '../comments/NewComment';
import CommentDisplay from '../utils/CommentDisplay';

import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  listContainer: {
    height: 'calc(100vh - 212px)',
    overflowY: 'scroll',
  },
  listSection: {
    margin: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  container: {
    borderRadius: 15,
    boxShadow: '0 2px 30px #E0E0E0',
    padding: theme.spacing(3),
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30
  },
  postedBy: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  name: {
    color: '#333333',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.2rem',
    fontWeight: 600,
    marginLeft: 10,
  },
  subTitle: {
    display: 'flex',
    fontSize: '1.2rem',
    color: '#666666',
    fontWeight: 500,
    marginTop: 5,
  },
  spacer: {
    marginRight: 5,
    marginLeft: 5,
  },
  title: {
    color: '#000000',
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: 1.4,
    marginBottom: 10
  },
  description: {
    color: '#333333',
    fontSize: '1.3rem',
    lineHeight: 1.4,
  },
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  commentHolder: {
    alignContent: "center",
    display: "flex",
    justifyContent: 'flex-start',
    marginBottom: 10,
    width: "100%"
  },
  avatarContainer: {
    position: 'relative',
    width: '60px'
  },
  avatar: {
    backgroundColor: '#bdbdbd',
    border: '1px solid #dddddd',
    color: '#ffffff',
    fontSize: '2rem',
    lineHeight: 2,
    position: 'absolute',
    textAlign: 'center',
    top: '10px'
  },
  comment: {
    background: '#F2F2F2',
    borderRadius: '10px',
    padding: theme.spacing(2),
    width: "100%"
  },
  commentHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  commenter: {
    '& h6': {
      fontWeight: 600
    }
  },
  time: {
    color: '#666666',
    fontSize: '1.2rem'
  },
  commentBody: {
    whiteSpace: "pre-wrap"
  }
}))

const AddAnnouncement = (props) => {
  const { t } = props
  const classes = useStyles();
  const { open, handleClose, announcementID, announcements, comments, users } = props;
  const announcement = announcements[announcementID];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="md"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* HEADER */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <div className={classes.header}>{t('noticeboard.name')}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        {/* --------------------------------------------------------- */}
        {/* ANNOUNCEMENT */}
        {/* --------------------------------------------------------- */}
        <div className={classes.listContainer}>
          <div className={classes.listSection}>

            <div className={classes.container}>
              <div className={classes.title}>
                {announcement.title}
              </div>
              <div className={classes.row}>
                <CommentDisplay editorContent={announcement.body} />
              </div>
              <div className={classes.postedBy}>
                <Avatar 
                  alt={initials(announcement.postedBy.name)}
                  src={announcement.postedBy.avatar} 
                >
                  {initials(announcement.postedBy.name)}
                </Avatar>
                <div className={classes.name}>
                  {announcement.postedBy.name}
                  <span className={classes.subTitle}>
                    <span className={classes.createdAt}>{moment(announcement.createdAt).format('DD MMM YYYY')}</span>
                  </span>
                </div>
              </div>

            </div>

            {/* --------------------------------------------------------- */}
            {/* COMMENT LIST */}
            {/* --------------------------------------------------------- */}
            {announcement.comments.map(commentID => {
              const comment = comments[commentID];
              return (
                <div className={classes.commentHolder} key={comment._id}>
                  <div className={classes.avatarContainer}>
                    <Avatar 
                      alt={initials(users[comment.addedBy].name)}
                      src={users[comment.addedBy].avatar} 
                      className={classes.avatar} 
                    >
                      {initials(users[comment.addedBy].name)}
                    </Avatar>
                  </div>
                  <div className={classes.comment}>
                    <div className={classes.commentHeader}>
                      <div className={classes.commenter} ><Typography variant="h6">{users[comment.addedBy].name}</Typography></div>
                      <div className={classes.time}>
                        {moment(comment.createdAt).isSame(moment(), 'day') ?
                        `Today, ${moment(comment.createdAt).format('h:mma')}` :
                        moment(comment.createdAt).format('DD MMM')}
                      </div>
                    </div>
                    
                    <CommentDisplay editorContent={comment.body} />
                  </div>
                </div>
              )
            })}


            {/* --------------------------------------------------------- */}
            {/* NEW COMMENT */}
            {/* --------------------------------------------------------- */}
            <NewComment 
              announcementID={announcementID} 
              placeholder={t('noticeboard.comment')}
              slatePlugins = {['hyperLink']}
            />

          </div>

        </div>

      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  announcements: state.announcements,
  comments: state.comments,
  users: state.users
})

export default withTranslation()(connect(mapStateToProps)(AddAnnouncement));



