import React from 'react';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { getAmtFormat } from "../../../../utils/utility";
import ConfirmForm from '../../../common/ConfirmForm';


const UpsellSummary = (props) => {

  const { admin, currency, reservation, upsellItems, classes, t } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  const handleConfirmFormClose = () => {
    setSelectedID(null);
    setConfirmOpen(false);
  }

  const handleConfirmDelete = () => {
    props.deleteUpsellItem([{ upsellID: selectedID, count: 0 }])
  }

  const itemView = reservation.upsellList && reservation.upsellList.map((item, index) => {
    const upsellData = { ...upsellItems[item.upsellID] }
    return item.count ? (
      <div className={classes.roomItemContainer} key={`upsell_${index}`}>
        <div className={classes.itemDesc}>
          <div style={{ display: 'flex' }}>
            <div>
              <Typography variant="body1" className={classes.roomName}>{upsellData.name}</Typography>
              <Typography variant="body2" className={classes.secondaryText}>
                {item.count} {t('existingReservation.summaryTab.items')} - {getAmtFormat(item.amount / item.count, currency)} {t('existingReservation.summaryTab.perItem')}
              </Typography>
              {(admin && !confirmOpen) ? <div className={classes.deleteText} onClick={() => { setSelectedID(item.upsellID); setConfirmOpen(true); }}>Delete</div> : null}
            </div>
          </div>
          <div className={classes.priceContainer}>
            <Typography variant="body1" className={classes.price}>{item.amount ? getAmtFormat(item.amount, currency) : null}</Typography>
          </div>
        </div>
        <ConfirmForm
          open={confirmOpen && selectedID === item.upsellID}
          setOpen={setConfirmOpen}
          onClose={handleConfirmFormClose}
          onConfirm={handleConfirmDelete}
        >
          Are you sure, you would like to delete this item?
        </ConfirmForm>
        <hr className={classes.divider} />
      </div>
    ) : null
  });

  return itemView;
}

export default withTranslation()(UpsellSummary);