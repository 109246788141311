import { editRatePlan } from "../../actions/rates/ratePlans";

const ratePlans = {default: true};

export default (state = ratePlans, action) => {
  switch (action.type) {
    case "LOAD_RATEPLANS_SUCCESS":
      return {
        ...action.payload
      };
    case "UPDATE_RATE_PLANS":
      return {
        ...state,
        ...action.payload
      };
    case "ADD_RATE_PLAN_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      };
    case "EDIT_RATE_PLAN_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      };
    case "DELETE_RATE_PLAN_SUCCESS":
      let deletedRatePlan = state[action.payload]
      return {
        ...state,
        [action.payload]: {...deletedRatePlan, isDeleted: true}
      };
    case "ADD_CHANNEL_RATEPLAN_MAPPING_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "DELETE_CHANNEL_RATEPLAN_MAPPING_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "RESET_RATEPLANS": 
      return ratePlans
    case "USER_LOGOUT":
      return ratePlans
    default:
      return state;
  }
};