const productCategory = {};

export default (state = productCategory, action) => {
  switch (action.type) {
    case "LOAD_PRODUCT_CATEGORIES_SUCCESS":
      return {
        ...state,
        ...action.payload.productCategory
      };
    case "CREATE_PRODUCT_CATEGORY_SUCCESS":
      if (state === {}) {
        return {
          [action.payload._id]: { ...action.payload }
        }
      } else return {
        ...state,
        [action.payload._id]: { ...action.payload }
      }

    case "DELETE_PRODUCT_CATEGORY_SUCCESS":
      const deleteProductCategory = state[action.payload.productCategoryID]
      return {
        ...state,
        [action.payload.productCategoryID]: { ...deleteProductCategory, ...action.payload.productCategory }
      }

    case "EDIT_PRODUCT_CATEGORY_SUCCESS":
      const productCategory = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: { ...productCategory, ...action.payload }
      }
    case "CREATE_PRODUCT_SUCCESS":
      const createProductCategory = state[action.payload.productCategoryID];
      createProductCategory.products = createProductCategory.products || [];
      return {
        ...state,
        [action.payload.productCategoryID]: {
          ...createProductCategory,
          products: [...createProductCategory.products, action.payload._id],
        }
      }

    case "DELETE_PRODUCT_SUCCESS":
      const deleteProduct = state[action.payload.productCategoryID];
      let typeProducts = deleteProduct.products || [];
      typeProducts = typeProducts.filter(id => id !== action.payload._id);
      return {
        ...state,
        [action.payload.productCategoryID]: {
          ...deleteProduct,
          products: typeProducts
        }
      }
    case "USER_LOGOUT":
      return {}
    default:
      return state;
  }
};