import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles( theme => ({
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10,
    textAlign: 'center'
  }
}))

const Upsell = (props) => {
  const { t } = props
  const classes = useStyles()

  return (
    <div className={classes.label}>
      {t('automate.onlineCheckin.steps.upsell.detail')}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
})

export default withTranslation()(connect(mapStateToProps)(Upsell))