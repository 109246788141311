import XLSX from 'sheetjs-style-v2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { currencyToNumber, fetchCurrencySymbol } from '../../utils/utility';

const headerStyle = { // set the style for target cell
  font: {
      bold: true
  },
  fill: {
      fgColor: {rgb: 'FFECF0E0'},
  },
  alignment: {
      horizontal: "center",
      vertical: 'center'
  }
};

export const exportExcel = (filename, headerConfig, data, headerType=[], excelColWidth=[], currencyData, totalRow = false) =>{

    const header = headerConfig.map(header => header.title);
    const currencySymbol = (currencyData && currencyData.code) ? fetchCurrencySymbol(currencyData.code) : '';

    const excelData = data.map(row => {

        const formattedData = {};

        headerConfig.forEach(header => {
            formattedData[ header.title ] = row[ header.field ];
        });

        return { ...formattedData };
    });

    const wb = XLSX.utils.book_new();

    const wsAll = XLSX.utils.json_to_sheet(excelData, { header });

    // Header Format
    for(var C = 0; C < headerType.length; ++C) {
        var cell_address = {c:C, r:0};
        /* if an A1-style address is needed, encode the address */
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        wsAll[cell_ref].s = headerStyle;

        if (totalRow) {
            var cell_address2 = {c:C, r:data.length};
            /* if an A1-style address is needed, encode the address */
            var cell_ref = XLSX.utils.encode_cell(cell_address2);
            wsAll[cell_ref].s = { // set the style for target cell
                font: {
                    bold: true
                },
                border:{
                    top: { style: 'thin', color: '#FF666666' }
                },
                alignment: {
                    vertical: 'center'
                }
            };
        }
    }

    // Column Width
    var wscols = [];
    excelColWidth.length!==0 && excelColWidth.map(colWidth => {
        wscols.push({wch: colWidth})
    })
    if (wscols.length > 0) wsAll['!cols'] = wscols;

    wsAll['!rows'] = [{hpx: 30}];

    // Cell Format
    headerType.length!==0 && headerType.map((headerItem,index) => {
        let t = '';
        let z = '';
        if (headerItem=='date') {
            t='d';
            z='d-mmm-yy';
        } else if (headerItem=='wholeNumber') {
            t='n';
            z='#,##0';
        } else if (headerItem=='number') {
            t='n';
            z='0.00';
        } else if (headerItem=='currency') {
            t='n';
            // z=`${currencySymbol}#,##0.00`;
            z='0.00';
        } else if (headerItem=='percentage') {
            t='n';
            z='0.00%';
        } else return // headerItem=='string'

        for(var R = 1; R <= data.length; ++R) {
            var cell_address = {c:index, r:R};
            /* if an A1-style address is needed, encode the address */
            var cell_ref = XLSX.utils.encode_cell(cell_address);
            var cell = wsAll[cell_ref];
            if(!cell) continue; // only format numeric cells
            cell.t = t;
            if (z !== '') cell.z = z;
            if (headerItem == 'currency') cell.v = currencyToNumber(currencyData, cell.v);
            if (headerItem == 'percentage') {
                let str = cell.v;
                str = str.slice(0, -1);
                cell.v = parseFloat(str)/100;
            }
        }
    });

    XLSX.utils.book_append_sheet(wb, wsAll);

    if (filename.startsWith('Reservations')) {
      const wsNotes = getCommunicationdData(data, 'notes');
      const wsMessages = getCommunicationdData(data, 'guestComments');
      if(wsMessages['!cols'].length > 3)
        XLSX.utils.book_append_sheet(wb, wsMessages, 'Messages');
      if(wsNotes['!cols'].length > 3)
        XLSX.utils.book_append_sheet(wb, wsNotes, 'Notes');
    }

    XLSX.writeFile(wb, `${filename}.xlsx`);
}

export function exportPdfTable(fileName = "report", columns = [], data = []) {
    if(!jsPDF) return;

    const pdfBodyData = data.map(rowData => columns.map(columnDef => rowData[columnDef.field]));

    //If Currency is INR  then replace the currency symbol with Rs.
    const pdfData =JSON.parse(JSON.stringify(pdfBodyData).replaceAll('₹', 'Rs.'))

    let content = {
        startY: 50,
        head: [columns.map(columnDef => columnDef.title)],
        body: pdfData||pdfBodyData,
    };

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    doc.text(fileName, 40, 40);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
}

export function groupingConfig({
    data: dataParam,
    excelHeaderType,
    excelColWidth,
    currencyData,
    tableTitle,
    displayDate,
    headerConfig: headerData,
    pdfHeaderData
}){
    const filename = `${tableTitle} ${displayDate}`;
    return {
        grouping: true,
        exportCsv: (columns, data) => {
            const headerConfig = headerData || [...columns];
            const tableData = data?.[0]?.groups ? cloneDeep(dataParam) : cloneDeep(data);
            exportExcel(filename, headerConfig, tableData, excelHeaderType, excelColWidth, currencyData);
        },
        exportPdf: (columns, data) => {
            const headerConfig = pdfHeaderData || headerData || [...columns];
            const tableData = data?.[0]?.groups ? cloneDeep(dataParam) : cloneDeep(data);
            exportPdfTable(filename, headerConfig, tableData);
        },
    }
}

function getCommunicationdData(data, key) {
  let formattedDataArray = data.map(row => {
    const formattedData = {};

    if (key == 'notes' && row[key] && row[key].length > 0) {
      formattedData['Reservation Code'] = row.resvCode;
      formattedData['Guest Name'] = row.name;
      formattedData['Email'] = row.email;
      let cnt = 1;
      row[key].forEach(item => {
        formattedData[`${key.slice(0, -1)} ${cnt}`] = item.note || item.comment;
        cnt++;
      });
    }
    if (key == 'guestComments') {
      if(
        (row.guestComments && row.guestComments.length > 0) ||
        (row.emails && row.emails.length > 0)
      ) {
        formattedData['Reservation Code'] = row.resvCode;
        formattedData['Guest Name'] = row.name;
        formattedData['Email'] = row.email;
        let cnt = 1;
        row.Messages?.forEach(message => {
            formattedData['Message ' + cnt] = message.comment;
            cnt++;
        });
        row.emails?.forEach(email => {
            formattedData['Message ' + cnt] = `Date: ${moment(email.createdAt).format('DD MMM YYYY')} \nFrom: ${email.from} \nTo: ${email.to} \nSubject: ${email.subject}`;
            cnt++;
        });
      }
    }

    return { ...formattedData };
  });

  let numberOfCols = 0;
  formattedDataArray = formattedDataArray.filter(message => Object.values(message).some(val => val !== ''));
  formattedDataArray.forEach(item => {
    numberOfCols = Math.max(numberOfCols, Object.keys(item).length);
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedDataArray, {});
  worksheet['!cols'] = [{ wch: 20 }, { wch: 40 }, { wch: 20 }];
  for (let i = 0; i < numberOfCols; i++) {
    const cell_address = { c: i, r: 0 };
    const cell_ref = XLSX.utils.encode_cell(cell_address);
    worksheet[cell_ref].s = headerStyle;

    if (i > 2) worksheet['!cols'].push({ wch: 40 });
  }
  worksheet['!rows'] = [{ hpx: 30 }];

  return worksheet;
}
