

//----------------------------------------------------------------
// SAVE USERS WHEN SPACE LOADS
//----------------------------------------------------------------
export default (state = [], action) => {
  switch(action.type) {
    case "LOAD_SPACE_SUCCESS":
      return {
        ...state,
        ...action.payload.users
      }
    case "EDIT_USER_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "UPDATE_USER_AVATAR_SUCCESS":
      const updateUserAvatar = state[action.payload.userID]
      return {
        ...state,
        [action.payload.userID]: {...updateUserAvatar, avatar: action.payload.avatar}
      }
    case "INVITE_USER_SUCCESS":
      return {
        ...state,
        [action.payload.data._id]: action.payload.data
      }
    case "ADMIN_USER_ADD_SUCCESS": 
      return {
        ...state,
        ...action.payload.data
      }

    case "USER_LOGOUT":
      return {
        ...action.payload
      }

    //Update user store when a user is removed from a space... 
    case "EDIT_USER_ON_PERMISSION_UPDATE":
      return {
        ...state,
        [action.payload._id]: action.payload
      }

    default:
      return state;
  }
};

