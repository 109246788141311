import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD TODAYS RESERVATIONS
//----------------------------------------------------------------
export function loadTodaysReservations(spaceID, propID, date, status) {
  const ACTION_NAME = "LOAD_TODAYS_RESERVATIONS";
  return function(dispatch) {
  dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reports/reservations`, {propertyID: propID, startDate: date, endDate: date, status})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, spaceID} });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// RESET RESERVATIONS REPORT
//----------------------------------------------------------------
export const resetTodaysReservations = (spaceID) => ({
  type: 'RESET_TODAYS_RESERVATIONS',
  payload: {spaceID}
})