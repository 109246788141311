import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex'
    },
    label: {
        fontSize: '1.2rem',
        color: '#666666',
        fontWeight: 400,
        marginTop: 10
    },
    placeholder: {
        marginTop: 10,
        background: '#F6F2EE',
        borderRadius: '15px',
        height: 80,
        marginRight: 15,
        width: 80
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: 0
    },
    noDisplay: {
        display: 'none'
    },
    image: {
        borderRadius: 15,
        objectFit: 'cover',
        height: 80,
        width: 80
    },
}));

const ImageUpload = props =>{
    const classes = useStyles();
    const { selectedLogo, onChangeHandler } = props;
    const [logoPreview, setLogoPreview] = useState(null);

    useEffect(() => {

        if(!selectedLogo){
            setLogoPreview(null);
            return;
        }

        if (selectedLogo instanceof File) {
            const objectUrl = URL.createObjectURL(selectedLogo);
            setLogoPreview(objectUrl)
    
            return () => URL.revokeObjectURL(objectUrl);
        }

         setLogoPreview(selectedLogo);

    }, [selectedLogo])

    const handleCapture = (e) => {
        
        if (!e.target.files || e.target.files.length === 0) {
            onChangeHandler(null);
            return;
        }

        if ((e.target.files[0].size / (1024 * 1024)) < 2) {
            onChangeHandler(e.target.files[0]);
            return;
        }
    }

    const handleRemovePicture = () =>{
        onChangeHandler(null);
    }

    return (
        <div className={classes.root}>
            <div className={classes.placeholder}>
                {logoPreview && <img className={classes.image} src={logoPreview} />}
            </div>

            {!logoPreview && (
                <label htmlFor="upload-logo">
                    <div className={clsx(classes.link, classes.label)}>Upload Property Logo</div>
                </label>
            )}

            {logoPreview && (
                <div 
                    className={clsx(classes.link, classes.label)} 
                    onClick={handleRemovePicture}
                >
                    remove
                </div>
            )}

            <input
                accept="image/*"
                className={classes.noDisplay}
                id="upload-logo"
                type="file"
                onChange={handleCapture}
            />
        </div>
    )
}

export default ImageUpload;