const revenuePercent = (live, currentSpaceID) => {
  let percentage = {YTD: 0, MTD: 0};
  if (currentSpaceID && live[currentSpaceID]) {
    const liveObj = live[currentSpaceID];
    if (liveObj.LY_YTDRev===0) {
      percentage.YTD="NA";
    } else {
      const YTDchange = ((liveObj.YTDRev - liveObj.LY_YTDRev) / liveObj.LY_YTDRev);
      percentage.YTD = parseFloat(YTDchange*100).toFixed(0);
    }

    if (liveObj.LY_MTDRev===0) {
      percentage.MTD="NA"  
    } else {
      const MTDchange = ((liveObj.MTDRev - liveObj.LY_MTDRev) / liveObj.LY_MTDRev);
      percentage.MTD = parseFloat(MTDchange*100).toFixed(0);
    }
    
  }
  return percentage;
}

export { revenuePercent};