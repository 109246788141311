import { toTitleCase } from '../../../components/utils/wordUtils';
import { fetchDateFormat, DATE_FORMATS } from '../../../utils/utility';
import { TRANSACTION_USER_TYPES } from '../../../utils/constants';

const getSessionUserName = (frontdeskUsers, session, type) => {
    let firstName, lastName = '';

    if (!frontdeskUsers[session.endedByID]) return '';

    const userID = (type == 'started') ? session.startedByID : session.endedByID;

    const userData = frontdeskUsers[userID];

    firstName = userData?.firstName || '';
    lastName = userData?.lastName || '';

    return `${firstName}${lastName}`;
}

const processCashDrawerSessions = (sessions, frontdeskUsers, currencyData) => {
    let dataFormat = [];
    const sessionIDs = Object.keys(sessions);
    const dateFormats = `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY} ${DATE_FORMATS.TIME}`;

    sessionIDs &&
    sessionIDs?.length !== 0 && 
    sessionIDs.map(id => {

        const session = sessions[id];

        let overShort = '';
        let overShortAmount = 0;

        if(session.isClosed){
            const sign = Math.sign(session.overShort) === -1 ? '-' : '';
            const amount = Math.abs(Math.round(session.overShort));
            overShort = `${sign} ${amount}`;
            overShortAmount = session.overShort;
        }

        dataFormat.push({
            startTime: fetchDateFormat(session.startTime, dateFormats),
            endTime: fetchDateFormat(session.endTime, dateFormats),
            startCount: session.startCount,
            endCount: session.isClosed && session.endCount || '',
            overShort,
            isClosed: session.isClosed,
            startedByUser: getSessionUserName(frontdeskUsers, session, 'started'),
            endedByUser: session.isClosed && getSessionUserName(frontdeskUsers, session, 'ended') || '',
            _id: id,
            overShortAmount
        })
    });
    return dataFormat;
}

const getTransactionCreator = (transaction, users, frontdeskUsers) => {

    if (transaction?.userType === TRANSACTION_USER_TYPES.WORKSPACE) {
        return toTitleCase(users?.[transaction?.createdBy]?.name || '');
    }

    const firstName = toTitleCase(frontdeskUsers?.[transaction?.createdBy]?.firstName || '');
    const lastName = toTitleCase(frontdeskUsers?.[transaction?.createdBy]?.lastName || '');

    return `${firstName} ${lastName}`;
}

const processSessionTransactions = (
    transactions,
    transactionModes,
    frontdeskUsers,
    users,
    currencyData
) => {
    let dataFormat = [];
    const transactionIDs = Object.keys(transactions);
    const dateFormats = `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY} ${DATE_FORMATS.TIME}`;

    transactionIDs &&
        transactionIDs?.length !== 0 && 
            transactionIDs.map(id => {

                const transaction = transactions[id];

                dataFormat.push({
                    transactionType: `${toTitleCase(transaction.type)} ${toTitleCase(transaction.userType)}`,
                    mode: toTitleCase(transactionModes?.[transaction?.modeID]?.name),
                    amount: transaction.amount,
                    createdBy: getTransactionCreator(transaction, users, frontdeskUsers),
                    createdAt: fetchDateFormat(transaction.createdAt, dateFormats),
                    resvID: transaction.reservationID,
                    note: transaction.note,
                    transactionTypeID: transaction.type
                });
            });

    return dataFormat;
}

export { processCashDrawerSessions, processSessionTransactions };