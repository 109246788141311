import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme =>({
    root:{
        maxWidth: '600px',
        marginBottom: '30px',
    },
    dayCheckContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        '& .label':{
            marginLeft: '5px',
            fontWeight: 500,
            fontSize: '1.3rem'
        }
    },
}));

const weekDays = [
    { id: 1, day: 'Monday' },
    { id: 2, day: 'Tuesday' },
    { id: 3, day: 'Wednesday' },
    { id: 4, day: 'Thursday' },
    { id: 5, day: 'Friday' },
    { id: 6, day: 'Saturday' },
    { id: 0, day: 'Sunday' },
]

const SlotRepeatDays = props =>{
    const classes = useStyles();
    const { selectedDays, selectDayHandler } = props;

    const checkedIfSelected = dayID =>{
        const isSelected = selectedDays.includes(dayID);
        return isSelected;
    }

    const checkboxHandler = event =>{
        let value = event.target.getAttribute('data-value');
        value = parseInt(value);
        const isChecked = event.target.checked;
        const type = isChecked ? 'add' : 'delete';
        selectDayHandler(type, value);
    }

    return(
        <div className={classes.root}>
            {weekDays.map(dayData =>(
                <div className={classes.dayCheckContainer} key={`repeatDay_${dayData.id}`}>
                    <div className="label">{dayData.day}</div>
                    <Checkbox
                        id={`day_${dayData.id}`}
                        name={dayData.day}
                        color="primary"
                        inputProps={{ 'data-value': dayData.id }}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={checkedIfSelected(dayData.id)}
                        onChange={checkboxHandler}
                    />
                </div>
            ))}
        </div>
    )
}

export default SlotRepeatDays;