import http from "../../utils/http";
import { normalize } from 'normalizr';
import { notificationSchema } from '../../schema';

//----------------------------------------------------------------
// LOAD NOTIFICATIONS
//----------------------------------------------------------------
export function loadNotifications() {
  const ACTION_NAME = "LOAD_NOTIFICATIONS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get('/api/notification')
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ notificationSchema ] );
        // console.log(normalizedOrder.entities);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.notifications });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// TOGGLE NOTIFICATION
//----------------------------------------------------------------
export function toggleNotification(id, status) {
  const ACTION_NAME = "TOGGLE_NOTIFICATION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/notification/${id}`, {status})
      .then(successResult => {
        // console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: successResult.data });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// ADD NOTIFICATION
//----------------------------------------------------------------
export const addNotification = (notification) => ({
  type: 'ADD_NOTIFICATION',
  payload: notification
})

//----------------------------------------------------------------
// MARK ALL READ
//----------------------------------------------------------------
export function markAllRead() {
  const ACTION_NAME = "NOTIFICATIONS_ALL_READ";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/notification/readAll`, {status})
      .then(successResult => {
        console.log(successResult.data)
        // dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: successResult.data });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// MENTION NOTIFICATION
//----------------------------------------------------------------
export function mentionNotification(notification) {
  const ACTION_NAME = "MENTION_NOTIFICATION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/notification`, notification)
      .then(successResult => {
        console.log(successResult.data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: successResult.data });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

