import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import HostelContainer from "./HostelContainer";
import LinkupsContainer from "./LinkupsContainer";
import LivePage from "../components/hostel/LivePage";
import ExperiencesContainer from "./ExperiencesContainer";
import TeamsContainer from "./TeamsContainer";
import PosContainer from "./PosContainer";
import PlusModal from "../components/plus/PlusModal";
import Chat from "../components/chat/Chat";

import { MENU } from "../utils/layout";
import ReservationContainer from "./ReservationContainer";
import SearchContainer from "./SearchContainer";
import { isFeatureEnabled } from "../utils/utility";

const styles = (theme) => ({
  rootContainer: {
    borderLeft: "0px solid #FFFFFF",
  },
  container: {
    background: "#F8F9FB",
    marginLeft: 0,
    width: "calc(100% - 0px)",
  },
});

const DashboardContainer = (props) => {
  const { classes, currentSpace, currentModule, currentChat, searchActive } =
    props;

  return (
    <div className={classes.rootContainer}>
      <div className={classes.container}>
        {/* MAIN NAVIGATION SECTION */}
        {/* --------------------------------------------------------------- */}
        {searchActive && <SearchContainer />}

        {!searchActive && currentModule === MENU.live.id && <LivePage />}

        {!searchActive && currentModule === MENU.beds.id && <HostelContainer />}

        {!searchActive &&
          currentModule === MENU.linkups.id && (
            <LinkupsContainer propertyID={currentSpace?.property?._id} />
          )}

        {!searchActive && currentModule === MENU.experience.id && (
          <ExperiencesContainer />
        )}

        {!searchActive && currentModule === MENU.pos.id && (
          <PosContainer currentSpace={currentSpace} />
        )}

        {!searchActive && currentModule === MENU.team.id && <TeamsContainer />}

        {/* MODALS AVAILABILE THROUGH THE APP */}
        {/* --------------------------------------------------------------- */}
        <PlusModal />

        {currentChat && <Chat />}

        <ReservationContainer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentModule: state.dashboard.currentModule,
  currentChat: state.dashboard.currentChat,
  searchActive: state.search.searchActive,
});

export default withStyles(styles)(connect(mapStateToProps)(DashboardContainer));
