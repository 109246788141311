import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { updateSettings, getSettings } from '../../redux/actions/onlineCheckin/onlineCheckin';

import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles( theme => ({
  row: {
  	display: 'flex',
  	flexDirection: 'column',
  	marginBottom: 30
  },
  title: {
  	fontSize: '1.5rem',
  	fontWeight: 600,
  	lineHeight: 1.3
  },
  labelTitle: {
  	fontSize: '1.4rem',
  	color: '#666666',
    fontWeight: 600,
  	marginTop: 20
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10
  },
  toggle: {
  	alignItems: 'center',
  	boxShadow: '0 1px 20px #F2F2F2',
  	borderRadius: 25,
  	display: 'flex'
  },
  toggleButton: {
  	alignItems: 'center',
  	color: '#BDBDBD',
  	cursor: 'pointer',
  	display: 'flex',
  	fontSize: '1.2rem',
  	fontWeight: 600,
  	height: 35,
  	justifyContent: 'center',
  	width: '50%'
  },
  left: {
  	borderTopLeftRadius: 25,
  	borderBottomLeftRadius: 25
  },
  right: {
  	borderTopRightRadius: 25,
  	borderBottomRightRadius: 25
  },
  selected: {
  	background: '#4F9581',
  	boxShadow: '-15px 0px 17px -7px #F2F2F2',
  	color: '#FFFFFF'
  },
  bottomMargin: {
    marginBottom: 15
  },
  alignCenter: {
    textAlign: 'center',
    marginBottom: 15
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  rootIcon: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  disabledIcon: {
    background: '#808B96',
    borderRadius: 5,
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  }
}))

const CustomerDetails = (props) => {
  const { t } = props
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const classes = useStyles()
  const { open, handleClose, currentSpace, property, loadingProperty, isLoading, onlineCheckin } = props
  const [toggle, setToggle] = React.useState(false)
  const [message, setMessage] = React.useState(null)

  const [save, setSave] = React.useState(false)
  const [display, setDisplay] = React.useState({name: 'mandatory', email: 'display', phone: 'display', gender: 'display', address: 'display', city: 'display', country: 'display', nationality: 'display', photo: 'display'})

  if (onlineCheckin.customerDetails && toggle === false) {
    setToggle(true)
    setDisplay(onlineCheckin.customerDetails)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleToggle = () => {
    setOpenSnackbar(true)
    setMessage('Customer details section cannot be turned off.')
  }

  const handleDisplay = (type) => {
    if(display[type] === 'none'){
      setDisplay({...display, [type]: 'display'})
    }
    else {
      setDisplay({...display, [type]: 'none'})
    }
    if(!save) setSave(true)
  }

  const handleMandatory = (type) => {
    if(display[type] === 'display'){
      setDisplay({...display, [type]: 'mandatory'})
    }
    else {
      setDisplay({...display, [type]: 'display'})
    }
    if(!save) setSave(true)
  }

  // const trueCheck = (obj) => {
  //   let i = 0
  //   for (var o in obj) {
  //     if(obj[o]) {
  //       i = i + 1
  //     }
  //   }

  //   if(i > 0)
  //     return true
  //   else
  //     return false
  // }

  const handleSave = () => {
    const data = {
      ...display
    }

    props.dispatch(updateSettings(currentSpace.propertyID, 'customerDetails', data))
    .then((response) => {
      if(response && response.status === 200) {
        setMessage('Customer Detail Settings saved!')
        setOpenSnackbar(true)
        setSave(false)
      }
      else {
        setMessage('Your settings has not been saved. Please try again!')
        setOpenSnackbar(true)
      }
    })
  }

  return (
    <div>
      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <div className={classes.title}>
              {t('automate.onlineCheckin.steps.customerDetails.name')}
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.toggle}>
              <div 
                className={clsx(classes.toggleButton, classes.left)} 
                style={{background: '#F2F2F2'}}
                onClick={() => handleToggle()}
              >
                OFF
              </div>
              <div 
                className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: true})}
              >
                ON
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item className={classes.bottomMargin} xs={4}></Grid>
          <Grid item className={classes.alignCenter} xs={4}><span className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDetails.display')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}><span className={classes.labelTitle}>{t('automate.onlineCheckin.steps.customerDetails.mandatory')}</span></Grid>

          <Grid item className={classes.bottomMargin}xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.nameLabel')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.name === 'display' || display.name === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('name')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.name === 'none'}
              checked={display.name === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('name')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.email')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.email === 'display' || display.email === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('email')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.email === 'none'}
              checked={display.email === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('email')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.phone')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.phone === 'display' || display.phone === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('phone')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.phone === 'none'}
              checked={display.phone === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('phone')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.address')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.address === 'display' || display.address === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('address')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.address === 'none'}
              checked={display.address === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('address')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.city')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.city === 'display' || display.city === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('city')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.city === 'none'}
              checked={display.city === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('city')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.country')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.country === 'display' || display.country === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('country')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.country === 'none'}
              checked={display.country === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('country')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>

          <Grid item className={classes.bottomMargin} xs={4}><span className={classes.label}>{t('automate.onlineCheckin.steps.customerDetails.photo')}</span></Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              checked={display.photo === 'display' || display.photo === 'mandatory'}
              checkedIcon={<span className={classes.checkedIcon} />}
              icon={<span className={classes.icon} />}
              onChange={(e)=>handleDisplay('photo')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.alignCenter} xs={4}>
            <Checkbox
              color="primary"
              disabled={display.photo === 'none'}
              checked={display.photo === 'mandatory'}
              classes={{root: classes.rootIcon, disabled: classes.disabledIcon}}
              checkedIcon={<span className={classes.checkedIcon} />}
              onChange={(e)=>handleMandatory('photo')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        {(save) && <div className={classes.buttonsContainer}>
          <div className={classes.button} onClick={handleSave}>
            Save
          </div>
        </div>}
      </div>
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  onlineCheckin: state.onlineCheckin
})

export default withTranslation()(connect(mapStateToProps)(CustomerDetails))