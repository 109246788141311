import http from '../../utils/http';
import { loadSpace } from '../dashboard/dashboard';
import { logoutUser } from '../auth/auth';

//----------------------------------------------------------------
// ADD SPACE
//----------------------------------------------------------------
export function addSpace(spaceID, currentSpaceID) {
  const ACTION_NAME = "ADD_SPACE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/users/adminPermissions`, {addSpace: spaceID})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        dispatch(loadSpace(spaceID, currentSpaceID));
        return true
      })
      .catch(errorResult => {
        // console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}

//----------------------------------------------------------------
// REMOVE SPACE
//----------------------------------------------------------------
export function removeSpace(spaceID) {
  const ACTION_NAME = "REMOVE_SPACE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/users/adminPermissions`, {removeSpace: spaceID})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        dispatch(logoutUser());
        return true
      })
      .catch(errorResult => {
        // console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}
