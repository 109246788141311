import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment-timezone";

import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import CloseIcon from "@material-ui/icons/Close";

import ModuleHeader from "./ModuleHeader";
import CalendarDateCard from "./calendar/CalendarDateCard";
import ActivityList from "./calendar/ActivityList";
import ActivitySummary from "./calendar/ActivitySummary";
import BookingMask from "./calendar/BookingMask";
import SearchInput from "./calendar/SearchInput";
import AccessHeaderAction from "../permissions/AcessHeaderAction";
import Placeholder from "./booking/Placeholder";

import { MODULE } from "../../common/constants/permission";

import { DATE_FORMATS } from "../../utils/utility";
import {
  fetchCalendarInfo,
  displayProducts,
  resetProductDisplay,
} from "../../redux/actions/experiences/calendar";
import NewBookingMask from "./booking/NewBookingMask";
import EditBookingMask from "./booking/EditBookingMask";
import { fetchBookingSource } from "../../redux/actions/reservation/newReservation";
import Reservation from "../reservations/Reservation";
import {
  getReservationDetails,
  resetReservationDetails,
} from "../../redux/actions/beds/beds";
import { fetchProperty } from "../../redux/actions/dashboard/property";
import IMAGES from "../../constants/images";

const styles = (theme) => ({
  root: {
    // maxWidth: '1100px',
    // margin: '0 auto',
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    backgroundColor: "#fff",
    padding: "20px 15px 20px 15px",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  searchSection: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
    marginBottom: "10px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      // flexDirection: 'column',
      marginTop: "10px",
    },
  },
  searchContainer: {
    alignItems: "center",
    background: "#f8f9fb",
    borderRadius: "5px",
    display: "flex",
    height: 40,
    marginRight: 10,
    paddingLeft: 40,
    paddingRight: theme.spacing(2),
    position: "relative",
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  calendarContainer: {
    height: "calc(100% - 120px)",
  },
  calendarSection: {
    height: "100%",
  },
  searchIcon: {
    position: "absolute",
    height: 15,
    left: 10,
    opacity: 0.5,
    top: 12,
    width: 15,
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    fontSize: "1.8rem",
    padding: 5,
    position: "absolute",
    right: 5,
    top: 6,
    "&:hover": {
      background: "#F0F0F0",
    },
  },

  fullDate: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "transparent",
    boxShadow: "none",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "10px",
    },
  },
  arrowContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginRight: 0,
    marginLeft: 0,
    height: "40px",
    width: "40px",
  },
  arrow: {
    background: "transparent",
    borderRadius: "50%",
    color: "#bdbdbd",
    height: "40px",
    width: "40px",
    minWidth: "40px",
    padding: 10,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  arrowIcon: {
    fontSize: "1.8rem",
  },
  datepicker: {
    minWidth: "120px",
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 600,
    color: "#bdbdbd",
  },
  activityInfoList: {
    boxSizing: "border-box",
    // padding: '0px 20px',
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  scrollableElement: {
    width: "100%",
    height: "inherit",
    overflow: "hidden",
  },
  listContainer: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingRight: "17px",
    boxSizing: "content-box",
  },
  loadingState: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(255,255,255,0.5)",
    zIndex: 999,
  },
  loader: {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    left: 0,
    border: 0,
    right: 0,
    top: "50%",
  },
});

class CalendarPage extends Component {
  state = {
    currentMonthDays: [],
    selectedDate: moment(),
    selectedMonth: moment(),
    selectedBookingID: null,
    newBooking: false,
    search: false,
  };

  daysInCurrentMonth = (date) => {
    let count = moment(date).daysInMonth();
    let days = [];
    for (let i = 1; i < count + 1; i++) {
      days.push(moment(date).date(i).format(DATE_FORMATS.DEFAULT_DATE));
    }
    return days;
  };

  initCalendarDisplay = (date) => {
    const { dispatch, currentSpace } = this.props;
    dispatch(resetProductDisplay());
    const currentMonthDays = this.daysInCurrentMonth(date);
    const currentMonth = date.clone().format("YYYY-MM");
    const params = {
      propertyID: currentSpace.propertyID,
      date: currentMonth,
    };
    this.setState({
      currentMonthDays,
      selectedMonth: date.clone(),
    });
    const response = dispatch(fetchCalendarInfo(params));
    response.then((success) => {
      if (success) {
        this.selectDateHandler(date);
      }
    });
  };

  componentDidMount() {
    const { currentSpace, dispatch } = this.props;
    const currentDate = moment().tz(currentSpace.timezone);
    this.initCalendarDisplay(currentDate);
    dispatch(fetchBookingSource(currentSpace.propertyID, currentSpace._id));
    dispatch(fetchProperty(currentSpace.propertyID));
  }

  handleClearSearch = () => {
    this.setState({ search: false });
  };

  handleSearch = (e) => {
    console.log(e.target.value);
  };

  selectDateHandler = (date) => {
    const { calendarData, dispatch } = this.props;
    const selectedDate = moment(date).format(DATE_FORMATS.DEFAULT_DATE);
    const products = calendarData[selectedDate] || [];
    dispatch(resetProductDisplay());
    dispatch(displayProducts(products));
    this.setState({ selectedDate });
  };

  decrementMonth = () => {
    const { selectedMonth } = this.state;
    const prevMonth = selectedMonth.clone().subtract(1, "month");
    this.initCalendarDisplay(prevMonth);
  };

  incrementMonth = () => {
    const { selectedMonth } = this.state;
    const nextMonth = selectedMonth.clone().add(1, "month");
    this.initCalendarDisplay(nextMonth);
  };

  checkIfDateSelected = (date) => {
    const { selectedDate } = this.state;
    const isSelected = moment(selectedDate).isSame(moment(date), "day");
    return isSelected;
  };

  openNewBooking = () => {
    this.setState({ newBooking: true });
  };

  closeNewBooking = () => {
    this.setState({ newBooking: false });
  };

  openBookingMask = (resID) => {
    this.props.dispatch(getReservationDetails(resID));
    this.setState({ selectedBookingID: resID });
  };

  closeModalHandler = () => {
    this.props.dispatch(resetReservationDetails());
    this.setState({ selectedBookingID: null });
  };

  render() {
    const {
      classes,
      currentSpace,
      calendarData = {},
      isLoading,
      iframe,
    } = this.props;
    const { currentMonthDays, selectedMonth, selectedBookingID, newBooking } =
      this.state;
    return (
      <div
        className={classes.root}
        style={{
          marginTop: iframe ? 0 : 20,
          paddingBottom: iframe ? 0 : 20,
          height: iframe ? "calc(100% - 20px)" : "calc(100% - 40px)",
        }}
      >
        {/*----------------HEADER = SEARCH + PERMISSIONS--------------------- */}
        <div className={classes.headerContainer}>
          <div className={classes.searchContainer}>
            <img className={classes.searchIcon} src={IMAGES.ICONS.search2} />
            <InputBase
              id="bookingEngineURL"
              name="bookingEngineURL"
              placeholder={"Search"}
              value={this.search}
              fullWidth
              type="text"
              onChange={this.handleSearch}
            />
            {this.search && (
              <CloseIcon
                className={classes.closeIcon}
                onClick={this.handleClearSearch}
              />
            )}
          </div>
          <div className={classes.permissions}>
            <AccessHeaderAction
              moduleID={MODULE.CALENDAR.ID}
              className={classes.accessHeader}
            />
          </div>
        </div>

        {/*----------------PROGRESS LOADER--------------------- */}
        {isLoading && (
          <div className={classes.loadingState}>
            <CircularProgress className={classes.loader} />
          </div>
        )}

        <div className={classes.searchSection}>
          {/* <SearchInput openBookingMask={this.openBookingMask} /> */}
          {/* <SearchInput openBookingMask={this.openBookingMask} />
                    <div
                        className={classes.add}
                        onClick={this.openNewBooking}
                        data-intercom-target={'New Reservation'}
                    >
                        <AddIcon className={classes.addIcon} />
                    </div> */}

          <div className={classes.fullDate}>
            <div className={classes.arrowContainer}>
              <Button
                className={clsx(classes.arrow, "left")}
                onClick={this.decrementMonth}
              >
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Button>
            </div>
            <div className={classes.datepicker}>
              {selectedMonth.format("MMMM YYYY")}
            </div>
            <div className={classes.arrowContainer}>
              <Button
                className={clsx(classes.arrow, "right")}
                onClick={this.incrementMonth}
              >
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Button>
            </div>
          </div>
        </div>

        <Grid container className={classes.calendarContainer}>
          <Grid item sm={3} xs={false} className={classes.calendarSection}>
            <div className={classes.scrollableElement}>
              <div className={classes.listContainer}>
                {currentMonthDays.map((day, dayIndex) => (
                  <CalendarDateCard
                    key={`day_${dayIndex}`}
                    dayInfo={day}
                    ref={this.currentDateRef}
                    timezone={currentSpace.timezone}
                    isActive={this.checkIfDateSelected(day)}
                    onClickHandler={this.selectDateHandler}
                    activityCount={
                      calendarData[day] ? calendarData[day].length : 0
                    }
                  />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item sm={4} xs={12} className={classes.calendarSection}>
            <div className={classes.activityInfoList}>
              <div className={classes.listContainer}>
                <ActivityList />
              </div>
            </div>
          </Grid>
          <Grid item sm={5} xs={12} className={classes.calendarSection}>
            <ActivitySummary
              openBookingMask={this.openBookingMask}
              openNewBooking={this.openNewBooking}
            />
          </Grid>
        </Grid>

        {/* { selectedBookingID && (
                    <BookingMask
                        bookingID={selectedBookingID}
                        closeModalHandler={this.closeModalHandler}
                    />
                )} */}

        {newBooking && (
          <NewBookingMask
            open={newBooking}
            handleClose={this.closeNewBooking}
            refreshCalendar={() =>
              this.initCalendarDisplay(moment(this.state.selectedDate))
            }
          />
        )}

        {/* { selectedBookingID && (
                    <EditBookingMask open={true}
                        bookingID={selectedBookingID}
                        currentSpace={currentSpace}
                        handleClose={this.closeModalHandler} 
                        refreshCalendar={() =>this.initCalendarDisplay(moment(this.state.selectedDate))}
                        />
                )} */}

        {selectedBookingID && (
          <Reservation
            open={true}
            handleCloseReservation={this.closeModalHandler}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { experiences, spaces, dashboard, loading } = state;
  return {
    iframe: dashboard.iframe,
    currentSpace: spaces[dashboard.currentSpace] || {},
    calendarData: experiences?.calendarData || {},
    isLoading: loading.FETCH_CALENDAR_INFO,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(CalendarPage));
