import http from "../../utils/http";
import axios from "axios";
import { normalize } from 'normalizr';
import { productSchema } from "../../schema/productCategorySchema";

//----------------------------------------------------------------
// CREATE PRODUCT 
//----------------------------------------------------------------
export function createProduct(product) {
  const ACTION_NAME = "CREATE_PRODUCT";
  let productData = { ...product };
  let file = productData.imageFile;
  delete productData.imageFile;
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/pos/product', { ...productData })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data.product } });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}

//----------------------------------------------------------------
// EDIT PRODUCT
//----------------------------------------------------------------
export function editProduct(productID, updatedProduct) {
  let updatedProductData = { ...updatedProduct };
  let file = updatedProductData.imageFile;
  let fileName = null;
  if (file && file.name) {
    fileName = file.name.split(".")[0];
    updatedProductData.fileName = fileName;
  }

  const ACTION_NAME = "EDIT_PRODUCT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/pos/product/${productID}`, { ...updatedProductData })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data.product } });
        return data
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}

//----------------------------------------------------------------
// DELETE PRODUCT
//----------------------------------------------------------------
export function deleteProduct(productID) {
  const ACTION_NAME = "DELETE_PRODUCT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/pos/product/${productID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return
      });
  };
}

// ----------------------------------------------------------------
// LOAD PRODUCTS
// ----------------------------------------------------------------
export function getProducts(propID) {
  const ACTION_NAME = "LOAD_PRODUCTS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/pos/product/${propID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, [productSchema]);
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...normalizedOrder.entities } });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

// PRODUCT IMAGE APIs......................................................................

export const POSImageUploadStatus = (type, error = 'Error') =>{
  const ACTION_NAME = 'PRODUCT_IMAGE_UPLOAD';
  return function (dispatch) {
      switch(type){

          case "request":
              dispatch({ type: `${ACTION_NAME}_REQUEST` });
              break;
  
          case "success":
              dispatch({ type: `${ACTION_NAME}_SUCCESS` });
              break;
  
          case "error":
              dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
              break;
  
          default:
              dispatch({ type: `${ACTION_NAME}_FAILURE` });
              break;
  
      }
  }
}

export const uploadProductPOSImage = (url, data) =>{
  const options = {
      headers: {
          "Content-Type": data.type
      }
  };
  return axios.put(url, data.file, options, { key: data.key });
}

export const saveProductImage = (productID, propertyID, key) => {
  const ACTION_NAME = "SAVE_PRODUCT_IMAGE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/pos/product/images/save`, { productID, propertyID, key })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        console.log("errorResult", errorResult);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
        return;
      });
  }
}

//----------------------------------------------------------------
// UPLOAD PRODUCT IMAGE
//----------------------------------------------------------------
function uploadProductImage(file, data, callback) {
  const fileParts = file.name.split(".");
  const fileType = fileParts[1];
  const key = data.key;
  const url = data.url;
  const options = {
    headers: {
      "Content-Type": fileType
    }
  };
  axios.put(url, file, options).then(result => {
    http.post("/api/pos/product/images/save", {
      productID: data.product._id,
      propertyID: data.product.propertyID,
      key: key
    }).then(response => {
      callback(null,response);
    }).catch(error => { callback(error); });
  }).catch(error => { callback( error); });
}