export default (state = {}, action) => {
  switch (action.type) {
    case "LOAD_HHL_TOKEN_SUCCESS":
      if (action.payload.token) {
        return { ...state, token: action.payload.token };
      } else return { ...state };
    default:
      return state;
  }
};
