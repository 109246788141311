export default (state = { properties: {} }, action) => {
  switch (action.type) {
    case "FETCH_MY_ALLOCATOR_PROPERTIES_SUCCESS":
      return {
        ...state,
        properties: { ...state.properties, ...action.payload.properties },
        userToken: action.payload.userToken,
        viewCCPassword: action.payload.viewCCPassword
      };
    case "FETCH_USERTOKEN_SUCCESS":
      return {
        ...state,
        userToken: action.payload.userToken
      };
    case "DELETE_PROPERTY_CHANNEL_MAPPING_SUCCESS":
      return {
        ...state,
        properties: {},
        userToken: null
      };
    default:
      return state;
  }
};