import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
// import SettingsIcon from '@material-ui/icons/Settings';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DoneIcon from '@material-ui/icons/Done';
import IMAGES from '../../constants/images';


const useStyles = makeStyles(theme =>({
    root: {
    
    },
    stepperContainer: {
        [theme.breakpoints.down('xs')]:{
            padding: '24px 0px'
        }
    },
    step: {
        '& .MuiStepLabel-root':{
            [theme.breakpoints.up('sm')]:{
                alignItems: 'unset'
            }
        },
        '& .MuiStepConnector-root':{
            [theme.breakpoints.up('sm')]:{
                right: "105%",
                left: "-70%",
            }
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':{
            [theme.breakpoints.up('sm')]:{
                textAlign: 'unset',
            }
        },
        '& .MuiStepButton-root': {
            width: "unset",
            margin: "unset",
            padding: "unset",
            boxSizing: "unset",
        },
        '& .MuiStepButton-touchRipple': {
            display: 'none'
        },

        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#5dbe7b'
        }
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    count: {
        color: '#BDBDBD',
        fontSize: '0.9rem',
        fontWeight: 600,
        textAlign: 'left',
        marginBottom: 5,
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
        }
    },
    label: {
        fontSize: '1.2rem',
        fontWeight: 600,
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.1rem',
            textAlign: 'center',
        }
    }
}));

const ColorlibConnector = withStyles(theme => ({
    alternativeLabel: {
      top: 22,
      [theme.breakpoints.down('xs')]: {
        top: 15,
      }
    },
    active: {
        '& $line': {
            backgroundColor: '#5dbe7b'
        },
    },
    completed: {
      '& $line': {
        backgroundColor: '#5dbe7b'
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
}))(StepConnector);

function getSteps(t) {
    return [
      t('hwMigration.stepperLabels.step1'), //Personal information
      t('hwMigration.stepperLabels.step2'), //Hostel details
      t('hwMigration.stepperLabels.step3'), //Name rooms
      t('hwMigration.stepperLabels.step4'), //Connect channels
      t('hwMigration.stepperLabels.step5'), //Payment details
    ];
}

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 0,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: 20,
      width: 20,
    },
    [theme.breakpoints.down('xs')]: {
        '& img': {
          height: 15,
          width: 15,
        },
        width: 30,
        height: 30,
    }
  },
  active: {
    backgroundColor: '#42476f'
  },
  completed: {
    backgroundColor: '#5dbe7b'
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src={IMAGES.ICONS.stepperUser} />,
    2: <img src={IMAGES.ICONS.stepperHostel} />,
    3: <img src={IMAGES.ICONS.stepperRooms} />,
    4: <img src={IMAGES.ICONS.stepperChannels} />,
    5: <img src={IMAGES.ICONS.stepperPayment} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <DoneIcon /> : icons[String(props.icon)]}
    </div>
  );
}

const CustomStepper = props =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const { activeStep, setActiveStep, ongoingStep, migrationCompleted } = props;
    const steps = getSteps(t);
    const stepperActiveStep = activeStep - 1; //stepper components steps start from zero........

    const handleStep = index =>{
        const selectedStep = index + 1;
        setActiveStep(selectedStep);
    }

    const getCompletedStatus = (index) =>{
        const stepperStepNo = index + 1;

        //If migration is completed and new member is registering for that property,
        //show step 1 active and all the other steps completed..
        if(ongoingStep == 1 && migrationCompleted && stepperStepNo > 1) return true; 
    
        return stepperStepNo < ongoingStep;
    }
    
    return (
        <div className={classes.root}>
            <Stepper 
                alternativeLabel 
                nonLinear
                activeStep={stepperActiveStep} 
                className={classes.stepperContainer} 
                connector={<ColorlibConnector />}
            >
                {steps.map((label, index) => (
                    <Step 
                        key={label} 
                        className={classes.step} 
                        completed={getCompletedStatus(index)}
                    >
                        <StepLabel StepIconComponent={ColorlibStepIcon} onClick={() =>handleStep(index)}>
                            <div className={classes.labelContainer}>
                                <div className={classes.count}>STEP {index + 1}</div>
                                <div className={classes.label}>{label}</div>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

export default CustomStepper;