import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { setCurrentSpaceType, setCurrentSpace, loadPropertyContent } from "../../redux/actions/dashboard/dashboard";
import { fetchProperty } from '../../redux/actions/dashboard/property';
import { resetEmailTemplates } from '../../redux/actions/emails/emails';
import { resetRatePlans } from '../../redux/actions/rates/ratePlans';
import { resetCashDrawers } from '../../redux/actions/cash-drawers/cashDrawers';
import { loadUserPermissions } from '../../redux/actions/permissions/permissions';
import { removeSpace } from '../../redux/actions/spaces/space';
import { resetError } from '../../redux/actions/common/common';

import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  menu: {
    position: 'relative',
    '& .MuiMenu-paper': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      height: 353,
      minWidth: 250,
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      padding: 0,
      height: '100%',
    },
    '& .MuiMenuItem-root': {
      padding: 0,
    }
  },
  spaceBetween: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    height: '40px',
    minWidth: 200,
  },
  menuItem: {
    minWidth: 200,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    position: 'relative',
  },
  reduced: {
    width: 'calc(80% - 20px)'
  },
  rightIcon: {
    color: '#BDBDBD',
    fontSize: '1.4rem'
  },
  bold: {
    color: '#506cf0',
    fontWeight: 600,
  },
  row: {
    alignItems: 'center',
    display: 'flex'
  },
  done: {
    color: '#506cf0',
    fontSize: '1.5rem',
    fontWeight: 600,
    padding: 8,
  },
  close: {
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#999999',
    '&:hover': {
      background: '#E0E0E0',
      color: '#000000'
    },
  },
  last: {
    borderTop: '1px solid #F0F0F0',
    marginTop: 'auto'
  },
  selected: {
    color: '#506cf0',
    cursor: 'auto',
    fontWeight: 600,
    '& p': {
      fontWeight: 600,
    },
    '&:hover': {
      background: '#FFFFFF'
    }
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 999
  },
  loader: {
    textAlign: 'center',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    border: 0,
    right: 0,
    top: '45%',
    width: '30px !important',
    height: '30px !important'
  },
}))

const SpaceListMenu = (props) => {
  const classes = useStyles();
  const { currentSpace, spaces, closeParent, emailTemplates, ratePlans, currentUser, currentUserID, t, errors, isLoading } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mouseOverMenu, setMouseOverMenu] = React.useState(false);
  const timeoutLength = 500;
  const spacesCount = Object.keys(spaces).length;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setMouseOverMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMouseOverMenu(false);
  };

  // const enterMenu = () => {
  //   setMouseOverMenu(true);
  // }

  // const leaveMenu = () => {
  //   setTimeout(() => {
  //     setAnchorEl(null);
  //     setMouseOverMenu(false);
  //   }, timeoutLength);
  // }

  const handleSpaceClick = (spaceID) => {
    if (spaceID!==currentSpace._id) {
      let spaceType = spaces[spaceID].type;
      if (!emailTemplates.default) { props.dispatch(resetEmailTemplates()) };
      if (!ratePlans.default) { props.dispatch(resetRatePlans()) };
      props.dispatch(resetCashDrawers());
      props.dispatch(loadUserPermissions(currentUserID, spaceID));
      props.dispatch(setCurrentSpace(spaceID));
      props.dispatch(setCurrentSpaceType(spaceType));
      props.dispatch(fetchProperty(spaces?.[spaceID]?.propertyID));
      props.dispatch(loadPropertyContent(spaces?.[spaceID]?.propertyID, spaceID));
      window.history.replaceState({}, document.title, `/live`);
    }
    setAnchorEl(null);
    setMouseOverMenu(false);
    closeParent();
  }

  const handleRemove = (space) => {
    console.log('remove', space);

    if(space && !isLoading){
      props.dispatch(removeSpace(space));
    }
  }
  
  const handleCloseError = () =>{
    props.dispatch(resetError('REMOVE_SPACE'));
  }

  const handleCreate = () => {
    props.handleCreateProperty();
  }

  const handleAddSpace = () => {
    props.handleAddSpace();
  }

  return (
    <div 
      className={classes.root} 
    >
      <div 
        className={classes.spaceBetween}
        onClick={handleClick} 
      >
        <Typography className={classes.bold} variant="body2">{t('navbar.createProperty')}</Typography>
        <ArrowRightIcon className={classes.rightIcon} />
      </div>
      
      {currentSpace && <Menu
        id="property-menu"
        anchorEl={anchorEl}
        className={classes.menu}
        getContentAnchorEl={null}
        keepMounted
        open={mouseOverMenu}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 231, left: 265 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        
        {Object.keys(spaces).map(space => (
          <MenuItem disableRipple key={space} className={clsx({[classes.row]: true, [classes.selected]: space===currentSpace._id})}>
            <div className={clsx({[classes.menuItem]: true, [classes.reduced]: (currentUser.isSystemAdmin || space===currentSpace._id)})} onClick={() => handleSpaceClick(space)}>
              <Typography variant="body2">{spaces[space].name}</Typography>
            </div>
            {space===currentSpace._id && <DoneIcon className={classes.done} />}
            {space!==currentSpace._id && currentUser.isSystemAdmin && <CloseIcon onClick={() => handleRemove(space)} className={clsx(classes.done, classes.close)} />}
          </MenuItem>
        ))}

        {!currentUser.isSystemAdmin && <MenuItem className={classes.last} onClick={handleCreate}>
          <Typography className={classes.menuItem} variant="body2">{t('navbar.newProperty')}</Typography>
        </MenuItem>}

        {currentUser.isSystemAdmin && <Tooltip title={spacesCount <= 5 ? "" : "Support Accounts cannot have more than 5 spaces"}>
          <div className={classes.last}><MenuItem disabled={spacesCount > 5} className={classes.last} onClick={props.handleAddSpace}>
            <Typography className={classes.menuItem} variant="body2">Add New Space</Typography>
          </MenuItem></div></Tooltip>}
          
        {/* ----------------PROGRESS LOADER--------------------- */}
        {isLoading && (
          <div className={classes.loadingState}>
            <CircularProgress className={classes.loader} />
          </div>
        )}
      
      </Menu>}

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            onClose={handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}

    </div>
  );
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentUser: state.auth.user,
  spaces: state.spaces,
  emailTemplates: state.emailTemplates,
  ratePlans: state.ratePlans,
  currentUserID: state.auth.user._id,
  errors: state.errors.REMOVE_SPACE,
  isLoading: state.loading.REMOVE_SPACE
})

export default withTranslation()(connect(mapStateToProps)(SpaceListMenu));