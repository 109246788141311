import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, CircularProgress, Grid } from "@material-ui/core";
import Status from './Status';
import BasicEditDetails from './BasicEditDetails';
import ConfirmForm from '../../common/ConfirmForm';
import EditBookingProducts from './EditBookingProducts';

const useStyles = makeStyles(theme => ({
  confirm: {
    padding: 15,
    margin: '10px 20px',
    borderRadius: 2,
    backgroundColor: '#fff'
  }
}));

const BookingTab = props => {
  const classes = useStyles();
  const { booking, bookingID, loading, editBookingMask, deleteBookingMask } = props;
  const [displayForm, setDisplayForm] = React.useState({ details: false, cancel: false });

  return (
    <div className={classes.container}>

      <Grid container>
        <Grid item xs={12}>
          {/* --------------------------------------------------------------- */}
          {/* STATUS FORM */}
          {/* --------------------------------------------------------------- */}
          <Status
            expand={displayForm.details}
            handleSelectStatus={(type, value) => setDisplayForm({ details: false, cancel: false, [type]: value })}
          />

          {displayForm.details && (
            <BasicEditDetails
              open={displayForm.details}
              booking={booking}
              bookingSource={props.bookingSource}
              handleEdit={editBookingMask}
              handleClose={() => setDisplayForm(false)}
            />
          )}

          {displayForm.cancel && (
            <div className={classes.confirm}>
              <ConfirmForm
                open={displayForm.cancel}
                setOpen={(value) => setDisplayForm({ ...displayForm, cancel: value })}
                onClose={() => setDisplayForm({ ...displayForm, cancel: false })}
                onConfirm={deleteBookingMask}
              >
                Are you sure you would like to delete this booking?
            </ConfirmForm>
            </div>
          )}

        </Grid>
        <Grid item xs={12}>
          {/* --------------------------------------------------------------- */}
          {/* PRODUCT TABLE View */}
          {/* --------------------------------------------------------------- */}
          <EditBookingProducts 
            bookingProducts={booking?.products || []}
            productInfo={props.productInfo}
            calendarData={props.calendarData}
            handleEdit={editBookingMask}
          />

        </Grid>
      </Grid>

      {loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.loading.UPDATE_BOOKING,
    productInfo: state.experiences?.activityList,
    bookingSource: state.bookingSource,
    calendarData: state.experiences?.calendarData,
  }
}

export default connect(mapStateToProps)(BookingTab);