import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { getCurrency } from '../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        // paddingLeft: '4px',
        // paddingRight: '2px',
        boxSizing: 'border-box',
    },
    activityInfo:{
        color: '#508ea3',
        marginBottom: '3px',
        padding: '5px',
        cursor: 'pointer',
        width: 'calc(100% - 10px)',
        '& div':{
            width: '100%',
            fontSize: '1rem',
            fontWeight: 600,
            marginBottom: '2px',
            paddingLeft: '20%',
            boxSizing: 'border-box'
        }
    }
}));

const DateBlock = props =>{
    const { 
        daysDisplayCount, 
        slotsList, 
        date, 
        currentSpace, 
        slotClickHandler, 
        productID,
        gradeTitle,
        t
    } = props;

    const classes = useStyles();
    const width = (100/daysDisplayCount);
    const currencyData = { 
        code: currentSpace.currency,
        country: currentSpace.country
    }

    if(slotsList && slotsList.length > 0){
        return(
                <div
                    className={classes.root} 
                    style={{width: `${width}%`}}
                >
                    {slotsList.map((slotData, indx) =>(
                        <div 
                            key={`activity_${slotData.slotID}_${indx}`} 
                            className={clsx(classes.activityInfo, 'activityInfo')} 
                            onClick={() => slotClickHandler({ 
                                ...slotData, 
                                productID, 
                                gradeTitle, 
                                date 
                            })}
                        >
                            {slotData.allDay && <div>All day</div>}
                            {!slotData.allDay && <div>{moment(`${date} ${slotData.startTime}`).format('HH:mm a')}</div>}
                            <div>{getCurrency(currencyData, slotData.price)}</div>
                            <div>{slotData.available} {t('experiences.manage.rate.slots')}</div>
                        </div>
                    ))}
                </div>
        )
    }

    return(
        <div style={{width: `${width}%`}}></div>
    )
}

const mapStateToProps = state =>{
    const { spaces,  dashboard } = state;
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},

    }
}

export default withTranslation()(connect(mapStateToProps)(DateBlock));