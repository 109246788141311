import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import _ from 'lodash';

import AddTeamMember from './AddTeamMember';

import AddIcon from '@material-ui/icons/Add';
import { Button, Checkbox, Typography, makeStyles } from '@material-ui/core';

import TeamList from './TeamList';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20,
    width: '100%',
  },
  preAuth: {
    background: '#ffffff',
    borderRadius: 15,
    padding: theme.spacing(2),
    width: 'calc(60% - 32px)'
  },
  addContainer: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    border: '1px dashed #999999',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  addText: {
    marginBottom: 5,
  },
  addIcon: {
    fontSize: '4rem'
  },
  legal: {
    alignItems: 'center',
    display: 'flex'
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    width: '100%',
  },
  skip: {
    color: '#AAAAAA',
    cursor: 'pointer',
    fontSize: '1.3rem',
    marginRight: 20,
  },
  next: {
    background: '#BB6633',
    borderRadius: '5px',
    boxShadow: 'none',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '1.4rem',
    padding: '8px 20px',
    '&:hover': {
      background: '#999999'
    }
  }
}))

const InviteTeam = (props) => {

  const classes = useStyles();
  const { currentSpaceID, t } = props;
  const [newMembers, setNewMembers] = React.useState([]);
  const [memberIndex, setMemberIndex] = React.useState(0);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [error, setError] = React.useState({terms: false})

  useEffect(() => {
    handleAddNewMember();
    console.log(currentSpaceID);
  }, []);

  const handleOpenTerms = () => {
    setOpenTerms(true);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
  }
  const handleCloseTerms = () => {
    setOpenTerms(false);
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }
  const handleAcceptTerms = () => {
    setOpenTerms(false);
    setTerms(true);
    setError({terms: false});
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }
  const handleToggle = (e) => {
    if (e.target.checked) {
      setTerms(true)
      setError({terms: false})
    } else {
      setTerms(false);
    }
  }

  const handleClear = (index) => {
    const array = _.cloneDeep(newMembers);
    array.splice(index);
    setNewMembers(array);
  }

  const handleAddNewMember = () => {
    setNewMembers(
      [...newMembers,
        <div key={memberIndex} className={classes.row}>
          <AddTeamMember index={memberIndex} handleCancel={handleClear} />
        </div>
      ]
    );
    setMemberIndex(memberIndex+1);
  }

  return (
    <div className={classes.root}>

      <div className={classes.row} style={{marginBottom: 0}}>
        <TeamList />
      </div>
    
      {newMembers}  

      <div className={classes.row}>
        <div className={classes.addContainer} onClick={handleAddNewMember}>
          <Typography variant="h6" className={classes.addText}>{t('onboarding.team.addMember')}</Typography>
          <AddIcon className={classes.addIcon} />
        </div>
      </div>

      {/* <div className={classes.row}>
        <div className={classes.legal}>
          <Checkbox color="primary" checked={terms} onClick={handleToggle} />
          <Typography variant="body2">
            Creating an account means you accept our <span className={classes.link} onClick={handleOpenTerms}>Terms of Service</span> 
          </Typography>
        </div>
        {error.terms && <div className={classes.error}>Please accept terms and conditions</div>}
      </div> */}

      {/* {openTerms && <TermsAndConditions open={openTerms} handleClose={handleCloseTerms} handleAccept={handleAcceptTerms} />} */}

      <div className={classes.buttonContainer}>
        <div className={classes.skip} onClick={props.handleSkip}>{t('onboarding.skip')}</div>
        <Button variant="contained" color="primary" className={classes.next} onClick={props.handleNext}>
          {t('onboarding.complete')}
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace
})

export default withTranslation()(connect(mapStateToProps)(InviteTeam));