import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';
import ImageUploadItem from './ImageUploadItem';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 30
  },
  listText: {
    paddingLeft: '0px',
    marginBottom: '20px',
    listStyleType: 'none',
    '& li':{
        marginBottom: '5px',
    }
  },
  imageContainer:{
    '& .errorMessage':{
      marginTop: '-27px'
    }
  }
}));

const photoType = {
  cover: { text: "Cover photo" },
  host: { text: "Host - Show yourself leading the activity" },
  action: { text: "Action - Show guests engaging in your experience" },
  details: { text: "Details - Capture a close-up of textures or interesting details" },
  location: {text: "Location - Show the full scene and try to include people" },
}

const defaultData = Object.keys(photoType).map(typeID => ({
  
  [typeID]: { 
    id: null, 
    imageUrl: '', 
    type: typeID,
    description: photoType[typeID].text   
  }

}));

const ActivityPhotos = props => {

  const classes = useStyles();
  const coverPhotoRef = useRef(null)

  const { 
    nextStepHandler, 
    prevStepHandler, 
    activeStepInfo,
    productData 
  } = props;

  const [ photos, setPhotos ] = useState({ ...Object.assign({}, ...defaultData) });
  const [ photoError, setPhotoError ] = useState(false);

  useEffect(()=>{
    if( productData.photos ){
      initPhotos(productData.photos);
    }
  }, [productData.photos]);

  const initPhotos = photosData =>{
    let initData = {};

    photosData.forEach(photo => {

      if(photo.type){

        initData[photo.type] = { 
          id: photo._id, 
          imageUrl: photo.original, 
          type: photo.type, 
          description: photoType[photo.type].text   
        };
        
      }
      return initData;
    });

    const initDefault = { ...Object.assign({}, ...defaultData) };

    setPhotos({ ...initDefault, ...initData });
  }

  const resetError = () =>{
    setPhotoError(false);
  }

  const proceedToNextStep = () =>{
    
    let hasCoverPhoto = false;
    const photosData = productData?.photos || [];

    hasCoverPhoto = photosData.find(photo => photo.type == 'cover');

    if(!hasCoverPhoto){ 
      setPhotoError(true);
      coverPhotoRef.current.scrollIntoView();  
      return;
    }

    nextStepHandler();
  }

  return (
    <>
      <div className="main-content">

        <div className="main-content-title">Add photos to your experience</div>

        <div className="sub-title">
     
            <p>Add at least 5 high-quality photos to show guests what it's like to take your experience. They will be reviewed by our team so make sure they meet our photo standards.</p> 
            <p>Requirements for all photos</p> 
            
            <ul className={classes.listText}>
              <li>- Photos must be bright, clear, and in color</li> 
              <li>- They must accurately describe the experience</li>
              <li>- They can't show people posed for the camera or taking a selfie</li>
              <li>- They can't be edited with filters, text or graphics, logos, or collages</li>
              <li>- They must belong to you — don't use copyrighted work</li>
            </ul>
            
            <p>Cover photo: Show people interacting on your experience <br/>Choose a candid photo that includes at least one person, and represents the experience as a whole. Selfies, posed, or staged photos will not be accepted.</p>
        </div>

        <div className={classes.row}>

          <div className={classes.imageContainer}>
            <ImageUploadItem 
              photoRef = {coverPhotoRef}
              productID={productData._id} 
              photoData={ photos.cover }
              resetError = {resetError}
            />
            
            {photoError && (
              <FormHelperText className="errorMessage">
                Cover photo is required
              </FormHelperText>
            )}
          </div>

        </div>

        <div className={classes.row}>

          <ImageUploadItem 
            productID={productData._id} 
            photoData={ photos.host }
          />
          <ImageUploadItem 
            productID={productData._id} 
            photoData={ photos.action }
          />

        </div>

        <div className={classes.row}>

          <ImageUploadItem 
            productID={productData._id} 
            photoData={ photos.details }
          />
          <ImageUploadItem 
            productID={productData._id} 
            photoData={ photos.location }
          />

        </div>

      </div>

      <Footer
        nextClickHandler={proceedToNextStep}
        backClickHandler={prevStepHandler}
        activeStepInfo = {activeStepInfo}
        productData = {productData}
      />
    </>
  )
}

export default ActivityPhotos; 