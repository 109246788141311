const calculateTotal = (currentSpaceID, live) => {
  let count = 1; //So that we never have a divide by 0 situation
  if (live[currentSpaceID]) {
    const today = live[currentSpaceID].todaysPayments || [];
    const cash = today.find(e => e.type==="cash") ? today.find(e => e.type==="cash").amount : 0;
    const card = today.find(e => e.type==="card") ? today.find(e => e.type==="card").amount : 0;
    const unpaid = today.find(e => e.type==="unPaid") ? today.find(e => e.type==="unPaid").amount : 0;
    if (cash === 0 && card ===  0 && unpaid === 0) {
      count = 1
    } else {
      count = cash + card + unpaid;
    }
  }
  return count;
}

const checkIns = (currentSpaceID, live) => {
  let count = 0;
  if (live[currentSpaceID]) {
    const today = live[currentSpaceID].checkIns || [];
    count = today.find(e => e._id.status==="In House") ? today.find(e => e._id.status==="In House").count : 0
  }
  return count
}

const checkOuts = (currentSpaceID, live) => {
  let count = 0;
  if (live[currentSpaceID]) {
    const today = live[currentSpaceID].checkOuts || [];
    count = today.find(e => e._id.status==="Checked Out") ? today.find(e => e._id.status==="Checked Out").count : 0
  }
  return count
}

const totalCheckIns = (currentSpaceID, live) => {
  let count = 0;
  if (live[currentSpaceID]) {
    const today = live[currentSpaceID].checkIns || [];
    count = (today.find(e => e._id.status==="Confirmed") ? today.find(e => e._id.status==="Confirmed").count : 0) +
            (today.find(e => e._id.status==="In House") ? today.find(e => e._id.status==="In House").count: 0)
  }
  return count
}

const totalCheckOuts = (currentSpaceID, live) => {
  let count = 0;
  if (live[currentSpaceID]) {
    const today = live[currentSpaceID].checkOuts || [];
    count = (today.find(e => e._id.status==="Checked Out") ? today.find(e => e._id.status==="Checked Out").count : 0) +
            (today.find(e => e._id.status==="In House") ? today.find(e => e._id.status==="In House").count: 0)
  }
  return count
}

const calculateOccupancy = (value) => {
    return value ? Math.round(value * 100) : 0
}
const fetchOccupancyForToday = (currentSpaceID, live) => {
  let todayChart, ytdChart, mtdChart, lyChart;

  if (!live[currentSpaceID]) {
    return { todayChart, ytdChart, mtdChart, lyChart }
  }
  let liveSpace = live[currentSpaceID] || {};
  let today = {}, mtd = {}, ytd = {}, ly = {}

  if(liveSpace.todaysOccupancy) today = liveSpace.todaysOccupancy
  if(liveSpace.MTDOccupancy) mtd = liveSpace.MTDOccupancy
  if(liveSpace.YTDOccupancy) ytd = liveSpace.YTDOccupancy
  if(liveSpace.LYOccupancy) ly = liveSpace.LYOccupancy


  todayChart = [
    { value: "total", key: "total", occupancy: calculateOccupancy(today.totalOccupancy) },
    { value: "dorm", key: "dorm", occupancy: calculateOccupancy(today.dormOccupancy) },
    { value: "private", key: "private", occupancy: calculateOccupancy(today.privateOccupancy) }
  ]
  ytdChart = [
    { value: "total", key: "total", occupancy: calculateOccupancy(ytd.totalOccupancy) },
    { value: "dorm", key: "dorm", occupancy: calculateOccupancy(ytd.dormOccupancy) },
    { value: "private", key: "private", occupancy: calculateOccupancy(ytd.privateOccupancy) }
  ]
  mtdChart = [
    { value: "total", key: "total", occupancy: calculateOccupancy(mtd.totalOccupancy) },
    { value: "dorm", key: "dorm", occupancy: calculateOccupancy(mtd.dormOccupancy) },
    { value: "private", key: "private", occupancy: calculateOccupancy(mtd.privateOccupancy) }
  ]
  lyChart = [
    { value: "total", key: "total", occupancy: calculateOccupancy(ly.totalOccupancy) },
    { value: "dorm", key: "dorm", occupancy: calculateOccupancy(ly.dormOccupancy) },
    { value: "private", key: "private", occupancy: calculateOccupancy(ly.privateOccupancy) }
  ]

  return { todayChart, ytdChart, mtdChart, lyChart }
}

export { calculateTotal, checkIns, totalCheckIns, checkOuts, totalCheckOuts, fetchOccupancyForToday }