import React from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Checkbox, Grid, InputBase, makeStyles, TextField, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { createRoomType } from '../../../redux/actions/rooms/roomTypes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  container: {
    background: '#ffffff',
    padding: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#F8F9FB',
    borderRadius: '5px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  number: {
    width: '25%'
  },
  currencyField: {
    alignItems: 'center',
    display: 'flex',
    width: '40%'
  },
  currency: {
    marginRight: 10
  },
  subText: {
    fontSize: '1.1rem',
    color: '#999999',
    marginTop: 5,
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#F2F2F2',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    overflow: 'hidden',
    width: 80,
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  },
  link: {
    color: '#66A859',
    cursor: 'pointer',
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  save: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    '&:hover': {
      background: '#FFFFFF'
    },
  },
  bin: {
    height: 20,
    opacity: 0.7,
    width: 20
  },
  saveButton: {
    borderRadius: 5,
    color: '#999999',
    width: '80px',
    '&:hover': {
      background: '#FFFFFF'
    }
  },
  warning: {
    color: '#ff9800',
    marginTop: 10
  },
  input: {
    display: 'none'
  }
}))

const NewRoomType = (props) => {

  const classes = useStyles();
  const { currentSpaceID, currentSpace, propertyID, t } = props;
  const [form, setForm] = React.useState({});
  const [preview, setPreview] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const [imgError, setImgError] = React.useState(false);
  const [error, setError] = React.useState(
    {
      name: false,
      dormOrPrivate: false,
      rackRate: false,
      numberOfUnits: false,
      bedsPerRoom: false
    }
  );

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
    if (e.target.value !== '') {
      setError({ ...error, [e.target.name]: false })
    }
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    if ((e.target.files[0].size / (1024 * 1024)) < 5) {
      setSelectedFile(e.target.files[0])
      setForm({ ...form, image: e.target.files[0] })
      setImgError(false)
    } else {
      setImgError(true)
      // console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
    }
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const numFields = ['numberOfUnits', 'rackRate', 'bedsPerRoom'];
    const mandatoryFields = ['name', 'dormOrPrivate'];

    mandatoryFields.map(item => {
      if (!form[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    numFields.map(item => {
      if (!form[item] || isNaN(form[item])) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    const checkInput = handleValidate();
    if (checkInput) {
      props.dispatch(createRoomType(currentSpaceID, { ...form, propertyID, signUpFlag: true }));
      if (props.modalView) {
        props.handleCloseModal();
      } else {
        setForm({});
        props.handleCancel(props.index);
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={window.innerWidth > 700 ? 5 : 0}>
          <Grid item xs={12} sm={6}>
            <div className={classes.row}>
              <div className={classes.label}>{t('onboarding.roomType.name')}</div>
              <div className={classes.field} style={{ border: error.name ? '1px solid red' : '1px solid #ffffff' }}>
                <InputBase
                  id="name"
                  name="name"
                  fullWidth
                  type="text"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {error.name && <div className={classes.error}>{t('onboarding.roomType.nameError')}</div>}
            </div>

            <div className={classes.row}>
              <div className={classes.picContainer}>
                <div className={classes.placeholder}>
                  {preview ? <img className={classes.image} src={preview} /> :
                    form.dormOrPrivate ?
                      (form.dormOrPrivate == 'private'
                        ? <img src="/images/placeholder-private.png" className={classes.image} />
                        : <img src="/images/placeholder-shared.png" className={classes.image} />)
                      : null}
                </div>
                <label htmlFor="upload-property-image"><div className={clsx(classes.link, classes.label)}>{t('onboarding.roomType.pic')}</div></label>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="upload-property-image"
                  type="file"
                  onChange={handleCapture}
                />
              </div>
              {imgError && <span className={classes.warning}>{t('onboarding.roomType.picError')}</span>}
            </div>

            <div className={classes.row}>
              <div className={classes.label}>{t('onboarding.roomType.room')}</div>
              <div className={clsx(classes.field, classes.number)} style={{ border: error.numberOfUnits ? '1px solid red' : '1px solid #ffffff' }}>
                <InputBase
                  id="numberOfUnits"
                  name="numberOfUnits"
                  type="tel"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {error.numberOfUnits && <div className={classes.error}>{t('onboarding.roomType.roomError')}</div>}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>

            <div className={classes.row}>
              <div className={classes.label}>{t('onboarding.roomType.rate')}</div>
              <div className={clsx(classes.field, classes.currencyField)} style={{ border: error.rackRate ? '1px solid red' : '1px solid #ffffff' }}>
                <span className={classes.currency}>{currentSpace.currency}</span>
                <InputBase
                  id="rackRate"
                  name="rackRate"
                  type="tel"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className={classes.subText}>{t('onboarding.roomType.complexRateError')}</div>
              {error.rackRate && <div className={classes.error}>{t('onboarding.roomType.rateError')}</div>}
            </div>

            <div className={classes.row}>
              <div className={classes.label}>{t('onboarding.roomType.type')}</div>
              <FormControl
                component="fieldset"
                style={{
                  borderLeft: error.dormOrPrivate ? '2px solid red' : '0px solid #ffffff',
                  paddingLeft: error.dormOrPrivate ? '5px' : 0,
                }}
              >
                <RadioGroup row aria-label="position" name="dormOrPrivate" onChange={(e) => handleChange(e)}>
                  <FormControlLabel value="private" control={<Radio color="primary" />} label="Private" />
                  <FormControlLabel value="dorm" control={<Radio color="primary" />} label="Shared" />
                </RadioGroup>
              </FormControl>
              {error.dormOrPrivate && <div className={classes.error}>{t('onboarding.roomType.error')}</div>}
            </div>

            <div className={classes.row}>
              <div className={classes.label}>{t('onboarding.roomType.guest')}</div>
              <div className={clsx(classes.field, classes.number)} style={{ border: error.bedsPerRoom ? '1px solid red' : '1px solid #ffffff' }}>
                <InputBase
                  id="bedsPerRoom"
                  name="bedsPerRoom"
                  type="tel"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {error.bedsPerRoom && <div className={classes.error}>{t('onboarding.roomType.guestError')}</div>}
            </div>
          </Grid>
          
        </Grid>
      </div>

      <div className={classes.save}>
        {!props.modalView && <Button className={classes.cancelButton} onClick={() => props.handleCancel(props.index)}>
          <img src="/images/icons/bin.png" className={classes.bin} />
        </Button>}
        <Button className={classes.saveButton} variant="outlined" onClick={() => handleSubmit()}>{t('actions.save')}</Button>
      </div>


    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  currentSpaceID: state.dashboard.currentSpace,
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID
})

export default withTranslation()(connect(mapStateToProps)(NewRoomType));