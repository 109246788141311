import http from "../../utils/http"
import { normalize } from "normalizr"
import { channelRefreshSchema } from "../../schema/channelRefreshSchema"
import { fetchChannelRoomType } from "./channelMapping"
import { CHANNEL } from "../../../utils/channels"

export function fetchRefreshTiming(propertyID) {
  const ACTION_NAME = "FETCH_CHANNEL_REFRESH_STATUS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/channels/refreshInfo/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        
        if(Object.keys(data).length === 0)
          return {}

        var normalizedOrder = normalize(data.channels, [ channelRefreshSchema ] )
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.channels });
        return normalizedOrder.entities.channels
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}