import moment from 'moment';

// Process housekeeping records to display in report table
// ------------------------------------------------------------------------
const housekeepingList = (housekeeping, housekeepingCategory, roomTypes, rooms, beds, filter) => {
  let dataFormat = [];
  housekeeping && 
  housekeeping.resArray && 
  housekeeping.resArray.length!==0 && 
  housekeeping.resArray.map(item => {
    if (filter.includes(item.category)) {
      dataFormat.push(
        {
          resvCode: item?.code || '',
          type: item.category && housekeepingCategory.find(e => e.value===item.category).label,
          roomType: item.roomTypeID && roomTypes[item.roomTypeID].name,
          room: item.roomID && rooms[item.roomID].name,
          bed: item.bedID && beds[item.bedID].name,
          name: item.name,
          checkIn: item.checkIn && moment(item.checkIn).format('DD MMM YYYY'),
          checkOut: item.checkOut && moment(item.checkOut).format('DD MMM YYYY'),
          duration: item.duration,
          reservationID: item.reservationID
        }
      )
    }
  });
  return dataFormat;
}

export { housekeepingList };