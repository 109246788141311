import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import RatePlanRow from './RatePlanRow';
import AvailabilityRow from './AvailabilityRow';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    // borderBottom: '1px solid #eeeeee',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    marginBottom: 20,
    paddingTop: 10,
  },
  container: {
    paddingBottom: 15,
  },
  roomType: {
    fontSize: '1.4rem',
    fontWeight: 600,
    overflow: 'hidden',
    paddingBottom: theme.spacing(1),
    paddingTop: 10,
    paddingLeft: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem'
    },
  },
}))

const RoomTypeRate = (props) => {
  const classes=useStyles();

  const {roomTypes, roomTypeID, numberOfDays, rate, selectedRatePlans, ratePlans, reloadRatesViewHandler } = props;
  const roomType = roomTypes[roomTypeID];

  const currentRoomTypeRatePlan =
  rate &&
  rate.ratePlans &&
  rate.ratePlans.find((ratePlan) => {
    return (ratePlan.data[0].roomTypeID = roomTypeID && ratePlan.data[0]._id);
  });

  return(
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.roomType}>
            {roomType.name}
          </div>
        </Grid>
      </Grid>

      {currentRoomTypeRatePlan &&
        <AvailabilityRow numberOfDays={numberOfDays} ratePlan={currentRoomTypeRatePlan} />
      }

      {rate && rate.ratePlans && rate.ratePlans.length > 0 && rate.ratePlans.map(ratePlan =>
        (selectedRatePlans.indexOf(ratePlan.ratePlanID) > -1) && (ratePlans[ratePlan.ratePlanID].roomTypeIDs.indexOf(roomTypeID) > -1) && 
        <RatePlanRow 
          key={ratePlan.ratePlanID} 
          numberOfDays={numberOfDays} 
          ratePlan={ratePlan} 
          roomTypeID={roomTypeID} 
          roomType={roomType} 
          reloadRatesViewHandler={reloadRatesViewHandler} 
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  roomTypes: state.roomTypes,
  ratePlans: state.ratePlans,
})

export default connect(mapStateToProps)(RoomTypeRate);