import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FORM_TYPE } from './Helper';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid',
        minHeight: 'calc(100% - 64px)',
        maxHeight: 'calc(100% - 64px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            maxHeight: '100%'
        }
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#ffffff',
        boxShadow: "0 1px 20px #F2F2F2",
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(3),
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '20px'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 14,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    },
    listContainer: {
        overflowY: 'auto'
    },
    choiceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40,
        marginBottom: 40
    },
    choice: {
        cursor: 'pointer',
        textAlign: 'center',
        padding: 40,
        borderRadius: 20,
        margin: 20,
        boxShadow: '0px 0px 5px 0px rgb(200 201 207 / 75%)',
    },
    icon: {
        width: 40,
        height: 40,
        opacity: 0.4,
        [theme.breakpoints.down('xs')]: {
            width: 20,
            height: 20
        }
    },
    label: {
        color: '#666666',
        fontSize: '1.4rem',
        fontWeight: 600,
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        }
    }
}));

const Choice = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.choiceContainer}>
                {!props.lockedReservation && <div className={classes.choice} onClick={() => props.changeModalHandler(FORM_TYPE.BED_FORM)}>
                    <img className={classes.icon} src={IMAGES.ICONS.shuffle} />
                    <div className={classes.label}>Change room</div>
                </div>}
                <div className={classes.choice} onClick={() => props.changeModalHandler(FORM_TYPE.RATE_FORM)}>
                    <img className={classes.icon} src={IMAGES.ICONS.dollar} />
                    <div className={classes.label}>Change price</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Choice;