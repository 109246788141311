import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";

import BannerSection from "./BannerSection";
import ReviewSection from "./ReviewSection";
import BookingSection from "./BookingSection";
import ManageSection from "./ManageSection";
import SummarySection from "./SummarySection";

import Grid from "@material-ui/core/Grid";
import { getPropertySettings } from "../../redux/actions/microsite/hostelworld";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import BlankState from "./BlankState";
import HWBlankState from "./HWBlankState";

const styles = (theme) => ({
  root: {
    background: "#FFFFFF",
    height: "100%",
    width: "calc(100% - 40px)",
    padding: "10px 20px",
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  leftColumn: {
    background: "#FFFFFF",
    padding: "15px 0px 15px 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    height: 40,
    width: 40,
  },
  title: {
    color: "#484848",
    fontSize: "1.4rem",
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 10,
  },
});

const HostelworldTab = (props) => {
  const { classes, dispatch, currentSpace, channelList } = props;

  useEffect(() => {
    dispatch(getPropertySettings(currentSpace.propertyID));
  }, [currentSpace.propertyID]);

  //BLANK STATE DISPLAY........................
  if (channelList?.Hostelworld?.status == "false") {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className={classes.leftColumn}>
              <BlankState />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <HWBlankState />
          </Grid>
        </Grid>
      </div>
    );
  }

  // //HOSTELWORLD TAB BODYY........................
  if (channelList?.Hostelworld?.status == "true") {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className={classes.leftColumn}>
              <BannerSection propertyNumber={channelList?.Hostelworld?.propertyNumber} />
              <ManageSection />

              <AccessController requiredPermission={MODULE.HW_BOOKINGS.ID}>
                <BookingSection />
              </AccessController>

              <AccessController requiredPermission={MODULE.HW_REVIEWS.ID}>
                <ReviewSection />
              </AccessController>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <SummarySection />
          </Grid>
        </Grid>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const {
    spaces,
    dashboard,
    inbox: { channels },
  } = state;
  return {
    currentSpace: spaces[dashboard.currentSpace],
    channelList: channels?.channelList || [],
  };
};

export default withStyles(styles)(connect(mapStateToProps)(HostelworldTab));
