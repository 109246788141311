import { schema } from 'normalizr';

const expertSchema = new schema.Entity('experts', {}, {
  idAttribute: '_id'
});

const listingSchema = new schema.Entity('listings', {}, {
  idAttribute: '_id'
});

const discussionSchema = new schema.Entity('discussions', {}, {
  idAttribute: '_id'
});

export { expertSchema, listingSchema, discussionSchema }