import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import ParticipantInfoCard from './PaticipantInfoCard';
import { fetchActivityBookings } from '../../../redux/actions/experiences/calendar';

const useStyles = makeStyles(theme =>({
    root:{
        width: '60%',
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    inputText: {
        background: '#ffffff',
        border: 'none',
        borderRadius: '5px',
        color: 'rgba(0, 0, 0, 0.87)',
        overflow: 'hidden',
        boxShadow: '0 2px 20px #F0F0F0',
        '& input': {
            fontSize: '1.4rem',
            padding: '10px 20px',
            height: '100%'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    listContainer:{
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#fff',
        width: '100%',
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '10px',
        paddingBottom: '0px',
        top: '40px',
        borderRadius: '5px',
        '& .participantCardRoot:last-child':{
            borderBottom: 'unset',
            marginBottom: '0px',
        }
    }
}));

const SearchInput = props =>{
    const { dispatch, currentSpace, openBookingMask, t } = props;
    const classes = useStyles();
    const [searchVal, setSearchVal] = useState('');
    const [searchResult, setSearchResult] = useState([]);

    const searchHandler = inputVal =>{
        if(!inputVal.trim()){
            setSearchResult([]);
            return;
        } 
        const params = {
            keywords: inputVal,
            skip: 0,
            limit: 10
        };
        const propertyID = currentSpace.propertyID;
        const response = dispatch(fetchActivityBookings(propertyID, params));
        response.then(data =>{
            if(data) setSearchResult([...data.result]);
        })
    }

    const debounceHandler = useCallback(_.debounce(searchHandler, 750), []); 

    const onChangeHandler = event =>{
        const value = event.target.value;
        setSearchVal(value);
        debounceHandler(value);
    }

    return(
        <div className={classes.root}>
            <TextField
                id="search"
                className={classes.inputText}
                placeholder={t('experiences.calendar.searchReservation')}
                fullWidth
                value={searchVal}
                onChange={onChangeHandler}
                variant="outlined"
            />
            {searchResult.length > 0 && (
                <div className={classes.listContainer}>
                    {searchResult.map(({
                        _id,
                        contact,
                        reservationID,
                        bookingSource = ''
                    })=>(
                        <ParticipantInfoCard 
                            key={_id}
                            bookingID={_id}
                            country={contact.country || 'NA'}
                            guestType={reservationID  ? t('experiences.calendar.inHouseGuest') : t('experiences.calendar.externalGuest') }
                            bookingSource={bookingSource}
                            guestName={`${contact.firstName} ${contact.lastName}`}
                            isPaymentPending={false}
                            openBookingMask={openBookingMask}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = state =>{
    const { spaces,  dashboard } = state;
    return{
        currentSpace: spaces[dashboard.currentSpace],
    }
}

export default withTranslation()(connect(mapStateToProps)(SearchInput));