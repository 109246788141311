import React, { useEffect } from 'react';
import { makeStyles, CircularProgress, formatMs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { updateSettings } from '../../redux/actions/onlineCheckin/onlineCheckin';

import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles( theme => ({
  row: {
  	display: 'flex',
  	flexDirection: 'column',
  	marginBottom: 30
  },
  title: {
  	fontSize: '1.5rem',
  	fontWeight: 600,
  	lineHeight: 1.3
  },
  label: {
    fontSize: '1.2rem',
    color: '#666666',
    fontWeight: 400,
    marginTop: 10
  },
  toggle: {
  	alignItems: 'center',
  	boxShadow: '0 1px 20px #F2F2F2',
  	borderRadius: 25,
  	display: 'flex'
  },
  toggleButton: {
  	alignItems: 'center',
  	color: '#BDBDBD',
  	cursor: 'pointer',
  	display: 'flex',
  	fontSize: '1.2rem',
  	fontWeight: 600,
  	height: 35,
  	justifyContent: 'center',
  	width: '50%'
  },
  left: {
  	borderTopLeftRadius: 25,
  	borderBottomLeftRadius: 25
  },
  right: {
  	borderTopRightRadius: 25,
  	borderBottomRightRadius: 25
  },
  selected: {
  	background: '#4F9581',
  	boxShadow: '-15px 0px 17px -7px #F2F2F2',
  	color: '#FFFFFF'
  },
  bottomMargin: {
    marginBottom: 15
  },
  alignCenter: {
    textAlign: 'center',
    marginBottom: 15
  },
  icon: {
    boxShadow: "0 1px 10px #E0E0E0",
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  rootIcon: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: 20,
    height: 20
  },
  disabledIcon: {
    background: '#808B96',
    borderRadius: 5
  },
  checkedIcon: {
    borderRadius: 5,
    backgroundColor: '#4F9581',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#4F9581'
    }
  },
  topMargin: {
    marginTop: 25
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25
  },
  button: {
    background: '#000000',
    borderRadius: 25,
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '1.2rem'
  },
  link: {
    color: theme.palette.primary.main,
    cursor:'pointer'
  },
  labelTitle: {
    fontSize: '1.3rem',
    color: '#666666',
    fontWeight: 600,
    marginTop: 20
  },
  field: {
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: "0 1px 10px #E0E0E0",
    height: 30,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 2,
    width: '80%'
  },
  picContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex'
  },
  placeholder: {
    marginTop: 15,
    background: '#F6F2EE',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80
  },
  noDisplay: {
    display: 'none'
  },
  picContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex'
  },
  placeholder: {
    marginTop: 15,
    background: '#F6F2EE',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0
  },
  noDisplay: {
    display: 'none'
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80
  },
  errorMessage: {
    color: "#f44336",
    fontSize: 10
  }
}))


const ContactDetails = (props) => {
  const { t } = props
  const [toggle, setToggle] = React.useState(true)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const classes = useStyles()
  const { open, handleClose, currentSpace, stripe, onlineCheckin } = props
  const [message, setMessage] = React.useState(null)

  const [initial, setInitial] = React.useState(true)
  const [info, setInfo] = React.useState({name: '', phone: ''})
  const [error, setError] = React.useState({name: false, phone: false})
  const [picPreview, setPicPreview] = React.useState(null)
  const [selectedPic, setSelectedPic] = React.useState()

  useEffect(() => {
    if (!selectedPic) {
      return
    }

    const objectUrl = URL.createObjectURL(selectedPic);
    setPicPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedPic])

  if (onlineCheckin.pointOfContact && initial) {
    setInitial(false)
    const data = {
      name: (onlineCheckin.pointOfContact.name) ? onlineCheckin.pointOfContact.name : '',
      phone: onlineCheckin.pointOfContact.phone ? onlineCheckin.pointOfContact.phone : ''
    }

    if(onlineCheckin.pointOfContact.contactPic) {
      setPicPreview(onlineCheckin.pointOfContact.contactPic)
    }

    setInfo(data)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleToggle = () => {
    setOpenSnackbar(true)
    setMessage('Contact Details section cannot be turned off.')
  }

  const handleSave = () => {
    // if((info.name.length === 0) || (info.phone.length === 0) || (!picPreview)) {
    //   setMessage('Please make sure to fill all the details before saving.')
    //   setOpenSnackbar(true)
    //   return 
    // }
    if(info.name.length === 0) {
      return setError({...error, name: true})
    }
    if(info.phone.length === 0) {
      return setError({...error, phone: true})
    }

    const data = {
      ...info,
      contact: selectedPic
    }

    props.dispatch(updateSettings(currentSpace.propertyID, 'pointOfContact', data))
    .then((response) => {
      if(response && response.status === 200) {
        setMessage('Payment settings have been saved.')
        setOpenSnackbar(true)
        setSave(false)
      }
      else {
        setMessage('Your settings has not been saved. Please try again!')
        setOpenSnackbar(true)
      }
    })
  }

  const handleChange = (e) => {
    let newInfo = {...info, [e.target.name]: e.target.value}
    setError({...error, [e.target.name]: false})
    setInfo(newInfo)
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedPic(undefined)
      return
    }

    if ((e.target.files[0].size / (1024 * 1024)) < 2) {
      setSelectedPic(e.target.files[0])
      return
    } else {
      console.log('Error')
    }
  }

  const handleRemovePicture = (type) => {
    setPicPreview(null)
    setSelectedPic(null)
  }

  return (
    <div>
      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <div className={classes.title}>
              {t('automate.onlineCheckin.steps.pointOfContact.name')}
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.toggle}>
              <div 
                className={clsx(classes.toggleButton, classes.left)} 
                style={{background: '#F2F2F2'}}
                onClick={() => handleToggle()}
              >
                OFF
              </div>
              <div 
                className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: true})}
              >
                ON
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      	<Grid container alignItems="center">
	      	<Grid item xs={12}>
        		<div className={classes.row}>
          			<Grid container alignItems="center">
            			<Grid item xs={7}>
              				<div className={classes.label}>
                				{t('automate.onlineCheckin.steps.pointOfContact.contactName')}<sup style={{color: 'red'}}>*</sup>
              				</div>
                      {error.name && <div className={classes.errorMessage}>
                        <span>{t('automate.onlineCheckin.steps.pointOfContact.nameError')}</span>
                      </div>}
            			</Grid>
            			<Grid item xs={5}>
              				<div className={classes.field}>
                				<InputBase 
									id="name"
									name="name"
									value={`${info.name}`}
									fullWidth
									type="text"
									onChange={(e) => handleChange(e)}
                				/>
              				</div>
            			</Grid>
            			<Grid item xs={7}>
              				<div className={classes.label}>
                				{t('automate.onlineCheckin.steps.pointOfContact.contactPhone')}<sup style={{color: 'red'}}>*</sup>
              				</div>
                      {error.phone && <div className={classes.errorMessage}>
                        <span>{t('automate.onlineCheckin.steps.pointOfContact.phoneError')}</span>
                      </div>}
            			</Grid>
            			<Grid item xs={5}>
              				<div className={classes.field}>
                				<InputBase 
									id="phone"
									name="phone"
									value={`${info.phone}`}
									fullWidth
									type="text"
									onChange={(e) => handleChange(e)}
                				/>
              				</div>
            			</Grid>
            			<Grid item xs={12} className={classes.topMargin}>
                    <div className={classes.label}>{t('automate.onlineCheckin.steps.pointOfContact.person')}</div>
                    <div className={classes.picContainer}>
              				<div className={classes.placeholder}>
              				  	{picPreview && <img className={classes.image} src={picPreview} />}
              				</div>
            					{!picPreview &&
            					  	<label htmlFor="upload-logo">
            					    	<div className={clsx(classes.link, classes.label)}>{t('automate.onlineCheckin.steps.pointOfContact.uploadPic')}</div>
            					  	</label>
            					}
			                {picPreview && <div className={clsx(classes.link, classes.label)} onClick={() => handleRemovePicture()}>
			                    	{t('automate.onlineCheckin.steps.pointOfContact.remove')}
			                </div>}
              				<input
        								accept="image/*"
        								className={classes.noDisplay}
        								id="upload-logo"
        								type="file"
        								onChange={(e) => handleCapture(e)}
              				/>
            				</div>
            			</Grid>
          			</Grid>
        		</div>
	      	</Grid>
	    </Grid>
	    <div className={classes.buttonsContainer}>
        <div className={classes.button} onClick={handleSave}>
            Save
        </div>
      </div>
      {(message) && <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key='top-center'
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />}
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  stripe: state.stripe,
  onlineCheckin: state.onlineCheckin
})

export default withTranslation()(connect(mapStateToProps)(ContactDetails))