import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { fetchDateFormat, DATE_FORMATS } from '../../utils/utility';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#FFF',
    border: "none",
    borderRadius: 5,
    marginBottom: 10,
    padding: theme.spacing(2),
  },
  chip: {
    alignItems: "center",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  smallText: {
    fontSize: "1.1rem",
    paddingRight: 5,
    fontWeight: 600
  },
  highlightedText: {
    color: '#EABF87',
    cursor: 'pointer',
    fontWeight: 600,
  },
  lightText: {
    color: "#828282",
    fontWeight: 400
  },
  note: {
    display: 'block',
    fontSize: '1.4rem',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    width: '100%',
    wordWrap: 'break-word',
  },
}));

const Note = props => {
  const { name, date, timezone, message } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <div className={classes.chip}>
        <Typography component="span" className={clsx(classes.smallText, classes.highlightedText)}>
          {name}
        </Typography>
        <Typography
          component="span"
          className={clsx(classes.lightText, classes.smallText)}
        >
          {fetchDateFormat(date, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, timezone)}
        </Typography>
      </div>
      <Typography className={clsx(classes.lightText, classes.note)} component="p">{message}</Typography>
    </Grid>
  )
}

export default Note;