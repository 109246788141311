import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import currencyCodes from 'currency-codes';

import { createNewSpace, setUserSignupStep } from '../../redux/actions/signup/signupCommunity';

import Select from 'react-select';
import { Button, InputBase, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 30px)',
    marginTop: 30,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  label: {
    fontSize: '1.3rem',
    color: '#666666',
    marginBottom: 10,
    marginTop: 10,
  },
  toggle: {
    alignItems: 'center',
    boxShadow: '0 1px 20px #F2F2F2',
    borderRadius: 25,
    display: 'flex',
  },
  toggleButton: {
    alignItems: 'center',
    color: '#BDBDBD',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    height: 35,
    justifyContent: 'center',
    width: '50%'
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: '#4F9581',
    boxShadow: '-15px 0px 17px -7px #F2F2F2',
    color: '#FFFFFF',
  },
  field: {
    background: '#ffffff',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  picContainer: {
    alignItems: 'center',
    // cursor: 'pointer',
    display: 'flex',
  },
  placeholder: {
    background: '#ffffff',
    borderRadius: '15px',
    height: 80,
    marginRight: 15,
    width: 80,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: 0,
  },
  legal: {
    alignItems: 'center',
    display: 'flex'
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem',
    marginTop: 20,
  },
  input: {
    display: "none"
  },
  warning: {
    color: '#ff9800',
    marginTop: 10
  },
  image: {
    borderRadius: 15,
    objectFit: 'cover',
    height: 80,
    width: 80,
  }
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const propertyType = [
  { value: 'Hostel', label: 'Hostel' },
  { value: 'Hotel', label: 'Hotel' },
  { value: 'Apartment', label: 'Apartment' },
  { value: 'Vacation Rental', label: 'Vacation Rental' },
  { value: 'Other', label: 'Other' }
]

var timezones = [
  { "label": "(GMT-12:00) International Date Line West", "value": "Etc/GMT+12" },
  { "label": "(GMT-11:00) Midway Island, Samoa", "value": "Pacific/Midway" },
  { "label": "(GMT-10:00) Hawaii", "value": "Pacific/Honolulu" },
  { "label": "(GMT-09:00) Alaska", "value": "US/Alaska" },
  { "label": "(GMT-08:00) Pacific Time (US & Canada)", "value": "America/Los_Angeles" },
  { "label": "(GMT-08:00) Tijuana, Baja California", "value": "America/Tijuana" },
  { "label": "(GMT-07:00) Arizona", "value": "US/Arizona" },
  { "label": "(GMT-07:00) Chihuahua, La Paz, Mazatlan", "value": "America/Chihuahua" },
  { "label": "(GMT-07:00) Mountain Time (US & Canada)", "value": "US/Mountain" },
  { "label": "(GMT-06:00) Central America", "value": "America/Managua" },
  { "label": "(GMT-06:00) Central Time (US & Canada)", "value": "US/Central" },
  { "label": "(GMT-06:00) Guadalajara, Mexico City, Monterrey", "value": "America/Mexico_City" },
  { "label": "(GMT-06:00) Saskatchewan", "value": "Canada/Saskatchewan" },
  { "label": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", "value": "America/Bogota" },
  { "label": "(GMT-05:00) Eastern Time (US & Canada)", "value": "US/Eastern" },
  { "label": "(GMT-05:00) Indiana (East)", "value": "US/East-Indiana" },
  { "label": "(GMT-04:00) Atlantic Time (Canada)", "value": "Canada/Atlantic" },
  { "label": "(GMT-04:00) Caracas, La Paz", "value": "America/Caracas" },
  { "label": "(GMT-04:00) Manaus", "value": "America/Manaus" },
  { "label": "(GMT-04:00) Santiago", "value": "America/Santiago" },
  { "label": "(GMT-03:30) Newfoundland", "value": "Canada/Newfoundland" },
  { "label": "(GMT-03:00) Brasilia", "value": "America/Sao_Paulo" },
  { "label": "(GMT-03:00) Buenos Aires, Georgetown", "value": "America/Argentina/Buenos_Aires" },
  { "label": "(GMT-03:00) Greenland", "value": "America/Godthab" },
  { "label": "(GMT-03:00) Montevideo", "value": "America/Montevideo" },
  { "label": "(GMT-02:00) Mid-Atlantic", "value": "America/Noronha" },
  { "label": "(GMT-01:00) Cape Verde Is.", "value": "Atlantic/Cape_Verde" },
  { "label": "(GMT-01:00) Azores", "value": "Atlantic/Azores" },
  { "label": "(GMT+00:00) Casablanca, Monrovia, Reykjavik", "value": "Africa/Casablanca" },
  { "label": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", "value": "Etc/Greenwich" },
  { "label": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", "value": "Europe/Amsterdam" },
  { "label": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", "value": "Europe/Belgrade" },
  { "label": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", "value": "Europe/Brussels" },
  { "label": "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", "value": "Europe/Sarajevo" },
  { "label": "(GMT+01:00) West Central Africa", "value": "Africa/Lagos" },
  { "label": "(GMT+02:00) Amman", "value": "Asia/Amman" },
  { "label": "(GMT+02:00) Athens, Bucharest, Istanbul", "value": "Europe/Athens" },
  { "label": "(GMT+02:00) Beirut", "value": "Asia/Beirut" },
  { "label": "(GMT+02:00) Cairo", "value": "Africa/Cairo" },
  { "label": "(GMT+02:00) Harare, Pretoria", "value": "Africa/Harare" },
  { "label": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", "value": "Europe/Helsinki" },
  { "label": "(GMT+02:00) Jerusalem", "value": "Asia/Jerusalem" },
  { "label": "(GMT+02:00) Minsk", "value": "Europe/Minsk" },
  { "label": "(GMT+02:00) Windhoek", "value": "Africa/Windhoek" },
  { "label": "(GMT+03:00) Kuwait, Riyadh, Baghdad", "value": "Asia/Kuwait" },
  { "label": "(GMT+03:00) Moscow, St. Petersburg, Volgograd", "value": "Europe/Moscow" },
  { "label": "(GMT+03:00) Nairobi", "value": "Africa/Nairobi" },
  { "label": "(GMT+03:00) Tbilisi", "value": "Asia/Tbilisi" },
  { "label": "(GMT+03:30) Tehran", "value": "Asia/Tehran" },
  { "label": "(GMT+04:00) Abu Dhabi, Muscat", "value": "Asia/Muscat" },
  { "label": "(GMT+04:00) Baku", "value": "Asia/Baku" },
  { "label": "(GMT+04:00) Yerevan", "value": "Asia/Yerevan" },
  { "label": "(GMT+04:30) Kabul", "value": "Asia/Kabul" },
  { "label": "(GMT+05:00) Yekaterinburg", "value": "Asia/Yekaterinburg" },
  { "label": "(GMT+05:00) Islamabad, Karachi, Tashkent", "value": "Asia/Karachi" },
  { "label": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", "value": "Asia/Calcutta" },
  { "label": "(GMT+05:30) Sri Jayawardenapura", "value": "Asia/Calcutta" },
  { "label": "(GMT+05:45) Kathmandu", "value": "Asia/Katmandu" },
  { "label": "(GMT+06:00) Almaty, Novosibirsk", "value": "Asia/Almaty" },
  { "label": "(GMT+06:00) Astana, Dhaka", "value": "Asia/Dhaka" },
  { "label": "(GMT+06:30) Yangon (Rangoon)", "value": "Asia/Rangoon" },
  { "label": "(GMT+07:00) Bangkok, Hanoi, Jakarta", "value": "Asia/Bangkok" },
  { "label": "(GMT+07:00) Krasnoyarsk", "value": "Asia/Krasnoyarsk" },
  { "label": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", "value": "Asia/Hong_Kong" },
  { "label": "(GMT+08:00) Kuala Lumpur, Singapore", "value": "Asia/Kuala_Lumpur" },
  { "label": "(GMT+08:00) Irkutsk, Ulaan Bataar", "value": "Asia/Irkutsk" },
  { "label": "(GMT+08:00) Perth", "value": "Australia/Perth" },
  { "label": "(GMT+08:00) Taipei", "value": "Asia/Taipei" },
  { "label": "(GMT+09:00) Osaka, Sapporo, Tokyo", "value": "Asia/Tokyo" },
  { "label": "(GMT+09:00) Seoul", "value": "Asia/Seoul" },
  { "label": "(GMT+09:00) Yakutsk", "value": "Asia/Yakutsk" },
  { "label": "(GMT+09:30) Adelaide", "value": "Australia/Adelaide" },
  { "label": "(GMT+09:30) Darwin", "value": "Australia/Darwin" },
  { "label": "(GMT+10:00) Brisbane", "value": "Australia/Brisbane" },
  { "label": "(GMT+10:00) Canberra, Melbourne, Sydney", "value": "Australia/Canberra" },
  { "label": "(GMT+10:00) Hobart", "value": "Australia/Hobart" },
  { "label": "(GMT+10:00) Guam, Port Moresby", "value": "Pacific/Guam" },
  { "label": "(GMT+10:00) Vladivostok", "value": "Asia/Vladivostok" },
  { "label": "(GMT+11:00) Magadan, Solomon Is., New Caledonia", "value": "Asia/Magadan" },
  { "label": "(GMT+12:00) Auckland, Wellington", "value": "Pacific/Auckland" },
  { "label": "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", "value": "Pacific/Fiji" },
  { "label": "(GMT+13:00) Nuku'alofa", "value": "Pacific/Tongatapu" }
]

const PropertySignupForm = (props) => {

  const classes = useStyles();
  const { createSpaceError } = props;
  const [space, setSpace] = React.useState({});
  const [preview, setPreview] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const [error, setError] = React.useState({type: false, name: false, city: false, country: false, currency: false, timezone: false});
  const [spaceCreationError, setSpaceCreationError] = React.useState(false);
  const [ toggle, setToggle ] = React.useState(true);
  const [imgError, setImgError] = React.useState(false);

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  React.useEffect(() => {
    if (createSpaceError) {
      setSpaceCreationError(true);
      // props.dispatch(resetSpaceCreationError());
    }
  }, [createSpaceError])

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    if ((e.target.files[0].size / (1024 * 1024)) < 5) {
      setSelectedFile(e.target.files[0])
      setSpace({ ...space, image: e.target.files[0] })
      setImgError(false)
    } else {
      setImgError(true)
      // console.log('Please upload file with size less than 5MB', (e.target.files[0].size / (1024 * 1024)))
    }
  }

  const handleRemovePicture = () => {
    setPreview(null);
    setImgError(false)
    setSelectedFile(null);
  }

  const handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
  }
  const countryOptions = handleGetCountries();

  const handleGetCurrencies = () => {
    let codeArray = []
    currencyCodes.codes().map(e => codeArray.push({ value: e, label: e }))
    return codeArray;
  }
  const currencyOptions = handleGetCurrencies();

  const handleChange = (e) => {
    setSpace({ ...space, [e.target.name]: e.target.value })
    if (e.target.value !=='') {
      setError({...error, [e.target.name]: false})
    }
  }

  const handleSelect = (option, type) => {
    setSpace({ ...space, [type]: option.value })
    setError({...error, [type]: false})
  }

  const handleValidate = () => {
    let foundError = false;
    let errorStep = {};
    const mandatoryFields = ['name', 'type', 'city', 'country', 'timezone', 'currency'];
    mandatoryFields.map(item => {
      if (!space[item]) {
        errorStep[item] = true;
        foundError = true;
      }
    })
    if (foundError) {
      setError({ ...error, ...errorStep })
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    setSpaceCreationError(false);
    if (toggle) {
      const check = handleValidate();
      if (check) {
        props.dispatch(createNewSpace(space));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'userAction', 'eventCategory': 'Signup', 'eventAction': 'Step 2', 'eventLabel': 'Create new space'});
      }
    } else {
      props.dispatch(setUserSignupStep(4));
    }
  }

  return (
    <div className={classes.root}>

      {spaceCreationError && 
        <div className={classes.error}>
          Unable to create your property listing. Perhaps this has something to do with 
          your image size. Please add a smaller image or remove the image and retry.
        </div>
      }

      {/* <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <div className={classes.title}>
              I own / operate a property
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.toggle}>
              <div 
                className={clsx(classes.toggleButton, classes.left)} 
                style={{background: toggle===false ? '#BDBDBD' : '#FFFFFF', color: toggle===false ? '#FFFFFF' : '#BDBDBD'}}
                onClick={() => setToggle(false)}
              >
                NO
              </div>
              <div 
                className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: toggle===true})}
                style={{background: toggle===false ? '#FFFFFF' : '#4F9581'}}
                onClick={() => setToggle(true)}
              >
                YES
              </div>
            </div>
          </Grid>
        </Grid>
      </div> */}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>What kind of place are you listing</div>
        <div className={classes.field} style={{border: error.type ? '1px solid red' : '1px solid #ffffff'}}>
          <Select
            className={classes.dropdownInput}
            isSearchable={true}
            name="type"
            onChange={(option) => handleSelect(option, 'type')}
            options={propertyType}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
        {error.type && <div className={classes.error}>Please select a property type</div>}
      </div>}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>Property Name</div>
        <div className={classes.field} style={{border: error.name ? '1px solid red' : '1px solid #ffffff'}}>
          <InputBase
            id="name"
            name="name"
            fullWidth
            type="text"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {error.name && <div className={classes.error}>Please enter your property name</div>}
      </div>}

      {toggle && <div className={classes.row}>
          <div className={classes.label}>Property Profile Picture</div>
          <div className={classes.picContainer}>
            <div className={classes.placeholder}>
              {preview && <img className={classes.image} src={preview} />}
            </div>
            {!preview &&
              <label htmlFor="upload-property-image">
                <div className={clsx(classes.link, classes.label)}>Upload profile picture</div>
              </label>
            }
            {preview &&
              <div className={clsx(classes.link, classes.label)} onClick={handleRemovePicture}>
                Remove
            </div>
            }
            <input
              accept="image/*"
              className={classes.input}
              id="upload-property-image"
              type="file"
              onChange={handleCapture}
            />
          </div>
          {imgError &&
            <div className={classes.label}>
              <span className={classes.warning}>The selected file is too large. Please select a file less than 5MB</span>
            </div>}
        </div>}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>City / Town</div>
        <div className={classes.field} style={{border: error.city ? '1px solid red' : '1px solid #ffffff'}}>
          <InputBase
            id="city"
            name="city"
            fullWidth
            type="text"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {error.city && <div className={classes.error}>Please enter your city name</div>}
      </div>}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>Country</div>
        <div className={classes.field} style={{border: error.country ? '1px solid red' : '1px solid #ffffff'}}>
          <Select
            className={classes.dropdownInput}
            isSearchable={true}
            name="country"
            onChange={(option) => handleSelect(option, 'country')}
            options={countryOptions}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
        {error.country && <div className={classes.error}>Please select your country</div>}
      </div>}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>Currency</div>
        <div className={classes.field} style={{border: error.currency ? '1px solid red' : '1px solid #ffffff'}}>
          <Select
            className={classes.dropdownInput}
            isSearchable={true}
            name="timezone"
            onChange={(option) => handleSelect(option, 'currency')}
            options={currencyOptions}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
        {error.currency && <div className={classes.error}>Please select a currency</div>}
      </div>}

      {toggle && <div className={classes.row}>
        <div className={classes.label}>Timezone</div>
        <div className={classes.field} style={{border: error.timezone ? '1px solid red' : '1px solid #ffffff'}}>
          <Select
            className={classes.dropdownInput}
            isSearchable={true}
            name="timezone"
            onChange={(option) => handleSelect(option, 'timezone')}
            options={timezones}
            styles={selectStyle}
            theme={theme => ({
              ...theme,
              border: 0,
              colors: {
                ...theme.colors,
                primary: '#666666',
                primary25: '#dddddd'
              },
            })}
          />
        </div>
        {error.timezone && <div className={classes.error}>Please select a time zone</div>}
      </div>}

      <div className={classes.row}>
        <Button variant="contained" color="primary" className={classes.next} onClick={handleSubmit}>
          {toggle ? 'Next' : 'Skip Property Setup'}
        </Button>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  createSpaceError: state.errors.CREATE_SPACE
});

export default connect(mapStateToProps)(PropertySignupForm);