import {
    checkDateInBetween,
    DATE_FORMATS,
    endOfMonth,
    fetchDateFormat,
    fetchNoOfNights,
    getDatesOfRange,
    getMomentFormat,
    getWeekDateByNumber,
    isSame,
    startOfMonth
} from '../../../../utils/utility';

export const DISPLAY_SETTING = {
    WEEK: 'week',
    MONTH: 'month'
}

export const FORM_TYPE = {
    CHOICE: 'choice',
    RATE_FORM: 'rate',
    BED_FORM: 'bed',
    DEPARTURE: 'departure',
    ARRIVAL: 'arrival',
    EXTEND: 'extend',
    PRE_PONE_CHECK_IN: 'prePoneCheckIn',
    POST_PONE_CHECK_IN: 'postPoneCheckIn',
    MOVE_ACCOMMODATION: 'moveAccommodation',
    EXTEND_ACCOMMODATION: 'extendAccommodation',
    SHORTEN_ACCOMMODATION: 'shortenAccommodation',
}

export const DEVICE_VIEW = {
    SMALL: 'small',
    LARGE: 'large'
}

export const fetchCalendarDates = (layout, dateInfo, view) => {
    let startCalendarDate, endCalendarDate, startDate, endDate;
    let endDateNumber = 7;

    // layout if week
    if (layout === DISPLAY_SETTING.WEEK) {
        startDate = dateInfo.startDate || dateInfo.checkIn;
        // Fetch monday of the week
        // startCalendarDate = getWeekDateByNumber(startDate, 1)
        if (getMomentFormat(startDate).day() === 0) {
            startCalendarDate = getWeekDateByNumber(startDate, -6);
            // Fetch sunday of the week
            endCalendarDate = getWeekDateByNumber(startDate, 0)
        }
        if (getMomentFormat(startDate).day() >= 1) {
            startCalendarDate = getWeekDateByNumber(startDate, 1);
            // Fetch sunday of the week
            endCalendarDate = getWeekDateByNumber(startDate, 7)
        }
    } 
    // layout if month
    if(layout === DISPLAY_SETTING.MONTH){
        startDate = startOfMonth(dateInfo.startDate);
         //if selected date not in check in checkout range
        if(!checkDateInBetween(dateInfo.checkIn, dateInfo.checkOut, startDate) && 
        !isSame(startDate, dateInfo.checkIn, 'month') && 
        !isSame(startDate, dateInfo.checkOut, 'month')){
            return [];
        }

        //same month
        if (isSame(dateInfo.startDate, dateInfo.checkIn, 'month')) {
            startDate = dateInfo.checkIn;
            if (getMomentFormat(startDate).day() === 0)
                startCalendarDate = getWeekDateByNumber(startDate, -6);
            else if (getMomentFormat(startDate).day() >= 1)
                startCalendarDate = getWeekDateByNumber(startDate, 1);
        }
        else startCalendarDate = getWeekDateByNumber(startDate, 1)

        //if date range in same month
        if (isSame(startDate, dateInfo.checkOut, 'month')){
            if(getMomentFormat(dateInfo.checkOut).day() === 0) 
                endCalendarDate = dateInfo.checkOut;
            else if(getMomentFormat(dateInfo.checkOut).day() === 1) 
                endCalendarDate = getWeekDateByNumber(dateInfo.checkOut, 0);
            else 
                endCalendarDate = getWeekDateByNumber(dateInfo.checkOut, endDateNumber)
        }
         //if date range in spans multiple months
        if(!isSame(startDate, dateInfo.checkOut, 'month')){
            endCalendarDate = endOfMonth(startDate);
        }
    }
    // console.log(startDate, endDate, startCalendarDate, endCalendarDate)
    let dates = getDatesOfRange(startCalendarDate, endCalendarDate, null, 'stringArray')
    if (view && view === DEVICE_VIEW.SMALL) {
        let rows = [...Array(Math.ceil(dates.length / 4))];
        return rows.map(function (row, d) { return dates.slice(d * 4, d * 4 + 4) });
    } else {
        let rows = [...Array(Math.ceil(dates.length / 7))];
        return rows.map(function (row, d) { return dates.slice(d * 7, d * 7 + 7) });
    }
}

export const GetNextWeekStart = (date) => {
    return getWeekDateByNumber(date, 8)
}

export const GetNextWeekEnd = (date) => {
    return getWeekDateByNumber(date, 14)
}

export const GetPreviousWeekStart = (date) => {
    return getWeekDateByNumber(date, -6)
}

export const GetPreviousWeekEnd = (date) => {
    return getWeekDateByNumber(date, 0)
}

export const GetCurrentWeekStart = (date) => {
    return getWeekDateByNumber(date, 1)
}

export const GetCurrentWeekEnd = (date) => {
    return getWeekDateByNumber(date, 7)
}

export const GetPreviousMonthStart = (date) => {
    return getMomentFormat(date).subtract(1, 'months').startOf('month').format(DATE_FORMATS.DEFAULT_DATE)
}

export const GetNextMonthStart = (date) => {
    return getMomentFormat(date).add(1, 'months').startOf('month').format(DATE_FORMATS.DEFAULT_DATE)
}


export const GetDateRange = (date1, date2) => {
    if(date1 && !date2){
        return { startDate: date1, endDate: null }
    }
    if(date1 <= date2){
        return { startDate: date1, endDate: date2 }
    }
    if(date2 < date1) {
        return { startDate: date2, endDate: date1 }
    }
}

export const AllowEditDates = (originalStartDate, originalEndDate, newStartDate, newEndDate) => {
    if (fetchNoOfNights(newStartDate, newEndDate) === fetchNoOfNights(originalStartDate, originalEndDate) &&
        !(newEndDate === originalEndDate && newStartDate === originalStartDate)) {
        return true
    }
    if (newEndDate < originalEndDate && newStartDate === originalStartDate) {
        return true
    }
    if (newEndDate > originalEndDate && newStartDate === originalStartDate) {
        return true
    }
    if (newStartDate !== originalStartDate || newEndDate !== originalEndDate) {
        return true
    }
    return false
}


export const buildSemiOptionFormData = (startDate, endDate, timezone, guestNumber, options=[]) => {
    let datesOfRange = getDatesOfRange(startDate, endDate, timezone, 'objectArray');
    let form = { guestNumber: guestNumber, items: datesOfRange };
    for (let dateIndex = 0; dateIndex < form.items.length; dateIndex++) {
        options.map(optionData => {
            let option = { ...optionData };
            if (option.roomTypeID && option.date === form.items[dateIndex].date && !form.items[dateIndex].roomTypeID) {
                form.items[dateIndex].roomTypeID = option.roomTypeID;
                form.items[dateIndex].rate = option.rate || 0;
                form.items[dateIndex].roomID = option.roomID;
                form.items[dateIndex].bedID = option.bedID;
            }
            return option;
        })
    }
    return form;
}


export const fetchAccommodationFormType = (originalStartDate, originalEndDate, newStartDate, newEndDate) => {
    if (fetchNoOfNights(newStartDate, newEndDate) === fetchNoOfNights(originalStartDate, originalEndDate) &&
        !(newEndDate === originalEndDate && newStartDate === originalStartDate)) {
        return FORM_TYPE.ARRIVAL;
    }
    if (newEndDate < originalEndDate && newStartDate === originalStartDate) {
        return FORM_TYPE.DEPARTURE;
    }
    if (newEndDate > originalEndDate && newStartDate === originalStartDate) {
        return FORM_TYPE.EXTEND;
    }
    if (newStartDate < originalStartDate && newEndDate === originalEndDate) {
        return FORM_TYPE.PRE_PONE_CHECK_IN;
    }
    if (newStartDate > originalStartDate && newEndDate === originalEndDate) {
        return FORM_TYPE.POST_PONE_CHECK_IN;
    }
    if (newStartDate < originalStartDate && newEndDate < originalEndDate) {
        return FORM_TYPE.MOVE_ACCOMMODATION;
    }
    if (newStartDate > originalStartDate && newEndDate > originalEndDate) {
        return FORM_TYPE.MOVE_ACCOMMODATION;
    }
    if (newStartDate < originalStartDate && newEndDate > originalEndDate) {
        return FORM_TYPE.EXTEND_ACCOMMODATION;
    }
    if (newStartDate > originalStartDate && newEndDate < originalEndDate) {
        return FORM_TYPE.SHORTEN_ACCOMMODATION;
    }
}

// Return only the required fields if present in the array
export const fetchRequiredKeyValue = (items, keys) => {
    return items.map(item => {
        let data = {};
        keys.forEach(key => {
            if (item[key] !== null && item[key] !== undefined)
                data[key] = item[key];
        });
        return data;
    })
}

export const validateEditReservationPayload = (items, keys) => {
    let error = false, errorMessage = '';
    if (!items) {
        errorMessage = errorMessage + ' Items not found';
        error = true;
        return {
            error, errorMessage
        }
    }

    items.forEach(item => {
        let flag = keys.every(function (key) {
            return item.hasOwnProperty(key);
        });

        if(errorMessage && !flag){
            errorMessage += ', '+ fetchDateFormat(item.date, 'DD MMM YYYY');
        }

        if(!errorMessage && !flag){
            error = true;
            errorMessage = 'Beds not available on '+ fetchDateFormat(item.date, 'DD MMM YYYY');
        }
    })

    return {
        error, errorMessage
    }

}