import React from "react";
import { makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import CashDrawerList from "./CashDrawerList";
import {
  resetCashDrawers,
  postNewCashDrawer,
} from "../../redux/actions/cash-drawers/cashDrawers";
import { returnCurrent } from "../../redux/selectors/dashboard";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Hidden from "@material-ui/core/Hidden";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      left: "15px",
    },
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  createSection: {
    alignItems: "flex-end",
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  contactDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  contact: {
    width: "47%",
  },
  pin: {
    marginRight: 25,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: 10,
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)",
    },
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const CashDrawers = (props) => {
  const { t } = props;
  const [title, setTitle] = React.useState("");
  const [newCashDrawer, setNewCashDrawer] = React.useState(false);
  const classes = useStyles();

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleClose = () => {
    setNewCashDrawer(false);
    props.handleCloseCashDrawerModal();
  };

  const handleNewCashDrawerSubmit = () => {
    if (props.currentSpace && props.currentSpace.propertyID) {
      console.log("submit ", title);
      props.dispatch(
        postNewCashDrawer(
          props.currentSpace._id,
          props.currentSpace.propertyID,
          title
        )
      );
    } else {
      console.log(
        "ERROR: Current space not loaded or no property ID has been setup"
      );
    }
    setNewCashDrawer(false);
    setTitle("");
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* {!newCashDrawer && (
            <Hidden smDown><Button 
              size='small' 
              onClick={() => setNewCashDrawer(true)} 
              className={classes.newListButton} 
              color='primary' 
              variant='outlined'
            >
              New Cash Drawer
            </Button></Hidden>
          )} */}
          {!newCashDrawer && (
            <AddIcon
              className={classes.addButton}
              onClick={() => setNewCashDrawer(true)}
            />
          )}
          <Typography className={classes.header}>
            {t("frontdesk.cashDrawer.name")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.CASH_DRAWERS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          {/* ------------------------------------------------ */}
          {/* CREATE NEW CASH DRAWER */}
          {/* ------------------------------------------------ */}
          <div
            className={classes.createSection}
            style={{ display: newCashDrawer === false ? "none" : "" }}
          >
            <TextField
              margin="dense"
              id="title"
              fullWidth
              label={t("frontdesk.cashDrawer.newName")}
              type="text"
              onChange={onTitleChange}
              value={title}
            />
            <div className={classes.buttonHolder}>
              <CloseIcon
                className={classes.button}
                onClick={() => setNewCashDrawer(false)}
              />
              <DoneIcon
                className={classes.button}
                onClick={handleNewCashDrawerSubmit}
              />
            </div>
          </div>

          {/* --------------------------------------------------------- */}
          {/* Staff List */}
          {/* --------------------------------------------------------- */}
          <div className={classes.listSection}>
            <CashDrawerList handleClose={handleClose} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
});

export default withTranslation()(connect(mapStateToProps)(CashDrawers));
