import http from "../../utils/http";
import { normalize } from 'normalizr';
import { messageSchema } from "../../schema/messageSchema";
import { setCurrentChat } from "../dashboard/dashboard";

//----------------------------------------------------------------
// LOAD MESSAGES
//----------------------------------------------------------------
export function loadMessages() {
  const ACTION_NAME = "LOAD_MESSAGES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get('/api/chatRoom')
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ messageSchema ] );
        // console.log(normalizedOrder.entities);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.messages });
      })
      .catch(errorResult => {
        console.log('Error', errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// NEW MESSAGE
//----------------------------------------------------------------
export function newMessage(members) {
  const ACTION_NAME = "NEW_MESSAGE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/chatRoom', {members: members})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        dispatch(setCurrentChat(data.chatRoomID))
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UNREAD MESSAGE NOTIFICATION
//----------------------------------------------------------------
export const newUnreadMessage = (message) => ({
  type: `NEW_UNREAD_MESSAGE`, 
  payload: message 
});

//----------------------------------------------------------------
// MARK MESSAGE READ
//----------------------------------------------------------------
export const markMessageRead = (message) => ({
  type: 'MARK_MESSAGE_READ', 
  message
})

