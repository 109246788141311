import moment from 'moment';

const colorArray = [
  "#4F9581",
  "#AEC1E1",
  "#F2D073",
  "#E37381",
  "#E6BBB3",
  '#C17C74',
  '#586F6B',
  '#AC9969',
  '#7A6F9B',
  '#30638E'
];

const calcSum = (array) => {
  let sum = 0;
  array.length && array.map(e => sum+= e.amount);
  return sum;
}

// Process booking metrics to display in report graph
// ------------------------------------------------------------------------
const bookingMetricGraphProcessing = (bookingMetrics, submittedDates, reportSettings, level) => {
  let barLabels = [];
  let barData = [];
  
  let difference = level==='daily' ? 'day' : 'month';
  let dateCount = submittedDates.startDate.clone().subtract(1, difference);
  do {
    dateCount = dateCount.add(1, difference);
    barLabels.push(level==='daily' ? dateCount.format('DD MMM') : dateCount.format('MMM YYYY'));
  } while (!dateCount.isSame(submittedDates.endDate, difference))

  reportSettings.map((reportItem, index) => {
    const { metric, label} = reportItem;

    let barItem = { 
      label,
      backgroundColor: colorArray[index] 
    };

    if (bookingMetrics && bookingMetrics[label] && bookingMetrics[label].length!==0) {
      let dateCounter = submittedDates.startDate.clone().subtract(1, difference);
      // barItem.backgroundColor = colorArray[index];
      // barItem.label = label;
      barItem.data=[];
      do {
        dateCounter = dateCounter.add(1, difference);
        barItem.data.push(bookingMetrics[label].find(e => moment(e.date).isSame(dateCounter, difference)) ? 
                    Number.parseFloat(bookingMetrics[label].find(e => moment(e.date).isSame(dateCounter, difference))[metric]).toFixed(2) : 
                    0)
      } while (!dateCounter.isSame(submittedDates.endDate, difference))
    }
    barData.push(barItem);
  })

  // console.log('graph data', barLabels, barData);

  return {barLabels, barData};
}

// Process booking metrics to display in report table
// ------------------------------------------------------------------------
const bookingMetricTableProccessing = (availableUnits = [], occupancy = {}, revenue, bookingMetrics, currencyData, submittedDates, reportSettings, level) =>{
  let tableData = [];
  let totalOccupancy = occupancy?.currentYear?.totalOccupancy || [];
  let revArray = (revenue && revenue.totalRevenue) ? revenue.totalRevenue : [];

  // console.log('table data calculation', level);
  // console.log('in here', setRevPAR, bookingMetrics);

  if (availableUnits && totalOccupancy && revArray && 
    revArray.length !== 0 && totalOccupancy.length!==0 && availableUnits.length!==0) {
    
    let difference = level==='daily' ? 'day' : 'month';
    let dateCount = submittedDates.startDate.clone().subtract(1, difference);
    let index = 0;

    do {
      dateCount = dateCount.add(1, difference);
      tableData.push({
        date: difference==='day' ? dateCount.format('DD MMM, YYYY') : dateCount.format('MMM YYYY'), 
        revenue: revArray.find(e => (moment(e.date).isSame(dateCount, difference))) ? 
        revArray.find(e => (moment(e.date).isSame(dateCount, difference))).amount 
                  : 0,
        occupancy: totalOccupancy.find(e => moment(e.date).isSame(dateCount, difference)) ? 
          `${parseFloat(totalOccupancy.find(e => moment(e.date).isSame(dateCount, difference)).occupancy*100).toFixed(2)}%` : 
          '0.00%',
      })

      if (difference=='day') {
        tableData[index]['availableUnits'] = 
          availableUnits.find(e => moment(e.date).isSame(dateCount, difference)) ? 
          availableUnits.find(e => moment(e.date).isSame(dateCount, difference)).availableUnits : 
          0;
        tableData[index]['unitsSold'] = 
          availableUnits.find(e => moment(e.date).isSame(dateCount, difference)) ? 
          availableUnits.find(e => moment(e.date).isSame(dateCount, difference)).unitsSold : 
          0
      }

      reportSettings.map(settingsItem => {
        if (bookingMetrics && bookingMetrics[settingsItem.label]) {
          tableData[index][settingsItem.label] = 
            bookingMetrics[settingsItem.label].find(e => moment(e.date).isSame(dateCount, difference)) ? 
            Number.parseFloat(bookingMetrics[settingsItem.label].find(e => moment(e.date).isSame(dateCount, difference))[settingsItem.metric]).toFixed(2) :
            0
        }
      });

      index++;

    } while (!dateCount.isSame(submittedDates.endDate, difference))
  } 

  // console.log('table data', tableData)
  return tableData;
}


export { bookingMetricGraphProcessing, bookingMetricTableProccessing };