import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { setOnboarding } from '../../redux/actions/landing/uiConfig';
import RoomTypes from './signupDemo/RoomTypes';
// import EmailForm from './signupDemo/EmailForm';
import InviteTeam from './signupDemo/InviteTeam';
import GoLive from './GoLive';
import UploadPhoto from './UploadPhoto';

import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1299,
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 40,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
    [theme.breakpoints.down('xs')]: {
      right: 8,
      top: 8
    },
  },
  container: {
    padding: theme.spacing(5),
    position: 'relative',
    maxWidth: '1000px',
    width: 'calc(100% - 80px)',
    background: '#fff',
    marginTop: '20px',
    marginBottom: 40,
    borderRadius: '5px',
    boxShadow: '0 2px 30px 0 #F0F0F0',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      width: 'calc(100% - 32px)',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  lineOne: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 50,
    }
  },
  boldText: {
    fontWeight: 600,
  },
  smallText: {
    fontSize: '1.2rem',
    marginLeft: 5,
  },
  smallBold: {
    fontSize: '1.2rem',
    fontWeight: 600
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  block: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  description: {
    [theme.breakpoints.down('xs')]:{
      width: 'calc(100% - 60px)',
    }
  },
  stepNumber: {
    alignItems: 'center',
    background: '#BB5533',
    borderRadius: '50%',
    color: '#FFFFFF',
    display: 'flex',
    fontSize: '1.5rem',
    fontWeight: 600,
    justifyContent: 'center',
    marginRight: 20,
    height: 40,
    width: 40,
  },
  stepComplete: {
    // background: '#999999',
    background: '#999999'
  },
  checkIcon: {
    color: '#FFFFFF',
    fontSize: '1.8rem',
  },
  input: {
    display: 'none'
  },
  buttonContainer: {
    marginLeft: 'auto',
    width: 200,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
    }
  },
  button: {
    background: '#FFFFFF',
    borderRadius: 5,
    color: '#000000',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    justifyContent: 'center',
    padding: '15px 25px',
    boxShadow: '0 2px 20px 0 #E0E0E0',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px'
    }
  },
  inProgress: {
    border: '1px solid #999999',
    borderRadius: 10,
    color: '#000000',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    justifyContent: 'center',
    padding: '10px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px'
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  }
}))


const Onboarding = (props) => {
  const classes = useStyles();
  const { currentUser, currentSpace, loadingRooms, loadingOnboardingStatus, uploadingAvatar, t } = props;
  const [completedSteps, setCompletedSteps] = React.useState({});
  const [rooms, setRooms] = React.useState(true);
  const [invite, setInvite] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [live, setLive] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [imgError, setImgError] = React.useState(false);

  React.useEffect(() => {
    let steps={};
    if (currentSpace && currentSpace.roomTypes && currentSpace.roomTypes.length !== 0) {
      steps.rooms = true;
    }
    if (currentUser && currentUser.avatar ) {
      steps.profile = true;
    }
    if (currentSpace && (currentSpace.members.length > 1 || currentSpace.admins.length > 1)) {
      steps.invite = true;
    }
    if (currentSpace && currentSpace.onboardingStatus) {
      steps.live = true;
    }
    setCompletedSteps({...steps});
  }, [currentSpace, currentUser]);

  const handleClose = () => {
    props.dispatch(setOnboarding(false));
  }

  const handleRoomsComplete = () => {
    setCompletedSteps({...completedSteps, rooms: true});
  }

  const handleInviteComplete = () => {
    setCompletedSteps({...completedSteps, invite: true});
  }

  const handleCapture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined)
      return
    }
    let file = e.target.files[0];
    setFile(file)
    if ((file.size / (1024 * 1024)) < 5) {
      setImgError(false)
    } else {
      setImgError(true)
      console.log('Please upload file with size less than 5MB', (file.size / (1024 * 1024)))
    }
  }

  return (
    <div className={classes.root}>

      <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />

        <div className={classes.header}>
          <div className={classes.lineOne}>
            <span className={classes.boldText}>{t('onboarding.setup')}</span>
            <span className={classes.smallText}>{t('onboarding.followSteps')}</span>
          </div>
          <div className={clsx(classes.line, classes.smallBold)}>{Object.keys(completedSteps).length} of 4 complete</div>
        </div>
      
        <Divider className={classes.divider}/>

        <div className={classes.block}>
          {!completedSteps.rooms && <div className={classes.stepNumber}>1</div>}
          {completedSteps.rooms && <div className={clsx(classes.stepNumber, classes.stepComplete)}><CheckIcon className={classes.checkIcon} /></div>}
          <div className={classes.description}>
            <span className={classes.boldText}>{t('onboarding.addRooms')}</span>
            <span className={classes.smallText}>{t('onboarding.addRoomsSubheader')}</span>
          </div>
          <div className={classes.buttonContainer}>
            {loadingRooms && <div className={classes.loading}><CircularProgress /></div>}
            {!rooms && !completedSteps.rooms && <div className={classes.button} onClick={() => setRooms(true)}>{t('onboarding.addRoomsButton')}</div>}
          </div>
        </div>

        {!completedSteps.rooms && !loadingRooms && rooms && <RoomTypes handleNext={handleRoomsComplete} handleSkip={() => setRooms(false)} />}

        <Divider className={classes.divider} />

        <div className={classes.block}>
          {!completedSteps.profile && <div className={classes.stepNumber}>2</div>}
          {completedSteps.profile && <div className={clsx(classes.stepNumber, classes.stepComplete)}><CheckIcon className={classes.checkIcon} /></div>}
          <div className={classes.description}>
            <span className={classes.boldText}>{t('onboarding.addPhoto')}</span>
            <span className={classes.smallText}>{t('onboarding.addPhotoSubheader')}</span>
          </div>
          <div className={classes.buttonContainer}>
            {uploadingAvatar && <div className={classes.loading}><CircularProgress /></div>}
            {!uploadingAvatar && !completedSteps.profile && 
              <div>
                <label className={classes.button} htmlFor="outlined-button-file">
                  {t('onboarding.addPhotoButton')}
                </label>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="outlined-button-file"
                  type="file"
                  onChange={handleCapture}
                />
              </div>
            }
          </div>
        </div>

        {!uploadingAvatar && file && <UploadPhoto open={Boolean(file)} file={file} handleClose={() => setFile(null)} />}

        <Divider className={classes.divider} />

        <div className={classes.block}>
          {!completedSteps.invite && <div className={classes.stepNumber}>3</div>}
          {completedSteps.invite && <div className={clsx(classes.stepNumber, classes.stepComplete)}><CheckIcon className={classes.checkIcon} /></div>}
          <div className={classes.description}>
            <span className={classes.boldText}>{t('onboarding.inviteTeam')}</span>
            <span className={classes.smallText}>{t('onboarding.inviteTeamSubheader')}</span>
          </div>
          <div className={classes.buttonContainer}>
            {!completedSteps.invite && !invite && <div className={classes.button} onClick={() => setInvite(true)}>{t('onboarding.inviteTeamButton')}</div>}
          </div>
        </div>

        {!completedSteps.invite && invite && <InviteTeam handleSkip={() => setInvite(false)} handleNext={handleInviteComplete} />}

        <Divider className={classes.divider} />

        <div className={classes.block} style={{marginBottom: 30}}>
          {!completedSteps.live && <div className={classes.stepNumber}>4</div>}
          {completedSteps.live && <div className={clsx(classes.stepNumber, classes.stepComplete)}><CheckIcon className={classes.checkIcon} /></div>}
          <div className={classes.description}>
            <span className={classes.boldText}>{t('onboarding.live')}</span>
            <span className={classes.smallText}>{t('onboarding.liveSubheader')}</span>
          </div>
          <div className={classes.buttonContainer}>
            {loadingOnboardingStatus && <div className={classes.loading}><CircularProgress /></div>}
            {!loadingOnboardingStatus && !completedSteps.live && !live && <div className={classes.button} onClick={() => setLive(true)}>{t('onboarding.liveButton')}</div>}
            {!loadingOnboardingStatus && completedSteps.live && <div className={classes.inProgress}>{t('onboarding.inProgress')}</div>}
          </div>
          
        </div>

        {!completedSteps.live && !loadingOnboardingStatus && live && <GoLive handleSkip={() => setLive(false)} />}

      </div>

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
  currentSpace: state.spaces[state.dashboard.currentSpace] || {},
  loadingRooms: state.loading.LOAD_ROOMTYPES,
  loadingOnboardingStatus: state.loading.SUBMIT_ONBOARDING,
  uploadingAvatar: state.loading.UPDATE_USER_AVATAR
});

export default withTranslation()(connect(mapStateToProps)(Onboarding));