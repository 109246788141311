import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import AddGradeModal from './AddGradeModal';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';

// import MoreButton from './MoreButton';
// import ConfirmForm from '../../common/ConfirmForm';

import { getExperienceStatus, defaultImageUrl } from '../Activity_Flow/activityHelper';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme =>({
    root:{
        marginBottom: '30px',
        boxShadow: '0 2px 20px #F0F0F0',
        padding: '20px 10px',
        borderRadius: '10px',
        [theme.breakpoints.down("xs")]:{
            boxShadow: 'unset',
            padding: 'unset'
        }
    },
    header: {
        margin: '0px 5px',
        marginBottom: '10px'
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: 10,
    },
    productPic: {
        height: 150,
        width: '100%',
        objectFit: 'cover',
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
      },
    nameText: {
        marginRight: 5
    },
    settingsIcon: {
        borderRadius: '50%',
        cursor: 'pointer',
        height: 15,
        padding: theme.spacing(1),
        width: 15,
        '& img': {
          height: 15,
          opacity: 0.5,
          width: 15,
        },
        '&:hover': {
          background: '#F8F9FB'
        }
    },
    gradesContainer:{
        display: 'flex',
        flexWrap: 'wrap',
    },
    gradeItemContainer:{
        width: '33.33%',
        cursor: 'pointer',
        '& .gradeItem':{
            backgroundColor: '#F8F9FB',
            borderRadius: '5px',
            cursor: 'pointer',
            padding: theme.spacing(2),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '1.3rem',
            margin: '5px',
        },
        [theme.breakpoints.down("sm")]:{
            width: '50%',
        },
        [theme.breakpoints.down("xs")]:{
            width: '100%',
        },
        '& .addGradeItem': {
            alignItems: 'center',
            border: '1px dashed #BDBDBD',
            borderRadius: 5,
            cursor: 'pointer',
            display: 'flex',
            fontSize: '1.3rem',
            margin: '5px',
            padding: theme.spacing(2),
            '&:hover': {
                backgroundColor: '#F8F9FB',
            }
        },
    },
    addIcon: {
        color: '#999999',
        fontSize: '1.5rem',
        marginRight: 5,
    },
    alert: {
        color: '#999999',
        fontSize: '1.2rem',
        marginTop: 10,
        paddingLeft: 5,
    },
    confirmContainer:{
        marginTop: '5px'
    },
    stepsInfo:{
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#666666',
    }
}));

// const moreOptions = [
//     { type: 'edit', label: 'Edit experience', icon: <EditIcon /> },
//     { type: 'delete', label: 'Delete experience', icon: <DeleteIcon /> }
// ];

const ProductListItem = props =>{
    const { 
        productID, 
        editProductHandler, 
        productList, 
        editProductVaritions, 
        t, 
        deleteProductHandler
    } = props;

    const classes = useStyles();
    // const [showGradeForm, setShowGradeForm] = useState(false);
    // const [confirmOpen, setConfirmOpen] = useState(false);
    // const hideGradeForm = () => setShowGradeForm(false);
    const productData = { ...productList[productID] };
    const gradeData = { ...productData.grades };
    const status = getExperienceStatus(productData);

    // const selectHandler = option =>{
    //     switch(option){

    //         case "edit":
    //             editProductHandler(productID);
    //             break;

    //         case "delete":
    //             setConfirmOpen(true);
    //             break;
    //     }
    // }

    return(
        <>
            <div className={classes.root}>

                {/* ----------- PRODUCT ITEM HEADER ---------- */}
                <div className={classes.header}>

                    <Grid container spacing={3} className={classes.headerContainer}>

                        {/* Product Picture */}
                        {/* ------------------------------------------------ */}
                        <Hidden smDown><Grid item md={4} lg={3}>
                            <img className={classes.productPic} src={productData?.photos?.[0]?.original || defaultImageUrl} />
                        </Grid></Hidden>
                        
                        {/* Room Type */}
                        {/* ------------------------------------------------ */}
                        <Grid item sm={12} md={8} lg={9}>
                            <div className={classes.nameContainer}>
                                <Typography variant="h4" className={classes.nameText}>{productData.title}</Typography>
                                
                                <div className={classes.settingsIcon} onClick={() => editProductHandler(productID)}>
                                    <img src={IMAGES.ICONS.settings} />
                                </div>

                                {/* <MoreButton 
                                    options={moreOptions}
                                    selectHandler={selectHandler}
                                >
                                    <div className={classes.settingsIcon}>
                                        <img src="/images/icons/settings.png" />
                                    </div>
                                </MoreButton> */}
                            </div>
                            <div className={classes.stepsInfo}>{status.completedStepCount} of {status.totalStepCount} steps complete</div>
                        
                        </Grid>
                    </Grid>

                    {/* -------------PRODUCT DELETE CONFIRM COMPONENT------------- */}
                    {/* <div className={classes.confirmContainer}>
                        <ConfirmForm
                            title="Delete experience?"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onClose={() => setConfirmOpen(false)}
                            onConfirm={() => deleteProductHandler(productID)}
                            >
                            Are you sure you would like to delete this experience?
                        </ConfirmForm>
                    </div> */}
                    
                </div>

                {/* ----------- ACTIVITY GRADES LIST ---------- */}
                <div className={classes.gradesContainer}>
                    {Object.keys(gradeData).map(gradeID =>(
                        <div 
                            key={`grade_${gradeID}`}
                            className={classes.gradeItemContainer}
                            onClick={() =>editProductVaritions(productID)}
                        >
                            <div className="gradeItem">
                                {gradeData[gradeID].title}
                            </div>
                        </div>
                    ))}

                    {gradeData && Object.keys(gradeData).length > 0 && <div 
                        className={classes.gradeItemContainer}
                        onClick={() =>editProductVaritions(productID)}
                    >
                        <div className="addGradeItem">
                            <AddIcon className={classes.addIcon} />
                            {t('experiences.manage.activity.addVariation')}
                        </div>
                    </div>}
                </div>


                {/* ----------- ZERO PRODUCT GRADES ---------- */}
                {(gradeData && Object.keys(gradeData).length == 0) && 
                    <div className={classes.alert}>
                        {t('experiences.manage.activity.activateExperience')}
                    </div>
                }

                {/* ---- CREATE ACTIVITY GRADES FORM ------ */}
                {/* { showGradeForm && (
                    <AddGradeModal 
                        productID={productID}
                        modalCloseHandler={hideGradeForm}
                    />
                )} */}
            </div>

        </>
    )
}

const mapStateToProps = state =>{
    const { experiences } = state;
    return{
        productList: experiences.activityList || {}
    }
}

export default withTranslation()(connect(mapStateToProps)(ProductListItem));