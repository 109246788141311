import http from '../../utils/http'
import { CHANNEL } from '../../../utils/channels'

export const getPropertySettings = propertyID => {
  const ACTION_NAME = 'GET_PROPERTY_SETTINGS'
  return dispatch => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/settings/${propertyID}`)
      .then(result => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: result.data })
      })
      .catch(error => {
        console.log('Error', error)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error })
      })
  }
}

export const setPropertySettings = (propertyID, payload) => {
  const ACTION_NAME = 'SET_PROPERTY_SETTINGS'
  return dispatch => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/settings/${propertyID}`, payload)
      .then(result => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: result.data })
      })
      .catch(error => {
        console.log('Error', error)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error })
      })
  }
}

export const setMicrositeSettings = (propertyID, payload) => {
  const ACTION_NAME = 'SET_MICROSITE_SETTINGS'
  return dispatch => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/settings/${propertyID}`, payload)
      .then(result => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: result.data })
      })
      .catch(error => {
        console.log('Error', error)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error })
      })
  }
}

export const getInboxReviews = (propertyID, params) => {
  const ACTION_NAME = 'GET_INBOX_REVIEWS'
  return dispatch => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` })
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/reviews/${propertyID}`, { params })
      .then(result => {
        console.log("api/frontdesk/inbox/Hostelworld/reviews", result)
        // dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: result.data });
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return result.data;
      })
      .catch(errorResult => {
        console.log('Error', errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult })
        throw errorResult?.response?.data?.error?.error?.message;
      })
  }
}
//----------------------------------------------------------------
// CREATE REVIEW FLAG
//----------------------------------------------------------------
export function createHostelWorldReviewFlag(payload, propertyID, reviewID) {
  console.log("payload, propertyID, reviewID", payload, propertyID, reviewID)
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_REVIEW_FLAG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/reviewFlag/${propertyID}/${reviewID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult.response);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        // return errorResult;
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}
//----------------------------------------------------------------
// CREATE REVIEW REPLY
//----------------------------------------------------------------
export function createHostelWorldReviewReply(payload, propertyID, reviewID) {
  console.log("payload, propertyID, reviewID", payload, propertyID, reviewID)
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_REVIEW_REPLY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/reviewReply/${propertyID}/${reviewID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult.response);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        // return errorResult;
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}


//----------------------------------------------------------------
// LOAD ROOM TYPES
//----------------------------------------------------------------
export function getHostelWorldRoomTypes(propertyID) {
  const ACTION_NAME = "LOAD_HOSTEL_WORLD_ROOM_TYPES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/roomTypes/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// CREATE ROOM TYPE
//----------------------------------------------------------------
export function createHostelWorldRoomType(payload, propertyID) {
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_ROOM_TYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/roomTypes/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult.response);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        // return errorResult;
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// UPDATE ROOM TYPE
//----------------------------------------------------------------
export function updateHostelWorldRoomType(payload, propertyID) {
  const ACTION_NAME = "UPDATE_HOSTEL_WORLD_ROOM_TYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/roomTypes/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// DELETE ROOM TYPE
//----------------------------------------------------------------
export function deleteHostelWorldRoomType(payload, propertyID) {
  const ACTION_NAME = "DELETE_HOSTEL_WORLD_ROOM_TYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/roomTypes/${propertyID}/${payload.roomtypeid}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// SET SETTINGS ROOM TYPES
//----------------------------------------------------------------
export const setHostelWorldSettingsRoomTypes = (payload = []) => ({
  type: 'UPDATE_SETTINGS_ROOM_TYPES',
  payload: payload
})


//----------------------------------------------------------------
// LOAD PROMOTIONS
//----------------------------------------------------------------
export function fetchHostelWorldPromotions(propertyID) {
  const ACTION_NAME = "LOAD_HOSTEL_WORLD_PROMOTIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/promotions/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// CREATE PROMOTION
//----------------------------------------------------------------
export function createHostelWorldPromotion(payload, propertyID) {
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_PROMOTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/promotions/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// UPDATE PROMOTION
//----------------------------------------------------------------
export function updateHostelWorldPromotion(payload, id, propertyID) {
  const ACTION_NAME = "UPDATE_HOSTEL_WORLD_PROMOTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/promotions/${propertyID}/${id}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// DELETE PROMOTION
//----------------------------------------------------------------
export function deleteHostelWorldPromotion(payload, propertyID) {
  const ACTION_NAME = "DELETE_HOSTEL_WORLD_PROMOTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/promotions/${propertyID}/${payload.id}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// LOAD HW TAXES
//----------------------------------------------------------------
export function getHostelWorldTaxes(propertyID) {
  const ACTION_NAME = "LOAD_HOSTEL_WORLD_TAXES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/taxes/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// CREATE HW TAX
//----------------------------------------------------------------
export function createHostelWorldTax(payload, propertyID) {
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/taxes/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// DELETE HW TAXES
//----------------------------------------------------------------
export function deleteHostelWorldTax(payload, propertyID) {
  const ACTION_NAME = "DELETE_HOSTEL_WORLD_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/taxes/${propertyID}/${payload.taxID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// LOAD HW RATEPLANS
//----------------------------------------------------------------
export function getHostelWorldRatePlans(propertyID) {
  const ACTION_NAME = "LOAD_HOSTEL_WORLD_RATE_PLANS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/ratePlans/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// CREATE RATE PLANS
//----------------------------------------------------------------
export function createHostelWorldRatePlan(payload, propertyID) {
  const ACTION_NAME = "CREATE_HOSTEL_WORLD_RATE_PLANS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/ratePlans/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// UPDATE RATE PLANS
//----------------------------------------------------------------
export function updateHostelWorldRatePlan(payload, id, propertyID) {
  const ACTION_NAME = "UPDATE_HOSTEL_WORLD_RATE_PLANS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/ratePlans/${propertyID}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// DELETE RATE PLANS
//----------------------------------------------------------------
export function deleteHostelWorldRatePlan(payload, propertyID) {
  const ACTION_NAME = "DELETE_HOSTEL_WORLD_RATE_PLANS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/ratePlans/${propertyID}/${payload.id}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        throw errorResult?.response?.data?.error?.error?.message;
      });
  };
}

//----------------------------------------------------------------
// LOAD HW INVENTORY
//----------------------------------------------------------------
export function getHostelWorldInventory(propertyID, params) {
  const ACTION_NAME = "LOAD_HOSTEL_WORLD_INVENTORY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/inventory/${propertyID}`, { params })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
export function getHostelWorldStats(propertyID) {
  const ACTION_NAME = "GET_HOSTEL_WORLD_STATS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/statistics/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

export function getLatestBookings(params) {
  const ACTION_NAME = "GET_LATEST_BOOKINGS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reports/reservations`, { ...params })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}

//----------------------------------------------------------------
// DELETE HW IMAGES
//----------------------------------------------------------------
export function deleteHostelWorldImages(imageID, propertyID) {
  const ACTION_NAME = "DELETE_HOSTEL_WORLD_IMAGES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/images/${propertyID}/gallery/images/${imageID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data } });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// UPLOAD HW IMAGES
//----------------------------------------------------------------
export function uploadHostelWorldImages(payload, propertyID) {
  const ACTION_NAME = "UPLOAD_HOSTEL_WORLD_IMAGES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/images/${propertyID}/gallery/images/upload`, payload, { headers: { 'Content-Type': 'application/json' } })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data } });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
// UPDATE HW PAYMENT METHOD
//----------------------------------------------------------------
export function updateHostelWorldPaymentMethod(payload, propertyID) {
  const ACTION_NAME = "UPDATE_HOSTEL_WORLD_PAYMENT_METHOD";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/properties/${propertyID}/policies/payment-methods`, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { data } });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}


//----------------------------------------------------------------
export function getHostelWorldInvoices(propertyID) {
  const ACTION_NAME = "GET_HOSTEL_WORLD_INVOICES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/vatinvoices/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}

//----------------------------------------------------------------
export function getHostelWorldInvoicePDF(propertyID, invoiceNO) {
  const ACTION_NAME = "GET_HOSTEL_WORLD_INVOICE_PDF";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/inbox/${CHANNEL.Hostelworld.code}/vatinvoices/${propertyID}/${invoiceNO}`, { responseType: 'arraybuffer' })
      .then(successResult => {
        let blob = new Blob([successResult.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return errorResult;
      });
  };
}