export const locationList = {
    'In house':[
        'Pool',
        'common room',
        'bar',
        'garden',
        'patio', 
        'rooftop'
    ],
    
    'Place of worship':[
        'Cemetery',
        'Church',
        'Mosque',
        'Synagogue',
        'Temple'
    ],

    'Residential venue':[
        'Apartment',
        'Backyard',
        'Common area',
        'Garage',
        'House',
        'Patio',
        'Rooftop patio'
    ],

    'Tourist attraction': [
        'Fountain',
        'Historic site',
        'Lighthouse',
        'Memorial site',
        'Monument',
        'Plaza',
        'Tourist information center',
    ],

    'Government or education venue': [
        'Animal rescue center',
        'City',
        'City hall',
        'Hospital',
        'Library',
        'Neighborhood',
        'Palace',
        'School',
        'University',
    ],

    'Sports and wellness venue': [
        'Bath house',
        'Beauty venue',
        'Gym',
        'Massage studio',
        'Nail salon',
        'Sauna',
        'Sports venue',
        'Wellness venue',
        'Workout studio'
    ],

    'Retail venue': [
        'Bookstores',
        'Boutique',
        'Clothing store',
        'Cosmetics shop',
        'Costume shop',
        'Flea market',
        'Flower shop',
        'Market',
        'Shopping mall',
        'Shops'
    ],

    'Food venue': [
        'Bakery',
        'Beer shop',
        'Brewery',
        'Butcher shop',
        'Cafe',
        'Cheese shop',
        'Cooking school',
        'Delicatessen',
        'Distillery',
        'Farmers market',
        'Fish market',
        'Food court',
        'Food stand',
        'Food truck',
        'Grocery stores',
        'Ice cream shop',
        'Restaurants',
        'Winery'
    ],

    'Entertainment venue': [
        'Amusement park',
        'Aquarium',
        'Arcade',
        'Art gallery',
        'Arts venue',
        'Bar',
        'Beer garden',
        'Casino',
        'Club',
        'Event venue',
        'Film studio',
        'Jazz club',
        'Karaoke',
        'Movie theater',
        'Museums',
        'Music venue',
        'Observatory',
        'Pub',
        'Stadium',
        'Theater venue',
        'Wine bar',
        'Zoo'
    ],

    'In nature': [
        'Bay',
        'Beach',
        'Campground',
        'Cave',
        'Countryside',
        'Desert',
        'Farm',
        'Field',
        'Forest',
        'Garden',
        'Harbor',
        'Hot Spring',
        'Island',
        'Jungle',
        'Lake',
        'Mountain',
        'Ocean',
        'Parks',
        'Pond',
        'Pool',
        'Rainforest',
        'River',
        'Ski area',
        'Tidepools',
        'Trail',
        'Tundra',
        'Vineyard',
        'Volcano',
        'Waterfall',
        'Waterfront'
    ],
}