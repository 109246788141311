import axios from "axios";

export function printFiscalReceipt(request, settings) {
  // create SOAP envelope
  const ACTION_NAME = "PRINT_FISCAL_RECIEPT";
  return function(dispatch) {
    //console.log(settings)
    if(!settings.toggle)
      return

    if(settings.printingType === 'ip') {

      var soap = '<?xml version="1.0" encoding="utf-16"?>\n' +
        '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
        '<s:Body>\n' +
        request.transaction.printerXml +
        '</s:Body>\n' +
        '</s:Envelope>\n';

      const localPrinterData = localStorage.getItem("printer");
      const printer = JSON.parse(localPrinterData);

      if(!printer?.deviceIp){
        console.error('No deviceIp found')
        return
      }

      const address = "http://" + printer.deviceIp + "/cgi-bin/fpmate.cgi"

      return axios.post(address,
        soap, {
          headers: {
            'Content-Type': 'application/xml;charset=utf-8' ,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
            'SOAPAction': '""'
          }
        }).then(res => {
          var parseString = require('xml2js').parseString; //here i'm using a library colled xml2js to parse the respanse from xml to js object
          var stripNS = require('xml2js').processors.stripPrefix;
          const options = {
            tagNameProcessors: [stripNS],
            explicitArray: false
          };
          parseString(res.data, options, function(err, result) {

            console.log(result, 'xml result'); //get the value from the respanse object

          });
          dispatch({
            type: `${ACTION_NAME}_REQUEST`
          });
        }).catch(err => {
          console.log(err, 'xml err'); 
        });
    }
    else {
      var soap = '<?xml version="1.0" encoding="utf-16"?>\n' + request.transaction.printerXml
      const url = window.URL.createObjectURL(
        new Blob([soap]),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'scontrino.xml'
      )

      document.body.appendChild(link)

      link.click()
      link.parentNode.removeChild(link)
    }
  }
};

export function testPrinterConnection() {
  // create SOAP envelope
  const ACTION_NAME = "TEST_PRINTER_CONNECTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    const localPrinterData = localStorage.getItem("printer");
    const printer = JSON.parse(localPrinterData);

    if(!printer?.deviceIp)
      return ({ status: 400, message: 'No DeviceIP found'});

    const address = "http://" + printer.deviceIp + "/cgi-bin/fpmate.cgi"
    // const address = "http://192.168.1.6:9100/cgi-bin/fpmate.cgi"
    var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
      '<s:Body>\n' +
      '<fpMateConfiguration>\n' +
      '<readDevices />\n' +
      '</fpMateConfiguration>\n' +
      '</s:Body>\n' +
      '</s:Envelope>\n';

    return axios.post(address,
      soap, {
        headers: {
          'Content-Type': 'application/xml;charset=utf-8' ,
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Methods': 'POST, GET',
          //'Access-Control-Allow-Credentials': 'true',
          //'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
          'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
          'SOAPAction': '""'
        },
        timeout: 3000
      }).then(res => {
        var parseString = require('xml2js').parseString; 
        var stripNS = require('xml2js').processors.stripPrefix;
        const options = {
          tagNameProcessors: [stripNS],
          explicitArray: false
        };
        parseString(res.data, options, function(err, result) {
          console.log(result, 'xml result'); 
        });
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`
        })
        return res
      }).catch(err => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: err.toString() });
        return ({status: 400, message: err.toString()})
      });
  }
}

export function handleTestEPOS() {
  // create SOAP envelope
  const ACTION_NAME = "TEST_PRINTER_CONNECTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    const localPrinterData = localStorage.getItem("printer");
    const printer = JSON.parse(localPrinterData);

    if(!printer?.deviceIp)
      return ({ status: 400, message: 'No DeviceIP found'});

    const address = "http://" + printer.deviceIp + "/cgi-bin/epos/service.cgi?devid=" + printer.deviceId + "&timeout=60000"
    // const address = "http://192.168.1.6:9100/cgi-bin/fpmate.cgi"
    var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
      '<s:Body>\n' +
      '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">\n' +
      '<text>Hello World&#10;</text>\n' +
      '<cut type="feed"/>\n' +
      '</epos-print>\n' +
      '</s:Body>\n' +
      '</s:Envelope>\n'

    return axios.post(address,
      soap, {
        headers: {
          'Content-Type': 'application/xml;charset=utf-8' ,
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'POST, GET',
          // 'Access-Control-Allow-Credentials': 'true',
          // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
          'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
          'SOAPAction': '""'
        }
      }).then(res => {
        var parseString = require('xml2js').parseString; 
        var stripNS = require('xml2js').processors.stripPrefix;
        const options = {
          tagNameProcessors: [stripNS],
          explicitArray: false
        };
        parseString(res.data, options, function(err, result) {
          console.log(result, 'xml result'); 
        });
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`
        })
        return res
      }).catch(err => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: err.toString() });
        return ({status: 400, message: err.toString()})
      });
  }
}

export function testPrinterConnectionXML() {
  const ACTION_NAME = "TEST_PRINTER_CONNECTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    const localPrinterData = localStorage.getItem("printer");
    const printer = JSON.parse(localPrinterData);

    if(!printer?.deviceIp)
      return ({ status: 400, message: 'No DeviceIP found'});

    const address = "https://" + printer.deviceIp + "/cgi-bin/fpMateConfiguration.xml"
    // const address = "http://192.168.1.6:9100/cgi-bin/fpmate.cgi"
    var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
      '<s:Body>\n' +
      '<fpMateConfiguration>\n' +
      '<readDevices />\n' +
      '</fpMateConfiguration>\n' +
      '</s:Body>\n' +
      '</s:Envelope>\n';

    return axios.post(address,
      soap, {
        headers: {
          'Content-Type': 'application/xml;charset=utf-8' ,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
          'SOAPAction': '""'
        },
        timeout: 3000
      }).then(res => {
        console.log(res)
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`
        });
        return res
      }).catch(err => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: err.toString() });
        return ({status: 400, message: err.toString()})
      });
  }
}

export function testPrinterVersion() {
  // create SOAP envelope
  const ACTION_NAME = "TEST_PRINTER_CONNECTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    const localPrinterData = localStorage.getItem("printer");
    const printer = JSON.parse(localPrinterData);

    if(!printer?.deviceIp)
      return ({ status: 400, message: 'No DeviceIP found'});

    const address = "http://" + printer.deviceIp + "/cgi-bin/fpmate.cgi"
    // const address = "http://192.168.1.6:9100/cgi-bin/fpmate.cgi"
    var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
      '<s:Body>\n' +
      '<fpMateConfiguration>\n' +
      '<readVersion />\n' +
      '</fpMateConfiguration>\n' +
      '</s:Body>\n' +
      '</s:Envelope>\n';

    return axios.post(address,
      soap, {
        headers: {
          'Content-Type': 'application/xml;charset=utf-8' ,
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'POST, GET',
          // 'Access-Control-Allow-Credentials': 'true',
          // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
          'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
          'SOAPAction': '""'
        },
        timeout: 3000
      }).then(res => {
        var parseString = require('xml2js').parseString; 
        var stripNS = require('xml2js').processors.stripPrefix;
        const options = {
          tagNameProcessors: [stripNS],
          explicitArray: false
        };
        parseString(res.data, options, function(err, result) {
          console.log(result, 'xml result'); 
        });
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`
        })
        return res
      }).catch(err => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: err.toString() });
        return ({status: 400, message: err.toString()})
      });
  }
}

export function testPrinterVersionHTTPS() {
  // create SOAP envelope
  const ACTION_NAME = "TEST_PRINTER_CONNECTION";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    const localPrinterData = localStorage.getItem("printer");
    const printer = JSON.parse(localPrinterData);

    if(!printer?.deviceIp)
      return ({ status: 400, message: 'No DeviceIP found'});

    const address = "https://" + printer.deviceIp + "/cgi-bin/fpmate.cgi"
    // const address = "http://192.168.1.6:9100/cgi-bin/fpmate.cgi"
    var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">\n' +
      '<s:Body>\n' +
      '<fpMateConfiguration>\n' +
      '<readVersion />\n' +
      '</fpMateConfiguration>\n' +
      '</s:Body>\n' +
      '</s:Envelope>\n';

    return axios.post(address,
      soap, {
        headers: {
          'Content-Type': 'application/xml;charset=utf-8' ,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
          'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
          'SOAPAction': '""'
        },
        timeout: 3000
      }).then(res => {
        var parseString = require('xml2js').parseString; 
        var stripNS = require('xml2js').processors.stripPrefix;
        const options = {
          tagNameProcessors: [stripNS],
          explicitArray: false
        };
        parseString(res.data, options, function(err, result) {
          console.log(result, 'xml result'); 
        });
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`
        })
        return res
      }).catch(err => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: err.toString() });
        return ({status: 400, message: err.toString()})
      });
  }
}
// create XMLHttpRequest object
// var xhr = createXMLHttpRequest();
// xhr.open('POST', address, true);
// set headers
// xhr.setRequestHeader('Content-Type', 'text/xml; charset=UTF-8');
// xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
// xhr.setRequestHeader('SOAPAction', '""');
// receive event
// var epos = this;
// xhr.onreadystatechange = function() {
//   // receive response message
//   // alert("xhr.readyState = " + xhr.readyState + "\n" + "xhr.status = " + xhr.status);
//   if (xhr.readyState == 4) {
//     if (xhr.status == 200) {
//       fireFiscalReceiveEvent(epos, xhr);
//     } else {
//       fireFiscalErrorEvent(epos, xhr);
//     }
//   }
// }
// // send request message
// xhr.send(soap);
// }

// const createXMLHttpRequest = () => {
//   var xhr = null;
//   if (window.XMLHttpRequest) {
//     xhr = new XMLHttpRequest();
//   } else if (window.ActiveXObject) {
//     xhr = new ActiveXObject('Msxml2.XMLHTTP');
//   } else {
//     throw new Error('XMLHttpRequest is not supported');
//   }
//   return xhr;
// }

// const fireReceiveEvent = (epos, xhr) => {
//   if (epos.onreceive) {
//     var res = xhr.responseXML.getElementsByTagName('response');
//     if (res.length > 0) {
//       // fire onreceive event
//       epos.onreceive({
//         success: /^(1|true)$/.test(res[0].getAttribute('success')),
//         code: res[0].getAttribute('code'),
//         status: parseInt(res[0].getAttribute('status'))
//       });
//     } else {
//       fireErrorEvent(epos, xhr);
//     }
//   }
// }

// //
// // Function: fireErrorEvent method
// // Description: generate the onerror event
// // Parameters:
// //      epos    object      ePOSPrint object
// //      xhr     object      XMLHttpRequest object
// // Return:      none
// //
// const fireErrorEvent = (epos, xhr) => {
//   // fire onerror event
//   if (epos.onerror) {
//     epos.onerror({
//       status: xhr.status,
//       responseText: xhr.responseText
//     });
//   }
// }