import http from "../../utils/http";

const getAuthHeader = (getState) => {
    const { loginData = {} } = getState().hwMigration;
    const authHeader = loginData.token ? { Authorization: `Bearer ${loginData.token}` } : null;
    return authHeader;
}

export const updateHWMigrationInfo = (stepNo, updateAPIParams, data) => {
    const ACTION_NAME = "UPDATE_HW_MIGRATION_INFO";
    // return { 
    //     type: `${ACTION_NAME}_SUCCESS`, 
    //     payload: data
    // }
    
    return function (dispatch, getState) {

        let config = {
            params: { ...updateAPIParams }
        };

        //APPEND AUTH HEADER TO REQUESTS FROM STEP 2 ONWARDS....................
        const authHeader = getAuthHeader(getState);

        if (authHeader) {
            config.headers = { ...authHeader };
        }

        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/hwInbox/migrate/${stepNo}`, data, config)
            .then(successResult => {
                const { data } = successResult;
                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: data
                });

                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                throw new Error(error);
            });
    }
}

export function resetError(action) {
    return function (dispatch) {
        dispatch({ type: `${action}_RESET`, payload: { error: null } });
    };
}

export const setScheduleCallStatus = (spaceID, data) => {
    const ACTION_NAME = "SET_SCHEDULE_CALL_STATUS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/space/${spaceID}`, data)
            .then(successResult => {
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const saveUserProfileLanguge = data => {
    const ACTION_NAME = "SAVE_USER_PROFILE_LANGUAGE";
    return function (dispatch, getState) {

        //APPEND AUTH HEADER TO REQUESTS...................
        let config = {}
        const authHeader = getAuthHeader(getState);

        if (authHeader) {
            config.headers = { ...authHeader };
        }

        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/UIsettings/user`, data, config)
            .then(successResult => {
                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: successResult.data
                });
                return true;
            })
            .catch(errorResult => {
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                console.log("error ", error);
                return;
            });
    }
}