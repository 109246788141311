import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'

import { sidebarMenu } from '../constant'
import { getSubMenuStatus } from '../progressHelper'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&.isActive .arrowIcon': {
      '-webkit-transform': 'rotate(180deg)', /* Chrome and other webkit browsers */
      '-moz-transform': 'rotate(180deg)', /* FF */
      '-o-transform': 'rotate(180deg)', /* Opera */
      '-ms-transform': 'rotate(180deg)', /* IE9 */
      transform: 'rotate(180deg)' /* W3C compliant browsers */
    }
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 500,
    color: '#4f4f4f',
    padding: '10px'
  },
  name: {
    paddingLeft: 10
  },
  subMenuItem: {
    border: '2px solid transparent',
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&.isActive': {
      border: '2px solid',
      borderColor: '#000',
      borderRadius: '20px'
    }
  },
  icon: {
    border: '1px solid',
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    '&.isActive': {
      backgroundColor: '#000',
      backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'32\' height=\'32\' viewBox=\'0 0 32 32\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z\' fill=\'%23fff\' fill-rule=\'nonzero\'/%3E%3C/svg%3E ")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '19px',
      backgroundPosition: '0px 1px'
    }
  }
}))

const SidebarItem = props => {
  const classes = useStyles()

  const {
    menuID,
    activeMenuID,
    navigateToMenuHandler,
    closeDrawerHandler = null,
    settings,
    t
  } = props

  const handleMenuClick = (menuID) => {
    if (activeMenuID === menuID) return

    navigateToMenuHandler(menuID)

    // For mobile mode..................................
    if (closeDrawerHandler) closeDrawerHandler()
  }

  return (
    <>
      <div
        className={clsx(
          classes.title,
          classes.subMenuItem,
          { isActive: menuID === activeMenuID }
        )}
        onClick={() => handleMenuClick(menuID)}
      >
        <div className={classes.name}>{t(`microsite.${sidebarMenu[menuID].id}`)}</div>
        <div className={clsx(classes.icon, {
          isActive: getSubMenuStatus(
            menuID,
            settings
          )
        })}
        />
      </div>
    </>
  )
}

export default withTranslation()(SidebarItem)
