import http from "../../utils/http";

export const fetchAvailabeBeds = (propertyID, params) => {
    const ACTION_NAME = "GET_FLOOR_PLAN_ROOM_MAPS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/floorPlan/availableBeds/${propertyID}`, { params })
            .then(successResult => {
                const { data } = successResult;
                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: {
                        availableBeds: { ...data.availableBed },
                        ratesInfo: { ...data.rates },
                    }
                })
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export const searchCustomers = (propertyID, params) => {
    const ACTION_NAME = "SEARCH_CUSTOMERS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/search/customer/${propertyID}`, { params })
            .then(successResult => {
                const { data } = successResult;
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export const reviewBooking = (propertyID, payload) => {
    const ACTION_NAME = "REVIEW_BOOKING";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/floorPlan/calculate/${propertyID}`, payload)
            .then(successResult => {
                const { availableBeds, preOccupiedBeds } = successResult.data;
                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: {
                        availableBeds,
                        preOccupiedBeds
                    }
                })
                return successResult.data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export const createNewBooking = (propertyID, payload) => {
    const ACTION_NAME = "CREATE_NEW_BOOKING";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.post(`/api/frontdesk/floorPlan/book/${propertyID}`, payload)
            .then(successResult => {
                const { data } = successResult;
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return data;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    }
}

export const updateSelectedBeds = bedInfo =>({
    type: 'UPDATE_SELECTED_BEDS',
    payload: bedInfo
});

export const deleteSelectedRoomBeds = roomID =>({
    type: 'DELETE_SELECTED_ROOM_BEDS',
    payload: roomID
});

export const resetFloorPlan = () =>({
    type: 'RESET_FLOOR_PLAN',
});


