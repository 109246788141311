import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";

import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchTemplateString,
  humanise,
  fetchNoOfNights,
} from "../../../utils/utility";
import { loadActivityLog } from "../../../redux/actions/reservation/editReservation";
import { loadRatePlans } from "../../../redux/actions/rates/ratePlans";
import { returnCurrent } from "../../../redux/selectors/dashboard";

import { makeStyles, Typography, Grid } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownArrow from "@material-ui/icons/ArrowDownward";
import UpArrow from "@material-ui/icons/ArrowUpward";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { LOG } from "../../../common/constants/log";
import { UNALLOCATED } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 10px)",
    overflowY: "auto",
    justifyContent: "flex-start",
    overflow: "hidden",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 0px)",
      marginBottom: 0,
    },
  },
  header: {
    alignItems: "center",
    borderBottom: "0px solid #dddddd",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
  },
  orderBy: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    marginLeft: 15,
    marginRight: 5,
    color: "#BDBDBD",
    "&:hover": {
      color: "#333333",
    },
  },
  sortIcon: {
    marginRight: 5,
  },
  notesContainer: {
    color: "#bdbdbd",
    fontSize: "1.4rem",
    lineHeight: "1.5",
    overflowY: "auto",
    paddingRight: "17px",
    boxSizing: "content-box",
  },
  noteContainer: {
    background: "#FFF",
    border: "none",
    borderRadius: "15px",
    marginBottom: 20,
    padding: 20,
  },
  chip: {
    alignItems: "center",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  smallText: {
    fontSize: "1.1rem",
    paddingRight: 5,
    fontWeight: 600,
  },
  highlightedText: {
    color: "#EABF87",
    cursor: "pointer",
    display: "block",
    fontWeight: 600,
    overflow: "hidden",
  },
  lightText: {
    color: "#828282",
    fontWeight: 400,
    textAlign: "right",
  },
  subject: {
    cursor: "pointer",
    display: "block",
    color: "#666666",
    fontSize: "1.4rem",
    overflow: "auto",
    paddingTop: theme.spacing(1),
    width: "100%",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
  },
  noActivity: {
    paddingTop: 0,
    textAlign: "center",
    color: "#999999",
    fontSize: "1.4rem",
    overflow: "auto",
    width: "100%",
    wordWrap: "break-word",
  },
  resFields: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    marginLeft: 20,
  },
  detail: {
    fontSize: "1.2rem",
    color: "#999999",
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
  },
}));

function stableSort(array, newest) {
  // const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = Math.sign(moment(a[0].createdAt).diff(b[0].createdAt, 'minutes'));
  //   if (order==0) return 0
  //   else if (order !== 0 && !newest) return order
  //   else return (order== -1 ? 1 : -1)
  // });
  // return stabilizedThis.map((el) => el[0]);

  let firstTruthy = newest ? -1 : 1;
  let secondTruthy = newest ? 1 : -1;

  return array.sort((l, m) =>
    l.createdAt > m.createdAt ? firstTruthy : secondTruthy
  );
}

const getRoomTypes = (accomList, roomTypes) => {
  const roomTypeNames = accomList.map(
    (item) => roomTypes?.[item?.roomTypeID]?.name || UNALLOCATED
  );
  return roomTypeNames.join(", ");
};

const getRatePlan = (accomList, ratePlans) => {
  const ratePlanNames = accomList.map(
    (item) => ratePlans?.[item?.ratePlanID]?.privateName
  );
  const uniqNames = [...new Set(ratePlanNames)];
  return uniqNames.join(", ");
};

const formatDateDisplay = (dateArray) => {
  const arrLength = dateArray.length;
  if (arrLength == 0) return "";
  if (arrLength > 1) {
    const dateString = `${dateArray[0]} to ${dateArray[arrLength - 1]}`;
    return dateString;
  }
  return dateArray[0];
};

const getFormattedChangeItems = (data) => {
  let formattedData = [];
  data.forEach((currActivity, index) => {
    if (index == 0) {
      const dateArray = [currActivity.date];
      const dateString = formatDateDisplay(dateArray);
      formattedData.push({ ...currActivity, date: [...dateArray], dateString });
      return;
    }
    const prevActivity = data[index - 1];
    const prevDate = prevActivity.date;
    const formattedDataPrevIndex = formattedData.length - 1;
    if (
      currActivity.initialBed === prevActivity.initialBed &&
      currActivity.finalBed === prevActivity.finalBed &&
      currActivity.initialAmount === prevActivity.initialAmount &&
      currActivity.finalAmount === prevActivity.finalAmount &&
      fetchNoOfNights(prevDate, currActivity.date) === 1
    ) {
      const dateArray = [
        ...formattedData[formattedDataPrevIndex].date,
        currActivity.date,
      ];
      const dateString = formatDateDisplay(dateArray);
      formattedData[formattedDataPrevIndex].date = [...dateArray];
      formattedData[formattedDataPrevIndex].dateString = dateString;
      return;
    }
    const dateArray = [currActivity.date];
    const dateString = formatDateDisplay(dateArray);
    formattedData.push({
      ...currActivity,
      date: [currActivity.date],
      dateString,
    });
  });
  return formattedData;
};

const ActivityLog = (props) => {
  const classes = useStyles();
  const {
    reservationID,
    activityLog,
    currentSpace,
    roomTypes,
    users,
    t,
    ratePlans,
    isSystemAdmin
  } = props;

  const [newestFirst, setNewestFirst] = React.useState(false);
  const [expanded, setExpanded] = React.useState({});
  const [ showSystemActions, setShowSystemActions ] = React.useState(false);

  const fetchActivityLog = () => {
    props.dispatch(loadActivityLog(reservationID));
  };

  React.useEffect(() => {
    if (ratePlans.default)
      props.dispatch(loadRatePlans(currentSpace.propertyID));
  }, []);

  const activityView = stableSort(activityLog, newestFirst).filter(activityLog => !!showSystemActions || !activityLog.isSystemAction).map(
    (logData, index) => {
      let log = { ...logData };
      const text = LOG?.[log?.activity]?.TEXT || null;
      const loopText = LOG?.[log?.activity]?.LOOP_TEXT || null;
      const items = [
        LOG.CHANGE_BED_MULTIPLE.CODE,
        LOG.CHANGE_RATE_MULTIPLE.CODE,
      ].includes(log.activity)
        ? getFormattedChangeItems(log.items)
        : log.items;

      if (log.userID && log.user === "Admin" && users[log.userID]) {
        log.user = users[log.userID].name;
      }
      log.user = humanise(log.user);

      return log ? (
        <Grid item xs={12} key={index} className={classes.noteContainer}>
          <div className={classes.chip}>
            <Typography
              component="span"
              className={clsx(classes.smallText, classes.highlightedText)}
            >
              {fetchDateFormat(
                log.createdAt,
                `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY} ${DATE_FORMATS.YEAR}, ${DATE_FORMATS.TIME}`,
                currentSpace.timezone
              )}
            </Typography>
          </div>
          {log.activity === LOG.CANCEL.CODE ? (
            <Typography className={classes.subject} component="p">
              {log.activity.note && fetchTemplateString(text, log)}
              {!log.activity.note && `Reservation cancelled by ${log.user}`}
            </Typography>
          ) : (
            <Typography className={classes.subject} component="p">
              {fetchTemplateString(text, log)}
              {items &&
                items.length > 0 &&
                (expanded && expanded[log._id] === true ? (
                  <ExpandLessIcon
                    className={classes.actionIcon}
                    onClick={() =>
                      setExpanded({ ...expanded, [log._id]: false })
                    }
                  />
                ) : (
                  <ExpandMoreIcon
                    className={classes.actionIcon}
                    onClick={() =>
                      setExpanded({ ...expanded, [log._id]: true })
                    }
                  />
                ))}
            </Typography>
          )}
          {(log.activity === LOG.CREATE_RESERVATION.CODE ||
            log.activity === LOG.OTA_MODIFY.CODE) && (
            <div className={classes.resFields}>
              <div className={classes.detail}>
                Check In:{" "}
                {fetchDateFormat(
                  log.reservation.checkIn,
                  `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}`,
                  currentSpace.timezone
                )}
              </div>
              <div className={classes.detail}>
                Check Out:{" "}
                {fetchDateFormat(
                  log.reservation.checkOut,
                  `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}`,
                  currentSpace.timezone
                )}
              </div>
              <div className={classes.detail}>
                Room Type:{" "}
                {getRoomTypes(log.reservation.accommodationList, roomTypes)}
              </div>
              <div className={classes.detail}>
                Rate Plan:{" "}
                {getRatePlan(log.reservation.accommodationList, ratePlans)}
              </div>
              <div className={classes.detail}>
                Number of guests: {log.reservation.guestCount}
              </div>
              <div className={classes.detail}>
                Booking Source: {log.reservation.bookingSource}
              </div>
              <div className={classes.detail}>
                Amount: {log.reservation.totalAmount}
              </div>
              <div className={classes.detail}>
                Balance: {log.reservation.balance}
              </div>
              {log.otaRoomTypes && (
                <div className={classes.detail}>
                  OTA RoomType: {log.otaRoomTypes}
                </div>
              )}
              {log.otaRatePlans && (
                <div className={classes.detail}>
                  OTA Rate Plan: {log.otaRatePlans}
                </div>
              )}
            </div>
          )}
          {expanded &&
            expanded[log._id] === true &&
            items?.map((item, itemIndex) => {
              if (
                [
                  LOG.CHANGE_ROOM_AND_ARRIVAL_DATE.CODE,
                  LOG.INTELRES_MOVE.CODE,
                ].includes(log.activity)
              ) {
                return (
                  <div className={classes.resFields} key={itemIndex}>
                    {item.initialBed && (
                      <div className={classes.detail}>
                        Room changed from {item.initialBed} to {item.finalBed}
                      </div>
                    )}
                    {item.initialCheckIn && (
                      <div className={classes.detail}>
                        Arrival date changed from {item.initialCheckIn} to{" "}
                        {item.finalCheckIn}
                      </div>
                    )}
                    {item.initialCheckOut && (
                      <div className={classes.detail}>
                        Departure date changed from {item.initialCheckOut} to{" "}
                        {item.finalCheckOut}
                      </div>
                    )}
                    {item.initialAmount &&
                      item.initialAmount !== item.finalAmount && (
                        <div className={classes.detail}>
                          Reservation rate changed from {log.currency}{" "}
                          {item.initialAmount} to {log.currency}{" "}
                          {item.finalAmount}
                        </div>
                      )}
                  </div>
                );
              }
              return (
                <div key={itemIndex} className={classes.detail}>
                  {fetchTemplateString(loopText, { ...log, ...item })}
                </div>
              );
            })}
        </Grid>
      ) : null;
    }
  );

  const noActivity = (
    <Grid item xs={12} className={classes.noteContainer}>
      <Typography
        className={clsx(classes.lightText, classes.noActivity)}
        component="p"
      >
        {t("existingReservation.activityTab.noActivity")}
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {isSystemAdmin && <div
          onClick={() => setShowSystemActions(!showSystemActions)}
          className={classes.orderBy}
        >
          {showSystemActions ? "Hide" : "Show"} System Actions
        </div>}
        <div onClick={() => fetchActivityLog()} className={classes.orderBy}>
          <RefreshIcon className={classes.actionIcon} />
          Refresh Logs
        </div>

        {newestFirst && (
          <div
            onClick={() => setNewestFirst(false)}
            className={classes.orderBy}
          >
            <UpArrow className={classes.sortIcon} />
            Newest first
          </div>
        )}
        {!newestFirst && (
          <div onClick={() => setNewestFirst(true)} className={classes.orderBy}>
            <DownArrow className={classes.sortIcon} />
            Oldest first
          </div>
        )}
      </div>

      <Grid className={classes.notesContainer} container>
        {activityView.length !== 0 && activityView}
        {activityView.length === 0 && noActivity}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  activityLog: state.reservationDetails.activityLog,
  reservationID: state.reservationDetails.reservation._id,
  roomTypes: state.roomTypes,
  users: state.users,
  ratePlans: state.ratePlans,
  isSystemAdmin: state.auth.user.isSystemAdmin
});

export default withTranslation()(connect(mapStateToProps)(ActivityLog));
