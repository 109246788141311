import http from "../../utils/http";
import { normalize } from 'normalizr';
import { buildingSchema } from "../../schema/roomTypeSchema";
// ----------------------------------------------------------------
// LOAD BUILDINGS
// ----------------------------------------------------------------
export function getBuildings(spaceID, propertyID) {
  const ACTION_NAME = "LOAD_BUILDINGS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/building/${propertyID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(successResult.data);
        var normalizedOrder = normalize(data, [buildingSchema]);
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...normalizedOrder.entities, spaceID } });
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data || errorResult;
        console.log(error);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE BUILDING 
//----------------------------------------------------------------
export function createBuilding(propertyID, spaceID, building) {
  const ACTION_NAME = "CREATE_BUILDING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/building/${propertyID}`, { ...building })
      .then(successResult => {
        const data = successResult.data;
        let normalizedOrder = {
          buildings: {
            [data._id] : data
          }
        }
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { buildings: normalizedOrder.buildings , spaceID }
        });
      })
      .catch(errorResult => {
        console.log(errorResult)
        const error = errorResult.response
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: error.data, code: error.status } });
      });
  };
}

//----------------------------------------------------------------
// EDIT BUILDING
//----------------------------------------------------------------
export function editBuilding(spaceID, buildingID, updatedBuilding) {
  const ACTION_NAME = "EDIT_BUILDING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/frontdesk/building/${buildingID}`, { ...updatedBuilding })
      .then(successResult => {
        const data = successResult.data;
        let normalizedOrder = {
          buildings: {
            [data._id] : data
          }
        }
        console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { buildings: normalizedOrder.buildings, spaceID } });
      })
      .catch(errorResult => {
        const error = errorResult.response
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: error.data, code: error.status } });
      });
  };
}

//----------------------------------------------------------------
// DELETE BUILDING
//----------------------------------------------------------------
export function deleteBuilding(buildingID) {
  const ACTION_NAME = "DELETE_BUILDING";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .delete(`/api/frontdesk/building/${buildingID}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {_id: data} });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
