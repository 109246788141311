const GUEST_DOC_TYPES = [
    { id: 'passport', value: 'Passport', translationID: 'newReservation.guest.passport' },
    { id: 'vaccinePassport', value: 'Vaccine Passport', translationID: 'newReservation.guest.vaccinePassport' },
    { id: 'visa', value: 'Visa', translationID: 'newReservation.guest.visa' },
    { id: 'license', value: 'Drivers Licence', translationID: 'newReservation.guest.license' },
    { id: 'nationalID', value: 'National ID', translationID: 'newReservation.guest.nationalID' },
    { id: 'other', value: 'Other', translationID: 'files.other' },
];

const GENDER_OPTIONS = [
    { value: "Male", label: "Male", translationID: 'newReservation.guest.male' },
    { value: "Female", label: "Female", translationID: 'newReservation.guest.male' },
    { value: "N/A", label: "N/A", translationID: '' }
  ];

export { GUEST_DOC_TYPES, GENDER_OPTIONS };