import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '5px',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        minHeight: '45px'
    },
    legendItem: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '11px',
        marginBottom: '5px',
        paddingRight: '15px',
        boxSizing: 'border-box',
    },
    lengend: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        marginRight: 7,
    },
    inHouse: {
        background: '#f0f2ff',
    },
    checkedOut: {
        background: '#e5f0ec',
    },
    confirmed: {
        background: '#fdf3da',
    },
}));

const colorArray = [
    "#8CB2A4",
    "#AEC1E1",
    "#F2D073",
    "#E37381",
    "#E6BBB3"
];
  
const compare = ( a, b ) => {
    if ( a.count > b.count ){
        return -1;
    }
    if ( a.count < b.count ){
        return 1;
    }
    return 0;
}

const CustomLegend = props =>{
    const classes = useStyles();
    const { currentSpace, live } = props;

    const bookingSource = live[currentSpace] && live[currentSpace].resvSource;
    const data = bookingSource ? 
                bookingSource.sort(compare).slice(0,5).map((e,index) => ({
                  id: e._id.status ? e._id.status : "None",
                  value: e.count, 
                  color: colorArray[index], 
                  label: e._id.status ? e._id.status : "None" 
                }) ) : 
                [];

    return(
        <div className={classes.root}>
            {data.map(item =>(
                <div className={classes.legendItem} key={`legend_${item.id}`}>
                    <div 
                        className={classes.lengend} 
                        style={{ background: item.color }}
                    />
                    <div>{item.label}</div>
                </div>
            ))}
        </div>
    )
} 

const mapStateToProps = state => ({
    currentSpace: state.dashboard.currentSpace,
    live: state.live
});

export default connect(mapStateToProps)(CustomLegend);