const messages = {};

export default (state = messages, action) => {
  switch (action.type) {
    case "LOAD_MESSAGES_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "NEW_MESSAGE_SUCCESS":
      return {
        ...state,
        [action.payload.chatRoomID]: action.payload
      };
    case "USER_LOGOUT":
      return {
        ...action.payload
      }
    case "MARK_MESSAGE_READ":
      return {
        ...state,
        [action.message]: {...state[action.message], unread: false}
      }
    case "NEW_UNREAD_MESSAGE":
      return {
        ...state,
        [action.payload.chatRoomID]: {...action.payload}
      }
    default:
      return state;
  }
};