import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import moment from "moment";

import AccessController from "../permissions/AccessController";
import { MODULE } from "../../common/constants/permission";
import AccessHeaderAction from "../permissions/AcessHeaderAction";
import {
  fetchCurrencyFormat,
  getNumberAbbr,
  getCurrency,
} from "../../utils/utility";
import { revenuePercent } from "../../redux/selectors/live/revenueMetrics";

import { Grid, Typography, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 3px 4px 0 #ECECEC",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    position: "relative",
  },
  header: {
    alignItems: "center",
    // borderBottom: "1px solid #dddddd",
    background: "#transparent",
    color: "#828282",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "flex-start",
    zIndex: "99",
    // margin: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& img": {
      height: 20,
      width: 20,
    },
  },
  cardTitle: {
    marginLeft: 0,
    verticalAlign: "middle",
    width: "100%",
    "& h4": {
      color: "#4f4f4f",
      fontWeight: 600,
    },
  },
  numberContainer: {
    paddingTop: theme.spacing(2),
  },
  leftColumn: {
    borderRight: "0px solid #BDBDBD",
    [theme.breakpoints.down("xs")]: {
      borderRight: "0px solid #FFFFFF",
    },
  },
  title: {
    color: "#333333",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    justifyContent: "center",
    marginBottom: 15,
  },
  numbers: {
    background: "#F8F9FB",
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  year: {
    color: "#666666",
    display: "flex",
    fontSize: "1.1rem",
    justifyContent: "center",
  },
  revenue: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.5rem",
    marginTop: 8,
  },
  accessInfo: {
    marginTop: "unset",
    marginRight: "5px",
    justifyContent: "flex-end",
    '& [data="action-btn"]': {
      display: "none",
    },
    '& [data="access-user-avatar"]': {
      width: "25px",
      height: "25px",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  loadingState: {
    alignItems: "center",
    display: "flex",
    height: 125,
    justifyContent: "center",
    width: "100%",
  },
  red: {
    color: "red",
  },
}));

const RevenueMetricsWidget = (props) => {
  const { t } = props;
  const classes = useStyles();

  const { live, loadingLive, currentSpace, currentSpaceID } = props;
  let currencyData = undefined;
  if (currentSpace) {
    //currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);
    currencyData = {
      code: currentSpace.currency,
      country: currentSpace.country,
    };
  }

  const percentage = useSelector((state) =>
    revenuePercent(state.live, state.dashboard.currentSpace)
  );

  return (
    <AccessController requiredPermission={MODULE.LIVE_REVENUE.ID}>
      <Grid item xs={12}>
        <Grid
          container
          className={clsx(classes.container, classes.displayRow)}
          data-intercom-target={"Revenue Stats"}
        >
          <Grid item xs={12}>
            <div className={classes.header}>
              <div className={classes.cardTitle}>
                <Typography component="h4" variant="h6">
                  {t("live.revenueHeader")}
                </Typography>
              </div>
              <AccessHeaderAction
                className={classes.accessInfo}
                moduleID={MODULE.LIVE_REVENUE.ID}
                maxDisplayLimit={3}
                loadAcessOnModalOpen={true}
              />
            </div>
          </Grid>

          {loadingLive && (
            <Grid item xs={12} className={classes.loadingState}>
              <CircularProgress />
            </Grid>
          )}

          {!loadingLive && (
            <Grid item xs={12} className={classes.numberContainer}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={clsx(classes.displayColumn, classes.leftColumn)}
                >
                  <div className={classes.title}>
                    {t("live.revenueMetrics.monthToDate")}
                  </div>
                  <Grid container className={classes.numbers}>
                    <Grid item xs={4}>
                      <div className={classes.year}>
                        {moment().subtract(1, "year").format("MMM YY")}
                      </div>
                      <div className={classes.revenue}>
                        {currencyData &&
                          live[currentSpaceID] &&
                          getCurrency(
                            currencyData,
                            live[currentSpaceID].LY_MTDRev
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.year}>
                        {moment().format("MMM YY")}
                      </div>
                      <div className={classes.revenue}>
                        {currencyData &&
                          live[currentSpaceID] &&
                          getCurrency(
                            currencyData,
                            live[currentSpaceID].MTDRev
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.year}>+/-</div>
                      <div
                        className={clsx({
                          [classes.revenue]: true,
                          [classes.red]: percentage.MTD < 0,
                        })}
                      >
                        {percentage.MTD !== "NA" &&
                          `${Math.abs(percentage.MTD)}%`}
                        {percentage.MTD === "NA" && "NA"}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.displayColumn}>
                  <div className={classes.title}>
                    {t("live.revenueMetrics.yearToDate")}
                  </div>
                  <Grid container className={classes.numbers}>
                    <Grid item xs={4}>
                      <div className={classes.year}>
                        {moment().subtract(1, "year").format("YYYY")}
                      </div>
                      <div className={classes.revenue}>
                        {currencyData &&
                          live[currentSpaceID] &&
                          getCurrency(
                            currencyData,
                            live[currentSpaceID].LY_YTDRev
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.year}>
                        {moment().format("YYYY")}
                      </div>
                      <div className={classes.revenue}>
                        {currencyData &&
                          live[currentSpaceID] &&
                          getCurrency(
                            currencyData,
                            live[currentSpaceID].YTDRev
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.year}>+/-</div>
                      <div
                        className={clsx({
                          [classes.revenue]: true,
                          [classes.red]: percentage.YTD < 0,
                        })}
                      >
                        {percentage.YTD !== "NA" &&
                          `${Math.abs(percentage.YTD)}%`}
                        {percentage.YTD === "NA" && "NA"}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </AccessController>
  );
};

const mapStateToProps = (state) => ({
  live: state.live,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  loadingLive: state.loading.LOAD_LIVE,
});

export default withTranslation()(
  connect(mapStateToProps)(RevenueMetricsWidget)
);
