import http from "../../utils/http";

//----------------------------------------------------------------
// LOAD REVENUE
//----------------------------------------------------------------
export function loadRevenue(propID, startDate, endDate, level) {
  const ACTION_NAME = "LOAD_REVENUE_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/reports/revenue/${propID}?startDate=${startDate}&endDate=${endDate}&level=${level}`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD REVENUE ITEMS
//----------------------------------------------------------------
export function loadRevenueItems(body) {
  const ACTION_NAME = "LOAD_REVENUE_ITEMS_REPORT";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/frontdesk/reports/itemRevenue`, body)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET REVENUE REPORT
//----------------------------------------------------------------
export const resetRevenueReport = () => ({
  type: 'RESET_REVENUE_REPORT'
})