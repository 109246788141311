import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_FORMATS, fetchCurrencyFormat, fetchDateFormat } from '../../../utils/utility';
import { SingleDatePicker } from 'react-dates';
import PlusMinus from '../../common/PlusMinus';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import ConfirmForm from '../../common/ConfirmForm';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 20,
    padding: 20,
    backgroundColor: '#fff'
  },
  title: {
    fontWeight: 600,
    margin: '10px 0'
  },
  tableContainer: {},
  bold: {
    fontWeight: 600,
  },
  body: {},
  row: {
    display: 'flex',
    margin: 10,
    borderBottom: '1px solid #ececec',
    paddingBottom: 10,
    alignItems: 'center',
  },
  column: {
    width: '30%'
  },
  actionColumn: {
    width: '10%',
    textAlign: 'end'
  },
  actionIcon: {
    marginLeft: 10,
    cursor: 'pointer',
    height: 15,
    width: 15,
    '&svg': {
      height: '1em',
      width: '1em',
    },
    borderRadius: '50%',
    color: '#666666',
    padding: theme.spacing(1),
    transition: '150ms',
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  countform: {
    '& .grid-inc-dec, & .grid-count': {
      flexBasis: 'unset!important',
      '& button': {
        width: '25px !important',
        height: '25px !important',
        '& img': {
          width: '10px !important',
          height: '10px !important',
        }
      },
      '& span': {
        height: '30px!important',
        width: '30px!important',
        minWidth: '25px!important',
        minHeight: '25px!important',
      }
    }
  },
  datepicker: {
    border: '1px solid #eeeeee',
    width: 155,
    background: '#f8f9fb',
    '& input': {
      background: '#f8f9fb',
    }
  }
}));

const EditBookingProducts = (props) => {
  const classes = useStyles();
  const { bookingProducts, productInfo, calendarData } = props;
  const [focused, setFocused] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [form, setForm] = React.useState({ _id: null, field: null, value: null, type: null });

  const onReset = () => {
    setForm({ _id: null, field: null, value: null, type: null });
  }

  const onSave = (oldData) => {
    if (!form._id || oldData[form.field] === form.value) {
      onReset();
    } else if (form.field === 'quantity' && form.value === 0) {
      setConfirm(true);
    }
    else {
      let payload = { products: [{ _id: form._id, [form.field]: form.value }] }
      props.handleEdit(payload);
      onReset()
    }
  }

  const onDelete = (id) => {
    setForm({ ...form, _id: id, type: 'delete' });
    setConfirm(true)
  }

  const onConfirmDelete = () => {
    let payload = {
      products: [{
        _id: form._id,
        quantity: 0
      }]
    }
    props.handleEdit(payload);
    onReset()
  }

  const fetchAvailability = (productID, slotID, availabilityID, selectedDate) => {
    const calendarInfo = calendarData[selectedDate] || [];
    let available;
    calendarInfo.map(data => {
      if (productID === data.productID && slotID === data.slotID && availabilityID === data.availabilityID) {
        available = data.available;
      }
    })
    return available;
  }

  const onCountChange = (count, itemID) => {
    setForm({ _id: itemID, field: 'quantity', value: count, type: 'edit' });
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>Products</div>
      <div className={classes.tableContainer}>
        <div className={classes.bold}>
          <div className={classes.row}>
            <div className={classes.column}>Variation name</div>
            <div className={classes.column}>Date</div>
            <div className={classes.column}>Quantity</div>
            <div className={classes.actionColumn}></div>
          </div>
        </div>
        <div className={classes.body}>
          {bookingProducts.map(({
            _id,
            productID,
            slotID,
            gradeID,
            availabilityID,
            date,
            quantity,
            price
          }) => {
            let available = fetchAvailability(productID, slotID, availabilityID, date)
            return (
              <React.Fragment key={_id}>
                <div className={classes.row}>
                  <div className={classes.column}>
                    <div className={classes.bold}>{productInfo?.[productID]?.grades?.[gradeID]?.title || productInfo?.[productID]?.title}</div>
                    <div>{fetchCurrencyFormat(price.currency)}{(price.raw * quantity)}</div>
                  </div>

                  <div className={classes.column}>
                    {form._id === _id && form.field === 'date' ?
                      <div className={classes.datepicker}>
                        <SingleDatePicker
                          date={moment(form.value)}
                          onDateChange={date => setForm({ ...form, value: fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE), type: 'edit' })}
                          focused={focused}
                          onFocusChange={({ focused }) => setFocused(focused)}
                          id="date"
                          placeholder="Select Date"
                          showClearDate={false}
                          noBorder={true}
                          numberOfMonths={1}
                          isOutsideRange={day => false}
                          displayFormat={'DD MMM YYYY'}
                          daySize={30}
                          hideKeyboardShortcutsPanel
                        />
                      </div>
                      : (
                        <div className={classes.rowContent}>
                          {fetchDateFormat(date, 'D MMM YYYY')}
                          {!form._id && <EditIcon
                            className={classes.actionIcon}
                            onClick={() => setForm({ _id, field: 'date', type: 'edit', value: date })}
                          />}
                        </div>
                      )}
                  </div>

                  <div className={classes.column}>
                    {form._id === _id && form.field === 'quantity' ?
                      <div className={classes.countform}>
                        <PlusMinus
                          maximum={available}
                          fullWidth={true}
                          itemID={_id}
                          onCountChange={onCountChange}
                          prefilledCount={quantity} />
                      </div>
                      : (
                        <div className={classes.rowContent}>
                          {quantity}
                          {!form._id && <EditIcon
                            className={classes.actionIcon}
                            onClick={() => setForm({ _id, field: 'quantity', type: 'edit', value: quantity })}
                          />}
                        </div>
                      )}
                  </div>

                  <div className={classes.actionColumn}>
                    {!form._id && (
                      <img className={classes.actionIcon} src={IMAGES.ICONS.bin} onClick={() => onDelete(_id)} />
                    )}
                    {
                      form._id === _id &&
                      form.type === 'edit' &&
                      <>
                        <SaveIcon className={classes.actionIcon} onClick={() => onSave({ date, quantity })} />
                        <ClearIcon className={classes.actionIcon} onClick={onReset} />
                      </>
                    }
                  </div>
                </div>

                {confirm && form._id === _id && (<div className={classes.row}>
                  <ConfirmForm
                    open={confirm}
                    setOpen={setConfirm}
                    onClose={() => { onReset(); setConfirm(false) }}
                    onConfirm={onConfirmDelete}>
                    Are you sure you would like to delete this product from the booking?
                  </ConfirmForm>
                </div>)}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default EditBookingProducts;