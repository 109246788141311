import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        display: "flex",
        // justifyContent: "flex-end",
        padding: "20px",
    },
    languageSelect: {
        minWidth: "80px",
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
}));

const selectStyle = {
    control: base => ({
      ...base,
      border: `1px solid #e0e0e0`,
      boxShadow: 0,
      fontWeight: 700,
      cursor: 'pointer',
      '&:hover': { border: `1px solid #e0e0e0` },
    }),
    indicatorSeparator: (base, state) =>({
        display: 'none',
        color: '#e0e0e0'
    })
};

const languageArray = [
    { label: 'EN', value: 'en' },
    { label: 'ES', value: 'es' },
    { label: 'IT', value: 'it' },
    { label: 'PT', value: 'pt' },
    { label: 'DE', value: 'de' },
    { label: 'JA', value: 'ja' },
];

const Header = props =>{

    const classes = useStyles();
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = React.useState(languageArray[0]);

    useEffect(() =>{
        const currentLanguage = i18next.language;
        const language = languageArray.find(lang => lang.value == currentLanguage);
        if(language) setSelectedLang(language);
    }, [i18n]);

    const selectLanguage = (data) => {
        setSelectedLang(data)
        i18n.changeLanguage(data.value);
    }

    return (
        <div className={clsx(classes.root, 'inboxlangSelect')}>
            <div className={classes.languageSelect}>
                <Select
                    value={selectedLang}
                    styles={selectStyle}
                    options={languageArray}
                    onChange={(e) => selectLanguage(e)}
                    menuPlacement="auto"
                />
            </div>
        </div>
    )
}

export default Header;
