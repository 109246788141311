

const usersCount = (spaces, currentSpaceID) => {
  let count = 1;
  if (currentSpaceID) {
    const currentSpace = spaces[currentSpaceID]
    if (currentSpace) {
      count = currentSpace.members.length + currentSpace.admins.length + currentSpace.pendingUsers.length;
      // console.log('users count', count);
    }
  }
  return count
}

const adminsCount = (spaces, currentSpaceID) => {
  let count = 1;
  if (currentSpaceID) {
    const currentSpace = spaces[currentSpaceID]
    if (currentSpace) {
      count = currentSpace.admins.length + currentSpace.pendingUsers.length;
      // console.log('admin count', count);
      // 
      // if onboardingStatus !== plus / pro
      // Add pending users to admin count
      // if onboardingStatus = pro / plus, admin count doesn't matter
    }
  }
  return count
}

export {usersCount, adminsCount};