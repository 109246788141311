import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';

import { processMasterwayBills } from '../../redux/selectors/masterway';
import { retryMasterFailedBill } from '../../redux/actions/masterway/masterway';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    button: {
        padding: '4px',
        '& .icon': {
            fontSize: '2rem'
        }
    },
    status: {
        color: '#f44336',
        '&.success': { color: '#49af41' }
    }
}));

const RetryButton = props =>{
    const classes = useStyles();
    const { isSuccess, billID, onClick } = props;

    if(isSuccess) return null;

    const onClickHandler = () =>{
        onClick(billID)
    }

    return (
        <IconButton 
            className={classes.button}
            onClick={onClickHandler}
        >
            <ReplayIcon className='icon'/>
        </IconButton>
    )
}

const FailedBills = props =>{
    const classes = useStyles();
    const { currentSpace, dispatch, failedBills } = props;
    const currencyData = {
        code: currentSpace.currency,
        country: currentSpace.country
    }
    const data = useMemo(() =>processMasterwayBills(failedBills, currencyData), [failedBills, currencyData]);
    const rowCount = data.length;

    const retryBillHandler = id =>{
        dispatch(retryMasterFailedBill(id));
    }

    return (
        <div>
            <MaterialTable 
                title="Failed Bills"
                columns={[
                    { title: 'Reservation Code', field: 'resCode' },
                    { title: 'Guest Name', field: 'guestName' },
                    { title: 'Type of Bill', field: 'billType' },
                    { 
                        title: 'Current Status', 
                        render: props =>(
                            <span className={clsx(classes.status, { success: props.isSuccess })}>
                                {props.currentStatus}
                            </span>
                            )
                    },
                    { title: 'Bill Amount', field: 'billAmount' },
                    { title: 'Reason for Failure', field: 'failureReason', width: '15%' },
                    { title: 'Last Retry time', field: 'lastTryDate' },
                    { 
                        title: 'Retry', 
                        render: props => (
                            <RetryButton 
                                {...props} 
                                onClick={retryBillHandler}
                            />
                        )
                    },
                ]}
                data={data}
                options={{
                    headerStyle: { 
                        backgroundColor: '#F6F2EE', 
                        padding: "10px",
                    },
                    rowStyle: { 
                        fontSize: '1.2rem',
                        padding: "10px",
                    },
                    paging: rowCount > 50 ? true : false,
                    pageSize: 50,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [50, 60, 80],
                }}
            />
        </div>
    )
}

const mapStateToProps = state => {
    const { spaces, dashboard, masterway } = state;
    return {
        currentSpace: spaces[dashboard.currentSpace],
        failedBills: masterway.failedBills
    }
}

export default connect(mapStateToProps)(FailedBills);