const reviews = []

export default (state = reviews, action) => {
  switch (action.type) {
      case 'GET_INBOX_REVIEWS_REQUEST':
        return [...(action.payload || [])]
        
      case 'GET_INBOX_REVIEWS_SUCCESS':
        return [...(action.payload || [])]
      case 'GET_INBOX_REVIEWS_FAILURE':
        return [...state]
    default:
      return state
  }
}
