import Hotjar from "@hotjar/browser";
import { HOTJAR_ID, HOTJAR_SV, HOTJAR_DEBUG } from "../config";

export const initHotjar = (hjid = HOTJAR_ID, hjsv = HOTJAR_SV) => {
  Hotjar.init(hjid, hjsv, {
    debug: HOTJAR_DEBUG,
  });

  const userId = "abc_123";
  const firstName = "John";
  const favoriteColor = "blue";

  Hotjar.identify(userId, {
    first_name: firstName,
    color: favoriteColor,
  });
};
