import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import ImageUploadItem from '../ImageUploadItem';

const useStyles = makeStyles(theme => ({
	field: {
		alignItems: 'center',
		background: '#ffffff',
		borderRadius: '5px',
		boxShadow: "0 1px 10px #E0E0E0",
		display: 'flex',
		height: 35,
		marginTop: 10,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: '60%'
	},
	description: {
		height: 80,
	}
}));

const BasicInfo = props => {
	const { t } = props
	const classes = useStyles();
	const {
		room,
		handleUpdateRoom
	} = props;
	
	const handleUpdate = (key, value) => {
		value = value ? value.trimStart() : '';
		handleUpdateRoom(key, value)
	}

	return (
		<>
			<div className="main-content">

				<div className="main-content-title">{t('beds.roomTypesAndRooms.basicInfo')}</div>
				<div className="sub-title">{t('beds.roomTypesAndRooms.addRoomMessage')}</div>

				<div className="row">
					<div className="label">{t('fields.name')}</div>
					<div className={classes.field}>
						<InputBase
							id="publicName"
							name="publicName"
							value={room.name}
							fullWidth
							type="text"
							autoFocus
							onChange={(e) => handleUpdate('name', e.target.value)}
						/>
					</div>
				</div>

				{room._id && <div className="row">
					<div className="label">{t('fields.codes')}</div>
					<div className={classes.field}>
						<InputBase
							id="code"
							name="code"
							value={room.code}
							disabled
							fullWidth
							type="text"
							autoFocus
						/>
					</div>
				</div>}

				<div className="row">
					<div className="label">{t('beds.roomTypesAndRooms.picture')}</div>
					{console.log('image', room?.images?.[room?.images?.length - 1]?.key)}
					<ImageUploadItem
						image={room?.images?.[room?.images?.length - 1]?.key || null}
						selectedFile={room.imageFile}
						onChange={(image) => {
							handleUpdateRoom('imageFile', image);
							if(!image && room._id){
								handleUpdateRoom('image', null);
							}
						}}
					/>
				</div>

			</div>
		</>
	)
}

export default withTranslation()(BasicInfo); 