const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case "LOAD_TAX_LIST_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.taxes
      }
    case "CREATE_TAX_ITEM_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "EDIT_TAX_ITEM_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "RESET_TAX_LIST":
      return {
        ...initialState
      };
    case "USER_LOGOUT":
      return { }
    default:
      return state;
  }
};
  

