import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import IMAGES from '../../constants/images';

const styles = theme => ({
  root: {
    borderRadius: 5,
    padding: '20px 20px',
    background: '#F8F9FB',
    marginTop: 15,
  },
  title: {
    color: '#000000',
    fontSize: '3rem',
    fontWeight: 600,
  },
  row: {
    marginBottom: 30
  },
  extraPadding: {
    marginTop: 60,
  },
  category: {
    color: '#999999',
    fontSize: '1.1rem',
    marginBottom: 5,
  },
  data: {
    alignItems: 'flex-start',
    display: 'flex',
    opacity: 0.4,
    marginLeft: '2px'
  },
  amount: {
    fontSize: '1.7rem',
    fontWeight: 600,
    marginRight: 3,
  },
  direction: {
    color: '#53998C',
    fontSize: '2.1rem'
  },
  percentage: {
    fontSize: '1.1rem',
    color: '#53998C',
  },
  pillContainer: {
    display: 'flex',
  },
  pill: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    color: '#999999',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.1rem',
    padding: '5px 10px',
    marginRight: 10,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      background: '#EEEEEE'
    }
  },
  refresh: {
    color: '#999999',
    fontSize: '1.8rem',
    marginRight: 10,
  },
  launch: {
    color: '#999999',
    fontSize: '1.5rem',
    marginLeft: 5,
  },
  title2: {
    color: '#333333',
    fontSize: '2rem',
  },
  whatsappContainer: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    color: '#666666',
    cursor: 'pointer',
    display: 'flex',
    marginTop: 10,
    padding: 20,
    '&:hover': {
      color: '#333333'
    }
  },
  whatsapp: {
    height: 30,
    opacity: 0.6,
    width: 30,
  },
  message: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginLeft: 15,
  },
  arrow: {
    marginLeft: 'auto',
  },
  logo: {
    height: 40,
    width: 40,
    marginTop: 10
},
  title: {
      color: '#484848',
      fontSize: '1.5rem',
      fontWeight: 600,
      marginTop: 15,
      marginBottom: 10
  },
  centerText: {
    textAlign: 'center'
  }
});

const summaryData = [
  { title: `This Week's Bookings`, value: 0 },
  { title: `This Month's Bookings`, value: 0 },
  { title: `This Week's Revenue`, value: 0 },
  { title: `This Month's Revenue`, value: 0 },
  { title: `This Week's Cancellations`, value: 0 },
  { title: `This Month's Cancellations`, value: 0 },
];

const HWBlankState = (props) => {
  const { classes } = props;

  return(
    <div className={classes.root}>

      <div className={clsx(classes.row, classes.centerText)}>
        <div className={classes.title}>Hostelworld</div>
        <img className={classes.logo} src={IMAGES.LOGOS.hostelworld} />
      </div>

        <div className={classes.row}>
          <Grid container spacing={3}>

            {summaryData.map((data, index) =>(
              <Grid item xs={6} key={`summary_${index}`}>
                <div>
                  <div className={classes.category}>{data.title}</div>
                  <div className={classes.data}>
                    <div className={classes.color}></div>
                    <div className={classes.amount}>{data.value}</div>
                  </div>
                </div>
              </Grid>
            ))}

          </Grid>
        </div>
    </div>
  )
}

export default withStyles(styles)(HWBlankState);