import IMAGES from '../constants/images';

const UPSELL_ITEMS = [
  {
    iconID: '0',
    name: 'Extra Cleaning Fee',
    icon: IMAGES.ICONS.upsellCleaning,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellCleaningWhite
  },
  {
    iconID: '1',
    name: 'Locks',
    icon: IMAGES.ICONS.upsellLocks,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellLocksWhite,
  },
  {
    iconID: '2',
    name: 'Towel',
    icon: IMAGES.ICONS.upsellTowel,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellTowelWhite
  },
  {
    iconID: '3',
    name: 'Beach Towel',
    icon: IMAGES.ICONS.upsellBeachTowel,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellBeachTowelWhite,
  },
  {
    iconID: '4',
    name: 'Bedsheets',
    icon: IMAGES.ICONS.upsellSheets,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellSheetsWhite,
  },
  {
    iconID: '5',
    name: 'Toilet Paper',
    icon: IMAGES.ICONS.upsellTp,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellTpWhite,
  },
  {
    iconID: '6',
    name: 'Gift Shop Item',
    icon: IMAGES.ICONS.upsellGiftShop,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellGiftShopWhite,
  },
  {
    iconID: '7',
    name: 'Map',
    icon: IMAGES.ICONS.upsellMap,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellMapWhite,
  },
  {
    iconID: '8',
    name: 'Cold Drink',
    icon: IMAGES.ICONS.upsellDrink,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellDrinkWhite,
  },
  {
    iconID: '9',
    name: 'Hot Drink',
    icon: IMAGES.ICONS.upsellCoffee,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellCoffeeWhite,
  },
  {
    iconID: '10',
    name: 'Snack',
    icon: IMAGES.ICONS.upsellFood,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellFoodWhite,
  },
  {
    iconID: '11',
    name: 'Water',
    icon: IMAGES.ICONS.upsellWater,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellWaterWhite,
  },
  {
    iconID: '12',
    name: 'Pick Up Service',
    icon: IMAGES.ICONS.upsellPickUp,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellPickUpWhite,
  },
  {
    iconID: '13',
    name: 'Misc',
    icon: IMAGES.ICONS.upsellMisc,
    isActive: false,
    selectedIcon: IMAGES.ICONS.upsellMiscWhite,
  },
]

export { UPSELL_ITEMS }