const defaultEmailTemplates = { default: true};

export default (state = defaultEmailTemplates, action) => {
  switch (action.type) {
    case "LOAD_EMAIL_TEMPLATES_SUCCESS":
      return {
        ...action.payload
      };
    case "RESET_EMAIL_TEMPLATES":
      return {
        ...defaultEmailTemplates
      }
    case "EDIT_EMAIL_SUCCESS":
      const editEmail = state[action.payload._id]
      return {
        ...state,
        [action.payload._id]: {
          ...editEmail, 
          subject: action.payload.subject, 
          isActive: action.payload.isActive,
          attachInvoice: action.payload.attachInvoice
        }
      }
    case "CREATE_EMAIL_SUCCESS":
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case "DELETE_EMAIL_TEMPLATE_SUCCESS":
      const newState = { ...state }
      delete newState[action.payload._id]
      return newState;

    case "USER_LOGOUT":
      return { ...defaultEmailTemplates }
    default:
      return state;
  }
};