import { makeStyles, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import clsx from 'clsx';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { editProperty } from '../../redux/actions/dashboard/property';
import { bulkDeleteRoomBlocks, resetError } from '../../redux/actions/reservation/editReservation'

import Toggle from '../common/Toggle';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
  rows: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  settingText: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  autoTagContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '10px'
  },
  infoRow: {
    alignItems: 'center',
    background: '#F8F9FB',
    borderRadius: 10,
    display: 'flex',
    padding: 30,
    width: 'calc(100% - 60px)',
    marginBottom: 25,
  },
  bulb: {
    width: 40,
    height: 40
  },
  helperText: {
    color: '#999999',
    fontSize: '1.2rem',
    lineHeight: 1.4,
    marginLeft: 20
  },
  row:{
    marginTop: 30,
    marginBottom: 30
  },
  rowheader: {
    marginBottom: 25,
  },
  fieldContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  errorContainer: {
    border: '1px solid #f44336',
    borderRadius: 5,
    color: '#484848',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  dateHolder: {
    '& .DateRangePicker_picker': {
      top: '46px !important',
    },
    '& .DateInput': {
      borderRadius: 5,
      background: 'transparent',
      width: 120
    },
    '& .DateInput_input': {
      width: 'calc(100% - 22px)'
    },
    '& .DateRangePickerInput': {
      background: 'transparent',
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      borderRadius: 5,
    },
  },
  buttonContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  go: {
    background: '#000000',
    borderRadius: 5,
    color: '#ffffff',
    cursor: 'pointer',
    fontWeight: 600,
    padding: '10px 15px',
  },
  stop: {
    background: '#ababab',
    borderRadius: 5,
    color: '#666666',
    cursor: 'pointer',
    fontWeight: 600,
    padding: '10px 15px',
  }
}));


const selectStyle = {
  control: base => ({
    ...base,
    boxShadow: 'none',
    border: 0,
    paddingLeft: 8,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const ReservationSettings = props => {
  const { t, dispatch, property, currentSpace, roomTypes, errors } = props
  const classes = useStyles()

  const [autoTag, setAutoTag] = useState(property.autoCustomerTag || false);
  const [dates, setDates] = useState({startDate: moment(), endDate: moment().add(30, 'days')})
  const [load, setLoad] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [focusedInput, setFocusedInput] = React.useState(null)
  const [roomTypeFilter, setRoomTypeFilter] = React.useState([]);

  const roomTypeArray = [];
  currentSpace.roomTypes && currentSpace.roomTypes.forEach(roomTypeID => {
    if(!roomTypes[roomTypeID].isActive) return;
    roomTypeArray.push({
      value: roomTypeID, 
      label: roomTypes[roomTypeID].name
    });
  });

  const handleAutoTagToggle = value => {
    setAutoTag(value)
    props.dispatch(editProperty(property._id, { autoCustomerTag: value }))
  }

  const setNewRoomType = (value) => {
    if (value) {
      let newRoomTypeFilter = value.map(item => item.value)
      setRoomTypeFilter(newRoomTypeFilter)
      setError(false)
    } else setRoomTypeFilter([])
  }

  const handleSubmit = () => {
    if(error || roomTypeFilter.length === 0) {
      setError(true)
      return
    }

    setLoad(true)
    const payload = {
      roomTypeIDs: roomTypeFilter,
      startDate: dates.startDate.format('YYYY-MM-DD'),
      endDate: dates.endDate.format('YYYY-MM-DD')
    }

    props.dispatch(bulkDeleteRoomBlocks(currentSpace.propertyID, payload, currentSpace.timezone))
    .then(response => {
      setLoad(false)
      if(response.length > 0)
        setMessage('Successfully deleted room blocks!')
      else
        setMessage('No room blocks to delete.')
    })
  }

  const handleCloseError = () => {
    props.dispatch(resetError('BULK_DELETE_ROOMBLOCK'))
  }

  const handleCloseMessage = () => {
    setMessage('')
  }

  return (
    <div>
      {load && <div className={"loadingOverlay"}>
        <CircularProgress className={"loading"} />
      </div>}
      <div className={classes.rows}>
        <div className={classes.autoTagContainer}>
          <div className={classes.settingText}>{t('reservationSettings.reservationTag')}</div>
          <Toggle
            toggle={autoTag}
            onText='ON'
            offText='OFF'
            handleOn={() => handleAutoTagToggle(true)}
            handleOff={() => handleAutoTagToggle(false)}
          />
        </div>
        <div className={classes.infoRow}>
          <div>
            <img src={IMAGES.ICONS.image} className={classes.bulb} />
          </div>
          <div className={classes.helperText}>{t('reservationSettings.tagHelper')}</div>
        </div>

        <div className={classes.row}>
          <div className={clsx(classes.settingText, classes.rowheader)}>{t('reservationSettings.deleteRoomBlocks')}</div>

          <div className={classes.infoRow}>
            <div>
              <img src={IMAGES.ICONS.image} className={classes.bulb} />
            </div>
            <div className={classes.helperText}>{t('reservationSettings.deleteRoomBlocksHelperText')}</div>
          </div>
          
          {/* --------------------------------------------------------- */}
          {/* DATE RANGE FILTERS */}
          {/* --------------------------------------------------------- */}
          <div>
            <Grid container spacing={4}>
              <Grid item className={classes.dateHolder}>
                <DateRangePicker
                  startDate={dates.startDate}
                  startDateId="start_date"
                  endDate={dates.endDate}
                  endDateId="end_date"
                  onDatesChange={({ startDate, endDate }) => { setDates({ startDate, endDate })}}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  focusedInput={focusedInput}
                  displayFormat={'DD MMM YYYY'}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <div className={error ? classes.errorContainer : classes.fieldContainer}>
                  <Select 
                    styles={selectStyle}
                    isMulti
                    options={roomTypeArray}
                    onChange={setNewRoomType}
                    placeholder={error ? t('reservationSettings.errorSelectRoomTypes') : t('reservationSettings.selectRoomTypes')}
                    theme={theme => ({
                      ...theme,
                      boxShadow: 'none',
                      colors: {
                        ...theme.colors,
                        primary: '#666666',
                        primary25: '#dddddd'
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonContainer} onClick={handleSubmit}>
                  <div className={error ? classes.stop : classes.go}>{t('actions.submit')}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {(errors.BULK_DELETE_ROOMBLOCK) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={3000}
          onClose={() => handleCloseError()}
          key='top-center'
          message={errors.BULK_DELETE_ROOMBLOCK.error}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseError()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      {(message) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={3000}
          onClose={() => handleCloseMessage()}
          key='top-center'
          message={message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseMessage()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  property: state.property,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
  errors: state.errors
})

export default withTranslation()(connect(mapStateToProps)(ReservationSettings));
