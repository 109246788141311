import React from "react";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

import CommentList from "../comments/CommentList";
import { setCurrentFolder, setCurrentFile } from "../../redux/actions/dashboard/dashboard";
import { resetFiles, editFile, deleteFile } from "../../redux/actions/files/files";
import { returnCurrent } from "../../redux/selectors/dashboard";
import { fetchDatePerTimezone, isValidUrl } from "../../utils/utility";

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    marginTop: 10
  },
  dialog: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: "0 1px 20px #F2F2F2",
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#000000',
  },
  newListButton: {
    left: 24,
    position: 'absolute',
    top: 29,
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  backButton: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '2.5rem',
    left: 24,
    padding: theme.spacing(1),
    position: 'absolute',
    top: 24,
    transition: '150ms',
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cancelButton: {
    marginRight: 10
  },
  folderOptions: {
    border: '1px solid #dddddd',
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    padding: '4px',
    position: 'absolute',
    top: 24,
    right: 24,
  },
  folderMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  moreIcon: {
    fontSize: '1.8rem'  
  },
  listItemIcon: {
    minWidth: '40px',
    width: '40px'
  },
  menuItem: {
    color: "#666666",
    fontSize: 14,
    minWidth: 150,
    textDecoration: "none"
  },
  listSection: {
    height: 'calc(100vh - 230px)',
    padding: theme.spacing(3),
    position: 'relative',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 150px)',
    }
  },
  fileContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  fileLink: {
    marginLeft: "auto"
  },
  imageIcon: {
    color: "#dddddd",
    height: 80,
    width: 80
  },
  uploadedImage: {
    marginLeft: "20%",
    width: "60%"
  },
  fileName: {
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10
  },
  editFileName: {
    marginTop: theme.spacing(2)
  },
  download: {
    '& a': {
      textDecoration: "none"
    }
  },
  downloadButton: {
    marginTop: 20,
    textDecoration: "none",
    '&:focus': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  fileDetails: {
    fontSize: "1.2rem",
    marginTop: 7
  },
  notesContainer: {
    marginTop: theme.spacing(2)
  },
  notes: {
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  notesDesc: {
    cursor: 'pointer'
  },
  select: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.8'
  },
  editNotes: {
    marginTop: theme.spacing(2)
  },
  submit: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  }
})

class FileItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      edit: false,
      name: '',
      notes: null,
      anchorEl: null,
    }
  }

  handleBack = () => {
    this.setState({edit: false, name: '', notes: ''});
    this.props.dispatch(setCurrentFile());
  }

  handleFileMenu = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleFileMenuClose = () => {
    this.setState({anchorEl: null})
  }

  handleEdit = () => {
    this.setState({anchorEl: null});
    this.setState({edit: true});
  }

  handleDelete = () => {
    this.props.dispatch(deleteFile(this.props.currentFileID, this.props.currentFolder._id))
    this.setState({anchorEl: null});
    this.props.dispatch(setCurrentFile());
  }

  handleCancelUpdates = () => {
    this.setState({edit: false, name: '', notes: ''});
  }

  handleSubmitUpdates = () => {
    console.log('submit', this.state.name, this.state.notes)
    var updates = {};
    if (this.state.name != '') {
      updates.name = this.state.name
    }
    // if (this.state.notes != null) {
    //   updates.body = this.state.notes
    // }
    this.props.dispatch(editFile(this.props.currentFileID, updates));
    this.handleCancelUpdates();
  }

  handleClose = () => {
    this.setState({edit: false, name: '', notes: ''});
    this.props.dispatch(setCurrentFolder());
    this.props.dispatch(setCurrentFile());
  }

  render() {

    const {classes, t} = this.props;
    const creatorID = this.props.currentFile && this.props.currentFile.addedBy;
    const validUrl = isValidUrl(this.props.currentFile.url);
    const currentFile = {
      ...this.props.currentFile,
      url: validUrl && this.props.currentFile.url,
      isDisabled: !validUrl
    }

    return(
      <div>
        <Dialog
          open={Boolean(this.props.currentFileID)}
          onClose={this.handleClose}
          classes={{ paperFullWidth: classes.dialogPaper }}
          maxWidth="sm"
          fullWidth={true}
          fullScreen={window.innerWidth < 901}
        >
          <div className={classes.dialog}>
            <div className={classes.dialogTitle}>
              <BackIcon className={classes.backButton} onClick={this.handleBack} />
              <div className={classes.header}>{this.props.currentFolder && this.props.currentFolder.name}</div>
              <CloseIcon className={classes.closeIcon} onClick={this.handleClose} />
            </div>

            <div className={classes.listSection}>

              {/* ------------------------------------------------ */}
              {/* File Menu */}
              {/* ------------------------------------------------ */}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleFileMenu}
                className={classes.folderOptions}
              >
                <MoreHorizIcon className={classes.moreIcon} />
              </IconButton>
              <Menu
                id="category-menu"
                anchorEl={this.state.anchorEl}
                className={classes.folderMenu}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleFileMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                style={{padding: 0}}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <ListItem button onClick={this.handleEdit}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <EditIcon />
                  </ListItemIcon>
                  <Typography variant="body2">{t('files.editFile')}</Typography>
                </ListItem>
                <ListItem button onClick={this.handleDelete}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <DeleteIcon />
                  </ListItemIcon>
                  <Typography variant="body2">{t('files.deleteFile')}</Typography>
                </ListItem>
              </Menu>

              {/* List Files */}
              {this.props.currentFile && (
                <div>
                  <div className={classes.fileContainer}>
                    <div className={classes.fileIcon}>
                      <a href={currentFile.url} target='_blank'>
                        {currentFile.storage === 's3' && currentFile.type === 'image' && <img className={classes.uploadedImage} src={`${currentFile.url}?${Date.now()}`}/>}
                        {currentFile.type === 'document' && <img className={classes.imageIcon} src="/images/icons/document-icon.png"/>}
                        {currentFile.type === 'spreadsheet' && <img className={classes.imageIcon} src="/images/icons/spreadsheet-icon.png"/>}
                        {currentFile.type === 'pdf' && <img className={classes.imageIcon} src="/images/icons/pdf-icon.png"/>}
                        {currentFile.type === 'other' && <img className={classes.imageIcon} src="/images/icons/document-icon.png"/>}
                      </a>
                    </div>

                    {/* --------------------------------------------------------------------- */}
                    {/* FILE NAME */}
                    {/* --------------------------------------------------------------------- */}
                    {!this.state.edit && <div className={classes.fileName} onClick={this.handleEdit}>{currentFile.name}</div>}
                    {this.state.edit && (
                      <TextField
                        id="file-name"
                        label={t('files.fileName')}
                        className={classes.editFileName}
                        defaultValue={currentFile.name}
                        onChange={(e) => this.setState({name: e.target.value})}
                        margin="none"
                        fullWidth
                        autoFocus
                      />
                    )}

                    {/* --------------------------------------------------------------------- */}
                    {/* POSTED BY */}
                    {/* --------------------------------------------------------------------- */}
                    {!this.state.edit && <div className={classes.fileDetails}>
                      {t('files.postedBy')} {!(creatorID === undefined) && this.props.users[creatorID] && this.props.users[creatorID].name}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>&#xb7;</span>
                      {fetchDatePerTimezone(currentFile.createdAt, this.props.currentSpace.timezone).format('MMM DD, YYYY')}
                    </div>}

                    {/* --------------------------------------------------------------------- */}
                    {/* DOWNLOAD BUTTON */}
                    {/* --------------------------------------------------------------------- */}
                    {!this.state.edit && <div className={classes.download}>
                      <a href={currentFile.url} target='_blank' download>
                        <Button 
                          size='small' 
                          color='primary' 
                          variant='outlined' 
                          className={classes.downloadButton}
                        >
                          {currentFile.storage === 's3' && <span>{t('files.openInTab')}</span>}
                          {currentFile.storage === 'box' && <span>{t('files.viewOn')} box</span>}
                          {currentFile.storage === 'oneDrive' && <span>{t('files.viewOn')} oneDrive</span>}
                          {currentFile.storage === 'dropbox' && <span>{t('files.viewOn')} Dropbox</span>}
                          {currentFile.storage === 'googleDrive' && <span>{t('files.viewOn')} Google</span>}
                        </Button>
                      </a>
                    </div>}
                  </div>

                  {/* --------------------------------------------------------------------- */}
                  {/* NOTES */}
                  {/* --------------------------------------------------------------------- */}
                  <div className={classes.notesContainer}>
                    {!this.state.edit && <div className={classes.notes}>{t('todos.notes')}:</div>}
                    {!this.state.edit && (
                      <div className={classes.notesDesc} onClick={this.handleEdit}>
                        {currentFile.desc ?
                        <div className={classes.noteDisplay}>{currentFile.desc}</div> : 
                        <span className={classes.select}>{t('todos.addExtraDetails')}..</span>}
                      </div>
                    )}

                    {this.state.edit && (
                      <div>
                        <TextField
                          id="outlined-full-width"
                          fullWidth
                          label={t('todos.notes')}
                          multiline
                          margin="none"
                          variant="outlined"
                          defaultValue={currentFile.desc}
                          onChange={(e) => this.setState({notes: e.target.value})}
                          className={classes.editNotes}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    )}

                  </div>

                  {/* --------------------------------------------------------------------- */}
                  {/* SUBMIT / CANCEL UPDATES */}
                  {/* --------------------------------------------------------------------- */}
                  {this.state.edit && (
                    <div className={classes.submit}>
                      <Button size="small" variant="outlined" className={classes.cancelButton} onClick={this.handleCancelUpdates}>{t('actions.cancel')}</Button>
                      <Button size="small" variant="outlined" className={classes.button} onClick={this.handleSubmitUpdates}>{t('actions.save')}</Button>
                    </div>
                  )}

                  <CommentList 
                    itemComments={currentFile.comments || []} 
                    slatePlugins={['files', 'mentions']}
                  />

                </div>
              )}
            </div>
          </div>
          
        </Dialog>
        
      </div>
    )
  }
}

FileItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentFileID: state.dashboard.currentFile,
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  currentFile: returnCurrent(state.files, state.dashboard.currentFile),
  currentFolder: returnCurrent(state.folders, state.dashboard.currentFolder),
  users: state.users
})

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(FileItem)));