import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { fetchCurrencyFormat } from '../../utils/utility'; 

import { makeStyles, withStyles, InputBase } from '@material-ui/core';
import { Grid, Switch } from '@material-ui/core';
import { compose } from 'redux';
import { addUpsellItem, updateUpsellItem } from '../../redux/actions/upsell/upsell';
import { UPSELL_ITEMS } from '../../utils/upsellItems';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 10,
    marginLeft: '20%',
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      marginLeft: '25%',
    },
  },
  bubble: {
    alignItems: 'flex-start',
    background: '#FFFFFF',
    borderRadius: '15px',
    boxShadow: "0 1px 10px solid #BDBDBD",
    display: 'flex',
    fontSize: '1.3rem',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'calc(100% - 32px)',
  },
  title: {
    marginBottom: 10,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: 25,
    width: 25,
  },
  selected: {
    // background: '#8CB2A4',
    // color: '#ffffff',
    // fontWeight: 600,
  },
  switchContainer: {
    marginLeft: 'auto'
  },
  editIcon: {
    marginLeft: 'auto',
    '& img': {
      height: 15,
      width: 15,
    }
  },
  rateRow: {
    marginTop: 10
  },
  rate: {
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: "0 1px 20px #F2F2F2",
    fontSize: '1.2rem',
    paddingLeft: 15,
  },
  rateView: {
    fontSize: '1.3rem',
  },
  error: {
    color: 'red',
    fontSize: '1.2rem',
    paddingTop: 10
  },
  saveContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  save: {
    background: '#8CB2A4',
    borderRadius: 10, 
    color: '#ffffff',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }
}))

const CustomSwitch = withStyles({
  switchBase: {
    color: '#E0E0E0',
    '&$checked': {
      color: '#8CB2A4',
    },
    '&$checked + $track': {
      backgroundColor: '#8CB2A4',
    },
  },
  checked: {},
  track: {},
})(Switch);

const UpsellItem = (props) => {
  const classes = useStyles();
  const { currentSpace, item, index } = props;
  const [selected, setSelected] = React.useState(false);
  const [rate, setRate] = React.useState(item.rate || '');
  const [name, setName] = React.useState(item.name);
  const [error, setError] = React.useState({name: false, rate: false});
  const [saved, setSaved] = React.useState(item.isActive || false);

  useEffect(() => {
    setName(item.name)
    setSaved(item.isActive)
    setSelected(item.isActive)
    setRate(item.rate)
  }, [item.name, item.rate])

  const handleUpdateName = (e) => {
    setName(e.target.value);
    if (e.target.value) {
      setError({...error, name: false})
    }
  }

  const handleUpdateRate = (e) => {
    setRate(e.target.value);
    if (e.target.value) {
      setError({...error, rate: false})
    }
  }

  const handleValidate = () => {
    let foundError = false
    let errorStep = {}
    if (!name) {
      errorStep.name = true;
      foundError = true;
    }
    if (!rate || isNaN(rate)) {
      errorStep.rate = true
      foundError = true
    }
    if (foundError) {
      setError({...error, ...errorStep})
      return false
    }
    else return true
  }

  const handleSubmit = () => {
    const check = handleValidate();
    if (check) {
      if (item._id) {
        props.dispatch(updateUpsellItem(item._id, {name, isActive: true, rate, iconID: item.iconID, propertyID: currentSpace.propertyID}))
      } else {
        props.dispatch(addUpsellItem(currentSpace._id, {name, rate, iconID: item.iconID, propertyID: currentSpace.propertyID}))
      }
      setSaved(true);
    }
  }

  const handleUpdate = (item) => {
    if (selected) {
      const check = handleValidate();
      if (check) {
        props.dispatch(updateUpsellItem(item._id, {name, isActive: false, rate, iconID: item.iconID, propertyID: currentSpace.propertyID}))
        setSaved(false);
      }      
    }
    setSelected(!selected)
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div 
          className={clsx({[classes.bubble]: true, [classes.selected]: selected })} 
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <div className={classes.iconContainer}>
                <img src={item.icon} className={classes.icon} />
              </div>
            </Grid>
            <Grid item xs={7}>
              {!selected && item.name}
              {saved && 
                <div className={classes.rateView}>
                  {name}
                </div>
              }
              {selected && !saved &&
                <div className={classes.rate} style={{border: error.name ? '1px solid red' : '1px solid #ffffff'}}>
                  <InputBase 
                    id="name"
                    name="name"
                    defaultValue={name}
                    fullWidth
                    type="text"
                    onChange={handleUpdateName}
                  />
                </div>
              }
              {selected && error.name && <div className={classes.error}>Please enter a valid name</div>}
            </Grid>
            <Grid item xs={3}>
              <div className={classes.switchContainer}>
                <CustomSwitch
                  checked={Boolean(selected)}
                  onChange={() => handleUpdate(item)}
                  color="primary"
                  name="switch"
                  inputProps={{ 'aria-label': 'room mapping switch' }}
                />
              </div>
            </Grid>
          </Grid>
          {selected && 
            <Grid container className={classes.rateRow} alignItems="center" spacing={2}>
              <Grid item xs={2}></Grid>
              <Grid item xs={7}>
                {saved && 
                  <div className={classes.rateView}>
                    {currentSpace.currency} {rate}
                  </div>
                }
                {!saved && <div className={classes.rate} style={{border: error.rate ? '1px solid red' : '1px solid #ffffff'}}>
                  <InputBase 
                    id="rate"
                    name="rate"
                    placeholder="Price"
                    fullWidth
                    defaultValue={item.price}
                    type="text"
                    onChange={handleUpdateRate}
                  />
                </div>}    
                {error.rate && <div className={classes.error}>Please enter a valid rate</div>}
                {!saved && <div className={classes.saveContainer}>
                  <div className={classes.save} onClick={handleSubmit}>Save</div>
                </div>}
              </Grid>
            </Grid>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  propertyID: state.spaces[state.dashboard.currentSpace].propertyID,
})

export default connect(mapStateToProps)(UpsellItem);