import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'; 
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';

import { initials } from '../../utils/wordUtils';

const useStyles = makeStyles(theme=>({
    toggle: {
        alignItems: 'center',
        boxShadow: '0 1px 20px #F2F2F2',
        borderRadius: 25,
        display: 'flex',
        borderRadius: '25px',
        overflow: 'hidden'
    },
    toggleButton: {
        alignItems: 'center',
        color: '#BDBDBD',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 600,
        height: 35,
        justifyContent: 'center',
        width: '50%',
    },
    selected: {
        background: '#4F9581',
        boxShadow: '-15px 0px 17px -7px #F2F2F2',
        color: '#FFFFFF',
    },
    disabletoggle:{
        pointerEvents: 'none'
    },  
    userName:{
        fontSize: '1.2rem',
        fontWeight: 600,
        lineHeight: 1.3,
        alignSelf: 'center',
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]:{
            marginLeft: 0
        }
    },
    listItem:{
        marginBottom: '25px',
        alignItems: 'center'
    },
    Avatar:{
        fontSize: '1.7rem',
        [theme.breakpoints.down('sm')]:{
            display: 'none'
        }
    },
    adminText:{
        textAlign: 'center',
        fontWeight: 500,
        color: '#BDBDBD'
    }
}));

const UserListItem = ({
    isAdmin,
    userID,
    users,
    isActive,
    updatePermissionHandler = null
}) =>{
    const classes = useStyles();
    const [toggle, setToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        setToggle(isActive);
    },[isActive]);

    const disableLoading = () =>{
        setIsLoading(false);
    }

    const handleSwitch = () => {
        const status = !toggle;
        setIsLoading(true);
        setToggle(status);
        updatePermissionHandler(userID, status, disableLoading);
    }

    return(
        <Grid container className={classes.listItem}>
            {users[userID] && (
                <>
                    <Grid item sm={1}>
                        <Avatar
                            alt={initials(users[userID].name)}
                            src={users[userID].avatar || ''}
                            className={classes.Avatar}
                        >
                            {initials(users[userID].name)}
                        </Avatar>
                    </Grid>
                    <Grid item sm={6} xs={7} className={classes.userName}>
                        {users[userID].name}
                    </Grid>
                    <Grid item sm={4} xs={5}>
                        {!isAdmin && (
                            <div className={clsx({ [classes.toggle]:true, [classes.disabletoggle]: isLoading })}>
                                <div 
                                    className={clsx(classes.toggleButton, classes.left)} 
                                    style={{background: toggle===false ? '#F2F2F2' : '#FFFFFF'}}
                                    onClick={handleSwitch}
                                >
                                    OFF
                                </div>
                                <div 
                                    className={clsx({[classes.toggleButton]: true, [classes.right]: true, [classes.selected]: toggle===true})}
                                    onClick={handleSwitch}
                                >
                                    ON
                                </div>
                            </div>
                        )}

                        {isAdmin && (
                            <div className={classes.adminText}>Admin</div>
                        )}
                    </Grid>
                </>
            )} 
        </Grid>
    )
}

const mapStateToProps = (state, props) =>{
    const { users, permissions: { accessIdData = {} } } = state;
    const isActive = (accessIdData[props.moduleID] && accessIdData[props.moduleID].includes(props.userID)) ? true : false;
    return{
        isActive,
        users
    }
}

export default connect(mapStateToProps)(UserListItem);