import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { addSpace } from '../../redux/actions/spaces/space';
import { resetError } from '../../redux/actions/common/common';

import Dialog from '@material-ui/core/Dialog';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 30px)',
        width: '100%',
        marginTop: '10px'
    },
    dialogTitle: {
        alignItems: 'center',
        background: '#f8f9fb',
        justifyContent: 'center',
        padding: theme.spacing(3),
        position: 'sticky',
        top: 0,
        textAlign: "center",
        zIndex: '105',
    },
    title: {
        display: 'flex',
        '& span': {
          marginRight: 20
        },
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'unset',
        }
      },
      closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 15,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
      },
    row: {
        margin: 24,
        width: '60%',
    },
    label: {
        color: '#484848',
        fontSize: '1.2rem',
        fontWeight: 500,
        marginBottom: 5,
    },
    field: {
        alignItems: 'center',
        boxShadow: "0 1px 10px #E0E0E0",
        borderRadius: '10px',
        display: 'flex',
        height: 35,
        marginTop: 10,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'calc(100% - 32px)',
        '& div': {
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            marginBottom: 10,
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 24,
    },
    button: {
        '&:hover':{
          background: '#FFF'
        }
    }
}));

const AddSpaceModal = props => {
    const classes = useStyles();
    const { open, handleClose, errors, isLoading } = props;
    const [spaceID, setSpaceID] = React.useState('');
    
    const handleSubmit = () => {
        if (spaceID !== '' && !isLoading) {
            const response = props.dispatch(addSpace(spaceID.trim(), props.currentSpaceID));
            response.then(data => {
                if (data)  handleClose();
            })
        }
    }

    const handleCloseError = () =>{
        props.dispatch(resetError('ADD_SPACE'));
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <div className={classes.dialogTitle}>
                <Typography variant="h4" className={classes.title}><span>Add Space</span></Typography>
                <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            </div>

            <div className={classes.root}>

                <div className={classes.row}>
                    <div className={classes.label}>Space ID</div>
                    <div className={classes.field}>
                    <InputBase
                        id="spaceID"
                        name="spaceID"
                        value={spaceID}
                        onChange={(e) => setSpaceID(e.target.value)}
                    />
                    </div>
                </div>

                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="outlined" color="primary" onClick={handleSubmit}>
                        {isLoading ? <CircularProgress size={25} /> : 'Submit'}
                    </Button> 
                </div>
            </div>

            {/* ------------- Snackbar error messages -------------- */}
            {errors && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={handleCloseError}
                    autoHideDuration={6000}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{errors}</span>}
                />
            )}
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    currentSpaceID: state.dashboard.currentSpace,
    errors: state.errors.ADD_SPACE,
    isLoading: state.loading.ADD_SPACE,
})

export default connect(mapStateToProps)(AddSpaceModal);