import React from 'react'
import { cloneDeep } from 'lodash'
import MaterialTable, { MTableBody } from 'material-table'
import moment from 'moment-timezone'
import { groupingConfig } from '../utils/excelExport'
import Pagination from '../common/Pagination'

const AvailabilityLogTableExport = props => {
  const { data = [], isSystemAdmin, paginationProps, timezone, t } = props
  const tableTitle = t('beds.availabilityLogs.name')

  data.forEach(row => {
    row.timestamp = moment.tz(row.updatedAt, timezone).format('ddd MMMM DD, hh:mm:ssa')
    row.operation = row.previous ? (row.current ? (row.current.available > row.previous.available ? 'Increased' : 'Decreased') : 'Deleted') : (row.current ? 'Added' : 'Invalid Operation')
    row.fromTo = `${row.previous ? row.previous.available : 'none'} -> ${row.current ? row.current.available : 'none'}`
    row.date = moment((row.previous || row.current).date).tz(timezone).format('YYYY-MM-DD')
    row.roomTypeID = String((row.previous || row.current).roomTypeID)
    row.propertyID = String((row.previous || row.current).propertyID)
  })

  const columns = [
    { title: t('beds.availabilityLogs.timestamp'), field: 'timestamp', headerStyle: { backgroundColor: '#F6F2EE' } },
    { title: t('beds.availabilityLogs.operation'), field: 'operation', headerStyle: { backgroundColor: '#F6F2EE' } },
    { title: t('beds.availabilityLogs.availabilityChange'), field: 'fromTo', headerStyle: { backgroundColor: '#F6F2EE' } },
    { title: t('beds.availabilityLogs.availabilityDate'), field: 'date', headerStyle: { backgroundColor: '#F6F2EE' } }
  ]

  if (isSystemAdmin) {
    columns.push(...[
      { title: t('beds.availabilityLogs.roomTypeID'), field: 'roomTypeID', headerStyle: { backgroundColor: '#F6F2EE' } },
      { title: t('beds.availabilityLogs.propertyID'), field: 'propertyID', headerStyle: { backgroundColor: '#F6F2EE' } },
      { title: t('beds.availabilityLogs.availabilityID'), field: 'availabilityID', headerStyle: { backgroundColor: '#F6F2EE' } },
      { title: t('beds.availabilityLogs.functionName'), field: 'functionName', headerStyle: { backgroundColor: '#F6F2EE' } },
      { title: t('beds.availabilityLogs.route'), field: 'route', headerStyle: { backgroundColor: '#F6F2EE' } }
    ])
  }

  const groupingConfigParmas = {
    data, 
    tableTitle
  }

  return (
    <MaterialTable
      title={tableTitle}
      columns={columns}
      data={data}
      localization={{
        toolbar: { exportCSVName: 'Export as Excel' }
      }}
      options={{
        exportButton: true,
        ...groupingConfig(groupingConfigParmas),
        rowStyle: { fontSize: '1.2rem' },
        headerStyle: {
          backgroundColor: '#F6F2EE',
          zIndex: 0
        },
        paging: false,
      }}
      components={{
        Body: props => (
          <>
            <MTableBody {...props} />

            {paginationProps.pagination.total > paginationProps.limitOptions[0].value && (
              <tfoot>
                <tr>
                  <td colSpan='7'>
                    <Pagination {...paginationProps} />
                  </td>
                </tr>
              </tfoot>
            )}
          </>
        )
      }}
    />
  )
}

export default AvailabilityLogTableExport
