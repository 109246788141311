import http from "../../utils/http";

export const fetchRatesAvailability = (params) =>{
    const ACTION_NAME = "FETCH_RATES_AVAILABILITY";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.get(`/api/frontdesk/experiences/inventory`, { params })
            .then(successResult => {
                const { products } = successResult.data;
                dispatch({ 
                    type: `${ACTION_NAME}_SUCCESS`, 
                    payload: [ ...products ]
                });

                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}

export const updateCalendarSlot = ( applyTo, slotID, data ) =>{
    const ACTION_NAME = "UPDATE_CALENDAR_SLOT";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http.patch(`/api/frontdesk/experiences/slots/inventory/${applyTo}/${slotID}`, data)
            .then(successResult => {
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
                return true;
            })
            .catch(errorResult => {
                console.log("errorResult", errorResult);
                let error =
                  errorResult && errorResult.response && errorResult.response.data
                    ? errorResult.response.data
                    : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
                return;
            });
    }
}