import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import http from "../../redux/utils/http";
import { DATE_FORMATS, fetchCombineDateFormat, fetchCurrencyFormat, fetchDateFormat, getAmtFormat } from '../../utils/utility';
import { isBoolean } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    width: 400,
  },
  header: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    position: 'fixed',
    background: 'white',
    width: '100%',
  },
  section: {
    marginTop: 60,
  },
  arrowIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    transition: '150ms',
    top: 10,
    marginRight: 10,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  cardRoot: {
    padding: '20px 20px 10px 20px',
    // boxShadow: '0 2px 20px #E0E0E0',
    background: '#F8F9FB',
    borderRadius: '10px',
    marginRight: '10px',
    marginLeft: '10px',
    marginBottom: '10px',
  },
  title: {
    color: "#333333",
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  highlightedText: {
    color: '#EABF87',
    fontWeight: '600',
    marginBottom: '10px',
    '& span': {
      fontSize: "1.1rem",
    }
  },
  primaryTitle: {
    color: "#333333",
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: '10px'
  },
  secondaryTitle: {
    fontSize: '1.2rem',
  },
  infoContainer: {
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '5px',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  },
  iconContainer: {
    marginBottom: 10,
    flexGrow: '0',
    maxWidth: '50%',
    flexBasis: '50%',
  }
}));

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


const RateLog = ({
  currentSpace,
  roomTypes,
  currency,
  classes,
  users,
  log,
  ratePlans
}) => {

  let roomTypesName = log.roomTypeIDs.map(id => roomTypes?.[id]?.name)?.join(', ');

  const ratePlanList = log.ratePlanIDs && log.ratePlanIDs.map(id =>{
    
    if(ratePlans[id]) return `${ratePlans[id].name} (${ratePlans[id].channel})`
    return '';
    
  }).join(', ') || '';

  const checkAllDaysWeek = (days) => {
    if (!Array.isArray(daysOfWeek) || !Array.isArray(days) || daysOfWeek.length !== days.length)
      return false;
    let allDays = daysOfWeek.concat().sort();
    let selectionDays = days.concat().sort();
    for (var i = 0; i < allDays.length; i++) {
      if (allDays[i] !== selectionDays[i])
        return false;
    }
    return true;
  }
  const logBy = log?.RMS_update ? 'RMS' : users?.[log.createdBy]?.name
  return (
    <div className={classes.cardRoot}>
      <div className={classes.highlightedText}>
        <span>At {fetchDateFormat(log.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, currentSpace.timezone)}</span>
        <span> by {logBy}</span>
      </div>

      <div className={classes.primaryTitle}>Room types: {roomTypesName}</div>

      <div className={classes.primaryTitle}></div>

      {log?.dateRange?.map((range, index) =>
        <React.Fragment key={index}>
          <div className={classes.primaryTitle}>
            Date range: {fetchCombineDateFormat(range.startDate, range.endDate, null, true)}
          </div>
          <div className={classes.primaryTitle}>
            Days of Week: {checkAllDaysWeek(range.daysOfWeek) ? 'All' : range?.daysOfWeek.map(day => day.substring(0, 3))?.join(', ')}
          </div>
        </React.Fragment>
      )}

      {ratePlanList && (
        <div className={classes.primaryTitle}>Rate plans: {ratePlanList}</div>
      )}
      


      <div className={classes.infoContainer}>
        {log.amount && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>Rate: {getAmtFormat(log.amount, currency)}</div>
        </div>}
        {log.minLOS !== null && log.minLOS >= 0 && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>minLOS: {log.minLOS} days</div>
        </div>}
        {log.maxLOS !== null && log.maxLOS >= 0 && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>maxLOS: {log.maxLOS} days</div>
        </div>}
        {isBoolean(log.stopSell) && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>
            Stop sell: {log.stopSell === true ? 'ON' : 'OFF'}
          </div>
        </div>}
        {isBoolean(log.CTA) && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>
            CTA: {log.CTA === true ? 'ON' : 'OFF'}
          </div>
        </div>}
        {isBoolean(log.CTD) && <div className={classes.iconContainer}>
          <div className={classes.secondaryTitle}>
            CTD: {log.CTD === true ? 'ON' : 'OFF'}
          </div>
        </div>}
      </div>
    </div>
  )
}

const RateLogs = (props) => {
  const classes = useStyles();
  const { open, currentSpace } = props;
  const [logs, setLogs] = React.useState([]);
  const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);

  React.useEffect(() => {
    if (open) {
      fetchRatesLog();
    }
  }, [open])

  const fetchRatesLog = (params) => {
    http.get(`/api/frontdesk/rateIntervals/${currentSpace.propertyID}`, {
      params
    })
      .then(successResult => {
        let data = successResult.data || [];
        if(data && data.length) {
          data = data.sort((l, m) => l.createdAt > m.createdAt ? -1 : 1)
        }
        setLogs([...data]);
      })
      .catch(errorResult => {
        setLogs([]);
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log('rate logs error ', error);
      });
  }

  return (
    <Drawer
      open={open}
      anchor={'right'}
      onClose={props.closeLog}
      transitionDuration={400}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <div className={classes.toolbar}>
          <ArrowForwardIcon className={classes.arrowIcon} onClick={props.closeLog} />
        </div>
        <div className={classes.title}>LOGS</div>
      </div>

      <div className={classes.section}>
        {logs.map((log, index) =>
          <RateLog
            key={index}
            log={log}
            classes={classes}
            currency={currency}
            currentSpace={currentSpace}
            roomTypes={props.roomTypes}
            users={props.users}
            ratePlans={props.ratePlans}
          />
        )}
      </div>

    </Drawer>
  )

}

const mapStateToProps = state => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
  roomTypes: state.roomTypes,
  users: state.users,
  ratePlans: state.ratePlans,
})

export default connect(mapStateToProps)(RateLogs);