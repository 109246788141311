import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { fetchCurrencyFormat } from '../../../../utils/utility'; 
import NumberInput from '../../../common/NumberInput';

const useStyles = makeStyles(theme =>({
  field: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: "0 1px 10px #E0E0E0",
    display: 'flex',
    height: 35,
    marginLeft: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 50,
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex'
  },
}));


const Price = props =>{
    const classes = useStyles();
    const { 
      currentSpace,
      product,
      handleUpdateProduct,
    } = props;
    const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Price</div>
                <div className="sub-title">COGS enables Counter to calculate gross margins in your sales reports</div>

                <div className="row">
                  <div className="label">Price</div>
                  <div className={classes.valueContainer}>

                    {/* Value */}
                    {/* ------------------------------------ */}
                    {currency}
                    <div className={classes.field}>
                      <NumberInput 
                        id="price"
                        name="price"
                        min={0}
                        value={(product.price && product.price !=='') ? product.price : ''}
                        onChange={(e) => handleUpdateProduct('price', e.target.value.trimStart())} 
                      />
                    </div>

                  </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  ratePlans: state.ratePlans,
  currentSpace: state.spaces[state.dashboard.currentSpace],
})

export default connect(mapStateToProps)(Price); 