import React, { useEffect } from "react";
import { makeStyles, CircularProgress, formatMs } from "@material-ui/core";
import { connect } from "react-redux";
import { TwitterPicker, ChromePicker } from "react-color";
import _ from "lodash";
import clsx from "clsx";

import {
  updateSettings,
  getSettings,
} from "../../redux/actions/onlineCheckin/onlineCheckin";
import { editProperty } from "../../redux/actions/dashboard/property";

import Steps from "./Steps";

import Select from "react-select";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import { MODULE } from "../../common/constants/permission";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.3,
  },
  labelTitle: {
    fontSize: "1.3rem",
    color: "#666666",
    fontWeight: 600,
    marginTop: 20,
  },
  toggle: {
    alignItems: "center",
    boxShadow: "0 1px 20px #F2F2F2",
    borderRadius: 25,
    display: "flex",
  },
  toggleButton: {
    alignItems: "center",
    color: "#BDBDBD",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: 600,
    height: 35,
    justifyContent: "center",
    width: "50%",
  },
  left: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  selected: {
    background: "#4F9581",
    boxShadow: "-15px 0px 17px -7px #F2F2F2",
    color: "#FFFFFF",
  },
  topMargin: {
    marginTop: 25,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#000000",
    borderRadius: 25,
    color: "#ffffff",
    cursor: "pointer",
    fontSize: "1.25rem",
    fontWeight: 600,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  select: {
    height: 35,
    paddingLeft: 20,
    width: "calc(100% - 20px)",
  },
  emailLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  emailRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  triggerToggle: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    height: 35,
    justifyContent: "center",
    width: "50%",
    color: "#666666",
    fontWeight: 400,
    background: "#ffffff",
    "&:hover": {
      background: "#dddddd",
    },
  },
}));

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: "none",
    minWidth: "180px",
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const triggerFreq = [
  { label: "Two days before", value: "2days" },
  { label: "Five Days before", value: "5days" },
  { label: "On Reservation", value: "reservation" },
];

const AutomaticEmail = (props) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const {
    open,
    handleClose,
    currentSpace,
    property,
    loadingProperty,
    isLoading,
    emailTemplates,
    onlineCheckin,
  } = props;
  const [emailToggle, setEmailToggle] = React.useState(false);
  const [defaultTemplate, setDefaultTemplate] = React.useState(true);
  const [selectedFreq, setFreq] = React.useState(null);
  const [customEmail, setCustomEmail] = React.useState(null);
  const [message, setMessage] = React.useState("");

  const [emailInitial, setEmailInitial] = React.useState(true);

  let emailType2 = [];
  if (!_.isEmpty(emailTemplates) && emailTemplates.default !== true) {
    emailType2 = [];
    Object.entries(emailTemplates).map((item) => {
      emailType2.push({ label: item[1].name, value: item[0] });
    });
  } else {
    emailType2 = [{ label: "No email templates setup", value: null }];
  }

  if (onlineCheckin.email && emailInitial) {
    setEmailInitial(false);
    setEmailToggle(onlineCheckin.email.automaticEmail);

    if (onlineCheckin.email.defaultTemplate) setDefaultTemplate(true);
    else setDefaultTemplate(false);

    if (onlineCheckin.email.trigger) {
      for (var i = 0; i < triggerFreq.length; i++) {
        if (triggerFreq[i].value === onlineCheckin.email.trigger) {
          setFreq({
            ...selectedFreq,
            value: triggerFreq[i].value,
            label: triggerFreq[i].label,
          });
        }
      }
    }
    Object.keys(emailTemplates).map((email) => {
      if (email === onlineCheckin.email.template) {
        setCustomEmail({
          ...customEmail,
          value: emailTemplates[email]._id,
          label: emailTemplates[email].name,
        });
      }
    });
  }

  const handleEmailToggle = (value) => {
    if (value) {
      setFreq({
        ...selectedFreq,
        label: triggerFreq[0].label,
        value: triggerFreq[0].value,
      });
    }
    setEmailToggle(value);

    const data = {
      automaticEmail: value,
    };

    props.dispatch(updateSettings(currentSpace.propertyID, "email", data));
  };

  const setTriggerFrequency = (e) => {
    setFreq({ ...selectedFreq, label: e.label, value: e.value });
  };

  const handleDefaultTemplate = (type) => {
    if (type === false) {
      setCustomEmail({
        ...customEmail,
        label: emailType2[0].label,
        value: emailType2[0].value,
      });
    }
    setDefaultTemplate(type);
  };

  const selectCustomEmail = (e) => {
    setCustomEmail({ ...customEmail, label: e.label, value: e.value });
  };

  const handleEmailSave = (e) => {
    const data = {
      automaticEmail: emailToggle,
      trigger: selectedFreq.value,
      defaultTemplate,
    };

    if (!defaultTemplate) {
      data.template = customEmail.value;
    }

    props
      .dispatch(updateSettings(currentSpace.propertyID, "email", data))
      .then((response) => {
        if (response && response.status === 200) {
          setMessage("Automatic Email Settings saved!");
          setOpenSnackbar(true);
        } else {
          setMessage("Your settings has not been saved. Please try again!");
          setOpenSnackbar(true);
        }
      });
    //automatic email settings save  action will come here
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={7}>
          <div className={classes.title}>Automatic Online Check-in Email</div>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.toggle}>
            <div
              className={clsx(classes.toggleButton, classes.left)}
              style={{
                background: emailToggle === false ? "#F2F2F2" : "#FFFFFF",
              }}
              onClick={() => handleEmailToggle(false)}
            >
              OFF
            </div>
            <div
              className={clsx({
                [classes.toggleButton]: true,
                [classes.right]: true,
                [classes.selected]: emailToggle === true,
              })}
              onClick={() => handleEmailToggle(true)}
            >
              ON
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Email Settings would come here! */}
      {emailToggle && (
        <div>
          <Grid container alignItems="center" className={classes.topMargin}>
            <Grid item xs={7}>
              <p className={classes.labelTitle}>Trigger</p>
            </Grid>
            <Grid item xs={5}>
              <div className={clsx(classes.toggle, classes.select)}>
                <Select
                  value={selectedFreq}
                  styles={selectStyle}
                  options={triggerFreq}
                  onChange={(e) => setTriggerFrequency(e)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#666666",
                      primary25: "#dddddd",
                    },
                  })}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.topMargin}>
            <Grid item xs={7}>
              <p className={classes.labelTitle}>Use default email template</p>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.toggle}>
                <div
                  className={clsx(classes.triggerToggle, classes.emailLeft)}
                  style={{
                    background:
                      defaultTemplate === false ? "#4F9581" : "#FFFFFF",
                    color: defaultTemplate === false ? "#FFFFFF" : "#666666",
                  }}
                  onClick={(e) => handleDefaultTemplate(false)}
                >
                  OFF
                </div>
                <div
                  className={clsx(classes.triggerToggle, classes.emailRight)}
                  style={{
                    background:
                      defaultTemplate === true ? "#4F9581" : "#FFFFFF",
                    color: defaultTemplate === true ? "#FFFFFF" : "#666666",
                  }}
                  onClick={(e) => handleDefaultTemplate(true)}
                >
                  ON
                </div>
              </div>
            </Grid>
          </Grid>
          {!defaultTemplate && (
            <div className={classes.topMargin}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <p className={classes.labelTitle}>Send Custom Email</p>
                </Grid>
                <Grid item xs={5}>
                  <div className={clsx(classes.toggle, classes.select)}>
                    <Select
                      value={customEmail}
                      styles={selectStyle}
                      options={emailType2}
                      onChange={(e) => selectCustomEmail(e)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#666666",
                          primary25: "#dddddd",
                        },
                      })}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          {emailToggle && (
            <div className={clsx(classes.buttonsContainer, classes.topMargin)}>
              <div className={classes.button} onClick={handleEmailSave}>
                Save
              </div>
            </div>
          )}
        </div>
      )}
      {message && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key="top-center"
          autoHideDuration={3000}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSpaceID: state.dashboard.currentSpace,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  property: state.property,
  isLoading: state.loading.EDIT_PROPERTY,
  loadingProperty: state.loading.LOAD_PROPERTY,
  emailTemplates: state.emailTemplates,
  onlineCheckin: state.onlineCheckin,
});

export default connect(mapStateToProps)(AutomaticEmail);
