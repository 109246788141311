import http from "../../utils/http";
import axios from "axios";

//----------------------------------------------------------------
// ADD CUSTOMER
//----------------------------------------------------------------
export function createCustomer(id, payload) {
  const ACTION_NAME = "ADD_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/frontdesk/resvCustomer/${id}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// UPDATE CUSTOMER
//----------------------------------------------------------------
export function updateCustomer(id, payload, isPrimary, accommodationIDs) {
  const ACTION_NAME = "UPDATE_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.patch(`/api/frontdesk/customer/${id}`, { ...payload })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data, isPrimary: isPrimary, accommodationIDs: accommodationIDs })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// UPLOAD CUSTOMER DOC
//----------------------------------------------------------------
export function uploadCustomerDoc(payload) {
  const ACTION_NAME = "UPLOAD_CUSTOMER_DOC";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/frontdesk/customer/uploadDoc/", {
        propertyID: payload.propID,
        customerID: payload.customerID,
        name: payload.name+'_time_'+new Date().getTime(),
        type: payload.type
      })
      .then(successResult => {
        const docInfo = successResult.data;
        const key = docInfo.key;
        const url = docInfo.url;
        const options = {
          headers: {
            "Content-Type": (payload.type === 'pdf' ? 'application/pdf': payload.type)
          }
        };
        axios.put(url, payload.file, options)
          .then(result => {
            http.post("/api/frontdesk/customer/saveDoc/", {
              propertyID: payload.propID,
              customerID: payload.customerID,
              key: key
            }).then(response => {
              dispatch({
                type: `${ACTION_NAME}_SUCCESS`,
                payload: response.data,
                _id: payload.customerID
              });
            }).catch(error => {
              return error;
            });
          })
          .catch(error => {
            return error;
          });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// DELETE CUSTOMER
//----------------------------------------------------------------
export function deleteCustomer(reservationID, customerID) {
  const ACTION_NAME = "DELETE_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(`/api/frontdesk/customer/${reservationID}/${customerID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            deletedCustomerID: customerID,
            data: data
          }
        })
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}