import http from '../../utils/http';
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentSpace } from '../dashboard/dashboard';
 
//----------------------------------------------------------------
// CREATE USER
//----------------------------------------------------------------
export function createNewUser(user) {
  const ACTION_NAME = "CREATE_USER";
  // console.log(values);
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/users/register`, user)
      .then(successResult => {
        const data = successResult.data;
        console.log(data)
        localStorage.setItem("jwtToken", data.token);
        setAuthToken(data.token);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
        dispatch(setUserSignupStep(2));
      })
      .catch(errorResult => {
        console.log(errorResult);
        let error = errorResult && errorResult.response && errorResult.response.data
                  ? errorResult.response.data
                  : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error } });
      });
  };
}

//----------------------------------------------------------------
// CREATE NEW SPACE
//----------------------------------------------------------------
export function createNewSpace(space) {
  let spaceData = {...space};
  const formData = new FormData();
  formData.append('upload', spaceData.image);
  delete spaceData.image;
  Object.keys(spaceData).forEach(k => {
    formData.append(k, spaceData[k]);
  });

  console.log("formData", formData);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const ACTION_NAME = "CREATE_SPACE";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/space`, formData, config)
      .then(successResult => {
        const data = successResult.data;
        console.log(data)
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
        dispatch(setUserSignupStep(3));
        dispatch(setCurrentSpace(data._id));
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// CREATE SPACE LISTING
//----------------------------------------------------------------
export function createSpaceListing(spaceListing, history, update) {
  let spaceListingData = {...spaceListing};
  const formData = new FormData();
  formData.append('upload', spaceListingData.image);
  delete spaceListingData.image;
  Object.keys(spaceListingData).forEach(k => {
    formData.append(k, spaceListingData[k]);
  });
  
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  let ACTION_NAME = "CREATE_SPACE_LISTING";
  if(!update) {
    ACTION_NAME = ACTION_NAME + '_SIGNUP'
  }
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/spaceListing', formData, config)
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data, update: update});
        if(!update) 
        dispatch(setUserSignupStep(4));
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET SPACE CREATION ERROR
//----------------------------------------------------------------
// export const resetSpaceCreationError = () => ({
//   type: 'RESET_SPACE_CREATION_ERROR'
// })

//----------------------------------------------------------------
// OUTLOOK LOGIN
//----------------------------------------------------------------
export function outlookLogin(spaceID, token) {
  const ACTION_NAME = "OUTLOOK_LOGIN";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post('/api/signUp/mailsyTrigger/outlook', {code: token, spaceID})
      .then(successResult => {
        const data = successResult.data;
        console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
      })
      .catch(errorResult => {
        console.log(errorResult.response);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// UPDATE USER PROFILE WITH SIGNUP STEP
//----------------------------------------------------------------
export function setUserSignupStep(step) {
  const ACTION_NAME = "EDIT_USER";
  return function(dispatch) {
    dispatch(setSignupStep(step));
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch('/api/users/me', {migrationStep: step})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// COMPLETE SIGNUP
//----------------------------------------------------------------
export function completeSignup(history) {
  const ACTION_NAME = "EDIT_USER";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch('/api/users/me', {migrationStep: 8})
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
        history.push('/');
        dispatch(setSignupStep(8));
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// SET SIGNUP STEP
//----------------------------------------------------------------
export const setSignupStep = (step) => ({
  type: 'SET_SIGNUP_STEP',
  step
})

//----------------------------------------------------------------
// SET FRONTDESK SIGNUP STEP
//----------------------------------------------------------------
export function setFrontdeskSignupStep(spaceID, step) {
  const ACTION_NAME = "SET_FRONTDESK_SIGNUP_STEP";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/space/${spaceID}`, {frontdeskSignup: step})
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data});
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

export const resetError = (action) => {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}

