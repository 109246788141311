import React from 'react';
import { Typography } from '@material-ui/core';
import { DATE_FORMATS, fetchDateFormat, getAmtFormat } from "../../../../utils/utility";
import { EXPERIENCE_STATUS } from '../../../../utils/constants';


const ExperienceSummary = (props) => {

  const { currency, expBookings, classes } = props;

  const itemView = expBookings?.map((booking) => {
    return booking?.products?.map((item, index) => {
      return item.quantity && item.status === EXPERIENCE_STATUS.CONFIRMED ? (
        <div className={classes.roomItemContainer} key={`experience_${index}`}>
          <div className={classes.itemDesc}>
            <div>
              <Typography variant="body2" className={classes.secodaryText}>{item.quantity} Unit</Typography>
              <Typography variant="body1" className={classes.roomName}>{item.product.title}</Typography>
              <Typography variant="body2" className={classes.iconCount}>{fetchDateFormat(item.date, DATE_FORMATS.LONG_DATE)}</Typography>
              <Typography variant="body2" className={classes.iconCount}>{getAmtFormat((item.price.raw || 0) / item.quantity, currency) + ' per unit'}</Typography>
            </div>
            <div className={classes.priceContainer}>
              <Typography variant="body1" className={classes.price}>{getAmtFormat((item?.price?.raw || 0), currency)}</Typography>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      ) : null
    });
  });

  return itemView;
}

export default ExperienceSummary;