import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import CashAccountList from "./CashAccountList";
import { returnCurrent } from "../../redux/selectors/dashboard";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";

import AccessHeaderAction from "../permissions/AcessHeaderAction";
import { MODULE } from "../../common/constants/permission";
import { setupCashAccounts } from "../../redux/actions/cash-drawers/cashAccounts";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: "hidden",
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  newListButton: {
    left: 24,
    position: "absolute",
    top: 29,
    "&:focus": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  addButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontSize: "3rem",
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: 28,
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      left: "15px",
    },
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  createSection: {
    alignItems: "flex-end",
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {
    boxShadow: "0 1px 10px #E0E0E0",
    borderRadius: 10,
    marginTop: 10,
    width: "80%",
  },
  buttonHolder: {
    display: "flex",
    marginLeft: 10,
    marginBottom: 4,
  },
  button: {
    border: "1px solid #dddddd",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1.8rem",
    marginLeft: 10,
    padding: 5,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
  },
  contactDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  contact: {
    width: "47%",
  },
  pin: {
    marginRight: 25,
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: 10,
  },
  loadingState: {
    minHeight: "calc(100vh - 250px)",
    margin: theme.spacing(3),
    width: "calc(100% - 48px)",
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)",
    },
  },
  listSection: {
    margin: theme.spacing(3),
    //minHeight: 'calc(100vh - 230px)'
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 10,
    paddingLeft: 8,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
    paddingLeft: 0,
    '[type="text"]': {
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const options = [
  "Inventory",
  "Guests",
  "Staff",
  "Utilities",
  "Operations",
  "Office Admin",
  "Misc",
];

const CashAccounts = (props) => {
  const { t } = props;
  const [title, setTitle] = React.useState("");
  const [newCashAccount, setNewCashAccount] = React.useState(false);
  const classes = useStyles();
  const { cashCategories, loadingCashCategories, cashAccounts } = props;
  const [cashCategoryOptions, setCashCategoryOptions] = React.useState([]);

  React.useEffect(() => {
    if (cashCategories) {
      let presentCategories = Object.keys(cashCategories).map(
        (i) => cashCategories[i].isActive && cashCategories[i].name
      );
      handleFetchOptions(presentCategories);
    }
  }, [cashCategories]);

  const handleFetchOptions = (presentCategories = []) => {
    let newOptions = _.difference(options, presentCategories).map((i) => {
      return {
        label: i,
        value: i,
      };
    });
    setCashCategoryOptions(newOptions);
  };

  console.log(cashCategories);
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleClose = () => {
    setNewCashAccount(false);
    props.handleCloseCashAccountModal();
  };

  const handleNewCashAccountSubmit = () => {
    if (props.currentSpace && props.currentSpace.propertyID) {
      console.log(props);
      props.dispatch(
        setupCashAccounts(
          props.currentSpace._id,
          props.currentSpace.propertyID,
          [{ name: title }]
        )
      );
    } else {
      console.log(
        "ERROR: Current space not loaded or no property ID has been setup"
      );
    }
    setNewCashAccount(false);
    setTitle("");
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {/* {!newCashAccount && (
            <Hidden smDown><Button 
              size='small' 
              onClick={() => setNewCashAccount(true)} 
              className={classes.newListButton} 
              color='primary' 
              variant='outlined'
            >
              New Category
            </Button></Hidden>
          )} */}
          {!newCashAccount && cashCategoryOptions.length > 0 && (
            <AddIcon
              className={classes.addButton}
              onClick={() => setNewCashAccount(true)}
            />
          )}
          <Typography className={classes.header}>
            {t("frontdesk.cashAccount.name")}
          </Typography>
          <AccessHeaderAction moduleID={MODULE.CASH_ACCOUNTS.ID} />
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.listContainer}>
          {/* ------------------------------------------------ */}
          {/* CREATE NEW CASH ACCOUNT CATEGORY */}
          {/* ------------------------------------------------ */}
          <div
            className={classes.createSection}
            style={{ display: newCashAccount === false ? "none" : "" }}
          >
            <div className={classes.select}>
              <Select
                isSearchable={true}
                name="name"
                placeholder="New cash category"
                onChange={(option) => setTitle(option.value)}
                options={cashCategoryOptions}
                styles={selectStyle}
                theme={(theme) => ({
                  ...theme,
                  border: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#666666",
                    primary25: "#dddddd",
                  },
                })}
              />
            </div>
            <div className={classes.buttonHolder}>
              <CloseIcon
                className={classes.button}
                onClick={() => setNewCashAccount(false)}
              />
              <DoneIcon
                className={classes.button}
                onClick={handleNewCashAccountSubmit}
              />
            </div>
          </div>

          {loadingCashCategories && (
            <div className={classes.loadingState}>
              {t("frontdesk.cashAccount.loading")}
            </div>
          )}

          {/* --------------------------------------------------------- */}
          {/* Staff List */}
          {/* --------------------------------------------------------- */}

          {!loadingCashCategories && (
            <div className={classes.listSection}>
              {Object.keys(cashCategories).map(
                (category) =>
                  cashCategories[category].isActive && (
                    <CashAccountList
                      categoryID={category}
                      cashCategoryOptions={cashCategoryOptions}
                      key={category}
                      handleClose={handleClose}
                      spaceID={props.currentSpace.id}
                      propertyID={props.currentSpace.propertyID}
                    />
                  )
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  cashCategories: state.cashCategories,
  cashAccounts: state.cashAccounts,
  loadingCashCategories: state.loading.LOAD_CASH_ACCOUNTS,
});

export default withTranslation()(connect(mapStateToProps)(CashAccounts));
