import http from "../../utils/http";

//----------------------------------------------------------------
// Load Hostelworld Hosted Linkups token
//----------------------------------------------------------------
export function getHostelworldHostedLinkupsToken({ propertyID }) {
  const ACTION_NAME = "LOAD_HHL_TOKEN";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/frontdesk/hostelworld/linkups/${propertyID}`)
      .then((successResult) => {
        const payload = successResult?.data;

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload,
        });
      })
      .catch((errorResult) => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}
