import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import clsx from 'clsx'

import NumberInput from '../../../common/NumberInput'
import MicrositeDescription from '../MicrositeDescription'
import { getMissingLanguage } from '../../helper'
import LanguagesView from '../LanguageView';

import MapBox from '../../../common/MapBox';

const useStyles = makeStyles(theme => ({
  field: {
    alignItems: 'flex-start',
    background: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 10px #E0E0E0',
    display: 'flex',
    flexWrap: 'wrap',
    height: 150,
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '80%'
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  short: {
    height: 35,
    width: '30%'
  },
  mapbox: {
    width: '80% !important',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}))

const getKey = (content, keyName, selectedLanguage) => {
  return content ? Object.keys(content).find(key => key.startsWith(keyName) && key.indexOf(selectedLanguage) >= 0) : null
}

const Location = props => {
  const classes = useStyles()
  const {
    settings = { location: {} },
    updateSettings,
    deleteSettingsContent,
    t
  } = props

  const { location: { latitude, longitude }, content } = settings
  const [selectedLanguage, setSelectedLanguage] = React.useState('en_gb');
  const [directionsContentKey, setDirectionsContentKey] = React.useState(getKey(content, 'directions', 'en_gb'));
  const [locationContentKey, setLocationContentKey] = React.useState(getKey(content, 'location', 'en_gb'));

  React.useEffect(() => {
    setDirectionsContentKey(getKey(content, 'directions', selectedLanguage))
    setLocationContentKey(getKey(content, 'location', selectedLanguage))
  }, [selectedLanguage])

  const seLanguageHandler = (lang) => {
    setSelectedLanguage(lang);
    deleteSettingsContent(`${directionsContentKey}`)
  }

  const onChangeLocation = useCallback((loc) =>{
    updateSettings('location.latitude', loc.latitude);
    updateSettings('location.longitude', loc.longitude);
  }, []);

  return (
    <>
      <div className='main-content'>

        <div className='main-content-title'>{t('microsite.location.title')}</div>
        <div className='sub-title'>{t('microsite.location.description')}</div>

        <div className={clsx('row', classes.mapbox)}>
          <MapBox
            coordinates={{ 
                latitude: latitude || 0, 
                longitude: longitude || 0 
            }} 
            onChangeLocation={onChangeLocation}
          />
        </div>

        <div className='row'>
          <div className='label'>{t('microsite.location.latitude')}</div>
          <div className={clsx(classes.field, classes.short)}>
            <NumberInput
              value={latitude}
              onChange={e => {
                updateSettings('location.latitude', e.target.value)
                updateSettings('location.longitude', longitude)
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='label'>{t('microsite.location.longitude')}</div>
          <div className={clsx(classes.field, classes.short)}>
            <NumberInput
              value={longitude}
              onChange={e => {
                updateSettings('location.latitude', latitude)
                updateSettings('location.longitude', e.target.value)
              }}
            />
          </div>
        </div>

        <LanguagesView value={selectedLanguage} updateLanguage={(lang) => seLanguageHandler(lang)}/>

        <MicrositeDescription
          label={t('microsite.location.directionsTo')}
          tooltipText={t('microsite.location.directionsToHelper')}
          keyName={'directionsTo'}
          value={content?.[directionsContentKey]?.content}
          disabled={false}
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${directionsContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'directions')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
        />

        <MicrositeDescription
          label={t('microsite.location.locatedNear')}
          tooltipText={t('microsite.location.locatedNearHelper')}
          keyName={'locatedNear'}
          value={content?.[locationContentKey]?.content}
          disabled={true}
          language={selectedLanguage}
          onChange={value => updateSettings(`content.${locationContentKey}.content`, value)}
          missingLanguages={getMissingLanguage(content, 'location')}
          updateLanguage={(lang) => seLanguageHandler(lang)}
        />

      </div>
    </>
  )
}

export default withTranslation()(Location)
