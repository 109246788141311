const defaultProperty = { default: true}

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "LOAD_PROPERTY_SUCCESS":
      return {
        ...action.payload
      }
    case "EDIT_PROPERTY_SUCCESS":
      return {
        ...action.payload
      }
    case "RESET_PROPERTY":
      return defaultProperty
    
    // COLOMBIA MIGRACION REPORT 
    // ---------------------------------------------
    case "LOAD_COLOMBIA_DETAILS_SUCCESS":
      return {
        ...state,
        colombia: {...action.payload}
      }
    case "SUBMIT_COLOMBIA_DETAILS_SUCCESS":
      return {
        ...state,
        colombia: {...action.payload}
      }

    default:
      return state;
  }
};
  