import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import http from '../../redux/utils/http';
import { setCurrentMaker } from '../../redux/actions/dashboard/dashboard';
import { initials } from '../utils/wordUtils';
import { newMessage } from '../../redux/actions/messages/messages';
import { changeUserRole } from '../../redux/actions/user/user';
import { toTitleCase } from '../utils/wordUtils';
import { reloadLoadSpaceDetails } from '../../redux/actions/subscriptions/subscriptionUpgrade';
import { adminUserRemove } from '../../redux/actions/user/user';

import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import DeleteIcon from '@material-ui/icons/Delete';

import ManageUserPermissions from '../permissions/ManageUserPermissions';
import { resetAccessData } from '../../redux/actions/permissions/permissions';
import ConfirmForm from '../common/ConfirmForm';

const useStyles = makeStyles( theme => ({
  root:{
    paddingBottom: theme.spacing(1),
  },
  container: {
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    padding: theme.spacing(2)
  },
  bubble: {
    backgroundColor: '#E0E0E0',
    border: '0px solid #dddddd',
    color: '#ffffff',
    fontSize: '2rem',
    height: 40,
    lineHeight: 3,
    textAlign: 'center',
    width: 40
  },
  userContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  userName: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 5,
  },
  you: {
    background: '#E0E0E0',
    borderRadius: '25px',
    color: '#666666',
    fontSize: '1.1rem',
    marginLeft: 15,
    padding: '5px 8px',
  },
  attributes: {
    color: '#484848',
    display: 'flex',
    marginBottom: 5,
  },
  dot: {
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    color: '#333333',
    fontWeight: 600,
  },
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  lockIcon:{
    borderRadius: '50%',
    padding: '8px',
    zIndex: 99,
    cursor: 'pointer',
    marginLeft: 15,
    '& img':{
      width: '20px',
      height: '20px'
    },
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  button: {
    marginLeft: 10,
    background: '#F8F9FB',
    borderRadius: 25,
    fontSize: '1.2rem'
  },
  downgradeButton: {
    border: '1px solid #f44336',
    marginLeft: 10,
    background: '#F6E2E0',
    color: '#f44336',
    borderRadius: 25,
    fontSize: '1.2rem'
  },
  upgradeButton: {
    border: '1px solid #2E6D63',
    marginLeft: 10,
    background: '#FFFFFF',
    color: '#2E6D63',
    borderRadius: 25,
    fontSize: '1.2rem'
  },
  dialogPaper: {
    minHeight: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  deleteIcon: {
    color: '#666666',
    cursor: 'pointer',
    padding: '10px',
    background: '#dddddd',
    transition: '150ms',
    borderRadius: '50%',
    width: '17px',
    height: '17px'
  }
}))

const UserTile = (props) => {
  const classes = useStyles();
  const { user, userType, userID, currentUserID, currentSpace, t, currentUser } = props;
  const isCurrentMakerAdmin = currentSpace && currentSpace.admins.includes(userID);
  const isCurrentUserAdmin = currentSpace.admins.includes(currentUserID);
  const isOwn = (userID == currentUserID) ? true : false;
  const isCurrentUserSysAdmin = currentUser?.isSystemAdmin

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false)

  useEffect(() => {
    return () => props.dispatch(resetAccessData());
  },[]);

  const handleClose = () => {
    closePermissionModal();
    props.dispatch(setCurrentMaker());
    window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
  }

  const handleLock = () => {
    setCurrentMaker(userID);
    setShowPermissionModal(true);
  }

  const handleMessage = () => {
    props.dispatch(newMessage([userID, currentUserID]));
  }

  const closePermissionModal = () =>{
    setShowPermissionModal(false);
  }

  const handleChangeUser = (type) => {
    props.dispatch(reloadLoadSpaceDetails(currentSpace._id))
    .then((response) => {
      if(response) {
        const space = response.spaces[currentSpace._id]
        if (space.admins.includes(currentUserID))
          props.dispatch(changeUserRole(userID, currentSpace._id, userType))
      }
    })
  }

  const deleteUser = () =>{
    const data = {
      userType: isCurrentMakerAdmin ? 'admins' : 'members',
      userID,
      spaceID: currentSpace._id
    }

    props.dispatch(adminUserRemove(data))
    .then((response) => {
      if(response.data)
        console.log('nice')
    })
  }

  return (
    <div className={classes.root}>
        {user != null &&  (
          <div className={classes.container}>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={1} md={1}>
                <Avatar
                  alt={initials(user.name)}
                  src={user.avatar} 
                  className={classes.bubble}
                >
                  {initials(user.name)}
                  {/* {console.log(currentMaker.name)} */}
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={5} md={5}>
                <div className={classes.userContainer}>
                  <div className={classes.userName}>
                    <Typography className={classes.title} variant="h6">{toTitleCase(user.name)}</Typography>
                  {user._id === currentUserID && <div className={classes.you}>You</div>}
                  </div>
                  <div className={classes.attributes}>
                    <Typography variant="body2">{user.email}</Typography>
                    <Hidden smDown><div className={classes.dot}>&#183;</div>
                    <Typography variant="body2">{toTitleCase(userType)}</Typography></Hidden>
                  </div>
                  <Hidden smUp>
                    <div className={classes.attributes}>
                      <Typography variant="body2">{toTitleCase(userType)}</Typography>
                    </div>
                  </Hidden>
                </div>
              </Grid>
              {!isCurrentUserSysAdmin && <Grid item xs={12} sm={6}  md={6}>
                {isCurrentUserAdmin && <div className={classes.actionContainer}>
                  {currentSpace.onboardingStatus && (currentSpace.onboardingStatus == 'plus' || currentSpace.onboardingStatus == 'pro') && <span>
                  {user._id !== currentUserID && userType === 'admin' && (
                      <Button variant="outlined" className={classes.downgradeButton} onClick={() => handleChangeUser('downgrade')}>Downgrade to Member</Button>
                    )}
                  {user._id !== currentUserID && userType === 'member' && (
                      <Button variant="outlined" className={classes.upgradeButton} onClick={() => handleChangeUser('upgrade')}>Upgrade to Admin</Button>
                    )}
                  </span>}
                  {!isOwn && (
                    <Button variant="outlined" className={classes.button} onClick={handleLock}>Permissions</Button>
                  )}
                  {/* {isCurrentUserAdmin && !isCurrentMakerAdmin && !isOwn && <div className={classes.lockIcon} onClick={handleLock}>
                    <img alt="delete" src='/images/icons/bin.png' />
                  </div>} */}
                </div>}
              </Grid>}
              {isCurrentUserSysAdmin && <Grid item xs={12} sm={6} md={6}>
                <div className={classes.actionContainer}>
                  {!confirmDelete && <DeleteIcon
                    className={classes.deleteIcon}
                    onClick={() => setConfirmDelete(true)}
                  />}
                  {confirmDelete && <ConfirmForm
                    title="Delete User?"
                    open={confirmDelete}
                    setOpen={setConfirmDelete}
                    onClose={() => setConfirmDelete(false)}
                    onConfirm={deleteUser}
                  >
                    Are you sure you would like to remove this user?
                  </ConfirmForm>}
                </div>
              </Grid>}
            </Grid>
          </div>
        )}

        {showPermissionModal && (
          <Dialog
            open={showPermissionModal}
            onClose={handleClose}
            classes={{ paperFullWidth: classes.dialogPaper }}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={window.innerWidth < 901}
          >
            <ManageUserPermissions 
              currentMaker={user}
              currentMakerID={userID}
              closeModalHandler={closePermissionModal}
              closeMainModalHandler={handleClose}
            />
          </Dialog>
        )}
    </div>
  );
}

const mapStateToProps = state => ({
  currentUserID: state.auth.user._id,
  currentUser: state.auth.user,
  currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default withTranslation()(connect(mapStateToProps)(UserTile));