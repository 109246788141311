import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import Header from './Header';
import { Button, Tooltip } from '@material-ui/core';
import DurationDatePicker from './DurationDatePicker';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from './Calendar';
import Choice from './Choice';
import ChangeRateForm from './ChangeRateForm';
import ChangeBedForm from './ChangeBedForm';
import { AllowEditDates, DEVICE_VIEW, fetchAccommodationFormType, FORM_TYPE } from './Helper';
import DialogForm from './DialogForm';
import { DATE_FORMATS, fetchDateFormat, fetchNoOfNights } from '../../../../utils/utility';
import ChangeDeparture from './ChangeDeparture';
import ChangeArrival from './ChangeArrival';
import ExtendStay from './ExtendStay';
import ChangeDates from './ChangeDates';

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid #dadada',
        borderRadius: 5,
        margin: '15px 25px 15px 25px',
        padding: 20,
        '&.disabled': {
            backgroundColor: '#f2f2f2',
            '& .DateInput_input__disabled, .DateInput__disabled, .DateRangePickerInput__disabled':{
                backgroundColor: '#f2f2f2',
            }
        },
        [theme.breakpoints.down('xs')]: {
            margin: 10,
            padding: 5,
        }
    },
    dateContainer: {
        display: 'flex',
        marginBottom: 20,
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '& button': {
                width: '100%'
            }
        }
    },
    settings: {
        position: 'relative'
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#ffffff',
        cursor: 'pointer',
        padding: theme.spacing(1),
    },
    chip: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        backgroundColor: '#7BAC85',
        borderRadius: 20,
        width: 'max-content',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            width: 'unset',
            padding: 10,
            marginBottom: 10,
        }
    },
    emoji: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '2rem',
        marginRight: 10,
    },
    text: {
        fontSize: '1.4rem',
    },
    button: {
        backgroundColor: '#ffffff',
        border: '1px solid #dadada',
        borderRadius: 20,
        padding: '10px 25px',
        fontSize: '1.3rem',
        color: '#13408d',
        fontWeight: '600',
    },
    disabled: {
        cursor: 'auto',
        color: '#999999',
    },
    body: {
        paddingTop: 5
    },
    spacing: {
        padding: '10px 0'
    },
    lightLabel: {
        color: '#bebebe',
        fontSize: '1.4rem',
        fontWeight: 600,
        marginBottom: 10,
    }
}));

const NotificationChip = ({
    classes,
    title,
    icon,
    cancelHandler
}) => (
    <div className={classes.chip}>
        <div className={classes.emoji}>&#128071;</div>
        <div className={classes.text}>{title}</div>
    </div>
)

const DoubleDurationPicker = (props) => {
    const classes = useStyles();
    const { data, range, index } = props;
    const [pickerRange, setPickerRange] = React.useState({ startDate: range.startDate, endDate: range.endDate })

    const onDatesChangeHandler = (startDate, endDate) => {
        setPickerRange({
            startDate: fetchDateFormat(startDate, DATE_FORMATS.DEFAULT_DATE),
            endDate: fetchDateFormat(endDate, DATE_FORMATS.DEFAULT_DATE)
        })
    }

    const onClickEditDatesHandler = () => {
        props.updatePickerRangeChoiceHandler(pickerRange.startDate, pickerRange.endDate)
    }

    return (
        <React.Fragment>
            {/* -------------------- Duration picker -------------------- */}
            <div className={classes.spacing}></div>
            <div className={classes.dateLabelContainer}>
                <div className={classes.lightLabel}>Original dates</div>
                <DurationDatePicker index={`old_${index}`} startLabel={'Check in date'} endLabel={'Check out date'} startDate={data.checkIn} endDate={data.checkOut} disabled={true} />
            </div>
            <div className={classes.spacing}></div>
            <div className={classes.dateLabelContainer}>
                <div className={classes.lightLabel}>New dates</div>
                <div className={classes.dateContainer}>
                    <DurationDatePicker index={`new_${index}`} startLabel={'Check in date'} endLabel={'Check out date'} startDate={range.startDate} endDate={range.endDate} onDatesChangeHandler={onDatesChangeHandler} />
                    {AllowEditDates(range.startDate, range.endDate, pickerRange.startDate, pickerRange.endDate) && <Button className={classes.button} onClick={onClickEditDatesHandler}>Edit dates</Button>}
                </div>
            </div>
            <div className={classes.spacing}></div>
        </React.Fragment>
    )
}

const AccommodationCard = (props) => {
    const classes = useStyles();
    const node = React.createRef();
    const { data, lockedReservation, currentSpace, allowExpand, allowDelete, allowDataActions, loading } = props;
    const [openForm, setOpenForm] = React.useState('')
    const [range, setRange] = React.useState({})
    const [pickerRange, setPickerRange] = React.useState({ startDate: data.checkIn, endDate: data.checkOut })
    const [expand, setExpand] = React.useState(allowExpand ? 'less' : 'more')
    const [view, setView] = React.useState((window.innerWidth > 600 ? DEVICE_VIEW.LARGE : DEVICE_VIEW.SMALL))

    React.useEffect(() => {
        if (pickerRange && openForm) {
            onClickEditDatesHandler()
        }
    }, [pickerRange])

    React.useEffect(() => {
        setPickerRange({ startDate: data.checkIn, endDate: data.checkOut })
    }, [data])

    React.useLayoutEffect(() => {
        function updateSize() {
            setView((window.innerWidth > 600 ? DEVICE_VIEW.LARGE : DEVICE_VIEW.SMALL));
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // -------------------- UI actions --------------------
    const closeModalHandler = () => {
        setOpenForm('');
        setRange({});
        node.current.clearRangeHandler();
        setPickerRange({ startDate: data.checkIn, endDate: data.checkOut })
        props.resetCallHandler();
    }
    const changeExpandHandler = (value) => {
        setExpand(value);
    }

    const selectChoiceHandler = (type) => {
        setOpenForm(type);
        if (type === FORM_TYPE.BED_FORM)
            props.callBedSuggestionHandler(range.startDate, (fetchNoOfNights(range.startDate, range.endDate) || 0) + 1)
        if (type === FORM_TYPE.EXTEND)
            props.callBedSuggestionHandler(data.checkOut, (fetchNoOfNights(data.checkOut, pickerRange.endDate) || 0) + 1)
        if (type === FORM_TYPE.MOVE_ACCOMMODATION ||
            type === FORM_TYPE.EXTEND_ACCOMMODATION ||
            type === FORM_TYPE.PRE_PONE_CHECK_IN)
            props.callBedSuggestionHandler(pickerRange.startDate, (fetchNoOfNights(pickerRange.startDate, pickerRange.endDate) || 0) + 1)
    }

    const onDatesChangeHandler = (startDate, endDate) => {
        setPickerRange({
            startDate: fetchDateFormat(startDate, DATE_FORMATS.DEFAULT_DATE),
            endDate: fetchDateFormat(endDate, DATE_FORMATS.DEFAULT_DATE)
        })
    }

    const onClickEditDatesHandler = () => {
        let type = fetchAccommodationFormType(data.checkIn, data.checkOut, pickerRange.startDate, pickerRange.endDate);
        if (type) selectChoiceHandler(type);
    }

    const fetchDialogTitleHandler = (type) => {
        let title = '';
        switch (type) {
            case FORM_TYPE.CHOICE:
                title = 'Select what you would like to do';
                break;
            case FORM_TYPE.RATE_FORM:
                title = `Edit price for ${data.guestName}`;
                break;
            case FORM_TYPE.BED_FORM:
                title = `Edit room for ${data.guestName}`;
                break;
            case FORM_TYPE.DEPARTURE:
            case FORM_TYPE.ARRIVAL:
            case FORM_TYPE.EXTEND:
            case FORM_TYPE.PRE_PONE_CHECK_IN:
            case FORM_TYPE.POST_PONE_CHECK_IN:
            case FORM_TYPE.MOVE_ACCOMMODATION:
            case FORM_TYPE.EXTEND_ACCOMMODATION:
            case FORM_TYPE.SHORTEN_ACCOMMODATION:
                title = `Edit dates for ${data.guestName}`;
                break;
            default:
                title = '';
                break;
        }
        return title;
    }

    // -------------------- submit --------------------
    const onSubmitHandler = (payload) => {
        props.changeGuestAccommodationHandler(payload);
        closeModalHandler();
    }



    return (
        <div className={clsx(classes.container)}>
            {/* ----------------------- Header ------------------------------ */}
            <Header
                data={data}
                expand={expand}
                allowDelete={allowDelete}
                allowDataActions={allowDataActions}
                deleteGuestHandler={() => props.deleteGuestHandler(data.guestNumber)}
                changeExpandHandler={changeExpandHandler}
            />
            { expand !== 'less' &&
                <div className={classes.body}>
                    {/* ----------------------- Edit reservation dates form ------------------------------ */}
                    <div className={classes.dateContainer}>
                        <DurationDatePicker
                            index={`original_${data.guestNumber}`}
                            disabled={!props.allowDataActions}
                            startLabel={'Check in date'}
                            endLabel={'Check out date'}
                            startDate={pickerRange.startDate}
                            endDate={pickerRange.endDate}
                            onDatesChangeHandler={onDatesChangeHandler}
                        />

                        {allowDataActions && AllowEditDates(data.checkIn, data.checkOut, pickerRange.startDate, pickerRange.endDate) &&
                            <Button className={classes.button} onClick={onClickEditDatesHandler}>Edit dates</Button>
                        }

                        {allowDataActions && !AllowEditDates(data.checkIn, data.checkOut, pickerRange.startDate, pickerRange.endDate) &&
                            <Tooltip title="Please select a new check-in and check-out date to proceed">
                                <Button className={clsx(classes.button, classes.disabled)}>Edit dates</Button>
                            </Tooltip>
                        }
                    </div>
                    {/* ----------------------- Notification chip ------------------------------ */}
                    {allowDataActions &&
                        <div className={classes.settings}>
                            <NotificationChip
                                classes={classes}
                                title="Select date range to change rooms & rates"
                                cancelHandler={() => void 0}
                            />
                        </div>
                    }
                    {/* ---------------- Guest accommodation calendar --------------------- */}
                    <Calendar
                        index={`calendar_${data.guestNumber}`}
                        ref={node}
                        data={data}
                        view={view}
                        currentSpace={currentSpace}
                        allowDataActions={allowDataActions}
                        changeModalHandler={(type, range) => {
                            setOpenForm(type);
                            setRange(range);
                        }}
                    />
                </div>
            }
            {/* ------------------------ Dialog form -------------------- */}
            {
                openForm &&
                <DialogForm isSmallerForm={openForm === FORM_TYPE.CHOICE} closeModalHandler={closeModalHandler} loading={loading}
                    title={fetchDialogTitleHandler(openForm)}>

                    {/* ------------------------ Choice form -------------------- */}
                    {
                        openForm === FORM_TYPE.CHOICE &&
                        <Choice changeModalHandler={(type) => selectChoiceHandler(type)} lockedReservation={lockedReservation}/>
                    }

                    {/* ------------------------ Change rate form -------------------- */}
                    {
                        openForm === FORM_TYPE.RATE_FORM &&
                        <ChangeRateForm data={data} range={range}
                            onSubmitHandler={onSubmitHandler} />
                    }

                    {/* ------------------------ Change bed form -------------------- */}
                    {
                        openForm === FORM_TYPE.BED_FORM &&
                        <ChangeBedForm data={data} range={range} currentSpace={currentSpace} view={view}
                            onSubmitHandler={onSubmitHandler} />
                    }

                    {/* ------------------------ Change departure -------------------- */}
                    {
                        openForm === FORM_TYPE.DEPARTURE &&
                        <React.Fragment>
                            <DoubleDurationPicker index={`${openForm}_${data.guestNumber}`} data={data} range={pickerRange} updatePickerRangeChoiceHandler={onDatesChangeHandler} />
                            <ChangeDeparture data={data} range={pickerRange}
                                onSubmitHandler={onSubmitHandler} />
                        </React.Fragment>
                    }

                    {/* ------------------------ Change arrival -------------------- */}
                    {
                        openForm === FORM_TYPE.ARRIVAL &&
                        <React.Fragment>
                            <DoubleDurationPicker index={`${openForm}_${data.guestNumber}`} data={data} range={pickerRange} updatePickerRangeChoiceHandler={onDatesChangeHandler} />
                            <ChangeArrival data={data} range={pickerRange}
                                onSubmitHandler={onSubmitHandler} />
                        </React.Fragment>
                    }

                    {/* ------------------------ Change extend -------------------- */}
                    {
                        openForm === FORM_TYPE.EXTEND &&
                        <React.Fragment>
                            <DoubleDurationPicker index={`${openForm}_${data.guestNumber}`} data={data} range={pickerRange} updatePickerRangeChoiceHandler={onDatesChangeHandler} />
                            <ExtendStay data={data} range={pickerRange} currentSpace={currentSpace} view={view}
                                onSubmitHandler={onSubmitHandler} />
                        </React.Fragment>
                    }

                    {/* ------------------------ Edit dates -------------------- */}
                    {
                        (openForm === FORM_TYPE.PRE_PONE_CHECK_IN ||
                            openForm === FORM_TYPE.POST_PONE_CHECK_IN ||
                            openForm === FORM_TYPE.MOVE_ACCOMMODATION ||
                            openForm === FORM_TYPE.EXTEND_ACCOMMODATION ||
                            openForm === FORM_TYPE.SHORTEN_ACCOMMODATION) &&
                        <React.Fragment>
                            <DoubleDurationPicker index={`${openForm}_${data.guestNumber}`} data={data} range={pickerRange} updatePickerRangeChoiceHandler={onDatesChangeHandler} />
                            <ChangeDates typeOfForm={openForm} data={data} range={pickerRange} currentSpace={currentSpace} view={view} lockedReservation={lockedReservation}
                                onSubmitHandler={(payload) => {
                                    // console.log('payload', payload)
                                    onSubmitHandler(payload)
                                }} />
                        </React.Fragment>
                    }
                </DialogForm>
            }

        </div>
    )
}

export default AccommodationCard;