import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { initials } from '../../utils/wordUtils';

const useStyles = makeStyles(theme =>({
    avatarContainer:{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        // overflow: 'hidden',
        // maxWidth: '345px',
        // paddingRight: '10px',
        // [theme.breakpoints.down("sm")]:{
        //     maxWidth: '155px'
        // }
    },
    avatar:{
        height: 30,
        width: 30,
        marginRight: -10,
        fontSize: '1rem'
    },
    handPointer:{
        cursor: 'pointer'
    }
}));

const ModuleUsersAvatar = ({ 
    isAdmin,
    displayAccessData, 
    users, 
    maxDisplayLimit,
    clickHandler 
}) =>{
    const classes = useStyles();
    const smallDevice = window.matchMedia('(max-width: 400px)').matches;
    let displayCount = smallDevice ? 6 : 15;
    displayCount = maxDisplayLimit ? maxDisplayLimit : displayCount;
    return(
        <div 
            className={clsx({
                [classes.avatarContainer]: true,
                [classes.handPointer]: isAdmin 
            })} 
            onClick={clickHandler}
        > 
            {displayAccessData.slice(0,displayCount).map((userID,key) => {
                if(users[userID]){
                    return(
                        <Avatar
                            data="access-user-avatar"
                            key={key}
                            className={classes.avatar}
                            alt={initials(users[userID].name)}
                            src={ users[userID].avatar || ''}
                        >
                            {initials(users[userID].name)}
                        </Avatar>
                    )
                }
            })}
        </div>
    );
}

const mapStateToProps = (state, props) =>{
    const { spaces, dashboard, users, permissions: { accessIdData = {} } } = state;
    const currentSpace = spaces[dashboard.currentSpace] || {};
    const admins = currentSpace.admins ? currentSpace.admins : [];
    let displayAccessData = accessIdData[props.moduleID] ?
                            [...admins, ...accessIdData[props.moduleID]] :
                            [...admins];
    return{
        displayAccessData,
        users
    }
}

export default connect(mapStateToProps)(ModuleUsersAvatar);