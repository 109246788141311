import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import ConfirmForm from '../../common/ConfirmForm';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: 10,
    marginLeft: 25,
  },
  lockSection: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DADADA',
    borderRadius: 50,
    cursor: 'pointer',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    textAlign: 'center',
    width: 50,
    '&:hover': {
      background: '#F2F2F2'
    }
  },
  form: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    maxWidth: '250px',
    padding: '0 10px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    }
  },
  icon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
  }
}));

const LockReservation = (props) => {
  const classes = useStyles();
  const { reservation, allocationLock = false, t } = props;
  const [lockValue, setLockValue] = React.useState(null);

  const smallDevice = window.matchMedia('(max-width: 420px)').matches;

  const handleConfirm = () => {
    if(lockValue) {
      props.updateReservation({
        reservationID: reservation._id,
        lockReservation: lockValue === 'lock' ? true : false
      })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.lockSection}>
        {
          allocationLock ?
            <img className={classes.icon} src={IMAGES.ICONS.lock} onClick={() => setLockValue('unlock')} />
            :
            <img className={classes.icon} src={IMAGES.ICONS.unlock} onClick={() => setLockValue('lock')} />
        }

      </div>
      <div className={classes.form}>
        <ConfirmForm
          open={lockValue ? true : false}
          setOpen={(value) => setLockValue(value)}
          onClose={() => setLockValue(null)}
          onConfirm={handleConfirm}
        >
          {lockValue === 'lock' ?
            (smallDevice ? t('existingReservation.reservationTab.lockReservationConfirmationMobile') :
              t('existingReservation.reservationTab.lockReservationConfirmation')) :
            (smallDevice ? t('existingReservation.reservationTab.unlockReservationConfirmationMobile') :
              t('existingReservation.reservationTab.unlockReservationConfirmation'))
          }
        </ConfirmForm>
      </div>
    </div>
  )
}

export default withTranslation()(LockReservation);