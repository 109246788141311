import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';

import Footer from '../Footer';

const useStyles = makeStyles(theme =>({
    inputField:{
        boxShadow: '0 1px 10px #E0E0E0',
        marginTop: '10px',
        width: '50%',
        borderRadius: '5px',
        [theme.breakpoints.down("xs")]:{
            width: '100%'
        }
    },
    textInput: {
        height: '40px',
        padding: '0px 10px',
        borderRadius: '5px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
    }
}));

const ActivityTitle = props =>{
    const classes = useStyles();

    const { 
        nextStepHandler, 
        prevStepHandler, 
        activeStepInfo,
        submitDraftHandler,
        productData 
    } = props;

    const [ title, setTitle ] = useState('');
    const [ titleError, setTitleError ] = useState(false);

    useEffect(() => {
        if(productData.title) setTitle(productData.title);
    }, [ productData.title ]);

    const validateTitle = title =>{
        const value = title.trim();
        const success = Boolean(value);
        const errorStatus = !success;
        setTitleError(errorStatus);
        return success;
    }

    const onChangeHandler = event =>{
        const value = event.target.value;
        validateTitle(value);
        setTitle(value);
    }

    const submitHandler = () =>{
        if(!validateTitle(title)) return;
        const data = { title };
        submitDraftHandler(data);
    }

    return(
        <>
            <div className="main-content">
                
                <div className="main-content-title">Name your experience</div>
                
                <div className="sub-title">
                    Make it short, descriptive, and exciting.
                </div>

                <div className={classes.inputField}>
                    <InputBase 
                        id="title"
                        name="title"
                        className={classes.textInput}
                        fullWidth
                        type="text"
                        error={titleError}
                        onChange={onChangeHandler}
                        value={title}
                    />
                </div>
                {titleError && (
                    <FormHelperText className="errorMessage">
                        Experience name is required
                    </FormHelperText>
                )}

            </div>

            <Footer 
                nextClickHandler = {submitHandler}
                backClickHandler = {prevStepHandler}
                activeStepInfo = {activeStepInfo}
                productData = {productData}
                hideBackBtn
            />
        </>
    )
}

export default ActivityTitle; 