import React from "react";
import { withStyles } from "@material-ui/core/styles";

import ReservationBlock from './ReservationBlock';
import CalenderDateBlock from './CalenderDateBlock';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import { checkDateIfGreater, fetchNoOfDays, getAvatarFlag } from "../../utils/utility";
import { 
    fetchBookingDuration, 
    isPreCheckedIn, 
    isStartDateEqualToCheckout,
    checkDateInReservation
} from './calenderReservationHelper';
import { RESERVATION_STATUS } from "../../utils/constants";
 
const styles = theme =>({
    roomSection: {
        borderBottom: "0px solid #dddddd",
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        height: "100%"
    },
    unpaid: {
        background: '#CC6666',
        borderRadius: '50%',
        height: 7,
        position: 'absolute',
        top: 0,
        right: 5,
        width: 7,
        zIndex: 999,
    },
    notes: {
        background: '#F2D073',
        borderRadius: '50%',
        height: 7,
        position: 'absolute',
        top: 0,
        right: 15,
        width: 7,
        zIndex: 999,
    }
});

const getReservationBlockWidth = (calenderInfo, reservation) =>{
    const { numberOfDays } = calenderInfo;
    let blockWidth = (100/numberOfDays)*reservation.visibleBookingDuration;

    // if reservation checkin date is before calender start date.
    blockWidth += isPreCheckedIn(calenderInfo, reservation) ? (50/numberOfDays) : 0;

    // If reservation checkout date spans accross calender end date.
    blockWidth += checkDateIfGreater(reservation.checkOut, calenderInfo.endDate) ? (50/numberOfDays) : 0;

    return blockWidth;
}

const getBookingNumber = (reservation) => {
    const { bookingSource, sourceResvID, code } = reservation;
    return sourceResvID ? `${sourceResvID} (${bookingSource})` : code;
}

const CalenderRoomReservations = props =>{
    const { classes } = props;
    const calenderInfo = { ...props.day, numberOfDays: props.numberOfDays };
    const reservationKeys = [ ...props.reservationKeys];
    const reservations = { ...props.reservations};
    let formatedReservationData = [];
    const dragID = 'Block';
    let checkOutAtCalenderStart = null;

    const fetchReservationPayStatus = (reservation) =>{
        const htmlView = [];
        const balance = Math.round((reservation.balance + Number.EPSILON) * 100) / 100;
        
        // RED DOT UNPAID
        if((balance > 0 && reservation.status !== RESERVATION_STATUS.BLOCK) && !checkDateIfGreater(reservation.checkOut, calenderInfo.endDate)){
            htmlView.push( <div key={`status_unpaid_${reservation._id}`} className={classes.unpaid}></div> );
        }
    
        // YELLOW DOT NOTE
        if((reservation.noteCount > 0) && !checkDateIfGreater(reservation.checkOut, calenderInfo.endDate)){
            htmlView.push( <div key={`status_notes_${reservation._id}`} className={classes.notes}></div> );
        }
        return htmlView;
    }

    // Adding data for reservation display ....
    reservationKeys.map(reservationID => {
        const reservation = reservations[reservationID] || {};  
        // Fetching booking duration within the range of calender start and end date...
        const visibleBookingDuration = fetchBookingDuration(calenderInfo, reservation);
        
        // Actual booking duration....
        const bookingDuration = fetchNoOfDays(reservation.checkIn, reservation.checkOut);
        const reservationNumber = getBookingNumber(reservation)
        const countryName = i18nIsoCountries.getName(reservation.country, "en") || reservation.country;

        if(visibleBookingDuration > 0){
            formatedReservationData.push({
                checkIn: reservation.checkIn,
                checkOut: reservation.checkOut,
                isStart: reservation.isStart,
                isEnd: reservation.isEnd,
                guestName: reservation.guestName,
                _id: reservation.accommodationID,
                resID: reservation.resID,
                bedID: reservation.bedID,
                roomID: reservation.roomID,
                noteCount: reservation.noteCount,
                totalAmount: reservation.totalAmount,
                balance: reservation.balance,
                status: reservation.status,
                accommodationID: reservation.accommodationID,
                allocationLock: reservation.allocationLock,
                visibleBookingDuration,
                bookingDuration,
                bookingSource: reservation.bookingSource,
                preCheckedIn: isPreCheckedIn(calenderInfo, reservation),
                isCalenderStartdateCheckout: isStartDateEqualToCheckout(calenderInfo.startDate, reservation.checkOut),
                flag: getAvatarFlag(countryName),
                reservationNumber: reservationNumber,
                latestNote: reservation.latestNote,
                code: reservation.code,
                guestCount: reservation.guestCount,
                timeOfArrival: reservation.timeOfArrival,
            });
        }

        if(isStartDateEqualToCheckout(calenderInfo.startDate, reservation.checkOut)){
            checkOutAtCalenderStart = { 
                checkIn: reservation.checkIn,
                checkOut: reservation.checkOut,
                guestName: reservation.guestName,
                _id: reservation.accommodationID,
                resID: reservation.resID,
                bedID: reservation.bedID,
                roomID: reservation.roomID,
                noteCount: reservation.noteCount,
                totalAmount: reservation.totalAmount,
                balance: reservation.balance,
                status: reservation.status,
                accommodationID: reservation.accommodationID,
                allocationLock: reservation.allocationLock,
                visibleBookingDuration,
                bookingDuration,
                bookingSource: reservation.bookingSource,
                flag: getAvatarFlag(countryName),
                reservationNumber: reservationNumber,
                latestNote: reservation.latestNote,
                code: reservation.code,
                guestCount: reservation.guestCount,
                timeOfArrival: reservation.timeOfArrival,
            }
        }
    });

    // Sorting reservation dates w.r.t checkin ....
    formatedReservationData = [...formatedReservationData].sort(function(a, b) {
        var date1 = new Date(a.checkIn);
        var date2 = new Date(b.checkIn);
        return date1-date2;
    });

    const checkInBeforeCalenderStart = formatedReservationData.find(res => res.preCheckedIn)
    let bookingSpan = 0;
    return(
        <div className={classes.roomSection} >
            {/* If calender start date is equal to checkout date,
                add reservation block before calender start date */}
            {checkOutAtCalenderStart && 
                <ReservationBlock
                    dragEnabled = {false}
                    tooltip = {props.tooltip}
                    colour = {props.colour}
                    bedInfo = {props.bedInfo}
                    guestNameLabel = {props.guestNameLabel}
                    {...checkOutAtCalenderStart}
                    width={100}
                    blockWidth = {(50/calenderInfo.numberOfDays)}
                    leftEdge = {true}
                    rightEdge = {false}
                    updateReservation = {props.updateReservation}
                    handleViewReservation= {props.handleViewReservation}
                    updateStatusHandler= {props.updateStatusHandler}
                    colorConfigInfo = {props.colorConfigInfo}
                />
            }

            {/* If: 1) no reservation spans from date before calender start date to date after calender start date. 
                2) reservation checkout date is not equal to calender start date.
                add space before calender start date 
            */}
            {!checkOutAtCalenderStart && !checkInBeforeCalenderStart &&
                <div id="before_cal" style={{width: `${(50/calenderInfo.numberOfDays)}%`}}></div>
            }

            { calenderInfo.days.map((date, index) =>{
                // If no reservations for a bed add empty blocks
                if(formatedReservationData.length == 0 && index <  (calenderInfo.days.length - 1)){
                    return <CalenderDateBlock 
                        dragId = {dragID}
                        id={`no_reservation_${index}`} 
                        key={`no_reservation${props.bedInfo._id}_${index}`} 
                        width={100/calenderInfo.numberOfDays}
                        bedInfo = {props.bedInfo}
                        date={date}
                        roomName = {props.roomName}
                        bedName = {props.bedName}
                        reservations = {null}
                        triggerErrorHandler={props.triggerErrorHandler}
                    />;
                }

                const htmlView = formatedReservationData.map((reservation, resIndex)=>{
                    // Check if date is equal to checkin date
                    // Or date lies in between checkin and checkout date...
                    const dateInReservation = checkDateInReservation(date, reservation.checkIn, reservation.checkOut);
                    if(dateInReservation){
                        // ReservationBlock is positioned relative to the checkin DateBlock
                        // inorder to update reservation date to a day after or previous to checkin date 
                        if( bookingSpan == 0){
                            bookingSpan = reservation.visibleBookingDuration;
                            if(reservation.preCheckedIn && !reservation.isCalenderStartdateCheckout){
                                return <React.Fragment key={`res_${reservation._id}_${resIndex}`}>
                                    <ReservationBlock 
                                        key={`res_${reservation._id}_${resIndex}`} 
                                        dragId = {dragID}
                                        dragEnabled = {false}
                                        tooltip = {props.tooltip}
                                        colour = {props.colour}
                                        bedInfo = {props.bedInfo}
                                        guestNameLabel = {props.guestNameLabel}
                                        roomName = {props.roomName}
                                        bedName = {props.bedName}
                                        {...reservation}
                                        rightEdge = {checkDateIfGreater(reservation.checkOut, calenderInfo.endDate)}
                                        leftEdge ={true}
                                        width = {getReservationBlockWidth(calenderInfo, reservation) * (2 * calenderInfo.numberOfDays)}
                                        blockWidth = {50/calenderInfo.numberOfDays}
                                        handleConfirmShow = {props.handleConfirmShow}
                                        handleViewReservation= {props.handleViewReservation}
                                        updateStatusHandler= {props.updateStatusHandler}
                                        colorConfigInfo = {props.colorConfigInfo}
                                        updateReservation = {props.updateReservation}
                                    >
                                        {fetchReservationPayStatus(reservation)}
                                    </ReservationBlock>
                                    <CalenderDateBlock 
                                        key={`first_space_${props.bedInfo._id}_${index}_${reservation._id}`}
                                        id={`space_block_${index}`} 
                                        dragId = {dragID}
                                        width={100/calenderInfo.numberOfDays}
                                        bedInfo = {props.bedInfo}
                                        roomName = {props.roomName}
                                        bedName = {props.bedName}
                                        date={date}
                                        reservations={formatedReservationData}
                                        triggerErrorHandler={props.triggerErrorHandler}
                                    />
                                </React.Fragment>;
                            }

                            return (
                                <ReservationBlock 
                                    key={`res_${reservation._id}_${resIndex}`} 
                                    dragId = {dragID}
                                    dragEnabled = {props.dragEnabled}
                                    tooltip = {props.tooltip}
                                    colour = {props.colour}
                                    bedInfo = {props.bedInfo}
                                    guestNameLabel = {props.guestNameLabel}
                                    roomName = {props.roomName}
                                    bedName = {props.bedName}
                                    {...reservation}
                                    rightEdge = {checkDateIfGreater(reservation.checkOut, calenderInfo.endDate)}
                                    leftEdge={false}
                                    width = {getReservationBlockWidth(calenderInfo, reservation) * calenderInfo.numberOfDays}
                                    blockWidth = {100/calenderInfo.numberOfDays}
                                    handleConfirmShow = {props.handleConfirmShow}
                                    handleViewReservation= {props.handleViewReservation}
                                    updateStatusHandler= {props.updateStatusHandler}
                                    colorConfigInfo = {props.colorConfigInfo}
                                    updateReservation = {props.updateReservation}
                                >
                                    {fetchReservationPayStatus(reservation)}
                                </ReservationBlock>
                            );
                        }
                        bookingSpan = bookingSpan - 1;

                        if(bookingSpan > 0 && index <  (calenderInfo.days.length - 1)){
                            return <CalenderDateBlock 
                                key={`second_space_${props.bedInfo._id}_${index}_${reservation._id}`}
                                id={`space_block_${index}`} 
                                dragId = {dragID}
                                width={100/calenderInfo.numberOfDays}
                                bedInfo = {props.bedInfo}
                                roomName = {props.roomName}
                                bedName = {props.bedName}
                                date={date}
                                reservations={formatedReservationData}
                                triggerErrorHandler={props.triggerErrorHandler}
                            />;
                        }

                        return null;
                    }
                });

                // Add space block if no reservation found for the date 
                if(!bookingSpan && index <  (calenderInfo.days.length - 1)){
                    return <CalenderDateBlock 
                        key={`last_space_${props.bedInfo._id}_${index}`}
                        id={`space_block_${index}`} 
                        dragId = {dragID}
                        width={100/calenderInfo.numberOfDays}
                        bedInfo = {props.bedInfo}
                        roomName = {props.roomName}
                        bedName = {props.bedName}
                        date={date}
                        reservations={formatedReservationData}
                        triggerErrorHandler={props.triggerErrorHandler}
                    />;
                }
                return htmlView;
            })}
        </div>
    );
}

export default withStyles(styles)(CalenderRoomReservations);