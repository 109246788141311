import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";

import {
  getPortalSession,
  updateChargebeeSubscription,
  resetError,
  reloadLoadSpaceDetails,
} from "../../redux/actions/subscriptions/subscriptionUpgrade";
import { CHARGEBEE_CB_SITE } from "../../config";

const PORTAL_ACCESS_TYPES = {
  login: "login",
  portalSession: "portal_session",
};

const PORTAL_ACCESS_MODE = PORTAL_ACCESS_TYPES.portalSession;

class ManageSubscription extends Component {
  cbInstance = null;

  componentDidMount() {
    //PORTAL ACCESS VIA PORTAL SESSION.............................
    if (PORTAL_ACCESS_MODE == PORTAL_ACCESS_TYPES.portalSession) {
      const { currentSpace, dispatch } = this.props;
      dispatch(getPortalSession(currentSpace._id)).then((response) => {
        if (response && response.portal_session) {
          this.initChargebee(response.portal_session);
          return;
        }
      });
      return;
    }

    //PORTAL ACCESS VIA LOGIN.............................
    this.initChargebee();
  }

  initChargebee = (portalSession = null) => {
    const dataCbSite = CHARGEBEE_CB_SITE;

    Chargebee.inited
      ? Chargebee.registerAgain()
      : Chargebee.init({ site: dataCbSite });

    this.cbInstance = Chargebee.getInstance();

    if (PORTAL_ACCESS_MODE == PORTAL_ACCESS_TYPES.portalSession) {
      this.cbInstance.setPortalSession(function () {
        return new Promise((resolve, reject) => {
          resolve(portalSession);
        });
      });
    }

    const cbPortal = this.cbInstance.createChargebeePortal();
    cbPortal.open({
      loaded: () => {},
      close: () => {
        this.closePortalHandler();
      },
      subscriptionChanged: (data) => {
        this.subscriptionChangeHandler(data.subscription);
      },
      subscriptionReactivated: (data) => {
        console.log("subscriptionReactivated", data);
      },
      subscriptionCancelled: (data) => {
        console.log("subscriptionCancelled", data);
      },
    });
  };

  subscriptionChangeHandler = (subscription) => {
    const { dispatch, currentSpace } = this.props;
    const payload = { subscriptionID: subscription.id };
    dispatch(updateChargebeeSubscription(currentSpace._id, payload)).then(
      (success) => {
        if (success) dispatch(reloadLoadSpaceDetails(currentSpace._id));
      }
    );
  };

  handleCloseError = () => {
    this.props.dispatch(resetError("GET_PORTAL_SESSION"));
    this.closePortalHandler();
  };

  closePortalHandler = () => {
    this.cbInstance.logout();
    this.props.closeManagePortal();
  };

  render() {
    const { errors } = this.props;
    return (
      <>
        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={this.handleCloseError}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{errors?.message}</span>}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { spaces, dashboard, loading, errors } = state;
  return {
    currentSpace: spaces?.[dashboard?.currentSpace] || {},
    errors: errors.GET_PORTAL_SESSION,
  };
};

export default connect(mapStateToProps)(ManageSubscription);
