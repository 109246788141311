import http from "../../utils/http";
import { normalize } from 'normalizr';

//----------------------------------------------------------------
// ADD NOTE
//----------------------------------------------------------------
export function createBookingSource(payload, spaceID) {
  const ACTION_NAME = "CREATE_BOOKING_SOURCE";
  return function(dispatch) {
    return http.post('/api/frontdesk/bookingSource/', {...payload})
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, spaceID} })
    })
    .catch(errorResult => {
      const error = errorResult.response
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: error.data, code: error.status } });
    });
  }
}

//----------------------------------------------------------------
// ADD NOTE
//----------------------------------------------------------------
export function updateBookingSource(spaceID, body) {
  const ACTION_NAME = "UPDATE_BOOKING_SOURCE";
  return function(dispatch) {
    return http.patch('/api/frontdesk/bookingSource', body)
    .then(successResult => {
      const data = successResult.data;
      // console.log(data);
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, spaceID}})
    })
    .catch(errorResult => {
      const error = errorResult.response
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { error: error.data, code: error.status } });
    });
  }
}

//----------------------------------------------------------------
// GET TRANSACTION MODE
//----------------------------------------------------------------
export function fetchBookingSource(propertyID, spaceID) {
  const ACTION_NAME = "FETCH_BOOKING_SOURCE";
  return function(dispatch) {
    return http.get(`/api/frontdesk/bookingSource/${propertyID}`)
    .then(successResult => {
      const data = successResult.data
      dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: {data, spaceID} })
    })
    .catch(errorResult => {
      console.log(errorResult);
      dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
    });
  }
}