import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import {
  createHostelWorldTax,
  deleteHostelWorldTax,
  getHostelWorldTaxes,
} from "../../../redux/actions/microsite/hostelworld";
import { getCurrency } from "../../../utils/utility";

import { Grid, Dialog, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import TaxForm from "./TaxForm";

import AccessHeaderAction from "../../permissions/AcessHeaderAction";
import { MODULE } from "../../../common/constants/permission";
import IMAGES from "../../../constants/images";
import ConfirmForm from "../../common/ConfirmForm";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#000000",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialog: {
    overflowY: "hidden",
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      maxHeight: "100%",
    },
  },
  dialogTitle: {
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 2px 20px #F0F0F0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    textAlign: "center",
    zIndex: "105",
  },
  iconButton: {
    borderRadius: "50%",
    cursor: "pointer",
    color: "#666666",
    fontWeight: 500,
    left: 24,
    padding: theme.spacing(1),
    position: "absolute",
    top: 24,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  closeIcon: {
    borderRadius: "50%",
    color: "#666666",
    cursor: "pointer",
    padding: theme.spacing(1),
    position: "absolute",
    right: 24,
    transition: "150ms",
    top: 20,
    "&:hover": {
      background: "#dddddd",
      color: "#666666",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      right: "10px",
    },
  },
  listSection: {
    margin: theme.spacing(3),
  },
  firstRow: {
    alignItems: "center",
    borderRadius: 5,
    display: "flex",
    marginTop: 40,
    marginBottom: 40,
  },
  bulb: {
    width: 40,
    height: 40,
  },
  helperText: {
    color: "#999999",
    fontSize: "1.2rem",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  listContainer: {
    height: "calc(100vh - 186px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
  },
  title: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginBottom: 20,
  },
  taxContainer: {
    display: "flex",
    justifyContent: "center",
  },
  main: {
    fontWeight: 600,
    marginBottom: 5,
  },
  subText: {
    fontSize: "1rem",
  },
  bubble: {
    alignItems: "center",
    background: "#E2E2E2",
    borderRadius: 10,
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.3rem",
    flexDirection: "column",
    height: 100,
    justifyContent: "center",
    lineHeight: 1.5,
    marginRight: 20,
    padding: 20,
    textAlign: "center",
    width: 100,
    [theme.breakpoints.down("xs")]: {
      height: 70,
      width: 70,
      padding: 10,
    },
  },
  selected: {
    background: "#4F9581",
    color: "#FFFFFF",
    cursor: "auto",
    fontWeight: 600,
  },
  row: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    padding: 20,
    marginBottom: 20,
  },
  nameContainer: {
    borderBottom: "1px solid #F2F2F2",
    color: "#333333",
    fontSize: "1.5rem",
    fontWeight: 600,
    paddingBottom: 10,
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    color: "#666666",
    fontSize: "1.2rem",
    marginTop: 10,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    borderRadius: "50%",
    height: 20,
    padding: 10,
    color: "#666666",
    cursor: "pointer",
    opacity: 0.6,
    width: 20,
    "&:hover": {
      background: "#EEEEEE",
    },
  },
  name: {
    marginLeft: 20,
  },
}));

const taxTypeOptions = [
  { label: "Service Fee", value: 2 },
  { label: "Country Tax", value: 4 },
  { label: "Resort Tax", value: 3 },
  { label: "City Tax", value: 1 },
  { label: "Sales Tax", value: 0 },
];

const appliesToOptions = [
  { label: "Person per night", value: 4 },
  { label: "Room per night", value: 3 },
  { label: "Person per stay", value: 2 },
  { label: "Room per stay", value: 1 },
  { label: "Total Cost", value: 0 },
];

const TaxList = (props) => {
  const classes = useStyles();

  const { open, closeHandler, errors, currentSpace } = props;
  const [loading, setLoading] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [createTax, setCreateTax] = React.useState(false);
  const [taxList, setTaxList] = React.useState([]);
  const [included, setIncluded] = React.useState(null);
  const [selectedTax, setSelectedTax] = React.useState(null);
  let currencyData = undefined;
  if (currentSpace) {
    //currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);
    currencyData = {
      code: currentSpace.currency,
      country: currentSpace.country,
    };
  }

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      fetchTaxHandler();
    }
  }, [open]);

  const handleRemove = () => {
    if (taxList.length == 0) setIncluded(true);
  };

  const submitHandler = (payload) => {
    setLoading(true);
    const response = props.dispatch(
      createHostelWorldTax(payload, currentSpace.propertyID)
    );
    response
      .then((data) => {
        setLoading(false);
        if (data) setTaxList([...data]);
      })
      .catch((error) => {
        setLoading(false);
      });
    setCreateTax(false);
  };

  const deleteHandler = (taxID) => {
    setLoading(true);
    if (taxID) {
      setSelectedTax(null);
      const response = props.dispatch(
        deleteHostelWorldTax({ taxID }, currentSpace.propertyID)
      );
      response
        .then((data) => {
          setLoading(false);
          if (data) setTaxList([...data]);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const fetchTaxHandler = () => {
    const response = props.dispatch(
      getHostelWorldTaxes(currentSpace.propertyID)
    );
    response
      .then((data) => {
        setLoading(false);
        if (data.length > 0) setIncluded(false);
        if (data) setTaxList([...data]);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const closeErrorHandler = () => {};

  const closeModalHandler = () => {
    if (errors) closeErrorHandler();
    closeHandler();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalHandler}
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          {!createTax && !included && (
            <AddIcon
              className={classes.iconButton}
              onClick={() => setCreateTax(true)}
            />
          )}

          {createTax && (
            <BackIcon
              className={classes.iconButton}
              onClick={() => setCreateTax(false)}
            />
          )}

          <div className={classes.header}>Tax Details</div>
          <AccessHeaderAction moduleID={MODULE.HW_TAXES.ID} />
          <CloseIcon
            className={classes.closeIcon}
            onClick={closeModalHandler}
          />
        </div>

        {!createTax && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              <div className={classes.title}>
                Are all taxes chargeable to the customer included in your room
                rates?
              </div>
              <div className={classes.taxContainer}>
                <div
                  className={clsx({
                    [classes.bubble]: true,
                    [classes.selected]: included == true,
                  })}
                  onClick={handleRemove}
                >
                  <div className={classes.main}>Included</div>
                  <div className={classes.subText}>
                    All taxes are included in the Rate price
                  </div>
                </div>
                <div
                  className={clsx({
                    [classes.bubble]: true,
                    [classes.selected]: included === false,
                  })}
                  onClick={() => setIncluded(false)}
                >
                  <div className={classes.main}>Not Included</div>
                  <div className={classes.subText}>
                    Taxes are charged at the property's front desk
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.listSection}>
              {included == false && (
                <div className={classes.firstRow}>
                  <div>
                    <img src={IMAGES.ICONS.bulb} className={classes.bulb} />
                  </div>
                  <div className={classes.helperText}>
                    Taxes that are not included in Rate are displayed in the
                    Hostelworld.com checkout as additional fees, payable on
                    arrival at the property.
                  </div>
                </div>
              )}

              {/* LIST ALL TAXES */}
              {/* --------------------------------------------- */}
              {taxList.map((tax, index) => (
                <div key={index} className={classes.row}>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={10}>
                      <div>
                        <div className={classes.nameContainer}>
                          <div>
                            {
                              taxTypeOptions.find((e) => e.value == tax.type)
                                .label
                            }
                          </div>
                        </div>

                        <div className={classes.detailsContainer}>
                          <div>
                            {tax.isFixed &&
                              currencyData &&
                              tax.amount &&
                              getCurrency(currencyData, tax.amount)}
                            {!tax.isFixed && `${tax.amount}%`}
                          </div>

                          <div className={classes.name}>
                            Applies to{" "}
                            {
                              appliesToOptions.find(
                                (e) => e.value == tax.appliesTo
                              ).label
                            }
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={2}>
                      <div className={classes.iconContainer}>
                        <img
                          src={IMAGES.ICONS.trash}
                          className={classes.icon}
                          onClick={() => {
                            setSelectedTax(tax.id);
                            setConfirmOpen(true);
                          }}
                        />
                      </div>
                    </Grid>

                    {selectedTax === tax.id && (
                      <Grid item xs={12}>
                        <ConfirmForm
                          title={"Delete tax?"}
                          open={confirmOpen}
                          setOpen={setConfirmOpen}
                          onClose={() => {
                            setConfirmOpen(false);
                            setSelectedTax(null);
                          }}
                          onConfirm={() => deleteHandler(tax.id)}
                        >
                          Are you sure you would like to delete this tax?
                        </ConfirmForm>
                      </Grid>
                    )}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        )}

        {createTax && (
          <div className={classes.listContainer}>
            <div className={classes.listSection}>
              <TaxForm
                submitHandler={submitHandler}
                cancelHandler={() => setCreateTax(false)}
              />
            </div>
          </div>
        )}

        {/* ------------- Snackbar error messages -------------- */}
        {errors && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={closeErrorHandler}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{errors}</span>}
          />
        )}

        {loading && (
          <div className={"loadingOverlay"}>
            <CircularProgress className={"loading"} />
          </div>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentSpace: state.spaces[state.dashboard.currentSpace],
});

export default connect(mapStateToProps)(TaxList);
