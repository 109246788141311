import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

import { returnCurrent } from '../../redux/selectors/dashboard';
import TaxItem from './TaxItem';

import { 
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';

import LayersIcon from '@material-ui/icons/Layers';

import IMAGES from '../../constants/images';
import HelperText from '../common/HelperText';

const useStyles = makeStyles (theme => ({
  root: {
    width: '100%'
  },
  blankState: {
    alignItems: 'center',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
    marginTop: 50,
  },
  row: {
    paddingTop: 5,
    paddingBottom: 5
  },
  icon: {
    height: 20,
    width: 20
  }
}))


const TaxList = (props) => {
  const { t } = props
  const classes = useStyles();
  const [selectedTax, setSelectedTax] = React.useState(null)

  const { taxes, currentSpace } = props;

  const handleClose = () => {
    setSelectedTax(null);
    props.handleClose();
  }

  return(
    <List className={classes.root}>

      {/* ------------------------------------------------ */}
      {/* LEARN - explanation */}
      {/* ------------------------------------------------ */}
      <HelperText text={'beds.taxesSnapshot.taxHelper'} link={'https://support.counter.app/en/articles/3957575-tax-settings'} />

      {/* ------------------------------------------------ */}
      {/* BLANK STATE - No Taxes*/}
      {/* ------------------------------------------------ */}
      {(!currentSpace.taxes || currentSpace.taxes.length ===0) && 
        <div className={classes.blankState}>
          {t('beds.taxesSnapshot.noTaxList')}
        </div>
      }

      {/* LIST ALL TAXES */}
      {/* --------------------------------------------- */}
      {currentSpace.taxes && currentSpace.taxes.map(item => (
        taxes[item] && <ListItem button onClick={() => setSelectedTax(item)} key={item}>
          {taxes[item].type === 'amountPerAccommodation' && <ListItemAvatar><img className={classes.icon} src={IMAGES.ICONS.bed} /></ListItemAvatar>}
          {taxes[item].type === 'amountPerNight' && <ListItemAvatar><img className={classes.icon} src={IMAGES.ICONS.nights} /></ListItemAvatar>}
          {taxes[item].type === 'amountPerPerson' && <ListItemAvatar><img className={classes.icon} src={IMAGES.ICONS.guest} /></ListItemAvatar>}
          {taxes[item].type === 'percentOfTotal' && <ListItemAvatar><img className={classes.icon} src={IMAGES.ICONS.discount} /></ListItemAvatar>}
          {taxes[item].type === 'rateSlabs' && <ListItemAvatar><LayersIcon /></ListItemAvatar>}
          <ListItemText primary={taxes[item].name} secondary={`Code: ${taxes[item].code}`}/>
        </ListItem>
      ))}

      {selectedTax && 
        <TaxItem 
          taxID={selectedTax} 
          handleBack={() => setSelectedTax(null)}
          handleClose={handleClose}
        />
      }

    </List>
  )
}

const mapStateToProps = state => ({
  currentSpace: returnCurrent(state.spaces, state.dashboard.currentSpace),
  taxes: state.taxes
})

export default withTranslation()(connect(mapStateToProps)(TaxList));