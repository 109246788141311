import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ParticipantInfoCard from './PaticipantInfoCard';
import { getCurrency } from '../../../utils/utility';
import { toTitleCase } from '../../utils/wordUtils';

import Grid from '@material-ui/core/Grid';

import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0 3px 4px 0 #ECECEC',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    mainContainer: {
        boxSizing: 'content-box',
        height: 'calc(100% - 40px)',
        overflow: 'hidden'
    },
    scrollableContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingRight: '17px'
    },
    imageContainer: {
        height: '100px',
        width: '100%',
        position: 'relative',
        backgroundColor: '#f2f4f8',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        '& img':{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '200px',
        },
    },
    amountTag:{
        color: '#fff',
        padding: '8px 16px',
        position: 'absolute',
        fontSize: '1.2rem',
        fontWeight: 600,
        backgroundColor: '#000',
        top: '200px',
        right: '20px',
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
    },  
    activityDetailsContainer:{
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
    },
    activityDetails: {
        height: 80,
        padding: 20,
        paddingTop: 40,
        paddingBottom: 30,
        '& .location':{
            color: '#00000',
            fontSize: '1.1rem',
            display: 'flex',
            alignItems: 'center',
        },
        '& .location img':{
            width: '14px',
            height: '14px',
            opacity: 1,
            marginRight: '5px'
        },
        '& .mainTitle':{
            fontSize: '2.4rem',
            fontWeight: 600,
            marginBottom: '10px',
            marginTop: 10,
        },
        '& .time':{
            fontSize: '1.3rem',
            fontWeight: 600,
            color: '#bdbdbd',
        }
    },
    instructionContainer: {
        height: 80,
    },
    instructions:{
        alignItems: 'center',
        display: 'flex',
        padding: '0 20px',
        '& .icon':{ 
            display: 'flex',
            alignItems: 'center',
        },
        '& .icon img':{
            width: '18px',
            height: '18px',
        },
        '& .textDesc':{
            fontSize: '1rem',
            color: '#000000',
            display: 'flex',
            paddingLeft: '10px',
        },
        '& .title': {
            marginRight: 5
        }
    },
    participantContainer:{
        marginTop: 20,
        height: 'calc(100% - 280px)',
        '& .title':{
            color: '#333333',
            fontSize: '1.2rem',
            fontWeight: 600,
            height: 20,
            margin: '5px 20px 15px 20px'
        }
    },
    participantListContainer:{
        width: '100%',
        height: 'calc(100% - 40px)',
        overflow: 'hidden',
        marginTop: 10,
    },
    participantList:{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        paddingRight: '17px', 
        boxSizing: 'content-box'
    },
    slotButton:{
        alignItems: 'center',
        backgroundColor: '#CC6633',
        borderRadius: '5px',
        color: '#fff',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.3rem',
        fontWeight: 600,
        height: 50,
        justifyContent: 'center',
        width: '100%',
        zIndex: 2,
    },
    sold: {
        backgroundColor: 'rgb(227, 227, 227)'
    }
}));    

const InstructionItem = props =>{
    const classes = useStyles();
    const { iconUrl, title, titleInfo } = props;
    return(
        <div className={classes.instructions}>
            <div className="icon">
                <img src={iconUrl} />
            </div>
            <div className="textDesc">
                <div className="title">{title}</div>
                {titleInfo && <div>{titleInfo}</div>}
            </div>
        </div>
    )
}

const ActivitySummary = props =>{
    const { productDetails = {}, productInfo, conductorInfo, openBookingMask, openNewBooking, t } = props;
    const { 
        imageUrl,
        productID,
        productName,
        locations,
        duration,
        conductorID,
        participants,
        available,
        currencyData,
        price
    } = productDetails;
    const classes = useStyles();
    const productData =  productInfo && productInfo[productID] ? productInfo[productID] : {};
    const conductorData = conductorInfo && conductorInfo[conductorID] ? conductorInfo[conductorID] : {};
    
    return(
        <>
            {productID && (
                <div className={classes.root}>
                    <div className={classes.mainContainer}> 
                        <div className={classes.scrollableContainer}>
                            <div className={classes.imageContainer}>
                                {imageUrl && <img src={imageUrl} alt="Activity" />}
                                {price!==0 && <div className={classes.amountTag}>{getCurrency(currencyData, price)} {t('experiences.calendar.perPerson')}</div>}
                                {price===0 && <div className={classes.amountTag}>{t('experiences.calendar.free')}</div>}
                            </div>
                            <div className={classes.activityDetailsContainer}>
                                <div className={classes.activityDetails}>
                                    {productData.locations && productData.locations.length > 0 && <div className="location">
                                        <img src={IMAGES.ICONS.location}/> {productData.locations.join(', ')}
                                    </div>}
                                    <div className="mainTitle">{productName}</div>
                                    <div className="time">{duration}</div>
                                </div>

                                <Grid className={classes.instructionContainer} container spacing={2}>
                                    {productData.minSize && <Grid item xs={6}>
                                        <InstructionItem
                                            iconUrl = {IMAGES.ICONS.experienceMin}
                                            title={t('experiences.calendar.minSize')}
                                            titleInfo={productData.minSize}
                                        />
                                    </Grid>}
                                    {productData.maxSize && <Grid item xs={6}>
                                        <InstructionItem
                                            iconUrl ={IMAGES.ICONS.experienceMax}
                                            title={t('experiences.calendar.maxSize')}
                                            titleInfo={productData.maxSize}
                                        />
                                    </Grid>}
                                    {conductorData.type && <Grid item xs={6}>
                                        <InstructionItem
                                            iconUrl ={IMAGES.ICONS.experienceInstructor}
                                            title={toTitleCase(conductorData.type)}
                                            titleInfo={toTitleCase(conductorData.name)}
                                        />
                                    </Grid>}
                                    <Grid item xs={6}>
                                        <InstructionItem
                                            iconUrl ={IMAGES.ICONS.experienceLocation}
                                            title={productData.atProperty ? t('experiences.calendar.inHouse') : t('experiences.calendar.External')}
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.participantContainer}>
                                    <div className="title">{t('experiences.calendar.participants')}</div>

                                    <div className={classes.participantListContainer}>
                                        <div className={classes.participantList}>
                                            {participants.map(({
                                                _id,
                                                contact = {},
                                                reservationID,
                                                bookingSource = '',
                                                balance,
                                                products
                                            }) =>(
                                                <ParticipantInfoCard 
                                                    key={_id}
                                                    bookingID={_id}
                                                    reservationID={reservationID}
                                                    country={contact.nationality || 'NA'}
                                                    guestType={reservationID  ? t('experiences.calendar.inHouseGuest') : t('experiences.calendar.externalGuest') }
                                                    bookingSource={bookingSource}
                                                    guestName={`${contact.firstName} ${contact.lastName}`}
                                                    isPaymentPending={balance && balance > 0 ? true : false}
                                                    openBookingMask={openBookingMask}
                                                    count={products.quantity}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {available ?
                        <div className={classes.slotButton} onClick={openNewBooking}>{t('experiences.calendar.addParticipant')}</div>
                        :
                        <div className={clsx(classes.slotButton, classes.sold)}>{t('experiences.calendar.soldOut')}</div>}

                </div>
            )}
        </>
    )
}

const mapStateToProps = state =>{
    const { experiences } = state;
    return{
        productList: experiences.displayProductList,
        productInfo: experiences.activityList,
        conductorInfo: experiences.conductorList,
        productDetails: experiences.displayProductDetails
    }
}

export default withTranslation()(connect(mapStateToProps)(ActivitySummary));