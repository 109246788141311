import React from "react";
import { connect } from 'react-redux';
import { makeStyles, CircularProgress, Box } from "@material-ui/core";
import clsx from 'clsx';

import { Button, Typography } from '@material-ui/core';
import { fetchCurrencyFormat, fetchDateFormat, getAmtFormat } from "../../../utils/utility";
// import { ICONS } from "../../../utils/imageUrls";

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#f8f9fb',
    marginRight: 10,
    marginTop: 10,
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  summaryContainer: {
    borderRadius: 2,
    fontSize: "1.4rem",
    lineHeight: "1.5",
    marginBottom: 20,
    overflowY: 'hidden',
    paddingLeft: 10,
    paddingRight: 10
  },
  primary: {
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: '10px',
    color: '#000'
  },
  secondaryText: {
    color: '#b3b3b3',
    fontSize: '1.1rem',
  },
  summaryCard: {
    padding: '20px',
    border: '5px',
    backgroundColor: '#fff',
    marginBottom: '20px'
  },
  amountText: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  inlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentButton: {
    background: '#d96977',
    color: '#ffffff',
    padding: 15,
    width: '100%',
    fontSize: '1.6rem',
    fontWeight: 600,
    '&:hover': {
      background: '#d96977',
      color: '#ffffff',
    },
    '&:disabled': {
      color: '#ffffff',
    }
  },
  paymentContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    marginBottom: 20,
    '& > button': {
      width: 'calc(100% - 20px)',
    }
  },
  tableWrapper: {
    overflow: "auto",
    width: "100%"
  },
  secodaryText: {
    color: '#262827',
    fontSize: '1.1rem',
    fontWeight: 600,
    "&.spanText": {
      display: "block",
      fontWeight: 500
    }
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 20
  },
  iconSection: {
    width: '50%'
  },
  summary: {
    padding: '0 10px',
    width: 'calc(100% - 20px)',
  },
  summaryLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 0,
    '& p': {
      fontSize: '1.4rem',
    }
  },
  taxTotal: {
    color: '#bdbdbd',
  },
  subTotal: {
    marginTop: 5,
    '& div': {
      color: '#262827',
      fontWeight: 600,
    }
  },
  balance: {
    color: '#d96977',
    marginTop: 20,
    '& div': {
      fontWeight: 600,
      fontSize: '1.6rem',
    }
  },
  aux: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 10,
  },
  auxText: {
    color: '#cccccc',
    fontSize: '1.1rem',
  },
  auxIcon: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    '& img': {
      height: 25,
      marginBottom: 10,
      opacity: 0.3,
      width: 25,
    },
    '&:hover': {
      '& img': {
        opacity: 1
      },
      '& p': {
        color: '#333333'
      }
    },
  },
  header: {
    paddingTop: 30,
  },
  headerExtra: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '15px 0',
    '&>div': {
      display: 'flex'
    }
  },
}));


const SummaryTab = props => {
  const classes = useStyles();
  const { currentSpace, booking, productInfo } = props;
  const currency = fetchCurrencyFormat(currentSpace.currency ? currentSpace.currency : null);

  return (
    <div className={classes.container}>

      <div className={classes.summaryContainer}>
        {/* --------------------------------------------------------------- */}
        {/* TITLE */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.header}>
          <Typography variant="h3" gutterBottom>
            Summary
        </Typography>
        </div>
        {/* --------------------------------------------------------------- */}
        {/* Product List */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.tableWrapper}>
          {booking?.products?.map(({
            _id,
            productID,
            gradeID,
            date,
            quantity,
            price
          }) => (
              <div key={_id} className={classes.summaryCard}>
                <div className={classes.primary}>
                  {productInfo?.[productID]?.grades?.[gradeID]?.title || productInfo?.[productID]?.title}
                </div>
                <div className={classes.secondaryText}>
                  {fetchDateFormat(date, 'D MMM YYYY')}
                </div>
                <div className={classes.inlineContainer}>
                  <div className={classes.secondaryText}>
                    {fetchCurrencyFormat(price.currency)}{price.raw} per person ({quantity})
                  </div>
                  <div className={classes.amountText}>
                    {fetchCurrencyFormat(price.currency)}{(price.raw * quantity)}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* --------------------------------------------------------------- */}
        {/* Summary Subtotal */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.summaryLine, classes.subTotal)}>
            <div>Total Amount</div>
            <div>{`${currency}${getAmtFormat(booking.total) || "0"}`}</div>
          </div>
        </div>


        {/* --------------------------------------------------------------- */}
        {/* Transaction list */}
        {/* --------------------------------------------------------------- */}


        {/* --------------------------------------------------------------- */}
        {/* Balance */}
        {/* --------------------------------------------------------------- */}
        <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.summaryLine, classes.balance)}>
            <div className={classes.themeText}>
              Balance
            </div>
            <div className={classes.themeText}>
              {`${currency}${getAmtFormat(booking.balance) || "0"}`}
            </div>
          </div>
        </div>

        {/* --------------------------------------------------------------- */}
        {/* FORM */}
        {/* --------------------------------------------------------------- */}
        <div className={classes.payment}>

        </div>
        {/* <div className={classes.paymentContainer}>
          <Button className={classes.paymentButton} >Collect Payment</Button>
        </div> */}

        {/* --------------------------------------------------------------- */}
        {/* DISCOUNT VOUCHER INVOICE TAX */}
        {/* --------------------------------------------------------------- */}
        {/* <div className={clsx(classes.tableWrapper, classes.summary)}>
          <div className={clsx(classes.aux)}>
            <div className={classes.auxIcon} >
              <img title="Invoice" src='/images/icons/printer.png' />
              <Typography className={classes.auxText} variant="body2">Invoice</Typography>
            </div>
            <div className={classes.auxIcon} >
              <img title="Discount" src='/images/icons/discount.png' />
              <Typography className={classes.auxText} variant="body2">Discount</Typography>
            </div>
            <div className={classes.auxIcon} >
              <img title="Tax" src='/images/icons/tax.svg' />
              <Typography className={classes.auxText} variant="body2">Tax</Typography>
            </div>
          </div>
        </div> */}


      </div>

      {props.loading &&
        <div className={"loadingOverlay"}>
          <CircularProgress className={"loading"} />
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: false,
    currentSpace: state.spaces[state.dashboard.currentSpace],
    productInfo: state.experiences?.activityList,
  }
}

export default connect(mapStateToProps)(SummaryTab);