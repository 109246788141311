import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';

import TimePicker from '../../common/TimePicker';
import SlotRepeatDays from './SlotRepeatDays';
import ConductorForm from './ConductorForm';
import SlotUpdateConfirm from './SlotUpdateConfirm';

import { addSlot, updateSlot, addGrade, fetchProductList } from '../../../redux/actions/experiences/activities';
import { updateCalendarSlot, fetchRatesAvailability } from '../../../redux/actions/experiences/ratesAvailability';

const styles = theme =>({
    root: {
        margin: '0px 30px',
        [theme.breakpoints.down('xs')]:{
            margin: '0px 15px',
        }
    },
    label: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginBottom: 5,
        width: '100%',
        padding: '5px'
    },
    selectFormControl: {
        // width: '100%',
        padding: '0px 5px',
        boxSizing: 'border-box',
        marginBottom: '20px'
    },
    selectInputContainer:{
        display: 'flex',

        '& $selectInput':{
            borderRadius: '0px',
        },
        
        '& .adornment':{
            border: '1px solid #d8dcdd',
            padding: '0px 10px',
            backgroundColor: '#ebeff0',
            color: '#19232d',
            fontSize: '1.2rem',
            fontWeight: 400,
            lineHeight: '35px',
            zIndex: 1,  
            minWidth: '30px',
            textAlign: 'center'
        },
        '& .adornment.start':{
            marginRight: '-1px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
        },
        '& .adornment.end':{
            marginLeft: '-2px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
        },

        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    selectInput: {
        '& .MuiSelect-root':{
            padding: '10px',
            paddingRight: '40px',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
            borderColor: '#d8dcdd'
        }
    },


    dateTimeContainer:{
        display: 'flex',
        flexWrap: 'wrap',
        padding:' 0px 5px',
        marginBottom: '20px',
        '& .timePicker':{
            marginLeft: '10px',
            [theme.breakpoints.down('xs')]:{
                marginLeft: '0px',
                width: '100%'
            }
        },
        '& $selectInputContainer':{
            marginBottom: '10px'
        }
    
    },
    datePicker:{
        border: '1px solid #d8dcdd',
        minWidth: '160px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        '& .SingleDatePicker_picker':{
            zIndex: 2
        },
        '& .DateInput_input__disabled':{
            backgroundColor: '#fff',
            fontStyle: 'unset'
        },
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },

    checkbox:{
        marginLeft: '0px',
        marginRight: '0px',
        width: 'max-content',
        marginBottom: '20px',
    },

    formControl:{
        marginBottom: '20px',
    },
    textInput: {
        height: '40px',
        backgroundColor: '#f8f9fb',
        borderRadius: '5px',
        padding: '0px 10px',
        '&.Mui-error':{
            border: '1px solid #f44336'
        },
        '&.title':{
            minWidth: '430px'
        }
    },
    staticTextInput:{
        height: '40px',
        display: 'flex',
        padding: '0px 10px',
        borderRadius: '5px',
        backgroundColor: '#f8f9fb',
        alignItems: 'center',
        width: '170px',
        marginBottom: '20px',
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        padding: '0px 5px',
        '& .button':{
            [theme.breakpoints.down("xs")]:{
                width: '100%'
            }
        }
    },

    selectConductor:{
        display: 'flex',
        marginBottom: '10px',
        '& $selectFormControl':{
            marginBottom: '0px'
        },
        '& $selectInput':{
            minWidth: '170px'
        }
    },

    addConductorBtn:{
        fontSize: '1.3rem',
        fontWeight: 600,
        cursor: 'pointer',
        padding: '5px',
        textDecoration: 'underline'
    },

    errorMessage: {
        color: "#f44336"
    },
});

const repeatTypes = {
    off: { id: 'off', title: 'Do not repeat', maxDuration: 0 },
    days: { id: 'days', title: 'Repeat daily', maxDuration: 31 },
    // months: { id: 'months', title: 'Repeat monthly', maxDuration: 12 },
    weeks: { id:'weeks', title: 'Repeat weekly', maxDuration: 31 },
    // years: { id:'years', title: 'Repeat yearly', maxDuration: 31 },
};

const defaultFormData = {
    start: {
        date: null,
        time: null
    },
    end: {
        date: null,
        time: null
    },
    allDay: false,
    repeat: {
        type: repeatTypes.days.id,
        interval: 1,
        until: null,
        dayOfWeek: []
    },
    customPrice: '',
    customAvailable: '',
    capacity: '',
    conductorID: ''
}

const formHelper = {
    customPriceError: false,
    customAvailableError: false,
    titleError: false,
    capacityError: false
}

const uiState = {
    startDateFocus: false,
    endDateFocus: false,
    repeatUntilFocus: false,
    updateSuccess: false,
    conductorForm: {
        id: null,
        show: false
    },
    errorData:{
        status: false,
        message: ''
    },
    showConfirm: false,
    showRepeatSection: true, 
}

class ActivitySlotForm extends Component {
    state = {
        ...defaultFormData,
        ...formHelper,
        ...uiState
    }

    componentDidMount(){

        // This component handles 
        // 1) Slot ADD/EDIT, 
        // 2) rates and availablity slot edit
        // 3) Add Grades
        //forms....

        const { productList, productID, gradeID, slotID,  availabilityID, conductorID } = this.props;
        
        //For rates and availability edit ....
        if(availabilityID){
            
            //Repeat section hidden for rates and availablity edit......... 
            this.setState({ showRepeatSection: false });

            const { allDay, available,  endTime, price, startTime, seletedDate, capacity } = this.props;
            const productData = { ...productList[productID] };
            const gradeData = { ...productData.grades[gradeID] };
            const slotData = { ...gradeData.slots[slotID] };
            
            // Converting slot start and end date immutable objects........
            slotData.start = { date: seletedDate, time: startTime };
            slotData.end = { date: seletedDate, time: endTime };

            slotData.start.time = moment(`${slotData.start.date} ${slotData.start.time}`);
            slotData.end.time = moment(`${slotData.end.date} ${slotData.end.time}`);
            // slotData.repeat.until = moment(slotData.repeat.until);

            slotData.start.date = moment(slotData.start.date);
            slotData.end.date = moment(slotData.start.date);
            
            slotData.customPrice = price;
            slotData.customAvailable = available;
            slotData.capacity = capacity;
            slotData.allDay = allDay;
            slotData.conductorID = conductorID;

            this.initFormData(slotData);

            return;
        }

        //If form in edit mode, set form fields....
        if(slotID){

            //Repeat section hidden for slot edit......... 
            this.setState({ showRepeatSection: false });

            const productData = { ...productList[productID] };
            const gradeData = { ...productData.grades[gradeID] };
            const slotData = { ...gradeData.slots[slotID] };
            
            // Converting slot start and end date immutable objects........
            slotData.start = { ...slotData.start };
            slotData.end = { ...slotData.end };

            slotData.start.time = moment(`${slotData.start.date} ${slotData.start.time}`);
            slotData.end.time = moment(`${slotData.end.date} ${slotData.end.time}`);
            slotData.repeat.until = moment(slotData.repeat.until);

            slotData.start.date = moment(slotData.start.date);
            slotData.end.date = moment(slotData.start.date);

            this.initFormData(slotData);
        }
    }

    initFormData = slotData =>{
        const formFields = Object.keys(defaultFormData);
        // Filtering form fields from product data........ 
        const filteredSlotData = Object.keys(slotData)
        .filter(key => formFields.includes(key))
        .reduce((obj, key) => (obj[key] = slotData[key], obj), {});
        this.setState({ ...filteredSlotData });
    }

    handleStartDate = date =>{
        const { start, end } = this.state;
        const endTime = start.time ? moment(start.time).add(1, 'hour') : null;

        if(!endTime){
            this.setState({
                start: { ...start, date },
                end: { ...end, date }
            });
            return;
        }

        this.setState({
            start: { ...start, date },
            end: { date, time: endTime }
        });
    }

    handleStartTime = time => {
        const { start } = this.state;
        const endTime = moment(time).add(1, 'hour');
        this.setState({ 
            start: { ...start, time },
            end: { date: start.date, time: endTime }
        });
    }

    checkboxHandler = event =>{
        const name = event.target.name;
        const isChecked = event.target.checked;
        this.setState({ [ name ]: isChecked });
    }

    onChangeHandler = event =>{
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [ name ]: value });
    }

    dependantInputHandler = event =>{ 
        const type = event.target.getAttribute('data-type');
        const name = event.target.getAttribute('data-attr-name');
        const value = event.target.value.trim();
        this.setState({
            [ type ]: { ...this.state[type], [ name ]: value }
        });
    }

    selectDayHandler = (type, value) =>{
        const { repeat } = this.state;
        let selectedWeekDays = new Set([ ...repeat.dayOfWeek ]);
        
        switch(type){
            case "add": 
                selectedWeekDays.add(value);
                break;
            
            case "delete":
                selectedWeekDays.delete(value);
                break;
        }

        this.setState({ 
            repeat: { ...repeat, dayOfWeek: [ ...selectedWeekDays ] }
        });
    }

    editConductorDetails = () =>{
        const { conductorID } = this.state;
        const data = { id: conductorID, show: true };

        this.setState({
            conductorForm: { ...data }
        });
    }

    addNewConductor = () =>{
        const data = { id: null, show: true };
        this.setState({
            conductorForm: { ...data }
        });
    }

    hideConductorForm = () =>{
        const data = { id: null, show: false } 
        this.setState({
            conductorForm: { ...data }
        });
    }

    validateForm = () =>{
        const { 
            start, 
            end, 
            repeat, 
            customPrice, 
            customAvailable,
            title,
            availabilityID,
            capacity
        } = this.state;

        const { gradeID, productID, productList, t } = this.props;
        const maxAvailability = productList[productID].maxSize;

        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        const formattedCustomAvailable = parseInt(customAvailable);
        const formattedCapacity = parseInt(capacity);

        if(!parseInt(customPrice)){
            formInputStatus.customPriceError = true;
            validationSuccess = false;
        }

        if(!formattedCustomAvailable){
            formInputStatus.customAvailableError = true;
            validationSuccess = false;
        }

        if(!formattedCapacity){
            formInputStatus.capacityError = true;
            validationSuccess = false;
        }

        //For grade title validation on create grades....
        if(!gradeID && !title){
            formInputStatus.titleError = true;
            validationSuccess = false;
        }

        if(!start.date || !start.time){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.startTime')
                }
            });
            return false;
        }

        if(!end.date || !end.time){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.endTime')
                }
            });
            return false;
        }

        if(repeat.type !== repeatTypes.off.id && !availabilityID && !repeat.until){
            this.setState({
                errorData:{
                    status: true,
                    message: t('experiences.manage.activity.error.repeatUntil')
                }
            });
            return false;
        }

        // if(formattedCustomAvailable && (formattedCustomAvailable > formattedMaxAvailability)){

        //     const errorMessage = formattedMaxAvailability > 1 ? 
        //         `${t('experiences.manage.activity.error.availability')} ${formattedMaxAvailability}` : 
        //         `${t('experiences.manage.activity.error.availabilityEqual')} ${formattedMaxAvailability}`;

        //     this.setState({
        //         errorData:{
        //             status: true,
        //             message: errorMessage
        //         }
        //     });
        //     return false;
        // }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    filterRequestData = data =>{
        const requestData = { ...data };
        
        Object.keys(uiState).forEach(key=>{
            delete requestData[key];
        });

        if(!requestData.conductorID){
            delete requestData.conductorID;
        }

        Object.keys(formHelper).forEach(key =>{
            delete requestData[key];
        });

        return { ...requestData };
    }

    getRequestData = () =>{
        const { currentSpace, productID, gradeID } = this.props;
        const { start, end, repeat } = this.state;

        const startDate = moment(start.date).format('YYYY-MM-DD');
        const endDate = moment(end.date).format('YYYY-MM-DD');

        const startTime = moment(start.time).format('HH:mm');
        const endTime = moment(end.time).format('HH:mm');

        const repeatUntil  = moment(repeat.until).format('YYYY-MM-DD');

        const data = { 
            ...this.state, 
            currency: currentSpace.currency,
            propertyID: currentSpace.propertyID,
            productID,
            gradeID,
            start: {
                date: startDate,
                time: startTime
            },
            end: {
                date: endDate,
                time: endTime
            },
            repeat: { 
                ...repeat,
                until: repeatUntil 
            }
        }
        
        // Deleting only ui required states from request object... 
        const requestData = this.filterRequestData(data);

        return { ...requestData };
    }

    editCalendarSlotHandler = applyTo =>{
        const { 
            dispatch, 
            slotID, 
            availabilityID, 
            priceID, 
            currentSpace,
            dateRangeObj 
        } = this.props;

        const propertyID = currentSpace.propertyID;

        // Validating form inputs..............
        if(!this.validateForm()) return;

        // Get payload data....
        const requestData = this.getRequestData();

        delete requestData.start.date;
        delete requestData.end.date;

        requestData.availabilityID = availabilityID;
        requestData.priceID = priceID;
        
        const response = dispatch(updateCalendarSlot(applyTo, slotID, requestData));
        response.then(success =>{
            if(success) {
                // Fetching updated slots data.....................
                if(applyTo == 'all') dispatch(fetchProductList(propertyID));
                
                // Refreshing the rates and availability calendar data.....
                const requestObj = { propertyID, ...dateRangeObj };
                dispatch(fetchRatesAvailability(requestObj));

                this.successHandler();
            }
        });
    }

    addGradesHandler = () =>{
        const { dispatch, productID, currentSpace } = this.props;
        // Validating form inputs..............
        if(!this.validateForm()) return;

        // Get payload data....
        const requestData = this.getRequestData();
        requestData.propertyID = currentSpace.propertyID,
        requestData.productID = productID;
        
        const response = dispatch(addGrade(currentSpace.propertyID, requestData));
        response.then(success =>{
            if(success) this.successHandler();
        });
    }

    editSlotHandler = () =>{
        const { dispatch, slotID } = this.props;
        
        // Validating form inputs..............
        if(!this.validateForm()) return;

        // Get payload data....
        const requestData = this.getRequestData();
        
        requestData._id = slotID;

        delete requestData.start.date;
        delete requestData.end.date;

        const response = dispatch(updateSlot(requestData));
        response.then(success =>{
            if(success) this.successHandler();
        });
    }

    submitHandler = () =>{
        const { dispatch, availabilityID, slotID, gradeID } = this.props;

        // Handle grades creation............
        if(!gradeID){
            this.addGradesHandler();
            return;
        }

        // Show confirm options for update through rates and availability calendar................
        if(availabilityID){ 
            this.toggleConfirmDisplay();
            return;
        }

        // Slot edit api call......
        if(slotID){
            this.editSlotHandler();
            return;
        }

        // Validating form inputs..............
        if(!this.validateForm()) return;

        // Get payload data....
        const requestData = this.getRequestData();
        
        const response = dispatch(addSlot(requestData));
        response.then(success =>{
            if(success) this.successHandler();
        });
    }

    successHandler = () =>{
        const { closeModalHandler } = this.props;
        this.setState({ updateSuccess: true });
        setTimeout(()=> closeModalHandler(), 2000);
    }

    toggleConfirmDisplay = () =>{
        this.setState(prevState =>({
            showConfirm: !prevState.showConfirm
        }));
    }

    handleCloseSnackBar = () =>{
        this.setState({
            updateSuccess: false,
            errorData: {
                status: false,
                message: ''
            }
        });
    }

    getUpdateSuccessMsg = () =>{
        const { availabilityID, slotID, gradeID, t } = this.props;
        if(!gradeID) return t('experiences.manage.activity.success.variation');
        if(availabilityID || slotID) return t('experiences.manage.activity.success.slotUpdate');
        return t('experiences.manage.activity.success.slotAdd');
    }

    render(){
        const { 
            classes, 
            conductorList, 
            availabilityID, 
            seletedDate, 
            gradeID, 
            currentSpace,
            t
        } = this.props;

        const { state } = this;

        const PropertyTimezone = currentSpace.timezone;

        return(
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                         {/* -----------------GRADE TITLE----------------- */}
                        {!gradeID && (
                            <>
                                <div className={classes.label}>{t('experiences.manage.activity.title')}*</div>
                                <FormControl className={classes.formControl}>
                                    <InputBase 
                                        id="grade_title"
                                        name="title"
                                        type="text"
                                        className={clsx(classes.textInput,'title')}
                                        fullWidth={true}
                                        value={state.title || ''}
                                        onChange={this.onChangeHandler}
                                        error={state.titleError}
                                    />
                                    {state.titleError && (
                                        <FormHelperText className={classes.errorMessage}>{t('experiences.manage.activity.error.title')}</FormHelperText>
                                    )}
                                </FormControl>
                            </>
                        )}

                        <div className={classes.label}>{t('experiences.manage.activity.nextAvailable')}*</div>

                        {/* -----------------SLOT START DATETIME----------------- */}
                        <div className={classes.dateTimeContainer}>
                            <div className={classes.selectInputContainer}>
                                <div className="adornment start">{t('experiences.manage.activity.from')}</div>
                                <div className={classes.datePicker}>
                                    <SingleDatePicker
                                        date={state.start.date}
                                        isOutsideRange={function noRefCheck() { }}
                                        id="start_date"
                                        onDateChange={this.handleStartDate}
                                        onFocusChange={({ focused }) => this.setState({ startDateFocus: focused })}
                                        focused={state.startDateFocus}
                                        noBorder={true}
                                        numberOfMonths={1}
                                        hideKeyboardShortcutsPanel
                                        displayFormat={'DD MMM YYYY'}
                                        disabled={Boolean(seletedDate) || !state.showRepeatSection} // Datepicker disabled for edit slot and edit solt for rates and availability
                                    />
                                </div>
                            </div>

                            {!state.allDay && (
                                <TimePicker 
                                    value={state.start.time} 
                                    onChange={this.handleStartTime}
                                />
                            )}
                        </div>

                        {/* -----------------SLOT END DATETIME----------------- */}
                        <div className={classes.dateTimeContainer}>
                            <div className={classes.selectInputContainer}>
                                <div className="adornment start">{t('experiences.manage.activity.to')}</div>
                                <div className={classes.datePicker}>
                                    <SingleDatePicker
                                        date={state.end.date}
                                        isOutsideRange={function noRefCheck() { }}
                                        id="end_date"
                                        onDateChange={date => this.setState({
                                            end: { ...state.end, date }
                                        })}
                                        onFocusChange={({ focused }) => this.setState({ endDateFocus: focused })}
                                        focused={state.endDateFocus}
                                        noBorder={true}
                                        numberOfMonths={1}
                                        hideKeyboardShortcutsPanel
                                        displayFormat={'DD MMM YYYY'}
                                        disabled={ Boolean(seletedDate) || !state.showRepeatSection } // Datepicker disabled for edit slot and edit solt for rates and availability
                                    />
                                </div>
                            </div>

                            {!state.allDay && (
                                <TimePicker 
                                    value={state.end.time} 
                                    onChange={time =>this.setState({ 
                                        end: { ...state.end, time }
                                    })}
                                />
                            )}
                        </div>
                        
                        {/* -----------------ALL DAY CHECK------------------- */}
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                            <Checkbox
                                id="all_day"
                                name="allDay"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={state.allDay}
                                onChange={this.checkboxHandler}
                            />
                            }
                            label={t('experiences.manage.activity.allDay')}
                        />
                        
                        {state.showRepeatSection && (
                            <>
                                {/* --------------SLOT REPEAT------------- */}
                                <div className={classes.label}>{t('experiences.manage.activity.repeat')}</div>

                                {/* ----------------------REAPEAT TYPE--------------------------- */}
                                <FormControl variant="outlined" className={classes.selectFormControl}>
                                    <Select
                                        native
                                        value={state.repeat.type}
                                        onChange={this.dependantInputHandler}
                                        className={classes.selectInput}
                                        inputProps={{
                                            'data-type': 'repeat',
                                            'data-attr-name': 'type',
                                            id: 'repeat_type',
                                        }}
                                    >
                                        {Object.keys(repeatTypes).map(type =>(
                                            <option value={repeatTypes[type].id} key={`repeatType_${repeatTypes[type].id}`}>
                                                {repeatTypes[type].title}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>


                                {state.repeat.type !== repeatTypes.off.id && (
                                    <>
                                        {/* ---------------------REAPEAT DURATION------------------------- */}
                                        <FormControl variant="outlined" className={classes.selectFormControl}>
                                            <div className={classes.selectInputContainer}>
                                                <div className="adornment start">{t('experiences.manage.activity.every')}</div>
                                                <Select
                                                    native
                                                    value={state.repeat.interval}
                                                    onChange={this.dependantInputHandler}
                                                    className={classes.selectInput}
                                                    inputProps={{
                                                        'data-type': 'repeat',
                                                        'data-attr-name': 'interval',
                                                        id: 'repeat_interval',
                                                    }}
                                                >
                                                    {Array(repeatTypes[state.repeat.type].maxDuration)
                                                    .fill()
                                                    .map((_, i) =>(
                                                        <option value={i+1} key={`duration_${i}`}>{i+1}</option>
                                                    ))}
                                                </Select>
                                                <div className="adornment end">
                                                    {repeatTypes[state.repeat.type].id}
                                                </div>
                                            </div>
                                        </FormControl>
                                        
                                        {/* ------------------------------REAPEAT UNTIL-------------------------- */}
                                        <div className={clsx(classes.selectFormControl, classes.selectInputContainer)}>
                                            <div className="adornment start">{t('experiences.manage.activity.until')}</div>
                                            <div className={classes.datePicker}>
                                                <SingleDatePicker
                                                    date={state.repeat.until}
                                                    isOutsideRange={day => day.isBefore(moment.tz(new Date(), PropertyTimezone).format('YYYY-MM-DD'))}
                                                    id="date_until"
                                                    onDateChange={date => this.setState({
                                                        repeat: { ...state.repeat, until: date }
                                                    })}
                                                    onFocusChange={({ focused }) => this.setState({ repeatUntilFocus: focused })}
                                                    focused={state.repeatUntilFocus}
                                                    noBorder={true}
                                                    numberOfMonths={1}
                                                    displayFormat={'DD MMM YYYY'}
                                                    hideKeyboardShortcutsPanel
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {state.repeat.type == repeatTypes.weeks.id && (
                                    <>
                                        {/* ------------REAPEAT ON---------- */}
                                        <div className={classes.label}>{t('experiences.manage.activity.on')}on:</div>
                                        <SlotRepeatDays 
                                            selectedDays={[...state.repeat.dayOfWeek]}
                                            selectDayHandler={this.selectDayHandler}
                                        />
                                    </>
                                )}

                            </>
                        )}
                        
                        {/* ------------------------------PRICE-------------------------- */}
                        <div className={classes.label}>{t('experiences.manage.activity.price')}*</div>
                        <FormControl className={classes.formControl}>
                            <InputBase 
                                id="custom_price"
                                name="customPrice"
                                type="number"
                                className={classes.textInput}
                                fullWidth={true}
                                value={state.customPrice || ''}
                                onChange={this.onChangeHandler}
                                error={state.customPriceError}
                            />
                            {state.customPriceError && (
                                <FormHelperText className={classes.errorMessage}>{t('experiences.manage.activity.error.price')}</FormHelperText>
                            )}
                        </FormControl>
                        
                        {/* ------------------------------AVAILABILITY-------------------------- */}
                        <div className={classes.label}>{t('experiences.manage.activity.availability')}</div>
                        <div className={classes.staticTextInput} >{state.customAvailable}</div>
                        {/* <FormControl className={classes.formControl}>
                            <div className={classes.textInput} >{state.customAvailable}</div>
                            <InputBase 
                                id="custom_available"
                                name="customAvailable"
                                type="number"
                                className={classes.textInput}
                                fullWidth={true}
                                value={state.customAvailable || ''}
                                onChange={this.onChangeHandler}
                                error={state.customAvailableError}
                            />
                            {state.customAvailableError && (
                                <FormHelperText className={classes.errorMessage}>{t('experiences.manage.activity.error.availability')}</FormHelperText>
                            )}
                        </FormControl> */}

                        <div className={classes.label}>Capacity*</div>
                        <FormControl className={classes.formControl}>
                            <InputBase 
                                id="capacity"
                                name="capacity"
                                type="number"
                                className={classes.textInput}
                                fullWidth={true}
                                value={state.capacity || ''}
                                onChange={this.onChangeHandler}
                                error={state.capacityError}
                            />
                            {state.capacityError && (
                                <FormHelperText className={classes.errorMessage}>Capacity is required.</FormHelperText>
                            )}
                        </FormControl>
                        
                        {/* ---------------SELECT CONDUCTOR------------------- */}
                        {/* <div className={classes.label}>{t('experiences.manage.activity.selectConductor')}:</div>
                        <div className={classes.selectConductor}>
                            <FormControl variant="outlined" className={classes.selectFormControl}>
                                <Select
                                    native
                                    value={state.conductorID || ''}
                                    onChange={this.onChangeHandler}
                                    className={classes.selectInput}
                                    inputProps={{
                                        name: 'conductorID'
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {Object.keys(conductorList).map(conductorID =>(
                                        <option value={conductorID} key={`conductor_${conductorID}`}>
                                            {conductorList[conductorID].name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            {state.conductorID && (
                                <Button 
                                    variant="outlined"
                                    onClick={this.editConductorDetails}
                                >
                                    {t('actions.edit')}
                                </Button>
                            )}
                        </div> */}

                        {/* <div 
                            className={classes.addConductorBtn} 
                            onClick={this.addNewConductor}
                        >
                            {t('experiences.manage.activity.addConductor')}
                        </div> */}
                        
                        {/* -------------CONDUCTOR FORM------------------ */}
                        {/* { state.conductorForm.show && (
                            <ConductorForm 
                                conductorID={state.conductorForm.id} 
                                hideFormHandler={this.hideConductorForm}
                            />
                        )} */}

                        {!state.showConfirm && (
                            <div className={classes.buttonContainer}>
                                <Button 
                                    className="button"
                                    variant="outlined"
                                    onClick={this.submitHandler}
                                >
                                    {t('actions.save')}
                                </Button>
                            </div>
                        )}

                        {state.showConfirm && (
                            <SlotUpdateConfirm 
                                availabilityID={availabilityID}
                                date={seletedDate}
                                onClickHandler={this.editCalendarSlotHandler}
                                closeHandler={this.toggleConfirmDisplay}
                            />
                        )}
                        
                    </Grid>
                </Grid>

                {/* ------------- Snackbar for frontend validation errors -------------- */}
                {state.errorData.status && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseSnackBar}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{state.errorData.message}</span>}
                    />
                )}

                {/* ------------- Snackbar success messages -------------- */}
                {state.updateSuccess && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseSnackBar}
                        autoHideDuration={6000}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.getUpdateSuccessMsg()}</span>}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { spaces, dashboard, experiences } = state;
    return{
        currentSpace: spaces[dashboard.currentSpace] || {},
        productList: experiences.activityList || {},
        conductorList: experiences.conductorList || {}
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(ActivitySlotForm)));