import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import CalenderRoomReservations from './CalenderRoomReservations'
import { forEach } from 'lodash'
import UnallocatedReservationRender from './UnallocatedReservationRender'

const FILTERS = {
  ROOMTYPE: 'roomType',
  ROOM: 'room',
  BUILDING: 'building'
}

const useStyles = makeStyles(theme => ({
  roomType: {
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: '5px',
    marginLeft: '10px',
    marginTop: '10px',
    color: '#000'
  },
  roomRow: {
    borderBottom: '1px solid #FFFFFF',
    minHeight: '42px',
    paddingBottom: 5,
    paddingTop: 5
  },
  roomsDivider: {
    borderBottom: '0.5px solid #CECECE',
    width: '100%',
    margin: '0px 10px'
  },
  room: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.1rem',
    fontWeight: 600,
    height: '100%',
    marginLeft: '12px',
    color: '#666666'
  },
  roomSort: {
    fontSize: '1.2rem',
    height: '100%',
    marginLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .roomName': {
      fontWeight: 600,
      marginRight: '5px',
      lineHeight: '30px',
      color: '#484848'
    },
    '& .roomTypeName': {
      fontSize: '1.1rem',
      color: '#666666'
    }
  },
  tooltipMainTitle: {
    fontWeight: 600,
    marginRight: '5px'
  }
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

const CalendarRender = props => {
  const {
    roomTypes,
    rooms,
    beds,
    type,
    calendarData,
    calendarProps
  } = props

  const classes = useStyles()

  // removing unallocated reserverations from beds
  const unallocatedReservationIds = Object.keys(calendarProps.reservations)
    .filter(rId => !calendarProps.reservations[rId].bedID)

  forEach(beds, bed => {
    bed.reservations = bed?.reservations?.filter(rId => !unallocatedReservationIds.includes(rId))
  })

  const getRoomName = (roomTypeID, roomID, bedID) => {
    if (!roomTypes[roomTypeID]) return ''

    let roomName = roomTypes[roomTypeID].dormOrPrivate === 'dorm' && beds[bedID].roomID && rooms[roomID]
      ? rooms[roomID].name
      : ''

    roomName = beds[bedID] ? `${roomName} ${beds[bedID].name}` : ''
    return roomName
  }

  const sortAlphaNum = (a, b) => {
    const reA = /[^a-zA-Z]/g
    const reN = /[^0-9]/g
    const aA = beds[a].name.replace(reA, '')
    const bA = beds[b].name.replace(reA, '')
    if (aA === bA) {
      const aN = parseInt(beds[a].name.replace(reN, ''), 10)
      const bN = parseInt(beds[b].name.replace(reN, ''), 10)
      return aN === bN ? 0 : aN > bN ? 1 : -1
    } else {
      return aA > bA ? 1 : -1
    }
  }

  // Filter only bedIDs which belong to a roomID
  // while looping through roomIDs data in roomTypes reducer data.....
  const filterBedIDs = bedsData => bedID => bedsData.includes(bedID)

  const getTooltipTitle = (roomName, roomTypeName) => {
    return (
      <>
        <span className={classes.tooltipMainTitle}>{roomName}</span>
        <span>({roomTypeName})</span>
      </>
    )
  }

  if (type === FILTERS.ROOMTYPE) {
    const roomTypeID = calendarData.roomTypeId

    const roomsData = calendarData.rooms ? [...calendarData.rooms] : []
    const roomTypeName = roomTypes[roomTypeID] ? roomTypes[roomTypeID].name : ''
    const roomTypeBeds = roomTypes[roomTypeID] ? roomTypes[roomTypeID].beds : []

    return (
      <>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={3} sm={3} md={2} className={classes.roomType}>{roomTypeName}</Grid>
        </Grid>
        {!calendarData.isUnallocated && roomsData.map(roomID => {
          const roomMappingData = rooms[roomID] && rooms[roomID].mapping
            ? rooms[roomID].mapping.find(data => data.roomTypeID === roomTypeID)
            : null

          if (roomMappingData && !roomMappingData.visible) return null

          return (
            <React.Fragment key={roomID}>
              {rooms[roomID] && rooms[roomID].beds &&
                rooms[roomID].beds
                  .filter(filterBedIDs(roomTypeBeds))
                  .sort(sortAlphaNum)
                  .map((bedID, index) => (
                    <Grid key={bedID + '_' + index} container className={classes.roomRow}>
                      <Grid item xs={3} sm={3} md={2}>
                        <div className={classes.room}>{getRoomName(roomTypeID, roomID, bedID)}</div>
                      </Grid>
                      <Grid item xs={9} sm={9} md={10}>
                        <CalenderRoomReservations
                          reservationKeys={beds[bedID].reservations || []}
                          bedInfo={beds[bedID]}
                          roomName={roomTypeName}
                          bedName={beds[bedID].name}
                          {...calendarProps}
                        />
                      </Grid>
                    </Grid>
                  ))}
              <div className={classes.roomsDivider} />
            </React.Fragment>
          )
        })}
        {calendarData.isUnallocated &&
          <UnallocatedReservationRender styleClasses={classes} calendarProps={calendarProps} filterType={type} />}
      </>
    )
  }

  if (type === FILTERS.ROOM) {
    const roomID = calendarData
    const uniqueRoomTypeIDs = []

    return (
      <>
        {!calendarData.isUnallocated && rooms[roomID] &&
        rooms[roomID].mapping &&
        rooms[roomID].mapping.map(mappingData => {
          const roomTypeID = mappingData.roomTypeID

          // Filter rooms with unique roomTypeIDs..............
          if (uniqueRoomTypeIDs.includes(roomTypeID)) return null
          if (!mappingData.visible) return null
          if (!roomTypes[roomTypeID]) return null

          uniqueRoomTypeIDs.push(roomTypeID)

          return (
            <React.Fragment key={roomTypeID}>
              {roomTypes[roomTypeID]?.beds
                .filter(filterBedIDs(rooms[roomID]?.beds))
                .sort(sortAlphaNum)
                .map(bedID => {
                  const roomName = getRoomName(roomTypeID, roomID, bedID)
                  const roomTypeName = roomTypes[roomTypeID].name
                  return (
                    <Grid container key={bedID} className={classes.roomRow}>

                      <Grid item xs={3} sm={3} md={2}>
                        <HtmlTooltip
                          title={getTooltipTitle(roomName, roomTypeName)}
                          placement='top-start'
                        >
                          <div className={classes.roomSort}>
                            <span className='roomName'>{roomName}</span>
                            <span className='roomTypeName'>({roomTypeName})</span>
                          </div>
                        </HtmlTooltip>
                      </Grid>

                      <Grid item xs={9} sm={9} md={10}>
                        <CalenderRoomReservations
                          reservationKeys={beds[bedID].reservations ? beds[bedID].reservations : []}
                          bedInfo={beds[bedID]}
                          roomName={roomTypeName}
                          bedName={beds[bedID].name}
                          {...calendarProps}
                        />
                      </Grid>
                    </Grid>
                  )
                })}

              <div className={classes.roomsDivider} />
            </React.Fragment>
          )
        })}
        {calendarData.isUnallocated &&
          <UnallocatedReservationRender styleClasses={classes} calendarProps={calendarProps} filterType={type} />}
      </>
    )
  }

  if (type === FILTERS.BUILDING) {
    const buildingData = calendarData

    return (
      <>
        <Grid item xs={12} sm={12}>
          <div className={classes.roomType}>{buildingData.buildingName}</div>
        </Grid>
        {
        !calendarData.isUnallocated &&
        buildingData.rooms &&
        buildingData.rooms.map(roomID => (
          rooms[roomID] &&
          rooms[roomID].mapping &&
          rooms[roomID].mapping.map(mappingData => {
            const roomTypeID = mappingData.roomTypeID

            if (!mappingData.visible) return null

            return (
              <React.Fragment key={mappingData}>
                {roomTypes[roomTypeID]?.beds
                  .filter(filterBedIDs(rooms[roomID]?.beds))
                  .sort(sortAlphaNum)
                  .map(bedID => {
                    const roomName = getRoomName(roomTypeID, roomID, bedID)
                    const roomTypeName = roomTypes[roomTypeID].name

                    return (
                      <Grid container key={bedID} className={classes.roomRow}>

                        <Grid item xs={3} sm={3} md={2}>
                          <HtmlTooltip
                            title={getTooltipTitle(roomName, roomTypeName)}
                            placement='top-start'
                          >
                            <div className={classes.roomSort}>
                              <span className='roomName'>{roomName}</span>
                              <span className='roomTypeName'>({roomTypeName})</span>
                            </div>
                          </HtmlTooltip>
                        </Grid>

                        <Grid item xs={9} sm={9} md={10}>
                          <CalenderRoomReservations
                            reservationKeys={beds[bedID].reservations ? beds[bedID].reservations : []}
                            bedInfo={beds[bedID]}
                            roomName={roomTypes[roomTypeID].name}
                            bedName={beds[bedID].name}
                            {...calendarProps}
                          />
                        </Grid>

                      </Grid>
                    )
                  })}

                <div className={classes.roomsDivider} />
              </React.Fragment>
            )
          })
        ))
        }
        {calendarData.isUnallocated &&
          <UnallocatedReservationRender styleClasses={classes} calendarProps={calendarProps} filterType={type} />}
      </>
    )
  }

  return <div>No Data Found</div>
}

const mapStateToProps = state => {
  const { roomTypes, rooms, beds } = state
  return {
    roomTypes,
    rooms,
    beds
  }
}

export default connect(mapStateToProps)(CalendarRender)
