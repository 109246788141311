
export default (state = {}, action) => {
  switch (action.type) {
    
    case "SET_USER_UI_SETTINGS":
      return {
        ...state,
        ...action.payload 
      }

    case "SET_CALENDAR_COLOR":
        return {
            ...state,
            [action.payload.spaceID]: {
                ...state[action.payload.spaceID],
                calendarColour: action.payload.colorConfig
            }
        }

    case "SET_LANGUAGE":
        return {
            ...state,
            [action.payload.spaceID]: {
                ...state[action.payload.spaceID],
                language: action.payload.language
            }
        }

    case "SET_CALENDAR_DATES":
        return {
            ...state,
            [action.payload.spaceID]: {
                ...state[action.payload.spaceID],
                dates: action.payload.dates
            }
        }

    default:
      return state;
  }
};