import React from 'react';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { getAmtFormat } from "../../../../utils/utility";
import ConfirmForm from '../../../common/ConfirmForm';


const POSSummary = (props) => {

  const { admin, currency, orders, products, classes, t } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  const handleConfirmFormClose = () => {
    setSelectedID(null);
    setConfirmOpen(false);
  }

  const handleConfirmDelete = () => {
    props.deletePOSProduct({ productID: selectedID })
  }

  const itemView = orders.map((item, index) => {
    const productData = { ...products[item.productID] }
    return item.quantity ? (
      <div className={classes.roomItemContainer} key={`product_${index}`}>
        <div className={classes.itemDesc}>
          <div style={{ display: 'flex' }}>
            <div>
              <Typography variant="body1" className={classes.roomName}>{productData.name}</Typography>
              <Typography variant="body2" className={classes.secondaryText}>
                {item.quantity} {t('existingReservation.summaryTab.items')} - {getAmtFormat(item.price / item.quantity, currency)} {t('existingReservation.summaryTab.perItem')}
              </Typography>
              {(admin && !confirmOpen) ? <div className={classes.deleteText} onClick={() => { setSelectedID(item.productID); setConfirmOpen(true); }}>Delete</div> : null}
            </div>
          </div>
          <div className={classes.priceContainer}>
            <Typography variant="body1" className={classes.price}>{item.price ? getAmtFormat(item.price, currency) : null}</Typography>
          </div>
        </div>
        <ConfirmForm
          open={confirmOpen && selectedID === item.productID}
          setOpen={setConfirmOpen}
          onClose={handleConfirmFormClose}
          onConfirm={handleConfirmDelete}
        >
          Are you sure, you would like to delete this product?
        </ConfirmForm>
        <hr className={classes.divider} />
      </div>
    ) : null
  });

  return itemView;
}

export default withTranslation()(POSSummary);