import React, { useEffect } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InputBase } from '@material-ui/core';
import Select from 'react-select';

import Button from '@material-ui/core/Button';
import NumberInput from '../../common/NumberInput';
import ConfirmForm from '../../common/ConfirmForm';
import IMAGES from '../../../constants/images';

// import { uploadRoomTypePhoto } from '../../../redux/actions/rooms/roomTypes';
// import DeleteConfirm from '../DeleteConfirm';

const useStyles = makeStyles(theme => ({
    submit: {
        display: 'flex',
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    picContainer: {
        alignItems: 'center',
        // cursor: 'pointer',
        display: 'flex',
    },
    placeholder: {
        background: '#F2F2F2',
        borderRadius: '15px',
        height: 80,
        marginRight: 15,
        overflow: 'hidden',
        width: 80,
    },
    image: {
        borderRadius: 15,
        objectFit: 'cover',
        height: 80,
        width: 80,
    },
    label: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: 0,
    },
    warning: {
        color: '#ff9800',
        marginTop: 10
    },
    input: {
        display: 'none'
    },
    deleteButton: {
        borderRadius: '50%',
        cursor: 'pointer',
        height: 20,
        opacity: 0.6,
        padding: 8,
        width: 20,
        '&:hover': {
            background: '#E0E0E0',
        }
    },
    leftAlignSubmit: {
        width: '60%'
    },
    righttAlignSubmit: {
        width: '40%',
        display: 'flex',
        justifyContent: "flex-end",
    },
    cancelButton: {
        margin: '0 10px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
        [theme.breakpoints.down('xs')]: {
            margin: '20px 0px',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
    },
    field: {
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        display: 'flex',
        height: 35,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'calc(80% - 32px)',
        '& > div': {
            width: '100%'
        },
        '&.disabled': {
            backgroundColor: '#E0E0E0'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    select: {
        border: '1px solid #E0E0E0',
        borderRadius: 5,
        width: '80%',
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    fieldError: {
        border: '1px solid red',
    },
    multiLine: {
        alignItems: 'flex-start',
        height: 100,
        overflowY: 'hidden'
    },
    cancel: {
        color: '#999999',
        fontSize: '1.2rem',
        marginRight: 15,
        '&:hover': {
            background: '#ffffff'
        }
    },
    imageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 40,
    },
    uploadButton: {
        color: '#999999',
        fontSize: '1.2rem',
        marginTop: 10,
    },
    camera: {
        display: 'inline-block',
        height: 15,
        opacity: 0.5,
        width: 15,
        marginRight: 10
    },
    input: {
        display: "none"
    },
    back: {
        '&:hover': {
            background: '#ffffff'
        }
    },
    submitButton: {
        background: '#000000',
        fontWeight: 600,
        fontSize: '1.2rem',
        '&:hover': {
            background: '#000000',
            boxShadow: 'none'
        }
    },
    tooltipText: {
        fontSize: '1rem'
    },
    warningSection: {
        padding: '10px 5px',
        textAlign: 'center',
        width: 'calc(80% - 10px)',
        background: 'rgb(255, 213, 153)',
        borderRadius: '5px',
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    errorSection: {
        padding: '10px 5px',
        textAlign: 'center',
        width: 'calc(80% - 10px)',
        background: 'rgb(255, 213, 153)',
        borderRadius: '5px',
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    infoIcon: {
        cursor: 'pointer',
        height: 12,
        width: 12,
        marginLeft: 10,
        opacity: 0.5
    }
}))

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: 0,
        borderRadius: 5,
        paddingLeft: 8,
        '&:hover': {
            border: state.isFocused ? 0 : 0
        },
        paddingLeft: 0,
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    })
};

const labelTypeOptions = [
    { label: 'Basic', value: 'BASIC' },
    { label: 'Standard', value: 'STANDARD' },
    { label: 'Deluxe', value: 'DELUXE' },
    { label: 'Superior', value: 'SUPERIOR' }
];

const basicTypeOptions = [
    { value: "Mixed Dorm", label: "Mixed Dorm", basictype: "Mixed Dorm" },
    { value: "Male Dorm", label: "Male Dorm", basictype: "Male Dorm" },
    { value: "Female Dorm", label: "Female Dorm", basictype: "Female Dorm" },
    { value: "Private", label: "Private Room", basictype: "Private" },
    { value: "Dbl Private", label: "Double Bed", basictype: "Dbl Private" },
    { value: "Twin Room", label: "Twin Room", basictype: "Private" },
    { value: "TENT", label: "Private Tent", basictype: "Private", extendedtype: "TENT" },
    { value: "APARTMENT", label: "Apartment", basictype: "Private", extendedtype: "APARTMENT" },
    { value: "FAMILYROOM", label: "Family Room", basictype: "Private", extendedtype: "FAMILYROOM" },
    { value: "SHAREDTENT", label: "Shared Tent", basictype: "Mixed Dorm", extendedtype: "SHAREDTENT"},
    { value: "MIXEDCAPSULE", label: "Mixed Capsule", basictype: "Mixed Dorm", extendedtype: "CAPSULE" },
    { value: "MALECAPSULE", label: "Male Capsule" , basictype: "Male Dorm", extendedtype: "CAPSULE" },
    { value: "FEMALECAPSULE", label: "Female Capsule", basictype: "Female Dorm", extendedtype: "CAPSULE"  },
    { value: "MIXEDPOD", label: "Mixed Pod", basictype: "Mixed Dorm", extendedtype: "POD" },
    { value: "MALEPOD", label: "Male Pod", basictype: "Male Dorm", extendedtype: "POD" },
    { value: "FEMALEPOD", label: "Female Pod", basictype: "Female Dorm", extendedtype: "POD" }
];

const ensuiteOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' }
];

const processBasicTypeOption = (data) => {
    let optionData = basicTypeOptions.find(option => {
        if (!data.extendedtype && option.basictype == data.basictype) {
            return option;
        }
        if(data.extendedtype && data.extendedtype == option.extendedtype && option.basictype == data.basictype){
            return option;
        }
    })
    return optionData?.value;
}

const Form = (props) => {
    const classes = useStyles();
    const { data, currentSpace, settings } = props;
    const [error, setError] = React.useState({ name: false });
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [disableBed, setDisabledBed] = React.useState(data.roomtypeid ? true : false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const roomTypeDefaultDetails = data.roomtypeid && settings?.roomTypes?.find(d => d.id === parseInt(data.roomtypeid)) || {};
    
    const [formData, setFormData] = React.useState({
        roomtypeid: data.roomtypeid || null,
        labeltype: data.labeltype || '',
        basictype: data.basictype || '',
        extendedtype: data.extendedtype || '',
        beds: data.beds || 0,
        currency: data.currency || settings.currency || null,
        ensuite: data.ensuite || '',
        roomtypename: data.roomtypename || '',
        description: data.description || roomTypeDefaultDetails?.description?.proposedUpdate || '',
        valueForBasicType: processBasicTypeOption(data)
    });

    const onSelectHandler = (event, name) => {
        const value = event.value;
        setFormData({ ...formData, [name]: value });
        setError({ ...error, [name]: false })
    }

    const onSelectBasicTypeHandler = (event) => {
        let stateData = { ...formData };
        const basictype = event.basictype;
        const extendedtype = event.extendedtype;
        const valueForBasicType = event.value;
        if (["Dbl Private", "Twin Room"].includes(valueForBasicType)){
            stateData = {...stateData, beds: 2}
        }
        setDisabledBed(disabledBedsHandler(valueForBasicType))
        setFormData({ ...stateData, basictype, extendedtype, valueForBasicType });
        setError({ ...error, basictype: false })
    }

    const onChangeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });
        setError({ ...error, [name]: false })
    }

    const handleValidate = () => {
        let foundError = false;
        let errorStep = {};
        let mandatoryFields = ['beds', 'basictype', 'ensuite'];
        mandatoryFields.map(item => {
            if (!formData[item]) {
                errorStep[item] = true;
                foundError = true;
            }
        })
        if (foundError) {
            setError({ ...error, ...errorStep })
            return false
        }
        if (formData.basictype.includes('Dorm') && formData.beds < 3) {
            setErrorMessage('Dorm room must have 4+ beds')
        }
        else return true
    }

    const handleSubmit = () => {
        console.log('formData', formData)
        setErrorMessage('')
        const checkInput = handleValidate();
        if (checkInput) {
            props.submitHandler(formData)
        }
    }

    const confirmDeleteHandler = () => {
        props.confirmDeleteHandler(data.roomtypeid)
    }

    const disabledBedsHandler = (valueForBasicType) => {
        let flag = data.roomtypeid ? true : false;
        if(["Dbl Private", "Twin Room"].includes(valueForBasicType)){
            flag = true;
        }
        return flag;
    }

    return (
        <div className={classes.root}>

            <div className={classes.row}>
                <div className={classes.label}>Room Grade
                <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div> Labels are to help the customer identify different room types These labels will appear in front of your basic room type on hostelworld.com They are not required to appear on our site</div>
                            <div> There are no strict rules to apply labels but our guidelines are:</div>
                            <div> Basic - the minimum requirements for a room Standard - may offer a sink, toilet, bigger room Deluxe - Extra items available such as TV, fridge, air conditioning Superior - the most exclusive room with all the above facilities plus for example, balcony, Jacuzzi</div>
                            <div> Please remember these are only guidelines</div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.select, { [classes.fieldError]: error.labeltype })}>
                    <Select
                        onChange={(e) => onSelectHandler(e, 'labeltype')}
                        options={labelTypeOptions}
                        value={labelTypeOptions.find(e => e.value === formData.labeltype)}
                        styles={selectStyle}
                        className={classes.dropdownInput}
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            {data.roomtypeid && <div className={classes.row}>
                <div className={classes.label}>HW room type ID</div>
                <div className={classes.field}>
                    <InputBase
                        id="code"
                        name="Code"
                        type="text"
                        disabled
                        defaultValue={data.roomtypeid}
                    />
                </div>
            </div>}

            <div className={classes.row}>
                <div className={classes.label}>Number of Guest
                <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div>This is the actual capacity or number of people that the room can sleep </div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.beds })}>
                    <NumberInput
                        id="beds"
                        name="beds"
                        disabled={disableBed}
                        min={0}
                        max={500}
                        value={formData.beds}
                        onChange={onChangeHandler} />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Room Type
                <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div>Select the type of room you are creating</div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.select, { [classes.fieldError]: error.basictype })}>
                    <Select
                        onChange={(e) => onSelectBasicTypeHandler(e)}
                        isDisabled={data.roomtypeid ? true : false}
                        options={basicTypeOptions}
                        value={basicTypeOptions.find(e => e.value === formData.valueForBasicType)}
                        styles={selectStyle}
                        className={classes.dropdownInput}
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Ensuite
                <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div>Are bathroom facilities located within the room? If Yes, choose yes!</div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.select, { [classes.fieldError]: error.ensuite })}>
                    <Select
                        onChange={(e) => onSelectHandler(e, 'ensuite')}
                        options={ensuiteOptions}
                        value={ensuiteOptions.find(e => e.value === formData.ensuite)}
                        styles={selectStyle}
                        className={classes.dropdownInput}
                        theme={theme => ({
                            ...theme,
                            border: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#666666',
                                primary25: '#dddddd'
                            },
                        })}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.label}>Custom Name
                <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div>This name will not appear online, it is here to help you distinguish between your room types</div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.field, { [classes.fieldError]: error.roomtypename })}>
                    <InputBase
                        id="roomtypename"
                        name="roomtypename"
                        type="text"
                        defaultValue={formData.roomtypename}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>

            <div className={classes.row}>
                {((formData.description && data.description !== formData.description) ||
                    roomTypeDefaultDetails?.description?.proposedUpdate) &&
                    <div className={classes.warningSection}>This text must be approved before it will appear online</div>
                }
                <div className={classes.label}>Description
                    <Tooltip placement="bottom-start" className={classes.icon} title={
                        <div className={classes.tooltipText}>
                            <div>Use this area to best describe your room to potential bookers. This content must be in English and it will be visible in the English version only.</div>
                            <div>Please note - a twin room must have two single beds/ bunk beds NOT a double bed. </div>
                        </div>
                    }>
                        <img className={classes.infoIcon} src={IMAGES.ICONS.question} />
                    </Tooltip>
                </div>
                <div className={clsx(classes.multiLine, classes.field)}>
                    <InputBase
                        id="description"
                        name="description"
                        fullWidth
                        multiline
                        rows="5"
                        type="text"
                        defaultValue={formData.description}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>

            <div className={classes.row}>
                {errorMessage && <div className={classes.errorSection}>{errorMessage}</div>}
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* SUBMIT / CANCEL / DELETE UPDATES */}
            {/* --------------------------------------------------------------------- */}

            <div className={classes.submit}>
                <div className={classes.leftAlignSubmit}>
                    <ConfirmForm
                        title={'Delete room type?'}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={confirmDeleteHandler}
                    >
                        Are you sure you would like to delete this room type?
                    </ConfirmForm>

                    {!confirmOpen && data.roomtypeid &&
                        <img src={IMAGES.ICONS.trash} className={classes.deleteButton} onClick={() => setConfirmOpen(true)} />
                    }

                </div>
                <div className={classes.righttAlignSubmit}>
                    <Button className={classes.cancel} onClick={props.cancelHandler}>Cancel</Button>
                    <Button variant="contained" color="primary" className={classes.submitButton} onClick={() => handleSubmit()}>Save</Button>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    settings: state.inbox.hostelworld.settings,
    currentSpace: state.spaces[state.dashboard.currentSpace]
})

export default connect(mapStateToProps)(Form);